import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import {ApiAction} from 'client/types';

import types from './types';

export const getUserPasswordExpiration: ApiAction = (clientId: number) => {
  return (dispatch) =>
    callApi(dispatch, {
      method: 'GET',
      endpoint: `${API_PATH}${API_METHODS.USER_PASSWORD_EXPIRATION_CONFIGS}`,
      types: [
        types.GET_PASSWORD_EXPIRATION_REQUEST,
        types.GET_PASSWORD_EXPIRATION_SUCCESS,
        types.GET_PASSWORD_EXPIRATION_ERROR,
      ],
      queryParams: {
        q: {
          client_id_eq: clientId,
        },
      },
    });
};

export const setUserPasswordExpiration: ApiAction = (body, configId) => {
  return (dispatch) =>
    callApi(dispatch, {
      method: configId ? 'PATCH' : 'POST',
      endpoint: `${API_PATH}${API_METHODS.USER_PASSWORD_EXPIRATION_CONFIGS}${configId ? `/${configId}` : ''}`,
      types: [
        types.SET_PASSWORD_EXPIRATION_REQUEST,
        types.SET_PASSWORD_EXPIRATION_SUCCESS,
        types.SET_PASSWORD_EXPIRATION_ERROR,
      ],
      body,
    });
};
