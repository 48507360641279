import React from 'react';

import PropTypes from 'prop-types';

import {diagramDotPrecisions} from 'client/ducks/results-graphs/constants';

import DropdownField from 'client/components/common/fields/dropdown-field';

const DiagramDotPrecisionField = (props) => {
  const {lang} = props;

  return (
    <DropdownField
      name="dot_precision"
      label={lang.INTERVAL_RANGE}
      options={[
        {value: diagramDotPrecisions.AUTOMATIC, label: lang.AUTOMATIC},
        {value: diagramDotPrecisions.FIXED, label: lang.FIXED_VALUE},
      ]}
      withWrap
    />
  );
};

DiagramDotPrecisionField.propTypes = {
  lang: PropTypes.object.isRequired,
};

export default DiagramDotPrecisionField;
