import React, {PureComponent} from 'react';

import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';

import {getColumnAdapters} from 'client/ducks/scenario/actions';
import {selectScenarioData, selectColumnAdapters, selectAllScenarioVariables} from 'client/ducks/scenario/selectors';

import {TextField, SelectField, TextareaField} from 'client/common/fields';

import CalculationComponents from './calculation-components';
import {DESTINATION_TYPES, IS_FIRST_VERSION} from './constants';
import {submitForm, getInitialValues, validate} from './helpers';
import * as rules from './rules';

import {preventDefault, onSubmitSuccess, onSubmitFail} from '../helpers';

import './ats-calculation-form.scss';

const databaseOptions = [
  {value: 'Lead', label: 'Leads DB'},
  {value: 'Visual1', label: 'Visual1'},
  {value: 'Visual2', label: 'Visual2'},
  {value: 'Visual3', label: 'Visual3'},
  {value: 'ParticipationPrize1', label: 'Win1'},
  {value: 'ParticipationPrize2', label: 'Win2'},
  {value: 'ParticipationPrize3', label: 'Win3'},
  {value: 'OperationDatum', label: 'Custom DB'},
];

class AtsCalculationForm extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    variables: PropTypes.array.isRequired,
    formValues: PropTypes.object.isRequired,
    scenario: PropTypes.object.isRequired,
    getColumnAdapters: PropTypes.func.isRequired,
    columnAdapters: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.fetchColumnAdapters();
  }

  fetchColumnAdapters = () => {
    const operation = this.props.scenario.automation_task.operation;

    const params = {
      operation_id: operation.id,
      client_id: operation.client_id,
      scope: 'calculation_step_destination',
    };

    return this.props.getColumnAdapters(params);
  };

  getDatabaseColumns = () => {
    const {formValues, columnAdapters} = this.props;

    if (!formValues.database) {
      return [];
    }

    return columnAdapters[formValues.database.value] || [];
  };

  render() {
    const {lang, variables, formValues} = this.props;

    return (
      <div className="ats-calculation-form">
        <form onSubmit={preventDefault}>
          <TextField name="name" label={lang.STEP_NAME_LABEL} required={true} />
          {!IS_FIRST_VERSION && (
            <SelectField
              name="destination_type"
              label={lang.DESTINATION_TYPE}
              options={[
                {value: DESTINATION_TYPES.VARIABLE, label: lang.VARIABLE},
                {value: DESTINATION_TYPES.DATABASE, label: lang.DATABASE},
              ]}
            />
          )}
          {rules.showVariable(formValues) && (
            <SelectField
              name="variable_id"
              label={lang.VARIABLE}
              options={variables.map((scenarioVariable) => ({
                value: scenarioVariable.id,
                label: scenarioVariable.full_name || scenarioVariable.name,
              }))}
            />
          )}
          {rules.showDatabase(formValues) && (
            <div className="ats-calculation-form__database-wrap">
              <SelectField name="database" label={lang.DATABASE} options={databaseOptions} required={true} />
              <SelectField
                name="column_adapter_id"
                label={lang.COLUMN}
                options={this.getDatabaseColumns().map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </div>
          )}
          {rules.showComponents(formValues) && <CalculationComponents formValues={formValues} />}
          <TextareaField name="comment" label={lang.COMMENT_LABEL} />
        </form>
      </div>
    );
  }
}

export default flow([
  reduxForm({
    touchOnBlur: false,
    validate,
    onSubmit: submitForm,
    onSubmitSuccess,
    onSubmitFail,
  }),
  connect(
    (state, {formId, data}) => {
      return {
        lang: state.languageState.payload.AUTOTASK_SCENARIO.SCENARIO_FORMS,
        form: formId,
        variables: selectAllScenarioVariables(state),
        scenario: selectScenarioData(state),
        columnAdapters: selectColumnAdapters(state),
        formValues: getFormValues(formId)(state) || {},
        initialValues: getInitialValues(data),
      };
    },
    {
      getColumnAdapters,
    },
  ),
])(AtsCalculationForm);
