import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import types from './types';

export const getPrizes = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.GAMES}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_DIY_OPERATION_PRIZEBLOCK_REQUEST,
        types.GET_DIY_OPERATION_PRIZEBLOCK_SUCCESS,
        types.GET_DIY_OPERATION_PRIZEBLOCK_ERROR,
      ],
      queryParams,
    });
};

export const updatePrizelistDates = (params = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PRIZES}`,
      method: 'PUT',
      noCache: true,
      types: [
        types.PUT_DIY_OPERATION_PRIZEBLOCK_DATES_REQUEST,
        types.PUT_DIY_OPERATION_PRIZEBLOCK_DATES_SUCCESS,
        types.PUT_DIY_OPERATION_PRIZEBLOCK_DATES_ERROR,
      ],
      body: JSON.stringify(params),
    });
};

export const getPrizesByGameId = (id, queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.GAMES}/${id}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_DIY_OPERATION_PRIZEBLOCK_REQUEST,
        types.GET_DIY_OPERATION_PRIZEBLOCK_SUCCESS,
        types.GET_DIY_OPERATION_PRIZEBLOCK_ERROR,
      ],
      queryParams,
    });
};

export const clearDiyOperationBlocks = () => ({
  type: types.CLEAR_DIY_OPERATION_BLOCKS,
});

export function getDevicesAction(queryParams) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DEVICES_EXTERNAL}`,
      method: 'GET',
      types: [types.GET_DIY_DEVICES_REQUEST, types.GET_DIY_DEVICES_SUCCESS, types.GET_DIY_DEVICES_ERROR],
      noCache: true,
      queryParams,
    });
  };
}

export const changePrizeOrder = (prizes) => ({
  type: types.CHANGE_PRIZE_ORDER,
  payload: {prizes},
});

export const updateGame = (gameId, body) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.GAMES}/${gameId}`,
      method: 'PATCH',
      noCache: true,
      types: [types.UPDATE_GAME_REQUEST, types.UPDATE_GAME_SUCCESS, types.UPDATE_GAME_ERROR],
      body,
    });
};
