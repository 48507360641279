import React from 'react';

import {useLanguage} from 'client/services/hooks';

import {MESSAGE_TASK_STATES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';
import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {MessageTask} from 'client/models/message-task/types';
import {TASK_TYPES} from 'client/models/operations/constants';

import MessageTaskListHeader from '../message-task-list-header';
import MessageTaskListPanel from '../message-task-list-panel';

type MessageTaskListGeneralProps = {
  task: MessageTask;
  className?: string;
  classNames?: {
    panel?: string;
    col?: string;
    link?: string;
    row?: string;
  };
};

const MessageTaskListGeneral: React.FC<MessageTaskListGeneralProps> = (props) => {
  const {task, className = '', classNames} = props;

  const lang = useLanguage('OPERATION_PAGE.MESSAGE_TASK_CARD');

  const statusMap = {
    [MESSAGE_TASK_STATES.PERFORMING]: lang.STATUSES.IN_PROGRESS,
    [MESSAGE_TASK_STATES.NEW]: lang.STATUSES.NEW,
    [MESSAGE_TASK_STATES.FINISHED]: lang.STATUSES.FINISHED,
    [MESSAGE_TASK_STATES.SLEEPING]: lang.STATUSES.PAUSED,
  };

  const renderTaskLink = (currentTask: MessageTask) => {
    const link = CustomLink.createTaskLink(task);
    return (
      <CustomScrollbars>
        <CustomLink className={classNames?.link} to={link}>
          <div className="custom-scrollbars__scroll-content">{currentTask.taskName}</div>
        </CustomLink>
      </CustomScrollbars>
    );
  };

  const renderBroadcastLink = (currentTask: MessageTask) => {
    const link = CustomLink.createMailingListLink(currentTask);
    return (
      task.broadcastName && (
        <CustomScrollbars>
          <CustomLink className={classNames?.link} to={link}>
            <div className="custom-scrollbars__scroll-content">{currentTask.broadcastName}</div>
          </CustomLink>
        </CustomScrollbars>
      )
    );
  };

  const renderEmailTemplate = (currentTask: MessageTask) => {
    return (
      currentTask.emailTemplate && (
        <CustomScrollbars>
          <div className="custom-scrollbars__scroll-content">
            <div className="break-word">{currentTask.emailTemplate.name}</div>
            <div className="break-word uppercase">
              {lang.ID_LABEL} {currentTask.emailTemplate.id}
            </div>
          </div>
        </CustomScrollbars>
      )
    );
  };

  const renderLeadsCount = (currentTask: MessageTask) => currentTask.leadsCount;

  const renderStatus = (currentTask: MessageTask) => statusMap[currentTask.taskState];

  const renderDelivered = (currentTask: MessageTask) => (
    <div className="main-text main-text--small underline">{currentTask.delivered}</div>
  );

  const renderWebhooks = (currentTask: MessageTask) =>
    currentTask.isWebhooksOpened ? lang.STATUSES.OPENED : lang.STATUSES.CLOSED;

  const columnsMap = [
    {
      name: lang.NAME_COLUMN,
      forTaskType: [TASK_TYPES.EMAIL, TASK_TYPES.SMS],
      renderMethod: renderTaskLink,
    },
    {
      name: lang.MAILING_LIST_COLUMN,
      forTaskType: [TASK_TYPES.EMAIL, TASK_TYPES.SMS],
      renderMethod: renderBroadcastLink,
    },
    {
      name: lang.TEMPLATE_COLUMN,
      forTaskType: [TASK_TYPES.EMAIL],
      renderMethod: renderEmailTemplate,
    },
    {
      name: lang.DELIVERED_COLUMN,
      forTaskType: [TASK_TYPES.SMS],
      renderMethod: renderDelivered,
    },
    {
      name: lang.N_LEADS_COLUMN,
      forTaskType: [TASK_TYPES.EMAIL, TASK_TYPES.SMS],
      renderMethod: renderLeadsCount,
    },
    {
      name: lang.STATUS_COLUMN,
      forTaskType: [TASK_TYPES.EMAIL, TASK_TYPES.SMS],
      renderMethod: renderStatus,
    },
    {
      name: lang.OPEN_COLUMN,
      forTaskType: [TASK_TYPES.EMAIL, TASK_TYPES.SMS],
      renderMethod: renderWebhooks,
    },
  ];

  return (
    <div className={className}>
      <MessageTaskListHeader headers={columnsMap} taskType={task.taskType} classNames={classNames} />
      <MessageTaskListPanel data={columnsMap} taskType={task.taskType} classNames={classNames} task={task} />
    </div>
  );
};

export default MessageTaskListGeneral;
