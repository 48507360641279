import React from 'react';

import placeholderNoPhoto from 'assets/images/placeholder-no-photo.png';
import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import './card.scss';

const b = bem('card');

const Card = (props) => {
  const {submit, isSelected, hasTasks, className, onSelect, src, children, classNames} = props;

  const type = submit ? 'submit' : 'button';

  return (
    <button
      type={type}
      className={cn(
        b({
          selected: isSelected,
          'has-tasks': hasTasks,
        }),
        className,
      )}
      onClick={onSelect}
    >
      <div className={cn(b('image-wrapper'), classNames.imageWrapper)}>
        <img alt="" src={src || placeholderNoPhoto} className={cn(b('image'), classNames.image)} />
      </div>
      {children}
    </button>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool,
  hasTasks: PropTypes.bool,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  src: PropTypes.string,
  submit: PropTypes.bool,
};

Card.defaultProps = {
  isSelected: false,
  hasTasks: false,
  className: '',
  onSelect: null,
  src: '',
  submit: false,
};

export default Card;
