import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';

export default class TestEmailStep extends PureComponent {
  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState;
  }

  getFormValue = (values, id) => {
    const targetKey = Object.keys(values).filter((key) => Number(key.slice(1)) === id)[0];
    return values[targetKey] || '';
  };

  renderMailjet = (item = {}, i) => {
    const {id, name} = item.email_template_variable || {};

    return (
      <div className="test-step__form-block" key={id}>
        <div className="fake-input fake-input--disabled">
          <div className="fake-input__label">{`MailJet's ${this.LANGUAGE.FIELD_TEXT} ${i}`}</div>
          <div className="fake-input__field">{`${this.LANGUAGE.VARIABLE_TEXT} ${i}: ${name}`}</div>
        </div>
      </div>
    );
  };

  renderMailjets = (items) => {
    return items.map((item, i) => this.renderMailjet(item, i + 1));
  };

  render() {
    const {formValues, step} = this.props;
    const {comment, implementation} = step;
    const {email_scenario_step_mapping_items, email_template = {}, recipient_variable = {}} = implementation;
    const {name: templateName} = email_template;
    const {id} = recipient_variable;
    const recipientVarName = this.getFormValue(formValues, id);

    return (
      <div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{this.LANGUAGE.TEMPLATE_TEXT}</div>
            <div className="fake-input__field">{templateName}</div>
          </div>
        </div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{this.LANGUAGE.EMAIL_TEXT}</div>
            <div className="fake-input__field">{recipientVarName}</div>
          </div>
        </div>
        {this.renderMailjets(email_scenario_step_mapping_items)}
        {comment && comment.length > 0 && (
          <div className="test-step__form-block">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{this.LANGUAGE.COMMENT_TEXT}</div>
              <div className="fake-input__textarea">{comment}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

TestEmailStep.propTypes = {
  formValues: PropTypes.object,
  step: PropTypes.object,
  variables: PropTypes.array,
  languageState: PropTypes.object.isRequired,
};
