import React, {Component} from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import PieChart from 'react-simple-pie-chart';

import {STATUS_COLORS} from 'client/common/config';
import GoogleMap from 'client/common/google-map';
import Popover from 'client/common/popovers/popover';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import './plan-map.scss';

class PlanMap extends Component {
  static propTypes = {
    onEditInteraction: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    labels: PropTypes.object,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    labels: {},
  };

  static PARIS_COORDINATES = {
    lat: 48.8566,
    lng: 2.3522,
  };

  static Marker({labels, place, onEditInteraction, readOnly}) {
    if (!place.offline_interactions?.length) {
      return null;
    }

    const groupedStatuses = groupBy(place.offline_interactions.map((i) => i.current_status));

    const slices = Object.keys(groupedStatuses).map((status) => ({
      value: groupedStatuses[status].length,
      color: STATUS_COLORS[status],
    }));

    return (
      <Popover
        shiftTop={6}
        shiftLeft={20}
        arrowOffset={70}
        trigger="click"
        className="plan-map__fix-marker"
        overlayInnerStyle={{
          width: 280,
          left: -100,
        }}
        overlay={
          <div className="popover-list theme-color-9">
            <div className="popover-list__title plan-map__popover-header">
              <div className="main-text main-text--bold">{labels.markerPopoverTitle}</div>
              <div className="plan-map__popover-subtitle">
                {place.street_address} {place.city_zip} {'\n'}
                {place.city_name}
              </div>
            </div>
            <div className="popover-list__list plan-map__popover-list">
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMax: 70,
                }}
              >
                <div className="plan-map__popover-list-title">{labels.markerPopoverInterfaces}</div>
                {place.offline_interactions.map((interaction) => {
                  const interfaceName = get(interaction, 'interface.name');
                  const deviceName = get(interaction, 'device.name');

                  return (
                    <div className="plan-map__popover-list-item" key={interaction.id}>
                      <div
                        className={cn('plan-map__popover-list-item__interface', {link: !readOnly})}
                        onClick={() => !readOnly && onEditInteraction(interaction)}
                        title={interfaceName}
                      >
                        {interfaceName}
                      </div>
                      <div
                        className={cn('plan-map__popover-list-item__device', {link: !readOnly})}
                        onClick={() => !readOnly && onEditInteraction(interaction)}
                        title={deviceName}
                      >
                        {deviceName}
                      </div>
                    </div>
                  );
                })}
              </CustomScrollbars>
            </div>
          </div>
        }
      >
        <div className="plan-map__marker-wrap">
          <div className="plan-map__marker-pie">
            <PieChart className="pie" slices={slices} />
          </div>
          <div className="plan-map__marker-count">{place.offline_interactions.length}</div>
        </div>
      </Popover>
    );
  }

  state = {
    center: this.PARIS_COORDINATES,
    isMarkersVisible: true,
  };

  handleZoomStart = () => {
    this.setState({
      isMarkersVisible: false,
    });
  };

  handleZoomEnd = () => {
    this.setState({
      isMarkersVisible: true,
    });
  };

  getOptions = () => {
    const options = {
      fullscreenControl: false,
    };

    return options;
  };

  getCenter = () => {
    const {data = []} = this.props;

    if (data?.length === 1) {
      return {
        lat: data[0].latitude,
        lng: data[0].longitude,
      };
    }

    return null;
  };

  renderMarker = ({data: place}) => {
    const {labels, onEditInteraction, readOnly} = this.props;
    return <PlanMap.Marker place={place} onEditInteraction={onEditInteraction} readOnly={readOnly} labels={labels} />;
  };

  render() {
    const {data} = this.props;

    return (
      <GoogleMap
        center={this.getCenter()}
        markers={data.map((place) => ({
          lat: place.latitude,
          lng: place.longitude,
          id: place.id,
          data: place,
        }))}
        renderMarker={this.renderMarker}
      />
    );
  }
}

PlanMap.Marker.propTypes = {
  onEditInteraction: PropTypes.func.isRequired,
  labels: PropTypes.object.isRequired,
  place: PropTypes.object.isRequired,
};

export default PlanMap;
