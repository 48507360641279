import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import {SOURCE_TYPES} from 'client/common/config';
import Icon from 'client/common/icon';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';

import cssModule from './sources-data-grid.module.scss';

const b = bem('sources-data-grid', {cssModule});

class SourcesDataGrid extends ReactQueryParams {
  static defaultProps = {
    data: [],
    meta: {},
    perPage: 5,
  };

  static propTypes = {
    data: PropTypes.array,
    lang: PropTypes.object.isRequired,
    meta: PropTypes.object,
    onPageChange: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
    onSelectedRecordsChanged: PropTypes.func.isRequired,
    perPage: PropTypes.number,
    onEditSourceClick: PropTypes.func.isRequired,
    onDeleteSource: PropTypes.func.isRequired,
    onShowAddSource: PropTypes.func.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  };

  onCheck = (isChecked, source) => {
    const {onSelectedRecordsChanged, selectedIds} = this.props;
    if (isChecked) {
      onSelectedRecordsChanged([...selectedIds, source.id]);
    } else {
      onSelectedRecordsChanged([...selectedIds].filter((id) => id !== source.id));
    }
  };

  onCheckAll = (isChecked) => {
    const {onSelectedRecordsChanged, selectedIds, data} = this.props;
    if (isChecked) {
      onSelectedRecordsChanged([...selectedIds, ...data.map(({id}) => id)]);
    } else {
      onSelectedRecordsChanged([...selectedIds].filter((id) => data.every((source) => source.id !== id)));
    }
  };

  renderToolbar = () => {
    const {lang, onShowAddSource, onDeleteSource, selectedIds} = this.props;

    const checkedCount = selectedIds.length;

    return (
      <>
        {checkedCount > 0 && (
          <button className="button button--bg-6" onClick={onDeleteSource}>
            <Icon name="trash" className="button__icon" width={17} height={19} />
            <span>
              {lang.DELETE_SOURCES_BUTTON} ({checkedCount})
            </span>
          </button>
        )}
        <button className="button button--bg-6" onClick={onShowAddSource}>
          <Icon name="plus" className="button__icon" />
          <span>{lang.ADD_SOURCE_BUTTON}</span>
        </button>
      </>
    );
  };

  renderSource = ({value: name, item: source}) => {
    return (
      <div className="link main-text ellipsis-text" onClick={() => this.props.onEditSourceClick(source)} title={name}>
        {name}
      </div>
    );
  };

  renderType = ({value: type}) => {
    return this.props.lang.SOURCE_TYPES[SOURCE_TYPES[type]];
  };

  renderEllipsisText = ({value: text}) => {
    return (
      <div className="ellipsis-text" title={text}>
        {text}
      </div>
    );
  };

  render() {
    const {lang, meta, onPageChange, data, onSortChange, perPage, selectedIds} = this.props;

    return (
      <div className={b()}>
        <div className={b('toolbar')}>
          {this.renderToolbar()}
          <PerPageDropdown
            value={perPage?.toString()}
            onChange={(value) => onPageChange({perPage: value, page: 1})}
            simpleValue
          />
        </div>
        <ClientTable
          data={data}
          onSortChange={({sortField, sortOrder}) => onSortChange(sortField, sortOrder)}
          sortField={this.queryParams.sort?.name}
          sortOrder={this.queryParams.sort?.order}
          checkable
          checkedRows={selectedIds.map((id) => ({id}))}
          onCheckAll={this.onCheckAll}
          onCheck={this.onCheck}
          columns={[
            {
              label: lang.SOURCE_COLUMN,
              name: 'name',
              path: 'name',
              width: 200,
              sortable: true,
              render: this.renderSource,
            },
            {
              label: lang.TYPE_COLUMN,
              name: 'kind',
              path: 'kind',
              width: 150,
              sortable: true,
              render: this.renderType,
            },
            {
              label: lang.CLIENT_COLUMN,
              name: 'client_name',
              path: 'client_name',
              width: 150,
              render: this.renderEllipsisText,
            },
            {
              label: lang.COMMENT_COLUMN,
              name: 'comment',
              path: 'comment',
              width: 380,
              render: this.renderEllipsisText,
            },
            {
              label: lang.DESCRIPTION_COLUMN,
              name: 'description',
              path: 'description',
              width: 380,
              render: this.renderEllipsisText,
            },
          ]}
        />
        <PaginationBar
          data={data}
          currentPage={meta.current_page}
          totalPages={meta.total_pages}
          totalItems={meta.total_count}
          perPage={perPage}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}

export default connect(({languageState}) => ({
  lang: {
    ...languageState.payload.SOURCES.TABLE,
    SOURCE_TYPES: languageState.payload.SOURCE_TYPES,
  },
}))(SourcesDataGrid);
