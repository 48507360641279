import {Dispatch} from 'redux';

import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import {WinningHeaderData} from 'client/components/winnings/various/winning-header/types';
import {ApiAction} from 'client/types';

import types from './types';

export const getWinningTasks: ApiAction = () => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.AUTOMATION_TASKS}`,
    method: 'GET',
    noCache: true,
    types: [types.GET_WINNING_TASKS_REQUEST, types.GET_WINNING_TASKS_SUCCESS, types.GET_WINNING_TASKS_ERROR],
    queryParams: {
      include: {
        interactions: null,
      },
    },
  });
};

export const getWinningTask: ApiAction = (taskId) => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.AUTOMATION_TASKS}/${taskId}`,
    method: 'GET',
    noCache: true,
    types: [types.GET_WINNING_TASK_REQUEST, types.GET_WINNING_TASK_SUCCESS, types.GET_WINNING_TASK_ERROR],
    queryParams: {
      include: {
        interactions: null,
      },
    },
  });
};

export const setWinningHeaderData = (data: WinningHeaderData) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.SET_WINNING_HEADER_DATA,
      payload: {data},
    });
  };
};

export const searchWinnings: ApiAction = (taskId, queryParams) => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.AUTOMATION_TASKS}/${taskId}${API_METHODS.SEARCH_WINNER}`,
    method: 'GET',
    noCache: true,
    types: [types.SEARCH_WINNINGS_REQUEST, types.SEARCH_WINNINGS_SUCCESS, types.SEARCH_WINNINGS_ERROR],
    queryParams,
  });
};

export const distributePrize: ApiAction = (id: number) => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.PARTICIPATION_PRIZES}/${id}/distribute`,
    method: 'POST',
    noCache: true,
    types: [types.DISTRIBUTE_PRIZE_REQUEST, types.DISTRIBUTE_PRIZE_SUCCESS, types.DISTRIBUTE_PRIZE_ERROR],
  });
};

export const resentEmail: ApiAction = (id: number) => (dispatch) => {
  return callApi(
    dispatch,
    {
      endpoint: `${API_PATH}${API_METHODS.PARTICIPATION_PRIZES}/${id}/resend_email`,
      method: 'POST',
      noCache: true,
      types: [types.RESEND_PRIZE_REQUEST, types.RESEND_PRIZE_SUCCESS, types.RESEND_PRIZE_ERROR],
    },
    true,
  );
};
