import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {formatNumberSpace} from 'client/services/formatters';
import {useLanguage} from 'client/services/hooks';

import {Diagram} from 'client/models/diagrams/types';

import cssModule from './diagram-stats.module.scss';

const b = bem('diagram-stats', {cssModule});

type DiagramStatsProps = {
  diagram: Diagram;
  className?: string;
};

const DiagramStats: React.FC<DiagramStatsProps> = (props) => {
  const {diagram, className} = props;

  const lang = useLanguage('RESULTS_GRAPHS_CLIENT');

  return (
    <div className={cn(b(), className)}>
      <span className={b('total')}>{formatNumberSpace(diagram?.values_count)}</span>
      <span className={b('results')}>{lang.RESULTS}</span>
      {diagram?.exclude_empty_values && (
        <span className={b('percent')}>
          ({diagram?.values_rate}%&#160;
          <span className={b('participations')}>{lang.OF_PARTICIPATIONS}</span>)
        </span>
      )}
    </div>
  );
};

export default DiagramStats;
