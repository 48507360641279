import React from 'react';

import PropTypes from 'prop-types';

import InstoreMonotoringList from 'client/components/instore/lists/instore-monitoring-list';

const InstoreMonotoringContainer = ({monitoringList}) => {
  return <InstoreMonotoringList monitoringList={monitoringList} />;
};

InstoreMonotoringContainer.propTypes = {
  monitoringList: PropTypes.array,
};

InstoreMonotoringContainer.defaultProps = {
  monitoringList: [],
};

export default InstoreMonotoringContainer;
