import {HistoryEditor} from 'slate-history';
import {ReactEditor} from 'slate-react';

export const TEXT_STYLE_OPTIONS = ['bold', 'italic', 'line-through', 'underline'] as const;
export const TEXT_STYLE_TO_LANG = {
  bold: 'BOLD',
  italic: 'ITALIC',
  'line-through': 'STRIKETHROUGH',
  underline: 'UNDERLINE',
} as const;

export const TEXT_ALIGN_OPTIONS = ['left', 'center', 'right', 'justify'] as const;
export const TEXT_ALIGN_TO_LANG = {
  left: 'LEFT',
  center: 'CENTER',
  right: 'RIGHT',
  justify: 'JUSTIFY',
} as const;

export const LIST_ITEM = 'list-item' as const;
export const LIST_TYPES = {
  ORDERED: 'ordered-list',
  UNORDERED: 'unordered-list',
} as const;
export const LIST_OPTIONS = Object.values(LIST_TYPES);

export const TYPOGRAPHY_TYPES = {
  PARAGRAPH: 'paragraph',
  HEADING_ONE: 'heading-one',
  HEADING_TWO: 'heading-two',
  HEADING_THREE: 'heading-three',
} as const;
export const TYPOGRAPHY_OPTIONS = Object.values(TYPOGRAPHY_TYPES);

export const LINK_ITEM = 'link' as const;
export const DEFAULT_STYLE = 'default-style' as const;

export const TYPE_TO_TAG = {
  [TYPOGRAPHY_TYPES.PARAGRAPH]: 'p',
  [TYPOGRAPHY_TYPES.HEADING_ONE]: 'h1',
  [TYPOGRAPHY_TYPES.HEADING_TWO]: 'h2',
  [TYPOGRAPHY_TYPES.HEADING_THREE]: 'h3',
  [LIST_TYPES.ORDERED]: 'ol',
  [LIST_TYPES.UNORDERED]: 'ul',
  [LIST_ITEM]: 'li',
  [LINK_ITEM]: 'a',
  [DEFAULT_STYLE]: 'default-style',
} as const;

export const DEFAULT_FONT_SIZE = '14px';
export const FONT_SIZE_OPTIONS = Array.from(new Array(15), (_, i) => i + 10); // [10, 11, ... 24]

export const TYPOGRAPHY_TO_SIZE = {
  [TYPOGRAPHY_TYPES.PARAGRAPH]: DEFAULT_FONT_SIZE,
  [TYPOGRAPHY_TYPES.HEADING_ONE]: '20px',
  [TYPOGRAPHY_TYPES.HEADING_TWO]: '16px',
  [TYPOGRAPHY_TYPES.HEADING_THREE]: '14px',
};

export const FONT_FAMILY_OPTIONS = ['Arial', 'Courier New', 'Georgia', 'Times New Roman', 'Trebuchet MS', 'Verdana'];

// types
export type AlignOption = (typeof TEXT_ALIGN_OPTIONS)[number];
export type TypographyOption = (typeof TYPOGRAPHY_TYPES)[keyof typeof TYPOGRAPHY_TYPES];
export type ListOption = (typeof LIST_TYPES)[keyof typeof LIST_TYPES];
export type BlockFormatType = typeof LIST_ITEM | AlignOption | TypographyOption | ListOption;
export type CustomPropertyType = 'color' | 'font-size' | 'font-family';
export type MarkFormatType = (typeof TEXT_STYLE_OPTIONS)[number] | CustomPropertyType | 'url';

export type CustomText = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  ['line-through']?: boolean;
  color?: string;
  ['font-size']?: string;
  ['font-family']?: string;
  url?: string;
};
export type CustomElement = {
  type: BlockFormatType;
  align?: AlignOption;
  children: CustomText[];
};
export type CustomEditor = ReactEditor & HistoryEditor;
