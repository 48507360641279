import React from 'react';

import {components, DropdownIndicatorProps} from 'react-select';

import Icon from 'client/common/icon';
import {SelectOptionType} from 'client/common/selects/types';

const DropdownIndicator: React.FC<DropdownIndicatorProps<SelectOptionType>> = ({...props}) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="dropdown" width={20} />
    </components.DropdownIndicator>
  );
};
export default DropdownIndicator;
