import {DATA_COMPONENTS, NAME_FIELD_MAPPER, REPORTS_PER_PAGE} from './constants';

type DataComponent = (typeof DATA_COMPONENTS)[keyof typeof DATA_COMPONENTS];
type FieldMap = {
  fileName: 'participation_export_name' | 'lead_transfer_name';
  date: string;
};

type ExportReportsRequestParamsType = {
  autotaskId: string;
  clientId: string;
  page: string;
  search: string;
  sort: string;
};
export const getExportReportsRequestParams = (component: DataComponent, params: ExportReportsRequestParamsType) => {
  const {autotaskId, clientId, page, search, sort} = params;

  const participationParams = {
    include: ['participation_export', 'user'],
    include_participation_export_report_shareable: null,
    include_participation_export_report_deletable: null,
    q: {
      g: {
        0: {
          participation_export_type_eq: 'FileParticipationExport',
          participation_export_automation_task_id_eq: autotaskId,
        },
        1: {
          participation_export_name_cont: search,
          user_full_name_cont: search,
          m: 'or',
        },
      },
      s: sort,
    },
    page,
    per_page: REPORTS_PER_PAGE,
  };

  const leadParams = {
    include: ['lead_transfer', 'user'],
    include_lead_export_report_deletable: null,
    include_lead_export_report_shareable: null,
    q: {
      g: {
        0: {
          lead_transfer_type_eq: 'FileLeadExport',
          lead_transfer_client_id_eq: clientId,
        },
        1: {
          lead_transfer_name_cont: search,
          user_full_name_cont: search,
          m: 'or',
        },
      },
      s: sort,
    },
    page,
    per_page: REPORTS_PER_PAGE,
  };

  const winnerParams = {
    include: ['participation_export', 'user'],
    include_winner_export_report_deletable: null,
    include_winner_export_report_shareable: null,
    q: {
      g: {
        0: {
          participation_export_type_eq: 'FileWinnerExport',
          participation_export_automation_task_id_eq: autotaskId,
        },
        1: {
          participation_export_name_cont: search,
          user_full_name_cont: search,
          m: 'or',
        },
      },
      s: sort,
    },
    page,
    per_page: REPORTS_PER_PAGE,
  };

  const map = {
    [DATA_COMPONENTS.PARTICIPATIONS]: participationParams,
    [DATA_COMPONENTS.LEADS]: leadParams,
    [DATA_COMPONENTS.LEADS_DATABASE]: leadParams,
    [DATA_COMPONENTS.WINNERS]: winnerParams,
  };

  return map[component];
};

export const mapTableFields = (field: keyof FieldMap, component: DataComponent) => {
  const fieldsMap: FieldMap = {
    fileName: NAME_FIELD_MAPPER[component],
    date: 'created_at',
  };

  return fieldsMap[field] || field;
};
