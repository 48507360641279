import {useEffect, useMemo} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle, useMount} from 'react-use';

import {useTranslations} from 'client/services/hooks/use-translations';

import {fetchLanguages} from 'client/ducks/language/actions';
import {selectCurrentLanguage, selectLanguages} from 'client/ducks/language/selectors';
import {selectUser} from 'client/ducks/user/selectors';

import {getAppLanguage} from 'client/common/config';

import {ApiDispatch} from 'client/types';

type Params = {
  isUserReady: boolean;
};

export default ({isUserReady}: Params) => {
  const dispatch: ApiDispatch = useDispatch();
  const user = useSelector(selectUser);
  const currentLanguage = useSelector(selectCurrentLanguage);
  const appLanguages = useSelector(selectLanguages);
  const [isLanguagesFetched, toggleLanguagesFetched] = useToggle(false);
  const {setLanguage} = useTranslations();

  const appLanguagesCodes = useMemo(() => appLanguages.map(({name}) => name), [appLanguages]);

  const isAnon = Boolean(isUserReady && !user.id);
  const isAdmin = Boolean(user?.id && user?.type === 'AdminUser');
  const isClient = Boolean(user?.id && user?.type === 'ClientUser');
  const isTracking = Boolean(user?.id && user?.type === 'PrizeTrackingUser');

  const isAnonOrAdmin = isAnon || isAdmin;
  const isClientOrTracking = isClient || isTracking;

  const shouldChangeAdminOrAnonLang = isAnonOrAdmin && currentLanguage !== getAppLanguage(appLanguagesCodes);
  const shouldChangeClientOrTrackingLang = isClientOrTracking && user.locale !== currentLanguage;
  const shouldChangeLang = shouldChangeClientOrTrackingLang || shouldChangeAdminOrAnonLang;

  const isLocaleReady = isUserReady && !shouldChangeLang;

  useMount(() => {
    setLanguage({lang: getAppLanguage(appLanguagesCodes), store: false});
  });

  useEffect(() => {
    if (shouldChangeAdminOrAnonLang) {
      setLanguage({lang: getAppLanguage(appLanguagesCodes), store: false});
    }
    if (shouldChangeClientOrTrackingLang) {
      setLanguage({lang: user.locale, store: true});
    }
  }, [appLanguagesCodes, setLanguage, shouldChangeAdminOrAnonLang, shouldChangeClientOrTrackingLang, user.locale]);

  useEffect(() => {
    if (isAnonOrAdmin && localStorage.getItem('language')) {
      localStorage.removeItem('language');
    }
    if (isClientOrTracking && localStorage.getItem('language') !== user.locale) {
      localStorage.setItem('language', user.locale);
    }
  }, [isAnonOrAdmin, isClientOrTracking, user.locale]);

  useEffect(() => {
    if (!isLanguagesFetched && user?.id) {
      dispatch(fetchLanguages()).then(() => {
        toggleLanguagesFetched();
      });
    }
  }, [dispatch, isLanguagesFetched, toggleLanguagesFetched, user.id]);

  return {isLocaleReady};
};
