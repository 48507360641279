import React from 'react';

import cn from 'classnames';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useQueryParams, useReduxFetch} from 'client/services/hooks';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {getLeadsClient} from 'client/ducks/leads-dashboard/actions';
import {selectLeadsClient} from 'client/ducks/leads-dashboard/selectors';

import Spinner from 'client/components/common/spinner';

import AgencyClientsBar from 'client/components/agencies/various/agency-clients-bar';
import {CLIENT_TYPES} from 'client/models/client/constants';

import MainInfoBlock from './leads-info-block';
import LeadsNavigation from './leads-navigation';
import LeadsOnlineUsers from './leads-online-users';
import LeadsPerCampaign from './leads-per-campaign';
import LeadsStatistic from './leads-statistic';
import LeadsTimeline from './leads-timeline';

import './leads-dashboard.scss';

const b = bem('leads-dashboard');

type LeadsDashboardProps = {
  clientId: number;
};

const LeadsDashboard: React.FC<LeadsDashboardProps> = (props) => {
  const {clientId} = props;
  const [{agencyClientId}] = useQueryParams(null, {}, {parse: {parseNumbers: true}});
  const currentClient = useSelector(selectCurrentClient);

  const {data: client, loading: loading} = useReduxFetch({
    action: getLeadsClient,
    selector: selectLeadsClient,
    actionArgs: [
      agencyClientId || clientId,
      {
        include_client_leads_count: null,
      },
    ],
  });

  if (currentClient?.id !== clientId || loading) {
    return <Spinner pageCentered />;
  }

  const isAgency = currentClient.type === CLIENT_TYPES.AGENCY && currentClient.client_management;

  const empty = client.leads_count < 1;

  return (
    <div className={b({agency: isAgency})}>
      {isAgency && <AgencyClientsBar agencyId={clientId} agencyOption />}
      <LeadsNavigation client={client} />
      <div className={b('row')}>
        <MainInfoBlock clientId={client.id} />
      </div>
      <div className={b('row')}>
        <LeadsStatistic client={client} empty={empty} />
      </div>
      <div className={b('row')}>
        <LeadsTimeline client={client} empty={empty} />
      </div>
      <div className={cn(b('row'), b('flex-row'))}>
        <LeadsOnlineUsers client={client} empty={empty} />
        <LeadsPerCampaign client={client} empty={empty} />
      </div>
    </div>
  );
};

export default LeadsDashboard;
