import React, {useCallback, useState} from 'react';

import {useSelector} from 'react-redux';
import {useMount, useStateList} from 'react-use';

import bem from 'client/services/bem';
import {useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {getCurrentTimezone} from 'client/services/utils/date';

import {selectGroupedClientTemplates} from 'client/ducks/templates/selectors';

import {Client} from 'client/models/client/types';

import {calcSteps} from './helpers';

import {DiyOperationModalFormName} from '../constants';
import {STEPS, StepType} from '../helpers';
import DiyOperationSummaryStep from '../steps/diy-operation-summary-step';
import DiyOperationTemplateStep from '../steps/diy-operation-template-step';

import cssModule from './diy-operation-modal-form.module.scss';

const STEP_MAPPING = {
  [STEPS.GENERAL]: DiyOperationTemplateStep,
  [STEPS.FORM]: DiyOperationTemplateStep,
  [STEPS.GAME]: DiyOperationTemplateStep,
  [STEPS.QUIZ]: DiyOperationTemplateStep,
  [STEPS.SUMMARY]: DiyOperationSummaryStep,
};

const b = bem('diy-operation-modal-form', {cssModule});

type DiyOperationModalFormProps = {
  generalTemplateId?: number;
  onSubmit: (value: object) => Promise<any>;
  client: Client;
};

const DiyOperationModalForm: React.FC<DiyOperationModalFormProps> = (props) => {
  const {generalTemplateId, onSubmit, client} = props;

  const templatesByTypes = useSelector(selectGroupedClientTemplates);
  const {change, valid} = useReduxForm(DiyOperationModalFormName);

  const [steps, setSteps] = useState<StepType[]>(() => calcSteps(templatesByTypes[STEPS.GENERAL], generalTemplateId));
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const {
    state: currentStep,
    prev: goToPrevStep,
    next: goToNextStep,
    currentIndex: currentStepIndex,
  } = useStateList<StepType>(steps);
  const prevStep = steps[currentStepIndex - 1] || null;

  const handleAddNewOperation = useCallback(
    (values) => {
      setLoadingSubmit(true);
      onSubmit(values).then(() => {
        setLoadingSubmit(false);
      });
    },
    [onSubmit],
  );

  const StepComponent = STEP_MAPPING[currentStep];

  useMount(() => {
    if (currentStep === STEPS.GENERAL && generalTemplateId) {
      const template = Object.values(templatesByTypes[STEPS.GENERAL] || {})
        .flat()
        .find((item) => item.id === generalTemplateId);
      change(`templates.${currentStep}`, template);
      goToNextStep();
    }
  });

  return (
    <div className={b({summary: currentStep === STEPS.SUMMARY})}>
      <StepComponent
        className={b('step')}
        updateFormState={change}
        currentStep={currentStep}
        goToPrevStep={goToPrevStep}
        goToNextStep={goToNextStep}
        prevStep={prevStep}
        setSteps={setSteps}
        onFinish={handleAddNewOperation}
        isFormValid={valid}
        loading={loadingSubmit}
        client={client}
      />
    </div>
  );
};

export default reduxForm<DiyOperationModalFormProps>({
  form: DiyOperationModalFormName,
  initialValues: {
    languages: [],
    device: false,
    online: false,
    timezone: getCurrentTimezone(),
  },
})(DiyOperationModalForm);
