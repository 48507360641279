import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
// components
import {Link} from 'react-router-dom';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {patchClient} from 'client/ducks/clients-list/actions';

import {CLIENT_PAGES, CLIENT_TYPES} from 'client/common/config';
import Icon from 'client/common/icon';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import CustomLink from 'client/components/common/custom-link';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';

import {TASK_TYPES} from 'client/models/operations/constants';

import './mailing-list.scss';

const FORM_NAME = 'mailingForm';
const ACTIVE_STATUS = 'active';
const INACTIVE_STATUS = 'inactive';

let items = [];

class MailingList extends Component {
  static form = FORM_NAME;

  static propTypes = {
    data: PropTypes.array,
    clientId: PropTypes.number.isRequired,
    editMode: PropTypes.bool,
    languageState: PropTypes.object.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    patchClient: PropTypes.func.isRequired,
  };

  static defaultProps = {
    editMode: false,
    data: [],
  };

  static validate(vals, props) {
    const LANGUAGE = props.languageState.payload.AGENCY.MAILING_LISTS_CARD;
    const values = vals || {};
    const errors = {};

    for (let i = 0; i < items.length; i++) {
      if (items[i].deleted) {
        continue;
      }

      const name = 'name' + i;
      const status = 'status' + i;

      if (!values[name] || !values[name].trim()) {
        errors[name] = LANGUAGE.NAME_IS_REQUIRED_ERROR;
      }
      if (!values[status] || !values[status].value) {
        errors[status] = LANGUAGE.STATUS_IS_REQUIRED_ERROR;
      }
    }

    return errors;
  }

  static updateSyncErrors(values) {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: FORM_NAME,
      },
      payload: {
        syncErrors: MailingList.validate(values),
      },
    };
  }

  static mapData(lists) {
    return {
      client: {
        broadcast_lists: lists.map((item) => ({
          id: item.id,
          name: item.name,
          active: item.active,
          _destroy: item.deleted,
        })),
      },
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      showDeleteItemModal: false,
      deletingItem: {},
      items: [],
    };

    this.LANGUAGE = props.languageState.payload.AGENCY.MAILING_LISTS_CARD;

    this.statuses = Object.keys(this.LANGUAGE.STATUSES).map((key) => ({
      value: key,
      label: this.LANGUAGE.STATUSES[key],
    }));
  }

  componentDidMount() {
    this.applyNewData(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.applyNewData(this.props.data);
    }
  }

  applyNewData = (data) => {
    items = data.slice();

    this.setState({items});

    this.props.initialize(
      items.reduce((init, item, index) => {
        init['name' + index] = item.name;
        init['status' + index] = {value: item.active ? ACTIVE_STATUS : INACTIVE_STATUS};

        return init;
      }, {}),
    );
  };

  onDeleteButtonClick = (item) => () => {
    this.setState({
      showDeleteItemModal: true,
      deletingItem: item,
    });
  };

  onDeleteItem = () => {
    this.setState((state) => ({
      showDeleteItemModal: false,
      deletingItem: {
        ...state.deletingItem,
        deleted: true,
      },
      items: items.map((item) => ({
        ...item,
        deleted: item.id === state.deletingItem.id,
      })),
    }));
  };

  handleChangeName =
    (index) =>
    ({target: {value}}) => {
      const item = this.state.items[index];
      item.name = value;
    };

  handleChangeStatus =
    (index) =>
    ({value}) => {
      const item = this.state.items[index];
      item.active = value === ACTIVE_STATUS;
    };

  save = () => {
    const {clientId} = this.props;
    const data = MailingList.mapData(this.state.items);

    return this.props.patchClient(clientId, data).then(({payload: {response: {errors} = {}}}) => {
      if (errors) {
        const submitErrors = {};

        for (let i in errors.broadcast_lists) {
          if (errors.broadcast_lists[i].name) {
            submitErrors['name' + i] = this.LANGUAGE.PHONES_EMAILS_CARD.NAME_IS_TAKEN_ERROR;
          }
        }

        throw new SubmissionError(submitErrors);
      }

      this.props.onSaveClick();
    });
  };

  renderItem = (editMode) => (item, i) => {
    if (item.deleted) {
      return null;
    }

    if (editMode) {
      return (
        <div className="mailing-list__item" key={item.id}>
          <Field
            cssModifier="mailing-list__input"
            name={`name${i}`}
            type="text"
            component={fieldTemplate}
            onChange={this.handleChangeName(i)}
          />
          <Field
            cssModifier="mailing-list__select"
            name={`status${i}`}
            type="select"
            options={this.statuses}
            component={fieldTemplate}
            onChange={this.handleChangeStatus(i)}
          />
          <button type="button" className="mailing-list__input-btn" onClick={this.onDeleteButtonClick(item)}>
            <Icon name="trash" className="mailing-list__delete-icon" width={17} height={19} />
          </button>
        </div>
      );
    }

    const {clientId} = this.props;
    const messageTask = item.message_tasks[0];
    const mailingListLink = CustomLink.createMailingListLink({
      taskType: TASK_TYPES.EMAIL,
      clientType: CLIENT_TYPES.COMPANY,
      clientId,
      operationId: messageTask.operation_id,
      taskId: messageTask.id,
      broadcastId: item.id,
    });

    const mailngListTasksLink = CustomLink.createMailingListTasksLink({
      clientType: CLIENT_TYPES.COMPANY,
      clientId,
      broadcastId: item.id,
    });

    return (
      <div className="mailing-list__item" key={item.id}>
        <Link className="link mailing-list__item__name ellipsis-text" to={mailingListLink}>
          {item.name}
        </Link>
        <span className="mailing-list__item__operations">
          <Icon name="comment" className="mailing-list__item__operations__icon" />
          <Link className="link" to={`${mailngListTasksLink}`}>
            {item.message_tasks_count}
          </Link>
        </span>
        {item.broadcast_recipients_count > 0 && (
          <span className="mailing-list__item__leads">
            <Link className="link" to={`${CLIENT_PAGES.LEADS_LIST}/${clientId}?broadcast_list_id=${item.id}`}>
              {item.broadcast_recipients_count}
            </Link>
          </span>
        )}
        <div
          className={cn('mailing-list__status', {
            'mailing-list__status--active': item.active,
            'mailing-list__status--inactive': !item.active,
          })}
        />
      </div>
    );
  };

  render() {
    const {editMode, onCancelClick, handleSubmit} = this.props;

    const listClassName = cn('mailing-list', {
      'mailing-list--expand': true,
      'mailing-list--empty': !this.state.items.length || editMode,
    });

    return (
      <div className={listClassName}>
        <ConfirmationModal
          show={this.state.showDeleteItemModal}
          onClose={() => this.setState({showDeleteItemModal: false})}
          onCancel={() => this.setState({showDeleteItemModal: false})}
          message={this.LANGUAGE.DELETE_MAILING_LIST_MODAL.BODY_TEXT}
          title={this.LANGUAGE.DELETE_MAILING_LIST_MODAL.TITLE}
          cancelText={this.LANGUAGE.CANCEL_BUTTON}
          confirmText={this.LANGUAGE.DELETE_MAILING_LIST_MODAL.CONFIRM}
          className="theme-color-2"
          buttonConfirmClass="button--bg-2"
          buttonCancelClass="button--bg-11"
          onConfirm={this.onDeleteItem}
        />

        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          <div className="mailing-list__list">
            {this.state.items.length === 0 ? (
              <span className="mailing-list__empty">{this.LANGUAGE.NO_RECORDS}</span>
            ) : (
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMax: !this.state.items.length || editMode ? 258 : 298,
                  autoHeightMin: !this.state.items.length || editMode ? 258 : 298,
                }}
              >
                <div className="mailing-list__list-content">{this.state.items.map(this.renderItem(editMode))}</div>
              </CustomScrollbars>
            )}
          </div>
          {editMode && (
            <div className="mailing-list__bottom">
              <div className="mailing-list__action-btns">
                <button className="button button--bg-11 mailing-list__action-btn" onClick={onCancelClick} type="button">
                  {this.LANGUAGE.CANCEL_BUTTON}
                </button>
                <button className="button button--bg-2 mailing-list__action-btn" type="submit">
                  {this.LANGUAGE.SAVE_BUTTON}
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

const form = reduxForm({
  form: MailingList.form,
  validate: MailingList.validate,
})(MailingList);

const mapStateToProps = ({languageState}) => ({
  languageState,
});

const mapDispatchToProps = {
  patchClient,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(form));
