import types from './types';

const initialState = {
  allIds: [],
  byId: {},
  allTwitterIds: [],
  byTwitterId: {},
  allFacebookIds: [],
  byFacebookId: {},
  meta: {},
};
const reduceFunction = (action) =>
  action.payload.social_accounts.reduce(
    (result, item) => {
      return {
        allIds: [...result.allIds, item.id],
        byId: {...result.byId, [item.id]: item},
      };
    },
    {
      allIds: [],
      byId: {},
    },
  );

export default function (state = initialState, action) {
  let reduced;

  switch (action.type) {
    case types.GET_SOCIAL_ACCOUNTS_SUCCESS:
      reduced = reduceFunction(action);
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload.meta,
        },
        allIds: reduced.allIds,
        byId: reduced.byId,
      };
    case types.GET_TWITTER_ACCOUNTS_SUCCESS:
      reduced = reduceFunction(action);
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload.meta,
        },
        allTwitterIds: reduced.allIds,
        byTwitterId: reduced.byId,
      };
    case types.GET_FACEBOOK_ACCOUNTS_SUCCESS:
      reduced = reduceFunction(action);
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload.meta,
        },
        allFacebookIds: reduced.allIds,
        byFacebookId: reduced.byId,
      };
    default:
      return state;
  }
}
