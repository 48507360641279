import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import './percentage-bar.scss';

const b = bem('percentage-bar');

function PercentageBar({value, className, barFrontClassName, barBackClassName}) {
  return (
    <div className={cn(b(), className)}>
      <div className={cn(b('back'), barBackClassName)}>
        <div className={cn(b('front'), barFrontClassName)} style={{width: `${value}%`}} />
      </div>
    </div>
  );
}

PercentageBar.propTypes = {
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
  barBackClassName: PropTypes.string,
  barFrontClassName: PropTypes.string,
};

PercentageBar.defaultProps = {
  className: '',
  barBackClassName: '',
  barFrontClassName: '',
};

export default PercentageBar;
