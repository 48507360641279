import React from 'react';

import bem from 'client/services/bem';

import Skeleton from 'client/common/various/skeleton';

import cssModule from './parameters-bar-skeleton.module.scss';

const b = bem('parameters-bar-skeleton', {cssModule});

const ParametersBarSkeleton: React.FC = () => {
  return (
    <div className={b()}>
      <div className={b('params')}>
        <div className={b('labels')}>
          <Skeleton className={b('label')} />
          <Skeleton className={b('label')} />
          <Skeleton className={b('label')} />
        </div>
        <div className={b('values')}>
          <Skeleton className={b('value')} />
          <Skeleton className={b('value', {long: true})} />
          <Skeleton className={b('value')} />
        </div>
      </div>
      <Skeleton className={b('button')} />
    </div>
  );
};

export default ParametersBarSkeleton;
