import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {set} from 'client/common/meta/meta.actions';

import LeadsDashboard from 'client/components/leads/leads-dashboard';

class LeadsDashboardPage extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    setMetaAction: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        clientId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.props.setMetaAction({title: this.props.lang.TITLE});
  }

  render() {
    const {match} = this.props;

    return <LeadsDashboard clientId={+match.params.clientId} />;
  }
}

export default withRouter(
  connect(
    (state) => ({
      lang: state.languageState.payload.LEADS_DASHBOARD,
    }),
    {
      setMetaAction: set,
    },
  )(LeadsDashboardPage),
);
