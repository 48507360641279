import React from 'react';

import PropTypes from 'prop-types';

import './auth-error.scss';

function AuthError(props) {
  const {children, ...rest} = props;

  return (
    <div className="auth-error" {...rest}>
      {children}
    </div>
  );
}

AuthError.propTypes = {
  children: PropTypes.node,
};

AuthError.defaultProps = {
  children: null,
};

export default AuthError;
