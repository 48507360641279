import React from 'react';

import {Field, BaseFieldProps} from 'redux-form';

import BaseFieldComponent from 'client/common/fields/base-field';

import VariableTextInput, {
  VariableTextInputProps,
} from 'client/components/email-template-editor/inputs/variable-text-input';
import {Translation} from 'client/models/language/types';

interface VariableTextFieldProps extends BaseFieldProps {
  label?: Translation;
}

const InputComponent = (props: VariableTextInputProps) => (
  <BaseFieldComponent Component={VariableTextInput} {...props} />
);

const VariableTextField = (props: VariableTextFieldProps) => {
  return <Field component={InputComponent} {...props} />;
};

export default VariableTextField;
