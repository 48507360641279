import types from './types';

const initialState = {
  optIns: [],
};
export default function (_state = initialState, action) {
  const state = Object.assign({}, _state);

  switch (action.type) {
    case types.GET_OPT_INS_SUCCESS:
      state.optIns = action.payload.opt_in_columns;
      return state;
    case types.FLUSH_OPTINS_COLUMNS_ALL_STATE:
      return initialState;
    default:
      return state;
  }
}
