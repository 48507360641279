import React from 'react';

import Popover from 'client/common/popovers/popover';

import EmptyCell from 'client/components/tables/common/cells/empty-cell';

import AccessLevelCellOverlay, {AccessLevelType} from './access-level-cell-overlay';

const AccessLevelCell = ({value}) => {
  if (!value?.accessed_level) {
    return <EmptyCell />;
  }

  if (!value?.levels_data) {
    return value?.accessed_level;
  }

  return (
    <Popover
      position="bottom"
      shiftTop={5}
      overlayInnerStyle={{
        width: 350,
      }}
      contentCssClass="custom-popover-new__content"
      overlay={<AccessLevelCellOverlay accessLevel={value} />}
    >
      {value?.accessed_level}
    </Popover>
  );
};

AccessLevelCell.propTypes = {
  value: AccessLevelType.isRequired,
};

export default AccessLevelCell;
