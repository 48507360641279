import React from 'react';

import PropTypes from 'prop-types';

import InfoBlock from 'client/components/common/info-block';

function BCCard({title, children}) {
  return (
    <InfoBlock>
      <InfoBlock.Title>{title}</InfoBlock.Title>
      <InfoBlock.Content>
        <InfoBlock.Body>{children}</InfoBlock.Body>
      </InfoBlock.Content>
    </InfoBlock>
  );
}

BCCard.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
};

BCCard.defaultProps = {
  title: '',
  children: '',
};

export default BCCard;
