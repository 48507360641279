import React from 'react';

import PropTypes from 'prop-types';

import {dateToString} from 'client/services/helpers';

import CouponInfoRow from '../coupon-info-row';

const CouponInfoApp = (props) => {
  const {lang, viewingCoupon} = props;

  return (
    <div>
      <CouponInfoRow label={lang.INTERNAL_CODE}>{viewingCoupon.internal_code}</CouponInfoRow>
      <CouponInfoRow label={lang.EXTERNAL_NAME}>{viewingCoupon.external_name}</CouponInfoRow>
      <CouponInfoRow label={lang.TYPE}>{lang['TYPE_' + viewingCoupon.coupon_type.toUpperCase()]}</CouponInfoRow>
      <CouponInfoRow label={lang.VALIDITY_TYPE}>
        {viewingCoupon.validity_duration ? lang.DURATION : lang.FIXED_VALUE}
      </CouponInfoRow>
      {viewingCoupon.validity_duration && (
        <CouponInfoRow label={lang.VALIDITY_DAYS}>{viewingCoupon.validity_days}</CouponInfoRow>
      )}
      {!viewingCoupon.validity_duration && (
        <CouponInfoRow label={lang.VALIDITY_FROM}>{dateToString(viewingCoupon.validity_from)}</CouponInfoRow>
      )}
      {!viewingCoupon.validity_duration && (
        <CouponInfoRow label={lang.VALIDITY_TO}>{dateToString(viewingCoupon.validity_to)}</CouponInfoRow>
      )}
      <CouponInfoRow label={lang.TOKENS_GENERATED}>{viewingCoupon.number_of_generated_tokens}</CouponInfoRow>
      <CouponInfoRow label={lang.COMMENTS} scrollable>
        {viewingCoupon.comment}
      </CouponInfoRow>
    </div>
  );
};

CouponInfoApp.propTypes = {
  lang: PropTypes.object.isRequired,
  viewingCoupon: PropTypes.shape({
    internal_code: PropTypes.string,
    external_name: PropTypes.string,
    coupon_type: PropTypes.string,
    validity_duration: PropTypes.bool,
    validity_days: PropTypes.number,
    validity_from: PropTypes.string,
    validity_to: PropTypes.string,
    number_of_generated_tokens: PropTypes.number,
    comment: PropTypes.string,
  }).isRequired,
};

export default CouponInfoApp;
