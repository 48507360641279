import {isEmptyString} from 'client/services/validator';

export default (lang) =>
  (values = {}) => {
    const errors = {};

    if (isEmptyString(values.name)) {
      errors.name = lang.REQUIRED;
    }

    return errors;
  };
