import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import ScrollArea from 'react-scrollbar';

import bem from 'client/services/bem';
import {doesValueExist} from 'client/services/helpers';

import AppButton from 'client/common/buttons/app-button';
import Icon from 'client/common/icon';
import PaginationBar from 'client/common/paginations/pagination-bar';
import Popover from 'client/common/popovers/popover';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import SearchField from 'client/components/common/search-field';

import cssModule from './stores-table.module.scss';

const b = bem('stores-table', {cssModule});

class StoresTable extends Component {
  static propTypes = {
    data: PropTypes.array,
    selectedStores: PropTypes.array,
    children: PropTypes.any,
    topRightSection: PropTypes.any,
    search: PropTypes.string,
    needToUpdateStores: PropTypes.bool,
    totalPages: PropTypes.number,
    totalItems: PropTypes.number,
    perPage: PropTypes.number,
    currentPage: PropTypes.number,
    mapViewClick: PropTypes.func,
    deleteStoresClick: PropTypes.func,
    selectRegionClick: PropTypes.func,
    onSearch: PropTypes.func,
    onSearchClear: PropTypes.func,
    showClear: PropTypes.bool,
    onPageChange: PropTypes.func,
    onSortChange: PropTypes.func,
    changeSelectedStores: PropTypes.func,
    onEditStoreClick: PropTypes.func,
    onSelectRegionClick: PropTypes.func,
    languageState: PropTypes.object,
    isAdmin: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      checkedRows: [],
    };
  }

  onPageChange = (params) => {
    this.setState({checkedRows: []});
    this.props.changeSelectedStores([]);
    this.props.onPageChange(params);
  };

  onSortChange = ({sortField, sortOrder}) => {
    this.setState({checkedRows: []});
    this.props.changeSelectedStores([]);
    this.props.onSortChange(sortField, sortOrder);
  };

  onSelect = (isChecked, item) => {
    let checkedItems;
    if (isChecked) {
      checkedItems = [...this.state.checkedRows, item];
    } else {
      checkedItems = this.state.checkedRows.filter((row) => row.id !== item.id);
    }

    this.setState({checkedRows: checkedItems});
    this.props.changeSelectedStores(checkedItems.map((row) => row.id));
  };

  onSelectAll = (isChecked) => {
    const checkedItems = isChecked ? this.props.data : [];
    this.setState({checkedRows: checkedItems});
    this.props.changeSelectedStores(checkedItems.map((row) => row.id));
  };

  renderToolbar = () => {
    const {selectedStores, lang} = this.props;
    return (
      <div className={b('toolbar')}>
        <SearchField
          cssModifier={cn('input', b('search-input'))}
          placeholder={lang.SEARCH_PLACEHOLDER}
          onSearch={this.props.onSearch}
          onClear={this.props.onSearchClear}
          defaultSearch={this.props.search}
        />

        <div className={b('action-buttons')}>
          {selectedStores.length > 0 && (
            <button className="button button--bg-3" onClick={this.props.selectRegionClick}>
              {lang.SELECT_REGION_PLACEHOLDER}
            </button>
          )}
          {selectedStores.length > 0 && (
            <button className="button button--bg-3" onClick={this.props.deleteStoresClick}>
              <Icon name="trash" className="button__icon" width={17} height={19} />
              <span>
                {lang.DELETE_STORES_BUTTON} ({selectedStores.length})
              </span>
            </button>
          )}
          <button className="button button--bg-3" onClick={() => this.props.mapViewClick()}>
            <Icon name="marker" className="button__icon" />
            <span>{lang.MAP_VIEW_BUTTON}</span>
          </button>
        </div>
      </div>
    );
  };

  renderOperationOverlay(row) {
    return (
      <ScrollArea
        className="in-table"
        contentClassName="in-table__content"
        smoothScrolling={true}
        verticalContainerStyle={{marginTop: '12px', width: '5px', marginRight: '4px'}}
        verticalScrollbarStyle={{borderRadius: '16px', width: '4px'}}
      >
        <div className="popover-list popover-list--color-1">
          <h3 className="popover-list__title">{this.props.lang.OPERATIONS_PERFORMED_TITLE}</h3>
          <ul className="popover-list__list">
            <li>
              <div className="main-text main-text--color-1">
                {row.offline_interations &&
                  row.offline_interations.map((op, i) => (
                    <div className="operation" key={i}>
                      <a href="#">{op.code}</a>
                      <div className="operation-name">{op.name}</div>
                    </div>
                  ))}
              </div>
            </li>
          </ul>
        </div>
      </ScrollArea>
    );
  }

  renderCommentOverlay(row) {
    return (
      <ScrollArea
        className="in-table"
        contentClassName="in-table__content"
        smoothScrolling={true}
        verticalContainerStyle={{marginTop: '12px', width: '5px', marginRight: '4px'}}
        verticalScrollbarStyle={{borderRadius: '16px', width: '4px'}}
      >
        <div className="popover-list popover-list--color-1">
          <h3 className="popover-list__title">{this.props.lang.COMMENTS_TITLE}</h3>
          <ul className="popover-list__list">
            <li>
              <div className="main-text main-text--color-1">{row.comment}</div>
            </li>
          </ul>
        </div>
      </ScrollArea>
    );
  }

  renderActions = ({item: row}) => {
    const showOperations = doesValueExist(row.offline_interations) && row.offline_interations.length > 0;
    const showComment = doesValueExist(row.comment) && row.comment.length > 0;

    return (
      <div className={b('icon-row')}>
        <Popover
          position="bottom"
          className={b('popover', {hidden: !showOperations})}
          arrowOffset={70}
          overlayInnerStyle={{
            width: 200,
          }}
          overlay={this.renderOperationOverlay(row)}
        >
          <Icon name="operation" />
        </Popover>
        <AppButton
          className={b('popover', {hidden: !row.latitude || !row.longitude})}
          iconName="marker"
          onClick={() => this.props.mapViewClick(row.id)}
          asWrap
        />
        <Popover
          position="bottom"
          className={b('popover', {hidden: !showComment})}
          arrowOffset={70}
          overlayInnerStyle={{
            width: 200,
          }}
          overlay={this.renderCommentOverlay(row)}
        >
          <Icon name="message" />
        </Popover>
      </div>
    );
  };

  renderName = ({item: store}) => {
    const {..._store} = store;
    _store.group = _store.network.group;
    _store.group.value = _store.group.id;
    _store.group.label = _store.group.name;

    _store.zip = _store.city;
    _store.agglomeration = _store.city.agglomeration.name;
    _store.zone = _store.city.agglomeration.zone.name;
    return this.props.isAdmin ? (
      <div
        className={b('link')}
        onClick={() => {
          this.props.onEditStoreClick(_store);
        }}
      >
        {store.name}
      </div>
    ) : (
      store.name
    );
  };

  renderRegion = ({item: store}) => {
    const {isAdmin, onSelectRegionClick, lang} = this.props;

    if (!isAdmin) {
      return store.region && store.region.name;
    }

    return store.region ? (
      <div
        className={b('link')}
        onClick={() => {
          if (isAdmin) {
            onSelectRegionClick(store);
          }
        }}
      >
        {store.region.name}
      </div>
    ) : (
      <div
        className={b('link', ['light'])}
        onClick={() => {
          onSelectRegionClick({id: store.id, client_id: store.client_id});
        }}
      >
        {lang.SELECT_REGION_PLACEHOLDER}
      </div>
    );
  };

  render() {
    const {data, lang} = this.props;
    return (
      <>
        <div className={b('toolbar-wrapper')}>
          {this.renderToolbar()}
          <PerPageDropdown
            onChange={(pages) => {
              this.props.onPageChange({page: 1, perPage: pages});
            }}
            value={String(this.props.perPage)}
            simpleValue
          />
        </div>
        <ClientTable
          data={data}
          onSortChange={this.onSortChange}
          sortField={this.props.sort?.name}
          sortOrder={this.props.sort?.order}
          checkable={this.props.isAdmin}
          onCheck={this.onSelect}
          onCheckAll={this.onSelectAll}
          checkedRows={this.state.checkedRows}
          columns={[
            {
              name: 'name',
              label: lang.PLACE_NAME_COLUMN,
              render: this.renderName,
              sortable: true,
              width: '9.5%',
            },
            {
              path: 'id',
              label: 'Id',
              width: '5%',
            },
            {name: 'group_name', path: 'group_name', label: lang.GROUP_COLUMN, sortable: true, width: '11%'},
            {name: 'network_name', path: 'network_name', label: lang.NETWORK_COLUMN, sortable: true, width: '11%'},
            {name: 'city_zip', path: 'city_zip', label: lang.ZIP_COLUMN, sortable: true, width: '5%'},
            {name: 'city_name', path: 'city_name', label: lang.CITY_COLUMN, sortable: true, width: '9%'},
            {
              name: 'agglomeration_name',
              path: 'agglomeration_name',
              label: lang.AGGLOMERATION_COLUMN,
              sortable: true,
              width: '12%',
            },
            {
              name: 'address',
              path: 'street_address',
              label: lang.ADDRESS_COLUMN,
              sortable: true,
              width: '18%',
            },
            {
              name: 'region_name',
              label: lang.REGION_COLUMN,
              render: this.renderRegion,
              sortable: true,
              width: '9.5%',
            },
            {name: 'actions', label: lang.ACTIONS_COLUMN, render: this.renderActions, width: '10%'},
          ]}
        />
        <PaginationBar
          data={data}
          perPage={this.props.perPage}
          totalPages={this.props.totalPages}
          totalItems={this.props.totalItems}
          currentPage={this.props.currentPage}
          onPageChange={this.onPageChange}
        />
      </>
    );
  }
}

export default StoresTable;
