import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, FieldArray, formValueSelector, reset} from 'redux-form';

import {required} from 'client/services/validator';

import {selectScenariosForMappingItems} from 'client/ducks/autotask/selectors';
import {selectColumns} from 'client/ducks/column-adapters/selectors';
import {selectOptInsForMapping} from 'client/ducks/opt-in-columns/selectors';

import {COLUMN_ADAPTER_TYPES} from 'client/common/config';
import Icon from 'client/common/icon';
import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';

import './map-items-modal.scss';

class MapItemsModal extends Component {
  static formName = 'MapItemsForm';

  static defaultProps = {
    show: false,
    formValues: [],
  };

  static COLUMN_TYPES = {
    LEAD: 'leadColumns',
    OPERATION: 'operationColumns',
    VISUAL1: 'visual1Columns',
    VISUAL2: 'visual2Columns',
    VISUAL3: 'visual3Columns',
    WIN1: 'win1Columns',
    WIN2: 'win2Columns',
    WIN3: 'win3Columns',
    SCENARIOS: 'scenarios',
  };

  static DISALLOWED_LEAD_COLUMNS = ['token'];

  static propTypes = {
    show: PropTypes.bool,
    lang: PropTypes.object.isRequired,
    langDbNames: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    interfaceItemIndex: PropTypes.number,
    parentFormName: PropTypes.string.isRequired,
    interfaceItem: PropTypes.object,
    mappingItems: PropTypes.array,
    leadColumns: PropTypes.array.isRequired,
    operationColumns: PropTypes.array.isRequired,
    visual1Columns: PropTypes.array.isRequired,
    visual2Columns: PropTypes.array.isRequired,
    visual3Columns: PropTypes.array.isRequired,
    win1Columns: PropTypes.array.isRequired,
    win2Columns: PropTypes.array.isRequired,
    win3Columns: PropTypes.array.isRequired,
    scenarios: PropTypes.array.isRequired,
    formValues: PropTypes.array,
    optIns: PropTypes.arrayOf(PropTypes.object).isRequired,
    initialize: PropTypes.func,
    reset: PropTypes.func.isRequired,
  };

  rules = {
    type: required(this.props.lang.SELECT_TYPE_ERROR),
    column: required(this.props.lang.SELECT_COL_ERROR),
    optIn: required(this.props.lang.SELECT_OPTIN_ERROR),
    optInText: required(this.props.lang.ENTER_OPTIN_ERROR),
  };

  constructor(props) {
    super(props);

    this.state = {
      addMapping: false,
    };
  }

  getdbs = () => {
    const {langDbNames} = this.props;

    return [
      {
        label: langDbNames.Lead,
        value: MapItemsModal.COLUMN_TYPES.LEAD,
        type: 'DataMappingItem',
      },
      {
        label: langDbNames.OperationDatum,
        value: MapItemsModal.COLUMN_TYPES.OPERATION,
        type: 'DataMappingItem',
      },
      {
        label: langDbNames.Visual1,
        value: MapItemsModal.COLUMN_TYPES.VISUAL1,
        type: 'DataMappingItem',
      },
      {
        label: langDbNames.Visual2,
        value: MapItemsModal.COLUMN_TYPES.VISUAL2,
        type: 'DataMappingItem',
      },
      {
        label: langDbNames.Visual3,
        value: MapItemsModal.COLUMN_TYPES.VISUAL3,
        type: 'DataMappingItem',
      },
      {
        label: langDbNames.Win1,
        value: MapItemsModal.COLUMN_TYPES.WIN1,
        type: 'DataMappingItem',
      },
      {
        label: langDbNames.Win2,
        value: MapItemsModal.COLUMN_TYPES.WIN2,
        type: 'DataMappingItem',
      },
      {
        label: langDbNames.Win3,
        value: MapItemsModal.COLUMN_TYPES.WIN3,
        type: 'DataMappingItem',
      },
      {
        label: langDbNames.Scenario,
        value: MapItemsModal.COLUMN_TYPES.SCENARIOS,
        type: 'TriggerMappingItem',
      },
    ];
  };

  getColumnsByType = (type) => {
    if (!type) {
      return [];
    }

    return this.props[type.value] || [];
  };

  shouldShowCheckboxes = (itemValue) => {
    const {type, column} = itemValue;

    const checkboxes = {
      required: false,
      unique: false,
    };

    if (!type) {
      return checkboxes;
    }

    switch (type.value) {
      case MapItemsModal.COLUMN_TYPES.LEAD:
        checkboxes.unique = true;
        checkboxes.required = true;
        break;
      case MapItemsModal.COLUMN_TYPES.OPERATION:
        checkboxes.required = true;
        break;
      case MapItemsModal.COLUMN_TYPES.VISUAL1:
        checkboxes.required = true;
        break;
      case MapItemsModal.COLUMN_TYPES.VISUAL2:
        checkboxes.required = true;
        break;
      case MapItemsModal.COLUMN_TYPES.VISUAL3:
        checkboxes.required = true;
        break;
      case MapItemsModal.COLUMN_TYPES.SCENARIOS:
        checkboxes.unique = false;
        checkboxes.required = false;
        break;
    }

    if (!['email', 'phone'].includes(column?.kind) && type.value !== MapItemsModal.COLUMN_TYPES.LEAD) {
      checkboxes.unique = false;
    }

    return checkboxes;
  };

  save = (values) => {
    if (this.props.onSubmit) {
      this.props.onSubmit(values);
    }
  };

  renderMappingItem = (member, index, onRemoveItem) => {
    const {lang} = this.props;
    const itemValue = this.props.formValues[this.props.interfaceItemIndex][index];
    const checkboxes = this.shouldShowCheckboxes(itemValue);
    const interfaceItem = this.props.interfaceItem;
    const dbs = this.getdbs();
    const columns = this.getColumnsByType(itemValue.type).filter((column) => {
      const baseRule =
        Boolean(interfaceItem.array) === column.array &&
        interfaceItem.kind === column.kind &&
        column.type !== 'SystemColumnAdapter';

      switch (itemValue.type.value) {
        case MapItemsModal.COLUMN_TYPES.SCENARIOS:
          return true;

        case MapItemsModal.COLUMN_TYPES.LEAD:
          return (
            baseRule &&
            column.type !== COLUMN_ADAPTER_TYPES.OPT_IN &&
            !MapItemsModal.DISALLOWED_LEAD_COLUMNS.includes(column.label)
          );

        default:
          return baseRule;
      }
    });

    return (
      <div className="map-items-modal__row" key={index}>
        <div className="map-items-modal__col-1">
          <Field
            label={lang.DB_NAME_LABEL}
            placeholder={lang.DB_NAME_PLACEHOLDER}
            cssModifier="select--no-min-height"
            name={`${member}.type`}
            type="select"
            options={dbs}
            searchable={false}
            component={fieldTemplate}
            validate={this.rules.type}
            scrollBarProps={{
              autoHeightMax: 300,
            }}
            onChange={() => this.props.change(`${member}.column`, null)}
          />
          <div className="theme-color-8 map-items-modal__item-controls">
            {checkboxes.required && (
              <Field
                label={<span className="main-text">{lang.USE_IN_ACCEPTING_RULE_LABEL}</span>}
                cssModifier="select--no-min-height map-items-modal__item-checkbox"
                name={`${member}.required`}
                type="checkbox"
                component={fieldTemplate}
              />
            )}
            {checkboxes.unique && (
              <Field
                label={<span className="main-text">{lang.USE_IN_MATCHING_RULE_LABEL}</span>}
                cssModifier="select--no-min-height"
                name={`${member}.unique`}
                type="checkbox"
                component={fieldTemplate}
              />
            )}
          </div>
        </div>
        <div className="map-items-modal__col-2">
          <Field
            label={lang.COLUMN_NAME_LABEL}
            placeholder={lang.COLUMN_NAME_PLACEHOLDER}
            cssModifier="select--no-min-height select--view-4"
            name={`${member}.column`}
            type="select"
            options={columns.filter((i) => !['created_at', 'updated_at'].includes(i.label))}
            searchable={false}
            component={fieldTemplate}
            validate={this.rules.column}
          />
        </div>
        <div className="map-items-modal__col-3">
          <div className="map-items-modal__del-btn" onClick={onRemoveItem}>
            <div className="button button--circle button--bg-7">
              <Icon name="trash" className="button__icon" width={17} height={19} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderOptIn = (member, index, onRemoveItem) => {
    const {lang} = this.props;

    return (
      <div className="map-items-modal__row" key={index}>
        <div className="map-items-modal__col-1">
          <Field
            label={lang.OPTIN_TYPE_LABEL}
            cssModifier="select--no-min-height"
            name={`${member}.type`}
            type="select"
            options={this.props.optIns}
            searchable={false}
            component={fieldTemplate}
            validate={this.rules.optIn}
            onChange={(e, value) => {
              this.props.change(`${member}.opt_in_text`, value.opt_in_text);
            }}
          />
          <div className="theme-color-8 map-items-modal__item-controls">
            <Field
              label={<span className="main-text">{lang.USE_IN_ACCEPTING_RULE_LABEL}</span>}
              cssModifier="select--no-min-height map-items-modal__item-checkbox"
              name={`${member}.required`}
              type="checkbox"
              component={fieldTemplate}
            />
          </div>
        </div>
        <div className="map-items-modal__col-2">
          <Field
            label={lang.COLUMN_LEADS_LABEL}
            cssModifier="input--no-min-height"
            type="text"
            name={`${member}.column`}
            component={fieldTemplate}
            disabled
          />
        </div>
        <div className="map-items-modal__col-3">
          <div className="map-items-modal__del-btn" onClick={onRemoveItem}>
            <div className="button button--circle button--bg-7">
              <Icon name="trash" className="button__icon" width={17} height={19} />
            </div>
          </div>
        </div>
        <div className="map-items-modal__textarea">
          <Field
            label={lang.INTERFACE_OPTIN_LABEL}
            name={`${member}.opt_in_text`}
            type="textarea"
            component={fieldTemplate}
            validate={this.rules.optInText}
          />
        </div>
      </div>
    );
  };

  renderItems = ({fields}) => {
    const {interfaceItem, lang} = this.props;
    const showOptInButton = !interfaceItem.array && interfaceItem.kind === 'boolean';
    const defaultMapItem = {};

    return (
      <div>
        <div className="map-items-modal__rows">
          <CustomScrollbars
            cssModifier="custom-scrollbars--view-2 custom-scrollbars--hide-hor-scroll"
            scrollbarProps={{
              autoHeightMin: 280,
              autoHeightMax: 390,
              hideTracksWhenNotNeeded: true,
            }}
          >
            <div className="custom-scrollbars__scroll-content">
              {fields.map((member, index, field) => {
                const fieldType = field.get(index).fieldType;

                return fieldType === 'opt_in'
                  ? this.renderOptIn(member, index, () => fields.remove(index))
                  : this.renderMappingItem(member, index, () => fields.remove(index));
              })}
            </div>
          </CustomScrollbars>
        </div>
        <div className="button button--bg-7 map-items-modal__add-btn" onClick={() => fields.push(defaultMapItem)}>
          <Icon name="plus" className="button__icon" />
          <span>{lang.ADD_MAPPING_BUTTON}</span>
        </div>
        {showOptInButton && (
          <div
            className="button button--bg-7 map-items-modal__add-btn"
            onClick={() => fields.push({fieldType: 'opt_in'})}
          >
            <Icon name="plus" className="button__icon" />
            <span>{lang.ADD_OPTIN_BUTTON}</span>
          </div>
        )}
      </div>
    );
  };

  handleClose = () => {
    this.props.reset();
    this.props.onCancel();
  };

  render() {
    const {handleSubmit, lang} = this.props;

    return (
      <Modal
        show={this.props.show}
        className="theme-color-8 map-items-modal"
        onClose={this.handleClose}
        title={lang.TITLE}
      >
        <form onSubmit={handleSubmit(this.save)}>
          <FieldArray name={`map_items.${this.props.interfaceItemIndex}`} component={this.renderItems} />
          <div className="map-items-modal__buttons">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={this.handleClose}>
              {lang.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-7 modal-window__footer-btn" type="submit">
              {lang.SAVE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const MapItemsFormModal = reduxForm({
  form: MapItemsModal.formName,
  reset,
  enableReinitialize: true,
})(MapItemsModal);

export default connect((state, ownProps) => {
  const mapItemsSelector = formValueSelector(MapItemsModal.formName);
  const parentFormSelector = formValueSelector(ownProps.parentFormName);
  return {
    lang: state.languageState.payload.AUTOTASK.MAP_ITEMS_MODAL,
    langDbNames: state.languageState.payload.DATABASES,
    optIns: selectOptInsForMapping(state),
    leadColumns: selectColumns(state, 'Lead'),
    operationColumns: selectColumns(state, 'OperationDatum'),
    visual1Columns: selectColumns(state, 'Visual1'),
    visual2Columns: selectColumns(state, 'Visual2'),
    visual3Columns: selectColumns(state, 'Visual3'),
    win1Columns: selectColumns(state, 'ParticipationPrize1'),
    win2Columns: selectColumns(state, 'ParticipationPrize2'),
    win3Columns: selectColumns(state, 'ParticipationPrize3'),
    formValues: mapItemsSelector(state, 'map_items'),
    initialValues: {
      map_items: ownProps.mappingItems || [],
    },
    interfaceItem: parentFormSelector(state, 'items')
      ? parentFormSelector(state, 'items')[ownProps.interfaceItemIndex]
      : {},
    scenarios: selectScenariosForMappingItems(state),
  };
})(MapItemsFormModal);
