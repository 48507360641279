import React, {useMemo} from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import ContentSkeleton from './content-skeleton';

import {Section} from '../blocks/section';
import {ROOT_PLACEHOLDER_ID} from '../constants';
import {useEditor} from '../hooks/useEditor';
import {useSortableItems} from '../hooks/useSortableItems';
import {RootLeveElement} from '../types';

import cssModule from './content.module.scss';

const b = bem('content', {cssModule});
const placeholderId = ROOT_PLACEHOLDER_ID;

type ContentProps = {
  loading?: boolean;
};

export const Content: React.FC<ContentProps> = ({loading}) => {
  const {availableBlocks, doc, addSection, dndData, moveSection} = useEditor();
  const allowedTypes = useMemo(() => [...availableBlocks.layout.map((el) => el.type), 'section'], [availableBlocks]);
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.CONTENT');

  const {sortableItems, containerRef, hoveredElId, isDragOver, onDragEnter, onDragOver, onDragLeave, onDrop} =
    useSortableItems<RootLeveElement>(doc.body, {
      placeholderId,
      allowedTypes,
    });

  const handleDrop = () => {
    onDrop();

    const {id, type} = dndData;

    if (!type || !allowedTypes.includes(type)) {
      return;
    }

    if (id === placeholderId) {
      addSection(type, hoveredElId);
    } else {
      moveSection(id, hoveredElId);
    }
  };

  if (loading) {
    return (
      <div className={b()}>
        <ContentSkeleton />
      </div>
    );
  }

  return (
    <div
      className={b()}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={handleDrop}
    >
      <div id="email-template-container" className={b('body-container')} ref={containerRef}>
        {sortableItems.map((el) => (
          <Section key={el.id} section={el} canDrop={dndData?.id === el.id && isDragOver} />
        ))}
        {!sortableItems.length && <span className={b('empty-template-placeholder')}>{lang.EMPTY_STATE}</span>}
      </div>
    </div>
  );
};
