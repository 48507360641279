import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {getPercent} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import InstoreDonutChart from 'client/components/instore/charts/instore-donut-chart';

import {getDonutChartData} from './helpers';

import cssModule from './instore-planning-donut-chart.module.scss';

const b = bem('instore-planning-donut-chart', {cssModule});

const InstorePlanningDonutChart = ({data}) => {
  const lang = useLanguage('INSTORE_TASK.CHARTS.INSTORE_DONUT_CHART');
  const langStatusRoad = useLanguage('ANIMATIONS.STATUS_ROAD');

  const graphName = Object.keys(data)[0];
  const {chartData, hasSecondValue} = getDonutChartData(data[graphName], langStatusRoad);

  const getSecondPercent = ({value, secondValue}) => getPercent(secondValue, value);

  return (
    <div className={cn('pie-chart-diagram', b())}>
      <div className={b('chart')}>
        <InstoreDonutChart
          data={chartData}
          title={lang[graphName.toUpperCase()]}
          secondValueTitle={hasSecondValue ? lang.QUOTA : ''}
          getSecondPercent={getSecondPercent}
          legendOnRight={true}
          legendMaxHeight={200}
          width={108}
          height={108}
        />
      </div>
    </div>
  );
};

InstorePlanningDonutChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default InstorePlanningDonutChart;
