import React, {useCallback} from 'react';

import {useSortable} from '@dnd-kit/sortable';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';

import {PRIZE_TYPES} from '../../prize-form/constants';

const b = bem('prizes-table');

const PrizesTableCol = ({
  fieldName,
  colKey,
  order,
  index,
  prize,
  onSelectPrize,
  onDelete,
  onActivate,
  disabled,
  disabledActivation,
  disabledDrag,
}) => {
  const {listeners, attributes} = useSortable({
    id: prize?.id,
  });

  const deleteHandler = useCallback(
    (e) => {
      e.preventDefault();
      onDelete(prize.id);
    },
    [onDelete, prize.id],
  );

  const activateHandler = useCallback(
    (e) => {
      e.preventDefault();
      onActivate(prize.id, !prize.active);
    },
    [onActivate, prize.active, prize.id],
  );

  switch (colKey) {
    case 'order':
      return (
        <>
          {!disabledDrag && <Icon name="sandwich" {...listeners} {...attributes} />}
          <span className={b('order-text')}>{order}</span>
        </>
      );
    case 'name':
      return (
        <button type="button" onClick={() => onSelectPrize(index)} className={b('selected-button')} disabled={disabled}>
          {get(prize, fieldName)}
        </button>
      );
    case 'delete':
      const hasWins = Boolean(
        prize?.total_quantities?.total_prize_won_non_validated || prize?.total_quantities?.total_prize_won_validated,
      );
      return (
        <button type="button" className={b('delete')} onClick={deleteHandler} disabled={disabled || hasWins}>
          <Icon name="trash" width={17} height={19} />
        </button>
      );
    case 'active':
      return (
        <button
          type="button"
          className={b('active')}
          onClick={activateHandler}
          disabled={disabled || disabledActivation}
        >
          <Icon name={prize.active ? 'clear' : 'big-plus'} />
        </button>
      );
    case 'coupon':
      const hasCoupon = get(prize, fieldName) === true || get(prize, fieldName) === PRIZE_TYPES.COUPON;
      return hasCoupon ? <Icon name="barcode" /> : <div />;
    default:
      return <div>{get(prize, fieldName, '')}</div>;
  }
};

PrizesTableCol.propTypes = {
  fieldName: PropTypes.string.isRequired,
  colKey: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  prize: PropTypes.object.isRequired,
  onSelectPrize: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  disabledActivation: PropTypes.bool,
  disabledDrag: PropTypes.bool,
};

export default PrizesTableCol;
