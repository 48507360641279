import update from 'immutability-helper';
import defaultsDeep from 'lodash/defaultsDeep';
import get from 'lodash/get';

import {operatorOptions, conditionOperatorOptions, valueKindOptions, VALUE_KIND, THEN_TYPE} from './constants';

import {filterScenarios, compactObject} from '../helpers';

export const createCondition = (variables) => ({
  variable_id: get(variables, '[0].id', null),
  operator: conditionOperatorOptions[0].value,
  value_kind: valueKindOptions[0].value,
  value: 1,
  value_variable_id: get(variables, '[1].id', null),
});

export const createImplementation = (variables) => ({
  branch_scenario_step_conditions: [createCondition(variables)],
});

export const getScenarios = (state) => {
  const {
    scenario: {
      payload: {
        id,
        automation_task: {scenarios},
      },
    },
  } = state;

  return filterScenarios(scenarios, id);
};

export const getScenarioVariables = (state) => {
  const {
    scenario: {
      payload: {scenario_variables, scenario_step_variables = []},
    },
  } = state;

  return scenario_variables.concat(scenario_step_variables);
};

export const getInitialValues = (data, state) => {
  return defaultsDeep({}, compactObject(data), {
    thenType: get(data, 'implementation.scenario_id', false) ? THEN_TYPE.goto : THEN_TYPE.end,
    thenGoToContinue: get(data, 'implementation.continue', false),
    implementation: {
      operator: get(operatorOptions, '[0].value', null),
      scenario_id: get(getScenarios(state), '[0].id', null),
    },
  });
};

const transformFormData = (values) => {
  let responce = update(values, {
    $unset: ['thenType'],
    implementation: {
      scenario_id: (value) => (values.thenType === THEN_TYPE.end ? null : value),
      branch_scenario_step_conditions: (conditions) =>
        conditions.map((condition) => {
          return update(condition, {
            value: (value) => (condition.value_kind === VALUE_KIND.scalar ? value : null),
            value_variable_id: (value) => (condition.value_kind === VALUE_KIND.variable ? value : null),
          });
        }),
    },
  });
  responce.implementation.continue = values.thenType === THEN_TYPE.end ? false : values.thenGoToContinue;
  return responce;
};

export const submitForm = (values, dispatch, props) => {
  const transformedValues = transformFormData(values);

  return props.onSubmitForm(transformedValues);
};
