import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import ClientTable from 'client/components/common/client-table';

import DatetimeCell from 'client/components/tables/common/cells/datetime-cell';

import './leads-histories.scss';

const b = bem('leads-histories');

class LeadHistories extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    className: PropTypes.string,
  };

  static colUpdate = 'update';

  constructor(props) {
    super(props);
    const {lang} = this.props;

    this.columns = [
      {
        name: 'created_at',
        label: lang.COLUMNS.DATE,
        path: 'created_at',
        render: DatetimeCell,
        width: '90px',
      },
      {
        name: 'kind',
        label: lang.COLUMNS.TYPE,
        path: 'kind',
        render: this.renderType,
        width: '130px',
      },
      {
        name: 'source',
        label: lang.COLUMNS.LINKS,
        path: 'source.name',
        width: '90px',
      },
      {
        name: 'data_modified',
        label: lang.COLUMNS.DATA_MODIFIED,
        path: 'data_modified',
        render: this.renderDataModified,
        width: '255px',
      },
    ];
  }

  renderType = ({item}) => {
    const text = item.kind === LeadHistories.colUpdate ? this.props.lang.FILTERS_MODAL_EDIT_RADIO : item.kind;
    return <span className={b('text')}>{text.toUpperCase()}</span>;
  };

  renderDataModified = ({item}) => {
    return <span className={b('text')}>{item.data_modified.map((i) => i + ' ')}</span>;
  };

  render() {
    const {data, className, lang} = this.props;

    return (
      <div className={cn(b(), className)}>
        <h3 className={b('title')}>{lang.TITLE}</h3>
        <ClientTable
          className={b('table', {'has-data': Boolean(data?.length)})}
          columns={this.columns}
          data={data}
          noDataText={lang.NO_DATA}
        />
      </div>
    );
  }
}

export default connect((state) => ({
  lang: state.languageState.payload.DATA_LEAD.MODAL.LEADS_HISTORIES,
}))(LeadHistories);
