import {DEFAULT_PASSWORD_EXPIRATION, UserPasswordExpirationConfig} from 'client/ducks/user-password-expiration/reducer';

export type PasswordExpirationFormData = {
  full: boolean;
  regions: boolean;
  places: boolean;
  local: boolean;
};

export const initialFormValues = (config: UserPasswordExpirationConfig) => {
  return {
    full: config?.full > 0,
    regions: config?.regions > 0,
    places: config?.places > 0,
    local: config?.local > 0,
  };
};

export const mapFormValues = (values: PasswordExpirationFormData, clientId: string) => {
  return {
    user_password_expiration_config: {
      client_id: clientId,
      full: values.full ? DEFAULT_PASSWORD_EXPIRATION : null,
      regions: values.regions ? DEFAULT_PASSWORD_EXPIRATION : null,
      places: values.places ? DEFAULT_PASSWORD_EXPIRATION : null,
      local: values.local ? DEFAULT_PASSWORD_EXPIRATION : null,
    },
  };
};
