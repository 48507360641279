import React, {PureComponent} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import SearchInput from 'client/common/inputs/search-input';

import Icon from 'client/components/common/icon';

import PerPage from 'client/components/tables/common/per-page';
import ResultsFound from 'client/components/tables/common/results-found';

import VisualsFiltersModal from '../visuals-filters-modal';
import VisualsSlider from '../visuals-slider';
import VisualsSwitcher from '../visuals-switcher';

import cssModule from './visuals-toolbar.module.scss';

const b = bem('visuals-toolbar', {cssModule});

class VisualsToolbar extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    onParamsChange: PropTypes.func.isRequired,
    gridIncrement: PropTypes.number.isRequired,
    onGridIncrementChange: PropTypes.func.isRequired,
    totalCount: PropTypes.number.isRequired,
    accessLevel: PropTypes.string.isRequired,
    isGrid: PropTypes.bool.isRequired,
    onIsGridChange: PropTypes.func.isRequired,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      showFiltersModal: false,
    };
  }

  toggleFiltersModal = () => {
    this.setState(({showFiltersModal}) => ({showFiltersModal: !showFiltersModal}));
  };

  renderFiltersModal = () => {
    const {onParamsChange, accessLevel, totalCount, params} = this.props;

    return (
      <VisualsFiltersModal
        filters={params.filters}
        onClose={this.toggleFiltersModal}
        onFiltersChange={onParamsChange}
        accessLevel={accessLevel}
        totalCount={totalCount}
      />
    );
  };

  render() {
    const {
      params,
      onParamsChange,
      totalCount,
      lang,
      onGridIncrementChange,
      isGrid,
      onIsGridChange,
      gridIncrement,
      className,
    } = this.props;
    const {showFiltersModal} = this.state;

    return (
      <div className={cn(b(), className)}>
        <div className={b('left')}>
          <SearchInput
            className={b('search-input')}
            onSearch={(value) => onParamsChange({search: value, page: 1})}
            searchDefault={params.search}
          />
          {showFiltersModal && this.renderFiltersModal()}
          <button className={b('filters-button')} onClick={this.toggleFiltersModal}>
            <Icon name="filters" className={b('filters-button-icon')} />
            {lang.FILTERS}
          </button>
          <ResultsFound value={totalCount} />
        </div>
        <div className={b('right')}>
          {isGrid && <VisualsSlider className={b('slider')} value={gridIncrement} onChange={onGridIncrementChange} />}
          <VisualsSwitcher isGrid={isGrid} onIsGridChange={onIsGridChange} />
          <PerPage currentValue={params.perPage} onChange={onParamsChange} />
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  lang: state.languageState.payload.CLIENT_VISUALS,
}))(VisualsToolbar);
