import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';

import './hashtag.scss';

class Hashtag extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    textClassName: PropTypes.string,
    btnClassName: PropTypes.string,
    icon: PropTypes.any,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
    theme: PropTypes.bool,
    editable: PropTypes.bool,
    appendSharp: PropTypes.bool,
    deletable: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    children: '',
    name: '',
    textClassName: '',
    btnClassName: '',
    icon: null,
    onClick: () => {},
    onDelete: () => {},
    onChange: () => {},
    theme: true,
    editable: false,
    appendSharp: false,
    deletable: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      inputValue: props.children,
    };
  }

  componentDidMount() {
    if (this.props.children === '') {
      // tag was added right now
      this.enableEditMode();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children) {
      this.setState({inputValue: this.props.children});
    }
  }

  enableEditMode = () => {
    this.setState({edit: true});
    if (!this.props.editable) {
      return;
    }

    const intervalID = setInterval(() => {
      if (this.input) {
        clearInterval(intervalID);
        this.input.focus();
      }
    }, 100);
  };

  saveChanges = (value) => {
    this.setState({edit: false});
    this.props.onChange(this.props.name, value);
  };

  render() {
    const {
      className,
      textClassName,
      btnClassName,
      children,
      icon,
      name,
      onClick,
      onDelete,
      theme,
      editable,
      appendSharp,
      deletable,
    } = this.props;

    const cssClass = classNames({
      hashtag: true,
      'hashtag--theme': theme,
    });

    return (
      <div data-hashtag-name={name} className={`${cssClass} ${className}`} onClick={() => onClick(name)}>
        {appendSharp && <span className="hashtag__sharp">#</span>}
        {editable && this.state.edit ? (
          <input
            onBlur={({target}) => this.saveChanges(target.value)}
            onKeyPress={(e) => e.key === 'Enter' && this.saveChanges(e.target.value)}
            className="hashtag__input"
            ref={(i) => {
              this.input = i;
            }}
            maxLength={255}
            value={this.state.inputValue}
            onChange={({target}) => this.setState({inputValue: target.value})}
          />
        ) : (
          <span className={`hashtag__text ${textClassName}`} onClick={this.enableEditMode}>
            {children}
          </span>
        )}
        {deletable && (
          <span
            data-hashtag-delete={name}
            className={`hashtag__del-btn ${btnClassName}`}
            onClick={() => onDelete(name)}
          >
            {icon ? icon : <Icon name="clear" />}
          </span>
        )}
      </div>
    );
  }
}

export default Hashtag;
