import React, {Component} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {selectTaskBaseInfo} from 'client/ducks/message-email-sms-task/selectors';

import {MESSAGE_TASK_STATES} from 'client/common/config';

import {TASK_TYPES} from 'client/models/operations/constants';

import BCHeader from './components/bc-header';
import BCTaskData from './components/bc-task-data';
import BroadcastColumn from './components/columns/broadcast-column';
import ClicksCountColumn from './components/columns/clicks-count-column';
import OpenStatusColumn from './components/columns/open-status-column';
import ResultsColumn from './components/columns/results-column';
import StateStatusColumn from './components/columns/state-status-column';
import TaskColumn from './components/columns/task-column';
import TemplateColumn from './components/columns/template-column';

import './bc-list.scss';

class BCList extends Component {
  static propTypes = {
    className: PropTypes.string,
    messageTask: PropTypes.object.isRequired,
    onClicksCountClick: PropTypes.func.isRequired,
    onToggleMessageTaskState: PropTypes.func.isRequired,
    onToggleWebhooks: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super(props);
    const taskLang = props.languageState.payload.BROADCASTING_TASK;

    this.LANGUAGE = {
      TASK_LIST: taskLang.TASK_LIST,
      POPOVER: taskLang.STATUS_POPOVER,
    };

    this.dataMap = [
      {
        name: this.LANGUAGE.TASK_LIST.BROADCASTING_COLUMN,
        forTaskType: [TASK_TYPES.EMAIL, TASK_TYPES.SMS],
        renderMethod: this.renderTaskLink,
      },
      {
        name: this.LANGUAGE.TASK_LIST.MAILING_COLUMN,
        forTaskType: [TASK_TYPES.EMAIL, TASK_TYPES.SMS],
        renderMethod: this.renderBroadcastLink,
      },
      {
        name: this.LANGUAGE.TASK_LIST.TEMPLATE_COLUMN,
        forTaskType: [TASK_TYPES.EMAIL],
        renderMethod: this.renderEmailTemplate,
      },
      {
        name: this.LANGUAGE.TASK_LIST.LEADS_COLUMN,
        forTaskType: [TASK_TYPES.EMAIL, TASK_TYPES.SMS],
        renderMethod: this.renderLeadsCount,
      },
      {
        name: this.LANGUAGE.TASK_LIST.SENT_TO_COLUMN,
        forTaskType: [TASK_TYPES.EMAIL],
        renderMethod: this.renderSentTo,
      },
      {
        name: this.LANGUAGE.TASK_LIST.SENT_BY_COLUMN,
        forTaskType: [TASK_TYPES.EMAIL],
        renderMethod: this.renderSentBy,
      },
      {
        name: this.LANGUAGE.TASK_LIST.SENT_TO_TWILIO_COLUMN,
        forTaskType: [TASK_TYPES.SMS],
        renderMethod: this.renderSentTo,
      },
      {
        name: this.LANGUAGE.TASK_LIST.SENT_BY_TWILIO_COLUMN,
        forTaskType: [TASK_TYPES.SMS],
        renderMethod: this.renderSentBy,
      },
      {
        name: this.LANGUAGE.TASK_LIST.OPEN_COLUMN,
        forTaskType: [TASK_TYPES.EMAIL],
        renderMethod: this.renderOpen,
      },
      {
        name: this.LANGUAGE.TASK_LIST.CLICKS_COLUMN,
        forTaskType: [TASK_TYPES.EMAIL],
        renderMethod: this.renderClicksCount,
      },
      {
        name: this.LANGUAGE.TASK_LIST.DELIVERED_COLUMN,
        forTaskType: [TASK_TYPES.SMS],
        renderMethod: this.renderDelivered,
      },
      {
        name: this.LANGUAGE.TASK_LIST.STATUS_1_COLUMN,
        forTaskType: [TASK_TYPES.EMAIL, TASK_TYPES.SMS],
        renderMethod: this.renderStatus1,
      },
      {
        name: this.LANGUAGE.TASK_LIST.STATUS_2_COLUMN,
        forTaskType: [TASK_TYPES.EMAIL, TASK_TYPES.SMS],
        renderMethod: this.renderStatus2,
      },
      {
        name: '',
        forTaskType: [TASK_TYPES.EMAIL, TASK_TYPES.SMS],
        renderMethod: this.renderResults,
      },
    ];
  }

  renderSentTo = () => this.props.messageTask.sentTo;
  renderSentBy = () => this.props.messageTask.sentBy;
  renderOpen = () => this.props.messageTask.open;
  renderDelivered = () => this.props.messageTask.delivered;
  renderTaskLink = () => <TaskColumn {...this.props} />;
  renderBroadcastLink = () => <BroadcastColumn {...this.props} />;

  renderLeadsCount = () => {
    const {broadcastRecipientsCount, recipientsCount, taskState} = this.props.messageTask;
    return taskState === MESSAGE_TASK_STATES.NEW ? broadcastRecipientsCount : recipientsCount;
  };

  renderEmailTemplate = () => {
    const {
      messageTask: {emailTemplate},
    } = this.props;
    return <TemplateColumn emailTemplate={emailTemplate} idText={this.LANGUAGE.TASK_LIST.ID_LABEL} />;
  };

  renderResults = () => {
    return <ResultsColumn linkText={this.LANGUAGE.TASK_LIST.SEE_RESULTS_LABEL} {...this.props} />;
  };

  renderClicksCount = () => {
    const {
      messageTask: {clicksCount},
      onClicksCountClick,
    } = this.props;
    return <ClicksCountColumn clicksCount={clicksCount} onClicksCountClick={onClicksCountClick} />;
  };

  renderStatus1 = () => {
    const {messageTask, languageState} = this.props;
    return <StateStatusColumn {...messageTask} languageState={languageState} />;
  };

  renderStatus2 = () => {
    const {messageTask, languageState} = this.props;
    return (
      <OpenStatusColumn
        taskType={messageTask.taskType}
        isWebhooksOpened={messageTask.isWebhooksOpened}
        languageState={languageState}
      />
    );
  };

  render() {
    const {className, messageTask} = this.props;

    const cssClass = classNames({
      'bc-list': true,
      'content-panel': true,
      'bc-list--sms': messageTask.taskType === TASK_TYPES.SMS,
    });

    return (
      <div className={`${cssClass} ${className}`}>
        <BCHeader headers={this.dataMap} taskType={messageTask.taskType} />
        <BCTaskData data={this.dataMap} taskType={messageTask.taskType} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  messageTask: selectTaskBaseInfo(state),
});

export default connect(mapStateToProps)(BCList);
