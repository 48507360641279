import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect, withRouter} from 'react-router-dom';

import {getIsNational, getIsClientAdmin} from 'client/services/cookie-data-source';

import {selectCurrentOrganization} from 'client/ducks/user-clients/selectors';
import {selectIsAdmin, selectIsUserLoaded} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import Company from '../components/client-agency/company';

class CompanyContainer extends Component {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    ...withRouter.propTypes,
    company: PropTypes.shape({
      company: PropTypes.object,
    }),
    currentOrganization: PropTypes.object.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  componentDidUpdate(prevProps) {
    const {
      company: {company: oldCompany},
    } = prevProps;
    const {
      company: {company: newCompany},
      setMetaAction,
    } = this.props;

    const oldName = oldCompany && oldCompany.name;
    const newName = newCompany && newCompany.name;

    if (newName !== oldName) {
      setMetaAction({
        title: newName,
      });
    }
  }

  render() {
    const {
      isAdmin,
      isUserLoaded,
      currentOrganization,
      match: {
        params: {id},
      },
    } = this.props;

    const orgId = +id;

    if (!isUserLoaded) {
      return null;
    }

    if (
      !isAdmin &&
      !getIsClientAdmin() &&
      (!getIsNational() || (currentOrganization.id && currentOrganization.id !== orgId))
    ) {
      return <Redirect to={CLIENT_PAGES.HOME} />;
    }

    return <Company id={orgId} isNational={getIsNational()} history={this.props.history} />;
  }
}

const mapStateToProps = (state) => ({
  company: state.company,
  isAdmin: selectIsAdmin(state),
  isUserLoaded: selectIsUserLoaded(state),
  currentOrganization: selectCurrentOrganization(state),
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyContainer);
