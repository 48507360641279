import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getColumnVisibilityItems(taskId) {
  const params = {
    include: {
      column_adapter: {
        column: null,
      },
    },
    q: {
      automation_task_id_eq: taskId,
      s: 'position asc',
    },
  };
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        queryParams: params,
        endpoint: `${API_PATH}${API_METHODS.COLUMN_VISIBILITY_ITEMS}`,
        method: 'GET',
        types: [
          types.GET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_REQUEST,
          types.GET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_SUCCESS,
          types.GET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_ERROR,
        ],
      },
      true,
    );
  };
}

export function updateColumnVisibilityItems(body = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.COLUMN_VISIBILITY_ITEMS}${API_METHODS.BULK_ACTION}`,
        method: 'PATCH',
        types: [
          types.SET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_REQUEST,
          types.SET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_SUCCESS,
          types.SET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_ERROR,
        ],
        body,
      },
      true,
    );
  };
}

export function deleteColumnVisibilityItem(id) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.COLUMN_VISIBILITY_ITEMS}/${id}`,
        method: 'DELETE',
        types: [
          types.DELETE_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_REQUEST,
          types.DELETE_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_SUCCESS,
          types.DELETE_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_ERROR,
        ],
      },
      true,
    );
  };
}

export function addColumnVisibilityItem(body = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.COLUMN_VISIBILITY_ITEMS}`,
        method: 'POST',
        types: [
          types.ADD_PARTICIPATION_CLIENT_VISIBILITY_ITEM_REQUEST,
          types.ADD_PARTICIPATION_CLIENT_VISIBILITY_ITEM_SUCCESS,
          types.ADD_PARTICIPATION_CLIENT_VISIBILITY_ITEM_ERROR,
        ],
        body,
      },
      true,
    );
  };
}

export function getDisplayColumns(taskId) {
  const params = {
    include: {
      column_adapter: {
        column: null,
      },
    },
    q: {
      automation_task_id_eq: taskId,
      s: 'position asc',
    },
  };

  return (dispatch) => {
    return callApi(
      dispatch,
      {
        queryParams: params,
        endpoint: `${API_PATH}${API_METHODS.PARTICIPATION_DISPLAY_ITEMS}`,
        method: 'GET',
        noCache: true,
        types: [
          types.GET_PARTICIPATION_DISPLAY_ITEM_REQUEST,
          types.GET_PARTICIPATION_DISPLAY_ITEM_SUCCESS,
          types.GET_PARTICIPATION_DISPLAY_ITEM_ERROR,
        ],
      },
      true,
    );
  };
}

export function updateDisplayColumns(body, userId) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.USERS}/${userId}`,
        method: 'PUT',
        types: [
          types.GET_PARTICIPATION_DISPLAY_ITEM_REQUEST,
          types.GET_PARTICIPATION_DISPLAY_ITEM_SUCCESS,
          types.GET_PARTICIPATION_DISPLAY_ITEM_ERROR,
        ],
        body,
      },
      true,
    );
  };
}

export function deleteDisplayColumn(id) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.PARTICIPATION_DISPLAY_ITEMS}/${id}`,
        method: 'DELETE',
        types: [
          types.DELETE_PARTICIPATION_DISPLAY_ITEM_REQUEST,
          types.DELETE_PARTICIPATION_DISPLAY_ITEM_SUCCESS,
          types.DELETE_PARTICIPATION_DISPLAY_ITEM_ERROR,
        ],
      },
      true,
    );
  };
}

export function deleteDisplayColumns(queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.PARTICIPATION_DISPLAY_ITEMS}`,
        queryParams,
        method: 'DELETE',
        types: [
          types.DELETE_PARTICIPATION_DISPLAY_ITEMS_REQUEST,
          types.DELETE_PARTICIPATION_DISPLAY_ITEMS_SUCCESS,
          types.DELETE_PARTICIPATION_DISPLAY_ITEMS_ERROR,
        ],
      },
      true,
    );
  };
}

export function updateColumnPosition(id, items) {
  return (dispatch) => {
    const body = {user: {display_items: items}};

    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.USERS}/${id}`,
        method: 'PUT',
        types: [
          types.DELETE_PARTICIPATION_DISPLAY_ITEM_REQUEST,
          types.DELETE_PARTICIPATION_DISPLAY_ITEM_SUCCESS,
          types.DELETE_PARTICIPATION_DISPLAY_ITEM_ERROR,
        ],
        body,
      },
      true,
    );
  };
}

export function updateLeadDisplayItemsBulk(body, queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PARTICIPATION_DISPLAY_ITEMS}${API_METHODS.BULK_ACTION}`,
      method: 'PATCH',
      types: [
        types.UPDATE_PARTICIPATION_DISPLAY_ITEMS_BULK_REQUEST,
        types.UPDATE_PARTICIPATION_DISPLAY_ITEMS_BULK_SUCCESS,
        types.UPDATE_PARTICIPATION_DISPLAY_ITEMS_BULK_ERROR,
      ],
      queryParams,
      body,
    });
  };
}
