import React from 'react';

import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import {useMount} from 'react-use';
import {reduxForm} from 'redux-form';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import AppButton from 'client/common/buttons';
import {TextField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import validate from './validate';

const b = bem('translations-modal');

const TranslationsItemModal = (props) => {
  const {translationsItem, onSubmit, onClose, handleSubmit} = props;
  const lang = useLanguage('TRANSLATIONS.FORM');
  const isLoading = useSelector((state) => state.translations.isLoading);
  const {initialize, valid} = useReduxForm(TranslationsItemModal.formName);

  useMount(() => {
    const {id, value} = translationsItem || {};
    initialize({
      id,
      value,
    });
  });

  if (!translationsItem) {
    return null;
  }

  return (
    <Modal title={translationsItem.id ? lang.TITLE_EDIT : lang.TITLE_ADD} onClose={onClose}>
      <div className={b()}>
        <form className={b('form')} onSubmit={handleSubmit(onSubmit)}>
          <div className={b('form-body')}>
            <TextField className={b('text-field')} name="value" label={lang.TRANSLATION_LABEL} />
          </div>
          {translationsItem.error && <div className={b('form-error')}>{translationsItem.error}</div>}
          <div className={b('form-buttons')}>
            <AppButton label={lang.SAVE} loading={isLoading} submit={true} disabled={!valid} />
            <AppButton label={lang.CANCEL} onClick={onClose} transparent={true} />
          </div>
        </form>
      </div>
    </Modal>
  );
};

TranslationsItemModal.formName = 'TranslationsItemForm';

TranslationsItemModal.propTypes = {
  translationsItem: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

TranslationsItemModal.defaultProps = {
  translationsItem: null,
};

const TranslationsItemModalForm = reduxForm({
  form: TranslationsItemModal.formName,
  shouldValidate: () => true,
  validate,
})(TranslationsItemModal);

export default connect((state) => ({
  // To use in validate function
  langErrors: state.languageState.payload.TRANSLATIONS.ERRORS,
}))(TranslationsItemModalForm);
