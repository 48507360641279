import React from 'react';

import cn from 'classnames';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons';

import SelectEmailTemplateModal from '../index';

import cssModule from './select-email-template-modal-container.module.scss';

type SelectEmailTemplateModalContainerProps = {
  className?: string;
  title: string;
  buttonLabel: string;
};

const b = bem('select-email-template-modal-container', {cssModule});

const SelectEmailTemplateModalContainer: React.FC<SelectEmailTemplateModalContainerProps> = ({
  title,
  buttonLabel,
  className,
}) => {
  const [opened, toggleOpened] = useToggle(false);

  return (
    <>
      <div className={b()}>
        <span className={b('title')}>{title}</span>
        <AppButton
          className={cn(b('button'), className)}
          onClick={toggleOpened}
          color="white"
          label={buttonLabel?.toUpperCase()}
        />
      </div>

      {opened && <SelectEmailTemplateModal onClose={toggleOpened} />}
    </>
  );
};

export default SelectEmailTemplateModalContainer;
