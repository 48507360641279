import React, {useMemo, useState, useEffect} from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectOperation} from 'client/ducks/operations/selectors';

import Icon from 'client/common/icon';
import {CheckList} from 'client/common/inputs';
import Popover from 'client/common/popovers/popover';

import {ACCESS_LEVEL_ICONS, ACCESS_LEVEL_OPTIONS} from 'client/models/common/constants';
import {Translation} from 'client/models/language/types';

import cssModule from './diy-op-access-level-popover.module.scss';

const b = bem('diy-op-access-level-popover', {cssModule});

type DiyOpAccessLevelPopoverProps = {
  access: string;
  isPopoverShown?: boolean;
  setIsPopoverShown?: (values: boolean) => void;
  title?: Translation;
  description?: Translation;
  onChange: (values: string) => void;
};

const DiyOpAccessLevelPopover: React.FC<DiyOpAccessLevelPopoverProps> = (props) => {
  const {title, description, onChange, access, setIsPopoverShown, isPopoverShown} = props;

  const lang = useLanguage('DIY_OPERATION.POPOVERS.DIY_OP_ACCESS_LEVEL_POPOVER');

  const {client_interface} = useSelector(selectOperation);

  const user_access_levels = client_interface?.templates?.[0]?.user_access_levels;

  const [prevAccess, setPrevAccess] = useState(access);
  const [checked, setChecked] = useState<string[]>([access]);

  useEffect(() => {
    if (access !== prevAccess) {
      setChecked([access]);
    }

    setPrevAccess(access);
  }, [access, checked, prevAccess]);

  const accessLevelOptions = useMemo(() => {
    const userAccessLevels = ['national', ...(user_access_levels || [])];

    return Object.entries(ACCESS_LEVEL_OPTIONS)
      .filter(([, label]) => userAccessLevels.includes(label))
      .map(([value, label]) => ({
        value: label,
        label: (
          <p className={b('label')} key={value}>
            <Icon name={ACCESS_LEVEL_ICONS[label]} />
            <span>{lang[value as keyof typeof lang]}</span>
          </p>
        ),
      }));
  }, [lang, user_access_levels]);

  const overlay = () => {
    return (
      <div className={b('content')}>
        {title && <p className={b('title')}>{title}</p>}
        {description && <p className={b('description')}>{description} </p>}
        <CheckList
          options={accessLevelOptions}
          asLabel={true}
          singleCheck={true}
          onChange={(value) => {
            setChecked(value);
            onChange(value[0]); // due to <CheckList/> component and prop singleCheck
          }}
          checked={checked}
        />
      </div>
    );
  };

  return (
    <Popover
      className={b()}
      shiftTop={2}
      position="bottom"
      overlayInnerStyle={{width: 340}}
      overlay={overlay}
      flipOptions={{mainAxis: true}}
      setIsPopoverShown={setIsPopoverShown}
      show={isPopoverShown}
    >
      <Icon
        className={b('icon')}
        name={ACCESS_LEVEL_ICONS[checked[0] as keyof typeof ACCESS_LEVEL_ICONS]}
        width={20}
        height={20}
      />
    </Popover>
  );
};

export default DiyOpAccessLevelPopover;
