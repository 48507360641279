import React, {Component} from 'react';

import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, getFormValues, reduxForm} from 'redux-form';

import {patch, post} from 'client/services/fetch';

import {ACCESS_LEVEL_TYPES} from 'client/ducks/client-users/constants';
import {selectCompany} from 'client/ducks/company/selectors';

import {API_METHODS} from 'client/common/config';
import {RadioGroupField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

class NewUserStep3Modal extends Component {
  static validate({phone}, {lang = {}} = {}) {
    const errors = {};
    const regex = /^[+0-9][0-9]*$/;

    if (errors.phone && !regex.test(phone)) {
      errors.phone = lang.PHONE_IS_INVALID_ERROR;
    }

    return errors;
  }

  static updateSyncErrors(values) {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'NewUserStep3Form',
      },
      payload: {
        syncErrors: NewUserStep3Modal.validate(values),
      },
    };
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.intervalID = setInterval(() => {
      if (!isEmpty(this.props.lang)) {
        clearInterval(this.intervalID);
        this.props.updateErrors({});
      }
    }, 200);
  }

  componentDidUpdate(prevProps) {
    const {user, initialize, updateErrors} = this.props;

    if (user !== prevProps.user) {
      const membership = (user && user.memberships && user.memberships[0]) || user;

      if (!membership || !membership.access_level) {
        initialize({access_level: 'full'});
        updateErrors({});
      } else {
        initialize(membership);
        updateErrors(membership);
      }
    }
  }

  save = ({phone, title, access_level}) => {
    const memberships = [
      {
        type: 'CompanyMembership',
        title,
        phone,
        access_level,
        client_id: this.props.clientId,
      },
    ];
    const isUserExist = !isUndefined(this.props.initialValues.id);
    const {onCreate, editingUser} = this.props;

    switch (access_level) {
      case ACCESS_LEVEL_TYPES.NATIONAL:
      case ACCESS_LEVEL_TYPES.CLIENT_ADMIN:
        if (editingUser) {
          onCreate({
            showEditCompanyAccessModal: 0,
            showEditUserModal: true,
            editingUser: {
              ...this.props.user,
              access_level,
            },
          });
          break;
        }

        const fullAccessNewUserData = {
          client_user: {
            ...this.props.user,
            memberships,
            invited_by_id: this.props.adminId,
          },
        };
        if (isUserExist) {
          patch(`${API_METHODS.CLIENT_USERS}/${this.props.initialValues.id}`, fullAccessNewUserData).then(() =>
            onCreate(),
          );
        } else {
          post(API_METHODS.CLIENT_USERS, fullAccessNewUserData).then(() => onCreate());
        }
        break;

      case ACCESS_LEVEL_TYPES.STORES:
        if (editingUser) {
          onCreate({
            showEditCompanyAccessModal: 3,
            editingUser: {
              ...this.props.user,
              memberships: [find(this.props.clientUser.memberships, (i) => i.client_id === this.props.clientId)],
            },
          });
          break;
        }

        onCreate({
          addNewUserStep: 32,
          addNewUserObject: {
            ...this.props.user,
            memberships,
          },
        });
        break;

      case ACCESS_LEVEL_TYPES.REGIONS:
      case ACCESS_LEVEL_TYPES.LOCAL:
        if (editingUser) {
          onCreate({
            showEditCompanyAccessModal: 2,
            editingUser: {
              ...this.props.user,
              access_level,
              memberships: [find(this.props.clientUser.memberships, (i) => i.client_id === this.props.clientId)],
            },
          });
          break;
        }

        onCreate({
          addNewUserStep: 31,
          addNewUserObject: {
            ...this.props.user,
            access_level,
            memberships,
          },
        });
        break;

      default:
        break;
    }
  };

  getAccessLevelOptions = () => {
    const {lang, company} = this.props;
    return [
      {
        label: `${lang.NATIONAL_ACCESS_LEVEL}
                      (${lang.NATIONAL_ACCESS_LEVEL_DESCRIPTION})`,
        value: ACCESS_LEVEL_TYPES.NATIONAL,
      },
      !!company?.regions?.length && {
        label: lang.REGIONS_ACCESS_LEVEL,
        value: ACCESS_LEVEL_TYPES.REGIONS,
      },
      !!company?.regions?.length &&
        company?.client_network && {
          label: lang.STORES_ACCESS_LEVEL,
          value: ACCESS_LEVEL_TYPES.STORES,
        },
      !!company?.regions?.length &&
        !company?.client_network && {
          label: lang.LOCAL_ACCESS_LEVEL,
          value: ACCESS_LEVEL_TYPES.LOCAL,
        },
      {
        label: lang.CLIENT_ADMIN_ACCESS_LEVEL,
        value: ACCESS_LEVEL_TYPES.CLIENT_ADMIN,
      },
    ].filter(Boolean);
  };

  render() {
    const {formValues, onClose, onBack, show, editingUser, lang} = this.props;
    const {first_name: firstName, last_name: lastName, title: civility, email} = this.props.user;
    const {access_level: accessLevel} = formValues.values || {};

    return (
      <Modal
        show={show}
        onClose={onClose}
        dialogClassName="modal-window--width-1 modal-window--theme-3"
        titleClassName="modal-window__title"
        title={
          <>
            <h3 className="title pull-left">{editingUser ? lang.EDIT_USER_MODAL_TITLE : lang.NEW_USER_MODAL_TITLE}</h3>
            {!editingUser && (
              <div className="pull-right modal-window__step">
                {lang.NEW_USER_MODAL_STEP} 3 {lang.NEW_USER_MODAL_STEP_OF} 3
              </div>
            )}
          </>
        }
      >
        <form noValidate="noValidate" onSubmit={this.props.handleSubmit(this.save)}>
          <div className="modal-window__subtitle modal-window__email">{email}</div>
          <div className="modal-window__subtitle modal-window__name">
            {lang.CIVILITY[civility]} {firstName} {lastName}
          </div>
          {!editingUser && (
            <div className="form-field-wrap">
              <Field name="title" label={lang.NEW_USER_MODAL_TITLE_LABEL} type="text" component={fieldTemplate} />
            </div>
          )}
          {!editingUser && (
            <div className="form-field-wrap">
              <Field name="phone" label={lang.NEW_USER_MODAL_PHONE_LABEL} type="text" component={fieldTemplate} />
            </div>
          )}
          <div className="form-field-wrap">
            <div className="list list--view-1 list--bg-1">
              <h4 className="list__title">{lang.NEW_USER_MODAL_ACCESS_LEVEL_TITLE}</h4>
              <RadioGroupField color="users" name="access_level" options={this.getAccessLevelOptions()} />
            </div>
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onBack}>
              {lang.NEW_USER_MODAL_BACK_BUTTON}
            </button>
            <button className="button button--bg-3 modal-window__footer-btn" type="submit">
              {!editingUser && accessLevel === ACCESS_LEVEL_TYPES.NATIONAL
                ? lang.NEW_USER_MODAL_CREATE_BUTTON
                : lang.NEW_USER_MODAL_NEXT_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

NewUserStep3Modal.propTypes = {
  email: PropTypes.string,
  adminId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  civility: PropTypes.string,
  editingUser: PropTypes.bool,
  show: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  updateErrors: PropTypes.func,
  handleSubmit: PropTypes.func,
  onCreate: PropTypes.func,
  initialize: PropTypes.func,
  initialValues: PropTypes.object,
  clientUser: PropTypes.object,
  user: PropTypes.object,
  lang: PropTypes.object.isRequired,
  company: PropTypes.shape({
    regions: PropTypes.arrayOf(PropTypes.object),
    client_network: PropTypes.bool,
  }),
  clientId: PropTypes.number,
  formValues: PropTypes.object,
};

NewUserStep3Modal.defaultProps = {
  show: false,
  editingUser: false,
  onBack: () => {},
  onClose: () => {},
  onCreate: () => {},
  clientId: 0,
  formValues: {},
  clientUser: {},
  company: {},
};

const NewUserStep3Form = reduxForm({
  form: 'NewUserStep3Form',
  validate: NewUserStep3Modal.validate,
})(NewUserStep3Modal);

export default connect(
  (state) => ({
    lang: state.languageState.payload.AGENCY,
    formValues: getFormValues('NewUserStep3Form')(state),
    company: selectCompany(state),
  }),
  {
    updateErrors: NewUserStep3Modal.updateSyncErrors,
  },
)(NewUserStep3Form);
