import {isEmail} from 'client/services/helpers';

import {EmailParams} from 'client/components/email-template-editor/types';
import {Translation} from 'client/models/language/types';

export default (values: EmailParams, lang: Record<string, Translation>) => {
  const errors: Record<string, Translation> = {};

  if (values?.replyTo && !isEmail(values?.replyTo)) {
    errors.replyTo = lang.EMAIL_FORMAT_INCORRECT;
  }

  return errors;
};
