import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector, SubmissionError} from 'redux-form';

import {required} from 'client/services/validator';

import {getManualVisualGroups, addManualVisualGroup, addManualVisualGroupItem} from 'client/ducks/groups/actions';
import {selectManualGroups} from 'client/ducks/groups/selectors';

import {GROUP_TYPES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

const TYPES = {
  GROUP: 'GROUP',
  SUBGROUP: 'SUBGROUP',
};

class VisCreateGroupModal extends Component {
  static formName = 'VisCreateGroupModalForm';

  static propTypes = {
    show: PropTypes.bool,
    groups: PropTypes.array,
    selectedIds: PropTypes.array.isRequired,
    lang: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    getManualVisualGroups: PropTypes.func.isRequired,
    addManualVisualGroup: PropTypes.func.isRequired,
    addManualVisualGroupItem: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    selectedType: PropTypes.string,
    clientId: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired,
    untouch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    groups: [],
    show: false,
  };

  constructor(props) {
    super(props);

    this.rules = {
      name: required(this.props.lang.NAME_REQUIRED),
      parent: required(this.props.lang.PARENT_REQUIRED),
      task: required(this.props.lang.TASK_REQUIRED),
    };
  }

  componentDidMount() {
    this.props.initialize({
      type: TYPES.GROUP,
    });
    this.props.getManualVisualGroups();
  }

  componentDidUpdate(prevProps) {
    const {show, selectedType} = prevProps;

    // when close modal
    if (!this.props.show && show) {
      this.props.reset();
    }
    // when "type" changes
    if (this.props.selectedType === TYPES.GROUP && selectedType === TYPES.SUBGROUP) {
      this.resetParentIdField();
    }
  }

  resetParentIdField = () => {
    this.handleFieldsChange({name: 'parent_id', value: null});
    this.props.untouch('parent_id');
  };

  handleFieldsChange = (...fields) => {
    fields.forEach(({name, value}) => {
      this.props.change(name, value);
    });
  };

  save = async ({name, type, parent_id}) => {
    const {error, payload} = await this.props.addManualVisualGroup({
      manual_visual_group: {
        name,
        parent_id: type === TYPES.SUBGROUP ? parent_id : null,
        type: GROUP_TYPES.MANUAL,
      },
    });

    if (error) {
      const {errors = {}} = payload.response;
      const submitErrors = {};

      if (errors.name) {
        submitErrors.name = this.props.lang.NAME_TAKEN_ERROR;
      }

      throw new SubmissionError(submitErrors);
    }

    const {manual_visual_group} = payload;
    const parentPromises = [];
    const childPromises = [];
    const params = {};

    // if there is no selected visuals then we are assuming that we are creating new empty group
    if (!this.props.selectedIds.length) {
      params.fromScratch = true;
    }

    this.props.selectedIds.forEach((visual_id) => {
      // NOTE: If visual is already added in parent group, the promise will return 422 error.
      // In this implementation this error is not handling.
      // This is not critical, because "visual" will be added in subgroup anyway.
      if (type === TYPES.SUBGROUP) {
        parentPromises.push({
          manual_visual_group_item: {
            visual_id,
            manual_visual_group_id: parent_id,
          },
        });
      }

      childPromises.push({
        manual_visual_group_item: {
          visual_id,
          manual_visual_group_id: manual_visual_group.id,
        },
      });
    });

    params.parentGroupId = parent_id;
    params.groupId = manual_visual_group.id;

    await Promise.all([
      ...parentPromises.map(this.props.addManualVisualGroupItem),
      ...childPromises.map(this.props.addManualVisualGroupItem),
    ]);

    this.props.reset();
    this.props.onConfirm(params);
  };

  render() {
    const {show, lang, groups, onClose, handleSubmit} = this.props;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-13 pmt-new-group"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TITLE}
      >
        <form onSubmit={handleSubmit(this.save)}>
          <FormFieldWrap>
            <Field
              label={lang.NAME_LABEL}
              name="name"
              type="text"
              validate={this.rules.name}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <div className="field-group theme-color-13">
            <h4 className="field-group__title">{lang.TYPE_LABEL}</h4>
            <FormFieldWrap>
              <Field name="type" type="radio" value={TYPES.GROUP} label={lang.GROUP_LABEL} component={fieldTemplate} />
            </FormFieldWrap>
            <FormFieldWrap>
              <Field
                name="type"
                type="radio"
                value={TYPES.SUBGROUP}
                label={lang.SUBGROUP_LABEL}
                component={fieldTemplate}
              />
            </FormFieldWrap>
            {this.props.selectedType === TYPES.SUBGROUP && (
              <FormFieldWrap>
                <Field
                  simpleValue={true}
                  name="parent_id"
                  type="select"
                  valueKey="id"
                  labelKey="name"
                  options={groups}
                  label={lang.PARENT_GROUP_LABEL}
                  validate={this.rules.parent}
                  component={fieldTemplate}
                />
              </FormFieldWrap>
            )}
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onClose}>
              {lang.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-8 modal-window__footer-btn" type="submit">
              {lang.CREATE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const VisCreateGroupModalForm = reduxForm({
  form: VisCreateGroupModal.formName,
})(VisCreateGroupModal);

const mapStateToProps = ({languageState, ...state}) => {
  const selector = formValueSelector(VisCreateGroupModal.formName);

  return {
    lang: languageState.payload.VISUALS.CREATE_GROUP_MODAL,
    selectedType: selector(state, 'type'),
    groups: selectManualGroups(state),
  };
};

const mapDispatchToProps = {
  getManualVisualGroups,
  addManualVisualGroup,
  addManualVisualGroupItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(VisCreateGroupModalForm);
