import React from 'react';

import PropTypes from 'prop-types';

const FileCell = ({value}) => {
  const isUrl = value.url || (typeof value === 'string' && value.startsWith('http'));
  const fileName = value.file_identifier || value.record?.file || 'URL';

  return (
    <div className="ellipsis-text">
      {isUrl ? (
        <a href={value.url || value} target="_blank" className="link" rel="noreferrer">
          {fileName}
        </a>
      ) : (
        <span>{fileName}</span>
      )}
    </div>
  );
};

FileCell.propTypes = {
  value: PropTypes.shape({
    url: PropTypes.string,
    file_identifier: PropTypes.string,
  }).isRequired,
};

export default FileCell;
