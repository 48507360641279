import get from 'lodash/get';
import {createSelector} from 'reselect';

export function selectVisuals(state) {
  return state.visuals.visuals;
}

export function selectVisualsList(state) {
  return {
    visuals: state.visuals.visuals,
    meta: state.visuals.meta,
  };
}

// returns nested object {[visualId]: { [manualGroupId]: manualGroupItemId[visualId], ...}, ... }
export const selectManualGroupItemsByVisualId = createSelector(selectVisuals, (visuals) =>
  visuals.reduce((accVisuals, visual) => {
    const {id: visualId, manual_visual_groups = []} = visual;

    return {
      ...accVisuals,
      [visualId]: manual_visual_groups.reduce((accGroups, group) => {
        const {id: groupId, manual_visual_group_items} = group;
        // eslint-disable-next-line max-nested-callbacks
        const item = manual_visual_group_items.find(({visual_id}) => visual_id === visualId);

        return item
          ? {
              ...accGroups,
              [groupId]: item.id,
            }
          : accGroups;
      }, {}),
    };
  }, {}),
);

export const selectManualGroupIdsByVisualId = createSelector(selectVisuals, (visuals) => {
  const gruopIds = visuals.reduce((accVisuals, visual) => {
    const {id: visualId, manual_visual_groups = []} = visual;

    return {
      ...accVisuals,
      [visualId]: manual_visual_groups.map((item) => item.id),
    };
  }, {});
  const keys = Object.keys(gruopIds);

  return keys.reduce((acc, item, index) => {
    const ids = gruopIds[item];
    ids.forEach((id) => {
      if (acc[id]) {
        acc[id] = [...acc[id], +keys[index]];
      } else {
        acc[id] = [+keys[index]];
      }
    });
    return acc;
  }, {});
});

export const selectManualGroupsIdsByVisualId = createSelector(selectVisuals, (visuals) =>
  visuals.reduce((acc, {id: visualId, manual_visual_groups = []}) => {
    return {
      ...acc,
      [visualId]: manual_visual_groups.map(({id}) => id),
    };
  }, {}),
);

export function selectGroupId(state, groupId) {
  return groupId;
}

export const selectVisualsIdsInsideGroup = createSelector(
  [selectManualGroupsIdsByVisualId, selectGroupId],
  (groupsByVisualId, groupId) =>
    Object.entries(groupsByVisualId).reduce((acc, [visualId, groupsIds]) => {
      return groupsIds.includes(groupId) ? [...acc, +visualId] : acc;
    }, []),
);

export const selectVisualsTotalCount = (state) => get(state, 'visuals.meta.total_count', 0);

export function selectVisualGroups(state) {
  return state.visuals.visualGroups;
}

export function selectGroupNames(state) {
  return selectVisualGroups(state)
    .reduce((groups, group) => groups.concat(group).concat(group.children || []), [])
    .map((group) => group.name);
}

export const selectGroupsListMapped = createSelector(selectVisualGroups, (groups) =>
  groups.reduce((acc, group) => {
    let subgroups = [];

    if (group.children) {
      subgroups = group.children.map(({id, name}) => ({
        id,
        name,
        isParent: false,
      }));
    }

    return [
      ...acc,
      {
        id: group.id,
        name: group.name,
        isParent: true,
      },
      ...subgroups,
    ];
  }, []),
);

export const selectGroupsMappedById = createSelector(selectVisualGroups, (groups) =>
  groups.reduce((acc, group) => {
    let subgroups = {};

    // map subgroups
    if (group.children) {
      subgroups = group.children.reduce((result, sub) => {
        const {id, name, parent_id: parentGroupId} = sub;
        return {
          ...result,
          [id]: {id, name, parentGroupId},
        };
      }, {});
    }

    return {
      ...acc,
      [group.id]: {
        id: group.id,
        name: group.name,
        parentGroupId: null,
      },
      ...subgroups,
    };
  }, {}),
);
