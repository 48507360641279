import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';

import {normalizeVariablesToIds, formatIdsToVariables} from 'client/services/formatters';

import {TextareaField} from 'client/common/fields';

import {TranslationJsx} from 'client/models/language/types';

import VariablesList from './variables-list';

import './variables-textarea.scss';

class VariablesTextarea extends PureComponent {
  static propTypes = {
    validate: PropTypes.arrayOf(PropTypes.func).isRequired,
    searchPlaceholder: TranslationJsx.isRequired,
    changeMessageValue: PropTypes.func.isRequired,
    messageValue: TranslationJsx.isRequired,
    searchLabel: TranslationJsx.isRequired,
    variables: PropTypes.array.isRequired,
    label: TranslationJsx.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
  };

  getUnclosedParenthesesIndex = () => {
    const string = this.props.messageValue;
    const openStr = '{{';
    const closeStr = '}}';
    let unclosedIndex = -1;

    for (let i = 0; i < string.length; ++i) {
      switch (string.substring(i, i + openStr.length)) {
        case openStr:
          if (unclosedIndex !== -1) {
            return unclosedIndex;
          }
          unclosedIndex = i;
          break;

        case closeStr:
          unclosedIndex = -1;
          break;

        default:
          break;
      }
    }

    return unclosedIndex;
  };

  handleVariableClick = (variable) => {
    const index = this.getUnclosedParenthesesIndex() + 2;
    const {messageValue} = this.props;

    const cursor = this.textarea.selectionStart;

    this.props.changeMessageValue(`${messageValue.slice(0, index)}${variable.id}}}${messageValue.slice(index)}`);
    this.textarea.focus();

    setTimeout(() => {
      this.textarea.selectionStart = this.textarea.selectionEnd = cursor + variable.full_name.length + 2;
    }, 50);
  };

  getTextareaRef = (ref) => {
    this.textarea = ref;
  };

  render() {
    const {searchPlaceholder, searchLabel, variables, validate, label, name, required} = this.props;

    return (
      <>
        <TextareaField
          format={formatIdsToVariables(variables)}
          normalize={normalizeVariablesToIds(variables)}
          getRef={this.getTextareaRef}
          validate={validate}
          label={label}
          name={name}
          required={required}
        />
        {this.getUnclosedParenthesesIndex() !== -1 && (
          <VariablesList
            onVariableClick={this.handleVariableClick}
            searchPlaceholder={searchPlaceholder}
            searchLabel={searchLabel}
            variables={variables}
          />
        )}
      </>
    );
  }
}

export default VariablesTextarea;
