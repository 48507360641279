import moment from 'moment';

export const getPeriod = (month, initDate) => {
  const initMoment = month ? moment(month) : moment(initDate);
  const startDay = moment(initMoment).startOf('month').isoWeekday(1);
  const endDay = moment(initMoment).endOf('month').isoWeekday(7);

  return {
    start: startDay.format('YYYY-MM-DD'),
    end: endDay.format('YYYY-MM-DD'),
  };
};
