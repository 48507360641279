import {useMemo} from 'react';

import get from 'lodash/get';
import {useParams} from 'react-router-dom';

import {useReduxFetch} from 'client/services/hooks';

import {getAgencyMembershipsAction} from 'client/ducks/agency/actions';
import {selectAgency} from 'client/ducks/agency/selectors';
import {getCategories} from 'client/ducks/categories/actions';
import {selectCategoriesOptions} from 'client/ducks/categories/selectors';
import {getSubcategories} from 'client/ducks/subcategories/actions';
import {selectSubcategories} from 'client/ducks/subcategories/selectors';

export default (categoryId: string) => {
  const {clientId} = useParams<{clientId: string}>();

  const {data: categories} = useReduxFetch({
    action: getCategories,
    selector: selectCategoriesOptions,
    actionArgs: {
      include: {
        subcategories: null,
      },
    },
  });

  const {data: subCategories} = useReduxFetch({
    action: getSubcategories,
    selector: selectSubcategories,
    fetchOnMount: !!categoryId,
    actionArgs: {
      q: {
        category_id_eq: +categoryId,
      },
    },
  });

  const {data: agencyMemberships} = useReduxFetch({
    action: getAgencyMembershipsAction,
    selector: selectAgency,
    actionArgs: clientId,
  });

  const mapOptions = (items: any[], valueKey: string, labelKey: string | string[]) =>
    items.map((item: {[x: string]: any}) => ({
      value: get(item, valueKey)?.toString(),
      label: Array.isArray(labelKey) ? labelKey.map((key) => get(item, key)).join(' ') : get(item, labelKey),
    }));

  const categoriesOptions = useMemo(() => mapOptions(categories, 'id', 'name'), [categories]);
  const subCategoriesOptions = useMemo(() => mapOptions(subCategories, 'id', 'name'), [subCategories]);
  const agencyOptions = useMemo(
    () => mapOptions(agencyMemberships, 'id', ['client_user.first_name', 'client_user.last_name']),
    [agencyMemberships],
  );

  return {
    categoriesOptions,
    subCategoriesOptions,
    agencyOptions,
  };
};
