import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Modal from 'client/common/modals/modal';

import CustomLink from 'client/components/common/custom-link';
import CustomScrollbars from 'client/components/common/custom-scrollbars';

function BCParticipationsModal(props) {
  const LANGUAGE = props.languageState.payload.LEADS_LIST;
  const {leadId, participations, isAdmin} = props;

  const participationModal = (
    <Modal
      show={props.show}
      onClose={props.onClose}
      dialogClassName="modal-window--width-1 theme-color-16"
      title={`${LANGUAGE.PARTICIPATIONS_MODAL_TITLE} ${leadId}`}
    >
      <div className="content-group theme-color-2">
        <CustomScrollbars
          scrollbarProps={{
            hideTracksWhenNotNeeded: true,
            autoHeightMax: 482,
            autoHeightMin: 200,
          }}
        >
          <div className="list list--view-2">
            <ul className="list__body">
              {participations.map((p, index) => {
                const {
                  operation_code: operationCode,
                  created_at: createdAt,
                  updated_at: updatedAt,
                  interface_name: interfaceName,
                  client_type: clientType,
                  client_id: clientId,
                  operation_id: operationId,
                } = p;

                const link = `${CustomLink.createOperationLink({clientType, clientId, operationId})}`;

                return (
                  <li key={index} className="list__item">
                    <div className="row">
                      <div className="col-xs-4">
                        {isAdmin && (
                          <CustomLink
                            to={link}
                            className="display-block link main-text main-text--upper main-text--bold break-word"
                          >
                            {operationCode}
                          </CustomLink>
                        )}
                        {!isAdmin && (
                          <span className="display-block main-text main-text--upper main-text--bold break-word">
                            {operationCode}
                          </span>
                        )}
                      </div>
                      <div className="col-xs-8">
                        <div className="main-text main-text--small main-text--color-1">{createdAt}</div>
                        <div className="main-text main-text--small main-text--color-1">{updatedAt}</div>
                        <div className="main-text main-text--small main-text--color-1">{interfaceName}</div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </CustomScrollbars>
      </div>
      <div className="modal-window__footer modal-window__footer--centered">
        <button className="button button--bg-5 modal-window__footer-btn" onClick={props.onClose}>
          {LANGUAGE.PARTICIPATIONS_MODAL_CLOSE_BUTTON}
        </button>
      </div>
    </Modal>
  );

  return participations && participations.length ? participationModal : null;
}

BCParticipationsModal.defaultProps = {
  isAdmin: false,
  show: false,
  participations: [],
  leadId: 0,
};

BCParticipationsModal.propTypes = {
  isAdmin: PropTypes.bool,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  languageState: PropTypes.object.isRequired,
  leadId: PropTypes.number,
  participations: PropTypes.array,
};

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

export default connect(mapStateToProps)(BCParticipationsModal);
