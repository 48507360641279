import React, {useMemo, useCallback, useEffect} from 'react';

import {useDispatch} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams} from 'client/services/hooks';

import {createInternalInstoreDashboard} from 'client/ducks/instore-dashboards/actions';

import AppButton from 'client/common/buttons/app-button';
import SelectDropdown from 'client/common/selects/select-dropdown';

import {INSTORE_DASHBOARD_TYPES} from 'client/models/instore-dashboard/constants';
import {InstoreDashboard} from 'client/models/instore-dashboard/types';
import {InstoreTask} from 'client/models/instore-tasks/types';
import {User} from 'client/models/users/types';
import {ApiDispatch} from 'client/types';

import {getOptions, getDefaultDashboardId} from './helpers';

import cssModule from './instore-dashboard-select.module.scss';

const b = bem('instore-dashboard-select', {cssModule});

type InstoreDashboardSelectProps = {
  task: InstoreTask;
  dashboards: InstoreDashboard[] | null;
  fetchDashboards: () => void;
  setDashboard: (dashboard?: InstoreDashboard) => void;
  user: User;
  onAddDashboard: () => void;
};

const InstoreDashboardSelect: React.FC<InstoreDashboardSelectProps> = (props) => {
  const {task, dashboards, fetchDashboards, setDashboard, user, onAddDashboard} = props;
  const dispatch: ApiDispatch = useDispatch();
  const lang = useLanguage('INSTORE_DASHBOARDS.INPUTS.INSTORE_DASHBOARD_SELECT');
  const [queryParams, setQueryParams] = useQueryParams(null, {}, {parse: {parseNumbers: true}});
  const [isInitDashboardCreated, toggleIsInitDashboardCreated] = useToggle(false);

  const createInitialDashboard = useCallback(async () => {
    const data = {
      instore_internal_dashboard: {
        name: lang.INITIAL_DASHBOARD_NAME.toString(),
        instore_task_id: task.id,
        user_id: user.id,
        initial: true,
      },
    };
    await dispatch(createInternalInstoreDashboard(data));
    fetchDashboards();
  }, [fetchDashboards, dispatch, lang.INITIAL_DASHBOARD_NAME, task.id, user.id]);

  useEffect(() => {
    if (dashboards && !dashboards.length && !isInitDashboardCreated) {
      toggleIsInitDashboardCreated();
      createInitialDashboard();
    }
  }, [dashboards, createInitialDashboard, isInitDashboardCreated, toggleIsInitDashboardCreated]);

  const options = useMemo(() => getOptions(dashboards, user), [dashboards, user]);

  useEffect(() => {
    if (!queryParams.dashboardId && dashboards?.length) {
      setQueryParams({dashboardId: getDefaultDashboardId(task, options, user)});
    }
  }, [queryParams.dashboardId, setQueryParams, dashboards, task, options, user]);

  useEffect(() => {
    if (dashboards && queryParams.dashboardId) {
      setDashboard(dashboards.find(({id}) => id === queryParams.dashboardId));
    }
  }, [setDashboard, dashboards, queryParams.dashboardId]);

  return (
    <>
      <SelectDropdown
        className={b()}
        value={queryParams.dashboardId}
        onChange={(dashboardId: number) => setQueryParams({dashboardId})}
        simpleValue={true}
        searchable={true}
        grouped={true}
        styles={{
          control: (baseStyles: object) => ({...baseStyles, height: '44px'}),
        }}
        options={[
          {
            label: lang.MY_DASHBOARDS,
            options: options[INSTORE_DASHBOARD_TYPES.USER],
          },
          {
            label: lang.GLOBAL_DASHBOARDS,
            labelClassName: b('global-label'),
            options: options[INSTORE_DASHBOARD_TYPES.GLOBAL],
          },
          {
            label: lang.WEEZIO_DASHBOARDS,
            labelClassName: b('weezio-label'),
            options: options[INSTORE_DASHBOARD_TYPES.WEEZIO],
          },
        ]}
        menuHeaderElement={
          <AppButton
            className={b('add-button')}
            iconConfig={{className: b('add-icon')}}
            iconName="plus-simple"
            label={lang.ADD_DASHBOARD}
            asWrap={true}
            onClick={onAddDashboard}
          />
        }
      />
    </>
  );
};

export default InstoreDashboardSelect;
