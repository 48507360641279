import React, {useCallback, useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useParams, generatePath} from 'react-router-dom';
import {useUnmount} from 'react-use';

import {useLanguage} from 'client/services/hooks';

import {setHeaderLinkBack, clearHeaderLinkBack} from 'client/ducks/header-link-back/actions';
import {getInstoreTask, clearInstoreTask} from 'client/ducks/instore-tasks/actions';
import {selectInstoreTask} from 'client/ducks/instore-tasks/selectors';
import {getOperation} from 'client/ducks/operations/actions';
import {selectOperation} from 'client/ducks/operations/selectors';

import {CLIENT_PAGES} from 'client/common/config';

import InstoreDashboardsContainer from 'client/components/instore/containers/instore-dashboards-container';
import InstorePlanningContainer from 'client/components/instore/containers/instore-planning-container';
import InstoreReportingContainer from 'client/components/instore/containers/instore-reporting-container';
import InstoreWeekViewContainer from 'client/components/instore/containers/instore-week-view-container';
import TaskWrapper from 'client/components/task-wrapper';

const InstoreTaskPage = () => {
  const lang = useLanguage('INSTORE_TASK');
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const task = useSelector(selectInstoreTask);
  const operation = useSelector(selectOperation);

  useEffect(() => {
    dispatch(setHeaderLinkBack({name: lang.TITLE, rootPage: false}));

    return () => {
      dispatch(clearHeaderLinkBack());
    };
  }, [dispatch, lang.TITLE]);

  const fetchTask = useCallback(() => {
    const queryParams = {
      include: {instore_task_accesses: null},
    };
    return dispatch(getInstoreTask(params.taskId, queryParams));
  }, [dispatch, params.taskId]);

  const fetchOperation = useCallback(() => {
    const queryParams = {};
    return dispatch(getOperation(params.operationId, queryParams));
  }, [dispatch, params.operationId]);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchOperation(), fetchTask()]).then(() => {
      setLoading(false);
    });
  }, [fetchOperation, fetchTask]);

  useUnmount(() => dispatch(clearInstoreTask()));

  const taskLink = generatePath(
    CLIENT_PAGES.CLIENTS +
      '/:clientId' +
      CLIENT_PAGES.OPERATIONS +
      '/:operationId' +
      CLIENT_PAGES.INSTORE_TASKS +
      '/:taskId',
    params,
  );

  if (!task) {
    return null;
  }

  return (
    <TaskWrapper
      task={task}
      operation={operation}
      loading={loading}
      tabs={[
        {
          label: lang.TABS.PLANING_TAB_LABEL,
          path: taskLink + CLIENT_PAGES.CLIENT_INSTORE_TASK_TABS.PLANNING,
          content: <InstorePlanningContainer task={task} />,
        },
        {
          label: lang.TABS.WEEK_VIEW_TAB_LABEL,
          path: taskLink + CLIENT_PAGES.CLIENT_INSTORE_TASK_TABS.WEEK_VIEW,
          content: <InstoreWeekViewContainer task={task} />,
        },
        {
          label: lang.TABS.REPORTING_TAB_LABEL,
          path: taskLink + CLIENT_PAGES.CLIENT_INSTORE_TASK_TABS.REPORTING,
          content: <InstoreReportingContainer task={task} />,
        },
        {
          label: lang.TABS.DASHBOARDS_TAB_LABEL,
          path: taskLink + CLIENT_PAGES.CLIENT_INSTORE_TASK_TABS.DASHBOARDS,
          content: <InstoreDashboardsContainer task={task} fetchTask={fetchTask} />,
        },
        {
          label: lang.TABS.CONFIGURATION_TAB_LABEL,
          disabled: true,
          path: taskLink + CLIENT_PAGES.CLIENT_INSTORE_TASK_TABS.CONFIGURATION,
          content: <></>,
        },
      ]}
    />
  );
};

export default InstoreTaskPage;
