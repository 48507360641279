import {Reducer} from 'redux';

import {Prize} from 'client/models/prizes/types';

import types from './types';

type State = {
  prizes: Prize[];
};

const initialState: State = {
  prizes: [],
};

const reducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LOTTERY_PRIZES_SUCCESS:
      return {
        ...state,
        prizes: action.payload.prizes,
      };

    case types.CHANGE_LOTTERY_PRIZE_ORDER:
      return {
        ...state,
        prizes: action.payload.prizes,
      };

    default:
      return state;
  }
};

export default reducer;
