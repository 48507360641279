import types from './types';

const initialState = {
  visuals: [],
  visualGroups: [],
  displayItems: [],
  meta: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_VISUAL_GROUPS_SUCCESS:
      state.visualGroups = action.payload.visual_groups;
      return state;
    case types.GET_VISUALS_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload.meta,
        },
        visuals: action.payload.visuals,
      };

    case types.GET_VISUAL_DISPLAY_ITEMS_SUCCESS:
      return {
        ...state,
        displayItems: action.payload.visual_display_items,
      };

    case types.GET_AUTOMATIC_VISUAL_GROUPS_SUCCESS:
      return {
        ...state,
        visuals: state.visuals.map((visual) =>
          visual.id !== action.payload.id
            ? visual
            : {...visual, automatic_visual_groups: action.payload.automatic_visual_groups},
        ),
      };

    default:
      return state;
  }
}
