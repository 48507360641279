import React from 'react';

import PropTypes from 'prop-types';
import {removeAll} from 'react-notification-system-redux';
import {connect} from 'react-redux';

import {CLIENT_PAGES} from 'client/common/config';

import './privacy-policy-notification.scss';

function PrivacyPolicyNotification(props) {
  const {lang, onCookiesPolicyAccept, onCookiesPolicyRefuse, hideHotifications} = props;

  const onAccept = () => {
    onCookiesPolicyAccept();
    hideHotifications();
  };

  const onRefuse = () => {
    onCookiesPolicyRefuse();
    hideHotifications();
  };

  return (
    <div className="privacy-policy-notification__buttons-container">
      <button className="privacy-policy-notification__accept" onClick={onAccept}>
        {lang.NOTIFICATION_ACTION_LABEL}
      </button>
      <a className="link privacy-policy-notification__link" href={CLIENT_PAGES.COOKIES_POLICY}>
        {lang.NOTIFICATION_MORE_INFO}
      </a>
      <a className="link privacy-policy-notification__link" onClick={onRefuse}>
        {lang.NOTIFICATION_REFUSE}
      </a>
    </div>
  );
}

PrivacyPolicyNotification.notificationStyle = {
  NotificationItem: {
    DefaultStyle: {
      height: '135px',
    },
  },
};

PrivacyPolicyNotification.propTypes = {
  lang: PropTypes.object.isRequired,
  onCookiesPolicyAccept: PropTypes.func.isRequired,
  onCookiesPolicyRefuse: PropTypes.func.isRequired,
  hideHotifications: PropTypes.func.isRequired,
};

export default connect(
  ({languageState}) => ({
    lang: languageState.payload.COOKIES_POLICY,
  }),
  {
    hideHotifications: removeAll,
  },
)(PrivacyPolicyNotification);
