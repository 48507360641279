import React, {useEffect, useCallback, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {useReduxForm, useLanguage} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {getInterfacesAction} from 'client/ducks/interfaces/actions';
import {getUsersByLead} from 'client/ducks/lead-histories/actions';
import {
  selectInterfacesForDropdown,
  selectUsersForDropdown,
  selectClientId,
} from 'client/ducks/lead-histories/selectors';

import AppButton from 'client/common/buttons';
import {FieldWrap, SelectField, CheckboxField, RadioGroupField, DatepickerField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import {areStatesEqual, convertDate} from 'client/components/leads/components/modals/leads-filters-modal/helpers';

import {InitialValuesForModalProps} from './types';
import validate from './validate';

const LeadsProfileFiltersModalFormName = 'LeadsProfileFiltersModalForm';

type LeadsProfileFiltersModalForm = InitialValuesForModalProps;

export type LeadsProfileFiltersModalProps = {
  handleSubmit: () => void;
  onFilterChange: (form: LeadsProfileFiltersModalForm) => void;
  onFilterResetData: (form: LeadsProfileFiltersModalForm) => void;
  onClose: () => void;
  leadId: string;
  totalItem: number;
  submitting: boolean;
  show?: boolean;
  initialValuesForModal: InitialValuesForModalProps;
};

const LeadsProfileFiltersModal: ReduxFormFC<LeadsProfileFiltersModalProps, LeadsProfileFiltersModalForm> = (props) => {
  const {onClose, show, leadId, onFilterChange, totalItem, handleSubmit, initialValuesForModal, onFilterResetData} =
    props;
  const lang = useLanguage('LEADS_PROFILE');

  const {formValues, invalid, change} = useReduxForm(LeadsProfileFiltersModalFormName, {
    initialValues: initialValuesForModal,
    validate: (values) => validate(values, {lang: lang}),
  });
  const [prevFormValues, setPrevFormValues] = useState(formValues);

  const participated = formValues.participated === 'true';

  const dispatch = useDispatch();

  const interfacesNames = useSelector(selectInterfacesForDropdown);
  const users = useSelector(selectUsersForDropdown);
  const clientId = useSelector(selectClientId);

  const fetchInterfaces = useCallback(() => {
    const interfacesNamesQueryParams = {
      q: {
        operation_client_id_eq: clientId,
      },
    };

    dispatch(getInterfacesAction(interfacesNamesQueryParams));
  }, [clientId, dispatch]);

  const fetchUsers = useCallback(() => {
    const userIdsQueryParams = {
      q: {
        lead_histories_lead_id_eq: leadId,
      },
      distinct: true,
    };
    dispatch(getUsersByLead(userIdsQueryParams));
  }, [dispatch, leadId]);

  useEffect(() => {
    fetchInterfaces();
    fetchUsers();
  }, [fetchInterfaces, fetchUsers]);

  useEffect(() => {
    if (!areStatesEqual(prevFormValues, formValues)) {
      onFilterChange(formValues);
      setPrevFormValues(formValues);
    }
  }, [change, formValues, onFilterChange, prevFormValues]);

  const onHandleClose = () => {
    onFilterResetData(formValues);
    onClose();
  };

  const onSave = () => {
    onFilterChange(formValues);
    onHandleClose();
  };

  return (
    <Modal
      show={show}
      className="leads-profile-filters-modal"
      bodyClassName="filters"
      onClose={onHandleClose}
      title={lang.FILTERS_MODAL_TITLE}
    >
      <form onSubmit={handleSubmit(onSave)}>
        <FieldWrap label={lang.FILTERS_MODAL_TIME_PERIOD_LABEL}>
          <FieldWrap wrap={true}>
            <RadioGroupField
              name="participated"
              color="leads"
              asButton={true}
              direction="horizontal"
              options={[
                {
                  value: 'false',
                  label: lang.FILTERS_MODAL_NO_MATTER_RADIO,
                },
                {
                  value: 'true',
                  label: lang.FILTERS_MODAL_TIME_PERIOD_RADIO,
                },
              ]}
            />
          </FieldWrap>
        </FieldWrap>
        <FieldWrap wrap={true}>
          <FieldWrap>
            <DatepickerField
              label={lang.FILTERS_MODAL_FROM_LABEL}
              name="filters.participatedFrom"
              disabled={!participated}
              required={true}
            />
          </FieldWrap>
          <FieldWrap>
            <DatepickerField
              label={lang.FILTERS_MODAL_TO_LABEL}
              name="filters.participatedTo"
              disabledDayBefore={convertDate(formValues.participatedFrom)}
              disabled={!participated}
              required={true}
            />
          </FieldWrap>
        </FieldWrap>
        <FieldWrap label={lang.FILTERS_MODAL_EVENT_TYPE_LABEL}>
          <FieldWrap wrap={true}>
            <CheckboxField
              name="filters.participation"
              label={lang.FILTERS_MODAL_PARTICIPATION_RADIO}
              color="leads"
              withWrap={false}
              inversionColor={true}
              asButton={true}
            />
            <CheckboxField
              name="filters.opt_in"
              label={lang.FILTERS_MODAL_OPTIN_RADIO}
              color="leads"
              withWrap={false}
              inversionColor={true}
              asButton={true}
            />
            <CheckboxField
              name="filters.visual"
              label={lang.FILTERS_MODAL_VISUAL_RADIO}
              color="leads"
              withWrap={false}
              inversionColor={true}
              asButton={true}
            />
            <CheckboxField
              name="filters.update"
              label={lang.FILTERS_MODAL_EDIT_RADIO}
              color="leads"
              withWrap={false}
              inversionColor={true}
              asButton={true}
            />
            <CheckboxField
              name="filters.export"
              label={lang.FILTERS_MODAL_EXPORT_RADIO}
              color="leads"
              withWrap={false}
              inversionColor={true}
              asButton={true}
            />
            <CheckboxField
              name="filters.import"
              label={lang.FILTERS_MODAL_IMPORT_RADIO}
              color="leads"
              withWrap={false}
              inversionColor={true}
              asButton={true}
            />
          </FieldWrap>
        </FieldWrap>
        <FieldWrap>
          <SelectField
            label={lang.FILTERS_MODAL_INTERACTION_LABEL}
            name="filters.interfacesNames"
            searchable={true}
            options={interfacesNames}
          />
        </FieldWrap>
        <FieldWrap>
          <SelectField label={lang.FILTERS_MODAL_ID_LABEL} name="filters.userId" searchable={false} options={users} />
        </FieldWrap>
        <FieldWrap center>
          <AppButton
            submit
            color="leads"
            label={`${lang.FILTERS_MODAL_CONFIRM_BUTTON} (${totalItem})`}
            disabled={invalid}
          />
        </FieldWrap>
      </form>
    </Modal>
  );
};

const form = reduxForm<LeadsProfileFiltersModalProps, LeadsProfileFiltersModalForm>({
  form: LeadsProfileFiltersModalFormName,
})(LeadsProfileFiltersModal);

export default form;
