import React, {useEffect, useCallback} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {useLanguage} from 'client/services/hooks';

import {setHeaderLinkBack, clearHeaderLinkBack} from 'client/ducks/header-link-back/actions';
import {getInstoreMonitoringList} from 'client/ducks/instore-tasks/actions';
import {selectInstoreTaskMonitoringList} from 'client/ducks/instore-tasks/selectors';

import {set as setMetaAction} from 'client/common/meta/meta.actions';

import InstoreMonotoringContainer from 'client/components/instore/containers/instore-monitoring-container';

const AnimationMonitoringPage = () => {
  const params = useParams();
  const lang = useLanguage('INSTORE_TASK.LISTS');
  const monitoringList = useSelector(selectInstoreTaskMonitoringList);
  const dispatch = useDispatch();

  const fetchMonitoring = useCallback(async () => {
    await dispatch(
      getInstoreMonitoringList({
        q: {
          kpiable_id_eq: params.animationId,
          kpiable_type_eq: 'Instore::Animation',
          task_kpi_nature_eq: 'report',
        },
        include: ['task_kpi'],
      }),
    );
  }, [dispatch, params.animationId]);

  useEffect(() => {
    dispatch(setMetaAction({title: lang.INSTORE_MONITORING_LIST}));
    dispatch(
      setHeaderLinkBack({
        name: lang.INSTORE_MONITORING_LIST,
        rootPage: false,
      }),
    );

    return () => {
      dispatch(clearHeaderLinkBack());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchMonitoring();
  }, [fetchMonitoring]);

  return <InstoreMonotoringContainer monitoringList={monitoringList} />;
};

export default AnimationMonitoringPage;
