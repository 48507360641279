import React from 'react';

import PropTypes from 'prop-types';

import BcNavItem from '../../bc-nav-item';

function BcTemplateNavItem(props) {
  const {languageState, checked, eventKey, templateName, ...restProps} = props;

  return (
    <BcNavItem
      title={<div className="break-word">{languageState.TEMPLATE_NAV}</div>}
      name={checked && <div className="break-word">{templateName}</div>}
      eventKey={eventKey}
      checked={checked}
      {...restProps}
    />
  );
}

BcTemplateNavItem.propTypes = {
  languageState: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  eventKey: PropTypes.string,
  templateName: PropTypes.string,
};

BcTemplateNavItem.defaultProps = {
  checked: false,
  eventKey: '',
  templateName: '',
};

export default BcTemplateNavItem;
