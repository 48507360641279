import React from 'react';

import PropTypes from 'prop-types';

import Icon from 'client/common/icon';

import {getAnimationAnomalyStatus} from '../../helpers';

const AnimationAnomalyCell = (props) => {
  const {className, item} = props;

  if (!item) {
    return null;
  }

  const iconName = getAnimationAnomalyStatus(item);

  return <Icon className={className} name={`anomaly-${iconName}`} />;
};

AnimationAnomalyCell.propTypes = {
  item: PropTypes.array.isRequired,
  className: PropTypes.string,
};

AnimationAnomalyCell.defaultProps = {
  className: '',
};

export default AnimationAnomalyCell;
