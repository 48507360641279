import React from 'react';

import PropTypes from 'prop-types';

import Popover from 'client/common/popovers/popover';

import {TranslationJsx} from 'client/models/language/types';

function BCPopoverStatusOpen(props) {
  const {texts, opened, labels} = props;

  const STATUSES = {
    closed: {
      label: labels.closed,
      text: texts.closed,
      color: '#47077F',
    },
    opened: {
      label: labels.opened,
      text: texts.opened,
      color: '#4A90E2',
    },
  };

  const status = opened ? STATUSES.opened : STATUSES.closed;

  return (
    <Popover
      position="bottom"
      shiftTop={5}
      overlayInnerCssClass="main-text"
      overlayInnerStyle={{
        width: 200,
      }}
      overlay={status.text}
    >
      <div
        style={{
          color: status.color,
        }}
      >
        {status.label}
      </div>
    </Popover>
  );
}

BCPopoverStatusOpen.propTypes = {
  texts: PropTypes.shape({
    opened: TranslationJsx.isRequired,
    closed: TranslationJsx.isRequired,
  }).isRequired,
  labels: PropTypes.shape({
    opened: TranslationJsx.isRequired,
    closed: TranslationJsx.isRequired,
  }).isRequired,
  opened: PropTypes.bool.isRequired,
};

export default BCPopoverStatusOpen;
