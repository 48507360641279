import React from 'react';

import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {PARTICIPANTS_SORT_MAP} from 'client/ducks/participants-list/constants';
import {getColumnVisibilityItems} from 'client/ducks/participation-display-items/actions';
import {selectColumnVisibilityItems} from 'client/ducks/participation-display-items/selectors';
import {getParticipationsTableData} from 'client/ducks/participations/actions';
import {selectParticipantsTableData, selectParticipationsTableDataMeta} from 'client/ducks/participations/selectors';

import ParticipantsCommonList from 'client/components/participants/lists/participants-common-list';

import {mapFilter, mapParticipationsFilterForUrl, clearFilters, getSearchParams} from '../helpers';

import './data-participations.scss';

export class DataParticipations extends ReactQueryParams {
  static propTypes = {
    autotaskId: PropTypes.number,
    filters: PropTypes.object,
    searchString: PropTypes.string,
    searchField: PropTypes.string,
    pages: PropTypes.shape({
      current: PropTypes.number,
      perPage: PropTypes.number,
    }),
    participants: PropTypes.arrayOf(PropTypes.object),
    participantsMeta: PropTypes.object,
    columns: PropTypes.arrayOf(PropTypes.object),
    getParticipationsTableData: PropTypes.func.isRequired,
    getColumnVisibilityItems: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    participants: [],
    pages: {
      page: 1,
      perPage: 25,
    },
  };

  static DEFAULT_FIRST_PAGE = 1;

  static DEFAULT_PER_PAGE = 25;

  static DEFAULT_SORT = 'participated_at desc';

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    const {queryParams} = this;
    if (queryParams.page) {
      this.props.onPageChange(queryParams.page);
    }
    const {nextParams} = getSearchParams({}, this.props);

    const params = {
      ...clearFilters(nextParams),
    };
    delete params.filter;
    this.setQueryParams(params);
    this.fetchAvailableColumns();
    this.fetchParticipations();
  }

  componentDidUpdate(prevProps) {
    const {prevParams, nextParams} = getSearchParams(prevProps, this.props);
    const mainParams = cloneDeep(nextParams);
    delete mainParams.filter;

    if (!isEmpty(prevParams) && !isEqual(prevParams, nextParams)) {
      this.setQueryParams({
        ...clearFilters(mainParams),
        search: mainParams.search,
        searchField: mainParams.searchField,
      });
      this.fetchParticipations();
    }
  }

  fetchAvailableColumns = () => {
    const {autotaskId} = this.props;
    this.props.getColumnVisibilityItems(autotaskId);
  };

  fetchParticipations = () => {
    const {
      queryParams,
      props: {autotaskId},
    } = this;
    const params = {
      automation_task_id: autotaskId,
      q: {
        s: queryParams.sortField
          ? get(PARTICIPANTS_SORT_MAP, `${queryParams.sortField}`, queryParams.sortField) + ' ' + queryParams.sortOrder
          : DataParticipations.DEFAULT_SORT,
      },
      page: queryParams.page || this.props.pages.current,
      per_page: queryParams.perPage || this.props.pages.perPage,
    };

    if (queryParams.search) {
      params.query_param = queryParams.searchField;
      params.query_string = queryParams.search;
    }
    if (queryParams.filter) {
      const {with_media, q, distinct, level_numbers, interface_ids} = mapParticipationsFilterForUrl(
        mapFilter(queryParams.filter),
      );

      params.distinct = distinct;
      params.with_media = with_media;
      params.level_numbers = level_numbers;
      params.interface_ids = interface_ids;
      params.q = {
        ...params.q,
        ...q,
      };
      params.q.g = [params.q.g];
    }

    this.setState({loading: true});
    this.props.onSetTotal(0);

    this.props
      .getParticipationsTableData(params)
      .then(({payload: {meta}}) => {
        if (meta) {
          this.props.onSetTotal(meta.total_count);
        }
        this.setState({loading: false});
      })
      .catch(() => this.setState({loading: false}));
  };

  onChangePagination = (pagination) => {
    if (pagination.page !== this.props.pages.current || pagination.perPage !== this.props.pages.perPage) {
      this.props.onPageChange(pagination);
    }
    if (
      pagination.sortField !== this.props.sortParams.sortField ||
      pagination.sortOrder !== this.props.sortParams.sortOrder
    ) {
      this.props.onSortChange(pagination);
    }
  };

  render() {
    const {participants, participantsMeta, pages, sortParams, columns} = this.props;
    const {loading} = this.state;
    const pagination = {
      ...sortParams,
      page: pages.current,
      perPage: pages.perPage,
    };

    return (
      <div className="data-participations">
        <ParticipantsCommonList
          displayColumns={columns}
          pagination={pagination}
          loading={loading}
          data={participants}
          meta={participantsMeta}
          onPaginationChange={this.onChangePagination}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    columns: selectColumnVisibilityItems(state),
    participants: selectParticipantsTableData(state),
    participantsMeta: selectParticipationsTableDataMeta(state),
  }),
  {
    getParticipationsTableData,
    getColumnVisibilityItems,
  },
)(DataParticipations);
