export const borderColor = '#DCE1EA';

export const fontColor = '#8996AC';

export const fontStyles = {
  family: 'barlow',
  weight: 400,
  lineHeight: '132%',
};

export const barStyles = {
  backgroundColor: '#98EA91',
  borderRadius: 6,
  borderSkipped: false,
  maxBarThickness: 9,
};

export const BAR_EMPTY_DATA = [
  {
    answer: 'A',
    value: '125',
  },
  {
    answer: 'B',
    value: '150',
  },
  {
    answer: 'C',
    value: '225',
  },
  {
    answer: 'D',
    value: '275',
  },
  {
    answer: 'E',
    value: '300',
  },
  {
    answer: 'F',
    value: '500',
  },
  {
    answer: 'G',
    value: '725',
  },
];
