import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import {diagramMethods} from 'client/ducks/results-graphs/constants';

import Field from 'client/components/common/fields/field';

import {isNumberDataSourceSelected} from '../../helpers';

import './diagram-method-field.scss';

const DiagramMethodField = (props) => {
  const {lang, dataSources, formValues} = props;
  const isNumberSelected = isNumberDataSourceSelected(dataSources, formValues);

  return (
    <Field
      name="diagram_method"
      label={lang.METHOD}
      type="select"
      searchable={false}
      simpleValue
      withWrap
      options={[
        {value: diagramMethods.VALUE, label: lang.VALUE},
        {value: diagramMethods.INTERVAL, label: lang.INTERVAL},
      ]}
      disabled={!isNumberSelected}
      cssModifier={cn('diagram-method-field', {
        'diagram-method-field--disabled': !isNumberSelected,
      })}
    />
  );
};

DiagramMethodField.propTypes = {
  lang: PropTypes.object.isRequired,
  dataSources: PropTypes.array.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default DiagramMethodField;
