import React, {useCallback} from 'react';

import {useDispatch} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {unlinkClientUser} from 'client/ducks/client-users/actions';
import {interpolate} from 'client/ducks/language/helpers';

import {useToast} from 'client/common/hooks/useToast';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import {Membership} from 'client/models/memberships/types';
import {ApiDispatch} from 'client/types';

type DeactivateMembershipModalProps = {
  membership: Membership | null;
  clientId: number;
  onClose: () => void;
  onDeactivated: () => void;
};

const DeactivateMembershipModal: React.FC<DeactivateMembershipModalProps> = (props) => {
  const {membership, clientId, onDeactivated, children, onClose} = props;
  const dispatch: ApiDispatch = useDispatch();
  const lang = useLanguage('CLIENT_ACCOUNT_PARAMETERS.MODALS.DEACTIVATE_MEMBERSHIP_MODAL');
  const {appendToastNotification} = useToast();

  const deactivateUser = useCallback(async () => {
    try {
      await dispatch(unlinkClientUser(membership?.client_user_id, clientId, false));
      appendToastNotification({
        type: 'success',
        title: interpolate(lang.SUCCESS_MESSAGE?.toString(), {
          firstName: membership?.client_user?.first_name || '',
          lastName: membership?.client_user?.last_name || '',
        }),
      });
      onDeactivated();
    } catch {
      appendToastNotification({
        type: 'error',
        title: interpolate(lang.ERROR_MESSAGE?.toString(), {
          firstName: membership?.client_user?.first_name || '',
          lastName: membership?.client_user?.last_name || '',
        }),
      });
    }
    onClose();
  }, [
    onClose,
    dispatch,
    membership,
    clientId,
    appendToastNotification,
    lang.SUCCESS_MESSAGE,
    lang.ERROR_MESSAGE,
    onDeactivated,
  ]);

  const descriptionParts = lang.DESCRIPTION.toString().split('{{fullName}}');
  const fullName = interpolate(`{{firstName}} {{lastName}}`, {
    firstName: membership?.client_user?.first_name || '',
    lastName: membership?.client_user?.last_name || '',
  });

  return (
    <ConfirmationModal
      show={!!membership}
      title={lang.TITLE}
      clientSide
      onClose={onClose}
      message={
        <span>
          {descriptionParts[0]}
          <strong>{fullName}</strong>
          {descriptionParts[1]}
        </span>
      }
      buttonConfirm={{
        label: lang.CONFIRMATION_BUTTON,
        onClick: deactivateUser,
        color: 'error',
      }}
      buttonCancel={{
        label: lang.CANCEL_BUTTON,
      }}
    >
      {children}
    </ConfirmationModal>
  );
};

export default DeactivateMembershipModal;
