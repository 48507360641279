import get from 'lodash/get';
import {createSelector} from 'reselect';

import {groupClientTemplateByTabs} from 'client/ducks/templates/mappers';
import {selectClientAccessAdditional} from 'client/ducks/user-clients/selectors';

import {getTemplateIdByFormId} from 'client/components/diy-operation/modals/diy-customization-modal/helpers';
import {CATALOG_TYPES} from 'client/models/templates/constants';

export const selectInterfaceTemplates = (state) => state.operations?.operation?.client_interface?.templates;

/**
 *
 * @param {import('client/types/common/redux').MainStates} state - Redux state
 * @returns {import('client/models/templates/types').Template[]} - Array of templates
 */
export function selectTemplates(state) {
  return state.templates.templates;
}

/**
 *
 * @param {object} state - Redux state
 * @param {TemplateType} type - Type of the template to select
 */
export const selectGroupedClientTemplatesByTabs = (type) =>
  createSelector(
    (state) => ({
      access: selectClientAccessAdditional(state),
      templates: state.templates[`${type}ClientTemplates`],
    }),
    ({templates, access}) => groupClientTemplateByTabs(templates, {access}),
  );

export const selectGroupedClientTemplates = createSelector(
  (state) => ({
    general: state.templates.generalClientTemplates,
    form: state.templates.formClientTemplates,
    game: state.templates.gameClientTemplates,
    quiz: state.templates.quizClientTemplates,
  }),
  (templates) => ({
    general: groupClientTemplateByTabs(templates.general),
    form: groupClientTemplateByTabs(templates.form),
    game: groupClientTemplateByTabs(templates.game),
    quiz: groupClientTemplateByTabs(templates.quiz),
  }),
);

export function selectTemplateForm(state) {
  return state.templates.template?.form?.values;
}

export const selectCurrentTemplateParam = (formId, param) =>
  createSelector(selectInterfaceTemplates, (templates) => {
    const templateId = getTemplateIdByFormId(formId, templates);
    const template = templates?.find(({id}) => id === templateId);
    if (Array.isArray(param) && template) {
      const keys = {};
      param.forEach((key) => {
        keys[key] = template[key];
      });
      return keys;
    }

    return param ? get(template, param) : template;
  });

export const selectGeneralTemplate = createSelector(selectInterfaceTemplates, (templates) => {
  return templates?.find((template) => template?.catalog.type === CATALOG_TYPES.TEMPLATE) || null;
});

export const selectGameTemplate = createSelector(selectInterfaceTemplates, (templates) => {
  return templates?.find((template) => template?.catalog.type === CATALOG_TYPES.GAME) || null;
});

export const selectQuizTemplate = createSelector(selectInterfaceTemplates, (templates) => {
  return templates?.find((template) => template?.catalog.type === CATALOG_TYPES.QUIZ) || null;
});

export const selectFormTemplate = createSelector(selectInterfaceTemplates, (templates) => {
  return templates?.find((template) => template?.catalog.type === CATALOG_TYPES.FORM) || null;
});

export function selectCurrentTemplateErrors(state) {
  return state.templates.template.form.errors;
}
export function selectInitialFormItems(state) {
  return state.templates.template.form?.initialFormItems;
}

export function selectModifiedFormItems(state) {
  return state.templates.template.form?.modifiedFormItems;
}

export function selectClientFonts(state) {
  return state.templates.clientFonts;
}

export function selectClientTemplateNames(state) {
  return state.templates.clientTemplateNames;
}

export function selectResult(state) {
  return state.templates.result;
}

export function selectIsFetchingResult(state) {
  return state.templates.fetchingResult;
}
