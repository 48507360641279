import {isEmptyString} from 'client/services/validator';

import {LOYALTY_COUPON_TYPES, PRIZE_BARCODE_FORMAT} from 'client/components/prizes/constants';
import validateCommonCoupon from 'client/components/prizes/fieldsets/prize-diy-common-coupon-fieldset/validate';
import validateValidity from 'client/components/prizes/fieldsets/prize-diy-validity-fieldset/validate';
import {COUPON_TYPES} from 'client/models/prizes/constants';

export default (allValues, {lang, shouldValidate = true, typeCodeIsBarcode}) => {
  const values = allValues?.coupon || {};
  const errors = {};

  if (!shouldValidate) {
    return errors;
  }

  if (typeCodeIsBarcode && (!values.barcode_format || values.barcode_format === PRIZE_BARCODE_FORMAT.NONE)) {
    errors.barcode_format = lang.NEED_RESET_TYPE_CODE;
  }

  if (isEmptyString(values.internal_name)) {
    errors.internal_name = lang.REQUIRED;
  }

  if (isEmptyString(values.internal_code)) {
    errors.internal_code = lang.REQUIRED;
  }

  if (isEmptyString(values.external_name)) {
    errors.external_name = lang.REQUIRED;
  }

  if ([LOYALTY_COUPON_TYPES.OPTIONAL, LOYALTY_COUPON_TYPES.MANDATORY].includes(allValues?.main.loyalty_coupon_type)) {
    if (isEmptyString(values.loyalty_amount)) {
      errors.loyalty_amount = lang.REQUIRED;
    }
    if (isEmptyString(values.loyalty_unit)) {
      errors.loyalty_unit = lang.REQUIRED;
    }
  }

  return {
    ...errors,
    ...validateValidity(values, lang),
    ...validateCommonCoupon(values, lang, values.type === COUPON_TYPES.COMMON),
  };
};
