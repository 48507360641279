import {useMemo, useRef} from 'react';

import {useSelector} from 'react-redux';

export const useParametrizedSelector = (selector, ...args) => {
  const memorizedSelector = useMemo(selector, [selector]);

  const result = useSelector((state) => memorizedSelector(state, ...args));

  const memoResult = useRef(result);

  return memoResult.current;
};
