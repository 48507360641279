import {MainStates} from 'client/types';

export const selectAllAgencies = (state: MainStates) => {
  return state.agencies.allAgencies;
};

export const selectAgencyClients = (state: MainStates) => {
  return state.agencies.agencyClients;
};

export const selectAllAgencyClients = (state: MainStates) => {
  return state.agencies.allAgencyClients;
};

export const selectAgencyClient = (state: MainStates) => {
  return state.agencies.agencyClient;
};
