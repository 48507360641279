import {
  ADMINS_LIST_NO_DATA,
  ADMINS_LIST_LOADED,
  ADMINS_LIST_ADMIN_ADDED,
  ADMIN_DELETE_SUCCESS,
} from './admins-list.action';

let init = {
  type: ADMINS_LIST_NO_DATA,
  payload: {
    data: [],
    meta: {
      current_page: 0,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
  },
};

export default function (_state_ = init, action) {
  let state = Object.assign({}, _state_);
  switch (action.type) {
    case ADMINS_LIST_NO_DATA:
      state.type = ADMINS_LIST_NO_DATA;
      state.payload = {data: action.payload.data, meta: action.payload.meta};
      return state;
    case ADMINS_LIST_LOADED:
      const {
        payload: {admin_users, meta},
      } = action;
      state.type = ADMINS_LIST_LOADED;
      state.payload = {data: admin_users, meta};
      return state;
    case ADMINS_LIST_ADMIN_ADDED:
      state.type = ADMINS_LIST_ADMIN_ADDED;
      state.payload.data.push(action.payload);
      state.payload.meta.total_count += 1;
      return state;
    case ADMIN_DELETE_SUCCESS:
      state.type = ADMIN_DELETE_SUCCESS;
      const removedElIndex = state.payload.data.map((el) => el.id).indexOf(action.payload.id);
      if (removedElIndex) {
        state.payload.data.splice(removedElIndex, 1);
        state.payload.meta.total_count -= 1;
      }
      return state;
    default:
      return state;
  }
}
