import sortBy from 'lodash/sortBy';

export const mapLevels = (levels) => {
  const mappedLevels = levels.map((level) => ({
    id: level.id,
    name: `${level.interface_name}.${level.level_name}`,
  }));

  return sortBy(mappedLevels, ({name}) => name.toLowerCase());
};
