import {TemplateData, ImageBlockData, TextBlockData} from 'client/components/email-template-editor/types';
import {Prize} from 'client/models/prizes/types';

import buildImageBlock from './buildImageBlock';
import buildPrizeBlock from './buildPrizeBlock';

type GenerateMjmlConfig = {
  prize?: Prize;
  language?: string;
  previewText?: string;
  width: number;
  skipPrizeBlock?: boolean;
  isPreview?: boolean;
};

export default (doc: TemplateData, config: GenerateMjmlConfig) => {
  const {prize, language, width, previewText, skipPrizeBlock, isPreview = false} = config || {};

  const rootEl = document.createElement('mjml');
  const headEl = document.createElement('mj-head');
  const bodyEl = document.createElement('mj-body');

  if (previewText) {
    const previewEl = document.createElement('mj-preview');
    previewEl.innerHTML = previewText;
    headEl.appendChild(previewEl);
  }

  bodyEl.setAttribute('width', `${width}px`);

  rootEl.appendChild(headEl);
  rootEl.appendChild(bodyEl);

  doc.body.forEach((section) => {
    const sectionEl = document.createElement('mj-section');
    bodyEl.appendChild(sectionEl);
    section.columns.forEach((column) => {
      const columnEl = document.createElement('mj-column');
      sectionEl.appendChild(columnEl);
      column.contentBlocks.forEach((contentBlock) => {
        if (contentBlock.type === 'prize' && !skipPrizeBlock) {
          columnEl.innerHTML += buildPrizeBlock(language, prize);
        } else if (contentBlock.type === 'image') {
          const imageBlockContent = contentBlock.content as ImageBlockData;
          columnEl.innerHTML += buildImageBlock(imageBlockContent, isPreview);
        } else if (contentBlock.type === 'text') {
          const textBlockContent = contentBlock.content as TextBlockData;
          const contentBlockEl = document.createElement('mj-text');
          contentBlockEl.innerHTML = textBlockContent.html || '';
          columnEl.appendChild(contentBlockEl);
        }
      });
    });
  });

  return rootEl.outerHTML;
};
