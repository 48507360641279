import React, {useCallback, useEffect, useState} from 'react';

import find from 'lodash/find';
import moment from 'moment/moment';
import {connect, useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {reduxForm} from 'redux-form';

import {useAppMedia, useQueryParams, useReduxForm} from 'client/services/hooks';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {updateAnimationStatus} from 'client/ducks/animations/actions';
import {deleteAnimation} from 'client/ducks/animations/actions-ts';
import {getMembership} from 'client/ducks/client-users/actions';
import {ACCESS_LEVEL_TYPES} from 'client/ducks/client-users/constants';
import {
  createInstoreTaskAnimation,
  getInstoreTaskAccesses,
  updateInstoreTaskAnimation,
} from 'client/ducks/instore-tasks/actions';
import {selectInstoreTaskAccesses} from 'client/ducks/instore-tasks/selectors';
import {selectClientAccessLevel, selectClientUserMembership} from 'client/ducks/user-clients/selectors';

import AnimationConfigMobileContainer from 'client/components/animations/containers/animation-config-container/animation-config-mobile-container';
import useFetchAvailableDays from 'client/components/animations/hooks/useFetchAvailableDays';
import AnimationBaseModal from 'client/components/animations/modals/animation-base-modal';
import AnimationMainModal from 'client/components/animations/modals/animation-main-modal';
import {ANIMATION_STATUSES} from 'client/components/instore/constants';
import {Animation, AnimationStatus} from 'client/models/animations/types';
import {Membership} from 'client/models/memberships/types';
import {ApiDispatch, MainStates} from 'client/types';

import initial from './initial';
import mapping from './mapping';
import validate from './validate';

type AnimationConfigContainerProps = {
  onClose: () => void;
  onSave: () => void;
  animation?: Animation;
  isReporting?: boolean;
};

export const AnimationConfigContainerFormName = 'AnimationConfigContainerForm';

const AnimationConfigContainer: ReduxFormFC<AnimationConfigContainerProps> = (props) => {
  const {onClose, animation, onSave, handleSubmit, isReporting} = props;
  const [completedBaseData, setCompletedBaseData] = useState(!!animation);
  const {initialize, formValues, change} = useReduxForm(AnimationConfigContainerFormName);
  const dispatch: ApiDispatch = useDispatch();
  const {id: membershipId} = useSelector(selectClientUserMembership) || {};
  const clientAccessLevel = useSelector(selectClientAccessLevel);
  const [membership, setMembership] = useState<Membership | null>(null);
  const {isTablet} = useAppMedia();
  const [{campaignId}] = useQueryParams();
  const isReadOnly = !!animation?.id && animation?.status !== ANIMATION_STATUSES.EDIT;
  const accesses = useSelector(selectInstoreTaskAccesses);

  useFetchAvailableDays({
    kitMappingId: formValues?.kit_mapping?.id,
    month: formValues?.month,
    date: formValues?.kit_mapping?.from,
    campaignId,
  });

  const firstAnimationDay = formValues?.animation_days?.[0];

  const params = useParams<{taskId: string; clientId: string}>();

  useEffect(() => {
    if (membership || animation) {
      setCompletedBaseData(!!animation);

      const access = find(accesses, (i) => i.membership_id === membership?.id);

      initialize(initial({access, animation}));
    }
  }, [accesses, animation, initialize, animation?.id, membership]);

  useEffect(() => {
    change('week', firstAnimationDay?.from ? moment(firstAnimationDay?.from).isoWeek() : null);
    change('year', firstAnimationDay?.from ? moment(firstAnimationDay?.from).year() : null);
  }, [change, firstAnimationDay]);

  const fetchAccesses = useCallback(() => {
    const queryParams = {
      include_membership_client_user_full_name: null,
      include: {
        membership: true,
        kit_mappings: {kit: true},
      },
      q: {
        instore_task_id_eq: params.taskId,
        membership_access_level_eq: ACCESS_LEVEL_TYPES.LOCAL,
        membership_client_id_eq: params.clientId,
      },
    };
    return dispatch(getInstoreTaskAccesses(queryParams));
  }, [params.taskId, params.clientId, dispatch]);

  const fetchCurrentMembership = useCallback(() => {
    if (membershipId) {
      dispatch<{membership: Membership}>(
        getMembership(membershipId, {
          include_membership_client_user_full_name: null,
          include: ['kit_mappings.kit'],
        }),
      ).then((response) => setMembership(response.payload?.membership));
    }
  }, [dispatch, membershipId]);

  useEffect(() => {
    if (clientAccessLevel === ACCESS_LEVEL_TYPES.LOCAL && !animation) {
      fetchCurrentMembership();
      fetchAccesses();
    }
  }, [fetchAccesses, fetchCurrentMembership, clientAccessLevel, animation]);

  const handleSuccessSaving = () => {
    onSave();
    setCompletedBaseData(false);
    initialize({});
  };

  // Right now the both saving (draft and submit) need one validation
  const save = (status: AnimationStatus) =>
    handleSubmit(async (data) => {
      const isEdit = !!animation?.id;

      const fetch = isEdit
        ? (body: any) => updateInstoreTaskAnimation(animation?.id, body)
        : createInstoreTaskAnimation;
      const body = mapping({formValues: data, campaignId, status, animation});

      await dispatch(fetch({instore_animation: body}));
      handleSuccessSaving();
    });

  const onCreate = () => {
    setCompletedBaseData(true);
  };

  const onSubmit = () => {
    save(ANIMATION_STATUSES.ISSUED as AnimationStatus)();
  };

  const onSaveDraft = () => {
    save(ANIMATION_STATUSES.EDIT as AnimationStatus)();
  };

  const onCancelSubmission = async () => {
    await dispatch(updateAnimationStatus(animation?.id, ANIMATION_STATUSES.EDIT));
    handleSuccessSaving();
  };

  const closeHandler = useCallback(() => {
    setCompletedBaseData(false);
    initialize({});
    onClose();
  }, [onClose, initialize]);

  const onDelete = () => {
    dispatch(deleteAnimation(animation?.id)).then(() => {
      onSave();
      setCompletedBaseData(true);
      initialize({});
    });
  };

  return (
    <>
      {!completedBaseData ? (
        <AnimationBaseModal onClose={closeHandler} onCreate={onCreate} />
      ) : (
        <>
          {isTablet ? (
            <AnimationConfigMobileContainer
              animation={animation}
              onClose={closeHandler}
              readOnly={isReadOnly}
              onSubmit={onSubmit}
              onSaveDraft={onSaveDraft}
              onDelete={onDelete}
            />
          ) : (
            <AnimationMainModal
              isReporting={isReporting}
              animation={animation}
              onClose={closeHandler}
              readOnly={isReadOnly}
              onSubmit={onSubmit}
              onSaveDraft={onSaveDraft}
              onCancelSubmission={onCancelSubmission}
              onDelete={onDelete}
            />
          )}
        </>
      )}
    </>
  );
};

AnimationConfigContainer.displayName = 'AnimationConfigContainer';

const AnimationConfigurationContainerForm = reduxForm({
  form: AnimationConfigContainerFormName,
  validate,
})(AnimationConfigContainer);

export default connect((state: MainStates) => ({
  lang: state.languageState.payload.ANIMATIONS,
}))(AnimationConfigurationContainerForm);
