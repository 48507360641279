import {RSAA} from 'redux-api-middleware';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getRegionsAction(companyId) {
  return (dispatch) => {
    if (typeof companyId === 'undefined') {
      return dispatch({type: types.GET_REGIONS_SUCCESS, payload: {regions: []}});
    }
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.REGIONS}?q[client_id_eq]=${companyId}`,
        method: 'GET',
        types: [types.GET_REGIONS_REQUEST, types.GET_REGIONS_SUCCESS, types.GET_REGIONS_ERROR],
        isAuth: true,
      },
    });
  };
}

export function getClientsAction() {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.CLIENTS}?q[add_places_eq]=true`,
        method: 'GET',
        types: [types.GET_CLIENTS_REQUEST, types.GET_CLIENTS_SUCCESS, types.GET_CLIENTS_ERROR],
        isAuth: true,
      },
    });
  };
}

export function toggleSubmittedAction() {
  return {
    type: types.TOGGLE_SUBMITTED,
  };
}
