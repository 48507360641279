import React from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import {useLanguage} from 'client/services/hooks';

import {CLIENT_PAGES} from 'client/common/config';

import './password-expiration-notification.scss';

function PasswordExpirationNotification(props) {
  const {days} = props;
  const history = useHistory();

  const lang = useLanguage('NOTIFICATIONS.PASSWORD_EXPIRATION');

  const onReset = () => {
    history.push(CLIENT_PAGES.PROFILE + '#editPassword');
  };

  return (
    <div className="password-expiration-notification">
      <span className="password-expiration-notification__message">{lang.MESSAGE.replace('{days}', days)}</span>
      <button className="password-expiration-notification__button button button--bg-1" onClick={onReset}>
        {lang.RESET}
      </button>
    </div>
  );
}

PasswordExpirationNotification.propTypes = {
  days: PropTypes.number.isRequired,
};

export default PasswordExpirationNotification;
