import React, {useState} from 'react';

import get from 'lodash/get';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useReduxForm} from 'client/services/hooks';

import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

import LanguageDevicePanel from 'client/components/diy-operation/controls/language-device-panel';
import {COMMON_INPUT} from 'client/components/prizes/constants';
import {PrizeDiyModalFormName} from 'client/components/prizes/modals/prize-diy-modal';

import cssModule from './general-language-selector.module.scss';

const b = bem('general-language-selector', {cssModule});

type GeneralLanguageSelectorProps = {
  langUniqueMode: boolean;

  onRemoveImage?: (ids: number[]) => void;
};

const GeneralLanguageSelector: React.FC<GeneralLanguageSelectorProps> = (props) => {
  const {langUniqueMode, onRemoveImage} = props;
  const languages = useSelector(selectPrizeLanguages);

  const [deletingImages, setDeletingImages] = useState<number[]>([]);
  const languageOptions = languages.map((i) => ({value: i, label: i}));

  const {formValues, change} = useReduxForm(PrizeDiyModalFormName);
  const {language, defaultLanguage} = formValues?.main || {};

  const handleLanguageCommonToggle = (isCommon: boolean) => {
    const newImageIds = [...deletingImages];

    const addUniqueId = (id: number) => {
      if (id && !newImageIds.includes(id)) {
        newImageIds.push(id);
      }
    };

    // copy the value of field for defaultLanguage to all other language fields
    if (isCommon) {
      const email = {...formValues?.email};
      const ticket = {...formValues?.ticket};
      const win: Record<string, string> = {};
      const prizeNames: Record<string, string> = {};
      const prizePictos: Record<string, string> = {};
      const legal: Record<string, string> = {};
      languages.forEach((langKey) => {
        // email
        email[`email_image_${langKey}`] = get(formValues, `email.email_image_${language}`);

        if (!email[`email_image_${langKey}`]) {
          // email id for removing images
          email[`email_image_${langKey}_id`] = get(formValues, `email.email_image_${langKey}_id`) || 0;
          addUniqueId(Number(email[`email_image_${langKey}_id`]));
        }

        email[`external_description_${langKey}`] = get(formValues, `email.external_description_${language}`);

        // ticket
        ticket[`ticket_image_${langKey}`] = get(formValues, `ticket.ticket_image_${language}`);

        if (!ticket[`ticket_image_${langKey}`]) {
          // ticket id for removing images
          ticket[`ticket_image_${langKey}_id`] = get(formValues, `ticket.ticket_image_${langKey}_id`);
          addUniqueId(Number(ticket[`ticket_image_${langKey}_id`]));
        }

        // win pop-up
        win[`win_pop_up_image_${langKey}`] = get(formValues, `win_pop_up.win_pop_up_image_${language}`);

        if (!win[`win_pop_up_image_${langKey}`]) {
          // win pop-up id for removing images
          win[`win_pop_up_image_${langKey}_id`] = get(formValues, `win_pop_up.win_pop_up_image_${langKey}_id`) || 0;
          addUniqueId(Number(win[`win_pop_up_image_${langKey}_id`]));
        }

        // prize name
        prizeNames[langKey] = get(formValues, `main.prize_names.${language}`);

        // prize picto
        prizePictos[langKey] = get(formValues, `main.prize_pictos.${language}`);

        // prize picto id for removing images
        if (!prizePictos[langKey]) {
          prizePictos[`${langKey}_id`] = get(formValues, `main.prize_pictos.${langKey}_id`) || 0;
          addUniqueId(Number(prizePictos[`${langKey}_id`]));
        }

        // legal
        legal[langKey] = get(formValues, `main.legal.${language}`);
      });
      change('email', email);
      change('ticket', ticket);
      change('win_pop_up', win);
      change('main.prize_names', prizeNames);
      change('main.prize_pictos', prizePictos);
      change('main.legal', legal);
    }
    setDeletingImages(newImageIds);

    // if newImageIds contains ids for removing images [email, ticket, picto, win pop-up], remove them
    if (newImageIds.length > 0) {
      onRemoveImage?.(newImageIds);
    }

    change('main.language', isCommon ? COMMON_INPUT : defaultLanguage);
  };

  return (
    <LanguageDevicePanel
      className={b()}
      languages={languageOptions}
      language={language}
      onChangeLanguage={(value) => change('main.language', value)}
      langInitiallyCommon={langUniqueMode}
      onLanguageCommonToggle={handleLanguageCommonToggle}
      prefix="main-language"
    />
  );
};

export default GeneralLanguageSelector;
