import React, {useState, useCallback} from 'react';

type UseVariableInsertOptions = {
  variableTemplate?: string;
  initialPosition?: 'start' | 'end';
};

export default (value = '', onChange: (value: string) => void, options: UseVariableInsertOptions = {}) => {
  const {variableTemplate = '{{VARIABLE_NAME}}', initialPosition = 'start'} = options;

  const initialPositionValue = initialPosition === 'start' ? 0 : value.length;

  const [position, setPosition] = useState<{start: number; end: number}>({
    start: initialPositionValue,
    end: initialPositionValue,
  });

  const onBlur: React.FocusEventHandler<HTMLInputElement> = useCallback((e) => {
    setPosition({
      start: e.target.selectionStart || 0,
      end: e.target.selectionEnd || 0,
    });
  }, []);

  const insertVariable = useCallback(
    (variable: string) => {
      const nextValue = Array.from(value);
      nextValue.splice(
        position.start,
        position.end - position.start,
        ...Array.from(variableTemplate.replace('VARIABLE_NAME', variable)),
      );

      onChange(nextValue.join(''));
    },
    [onChange, position.end, position.start, value, variableTemplate],
  );

  return {
    onBlur,
    insertVariable,
  };
};
