export default {
  GET_TRACKERS_REQUEST: '@@tw-trackers/GET_TRACKERS_REQUEST',
  GET_TRACKERS_SUCCESS: '@@tw-trackers/GET_TRACKERS_SUCCESS',
  GET_TRACKERS_ERROR: '@@tw-trackers/GET_TRACKERS_ERROR',

  GET_TRACKER_REQUEST: '@@tw-trackers/GET_TRACKER_REQUEST',
  GET_TRACKER_SUCCESS: '@@tw-trackers/GET_TRACKER_SUCCESS',
  GET_TRACKER_ERROR: '@@tw-trackers/GET_TRACKER_ERROR',

  FETCH_NEW_TRACKER_REQUEST: '@@tw-trackers/FETCH_NEW_TRACKER_REQUEST',
  FETCH_NEW_TRACKER_SUCCESS: '@@tw-trackers/FETCH_NEW_TRACKER_SUCCESS',
  FETCH_NEW_TRACKER_ERROR: '@@tw-trackers/FETCH_NEW_TRACKER_ERROR',
};
