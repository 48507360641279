import {MainStates} from 'client/types';

export const selectWinningTasks = (state: MainStates) => {
  return state.prizeDistribution.winningTasks;
};

export const selectWinningTask = (state: MainStates) => {
  return state.prizeDistribution.winningTask;
};

export const selectWinningHeaderData = (state: MainStates) => {
  return state.prizeDistribution.winningHeaderData;
};

export const selectWinningSearchResults = (state: MainStates) => {
  return state.prizeDistribution.searchResults;
};
