import React, {Fragment, useState, useCallback} from 'react';

import PropTypes from 'prop-types';

import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons';

import OperationsFiltersModal from 'client/components/operations-client/components/modals/operations-filters-modal';

const OperationsFilters = (props) => {
  const {className, onFiltersChange, clientId} = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const lang = useLanguage('OPERATIONS');

  const hideModal = useCallback(() => setIsModalVisible(false), []);

  const showModal = () => setIsModalVisible(true);

  return (
    <Fragment>
      <AppButton
        color="primary"
        iconName="filters"
        label={lang.FILTERS_BUTTON}
        className={className}
        onClick={showModal}
      />
      <OperationsFiltersModal
        show={isModalVisible}
        hideModal={hideModal}
        updateFilters={onFiltersChange}
        clientId={clientId}
      />
    </Fragment>
  );
};

OperationsFilters.defaultProps = {
  className: '',
};

OperationsFilters.propTypes = {
  className: PropTypes.string,
  onFiltersChange: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
};

export default OperationsFilters;
