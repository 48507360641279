import {paginateList} from 'client/services/helpers';

import {CatalogModuleType, TemplateGroup} from 'client/models/templates/types';

type GroupedCatalogs = {game: TemplateGroup[]; form: TemplateGroup[]; quiz: TemplateGroup[]};

const paginateCatalogs = (catalogs: GroupedCatalogs, containerWidth: number) => {
  const perRow = Math.min(Math.floor(containerWidth / 268), 6) || 6;

  const game = paginateList(catalogs.game, perRow);
  const form = paginateList(catalogs.form, perRow);
  const quiz = paginateList(catalogs.quiz, perRow);

  return {
    game,
    form,
    quiz,
    perRow,
    total: game.length + form.length + quiz.length,
  };
};

export const prepareCatalogs = (templateGroups: TemplateGroup[], clientId: number, containerWidth: number) => {
  const byModuleType = templateGroups.reduce<Record<CatalogModuleType, TemplateGroup[]>>(
    (acc, group) => {
      acc[group.module_type].push(group);
      return acc;
    },
    {
      game: [],
      form: [],
      quiz: [],
    },
  );

  return paginateCatalogs(byModuleType, containerWidth);
};
