import React, {useCallback, useEffect} from 'react';

import sortBy from 'lodash/sortBy';
import {useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {createCampaign, getCampaignsWithChannels} from 'client/ducks/campaigns/actions';
import {selectCampaignsWithChannels, selectCampaignsWithChannelsLoading} from 'client/ducks/campaigns/selectors';

import AppButton from 'client/common/buttons';

import Spinner from 'client/components/common/spinner';

import CampaignDiyBlock from 'client/components/campaigns/blocks/campaign-diy-block';
import {isOperationArchived} from 'client/models/operations/helpers';
import {Operation} from 'client/models/operations/types';
import {ApiDispatch} from 'client/types';

import {getNewCampaignName} from './helpers';

import './campaigns-diy-list.scss';

const b = bem('campaigns-diy-list');

type CampaignDiyListProps = {
  operation: Operation;
  fetchOperation: () => Promise<void>;
  fetchGame: () => Promise<void>;
};

const CampaignsDiyList: React.FC<CampaignDiyListProps> = ({operation, fetchOperation, fetchGame}) => {
  const lang = useLanguage('DIY_ONLINE.CAMPAIGNS');
  const campaigns = useSelector(selectCampaignsWithChannels);
  const campaignsLoading = useSelector(selectCampaignsWithChannelsLoading);
  const autotask = useSelector(selectAutotask);
  const dispatch: ApiDispatch = useDispatch();

  const interfaceId = operation?.client_interface?.id;

  const isOpArchived = isOperationArchived(operation);

  const fetchCampaign = useCallback(async () => {
    if (interfaceId) {
      await dispatch(
        getCampaignsWithChannels(interfaceId, {
          include_campaign_prize_maps_modified: true,
          include_campaign_prize_maps_present: true,
          include_interaction_prize_maps_modified: true,
          include_interaction_prize_maps_present: true,
          include_interaction_finished: true,
          include: {
            campaigns: {
              interactions: {
                region: null,
                place: null,
              },
            },
          },
        }),
      );
    }
  }, [dispatch, interfaceId]);

  useEffect(() => {
    fetchCampaign();
  }, [fetchCampaign]);

  const onAddClick = useCallback(() => {
    const body = {
      campaign: {
        name: getNewCampaignName(campaigns, lang.CAMPAIGN),
        automation_task_id: autotask.id,
        interface_id: interfaceId,
      },
    };

    dispatch(createCampaign(body)).then(() => {
      if (interfaceId) {
        dispatch(getCampaignsWithChannels(interfaceId));
        fetchGame();
        fetchOperation();
      }
    });
  }, [fetchGame, autotask.id, interfaceId, campaigns, dispatch, lang.CAMPAIGN, fetchOperation]);

  const addIconConfig = {
    width: 16,
    height: 17,
  };

  return (
    <div className={b()}>
      {campaignsLoading && <Spinner color="primary" className={b('spinner')} />}
      {!campaignsLoading && (
        <>
          <div className={b('buttons-block')}>
            <AppButton
              type="button"
              onClick={onAddClick}
              label={lang.ADD_CAMPAIGN}
              color="light-clients"
              iconName="small-plus"
              classNameIcon={b('add-icon')}
              iconConfig={addIconConfig}
              disabled={isOpArchived}
            />
          </div>
          <div className={b('content')}>
            {sortBy(campaigns.allIds).map((campaignId) => {
              const campaign = campaigns.byId[campaignId];
              return (
                <CampaignDiyBlock
                  key={campaign.id}
                  campaignId={campaign.id}
                  campaign={campaign}
                  fetchOperation={fetchOperation}
                  fetchCampaign={fetchCampaign}
                  fetchGame={fetchGame}
                  disabled={isOpArchived}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default CampaignsDiyList;
