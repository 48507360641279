import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';

import {dateToString} from 'client/services/helpers';
import {useCalendarUnitTranslations} from 'client/services/hooks';

import Popover from 'client/common/popovers/popover';

import Icon from 'client/components/common/icon';

import './datepicker-range.scss';

const OverlayContent = (props) => {
  const {from, to, onChange} = props;

  const calendarUnitTranslations = useCalendarUnitTranslations();

  return (
    <div className="datepicker-range__content">
      <DayPicker
        modifiers={{highlighted: from}}
        month={from}
        onDayClick={(date) => onChange({from: date, to})}
        disabledDays={{after: to}}
        {...calendarUnitTranslations}
      />
      <DayPicker
        modifiers={{highlighted: to}}
        month={to}
        onDayClick={(date) => onChange({from, to: date})}
        disabledDays={{before: from}}
        {...calendarUnitTranslations}
      />
    </div>
  );
};

OverlayContent.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

class DatepickerRange extends Component {
  static propTypes = {
    from: PropTypes.object.isRequired,
    to: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    alignRight: PropTypes.bool,
    dateFormat: PropTypes.string,
  };

  static defaultProps = {
    alignRight: false,
    dateFormat: 'MMM D, YYYY',
  };

  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  handleToggle = () => this.setState(({show}) => ({show: !show}));

  render() {
    const {from, to, onChange, alignRight, dateFormat} = this.props;

    return (
      <Popover
        trigger="click"
        shiftTop={10}
        arrowOffset={55}
        overlayInnerStyle={{
          width: 570,
        }}
        overlay={<OverlayContent from={from} to={to} onChange={onChange} />}
      >
        <div
          className={cn('datepicker-range', {
            'datepicker-range--align-right': alignRight,
          })}
        >
          <div
            className="datepicker-range__date"
            ref={(button) => {
              this.target = button;
            }}
            onClick={this.handleToggle}
          >
            <span className="datepicker-range__date-text">
              {dateToString(from, dateFormat)} - {dateToString(to, dateFormat)}
            </span>
            <Icon name="calendar-2" className="datepicker-range__icon" />
          </div>
        </div>
      </Popover>
    );
  }
}

export default DatepickerRange;
