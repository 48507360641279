import React, {Component} from 'react';

import find from 'lodash/find';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import {patch, post} from 'client/services/fetch';

import {API_METHODS} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

class NewUserStep4Modal extends Component {
  constructor(props) {
    super(props);

    this.LANGUAGE = this.props.languageState.payload.AGENCY;
  }

  componentDidUpdate(prevProps) {
    const {show, user, initialize} = this.props;

    if (!prevProps.show && show) {
      if (!user || !user.access_level) {
        initialize({access_level: 'full'});
      } else {
        initialize(user);
      }
    }
  }

  save = ({access_level}) => {
    switch (access_level) {
      case 'full':
      case 'client_admin':
        if (this.props.editingUser) {
          this.props.onCreate({
            showEditAgencyAccessModal: 0,
            showEditUserModal: true,
            editingUser: {
              ...this.props.user,
              access_level,
            },
          });
        } else {
          const fullAccessNewUserData = {
            client_user: {
              ...this.props.user,
              memberships: [
                {
                  ...this.props.user.memberships[0],
                  access_level,
                },
              ],
              invited_by_id: this.props.adminId,
            },
          };
          if (this.props.clientUserId) {
            patch(`${API_METHODS.CLIENT_USERS}/${this.props.clientUserId}`, fullAccessNewUserData).then(() =>
              this.props.onCreate(0),
            );
          } else {
            post(API_METHODS.CLIENT_USERS, fullAccessNewUserData).then(() => this.props.onCreate(0));
          }
        }
        break;

      case 'places':
        if (this.props.editingUser) {
          this.props.onCreate({
            showEditAgencyAccessModal: 3,
            editingUser: {
              ...this.props.user,
              memberships: [find(this.props.clientUser.memberships, (i) => i.client_id === this.props.clientId)],
            },
          });
        } else {
          this.props.onCreate({
            addNewUserStep: 42,
            addNewUserObject: {
              ...this.props.user,
              memberships: [
                {
                  ...this.props.user.memberships[0],
                  access_level,
                },
              ],
            },
          });
        }
        break;

      case 'regions':
        if (this.props.editingUser) {
          this.props.onCreate({
            showEditAgencyAccessModal: 2,
            editingUser: {
              ...this.props.user,
              memberships: [find(this.props.clientUser.memberships, (i) => i.client_id === this.props.clientId)],
            },
          });
        } else {
          this.props.onCreate({
            addNewUserStep: 41,
            addNewUserObject: {
              ...this.props.user,
              memberships: [
                {
                  ...this.props.user.memberships[0],
                  access_level,
                },
              ],
            },
          });
        }
        break;

      default:
        break;
    }
  };

  render() {
    const {formValues, editingUser, show, onBack, onClose, isAgency} = this.props;
    const {first_name: firstName, last_name: lastName, title: civility, email} = this.props.user;
    const {access_level: accessLevel} = formValues.values ? formValues.values : {};
    return (
      <Modal
        show={show}
        onClose={onClose}
        dialogClassName="modal-window--width-1 modal-window--theme-3"
        titleClassName="modal-window__title"
        title={
          <>
            <h3 className="title pull-left">
              {editingUser ? this.LANGUAGE.EDIT_USER_MODAL_TITLE : this.LANGUAGE.NEW_USER_MODAL_TITLE}
            </h3>
            <div className="pull-right modal-window__step">
              {this.LANGUAGE.NEW_USER_MODAL_STEP} 4 {this.LANGUAGE.NEW_USER_MODAL_STEP_OF} 4
            </div>
          </>
        }
      >
        <form noValidate="noValidate" onSubmit={this.props.handleSubmit(this.save)}>
          <div className="modal-window__subtitle modal-window__email">{email}</div>
          <div className="modal-window__subtitle modal-window__name">
            {this.LANGUAGE.CIVILITY[civility]} {firstName} {lastName}
          </div>
          <div className="form-field-wrap">
            <div className="list list--view-1 list list--bg-1">
              <h4 className="list__title">{this.LANGUAGE.NEW_USER_MODAL_ACCESS_LEVEL_TITLE}</h4>
              <div className="list__item">
                <Field
                  cssModifier="radio-button--view-1"
                  name="access_level"
                  label={`${this.LANGUAGE.NATIONAL_ACCESS_LEVEL}
                      (${this.LANGUAGE.NATIONAL_ACCESS_LEVEL_DESCRIPTION})`}
                  value="full"
                  type="radio"
                  component={fieldTemplate}
                />
              </div>
              {!isAgency && (
                <div className="list__item">
                  <Field
                    cssModifier="radio-button--view-1"
                    name="access_level"
                    label={this.LANGUAGE.REGIONS_ACCESS_LEVEL}
                    value="regions"
                    type="radio"
                    component={fieldTemplate}
                  />
                </div>
              )}
              {!isAgency && (
                <div className="list__item">
                  <Field
                    cssModifier="radio-button--view-1"
                    name="access_level"
                    label={this.LANGUAGE.STORES_ACCESS_LEVEL}
                    value="places"
                    type="radio"
                    component={fieldTemplate}
                  />
                </div>
              )}
              <div className="list__item">
                <Field
                  cssModifier="radio-button--view-1"
                  name="access_level"
                  label={this.LANGUAGE.CLIENT_ADMIN_ACCESS_LEVEL}
                  value="client_admin"
                  type="radio"
                  component={fieldTemplate}
                />
              </div>
            </div>
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onBack}>
              {this.LANGUAGE.NEW_USER_MODAL_BACK_BUTTON}
            </button>
            <button className="button button--bg-3 modal-window__footer-btn" type="submit">
              {!editingUser && accessLevel === 'full'
                ? this.LANGUAGE.NEW_USER_MODAL_CREATE_BUTTON
                : this.LANGUAGE.NEW_USER_MODAL_NEXT_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

NewUserStep4Modal.propTypes = {
  user: PropTypes.object,
  adminId: PropTypes.number,
  show: PropTypes.bool,
  clientUserId: PropTypes.number,
  clientId: PropTypes.number,
  editingUser: PropTypes.bool,
  onBack: PropTypes.func,
  initialize: PropTypes.func,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  updateErrors: PropTypes.func,
  handleSubmit: PropTypes.func,
  languageState: PropTypes.object,
  clientUser: PropTypes.object,
  formValues: PropTypes.object,
  isAgency: PropTypes.bool,
};

NewUserStep4Modal.defaultProps = {
  show: false,
  editingUser: false,
  clientUserId: 0,
  clientId: 0,
  onBack: () => {},
  onClose: () => {},
  onCreate: () => {},
  formValues: {},
  clientUser: {},
  isAgency: false,
};

const NewUserStep4Form = reduxForm({
  form: 'NewUserStep4Form',
  validate: NewUserStep4Modal.validate,
})(NewUserStep4Modal);

export default connect(({languageState, form}) => ({
  languageState,
  formValues: form.NewUserStep4Form,
}))(NewUserStep4Form);
