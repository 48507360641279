import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import './step-nav.scss';

function StepNavItem({className, title, name, content, active, id, role, tabIndex, onKeyDown, onSelect, onClick}) {
  const cssClass = classNames({
    'step-nav__item': true,
  });

  const activeClass = active ? 'step-nav__item--active' : '';

  return (
    <div
      className={`${cssClass} ${className} ${activeClass}`}
      id={id}
      role={role}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onSelect={onSelect}
      onClick={onClick}
    >
      {title && <div className="step-nav__item-title">{title}</div>}
      {name && <div className="step-nav__item-name">{name}</div>}
      {content}
    </div>
  );
}

StepNavItem.defaultProps = {
  className: '',
  name: '',
  title: '',
  content: null,
};

StepNavItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.any,
  title: PropTypes.any,
  content: PropTypes.any,
  eventKey: PropTypes.any.isRequired,
  active: PropTypes.bool,
  activeHref: PropTypes.string,
  activeKey: PropTypes.string,
  id: PropTypes.string,
  role: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
};

function StepNav({children, className}) {
  return <div className={`step-nav ${className}`}>{children}</div>;
}

StepNav.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

StepNav.defaultProps = {
  children: null,
  className: '',
};

export {StepNavItem};
export default StepNav;
