import {useCallback, useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {getAnimationQuestions} from 'client/ducks/animations/actions';
import {selectInstoreTask} from 'client/ducks/instore-tasks/selectors';

import {sortQuestions} from 'client/components/animations/helpers';

const useFetchQuestions = (rawFilters) => {
  const dispatch = useDispatch();
  const task = useSelector(selectInstoreTask);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const filtersString = JSON.stringify(rawFilters || {});

  const fetch = useCallback(async () => {
    setLoading(true);
    setData([]);
    const filters = JSON.parse(filtersString);
    try {
      if (task?.id) {
        const response = await dispatch(
          getAnimationQuestions(
            {taskId: task?.id},
            {
              interaction_group_id: filters?.interaction_group,
              from: filters?.from,
              to: filters?.to,
              week: filters?.week,
              statuses: filters?.statuses,
              kit_ids: [filters?.kit],
              network_ids: [filters?.network],
              user_ids: [filters?.user],
              region_ids: [filters?.region],
            },
          ),
        );

        setData(sortQuestions(response.payload?.questions || []));
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch, filtersString, task?.id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    fetch,
    loading,
    data: data,
  };
};

export default useFetchQuestions;
