import {diagramKinds} from 'client/ducks/results-graphs/constants';

import {PieChartDiagram, LineDiagram, HorizontalBarsDiagram, VerticalBarsDiagram} from '../diagrams';
import {EXCLUDABLE_KEYS} from '../diagrams/constants';

export const componentMap = {
  [diagramKinds.PIE_CHART]: PieChartDiagram,
  [diagramKinds.LINE]: LineDiagram,
  [diagramKinds.HORIZONTAL_BARS]: HorizontalBarsDiagram,
  [diagramKinds.VERTICAL_BARS]: VerticalBarsDiagram,
};

export const MAX_LABEL_LENGTH = 4;

type Data = {
  [key: string]: any;
};

export const hasLongStringLabels = (data: Data) => {
  if (!data) {
    return false;
  }

  const keys = Object.keys(data).filter((key) => !EXCLUDABLE_KEYS.includes(key));

  if (keys.length) {
    return keys.some((key) => key.length > MAX_LABEL_LENGTH) && keys.length > 10;
  }
  return false;
};
