import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';

import {TASK_TYPES} from 'client/models/operations/constants';

import BCPopoverStatusOpen from '../../popovers/bc-popover-status-open';

function StateStatusColumn(props) {
  const {isWebhooksOpened, taskType, languageState} = props;

  const popoverEmail = get(languageState, 'payload.BROADCASTING_TASK.STATUS_POPOVER') || {};
  const popoverSms = get(languageState, 'payload.BROADCASTING_TASK.SMS_STATUS_POPOVER') || {};
  const popover = taskType === TASK_TYPES.SMS ? popoverSms : popoverEmail;
  const labels = get(languageState, 'payload.BROADCASTING_TASK.TASK_LIST.STATUS') || {};

  const language = {
    texts: {
      opened: popover.OPENED,
      closed: popover.CLOSED,
    },
    labels: {
      opened: labels.OPENED,
      closed: labels.CLOSED,
    },
  };

  return <BCPopoverStatusOpen opened={isWebhooksOpened} {...language} />;
}

StateStatusColumn.propTypes = {
  taskType: PropTypes.string.isRequired,
  isWebhooksOpened: PropTypes.bool.isRequired,
  languageState: PropTypes.object.isRequired,
};

export default StateStatusColumn;
