import React, {useState, useRef} from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import {Client} from 'client/models/client/types';
import {ClientTemplate, TemplateGroup} from 'client/models/templates/types';

import CatalogModulesCard from '../catalog-modules-card';
import CatalogModulesSlider from '../catalog-modules-slider';

import cssModule from './catalog-modules-section.module.scss';

type CatalogModulesSectionProps = {
  className?: string;
  templateGroup: TemplateGroup[];
  perRow: number;
  fetchData: () => Promise<any>;
  updateTemplate: (id: number, data: Partial<ClientTemplate>) => void;
  clientId: number;
  agencyClient?: Client | null;
};

const b = bem('catalog-modules-section', {cssModule});

const CatalogModulesSection: React.FC<CatalogModulesSectionProps> = (props) => {
  const {className, templateGroup, perRow, fetchData, updateTemplate, clientId, agencyClient} = props;

  const [openedCatalogId, setOpenedCatalogId] = useState<number | null>(null);

  const [cardPosition, setCardPosition] = useState<{top: number; left: number} | null>(null);
  const cardRefs = useRef<Record<number, HTMLDivElement | null>>({});

  const handleCardClick = (catalogId: number | null) => {
    if (catalogId) {
      const cardRef = cardRefs.current[catalogId];

      if (cardRef) {
        const {top, left} = cardRef.getBoundingClientRect();
        setCardPosition({top, left});
      }
    }

    setOpenedCatalogId(catalogId);
  };

  // for var css dinamic position
  const style = {'--leftPositionCatalogModulesSlider': `${cardPosition?.left}px` || 0};

  return (
    <article className={cn(b(), className)}>
      <div className={b('cards')}>
        {templateGroup?.map((catalog) => (
          <div ref={(ref) => (cardRefs.current[catalog.id] = ref)} key={catalog.id}>
            <CatalogModulesCard
              onClick={handleCardClick}
              className={cn(b('catalog-modules-card'))}
              openedSlider={catalog.id === openedCatalogId}
              templateGroup={catalog}
            />
          </div>
        ))}
      </div>
      {openedCatalogId && (
        <CatalogModulesSlider
          agencyClient={agencyClient}
          clientId={clientId}
          className={cn(b('popover-slider'))}
          style={style}
          templates={templateGroup.find((i) => i.id === openedCatalogId)?.templates || []}
          perRow={perRow}
          fetchData={fetchData}
          updateTemplate={updateTemplate}
        />
      )}
    </article>
  );
};

export default CatalogModulesSection;
