import get from 'lodash/get';

import {mapClientDiagrams, replaceDiagram} from './mappers';
import types from './types';

const initialState = {
  diagrams: [],
  clientDiagrams: [],
  dataSources: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DIAGRAMS_SUCCESS:
      return {
        ...state,
        diagrams: get(action, 'payload.diagrams', []),
      };

    case types.GET_CLIENT_DIAGRAMS_SUCCESS:
      return {
        ...state,
        clientDiagrams: mapClientDiagrams(get(action, 'payload.diagrams', [])),
      };

    case types.GET_CLIENT_DIAGRAM_SUCCESS:
      return {
        ...state,
        clientDiagrams: replaceDiagram(state.clientDiagrams, get(action, 'payload.result.diagrams[0]')),
      };

    case types.GET_DATA_SOURCES_SUCCESS:
      return {
        ...state,
        dataSources: get(action, 'payload.OperationDatum', []),
      };

    case types.CLEAR_CLIENT_DIAGRAMS:
      return {
        ...state,
        clientDiagrams: initialState.clientDiagrams,
      };

    default:
      return state;
  }
};
