import React, {useEffect} from 'react';

import {generatePath} from 'react-router';
import {useHistory} from 'react-router-dom';
import {routePaths} from 'routes/index';

import bem from 'client/services/bem';
import {useReduxFetch} from 'client/services/hooks';

import {getWinningTasks} from 'client/ducks/prize-distribution/actions';
import {selectWinningTasks} from 'client/ducks/prize-distribution/selectors';

import WinningTaskCard from 'client/components/winnings/cards/winning-task-card';
import WinningBaseContainer from 'client/components/winnings/containers/winning-base-container';

import cssModule from './winning-tasks-list.module.scss';

const b = bem('winning-tasks-list', {cssModule});

const WinningTasksList: React.FC = () => {
  const history = useHistory();

  const {data = [], loading} = useReduxFetch({
    action: getWinningTasks,
    selector: selectWinningTasks,
  });

  useEffect(() => {
    if (data.length === 1) {
      history.push(generatePath(routePaths.winning.WinningSearchPage, {taskId: data[0].id}));
    }
  }, [data, history]);

  const handleClick = (taskId: number) => {
    history.push(generatePath(routePaths.winning.WinningSearchPage, {taskId}));
  };

  return (
    <WinningBaseContainer className={b()} loading={loading} empty={!data.length}>
      <div className={b('content')}>
        {data.map((task) => (
          <WinningTaskCard className={b('card')} key={task.id} task={task} onClick={() => handleClick(task.id)} />
        ))}
      </div>
    </WinningBaseContainer>
  );
};

export default WinningTasksList;
