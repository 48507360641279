import React, {Component} from 'react';

import PropTypes from 'prop-types';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import VariableValue from 'client/components/common/variable-value';

class VariableValuesStep extends Component {
  static propTypes = {
    variables: PropTypes.array,
    values: PropTypes.object,
  };

  static defaultProps = {
    variables: [],
    values: {},
  };

  render() {
    const {variables, values} = this.props;

    return (
      <CustomScrollbars
        scrollbarProps={{
          autoHeightMax: 600,
        }}
      >
        <div className="rt-result-steps__container interface-variables">
          {variables.map((variable, key) => (
            <div key={key} className="form-field-wrap">
              <div className="fake-input fake-input--disabled var-container">
                <div className="fake-input__label var-name">{variable.full_name || variable.name}:</div>
                <VariableValue variable={variable} value={values[variable.id]} withTimeZone={true} />
              </div>
            </div>
          ))}
        </div>
      </CustomScrollbars>
    );
  }
}
export default VariableValuesStep;
