import React from 'react';

import getValue from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import {setTestMode} from 'client/ducks/coupon-tokens/actions';

import AppButton from 'client/common/buttons';
import SearchInput from 'client/common/inputs/search-input';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import Icon from 'client/components/common/icon';

import cssModule from './tokens-grid.module.scss';

const b = bem('tokens-grid', {cssModule});

const renderCol = (content, modificator) => (
  <div className={b('col', {[modificator]: Boolean(modificator)})} title={typeof content === 'string' && content}>
    {content}
  </div>
);

const SIZE_PER_PAGE_LIST = [25, 50, 100].map((n) => ({value: `${n}`, label: n}));

const TokensGrid = (props) => {
  const {data, meta, language, sort} = props;

  const renderToolbar = () => {
    return (
      <div className={b('filter-bar')}>
        <SearchInput
          className={b('search-input')}
          placeholder={language.SEARCH_PLACEHOLDER}
          onSearch={props.onSearchChange}
          searchDefault={props.search}
        />

        <AppButton
          onClick={props.openFilterModal}
          className={b('filter-button')}
          label={language.OPEN_FILTER_BUTTON}
          iconName="filter"
          color="coupons"
        />
        <span className={b('search-result-num')}>
          <span className="main-text">{`${meta.total_count || 0} ${language.NUM_OF_RESULTS}`}</span>
        </span>
      </div>
    );
  };

  const changeTestMode = async (couponId, nextState) => {
    props.setTest(couponId, nextState).then(({error}) => {
      if (!error) {
        props.updateMe();
      }
    });
  };

  const dataFormatCallback = (key) => {
    const renderDate = (iso) => {
      const date = moment(iso).format('DD/MM/YYYY');
      const time = moment(iso).format('HH:mm:ss');
      return (
        <div title={`${date} ${time}`}>
          {date} <div className="tokens-grid__time">{time}</div>
        </div>
      );
    };

    return ({value, item: row}) => {
      switch (key) {
        case 'token':
          return (
            <div className={b('token-field')} title={value}>
              <div className={b('token-value')}>{value}</div>
              <CopyToClipboard text={value}>
                <Icon name="copy" className="theme-color-14 cursor-pointer" />
              </CopyToClipboard>
            </div>
          );
        case 'test':
          return row.coupon ? (
            <button
              onClick={() => changeTestMode(row.coupon.id, !row.coupon.test_mode)}
              type="button"
              className={b('test-button', {active: row.coupon.test_mode})}
            />
          ) : (
            ''
          );
        case 'date':
          return renderCol(renderDate(value));
        case 'visual':
          return row.visual_type && row.visual_type !== 'none' ? (
            <Icon name={row.visual_type === 'barcode' ? 'barcode' : 'qr-code'} />
          ) : (
            ''
          );
        case 'lead':
          const leadId =
            getValue(row, 'participation_prize.participation.lead_id') || getValue(row, 'participation.lead_id');
          return leadId ? <Icon name="lead-2" className="theme-color-14" /> : '';
        case 'coupon.type':
          const type = getValue(row, 'coupon.type');
          return type ? type.replace('Coupon', '') : '';
        case 'scenario_step':
          const stepName = getValue(row, 'scenario_step.name');
          const scenarioName = getValue(row, 'scenario_step.scenario.name');
          const name = stepName && scenarioName ? `${scenarioName} - ${stepName}` : '';
          return renderCol(name, 'typical');
        case 'interface':
          const forRender =
            getValue(row, 'participation_prize.participation.interface.name') ||
            getValue(row, 'participation.interface.name');
          return renderCol(forRender, 'typical');
        default:
          return renderCol(getValue(row, key), 'typical');
      }
    };
  };

  return (
    <>
      <div className={b('toolbar-wrapper')}>
        {renderToolbar()}
        <PerPageDropdown
          value={String(props.perPage)}
          onChange={(pages) => props.onPageChange({page: 1, perPage: pages})}
          options={SIZE_PER_PAGE_LIST}
          simpleValue
        />
      </div>

      <ClientTable
        data={data}
        onSortChange={props.onSortChange}
        noDataText={language.EMPTY_TABLE}
        sortField={sort?.name}
        sortOrder={sort?.order}
        columns={[
          {
            path: 'coupon',
            label: language.COUPON_NAME_COLUMN,
            render: dataFormatCallback('coupon.internal_name'),
            width: 100,
          },
          {
            path: 'coupon',
            label: language.INTERNAL_CODE_COLUMN,
            render: dataFormatCallback('coupon.internal_code'),
            width: 100,
          },
          {path: 'coupon', label: language.TYPE_COLUMN, render: dataFormatCallback('coupon.type'), width: 100},
          {path: 'token', label: language.TOKEN_COLUMN, render: dataFormatCallback('token'), width: 350},
          {path: 'visual', label: language.VISUAL_COLUMN, render: dataFormatCallback('visual')},
          {
            name: 'created_at',
            path: 'created_at',
            label: language.CREATION_DATE_COLUMN,
            render: dataFormatCallback('date'),
            sortable: true,
          },
          {
            name: 'generated_at',
            path: 'generated_at',
            label: language.GENERATION_DATE_COLUMN,
            render: dataFormatCallback('date'),
            sortable: true,
          },
          {name: 'coupon', label: language.TEST_COLUMN, render: dataFormatCallback('test')},
          {
            name: 'participation',
            label: language.INTERFACE_COLUMN,
            render: dataFormatCallback('interface'),
            width: 110,
          },
          {
            name: 'scenario_step',
            label: language.SCENARIO_STEP_COLUMN,
            render: dataFormatCallback('scenario_step'),
            width: 150,
          },
          {name: 'lead', label: language.LEAD_COLUMN, render: dataFormatCallback('lead'), width: 80},
        ]}
      />
      <PaginationBar
        data={data}
        currentPage={meta.current_page}
        totalPages={meta.total_pages}
        perPage={props.perPage}
        totalItems={meta.total_count}
        onPageChange={props.onPageChange}
        className={b('pagination-bar')}
      />
    </>
  );
};

TokensGrid.propTypes = {
  data: PropTypes.array,
  language: PropTypes.object,
  meta: PropTypes.object,
  perPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  updateMe: PropTypes.func.isRequired,
  openFilterModal: PropTypes.func.isRequired,
};

TokensGrid.defaultProps = {
  data: [],
  language: {},
  meta: {
    current_page: 1,
    total_pages: 1,
    total_count: 0,
  },
  perPage: 10,
};

export default connect(
  ({languageState}) => ({
    language: languageState.payload.TOKENS,
  }),
  {
    setTest: setTestMode,
  },
)(TokensGrid);
