import React, {useMemo, useState} from 'react';

import {useDispatch} from 'react-redux';
import {useMount} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {getTranslations, getLanguages} from 'client/ducks/translations/actions';

import {set as setMetaAction} from 'client/common/meta/meta.actions';
import Offscreen from 'client/common/offscreen';
import TabSwitcher from 'client/common/tab-switcher';

import {TranslationsLanguages} from 'client/components/translations/translations-languages';

import TranslationsApproval from './translations-approval';
import TranslationsStructure from './translations-structure';

import './translations.scss';

const b = bem('translations');

const TAB_STRUCTURE = 'structure';
const TAB_APPROVAL = 'approval';
const TAB_LANGUAGES = 'languages';

function Translations() {
  const lang = useLanguage('TRANSLATIONS');
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(TAB_STRUCTURE);

  const tabs = useMemo(() => {
    return [
      {title: lang.TABS.STRUCTURE, value: TAB_STRUCTURE},
      {title: lang.TABS.APPROVAL, value: TAB_APPROVAL},
      {title: lang.TABS.LANGUAGES, value: TAB_LANGUAGES},
    ];
  }, [lang]);

  useMount(() => {
    dispatch(getTranslations());
    dispatch(getLanguages());
    dispatch(
      setMetaAction({
        title: lang.TITLE,
      }),
    );
  });

  return (
    <div className={b()}>
      <h1 className={b('heading')}>{lang.TITLE}</h1>
      <TabSwitcher tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} className={b('tabs')} />
      <div className={b('tabs-wrap')}>
        <Offscreen hidden={activeTab !== TAB_STRUCTURE}>
          <TranslationsStructure />
        </Offscreen>
        <Offscreen hidden={activeTab !== TAB_APPROVAL}>
          <TranslationsApproval />
        </Offscreen>
        <Offscreen hidden={activeTab !== TAB_LANGUAGES}>
          <TranslationsLanguages />
        </Offscreen>
      </div>
    </div>
  );
}

export default Translations;
