import React, {PureComponent} from 'react';

import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getFormValues, reduxForm} from 'redux-form';

import {uid} from 'client/services/helpers';

import {getDataSources, createDiagram, updateDiagram, deleteDiagram} from 'client/ducks/results-graphs/actions';
import {diagramMethods, diagramColors, diagramKinds} from 'client/ducks/results-graphs/constants';
import {selectDataSources} from 'client/ducks/results-graphs/selectors';
import {addToastNotifications} from 'client/ducks/toast-notification/actions';

import Icon from 'client/common/icon';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Modal from 'client/common/modals/modal';

import {TextField, ToggleField, ColorField} from 'client/components/common/fields';

import {
  DiagramKindField,
  DiagramDataSourceField,
  DiagramMethodField,
  DiagramLineKindField,
  DiagramDotPrecisionField,
} from './fields';
import {mapInitialValues, mapFormValues, validate} from './formHelpers';
import * as helpers from './helpers';

import './diagram-modal.scss';

class DiagramModal extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    clientId: PropTypes.number.isRequired,
    operationId: PropTypes.number.isRequired,
    autotaskId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    editingDiagram: PropTypes.object,
    isEdit: PropTypes.bool.isRequired,
    dataSources: PropTypes.array.isRequired,
    getDataSources: PropTypes.func.isRequired,
    createDiagram: PropTypes.func.isRequired,
    updateDiagram: PropTypes.func.isRequired,
    deleteDiagram: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    untouch: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    addToastNotifications: PropTypes.func.isRequired,
  };

  static defaultProps = {
    editingDiagram: null,
  };

  static formName = 'DiagramModalForm';

  state = {
    showDeleteModal: false,
  };

  componentDidMount() {
    this.fetchDataSources();
  }

  componentDidUpdate() {
    this.handleDataSourceChange();
    if (this.props.formValues.kind === 'line') {
      this.props.change('exclude_empty_values', true);
    }
  }

  fetchDataSources = () => {
    const {clientId, operationId} = this.props;

    const params = {
      client_id: clientId,
      operation_id: operationId,
      scope: 'operation_datum',
    };

    return this.props.getDataSources(params);
  };

  handleDataSourceChange = () => {
    const {dataSources, formValues} = this.props;

    if (dataSources.length) {
      const isNumberSelected = helpers.isNumberDataSourceSelected(dataSources, formValues);
      const isIntervalSelected = formValues.diagram_method === diagramMethods.INTERVAL;

      if (!isNumberSelected && isIntervalSelected) {
        this.props.change('diagram_method', diagramMethods.VALUE);
      }

      if (!isNumberSelected && formValues.kind === diagramKinds.LINE) {
        this.props.change('column_adapter_id', null);
        this.props.untouch('column_adapter_id');
      }
    }
  };

  handleSave = async (values) => {
    const {editingDiagram, isEdit} = this.props;

    const data = mapFormValues(values, this.props);

    const response = await (isEdit
      ? this.props.updateDiagram(editingDiagram.id, data)
      : this.props.createDiagram(data));
    if (response.error) {
      const errors = response?.payload?.response?.errors;
      this.props.addToastNotifications({
        id: uid(),
        type: 'error',
        description: errors?.base?.[0] || errors?.column_adapter?.[0],
      });
    }
    await this.props.fetchData();
    this.props.onClose();
  };

  handleDelete = () => {
    return this.props.deleteDiagram(this.props.editingDiagram.id).then(this.props.fetchData).then(this.props.onClose);
  };

  isSaveButtonDisabled = () => {
    const {formValues, valid, submitting, isEdit, initialValues} = this.props;
    const isDisabled = !valid || submitting;

    if (isEdit) {
      return isDisabled || isEqual(formValues, initialValues);
    }

    return isDisabled;
  };

  toggleDeleteModal = () => this.setState(({showDeleteModal}) => ({showDeleteModal: !showDeleteModal}));

  renderDeleteModal = () => {
    const {lang} = this.props;

    return (
      <ConfirmationModal
        show={true}
        onClose={this.toggleDeleteModal}
        onCancel={this.toggleDeleteModal}
        message={lang.DELETE_MESSAGE}
        cancelText={lang.CANCEL}
        confirmText={lang.DELETE_GRAPH}
        className="modal-window--theme-4"
        buttonConfirmClass="button--bg-4"
        onConfirm={this.handleDelete}
      />
    );
  };

  render() {
    const {lang, onClose, handleSubmit, dataSources, formValues, isEdit} = this.props;
    const common = {lang, dataSources, formValues};
    const {showDeleteModal} = this.state;

    return (
      <Modal
        dialogClassName="diagram-modal theme-color-9"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={isEdit ? lang.EDIT_TITLE : lang.ADD_TITLE}
        titleButton={
          isEdit && (
            <button
              type="button"
              className="diagram-modal__delete button button--bg-13 button--circle"
              onClick={this.toggleDeleteModal}
            >
              <Icon name="trash" className="button__icon" width={17} height={19} />
            </button>
          )
        }
      >
        {showDeleteModal && this.renderDeleteModal()}
        <form onSubmit={handleSubmit(this.handleSave)}>
          <div className="theme-color-9">
            <TextField name="name" label={lang.NAME} withWrap />
            <ToggleField name="visible" label={lang.VISIBLE} withWrap inline />
            <ToggleField name="exclude_empty_values" label={lang.EXCLUDE_EMPTY} withWrap inline />
            <DiagramKindField lang={lang} />
            <DiagramDataSourceField {...common} />
            {helpers.showMethod(formValues) && <DiagramMethodField {...common} />}
            {helpers.showRangeOfInterval(formValues) && (
              <TextField name="range_of_interval" label={lang.RANGE_OF_INTERVAL} withWrap />
            )}
            {helpers.showLineKind(formValues) && <DiagramLineKindField lang={lang} />}
            {helpers.showIntervalRange(formValues) && <DiagramDotPrecisionField lang={lang} />}
            {helpers.showRange(formValues) && <TextField name="range" label={lang.RANGE} withWrap />}
            {helpers.showColor(formValues) && (
              <ColorField name="color" label={lang.COLOR} colors={diagramColors} withWrap />
            )}
            {helpers.showTooltip(formValues) && <TextField name="tooltip" label={lang.TOOLTIP_TITLE} withWrap />}
          </div>
          <div className="diagram-modal__buttons">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onClose}>
              {lang.CANCEL}
            </button>
            <button
              className="button button--bg-4 modal-window__footer-btn"
              type="submit"
              disabled={this.isSaveButtonDisabled()}
            >
              {isEdit ? lang.SAVE : lang.CREATE}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const DiagramModalForm = reduxForm({
  form: DiagramModal.formName,
  shouldValidate: () => true,
  validate,
})(DiagramModal);

export default connect(
  (state, props) => ({
    lang: state.languageState.payload.RESULTS_GRAPH_MODAL,
    dataSources: selectDataSources(state),
    formValues: getFormValues(DiagramModal.formName)(state) || {},
    initialValues: mapInitialValues(props.editingDiagram),
    isEdit: !!props.editingDiagram,
  }),
  {
    getDataSources,
    createDiagram,
    updateDiagram,
    deleteDiagram,
    addToastNotifications,
  },
)(DiagramModalForm);
