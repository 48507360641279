import React, {memo} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {HoveringField} from 'client/components/common/fields';

import {MEDIA_LEVELS, MEDIA_TYPES} from 'client/components/medias/constants';

import './medias-duration-cell.scss';

const b = bem('medias-duration-cell');

const MediasDurationCell = memo((props) => {
  const {index, item, level, isLocal} = props;
  const compareLevel = isLocal ? MEDIA_LEVELS.DEVICE : level;

  const isVideo = item.media_type === MEDIA_TYPES.VIDEO;
  const isLockedByOwner = item.level !== compareLevel && item.locked;

  return (
    <div className={b()}>
      <HoveringField name={`medias[${index}].duration`} readOnly={isVideo || isLockedByOwner} />
    </div>
  );
});

MediasDurationCell.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  isLocal: PropTypes.bool.isRequired,
  level: PropTypes.string.isRequired,
};

export default MediasDurationCell;
