import moment from 'moment';

export const getTime = (rawValue, withSeconds) => {
  const pattern = withSeconds ? 'HH:mm:ss' : 'HH:mm';

  return rawValue && moment(rawValue).format(pattern);
};

export const getDate = (rawValue) => {
  return rawValue && moment(rawValue).format('DD/MM/YYYY');
};

export const getValue = (dateValue, timeValue) => {
  const nextMoment = moment(dateValue, 'DD/MM/YYYY');
  const timeValueArray = timeValue?.split(':') || [];
  nextMoment.hours(Number(timeValueArray[0]));
  nextMoment.minutes(Number(timeValueArray[1]));
  nextMoment.seconds(Number(timeValueArray[2]) || '00');

  return nextMoment.toISOString();
};
