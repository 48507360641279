import React, {useCallback, useEffect, useMemo} from 'react';

import cn from 'classnames';
import {useSelector} from 'react-redux';
import {useSet} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectAutotask} from 'client/ducks/autotask/selectors';

import AppButton from 'client/common/buttons';

import {ParticipationColumn, ParticipationColumnAdapter} from 'client/models/participations/types';

import ParticipantsTableSettingsModalAllList from '../participants-table-settings-modal-all-list';
import ParticipantsTableSettingsModalSelectedList from '../participants-table-settings-modal-selected-list';

import cssModule from './participants-table-settings-base.module.scss';

type ParticipantsTableSettingsBaseProps = {
  columns: ParticipationColumn[];
  onChange: (columns: ParticipationColumn[]) => void;
  activeTab: 'admin' | 'client';
  onChangeTab: (tab: ParticipantsTableSettingsBaseProps['activeTab']) => void;
  className?: string;
};

const b = bem('participants-table-settings-base', {cssModule});

const ParticipantsTableSettingsBase: React.FC<ParticipantsTableSettingsBaseProps> = (props) => {
  const {columns, activeTab, onChangeTab, onChange, className} = props;

  const lang = useLanguage('PARTICIPANTS_LIST');
  const automationTask = useSelector(selectAutotask);

  const [checkedAdapters, {add: addCheckedAdapters, remove: removeCheckedAdapters, reset: resetAdapters}] =
    useSet<ParticipationColumnAdapter>();
  const [checkedColumns, {add: addCheckedColumns, remove: removeCheckedColumns, reset: resetColumns}] =
    useSet<ParticipationColumn>();

  useEffect(() => {
    resetAdapters();
    resetColumns();
  }, [activeTab, resetAdapters, resetColumns]);

  const onCheckAdapters = (nextAdapters: ParticipationColumnAdapter[], nextState: boolean) => {
    nextAdapters.map((adapter) => {
      if (nextState) {
        addCheckedAdapters(adapter);
      } else {
        removeCheckedAdapters(adapter);
      }
    });
  };

  const onCheckColumns = (nextColumn: ParticipationColumn, nextState: boolean) => {
    if (nextState) {
      addCheckedColumns(nextColumn);
    } else {
      removeCheckedColumns(nextColumn);
    }
  };

  const onAddAdapters = () => {
    const nextColumns = Array.from(checkedAdapters).map((adapter, index) => ({
      automation_task_id: automationTask.id,
      column_adapter_id: adapter.id,
      column_adapter: adapter,
      column_visibility_item_id: null,
      position: columns.length + index + 1,
      type: activeTab === 'admin' ? 'ParticipationDisplayItem' : null,
    })) as unknown as ParticipationColumn[];

    resetAdapters();
    onChange(Array.from(new Set([...columns, ...nextColumns])));
  };
  const onRemoveColumns = () => {
    const nextColumns = new Set(columns);
    checkedColumns.forEach((column) => {
      nextColumns.delete(column);
    });

    resetColumns();
    onChange(Array.from(nextColumns));
  };

  const disabledMoveUp = useMemo(
    () =>
      !checkedColumns.size ||
      Array.from(checkedColumns).some((column) => column.column_adapter_id === columns[0]?.column_adapter_id),
    [columns, checkedColumns],
  );

  const disabledMoveDown = useMemo(
    () =>
      !checkedColumns.size ||
      Array.from(checkedColumns).some(
        (column) => column.column_adapter_id === columns[columns.length - 1]?.column_adapter_id,
      ),
    [columns, checkedColumns],
  );

  const onMoveColumn = useCallback(
    (direction: 'down' | 'up') => {
      const nextSelectedColumns = [...columns];

      const prevIndexes = Array.from(checkedColumns)
        .map((column) => {
          return nextSelectedColumns.findIndex(({column_adapter_id}) => column.column_adapter_id === column_adapter_id);
        })
        .sort((aIndex, bIndex) => (direction === 'up' ? aIndex - bIndex : bIndex - aIndex));

      prevIndexes.forEach((index) => {
        if (index >= 0) {
          const foundColumn = nextSelectedColumns[index];
          nextSelectedColumns.splice(index, 1);
          nextSelectedColumns.splice(direction === 'up' ? index - 1 : index + 1, 0, foundColumn);
        }
      });
      onChange(nextSelectedColumns);
    },
    [onChange, columns, checkedColumns],
  );

  return (
    <div className={cn(b(), className)}>
      <div className={b('column')}>
        <p className={b('column-title')}>{lang.TABLE_SETTINGS_MODAL_ALL_COLUMNS}</p>
        <div className={b('column-overflow')}>
          <ParticipantsTableSettingsModalAllList
            className={b('column-content')}
            selectedColumns={columns}
            checkedAdapters={Array.from(checkedAdapters)}
            onCheckAdapters={onCheckAdapters}
          />
        </div>
      </div>
      <div className={b('buttons', ['move'])}>
        <AppButton
          type="button"
          rounded
          disabledOpacity
          color="games"
          iconName="arrow"
          disabled={!checkedAdapters.size}
          onClick={onAddAdapters}
          className={b('button', ['add'])}
        />
        <AppButton
          type="button"
          rounded
          disabledOpacity
          color="games"
          iconName="arrow"
          disabled={!checkedColumns.size}
          onClick={onRemoveColumns}
          className={b('button', ['remove'])}
        />
      </div>
      <div className={b('column')}>
        <p className={b('column-title')}>{lang.TABLE_SETTINGS_MODAL_DISPLAYED_COLUMNS}</p>
        <div className={b('tabs')}>
          <button
            className={b('tab', {active: activeTab === 'admin'})}
            type="button"
            onClick={() => {
              onChangeTab('admin');
            }}
          >
            {lang.TABLE_SETTINGS_ADMIN_VISIBILITY_TAB}
          </button>
          <button
            className={b('tab', {active: activeTab === 'client'})}
            type="button"
            onClick={() => {
              onChangeTab('client');
            }}
          >
            {lang.TABLE_SETTINGS_CLIENT_VISIBILITY_TAB}
          </button>
        </div>
        <div className={b('column-overflow', ['selected'])}>
          <div className={b('column-content')}>
            <ParticipantsTableSettingsModalSelectedList
              selectedColumns={columns}
              checkedColumns={Array.from(checkedColumns)}
              onCheckColumn={onCheckColumns}
            />
          </div>
        </div>
      </div>

      <div className={b('buttons', ['move'])}>
        <AppButton
          type="button"
          rounded
          disabledOpacity
          color="games"
          iconName="arrow"
          disabled={disabledMoveUp}
          onClick={() => onMoveColumn('up')}
          className={b('button', ['move-up'])}
        />

        <AppButton
          type="button"
          rounded
          disabledOpacity
          color="games"
          iconName="arrow"
          disabled={disabledMoveDown}
          onClick={() => onMoveColumn('down')}
          className={b('button', ['move-down'])}
        />
      </div>
    </div>
  );
};

export default ParticipantsTableSettingsBase;
