import React, {useCallback} from 'react';

import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {resendInvitation} from 'client/ducks/client-users/actions';

import ConfirmationModal from 'client/common/modals/confirmation-modal';

import Icon from 'client/components/common/icon';

import './users-table-resend-invitation-cell.scss';

const b = bem('users-table-resend-invitation-cell');

const UsersTableResendInvitationCell = (props) => {
  const {user} = props;

  const [showModal, toggleModal] = useToggle();
  const lang = useLanguage('AGENCY.USERS_TABLE_CELLS.USERS_TABLE_RESEND_INVITATION_CELL');
  const dispatch = useDispatch();

  const handleConfirm = useCallback(() => {
    dispatch(resendInvitation(user.id)).then(toggleModal);
  }, [dispatch, toggleModal, user.id]);

  return (
    <div className={b()}>
      <Icon name="email" className={b('icon')} onClick={toggleModal} title={lang.HINT} />
      {showModal && (
        <ConfirmationModal
          show={true}
          message={lang.CONFIRM_MESSAGE}
          onClose={toggleModal}
          onCancel={toggleModal}
          cancelText={lang.CANCEL_TEXT}
          confirmText={lang.CONFIRM_TEXT}
          onConfirm={handleConfirm}
          buttonConfirmClass="button--bg-3"
        />
      )}
    </div>
  );
};

UsersTableResendInvitationCell.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default UsersTableResendInvitationCell;
