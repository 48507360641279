import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {selectIsAdmin, selectIsUserLoaded} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import Tokens from 'client/components/tokens';

class TokensPage extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    setMetaAction: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    language: PropTypes.object.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const {setMetaAction, language} = this.props;

    setMetaAction({
      title: language.TITLE,
    });
  }

  render() {
    const {
      match: {
        params: {operationId, clientId},
      },
      isUserLoaded,
      isAdmin,
    } = this.props;

    if (!isUserLoaded) {
      return null;
    }

    if (!isAdmin) {
      return <Redirect to={CLIENT_PAGES.HOME} />;
    }

    return <Tokens operationId={+operationId} clientId={+clientId} />;
  }
}

const mapStateToProps = (state) => ({
  language: state.languageState.payload.TOKENS,
  isAdmin: selectIsAdmin(state),
  isUserLoaded: selectIsUserLoaded(state),
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(TokensPage);
