export default {
  NO_DATA: '@@sms-senders/NO_DATA',
  LOADING: '@@sms-senders/LOADING',
  LOADED: '@@sms-senders/LOADED',
  LOAD_FAILED: '@@sms-senders/LOAD_FAILED',

  ADD_SMS_SENDER_REQUEST: '@@sms-senders/ADD_SMS_SENDER_REQUEST',
  ADD_SMS_SENDER_SUCCESS: '@@sms-senders/ADD_SMS_SENDER_SUCCESS',
  ADD_SMS_SENDER_ERROR: '@@sms-senders/ADD_SMS_SENDER_ERROR',

  UPDATE_SMS_SENDER_REQUEST: '@@sms-senders/UPDATE_SMS_SENDER_REQUEST',
  UPDATE_SMS_SENDER_SUCCESS: '@@sms-senders/UPDATE_SMS_SENDER_SUCCESS',
  UPDATE_SMS_SENDER_ERROR: '@@sms-senders/UPDATE_SMS_SENDER_ERROR',

  REMOVE_SMS_SENDER_REQUEST: '@@sms-senders/REMOVE_SMS_SENDER_REQUEST',
  REMOVE_SMS_SENDER_SUCCESS: '@@sms-senders/REMOVE_SMS_SENDER_SUCCESS',
  REMOVE_SMS_SENDER_ERROR: '@@sms-senders/REMOVE_SMS_SENDER_ERROR',
};
