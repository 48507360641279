import React, {useEffect, useCallback} from 'react';

import find from 'lodash/find';
import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {uid} from 'client/services/helpers';
import {useLanguage, useQueryParams} from 'client/services/hooks';

import {getActiveEmailTemplateKind} from 'client/ducks/email-templates/actions';
import {selectActiveTemplateKind} from 'client/ducks/email-templates/selectors';
import {updateOperationEmailTemplate} from 'client/ducks/operations/actions';

import {useToast} from 'client/common/hooks/useToast';

import {EmailTemplateEditor} from 'client/components/email-template-editor';
import {TemplateData} from 'client/components/email-template-editor/types';
import {EmailTemplateKind} from 'client/models/email-templates/types';
import {ApiDispatch} from 'client/types';

import {getNewVariables} from './helpers';

import cssModule from './diy-op-email-templates.module.scss';

const b = bem('diy-op-email-templates', {cssModule});

type DiyOpEmailTemplatesProps = {
  emailTemplateKinds: EmailTemplateKind[];
  loading?: boolean;
};

export const DiyOpEmailTemplates: React.FC<DiyOpEmailTemplatesProps> = (props) => {
  const {emailTemplateKinds, loading} = props;
  const dispatch: ApiDispatch = useDispatch();
  const [queryParams, setQueryParams] = useQueryParams(null, {}, {parse: {parseNumbers: true}});
  const [isSaving, toggleIsSaving] = useToggle(false);
  const [loadingKind, toggleLoadingKind] = useToggle(false);
  const currentKind = useSelector(selectActiveTemplateKind);

  const {appendToastNotification} = useToast();
  const lang = useLanguage('DIY_OPERATION.DASHBOARDS.DIY_OP_EMAIL_TEMPLATES');

  const currentKindId = queryParams.kindId || find(emailTemplateKinds, {id: queryParams.kindId})?.id || null;

  const currentTemplate =
    currentKind && queryParams.language
      ? find(currentKind?.email_templates || [], {language_tag: queryParams.language}) || null
      : null;

  const fetchActiveTemplateKind = useCallback(async () => {
    toggleLoadingKind();
    await dispatch(getActiveEmailTemplateKind(currentKindId));
    toggleLoadingKind();
  }, [currentKindId, dispatch, toggleLoadingKind]);

  useEffect(() => {
    if (currentKindId && currentKindId !== currentKind?.id) {
      fetchActiveTemplateKind();
    }
  }, [currentKindId, currentKind, fetchActiveTemplateKind]);

  useEffect(() => {
    if (!currentKind && emailTemplateKinds.length) {
      setQueryParams({kindId: emailTemplateKinds[0].id});
    }

    if (currentKind && !currentTemplate && currentKind.email_templates?.length) {
      setQueryParams({language: currentKind.email_templates[0].language_tag});
    }
  }, [currentKind, currentTemplate, emailTemplateKinds, setQueryParams]);

  const handleSaveTemplate = async (
    data: TemplateData,
    mjml: string,
    addedVariables: string[],
    hasPrize: boolean,
    extraData: Record<string, any>,
  ) => {
    toggleIsSaving();
    await dispatch(
      updateOperationEmailTemplate(currentTemplate?.id, {
        operation_email_template: {
          json_part: data,
          mjml_part: mjml,
          email_template_variables: getNewVariables(addedVariables, currentKind, currentTemplate),
          prize_block: hasPrize,
          ...extraData,
        },
      }),
    ).then(({error}) => {
      if (error) {
        return appendToastNotification({
          id: uid(),
          type: 'error',
          title: lang.EMAIL_FAILED_SAVED_TITLE,
          description: lang.EMAIL_FAILED_SAVED_DESCRIPTION,
        });
      }
      return null;
    });
    await fetchActiveTemplateKind();
    toggleIsSaving();
  };

  return (
    <div className={b()}>
      <EmailTemplateEditor
        onSave={handleSaveTemplate}
        loading={loading || loadingKind || isSaving}
        activeTemplate={currentTemplate}
        currentKind={currentKind}
        kinds={emailTemplateKinds}
        onChangeKind={(kindId: number) => setQueryParams({kindId})}
        currentLanguage={queryParams.language}
        languages={currentKind?.email_templates?.map((i) => i.language_tag) || []}
        onChangeLanguage={(language: string) => setQueryParams({language})}
        fetchData={fetchActiveTemplateKind}
      />
    </div>
  );
};
