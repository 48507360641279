import {isEmptyString} from 'client/services/validator';

import {Translation} from 'client/models/language/types';

import {SmsTemplateFormValues} from './types';

export default (values: SmsTemplateFormValues, lang: Record<string, Translation>) => {
  const errors: Record<string, any> = {name: {}, message: {}};

  if (!values) {
    return errors;
  }

  Object.keys(values.name).forEach((langKey) => {
    if (isEmptyString(values.name[langKey])) {
      errors.name[langKey] = lang.REQUIRED;
    }
  });

  Object.keys(values.message).forEach((langKey) => {
    if (isEmptyString(values.message[langKey])) {
      errors.message[langKey] = lang.REQUIRED;
    }
  });

  if (!values.sms_sender_id) {
    errors.sms_sender_id = lang.REQUIRED;
  }

  return errors;
};
