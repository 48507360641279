import React from 'react';

import PropTypes from 'prop-types';

import './tpl-vars-list.scss';

function TemplateVarsList({className, items}) {
  return (
    <div className={`tpl-vars-list ${className}`}>
      {items.map((item, key) => (
        <div className="tpl-vars-list__item" key={key}>
          {`{{${item.name}}}`}
        </div>
      ))}
    </div>
  );
}

TemplateVarsList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
};

TemplateVarsList.defaultProps = {
  className: '',
  items: [],
};

export default TemplateVarsList;
