import {RSAA} from 'redux-api-middleware';

import {callApi} from 'client/services/call-api';
import {getEmail, getToken} from 'client/services/cookie-data-source';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

// getClientsAction - legacy code. Use getClients() instead.
export function getClientsAction(params = '') {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.CLIENTS}?${params}`,
        method: 'GET',
        isAuth: true,
        types: [types.CLIENTS_LIST_LOADING, types.CLIENTS_LIST_LOADED, types.CLIENTS_LIST_LOAD_FAILED],
      },
    });
  };
}

export function getClients(queryParams = {}, isMyClients = false) {
  const myClientsActionTypes = [types.GET_MY_CLIENTS_REQUEST, types.GET_MY_CLIENTS_SUCCESS, types.GET_MY_CLIENTS_ERROR];

  const clientsActionTypes = [types.GET_CLIENTS_REQUEST, types.GET_CLIENTS_SUCCESS, types.GET_CLIENTS_ERROR];

  const actionTypes = isMyClients ? myClientsActionTypes : clientsActionTypes;

  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENTS}`,
      method: 'GET',
      types: actionTypes,
      noCache: true,
      queryParams,
    });
}

export function getClientsWithLeads() {
  return (dispatch) => {
    const joins = 'q[g][][leads_first_name_present]=1&distinct=true';

    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.CLIENTS}?${joins}`,
        method: 'GET',
        isAuth: true,
        types: [types.CLIENTS_LIST_LOADING, types.CLIENTS_LIST_LOADED, types.CLIENTS_LIST_LOAD_FAILED],
      },
    });
  };
}

export function getClient(id, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENTS}/${id}`,
      method: 'GET',
      types: [types.GET_CLIENT_REQUEST, types.GET_CLIENT_SUCCESS, types.GET_CLIENT_ERROR],
      noCache: true,
      queryParams,
    });
}

export function patchClient(id, body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENTS}/${id}`,
      method: 'PATCH',
      types: [types.PATCH_CLIENT_REQUEST, types.PATCH_CLIENT_SUCCESS, types.PATCH_CLIENT_ERROR],
      body,
    });
}

export function deleteClient(id) {
  return (dispatch) => {
    return dispatch({
      type: types.CLIENTS_DELETE_SUCCESS,
      payload: {id},
    });
  };
}

export function patchClientWithFormData(id, formData) {
  return (dispatch) => {
    dispatch({
      type: types.PATCH_CLIENT_WITH_FORM_DATA_REQUEST,
    });

    return fetch(`${API_PATH}${API_METHODS.CLIENTS}/${id}`, {
      method: 'PATCH',
      headers: {
        'X-User-Email': getEmail(),
        'X-User-Token': getToken(),
      },
      body: formData,
    })
      .then((payload) => {
        dispatch({
          type: types.PATCH_CLIENT_WITH_FORM_DATA_SUCCESS,
          payload,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.PATCH_CLIENT_WITH_FORM_DATA_ERROR,
          payload: error,
        });
      });
  };
}
