import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {COUPON_TYPES} from 'client/ducks/coupons/constants';

import Modal from 'client/common/modals/modal';

import CouponInfoApp from './coupon-info-app';
import CouponInfoSogec from './coupon-info-sogec';
import CouponInfoWeezio from './coupon-info-weezio';

import './coupon-info-modal.scss';

const CouponInfoModal = (props) => {
  const {lang, onClose, viewingCoupon} = props;

  return (
    <Modal onClose={onClose} dialogClassName="coupon-info-modal">
      <p className="coupon-info-modal__title">
        {lang.COUPON} [{viewingCoupon.internal_name}]
      </p>
      <div className="coupon-info-modal__content">
        <div className="coupon-info-modal__column coupon-info-modal__column--left">
          <CouponInfoApp lang={lang} viewingCoupon={viewingCoupon} />
        </div>
        <div className="coupon-info-modal__column coupon-info-modal__column--right">
          {viewingCoupon.coupon_type === COUPON_TYPES.WEEZIO && (
            <CouponInfoWeezio lang={lang} viewingCoupon={viewingCoupon} />
          )}
          {viewingCoupon.coupon_type === COUPON_TYPES.SOGEC && (
            <CouponInfoSogec lang={lang} viewingCoupon={viewingCoupon} />
          )}
        </div>
      </div>
      <div className="coupon-info-modal__footer">
        <button className="coupon-info-modal__ok-button button button--bg-9" onClick={onClose}>
          {lang.OK}
        </button>
      </div>
    </Modal>
  );
};

CouponInfoModal.propTypes = {
  lang: PropTypes.object.isRequired,
  viewingCoupon: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect((state) => ({
  lang: {
    ...state.languageState.payload.OPERATION_PAGE.COUPONS_CARD.COUPON_INFO_MODAL,
    ...state.languageState.payload.OPERATION_PAGE.COUPONS_CARD.COUPON_FIELDS,
  },
}))(CouponInfoModal);
