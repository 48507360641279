import find from 'lodash/find';
import moment from 'moment';

import {getCurrentInteraction} from 'client/components/devices/lists/devices-diy-list/devices-diy-list-table/helpers';

const initTime = (value) => {
  return value && moment(value).set('seconds', 0).toISOString();
};

const initDevicePrizes = (prizes, devicePrizes) => {
  return prizes?.map((prize) => {
    const currentDevicePrize = find(devicePrizes, {prize_id: prize.id});

    return {
      prize_id: prize.id,
      imports: {
        tokens_imported: 0,
        tokens_used: 0,
      },
      ...currentDevicePrize,
      prize,
    };
  });
};

export default (device, prizes, operation) => {
  const currentInteraction = getCurrentInteraction(device, operation);

  if (device && currentInteraction) {
    return {
      from: initTime(currentInteraction?.from || operation?.from),
      to: initTime(currentInteraction?.to || operation?.to),
      default_language: currentInteraction?.default_language || operation.default_language,
      device_prizes: initDevicePrizes(prizes, device.device_prizes),
    };
  }
  return {
    from: initTime(operation?.from),
    to: initTime(operation?.to),
    default_language: operation.default_language,
  };
};
