import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {formValueSelector} from 'redux-form';

import {transformQueryParamsForExport} from 'client/services/exportHelpers';

import {getAutotask} from 'client/ducks/autotask/actions';
import {selectAutotask} from 'client/ducks/autotask/selectors';
import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getBroadcast} from 'client/ducks/broadcast/actions';
import {selectBroadcast} from 'client/ducks/broadcast/selectors';
import {selectClientUsers} from 'client/ducks/client-users/selectors';
import {getClientsWithLeads, getClient} from 'client/ducks/clients-list/actions';
import {getAvailableColumnAdaptersByAutomationTask} from 'client/ducks/column-adapters/actions';
import {selectAvailableParticipationColumns} from 'client/ducks/column-adapters/selectors';
import {getCustomColumns} from 'client/ducks/custom-columns/actions';
import {selectClientName, selectClient} from 'client/ducks/leads-list/selectors';
import {PARTICIPANTS_SORT_MAP} from 'client/ducks/participants-list/constants';
import {getColumnVisibilityItems, getDisplayColumns} from 'client/ducks/participation-display-items/actions';
import {selectColumnVisibilityItems} from 'client/ducks/participation-display-items/selectors';
import {getParticipationsExportReport} from 'client/ducks/participation-export/actions';
import {getExportReportSelector} from 'client/ducks/participation-export/selectors';
import {getAvailableExportColumns} from 'client/ducks/participation-export/selectors';
import {getParticipationsTableData} from 'client/ducks/participations/actions';
import {selectParticipantsTableData, selectParticipationsTableDataMeta} from 'client/ducks/participations/selectors';

import {CLIENT_PAGES} from 'client/common/config';

import Icon from 'client/components/common/icon';
import TitleBlock from 'client/components/common/title-block';

import ParticipantsExportTypeModal from 'client/components/participants/components/modals/participants-export-type-modal';
import ParticipantsListModals from 'client/components/participants/participants-list/participants-list-modals';

import {selectIsAdmin} from '../../../ducks/user/selectors';
import {getClientPage} from '../../../services/helpers';
import Spinner from '../../common/spinner';
import ParticipantsFilterModal from '../components/modals/participants-filters-modal';
import ParticipantsCommentsPopover from '../components/popovers/participants-comments-popover';
import ParticipantsSummaryPopover from '../components/popovers/participants-summary-popover';
import ParticipantsMainDataGrid from '../components/tables/participants-main-data-grid/participants-main-data-grid';
import {mapFilter, mapFilterForUrl} from '../participants-list/helper';

import './participants-export.scss';

class ParticipantsExport extends ReactQueryParams {
  static defaultProps = {
    broadcast: {},
    clientId: '',
    allColumns: [],
    exportReport: null,
  };

  static propTypes = {
    getParticipationsTableData: PropTypes.func.isRequired,
    getColumnAdapters: PropTypes.func.isRequired,
    columnVisibilityItems: PropTypes.array.isRequired,
    participationDisplayItems: PropTypes.array.isRequired,
    getAdminDisplayedColumns: PropTypes.func.isRequired,
    getCustomColumns: PropTypes.func.isRequired,
    isNational: PropTypes.bool.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    getClient: PropTypes.func.isRequired,
    getAutotask: PropTypes.func.isRequired,
    generalParticipationColumns: PropTypes.object.isRequired,
    getInternalColumns: PropTypes.func,
    clientName: PropTypes.string,
    importType: PropTypes.string,
    exportType: PropTypes.string,
    allColumns: PropTypes.array,
    isAdmin: PropTypes.bool.isRequired,
    broadcast: PropTypes.object,
    exportId: PropTypes.string,
    clientId: PropTypes.string,
    operationId: PropTypes.string,
    taskId: PropTypes.string,
    autoTask: PropTypes.object,
    getClientDisplayedColumns: PropTypes.func.isRequired,
    exportReport: PropTypes.object,
    clientUsers: PropTypes.array.isRequired,
    ...withRouter.propTypes,
  };

  static DEFAULT_SORT_FIELD = 'id';
  static DEFAULT_SORT_ORDER = 'asc';
  static DEFAULT_FIRST_PAGE = 1;
  static DEFAULT_PER_PAGE = 5;
  static DEFAULT_CUSTOM_COLUMNS_PER_PAGE = 100;
  static DEFAULT_SEARCH_OPTION = 'lead_first_name_cont';

  static MODALS_MAP = {
    FILTERS: 'FILTERS',
    TABLE_SETTINGS: 'TABLE_SETTINGS',
    CLIENT_VISIBILITY_SETTINGS: 'CLIENT_VISIBILITY_SETTINGS',
    PARTICIPATIONS: 'PARTICIPATIONS',
    EXPORT_MODALS: {
      EXPORT_TYPE_MODAL: 'EXPORT_TYPE_MODAL',
      GENERATE_API_EXPORT_MODAL: 'GENERATE_API_EXPORT_MODAL',
      GENERATE_EXPORT_MODAL: 'GENERATE_EXPORT_MODAL',
    },
  };

  static settingsModalTabKeys = {
    adminVisibility: 'adminVisibility',
    clientVisibility: 'clientVisibility',
    adminExport: 'adminExport',
    clientExport: 'clientExport',
  };

  constructor(props) {
    super(props);
    this.LANGUAGE = props.languageState.payload.PARTICIPANTS_PORT;

    this.state = {
      page: ParticipantsExport.DEFAULT_PAGE,
      perPage: ParticipantsExport.DEFAULT_PER_PAGE,
      sort: {
        name: '',
        order: '',
      },
      search: '',
      showClear: false,
      searchDefault: '',
      filter: {},
      visibleModal: '',
      isAll: false,
      checkedParticipations: [],
      tableSettingsModalTab: ParticipantsExport.settingsModalTabKeys.adminVisibility,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({isLoading: true});
    this.fetchAllData().then(() => {
      this.setState({isLoading: false});
      this.updateBreadcrumbs();
    });
  }

  componentWillUnmount() {
    this.checkedLeads = [];
    this.props.clearBreadcrumbs();
  }

  updateBreadcrumbs = () => {
    const {isNational} = this.props;

    let breadcrumbs = [];
    if (this.props.clientId && this.props.autoTask) {
      const {clientId, operationId, taskId} = this.props;
      breadcrumbs = breadcrumbs.slice(0, -1).concat([
        {
          href: getClientPage(this.props.autoTask.client) + '/' + clientId,
          name: this.props.autoTask.client.name,
          hidden: isNational,
        },
        {
          href: `${getClientPage(this.props.autoTask.client)}/${clientId}${CLIENT_PAGES.OPERATIONS}/${operationId}`,
          name: this.props.autoTask.operation.name,
          hidden: isNational,
        },
        {
          href:
            `${getClientPage(this.props.autoTask.client)}/${clientId}${CLIENT_PAGES.OPERATIONS}/${operationId}` +
            `${CLIENT_PAGES.AUTOTASK}/${taskId}`,
          name: this.props.autoTask.name,
          hidden: isNational,
        },
        {
          name: 'Participations export',
          hidden: isNational,
        },
      ]);
    }

    return this.props.setBreadcrumbs(breadcrumbs);
  };

  fetchData = () => {
    this.setState({
      isLoading: true,
    });
    return Promise.all([this.fetchTableData(), this.fetchColumns()]).finally(() => {
      this.setState({
        isLoading: false,
      });
    });
  };

  fetchAllData = async () => {
    this.setState({isLoading: true});
    await Promise.all([this.fetchInitial(), this.fetchTableData(), this.fetchExportReport(), this.fetchColumns()]);
    this.setState({isLoading: false});
  };

  fetchAutotask = () => {
    if (this.props.clientId) {
      return this.props
        .getAutotask(this.props.taskId, {
          include: {
            client: null,
            operation: null,
          },
        })
        .catch((error) => {
          if (error.status === 404) {
            this.props.history.push(CLIENT_PAGES.ACCESS_DENIED);
          }
        });
    }
    return Promise.resolve();
  };

  fetchTableData = () => {
    const {clientId, operationId, taskId, exportId} = this.props;
    const {queryParams} = this;

    const sort =
      `${
        (queryParams.sort && PARTICIPANTS_SORT_MAP[queryParams.sort.name]) || ParticipantsExport.DEFAULT_SORT_FIELD
      } ` + `${(queryParams.sort && queryParams.sort.order) || ParticipantsExport.DEFAULT_SORT_ORDER}`;

    const search = queryParams.search;

    const params = {
      include: {
        client: 'opt_in_columns',
        operation: 'client.opt_in_columns',
        automation_task: 'client',
        lead: {
          client: null,
          opt_in_columns: null,
        },
        visuals: null,
        device: null,
        interface: null,
        place: null,
        source: null,
        scenario_step_executions: null,
        operation_datum: null,
      },
      query_string: search,
      q: {
        s: sort,
      },
      page: queryParams.page || ParticipantsExport.DEFAULT_FIRST_PAGE,
      per_page: queryParams.perPage || ParticipantsExport.DEFAULT_PER_PAGE,
    };

    if (clientId && operationId && taskId) {
      params.automation_task_id = taskId;
    }

    if (exportId) {
      params.q.exported_participations_participation_export_participation_export_reports_id_eq = exportId;
      params.participation_export_report_id = exportId;
    }

    if (queryParams.filter) {
      const {q, distinct} = mapFilterForUrl(mapFilter(queryParams.filter));

      params.distinct = distinct;
      params.q = {
        ...params.q,
        ...q,
      };
      params.q.g = [params.q.g];
      const {showHidden, showNotHidden} = queryParams.filter;
      if (showHidden && !showNotHidden) {
        params.q.visible_by_client_eq = false;
      }

      if (!showHidden && showNotHidden) {
        params.q.visible_by_client_eq = true;
      }
    }

    return this.props.getParticipationsTableData(params);
  };

  fetchExportReport = () => {
    return this.props.getParticipationsExportReport(this.props.exportId, {
      include: {
        user: null,
        participation_export: {
          participation_export_items: {
            column_adapter: null,
          },
          memberships: {
            client_user: null,
          },
        },
      },
    });
  };

  fetchInitial = async () => {
    await Promise.all([this.props.getColumnAdapters(this.props.taskId, 'participations_export'), this.fetchAutotask()]);
  };

  fetchColumns = () => {
    return Promise.all([
      this.props.getClientDisplayedColumns(this.props.taskId),
      this.props.getAdminDisplayedColumns(this.props.taskId),
    ]);
  };

  onFetch = () => {
    this.fetchData();
  };

  onPaginationChange = ({sortField: name, sortOrder: order, page, perPage}) => {
    this.setQueryParams(
      {
        filter: mapFilter(this.queryParams.filter),
        page,
        perPage,
        sort: {name, order},
      },
      true,
    );
    this.fetchTableData();
  };

  onSortChange = (name, order) => {
    this.setQueryParams(
      {
        filter: mapFilter(this.queryParams.filter),
        sort: {name, order},
      },
      true,
    );
    this.fetchTableData();
  };

  onPageChange = ({page, perPage}) => {
    this.setQueryParams(
      {
        filter: mapFilter(this.queryParams.filter),
        page,
        perPage,
      },
      true,
    );
    this.fetchTableData();
  };

  handleShowModal = (name, data) => {
    this.setState({visibleModal: name, ...data});
  };

  handleCloseModal = () => this.setState({visibleModal: '', isAll: false});

  onSearchChange = (search) => {
    this.setQueryParams(
      {
        filter: mapFilter(this.queryParams.filter),
        search,
        page: ParticipantsExport.DEFAULT_FIRST_PAGE,
      },
      true,
    );
    this.fetchTableData();
  };

  onSearchClear = () => {
    this.setQueryParams(
      {
        filter: mapFilter(this.queryParams.filter),
        search: '',
      },
      true,
    );
    this.fetchTableData();
  };

  onFilterChange = (filter) => {
    this.setQueryParams(
      {
        filter: mapFilter(filter),
        page: 1,
      },
      true,
    );
    this.fetchTableData();
  };

  onFilterClear = () => {
    this.onFilterChange({});
  };

  onFilterFieldClear = (fieldsList, value = false) => {
    const obj = {...this.queryParams.filter};

    if (value) {
      obj[fieldsList] = obj[fieldsList].filter((item) => item.value !== value);
    } else {
      delete obj[fieldsList];
    }

    this.onFilterChange(obj);
  };

  onShowClientVisibilitySettings = () =>
    this.handleShowModal(ParticipantsExport.MODALS_MAP.TABLE_SETTINGS, {
      tableSettingsModalTab: ParticipantsExport.settingsModalTabKeys.clientVisibility,
    });

  onShowTableSettings = () =>
    this.handleShowModal(ParticipantsExport.MODALS_MAP.TABLE_SETTINGS, {
      tableSettingsModalTab: ParticipantsExport.settingsModalTabKeys.adminVisibility,
    });

  getCheckedParticipations = (checkedParticipations) => {
    this.setState({checkedParticipations});
  };

  getQueryStringParamsForRequest = () => {
    const {queryParams} = this;

    return queryParams.search
      ? {
          query_string: queryParams.search,
          query_param: queryParams.searchOption || ParticipantsExport.DEFAULT_SEARCH_OPTION,
        }
      : {};
  };

  getExportQueryParams = () => {
    const queryParams = {...mapFilterForUrl(mapFilter(this.queryParams.filter))};

    return {
      query_params: transformQueryParamsForExport(queryParams),
      ...this.getQueryStringParamsForRequest(),
    };
  };

  render() {
    const {
      availableExportColumns,
      participantsList,
      meta,
      clientId,
      participationDisplayItems,
      columnVisibilityItems,
      isAdmin,
      exportReport,
      clientUsers,
    } = this.props;
    const {visibleModal, tableSettingsModalTab, checkedParticipations, isAll, isLoading} = this.state;
    const {queryParams} = this;
    const columnExportItems = exportReport ? exportReport.columnExportItems : [];
    const isErrorExportTypeModal = !Array.isArray(checkedParticipations) || !checkedParticipations.length;

    const pagination = {
      page: +queryParams.page || ParticipantsExport.DEFAULT_FIRST_PAGE,
      perPage: +queryParams.perPage || ParticipantsExport.DEFAULT_PER_PAGE,
      sortField: queryParams.sort?.name || ParticipantsExport.DEFAULT_SORT_FIELD,
      sortOrder: queryParams.sort?.order || ParticipantsExport.DEFAULT_SORT_ORDER,
    };

    return (
      <div className="theme-color-9">
        {isLoading ? (
          <Spinner centered />
        ) : (
          <>
            <ParticipantsFilterModal
              show={visibleModal === ParticipantsExport.MODALS_MAP.FILTERS}
              onClose={this.handleCloseModal}
              onFilterChange={this.onFilterChange}
              clientId={this.props.clientId}
              filter={queryParams.filter}
              taskId={this.props.taskId}
            />
            {exportReport && (
              <div className="flex-container flex-justify-between">
                <div className="page__title-block">
                  <TitleBlock theme={true} separator={true}>
                    <TitleBlock.Item>{exportReport.name}</TitleBlock.Item>
                    <TitleBlock.Item>
                      {`${this.LANGUAGE.PAGE_TITLE_FORMAT}: `}
                      <b>{exportReport.format}</b>
                    </TitleBlock.Item>
                  </TitleBlock>
                </div>
                <div className="participants-export__header-icons">
                  <div className="flex-container flex-align-center">
                    <div>
                      <ParticipantsSummaryPopover
                        exportReport={exportReport}
                        refresh={this.fetchExportReport}
                        position="bottom"
                      >
                        <div className="leads-import__header-icon">
                          <Icon name="summary" />
                        </div>
                      </ParticipantsSummaryPopover>
                    </div>
                    <div>
                      <ParticipantsCommentsPopover comment={exportReport.comment} position="bottom">
                        <div className="participants-export__header-icon">
                          <Icon name="message" className={exportReport.comment ? '' : 'hidden'} />
                        </div>
                      </ParticipantsCommentsPopover>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div>
              <ParticipantsMainDataGrid
                data={participantsList}
                pagination={pagination}
                meta={meta}
                isAdmin={isAdmin}
                isReportTable={true}
                clientId={clientId}
                perPage={parseInt(queryParams.perPage, 10)}
                onPaginationChange={this.onPaginationChange}
                onPageChange={this.onPageChange}
                onSortChange={this.onSortChange}
                onChangeCheckedList={(checkedList) => this.setState({checkedParticipations: checkedList})}
                onFilterShow={() => this.handleShowModal(ParticipantsExport.MODALS_MAP.FILTERS)}
                onFilterClear={this.onFilterClear}
                onFilterFieldClear={this.onFilterFieldClear}
                onSearchOptionChange={this.onSearchOptionChange}
                searchOption={queryParams.searchOption || ParticipantsExport.DEFAULT_SEARCH_OPTION}
                onSearchChange={this.onSearchChange}
                onSearchClear={this.onSearchClear}
                showClear={!!queryParams.search}
                filter={queryParams.filter}
                participationDisplayItems={participationDisplayItems}
                columnVisibilityItems={columnVisibilityItems}
                columnExportItems={columnExportItems}
                onShowClientVisibilitySettings={this.onShowClientVisibilitySettings}
                onShowTableSettings={this.onShowTableSettings}
                onShowExport={() => this.handleShowModal(ParticipantsExport.MODALS_MAP.EXPORT_MODALS.EXPORT_TYPE_MODAL)}
                onShowExportAll={() =>
                  this.handleShowModal(ParticipantsExport.MODALS_MAP.EXPORT_MODALS.EXPORT_TYPE_MODAL, {
                    isAll: true,
                  })
                }
              />
            </div>
            <ParticipantsListModals
              visibleModal={visibleModal}
              generalParticipationColumns={this.props.generalParticipationColumns}
              tableSettingsModalTab={tableSettingsModalTab}
              columnVisibilityItems={columnVisibilityItems}
              participationDisplayItems={participationDisplayItems}
              handleCloseModal={this.handleCloseModal}
              handleShowModal={this.handleShowModal}
              onFilterChange={this.onFilterChange}
              exportType={this.props.exportType}
              isAdmin={isAdmin}
              isErrorExportTypeModal={isErrorExportTypeModal}
              isAll={isAll}
              getExportQueryParams={this.getExportQueryParams}
              checkedParticipations={checkedParticipations}
              availableExportColumns={availableExportColumns}
              clientUsers={clientUsers}
              queryParams={queryParams}
              onFetch={this.onFetch}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const exportTypeFormSelector = formValueSelector(ParticipantsExportTypeModal.formName);
  return {
    languageState: state.languageState,
    participantsList: selectParticipantsTableData(state),
    customColumns: state.customColumns,
    broadcast: selectBroadcast(state),
    meta: selectParticipationsTableDataMeta(state),
    clientName: selectClientName(state),
    client: selectClient(state),
    autoTask: selectAutotask(state),
    generalParticipationColumns: selectAvailableParticipationColumns(state),
    isAdmin: selectIsAdmin(state),
    exportReport: getExportReportSelector(state),
    columnVisibilityItems: selectColumnVisibilityItems(state),
    clientUsers: selectClientUsers(state, ownProps.clientId),
    exportType: exportTypeFormSelector(state, 'export'),
    availableExportColumns: getAvailableExportColumns(state),
    participationDisplayItems: state.participationDisplayItems.payload,
  };
};

const mapDispatchToProps = {
  getAutotask,
  getClient,
  getParticipationsTableData,
  setBreadcrumbs,
  clearBreadcrumbs,
  getClientsWithLeads,
  getCustomColumns,
  getColumnAdapters: getAvailableColumnAdaptersByAutomationTask,
  getBroadcast,
  getParticipationsExportReport,
  getClientDisplayedColumns: getColumnVisibilityItems,
  getAdminDisplayedColumns: getDisplayColumns,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ParticipantsExport));
