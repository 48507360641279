import get from 'lodash/get';

export const getSourceNames = (sources) => {
  const sourceNames = sources.reduce((acc, item) => [...acc, item.source_type], []);

  return Array.from(new Set(sourceNames));
};

const unionDuplicated = (array) => {
  const transformed = {};
  Object.entries(array).forEach(([, {source, visits_count}]) => {
    if (transformed[source]) {
      transformed[source] += visits_count;
    } else {
      transformed[source] = visits_count;
    }
  });

  return Object.entries(transformed).map(([key, value]) => ({
    source_type: key,
    visits_count: value,
  }));
};

const getSourcesByType = (campaigns) => {
  const sourcesByType = [];

  campaigns.forEach((campaign) => {
    const types = campaign.records.length
      ? campaign.records.reduce((acc, {source_type, visits_count}) => {
          const sourceKey = source_type || 'N/A';
          if (visits_count) {
            acc.push({source: sourceKey, visits_count});
          }
          return acc;
        }, [])
      : [];
    sourcesByType.push(...types);
  });

  return unionDuplicated(sourcesByType);
};

export const calculateTotal = (arr, key) => {
  return arr.reduce((acc, item) => (key ? acc + item[key] : acc + item), 0);
};

const filterNotEmptyRecords = (item) => {
  return {
    ...item,
    participations_count: item.records?.reduce((total, elem) => total + elem.participations_count, 0) || 0,
    records: item.records?.filter((record) => record.participations_count > 0) || [],
  };
};

export const checkForEmptyRecords = (source) => {
  const anyNonEmptyRecord = source.records.length
    ? source.records.some(({participations_count, visits_count}) => {
        return participations_count + visits_count > 0;
      })
    : false;
  return anyNonEmptyRecord
    ? source
    : {
        ...source,
        records: [],
      };
};

const getVisitsCount = (item) => {
  if (!item.records || !item.records.length) {
    return 0;
  }
  return calculateTotal(item.records, 'visits_count');
};

export const sourcesMapper = (src) => {
  const sources = {
    campaigns: [],
    plans: [],
    direct_access: {
      interfaces: [],
    },
    ...src,
  };
  const campaignsVisits = sources.campaigns.map(getVisitsCount);
  const plansVisits = sources.plans.map(getVisitsCount);
  const directVisits = calculateTotal(sources.direct_access.interfaces, 'visits_count');
  const filteredDevices = sources.plans.map(filterNotEmptyRecords);

  return {
    total_direct_and_campaigns_participations: get(sources, 'total_direct_and_campaigns_participations', 0),
    total_direct_and_campaigns_visits: get(sources, 'total_direct_and_campaigns_visits', 0),
    visitsBySourceType: {
      campaigns: calculateTotal(campaignsVisits),
      devices: calculateTotal(plansVisits),
      sources: getSourcesByType(sources.campaigns),
      direct_access: directVisits,
    },
    sources: {
      campaigns: sources.campaigns,
      devices: filteredDevices,
      direct_access: {
        to: sources.direct_access.to,
        from: sources.direct_access.from,
        interfaces: sources.direct_access.interfaces,
      },
    },
  };
};
