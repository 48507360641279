import React from 'react';

// import cn from 'classnames';
import './empty-cell.scss';

export default function EmptyCell() {
  return <div className="table-empty-cell_disabled"> </div>;
}

// const EmptyCell = ({defaultText = 'N/A'}) => <div style={{textAlign: 'center'}}>{defaultText}</div>;
