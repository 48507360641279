import React, {useMemo, useState} from 'react';

import {useDispatch} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {updateAnimationDetails} from 'client/ducks/animations/actions';

import AppButton from 'client/common/buttons';
import Icon from 'client/common/icon';
import Modal from 'client/common/modals/modal';

import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container';
import AnimationAnimatorFieldset from 'client/components/animations/fieldsets/animation-animator-fieldset';
import AnimationEventFieldset from 'client/components/animations/fieldsets/animation-event-fieldset';
import AnimationProductsFieldset from 'client/components/animations/fieldsets/animation-products-fieldset';
import {ANIMATION_STATUSES} from 'client/components/instore/constants';
import {canEditAnimationDetails} from 'client/components/instore/helpers';

import mapping from '../animation-details-block/mapping';

import './animation-details-mobile-block.scss';

const b = bem('animation-details-mobile-block');

const MODALS = {
  ANIMATOR: 'ANIMATOR',
  PRODUCT: 'PRODUCT',
  EVENT: 'EVENT',
};

const AnimationDetailsMobileBlock = () => {
  const lang = useLanguage('ANIMATIONS.BLOCKS.ANIMATION_DETAILS_MOBILE_BLOCK');
  const {formValues, invalid} = useReduxForm(AnimationConfigContainerFormName);
  const [openedModal, setOpenedModal] = useState(null);
  const detailsReadOnly = !canEditAnimationDetails(formValues.status);
  const dispatch = useDispatch();

  const canEdit = [ANIMATION_STATUSES.ISSUED, ANIMATION_STATUSES.VALIDATED, ANIMATION_STATUSES.RECEIVED].includes(
    formValues.status,
  );

  const detailsData = useMemo(() => {
    const {details_reco, details_products, details_event} = formValues?.kit_mapping || {};
    return [
      {
        name: lang.RECOMMENDED_ANIMATOR,
        button: lang.CONTACT_BUTTON,
        isShown: details_reco,
        value: MODALS.ANIMATOR,
        onClick: () => setOpenedModal(MODALS.ANIMATOR),
      },
      {
        name: lang.PRODUCT_ORDERS,
        button: lang.DETAILS_BUTTON,
        isShown: details_products,
        value: MODALS.PRODUCT,
        onClick: () => setOpenedModal(MODALS.PRODUCT),
      },
      {
        name: lang.EVENT_INSTALLATION,
        button: lang.DETAILS_BUTTON,
        isShown: details_event,
        value: MODALS.EVENT,
        onClick: () => setOpenedModal(MODALS.EVENT),
      },
    ];
  }, [formValues?.kit_mapping, lang]);

  const onClose = () => {
    setOpenedModal(null);
  };

  const onSaveDetailModal = () => {
    if (canEdit) {
      dispatch(updateAnimationDetails(formValues?.id, mapping(formValues)));
    }
    onClose();
  };

  return (
    <div className={b()}>
      <ul className={b('details-list')}>
        {detailsData?.map(
          ({name, button, isShown, onClick}) =>
            !!isShown && (
              <li className={b('details-item')} key={name}>
                <Icon name="check-sign" className={b('details-item-icon')} />
                <span className={b('details-item-name')}>{name}</span>
                <AppButton
                  className={b('details-item-button')}
                  label={button}
                  color="light-clients"
                  onClick={onClick}
                />
              </li>
            ),
        )}
        {!!openedModal && (
          <Modal
            show
            onClose={onClose}
            dialogClassName={b('details-modal')}
            bodyClassName={b('details-modal-body')}
            title={lang.MODAL_TITLES[openedModal]}
          >
            {openedModal === MODALS.ANIMATOR && <AnimationAnimatorFieldset readOnly={detailsReadOnly} />}
            {openedModal === MODALS.PRODUCT && (
              <AnimationProductsFieldset readOnly={detailsReadOnly} values={formValues.products || []} />
            )}
            {openedModal === MODALS.EVENT && <AnimationEventFieldset readOnly={detailsReadOnly} />}

            <AppButton
              label={canEdit ? lang.MODAL_SUBMIT_BUTTON : lang.MODAL_OK_BUTTON}
              color="devices"
              disabled={invalid}
              className={b('details-ok-button')}
              onClick={onSaveDetailModal}
            />
          </Modal>
        )}
      </ul>
    </div>
  );
};

export default AnimationDetailsMobileBlock;
