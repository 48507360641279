import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getTaskIndex(queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.TASK_INDEX_ITEMS}`,
      method: 'GET',
      types: [types.GET_TASK_INDEX_REQUEST, types.GET_TASK_INDEX_SUCCESS, types.GET_TASK_INDEX_ERROR],
      queryParams,
    });
  };
}
