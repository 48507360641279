import React, {Component} from 'react';

import {Table, Column} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import ExportAPIHistoryToolsCell from '../cells/api-history-tools-cell';
import DateCell from '../cells/date-cell';
import TextCell from '../cells/text-cell';

class ParticipantsAPIHistoryCardDataGrid extends Component {
  static propTypes = {
    clientId: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    languageState: PropTypes.object.isRequired,
    updateCallBack: PropTypes.func.isRequired,
    editCallBack: PropTypes.func.isRequired,
    isDashboard: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.PARTICIPANTS_EXPORT_HISTORY;
  }

  render() {
    const {data, isDashboard} = this.props;

    return (
      <div className="fixed-table fixed-table--leads-card participants-export-history-card-table">
        <div
          className={`main-text main-text--bold
        participants-export-history-card-tabel-title${isDashboard ? '-dashboard' : ''}`}
        >
          {this.LANGUAGE.API_EXPORT_GENERATED_LABEL}:
        </div>
        <Table
          rowsCount={data.length}
          headerHeight={0}
          rowHeight={34}
          heigth={140}
          maxHeight={140}
          width={540}
          data={data.reverse()}
        >
          <Column cell={<TextCell data={this.props.data} field="format" />} width={40} />
          <Column cell={<DateCell data={this.props.data} field="endDate" />} width={40} />
          <Column cell={<TextCell data={this.props.data} field="nullStr" />} width={120} />
          <Column
            cell={
              <ExportAPIHistoryToolsCell
                data={this.props.data}
                field="APITools"
                updateCallBack={this.props.updateCallBack}
                editCallBack={this.props.editCallBack}
                isDashboard={isDashboard}
              />
            }
            width={40}
          />
        </Table>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(ParticipantsAPIHistoryCardDataGrid);
