export default {
  GET_COUPON_REQUEST: '@@coupon-tokens/GET_COUPON_REQUEST',
  GET_COUPON_SUCCESS: '@@coupon-tokens/GET_COUPON_SUCCESS',
  GET_COUPON_ERROR: '@@coupon-tokens/GET_COUPON_ERROR',

  GET_COUPON_TOKENS_REQUEST: '@@coupon-tokens/GET_COUPON_TOKENS_REQUEST',
  GET_COUPON_TOKENS_SUCCESS: '@@coupon-tokens/GET_COUPON_TOKENS_SUCCESS',
  GET_COUPON_TOKENS_ERROR: '@@coupon-tokens/GET_COUPON_TOKENS_ERROR',

  SET_COUPON_TEST_MODE_REQUEST: '@@coupon-tokens/SET_COUPON_TEST_MODE_REQUEST',
  SET_COUPON_TEST_MODE_SUCCESS: '@@coupon-tokens/SET_COUPON_TEST_MODE_SUCCESS',
  SET_COUPON_TEST_MODE_ERROR: '@@coupon-tokens/SET_COUPON_TEST_MODE_ERROR',
};
