import React from 'react';

import PropTypes from 'prop-types';

import {CLIENT_PAGES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';

import {TranslationJsx} from 'client/models/language/types';

import BCBroadcastControls from '../../bc-broadcast-controls';

function ResultsColumn(props) {
  const {linkText, messageTask} = props;
  const link = CustomLink.createTaskLink(messageTask) + CLIENT_PAGES.MESSAGE_TASK_RESULTS;

  return (
    <div className="flex-container flex-justify-between">
      <CustomLink to={link} className="link display-block">
        {linkText}
      </CustomLink>
      <BCBroadcastControls {...props} {...messageTask} />
    </div>
  );
}

ResultsColumn.propTypes = {
  linkText: TranslationJsx.isRequired,
  onToggleMessageTaskState: PropTypes.func.isRequired,
  onToggleWebhooks: PropTypes.func.isRequired,
  messageTask: PropTypes.shape({
    taskName: PropTypes.string,
    taskType: PropTypes.string,
    clientType: PropTypes.string,
    operationId: PropTypes.number,
    clientId: PropTypes.number,
    taskId: PropTypes.number,
  }).isRequired,
};

export default ResultsColumn;
