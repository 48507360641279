import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import bem from 'client/services/bem';
import {getClientPage} from 'client/services/helpers';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getEmailTemplates} from 'client/ducks/email-templates/actions';
import {getOperation} from 'client/ducks/operations/actions';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import Icon from 'client/common/icon';
import Pagination from 'client/common/paginations/pagination';

import TitleBlock from 'client/components/common/title-block';

import AddTemplateModal from '../components/modals/add-template-modal';
import TemplateItem from '../components/template-item';

import '../templates.scss';
import cssModule from './templates-list.module.scss';

const b = bem('templates-list', {cssModule});

class Templates extends ReactQueryParams {
  static propTypes = {
    templatesMeta: PropTypes.object,
    isAdmin: PropTypes.bool.isRequired,
    operation: PropTypes.object.isRequired,
    getOperation: PropTypes.func.isRequired,
    operationId: PropTypes.number.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    emailTemplates: PropTypes.array.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    getEmailTemplates: PropTypes.func.isRequired,
  };

  static defaultProps = {
    templatesMeta: {},
  };

  static DEFAULT_PAGE_NUM = 1;
  static DEFAULT_PER_PAGE = 8;

  constructor(props) {
    super(props);

    this.state = {
      showAddTemplate: false,
      editingTemplate: {},
    };

    this.LANGUAGE = props.languageState.payload.TEMPLATES;
  }

  componentDidMount() {
    this.updateBreadcrumbs();
    this.updateTemplates();
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  updateTemplates = () => {
    const {queryParams: params} = this;
    return this.props.getEmailTemplates({
      q: {
        operation_id_eq: this.props.operationId,
        template_email_templates_message_template_kind_id_null: true,
      },
      per_page: Templates.DEFAULT_PER_PAGE,
      page: params.page || Templates.DEFAULT_PAGE_NUM,
      include: ['email_template_variables', 'email_template_links'],
    });
  };

  updateBreadcrumbs = () => {
    return this.props.getOperation(this.props.operationId, {include: 'client.agency'}).then(() => {
      const {
        languageState: {
          payload: {BREADCRUMBS},
        },
        operation: {client, ...operation},
      } = this.props;

      const clientHref = `${getClientPage(client)}/${client.id}`;
      const agencyBreadcrumb = client.agency
        ? [
            {
              href: `${getClientPage(client.agency)}/${client.agency.id}`,
              name: client.agency.name,
            },
          ]
        : [];

      const operationHref = `${clientHref}${CLIENT_PAGES.OPERATIONS}/${operation.id}`;
      const customOpDBHref = `${operationHref}${CLIENT_PAGES.CUSTOM_OPERATION_DATABASE}`;

      this.props.setBreadcrumbs([
        {
          href: CLIENT_PAGES.VISUALS,
          name: BREADCRUMBS.VISUALS,
        },
        {
          href: CLIENT_PAGES.CLIENTS_LIST,
          name: BREADCRUMBS.CLIENTS,
        },
        ...agencyBreadcrumb,
        {
          name: client.name,
          href: clientHref,
        },
        {
          name: BREADCRUMBS.CUSTOM_OPERATION,
          href: customOpDBHref,
        },
        {
          name: operation.name,
          href: operationHref,
        },
        {
          name: BREADCRUMBS.TEMPLATES,
          href: CLIENT_PAGES.TEMPLATES,
        },
      ]);
    });
  };

  handleShowAddTemplate = (editingTemplate = {}) => {
    this.setState({
      showAddTemplate: !this.state.showAddTemplate,
      editingTemplate,
    });
  };

  handleTemplateCreated = () => {
    this.handleShowAddTemplate();
    this.updateTemplates();
  };

  handlePageChanged = (page) => {
    this.setQueryParams({page});
    this.updateTemplates();
  };

  render() {
    const {showAddTemplate, editingTemplate} = this.state;

    const {emailTemplates, templatesMeta, isAdmin} = this.props;

    return (
      <div className="theme-color-16 templates">
        <AddTemplateModal
          show={showAddTemplate}
          onClose={() => this.handleShowAddTemplate({})}
          onConfirm={this.handleTemplateCreated}
          operationId={this.props.operationId}
          editingTemplate={editingTemplate}
        />

        <div className="page__title-block flex-container flex-justify-between">
          <div>
            <TitleBlock theme>
              <TitleBlock.Item>
                {this.LANGUAGE.TITLE} ({templatesMeta.total_count})
              </TitleBlock.Item>
            </TitleBlock>
          </div>
        </div>

        {isAdmin && (
          <div className="templates__action-bar">
            <button className="button button--bg-14" onClick={() => this.handleShowAddTemplate({})}>
              <Icon name="plus" className="button__icon" />
              <span>{this.LANGUAGE.ADD_BUTTON}</span>
            </button>
          </div>
        )}

        <div className={b('grid')}>
          {emailTemplates.map((template) => (
            <div className={b('grid-item')} key={template.id}>
              <TemplateItem
                labels={{
                  merged: this.LANGUAGE.MERGED_TEXT,
                  updated: this.LANGUAGE.UPDATED_TEXT,
                  created: this.LANGUAGE.CREATED_TEXT,
                  links: this.LANGUAGE.LINKS_TEXT,
                }}
                template={template}
                onEditClick={this.handleShowAddTemplate}
                editable={isAdmin}
              />
            </div>
          ))}
        </div>

        <div className="templates__pagination">
          <Pagination
            totalPages={templatesMeta.total_pages}
            maxButtons={3}
            currentPage={templatesMeta.current_page}
            onClick={this.handlePageChanged}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  ({languageState, emailTemplates, operations, ...state}) => ({
    languageState,
    emailTemplates: emailTemplates.operationEmailTemplates.data,
    templatesMeta: emailTemplates.operationEmailTemplates.meta,
    operation: operations.operation,
    isAdmin: selectIsAdmin(state),
  }),
  {
    setBreadcrumbs,
    clearBreadcrumbs,
    getEmailTemplates,
    getOperation,
  },
)(Templates);
