export default {
  GET_CLIENT_USERS_REQUEST: '@@client-users/GET_CLIENT_USERS_REQUEST',
  GET_CLIENT_USERS_SUCCESS: '@@client-users/GET_CLIENT_USERS_SUCCESS',
  GET_CLIENT_USERS_ERROR: '@@client-users/GET_CLIENT_USERS_ERROR',
  GET_CURRENT_CLIENT_USERS_REQUEST: '@@client-users/GET_CURRENT_CLIENT_USERS_REQUEST',
  GET_CURRENT_CLIENT_USERS_SUCCESS: '@@client-users/GET_CURRENT_CLIENT_USERS_SUCCESS',
  GET_CURRENT_CLIENT_USERS_ERROR: '@@client-users/GET_CURRENT_CLIENT_USERS_ERROR',

  CLIENT_USERS_BLOCK_LOADING: '@@client-users/CLIENT_USERS_BLOCK_LOADING',
  CLIENT_USERS_BLOCK_SUCCESS: '@@client-users/CLIENT_USERS_BLOCK_SUCCESS',
  CLIENT_USERS_BLOCK_FAILED: '@@client-users/CLIENT_USERS_BLOCK_FAILED',

  RESEND_INVITATION_REQUEST: '@@client-users/RESEND_INVITATION_REQUEST',
  RESEND_INVITATION_SUCCESS: '@@client-users/RESEND_INVITATION_SUCCESS',
  RESEND_INVITATION_FAILED: '@@client-users/RESEND_INVITATION_FAILED',

  RESEND_CLIENT_INVITATION_REQUEST: '@@client-users/RESEND_CLIENT_INVITATION_REQUEST',
  RESEND_CLIENT_INVITATION_SUCCESS: '@@client-users/RESEND_CLIENT_INVITATION_SUCCESS',
  RESEND_CLIENT_INVITATION_FAILED: '@@client-users/RESEND_CLIENT_INVITATION_FAILED',

  GET_REGION_REQUEST: '@@client-users/GET_REGION_REQUEST',
  GET_REGION_SUCCESS: '@@client-users/GET_REGION_SUCCESS',
  GET_REGION_FAILED: '@@client-users/GET_REGION_FAILED',

  GET_MEMBERSHIPS_REQUEST: '@@client-users/GET_MEMBERSHIPS_REQUEST',
  GET_MEMBERSHIPS_SUCCESS: '@@client-users/GET_MEMBERSHIPS_SUCCESS',
  GET_MEMBERSHIPS_FAILED: '@@client-users/GET_MEMBERSHIPS_FAILED',

  GET_MEBERSHIP_REQUEST: '@@client-users/GET_MEBERSHIP_REQUEST',
  GET_MEBERSHIP_SUCCESS: '@@client-users/GET_MEBERSHIP_SUCCESS',
  GET_MEBERSHIP_FAILED: '@@client-users/GET_MEBERSHIP_FAILED',

  UNLINK_CLIENT_USER_REQUEST: '@@client-users/UNLINK_CLIENT_USER_REQUEST',
  UNLINK_CLIENT_USER_SUCCESS: '@@client-users/UNLINK_CLIENT_USER_SUCCESS',
  UNLINK_CLIENT_USER_FAILED: '@@client-users/UNLINK_CLIENT_USER_FAILED',

  DELETE_CLIENT_USER_REQUEST: '@@client-users/DELETE_CLIENT_USER_REQUEST',
  DELETE_CLIENT_USER_SUCCESS: '@@client-users/DELETE_CLIENT_USER_SUCCESS',
  DELETE_CLIENT_USER_FAILED: '@@client-users/DELETE_CLIENT_USER_FAILED',

  CREATE_CLIENT_USER_REQUEST: '@@client-users/CREATE_CLIENT_USER_REQUEST',
  CREATE_CLIENT_USER_SUCCESS: '@@client-users/CREATE_CLIENT_USER_SUCCESS',
  CREATE_CLIENT_USER_FAILED: '@@client-users/CREATE_CLIENT_USER_FAILED',

  UPDATE_CLIENT_USER_REQUEST: '@@client-users/UPDATE_CLIENT_USER_REQUEST',
  UPDATE_CLIENT_USER_SUCCESS: '@@client-users/UPDATE_CLIENT_USER_SUCCESS',
  UPDATE_CLIENT_USER_FAILED: '@@client-users/UPDATE_CLIENT_USER_FAILED',

  GET_AVAILABLE_FOR_ATTRIBUTION_USERS_REQUEST: '@@client-users/GET_AVAILABLE_FOR_ATTRIBUTION_USERS_REQUEST',
  GET_AVAILABLE_FOR_ATTRIBUTION_USERS_SUCCESS: '@@client-users/GET_AVAILABLE_FOR_ATTRIBUTION_USERS_SUCCESS',
  GET_AVAILABLE_FOR_ATTRIBUTION_USERS_FAILED: '@@client-users/GET_AVAILABLE_FOR_ATTRIBUTION_USERS_FAILED',
};
