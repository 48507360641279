import moment from 'moment';

import {transformDate} from 'client/services/helpers';

import {TIME_FORMAT} from 'client/components/prizes/constants';
import {SCHEDULE_TABS} from 'client/components/prizes/fieldsets/prize-diy-schedule-fieldset/constants';

export default ({dates_off, weekSchedule}) => {
  const {openingHours, intraday_win, next_day_win, prizes_number, configurationId, initialTimestamps} = weekSchedule;

  const timeStampsToSubmit = initialTimestamps.map(({id}) => ({id, _destroy: true}));
  Object.entries(openingHours).forEach((entry) => {
    const [weekday, timeStamps] = entry;

    timeStamps.forEach(({value}) => {
      const [from, to] = value.split(' ');

      timeStampsToSubmit.push({
        weekday,
        win_time_from: moment(from, TIME_FORMAT).format(),
        win_time_to: moment(to, TIME_FORMAT).format(),
      });
    });
  });

  return {
    schedule_instant_win_configuration: {
      intraday_win,
      next_day_win,
      prizes_number,
      dates_off: dates_off && dates_off.map((date) => transformDate(date.value, false)),
      prize_timestamps: timeStampsToSubmit,
      schedule_type: SCHEDULE_TABS.SCHEDULE,
      id: configurationId,
    },
  };
};
