import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Field, getFormValues, reduxForm, SubmissionError, updateSyncWarnings} from 'redux-form';

import {capitalizeFirstLetter} from 'client/services/formatters';
import {mapSelectList} from 'client/services/helpers';
import {getAuthHeaders} from 'client/services/helpers';
import {addressTransform} from 'client/services/helpers';

import {getAllAgencies} from 'client/ducks/agencies/actions';
import {selectAllAgencies} from 'client/ducks/agencies/selectors';
import {getAgencyUsers} from 'client/ducks/agency-users/agency-users.action';
import {getCategories} from 'client/ducks/categories/actions';
import {getClientsCheckSiren} from 'client/ducks/client-account/actions';
import {interpolate} from 'client/ducks/language/helpers';
import {getSubcategories} from 'client/ducks/subcategories/actions';
import {selectSubcategories} from 'client/ducks/subcategories/selectors';
import {getSubsidiaries} from 'client/ducks/subsidiaries/actions';

import {API_METHODS, API_PATH, CLIENT_PAGES} from 'client/common/config';
import {TextField, SelectField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

import './new-client-modal.scss';

let LANGUAGE = {};
let langCommon = {};
const initialValues = {agency_id: '0'};

const SIREN_COUNTRY = 'France';

class NewClientModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    valid: PropTypes.bool,
    error: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    submitFailed: PropTypes.bool,
    initialValues: PropTypes.object,
    reset: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    untouch: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    getAgencies: PropTypes.func.isRequired,
    adminsList: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    getCategories: PropTypes.func.isRequired,
    agenciesList: PropTypes.object.isRequired,
    subsidiaries: PropTypes.object.isRequired,
    onSaveContinue: PropTypes.func.isRequired,
    getAgencyUsers: PropTypes.func.isRequired,
    subcategories: PropTypes.array.isRequired,
    getSubsidiaries: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    ...withRouter.propTypes,
    getSubcategories: PropTypes.func.isRequired,
  };

  static defaultProps = {
    show: false,
  };

  static formName = 'NewClientModalForm';

  static validate({name, brand_name, subsidiary_id, category, subcategory_id, country, siren_number}) {
    let errors = {};

    if (!name || !name.trim()) {
      errors.name = LANGUAGE.NAME_REQUIRED;
    }

    if (!brand_name || !brand_name.trim()) {
      errors.brand_name = LANGUAGE.BRAND_NAME_REQUIRED;
    }

    if (!category) {
      errors.category = LANGUAGE.CATEGORY_REQUIRED;
    }

    if (!subsidiary_id) {
      errors.subsidiary_id = LANGUAGE.SUBSIDIARY_REQUIRED;
    }

    if (!subcategory_id) {
      errors.subcategory_id = LANGUAGE.SUBCATEGORY_REQUIRED;
    }

    if (!country) {
      errors.country = LANGUAGE.REQUIRED;
    }

    if (country === SIREN_COUNTRY && !siren_number) {
      errors.siren_number = LANGUAGE.REQUIRED;
    }

    return errors;
  }
  static async validateAsync({siren_number}, dispatch, props) {
    let errors = {};

    if (!siren_number) {
      return Promise.resolve();
    }
    if (siren_number?.toString().length !== 9) {
      errors.siren_number = langCommon.SIREN.SIREN_IS_NOT_VALID;
    } else {
      try {
        const response = await props.getClientsCheckSiren(siren_number);
        if (response?.payload?.real) {
          if (response?.payload.clients_count) {
            dispatch(
              updateSyncWarnings(
                NewClientModal.formName,
                {
                  siren_number: interpolate(langCommon.SIREN.SIREN_EXISTS?.toString(), {
                    siren: response?.payload.clients_count,
                  }),
                },
                '',
              ),
            );
          }
        } else {
          errors.siren_number = langCommon.SIREN.SIREN_DOES_NOT_EXIST;
          dispatch(
            updateSyncWarnings(
              NewClientModal.formName,
              {
                siren_number: '',
              },
              '',
            ),
          );
        }
      } catch {
        errors.siren_number = langCommon.SIREN.SIREN_CHECKING_SERVICE_DOES_NOT_RESPOND_TITLE;
        dispatch(
          updateSyncWarnings(
            NewClientModal.formName,
            {
              siren_number: '',
            },
            '',
          ),
        );
      }
    }
    if (Object.values(errors).length) {
      return Promise.reject(errors);
    }

    return Promise.resolve();
  }

  constructor(props) {
    super(props);

    this.state = {
      isNextStep: false,
      isAgency: false,
    };

    LANGUAGE = this.props.languageState.payload.CLIENTS_LIST;
    langCommon = this.props.languageState.payload.COMMON;
  }

  componentDidMount() {
    this.props.getCategories();
    this.props.getSubsidiaries();
    this.props.getAgencies();
  }

  onAgencyStatusChange = (e, isAgency) => {
    this.props.untouch('brand_name', 'name', 'subsidiary_id', 'agency_id', 'category', 'subcategory_id');
    this.setState({isAgency: !!isAgency});
  };

  getValidationMessage = (errors) => {
    const errorsList = [];
    for (let [key, value] of Object.entries(errors)) {
      const field = {
        name: 'Name',
        brand_name: 'Brand name',
      };

      if (field[key]) {
        errorsList.push(`${field[key]} ${value[0]}.\n`);
      }
    }
    return errorsList.join('');
  };

  handleCancel = () => {
    this.setState({isAgency: false});
    this.props.reset();
    this.props.untouch('brand_name', 'name', 'subsidiary_id', 'agency_id', 'category', 'subcategory_id');
    this.props.onCancel();
  };

  handleChangeClientAgency = (event, value) => {
    if (value) {
      this.props.getAgencyUsers(value);

      this.setState({
        isNextStep: true,
      });
    } else {
      this.setState({
        isNextStep: false,
      });
    }
  };

  handleClose = () => {
    this.setState({isAgency: false, isNextStep: false});
    this.props.onClose();
  };

  save = (obj) => {
    const formattedData = this.mapData(obj);
    if (this.state.isAgency) {
      return this.saveAsAgency(formattedData);
    } else if (!formattedData.agency_id) {
      const cb = (response) => {
        if (response.errors) {
          const brandName = response.errors.brand_name;
          const fieldWithError = brandName && brandName[0] ? 'brand_name' : '_error';

          throw new SubmissionError({[fieldWithError]: this.getValidationMessage(response.errors)});
        }
        this.props.reset();
        this.props.onClose();
        this.props.history.push(`${CLIENT_PAGES.COMPANIES}/${response.company.id}`);
      };
      return this.saveCompany(formattedData, cb);
    }

    // Save and continue
    const cb = (response) => {
      if (response.errors) {
        const brandName = response.errors.brand_name;
        const fieldWithError = brandName && brandName[0] ? 'brand_name' : '_error';

        throw new SubmissionError({[fieldWithError]: this.getValidationMessage(response.errors)});
      }
      this.props.onClose();
      this.props.onSaveContinue(response.company);
    };

    if (this.props.data.id) {
      return this.updateCompany(formattedData, cb, this.props.data);
    }
    return this.saveCompany(formattedData, cb);
  };

  changeCategory = (e, {value}) => {
    this.props.getSubcategories({
      q: {
        category_id_eq: value,
      },
    });

    this.props.change('subcategory_id', '');
    this.props.untouch('subcategory_id');
  };

  saveAsAgency = (data) => {
    return fetch(`${API_PATH}${API_METHODS.AGENCIES}`, {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify({agency: {...data}}),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.errors) {
          throw new SubmissionError({_error: this.getValidationMessage(response.errors)});
        }
        this.props.reset();
        this.props.history.push(`${CLIENT_PAGES.AGENCIES}/${response.agency.id}`);
        this.props.onClose();
      });
  };

  saveCompany = (data, cb) => {
    return fetch(`${API_PATH}${API_METHODS.COMPANIES}`, {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify({company: {...data}}),
    })
      .then((response) => response.json())
      .then((response) => (cb ? cb(response) : null));
  };

  updateCompany = (data, cb, savedData) => {
    return fetch(`${API_PATH}${API_METHODS.COMPANIES}/${savedData.id}`, {
      method: 'PATCH',
      headers: getAuthHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => (cb ? cb(response) : null));
  };

  mapData = (obj) => {
    const {subcategory_id, subsidiary_id, poc_admin_user_id, street_address_0, street_address_1, street_address_2} =
      obj;

    const copiedObj = {...obj};
    delete copiedObj.category;
    delete copiedObj.street_address_0;
    delete copiedObj.street_address_1;
    delete copiedObj.street_address_2;

    let street_address = addressTransform.joinAddress([street_address_0, street_address_1, street_address_2]);
    let data = {
      ...copiedObj,
      subsidiary_id: subsidiary_id.value,
      subcategory_id: subcategory_id.value,
      add_places: 'true',
      logo: '',
      website: '',
      poc_membership_id: '',
      street_address,
    };

    if (poc_admin_user_id) {
      data = {...data, poc_admin_user_id};
    }
    if (!this.state.isAgency && obj.agency_id && obj.agency_id !== '0') {
      data = {...data, agency_id: obj.agency_id};
    } else {
      delete data.agency_id;
    }

    return data;
  };

  getCountries = () => {
    return Object.entries(langCommon.COUNTRIES).map(([key, label]) => {
      let value = capitalizeFirstLetter(key.toLowerCase());

      if (key === 'UK') {
        value = 'United Kingdom';
      }

      return {
        value,
        label,
      };
    });
  };
  handleCountryChange = (country) => {
    const {change} = this.props;
    if (country !== SIREN_COUNTRY) {
      change(NewClientModal.formName, 'siren_number', '');
    }
    change(NewClientModal.formName, 'country', country);
  };

  render() {
    const {
      handleSubmit,
      submitting,
      valid,
      pristine,
      subsidiaries,
      categories,
      subcategories,
      agenciesList,
      adminsList,
      submitFailed,
      formValues,
      error,
    } = this.props;
    const {isNextStep, isAgency} = this.state;
    const subsidiariesList = mapSelectList(subsidiaries.payload);
    const categoriesList = mapSelectList(categories.payload);
    const subcategoriesList = mapSelectList(subcategories);
    const agencies = mapSelectList(agenciesList);
    agencies.unshift({value: '0', label: LANGUAGE.NO_AGENCY_LABEL});
    const contactList = mapSelectList(adminsList.payload.data, 'full_name');

    return (
      <Modal
        show={this.props.show}
        onClose={this.handleClose}
        title={LANGUAGE.NEW_CLIENT_MODAL_TITLE}
        dialogClassName="modal-window--width-2 modal-window--theme-1 new-client-modal"
      >
        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          <div className="modal-window__form" style={{marginTop: '-3.25rem'}}>
            <div className="row">
              <div className="col-xs-6 modal-window__col-left">
                <div className="theme-color-1 new-client-modal__agency-switcher">
                  <Field
                    name="agency"
                    onChange={this.onAgencyStatusChange}
                    type="switcher"
                    label={LANGUAGE.NEW_USER_MODAL_AGENCY_SWITCH_LABEL}
                    component={fieldTemplate}
                  />
                </div>
                <div className="form-field-wrap">
                  <Field
                    name="name"
                    type="text"
                    style={{
                      minHeight: '3.375rem',
                    }}
                    component={fieldTemplate}
                  />
                </div>
                <h3 className="modal-window__section-title">{LANGUAGE.NEW_CLIENT_MODAL_SUBTITLE_1}</h3>
                <div className="form-field-wrap">
                  <Field
                    cssModifier="select--view-1"
                    label={LANGUAGE.NEW_CLIENT_MODAL_BRAND_NAME_LABEL}
                    name="brand_name"
                    type="text"
                    searchable={false}
                    component={fieldTemplate}
                  />
                </div>
                <div className="form-field-wrap">
                  <SelectField
                    name="country"
                    placeholder={LANGUAGE.COUNTRY_PLACEHOLDER}
                    label={LANGUAGE.NEW_CLIENT_MODAL_COUNTRY_LABEL + ' *'}
                    simpleValue
                    onChange={this.handleCountryChange}
                    options={this.getCountries()}
                  />
                </div>
                {formValues?.country === SIREN_COUNTRY && (
                  <div className="form-field-wrap">
                    <TextField label={langCommon.SIREN.SIREN_LABEL + ' *'} name="siren_number" maxlength={9} />
                  </div>
                )}
                <div className="form-field-wrap">
                  <Field
                    cssModifier="select--view-1"
                    label={LANGUAGE.NEW_CLIENT_MODAL_SUBSIDIARY_LABEL}
                    name="subsidiary_id"
                    type="select"
                    searchable={false}
                    options={subsidiariesList}
                    component={fieldTemplate}
                  />
                </div>
                {!isAgency && (
                  <div className="form-field-wrap">
                    <Field
                      cssModifier="select--view-1"
                      label={LANGUAGE.NEW_CLIENT_MODAL_AGENCY_LABEL}
                      name="agency_id"
                      type="select"
                      simpleValue
                      searchable={false}
                      onChange={this.handleChangeClientAgency}
                      options={agencies}
                      component={fieldTemplate}
                    />
                  </div>
                )}
                <div className="form-field-wrap">
                  <Field
                    cssModifier="select--view-1"
                    label={LANGUAGE.NEW_CLIENT_MODAL_CATEGORY_LABEL}
                    name="category"
                    type="select"
                    searchable={false}
                    onChange={this.changeCategory}
                    options={categoriesList}
                    component={fieldTemplate}
                  />
                </div>
                <div className="form-field-wrap">
                  <Field
                    cssModifier="select--view-1"
                    label={LANGUAGE.NEW_CLIENT_MODAL_SUBCATEGORY_LABEL}
                    name="subcategory_id"
                    type="select"
                    searchable={false}
                    options={subcategoriesList}
                    component={fieldTemplate}
                  />
                </div>
                {submitFailed && (
                  <div className="input--error">
                    <p className="input__message">{error}</p>
                  </div>
                )}
              </div>
              <div className="col-xs-6 modal-window__col-right">
                <h3 className="modal-window__section-title" style={{marginTop: '-0.5rem'}}>
                  {LANGUAGE.NEW_CLIENT_MODAL_SUBTITLE_2}
                </h3>
                <div className="form-field-wrap" style={{marginBottom: '0.625rem'}}>
                  <Field
                    simpleValue
                    cssModifier="select--view-1"
                    label={LANGUAGE.NEW_CLIENT_MODAL_CONTACT_PERSON_LABEL}
                    name="poc_admin_user_id"
                    type="select"
                    searchable={false}
                    options={contactList}
                    component={fieldTemplate}
                  />
                </div>
                <h3 className="modal-window__section-title">{LANGUAGE.NEW_CLIENT_MODAL_SUBTITLE_3}</h3>
                <div className="form-field-wrap" style={{marginBottom: '1.5rem'}}>
                  <Field
                    cssModifier="textarea--size-1"
                    label={LANGUAGE.NEW_CLIENT_MODAL_COMMENTS_LABEL}
                    name="comment"
                    type="textarea"
                    component={fieldTemplate}
                  />
                </div>
                <div className="form-field-wrap">
                  <Field
                    label={LANGUAGE.NEW_CLIENT_MODAL_STREET_LABEL}
                    name="street_address_0"
                    type="text"
                    component={fieldTemplate}
                  />
                </div>
                <div className="form-field-wrap">
                  <Field
                    label={LANGUAGE.NEW_CLIENT_MODAL_STREET_LABEL}
                    name="street_address_1"
                    type="text"
                    component={fieldTemplate}
                  />
                </div>
                <div className="form-field-wrap">
                  <Field
                    label={LANGUAGE.NEW_CLIENT_MODAL_STREET_LABEL}
                    name="street_address_2"
                    type="text"
                    component={fieldTemplate}
                  />
                </div>
                <div className="form-field-wrap">
                  <div
                    className="display-inline-block"
                    style={{
                      width: '8.125rem',
                      marginRight: '0.625rem',
                    }}
                  >
                    <Field
                      label={LANGUAGE.NEW_CLIENT_MODAL_ZIP_LABEL}
                      name="zip"
                      type="text"
                      component={fieldTemplate}
                    />
                  </div>
                  <div
                    className="display-inline-block"
                    style={{
                      width: '12.5rem',
                    }}
                  >
                    <Field
                      label={LANGUAGE.NEW_CLIENT_MODAL_CITY_LABEL}
                      name="city"
                      type="text"
                      component={fieldTemplate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="submit" onClick={this.handleCancel}>
              {LANGUAGE.NEW_CLIENT_MODAL_CANCEL_BUTTON}
            </button>
            {!isNextStep || isAgency ? (
              <button
                className="button button--bg-1 modal-window__footer-btn"
                type="submit"
                disabled={submitting || !valid || pristine}
              >
                {LANGUAGE.NEW_CLIENT_MODAL_CREATE_BUTTON}
              </button>
            ) : (
              <button
                className="button button--bg-1 modal-window__footer-btn"
                type="submit"
                disabled={submitting || !valid || pristine}
              >
                {LANGUAGE.NEW_CLIENT_MODAL_SAVE_BUTTON}
              </button>
            )}
          </div>
        </form>
      </Modal>
    );
  }
}

const NewClientUserForm = reduxForm({
  form: NewClientModal.formName,
  validate: NewClientModal.validate,
  asyncValidate: NewClientModal.validateAsync,
  asyncBlurFields: ['siren_number'],
})(NewClientModal);

export default connect(
  (state) => ({
    languageState: state.languageState,
    subsidiaries: state.subsidiaries,
    categories: state.categories,
    subcategories: selectSubcategories(state),
    agenciesList: selectAllAgencies(state),
    initialValues,
    formValues: getFormValues(NewClientModal.formName)(state),
  }),
  {
    getSubsidiaries,
    getCategories,
    getSubcategories,
    getAgencies: getAllAgencies,
    getAgencyUsers,
    getClientsCheckSiren,
  },
)(withRouter(NewClientUserForm));
