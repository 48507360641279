import React from 'react';

import {useDispatch} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {updatePrizeValidation} from 'client/ducks/prizes/actions';

import AppButton from 'client/common/buttons';
import Popover from 'client/common/popovers/popover';

import {Translation} from 'client/models/language/types';
import {ParticipationPrize} from 'client/models/prizes/types';

import {getStatus} from './helpers';

import cssModule from './admin-prize-cell.module.scss';

const b = bem('admin-prize-cell', {cssModule});

type AdminPrizeCellProps = {
  value: ParticipationPrize[];
  onUpdate: () => void;
};

const AdminPrizeCell: React.FC<AdminPrizeCellProps> = (props) => {
  const {value, onUpdate} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('DATA_TAB.WINNERS_TABLE');
  const statusLang: Record<string, Translation> = lang.VALIDATED;

  if (!Array.isArray(value)) {
    return null;
  }

  const handlePrizeUpdate = async (id: number, data: Record<string, boolean>) => {
    await dispatch(updatePrizeValidation(id, data));
    onUpdate();
  };

  return (
    <div className={b()}>
      {value.map((item) => {
        const {id, name, validated, validation_required, validation_locked} = item;

        if (!name) {
          return null;
        }

        const {status, langKey} = getStatus(validated, validation_required, validation_locked);

        return (
          <div className={b('item')} key={id}>
            <AppButton
              iconName={validation_locked ? 'lock-2' : 'lock-2-unlocked'}
              onClick={() => handlePrizeUpdate(id, {validation_locked: !validation_locked})}
              className={b('lock-btn')}
              asWrap
            />
            <Popover
              position="bottom"
              shiftTop={15}
              overlay={
                <div>
                  <p className={b('hint-text')}>{statusLang[langKey]}</p>
                  {validation_required && <p className={b('hint-text')}>{lang.SWITCH}</p>}
                </div>
              }
            >
              <AppButton
                label={<figure className={b('validation', [status])} />}
                onClick={() => handlePrizeUpdate(id, {validated: !validated})}
                disabled={!validation_required}
                asWrap
              />
            </Popover>
            <p className={b('name')}>{name}</p>
          </div>
        );
      })}
    </div>
  );
};

export default AdminPrizeCell;
