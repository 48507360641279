import React from 'react';

import PropTypes from 'prop-types';

// components
import Icon from 'client/components/common/icon';
import InfoBlock from 'client/components/common/info-block';

import './leads-information-card.scss';

function LeadsInformationCard(props) {
  const {children, onEditClick, title, canEdit} = props;

  return (
    <InfoBlock colorTheme="leads" className="leads-information-card">
      <InfoBlock.Title>{title}</InfoBlock.Title>
      <InfoBlock.Content>
        <InfoBlock.Header className="clearfix">
          <button className="button button--circle button--bg-2 pull-right" onClick={onEditClick} disabled={!canEdit}>
            <Icon name="edit" className="button__icon" width={20} height={20} />
          </button>
        </InfoBlock.Header>
        <InfoBlock.Body limitedHeight>
          <div className="leads-information-card__content">{children}</div>
        </InfoBlock.Body>
      </InfoBlock.Content>
    </InfoBlock>
  );
}

LeadsInformationCard.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
  onEditClick: PropTypes.func,
  canEdit: PropTypes.bool,
};

LeadsInformationCard.defaultProps = {
  title: '',
  children: '',
  onEditClick: () => {},
  canEdit: true,
};

export default LeadsInformationCard;
