import React, {Component} from 'react';

import flow from 'lodash/flow';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {getFormValues, isValid, Field, reduxForm, SubmissionError} from 'redux-form';

import {alphaNumeric, maxLength, required, arrayUnique} from 'client/services/validator';

import {createScenario} from 'client/ducks/autotask/actions';
import {selectScenariosNames, selectScenariosCodes} from 'client/ducks/autotask/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

class NewScenarioModal extends Component {
  static propTypes = {
    addScenarioForm: PropTypes.object,
    automationTaskId: PropTypes.number.isRequired,
    change: PropTypes.func,
    changeUrl: PropTypes.func,
    createScenario: PropTypes.func,
    ...withRouter.propTypes,
    initialize: PropTypes.func,
    invalid: PropTypes.bool,
    show: PropTypes.bool,
    languageState: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    touch: PropTypes.func,
    untouch: PropTypes.func,
    validForm: PropTypes.bool,
    scenarioNames: PropTypes.array,
    scenarioCodes: PropTypes.array,
    handleSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    show: false,
    onConfirm: () => {},
    onClose: () => {},
    onCancel: () => {},
  };

  LANGUAGE = this.props.languageState.payload.AUTOTASK.NEW_SCENARIO_MODAL;

  constructor(props) {
    super(props);

    this.state = {
      alreadyUsed: {
        name: false,
        code: false,
      },
    };

    this.rules = {
      name: [
        required('Value is required'),
        alphaNumeric('Only letters and numbers allowed'),
        maxLength(26, 'Max length 26 symbols'),
        (value) => arrayUnique(this.props.scenarioNames, 'Such name already exists')(value),
      ],
      code: [
        required('Value is required'),
        alphaNumeric('Only letters and numbers allowed'),
        (value) => arrayUnique(this.props.scenarioNames, 'Such code already exists')(value),
      ],
    };
  }

  componentDidMount() {
    this.props.initialize({
      name: '',
      code: '',
    });
  }

  handleSubmit = () => {
    const {addScenarioForm = {}, automationTaskId, history, touch, validForm} = this.props;

    // is triggered when no data was typed in
    if (!validForm) {
      touch('name', 'code');
      return null;
    }

    return this.props
      .createScenario({
        ...addScenarioForm,
        automation_task_id: automationTaskId,
      })
      .then((data) => {
        const {error, payload} = data;

        if (error) {
          if (get(payload, 'response.errors.code')) {
            throw new SubmissionError({
              code: this.LANGUAGE.CODE_UNIQUE_ERROR,
            });
          }
          return;
        }

        const {id} = payload.scenario;
        history.push(`${CLIENT_PAGES.SCENARIO}/${id}`);
      });
  };

  handleCancel = () => {
    this.props.change('name', '');
    this.props.change('code', '');
    this.props.untouch('name', 'code');
    this.setState({
      ...this.state,
      alreadyUsed: {
        name: false,
        code: false,
      },
    });
    this.props.onClose();
  };

  inputChangeHandler = (e) => {
    e.preventDefault();
    const {name: fieldName, value} = e.target;
    const {change} = this.props;

    if (/^[a-zA-Z\d]*$/.test(value) && value.length <= 26) {
      change(fieldName, value);
    }
  };

  render() {
    const {show, handleSubmit} = this.props;

    return (
      <Modal
        show={show}
        onClose={this.handleCancel}
        dialogClassName="modal-window--width-1 theme-color-8 set-optin-modal"
        title={this.LANGUAGE.TITLE}
      >
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <Field
            label={this.LANGUAGE.SCENARIO_NAME_LABEL}
            name="name"
            type="text"
            onChange={this.inputChangeHandler}
            validate={this.rules.name}
            component={fieldTemplate}
          />
          <Field
            label={this.LANGUAGE.SCENARIO_CODE_LABEL}
            name="code"
            type="text"
            onChange={this.inputChangeHandler}
            validate={this.rules.code}
            component={fieldTemplate}
          />
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={this.handleCancel}>
              {this.LANGUAGE.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-7 modal-window__footer-btn" type="submit">
              {this.LANGUAGE.CREATE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  addScenarioForm: getFormValues('NewScenarioModalForm')(state),
  validForm: isValid('NewScenarioModalForm')(state),
  scenarioNames: selectScenariosNames(state),
  scenarioCodes: selectScenariosCodes(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createScenario,
    },
    dispatch,
  );

export default flow([
  reduxForm({
    form: 'NewScenarioModalForm',
  }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
])(NewScenarioModal);
