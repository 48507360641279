import isEmpty from 'lodash/isEmpty';

import {
  FORCED_INPUT,
  INPUT_TYPES,
  SPECIAL_SELECTOR_TYPES,
} from 'client/components/diy-operation/modals/diy-customization-modal/constants';
import {CUSTOM_INPUTS} from 'client/components/diy-operation/modals/diy-customization-modal/constants';
import {OVERALL_INPUT} from 'client/components/diy-operation/modals/diy-customization-modal/constants';

export const setValueForAllAnswers = (answer, {lang, languages, device, devices, value}) => {
  let answers;
  switch (true) {
    case CUSTOM_INPUTS.includes(device) && !CUSTOM_INPUTS.includes(lang):
      answers = [
        ...devices.map((item) => {
          // if the answer is already set for this language and device, then we take it's id to make PUT request
          const currentValue = value.find((val) => val.screen_format === item.value && val.language === lang);
          return {...answer, language: lang, screen_format: item.value, ...(currentValue && {id: currentValue.id})};
        }),
        ...value.filter((item) => item.language !== lang).map((item) => ({...answer, ...item})), // add non-changed values as well as additional display_type field to them
      ];
      break;

    case CUSTOM_INPUTS.includes(lang) && !CUSTOM_INPUTS.includes(device):
      answers = [
        ...languages.map((item) => {
          // if the answer is already set for this language and device, then we take it's id to make PUT request
          const currentValue = value.find((val) => val.language === item.value && val.screen_format === device);

          return {...answer, language: item.value, screen_format: device, ...(currentValue && {id: currentValue.id})};
        }),
        ...value.filter((item) => item.screen_format !== device).map((item) => ({...answer, ...item})), // add non-changed values  as additional display_type field to them
      ];
      break;

    default:
      answers = [];
      languages.forEach(({value: language}) => {
        const allLangs = devices.map((item) => {
          // if the answer is already set for this language and device, then we take it's id to make PUT request

          const currentValue = value.find((val) => val.language === language && val.screen_format === item.value);

          return {
            ...answer,
            language: language,
            screen_format: item.value,
            ...(currentValue && {id: currentValue.id}),
          };
        });
        answers.push(...allLangs);
      });
      break;
  }
  return answers;
};

export const getContainerHeight = (height) => {
  const DEFAULT_HEIGHT = 200;
  if (!height) {
    return DEFAULT_HEIGHT;
  }
  const MIN_HEIGHT = 50;
  const MAX_HEIGHT = 500;

  if (+height > MAX_HEIGHT) {
    return MAX_HEIGHT;
  }
  if (+height < MIN_HEIGHT) {
    return MIN_HEIGHT;
  }
  return +height;
};

export const langDeviceSetting = (param, setting) => {
  switch (param) {
    case OVERALL_INPUT:
      return OVERALL_INPUT;

    case FORCED_INPUT:
      return FORCED_INPUT;

    default:
      return setting;
  }
};

export const getFileRequirements = (formItem, device) => {
  const {check_error_width, check_error_height, check_error_ratio, check_error_size, check_error_format} = formItem;
  let check_errors = {};

  if (device === OVERALL_INPUT || device === FORCED_INPUT) {
    check_errors.width = !isEmpty(Object.values(check_error_width)) && Math.max(...Object.values(check_error_width));
    check_errors.height = !isEmpty(Object.values(check_error_height)) && Math.max(...Object.values(check_error_height));
    check_errors.size = !isEmpty(Object.values(check_error_size)) && Math.max(...Object.values(check_error_size));

    if (new Set(Object.values(check_error_ratio)).size === 1) {
      check_errors.ratio = [...new Set(Object.values(check_error_ratio))][0];
    }
  }

  return {
    width: check_error_width[device] || check_errors?.width,
    height: check_error_height[device] || check_errors?.height,
    ratio: check_error_ratio[device] || check_errors?.ratio,
    size: check_error_size[device] || check_errors?.size,
    format: check_error_format,
  };
};

export const isFormItemEmpty = (formItem) => {
  const isEmptyDropdown =
    formItem.display_type === INPUT_TYPES.DROPDOWN &&
    !formItem.form_choices.length &&
    formItem.special_selector === SPECIAL_SELECTOR_TYPES.NONE;
  const isEmptyImageCarousel = formItem.display_type === INPUT_TYPES.CAROUSEL && !formItem.form_choices.length;

  return isEmptyDropdown || isEmptyImageCarousel;
};
