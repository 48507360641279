import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';

import {
  updateGame,
  deleteGame,
  getPrizes,
  getLevels,
  getPrizeMaps,
  updatePrizeMap,
  deletePrize,
} from 'client/ducks/games/actions';
import {selectPrizes, selectLevels, selectPrizeMaps} from 'client/ducks/games/selectors';

import Modal from 'client/common/modals/modal';

import {GAME_TYPES} from './constants';
import GameConfirmationBlock from './game-confirmation-block';
import GameHeaderBlock from './game-header-block';
import GamePeriodBlock from './game-period-block';
import GamePrizeDrawBlock from './game-prize-draw-block';
import {mapGameFormValues, mapPrizeMapFormValues} from './mapFormValues';
import mapInitialValues from './mapInitialValues';
import PrizeForm from './prize-form';
import PrizesTable from './prizes-table';
import validate from './validate';

import './game-config-modal.scss';

class GameConfigModal extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    fetchGames: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    valid: PropTypes.bool.isRequired,
    levels: PropTypes.array.isRequired,
    getLevels: PropTypes.func.isRequired,
    game: PropTypes.object.isRequired,
    updateGame: PropTypes.func.isRequired,
    deleteGame: PropTypes.func.isRequired,
    prizes: PropTypes.array.isRequired,
    getPrizes: PropTypes.func.isRequired,
    prizeMaps: PropTypes.array.isRequired,
    getPrizeMaps: PropTypes.func.isRequired,
    updatePrizeMap: PropTypes.func.isRequired,
    prizeMapParams: PropTypes.object,
    levelKey: PropTypes.string,
    deletePrize: PropTypes.func.isRequired,
  };

  static defaultProps = {
    fetchGames: null,
    prizeMapParams: null,
    levelKey: '',
  };

  static formName = 'GameConfigModalForm';

  state = {
    confirmation: null,
    editPrize: null,
    addPrize: false,
  };

  componentDidMount() {
    this.fetchLevels();

    if (this.props.prizeMapParams) {
      this.fetchPrizeMaps();
    } else {
      this.fetchPrizes();
    }
  }

  componentWillUnmount() {
    this.setState({
      editPrize: null,
      addPrize: false,
    });
  }

  fetchPrizes = () => {
    const params = {
      include: [
        'coupon',
        'schedule_instant_win_configuration.prize_timestamps',
        'participation_instant_win_configuration',
        'time_instant_win_configuration',
        'game_validation_level',
        'prize_substitution_items',
      ],
      q: {
        game_id_eq: this.props.game.id,
      },
      include_prize_total_quantities: true,
    };

    return this.props.getPrizes(params).then(() => {
      this.setState({
        editPrize: null,
        deletePrize: null,
        addPrize: false,
      });
    });
  };

  fetchLevels = () => {
    const params = {
      q: {
        interface_automation_task_id_eq: this.props.game.automation_task_id,
      },
    };

    return this.props.getLevels(params);
  };

  fetchPrizeMaps = async () => {
    const params = {
      include: [
        'coupon',
        'instant_win_configuration',
        'participation_instant_win_configuration',
        'schedule_instant_win_configuration.prize_map_timestamps',
        'time_instant_win_configuration',
        'prize',
      ],
      q: {
        prize_game_id_eq: this.props.game.id,
        ...this.props.prizeMapParams,
      },
    };

    await this.props.getPrizeMaps(params);
    this.setState({
      editingPrizeIndex: null,
      deletePrize: null,
      addPrize: false,
    });
    this.props.initialize(mapInitialValues(this.props.game, this.props.prizeMaps));
  };

  setConfirmation = (confirmation) => {
    this.setState({confirmation});
  };

  setDeleteGameConfirmation = () => {
    const {lang} = this.props;

    this.setConfirmation({
      message: lang.DELETE_GAME_MESSAGE,
      cancelText: lang.CANCEL,
      confirmText: lang.CONFIRM_DELETE_GAME,
      onCancel: () => this.setConfirmation(null),
      onConfirm: this.handleDelete,
    });
  };

  handleDelete = () => {
    this.props.deleteGame(this.props.game.id).then(this.props.fetchGames).then(this.props.onClose);
  };

  handleSave = (values) => {
    if (this.props.prizeMapParams) {
      const data = mapPrizeMapFormValues(values, this.props.game);

      return this.props.updatePrizeMap(this.props.prizeMaps[0].id, data).then(this.props.onClose);
    }

    const data = mapGameFormValues(values, this.props.game);

    return this.props.updateGame(this.props.game.id, data).then(this.props.fetchGames).then(this.props.onClose);
  };

  handleAdd = () => {
    this.setState({
      addPrize: true,
      editingPrizeIndex: null,
    });
  };

  handleEdit = (index) => {
    this.setState({
      addPrize: false,
      editingPrizeIndex: index,
    });
  };

  handleCancel = () => {
    this.setState({
      addPrize: false,
      editingPrizeIndex: null,
    });
  };

  confirmationDeletePrize = async (id) => {
    await this.props.deletePrize(id);
    await this.fetchPrizes();
    this.setState({confirmation: null});
  };

  handleDeletePrize = (id) => {
    const {lang} = this.props;
    this.setState({
      confirmation: {
        message: lang.DELETE_PRIZE_MESSAGE,
        cancelText: lang.CANCEL,
        confirmText: lang.CONFIRM_DELETE_PRIZE,
        onCancel: () => this.setConfirmation(null),
        onConfirm: () => this.confirmationDeletePrize(id),
      },
    });
  };

  handleActivatePrizeMap = async (id, active) => {
    await this.props.updatePrizeMap(id, {
      prize_map: {
        id,
        active,
      },
    });
    await this.fetchPrizeMaps();
  };

  render() {
    const {lang, onClose, handleSubmit, game, levels, formValues, valid, prizes, prizeMaps, prizeMapParams, levelKey} =
      this.props;
    const {confirmation, editingPrizeIndex, addPrize} = this.state;
    const showPrizeForm = !!editingPrizeIndex?.toString() || addPrize;
    const isPrizeMap = !!prizeMapParams;
    const timeEditable = prizeMapParams && prizeMaps[0] ? prizeMaps[0].time_editable : true;

    const disabledTable =
      !!showPrizeForm ||
      !!confirmation ||
      !game.actual_from ||
      !game.actual_to ||
      !game.default_from ||
      !game.default_to;

    const prevPrizeIndex = addPrize ? prizes.length - 1 : editingPrizeIndex - 1;
    const nextPrizeIndex = editingPrizeIndex?.toString() ? editingPrizeIndex + 1 : -1;
    const arr = isPrizeMap ? prizeMaps : prizes;

    const formProps = {
      prize: arr[editingPrizeIndex],
      prevPrize: arr[prevPrizeIndex],
      nextPrize: arr[nextPrizeIndex],
    };

    return (
      <Modal dialogClassName="game-config-modal" show onClose={onClose}>
        <form onSubmit={handleSubmit(this.handleSave)}>
          <GameHeaderBlock
            lang={lang}
            formValues={formValues}
            game={game}
            levels={levels}
            isPrizeMap={isPrizeMap}
            levelKey={levelKey}
          />
          <div className="game-config-modal__columns">
            <div className="game-config-modal__column-left">
              <GamePeriodBlock lang={lang} game={game} isPrizeMap={isPrizeMap} editable={timeEditable} />
              <PrizesTable
                deletePrize={this.handleDeletePrize}
                activatePrizeMap={this.handleActivatePrizeMap}
                disabledActions={disabledTable}
                onAdd={this.handleAdd}
                onSelectPrize={this.handleEdit}
                game={game}
                prizes={prizes}
                prizeMaps={prizeMaps}
                isPrizeMap={isPrizeMap}
                fetchPrizes={this.fetchPrizes}
                fetchPrizeMaps={this.fetchPrizeMaps}
              />
              {formValues.game_type === GAME_TYPES.PRIZE_DRAW && (
                <GamePrizeDrawBlock lang={lang} editable={timeEditable} />
              )}
            </div>
            <div className="game-config-modal__column-right">
              {showPrizeForm && (
                <PrizeForm
                  {...formProps}
                  prizes={prizes || []}
                  onSave={isPrizeMap ? this.fetchPrizeMaps : this.fetchPrizes}
                  onCancel={this.handleCancel}
                  game={{
                    ...game,
                    ...formValues,
                  }}
                  isPrizeMap={isPrizeMap}
                />
              )}
              {confirmation && <GameConfirmationBlock {...confirmation} />}
            </div>
          </div>
          <div className="game-config-modal__buttons">
            <button className="button button--bg-4" type="submit" disabled={!valid || confirmation || showPrizeForm}>
              {lang.SAVE_GAME}
            </button>
            {!prizeMapParams && (
              <button
                className="button button--bg-7"
                type="button"
                disabled={prizes.length}
                onClick={this.setDeleteGameConfirmation}
              >
                {lang.DELETE_GAME}
              </button>
            )}
          </div>
        </form>
      </Modal>
    );
  }
}

const GameConfigModalForm = reduxForm({
  form: GameConfigModal.formName,
  shouldValidate: () => true,
  validate,
})(GameConfigModal);

export default connect(
  (state, props) => ({
    lang: state.languageState.payload.GAMES.GAME_CONFIG_MODAL,
    levels: selectLevels(state),
    prizes: selectPrizes(state),
    prizeMaps: selectPrizeMaps(state),
    formValues: getFormValues(GameConfigModal.formName)(state) || {},
    initialValues: props.game ? mapInitialValues(props.game) : null,
  }),
  {
    updateGame,
    deleteGame,
    getPrizes,
    deletePrize,
    getLevels,
    getPrizeMaps,
    updatePrizeMap,
  },
)(GameConfigModalForm);
