import React from 'react';

import {Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';

function DateCell({data, rowIndex, field}) {
  return <Cell>{data[rowIndex][field]}</Cell>;
}

DateCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

DateCell.defaultProps = {
  data: [],
};

export default DateCell;
