import {useCallback, useEffect, useMemo} from 'react';

import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import {ACCESS_LEVEL_TYPES} from 'client/ducks/client-users/constants';
import {getInstoreTaskAccesses, getTaskNetworks} from 'client/ducks/instore-tasks/actions';
import {selectInstoreTaskAccesses, selectTaskNetworks} from 'client/ducks/instore-tasks/selectors';
import {getPlacesAction} from 'client/ducks/places/actions';
import {selectPlaces} from 'client/ducks/places/selectors';

import {Place} from 'client/models/places/types';
import {ApiDispatch} from 'client/types';
import {Option} from 'client/types/common';

export const useFetchPlaces = (filters: {networkId?: string; cityId?: string}) => {
  const [loading, toggleLoading] = useToggle(false);

  const dispatch: ApiDispatch = useDispatch();
  const places = useSelector(selectPlaces);

  const fetchPlaces = useCallback(
    (networkId) => {
      const queryParams = {
        include: ['network'],
        q: {
          network_id_eq: networkId,
        },
      };
      toggleLoading();
      return dispatch(getPlacesAction(queryParams)).finally(toggleLoading);
    },
    [dispatch, toggleLoading],
  );

  useEffect(() => {
    if (filters.networkId) {
      fetchPlaces(filters.networkId);
    }
  }, [fetchPlaces, filters.networkId]);

  const cityOptions: Option[] = useMemo(
    () =>
      uniqBy(places, 'city_id')
        .map((place) => ({
          label: place.city_name,
          value: place.city_id,
        }))
        .filter(({value}) => value) as Option[],
    [places],
  );

  const placeOptions: (Option & Place)[] = useMemo(
    () =>
      filter(places, {city_id: Number(filters.cityId)}).map((place) => ({
        label: place.name,
        value: place.id,
        ...place,
      })),
    [filters.cityId, places],
  );

  return {loading, cityOptions, placeOptions};
};

export const useFetchTaskNetworks = (filters: {taskId: number}) => {
  const [loading, toggleLoading] = useToggle(false);

  const dispatch: ApiDispatch = useDispatch();
  const taskNetworks = useSelector(selectTaskNetworks);

  const fetchTaskNetworks = useCallback(() => {
    const queryParams: Record<string, any> = {
      include: ['network'],
      q: {
        instore_task_id_eq: filters.taskId,
      },
    };
    toggleLoading();
    return dispatch(getTaskNetworks(queryParams)).finally(() => toggleLoading());
  }, [dispatch, filters.taskId, toggleLoading]);

  useEffect(() => {
    fetchTaskNetworks();
  }, [fetchTaskNetworks]);

  const brandOptions: Option[] = useMemo(
    () =>
      taskNetworks
        ?.filter((taskNetwork) => !!taskNetwork.network)
        .map((taskNetwork) => ({
          label: taskNetwork.network!.name,
          value: taskNetwork.network!.id,
        })) || [],
    [taskNetworks],
  );

  return {brandOptions, loading};
};

export const useFetchLocalUsers = (
  filters: {regionIds: null | number[]; taskId: number; clientAccessLevel: string},
  skip: boolean,
) => {
  const [loading, toggleLoading] = useToggle(false);

  const dispatch: ApiDispatch = useDispatch();
  const taskAccesses = useSelector(selectInstoreTaskAccesses);

  const fetchTaskAccesses = useCallback(() => {
    if (!skip) {
      const queryParams: Record<string, any> = {
        include: ['membership.client_user', 'kit_mappings.kit'],
        q: {
          instore_task_id_eq: filters.taskId,
          membership_access_level_eq: ACCESS_LEVEL_TYPES.LOCAL,
        },
      };
      if (filters.clientAccessLevel === ACCESS_LEVEL_TYPES.REGIONS) {
        queryParams.q.membership_region_accesses_region_id_in = filters.regionIds;
      }

      toggleLoading();
      return dispatch(getInstoreTaskAccesses(queryParams)).finally(() => toggleLoading());
    }
    return Promise.resolve();
  }, [skip, filters.clientAccessLevel, filters.taskId, filters.regionIds, toggleLoading, dispatch]);

  useEffect(() => {
    fetchTaskAccesses();
  }, [fetchTaskAccesses]);

  const userOptions: Option[] = useMemo(
    () =>
      taskAccesses
        ?.filter((taskAccess) => taskAccess.membership?.client_user?.full_name)
        .map((taskAccess) => ({
          value: taskAccess.id || '',
          label: taskAccess.membership?.client_user?.full_name || '',
          ...taskAccess,
        })) || [],
    [taskAccesses],
  );

  return {userOptions, loading};
};
