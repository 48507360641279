import types from './types';

const initialState = {
  activeInterface: {
    interface_items: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_SG_INTERFACES_STATE:
      return initialState;

    case types.GET_NEW_SG_INTERFACE_SUCCESS:
      return {
        ...state,
        activeInterface: action.payload.survey_gizmo_interface,
      };

    default:
      return state;
  }
}
