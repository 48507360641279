import React, {useMemo} from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {getComments} from 'client/components/devices/modals/client-device-modal/helpers';
import {DeviceAffectation} from 'client/models/device-affectations';
import {AFFECTATION_INFO_MODES} from 'client/models/device-affectations';

import DeliveryRoad from './delivery-road';
import ReturnRoad from './return-road';

import ClientDeviceModalCommentsBlock from '../blocks/client-device-modal-comments-block';

import cssModule from './client-device-modal-logistics-tab.module.scss';

const b = bem('client-device-modal-logistics-tab', {cssModule});

type ClientDeviceModalLogisticsTabProps = {
  affectation: DeviceAffectation;
};

const ClientDeviceModalLogisticsTab: React.FC<ClientDeviceModalLogisticsTabProps> = (props) => {
  const {affectation} = props;
  const lang = useLanguage('CLIENT_DEVICES.MODAL.LOGISTICS_TAB');

  const commentsData = useMemo(() => {
    return getComments(affectation, AFFECTATION_INFO_MODES.LOGISTIC);
  }, [affectation]);

  return (
    <div className={b()}>
      <div>
        <p className={b('title')}>{lang.DELIVERY}</p>
        <DeliveryRoad affectation={affectation} />
      </div>
      <div>
        <p className={b('title')}>{lang.RETURN}</p>
        <ReturnRoad affectation={affectation} />
      </div>
      <div>
        <p className={b('title')}>{lang.COMMENTS}</p>
        <ClientDeviceModalCommentsBlock className={b('comments')} data={commentsData} />
      </div>
    </div>
  );
};

export default ClientDeviceModalLogisticsTab;
