import React, {useEffect, useState} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import LayoutPanel from 'client/common/panels/layout-panel';

import {KPI_TYPES} from 'client/components/animations/constants';
import AnimationQuestionList from 'client/components/animations/lists/animation-question-list';
import {TranslationJsx} from 'client/models/language/types';

import AnimationQuestionnaireCardStatistic from './animation-questionnaire-card-statistic';

import cssModule from './animation-questionnaire-card.module.scss';

const b = bem('animation-questionnaire-card', {cssModule});

const getFirstActive = (data) => {
  return data?.find(({type}) => type === KPI_TYPES.GRAPH);
};

const AnimationQuestionnaireCard = (props) => {
  const {className = '', title, data, titleList} = props;
  const [selected, setSelected] = useState(getFirstActive(data));

  useEffect(() => {
    setSelected(getFirstActive(data));
  }, [data]);

  return (
    <LayoutPanel className={cn(b(), className)}>
      <h3 className={b('title')}>{title}</h3>
      <div className={b('content')}>
        <div className={b('col')}>
          <AnimationQuestionList data={data} selected={selected} title={titleList} onSelect={setSelected} />
        </div>
        {selected && selected?.type !== KPI_TYPES.STAT && (
          <div className={b('col')}>
            <AnimationQuestionnaireCardStatistic data={selected} />
          </div>
        )}
      </div>
    </LayoutPanel>
  );
};

AnimationQuestionnaireCard.propTypes = {
  title: TranslationJsx.isRequired,
  titleList: TranslationJsx.isRequired,
  data: PropTypes.arrayOf(AnimationQuestionnaireCardStatistic.propTypes.data).isRequired,
  className: PropTypes.string,
};

export default AnimationQuestionnaireCard;
