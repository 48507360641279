import types from './types';

let initialState = {
  payload: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_SUBSIDIARIES_SUCCESS:
      return {
        ...state,
        payload: action.payload.subsidiaries,
      };

    default:
      return state;
  }
}
