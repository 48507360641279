import React, {Component} from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {get} from 'client/services/fetch';

import {API_METHODS} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

let LANGUAGE = {};

class NewUserStep1Modal extends Component {
  static validate({email}) {
    const errors = {};

    if (!email) {
      errors.email = LANGUAGE.EMAIL_IS_REQUIRED_ERROR;
    } else {
      const firstIndex = email.indexOf('@');
      const lastIndex = email.lastIndexOf('@');
      if (firstIndex === -1 || firstIndex !== lastIndex || firstIndex === 0 || lastIndex === email.length - 1) {
        errors.email = LANGUAGE.EMAIL_IS_INVALID_ERROR;
      }
    }

    return errors;
  }

  static updateSyncErrors(values) {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'NewUserStep1Form',
      },
      payload: {
        syncErrors: NewUserStep1Modal.validate(values),
      },
    };
  }

  constructor(props) {
    super(props);

    LANGUAGE = this.LANGUAGE = this.props.languageState.payload.AGENCY;
  }

  componentDidMount() {
    this.intervalID = setInterval(() => {
      if (!isEmpty(LANGUAGE)) {
        clearInterval(this.intervalID);
        this.props.updateErrors({});
      }
    }, 200);
  }

  componentDidUpdate(prevProps) {
    const {show, initialize, updateErrors} = this.props;

    if (show && !prevProps.show) {
      initialize({email: ''});
      updateErrors({});
    }
  }

  save = ({email}) => {
    const emailError = {email: LANGUAGE.EMAIL_IS_TAKEN};

    return get(API_METHODS.ADMIN_USERS, {
      q: {
        email_matches: email,
      },
    })
      .then(({admin_users}) => {
        if (admin_users.length) {
          throw new SubmissionError(emailError);
        }
      })
      .then(() =>
        get(API_METHODS.MEMBERSHIPS, {
          q: {
            client_id_eq: this.props.clientId,
            client_user_email_matches: email,
          },
        }),
      )
      .then(({memberships}) => {
        if (memberships.length) {
          throw new SubmissionError(emailError);
        }
        this.props.onNext({
          email: email.toLowerCase(),
          addNewUserStep: 2,
          addNewUserObject: {
            email: email.toLowerCase(),
          },
        });
      });
  };

  render() {
    const {totalSteps, onClose, onCancel} = this.props;
    return (
      <Modal
        show={this.props.show}
        onClose={onClose}
        dialogClassName="modal-window--width-1 modal-window--theme-3"
        backdropClassName="modal-window__backdrop"
        titleClassName="modal-window__title"
        title={
          <>
            <h3 className="title pull-left">{this.LANGUAGE.NEW_USER_MODAL_TITLE}</h3>
            <div className="pull-right modal-window__step">
              {this.LANGUAGE.NEW_USER_MODAL_STEP} 1 {this.LANGUAGE.NEW_USER_MODAL_STEP_OF} {totalSteps}
            </div>
          </>
        }
      >
        <form noValidate="noValidate" onSubmit={this.props.handleSubmit(this.save)}>
          <Field name="email" label={this.LANGUAGE.NEW_USER_MODAL_EMAIL_LABEL} type="text" component={fieldTemplate} />
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onCancel}>
              {this.LANGUAGE.NEW_USER_MODAL_CANCEL_BUTTON}
            </button>
            <button className="button button--bg-3 modal-window__footer-btn" type="submit">
              {this.LANGUAGE.NEW_USER_MODAL_NEXT_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

NewUserStep1Modal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  handleSubmit: PropTypes.func,
  updateErrors: PropTypes.func,
  initialize: PropTypes.func,
  languageState: PropTypes.object,
  totalSteps: PropTypes.number,
  clientId: PropTypes.number,
};

NewUserStep1Modal.defaultProps = {
  show: false,
  onCancel: () => {},
  onClose: () => {},
  onNext: () => {},
};

const NewUserStep1Form = reduxForm({
  form: 'NewUserStep1Form',
  validate: NewUserStep1Modal.validate,
})(NewUserStep1Modal);

export default connect(
  ({languageState}) => ({
    languageState,
  }),
  {
    updateErrors: NewUserStep1Modal.updateSyncErrors,
  },
)(NewUserStep1Form);
