import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

function getLeadsFromEndpoint(endpoint, queryParams = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint,
        method: 'GET',
        noCache: true,
        types: [types.GET_LEADS_REQUEST, types.GET_LEADS_SUCCESS, types.GET_LEADS_ERROR],
        queryParams,
      },
      true,
    );
  };
}

export function getLeads(queryParams = {}, clientId) {
  const endpoint = clientId
    ? `${API_PATH}${API_METHODS.CLIENTS}/${clientId}${API_METHODS.LEADS}`
    : `${API_PATH}${API_METHODS.LEADS}`;

  return getLeadsFromEndpoint(endpoint, queryParams);
}

export function getLeadsWithImported(queryParams = {}) {
  const endpoint = `${API_PATH}${API_METHODS.LEADS}${API_METHODS.LEADS_WITH_IMPORTED}`;

  return getLeadsFromEndpoint(endpoint, queryParams);
}

export function deleteLead(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.LEADS}`,
      method: 'DELETE',
      types: [types.DELETE_LEAD_REQUEST, types.DELETE_LEAD_SUCCESS, types.DELETE_LEAD_ERROR],
      queryParams,
    });
}

export function createLeadColumn(body) {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEADS_COLUMN}`,
        method: 'POST',
        types: [types.CREATE_LEAD_COLUMN_REQUEST, types.CREATE_LEAD_COLUMN_SUCCESS, types.CREATE_LEAD_COLUMN_ERROR],
        body,
        queryParams: {
          include: ['external_column_adapter'],
        },
      },
      true,
    );
}

export function updateLeadColumn(body) {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEADS_COLUMN}/${body.id}`,
        method: 'PATCH',
        types: [types.UPDATE_LEAD_COLUMN_REQUEST, types.UPDATE_LEAD_COLUMN_SUCCESS, types.UPDATE_LEAD_COLUMN_ERROR],
        body,
      },
      true,
    );
}

export function deleteLeadColumn(id) {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEADS_COLUMN}/${id}`,
        method: 'DELETE',
        types: [types.DELETE_LEAD_COLUMN_REQUEST, types.DELETE_LEAD_COLUMN_SUCCESS, types.DELETE_LEAD_COLUMN_ERROR],
      },
      true,
    );
}

export function flushLeadsAllState() {
  return {
    type: types.FLUSH_LEADS_ALL_STATE,
  };
}

export function getLeadsStatistic(queryParams = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEADS_STATISTIC}`,
        method: 'GET',
        types: [types.GET_LEADS_STATISTIC_REQUEST, types.GET_LEADS_STATISTIC_SUCCESS, types.GET_LEADS_STATISTIC_ERROR],
        queryParams,
      },
      true,
    );
  };
}

export function getOnlineUsersStatistic(queryParams = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.ONLINE_USERS_STATISTIC}`,
        method: 'GET',
        types: [
          types.GET_ONLINE_USERS_STATISTIC_REQUEST,
          types.GET_ONLINE_USERS_STATISTIC_SUCCESS,
          types.GET_ONLINE_USERS_STATISTIC_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}
