import React, {useMemo} from 'react';

import sortBy from 'lodash/sortBy';
import moment from 'moment';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import Icon from 'client/common/icon';

import AnimationReportingTable from 'client/components/animations/components/animation-reporting-table';
import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container';
import {AnimationAnomalyCell} from 'client/components/instore/cells';

import cssModule from './animation-days-list.module.scss';

const b = bem('animation-days-list', {cssModule});

const AnimationDaysList = ({className}) => {
  const {formValues} = useReduxForm(AnimationConfigContainerFormName);
  const lang = useLanguage('ANIMATIONS.LISTS.ANIMATION_DAYS_LIST');

  const data = useMemo(() => {
    return sortBy(formValues?.animation_days, 'from');
  }, [formValues?.animation_days]);

  const columns = [
    {
      name: 'from',
      path: 'from',
      label: lang.DAY_COLUMN,
      render: ({value}) => moment(value).format('dddd, D MMM'),
    },
    {
      name: 'hr',
      label: lang.HR_COLUMN,
      cellClassName: b('column'),
      render: ({item}) => <Icon name={`hr-${item.hr_status}`} />,
    },
    {
      name: 'install',
      label: lang.INSTALL_COLUMN,
      cellClassName: b('column'),
      render: ({item}) => <Icon name={`install-${item.install_status}`} />,
    },
    {
      name: 'anomalies',
      label: lang.ANOMALIES_COLUMN,
      cellClassName: b('column'),
      render: ({item}) => (
        <AnimationAnomalyCell
          className={b('icon')}
          item={{
            ...formValues,
            animation_days: [item],
          }}
        />
      ),
    },
  ];

  return (
    <AnimationReportingTable
      className={className}
      title={lang.TITLE}
      columns={columns}
      data={data}
      tableClassName={b('table')}
    />
  );
};

AnimationDaysList.propTypes = {
  className: PropTypes.string,
};

AnimationDaysList.defaultProps = {
  className: '',
};

export default AnimationDaysList;
