import types from './types';

const initialState = {
  isLoading: false,
  error: false,
  user: {
    login_url: '',
  },
  callbackError: null,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case types.GET_AUTHINFO_REQUEST:
      return {...state, isLoading: true, ...payload};

    case types.GET_AUTHINFO_SUCCESS:
      return {...state, isLoading: false, ...payload};

    case types.GET_AUTHINFO_ERROR:
      return {isLoading: false, error: true, ...payload};

    case types.SET_CALLBACK_ERROR:
      return {...state, callbackError: payload.callbackError || initialState.callbackError};

    default:
      return state;
  }
};
