import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {isEmail} from 'validator';

import {getAuthHeaders} from 'client/services/helpers';

import {getSubsidiaries} from 'client/ducks/subsidiaries/actions';

import {API_PATH, API_METHODS, PROFILE} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

const initialValues = {title: 'M.', role: 'admin'};

class AddNewUserModal extends Component {
  static defaultProps = {
    show: false,
  };

  static propTypes = {
    getSubsidiaries: PropTypes.func.isRequired,
    subsidiaries: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    userState: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    valid: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    show: PropTypes.bool,
  };

  static formName = 'AddNewUserModalForm';

  static validate({first_name, last_name, phone, email, title, role, qualification, subsidiary_id}, {lang}) {
    let errors = {};

    if (!first_name || !first_name.trim()) {
      errors.first_name = lang.FIRST_NAME_REQUIRED;
    }
    if (!last_name || !last_name.trim()) {
      errors.last_name = lang.LAST_NAME_REQUIRED;
    }
    if (!phone || !phone.trim()) {
      errors.phone = lang.PHONE_NUMBER_REQUIRED;
    }
    if (!email) {
      errors.email = lang.EMAIL_REQUIRED;
    } else if (email && !isEmail(email)) {
      errors.email = lang.EMAIL_FORMAT_INCORRECT;
    }
    if (!title) {
      errors.title = lang.CIVILITY_REQUIRED;
    }
    if (!role) {
      errors.role = lang.USER_ROLE_REQUIRED;
    }
    if (!qualification) {
      errors.qualification = lang.QUALIFICATION_REQUIRED;
    }
    if (!subsidiary_id) {
      errors.subsidiary_id = lang.SUBSIDIARY_REQUIRED;
    }

    return errors;
  }

  constructor(props) {
    super(props);

    this.state = {
      qualification: [
        {value: PROFILE.PROJECT_MANAGER, label: this.props.lang.QUALIFICATION_PM},
        {value: PROFILE.ACCOUNT_MANAGER, label: this.props.lang.QUALIFICATION_AM},
      ],
    };
  }

  componentDidMount() {
    this.props.getSubsidiaries();
  }

  handleClose = () => {
    this.props.onClose();
    this.props.reset();
  };

  mapData = (obj) => {
    const {qualification, subsidiary_id, title, role} = obj;
    return {
      ...obj,
      qualification,
      role: role.value || initialValues.role,
      subsidiary_id,
      title: title.value || initialValues.title,
      type: this.props.userState.payload.user.type,
      avatar: '',
    };
  };

  save = (data) => {
    return fetch(`${API_PATH}${API_METHODS.ADMIN_USERS}`, {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify({
        admin_user: {
          ...this.mapData(data),
          invited_by_id: this.props.userState.payload.user.id,
        },
      }),
    })
      .then((res) => res.json())
      .then(({errors}) => {
        if (errors) {
          throw new SubmissionError({email: this.props.lang.EMAIL_TAKEN});
        }
        this.handleClose();
      });
  };

  handleRoleChange = ({value}) => {
    const qualification = [
      {value: PROFILE.PROJECT_MANAGER, label: this.props.lang.QUALIFICATION_PM},
      {value: PROFILE.ACCOUNT_MANAGER, label: this.props.lang.QUALIFICATION_AM},
    ];
    if (value === PROFILE.SUPER_ADMIN) {
      qualification.push({value: PROFILE.TECHNICAL_STAFF, label: this.props.lang.QUALIFICATION_TS});
    } else {
      this.props.change('qualification', PROFILE.PROJECT_MANAGER);
    }
    this.setState({qualification});
  };

  render() {
    const {show, lang, handleSubmit, submitting, valid, pristine, subsidiaries} = this.props;

    const subsidiariesList = subsidiaries.payload.length
      ? subsidiaries.payload.map(({id, name}) => ({value: String(id), label: name}))
      : [];
    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 modal-window--theme-1"
        backdropClassName="modal-window__backdrop"
        onClose={this.handleClose}
        title={lang.NEW_USER_MODAL_TITLE}
      >
        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          <FormFieldWrap>
            <Field
              cssModifier="select--view-1"
              required
              label={lang.NEW_USER_MODAL_CIVILITY_LABEL}
              name="title"
              type="select"
              searchable={false}
              options={[
                {value: 'M.', label: lang.CIVILITY_M},
                {value: 'Mme.', label: lang.CIVILITY_MME},
              ]}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              required
              label={lang.NEW_USER_MODAL_FIRST_NAME_LABEL}
              name="first_name"
              type="text"
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              required
              label={lang.NEW_USER_MODAL_LAST_NAME_LABEL}
              name="last_name"
              type="text"
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              required
              label={lang.NEW_USER_MODAL_EMAIL_LABEL}
              name="email"
              type="email"
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <div>
            <Field
              cssModifier="select--view-1"
              required
              label={lang.NEW_USER_MODAL_ROLE_LABEL}
              name="role"
              type="select"
              searchable={false}
              onChange={this.handleRoleChange}
              options={[
                {value: 'admin', label: lang.ROLES_ADMIN},
                {value: 'super_admin', label: lang.ROLES_SUPER_ADMIN},
              ]}
              component={fieldTemplate}
            />
          </div>
          <FormFieldWrap>
            <Field
              cssModifier="select--view-1"
              required
              label={lang.NEW_USER_MODAL_QUALIFICATION_LABEL}
              name="qualification"
              simpleValue
              type="select"
              searchable={false}
              options={this.state.qualification}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              cssModifier="select--view-1"
              required
              label={lang.NEW_USER_MODAL_SUBSIDIARY_LABEL}
              name="subsidiary_id"
              simpleValue
              type="select"
              searchable={false}
              options={subsidiariesList}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              cssModifier="input--no-min-height"
              required
              label={lang.NEW_USER_MODAL_PHONE_LABEL}
              name="phone"
              type="text"
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <div className="modal-window__footer modal-window__footer--centered">
            <button
              className="button button--bg-1 modal-window__footer-btn"
              type="submit"
              disabled={submitting || !valid || pristine}
            >
              {lang.NEW_USER_MODAL_SEND_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const addNewUserForm = reduxForm({
  form: AddNewUserModal.formName,
  validate: AddNewUserModal.validate,
})(AddNewUserModal);

export default connect(
  ({languageState, subsidiaries, userState}) => ({
    initialValues,
    lang: languageState.payload.ADMINS,
    subsidiaries,
    userState,
  }),
  {
    getSubsidiaries,
  },
)(addNewUserForm);
