import get from 'lodash/get';

export function selectCampaigns(state) {
  return state.campaigns.allIds.reduce((result, id) => {
    const campaignItem = state.campaigns.byId[id];

    return campaignItem ? [...result, campaignItem] : result;
  }, []);
}

export function selectCampaignNames(state) {
  return state.campaigns.allIds.reduce((result, id) => {
    const campaignItem = state.campaigns.byId[id];

    return campaignItem ? [...result, campaignItem.name] : result;
  }, []);
}

export function selectCampaignRegions(state) {
  return get(state.campaigns, 'campaign.automation_task.operation.client.regions', []);
}

export function selectCampaignPlaces(state) {
  const regions = selectCampaignRegions(state);

  return regions.reduce((acc, region) => {
    const places = region.places || [];
    places.forEach((place) => acc.push(place));
    return acc;
  }, []);
}

export function selectCampaignsWithChannels(state) {
  return state.campaigns.campaignsWithChannels;
}

export function selectCampaignsWithChannelsLoading(state) {
  return state.campaigns.loadingCampaignsWithChannels;
}

export const selectPermanentSources = (state) => {
  return state.campaigns.permanentSources;
};
