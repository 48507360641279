import {EmailParams} from 'client/components/email-template-editor/types';

export default (emailParams: EmailParams) => ({
  subject: emailParams.subject,
  subject_preview: emailParams.previewText,
  sender_name: emailParams.name,
  email_sender_id: emailParams.senderId,
  sender_email: emailParams.senderEmail,
  reply_to: emailParams.replyTo,
});
