import React, {Component} from 'react';

import find from 'lodash/find';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getUserId} from 'client/services/cookie-data-source';

import {deleteBCDisplayColumns} from 'client/ducks/broadcast/actions';
import {selectAvailableColumnAdapters} from 'client/ducks/column-adapters/selectors';
import {getCustomColumns} from 'client/ducks/custom-columns/actions';
import {selectAllCustomColumns} from 'client/ducks/custom-columns/selectors';
import {
  getColumnVisibilityItems,
  updateColumnVisibilityItems,
  deleteDisplayColumns,
} from 'client/ducks/lead-display-items/actions';
import {selectColumnVisibilityItems} from 'client/ducks/lead-display-items/selectors';

import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Rollout from 'client/components/common/rollout';

import './leads-client-visibility-settings-modal.scss';

class LeadsClientVisibilitySettingsModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    clientId: PropTypes.string.isRequired,
    allCustomColumns: PropTypes.array.isRequired,
    internalColumns: PropTypes.array.isRequired,
    getCustomColumns: PropTypes.func.isRequired,
    deleteDisplayColumns: PropTypes.func.isRequired,
    deleteBCDisplayColumns: PropTypes.func.isRequired,
    getColumnVisibilityItems: PropTypes.func.isRequired,
    updateColumnVisibilityItems: PropTypes.func.isRequired,
    columnVisibilityItems: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.generalCols = [
      {
        name: this.props.lang.FIRST_NAME,
        adapter: ['first_name'],
      },
      {
        name: this.props.lang.LAST_NAME,
        adapter: ['last_name'],
      },
      {
        name: this.props.lang.GENDER_SMALL,
        adapter: ['gender'],
      },
      {
        name: this.props.lang.BIRTH_DATE,
        adapter: ['birth_date'],
      },
      {
        name: this.props.lang.CONTACTS,
        adapter: ['phone', 'email'],
      },
      {
        name: this.props.lang.PARTICIPATION,
        adapter: ['last_participation', 'participations_count'],
      },
      {
        name: this.props.lang.VISUALS_COUNT,
        adapter: ['visuals_count'],
      },
      {
        name: this.props.lang.CREATED_AT,
        adapter: ['created_at'],
      },
      {
        name: this.props.lang.SOURCE,
        adapter: ['first_source'],
      },
      {
        name: this.props.lang.LAST_EXPORT,
        adapter: ['last_export'],
      },
      {
        name: this.props.lang.OPTINS_COLUMN,
        adapter: ['opt_ins'],
      },
      {
        name: this.props.lang.EXPIRES_AT,
        adapter: ['expires_at'],
      },
      {
        name: this.props.lang.ADDRESS1,
        adapter: ['address1'],
      },
      {
        name: this.props.lang.ADDRESS2,
        adapter: ['address2'],
      },
      {
        name: this.props.lang.ZIP,
        adapter: ['zip'],
      },
      {
        name: this.props.lang.CITY,
        adapter: ['city'],
      },
      {
        name: this.props.lang.COUNTRY,
        adapter: ['country'],
      },
    ];

    this.state = {
      generalCols: this.generalCols,
      customCols: [],
      checkedAllColumns: false,
      checkedGeneralColumns: false,
      checkedCustomColumns: false,
      accordionEnabled: true,
    };
    this.userId = getUserId();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.show) {
      return;
    }

    if (this.props.internalColumns !== prevProps.internalColumns || (this.props.show && !prevProps.show)) {
      this.props
        .getColumnVisibilityItems(this.props.clientId)
        .then(() => this.props.getCustomColumns(this.props.clientId, true))
        .then(() =>
          this.setState({customCols: this.props.allCustomColumns}, () => {
            this.initializeColumns(this.state.generalCols, true);
            this.initializeColumns(this.state.customCols, false);
          }),
        );
    }
  }

  getAdapterId(item) {
    const columns = item.adapter ? this.props.internalColumns : this.props.allCustomColumns;

    return columns.reduce((acc, column) => {
      if (item.adapter) {
        return item.adapter.includes(column.name) ? [column.id, ...acc] : acc;
      }

      return column.name === item.name ? [column.external_column_adapter.id, ...acc] : acc;
    }, []);
  }

  getId = (item) => {
    if (item.adapter) {
      const found = this.props.internalColumns.find((el) => el.name === item.adapter[0]);
      return get(found, 'id');
    }

    const found = this.props.allCustomColumns.find((el) => el.name === item.name);
    return get(found, 'external_column_adapter.id');
  };

  initializeColumns = (columns, isGeneral) => {
    const changedCols = columns.map((item) => ({...item, checked: this.filterColumns(this.getId(item))}));
    if (isGeneral) {
      this.setState({
        generalCols: changedCols,
        checkedGeneralColumns: this.getVisibleColumnsState(changedCols),
        checkedAllColumns: this.getVisibleColumnsState(changedCols) && this.state.checkedCustomColumns,
      });
    } else {
      this.setState({
        customCols: changedCols,
        checkedCustomColumns: changedCols.length && this.getVisibleColumnsState(changedCols),
        checkedAllColumns: this.getVisibleColumnsState(changedCols) && this.state.checkedGeneralColumns,
      });
    }
  };

  toggleAllColumns = (e) => {
    this.toggleGeneralColumns(e);
    this.toggleCustomColumns(e);
    this.setState({
      checkedAllColumns: e.currentTarget.checked,
    });
  };

  getVisibleColumnsState = (columns) => columns.every((item) => item.checked);

  toggleGeneralColumns = ({currentTarget}) => {
    const generalCols = this.state.generalCols.map((el) => ({
      ...el,
      checked: currentTarget.checked,
    }));
    this.setState({
      generalCols,
      checkedGeneralColumns: currentTarget.checked,
      checkedAllColumns: this.state.checkedCustomColumns && currentTarget.checked,
    });
  };

  toggleCustomColumns = ({currentTarget}) => {
    const customCols = this.state.customCols.map((el) => ({
      ...el,
      checked: currentTarget.checked,
    }));
    this.setState({
      customCols,
      checkedCustomColumns: currentTarget.checked,
      checkedAllColumns: this.state.checkedGeneralColumns && currentTarget.checked,
    });
  };

  handleColumnCheck = ({target}, isGeneral = false) => {
    const chosenName = target.getAttribute('data-name');
    const changedCols = (isGeneral ? this.state.generalCols : this.state.customCols).map((item) =>
      item.name === chosenName ? {...item, checked: target.checked} : item,
    );

    if (isGeneral) {
      this.setState({
        generalCols: changedCols,
        checkedGeneralColumns: this.getVisibleColumnsState(changedCols),
        checkedAllColumns:
          this.getVisibleColumnsState(changedCols) && this.getVisibleColumnsState(this.state.customCols),
      });
    } else {
      this.setState({
        customCols: changedCols,
        checkedCustomColumns: this.getVisibleColumnsState(changedCols),
        checkedAllColumns:
          this.getVisibleColumnsState(changedCols) && this.getVisibleColumnsState(this.state.generalCols),
      });
    }
  };

  getDisplayData = () => {
    const {generalCols, customCols} = this.state;
    const fromGeneral = generalCols.filter(({checked}) => checked);
    const fromCustom = customCols.filter(({checked}) => checked);
    const displayedColomns = this.mapDisplayedColumns([...fromGeneral, ...fromCustom]);
    const displayList = this.props.columnVisibilityItems.slice();
    const removalList = [];
    displayList.forEach(({id, client_id, column_adapter_id}) => {
      if (!this.filterColumns(column_adapter_id, displayedColomns)) {
        removalList.push({
          id,
          client_id,
          column_adapter_id,
          _destroy: true,
        });
      }
    });
    const postData = displayedColomns.filter(
      ({column_adapter_id}) =>
        !this.filterColumns(column_adapter_id, removalList) && !this.filterColumns(column_adapter_id),
    );
    return {
      column_visibility_item: [...removalList, ...postData],
    };
  };

  save = () => {
    const data = this.getDisplayData();
    if (data.column_visibility_item.length) {
      this.props
        .updateColumnVisibilityItems(data)
        .then(() => this.props.getColumnVisibilityItems(this.props.clientId))
        .then(this.props.onClose);
    }
    return this.props.onClose();
  };

  mapDisplayedColumns = (columns) => {
    const data = columns.map((el) => ({
      client_id: this.props.clientId,
      column_adapter_id: this.getAdapterId(el),
    }));
    const finalData = [];

    data.forEach((el) => {
      const colAdapterList = el.column_adapter_id.slice();
      el.column_adapter_id = colAdapterList[0];
      finalData.push(el);
    });
    return finalData;
  };

  filterColumns = (id, data = this.props.columnVisibilityItems) => {
    return !!find(data, ({column_adapter_id}) => column_adapter_id === id);
  };

  render() {
    const {show, onClose, lang} = this.props;
    const {generalCols, customCols, checkedAllColumns, checkedGeneralColumns, checkedCustomColumns, accordionEnabled} =
      this.state;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 modal-window--theme-2 leads-table-settings-modal"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TABLE_SETTINGS_MODAL_TITLE}
      >
        <div className="row pos-rel">
          <div className="modal-window__descr main-text">{lang.TABLE_SETTINGS_MODAL_ALL_COLUMNS}</div>
          <div className="content-group theme-color-2">
            <div className="content-group__header">
              <label className="checkbox-button">
                <input
                  className="checkbox-button__input"
                  type="checkbox"
                  name="checkbox-all"
                  value="0"
                  checked={checkedAllColumns}
                  onChange={this.toggleAllColumns}
                />
                <div className="checkbox-button__label">
                  <span className="icon-checked checkbox-button__icon" />
                  <span className="checkbox-button__text">
                    <span className="main-text main-text--bold">{lang.TABLE_SETTINGS_MODAL_ALL_COLUMNS_GROUP}</span>
                  </span>
                </div>
              </label>
            </div>
            <Rollout
              title={
                <label className="checkbox-button theme-color-2">
                  <input
                    className="checkbox-button__input"
                    type="checkbox"
                    name="checkbox-general"
                    checked={checkedGeneralColumns}
                    onChange={this.toggleGeneralColumns}
                  />
                  <div className="checkbox-button__label">
                    <span className="icon-checked checkbox-button__icon" />
                    <span className="checkbox-button__text">
                      <span className="main-text main-text--bold">
                        {lang.TABLE_SETTINGS_MODAL_GENERAL_COLUMNS_GROUP}
                      </span>
                    </span>
                  </div>
                </label>
              }
              className="rollout--expand-1"
              onTransition={this.handleTransition}
              isOpened={accordionEnabled}
            >
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMax: 180,
                }}
              >
                <div className="list list--view-3">
                  <div className="list__group">
                    {generalCols.map((item, index) => (
                      <div className="list__item" key={index}>
                        <label className="checkbox-button checkbox-button--view-1 checkbox-button">
                          <input
                            type="checkbox"
                            data-name={item.name}
                            className="checkbox-button__input"
                            checked={item.checked}
                            onChange={(e) => {
                              this.handleColumnCheck(e, true);
                            }}
                          />
                          <div className="checkbox-button__label">
                            <span className="icon-checked checkbox-button__icon" />
                            <span className="checkbox-button__text">{item.name}</span>
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </CustomScrollbars>
            </Rollout>
            <Rollout
              title={
                <label className="checkbox-button theme-color-2">
                  <input
                    className="checkbox-button__input"
                    type="checkbox"
                    name="checkbox-custom"
                    value="0"
                    checked={checkedCustomColumns}
                    onChange={this.toggleCustomColumns}
                  />
                  <div className="checkbox-button__label">
                    <span className="icon-checked checkbox-button__icon" />
                    <span className="checkbox-button__text">
                      <span className="main-text main-text--bold">
                        {lang.TABLE_SETTINGS_MODAL_CUSTOM_COLUMNS_GROUP}
                      </span>
                    </span>
                  </div>
                </label>
              }
              className="rollout--expand-1 rollout--no-border-bottom"
              onTransition={this.handleTransition}
              isOpened={accordionEnabled}
            >
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMax: 200,
                }}
              >
                <div className="list list--view-3">
                  <div className="list__group">
                    {customCols.map((item, index) => (
                      <div key={index} className="list__item">
                        <label className="checkbox-button checkbox-button--view-1 checkbox-button">
                          <input
                            type="checkbox"
                            data-name={item.name}
                            className="checkbox-button__input"
                            checked={item.checked}
                            onChange={this.handleColumnCheck}
                          />
                          <div className="checkbox-button__label">
                            <span className="icon-checked checkbox-button__icon" />
                            <span className="checkbox-button__text">{item.name}</span>
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </CustomScrollbars>
            </Rollout>
          </div>
        </div>
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-11 modal-window__footer-btn" onClick={onClose}>
            {lang.TABLE_SETTINGS_CANCEL_BUTTON}
          </button>
          <button className="button button--bg-2 modal-window__footer-btn" onClick={this.save}>
            {lang.TABLE_SETTINGS_MODAL_CONFIRM_BUTTON}
          </button>
        </div>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    internalColumns: selectAvailableColumnAdapters(state, 'Lead'),
    lang: state.languageState.payload.LEADS_LIST,
    allCustomColumns: selectAllCustomColumns(state),
    columnVisibilityItems: selectColumnVisibilityItems(state),
  }),
  {
    getColumnVisibilityItems,
    updateColumnVisibilityItems,
    getCustomColumns,
    deleteDisplayColumns,
    deleteBCDisplayColumns,
  },
)(LeadsClientVisibilitySettingsModal);
