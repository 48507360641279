import {callApi} from 'client/services/call-api';

import {getQueryParamsByTab} from 'client/ducks/templates/mappers';

import {API_METHODS, API_PATH} from 'client/common/config';

import types from './types';

export function getTemplates(queryParams) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.TEMPLATES}`,
      method: 'GET',
      types: [types.GET_TEMPLATES_REQUEST, types.GET_TEMPLATES_SUCCESS, types.GET_TEMPLATES_ERROR],
      queryParams,
    });
}

export function getTemplateForm(id, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.FORMS}/${id}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_TEMPLATE_FORM_REQUEST, types.GET_TEMPLATE_FORM_SUCCESS, types.GET_TEMPLATE_FORM_ERROR],
      queryParams,
    });
}

export function createTemplateAnswer(body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.TEMPLATE_ANSWERS}/`,
      method: 'POST',
      noCache: true,
      types: [
        types.CREATE_TEMPLATE_ANSWER_REQUEST,
        types.CREATE_TEMPLATE_ANSWER_SUCCESS,
        types.CREATE_TEMPLATE_ANSWER_ERROR,
      ],
      queryParams: {
        include: {
          media_storage_item: {
            media_storage: null,
          },
        },
      },
      body,
    });
}

export function updateFormItemAccess(body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.FORM_ITEM_ACCESSES}/bulk_action`,
      method: 'PATCH',
      noCache: true,
      types: [
        types.UPDATE_FORM_ITEM_ACCESS_REQUEST,
        types.UPDATE_FORM_ITEM_ACCESS_SUCCESS,
        types.UPDATE_FORM_ITEM_ACCESS_ERROR,
      ],
      body,
    });
}

export function updateTemplateAnswer(id, body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.TEMPLATE_ANSWERS}/${id}`,
      method: 'PUT',
      noCache: true,
      types: [
        types.UPDATE_TEMPLATE_ANSWER_REQUEST,
        types.UPDATE_TEMPLATE_ANSWER_SUCCESS,
        types.UPDATE_TEMPLATE_ANSWER_ERROR,
      ],
      queryParams: {
        include: {
          media_storage_item: {
            media_storage: null,
          },
        },
      },
      body,
    });
}

export function clearCurrentTemplateForm() {
  return {
    type: types.CLEAR_CURRENT_TEMPLATE_FORM,
  };
}

export function setFormError(data) {
  return {
    type: types.SET_CURRENT_TEMPLATE_FORM_ERROR,
    payload: data,
  };
}

export function setFormItem(data) {
  return {
    type: types.SET_CURRENT_TEMPLATE_FORM_ANSWER,
    payload: data,
  };
}

export function createMediaStorage(body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MEDIA_STORAGES}`,
      method: 'POST',
      noCache: true,
      types: [types.CREATE_MEDIA_STORAGE_REQUEST, types.CREATE_MEDIA_STORAGE_SUCCESS, types.CREATE_MEDIA_STORAGE_ERROR],
      body,
    });
}

export function updateMediaStorage(id, body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MEDIA_STORAGES}/${id}`,
      method: 'PUT',
      noCache: true,
      types: [types.UPDATE_MEDIA_STORAGE_REQUEST, types.UPDATE_MEDIA_STORAGE_SUCCESS, types.UPDATE_MEDIA_STORAGE_ERROR],
      body,
    });
}

export function getClientFonts(clientId) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.FONTS}`,
      method: 'GET',
      types: [types.GET_CLIENT_FONTS_REQUEST, types.GET_CLIENT_FONTS_SUCCESS, types.GET_CLIENT_FONTS_ERROR],
      queryParams: {
        include: {
          client_fonts: null,
        },
        q: {
          m: 'or',
          client_fonts_client_id_eq: clientId,
          client_fonts_client_id_null: true,
        },
      },
    });
}

export const getClientTemplates = (queryParams) => (dispatch) =>
  callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.CLIENT_TEMPLATES}`,
    method: 'GET',
    noCache: true,
    types: [types.GET_CLIENT_TEMPLATES_REQUEST, types.GET_CLIENT_TEMPLATES_SUCCESS, types.GET_CLIENT_TEMPLATES_ERROR],
    queryParams,
  });

export const getClientTemplatesByTab = ({tab, accessLevel, queryParams}) =>
  getClientTemplates({
    ...queryParams,
    q: {
      ...queryParams.q,
      ...getQueryParamsByTab({tab, accessLevel}),
    },
  });

export const updateClientTemplate = (id, body) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.CLIENT_TEMPLATES}/${id}`,
        method: 'PATCH',
        types: [
          types.UPDATE_CLIENT_TEMPLATE_REQUEST,
          types.UPDATE_CLIENT_TEMPLATE_SUCCESS,
          types.UPDATE_CLIENT_TEMPLATE_ERROR,
        ],
        body,
      },
      true,
    );
};

export const deleteClientTemplate = (id) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.CLIENT_TEMPLATES}/${id}`,
        method: 'DELETE',
        types: [
          types.DELETE_CLIENT_TEMPLATE_REQUEST,
          types.DELETE_CLIENT_TEMPLATE_SUCCESS,
          types.DELETE_CLIENT_TEMPLATE_ERROR,
        ],
      },
      true,
    );
};

export const saveTemplateToLibrary = (id, body, queryParams) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.TEMPLATES}/${id}/save_to_library`,
        method: 'POST',
        types: [
          types.SAVE_TEMPLATE_TO_LIBRARY_REQUEST,
          types.SAVE_TEMPLATE_TO_LIBRARY_SUCCESS,
          types.SAVE_TEMPLATE_TO_LIBRARY_ERROR,
        ],
        body,
        queryParams,
      },
      true,
    );
};

export const getClientTemplateNames = (clientId) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.CLIENTS}/${clientId}${API_METHODS.CLIENT_TEMPLATE_NAMES}`,
        method: 'GET',
        types: [
          types.GET_CLIENT_TEMPLATE_NAMES_REQUEST,
          types.GET_CLIENT_TEMPLATE_NAMES_SUCCESS,
          types.GET_CLIENT_TEMPLATE_NAMES_ERROR,
        ],
      },
      true,
    );
};

export const createTemplateScreenshot = (id, body, queryParams = {}) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.TEMPLATES}/${id}/attach_screenshots`,
        method: 'PATCH',
        types: [
          types.CREATE_TEMPLATE_SCREENSHOT_REQUEST,
          types.CREATE_TEMPLATE_SCREENSHOT_SUCCESS,
          types.CREATE_TEMPLATE_SCREENSHOT_ERROR,
        ],
        body,
        queryParams,
      },
      true,
    );
};

export const deleteTemplateScreenshot = (templateId, screenshotId) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.REMOVE_IMAGES}`,
        method: 'PATCH',
        types: [
          types.DELETE_TEMPLATE_SCREENSHOT_REQUEST,
          types.DELETE_TEMPLATE_SCREENSHOT_SUCCESS,
          types.DELETE_TEMPLATE_SCREENSHOT_ERROR,
        ],
        queryParams: {
          record_type: 'Template',
          record_id: templateId,
          image_ids: [screenshotId],
        },
      },
      true,
    );
};

export const shareTemplateWithClients = (id, body) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.TEMPLATES}/${id}/share`,
        method: 'POST',
        types: [
          types.SHARE_TEMPLATE_WITH_CLIENTS_REQUEST,
          types.SHARE_TEMPLATE_WITH_CLIENTS_SUCCESS,
          types.SHARE_TEMPLATE_WITH_CLIENTS_ERROR,
        ],
        body,
      },
      true,
    );
};

export const getResultByClientTemplate = (templateId) => (dispatch) =>
  callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.CLIENT_TEMPLATES}/${templateId}/result`,
    method: 'GET',
    types: [
      types.GET_RESULT_BY_CLIENT_TEMPLATE_REQUEST,
      types.GET_RESULT_BY_CLIENT_TEMPLATE_SUCCESS,
      types.GET_RESULT_BY_CLIENT_TEMPLATE_ERROR,
    ],
  });

export function getResultByInterface(interfaceId) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACES}/${interfaceId}/result`,
      method: 'GET',
      types: [
        types.GET_RESULT_BY_INTERFACE_REQUEST,
        types.GET_RESULT_BY_INTERFACE_SUCCESS,
        types.GET_RESULT_BY_INTERFACE_ERROR,
      ],
    });
  };
}

export function updateResult(updatedResult) {
  return {
    type: types.UPDATE_RESULT,
    payload: updatedResult,
  };
}
export function resetResult() {
  return {
    type: types.RESET_RESULT,
  };
}
export function saveResult() {
  return {
    type: types.SAVE_RESULT,
  };
}
