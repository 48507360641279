import React from 'react';

import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useMount} from 'react-use';

import {useLanguage} from 'client/services/hooks';

import {setHeaderLinkBack} from 'client/ducks/header-link-back/actions';

import {set} from 'client/common/meta/meta.actions';

import AnomaliesContainer from 'client/components/instore/containers/anomalies-container';

const AnimationAnomaliesPage = () => {
  const dispatch = useDispatch();
  const lang = useLanguage('ANIMATIONS.PAGES');
  const params = useParams();

  useMount(() => {
    dispatch(set({title: lang.ANOMALIES}));
    dispatch(
      setHeaderLinkBack({
        name: lang.ANOMALIES,
        rootPage: false,
      }),
    );
  });

  return <AnomaliesContainer animationId={+params.animationId} />;
};

export default AnimationAnomaliesPage;
