export default {
  GET_TEMPLATES_REQUEST: '@@templates/GET_TEMPLATES_REQUEST',
  GET_TEMPLATES_SUCCESS: '@@templates/GET_TEMPLATES_SUCCESS',
  GET_TEMPLATES_ERROR: '@@templates/GET_TEMPLATES_ERROR',

  GET_CLIENT_TEMPLATES_REQUEST: '@@templates/GET_CLIENT_TEMPLATES_REQUEST',
  GET_CLIENT_TEMPLATES_SUCCESS: '@@templates/GET_CLIENT_TEMPLATES_SUCCESS',
  GET_CLIENT_TEMPLATES_ERROR: '@@templates/GET_CLIENT_TEMPLATES_ERROR',

  GET_TEMPLATE_FORM_REQUEST: '@@templates/GET_TEMPLATE_FORM_REQUEST',
  GET_TEMPLATE_FORM_SUCCESS: '@@templates/GET_TEMPLATE_FORM_SUCCESS',
  GET_TEMPLATE_FORM_ERROR: '@@templates/GET_TEMPLATE_FORM_ERROR',

  CREATE_TEMPLATE_ANSWER_REQUEST: '@@templates/CREATE_TEMPLATE_ANSWER_REQUEST',
  CREATE_TEMPLATE_ANSWER_SUCCESS: '@@templates/CREATE_TEMPLATE_ANSWER_SUCCESS',
  CREATE_TEMPLATE_ANSWER_ERROR: '@@templates/CREATE_TEMPLATE_ANSWER_ERROR',

  UPDATE_FORM_ITEM_ACCESS_REQUEST: '@@templates/UPDATE_FORM_ITEM_ACCESS_REQUEST',
  UPDATE_FORM_ITEM_ACCESS_SUCCESS: '@@templates/UPDATE_FORM_ITEM_ACCESS_SUCCESS',
  UPDATE_FORM_ITEM_ACCESS_ERROR: '@@templates/UPDATE_FORM_ITEM_ACCESS_ERROR',

  UPDATE_TEMPLATE_ANSWER_REQUEST: '@@templates/UPDATE_TEMPLATE_ANSWER_REQUEST',
  UPDATE_TEMPLATE_ANSWER_SUCCESS: '@@templates/UPDATE_TEMPLATE_ANSWER_SUCCESS',
  UPDATE_TEMPLATE_ANSWER_ERROR: '@@templates/UPDATE_TEMPLATE_ANSWER_ERROR',

  SET_CURRENT_TEMPLATE_FORM_ERROR: '@@templates/SET_CURRENT_TEMPLATE_FORM_ERROR',
  SET_CURRENT_TEMPLATE_FORM_ANSWER: '@@templates/SET_CURRENT_TEMPLATE_FORM_ANSWER',

  REMOVE_CURRENT_TEMPLATE_FORM_ERROR: '@@templates/REMOVE_CURRENT_TEMPLATE_FORM_ERROR',
  CLEAR_CURRENT_TEMPLATE_FORM: '@@templates/CLEAR_CURRENT_TEMPLATE_FORM',

  CREATE_MEDIA_STORAGE_REQUEST: '@@templates/CREATE_MEDIA_STORAGE_REQUEST',
  CREATE_MEDIA_STORAGE_SUCCESS: '@@templates/CREATE_MEDIA_STORAGE_SUCCESS',
  CREATE_MEDIA_STORAGE_ERROR: '@@templates/CREATE_MEDIA_STORAGE_ERROR',

  UPDATE_MEDIA_STORAGE_REQUEST: '@@templates/UPDATE_MEDIA_STORAGE_REQUEST',
  UPDATE_MEDIA_STORAGE_SUCCESS: '@@templates/UPDATE_MEDIA_STORAGE_SUCCESS',
  UPDATE_MEDIA_STORAGE_ERROR: '@@templates/UPDATE_MEDIA_STORAGE_ERROR',

  GET_CLIENT_FONTS_REQUEST: '@@templates/GET_FONTS_REQUEST',
  GET_CLIENT_FONTS_SUCCESS: '@@templates/GET_FONTS_SUCCESS',
  GET_CLIENT_FONTS_ERROR: '@@templates/GET_FONTS_ERROR',

  UPDATE_CLIENT_TEMPLATE_REQUEST: '@@templates/UPDATE_CLIENT_TEMPLATE_REQUEST',
  UPDATE_CLIENT_TEMPLATE_SUCCESS: '@@templates/UPDATE_CLIENT_TEMPLATE_SUCCESS',
  UPDATE_CLIENT_TEMPLATE_ERROR: '@@templates/UPDATE_CLIENT_TEMPLATE_ERROR',

  DELETE_CLIENT_TEMPLATE_REQUEST: '@@templates/DELETE_CLIENT_TEMPLATE_REQUEST',
  DELETE_CLIENT_TEMPLATE_SUCCESS: '@@templates/DELETE_CLIENT_TEMPLATE_SUCCESS',
  DELETE_CLIENT_TEMPLATE_ERROR: '@@templates/DELETE_CLIENT_TEMPLATE_ERROR',

  SAVE_TEMPLATE_TO_LIBRARY_REQUEST: '@@templates/SAVE_TEMPLATE_TO_LIBRARY_REQUEST',
  SAVE_TEMPLATE_TO_LIBRARY_SUCCESS: '@@templates/SAVE_TEMPLATE_TO_LIBRARY_SUCCESS',
  SAVE_TEMPLATE_TO_LIBRARY_ERROR: '@@templates/SAVE_TEMPLATE_TO_LIBRARY_ERROR',

  GET_CLIENT_TEMPLATE_NAMES_REQUEST: '@@templates/GET_CLIENT_TEMPLATE_NAMES_REQUEST',
  GET_CLIENT_TEMPLATE_NAMES_SUCCESS: '@@templates/GET_CLIENT_TEMPLATE_NAMES_SUCCESS',
  GET_CLIENT_TEMPLATE_NAMES_ERROR: '@@templates/GET_CLIENT_TEMPLATE_NAMES_ERROR',

  GET_RESULT_BY_INTERFACE_REQUEST: '@@templates/GET_RESULT_BY_INTERFACE_REQUEST',
  GET_RESULT_BY_INTERFACE_SUCCESS: '@@templates/GET_RESULT_BY_INTERFACE_SUCCESS',
  GET_RESULT_BY_INTERFACE_ERROR: '@@templates/GET_RESULT_BY_INTERFACE_ERROR',

  GET_RESULT_BY_CLIENT_TEMPLATE_REQUEST: '@@templates/GET_RESULT_BY_CLIENT_TEMPLATE_REQUEST',
  GET_RESULT_BY_CLIENT_TEMPLATE_SUCCESS: '@@templates/GET_RESULT_BY_CLIENT_TEMPLATE_SUCCESS',
  GET_RESULT_BY_CLIENT_TEMPLATE_ERROR: '@@templates/GET_RESULT_BY_CLIENT_TEMPLATE_ERROR',

  UPDATE_RESULT: '@@templates/UPDATE_RESULT',
  RESET_RESULT: '@@templates/RESET_RESULT',
  SAVE_RESULT: '@@templates/SAVE_RESULT',

  CREATE_TEMPLATE_SCREENSHOT_REQUEST: '@@templates/CREATE_TEMPLATE_SCREENSHOT_REQUEST',
  CREATE_TEMPLATE_SCREENSHOT_SUCCESS: '@@templates/CREATE_TEMPLATE_SCREENSHOT_SUCCESS',
  CREATE_TEMPLATE_SCREENSHOT_ERROR: '@@templates/CREATE_TEMPLATE_SCREENSHOT_ERROR',

  DELETE_TEMPLATE_SCREENSHOT_REQUEST: '@@templates/REMOVE_TEMPLATE_SCREENSHOT_REQUEST',
  DELETE_TEMPLATE_SCREENSHOT_SUCCESS: '@@templates/REMOVE_TEMPLATE_SCREENSHOT_SUCCESS',
  DELETE_TEMPLATE_SCREENSHOT_ERROR: '@@templates/REMOVE_TEMPLATE_SCREENSHOT_ERROR',

  SHARE_TEMPLATE_WITH_CLIENTS_REQUEST: '@@templates/SHARE_TEMPLATE_WITH_CLIENTS_REQUEST',
  SHARE_TEMPLATE_WITH_CLIENTS_SUCCESS: '@@templates/SHARE_TEMPLATE_WITH_CLIENTS_SUCCESS',
  SHARE_TEMPLATE_WITH_CLIENTS_ERROR: '@@templates/SHARE_TEMPLATE_WITH_CLIENTS_ERROR',
};
