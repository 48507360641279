export function selectLeadsStatistic(state) {
  return state.leadsDashboard.leadsStatistic;
}

export function selectLeadsTimeline(state) {
  return state.leadsDashboard.leadsTimeline;
}

export function selectLeadsOnlineUsers(state) {
  return state.leadsDashboard.leadsOnlineUsers;
}

export function selectLeadsPerCampaign(state) {
  return state.leadsDashboard.leadsPerCampaign;
}

export function selectLeadsClient(state) {
  return state.leadsDashboard.client;
}
