import React, {Component} from 'react';

import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {required, email} from 'client/services/validator';

import {addEmailSender, updateEmailSender, removeEmailSender} from 'client/ducks/email-senders/actions';

import Icon from 'client/common/icon';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

import './params-email-modal.scss';

class ParamsEmailModal extends Component {
  static formName = 'ParamsEmailModalForm';

  static defaultProps = {
    show: false,
  };

  static propTypes = {
    show: PropTypes.bool,
    lang: PropTypes.object.isRequired,
    data: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    addEmailSender: PropTypes.func.isRequired,
    updateEmailSender: PropTypes.func.isRequired,
    removeEmailSender: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.rules = {
      name: required(this.props.lang.NAME_REQUIRED),
      value: [required(this.props.lang.EMAIL_REQUIRED), email(this.props.lang.EMAIL_FORMAT_INCORRECT)],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.props.initialize(this.props.data);
    }
  }

  handleSaveErrors = (response) => {
    const {error, payload} = response;
    const formErrors = {};

    if (!error) {
      return;
    }

    ['name', 'value'].forEach((key) => {
      const item = get(payload, `response.errors.${key}`);
      if (isArray(item) && item.includes('taken')) {
        formErrors[key] = this.props.lang.VALUE_TAKEN;
      }
    });

    if (!isEmpty(formErrors)) {
      throw new SubmissionError({
        ...formErrors,
      });
    }
  };

  save = async (form) => {
    const {data} = this.props;

    if (!data) {
      await this.props.addEmailSender(form).then(this.handleSaveErrors);
    } else {
      await this.props.updateEmailSender(data.id, form).then(this.handleSaveErrors);
    }

    this.props.reset();
    this.props.onConfirm();
  };

  handleRemove = async () => {
    const {data} = this.props;

    await this.props.removeEmailSender(data.id);

    this.props.reset();
    this.props.onConfirm();
  };

  handleClose = () => {
    this.props.onClose();
    this.props.reset();
  };

  render() {
    const {handleSubmit, show, data, lang} = this.props;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-7"
        backdropClassName="modal-window__backdrop"
        onClose={this.handleClose}
        title={lang.TITLE}
      >
        <form onSubmit={handleSubmit(this.save)}>
          <FormFieldWrap>
            <Field
              label={lang.NAME_LABEL + ' *'}
              name="name"
              type="text"
              validate={this.rules.name}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              label={lang.EMAIL_LABEL + ' *'}
              name="value"
              type="text"
              validate={this.rules.value}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          {data && (
            <FormFieldWrap>
              <button className="button button--bg-12" type="button" onClick={this.handleRemove}>
                <Icon name="trash" className="button__icon" width={17} height={19} />
                <span>{lang.REMOVE_BUTTON}</span>
              </button>
            </FormFieldWrap>
          )}
          <div className="modal-window__footer modal-window__footer--centered params-email-modal__footer">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={this.handleClose}>
              {lang.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-2 modal-window__footer-btn" type="submit">
              {data ? lang.SAVE_BUTTON : lang.ADD_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const form = reduxForm({
  form: ParamsEmailModal.formName,
})(ParamsEmailModal);

const mapStateToProps = ({languageState}) => ({
  lang: languageState.payload.PARAMETERS.EMAIL_MODAL,
});

const mapDispatchToProps = {
  addEmailSender,
  updateEmailSender,
  removeEmailSender,
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
