export const NO_DATA_LABEL = 'No data';

export const COLORS = [
  '#00629C',
  '#E12B2B',
  '#B958C9',
  '#76B136',
  '#F67D3C',
  '#F6D976',
  '#B92A6C',
  '#71C9FB',
  '#98EA91',
  '#FFAAAA',
  '#F4A0FB',
  '#48B7B7',
  '#89B6F9',
  '#FFC267',
  '#42D085',
  '#8CEEEE',
  '#787DF8',
  '#F44E9A',
  '#D0F1AC',
  '#F6D7FF',
];
