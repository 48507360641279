import React, {Component} from 'react';

import find from 'lodash/find';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {transformDate} from 'client/services/helpers';

import {SCENARIO_STEP_TYPES} from 'client/common/config';

import StepNav, {StepNavItem} from 'client/components/common/step-nav';
import VTabs, {VTabsCol, VTabsPane} from 'client/components/common/v-tabs';

import BranchScenarioStep from '../steps/branch-step';
import CalculationScenarioStep from '../steps/calculation-scenario-step';
import EmailScenarioStep from '../steps/email-step';
import InstantLotteryScenarioStep from '../steps/instant-lottery-step';
import ScheduledLotteryScenarioStep from '../steps/scheduled-lottery-step';
import SmsScenarioStep from '../steps/sms-step';
import SocialPostScenarioStep from '../steps/social-post-step';
import StepContainer from '../steps/step-container';
import StepErrorContainer from '../steps/step-error-container';
import TimerScenarioStep from '../steps/timer-step';
import VariableValuesStep from '../steps/variable-values';

import './result-steps-list.scss';

const STEPS_MAPPING = {
  TimerScenarioStep: (props) => <TimerScenarioStep {...props} />,
  BranchScenarioStep: (props) => <BranchScenarioStep {...props} />,
  TwitterScenarioStep: (props) => <SocialPostScenarioStep {...props} />,
  FacebookScenarioStep: (props) => <SocialPostScenarioStep {...props} />,
  SmsScenarioStep: (props) => <SmsScenarioStep {...props} />,
  EmailScenarioStep: (props) => <EmailScenarioStep {...props} />,
  InstantLotteryScenarioStep: (props) => <InstantLotteryScenarioStep {...props} />,
  ScheduledLotteryScenarioStep: (props) => <ScheduledLotteryScenarioStep {...props} />,
  CouponScenarioStep: (props) => <ScheduledLotteryScenarioStep {...props} />,
  CalculationScenarioStep: (props) => <CalculationScenarioStep {...props} />,
};

class TestStepsList extends Component {
  static propTypes = {
    scenarioErrors: PropTypes.array,
    variables: PropTypes.array,
    languageState: PropTypes.object.isRequired,
    scenarioSteps: PropTypes.array,
    stepStates: PropTypes.object,
    stoppedBy: PropTypes.object,
    endedAt: PropTypes.string,
    variableValues: PropTypes.object,
  };

  static defaultProps = {
    scenarioSteps: [],
    usedVariables: [],
    variables: [],
    stepStates: {},
    variableValues: {},
  };

  state = {
    activeKey: 'variables',
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.RESULT_MODAL;
    this.LANGUAGE_SCENARIO_STEPS = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.SCENARIO_STEPS;
  }

  getValuedMessage = (message) => {
    const {variables, variableValues} = this.props;
    const variableIds = message.match(/{{[0-9]+}}/g);

    let result = message;

    if (!variableIds) {
      return result;
    }

    variableIds
      .map((i) => Number(i.slice(2, -2)))
      .forEach((id) => {
        const variable = find(variables, (v) => v.id === id);
        if (!variable) {
          return;
        }

        let valueToReplace = variableValues[id];

        switch (variable.kind) {
          case 'file':
            valueToReplace = valueToReplace && valueToReplace.url;
            break;

          case 'datetime':
            valueToReplace = valueToReplace && transformDate(valueToReplace, true, true);
            break;

          default:
            break;
        }
        result = result.replace(`{{${id}}}`, `{{${variable.full_name || variable.name}: ${valueToReplace}}}`);
      });

    return result;
  };

  renderScenarioSteps = (steps, variableValues) => {
    return steps.map((step, key) => (
      <VTabsPane
        eventKey={'' + step.id}
        key={key}
        className="test-step-list__content-col"
        activeKey={this.state.activeKey}
      >
        <VTabsCol>
          <StepContainer
            variables={step.scenario_step_variables}
            values={variableValues}
            isLottery={step.implementation_type === SCENARIO_STEP_TYPES.instantLottery}
          >
            {STEPS_MAPPING[step.implementation_class_name]({
              step,
              variableValues,
              getValuedMessage: this.getValuedMessage,
            })}
          </StepContainer>
        </VTabsCol>
      </VTabsPane>
    ));
  };

  renderScenarioErrors = (scenarioSteps, scenarioErrors) => {
    const errors = {};
    scenarioErrors.forEach((err) => (errors[err.scenario_step_id] = JSON.stringify({message: err.message}, 0, 2)));

    return scenarioSteps.map((step, key) => (
      <VTabsPane
        eventKey={'' + step.id}
        key={key}
        className="test-step-list__content-col"
        activeKey={this.state.activeKey}
      >
        <VTabsCol>
          <StepErrorContainer error_message={errors[step.id] ? errors[step.id] : ''} />
        </VTabsCol>
      </VTabsPane>
    ));
  };

  render() {
    const {LANGUAGE, LANGUAGE_SCENARIO_STEPS} = this;

    const {scenarioErrors, variables, scenarioSteps, stepStates, variableValues, stoppedBy, endedAt} = this.props;

    return (
      <VTabs
        id="results"
        className="theme-color-9 rt-result-steps"
        defaultActiveKey="variables"
        navbar={
          <div>
            <StepNav>
              <StepNavItem
                className="step-nav__item--view-1 rt-result-steps__nav-item--big title-text"
                title={<div className="break-word">{LANGUAGE.VARIABLE_VALUES_TITLE}</div>}
                eventKey="variables"
                onClick={() => this.setState({activeKey: 'variables'})}
                active={this.state.activeKey === 'variables'}
              />
            </StepNav>

            <div className="rt-result-steps__list-title break-word title-text">{LANGUAGE.STEP_PARAMETERS_TITLE}</div>

            <StepNav>
              {scenarioSteps.map((step, key) => {
                if (!stepStates[step.id]) {
                  return null;
                }

                const stepProps = {
                  onClick: () => this.setState({activeKey: step.id.toString()}),
                  active: this.state.activeKey === step.id.toString(),
                  className: 'step-nav__item--view-1 rt-result-steps__nav-item',
                  eventKey: '' + step.id,
                  name: <div className="break-word">{step.name}</div>,
                  content: (
                    <div className="rt-result-steps__nav-item__content">
                      {LANGUAGE_SCENARIO_STEPS[step.implementation_class_name]}
                    </div>
                  ),
                  key,
                };

                if (stoppedBy) {
                  stepProps.content = (
                    <div className="rt-result-steps__nav-item__content">
                      {LANGUAGE_SCENARIO_STEPS[step.implementation_class_name]}

                      <div className="rt-result-steps__nav-item__content__stopped-by ellipsis-text">
                        {LANGUAGE.STOPPED_BY_LABEL}&nbsp;
                        <span className="ellipsis-text" title={`${stoppedBy.first_name} ${stoppedBy.last_name}`}>
                          {`${stoppedBy.first_name} ${stoppedBy.last_name}`}
                        </span>
                      </div>
                      <div className="rt-result-steps__nav-item__content__stopped-when ellipsis-text">
                        {moment(endedAt).format('DD/MM/YYYY HH:mm:ss')}
                      </div>
                    </div>
                  );
                }

                if (stepStates[step.id] === 'failed') {
                  stepProps.className += ' rt-result-steps__nav-item--failed';
                }

                return <StepNavItem {...stepProps} key={step.id} />;
              })}
            </StepNav>
          </div>
        }
      >
        <VTabsPane eventKey="variables" className="test-step-list__content-col" activeKey={this.state.activeKey}>
          <VTabsCol>
            <VariableValuesStep variables={variables} values={variableValues} />
          </VTabsCol>
        </VTabsPane>

        {this.renderScenarioSteps(scenarioSteps, variableValues)}
        {this.renderScenarioErrors(scenarioSteps, scenarioErrors)}
      </VTabs>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(TestStepsList);
