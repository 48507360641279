import {dateToString} from 'client/services/helpers';

import {COUPON_TOKEN_MODES} from 'client/models/prizes/constants';
import {Coupon} from 'client/models/prizes/types';
import {CouponCodeInterval} from 'client/models/prizes/types';

const mapCodeInterval = (item: CouponCodeInterval) => {
  return {
    code: item.code,
    from: dateToString(item.from),
    to: dateToString(item.to),
  };
};

export default (data?: Coupon) => {
  return {
    token_mode: data?.token_mode || COUPON_TOKEN_MODES.PERMANENT,
    token_code: data?.token_code,
    coupon_code_intervals: data?.coupon_code_intervals?.length
      ? data.coupon_code_intervals.map(mapCodeInterval)
      : [{code: '', from: '', to: ''}],
    initial_interval_ids: data?.coupon_code_intervals?.map((i) => i.id) || [],
  };
};
