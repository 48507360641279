import {NA_VALUE, DEFAULT_COLOR, CHART_COLORS} from './constants';

export const mapSourceType = (map, sourceType) => {
  return map[sourceType.toUpperCase()] || sourceType;
};

export const getChartColor = (source, index) => {
  return source.source_type === NA_VALUE ? DEFAULT_COLOR : CHART_COLORS[index];
};

export const calculateVisits = ({direct_access, sources}) => {
  return (sources.length ? sources.reduce((acc, {visits_count}) => acc + visits_count, 0) : 0) + direct_access;
};

export const getChartData = ({sources, direct_access}, sourceTypes) => {
  if (!sources.length && !direct_access) {
    return null;
  }

  const mappedSources =
    sources?.map((source, idx) => ({
      label:
        source.source_type === NA_VALUE
          ? sourceTypes.SOURCE_TYPES.UNKNOWN
          : mapSourceType(sourceTypes, source.source_type),
      value: source.visits_count,
      color: getChartColor(source, idx),
    })) || [];

  if (direct_access) {
    return [
      ...mappedSources,
      {
        label: 'Direct',
        value: direct_access,
        color: CHART_COLORS[mappedSources.length + 1],
      },
    ];
  }

  return mappedSources;
};
