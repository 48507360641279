import React from 'react';

import PropTypes from 'prop-types';

import {useLanguage} from 'client/services/hooks';

import DateCell from 'client/common/cells/date-cell';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

const AccessLevelCellOverlay = ({accessLevel}) => {
  const lang = useLanguage('PARTICIPANTS_LIST');

  if (!accessLevel.levels_data?.length) {
    return null;
  }

  return (
    <div className="popover-list popover-list--color-3">
      <h3 className="popover-list__title">{lang.ACCESSED_LEVEL_HISTORY}</h3>
      <CustomScrollbars
        scrollbarProps={{
          autoHeightMax: 280,
        }}
      >
        <ul className="popover-list__list popover-list__list--markers">
          {accessLevel.levels_data.map((item) => (
            <li key={item.reached_at} className="popover-list__item popover-list__item--row">
              <p className="popover-list__text">{item.accessed_level}</p>
              <p className="popover-list__text">{item.interface_name}</p>
              <p className="popover-list__text popover-list__text--color-1">
                <DateCell value={item.reached_at} isColumn />
              </p>
            </li>
          ))}
        </ul>
      </CustomScrollbars>
    </div>
  );
};

export const AccessLevelType = PropTypes.shape({
  accessed_level: PropTypes.string.isRequired,
  levels_data: PropTypes.arrayOf({
    interface_level: PropTypes.shape({
      number: PropTypes.number.isRequired,
      level_name: PropTypes.string.isRequired,
    }),
    reached_at: PropTypes.string.isRequired,
  }).isRequired,
});

AccessLevelCellOverlay.propTypes = {
  accessLevel: AccessLevelType.isRequired,
};

export default AccessLevelCellOverlay;
