import React from 'react';

import Spinner from 'client/components/common/spinner';

import useFetchSelectedAgencyClient from 'client/components/agencies/hooks/useFetchSelectedAgencyClient';
import ClientOperationsList from 'client/components/operations-client/components/lists/client-operations-list';

type ClientOperationsContainerProps = {
  clientId: number;
};

const ClientOperationsContainer: React.FC<ClientOperationsContainerProps> = (props) => {
  const {clientId} = props;
  const {loading, generalClient, agencyClient} = useFetchSelectedAgencyClient(clientId);

  if (loading) {
    return <Spinner color="primary" pageCentered />;
  }

  return <ClientOperationsList generalClient={generalClient} agencyClient={agencyClient} />;
};

export default ClientOperationsContainer;
