import {AgencyCreateClientModalFormValues} from 'client/components/agencies/modals/agency-client-modal/types';
import {Client} from 'client/models/client/types';

export default (client?: Client | null): Partial<AgencyCreateClientModalFormValues> => {
  const formValues: Partial<AgencyCreateClientModalFormValues> = {
    country: client?.country,
    siren_number: client?.siren_number,
    name: client?.name,
    brand_name: client?.brand_name,
    category_id: client?.category?.id?.toString(),
    subcategory_id: client?.subcategory_id?.toString(),
    poc_agency_membership_id: client?.poc_agency_membership_id?.toString(),
    street_address: client?.street_address,
    zip: client?.zip,
    city: client?.city,
  };

  if (client?.logo_url) {
    formValues.logo_image = {
      data: client.logo_url,
    };
  }

  return formValues;
};
