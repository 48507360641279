import React from 'react';

import PropTypes from 'prop-types';

import {formatNumberSpace} from 'client/services/formatters';
import {getPercent} from 'client/services/helpers';

import Icon from 'client/components/common/icon';
import PercentageBar from 'client/components/common/percentage-bar';

import {TranslationJsx} from 'client/models/language/types';

import './contact-bars.scss';

const mapData = (data) => {
  const {total, with_phone, with_email, with_address} = data;

  return [
    {
      icon: 'phones',
      value: with_phone,
      percent: getPercent(total, with_phone, false),
    },
    {
      icon: 'email',
      value: with_email,
      percent: getPercent(total, with_email, false),
    },
    {
      icon: 'address',
      value: with_address,
      percent: getPercent(total, with_address, false),
    },
  ];
};

const ContactBars = (props) => {
  const {title, data} = props;

  const mappedData = mapData(data);

  return (
    <div className="contact-bars">
      {title && <div className="contact-bars__title">{title}</div>}
      <div className="contact-bars__content">
        <div className="contact-bars__values">
          {mappedData.map(({icon, value}, index) => (
            <div key={index} className="contact-bars__value">
              <Icon name={icon} className="contact-bars__icon" />
              {formatNumberSpace(value)}
            </div>
          ))}
        </div>
        <div className="contact-bars__percents">
          {mappedData.map(({percent}, index) => (
            <div key={index} className="contact-bars__percent">
              {percent ? percent + '%' : null}
            </div>
          ))}
        </div>
        <div className="contact-bars__bars">
          {mappedData.map(({percent}, index) => (
            <div key={index} className="contact-bars__bar">
              <PercentageBar value={Number(percent)} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ContactBars.propTypes = {
  title: TranslationJsx,
  data: PropTypes.shape({
    total: PropTypes.number.isRequired,
    with_phone: PropTypes.number.isRequired,
    with_email: PropTypes.number.isRequired,
    with_address: PropTypes.number.isRequired,
  }).isRequired,
};

ContactBars.defaultProps = {
  title: null,
};

export default ContactBars;
