import {generatePath} from 'react-router';

import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getPrivacyPolicies(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PRIVACY_POLICY}`,
      method: 'GET',
      types: [types.GET_PRIVACY_POLICIES_REQUEST, types.GET_PRIVACY_POLICIES_SUCCESS, types.GET_PRIVACY_POLICIES_ERROR],
      queryParams,
    });
}

export function acceptPrivacyPolicy(id, body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${generatePath(API_METHODS.PRIVACY_POLICY_ACCEPT, {id})}`,
      method: 'POST',
      types: [
        types.POST_ACCEPT_PRIVACY_POLICY_REQUEST,
        types.POST_ACCEPT_PRIVACY_POLICY_SUCCESS,
        types.POST_ACCEPT_PRIVACY_POLICY_ERROR,
      ],
      body,
    });
}
