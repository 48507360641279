import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import {set as setMetaAction} from 'client/common/meta/meta.actions';

import TestParameters from '../components/test-parameters';

class TestParametersPage extends Component {
  static propTypes = {
    ...withRouter.propTypes,
    setMetaAction: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.setMetaAction({title: this.props.lang.TITLE});
  }

  render() {
    return <TestParameters id={+this.props.match.params.id} history={this.props.history} />;
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setMetaAction,
    },
    dispatch,
  );

export default connect(
  ({languageState}) => ({
    lang: languageState.payload.TEST_PARAMETERS,
  }),
  mapDispatchToProps,
)(TestParametersPage);
