import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons/app-button';
import Popover from 'client/common/popovers/popover';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';
import Spinner from 'client/components/common/spinner';

import {Translation} from 'client/models/language/types';

import cssModule from './leads-params-column.module.scss';

const b = bem('leads-params-column', {cssModule});

type LeadsParamsColumnProps = {
  className?: string;
  children: React.ReactNode;
  label?: Translation | React.ReactNode;
  count?: number;
  onButtonClick?: ((nextValue?: boolean) => void) | null;
  buttonTitle?: Translation;
  loading?: boolean;
  maxHeight?: number;
  empty?: boolean;
  emptyText?: Translation;
};

const LeadsParamsColumn: React.FC<LeadsParamsColumnProps> = (props) => {
  const {
    className,
    children,
    label,
    count,
    onButtonClick,
    buttonTitle,
    loading,
    maxHeight = 310,
    empty,
    emptyText,
  } = props;

  const addButton = (
    <AppButton className={b('plus-button')} iconName="plus-simple" title={buttonTitle} onClick={onButtonClick} />
  );

  return (
    <div className={cn(b(), className)}>
      {label && (
        <div className={b('top')}>
          <p className={b('label')}>
            {label}
            {count && !loading ? ` (${count})` : null}
          </p>
          {onButtonClick && buttonTitle && (
            <Popover overlay={buttonTitle} position="top">
              {addButton}
            </Popover>
          )}
          {onButtonClick && !buttonTitle && addButton}
        </div>
      )}
      <CustomScrollbars scrollbarProps={{autoHeightMin: maxHeight, autoHeightMax: maxHeight}}>
        <div className={b('content')}>
          {loading && <Spinner className={b('spinner')} centered />}
          {!empty && !loading && children}
          {empty && !loading && (
            <div className={b('empty-wrapper')}>
              <Icon name="table-no-data" />
              <p className={b('empty-text')}>{emptyText}</p>
            </div>
          )}
        </div>
      </CustomScrollbars>
    </div>
  );
};

export default LeadsParamsColumn;
