import {mapWinnerExportReport} from './mappers';
import types from './types';

const initialState = {
  exportReports: {
    meta: {},
    items: [],
  },
  exportReport: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_WINNER_EXPORT_REPORTS_SUCCESS:
      return {
        ...state,
        exportReports: {
          meta: action.payload.meta,
          items: action.payload.winner_export_reports.map(mapWinnerExportReport),
        },
      };
    case types.GET_WINNER_EXPORT_REPORT_SUCCESS:
      return {
        ...state,
        exportReport: action.payload.winner_export_report,
      };
    default:
      return state;
  }
}
