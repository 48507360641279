import {useCallback, useEffect, useMemo, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {selectAutotaskParam} from 'client/ducks/autotask/selectors';
import {getPrizes} from 'client/ducks/games/actions';
import {selectPrizes} from 'client/ducks/games/selectors';

const useFetchPrizes = () => {
  const dispatch = useDispatch();
  const prizes = useSelector(selectPrizes);
  const clientGames = useSelector(selectAutotaskParam('client_games'));
  const [loading, setLoading] = useState(true);

  const clientGameId = useMemo(() => {
    return clientGames?.find((game) => game.game_type === 'instant_win')?.id;
  }, [clientGames]);

  const fetchPrizes = useCallback(async () => {
    if (clientGameId) {
      setLoading(true);
      await dispatch(
        getPrizes({
          include: {coupon: {imports: null}, imports: null},
          q: {
            game_id_eq: clientGameId,
            coupon_device_level_code_list_eq: true,
          },
        }),
      );
    }
    setLoading(false);
  }, [dispatch, clientGameId]);

  useEffect(() => {
    fetchPrizes();
  }, [fetchPrizes]);

  return {
    data: prizes,
    loading,
    fetchPrizes,
  };
};

export default useFetchPrizes;
