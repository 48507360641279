import React, {useCallback, useMemo} from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {CheckboxField, NumberField, ToggleField} from 'client/common/fields';

import ClientTable from 'client/components/common/client-table';
import {ClientTableColumn, RenderCallback} from 'client/components/common/client-table/types';

import {PrizeMap} from 'client/models/prizes/types';

import cssModule from './lottery-prize-maps-table.module.scss';

const b = bem('lottery-prize-maps-table', {cssModule});

type LotteryPrizeMapTableProps = {
  className?: string;
  data: PrizeMap[];
  location: 'DRAW' | 'PRIZE_CUSTOMIZATION';
  level?: 'GLOBAL' | 'ONLINE' | 'DEVICE';
  hasSegmentation: boolean;
  disabled: boolean;
};

/**
 * LotteryPrizeMapsTable - Component is for displaying prize maps of lottery game.
 * This component works only with redux-form
 */
const LotteryPrizeMapsTable: React.FC<LotteryPrizeMapTableProps> = (props) => {
  const {className, data, location, level, hasSegmentation, disabled} = props;

  const lang = useLanguage('LOTTERY.TABLES.PRIZE_MAPS');

  const isActive = (item: PrizeMap) => item.active && item.prize_draw_active;

  const renderInput = useCallback(
    (name: string): RenderCallback<PrizeMap> =>
      ({index, item}) =>
        (
          <NumberField
            hoveringMode
            name={`prize_maps[${index}].${name}`}
            inputClassName={b('field', {
              [`prize-active`]: isActive(item),
            })}
            key={item.active?.toString()}
            readOnly={!isActive(item) || disabled}
            min={0}
          />
        ),
    [disabled],
  );

  const columns = useMemo(() => {
    const result: ClientTableColumn<PrizeMap>[] = [
      {
        name: 'prize_internal_name',
        path: 'prize_internal_name',
        label: lang.NAME_COLUMN,
        cellClassName: (item) => b('cell', {'prize-active': isActive(item), name: true}),
      },
    ];

    if (location === 'PRIZE_CUSTOMIZATION') {
      result.push(
        {
          name: 'current_stock',
          path: 'current_stock',
          width: 110,
          label: lang.CURRENT_STOCK_COLUMN,
          cellClassName: (item) => b('cell', {'prize-active': isActive(item)}),
        },
        {
          name: 'initial_stock',
          path: 'initial_stock',
          width: 110,
          label: lang.INITIAL_STOCK_COLUMN,
          render: renderInput('initial_stock'),
          cellClassName: (item) => b('cell', {'prize-active': isActive(item)}),
        },
      );
    } else if (hasSegmentation) {
      result.push({
        name: 'total_quantity',
        width: 110,
        label: lang.TOTAL_QUANTITY_COLUMN,
        cellClassName: (item) => b('cell', {'prize-active': isActive(item)}),
      });

      if (level === 'GLOBAL') {
        result.push(
          {
            name: 'online_quantity',
            width: 110,
            label: lang.ONLINE_QUANTITY_COLUMN,
            render: renderInput('online_quantity'),
            cellClassName: (item) => b('cell', {'prize-active': isActive(item)}),
          },
          {
            name: 'device_quantity',
            width: 110,
            label: lang.DEVICE_QUANTITY_COLUMN,
            render: renderInput('device_quantity'),
            cellClassName: (item) => b('cell', {'prize-active': isActive(item)}),
          },
        );
      } else {
        result.push({
          name: 'default_quantity',
          width: 110,
          label: lang.DEFAULT_QUANTITY_COLUMN,
          render: renderInput('default_quantity'),
          cellClassName: (item) => b('cell', {'prize-active': isActive(item)}),
        });
      }
    } else {
      result.push({
        name: 'quantity',
        width: 110,
        label: lang.QUANTITY_COLUMN,
        render: renderInput('quantity'),
        cellClassName: (item) => b('cell', {'prize-active': isActive(item)}),
      });
    }

    result.push(
      {
        label: lang.WIN_ANNOUNCEMENT_COLUMN,
        name: 'win_announce',
        width: 160,
        subcolumns: [
          {
            name: 'email_send',
            path: `prize_maps`,
            width: 80,
            label: lang.EMAIL_COLUMN,
            render: ({index, item}) => (
              <CheckboxField
                name={`prize_maps[${index}].email_send`}
                key={item?.email_send?.toString()}
                disabled={!isActive(item) || disabled}
              />
            ),
          },
          {
            name: 'sms_send',
            label: lang.SMS_COLUMN,
            width: 80,
            render: ({index, item}) => (
              <CheckboxField
                name={`prize_maps[${index}].sms_send`}
                key={item?.sms_send?.toString()}
                disabled={!isActive(item) || disabled}
              />
            ),
          },
        ],
      },
      {
        name: 'toggle',
        path: 'active',
        width: 90,
        label: lang.ACTIVE_COLUMN,

        labelClassName: b('cell', {'prize-active': true, toggle: true}),
        cellClassName: (item) => b('cell', {'prize-active': item.prize_draw_active, toggle: true}),
        render: ({index, item}) => {
          return (
            <ToggleField
              name={`prize_maps[${index}].active`}
              color="primary"
              rounded
              disabled={!item.prize_draw_active || disabled}
            />
          );
        },
      },
    );

    return result;
  }, [hasSegmentation, lang, level, location, renderInput, disabled]);

  return (
    <div className={className}>
      <ClientTable data={data} columns={columns} className={b()} />
    </div>
  );
};

export default LotteryPrizeMapsTable;
