export default {
  LIST_LOADING: '@@scenario/LIST_LOADING',
  LIST_LOADED: '@@scenario/LIST_LOADED',
  LIST_LOAD_FAILED: '@@scenario/LIST_LOAD_FAILED',

  NO_DATA: '@@scenario/NO_DATA',
  LOADING: '@@scenario/LOADING',
  LOADED: '@@scenario/LOADED',
  LOAD_FAILED: '@@scenario/LOAD_FAILED',

  UPDATING: '@@scenario/UPDATING',
  UPDATED: '@@scenario/UPDATED',
  UPDATE_FAILED: '@@scenario/UPDATE_FAILED',

  DELETING: '@@scenario/DELETING',
  DELETED: '@@scenario/DELETED',
  DELETE_FAILED: '@@scenario/DELETE_FAILED',

  VARIABLE_LIST_LOADING: '@@scenario/VARIABLE_LIST_LOADING',
  VARIABLE_LIST_LOADED: '@@scenario/VARIABLE_LIST_LOADED',
  VARIABLE_LIST_LOAD_FAILED: '@@scenario/VARIABLE_LIST_LOAD_FAILED',

  VARIABLE_CREATING: '@@scenario/VARIABLE_CREATING',
  VARIABLE_CREATED: '@@scenario/VARIABLE_CREATED',
  VARIABLE_CREATE_FAILED: '@@scenario/VARIABLE_CREATE_FAILED',

  VARIABLE_UPDATING: '@@scenario/VARIABLE_UPDATING',
  VARIABLE_UPDATED: '@@scenario/VARIABLE_UPDATED',
  VARIABLE_UPDATE_FAILED: '@@scenario/VARIABLE_UPDATE_FAILED',

  VARIABLE_DELETING: '@@scenario/VARIABLE_DELETING',
  VARIABLE_DELETED: '@@scenario/VARIABLE_DELETED',
  VARIABLE_DELETE_FAILED: '@@scenario/VARIABLE_DELETE_FAILED',

  STEP_LIST_LOADING: '@@scenario/STEP_LIST_LOADING',
  STEP_LIST_LOADED: '@@scenario/STEP_LIST_LOADED',
  STEP_LIST_LOAD_FAILED: '@@scenario/STEP_LIST_LOAD_FAILED',
  STEP_ADD_INTO_STORE: '@@scenario/STEP_ADD_INTO_STORE',
  STEP_SORT_IN_STORE: '@@scenario/STEP_SORT_IN_STORE',
  STEP_LIST_REMOVE_NOT_SAVED: '@@scenario/STEP_REMOVE_NOT_SAVED',

  STEP_CREATING: '@@scenario/STEP_CREATING',
  STEP_CREATED: '@@scenario/STEP_CREATED',
  STEP_CREATE_FAILED: '@@scenario/STEP_CREATE_FAILED',

  STEP_UPDATING: '@@scenario/STEP_UPDATING',
  STEP_UPDATED: '@@scenario/STEP_UPDATED',
  STEP_UPDATE_FAILED: '@@scenario/STEP_UPDATE_FAILED',

  STEP_DELETING: '@@scenario/STEP_DELETING',
  STEP_DELETED: '@@scenario/STEP_DELETED',
  STEP_DELETE_FAILED: '@@scenario/STEP_DELETE_FAILED',

  EXECUTION_LOG_LIST_LOADING: '@@scenario/EXECUTION_LOG_LIST_LOADING',
  EXECUTION_LOG_LIST_LOADED: '@@scenario/EXECUTION_LOG_LIST_LOADED',
  EXECUTION_LOG_LIST_LOAD_FAILED: '@@scenario/EXECUTION_LOG_LIST_LOAD_FAILED',

  BRANCH_SCENARIO_STEP_DELETING: '@@scenario/BRANCH_SCENARIO_STEP_DELETING',
  BRANCH_SCENARIO_STEP_DELETED: '@@scenario/BRANCH_SCENARIO_STEP_DELETED',
  BRANCH_SCENARIO_STEP_DELETE_FAILED: '@@scenario/BRANCH_SCENARIO_STEP_DELETE_FAILED',

  INSTANT_LOTTERY_SCENARIO_STEP_DELETING: '@@scenario/INSTANT_LOTTERY_SCENARIO_STEP_DELETING',
  INSTANT_LOTTERY_SCENARIO_STEP_DELETED: '@@scenario/INSTANT_LOTTERY_SCENARIO_STEP_DELETED',
  INSTANT_LOTTERY_SCENARIO_STEP_DELETE_FAILED: '@@scenario/INSTANT_LOTTERY_SCENARIO_STEP_DELETE_FAILED',

  FILE_SCENARIO_STEP_IMAGE_UPLOADING: '@@scenario/FILE_SCENARIO_STEP_IMAGE_UPLOADING',
  FILE_SCENARIO_STEP_IMAGE_UPLOADED: '@@scenario/FILE_SCENARIO_STEP_IMAGE_UPLOADED',
  FILE_SCENARIO_STEP_IMAGE_UPLOAD_FAILED: '@@scenario/FILE_SCENARIO_STEP_IMAGE_UPLOAD_FAILED',

  SOCIAL_SCENARIO_STEP_IMAGE_DELETING: '@@scenario/SOCIAL_SCENARIO_STEP_IMAGE_DELETING',
  SOCIAL_SCENARIO_STEP_IMAGE_DELETED: '@@scenario/SOCIAL_SCENARIO_STEP_IMAGE_DELETED',
  SOCIAL_SCENARIO_STEP_IMAGE_DELETE_FAILED: '@@scenario/SOCIAL_SCENARIO_STEP_IMAGE_DELETE_FAILED',

  TEST_SCENARIO_REQUEST: '@@scenario/TEST_SCENARIO_REQUEST',
  TEST_SCENARIO_SUCCESS: '@@scenario/TEST_SCENARIO_SUCCESS',
  TEST_SCENARIO_ERROR: '@@scenario/TEST_SCENARIO_ERROR',

  GET_POSSIBLE_TEST_VARIABLES_REQUEST: '@@scenario/GET_POSSIBLE_TEST_VARIABLES_REQUEST',
  GET_POSSIBLE_TEST_VARIABLES_SUCCESS: '@@scenario/GET_POSSIBLE_TEST_VARIABLES_SUCCESS',
  GET_POSSIBLE_TEST_VARIABLES_ERROR: '@@scenario/GET_POSSIBLE_TEST_VARIABLES_ERROR',

  GET_PRIZES_REQUEST: '@@scenario/GET_PRIZES_REQUEST',
  GET_PRIZES_SUCCESS: '@@scenario/GET_PRIZES_SUCCESS',
  GET_PRIZES_ERROR: '@@scenario/GET_PRIZES_ERROR',

  GET_COLUMN_ADAPTERS_REQUEST: '@@scenario/GET_COLUMN_ADAPTERS_REQUEST',
  GET_COLUMN_ADAPTERS_SUCCESS: '@@scenario/GET_COLUMN_ADAPTERS_SUCCESS',
  GET_COLUMN_ADAPTERS_ERROR: '@@scenario/GET_COLUMN_ADAPTERS_ERROR',

  GET_PARTICIPATION_COLUMN_ADAPTERS_REQUEST: '@@scenario/GET_PARTICIPATION_COLUMN_ADAPTERS_REQUEST',
  GET_PARTICIPATION_COLUMN_ADAPTERS_SUCCESS: '@@scenario/GET_PARTICIPATION_COLUMN_ADAPTERS_SUCCESS',
  GET_PARTICIPATION_COLUMN_ADAPTERS_ERROR: '@@scenario/GET_PARTICIPATION_COLUMN_ADAPTERS_ERROR',
};
