import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {set as setMetaAction} from 'client/common/meta/meta.actions';

import ClientDevices from 'client/components/devices/lists/client-devices-list';

const ClientDevicesPage: React.FC = () => {
  const lang = useLanguage('CLIENT_DEVICES');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMetaAction({title: lang.TITLE}));
  }, [dispatch, lang.TITLE]);

  return <ClientDevices />;
};

export default ClientDevicesPage;
