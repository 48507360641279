import React, {Component} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import BarChart from 'client/common/charts/bar-chart';
import PieChart from 'client/common/charts/pie-chart';
import Icon from 'client/common/icon';

import {TranslationJsx} from 'client/models/language/types';

import CampaignCardHeader from '../campaign-card-header';

import cssModule from './campaign-card.module.scss';

const statusesMap = {
  1: 'status-new',
  2: 'source-in-process',
  3: 'source-completed',
  4: 'source-completed',
  5: 'source-completed',
};

const b = bem('campaign-card', {cssModule});

class CampaignCard extends Component {
  static propTypes = {
    campaign: PropTypes.object,
    title: PropTypes.oneOfType([TranslationJsx, PropTypes.element]),
    description: TranslationJsx,
    comment: TranslationJsx,
    collapsible: PropTypes.bool,
    totalValues: PropTypes.shape({
      visits: PropTypes.number,
      participations: PropTypes.number,
    }),
    periodText: TranslationJsx,
  };

  static defaultProps = {
    periodText: 'Period',
  };

  state = {
    isCollapsed: false,
  };

  setCollapse = () =>
    this.setState((state) => ({
      isCollapsed: !state.isCollapsed,
    }));

  calculatePercent = (amount, total) => (total && amount ? Math.round((amount / total) * 100) : 0);

  calculateConversion = (visits, participations) => {
    if (visits) {
      return this.calculatePercent(participations, visits);
    }
    return null;
  };

  renderSubItems = (isCollapsed, records, totalValues, description, comment = '') => {
    if (!isCollapsed || !records.length) {
      return null;
    }
    return records.map((elem) => (
      <div className={b('sources-list')} key={elem.id || elem.name}>
        <CampaignCard
          title={
            <CampaignCardHeader
              className={b('card-header')}
              name={elem.name}
              start={elem.from}
              end={elem.to}
              description={elem.description || description}
              comment={elem.comment || comment}
              status={statusesMap[elem.current_status]}
              source_type={elem.source_type || ''}
              periodText=""
              isSource
            />
          }
          campaign={elem}
          totalValues={totalValues}
          collapsible={false}
        />
      </div>
    ));
  };

  render() {
    const {title, campaign, collapsible, totalValues, description} = this.props;
    const {isCollapsed} = this.state;

    return (
      <div className={b({'interface-block': collapsible, 'source-item': !collapsible})}>
        <div className={b('main', {summary: collapsible})}>
          <div className={b('title')}>
            {collapsible && campaign.records && campaign.records.length > 0 && (
              <div className={b('collapse-switcher')} onClick={this.setCollapse}>
                <Icon name={`arrow-${isCollapsed ? 'up' : 'down'}`} className={b('item-icon')} />
              </div>
            )}
            {!collapsible && !!campaign.status && (
              <Icon className={b('status-icon')} name={statusesMap[campaign.status]} />
            )}
            <div className={b('title-text')}>{title}</div>
          </div>
          <div className={b('progress-bar', ['first'])}>
            <BarChart
              number={campaign.visits_count || 0}
              percent={this.calculatePercent(campaign.visits_count, totalValues.visits)}
              color="visits"
              orientation="vertical"
              showPercent={Boolean(campaign.visits_count)}
              showEmptyPercent
              showBrackets={false}
              textColor="text-normal"
              titleWidth={177}
              titleFontWeight={700}
              barColor="visits"
              className={b('bar-chart', {vertical: collapsible, source: !collapsible})}
              classNames={{
                total: collapsible ? b('bar-chart-total') : '',
                stripe: b('bar-chart-stripe', {summary: collapsible, main: !collapsible}),
                number: b('bar-chart-number', {summary: collapsible, main: !collapsible}),
              }}
            />
          </div>
          <PieChart
            className={b('donut-chart')}
            value={this.calculateConversion(campaign.visits_count, campaign.participations_count)}
          />
          <div className={b('progress-bar', ['second'])}>
            <BarChart
              number={campaign.participations_count || 0}
              percent={this.calculatePercent(campaign.participations_count, totalValues.participations)}
              color="participants"
              orientation="vertical"
              showPercent={Boolean(campaign.visits_count)}
              showEmptyPercent
              showBrackets={false}
              textColor="text-normal"
              titleWidth={177}
              titleFontWeight={700}
              barColor="participants"
              className={b('bar-chart', {vertical: collapsible, source: !collapsible})}
              classNames={{
                total: collapsible ? b('bar-chart-total') : '',
                stripe: b('bar-chart-stripe', {summary: collapsible, main: !collapsible}),
                number: b('bar-chart-number', {summary: collapsible, main: !collapsible}),
              }}
            />
          </div>
        </div>
        {this.renderSubItems(isCollapsed, campaign.records, totalValues, description)}
      </div>
    );
  }
}

export default CampaignCard;
