import React from 'react';

import PropTypes from 'prop-types';

import InstoreDonutChart from 'client/components/instore/charts/instore-donut-chart';
import {getColor} from 'client/components/instore/charts/instore-donut-chart/helpers';

const InstoreWeekDonutChart = (props) => {
  const {data} = props;

  return (
    <InstoreDonutChart
      data={data.map(({answer, value}, index) => ({
        label: answer,
        value: value,
        color: getColor(index),
      }))}
      width={138}
      height={138}
    />
  );
};

InstoreWeekDonutChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
};

export default InstoreWeekDonutChart;
