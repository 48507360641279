import React, {PureComponent} from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import {connect} from 'react-redux';

function DateCell({data, rowIndex, field}) {
  return (
    <Cell>
      <div className="bold">
        <Moment format="DD/MM/YYYY">{data[rowIndex][field]}</Moment>
      </div>
      <Moment format="hh:mm:ss">{data[rowIndex][field]}</Moment>
    </Cell>
  );
}

DateCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

function DescrCell({data, rowIndex, field}) {
  return <Cell>{data[rowIndex][field]}</Cell>;
}

DescrCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

class AtsErrorsCardTable extends PureComponent {
  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK_SCENARIO.ERRORS_CARD_TABLE;
  }

  render() {
    return (
      <div className="fixed-table fixed-table--scenarios-errors-card fixed-table--expand-1">
        <Table
          rowsCount={this.props.data.length}
          headerHeight={40}
          rowHeight={55}
          width={230}
          height={314}
          {...this.props}
        >
          <Column
            header={<Cell>{this.LANGUAGE.DATE_COLUMN}</Cell>}
            cell={<DateCell data={this.props.data} field="created_at" />}
            width={110}
          />
          <Column
            header={<Cell>{this.LANGUAGE.DESCRIPTION_COLUMN}</Cell>}
            cell={<DescrCell data={this.props.data} field="kind" />}
            width={230}
          />
        </Table>
      </div>
    );
  }
}

AtsErrorsCardTable.defaultProps = {
  data: [],
};

AtsErrorsCardTable.propTypes = {
  languageState: PropTypes.object.isRequired,
  data: PropTypes.array,
};

export default connect(({languageState}) => ({
  languageState,
}))(AtsErrorsCardTable);
