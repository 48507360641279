import {validNameExt} from 'client/components/prizes/helpers';

export default (values, {lang, isCommon, defaultLanguage}) => {
  const errors = {};

  if (isCommon) {
    const key = `win_pop_up_image_${defaultLanguage}`;
    if (values[key] && !validNameExt(values[key]?.name)) {
      errors[key] = lang.INCORRECT_IMAGE_FORMAT;
    }
  } else {
    for (let key in values) {
      if (key.startsWith('win_pop_up_image_')) {
        if (values[key] && !validNameExt(values[key]?.name)) {
          errors[key] = lang.INCORRECT_IMAGE_FORMAT;
        }
      }
    }
  }
  return errors;
};
