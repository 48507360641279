import moment from 'moment';

import {DATE_FORMAT} from './constants';

const isAfter = (first, second) => {
  return second && moment(first, DATE_FORMAT).isSameOrAfter(moment(second, DATE_FORMAT));
};

export const validateDate = (dates, type) => {
  const value = dates[`value_${type}`];

  if (!value?.trim()) {
    return 'REQUIRED';
  }

  if (!moment(value, DATE_FORMAT, true).isValid()) {
    return 'DATE_FORMAT';
  }

  if (type === 'from' && isAfter(value, dates.value_to)) {
    return 'BEFORE_TO';
  }

  if (type === 'to' && isAfter(dates.value_from, value)) {
    return 'AFTER_FROM';
  }

  return null;
};
