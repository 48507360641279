import React, {Fragment, useRef, memo, useEffect} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';

import {isNewRegionId} from 'client/components/client-agency/components/cards/regions-card/helpers';

import StorePopover from '../components/popovers/store-popover';
import UserPopover from '../components/popovers/user-popover';

import './region.scss';

const b = bem('region');

const Region = (props) => {
  const {region, isEditMode, updateRegions} = props;
  const {id, name, places = [], region_accesses = []} = region;

  const inputRef = useRef(null);

  useEffect(() => {
    // update the input value when the server data is refreshed
    const inputElement = inputRef.current;
    inputElement.value = name;
  }, [name]);

  const handleBlur = () => {
    const inputElement = inputRef.current;
    const trimmedValue = inputElement.value.trim();

    if (trimmedValue !== name) {
      updateRegions({id, name: trimmedValue});
    }

    // trim the input value to make sure server and local data are identical
    inputElement.value = trimmedValue;
  };

  const handleDeleteClick = () => {
    // api needs both id and name to delete a region
    updateRegions({id, name, destroy: true});
  };

  const hasRelatedData = places.length > 0 || region_accesses.length > 0;
  const iconName = hasRelatedData ? 'lock' : 'trash';

  // locally generated ids of regions being added should not be visible
  const normalizedId = isNewRegionId(id) ? null : id;

  return (
    <div className={b()}>
      <div className={b('id')}>{normalizedId}</div>

      {/* uncontrolled input is used as a value is accessed on blur only */}
      <input
        type="text"
        className={cn('input__field', b('input'))}
        disabled={!isEditMode}
        ref={inputRef}
        defaultValue={name}
        onBlur={handleBlur}
      />

      {isEditMode && (
        <button type="button" className={b('icon-button')} disabled={hasRelatedData} onClick={handleDeleteClick}>
          <Icon name={iconName} className={b('icon')} width={17} height={19} />
        </button>
      )}
      {!isEditMode && (
        <>
          <div className={b('stores')}>
            <StorePopover id={id} name={name} stores={places} />
          </div>
          <div className={b('users')}>
            <UserPopover users={region_accesses} />
          </div>
        </>
      )}
    </div>
  );
};

Region.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  updateRegions: PropTypes.func,
  region: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
    places: PropTypes.array,
    region_accesses: PropTypes.array,
  }).isRequired,
};

export default memo(Region);
