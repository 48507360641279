import {useCallback, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useMount} from 'react-use';
import {
  change as changeForm,
  getFormSyncErrors,
  getFormSyncWarnings,
  getFormValues,
  initialize as initializeForm,
  isInvalid,
  isValid,
  reduxForm as reduxFormNative,
  registerField as registerFieldForm,
  reset as resetForm,
  submit as submitForm,
  unregisterField as unregisterFieldForm,
  hasSubmitFailed,
  getFormAsyncErrors,
  isAsyncValidating as isAsyncValidatingForm,
  updateSyncWarnings,
  updateSyncErrors,
} from 'redux-form';
import {ConfigProps} from 'redux-form/lib/reduxForm';

import {FormDecorator, UseReduxFormConfig} from 'client/services/hooks/use-redux-form/types';
import {useReduxFormValidation} from 'client/services/hooks/useReduxFormValidation';

export {reduxForm as reduxFormNative} from 'redux-form';

// useDeepCompareEffect sends warning in console, because of primitive variables.
// this object is not primitive and will be used in dependencies in useDeepCompareEffect
const FOR_PROTECTION = {};

/**
 * reduxForm - typed `reduxForm` from deprecated library `redux-form`
 * @example  of using with `reduxForm`:
 * reduxForm<ComponentProps, FormValueType>({...})(Component)
 */
export const reduxForm = <TProps, TFormData = any>(
  config: Omit<ConfigProps<TFormData, TProps>, 'form'> & {form?: string},
): FormDecorator<TProps, TFormData> => reduxFormNative(config) as unknown as FormDecorator<TProps, TFormData>;

const EMPTY_FORM_VALUES = {};
const useReduxForm = <TFormValues extends Record<string, any>>(
  formName: string,
  config?: UseReduxFormConfig<TFormValues>,
) => {
  const {validate, validateAsync, validateDeps, initialValues} = config || {};

  const [initialized, setInitialized] = useState(!initialValues);

  const formValues = useSelector(getFormValues(formName)) as TFormValues;
  const errorsSync: Record<string, any> = useSelector(getFormSyncErrors(formName));
  const errorsAsync: Record<string, any> = useSelector(getFormAsyncErrors(formName));
  const isAsyncValidating: boolean = useSelector(isAsyncValidatingForm(formName));
  const valid: boolean = useSelector(isValid(formName));
  const invalid: boolean = useSelector(isInvalid(formName));
  const warnings: Record<string, any> = useSelector(getFormSyncWarnings(formName));
  const submitFailed = useSelector(hasSubmitFailed(formName));

  const dispatch = useDispatch();

  const change = useCallback((name, value) => dispatch(changeForm(formName, name, value)), [dispatch, formName]);

  const reset = useCallback(() => dispatch(resetForm(formName)), [dispatch, formName]);

  const submit = useCallback(() => dispatch(submitForm(formName)), [dispatch, formName]);

  const initialize = useCallback((values) => dispatch(initializeForm(formName, values)), [dispatch, formName]);

  const registerField = useCallback(
    (name, type = 'Field') => dispatch(registerFieldForm(formName, name, type)),
    [dispatch, formName],
  );
  const unregisterField = useCallback((name) => dispatch(unregisterFieldForm(formName, name)), [dispatch, formName]);

  const resultFormValue = formValues || initialValues || EMPTY_FORM_VALUES; // `|| initialValues` - isn't a good solution because redux-form is deprecated

  const setErrors = useCallback(
    (errors) => {
      dispatch(updateSyncErrors(formName, errors, ''));
    },
    [formName, dispatch],
  );

  const setWarnings = useCallback(
    (syncWarnings: Record<string, any> = {}) => {
      dispatch(updateSyncWarnings(formName, syncWarnings, ''));
    },
    [dispatch, formName],
  );

  useMount(() => {
    if (initialValues) {
      initialize(initialValues);
      setInitialized(!!initialValues);
    }
  });

  useReduxFormValidation(
    {
      formName,
      validate: validate && initialized ? () => validate(resultFormValue) : null,
      validateAsync: validateAsync && initialized ? () => validateAsync(resultFormValue) : null,
    },
    validate || validateAsync
      ? [resultFormValue, initialized, FOR_PROTECTION, ...(validateDeps || [])]
      : [FOR_PROTECTION],
  );

  return {
    formValues: resultFormValue,
    errors: errorsSync,
    errorsAsync: errorsAsync?.errors || {},
    valid,
    invalid,
    change,
    reset,
    initialize,
    submit,
    registerField,
    unregisterField,
    warnings,
    submitFailed,
    setErrors,
    isAsyncValidating,
    setWarnings,
  };
};

export default useReduxForm;
