import React, {PureComponent} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import {greaterThan, lessThan, number, required} from 'client/services/validator';

import fieldTemplate from 'client/components/common/field';

const PrizeLevelMaxVal = Number.MAX_SAFE_INTEGER;

class TestDrawForm extends PureComponent {
  constructor(props) {
    super(props);

    this.rules = {
      prizeLevel: [
        required(this.props.languageState.errors.REQUIRED),
        greaterThan(0, `${this.props.languageState.errors.GREATER_THAN} 0`),
        number(this.props.languageState.errors.INTEGER),
        lessThan(PrizeLevelMaxVal, `${this.props.languageState.errors.LESS_THAN} ${PrizeLevelMaxVal}`),
      ],
      prizeName: [required(this.props.languageState.errors.REQUIRED)],
    };
  }

  prizeLevelChangeHandler = (e) => {
    const {value} = e.target;
    const {change, id, formValues} = this.props;
    const {name, PrizeName, PrizeLevel} = formValues[`_${id}`];

    if (value && !/^-?(\d+)?(\.)?(\d+)?$/.test(value)) {
      e.preventDefault();
      change(`_${id}`, {
        name,
        PrizeName,
        PrizeLevel,
      });
    }
  };

  radioChangeHandler = (e) => {
    const {change, id, untouch} = this.props;
    const {value} = e.target;

    change(`_${id}`, {
      name: value,
    });
    untouch(`_${id}.PrizeLevel`, `_${id}.PrizeName`);
  };

  render() {
    const {id, formValues, languageState: LANGUAGE} = this.props;
    const name = get(formValues, `_${id}.name`, '');
    const showOverlay = name === 'loss';

    return (
      <div>
        <div className="test-step__form-block">
          <Field
            label={`${LANGUAGE.IMITATE_LABEL} - ${LANGUAGE.NOT_WON_LABEL}`}
            name={`_${id}.name`}
            type="radio"
            value="loss"
            onChange={this.radioChangeHandler}
            component={fieldTemplate}
          />
        </div>
        <div className="test-step__form-block">
          <Field
            label={`${LANGUAGE.IMITATE_LABEL} - ${LANGUAGE.WON_LABEL}`}
            name={`_${id}.name`}
            type="radio"
            value="win"
            component={fieldTemplate}
          />
          <div className="test-step__form-subblocks">
            {showOverlay && <div className="test-step__form-subblocks--overlay" />}
            <div className="test-step__form-block">
              <div className="test-step__form-subblock-desc test-step__form-subblock-desc--view-1">
                <div className="test-step__form-row">
                  <div className="test-step__form-field-wrap test-step__form-field-wrap--flex">
                    <div className="test-step__form-field test-step__form-field--size-1">
                      <Field
                        cssModifier="input--no-min-height"
                        label={LANGUAGE.PRIZE_LEVEL_LABEL}
                        name={`_${id}.PrizeLevel`}
                        type="text"
                        searchable={false}
                        onChange={this.prizeLevelChangeHandler}
                        validate={this.rules.prizeLevel}
                        component={fieldTemplate}
                      />
                    </div>
                  </div>
                </div>
                <div className="test-step__form-row">
                  <div className="test-step__form-field-wrap test-step__form-field-wrap--flex">
                    <div className="test-step__form-field test-step__form-field--size-1">
                      <Field
                        label={LANGUAGE.PRIZE_NAME_LABEL}
                        cssModifier="input--no-min-height"
                        name={`_${id}.PrizeName`}
                        type="text"
                        validate={this.rules.prizeName}
                        component={fieldTemplate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TestDrawForm.propTypes = {
  change: PropTypes.func,
  id: PropTypes.number,
  formValues: PropTypes.object,
  untouch: PropTypes.func,
  languageState: PropTypes.object.isRequired,
};

export default TestDrawForm;
