import React from 'react';

import {useDispatch} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {unlinkClientUser, deleteClientUser} from 'client/ducks/client-users/actions';

import ConfirmationModal from 'client/common/modals/confirmation-modal';

import {User} from 'client/models/users/types';
import {ApiDispatch} from 'client/types';

type CurrentUserData = {
  index: number | null;
  user: User | null;
  soft: boolean;
};

type ClientUserDeactivationWizardProps = {
  currentUser: CurrentUserData;
  clientId: number;
  allUsers: User[];
  changeCurrentUser: (data: CurrentUserData) => void;
  fetchUsers: () => void;
};

const ClientUserDeactivationWizard: React.FC<ClientUserDeactivationWizardProps> = (props) => {
  const {
    currentUser: {index, user, soft},
    clientId,
    allUsers,
    changeCurrentUser,
    fetchUsers,
  } = props;
  const dispatch: ApiDispatch = useDispatch();
  const lang = useLanguage('CLIENT_AGENCY.WIZARDS.CLIENT_USER_DEACTIVATION_WIZARD');

  const showDeactivationModal = index !== null && user && ((user.memberships?.length || 0) > 1 || soft);
  const showDeletingModal = index !== null && user && user.memberships?.length === 1 && !soft;

  const handleNextUserDeactivation = () => {
    const nextIndex = (index || 0) + 1;
    const nextUser = allUsers[nextIndex];

    changeCurrentUser({
      index: nextUser ? nextIndex : null,
      user: nextUser || null,
      soft: false,
    });

    if (!nextUser) {
      fetchUsers();
    }
  };

  const switchToSoftDeactivation = () => changeCurrentUser({index, user, soft: true});

  const deactivateUserWithExports = async () => {
    await dispatch(unlinkClientUser(user?.id, clientId, true));
    handleNextUserDeactivation();
  };

  const deactivateUserWithoutExports = async () => {
    await dispatch(unlinkClientUser(user?.id, clientId, false));
    handleNextUserDeactivation();
  };

  const deleteUser = async () => {
    await dispatch(deleteClientUser(user?.id));
    handleNextUserDeactivation();
  };

  const commonProps = {
    show: true,
    buttonConfirmClass: 'button--bg-3',
    buttonCancelClass: 'button--bg-3',
    onClose: handleNextUserDeactivation,
    message: (
      <>
        <strong>{user?.full_name}</strong>
        <br />
        <span>{showDeletingModal ? lang.DELETE_USER_MESSAGE : lang.DEACTIVATE_USER_MESSAGE}</span>
      </>
    ),
  };

  if (showDeactivationModal) {
    return (
      <ConfirmationModal
        {...commonProps}
        cancelText={lang.DELETE_EXPORTS_LABEL}
        onCancel={deactivateUserWithExports}
        confirmText={lang.KEEP_EXPORTS_LABEL}
        onConfirm={deactivateUserWithoutExports}
      />
    );
  }

  if (showDeletingModal) {
    return (
      <ConfirmationModal
        {...commonProps}
        cancelText={lang.DELETE_USER_LABEL}
        onCancel={deleteUser}
        confirmText={lang.DEACTIVATE_ACCOUNT_LABEL}
        onConfirm={switchToSoftDeactivation}
      />
    );
  }

  return null;
};

export default ClientUserDeactivationWizard;
