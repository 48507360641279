import React, {useState, ReactElement} from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import AccordionSummary from './accordion-summary';

import './accordion.scss';

type AccordionProps = {
  title: string;
  iconName?: string;
  tooltips?: ReactElement;
  opened?: boolean;
  destroy?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  classNames?: {
    title?: string;
    summary?: string;
  };
};

const b = bem('accordion');

const Accordion: React.FC<AccordionProps> = (props) => {
  const {
    destroy = true,
    className,
    title,
    iconName = '',
    tooltips,
    opened = false,
    disabled = false,
    children,
    classNames,
  } = props;
  const [isOpened, setIsOpened] = useState<boolean>(opened);

  const handleClick = (event: React.ChangeEvent<HTMLDetailsElement>) => {
    setIsOpened(event.target.open);
  };

  return (
    <details className={cn(b({disabled}), className)} onToggle={handleClick} open={opened}>
      <AccordionSummary title={title} iconName={iconName} tooltips={tooltips} className={classNames?.title} />
      {(isOpened || !destroy) && <div className={b('content', {opened: isOpened})}>{children}</div>}
    </details>
  );
};

export default Accordion;
