import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect, withRouter} from 'react-router-dom';

import {selectScenarioData} from 'client/ducks/scenario/selectors';
import {selectIsAdmin, selectIsUserLoaded} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import AutotaskScenario from 'client/components/autotask-scenario/autotask-scenario';

class AutotaskScenarioPage extends Component {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    ...withRouter.propTypes,
    scenario: PropTypes.object.isRequired,
    languageState: PropTypes.object.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  componentDidUpdate(prevProps) {
    const {setMetaAction, scenario, languageState} = this.props;
    const title = languageState.payload.AUTOTASK_SCENARIO.TITLE_SCENARIO;

    if (prevProps.scenario !== scenario) {
      setMetaAction({
        title: `${title}: ${scenario.name}`,
      });
    }
  }

  render() {
    const {
      match: {
        params: {id},
      },
      history,
      isUserLoaded,
      isAdmin,
    } = this.props;

    if (!isUserLoaded) {
      return null;
    }

    if (isUserLoaded && !isAdmin) {
      return <Redirect to={CLIENT_PAGES.HOME} />;
    }

    return <AutotaskScenario id={+id} history={history} />;
  }
}

const mapStateToProps = (state) => ({
  scenario: selectScenarioData(state),
  languageState: state.languageState,
  isAdmin: selectIsAdmin(state),
  isUserLoaded: selectIsUserLoaded(state),
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutotaskScenarioPage);
