import moment from 'moment';

export function getLastPrivacyPolicy(state) {
  const policies = state.privacyPolicy.privacy_policies;
  return policies.length > 0 ? policies[0] : null;
}

export function getPrivacyPolicyUserInfo(state) {
  const user = state.userState.payload.user;
  return {
    privacy_policy_accepted: user.privacy_policy_accepted,
    privacy_policy_accepted_at: user.privacy_policy_accepted_at,
  };
}

export function getMyPrivacyPolicy(state) {
  const acceptedDate = state.userState.payload.user.privacy_policy_accepted_at;
  const policies = state.privacyPolicy.privacy_policies;
  return policies.find((policy) => {
    return moment(acceptedDate).isSameOrAfter(policy.created_at);
  });
}
