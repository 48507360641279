import types from './types';

const initialState = {
  taskIndexItems: [],
  meta: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_TASK_INDEX_SUCCESS:
      return {
        ...state,
        taskIndexItems: [...action.payload.task_index_items],
        meta: {...state.meta, ...action.payload.meta},
      };
    default:
      return state;
  }
}
