import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import types from './types';

export const getWinningsRegions = (queryParams = {}) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.REGIONS}`,
        method: 'GET',
        types: [
          types.GET_WINNINGS_REGIONS_REQUEST,
          types.GET_WINNINGS_REGIONS_SUCCESS,
          types.GET_WINNINGS_REGIONS_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
};

export const getWinningsStores = (queryParams = {}) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.PLACES}`,
        method: 'GET',
        types: [types.GET_WINNINGS_STORES_REQUEST, types.GET_WINNINGS_STORES_SUCCESS, types.GET_WINNINGS_STORES_ERROR],
        queryParams,
      },
      true,
    );
  };
};

export const getWinningsDevices = (queryParams = {}) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.OFFLINE_INTERACTIONS}`,
        method: 'GET',
        types: [
          types.GET_WINNINGS_DEVICES_REQUEST,
          types.GET_WINNINGS_DEVICES_SUCCESS,
          types.GET_WINNINGS_DEVICES_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
};

export const getWinningsCampaigns = (queryParams = {}) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.CAMPAIGNS}`,
        method: 'GET',
        types: [
          types.GET_WINNINGS_CAMPAIGNS_REQUEST,
          types.GET_WINNINGS_CAMPAIGNS_SUCCESS,
          types.GET_WINNINGS_CAMPAIGNS_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
};

export const getWinnings = ({autotaskId, queryParams = {}}) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.AUTOMATION_TASKS}/${autotaskId}${API_METHODS.WINNING_PRIZES}`,
        method: 'GET',
        types: [types.GET_WINNINGS_REQUEST, types.GET_WINNINGS_SUCCESS, types.GET_WINNINGS_ERROR],
        queryParams,
      },
      true,
    );
  };
};

export const searchWinner = (autotaskId, queryParams = {}) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.AUTOMATION_TASKS}/${autotaskId}${API_METHODS.SEARCH_WINNER}`,
        method: 'GET',
        types: [types.SEARCH_WINNER_REQUEST, types.SEARCH_WINNER_SUCCESS, types.SEARCH_WINNER_ERROR],
        queryParams,
      },
      true,
    );
  };
};
