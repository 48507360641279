import React from 'react';

export class TranslationElement extends Array<React.FunctionComponentElement<any>> {
  toString(): string {
    return String(this[0]?.props?.content ?? '');
  }

  toLowerCase() {
    return this.toString().toLowerCase();
  }

  toUpperCase() {
    return this.toString().toUpperCase();
  }

  trim() {
    return this.toString().trim();
  }

  replace(searchValue: string | RegExp, replaceValue: string) {
    return this.toString().replace(searchValue, replaceValue);
  }

  split(separator: string | RegExp, limit?: number) {
    return this.toString().split(separator, limit);
  }
}
