import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function clearCampaignsState() {
  return {
    type: types.CLEAR_CAMPAIGNS_STATE,
  };
}

export function fetchCampaigns(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CAMPAIGNS}`,
      method: 'GET',
      headers: {
        'cache-control': 'no-cache',
      },
      types: [types.FETCH_CAMPAIGNS_REQUEST, types.FETCH_CAMPAIGNS_SUCCESS, types.FETCH_CAMPAIGNS_ERROR],
      queryParams,
    });
}

export function getCampaign(id, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CAMPAIGNS}/${id}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_CAMPAIGN_REQUEST, types.GET_CAMPAIGN_SUCCESS, types.GET_CAMPAIGN_ERROR],
      queryParams,
    });
}

export function patchCampaign(id, body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CAMPAIGNS}/${id}`,
      method: 'PATCH',
      noCache: true,
      types: [types.PATCH_CAMPAIGN_REQUEST, types.PATCH_CAMPAIGN_SUCCESS, types.PATCH_CAMPAIGN_ERROR],
      body,
    });
}

export function deleteCampaign(id) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CAMPAIGNS}/${id}`,
      method: 'DELETE',
      noCache: true,
      types: [types.DELETE_CAMPAIGN_REQUEST, types.DELETE_CAMPAIGN_SUCCESS, types.DELETE_CAMPAIGN_ERROR],
    });
}

export function createCampaign(body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CAMPAIGNS}`,
      method: 'POST',
      types: [types.CREATE_CAMPAIGN_REQUEST, types.CREATE_CAMPAIGN_SUCCESS, types.CREATE_CAMPAIGN_ERROR],
      body,
    });
}

/**
 *
 * @param {number} interfaceId
 * @param {Object} queryParams
 * @return {function(*): Promise<RSAAResultAction<unknown>>}
 */
export function getCampaignsWithChannels(
  interfaceId,
  queryParams = {
    include: {
      campaigns: {
        interactions: null,
        permanent_sources: null,
      },
    },
  },
) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACES}/${interfaceId}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_CAMPAIGNS_WITH_CHANNELS_REQUEST,
        types.GET_CAMPAIGNS_WITH_CHANNELS_SUCCESS,
        types.GET_CAMPAIGNS_WITH_CHANNELS_ERROR,
      ],
      queryParams,
    });
}

export function removeChannel(channelId) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.ONLINE_INTERACTIONS}/${channelId}`,
      method: 'DELETE',
      noCache: true,
      types: [types.REMOVE_CHANNEL_REQUEST, types.REMOVE_CHANNEL_SUCCESS, types.REMOVE_CHANNEL_FAILED],
    });
}

export const getPermanentSources = (queryParams) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PERMANENT_SOURCES}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_PERMANENT_SOURCES_REQUEST,
        types.GET_PERMANENT_SOURCES_SUCCESS,
        types.GET_PERMANENT_SOURCES_FAILED,
      ],
      queryParams,
    });
};
