import React, {Component} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import './view-toggle.scss';

class ViewToggle extends Component {
  static Item({name, className, active, children}) {
    const cssClass = classNames({
      'view-toggle__item': true,
      'view-toggle__item--active': active === name,
    });

    return (
      <div className={`${cssClass} ${className}`} data-view-toggle-name={name}>
        {children}
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      activeItem: props.defaultActive,
    };
  }

  componentDidMount() {
    this.props.onMount(this.state.activeItem);
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultActive !== prevProps.defaultActive) {
      this.setState({
        activeItem: this.props.defaultActive,
      });
    }
  }

  handleClick = (e) => {
    const currentTarget = e.currentTarget;

    let target = e.target;
    let data = null;

    const {onClick} = this.props;

    while (target !== currentTarget) {
      data = target.getAttribute('data-view-toggle-name');

      if (data) {
        this.setState({
          activeItem: data,
        });

        onClick(data);

        return false;
      }

      target = target.parentNode;
    }

    return false;
  };

  render() {
    const {children, className} = this.props;

    const {activeItem} = this.state;

    return (
      <div className={`view-toggle ${className}`} onClick={this.handleClick}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            active: activeItem,
          }),
        )}
      </div>
    );
  }
}

ViewToggle.defaultProps = {
  children: null,
  className: '',
  onClick: () => {},
  onMount: () => {},
  defaultActive: '',
};

ViewToggle.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMount: PropTypes.func,
  defaultActive: PropTypes.string,
};

ViewToggle.Item.defaultProps = {
  active: '',
  children: '',
  className: '',
};

ViewToggle.Item.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default ViewToggle;
