import React from 'react';

import PropTypes from 'prop-types';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {TranslationJsx} from 'client/models/language/types';

import './coupon-info-row.scss';

const CouponInfoRow = (props) => {
  const {label, sectionLabel, children, scrollable} = props;

  if (sectionLabel) {
    return (
      <div className="coupon-info-row">
        <p className="coupon-info-row__section-label">{sectionLabel}</p>
      </div>
    );
  }

  return (
    <div className="coupon-info-row">
      <p className="coupon-info-row__label">{label}</p>
      {scrollable && (
        <div className="coupon-info-row__value-wrap">
          <CustomScrollbars
            scrollbarProps={{
              autoHeightMax: 40,
            }}
          >
            <p className="coupon-info-row__value coupon-info-row__value--scrollable">{children}</p>
          </CustomScrollbars>
        </div>
      )}
      {!scrollable && (
        <p className="coupon-info-row__value coupon-info-row__value--ellipsis" title={children}>
          {children}
        </p>
      )}
    </div>
  );
};

CouponInfoRow.propTypes = {
  label: TranslationJsx,
  sectionLabel: TranslationJsx,
  children: PropTypes.node,
  scrollable: PropTypes.bool,
};

CouponInfoRow.defaultProps = {
  label: null,
  sectionLabel: null,
  children: null,
  scrollable: false,
};

export default CouponInfoRow;
