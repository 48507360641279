import filter from 'lodash/filter';
import {matchPath} from 'react-router-dom';
import {preparePaths} from 'routes/helpers';
import {Route} from 'routes/types';

import MainContainer from 'client/main';

import adminRoutes from './admin';
import authRoutes from './auth';
import clientRoutes from './client';
import commonRoutes from './common';
import winningRoutes from './winning';

export const routeList = [
  {
    component: MainContainer,
    routes: [
      ...authRoutes,
      ...adminRoutes,
      ...clientRoutes,
      ...winningRoutes,

      // order of routes is important. path: "*" should be the last one.
      ...commonRoutes,
    ],
  },
];

export const matchRoutes = (routes: Route[] = [], pathname = '') => {
  return filter(routes, (route) => {
    return matchPath(pathname, route);
  }).map((route: any) => ({route, match: {url: pathname, path: route.path}}));
};

export const routePaths = {
  client: preparePaths(clientRoutes),
  common: preparePaths(commonRoutes),
  auth: preparePaths(authRoutes),
  admin: preparePaths(adminRoutes),
  winning: preparePaths(winningRoutes),
};
