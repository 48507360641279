import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Modal from 'client/common/modals/modal';

import ClientDevicesFiltersForm from '../client-devices-list-filters-form';

import './client-devices-list-filter-modal.scss';

const b = bem('client-devices-list-filter-modal');

type ClientDevicesListFilterModalProps = {
  onClose: () => void;
  show: boolean;
};

const ClientDevicesListFilterModal: React.FC<ClientDevicesListFilterModalProps> = (props) => {
  const {onClose, show} = props;

  const lang = useLanguage('CLIENT_DEVICES.FILTER_MODAL');

  return (
    <Modal title={lang.TITLE} onClose={onClose} className={b()} show={show}>
      <ClientDevicesFiltersForm onClose={onClose} />
    </Modal>
  );
};
export default ClientDevicesListFilterModal;
