import React, {useCallback} from 'react';

import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {updateParicipationField, updateParticipation} from 'client/ducks/participations/actions';

import {useToast} from 'client/common/hooks/useToast';

import Field from 'client/components/common/field';

const TestModeSwitcherCell = ({value, participation, path, disabled}) => {
  const dispatch = useDispatch();
  const {appendToastNotification} = useToast();
  const autotask = useSelector(selectAutotask);

  const isOpArchived = autotask?.operation?.status === 'finished';

  const updateStatus = useCallback(async () => {
    const next = !value;
    const response = await dispatch(updateParticipation(participation?.id, {test_mode: next}));
    if (response.error) {
      appendToastNotification({type: 'error', description: response?.payload?.response?.errors?.base?.[0]});
    } else {
      dispatch(updateParicipationField(participation?.id, `visibility_items.${path}`, next));
    }
  }, [dispatch, participation?.id, path, value, appendToastNotification]);

  return (
    <Field
      cssModifier="theme-color-3 content-panel__switcher"
      name="active"
      type="switcher"
      disabled={disabled || isOpArchived}
      input={{
        checked: value,
        onChange: updateStatus,
      }}
    />
  );
};

TestModeSwitcherCell.propTypes = {
  value: PropTypes.bool.isRequired,
  participation: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default TestModeSwitcherCell;
