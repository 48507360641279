import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getLeadsTransferReports(queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.LEAD_TRANSFER_REPORTS}`,
      method: 'GET',
      types: [
        types.GET_LEAD_TRANSFER_REPORTS_REQUEST,
        types.GET_LEAD_TRANSFER_REPORTS_SUCCESS,
        types.GET_LEAD_TRANSFER_REPORTS_ERROR,
      ],
      queryParams,
    });
  };
}
