import React from 'react';

import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';

import Icon from 'client/components/common/icon';

import cssModule from './card-column.module.scss';

const b = bem('card-column', {cssModule});

function CardColumn(props) {
  const {link} = props;

  return (
    <div className={b()}>
      <Link to={link}>
        <span className="theme-color-16">
          <Icon name="comment" />
        </span>
      </Link>
    </div>
  );
}

CardColumn.propTypes = {
  link: PropTypes.string.isRequired,
};

export default CardColumn;
