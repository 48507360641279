import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons';

import {TranslationJsx} from 'client/models/language/types';

import './diy-operation-modal-footer.scss';

const b = bem('diy-operation-modal-footer');

const DiyOperationModalFooter = (props) => {
  const {onReturn, onProceed, onSkip, onFinish, skipButtonLabel, className, isProceedButtonDisabled, loading} = props;

  const lang = useLanguage('OPERATIONS.DIY_OPERATION_MODAL');

  return (
    <div className={cn(b(), className)}>
      {onReturn && (
        <AppButton
          transparent
          className={b('button')}
          label={lang.BACK_BUTTON}
          iconName="arrow-left"
          onClick={onReturn}
        />
      )}

      <div className={b('proceed-buttons')}>
        {onSkip && skipButtonLabel && (
          <AppButton transparent className={b('button')} label={skipButtonLabel} onClick={onSkip} />
        )}
        {onProceed && (
          <AppButton
            className={b('button')}
            label={lang.OK_BUTTON}
            disabled={isProceedButtonDisabled}
            onClick={onProceed}
          />
        )}

        {onFinish && (
          <AppButton
            className={b('button')}
            label={lang.FINISH_BUTTON}
            onClick={onFinish}
            disabled={isProceedButtonDisabled}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

DiyOperationModalFooter.defaultProps = {
  onFinish: null,
  onReturn: null,
  onSkip: null,
  onProceed: null,
  skipButtonLabel: '',
  className: '',
  isProceedButtonDisabled: false,
  loading: false,
};

DiyOperationModalFooter.propTypes = {
  skipButtonLabel: TranslationJsx,
  onFinish: PropTypes.func,
  onReturn: PropTypes.func,
  onProceed: PropTypes.func,
  onSkip: PropTypes.func,
  className: PropTypes.string,
  isProceedButtonDisabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default DiyOperationModalFooter;
