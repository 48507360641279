import React from 'react';

import PropTypes from 'prop-types';

import FieldWrap from 'client/common/fields/field-wrap';

import {BaseFieldComponentProps} from './types';

const BaseFieldComponent = ({Component, ...props}) => {
  const {
    label,
    input: {value, name, onChange, onBlur, onFocus},
    meta: {touched, error, warning},
    withWrap,
    className,
    alwaysShowError,
    ...otherProps
  } = props;
  const componentProps = {
    name,
    label,
    value,
    onChange,
    onBlur,
    onFocus,
    ...otherProps,
  };

  if ((touched || alwaysShowError) && error) {
    componentProps.errorMessage = error;
  }
  componentProps.warningMessage = warning || componentProps.warningMessage;

  if (withWrap) {
    return (
      <FieldWrap className={className}>
        <Component {...componentProps} />
      </FieldWrap>
    );
  }

  return <Component {...componentProps} className={className} />;
};

BaseFieldComponent.propTypes = {
  ...BaseFieldComponentProps,
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  alwaysShowError: PropTypes.bool,
};

BaseFieldComponent.defaultProps = {
  label: null,
  errorMessage: null,
  withWrap: false,
  alwaysShowError: false,
};

export default BaseFieldComponent;
