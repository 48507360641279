import pick from 'lodash/pick';

import {PARTICIPANTS_COLUMN_MAP} from 'client/ducks/participants-list/constants';

import {ParticipationColumn} from 'client/models/participations/types';

export const getColumnName = (record_type: string, name: string) => {
  const columnName = `${record_type} ${name}`;
  return PARTICIPANTS_COLUMN_MAP[columnName] || columnName;
};

export const prepareBody = (
  prevColumns: ParticipationColumn[],
  newColumns: ParticipationColumn[],
  isAdmin: boolean,
) => {
  const forDelete: {id: number; _destroy: true}[] = [];
  prevColumns.forEach((column) => {
    if (!newColumns.some(({id}) => !!column.id && id === column.id)) {
      forDelete.push({
        id: column.id,
        _destroy: true,
      });
    }
  });
  const forSaving: {
    column_adapter_id?: number;
    automation_task_id?: number;
    id?: number;
    position?: number;
    type?: string;
  }[] = [];

  newColumns.forEach((column, index) => {
    const result = column;
    if (column.position !== index || column.column_adapter_id !== prevColumns[index]?.column_adapter_id) {
      result.position = index;

      forSaving.push(pick(result, 'column_adapter_id', 'automation_task_id', 'id', 'position', isAdmin ? 'type' : ''));
    }
  });

  return {
    forDelete,
    forSaving,
  };
};
