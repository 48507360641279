import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import CountBlock from 'client/common/count-block';

// components
import Icon from 'client/components/common/icon';
import InfoBlock from 'client/components/common/info-block';

import {TranslationJsx} from 'client/models/language/types';

import SocialNetworkAccountsDataGrid from '../../tables/social-network-accounts-card-data-grid';
import SocialNetworkAccountsList from '../../tables/social-network-accounts-list';

class SocialNetworkAccountsCard extends Component {
  static propTypes = {
    data: PropTypes.array,
    clientId: PropTypes.number,
    title: TranslationJsx,
    className: PropTypes.string,
    accountsCount: PropTypes.number,
    withSubsidiaries: PropTypes.bool,
    refresh: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool,
    userState: PropTypes.object.isRequired,
    allowEdit: PropTypes.bool,
    height: PropTypes.number,
    fullHeight: PropTypes.bool,
  };

  static defaultProps = {
    data: [],
    accountsCount: 0,
    withSubsidiaries: false,
    isAdmin: false,
    allowEdit: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
    };
  }

  handleEditClick = () => {
    this.setState({
      editMode: true,
    });
  };

  handleCancelClick = () => {
    this.props.refresh();
    this.setState({
      editMode: false,
    });
  };

  render() {
    const {
      data,
      clientId,
      accountsCount,
      isAdmin,
      withSubsidiaries,
      className,
      lang,
      userState,
      allowEdit,
      height,
      fullHeight,
    } = this.props;
    const {editMode} = this.state;
    const isViewMoveOn = userState.viewMode.on;

    return (
      <InfoBlock colorTheme="social-accounts" className={className}>
        <InfoBlock.Title>{lang.TITLE}</InfoBlock.Title>
        <InfoBlock.Content>
          <InfoBlock.Header className="clearfix">
            <CountBlock itemName={lang.ITEMS_NAME} className="pull-left" num={accountsCount} />
            {accountsCount > 0 && !editMode && allowEdit && (
              <button
                className="button button--circle button--bg-8 pull-right"
                onClick={this.handleEditClick}
                disabled={isViewMoveOn}
              >
                <Icon name="edit" className="button__icon" width={20} height={20} />
              </button>
            )}
          </InfoBlock.Header>
          <InfoBlock.Body>
            {editMode || !accountsCount ? (
              <SocialNetworkAccountsList
                onCancelClick={this.handleCancelClick}
                onSaveClick={this.handleCancelClick}
                onAddClick={this.handleEditClick}
                editMode={editMode}
                data={data}
                allowEdit={allowEdit}
                clientId={clientId}
                withSubsidiaries={withSubsidiaries}
                languageState={lang}
                isAdmin={isAdmin}
                isViewMoveOn={isViewMoveOn}
                fullHeight={fullHeight}
              />
            ) : (
              <SocialNetworkAccountsDataGrid
                data={data}
                withSubsidiaries={withSubsidiaries}
                isAdmin={isAdmin}
                height={height}
              />
            )}
          </InfoBlock.Body>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

const mapStateToProps = ({
  isAdmin,
  languageState: {
    payload: {AGENCY, ACCOUNT_PARAMETERS},
  },
  userState,
}) => ({
  lang: isAdmin ? AGENCY.SOCIAL_NETWORK_ACCOUNTS_CARD : ACCOUNT_PARAMETERS.SOCIAL_NETWORK,
  userState,
});

export default connect(mapStateToProps)(SocialNetworkAccountsCard);
