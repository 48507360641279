import React from 'react';

import PropTypes from 'prop-types';

function ClicksCountColumn(props) {
  const {onClicksCountClick, clicksCount} = props;
  return (
    <div className="link" onClick={onClicksCountClick}>
      {clicksCount}
    </div>
  );
}

ClicksCountColumn.propTypes = {
  onClicksCountClick: PropTypes.func.isRequired,
  clicksCount: PropTypes.number.isRequired,
};

export default ClicksCountColumn;
