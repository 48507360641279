import React from 'react';

import PropTypes from 'prop-types';

import DropdownAny from 'client/components/common/dropdown-any';

import './bc-select-dropdown.scss';

function BCSelectDropdown(props) {
  const {children, value, className, customDropdown} = props;

  return (
    <DropdownAny
      customDropdown={customDropdown}
      fullActive={true}
      className={`${className} dropdown-any--view-1 bc-select-dropdown`}
      value={value}
    >
      <div className="theme-color-16 dropdown-any__dropdown-body-inner">{children}</div>
    </DropdownAny>
  );
}

BCSelectDropdown.propTypes = {
  children: PropTypes.node,
  customDropdown: PropTypes.instanceOf(Element),
  value: PropTypes.node,
  className: PropTypes.string,
};

BCSelectDropdown.defaultProps = {
  value: null,
  children: null,
  customDropdown: null,
  className: '',
};

export default BCSelectDropdown;
