import React, {PureComponent} from 'react';

import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';

import bem from 'client/services/bem';

import {getCoupons} from 'client/ducks/coupon-tokens/actions';
import {selectScenarioData, selectAllScenarioVariables} from 'client/ducks/scenario/selectors';

import {SelectField, TextField, TextareaField} from 'client/common/fields';

import {Field} from 'client/components/common/fields';

import {BARCODE_FORMATS, getVisualOptions, SOGEC_FIELD} from './constants';
import {submitForm, getInitialValues, validate} from './helpers';

import {preventDefault, onSubmitSuccess, onSubmitFail} from '../helpers';

import cssModule from './ats-coupon-form.module.scss';

const b = bem('ats-coupon-form', {cssModule});

class AtsCouponForm extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    scenario: PropTypes.object.isRequired,
    getCoupons: PropTypes.func.isRequired,
    coupons: PropTypes.array,
    variables: PropTypes.array.isRequired,

    //  from state:
    change: PropTypes.func.isRequired,
  };

  static defaultProps = {
    coupons: [],
  };

  handleChangeCoupon = () => {
    this.props.change('visual_type', 'none');
  };

  getCouponOptions = async (search = '') => {
    const {
      automation_task: {operation_id: operationId},
    } = this.props.scenario;

    if (!search || !operationId) {
      return [];
    }

    await this.props.getCoupons({
      q: {
        operation_id_eq: operationId.toString(),
        g: {
          0: {
            internal_name_cont: search,
          },
        },
      },
    });

    return this.props.coupons;
  };

  render() {
    const {lang, formValues} = this.props;
    const {coupon, visual_type} = formValues;
    const type = coupon && coupon.type;

    const variableOptions = this.props.variables.map((scenarioVariable) => ({
      value: scenarioVariable.id,
      label: scenarioVariable.full_name || scenarioVariable.name,
    }));

    return (
      <div className={b()}>
        <form onSubmit={preventDefault} className={b('form')}>
          <TextField name="name" label={lang.STEP_NAME_LABEL} required={true} />

          {/* TODO: change async-dropdown to src/client/common/fields/async-select-field/index.jsx */}
          <Field
            label={lang.COUPON.INTERNAL_NAME_LABEL}
            getOptions={this.getCouponOptions}
            name="coupon"
            type="async-dropdown"
            multi={false}
            labelKey="internal_name"
            valueKey="id"
            cssModifier={b('select')}
            required={true}
          />

          {['WeezioCoupon', 'AppCoupon'].includes(type) && (
            <div className={b('visual')}>
              <SelectField
                label={lang.COUPON.VISUAL_LABEL}
                options={getVisualOptions(lang.COUPON)}
                simpleValue
                name="visual_type"
              />
              {visual_type === 'barcode' && (
                <SelectField
                  label={lang.COUPON.BARCODE_FORMAT_LABEL}
                  options={BARCODE_FORMATS}
                  name="barcode_format"
                  simpleValue
                />
              )}
            </div>
          )}

          {type === 'AppCoupon' && (
            <div className={b('variables')}>
              <div className={b('label')}>{lang.VARIABLE_SOURCE}</div>
              <div className={b('variable-field')}>
                <div className={b('variable-label')}>{lang.COUPON.TOKEN_ID_LABEL}</div>
                <SelectField options={variableOptions} name="token_id_variable_id" simpleValue />
              </div>

              <div className={b('variable-field')}>
                <div className={b('variable-label')}>{lang.COUPON.TOKEN_DATE_LABEL}</div>
                <SelectField options={variableOptions} name="token_date_variable_id" simpleValue />
              </div>
            </div>
          )}

          {type === 'SogecCoupon' && (
            <div className={b('variables')}>
              <div className={b('label')}>{lang.VARIABLE_SOURCE}</div>
              <div className={b('row')}>
                <div className={b('sogec-col')}>
                  {SOGEC_FIELD.slice(0, 4).map(({label, fieldName}) => (
                    <div className={b('variable-field')} key={fieldName}>
                      <div className={b('variable-label', ['sogec'])}>{lang.COUPON[label]}</div>
                      <SelectField options={variableOptions} name={`${fieldName}_variable_id`} simpleValue />
                    </div>
                  ))}
                </div>
                <div className={b('sogec-col')}>
                  {SOGEC_FIELD.slice(4).map(({label, fieldName}) => (
                    <div className={b('variable-field')} key={fieldName}>
                      <div className={b('variable-label', ['sogec'])}>{lang.COUPON[label]}</div>
                      <SelectField options={variableOptions} name={`${fieldName}_variable_id`} simpleValue />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <TextareaField name="comment" label={lang.COMMENT_LABEL} />
        </form>
      </div>
    );
  }
}

export default flow([
  reduxForm({
    touchOnBlur: false,
    validate,
    onSubmit: submitForm,
    onSubmitSuccess,
    onSubmitFail,
  }),
  connect(
    (state, {formId, data}) => {
      return {
        lang: state.languageState.payload.AUTOTASK_SCENARIO.SCENARIO_FORMS,
        form: formId,
        coupons: state.couponTokens.coupons,
        variables: selectAllScenarioVariables(state),
        scenario: selectScenarioData(state),
        formValues: getFormValues(formId)(state) || {},
        initialValues: getInitialValues(data),
      };
    },
    {
      getCoupons,
    },
  ),
])(AtsCouponForm);
