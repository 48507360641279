import React from 'react';

import PropTypes from 'prop-types';

const AnimationUserCell = (props) => {
  const {value} = props;

  const nameParts = value.client_user_full_name?.split(' ') || [];

  return (
    <span>
      {nameParts?.[0]?.[0]}
      {nameParts?.[1]?.[0]}
    </span>
  );
};

AnimationUserCell.propTypes = {
  value: PropTypes.shape({
    client_user_full_name: PropTypes.string,
  }).isRequired,
};

export default AnimationUserCell;
