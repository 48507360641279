import {
  createLeadsExportFile,
  deleteFileLeadExport,
  getLeadsExportFile,
  getLeadsExportReport,
  getLeadsExportReports,
  shareLeadExport,
} from 'client/ducks/leads-export/actions';
import {selectLeadsExportReport, selectLeadsExportReports} from 'client/ducks/leads-export/selectors';
import {
  createParticipationsExportFile,
  deleteFileParticipationExport,
  getParticipationsExportFile,
  getParticipationsExportReport,
  getParticipationsExportReports,
  shareParticipationExport,
} from 'client/ducks/participation-export/actions';
import {
  selectParticipationExportReport,
  selectParticipationExportReports,
} from 'client/ducks/participation-export/selectors';
import {
  createWinnerExportFile,
  deleteFileWinnerExport,
  getWinnerExportReport,
  getWinnerExportReports,
  performWinnerExportFile,
  shareWinnerExport,
} from 'client/ducks/winner-export/actions';
import {selectWinnerExportReport, selectWinnerExportReports} from 'client/ducks/winner-export/selectors';

import LeadsGenerateFileModal from 'client/components/leads/modals/leads-generate-file-modal';

import {DATA_COMPONENTS} from './constants';

import DataLeads from '../data-leads';
import DataParticipations from '../data-participations';
import DataWinners from '../data-winners';
import CommonGenerateFileModal from '../generate-file-modal';
import LeadsFiltersModal from '../leads-filters-modal';
import FiltersModal from '../participations-filters-modal';
import WinnersFiltersModal from '../winners-filters-modal';

const LEADS_OPTIONS = {
  component: DataLeads,
  modal: LeadsFiltersModal,
  modalGenerateFile: CommonGenerateFileModal,
  selector: selectLeadsExportReports,
  actionGetReports: getLeadsExportReports,
  actionGetReport: getLeadsExportReport,
  selectReport: selectLeadsExportReport,
  actionShare: shareLeadExport,
  actionGenerate: createLeadsExportFile,
  actionPerform: getLeadsExportFile,
  deleteExportAction: deleteFileLeadExport,
} as const;

export const DATA_COMMON_COMPONENTS = {
  [DATA_COMPONENTS.PARTICIPATIONS]: {
    component: DataParticipations,
    modal: FiltersModal,
    modalGenerateFile: CommonGenerateFileModal,
    selector: selectParticipationExportReports,
    actionGetReports: getParticipationsExportReports,
    actionGetReport: getParticipationsExportReport,
    selectReport: selectParticipationExportReport,
    actionShare: shareParticipationExport,
    actionGenerate: createParticipationsExportFile,
    actionPerform: getParticipationsExportFile,
    deleteExportAction: deleteFileParticipationExport,
  },
  [DATA_COMPONENTS.LEADS]: LEADS_OPTIONS,
  [DATA_COMPONENTS.LEADS_DATABASE]: {
    ...LEADS_OPTIONS,
    modalGenerateFile: LeadsGenerateFileModal,
  },
  [DATA_COMPONENTS.WINNERS]: {
    component: DataWinners,
    modal: WinnersFiltersModal,
    modalGenerateFile: CommonGenerateFileModal,
    selector: selectWinnerExportReports,
    actionGetReports: getWinnerExportReports,
    actionGetReport: getWinnerExportReport,
    selectReport: selectWinnerExportReport,
    actionShare: shareWinnerExport,
    actionGenerate: createWinnerExportFile,
    actionPerform: performWinnerExportFile,
    deleteExportAction: deleteFileWinnerExport,
  },
} as const;
