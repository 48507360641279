import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {addImage, removeImage} from 'client/ducks/images/actions';

import {MAX_IMAGE_SIZE} from 'client/common/config';
import Icon from 'client/common/icon';

import {TranslationJsx} from 'client/models/language/types';

import InfoBlock from '../info-block';

import './image-panel.scss';

class ImageCard extends Component {
  static propTypes = {
    title: TranslationJsx,
    header: PropTypes.node,
    children: PropTypes.node,
  };

  render() {
    const {title, header, children} = this.props;

    return (
      <InfoBlock className="image-card">
        <InfoBlock.Title>{title}</InfoBlock.Title>
        <InfoBlock.Content>
          <InfoBlock.Header>{header}</InfoBlock.Header>
          <InfoBlock.Body className="image-card__body">{children}</InfoBlock.Body>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

class ImagePanel extends Component {
  state = {
    isImageLoaded: false,
    image: null,
    invalidImageSize: false,
  };

  colorClasses = {
    operation: 4,
    automation_task: 4,
  };

  fileInput = null;

  static propTypes = {
    id: PropTypes.number,
    kind: PropTypes.oneOf(['operation', 'automation_task']),
    className: PropTypes.string,
    colorTheme: PropTypes.number,
    imageUrl: PropTypes.string,
    title: TranslationJsx,
    header: PropTypes.node,
    language: PropTypes.object,
    callback: PropTypes.func,
    addImage: PropTypes.func,
    removeImage: PropTypes.func,
  };

  deleteImageHandler = async () => {
    const {callback, id, kind} = this.props;
    this.props.removeImage(id, kind).then(() => {
      if (callback) {
        callback();
      }
      this.setState({
        image: null,
      });
    });
  };

  uploadImageHandler = async (e) => {
    const {callback, id, kind} = this.props;
    const file = e.target.files[0];

    if (file.size < MAX_IMAGE_SIZE) {
      this.props.addImage(id, kind, file).then(() => {
        this.setState({
          image: URL.createObjectURL(file),
        });
        if (callback) {
          callback();
        }
      });
    } else {
      this.setState({
        invalidImageSize: true,
      });
    }
  };

  renderImagePanelHeader = () => {
    const {imageUrl} = this.props;
    const colorNumber = this.colorClasses[this.props.kind];
    return (
      <div className="image-card__header-actions">
        {!imageUrl ? (
          <div>
            <input
              ref={(ref) => (this.fileInput = ref)}
              id="image"
              name="image"
              className="input-file__input"
              style={{display: 'none'}}
              type="file"
              accept="image/png, image/jpeg"
              onChange={this.uploadImageHandler}
            />
            <button
              className={`button button--bg-${colorNumber} pull-right image-button`}
              onClick={() => this.fileInput.click()}
            >
              <div>
                <Icon name="plus" className="button__icon" />
                <Icon name="image" className="button__icon" />
              </div>
            </button>
          </div>
        ) : (
          <button className="button button--circle button--bg-operations pull-right" onClick={this.deleteImageHandler}>
            <Icon name="trash" className="button__icon" width={17} height={19} />
          </button>
        )}
      </div>
    );
  };

  renderImagePanelMessage = () => {
    const {language, imageUrl} = this.props;
    if (!imageUrl && !this.state.invalidImageSize) {
      return <span>{language.NO_IMAGE}</span>;
    }
    if (this.state.invalidImageSize) {
      return (
        <div className="theme-color-eye-closed error-container">
          <div className="error-container__header">
            <Icon name="image" className="button__icon" />
            <span>{language.SIZE_TOO_LARGE}</span>
          </div>
          <div className="error-container__message">{language.ERROR_UPLOAD_MESSAGE}</div>
        </div>
      );
    }
    return null;
  };

  render() {
    const {imageUrl, title, header} = this.props;

    return (
      <ImageCard title={title || 'Image'} header={header || this.renderImagePanelHeader()}>
        <div className={cn('image__block', {'image__block-empty': !imageUrl})}>
          {imageUrl ? <img className="image__block_content" src={imageUrl} alt="" /> : this.renderImagePanelMessage()}
        </div>
      </ImageCard>
    );
  }
}

export default connect(null, {
  addImage,
  removeImage,
})(ImagePanel);
