import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getLeadsOnlineUsers} from 'client/ducks/leads-dashboard/actions';
import {selectLeadsOnlineUsers} from 'client/ducks/leads-dashboard/selectors';

import DashboardCard from 'client/components/common/cards/dashboard-card';
import OnlineUsersChart from 'client/components/common/charts-custom/online-users-chart';

class LeadsOnlineUsers extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    empty: PropTypes.bool.isRequired,
    leadsOnlineUsers: PropTypes.object.isRequired,
    getLeadsOnlineUsers: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
  };

  componentDidMount() {
    this.fetchOnlineUsersStatistic();
  }

  componentDidUpdate(prevProps) {
    if (this.props.client.id !== prevProps.client.id) {
      this.fetchOnlineUsersStatistic();
    }
  }

  toggleLoading = () => this.setState(({loading}) => ({loading: !loading}));

  fetchOnlineUsersStatistic = () => {
    this.toggleLoading();
    return this.props
      .getLeadsOnlineUsers({
        client_id: this.props.client.id,
      })
      .then(this.toggleLoading);
  };

  render() {
    const {lang, empty, leadsOnlineUsers} = this.props;
    const {loading} = this.state;

    return (
      <DashboardCard
        title={lang.ONLINE_USERS}
        iconName="leads-online-users-colored"
        empty={empty}
        emptyText={lang.NO_DATA}
        emptyHeight={255}
        loading={loading}
        loadingColor="leads"
      >
        <OnlineUsersChart data={leadsOnlineUsers} />
      </DashboardCard>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.languageState.payload.LEADS_DASHBOARD,
    leadsOnlineUsers: selectLeadsOnlineUsers(state),
  }),
  {
    getLeadsOnlineUsers,
  },
)(LeadsOnlineUsers);
