export default {
  GET_WINNING_TASKS_REQUEST: '@@prize-distribution/GET_WINNING_TASKS_REQUEST',
  GET_WINNING_TASKS_SUCCESS: '@@prize-distribution/GET_WINNING_TASKS_SUCCESS',
  GET_WINNING_TASKS_ERROR: '@@prize-distribution/GET_WINNING_TASKS_ERROR',

  GET_WINNING_TASK_REQUEST: '@@prize-distribution/GET_WINNING_TASK_REQUEST',
  GET_WINNING_TASK_SUCCESS: '@@prize-distribution/GET_WINNING_TASK_SUCCESS',
  GET_WINNING_TASK_ERROR: '@@prize-distribution/GET_WINNING_TASK_ERROR',

  DISTRIBUTE_PRIZE_REQUEST: '@@prize-distribution/DISTRIBUTE_PRIZE_REQUEST',
  DISTRIBUTE_PRIZE_SUCCESS: '@@prize-distribution/DISTRIBUTE_PRIZE_SUCCESS',
  DISTRIBUTE_PRIZE_ERROR: '@@prize-distribution/DISTRIBUTE_PRIZE_ERROR',

  RESEND_PRIZE_REQUEST: '@@prize-distribution/RESEND_PRIZE_REQUEST',
  RESEND_PRIZE_SUCCESS: '@@prize-distribution/RESEND_PRIZE_SUCCESS',
  RESEND_PRIZE_ERROR: '@@prize-distribution/RESEND_PRIZE_ERROR',

  SET_WINNING_HEADER_DATA: '@@prize-distribution/SET_WINNING_HEADER_DATA',

  SEARCH_WINNINGS_REQUEST: '@@prize-distribution/SEARCH_WINNINGS_REQUEST',
  SEARCH_WINNINGS_SUCCESS: '@@prize-distribution/SEARCH_WINNINGS_SUCCESS',
  SEARCH_WINNINGS_ERROR: '@@prize-distribution/SEARCH_WINNINGS_ERROR',
};
