import React, {Component} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getHomeDashboard} from 'client/services/helpers';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getMessageTask, flushMessageTaskAllState} from 'client/ducks/message-email-sms-task/actions';
import {selectTaskBaseInfo} from 'client/ducks/message-email-sms-task/selectors';

import {CLIENT_PAGES, CLIENT_LEVEL_TYPES, OPERATION_STATUS_TYPES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';
import TitleBlock from 'client/components/common/title-block';

import BCResultsTableContainer from './components/bc-results-table-container';

class MessageTaskResults extends Component {
  static propTypes = {
    flushMessageTaskAllState: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    getMessageTask: PropTypes.func.isRequired,
    isNational: PropTypes.bool.isRequired,
    taskId: PropTypes.string.isRequired,
    task: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = {
      ...props.languageState.payload.BROADCASTING_TASK.RESULT,
      BREADCRUMBS: props.languageState.payload.BREADCRUMBS,
    };
  }

  componentDidMount() {
    this.getMessageTask().then(this.setBreadcrumbs);
  }

  componentWillUnmount() {
    this.props.flushMessageTaskAllState();
    this.props.clearBreadcrumbs();
  }

  setBreadcrumbs = () => {
    const {
      isNational,
      languageState: {
        payload: {BREADCRUMBS},
      },
      task: {
        clientName,
        clientId,
        clientType,
        taskType,
        taskId,
        taskName,
        taskDashboards,
        operationId,
        operationName,
        operationStatus,
        operationDashboards,
      },
    } = this.props;

    const basicInfo = {
      taskType,
      clientType,
      operationId,
      clientId,
      taskId,
    };

    const operationHomeDashboard = getHomeDashboard(operationDashboards);
    const taskHomeDashboard = getHomeDashboard(taskDashboards);

    const defaultBreadcrumbs = [
      {
        name: BREADCRUMBS.CLIENTS,
        href: CLIENT_PAGES.CLIENTS_LIST,
      },
      {
        name: clientName,
        href: CustomLink.createClientLink(basicInfo),
      },
      {
        name: operationName,
        href: CustomLink.createOperationLink(basicInfo),
      },
      {
        name: taskName,
        href: CustomLink.createTaskLink(basicInfo),
      },
      {
        name: BREADCRUMBS.RESULT,
      },
    ];

    const nationalBreadcrumbs = [
      {
        name:
          operationStatus !== OPERATION_STATUS_TYPES.ACTIVE
            ? this.LANGUAGE.BREADCRUMBS.FINISHED_OPERATIONS
            : this.LANGUAGE.BREADCRUMBS.ONGOING_OPERATIONS,
        href:
          operationStatus !== OPERATION_STATUS_TYPES.ACTIVE
            ? CLIENT_PAGES.OPERATIONS_FINISHED
            : CLIENT_PAGES.OPERATIONS_ACTIVE,
      },
      {
        name: get(operationHomeDashboard, 'name'),
        href:
          operationHomeDashboard &&
          CustomLink.createDashboardsLink({
            ...basicInfo,
            dashboardType: CLIENT_LEVEL_TYPES.OPERATION,
            dashboardId: operationHomeDashboard.id,
          }),
        hidden: !operationHomeDashboard,
      },
      {
        name: get(taskHomeDashboard, 'name'),
        href:
          taskHomeDashboard &&
          CustomLink.createDashboardsLink({
            ...basicInfo,
            dashboardType: CLIENT_LEVEL_TYPES.MESSAGE_TASK,
            dashboardId: taskHomeDashboard.id,
          }),
        hidden: !taskHomeDashboard,
      },
      {
        name: BREADCRUMBS.RESULT,
      },
    ];

    return this.props.setBreadcrumbs(isNational ? nationalBreadcrumbs : defaultBreadcrumbs);
  };

  getMessageTask = () => {
    const {taskId} = this.props;
    const params = {
      include: {
        client: null,
        operation: ['dashboards'],
        dashboards: null,
      },
    };
    return this.props.getMessageTask(taskId, params);
  };

  render() {
    const {
      task: {taskType, taskState},
      taskId,
    } = this.props;

    return (
      <div className="theme-color-16">
        <div className="page__title-block flex-container flex-justify-between">
          <div>
            <TitleBlock theme={true}>
              <TitleBlock.Item>{this.LANGUAGE.TITLE}</TitleBlock.Item>
            </TitleBlock>
          </div>
        </div>
        <BCResultsTableContainer taskId={taskId} taskType={taskType} taskState={taskState} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  task: selectTaskBaseInfo(state),
});

const mapDispatchToProps = {
  setBreadcrumbs,
  clearBreadcrumbs,
  getMessageTask,
  flushMessageTaskAllState,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageTaskResults);
