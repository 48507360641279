import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons/app-button';
import Icon from 'client/common/icon';

import {ANIMATION_STATUSES} from 'client/components/instore/constants';

import AnimationIconCell from '../animation-icon-cell';

import './animation-actions-cell.scss';

const b = bem('animation-actions-cell');

const AnimationActionsCell = (props) => {
  const {className, item, onEdit} = props;

  return (
    <AnimationIconCell className={cn(b(), className)} testId="animation-actions-cell">
      <AppButton
        className={b('button')}
        label={<Icon name={`action-${item.status === ANIMATION_STATUSES.EDIT ? 'edit' : 'view'}`} />}
        onClick={() => onEdit(item)}
        asWrap
      />
    </AnimationIconCell>
  );
};

AnimationActionsCell.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
};

AnimationActionsCell.defaultProps = {
  className: null,
};

export default AnimationActionsCell;
