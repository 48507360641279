import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import {DIAGRAM_TYPES} from 'client/components/client-autotask/tabs/results-tab/diagrams/constants';
import {roundValue} from 'client/components/client-autotask/tabs/results-tab/diagrams/helpers';
import VerticalBar from 'client/components/client-autotask/tabs/results-tab/diagrams/vertical-bars-diagram/vertical-bar';

import cssModule from './graph.module.scss';

const b = bem('graph', {cssModule});

type GraphProps = {
  data: Record<string, any>[];
  maxValue: number;
  valuesCount: number;
  step: number;
  className?: string;
  color?: string;
  tooltip: string;
  method: 'value' | 'interval';
};

const Graph: React.FC<GraphProps> = (props) => {
  const {data, maxValue, method, color = '#B958C9', className, tooltip, valuesCount} = props;

  return (
    <div className={cn(className, b())}>
      {data.map((col: any) => {
        let percent;
        if (method === DIAGRAM_TYPES.INTERVAL) {
          // Caused WEEZIO-2657
          // percent = (col.value / step / maxValue) * 100 * 0.8;
          percent = (col.value / maxValue) * 100 * 0.8;
        } else {
          percent = (col.value / maxValue) * 100 * 0.8;
        }
        return (
          <div key={col.label} className={b('column')}>
            <VerticalBar
              value={col.value}
              color={color}
              label={col.label}
              percent={percent}
              maxValue={maxValue}
              tooltip={tooltip}
              valuesCount={roundValue((col.value * 100) / valuesCount)}
            />
            <div className={b('column-label-text-wrap')}>
              <div className={b('columns-label-text')}>
                {Array.isArray(col.label) ? (
                  <span className={b('columns-label')}>
                    <span>{col.label[0]}</span>
                    <span>–</span>
                    <span>{col.label[1]}</span>
                  </span>
                ) : (
                  col.label
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Graph;
