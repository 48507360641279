import React from 'react';

import {RenderElementProps} from 'slate-react';

import {TEXT_ALIGN_OPTIONS, TYPE_TO_TAG, TYPOGRAPHY_TYPES} from '../constants';

export type ElementProps = RenderElementProps & {
  element: {
    align: (typeof TEXT_ALIGN_OPTIONS)[number];
    type?: (typeof TYPOGRAPHY_TYPES)[keyof typeof TYPOGRAPHY_TYPES];
  };
};
const Element: React.FC<ElementProps> = (props) => {
  const style = {textAlign: props.element.align};

  const ElementTag = TYPE_TO_TAG[props.element.type || TYPOGRAPHY_TYPES.PARAGRAPH];

  return (
    <ElementTag {...props.attributes} style={style}>
      {props.children}
    </ElementTag>
  );
};

export default Element;
