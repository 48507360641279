import React, {FC, useEffect} from 'react';

import {useHistory} from 'react-router-dom';

import {getOrganizationId} from 'client/services/cookie-data-source';

import HomeClientEmpty from './empty-client';

const HomeClient: FC = () => {
  const history = useHistory();
  const orgId = getOrganizationId();

  useEffect(() => {
    if (orgId && history.location.pathname === '/') {
      history.push(`/clients/${orgId}/operations`);
    }
  }, [orgId, history]);

  return <HomeClientEmpty />;
};

export default HomeClient;
