import React, {useEffect, useCallback, useState} from 'react';

import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';

import {getMediasDashboard} from 'client/ducks/medias/actions';
import {selectMediasDashboard} from 'client/ducks/medias/selectors';

import {formatDuration, getMediaLevel} from 'client/components/medias/helpers';
import MediasConfigModal from 'client/components/medias/modals/medias-config-modal';

import './medias-summary-dashboard.scss';

const b = bem('medias-summary-dashboard');

const MediasSummaryDashboard = (props) => {
  const {clientId} = props;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const lang = useSelector((state) => state.languageState.payload.MEDIAS);
  const mediasDashboard = useSelector(selectMediasDashboard);
  const level = getMediaLevel();

  const fetchData = useCallback(() => {
    dispatch(getMediasDashboard({client_id: clientId}));
  }, [dispatch, clientId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleOpen = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowModal(false);
    fetchData();
  }, [fetchData]);

  return (
    <div className={b()}>
      {showModal && <MediasConfigModal clientId={clientId} onClose={handleClose} level={level} />}
      <p className={b('title')}>{lang.MULTIMEDIA_PLAYER}</p>
      <p className={b('info')}>
        {formatDuration(mediasDashboard.total_duration)} - {mediasDashboard.items_number} {lang.ITEMS}
      </p>
      <button className={b('button')} onClick={handleOpen}>
        {lang.CONFIGURATION}
      </button>
    </div>
  );
};

MediasSummaryDashboard.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default MediasSummaryDashboard;
