import {Langs} from 'client/services/hooks/useLanguage/types';
import {passwordValidator} from 'client/services/validator';

import {ProfilePasswordValues} from './types';
import {ValidationArgs, ValidationResult} from './types';

const validateRequiredField = (
  value: string | undefined,
  field: keyof ProfilePasswordValues,
  lang: Langs<'PROFILE.PROFILE_PASSWORD'>,
  errors: ValidationResult,
): void => {
  if (!value) {
    errors[field] = lang.REQUIRED;
  }
};

const validatePasswordMatch = (
  newPassword: string | undefined,
  confirmPassword: string | undefined,
  lang: Langs<'PROFILE.PROFILE_PASSWORD'>,
  errors: ValidationResult,
): void => {
  if (confirmPassword && newPassword !== confirmPassword) {
    errors.confirmPassword = lang.PASSWORD_MATCH_ERROR;
  }
};

const validateNewPasswordFormat = (
  newPassword: string | undefined,
  lang: Langs<'PROFILE.PROFILE_PASSWORD'>,
  errors: ValidationResult,
): void => {
  if (newPassword && !passwordValidator(newPassword)) {
    errors.newPassword = lang.PASSWORD_FORMAT_ERROR;
  }
};

export default (formValues: ProfilePasswordValues, {lang}: ValidationArgs): ValidationResult => {
  const errors: ValidationResult = {};

  validateRequiredField(formValues?.currentPassword, 'currentPassword', lang, errors);
  validateRequiredField(formValues?.newPassword, 'newPassword', lang, errors);
  validateRequiredField(formValues?.confirmPassword, 'confirmPassword', lang, errors);

  validatePasswordMatch(formValues?.newPassword, formValues?.confirmPassword, lang, errors);
  validateNewPasswordFormat(formValues?.newPassword, lang, errors);

  return errors;
};
