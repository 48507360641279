import React, {Component} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import './title-block.scss';

class TitleBlock extends Component {
  static defaultProps = {
    className: '',
    theme: false,
    small: false,
    separator: false,
    ellipsis: '',
    children: '',
  };

  static propTypes = {
    className: PropTypes.string,
    theme: PropTypes.bool,
    small: PropTypes.bool,
    separator: PropTypes.bool,
    ellipsis: PropTypes.string,
    children: PropTypes.any,
  };

  static Item(props) {
    return (
      <div className={`title-block__item ${props.className}`}>
        <div className="title-block__item-text" title={typeof props.children === 'string' ? props.children : ''}>
          {props.children}
        </div>
      </div>
    );
  }

  static Icon(props) {
    return <span className={`title-block__icon ${props.className}`}>{props.children}</span>;
  }

  render() {
    const {className, theme, small, separator, ellipsis} = this.props;

    const cssClass = classNames({
      'title-block': true,
      'title-block--theme': theme,
      'title-block--small': small,
      'title-block--separator': separator,
      [`title-block--ellipsis title-block--items-width-${ellipsis}`]: !!ellipsis,
    });

    return <div className={`${cssClass} ${className}`}>{this.props.children}</div>;
  }
}

TitleBlock.Item.defaultProps = {
  className: '',
  children: '',
};

TitleBlock.Item.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

TitleBlock.Icon.defaultProps = {
  className: '',
  children: '',
};

TitleBlock.Icon.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default TitleBlock;
