import React, {useMemo} from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import LayoutPanel from 'client/common/panels/layout-panel';

import InstorePlanningDonutChart from 'client/components/instore/charts/instore-planning-donut-chart';
import {ANIMATION_STATUS_LANG_MAP} from 'client/components/instore/constants';
import {AnimationStatistic, AnimationStatisticQuota} from 'client/models/animations/types';

import './instore-statistics-panel.scss';

const b = bem('instore-statistics-panel');

type InstoreStatisticsPanelProps = {
  statistics: AnimationStatistic;
  loading: boolean;
};

const InstoreStatisticsPanel: React.FC<InstoreStatisticsPanelProps> = ({statistics, loading}) => {
  const lang = useLanguage('INSTORE_TASK.PANELS.INSTORE_STATISTICS_PANEL');

  const data = useMemo(() => {
    const keys: (keyof AnimationStatistic)[] = ['brand', 'region', 'area', 'status'];

    return keys.map((key) => {
      if (key === 'status') {
        const stat = statistics[key] as Record<string, number>;
        return {
          status: Object.entries(stat || {}).reduce(
            (acc, [keyStat, value]) => ({
              ...acc,
              [ANIMATION_STATUS_LANG_MAP[keyStat]]: value,
            }),
            {},
          ),
        };
      }

      const stat = statistics[key] as Record<string, AnimationStatisticQuota>;
      return {
        [key === 'area' ? key || lang.NO_AREA : key]: Object.entries(stat || {}).reduce(
          (result, [keyStat, value]) => ({
            ...result,
            [keyStat]: [value.animations_count, value.quota],
          }),
          {},
        ),
      };
    });
  }, [lang.NO_AREA, statistics]);

  if (loading) {
    return null;
  }

  return (
    <div className={b()}>
      <div className={b('group')}>
        {data.slice(0, 2).map((chart, idx) => (
          <LayoutPanel key={idx} className={b('chart')}>
            <InstorePlanningDonutChart data={chart} />
          </LayoutPanel>
        ))}
      </div>
      <div className={b('group')}>
        {data.slice(2).map((chart, idx) => (
          <LayoutPanel key={idx} className={b('chart')}>
            <InstorePlanningDonutChart data={chart} />
          </LayoutPanel>
        ))}
      </div>
    </div>
  );
};

export default InstoreStatisticsPanel;
