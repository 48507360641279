export default {
  CLIENTS_LIST_NO_DATA: '@@clients-list/CLIENTS_LIST_NO_DATA',
  CLIENTS_LIST_LOADING: '@@clients-list/CLIENTS_LIST_LOADING',
  CLIENTS_LIST_LOADED: '@@clients-list/CLIENTS_LIST_LOADED',
  CLIENTS_LIST_LOAD_FAILED: '@@clients-list/CLIENTS_LIST_LOAD_FAILED',

  CLIENTS_LIST_ADDING: '@@clients-list/CLIENTS_LIST_ADDING',
  CLIENTS_LIST_CLIENT_ADDED: '@@clients-list/CLIENTS_LIST_CLIENT_ADDED',
  CLIENTS_LIST_ADD_FAILED: '@@clients-list/CLIENTS_LIST_ADD_FAILED',

  CLIENTS_DELETE_LOADING: '@@clients-list/CLIENTS_DELETE_LOADING',
  CLIENTS_DELETE_SUCCESS: '@@clients-list/CLIENTS_DELETE_SUCCESS',
  CLIENTS_DELETE_FAILED: '@@clients-list/CLIENTS_DELETE_FAILED',

  GET_CLIENT_REQUEST: '@@clients-list/GET_CLIENT_REQUEST',
  GET_CLIENT_SUCCESS: '@@clients-list/GET_CLIENT_SUCCESS',
  GET_CLIENT_ERROR: '@@clients-list/GET_CLIENT_ERROR',

  PATCH_CLIENT_REQUEST: '@@clients-list/PATCH_CLIENT_REQUEST',
  PATCH_CLIENT_SUCCESS: '@@clients-list/PATCH_CLIENT_SUCCESS',
  PATCH_CLIENT_ERROR: '@@clients-list/PATCH_CLIENT_ERROR',

  PATCH_CLIENT_WITH_FORM_DATA_REQUEST: '@@clients-list/PATCH_CLIENT_WITH_FORM_DATA_REQUEST',
  PATCH_CLIENT_WITH_FORM_DATA_SUCCESS: '@@clients-list/PATCH_CLIENT_WITH_FORM_DATA_SUCCESS',
  PATCH_CLIENT_WITH_FORM_DATA_ERROR: '@@clients-list/PATCH_CLIENT_WITH_FORM_DATA_ERROR',

  GET_CLIENTS_REQUEST: '@@clients-list/GET_CLIENTS_REQUEST',
  GET_CLIENTS_SUCCESS: '@@clients-list/GET_CLIENTS_SUCCESS',
  GET_CLIENTS_ERROR: '@@clients-list/GET_CLIENTS_ERROR',

  GET_MY_CLIENTS_REQUEST: '@@clients-list/GET_MY_CLIENTS_REQUEST',
  GET_MY_CLIENTS_SUCCESS: '@@clients-list/GET_MY_CLIENTS_SUCCESS',
  GET_MY_CLIENTS_ERROR: '@@clients-list/GET_MY_CLIENTS_ERROR',
};
