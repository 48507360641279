import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {isEmptyString} from 'client/services/validator';

import {Translation} from 'client/models/language/types';

import {PrizeDiyRatioTimeValues} from './type';

type ValidateProps = (
  values: PrizeDiyRatioTimeValues,
  config: {lang: LangsByPath<['PRIZES.FIELDSETS.ERRORS']>},
) => Record<string, string>;

export default ((values, {lang}, shouldValidate = true) => {
  const errors: Record<string, Translation> = {};

  if (!shouldValidate) {
    return errors;
  }

  if (isEmptyString(values.units_number)) {
    errors.units_number = lang.REQUIRED;
  }

  if (isEmptyString(values.time_unit)) {
    errors.time_unit = lang.REQUIRED;
  }

  return errors;
}) as ValidateProps;
