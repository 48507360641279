import {OPT_IN_TYPES} from './constants';

export function selectOptIns(state) {
  return state.optIns.optIns;
}

export function selectOptInsForMapping(state) {
  return state.optIns.optIns.map((item) => {
    return {
      value: item.opt_in_column_adapter && item.opt_in_column_adapter.id,
      label: item.name,
      type: 'OptInMappingItem',
      opt_in_text: item.opt_in_text,
    };
  });
}

export function selectOptInsCodes(state) {
  const codes = {};
  state.optIns.optIns.forEach((optIn) => {
    codes[optIn.id] = optIn.code;
  });

  return codes;
}

export function selectOptInsFiltersMapped(state) {
  return state.optIns.optIns.map((optIn) => {
    return {
      id: optIn.id,
      name: optIn.name,
      code: optIn.code,
      level: optIn.level,
    };
  });
}

export function selectOptInsForFilterModal(state) {
  return [
    ...state.optIns.optIns
      .filter(({opt_in_type}) => opt_in_type !== OPT_IN_TYPES.EXTERNAL_CLIENT)
      .map(({id, name}) => {
        return {
          value: id,
          label: name,
        };
      }),
  ];
}

export function selectOptInsTakenMandatoryOrders(state, mandatoryOrder) {
  return state.optIns.optIns
    .map((optIn) => optIn.mandatory_order)
    .filter((order) => order !== null && order !== mandatoryOrder);
}
