import React, {Component} from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {mapSelectList} from 'client/services/helpers';

import {getFirstSources} from 'client/ducks/first-sources/actions';
// import {getAutotasks} from 'client/ducks/autotask/actions';
import {getOperations} from 'client/ducks/operations/actions';
import {selectOperationsForFilterModal} from 'client/ducks/operations/selectors';
import {getOptInsAction} from 'client/ducks/opt-in-columns/actions';
import {selectOptInsForFilterModal} from 'client/ducks/opt-in-columns/selectors';

import Modal from 'client/common/modals/modal';

import AsyncDropdownAny from 'client/components/common/async-dropdown-any';
import CustomSelect from 'client/components/common/custom-select';
import Datepicker from 'client/components/common/datepicker';

import {getSourceParams} from 'client/components/leads/leads-list/helper';

import './leads-filters-modal.scss';

const isFilterEmpty = (filter) => {
  return Object.keys(filter).every((item) => !filter[item]);
};

const checkFilterValue = (filters, groupName, groupKey, value) => {
  const checkedValue = groupName ? get(filters, [groupName, groupKey], null) : get(filters, [groupKey], null);
  if (value) {
    return checkedValue === value;
  }
  if (groupName && !isNull(checkedValue)) {
    return checkedValue;
  }
  return !isUndefined(value) ? filters[groupKey] === value : filters[groupKey];
};

const setValue = (value) => {
  return typeof value === 'string' ? value : !value;
};

const convertFilters = ({phone, email, visuals, gender, origin, operation, optIn, ...filters}) => ({
  phone,
  email,
  visuals,
  origin,
  operation,
  optIn,
  participated: {
    not: !filters.participated,
    participated: filters.participated,
    participatedFrom: filters.participatedFrom,
    participatedTo: filters.participatedTo,
  },
  gender,
  created: {
    not: !filters.created,
    created: filters.created,
    createdFrom: filters.createdFrom,
    createdTo: filters.createdTo,
  },
  optInRadio: {
    not: !filters.optInRadio && filters.optInRadio !== 'no_active',
    optInRadio: filters.optInRadio === true,
    no_active: filters.optInRadio === 'no_active',
  },
});

const initSourceFilter = (list) => {
  const sourceList = [];
  list.forEach((el) => {
    const {id, date, time, name, type} = getSourceParams(el.source, true);
    sourceList.push({id, name: String(name).toUpperCase(), custom: `${date} ${time}`, type});
  });
  return sourceList;
};

export class FiltersModal extends Component {
  static propTypes = {
    instantApply: PropTypes.bool,
    language: PropTypes.object,
    filters: PropTypes.object,
    options: PropTypes.shape({
      scenarios: [],
    }),
    totalResults: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
    getFirstSources: PropTypes.func.isRequired,
    getOperations: PropTypes.func.isRequired,
    getOptIns: PropTypes.func.isRequired,
    clientId: PropTypes.string,
    operations: PropTypes.array,
    optIns: PropTypes.array,
    firstSources: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const {filters} = props;
    this.state = {
      filters: convertFilters(filters),
    };
  }

  componentDidMount() {
    if (this.props.clientId) {
      this.props.getFirstSources({
        include: {
          source: ['client', 'interaction.interface.automation_task.operation'],
        },
        q: {
          client_id_eq: this.props.clientId,
        },
      });
    }
  }

  getOperationsOptions = async (search = '') => {
    await this.props.getOperations({
      q: {
        leads_count_gt: 0,
        client_id_eq: this.props.clientId,
        name_cont: search,
      },
    });
    return this.props.operations;
  };

  getSourcesOptions = () => {
    const {firstSources, language} = this.props;
    const initSources = initSourceFilter(firstSources.payload);

    return [{value: '0', label: language.DOESNT_MATTER}, ...mapSelectList(initSources)];
  };

  getOptInsOptions = async (search = '') => {
    const response = await this.props.getOptIns({
      include_opt_in_column_leads_count: null,
      q: {
        client_id_eq: this.props.clientId,
        name_cont: search,
      },
    });

    const optIns = response.payload.opt_in_columns;
    return optIns.filter((optIn) => optIn.leads_count > 0).map((i) => ({label: i.name, value: i.id}));
  };

  handleCheckFilterDropdown = (filter) => (selected) => {
    const excludableValues = ['none', '0'];

    if (
      excludableValues.includes(selected.value) ||
      (Array.isArray(selected) && selected.some((item) => excludableValues.includes(item.value)))
    ) {
      this.setState({
        filters: {
          ...this.state.filters,
          [filter]: null,
        },
      });
      this.props.onFiltersChange({[filter]: null});
    } else {
      this.setState({
        filters: {
          ...this.state.filters,
          [filter]: selected,
        },
      });
      this.props.onFiltersChange({[filter]: selected});
    }
  };

  createGroupsConfig = (language) => ({
    attributesGroupConfig: [
      {
        component: 'button',
        fieldName: 'phone',
        type: 'checkbox',
        label: language.PHONE,
        props: {},
      },
      {
        component: 'button',
        fieldName: 'email',
        type: 'checkbox',
        label: language.EMAIL,
        props: {},
      },
      {
        component: 'button',
        fieldName: 'visuals',
        type: 'checkbox',
        label: language.MEDIA,
        props: {},
      },
    ],
    participatedGroupConfig: [
      {
        component: 'button',
        groupName: 'participated',
        fieldName: 'not',
        type: 'radio',
        label: language.DOESNT_MATTER,
        props: {},
      },
      {
        component: 'button',
        groupName: 'participated',
        fieldName: 'participated',
        type: 'radio',
        label: language.TIME_PERIOD,
        props: {},
      },
      {
        component: 'datepicker',
        groupName: 'participated',
        fieldName: 'participatedFrom',
        props: {
          name: 'participatedFrom',
          label: language.FROM,
          disabled: !this.state.filters.participated.participated,
          onChange: (date) => this.updateFilter({key: 'participatedFrom', value: date, groupName: 'participated'}),
        },
      },
      {
        component: 'datepicker',
        groupName: 'participated',
        fieldName: 'participatedTo',
        props: {
          name: 'participatedTo',
          label: language.TO,
          disabled: !this.state.filters.participated.participated,
          onChange: (date) => this.updateFilter({key: 'participatedTo', value: date, groupName: 'participated'}),
        },
      },
    ],
    genderGroupConfig: [
      {
        component: 'button',
        fieldName: 'gender',
        type: 'radio',
        value: null,
        label: language.DOESNT_MATTER,
        props: {},
      },
      {
        component: 'button',
        fieldName: 'gender',
        type: 'radio',
        label: language.MALE,
        value: 'male',
        props: {},
      },
      {
        component: 'button',
        fieldName: 'gender',
        type: 'radio',
        label: language.FEMALE,
        value: 'female',
        props: {},
      },
    ],
    createdGroupConfig: [
      {
        component: 'button',
        groupName: 'created',
        fieldName: 'not',
        type: 'radio',
        label: language.DOESNT_MATTER,
        props: {},
      },
      {
        component: 'button',
        groupName: 'created',
        fieldName: 'created',
        type: 'radio',
        label: language.TIME_PERIOD,
        props: {},
      },
      {
        component: 'datepicker',
        groupName: 'created',
        fieldName: 'createdFrom',
        props: {
          name: 'createdFrom',
          label: language.FROM,
          disabled: !this.state.filters.created.created,
          onChange: (date) => this.updateFilter({key: 'createdFrom', value: date, groupName: 'created'}),
        },
      },
      {
        component: 'datepicker',
        groupName: 'created',
        fieldName: 'createdTo',
        props: {
          name: 'createdTo',
          label: language.TO,
          disabled: !this.state.filters.created.created,
          onChange: (date) => this.updateFilter({key: 'createdTo', value: date, groupName: 'created'}),
        },
      },
    ],
    optInsGroupConfig: [
      {
        component: 'button',
        groupName: 'optInRadio',
        fieldName: 'not',
        type: 'radio',
        label: language.DOESNT_MATTER,
        props: {
          onClick: () => {
            this.setState((state) => ({
              filters: {
                ...state.filters,
                optInRadio: {
                  optInRadio: false,
                  no_active: false,
                  not: true,
                },
                optIn: null,
              },
            }));
            this.props.onFiltersChange({
              optInRadio: false,
              optIn: null,
            });
          },
        },
      },
      {
        component: 'button',
        groupName: 'optInRadio',
        fieldName: 'optInRadio',
        type: 'radio',
        label: language.SELECT_OPTINS,
        props: {},
      },
      {
        component: 'button',
        groupName: 'optInRadio',
        fieldName: 'no_active',
        type: 'radio',
        label: language.NO_ACTIVE,
        props: {
          onClick: () => {
            this.setState((state) => ({
              filters: {
                ...state.filters,
                optInRadio: {
                  optInRadio: false,
                  no_active: true,
                  not: false,
                },
                optIn: null,
              },
            }));
            this.props.onFiltersChange({
              optInRadio: 'no_active',
              optIn: null,
            });
          },
        },
      },
    ],
  });

  createGroupComponent = ({component, groupName = null, fieldName, type, label = '', props, value}) => {
    switch (component) {
      case 'button':
        return this.renderFilterButton({fieldName, value, groupName, type, label, props});

      case 'select':
        return (
          <CustomSelect
            key={fieldName}
            name={fieldName}
            value={value}
            cssModifier="leads-filters-modal__filter-selector"
            {...props}
          />
        );

      case 'datepicker':
        const dateValue = groupName ? this.state.filters[groupName][fieldName] : this.state.filters[fieldName];

        return (
          <Datepicker
            key={fieldName}
            name={fieldName}
            value={dateValue}
            cssModifier="leads-filters-modal__filter-datepicker"
            {...props}
          />
        );

      default:
        return null;
    }
  };

  handleShowResults = () => {
    const {instantApply, onFiltersChange, onClose} = this.props;

    if (!instantApply) {
      onFiltersChange({filters: this.state.filters});
    }

    onClose();
  };

  updateFilter = ({key, value, groupName, type = 'checkbox'}) => {
    let val;
    if (groupName) {
      val = isNull(value) ? value : value || this.state.filters[groupName][key];

      return this.updateGroupFilter(groupName, key, val, type);
    }
    val = value || this.state.filters[key];
    this.setState((state) => ({
      filters: {
        ...state.filters,
        [key]: isNull(value) ? value : setValue(val),
      },
    }));
    return this.props.onFiltersChange({
      [key]: isNull(value) ? value : setValue(val),
    });
  };

  updateGroupFilter = (groupName, key, value, type) => {
    let newGroup = {...this.state.filters[groupName]};
    // if group of buttons is radio type
    if (type === 'radio') {
      Object.keys(newGroup)
        // .filter(groupKey => typeof newGroup[groupKey] === 'boolean')
        .forEach((groupKey) => {
          newGroup[groupKey] = typeof newGroup[groupKey] === 'boolean' ? false : '';
        });
      newGroup[key] = setValue(value);
      if (isFilterEmpty(newGroup)) {
        newGroup.not = true;
      }
      this.setState((state) => ({
        filters: {
          ...state.filters,
          [groupName]: newGroup,
        },
      }));

      return this.setTableFilter(groupName, key, value);
    }
    // if group of buttons is checkbox type
    if (key === 'not') {
      Object.keys(newGroup).forEach((filterKey) => {
        newGroup[filterKey] = typeof newGroup[filterKey] === 'boolean' ? false : '';
      });
      newGroup.not = true;
    } else {
      newGroup[key] = setValue(value);
      newGroup.not = false;
    }
    return this.setState(
      (state) => ({
        filters: {
          ...state.filters,
          [groupName]: newGroup,
        },
      }),
      () => {
        this.checkGroupForDefaultValue(groupName);
        this.props.onFiltersChange(newGroup);
      },
    );
  };

  setTableFilter = (groupName, key) => {
    const {onFiltersChange} = this.props;
    if (key === 'not') {
      const group = this.state.filters[groupName];
      const newGroup = {};
      Object.keys(group).forEach((fieldName) => {
        switch (typeof group[fieldName]) {
          case 'boolean':
            newGroup[fieldName] = false;
            break;

          case 'string':
            newGroup[fieldName] = '';
            break;

          default:
            break;
        }
      });
      delete newGroup.not;
      newGroup[groupName] = false;
      if (!(groupName in newGroup)) {
        newGroup[groupName] = true;
      }
      return onFiltersChange(newGroup);
    }
    return onFiltersChange({[groupName]: key});
  };

  checkGroupForDefaultValue = (groupName) => {
    const group = get(this.state.filters, groupName, null);
    if (group && 'not' in group && isFilterEmpty(group)) {
      this.setState({
        filters: {
          ...this.state.filters,
          [groupName]: {
            ...this.state.filters[groupName],
            not: true,
          },
        },
      });
    }
  };

  renderFilterButton = ({
    // key,
    groupName,
    fieldName,
    value,
    type = 'checkbox',
    label = '',
    props,
  }) => {
    const isFilterChecked = checkFilterValue(this.state.filters, groupName, fieldName, value);
    const clickHandler = () => {
      if (props.onClick) {
        return props.onClick();
      }
      return this.updateFilter({key: fieldName, value, groupName, type});
    };

    return (
      <button
        key={label}
        className={cn('leads-filters-modal__filter-item', {
          'leads-filters-modal__filter-item--set': isFilterChecked,
        })}
        onClick={clickHandler}
      >
        {label}
      </button>
    );
  };

  renderFilterAsyncSelector = (item, additionalProps = {}) => {
    return <AsyncDropdownAny className="select--view-2" value={item} {...additionalProps} />;
  };

  renderFilterSelector = (item, options, additionalProps = {}) => {
    return (
      <CustomSelect
        value={item}
        cssModifier="leads-filters-modal__filter-selector"
        options={options}
        {...additionalProps}
      />
    );
  };

  renderFilterGroup = (config) => {
    return (
      <div className="leads-filters-modal__group">
        {config.map((filterElement) => this.createGroupComponent(filterElement))}
      </div>
    );
  };

  render() {
    const {language, totalResults, onClose} = this.props;
    const {
      filters: {origin, operation, optIn, optInRadio},
    } = this.state;
    const {attributesGroupConfig, participatedGroupConfig, genderGroupConfig, createdGroupConfig, optInsGroupConfig} =
      this.createGroupsConfig(language);
    const selectOptions = {
      noResultsText: language.NO_RESULTS,
      // placeholder: language.NO_RESULTS
    };

    return (
      <Modal title={language.TITLE} dialogClassName="leads-filters-modal" onClose={onClose}>
        <div className="leads-filters-modal__container">
          <div className="leads-filters-modal__row">
            <div className="leads-filters-modal__label">{language.ATTRIBUTES}</div>
            {this.renderFilterGroup(attributesGroupConfig)}
          </div>
          <div className="leads-filters-modal__row">
            <div className="leads-filters-modal__label">{language.PARTICIPATED}</div>
            {this.renderFilterGroup(participatedGroupConfig)}
          </div>
          <div className="leads-filters-modal__row">
            <div className="leads-filters-modal__label">{language.GENDER}</div>
            {this.renderFilterGroup(genderGroupConfig)}
          </div>
          <div className="leads-filters-modal__row">
            <div className="leads-filters-modal__label">{language.CREATED}</div>
            {this.renderFilterGroup(createdGroupConfig)}
          </div>
          <div className="leads-filters-modal__row">
            <div className="leads-filters-modal__label">{language.ORIGIN}</div>
            {this.renderFilterSelector(origin, this.getSourcesOptions(), {
              ...selectOptions,
              onChange: this.handleCheckFilterDropdown('origin'),
            })}
          </div>
          <div className="leads-filters-modal__row">
            <div className="leads-filters-modal__label">{language.OPERATION}</div>
            {this.renderFilterAsyncSelector(operation, {
              getOptions: this.getOperationsOptions,
              onChange: this.handleCheckFilterDropdown('operation'),
            })}
          </div>
          <div className="leads-filters-modal__row">
            <div className="leads-filters-modal__label">Opt-In</div>
            {this.renderFilterGroup(optInsGroupConfig)}
          </div>
          <div className="leads-filters-modal__row">
            <div className="leads-filters-modal__label">{language.SELECT_OPTINS}</div>
            {this.renderFilterAsyncSelector(optIn, {
              getOptions: this.getOptInsOptions,
              onChange: this.handleCheckFilterDropdown('optIn'),
              multi: true,
              disabled: !optInRadio.optInRadio,
            })}
          </div>
          <div className="leads-filters-modal__results-button-wrap">
            <button className="leads-filters-modal__results-button" onClick={this.handleShowResults}>
              {language.BUTTON_RESULTS} {totalResults > 0 && `(${totalResults})`}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    firstSources: state.firstSources,
    operations: selectOperationsForFilterModal(state),
    optIns: selectOptInsForFilterModal(state),
  }),
  {
    getFirstSources,
    getOperations,
    // getAutotasks,
    getOptIns: getOptInsAction,
  },
)(FiltersModal);
