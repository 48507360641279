import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {MESSAGE_TASK_STATES} from 'client/common/config';
import Popover from 'client/common/popovers/popover';

import Icon from 'client/components/common/icon';

import './bc-settings-popover.scss';

class BCSettingsPopover extends Component {
  static defaultProps = {
    children: null,
  };

  static propTypes = {
    archived: PropTypes.bool.isRequired,
    sentTo: PropTypes.number.isRequired,
    taskName: PropTypes.string.isRequired,
    taskState: PropTypes.string.isRequired,
    languageState: PropTypes.object.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onToggleArchiveClick: PropTypes.func.isRequired,
    children: PropTypes.node,
    onDeleteClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.BROADCASTING_TASK.SETTINGS_POPOVER;
  }

  renderArchiveButton = () => {
    const {onToggleArchiveClick, taskState} = this.props;

    return taskState !== MESSAGE_TASK_STATES.PERFORMING ? (
      <div className="link display-block" onClick={() => onToggleArchiveClick(true)}>
        {this.LANGUAGE.ARCHIVE_LABEL}
      </div>
    ) : (
      <div key="archive-notice" className="main-text main-text--small">
        {this.LANGUAGE.NOTICE_LABEL}
      </div>
    );
  };

  renderArchiveToggle = () => {
    const {archived, onToggleArchiveClick} = this.props;

    return archived ? (
      <div key="archive-btn" className="bc-settings-popover__restore-link" onClick={() => onToggleArchiveClick(false)}>
        {this.LANGUAGE.RESTORE_LABEL}
      </div>
    ) : (
      this.renderArchiveButton()
    );
  };

  render() {
    const {children, taskName, sentTo, onDeleteClick, onEditClick} = this.props;

    const {LANGUAGE} = this;

    return (
      <Popover
        trigger="both"
        position="bottom"
        arrowOffset={90}
        overlayInnerStyle={{
          width: 340,
        }}
        overlay={
          <div className="popover-list theme-color-16 bc-settings-popover">
            <h3 className="popover-list__title flex-container flex-align-center flex-justify-between">
              <div>{LANGUAGE.TITLE}</div>
              <button className="button button--circle button--bg-14" onClick={onEditClick}>
                <Icon name="edit" className="button__icon" width={20} height={20} />
              </button>
            </h3>
            <div className="bc-settings-popover__content">
              <div className="bc-settings-popover__block">
                <div className="fake-input fake-input--disabled">
                  <div className="fake-input__label">{LANGUAGE.NAME_LABEL}</div>
                  <div className="fake-input__field">{taskName}</div>
                </div>
              </div>
              <div className="bc-settings-popover__links">
                {this.renderArchiveToggle()}
                {!sentTo && (
                  <div className="bc-settings-popover__delete-link message-error-text" onClick={onDeleteClick}>
                    {LANGUAGE.DELETE_LABEL}
                  </div>
                )}
              </div>
            </div>
          </div>
        }
      >
        {children}
      </Popover>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

export default connect(mapStateToProps)(BCSettingsPopover);
