import React, {useState} from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';
import DndFileInput from 'client/common/inputs/dnd-file-input';

import cssModule from './upload-tab.module.scss';

const b = bem('upload-tab', {cssModule});

type UploadTabProps = {
  onChange: (data: {file: File}) => void;
};

const UploadTab: React.FC<UploadTabProps> = (props) => {
  const {onChange} = props;
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.MODALS.INSERT_IMAGE_MODAL.UPLOAD_TAB');
  const [image, setImage] = useState<File | null>(null);

  const handleChange = (file: File) => {
    setImage(file);
    onChange({file});
  };

  return (
    <div className={b()}>
      <DndFileInput className={image ? b('image-dnd') : null} onChange={handleChange}>
        {image ? (
          <div className={b('image-wrap')}>
            <img className={b('image-preview')} src={URL.createObjectURL(image)} alt="" />
          </div>
        ) : (
          <div className={b('empty-state')}>
            <Icon name="plus-in-circle" />
            <p className={b('drag-text')}>{lang.DRAG_AND_DROP}</p>
            <p className={b('click-text')}>{lang.OR_CLICK}</p>
            <p className={b('filesize-text')}>{lang.RECOMMENDED_FILESIZE}</p>
          </div>
        )}
      </DndFileInput>
    </div>
  );
};

export default UploadTab;
