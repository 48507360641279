import {useSelector} from 'react-redux';

import {useQueryParams, useReduxFetch} from 'client/services/hooks';

import {getAgencyClient} from 'client/ducks/agencies/actions';
import {selectAgencyClient} from 'client/ducks/agencies/selectors';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';

import {CLIENT_TYPES} from 'client/models/client/constants';

const useFetchSelectedAgencyClient = (agencyId: number) => {
  const generalClient = useSelector(selectCurrentClient);
  const [queryParams] = useQueryParams(null, {}, {parse: {parseNumbers: true}});
  const isGeneralClientActual = generalClient.id === agencyId;
  const isAgency = isGeneralClientActual && generalClient.type === CLIENT_TYPES.AGENCY;
  const agencyClientId =
    queryParams.agencyClientId && queryParams.agencyClientId !== generalClient.id ? queryParams.agencyClientId : null;

  const {data: agencyClient = null, loading: loadingAgencyClient} = useReduxFetch({
    action: getAgencyClient,
    selector: selectAgencyClient,
    actionArgs: agencyClientId,
    skip: !isAgency || !agencyClientId,
  });

  const isAgencyClientActual = !agencyClientId || agencyClient?.id === agencyClientId;
  const areClientsActual = isGeneralClientActual && isAgencyClientActual;
  const loading = !areClientsActual || loadingAgencyClient;

  return {
    loading,
    generalClient,
    agencyClient: agencyClientId ? agencyClient : null,
  };
};

export default useFetchSelectedAgencyClient;
