import React from 'react';

import {useLanguage} from 'client/services/hooks';

import {interpolate} from 'client/ducks/language/helpers';

import ConfirmationPopover from 'client/common/popovers/confirmation-popover';

type TokensReimportPopoverProps = {
  children: string | React.ReactNode;
  usedTokensCount: number;
  onConfirm: () => any;
  disabled?: boolean;
};

const TokensReimportPopover: React.FC<TokensReimportPopoverProps> = (props) => {
  const {usedTokensCount, onConfirm, disabled, children} = props;
  const lang = useLanguage('PRIZES.POPOVERS.TOKENS_REIMPORT_POPOVER');

  return (
    <ConfirmationPopover
      title={lang.IMPORT_TOKEN_LIST}
      message={interpolate(lang.TOKENS_USED.toString(), {
        used: usedTokensCount,
      })}
      okButtonProps={{
        label: lang.IMPORT,
        onClick: onConfirm,
      }}
      popoverProps={{disabled}}
    >
      {children}
    </ConfirmationPopover>
  );
};

export default TokensReimportPopover;
