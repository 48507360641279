import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {transformDateTimeZone} from 'client/services/helpers';

class ScheduledLotteryStep extends Component {
  static propTypes = {
    step: PropTypes.object,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    step: {},
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.RESULT_MODAL;
  }

  render() {
    const {LANGUAGE} = this;
    const {step} = this.props;
    const {implementation} = step;

    return (
      <div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{LANGUAGE.PARTICIPATION_LABEL}</div>
            <div className="test-step__form-field-wrap test-step__form-field-wrap--flex">
              <div className="test-step__form-label">{LANGUAGE.FROM_LABEL}</div>
              <div className="test-step__form-field test-step__form-field--size-1">
                <div className="fake-input__field">{transformDateTimeZone(implementation.from)}</div>
              </div>
            </div>
            <div className="test-step__form-field-wrap test-step__form-field-wrap--flex">
              <div className="test-step__form-label">{LANGUAGE.TO_LABEL}</div>
              <div className="test-step__form-field test-step__form-field--size-1">
                <div className="fake-input__field">{transformDateTimeZone(implementation.to)}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{LANGUAGE.DRAW_LABEL}</div>
            <div className="test-step__form-field-wrap test-step__form-field-wrap--flex">
              <div className="test-step__form-label">{LANGUAGE.AT_LABEL}</div>
              <div className="test-step__form-field test-step__form-field--size-1">
                <div className="fake-input__field">{transformDateTimeZone(implementation.draw_at)}</div>
              </div>
            </div>
          </div>
        </div>
        {implementation.lottery_scenario_step_prizes.map((prize, key) => (
          <div key={key}>
            <div className="test-step__form-block">
              <div className="fake-input fake-input--disabled var-container">
                <div className="fake-input__label var-name">
                  {LANGUAGE.PRIZE_LEVEL_LABEL} {prize.level}:
                </div>
                <div className="fake-input__field--long-text">{prize.name}</div>
              </div>
            </div>
            <div className="test-step__form-block">
              <div className="fake-input fake-input--disabled var-container">
                <div className="fake-input__label var-name">{LANGUAGE.NUMBER_LABEL}:</div>
                <div className="fake-input__field">{prize.condition}</div>
              </div>
            </div>
          </div>
        ))}
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled var-container">
            <div className="fake-input__label var-name">{LANGUAGE.COMMENT_LABEL}:</div>
            <div className="fake-input__textarea">{step.comment}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(ScheduledLotteryStep);
