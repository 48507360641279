import React, {useState} from 'react';

import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import TabSwitcher from 'client/common/tab-switcher';
import PlusButton from 'client/common/tab-switcher/custom-elements/plus-button';

import DiyOpLotteryBlock from 'client/components/diy-operation/blocks/diy-op-lottery-block';
import DiyOpPrizesBlock from 'client/components/diy-operation/blocks/diy-op-prizes-block';
import DrawParametersModal from 'client/components/lottery/modals/draw-parameters-modal';
import {Autotask} from 'client/models/autotask/types';
import {isOperationArchived} from 'client/models/operations/helpers';
import {Operation} from 'client/models/operations/types';
import {GAME_TYPES} from 'client/models/prizes/constants';

import cssModule from './diy-op-games-block.module.scss';

const b = bem('diy-op-games-block', {cssModule});

const tabs = {
  INSTANT_WIN: 'instant_win',
  LOTTERY: 'lottery',
};

type DiyOpGamesBlockProps = {
  operation: Operation;
  autotask: Autotask;
  fetchAutotask: () => Promise<void>;
};

const DiyOpGamesBlock: React.FC<DiyOpGamesBlockProps> = (props) => {
  const {operation, autotask, fetchAutotask} = props;
  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_GAMES_BLOCK');
  const [activeTab, setActiveTab] = useState(tabs.INSTANT_WIN);
  const [showDrawModal, toggleDrawModal] = useToggle(false);

  const hasInstantWin = autotask.client_games?.some((i) => i.game_type === GAME_TYPES.INSTANT_WIN);
  const hasLottery = autotask.client_games?.some((i) => i.game_type === GAME_TYPES.PRIZE_DRAW);

  const lotteryGame = hasLottery ? autotask.client_games?.find((i) => i.game_type === GAME_TYPES.PRIZE_DRAW) : null;

  const getTabClassName = ({isActive}: {isActive: boolean}) => b('tab-link', {active: isActive});

  const isOpArchived = isOperationArchived(operation);

  return (
    <div className={b()}>
      {showDrawModal && (
        <DrawParametersModal
          onClose={() => {
            toggleDrawModal();
          }}
          onSave={async () => {
            await fetchAutotask();
            setActiveTab(tabs.LOTTERY);
          }}
        />
      )}
      <TabSwitcher
        className={b('tabs')}
        tabs={[
          {
            title: lang.INSTANT_WIN,
            value: tabs.INSTANT_WIN,
            className: getTabClassName,
            exclude: !hasInstantWin,
          },
          {
            title: lang.LOTTERY,
            value: tabs.LOTTERY,
            className: getTabClassName,
            exclude: !hasLottery,
          },
          {
            customElement: <PlusButton key="plus" onClick={toggleDrawModal} />,
            exclude: hasLottery || isOpArchived || !operation.editable,
          },
        ]}
        activeTab={activeTab}
        onTabClick={setActiveTab}
      />
      {activeTab === tabs.INSTANT_WIN && <DiyOpPrizesBlock operation={operation} fetchAutotask={fetchAutotask} />}
      {activeTab === tabs.LOTTERY && lotteryGame && (
        <DiyOpLotteryBlock
          fetchAutotask={fetchAutotask}
          game={lotteryGame}
          operation={operation}
          onDeleteDraw={() => setActiveTab(tabs.INSTANT_WIN)}
        />
      )}
    </div>
  );
};

export default DiyOpGamesBlock;
