import get from 'lodash/get';
import groupBy from 'lodash/groupBy';

export function mapParticipants(participants) {
  return participants.map(
    ({
      id,
      lead,
      participation_prizes,
      region_name,
      store_name,
      interaction,
      min_level_reached_at,
      level_reached_or_participated_at,
    }) => ({
      ...lead,
      winner_id: id,
      origin: get(interaction, 'name', ''),
      prizes: groupBy(participation_prizes, 'db_number'),
      region: region_name,
      store: store_name,
      min_level_reached_at,
      level_reached_or_participated_at,
    }),
  );
}
