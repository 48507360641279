import React from 'react';

import {Field} from 'redux-form';

import BaseFieldComponent from 'client/common/fields/base-field';
import {DatepickerInput} from 'client/common/inputs';

import {BaseFieldComponentProps} from '../base-field/types';

const InputComponent = (props) => <BaseFieldComponent Component={DatepickerInput} {...props} />;

InputComponent.propTypes = BaseFieldComponentProps;

const DatepickerField = (props) => {
  return <Field component={InputComponent} {...props} />;
};

export default DatepickerField;
