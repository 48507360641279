import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import {selectLeadsDisplayItemsMappedTable} from 'client/ducks/lead-display-items/selectors';
import {anonymizeLead} from 'client/ducks/lead/actions';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {CLIENT_PAGES, COLUMN_ADAPTER_TYPES} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';

import ParticipationsModal from 'client/components/leads/components/modals/participations-modal/participations-modal';

import CardColumn from './components/columns/card-column';
import ClientsColumn from './components/columns/clients-column';
import ContactsColumn from './components/columns/contacts-column';
import ExportedColumn from './components/columns/exported-column';
import NameColumn from './components/columns/name-column';
import ParticipationsAllColumn from './components/columns/participations-all-column';
import ParticipationsLastColumn from './components/columns/participations-last-column';
import TextColumn from './components/columns/text-column';
import LeadsListToolbar from './components/leads-list-toolbar';

import cssModule from './leads-main-data-grid.module.scss';

const b = bem('leads-main-data-grid', {cssModule});

class LeadsMainDataGrid extends ReactQueryParams {
  static propTypes = {
    data: PropTypes.array,
    isAdmin: PropTypes.bool.isRequired,
    filter: PropTypes.object,
    meta: PropTypes.object.isRequired,
    allGeneralColumns: PropTypes.array,
    allCustomColumns: PropTypes.object,
    showClear: PropTypes.bool.isRequired,
    anonymizeLead: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
    onFilterShow: PropTypes.func.isRequired,
    onSearchClear: PropTypes.func.isRequired,
    onFilterClear: PropTypes.func.isRequired,
    onLeadsDelete: PropTypes.func,
    onSearchChange: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    searchDefault: PropTypes.string.isRequired,
    getCheckedLeads: PropTypes.func,
    internalColumns: PropTypes.array,
    leadDisplayItems: PropTypes.array.isRequired,
    onFilterFieldClear: PropTypes.func.isRequired,
    onAddNewColumnShow: PropTypes.func,
    onShowTableSettings: PropTypes.func,
    isPossibleToAddColumn: PropTypes.bool,
    onShowClientVisibilitySettings: PropTypes.func,
    loading: PropTypes.bool,
    loadingColumns: PropTypes.bool,
  };

  static defaultProps = {
    filter: {},
    data: [],
    isReportTable: false,
    isPossibleToAddColumn: false,
    onLeadsDelete: () => null,
    getCheckedLeads: () => null,
    onAddNewColumnShow: () => null,
    onShowTableSettings: () => null,
    onShowClientVisibilitySettings: () => null,
    loading: false,
    loadingColumns: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      meta: {total_count: 0},
      deleteConfirmationPopup: false,
      isParticipationVisible: false,
      participations: [],
      leadsId: null,
      checkedRows: [],
    };

    const langPayload = props.languageState.payload;
    this.LANGUAGE = langPayload.LEADS_LIST;
    this.LANGUAGE_TABLE = langPayload.TABLE;

    this.baseColumns = [
      {
        name: 'card',
        path: 'id',
        width: 60,
        render: this.renderCard,
        label: this.LANGUAGE.CARD_TABLE_COLUMN,
      },
      {
        name: 'first_name',
        path: 'first_name',
        render: this.renderName,
        width: 110,
        label: this.LANGUAGE.FIRST_NAME_TABLE_COLUMN,
        sortable: true,
      },
      {
        name: 'last_name',
        path: 'last_name',
        render: this.renderName,
        width: 110,
        label: this.LANGUAGE.LAST_NAME_TABLE_COLUMN,
        sortable: true,
      },
      {
        name: 'gender',
        path: 'gender',
        width: 85,
        label: this.LANGUAGE.GENDER_TABLE_COLUMN,
        sortable: true,
      },
      {
        name: 'birth_date',
        path: 'birth_date',
        width: 65,
        label: this.LANGUAGE.AGE_TABLE_COLUMN,
        sortable: true,
      },
      {
        name: 'phone',
        path: 'phone',
        render: this.renderContacts,
        width: 85,
        label: this.LANGUAGE.CONTACTS_TABLE_COLUMN,
        sortable: true,
      },
      {
        name: 'address1',
        path: 'address1',
        width: 110,
        label: this.LANGUAGE.ADDRESS1,
        sortable: true,
      },
      {
        name: 'address2',
        path: 'address2',
        width: 110,
        label: this.LANGUAGE.ADDRESS2,
        sortable: true,
      },
      {
        name: 'zip',
        path: 'zip',
        width: 100,
        label: this.LANGUAGE.ZIP,
        sortable: true,
        ellipsisText: true,
      },
      {
        name: 'city',
        path: 'city',
        width: 100,
        label: this.LANGUAGE.CITY,
        sortable: true,
        ellipsisText: true,
      },
      {
        name: 'country',
        path: 'country',
        width: 100,
        label: this.LANGUAGE.COUNTRY,
        sortable: true,
      },
      {
        name: 'opt_ins',
        path: 'leads_opt_ins',
        render: this.renderOptIns,
        width: 100,
        label: this.LANGUAGE.OPT_IN_TYPES_TABLE_COLUMN,
        sortable: true,
      },
      {
        name: 'participations',
        width: 165,
        label: this.LANGUAGE.PARTICIPATIONS_TABLE_COLUMN,
        subcolumns: [
          {
            name: 'latest_participated_at',
            path: 'latest_participated_at',
            width: 75,
            label: this.LANGUAGE.PARTICIPATIONS_LAST_TABLE_COLUMN,
            render: this.renderParticipationsLast,
            sortable: true,
          },
          {
            name: 'participations_count',
            path: 'participations_count',
            width: 90,
            label: this.LANGUAGE.PARTICIPATIONS_ALL_TABLE_COLUMN,
            render: this.renderParticipationsAll,
            sortable: true,
          },
        ],
      },
      {
        name: 'visuals_count',
        path: 'visuals_count',
        width: 85,
        label: this.LANGUAGE.VISUALS_TABLE_COLUMN,
        sortable: true,
      },
      {
        name: 'client_name',
        path: 'client_name',
        width: 90,
        render: this.renderClients,
        label: this.LANGUAGE.CLIENT_TABLE_COLUMN,
        sortable: true,
      },
      {
        name: 'created_at',
        path: 'created_at',
        width: 90,
        label: this.LANGUAGE.CREATED_TABLE_COLUMN,
        sortable: true,
      },
      {
        name: 'expires_at',
        path: 'expires_at',
        width: 120,
        render: this.renderDate,
        label: this.LANGUAGE.EXPIRES_AT,
      },
      {
        name: 'first_source',
        path: 'first_source',
        width: 110,
        render: this.renderSource,
        label: this.LANGUAGE.SOURCE_TABLE_COLUMN,
        sortable: true,
      },
      {
        name: 'last_export',
        path: 'last_export',
        width: 100,
        render: this.renderExported,
        label: this.LANGUAGE.EXPORTED_TABLE_COLUMN,
        sortable: true,
      },
    ];
  }

  showMoreParticipation = (leadsId) => {
    this.setState({
      isParticipationVisible: true,
      participations: this.props.data.find((item) => item.id === leadsId).participations,
      leadsId,
    });
  };

  renderCard = ({value: id}) => <CardColumn link={`${CLIENT_PAGES.LEADS_PROFILE}/${id}`} />;
  renderName = ({value}) => <NameColumn name={value} />;
  renderClients = ({value}) => (value ? <ClientsColumn {...value} /> : null);
  renderExported = ({value}) => <ExportedColumn {...value} name={String(value.name)} />;
  renderContacts = ({item}) => <ContactsColumn {...item} />;
  renderText = (str) => <TextColumn value={str} />;
  renderSource = ({value}) => this.renderText(value.name);

  renderParticipationsLast = ({value = {}}) => {
    const {isAdmin} = this.props;
    const {
      created_at: date,
      operation_name: name,
      client_type: clientType,
      client_id: clientId,
      operation_id: operationId,
    } = value;

    return (
      <ParticipationsLastColumn
        date={date}
        name={name}
        clientType={clientType}
        clientId={clientId}
        operationId={operationId}
        isAdmin={isAdmin}
      />
    );
  };

  renderParticipationsAll = ({value: data}) => {
    const {isAdmin} = this.props;
    const {participations, count} = data;
    const leadId = data.id ? data.id : data.lead_id;

    return (
      <ParticipationsAllColumn
        count={count}
        participations={participations}
        showMoreText={this.LANGUAGE.SHOW_MORE}
        onClick={() => this.showMoreParticipation(leadId, participations)}
        isAdmin={isAdmin}
      />
    );
  };

  renderOptIns = ({value: optIns}) => (
    <div style={{width: '100px'}}>
      {optIns.map((code, key) => (
        <div className="ellipsis-text" key={key}>
          {code}
        </div>
      ))}
    </div>
  );

  renderDate = ({value}) => {
    return <div className="bold">{value || this.LANGUAGE.NO_VALUE}</div>;
  };

  confirmDeleteLeads = async () => {
    let err = false;

    this.setState({deleteConfirmationPopup: false});

    if (this.state.checkedRows.length) {
      const anonymizeLeads = this.state.checkedRows.map(({id}) => {
        this.props.anonymizeLead(id);
      });
      await Promise.all(anonymizeLeads).catch(() => {
        err = true;
      });
    }

    if (!err) {
      this.setState({checkedRows: []});
      await this.props.onLeadsDelete();
    }
  };

  deleteLeads = () => {
    this.setState({deleteConfirmationPopup: true});
  };

  closeDeletePopup = () => {
    this.setState({deleteConfirmationPopup: false});
  };

  closeParticipation = () => {
    this.setState({isParticipationVisible: false});
  };

  onSortChange = ({sortField, sortOrder}) => {
    this.setState({checkedRows: []});
    this.props.onSortChange(sortField, sortOrder);
  };

  getColumns = (displayItems) => {
    const displayColumns = displayItems.length
      ? displayItems
      : [...this.props.allGeneralColumns, ...this.props.allCustomColumns.payload.lead_columns];

    let columns = [];

    columns.push({
      name: 'card',
      path: 'id',
      width: '60',
      render: this.renderCard,
      label: this.LANGUAGE.CARD_TABLE_COLUMN,
    });

    displayColumns.forEach((item) => {
      const {type: adapterType, name: adapterName} = item;

      switch (adapterType) {
        case COLUMN_ADAPTER_TYPES.EXTERNAL: // custom column
        case 'LeadColumn': // custom column
          columns.push({
            width: '90',
            render: this.renderName,
            label: item.name,
            path: String(item.id),
            name: String(item.id),
          });
          break;
        case COLUMN_ADAPTER_TYPES.INTERNAL: // default column
        case COLUMN_ADAPTER_TYPES.ASSOCIATION:
        case COLUMN_ADAPTER_TYPES.SYSTEM:
          switch (adapterName) {
            case 'last_participation':
              columns.push({
                name: 'participations',
                width: '165',
                label: this.LANGUAGE.PARTICIPATIONS_TABLE_COLUMN,
                subcolumns: [
                  {
                    name: 'latest_participated_at',
                    path: 'latest_participated_at',
                    width: '75',
                    label: this.LANGUAGE.PARTICIPATIONS_LAST_TABLE_COLUMN,
                    render: this.renderParticipationsLast,
                    sortable: true,
                  },
                  {
                    name: 'participations_count',
                    path: 'participations_count',
                    width: '90',
                    label: this.LANGUAGE.PARTICIPATIONS_ALL_TABLE_COLUMN,
                    render: this.renderParticipationsAll,
                    sortable: true,
                  },
                ],
              });
              break;
            case 'phone':
            case 'email':
              columns.push({
                name: 'phone',
                path: 'phone',
                render: this.renderContacts,
                width: '85',
                label: this.LANGUAGE.CONTACTS_TABLE_COLUMN,
              });
              break;
            default:
              const column = this.baseColumns.find((col) => col.name === adapterName);
              if (column) {
                columns.push(column);
              }
          }
          break;
        default:
      }
    });

    return columns.filter((col) => col.name);
  };

  render() {
    const {
      meta,
      onPageChange,
      data,
      onSearchChange,
      clientId,
      isAdmin,
      loading,
      leadDisplayItems,
      allGeneralColumns,
      perPage = 5,
      getCheckedLeads,
    } = this.props;

    const {isParticipationVisible, participations, leadsId, halfLink} = this.state;

    const message = this.LANGUAGE.DELETE_MODAL_MESSAGE
      ? this.LANGUAGE.DELETE_MODAL_MESSAGE.replace(/\[N\]/, this.state.checkedRows.length)
      : '';
    const isClientVersion = !!clientId;

    const columns = clientId && allGeneralColumns ? this.getColumns(leadDisplayItems) : this.baseColumns;

    return (
      <div className="pos-rel">
        <ParticipationsModal
          show={isParticipationVisible}
          onClose={this.closeParticipation}
          participations={participations}
          leadsId={leadsId}
          halfLink={halfLink}
          isAdmin={isAdmin}
        />
        <ConfirmationModal
          title={this.LANGUAGE.DELETE_MODAL_TITLE}
          message={message}
          cancelText={this.LANGUAGE.DELETE_MODAL_CANCEL_BUTTON}
          confirmText={this.LANGUAGE.DELETE_MODAL_CONFIRM_BUTTON}
          className="theme-color-2"
          buttonConfirmClass="button--bg-2"
          onConfirm={this.confirmDeleteLeads}
          onCancel={this.closeDeletePopup}
          onClose={this.closeDeletePopup}
          show={this.state.deleteConfirmationPopup}
        />
        <div className={b('toolbar-wrapper')}>
          <LeadsListToolbar
            isClientVersion={isClientVersion}
            onFilterFieldClear={this.props.onFilterFieldClear}
            onFilterClear={this.props.onFilterClear}
            LANGUAGE={this.LANGUAGE}
            LANGUAGE_TABLE={this.LANGUAGE_TABLE}
            filter={this.props.filter}
            meta={this.props.meta}
            checkedLength={this.state.checkedRows.length}
            showFilter={this.props.onFilterShow}
            deleteLeads={this.deleteLeads}
            addNewColumn={this.props.onAddNewColumnShow}
            openTableSettings={this.props.onShowTableSettings}
            openClientVisibilitySettings={this.props.onShowClientVisibilitySettings}
            showImport={this.props.showImport}
            showExport={this.props.showExport}
            showExportAll={this.props.showExportAll}
            isReportTable={this.props.isReportTable}
            isPossibleToAddColumn={this.props.isPossibleToAddColumn}
            isAdmin={this.props.isAdmin}
            loadingColumns={this.props.loadingColumns}
            onSearchChange={onSearchChange}
          />
          <PerPageDropdown
            onChange={(pages) => {
              onPageChange({page: 1, perPage: pages});
            }}
            value={String(perPage)}
            simpleValue
          />
        </div>
        <ClientTable
          data={data}
          onSortChange={this.onSortChange}
          loading={loading}
          checkable={true}
          onCheck={(isChecked, item) => {
            let checkedItems;
            if (isChecked) {
              checkedItems = [...this.state.checkedRows, item];
            } else {
              checkedItems = this.state.checkedRows.filter((row) => row.id !== item.id);
            }

            this.setState({checkedRows: checkedItems});
            getCheckedLeads(checkedItems.map(({id}) => id));
          }}
          onCheckAll={(isChecked) => {
            this.setState({checkedRows: isChecked ? data : []});
            getCheckedLeads(data.map(({id}) => id));
          }}
          checkedRows={this.state.checkedRows}
          columns={columns}
          sortField={this.props.sort?.name}
          sortOrder={this.props.sort?.order}
        />
        <PaginationBar
          data={data}
          currentPage={meta.current_page}
          totalPages={meta.total_pages}
          totalItems={meta.total_count}
          onPageChange={onPageChange}
          perPage={perPage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  leadDisplayItems: selectLeadsDisplayItemsMappedTable(state),
  isAdmin: selectIsAdmin(state),
});

const mapDispatchToProps = {
  anonymizeLead,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsMainDataGrid);
