import React, {useState, useMemo} from 'react';

import placeholderIcon from 'assets/icons/colored/placeholder-no-photo-mini.svg';
import placeholder from 'assets/icons/colored/placeholder-no-photo.svg';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {interpolate} from 'client/ducks/language/helpers';
import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

import ImageField from 'client/common/fields/image-field';
import TextField from 'client/common/fields/text-field';
import TextareaField from 'client/common/fields/textarea-field';

import LanguageDevicePanel from 'client/components/diy-operation/controls/language-device-panel';
import {LotteryPrizeModalFormName} from 'client/components/lottery/modals/lottery-prize-modal';

import cssModule from './win-params-fieldset.module.scss';

const b = bem('win-params-fieldset', {cssModule});

type WinParamsFieldsetProps = {
  disabled: boolean;
  onRemoveImage?: (ids: number[]) => void;
};

const PICTO_SIZE = 256;

const WinParamsFieldset: React.FC<WinParamsFieldsetProps> = (props) => {
  const {disabled, onRemoveImage} = props;

  const [deletingImages, setDeletingImages] = useState<number[]>([]);

  const lang = useLanguage('LOTTERY.MODALS.LOTTERY_PRIZE_MODAL.WIN_PARAMS_FIELDSET');
  const languages = useSelector(selectPrizeLanguages);

  const languageOptions = useMemo(() => languages.map((i) => ({value: i, label: i})), [languages]);

  const {
    formValues: {winParams = {}},
    change,
  } = useReduxForm(LotteryPrizeModalFormName);
  const {language, defaultLanguage, langUniqueMode} = winParams;

  const copyDefaultValue = (event: React.ChangeEvent<HTMLInputElement> | File, field: string) => {
    const newValue = (event as React.ChangeEvent<HTMLInputElement>)?.target?.value ?? (event as File);

    if (langUniqueMode) {
      languages.forEach((key) => {
        change(`winParams.${field}.${key}`, newValue);
      });
    } else if (language === defaultLanguage) {
      const fieldValues = winParams[field];
      const defaultValue = newValue || fieldValues[defaultLanguage];

      if (defaultValue) {
        for (const [key, value] of Object.entries(fieldValues)) {
          if (key !== defaultLanguage && !value) {
            change(`winParams.${field}.${key}`, defaultValue);
          }
        }
      }
    }
  };

  const onUpload = (file: File, field: string) => {
    if (file) {
      const id = winParams[field]?.[`${language}_id`] || null;
      setDeletingImages((prev) => prev.filter((i) => i !== id));
    }

    change(`winParams.${field}.${language}`, file);
    copyDefaultValue(file, field);
  };

  const handleRemoveImage = (field: string) => {
    const newDeletingImages: number[] = [...deletingImages];

    const addUniqueId = (id: number) => {
      if (id && !newDeletingImages.includes(id)) {
        newDeletingImages.push(id);
      }
    };

    if (langUniqueMode) {
      for (const langKey of languages) {
        const currentId = winParams[field]?.[`${langKey}_id`] || null;
        addUniqueId(currentId);
      }
    } else {
      const currentId = winParams[field]?.[`${language}_id`] || null;
      addUniqueId(currentId);
    }

    setDeletingImages(newDeletingImages);

    onRemoveImage?.(newDeletingImages);
  };

  const handleLanguageCommonToggle = (value: boolean) => {
    const fields = ['name', 'picto', 'image', 'description', 'legal'] as const;

    const updatedFields = fields.reduce(
      (acc, field) => {
        acc[field] = {...winParams?.[field]};
        return acc;
      },
      {} as Record<(typeof fields)[number], any>,
    );

    change('winParams.langUniqueMode', value);

    languages.forEach((key) => {
      fields.forEach((field) => {
        updatedFields[field][key] = updatedFields[field][language];
      });
    });

    fields.forEach((field) => {
      change(`winParams.${field}`, updatedFields[field]);
    });
  };

  return (
    <div className={b()}>
      {typeof langUniqueMode === 'boolean' && (
        <LanguageDevicePanel
          className={b('lang-group')}
          languages={languageOptions}
          language={language}
          onChangeLanguage={(value) => change('winParams.language', value)}
          langInitiallyCommon={langUniqueMode}
          onLanguageCommonToggle={handleLanguageCommonToggle}
          prefix="win-params-language"
        />
      )}
      <div className={b('field')}>
        <TextField
          className={b('name-field')}
          name={`winParams.name.${language}`}
          label={lang.PRIZE_NAME}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => copyDefaultValue(e, 'name')}
          disabled={disabled}
          withWrap={true}
          required={true}
        />
        <ImageField
          disabled={disabled}
          className={b('picto-field')}
          name={`winParams.picto.${language}`}
          onChange={(file: File) => onUpload(file, 'picto')}
          acceptFormats=".png,.jpg,.jpeg"
          placeholderIcon={placeholderIcon}
          placeholders={{
            noValue: lang.PLACEHOLDER_PICTO_INSERT,
            withValue: lang.PLACEHOLDER_PICTO_REPLACE,
          }}
          checks={{
            checks: {
              check_error_size: PICTO_SIZE,
            },
            messages: {
              check_error_size: interpolate(lang.PICTO_SIZE_ERROR?.toString(), {
                width: PICTO_SIZE,
                height: PICTO_SIZE,
              }),
            },
          }}
          onRemove={() => handleRemoveImage('picto')}
          removable={true}
        />
      </div>
      <div className={b('image-row')}>
        <ImageField
          classNames={{
            controls: b('win-params-controls'),
          }}
          name={`winParams.image.${language}`}
          label={lang.IMAGE}
          onChange={(file: File) => onUpload(file, 'image')}
          acceptFormats=".png,.jpg,.jpeg"
          placeholderIcon={placeholder}
          keepRatio={true}
          disabled={disabled}
          onRemove={() => handleRemoveImage('image')}
          removable={true}
        />
      </div>
      <TextareaField
        name={`winParams.description.${language}`}
        label={lang.DESCRIPTION}
        textareaClassName={b('description')}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => copyDefaultValue(e, 'description')}
        disabled={disabled}
      />
      <TextareaField
        name={`winParams.legal.${language}`}
        className={b('legal-field')}
        label={lang.LEGAL}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => copyDefaultValue(e, 'legal')}
        height={75}
        disabled={disabled}
      />
    </div>
  );
};

export default WinParamsFieldset;
