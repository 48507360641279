import React, {PureComponent} from 'react';

import update from 'immutability-helper';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FieldArray, reduxForm, formValueSelector} from 'redux-form';

import bem from 'client/services/bem';
import {required, number, maxLength} from 'client/services/validator';

import {deleteLotteryScenarioStepPrize} from 'client/ducks/scenario/actions';

import AppButton from 'client/common/buttons';
import {SelectField, TextField, TextareaField, RadioGroupField} from 'client/common/fields';

import {maxLengthNormalization, preventDefault, onSubmitSuccess, onSubmitFail} from '../helpers';

// re-use main form styles
// TODO: make own styles
import cssModule from './../../ats-step/ats-step.module.scss';

const b = bem('ats-step', {cssModule});

const TIME_UNITS = [
  {value: 'days', label: 'days'},
  {value: 'hours', label: 'hours'},
  {value: 'minutes', label: 'minutes'},
  {value: 'seconds', label: 'seconds', default: true},
];

const createPrize = () => ({
  name: '',
  condition: 1,
  time_unit: TIME_UNITS.find((unit) => unit.default).value,
});

class AtsInstantWinForm extends PureComponent {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    deleteLotteryScenarioStepPrize: PropTypes.func.isRequired,
    array: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    data: PropTypes.object,
    isTimeRule: PropTypes.bool,
  };

  LANGUAGE = this.props.languageState.payload.AUTOTASK_SCENARIO.SCENARIO_FORMS;

  rules = {
    name: [required(this.LANGUAGE.ERRORS.REQUIRED)],
    prizePer: [
      required(this.LANGUAGE.ERRORS.REQUIRED),
      number(this.LANGUAGE.ERRORS.ONLY_NUMBERS),
      maxLength(7, `${this.LANGUAGE.ERRORS.MAXIMUM_LENGTH} 7 ${this.LANGUAGE.ERRORS.DIGITS}`),
    ],
    prizeTypeNumber: [required(this.LANGUAGE.ERRORS.REQUIRED)],
  };

  prizeTypeOptions = [
    {label: this.LANGUAGE.WIN_LABEL + '1', value: '1'},
    {label: this.LANGUAGE.WIN_LABEL + '2', value: '2'},
    {label: this.LANGUAGE.WIN_LABEL + '3', value: '3'},
  ];

  timeOptions = () => {
    return TIME_UNITS.map((option) => {
      const newLabel = this.LANGUAGE.WIN[`${option.label.toUpperCase()}_OPTION`] || option.label;

      return option.default
        ? {
            value: option.value,
            label: newLabel,
            default: option.default,
          }
        : {
            value: option.value,
            label: newLabel,
          };
    });
  };

  renderPrize = (prize, index, onRemove, isPerTime) => {
    let removeButton = null;

    if (onRemove) {
      removeButton = (
        <AppButton
          onClick={onRemove}
          iconName="trash"
          rounded={true}
          color="text-additional"
          className={b('form-item-delete')}
        />
      );
    }

    return (
      <>
        <TextField
          name={`${prize}.name`}
          label={`${this.LANGUAGE.WIN.PRIZE_LEVEL_LABEL} ${index + 1}`}
          validate={this.rules.name}
          required={true}
          className={b('form-field-wrap', ['view-2'])}
        />
        <TextField
          name={`${prize}.limit`}
          label={this.LANGUAGE.WIN.AMOUNT_OF_PRIZES}
          normalize={maxLengthNormalization(7)}
          className={b('form-field-wrap', ['view-2'])}
        />

        <div className={b('form-field-wrap', ['flex'])}>
          <div className={b('form-label')}>{this.LANGUAGE.WIN.WINNER_LABEL}</div>
          <TextField
            name={`${prize}.condition`}
            validate={this.rules.prizePer}
            normalize={maxLengthNormalization(7)}
            className={b('form-field', ['size-6'])}
          />
          {!isPerTime ? (
            <div className={b('form-label')}>{this.LANGUAGE.WIN.PERSONS_LABEL}</div>
          ) : (
            <SelectField
              name={`${prize}.time_unit`}
              searchable={false}
              options={this.timeOptions()}
              simpleValue={true}
              className={b('form-field', ['size-7'])}
            />
          )}
        </div>
        {removeButton}
      </>
    );
  };

  removePrize = (fields, index) => {
    const {data} = this.props;
    const field = fields.get(index);

    if (field.id) {
      this.props.deleteLotteryScenarioStepPrize(data.id, field.id).then(() => fields.remove(index));
    } else {
      fields.remove(index);
    }
  };

  renderPrizes = ({fields, isTimeRule}) => {
    const onRemove = fields.length > 1 ? (index) => () => this.removePrize(fields, index) : () => null;

    return fields.map((field, index) => (
      <div key={index} className={b('form-item')}>
        {this.renderPrize(field, index, onRemove(index), isTimeRule)}
      </div>
    ));
  };

  handleAddPrize = () => {
    this.props.array.push('implementation.lottery_scenario_step_prizes', createPrize());
  };

  render() {
    const {isTimeRule} = this.props;

    return (
      <form onSubmit={preventDefault} className={b('form')}>
        <TextField label={this.LANGUAGE.STEP_NAME_LABEL} name="name" validate={this.rules.name} required={true} />
        <SelectField
          label={this.LANGUAGE.PRIZE_TYPE_LABEL}
          name="implementation.prize_type_number"
          searchable={false}
          options={this.prizeTypeOptions}
          validate={this.rules.prizeTypeNumber}
          simpleValue={true}
          required={true}
        />

        <div>
          <h4 className={b('form-title')}>{this.LANGUAGE.WIN.RULE_LABEL}</h4>
          <RadioGroupField
            color="default"
            direction="horizontal"
            name="implementation.kind"
            options={[
              {
                label: this.LANGUAGE.WIN.TIME_LABEL,
                value: 'time',
              },
              {
                label: this.LANGUAGE.WIN.PERSONS_LABEL,
                value: 'persons',
              },
            ]}
          />
        </div>
        <FieldArray
          name="implementation.lottery_scenario_step_prizes"
          component={this.renderPrizes}
          props={{
            isTimeRule,
          }}
        />

        <AppButton
          iconName="plus"
          onClick={this.handleAddPrize}
          label={this.LANGUAGE.WIN.ADD_PRIZE_BUTTON}
          size="small"
          fontSize="small"
          color="text-additional"
        />
        <TextareaField label={this.LANGUAGE.COMMENT_LABEL} name="comment" />
      </form>
    );
  }
}

export const createImplementation = () => ({
  lottery_scenario_step_prizes: [createPrize()],
  kind: 'time',
});

// ?`
export const transformFormData = (values) =>
  update(values, {
    implementation: {
      lottery_scenario_step_prizes: {
        $apply: (prizes) =>
          prizes.map((prize, index) => ({
            ...prize,
            level: index + 1,
          })),
      },
    },
  });

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(props.formId);
  const lotteryScenarioStepPrizes = props.data.implementation.lottery_scenario_step_prizes
    .map((item) => ({
      ...item,
      amount: 1,
    }))
    .sort((a, c) => a.level - c.level);

  return {
    form: props.formId,
    initialValues: {
      ...props.data,
      implementation: {
        ...props.data.implementation,
        lottery_scenario_step_prizes: lotteryScenarioStepPrizes,
      },
    },
    isTimeRule: selector(state, 'implementation.kind') === 'time',
    languageState: state.languageState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteLotteryScenarioStepPrize,
    },
    dispatch,
  );

export default flow([
  reduxForm({
    touchOnBlur: false,
    onSubmit: (values, dispatch, props) => {
      const transformedValues = transformFormData(values);

      return props.onSubmitForm(transformedValues);
    },
    onSubmitSuccess,
    onSubmitFail,
    enableReinitialize: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
])(AtsInstantWinForm);
