import React, {useState, useCallback} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {useUpdateEffect} from 'react-use';

/**
 *
 * @type {React.FC<DiyOpMainBlockInput.PropTypes>}
 */

// TODO: from jsx => tsx
const DiyOpMainBlockInput = (props) => {
  const {component: Component, name, updateOperation, initialValue, ...restProps} = props;

  const [valueState, setValueState] = useState(initialValue);

  useUpdateEffect(() => {
    if (valueState !== initialValue) {
      setValueState(initialValue);
    }
  }, [initialValue]);

  const handleBlur = useCallback(() => {
    if (valueState !== initialValue) {
      updateOperation(name, valueState);
    }
  }, [name, updateOperation, initialValue, valueState]);

  const handleChange = useCallback((arg) => {
    const value = get(arg, 'target.value', arg); // arg can be event or value

    setValueState(value);
  }, []);

  return <Component name={name} value={valueState} onBlur={handleBlur} onChange={handleChange} {...restProps} />;
};

DiyOpMainBlockInput.defaultProps = {
  initialValue: '',
};

DiyOpMainBlockInput.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,
  updateOperation: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
};

export default DiyOpMainBlockInput;
