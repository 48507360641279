import React from 'react';

import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';
import InfoBlock from 'client/components/common/info-block';

import ProfileAvatar from 'client/components/profile-avatar';

import './profile-admin.scss';

const ProfileAdmin = (props) => {
  const {
    avatarSrc = '',
    children,
    onEditPhotoClick,
    onEditClick,
    hideEditBtn,
    edit,
    lang,
    editPassword,
    isBlocked,
    onBlock,
    user,
  } = props;
  const isWoman = user?.title === 'Mme.';

  return (
    <InfoBlock className="profile-admin">
      <InfoBlock.Title>{lang.TITLE}</InfoBlock.Title>
      <InfoBlock.Content>
        <InfoBlock.Body>
          <div className="profile-admin__content">
            {!hideEditBtn && !edit && (
              <button
                className="button button--circle button--bg-1 profile-admin__edit-btn"
                disabled={editPassword}
                onClick={onEditClick}
              >
                <Icon name="edit" className="button__icon" width={20} height={20} />
              </button>
            )}
            <div className="profile-admin__header">
              <div className="profile-admin__avatar-col">
                <div className="profile-admin__avatar">
                  <ProfileAvatar isWoman={isWoman} onEditClick={onEditPhotoClick} src={avatarSrc} />
                </div>
              </div>
              <div className="profile-admin__blocking-col">
                <button className="button button--bg-1" type="button" onClick={onBlock}>
                  {isBlocked ? lang.UNBLOCK_USER : lang.BLOCK_USER}
                </button>
              </div>
            </div>
            {children}
          </div>
        </InfoBlock.Body>
      </InfoBlock.Content>
    </InfoBlock>
  );
};

ProfileAdmin.propTypes = {
  avatarSrc: PropTypes.string,
  children: PropTypes.any.isRequired,
  onEditPhotoClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onBlock: PropTypes.func.isRequired,
  hideEditBtn: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  editPassword: PropTypes.bool.isRequired,
  lang: PropTypes.object.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default ProfileAdmin;
