export const selectWinnerExportReports = (state) => state.winnerExport.exportReports;

export const selectWinnerExportReport = (state) => {
  return state.winnerExport.exportReport
    ? {
        ...state.winnerExport.exportReport,
        transfer_id: state.winnerExport.exportReport?.participation_export_id,
      }
    : null;
};
