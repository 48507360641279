import React from 'react';

import {useMount} from 'react-use';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons/app-button';

import './hubspot-product-fruit.scss';

const b = bem('hubspot-product-fruit');

const HubspotProductFruit = () => {
  useMount(() => {
    window.hsConversationsSettings = {
      loadImmediately: true,
      inlineEmbedSelector: '#hubspot-chat',
    };
    const hubspotHolder = document.getElementById('hubspot-chat-holder');
    window.addEventListener('productfruits_button_ext_widget_init', () => {
      if (hubspotHolder) {
        hubspotHolder.style.visibility = '';
      }
    });
  });

  const handleHubspotClose = () => {
    const hubspotHolder = document.getElementById('hubspot-chat-holder');
    hubspotHolder.style.visibility = 'hidden';
    window.productFruits.api.button.showButton();
  };

  return (
    <div id="hubspot-chat-holder" className={b()} style={{visibility: 'hidden'}}>
      <AppButton iconName="close" className={b('close')} onClick={handleHubspotClose} asWrap />
      <div id="hubspot-chat" className={b('hubspot-chat')} />
    </div>
  );
};

export default HubspotProductFruit;
