import React from 'react';

import PropTypes from 'prop-types';

import BcNavItem from '../../bc-nav-item';

function BcMailingNavItem(props) {
  const {languageState, checked, eventKey, count, ...restProps} = props;

  return (
    <BcNavItem
      title={<div className="break-word">{languageState.MAILING_LIST_NAV}</div>}
      name={
        checked && (
          <div className="break-word">
            {count} {languageState.LEADS_LABEL}
          </div>
        )
      }
      eventKey={eventKey}
      checked={checked}
      {...restProps}
    />
  );
}

BcMailingNavItem.propTypes = {
  languageState: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  eventKey: PropTypes.any.isRequired,
  count: PropTypes.number,
};

BcMailingNavItem.defaultProps = {
  checked: false,
  count: 0,
};

export default BcMailingNavItem;
