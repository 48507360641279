export default {
  GET_SCENARIO_EXECUTIONS_REQUEST: '@@scenario-tun-table/GET_SCENARIO_EXECUTIONS_REQUEST',
  GET_SCENARIO_EXECUTIONS_SUCCESS: '@@scenario-tun-table/GET_SCENARIO_EXECUTIONS_SUCCESS',
  GET_SCENARIO_EXECUTIONS_ERROR: '@@scenario-tun-table/GET_SCENARIO_EXECUTIONS_ERROR',

  GET_VARIABLES_REQUEST: '@@scenario-tun-table/GET_VARIABLES_REQUEST',
  GET_VARIABLES_SUCCESS: '@@scenario-tun-table/GET_VARIABLES_SUCCESS',
  GET_VARIABLES_ERROR: '@@scenario-tun-table/GET_VARIABLES_ERROR',

  CLEAR_SCENARIO_RUN_TABLE: '@@scenario-tun-table/CLEAR_SCENARIO_RUN_TABLE',
};
