import {DESTINATION_TYPES, COMPONENT_TYPES, IS_FIRST_VERSION} from './constants';
import * as rules from './rules';

export const createImplementation = () => ({
  type: 'CalculationScenarioStep',
});

const createComponent = (values, number) => {
  const type = values[`type${number}`];
  const valueText = values[`component_text${number}`];
  const valueDropdown = values[`component_dropdown${number}`];

  const component = {
    component_type: type,
    number,
  };

  if (type === COMPONENT_TYPES.FIX) {
    component.value = valueText;
  }

  if (type === COMPONENT_TYPES.VAR) {
    component.variable_id = valueDropdown;
  }

  return component;
};

const createOperation = (values, number) => {
  return {
    component_type: COMPONENT_TYPES.OPERATION,
    number,
    value: values[`operation${number}`] || null,
  };
};

export const mapFormData = (values, initialData) => {
  const data = {
    id: initialData.id,
    name: values.name,
    scenario_id: initialData.scenario_id,
    implementation_type: initialData.implementation_type,
    position: initialData.position,
    comment: values.comment,
  };

  const implementation = {
    id: initialData.implementation.id,
    type: initialData.implementation.type,
    destination_type: values.destination_type,
    consider_empty_values: values.consider_empty_values,
  };

  if (values.destination_type === DESTINATION_TYPES.VARIABLE) {
    implementation.variable_id = values.variable_id;
  }

  if (values.destination_type === DESTINATION_TYPES.DATABASE) {
    implementation.column_adapter_id = values.column_adapter_id;
  }

  const initialComponents = initialData.implementation.calculation_step_components || [];
  const components = [...initialComponents.map(({id}) => ({id, _destroy: true}))];

  for (let i = 1; i <= 4; i++) {
    components.push(createComponent(values, i, initialComponents));
  }

  for (let i = 1; i <= 3; i++) {
    components.push(createOperation(values, i, initialComponents));
  }

  implementation.calculation_step_components = components;
  data.implementation = implementation;

  return data;
};

const getInitialDatabase = (columnAdapter) => {
  if (!columnAdapter) {
    return null;
  }

  const name = columnAdapter.record_type || '';
  const number = columnAdapter.db_number || '';

  return `${name}${number}` || null;
};

const getInitialCalculationComponents = (data) => {
  const result = {};

  if (!data) {
    return result;
  }

  data.forEach((item) => {
    const {number} = item;

    if (item.component_type === COMPONENT_TYPES.OPERATION) {
      result[`operation${number}`] = item.value;
    } else {
      result[`type${number}`] = item.component_type;
      result[`component_text${number}`] = item.value;
      result[`component_dropdown${number}`] = item.variable_id;
    }
  });

  return result;
};

const getInitialDestinationType = (step) => {
  if (IS_FIRST_VERSION) {
    // In first version destination_type is always database
    return DESTINATION_TYPES.DATABASE;
  }

  return step.implementation.destination_type;
};

export const getInitialValues = (step) => {
  return {
    name: step.name,
    comment: step.comment,
    consider_empty_values: step.implementation.consider_empty_values || false,
    destination_type: getInitialDestinationType(step),
    variable_id: step.implementation.variable_id,
    column_adapter_id: step.implementation.column_adapter_id,
    database: getInitialDatabase(step.implementation.column_adapter),
    ...getInitialCalculationComponents(step.implementation.calculation_step_components),
  };
};

export const validate = (values, props) => {
  const {lang} = props;
  const errors = {};

  if (!values.name) {
    errors.name = lang.ERRORS.REQUIRED;
  }

  if (!values.destination_type) {
    errors.destination_type = lang.ERRORS.REQUIRED;
  }

  if (rules.showVariable(values) && !rules.isVariableSelected(values)) {
    errors.variable_id = lang.ERRORS.REQUIRED;
  }

  if (rules.showDatabase(values) && !rules.isDatabaseSelected(values)) {
    errors.database = lang.ERRORS.REQUIRED;
  }

  if (rules.isDatabaseSelected(values) && !rules.isColumnSelected(values)) {
    errors.column_adapter_id = lang.ERRORS.REQUIRED;
  }

  return errors;
};

export const submitForm = (values, dispatch, props) => {
  const mappedData = mapFormData(values, props.data);

  return props.onSubmitForm(mappedData);
};
