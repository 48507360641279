const mapInterfaceLevels = (obj, keys) =>
  obj
    ? keys.reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
      }, {})
    : {};

export const mapLinkedInterfaces = (scenario) => {
  if (!scenario) {
    return [];
  }
  const interfaceLevelKeys = ['number', 'level_name', 'id'];
  const linkedInterfaces =
    scenario && scenario.trigger_mapping_items
      ? scenario.trigger_mapping_items.map((v) => v.interface_item.interface)
      : [];
  const interfaceLevels = scenario && scenario.interface_levels ? scenario.interface_levels : [];
  const interfaceLevelsIds = interfaceLevels.map((level) => level.interface_id);

  const mappedlinkedInterfaces = linkedInterfaces
    .filter((v, i) => i <= linkedInterfaces.findIndex((vv) => vv.id === v.id))
    .map(({name, id}) => {
      const res = {
        name,
        id,
      };

      if (interfaceLevelsIds.includes(id)) {
        const interfaceLevel = scenario.interface_levels.find(({interface_id}) => interface_id === id);

        res.level = {...mapInterfaceLevels(interfaceLevel, interfaceLevelKeys)};
      }
      return res;
    });
  const mappedlinkedInterfacesNames = mappedlinkedInterfaces.map(({name}) => name);
  const mappedInterfaceLevels = interfaceLevels.reduce((acc, {interface_name, ...rest}) => {
    if (!mappedlinkedInterfacesNames.includes(interface_name)) {
      acc.push({
        name: interface_name,
        level: {
          number: rest[interfaceLevelKeys[0]],
          level_name: rest[interfaceLevelKeys[1]],
        },
        id: rest[interfaceLevelKeys[2]],
      });
    }
    return acc;
  }, []);

  return [...mappedlinkedInterfaces, ...mappedInterfaceLevels];
};
