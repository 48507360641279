import React, {useEffect} from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch, useReduxForm} from 'client/services/hooks';

import {getRegions} from 'client/ducks/client-users/actions';
import {selectClientAccessLevel, selectCurrentMembership} from 'client/ducks/user-clients/selectors';

import AwaitContainer from 'client/common/await-container';
import {USER_MEMBERSHIP} from 'client/common/config';
import {RadioGroupField, TextField} from 'client/common/fields';
import WarningLabel from 'client/common/typography/warning-label';

import {ClientUserModalFormName} from 'client/components/client-account-parameters/modals/client-user-modal';
import {ClientUserModalForm} from 'client/components/client-account-parameters/modals/client-user-modal/types';
import {Client} from 'client/models/client/types';
import {ACCESS_LEVEL} from 'client/models/common/constants';
import {AccessLevelType} from 'client/models/common/types';
import {Region} from 'client/models/regions/types';

import ClientUserTitle from '../client-user-title';

import cssModule from './client-user-membership-step.module.scss';

const b = bem('client-user-membership-step', {cssModule});

type ClientUserMembershipStepProps = {
  client: Client;
};

const ClientUserMembershipStep: React.FC<ClientUserMembershipStepProps> = ({client}) => {
  const lang = useLanguage('CLIENT_ACCOUNT_PARAMETERS.MODALS.CLIENT_USER');
  const langCommon = useLanguage('COMMON');

  const {formValues, change} = useReduxForm<ClientUserModalForm>(ClientUserModalFormName);
  const accessLevel = useSelector(selectClientAccessLevel);
  const isClientAdmin = accessLevel === USER_MEMBERSHIP.CLIENT_ADMIN;

  const currentMembership = useSelector(selectCurrentMembership);
  const membership = formValues.memberships?.[0];
  const isCurrentUser =
    currentMembership?.client_user_id && membership?.client_user_id === currentMembership?.client_user_id;

  const {data, loading} = useReduxFetch<{regions: Region[]}>({
    action: getRegions,
    actionArgs: [
      client.id,
      {
        include: ['places'],
        q: {
          client_id_eq: client.id,
        },
      },
    ],
  });

  useEffect(() => {
    if (
      !formValues.memberships?.[0]?.access_level &&
      ([ACCESS_LEVEL.CLIENT_ADMIN, ACCESS_LEVEL.NATIONAL] as AccessLevelType[]).includes(accessLevel)
    ) {
      change('memberships[0].access_level', USER_MEMBERSHIP.NATIONAL);
    }
  }, [accessLevel, change, formValues.memberships]);

  const handleChangeAccessLevel = () => {
    change('memberships[0].region_accesses', []);
    change('memberships[0].place_accesses', []);
  };

  return (
    <AwaitContainer loading={loading} className={b()}>
      <ClientUserTitle
        email={formValues.email}
        civility={formValues.title}
        firstName={formValues.first_name}
        lastName={formValues.last_name}
      />
      <TextField label={lang.TITLE_LABEL} name="memberships[0].title" />
      <TextField label={lang.PHONE_LABEL} name="memberships[0].phone" />
      <p className={b('block-title')}>
        {lang.ACCESS_LEVEL}
        {isCurrentUser && <WarningLabel label={lang.ERRORS.CANT_MODIFY} />}
      </p>
      <RadioGroupField
        className={b('options')}
        name="memberships[0].access_level"
        disabled={isCurrentUser}
        onChange={handleChangeAccessLevel}
        options={[
          {
            label: langCommon.ACCESS_LEVEL.NATIONAL,
            exclude: !(
              [ACCESS_LEVEL.CLIENT_ADMIN, ACCESS_LEVEL.NATIONAL, USER_MEMBERSHIP.NATIONAL] as AccessLevelType[]
            ).includes(accessLevel),
            value: USER_MEMBERSHIP.NATIONAL,
          },
          {
            exclude:
              !data?.regions?.length ||
              ([ACCESS_LEVEL.STORE, ACCESS_LEVEL.PLACES, ACCESS_LEVEL.LOCAL] as AccessLevelType[]).includes(
                accessLevel,
              ),
            label: langCommon.ACCESS_LEVEL.REGION,
            value: USER_MEMBERSHIP.REGION,
          },
          {
            exclude:
              !data?.regions?.length ||
              !client.client_network ||
              ([ACCESS_LEVEL.LOCAL] as AccessLevelType[]).includes(accessLevel),
            label: langCommon.ACCESS_LEVEL.STORE,
            value: USER_MEMBERSHIP.STORE,
          },
          {exclude: !isClientAdmin, label: langCommon.ACCESS_LEVEL.CLIENT_ADMIN, value: USER_MEMBERSHIP.CLIENT_ADMIN},
        ]}
      />
    </AwaitContainer>
  );
};

export default ClientUserMembershipStep;
