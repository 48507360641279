export const getFetchParams = (agencyId: number, currentLanguage: string, queryParams: Record<string, string>) => {
  const {search, page, perPage} = queryParams;
  return {
    include_company_allowed_to_destroy: null,
    include_client_logo_url: null,
    [`include_membership_client_user_full_name_with_title_${currentLanguage}`]: null,
    include: {
      poc_membership: null,
      poc_agency_membership: null,
      category: null,
    },
    q: {
      agency_id_eq: agencyId,
      s: ['name asc', 'brand_name asc'],
      g: {
        search: {
          m: 'or',
          name_cont: search,
          brand_name_cont: search,
          [`poc_membership_client_user_full_name_with_title_${currentLanguage}_cont`]: search,
          [`poc_agency_membership_client_user_full_name_with_title_${currentLanguage}_cont`]: search,
        },
      },
    },
    page,
    per_page: perPage,
  };
};
