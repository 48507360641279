import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';

import cssModule from './catalog-templates-no-access-panel.module.scss';

const b = bem('catalog-templates-no-access-panel', {cssModule});

const CatalogTemplatesNoAccessPanel = () => {
  const lang = useLanguage('CATALOG_TEMPLATES.PANELS.NO_ACCESS_PANEL');

  return (
    <div className={b()}>
      <Icon name="empty-state" className={b('icon')} />
      <span className={b('no-records')}>{lang.NO_ACCESS_TEXT}</span>
    </div>
  );
};

export default CatalogTemplatesNoAccessPanel;
