import React from 'react';

import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import InfoBlock from 'client/components/common/info-block';

import {TranslationJsx} from 'client/models/language/types';

import './ats-linked-card.scss';

function AtsLinkedCard({data, title, header, path, link}) {
  return (
    <InfoBlock className="ats-linked-card">
      <InfoBlock.Title>{title}</InfoBlock.Title>
      <InfoBlock.Content>
        <InfoBlock.Header>{header}</InfoBlock.Header>
        <InfoBlock.Body limitedHeight={true} expandRight={true}>
          <CustomScrollbars
            scrollbarProps={{
              autoHeightMax: 300,
            }}
          >
            <div className="ats-linked-card__inner">
              {data.map((item, i) => {
                const linkTo = link.length ? link : `${path}/${item.id}`;
                return (
                  <div className="ats-linked-card__item" key={i}>
                    <Link to={linkTo} className="link main-text">
                      {item.name}
                    </Link>
                    <span>{item.level && `${item.level.number} - ${item.level.level_name}`}</span>
                  </div>
                );
              })}
            </div>
          </CustomScrollbars>
        </InfoBlock.Body>
      </InfoBlock.Content>
    </InfoBlock>
  );
}

AtsLinkedCard.defaultProps = {
  data: [{name: '', link: ''}],
  title: '',
  header: '',
  link: '',
  path: '',
};

AtsLinkedCard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  title: TranslationJsx,
  header: PropTypes.any,
  path: PropTypes.string,
  link: PropTypes.string,
};

export default AtsLinkedCard;
