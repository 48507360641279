export default {
  NO_DATA: '@@lead/NO_DATA',
  EDIT_LEAD_INFO_REQUEST: '@@lead/EDIT_LEAD_INFO_REQUEST',
  EDIT_LEAD_INFO_SUCCESS: '@@lead/EDIT_LEAD_INFO_SUCCESS',
  EDIT_LEAD_INFO_ERROR: '@@lead/EDIT_LEAD_INFO_ERROR',
  LEAD_PROFILE_REQUEST: '@@lead/LEAD_PROFILE_REQUEST',
  LEAD_PROFILE_SUCCESS: '@@lead/LEAD_PROFILE_SUCCESS',
  LEAD_PROFILE_ERROR: '@@lead/LEAD_PROFILE_ERROR',
  UPDATING: '@@lead-histories/UPDATING',
  UPDATED: '@@lead-histories/UPDATED',
  UPDATE_FAILED: '@@lead-histories/UPDATE_FAILED',

  DELETE_LEAD_PROFILE_REQUEST: '@@lead-profile/DELETE_LEAD_PROFILE_REQUEST',
  DELETE_LEAD_PROFILE_SUCCESS: '@@lead-profile/DELETE_LEAD_PROFILE_SUCCESS',
  DELETE_LEAD_PROFILE_ERROR: '@@lead-profile/DELETE_LEAD_PROFILE_ERROR',

  ANONYMIZE_LEAD_PROFILE_REQUEST: '@@lead-profile/ANONYMIZE_LEAD_PROFILE_REQUEST',
  ANONYMIZE_LEAD_PROFILE_SUCCESS: '@@lead-profile/ANONYMIZE_LEAD_PROFILE_SUCCESS',
  ANONYMIZE_LEAD_PROFILE_ERROR: '@@lead-profile/ANONYMIZE_LEAD_PROFILE_ERROR',

  LEAD_IMPORT_FILE_REQUEST: '@@lead-import/LEAD_IMPORT_FILE_REQUEST',
  LEAD_IMPORT_FILE_SUCCESS: '@@lead-import/LEAD_IMPORT_FILE_SUCCESS',
  LEAD_IMPORT_FILE_ERROR: '@@lead-import/LEAD_IMPORT_FILE_ERROR',

  LEAD_GENERATE_FILE_REQUEST: '@@lead-import/LEAD_GENERATE_FILE_REQUEST',
  LEAD_GENERATE_FILE_SUCCESS: '@@lead-import/LEAD_GENERATE_FILE_SUCCESS',
  LEAD_GENERATE_FILE_ERROR: '@@lead-import/LEAD_GENERATE_FILE_ERROR',

  GET_IMPORT_FILE_REQUEST: '@@lead-import/GET_IMPORT_FILE_REQUEST',
  GET_IMPORT_FILE_SUCCESS: '@@lead-import/GET_IMPORT_FILE_SUCCESS',
  GET_IMPORT_FILE_ERROR: '@@lead-import/GET_IMPORT_FILE_ERROR',

  IMPORT_FILE_REQUEST: '@@lead-import/IMPORT_FILE_REQUEST',
  IMPORT_FILE_SUCCESS: '@@lead-import/IMPORT_FILE_SUCCESS',
  IMPORT_FILE_ERROR: '@@lead-import/IMPORT_FILE_ERROR',

  GET_LEADS_REPORTS_REQUEST: '@@leads-import/GET_LEADS_REPORTS_REQUEST',
  GET_LEADS_REPORTS_SUCCESS: '@@leads-import/GET_LEADS_REPORTS_SUCCESS',
  GET_LEADS_REPORTS_ERROR: '@@leads-import/GET_LEADS_REPORTS_ERROR',

  LEAD_GENERATE_API_REQUEST: '@@lead-import/LEAD_GENERATE_API_REQUEST',
  LEAD_GENERATE_API_SUCCESS: '@@lead-import/LEAD_GENERATE_API_SUCCESS',
  LEAD_GENERATE_API_ERROR: '@@lead-import/LEAD_GENERATE_API_ERROR',
};
