export const formatSegmentation = ({device, online}, lang) => {
  if (device && online) {
    return lang.BOTH;
  }

  if (device) {
    return lang.DEVICE;
  }

  if (online) {
    return lang.ONLINE;
  }

  return lang.NOTHING;
};
