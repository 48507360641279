import React, {useCallback, useEffect, useMemo} from 'react';

import upperFirst from 'lodash/upperFirst';
import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {getMemberships} from 'client/ducks/client-users/actions';
import {interpolate} from 'client/ducks/language/helpers';
import {selectColumnVisibilityItems} from 'client/ducks/lead-display-items/selectors';
import {selectIsAdmin, selectViewMode} from 'client/ducks/user/selectors';

import AppButton from 'client/common/buttons';
import {USER_MEMBERSHIP} from 'client/common/config';
import {RadioGroupField, TextField, TextareaField, SelectField} from 'client/common/fields';
import {useToast} from 'client/common/hooks/useToast';
import {CheckList, RadioGroupInput} from 'client/common/inputs';
import Modal from 'client/common/modals/modal';

import {DataLeadsFilters} from 'client/components/client-autotask/tabs/data-tab/leads-filters-modal/types';
import {ExportReport} from 'client/models/data-exports/types';
import {Membership} from 'client/models/memberships/types';
import {ApiAction} from 'client/types';

import {FILE_OPTIONS} from './constants';
import {mappingLeadFilters, validate} from './helpers';

import cssModule from './leads-generate-file-modal.module.scss';

const b = bem('leads-generate-file-modal', {cssModule});

export type LeadsGenerationExportFilters = Partial<DataLeadsFilters> & {
  search?: string;
} & Record<string, any>;

const MAX_RECORDS_FOR_XLSX = 900000;

type LeadsGenerateFileModalProps = {
  onClose: () => void;
  filters: LeadsGenerationExportFilters;
  actionGenerate: ApiAction;
  actionPerform: ApiAction;
  total: number;
  clientId: number;
};

export type LeadsGenerateFileFormValues = {
  name: string;
  comment?: string;
  allowed_for_all: boolean;
  format: typeof FILE_OPTIONS.CSV | typeof FILE_OPTIONS.XLSX;
  columns: string[];
  memberships: string[];
};

const formName = 'LeadsGenerateFileForm';

const LeadsGenerateFileModal: ReduxFormFC<LeadsGenerateFileModalProps, LeadsGenerateFileFormValues> = ({
  onClose,
  handleSubmit,
  filters,
  actionGenerate,
  actionPerform,
  total,
  clientId,
}) => {
  const lang = useLanguage('LEADS_DATABASE.MODALS.GENERATE_FILE_MODAL');
  const langCommonExportFile = useLanguage('GENERATING_EXPORT_FILE');

  const autotask = useSelector(selectAutotask);
  const {appendToastNotification} = useToast();
  const [loadingReport, toggleLoadingReport] = useToggle(false);
  const admin = useSelector(selectIsAdmin);
  const viewMode = useSelector(selectViewMode);

  const columns = useSelector(selectColumnVisibilityItems);

  const {invalid, formValues, change, registerField} = useReduxForm<LeadsGenerateFileFormValues>(formName, {
    initialValues: {format: FILE_OPTIONS.CSV, allowed_for_all: true},
    validate: (values) => validate(values, lang),
  });

  const {data, loading: loadingMemberships} = useReduxFetch<{memberships: Membership[]}>({
    action: getMemberships,
    actionArgs: {
      include_membership_client_user_full_name: null,
      q: {
        client_id_eq: clientId,
        access_level_eq: USER_MEMBERSHIP.CLIENT_ADMIN,
      },
    },
  });

  const {fetch: fetchGenerate, loading: loadingGenerate} = useReduxFetch<Record<string, ExportReport>>({
    action: actionGenerate,
    fetchOnMount: false,
  });

  const {fetch: fetchPerform, loading: loadingPerform} = useReduxFetch<Record<string, ExportReport>>({
    action: actionPerform,
    fetchOnMount: false,
  });

  const columnsOptions = useMemo(
    () =>
      columns
        .filter(
          ({column_adapter}) =>
            column_adapter?.type !== 'AssociationColumnAdapter' &&
            !column_adapter?.array &&
            column_adapter?.kind !== 'file',
        )
        .map((column) => {
          return {
            value: column.column_adapter_id?.toString(),
            label:
              lang.COLUMNS[`${column.column_adapter?.name.toUpperCase()}` as keyof typeof lang.COLUMNS] ||
              upperFirst(column.column_adapter?.name.replaceAll('_', ' ')),
          };
        }),
    [columns, lang],
  );

  useEffect(() => {
    registerField('columns');
  }, [registerField]);

  const onSubmit = async (values: LeadsGenerateFileFormValues) => {
    const exportField = 'file_lead_export';
    const fileName = `${values.name}.${values.format}`;
    const title = interpolate(langCommonExportFile.NOTIFICATION_TITLE?.toString(), {
      fileName,
    });

    const parametersForExport = mappingLeadFilters(filters || {});
    parametersForExport.query_params.participations_automation_task_id_eq = autotask.id;

    const body: Record<string, any> = {
      ...values,
      lead_transfer_items: values.columns?.map((column) => ({
        type: 'LeadExportItem',
        column_adapter_id: +column,
      })),
      transferable_memberships: values.memberships?.map((membership) => ({
        membership_id: +membership,
      })),
      client_id: clientId,
      automation_task_id: autotask.id,
      parameters_for_export: parametersForExport,
    };
    delete body.columns;
    delete body.memberships;

    if (!admin) {
      body.client_side = true;
    }

    try {
      toggleLoadingReport();

      const exportResponse = await fetchGenerate(
        {
          [exportField]: body,
        },
        {...(viewMode.on && {fake_client_user_id: viewMode.id})},
      );

      await fetchPerform(exportResponse.payload[exportField].id, {
        initial_total: total,
        ...(viewMode.on && {fake_client_user_id: viewMode.id}),
      });

      appendToastNotification({
        type: 'success',
        title,
        description: langCommonExportFile.NOTIFICATION_IN_PROCESS,
      });
      onClose();
    } catch (e) {
      console.error(e);
    }
    toggleLoadingReport();
  };

  const membershipOptions = useMemo(
    () =>
      data?.memberships?.map((membership) => ({
        value: membership.id?.toString(),
        label: membership.client_user_full_name,
      })) || [],
    [data?.memberships],
  );

  const formatOptions = [{value: FILE_OPTIONS.CSV, label: FILE_OPTIONS.CSV.toUpperCase()}];
  if (total <= MAX_RECORDS_FOR_XLSX) {
    formatOptions.push({value: FILE_OPTIONS.XLSX, label: FILE_OPTIONS.XLSX.toUpperCase()});
  }

  const handleChangeUserSelections: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const value = e.target.value === 'true';
      change('allowed_for_all', value);
      if (value) {
        change('memberships', []);
      }
    },
    [change],
  );

  return (
    <Modal
      onClose={onClose}
      className={b()}
      title={lang.GENERATE_FILE}
      titleButton={
        <div className={b('buttons')}>
          <AppButton label={lang.CANCEL_BUTTON} transparent onClick={onClose} />

          <AppButton
            label={lang.GENERATE_BUTTON}
            disabled={invalid}
            onClick={handleSubmit(onSubmit)}
            className={b('button', ['submit'])}
            loading={loadingGenerate || loadingPerform || loadingReport}
          />
        </div>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={b('form-items')}>
          <TextField label={lang.FILE_NAME} name="name" required={true} />
          <div className={b('section')}>
            <h4 className={b('section-title')}>{lang.COLUMNS_SECTION}</h4>
            <CheckList
              options={columnsOptions}
              bordered
              className={b('check-list')}
              onChange={(nextState) => change('columns', nextState)}
              checked={formValues.columns || []}
              inversionColor
              color="primary"
              maxHeight={231}
              allOption
              allOptionLabel={lang.ALL_COLUMNS}
            />
          </div>
          <div className={b('section')}>
            <h4 className={b('section-title')}>{lang.USERS_SECTION}</h4>
            <RadioGroupInput
              name="allowed_for_all"
              direction="horizontal"
              className={b('radio-group')}
              value={formValues.allowed_for_all?.toString()}
              options={[
                {label: lang.ALL_USERS, value: 'true'},
                {label: lang.SELECT_USERS, value: 'false'},
              ]}
              onChange={handleChangeUserSelections}
            />
            {!formValues.allowed_for_all && (
              <SelectField
                name="memberships"
                options={membershipOptions}
                className={b('memberships')}
                label={lang.USER_DROPDOWN}
                isLoading={loadingMemberships}
                closeMenuOnSelect={false}
                multi
                simpleValue
                required
              />
            )}
          </div>
          <div className={b('section')}>
            <h4 className={b('section-title')}>{lang.FORMAT_SECTION}</h4>
            <RadioGroupField
              name="format"
              direction="horizontal"
              className={b('radio-group')}
              options={formatOptions}
            />
          </div>
          <TextareaField label={lang.COMMENT} name="comment" />
        </div>
      </form>
    </Modal>
  );
};

export default reduxForm<LeadsGenerateFileModalProps, LeadsGenerateFileFormValues>({form: formName})(
  LeadsGenerateFileModal,
);
