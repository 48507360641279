export function mapFilter(data) {
  const obj = {...data};

  ['operation_in', 'group_in', 'task_in'].forEach((key) => {
    if (!Array.isArray(obj[key]) || obj[key].length === 0) {
      delete obj[key];
    } else {
      obj[key] = obj[key].map((item) => ({
        ...item,
        name: encodeURIComponent(item.name),
      }));
    }
  });

  return obj;
}
