import React from 'react';

import PropTypes from 'prop-types';

import GridView from 'client/components/common/grid-view';
import PostPreview from 'client/components/common/post-preview';

import {TranslationJsx} from 'client/models/language/types';

import './vis-grid-view.scss';

function VisGridView({
  toolbar,
  meta,
  data,
  previewSize,
  onPreviewClick,
  onPageChange,
  onSelect,
  updateMe,
  noDataMessage,
  selectedIds,
  isAdmin,
  visualsType,
  diaporamaValues,
  isColAdapterInDiaporama,
  getArrivingType,
  getVisualOrDiaporamaValue,
}) {
  return (
    <div className="vis-grid-view">
      <div>{toolbar}</div>
      {data.length > 0 ? (
        <GridView
          className="vis-grid-view__grid"
          paginationParams={{
            currentPage: meta.current_page,
            totalPages: meta.total_pages,
            onPageChange,
          }}
        >
          {data.map((visual, i) => {
            return (
              <div key={i} className="grid-view__item" style={previewSize}>
                <PostPreview
                  type={visualsType}
                  flexible={true}
                  visual={visual}
                  isAdmin={isAdmin}
                  checkboxName={`check-${i}`}
                  checked={selectedIds.includes(visual.id)}
                  onSelect={onSelect}
                  onPreviewClick={onPreviewClick}
                  updateMe={updateMe}
                  diaporamaValues={diaporamaValues}
                  isColAdapterInDiaporama={isColAdapterInDiaporama}
                  getArrivingType={getArrivingType}
                  getVisualOrDiaporamaValue={getVisualOrDiaporamaValue}
                />
              </div>
            );
          })}
        </GridView>
      ) : (
        <div className="text-center">{noDataMessage}</div>
      )}
    </div>
  );
}

VisGridView.defaultProps = {
  toolbar: null,
  meta: {},
  previewSize: {},
  onPageChange: () => {},
};

VisGridView.propTypes = {
  visualsType: PropTypes.string.isRequired,
  toolbar: PropTypes.any,
  meta: PropTypes.object,
  data: PropTypes.array.isRequired,
  selectedIds: PropTypes.array.isRequired,
  noDataMessage: TranslationJsx,
  previewSize: PropTypes.object,
  onPreviewClick: PropTypes.func.isRequired,
  updateMe: PropTypes.func,
  onPageChange: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  diaporamaValues: PropTypes.object.isRequired,
  isColAdapterInDiaporama: PropTypes.func.isRequired,
  getArrivingType: PropTypes.func.isRequired,
  getVisualOrDiaporamaValue: PropTypes.func.isRequired,
};

export default VisGridView;
