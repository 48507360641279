import React, {useCallback, useMemo, useRef, useState} from 'react';

import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {useMount} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Card from 'client/common/card';
import {CLIENT_PAGES} from 'client/common/config';
import Popover from 'client/common/popovers/popover';
import Tag from 'client/common/tag';

import OpsTasksModal from 'client/components/operations-client/components/modals/operations-tasks-modal';
import {generateClientOperationRoute} from 'client/models/operations/helpers';

import './operation-card.scss';

const b = bem('operation-card');

const tagColor = {
  national: 'primary',
  region: 'participants',
};

const OperationCard = (props) => {
  const {
    className,
    isSelected,
    onSelectOperation,
    operation,
    tag,
    additionalInfo,
    history,
    redirectToConfigTab,
    isWide,
    clientName,
  } = props;

  const {
    name,
    operation_image,
    automation_tasks,
    instore_tasks,
    from: dateStart,
    to: dateEnd,
    id: operationId,
    client_id,
  } = operation;

  const lang = useLanguage('OPERATIONS');

  const [isTasksModalVisible, setIsTasksModalVisible] = useState(false);

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const infoBoxRef = useRef(null);

  useMount(() => {
    const boxElement = infoBoxRef.current;

    // 2px is added for safety
    if (boxElement && boxElement.scrollHeight > boxElement.clientHeight + 2) {
      setIsPopoverVisible(true);
    }
  });

  const displayTasks = useMemo(() => {
    return [...(isWide ? automation_tasks : []), ...instore_tasks];
  }, [automation_tasks, instore_tasks, isWide]);

  const getLink = useCallback(
    (task) => {
      return generateClientOperationRoute({
        operation,
        task,
        isConfigurationBlock: !!redirectToConfigTab,
        clientId: client_id,
      });
    },
    [operation, redirectToConfigTab, client_id],
  );

  const onSelect = useCallback(
    (e, selectedTask) => {
      if (displayTasks.length) {
        const task = selectedTask || displayTasks[0];

        let link = '';

        if (displayTasks.length > 1) {
          setIsTasksModalVisible(true);
          if (isTasksModalVisible) {
            link = getLink(task);
          }
        } else {
          link = getLink(task);
        }

        if (link) {
          onSelectOperation(operationId);
          history.push(link);
        }
      }
    },
    [displayTasks, isTasksModalVisible, getLink, onSelectOperation, operationId, history],
  );

  const hasTasks = displayTasks.length > 1;

  return (
    <>
      <Card
        className={cn(b(), className)}
        classNames={{
          imageWrapper: b('image-wrapper'),
          image: b('image'),
        }}
        isSelected={isSelected}
        hasTasks={hasTasks}
        onSelect={onSelect}
        src={operation_image?.url}
        submit
      >
        <div className={b('info')}>
          <p className={b('title')}>{name}</p>
          <div className={b('date')}>
            <div className={b('date-start')}>
              <p className={b('date-title')}>{lang.START_DATE}</p>
              <p className={b('date-value')}>{moment(dateStart).format('DD/MM/YYYY')}</p>
            </div>
            <div className={b('date-end')}>
              <p className={b('date-title')}>{lang.END_DATE}</p>
              <p className={b('date-value')}>{moment(dateEnd).format('DD/MM/YYYY')}</p>
            </div>
          </div>
          {/* TODO: add props animations */}
          {/* <p className={b('animations')}>{} {lang.ANIMATIONS}</p> */}
        </div>
        {(tag || additionalInfo || clientName) && (
          <div className={b('footer')}>
            {tag && <Tag text={tag} iconName={tag} color={tagColor[tag]} />}
            {additionalInfo && (
              <Popover
                overlay={additionalInfo}
                position="right"
                show={isPopoverVisible}
                className={b('popover')}
                triggerClassName={b('trigger')}
              >
                <div ref={infoBoxRef} className={b('additional-info-box')}>
                  {additionalInfo}
                </div>
              </Popover>
            )}
            {clientName && <span className={b('client-chip')}>{clientName}</span>}
          </div>
        )}
      </Card>
      {isTasksModalVisible && (
        <OpsTasksModal
          show={isTasksModalVisible}
          tasks={displayTasks}
          onClose={() => setIsTasksModalVisible(false)}
          onSelect={onSelect}
          dateStart={dateStart}
          dateEnd={dateEnd}
        />
      )}
    </>
  );
};

OperationCard.defaultProps = {
  isSelected: false,
  tag: '',
  additionalInfo: '',
  targetTab: CLIENT_PAGES.CLIENT_AUTOTASK_TABS.PARTICIPATIONS,
  isWide: false,
  clientName: null,
};

OperationCard.propTypes = {
  isSelected: PropTypes.bool,
  onSelectOperation: PropTypes.func.isRequired,
  className: PropTypes.string,
  operation: PropTypes.shape({
    name: PropTypes.string.isRequired,
    client_id: PropTypes.number.isRequired,
    operation_image: PropTypes.shape({
      url: PropTypes.string,
    }),
    id: PropTypes.number.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    automation_tasks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        image: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    ),
    instore_tasks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        image: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    ),
    show_configuration: PropTypes.bool,
    client_automation_task_id: PropTypes.number,
  }).isRequired,
  tag: PropTypes.string,
  additionalInfo: PropTypes.string,
  redirectToConfigTab: PropTypes.bool,

  // from react-router
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  isWide: PropTypes.bool,
  clientName: PropTypes.string,
};

export default withRouter(OperationCard);
