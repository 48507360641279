import {WINNING_SEARCH_TYPES} from './constants';
import {WinningSearchValues} from './types';

export default (): WinningSearchValues => {
  return {
    search_type: WINNING_SEARCH_TYPES.EMAIL,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    token: '',
    loyalty_identifier: '',
  };
};
