// components
import React, {Component} from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {CLIENT_PAGES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';

import './templates-card-data-grid.scss';

let LANGUAGE = {};

function NameCell({data, rowIndex, ...props}) {
  const template = data[rowIndex];

  const {clientType, clientId, operationId, ...restProps} = props;

  const clientLink = CustomLink.createClientLink({clientType, clientId});
  const link = `${clientLink}${CLIENT_PAGES.OPERATIONS}/${operationId}${CLIENT_PAGES.TEMPLATES}/${template.id}`;

  return (
    <Cell {...restProps}>
      <CustomLink to={link} className="link ellipsis-text ellipsis-cell" title={template.name}>
        {template.name}
      </CustomLink>
    </Cell>
  );
}

NameCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
  clientType: PropTypes.string,
  clientId: PropTypes.number,
  operationId: PropTypes.number,
};

function IdCell({data, rowIndex, field, ...props}) {
  return (
    <Cell {...props}>
      <div className="ellipsis-cell ellipsis-text" title={data[rowIndex][field]}>
        {data[rowIndex][field]}
      </div>
    </Cell>
  );
}
IdCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

class TemplatesCardDataGrid extends Component {
  constructor(props) {
    super(props);

    LANGUAGE = props.languageState.payload.OPERATION_PAGE;
  }

  render() {
    const {data, clientId, clientType, operationId, ...restProps} = this.props;
    return (
      <div
        style={{paddingTop: '6px'}}
        className="fixed-table fixed-table--expand-1 fixed-table--templates-card theme-color-templates"
      >
        <Table rowsCount={data.length} headerHeight={40} rowHeight={34} width={230} height={250} {...restProps}>
          <Column
            header={<Cell>{LANGUAGE.TEMPLATES.NAME_COLUMN}</Cell>}
            cell={<NameCell data={data} clientId={clientId} clientType={clientType} operationId={operationId} />}
            width={100}
          />
          <Column
            header={<Cell>{LANGUAGE.TEMPLATES.ID_COLUMN}</Cell>}
            cell={<IdCell data={data} field="mailjet_id" />}
            width={100}
          />
        </Table>
      </div>
    );
  }
}

TemplatesCardDataGrid.propTypes = {
  data: PropTypes.array,
  languageState: PropTypes.object.isRequired,
  clientType: PropTypes.string.isRequired,
  clientId: PropTypes.number.isRequired,
  operationId: PropTypes.number.isRequired,
};

TemplatesCardDataGrid.defaultProps = {
  data: [],
};

export default connect(({languageState}) => ({languageState}))(TemplatesCardDataGrid);
