import {transformDate} from 'client/services/helpers';

export default (clientId, data) => {
  return {
    operation: {
      ...data,
      common_retention_years: parseInt(data.common_retention_years, 10),
      common_retention_months: parseInt(data.common_retention_months, 10),
      leads_retention_years: parseInt(data.leads_retention_years, 10),
      leads_retention_months: parseInt(data.leads_retention_months, 10),
      from: transformDate(data.from, false),
      to: transformDate(data.to, false),
      status: 'active',
      client_id: clientId,
    },
  };
};
