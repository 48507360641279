export default {
  GET_LEADS_IMPORT_REPORT_REQUEST: '@@leads-import-report/GET_LEADS_IMPORT_REPORT_REQUEST',
  GET_LEADS_IMPORT_REPORT_SUCCESS: '@@leads-import-report/GET_LEADS_IMPORT_REPORT_SUCCESS',
  GET_LEADS_IMPORT_REPORT_ERROR: '@@leads-import-report/GET_LEADS_IMPORT_REPORT_ERROR',

  GET_LEADS_API_IMPORT_REPORT_REQUEST: '@@leads-import-report/GET_LEADS_API_IMPORT_REPORT_REQUEST',
  GET_LEADS_API_IMPORT_REPORT_SUCCESS: '@@leads-import-report/GET_LEADS_API_IMPORT_REPORT_SUCCESS',
  GET_LEADS_API_IMPORT_REPORT_ERROR: '@@leads-import-report/GET_LEADS_API_IMPORT_REPORT_ERROR',

  GET_IMPORTED_LEADS_REQUEST: '@@leads-import/GET_IMPORTED_LEADS_REQUEST',
  GET_IMPORTED_LEADS_SUCCESS: '@@leads-import/GET_IMPORTED_LEADS_SUCCESS',
  GET_IMPORTED_LEADS_ERROR: '@@leads-import/GET_IMPORTED_LEADS_ERROR',

  DELETE_API_LEADS_IMPORT_REQUEST: '@@leads-import/DELETE_API_LEADS_IMPORT_REQUEST',
  DELETE_API_LEADS_IMPORT_SUCCESS: '@@leads-import/DELETE_API_LEADS_IMPORT_SUCCESS',
  DELETE_API_LEADS_IMPORT_ERROR: '@@leads-import/DELETE_API_LEADS_IMPORT_ERROR',

  GET_LEADS_IMPORT_REPORTS_REQUEST: '@@leads-import-report/GET_LEADS_IMPORT_REPORTS_REQUEST',
  GET_LEADS_IMPORT_REPORTS_SUCCESS: '@@leads-import-report/GET_LEADS_IMPORT_REPORTS_SUCCESS',
  GET_LEADS_IMPORT_REPORTS_ERROR: '@@leads-import-report/GET_LEADS_IMPORT_REPORTS_ERROR',

  GET_API_LEAD_IMPORTS_REQUEST: '@@leads-import-report/GET_API_LEAD_IMPORTS_REQUEST',
  GET_API_LEAD_IMPORTS_SUCCESS: '@@leads-import-report/GET_API_LEAD_IMPORTS_SUCCESS',
  GET_API_LEAD_IMPORTS_ERROR: '@@leads-import-report/GET_API_LEAD_IMPORTS_ERROR',
};
