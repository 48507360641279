import {callApi} from 'client/services/call-api';
import {getEmail, getToken} from 'client/services/cookie-data-source';

import {API_METHODS, API_PATH} from 'client/common/config';

import types from './types';

export const getInstoreTask = (id, queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_TASKS}/${id}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_INSTORE_TASK_REQUEST, types.GET_INSTORE_TASK_SUCCESS, types.GET_INSTORE_TASK_FAILED],
      queryParams,
    });
};

export const clearInstoreTask = () => {
  return (dispatch) => dispatch({type: types.GET_INSTORE_TASK_SUCCESS});
};

export const getTaskNetworks = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_TASKS_NETWORKS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_TASK_NETWORKS_REQUEST, types.GET_TASK_NETWORKS_SUCCESS, types.GET_TASK_NETWORKS_FAILED],
      queryParams,
    });
};

export const getInstoreTaskAccesses = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_TASKS_ACCESSES}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_INSTORE_TASK_ACCESSES_REQUEST,
        types.GET_INSTORE_TASK_ACCESSES_SUCCESS,
        types.GET_INSTORE_TASK_ACCESSES_FAILED,
      ],
      queryParams,
    });
};

export const getInstoreTaskPositions = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_TASKS_POSITIONS}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_INSTORE_TASK_POSITIONS_REQUEST,
        types.GET_INSTORE_TASK_POSITIONS_SUCCESS,
        types.GET_INSTORE_TASK_POSITIONS_FAILED,
      ],
      queryParams,
    });
};

export const getInstoreTaskKits = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_TASKS_KITS}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_INSTORE_TASK_KITS_REQUEST,
        types.GET_INSTORE_TASK_KITS_SUCCESS,
        types.GET_INSTORE_TASK_KITS_FAILED,
      ],
      queryParams,
    });
};

export const getInstoreTaskKitMappings = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_TASKS_KIT_MAPPINGS}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_INSTORE_TASK_KIT_MAPPINGS_REQUEST,
        types.GET_INSTORE_TASK_KIT_MAPPINGS_SUCCESS,
        types.GET_INSTORE_TASK_KIT_MAPPINGS_FAILED,
      ],
      queryParams,
    });
};

export const getInstoreTaskCampaigns = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}/interaction_groups`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_INSTORE_TASK_CAMPAIGNS_REQUEST,
        types.GET_INSTORE_TASK_CAMPAIGNS_SUCCESS,
        types.GET_INSTORE_TASK_CAMPAIGNS_FAILED,
      ],
      queryParams,
    });
};

export const clearInstoreTaskCampaigns = () => {
  return (dispatch) => dispatch({type: types.GET_INSTORE_TASK_CAMPAIGNS_SUCCESS});
};

// params "from" and "to" are required
export const getInstoreTaskKitAvailableDays = (kitMappingId, queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_TASKS_KIT_MAPPINGS}/${kitMappingId}/available_days`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_INSTORE_TASK_KIT_AVAILABLE_DAYS_REQUEST,
        types.GET_INSTORE_TASK_KIT_AVAILABLE_DAYS_SUCCESS,
        types.GET_INSTORE_TASK_KIT_AVAILABLE_DAYS_FAILED,
      ],
      queryParams,
    });
};

export const createInstoreTaskAnimation = (body = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.ANIMATIONS}`,
      method: 'POST',
      noCache: true,
      types: [
        types.CREATE_INSTORE_TASK_ANIMATION_REQUEST,
        types.CREATE_INSTORE_TASK_ANIMATION_SUCCESS,
        types.CREATE_INSTORE_TASK_ANIMATION_FAILED,
      ],
      body,
    });
};

export const updateInstoreTaskAnimation = (id, body = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.ANIMATIONS}/${id}`,
      method: 'PATCH',
      noCache: true,
      types: [
        types.UPDATE_INSTORE_TASK_ANIMATION_REQUEST,
        types.UPDATE_INSTORE_TASK_ANIMATION_SUCCESS,
        types.UPDATE_INSTORE_TASK_ANIMATION_FAILED,
      ],
      body,
    });
};

export const getInstoreTaskAnimationPhotos = (animationId) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_TASKS_ANIMATION_PHOTOS}`,
      method: 'GET',
      types: [
        types.GET_INSTORE_TASK_ANIMATION_PHOTOS_REQUEST,
        types.GET_INSTORE_TASK_ANIMATION_PHOTOS_SUCCESS,
        types.GET_INSTORE_TASK_ANIMATION_PHOTOS_FAILED,
      ],
      queryParams: {
        q: {
          animation_day_animation_id_eq: animationId,
        },
      },
    });
};

export const addInstoreTaskAnimationPhotos = (body) => {
  return () =>
    fetch(`${API_PATH}${API_METHODS.INSTORE_TASKS_ANIMATION_PHOTOS}`, {
      method: 'POST',
      headers: {
        'X-User-Email': getEmail(),
        'X-User-Token': getToken(),
      },
      body,
    });
};

export const getInstoreMonitoringList = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_TASKS_MONITORING}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_INSTORE_TASK_MONITORING_LIST_REQUEST,
        types.GET_INSTORE_TASK_MONITORING_LIST_SUCCESS,
        types.GET_INSTORE_TASK_MONITORING_LIST_FAILED,
      ],
      queryParams,
    });
};

export const getInstoreStatsFiles = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_TASKS_STATS_FILES}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_INSTORE_TASK_STATS_FILES_REQUEST,
        types.GET_INSTORE_TASK_STATS_FILES_SUCCESS,
        types.GET_INSTORE_TASK_STATS_FILES_FAILED,
      ],
      queryParams,
    });
};

export const getInstoreTaskKpis = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_TASK_KPIS}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_INSTORE_TASK_KPIS_FILES_REQUEST,
        types.GET_INSTORE_TASK_KPIS_FILES_SUCCESS,
        types.GET_INSTORE_TASK_KPIS_FILES_FAILED,
      ],
      queryParams,
    });
};
