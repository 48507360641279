import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, reset} from 'redux-form';

import {required, arrayUnique} from 'client/services/validator';

import {createEmailTask, createSmsTask} from 'client/ducks/message-email-sms-task/actions';
import {selectOperationMessageTaskNames} from 'client/ducks/operations/selectors';

import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

import {TASK_TYPES} from 'client/models/operations/constants';

class NewMessageBroadcastModal extends Component {
  static propTypes = {
    taskType: PropTypes.string,
    show: PropTypes.bool,
    operationId: PropTypes.number.isRequired,
    languageState: PropTypes.object.isRequired,
    messageTaskNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    createSmsTask: PropTypes.func.isRequired,
    createEmailTask: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  static defaultProps = {
    show: false,
  };

  static formName = 'NewMessageTaskModalForm';

  static TASKS_MAP = {
    [TASK_TYPES.SMS]: 'sms_task',
    [TASK_TYPES.EMAIL]: 'email_task',
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.OPERATION_PAGE.NEW_MESSAGE_TASK_MODAL;

    this.rules = {
      name: [
        (value) => {
          return arrayUnique(this.getTaskNames(), this.LANGUAGE.NAME_IS_UNIQUE_ERROR)(value);
        },
        required(this.LANGUAGE.NAME_IS_REQUIRED_ERROR),
      ],
    };
  }

  getTaskNames = () => this.props.messageTaskNames;

  save = (data) => {
    const {operationId, onConfirm, taskType} = this.props;
    const body = {
      [NewMessageBroadcastModal.TASKS_MAP[taskType]]: {
        operation_id: operationId,
        name: data.name,
      },
    };

    if (taskType === TASK_TYPES.SMS) {
      return this.props.createSmsTask(body).then((res) => {
        if (!res.error) {
          onConfirm(res.payload.sms_task.id);
        }
      });
    }

    return this.props.createEmailTask(body).then((res) => {
      if (!res.error) {
        onConfirm(res.payload.email_task.id);
      }
    });
  };

  handleClose = () => {
    this.props.reset();
    this.props.onClose();
  };

  render() {
    const {show, handleSubmit, taskType} = this.props;

    return (
      <Modal
        show={show}
        onClose={this.handleClose}
        title={taskType === TASK_TYPES.SMS ? this.LANGUAGE.TITLE_SMS : this.LANGUAGE.TITLE_EMAIL}
        dialogClassName="modal-window--width-1 theme-color-16"
      >
        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          <Field
            cssModifier="input--no-min-height"
            label={this.LANGUAGE.NAME_LABEL}
            name="name"
            type="text"
            component={fieldTemplate}
            validate={this.rules.name}
          />
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={this.handleClose}>
              {this.LANGUAGE.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-14 modal-window__footer-btn" type="submit">
              {this.LANGUAGE.CONFIRM_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const form = reduxForm({
  form: NewMessageBroadcastModal.formName,
})(NewMessageBroadcastModal);

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  messageTaskNames: selectOperationMessageTaskNames(state),
});

const mapDispatchToProps = {
  createEmailTask,
  createSmsTask,
  reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
