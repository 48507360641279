import React, {Component} from 'react';

import PropTypes from 'prop-types';

import Pagination from 'client/common/paginations/pagination';

import './grid-view.scss';

class GridView extends Component {
  render() {
    const {
      paginationParams: {currentPage, totalPages, onPageChange},
      children,
      className,
    } = this.props;

    return (
      <div className={`grid-view ${className}`}>
        <div className="grid-view__items">{children}</div>
        <div className="grid-view__pagination">
          <Pagination
            totalPages={totalPages}
            maxButtons={3}
            currentPage={currentPage}
            onClick={(page) => {
              onPageChange({page});
            }}
          />
        </div>
      </div>
    );
  }
}

GridView.defaultProps = {
  paginationParams: {
    currentPage: 2,
    totalPages: 100,
    onPageChange: () => {},
  },
  children: '',
  className: '',
};

GridView.propTypes = {
  paginationParams: PropTypes.shape({
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    onPageChange: PropTypes.func,
  }),
  children: PropTypes.any,
  className: PropTypes.string,
};

export default GridView;
