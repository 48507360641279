import {AFFECTATION_STEPS, AffectationStep} from 'client/models/device-affectations';

const stepIsAfter = (currentStep: AffectationStep, compareStep: AffectationStep) => {
  if (compareStep === AFFECTATION_STEPS.PREPARATION) {
    return false;
  } else if (compareStep === AFFECTATION_STEPS.SENT && currentStep === AFFECTATION_STEPS.PREPARATION) {
    return true;
  } else if (
    compareStep === AFFECTATION_STEPS.RECEIVED &&
    ([AFFECTATION_STEPS.PREPARATION, AFFECTATION_STEPS.SENT] as AffectationStep[]).includes(currentStep)
  ) {
    return true;
  } else if (compareStep === AFFECTATION_STEPS.VERIFIED && currentStep !== AFFECTATION_STEPS.VERIFIED) {
    return true;
  }
  return false;
};

export const stepIsBefore = (currentStep: AffectationStep, compareStep: AffectationStep) => {
  return !stepIsAfter(currentStep, compareStep);
};
