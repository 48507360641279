import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons/app-button';

import {Pagination as PaginationProps} from 'client/models/pagination/types';

import {usePagination, DOTS} from './usePagination';

import cssModule from './pagination.module.scss';

const b = bem('pagination', {cssModule});

const Pagination: React.FC<PaginationProps> = (props) => {
  const {
    prev = true,
    next = true,
    ellipsis,
    totalItems = 1,
    currentPage = 1,
    maxButtons = 1,
    siblingCount = 1,
    color = 'clients',
    onClick,
    className,
    perPage = 5,
    totalPages = 1,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    siblingCount,
    maxButtons,
    onClick,
    totalItems,
    totalPages,
  });

  if (!paginationRange || !currentPage || paginationRange.length < 2) {
    return null;
  }

  const handleNext = () => {
    onClick(+currentPage + 1, perPage);
  };

  const handlePrevious = () => {
    onClick(+currentPage - 1, perPage);
  };

  return (
    <nav className={cn(b(), className)}>
      {prev && (
        <AppButton
          className={b('button', ['prev'])}
          disabled={currentPage === 1}
          iconName="arrow-left"
          onClick={handlePrevious}
          transparent={true}
          data-type="button"
          color={color}
        />
      )}
      <ul className={b('list')}>
        {paginationRange.map((pageNumber: number | string, idx: number, array) => {
          if (pageNumber === DOTS && ellipsis) {
            return (
              <li key={idx} className={b('item', ['dots'])}>
                &#8230;
              </li>
            );
          }

          return (
            <li key={idx} className={b('item')}>
              <AppButton
                className={b('button', {current: pageNumber === currentPage, item: pageNumber !== currentPage})}
                color={pageNumber === currentPage ? color : 'text-additional'}
                transparent={true}
                onClick={() => onClick(pageNumber === DOTS ? +array[idx - 1] + 1 : +pageNumber, perPage)}
                fontSize="extra-small"
                label={pageNumber}
                data-type="button"
              />
            </li>
          );
        })}
      </ul>
      {next && (
        <AppButton
          onClick={handleNext}
          disabled={currentPage === paginationRange[paginationRange.length - 1]}
          className={b('button', ['next'])}
          iconName="arrow-right"
          data-type="button"
          transparent={true}
          color={color}
        />
      )}
    </nav>
  );
};

export default Pagination;
