import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {CheckboxField, NumberField} from 'client/common/fields';
import DateTimeListField from 'client/common/fields/datetime-list-field';
import TitleLine from 'client/common/typography/title-line';

import {WEEKDAYS} from 'client/components/prizes/constants';

import './prize-diy-week-schedule-fieldset.scss';

const b = bem('prize-diy-week-schedule-fieldset');

const DiyWeekScheduleFieldset = ({disabled}) => {
  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_WEEK_SCHEDULE_FIELDSET');

  return (
    <div className={b()}>
      <p className={b('description')}>{lang.DESCRIPTION}</p>

      <div className={b('weekdays-controls')}>
        <TitleLine text={lang.WINS_NUMBER} />
        <div className={b('weekdays')}>
          {WEEKDAYS.map((weekday) => (
            <div key={weekday}>
              <span className={b('weekday-label')}>{lang.WEEKDAYS[weekday.toUpperCase()]}</span>
              <NumberField name={`weekSchedule.prizes_number.${weekday}`} disabled={disabled} />
            </div>
          ))}
        </div>
        <TitleLine text={lang.OPENING_HOURS} />
        <div className={b('weekdays')}>
          {WEEKDAYS.map((weekday) => (
            <DateTimeListField
              key={weekday}
              name={`weekSchedule.openingHours.${weekday}`}
              className={b('timestamps')}
              fixed={4}
              type="time"
              maxLength={4}
              period
              disabled={disabled}
              maskChars={{second: '0'}}
              placeholder={{from: lang.FROM, to: lang.TO}}
            />
          ))}
        </div>
      </div>

      <div className={b('additional-controls')}>
        <span>{lang.PRIZES_LEFT}</span>
        <div className={b('same-day')}>
          <CheckboxField
            label={lang.NEXT_DAY_LABEL}
            color="primary"
            inversionColor
            name="weekSchedule.next_day_win"
            disabled={disabled}
          />
          <CheckboxField
            label={lang.SAME_DAY_LABEL}
            color="primary"
            inversionColor
            name="weekSchedule.intraday_win"
            disabled={disabled}
          />
        </div>
        <span>{lang.EXCLUDE_LABEL}</span>
        <DateTimeListField name="dates_off" className={b('exclude')} type="date" disabled={disabled} />
      </div>
    </div>
  );
};

DiyWeekScheduleFieldset.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default DiyWeekScheduleFieldset;
