import React from 'react';

import PropTypes from 'prop-types';

import {CLIENT_PAGES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';

function TaskColumn(props) {
  const {messageTask} = props;
  const link = CustomLink.createTaskLink(messageTask) + CLIENT_PAGES.MESSAGE_TASK_SETTINGS;
  return (
    <CustomLink className="link break-word display-block" to={link}>
      {messageTask.taskName}
    </CustomLink>
  );
}

TaskColumn.propTypes = {
  messageTask: PropTypes.shape({
    taskName: PropTypes.string,
    taskType: PropTypes.string,
    clientType: PropTypes.string,
    operationId: PropTypes.number,
    clientId: PropTypes.number,
    taskId: PropTypes.number,
  }).isRequired,
};

export default TaskColumn;
