import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useVariableInsert} from 'client/services/hooks';

import {TextInput} from 'client/common/inputs';

import ParametersVariableDropdown from 'client/components/email-template-editor/inputs/parameters-variable-dropdown';

import cssModule from './variable-text-input.module.scss';

const b = bem('variable-text-input', {cssModule});

export type VariableTextInputProps = {
  className?: string;
  name: string;
  label?: string;
  value?: string;
  errorMessage?: string;
  onChange: (value: string) => void;
};

const VariableTextInput: React.FC<VariableTextInputProps> = (props) => {
  const {className, name, label, value = '', errorMessage, onChange} = props;

  const {onBlur, insertVariable} = useVariableInsert(value, onChange, {
    variableTemplate: '{{var:VARIABLE_NAME}}',
    initialPosition: 'end',
  });

  return (
    <div className={cn(b(), className)}>
      <TextInput
        label={label}
        name={name}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        onBlur={onBlur}
        inputClassName={b('input')}
        errorMessage={errorMessage}
      />
      <ParametersVariableDropdown onSelect={insertVariable} />
    </div>
  );
};

export default VariableTextInput;
