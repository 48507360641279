import types from './types';

const initialState = {
  payload: {
    lead_columns: [],
    all_lead_columns: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_CUSTOM_COLUMNS_SUCCESS:
      return {
        ...state,
        payload: {...state.payload, ...action.payload},
      };
    case types.GET_ALL_CUSTOM_COLUMNS_SUCCESS:
      return {
        ...state,
        payload: {...state.payload, all_lead_columns: action.payload.lead_columns},
      };

    case types.FLUSH_CUSTOM_COLUMNS_ALL_STATE:
      return initialState;
    default:
      return state;
  }
}
