import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Popover from 'client/common/popovers/popover';

import './at-plan-summary-popover.scss';

function AtPlanSummaryPopover({children, languageState, plan}) {
  const LANGUAGE = languageState.payload.AUTOTASK_PLAN.SUMMARY_POPOVER;

  return (
    <Popover
      shiftTop={5}
      position="bottom"
      shiftOptions={{
        mainAxis: true,
      }}
      overlayInnerStyle={{
        width: 220,
      }}
      overlay={
        <div className="at-plan-summary-popover">
          <div className="popover-list theme-color-9">
            <h3 className="popover-list__title">{LANGUAGE.TITLE}</h3>
            <ul className="popover-list__list">
              <li className="popover-list__item">
                <div className="at-plan-summary-popover__item">
                  <div className="at-plan-summary-popover__name">{LANGUAGE.PLACES}</div>
                  <div className="at-plan-summary-popover__val">{plan.places_count}</div>
                </div>
              </li>
              <li className="popover-list__item">
                <div className="at-plan-summary-popover__item">
                  <div className="at-plan-summary-popover__name">{LANGUAGE.START_DATE}</div>
                  <div className="at-plan-summary-popover__val">{moment(plan.from).format('DD/MM/YYYY HH:mm')}</div>
                </div>
              </li>
              <li className="popover-list__item">
                <div className="at-plan-summary-popover__item">
                  <div className="at-plan-summary-popover__name">{LANGUAGE.END_DATE}</div>
                  <div className="at-plan-summary-popover__val">{moment(plan.to).format('DD/MM/YYYY HH:mm')}</div>
                </div>
              </li>
              <li className="popover-list__item">
                <div className="at-plan-summary-popover__item">
                  <div className="at-plan-summary-popover__name">{LANGUAGE.PART}</div>
                  <div className="at-plan-summary-popover__val">{plan.participations_count}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      }
    >
      {children}
    </Popover>
  );
}

AtPlanSummaryPopover.propTypes = {
  languageState: PropTypes.object.isRequired,
  children: PropTypes.node,
  plan: PropTypes.shape({
    participations_count: PropTypes.number,
    from: PropTypes.string,
    to: PropTypes.string,
    places_count: PropTypes.number,
  }).isRequired,
};

export default connect(({languageState}) => ({
  languageState,
}))(AtPlanSummaryPopover);
