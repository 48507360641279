import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';
import {ReactNode} from 'client/types';

export const TaskWrapperTabsType = PropTypes.arrayOf(
  PropTypes.shape({
    path: PropTypes.string.isRequired,
    content: ReactNode.isRequired,
    label: TranslationJsx.isRequired,
    disabled: PropTypes.bool,
  }),
);
