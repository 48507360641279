export default (values) => {
  const result = {
    device_level_code_list: values?.device_level_code_list || false,
  };

  if (values?.device?.file && values?.segmentationImports) {
    result.device_token_list = {
      filename: values?.device?.filename,
      data: values?.device?.file,
    };
  }

  if (values?.online?.file && values?.segmentationImports) {
    result.online_token_list = {
      filename: values?.online?.filename,
      data: values?.online?.file,
    };
  }

  if (values?.common?.file && !values?.segmentationImports) {
    result.common_token_list = {
      filename: values?.common?.filename,
      data: values?.common?.file,
    };
  }

  return result;
};
