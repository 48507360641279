import {MainStates} from 'client/types';

export const selectClientAccount = (state: MainStates) => {
  return state.clientAccount.clientAccount;
};

export const selectClientMemberships = (state: MainStates) => {
  return state.clientAccount.clientMemberships;
};

export const selectClientStores = (state: MainStates) => {
  return state.clientAccount.clientStores;
};
