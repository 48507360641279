import {Dispatch} from 'redux';

import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import {ContentBlock, DndData, Section, TemplateData} from 'client/components/email-template-editor/types';
import {EmailTemplate, EmailTemplateKind, EmailTemplateImage} from 'client/models/email-templates/types';
import {ApiAction} from 'client/types';

import types from './types';

export const getEmailTemplates: ApiAction<EmailTemplate[]> = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_TEMPLATES}`,
      noCache: true,
      method: 'GET',
      types: [
        types.GET_OPERATION_EMAIL_TEMPLATES_REQUEST,
        types.GET_OPERATION_EMAIL_TEMPLATES_SUCCESS,
        types.GET_OPERATION_EMAIL_TEMPLATES_ERROR,
      ],
      queryParams,
    });
};

export const getEmailTemplate: ApiAction<EmailTemplate> = (id, queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_TEMPLATES}/${id}`,
      method: 'GET',
      types: [types.GET_EMAIL_TEMPLATE_REQUEST, types.GET_EMAIL_TEMPLATE_SUCCESS, types.GET_EMAIL_TEMPLATE_ERROR],
      queryParams,
    });
};

export const createEmailTemplate: ApiAction<EmailTemplate> = (body = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_TEMPLATES}`,
      method: 'POST',
      types: [
        types.CREATE_EMAIL_TEMPLATE_REQUEST,
        types.CREATE_EMAIL_TEMPLATE_SUCCESS,
        types.CREATE_EMAIL_TEMPLATE_ERROR,
      ],
      body,
    });
};

export const fetchEditedTemplate: ApiAction<EmailTemplate> = (id, body = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_TEMPLATES}/${id}/edit`,
      method: 'POST',
      types: [
        types.FETCH_EDITED_EMAIL_TEMPLATE_REQUEST,
        types.FETCH_EDITED_EMAIL_TEMPLATE_SUCCESS,
        types.FETCH_EDITED_EMAIL_TEMPLATE_ERROR,
      ],
      body,
    });
};

export const patchEmailTemplate: ApiAction<EmailTemplate> = (id, body = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_TEMPLATES}/${id}`,
      method: 'PATCH',
      types: [types.PATCH_EMAIL_TEMPLATE_REQUEST, types.PATCH_EMAIL_TEMPLATE_SUCCESS, types.PATCH_EMAIL_TEMPLATE_ERROR],
      body,
    });
};

export const patchEmailTemplateLink: ApiAction = (id, body = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_TEMPLATE_LINKS}/${id}`,
      method: 'PATCH',
      types: [
        types.PATCH_EMAIL_TEMPLATE_LINK_REQUEST,
        types.PATCH_EMAIL_TEMPLATE_LINK_SUCCESS,
        types.PATCH_EMAIL_TEMPLATE_LINK_ERROR,
      ],
      body,
    });
};

export const getEmailTemplateKinds: ApiAction = (params: {operationId?: number; emailTemplateId?: number}) => {
  return (dispatch) => {
    const queryParams: Record<string, any> = {
      q: {
        operation_id_eq: params.operationId,
        template_email_templates_template_id_eq: params.emailTemplateId,
      },
    };

    if (!params.operationId) {
      queryParams.distinct = true;
    }

    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_TEMPLATE_KINDS}`,
      method: 'GET',
      types: [
        types.GET_EMAIL_TEMPLATE_KINDS_REQUEST,
        types.GET_EMAIL_TEMPLATE_KINDS_SUCCESS,
        types.GET_EMAIL_TEMPLATE_KINDS_ERROR,
      ],
      queryParams,
    });
  };
};

export const getActiveEmailTemplateKind: ApiAction = (kindId: number) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_TEMPLATE_KINDS}/${kindId}`,
      method: 'GET',
      types: [
        types.GET_ACTIVE_EMAIL_TEMPLATE_KIND_REQUEST,
        types.GET_ACTIVE_EMAIL_TEMPLATE_KIND_SUCCESS,
        types.GET_ACTIVE_EMAIL_TEMPLATE_KIND_ERROR,
      ],
      queryParams: {
        include: {
          email_template_kind_variables: {
            column_adapter: null,
          },
          email_templates: {
            email_template_variables: null,
            email_template_images: null,
            template_email_templates: null,
          },
        },
      },
    });
};

export const getPrizesByGamesId: ApiAction = ({
  gameIds,
  sms = false,
  email = false,
}: {
  gameIds: number[];
  sms: boolean;
  email: boolean;
}) => {
  const params: Record<string, any> = {game_id_in: gameIds};
  if (email) {
    params.email_send_eq = true;
  }
  if (sms) {
    params.sms_send_eq = true;
  }
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PRIZES}`,
      method: 'GET',
      types: [types.GET_PRIZES_REQUEST, types.GET_PRIZES_SUCCESS, types.GET_PRIZES_ERROR],
      queryParams: {
        q: params,
        include: {
          coupon: true,
        },
        include_prize_email_image_translations: true,
        include_prize_email_mocked_params: true,
      },
    });
};

export const getEmailSenders: ApiAction = (clientId: number) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_SENDERS}`,
      method: 'GET',
      types: [types.GET_EMAIL_SENDERS_REQUEST, types.GET_EMAIL_SENDERS_SUCCESS, types.GET_EMAIL_SENDERS_ERROR],
      queryParams: {
        q: {
          client_id_eq: clientId,
          client_id_null: true,
          m: 'or',
          s: 'client_id',
        },
      },
    });
};

export const updateEmailTemplateParameters: ApiAction = (id, body = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_TEMPLATES}/${id}`,
      method: 'PATCH',
      types: [
        types.UPDATE_EMAIL_TEMPLATE_PARAMETERS_REQUEST,
        types.UPDATE_EMAIL_TEMPLATE_PARAMETERS_SUCCESS,
        types.UPDATE_EMAIL_TEMPLATE_PARAMETERS_ERROR,
      ],
      body: {operation_email_template: body},
      queryParams: {
        include: {email_template_variables: null},
      },
    });
};

export const uploadEmailTemplateImage: ApiAction<{email_template_image: EmailTemplateImage}> = (body) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_TEMPLATE_IMAGES}`,
      method: 'POST',
      types: [
        types.UPLOAD_EMAIL_TEMPLATE_IMAGE_REQUEST,
        types.UPLOAD_EMAIL_TEMPLATE_IMAGE_SUCCESS,
        types.UPLOAD_EMAIL_TEMPLATE_IMAGE_ERROR,
      ],
      body,
    });
};

export const updateEmailTemplateImage: ApiAction<{email_template_image: EmailTemplateImage}> = (id, body) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_TEMPLATE_IMAGES}/${id}`,
      method: 'PATCH',
      types: [
        types.UPLOAD_EMAIL_TEMPLATE_IMAGE_REQUEST,
        types.UPLOAD_EMAIL_TEMPLATE_IMAGE_SUCCESS,
        types.UPLOAD_EMAIL_TEMPLATE_IMAGE_ERROR,
      ],
      body,
      noCache: true,
    });
};

export const sendTestEmail: ApiAction = (templateId: number, body) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATION_EMAIL_TEMPLATES}/${templateId}/send_test_email`,
      method: 'POST',
      types: [types.SEND_TEST_EMAIL_REQUEST, types.SEND_TEST_EMAIL_SUCCESS, types.SEND_TEST_EMAIL_ERROR],
      body,
    });
};

export const parseMjml: ApiAction<{result: string}> = (body) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MJML_PARSE}`,
      method: 'POST',
      types: [types.PARSE_MJML_REQUEST, types.PARSE_MJML_SUCCESS, types.PARSE_MJML_ERROR],
      body,
    });
};

export const setActiveTemplate = (template: EmailTemplate | null) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.SET_ACTIVE_EMAIL_TEMPLATE,
      payload: {template},
    });
  };
};

export const setActiveTemplateKind = (templateKind: EmailTemplateKind | null) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.SET_ACTIVE_EMAIL_TEMPLATE_KIND,
      payload: {templateKind},
    });
  };
};

export const addEmailTemplateVariable = (variable: string | null) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.ADD_EMAIL_TEMPLATE_VARIABLE,
      payload: {variable},
    });
  };
};

export const editorSetDocData = (docData: TemplateData) => {
  const doc = {
    body: Array.isArray(docData?.body) ? docData.body : [],
  };
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.EDITOR_SET_DOC_DATA,
      payload: {
        doc: doc,
      },
    });
  };
};

export const editorCreateSection = (section: Section, beforeSectionId: string | null = null) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.EDITOR_CREATE_SECTION,
      payload: {
        section,
        beforeSectionId,
      },
    });
  };
};

export const editorUpdateSection = (section: Section) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.EDITOR_UPDATE_SECTION,
      payload: {
        section,
      },
    });
  };
};

export const editorMoveSection = (sectionId: string, afterSectionId: string) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.EDITOR_MOVE_SECTION,
      payload: {
        sectionId,
        afterSectionId,
      },
    });
  };
};

export const editorDeleteSection = (sectionId: string) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.EDITOR_DELETE_SECTION,
      payload: {
        sectionId,
      },
    });
  };
};

export const editorCreateContentBlock = (
  columnId: string,
  contentBlock: ContentBlock,
  afterContentBlockId: string | null = null,
) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.EDITOR_CREATE_CONTENT_BLOCK,
      payload: {
        columnId,
        contentBlock,
        afterContentBlockId,
      },
    });
  };
};

export const editorUpdateContentBlock = (contentBlock: ContentBlock) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.EDITOR_UPDATE_CONTENT_BLOCK,
      payload: {
        contentBlock,
      },
    });
  };
};

export const editorDeleteContentBlock = (contentBlockId: string) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.EDITOR_DELETE_CONTENT_BLOCK,
      payload: {
        contentBlockId,
      },
    });
  };
};

export const editorMoveContentBlock = (contentBlockId: string, targetColumnId: string, afterContentBlockId: string) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.EDITOR_MOVE_CONTENT_BLOCK,
      payload: {
        contentBlockId,
        targetColumnId,
        afterContentBlockId,
      },
    });
  };
};

export function editorSetDndData(dndData: DndData) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.EDITOR_SET_DND_DATA,
      payload: dndData,
    });
  };
}

export function editorSetActiveBlockId(value: string | null) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.EDITOR_SET_ACTIVE_BLOCK_ID,
      payload: value,
    });
  };
}

export const getMasterEmailTemplates: ApiAction = (queryParams) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MASTER_EMAIL_TEMPLATES}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_MASTER_EMAIL_TEMPLATES_REQUEST,
        types.GET_MASTER_EMAIL_TEMPLATES_SUCCESS,
        types.GET_MASTER_EMAIL_TEMPLATES_ERROR,
      ],
      queryParams,
    });
};

export function addEmailTemplateImagesForDelete(files: EmailTemplateImage[]) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.EDITOR_ADD_IMAGE_FOR_DELETE,
      payload: files,
    });
  };
}
export function resetEmailTemplateImagesForDelete() {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.EDITOR_RESET_IMAGE_FOR_DELETE,
    });
  };
}

export const getGalleryEmailTemplates: ApiAction<EmailTemplate[]> = (queryParams) => {
  return (dispatch: Dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_TEMPLATES}${API_METHODS.GALLERY_EMAIL_TEMPLATES}`,
      noCache: true,
      method: 'GET',
      types: [
        types.GET_GALLERY_EMAIL_TEMPLATES_REQUEST,
        types.GET_GALLERY_EMAIL_TEMPLATES_SUCCESS,
        types.GET_GALLERY_EMAIL_TEMPLATES_ERROR,
      ],
      queryParams,
    });
  };
};

export const replaceEmailTemplate: ApiAction<EmailTemplate> = (currentTemplateId: number, newTemplateId: number) => {
  return (dispatch: Dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_TEMPLATES}/${currentTemplateId}${API_METHODS.REPLACE_EMAIL_TEMPLATE}`,
      method: 'POST',
      types: [
        types.REPLACE_EMAIL_TEMPLATE_REQUEST,
        types.REPLACE_EMAIL_TEMPLATE_SUCCESS,
        types.REPLACE_EMAIL_TEMPLATE_ERROR,
      ],
      queryParams: {
        gallery_email_template_id: newTemplateId,
      },
    });
  };
};
