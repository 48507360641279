import React, {Component} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import './grid.scss';

class Grid extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    paddings: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    children: null,
    paddings: false,
  };

  static Row({className = '', children = null}) {
    return <div className={`grid__row ${className}`}>{children}</div>;
  }

  static Col({className = '', children = null, size = 0}) {
    return <div className={`grid__col grid__col-${size} ${className}`}>{children}</div>;
  }

  render() {
    const {className, children, paddings} = this.props;

    const cssClass = classNames({
      grid: true,
      'grid--paddings': paddings,
    });

    return <div className={`${cssClass} ${className}`}>{children}</div>;
  }
}

export default Grid;
