import React from 'react';

import {Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';

function FormatCell({data, rowIndex, field, formatTextLabels, ...props}) {
  return (
    <Cell {...props}>
      <div className="ellipsis-cell ellipsis-text">{formatTextLabels[data[rowIndex][field]]}</div>
    </Cell>
  );
}

FormatCell.propTypes = {
  rowIndex: PropTypes.number,
  data: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  formatTextLabels: PropTypes.object.isRequired,
};

export default FormatCell;
