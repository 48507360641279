import React from 'react';

import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

import './game-confirmation-block.scss';

const GameConfirmationBlock = (props) => {
  const {message, cancelText, confirmText, onCancel, onConfirm} = props;

  return (
    <div className="game-confirmation-block">
      <p className="game-confirmation-block__message">{message}</p>
      <div>
        <button className="game-confirmation-block__cancel-button button button--bg-5" type="button" onClick={onCancel}>
          {cancelText}
        </button>
        <button
          className="game-confirmation-block__confirm-button button button--bg-4"
          type="button"
          onClick={onConfirm}
        >
          {confirmText}
        </button>
      </div>
    </div>
  );
};

GameConfirmationBlock.propTypes = {
  message: TranslationJsx.isRequired,
  cancelText: TranslationJsx.isRequired,
  confirmText: TranslationJsx.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default GameConfirmationBlock;
