import React from 'react';

import PropTypes from 'prop-types';

import {diagramKinds} from 'client/ducks/results-graphs/constants';

import Field from 'client/components/common/fields/field';

const types = ['ExternalColumnAdapter'];
const commonKinds = ['integer', 'decimal', 'boolean', 'string'];
const lineKinds = ['decimal'];

const getDataSourcesOptions = (dataSources, formValues) => {
  const kinds = formValues.kind === diagramKinds.LINE ? lineKinds : commonKinds;

  return dataSources
    .filter(({type, kind}) => types.includes(type) && kinds.includes(kind))
    .map((item) => ({value: item.id, label: item.name}));
};

const DiagramDataSourceField = (props) => {
  const {lang, dataSources, formValues} = props;

  return (
    <Field
      name="column_adapter_id"
      label={lang.DATA_SOURCE}
      type="select"
      searchable={false}
      options={getDataSourcesOptions(dataSources, formValues)}
      simpleValue
      withWrap
    />
  );
};

DiagramDataSourceField.propTypes = {
  lang: PropTypes.object.isRequired,
  dataSources: PropTypes.array.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default DiagramDataSourceField;
