import React, {useRef} from 'react';

import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import FileButton from 'client/common/buttons/file-button';

const InputComponent = (props) => {
  const {input, ...rest} = props;
  const ref = useRef(null);

  const onChange = (...args) => {
    input.onChange?.(...args);
    rest.onChange?.(...args);
  };
  return <FileButton {...omit(rest, 'meta')} onChange={onChange} ref={ref} />;
};

InputComponent.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  onChange: PropTypes.func,
};

InputComponent.defaultProps = {
  onChange: null,
};

const FileButtonField = (props) => {
  return <Field component={InputComponent} {...props} />;
};

export default FileButtonField;
