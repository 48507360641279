import {useCallback, useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import {getInstoreTaskCampaigns} from 'client/ducks/instore-tasks/actions';
import {selectInstoreTaskCampaigns} from 'client/ducks/instore-tasks/selectors';

export const useFetchCampaigns = ({taskId}) => {
  const dispatch = useDispatch();

  const [loading, toggleLoading] = useToggle(false);

  const campaigns = useSelector(selectInstoreTaskCampaigns);

  const fetchCampaigns = useCallback(async () => {
    const params = {
      q: {instore_task_id_eq: taskId},
    };
    toggleLoading();
    const result = await dispatch(getInstoreTaskCampaigns(params));
    toggleLoading();

    return result;
  }, [dispatch, taskId, toggleLoading]);

  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);

  return {loadingCampaigns: loading, campaigns};
};
