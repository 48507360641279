import {useDispatch, useSelector} from 'react-redux';

import {setOperation} from 'client/ducks/operations/actions';
import {getResultByClientTemplate} from 'client/ducks/templates/actions';
import {selectUser} from 'client/ducks/user/selectors';

import {hasOnline, hasDevice} from 'client/components/diy-operation/modals/diy-op-template-modal/helpers';
import {FormType} from 'client/models/diy-customization-form/types';
import {Template, ClientTemplate} from 'client/models/templates/types';
import {User} from 'client/models/users/types';

import {getFormByType} from './helpers';

const mapTemplateToFakeOperation = (template: Template | ClientTemplate, user: User) => {
  return {
    editable: true,
    with_real_participations: false,
    languages: template.languages,
    client_interface: {
      device: hasDevice(template.image_formats),
      online: hasOnline(template.image_formats),
      languages: template.languages,
      default_language: template.languages.includes(user.locale) ? user.locale : template.languages[0],
      screen_formats: template.image_formats,
      templates: [template],
    },
  };
};

// Use this hook if you open the customization modal outside the operation configuration page
export default () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const onOpenCustomizationModal = (template: Template | ClientTemplate, formType: FormType) => {
    const form = getFormByType(template, formType);
    if (form) {
      dispatch(getResultByClientTemplate(template.id));
      dispatch(setOperation(mapTemplateToFakeOperation(template, user)));
    }

    return form;
  };

  const onCloseCustomizationModal = () => {
    dispatch(setOperation({}));
  };

  return {onOpenCustomizationModal, onCloseCustomizationModal};
};
