import React from 'react';

import {useSelector} from 'react-redux';
import {generatePath} from 'react-router';
import {useHistory, useParams} from 'react-router-dom';
import {routePaths} from 'routes/index';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';
import {apiAction} from 'client/services/hooks/use-redux-fetch/requests';

import {selectCurrentLanguage} from 'client/ducks/language/selectors';

import AwaitContainer from 'client/common/await-container';
import AppButton from 'client/common/buttons';
import {API_METHODS} from 'client/common/config';

import ClientAccountParameters from 'client/components/client-account-parameters';
import {Client} from 'client/models/client/types';

import cssModule from './agencies-client.module.scss';

const b = bem('agencies-client', {cssModule});

const AgencyClientPage: React.FC = () => {
  const lang = useLanguage('AGENCIES.CLIENT_PAGE');

  const history = useHistory();
  const {clientId, agencyClientId} = useParams<{agencyClientId: string; clientId: string}>();
  const currentLanguage = useSelector(selectCurrentLanguage);

  const rootPath = generatePath(routePaths.client.AgencyClientPage, {agencyClientId, clientId, tab: ':tab'});

  const {data, loading, fetch} = useReduxFetch<{client: Client}>({
    action: apiAction,
    actionArgs: {
      url: `${API_METHODS.CLIENTS}/${agencyClientId}`,
      queryParams: {
        include_client_logo_url: true,
        [`include_membership_client_user_full_name_with_title_${currentLanguage}`]: null,
        include: ['agency', 'subsidiary', 'poc_agency_membership', 'poc_membership', 'category'],
      },
    },
  });
  const client = data?.client;

  if (!client) {
    return null;
  }

  return (
    <AwaitContainer loading={loading}>
      <div className={b()}>
        <header className={b('header')}>
          <AppButton
            label={lang.BACK_BUTTON}
            iconName="arrow-left"
            transparent
            color="primary"
            size="small"
            onClick={() => history.push(generatePath(routePaths.client.AgencyClientsPage, {clientId}))}
          />

          <span className={b('client-name')}>{client.name}</span>
        </header>
        <ClientAccountParameters client={client} rootPath={rootPath} fetchClient={fetch} />
      </div>
    </AwaitContainer>
  );
};

export default AgencyClientPage;
