import React from 'react';

import PropTypes from 'prop-types';

// components
import InfoBlock from 'client/components/common/info-block';

import {TranslationJsx} from 'client/models/language/types';

function OperationCard(props) {
  const {children, title, button, className} = props;

  return (
    <InfoBlock className={className}>
      <InfoBlock.Title>
        <span className="margin-right-10">{title}</span>
        <span>{button}</span>
      </InfoBlock.Title>
      <InfoBlock.Content>
        <InfoBlock.Body>{children}</InfoBlock.Body>
      </InfoBlock.Content>
    </InfoBlock>
  );
}

OperationCard.propTypes = {
  title: TranslationJsx,
  button: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
};

OperationCard.defaultProps = {
  title: '',
  button: '',
  children: '',
  className: '',
};

export default OperationCard;
