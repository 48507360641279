import React, {memo} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {HoveringField} from 'client/components/common/fields';

import {MEDIA_LEVELS} from 'client/components/medias/constants';

import './medias-name-cell.scss';

const b = bem('medias-name-cell');

const MediasNameCell = memo((props) => {
  const {index, item, level, isLocal} = props;
  const compareLevel = isLocal ? MEDIA_LEVELS.DEVICE : level;

  return (
    <div className={b()}>
      <HoveringField name={`medias[${index}].name`} readOnly={item.level !== compareLevel} />
    </div>
  );
});

MediasNameCell.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  isLocal: PropTypes.bool.isRequired,
  level: PropTypes.string.isRequired,
};

export default MediasNameCell;
