import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function fetchSources(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PERMANENT_SOURCES}`,
      method: 'GET',
      noCache: true,
      types: [types.FETCH_SOURCES_REQUEST, types.FETCH_SOURCES_SUCCESS, types.FETCH_SOURCES_ERROR],
      queryParams,
    });
}

export function createSource(body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PERMANENT_SOURCES}`,
      method: 'POST',
      noCache: true,
      types: [types.CREATE_SOURCE_REQUEST, types.CREATE_SOURCE_SUCCESS, types.CREATE_SOURCE_ERROR],
      body,
    });
}

export function patchSource(id, body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PERMANENT_SOURCES}/${id}`,
      method: 'PATCH',
      noCache: true,
      types: [types.PATCH_SOURCE_REQUEST, types.PATCH_SOURCE_SUCCESS, types.PATCH_SOURCE_ERROR],
      body,
    });
}

export function deleteSources(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PERMANENT_SOURCES}`,
      method: 'DELETE',
      noCache: true,
      types: [types.DELETE_SOURCE_REQUEST, types.DELETE_SOURCE_SUCCESS, types.DELETE_SOURCE_ERROR],
      queryParams,
    });
}

export const getClientOptions = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENTS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_CLIENT_OPTIONS_REQUEST, types.GET_CLIENT_OPTIONS_SUCCESS, types.GET_CLIENT_OPTIONS_ERROR],
      queryParams,
    });
};
