import React, {useCallback} from 'react';

import PropTypes from 'prop-types';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons';
import ColorPickerInput from 'client/common/inputs/color-picker-input';

import Icon from 'client/components/common/icon';

import {rgba2hex} from 'client/components/diy-operation/modals/diy-customization-modal/helpers';

import cssModule from './color-input-item.module.scss';

const b = bem('color-input-item', {cssModule});

const DEFAULT_COLOR = '#FFFFFFFF';

const ColorInputItem = ({
  value,
  onChange,
  disabled = false,
  defaultColor: isSetToDefault,
  colorRequired = false,
  ...colorPickerInputProps
}) => {
  const translations = useLanguage('DIY_OPERATION.MODALS.CONFIGURATION_MODAL.COLOR_PICKER');
  const [defaultColor, toggleDefaultColor] = useToggle(isSetToDefault);

  const handleDefaulColor = useCallback(() => {
    toggleDefaultColor();
    if (!defaultColor) {
      onChange(null);
    } else {
      onChange(DEFAULT_COLOR);
    }
  }, [defaultColor, toggleDefaultColor, onChange]);

  const handleChangeColor = (color) => {
    let val;
    if (typeof color === 'string') {
      val = color.trim(8);
    } else {
      const {
        rgb: {r, g, b: blue, a},
      } = color;
      val = `#${rgba2hex(r, g, blue, a)}`;
    }
    onChange(val);
  };

  const colorInput = value ? value.slice(0, 7).toUpperCase() : '';

  return (
    <>
      <div className={b()}>
        <ColorPickerInput
          disabled={disabled}
          disableManualInput={true}
          className={b('input-container')}
          inputValue={colorInput}
          value={value}
          asHex={false}
          onChange={handleChangeColor}
          readOnly={true}
          hideValue={Boolean(defaultColor)}
          customColorBox={
            defaultColor ? (
              <div className={b('input-container')}>
                <Icon className={b('no-color-icon')} name="no-color" onClick={disabled ? null : handleDefaulColor} />
              </div>
            ) : null
          }
          {...colorPickerInputProps}
        />
        {!colorRequired && !defaultColor && (
          <AppButton
            label={translations.BY_DEFAULT}
            className={b('default-button')}
            size="small"
            color="light-clients"
            onClick={handleDefaulColor}
            disabled={disabled}
          />
        )}
      </div>
      {defaultColor && <span className={b('no-color-text')}>{translations.COMMENT}</span>}
    </>
  );
};

ColorInputItem.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  defaultColor: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  colorRequired: PropTypes.bool,
  colorPickerInputProps: PropTypes.shape({
    ...ColorPickerInput.propTypes,
  }),
};

export default ColorInputItem;
