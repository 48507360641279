import find from 'lodash/find';

import {dateToString} from 'client/services/helpers';

export default (autotask) => {
  const winConfig = autotask.custom_prize_type_names || [];

  const win1 = find(winConfig, {prize_type_number: 1});
  const win2 = find(winConfig, {prize_type_number: 2});
  const win3 = find(winConfig, {prize_type_number: 3});

  return {
    name: autotask.name,
    validation_limit: dateToString(autotask.validation_limit),
    csm_user_id: autotask.csm_user_id,
    win1_name: win1 ? win1.custom_name : '',
    win1_validation: win1 ? win1.validation_required : false,
    win2_name: win2 ? win2.custom_name : '',
    win2_validation: win2 ? win2.validation_required : false,
    win3_name: win3 ? win3.custom_name : '',
    win3_validation: win3 ? win3.validation_required : false,
  };
};
