import React from 'react';

import pick from 'lodash/pick';
import {useDispatch} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useAppMedia, useLanguage, useReduxForm} from 'client/services/hooks';

import {changeAnimationContacts} from 'client/ducks/animations/actions';

import AppButton from 'client/common/buttons';
import Icon from 'client/common/icon';
import Modal from 'client/common/modals/modal';
import LayoutPanel from 'client/common/panels/layout-panel';
import Popover from 'client/common/popovers/popover';

import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container';

import AnimationBrandInfo from './animation-brand-info';

import cssModule from './animation-brand-title.module.scss';

const b = bem('animation-brand-title', {cssModule});

const AnimationBrandTitle = () => {
  const lang = useLanguage('ANIMATIONS.ANIMATION_BRAND_TITLE');
  const [showInfoModal, toggleInfoModal] = useToggle(false);
  const [showEditModal, toggleEditModal] = useToggle(false);
  const {isTablet} = useAppMedia();
  const {formValues, change} = useReduxForm(AnimationConfigContainerFormName);
  const dispatch = useDispatch();

  if (!formValues?.place) {
    return null;
  }

  const {place = {}} = formValues || {};
  const {network = {}} = place;

  const changeFormContacts = (contactData) => {
    ['contact_name', 'contact_email', 'contact_phone', 'security_email', 'security_fax'].forEach((name) => {
      change(name, contactData[name]);
    });
  };

  const onChangeContacts = (contactData) => {
    dispatch(
      changeAnimationContacts(formValues?.id, {
        instore_animation: pick(
          contactData,
          'contact_name',
          'contact_email',
          'contact_phone',
          'security_email',
          'security_fax',
        ),
      }),
    );
    changeFormContacts(contactData);
  };

  return (
    <LayoutPanel className={b({mobile: isTablet})}>
      <div className={b('body')}>
        <div className={b('top')}>
          {!!network?.icon_url && <img className={b('brand-logo')} src={network?.icon_url} alt="" />}
          <span className={b('place-title', {mobile: isTablet})}>
            {place.name} ({place.city_name})
          </span>
          {!isTablet && (
            <Popover
              position="bottom"
              overlay={
                <AnimationBrandInfo
                  showEditForm={showEditModal}
                  animation={formValues}
                  onSubmit={onChangeContacts}
                  onEdit={toggleEditModal}
                  onClose={toggleEditModal}
                />
              }
            >
              <Icon name="info" className={b('show-info-btn')} width={20} height={20} />
            </Popover>
          )}
          {formValues?.week && !isTablet && (
            <span className={b('week-number')}>
              {lang.WEEK}
              {formValues.week}
            </span>
          )}
        </div>
        {isTablet && (
          <div className={b('bottom')}>
            <div className={b('address')}>
              <Icon name="map-line" className={b('address-icon')} />
              <p className={b('address-string')}>{place?.street_address}</p>
            </div>
            <AppButton
              className={b('contact-button')}
              label={lang.CONTACT}
              iconName="profile"
              onClick={toggleInfoModal}
              color="light-clients"
            />
          </div>
        )}
      </div>
      {showInfoModal && (
        <Modal
          onClose={toggleInfoModal}
          className={b('info-modal')}
          classNames={{
            body: b('info-modal-body'),
          }}
        >
          <AnimationBrandInfo
            showEditForm={showEditModal}
            animation={formValues}
            onSubmit={onChangeContacts}
            onEdit={toggleEditModal}
            onClose={toggleEditModal}
          />
        </Modal>
      )}
    </LayoutPanel>
  );
};

export default AnimationBrandTitle;
