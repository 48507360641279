import React, {useEffect} from 'react';

import get from 'lodash/get';
import {useSelector} from 'react-redux';
import {generatePath} from 'react-router';
import {useHistory, useParams} from 'react-router-dom';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useReduxFetch} from 'client/services/hooks';
import {useLanguage} from 'client/services/hooks';

import {getClientAccount} from 'client/ducks/client-account/actions';
import {selectClientAccount} from 'client/ducks/client-account/selectors';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';

import TabSwitcher from 'client/common/tab-switcher';

import AgencyClientModal from 'client/components/agencies/modals/agency-client-modal';
import {Client} from 'client/models/client/types';

import AccountBasicInfo from './account-basic-info';
import AccountContact from './account-contact';
import AccountPermissions from './account-permissions';
import AccountRegionsStores from './account-regions-stores';
import AccountSocialNetwork from './account-social-network';
import AccountStores from './account-stores';
import AccountUsers from './account-users';

import cssModule from './client-account-parameters.module.scss';

const b = bem('client-account-parameters', {cssModule});

type ClientAccountParametersProps = {
  client: Client;
  fetchClient: () => Promise<any>;
  rootPath: string;
};

export const TABS = {
  USERS: 'users',
  STORES: 'stores',
};

const ClientAccountParameters: React.FC<ClientAccountParametersProps> = (props) => {
  const {client, rootPath, fetchClient} = props;
  const lang = useLanguage('ACCOUNT_PARAMETERS');
  const history = useHistory();
  const {tab} = useParams<{tab: string}>();
  const [editClient, toggleEditClient] = useToggle(false);

  const currentClient = useSelector(selectCurrentClient);

  const clientIsAgency = client.type === 'Agency';
  const currentIsAgency = currentClient.type === 'Agency';
  const showPermissions = currentIsAgency && client.id !== currentClient.id && currentClient.client_management;

  useEffect(() => {
    if (!tab || (!client.client_network && tab === TABS.STORES)) {
      history.push(generatePath(rootPath, {tab: TABS.USERS}));
    }
  }, [client.client_network, tab, history, rootPath]);

  const {
    fetch: fetchClientAccount,
    data: clientAccount,
    loading,
  } = useReduxFetch({
    action: getClientAccount,
    actionArgs: client.id,
    selector: selectClientAccount,
  });

  const handleTabClick = (value: string) => {
    history.push(generatePath(rootPath, {tab: value}));
  };

  const getTabClassName = ({isActive}: {isActive: boolean}) => b('tab-link', {active: isActive});

  if (!clientAccount && !loading) {
    return null;
  }

  const handleCloseClientModal = () => {
    toggleEditClient();
  };

  const handleUpdateClient = () => {
    fetchClientAccount();
    fetchClient();
  };

  return (
    <div className={b()}>
      <div className={b('info')}>
        <div className={b('info-column')}>
          <AccountBasicInfo
            client={client}
            onEditClick={toggleEditClient}
            allowEditClient={currentIsAgency && client.id !== currentClient.id && currentClient.client_management}
          />
          <AccountContact
            loading={loading}
            client={client}
            clientAccount={clientAccount || null}
            onContactUpdate={handleUpdateClient}
          />
        </div>
        {!clientIsAgency && (
          <div className={b('info-column')}>
            <AccountRegionsStores clientAccount={clientAccount || {}} loading={loading} />
          </div>
        )}
        <div className={b('info-column')}>
          <AccountSocialNetwork
            client={client}
            socialAccounts={get(clientAccount, 'social_accounts', [])}
            updateMe={fetchClientAccount}
            loading={loading}
            height={currentClient.client_management ? 200 : null}
            fullHeight={!showPermissions}
          />
          {showPermissions && <AccountPermissions client={client} />}
        </div>
      </div>
      <TabSwitcher
        className={b('tabs')}
        tabs={[
          {title: lang.TABS.USERS, value: TABS.USERS, className: getTabClassName},
          {title: lang.TABS.STORES, value: TABS.STORES, className: getTabClassName, exclude: !client.client_network},
        ]}
        activeTab={tab}
        onTabClick={handleTabClick}
      />
      <div>
        {tab === TABS.USERS && <AccountUsers client={client} />}
        {tab === TABS.STORES && <AccountStores client={client} />}
      </div>

      {editClient && client.agency_id && (
        <AgencyClientModal
          agencyId={client.agency_id}
          onClose={handleCloseClientModal}
          onSubmit={handleUpdateClient}
          initialData={client}
        />
      )}
    </div>
  );
};

export default ClientAccountParameters;
