import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';

import Field from 'client/components/common/field';

import {TranslationJsx} from 'client/models/language/types';

class VariablesList extends PureComponent {
  static propTypes = {
    searchLabel: TranslationJsx.isRequired,
    onVariableClick: PropTypes.func.isRequired,
    searchPlaceholder: TranslationJsx.isRequired,
    variables: PropTypes.arrayOf(
      PropTypes.shape({
        full_name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      }),
    ).isRequired,
  };

  state = {
    search: '',
  };

  handleChangeSearch = ({target: {value: search}}) => this.setState({search});

  getFilteredVariables = () =>
    this.props.variables.filter((i) => i.full_name.toLowerCase().includes(this.state.search.toLowerCase()));

  render() {
    const {searchLabel, searchPlaceholder} = this.props;

    return (
      <div className="variables">
        <Field
          label={searchLabel}
          type="text"
          name="search"
          input={{
            value: this.state.search,
            onChange: this.handleChangeSearch,
          }}
          placeholder={searchPlaceholder}
        />
        {this.getFilteredVariables().map((variable, index) => (
          <div key={index} className="variables__item" onClick={() => this.props.onVariableClick(variable)}>
            {variable.full_name}
          </div>
        ))}
      </div>
    );
  }
}

export default VariablesList;
