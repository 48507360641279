import {DATA_COMPONENTS} from 'client/components/client-autotask/tabs/data-tab/data-common/constants';

import {mappingParticipationFilters, mappingWinningFilters, mappingLeadFilters} from './helpers';

export const FILE_OPTIONS = {
  CSV: 'csv',
  XLSX: 'xlsx',
};

export const EXPORT_FIELD_MAP = {
  [DATA_COMPONENTS.PARTICIPATIONS]: 'file_participation_export',
  [DATA_COMPONENTS.LEADS]: 'file_lead_export',
  [DATA_COMPONENTS.LEADS_DATABASE]: 'file_lead_export',
  [DATA_COMPONENTS.WINNERS]: 'file_winner_export',
};

export const REPORT_FIELD_MAP = {
  [DATA_COMPONENTS.PARTICIPATIONS]: 'participation_export_report',
  [DATA_COMPONENTS.LEADS]: 'lead_export_report',
  [DATA_COMPONENTS.WINNERS]: 'winner_export_report',
};

export const MAPPING_METHOD = {
  [DATA_COMPONENTS.PARTICIPATIONS]: mappingParticipationFilters,
  [DATA_COMPONENTS.LEADS]: mappingLeadFilters,
  [DATA_COMPONENTS.LEADS_DATABASE]: mappingLeadFilters,
  [DATA_COMPONENTS.WINNERS]: mappingWinningFilters,
};
