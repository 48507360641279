import React from 'react';

import {useLanguage} from 'client/services/hooks';

import ToolbarButton from 'client/common/text-editor/buttons/toolbar-button';
import {CustomEditor, TEXT_STYLE_OPTIONS, TEXT_STYLE_TO_LANG} from 'client/common/text-editor/constants';
import {editorMethods} from 'client/common/text-editor/helpers';

type StyleButtonsProps = {
  editor: CustomEditor;
  className?: string;
};

const StyleButtons = (props: StyleButtonsProps) => {
  const {editor, className} = props;

  const lang = useLanguage('COMMON.TEXT_EDITOR.LABELS');

  return (
    <div className={className}>
      {TEXT_STYLE_OPTIONS.map((style) => (
        <ToolbarButton
          key={style}
          onClick={() => {
            editorMethods.toggleMark(editor, style);
          }}
          iconName={style}
          active={editorMethods.isMarkActive(editor, style)}
          label={lang[TEXT_STYLE_TO_LANG[style]]}
          bordered={false}
        />
      ))}
    </div>
  );
};

export default StyleButtons;
