import React, {useCallback, useEffect, useMemo} from 'react';

import find from 'lodash/find';
import PropTypes from 'prop-types';
import {connect, useDispatch, useSelector} from 'react-redux';
import {reduxForm} from 'redux-form';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm from 'client/services/hooks/use-redux-form';

import {selectCampaignPlaces, selectCampaignRegions} from 'client/ducks/campaigns/selectors';
import {createOnlineInteraction, updateOnlineInteraction} from 'client/ducks/interactions/actions';
import {selectOnlineInteractionById} from 'client/ducks/interactions/selectors';
import {fetchSources} from 'client/ducks/sources/actions';
import {selectSourcesByKind} from 'client/ducks/sources/selectors';

import AppButton from 'client/common/buttons';
import {SOURCE_TYPES} from 'client/common/config';
import {DatepickerField, FieldWrap, SelectField, TextField} from 'client/common/fields';
import {useToast} from 'client/common/hooks/useToast';
import Modal from 'client/common/modals/modal';

import initial from './initial';
import mapValues from './mapValues';
import validate from './validate';

import {itemToOption} from '../helpers';

import './campaign-source-modal.scss';

const b = bem('campaign-source-modal');

const CampaignSourceModal = (props) => {
  const {show, interactionId, onClose, onSubmit, campaignId, initialized, handleSubmit} = props;
  const lang = useLanguage('CAMPAIGN.SOURCE_MODAL');
  const langSourceTypes = useLanguage('SOURCE_TYPES');
  const {formValues, change, invalid} = useReduxForm(CampaignSourceModal.formName);
  const sources = useSelector((state) => selectSourcesByKind(state, formValues?.kind));
  const regions = useSelector(selectCampaignRegions);
  const places = useSelector(selectCampaignPlaces);
  const dispatch = useDispatch();
  const {appendToastNotification} = useToast();

  const onlineInteraction = useSelector((state) => selectOnlineInteractionById(state, interactionId));

  const stores = useMemo(() => {
    const regionId = formValues?.region_id;
    const activePlaces = places.filter((i) => i.active);

    return regionId ? activePlaces.filter((place) => place.region_id === regionId) : activePlaces;
  }, [formValues?.region_id, places]);

  const getSources = useCallback(() => {
    if (initialized) {
      dispatch(
        fetchSources({
          q: {
            kind_eq: formValues?.kind,
          },
        }),
      );
    }
  }, [dispatch, formValues?.kind, initialized]);

  useEffect(() => {
    getSources();
  }, [getSources]);

  const handleChangeSource = (_, source_id) => {
    const {name} = formValues || {};
    if (source_id && !name) {
      const source = find(sources, {id: source_id});
      change('name', source.name);
    }
  };

  const handleChangeStore = (_, store_id) => {
    if (store_id && !formValues.region_id) {
      const place = find(places, {id: store_id});
      change('region_id', place.region_id);
    }
  };

  const handleChangeRegion = (_, region_id) => {
    if (formValues?.store_id && region_id) {
      const region = find(regions, {id: region_id});
      const place = find(region.places, {id: formValues?.store_id});
      if (!place) {
        change('store_id', null);
      }
    }
  };

  const handleSubmitForm = handleSubmit(async (values) => {
    const data = mapValues(values);

    const fetchInteraction = interactionId
      ? () => updateOnlineInteraction(onlineInteraction.id, data)
      : () =>
          createOnlineInteraction({
            ...data,
            interaction_group_id: campaignId,
          });

    const response = await dispatch(fetchInteraction());
    if (response.error) {
      appendToastNotification({type: 'error', description: response?.payload?.response?.errors?.base?.[0]});
    }
    onSubmit();
  });

  return (
    <Modal
      show={show}
      className="modal-window--width-1 theme-color-7"
      onClose={onClose}
      title={interactionId ? lang.EDIT_TITLE : lang.TITLE}
    >
      <form onSubmit={handleSubmitForm}>
        <SelectField
          options={Object.keys(SOURCE_TYPES).map((key) => ({
            value: key,
            label: langSourceTypes[SOURCE_TYPES[key]],
          }))}
          label={lang.TYPE_LABEL}
          name="kind"
          simpleValue={true}
          withWrap={true}
          required={true}
        />
        <SelectField
          name="source_id"
          onChange={handleChangeSource}
          simpleValue={true}
          label={lang.SOURCE_LABEL}
          options={sources?.map(itemToOption)}
          withWrap={true}
        />
        <TextField label={lang.NAME_LABEL} name="name" withWrap={true} required={true} />
        <TextField label={lang.COMMENTS} name="comment" withWrap={true} />
        <TextField label={lang.URL_LABEL} name="url" withWrap={true} />
        <TextField label={lang.PARAM_1_LABEL} name="parameter1" withWrap={true} />
        <TextField label={lang.PARAM_2_LABEL} name="parameter2" withWrap={true} />
        <TextField label={lang.PARAM_3_LABEL} name="parameter3" withWrap={true} />
        <SelectField
          simpleValue={true}
          withWrap={true}
          label={lang.REGION_LABEL}
          name="region_id"
          onChange={handleChangeRegion}
          options={regions.map(itemToOption)}
        />
        <SelectField
          simpleValue={true}
          withWrap={true}
          searchable={true}
          label={lang.STORE_LABEL}
          name="store_id"
          onChange={handleChangeStore}
          options={stores.map(itemToOption)}
        />

        <FieldWrap className={b('row', ['period'])}>
          <DatepickerField name="from" label={lang.FROM_LABEL} required={true} />
          <DatepickerField name="to" label={lang.TO_LABEL} required={true} />
        </FieldWrap>
        <FieldWrap className={b('row', ['period'])}>
          <TextField name="fromTime" placeholder={lang.TIME_PLACEHOLDER} />
          <TextField name="toTime" placeholder={lang.TIME_PLACEHOLDER} />
        </FieldWrap>

        <SelectField
          options={[
            {value: true, label: lang.STATUS_ACTIVE},
            {value: false, label: lang.STATUS_INACTIVE},
          ]}
          name="active"
          label={lang.STATUS_LABEL}
          simpleValue={true}
          withWrap={true}
          required={true}
        />
        <div className={b('row', ['buttons'])}>
          <AppButton
            nativeStyle={true}
            label={lang.CANCEL_BUTTON}
            className="button button--bg-11 "
            onClick={onClose}
          />
          <AppButton
            disabled={invalid}
            nativeStyle={true}
            label={interactionId ? lang.SAVE_BUTTON : lang.CONFIRM_BUTTON}
            className="button button--bg-6"
            submit={true}
          />
        </div>
      </form>
    </Modal>
  );
};

CampaignSourceModal.formName = 'CampaignSourceModal';
CampaignSourceModal.displayName = 'CampaignSourceModal';

CampaignSourceModal.propTypes = {
  show: PropTypes.bool.isRequired,
  initialized: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  interactionId: PropTypes.number,
  campaignId: PropTypes.number.isRequired,
};

CampaignSourceModal.defaultProps = {
  interactionId: null,
};

export default connect((state, props) => ({
  langErrors: state.languageState.payload.CAMPAIGN.SOURCE_MODAL.ERRORS,
  onlineInteraction: selectOnlineInteractionById(state, props.interactionId),
  initialValues: {...initial(selectOnlineInteractionById(state, props.interactionId))},
}))(
  reduxForm({
    form: CampaignSourceModal.formName,
    validate,
  })(CampaignSourceModal),
);
