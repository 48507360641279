import {Animation} from 'client/models/animations/types';

import {AnimationBrandInfoForm} from './index';

export default (animation: Animation): AnimationBrandInfoForm => {
  return {
    contact_name: animation?.contact_name,
    contact_email: animation?.contact_email,
    contact_phone: animation?.contact_phone,
    security_email: animation?.security_email,
    security_fax: animation?.security_fax,

    place: animation?.place,
    network: animation?.place?.network,
  };
};
