import React from 'react';

import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

import bem from 'client/services/bem';

import {PARTICIPANTS_SORT_MAP} from 'client/ducks/participants-list/constants';

import DateCell from 'client/common/cells/date-cell';

import AutomationTaskIdCell from 'client/components/participants/lists/participants-common-list/cells/automation-task-id-cell';
import EmptyCell from 'client/components/participants/lists/participants-common-list/cells/empty-cell';
import FileCell from 'client/components/participants/lists/participants-common-list/cells/file-cell';
import InteractionTypeCell from 'client/components/participants/lists/participants-common-list/cells/interaction-type-cell';
import OperationIdCell from 'client/components/participants/lists/participants-common-list/cells/operation-id-cell';
import OptInsCell from 'client/components/participants/lists/participants-common-list/cells/optins-cell';
import VisualsCell from 'client/components/participants/lists/participants-common-list/cells/visuals-cell';

import AccessLevelCell from './cells/access-level-cell';
import TestModeSwitcherCell from './cells/test-mode-switcher-cell';
import TextCell from './cells/text-cell';

const KIND_RENDERER = {
  datetime: (props) => <DateCell {...props} isColumn />,
  date: (props) => <DateCell {...props} hideTime />,
  file: FileCell,
};

const KIND_EMPTY_DATA = {
  datetime: () => <EmptyCell isDate />,
  date: () => <EmptyCell isDate />,
};

const CUSTOM_CONFIGS = {
  lead__visuals_count: {
    component: VisualsCell,
    header: 'VISUALS_TABLE_COLUMN',
  },
  lead__opt_ins: {
    component: OptInsCell,
  },
  operation__id: {
    component: OperationIdCell,
    header: 'OPERATION_TABLE_COLUMN',
  },
  automation_task__id: {
    component: AutomationTaskIdCell,
    header: 'TASK_TABLE_COLUMN',
  },
  participation__expires_at: {
    component: KIND_RENDERER.date,
    header: 'EXPIRES_AT',
  },
  participation__test_mode: {
    component: TestModeSwitcherCell,
    header: 'TEST_DATA',
  },
  participation__min_level_reached_at: {
    component: KIND_RENDERER.datetime,
    header: 'STATUS_AT',
  },
  participation__accessed_level: {
    component: AccessLevelCell,
    header: 'ACCESSED_LEVEL',
  },
  participation__store_name: {
    header: 'STORE',
  },
  participation__region_name: {
    header: 'REGION',
  },
  interaction__type: {
    component: InteractionTypeCell,
  },
};

const b = bem('participants-common-list');

export const prepareColumns = (displayColumns, {lang, isAdmin}) => {
  return displayColumns?.map((column) => {
    const {column_adapter} = column;

    const mapName = [
      column_adapter?.participation_association_type || column_adapter?.record_type,
      column_adapter?.column?.readable_name || column_adapter?.column?.name || column_adapter?.name,
    ]
      .filter(Boolean)
      .map((s) => s.toLowerCase())
      .join('__');

    const customConfig = CUSTOM_CONFIGS[mapName];

    const RenderComponent = customConfig?.component || KIND_RENDERER[column_adapter?.kind] || TextCell;
    const RenderEmpty = KIND_EMPTY_DATA[column_adapter?.kind] || EmptyCell;

    const label =
      lang[customConfig?.header] ||
      [
        column_adapter?.record_type,
        column_adapter?.db_number,
        (column_adapter?.column_type === 'InterfaceItem' && column_adapter.name) ||
          column_adapter?.column?.readable_name ||
          column_adapter?.column?.name ||
          column_adapter?.name,
      ]
        .filter(Boolean)
        .join(' ');

    return {
      sortable: !!PARTICIPANTS_SORT_MAP[mapName] || column.sortable,
      name: PARTICIPANTS_SORT_MAP[mapName] || [mapName, column_adapter?.db_number].filter(Boolean).join('_'),
      path: column.id?.toString(),
      label: label,
      width: 150,
      render: ({item, value}) => (
        <div className={b('cell')}>
          {isUndefined(value) || isNull(value) || value === '' ? (
            <RenderEmpty />
          ) : (
            <RenderComponent participation={item} value={value} path={column.id?.toString()} disabled={!isAdmin} />
          )}
        </div>
      ),
    };
  });
};
