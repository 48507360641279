import React from 'react';

import {Field} from 'redux-form';

import DatetimePicker from '../../inputs/datetime-picker';
import BaseFieldComponent from '../base-field';

const DatetimePickerFieldComponent = (props) => <BaseFieldComponent Component={DatetimePicker} {...props} />;

const DatetimePickerField = (props) => {
  return <Field component={DatetimePickerFieldComponent} {...props} />;
};

export default DatetimePickerField;
