import moment from 'moment';

export const mapApiLeadImports = (data) => {
  return data.map((item) => {
    return {
      ...item,
      from: moment(item.from).format('YYYY-MM-DD'),
      to: moment(item.to).format('YYYY-MM-DD'),
    };
  });
};
