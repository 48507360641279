import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {patchAutotask} from 'client/ducks/autotask/actions';

import {CLIENT_PAGES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';
import CustomScrollbars from 'client/components/common/custom-scrollbars';

import './autotask-list.scss';

class AutotaskList extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    autotask: PropTypes.object,
    clientType: PropTypes.string.isRequired,
    clientId: PropTypes.number.isRequired,
    patchAutotask: PropTypes.func.isRequired,
  };

  static defaultProps = {
    autotask: {},
  };

  static InteractionTypes = {
    CAMPAIGN: 'Campaign',
    PLAN: 'Plan',
  };

  static scrollbarsHeight = 225;

  constructor(props) {
    super(props);

    this.LANGUAGE = this.props.languageState.payload.OPERATION_PAGE;

    this.autotaskLink = CustomLink.createTaskLink({
      taskType: props.autotask.type,
      taskId: props.autotask.id,
      clientId: props.clientId,
      clientType: props.clientType,
      operationId: props.autotask.operation_id,
    });
  }

  renderCampaign = (campaign) => (
    <div key={campaign.id} className="autotask-list__cell-link">
      <Link
        to={`${CLIENT_PAGES.CAMPAIGN}/${campaign.id}`}
        className="link display-block ellipsis-text"
        title={campaign.name}
      >
        {campaign.name}
      </Link>
    </div>
  );

  renderPlan = (plan) => (
    <div key={plan.id} className="autotask-list__cell-link">
      <Link
        key={plan.id}
        to={`${CLIENT_PAGES.PLANS}/${plan.id}${CLIENT_PAGES.DEVICES}`}
        className="link display-block ellipsis-text"
        title={plan.name}
      >
        {plan.name}
      </Link>
    </div>
  );

  renderInterface = (_interface) => (
    <div key={_interface.id} className="autotask-list__cell-link">
      <Link to={this.autotaskLink} className="link display-block ellipsis-text" title={_interface.name}>
        {_interface.name}
      </Link>
    </div>
  );

  renderScenario = (scenario) => (
    <div key={scenario.id} className="autotask-list__cell-link">
      <Link
        to={`${CLIENT_PAGES.SCENARIO}/${scenario.id}`}
        className="link display-block ellipsis-text"
        title={scenario.name}
      >
        {scenario.name}
      </Link>
    </div>
  );

  renderInteraction = (interaction_groups, type) => {
    return interaction_groups.map((el) => {
      switch (type) {
        case AutotaskList.InteractionTypes.PLAN:
          return el.type === AutotaskList.InteractionTypes.PLAN ? this.renderPlan(el) : null;
        case AutotaskList.InteractionTypes.CAMPAIGN:
          return el.type === AutotaskList.InteractionTypes.CAMPAIGN ? this.renderCampaign(el) : null;
        default:
          return null;
      }
    });
  };

  renderInterfaces = (interfaces) => {
    if (interfaces) {
      return interfaces.map((_interface) => this.renderInterface(_interface));
    }
    return null;
  };

  renderScenarios = (scenarios) => {
    if (scenarios) {
      return scenarios.map((scenario) => this.renderScenario(scenario));
    }
    return null;
  };

  toggleVisibleByClient = () => {
    this.props.patchAutotask(this.props.autotask.id, {
      visible_by_client: !this.props.autotask.visible_by_client,
    });
  };

  renderItem = (autotask) => {
    const {name, image, scenarios, interfaces, interaction_groups} = autotask;

    return (
      <div className="content-panel__panel autotask-list__item">
        <div className="content-panel__panel-inner">
          <div className="content-panel__row">
            <div className="autotask-list__col ellipsis-text">
              <img className="autotask-list__image" src={image.url} />
              <CustomLink to={this.autotaskLink} className="link" title={name}>
                {name}
              </CustomLink>
            </div>
            <div className="autotask-list__col">
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMax: AutotaskList.scrollbarsHeight,
                  autoHeightMin: AutotaskList.scrollbarsHeight,
                }}
              >
                {this.renderScenarios(scenarios)}
              </CustomScrollbars>
            </div>
            <div className="autotask-list__col">
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMax: AutotaskList.scrollbarsHeight,
                  autoHeightMin: AutotaskList.scrollbarsHeight,
                }}
              >
                {this.renderInterfaces(interfaces)}
              </CustomScrollbars>
            </div>
            <div className="autotask-list__col ellipsis-text">
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMax: AutotaskList.scrollbarsHeight,
                  autoHeightMin: AutotaskList.scrollbarsHeight,
                }}
              >
                {this.renderInteraction(interaction_groups, AutotaskList.InteractionTypes.PLAN)}
              </CustomScrollbars>
            </div>
            <div className="autotask-list__col">
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMax: AutotaskList.scrollbarsHeight,
                  autoHeightMin: AutotaskList.scrollbarsHeight,
                }}
              >
                {this.renderInteraction(interaction_groups, AutotaskList.InteractionTypes.CAMPAIGN)}
              </CustomScrollbars>
            </div>
            <div className="autotask-list__col">
              <div className="switcher__input-wrap">
                <input
                  className="switcher__input"
                  type="checkbox"
                  name="add-stores"
                  value="0"
                  onChange={() => {}}
                  checked={autotask.visible_by_client}
                />
                <div className="switcher__label" onClick={this.toggleVisibleByClient} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {autotask} = this.props;

    return (
      <div className="content-panel autotask-list">
        <div className="content-panel__header autotask-list__header">
          <div className="autotask-list__col">{this.LANGUAGE.AUTOMATION_TASK.NAME_COLUMN}</div>
          <div className="autotask-list__col">{this.LANGUAGE.AUTOMATION_TASK.SCENARIOS_COLUMN}</div>
          <div className="autotask-list__col">{this.LANGUAGE.AUTOMATION_TASK.INTERFACES_COLUMN}</div>
          <div className="autotask-list__col">{this.LANGUAGE.AUTOMATION_TASK.PLAN_COLUMN}</div>
          <div className="autotask-list__col">{this.LANGUAGE.AUTOMATION_TASK.CAMPAIGNS_COLUMN}</div>
          <div className="autotask-list__col">{this.LANGUAGE.AUTOMATION_TASK.VISIBLE_FOR_CLIENTS_COLUMN}</div>
        </div>
        {this.renderItem(autotask)}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    languageState: state.languageState,
  }),
  {
    patchAutotask,
  },
)(AutotaskList);
