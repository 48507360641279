import React, {Component} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';

import {TranslationJsx} from 'client/models/language/types';
import {ReactNode} from 'client/types';

import './error-boundary.scss';

const b = bem('error-boundary');

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    this.props.onChangeError?.({hasError: !!error});
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    const {hasError} = this.state;
    const {children, message} = this.props;

    if (hasError) {
      return (
        <div className={b()}>
          <Icon name="warning" className={b('icon')} height={42} width={42} />
          <p className={b('message')}>{message}</p>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: ReactNode.isRequired,
  message: TranslationJsx.isRequired,
  onChangeError: PropTypes.func,
};

ErrorBoundary.defaultProps = {
  onChangeError: null,
};

export default ErrorBoundary;
