import {callApi} from 'client/services/call-api';
import {getEmail, getToken} from 'client/services/cookie-data-source';
import {objectToFormData} from 'client/services/formData';

import {API_METHODS, API_PATH} from 'client/common/config';

import types from './types';

export function clearInterfacesState() {
  return {
    type: types.CLEAR_INTERFACES_STATE,
  };
}

export function getInterfacesAction(queryParams = {}, isForValidation = false) {
  const getInterfacesForValidationActionTypes = [
    types.GET_INTERFACES_FOR_VALIDATION_REQUEST,
    types.GET_INTERFACES_FOR_VALIDATION_SUCCESS,
    types.GET_INTERFACES_FOR_VALIDATION_ERROR,
  ];
  const getInterfacesActionTypes = [
    types.GET_INTERFACES_REQUEST,
    types.GET_INTERFACES_SUCCESS,
    types.GET_INTERFACES_ERROR,
  ];
  const actionTypes = isForValidation ? getInterfacesForValidationActionTypes : getInterfacesActionTypes;
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACES}`,
      method: 'GET',
      types: actionTypes,
      noCache: true,
      queryParams,
    });
  };
}

export function patchInterfaceAction(id, body = {}, queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACES}/${id}`,
      method: 'PATCH',
      types: [types.PATCH_INTERFACE_REQUEST, types.PATCH_INTERFACE_SUCCESS, types.PATCH_INTERFACE_ERROR],
      queryParams,
      body,
    });
  };
}

export function patchInterfaceLevels(id, body = {}) {
  const data = {
    interface: {
      interface_levels: body,
    },
  };

  return () =>
    fetch(`${API_PATH}${API_METHODS.INTERFACES}/${id}`, {
      method: 'PATCH',
      headers: {
        'X-User-Email': getEmail(),
        'X-User-Token': getToken(),
      },
      body: objectToFormData(data),
    });
}

export function createInternalInterface(body = {}, queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERNAL_INTERFACES}`,
      method: 'POST',
      types: [
        types.CREATE_INTERNAL_INTERFACE_REQUEST,
        types.CREATE_INTERNAL_INTERFACE_SUCCESS,
        types.CREATE_INTERNAL_INTERFACE_ERROR,
      ],
      headers: {
        'cache-control': 'no-cache',
      },
      queryParams,
      body,
    });
  };
}

export function deleteInterface(id) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACES}/${id}`,
      method: 'DELETE',
      types: [types.DELETE_INTERFACE_REQUEST, types.DELETE_INTERFACE_SUCCESS, types.DELETE_INTERFACE_ERROR],
    });
  };
}

export function deleteInterfaceItem(id) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACE_ITEMS}/${id}`,
      method: 'DELETE',
      types: [
        types.DELETE_INTERFACE_ITEM_REQUEST,
        types.DELETE_INTERFACE_ITEM_SUCCESS,
        types.DELETE_INTERFACE_ITEM_ERROR,
      ],
    });
  };
}

export function createInterfaceItem(body = {}, queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACE_ITEMS}`,
      method: 'POST',
      types: [
        types.CREATE_INTERFACE_ITEM_REQUEST,
        types.CREATE_INTERFACE_ITEM_SUCCESS,
        types.CREATE_INTERFACE_ITEM_ERROR,
      ],
      queryParams,
      body,
    });
  };
}

export function patchInterfaceItem(id, body = {}, queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACE_ITEMS}/${id}`,
      method: 'PATCH',
      types: [types.PATCH_INTERFACE_ITEM_REQUEST, types.PATCH_INTERFACE_ITEM_SUCCESS, types.PATCH_INTERFACE_ITEM_ERROR],
      queryParams,
      body,
    });
  };
}

export function createDeviceInterface(interfaceId, state) {
  const deviceSynchronizedAt = ['published', 'republished'].includes(state)
    ? {
        device_synchronized_at: new Date(),
      }
    : null;

  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACES}/${interfaceId}`,
      method: 'PUT',
      types: [
        types.CREATE_DEVICE_INTERFACE_REQUEST,
        types.CREATE_DEVICE_INTERFACE_SUCCESS,
        types.CREATE_DEVICE_INTERFACE_ERROR,
      ],
      body: {
        ...deviceSynchronizedAt,
        device_state: state,
      },
    });
  };
}

export function updateDeviceInterface(interfaceId) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACES}/${interfaceId}/${API_METHODS.DEVICE_REPUBLISH}`,
      method: 'POST',
      types: [
        types.UPDATE_DEVICE_INTERFACE_REQUEST,
        types.UPDATE_DEVICE_INTERFACE_SUCCESS,
        types.UPDATE_DEVICE_INTERFACE_ERROR,
      ],
    });
  };
}

export function createWebAppInterface(id, body = {}, queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACES}/${id}/${API_METHODS.WEB_APP_PUBLISH}`,
      method: 'POST',
      types: [
        types.CREATE_WEB_APP_INTERFACE_REQUEST,
        types.CREATE_WEB_APP_INTERFACE_SUCCESS,
        types.CREATE_WEB_APP_INTERFACE_ERROR,
      ],
      queryParams,
      body,
    });
  };
}

export function updateWebAppInterface(id, body = {}, queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACES}/${id}/${API_METHODS.WEB_APP_REPUBLISH}`,
      method: 'POST',
      types: [
        types.PATCH_WEB_APP_INTERFACE_REQUEST,
        types.PATCH_WEB_APP_INTERFACE_SUCCESS,
        types.PATCH_WEB_APP_INTERFACE_ERROR,
      ],
      queryParams,
      body,
    });
  };
}

export function deleteWebAppInterface(id) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACES}/${id}/${API_METHODS.WEB_APP_UNPUBLISH}`,
      method: 'POST',
      types: [
        types.REMOVE_WEB_APP_INTERFACE_REQUEST,
        types.REMOVE_WEB_APP_INTERFACE_SUCCESS,
        types.REMOVE_WEB_APP_INTERFACE_ERROR,
      ],
    });
}

/**
 * @type {import('client/types/common/redux').ApiAction}
 */
export function webAppTesting(id) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACES}/${id}/${API_METHODS.WEB_APP_TESTING}`,
      method: 'POST',
      types: [
        types.TESTING_WEB_APP_INTERFACE_REQUEST,
        types.TESTING_WEB_APP_INTERFACE_SUCCESS,
        types.TESTING_WEB_APP_INTERFACE_ERROR,
      ],
    });
}
