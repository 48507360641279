import React, {ChangeEvent, useEffect, useState} from 'react';

import {useDispatch} from 'react-redux';
import {BaseRange, Editor, Range, Text, Transforms} from 'slate';
import {useSlate} from 'slate-react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {addEmailTemplateVariable} from 'client/ducks/email-templates/actions';

import {FieldWrap} from 'client/common/fields';
import Icon from 'client/common/icon';
import {TextInput} from 'client/common/inputs';
import Popover from 'client/common/popovers/popover';
import ToolbarButton from 'client/common/text-editor/buttons/toolbar-button';
import {CustomText} from 'client/common/text-editor/constants';
import {editorMethods} from 'client/common/text-editor/helpers';

import ParametersVariableDropdown from 'client/components/email-template-editor/inputs/parameters-variable-dropdown';

import cssModule from './link-input.module.scss';

const b = bem('link-input', {cssModule});

type LinkInputProps = {
  onSubmit: (link: string, text: string) => void;
};

const LinkInput: React.FC<LinkInputProps> = ({onSubmit}) => {
  const editor = useSlate();
  const dispatch = useDispatch();
  const selection = editor.selection;
  const lang = useLanguage('COMMON.TEXT_EDITOR.LABELS');

  const [link, setLink] = useState('');
  const [text, setText] = useState('');

  const handleVariableSelect = (variable: string) => {
    setLink((prevValue) => `${prevValue}{{var:${variable}}}`);
    dispatch(addEmailTemplateVariable(variable));
  };

  const unlink = () => {
    if (editorMethods.isMarkActive(editor, 'url')) {
      editorMethods.toggleMark(editor, 'url');
      setText('');
      setLink('');
    }
  };

  const isCollapsed = Range.isExpanded(selection as BaseRange);
  useEffect(() => {
    const isLinkSelected = 'url' in (Editor.marks(editor) || {});

    const [start] = Range.edges(selection as BaseRange);
    const path = start.path;
    const currentNode = Editor.node(editor, path)[0] as CustomText;
    const {text: linkText, url: linkUrl} = currentNode;

    if (isLinkSelected && linkUrl) {
      setLink(linkUrl);
    }

    if (isCollapsed) {
      setText(Editor.string(editor, selection as BaseRange));
    } else if (isLinkSelected) {
      setText(linkText);

      const [match] = Editor.nodes(editor, {
        match: (n) => Text.isText(n) && Boolean(n.url),
      });

      if (match) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, linkPath] = match;
        const linkStart = Editor.start(editor, linkPath);
        const linkEnd = Editor.end(editor, linkPath);
        Transforms.select(editor, {anchor: linkStart, focus: linkEnd});
      }
    }
  }, [editor, selection, isCollapsed]);

  return (
    <div className={b()}>
      <div>
        <FieldWrap className={b('input-wrap')}>
          <TextInput
            label={lang.LINK}
            placeholder="https://example.com"
            className={b('input')}
            value={link}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setLink(event.target?.value)}
          />
          <div className={b('input-buttons')}>
            <ParametersVariableDropdown
              onSelect={handleVariableSelect}
              trigger={<Icon name="braces" className={b('icon')} />}
            />
            <Popover overlay={lang.UNLINK} position="top" className={b('unlink')} hideOnMouseLeave disableFocus>
              <Icon name="unlink" className={b('icon')} onClick={unlink} />
            </Popover>
          </div>
        </FieldWrap>
        <TextInput
          label={lang.DISPLAY_TEXT}
          className={b('input')}
          value={text}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setText(event.target?.value)}
        />
      </div>

      <ToolbarButton
        iconName="check"
        className={b('button')}
        iconProps={{width: 24, height: 24}}
        onClick={() => onSubmit(link, text)}
        disabled={!link || !text}
      />
    </div>
  );
};

export default LinkInput;
