import {mapCoupons} from './mappers';
import types from './types';

const initialState = {
  coupons: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_COUPONS_SUCCESS:
      return {
        ...state,
        coupons: mapCoupons(action.payload?.coupons || []),
      };

    default:
      return state;
  }
}
