import {COMMON_INPUT} from 'client/components/prizes/constants';
import {PrizeMap} from 'client/models/prizes/types';

export default (values: PrizeMap, {defaultLanguage}: {defaultLanguage: string}) => {
  return {
    activeLanguage: values.language_unique_mode ? COMMON_INPUT : defaultLanguage,
    email_send: values.email_send,
    sms_send: values.sms_send,
    level: values.level,
    distribution: values.distribution,
    ticket_print: values.ticket_print,
    external_prize_name_translations: values.external_prize_name_translations,
  };
};
