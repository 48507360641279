import {ANIMATION_ANOMALY_STATUSES} from 'client/components/instore/constants';

export const sortAnimationDays = (animationDays) => {
  if (!animationDays) {
    return null;
  }

  return animationDays.sort((a, b) => new Date(a.from) - new Date(b.from));
};

export const getAnimationAnomalyStatus = (item) => {
  if (!item) {
    return null;
  }

  const total = item.length;
  const unviewed = item.filter((i) => !i.viewed).length > 0;
  const manually_unviewed = item.filter((i) => i.manual_unviewed).length;

  if (!total) {
    return null;
  } else if (unviewed || manually_unviewed) {
    return ANIMATION_ANOMALY_STATUSES.UNVIEWED;
  }

  return ANIMATION_ANOMALY_STATUSES.VIEWED;
};

export const sortQuestions = (questions) => {
  return questions.sort(
    (prevQ, nextQ) => (nextQ.order === null || (prevQ.order !== null && nextQ.order > prevQ.order) ? -1 : 1), // sort by order, putting the nulls at the end));
  );
};
