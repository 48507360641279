import React from 'react';

import PropTypes from 'prop-types';

import {getStringFromArrayMap} from 'client/services/helpers';

import {FILTERED_GENDER_TYPES, MESSAGE_TASK_STATES} from 'client/common/config';

import BaseToolbar from 'client/components/common/base-toolbar';

import BCResultFiltersModal from 'client/components/broadcasting/components/modals/bc-result-filters-modal';
import {TASK_TYPES} from 'client/models/operations/constants';

function BCResultsToolbar(props) {
  const {
    onShowFilterClick,
    onClearFiltersClick,
    onFilterDeleteClick,
    searchField,
    language,
    resultsNum,
    queryParams,
    taskType,
    taskState,
  } = props;

  const filters = queryParams.filters || {};
  const map = BCResultFiltersModal.filtersMap;
  const filtersLang = language.FILTERS_MODAL;
  const filtersArray = BCResultsToolbar.createFiltersArray(filters, map, filtersLang, taskType, taskState);
  const numFiltersApplied = filtersArray.filter((item) => item.applied).length;

  return (
    <div className="flex-container flex-align-bottom flex-justify-around">
      <BaseToolbar
        searchField={searchField}
        resultsNum={resultsNum}
        filterBtnClass="button--bg-14"
        clearFiltersBtnClass="button--bg-14"
        onShowFilterClick={onShowFilterClick}
        onClearFiltersClick={onClearFiltersClick}
        onFiltersClick={onFilterDeleteClick(filters)}
        textLabels={{
          filtersButton: `${language.TABLE.FILTERS_BUTTON} (${numFiltersApplied})`,
          clearFilters: language.TABLE.CLEAR_FILTER_BUTTON,
          resultsNum: language.TABLE.RESULTS,
        }}
        filters={filtersArray}
      />
    </div>
  );
}

BCResultsToolbar.createFiltersArray = (filters, map, filtersLang, taskType, taskState) => {
  const isSMSTask = taskType === TASK_TYPES.SMS;
  const isNewTask = taskState === MESSAGE_TASK_STATES.NEW;

  const OPEN_EVENT_MAP = {
    [map.open_event_successfully.opened]: filtersLang.OPENED_RADIO,
    [map.open_event_successfully.notOpened]: filtersLang.NOT_OPENED_RADIO,
  };

  const UNSUBSCRIBED_MAP = {
    [map.unsubscribed.clicked]: filtersLang.CLICKED_RADIO,
    [map.unsubscribed.notClicked]: filtersLang.NOT_CLICKED_RADIO,
  };

  const DELIVERED_MAP = {
    [map.delivered_event_successfully.delivered]: filtersLang.DELIVERED_RADIO,
    [map.delivered_event_successfully.notDelivered]: filtersLang.NOT_DELIVERED_RADIO,
  };

  return [
    {
      name: map.lead_gender_eq.field,
      applied: filters[map.lead_gender_eq.field],
      label: `${filtersLang.GENDER_LABEL}: ${filtersLang[FILTERED_GENDER_TYPES[filters[map.lead_gender_eq.field]]]}`,
    },
    {
      name: map.lead_phone_present.field,
      applied: filters[map.lead_phone_present.field],
      label: filtersLang.WITH_PHONE_RADIO,
    },
    {
      name: map.lead_email_present.field,
      applied: filters[map.lead_email_present.field],
      label: filtersLang.WITH_EMAIL_RADIO,
    },
    {
      name: map.queued_event_successfully.field,
      applied:
        !isNewTask &&
        (filters[map.queued_event_successfully_true.field] ||
          filters[map.queued_event_successfully_false.field] ||
          filters[map.queued_event_successfully_null.field]),
      label:
        (isSMSTask ? filtersLang.SENT_TO_TWILIO_LABEL : filtersLang.SENT_TO_LABEL) +
        ': ' +
        getStringFromArrayMap(Object.keys(filters), {
          [map.queued_event_successfully_true.field]: filtersLang.SENT_RADIO,
          [map.queued_event_successfully_null.field]: filtersLang.NOT_SENT_RADIO,
          [map.queued_event_successfully_false.field]: filtersLang.ERROR_RADIO,
        }),
    },
    {
      name: map.sent_event_successfully.field,
      applied:
        !isNewTask &&
        (filters[map.sent_event_successfully_true.field] ||
          filters[map.sent_event_successfully_false.field] ||
          filters[map.sent_event_successfully_null.field]),
      label:
        (isSMSTask ? filtersLang.SENT_BY_TWILIO_LABEL : filtersLang.SENT_BY_LABEL) +
        ': ' +
        getStringFromArrayMap(Object.keys(filters), {
          [map.sent_event_successfully_true.field]: filtersLang.SENT_RADIO,
          [map.sent_event_successfully_null.field]: filtersLang.NOT_SENT_RADIO,
          [map.sent_event_successfully_false.field]: filtersLang.ERROR_RADIO,
        }),
    },
    {
      name: map.open_event_successfully.field,
      applied: !isNewTask && !isSMSTask && filters[map.open_event_successfully.field],
      label: filtersLang.OPEN_LABEL + ': ' + OPEN_EVENT_MAP[filters[map.open_event_successfully.field]],
    },
    {
      name: map.unsubscribed.field,
      applied: !isNewTask && !isSMSTask && filters[map.unsubscribed.field],
      label: filtersLang.UNSUBSCRIBE_LABEL + ': ' + UNSUBSCRIBED_MAP[filters[map.unsubscribed.field]],
    },
    {
      name: map.delivered_event_successfully.field,
      applied: !isNewTask && isSMSTask && filters[map.delivered_event_successfully.field],
      label: filtersLang.DELIVERED_LABEL + ': ' + DELIVERED_MAP[filters[map.delivered_event_successfully.field]],
    },
  ];
};

BCResultsToolbar.propTypes = {
  taskType: PropTypes.string.isRequired,
  taskState: PropTypes.string.isRequired,
  searchField: PropTypes.node.isRequired,
  language: PropTypes.object.isRequired,
  onShowFilterClick: PropTypes.func.isRequired,
  onClearFiltersClick: PropTypes.func.isRequired,
  onFilterDeleteClick: PropTypes.func.isRequired,
  resultsNum: PropTypes.number.isRequired,
  queryParams: PropTypes.object.isRequired,
};

export default BCResultsToolbar;
