import {useMemo} from 'react';

import {useSelector} from 'react-redux';

import {selectOperation, selectClientInterface} from 'client/ducks/operations/selectors';

import {DEFAULT_DEVICE_OPTIONS} from 'client/components/diy-operation/controls/lang-device-control';
import {getDefaultScreenFormat} from 'client/components/diy-operation/modals/diy-customization-modal/helpers';
import {Template} from 'client/models/templates/types';

type Params = {
  template?: Template;
};

const useCustomizationPreviewLanguagesDevices = (params: Params) => {
  const {template} = params;

  const operation = useSelector(selectOperation);
  const currentInterface = useSelector(selectClientInterface);
  const defaultLanguage = (currentInterface || template)?.default_language;

  const formats = (currentInterface || template)?.screen_formats;
  const devices = useMemo(() => {
    return formats ? DEFAULT_DEVICE_OPTIONS.filter((device) => formats.includes(device.value)) : [];
  }, [formats]);

  const languages = (operation || template)?.languages?.map((language: string) => ({
    label: language,
    value: language,
  }));

  return {languages, devices, defaultLanguage, defaultDevice: getDefaultScreenFormat(devices)};
};

export default useCustomizationPreviewLanguagesDevices;
