const defaultOptions = {
  elementDelimiter: '__',
  modifierDelimiter: '--',
  namespace: '',
  namespaceDelimiter: '-',
  strict: true,
  cssModule: null,
};

export function setup({elementDelimiter, modifierDelimiter, namespace, namespaceDelimiter, strict}) {
  if (typeof elementDelimiter === 'string') {
    defaultOptions.elementDelimiter = elementDelimiter;
  }
  if (typeof modifierDelimiter === 'string') {
    defaultOptions.modifierDelimiter = modifierDelimiter;
  }
  if (typeof namespace === 'string' || Array.isArray(namespace)) {
    defaultOptions.namespace = namespace;
  }
  if (typeof namespaceDelimiter === 'string') {
    defaultOptions.namespaceDelimiter = namespaceDelimiter;
  }
  if (typeof strict === 'boolean') {
    defaultOptions.strict = strict;
  }
}

const uniqueChars = (list) => list.filter((value, index, self) => self.indexOf(value) === index);

const includesChars = (str, chars) => chars.some((char) => str.includes(char));

const invalidMessage = (subject, subjectValue, delimiters) => {
  const delims = `"${delimiters.join('", "')}"`;
  return `The ${subject} ("${subjectValue}") must not use the characters contained within the delimiters (${delims}).`;
};

// eslint-disable-next-line max-lines-per-function
export default function bem(block, options = {}) {
  const {elementDelimiter, modifierDelimiter, namespace, namespaceDelimiter, strict, cssModule} = {
    ...defaultOptions,
    ...options,
  };

  const namespaces = []
    .concat(namespace)
    .filter(Boolean) // compact
    .reduce((joined, ns) => `${joined}${ns}${namespaceDelimiter}`, '');

  const namespaceBlock = `${namespaces}${block}`;

  const delimiters = strict ? [elementDelimiter, modifierDelimiter] : [];
  const delimiterChars = strict ? uniqueChars(delimiters) : [];

  const bemBlock = (elementOrModifiers, modifiers) => {
    if (elementOrModifiers === null) {
      return namespaceBlock;
    }

    const element = typeof elementOrModifiers === 'string' ? elementOrModifiers : null;

    if (strict && element && includesChars(element, delimiterChars)) {
      throw new Error(invalidMessage('element', element, delimiters));
    }

    const base = element ? `${namespaceBlock}${elementDelimiter}${element}` : namespaceBlock;

    const mods = typeof elementOrModifiers === 'string' ? modifiers : elementOrModifiers;

    if (!mods) {
      return base;
    }

    const addModifiers = (className, modifier) => {
      if (modifier) {
        if (strict && includesChars(modifier, delimiterChars)) {
          throw new Error(invalidMessage('modifier', modifier, delimiters));
        }
        return `${className} ${base}${modifierDelimiter}${modifier}`;
      }
      return className;
    };

    if (Array.isArray(mods)) {
      return mods.reduce(addModifiers, base);
    }

    return Object.keys(mods)
      .filter((mod) => mods[mod])
      .reduce(addModifiers, base);
  };

  return (...args) => {
    const classNames = bemBlock(...args);

    if (!cssModule) {
      return classNames;
    }

    return classNames
      .split(' ')
      .map((el) => cssModule[el] || el)
      .join(' ');
  };
}
