import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons/app-button';
import Popover from 'client/common/popovers/popover';

import {Translation} from 'client/models/language/types';

import cssModule from './button-group.module.scss';

const b = bem('button-group', {cssModule});

type Button = {
  icon: string;
  label?: string;
  tooltip?: Translation;
  className?: string;
  onClick?: () => void;
  isActive?: boolean;
  disabled?: boolean;
};

type ButtonGroupProps = {
  buttons: Button[];
  className?: string;
};

export const ButtonGroup: React.FC<ButtonGroupProps> = ({buttons, className}) => {
  return (
    <div className={cn(b(), className)}>
      {buttons.map((btn) => {
        const buttonComponent = (
          <AppButton
            key={btn.icon}
            iconName={btn.icon}
            label={btn.label}
            className={cn(b('btn'), btn.className, {[b('active-btn')]: btn.isActive})}
            asWrap={true}
            onClick={btn.onClick}
            disabled={btn.disabled}
          />
        );
        return btn.tooltip ? (
          <Popover overlay={btn.tooltip} className={b('tooltip')} position="top" hideOnMouseLeave>
            {buttonComponent}
          </Popover>
        ) : (
          buttonComponent
        );
      })}
    </div>
  );
};
