export default {
  GET_DEVICE_AFFECTATIONS_REQUEST: '@@device-affectations/GET_DEVICE_AFFECTATIONS_REQUEST',
  GET_DEVICE_AFFECTATIONS_SUCCESS: '@@device-affectations/GET_DEVICE_AFFECTATIONS_SUCCESS',
  GET_DEVICE_AFFECTATIONS_ERROR: '@@device-affectations/GET_DEVICE_AFFECTATIONS_ERROR',

  GET_DEVICE_AFFECTATIONS_FOR_FILTERS_REQUEST: '@@device-affectations/GET_DEVICE_AFFECTATIONS_FOR_FILTERS_REQUEST',
  GET_DEVICE_AFFECTATIONS_FOR_FILTERS_SUCCESS: '@@device-affectations/GET_DEVICE_AFFECTATIONS_FOR_FILTERS_SUCCESS',
  GET_DEVICE_AFFECTATIONS_FOR_FILTERS_ERROR: '@@device-affectations/GET_DEVICE_AFFECTATIONS_FOR_FILTERS_ERROR',

  GET_DEVICE_AFFECTATION_REQUEST: '@@device-affectations/GET_DEVICE_AFFECTATION_REQUEST',
  GET_DEVICE_AFFECTATION_SUCCESS: '@@device-affectations/GET_DEVICE_AFFECTATION_SUCCESS',
  GET_DEVICE_AFFECTATION_ERROR: '@@device-affectations/GET_DEVICE_AFFECTATION_ERROR',
};
