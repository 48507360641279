import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {set} from 'client/common/meta/meta.actions';

import ClientsList from '../components/clients-list';

class ClientsListPage extends ReactQueryParams {
  constructor() {
    super();
  }

  componentDidMount() {
    const TITLE =
      this.props.route && this.props.route.params && this.props.route.params.forCurrentUser
        ? this.props.lang.PAGE_TITLE_MY_CLIENTS
        : this.props.lang.PAGE_TITLE_ALL_CLIENTS;

    this.props.setMetaAction({title: TITLE});
  }

  render() {
    const {queryParams: params} = this;
    const {route} = this.props;
    const isForCurrentUser = route && route.params && route.params.forCurrentUser;

    return <ClientsList urlParams={params} isForCurrentUser={isForCurrentUser} />;
  }
}

ClientsListPage.propTypes = {
  setMetaAction: PropTypes.func,
  lang: PropTypes.object.isRequired,
};

export default connect(
  (state) => ({
    lang: state.languageState.payload.CLIENTS_LIST,
  }),
  (dispatch) => ({
    setMetaAction: bindActionCreators(set, dispatch),
  }),
)(ClientsListPage);
