import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {CLIENT_PAGES} from 'client/common/config';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import './campaign-list.scss';

function CampaignList(props) {
  const LANGUAGE = props.languageState.payload.AUTOTASK.AUTOTASK_CAMPAIGN_LIST;

  return (
    <div className="content-panel campaign-list">
      <div className="content-panel__header campaign-list__header">
        <div className="campaign-list__col">{LANGUAGE.SOURCE_COLUMN}</div>
      </div>
      {props.items.map((item) => {
        return (
          <div className="content-panel__panel campaign-list__item" key={item.id}>
            <CustomScrollbars
              scrollbarProps={{
                autoHeightMax: 100,
              }}
            >
              <div className="content-panel__panel-inner">
                <div className="content-panel__row">
                  <div className="campaign-list__col">
                    <Link to={`${CLIENT_PAGES.CAMPAIGN}/${item.id}`} className="link break-word display-block">
                      {item.name}
                    </Link>
                  </div>
                </div>
              </div>
            </CustomScrollbars>
          </div>
        );
      })}
    </div>
  );
}

CampaignList.propTypes = {
  languageState: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(({languageState}) => ({
  languageState,
}))(CampaignList);
