import {useCallback, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {uid} from 'client/services/helpers';

import {addToastNotifications, removeToastNotifications} from 'client/ducks/toast-notification/actions';
import {selectNotifications} from 'client/ducks/toast-notification/selectors';

import {AppendToastNotification} from 'client/models/toast-notification/types';

const EVENT_DELAY = 400;

export const useToast = () => {
  const toastNotifications = useSelector(selectNotifications);
  const [isDeleted, setIsDeleted] = useState(false);
  const dispatch = useDispatch();

  // add a notification
  const appendToastNotification = useCallback(
    (data: AppendToastNotification) => {
      setTimeout(() => {
        dispatch(
          addToastNotifications({
            id: uid(),
            ...data,
          }),
        );
      }, EVENT_DELAY);
    },
    [dispatch],
  );

  // remove a notification
  const removeToastNotification = useCallback(
    (id: string | number) => {
      setIsDeleted(true);
      setTimeout(() => {
        dispatch(removeToastNotifications(id));
      }, EVENT_DELAY);
    },
    [dispatch],
  );

  return {
    isDeleted,
    toastNotifications,
    removeToastNotification,
    appendToastNotification,
  };
};
