import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getBroadcast, flushBroadcastAllState} from 'client/ducks/broadcast/actions';
import {selectBroadcast, selectBroadcastClient} from 'client/ducks/broadcast/selectors';
import {getMessageTasks, flushMessageTaskAllState} from 'client/ducks/message-email-sms-task/actions';
import {selectMessageTasks, selectMessageTaskMeta} from 'client/ducks/message-email-sms-task/selectors';

import {CLIENT_PAGES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';
import TitleBlock from 'client/components/common/title-block';

import BCMailingTasksDataGrid from '../components/tables/bc-mailing-tasks-data-grid';

class MailingListTasks extends ReactQueryParams {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    broadcastId: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    client: PropTypes.object,
    broadcast: PropTypes.object,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    getMessageTasks: PropTypes.func.isRequired,
    flushMessageTaskAllState: PropTypes.func.isRequired,
    flushBroadcastAllState: PropTypes.func.isRequired,
  };

  static defaultProps = {
    broadcast: {},
    client: {},
  };

  static DEFAULT_FIRST_PAGE = 1;
  static DEFAULT_PER_PAGE = 5;

  constructor(props) {
    super(props);

    this.LANGUAGE = {
      BREADCRUMBS: props.languageState.payload.BREADCRUMBS,
    };
  }

  defaultQueryParams = {
    search: '',
    page: '',
    perPage: '',
  };

  componentDidMount() {
    this.getBroadcast().then(() => {
      this.updateBreadCrumbs();
    });

    this.fetchData();
  }

  componentDidUpdate({broadcastId}) {
    if (this.props.broadcastId !== broadcastId) {
      this.getBroadcast().then(() => {
        this.updateBreadCrumbs();
      });

      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.props.flushMessageTaskAllState();
    this.props.flushBroadcastAllState();
    this.props.clearBreadcrumbs();
  }

  updateBreadCrumbs() {
    const {broadcast, client} = this.props;

    const agencyBreadcrumb = client.agency
      ? [
          {
            href: CustomLink.createClientLink({clientId: client.agency.id, clientType: client.agency.type}),
            name: client.agency.name,
          },
        ]
      : [];

    return this.props.setBreadcrumbs([
      {
        href: CLIENT_PAGES.VISUALS,
        name: this.LANGUAGE.BREADCRUMBS.VISUALS,
      },
      {
        name: this.LANGUAGE.BREADCRUMBS.CLIENTS,
        href: CLIENT_PAGES.CLIENTS_LIST,
      },
      ...agencyBreadcrumb,
      {
        name: client.name,
        href: CustomLink.createClientLink({clientId: client.id, clientType: client.type}),
      },
      {
        name: `${this.LANGUAGE.BREADCRUMBS.MESSAGE_TASKS_THAT_USE} ${broadcast.name}`,
      },
    ]);
  }

  getBroadcast = () => {
    const params = {
      include: ['client.agency'],
    };

    return this.props.getBroadcast(this.props.broadcastId, params);
  };

  handleSearch = (value) => {
    this.setQueryParams(
      {
        search: value,
        page: MailingListTasks.DEFAULT_FIRST_PAGE,
      },
      true,
    );

    this.fetchData();
  };

  handlePageChange = (params) => {
    this.setQueryParams(params);
    return this.fetchData();
  };

  handleSearchClear = () => {
    this.setQueryParams(
      {
        search: '',
      },
      true,
    );
    this.fetchData();
  };

  fetchData = () => {
    const {queryParams} = this;
    const {clientId, broadcastId} = this.props;

    const params = {
      q: {
        client_id_eq: clientId,
        broadcast_list_id_eq: broadcastId,
        name_cont: queryParams.search,
      },
      page: queryParams.page || MailingListTasks.DEFAULT_FIRST_PAGE,
      per_page: queryParams.perPage || MailingListTasks.DEFAULT_PER_PAGE,
    };

    return this.props.getMessageTasks(params);
  };

  render() {
    const {broadcast, client, meta, data} = this.props;

    return (
      <div className="theme-color-16">
        <div className="page__title-block">
          <div>
            <TitleBlock theme ellipsis="1">
              <TitleBlock.Item>{broadcast.name}</TitleBlock.Item>
            </TitleBlock>
          </div>
        </div>
        <BCMailingTasksDataGrid
          data={data}
          meta={meta}
          client={client}
          onSearchClear={this.handleSearchClear}
          onSearch={this.handleSearch}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

const mapStateToProps = ({languageState, ...state}) => {
  return {
    languageState,
    data: selectMessageTasks(state),
    meta: selectMessageTaskMeta(state),
    broadcast: selectBroadcast(state),
    client: selectBroadcastClient(state),
  };
};

const mapDispatchToProps = {
  setBreadcrumbs,
  clearBreadcrumbs,
  getBroadcast,
  getMessageTasks,
  flushMessageTaskAllState,
  flushBroadcastAllState,
};

export default connect(mapStateToProps, mapDispatchToProps)(MailingListTasks);
