import React, {useMemo} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';

import bem from 'client/services/bem';
import {useAppMedia, useLanguage, useReduxForm} from 'client/services/hooks';

import AppButton from 'client/common/buttons';
import {DatepickerField, SelectField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import {getDefaultStatusOptions} from './helpers';
import {useGetKits, useGetTaskAccesses, useGetNetworks} from './hooks';

import cssModule from './instore-planning-animation-list-filters.module.scss';

const b = bem('instore-planning-animation-list-filters', {cssModule});

const INSTORE_FILTERS_FORM_NAME = 'InstorePlanningAnimationListFiltersForm';

const InstorePlanningAnimationListFilters = (props) => {
  const {onClose, onSubmit, defaultFilters, getStatusOptions} = props;

  const statusLangs = useLanguage('ANIMATIONS.STATUS_ROAD');
  const lang = useLanguage('INSTORE_TASK.LISTS.INSTORE_PLANNING_ANIMATION_LIST.FILTERS');
  const {isTablet} = useAppMedia();

  const {formValues, change} = useReduxForm(INSTORE_FILTERS_FORM_NAME, {
    initialValues: {
      ...defaultFilters,
      from: defaultFilters?.from && moment(defaultFilters.from, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      to: defaultFilters?.to && moment(defaultFilters.to, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
  });

  const {loading: networkLoading, options: networkOptions} = useGetNetworks();
  const {loading: kitLoading, options: kitOptions} = useGetKits();
  const {loading: accessesLoading, userOptions, regionOptions} = useGetTaskAccesses();

  const onSubmitClick = () => {
    onSubmit({
      ...formValues,
      from: formValues.from && moment(formValues.from, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      to: formValues.to && moment(formValues.to, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    });
    onClose();
  };

  const onChangeBrand = () => {
    change('region', null);
  };

  const statusOptions = useMemo(() => {
    const defaultStatuses = getDefaultStatusOptions(statusLangs);

    return getStatusOptions?.(defaultStatuses) || defaultStatuses;
  }, [getStatusOptions, statusLangs]);

  const dataLoading = kitLoading || networkLoading || accessesLoading;

  return (
    <Modal show onClose={onClose} title={lang.TITLE}>
      <span className={b('period-title')}>{lang.ANIMATION_PERIOD_TITLE}</span>
      <div className={b('period', {mobile: isTablet})}>
        <DatepickerField
          name="from"
          label={lang.FROM_FIELD}
          disabledDayAfter={formValues.to}
          autoComplete="off"
          changeFormat="YYYY-MM-DD"
        />
        <DatepickerField
          name="to"
          label={lang.TO_FIELD}
          disabledDayBefore={formValues.from}
          autoComplete="off"
          changeFormat="YYYY-MM-DD"
        />
      </div>
      <SelectField options={statusOptions} name="status" label={lang.STATUS_FIELD} withWrap simpleValue clearable />
      <SelectField
        options={networkOptions}
        isLoading={networkLoading}
        onChange={onChangeBrand}
        name="network"
        label={lang.BRAND_FIELD}
        withWrap
        simpleValue
        clearable
      />
      <SelectField
        options={regionOptions}
        isLoading={accessesLoading}
        name="region"
        label={lang.REGION_FIELD}
        withWrap
        simpleValue
        clearable
      />
      <SelectField
        options={kitOptions}
        isLoading={kitLoading}
        name="kit"
        label={lang.KIT_FIELD}
        withWrap
        simpleValue
        clearable
      />
      <SelectField
        options={userOptions}
        isLoading={accessesLoading}
        name="user"
        label={lang.USER_FIELD}
        withWrap
        simpleValue
        clearable
      />

      <div className={b('buttons', {mobile: isTablet})}>
        <AppButton
          label={lang.SUBMIT_BUTTON}
          color="primary"
          className={b('button')}
          onClick={() => onSubmitClick(formValues)}
          disabled={dataLoading}
        />
      </div>
    </Modal>
  );
};

InstorePlanningAnimationListFilters.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  getStatusOptions: PropTypes.func,
  defaultFilters: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    network: PropTypes.string,
    region: PropTypes.string,
    status: PropTypes.string,
    kit: PropTypes.string,
    membership: PropTypes.string,
  }),
};

InstorePlanningAnimationListFilters.defaultProps = {
  defaultFilters: null,
  getStatusOptions: null,
};

const form = reduxForm({
  form: INSTORE_FILTERS_FORM_NAME,
})(InstorePlanningAnimationListFilters);

form.displayName = 'InstorePlanningAnimationListFiltersForm';
form.propTypes = InstorePlanningAnimationListFilters.propTypes;

export default form;
