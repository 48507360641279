import React from 'react';

import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';
import {StepNavItem} from 'client/components/common/step-nav';

function BCNavItem(props) {
  const {checked, eventKey, ...rest} = props;

  return (
    <StepNavItem
      className="step-nav__item--view-1 broadcasting__nav-item"
      key={eventKey}
      eventKey={eventKey}
      content={
        checked && (
          <div className="broadcasting__nav-icon">
            <Icon name="check" />
          </div>
        )
      }
      {...rest}
    />
  );
}

BCNavItem.propTypes = {
  title: PropTypes.any,
  eventKey: PropTypes.any.isRequired,
  name: PropTypes.any,
  checked: PropTypes.bool,
  active: PropTypes.bool,
  activeHref: PropTypes.string,
  activeKey: PropTypes.string,
  id: PropTypes.string,
  role: PropTypes.string,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
};

BCNavItem.defaultProps = {
  title: '',
  name: '',
  checked: false,
};

export default BCNavItem;
