import React from 'react';

import PropTypes from 'prop-types';

import {formatNumberSpace} from 'client/services/formatters';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {getHorizontalGraphData} from './helpers';

import './horizontal-bars-diagram.scss';

const HorizontalBarsDiagram = ({diagram}) => {
  const {values_count, values, color = '#B958C9', diagram_method, dot_value} = diagram;
  const data = getHorizontalGraphData(values, values_count, diagram_method, dot_value);

  return (
    <div>
      <CustomScrollbars
        scrollbarProps={{
          autoHeightMax: 230,
        }}
      >
        <div className="horizontal-bars-diagram">
          <div className="horizontal-bars-diagram__labels-column">
            {data.map(({label}, index) => (
              <div key={index} className="horizontal-bars-diagram__label" title={label}>
                {label}
              </div>
            ))}
          </div>
          <div className="horizontal-bars-diagram__values-column">
            {data.map(({value}, index) => (
              <div key={index} className="horizontal-bars-diagram__value">
                {formatNumberSpace(value)}
              </div>
            ))}
          </div>
          <div className="horizontal-bars-diagram__percents-column">
            {data.map(({percent}, index) => (
              <div key={index} className="horizontal-bars-diagram__percent">
                {percent}
              </div>
            ))}
          </div>
          <div className="horizontal-bars-diagram__bars-column">
            {data.map(({barPercent}, index) => (
              <div key={index} className="horizontal-bars-diagram__bar-wrap">
                <div className="horizontal-bars-diagram__bar" style={{width: barPercent, backgroundColor: color}} />
              </div>
            ))}
          </div>
        </div>
      </CustomScrollbars>
    </div>
  );
};

HorizontalBarsDiagram.propTypes = {
  diagram: PropTypes.object.isRequired,
};

export default HorizontalBarsDiagram;
