import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import {required} from 'client/services/validator';

// components
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

class SelectSubsidiaryModal extends Component {
  static propTypes = {
    className: PropTypes.string,
    buttonConfirmClass: PropTypes.string,
    lang: PropTypes.object.isRequired,
    subsidiaries: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    pageId: PropTypes.string,
    name: PropTypes.string,
    show: PropTypes.bool,
  };

  static defaultProps = {
    show: false,
  };

  static formName = 'SelectSubsidiaryForm';

  constructor(props) {
    super(props);

    this.rules = {
      subsidiary_id: required(this.props.lang.SUBSIDIARY_IS_REQUIRED_ERROR),
    };
  }

  componentDidUpdate({show}) {
    if (this.props.show && !show) {
      this.props.initialize({});
    }
  }

  save = ({subsidiary_id}) => {
    return this.props.onConfirm(subsidiary_id);
  };

  render() {
    const {handleSubmit, onClose, show, lang, subsidiaries, className, buttonConfirmClass} = this.props;

    return (
      <Modal
        show={show}
        dialogClassName={`modal-window--width-1 ${className}`}
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TITLE}
      >
        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          <FormFieldWrap>
            <Field
              label={lang.SUBSIDIARY_LABEL}
              cssModifier="select--no-min-height"
              name="subsidiary_id"
              type="select"
              simpleValue
              labelKey="name"
              valueKey="id"
              options={subsidiaries}
              validate={this.rules.subsidiary_id}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className={`button modal-window__footer-btn ${buttonConfirmClass}`} type="submit">
              {lang.NEXT_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const form = reduxForm({
  form: SelectSubsidiaryModal.formName,
})(SelectSubsidiaryModal);

const mapStateToProps = (state) => ({
  lang: state.languageState.payload.AGENCY.ADD_SOCIAL_ACCOUNT_MODAL,
});

export default connect(mapStateToProps)(form);
