import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import moment from 'moment';

import {formatDate, formatTime, formatTimeWithSeconds, transformDateTimeZone} from 'client/services/helpers';

import {GENDER_TYPES_VALUES} from 'client/common/config';

import LeadsFiltersModal from 'client/components/leads/components/modals/leads-filters-modal/leads-filters-modal';

export function getAge(birthDate) {
  let years = '';
  if (birthDate) {
    const birthDateMoment = moment(birthDate);
    years = moment().diff(birthDateMoment, 'years');
  }

  return years;
}

export function mapLeadsData(leads, customs, clientId) {
  let mappedLeads = [];

  if (customs.length || !clientId) {
    mappedLeads = leads.map(
      ({
        id,
        first_name,
        last_name,
        gender,
        birth_date,
        phone,
        email,
        comment,
        client,
        data,
        created_at,
        participations,
        participations_count,
        last_participation,
        visuals_count,
        first_source,
        last_export,
      }) => ({
        id,
        first_name,
        last_name,
        gender,
        birth_date: getAge(birth_date),
        phone,
        data,
        email,
        comment,
        visuals_count,
        participations,
        participations_count,
        last_participation_created_at: last_participation,
        client_name: client,
        created_at: formatDate(created_at),
        first_source,
        last_export,
      }),
    );
  }

  // get values for custom columns
  mappedLeads.forEach((el) => {
    let keys = Object.keys(el.data);
    keys.forEach((column) => {
      const field = getFieldName(column, customs);
      el[field] = el.data[column];
    });
  });
  return mappedLeads;
}

export function getFieldName(index, customs) {
  const customColumn = customs.filter((el) => el.id === Number(index));
  return customColumn[0] && customColumn[0].external_column_adapter.name;
}

export function mapGridColumns(list) {
  const mappedColumns = [];
  let item;
  let columns = JSON.parse(JSON.stringify(list));

  if (columns && columns.length) {
    const sortedArray = ['first_name', 'last_name', 'birth_date', 'visuals_count', 'client_name', 'created_at'];

    columns.forEach((el) => {
      item = JSON.parse(JSON.stringify(el));
      if (item.column_adapter.name === 'client_id') {
        item.column_adapter.name = 'client_name';
      }
      if (sortedArray.includes(item.column_adapter.name)) {
        item.dataSort = true;
      }
      if (item.column_adapter.name !== 'email' && item.column_adapter.name !== 'participations_count') {
        mappedColumns.push(item);
      }
    });
  }

  return mappedColumns;
}

export function mapFilter(data) {
  const obj = {...data};

  ['client', 'source'].forEach((key) => {
    if (!obj[key]) {
      return;
    }

    if (obj[key].value === '0') {
      delete obj[key];
    } else {
      obj[key] = {
        ...obj[key],
        label: encodeURIComponent(obj[key].label),
      };
    }
  });

  ['phone', 'email', 'visuals'].forEach((key) => {
    if (!obj[key] || obj[key] === 'false') {
      delete obj[key];
    }
  });

  ['campaign', 'place'].forEach((key) => {
    if (!obj[key]) {
      delete obj[key];
    }
  });

  ['operation', 'autotask', 'opt_in', 'scenario', 'levels'].forEach((key) => {
    if (!Array.isArray(obj[key]) || obj[key].length === 0) {
      delete obj[key];
    } else {
      obj[key] = obj[key].map((item) => ({
        ...item,
        label: encodeURIComponent(item.label),
      }));
    }
  });

  ['participated', 'gender', 'created', 'optInRadio'].forEach((key) => {
    if (obj[key] === 'false') {
      delete obj[key];
    }
  });
  if (obj.m === 'and') {
    delete obj.m;
  }
  return obj;
}

export function mapFilterForUrl(data) {
  const params = {
    distinct: true,
    q: {
      g: {},
    },
  };

  const formatDateForBackend = (date, time) => moment(`${date} ${time}`, 'DD/MM/YYYY HH:mm:ss').toISOString();

  const genderMap = {
    male: GENDER_TYPES_VALUES.MALE,
    female: GENDER_TYPES_VALUES.FEMALE,
    other: GENDER_TYPES_VALUES.OTHER,
    'N/A': GENDER_TYPES_VALUES.NOT_SPECIFIED,
  };

  params.q.gender_eq = genderMap[data.gender] || data.gender;

  if (data.participated && data.participatedFrom) {
    params.q.g.participations_created_at_gteq = formatDateForBackend(data.participatedFrom, '00:00:00');
  }

  if (data.participated && data.participatedTo) {
    params.q.g.participations_created_at_lteq = formatDateForBackend(data.participatedTo, '23:59:59');
  }

  ['phone', 'email'].forEach((key) => {
    if (data[key]) {
      params.q[`${key}_present`] = 't';
    }
  });

  if (data.visuals) {
    params.q.visuals_count_gt = 0;
  }

  if (data.created && data.createdFrom) {
    params.q.g.created_at_gteq = formatDateForBackend(data.createdFrom, '00:00:00');
  }

  if (data.created && data.createdTo) {
    params.q.g.created_at_lteq = formatDateForBackend(data.createdTo, '23:59:59');
  }
  const getName = (id) => `opt_ins->>${id}_eq`;
  const dropdownFilters = [
    {
      key: 'client',
      filter: 'client_id_eq',
    },
    {
      key: 'source',
      filter: 'lead_histories_source_id_eq',
    },
    {
      key: 'operation',
      filter: 'participations_operation_id_in',
    },
    {
      key: 'autotask',
      filter: 'participations_automation_task_id_in',
    },
    {
      key: 'opt_in',
      filter: null,
    },
    {
      key: 'optInRadio',
      filter: 'opt_ins_is_empty_json',
    },
  ];
  dropdownFilters.forEach(({key, filter}) => {
    if (data[key]) {
      if (Array.isArray(data[key])) {
        if (key === 'opt_in') {
          map(data[key], 'value').forEach((el) => {
            params.q.g[getName(el)] = true;
            return params;
          });
          params.q.g.m = data.m;
        } else {
          params.q[filter] = map(data[key], 'value');
        }
      } else if (data[key].value) {
        params.q[filter] = data[key].value;
      } else if (data[key] === LeadsFiltersModal.optInValues.noActive) {
        params.q[filter] = true;
      }
    }
  });
  return params;
}

export function formatDateOptions(setDate, isBackOrder, div) {
  if (setDate === null) {
    return '';
  }

  const localTime = transformDateTimeZone(setDate);
  const dateList = localTime ? localTime.substr(0, 10).split('-') : [];
  return (isBackOrder ? dateList : dateList.reverse()).join(div);
}

export function mapBasicInfo(lead) {
  const sourceObj = getSourceParams(lead.first_source, true);
  return {
    gender: lead.gender || 'null',
    first_name: lead.first_name,
    last_name: lead.last_name,
    birth_date: formatDateOptions(lead.birth_date, false, '/'),
    age: getAge(lead.birth_date),
    phone: lead.phone,
    email: lead.email,
    created: `${formatDateOptions(lead.created_at, false, '/')} ${formatTimeWithSeconds(lead.created_at)}`,
    comment: get(lead, 'comment'),
    firstSource: sourceObj.name,
    firstSourceBy: sourceObj.clientName,
    client: get(lead, 'client.name'),
    visuals: lead.visuals_count,
    participations: lead.participations_count,
    lastExport: formatDateOptions(get(lead, 'last_export'), false, '/'),
    lastExportBy: get(lead, 'last_name'),
    active_opt_ins: lead.active_opt_ins,
  };
}

export function mapCustomInfo({external_column_names = [], data}) {
  return external_column_names.reduce((acc, name) => {
    const customInfo = name.array ? data[name.id][0] : data[name.id];
    const value =
      {
        file: customInfo ? customInfo.file_identifier : null,
        datetime: formatDateOptions(customInfo, false, '/'),
      }[name.kind] || customInfo;
    return {
      ...acc,
      [`x${name.id}`]: value,
    };
  }, {});
}

export function getSourceParams(data, options) {
  const obj = {};
  if (data) {
    obj.date = formatDateOptions(data.created_at, options, '-');
    obj.time = formatTime(data.created_at);
    obj.id = data.id;
    obj.name = data.name;
    obj.type = data.type;
    switch (data.type) {
      case 'ApiLeadImport':
        obj.name = data.membership_id;
        obj.url = '#';
        obj.clientName = data.client.name;
        break;
      case 'Participation':
        obj.name = get(data, 'interaction.interface.automation_task.operation.code', '');
        obj.url = '#';
        obj.clientName = get(data, 'interaction.interface.name', '');
        const operation = get(data, 'interaction.interface.automation_task.operation', {});

        if (!isEmpty(operation)) {
          const type = operation.status === 'active' ? 'companies' : 'agencies';
          obj.url = `/${type}/${operation.client_id}/operations/${operation.id}`;
        }
        break;
      case 'FileLeadImport':
        obj.name = `${data.name}.${data.format}`;
        obj.url = '#';
        obj.clientName = data.client.name;
        break;
      default:
        break;
    }
  }
  return obj;
}

export function mapColumnsNames(columns, language) {
  return columns.map((col) => {
    let name;
    switch (col.name) {
      case 'first_name':
        name = language.FIRST_NAME;
        break;
      case 'last_name':
        name = language.LAST_NAME;
        break;
      case 'birth_date':
        name = language.BIRTH_DATE_DATE;
        break;
      case 'email':
        name = language.EMAIL;
        break;
      case 'phone':
        name = language.PHONE;
        break;
      case 'gender':
        name = language.GENDER;
        break;
      case 'expires_at':
        name = language.EXPIRES_AT;
        break;
      default:
        name = col.name;
        break;
    }
    return {
      ...col,
      name: name,
    };
  });
}
