import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import ImageContainer from 'client/common/image-container';

import './animation-brand-cell.scss';

const b = bem('animation-brand-cell');

const AnimationBrandCell = (props) => {
  const {item, size, renderNoValue} = props;

  const value = item.place?.network?.icon_url;

  if (!value && !renderNoValue) {
    return null;
  }

  return <ImageContainer classNamePreview={b()} value={value} width={size} height={size} keepRatio />;
};

AnimationBrandCell.propTypes = {
  item: PropTypes.shape({
    place: PropTypes.shape({
      network: PropTypes.shape({
        icon_url: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  size: PropTypes.number,
  renderNoValue: PropTypes.bool,
};

AnimationBrandCell.defaultProps = {
  size: 16,
  renderNoValue: false,
};

export default AnimationBrandCell;
