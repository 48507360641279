import React, {useMemo} from 'react';

import arrayMove from 'array-move';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import bem from 'client/services/bem';
import {formatNumberSpace} from 'client/services/formatters';
import {useLanguage} from 'client/services/hooks';

import {changePrizeOrder} from 'client/ducks/diy-operation/actions';
import {updatePrizeOrder} from 'client/ducks/games/actions';

import AppButton from 'client/common/buttons';

import ClientTable from 'client/components/common/client-table';
import Icon from 'client/components/common/icon';

import {isEditingDisabled} from './helpers';

import './diy-op-prizes-block-table.scss';

const b = bem('diy-op-prizes-block-table');

const DiyOpPrizesBlockTable = (props) => {
  const {prizeList, onEditClick, gameId, disabled} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_PRIZES_BLOCK');

  const excludeRowsFromDndSorting = useMemo(() => {
    return prizeList
      .map((el, index) => ({index, fullWin: el.data.full_win}))
      .filter(({fullWin}) => fullWin)
      .map(({index}) => index);
  }, [prizeList]);

  const handleDragEnd = ({oldIndex, newIndex}) => {
    const newList = arrayMove(prizeList, oldIndex, newIndex);

    dispatch(changePrizeOrder(newList));

    dispatch(
      updatePrizeOrder(
        gameId,
        newList.reduce(
          (acc, el, index) => ({
            ...acc,
            [el.data.id]: index,
          }),
          {},
        ),
      ),
    );
  };

  const columns = useMemo(() => {
    return [
      {
        name: 'index',
        label: '#',
        render: ({index}) => index + 1,
      },
      {
        name: 'prizeName',
        label: lang.TABLE_HEADERS.PRIZE_NAME,
        render: (row) => (
          <AppButton
            className={b('prize-name-button')}
            label={row.item.prizeName}
            onClick={() => onEditClick(row.index)}
            asWrap
            disabled={isEditingDisabled(row.item)}
          />
        ),
      },
      {
        name: 'currentStock',
        label: lang.TABLE_HEADERS.CURRENT_STOCK,
        path: 'totalCurrentStock',
        render: (row) => <span>{formatNumberSpace(row.item?.totalCurrentStock)}</span>,
      },
      {
        name: 'coupons',
        label: lang.TABLE_HEADERS.COUPONS,
        path: 'coupon_type',
        render: (row) => row.item.coupon_type && <Icon name="source-completed" />,
      },
      {
        name: 'popUp',
        label: lang.TABLE_HEADERS.POP_UP,
        path: 'type',
        render: (row) => row.item.popup && <div className={b('cell-circle')} />,
      },
      {
        name: 'ticket',
        label: lang.TABLE_HEADERS.TICKET,
        path: 'type',
        render: (row) => row.item.ticket && <div className={b('cell-circle')} />,
      },
      {
        name: 'email',
        label: lang.TABLE_HEADERS.EMAIL,
        path: 'type',
        render: (row) => row.item.email && <div className={b('cell-circle')} />,
      },
      {
        name: 'schedule',
        label: lang.TABLE_HEADERS.SCHEDULE,
        path: 'schedule',
        render: (row) => row.item.schedule && <span>{lang.SCHEDULE_TYPES[row.item.schedule]}</span>,
      },
    ];
  }, [lang, onEditClick]);

  return (
    <ClientTable
      className={b('', {scrollable: prizeList.length > 5})}
      columns={columns}
      data={prizeList}
      iconClassName={b('icon')}
      draggable={!disabled}
      onDragEnd={handleDragEnd}
      getRowTitle={({item}) => (isEditingDisabled(item) ? lang.NOT_EDITABLE : null)}
      excludeRowsFromDndSorting={excludeRowsFromDndSorting}
    />
  );
};

DiyOpPrizesBlockTable.propTypes = {
  prizeList: PropTypes.array,
  onEditClick: PropTypes.func.isRequired,
  gameId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};

DiyOpPrizesBlockTable.defaultProps = {
  prizeList: [],
};

export default DiyOpPrizesBlockTable;
