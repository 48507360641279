import get from 'lodash/get';

import types from './types';

const initialState = {
  devices: {
    data: [],
    max_visits: 0,
    unlinked_count: 0,
    meta: {
      current_page: 0,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
  },
  devicesFilteredRegions: {
    data: [],
  },
  devicesFilteredStores: {
    data: [],
  },
  devicesFilteredTask: {
    data: [],
  },
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case types.FETCH_DEVICES_EXTERNAL_SUCCESS:
      return {
        ...state,
        devices: {
          data: get(payload, 'devices', []),
          max_visits: get(payload, 'aggregations.max_visits'),
          unlinked_count: get(payload, 'aggregations.unlinked_count'),
          meta: get(payload, 'meta', state.devices.meta),
        },
      };
    case types.FETCH_DEVICES_EXTERNAL_FILTER_REGIONS_SUCCESS:
      return {
        ...state,
        devicesFilteredRegions: {
          data: get(payload, 'devices', []),
        },
      };

    case types.FETCH_DEVICES_EXTERNAL_FILTER_STORES_SUCCESS:
      return {
        ...state,
        devicesFilteredStores: {
          data: get(payload, 'devices', []),
        },
      };

    case types.FETCH_DEVICES_EXTERNAL_FILTER_TASK_SUCCESS:
      return {
        ...state,
        devicesFilteredTask: {
          data: get(payload, 'devices', []),
        },
      };

    default:
      return state;
  }
};
