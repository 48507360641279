import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import ClientTable from 'client/components/common/client-table';
import ClientTableHeader from 'client/components/common/client-table/client-table-header';

import cssModule from './instore-dashboard-stats-table.module.scss';

const b = bem('instore-dashboard-stats-table', {cssModule});

const InstoreDashboardStatsTable = (props) => {
  const {data, consolidation} = props;
  const lang = useLanguage('INSTORE_DASHBOARDS.TABLES.INSTORE_DASHBOARD_STATS_TABLE');

  const overallAverage = data?.length ? data.reduce((acc, item) => acc + item.average, 0) / data.length : 0;
  const overallTotal = data?.length ? data.reduce((acc, item) => acc + item.total, 0) : 0;
  const overallAverageLabel = Number.parseFloat(overallAverage.toFixed(3)); // parse to remove trailing zeros
  const overallTotalLabel = Number.parseFloat(overallTotal.toFixed(3)); // parse to remove trailing zeros

  const subheader = (
    <>
      <ClientTableHeader
        column={{
          name: 'all',
          path: 'all',
          label: lang.ALL,
          headerClassName: b('subheader'),
        }}
      />
      <ClientTableHeader
        column={{
          name: 'number',
          path: 'number',
          label: overallAverageLabel,
          labelClassName: b('header-label', ['aligned']),
          headerClassName: b('subheader'),
        }}
      />
      <ClientTableHeader
        column={{
          name: 'number',
          path: 'number',
          label: overallTotalLabel,
          labelClassName: b('header-label', ['aligned']),
          headerClassName: b('subheader'),
        }}
      />
    </>
  );

  return (
    <ClientTable
      columns={[
        {
          name: 'name',
          path: 'name',
          label: lang.CONSOLIDATION[consolidation.toUpperCase()],
          labelClassName: b('header-label', ['main']),
          noWrap: true,
        },
        {
          name: 'average',
          path: 'average',
          label: lang.AVERAGE,
          labelClassName: b('header-label', ['main', 'aligned']),
          noWrap: true,
        },
        {
          name: 'total',
          path: 'total',
          label: lang.TOTAL,
          labelClassName: b('header-label', ['main', 'aligned']),
          noWrap: true,
        },
      ]}
      className={b()}
      renderHeaderChildren={subheader}
      data={data}
      commonHeaderClassName={b('header')}
      commonCellClassName={b('cell')}
    />
  );
};

InstoreDashboardStatsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      average: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
    }),
  ).isRequired,
  consolidation: PropTypes.string.isRequired,
};

export default InstoreDashboardStatsTable;
