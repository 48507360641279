import React from 'react';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import bem from 'client/services/bem';
import {dateToString, prettyJoin} from 'client/services/helpers';
import {useLanguage, useAppMedia} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';
import Icon from 'client/common/icon';

import {WINNING_SEARCH_TYPES} from 'client/components/winnings/forms/winning-search-form/constants';
import {WinningSearchType} from 'client/components/winnings/forms/winning-search-form/types';
import {getFullName, getPrizeName} from 'client/components/winnings/helpers';
import {WinningSearchResult} from 'client/models/winnings/types';

import {getCopyText} from './helpers';

import cssModule from './winning-search-result-item.module.scss';

const b = bem('winning-search-result-item', {cssModule});

type WinningSearchResultItemProps = {
  item: WinningSearchResult;
  searchType: WinningSearchType;
  onSelect?: (prizeId: number) => void;
  showCopy?: boolean;
};

const WinningSearchResultItem: React.FC<WinningSearchResultItemProps> = (props) => {
  const {item, searchType, showCopy, onSelect} = props;
  const lang = useLanguage('WINNINGS.LISTS.WINNING_SEARCH_RESULT_LIST.WINNING_SEARCH_RESULT_ITEM');
  const langCommon = useLanguage('COMMON');
  const {isTablet} = useAppMedia();

  return (
    <div className={b({mobile: isTablet})}>
      <div className={b('winner-info')}>
        <div>
          <p className={b('winner-info-label')}>{lang.WINNER}</p>
          {item.lead.email && <p className={b('winner-info-label')}>{lang.EMAIL}</p>}
          {searchType === WINNING_SEARCH_TYPES.PHONE && <p className={b('winner-info-label')}>{lang.PHONE}</p>}
          {searchType === WINNING_SEARCH_TYPES.TOKEN && <p className={b('winner-info-label')}>{lang.TOKEN}</p>}
          {searchType === WINNING_SEARCH_TYPES.LOYALTY_ID && (
            <p className={b('winner-info-label')}>{lang.LOYALTY_ID}</p>
          )}
        </div>
        <div>
          <p className={b('winner-info-label', ['bold'])}>{getFullName(item.lead, langCommon)}</p>
          {item.lead.email && <p className={b('winner-info-label', ['bold'])}>{item.lead.email}</p>}
          {searchType === WINNING_SEARCH_TYPES.PHONE && (
            <p className={b('winner-info-label', ['bold'])}>{item.lead.phone}</p>
          )}
          {searchType === WINNING_SEARCH_TYPES.TOKEN && (
            <p className={b('winner-info-label', ['bold'])}>{item.prizes?.[0]?.coupon?.token}</p>
          )}
          {searchType === WINNING_SEARCH_TYPES.LOYALTY_ID && (
            <p className={b('winner-info-label', ['bold'])}>{item.prizes?.[0]?.loyalty_identifier}</p>
          )}
        </div>
        {showCopy && (
          <div className={b('copy-wrap')}>
            <CopyToClipboard text={getCopyText(searchType, item, langCommon)}>
              <AppButton
                className={b('copy-button')}
                iconConfig={{
                  name: 'copy',
                  width: 16,
                  height: 16,
                }}
                label={lang.COPY_TEXT}
                transparent
              />
            </CopyToClipboard>
          </div>
        )}
      </div>
      <p className={b('prize-won-label')}>
        {lang.PRIZE_WON} {item.prizes.length}
      </p>
      {item.prizes.map((prize) => {
        const winDate = dateToString(prize.win_time);
        const winTime = dateToString(prize.win_time, 'HH:mm');
        const store = prize.interaction?.store;
        return (
          <AppButton
            key={prize.id}
            className={b('prize-item-button')}
            onClick={() => onSelect?.(prize.id)}
            label={
              <div key={prize.id} className={b('prize-item-content')}>
                <div className={b('prize-top')}>
                  <p className={b('prize-name')}>{getPrizeName(prize)}</p>
                  {prize.distributed && <Icon className={b('distributed-icon')} name="distributed-prize" />}
                </div>
                <p className={b('prize-store')}>{prize.interaction?.name}</p>
                <p className={b('prize-store')}>{store?.name}</p>
                <p className={b('prize-store')}>{prettyJoin([store?.city_zip, store?.city_name])}</p>
                <p className={b('prize-date')}>
                  {winDate} <span className={b('prize-time')}>{winTime}</span>
                </p>
              </div>
            }
            asWrap
          />
        );
      })}
    </div>
  );
};

export default WinningSearchResultItem;
