import PropTypes from 'prop-types';

import {useLanguage} from 'client/services/hooks';

const InteractionTypeCell = ({value}) => {
  const lang = useLanguage('PARTICIPANTS_LIST');
  const map = {
    OnlineInteraction: lang.SOURCE,
    OfflineInteraction: lang.DEVICE,
  };

  return map[value] || lang.DIRECT;
};

InteractionTypeCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default InteractionTypeCell;
