import find from 'lodash/find';

import types from './types';

const initialState = {
  mapModalToggle: false,
  addPlaceModalToggle: false,
  linkToClientModalToggle: false,
  needToUpdatePlaces: false,
  addPlaceSubmitting: false,
  addressDoesNotExist: [false],
  isLoadingPlaces: false,
  perPage: 5,
  search: '',
  selectedPlaces: [],
  mappedPlaces: [],
  linkedClientId: 0,
  linkedRegionId: 0,
  sort: {name: 'name', order: 'ASC'},
  meta: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
  places: [],
};

export default function (_state = initialState, action) {
  const state = Object.assign({}, _state);

  switch (action.type) {
    case types.TOGGLE_ADD_PLACE_MODAL:
      state.addPlaceModalToggle = !state.addPlaceModalToggle;
      return state;

    case types.TOGGLE_MAP_MODAL:
      state.mapModalToggle = !state.mapModalToggle;
      return state;

    case types.TOGGLE_LINK_TO_CLIENT_MODAL:
      state.linkToClientModalToggle = !state.linkToClientModalToggle;
      if (!state.linkToClientModalToggle) {
        return state;
      }
      let selectedPlace = find(state.places, (i) => i.id === state.selectedPlaces[0]);
      let regionId = selectedPlace.region_id;
      const clientId = selectedPlace.client_id;
      for (let id of state.selectedPlaces) {
        selectedPlace = find(state.places, (i) => i.id === id);
        if (selectedPlace.client_id !== clientId) {
          delete state.linkedClientId;
          delete state.linkedRegionId;
          return state;
        }
        if (selectedPlace.region_id !== regionId) {
          // eslint-disable-next-line no-undefined
          regionId = undefined;
        }
      }
      state.linkedClientId = clientId;
      state.linkedRegionId = regionId;
      return state;

    case types.GET_PLACES_REQUEST:
      state.needToUpdatePlaces = false;
      state.isLoadingPlaces = true;
      return state;

    case types.GET_PLACES_SUCCESS:
      state.isLoadingPlaces = false;
      state.places = action.payload.places;
      state.meta = {...state.meta, ...action.payload.meta};
      return state;

    case types.GET_MAPPED_PLACES_SUCCESS:
      state.mappedPlaces = action.payload.places;
      return state;

    case types.GET_PLACES_ERROR:
      state.addPlaceSubmitting = false;
      return state;

    case types.GET_PLACES_WITH_FILTER_REQUEST:
      state.needToUpdatePlaces = false;
      state.isLoadingPlaces = true;
      return state;

    case types.GET_PLACES_WITH_FILTER_SUCCESS:
      state.isLoadingPlaces = false;
      state.places = action.payload.places;
      state.meta = {...state.meta, ...action.payload.meta};
      return state;

    case types.GET_PLACES_WITH_FILTER_ERROR:
      state.addPlaceSubmitting = false;
      return state;

    case types.ADD_PLACE_REQUEST:
      state.addPlaceSubmitting = true;
      return state;

    case types.ADD_PLACE_SUCCESS:
      state.addPlaceSubmitting = false;
      state.place = action.payload.place;
      state.addPlaceModalToggle = false;
      state.needToUpdatePlaces = true;
      state.addressDoesNotExist = [false];
      return state;

    case types.ADD_PLACE_ERROR:
      state.addPlaceSubmitting = false;
      if (action.payload.response.errors.latitude) {
        state.addressDoesNotExist = [true];
      }
      return state;

    case types.EDIT_PLACE_REQUEST:
      state.addPlaceSubmitting = true;
      return state;

    case types.EDIT_PLACE_SUCCESS:
      state.addPlaceSubmitting = false;
      state.addPlaceModalToggle = false;
      state.linkToClientModalToggle = false;
      state.needToUpdatePlaces = true;
      return state;

    case types.EDIT_PLACE_ERROR:
      return state;

    case types.LINK_TO_CLIENT_SUCCESS:
      state.linkToClientModalToggle = false;
      state.needToUpdatePlaces = true;
      return state;

    case types.LINK_TO_CLIENT_ERROR:
      return state;

    case types.CHANGE_SELECTED_PLACES:
      state.selectedPlaces = action.selectedPlaces;
      return state;

    default:
      return state;
  }
}
