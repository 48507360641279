import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import LanguageDeviceControl from 'client/components/diy-operation/controls/lang-device-control';

import OverallInputButton from './overall-input-button';

import './language-device-panel.scss';

const b = bem('language-device-panel');

const getLength = (items) => items?.length || 0;

const LanguageDevicePanel = (props) => {
  const {
    prefix,
    language,
    languages,
    device,
    devices,
    onChangeLanguage,
    onChangeDevice,
    className,
    langInitiallyCommon,
    deviceInitiallyCommon,
    onLanguageCommonToggle,
    onDeviceCommonToggle,
    disabled,
  } = props;
  const translations = {
    ...useLanguage('DIY_OPERATION.CONTROLS.DIY_OP_LANG_DEVICE_CONTROL'),
    ...useLanguage('DIY_OPERATION.MODALS.CONFIGURATION_MODAL'),
  };

  const [isCommonLang, toggleIsCommonLang] = useToggle(langInitiallyCommon);
  const [isCommonDevice, toggleIsCommonDevice] = useToggle(deviceInitiallyCommon);

  if (getLength(languages) < 2 && getLength(devices) < 2) {
    return null;
  }

  return (
    <div className={classNames(b(), className)}>
      {getLength(languages) > 1 && (
        <div className={b('block')}>
          {isCommonLang ? (
            <OverallInputButton
              iconName="per-item-input"
              size="small"
              label={translations.LANG_UNIQUE_INPUT}
              disabled={disabled}
              onClick={() => {
                toggleIsCommonLang();
                onLanguageCommonToggle?.(false);
              }}
            />
          ) : (
            <>
              <div className={b('label')}>
                <span>{translations.LANGUAGE_SELECTOR_LEGEND}</span>
              </div>
              <div className={b('buttons-wrapper')}>
                <LanguageDeviceControl
                  showLabel={false}
                  prefix={prefix}
                  language={language}
                  languages={languages}
                  onChangeLanguage={onChangeLanguage}
                  className={b('language')}
                  disabled={disabled}
                />
                <OverallInputButton
                  iconName="common"
                  tooltipText={translations.COMMON_INPUT}
                  onClick={() => {
                    toggleIsCommonLang();
                    onLanguageCommonToggle?.(true);
                  }}
                  showTooltip
                  disabled={disabled}
                />
              </div>
            </>
          )}
        </div>
      )}

      {getLength(devices) > 1 && (
        <div className={b('block', {devices})}>
          {isCommonDevice ? (
            <OverallInputButton
              iconName="per-item-input"
              size="small"
              label={translations.DEVICE_UNIQUE_INPUT}
              disabled={disabled}
              onClick={() => {
                toggleIsCommonDevice();
                onDeviceCommonToggle?.(false);
              }}
            />
          ) : (
            <>
              <div className={b('label')}>
                <span>{translations.DEVICE_SELECTOR_LEGEND}</span>
              </div>
              <div className={b('buttons-wrapper')}>
                <OverallInputButton
                  iconName="common"
                  tooltipText={translations.COMMON_INPUT}
                  onClick={() => {
                    toggleIsCommonDevice();
                    onDeviceCommonToggle?.(true);
                  }}
                  showTooltip
                  disabled={disabled}
                />
                <LanguageDeviceControl
                  showLabel={false}
                  prefix={prefix}
                  device={device}
                  devices={devices}
                  onChangeDevice={onChangeDevice}
                  className={b('language')}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

LanguageDevicePanel.propTypes = {
  className: PropTypes.string,
  prefix: PropTypes.string.isRequired,
  language: PropTypes.string,
  defaultLanguage: PropTypes.string,
  languages: PropTypes.array,
  device: PropTypes.string,
  defaultDevice: PropTypes.string,
  devices: PropTypes.array,
  onChangeLanguage: PropTypes.func,
  onChangeDevice: PropTypes.func,
  langInitiallyCommon: PropTypes.bool,
  deviceInitiallyCommon: PropTypes.bool,
  onLanguageCommonToggle: PropTypes.func,
  onDeviceCommonToggle: PropTypes.func,
  disabled: PropTypes.bool,
};

LanguageDevicePanel.defaultProps = {
  className: '',
  language: null,
  defaultLanguage: null,
  languages: null,
  device: null,
  defaultDevice: null,
  devices: null,
  onChangeDevice: null,
  onChangeLanguage: null,
  langInitiallyCommon: false,
  deviceInitiallyCommon: false,
  onLanguageCommonToggle: null,
  onDeviceCommonToggle: null,
  disabled: false,
};

export default LanguageDevicePanel;
