import React from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {selectClient} from 'client/ducks/leads-list/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import ArticlePanel from 'client/common/panels/article-panel';

import DiyOpDiffusionCard from 'client/components/diy-operation/cards/diy-op-diffusion-card';

import './diy-op-diffusion-block.scss';

const b = bem('diy-op-diffusion-block');

const tabs = CLIENT_PAGES.CLIENT_AUTOTASK_TABS;

const DEVICES = tabs.CONFIGURATION_DEVICES.replace('/', '');
const ONLINE = tabs.CONFIGURATION_ONLINE.replace('/', '');

/**
 *
 * @type {React.FC<DIYOpDiffusionBlock.propTypes>}
 */

// TODO: from jsx => tsx
const DIYOpDiffusionBlock = ({operation, fetchOperation}) => {
  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_DIFFUSION_BLOCK');
  const client = useSelector(selectClient);
  const autotask = useSelector(selectAutotask);

  const isDevice = operation?.client_interface?.device;
  const isWebApp = operation?.client_interface?.online;

  const isDevicesCardConfigurable = autotask.interaction_groups.some((interaction) => interaction.type === 'Plan');
  const isWebAppCardConfigurable = autotask.interaction_groups.some((interaction) => interaction.type === 'Campaign');

  return (
    <ArticlePanel className={b()} title={lang.TITLE}>
      <DiyOpDiffusionCard
        title={lang.DEVICES_TITLE}
        value={operation.devices_count}
        type={DEVICES}
        total={client.devices_count}
        className={b('card')}
        disabledConfigurationPrize={isWebApp && !isDevice}
        disabledConfiguration={!isDevicesCardConfigurable}
        showButton={!client.auto_configuration}
        fetchOperation={fetchOperation}
      />
      <DiyOpDiffusionCard
        title={lang.ONLINE_TITLE}
        value={operation.online_interactions_count || 0}
        type={ONLINE}
        className={b('card')}
        disabledConfigurationPrize={!isWebApp && isDevice}
        disabledConfiguration={!isWebAppCardConfigurable}
        showButton={!client.auto_configuration}
        fetchOperation={fetchOperation}
      />
    </ArticlePanel>
  );
};

DIYOpDiffusionBlock.propTypes = {
  operation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    devices_count: PropTypes.number.isRequired,
    online_interactions_count: PropTypes.number,
  }).isRequired,
  fetchOperation: PropTypes.func.isRequired,
};

export default DIYOpDiffusionBlock;
