export const checkForPHPScripts = (data: Blob): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      const result = event.target?.result;

      if (typeof result !== 'string') {
        return;
      }

      const phpTagPattern = /<\?(?:php|=|)[\s\S]*?(?:\?>|$)/gi;
      const containsPhpTags = phpTagPattern.test(result);

      resolve(containsPhpTags);
    };

    fileReader.readAsText(data);
  });
};
