import {SET_DUB_FA} from './types.js';

const initialState = {
  doubleFactorAuth: {
    isSet: false,
    email: null,
    password: null,
    options: {
      email: '',
      phone: '',
      subsidiary_email: '',
      subsidiary_phone: '',
    },
  },
};

export default function settingsReducer(state = initialState, {type, payload}) {
  switch (type) {
    case SET_DUB_FA:
      return {
        ...state,
        doubleFactorAuth: {
          isSet: true,
          ...payload,
        },
      };

    default:
      return state;
  }
}
