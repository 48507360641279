import {map} from 'lodash';

import {borderColor, fontColor, fontStyles, barStyles, BAR_EMPTY_DATA} from './constants';

const getScaleOptions = (suggestedMax) => ({
  xAxis: {
    display: true,
    grid: {
      borderColor: borderColor,
      display: false,
    },
    ticks: {
      color: fontColor,
      font: fontStyles,
    },
  },
  yAxis: {
    suggestedMax,
    display: true,
    grid: {
      color: borderColor,
      drawBorder: false,
      borderDash: [4, 4],
    },
    ticks: {
      color: fontColor,
      precision: 0,
      font: fontStyles,
    },
  },
});

/**
 * @typedef {import('chart.js').ChartOptions} ChartOptions
 */

/**
 * Returns options for Bar/Pie chart.js
 * @param {Object} config - extern options
 * @returns {ChartOptions} - Options for chart.js copmonent
 */
export const getChartOptions = ({suggestedMax, empty}) => {
  const backgroundColor = empty ? borderColor : barStyles.backgroundColor;
  return {
    animation: {duration: 0},
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: !empty,
      },
    },
    scales: getScaleOptions(suggestedMax),
    datasets: {
      bar: {
        ...barStyles,
        backgroundColor,
      },
    },
  };
};

export const getChartData = (data) => {
  const rawData = !data?.length ? BAR_EMPTY_DATA : data;

  return {
    labels: map(rawData, 'answer'),
    datasets: [
      {
        type: 'bar',
        data: map(rawData, (d) => Number(d.value)),
      },
    ],
  };
};
