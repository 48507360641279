import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/components/common/icon';

import './status-tag.scss';

const b = bem('status-tag');

const StatusTag = (props) => {
  const {status, className, id} = props;
  const lang = useLanguage('COMMON.STATUSES');

  return (
    <div className={cn(b([status]), className)} id={id}>
      <Icon name={status} className={b('icon')} />
      <span className={b('text')}>{lang[status.toUpperCase()]}</span>
    </div>
  );
};

// please, to add new icon name to $status-variants too ( status.scss )
StatusTag.propTypes = {
  status: PropTypes.oneOf(['active', 'finished', 'on_configuration']).isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
};

StatusTag.defaultProps = {
  className: '',
  id: null,
};

export default StatusTag;
