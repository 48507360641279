import React, {Component} from 'react';

import remove from 'lodash/remove';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';

import {ACCESS_LEVEL_TYPES} from 'client/ducks/client-users/constants';

import {API_METHODS} from 'client/common/config';
import Icon from 'client/common/icon';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import SearchField from 'client/components/common/search-field';

import UsersTableEditorCell from './cells/users-table-editor-cell';
import UsersTableResendInvitationCell from './cells/users-table-resend-invitation-cell';

import './users-table.scss';

const b = bem('users-table');

class UsersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: {sortField: '', sortOrder: ''},
      perPage: 5,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.needToUpdate && !this.props.needToUpdate) {
      this.onAllSelect(false);
    }
  }
  renderCivility = ({item: user}) => {
    return <div>{user.client_user?.title}</div>;
  };

  renderEmail = ({item: user}) => {
    return <div>{user.prize_tracking_user?.login || user.email}</div>;
  };

  renderCell = ({value: cell}) => {
    return <div className="u-list__item--overflow-ellipsis">{cell}</div>;
  };

  onPageChange = (params) => {
    this.setState({perPage: params.perPage});
    this.onAllSelect(false);
    this.props.onPageChange(params);
  };

  onSortChange = (sort) => {
    this.onAllSelect(false);
    this.setState({sort});

    this.props.onSortChange(sort.sortField, sort.sortOrder);
  };

  onSelect = (isSelected, row) => {
    const {changeSelectedRecords, selectedRecords} = this.props;

    if (isSelected) {
      selectedRecords.push(row.id);
    } else {
      remove(selectedRecords, (i) => i === row.id);
    }
    changeSelectedRecords(selectedRecords);
  };

  onAllSelect = (isSelected) => {
    const {changeSelectedRecords, data, selectedRecords} = this.props;

    if (isSelected) {
      changeSelectedRecords(
        uniq([...selectedRecords, ...data.filter((item) => !item.prize_tracking_user_id).map(({id}) => id)]),
      );
    } else {
      changeSelectedRecords(selectedRecords.filter((id) => !data.find((row) => row.id === id)));
    }
  };

  handleNameClick = (user) => {
    if (this.props.isAdmin) {
      this.props.onChangeUserClick(user);
    }
  };

  toggleCheckbox = (isChecked, toggledNumber, list) => {
    const newList = list.slice(0);
    newList[toggledNumber] = isChecked;
    return newList;
  };

  toggleThemAll = (isChecked, list) => {
    return list.fill(isChecked);
  };

  renderClientAccessLevel = ({item: user}) => {
    if (user.all_companies_access) {
      return <span>{this.props.lang.ALL_CLIENTS_ACCESS_LEVEL}</span>;
    }
    return (
      <ul className="u-list u-list--no-markers">
        {user.accessible_clients.map((item) => (
          <li className="u-list__item u-list__item--overflow-ellipsis" key={item.client.id}>
            <Link to={`${API_METHODS.COMPANIES}/${item.client.id}`}>{item.client.name}</Link>
          </li>
        ))}
      </ul>
    );
  };

  renderAgencyAccessLevel = ({item: user}) => {
    switch (user.access_level) {
      case ACCESS_LEVEL_TYPES.STORES:
        return (
          <div className="clearfix">
            {this.props.clientAgency && (
              <div className="pull-left access-level-label">{this.props.lang.STORES_ACCESS_LEVEL}</div>
            )}
            <div className="pull-left" style={{width: this.props.clientAgency ? '79%' : '100%'}}>
              <div className="u-list access-level-list">
                <CustomScrollbars
                  scrollbarProps={{
                    autoHeightMax: 150,
                  }}
                >
                  <div className="access-level-list__inner">
                    {user.place_accesses.map(({place}) => (
                      <div className="u-list__item" key={place.id}>
                        <div className="access-level-list__city">
                          <div className="ellipsis-text">{place.city_name}</div>
                        </div>
                        <div className="access-level-list__place">
                          <div className="ellipsis-text">{place.street_address}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </CustomScrollbars>
              </div>
            </div>
          </div>
        );

      case ACCESS_LEVEL_TYPES.LOCAL:
      case ACCESS_LEVEL_TYPES.REGIONS:
        return (
          <div>
            {this.props.clientAgency && (
              <div className="pull-left access-level-label">
                {user.access_level === ACCESS_LEVEL_TYPES.LOCAL
                  ? this.props.lang.LOCAL_ACCESS_LEVEL
                  : this.props.lang.REGIONS_ACCESS_LEVEL}
              </div>
            )}
            <div className="pull-left" style={{width: this.props.clientAgency ? '79%' : '100%'}}>
              <div className="u-list access-level-list">
                <CustomScrollbars
                  scrollbarProps={{
                    autoHeightMax: 150,
                  }}
                >
                  <div className="access-level-list__inner">
                    {user.region_accesses.map(({region}) => (
                      <div className="u-list__item" key={region.id}>
                        <div className="access-level-list__city">
                          <div className="ellipsis-text">{region.name}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </CustomScrollbars>
              </div>
            </div>
          </div>
        );

      case ACCESS_LEVEL_TYPES.NATIONAL:
        return (
          <div>
            {this.props.clientAgency && (
              <div className="pull-left access-level-label">{this.props.lang.NATIONAL_ACCESS_LEVEL}</div>
            )}
            <div className="pull-left" style={{width: this.props.clientAgency ? '79%' : '100%'}}>
              {this.props.lang.NATIONAL_ACCESS_LEVEL_DESCRIPTION}
            </div>
          </div>
        );

      case ACCESS_LEVEL_TYPES.CLIENT_ADMIN:
        return <div className="pull-left">{this.props.lang.CLIENT_ADMIN_ACCESS_LEVEL}</div>;

      default:
        return null;
    }
  };

  renderName = ({item: user}) => {
    return (
      <div
        className={user.prize_tracking_user_id ? '' : 'users-table__link'}
        onClick={() => {
          if (!user.prize_tracking_user) {
            this.handleNameClick(user);
          }
        }}
      >
        {user.prize_tracking_user?.full_name || user.client_user?.full_name}
      </div>
    );
  };

  renderTitle = ({item: user}) => {
    return <div>{user.prize_tracking_user_id ? this.props.lang.PRIZE_TRACKING : user.client_user?.full_name}</div>;
  };

  renderBlocked({item: user}) {
    const value = user.prize_tracking_user?.blocked || user.client_user?.blocked;
    return (
      <div className="users-table__blocked-cell">
        <Icon name="role-user" className={value ? 'theme-color-12' : 'theme-color-3'} />
        {!user.prize_tracking_user_id && <UsersTableResendInvitationCell user={user.client_user} />}
      </div>
    );
  }

  renderEditor({item: user}) {
    return user.prize_tracking_user_id ? null : <UsersTableEditorCell membership={user} />;
  }

  renderToolbar = () => {
    const {isAdmin} = this.props;

    return (
      <div className={b('toolbar-wrapper')}>
        <div className={b('toolbar', ['left'])}>
          <div className={b('title')}>
            <span className={b('title-text')}>
              {this.props.lang.USERS_TITLE} ({this.props.totalItems})
            </span>
          </div>
          <SearchField
            cssModifier="users-table__search"
            onSearch={this.props.onSearch}
            onClear={this.props.onSearch}
            reset={this.props.resetUsersSearch}
            onReset={this.props.onResetUsersSearch}
          />
        </div>
        <div className={b('toolbar', ['right'])}>
          {isAdmin && (
            <>
              {this.props.selectedRecords.length ? (
                <button className="button button--bg-3" onClick={this.props.deleteUsersClick}>
                  <Icon name="trash" className="button__icon" width={17} height={19} />
                  <span>{`${this.props.lang.DELETE_USERS_BUTTON} (${this.props.selectedRecords.length})`}</span>
                </button>
              ) : (
                <button className="button button--bg-3" onClick={this.props.addNewClick}>
                  <Icon name="plus" className="button__icon" />
                  <span>{this.props.lang.ADD_NEW_BUTTON}</span>
                </button>
              )}
            </>
          )}
          <PerPageDropdown
            simpleValue
            value={this.state.perPage.toString()}
            onChange={(value) => this.onPageChange({page: 1, perPage: value})}
            options={[5, 10, 15].map((n) => ({value: `${n}`, label: n}))}
          />
        </div>
      </div>
    );
  };

  render() {
    const {data, currentPage, totalPages, totalItems, lang, selectedRecords} = this.props;

    return (
      <div className={b()}>
        {this.renderToolbar()}
        <ClientTable
          data={data}
          {...this.state.sort}
          onSortChange={this.onSortChange}
          checkable
          disableCheck={(item) => !!item.prize_tracking_user_id}
          onCheck={this.onSelect}
          onCheckAll={this.onAllSelect}
          checkedRows={selectedRecords.map((id) => ({id}))}
          checkboxProps={{
            color: 'users',
          }}
          columns={[
            {
              label: lang.CIVILITY_COLUMN,
              name: 'civility',
              path: 'civility',
              render: this.renderCivility,
            },
            {
              label: lang.FIRST_LAST_NAME_COLUMN,
              name: 'client_user_full_name',
              path: 'client_user_full_name',
              sortable: true,
              render: this.renderName,
            },
            {
              label: '',
              name: 'client_user_blocked',
              path: 'client_user_blocked',
              render: this.renderBlocked,
            },
            {
              label: lang.TITLE_COLUMN,
              name: 'title',
              path: 'title',
              render: this.renderTitle,
            },
            {
              label: lang.EMAIL_COLUMN,
              name: 'email',
              path: 'email',
              render: this.renderEmail,
            },
            {
              label: lang.PHONE_COLUMN,
              name: 'phone',
              path: 'phone',
              render: this.renderCell,
            },
            {
              label: (
                <div>
                  <div>{lang.ACCESS_LEVEL_COLUMN}</div>
                  <div>({lang.CLIENT_ACCESS_LEVEL_COLUMN})</div>
                </div>
              ),
              name: 'client_access_level',
              path: 'client_access_level',
              render: this.renderClientAccessLevel,
              show: !this.props.clientAgency,
            },
            {
              label: (
                <div>
                  <div>{lang.ACCESS_LEVEL_COLUMN}</div>
                  {!this.props.clientAgency && <div>({lang.AGENCY_ACCESS_LEVEL_COLUMN})</div>}
                </div>
              ),
              name: 'agency_access_level',
              path: 'agency_access_level',
              render: this.renderAgencyAccessLevel,
            },
            {
              label: lang.EDITOR_COLUMN,
              name: 'editor',
              path: 'editor',
              render: this.renderEditor,
            },
          ]}
        />
        <PaginationBar
          onPageChange={this.onPageChange}
          totalPages={totalPages}
          totalItems={totalItems}
          currentPage={currentPage}
          perPage={this.state.perPage}
          data={data}
        />
      </div>
    );
  }
}

UsersTable.propTypes = {
  data: PropTypes.array,
  selectedRecords: PropTypes.array,
  children: PropTypes.any,
  deleteUsersClick: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  totalItems: PropTypes.number,
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  changeSelectedRecords: PropTypes.func.isRequired,
  onChangeUserClick: PropTypes.func.isRequired,
  addNewClick: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  needToUpdate: PropTypes.bool,
  clientAgency: PropTypes.bool,
  lang: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  resetUsersSearch: PropTypes.bool.isRequired,
  onResetUsersSearch: PropTypes.func.isRequired,
};

UsersTable.defaultProps = {
  data: [],
  clientAgency: false,
  needToUpdate: false,
  totalItems: 0,
  currentPage: 1,
  totalPages: 1,
  perPage: 5,
};

export default connect(({languageState}) => ({
  lang: languageState.payload.AGENCY,
}))(UsersTable);
