import React from 'react';

import cn from 'classnames';
import {useHistory} from 'react-router-dom';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons';

import {Translation} from 'client/models/language/types';

import cssModule from './winning-back-button.module.scss';

const b = bem('winning-back-button', {cssModule});

type WinningBackButtonProps = {
  className?: string;
  icon?: 'arrow-left' | 'close' | null;
  path?: string;
  callback?: () => void;
  label?: Translation;
};

const WinningBackButton: React.FC<WinningBackButtonProps> = (props) => {
  const {className, icon, path, label, callback} = props;
  const history = useHistory();

  return (
    <div className={cn(b(), className)}>
      {icon && (
        <AppButton
          onClick={() => {
            callback ? callback() : path && history.push(path);
          }}
          iconConfig={{
            className: b('icon'),
            name: icon,
            width: 16,
            height: 16,
          }}
          asWrap
        />
      )}
      {label && <span className={b('label')}>{label}</span>}
    </div>
  );
};

export default WinningBackButton;
