import React, {useEffect} from 'react';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';

import {SelectField} from 'client/common/fields';

import {TEMPLATE_CATEGORY, TEMPLATE_CATEGORY_RESULT} from 'client/models/templates/constants';
import {Template} from 'client/models/templates/types';

import cssModule from './template-game-parameters.module.scss';

const b = bem('template-game-parameters', {cssModule});

export const TemplateGameParametersFormName = 'TemplateGameParametersForm';

type TemplateGameParametersProps = {
  template: Template;
};

const TemplateGameParameters: React.FC<TemplateGameParametersProps> = (props) => {
  const {template} = props;

  const lang = useLanguage('TEMPLATE.GAME_PARAMS');
  const langCommon = useLanguage('COMMON');

  const {initialize} = useReduxForm<Pick<Template, 'category' | 'category_result' | 'game_number'>>(
    TemplateGameParametersFormName,
    {
      initialValues: {
        category: template.category,
        category_result: template.category_result,
        game_number: template.game_number,
      },
    },
  );

  useEffect(() => {
    initialize({
      category: template.category,
      category_result: template.category_result,
      game_number: template.game_number,
    });
  }, [initialize, template, template.id]);

  return (
    <div className={b()}>
      <SelectField
        label={lang.CATEGORY_FIELD}
        name="category"
        simpleValue
        clearable
        options={[
          {label: langCommon.NONE, value: TEMPLATE_CATEGORY.NONE},
          {label: langCommon.INSTANT_WIN, value: TEMPLATE_CATEGORY.INSTANT_WIN},
          {label: langCommon.LOTTERY, value: TEMPLATE_CATEGORY.LOTTERY},
          {label: lang.GENERIC_OPTION, value: TEMPLATE_CATEGORY.GENERIC},
        ]}
      />
      <SelectField
        label={lang.CATEGORY_RESULT_FIELD}
        name="category_result"
        simpleValue
        clearable
        options={[
          {label: langCommon.NONE, value: TEMPLATE_CATEGORY_RESULT.NONE},
          {label: lang.WIN_OPTION, value: TEMPLATE_CATEGORY_RESULT.WIN},
          {label: lang.LOSS_OPTION, value: TEMPLATE_CATEGORY_RESULT.LOSS},
        ]}
      />
      <SelectField
        label={lang.GAME_NUMBER_FIELD}
        name="game_number"
        simpleValue
        clearable
        options={[
          {label: '1', value: 1},
          {label: '2', value: 2},
          {label: '3', value: 3},
        ]}
      />
    </div>
  );
};

const Form = reduxForm<TemplateGameParametersProps>({form: TemplateGameParametersFormName})(TemplateGameParameters);

export default Form;
