import React from 'react';

import bem from 'client/services/bem';

import cssModule from './participants-summary-popover-block-info.module.scss';

const b = bem('participants-summary-popover-block-info', {cssModule});

type ParticipantsSummaryPopoverBlockInfoProps = {
  caption: string;
  data: string | React.ReactNode;
};
const ParticipantsSummaryPopoverBlockInfo: React.FC<ParticipantsSummaryPopoverBlockInfoProps> = (props) => {
  const {caption, data} = props;
  return (
    <div className={b('')}>
      <span className={b('info-text', ['caption'])}>{caption}</span>
      <div className={b('info-text', ['data'])}>{data}</div>
    </div>
  );
};

export default ParticipantsSummaryPopoverBlockInfo;
