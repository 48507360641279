import React from 'react';

import PropTypes from 'prop-types';

function AmountColumn(props) {
  const {amount, onClick} = props;

  return (
    <div className="text-center">
      <div className="link" onClick={onClick}>
        {amount}
      </div>
    </div>
  );
}

AmountColumn.propTypes = {
  amount: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

export default AmountColumn;
