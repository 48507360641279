import React, {useMemo} from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectRegions} from 'client/ducks/client-users/selectors';
import {selectCurrentMembership} from 'client/ducks/user-clients/selectors';

import {USER_MEMBERSHIP} from 'client/common/config';
import {CheckListField, RadioGroupField} from 'client/common/fields';
import WarningLabel from 'client/common/typography/warning-label';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {ClientUserModalFormName} from 'client/components/client-account-parameters/modals/client-user-modal';
import {ClientUserModalForm} from 'client/components/client-account-parameters/modals/client-user-modal/types';
import {Client} from 'client/models/client/types';

import ClientUserTitle from '../client-user-title';

import cssModule from './client-user-regions-step.module.scss';

const b = bem('client-user-regions-step', {cssModule});

type ClientUserRegionsStepProps = {
  client: Client;
};

const ClientUserRegionsStep: React.FC<ClientUserRegionsStepProps> = () => {
  const lang = useLanguage('CLIENT_ACCOUNT_PARAMETERS.MODALS.CLIENT_USER');

  const {formValues} = useReduxForm<ClientUserModalForm>(ClientUserModalFormName);

  const regions = useSelector(selectRegions);

  const currentMembership = useSelector(selectCurrentMembership);
  const membership = formValues.memberships?.[0];
  const isCurrentUser = currentMembership?.id && membership?.id === currentMembership?.id;

  const regionsOptions = useMemo(
    () =>
      regions.map((region) => ({
        value: region.id?.toString(),
        label: region.name,
      })),
    [regions],
  );

  return (
    <div className={b()}>
      <ClientUserTitle
        email={formValues.email}
        civility={formValues.title}
        firstName={formValues.first_name}
        lastName={formValues.last_name}
      />
      <p className={b('block-title')}>
        {lang.ACCESS_TO_REGIONS}
        {isCurrentUser && <WarningLabel label={lang.ERRORS.CANT_MODIFY} />}
      </p>
      <CustomScrollbars
        scrollbarProps={{
          autoHeightMax: 301,
        }}
      >
        {formValues.memberships[0]?.access_level === USER_MEMBERSHIP.LOCAL ? (
          <RadioGroupField
            options={regionsOptions}
            name="memberships[0].region_accesses[0]"
            inversionColor
            disabled={isCurrentUser}
            color="primary"
          />
        ) : (
          <CheckListField
            options={regionsOptions}
            name="memberships[0].region_accesses"
            inversionColor
            disabled={isCurrentUser}
            color="primary"
          />
        )}
      </CustomScrollbars>
    </div>
  );
};

export default ClientUserRegionsStep;
