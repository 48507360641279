import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {ToggleField} from 'client/common/fields';

import cssModule from './calculation-consider-empty-field.module.scss';

const b = bem('calculation-consider-empty-field', {cssModule});

const CalculationConsiderEmptyField: React.FC = () => {
  const lang = useLanguage('AUTOTASK_SCENARIO.SCENARIO_FORMS');

  return (
    <div className={b()}>
      <span className={b('text')}>{lang.CONSIDER_EMPTY_OFF}</span>
      <ToggleField className={b('toggle-field')} name="consider_empty_values" withWrap={false} />
      <span className={b('text')}>{lang.CONSIDER_EMPTY_ON}</span>
    </div>
  );
};

export default CalculationConsiderEmptyField;
