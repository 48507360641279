import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import {useSetState} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {CheckboxField} from 'client/common/fields';
import DateTimeListField from 'client/common/fields/datetime-list-field';

import {TIME_FORMAT, WEEKDAYS} from 'client/components/prizes/constants';
import {PrizeDiyModalFormName} from 'client/components/prizes/modals/prize-diy-modal';

import './prize-diy-weekly-list-fieldset.scss';

const b = bem('prize-diy-weekly-list-fieldset');

const PrizeDiyWeeklyListFieldset = ({disabled}) => {
  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_WEEKLY_LIST_FIELDSET');

  const {change} = useReduxForm(PrizeDiyModalFormName);
  const [keys, setKeys] = useSetState({});

  const handleInputBlur = (values, fieldName) => {
    if (values.length > 1) {
      const sortedValues = values.sort((first, second) =>
        moment(first.value, TIME_FORMAT).diff(moment(second.value, TIME_FORMAT), 'seconds'),
      );

      change(fieldName, sortedValues);
      setKeys((prevState) => ({[fieldName]: (prevState[fieldName] || 0) + 1})); // the key is updated to re-render a DateTimeListField every time the values are updated manually
    }
  };

  return (
    <div className={b()}>
      <p className={b('description')}>{lang.DESCRIPTION}</p>
      <ul className={b('weekdays')}>
        {WEEKDAYS.map((weekday) => {
          const fieldName = `weeklyList.timestamps.${weekday}`;
          const key = weekday + String(keys[fieldName] || '');

          return (
            <li key={key} className={b('weekday')}>
              <span className={b('weekday-title')}>{lang.WEEKDAYS[weekday.toUpperCase()]}</span>
              <DateTimeListField
                type="time"
                name={fieldName}
                className={b('weekday-input')}
                disabled={disabled}
                maskChars={{second: '0'}}
                onBlur={(_, values) => handleInputBlur(values, fieldName)}
              />
            </li>
          );
        })}
      </ul>
      <div className={b('additional-controls')}>
        <span>{lang.PRIZES_LEFT}</span>
        <div className={b('same-day')}>
          <CheckboxField
            label={lang.NEXT_DAY_LABEL}
            color="primary"
            inversionColor
            name="weeklyList.next_day_win"
            disabled={disabled}
          />
          <CheckboxField
            label={lang.SAME_DAY_LABEL}
            color="primary"
            inversionColor
            name="weeklyList.intraday_win"
            disabled={disabled}
          />
        </div>
        <span>{lang.EXCLUDE_LABEL}</span>
        <DateTimeListField name="dates_off" className={b('exclude')} type="date" disabled={disabled} />
      </div>
    </div>
  );
};

PrizeDiyWeeklyListFieldset.propTypes = {
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool,
};

export default PrizeDiyWeeklyListFieldset;
