import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, Field} from 'redux-form';

import {selectLanguageDomain} from 'client/ducks/language/selectors';
import {selectGroupsMappedById} from 'client/ducks/visuals/selectors';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';

class VisGroupsForm extends Component {
  static propTypes = {
    language: PropTypes.object.isRequired,
    pristine: PropTypes.bool.isRequired,
    groupsById: PropTypes.object.isRequired,
    reset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onGroupConfirm: PropTypes.func.isRequired,
    onCreateGroupClick: PropTypes.func.isRequired,
    getListRef: PropTypes.func,
  };

  static defaultProps = {
    getListRef: () => {},
  };

  static formName = 'VisSelectGroupDropdownForm';
  static languageDomain = 'VISUALS.SELECT_DROPDOWN';

  renderList = () => {
    const {language, groupsById} = this.props;

    return Object.entries(groupsById).map(([id, group]) => {
      const {name, parentGroupId} = group;
      const labelPrefix = parentGroupId ? language.MANUAL_SUBGROUP : language.MANUAL_GROUP;
      const label = `${labelPrefix}: ${name}`;

      return (
        <div key={id} className="form-field-wrap form-field-wrap--margin-3" title={label}>
          <Field
            cssModifier="radio-button--view-3"
            type="radio"
            name="id"
            value={String(id)}
            label={label}
            component={fieldTemplate}
          />
        </div>
      );
    });
  };

  save = ({id}) => {
    const {groupsById, onGroupConfirm} = this.props;
    const selectedGroup = groupsById[id];
    onGroupConfirm(selectedGroup);
  };

  render() {
    const {pristine, language, reset, handleSubmit, onCreateGroupClick} = this.props;

    return (
      <form onSubmit={handleSubmit(this.save)} noValidate="noValidate">
        <CustomScrollbars
          scrollbarProps={{
            hideTracksWhenNotNeeded: true,
          }}
        >
          <div
            className="custom-scrollbars__scroll-content"
            ref={(list) => {
              this.props.getListRef(list);
            }}
          >
            {this.renderList()}
          </div>
        </CustomScrollbars>
        <div className="vis-select-dropdown__bottom">
          <button
            type="button"
            className="button button--bg-8 button--full-width vis-select-dropdown__full-btn"
            onClick={onCreateGroupClick}
          >
            {language.CREATE_GROUP}
          </button>
          <button
            disabled={pristine}
            type="button"
            onClick={reset}
            className="button button--bg-11 vis-select-dropdown__btn"
          >
            {language.CANCEL_BUTTON}
          </button>
          <button disabled={pristine} type="submit" className="button button--bg-8 vis-select-dropdown__btn">
            {language.CONFIRM_BUTTON}
          </button>
        </div>
      </form>
    );
  }
}

const form = reduxForm({
  form: VisGroupsForm.formName,
})(VisGroupsForm);

const mapStateToProps = (state) => ({
  language: selectLanguageDomain(state, VisGroupsForm.languageDomain),
  groupsById: selectGroupsMappedById(state),
});

export default connect(mapStateToProps)(form);
