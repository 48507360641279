export default {
  GET_INSTORE_DASHBOARDS_REQUEST: '@@instore-dashboards/GET_INSTORE_DASHBOARDS_REQUEST',
  GET_INSTORE_DASHBOARDS_SUCCESS: '@@instore-dashboards/GET_INSTORE_DASHBOARDS_SUCCESS',
  GET_INSTORE_DASHBOARDS_FAILED: '@@instore-dashboards/GET_INSTORE_DASHBOARDS_FAILED',

  CLEAR_INSTORE_DASHBOARDS: '@@instore-dashboards/CLEAR_INSTORE_DASHBOARDS',

  CREATE_INTERNAL_INSTORE_DASHBOARD_REQUEST: '@@instore-dashboards/CREATE_INTERNAL_INSTORE_DASHBOARD_REQUEST',
  CREATE_INTERNAL_INSTORE_DASHBOARD_SUCCESS: '@@instore-dashboards/CREATE_INTERNAL_INSTORE_DASHBOARD_SUCCESS',
  CREATE_INTERNAL_INSTORE_DASHBOARD_FAILED: '@@instore-dashboards/CREATE_INTERNAL_INSTORE_DASHBOARD_FAILED',

  UPDATE_INTERNAL_INSTORE_DASHBOARD_REQUEST: '@@instore-dashboards/UPDATE_INTERNAL_INSTORE_DASHBOARD_REQUEST',
  UPDATE_INTERNAL_INSTORE_DASHBOARD_SUCCESS: '@@instore-dashboards/UPDATE_INTERNAL_INSTORE_DASHBOARD_SUCCESS',
  UPDATE_INTERNAL_INSTORE_DASHBOARD_FAILED: '@@instore-dashboards/UPDATE_INTERNAL_INSTORE_DASHBOARD_FAILED',

  DELETE_INTERNAL_INSTORE_DASHBOARD_REQUEST: '@@instore-dashboards/DELETE_INTERNAL_INSTORE_DASHBOARD_REQUEST',
  DELETE_INTERNAL_INSTORE_DASHBOARD_SUCCESS: '@@instore-dashboards/DELETE_INTERNAL_INSTORE_DASHBOARD_SUCCESS',
  DELETE_INTERNAL_INSTORE_DASHBOARD_FAILED: '@@instore-dashboards/DELETE_INTERNAL_INSTORE_DASHBOARD_FAILED',

  SET_DEFAULT_INSTORE_DASHBOARD_REQUEST: '@@instore-dashboards/SET_DEFAULT_INSTORE_DASHBOARD_REQUEST',
  SET_DEFAULT_INSTORE_DASHBOARD_SUCCESS: '@@instore-dashboards/SET_DEFAULT_INSTORE_DASHBOARD_SUCCESS',
  SET_DEFAULT_INSTORE_DASHBOARD_FAILED: '@@instore-dashboards/SET_DEFAULT_INSTORE_DASHBOARD_FAILED',

  UPDATE_INSTORE_DASHBOARD_REQUEST: '@@instore-dashboards/UPDATE_INSTORE_DASHBOARD_REQUEST',
  UPDATE_INSTORE_DASHBOARD_SUCCESS: '@@instore-dashboards/UPDATE_INSTORE_DASHBOARD_SUCCESS',
  UPDATE_INSTORE_DASHBOARD_FAILED: '@@instore-dashboards/UPDATE_INSTORE_DASHBOARD_FAILED',

  GET_INSTORE_DASHBOARD_BLOCKS_REQUEST: '@@instore-dashboards/GET_INSTORE_DASHBOARD_BLOCKS_REQUEST',
  GET_INSTORE_DASHBOARD_BLOCKS_SUCCESS: '@@instore-dashboards/GET_INSTORE_DASHBOARD_BLOCKS_SUCCESS',
  GET_INSTORE_DASHBOARD_BLOCKS_FAILED: '@@instore-dashboards/GET_INSTORE_DASHBOARD_BLOCKS_FAILED',

  GET_INSTORE_DASHBOARD_KPIS_REQUEST: '@@instore-dashboards/GET_INSTORE_DASHBOARD_KPIS_REQUEST',
  GET_INSTORE_DASHBOARD_KPIS_SUCCESS: '@@instore-dashboards/GET_INSTORE_DASHBOARD_KPIS_SUCCESS',
  GET_INSTORE_DASHBOARD_KPIS_FAILED: '@@instore-dashboards/GET_INSTORE_DASHBOARD_KPIS_FAILED',

  CREATE_INSTORE_DASHBOARD_BLOCK_REQUEST: '@@instore-dashboards/CREATE_INSTORE_DASHBOARD_BLOCK_REQUEST',
  CREATE_INSTORE_DASHBOARD_BLOCK_SUCCESS: '@@instore-dashboards/CREATE_INSTORE_DASHBOARD_BLOCK_SUCCESS',
  CREATE_INSTORE_DASHBOARD_BLOCK_FAILED: '@@instore-dashboards/CREATE_INSTORE_DASHBOARD_BLOCK_FAILED',

  UPDATE_INSTORE_DASHBOARD_BLOCK_REQUEST: '@@instore-dashboards/UPDATE_INSTORE_DASHBOARD_BLOCK_REQUEST',
  UPDATE_INSTORE_DASHBOARD_BLOCK_SUCCESS: '@@instore-dashboards/UPDATE_INSTORE_DASHBOARD_BLOCK_SUCCESS',
  UPDATE_INSTORE_DASHBOARD_BLOCK_FAILED: '@@instore-dashboards/UPDATE_INSTORE_DASHBOARD_BLOCK_FAILED',

  DELETE_INSTORE_DASHBOARD_BLOCK_REQUEST: '@@instore-dashboards/DELETE_INSTORE_DASHBOARD_BLOCK_REQUEST',
  DELETE_INSTORE_DASHBOARD_BLOCK_SUCCESS: '@@instore-dashboards/DELETE_INSTORE_DASHBOARD_BLOCK_SUCCESS',
  DELETE_INSTORE_DASHBOARD_BLOCK_FAILED: '@@instore-dashboards/DELETE_INSTORE_DASHBOARD_BLOCK_FAILED',

  DELETE_INSTORE_DASHBOARD_BLOCK_FROM_STORE: '@@instore-dashboards/DELETE_INSTORE_DASHBOARD_BLOCK_FROM_STORE',
};
