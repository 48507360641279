import {selectAutotaskTypes} from 'client/ducks/autotask/selectors';

import {SEGMENTATION_TYPES} from 'client/components/prizes/constants';

export default (prize, state) => {
  const {isDevicesType, isOnlineType} = selectAutotaskTypes(state);
  const isDeviceAndOnlineType = Boolean(isDevicesType && isOnlineType);

  return {
    device_online_segmentation: isDeviceAndOnlineType && Boolean(prize?.device_stock || prize?.online_stock),
    geo_level: prize?.geo_level === SEGMENTATION_TYPES.PER_DEVICE,
    online_level: [SEGMENTATION_TYPES.PER_CAMPAIGN, SEGMENTATION_TYPES.PER_CHANNEL].includes(prize?.online_level)
      ? prize?.online_level
      : SEGMENTATION_TYPES.NONE,
    device_stock: prize?.device_stock?.toString(),
    online_stock: prize?.online_stock?.toString(),
    default_initial_stock: prize?.default_initial_stock?.toString(),
    device_online_type: isDeviceAndOnlineType, // for mapping form  values
  };
};
