import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import Spinner from 'client/components/common/spinner';

import {TranslationJsx} from 'client/models/language/types';

import './account-card.scss';

const AccountCard = (props) => {
  const {title, children, className, loading} = props;

  return (
    <div className={cn(['account-card', className])}>
      {title && <p className="account-card__title">{title}</p>}
      {loading ? <Spinner centered color="primary" /> : children}
    </div>
  );
};

AccountCard.propTypes = {
  title: TranslationJsx,
  className: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
};

AccountCard.defaultProps = {
  title: null,
  className: null,
  children: null,
  loading: false,
};

export default AccountCard;
