import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';
import Modal from 'client/common/modals/modal';

import {TranslationJsx} from 'client/models/language/types';

import './delete-users-modal.scss';

const b = bem('delete-users-modal');

const DeleteUsersModal = (props) => {
  const {show, onClose, onConfirm, users, errorMessage} = props;

  const lang = useLanguage('AGENCY.DELETE_USERS_MODAL');

  const confirmButtonLabel = users.length ? lang.CONFIRM_REST : lang.CONFIRM;

  return (
    <Modal
      show={show}
      dialogClassName={b('modal', {wide: users.length})}
      backdropClassName="modal-window__backdrop"
      onClose={onClose}
      title={lang.TITLE}
      bodyClassName={b('body')}
    >
      <div className={b('content')}>
        <p>{lang.QUESTION_TEXT}</p>
        {users.length > 0 && (
          <div>
            <p>{lang.WARNING_TEXT}</p>
            <ul className={b('list')}>
              {users.map(({id, full_name}) => (
                <li className={b('list-item')} key={id}>
                  {full_name}
                </li>
              ))}
            </ul>
          </div>
        )}
        {errorMessage && <p className={b('error')}>{errorMessage}</p>}
      </div>

      <div className={b('footer')}>
        <AppButton className={b('button', ['cancel'])} onClick={onClose} nativeStyle label={lang.CANCEL} />
        <AppButton className={b('button', ['confirm'])} onClick={onConfirm} nativeStyle label={confirmButtonLabel} />
      </div>
    </Modal>
  );
};

DeleteUsersModal.defaultProps = {
  errorMessage: '',
};

DeleteUsersModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      full_name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
};

export default DeleteUsersModal;
