import React, {Component} from 'react';

import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

let LANGUAGE = {};

class NewUserAgencyStep3Modal extends Component {
  static validate({title, phone}) {
    const errors = {};
    const regex = /^[+0-9][0-9]*$/;

    if (!title || !title.trim()) {
      errors.title = LANGUAGE.TITLE_IS_REQUIRED_ERROR;
    }
    if (!phone || !phone.trim()) {
      errors.phone = LANGUAGE.PHONE_IS_REQUIRED_ERROR;
    } else if (!regex.test(phone)) {
      errors.phone = LANGUAGE.PHONE_IS_INVALID_ERROR;
    }

    return errors;
  }

  static updateSyncErrors(values) {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'NewUserAgencyStep3Form',
      },
      payload: {
        syncErrors: NewUserAgencyStep3Modal.validate(values),
      },
    };
  }

  constructor(props) {
    super(props);

    LANGUAGE = this.LANGUAGE = this.props.languageState.payload.AGENCY;
  }

  componentDidMount() {
    this.intervalID = setInterval(() => {
      if (!isEmpty(LANGUAGE)) {
        clearInterval(this.intervalID);
        this.props.updateErrors({});
      }
    }, 200);
  }

  componentDidUpdate(prevProps) {
    const {initialValues, user} = this.props;
    if (prevProps.initialValues !== initialValues || prevProps.user !== user) {
      if (!initialValues || !initialValues.all_companies_access) {
        this.props.initialize({all_companies_access: 'true'});
        this.props.updateErrors({});
      } else {
        this.props.initialize(initialValues);
        this.props.updateErrors(initialValues);
      }
      if (user) {
        const membership = user.memberships && user.memberships[0];
        if (!membership || isUndefined(membership.all_companies_access)) {
          this.props.initialize({all_companies_access: 'true'});
          this.props.updateErrors({});
        } else {
          membership.all_companies_access = membership.all_companies_access.toString();
          this.props.initialize(membership);
          this.props.updateErrors(membership);
        }
      }
    }
  }

  save = ({title, phone, all_companies_access}) => {
    if (this.props.editingUser) {
      if (all_companies_access === 'true') {
        this.props.onNext({
          showEditClientsAccessModal: 0,
          showEditUserModal: true,
          editingUser: {
            ...this.props.user,
            all_companies_access: 'true',
          },
        });
      } else {
        this.props.onNext({
          showEditClientsAccessModal: 2,
        });
      }
    } else {
      this.props.onNext({
        addNewUserStep: all_companies_access === 'true' ? 4 : 31,
        addNewUserObject: {
          ...this.props.user,
          memberships: [
            {
              type: 'AgencyMembership',
              title,
              phone,
              all_companies_access,
              client_id: this.props.clientId,
            },
          ],
        },
      });
    }
  };

  render() {
    const {show, editingUser, onBack, onClose} = this.props;
    const {first_name: firstName, last_name: lastName, title: civility, email} = this.props.user;
    return (
      <Modal
        show={show}
        onClose={onClose}
        dialogClassName="modal-window--width-1 modal-window--theme-3"
        titleClassName="modal-window__title"
        title={
          <>
            <h3 className="title pull-left">
              {editingUser ? this.LANGUAGE.EDIT_USER_MODAL_TITLE : this.LANGUAGE.NEW_USER_MODAL_TITLE}
            </h3>
            {!editingUser && (
              <div className="pull-right modal-window__step">
                {this.LANGUAGE.NEW_USER_MODAL_STEP} 3 {this.LANGUAGE.NEW_USER_MODAL_STEP_OF} 4
              </div>
            )}
          </>
        }
      >
        <form noValidate="noValidate" onSubmit={this.props.handleSubmit(this.save)}>
          <div className="modal-window__subtitle modal-window__email">{email}</div>
          <div className="modal-window__subtitle modal-window__name">
            {this.LANGUAGE.CIVILITY[civility]} {firstName} {lastName}
          </div>
          {!editingUser && (
            <div className="form-field-wrap">
              <Field
                name="title"
                label={this.LANGUAGE.NEW_USER_MODAL_TITLE_LABEL}
                type="text"
                component={fieldTemplate}
              />
            </div>
          )}
          {!editingUser && (
            <div className="form-field-wrap">
              <Field
                name="phone"
                label={this.LANGUAGE.NEW_USER_MODAL_PHONE_LABEL}
                type="text"
                component={fieldTemplate}
              />
            </div>
          )}
          <div className="form-field-wrap">
            <div className="list list--view-1 list list--bg-1">
              <h4 className="list__title">{this.LANGUAGE.CLIENT_ACCESS_LEVEL_COLUMN}</h4>
              <div className="list__item">
                <Field
                  cssModifier="radio-button--view-1"
                  name="all_companies_access"
                  label={this.LANGUAGE.ALL_CLIENTS_ACCESS_LEVEL}
                  type="radio"
                  value="true"
                  component={fieldTemplate}
                />
              </div>
              <div className="list__item">
                <Field
                  cssModifier="radio-button--view-1"
                  name="all_companies_access"
                  label={this.LANGUAGE.SOME_CLIENTS_ACCESS_LEVEL}
                  type="radio"
                  value="false"
                  component={fieldTemplate}
                />
              </div>
            </div>
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onBack}>
              {this.LANGUAGE.NEW_USER_MODAL_BACK_BUTTON}
            </button>
            <button className="button button--bg-3 modal-window__footer-btn" type="submit">
              {this.LANGUAGE.NEW_USER_MODAL_NEXT_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

NewUserAgencyStep3Modal.propTypes = {
  user: PropTypes.object,
  show: PropTypes.bool,
  clientId: PropTypes.number,
  editingUser: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  handleSubmit: PropTypes.func,
  updateErrors: PropTypes.func,
  initialize: PropTypes.func,
  languageState: PropTypes.object,
  initialValues: PropTypes.object,
};

NewUserAgencyStep3Modal.defaultProps = {
  show: false,
  editingUser: false,
  clientId: 0,
  onBack: () => {},
  onClose: () => {},
  onNext: () => {},
};

const NewUserAgencyStep3Form = reduxForm({
  form: 'NewUserAgencyStep3Form',
  validate: NewUserAgencyStep3Modal.validate,
})(NewUserAgencyStep3Modal);

export default connect(
  ({languageState}) => ({
    languageState,
  }),
  {
    updateErrors: NewUserAgencyStep3Modal.updateSyncErrors,
  },
)(NewUserAgencyStep3Form);
