import React, {useCallback, useMemo, useState} from 'react';

import {reject} from 'lodash';
import {useDispatch} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {updateAnimationDetails} from 'client/ducks/animations/actions';

import AppButton from 'client/common/buttons/app-button';
import Offscreen from 'client/common/offscreen';
import TabSwitcher from 'client/common/tab-switcher';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container';
import AnimationAnimatorFieldset from 'client/components/animations/fieldsets/animation-animator-fieldset';
import AnimationEventFieldset from 'client/components/animations/fieldsets/animation-event-fieldset';
import AnimationProductsFieldset from 'client/components/animations/fieldsets/animation-products-fieldset';
import {ANIMATION_STATUSES} from 'client/components/instore/constants';
import {canEditAnimationDetails} from 'client/components/instore/helpers';

import mapping from './mapping';

import './animation-details-block.scss';

const TABS = {
  RECOMMENDED_ANIMATOR: 'recommended_animator',
  PRODUCT_ORDERS: 'product_orders',
  EVENT_INSTALLATION: 'event_installation',
};

const b = bem('animation-details-block');

const getDefaultTab = (kitMapping = {}) => {
  switch (true) {
    case kitMapping.details_reco:
      return TABS.RECOMMENDED_ANIMATOR;
    case kitMapping.details_products:
      return TABS.PRODUCT_ORDERS;
    case kitMapping.details_event:
      return TABS.EVENT_INSTALLATION;
    default:
      return null;
  }
};

const AnimationDetailsBlock = () => {
  const lang = useLanguage('ANIMATIONS.BLOCKS.ANIMATION_DETAILS_BLOCK');
  const {formValues, invalid} = useReduxForm(AnimationConfigContainerFormName);
  const [activeTab, setActiveTab] = useState(getDefaultTab(formValues?.kit_mapping || {}));

  const dispatch = useDispatch();

  const readOnly = !canEditAnimationDetails(formValues.status);
  const showSubmit = [ANIMATION_STATUSES.ISSUED, ANIMATION_STATUSES.VALIDATED, ANIMATION_STATUSES.RECEIVED].includes(
    formValues.status,
  );

  const tabs = useMemo(() => {
    const {details_reco, details_products, details_event} = formValues?.kit_mapping || {};
    return reject(
      [
        {
          title: lang.TABS.RECOMMENDED_ANIMATOR.TITLE,
          value: TABS.RECOMMENDED_ANIMATOR,
          className: b('tab-link'),
          disabled: !details_reco,
        },
        {
          title: lang.TABS.PRODUCT_ORDERS.TITLE,
          value: TABS.PRODUCT_ORDERS,
          className: b('tab-link'),
          disabled: !details_products,
        },
        {
          title: lang.TABS.EVENT_INSTALLATION.TITLE,
          value: TABS.EVENT_INSTALLATION,
          className: b('tab-link'),
          disabled: !details_event,
        },
      ],
      'disabled',
    );
  }, [formValues?.kit_mapping, lang]);

  const saveDetails = useCallback(() => {
    dispatch(updateAnimationDetails(formValues?.id, mapping(formValues)));
  }, [formValues, dispatch]);

  if (!tabs.length) {
    return null;
  }

  return (
    <div className={b()}>
      <div className={b('head')}>
        <h3 className={b('heading')}>{lang.TITLE}</h3>
        {showSubmit && (
          <AppButton
            label={lang.SUBMIT}
            color="light-clients"
            iconName="check"
            disabled={invalid}
            onClick={saveDetails}
          />
        )}
      </div>
      <TabSwitcher className={b('tab-switcher')} tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />

      <Offscreen hidden={activeTab !== TABS.RECOMMENDED_ANIMATOR}>
        <AnimationAnimatorFieldset readOnly={readOnly} />
      </Offscreen>
      <Offscreen hidden={activeTab !== TABS.PRODUCT_ORDERS}>
        <AnimationProductsFieldset readOnly={readOnly} values={formValues.products || []} />
      </Offscreen>
      <Offscreen hidden={activeTab !== TABS.EVENT_INSTALLATION}>
        <CustomScrollbars scrollbarProps={{autoHeightMax: 240}}>
          <AnimationEventFieldset readOnly={readOnly} />
        </CustomScrollbars>
      </Offscreen>
    </div>
  );
};

export default AnimationDetailsBlock;
