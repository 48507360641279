import React from 'react';

import arrayMove from 'array-move';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect, useDispatch} from 'react-redux';

import bem from 'client/services/bem';

import {
  updateGame,
  updatePrizeOrdersInStore,
  updatePrizeMap,
  updatePrizeMapsOrdersInStore,
  updatePrizeOrder,
} from 'client/ducks/games/actions';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';

import PrizesTableContainer from './prizes-table-container';

import './prizes-table.scss';

const b = bem('prizes-table');

const PrizesTable = (props) => {
  const {
    game,
    prizes = [],
    prizeMaps = [],
    lang,
    onAdd,
    onSelectPrize,
    isPrizeMap,
    disabledActions,
    disabledActivation,
    deletePrize,
    activatePrizeMap,
  } = props;

  const dispatch = useDispatch();

  const onSortEnd = ({oldIndex, newIndex}) => {
    const nextPrizeMaps = arrayMove(isPrizeMap ? prizeMaps : prizes, oldIndex, newIndex);
    const nextPrizes = arrayMove(isPrizeMap ? prizeMaps : prizes, oldIndex, newIndex).map((prize, index) => {
      if (isPrizeMap) {
        nextPrizeMaps[index] = {
          ...nextPrizeMaps[index],
          order: index,
        };
      }
      return {
        ...prize,
        id: isPrizeMap ? prize.prize_id : prize.id,
        order: index,
      };
    });

    dispatch(
      updatePrizeOrder(
        game.id,
        nextPrizes.reduce(
          (acc, prize) => ({
            ...acc,
            [prize.id]: prize.order,
          }),
          {},
        ),
      ),
    );
    if (isPrizeMap) {
      dispatch(updatePrizeMapsOrdersInStore(nextPrizeMaps));
    } else {
      dispatch(updatePrizeOrdersInStore(nextPrizes));
    }
  };

  return (
    <div className={b()}>
      <div className={b('header')}>
        <span className="title-h2">{lang.TITLE}</span>
        {!isPrizeMap && (
          <button
            type="button"
            className={cn(b('add'), 'button button--bg-4')}
            onClick={onAdd}
            disabled={disabledActions}
          >
            {lang.ADD_PRIZE}
            <Icon name="plus" />
          </button>
        )}
      </div>

      <CustomScrollbars style={{maxHeight: 240}} scrollbarProps={{autoHeightMax: 240}}>
        <PrizesTableContainer
          disabledActions={disabledActions}
          disabledActivation={disabledActivation}
          prizes={isPrizeMap ? prizeMaps : prizes}
          lang={lang}
          onActivate={activatePrizeMap}
          isPrizeMap={isPrizeMap}
          useDragHandle
          onSortEnd={onSortEnd}
          onSelectPrize={onSelectPrize}
          onDelete={deletePrize}
        />
      </CustomScrollbars>
    </div>
  );
};

PrizesTable.propsTypes = {
  game: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSelectPrize: PropTypes.func.isRequired,
  isPrizeMap: PropTypes.bool.isRequired,
  onSortEnd: PropTypes.func,
  fetchPrizes: PropTypes.func,
  fetchPrizeMaps: PropTypes.func,
  activatePrizeMap: PropTypes.func.isRequired,
  deletePrize: PropTypes.func.isRequired,
  prizes: PropTypes.array,
  prizeMaps: PropTypes.array,
  disabledActions: PropTypes.bool,
  disabledActivation: PropTypes.bool,

  //  from state:
  updatePrizeOrdersInStore: PropTypes.func.isRequired,
  updatePrizeMapsOrdersInStore: PropTypes.func.isRequired,
  updateGame: PropTypes.func.isRequired,
  updatePrizeMap: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
};

export default connect(
  ({languageState}) => ({
    lang: languageState.payload.GAMES.GAME_CONFIG_MODAL.PRIZES_TABLE,
  }),
  {
    updateGame,
    updatePrizeOrdersInStore,
    updatePrizeMapsOrdersInStore,
    updatePrizeMap,
  },
)(PrizesTable);
