import React from 'react';

import BaseInput, {BaseInputProps} from '../base-input';

type TextInputProps = Omit<BaseInputProps, 'type'>;

const TextInput: React.FC<TextInputProps> = (props) => {
  return <BaseInput {...props} type="text" />;
};

export default TextInput;
