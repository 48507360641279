import React, {Component} from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Field, reduxForm, FormSection, formValueSelector, getFormValues} from 'redux-form';

import {downloadFileWithAuth} from 'client/services/helpers';
import {required} from 'client/services/validator';

import {createLeadsExportFile, getLeadsExportFile} from 'client/ducks/leads-export/actions';

import {LEAD_IMPORT_EXPORT_FILE_TYPES, LEAD_EXPORT_TYPES, CLIENT_PAGES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import AllowedUsersField from 'client/components/common/custom-fields/allowed-users-field';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

import LeadsFilterModal from 'client/components/leads/components/modals/leads-filters-modal/leads-filters-modal';

class LeadsGenerateExportModal extends Component {
  static defaultProps = {
    show: false,
    columnsGroup: {},
    columns: [],
    users: [],
    formValues: {},
  };

  static propTypes = {
    show: PropTypes.bool,
    isAll: PropTypes.bool,
    filters: PropTypes.object,
    lang: PropTypes.object.isRequired,
    columnsGroup: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    change: PropTypes.func.isRequired,
    createLeadsExportFile: PropTypes.func.isRequired,
    getLeadsExportFile: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    clientId: PropTypes.string.isRequired,
    generalColumns: PropTypes.array.isRequired,
    columns: PropTypes.array,
    checkedLeads: PropTypes.array.isRequired,
    users: PropTypes.array,
    formValues: PropTypes.object,
    ...withRouter.propTypes,
    queryParams: PropTypes.object,
  };

  static formName = 'LeadsGenerateExportModalForm';

  static checkboxAllColumns = 'checkboxAllColumns';

  static columnsGroup = 'columnsGroup';

  constructor(props) {
    super(props);

    this.rules = {
      name: [required(this.props.lang.REQUIRED)],
    };

    this.state = {
      showWarning: true,
      isColumnsFieldsTouched: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {formValues, show, columnsGroup} = this.props;

    if (show && !prevProps.show) {
      const initValues = {
        columnsGroup: {},
        format: LEAD_IMPORT_EXPORT_FILE_TYPES.XLS,
        users: [],
        allowed_for_all: 'true',
      };
      this.props.initialize(initValues);
      this.setState({
        showWarning: true,
        isColumnsFieldsTouched: false,
      });
    }

    if (columnsGroup !== prevProps.columnsGroup) {
      this.validateFormSection(this.props);
    }

    if (formValues.allowed_for_all === 'true' && !isEmpty(formValues.users)) {
      this.props.change('users', []);
    }
  }

  validateFormSection = (props) => {
    const columnsGroup = props.columnsGroup ? Object.keys(props.columnsGroup) : [];
    const validate = columnsGroup.some((el) => props.columnsGroup[el] === true);
    this.setState({showWarning: !validate});
  };

  toggleAllColumns = (e, colGroup) => {
    this.setState({isColumnsFieldsTouched: true});

    const result = this.props.columns.reduce(
      (group, item) => ({
        ...group,
        [`_${item.id}`]: e.currentTarget.checked,
      }),
      {},
    );

    this.props.change(colGroup, result);
  };

  uncheckAllColumns = (checkBox) => {
    this.props.change(checkBox, false);
    this.setState({isColumnsFieldsTouched: true});
  };

  onSave = (values) => {
    const {isAll, checkedLeads} = this.props;

    if (this.state.showWarning) {
      this.setState({isColumnsFieldsTouched: true});
      return;
    }
    if (!checkedLeads.length && !isAll) {
      return;
    }
    const columnsGroup = Object.entries(values.columnsGroup);
    let transferItems = [];
    columnsGroup.forEach((el) => {
      if (el[1]) {
        transferItems.push({
          type: LEAD_EXPORT_TYPES.LEAD_EXPORT_ITEM,
          column_adapter_id: Number(el[0].slice(1)),
        });
      }
    });
    const importData = {
      file_lead_export: {
        name: values.name,
        comment: values.comment || '',
        client_id: this.props.clientId,
        format: values.format,
        lead_transfer_items: transferItems,
        allowed_for_all: values.allowed_for_all === 'true',
        transferable_memberships: values.users?.map(({value}) => ({
          membership_id: value,
        })),
        parameters_for_export: this.props.isAll ? this.props.queryParams : {},
      },
    };

    if (!this.props.isAll) {
      importData.file_lead_export.transferable_leads = checkedLeads.map((id) => ({
        lead_id: id,
      }));
    }

    this.props.createLeadsExportFile(importData).then((res) => {
      this.props.getLeadsExportFile(res.payload.file_lead_export.id).then((response) => {
        const {url} = response.payload.lead_export_report.leads_file;
        downloadFileWithAuth(url);

        setTimeout(() => {
          this.props.history.push(`${CLIENT_PAGES.LEADS_EXPORT}/${response.payload.lead_export_report.id}`);
        }, 1000);
      });
    });
  };

  render() {
    const {columns, handleSubmit, onCancel, onClose, show, checkedLeads, isAll, users, formValues, lang} = this.props;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-2 leads-port-layout"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TITLE}
      >
        <form onSubmit={handleSubmit(this.onSave)}>
          <div className="theme-color-2">
            <FormFieldWrap>
              <Field
                type="text"
                name="name"
                label={lang.FILE_NAME_LABEL + '*'}
                component={fieldTemplate}
                validate={this.rules.name}
              />
            </FormFieldWrap>
            <FormFieldWrap>
              <div className="main-text main-text--small main-text--color-1">{lang.COLUMNS_LABEL + '*'}</div>
              <div className="content-group">
                <CustomScrollbars
                  scrollbarProps={{
                    autoHeightMin: 200,
                    autoHeightMax: 360,
                  }}
                >
                  <div className="leads-port-layout__group">
                    <div className="leads-port-layout__group-header">
                      <Field
                        label={lang.COLUMNS_COLUMN_TITLE}
                        name={LeadsGenerateExportModal.checkboxAllColumns}
                        type="checkbox"
                        onChange={(e) => this.toggleAllColumns(e, LeadsGenerateExportModal.columnsGroup)}
                        component={fieldTemplate}
                      />
                    </div>
                    <div className="leads-port-layout__group-body">
                      <FormSection name={LeadsGenerateExportModal.columnsGroup}>
                        {columns.map((col, index) => {
                          return (
                            <Field
                              key={index}
                              label={col.name}
                              cssModifier="leads-port-layout__checkbox"
                              name={`_${col.id}`}
                              type="checkbox"
                              onChange={() => {
                                this.uncheckAllColumns(LeadsGenerateExportModal.checkboxAllColumns);
                              }}
                              component={fieldTemplate}
                            />
                          );
                        })}
                      </FormSection>
                    </div>
                  </div>
                </CustomScrollbars>
              </div>
              {this.state.showWarning && this.state.isColumnsFieldsTouched && (
                <div className="message-error-text">{lang.NO_MATCH_ERROR_MESSAGE}</div>
              )}
              {!checkedLeads.length && !isAll && <div className="message-error-text">{lang.NO_LEADS_SELECTED}</div>}
            </FormFieldWrap>
            <FormFieldWrap>
              <div className="leads-port-layout__row-title">{lang.FORMAT_LABEL}</div>
              <div className="flex-container">
                <div className="leads-port-layout__col-1">
                  <Field
                    label={<span className="main-text">{lang.XLS}</span>}
                    name="format"
                    value={LEAD_IMPORT_EXPORT_FILE_TYPES.XLS}
                    type="radio"
                    component={fieldTemplate}
                  />
                </div>
                <div className="leads-port-layout__col-1">
                  <Field
                    label={<span className="main-text">{lang.CSV}</span>}
                    name="format"
                    value={LEAD_IMPORT_EXPORT_FILE_TYPES.CSV}
                    type="radio"
                    component={fieldTemplate}
                  />
                </div>
              </div>
            </FormFieldWrap>
            <FormFieldWrap>
              <div className="leads-port-layout__row-title">{lang.ALLOWED_USERS_LABEL}</div>
              <div className="flex-container margin-bottom--5">
                <div className="leads-port-layout__col-1">
                  <Field
                    label={lang.ALLOW_ALL_LABEL}
                    name="allowed_for_all"
                    value="true"
                    type="radio"
                    component={fieldTemplate}
                  />
                </div>
                <div className="leads-port-layout__col-1">
                  <Field
                    label={lang.ALLOW_SELECTED_LABEL}
                    name="allowed_for_all"
                    value="false"
                    type="radio"
                    component={fieldTemplate}
                  />
                </div>
              </div>
              <AllowedUsersField
                label={lang.USER_ALLOWED_LABEL}
                users={users}
                disabled={formValues.allowed_for_all === 'true'}
              />
            </FormFieldWrap>
            <FormFieldWrap>
              <Field
                cssModifier="leads-port-layout__textarea"
                name="comment"
                type="textarea"
                label={lang.COMMENT_LABEL}
                component={fieldTemplate}
              />
            </FormFieldWrap>
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button
              className="button button--bg-11 modal-window__footer-btn"
              type="button"
              onClick={onCancel || onClose}
            >
              {lang.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-2 modal-window__footer-btn" type="submit">
              {lang.GENERATE_FILE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const LeadsGenerateExportModalForm = reduxForm({
  form: LeadsGenerateExportModal.formName,
})(LeadsGenerateExportModal);

export default connect(
  (state) => {
    const selector = formValueSelector(LeadsGenerateExportModal.formName);
    const filtersSelector = getFormValues(LeadsFilterModal.formName);

    return {
      lang: state.languageState.payload.LEADS_PORT.GENERATE_EXPORT_FILE_MODAL,
      columnsGroup: selector(state, LeadsGenerateExportModal.columnsGroup),
      filters: filtersSelector(state),
      formValues: getFormValues(LeadsGenerateExportModal.formName)(state),
    };
  },
  {createLeadsExportFile, getLeadsExportFile},
)(withRouter(LeadsGenerateExportModalForm));
