import React from 'react';

import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';
import InfoBlock from 'client/components/common/info-block';

import {TranslationJsx} from 'client/models/language/types';

import './params-card.scss';

function ParamsCard({className, children, title, onAddClick, buttonText}) {
  return (
    <InfoBlock className={`params-card ${className}`}>
      <InfoBlock.Title>
        <div className="theme-color-7">{title}</div>
      </InfoBlock.Title>
      <InfoBlock.Content className="params-card__content">
        <InfoBlock.Body>
          {children}
          <div className="params-card__bottom">
            <button className="button button--bg-5" onClick={onAddClick}>
              <Icon className="button__icon" name="plus" />
              <span>{buttonText}</span>
            </button>
          </div>
        </InfoBlock.Body>
      </InfoBlock.Content>
    </InfoBlock>
  );
}

ParamsCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.any,
  buttonText: TranslationJsx,
  onAddClick: PropTypes.func,
};

ParamsCard.defaultProps = {
  className: '',
  children: null,
  title: '',
  buttonText: '',
  onAddClick: () => {},
};

export default ParamsCard;
