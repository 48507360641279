import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import {calculateAge} from 'client/services/helpers';

import {selectOptIns} from 'client/ducks/opt-in-columns/selectors';

import {
  GENDER_TYPES,
  LEAD_SOURCE_TYPES,
  CLIENT_PAGES,
  LEAD_TRANSFER_TYPES,
  CLIENT_TYPES,
  COLUMN_ADAPTER_TYPES,
  COLUMN_TYPES,
} from 'client/common/config';

import {mapColumnsNames} from 'client/components/leads/leads-list/helper';
import {TASK_TYPES} from 'client/models/operations/constants';

// import {selectColumnVisibilityItemsMapped} from 'client/ducks/lead-display-items/selectors';

export function selectLeads(state) {
  return state.leadsList.payload.data;
}

export function selectLeadsMeta(state) {
  return state.leadsList.payload.meta;
}

export function selectLeadsTotalCount(state) {
  return selectLeadsMeta(state).total_count;
}

export function selectClientName(state) {
  return state.clientsList.client.name;
}

export function selectClient(state) {
  return state.clientsList.client;
}

export function selectOptinsCodes(state) {
  const codes = {};
  selectOptIns(state).forEach((optIn) => {
    codes[optIn.id] = optIn.code;
  });

  return codes;
}

export function selectUnselectableRow(lead, taskType) {
  switch (true) {
    case taskType === TASK_TYPES.EMAIL && !lead.email:
      return lead.id;
    case taskType === TASK_TYPES.SMS && !lead.phone:
      return lead.id;
    default:
      return 0;
  }
}

export function selectDefaultSelectedRow(recipients = [], broadcastId) {
  const broadcastRecipient =
    recipients.length && recipients.filter((recipient) => String(recipient.broadcast_list_id) === broadcastId)[0];

  return broadcastRecipient && broadcastRecipient.id ? broadcastRecipient.id : 0;
}

export function selectLeadsClient(client) {
  return {
    clientName: client.name,
    clientType: client.type,
    clientId: client.id,
  };
}

export function selectLastParticipation(participation) {
  return {
    operation_id: get(participation, 'operation.id', 0),
    operation_name: get(participation, 'operation.name', ''),
    client_id: get(participation, 'operation.client_id', 0),
    client_type: get(participation, 'operation.client.type', ''),
    created_at: has(participation, 'created_at') ? moment(participation.created_at).format('DD/MM/YYYY') : '',
  };
}

export function selectParticipations(participations = []) {
  return participations.map((p) => {
    return {
      created_at: p.created_at ? moment(p.created_at).format('HH:mm DD/MM/YYYY') : '',
      updated_at: p.updated_at ? moment(p.updated_at).format('HH:mm DD/MM/YYYY') : '',
      operation_name: get(p, 'operation.name', ''),
      operation_code: get(p, 'operation.code', ''),
      operation_id: get(p, 'operation.id', 0),
      client_id: get(p, 'operation.client_id', 0),
      client_type: get(p, 'operation.client.type', ''),
      interface_name: get(p, 'interface.name', ''),
    };
  });
}

export function selectExportParams(data, state) {
  const obj = {date: '', time: '', url: '', clientName: '', name: ''};
  const report = state.leadsImport.importReport;
  if (!data) {
    return obj;
  }

  obj.date = moment(data.created_at).format('DD/MM/YYYY');
  obj.time = moment(data.created_at).format('HH:mm');
  obj.url = `${CLIENT_PAGES.LEADS_EXPORT}/${data.id}`;
  obj.clientName = get(data, 'client.name');

  switch (data.type) {
    case LEAD_TRANSFER_TYPES.API_EXPORT:
      obj.name = get(report.user, 'full_name');
      break;
    case LEAD_TRANSFER_TYPES.EXPORT:
      obj.name = `${data.name}.${data.format}`;
      break;
    default:
      break;
  }

  return obj;
}

export function selectSourceParams(data) {
  const obj = {date: '', time: '', url: '', clientName: '', name: ''};

  if (!data) {
    return obj;
  }

  obj.date = moment(data.created_at).format('DD/MM/YYYY');
  obj.time = moment(data.created_at).format('HH:mm');
  obj.id = data.id;
  obj.name = data.name;
  switch (data.type) {
    case LEAD_SOURCE_TYPES.API_LEAD_IMPORT:
      obj.name = data.name;
      obj.url = '#';
      obj.clientName = get(data, 'client.name');
      break;
    case LEAD_SOURCE_TYPES.PARTICIPATION:
      obj.name = get(data, 'interaction.interface.automation_task.operation.name', '');
      obj.url = '#';
      obj.clientName = get(data, 'interaction.interface.name', '');
      const operation = get(data, 'interaction.interface.automation_task.operation', {});
      const clientType = get(data, 'interaction.interface.automation_task.operation.client.type', '');
      if (!isEmpty(operation)) {
        const type = clientType === CLIENT_TYPES.COMPANY ? CLIENT_PAGES.COMPANIES : CLIENT_PAGES.AGENCIES;
        obj.url = `${type}/${operation.client_id}${CLIENT_PAGES.OPERATIONS}/${operation.id}`;
      }
      break;
    case LEAD_SOURCE_TYPES.FILE_LEAD_IMPORT:
      // TODO: will be implemented later, after leads import
      obj.name = `${data.name}.${data.format}`;
      obj.url = '#';
      obj.clientName = get(data, 'client.name');
      break;
    default:
      break;
  }

  return obj;
}

export function selectLeadsTable(state) {
  const leadsList = selectLeads(state);
  return leadsList.map(
    ({
      id,
      first_name,
      last_name,
      gender,
      birth_date,
      lead_histories = {},
      address1,
      address2,
      zip,
      city,
      country,
      phone = '',
      latest_participated_at,
      last_participation,
      first_source,
      opt_ins,
      opt_in_columns,
      input_data = {},
      email = '',
      comment,
      client,
      participations_count = 0,
      visuals_count,
      created_at,
      expires_at,
    }) => {
      return {
        id,
        first_name,
        last_name,
        gender,
        birth_date,
        lead_histories,
        address1,
        address2,
        zip,
        city,
        country,
        phone,
        comment,
        client,
        email,
        last_participation: latest_participated_at,
        operation_name: last_participation?.operation?.name,
        first_source: get(first_source, 'name', ''),
        opt_ins:
          opt_in_columns?.map((el) => ({
            ...el,
            isHidden: opt_ins[el.id] === false,
          })) || [],
        input_data,
        participations_count,
        visuals_count,
        created: created_at,
        created_at: created_at,
        expires_at: expires_at,
      };
    },
  );
}

export function selectLeadsMailingTableMapped(state, broadcastId, taskType = '') {
  const leadsList = selectLeads(state);
  const LANG_GENDERS = state.languageState.payload.GENDERS;
  const defaultSelectedRows = [];
  const unselectableRows = [];

  const codes = selectOptinsCodes(state);

  const tableData = leadsList.map((lead) => {
    const {
      id,
      first_name,
      last_name,
      gender,
      birth_date,
      visuals_count,
      email,
      phone,
      opt_ins = {},
      opt_in_columns = [],
      created_at,
      last_export,
      first_source,
      client,
      broadcast_recipients,
      participations_count,
      participations = [],
      last_participation,
      data,
      expires_at,
      address1,
      address2,
      zip,
      city,
      country,
    } = lead;

    // get optins codes
    const optinCodes = Object.keys(opt_ins).map((key) => codes[key]);
    const leadsListOptInCodes = opt_in_columns.filter((i) => opt_ins[i.id]).map((i) => i.name);

    // unselectable row
    const rowId = selectUnselectableRow(lead, taskType);

    if (rowId) {
      unselectableRows.push(rowId);
    }

    // row selected
    const recipient_id = selectDefaultSelectedRow(broadcast_recipients, broadcastId);

    if (recipient_id) {
      defaultSelectedRows.push(lead.id);
    }

    // last participation column data
    const latest_participated_at = selectLastParticipation(last_participation);

    // participations count column data
    const participationsCount = {
      participations: selectParticipations(participations),
      count: participations_count,
      lead_id: id,
    };

    // const convertedParticipations = participations.reduce((acc, item) => {
    //   acc[item.lead_id] = {
    //     created_at: item.created_at,
    //     updated_at: item.updated_at,
    //     interface: item.interaction.interface
    //   };
    //   return acc;
    // }, {});

    const customData = Object.entries(data).reduce((result, [key, value]) => {
      const customInfo = Array.isArray(value) ? value[0] : value;
      const content = customInfo && customInfo.file_identifier ? customInfo.file_identifier : customInfo || null;
      return {
        ...result,
        [key]: content && String(content),
      };
    }, {});

    const convertedParticipations = participations.filter((item) => item.lead_id === id);
    return {
      id,
      first_name,
      last_name,
      visuals_count,
      hasEmail: !!email,
      hasPhone: !!phone,
      opt_ins: optinCodes,
      address1,
      address2,
      zip,
      city,
      country,
      leads_opt_ins: leadsListOptInCodes,
      gender: gender ? LANG_GENDERS[GENDER_TYPES[gender]] || gender : LANG_GENDERS.NOT_SPECIFIED,
      created_at: moment(created_at).format('DD/MM/YYYY'),
      expires_at: expires_at ? moment(expires_at).format('DD/MM/YYYY') : null,
      birth_date: calculateAge(birth_date),
      last_export: selectExportParams(last_export, state),
      first_source: selectSourceParams(first_source),
      client_name: client ? selectLeadsClient(client) : {},
      recipient_id,
      broadcast_recipients,
      latest_participated_at,
      participations: convertedParticipations,
      participations_count: participationsCount,
      ...customData,
    };
  });

  return {
    tableData,
    unselectableRows,
    defaultSelectedRows,
  };
}

export function selectColumnAdaptersForImportExport(state) {
  const internalColumnAdapter = COLUMN_ADAPTER_TYPES.INTERNAL;
  const externalColumnAdapter = COLUMN_ADAPTER_TYPES.EXTERNAL;
  const optInColumnAdapter = COLUMN_ADAPTER_TYPES.OPT_IN;
  const fileType = COLUMN_TYPES.FILE;
  const language = get(state, 'languageState.payload.LEADS_LIST', {});
  const leadAllColumns = get(state, 'columnAdapters.availableColumnAdapters.Lead', []);
  const intExtCols = leadAllColumns.filter((col) => {
    return (
      [internalColumnAdapter, optInColumnAdapter].includes(col.type) ||
      (col.type === externalColumnAdapter && col.kind !== fileType && col.array === false) ||
      col.name === 'expires_at'
    );
  });
  return mapColumnsNames(intExtCols, language);
}

export function selectLeadsStatistic(state) {
  return state.leadsList.leadsStatistic;
}

export function selectOnlineUsersStatistic(state) {
  return state.leadsList.onlineUsersStatistic;
}
