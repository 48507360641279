import React from 'react';

import moment from 'moment';

import {useLanguage} from 'client/services/hooks';

import {AFFECTATION_STEPS, DeviceAffectation} from 'client/models/device-affectations';

import Road from '../road';

type DeliveryRoadProps = {
  affectation: DeviceAffectation;
};

const DeliveryRoad: React.FC<DeliveryRoadProps> = ({affectation}) => {
  const lang = useLanguage('CLIENT_DEVICES.LOGISTIC');

  return (
    <Road
      startIcon="weezio-transparent-color"
      endIcon="weezio-home-transparent"
      disabledEndIcon="weezio-home"
      disabledStartIcon="weezio-transparent-monochrome"
      currentStep={affectation.delivery_step}
      currentStatus={affectation.delivery_status}
      steps={[
        {
          title: lang.PREPARATION_STATUS,
          value: AFFECTATION_STEPS.PREPARATION,
        },
        {
          title: lang.SENT_STATUS,
          value: AFFECTATION_STEPS.SENT,
          subStep: {
            title:
              affectation?.delivery_expected &&
              `${lang.EXPECTED_DELIVERY_BEFORE} ${moment(affectation.delivery_expected).format('DD/MM/YY')}`,
          },
        },
        {
          title: lang.RECEIVED_STATUS,
          value: AFFECTATION_STEPS.RECEIVED,
          subStep: {
            align: 'right',
            title:
              affectation?.installation_schedule &&
              `${lang.MEETING_SCHEDULED} ${moment(affectation.installation_schedule).format('DD/MM/YY')}`,
          },
        },
        {
          title: lang.VERIFIED_STATUS,
          value: AFFECTATION_STEPS.VERIFIED,
        },
      ]}
    />
  );
};

export default DeliveryRoad;
