import React, {useCallback, useEffect, useRef} from 'react';

import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import Notifications, {
  info as infoNotification,
  removeAll as removeNotifications,
} from 'react-notification-system-redux';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Switch, useHistory, useLocation, matchPath} from 'react-router-dom';
import {useMount, useToggle, useInterval} from 'react-use';
import {Userpilot} from 'userpilot';

import {refreshBearerToken} from 'client/services/bearer';
import {
  getToken,
  getUserId,
  setOrganizationId,
  setAccessLevel,
  setOrganizationName,
  getOrganizationId,
  getAccessLevel,
  clearCookies,
  clearAccessLevel,
  clearOrganizationId,
  clearOrganizationName,
  getIsTrackingUser,
} from 'client/services/cookie-data-source';
import {useLanguage, useAppMedia} from 'client/services/hooks';
import useLocaleManagement from 'client/services/hooks/useLocaleManagement';
import useProductFruits from 'client/services/product-fruits/useProductFruits';
import * as viewModeService from 'client/services/userViewModeService';

import {getAdminSubsidiary} from 'client/ducks/admin-users/actions';
import {clearAutotaskState} from 'client/ducks/autotask/actions';
import {getClient} from 'client/ducks/clients-list/actions';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {selectCurrentLanguage} from 'client/ducks/language/selectors';
import {getUserAllClients, setCurrentOrganization, setUserAllClients} from 'client/ducks/user-clients/actions';
import {selectAllUserClients, selectUserMemberships} from 'client/ducks/user-clients/selectors';
import {getUserById, logout, setViewModeUserId, setTrackingUser} from 'client/ducks/user/actions';
import {selectIsAdmin, selectUser} from 'client/ducks/user/selectors';

import {
  PRODUCT_FRUITS_WORKSPACE_CODE,
  REFRESH_TOKEN_INTERVAL,
  REFRESH_TOKEN_INITIAL_TIMEOUT,
} from 'client/common/config';
import {
  CLIENT_PAGES,
  APP_ROLES,
  CLIENT_TYPES,
  LOCAL_STORAGE,
  HUBSPOT_SCRIPT_SRC,
  USER_TYPES,
} from 'client/common/config';
import MetaComponent from 'client/common/meta/meta.jsx';

import ErrorModal from 'client/components/error-modal';
import PrivacyPolicyNotification from 'client/components/privacy-policy-accept/components/privacy-policy-notification';
import HubspotProductFruit from 'client/components/various/hubspot-product-fruit';
import MainLayout from 'client/layout';
import {WsProvider} from 'client/providers/ws';
import {AuthProvider} from 'client/providers/auth';

import {USER_LOADED} from './ducks/user/types';
import PageNotFound from './pages/page-not-found';

import {matchRoutes} from '../routes';

const MainContainer = ({route}) => {
  const isCookieNotificatedRef = useRef(false);

  const [isUserReady, toggleIsUserReady] = useToggle(false); // prevent redirect to login when user data has not loaded yet
  const [loadingUser, toggleLoadingUser] = useToggle(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const languageCookie = useLanguage('COOKIES_POLICY');

  const {isTablet} = useAppMedia();

  const isTrackingUser = getIsTrackingUser();

  const user = useSelector(selectUser);
  const userState = useSelector((state) => state.userState);
  const isAdminUser = useSelector(selectIsAdmin);
  const clientList = useSelector(selectAllUserClients);
  const userMemberships = useSelector(selectUserMemberships);
  const interceptor = useSelector((state) => state.interceptor);
  const notifications = useSelector((state) => state.notifications);
  const selectedClient = useSelector(selectCurrentClient);
  const currentLanguage = useSelector(selectCurrentLanguage);
  const loadingClientIdRef = useRef(null);

  const branch = matchRoutes(route.routes, location.pathname)[0];

  const match = matchPath(branch.match.url, {path: branch.match.path});
  const clientId = match.params.clientId;

  const {isLocaleReady} = useLocaleManagement({isUserReady});

  const getDefaultClient = useCallback((clients) => {
    switch (true) {
      case clients.length === 1:
        return clients[0];
      case clients.length > 1:
        const clientAgency = clients.find((client) => client.type === CLIENT_TYPES.AGENCY);
        return clientAgency || clients[0];
      default:
        return {};
    }
  }, []);

  const fetchClientInfo = useCallback(
    (currentClientID) => {
      const queryParams = {
        include: ['subsidiary', 'agency', 'poc_membership', 'poc_agency_membership', 'category'],
        [`include_membership_client_user_full_name_with_title_${currentLanguage}`]: null,
        include_client_weezio_client_templates_count: null,
        include_client_user_client_templates_count: null,
        include_client_shared_client_templates_count: null,
        include_client_device_affectations_count: null,
        include_client_leads_count: null,
        include_client_logo_url: null,
        include_client_agency_logo_url: null,
      };

      return dispatch(getClient(currentClientID, queryParams));
    },
    [currentLanguage, dispatch],
  );

  const handleClientChange = useCallback(
    async (client, access) => {
      if (loadingClientIdRef.current && client.id === loadingClientIdRef.current) {
        return;
      }

      loadingClientIdRef.current = client.id;

      if (client) {
        setOrganizationId(client.id);
        setOrganizationName(client.name);
        setAccessLevel(access);

        dispatch(setCurrentOrganization(client));

        await fetchClientInfo(client.id);

        loadingClientIdRef.current = null;
      }
      return null;
    },
    [dispatch, fetchClientInfo],
  );

  // Changes client if clientId param in URL is not equal selectedClient.id
  useEffect(() => {
    if (selectedClient.id && clientId && selectedClient.id.toString() !== clientId) {
      const newClient = clientList.find((i) => i.id.toString() === clientId);
      if (newClient) {
        handleClientChange(newClient, userMemberships[newClient.id]);
      }
    }
  }, [selectedClient.id, clientId, handleClientChange, clientList, userMemberships]);

  const getUserClients = useCallback(() => {
    const params = {
      include: {
        memberships: {
          client: 'companies',
          company_accesses: 'company',
          data_tab_access: null,
        },
      },
    };

    return dispatch(getUserAllClients(user.id, params));
  }, [dispatch, user?.id]);

  const setDefaultConfiguration = useCallback(() => {
    if (!isAdminUser) {
      getUserClients();
    } else if (!viewModeService.getViewModeStorageParam().on) {
      dispatch(getAdminSubsidiary(getUserId()));
    }
  }, [dispatch, isAdminUser, getUserClients]);

  const setDefaultClient = useCallback(() => {
    if (isAdminUser || isEmpty(clientList) || !user?.id) {
      return;
    }

    let currentClient;
    let userAccess;

    if (!getOrganizationId() && !clientId) {
      // when current client was not set yet
      currentClient = getDefaultClient(clientList);
      userAccess = userMemberships[currentClient.id];
    } else if (clientId && getOrganizationId !== clientId) {
      // when client id is set via url bar
      currentClient =
        clientList.find(({id}) => {
          return String(id) === clientId;
        }) || {};
      userAccess = userMemberships[currentClient.id];
    } else {
      currentClient = clientList.find(({id}) => {
        return String(id) === getOrganizationId();
      });
      userAccess = getAccessLevel();
    }

    handleClientChange(currentClient, userAccess);
  }, [clientList, getDefaultClient, handleClientChange, isAdminUser, clientId, user?.id, userMemberships]);

  const cookiesPolicyAccept = useCallback(() => {
    Cookies.set(LOCAL_STORAGE.COOKIES_POLICY(user.id), String(true));
    Cookies.set(LOCAL_STORAGE.COOKIES_POLICY_DATE(user.id), new Date().toString());
  }, [user?.id]);

  const cancelCookiesPolicyAccept = useCallback(() => {
    Cookies.set(LOCAL_STORAGE.COOKIES_POLICY(user.id), String(false));
  }, [user?.id]);

  const cookiesPolicyRefuse = () => {
    clearCookies();
    window.location.replace('/');
  };

  const cookiesPolicyNotification = useCallback(() => {
    const notificationOpts = {
      title: languageCookie.NOTIFICATION_TITLE,
      message: languageCookie.NOTIFICATION_TEXT,
      position: 'tc',
      autoDismiss: 0,
      dismissible: 'button',
      children: (
        <PrivacyPolicyNotification
          onCookiesPolicyAccept={cookiesPolicyAccept}
          onCookiesPolicyRefuse={cookiesPolicyRefuse}
        />
      ),
    };
    const token = getToken();
    const isLoggedUser = token && userState.type === USER_LOADED;
    const privacy_policy_accepted = user.privacy_policy_accepted;
    let isCookiePolicyAccepted = false;
    if (isLoggedUser) {
      const userId = user.id;
      isCookiePolicyAccepted = Cookies.get(LOCAL_STORAGE.COOKIES_POLICY(userId)) === 'true';
    }

    if (isCookiePolicyAccepted) {
      const cookiesPolicyAcceptDate = new Date(Cookies.get(LOCAL_STORAGE.COOKIES_POLICY_DATE(user.id)));

      const diffDate = new Date().valueOf() - cookiesPolicyAcceptDate.valueOf();

      if (diffDate > 34164000000) {
        cancelCookiesPolicyAccept();
      }
    }

    if (isLoggedUser && !isCookieNotificatedRef.current && privacy_policy_accepted && !isCookiePolicyAccepted) {
      isCookieNotificatedRef.current = true;
      dispatch(infoNotification(notificationOpts));
    }
  }, [
    cancelCookiesPolicyAccept,
    cookiesPolicyAccept,
    dispatch,
    languageCookie.NOTIFICATION_TEXT,
    languageCookie.NOTIFICATION_TITLE,
    user.id,
    user.privacy_policy_accepted,
    userState.type,
  ]);

  const checkViewModeActivation = useCallback(() => {
    const userId = viewModeService.getHashViewModeUserId();
    if (userId) {
      dispatch(getUserById(userId)).then(() => {
        dispatch(setViewModeUserId(userId));
        toggleLoadingUser();
        toggleIsUserReady();
      });
    }
  }, [toggleLoadingUser, toggleIsUserReady, dispatch]);

  const checkClientChange = useCallback(() => {
    const {error, response} = interceptor;

    if (error && response && response.clients) {
      if (isEmpty(response.clients)) {
        dispatch(logout()).then(() => {
          history.push(CLIENT_PAGES.LOGIN);
        });
      } else if (response.clients.length === 1) {
        clearAccessLevel();
        clearOrganizationId();
        clearOrganizationName();
        dispatch(clearAutotaskState());
        window.location.replace('/');
      } else {
        clearAccessLevel();
        clearOrganizationId();
        clearOrganizationName();
        dispatch(clearAutotaskState());
        dispatch(setCurrentOrganization());
        dispatch(setUserAllClients(response.clients));
        history.push(CLIENT_PAGES.WAS_DELETED);
      }
    }
  }, [dispatch, history, interceptor]);

  const handleHubSpotScript = useCallback(() => {
    if (HUBSPOT_SCRIPT_SRC && user.id && user.type === USER_TYPES.CLIENT_USER) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      script.src = HUBSPOT_SCRIPT_SRC;
      document.head.appendChild(script);
    }
  }, [user?.id, user?.type]);

  // Effects
  useMount(() => {
    const {viewMode} = userState;
    if (isTrackingUser) {
      dispatch(setTrackingUser());
      toggleIsUserReady();
      toggleLoadingUser();
    } else if (getToken()) {
      dispatch(getUserById(getUserId()))
        .then(() => {
          if (!viewMode.on || getUserId() === viewMode.id) {
            toggleIsUserReady();
          }
        })
        .then(() => {
          if (!viewMode.on || getUserId() === viewMode.id) {
            toggleLoadingUser();
          }
        });
    } else {
      toggleIsUserReady();
      toggleLoadingUser();
    }
  });

  useProductFruits();

  useEffect(() => {
    Userpilot.reload();
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (user?.id && !isTrackingUser) {
      setDefaultConfiguration();
    }
  }, [setDefaultConfiguration, user.id, isTrackingUser]);

  useEffect(() => {
    setDefaultClient();
  }, [setDefaultClient]);

  useEffect(() => {
    dispatch(removeNotifications());
    isCookieNotificatedRef.current = false;
    cookiesPolicyNotification();
  }, [dispatch, location.key, cookiesPolicyNotification]);

  useEffect(() => {
    // when logged user is Admin check if #view_user_id in query
    if (isAdminUser) {
      checkViewModeActivation();
    }

    const isViewModeOn = userState.viewMode.on;
    const userId = viewModeService.getHashViewModeUserId();
    // if viewMode is set but no #view_user_id in query
    if (isViewModeOn && !userId) {
      viewModeService.setHashViewModeQueryParam(userState.viewMode.id);
    }
  }, [checkViewModeActivation, isAdminUser, userState.viewMode.id, userState.viewMode.on]);

  useEffect(() => checkClientChange(), [checkClientChange]);

  useEffect(() => {
    handleHubSpotScript(user);
  }, [handleHubSpotScript, user]);

  useMount(() => isTrackingUser && setTimeout(refreshBearerToken, REFRESH_TOKEN_INITIAL_TIMEOUT));
  useInterval(() => refreshBearerToken(), isTrackingUser ? REFRESH_TOKEN_INTERVAL : null);

  const currentRoute = matchRoutes(route.routes, location.pathname)[0];
  const params = currentRoute.route.params;
  const token = getToken();
  const userId = getUserId();
  const nobreadcrumbs = 'nobreadcrumbs' in params;

  const privacy_policy_accepted = user.privacy_policy_accepted;

  const isUserLogged = token && userState.type === USER_LOADED;

  const isProfilePage = currentRoute.route.path === CLIENT_PAGES.PROFILE;
  const isUserNotAdmin = userState.payload.role !== APP_ROLES.ADMIN && userState.payload.role !== APP_ROLES.SUPER_ADMIN;
  const isViewModeActive = Boolean(userState.viewMode.on);
  const isLogoAlwaysShown = [CLIENT_PAGES.UNSUBSCRIBE, CLIENT_PAGES.UPDATE_PREFERENCES].includes(location.pathname);
  const everythingIsLoaded = isLocaleReady && !loadingUser && isUserLogged;

  return (
    <MetaComponent>
      <section>
        <AuthProvider route={route} isUserReady={isUserReady} isViewModeActive={isViewModeActive}>
          {PRODUCT_FRUITS_WORKSPACE_CODE && user.id && user.type === USER_TYPES.CLIENT_USER && <HubspotProductFruit />}
          <ErrorModal />
          {!isViewModeActive && (
            <div>
              <Notifications notifications={notifications} style={PrivacyPolicyNotification.notificationStyle} />
            </div>
          )}

          <MainLayout
            isAdmin={isAdminUser}
            isViewModeActive={isViewModeActive}
            isUserLogged={isUserLogged}
            isLoading={loadingUser || !isLocaleReady}
            isPolicyAccepted={privacy_policy_accepted}
            isLogoAlwaysShown={isLogoAlwaysShown}
            userId={userId}
            role={userState.payload.role}
            route={route}
            routeParams={params}
            location={location}
            nobreadcrumbs={(isProfilePage && isUserNotAdmin) || nobreadcrumbs}
            clientsList={clientList}
            onClientChange={handleClientChange}
            isMobile={isTablet}
          >
            <WsProvider connect={everythingIsLoaded}>
              <Switch>
                {route.routes
                  .filter(({path}) => path !== '*')
                  .map((renderRoute, index) => (
                    <Route
                      key={renderRoute.key || index}
                      path={renderRoute.path}
                      exact={renderRoute.exact}
                      strict={renderRoute.strict}
                      render={(props) => <renderRoute.component {...props} route={renderRoute} />}
                    />
                  ))}
                <Route component={PageNotFound} key="not-found" />
              </Switch>
            </WsProvider>
          </MainLayout>
        </AuthProvider>
      </section>
    </MetaComponent>
  );
};

export default MainContainer;
