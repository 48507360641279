import React from 'react';

import {HistoryEditor} from 'slate-history';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import ToolbarButton from 'client/common/text-editor/buttons/toolbar-button';
import {CustomEditor} from 'client/common/text-editor/constants';

import cssModule from './history-buttons.module.scss';

const b = bem('history-buttons', {cssModule});

const HistoryButtons = ({editor}: {editor: CustomEditor}) => {
  const lang = useLanguage('COMMON.TEXT_EDITOR.LABELS');

  const canUndo = editor.history.undos.length > 0;
  const canRedo = editor.history.redos.length > 0;

  return (
    <div className={b('history-buttons')}>
      <ToolbarButton
        className={b('first-button')}
        onClick={() => HistoryEditor.undo(editor)}
        iconName="undo"
        label={lang.UNDO}
        disabled={!canUndo}
        bordered={false}
      />
      <ToolbarButton
        onClick={() => HistoryEditor.redo(editor)}
        iconName="redo"
        label={lang.REDO}
        disabled={!canRedo}
        bordered={false}
      />
    </div>
  );
};

export default HistoryButtons;
