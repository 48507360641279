import React from 'react';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams, useReduxFetch} from 'client/services/hooks';

import {getClientStores} from 'client/ducks/client-account/actions';
import {selectClientStores} from 'client/ducks/client-account/selectors';

import SearchInput from 'client/common/inputs/search-input';
import PaginationBar from 'client/common/paginations/pagination-bar';

import StoreInfoModal from 'client/components/client-account-parameters/account-stores/store-info-modal';
import PerPage from 'client/components/tables/common/per-page';
import {Client} from 'client/models/client/types';
import {Store} from 'client/models/stores/types';

import AccountStoresTable from './account-stores-table';

import cssModule from './account-stores.module.scss';

const b = bem('account-stores', {cssModule});

type AccountStoresProps = {
  client: Client;
};

const AccountStores: React.FC<AccountStoresProps> = (props) => {
  const {client} = props;
  const lang = useLanguage('ACCOUNT_PARAMETERS.ACCOUNT_STORES');
  const [queryParams, setQueryParams] = useQueryParams(null, {page: '1', perPage: '25', search: ''});
  const [editStore, setEditStore] = React.useState<Store | null>(null);

  const {data, loading, fetch} = useReduxFetch({
    action: getClientStores,
    selector: selectClientStores,
    actionArgs: {
      q: {
        client_id_eq: client.id,
        name_or_city_name_or_city_zip_cont: queryParams.search,
        active_eq: true,
      },
      page: queryParams.page,
      per_page: queryParams.perPage,
    },
  });

  const onSaveStore = () => {
    fetch().then(() => setEditStore(null));
  };

  return (
    <div className={b()}>
      <div className={b('top')}>
        <SearchInput
          className={b('search')}
          placeholder={lang.SEARCH_PLACEHOLDER}
          onSearch={(search) => setQueryParams({search, page: 1})}
          searchDefault={queryParams.search}
        />
        <PerPage currentValue={queryParams.perPage} onChange={setQueryParams} />
      </div>
      {data && (
        <>
          <AccountStoresTable
            data={data.items}
            loading={loading && !data.items.length}
            setActiveStore={(store: Store) => setEditStore(store)}
          />
          <PaginationBar
            onPageChange={setQueryParams}
            data={data.items}
            totalPages={data.meta.total_pages}
            totalItems={data.meta.total_count}
            perPage={+queryParams.perPage}
            currentPage={data.meta.current_page}
          />
        </>
      )}
      {editStore && <StoreInfoModal onClose={() => setEditStore(null)} store={editStore} onSave={onSaveStore} />}
    </div>
  );
};

export default AccountStores;
