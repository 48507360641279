import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getCustomColumns(clientId, getAll = false) {
  const queryParams = {
    q: {
      client_id_eq: clientId,
      distinct: true,
    },
    include: ['external_column_adapter'],
    page: 1,
    per_page: 100,
  };
  const allColumnsActionTypes = [
    types.GET_ALL_CUSTOM_COLUMNS_REQUEST,
    types.GET_ALL_CUSTOM_COLUMNS_SUCCESS,
    types.GET_ALL_CUSTOM_COLUMNS_ERROR,
  ];

  const columnsActionTypes = [
    types.GET_CUSTOM_COLUMNS_REQUEST,
    types.GET_CUSTOM_COLUMNS_SUCCESS,
    types.GET_CUSTOM_COLUMNS_ERROR,
  ];

  const actionTypes = getAll ? allColumnsActionTypes : columnsActionTypes;

  if (!getAll) {
    queryParams.q.external_column_adapter_column_visibility_items_client_id_eq = clientId;
  }

  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEADS_COLUMN}`,
        method: 'GET',
        types: actionTypes,
        queryParams,
      },
      true,
    );
  };
}

export function flushCustomColumnsAllState() {
  return {
    type: types.FLUSH_CUSTOM_COLUMNS_ALL_STATE,
  };
}

export function deleteCustomColumnAction(id) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEADS_COLUMN}/${id}`,
        method: 'DELETE',
        types: [
          types.DELETE_CUSTOM_COLUMN_REQUEST,
          types.DELETE_CUSTOM_COLUMN_SUCCESS,
          types.DELETE_CUSTOM_COLUMN_ERROR,
        ],
      },
      true,
    );
  };
}
