import React from 'react';

import cn from 'classnames';
import uniq from 'lodash/uniq';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';
import {post} from 'client/services/fetch';
import {getValueIfExists, getClientPage} from 'client/services/helpers';

import {API_METHODS, CLIENT_PAGES, RUN_TABLE_STATUS_COLORS} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import FilterLabel from 'client/components/common/filter-label';
import Icon from 'client/components/common/icon';
import SearchField from 'client/components/common/search-field';

import FiltersModal from '../../modals/run-table-filters-modal';
import ResultModal from '../../modals/run-table-result-modal';
import VariablesModal from '../../modals/run-table-variables-modal';

import cssModule from './run-table-data-grid.module.scss';

const b = bem('run-table-data-grid', {cssModule});

class RunTableDataGrid extends ReactQueryParams {
  static propTypes = {
    data: PropTypes.array,
    languageState: PropTypes.object.isRequired,
    autotaskId: PropTypes.number,
    meta: PropTypes.object,
    search: PropTypes.string,
    updateMe: PropTypes.func,
    onPageChange: PropTypes.func,
    onSortChange: PropTypes.func,
    onSearchChange: PropTypes.func,
    globalView: PropTypes.bool,
    perPage: PropTypes.number,
  };

  static defaultProps = {
    data: [],
    meta: {
      current_page: 1,
      total_pages: 10,
      total_count: 100,
    },
    onPageChange: () => {},
    onSortChange: () => {},
    onSearchChange: () => {},
    perPage: 5,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE;

    this.state = {
      showingVariables: [],
      variableValues: {},
      showingResult: {},
      showStopRunModal: false,
      showVariablesModal: false,
      showResultModal: false,
      showFiltersModal: false,
      checkedRows: [],
    };
  }

  static toggleThemAll(isChecked, list) {
    return list.fill(isChecked);
  }

  static toggleCheckbox(isChecked, toggledNumber, list) {
    const newList = list.slice(0);
    newList[toggledNumber] = isChecked;
    return newList;
  }

  onPageChange = (params) => {
    this.setState({checkedRows: []});
    this.props.onPageChange(params);
  };

  onSortChange = ({sortField, sortOrder}) => {
    this.setState({checkedRows: []});
    this.props.onSortChange(sortField, sortOrder);
  };

  onSearchChange = (search) => {
    this.setState({checkedRows: []});
    this.props.onSearchChange(search);
  };

  onSearchClear = () => {
    this.setState({checkedRows: []});
    this.props.onSearchClear();
  };

  handleStopRun = () => {
    const promises = [];

    this.state.checkedRows.forEach((i) => {
      promises.push(post(`${API_METHODS.SCENARIO_EXECUTIONS}/${i.id}${API_METHODS.STOP}`));
    });

    this.setState({showStopRunModal: false});

    Promise.all(promises).then(() => {
      this.props.updateMe();
    });
  };

  removeFromFilter = (key) => {
    const {filters} = this.queryParams;
    delete filters[key];
    this.setQueryParams({filters}, true);
    this.setState({checkedRows: []});
    this.props.updateMe(filters);
  };

  clearFilter = () => {
    this.setQueryParams({filters: {}}, true);
    this.setState({checkedRows: []});
    this.props.updateMe({});
  };

  renderToolbar = () => {
    const checkedCount = this.state.checkedRows.length;
    const {filters = {}} = this.queryParams;
    const {LANGUAGE} = this;

    const filtersCount = Object.keys(filters).length;

    return (
      <div className={cn('flex-container flex-justify-between flex-align-bottom', b('toolbar'))}>
        <div>
          <div>
            <SearchField
              cssModifier={cn('input', b('search-input'))}
              placeholder={this.LANGUAGE.SEARCH_PLACEHOLDER}
              onSearch={this.onSearchChange}
              onClear={this.onSearchClear}
              defaultSearch={this.props.search}
            />
            <button onClick={() => this.setState({showFiltersModal: true})} className="button button--bg-4">
              <Icon name="filter" className="button__icon" />
              <span>
                {`${LANGUAGE.FILTERS_BUTTON}
                  (${filtersCount})`}
              </span>
            </button>
            <span className={b('search-result-num')}>
              <span className="main-text">{`${this.props.meta.total_count || 0} ${LANGUAGE.NUM_OF_RESULTS}`}</span>
            </span>
          </div>
          <div className={b('filter-labels')}>
            {filters.startDate && (
              <FilterLabel
                text={`${LANGUAGE.FILTERS_MODAL.START_DATE_LABEL}: ${filters.startDate.type} ${moment(
                  filters.startDate.value,
                ).format('DD/MM/YYYY HH:mm')}`}
                onDelete={() => this.removeFromFilter('startDate')}
              />
            )}

            {filters.endDate && (
              <FilterLabel
                text={`${LANGUAGE.FILTERS_MODAL.END_DATE_LABEL}: ${filters.endDate.type} ${moment(
                  filters.endDate.value,
                ).format('DD/MM/YYYY HH:mm')}`}
                onDelete={() => this.removeFromFilter('endDate')}
              />
            )}

            {filters.runningSteps && (
              <FilterLabel
                text={`${LANGUAGE.FILTERS_MODAL.CURRENTLY_RUNNING_STEPS_LABEL}: ${filters.runningSteps
                  .map((i) => LANGUAGE.SCENARIO_STEPS[i])
                  .join(', ')}`}
                onDelete={() => this.removeFromFilter('runningSteps')}
              />
            )}

            {filters.statuses && (
              <FilterLabel
                text={`${LANGUAGE.FILTERS_MODAL.STATUS_LABEL}: ${uniq(
                  filters.statuses.map((i) => LANGUAGE.STATUSES[i]),
                ).join(', ')}`}
                onDelete={() => this.removeFromFilter('statuses')}
              />
            )}

            {filters.triggeredScenarios && (
              <FilterLabel
                text={`${LANGUAGE.FILTERS_MODAL.TRIGGERED_SCENARIOS_LABEL}: ${filters.triggeredScenarios
                  .map((i) => i.code)
                  .join(', ')}`}
                onDelete={() => this.removeFromFilter('triggeredScenarios')}
              />
            )}

            {filters.interfaces && (
              <FilterLabel
                text={`${LANGUAGE.FILTERS_MODAL.INTERFACES_LABEL}: ${filters.interfaces.map((i) => i.code).join(', ')}`}
                onDelete={() => this.removeFromFilter('interfaces')}
              />
            )}
            {filtersCount > 0 && (
              <button onClick={this.clearFilter} className="button button--bg-4">
                {LANGUAGE.CLEAR_FILTER_BUTTON}
              </button>
            )}
          </div>
        </div>

        <div>
          {checkedCount > 0 && (
            <button
              className="button button--bg-4 margin-right-10"
              onClick={() => this.setState({showStopRunModal: true})}
            >
              {`${LANGUAGE.STOP_RUN_BUTTON} (${checkedCount})`}
            </button>
          )}
        </div>
      </div>
    );
  };

  renderText = ({value = ''}) => {
    return (
      <div className="ellipsis-text" title={value}>
        {value}
      </div>
    );
  };

  renderFirstName = (participation) => this.renderText(getValueIfExists(participation, ['lead', 'first_name']));

  renderLastName = (participation) => this.renderText(getValueIfExists(participation, ['lead', 'last_name']));

  renderLeadCard = ({value}) =>
    value?.lead && (
      <div className={b('lead-card')}>
        <Link to={`${CLIENT_PAGES.LEADS_PROFILE}/${value.lead_id}`}>
          <span className="theme-color-9">
            <Icon name="comment" />
          </span>
        </Link>
      </div>
    );

  renderStatus = ({value}) => {
    return (
      <div className="text-center">
        <span
          title={this.LANGUAGE.STATUSES[value]}
          className="status-icon"
          style={{
            color: RUN_TABLE_STATUS_COLORS[value],
          }}
        />
      </div>
    );
  };

  renderDate = ({value}) => {
    if (!value?.trim()) {
      return null;
    }

    return (
      <div>
        <div className="main-text main-text--small main-text--bold">{moment(value).format('DD/MM/YYYY')}</div>
        <div className="main-text main-text--small">{moment(value).format('HH:mm:ss')}</div>
      </div>
    );
  };

  renderTriggeringVariables = ({item}) => {
    const {scenario, variable_values} = item;
    const showingVariables = scenario?.scenario_variables || [];
    return showingVariables.length > 0 ? (
      <div
        className="link main-text"
        onClick={() => this.setState({showVariablesModal: true, showingVariables, variableValues: variable_values})}
      >
        {showingVariables.length}
      </div>
    ) : null;
  };

  renderCurrentVariables = ({item}) => {
    const {scenario_step, variable_values} = item;
    const showingVariables = scenario_step?.scenario_step_variables || [];
    return showingVariables.length > 0 ? (
      <div
        className="link main-text"
        onClick={() => this.setState({showVariablesModal: true, showingVariables, variableValues: variable_values})}
      >
        {showingVariables.length}
      </div>
    ) : null;
  };

  renderResult = ({item}) => {
    return (
      <div className="link main-text" onClick={() => this.setState({showResultModal: true, showingResult: item})}>
        {this.LANGUAGE.SEE_RESULT}
      </div>
    );
  };

  renderOperationName = ({value: operation}) => {
    return (
      <Link
        className="link main-text ellipsis-text max-width-100-percent"
        to={`${getClientPage(operation.client)}/${operation.client.id}${CLIENT_PAGES.OPERATIONS}/${operation.id}`}
        title={operation.name}
      >
        {operation.name}
      </Link>
    );
  };

  renderClientName = ({value: client}) => {
    return (
      <Link
        className="link main-text ellipsis-text max-width-100-percent"
        to={`${getClientPage(client)}/${client.id}`}
        title={client.name}
      >
        {client.name}
      </Link>
    );
  };

  render() {
    const {data, meta} = this.props;

    return (
      <div>
        <ResultModal
          show={this.state.showResultModal}
          scenarioExecution={this.state.showingResult}
          onClose={() => this.setState({showResultModal: false})}
        />

        <VariablesModal
          show={this.state.showVariablesModal}
          variables={this.state.showingVariables}
          values={this.state.variableValues}
          onClose={() => this.setState({showVariablesModal: false})}
        />

        <FiltersModal
          show={this.state.showFiltersModal}
          onClose={() => this.setState({showFiltersModal: false, checkedRows: []})}
          updateMe={this.props.updateMe}
          totalItems={this.props.meta.total_count}
          autotaskId={this.props.autotaskId}
          globalView={this.props.globalView}
        />

        <ConfirmationModal
          show={this.state.showStopRunModal}
          onClose={() => this.setState({showStopRunModal: false})}
          onCancel={() => this.setState({showStopRunModal: false})}
          onConfirm={this.handleStopRun}
          title={this.LANGUAGE.STOP_RUN_MODAL.TITLE}
          message={this.LANGUAGE.STOP_RUN_MODAL.BODY_TEXT.split('\n').map((item, i) => (
            <p key={i}>{item}</p>
          ))}
          cancelText={this.LANGUAGE.STOP_RUN_MODAL.CANCEL_BUTTON}
          confirmText={this.LANGUAGE.STOP_RUN_MODAL.YES_BUTTON}
          className="theme-color-opdb-labels"
          buttonConfirmClass="button--bg-4"
        />

        <div className={b('toolbar-wrapper')}>
          {this.renderToolbar()}
          <PerPageDropdown
            onChange={(pages) => {
              this.props.onPageChange({page: 1, perPage: pages});
            }}
            value={String(this.props.perPage)}
            simpleValue
          />
        </div>
        <ClientTable
          data={data}
          onSortChange={this.onSortChange}
          checkable={true}
          onCheck={(isChecked, item) => {
            let checkedItems;
            if (isChecked) {
              checkedItems = [...this.state.checkedRows, item];
            } else {
              checkedItems = this.state.checkedRows.filter((row) => row.id !== item.id);
            }

            this.setState({checkedRows: checkedItems});
          }}
          onCheckAll={(isChecked) => {
            this.setState({checkedRows: isChecked ? data : []});
          }}
          checkedRows={this.state.checkedRows}
          sortField={this.props.sort?.name}
          sortOrder={this.props.sort?.order}
          checkboxProps={{color: 'games'}}
          columns={[
            {
              name: 'id',
              label: this.LANGUAGE.PARTICIPANT_LEAD_COLUMN,
              width: 300,
              subcolumns: [
                {
                  label: this.LANGUAGE.FIRST_NAME_COLUMN,
                  path: 'participation.lead.first_name',
                  render: this.renderText,
                },
                {label: this.LANGUAGE.LAST_NAME_COLUMN, path: 'participation.lead.last_name', render: this.renderText},
                {
                  label: this.LANGUAGE.CARD_COLUMN,
                  path: 'participation',
                  render: this.renderLeadCard,
                },
              ],
            },
            {
              name: 'triggering',
              label: this.LANGUAGE.TRIGGERING_COLUMN,
              width: 480,
              subcolumns: [
                {
                  path: 'participation.interaction.interface.name',
                  name: 'participation_interaction_interface_name',
                  render: this.renderText,
                  label: this.LANGUAGE.INTERFACE_COLUMN,
                  sortable: true,
                },
                {
                  path: 'scenario.code',
                  name: 'scenario_code',
                  sortable: true,
                  render: this.renderText,
                  label: this.LANGUAGE.SCENARIO_COLUMN,
                },
                {
                  path: 'created_at',
                  name: 'created_at',
                  sortable: true,
                  render: this.renderDate,
                  label: this.LANGUAGE.TIMESTAMP_COLUMN,
                },
                {
                  name: 'triggering_variables',
                  render: this.renderTriggeringVariables,
                  label: this.LANGUAGE.VARIABLES_COLUMN,
                },
              ],
            },
            {
              name: 'current_execution',
              label: this.LANGUAGE.CURRENT_EXECUTION_COLUMN,
              width: 480,
              subcolumns: [
                {
                  path: 'scenario_step.scenario.code',
                  name: 'scenario_step_scenario_code',
                  sortable: true,
                  render: this.renderText,
                  label: this.LANGUAGE.SCENARIO_COLUMN,
                },
                {
                  path: 'scenario_step.implementation_class_name',
                  name: 'scenario_step_implementation_class_name',
                  sortable: true,
                  render: this.renderText,
                  label: this.LANGUAGE.STEP_COLUMN,
                  formatter: (value) => this.LANGUAGE.SCENARIO_STEPS[value],
                },
                {
                  path: 'scenario_step.updated_at',
                  name: 'scenario_step_updated_at',
                  sortable: true,
                  render: this.renderDate,
                  label: this.LANGUAGE.TIMESTAMP_COLUMN,
                },
                {name: 'current_variables', render: this.renderCurrentVariables, label: this.LANGUAGE.VARIABLES_COLUMN},
              ],
            },
            {
              name: 'participation',
              label: this.LANGUAGE.SOURCE,
              width: 300,
              subcolumns: [
                {
                  path: 'participation.interaction.id',
                  render: this.renderText,
                  label: this.LANGUAGE.SOURCE_ID,
                },
                {
                  path: 'participation.interaction.name',
                  render: this.renderText,
                  label: this.LANGUAGE.SOURCE_NAME,
                },
                {
                  path: 'participation.interaction.source.name',
                  render: this.renderText,
                  label: this.LANGUAGE.SOURCE,
                },
              ],
            },
            {
              ...(this.props.globalView && {
                path: 'scenario.automation_task.operation',
                render: this.renderOperationName,
                label: this.LANGUAGE.OPERATION_COLUMN,
                width: 100,
              }),
            },
            {
              ...(this.props.globalView && {
                path: 'scenario.automation_task.operation.client',
                render: this.renderClientName,
                label: this.LANGUAGE.CLIENT_COLUMN,
                width: 100,
              }),
            },
            {
              path: 'state',
              name: 'state',
              sortable: true,
              render: this.renderStatus,
              label: this.LANGUAGE.STATUS_COLUMN,
              width: 75,
            },
            {
              path: 'ended_at',
              name: 'ended_at',
              sortable: true,
              render: this.renderDate,
              label: this.LANGUAGE.TIMESTAMP_COLUMN,
              width: 100,
            },
            {name: 'result', render: this.renderResult, label: this.LANGUAGE.RESULT_COLUMN, width: 80},
          ]}
        />
        <PaginationBar
          data={data}
          perPage={this.props.perPage}
          totalPages={meta.total_pages}
          totalItems={meta.total_count}
          currentPage={meta.current_page}
          onPageChange={this.onPageChange}
        />
      </div>
    );
  }
}

export default connect(({languageState}) => ({
  languageState,
}))(RunTableDataGrid);
