import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import {addHashToImage, checkFileType} from 'client/services/helpers';

import {leadsImportFile} from 'client/ducks/lead/actions';
import {getLeadsImportReport} from 'client/ducks/leads-import/actions';
import {selectImportReportId} from 'client/ducks/leads-import/selectors';

import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import LoadingSpinner from 'client/components/common/loading-spinner';

import './leads-import-file-modal.scss';

class LeadsImportFileModal extends Component {
  static defaultProps = {
    show: false,
  };

  static propTypes = {
    show: PropTypes.bool,
    lang: PropTypes.object.isRequired,
    leadImportId: PropTypes.number,
    leadsImportFile: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    getLeadsImportReport: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    onClose: PropTypes.func.isRequired,
  };

  static formName = 'LeadsImportFileModalForm';

  constructor(props) {
    super(props);

    this.state = {
      disableSubmit: true,
      showErrorText: false,
      errorText: '',
      isLoading: false,
      file: {
        name: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.props.initialize({file: ''});
    }
  }

  onSave = () => {
    const file = this.state.file;
    const formData = new FormData();
    const hash = String(new Date().getTime());
    const hashedName = addHashToImage(this.state.file.name, hash);
    formData.append('file_lead_import[leads]', file, encodeURIComponent(hashedName));

    this.setState({isLoading: true});

    this.props.leadsImportFile(formData).then(() => {
      const {leadImportId} = this.props;
      if (!leadImportId) {
        this.setState({isLoading: false});
        this.showError(this.props.lang.INCORRECT_FILE);
        return;
      }
      this.props
        .getLeadsImportReport(leadImportId, {
          include: ['lead_transfer', 'user'],
        })
        .then(() => {
          this.setState({isLoading: false});
          this.cleanFileName();
          this.props.onConfirm();
        });
    });
  };

  handleChange = (e) => {
    const file = e.target.files[0];
    checkFileType(file)
      .then(() => {
        this.setState({
          file,
          disableSubmit: false,
        });
        this.resetError();
      })
      .catch(() => {
        this.showError(this.props.lang.INCORRECT_FILE_TYPE);
      });
  };

  resetError = () => {
    this.setState({
      showErrorText: false,
      errorText: '',
    });
  };

  showError = (text) => {
    this.setState({
      showErrorText: true,
      errorText: text,
      disableSubmit: true,
    });
  };

  handleCloseModal = () => {
    this.cleanFileName();
    this.resetError();
    this.props.onClose();
  };

  cleanFileName() {
    this.setState({
      file: {
        name: '',
      },
    });
  }

  render() {
    const {handleSubmit, show, onCancel, lang} = this.props;
    const {disableSubmit, isLoading} = this.state;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-2"
        backdropClassName="modal-window__backdrop"
        onClose={this.handleCloseModal}
        title={lang.TITLE}
      >
        <form onSubmit={handleSubmit(this.onSave)}>
          <div className="theme-color-2">
            <Field
              buttonCssClass="button--bg-2"
              buttonText={lang.CHOOSE_BUTTON}
              placeholder={lang.FILE_PLACEHOLDER}
              name="file"
              type="file-single"
              htmlFor="id-file"
              fileName={this.state.file.name}
              component={fieldTemplate}
              input={{
                onChange: this.handleChange,
              }}
            />
            {this.state.showErrorText && <div className="message-error-text">{this.state.errorText}</div>}
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button
              className="button button--bg-11 modal-window__footer-btn"
              type="button"
              onClick={onCancel || this.handleCloseModal}
            >
              {lang.CANCEL_BUTTON}
            </button>
            <button
              className="button button--bg-2 modal-window__footer-btn"
              type="submit"
              disabled={disableSubmit || isLoading}
            >
              {isLoading ? (
                <LoadingSpinner cssModifier="leads-import-file-modal__loader" loading={true} />
              ) : (
                lang.NEXT_BUTTON
              )}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const LeadsImportFileModalForm = reduxForm({
  form: LeadsImportFileModal.formName,
})(LeadsImportFileModal);

export default connect(
  (state) => ({
    lang: state.languageState.payload.LEADS_PORT.IMPORT_FILE_MODAL,
    leadImportId: selectImportReportId(state),
  }),
  {leadsImportFile, getLeadsImportReport},
)(LeadsImportFileModalForm);
