import React from 'react';

import bem from 'client/services/bem';

import Popover from 'client/common/popovers/popover';

import Icon from 'client/components/common/icon';

import cssModule from './exclude-characters-label.module.scss';

const b = bem('exclude-characters-label', {cssModule});

type ExcludeCharactersLabelProps = {
  label: string;
  text: string;
};

const ExcludeCharactersLabel: React.FC<ExcludeCharactersLabelProps> = (props) => {
  const {text, label} = props;

  return (
    <div className={b()}>
      <span>{label}</span>
      <Popover
        className={b('popover')}
        position="bottom"
        overlayInnerStyle={{width: 200}}
        overlay={<span className={b('text')}>{text}</span>}
      >
        <Icon className={b('icon')} name="info-small" width={10} height={10} />
      </Popover>
    </div>
  );
};

export default ExcludeCharactersLabel;
