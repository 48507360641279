import {isNumberString} from 'client/services/helpers';

import * as constants from 'client/ducks/results-graphs/constants';

import * as helpers from './helpers';

export const mapInitialValues = (editingDiagram) => {
  const defaultValues = {
    name: '',
    visible: false,
    exclude_empty_values: true,
    kind: constants.diagramKinds.PIE_CHART,
    column_adapter_id: null,
    diagram_method: constants.diagramMethods.VALUE,
    range_of_interval: '1',
    line_kind: constants.diagramLineKinds.LINE_WITH_VALUES,
    dot_precision: constants.diagramDotPrecisions.AUTOMATIC,
    range: '5',
    color: constants.diagramColors[0],
    tooltip: '',
  };

  if (!editingDiagram) {
    return defaultValues;
  }

  return {
    name: editingDiagram.name,
    visible: editingDiagram.visible,
    exclude_empty_values: editingDiagram.exclude_empty_values,
    kind: editingDiagram.kind,
    column_adapter_id: editingDiagram.column_adapter_id,
    diagram_method: editingDiagram.diagram_method,
    range_of_interval: editingDiagram.dot_value || defaultValues.range_of_interval,
    line_kind: editingDiagram.line_kind,
    dot_precision: editingDiagram.dot_precision,
    range: editingDiagram.dot_value || defaultValues.range,
    color: editingDiagram.color || defaultValues.color,
    tooltip: editingDiagram.tooltip,
  };
};

export const mapFormValues = (formValues, props) => {
  const {clientId, autotaskId} = props;

  const data = {
    client_id: clientId,
    automation_task_id: autotaskId,
    name: formValues.name,
    visible: formValues.visible,
    exclude_empty_values: formValues.exclude_empty_values,
    kind: formValues.kind,
    column_adapter_id: formValues.column_adapter_id,
    color: formValues.color,
  };

  if (helpers.showMethod(formValues)) {
    data.diagram_method = formValues.diagram_method;
  }

  if (helpers.showRangeOfInterval(formValues)) {
    data.dot_value = formValues.range_of_interval;
  }

  if (helpers.showLineKind(formValues)) {
    data.line_kind = formValues.line_kind;
  }

  if (helpers.showIntervalRange(formValues)) {
    data.dot_precision = formValues.dot_precision;
  }

  if (helpers.showRange(formValues)) {
    data.dot_value = formValues.range;
  }

  if (helpers.showTooltip(formValues)) {
    data.tooltip = formValues.tooltip;
  }

  return {diagram: data};
};

export const validate = (formValues, props) => {
  const {lang} = props;

  if (!formValues.name || !formValues.name.trim()) {
    return {name: lang.NAME_REQUIRED};
  }

  if (!formValues.column_adapter_id) {
    return {column_adapter_id: lang.DATA_SOURCE_REQUIRED};
  }

  if (helpers.showRangeOfInterval(formValues) && !formValues.range_of_interval) {
    return {range_of_interval: lang.RANGE_REQUIRED};
  }

  if (helpers.showRangeOfInterval(formValues) && !isNumberString(formValues.range_of_interval)) {
    return {range_of_interval: lang.NUMBER_REQUIRED};
  }

  if (helpers.showRangeOfInterval(formValues) && +formValues.range_of_interval < 1) {
    return {range_of_interval: lang.NUMBER_INVALID.replace('{{value}}', 1)};
  }

  if (helpers.showRange(formValues) && !formValues.range) {
    return {range: lang.VALUE_REQUIRED};
  }

  if (helpers.showRange(formValues) && !isNumberString(formValues.range)) {
    return {range: lang.NUMBER_REQUIRED};
  }

  if (helpers.showRange(formValues) && +formValues.range < 2) {
    return {range: lang.NUMBER_INVALID.replace('{{value}}', 2)};
  }

  return {};
};
