export default {
  USERS_CHANGE_PER_PAGE: '@@agency/USERS_CHANGE_PER_PAGE',
  ONGOING_OPERATIONS_CHANGE_PER_PAGE: '@@agency/ONGOING_OPERATIONS_CHANGE_PER_PAGE',
  FINISHED_OPERATIONS_CHANGE_PER_PAGE: '@@agency/FINISHED_OPERATIONS_CHANGE_PER_PAGE',
  CLIENTS_CHANGE_PER_PAGE: '@@agency/CLIENTS_CHANGE_PER_PAGE',

  USERS_CHANGE_SORT: '@@agency/USERS_CHANGE_SORT',
  ONGOING_OPERATIONS_CHANGE_SORT: '@@agency/ONGOING_OPERATIONS_CHANGE_SORT',
  FINISHED_OPERATIONS_CHANGE_SORT: '@@agency/FINISHED_OPERATIONS_CHANGE_SORT',
  CLIENTS_CHANGE_SORT: '@@agency/CLIENTS_CHANGE_SORT',

  USERS_CHANGE_SEARCH: '@@agency/USERS_CHANGE_SEARCH',

  CHANGE_SELECTED_USERS: '@@agency/CHANGE_SELECTED_USERS',
  CLEAR_STORE: '@@agency/CLEAR_STORE',

  GET_USERS_REQUEST: '@@agency/GET_USERS_REQUEST',
  GET_USERS_SUCCESS: '@@agency/GET_USERS_SUCCESS',
  GET_USERS_ERROR: '@@agency/GET_USERS_ERROR',

  GET_CLIENTS_REQUEST: '@@agency/GET_CLIENTS_REQUEST',
  GET_CLIENTS_SUCCESS: '@@agency/GET_CLIENTS_SUCCESS',
  GET_CLIENTS_ERROR: '@@agency/GET_CLIENTS_ERROR',

  GET_CLIENTS_BY_ID_REQUEST: '@@agency/GET_CLIENTS_BY_ID_REQUEST',
  GET_CLIENTS_BY_ID_SUCCESS: '@@agency/GET_CLIENTS_BY_ID_SUCCESS',
  GET_CLIENTS_BY_ID_ERROR: '@@agency/GET_CLIENTS_BY_ID_ERROR',

  GET_ONGOING_OPERATIONS_REQUEST: '@@agency/GET_ONGOING_OPERATIONS_REQUEST',
  GET_ONGOING_OPERATIONS_SUCCESS: '@@agency/GET_ONGOING_OPERATIONS_SUCCESS',
  GET_ONGOING_OPERATIONS_ERROR: '@@agency/GET_ONGOING_OPERATIONS_ERROR',

  GET_FINISHED_OPERATIONS_REQUEST: '@@agency/GET_FINISHED_OPERATIONS_REQUEST',
  GET_FINISHED_OPERATIONS_SUCCESS: '@@agency/GET_FINISHED_OPERATIONS_SUCCESS',
  GET_FINISHED_OPERATIONS_ERROR: '@@agency/GET_FINISHED_OPERATIONS_ERROR',

  GET_AGENCY_REQUEST: '@@agency/GET_AGENCY_REQUEST',
  GET_AGENCY_SUCCESS: '@@agency/GET_AGENCY_SUCCESS',
  GET_AGENCY_ERROR: '@@agency/GET_AGENCY_ERROR',

  DELETE_AGENCY_REQUEST: '@@agency/DELETE_AGENCY_REQUEST',
  DELETE_AGENCY_SUCCESS: '@@agency/DELETE_AGENCY_SUCCESS',
  DELETE_AGENCY_ERROR: '@@agency/DELETE_AGENCY_ERROR',

  GET_CLIENT_USER_REQUEST: '@@agency/GET_CLIENT_USER_REQUEST',
  GET_CLIENT_USER_SUCCESS: '@@agency/GET_CLIENT_USER_SUCCESS',
  GET_CLIENT_USER_ERROR: '@@agency/GET_CLIENT_USER_ERROR',

  GET_SUBSIDIARIES_REQUEST: '@@agency/GET_SUBSIDIARIES_REQUEST',
  GET_SUBSIDIARIES_SUCCESS: '@@agency/GET_SUBSIDIARIES_SUCCESS',
  GET_SUBSIDIARIES_ERROR: '@@agency/GET_SUBSIDIARIES_ERROR',

  GET_AGENCY_MEMBERSHIPS_REQUEST: '@@agency/GET_AGENCY_MEMBERSHIPS_REQUEST',
  GET_AGENCY_MEMBERSHIPS_SUCCESS: '@@agency/GET_AGENCY_MEMBERSHIPS_SUCCESS',
  GET_AGENCY_MEMBERSHIPS_ERROR: '@@agency/GET_AGENCY_MEMBERSHIPS_ERROR',

  GET_ADMIN_USERS_REQUEST: '@@agency/GET_ADMIN_USERS_REQUEST',
  GET_ADMIN_USERS_SUCCESS: '@@agency/GET_ADMIN_USERS_SUCCESS',
  GET_ADMIN_USERS_ERROR: '@@agency/GET_ADMIN_USERS_ERROR',

  GET_AGENCY_USERS_FULL_LIST_REQUEST: '@@agency/GET_AGENCY_USERS_FULL_LIST_REQUEST',
  GET_AGENCY_USERS_FULL_LIST_SUCCESS: '@@agency/GET_AGENCY_USERS_FULL_LIST_SUCCESS',
  GET_AGENCY_USERS_FULL_LIST_ERROR: '@@agency/GET_AGENCY_USERS_FULL_LIST_ERROR',

  SET_DEFAULT_NEW_USER: '@@agency/SET_DEFAULT_NEW_USER',
};
