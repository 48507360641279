import React from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {getParticipationWinners} from 'client/ducks/participants-winners/actions';
import {
  participantsWinnersSelector,
  participantsWinnersMetaSelector,
} from 'client/ducks/participants-winners/selectors';

import PaginationBar from 'client/common/paginations/pagination-bar';

import ClientTable from 'client/components/common/client-table';

import DatetimeCell from 'client/components/tables/common/cells/datetime-cell';

import AdminPrizeCell from './admin-prize-cell';

import {mapFilter, mapWinnersFilterForUrl, clearFilters, getSearchParams} from '../helpers';

import './data-winners.scss';

const getPrizeNames = (prizeNames) => prizeNames.reduce((acc, prize) => [...acc, prize.custom_name], []);

class DataWinners extends ReactQueryParams {
  static propTypes = {
    winners: PropTypes.array,
    winnersMeta: PropTypes.shape({
      current_page: PropTypes.number,
      next_page: PropTypes.number,
      prev_page: PropTypes.number,
      total_count: PropTypes.number,
      total_pages: PropTypes.number,
    }),
    prizeNames: PropTypes.array,
    prizeNamePath: PropTypes.string,
    filters: PropTypes.object,
    searchString: PropTypes.string,
    pages: PropTypes.shape({
      current: PropTypes.number,
      perPage: PropTypes.number,
    }),
    isAdmin: PropTypes.bool,
  };

  static defaultProps = {
    winners: [],
    winnersMeta: {
      current_page: 1,
      next_page: 2,
      prev_page: 0,
      total_count: 1,
      total_pages: 1,
    },
    prizeNames: [],
    filters: {},
    isAdmin: false,
  };

  static DEFAULT_SORT = 'participated_at desc';

  static PRIZES_NAME_FOR_WIN_PREFIX = 'participation_prizes_name_for_win_';

  static excludableColumns = ['store', 'region'];

  constructor(props) {
    super(props);
    this.basicColumns = [
      {
        name: 'firstName',
        label: props.language.FIRST_NAME,
        path: 'first_name',
      },
      {
        name: 'name',
        label: props.language.LAST_NAME,
        path: 'last_name',
      },
      !props.isAdmin && {
        name: 'gender',
        label: props.language.GENDER,
        path: 'gender',
      },
      {
        name: 'email',
        label: props.language.EMAIL,
        path: 'email',
      },
      {
        name: 'phone',
        label: props.language.PHONE,
        path: 'phone',
      },
      {
        name: 'origin',
        label: props.language.SOURCE,
        path: 'origin',
        sortable: true,
      },
      {
        name: 'region',
        label: props.language.REGION,
        path: 'region',
        sortable: true,
      },
      {
        name: 'store',
        label: props.language.STORE,
        path: 'store',
      },
    ].filter((el) => el);
    this.state = {
      columns: [],
      page: 1,
      loading: false,
    };
  }

  componentDidMount() {
    const {queryParams} = this;
    if (queryParams.page) {
      this.props.onPageChange(queryParams.page);
    }

    this.fetchWinners();
  }

  componentDidUpdate(prevProps) {
    const {prevParams, nextParams} = getSearchParams(prevProps, this.props);
    const mainParams = cloneDeep(nextParams);
    delete mainParams.filter;

    if (!isEmpty(prevParams) && !isEqual(prevParams, nextParams)) {
      this.setQueryParams({
        ...clearFilters(mainParams),
        search: mainParams.search,
      });
      this.fetchWinners();
    }
    if (!isEqual(getPrizeNames(this.props.prizeNames), getPrizeNames(prevProps.prizeNames))) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        columns: this.formatColumns(this.props),
      });
    }
  }

  onPageChange = ({page}) => {
    this.setState({
      page,
    });
  };

  formatColumns = ({prizeNames, language, winners}) => {
    const label = language.PARTICIPATION;
    const columns = this.basicColumns.map((col) => ({
      ...col,
      show: DataWinners.excludableColumns.includes(col.name) ? winners.some((winner) => winner[col.name]) : true,
    }));
    if (prizeNames) {
      columns.push(
        ...prizeNames.map((item, idx) => ({
          name: `${DataWinners.PRIZES_NAME_FOR_WIN_PREFIX}${idx + 1}`,
          label: item.custom_name,
          sortable: true,
          path: `prizes.${idx + 1}`,
          render: this.props.isAdmin
            ? ({value}) => <AdminPrizeCell value={value} onUpdate={this.fetchWinners} />
            : this.defaultPrizeRenderer,
        })),
      );
    }

    return [
      ...columns,
      {
        name: 'level_reached_or_participated_at',
        label,
        sortable: true,
        path: 'latest_participated_at',
        render: DatetimeCell,
        initialSortOrder: 'desc',
      },
    ];
  };

  // eslint-disable-next-line react/prop-types
  defaultPrizeRenderer = ({value}) => {
    return (
      <div>
        {value === ' '
          ? value
          : value.map(({name}, index) => (
              <div className="client-table__value" key={`${name}-${index}`}>
                {name}
              </div>
            ))}
      </div>
    );
  };

  isCustomSort = () => {
    return this.queryParams.sortField?.indexOf(DataWinners.PRIZES_NAME_FOR_WIN_PREFIX) > -1;
  };

  fetchWinners = () => {
    const {
      queryParams,
      props: {autotaskId},
      isCustomSort,
    } = this;
    const sortKey = isCustomSort() ? 'custom_sort' : 's';
    const params = {
      automation_task_id: autotaskId,
      q: {
        [sortKey]: queryParams.sortField
          ? `${queryParams.sortField} ${queryParams.sortOrder}`
          : DataWinners.DEFAULT_SORT,
      },
      page: queryParams.page || this.props.pages.current,
      per_page: queryParams.perPage || this.props.pages.perPage,
    };
    if (queryParams.filter) {
      const {q, distinct} = mapWinnersFilterForUrl(mapFilter(queryParams.filter), this.props.prizeNames);

      params.distinct = distinct;
      params.q = {
        ...params.q,
        ...q,
      };
      params.q.g = [params.q.g];
    }
    if (queryParams.search) {
      params.query_string = encodeURIComponent(queryParams.search);
    }

    this.setState({loading: true});
    this.props.onSetTotal(0);

    this.props
      .getParticipationWinners(params)
      .then(({payload: {meta}}) => {
        this.setState({loading: false});
        if (meta) {
          this.props.onSetTotal(meta.total_count);
        }
        this.setState({
          columns: this.formatColumns(this.props),
        });
      })
      .catch(() => this.setState({loading: false}));
  };

  render() {
    const {winners = [], winnersMeta, pages, sortParams, onSortChange, onPageChange} = this.props;
    const {columns, loading} = this.state;

    return (
      <div className="data-winners">
        <ClientTable
          columns={columns}
          rowKey="winner_id"
          data={winners}
          sortField={sortParams.sortField}
          sortOrder={sortParams.sortOrder}
          onSortChange={onSortChange}
          loading={loading}
        />
        <PaginationBar
          onPageChange={onPageChange}
          data={winners}
          totalPages={winnersMeta.total_pages}
          totalItems={winnersMeta.total_count}
          perPage={pages.perPage}
          currentPage={winnersMeta.current_page}
          loading={loading}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    winners: participantsWinnersSelector(state),
    winnersMeta: participantsWinnersMetaSelector(state),
  }),
  {
    getParticipationWinners,
  },
)(DataWinners);
