import React from 'react';

import PropTypes from 'prop-types';

import Popover from 'client/common/popovers/popover';

function AtStatusPopover({children, overlay}) {
  return (
    <Popover
      shiftTop={5}
      position="bottom"
      className="pull-left"
      arrowConfig={90}
      overlayInnerStyle={{
        width: 220,
      }}
      overlay={overlay}
    >
      {children}
    </Popover>
  );
}

AtStatusPopover.defaultProps = {
  children: '',
  overlay: '',
};

AtStatusPopover.propTypes = {
  children: PropTypes.any,
  overlay: PropTypes.any,
};

export default AtStatusPopover;
