import React, {Component} from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {reset} from 'redux-form';

import bem from 'client/services/bem';
import {getAuthHeaders, getQueryParams, mapFilter} from 'client/services/helpers';
import {getClientPage} from 'client/services/helpers';

import {getAdmins} from 'client/ducks/admins-list/admins-list.action';
import {selectAllAgencies} from 'client/ducks/agencies/selectors';
import {AGENCY_USERS_LIST_LOADED} from 'client/ducks/agency-users/agency-users.action';

import {API_METHODS, API_PATH, CLIENT_PAGES, APP_ROLES} from 'client/common/config';
import PaginationBar from 'client/common/paginations/pagination-bar';
import Popover from 'client/common/popovers/popover';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import FilterLabel from 'client/components/common/filter-label';
import Icon from 'client/components/common/icon';
import SearchField from 'client/components/common/search-field';

import ClientsFiltersModal from '../../modals/clients-filters-modal';
import NewClientModal from '../../modals/new-client-modal';
import NewClientNoSelectModal from '../../modals/new-client-no-select-modal';
import NewClientSelectUsersModal from '../../modals/new-client-select-user-modal';

import cssModule from './clients-data-grid.module.scss';

const b = bem('clients-data-grid', {cssModule});

let LANGUAGE = {};

class ClientsDataGrid extends Component {
  static propTypes = {
    data: PropTypes.array,
    languageState: PropTypes.object,
    onPageChange: PropTypes.func,
    meta: PropTypes.object,
    userState: PropTypes.string,
    onSortChange: PropTypes.func,
    onSearchChange: PropTypes.func,
    onSearchClear: PropTypes.func,
    showClear: PropTypes.bool,
    agenciesList: PropTypes.array,
    getAdmins: PropTypes.func.isRequired,
    adminsList: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    ...withRouter.propTypes,
  };

  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      showModalCreate: false,
      showModalSelect: false,
      showModalNoSelect: false,
      data: {},
      members: [],
      isFilterVisible: false,
      filter: {},
      meta: {total_count: 0},
      selectedAgencyId: '',
    };

    LANGUAGE = props.languageState.payload.CLIENTS_LIST;

    this.LANGUAGE_TABLE = props.languageState.payload.TABLE;
  }

  componentDidMount() {
    this.props.getAdmins({isFilterParam: true});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.meta !== this.props.meta) {
      this.setState({meta: this.props.meta});
    }
    if (prevProps.filter !== this.props.filter) {
      this.setState({filter: this.props.filter});
    }
  }
  getAgencyName = () => {
    const id = get(this.state.data, 'agency_id');
    const agenciesList = this.props.agenciesList;
    let currentAgencyName = '';
    agenciesList.forEach((el) => {
      if (el.id === id) {
        currentAgencyName = el.name;
      }
    });
    return currentAgencyName;
  };

  renderOps = ({value, item}) => {
    return value ? (
      <Link
        to={`${CLIENT_PAGES.OPERATIONS}?filters={"client":{"id":${item.id},"name":"${item.name}"}}`}
        className="link main-text"
      >
        {value}
      </Link>
    ) : (
      value
    );
  };

  renderClient = ({value, item}) => {
    return (
      <Link to={`${getClientPage(item)}/${item.id}`} className="link main-text display-block break-word">
        {value}
      </Link>
    );
  };

  renderName = ({value}) => {
    return <div className="break-word">{value}</div>;
  };

  openFilter = () => {
    this.setState({isFilterVisible: true});
  };

  closeFilter = () => {
    this.setState({isFilterVisible: false});
  };

  static renderStatusPopup(isAgency, agency, companies, id) {
    let popup = null;

    if (agency) {
      popup = (
        <div>
          <p className="main-text">{LANGUAGE.CLIENTS_OF_AGENCY}</p>
          <Link className="link main-text break-word display-block" to={`${CLIENT_PAGES.AGENCIES}/${agency.id}`}>
            {agency.name}
          </Link>
        </div>
      );
    } else if (companies && companies.length) {
      const displayCompanies = companies
        ? companies.slice(0, 10).sort((prev, next) => prev.name.localeCompare(next.name))
        : [];

      popup = (
        <div>
          <p className="main-text">{LANGUAGE.CLIENTS_OF_AGENCY}:</p>
          {displayCompanies.map((el, index) => (
            <div key={index} title={el.name}>
              <Link className="link main-text ellipsis-text display-block" to={`${CLIENT_PAGES.COMPANIES}/${el.id}`}>
                {el.name}
              </Link>
            </div>
          ))}
          {companies && companies.length > 10 && (
            <div>
              <Link className="link main-text break-word display-block" to={`${CLIENT_PAGES.AGENCIES}/${id}`}>
                {LANGUAGE.SEE_ALL_CLIENTS}
              </Link>
            </div>
          )}
        </div>
      );
    }
    return popup;
  }

  handleShowModalClick = () => {
    this.setState({
      showModalCreate: !this.state.showModalCreate,
    });
  };

  updateUsers = (data, id, cb) => {
    const COMPANY_JOINS = getQueryParams({
      include: [
        'poc_admin_user',
        'subsidiary',
        'leads',
        'poc_membership',
        'subcategory',
        'places.place_accesses.accessable.client_user',
        'regions.places',
        'regions.region_accesses.accessable.client_user',
        'agency',
        'poc_agency_membership',
        'company_accesses.agency_membership',
      ],
    });
    return fetch(`${API_PATH}${API_METHODS.COMPANIES}/${id}?${COMPANY_JOINS}`, {
      method: 'PATCH',
      headers: getAuthHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => (cb ? cb(response) : null));
  };

  saveMembers = (members) => {
    this.setState({members});
  };

  handleCancelClick = () => {
    this.setState({
      showModalCreate: false,
    });
  };

  handleSaveContinueClick = (data) => {
    this.setState({data});

    if (this.props.agencyUsers.payload.length) {
      this.setState({
        showModalCreate: false,
        showModalSelect: true,
      });
    } else {
      this.setState({
        showModalCreate: false,
        showModalNoSelect: true,
      });
    }
  };

  handleBackClick = () => {
    this.setState({
      showModalCreate: true,
      showModalSelect: false,
    });
  };

  handleCloseSelectClick = () => {
    this.setState({
      showModalSelect: false,
    });
  };

  mapMembers = (list) => {
    return list.filter(({checked}) => checked).map((el) => ({agency_membership_id: el.id}));
  };

  handleCreateSelectClick = (members) => {
    const innerObj = Object.assign({}, {company_accesses: this.mapMembers(members)});
    const cb = (response) => {
      if (!response.errors) {
        this.setState({
          showModalSelect: false,
          showModalNoSelect: false,
        });
        this.setState({data: {}, members: []});
        this.props.reset('NewClientModalForm');
        this.props.history.push(`${CLIENT_PAGES.COMPANIES}/${response.company.id}`);
      }
    };

    this.updateUsers({company: innerObj}, this.state.data.id, cb);
  };

  handleBackNoSelectClick = () => {
    this.setState({
      showModalSelect: false,
      showModalNoSelect: false,
      showModalCreate: true,
    });
  };

  handleCloseNoSelectClick = () => {
    this.setState({
      showModalNoSelect: false,
    });
  };

  handleCreateNoSelectClick = () => {
    const id = this.state.data.id;
    this.setState({data: {}, members: []});
    this.props.reset('NewClientModalForm');
    this.setState({
      showModalNoSelect: false,
    });
    this.props.history.push(`${CLIENT_PAGES.COMPANIES}/${id}`);
  };

  // correction - no_operations_num & no_operations belongs to one filter.
  checkOperationFilter = (data) => {
    delete data.no_operations_num;
    return data;
  };

  renderStatus({value: status, item: row}) {
    const isAgency = status === 'Agency';
    const isDataPresent = row.agency || (row.companies && row.companies.length);
    const isComment = !!row.comment;

    return (
      <div className="theme-color-1 clearfix">
        <Popover
          className="pull-left"
          shiftLeft={-4}
          arrowOffset={80}
          position="bottom"
          flipOptions={{
            mainAxis: true,
          }}
          contentClassName={b('status-popup')}
          overlayInnerStyle={{
            width: 211,
          }}
          overlay={
            isDataPresent ? (
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMax: 150,
                }}
              >
                <div className="custom-scrollbars__scroll-content">
                  {ClientsDataGrid.renderStatusPopup(isAgency, row.agency, row.companies, row.id)}
                </div>
              </CustomScrollbars>
            ) : (
              ''
            )
          }
        >
          {status === 'Agency' && <Icon name="status-1" />}
          {status === 'Client of agency' && <Icon name="status-2" />}
        </Popover>
        {isComment && (
          <Popover
            className="pull-right"
            shiftLeft={-4}
            arrowOffset={80}
            contentClassName={b('status-popup')}
            position="bottom"
            overlayInnerStyle={{
              width: 211,
            }}
            overlay={
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMax: 105,
                }}
              >
                <div className="main-text">{row.comment}</div>
              </CustomScrollbars>
            }
          >
            <Icon name="message" />
          </Popover>
        )}
      </div>
    );
  }

  renderOngoingOps({value: operations, item: client}) {
    if (!Array.isArray(operations)) {
      return null;
    }

    const outGoing = operations?.map(({id, code, name}, index) => {
      return (
        <li key={index} className="operations-list__item">
          <Link
            to={`${getClientPage(client)}/${client.id}${CLIENT_PAGES.OPERATIONS}/${id}`}
            className="operations-list__code display-block break-word"
          >
            {code}
          </Link>
          <div className="operations-list__name break-word">{name}</div>
        </li>
      );
    });

    return (
      <CustomScrollbars
        scrollbarProps={{
          autoHeightMax: 140,
        }}
      >
        <div className="custom-scrollbars__scroll-content">
          <ul className="operations-list">{outGoing}</ul>
        </div>
      </CustomScrollbars>
    );
  }

  renderCreateNewButton = () => {
    const isAdmin = this.props.userState === APP_ROLES.ADMIN || this.props.userState === APP_ROLES.SUPER_ADMIN;
    return (
      isAdmin && (
        <button className="button button--bg-1 pull-right" onClick={this.handleShowModalClick}>
          <Icon name="plus" className="button__icon" />
          <span>{LANGUAGE.ADD_NEW_BUTTON}</span>
        </button>
      )
    );
  };

  renderToolbar = () => (
    <div className={cn('flex-container flex-justify-between flex-align-bottom', b('toolbar'))}>
      <div>
        <div>
          <SearchField
            cssModifier={cn('input', b('search-input'))}
            placeholder={LANGUAGE.SEARCH_PLACEHOLDER}
            onSearch={this.props.onSearchChange}
            onClear={this.props.onSearchClear}
            showClear={this.props.showClear}
            searchDefault={this.props.searchDefault}
          />

          <button onClick={this.openFilter} className="button button--bg-1">
            <Icon name="filter" className="button__icon" />
            <span>
              {`${LANGUAGE.OPEN_FILTER_BUTTON}
                  (${Object.keys(this.checkOperationFilter(mapFilter(this.state.filter))).length})`}
            </span>
          </button>
          <span className={b('search-result-num')}>
            <span className="main-text main-text--bold">{this.state.meta.total_count}</span>
            <span className="main-text">{` ${this.LANGUAGE_TABLE.RESULTS}`}</span>
          </span>
        </div>
        <div className={b('filter-labels')}>
          {this.state.filter &&
            this.state.filter.poc_admin_user_id &&
            this.state.filter.poc_admin_user_id.value !== '0' && (
              <FilterLabel
                text={`${LANGUAGE.POC_BUZZEO}: ${this.state.filter.poc_admin_user_id.label}`}
                onDelete={() => this.props.onFilterFieldClear(['poc_admin_user_id'])}
              />
            )}
          {this.state.filter &&
            this.state.filter.no_operations &&
            this.state.filter.no_operations.value !== '0' &&
            this.state.filter.no_operations_num && (
              <FilterLabel
                text={`${LANGUAGE.NO_OF_OPERATION}: ${
                  this.state.filter.no_operations.value === 'lt' ? 'Less than' : 'More than'
                }
                     ${this.state.filter.no_operations_num}`}
                onDelete={() => this.props.onFilterFieldClear(['no_operations', 'no_operations_num'])}
              />
            )}
          {this.state.filter && this.state.filter.type && (
            <FilterLabel
              text={`${LANGUAGE.STATUS}: ${this.state.filter.type === 'Agency' ? 'AGENCY' : 'CLIENT OF AGENCY'}`}
              onDelete={() => this.props.onFilterFieldClear(['type'])}
            />
          )}
          {this.state.filter && this.state.filter.stores && (
            <FilterLabel
              text={`${LANGUAGE.STORES}: ${this.state.filter.stores === 'false' ? 'NO STORES' : 'WITH STORES'}`}
              onDelete={() => this.props.onFilterFieldClear(['stores'])}
            />
          )}
          {this.state.filter && this.state.filter.comments && (
            <FilterLabel
              text={`${LANGUAGE.COMMENTS}: ${this.state.filter.comments === '0' ? 'NO COMMENTS' : 'WITH COMMENTS'}`}
              onDelete={() => this.props.onFilterFieldClear(['comments'])}
            />
          )}
          {Object.keys(this.checkOperationFilter(mapFilter(this.state.filter))).length > 0 && (
            <button onClick={this.props.onFilterClear} className="button button--bg-1">
              {LANGUAGE.CLEAR_FILTER_BUTTON}
            </button>
          )}
        </div>
      </div>

      {this.renderCreateNewButton()}
    </div>
  );

  render() {
    const {meta, onPageChange, data, onSortChange, agencyUsers, onFilterChange, adminsList, loading} = this.props;

    const isUserListInited = agencyUsers.type === AGENCY_USERS_LIST_LOADED;
    const {isFilterVisible, members} = this.state;

    return (
      <div className="pos-rel">
        <NewClientModal
          show={this.state.showModalCreate}
          onClose={this.handleShowModalClick}
          onCancel={this.handleCancelClick}
          data={this.state.data}
          onSaveContinue={this.handleSaveContinueClick}
          adminsList={adminsList}
        />

        {isUserListInited && (
          <NewClientSelectUsersModal
            show={this.state.showModalSelect}
            onBack={this.handleBackClick}
            onClose={this.handleCloseSelectClick}
            onCreate={this.handleCreateSelectClick}
            onSave={this.saveMembers}
            data={this.state.data}
            members={members}
            agencyName={this.getAgencyName()}
          />
        )}

        <NewClientNoSelectModal
          show={this.state.showModalNoSelect}
          data={this.state.data}
          onBack={this.handleBackNoSelectClick}
          onClose={this.handleCloseNoSelectClick}
          onCreate={this.handleCreateNoSelectClick}
        />
        <ClientsFiltersModal
          show={isFilterVisible}
          onClose={this.closeFilter}
          onFilterChange={onFilterChange}
          filter={this.state.filter}
          adminsList={adminsList}
        />

        <div className={b('toolbar-wrapper')}>
          {this.renderToolbar()}
          <PerPageDropdown
            onChange={(pages) => {
              onPageChange({page: 1, perPage: pages});
            }}
            value={String(this.props.perPage)}
            simpleValue
          />
        </div>

        <ClientTable
          data={data}
          onSortChange={onSortChange}
          loading={loading}
          loadingColor="visuals"
          sortField={this.props.sort?.name}
          sortOrder={this.props.sort?.order}
          columns={[
            {
              name: 'name',
              path: 'name',
              render: this.renderClient,
              label: LANGUAGE.CLIENT_NAME_TABLE_COLUMN,
              sortable: true,
              width: '245',
            },
            {
              name: 'poc_admin_user_full_name',
              path: 'poc_admin_user_full_name',
              render: this.renderName,
              label: LANGUAGE.POC_BUZZEO_TABLE_COLUMN,
              sortable: true,
              width: '236',
            },
            {
              path: 'postFromClient',
              label: LANGUAGE.POC_CLIENT_TABLE_COLUMN,
              width: '170',
              render: this.renderName,
            },
            {
              path: 'stores',
              width: '70',
              label: LANGUAGE.STORES_TABLE_COLUMN,
            },
            {
              name: 'operations_count',
              path: 'operations_count',
              label: LANGUAGE.NO_OPS_TABLE_COLUMN,
              width: '140',
              sortable: true,
              render: this.renderOps,
            },
            {
              name: 'operations',
              path: 'operations',
              sortable: true,
              width: '189',
              label: (
                <>
                  <div className="ellipsis-text">{LANGUAGE.ONGOING_OPS_TABLE_COLUMN_1}</div>
                  <div className={b('subheader-text')}>{LANGUAGE.ONGOING_OPS_TABLE_COLUMN_2}</div>
                </>
              ),
              render: this.renderOngoingOps,
            },
            {
              name: 'type',
              path: 'type',
              sortable: true,
              width: '120',
              label: LANGUAGE.STATUS_TABLE_COLUMN,
              render: this.renderStatus,
            },
          ]}
        />

        <div className={b('status-types-panel')}>
          <span className={b('status-type')}>
            <Icon name="status-1" />
            <span className={b('status-type-name')}>{LANGUAGE.STATUS_CLARIFICATION_1}</span>
          </span>
          <span className={b('status-type')}>
            <Icon name="status-2" />
            <span className={b('status-type-name')}>{LANGUAGE.STATUS_CLARIFICATION_2}</span>
          </span>
        </div>
        <PaginationBar
          data={data}
          currentPage={meta.current_page}
          totalPages={meta.total_pages}
          totalItems={meta.total_count}
          perPage={this.props.perPage}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    languageState: state.languageState,
    agencyUsers: state.agencyUsers,
    userState: state.userState.payload.role,
    agenciesList: selectAllAgencies(state),
    adminsList: state.adminsList,
  }),
  {
    reset,
    getAdmins,
  },
)(withRouter(ClientsDataGrid));
