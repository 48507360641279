import moment from 'moment';

import {prettyJoin} from 'client/services/helpers.js';

export const getFullName = (firstName, lastName) => {
  return prettyJoin([firstName, lastName]);
};

export const getAge = (birthDate) => {
  let years = '';
  if (birthDate) {
    const birthDateMoment = moment(birthDate);
    years = moment().diff(birthDateMoment, 'years');
  }

  return years;
};
