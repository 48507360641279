import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {formValueSelector} from 'redux-form';

import {transformQueryParamsForExport} from 'client/services/exportHelpers';
import {getClientPage} from 'client/services/helpers';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getBroadcast} from 'client/ducks/broadcast/actions';
import {selectBroadcast} from 'client/ducks/broadcast/selectors';
import {getClientUsersWithMembershipsAction} from 'client/ducks/client-users/actions';
import {selectClientUsers} from 'client/ducks/client-users/selectors';
import {getClientsWithLeads, getClient} from 'client/ducks/clients-list/actions';
import {getAvailableColumnAdaptersByClient} from 'client/ducks/column-adapters/actions';
import {selectGeneralLeadColumns} from 'client/ducks/column-adapters/selectors';
import {getCustomColumns} from 'client/ducks/custom-columns/actions';
import {getDisplayColumns, updateLeadDisplayItemsBulk} from 'client/ducks/lead-display-items/actions';
import {getColumnVisibilityItems} from 'client/ducks/lead-display-items/actions';
import {selectColumnVisibilityItems} from 'client/ducks/lead-display-items/selectors';
import {getLeads} from 'client/ducks/leads-list/actions';
import {
  selectLeadsMailingTableMapped,
  selectLeadsMeta,
  selectClientName,
  selectClient,
  selectColumnAdaptersForImportExport,
} from 'client/ducks/leads-list/selectors';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';

import TitleBlock from 'client/components/common/title-block';

import AddNewColumnModal from 'client/components/leads/components/modals/add-new-column-modal';
import LeadsClientVisibilitySettingsModal from 'client/components/leads/components/modals/leads-client-visibility-settings-modal/index';
import LeadsExportTypeModal from 'client/components/leads/components/modals/leads-export-type-modal';
import LeadsFiltersModal from 'client/components/leads/components/modals/leads-filters-modal/leads-filters-modal';
import LeadsGenerateApiExportModal from 'client/components/leads/components/modals/leads-generate-api-export-modal';
import LeadsGenerateApiImportModal from 'client/components/leads/components/modals/leads-generate-api-import-modal';
import LeadsGenerateExportModal from 'client/components/leads/components/modals/leads-generate-export-modal';
import LeadsGenerateImportModal from 'client/components/leads/components/modals/leads-generate-import-modal';
import LeadsImportFileModal from 'client/components/leads/components/modals/leads-import-file-modal';
import LeadsImportTypeModal from 'client/components/leads/components/modals/leads-import-type-modal';
import LeadsPortSummaryModal from 'client/components/leads/components/modals/leads-port-summary-modal';
import LeadsTableSettingsModal from 'client/components/leads/components/modals/leads-table-settings-modal/leads-table-settings-modal';

import {mapFilter, mapFilterForUrl} from './helper';

import LeadsMainDataGrid from '../components/tables/leads-main-data-grid/leads-main-data-grid';

class LeadsList extends ReactQueryParams {
  static defaultProps = {
    broadcast: {},
    clientId: '',
    allColumns: [],
  };

  static propTypes = {
    getAvailableColumnAdaptersByClient: PropTypes.func.isRequired,
    updateLeadDisplayItemsBulk: PropTypes.func.isRequired,
    columnVisibilityItems: PropTypes.array.isRequired,
    leadDisplayItems: PropTypes.object.isRequired,
    getDisplayColumns: PropTypes.func.isRequired,
    getCustomColumns: PropTypes.func.isRequired,
    isNational: PropTypes.bool.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    generalColumns: PropTypes.array.isRequired,
    languageState: PropTypes.object.isRequired,
    ...withRouter.propTypes,
    getClient: PropTypes.func.isRequired,
    getLeads: PropTypes.func.isRequired,
    clientName: PropTypes.string,
    importType: PropTypes.string,
    exportType: PropTypes.string,
    allColumns: PropTypes.array,
    isAdmin: PropTypes.bool.isRequired,
    broadcast: PropTypes.object,
    clientId: PropTypes.string,
    clientUsers: PropTypes.array.isRequired,
    getClientUsersWithMemberships: PropTypes.func.isRequired,
  };

  static DEFAULT_SORT_FIELD = 'first_name';
  static DEFAULT_SORT_ORDER = 'asc';
  static DEFAULT_FIRST_PAGE = 1;
  static DEFAULT_PER_PAGE = 5;
  static DEFAULT_CUSTOM_COLUMNS_PER_PAGE = 100;

  static MODALS_MAP = {
    FILTERS: 'FILTERS',
    TABLE_SETTINGS: 'TABLE_SETTINGS',
    CLIENT_VISIBILITY_SETTINGS: 'CLIENT_VISIBILITY_SETTINGS',
    NEW_COLUMN: 'NEW_COLUMN',
    PARTICIPATIONS: 'PARTICIPATIONS',
    IMPORT_MODALS: {
      GENERATE_API_IMPORT_MODAL: 'GENERATE_API_IMPORT_MODAL',
      GENERATE_IMPORT_MODAL: 'GENERATE_IMPORT_MODAL',
      IMPORT_FILE_MODAL: 'IMPORT_FILE_TYPE',
      IMPORT_TYPE_MODAL: 'IMPORT_TYPE_MODAL',
      IMPORT_SUMMARY_MODAL: 'IMPORT_SUMMARY_MODAL',
    },
    EXPORT_MODALS: {
      EXPORT_TYPE_MODAL: 'EXPORT_TYPE_MODAL',
      GENERATE_API_EXPORT_MODAL: 'GENERATE_API_EXPORT_MODAL',
      GENERATE_EXPORT_MODAL: 'GENERATE_EXPORT_MODAL',
    },
  };

  constructor(props) {
    super(props);
    this.LANGUAGE = props.languageState.payload.LEADS_LIST;
    this.generalColumns = [
      this.LANGUAGE.FIRST_NAME,
      this.LANGUAGE.LAST_NAME,
      this.LANGUAGE.EMAIL,
      this.LANGUAGE.PHONE,
      'custom_id',
      'custom_client_id',
    ];

    this.state = {
      page: LeadsList.DEFAULT_PAGE,
      perPage: LeadsList.DEFAULT_PER_PAGE,
      sort: {
        name: '',
        order: '',
      },
      search: '',
      showClear: false,
      searchDefault: '',
      filter: {},
      visibleModal: '',
      isAll: false,
      checkedLeads: [],
      loading: false,
      loadingColumns: false,
    };
  }

  componentDidMount() {
    this.setState({loading: true});

    if (!this.props.clientId) {
      this.getLeads();
    }
    this.checkEmptyDisplayedColumns();

    this.props.getClientsWithLeads();
    this.props.getCustomColumns(this.props.clientId, true);

    if (this.props.clientId) {
      this.props
        .getClient(this.props.clientId, {
          include: ['dashboards', 'subsidiary'],
        })
        .then(() => {
          this.getLeads()
            .then(() => {
              this.updateBreadcrumbs();
            })
            .catch((error) => {
              if (error.status === 404) {
                this.props.history.push(CLIENT_PAGES.ACCESS_DENIED);
              }
            });
        });
    } else {
      this.updateBreadcrumbs();
    }

    if (this.queryParams.broadcast_list_id) {
      this.props.getBroadcast(this.queryParams.broadcast_list_id).then(() => {
        this.updateBreadcrumbs();
      });
    }

    this.props.getClientUsersWithMemberships(this.props.clientId);
  }

  componentDidUpdate() {
    this.checkedLeads = [];
  }

  componentWillUnmount() {
    this.checkedLeads = [];
    this.props.clearBreadcrumbs();
  }

  checkEmptyDisplayedColumns = async () => {
    const {clientId} = this.props;

    if (!clientId) {
      return;
    }

    this.setState({loadingColumns: true});

    await Promise.all([
      this.props.getDisplayColumns(clientId),
      this.props.getAvailableColumnAdaptersByClient(clientId),
      this.props.getColumnVisibilityItems(clientId),
    ]);

    this.setState({loadingColumns: false});

    // if (this.props.leadDisplayItems.payload.length === 0 && this.props.columnVisibilityItems.length) {
    //   this.props.updateLeadDisplayItemsBulk({
    //     lead_display_item: this.props.generalColumns.map(({id}, index) => ({
    //       client_id: clientId,
    //       column_adapter_id: id,
    //       position: index + 1
    //     }))
    //   }, {
    //     include: ['column_adapter']
    //   });
    // }
  };

  updateBreadcrumbs = () => {
    const {
      isNational,
      languageState: {
        payload: {BREADCRUMBS},
      },
    } = this.props;

    if (!this.props.clientId && !this.queryParams.broadcast_list_id) {
      return [];
    }

    let breadcrumbs = [
      {
        href: CLIENT_PAGES.VISUALS,
        name: BREADCRUMBS.VISUALS,
        hidden: isNational,
      },
      {
        href: CLIENT_PAGES.CLIENTS_LIST,
        name: BREADCRUMBS.CLIENTS,
        hidden: isNational,
      },
      {
        name: BREADCRUMBS.LEADS,
        hidden: isNational,
      },
    ];

    if (this.props.clientId) {
      breadcrumbs = breadcrumbs.slice(0, -1).concat([
        {
          href: getClientPage(this.props.client) + '/' + this.props.client.id,
          name: this.props.clientName,
          hidden: isNational,
        },
        {
          name: BREADCRUMBS.LEADS,
          hidden: isNational,
        },
      ]);
    }

    if (this.queryParams.broadcast_list_id) {
      const {broadcast} = this.props;

      breadcrumbs = breadcrumbs.slice(0, -1).concat([
        {
          name: `${BREADCRUMBS.LEADS_OF} ${broadcast.name}`,
          hidden: isNational,
        },
      ]);
    }

    return this.props.setBreadcrumbs(breadcrumbs);
  };

  mapSortName = (name) => {
    const map = {
      first_source: 'first_source_created_at',
      last_export: 'last_export_created_at',
    };
    return map[name] || name;
  };

  getLeads = () => {
    const {queryParams} = this;

    this.setState({loading: true});

    const sort =
      `${(queryParams.sort && this.mapSortName(queryParams.sort.name)) || LeadsList.DEFAULT_SORT_FIELD} ` +
      `${(queryParams.sort && queryParams.sort.order) || LeadsList.DEFAULT_SORT_ORDER}`;

    const search = queryParams.search;

    const params = {
      include_lead_expires_at: null,
      include: {
        client: null,
        opt_in_columns: null,
        last_participation: {
          operation: {client: null},
        },
        participations: {
          operation: {client: null},
          interface: null,
        },
        first_source: null,
      },
      q: {
        s: sort,
        first_name_or_last_name_cont: search,
      },
      page: queryParams.page || LeadsList.DEFAULT_FIRST_PAGE,
      per_page: queryParams.perPage || LeadsList.DEFAULT_PER_PAGE,
    };

    if (queryParams.filter) {
      const {q, distinct} = mapFilterForUrl(mapFilter(queryParams.filter));

      params.distinct = distinct;
      params.q = {
        ...params.q,
        ...q,
      };
      params.q.g = [params.q.g];
    }

    if (queryParams.broadcast_list_id) {
      params.q = {
        ...params.q,
        broadcast_recipients_broadcast_list_id_eq: queryParams.broadcast_list_id,
      };
    }

    if (queryParams.automation_task_id) {
      params.q = {
        ...params.q,
        distinct: true,
        participations_interaction_interface_automation_task_id_eq: queryParams.automation_task_id,
      };
    }

    if (['last_export_created_at', 'first_source_created_at'].some((i) => sort.includes(i))) {
      delete params.distinct;
    }

    window.scrollTo(0, 0);

    return this.props.getLeads(params, this.props.clientId).then(() => {
      this.setState({loading: false});
      return Promise.resolve();
    });
  };

  onSortChange = (name, order) => {
    this.setQueryParams(
      {
        filter: mapFilter(this.queryParams.filter),
        sort: {name, order},
      },
      true,
    );
    this.getLeads();
  };

  onPageChange = ({page, perPage}) => {
    this.setQueryParams(
      {
        filter: mapFilter(this.queryParams.filter),
        page,
        perPage,
      },
      true,
    );
    this.getLeads();
  };

  onSearchChange = (search) => {
    this.setQueryParams(
      {
        filter: mapFilter(this.queryParams.filter),
        search,
        page: LeadsList.DEFAULT_FIRST_PAGE,
      },
      true,
    );
    this.getLeads();
  };

  onSearchClear = () => {
    this.setQueryParams(
      {
        filter: mapFilter(this.queryParams.filter),
        search: '',
      },
      true,
    );
    this.getLeads();
  };

  onFilterChange = (filter) => {
    this.setQueryParams(
      {
        filter: mapFilter(filter),
        page: 1,
      },
      true,
    );
    this.getLeads();
  };

  onFilterClear = () => {
    this.onFilterChange({});
  };

  onFilterFieldClear = (fieldsList, value = false) => {
    const obj = {...this.queryParams.filter};

    if (value) {
      obj[fieldsList] = obj[fieldsList].filter((item) => item.value !== value);
    } else {
      delete obj[fieldsList];
    }

    this.onFilterChange(obj);
  };

  handleShowModal = (name, data) => {
    this.setState({visibleModal: name, ...data});
  };

  handleCloseModal = () => this.setState({visibleModal: '', isAll: false});

  getCheckedLeads = (checkedLeads) => {
    this.setState({checkedLeads});
  };

  handleCancelImport = () => {
    this.handleShowModal(LeadsList.MODALS_MAP.IMPORT_MODALS.IMPORT_TYPE_MODAL);
  };
  handleCancelExport = () => {
    this.handleShowModal(LeadsList.MODALS_MAP.EXPORT_MODALS.EXPORT_TYPE_MODAL);
  };

  getExportQueryParams = () => {
    const queryParams = {...mapFilterForUrl(mapFilter(this.queryParams.filter))};

    return {
      query_params: transformQueryParamsForExport(queryParams),
      query_string: this.queryParams.search,
    };
  };

  render() {
    const {leadsList: data, meta, customColumns, clientId, isAdmin, leadDisplayItems, clientUsers} = this.props;
    const {visibleModal, loading, loadingColumns} = this.state;
    const {queryParams} = this;

    const isPossibleToAddColumn =
      customColumns.payload.all_lead_columns.length < LeadsList.DEFAULT_CUSTOM_COLUMNS_PER_PAGE;

    return (
      <div className="theme-color-2">
        {clientId && (
          <LeadsTableSettingsModal
            show={visibleModal === LeadsList.MODALS_MAP.TABLE_SETTINGS}
            clientId={clientId}
            onClose={this.handleCloseModal}
          />
        )}
        {clientId && (
          <LeadsClientVisibilitySettingsModal
            show={visibleModal === LeadsList.MODALS_MAP.CLIENT_VISIBILITY_SETTINGS}
            clientId={clientId}
            onClose={this.handleCloseModal}
            onCancel={this.handleCloseModal}
          />
        )}
        {isPossibleToAddColumn && clientId && (
          <AddNewColumnModal
            show={visibleModal === LeadsList.MODALS_MAP.NEW_COLUMN}
            onClose={this.handleCloseModal}
            onConfirm={() => {
              return Promise.all([this.getLeads(), this.props.getCustomColumns(this.props.clientId, true)]).then(() => {
                this.handleCloseModal();
                this.handleShowModal(LeadsList.MODALS_MAP.TABLE_SETTINGS);
              });
            }}
            clientId={this.props.clientId}
          />
        )}
        <LeadsFiltersModal
          show={visibleModal === LeadsList.MODALS_MAP.FILTERS}
          onClose={this.handleCloseModal}
          onFilterChange={this.onFilterChange}
          clientId={this.props.clientId}
          filter={queryParams.filter}
        />
        <LeadsImportFileModal
          show={visibleModal === LeadsList.MODALS_MAP.IMPORT_MODALS.IMPORT_FILE_MODAL}
          onClose={this.handleCloseModal}
          onCancel={this.handleCancelImport}
          onConfirm={() => this.handleShowModal(LeadsList.MODALS_MAP.IMPORT_MODALS.IMPORT_SUMMARY_MODAL)}
        />
        <LeadsPortSummaryModal
          show={visibleModal === LeadsList.MODALS_MAP.IMPORT_MODALS.IMPORT_SUMMARY_MODAL}
          onClose={this.handleCloseModal}
        />
        <LeadsGenerateImportModal
          show={visibleModal === LeadsList.MODALS_MAP.IMPORT_MODALS.GENERATE_IMPORT_MODAL}
          onCancel={this.handleCancelImport}
          onClose={this.handleCloseModal}
          clientId={clientId}
          columns={this.props.allColumns}
          generalColumns={this.generalColumns}
        />
        <LeadsGenerateApiImportModal
          show={visibleModal === LeadsList.MODALS_MAP.IMPORT_MODALS.GENERATE_API_IMPORT_MODAL}
          onCancel={this.handleCancelImport}
          onClose={this.handleCloseModal}
          clientId={Number(clientId)}
          columns={this.props.allColumns}
          generalColumns={this.generalColumns}
          users={clientUsers}
        />
        <LeadsImportTypeModal
          show={visibleModal === LeadsList.MODALS_MAP.IMPORT_MODALS.IMPORT_TYPE_MODAL}
          onConfirm={() => this.handleShowModal(this.props.importType)}
          onClose={this.handleCloseModal}
          modals={LeadsList.MODALS_MAP.IMPORT_MODALS}
          isAdmin={isAdmin}
        />
        <LeadsExportTypeModal
          show={visibleModal === LeadsList.MODALS_MAP.EXPORT_MODALS.EXPORT_TYPE_MODAL}
          onConfirm={() => this.handleShowModal(this.props.exportType)}
          onClose={this.handleCloseModal}
          modals={LeadsList.MODALS_MAP.EXPORT_MODALS}
          isAdmin={isAdmin}
        />
        <LeadsGenerateExportModal
          show={visibleModal === LeadsList.MODALS_MAP.EXPORT_MODALS.GENERATE_EXPORT_MODAL}
          onClose={this.handleCloseModal}
          onCancel={this.handleCancelExport}
          clientId={clientId}
          isAll={this.state.isAll}
          columns={this.props.allColumns}
          checkedLeads={this.state.checkedLeads}
          generalColumns={this.generalColumns}
          users={clientUsers}
          queryParams={this.getExportQueryParams()}
        />
        <LeadsGenerateApiExportModal
          show={visibleModal === LeadsList.MODALS_MAP.EXPORT_MODALS.GENERATE_API_EXPORT_MODAL}
          onClose={this.handleCloseModal}
          onCancel={this.handleCancelExport}
          clientId={Number(clientId)}
          columns={this.props.allColumns}
          users={clientUsers}
          isAll={this.state.isAll}
          queryParams={this.getExportQueryParams()}
          checkedLeads={this.state.checkedLeads}
        />
        <div className="page__title-block">
          <TitleBlock theme={true}>
            <TitleBlock.Item>{`${this.LANGUAGE.PAGE_TITLE}`}</TitleBlock.Item>
          </TitleBlock>
        </div>
        <div>
          <LeadsMainDataGrid
            data={data}
            meta={meta}
            filter={queryParams.filter}
            clientId={this.props.clientId}
            onPageChange={this.onPageChange}
            onSortChange={this.onSortChange}
            onSearchChange={this.onSearchChange}
            onSearchClear={this.onSearchClear}
            showClear={!!queryParams.search}
            searchDefault={queryParams.search || ''}
            onFilterShow={() => this.handleShowModal(LeadsList.MODALS_MAP.FILTERS)}
            showImport={() => this.handleShowModal(LeadsList.MODALS_MAP.IMPORT_MODALS.IMPORT_TYPE_MODAL)}
            showExport={() => this.handleShowModal(LeadsList.MODALS_MAP.EXPORT_MODALS.EXPORT_TYPE_MODAL)}
            showExportAll={() =>
              this.handleShowModal(LeadsList.MODALS_MAP.EXPORT_MODALS.EXPORT_TYPE_MODAL, {
                isAll: true,
              })
            }
            onAddNewColumnShow={() => this.handleShowModal(LeadsList.MODALS_MAP.NEW_COLUMN)}
            onFilterChange={this.onFilterChange}
            onFilterClear={this.onFilterClear}
            onFilterFieldClear={this.onFilterFieldClear}
            onShowTableSettings={() => this.handleShowModal(LeadsList.MODALS_MAP.TABLE_SETTINGS)}
            onShowClientVisibilitySettings={() => this.handleShowModal(LeadsList.MODALS_MAP.CLIENT_VISIBILITY_SETTINGS)}
            onLeadsDelete={this.getLeads}
            leadDisplayItems={leadDisplayItems}
            getCheckedLeads={this.getCheckedLeads}
            isAdmin={isAdmin}
            allGeneralColumns={this.props.generalColumns}
            allCustomColumns={this.props.customColumns}
            isPossibleToAddColumn={isPossibleToAddColumn}
            loading={loading}
            loadingColumns={loadingColumns}
            perPage={queryParams.perPage}
            sort={queryParams.sort}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const importTypeFormSelector = formValueSelector(LeadsImportTypeModal.formName);
  const exportTypeFormSelector = formValueSelector(LeadsExportTypeModal.formName);
  return {
    languageState: state.languageState,
    leadsList: selectLeadsMailingTableMapped(state).tableData,
    leadDisplayItems: state.leadDisplayItems,
    columnVisibilityItems: selectColumnVisibilityItems(state),
    customColumns: state.customColumns,
    broadcast: selectBroadcast(state),
    meta: selectLeadsMeta(state),
    clientName: selectClientName(state),
    client: selectClient(state),
    allColumns: selectColumnAdaptersForImportExport(state),
    generalColumns: selectGeneralLeadColumns(state),
    importType: importTypeFormSelector(state, 'import'),
    exportType: exportTypeFormSelector(state, 'export'),
    isAdmin: selectIsAdmin(state),
    clientUsers: selectClientUsers(state, ownProps.clientId),
  };
};

const mapDispatchToProps = {
  getClient,
  getLeads,
  setBreadcrumbs,
  clearBreadcrumbs,
  getClientsWithLeads,
  getDisplayColumns,
  getCustomColumns,
  getAvailableColumnAdaptersByClient,
  getColumnVisibilityItems,
  updateLeadDisplayItemsBulk,
  getBroadcast,
  getClientUsersWithMemberships: getClientUsersWithMembershipsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeadsList));
