import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {dateToString, isDateValid} from 'client/services/helpers';

import './date-cell.scss';

const b = bem('date-cell');
const defaultDateFormat = 'DD/MM/YYYY';
const defaultTimeFormat = 'HH:mm:ss';

const DateCell = (props) => {
  const {
    value,
    dateFormat = defaultDateFormat,
    timeFormat = defaultTimeFormat,
    className,
    dateClassName,
    timeClassName,
    hideTime,
    isColumn,
  } = props;

  if (!isDateValid(value)) {
    return null;
  }

  const date = dateToString(value, dateFormat);
  const time = dateToString(value, timeFormat);

  return (
    <div className={cn(b({column: isColumn}), className)} title={`${date} ${time}`}>
      <span className={cn(b('date'), dateClassName)}>{date}</span>
      {!hideTime && <span className={cn(b('time'), timeClassName)}>{time}</span>}
    </div>
  );
};

DateCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  className: PropTypes.string,
  dateClassName: PropTypes.string,
  timeClassName: PropTypes.string,
  isColumn: PropTypes.bool,
  hideTime: PropTypes.bool,
};

DateCell.defaultProps = {
  dateFormat: defaultDateFormat,
  timeFormat: defaultTimeFormat,
  className: '',
  dateClassName: '',
  timeClassName: '',
  hideTime: false,
  isColumn: false,
};

export default DateCell;
