import React from 'react';

import PropTypes from 'prop-types';

function PlaceAddressHint({place}) {
  return (
    <div>
      <div>{place.street_address}</div>
      <div>
        {place.city_zip} {place.city_name}
      </div>
    </div>
  );
}

PlaceAddressHint.propTypes = {
  place: PropTypes.object.isRequired,
};

export default PlaceAddressHint;
