import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import TableInfo from 'client/common/table-info';

import {Prize} from 'client/models/prizes/types';

import cssModule from './prize-diy-quantities-table.module.scss';

const b = bem('prize-diy-quantities-table', {cssModule});

type PrizeDiyQuantitiesTableProps = {
  prize?: Prize | null;
};

const PrizeDiyQuantitiesTable: React.FC<PrizeDiyQuantitiesTableProps> = (props) => {
  const {prize = null} = props;
  const lang = useLanguage('PRIZES.MODALS.PRIZE_DIY_MODAL.QUANTITIES');

  const data = [
    {
      device: prize?.device_quantities?.device_initial_stock || 0,
      online: prize?.online_quantities?.online_initial_stock || 0,
      total: prize?.total_quantities?.total_initial_stock || 0,
    },
  ];

  const columns = [
    {
      name: 'device',
      header: lang.COL_DEVICES,
      path: 'device',
    },
    {
      name: 'online',
      header: lang.COL_ONLINE,
      path: 'online',
    },
    {
      name: 'total',
      header: lang.COL_TOTAL,
      path: 'total',
    },
  ];

  return (
    <TableInfo className={b()} title={lang.TITLE} columns={columns} rowNames={[lang.ROW_TOTAL_DOTATION]} data={data} />
  );
};

export default PrizeDiyQuantitiesTable;
