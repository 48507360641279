import React, {Component} from 'react';

import find from 'lodash/find';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {replaceText} from 'client/services/helpers';

import {updateWebApp} from 'client/ducks/autotask/actions';
import {
  patchInterfaceAction,
  deleteInterface,
  deleteInterfaceItem,
  createInterfaceItem,
} from 'client/ducks/interfaces/actions';

import ConfirmationModal from 'client/common/modals/confirmation-modal';

import UpdateSettingsModal from 'client/components/autotask/autotask-interfaces/components/modals/update-settings-modal';

import InterfaceItem from './interface-item';

import EditInterfaceItemsModal from '../../modals/edit-interface-items-modal';
import SetInterfaceLevelsModal from '../../modals/set-interface-levels-modal';
import UpdateInterfaceModal from '../../modals/update-interface-modal';
import UpdateNameModal from '../../modals/update-name-modal';

import './interface-list.scss';

class InterfaceList extends Component {
  static propTypes = {
    editInterface: PropTypes.func.isRequired,
    deleteInterface: PropTypes.func.isRequired,
    deleteInterfaceItem: PropTypes.func.isRequired,
    updateWebApp: PropTypes.func.isRequired,
    createInterfaceItem: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    onDeleteInterfaceConfirm: PropTypes.func.isRequired,
    fetchInterfaces: PropTypes.func.isRequired,
    interfaces: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static Modals = {
    DEACTIVATE_INTERFACE_MODAL: 'DEACTIVATE_INTERFACE_MODAL',
    ACTIVATE_INTERFACE_MODAL: 'ACTIVATE_INTERFACE_MODAL',
    UPDATE_INTERFACE_MODAL: 'UPDATE_INTERFACE_MODAL',
    UPDATE_INTERFACE_NAME_MODAL: 'UPDATE_INTERFACE_NAME_MODAL',
    EDIT_INTERFACE_ITEMS_MODAL: 'EDIT_INTERFACE_ITEMS_MODAL',
    UPDATE_SETTINGS_MODAL: 'UPDATE_SETTINGS_MODAL',
    INTERFACE_DELETE_MODAL: 'INTERFACE_DELETE_MODAL',
    SET_INTERFACE_LEVELS_MODAL: 'SET_INTERFACE_LEVELS_MODAL',
  };

  constructor(props) {
    super(props);

    this.state = {
      activeModal: null,
      interfaceCode: '',
      interfaceName: null,
      items: [],
      interfaceFamily: '',
      interfaceId: null,
      interfaceUpdateKind: '',
    };
  }

  handleActiveModalClose = () => {
    this.setState({
      activeModal: null,
    });
  };

  handleInterfaceActivationChange = (isActive, name, id) => {
    this.setState({
      interfaceName: name,
      interfaceId: id,
      activeModal: isActive
        ? InterfaceList.Modals.DEACTIVATE_INTERFACE_MODAL
        : InterfaceList.Modals.ACTIVATE_INTERFACE_MODAL,
    });
  };

  handleChangeWebAppBeta = async (item, checked) => {
    await this.props.updateWebApp(item.web_app.id, {
      web_app: {beta_enabled: checked},
    });
    this.props.fetchInterfaces();
  };

  handleChangeWebAppUpdate = async (item, checked) => {
    await this.props.updateWebApp(item.web_app.id, {
      web_app: {update_enabled: checked},
    });
    this.props.fetchInterfaces();
  };

  handleCloseModal = () =>
    this.setState({
      activeModal: null,
    });

  changeInterfaceActivation = () => {
    this.props
      .editInterface(
        this.state.interfaceId,
        {
          active: this.state.activeModal === InterfaceList.Modals.ACTIVATE_INTERFACE_MODAL,
        },
        {
          include: [
            {
              interface_items: {
                scenario_variables: null,
                mapping_items: {
                  column_adapter: 'opt_in_column',
                },
              },
              interface_levels: {
                scenarios: '',
                interface_level_scenarios: '',
                screenshots: '',
              },
            },
          ],
        },
      )
      .then(this.handleCloseModal);
  };

  updateInterfaceName = (name) => {
    this.props
      .editInterface(
        this.state.interfaceId,
        {name},
        {
          include: [
            {
              interface_items: {
                scenario_variables: null,
                mapping_items: {
                  column_adapter: 'opt_in_column',
                },
              },
            },
          ],
        },
      )
      .then(this.handleCloseModal);
  };

  openUpdateNameModal = ({name, id, code, interface_items, interface_template_id, type, update_kind}, e) => {
    e.preventDefault();

    this.setState({
      interfaceCode: code,
      interfaceName: name,
      interfaceId: id,
      items: interface_items,
      interface_template_id,
      interfaceFamily: type,
      interfaceUpdateKind: update_kind,
      activeModal: InterfaceList.Modals.UPDATE_INTERFACE_MODAL,
    });
  };

  handleDeleteInterfaceClick = (id, name) => {
    this.setState({
      interfaceName: name,
      interfaceId: id,
      activeModal: InterfaceList.Modals.INTERFACE_DELETE_MODAL,
    });
  };

  handleDeleteInterfaceConfirm = () => {
    this.props.deleteInterface(this.state.interfaceId).then(() => {
      this.props.onDeleteInterfaceConfirm();
      this.handleCloseModal();
    });
  };

  selectModal = (modalType) => {
    this.setState({
      activeModal: modalType,
    });
  };

  updateInterface = (values) => {
    return this.props
      .editInterface(
        this.state.interfaceId,
        {
          interface: {
            ...values,
          },
        },
        {
          include: [
            {
              interface_items: {
                scenario_variables: null,
                mapping_items: {
                  column_adapter: 'opt_in_column',
                  scenario: null,
                },
              },
            },
            {
              interface_levels: {
                scenarios: null,
                interface_level_scenarios: null,
                screenshots: null,
              },
            },
          ],
        },
      )
      .then((response) => {
        if (!response.error) {
          this.handleCloseModal();
        }
      });
  };

  getCurrentInterface = () => {
    return this.state.interfaceId ? find(this.props.interfaces, {id: this.state.interfaceId}) : {};
  };

  render() {
    const {lang} = this.props;
    const {activeModal} = this.state;
    const {
      DEACTIVATE_INTERFACE_MODAL,
      ACTIVATE_INTERFACE_MODAL,
      UPDATE_INTERFACE_MODAL,
      UPDATE_INTERFACE_NAME_MODAL,
      EDIT_INTERFACE_ITEMS_MODAL,
      UPDATE_SETTINGS_MODAL,
      INTERFACE_DELETE_MODAL,
      SET_INTERFACE_LEVELS_MODAL,
    } = InterfaceList.Modals;
    const deactivateInterfaceMessage = replaceText(
      /\[NAME\]/,
      lang.DEACTIVATE_INTERFACE_MODAL.MESSAGE,
      this.state.interfaceName,
    );
    const activateInterfaceMessage = replaceText(
      /\[NAME\]/,
      lang.ACTIVATE_INTERFACE_MODAL.MESSAGE,
      this.state.interfaceName,
    );
    const deleteInterfaceTitle = `${this.state.interfaceName} ${lang.DELETE_INTERFACE_MODAL.TITLE}`;
    const deleteInterfaceSubtitle = `${lang.DELETE_INTERFACE_MODAL.SUBTITLE}: ${this.state.interfaceId}`;
    const interfaceParams = {
      interface_name: this.state.interfaceName,
      interface_code: this.state.interfaceCode,
    };

    const currentInterface = this.getCurrentInterface();

    return (
      <div className="content-panel interface-list">
        <div className="content-panel__header interface-list__header">
          <div className="interface-list__col">{lang.NAME_COLUMN}</div>
          <div className="interface-list__col">{lang.LEVELS_COLUMN}</div>
          <div className="interface-list__col">{lang.ITEMS_COLUMN}</div>
          <div className="interface-list__col">{lang.INTERFACE_OPTINS_COLUMN}</div>
          <div className="interface-list__col">{lang.INTERFACE_ID_COLUMN}</div>
        </div>

        {this.props.interfaces.map((item) => (
          <InterfaceItem
            key={item.id}
            lang={lang}
            interfaceItem={item}
            handleChangeWebAppBeta={this.handleChangeWebAppBeta}
            handleChangeWebAppUpdate={this.handleChangeWebAppUpdate}
            handleInterfaceActivationChange={this.handleInterfaceActivationChange}
            handleEditInterface={this.openUpdateNameModal}
            handleDeleteInterfaceClick={this.handleDeleteInterfaceClick}
          />
        ))}

        {activeModal === INTERFACE_DELETE_MODAL && (
          <ConfirmationModal
            show={true}
            title={deleteInterfaceTitle}
            subtitle={deleteInterfaceSubtitle}
            message={lang.DELETE_INTERFACE_MODAL.MESSAGE}
            buttonConfirmClass="button--bg-7"
            className="theme-color-8"
            cancelText={lang.DELETE_INTERFACE_MODAL.CANCEL_BUTTON}
            confirmText={lang.DELETE_INTERFACE_MODAL.CONFIRM_BUTTON}
            onCancel={this.handleCloseModal}
            onClose={this.handleCloseModal}
            onConfirm={this.handleDeleteInterfaceConfirm}
          />
        )}

        {activeModal === DEACTIVATE_INTERFACE_MODAL && (
          <ConfirmationModal
            show={true}
            title={lang.DEACTIVATE_INTERFACE_MODAL.TITLE}
            message={deactivateInterfaceMessage}
            buttonConfirmClass="button--bg-7"
            className="theme-color-8"
            cancelText={lang.DEACTIVATE_INTERFACE_MODAL.CANCEL_BUTTON}
            confirmText={lang.DEACTIVATE_INTERFACE_MODAL.DEACTIVATE_BUTTON}
            onCancel={this.handleCloseModal}
            onClose={this.handleCloseModal}
            onConfirm={this.changeInterfaceActivation}
          />
        )}

        {activeModal === ACTIVATE_INTERFACE_MODAL && (
          <ConfirmationModal
            show={true}
            title={lang.ACTIVATE_INTERFACE_MODAL.TITLE}
            message={activateInterfaceMessage}
            buttonConfirmClass="button--bg-7"
            className="theme-color-8"
            cancelText={lang.ACTIVATE_INTERFACE_MODAL.CANCEL_BUTTON}
            confirmText={lang.ACTIVATE_INTERFACE_MODAL.ACTIVATE_BUTTON}
            onCancel={this.handleCloseModal}
            onClose={this.handleCloseModal}
            onConfirm={this.changeInterfaceActivation}
          />
        )}

        {activeModal === UPDATE_INTERFACE_MODAL && (
          <UpdateInterfaceModal
            onClose={this.handleCloseModal}
            selectModal={this.selectModal}
            modalNames={InterfaceList.Modals}
          />
        )}
        {activeModal === UPDATE_INTERFACE_NAME_MODAL && (
          <UpdateNameModal
            interfaceParams={interfaceParams}
            onSubmit={this.updateInterface}
            onClose={this.handleCloseModal}
          />
        )}
        {activeModal === EDIT_INTERFACE_ITEMS_MODAL && (
          <EditInterfaceItemsModal
            items={this.state.items}
            interfaceId={this.state.interfaceId}
            interfaceTemplateId={this.state.interface_template_id}
            interfaceFamily={this.state.interfaceFamily}
            onSubmit={this.updateInterface}
            onKindChange={this.handleClearMapItemsByIndex}
            onClose={this.handleCloseModal}
            fetchInterfaces={this.props.fetchInterfaces}
          />
        )}

        {activeModal === SET_INTERFACE_LEVELS_MODAL && (
          <SetInterfaceLevelsModal
            interfaceData={currentInterface}
            fetchInterfaces={this.props.fetchInterfaces}
            onClose={this.handleCloseModal}
            onCancel={this.handleCloseModal}
            edit
          />
        )}
        {activeModal === UPDATE_SETTINGS_MODAL && (
          <UpdateSettingsModal
            show={true}
            onClose={this.handleCloseModal}
            onConfirm={this.updateInterface}
            updateKind={this.state.interfaceUpdateKind}
          />
        )}
      </div>
    );
  }
}

export default connect(
  ({languageState}) => ({
    lang: languageState.payload.AUTOTASK.AUTOTASK_INTERFACES_LIST,
  }),
  {
    editInterface: patchInterfaceAction,
    deleteInterface,
    deleteInterfaceItem,
    createInterfaceItem,
    updateWebApp,
  },
)(InterfaceList);
