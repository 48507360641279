export const MAX_LANGUAGES = 7;

export const DEFAULT_LANGUAGE = 'fr';

export const ALL_LANGUAGES = [
  'bg',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'fi',
  'ga',
  'hr',
  'hu',
  'it',
  'lv',
  'nl',
  'pl',
  'pt',
  'ro',
  'sk',
  'sl',
  'sv',
];
