import moment from 'moment';

export const convertDateValue = (rawValue) => {
  if (!rawValue) {
    return '';
  }

  const value = rawValue.slice(0, 10);

  if (moment(value, 'DD/MM/YYYY', true).isValid()) {
    return value;
  }

  if (moment(value, 'YYYY-MM-DD', true).isValid()) {
    return moment(value).format('DD/MM/YYYY');
  }

  return rawValue;
};
