import React, {PureComponent} from 'react';

import findKey from 'lodash/findKey';
import flow from 'lodash/flow';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isNumber from 'lodash/isNumber';
import maxBy from 'lodash/maxBy';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {submit, isInvalid, hasSubmitFailed, isDirty, SubmissionError} from 'redux-form';

import {uid} from 'client/services/helpers';

import {SCENARIO_STEP_TYPES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import {
  deleteScenarioStepFromStore,
  deleteNotSavedScenarioSteps,
  addScenarioStepIntoStore,
  sortScenarioStepsInStore,
  createScenarioStep,
  updateScenarioStep,
  deleteScenarioStep,
  getScenarioStep,
  updateScenario,
} from '../../../../../ducks/scenario/actions';
import AtsStepEditor from '../../ats-scenario-editor/ats-scenario-editor';
import {getFormId} from '../../ats-scenario-editor/components/ats-step-list/ats-step-list';
import {
  createBranchImplementation,
  createTimerImplementation,
  createCalculationImplementation,
  createSMSImplementation,
  createEmailImplementation,
  createSocialImplementation,
  createPrizeDrawImplementation,
  createInstantWinImplementation,
  createInstantCoupon,
} from '../../ats-scenario-editor/components/forms';

import './ats-scenario-editor-modal.scss';

const QUERY_PARAMS_MAPPING = {
  [SCENARIO_STEP_TYPES.timer]: {
    include: [
      {
        implementation: ['timer_scenario_steps'],
      },
    ],
  },
  [SCENARIO_STEP_TYPES.branch]: {
    include: [
      {
        implementation: [
          'scenario',
          {
            branch_scenario_step_conditions: ['variable', 'value_variable'],
          },
        ],
      },
    ],
  },
  [SCENARIO_STEP_TYPES.message.sms]: {
    include: [
      {
        implementation: ['sms_scenario_steps', 'message_scenario_step_to_opt_in_columns'],
      },
    ],
  },
  [SCENARIO_STEP_TYPES.message.email]: {
    include: [
      {
        implementation: [
          'email_scenario_steps',
          'email_scenario_step_mapping_items',
          'message_scenario_step_to_opt_in_columns',
        ],
      },
    ],
  },
  [SCENARIO_STEP_TYPES.social.facebook]: {
    include: [
      {
        implementation: ['facebook_scenario_steps', 'social_scenario_step_images'],
      },
    ],
  },
  [SCENARIO_STEP_TYPES.social.twitter]: {
    include: [
      {
        implementation: ['twitter_scenario_steps', 'social_scenario_step_images'],
      },
    ],
  },
  [SCENARIO_STEP_TYPES.instantLottery]: {
    include: [
      {
        implementation: ['lottery_scenario_step_prizes'],
      },
    ],
  },
  [SCENARIO_STEP_TYPES.prizeDraw]: {
    include: [
      {
        implementation: ['lottery_scenario_step_prizes'],
      },
    ],
  },
  [SCENARIO_STEP_TYPES.coupons]: {
    include: [
      {
        implementation: ['coupons_scenario_step', 'coupon'],
      },
    ],
  },
  [SCENARIO_STEP_TYPES.appCoupons]: {
    include: [
      {
        implementation: ['coupons_scenario_step', 'coupon'],
      },
    ],
  },
  [SCENARIO_STEP_TYPES.sogecCoupons]: {
    include: [
      {
        implementation: ['coupons_scenario_step', 'coupon'],
      },
    ],
  },
  [SCENARIO_STEP_TYPES.weezioCoupons]: {
    include: [
      {
        implementation: ['coupons_scenario_step', 'coupon'],
      },
    ],
  },
  [SCENARIO_STEP_TYPES.calculation]: {
    include: [
      {
        implementation: ['calculation_step_components', 'column_adapter'],
      },
    ],
  },
};

class AtsScenarioEditorModal extends PureComponent {
  static propTypes = {
    deleteScenarioStepFromStore: PropTypes.func.isRequired,
    deleteNotSavedScenarioSteps: PropTypes.func.isRequired,
    addScenarioStepIntoStore: PropTypes.func.isRequired,
    sortScenarioStepsInStore: PropTypes.func.isRequired,
    touchedScenarioSteps: PropTypes.array.isRequired,
    isPossibleToAddStep: PropTypes.bool.isRequired,
    createScenarioStep: PropTypes.func.isRequired,
    updateScenarioStep: PropTypes.func.isRequired,
    deleteScenarioStep: PropTypes.func.isRequired,
    getScenarioSteps: PropTypes.func.isRequired,
    languageSteps: PropTypes.object.isRequired,
    language: PropTypes.object.isRequired,
    getScenarioStep: PropTypes.func.isRequired,
    scenarioSteps: PropTypes.array.isRequired,
    updateScenario: PropTypes.func.isRequired,
    invalidForms: PropTypes.array.isRequired,
    scenarioId: PropTypes.number.isRequired,
    variables: PropTypes.array.isRequired,
    updateMe: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    onStepsChanged: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    show: PropTypes.bool,
  };

  static defaultProps = {
    show: false,
  };

  submitFormResolvers = {};

  constructor(props) {
    super(props);

    this.state = {
      activeEventKey: null,
    };

    this.IMPLEMENTATION_MAPPING = {
      [SCENARIO_STEP_TYPES.branch]: {
        type: SCENARIO_STEP_TYPES.branch,
        ...createBranchImplementation(this.props.variables),
      },
      [SCENARIO_STEP_TYPES.timer]: {
        type: SCENARIO_STEP_TYPES.timer,
        ...createTimerImplementation(),
      },
      [SCENARIO_STEP_TYPES.calculation]: {
        type: SCENARIO_STEP_TYPES.calculation,
        ...createCalculationImplementation(),
      },
      [SCENARIO_STEP_TYPES.message.sms]: {
        type: SCENARIO_STEP_TYPES.message.sms,
        ...createSMSImplementation(),
      },
      [SCENARIO_STEP_TYPES.message.email]: {
        type: SCENARIO_STEP_TYPES.message.email,
        ...createEmailImplementation(),
      },
      [SCENARIO_STEP_TYPES.social.facebook]: {
        type: SCENARIO_STEP_TYPES.social.facebook,
        ...createSocialImplementation(),
      },
      [SCENARIO_STEP_TYPES.social.twitter]: {
        type: SCENARIO_STEP_TYPES.social.twitter,
        ...createSocialImplementation(),
      },
      [SCENARIO_STEP_TYPES.instantLottery]: {
        type: SCENARIO_STEP_TYPES.instantLottery,
        ...createInstantWinImplementation(),
      },
      [SCENARIO_STEP_TYPES.prizeDraw]: {
        type: SCENARIO_STEP_TYPES.prizeDraw,
        ...createPrizeDrawImplementation(),
      },
      [SCENARIO_STEP_TYPES.coupons]: {
        type: SCENARIO_STEP_TYPES.coupons,
        ...createInstantCoupon(),
      },
      [SCENARIO_STEP_TYPES.weezioCoupons]: {
        type: SCENARIO_STEP_TYPES.coupons,
        ...createInstantCoupon(),
      },
    };
  }

  findInheritImplementationType = (type) => {
    const inheritKey = findKey(SCENARIO_STEP_TYPES, (child) => includes(child, type));
    const inheritObject = SCENARIO_STEP_TYPES[inheritKey];

    if (typeof inheritObject === 'object') {
      return inheritObject.inherit;
    }

    return inheritObject;
  };

  handleAddStep = (implementationType) => {
    const {scenarioId, scenarioSteps} = this.props;

    const position =
      1 + flow([(values) => maxBy(values, 'position'), (item) => get(item, 'position', 0)])(scenarioSteps);

    if (this.props.isPossibleToAddStep) {
      this.props.addScenarioStepIntoStore({
        id: uid(implementationType),
        name: '',
        scenario_id: scenarioId,
        implementation_type: this.findInheritImplementationType(implementationType),
        position,
        implementation: this.IMPLEMENTATION_MAPPING[implementationType] || {},
        comment: '',
      });
    }
  };

  handleSortSteps = (oldIndex, newIndex) => {
    const {scenarioSteps} = this.props;

    const steps =
      newIndex > oldIndex
        ? [
            ...scenarioSteps.slice(0, oldIndex),
            ...scenarioSteps.slice(oldIndex + 1, newIndex + 1),
            scenarioSteps[oldIndex],
            ...scenarioSteps.slice(newIndex + 1),
          ]
        : [
            ...scenarioSteps.slice(0, newIndex),
            scenarioSteps[oldIndex],
            ...scenarioSteps.slice(newIndex, oldIndex),
            ...scenarioSteps.slice(oldIndex + 1),
          ];

    const itemWithMaxPosition = maxBy(this.props.scenarioSteps, 'position') || {};
    const maxPosition = get(itemWithMaxPosition, 'position', 0);

    this.props.updateScenario(this.props.scenarioId, {
      scenario: {
        scenario_steps: steps.map(({id}, index) => ({
          id,
          position: maxPosition + index + 1,
        })),
      },
    });

    this.props.sortScenarioStepsInStore(oldIndex, newIndex, maxPosition + 1);
  };

  handleDeleteStep = (id) => {
    if (isNumber(id)) {
      return this.props.deleteScenarioStep(id).then(() => this.props.updateMe({steps: false}));
    }

    return this.props.deleteScenarioStepFromStore(id);
  };

  handleSaveStep = async (data) => {
    const id = data.id;
    const queryParams = QUERY_PARAMS_MAPPING[data.implementation.type];

    const preparedData = {
      ...data,
      implementation: data.implementation && {
        ...data.implementation,
        branch_scenario_step_conditions:
          data.implementation.branch_scenario_step_conditions &&
          data.implementation.branch_scenario_step_conditions.map((i) => omit(i, ['variable', 'value_variable'])),
      },
    };

    let response;
    if (isNumber(id)) {
      response = await this.props.updateScenarioStep(
        id,
        {
          scenario_step: preparedData,
        },
        queryParams,
      );
    } else {
      response = await this.props.createScenarioStep(id, {scenario_step: preparedData}, queryParams).catch(() => {
        throw new SubmissionError({name: this.props.language.THIS_NAME_IS_TAKEN_ERROR});
      });
    }
    this.props.updateMe({steps: false});

    return response;
  };

  handleSelectStep = (eventKey) => {
    this.setState({
      activeEventKey: eventKey,
    });
  };

  handleCancel = () => {
    this.props.deleteNotSavedScenarioSteps();
    this.props.onCancel();
    this.props.onStepsChanged();
  };

  handleSubmitResult = (result, data) => {
    const formId = getFormId(data.initialId);

    if (this.submitFormResolvers[formId]) {
      this.submitFormResolvers[formId]({
        success: result === 'success',
        data,
      });
    }
  };

  handleSubmitSingleStep = (stepId) => {
    const formId = getFormId(stepId);

    return new Promise((resolve) => {
      this.submitFormResolvers[formId] = resolve;
      this.props.submit(formId);
    }).then(({data}) => {
      delete this.submitFormResolvers[data.initialId];

      const invalidStep = this.props.invalidForms.find((v) => v.id === stepId);

      if (!invalidStep) {
        this.props.getScenarioStep(data.id, QUERY_PARAMS_MAPPING[data.implementation.type]);
      }

      return {
        success: !invalidStep,
      };
    });
  };

  render() {
    const {show, scenarioSteps, invalidForms, language, languageSteps} = this.props;

    return (
      <Modal
        title={language.TITLE}
        onClose={this.handleCancel}
        show={show}
        dialogClassName="modal-window--width-2 theme-color-7 ats-scenario-editor-modal"
        backdropClassName="modal-window__backdrop"
      >
        <div className="ats-scenario-editor-modal__content">
          <AtsStepEditor
            labels={{
              addStep: language.ADD_STEP_DROPDOWN,
              stepList: language.STEP_LIST_TITLE,
              stepConfig: language.STEP_CONFIG_TITLE,
              impossibleToAdd: language.IMPOSSIBLE_TO_ADD,
              pleaseDeleteOne: language.PLEASE_DELETE_ONE,
            }}
            stepNames={languageSteps}
            applyBtnText={language.APPLY_BUTTON}
            scenarioSteps={scenarioSteps}
            handleAddStep={this.handleAddStep}
            handleSelectStep={this.handleSelectStep}
            handleSaveStep={this.handleSaveStep}
            handleSaveSingleStep={this.handleSubmitSingleStep}
            handleDeleteStep={this.handleDeleteStep}
            handleSortSteps={this.handleSortSteps}
            handleSubmitResult={this.handleSubmitResult}
            invalidForms={invalidForms}
            isPossibleToAddStep={this.props.isPossibleToAddStep}
          />
        </div>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    language: state.languageState.payload.AUTOTASK_SCENARIO.SCENARIO_EDITOR_MODAL,
    languageSteps: state.languageState.payload.AUTOTASK_SCENARIO.SCENARIO_STEP_NAMES,
    scenarioSteps: state.scenario.payload.scenario_steps,
    touchedScenarioSteps: state.scenario.payload.scenario_steps.filter(
      (v) => !isNumber(v.id) || isDirty(getFormId(v.id))(state),
    ),
    invalidForms: state.scenario.payload.scenario_steps.filter(
      (v) => isInvalid(getFormId(v.id))(state) && hasSubmitFailed(getFormId(v.id))(state),
    ),
  }),
  {
    addScenarioStepIntoStore,
    sortScenarioStepsInStore,
    deleteScenarioStepFromStore,
    createScenarioStep,
    getScenarioStep,
    updateScenario,
    updateScenarioStep,
    deleteScenarioStep,
    deleteNotSavedScenarioSteps,
    submit,
    isInvalid,
    hasSubmitFailed,
  },
)(AtsScenarioEditorModal);
