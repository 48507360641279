import toFormData from 'object-to-formdata';

import {callApi} from 'client/services/call-api';
import {getEmail, getOrganizationId, getToken} from 'client/services/cookie-data-source';

import {API_PATH, API_METHODS} from 'client/common/config';

import {getMediaLevel} from 'client/components/medias/helpers';

import types from './types';

export const getMediasDashboard = (queryParams = {}) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}/global_media_files/dashboard`,
        method: 'GET',
        types: [
          types.GET_MEDIAS_DASHBOARD_REQUEST,
          types.GET_MEDIAS_DASHBOARD_SUCCESS,
          types.GET_MEDIAS_DASHBOARD_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
};

export const getGlobalMedias = (queryParams = {}) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}/global_media_files`,
        method: 'GET',
        types: [types.GET_GLOBAL_MEDIAS_REQUEST, types.GET_GLOBAL_MEDIAS_SUCCESS, types.GET_GLOBAL_MEDIAS_ERROR],
        queryParams,
      },
      true,
    );
  };
};

export const getLocalMedias = (queryParams = {}) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}/local_media_files`,
        method: 'GET',
        types: [types.GET_LOCAL_MEDIAS_REQUEST, types.GET_LOCAL_MEDIAS_SUCCESS, types.GET_LOCAL_MEDIAS_ERROR],
        queryParams,
      },
      true,
    );
  };
};

export const updateMedia = (id, body) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}/media_files/${id}`,
        method: 'PUT',
        types: [types.UPDATE_MEDIA_REQUEST, types.UPDATE_MEDIA_SUCCESS, types.UPDATE_MEDIA_ERROR],
        body,
      },
      true,
    );
  };
};

export const deleteMedia = (id) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}/media_files/${id}`,
        method: 'DELETE',
        types: [types.DELETE_MEDIA_REQUEST, types.DELETE_MEDIA_SUCCESS, types.DELETE_MEDIA_ERROR],
      },
      true,
    );
  };
};

export const uploadGlobalMediaFile = (file) => {
  return async (dispatch) => {
    dispatch(() => ({
      type: types.UPLOAD_FILE_REQUEST,
    }));

    const fetchResponse = await fetch(`${API_PATH}${API_METHODS.GLOBAL_MULTIMEDIA_FILE}`, {
      method: 'POST',
      headers: {
        'X-User-Email': getEmail(),
        'X-User-Token': getToken(),
      },
      body: toFormData({
        global_media_file: {
          client_id: getOrganizationId(),
          level: getMediaLevel(),
          file,
        },
      }),
    });
    const response = await fetchResponse.json();
    dispatch(() => ({
      type: types.UPLOAD_FILE_SUCCESS,
      payload: response,
    }));
    return response;
  };
};

export const uploadLocalMediaFile = (file, deviceAffectationId) => {
  return async (dispatch) => {
    dispatch(() => ({
      type: types.UPLOAD_FILE_REQUEST,
    }));

    const fetchResponse = await fetch(`${API_PATH}${API_METHODS.LOCAL_MULTIMEDIA_FILE}`, {
      method: 'POST',
      headers: {
        'X-User-Email': getEmail(),
        'X-User-Token': getToken(),
      },
      body: toFormData({
        local_media_file: {
          client_id: getOrganizationId(),
          device_affectation_id: deviceAffectationId,
          file,
        },
      }),
    });
    const response = await fetchResponse.json();
    dispatch(() => ({
      type: types.UPLOAD_FILE_SUCCESS,
      payload: response,
    }));
    return response;
  };
};

export const getMedia = (id, isLocal = false) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}/${isLocal ? 'local_media_files' : 'global_media_files'}/${id}`,
        method: 'GET',
        types: [types.GET_MEDIA_REQUEST, types.GET_MEDIA_SUCCESS, types.GET_MEDIA_ERROR],
        queryParams: {
          include: ['default_media_configuration'],
        },
      },
      true,
    );
  };
};
