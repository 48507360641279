export default {
  GET_OPT_IN_MAPPING_ITEMS_REQUEST: '@@opt-in-mapping-items/GET_OPT_IN_MAPPING_ITEMS_REQUEST',
  GET_OPT_IN_MAPPING_ITEMS_SUCCESS: '@@opt-in-mapping-items/GET_OPT_IN_MAPPING_ITEMS_SUCCESS',
  GET_OPT_IN_MAPPING_ITEMS_ERROR: '@@opt-in-mapping-items/GET_OPT_IN_MAPPING_ITEMS_ERROR',

  UPDATE_OPT_IN_MAPPING_ITEMS_REQUEST: '@@opt-in-mapping-items/UPDATE_OPT_IN_MAPPING_ITEMS_REQUEST',
  UPDATE_OPT_IN_MAPPING_ITEMS_SUCCESS: '@@opt-in-mapping-items/UPDATE_OPT_IN_MAPPING_ITEMS_SUCCESS',
  UPDATE_OPT_IN_MAPPING_ITEMS_ERROR: '@@opt-in-mapping-items/UPDATE_OPT_IN_MAPPING_ITEMS_ERROR',

  GET_OPT_IN_COLUMNS_REQUEST: '@@opt-in-mapping-items/GET_OPT_IN_COLUMNS_REQUEST',
  GET_OPT_IN_COLUMNS_SUCCESS: '@@opt-in-mapping-items/GET_OPT_IN_COLUMNS_SUCCESS',
  GET_OPT_IN_COLUMNS_ERROR: '@@opt-in-mapping-items/GET_OPT_IN_COLUMNS_ERROR',

  DELETE_OPT_IN_MAPPING_ITEM_REQUEST: '@@opt-in-mapping-items/DELETE_OPT_IN_MAPPING_ITEM_REQUEST',
  DELETE_OPT_IN_MAPPING_ITEM_SUCCESS: '@@opt-in-mapping-items/DELETE_OPT_IN_MAPPING_ITEM_SUCCESS',
  DELETE_OPT_IN_MAPPING_ITEM_ERROR: '@@opt-in-mapping-items/DELETE_OPT_IN_MAPPING_ITEM_ERROR',
};
