import React from 'react';

import cn from 'classnames';
import get from 'lodash/get';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import VisualsPlaceholder from 'client/components/client-autotask/tabs/visuals-tab/visuals-placeholder';
import TagsCell from 'client/components/client-autotask/tabs/visuals-tab/visuals-table/tags-cell';
import DatetimeCell from 'client/components/tables/common/cells/datetime-cell';
import {Visuals} from 'client/models/visuals/types';

import cssModule from './visual-card.module.scss';

type VisualCardProps = {
  visual: Visuals;
  gridIncrement: number;
  onTogglePreviewModal: (id: number) => void;
  className?: string;
};

const b = bem('visual-card', {cssModule});

const MIN_CARD_WIDTH = 140;
const MIN_PREVIEW_HEIGHT = 80;

const VisualCard: React.FC<VisualCardProps> = (props) => {
  const {visual, gridIncrement, onTogglePreviewModal, className} = props;

  const lang = useLanguage('CLIENT_VISUALS');

  const getCardStyle = () => ({
    width: MIN_CARD_WIDTH + gridIncrement + 'px',
  });

  const getPreviewStyle = () => ({
    height: MIN_PREVIEW_HEIGHT + gridIncrement + 'px',
  });

  const interfaceName = visual?.interface?.name || '';
  const origin = visual?.interaction?.name || lang.DIRECT;
  const src = get(visual, 'file.preview.url.url');
  const onClick = () => onTogglePreviewModal(visual.id);

  return (
    <div className={cn(b(), className)} style={getCardStyle()}>
      <div className={b('preview')} style={getPreviewStyle()}>
        <div>
          {src ? (
            <img className={b('image')} src={src} onClick={onClick} alt="" />
          ) : (
            <VisualsPlaceholder visual={visual} onClick={onClick} className={b('visuals-placeholder')} />
          )}
        </div>
      </div>
      <div className={b('info')}>
        <div className={b('created')}>
          <DatetimeCell value={visual?.created_at} fullWidth />
        </div>
        <div className={b('interface')} title={interfaceName}>
          {interfaceName}
        </div>
        <div className={b('origin')} title={String(origin)}>
          {origin}
        </div>
        {visual?.tags && (
          <div className={b('tags')}>
            <TagsCell tags={visual?.tags} />
          </div>
        )}
      </div>
    </div>
  );
};

export default VisualCard;
