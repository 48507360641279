export const selectDiagrams = (state) => {
  return state.resultsGraphs.diagrams;
};

export const selectClientDiagrams = (state) => {
  return state.resultsGraphs.clientDiagrams;
};

export const selectDataSources = (state) => {
  return state.resultsGraphs.dataSources;
};
