import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import fieldTemplate from 'client/components/common/field';

const CASE_MAPPING = {
  case1: {
    name: 'skip',
    MessageDelivered: true,
    MessageSent: true,
    TimeExpired: false,
  },
  case2: {
    name: 'skip',
    MessageDelivered: false,
    MessageSent: true,
    TimeExpired: false,
  },
  case3: {
    name: 'skip',
    MessageDelivered: false,
    MessageSent: true,
    TimeExpired: true,
  },
};

class TestSmsForm extends PureComponent {
  constructor() {
    super();
  }

  radioCaseChangeHandler = (e) => {
    const {value} = e.target;
    const {change, id} = this.props;

    switch (value) {
      case 'case1':
      case 'case2':
      case 'case3':
        change(`_${id}`, {...CASE_MAPPING[value]});
        break;
      default:
        break;
    }
  };

  radioNameChangeHandler = (e) => {
    const {change, id} = this.props;
    const {value} = e.target;

    switch (value) {
      case 'normal':
        change(`_${id}`, {
          name: e.target.value,
        });
        break;
      case 'skip':
        change(`_${id}`, {
          ...CASE_MAPPING.case1,
          case: 'case1',
        });
        break;
      default:
        break;
    }
  };

  render() {
    const {id, formValues, languageState: LANGUAGE} = this.props;
    const name = formValues ? formValues[`_${id}`].name : '';
    const showOverlay = name === 'normal';

    return (
      <div>
        <div className="test-step__form-block">
          <Field
            label={LANGUAGE.RUN_LABEL}
            name={`_${id}.name`}
            type="radio"
            value="normal"
            onChange={this.radioNameChangeHandler}
            component={fieldTemplate}
          />
        </div>
        <div className="test-step__form-block">
          <Field
            label={LANGUAGE.IMITATE_LABEL}
            name={`_${id}.name`}
            type="radio"
            value="skip"
            onChange={this.radioNameChangeHandler}
            component={fieldTemplate}
          />
          <div className="test-step__form-subblocks">
            {showOverlay && <div className="test-step__form-subblocks--overlay" />}
            <div className="test-step__form-block">
              <Field
                label={`${LANGUAGE.CASE_LABEL} 1`}
                name={`_${id}.case`}
                type="radio"
                value="case1"
                onChange={this.radioCaseChangeHandler}
                component={fieldTemplate}
              />
              <div className="test-step__form-subblock-desc">
                <div>
                  {LANGUAGE.SENT_TEXT}: {LANGUAGE.TRUE_TEXT}
                </div>
                <div>
                  {LANGUAGE.DELIVERED_TEXT}: {LANGUAGE.TRUE_TEXT}
                </div>
                <div>
                  {LANGUAGE.EXPIRED_TEXT}: {LANGUAGE.FALSE_TEXT}
                </div>
              </div>
            </div>
            <div className="test-step__form-block">
              <Field
                label={`${LANGUAGE.CASE_LABEL} 2`}
                name={`_${id}.case`}
                type="radio"
                value="case2"
                onChange={this.radioCaseChangeHandler}
                component={fieldTemplate}
              />
              <div className="test-step__form-subblock-desc">
                <div>
                  {LANGUAGE.SENT_TEXT}: {LANGUAGE.TRUE_TEXT}
                </div>
                <div>
                  {LANGUAGE.DELIVERED_TEXT}: {LANGUAGE.FALSE_TEXT}
                </div>
                <div>
                  {LANGUAGE.EXPIRED_TEXT}: {LANGUAGE.FALSE_TEXT}
                </div>
              </div>
            </div>
            <div className="test-step__form-block">
              <Field
                label={`${LANGUAGE.CASE_LABEL} 3`}
                name={`_${id}.case`}
                type="radio"
                value="case3"
                onChange={this.radioCaseChangeHandler}
                component={fieldTemplate}
              />
              <div className="test-step__form-subblock-desc">
                <div>
                  {LANGUAGE.SENT_TEXT}: {LANGUAGE.TRUE_TEXT}
                </div>
                <div>
                  {LANGUAGE.DELIVERED_TEXT}: {LANGUAGE.FALSE_TEXT}
                </div>
                <div>
                  {LANGUAGE.EXPIRED_TEXT}: {LANGUAGE.TRUE_TEXT}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TestSmsForm.propTypes = {
  change: PropTypes.func,
  id: PropTypes.number,
  formValues: PropTypes.object,
  languageState: PropTypes.object.isRequired,
};

export default TestSmsForm;
