import type {PreviewAsset} from 'client/models/diy-customization-form/types';

export const getPreviewHtml = async (htmlUrl: string, assets: PreviewAsset[]) => {
  let previewHtml = await fetch(htmlUrl).then((res) => res.text());

  // get style assets linked in HTML to download and inject the content into head of HTML
  const stylesAssets = assets.filter(
    (asset) => asset.relative_path.endsWith('.css') && previewHtml.match(asset.relative_path),
  );

  const promises = await Promise.allSettled(stylesAssets.map((asset) => fetch(asset.url)));

  let index = 0;
  for await (const promise of promises) {
    if (promise.status === 'fulfilled') {
      const response = promise.value as Response;
      const styles = await response.text();

      const {relative_path} = stylesAssets[index];

      // replace a link to external css with inline style rules
      previewHtml = previewHtml.replace(
        new RegExp(`(<link.*href=")(${relative_path})(".*>)`),
        `<style>${styles}</style>`,
      );
    }
    index++;
  }

  // replace relative file path with the URL to external file
  assets.forEach((asset) => (previewHtml = previewHtml.replace(new RegExp(`\/?${asset.relative_path}`), asset.url)));

  return previewHtml;
};
