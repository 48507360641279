import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import ErrorMessage from 'client/common/inputs/error-message';
import RequiredLabel from 'client/common/inputs/required-label';

import {TranslationJsx} from 'client/models/language/types';

import cssModule from './password-input.module.scss';

const b = bem('password-input', {cssModule});

const PasswordInput = React.memo((props) => {
  const {label, className, name, inputClassName, errorMessage, warning, required = false, ...inputProps} = props;

  return (
    <div className={cn(b({error: !!errorMessage, success: warning && !errorMessage}), className)}>
      {label && (
        <label htmlFor={name} className={b('label')}>
          {label}
        </label>
      )}
      <input
        {...inputProps}
        name={name}
        className={cn(b('field'), inputClassName)}
        type="password"
        aria-invalid={!!errorMessage}
      />
      {!errorMessage && required && <RequiredLabel />}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {warning && !errorMessage && <p className={b('success-message')}>{warning}</p>}
    </div>
  );
});

PasswordInput.propTypes = {
  label: TranslationJsx,
  name: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  value: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
  warning: TranslationJsx,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

PasswordInput.defaultProps = {
  label: '',
  name: '',
  errorMessage: null,
  warning: '',
  className: '',
  inputClassName: '',
  onChange: null,
};

export default PasswordInput;
