// Use in beforeDraw or afterDraw callback
export const drawVerticalLineOnHover = (chart) => {
  if (chart.tooltip?.getActiveElements && chart.tooltip?.getActiveElements().length) {
    const activePoint = chart.tooltip?.getActiveElements()[0];
    const ctx = chart.ctx;
    const x = activePoint.element.x;
    const topY = chart.scales.yAxes.top;
    const bottomY = chart.scales.yAxes.bottom;

    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x, topY);
    ctx.lineTo(x, bottomY);
    ctx.lineWidth = 1;
    ctx.setLineDash([4, 4]);
    ctx.strokeStyle = activePoint.element.options.backgroundColor;
    ctx.stroke();
    ctx.restore();
  }
};

// Use in beforeInit callback
export const labelLineBreak = (chart) => {
  chart.data.labels.forEach((label, index, labelsArr) => {
    if (/\n/.test(label)) {
      labelsArr[index] = label.split(/\n/);
    }
  });
};
