import React, {useMemo} from 'react';

import cn from 'classnames';
import find from 'lodash/find';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {checkCampaignSourcesSelector} from 'client/ducks/participations-statistics/selectors';
import {selectClientUserMembership} from 'client/ducks/user-clients/selectors';

import {CLIENT_PAGES, USER_MEMBERSHIP} from 'client/common/config';
import Popover from 'client/common/popovers/popover';
import Dropdown from 'client/common/selects/dropdown';

import CustomLink from 'client/components/common/custom-link';

import {Autotask} from 'client/models/autotask/types';
import {Operation} from 'client/models/operations/types';

import menuConfig from './menuConfig';

import cssModule from './client-autotask-menu.module.scss';

const b = bem('client-autotask-menu', {cssModule});

type ClientAutotaskMenuProps = {
  autotask: Autotask;
  operation: Operation;
  activeTab: string;
  accessLevel: string;
  className?: string;
};

const ClientAutotaskMenu: React.FC<ClientAutotaskMenuProps> = (props) => {
  const {autotask, operation, activeTab, accessLevel, className} = props;
  const lang = useLanguage('CLIENT_AUTOTASK_MENU');
  const history = useHistory();
  const isCampaignTabBlocked = useSelector(checkCampaignSourcesSelector);
  const currentMembership = useSelector(selectClientUserMembership);

  const tabs = CLIENT_PAGES.CLIENT_AUTOTASK_TABS;

  const goToTab = (tab: any) => {
    const link = CustomLink.createClientAutotaskTabLink({
      clientId: operation.client_id,
      operationId: operation.id,
      autotaskId: autotask.id,
      tab,
    });

    history.push(link);
  };

  const renderDisabledMenuItem = (item: any) => {
    return (
      <Popover
        key={item.tab}
        position="bottom"
        className={b('item', {disabled: true})}
        shiftTop={15}
        overlay={lang.MENU_HINTS[item.langKey as keyof typeof lang.MENU_HINTS]}
      >
        {lang.MENU_ITEMS[item.langKey as keyof typeof lang.MENU_HINTS]}
      </Popover>
    );
  };

  const isMenuItemDisabled = (name: any) => {
    switch (name) {
      case tabs.CAMPAIGNS:
        return isCampaignTabBlocked;
      case tabs.PLANS:
        return !autotask?.contains_offline_interactions;
      case tabs.VISUALS:
        return autotask?.client_visuals_count && autotask?.client_visuals_count < 1;
      case tabs.RESULTS:
        return autotask?.visible_diagrams_count && autotask?.visible_diagrams_count < 1;
      case tabs.CONFIGURATION:
        return !operation.show_configuration || operation.client_automation_task_id !== autotask.id;
      default:
        return false;
    }
  };
  const renderMenuItem = (item: any, handleClick = true) => {
    if (item.subItems) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return renderSubMenu(item);
    }

    if (isMenuItemDisabled(item.tab)) {
      return renderDisabledMenuItem(item);
    }

    const active = item.tab === activeTab;

    return (
      <div key={item.langKey} onClick={() => handleClick && goToTab(item.tab)} className={b('item', {active})}>
        {lang.MENU_ITEMS[item.langKey as keyof typeof lang.MENU_HINTS]}
      </div>
    );
  };

  const renderSubMenu = (item: any) => {
    const active = !!find(item.subItems, {tab: activeTab});

    return (
      <Dropdown
        key={item.langKey}
        className={cn(b('submenu'), b('item', {active}))}
        trigger={
          <span className={b('submenu-name', {active})}>
            {lang.MENU_ITEMS[item.langKey as keyof typeof lang.MENU_HINTS]}
          </span>
        }
        options={item.subItems.map((subItem: any) => {
          return {
            key: subItem.langKey,
            onClick: () => goToTab(subItem.tab),
            label: <div className={b('submenu-item')}>{renderMenuItem(subItem, false)}</div>,
          };
        })}
        position="bottom"
        showToggleArrow
      />
    );
  };

  const menuItems = useMemo(() => {
    const access = currentMembership?.data_tab_access || {};
    const hasDataAccess = access.participations || access.leads || access.winners;
    const isClientAdmin = accessLevel === USER_MEMBERSHIP.CLIENT_ADMIN;
    const result: any[] = [];

    menuConfig.forEach((item) => {
      if (!item.check || isClientAdmin) {
        result.push(item);
      } else if (item.check === 'data_tab_access' && hasDataAccess) {
        const newItem = {
          ...item,
          subItems: item.subItems.filter((subItem) => access[subItem.check]),
        };
        result.push(newItem);
      }
    });

    return result;
  }, [accessLevel, currentMembership?.data_tab_access]);

  return <div className={cn(b(), className)}>{menuItems.map((item) => renderMenuItem(item))}</div>;
};

export default ClientAutotaskMenu;
