import React from 'react';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {NumberField} from 'client/common/fields';
import LayoutPanel from 'client/common/panels/layout-panel';

import {PrizeMapCustomizationFormName} from 'client/components/prizes/forms/prizemap-customization-form';
import {PrizeMap} from 'client/models/prizes/types';

import cssModule from './prize-diy-stock-information.module.scss';

const b = bem('prize-diy-stock-information', {cssModule});

type PrizeDiyStockInformationProps = {
  disabled?: boolean;
};
const PrizeDiyStockInformation: React.FC<PrizeDiyStockInformationProps> = ({disabled}) => {
  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_STOCK_INFORMATION_FIELDSET');
  const {formValues: prizeMap} = useReduxForm<PrizeMap>(PrizeMapCustomizationFormName);

  const currentStock =
    +(prizeMap.stock_offset || 0) +
    +(prizeMap.initial_stock || 0) -
    ((+prizeMap.prizes_not_validated || 0) + (+prizeMap.prizes_validated || 0));

  return (
    <LayoutPanel className={b()}>
      <h4 className={b('title')}>{lang.STOCK_INFORMATION}</h4>
      <div className={b('body')}>
        <div className={b('fields')}>
          <NumberField name="initial_stock" label={lang.INITIAL_STOCK_LABEL} disabled={disabled} />
          <NumberField name="stock_offset" label={lang.STOCK_OFFSET_LABEL} disabled={disabled} />
        </div>
        <div className={b('values')}>
          <div className={b('values-item')}>
            <span className={b('values-label')}>{lang.WON_NOT_VALIDATED}</span>
            <span className={b('values-value')}>{prizeMap.prizes_not_validated}</span>
          </div>
          <div className={b('values-item')}>
            <span className={b('values-label')}>{lang.WON_VALIDATED}</span>
            <span className={b('values-value')}>{prizeMap.prizes_validated}</span>
          </div>
          <div className={b('values-item')}>
            <span className={b('values-label')}>{lang.CURRENT_STOCK}</span>
            <span className={b('values-value')}>{currentStock}</span>
          </div>
        </div>
      </div>
    </LayoutPanel>
  );
};

export default PrizeDiyStockInformation;
