import {Langs} from 'client/services/hooks/useLanguage/types';

import validatePeriodBlock from 'client/components/lottery/fieldsets/lottery-period-block/validate';
import validatePrizeMapTable from 'client/components/lottery/tables/lottery-prize-maps-table/validate';

import {LotteryPrizeCustomizationValues} from './types';

export default (
  formValues: LotteryPrizeCustomizationValues,
  args: {lang: Langs<'LOTTERY.MODALS.LOTTERY_PRIZE_CUSTOMIZATION_MODAL.ERRORS'>},
) => {
  return {
    ...validatePrizeMapTable(formValues, args),
    ...validatePeriodBlock(formValues, args),
  };
};
