import find from 'lodash/find';

import {APP_ROLES} from 'client/common/config';

export function updateMembershipsFromForm(initialItems, formValues) {
  const result = [];

  initialItems.forEach((item) => {
    if (!find(formValues, {value: item.id})) {
      result.push({
        id: item.transferableMembershipId,
        _destroy: true,
      });
    }
  });

  formValues.forEach((item) => {
    if (!find(initialItems, {id: item.value})) {
      result.push({
        membership_id: item.value,
      });
    }
  });

  return result;
}

export function inMemberships(memberships, user) {
  return !!find(memberships, {client_user_id: user.id});
}

export function canManipulateTransfer(report, currentUser) {
  const {memberships, allowed_for_all} = report;

  return (
    allowed_for_all ||
    [APP_ROLES.SUPER_ADMIN, APP_ROLES.SUPER_ADMIN].includes(currentUser.role) ||
    inMemberships(memberships, currentUser)
  );
}
