export function selectParticipationsDisplayItems(state) {
  return state.participationDisplayItems.payload || [];
}
export function selectColumnVisibilityItems(state) {
  return state.participationDisplayItems.visibilityItems;
}

const convertRecordType = (str) =>
  str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();

export function selectColumnVisibilityItemsMapped(state) {
  return state.participationDisplayItems.visibilityItems.map(({column_adapter: {name, kind, record_type}}) => ({
    name: `${convertRecordType(record_type)}__${name}`,
    label: `${record_type} ${name}`,
    path: `${convertRecordType(record_type)}__${name}`,
    record_type,
    kind,
  }));
}

export function selectParticipationsDisplayItemsMappedTable(state) {
  const displayItems = selectParticipationsDisplayItems(state);

  return displayItems.map((item) => {
    return {
      name: item.column_adapter && item.column_adapter.name,
      type: item.column_adapter && item.column_adapter.type,
      id: item.column_adapter && item.column_adapter.column_id,
    };
  });
}
