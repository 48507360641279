import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {STATUS_TYPES} from 'client/common/config';

import Icon from '../icon';

import '../status-buttons/status-buttons.scss';

function VisibleStatusButtons({onClick, title, className, languageState}) {
  const LANGUAGE_STATUSES = languageState.payload.VISUALS.PREVIEW_MODAL.VISIBLE_STATUSES;

  return (
    <div className={`status-buttons ${className}`}>
      {title && <div className="status-buttons__title">{title}</div>}
      <div className="status-buttons__btns">
        <button
          className="status-buttons__btn status-buttons__btn--validated"
          onClick={() => onClick(STATUS_TYPES.VALIDATED)}
          title={LANGUAGE_STATUSES.validated}
        >
          <Icon name="check" className="status-buttons__icon" />
        </button>
        <button
          className="status-buttons__btn status-buttons__btn--canceled"
          onClick={() => onClick(STATUS_TYPES.CANCELED)}
          title={LANGUAGE_STATUSES.canceled}
        >
          <Icon name="return" className="status-buttons__icon" />
        </button>
      </div>
    </div>
  );
}

VisibleStatusButtons.propTypes = {
  onClick: PropTypes.func,
  languageState: PropTypes.object.isRequired,
  title: PropTypes.any,
  className: PropTypes.string,
};

VisibleStatusButtons.defaultProps = {
  onClick: () => {},
  title: '',
  className: '',
};

export default connect(({languageState}) => ({languageState}))(VisibleStatusButtons);
