import React, {useEffect} from 'react';

import {connect} from 'react-redux';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams, generatePath} from 'react-router-dom';
import {reduxForm} from 'redux-form';

import {useLanguage, useReduxForm} from 'client/services/hooks';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {deleteAnimation} from 'client/ducks/animations/actions-ts';
import {selectClientParams} from 'client/ducks/operations/selectors';

import AppButton from 'client/common/buttons';
import {CLIENT_PAGES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';
import LoadingSpinner from 'client/components/common/loading-spinner';

import AnimationAnomaliesInfo from 'client/components/animations/components/animation-anomalies-info';
import AnimationDaysInfo from 'client/components/animations/components/animation-days-info';
import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container';
import validate from 'client/components/animations/containers/animation-config-container/validate';
import useFetchAvailableDays from 'client/components/animations/hooks/useFetchAvailableDays';
import {ANIMATION_STATUSES} from 'client/components/instore/constants';
import {getAnimationAnomalyStatus, getAnimationValidationStatus} from 'client/components/instore/helpers';
import {ApiDispatch, MainStates} from 'client/types/common/redux';

import {useGetAnimation} from './hooks';

import AnimationConfigMobileContainer from '../animation-config-container/animation-config-mobile-container';
import initial from '../animation-config-container/initial';
import mapping from '../animation-config-container/mapping';

const path =
  CLIENT_PAGES.CLIENTS +
  '/:clientId' +
  CLIENT_PAGES.OPERATIONS +
  '/:operationId' +
  CLIENT_PAGES.INSTORE_TASKS +
  '/:taskId' +
  CLIENT_PAGES.ANIMATIONS +
  '/:animationId' +
  CLIENT_PAGES.MONITORING;

type AnimationPageContainerProps = {
  campaignId?: number;
};

// Page of created Animation.
const AnimationPageContainer: ReduxFormFC<AnimationPageContainerProps, Record<string, string>> = (props) => {
  const {campaignId} = props;
  const lang = useLanguage('ANIMATIONS.CONTAINER.ANIMATION_CONFIG_MOBILE_CONTAINER');

  const params = useParams<{animationId: string}>();
  const history = useHistory();
  const {loading, animation, changeStatus, fetchAnimation, saveAnimation} = useGetAnimation(
    params.animationId,
    campaignId,
  );

  const {initialize, formValues} = useReduxForm(AnimationConfigContainerFormName);
  const dispatch: ApiDispatch = useDispatch();
  const clientParams = useSelector(selectClientParams);

  const readOnly = animation?.status !== ANIMATION_STATUSES.EDIT;

  useFetchAvailableDays({
    kitMappingId: formValues?.kit_mapping?.id,
    month: formValues?.month,
    date: formValues?.kit_mapping?.from,
    campaignId,
  });

  useEffect(() => {
    if (animation?.id) {
      initialize(initial({animation}));
    }
  }, [initialize, animation]);

  const handleMonitorClick = () => {
    history.push(generatePath(path, {...params}));
  };

  const handlePhotosClick = () => history.push(location.pathname + CLIENT_PAGES.PHOTOS);

  const handleCancelSubmittingClick = async () => {
    await changeStatus(ANIMATION_STATUSES.EDIT);
    fetchAnimation();
  };

  const handleSaveClick = (status: string) => async () => {
    const body = mapping({formValues, status, animation, campaignId});
    await saveAnimation({instore_animation: body});
    fetchAnimation();
  };

  const onDelete = () => {
    dispatch(deleteAnimation(animation.id)).then(() => {
      const link = CustomLink.createClientInstoreTaskTabLink({
        ...params,
        tab: CLIENT_PAGES.CLIENT_INSTORE_TASK_TABS.PLANNING,
      });
      history.push(link + clientParams);
    });
  };

  const showAnomaliesInfo =
    !!animation?.status &&
    [
      ANIMATION_STATUSES.PRODUCTION,
      ANIMATION_STATUSES.RUNNING,
      ANIMATION_STATUSES.FINISHED,
      ANIMATION_STATUSES.REPORT,
    ].includes(animation.status);
  const showCancelSubmitting = ANIMATION_STATUSES.ISSUED === animation?.status;

  const hrStatus = getAnimationValidationStatus(animation, 'hr_status');
  const installStatus = getAnimationValidationStatus(animation, 'install_status');
  const animationDaysStatus = getAnimationAnomalyStatus(animation, 'animation_days');

  return (
    <>
      <LoadingSpinner loading={loading}>
        <AnimationConfigMobileContainer
          animation={animation}
          readOnly={readOnly}
          onOpenMonitoring={handleMonitorClick}
          onOpenPhotos={handlePhotosClick}
          onSubmit={handleSaveClick(ANIMATION_STATUSES.ISSUED)}
          onSaveDraft={handleSaveClick(ANIMATION_STATUSES.EDIT)}
          onCancelSubmitting={handleCancelSubmittingClick}
          onDelete={onDelete}
          anomaliesContent={
            showAnomaliesInfo ? (
              <>
                {(hrStatus || installStatus || animationDaysStatus || animation?.comments) && (
                  <AnimationAnomaliesInfo animation={animation} />
                )}
                <AnimationDaysInfo animation={animation} />
              </>
            ) : null
          }
        />
        {showCancelSubmitting && (
          <AppButton label={lang.CANCEL_SUBMITTING_BUTTON} onClick={handleCancelSubmittingClick} color="clients" />
        )}
      </LoadingSpinner>
    </>
  );
};

const form = reduxForm({
  form: AnimationConfigContainerFormName,
  validate,
})(AnimationPageContainer);

export default connect((state: MainStates) => ({
  lang: state.languageState.payload.ANIMATIONS,
}))(form);
