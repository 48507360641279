import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import cssModule from './prize-popover.module.scss';

const b = bem('prize-popover', {cssModule});

type PrizePopoverProps = {
  children: React.ReactNode;
};

const PrizePopover: React.FC<PrizePopoverProps> = (props) => {
  const {children} = props;
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.BLOCKS.PRIZE_BLOCK.PRIZE_POPOVER');

  return (
    <Popover
      className={b()}
      trigger="click"
      contentClassName={b('content')}
      arrowConfig={{
        fill: '#404f6b',
        stroke: '#404f6b',
        tipRadius: 1,
        width: 20,
        height: 10,
      }}
      overlay={
        <div className={b('overlay')}>
          <p className={b('item')}>
            <span className={b('text')}>{lang.NAME}</span>
          </p>
          <p className={b('item')}>
            <span className={b('text')}>{lang.IMAGE}</span>
            <Icon name="image-3" />
          </p>
          <p className={b('item')}>
            <span className={b('text')}>{lang.DESCRIPTION}</span>
          </p>
          <p className={b('item')}>
            <span className={b('text')}>{lang.VISUAL_TOKEN}</span>
            <Icon name="visual-token" />
          </p>
          <p className={b('item')}>
            <span className={b('text')}>{lang.STRING_TOKEN}</span>
          </p>
        </div>
      }
    >
      {children}
    </Popover>
  );
};

export default PrizePopover;
