import React, {memo} from 'react';

import find from 'lodash/find';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import GamesAddButtons from './components/games-add-buttons';
import GamesData from './components/games-data';
import GamesHeader from './components/games-header';

import './games-table.scss';

const b = bem('games-table');

const GamesTable = (props) => {
  const {games, onEditClick, onAddGame} = props;
  const lang = useLanguage('GAMES.GAMES_CARD.GAMES_TABLE');

  return (
    <div className={b()}>
      <GamesHeader />
      <div className={b('data')}>
        {[1, 2, 3].map((winNumber) => {
          const game = find(games, {win_number: winNumber});
          return game ? (
            <GamesData key={winNumber} lang={lang} game={game} onEditClick={() => onEditClick(game)} />
          ) : (
            <GamesAddButtons winNumber={winNumber} key={winNumber} onClick={() => onAddGame(winNumber)} />
          );
        })}
      </div>
    </div>
  );
};

GamesTable.propTypes = {
  games: PropTypes.array.isRequired,
  onAddGame: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default memo(GamesTable);
