import {hasStartedInteraction} from 'client/models/interactions/helpers';

import {mapInterfaceLevels} from './mappers';

export function selectInterfaces(state) {
  return state.interfaces.interfacesIds.map((id) => {
    const interfaceItem = state.interfaces.interfacesById[id];
    const {interface_levels = []} = interfaceItem;
    let itemsWithOptIns = [];

    const optIns =
      interfaceItem.interface_items &&
      interfaceItem.interface_items.reduce((result, item) => {
        const optInItems = item.mapping_items
          .filter((mappingItem) => {
            return mappingItem.type === 'OptInMappingItem';
          })
          .map((filteredMappingItem) => {
            itemsWithOptIns.push(item.id);

            return {
              name: filteredMappingItem.column_adapter.name,
              text: filteredMappingItem.opt_in_text,
              level: filteredMappingItem.column_adapter.opt_in_column.level,
            };
          });

        return optInItems.length ? [...result, ...optInItems] : result;
      }, []);

    return {
      ...interfaceItem,
      optIns: optIns,
      itemsWithOptIns,
      has_real_participations: hasStartedInteraction(interfaceItem?.interactions, true),
      interface_levels: mapInterfaceLevels(interface_levels),
    };
  });
}

export function selectInterfacesWithCodes(state) {
  return state.interfaces.interfacesIds.map((id) => ({
    id,
    code: state.interfaces.interfacesById[id].code,
  }));
}

export function selectInterfacesWithNames(state) {
  return state.interfaces.interfacesIds.map((id) => ({
    id,
    name: state.interfaces.interfacesById[id].name,
  }));
}

export function selectInterfacesByType(state, type) {
  return state.interfaces.interfacesIds.reduce((result, id) => {
    const interfaceItem = state.interfaces.interfacesById[id];

    return interfaceItem && interfaceItem.type === type ? [...result, interfaceItem] : result;
  }, []);
}

export function selectInterfacesMeta(state) {
  return state.interfaces.interfacesMeta;
}
