// Normalizers
// A function to convert whatever value the user has entered into the value that you want stored in the Redux store for the field.
// Docs: https://redux-form.com/7.3.0/docs/api/field.md/#-code-format-value-name-gt-formattedvalue-code-optional-
import {getAppLanguage} from 'client/common/config';

export const normalizeStringToBoolean = (value) => value === 'true';

export const normalizeStringToUpperCase = (value) => value.toUpperCase();

export const normalizeToSelectOption = (v) => {
  return v.value ? String(v.value) : '';
};

export const normalizeToNull = (value) => (value === 'null' ? null : value);

export const normalizeVariablesToIds = (variables) => (value) => {
  const regex = /{{([^}{]*)}}/g;
  let result = value;
  let match = regex.exec(value);

  while (match) {
    const variable = variables.find((i) => i.full_name === match[1]); // eslint-disable-line no-loop-func
    result = result.replace(match[0], `{{${variable ? variable.id : match[1]}}}`);
    match = regex.exec(value);
  }

  return result;
};

// Formatters
// Formats the value from the Redux store to be displayed in the field input
// Docs: https://redux-form.com/7.3.0/docs/api/field.md/#-code-normalize-value-previousvalue-allvalues-previousallvalues-gt-nextvalue-code-optional-

export const formatToString = (value) => String(value);

export const formatToSelectOption = (v) => {
  return v ? {value: String(v)} : {};
};

export const formatIdsToVariables = (variables) => (value) => {
  const regex = /{{([0-9]*)}}/g;
  let result = value;
  let match = regex.exec(value);

  while (match) {
    const variable = variables.find((i) => String(i.id) === match[1]); // eslint-disable-line no-loop-func
    result = result.replace(match[0], `{{${variable ? variable.full_name : match[1]}}}`);
    match = regex.exec(value);
  }

  return result;
};

export const formatNumber = (number) => (number.toString().length === 1 ? `0${number}` : number);

export function formatNumberSpace(number) {
  if (!number && number !== 0) {
    return '';
  }

  const numberString = `${number}`;
  const numberArray = numberString.split('').reverse();

  return numberArray
    .reduce((acc, digit, index) => {
      if (index % 3 === 0) {
        return digit + ' ' + acc;
      }
      return digit + acc;
    }, '')
    .trim();
}

export function formatFloat(number) {
  return number.toLocaleString(getAppLanguage());
}

export function formatFileSize(size) {
  if (!size) {
    return null;
  }

  const units = ['b', 'kb', 'mb', 'gb', 'tb'];
  const index = Math.floor(Math.log(size) / Math.log(1024));
  const unit = units[index];
  const value = (size / Math.pow(1024, index)).toFixed(2) * 1;

  return `${value} ${unit}`;
}

export const capitalizeFirstLetter = (string) => (string ? string[0].toUpperCase() + string.slice(1) : '');

export const removeSpaces = (value) => value?.replaceAll(' ', '') || '';
