import React, {PureComponent} from 'react';

import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';

import {getFileSize} from 'client/services/helpers';
import {
  anyString,
  date,
  decimal,
  email,
  fileFormat,
  fileSize,
  number,
  phone,
  required,
} from 'client/services/validator';

import Icon from 'client/common/icon';

import fieldTemplate from 'client/components/common/field';
import ImgPreview from 'client/components/common/img-preview';

const maxFileSize = 31457280;

class TestVars extends PureComponent {
  static propTypes = {
    change: PropTypes.func,
    initialize: PropTypes.func,
    formValues: PropTypes.object,
    languageState: PropTypes.object.isRequired,
    variables: PropTypes.array,
    notCheckedFields: PropTypes.array,
    untouch: PropTypes.func,
  };

  static defaultProps = {
    variables: [],
    notCheckedFields: [],
  };

  constructor(props) {
    super(props);

    this.rules = {
      required: required(this.props.languageState.errors.REQUIRED),
      email: email(this.props.languageState.errors.EMAIL),
      phone: phone(this.props.languageState.errors.PHONE),
      string: anyString(this.props.languageState.errors.STRING),
      integer: number(this.props.languageState.errors.INTEGER),
      decimal: decimal(this.props.languageState.errors.DECIMAL),
      datetime: date(this.props.languageState.errors.DATETIME),
      size: fileSize(`${this.props.languageState.errors.MAX_FILE_SIZE} 30MB`, maxFileSize),
      format: fileFormat(this.props.languageState.errors.FILE_FORMAT),
    };

    this.state = {
      booleanFields: {},
      imageKeys: {},
    };
  }

  componentDidMount() {
    this.props.variables.forEach((item) => {
      switch (item.kind) {
        case 'boolean':
          this.setState({
            booleanFields: {
              ...this.state.booleanFields,
              ['_' + item.id]: '',
            },
          });
          break;
        default:
          break;
        //
      }
    });

    this.initializeForm(this.props.variables);
  }

  initializeForm = (variables) => {
    const initial = {};

    const datetime = variables.filter((v) => v.kind === 'datetime');

    if (!datetime.length) {
      return;
    }

    const now = moment();

    datetime.forEach((dt) => {
      initial[`_${dt.id}`] = moment({
        year: now.get('year'),
        month: now.get('month'),
        date: now.get('date'),
        hours: now.get('hours'),
        minutes: now.get('minutes'),
        seconds: 0,
      });
    });

    this.props.initialize(initial);
  };

  getValidators = (kind, isRequired) => {
    let validators = [];
    if (isRequired) {
      validators.push(this.rules.required);
    }
    validators.push(this.rules[kind]);
    return validators;
  };

  validateFile = (type, size) => /^image\/(p?jpe?g|png|(x-)?tiff|gif)$/.test(type) && size < maxFileSize;

  handleRemoveImage = (id) => {
    this.props.change(`_${id}`, '');
    this.props.untouch(`_${id}`);

    // The reason for using a dynamic key is that the file type field is not reset
    // by simply calling the change() function from redux-form.
    // Because of this, it is impossible to load the same picture in a field twice
    // https://github.com/erikras/redux-form/issues/769#issuecomment-215411743
    this.setState({
      imageKeys: {
        ...this.state.imageKeys,
        [id]: id + Math.random(),
      },
    });
  };

  renderTextField = (variable) => {
    return (
      <Field
        placeholder={this.props.languageState.form.ADD_VAR_PLACEHOLDER}
        label={`${variable.full_name || variable.name} ${variable.required ? '*' : ''}`}
        name={'_' + variable.id}
        type="text"
        component={fieldTemplate}
        validate={this.getValidators(variable.kind, variable.required)}
      />
    );
  };

  renderFileField = (variable) => {
    const {imageKeys} = this.state;
    const {id} = variable;
    const imageKey = imageKeys[id] || id;
    const file = get(this.props.formValues, `_${variable.id}.[0]`);

    const imageUrl = file ? window.URL.createObjectURL(file) : null;
    const imageSize = file ? getFileSize(file.size) : null;
    const imageTitle = file ? file.name : null;
    const validType = file ? this.validateFile(file.type, file.size) : false;

    return (
      <div className="test-step__form-item test-step__form-item--view-3">
        <div className="test-step__form-title test-step__form-title--view-1">
          {`${variable.full_name || variable.name} ${variable.required ? '*' : ''}`}
        </div>
        <div className="ats-step__form-load-buttons">
          <Field
            key={imageKey}
            cssModifier="input-file--view-1 ats-step__form-load-button"
            buttonCssClass="button--bg-13"
            buttonText={this.props.languageState.form.LOAD_IMAGE_BUTTON}
            htmlFor={id}
            name={`_${id}`}
            accept="image/*"
            type="file"
            validate={[this.rules.format, this.rules.size]}
            component={fieldTemplate}
          />
        </div>
        {validType && (
          <div className="ats-step__form-item ats-step__form-item--view-2">
            <ImgPreview
              className="ats-step__form-load-img"
              imgWrapClass="ats-step__form-img-wrap"
              imgDescrClass="ats-step__form-img-descr"
              imgTitleClass="ellipsis-text"
              src={imageUrl}
              title={imageTitle}
              descr={imageSize}
            />
            <button className="button button--circle button--bg-13" onClick={() => this.handleRemoveImage(id)}>
              <Icon name="trash" className="button__icon" width={17} height={19} />
            </button>
          </div>
        )}
      </div>
    );
  };

  renderBooleanField = (variable) => {
    const {booleanFields} = this.state;
    const fieldVal = booleanFields[`_${variable.id}`];
    const {notCheckedFields} = this.props;
    const requiredNotChecked = notCheckedFields.indexOf(variable.id) !== -1;

    return (
      <div>
        <div className="test-step__form-item test-step__form-item--view-3">
          <div className="test-step__form-title test-step__form-title--view-3" key="title">
            {`${variable.full_name || variable.name} ${variable.required ? '*' : ''}`}
          </div>
          <div className="test-step__form-field-wrap test-step__form-field-wrap--flex" key="buttons">
            <div className="test-step__form-field test-step__form-field--size-7 theme-color-7">
              <Field
                label={this.props.languageState.form.TRUE_LABEL}
                name={`_${variable.id}`}
                type="radio"
                value="true"
                component={fieldTemplate}
                onChange={this.handleRadioChange}
              />
            </div>
            <div className="test-step__form-field test-step__form-field--size-7 theme-color-7">
              <Field
                label={this.props.languageState.form.FALSE_LABEL}
                name={`_${variable.id}`}
                type="radio"
                value="false"
                component={fieldTemplate}
                onChange={this.handleRadioChange}
              />
            </div>
          </div>
          {!fieldVal && requiredNotChecked && (
            <div className="message-error-text test-step__group-error">{this.props.languageState.errors.REQUIRED}</div>
          )}
        </div>
      </div>
    );
  };

  renderDateTimeField = (variable) => {
    return (
      <div className="test-step__form-field-wrap test-step__form-field-wrap--view-3">
        <div className="test-step__form-field">
          <Field
            label={variable.full_name || variable.name}
            name={'_' + variable.id}
            type="datetimepicker"
            labelBlock={true}
            component={fieldTemplate}
            validate={this.getValidators(variable.kind, variable.required)}
          />
        </div>
      </div>
    );
  };

  renderVariable = (variable) => {
    let field;

    switch (variable.kind) {
      case 'email':
      case 'phone':
      case 'string':
      case 'integer':
      case 'decimal':
        field = this.renderTextField(variable);
        break;
      case 'file':
        field = this.renderFileField(variable);
        break;
      case 'boolean':
        field = this.renderBooleanField(variable);
        break;
      case 'datetime':
        field = this.renderDateTimeField(variable);
        break;
      default:
      //
    }

    return (
      <div key={variable.id} className="form-field-wrap">
        {field}
      </div>
    );
  };

  renderVariables = (variables) => {
    return variables.map((variable) => this.renderVariable(variable));
  };

  handleRadioChange = (e) => {
    const {
      target: {name, value},
    } = e;
    this.setState({
      booleanFields: {
        ...this.state.booleanFields,
        [name]: value,
      },
    });
  };

  render() {
    const {variables} = this.props;

    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div>{this.renderVariables(variables)}</div>
      </form>
    );
  }
}

const TestVarsForm = reduxForm({
  form: 'TestParametersForm',
})(TestVars);

export default TestVarsForm;
