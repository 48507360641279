import types from './types';

const initialState = {
  operationData: [],
  columns: [],
  visibleColumns: [],
  systemColumns: [],
  meta: {},
};

export default function (_state = initialState, action) {
  const state = Object.assign({}, _state);

  switch (action.type) {
    case types.GET_CUSTOM_OPERATION_DATA_SUCCESS:
      state.operationData = action.payload.operation_data;
      state.meta = action.payload.meta;
      return state;

    case types.GET_COLUMNS_SUCCESS:
      state.columns = action.payload.operation_datum_columns;
      return state;

    case types.GET_SYSTEM_COLUMNS_SUCCESS:
      state.systemColumns = action.payload.system_column_adapters;
      return state;

    case types.GET_VISIBLE_COLUMNS_SUCCESS:
      state.visibleColumns = action.payload.operation_datum_display_items;
      return state;

    case types.GET_OPERATION_DATA_SUCCESS:
      state.operationData = action.payload.operation_data;
      return state;

    default:
      return state;
  }
}
