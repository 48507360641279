import {cloneDeep} from 'lodash';

import {USER_MEMBERSHIP} from 'client/common/config';

import {ClientUserModalForm} from 'client/components/client-account-parameters/modals/client-user-modal/types';
import {Client} from 'client/models/client/types';
import {CompanyAccess, Membership} from 'client/models/memberships/types';
import {PlaceAccess} from 'client/models/places/types';
import {RegionAccess} from 'client/models/regions/types';

type MembershipRequestBody = Omit<Membership, 'region_accesses' | 'place_accesses' | 'company_accesses'> & {
  region_accesses: Partial<RegionAccess>[];
  place_accesses: Partial<PlaceAccess>[];
  company_accesses: Partial<CompanyAccess>[];
};

const mappingMembership = (
  values: ClientUserModalForm,
  params: {userId?: number; client: Client; initial?: Membership | null},
) => {
  const {client, userId, initial} = params;
  const isAgency = client.type === 'Agency';

  const formMembership = values.memberships![0];

  const body: Partial<MembershipRequestBody> = {
    id: formMembership.id,
    client_id: client.id,
    client_user_id: userId,
    access_level: formMembership.access_level,
    title: formMembership.title,
    phone: formMembership.phone,
    type: isAgency ? 'AgencyMembership' : 'CompanyMembership',
  };

  // Only if current client is agency AND new user is National, all_companies_access exists
  if (isAgency && formMembership.access_level === USER_MEMBERSHIP.NATIONAL) {
    body.all_companies_access = !!formMembership.all_companies_access;
    if (!body.all_companies_access) {
      body.company_accesses = formMembership.company_accesses
        ?.filter((id) => !initial?.company_accesses?.some((access) => access.company_id === +id))
        .map((id) => ({company_id: +id}));

      initial?.company_accesses?.forEach((access) => {
        if (!formMembership?.company_accesses?.some((id) => access.company_id === +id)) {
          body.company_accesses?.push({id: access.id, _destroy: true});
        }
      });
    }
  }

  const hasRegionsStep = [USER_MEMBERSHIP.REGION, USER_MEMBERSHIP.LOCAL].includes(
    formMembership?.access_level as string,
  );

  if (hasRegionsStep) {
    body.region_accesses = formMembership.region_accesses
      ?.filter((id) => !initial?.region_accesses?.some((access) => access.region_id === +id))
      .map((id) => ({region_id: +id}));

    initial?.region_accesses?.forEach((access) => {
      if (!formMembership?.region_accesses?.some((id) => access.region_id === +id)) {
        body.region_accesses?.push({id: access.id, _destroy: true});
      }
    });
  }
  if (formMembership.access_level === USER_MEMBERSHIP.STORE) {
    body.place_accesses = formMembership.place_accesses
      ?.filter((id) => !initial?.place_accesses?.some((access) => access.place_id === +id))
      .map((id) => ({place_id: +id}));
    initial?.place_accesses?.forEach((access) => {
      if (!formMembership?.place_accesses?.some((id) => access.place_id === +id)) {
        body.place_accesses?.push({id: access.id, _destroy: true});
      }
    });
  }

  return body;
};

export default (
  values: ClientUserModalForm,
  params: {client: Client; userId?: number; initial?: Membership | null},
) => {
  const {client, userId, initial} = params;

  const body = {
    ...cloneDeep(values),
    client_id: client.id,
    memberships: [mappingMembership(values, {client, userId, initial})],
  };

  return body;
};
