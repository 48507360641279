import {useCallback, useEffect, useMemo} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import {selectAutotaskParam} from 'client/ducks/autotask/selectors';
import {clearDiyOperationBlocks, getPrizesByGameId} from 'client/ducks/diy-operation/actions';
import {selectDiyPrizeBlock, selectDiyPrizeGame} from 'client/ducks/diy-operation/selectors';

import {Game} from 'client/models/prizes/types';
import {ApiDispatch} from 'client/types';

type UseFetchDiyGameConfig = {
  onMount?: boolean;
};

export const useFetchDiyGame = (config?: UseFetchDiyGameConfig) => {
  const {onMount} = config || {};

  const dispatchApi: ApiDispatch = useDispatch();
  const dispatch = useDispatch();
  const prizeBlock = useSelector(selectDiyPrizeBlock);
  const game: Game = useSelector(selectDiyPrizeGame);
  const [loading, toggleLoading] = useToggle(!!onMount);

  const clientGames: Game[] = useSelector(selectAutotaskParam('client_games'));

  const clientGameId = useMemo(() => {
    return clientGames?.find((g) => g.game_type === 'instant_win')?.id;
  }, [clientGames]);

  const fetchGame = useCallback(() => {
    const params = {
      include_prize_picto_translations: 'null',
      include_prize_images: 'null',
      include_prize_win_pop_up_image_translations: true,
      include_prize_ticket_image_translations: true,
      include_prize_email_image_translations: true,
      include_prize_total_quantities: true,
      include_prize_device_quantities: true,
      include_prize_online_quantities: true,
      include_client_coupon_imports: true,
      include: [
        'prizes',
        'prizes.coupon.coupon_code_intervals',
        'prizes.instant_win_configuration',
        'prizes.time_instant_win_configuration',
        'prizes.schedule_instant_win_configuration.prize_timestamps',
        'prizes.participation_instant_win_configuration',
        'prizes.schedule_instant_win_configuration',
      ],
    };
    toggleLoading(true);
    return dispatchApi(getPrizesByGameId(clientGameId, params)).then(() => toggleLoading(false));
  }, [dispatchApi, clientGameId, toggleLoading]);

  useEffect(() => {
    if (onMount) {
      if (clientGameId) {
        fetchGame();
      }
      return () => {
        dispatch(clearDiyOperationBlocks());
      };
    }
    return () => {};
  }, [dispatch, clientGameId, fetchGame, onMount]);

  return {prizeBlock, game, fetchGame, loading};
};
