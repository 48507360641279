import React, {useCallback, useEffect, useState} from 'react';

import {closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {Prize} from 'client/models/prizes/types';

import {headers} from '../helpers';
import PrizesTableRow from '../prizes-table-row';

const b = bem('prizes-table');

type PrizesTableContainerProps = {
  prizes: Prize[];
  onSortEnd: (params: {oldIndex: number; newIndex: number}) => void;
  onSelectPrize: (index: number) => void;
  onDelete: (id: string) => void;
  onActivate: (id: string, active: boolean) => void;
  isPrizeMap: boolean;
  disabledActions: boolean;
  disabledActivation?: boolean;
};

const PrizesTableContainer: React.FC<PrizesTableContainerProps> = ({
  prizes,
  onSelectPrize,
  onDelete,
  onActivate,
  isPrizeMap,
  disabledActions,
  disabledActivation,
  onSortEnd,
}) => {
  const lang = useLanguage('GAMES.GAME_CONFIG_MODAL.PRIZES_TABLE');
  const [sortedPrizes, setSortedPrizes] = useState(prizes);

  const emptyHandler = useCallback(() => {}, []);

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;

    if (active.id !== over?.id) {
      setSortedPrizes((items) => {
        const oldIndex = items.findIndex((prize) => prize.id === active.id);
        const newIndex = items.findIndex((prize) => prize.id === over?.id);

        const next = arrayMove(items, oldIndex, newIndex);
        onSortEnd({oldIndex, newIndex});
        return next;
      });
    }
  };

  useEffect(() => {
    setSortedPrizes(prizes.sort((aP, bP) => aP.order - bP.order));
  }, [prizes]);

  return (
    <div className={b('content')}>
      <div className={b('row', ['header'])}>
        {headers(lang, isPrizeMap).map((header) => (
          <div className={b('col', ['header', header.key])} key={header.key}>
            {header.title}
          </div>
        ))}
      </div>

      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={sortedPrizes} strategy={verticalListSortingStrategy}>
          {sortedPrizes.map((prize, index) => (
            <PrizesTableRow
              disabled={disabledActions}
              disabledActivation={!!disabledActivation}
              isPrizeMap={isPrizeMap}
              prize={prize}
              onDelete={disabledActions ? emptyHandler : onDelete}
              onActivate={disabledActions ? emptyHandler : onActivate}
              key={prize.id}
              order={index + 1}
              index={index}
              onSelectPrize={disabledActions ? emptyHandler : () => onSelectPrize(index)}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default PrizesTableContainer;
