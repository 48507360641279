import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {set} from 'client/common/meta/meta.actions';

import Parameters from '../components/parameters';

class ParametersPage extends Component {
  componentDidMount() {
    this.props.setMetaAction({
      title: this.props.lang.TITLE,
    });
  }

  render() {
    return <Parameters />;
  }
}

ParametersPage.propTypes = {
  setMetaAction: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    lang: state.languageState.payload.PARAMETERS,
  }),
  (dispatch) => ({
    setMetaAction: bindActionCreators(set, dispatch),
  }),
)(ParametersPage);
