import {OptInColumn} from 'client/models/opt-in-column/types';

import {LeadOptinValues} from './types';

const getInitialLanguages = (data?: OptInColumn) => {
  if (!data) {
    return ['fr'];
  }
  return [...new Set([...Object.keys(data.opt_in_text_translations), ...Object.keys(data.opt_out_text_translations)])];
};

export default (data?: OptInColumn): LeadOptinValues => {
  const initialLanguages = getInitialLanguages(data);

  return {
    level: Number(data?.level) || 1,
    code: data?.code,
    opt_in_type: data?.opt_in_type,
    mandatory_order: data?.mandatory_order?.toString(),
    name: data?.name,
    comment: data?.comment,
    current_language: 'fr',
    initial_languages: initialLanguages,
    languages: initialLanguages,
    optin_texts: data?.opt_in_text_translations || {},
    optout_texts: data?.opt_out_text_translations || {},
    editable: data ? data.editable : true,
  };
};
