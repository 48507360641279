import validateAnimator from 'client/components/animations/fieldsets/animation-animator-fieldset/validate';

/**
 *
 * @param formValues
 * @param props
 * @return {object}
 */
export default (formValues, props) => {
  const {lang} = props;

  let errors = {};

  const completedBaseData = !!(
    formValues?.access?.membership?.id &&
    formValues?.place?.network_id &&
    formValues?.place?.id
  );

  if (completedBaseData) {
    if (!formValues.kit_mapping_id) {
      errors.kit_mapping_id = lang.ERRORS.REQUIRED;
    }
    if (!formValues.animation_days?.length) {
      errors.animation_days = lang.ERRORS.REQUIRED;
    }

    errors = {
      ...errors,
      ...validateAnimator(formValues, {lang}),
    };
  }

  return errors;
};
