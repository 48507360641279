import React, {Component} from 'react';

import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

import InfoBlock from '../info-block';

export default class ImageCard extends Component {
  static propTypes = {
    title: TranslationJsx,
    header: PropTypes.node,
    children: PropTypes.node,
  };

  render() {
    const {title, header, children} = this.props;

    return (
      <InfoBlock>
        <InfoBlock.Title>{title}</InfoBlock.Title>
        <InfoBlock.Content>
          <InfoBlock.Header>{header}</InfoBlock.Header>
          <InfoBlock.Body className="image-card__body">{children}</InfoBlock.Body>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}
