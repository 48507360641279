import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';

import './animation-icon-cell.scss';

const b = bem('animation-icon-cell');

const AnimationIconCell = (props) => {
  const {className, item, field, iconNameGetter, iconPrefix, children, testId} = props;

  const iconName = iconNameGetter?.(item, field) || null;

  if (!iconName && !children) {
    return null;
  }

  return (
    <div className={cn(b(), className)} data-testid={testId}>
      {iconName && <Icon name={`${iconPrefix}-${iconName}`} />}
      {children}
    </div>
  );
};

AnimationIconCell.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  field: PropTypes.string,
  iconPrefix: PropTypes.string,
  iconNameGetter: PropTypes.func,
  children: PropTypes.node,
  testId: PropTypes.string,
};

AnimationIconCell.defaultProps = {
  className: null,
  item: null,
  field: null,
  iconPrefix: null,
  iconNameGetter: null,
  children: null,
  testId: 'animation-icon-cell',
};

export default AnimationIconCell;
