import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';

import {TranslationJsx} from 'client/models/language/types';

import './checkbox-control.scss';

const CheckboxControl = (props) => {
  const {checked, text, textElement, onChange, className, color} = props;

  const boxStyle = color && checked ? {backgroundColor: color} : null;

  return (
    <div className={cn(['checkbox-control', className])} onClick={onChange}>
      <div
        style={boxStyle}
        className={cn('checkbox-control__box', {
          'checkbox-control__box--checked': checked,
        })}
      >
        {checked && <Icon name="check-small" className="checkbox-control__check" />}
      </div>
      {(text || textElement) && (
        <div className="checkbox-control__text">
          {text}
          {textElement}
        </div>
      )}
    </div>
  );
};

CheckboxControl.propTypes = {
  checked: PropTypes.bool,
  text: TranslationJsx,
  textElement: PropTypes.node,
  onChange: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string,
};

CheckboxControl.defaultProps = {
  checked: false,
  text: null,
  textElement: null,
  onChange: null,
  className: '',
  color: null,
};

export default CheckboxControl;
