export default {
  GET_CLIENTS_DOMAINS_REQUEST: '@@clients-domains/GET_CLIENTS_DOMAINS_REQUEST',
  GET_CLIENTS_DOMAINS_SUCCESS: '@@clients-domains/GET_CLIENTS_DOMAINS_SUCCESS',
  GET_CLIENTS_DOMAINS_ERROR: '@@clients-domains/GET_CLIENTS_DOMAINS_ERROR',

  CREATE_CLIENTS_DOMAINS_REQUEST: '@@clients-domains/CREATE_CLIENTS_DOMAINS_REQUEST',
  CREATE_CLIENTS_DOMAINS_SUCCESS: '@@clients-domains/CREATE_CLIENTS_DOMAINS_SUCCESS',
  CREATE_CLIENTS_DOMAINS_ERROR: '@@clients-domains/CREATE_CLIENTS_DOMAINS_ERROR',

  UPDATE_CLIENTS_DOMAINS_REQUEST: '@@clients-domains/UPDATE_CLIENTS_DOMAINS_REQUEST',
  UPDATE_CLIENTS_DOMAINS_SUCCESS: '@@clients-domains/UPDATE_CLIENTS_DOMAINS_SUCCESS',
  UPDATE_CLIENTS_DOMAINS_ERROR: '@@clients-domains/UPDATE_CLIENTS_DOMAINS_ERROR',
};
