import React from 'react';

import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import {toggleSubmittedAction} from 'client/ducks/add-place-form/actions';

import PlacesStoresMapModal from 'client/components/common/places-stores-map-modal';
import TitleBlock from 'client/components/common/title-block';

import AddPlaceModal from './components/modals/add-place-modal';
import LinkToClientModal from './components/modals/link-to-client-modal';
import PlacesTable from './components/tables/places-table';

import {toggleSubmittedAction as toggleLinkToClientSubmittedAction} from '../../ducks/link-to-client-form/actions';
import {
  addPlaceAction,
  linkToClientAction,
  getPlacesAction,
  toggleAddPlaceModalAction,
  toggleLinkToClientModalAction,
  toggleMapModalAction,
  changeSelectedPlacesAction,
  getMappedPlacesAction,
  editPlaceAction,
} from '../../ducks/places/actions';

import cssModule from './places.module.scss';

const b = bem('places', {cssModule});

class PlacesContainer extends ReactQueryParams {
  constructor(props) {
    super(props);

    this.state = {
      isPlaceEditing: false,
      editingPlace: {},
      search: '',
      showingPlaceId: null,
    };

    this.LANGUAGE = props.languageState.payload.PLACES;

    this.getMyPlaces = this.getMyPlaces.bind(this);
    this.onUnlinkClientButtonClick = this.onUnlinkClientButtonClick.bind(this);
    this.onLinkToClientButtonClick = this.onLinkToClientButtonClick.bind(this);
    this.onEditPlaceButtonClick = this.onEditPlaceButtonClick.bind(this);
    this.onAddPlaceButtonClick = this.onAddPlaceButtonClick.bind(this);
    this.onEditPlaceClick = this.onEditPlaceClick.bind(this);
    this.onNewPlaceButtonClick = this.onNewPlaceButtonClick.bind(this);
    this.onMapViewButtonClick = this.onMapViewButtonClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentDidMount() {
    this.getMyPlaces();
  }

  processQueryString = (params) => {
    const {page = 1, perPage = 5, search, sort = {}} = params;

    const queryParams = {
      include: ['network.group', 'city.agglomeration.zone', 'client', 'region', 'offline_interactions'],
      q: {
        name_cont: search,
      },
      page,
      per_page: perPage,
      distinct: true,
    };

    switch (sort.name) {
      case 'group_name':
        queryParams.q.s = [`${sort.name} ${sort.order}`, 'network_name', 'zone_name', 'agglomeration_name', 'name'];
        break;
      case 'network_name':
        queryParams.q.s = [`${sort.name} ${sort.order}`, 'zone_name', 'agglomeration_name', 'name'];
        break;
      case 'zone_name':
        queryParams.q.s = [`${sort.name} ${sort.order}`, 'agglomeration_name', 'name'];
        break;
      case 'agglomeration_name':
      case 'city_name':
      case 'city_zip':
        queryParams.q.s = [`${sort.name} ${sort.order}`, 'name'];
        break;
      case 'client_name':
        queryParams.q.s = [`${sort.name} ${sort.order}`, 'zone_name', 'agglomeration_name', 'name'];
        break;
      default:
        queryParams.q.s = `${sort.name} ${sort.order}`;
        break;
    }

    return queryParams;
  };

  getMyPlaces() {
    const {queryParams: params} = this;
    window.scrollTo(0, 0);
    this.props.getPlaces(
      this.processQueryString({
        page: params.page || 1,
        perPage: params.perPage || 5,
        sort: {
          name: (params.sort && params.sort.name) || 'created_at',
          order: (params.sort && params.sort.order) || 'DESC',
        },
        search: params.search,
      }),
    );
  }

  onLinkToClientButtonClick() {
    const {linkToClient, linkToClientForm} = this.props;
    const {selectedPlaces} = this.props.places;

    const linkToClientFormValues = linkToClientForm && linkToClientForm.values ? linkToClientForm.values : {};
    const linkToClientFormErrors = linkToClientForm && linkToClientForm.syncErrors ? linkToClientForm.syncErrors : {};

    if (isEmpty(linkToClientFormErrors) && linkToClientFormValues.client) {
      for (let placeId of selectedPlaces.slice().reverse()) {
        linkToClient({
          id: placeId,
          client_id: linkToClientFormValues.client.id,
          region_id: linkToClientFormValues.region && linkToClientFormValues.region.id,
        });
      }
    }
  }

  static validateAddPlaceFormValues(addPlaceForm) {
    const addPlaceFormValues = addPlaceForm && addPlaceForm.values ? addPlaceForm.values : {};
    const addPlaceFormErrors = addPlaceForm && addPlaceForm.syncErrors ? addPlaceForm.syncErrors : {};

    if (
      isEmpty(addPlaceFormErrors) &&
      addPlaceFormValues.name &&
      addPlaceFormValues.network &&
      addPlaceFormValues.city &&
      addPlaceFormValues.street_address
    ) {
      return {
        name: addPlaceFormValues.name,
        network_id: addPlaceFormValues.network.id,
        city_id: addPlaceFormValues.city.id,
        street_address: `${addPlaceFormValues.street_address}`,
        client_id: (addPlaceFormValues.client && addPlaceFormValues.client.id) || null,
        region_id: (addPlaceFormValues.region && addPlaceFormValues.region.id) || null,
        comment: addPlaceFormValues.comment || null,
      };
    }
    return null;
  }

  onAddPlaceButtonClick() {
    const place = PlacesContainer.validateAddPlaceFormValues(this.props.addPlaceForm);
    if (place) {
      this.props.addPlace(place).catch(() => this.props.toggleSubmitted());
    } else {
      this.props.toggleSubmitted();
    }
  }

  onEditPlaceButtonClick() {
    const place = PlacesContainer.validateAddPlaceFormValues(this.props.addPlaceForm);
    if (place) {
      this.props.editPlace(this.state.editingPlace.id, place).catch(() => this.props.toggleSubmitted());
    } else {
      this.props.toggleSubmitted();
    }
  }

  onPageChange(params) {
    this.setQueryParams(params, true);
    this.getMyPlaces();
  }

  onSortChange(name, order) {
    this.setQueryParams(
      {
        sort: {name, order},
      },
      true,
    );
    this.getMyPlaces();
  }

  onSearchChange(search) {
    if (search) {
      this.setQueryParams({search, page: 1}, true);
      this.setState({search, searchDefault: ''}, this.getMyPlaces);
    }
  }

  onMapViewButtonClick() {
    this.props.getMappedPlaces(this.queryParams.search).then(this.props.toggleMapModal);
  }

  onNewPlaceButtonClick() {
    this.setState(
      {
        isPlaceEditing: false,
      },
      () => this.props.toggleAddPlaceModal(),
    );
  }

  onEditPlaceClick(editingPlace) {
    this.setState(
      {
        isPlaceEditing: true,
        editingPlace,
      },
      () => this.props.toggleAddPlaceModal(),
    );
  }

  onUnlinkClientButtonClick() {
    const {linkToClientModalToggle, selectedPlaces} = this.props.places;
    if (linkToClientModalToggle) {
      for (let id of selectedPlaces) {
        this.props.editPlace(id, {
          region_id: null,
          client_id: null,
        });
      }
    }
  }

  onCloseMapModal = () => {
    this.setState({showingPlaceId: null}, this.props.toggleMapModal);
  };

  showSinglePlaceOnMap = (showingPlaceId) => {
    this.setState({showingPlaceId}, this.onMapViewButtonClick);
  };

  render() {
    const {toggleAddPlaceModal, toggleLinkToClientModal, changeSelectedPlaces, languageState} = this.props;
    const {
      places,
      meta = {},
      mappedPlaces,
      linkedClientId,
      linkedRegionId,
      mapModalToggle,
      addPlaceModalToggle,
      linkToClientModalToggle,
      needToUpdatePlaces,
      selectedPlaces,
      addPlaceSubmitting,
      addressDoesNotExist,
    } = this.props.places;
    const {showingPlaceId} = this.state;

    const filteredMapPlaces = showingPlaceId ? mappedPlaces.filter((i) => i.id === showingPlaceId) : mappedPlaces;

    let linkedClient = {};
    if (linkedClientId) {
      const clientedPlace = find(places, (i) => i.client_id === linkedClientId);
      const regionedPlace = find(places, (i) => i.region_id === linkedRegionId);
      linkedClient = {
        client: {
          id: linkedClientId,
          name: clientedPlace && clientedPlace.client && clientedPlace.client.name,
        },
        region: linkedRegionId && {
          id: linkedRegionId,
          name: regionedPlace && regionedPlace.region && regionedPlace.region.name,
        },
      };
    }

    const {LANGUAGE} = this;

    if (needToUpdatePlaces) {
      this.getMyPlaces();
    }

    return (
      <div className={b()}>
        <div className="page__title-block">
          <TitleBlock small theme>
            <TitleBlock.Item>
              {LANGUAGE.PLACES_TITLE} ({meta ? meta.total_count : 0})
            </TitleBlock.Item>
          </TitleBlock>
        </div>
        <PlacesTable
          needToUpdatePlaces={needToUpdatePlaces}
          data={places}
          currentPage={meta.current_page}
          totalPages={meta.total_pages}
          selectedPlaces={selectedPlaces}
          perPage={Number(this.queryParams.perPage) || 5}
          search={this.queryParams.search}
          changeSelectedPlaces={changeSelectedPlaces}
          onPageChange={this.onPageChange}
          onSortChange={this.onSortChange}
          onSearch={this.onSearchChange}
          totalItems={meta.total_count}
          mapViewClick={this.onMapViewButtonClick}
          addPlaceClick={this.onNewPlaceButtonClick}
          linkToClientClick={toggleLinkToClientModal}
          languageState={languageState}
          onEditPlaceClick={this.onEditPlaceClick}
          showSinglePlaceOnMap={this.showSinglePlaceOnMap}
        />

        <PlacesStoresMapModal show={mapModalToggle} onClose={this.onCloseMapModal} places={filteredMapPlaces} />

        <AddPlaceModal
          show={addPlaceModalToggle}
          onClose={toggleAddPlaceModal}
          isPlaceEditing={this.state.isPlaceEditing}
          initialValues={this.state.isPlaceEditing ? this.state.editingPlace : {}}
          addressDoesNotExist={addressDoesNotExist}
          submitting={addPlaceSubmitting}
          onConfirm={this.state.isPlaceEditing ? this.onEditPlaceButtonClick : this.onAddPlaceButtonClick}
        />

        <LinkToClientModal
          show={linkToClientModalToggle}
          onClose={toggleLinkToClientModal}
          linkedClient={linkedClient}
          onUnlinkClientButtonClick={this.onUnlinkClientButtonClick}
          onConfirm={this.onLinkToClientButtonClick}
        />
      </div>
    );
  }
}

PlacesContainer.propTypes = {
  toggleMapModal: PropTypes.func,
  toggleAddPlaceModal: PropTypes.func,
  toggleLinkToClientModal: PropTypes.func,
  toggleSubmitted: PropTypes.func,
  toggleLinkToClientSubmitted: PropTypes.func,
  changeSelectedPlaces: PropTypes.func,
  addPlace: PropTypes.func,
  editPlace: PropTypes.func,
  linkToClient: PropTypes.func,
  getMappedPlaces: PropTypes.func,
  getPlaces: PropTypes.func,
  places: PropTypes.shape({
    places: PropTypes.array,
    linkedClientId: PropTypes.number,
    linkedRegionId: PropTypes.number,
    meta: PropTypes.shape({
      current_page: PropTypes.number,
      next_page: PropTypes.any,
      prev_page: PropTypes.any,
      total_count: PropTypes.number,
      total_pages: PropTypes.number,
    }),

    mapModalToggle: PropTypes.bool,
    addPlaceModalToggle: PropTypes.bool,
    linkToClientModalToggle: PropTypes.bool,
    needToUpdatePlaces: PropTypes.bool,
    addressDoesNotExist: PropTypes.array,
    selectedPlaces: PropTypes.array,
    mappedPlaces: PropTypes.array,
    addPlaceSubmitting: PropTypes.bool,
  }),
  addPlaceForm: PropTypes.shape({
    values: PropTypes.object,
    syncErrors: PropTypes.object,
  }),
  linkToClientForm: PropTypes.shape({
    values: PropTypes.object,
    syncErrors: PropTypes.object,
  }),
  languageState: PropTypes.object.isRequired,
};

const mapStateToProps = ({places, form, languageState}) => ({
  places,
  addPlaceForm: form.addPlaceForm,
  linkToClientForm: form.linkToClientForm,
  languageState,
});

const mapDispatchToProps = {
  toggleMapModal: toggleMapModalAction,
  toggleAddPlaceModal: toggleAddPlaceModalAction,
  toggleLinkToClientModal: toggleLinkToClientModalAction,
  getPlaces: getPlacesAction,
  getMappedPlaces: getMappedPlacesAction,
  addPlace: addPlaceAction,
  editPlace: editPlaceAction,
  linkToClient: linkToClientAction,
  toggleSubmitted: toggleSubmittedAction,
  changeSelectedPlaces: changeSelectedPlacesAction,
  toggleLinkToClientSubmitted: toggleLinkToClientSubmittedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlacesContainer);
