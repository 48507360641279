export const selectMediasDashboard = (state) => {
  return state.medias.mediasDashboard;
};

export const selectGlobalMedias = (state) => {
  return state.medias.globalMedias;
};

export const selectLocalMedias = (state) => {
  return state.medias.localMedias;
};

export const selectGlobalMediasDuration = (state) => {
  return state.medias.globalMediasDuration;
};
export const selectLocalMediasDuration = (state) => {
  return state.medias.localMediasDuration;
};

export const selectMedias = (state, isLocal = false) =>
  isLocal ? selectLocalMedias(state) : selectGlobalMedias(state);

export const selectMediasDuration = (state, isLocal = false) =>
  isLocal ? selectLocalMediasDuration(state) : selectGlobalMediasDuration(state);
