const ALLOW_REGEXP = /[@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!"#¤%&'()*+,\-./0-9:;<=>?A-Za-zÄÖÑÜ§¿äöñüà^{}\[\]\\~\|€\s]/g;

export const calcSymbols = (text: string) => {
  const unicode = !!text.replaceAll(ALLOW_REGEXP, '').length;
  const maxCount = unicode ? 70 : 160;
  const lesMaxCount = unicode ? 67 : 153;

  const smsCount = text.length <= maxCount ? 1 : Math.ceil(text.length / lesMaxCount);
  let remaining = maxCount - text.length;

  if (smsCount !== 1) {
    const countToLastSms = (smsCount - 1) * lesMaxCount;

    remaining = lesMaxCount - (text.length - countToLastSms);
  }

  return {
    unicode,
    maxCount,
    smsCount,
    remaining,
  };
};
