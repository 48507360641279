function calculateNumber(value, round = false) {
  const exponent = Math.floor(Math.log(value) / Math.log(10));
  const fraction = value / Math.pow(10, exponent);
  let newFraction;

  if (round) {
    if (fraction < 1.5) {
      newFraction = 1.0;
    } else if (fraction < 3.0) {
      newFraction = 2.0;
    } else if (fraction < 7.0) {
      newFraction = 5.0;
    } else {
      newFraction = 10.0;
    }
  }

  if (!round) {
    if (fraction <= 1) {
      newFraction = 1.0;
    } else if (fraction <= 2) {
      newFraction = 2.0;
    } else if (fraction <= 5) {
      newFraction = 5.0;
    } else {
      newFraction = 10.0;
    }
  }

  return newFraction * Math.pow(10, exponent);
}

export function calculateTicks(start, end, ticks = 5) {
  let axisStart = start;
  let axisEnd = end;
  let axisWidth = axisEnd - axisStart;

  if (axisWidth === 0) {
    axisStart -= 0.5;
    axisEnd += 0.5;
    axisWidth = axisEnd - axisStart;
  }

  const range = calculateNumber(axisWidth);
  const step = calculateNumber(range / (ticks - 1), true);
  const min = Math.floor(axisStart / step) * step;
  const max = Math.ceil(axisEnd / step) * step;
  const stepsCount = max / step + 1;
  const stepHeight = 100 / (stepsCount - 1) + '%';
  const labelWidth = max.toString().length * 5 + 20;

  return {
    min,
    max,
    step,
    stepsCount,
    stepHeight,
    labelWidth,
  };
}
