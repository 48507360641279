import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, FormSection, formValueSelector} from 'redux-form';

import {getEmail, getToken} from 'client/services/cookie-data-source';
import {downloadFile} from 'client/services/helpers';
import {required} from 'client/services/validator';

import {leadsGenerateImportFile, getImportFile} from 'client/ducks/lead/actions';

import {LEAD_IMPORT_TYPES, LEAD_IMPORT_UPDATE_TYPES, LEAD_IMPORT_EXPORT_FILE_TYPES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';
import LoadingSpinner from 'client/components/common/loading-spinner';

import './leads-generate-import-modal.scss';

class LeadsGenerateImportModal extends Component {
  static defaultProps = {
    show: false,
    columnsGroup: {},
    columns: [],
  };

  static propTypes = {
    show: PropTypes.bool,
    lang: PropTypes.object.isRequired,
    columnsGroup: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    leadsGenerateImportFile: PropTypes.func.isRequired,
    getImportFile: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    initialize: PropTypes.func,
    clientId: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    generalColumns: PropTypes.array.isRequired,
    columns: PropTypes.array,
    matchingColumns: PropTypes.array,
    acceptingRuleGroup: PropTypes.array,
    matchingRuleGroup: PropTypes.object,
    importLeadsFile: PropTypes.object,
  };

  static formName = 'LeadsGenerateImportFormModal';

  static checkboxColumns = {
    columnsGroup: 'columnsGroup',
    acceptingRuleGroup: 'acceptingRuleGroup',
    matchingRuleGroup: 'matchingRuleGroup',
    checkboxAllColumns: 'checkboxAllColumns',
    checkboxAllMatchingRule: 'checkboxAllMatchingRule',
    checkboxAllAcceptingRule: 'checkboxAllAcceptingRule',
  };

  constructor(props) {
    super(props);

    this.state = {
      showMatchingRuleWarning: true,
      isMatchingFieldTouched: false,
      isLoading: false,
    };

    this.rules = {
      name: [required(this.props.lang.REQUIRED)],
    };
  }

  componentDidUpdate(prevProps) {
    const {show, matchingRuleGroup, columnsGroup} = this.props;

    if (show && !prevProps.show) {
      this.props.initialize({
        matchingRuleGroup: {},
        acceptingRuleGroup: {},
        columnsGroup: {},
        format: LEAD_IMPORT_EXPORT_FILE_TYPES.XLS,
        update_kind: LEAD_IMPORT_UPDATE_TYPES.UPDATE_ALL,
      });
      this.setState({
        showMatchingRuleWarning: true,
        isMatchingFieldTouched: false,
      });
    }

    if (matchingRuleGroup !== prevProps.matchingRuleGroup || columnsGroup !== prevProps.columnsGroup) {
      this.validateFormSection(this.props);
    }
  }

  onSave = (values) => {
    if (this.state.showMatchingRuleWarning) {
      this.setState({isMatchingFieldTouched: true});
      return;
    }
    const columnsGroup = Object.entries(values.columnsGroup);
    let transferItems = [];
    columnsGroup.forEach((el) => {
      if (el[1]) {
        transferItems.push({
          type: LEAD_IMPORT_TYPES.LEAD_IMPORT_ITEM,
          column_adapter_id: Number(el[0].slice(1)),
          required: !!values.acceptingRuleGroup[el[0]],
          unique: !!values.matchingRuleGroup[el[0]],
        });
      }
    });

    const exportData = {
      file_lead_import: {
        name: values.name,
        comment: values.comment || '',
        client_id: this.props.clientId,
        update_kind: values.update_kind,
        format: values.format,
        lead_transfer_items: transferItems,
      },
    };

    this.setState({isLoading: true});

    this.props.leadsGenerateImportFile(exportData).then((res) => {
      this.props.onClose();
      this.props.getImportFile(res.payload.file_lead_import.id).then(() => {
        const url = `${this.props.importLeadsFile.url}?user_email=${getEmail()}&user_token=${getToken()}`;
        downloadFile(url);
        this.setState({isLoading: false});
      });
    });
  };

  toggleAllColumns = (e, colGroup) => {
    this.setState({isMatchingFieldTouched: true});
    let checkboxGroup;
    switch (colGroup) {
      case LeadsGenerateImportModal.checkboxColumns.columnsGroup:
      case LeadsGenerateImportModal.checkboxColumns.acceptingRuleGroup:
        checkboxGroup = this.props.columns;
        break;
      case LeadsGenerateImportModal.checkboxColumns.matchingRuleGroup:
        checkboxGroup = this.props.columns.filter(
          (el) => this.props.generalColumns.includes(el.name) && this.props.columnsGroup[`_${el.id}`],
        );
        break;
      default:
        checkboxGroup = [];
    }

    const result = checkboxGroup.reduce(
      (group, item) => ({
        ...group,
        [`_${item.id}`]: e.currentTarget.checked,
      }),
      {},
    );

    if (!e.currentTarget.checked && colGroup === LeadsGenerateImportModal.checkboxColumns.columnsGroup) {
      this.uncheckAllColumns(LeadsGenerateImportModal.checkboxColumns.checkboxAllMatchingRule);
      this.props.change(LeadsGenerateImportModal.checkboxColumns.matchingRuleGroup, {
        ...this.props.matchingRuleGroup,
        ...result,
      });
      this.uncheckAllColumns(LeadsGenerateImportModal.checkboxColumns.checkboxAllAcceptingRule);
      this.props.change(LeadsGenerateImportModal.checkboxColumns.acceptingRuleGroup, {
        ...this.props.acceptingRuleGroup,
        ...result,
      });
    }

    this.props.change(colGroup, result);
  };

  uncheckAllColumns = (checkBox) => {
    this.props.change(checkBox, false);
    this.setState({isMatchingFieldTouched: true});
  };

  validateFormSection = (props) => {
    const matchingCols = props.matchingRuleGroup ? Object.keys(props.matchingRuleGroup) : [];
    const columnsGroup = props.columnsGroup ? Object.keys(props.columnsGroup) : [];
    const validate =
      matchingCols.some((el) => props.matchingRuleGroup[el] === true) &&
      columnsGroup.some((el) => props.columnsGroup[el] === true);
    this.setState({showMatchingRuleWarning: !validate});
  };

  checkMatchingRule = (e) => {
    const names = this.props.columns.filter(
      (el) => el.name === this.props.generalColumns[0] || el.name === this.props.generalColumns[1],
    );
    const namesCols = names.reduce(
      (group, item) => ({
        ...group,
        [`_${item.id}`]: e.currentTarget.checked,
      }),
      {},
    );

    if (e.currentTarget.checked) {
      this.props.change(LeadsGenerateImportModal.checkboxColumns.columnsGroup, {
        ...this.props.columnsGroup,
        ...namesCols,
      });
      this.props.change(LeadsGenerateImportModal.checkboxColumns.matchingRuleGroup, {
        ...this.props.matchingRuleGroup,
        ...namesCols,
      });
    } else {
      this.props.change(LeadsGenerateImportModal.checkboxColumns.matchingRuleGroup, {
        ...this.props.matchingRuleGroup,
        ...namesCols,
      });
    }
  };

  checkName = (e, id) => {
    const names = this.props.columns.filter(
      (el) => el.name === this.props.generalColumns[0] || el.name === this.props.generalColumns[1],
    );
    const namesCols = names.reduce(
      (group, item) => ({
        ...group,
        [`_${item.id}`]: e.currentTarget.checked,
      }),
      {},
    );

    const otherCol = names.find((el) => el.id !== id).id;
    if (!e.currentTarget.checked) {
      this.props.change(LeadsGenerateImportModal.checkboxColumns.matchingRuleGroup, {
        ...this.props.matchingRuleGroup,
        ...namesCols,
      });
    }
    if (e.currentTarget.checked && this.props.matchingRuleGroup[`_${otherCol}`]) {
      this.props.change(LeadsGenerateImportModal.checkboxColumns.matchingRuleGroup, {
        ...this.props.matchingRuleGroup,
        ...namesCols,
      });
    }
  };

  render() {
    const {columns, handleSubmit, onClose, onCancel, show, lang, generalColumns, columnsGroup} = this.props;
    const {isLoading} = this.state;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-2 theme-color-2 leads-port-layout"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TITLE}
      >
        <form onSubmit={handleSubmit(this.onSave)}>
          <FormFieldWrap>
            <div className="leads-port-layout__col-4">
              <Field
                type="text"
                name="name"
                label={lang.FILE_NAME_LABEL + '*'}
                component={fieldTemplate}
                validate={this.rules.name}
              />
            </div>
          </FormFieldWrap>
          <FormFieldWrap>
            <div className="main-text main-text--small main-text--color-1">{lang.FILE_USED_COLS_LABEL}</div>
            <div className="content-group">
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMin: 200,
                  autoHeightMax: 360,
                }}
              >
                <div className="leads-port-layout__group">
                  <div className="leads-port-layout__group-header flex-container">
                    <div className="leads-port-layout__col-1">
                      <Field
                        label={lang.COLUMNS_COLUMN_TITLE}
                        name={LeadsGenerateImportModal.checkboxColumns.checkboxAllColumns}
                        type="checkbox"
                        onChange={(e) =>
                          this.toggleAllColumns(e, LeadsGenerateImportModal.checkboxColumns.columnsGroup)
                        }
                        component={fieldTemplate}
                      />
                    </div>
                    <div className="leads-port-layout__col-2">
                      <div className="flex-container flex-align-center">
                        <Field
                          label={lang.USE_ACCEPTING_RULE_COLUMN_TITLE}
                          name={LeadsGenerateImportModal.checkboxColumns.checkboxAllAcceptingRule}
                          type="checkbox"
                          onChange={(e) =>
                            this.toggleAllColumns(e, LeadsGenerateImportModal.checkboxColumns.acceptingRuleGroup)
                          }
                          component={fieldTemplate}
                        />
                      </div>
                    </div>
                    <div className="leads-port-layout__col-3">
                      <div className="flex-container flex-align-center">
                        <Field
                          label={lang.USE_MATCHING_RULE_COLUMN_TITLE + '*'}
                          name={LeadsGenerateImportModal.checkboxColumns.checkboxAllMatchingRule}
                          type="checkbox"
                          onChange={(e) =>
                            this.toggleAllColumns(e, LeadsGenerateImportModal.checkboxColumns.matchingRuleGroup)
                          }
                          component={fieldTemplate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="leads-port-layout__group-body flex-container">
                    <div className="leads-port-layout__col-1">
                      <FormSection name={LeadsGenerateImportModal.checkboxColumns.columnsGroup}>
                        {columns.map((col, index) => {
                          return (
                            <Field
                              key={index}
                              cssModifier="leads-port-layout__checkbox"
                              label={col.name}
                              name={`_${col.id}`}
                              type="checkbox"
                              onChange={(e) => {
                                if (col.name === generalColumns[0] || col.name === generalColumns[1]) {
                                  this.checkName(e, col.id);
                                }
                                this.uncheckAllColumns(LeadsGenerateImportModal.checkboxColumns.checkboxAllColumns);
                              }}
                              component={fieldTemplate}
                            />
                          );
                        })}
                      </FormSection>
                    </div>
                    <div className="leads-port-layout__col-2">
                      <FormSection name={LeadsGenerateImportModal.checkboxColumns.acceptingRuleGroup}>
                        {columns.map((col, index) => {
                          return columnsGroup[`_${col.id}`] ? (
                            <Field
                              key={index}
                              cssModifier="leads-port-layout__checkbox"
                              name={`_${col.id}`}
                              onChange={() =>
                                this.uncheckAllColumns(
                                  LeadsGenerateImportModal.checkboxColumns.checkboxAllAcceptingRule,
                                )
                              }
                              type="checkbox"
                              component={fieldTemplate}
                            />
                          ) : (
                            <div key={index} className="leads-port-layout__empty" />
                          );
                        })}
                      </FormSection>
                    </div>
                    <div className="leads-port-layout__col-3">
                      <FormSection name={LeadsGenerateImportModal.checkboxColumns.matchingRuleGroup}>
                        {columns.map((col, index) => {
                          return columnsGroup[`_${col.id}`] && generalColumns.includes(col.name) ? (
                            <Field
                              key={index}
                              cssModifier="leads-port-layout__checkbox"
                              name={`_${col.id}`}
                              onChange={(e) => {
                                if (
                                  col.name === this.props.generalColumns[0] ||
                                  col.name === this.props.generalColumns[1]
                                ) {
                                  this.checkMatchingRule(e, col.id);
                                }
                                this.uncheckAllColumns(
                                  LeadsGenerateImportModal.checkboxColumns.checkboxAllMatchingRule,
                                );
                              }}
                              type="checkbox"
                              component={fieldTemplate}
                            />
                          ) : (
                            <div key={index} className="leads-port-layout__empty" />
                          );
                        })}
                      </FormSection>
                    </div>
                  </div>
                </div>
              </CustomScrollbars>
            </div>

            {this.state.showMatchingRuleWarning && this.state.isMatchingFieldTouched && (
              <div className="message-error-text">{lang.NO_MATCH_ERROR_MESSAGE}</div>
            )}
          </FormFieldWrap>
          <div className="leads-port-layout__row">
            <div className="leads-port-layout__row-title">{lang.UPDATE_SETTINGS_TITLE}</div>
            <div className="flex-container flex-container--space-between">
              <Field
                label={<span className="main-text">{lang.UPDATE_ALL_LABEL}</span>}
                name="update_kind"
                value={LEAD_IMPORT_UPDATE_TYPES.UPDATE_ALL}
                type="radio"
                component={fieldTemplate}
              />
              <Field
                label={<span className="main-text">{lang.UPDATE_ONLY_EMPTY_LABEL}</span>}
                name="update_kind"
                value={LEAD_IMPORT_UPDATE_TYPES.UPDATE_ONLY_EMPTY_FIELDS}
                type="radio"
                component={fieldTemplate}
              />
              <Field
                label={<span className="main-text">{lang.UPDATE_EXCEPT_EMPTY_LABEL}</span>}
                name="update_kind"
                value={LEAD_IMPORT_UPDATE_TYPES.UPDATE_EXCEPT_EMPTY}
                type="radio"
                component={fieldTemplate}
              />
              <Field
                label={<span className="main-text">{lang.UPDATE_NOTHING}</span>}
                name="update_kind"
                value={LEAD_IMPORT_UPDATE_TYPES.UPDATE_NOTHING}
                type="radio"
                component={fieldTemplate}
              />
            </div>
            <div className="leads-port-layout__row">
              <div className="leads-port-layout__row-title">{lang.UPDATE_SETTINGS_TITLE}</div>
              <div className="flex-container">
                <div className="leads-port-layout__col-5">
                  <Field
                    label={<span className="main-text">{lang.XLS}</span>}
                    name="format"
                    value={LEAD_IMPORT_EXPORT_FILE_TYPES.XLS}
                    type="radio"
                    component={fieldTemplate}
                  />
                </div>
                <div className="leads-port-layout__col-6">
                  <Field
                    label={<span className="main-text">{lang.CSV}</span>}
                    name="format"
                    value={LEAD_IMPORT_EXPORT_FILE_TYPES.CSV}
                    type="radio"
                    component={fieldTemplate}
                  />
                </div>
              </div>
            </div>
            <div className="leads-port-layout__row">
              <div className="leads-port-layout__col-4">
                <Field
                  cssModifier="leads-port-layout__textarea"
                  name="comment"
                  type="textarea"
                  label={lang.COMMENT_LABEL}
                  component={fieldTemplate}
                />
              </div>
            </div>
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button
              className="button button--bg-11 modal-window__footer-btn"
              onClick={onCancel || onClose}
              type="button"
            >
              {lang.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-2 modal-window__footer-btn" type="submit" disabled={isLoading}>
              {isLoading ? (
                <LoadingSpinner cssModifier="leads-generate-import-modal__loader" loading={true} />
              ) : (
                lang.GENERATE_FILE_BUTTON
              )}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const LeadsGenerateImportFormModal = reduxForm({
  form: LeadsGenerateImportModal.formName,
})(LeadsGenerateImportModal);

export default connect(
  (state) => {
    const selector = formValueSelector(LeadsGenerateImportModal.formName);
    return {
      lang: state.languageState.payload.LEADS_PORT.GENERATE_IMPORT_MODAL,
      columnsGroup: selector(state, LeadsGenerateImportModal.checkboxColumns.columnsGroup),
      matchingRuleGroup: selector(state, LeadsGenerateImportModal.checkboxColumns.matchingRuleGroup),
      importLeadsFile: state.lead.importFile,
    };
  },
  {leadsGenerateImportFile, getImportFile},
)(LeadsGenerateImportFormModal);
