import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {ANIMATION_STATUS_COLORS, ANIMATION_STATUS_LANG_MAP} from 'client/components/instore/constants';

import './animation-status-cell.scss';

const b = bem('animation-status-cell');

const AnimationStatusCell = (props) => {
  const {item, showName} = props;
  const lang = useLanguage('ANIMATIONS.STATUS_ROAD');

  return (
    <div className={b()}>
      <figure className={b('circle')} style={{backgroundColor: ANIMATION_STATUS_COLORS[item.status]}} />
      {showName && <span className={b('name')}>{lang[ANIMATION_STATUS_LANG_MAP[item.status]]}</span>}
    </div>
  );
};

AnimationStatusCell.propTypes = {
  item: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
  showName: PropTypes.bool,
};

AnimationStatusCell.defaultProps = {
  showName: false,
};

export default AnimationStatusCell;
