import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function clearAutotaskState() {
  return {
    type: types.CLEAR_AUTOTASK_STATE,
  };
}

export function getAutotask(id, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.AUTOMATION_TASKS}/${id}`,
      method: 'GET',
      headers: {
        'cache-control': 'no-cache',
      },
      types: [types.LOADING, types.LOADED, types.LOAD_FAILED],
      queryParams,
    });
}

export function getAutotasks(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.AUTOMATION_TASKS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_AUTOTASKS_REQUEST, types.GET_AUTOTASKS_SUCCESS, types.GET_AUTOTASKS_ERROR],
      queryParams,
    });
}

export function patchAutotask(id, body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.AUTOMATION_TASKS}/${id}`,
      method: 'PATCH',
      noCache: true,
      types: [
        types.PATCH_AUTOMATION_TASKS_REQUEST,
        types.PATCH_AUTOMATION_TASKS_SUCCESS,
        types.PATCH_AUTOMATION_TASKS_ERROR,
      ],
      body,
    });
}

export const changeAutotaskValidated = (id, validated) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.AUTOMATION_TASKS}/${id}/change_validated`,
      method: 'PATCH',
      noCache: true,
      types: [
        types.CHANGE_AUTOTASK_VALIDATED_REQUEST,
        types.CHANGE_AUTOTASK_VALIDATED_SUCCESS,
        types.CHANGE_AUTOTASK_VALIDATED_ERROR,
      ],
      queryParams: {
        validated,
      },
    });
};

export function deleteAutotask(id) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.AUTOMATION_TASKS}/${id}`,
      method: 'DELETE',
      noCache: true,
      types: [
        types.DELETE_AUTOMATION_TASKS_REQUEST,
        types.DELETE_AUTOMATION_TASKS_SUCCESS,
        types.DELETE_AUTOMATION_TASKS_ERROR,
      ],
    });
}

export function createScenario(body = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SCENARIOS}`,
      method: 'POST',
      types: [types.SCENARIO_CREATING, types.SCENARIO_CREATED, types.SCENARIO_CREATE_FAILED],
      body,
    });
  };
}

export function updateScenario(id, body) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.SCENARIOS}/${id}`,
        method: 'PUT',
        headers: {
          'cache-control': 'no-cache',
          pragma: 'no-cache',
        },
        types: [types.SCENARIO_UPDATING, types.SCENARIO_UPDATED, types.SCENARIO_UPDATE_FAILED],
        body,
      },
      true,
    );
  };
}

export function getAutotaskValidationLog(autotaskId) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.HISTORY_CHANGES}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_AUTOTASK_VALIDATION_LOG_REQUEST,
        types.GET_AUTOTASK_VALIDATION_LOG_SUCCESS,
        types.GET_AUTOTASK_VALIDATION_LOG_ERROR,
      ],
      queryParams: {
        record_type: 'AutomationTask',
        record_id: autotaskId,
        attributes: ['validated'],
        sort: 'desc',
      },
    });
}
export function getAutotaskPublicationWebAppLog(webAppId) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.HISTORY_CHANGES}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_AUTOTASK_PUBLICATION_WEB_APP_LOG_REQUEST,
        types.GET_AUTOTASK_PUBLICATION_WEB_APP_LOG_SUCCESS,
        types.GET_AUTOTASK_PUBLICATION_WEB_APP_LOG_ERROR,
      ],
      queryParams: {
        record_type: 'WebApp',
        record_id: webAppId,
        attributes: ['state'],
      },
    });
}

export function getAutotaskPublicationDevicepLog(interfaceId) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.HISTORY_CHANGES}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_AUTOTASK_PUBLICATION_DEVICE_LOG_REQUEST,
        types.GET_AUTOTASK_PUBLICATION_DEVICE_LOG_SUCCESS,
        types.GET_AUTOTASK_PUBLICATION_DEVICE_LOG_ERROR,
      ],
      queryParams: {
        record_type: 'Interface',
        record_id: interfaceId,
        attributes: ['device_state'],
      },
    });
}

export function updateStatistic(taskId) {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.AUTOMATION_TASKS}/${taskId}/save_statistics`,
        method: 'POST',
        noCache: true,
        types: [
          types.UPDATE_AUTOTASK_STATISTIC_REQUEST,
          types.UPDATE_AUTOTASK_STATISTIC_SUCCESS,
          types.UPDATE_AUTOTASK_STATISTIC_ERROR,
        ],
      },
      true,
    );
}

export const updateWebApp = (id, body) => (dispatch) =>
  callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.WEB_APPS}/${id}`,
    method: 'PATCH',
    noCache: true,
    types: [types.UPDATE_WEB_APP_REQUEST, types.UPDATE_WEB_APP_SUCCESS, types.UPDATE_WEB_APP_ERROR],
    body,
  });
