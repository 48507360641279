import {WEEKDAYS} from 'client/components/prizes/constants';

export default (values, lang, shouldValidate = true) => {
  const errors = {};

  if (!shouldValidate) {
    return errors;
  }

  const winDays = Object.entries(values.prizes_number || {})
    .filter(([, value]) => Number(value) > 0)
    .map(([key]) => key);

  if (!winDays.length) {
    WEEKDAYS.forEach((day) => {
      errors.prizes_number = {
        ...errors.prizes_number,
        [day]: lang.REQUIRED,
      };
    });
  }

  Object.entries(values.openingHours).forEach(([weekday, timeStamps]) => {
    if (
      (timeStamps.length > 0 && !winDays.includes(weekday)) ||
      (timeStamps.length === 0 && winDays.includes(weekday))
    ) {
      errors.prizes_number = {
        ...errors.prizes_number,
        [weekday]: lang.BOTH_INPUTS_FILLED,
      };
      errors.openingHours = {
        ...errors.openingHours,
        [weekday]: lang.BOTH_INPUTS_FILLED,
      };
    }
  });

  return errors;
};
