import React, {Component} from 'react';

import {Table, Column} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {
  getParticipationsFileExportHistory,
  getParticipationsApiExportHistory,
} from 'client/ducks/participation-export/actions';
import {
  selectParticipationFileExportHistory,
  selectParticipationApiExportHistory,
} from 'client/ducks/participation-export/selectors';

import BasePagination from 'client/components/common/base-pagination';

import DateCell from '../cells/date-cell';
import ExportHistoryToolsCell from '../cells/export-history-tools-cell';
import NbOfParticipantsCell from '../cells/nb-of-participants-cell';
import TextCell from '../cells/text-cell';

class ParticipantsExportHistoryCardDataGrid extends Component {
  static propTypes = {
    clientId: PropTypes.number.isRequired,
    operationId: PropTypes.number,
    taskId: PropTypes.number,
    languageState: PropTypes.object.isRequired,
    isDashboard: PropTypes.bool.isRequired,
    isGlobal: PropTypes.bool,
    exportType: PropTypes.oneOf(['file', 'api']).isRequired,
    perPage: PropTypes.number,
    showPagination: PropTypes.bool,
    getParticipationsFileExportHistory: PropTypes.func.isRequired,
    getParticipationsApiExportHistory: PropTypes.func.isRequired,
    fileExportHistory: PropTypes.shape({
      meta: PropTypes.object.isRequired,
      items: PropTypes.array.isRequired,
    }).isRequired,
    apiExportHistory: PropTypes.shape({
      meta: PropTypes.object.isRequired,
      items: PropTypes.array.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    perPage: 100,
    showPagination: true,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.PARTICIPANTS_EXPORT_HISTORY;

    this.state = {
      page: 1,
    };
  }

  componentDidMount() {
    this.fetchExportHistory();
  }

  fetchExportHistory = () => {
    const {isGlobal, taskId, clientId, exportType, perPage} = this.props;
    const {page} = this.state;

    const params = {
      page,
      per_page: perPage,
      include: {
        user: null,
        participation_export: {
          memberships: {
            client_user: null,
          },
        },
      },
      q: {
        s: 'created_at desc',
      },
    };

    if (isGlobal) {
      params.q.participation_export_automation_task_operation_client_id_eq = clientId;
    } else {
      params.q.participation_export_automation_task_id_eq = taskId;
    }

    if (exportType === 'file') {
      this.props.getParticipationsFileExportHistory(params);
    }

    if (exportType === 'api') {
      this.props.getParticipationsApiExportHistory(params);
    }
  };

  getData = () => {
    switch (this.props.exportType) {
      case 'file':
        return this.props.fileExportHistory;
      case 'api':
        return this.props.apiExportHistory;
      default:
        return {};
    }
  };

  getLabel = () => {
    switch (this.props.exportType) {
      case 'file':
        return this.LANGUAGE.EXPORT_FILE_HISTORY_LABEL;
      case 'api':
        return this.LANGUAGE.EXPORT_API_HISTORY_LABEL;
      default:
        return '';
    }
  };

  handlePageChange = (page) => {
    this.setState({page}, this.fetchExportHistory);
  };

  render() {
    const {clientId, operationId, taskId, isDashboard, showPagination} = this.props;
    const {items, meta} = this.getData();

    if (!items.length) {
      return null;
    }

    return (
      <div className="fixed-table fixed-table--leads-card participants-export-history-card-table">
        <div
          className={`main-text main-text--bold
        participants-export-history-card-tabel-title${isDashboard ? '-dashboard' : ''}`}
        >
          {this.getLabel()}:
        </div>
        <Table
          rowsCount={items.length}
          headerHeight={0}
          rowHeight={34}
          height={140}
          maxHeight={140}
          width={540}
          data={items}
        >
          <Column cell={<TextCell data={items} field="format" />} flexGrow={1} width={40} />
          <Column cell={<DateCell data={items} field="date" />} width={40} />
          <Column
            cell={
              <NbOfParticipantsCell
                data={items}
                field="participantsReportLink"
                clientId={clientId}
                operationId={operationId}
                taskId={taskId}
              />
            }
            width={120}
          />
          <Column
            cell={<ExportHistoryToolsCell data={items} isDashboard={isDashboard} refresh={this.fetchExportHistory} />}
            width={40}
          />
        </Table>
        {showPagination && (
          <div className="info-block info-block--color-participants-history">
            <div className="info-block__pagination">
              <BasePagination
                totalPages={meta?.total_pages}
                currentPage={meta?.current_page}
                onClick={this.handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    languageState: state.languageState,
    fileExportHistory: selectParticipationFileExportHistory(state),
    apiExportHistory: selectParticipationApiExportHistory(state),
  }),
  {
    getParticipationsFileExportHistory,
    getParticipationsApiExportHistory,
  },
)(ParticipantsExportHistoryCardDataGrid);
