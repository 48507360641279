export const ANIMATION_STATUSES = {
  EDIT: 'edit',
  ISSUED: 'issued',
  VALIDATED: 'validated',
  RECEIVED: 'received',
  PRODUCTION: 'production',
  RUNNING: 'running',
  FINISHED: 'finished',
  REPORT: 'report',
};

export const ANIMATION_STATUS_COLORS = {
  [ANIMATION_STATUSES.EDIT]: '#DCE1EA',
  [ANIMATION_STATUSES.ISSUED]: '#8ED2F8',
  [ANIMATION_STATUSES.VALIDATED]: '#308ED1',
  [ANIMATION_STATUSES.RECEIVED]: '#00629C',
  [ANIMATION_STATUSES.PRODUCTION]: '#6BFFB8',
  [ANIMATION_STATUSES.RUNNING]: '#0DD280',
  [ANIMATION_STATUSES.FINISHED]: '#059A64',
  [ANIMATION_STATUSES.REPORT]: '#F4A0FB',
};

export const ANIMATION_STATUS_LANG_MAP = {
  [ANIMATION_STATUSES.EDIT]: 'DRAFT',
  [ANIMATION_STATUSES.ISSUED]: 'SENT',
  [ANIMATION_STATUSES.VALIDATED]: 'VALIDATION',
  [ANIMATION_STATUSES.RECEIVED]: 'RECEIVED',
  [ANIMATION_STATUSES.PRODUCTION]: 'PRODUCTION',
  [ANIMATION_STATUSES.RUNNING]: 'RUNNING',
  [ANIMATION_STATUSES.FINISHED]: 'FINISHED',
  [ANIMATION_STATUSES.REPORT]: 'REPORTED',
};

export const ANIMATION_VALIDATION_STATUSES = {
  OK: 'ok',
  ANOMALY: 'anomaly',
};

export const ANIMATION_ANOMALY_STATUSES = {
  VIEWED: 'viewed',
  UNVIEWED: 'unviewed',
};
