import React from 'react';

import placeholderNoPhoto from 'assets/images/placeholder-no-photo.png';
import moment from 'moment';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Modal from 'client/common/modals/modal';

import Typography from 'client/components/common/typography';

import './operations-tasks-modal.scss';

const b = bem('operations-tasks-modal');

const OpsTasksModal = (props) => {
  const {tasks, show, dateStart, dateEnd, onClose, onSelect} = props;
  const lang = useLanguage('OPERATIONS');

  return (
    <Modal dialogClassName={b()} onClose={onClose} show={show}>
      <div className={b('content')}>
        {tasks.map((task, indx) => {
          const {name, id, image} = task;

          return (
            <button type="submit" className="card" key={id} id={indx} onClick={(e) => onSelect(e, task)}>
              <div className="card__image">
                <img alt="" src={image?.url || task?.image_url || placeholderNoPhoto} />
              </div>
              <div className="card__info">
                <Typography className="card__title" variant="title-h3">
                  {name}
                </Typography>
                <div className="card__date">
                  <div className="card__date-start">
                    <p className="card__date-title">{lang.START_DATE}</p>
                    <p className="card__date-value">{moment(dateStart).format('DD/MM/YYYY')}</p>
                  </div>
                  <div className="card__date-end">
                    <p className="card__date-title">{lang.END_DATE}</p>
                    <p className="card__date-value">{moment(dateEnd).format('DD/MM/YYYY')}</p>
                  </div>
                </div>
              </div>
            </button>
          );
        })}
      </div>
    </Modal>
  );
};

OpsTasksModal.propTypes = {
  tasks: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  dateStart: PropTypes.string.isRequired,
  dateEnd: PropTypes.string.isRequired,
};

export default OpsTasksModal;
