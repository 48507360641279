import React, {Component} from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import CustomLink from 'client/components/common/custom-link';

function TaskNameCell({data, rowIndex, field}) {
  const {type, clientType, operationId, clientId, id} = data[rowIndex];

  const linkData = {
    taskType: type,
    clientType,
    operationId,
    clientId,
    taskId: id,
  };

  return (
    <Cell>
      <CustomLink to={CustomLink.createTaskLink(linkData)} className="link ellipsis-text display-block">
        {data[rowIndex][field]}
      </CustomLink>
    </Cell>
  );
}

TaskNameCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

function ClientCell({data, rowIndex, field}) {
  return (
    <Cell>
      <div className="ellipsis-text">{data[rowIndex][field]}</div>
    </Cell>
  );
}

ClientCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

function DateCell({data, rowIndex, field}) {
  return <Cell>{data[rowIndex][field]}</Cell>;
}

DateCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

class HomeOpsTable extends Component {
  static propTypes = {
    data: PropTypes.array,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.HOME.OPS_TABLE;
  }

  render() {
    const {data} = this.props;

    return (
      <div className="fixed-table fixed-table--home-ops-card fixed-table--expand-1">
        <Table rowsCount={data.length} headerHeight={40} rowHeight={30} width={370} height={342} overflowX="hidden">
          <Column
            header={<Cell>{this.LANGUAGE.TASK_NAME_COLUMN}</Cell>}
            cell={<TaskNameCell data={data} field="name" />}
            width={158}
          />
          <Column
            header={<Cell>{this.LANGUAGE.CLIENT_COLUMN}</Cell>}
            cell={<ClientCell data={data} field="clientName" />}
            width={122}
          />
          <Column
            header={<Cell>{this.LANGUAGE.DATE_COLUMN}</Cell>}
            cell={<DateCell data={data} field="createdAt" />}
            width={90}
          />
        </Table>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(HomeOpsTable);
