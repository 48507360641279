import React, {Component} from 'react';

import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, Field} from 'redux-form';

import {getEmailTemplates} from 'client/ducks/email-templates/actions';
import {selectTemplatesStepsMapped} from 'client/ducks/email-templates/selectors';
import {selectTaskBaseInfo} from 'client/ducks/message-email-sms-task/selectors';

import fieldTemplate from 'client/components/common/field';

import '../bc-step.scss';

class BCTemplateStep extends Component {
  static formName = 'BCTemplateStepName';

  static propTypes = {
    task: PropTypes.object.isRequired,
    taskId: PropTypes.string.isRequired,
    taskState: PropTypes.string.isRequired,
    templates: PropTypes.array.isRequired,
    getEmailTemplates: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    disabledWhilePerforming: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.getEmailTemplates();
  }

  getEmailTemplates = () => {
    const params = {
      q: {
        operation_id_eq: this.props.task.operationId,
      },
    };

    return this.props.getEmailTemplates(params);
  };

  handleFormChange = (param) => {
    const body = {
      message_task: {
        email_template_id: param.value,
      },
    };

    return this.props.onSave(body);
  };

  render() {
    const {templates, task, disabledWhilePerforming: disabled} = this.props;

    const html = {
      __html: task.emailTemplate && task.emailTemplate.html_part,
    };

    return (
      <div className="bc-step">
        <div className="bc-step__inner">
          <div className="bc-step__header">
            <form>
              <Field
                cssModifier="select--no-min-height form-field-wrap--width-1 select--view-4"
                name="email_templates"
                type="select"
                searchable={false}
                component={fieldTemplate}
                options={templates}
                disabled={disabled}
                onChange={this.handleFormChange}
              />
            </form>
          </div>
          {html.__html && <div dangerouslySetInnerHTML={html} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const task = selectTaskBaseInfo(state);

  const initialValues = {
    email_templates: {
      value: task.emailTemplateId || '0',
    },
  };

  return {
    initialValues: initialValues,
    task,
    templates: selectTemplatesStepsMapped(state),
  };
};

const mapDispatchToProps = {
  getEmailTemplates,
};

export default flow([
  reduxForm({
    form: BCTemplateStep.formName,
    enableReinitialize: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
])(BCTemplateStep);
