import {USER_MEMBERSHIP} from 'client/common/config';

export const MEDIA_LEVELS = {
  NATIONAL: 'national',
  REGION: 'region',
  STORE: 'store',
  DEVICE: 'device',
};

export const MEDIA_TYPES = {
  VIDEO: 'video',
  IMAGE: 'image',
};

export const MEDIA_LEVEL_MAP = {
  [USER_MEMBERSHIP.CLIENT_ADMIN]: MEDIA_LEVELS.NATIONAL,
  [USER_MEMBERSHIP.NATIONAL]: MEDIA_LEVELS.NATIONAL,
  [USER_MEMBERSHIP.REGION]: MEDIA_LEVELS.REGION,
  [USER_MEMBERSHIP.STORE]: MEDIA_LEVELS.STORE,
};
