import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {TranslationElement} from 'client/services/translation-element';

import {interpolate} from 'client/ducks/language/helpers';

import {Client} from 'client/models/client/types';

import {DiyOpTemplateValues} from '../types';

export const getSuccessToastData = (
  data: {ids: number[]; clients: Client[]; formValues: DiyOpTemplateValues},
  lang: LangsByPath<['DIY_OPERATION.MODALS.DIY_OP_TEMPLATE_MODAL.SHARE_WITH_CLIENTS_STEP']>,
) => {
  const {ids, clients, formValues} = data;
  const count = ids.length;

  const title = interpolate(lang.TEMPLATE_SHARED.toString(), {
    name: formValues.name,
    count,
  }) as TranslationElement;

  const description =
    count > 5
      ? ''
      : clients
          .filter((i) => ids.includes(i.id))
          .map((i) => i.brand_name)
          .join(', ');

  return {title, description};
};

export const filterClients = (clients: Client[], region: boolean, store: boolean) => {
  if (!region && !store) {
    return clients;
  }
  return clients.filter((i) => {
    let result = i.client_network;
    if (region) {
      result = result && Boolean(i.available_regions_count);
    }
    if (store) {
      result = result && Boolean(i.available_places_count);
    }
    return result;
  });
};
