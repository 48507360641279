export default {
  CLEAR_SG_INTERFACES_STATE: '@@sg_interfaces/CLEAR_SG_INTERFACES_STATE',

  GET_NEW_SG_INTERFACE_REQUEST: '@@sg_interfaces/GET_NEW_SG_INTERFACE_REQUEST',
  GET_NEW_SG_INTERFACE_SUCCESS: '@@sg_interfaces/GET_NEW_SG_INTERFACE_SUCCESS',
  GET_NEW_SG_INTERFACE_ERROR: '@@sg_interfaces/GET_NEW_SG_INTERFACE_ERROR',

  CREATE_SG_INTERFACE_REQUEST: '@@sg_interfaces/CREATE_SG_INTERFACE_REQUEST',
  CREATE_SG_INTERFACE_SUCCESS: '@@sg_interfaces/CREATE_SG_INTERFACE_SUCCESS',
  CREATE_SG_INTERFACE_ERROR: '@@sg_interfaces/CREATE_SG_INTERFACE_ERROR',
};
