export const buildTranslationsTree = (data) => {
  const rootBranch = data.filter((el) => el.parent_id === null);

  const translationsByParentMap = data.reduce((acc, el) => {
    if (!Array.isArray(acc[el.parent_id])) {
      acc[el.parent_id] = [];
    }
    acc[el.parent_id].push(el);
    return acc;
  }, {});

  const buildBranch = (branch) => {
    return branch.reduce((acc, el) => {
      const translation = {...el};
      acc.push(translation);
      if (translationsByParentMap[el.id]) {
        translation.children = buildBranch(translationsByParentMap[el.id]);
      }
      return acc;
    }, []);
  };

  return buildBranch(rootBranch);
};

/**
 * @param {import('client/models/translations/types').Translation[]} translations
 * @returns {*}
 */
export const flattenTranslationsTree = (translations) => {
  return translations.reduce((acc, el) => {
    const {children, ...translation} = el;
    acc.push(translation);
    if (children?.length) {
      return [...acc, ...flattenTranslationsTree(children)];
    }
    return acc;
  }, []);
};

export const markTranslationsWithChildren = (translations) => {
  const cache = new Set();
  for (const {parent_id} of translations) {
    cache.add(parent_id);
  }

  return translations.map((el) => {
    return {
      ...el,
      hasChildren: cache.has(el.id),
    };
  });
};

const calcParents = (translation, translationsById) => {
  const result = {path: '', parentPath: '', parents: []};
  if (!translation?.parent_id) {
    result.path = translation.key;
  } else {
    const parent = translationsById[translation.parent_id];
    const resultParent = parent ? calcParents(parent, translationsById) : {};
    result.parentPath = [resultParent.path].filter(Boolean).join('.');
    result.path = [result.parentPath, translation.key].join('.');
  }

  return result;
};

const prepareTranslationPaths = (translations, translationsById) => {
  return translations.map((el) => ({
    ...el,
    ...calcParents(el, translationsById),
  }));
};

export const prepareTranslations = (translationsById) => {
  const translations = Object.values(translationsById).filter(Boolean);

  return markTranslationsWithChildren(prepareTranslationPaths(translations, translationsById));
};
