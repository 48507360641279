import React from 'react';

import classNames from 'classnames';
import filter from 'lodash/filter';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectInstoreTask} from 'client/ducks/instore-tasks/selectors';

import useFetchQuestions from 'client/components/animations/blocks/animation-questionnaires-block/hooks/use-fetch-questions';
import AnimationQuestionnaireCard from 'client/components/animations/cards/animation-questionnaire-card';
import {sortQuestions} from 'client/components/animations/helpers';
import {useFetchTaskKpis} from 'client/components/instore/lists/instore-reporting-animation-list/hooks';

import cssModule from './animation-questionnaires-block.module.scss';

const b = bem('animation-questionnaires-block', {cssModule});

const AnimationQuestionnairesBlock = (props) => {
  const {className = '', filters = {}, showAllQuestions = false} = props;
  const lang = useLanguage('ANIMATIONS.CARDS.QUESTIONNAIRES');
  const {data} = useFetchQuestions(filters);
  const task = useSelector(selectInstoreTask);
  const {kpis} = useFetchTaskKpis({task});
  const fullData = [...data];

  if (showAllQuestions) {
    kpis
      .filter(({kpi_type}) => kpi_type !== 'none')
      .forEach((item) => {
        if (!find(fullData, {id: item.id})) {
          fullData.push({...item, title: item.name, data: null});
        }
      });
  }

  const monitoringData = sortQuestions(filter(fullData, {nature: 'report'}));
  const kpiData = sortQuestions(filter(fullData, {nature: 'kpi'}));

  return (
    <div className={classNames(b(), className)}>
      <AnimationQuestionnaireCard
        data={monitoringData}
        titleList={lang.MONITORING_LIST_TITLE}
        title={lang.MONITORING_TITLE}
      />
      <AnimationQuestionnaireCard data={kpiData} titleList={lang.KPI_LIST_TITLE} title={lang.KPI_TITLE} />
    </div>
  );
};

AnimationQuestionnairesBlock.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.object,
  showAllQuestions: PropTypes.bool,
};

export default AnimationQuestionnairesBlock;
