import React from 'react';

import PropTypes from 'prop-types';

import DropdownAny from 'client/components/common/dropdown-any';

import './vis-select-dropdown.scss';

function VisSelectDropdown({children, value, className, customDropdown}) {
  return (
    <DropdownAny
      customDropdown={customDropdown}
      fullActive={true}
      className={`${className} dropdown-any--view-1 vis-select-dropdown`}
      value={value}
    >
      <div className="theme-color-13 dropdown-any__dropdown-body-inner">{children}</div>
    </DropdownAny>
  );
}

VisSelectDropdown.defaultProps = {
  children: '',
  value: '',
  className: '',
  customDropdown: null,
};

VisSelectDropdown.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  className: PropTypes.string,
  customDropdown: PropTypes.instanceOf(Element),
};

export default VisSelectDropdown;
