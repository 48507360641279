import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import {MessageTask} from 'client/models/message-task/types';

import MessageTaskListAdditional from './components/message-task-list-additional';
import MessageTaskListGeneral from './components/message-task-list-general';

import cssModule from './message-task-list.module.scss';

const b = bem('message-task-list', {cssModule});

type MessageTaskListProps = {
  task: MessageTask;
  onClicksCountClick: (clicksStatistic: any) => void;
  className?: string;
};

const MessageTaskList: React.FC<MessageTaskListProps> = ({task, onClicksCountClick, className = ''}) => {
  const classNames = {
    header: b('header'),
    panel: b('panel'),
    col: b('col'),
    link: b('link'),
    row: b('row'),
  };

  return (
    <div className={cn(b(), className)}>
      <MessageTaskListGeneral task={task} className={b('section')} classNames={classNames} />
      <MessageTaskListAdditional
        onClicksCountClick={onClicksCountClick}
        task={task}
        className={b('section')}
        classNames={classNames}
      />
    </div>
  );
};

export default MessageTaskList;
