import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {set} from 'client/common/meta/meta.actions';

import WinningLogin from 'client/components/winnings/various/winning-login';

const WinningLoginPage = () => {
  const lang = useLanguage('WINNINGS.VARIOUS.WINNING_LOGIN');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set({title: lang.TITLE}));
  }, [dispatch, lang.TITLE]);

  return <WinningLogin />;
};

export default WinningLoginPage;
