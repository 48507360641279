import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';
import Spinner from 'client/components/common/spinner';

import {TranslationJsx} from 'client/models/language/types';

import './participation-tab-card.scss';

function ParticipationTabCard(props) {
  const {children, title, iconName, noPrimaryIconClass, loading, loadingColor} = props;

  return (
    <div className="participation-tab-card">
      <div className="participation-tab-card__header">
        {iconName && <Icon name={iconName} className={cn('icons', {'icons-primary': !noPrimaryIconClass})} />}
        <span className="participation-tab-card__title">{title}</span>
      </div>
      <div className="participation-tab-card__content">
        {loading ? <Spinner color={loadingColor} centered /> : children}
      </div>
    </div>
  );
}

ParticipationTabCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: TranslationJsx.isRequired,
  iconName: PropTypes.string,
  noPrimaryIconClass: PropTypes.bool,
  loading: PropTypes.bool,
  loadingColor: PropTypes.string,
};

ParticipationTabCard.defaultProps = {
  iconName: null,
  noPrimaryIconClass: false,
  loading: false,
  loadingColor: 'primary',
};

export default ParticipationTabCard;
