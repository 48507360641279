import {EXTERNAL_INSTORE_DASHBOARD_TYPES, INSTORE_DASHBOARD_TYPES} from 'client/models/instore-dashboard/constants';
import {InstoreDashboard, InstoreDashboardType} from 'client/models/instore-dashboard/types';

export const getInstoreDashboardType = (dashboard: InstoreDashboard, userId: number): InstoreDashboardType | null => {
  if (!dashboard || !userId) {
    return null;
  }

  switch (true) {
    case dashboard.type === EXTERNAL_INSTORE_DASHBOARD_TYPES.INTERNAL && dashboard.user_id === userId:
      return INSTORE_DASHBOARD_TYPES.USER;
    case dashboard.type === EXTERNAL_INSTORE_DASHBOARD_TYPES.INTERNAL && dashboard.user_id !== userId:
      return INSTORE_DASHBOARD_TYPES.GLOBAL;
    case dashboard.type === EXTERNAL_INSTORE_DASHBOARD_TYPES.WEEZIO:
      return INSTORE_DASHBOARD_TYPES.WEEZIO;
    default:
      return null;
  }
};
