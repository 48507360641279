import React from 'react';

import PropTypes from 'prop-types';

import CustomLink from 'client/components/common/custom-link';

function BroadcastColumn(props) {
  const {messageTask} = props;
  const link = CustomLink.createMailingListLink(messageTask);
  return messageTask.broadcastName ? (
    <CustomLink className="link break-word display-block" to={link}>
      {messageTask.broadcastName}
    </CustomLink>
  ) : null;
}

BroadcastColumn.propTypes = {
  messageTask: PropTypes.shape({
    taskName: PropTypes.string,
    taskType: PropTypes.string,
    broadcastName: PropTypes.string,
    clientType: PropTypes.string,
    operationId: PropTypes.number,
    clientId: PropTypes.number,
    taskId: PropTypes.number,
  }).isRequired,
};

export default BroadcastColumn;
