import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

function mapLevelScreenshotsForSave(screenshots, initialScreenshots = []) {
  const result = [];

  const initialScreenshotIds = initialScreenshots.map(({id}) => id);
  const screenshotIds = screenshots.map(({id}) => id).filter((id) => id);

  initialScreenshotIds.forEach((id) => {
    if (!screenshotIds.includes(id)) {
      result.push({id, _destroy: true, screenshot_type: 'desktop'});
    }
  });

  screenshots.forEach((screenshot) => {
    result.push({
      id: screenshot.id,
      screenshot_type: screenshot.screenshot_type.value,
      image: screenshot.id ? null : screenshot.image,
    });
  });

  return result;
}

function mapLevelScenariosForSave(scenarios, initialScenarios = []) {
  const result = [];

  const initialScenarioIds = initialScenarios.map(({id}) => id);
  const scenarioIds = scenarios.map(({value}) => value);

  initialScenarioIds.forEach((id) => {
    if (!scenarioIds.includes(id)) {
      const initialScenario = find(initialScenarios, {id});
      result.push({
        id: initialScenario.relation_id,
        scenario_id: initialScenario.id,
        _destroy: true,
      });
    }
  });

  scenarioIds.forEach((id) => {
    if (!initialScenarioIds.includes(id)) {
      result.push({scenario_id: id});
    }
  });

  return result;
}

export function mapLevelsForSave(levels, initialLevels = []) {
  const data = [];

  const initialLevelIds = initialLevels.map(({id}) => id);
  const levelIds = levels.map(({id}) => id).filter((id) => id);

  initialLevelIds.forEach((id) => {
    if (!levelIds.includes(id)) {
      data.push({id, _destroy: true});
    }
  });

  levels.forEach((level) => {
    const initialLevel = level.id ? find(initialLevels, {id: level.id}) : null;
    const initialScenarios = initialLevel ? initialLevel.scenarios : [];
    const initialScreenshots = initialLevel ? initialLevel.screenshots : [];

    data.push({
      id: level.id,
      number: level.number,
      level_name: level.level_name,
      code: level.code,
      minimum_level: level.minimum_level,
      screenshots: mapLevelScreenshotsForSave(level.screenshots, initialScreenshots),
      interface_level_scenarios: mapLevelScenariosForSave(level.scenarios, initialScenarios),
    });
  });

  return data;
}

export function mapLevelsForForm(levels) {
  const data = [];

  levels.forEach((level) => {
    data.push({
      id: level.id,
      number: level.number,
      level_name: level.level_name,
      code: level.code,
      minimum_level: level.minimum_level,
      screenshots: level.screenshots.map((screenshot) => ({
        ...screenshot,
        screenshot_type: {value: screenshot.screenshot_type},
      })),
      scenarios: level.scenarios.map((scenario) => ({
        label: scenario.name,
        value: scenario.id,
      })),
    });
  });

  return sortBy(data, 'number');
}
