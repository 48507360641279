import React, {useMemo} from 'react';

import orderBy from 'lodash/orderBy';
import moment from 'moment';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import Icon from 'client/common/icon';

import ClientTable from 'client/components/common/client-table';

import AnimationTimestampsBlock from 'client/components/animations/blocks/animation-timestamps-block';
import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container';

import {dateToTime} from './helpers';

import './animation-planning-fieldset.scss';

const b = bem('animation-planning-fieldset');

const renderDate = (date, weekDayLettersNumber = 10) => (
  <span className={b('date')}>
    <b>{moment(date).format('DD/MM/YY')}</b> {moment(date).format('dddd').slice(0, weekDayLettersNumber)}
  </span>
);

const AnimationPlanningFieldset = ({readOnly, showHeader}) => {
  const lang = useLanguage('ANIMATIONS.MODALS.ANIMATION_MAIN_MODAL.TABS.GENERAL');

  const {formValues = {}} = useReduxForm(AnimationConfigContainerFormName);
  const {animation_days = []} = formValues;

  const sortedAnimationDays = useMemo(() => orderBy(animation_days, 'from', 'asc'), [animation_days]);

  return (
    <div className={b()}>
      {showHeader && (
        <div>
          <span className={b('title')}>{lang.PLANNING}</span>
          <span className={b('duration')}>
            <Icon name="calendar-2" className={b('duration-icon')} width="16" height="16" />
            {sortedAnimationDays.length} {lang.DAYS}
          </span>
        </div>
      )}
      {readOnly ? (
        <ClientTable
          className={b('table')}
          classNames={{
            header: b('table-header'),
          }}
          columns={[
            {
              path: 'from',
              key: 'date',
              label: lang.DATE,
              render: ({value}) => renderDate(value),
              cellClassName: b('table-cell'),
            },
            {
              path: 'from',
              key: 'from',
              label: lang.FROM,
              formatter: dateToTime,
              cellClassName: b('table-cell', ['time']),
            },
            {
              path: 'to',
              key: 'to',
              label: lang.TO,
              formatter: dateToTime,
              cellClassName: b('table-cell', ['time']),
            },
          ]}
          data={sortedAnimationDays}
        />
      ) : (
        <AnimationTimestampsBlock data={sortedAnimationDays} />
      )}
    </div>
  );
};

AnimationPlanningFieldset.propTypes = {
  readOnly: PropTypes.bool,
  showHeader: PropTypes.bool,
};

AnimationPlanningFieldset.defaultProps = {
  readOnly: true,
  showHeader: true,
};

export default AnimationPlanningFieldset;
