import React from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectCurrentLanguage} from 'client/ducks/language/selectors';

import Modal from 'client/common/modals/modal';

import {OptInColumn} from 'client/models/opt-in-column/types';

import cssModule from './leads-parameters-modal.module.scss';

const b = bem('leads-parameters-modal', {cssModule});

type LeadsOptinSettingsModalProps = {
  optInColumn: OptInColumn;
  onClose: () => void;
};

const LeadsOptinSettingsModal: React.FC<LeadsOptinSettingsModalProps> = (props) => {
  const {onClose, optInColumn} = props;
  const lang = useLanguage('LEADS_PARAMETERS.MODAL');
  const currentLanguage: string = useSelector(selectCurrentLanguage);

  const getTextTranslation = (kind: 'in' | 'out'): string => {
    const translations = optInColumn[`opt_${kind}_text_translations`];
    return translations[currentLanguage] || Object.values(translations)?.[0] || optInColumn[`opt_${kind}_text`];
  };

  return (
    <Modal show={true} dialogClassName={b()} title={optInColumn.name} onClose={onClose}>
      <div>
        <div className={b('field')}>
          <div className={b('label')}>{lang.TYPE}</div>
          <div className={b('info', ['type'])}>{optInColumn.opt_in_type.replace('_', ' ')}</div>
        </div>
        <div className={b('field')}>
          <div className={b('label')}>{lang.LEVEL_LABEL}</div>
          <div className={b('info')}>{optInColumn.level}</div>
        </div>
        <div className={b('field')}>
          <div className={b('label')}>{lang.CODE}</div>
          <div className={b('info')}>{optInColumn.code}</div>
        </div>
        <div className={b('field')}>
          <div className={b('label')}>{lang.OPTIN_TEXT_LABEL}</div>
          <div className={b('info')}>{getTextTranslation('in')}</div>
        </div>
        <div className={b('field')}>
          <div className={b('label')}>{lang.OPTOUT_TEXT_LABEL}</div>
          <div className={b('info')}>{getTextTranslation('out')}</div>
        </div>
      </div>
    </Modal>
  );
};

export default LeadsOptinSettingsModal;
