import React from 'react';

import {useLanguage} from 'client/services/hooks';

import ToolbarButton from 'client/common/text-editor/buttons/toolbar-button';
import {CustomEditor, LIST_TYPES} from 'client/common/text-editor/constants';
import {editorMethods} from 'client/common/text-editor/helpers';

type ListButtonsProps = {
  editor: CustomEditor;
};
const ListButtons = (props: ListButtonsProps) => {
  const {editor} = props;

  const lang = useLanguage('COMMON.TEXT_EDITOR.LABELS');

  return (
    <div>
      <ToolbarButton
        onClick={() => editorMethods.toggleBlock(editor, LIST_TYPES.UNORDERED)}
        iconName="u-list"
        label={lang.UNORDERED_LIST}
        active={editorMethods.isBlockActive(editor, LIST_TYPES.UNORDERED)}
        bordered={false}
      />
      <ToolbarButton
        onClick={() => editorMethods.toggleBlock(editor, LIST_TYPES.ORDERED)}
        iconName="o-list"
        label={lang.ORDERED_LIST}
        active={editorMethods.isBlockActive(editor, LIST_TYPES.ORDERED)}
        bordered={false}
      />
    </div>
  );
};

export default ListButtons;
