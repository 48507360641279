import {dateToString, prettyJoin} from 'client/services/helpers';
import {LangsByPath} from 'client/services/hooks/useLanguage/types';

import {GENDER_TYPES_VALUES} from 'client/models/common/constants';
import {WinningSearchLead, WinningSearchResultPrize} from 'client/models/winnings/types';

export const getPrizeName = (prize: WinningSearchResultPrize) => {
  const {coupon} = prize;
  if (coupon) {
    const loyalty = prettyJoin([coupon.loyalty_amount, coupon.loyalty_unit]);
    const from = dateToString(coupon.token_validity_from);
    const to = dateToString(coupon.token_validity_to);
    const period = prettyJoin([from, to], ' - ');
    return prettyJoin([loyalty, prize.prize_name, period], ' | ');
  }
  return prize.prize_name;
};

export const getCivility = (lead: WinningSearchLead, lang: LangsByPath<['COMMON']>) => {
  switch (lead.gender) {
    case GENDER_TYPES_VALUES.MALE:
      return lang.CIVILITY.MALE;
    case GENDER_TYPES_VALUES.FEMALE:
      return lang.CIVILITY.FEMALE;
    default:
      return '';
  }
};
export const getFullName = (lead: WinningSearchLead, lang: LangsByPath<['COMMON']>) => {
  return prettyJoin([getCivility(lead, lang), lead.first_name, lead.last_name]);
};
