import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {Bar} from 'react-chartjs-2';

import {getChartOptions, getChartData} from './helpers';

const InstoreBarChart = ({data: rawData}) => {
  const data = getChartData(rawData);

  const maxBar = useMemo(() => {
    const rawMax = Math.max(...data.datasets.flatMap((dataset) => dataset.data.flatMap(Number)));
    return rawMax + rawMax * 0.2;
  }, [data.datasets]);

  const options = getChartOptions({suggestedMax: maxBar, empty: !rawData?.length});

  return <Bar height={251} width={233} options={options} data={data} />;
};

InstoreBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
};

export default InstoreBarChart;
