import get from 'lodash/get';

import {mapLeadsTimeline} from './mappers';
import types from './types';

const initialState = {
  leadsStatistic: {
    gender: {
      male_count: 0,
      female_count: 0,
      unknown_count: 0,
    },
    participations_conversion: 0,
    coordinations: {
      with_phone: 0,
      with_email: 0,
      with_address: 0,
    },
    total: 0,
  },
  leadsTimeline: {
    points: [],
    leads: [],
    leads_with_optins: [],
  },
  leadsOnlineUsers: {
    os: [],
    browser: [],
    device: [],
  },
  leadsPerCampaign: [],
  client: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_LEADS_STATISTIC_SUCCESS:
      return {
        ...state,
        leadsStatistic: get(action, 'payload.result.leads_statistic', initialState.leadsStatistic),
      };

    case types.GET_LEADS_TIMELINE_REQUEST:
      return {
        ...state,
        leadsTimeline: initialState.leadsTimeline,
      };

    case types.GET_LEADS_TIMELINE_SUCCESS:
      const timelineData = get(action, 'payload.result.leads_timeline_statistic.time_lines', []);
      return {
        ...state,
        leadsTimeline: mapLeadsTimeline(timelineData),
      };

    case types.GET_LEADS_ONLINE_USERS_SUCCESS:
      return {
        ...state,
        leadsOnlineUsers: get(action, 'payload.result.online_user_statistic', initialState.leadsOnlineUsers),
      };

    case types.GET_LEADS_PER_CAMPAIGN_SUCCESS:
      return {
        ...state,
        leadsPerCampaign: get(
          action,
          'payload.result.campaign_leads_statistic.automation_tasks',
          initialState.leadsPerCampaign,
        ),
      };

    case types.GET_LEADS_CLIENT_SUCCESS:
      return {
        ...state,
        client: get(action, 'payload.client', initialState.client),
      };

    default:
      return state;
  }
}
