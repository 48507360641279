import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import './coupon-step.scss';

class AppCouponStep extends Component {
  static propTypes = {
    step: PropTypes.object,
    variableValues: PropTypes.object,
    language: PropTypes.object.isRequired,
  };

  static defaultProps = {
    step: {},
    variableValues: {},
  };

  render() {
    const {step, language, variableValues} = this.props;
    const {implementation} = step;
    const {coupon} = implementation;

    return (
      <div className="coupon-step">
        <div className="coupon-step__form-block">
          <div className="fake-input fake-input--disabled var-container">
            <div className="fake-input__label var-name">{language.INTERNAL_COUPON_NAME_LABEL}:</div>
            <div className="fake-input__field">{implementation.coupon.internal_name}</div>
          </div>
          {coupon.type !== 'SogecCoupon' && (
            <div className="fake-input fake-input--disabled var-container">
              <div className="fake-input__label var-name">{language.VISUAL_LABEL}:</div>
              <div className="fake-input__field">
                {implementation.visual_type === 'qr_code' && language.QR_CODE}
                {implementation.visual_type === 'barcode' && language.BARCODE}
              </div>
            </div>
          )}
          {coupon.type === 'AppCoupon' && (
            <div>
              <div className="fake-input fake-input--disabled var-container">
                <div className="fake-input__label var-name">
                  {`${language.SOURCE_VARIABLE} ${language.TOKEN_ID_LABEL}`}:
                </div>
                <div className="fake-input__field">{variableValues[implementation.token_id_variable_id]}</div>
              </div>
              <div className="fake-input fake-input--disabled var-container">
                <div className="fake-input__label var-name">
                  {`${language.SOURCE_VARIABLE} ${language.TOKEN_DATE_LABEL}`}:
                </div>
                <div className="fake-input__field">{variableValues[implementation.token_date_variable_id]}</div>
              </div>
            </div>
          )}

          {coupon.type === 'SogecCoupon' && (
            <div>
              <div className="fake-input fake-input--disabled var-container">
                <div className="fake-input__label var-name">
                  {`${language.SOURCE_VARIABLE} ${language.FIRST_NAME_LABEL}`}:
                </div>
                <div className="fake-input__field">{variableValues[implementation.first_name_id]}</div>
              </div>
              <div className="fake-input fake-input--disabled var-container">
                <div className="fake-input__label var-name">
                  {`${language.SOURCE_VARIABLE} ${language.LAST_NAME_LABEL}`}:
                </div>
                <div className="fake-input__field">{variableValues[implementation.last_name_id]}</div>
              </div>
              <div className="fake-input fake-input--disabled var-container">
                <div className="fake-input__label var-name">
                  {`${language.SOURCE_VARIABLE} ${language.EMAIL_LABEL}`}:
                </div>
                <div className="fake-input__field">{variableValues[implementation.email_id]}</div>
              </div>

              <div className="fake-input fake-input--disabled var-container">
                <div className="fake-input__label var-name">
                  {`${language.SOURCE_VARIABLE} ${language.CITY_LABEL}`}:
                </div>
                <div className="fake-input__field">{variableValues[implementation.city_id]}</div>
              </div>

              <div className="fake-input fake-input--disabled var-container">
                <div className="fake-input__label var-name">{`${language.SOURCE_VARIABLE} ${language.ZIP_LABEL}`}:</div>
                <div className="fake-input__field">{variableValues[implementation.zip_id]}</div>
              </div>

              <div className="fake-input fake-input--disabled var-container">
                <div className="fake-input__label var-name">
                  {`${language.SOURCE_VARIABLE} ${language.ADDRESS_1_LABEL}`}:
                </div>
                <div className="fake-input__field">{variableValues[implementation.address1_id]}</div>
              </div>
              <div className="fake-input fake-input--disabled var-container">
                <div className="fake-input__label var-name">
                  {`${language.SOURCE_VARIABLE} ${language.ADDRESS_2_LABEL}`}:
                </div>
                <div className="fake-input__field">{variableValues[implementation.address2_id]}</div>
              </div>
              <div className="fake-input fake-input--disabled var-container">
                <div className="fake-input__label var-name">
                  {`${language.SOURCE_VARIABLE} ${language.ADDRESS_3_LABEL}`}:
                </div>
                <div className="fake-input__field">{variableValues[implementation.address3_id]}</div>
              </div>
              <div className="fake-input fake-input--disabled var-container">
                <div className="fake-input__label var-name">
                  {`${language.SOURCE_VARIABLE} ${language.ADDRESS_4_LABEL}`}:
                </div>
                <div className="fake-input__field">{variableValues[implementation.address4_id]}</div>
              </div>
            </div>
          )}

          <div className="fake-input fake-input--disabled var-container">
            <div className="fake-input__label var-name">{language.COMMENT_LABEL}:</div>
            <div className="fake-input__field">{step.comment}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({languageState}) => ({
  language: languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.RESULT_MODAL,
}))(AppCouponStep);
