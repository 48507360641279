import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import cssModule from './required-label.module.scss';

const b = bem('required-label', {cssModule});

type RequiredLabelProps = {
  className?: string;
};

const RequiredLabel: React.FC<RequiredLabelProps> = ({className}) => {
  const lang = useLanguage('COMMON');

  return <span className={cn(b(), className)}>{lang.REQUIRED_FIELD}</span>;
};

export default RequiredLabel;
