import {Membership} from 'client/models/memberships/types';

import {TrackingUserValues} from './types';

export default (data?: Membership | null, locale?: string): TrackingUserValues => {
  return {
    name: data?.prize_tracking_user?.full_name || '',
    login: data?.prize_tracking_user?.login || '',
    password: '',
    store_id: data?.place_accesses?.[0].place_id,
    locale: data?.prize_tracking_user?.locale || locale,
  };
};
