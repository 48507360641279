import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm} from 'redux-form';

import {scenarioVariableSave} from 'client/services/serverValitator';

import {createScenarioVariable} from 'client/ducks/scenario/actions';

import Modal from 'client/common/modals/modal';

import AtsVarForm, {FORM_NAME, SOURCE_TYPES} from '../../forms/ats-var-form/ats-var-form';

class AtsAddVarModal extends PureComponent {
  static propTypes = {
    show: PropTypes.bool,
    languageState: PropTypes.object.isRequired,
    scenario: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    createScenarioVariable: PropTypes.func.isRequired,
    autotaskId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    show: false,
    onConfirm: () => {},
    onClose: () => {},
    onCancel: () => {},
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK_SCENARIO.ADD_VAR_MODAL;
  }

  save = (data) =>
    this.props
      .createScenarioVariable(
        {
          scenario_id: this.props.scenario.id,
          name: data.name,
          source_type: data.source_type,
          source_id: data.source_id,
          required: data.required,
        },
        {
          include: [
            {
              source: ['interface', 'scenario', 'scenario_step'],
            },
          ],
        },
      )
      .then(this.props.onConfirm)
      .catch(scenarioVariableSave);

  render() {
    const {show, onClose, onCancel, handleSubmit, submitting, valid, pristine, autotaskId} = this.props;

    return (
      <Modal
        show={show}
        onClose={onClose}
        dialogClassName="modal-window--width-1 theme-color-7"
        title={this.LANGUAGE.TITLE}
      >
        <form onSubmit={handleSubmit(this.save)}>
          <AtsVarForm data={{source_type: SOURCE_TYPES.interface}} autotaskId={autotaskId} />
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onCancel}>
              {this.LANGUAGE.CANCEL_BUTTON}
            </button>
            <button
              className="button button--bg-13 modal-window__footer-btn"
              type="submit"
              disabled={submitting || !valid || pristine}
            >
              {this.LANGUAGE.CONFIRM_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const AtsAddVarModalForm = reduxForm({
  form: FORM_NAME,
})(AtsAddVarModal);

const mapStateToProps = ({languageState, scenario}) => ({
  languageState,
  scenario: scenario.payload,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createScenarioVariable,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AtsAddVarModalForm);
