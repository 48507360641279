import find from 'lodash/find';
import get from 'lodash/get';
import {createSelector} from 'reselect';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';

import {MEMBERSHIP_TO_ACCESS_LEVEL} from 'client/common/config';
import {MEMBERSHIPS_TYPES, USER_MEMBERSHIP, APP_ROLES} from 'client/common/config';

export function selectLogoForFooter(state) {
  if (state.userState.payload.role !== APP_ROLES.ADMIN && state.userState.payload.role !== APP_ROLES.SUPER_ADMIN) {
    const info = state.clientsList.client;
    const agencyLogo = get(info, 'agency.logo_url');
    const subsidiaryLogo = get(info, 'subsidiary.logo.url');
    return agencyLogo || subsidiaryLogo;
  }
  const info = state.adminUsers.adminUsersSubsidiary;
  return get(info, 'logo.url');
}

export function selectLogoForHeader(state) {
  const client = selectCurrentClient(state);
  return get(client, 'logo_url') || get(client, 'agency.logo_url') || get(client, 'subsidiary.logo.url');
}

export function selectClientUser(state) {
  return state.allUserClients.payload;
}
// TODO check for list; if needed to add company which is already in list
export const selectClientUserMemberships = createSelector(selectClientUser, (client) => {
  return client?.client_user?.memberships?.filter((membership) => membership.client) || [];
});

/**
 * @returns {import('client/models/memberships').Membeship|null}
 */
export function selectClientUserMembership(state) {
  const organization = selectCurrentOrganization(state);
  if (organization) {
    const memberships = selectClientUserMemberships(state);
    return find(memberships, {client_id: organization.id}) ?? find(memberships, {client_id: organization.agency_id});
  }
  return null;
}

export function selectClientAccessLevel(state) {
  const membership = selectClientUserMembership(state);
  return get(membership, 'access_level', '');
}

export function selectClientAccessAdditional(state) {
  const membership = selectClientUserMembership(state);
  return get(membership, 'access', '');
}

export const selectAllUserClients = createSelector(selectClientUserMemberships, (memberships) => {
  if (!memberships?.length) {
    return [];
  }
  const flatten = memberships.reduce((clients, membership) => {
    switch (membership.type) {
      case MEMBERSHIPS_TYPES.COMPANY:
        return clients.concat(membership.client);
      case MEMBERSHIPS_TYPES.AGENCY:
        const companies = membership.all_companies_access
          ? membership.client.companies
          : membership.company_accesses.map((company) => company.company);
        return clients.concat(membership.client).concat(companies);
      default:
        return clients;
    }
  }, []);
  const mapped = [];
  flatten.forEach((item, index) => {
    if (!index) {
      mapped.push(item);
    } else if (!mapped.some((el) => el.id === item.id)) {
      mapped.push(item);
    }
  });
  return mapped;
});

export const selectUserMemberships = createSelector(selectClientUserMemberships, (memberships) => {
  if (!memberships) {
    return {};
  }
  // It is important to check firstly role of user in company, and then in agency.
  // Because membership of agency has higher priority and
  // gives to user "full" access. Agency membership can rewrite access of company.
  const membershipsSorted = memberships.sort((a, b) =>
    a.type === MEMBERSHIPS_TYPES.COMPANY && b.type === MEMBERSHIPS_TYPES.AGENCY ? -1 : 1,
  );

  return membershipsSorted.reduce((acc, membership) => {
    let roles = {};

    switch (membership.type) {
      case MEMBERSHIPS_TYPES.COMPANY:
        roles = {[membership.client.id]: membership.access_level};
        break;
      case MEMBERSHIPS_TYPES.AGENCY:
        const companiesAccessess = membership.all_companies_access
          ? membership.client.companies.reduce((res, company) => {
              return {
                ...res,
                [company.id]: USER_MEMBERSHIP.NATIONAL,
              };
            }, {})
          : membership.company_accesses.reduce((res, companyAccess) => {
              return {
                ...res,
                [companyAccess.company_id]: USER_MEMBERSHIP.NATIONAL,
              };
            }, {});

        roles = {
          [membership.client.id]: membership.access_level,
          ...companiesAccessess,
        };
        break;
      default:
      //
    }

    return {...acc, ...roles};
  }, {});
});

export function selectCurrentOrganization(state) {
  return state.allUserClients.currentOrganization;
}

export const selectCurrentMembershipLevel = (state) => {
  const currentClient = selectCurrentClient(state);
  const userMemberships = selectUserMemberships(state);

  return userMemberships[currentClient.id];
};

export const selectCurrentAccessLevel = (state) => {
  return MEMBERSHIP_TO_ACCESS_LEVEL[selectCurrentMembershipLevel(state)];
};

/**
 *
 * @returns {import('client/models/memberships').Membership|null}
 * @deprecated use selectClientUserMembership;
 */
export const selectCurrentMembership = selectClientUserMembership;

export const selectClientUsersLoading = (state) => {
  return state.allUserClients.isLoading;
};
