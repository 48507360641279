import React from 'react';

import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons';
import Popover from 'client/common/popovers/popover';

import {TranslationJsx} from 'client/models/language/types';

import './copy-to-clipboard-popover.scss';

const b = bem('copy-to-clipboard-popover');

const CopyToClipboardPopover = ({title, text, children, ...rest}) => {
  const lang = useLanguage('COMMON.POPOVERS.COPY_TO_CLIPBOARD_POPOVER');

  return (
    <Popover
      overlay={
        <div className={b()}>
          {title && <h3 className={b('header')}>{title}</h3>}
          <span className={b('content')}>{text}</span>
          <div className={b('button-container')}>
            <CopyToClipboard text={text}>
              <AppButton label={lang.COPY} type="button" />
            </CopyToClipboard>
          </div>
        </div>
      }
      {...rest}
    >
      {children}
    </Popover>
  );
};

CopyToClipboardPopover.propTypes = {
  title: TranslationJsx,
  text: TranslationJsx.isRequired,
  children: PropTypes.node.isRequired,
};

CopyToClipboardPopover.defaultProps = {
  title: '',
};

export default CopyToClipboardPopover;
