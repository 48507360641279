import {EVENTS_META} from './meta.actions';

const initState = {
  payload: {
    title: '',
    description: '',
    keywords: [],
  },
};

export default function (stateCopy = initState, {type, payload}) {
  let state = Object.assign({}, stateCopy);

  switch (type) {
    case EVENTS_META.TYPE_META_SET:
      state.type = EVENTS_META.TYPE_META_SET;
      if (payload.keywords && payload.keywords.length) {
        state.payload.keywords = payload.keywords;
      }

      state.payload.title = payload.title ? payload.title : '';
      state.payload.keywords = payload.keywords ? payload.keywords : '';
      return state;
    default:
      return state;
  }
}
