import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import SelectDropdown from 'client/common/selects/select-dropdown';

import {Translation} from 'client/models/language/types';

import {OPTION_NAMES} from './constants';

import './participants-list-search-options.scss';

const b = bem('participants-list-search-options');

type ParticipantsListSearchOptionsProps = {
  value: string;
  onChange: (nextValue: string) => void;
  filterOptions?: (option: {value: string; label: Translation}) => boolean;
  className?: string;
};

const ParticipantsListSearchOptions: React.FC<ParticipantsListSearchOptionsProps> = (props) => {
  const {value, onChange, filterOptions, className} = props;
  const lang = useLanguage('PARTICIPANTS_LIST.SEARCH_OPTIONS');

  const options = [
    {value: OPTION_NAMES.LEAD_FIRST_NAME, label: lang.LEAD_FIRST_NAME},
    {value: OPTION_NAMES.LEAD_LAST_NAME, label: lang.LEAD_SECOND_NAME},
    {value: OPTION_NAMES.LEAD_EMAIL, label: lang.LEAD_EMAIL},
    {value: OPTION_NAMES.PARTICIPATION_DATA, label: lang.OPERATION_CUSTOM_DATA},
    {value: OPTION_NAMES.PRIZE_NAME, label: lang.PRIZE_NAME},
    {value: OPTION_NAMES.PRIZE_LEVEL, label: lang.PRIZE_LEVEL},
    {value: OPTION_NAMES.PRIZE_WIN_CODE, label: lang.PRIZE_WIN_CODE},
    {value: OPTION_NAMES.PRIZE_COUPON_TOKEN, label: lang.PRIZE_TOKEN},
    {value: OPTION_NAMES.DEVICE_ID, label: lang.SOURCE_DEVICE_ID},
    {value: OPTION_NAMES.SOURCE_ID, label: lang.SOURCE_ID},
    {value: OPTION_NAMES.DEVICE_NAME, label: lang.SOURCE_DEVICE_NAME},
    {value: OPTION_NAMES.SOURCE_NAME, label: lang.SOURCE_NAME},
    {value: OPTION_NAMES.CITY_NAME, label: lang.PLACE_CITY},
    {value: OPTION_NAMES.AGGLOMERATION_NAME, label: lang.PLACE_AGGLOMERATION},
    {value: OPTION_NAMES.ZONE_NAME, label: lang.PLACE_ZONE},
    {value: OPTION_NAMES.SESSION_ID, label: lang.SESSION_ID},
    {value: OPTION_NAMES.PARTICIPATION_ID, label: lang.PARTICIPATION_ID},
  ];

  return (
    <SelectDropdown
      className={cn(b(), className)}
      options={filterOptions ? options.filter(filterOptions) : options}
      value={value}
      onChange={onChange}
      simpleValue
    />
  );
};

export default ParticipantsListSearchOptions;
