import React, {Component} from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {error} from 'react-notification-system-redux';
import {connect} from 'react-redux';
import {getFormValues, startSubmit, stopSubmit, setSubmitSucceeded, setSubmitFailed} from 'redux-form';

import {getHomeDashboard} from 'client/services/helpers';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getEmailTemplate, patchEmailTemplate, fetchEditedTemplate} from 'client/ducks/email-templates/actions';
import {selectEmailTemplateLinks} from 'client/ducks/email-templates/selectors';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {CLIENT_PAGES, OPERATION_STATUS_TYPES, CLIENT_LEVEL_TYPES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';
import Icon from 'client/components/common/icon';
import TitleBlock from 'client/components/common/title-block';

import TemplateGameParameters, {
  TemplateGameParametersFormName,
} from 'client/components/templates/template-game-parameters';

import TemplateLinksList from '../components/lists/template-links-list';
import TemplateVarsList from '../components/lists/template-variables-list';
import TemplateBlock from '../components/template-block';

import '../templates.scss';

class Template extends Component {
  static propTypes = {
    linksValues: PropTypes.object,
    emailTemplateLinks: PropTypes.array.isRequired,
    fetchEditedTemplate: PropTypes.func.isRequired,
    setSubmitFailed: PropTypes.func.isRequired,
    setSubmitSucceeded: PropTypes.func.isRequired,
    stopSubmit: PropTypes.func.isRequired,
    startSubmit: PropTypes.func.isRequired,
    patchEmailTemplate: PropTypes.func.isRequired,
    errorNotification: PropTypes.func.isRequired,
    getEmailTemplate: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    template: PropTypes.object.isRequired,
    isNational: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isTemplateUpdating: false,
      disableUpdateButton: false,
    };

    this.LANGUAGE = {
      ...props.languageState.payload.TEMPLATE,
      BREADCRUMBS: props.languageState.payload.BREADCRUMBS,
    };
  }

  componentDidMount() {
    this.updateTemplate().then(this.updateBreadcrumbs);
  }

  componentDidUpdate() {
    this.styleNormalize();
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  updateTemplate = () => {
    return this.props.getEmailTemplate(this.props.id, {
      include: [
        'email_template_variables',
        'email_template_links',
        {
          operation: {
            dashboards: null,
            client: ['agency', 'dashboards'],
          },
        },
        {
          message_tasks: ['dashboards'],
        },
      ],
    });
  };

  updateBreadcrumbs = () => {
    const {
      isNational,
      template: {
        operation: {client, dashboards: operationDashboards, ...operation},
        ...template
      },
      languageState: {
        payload: {BREADCRUMBS},
      },
    } = this.props;

    const basicInfo = {
      clientId: client.id,
      clientType: client.type,
      operationId: operation.id,
    };

    const agencyBreadcrumb = client.agency
      ? [
          {
            href: CustomLink.createClientLink({
              clientId: client.agency.id,
              clientType: client.agency.type,
            }),
            name: client.agency.name,
          },
        ]
      : [];

    const messageTask = template.message_tasks.find((i) => i.dashboards.length) || {};
    const operationHref = CustomLink.createOperationLink(basicInfo);

    const operationHomeDashboard = getHomeDashboard(operationDashboards);
    const clientHomeDashboard = getHomeDashboard(client.dashboards);
    const messageTaskHomeDashboard = getHomeDashboard(messageTask.dashboards);

    const defaultBreadcrumbs = [
      {
        name: BREADCRUMBS.CLIENTS,
        href: CLIENT_PAGES.CLIENTS_LIST,
      },
      ...agencyBreadcrumb,
      {
        name: client.name,
        href: CustomLink.createClientLink(basicInfo),
      },
      {
        name: operation.name,
        href: operationHref,
      },
      {
        name: BREADCRUMBS.TEMPLATES,
        href: operationHref + CLIENT_PAGES.TEMPLATES,
      },
      {
        name: template.name,
      },
    ];

    const nationalBreadcrumbs = [
      {
        name:
          operation.status !== OPERATION_STATUS_TYPES.ACTIVE
            ? this.LANGUAGE.BREADCRUMBS.FINISHED_OPERATIONS
            : this.LANGUAGE.BREADCRUMBS.ONGOING_OPERATIONS,
        href:
          operation.status !== OPERATION_STATUS_TYPES.ACTIVE
            ? CLIENT_PAGES.OPERATIONS_FINISHED
            : CLIENT_PAGES.OPERATIONS_ACTIVE,
      },
      {
        name: get(clientHomeDashboard, 'name'),
        href:
          clientHomeDashboard &&
          CustomLink.createDashboardsLink({
            ...basicInfo,
            dashboardType: CLIENT_LEVEL_TYPES.CLIENT,
            dashboardId: clientHomeDashboard.id,
          }),
        hidden: !clientHomeDashboard,
      },
      {
        name: get(operationHomeDashboard, 'name'),
        href:
          operationHomeDashboard &&
          CustomLink.createDashboardsLink({
            ...basicInfo,
            dashboardType: CLIENT_LEVEL_TYPES.OPERATION,
            dashboardId: operationHomeDashboard.id,
          }),
        hidden: !operationHomeDashboard,
      },
      {
        name: get(messageTaskHomeDashboard, 'name'),
        href:
          messageTaskHomeDashboard &&
          CustomLink.createDashboardsLink({
            ...basicInfo,
            taskType: messageTask.type,
            taskId: messageTask.id,
            dashboardType: CLIENT_LEVEL_TYPES.MESSAGE_TASK,
            dashboardId: messageTaskHomeDashboard.id,
          }),
        hidden: !messageTaskHomeDashboard,
      },
      {
        name: template.name,
      },
    ];

    return this.props.setBreadcrumbs(isNational ? nationalBreadcrumbs : defaultBreadcrumbs);
  };

  handleUpdateTemplateClick = () => {
    this.setState({disableUpdateButton: true});

    return this.props
      .fetchEditedTemplate(this.props.id, {
        operation_email_template: {
          name: this.props.template.name,
        },
      })
      .then((result) => {
        this.setState({disableUpdateButton: false});

        if (result.error) {
          return this.props.errorNotification({
            message: this.LANGUAGE.ERRORS.MESSAGE,
          });
        }

        return this.setState({
          isTemplateUpdating: true,
        });
      });
  };

  handleSaveUpdatesClick = () => {
    this.setState({disableUpdateButton: true});
    this.props.startSubmit(TemplateLinksList.formName);
    const newLinks = Object.values(this.props.linksValues);
    const {gameParams} = this.props;
    const destroyedLinks = this.props.template.email_template_links.filter((link) => link._destroy);
    return this.props
      .patchEmailTemplate(this.props.id, {
        include: ['email_template_variables', 'email_template_links'],
        operation_email_template: {
          ...this.props.template,
          ...gameParams,
          email_template_links: [...destroyedLinks, ...newLinks],
        },
      })
      .then(({payload: {response: {errors} = {}}}) => {
        if (errors) {
          const validationErrors = errors.email_template_links.reduce((acc, item, index) => {
            if (!isEmpty(item)) {
              return {
                ...acc,
                [`item${index}`]: {label: this.LANGUAGE.ERRORS.NAME_IS_TAKEN},
              };
            }
            return acc;
          }, {});
          this.props.stopSubmit(TemplateLinksList.formName, validationErrors);

          const fieldNames = Object.keys(validationErrors).map((key) => key + '.label');
          this.props.setSubmitFailed(TemplateLinksList.formName, ...fieldNames);
          this.setState({
            disableUpdateButton: false,
          });
        } else {
          this.props.stopSubmit(TemplateLinksList.formName);
          this.props.setSubmitSucceeded(TemplateLinksList.formName);
          this.setState({
            disableUpdateButton: false,
            isTemplateUpdating: false,
          });
        }
      });
  };

  styleNormalize() {
    const elNormalizeSelector = '.templates__main-img';
    const imgs = document.querySelector(elNormalizeSelector)?.querySelectorAll('img');
    imgs?.forEach((img) => {
      const width = img.getAttribute('width');
      const height = img.getAttribute('height');
      if (width) {
        img.style.width = `${width}px`;
      }
      if (height) {
        img.style.height = `${height}px`;
      }
    });
    const tables = document.querySelector(elNormalizeSelector)?.querySelectorAll('table');
    tables?.forEach((table) => {
      const bgColor = table.getAttribute('bgcolor');
      if (bgColor) {
        table.style.backgroundColor = `${bgColor}`;
      }
    });
  }

  render() {
    const {template, isAdmin, emailTemplateLinks} = this.props;
    const {disableUpdateButton, isTemplateUpdating} = this.state;

    if (!template) {
      return null;
    }

    return (
      <div className="theme-color-16 templates">
        <div className="page__title-block flex-container flex-justify-between">
          <div>
            <TitleBlock theme>
              <TitleBlock.Item>{template.name}</TitleBlock.Item>
            </TitleBlock>
          </div>
        </div>
        <div className="templates__action-bar">
          {isAdmin && (
            <button
              className="button button--bg-14"
              onClick={isTemplateUpdating ? this.handleSaveUpdatesClick : this.handleUpdateTemplateClick}
              disabled={disableUpdateButton}
            >
              {!isTemplateUpdating && <Icon name="rotate" className="button__icon" />}
              <span>{isTemplateUpdating ? this.LANGUAGE.SAVE_BUTTON : this.LANGUAGE.UPDATE_BUTTON}</span>
            </button>
          )}
        </div>
        <div className="templates__main">
          <div className="templates__content">
            <div
              dangerouslySetInnerHTML={{
                __html: template.html_part || '',
              }}
              className="templates__main-img"
            />
          </div>
          <div className="templates__sidebar">
            <TemplateBlock
              className="templates__sidebar-block"
              title={this.LANGUAGE.GAME_PARAMS_TITLE}
              content={<TemplateGameParameters template={template} />}
            />
            <TemplateBlock
              scrollbarProps={{
                autoHeightMax: 240,
                autoHeightMin: 150,
              }}
              className="templates__sidebar-block"
              title={this.LANGUAGE.VARS_LIST_TITLE}
              content={<TemplateVarsList items={template.email_template_variables} />}
            />
            <TemplateBlock
              scrollbarProps={{
                autoHeightMax: 500,
                autoHeightMin: 300,
              }}
              className="templates__sidebar-block"
              title={this.LANGUAGE.LINKS_LIST_TITLE}
              content={
                <TemplateLinksList
                  items={emailTemplateLinks}
                  isAdmin={isAdmin}
                  isTemplateUpdating={isTemplateUpdating}
                />
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({languageState, ...state}) => ({
    linksValues: getFormValues(TemplateLinksList.formName)(state),
    gameParams: getFormValues(TemplateGameParametersFormName)(state),
    languageState,
    isAdmin: selectIsAdmin(state),
    template: state.emailTemplates.emailTemplate,
    emailTemplateLinks: selectEmailTemplateLinks(state),
  }),
  {
    setBreadcrumbs,
    clearBreadcrumbs,
    getEmailTemplate,
    patchEmailTemplate,
    fetchEditedTemplate,
    startSubmit,
    stopSubmit,
    setSubmitFailed,
    setSubmitSucceeded,
    errorNotification: error,
  },
)(Template);
