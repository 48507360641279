export const TIME_UNITS_VALUES = {
  DAYS: 'days',
  HOURS: 'hours',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
};

export const TIME_UNITS = [
  {value: TIME_UNITS_VALUES.DAYS, label: TIME_UNITS_VALUES.DAYS},
  {value: TIME_UNITS_VALUES.HOURS, label: TIME_UNITS_VALUES.HOURS, default: true},
  {value: TIME_UNITS_VALUES.MINUTES, label: TIME_UNITS_VALUES.MINUTES},
  {value: TIME_UNITS_VALUES.SECONDS, label: TIME_UNITS_VALUES.SECONDS},
];
