import React, {useMemo, useRef} from 'react';

import {useClickAway, useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';

import {ImageEditor, ImageEditorProps} from 'client/components/email-template-editor/editors/image-editor';
import {useEditor} from 'client/components/email-template-editor/hooks/useEditor';
import InsertImageModal from 'client/components/email-template-editor/modals/insert-image-modal';
import {
  ContentBlockData,
  ImageBlock as ImageBlockType,
  ImageBlockData,
} from 'client/components/email-template-editor/types';

import cssModule from './image-block.module.scss';

const b = bem('image-block', {cssModule});

type ImageBlockProps = {
  contentBlock: ImageBlockType;
  onSubmit: (data: ContentBlockData) => void;
};

export const ImageBlock: React.FC<ImageBlockProps> = (props) => {
  const {contentBlock, onSubmit} = props;
  const {activeBlockId, setActiveBlockId} = useEditor();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const isEditMode = useMemo(() => {
    return activeBlockId === contentBlock.id;
  }, [activeBlockId, contentBlock.id]);
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.BLOCKS.IMAGE_BLOCK');
  const [showModal, toggleModal] = useToggle(false);
  const {src, variableName, altText, redirectUrl, emailTemplateImage} = contentBlock?.content || {};

  useClickAway(wrapperRef, () => {
    if (activeBlockId === contentBlock.id) {
      setActiveBlockId(null);
    }
  });

  const handleChange: ImageEditorProps['onChange'] = (newData) => {
    onSubmit({
      ...newData,
      variableName,
    });
  };

  const handleClick = () => {
    if (activeBlockId !== contentBlock.id) {
      setActiveBlockId(contentBlock.id);
    }
  };

  const handleChooseImage = () => {
    setActiveBlockId(null);
    toggleModal(true);
  };

  const handleSubmitInsertImage = (data: ImageBlockData) => {
    const nextData = data;
    if (!nextData.variableName) {
      nextData.emailTemplateImage = emailTemplateImage;
    }
    onSubmit({
      ...nextData,
    });
  };

  return (
    <div className={b()} ref={wrapperRef} onClick={handleClick}>
      {showModal && <InsertImageModal onClose={toggleModal} onSubmit={handleSubmitInsertImage} />}
      {!src || variableName ? (
        <button type="button" className={b('placeholder', {clickable: !isEditMode})} onClick={toggleModal}>
          <Icon name="image-2" />
          <span>{variableName ? `{{${variableName}}}` : lang.EMPTY_STATE}</span>
        </button>
      ) : (
        <ImageEditor
          imageBlockData={contentBlock.content}
          isEditMode={isEditMode}
          onChange={handleChange}
          onChooseImage={handleChooseImage}
          defaultAlt={altText}
          defaultUrl={redirectUrl}
        />
      )}
    </div>
  );
};
