import {COUPON_TYPES} from 'client/models/prizes/constants';

import {COUPON_TYPE_MAP} from './constants';

const ADMIN_COUPON_TYPES = [COUPON_TYPES.WEEZIO, COUPON_TYPES.SOGEC, COUPON_TYPES.APP, COUPON_TYPES.CLIENT];

export const mapCoupon = (data) => {
  return {
    ...data,
    coupon_type: COUPON_TYPE_MAP[data.type],
  };
};

export const mapCoupons = (data) => {
  return data.filter((i) => ADMIN_COUPON_TYPES.includes(i.type)).map(mapCoupon);
};
