import React from 'react';

import bem from 'client/services/bem';

import Popover from 'client/common/popovers/popover';

import cssModule from './vertical-bar.module.scss';

const b = bem('vertical-bar', {cssModule});

const calculateOffset = (value: number) => (value - 80) * (163 / -80);

type VerticalBarProps = {
  color: string;
  label: string | number | string[] | number[];
  value: number;
  percent: number;
  maxValue: number;
  valuesCount: number;
  tooltip: string;
};

const VerticalBar: React.FC<VerticalBarProps> = (props) => {
  const {color, label, value, percent, tooltip, valuesCount} = props;

  return (
    <Popover
      className={b()}
      position="top"
      overlay={
        <div>
          <div className={b('hint-text')}>{tooltip}</div>
          <div className={b('hint-text')}>
            {Array.isArray(label) ? (
              <span>
                <span>{label[0]}</span>
                <span> – </span>
                <span>{label[1]}</span>
              </span>
            ) : (
              label
            )}
          </div>
          <div className={b('hint-text')} style={{color: color, fontWeight: 'bold'}}>
            {value}
          </div>
          <div className={b('hint-text')}>{valuesCount}%</div>
        </div>
      }
      shiftTop={calculateOffset(percent)}
      shiftLeft={4}
    >
      <div className={b('column-bar')} style={{backgroundColor: color, height: `${percent}%`}} />
    </Popover>
  );
};

export default VerticalBar;
