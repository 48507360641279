import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import set from 'lodash/set';
import unset from 'lodash/unset';
import moment from 'moment';

import {GAME_TYPES} from 'client/components/games/game-config-modal/constants';
import {REGEXP_SPLIT, PRIZE_TYPES} from 'client/components/games/game-config-modal/prize-form/constants';
import {getWeekdays} from 'client/components/games/game-config-modal/prize-form/helpers';

const timeToISO = (time) => {
  return moment()
    .set({hours: time.split(':')[0], minutes: time.split(':')[1]})
    .toISOString();
};

const mapSubstitutionForSave = (substitution, initialSubstitutions = []) => {
  return [
    ...initialSubstitutions.map(({id}) => ({id, _destroy: true})),
    ...substitution
      .filter(({order}) => order)
      .map((item) => ({
        id: null,
        substitute_prize_id: item.prizeId,
        order: +item.order,
      })),
  ];
};

const mapInstantWinForSave = ({values, game, isPrizeMap}, initialValues = {}) => {
  const body = {};

  if (game.game_type === 'instant_win' && !values.full_win) {
    if (values.instant_win_type === 'schedule_instant_win') {
      const initConfiguration = get(initialValues, 'schedule_instant_win_configuration', {});
      const id = get(initConfiguration, 'id');
      set(body, 'schedule_instant_win_configuration.id', id);
      const scheduleType = get(values, 'schedule_instant_win_configuration.schedule_type');
      set(body, 'schedule_instant_win_configuration.schedule_type', scheduleType);
      const isChangedType = get(initialValues, 'schedule_instant_win_configuration.schedule_type') !== scheduleType;

      let bodyTimestamps = [];

      const touchedWeeks = get(values, 'schedule_instant_win_configuration.touchedWeeks', []);
      if (!isChangedType) {
        bodyTimestamps = get(initConfiguration, isPrizeMap ? 'prize_map_timestamps' : 'prize_timestamps', []);
        if (scheduleType === 'manual') {
          bodyTimestamps = bodyTimestamps.map((timestamp) => ({
            id: timestamp.id,
            _destroy: true,
          }));
        } else {
          bodyTimestamps = bodyTimestamps.map((timestamp) => ({
            id: timestamp.id,
            _destroy: touchedWeeks.includes(getWeekdays({}).findIndex(({value}) => timestamp.weekday === value)),
          }));
        }
      }

      if (scheduleType === 'manual') {
        const timestamps = get(values, 'schedule_instant_win_configuration.manual.schedule_win_timestamps', '')
          .split(REGEXP_SPLIT)
          .filter(Boolean)
          .map((manual_time) => ({
            manual_time: moment(manual_time, 'DD/MM/YYYY HH:mm:ss').toISOString(),
          }));

        bodyTimestamps.push(...timestamps);

        set(
          body,
          `schedule_instant_win_configuration.${isPrizeMap ? 'prize_map_timestamps' : 'prize_timestamps'}`,
          bodyTimestamps,
        );

        set(
          body,
          'schedule_instant_win_configuration.intraday_win',
          !!get(values, 'schedule_instant_win_configuration.manual.intraday_win'),
        );
        set(
          body,
          'schedule_instant_win_configuration.next_day_win',
          !!get(values, 'schedule_instant_win_configuration.manual.next_day_win'),
        );
      } else if (scheduleType === 'fix_week') {
        const timestampsWeeks = get(values, 'schedule_instant_win_configuration.fix_week.schedule_win_timestamps', []);
        timestampsWeeks.forEach((weekTime, index) => {
          if (weekTime.win_time) {
            weekTime.win_time
              .split(REGEXP_SPLIT)
              .filter(Boolean)
              .forEach((winTime) => {
                if (touchedWeeks.includes(index)) {
                  bodyTimestamps.push({
                    weekday: getWeekdays({})[index].value,
                    win_time: timeToISO(winTime),
                  });
                }
              });
          }
        });
        set(
          body,
          `schedule_instant_win_configuration.${isPrizeMap ? 'prize_map_timestamps' : 'prize_timestamps'}`,
          bodyTimestamps,
        );

        const datesOff = get(values, 'schedule_instant_win_configuration.dates_off', '');
        const bodyDatesOff = [];
        datesOff
          .split(REGEXP_SPLIT)
          .filter(Boolean)
          .forEach((date) => bodyDatesOff.push(date));
        set(body, 'schedule_instant_win_configuration.dates_off', bodyDatesOff.filter(Boolean));

        set(
          body,
          'schedule_instant_win_configuration.intraday_win',
          !!get(values, 'schedule_instant_win_configuration.fix_week.intraday_win'),
        );
        set(
          body,
          'schedule_instant_win_configuration.next_day_win',
          !!get(values, 'schedule_instant_win_configuration.fix_week.next_day_win'),
        );
      } else if (scheduleType === 'calculated_rule') {
        const timestamps = get(
          values,
          'schedule_instant_win_configuration.calculated_rule.schedule_win_timestamps',
          [],
        );
        const prizesNumber = get(values, 'schedule_instant_win_configuration.calculated_rule.prizes_number', {});
        timestamps.forEach((times, index) => {
          if (times.win_time) {
            times.win_time
              .split(REGEXP_SPLIT)
              .reduce(function (result, value, indexWinTime, array) {
                if (indexWinTime % 2 === 0) {
                  result.push(array.slice(indexWinTime, indexWinTime + 2));
                }
                return result;
              }, [])
              .forEach(([from, to]) => {
                if (from && to && touchedWeeks.includes(index)) {
                  bodyTimestamps.push({
                    weekday: getWeekdays({})[index].value,
                    win_time_from: timeToISO(from),
                    win_time_to: timeToISO(to),
                  });
                }
              });
          }
        });

        set(
          body,
          `schedule_instant_win_configuration.${isPrizeMap ? 'prize_map_timestamps' : 'prize_timestamps'}`,
          bodyTimestamps,
        );
        set(body, 'schedule_instant_win_configuration.prizes_number', prizesNumber);
        set(
          body,
          'schedule_instant_win_configuration.intraday_win',
          !!get(values, 'schedule_instant_win_configuration.calculated_rule.intraday_win'),
        );
        set(
          body,
          'schedule_instant_win_configuration.next_day_win',
          !!get(values, 'schedule_instant_win_configuration.calculated_rule.next_day_win'),
        );

        const datesOff = get(values, 'schedule_instant_win_configuration.dates_off', '');
        const bodyDatesOff = [];
        datesOff.split(REGEXP_SPLIT).forEach((date) => bodyDatesOff.push(date));
        set(body, 'schedule_instant_win_configuration.dates_off', bodyDatesOff.filter(Boolean));
      }

      unset(body, 'schedule_instant_win_configuration.calculated_rule');
      unset(body, 'schedule_instant_win_configuration.fix_week');
      unset(body, 'schedule_instant_win_configuration.manual');
    } else if (values.instant_win_type === 'time_instant_win') {
      const initConfiguration = get(initialValues, 'time_instant_win_configuration', {});
      const id = get(initConfiguration, 'id');
      set(body, 'time_instant_win_configuration.id', id);
      body.time_instant_win_configuration = values.time_instant_win_configuration;
      const timeUnit = body.time_instant_win_configuration.time_unit;
      const unitsNumber = body.time_instant_win_configuration.units_number;
      body.time_instant_win_configuration.time_unit = timeUnit === 'none' || !unitsNumber ? null : timeUnit;
    } else if (values.instant_win_type === 'participation_instant_win') {
      const initConfiguration = get(initialValues, 'participation_instant_win_configuration', {});
      const id = get(initConfiguration, 'id');
      set(body, 'participation_instant_win_configuration.id', id);
      body.participation_instant_win_configuration = values.participation_instant_win_configuration;
      const timeUnit = body.participation_instant_win_configuration.time_unit;
      body.participation_instant_win_configuration.time_unit = timeUnit || null;
    }
  } else {
    delete body.schedule_instant_win_configuration;
  }
  delete body.instant_win_type;

  return body;
};

export const mappingValuesPrize = ({values = {}, game}, inititalValues) => {
  const body = {
    id: values.id,
    game_id: game.id,
    loyalty_only: values.loyalty_only,
    type: values.type === PRIZE_TYPES.COMMON ? null : values.type,
    image: values.file && values.file[0],
    geo_level: values.geo_level || 'none',
    online_level: values.online_level || 'none',
    internal_name: values.internal_name,
    external_name: values.external_name,
    code: values.code,
    comment: values.comment,
    external_description: values.external_description,
    default_initial_stock: values.default_initial_stock,
    default_stock_offset: values.default_stock_offset || 0,
    distribution: values.distribution,
    level: values.level,
    full_win: values.full_win,
    ...(values.full_win ? {} : mapInstantWinForSave({values, game}, inititalValues || {})),
  };

  if (body.full_win) {
    body.full_win_ratio = values.full_win_ratio || 0;
  }

  if (values.type === PRIZE_TYPES.COUPON) {
    body.coupon_id = values.coupon.id;
  }

  if (values.type === PRIZE_TYPES.LOYALTY) {
    body.loyalty_amount = values.loyalty_amount;
    body.loyalty_unit = values.loyalty_unit;
  }

  if (game.game_type === GAME_TYPES.ATTRIBUTION) {
    const substitutionForSave = mapSubstitutionForSave(
      values.substitution,
      (inititalValues || {}).prize_substitution_items || [],
    );
    if (substitutionForSave && substitutionForSave.length) {
      body.prize_substitution_items = substitutionForSave;
    }
    delete body.substitution;
  }

  return body;
};

export const mappingValuesPrizeMap = ({values = {}, game}, initialValues = {}) => {
  const body = {
    id: values.id,
    external_prize_name: values.external_name || null,
    prize_code: values.code || null,
    initial_stock: values.initial_stock || null,
    stock_offset: values.stock_offset || null,
    external_description: values.external_description || null,
    image: values.file && values.file[0],
    ...(initialValues.prize?.full_win ? {} : mapInstantWinForSave({values, game, isPrizeMap: true}, initialValues)),
  };

  return pickBy(body, (v) => !!v || v === null);
};
