export default {
  GET_SMS_TEMPLATE_KINDS_REQUEST: '@@sms-templates/GET_SMS_TEMPLATE_KINDS_REQUEST',
  GET_SMS_TEMPLATE_KINDS_SUCCESS: '@@sms-templates/GET_SMS_TEMPLATE_KINDS_SUCCESS',
  GET_SMS_TEMPLATE_KINDS_ERROR: '@@sms-templates/GET_SMS_TEMPLATE_KINDS_ERROR',

  UPDATE_SMS_TEMPLATES_BULK_REQUEST: '@@sms-templates/UPDATE_SMS_TEMPLATES_BULK_REQUEST',
  UPDATE_SMS_TEMPLATES_BULK_SUCCESS: '@@sms-templates/UPDATE_SMS_TEMPLATES_BULK_SUCCESS',
  UPDATE_SMS_TEMPLATES_BULK_ERROR: '@@sms-templates/UPDATE_SMS_TEMPLATES_BULK_ERROR',

  SEND_TEST_SMS_REQUEST: '@@sms-templates/SEND_TEST_SMS_REQUEST',
  SEND_TEST_SMS_SUCCESS: '@@sms-templates/SEND_TEST_SMS_SUCCESS',
  SEND_TEST_SMS_ERROR: '@@sms-templates/SEND_TEST_SMS_ERROR',
};
