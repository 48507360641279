import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {required} from 'client/services/validator';

import {LeadsCustomColumnFormValues} from './index';

export default (values: LeadsCustomColumnFormValues, lang: LangsByPath<['COMMON']>) => {
  return {
    name: required(lang.REQUIRED_FIELD)(values.name),
  };
};
