import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import {ApiAction} from 'client/types';

import types from './types';

export const getOptinColumns: ApiAction = (clientId) => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.OPT_IN_COLUMNS}`,
    method: 'GET',
    noCache: true,
    types: [types.GET_OPTIN_COLUMNS_REQUEST, types.GET_OPTIN_COLUMNS_SUCCESS, types.GET_OPTIN_COLUMNS_ERROR],
    queryParams: {
      include_opt_in_column_leads_count: null,
      q: {
        client_id_eq: clientId,
        s: 'level',
      },
    },
  });
};

export const getLeadColumns: ApiAction = (clientId) => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.LEADS_COLUMN}`,
    method: 'GET',
    noCache: true,
    types: [types.GET_LEAD_COLUMNS_REQUEST, types.GET_LEAD_COLUMNS_SUCCESS, types.GET_LEAD_COLUMNS_ERROR],
    queryParams: {
      q: {
        client_id_eq: clientId,
        distinct: true,
      },
    },
  });
};

export const createOptinColumn: ApiAction = (body) => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.OPT_IN_COLUMNS}`,
    method: 'POST',
    noCache: true,
    types: [types.CREATE_OPTIN_COLUMN_REQUEST, types.CREATE_OPTIN_COLUMN_SUCCESS, types.CREATE_OPTIN_COLUMN_ERROR],
    body,
  });
};

export const updateOptinColumn: ApiAction = (id, body) => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.OPT_IN_COLUMNS}/${id}`,
    method: 'PATCH',
    noCache: true,
    types: [types.UPDATE_OPTIN_COLUMN_REQUEST, types.UPDATE_OPTIN_COLUMN_SUCCESS, types.UPDATE_OPTIN_COLUMN_ERROR],
    body,
  });
};

export const deleteOptinColumn: ApiAction = (id) => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.OPT_IN_COLUMNS}/${id}`,
    method: 'DELETE',
    noCache: true,
    types: [types.DELETE_OPTIN_COLUMN_REQUEST, types.DELETE_OPTIN_COLUMN_SUCCESS, types.DELETE_OPTIN_COLUMN_ERROR],
  });
};
