import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {set as setMetaAction} from 'client/common/meta/meta.actions';

const ContactsPage = () => {
  const dispatch = useDispatch();
  const lang = useLanguage('CONTACTS');

  useEffect(() => {
    const title = lang.TITLE;
    dispatch(setMetaAction({title}));
  }, [lang, dispatch]);

  return <div>{lang.TITLE}</div>;
};

export default ContactsPage;
