import React from 'react';

import PropTypes from 'prop-types';

import {ToggleInput} from 'client/common/inputs';

const ToggleInputItem = ({value, ...restProps}) => (
  <ToggleInput
    rounded={true}
    checked={value}
    backgroundColor="current"
    borderColor="current"
    color="primary"
    {...restProps}
  />
);

ToggleInputItem.propTypes = {
  value: PropTypes.bool,
};

ToggleInputItem.defaultProps = {
  value: false,
};

export default ToggleInputItem;
