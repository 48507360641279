import React from 'react';

import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';

function UnsubscribeColumn(props) {
  const iconName = props.unsubscribe ? 'check' : 'clear';

  return (
    <div className="text-center">
      <Icon name={iconName} />
    </div>
  );
}

UnsubscribeColumn.propTypes = {
  unsubscribe: PropTypes.bool.isRequired,
};

export default UnsubscribeColumn;
