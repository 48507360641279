import React from 'react';

import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getClientUsersAction} from 'client/ducks/client-users/actions';
import {getAvailableColumnAdaptersByClient} from 'client/ducks/column-adapters/actions';
import {selectGeneralLeadColumns} from 'client/ducks/column-adapters/selectors';
import {getCustomColumns} from 'client/ducks/custom-columns/actions';
import {getColumnVisibilityItems, getDisplayColumns} from 'client/ducks/lead-display-items/actions';
import {selectTableReport} from 'client/ducks/lead/selectors';
import {getLeadsExportReport} from 'client/ducks/leads-export/actions';
import {deleteApiLeadsExport} from 'client/ducks/leads-export/actions';
import {getLeads} from 'client/ducks/leads-list/actions';
import {selectLeadsMailingTableMapped, selectLeadsMeta} from 'client/ducks/leads-list/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import CustomLink from 'client/components/common/custom-link';
import Icon from 'client/components/common/icon';
import TitleBlock from 'client/components/common/title-block';

import LeadsFilterModal from 'client/components/leads/components/modals/leads-filters-modal/leads-filters-modal';
import LeadsCommentsPopover from 'client/components/leads/components/popovers/leads-comments-popover';
import LeadsErrorPopover from 'client/components/leads/components/popovers/leads-error-popover';
import LeadsSettingsPopover from 'client/components/leads/components/popovers/leads-settings-popover';
import LeadsSummaryPopover from 'client/components/leads/components/popovers/leads-summary-popover';
import LeadsMainDataGrid from 'client/components/leads/components/tables/leads-main-data-grid/leads-main-data-grid';

import {mapFilter, mapFilterForUrl} from '../leads-list/helper';

import './leads-export.scss';

class LeadsExport extends ReactQueryParams {
  static defaultProps = {
    leadsExportTableReport: {},
    generalColumns: [],
    customColumnscustomColumns: {},
  };

  static propTypes = {
    languageState: PropTypes.object.isRequired,
    leadsExportTableReport: PropTypes.object,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    exportId: PropTypes.string.isRequired,
    getLeadsExportReport: PropTypes.func.isRequired,
    getClientUsersAction: PropTypes.func.isRequired,
    deleteApiLeadsExport: PropTypes.func.isRequired,
    generalColumns: PropTypes.array,
    customColumns: PropTypes.object,
    getAvailableColumnAdaptersByClient: PropTypes.func.isRequired,
    getColumnVisibilityItems: PropTypes.func.isRequired,
    getCustomColumns: PropTypes.func.isRequired,
    leadDisplayItems: PropTypes.object.isRequired,
    ...withRouter.propTypes,
  };

  static MODALS_MAP = {
    FILTERS: 'FILTERS',
    TABLE_SETTINGS: 'TABLE_SETTINGS',
  };

  static DEFAULT_SORT_FIELD = 'first_name';
  static DEFAULT_SORT_ORDER = 'asc';
  static DEFAULT_FIRST_PAGE = 1;
  static DEFAULT_PER_PAGE = 5;

  constructor(props) {
    super(props);
    this.LANGUAGE = props.languageState.payload.LEADS_PORT;
    this.LANGUAGE_LEADS = props.languageState.payload.AGENCY;
    this.state = {
      sort: {
        name: '',
        order: '',
      },
      search: '',
      showClear: false,
      searchDefault: '',
      filter: {},
      visibleModal: '',
    };
  }

  componentDidMount() {
    this.getReport().then(() => {
      const {clientId} = this.props.leadsExportTableReport;

      this.props.getClientUsersAction({
        include: ['memberships'],
        q: {
          memberships_client_id_eq: clientId,
        },
      });
      this.setBreadCrumbs();
      this.getExportedLeads();

      if (clientId) {
        this.props.getDisplayColumns(clientId);
        this.props.getColumnVisibilityItems(clientId);
        this.props.getCustomColumns(clientId, true);
        this.props.getAvailableColumnAdaptersByClient(clientId);
      }
    });
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  getReport = () => {
    return this.props.getLeadsExportReport(this.props.exportId, {
      include: {
        user: null,
        lead_transfer: {
          client: null,
        },
        memberships: {
          client_user: null,
        },
      },
    });
  };

  getExportedLeads = () => {
    const {queryParams} = this;

    const sort =
      `${(queryParams.sort && queryParams.sort.name) || LeadsExport.DEFAULT_SORT_FIELD} ` +
      `${(queryParams.sort && queryParams.sort.order) || LeadsExport.DEFAULT_SORT_ORDER}`;

    const search = queryParams.search;

    const params = {
      distinct: true,
      include_lead_expires_at: null,
      include: {
        opt_in_columns: null,
        client: null,
        last_participation: 'operation.client',
        participations: {
          interaction: 'interface',
          operation: 'client',
        },
        broadcast_recipients: null,
        last_export: 'client',
        first_source: {
          client: null,
          interaction: 'interface.automation_task.operation.client',
        },
      },
      q: {
        lead_histories_source_id_eq: this.props.leadsExportTableReport.transferId,
        lead_histories_source_type_eq: 'LeadTransfer',
        s: sort,
        first_name_or_last_name_cont: search,
      },
      page: queryParams.page || LeadsExport.DEFAULT_FIRST_PAGE,
      per_page: queryParams.perPage || LeadsExport.DEFAULT_PER_PAGE,
    };

    if (queryParams.filter) {
      const {q, distinct} = mapFilterForUrl(mapFilter(queryParams.filter));

      params.distinct = distinct;
      params.q = {
        ...params.q,
        ...q,
      };
      params.q.g = [params.q.g];
    }

    if (queryParams.broadcast_list_id) {
      params.q = {
        ...params.q,
        broadcast_recipients_broadcast_list_id_eq: queryParams.broadcast_list_id,
      };
    }
    window.scrollTo(0, 0);
    return this.props.getLeads(params);
  };

  setBreadCrumbs = () => {
    const {
      languageState: {
        payload: {BREADCRUMBS},
      },
      leadsExportTableReport,
    } = this.props;

    this.props.setBreadcrumbs([
      {
        name: BREADCRUMBS.CLIENTS,
        href: CLIENT_PAGES.CLIENTS_LIST,
      },
      {
        name: leadsExportTableReport.clientName,
        href: CustomLink.createClientLink({
          clientType: leadsExportTableReport.clientType,
          clientId: leadsExportTableReport.clientId,
        }),
      },
      {
        name: BREADCRUMBS.LEADS,
        href: CLIENT_PAGES.LEADS_LIST + '/' + leadsExportTableReport.clientId,
      },
      {
        name: leadsExportTableReport.reportName,
      },
    ]);
  };

  onSortChange = (name, order) => {
    this.setQueryParams({sort: {name, order}}, true);
    this.getExportedLeads();
  };

  onPageChange = ({page, perPage}) => {
    this.setQueryParams({page, perPage}, true);
    this.getExportedLeads();
  };

  onSearchChange = (search) => {
    this.setQueryParams(
      {
        search,
        page: LeadsExport.DEFAULT_FIRST_PAGE,
      },
      true,
    );
    this.getExportedLeads();
  };

  onSearchClear = () => {
    this.setQueryParams({search: ''}, true);
    this.getExportedLeads();
  };

  onFilterChange = (filter) => {
    this.setQueryParams({filter: mapFilter(filter), page: LeadsExport.DEFAULT_FIRST_PAGE}, true);
    this.getExportedLeads();
  };

  onFilterClear = () => {
    this.onFilterChange({});
  };

  onFilterFieldClear = (fieldsList) => {
    const obj = omit(this.queryParams.filter, fieldsList);
    this.onFilterChange(obj);
  };

  handleShowModal = (name) => {
    this.setState({visibleModal: name});
  };

  handleCloseModal = () => this.setState({visibleModal: ''});

  handleDeleteReport = (id) => {
    this.setState({
      reportForDelete: id,
      showDeleteReport: true,
    });
  };

  onDeleteReport = (id) => {
    this.props.deleteApiLeadsExport(id).then(() => {
      this.props.history.push(CLIENT_PAGES.LEADS_LIST + '/' + this.props.leadsExportTableReport.clientId);
    });
    this.setState({showDeleteReport: false});
  };

  render() {
    const {leadsExportTableReport, leadsList: data, meta} = this.props;
    const {visibleModal} = this.state;
    const {queryParams} = this;
    return (
      <div className="theme-color-2">
        <ConfirmationModal
          show={this.state.showDeleteReport}
          onClose={() => this.setState({showDeleteReport: false})}
          onCancel={() => this.setState({showDeleteReport: false})}
          title={this.LANGUAGE_LEADS.DELETE_REPORT_TITLE_TEXT}
          message={this.LANGUAGE_LEADS.DELETE_REPORT_MODAL_TEXT}
          cancelText={this.LANGUAGE_LEADS.DELETE_CLIENTS_PAGE_MODAL_CANCEL}
          confirmText={this.LANGUAGE_LEADS.DELETE_CLIENTS_PAGE_MODAL_CONFIRM}
          className="modal-window--theme-1"
          buttonConfirmClass="button--bg-1"
          buttonCancelClass="button--bg-5"
          onConfirm={() => this.onDeleteReport(this.state.reportForDelete)}
        />

        <LeadsFilterModal
          show={visibleModal === LeadsExport.MODALS_MAP.FILTERS}
          onClose={this.handleCloseModal}
          onFilterChange={this.onFilterChange}
          filter={queryParams.filter}
        />
        <div className="flex-container flex-justify-between">
          <div className="page__title-block">
            <TitleBlock theme={true} separator={true}>
              <TitleBlock.Item>{leadsExportTableReport.reportName}</TitleBlock.Item>
              {leadsExportTableReport.format && (
                <TitleBlock.Item>
                  {`${this.LANGUAGE.PAGE_TITLE_FORMAT}: `}
                  <b>{leadsExportTableReport.format}</b>
                </TitleBlock.Item>
              )}
            </TitleBlock>
          </div>
          <div className="leads-import__header-icons">
            <div className="flex-container flex-align-center">
              <div>
                <LeadsSummaryPopover leadsReport={leadsExportTableReport} refresh={this.getReport}>
                  <div className="leads-import__header-icon">
                    <Icon name="summary" />
                  </div>
                </LeadsSummaryPopover>
              </div>
              <div>
                {leadsExportTableReport.hasErrors ? (
                  <LeadsErrorPopover date={leadsExportTableReport.created_at} url={leadsExportTableReport.url}>
                    <div className="leads-import__header-icon">
                      <Icon
                        name="status-error"
                        className={
                          leadsExportTableReport.type && leadsExportTableReport.type.startsWith('Api') ? '' : 'hidden'
                        }
                      />
                    </div>
                  </LeadsErrorPopover>
                ) : (
                  <LeadsSettingsPopover
                    userName={leadsExportTableReport.userName}
                    memberships={leadsExportTableReport.memberships}
                    allowAll={leadsExportTableReport.allowed_for_all}
                    dateFrom={leadsExportTableReport.dateFrom}
                    dateTo={leadsExportTableReport.dateTo}
                    leadTransferId={String(leadsExportTableReport.leadTransferId)}
                    deleteReport={this.handleDeleteReport}
                  >
                    <Icon
                      name="settings"
                      className={
                        leadsExportTableReport.type && leadsExportTableReport.type.startsWith('Api') ? '' : 'hidden'
                      }
                    />
                  </LeadsSettingsPopover>
                )}
              </div>
              <div>
                <LeadsCommentsPopover comment={leadsExportTableReport.comment}>
                  <div className="leads-import__header-icon">
                    <Icon name="message" className={leadsExportTableReport.comment ? '' : 'hidden'} />
                  </div>
                </LeadsCommentsPopover>
              </div>
            </div>
          </div>
        </div>
        <div>
          <LeadsMainDataGrid
            data={data}
            meta={meta}
            filter={queryParams.filter}
            clientId={String(leadsExportTableReport.clientId)}
            onPageChange={this.onPageChange}
            onSortChange={this.onSortChange}
            onSearchChange={this.onSearchChange}
            onSearchClear={this.onSearchClear}
            showClear={!!queryParams.search}
            searchDefault={queryParams.search || ''}
            onFilterShow={() => this.handleShowModal(LeadsExport.MODALS_MAP.FILTERS)}
            onFilterChange={this.onFilterChange}
            onFilterClear={this.onFilterClear}
            onFilterFieldClear={this.onFilterFieldClear}
            isReportTable={true}
            allGeneralColumns={this.props.generalColumns}
            allCustomColumns={this.props.customColumns}
            leadDisplayItems={this.props.leadDisplayItems}
            perPage={queryParams.perPage}
            sort={queryParams.sort}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    languageState: state.languageState,
    leadsExportTableReport: selectTableReport(state, false),
    leadsList: selectLeadsMailingTableMapped(state).tableData,
    meta: selectLeadsMeta(state),
    generalColumns: selectGeneralLeadColumns(state),
    customColumns: state.customColumns,
    leadDisplayItems: state.leadDisplayItems,
  }),
  {
    setBreadcrumbs,
    clearBreadcrumbs,
    getLeadsExportReport,
    getLeads,
    getClientUsersAction,
    deleteApiLeadsExport,
    getColumnVisibilityItems,
    getCustomColumns,
    getAvailableColumnAdaptersByClient,
    getDisplayColumns,
  },
)(withRouter(LeadsExport));
