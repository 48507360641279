import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

class InterfaceMappingService {
  static _dbNames = {};
  static _columnTypes = {};

  /**
   * Method for initiating fields
   * @param {Object} dbNames list of names
   * @param {Object} columnTypes list of column types
   * @returns {null} Null
   */
  static init = (dbNames, columnTypes) => {
    InterfaceMappingService._dbNames = dbNames;
    InterfaceMappingService._columnTypes = columnTypes;
  };

  static _convertMappingItem = (mapItems) => {
    return mapItems.map((item) => {
      let mappedItem;
      switch (item.type) {
        case 'DataMappingItem':
          mappedItem = {
            ...InterfaceMappingService._mapDataMappingItem(item),
          };
          break;
        case 'TriggerMappingItem':
          mappedItem = {
            ...InterfaceMappingService._mapTriggerMappingItem(item),
          };
          break;
        case 'OptInMappingItem':
          mappedItem = {
            ...InterfaceMappingService._mapOptInMappingItem(item),
          };
          break;
        default:
          break;
      }
      if (item.unique) {
        mappedItem.unique = item.unique;
      }
      if (item.required) {
        mappedItem.required = item.required;
      }
      return mappedItem;
    });
  };

  /**
   * Method for converting interface items
   * @param {Array} mappingItems Array of items
   * @param {Object} dbNames Array of names of DBs
   * @param {Object} columnTypes column types
   * @returns {Array} mapped items
   */
  static convertMappingItems = (mappingItems) => {
    if (mappingItems && mappingItems.length) {
      return mappingItems.map(InterfaceMappingService._convertMappingItem);
    }
    return [];
  };

  static _getDbName = (columnAdapter) => {
    const {record_type, db_number} = columnAdapter;
    let key;

    switch (record_type) {
      case 'Visual':
        key = `${record_type}${db_number}`;
        break;
      case 'ParticipationPrize':
        key = `Win${db_number}`;
        break;
      default:
        key = record_type;
    }

    return InterfaceMappingService._dbNames[key];
  };

  static _getColumnType = (columnAdapter) => {
    const {record_type, db_number} = columnAdapter;
    let key;

    switch (record_type) {
      case 'Visual':
        key = `${record_type}${db_number}`;
        break;
      case 'ParticipationPrize':
        key = `Win${db_number}`;
        break;
      case 'OperationDatum':
        key = 'OPERATION';
        break;
      default:
        key = record_type;
    }

    return InterfaceMappingService._columnTypes[key.toUpperCase()];
  };

  // private method
  static _mapDataMappingItem = (item) => {
    return {
      id: item.id,
      type: {
        label: InterfaceMappingService._getDbName(item.column_adapter),
        value: InterfaceMappingService._getColumnType(item.column_adapter),
        type: item.type,
      },
      column: {
        label: item.column_adapter.name,
        value: item.column_adapter.id,
        type: item.column_adapter.type,
        array: false,
        kind: item.column_adapter.kind,
      },
    };
  };

  // private method
  static _mapTriggerMappingItem = (item) => {
    return {
      id: item.id,
      type: {
        label: 'Scenarios',
        type: 'TriggerMappingItem',
        value: 'scenarios',
      },
      column: {
        label: item.scenario.name,
        value: item.scenario.id,
        type: item.scenario.type,
        array: false,
      },
    };
  };

  /**
   * static private method for mapping item to OptIn
   * @param {Object} item origin item
   * @returns {Object} mapped item
   */
  static _mapOptInMappingItem = (item) => {
    return {
      fieldType: 'opt_in',
      opt_in_text: item.opt_in_text,
      type: {
        label: item.column_adapter.name,
        opt_in_text: item.opt_in_text,
        type: 'OptInMappingItem',
        value: item.column_adapter_id,
      },
    };
  };

  /**
   * Private method for detecting if interface item is changed
   * @param {object} item modified interface item
   * @param {object} oldItem initial interface item
   * @returns {boolean} boolean value
   */
  static _isInterfaceItemChanged = (item, oldItem) => {
    if (
      (item.id && item.name !== oldItem.name) ||
      (item.id && item.readable_name !== oldItem.readable_name) ||
      item.kind !== oldItem.kind ||
      item.array !== oldItem.array ||
      item.value_as_true !== oldItem.value_as_true ||
      item.value_as_false !== oldItem.value_as_false ||
      (item.mapping_items.length &&
        oldItem.mapping_items.length &&
        item.mapping_items.length !== oldItem.mapping_items.length)
    ) {
      return true;
    }

    if (oldItem.mapping_items.length !== item.mapping_items.length) {
      return true;
    }

    const oldItemMappingItems = oldItem.mapping_items;
    const changedMappingItemsList = [];

    item.mapping_items.forEach((el, idx) => {
      // select correct old mapping item
      const oldItemMappings = oldItemMappingItems[idx];

      if (
        el.type !== oldItemMappings.type ||
        el.column_adapter_id !== oldItemMappings.column_adapter_id ||
        el.required !== oldItemMappings.required
      ) {
        changedMappingItemsList.push(el.id);
      }

      if (el.type === 'OptInMappingItem' && el.opt_in_text !== oldItemMappings.opt_in_text) {
        changedMappingItemsList.push(el.id);
      }
    });
    return Boolean(changedMappingItemsList.length);
  };

  /**
   * Method for checking if item is in a list
   * @param {Object} item item
   * @param {Array} list items list
   * @param {String} param key
   * @returns {Boolean} if item is in list
   */
  static _isItemInList = (item, list, param = 'id') => {
    return list.length && item.id ? list.map((el) => el[param]).includes(item[param]) : false;
  };

  static _checkIfItemDeletedFromList = (item, itemsList) => {
    return itemsList.length && item.id ? !itemsList.map((el) => el.id).includes(item.id) : false;
  };

  static _transformMappedItemForSave = (mappingItem) => {
    if (mappingItem._destroy) {
      return {
        id: mappingItem.id,
        _destroy: mappingItem._destroy,
      };
    }
    let transformedMappingItem = {type: mappingItem.type.type};
    if (mappingItem.id) {
      transformedMappingItem.id = mappingItem.id;
    }
    switch (mappingItem.type.type) {
      case 'DataMappingItem':
        transformedMappingItem.column_adapter_id = mappingItem.column.value;
        transformedMappingItem.required = mappingItem.required || false;
        transformedMappingItem.unique = mappingItem.unique || false;
        break;

      case 'TriggerMappingItem':
        transformedMappingItem.scenario_id = mappingItem.column.value;
        break;

      case 'OptInMappingItem':
        transformedMappingItem.column_adapter_id = mappingItem.type.value;
        transformedMappingItem.opt_in_text = mappingItem.opt_in_text;
        transformedMappingItem.required = mappingItem.required || false;
        break;

      default:
        transformedMappingItem = {};
    }

    return transformedMappingItem;
  };

  static _transformMappedItemsForSave = (mappingItems, initialMappingItems) => {
    const deleted = initialMappingItems
      .filter((item) => {
        const itemIds = mappingItems.map((el) => el.id);
        return !itemIds.includes(item.id);
      })
      .map((item) => ({
        id: item.id,
        _destroy: true,
      }));
    const transformed = mappingItems
      .map(InterfaceMappingService._transformMappedItemForSave)
      .filter((i) => !isEmpty(i));

    return [...deleted, ...transformed];
  };

  /**
   * Method for mapping modified item
   * @param {Array} items final items list
   * @param {Array} initialItems initial mapping items list
   * @returns {Array} mapped list
   */
  static mapModifiedInterfaceItems = (items, initialItems) => {
    // const initialMappingItems = initialItems.map(item => item.mapping_items);
    // const mappingItems = items.map(item => item.mapping_items);
    const modified = [];
    items.forEach((item) => {
      const itemData = {
        array: item.array || false,
        kind: item.kind,
        external_id: item.external_id,
        name: item.name,
        readable_name: item.readable_name,
      };

      if (item.id) {
        itemData.id = item.id;
      }

      itemData.value_as_true = itemData.kind === 'boolean' ? item.value_as_true || '' : null;
      itemData.value_as_false = itemData.kind === 'boolean' ? item.value_as_false || '' : null;
      // mapping items
      const initialMappingItems = initialItems.find((initialItem) => item.id === initialItem.id).mapping_items;
      if (item.mapping_items.length) {
        itemData.mapping_items = InterfaceMappingService._transformMappedItemsForSave(
          item.mapping_items,
          initialMappingItems,
        );
      } else if (initialMappingItems.length) {
        itemData.mapping_items = initialMappingItems.map((mappingItem) => ({
          id: mappingItem.id,
          _destroy: true,
        }));
      }
      if (
        InterfaceMappingService._isInterfaceItemChanged(
          item,
          initialItems.find((initialItem) => initialItem.id === item.id),
        )
      ) {
        modified.push(itemData);
      }
      // return itemData;
    });

    return modified;
  };

  /**
   * Public method for mapping deleted items
   * @param {Array} items final items
   * @param {Array} initItems initial items
   * @returns {Array} mapped list of deleted items
   */
  static mapDeletedInterfaceItems = (items, initItems) => {
    const deleted = [];
    const itemIds = items.map((el) => el.id);
    initItems.forEach((item) => {
      if (!itemIds.includes(item.id)) {
        const initialItem = initItems.find((element) => element.id === item.id);
        deleted.push({
          ...initialItem,
          mapping_items: initialItem.mapping_items.length
            ? initialItem.mapping_items.map((el) => ({
                ...el,
                _destroy: true,
              }))
            : [],
          _destroy: true,
        });
      }
    });

    return deleted;
  };

  /**
   * Public method for mapping items modified by kind
   * @param {Array} modifiedItems list of modified items
   * @param {Array} initialItems list of initial items
   * @returns {Array} list of mapped items
   */
  static mapModifiedItemsByKind = (modifiedItems, initialItems) => {
    const modifiedKinds = [];

    if (!modifiedItems.length || !initialItems.length) {
      return modifiedKinds;
    }
    modifiedItems.forEach((item) => {
      const initialItem = initialItems.find((el) => el.id === item.id);
      if (initialItem && initialItem.kind !== item.kind) {
        modifiedKinds.push({
          ...initialItem,
          array: item.array,
          name: item.name,
          kind: item.kind,
          mapping_items: item.mapping_items,
        });
      }
    });

    return modifiedKinds;
  };

  static mapCreatedItem = (createdItem) => {
    const {mapping_items = []} = createdItem;
    return {
      interface_item: {
        ...createdItem,
        mapping_items: mapping_items.map(InterfaceMappingService._transformMappedItemForSave),
      },
    };
  };

  static validateLeadMatchingRule = (interfaceItems, leadColumns) => {
    const allMappingItems = interfaceItems.reduce((acc, interfaceItem) => {
      return [...acc, ...(interfaceItem.mapping_items || [])];
    }, []);

    const leadMappingItems = allMappingItems.filter((mappingItem) => {
      const adapterId = get(mappingItem, 'column_adapter_id') || get(mappingItem, 'column.value');
      return adapterId && find(leadColumns, {value: adapterId});
    });

    if (!leadMappingItems.length) {
      return true;
    }

    return leadMappingItems.filter(({unique}) => unique).length > 0;
  };
}

export default InterfaceMappingService;
