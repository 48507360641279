import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import {ApiAction} from 'client/types';

import types from './types';

export const deleteAnimation: ApiAction = (id: string | number) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.ANIMATIONS}/${id}`,
        method: 'DELETE',
        types: [types.DELETE_ANIMATION_REQUEST, types.DELETE_ANIMATION_SUCCESS, types.DELETE_ANIMATION_ERROR],
      },
      true,
    );
  };
};
