import React from 'react';

import find from 'lodash/find';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getClientPage} from 'client/services/helpers';

import {getAutotask} from 'client/ducks/autotask/actions';
import {selectAutotask, selectAutotaskPrizeNames} from 'client/ducks/autotask/selectors';
import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import DataTabContainer from 'client/components/client-autotask/tabs/data-tab/data-common';
import {DATA_COMPONENTS} from 'client/components/client-autotask/tabs/data-tab/data-common/constants';

class WinnersPage extends ReactQueryParams {
  static propTypes = {
    autotask: PropTypes.object,
    operationId: PropTypes.number,
    prizeNames: PropTypes.arrayOf(PropTypes.object),
    getAutotask: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.fetchData();
    this.props.setMetaAction({title: this.props.language.TITLE});
  }

  componentDidUpdate(prevProps) {
    if (this.props.autotaskId !== prevProps.autotaskId) {
      this.fetchData();
    }
  }

  updateBreadcrumbs = () => {
    let breadcrumbs = [];

    if (this.props.autotask) {
      if (this.props.isNational) {
        breadcrumbs = this.buildNationalBreadcrumbs();
        return this.props.setBreadcrumbs(breadcrumbs);
      }

      const {operationId, autotask, isAdmin} = this.props;
      const clientId = this.props.match.params.clientId;

      const operationDashboard = get(autotask, 'operation.dashboards[0]', {});
      const homeDashboard = find(autotask.dashboards, {kind: 'home'}) || {};

      const operationDashboardPath = isAdmin ? '' : `${CLIENT_PAGES.DASHBOARDS}/${operationDashboard.id}`;
      const homeDashboardPath = isAdmin ? '' : `${CLIENT_PAGES.DASHBOARDS}/${homeDashboard.id}`;

      breadcrumbs = breadcrumbs.slice(0, -1).concat([
        {
          href: getClientPage(autotask.client) + '/' + clientId,
          name: autotask.client?.name,
          hidden: false,
        },
        {
          href:
            `${getClientPage(autotask.client)}/${clientId}${CLIENT_PAGES.OPERATIONS}/${operationId}` +
            operationDashboardPath,
          name: autotask.operation.name,
          hidden: false,
        },
        {
          href:
            `${getClientPage(autotask.client)}/${clientId}${CLIENT_PAGES.OPERATIONS}/${operationId}` +
            `${CLIENT_PAGES.AUTOTASK}/${autotask.id}` +
            homeDashboardPath,
          name: autotask.name,
          hidden: false,
        },
        {
          name: this.props.language.TITLE,
          hidden: false,
        },
      ]);
    }

    return this.props.setBreadcrumbs(breadcrumbs);
  };

  fetchData = () => {
    const {
      match: {
        params: {taskId},
      },
    } = this.props;

    this.props
      .getAutotask(taskId, {
        include: {
          client: null,
          dashboards: null,
          operation: {dashboards: null},
          custom_prize_type_names: null,
        },
      })
      .then(this.updateBreadcrumbs);
  };

  render() {
    const {
      match: {
        params: {taskId},
      },
      prizeNames,
    } = this.props;
    return (
      <DataTabContainer
        autotaskId={+taskId}
        component={DATA_COMPONENTS.WINNERS}
        prizeNames={prizeNames}
        prizeNamePath=" "
        isAdmin={true}
        showReportsButtons={true}
      />
    );
  }
}

const mapStateToProps = ({languageState, ...state}) => ({
  autotask: selectAutotask(state),
  isAdmin: selectIsAdmin(state),
  language: languageState.payload.DATA_TAB.WINNERS_TABLE,
  operationId: state.autotask.payload.operation.id,
  prizeNames: selectAutotaskPrizeNames(state),
});

const mapDispatchToProps = {
  getAutotask,
  setBreadcrumbs,
  clearBreadcrumbs,
  setMetaAction: set,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WinnersPage));
