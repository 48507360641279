import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getPercent} from 'client/services/helpers';

import {getLeadsPerCampaign} from 'client/ducks/leads-dashboard/actions';
import {selectLeadsPerCampaign} from 'client/ducks/leads-dashboard/selectors';

import DashboardCard from 'client/components/common/cards/dashboard-card';
import BarsList from 'client/components/common/charts-custom/bars-list';
import CustomScrollbars from 'client/components/common/custom-scrollbars';

class LeadsPerCampaign extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    empty: PropTypes.bool.isRequired,
    leadsPerCampaign: PropTypes.array.isRequired,
    getLeadsPerCampaign: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
  };

  componentDidMount() {
    this.fetchLeadsPerCampaign();
  }

  componentDidUpdate(prevProps) {
    if (this.props.client.id !== prevProps.client.id) {
      this.fetchLeadsPerCampaign();
    }
  }

  toggleLoading = () => this.setState(({loading}) => ({loading: !loading}));

  fetchLeadsPerCampaign = () => {
    this.toggleLoading();
    return this.props
      .getLeadsPerCampaign({
        client_id: this.props.client.id,
      })
      .then(this.toggleLoading);
  };

  getData = () => {
    const {client, leadsPerCampaign} = this.props;

    return leadsPerCampaign.map((item) => ({
      name: item.name,
      value: item.new_leads_count,
      percent: getPercent(client.leads_count, item.new_leads_count, false),
    }));
  };

  render() {
    const {lang, empty} = this.props;
    const {loading} = this.state;

    return (
      <DashboardCard
        title={lang.LEADS_PER_CAMPAIGN}
        iconName="leads-per-campaign"
        empty={empty}
        emptyText={lang.NO_DATA}
        emptyHeight={255}
        loading={loading}
        loadingColor="leads"
      >
        <CustomScrollbars
          scrollbarProps={{
            autoHeightMax: 310,
          }}
        >
          <BarsList data={this.getData()} />
        </CustomScrollbars>
      </DashboardCard>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.languageState.payload.LEADS_DASHBOARD,
    leadsPerCampaign: selectLeadsPerCampaign(state),
  }),
  {
    getLeadsPerCampaign,
  },
)(LeadsPerCampaign);
