export const CHART_COLORS = [
  '#F6D976',
  '#8ED2F8',
  '#FE9191',
  '#C886D2',
  '#98EA91',
  '#8589F1',
  '#85EEEE',
  '#5DC4C4',
  '#F4A0FB',
  '#00629c',
  '#e12b2b',
  '#b958c9',
  '#76B136',
  '#F67D3C',
  '#B92A6C',
  '#71C9FB',
  '#FFAAAA',
  '#48B7B7',
  '#89B6F9',
  '#FFC267',
  '#42D085',
  '#8CEEEE',
  '#787DF8',
  '#F44E9A',
  '#D0F1AC',
  '#F6D7FF',
];
