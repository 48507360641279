import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons/app-button';

import cssModule from './plus-button.module.scss';

const b = bem('plus-button', {cssModule});

type PlusButtonProps = {
  className?: string;
  onClick: () => void;
};

const PlusButton: React.FC<PlusButtonProps> = (props) => {
  const {className, onClick} = props;

  return (
    <AppButton
      className={cn(b(), className)}
      onClick={onClick}
      iconName="plus-simple"
      iconConfig={{width: 20, height: 20}}
      asWrap
    />
  );
};

export default PlusButton;
