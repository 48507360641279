import React from 'react';

import PropTypes from 'prop-types';
import {useHistory, useParams, generatePath} from 'react-router-dom';

import bem from 'client/services/bem';

import {CLIENT_PAGES} from 'client/common/config';

import {
  AnimationStatusCell,
  AnimationBrandCell,
  AnimationHrCell,
  AnimationInstallCell,
  AnimationAnomalyCell,
  AnimationActionsCell,
} from 'client/components/instore/cells';
import {ANIMATION_STATUSES} from 'client/components/instore/constants';
import {getAnimationDate} from 'client/components/instore/helpers';

import './animation-card.scss';

const b = bem('animation-card');

const AnimationCard = (props) => {
  const {item, testId} = props;
  const history = useHistory();
  const params = useParams();

  const path =
    CLIENT_PAGES.CLIENTS +
    '/:clientId' +
    CLIENT_PAGES.OPERATIONS +
    '/:operationId' +
    CLIENT_PAGES.INSTORE_TASKS +
    '/:taskId' +
    CLIENT_PAGES.ANIMATIONS +
    '/:animationId';

  const handleCardClick = () => {
    history.push(generatePath(path, {...params, animationId: item.id}));
  };

  return (
    <button type="button" className={b()} onClick={handleCardClick} tabIndex={0} data-testid={testId}>
      <div className={b('top')}>
        <AnimationBrandCell item={item} size={24} renderNoValue={false} />
        <div>
          <div className={b('place')}>{item.place?.name}</div>
          <div className={b('from')}>{getAnimationDate(item.animation_days, 'from', false)}</div>
        </div>
      </div>
      <div className={b('bottom')}>
        <AnimationStatusCell item={item} showName={true} />
        <div className={b('bottom-right')}>
          {item.status === ANIMATION_STATUSES.EDIT ? (
            <AnimationActionsCell className={b('cell')} item={item} onEdit={handleCardClick} />
          ) : (
            <>
              <AnimationHrCell className={b('cell')} item={item} />
              <AnimationInstallCell className={b('cell')} item={item} />
              <AnimationAnomalyCell className={b('cell')} item={item} />
            </>
          )}
        </div>
      </div>
    </button>
  );
};

AnimationCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    place: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    animation_days: PropTypes.array.isRequired,
  }).isRequired,
  testId: PropTypes.string,
};

AnimationCard.propTypes = {
  testId: 'animation-card',
};

export default AnimationCard;
