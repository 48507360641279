import {useSelector} from 'react-redux';

import {selectCurrentUser} from 'client/ducks/user/selectors';

import {getInstoreDashboardType} from 'client/components/instore-dashboards/helpers';
import {INSTORE_DASHBOARD_TYPES} from 'client/models/instore-dashboard/constants';

export default (dashboard) => {
  const user = useSelector(selectCurrentUser);
  const dashboardType = getInstoreDashboardType(dashboard, user.id);

  return {
    isUser: dashboardType === INSTORE_DASHBOARD_TYPES.USER,
    isGlobal: dashboardType === INSTORE_DASHBOARD_TYPES.GLOBAL,
    isWeezio: dashboardType === INSTORE_DASHBOARD_TYPES.WEEZIO,
  };
};
