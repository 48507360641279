import React from 'react';

import PropTypes from 'prop-types';

function NameColumn(props) {
  const {name} = props;

  return <div className="break-word">{name}</div>;
}

NameColumn.propTypes = {
  name: PropTypes.string,
};

export default NameColumn;
