import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import SearchInput from 'client/common/inputs/search-input';

import Icon from 'client/components/common/icon';

import PerPage from 'client/components/tables/common/per-page';

import './filter-bar.scss';

function FilterBar(props) {
  const {params, onParamsChange, onPlacesViewClick, onMapViewClick, isDevices} = props;

  const LANGUAGE = props.languageState.payload.AUTOTASK_PLAN.CLIENT_PLACES;
  return (
    <div className="filter-bar">
      <div className="filter-bar__search-field">
        <SearchInput onSearch={(value) => onParamsChange({search: value, page: 1})} searchDefault={params.search} />
      </div>
      <div className="filter-bar__buttons-container">
        <button className="filter-bar__button" onClick={onPlacesViewClick}>
          <span>{isDevices ? LANGUAGE.PLACES_VIEW_BUTTON : LANGUAGE.DEVICES_VIEW_BUTTON}</span>
        </button>
        <button className="filter-bar__button" onClick={onMapViewClick}>
          <Icon name="map-line" className="filter-bar__icon" />
          <span>{LANGUAGE.MAP_VIEW_BUTTON}</span>
        </button>
      </div>
      <div className="filter-bar__dropdown">
        <PerPage currentValue={params.perPage} onChange={onParamsChange} />
      </div>
    </div>
  );
}

FilterBar.propTypes = {
  languageState: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  onParamsChange: PropTypes.func.isRequired,
  onPlacesViewClick: PropTypes.func.isRequired,
  isDevices: PropTypes.bool.isRequired,
  onMapViewClick: PropTypes.func.isRequired,
};

export default connect(({languageState}) => ({
  languageState,
}))(FilterBar);
