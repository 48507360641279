import React, {useState, useRef} from 'react';

import cn from 'classnames';
import {get} from 'lodash';
import moment from 'moment/moment';
import {useDispatch} from 'react-redux';

import bem from 'client/services/bem';
import {uid} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';
import useReduxForm from 'client/services/hooks/use-redux-form';

import FileButton from 'client/common/buttons/file-button';
import {CheckboxField} from 'client/common/fields';
import DateTimeListField from 'client/common/fields/datetime-list-field';
import {useToast} from 'client/common/hooks/useToast';

import {validateImportedValues} from 'client/components/prizes/fieldsets/prize-diy-custom-list-fieldset/helpers';

import cssModule from './prize-diy-custom-list-fieldset.module.scss';

const b = bem('prize-diy-custom-list-fieldset', {cssModule});

export const TIMESTAMPS_FIELD_NAME = 'customList.timestamps';

type PrizeDiyCustomListFieldsetProps = {
  formName: string;
  disabled?: boolean;
  hasAdditionalControls?: boolean;
  className?: string;
  classNames?: {
    description?: string;
    inputContainer?: string;
    inputFields?: string;
    button?: string;
    errorMessage?: string;
    additionalControls?: string;
  };
  required?: boolean;
};

const sortCb = (aDate: {value: string}, bDate: {value: string}) =>
  moment(aDate.value, 'DD/MM/YYYY HH:mm:ss').toISOString() < moment(bDate.value, 'DD/MM/YYYY HH:mm:ss').toISOString()
    ? -1
    : 0;

const PrizeDiyCustomListFieldset: React.FC<PrizeDiyCustomListFieldsetProps> = ({
  disabled,
  formName,
  hasAdditionalControls,
  className,
  classNames,
  required = false,
}) => {
  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_CUSTOM_LIST_FIELDSET');
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {appendToastNotification} = useToast();

  const [key, setKey] = useState<string>(uid);

  const {formValues, change, errors} = useReduxForm(formName);

  const handleFileImport = (file: File) => {
    file.text().then((content) => {
      const entries = content.split(',').map((entry) => entry.trim());
      const dateTimeList = validateImportedValues(entries);

      if (dateTimeList.length) {
        const nextValue = [...get(formValues, TIMESTAMPS_FIELD_NAME), ...dateTimeList].sort(sortCb) || [];
        dispatch(change(TIMESTAMPS_FIELD_NAME, nextValue));
        setKey(uid()); // force DateTimeListField to re-render to make it re-initialize its state with imported data
      }

      const rejectedLength = entries.length - dateTimeList.length;
      appendToastNotification({
        type: rejectedLength ? 'warning' : 'success',
        title: file.name,
        description: `${dateTimeList.length} ${lang.ERROR_MESSAGE.RECORDS_IMPORTED},
        ${rejectedLength} ${lang.ERROR_MESSAGE.REJECTED}`,
        shouldPersist: rejectedLength > 0,
      });

      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // reset selected file
      }
    });
  };

  const hasError = get(errors, TIMESTAMPS_FIELD_NAME);

  return (
    <div className={cn(b(), className)}>
      <p className={cn(b('description'), classNames?.description)}>{lang.DESCRIPTION}</p>
      <div className={cn(b('input-container', {disabled, error: hasError}), classNames?.inputContainer)}>
        <DateTimeListField
          key={key}
          name={TIMESTAMPS_FIELD_NAME}
          className={cn(b('input-fields'), classNames?.inputFields)}
          type="date-time"
          disabled={disabled}
          maskChars={{second: '0'}}
          placeholder={lang.INPUT_PLACEHOLDER}
          required={required}
        />
        <FileButton
          color="light-clients"
          iconName="exports"
          className={cn(b('button'), classNames?.button)}
          label={lang.IMPORT_BUTTON_LABEL}
          disabled={disabled}
          onChange={handleFileImport}
          acceptFormats=".txt"
          ref={fileInputRef}
        />
      </div>
      <p className={b('import-hint-message')}>{lang.IMPORT_HINT}</p>
      {hasAdditionalControls && (
        <div className={cn(b('additional-controls'), classNames?.additionalControls)}>
          <span>{lang.PRIZES_LEFT}</span>
          <div className={b('same-day')}>
            <CheckboxField
              label={lang.NEXT_DAY_LABEL}
              color="primary"
              inversionColor
              name="customList.next_day_win"
              disabled={disabled}
            />
            <CheckboxField
              label={lang.SAME_DAY_LABEL}
              color="primary"
              inversionColor
              name="customList.intraday_win"
              disabled={disabled}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PrizeDiyCustomListFieldset;
