import React, {Component} from 'react';

import PropTypes from 'prop-types';
import Select from 'react-select-plus';

import {TranslationJsx} from 'client/models/language/types';

import CustomScrollbars from '../custom-scrollbars';
import Icon from '../icon';

const optionStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '5px',
  alignItems: 'center',
};

class CustomSelect extends Component {
  static propTypes = {
    cssModifier: PropTypes.string,
    placeholder: TranslationJsx,
    noResultsText: TranslationJsx,
    value: PropTypes.any,
    options: PropTypes.array,
    clearable: PropTypes.bool,
    multi: PropTypes.bool,
    searchable: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.any,
    icon: PropTypes.any,
    scrollBarProps: PropTypes.object,
  };

  static defaultProps = {
    cssModifier: '',
    placeholder: '',
    noResultsText: 'No results found',
    value: null,
    options: [],
    multi: false,
    clearable: false,
    searchable: false,
    onBlur: () => {},
    onChange: () => {},
    error: false,
    disabled: false,
    label: null,
    icon: null,
    scrollBarProps: {},
  };

  menuRenderer = (params) => {
    const menu = Select.defaultProps.menuRenderer(params);
    const {scrollBarProps} = this.props;

    return (
      <CustomScrollbars
        scrollbarProps={{
          autoHeightMax: 190,
          ...scrollBarProps,
          hideTracksWhenNotNeeded: true,
        }}
        cssModifier="custom-scrollbars--view-2"
      >
        {menu}
      </CustomScrollbars>
    );
  };

  valueRenderer = (option) => {
    return (
      <div className="select__value">
        {option.labelKey || option.label}{' '}
        {option.selectCustomOption && <span className="select__value-custom">{option.selectCustomOption}</span>}
      </div>
    );
  };

  optionRenderer = (item) => {
    const {icon} = this.props;

    return (
      <div className="select__option-content" style={optionStyles}>
        {icon && <span className="select__option-icon">{icon}</span>}
        <span className="select__option-text">{item.labelKey || item.label}</span>
        {item.selectCustomOption && <span className="select__option-custom">{item.selectCustomOption}</span>}
      </div>
    );
  };

  arrowRenderer = () => {
    return <Icon name="dropdown" className="select__icon" />;
  };

  render() {
    const {
      cssModifier,
      placeholder,
      value,
      options,
      clearable,
      searchable,
      onBlur,
      onChange,
      error,
      disabled,
      label,
      multi,
      noResultsText,
    } = this.props;

    const errorClass = error ? 'select--error' : '';

    return (
      <div className={`select ${cssModifier} ${errorClass}`}>
        {label && <div className="select__label">{label}</div>}
        <Select
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          multi={multi}
          options={options}
          onBlur={onBlur}
          className="select__field"
          searchable={searchable}
          clearable={clearable}
          arrowRenderer={this.arrowRenderer}
          onChange={onChange}
          menuRenderer={this.menuRenderer}
          optionRenderer={this.optionRenderer}
          valueRenderer={this.valueRenderer}
          noResultsText={noResultsText}
        />
        {error && <p className="select__message">{error}</p>}
      </div>
    );
  }
}

export default CustomSelect;
