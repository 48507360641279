import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons';

import {Translation} from 'client/models/language/types';
import {Option, OptionValue} from 'client/types/common/index';

import cssModule from './checkbox-button-group.module.scss';

const b = bem('checkbox-button-group', {cssModule});

type CheckboxButtonGroupProps = {
  value?: OptionValue[];
  onChange?: (value: any[], selectedValue: any) => void;
  options: Option[];
  className?: string;
  classNames?: {
    label?: string;
    button?: string;
    controls?: string;
  };
  label?: Translation;
};

const CheckboxButtonGroup: React.FC<CheckboxButtonGroupProps> = (props) => {
  const {value, options, onChange, className, classNames, label} = props;

  const handleClick = (selectedValue: OptionValue) => {
    const nextValue = value?.includes(selectedValue)
      ? value?.filter((v) => v !== selectedValue)
      : [...(value || []), selectedValue];
    onChange?.(nextValue || [], selectedValue);
  };

  return (
    <div className={cn(b(), className)}>
      {label && <span className={cn(b('label'), classNames?.label)}>{label}</span>}
      <div className={cn(b('controls'), classNames?.controls)}>
        {options.map((option) => (
          <AppButton
            key={option.value}
            label={option.label}
            value={option.value}
            onClick={() => handleClick(option.value as OptionValue)}
            className={cn(b('button', {checked: value?.includes(option.value)}), classNames?.button)}
            outline={!value?.includes(option.value)}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckboxButtonGroup;
