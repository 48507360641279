import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {getValueIfExists} from 'client/services/helpers';

import {getAutotask} from 'client/ducks/autotask/actions';
import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getScenarioExecutions} from 'client/ducks/scenario-run-table/actions';

import {CLIENT_PAGES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';
import Icon from 'client/components/common/icon';
import TitleBlock from 'client/components/common/title-block';

import RunTableDataGrid from './components/tables/run-table-data-grid';

import './scenario-run-table.scss';

class ScenarioRunTable extends ReactQueryParams {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    getScenarioExecutions: PropTypes.func.isRequired,
    getAutotask: PropTypes.func.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    autotaskId: PropTypes.number,

    globalView: PropTypes.bool,
    scenarioRunTable: PropTypes.shape({
      scenarioExecutions: PropTypes.array,
      meta: PropTypes.object,
    }),
    autotask: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE;
  }

  componentDidMount() {
    const {autotaskId} = this.props;

    const promise = autotaskId
      ? this.props.getAutotask(this.props.autotaskId, {
          include: ['operation.client.agency'],
        })
      : Promise.resolve();

    promise.then(this.updateMe).then(this.updateBreadcrumbs);
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  updateBreadcrumbs = () => {
    const {BREADCRUMBS} = this.props.languageState.payload;

    if (this.props.globalView) {
      return this.props.setBreadcrumbs([
        {
          href: CLIENT_PAGES.VISUALS,
          name: BREADCRUMBS.VISUALS,
        },
        {
          name: BREADCRUMBS.SCENARIO_RUN_TABLE,
        },
      ]);
    }

    const {
      autotask: {
        id: taskId,
        name: taskName,
        type: taskType,
        operation: {
          name: operationName,
          id: operationId,
          client,
          client: {name: clientName, id: clientId, type: clientType},
        },
      },
    } = this.props;

    const agencyBreadcrumb = client.agency
      ? [
          {
            href: CustomLink.createClientLink({
              clientType: client.agency.type,
              clientId: client.agency.id,
            }),
            name: client.agency.name,
          },
        ]
      : [];

    return this.props.setBreadcrumbs([
      {
        href: CLIENT_PAGES.VISUALS,
        name: BREADCRUMBS.VISUALS,
      },
      ...agencyBreadcrumb,
      {
        name: clientName,
        href: CustomLink.createClientLink({
          clientType,
          clientId,
        }),
      },
      {
        name: BREADCRUMBS.CUSTOM_OPERATION,
        href: CustomLink.createCustomOperationDBLink({
          clientId,
          clientType,
          operationId,
        }),
      },
      {
        name: operationName,
        href: CustomLink.createOperationLink({
          clientId,
          clientType,
          operationId,
        }),
      },
      {
        name: taskName,
        href: CustomLink.createTaskLink({
          taskType,
          clientType,
          operationId,
          clientId,
          taskId,
        }),
      },
      {
        name: BREADCRUMBS.SCENARIO_RUN_TABLE,
      },
    ]);
  };

  updateMe = (newFilters) => {
    const {
      queryParams: {sort, search, filters: queryFilters, ...params},
    } = this;
    const filters = newFilters || queryFilters || {};

    const sortParam = {
      name: (sort && sort.name) || 'id',
      order: (sort && sort.order) || 'ASC',
    };

    if (!params.perPage) {
      this.setQueryParams({perPage: 5});
    }

    const scenarioExecutionsParams = {
      page: params.page || 1,
      per_page: +params.perPage || 5,
      include: [
        {
          participation: [
            {
              interaction: ['interface', 'source'],
            },
            'lead',
          ],
        },
        {
          scenario: [
            'scenario_variables',
            'scenario_step_variables',
            {
              automation_task: [
                {
                  operation: ['client'],
                },
              ],
            },
          ],
        },
        {
          scenario_step: ['scenario', 'scenario_step_variables'],
        },
        'stopped_by',
      ],
      q: {
        s: `${sortParam.name} ${sortParam.order}`,
        scenario_step_implementation_class_name_in: filters.runningSteps,
        state_in: filters.statuses,
        g: search && {
          0: {
            m: 'or',
            participation_lead_first_name_cont: search,
            participation_lead_last_name_cont: search,
          },
        },
      },
    };

    if (!this.props.globalView) {
      scenarioExecutionsParams.q.automation_task_id_eq = this.props.autotaskId;
    }

    switch (getValueIfExists(filters, ['startDate', 'type'])) {
      case 'FROM':
        scenarioExecutionsParams.q.created_at_gt = filters.startDate.value;
        break;
      case 'TILL':
        scenarioExecutionsParams.q.created_at_lt = filters.startDate.value;
        break;
      default:
        break;
    }

    switch (getValueIfExists(filters, ['endDate', 'type'])) {
      case 'FROM':
        scenarioExecutionsParams.q.ended_at_gt = filters.endDate.value;
        break;
      case 'TILL':
        scenarioExecutionsParams.q.ended_at_lt = filters.endDate.value;
        break;
      default:
        break;
    }

    if (filters.triggeredScenarios && filters.triggeredScenarios.length > 0) {
      scenarioExecutionsParams.q.scenario_id_in = filters.triggeredScenarios.map((i) => i.id);
    }

    if (filters.interfaces && filters.interfaces.length > 0) {
      scenarioExecutionsParams.q.participation_interaction_interface_id_in = filters.interfaces.map((i) => i.id);
    }
    window.scrollTo(0, 0);
    return this.props.getScenarioExecutions(scenarioExecutionsParams);
  };

  onSearchChange = (search = '') => {
    this.setQueryParams({search, page: 1}, true);
    this.setState({search}, this.updateMe);
  };

  onSearchClear = () => this.onSearchChange();

  onPageChange = (params) => {
    this.setQueryParams(params, true);
    this.updateMe();
  };

  onSortChange = (name, order) => {
    this.setQueryParams(
      {
        sort: {name, order},
      },
      true,
    );
    this.updateMe();
  };

  render() {
    const {scenarioExecutions, meta} = this.props.scenarioRunTable;
    return (
      <div className="theme-color-9 scenario-run-table">
        <div className="page__title-block flex-container flex-align-center">
          <TitleBlock theme={true}>
            <TitleBlock.Item>{this.LANGUAGE.TITLE}</TitleBlock.Item>
            <TitleBlock.Item>
              <button className="button button--square button--bg-4 refresh-button" onClick={() => this.updateMe()}>
                <Icon name="rotate" className="button__icon" />
              </button>
            </TitleBlock.Item>
          </TitleBlock>
        </div>

        <RunTableDataGrid
          data={scenarioExecutions}
          autotaskId={this.props.autotaskId}
          meta={meta}
          onSearchChange={this.onSearchChange}
          onSearchClear={this.onSearchClear}
          onPageChange={this.onPageChange}
          perPage={+this.queryParams.perPage}
          onSortChange={this.onSortChange}
          sort={this.queryParams.sort}
          updateMe={this.updateMe}
          search={this.queryParams.search}
          globalView={this.props.globalView}
        />
      </div>
    );
  }
}

export default connect(
  ({languageState, scenarioRunTable, autotask}) => ({
    languageState,
    scenarioRunTable,
    autotask: autotask.payload,
  }),
  {
    setBreadcrumbs,
    clearBreadcrumbs,
    getScenarioExecutions,
    getAutotask,
  },
)(ScenarioRunTable);
