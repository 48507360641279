import sortBy from 'lodash/sortBy';

import {getPercent} from 'client/services/helpers';

import {EXCLUDABLE_KEYS} from '../constants';
import {roundValue} from '../helpers';

const getIntervalGraphData = (data) => {
  const numericKeys = Object.keys(data)
    .filter((key) => !EXCLUDABLE_KEYS.includes(key))
    .sort((a, b) => a - b);

  const intervals = Array.from(numericKeys, (item, index) => ({
    min: +item,
    max: +numericKeys[index + 1] - 1,
    total: data[item],
  }));
  intervals.pop();
  const mappedData = intervals.map(({min, max, total}) => ({
    label: [min, max],
    value: total,
  }));
  const undefinedKeys = Object.keys(data).filter((key) => EXCLUDABLE_KEYS.includes(key) && data[key]);
  if (undefinedKeys.length) {
    undefinedKeys.forEach((key) =>
      mappedData.push({
        value: data[key],
        label: key,
      }),
    );
  }
  return mappedData;
};

export const getHorizontalGraphData = (data = {}, total, method, dot_value) => {
  const maxValue = Math.max(...Object.values(data));
  if (method === 'value') {
    const groupedData = Object.entries(data).reduce((acc, [key, value]) => {
      return acc[key] ? {...acc, [key]: acc[key] + value} : {...acc, [key]: value};
    }, {});
    const mappedData = Object.entries(groupedData)
      .filter(([key]) => (EXCLUDABLE_KEYS.includes(key) && data[key]) || !EXCLUDABLE_KEYS.includes(key))
      .map(([key, value]) => ({
        label: roundValue(key),
        value,
        percent: getPercent(total, value),
        barPercent: getPercent(maxValue, value),
      }));

    return sortBy(mappedData, ({label}) => +label);
  }
  const intervalData = getIntervalGraphData(data, dot_value);

  const transformedintervalData = intervalData.map(({label, value}) => {
    const barLabel = Array.isArray(label) && label.length === 2 ? `${label[0]} - ${label[1]}` : label;
    return {
      label: barLabel,
      value,
      percent: getPercent(total, value),
      barPercent: getPercent(maxValue, value),
    };
  });

  const maxIntervalValue = transformedintervalData.reduce((acc, item) => {
    return item.value > acc ? item.value : acc;
  }, 0);

  return transformedintervalData.map(({value, ...item}) => ({
    ...item,
    value,
    percent: getPercent(total, value),
    barPercent: getPercent(maxIntervalValue, value),
  }));
};
