import {SubmissionError} from 'redux-form';

import store from '../../store';

export function scenarioVariableSave({response: {errors}}) {
  const {languageState} = store({}).getState();

  if (errors) {
    const submitErrors = {};

    if (errors.scenario && errors.scenario.includes('taken')) {
      submitErrors.source_id = languageState.payload.AUTOTASK_SCENARIO.VAR_FORM.SOURCE_ID_TAKEN_ERROR;
    }

    if (errors.name && errors.name.includes('taken')) {
      submitErrors.name = languageState.payload.AUTOTASK_SCENARIO.VAR_FORM.NAME_TAKEN_ERROR;
    }

    throw new SubmissionError(submitErrors);
  }
}
