export default function (animation) {
  return {
    surface: animation?.surface,
    electric_plug: animation?.electric_plug,
    dock: animation?.dock,
    stock: animation?.stock,
    log_conditions: animation?.log_conditions,
    comments: animation?.comments,
  };
}
