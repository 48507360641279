import React from 'react';

import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectClientAccessLevel} from 'client/ducks/user-clients/selectors';

import {USER_MEMBERSHIP} from 'client/common/config';

import Icon from 'client/components/common/icon';

import {Client} from 'client/models/client/types';

import cssModule from './leads-navigation.module.scss';

const b = bem('leads-navigation', {cssModule});

type LeadsNavigationProps = {
  client: Client;
};

const LeadsNavigation: React.FC<LeadsNavigationProps> = (props) => {
  const {client} = props;
  const lang = useLanguage('LEADS_DASHBOARD');
  const accessLevel = useSelector(selectClientAccessLevel);

  return (
    <nav className={b()}>
      <h1 className={b('title')}>{lang.LEADS}</h1>
      {accessLevel === USER_MEMBERSHIP.CLIENT_ADMIN && (
        <ul className={b('nav-list')}>
          <li className={b('nav-item')}>
            <Link to={`/clients/${client.id}/leads/database`} className={b('nav-link')}>
              <span className={b('link-label')}>{lang.LINKS.DATABASE}</span>
              <span>
                <Icon name="arrow-right" />
              </span>
            </Link>
          </li>
          <li className={b('nav-item')}>
            <Link to={`/clients/${client.id}/leads/parameters`} className={b('nav-link')}>
              <span className={b('link-label')}>{lang.LINKS.PARAMETERS}</span>
              <span>
                <Icon name="arrow-right" />
              </span>
            </Link>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default LeadsNavigation;
