import React, {useMemo} from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {URL_REGEX} from 'client/services/validator';

import Icon from 'client/components/common/icon';

import './accordion-summary.scss';

const b = bem('accordion-summary');

type AccordionSummaryProps = {
  title: string;
  iconName?: string;
  tooltips?: React.ReactNode;
  className?: string;
  classNames?: {
    title?: string;
  };
  tooltipIconConfig?: {
    width?: number;
    height?: number;
  };
};

const AccordionSummary: React.FC<AccordionSummaryProps> = (props) => {
  const {iconName = '', title, tooltips, tooltipIconConfig = {width: 20, height: 20}, className, classNames} = props;
  const headerIcon = useMemo(() => {
    if (iconName && typeof iconName === 'string') {
      return iconName.match(new RegExp(URL_REGEX.source, URL_REGEX.flags + 'g')) ? (
        <img src={iconName} className={b('icon')} alt="" {...tooltipIconConfig} />
      ) : (
        <Icon name={iconName} className={b('icon')} {...tooltipIconConfig} />
      );
    }

    return null;
  }, [iconName, tooltipIconConfig]);

  return (
    <summary className={cn(b(), className)}>
      <div className={b('inner')}>
        {headerIcon}
        <p className={cn(b('title'), classNames?.title)}>{title}</p>
        <div className={b('tooltips')}>{tooltips}</div>
      </div>
    </summary>
  );
};

export default AccordionSummary;
