import React, {Component} from 'react';

import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, Field} from 'redux-form';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';

import {TranslationJsx} from 'client/models/language/types';

class BCMailingListForm extends Component {
  static propTypes = {
    formName: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    pristine: PropTypes.bool.isRequired,
    cancelButtonText: TranslationJsx,
    confirmButtonText: TranslationJsx,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    getListRef: PropTypes.func,
  };

  static defaultProps = {
    cancelButtonText: '',
    confirmButtonText: '',
    getListRef: () => {},
  };

  renderList = () => {
    const {data} = this.props;

    return data.map((item) => {
      return (
        <div className="form-field-wrap form-field-wrap--margin-3" key={item.id}>
          <Field
            cssModifier="radio-button--view-3"
            type="radio"
            name="id"
            value={`${item.id}`}
            label={item.name}
            component={fieldTemplate}
          />
        </div>
      );
    });
  };

  save = (data) => this.props.onConfirm(data.id);

  render() {
    const {cancelButtonText, confirmButtonText, handleSubmit, pristine, onCancel} = this.props;

    return (
      <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
        <CustomScrollbars
          scrollbarProps={{
            hideTracksWhenNotNeeded: true,
          }}
        >
          <div
            className="custom-scrollbars__scroll-content"
            ref={(list) => {
              this.props.getListRef(list);
            }}
          >
            {this.renderList()}
          </div>
        </CustomScrollbars>
        <div className="bc-select-dropdown__bottom">
          <button
            disabled={pristine}
            type="button"
            className="button button--bg-11 bc-select-dropdown__btn"
            onClick={onCancel}
          >
            {cancelButtonText}
          </button>
          <button disabled={pristine} type="submit" className="button button--bg-14 bc-select-dropdown__btn">
            {confirmButtonText}
          </button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  form: ownProps.formName,
});

export default flow([reduxForm(), connect(mapStateToProps)])(BCMailingListForm);
