import React from 'react';

import bem from 'client/services/bem';

import PrizeDiyCommonCouponFieldset from 'client/components/prizes/fieldsets/prize-diy-common-coupon-fieldset';
import PrizeDiyCouponFieldset from 'client/components/prizes/fieldsets/prize-diy-coupon-fieldset';
import PrizeDiyCouponImportsFieldset from 'client/components/prizes/fieldsets/prize-diy-coupon-imports-fieldset';
import {Operation} from 'client/models/operations/types';
import {COUPON_TYPES} from 'client/models/prizes/constants';
import {CouponType} from 'client/models/prizes/types';

import cssModule from './prize-diy-modal-coupon.module.scss';

const b = bem('prize-diy-modal-coupon', {cssModule});

type PrizeDiyModalCouponProps = {
  operation: Operation;
  couponType: CouponType;
  disabled: boolean;
};

const PrizeDiyModalCoupon: React.FC<PrizeDiyModalCouponProps> = (props) => {
  const {operation, couponType, disabled} = props;

  return (
    <div className={b()}>
      <div className={b('column-left')}>
        <PrizeDiyCouponFieldset disabled={disabled} />
      </div>
      <div className={b('column-right')}>
        {couponType === COUPON_TYPES.COMMON ? (
          <PrizeDiyCommonCouponFieldset disabled={disabled} />
        ) : (
          <PrizeDiyCouponImportsFieldset operation={operation} disabled={disabled} />
        )}
      </div>
    </div>
  );
};

export default PrizeDiyModalCoupon;
