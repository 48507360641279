import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import ClientTable from 'client/components/common/client-table';

import {TranslationJsx} from 'client/models/language/types';

import './animation-reporting-table.scss';

const b = bem('animation-reporting-table');

const AnimationReportingTable = (props) => {
  const {className, title, tableClassName, noDataHeight, ...tableProps} = props;
  const lang = useLanguage('ANIMATIONS.LISTS');

  const columns = tableProps.columns?.map((col) => ({
    ...col,
    valueClassName: b('table-cell-value', {title: col.title}),
  }));

  return (
    <div className={classNames(b(), className)}>
      <h3 className={b('title')}>{title}</h3>
      <ClientTable
        className={classNames(b('table'), tableClassName)}
        commonHeaderClassName={b('table-header')}
        commonCellClassName={b('table-cell')}
        {...tableProps}
        noDataText={lang.NO_DATA}
        columns={columns}
        noDataHeight={noDataHeight}
      />
    </div>
  );
};

AnimationReportingTable.propTypes = {
  className: PropTypes.string,
  tableClassName: PropTypes.string,
  title: TranslationJsx.isRequired,
  noDataHeight: PropTypes.number,
  ...ClientTable.propTypes,
};

AnimationReportingTable.defaultProps = {
  className: null,
  tableClassName: null,
  noDataHeight: 110,
};

export default AnimationReportingTable;
