import React from 'react';

import PropTypes from 'prop-types';

import DevicesDiyList from 'client/components/devices/lists/devices-diy-list';

/**
 *
 * @type {React.FC<DiyDevices.PropTypes>}
 */

// TODO: from jsx => tsx
const DiyDevices = ({operation, fetchOperation, fetchGame}) => {
  return <DevicesDiyList operation={operation} fetchOperation={fetchOperation} fetchGame={fetchGame} />;
};

DiyDevices.propTypes = {
  operation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    client_id: PropTypes.number.isRequired,
  }),
  fetchOperation: PropTypes.func.isRequired,
  fetchGame: PropTypes.func.isRequired,
};

export default DiyDevices;
