import {Game, PrizeMap} from 'client/models/prizes/types';

import {LotteryPrizeCustomizationValues} from './types';

type InitialParams = {
  game: Game;
  prize_maps: PrizeMap[];
};

export default (params: InitialParams): LotteryPrizeCustomizationValues => {
  const {game, prize_maps} = params;
  return {
    id: game.id,
    prize_maps,
    game_draws:
      game.game_draws?.map((draw) => ({
        id: draw.id,
        default_from: draw.default_from,
        default_to: draw.default_to,
        default_draw_time: draw.default_draw_time,
        loss_email: draw.loss_email,
        loss_sms: draw.loss_sms,
      })) || [],
  };
};
