import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import LotteryLossResultAnnouncement from 'client/components/lottery/fieldsets/lottery-loss-result-announcement';
import LotteryPeriodBlock from 'client/components/lottery/fieldsets/lottery-period-block';

import cssModule from './lottery-campaign-date-time-fieldset.module.scss';

const b = bem('lottery-campaign-date-time-fieldset', {cssModule});

type LotteryCampaignDateTimeFieldsetProps = {
  className?: string;
  name: string;
  disabled: boolean;
};

const LotteryCampaignDateTimeFieldset: React.FC<LotteryCampaignDateTimeFieldsetProps> = (props) => {
  const {className, name, disabled} = props;

  return (
    <div className={cn(b(), className)}>
      <LotteryPeriodBlock name={name} isReadOnly={disabled} />
      <LotteryLossResultAnnouncement name={name} isReadOnly={disabled} />
    </div>
  );
};

export default LotteryCampaignDateTimeFieldset;
