import React from 'react';

import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {updateParicipationField, updateParticipation} from 'client/ducks/participations/actions';

import AppButton from 'client/common/buttons';
import {useToast} from 'client/common/hooks/useToast';

import './client-hidden-column.scss';

const b = bem('client-hidden-column');

function ClientHiddenColumn(props) {
  const {isVisible, participation, disabled} = props;
  const dispatch = useDispatch();
  const {appendToastNotification} = useToast();
  const autotask = useSelector(selectAutotask);

  const isOpArchived = autotask?.operation?.status === 'finished';

  const onToggle = async () => {
    const nextValue = !isVisible;
    const response = await dispatch(updateParticipation(participation.id, {visible_by_client: nextValue}));
    if (response.error) {
      appendToastNotification({type: 'error', description: response?.payload?.response?.errors?.base?.[0]});
    } else {
      dispatch(updateParicipationField(participation?.id, 'visible_by_client', nextValue));
    }
  };

  return (
    <AppButton
      asWrap
      disabled={disabled || isOpArchived}
      iconName={isVisible ? 'eye-opened' : 'eye-closed'}
      className={b({
        closed: !isVisible,
        disabled: disabled || isOpArchived,
      })}
      onClick={onToggle}
    />
  );
}

ClientHiddenColumn.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  participation: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default ClientHiddenColumn;
