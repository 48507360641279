import React from 'react';

import PropTypes from 'prop-types';

import './loader.scss';

function LoadingSpinner({className, cssModifier, loading, children}) {
  const mainNode = typeof children !== 'string' ? children : <span>{children}</span>;

  return loading ? <div className={`loader ${className || cssModifier}`} /> : mainNode;
}

LoadingSpinner.defaultProps = {
  cssModifier: '',
  className: '',
  children: null,
  loading: false,
};

LoadingSpinner.propTypes = {
  cssModifier: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
};

export default LoadingSpinner;
