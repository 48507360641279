import {Reducer} from 'redux';

import {RegionAccess} from 'client/models/regions/types';

import types from './types';

type RegionsState = {
  region_accesses: RegionAccess[];
};

const initialState: RegionsState = {
  region_accesses: [],
};

const regionsReducer: Reducer<RegionsState> = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REGION_ACCESSES_SUCCESS:
      return {
        ...state,
        region_accesses: action.payload.region_accesses,
      };

    default:
      return state;
  }
};

export default regionsReducer;
