import React from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';

import {getClientPage} from 'client/services/helpers';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';

const VisualsCell = ({value, participation, disabled}) => {
  const {clientId} = useParams();
  const {client} = useSelector(selectAutotask);
  const currentClient = useSelector(selectCurrentClient);

  if (value > 0 && !disabled) {
    return (
      <Link
        to={`${getClientPage(client || currentClient)}/${clientId}/visuals?filters={"participation_id_eq":"${
          participation.id
        }"}`}
        className="link ellipsis-text"
      >
        {value}
      </Link>
    );
  }

  return value;
};

VisualsCell.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  participation: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

export default VisualsCell;
