import React, {useMemo} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useMount, useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {getEmailSenders, updateEmailTemplateParameters} from 'client/ducks/email-templates/actions';
import {selectAddedVariables, selectEmailSenders} from 'client/ducks/email-templates/selectors';
import {selectActiveTemplate} from 'client/ducks/email-templates/selectors';

import AppButton from 'client/common/buttons';
import SelectField from 'client/common/fields/select-field';
import TextField from 'client/common/fields/text-field';
import Modal from 'client/common/modals/modal';

import {getNewVariables} from 'client/components/diy-operation/dashboards/diy-op-email-templates/helpers';
import VariableTextField from 'client/components/email-template-editor/fields/variable-text-field';
import {EmailParams} from 'client/components/email-template-editor/types';
import {EmailTemplateKind} from 'client/models/email-templates/types';

import mapFormValues from './mapFormValues';
import validateValues from './validateValues';

import cssModule from './parameters-modal.module.scss';

const b = bem('parameters-modal', {cssModule});

export const ParametersModalFormName = 'EmailTemplateEditorParametersModalForm';

type ParametersModalProps = {
  onCancel: () => void;
  emailParams: EmailParams;
  setEmailParams: (params: EmailParams) => void;
  kind: EmailTemplateKind;
};

const ParametersModal: ReduxFormFC<ParametersModalProps, EmailParams> = (props) => {
  const {onCancel, emailParams, setEmailParams, handleSubmit, kind} = props;
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.MODALS.PARAMETERS_MODAL');
  const dispatch = useDispatch();
  const client = useSelector(selectCurrentClient);
  const emailSenders = useSelector(selectEmailSenders);
  const activeTemplate = useSelector(selectActiveTemplate);
  const addedVariables = useSelector(selectAddedVariables);
  const [isSaving, toggleIsSaving] = useToggle(false);

  const {invalid} = useReduxForm(ParametersModalFormName, {
    initialValues: emailParams,
    validate: (values) => validateValues(values, lang.ERRORS),
  });

  useMount(() => {
    dispatch(getEmailSenders(client.id));
  });

  const senderOptions = useMemo(() => {
    const result = emailSenders.map((i) => ({value: i.id, label: i.value}));

    const senderInList = !!emailParams.senderId && result.some((i) => i.value === emailParams.senderId);

    if (emailSenders.length && emailParams.senderId && !senderInList) {
      result.push({value: emailParams.senderId, label: activeTemplate?.sender_email || ''});
    }

    return result;
  }, [emailParams.senderId, emailSenders, activeTemplate?.sender_email]);

  const save = async (values: EmailParams) => {
    toggleIsSaving();
    const params = {
      ...values,
      senderEmail: senderOptions.find((i) => i.value === values.senderId)?.label || '',
    };
    setEmailParams(params);
    const data = {
      ...mapFormValues(params),
      email_template_variables: getNewVariables(addedVariables, kind, activeTemplate),
    };
    await dispatch(updateEmailTemplateParameters(activeTemplate?.id, data));
    toggleIsSaving();
    onCancel();
  };

  return (
    <Modal onClose={onCancel} title={lang.TITLE} dialogClassName={b()} bodyClassName={b('body')} show>
      <form onSubmit={handleSubmit(save)}>
        <div className={b('content')}>
          <div className={b('section')}>
            <p className={b('section-title')}>{lang.SUBJECT_AND_PREVIEW_SECTION_TITLE}</p>
            <VariableTextField name="subject" label={lang.SUBJECT_LABEL} />
            <VariableTextField name="previewText" label={lang.PREVIEW_TEXT_LABEL} />
          </div>
          <div className={b('section')}>
            <p className={b('section-title')}>{lang.FROM_SECTION_TITLE}</p>
            <TextField label={lang.NAME_LABEL} name="name" />
            <SelectField label={lang.EMAIL_LABEL} name="senderId" options={senderOptions} simpleValue />
          </div>
          <div className={b('section')}>
            <p className={b('section-title')}>{lang.ADDRESS_SECTION_TITLE}</p>
            <TextField label={lang.EMAIL_LABEL} name="replyTo" />
          </div>
          <AppButton
            label={lang.OK_BUTTON}
            className={b('button')}
            size="medium"
            disabled={invalid}
            loading={isSaving}
            submit
          />
        </div>
      </form>
    </Modal>
  );
};

export default reduxForm<ParametersModalProps, EmailParams>({
  form: ParametersModalFormName,
})(ParametersModal);
