import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';

import ClientTable from 'client/components/common/client-table';

import {Store} from 'client/models/stores/types';

import cssModule from './account-stores-table.module.scss';

const b = bem('account-stores-table', {cssModule});

type AccountStoresTableProps = {
  data: Store[];
  loading: boolean;
  setActiveStore: (store: Store) => void;
};

const AccountStoresTable: React.FC<AccountStoresTableProps> = (props) => {
  const {data, loading, setActiveStore} = props;
  const lang = useLanguage('ACCOUNT_PARAMETERS.ACCOUNT_STORES.ACCOUNT_STORES_TABLE');

  return (
    <ClientTable
      className={b()}
      data={data}
      loading={loading}
      noDataText={lang.NO_DATA}
      commonHeaderClassNames={{th: b('header-cell')}}
      columns={[
        {
          name: 'name',
          path: 'name',
          width: '700px',
          label: lang.NAME,
        },
        {
          name: 'city',
          path: 'city_name',
          label: lang.CITY,
        },
        {
          name: 'zip',
          path: 'city_zip',
          label: lang.ZIP,
        },
        {
          name: 'phone',
          path: 'phone',
          label: lang.PHONE,
        },
        {
          name: 'view',
          width: '100px',
          render: ({item}) => (
            <AppButton label={lang.VIEW} color="devices" onClick={() => setActiveStore(item)} transparent />
          ),
        },
      ]}
    />
  );
};

export default AccountStoresTable;
