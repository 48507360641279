import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getAvailableDomains(queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.AVAILABLE_DOMAINS}`,
      method: 'GET',
      types: [
        types.GET_AVAILABLE_DOMAINS_REQUEST,
        types.GET_AVAILABLE_DOMAINS_SUCCESS,
        types.GET_AVAILABLE_DOMAINS_ERROR,
      ],
      queryParams,
    });
  };
}
