import React, {useEffect, useMemo} from 'react';

import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';

import {patch, post} from 'client/services/fetch';
import {useReduxForm, useLanguage} from 'client/services/hooks';

import {ACCESS_LEVEL_TYPES} from 'client/ducks/client-users/constants';

import {API_METHODS} from 'client/common/config';
import {RadioGroupField} from 'client/common/fields';
import CheckListField from 'client/common/fields/check-list-field';
import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

const NewUserAccessRegionsModal = (props) => {
  const {show, editingUser, clientUser, user, regions, totalSteps, onBack, onClose, adminId, onCreate, handleSubmit} =
    props;
  const lang = useLanguage('AGENCY');
  const {initialize, formValues} = useReduxForm('NewUserAccessRegionsForm');

  const isLocal = user.access_level === ACCESS_LEVEL_TYPES.LOCAL;

  useEffect(() => {
    if (editingUser) {
      let checked = [];
      user.region_accesses?.forEach((region_access) => {
        const found = find(regions, {id: region_access.region_id});
        if (found) {
          checked.push(found.id?.toString());
        }
      });
      if (isLocal) {
        checked = checked.slice(0, 1);
      }
      initialize({
        regions: checked,
      });
    }
  }, [user, regions, initialize, editingUser, isLocal]);

  const save = ({regions: checkedRegions}) => {
    const region_accesses = checkedRegions.map((i) => ({region_id: i}));
    const userExists = !isUndefined(clientUser.id);
    const regionsNewUserData = {
      client_user: {
        ...user,
        memberships: [
          {
            ...user.memberships[0],
            region_accesses,
          },
        ],
        invited_by_id: adminId,
      },
    };
    if (editingUser) {
      onCreate({
        showEditCompanyAccessModal: 0,
        showEditAgencyAccessModal: 0,
        showEditUserModal: true,
        editingUser: {
          ...user,
          region_accesses: user.region_accesses
            .filter((i) => i.id && !checkedRegions.includes(i.id))
            .map(({id}) => ({id, _destroy: true}))
            .concat(region_accesses),
        },
      });
      return;
    }
    if (userExists) {
      patch(`${API_METHODS.CLIENT_USERS}/${clientUser.id}`, regionsNewUserData).then(onCreate);
    } else {
      post(API_METHODS.CLIENT_USERS, regionsNewUserData).then(onCreate);
    }
  };

  const {first_name: firstName, last_name: lastName, title: civility, email} = user;

  const regionOptions = useMemo(() => {
    return regions?.map((r) => ({value: r.id?.toString(), label: r.name}));
  }, [regions]);

  const disabledSubmit = isLocal && !formValues?.regions?.length;

  return (
    <Modal show={show} title={editingUser ? lang.EDIT_USER_MODAL_TITLE : lang.NEW_USER_MODAL_TITLE} onClose={onClose}>
      {!editingUser && (
        <div className="pull-right modal-window__step">
          {lang.NEW_USER_MODAL_STEP} {totalSteps} {lang.NEW_USER_MODAL_STEP_OF} {totalSteps}
        </div>
      )}
      <div className="clearfix" />
      <div className="modal-window__subtitle modal-window__email">{email}</div>
      <div className="modal-window__subtitle modal-window__name">
        {lang.CIVILITY[civility]} {firstName} {lastName}
      </div>
      <form noValidate="noValidate" onSubmit={handleSubmit(save)}>
        <div className="modal-window__descr main-text main-text--bold modal-window--margin-bottom-5">
          {lang.NEW_USER_MODAL_REGION_ACCCESS}
        </div>
        <div className="content-group theme-color-3 min-height-200">
          <CustomScrollbars
            scrollbarProps={{
              autoHeightMax: 482,
            }}
          >
            <div className="list list--view-2">
              {isLocal ? (
                <RadioGroupField
                  color="users"
                  name="regions[0]"
                  defaultValue={regionOptions[0]?.value}
                  options={regionOptions}
                />
              ) : (
                <CheckListField options={regionOptions} singleCheck={isLocal} name="regions" color="users" />
              )}
            </div>
          </CustomScrollbars>
        </div>
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onBack}>
            {lang.NEW_USER_MODAL_BACK_BUTTON}
          </button>
          <button className="button button--bg-3 modal-window__footer-btn" type="submit" disabled={disabledSubmit}>
            {editingUser ? lang.NEW_USER_MODAL_NEXT_BUTTON : lang.NEW_USER_MODAL_CREATE_BUTTON}
          </button>
        </div>
      </form>
    </Modal>
  );
};

NewUserAccessRegionsModal.propTypes = {
  user: PropTypes.object,
  adminId: PropTypes.number,
  clientUser: PropTypes.object,
  totalSteps: PropTypes.number,
  show: PropTypes.bool,
  editingUser: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  onCreate: PropTypes.func,
  regions: PropTypes.array,
};

NewUserAccessRegionsModal.defaultProps = {
  show: false,
  editingUser: false,
  totalSteps: 3,
  onBack: () => {},
  onClose: () => {},
  onCreate: () => {},
  clientUser: {},
};

const NewUserAccessRegionsForm = reduxForm({
  form: 'NewUserAccessRegionsForm',
})(NewUserAccessRegionsModal);

export default NewUserAccessRegionsForm;
