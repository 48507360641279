import React, {Component} from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import CustomLink from 'client/components/common/custom-link';

function ClientCell({data, rowIndex, field}) {
  const linkData = {
    clientType: data[rowIndex].type,
    clientId: data[rowIndex].id,
  };

  return (
    <Cell>
      <CustomLink to={CustomLink.createClientLink(linkData)} className="link display-block ellipsis-text">
        {data[rowIndex][field]}
      </CustomLink>
    </Cell>
  );
}

ClientCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

function DateCell({data, rowIndex}) {
  return <Cell>{data[rowIndex].createdAt}</Cell>;
}

DateCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

class HomeClientsTable extends Component {
  static propTypes = {
    data: PropTypes.array,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.HOME.CLIENTS_TABLE;
  }

  render() {
    const {data} = this.props;

    return (
      <div className="fixed-table fixed-table--home-clients-card fixed-table--expand-1">
        <Table rowsCount={data.length} headerHeight={40} rowHeight={30} width={370} height={342} overflowX="hidden">
          <Column
            header={<Cell>{this.LANGUAGE.CLIENT_COLUMN}</Cell>}
            cell={<ClientCell data={data} field="name" />}
            width={280}
          />
          <Column
            header={<Cell>{this.LANGUAGE.DATE_COLUMN}</Cell>}
            cell={<DateCell data={data} field="createdAt" />}
            width={90}
          />
        </Table>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(HomeClientsTable);
