import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {dateToString, getAndDownloadFile} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';

import './animation-photos-modal-header.scss';

const b = bem('animation-photos-modal-header');

const AnimationPhotosModalHeader = (props) => {
  const {onBack, photo} = props;
  const lang = useLanguage('ANIMATIONS.MODALS.ANIMATION_PHOTOS_MODAL.ANIMATION_PHOTOS_MODAL_HEADER');

  return (
    <div className={b()}>
      {photo ? (
        <>
          <AppButton
            className={b('back-button')}
            classNameIcon={b('back-icon')}
            onClick={onBack}
            label={lang.BACK_TO_PHOTOS}
            iconName="arrow-left"
            asWrap={true}
          />
          <span className={b('name')}>{photo?.animation?.place?.name}</span>
          <span className={b('date')}>{dateToString(photo.created_at)}</span>
          <AppButton
            iconName="downloads"
            classNameIcon={b('download-icon')}
            iconConfig={{width: 16, height: 16}}
            onClick={() => getAndDownloadFile(photo.url)}
            asWrap={true}
          />
        </>
      ) : (
        <span className={b('title')}>{lang.PHOTOS}</span>
      )}
    </div>
  );
};

AnimationPhotosModalHeader.propTypes = {
  onBack: PropTypes.func.isRequired,
  photo: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    animation: PropTypes.shape({
      place: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }),
};

AnimationPhotosModalHeader.defaultProps = {
  photo: null,
};

export default AnimationPhotosModalHeader;
