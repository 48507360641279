import React from 'react';

import isEqual from 'lodash/isEqual';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';
import useReduxFetch from 'client/services/hooks/use-redux-fetch';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {createDataTabAccess, updateDataTabAccess} from 'client/ducks/client-account/actions';

import AppButton from 'client/common/buttons';
import {CheckboxField} from 'client/common/fields';
import {useToast} from 'client/common/hooks/useToast';
import Modal from 'client/common/modals/modal';

import {Membership} from 'client/models/memberships/types';

import initialValues from './initial';

import cssModule from './account-users-export-access-modal.module.scss';

const b = bem('account-users-export-access-modal', {cssModule});

type AccountUsersExportAccessModalProps = {
  onClose: () => void;
  onFetch: () => void;
  membership: Membership;
};

export type ExportAccessModalFormValues = {
  leads: boolean;
  participations: boolean;
  winners: boolean;
};

const formName = 'AccountUsersExportAccessModalForm';

const AccountUsersExportAccessModal: ReduxFormFC<AccountUsersExportAccessModalProps, ExportAccessModalFormValues> = (
  props,
) => {
  const {onClose, membership, handleSubmit, onFetch} = props;

  const {appendToastNotification} = useToast();

  const {formValues} = useReduxForm(formName, {
    initialValues: initialValues(membership),
  });

  const {loading, fetch} = useReduxFetch({
    action: membership?.data_tab_access?.id ? updateDataTabAccess : createDataTabAccess,
    fetchOnMount: false,
  });

  const lang = useLanguage('CLIENT_ACCOUNT_PARAMETERS.ACCOUNT_USERS.MODALS.EXPORT_ACCESS');

  const onSubmit = handleSubmit(async (values) => {
    try {
      await fetch({
        ...values,
        id: membership.data_tab_access?.id,
        membership_id: membership.id,
      });
      onClose();
      onFetch();
      appendToastNotification({
        type: 'success',
        title: lang.SAVE_SUCCESS,
      });
    } catch (e) {
      console.error(e);
      appendToastNotification({
        type: 'error',
        title: lang.SAVE_ERROR,
      });
    }
  });

  const touched = !isEqual(initialValues(membership), formValues);

  return (
    <Modal show title={lang.TITLE} onClose={onClose} dialogClassName={b()}>
      <div className={b('body')}>
        {membership.client_user?.full_name && (
          <p className={b('description')}>{`${membership.client_user?.full_name} ${lang.DESCRIPTION}`}</p>
        )}
        <form className={b('form')} onSubmit={onSubmit}>
          <CheckboxField name="leads" label={lang.LEADS_LABEL} color="primary" />
          <CheckboxField name="participations" label={lang.PARTICIPATIONS_LABEL} color="primary" />
          <CheckboxField name="winners" label={lang.WINNERS_LABEL} color="primary" />
          <AppButton
            submit
            loading={loading}
            label={lang.SAVE_BUTTON}
            className={b('submit-button')}
            disabled={!touched}
          />
        </form>
      </div>
    </Modal>
  );
};

const Form = reduxForm<AccountUsersExportAccessModalProps, ExportAccessModalFormValues>({
  form: formName,
})(AccountUsersExportAccessModal);

Form.displayName = 'AccountUsersExportAccessModalForm';

export default Form;
