import React, {useCallback, useMemo, useState} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons';

import './translations-list.scss';

const b = bem('translations-list');

const TranslationsList = (props) => {
  const {translations = [], show, onTranslationCreate, onTranslationUpdate} = props;
  const [expandedChildren, setExpandedChildren] = useState({});
  const lang = useLanguage('TRANSLATIONS');
  const parentId = translations?.[0]?.parent_id || null;

  const sortedTranslations = useMemo(() => {
    return translations.sort((translationA, translationB) => {
      if (translationA.hasChildren === translationB.hasChildren) {
        if (translationA.key > translationB.key) {
          return 1;
        }
        if (translationA.key < translationB.key) {
          return -1;
        }
        return 0;
      }
      return translationB.hasChildren - translationA.hasChildren;
    });
  }, [translations]);

  const toggleExpandedChildren = useCallback((id, state = null) => {
    setExpandedChildren((prevState) => {
      return {
        ...prevState,
        [id]: state === null ? !prevState[id] : state,
      };
    });
  }, []);

  const handleTranslationCreate = useCallback(
    (id) => {
      onTranslationCreate(id);
      toggleExpandedChildren(id, true);
    },
    [onTranslationCreate, toggleExpandedChildren],
  );

  if (!translations?.length) {
    return null;
  }

  return (
    <>
      <ul className={b({expanded: show, root: !parentId})}>
        {sortedTranslations.map(({id, key, hasChildren, children, translation_items}, index) => (
          <li key={id} className={b('list-item')}>
            <div className={b('translation')}>
              <div className={b('toggle-expand-wrap')}>
                {!!hasChildren && (
                  <AppButton
                    className={b('toggle-expand-btn')}
                    onClick={() => toggleExpandedChildren(id)}
                    label={expandedChildren[id] ? '-' : '+'}
                    title={expandedChildren[id] ? lang.COLLAPSE : lang.EXPAND}
                    asWrap={true}
                  />
                )}
              </div>
              <div className={b('action-buttons')}>
                <AppButton
                  className={b('edit-translation-btn')}
                  onClick={() => onTranslationUpdate(sortedTranslations[index])}
                  label={key}
                  asWrap={true}
                />
                {!translation_items?.length && (
                  <AppButton
                    className={b('create-translation-btn')}
                    onClick={() => handleTranslationCreate(id)}
                    label="+"
                    asWrap={true}
                  />
                )}
              </div>
            </div>
            {!!hasChildren && !!expandedChildren[id] && (
              <div className={cn(b('children'))}>
                <TranslationsList {...props} translations={children} show={!!expandedChildren[id]} />
              </div>
            )}
          </li>
        ))}
        {!parentId && (
          <li>
            <AppButton
              className={b('create-root-translation-btn')}
              onClick={() => handleTranslationCreate(parentId)}
              label={lang.ADD_TRANSLATION}
              asWrap={true}
            />
          </li>
        )}
      </ul>
    </>
  );
};

TranslationsList.propTypes = {
  translations: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  onTranslationCreate: PropTypes.func.isRequired,
  onTranslationUpdate: PropTypes.func.isRequired,
};

export default TranslationsList;
