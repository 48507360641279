import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import {selectIsAdmin} from 'client/ducks/user/selectors';

import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import SearchField from 'client/components/common/search-field';

import {TASK_TYPES} from 'client/models/operations/constants';

import BCResultsToolbar from './components/bc-results-toolbar';
import AmountColumn from './components/columns/amount-column';
import ContactsColumn from './components/columns/contacts-column';
import StatusColumn from './components/columns/status-column';
import UnsubscribeColumn from './components/columns/unsubscribe-column';

import cssModule from './bc-results-data-grid.module.scss';

const b = bem('bc-results-data-grid', {cssModule});

class BCResultsDataGrid extends Component {
  static propTypes = {
    taskType: PropTypes.string.isRequired,
    taskState: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    languageState: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    meta: PropTypes.object.isRequired,
    queryParams: PropTypes.object.isRequired,
    onShowFilterClick: PropTypes.func.isRequired,
    onFilterDeleteClick: PropTypes.func.isRequired,
    onClearFiltersClick: PropTypes.func.isRequired,
    onClicksCountClick: PropTypes.func.isRequired,
    onVarsCountClick: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onSearchClear: PropTypes.func.isRequired,
    defaultSearchValue: PropTypes.string,
  };

  constructor(props) {
    super(props);

    const langPayload = props.languageState.payload;

    this.LANGUAGE = {
      RESULT_TABLE: langPayload.BROADCASTING_TASK.RESULT.TABLE,
      TABLE: langPayload.TABLE,
      FILTERS_MODAL: langPayload.BROADCASTING_TASK.RESULT_FILTERS_MODAL,
    };
  }

  getColumns() {
    const {isAdmin, taskType} = this.props;

    return [
      {
        name: 'lead_first_name',
        path: 'lead_first_name',
        sortable: true,
        label: this.LANGUAGE.RESULT_TABLE.FIRST_NAME_COLUMN,
        width: 120,
      },
      {
        name: 'lead_last_name',
        path: 'lead_last_name',
        sortable: true,
        label: this.LANGUAGE.RESULT_TABLE.LAST_NAME_COLUMN,
        width: 120,
      },
      {
        name: 'lead_gender',
        path: 'lead_gender',
        label: this.LANGUAGE.RESULT_TABLE.GENDER_COLUMN,
        sortable: true,
        width: 85,
      },
      {
        name: 'lead_birth_date',
        path: 'lead_birth_date',
        sortable: true,
        label: this.LANGUAGE.RESULT_TABLE.AGE_COLUMN,
        width: 65,
      },
      {
        name: 'contacts',
        render: this.renderContacts,
        label: this.LANGUAGE.RESULT_TABLE.CONTACTS_COLUMN,
        sortable: true,
        width: 95,
      },
      {
        name: 'sending',
        label: this.LANGUAGE.RESULT_TABLE.SENDING_COLUMN,
        subcolumns: [
          {
            name: 'queued_event',
            path: 'queued_event',
            width: taskType === TASK_TYPES.EMAIL ? 128 : 186,
            sortable: true,
            render: this.renderStatus,
            label:
              taskType === TASK_TYPES.EMAIL
                ? this.LANGUAGE.RESULT_TABLE.SENT_TO_COLUMN
                : this.LANGUAGE.RESULT_TABLE.SENT_TO_TWILIO_COLUMN,

            show: isAdmin,
          },
          {
            name: 'sent_event',
            path: 'sent_event',
            width: taskType === TASK_TYPES.EMAIL ? 128 : 186,
            sortable: true,
            render: this.renderStatus,
            label:
              taskType === TASK_TYPES.EMAIL
                ? this.LANGUAGE.RESULT_TABLE.SENT_BY_COLUMN
                : this.LANGUAGE.RESULT_TABLE.SENT_BY_TWILIO_COLUMN,

            show: isAdmin,
          },
          {
            name: 'open_event',
            path: 'open_event',
            width: 128,
            sortable: true,
            render: this.renderStatus,
            label: this.LANGUAGE.RESULT_TABLE.OPEN_COLUMN,
            show: taskType === TASK_TYPES.EMAIL,
          },
          {
            name: 'clicks_count',
            path: 'clicks_count',
            width: 116,
            render: this.renderClicks,
            label: this.LANGUAGE.RESULT_TABLE.CLICKS_COLUMN,
            sortable: true,
            show: taskType === TASK_TYPES.EMAIL,
          },
          {
            name: 'unsubscribed',
            path: 'unsubscribed',
            width: 118,
            render: this.renderUnsubscribe,
            label: this.LANGUAGE.RESULT_TABLE.UNSUBSCRIBE_COLUMN,
            sortable: true,
            show: taskType === TASK_TYPES.EMAIL,
          },
          {
            name: 'delivered_event',
            path: 'delivered_event',
            width: 187,
            sortable: true,
            render: this.renderStatus,
            label: this.LANGUAGE.RESULT_TABLE.DELIVERED_COLUMN,
            show: taskType === TASK_TYPES.SMS,
          },
          {
            name: 'variables_count',
            width: taskType === TASK_TYPES.EMAIL ? 128 : 174,
            render: this.renderVars,
            sortable: true,
            label: this.LANGUAGE.RESULT_TABLE.VARIABLES_COLUMN,
          },
        ],
      },
    ];
  }

  renderSearchField = () => {
    return (
      <SearchField
        onClearFiltersClick={this.props.onClearFiltersClick}
        onFilterDeleteClick={this.props.onFilterDeleteClick}
        placeholder={this.LANGUAGE.TABLE.SEARCH_PLACEHOLDER}
        defaultSearch={this.props.defaultSearchValue}
        onSearch={this.props.onSearchChange}
        onClear={this.props.onSearchClear}
      />
    );
  };

  renderToolbar = () => {
    return (
      <BCResultsToolbar
        queryParams={this.props.queryParams}
        resultsNum={this.props.meta.total_count}
        language={this.LANGUAGE}
        searchField={this.renderSearchField()}
        {...this.props}
      />
    );
  };

  renderContacts = ({item}) => <ContactsColumn {...item} />;
  renderUnsubscribe = ({value}) => <UnsubscribeColumn unsubscribe={value} />;
  renderStatus = ({value}) => <StatusColumn event={value} />;
  renderVars = ({value, item}) => {
    return <AmountColumn amount={value} onClick={() => this.props.onVarsCountClick(item.variables)} />;
  };

  renderClicks = ({value, item}) => {
    return <AmountColumn amount={value} onClick={() => this.props.onClicksCountClick(item.clicksStatistic)} />;
  };

  render() {
    const {meta, onPageChange, data, onSortChange, queryParams} = this.props;

    return (
      <div>
        <div className={b('toolbar-wrapper')}>
          {this.renderToolbar()}
          <PerPageDropdown
            value={queryParams.perPage || '5'}
            onChange={(pages) => onPageChange({page: 1, perPage: pages})}
            simpleValue
          />
        </div>
        <ClientTable
          data={data}
          onSortChange={onSortChange}
          sortField={queryParams.sort?.name}
          sortOrder={queryParams.sort?.order}
          columns={this.getColumns()}
          className={b()}
        />
        <PaginationBar
          data={data}
          currentPage={meta.current_page}
          totalPages={meta.total_pages}
          perPage={queryParams.perPage}
          totalItems={meta.total_count}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  isAdmin: selectIsAdmin(state),
});

export default connect(mapStateToProps)(BCResultsDataGrid);
