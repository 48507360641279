import React, {Component} from 'react';

type ClientTableErrorBoundaryProps = {
  children: React.ReactNode | React.ReactNode[];
  message?: string;
  fallback?: React.ReactNode;
};

/**
 * ClientTableErrorBoundary - for displaying empty value, if this one is object and it's not a jsx element.
 * jsx element is possible, when we provide Translation jsx object to value.
 */
class ClientTableErrorBoundary extends Component<ClientTableErrorBoundaryProps, {hasError: boolean}> {
  constructor(props: ClientTableErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  render() {
    const {hasError} = this.state;
    const {children, fallback = null} = this.props;

    if (hasError) {
      return fallback;
    }

    return children;
  }
}

export default ClientTableErrorBoundary;
