import React, {Component} from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import CodeCell from '../cells/code-cell';
import DateCell from '../cells/date-cell';
import ImportExportToolsCell from '../cells/import-export-tools-cell';
import NoOfLeadsCell from '../cells/no-of-leads-cell';

class LeadsCardDataGrid extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    languageState: PropTypes.object.isRequired,
    deleteReport: PropTypes.func.isRequired,
    getReports: PropTypes.func.isRequired,
    hideHeader: PropTypes.bool,
  };

  static defaultProps = {
    hideHeader: false,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AGENCY.LEADS_CARD;
  }

  render() {
    const {data, deleteReport, getReports, hideHeader} = this.props;

    const hideHeaderClass = hideHeader ? 'fixed-table_hide-header' : '';

    return (
      <div className={`fixed-table fixed-table--leads-card theme-color-2 ${hideHeaderClass}`}>
        <div className="fixed-table__title">
          <div className="main-text main-text--bold">{this.LANGUAGE.IMPORT_EXPORT_HISTORY_LABEL}</div>
        </div>
        <Table
          rowsCount={data.length}
          headerHeight={hideHeader ? 0 : 40}
          rowHeight={34}
          width={340}
          maxHeight={hideHeader ? 143 : 267}
          {...this.props}
        >
          <Column header={<Cell>{this.LANGUAGE.CODE_COLUMN}</Cell>} cell={<CodeCell data={data} />} width={60} />
          <Column
            header={<Cell>{this.LANGUAGE.END_DATE_COLUMN}</Cell>}
            cell={<DateCell data={data} field="updatedDate" />}
            width={80}
          />
          <Column
            header={<Cell>{this.LANGUAGE.NO_OF_LEADS_COLUMN}</Cell>}
            cell={<NoOfLeadsCell data={data} field="total" />}
            width={60}
          />
          <Column
            cell={<ImportExportToolsCell data={data} deleteReport={deleteReport} getReports={getReports} />}
            width={120}
            flexGrow={1}
          />
        </Table>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(LeadsCardDataGrid);
