import PropTypes from 'prop-types';

import {useLanguage} from 'client/services/hooks';

import {TranslationJsx} from 'client/models/language/types';

const EmptyCell = ({text, isDate}) => {
  const lang = useLanguage('PARTICIPANTS_LIST');
  return isDate ? lang.NO_DATE : text;
};

EmptyCell.propTypes = {
  text: TranslationJsx,
  isDate: PropTypes.bool,
};
EmptyCell.defaultProps = {
  text: 'N/A',
  isDate: false,
};

export default EmptyCell;
