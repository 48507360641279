import React, {useEffect, useMemo} from 'react';

import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, matchPath, useParams} from 'react-router-dom';

import bem from 'client/services/bem';
import useTaskWrapper from 'client/services/hooks/use-task-wrapper';

import {selectClientParams} from 'client/ducks/operations/selectors';

import TaskHeader from 'client/common/header/task-header';
import {set as setMetaAction} from 'client/common/meta/meta.actions';
import Offscreen from 'client/common/offscreen';

import {TaskWrapperTabsType} from './type';

import CustomLink from '../common/custom-link';
import LoadingSpinner from '../common/loading-spinner';

import './task-wrapper.scss';

const b = bem('task-wrapper');

const TaskWrapper = React.memo(({tabs, loading, task}) => {
  const params = useParams();
  const history = useHistory();
  const clientParams = useSelector(selectClientParams);
  const dispatch = useDispatch();
  const {showHeader} = useTaskWrapper();

  const handleBackClick = () => {
    const link = CustomLink.createClientOperationsLink({
      clientId: params.clientId,
    });
    history.push(link + (clientParams?.qs || ''));
  };

  const activeTab = useMemo(
    () => tabs.find((tab) => matchPath(history.location.pathname, tab.path)),
    [history.location.pathname, tabs],
  );

  useEffect(() => {
    dispatch(setMetaAction({title: activeTab?.label}));
  }, [dispatch, activeTab?.label]);

  return (
    <div className={b()}>
      {loading && <LoadingSpinner loading={loading} className={b('loader')} />}
      <Offscreen hidden={loading}>
        <>
          {showHeader && <TaskHeader handleBackClick={handleBackClick} task={task} tabs={tabs} />}
          <div className={b('content')}>{activeTab?.content}</div>
        </>
      </Offscreen>
    </div>
  );
});

TaskWrapper.propTypes = {
  loading: PropTypes.bool,
  operation: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  task: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  tabs: TaskWrapperTabsType.isRequired,
};

TaskWrapper.defaultProps = {
  loading: false,
  operation: null,
  task: null,
};

export default TaskWrapper;
