import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Field from '../field';

import './dropdown-field.scss';

const DropdownField = (props) => {
  const {lang, cssModifier, inline, readOnly, ...rest} = props;

  return (
    <Field
      cssModifier={cn('dropdown-field', cssModifier, {
        'dropdown-field--inline': inline,
        'dropdown-field--read-only': readOnly,
      })}
      searchable={false}
      noResultsText={lang.DROPDOWN_NO_DATA}
      simpleValue
      {...rest}
    />
  );
};

DropdownField.propTypes = {
  ...Field.propTypes,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  cssModifier: PropTypes.string,
  inline: PropTypes.bool,
};

DropdownField.defaultProps = {
  ...Field.defaultProps,
  type: 'select',
  readOnly: false,
  cssModifier: null,
  inline: false,
};

export default connect((state) => ({
  lang: state.languageState.payload.COMMON,
}))(DropdownField);
