import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import RadioButtonGroup from 'client/common/button-group/radio-button-group';
import Icon from 'client/common/icon';

import {SCREEN_FORMATS} from 'client/components/diy-operation/modals/diy-customization-modal/constants';

import './language-device-control.scss';

const b = bem('language-device-control');

export const DEFAULT_DEVICE_OPTIONS = [
  {value: SCREEN_FORMATS.phone, label: <Icon name="phones" width="24" height="24" />},
  {value: SCREEN_FORMATS.desktop, label: <Icon name="monitor" width="24" height="24" />},
  {value: SCREEN_FORMATS.kiosk_vertical, label: <Icon name="kiosk-vertical" width="24" height="24" />},
  {value: SCREEN_FORMATS.kiosk_horizontal, label: <Icon name="kiosk-horizontal" width="24" height="24" />},
];

const LanguageDeviceControl = (props) => {
  const {
    children,
    showLabel,
    language,
    device,
    languages,
    devices,
    prefix = '',
    onChangeLanguage,
    onChangeDevice,
    className,
    langControlClassName,
    deviceControlClassName,
    disabled,
  } = props;

  const lang = useLanguage('DIY_OPERATION.CONTROLS.DIY_OP_LANG_DEVICE_CONTROL');
  const isLangControlShown = languages?.length > 1 || devices?.length > 1;

  return (
    <>
      {isLangControlShown && (
        <div className={cn(b(), className)}>
          {languages?.length > 1 && (
            <RadioButtonGroup
              name={`${prefix}-language`}
              legend={showLabel ? lang.LANGUAGE_SELECTOR_LEGEND : ''}
              value={language}
              radioButtons={languages}
              className={langControlClassName}
              controlClassName={b('control')}
              labelClassName={b('label')}
              onChange={(event) => onChangeLanguage?.(event.target.value)}
              disabled={disabled}
            />
          )}
          {devices?.length > 1 && (
            <RadioButtonGroup
              name={`${prefix}-device`}
              legend={showLabel ? lang.DEVICE_SELECTOR_LEGEND : ''}
              value={device}
              radioButtons={devices}
              className={deviceControlClassName}
              controlClassName={b('control')}
              labelClassName={b('label', ['device'])}
              onChange={(event) => onChangeDevice?.(event.target.value)}
              disabled={disabled}
            />
          )}
        </div>
      )}
      {children && children(language, device)}
    </>
  );
};

LanguageDeviceControl.propTypes = {
  showLabel: PropTypes.bool,
  language: PropTypes.string,
  device: PropTypes.string,
  languages: PropTypes.array,
  devices: PropTypes.array,
  children: PropTypes.node,
  prefix: PropTypes.string.isRequired, // need to correctly identify and manage value changes
  onChangeLanguage: PropTypes.func,
  onChangeDevice: PropTypes.func,
  className: PropTypes.string,
  langControlClassName: PropTypes.string,
  deviceControlClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

LanguageDeviceControl.defaultProps = {
  showLabel: true,
  language: 'en',
  device: 'wl',
  children: null,
  className: '',
  languages: null,
  devices: null,
  onChangeDevice: null,
  onChangeLanguage: null,
  langControlClassName: '',
  deviceControlClassName: '',
  disabled: false,
};

export default LanguageDeviceControl;
