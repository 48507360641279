import React from 'react';

import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

import {DonutChartTitle, DonutChartDoughnut} from './../index';

const DonutChartDoughnutEmpty = (props) => {
  const {title, className, width, height} = props;

  return (
    <div className={className}>
      <DonutChartTitle title={title} />
      <DonutChartDoughnut
        showLegend={false}
        width={width}
        height={height}
        data={{
          datasets: [
            {
              data: [1, 1, 1],
              backgroundColor: '#DCE1EA',
              borderWidth: 2,
            },
          ],
        }}
        options={{
          cutout: '75%',
          animation: {duration: 0},
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {display: false},
            tooltip: {
              enabled: false,
            },
          },
        }}
      />
    </div>
  );
};

DonutChartDoughnutEmpty.propTypes = {
  title: TranslationJsx,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

DonutChartDoughnutEmpty.defaultProps = {
  title: '',
  className: '',
  width: 108,
  height: 108,
};

export default DonutChartDoughnutEmpty;
