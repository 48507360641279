import React from 'react';

import {Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';

import ParticipantsCommentPopover from '../../../popovers/participants-comments-popover';
import ParticipantsSummaryPopover from '../../../popovers/participants-summary-popover';

import './export-history-tools-cell.scss';

function ExportHistoryToolsCell(props) {
  const {data, rowIndex, isDashboard, refresh} = props;
  const exportReport = data[rowIndex];
  const {comment} = exportReport;

  return (
    <Cell>
      <div className={`export-history-tools-cell__container${isDashboard ? '-dashboard' : ''}`}>
        <ParticipantsSummaryPopover exportReport={exportReport} refresh={refresh}>
          <div className="leads-import__header-icon">
            <Icon name="summary" />
          </div>
        </ParticipantsSummaryPopover>
        {comment && (
          <ParticipantsCommentPopover comment={comment}>
            <div className="participants-export__header-icon">
              <Icon name="message" />
            </div>
          </ParticipantsCommentPopover>
        )}
      </div>
    </Cell>
  );
}

ExportHistoryToolsCell.defaultProps = {
  data: [],
};

ExportHistoryToolsCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  isDashboard: PropTypes.bool,
  refresh: PropTypes.func.isRequired,
};

export default ExportHistoryToolsCell;
