export const validate = (values, props) => {
  const {
    name,
    code,
    level,
    opt_in_text_translations,
    opt_out_text_translations,
    opt_in_type,
    mandatory_order,
    languages,
  } = values;
  const {lang, takenMandatoryOrders} = props;
  const errors = {
    opt_in_text_translations: {},
    opt_out_text_translations: {},
  };

  if (!name || !name.trim()) {
    errors.name = lang.TITLE_IS_REQUIRED_ERROR;
  }
  if (!code) {
    errors.code = lang.CODE_IS_REQUIRED_ERROR;
  }
  if (!level) {
    errors.level = lang.LEVEL_IS_REQUIRED_ERROR;
  }
  if (!opt_in_type) {
    errors.opt_in_type = lang.OPT_IN_TYPE_REQUIRED_ERROR;
  }
  if (!opt_in_text_translations?.en?.trim() && !opt_in_text_translations?.fr?.trim()) {
    languages?.forEach((language) => {
      errors.opt_in_text_translations[language.value] = lang.ENGLISH_OR_FRENCH_REQUIRED_ERROR;
    });
  }
  if (!opt_out_text_translations?.en?.trim() && !opt_out_text_translations?.fr?.trim()) {
    languages?.forEach((language) => {
      errors.opt_out_text_translations[language.value] = lang.ENGLISH_OR_FRENCH_REQUIRED_ERROR;
    });
  }
  if (mandatory_order && mandatory_order < 1) {
    errors.mandatory_order = lang.INTEGER;
  }
  if (mandatory_order && takenMandatoryOrders.includes(Number(mandatory_order))) {
    errors.mandatory_order = lang.POSITION_TAKEN_ERROR;
  }

  return errors;
};
