export default {
  GET_OPT_INS_REQUEST: '@@opt-ins/GET_OPT_INS_REQUEST',
  GET_OPT_INS_SUCCESS: '@@opt-ins/GET_OPT_INS_SUCCESS',
  GET_OPT_INS_ERROR: '@@opt-ins/GET_OPT_INS_ERROR',

  OPT_IN_FROM_MESSAGE_SCENARIO_STEP_DELETING: '@@opt-ins/OPT_IN_FROM_MESSAGE_SCENARIO_STEP_DELETING',
  OPT_IN_FROM_MESSAGE_SCENARIO_STEP_DELETED: '@@opt-ins/OPT_IN_FROM_MESSAGE_SCENARIO_STEP_DELETED',
  OPT_IN_FROM_MESSAGE_SCENARIO_STEP_DELETE_FAILED: '@@opt-ins/OPT_IN_FROM_MESSAGE_SCENARIO_STEP_DELETE_FAILED',

  FLUSH_OPTINS_COLUMNS_ALL_STATE: '@@opt-ins/FLUSH_OPTINS_COLUMNS_ALL_STATE',
};
