import types from './types';

const initialState = {
  reports: {
    data: [],
    meta: {
      current_page: 0,
      next_page: 0,
      prev_page: 0,
      total_count: 0,
      total_pages: 0,
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_LEAD_TRANSFER_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          data: [...action.payload.lead_transfer_reports],
          meta: {
            ...state.reports.meta,
            ...action.payload.meta,
          },
        },
      };
    default:
      return state;
  }
}
