import React, {useState} from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';
import LayoutPanel from 'client/common/panels/layout-panel';

import {INNER_PLACEHOLDER_ID, ROOT_PLACEHOLDER_ID} from 'client/components/email-template-editor/constants';
import {useEditor} from 'client/components/email-template-editor/hooks/useEditor';
import {AvailableBlockContent, AvailableBlockLayout} from 'client/components/email-template-editor/types';
import {Translation} from 'client/models/language/types';

import SidebarSkeleton from './sidebar-skeleton';

import cssModule from './sidebar.module.scss';

const b = bem('sidebar', {cssModule});

type SidebarProps = {
  loading?: boolean;
};

const Sidebar: React.FC<SidebarProps> = (props) => {
  const {loading} = props;
  const lang: Record<string, Translation> = useLanguage('EMAIL_TEMPLATE_EDITOR.BARS.SIDEBAR');
  const {availableBlocks, setDndData, doc} = useEditor();
  const [draggingBlockId, setDraggingBlockId] = useState<string | null>();
  const {width} = useEditor();

  const handleDragStart = (e: React.DragEvent, el: AvailableBlockLayout | AvailableBlockContent) => {
    let placeholderId = ROOT_PLACEHOLDER_ID;

    if (availableBlocks.content.find(({type}) => type === el.type)) {
      placeholderId = INNER_PLACEHOLDER_ID;
    }

    setDndData({
      id: placeholderId,
      type: el.type,
    });
  };

  const handleDragEnd = () => {
    setDraggingBlockId(null);
    setDndData({
      type: null,
    });
  };

  if (loading) {
    return (
      <LayoutPanel className={b()}>
        <SidebarSkeleton />
      </LayoutPanel>
    );
  }

  const disableContentBlocks = !doc.body?.[0]?.columns?.length;

  return (
    <LayoutPanel className={b()}>
      <div className={b('items-wrapper')}>
        <div className={b('items')}>
          <p className={b('items-title')}>{lang.LAYOUT}</p>
          <p className={b('width-label')}>{`${lang.LAYOUT_WIDTH} ${width} px`}</p>
          <div className={b('items-body')}>
            {availableBlocks.layout.map((el) => (
              <div
                key={el.id}
                className={b('item', [el.id === draggingBlockId ? 'is-dragging' : ''])}
                draggable
                onDragStart={(e) => handleDragStart(e, el)}
                onDragEnd={() => handleDragEnd()}
                onMouseDown={() => setDraggingBlockId(el.id)}
                onMouseUp={() => setDraggingBlockId(null)}
              >
                <Icon name={el.icon} className={b('item-icon')} height={26} />
                {lang[el.label]}
              </div>
            ))}
          </div>
        </div>
        <div className={b('items')}>
          <p className={b('items-title')}>{lang.CONTENT}</p>
          <div className={b('items-body')}>
            {availableBlocks.content.map((el) => (
              <div
                key={el.id}
                className={b('item', {'is-dragging': el.id === draggingBlockId, disabled: disableContentBlocks})}
                draggable={!disableContentBlocks}
                onDragStart={(e) => handleDragStart(e, el)}
                onDragEnd={() => handleDragEnd()}
                onMouseDown={() => setDraggingBlockId(el.id)}
                onMouseUp={() => setDraggingBlockId(null)}
              >
                <Icon name={el.icon} className={b('item-icon')} height={26} />
                {lang[el.label]}
              </div>
            ))}
          </div>
        </div>
      </div>
    </LayoutPanel>
  );
};

export default Sidebar;
