export default {
  // TODO: needs refactor
  NO_DATA: '@@leads-list/NO_DATA',
  LOADING: '@@leads-list/LOADING',
  LOADED: '@@leads-list/LOADED',
  LOAD_FAILED: '@@leads-list/LOAD_FAILED',

  ADDING: '@@leads-list/ADDING',
  ADDED: '@@leads-list/ADDED',
  ADD_FAILED: '@@leads-list/LEAD_ADDED',

  GET_LEADS_REQUEST: '@@leads-list/GET_LEADS_REQUEST',
  GET_LEADS_SUCCESS: '@@leads-list/GET_LEADS_SUCCESS',
  GET_LEADS_ERROR: '@@leads-list/GET_LEADS_ERROR',

  DELETE_LEAD_REQUEST: '@@leads-list/DELETE_LEAD_REQUEST',
  DELETE_LEAD_SUCCESS: '@@leads-list/DELETE_LEAD_SUCCESS',
  DELETE_LEAD_ERROR: '@@leads-list/DELETE_LEAD_ERROR',

  CREATE_LEAD_COLUMN_REQUEST: '@@leads-list/CREATE_LEAD_COLUMN_REQUEST',
  CREATE_LEAD_COLUMN_SUCCESS: '@@leads-list/CREATE_LEAD_COLUMN_SUCCESS',
  CREATE_LEAD_COLUMN_ERROR: '@@leads-list/CREATE_LEAD_COLUMN_ERROR',

  UPDATE_LEAD_COLUMN_REQUEST: '@@leads-list/UPDATE_LEAD_COLUMN_REQUEST',
  UPDATE_LEAD_COLUMN_SUCCESS: '@@leads-list/UPDATE_LEAD_COLUMN_SUCCESS',
  UPDATE_LEAD_COLUMN_ERROR: '@@leads-list/UPDATE_LEAD_COLUMN_ERROR',

  DELETE_LEAD_COLUMN_REQUEST: '@@leads-list/DELETE_LEAD_COLUMN_REQUEST',
  DELETE_LEAD_COLUMN_SUCCESS: '@@leads-list/DELETE_LEAD_COLUMN_SUCCESS',
  DELETE_LEAD_COLUMN_ERROR: '@@leads-list/DELETE_LEAD_COLUMN_ERROR',

  FLUSH_LEADS_ALL_STATE: '@@leads-list/FLUSH_LEADS_ALL_STATE',

  GET_LEADS_STATISTIC_REQUEST: '@@leads-list/GET_LEADS_STATISTIC_REQUEST',
  GET_LEADS_STATISTIC_SUCCESS: '@@leads-list/GET_LEADS_STATISTIC_SUCCESS',
  GET_LEADS_STATISTIC_ERROR: '@@leads-list/GET_LEADS_STATISTIC_ERROR',

  GET_ONLINE_USERS_STATISTIC_REQUEST: '@@leads-list/GET_ONLINE_USERS_STATISTIC_REQUEST',
  GET_ONLINE_USERS_STATISTIC_SUCCESS: '@@leads-list/GET_ONLINE_USERS_STATISTIC_SUCCESS',
  GET_ONLINE_USERS_STATISTIC_ERROR: '@@leads-list/GET_ONLINE_USERS_STATISTIC_ERROR',
};
