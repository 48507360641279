import React, {Component} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, Field, getFormValues} from 'redux-form';

import {getSources} from 'client/ducks/first-sources/actions';
import {selectFirstSourcesLeadsFilterMapped} from 'client/ducks/first-sources/selectors';

import {GENDER_TYPES_VALUES} from 'client/common/config';

import fieldTemplate from 'client/components/common/field';

class BCMailingFiltersForm extends Component {
  static propTypes = {
    init: PropTypes.bool,
    formValues: PropTypes.object,
    filters: PropTypes.object,
    firstSources: PropTypes.array.isRequired,
    clientId: PropTypes.string.isRequired,
    initialize: PropTypes.func.isRequired,
    onFormChange: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    getSources: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    init: false,
    formValues: {},
    filters: {},
  };

  static qsNames = {
    filters: 'filters',
  };

  static formName = 'BcMailingFiltersModalForm';

  static filtersMap = {
    email_present: {
      field: 'email_present',
      default: false,
      localeKey: 'WITH_EMAIL',
    },
    phone_present: {
      field: 'phone_present',
      default: false,
      localeKey: 'WITH_PHONE',
    },
    visuals_present: {
      field: 'visuals_present',
      default: false,
      localeKey: 'WITH_VISUALS',
    },
    gender_eq: {
      field: 'gender_eq',
      default: 'no',
      localeKey: 'GENDER',
    },
    created_at: {
      field: 'created_at',
      period: 'period',
      default: 'no',
    },
    created_at_gteq: {
      field: 'created_at_gteq',
      default: '',
      localeKey: 'CREATED_FROM',
    },
    created_at_lteq: {
      field: 'created_at_lteq',
      default: '',
      localeKey: 'CREATED_TO',
    },
    participated_at: {
      field: 'participated_at',
      period: 'period',
      default: 'no',
    },
    participated_at_gteq: {
      field: 'participated_at_gteq',
      default: '',
      localeKey: 'PARTICIPATED_FROM',
    },
    participated_at_lteq: {
      field: 'participated_at_lteq',
      default: '',
      localeKey: 'PARTICIPATED_TO',
    },
    lead_histories_source_id_eq: {
      field: 'lead_histories_source_id_eq',
      default: {
        value: '0',
      },
      localeKey: 'SOURCE',
    },
  };

  constructor(props) {
    super(props);

    const map = BCMailingFiltersForm.filtersMap;

    this.state = {
      disabledCreatedAt: false,
      disabledParticipatedAt: false,
      [map.created_at_gteq.field]: '',
      [map.created_at_lteq.field]: '',
      [map.participated_at_gteq.field]: '',
      [map.participated_at_lteq.field]: '',
    };

    this.LANGUAGE = props.languageState.payload.BROADCASTING_TASK.MAILING_FILTERS_MODAL;
  }

  componentDidMount() {
    this.getSources();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.init && prevProps.init) {
      this.initForm(this.props.filters);
    }
  }

  initForm = (filters = {}) => {
    const map = BCMailingFiltersForm.filtersMap;

    const values = Object.keys(filters).reduce((prevValues, key) => {
      switch (key) {
        case map.email_present.field:
        case map.phone_present.field:
        case map.visuals_present.field:
          prevValues[key] = filters[key] || map[key].default;
          break;
        case map.created_at_gteq.field:
        case map.created_at_lteq.field:
        case map.participated_at_gteq.field:
        case map.participated_at_lteq.field:
          prevValues[key] = filters[key] ? moment(filters[key]).format('DD/MM/YYYY') : map[key].default;
          break;
        default:
      }
      return prevValues;
    }, {});

    values[map.gender_eq.field] = filters[map.gender_eq.field] || map.gender_eq.default;

    // init sources filter dropdown
    values[map.lead_histories_source_id_eq.field] =
      filters[map.lead_histories_source_id_eq.field] || map.lead_histories_source_id_eq.default;

    // enable/disable datepickers
    values[map.created_at.field] =
      filters[map.created_at_gteq.field] || filters[map.created_at_lteq.field]
        ? map.created_at.period
        : map.created_at.default;

    values[map.participated_at.field] =
      filters[map.participated_at_gteq.field] || filters[map.participated_at_lteq.field]
        ? map.participated_at.period
        : map.participated_at.default;

    Promise.resolve(this.props.initialize(values)).then(() => {
      this.setState({
        disabledCreatedAt: !filters[map.created_at_gteq.field] && !filters[map.created_at_lteq.field],
        disabledParticipatedAt: !filters[map.participated_at_gteq.field] && !filters[map.participated_at_lteq.field],
        [map.created_at_gteq.field]: this.getDisabledDate(filters[map.created_at_gteq.field]),
        [map.created_at_lteq.field]: this.getDisabledDate(filters[map.created_at_lteq.field]),
        [map.participated_at_gteq.field]: this.getDisabledDate(filters[map.participated_at_gteq.field]),
        [map.participated_at_lteq.field]: this.getDisabledDate(filters[map.participated_at_lteq.field]),
      });
    });
  };

  getSources = () => {
    const {clientId} = this.props;

    const params = {
      q: {
        client_id_eq: clientId,
        'source.name_not_null': 'not',
      },
      include: {
        source: {
          client: null,
          interaction: 'interface.automation_task.operation',
        },
      },
      // uniq_by_source: true
    };

    return this.props.getSources(params);
  };

  getDisabledDate = (value = '') => {
    return value ? moment(value).toDate() : '';
  };

  handleChange = () => () => {
    const map = BCMailingFiltersForm.filtersMap;

    const {formValues} = this.props;

    const filters = Object.keys(formValues).reduce((prevFilters, key) => {
      const isDefault = formValues[key] === map[key].default;
      switch (key) {
        case map.email_present.field:
        case map.phone_present.field:
        case map.visuals_present.field:
        case map.gender_eq.field:
          if (!isDefault) {
            prevFilters[map[key].field] = formValues[key];
          }
          break;
        case map.created_at.field:
          if (isDefault) {
            delete prevFilters.created_at_gteq;
            delete prevFilters.created_at_lteq;

            this.handleFieldValueChange(map.created_at_gteq.field, map.created_at_gteq.default);

            this.handleFieldValueChange(map.created_at_lteq.field, map.created_at_lteq.default);
          }

          this.setState({
            disabledCreatedAt: isDefault,
          });
          break;
        case map.participated_at.field:
          if (isDefault) {
            this.handleFieldValueChange(map.participated_at_gteq.field, map.participated_at_gteq.default);

            this.handleFieldValueChange(map.participated_at_lteq.field, map.participated_at_lteq.default);

            delete prevFilters.participated_at_gteq;
            delete prevFilters.participated_at_lteq;
          }

          this.setState({
            disabledParticipatedAt: isDefault,
          });
          break;
        case map.created_at_gteq.field:
        case map.created_at_lteq.field:
          switch (true) {
            case formValues[map.created_at.field] === map.created_at.default:
              break;
            case !isDefault:
              // disable datepicker dates
              const disabledDate = formValues[key] ? moment(formValues[key], 'DD/MM/YYYY').toDate() : '';

              this.setState({
                [key]: disabledDate,
              });

              // set filter value
              const time = moment(formValues[key], 'DD/MM/YYYY').toISOString(true);
              prevFilters[map[key].field] = time;
              break;
            default:
          }
          break;
        case map.participated_at_gteq.field:
        case map.participated_at_lteq.field:
          switch (true) {
            case formValues[map.participated_at.field] === map.participated_at.default:
              delete prevFilters[map[key].field];
              this.setState({
                [key]: '',
              });
              break;
            case !isDefault:
              // disable datepicker dates
              const disabledDate = formValues[key] ? moment(formValues[key], 'DD/MM/YYYY').toDate() : '';

              this.setState({
                [key]: disabledDate,
              });

              // set filter value
              const time = moment(formValues[key], 'DD/MM/YYYY').toISOString(true);
              prevFilters[map[key].field] = time;
              break;
            default:
          }
          break;
        case map.lead_histories_source_id_eq.field:
          if (formValues[key].value !== map[key].default.value) {
            prevFilters[map[key].field] = {
              value: formValues[key].value,
              label: encodeURIComponent(formValues[key].label),
              selectCustomOption: formValues[key].selectCustomOption,
            };
          }
          break;
        default:
        //
      }
      return prevFilters;
    }, {});
    this.props.onFormChange(filters);
  };

  handleFieldChange = () => {
    Promise.resolve().then(this.handleChange());
  };

  handleFieldValueChange = (field, value) => {
    this.props.change(field, value);
  };

  extendSources = (sources = []) => {
    return [
      {
        value: '0',
        label: this.LANGUAGE.NO_MATTER_RADIO,
      },
      ...sources,
    ];
  };

  render() {
    const {firstSources} = this.props;

    const {disabledCreatedAt, disabledParticipatedAt} = this.state;

    const {LANGUAGE} = this;
    const map = BCMailingFiltersForm.filtersMap;
    const sourceOptions = this.extendSources(firstSources);
    return (
      <form>
        <div className="form-field-wrap form-field-wrap--margin-1 fake-input">
          <div className="fake-input__label">{LANGUAGE.ATTRIBUTES_LABEL}</div>
          <div className="field-group">
            <div className="field-group__row">
              <div className="field-group__field">
                <Field
                  cssModifier="checkbox-button--switcher-theme-1"
                  label={LANGUAGE.WITH_PHONE_RADIO}
                  name={map.phone_present.field}
                  type="checkbox"
                  value="true"
                  checkboxType="switcher-2"
                  onChange={this.handleFieldChange}
                  component={fieldTemplate}
                />
              </div>
              <div className="field-group__field">
                <Field
                  cssModifier="checkbox-button--switcher-theme-1"
                  label={LANGUAGE.WITH_EMAIL_RADIO}
                  name={map.email_present.field}
                  type="checkbox"
                  checkboxType="switcher-2"
                  value="true"
                  onChange={this.handleFieldChange}
                  component={fieldTemplate}
                />
              </div>
              <div className="field-group__field">
                <Field
                  cssModifier="checkbox-button--switcher-theme-1"
                  label={LANGUAGE.WITH_VISUALS_RADIO}
                  name={map.visuals_present.field}
                  type="checkbox"
                  value="true"
                  checkboxType="switcher-2"
                  onChange={this.handleFieldChange}
                  component={fieldTemplate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-field-wrap form-field-wrap--margin-1 fake-input z_400">
          <div className="fake-input__label">{LANGUAGE.PARTICIPATED_LABEL}</div>
          <div className="field-group">
            <div className="field-group__row">
              <div className="field-group__field">
                <Field
                  cssModifier="radio-button--switcher-theme-1"
                  label={LANGUAGE.NO_MATTER_RADIO}
                  name={map.participated_at.field}
                  type="radio"
                  value={map.participated_at.default}
                  radioType="switcher-2"
                  onChange={this.handleFieldChange}
                  component={fieldTemplate}
                />
              </div>
              <div className="field-group__field">
                <Field
                  cssModifier="radio-button--switcher-theme-1"
                  label={LANGUAGE.TIME_PERIOD_RADIO}
                  name={map.participated_at.field}
                  type="radio"
                  value={map.participated_at.period}
                  radioType="switcher-2"
                  onChange={this.handleFieldChange}
                  component={fieldTemplate}
                />
              </div>
            </div>
            <div className="field-group__row pos-rel z_300">
              <div className="field-group__field field-group__field--size-3">
                <Field
                  label={LANGUAGE.FROM_LABEL}
                  name={map.participated_at_gteq.field}
                  disabled={disabledParticipatedAt}
                  type="datepicker"
                  disabledDayAfter={this.state[map.participated_at_lteq.field]}
                  onChange={this.handleFieldChange}
                  component={fieldTemplate}
                />
              </div>
              <div className="field-group__field field-group__field--size-3">
                <Field
                  label={LANGUAGE.TO_LABEL}
                  name={map.participated_at_lteq.field}
                  disabled={disabledParticipatedAt}
                  type="datepicker"
                  disabledDayBefore={this.state[map.participated_at_gteq.field]}
                  onChange={this.handleFieldChange}
                  component={fieldTemplate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-field-wrap form-field-wrap--margin-1 fake-input">
          <div className="fake-input__label">{LANGUAGE.GENDER_LABEL}</div>
          <div className="field-group">
            <div className="field-group__row">
              <div className="field-group__field">
                <Field
                  cssModifier="radio-button--switcher-theme-1"
                  label={LANGUAGE.NO_MATTER_RADIO}
                  name={map.gender_eq.field}
                  value={map.gender_eq.default}
                  type="radio"
                  radioType="switcher-2"
                  onChange={this.handleFieldChange}
                  component={fieldTemplate}
                />
              </div>
              <div className="field-group__field">
                <Field
                  cssModifier="radio-button--switcher-theme-1"
                  label={LANGUAGE.MALE_RADIO}
                  name={map.gender_eq.field}
                  value={GENDER_TYPES_VALUES.MALE}
                  type="radio"
                  radioType="switcher-2"
                  onChange={this.handleFieldChange}
                  component={fieldTemplate}
                />
              </div>
              <div className="field-group__field">
                <Field
                  cssModifier="radio-button--switcher-theme-1"
                  label={LANGUAGE.FEMALE_RADIO}
                  name={map.gender_eq.field}
                  value={GENDER_TYPES_VALUES.FEMALE}
                  type="radio"
                  radioType="switcher-2"
                  onChange={this.handleFieldChange}
                  component={fieldTemplate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-field-wrap form-field-wrap--margin-1 fake-input z_300">
          <div className="fake-input__label">{LANGUAGE.CREATED_LABEL}</div>
          <div className="field-group">
            <div className="field-group__row">
              <div className="field-group__field">
                <Field
                  cssModifier="radio-button--switcher-theme-1"
                  label={LANGUAGE.NO_MATTER_RADIO}
                  name={map.created_at.field}
                  value={map.created_at.default}
                  type="radio"
                  radioType="switcher-2"
                  onChange={this.handleFieldChange}
                  component={fieldTemplate}
                />
              </div>
              <div className="field-group__field">
                <Field
                  cssModifier="radio-button--switcher-theme-1"
                  label={LANGUAGE.TIME_PERIOD_RADIO}
                  name={map.created_at.field}
                  value={map.created_at.period}
                  type="radio"
                  radioType="switcher-2"
                  onChange={this.handleFieldChange}
                  component={fieldTemplate}
                />
              </div>
            </div>
          </div>
          <div className="field-group__row">
            <div className="field-group__field field-group__field--size-3">
              <Field
                label={LANGUAGE.FROM_LABEL}
                name={map.created_at_gteq.field}
                disabled={disabledCreatedAt}
                onChange={this.handleFieldChange}
                type="datepicker"
                disabledDayAfter={this.state[map.created_at_lteq.field]}
                component={fieldTemplate}
              />
            </div>
            <div className="field-group__field field-group__field--size-3">
              <Field
                label={LANGUAGE.TO_LABEL}
                disabled={disabledCreatedAt}
                name={map.created_at_lteq.field}
                onChange={this.handleFieldChange}
                type="datepicker"
                disabledDayBefore={this.state[map.created_at_gteq.field]}
                component={fieldTemplate}
              />
            </div>
          </div>
        </div>
        <div className="form-field-wrap form-field-wrap--margin-1 z_200">
          <Field
            cssModifier="select--no-min-height select--view-6"
            label={LANGUAGE.SOURCE_LABEL}
            name={map.lead_histories_source_id_eq.field}
            type="select"
            onChange={this.handleFieldChange}
            searchable={false}
            options={sourceOptions}
            component={fieldTemplate}
          />
        </div>
      </form>
    );
  }
}

const form = reduxForm({
  form: BCMailingFiltersForm.formName,
})(BCMailingFiltersForm);

const mapStateToProps = (state) => ({
  formValues: getFormValues(BCMailingFiltersForm.formName)(state),
  languageState: state.languageState,
  firstSources: selectFirstSourcesLeadsFilterMapped(state),
});

const mapDispatchToProps = {
  getSources,
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
