import React, {Component} from 'react';

import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, formValueSelector, reduxForm, FormSection, getFormValues} from 'redux-form';

import {updateMembershipsFromForm} from 'client/services/membershipHelpers';
import {date, dateIsAfter, required} from 'client/services/validator';

import {apiLeadsExport, updateApiLeadsExport} from 'client/ducks/leads-export/actions';

import {LEAD_EXPORT_TYPES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import AllowedUsersField, {userToOption} from 'client/components/common/custom-fields/allowed-users-field';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

function getInitialValues(editingExport = null) {
  if (editingExport) {
    return {
      dateFrom: editingExport.from,
      dateTo: editingExport.to,
      comment: editingExport.comment,
      allowed_for_all: editingExport.allowed_for_all ? 'true' : 'false',
      users: editingExport.memberships.map((i) => {
        return userToOption(i.client_user);
      }),
      columnsGroup: editingExport.lead_transfer_items.reduce((res, i) => {
        res[`_${i.column_adapter_id}`] = true;
        return res;
      }, {}),
    };
  }

  return {
    columnsGroup: {},
    allowed_for_all: 'true',
    users: [],
  };
}

class LeadsGenerateApiExportModal extends Component {
  static defaultProps = {
    show: false,
    columnsGroup: {},
    columns: [],
    users: [],
    editingExport: null,
    formValues: {},
    isAll: true,
    queryParams: {},
    checkedLeads: [],
  };

  static propTypes = {
    show: PropTypes.bool,
    lang: PropTypes.object.isRequired,
    columnsGroup: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    change: PropTypes.func.isRequired,
    apiLeadsExport: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    clientId: PropTypes.number.isRequired,
    columns: PropTypes.array,
    users: PropTypes.array,
    editingExport: PropTypes.object,
    updateApiLeadsExport: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    isAll: PropTypes.bool,
    queryParams: PropTypes.object,
    checkedLeads: PropTypes.array,
  };

  static formName = 'LeadsGenerateApiExportModalForm';

  static checkboxAllColumns = 'checkboxAllColumns';

  static columnsGroup = 'columnsGroup';

  constructor(props) {
    super(props);

    this.rules = {
      dateFrom: [
        date(this.props.lang.ERRORS.INVALID_DATE_FORMAT),
        required(this.props.lang.REQUIRED),
        (value) => {
          return moment.utc(value, 'DD-MM-YYYY').isSameOrAfter(moment.utc(), 'day')
            ? null
            : this.props.lang.ERRORS.INVALID_DATE_START;
        },
      ],
      dateTo: [
        date(this.props.lang.ERRORS.INVALID_DATE_FORMAT),
        required(this.props.lang.REQUIRED),
        (value, {dateFrom}) => {
          return value && dateFrom && !date('.')(dateFrom)
            ? dateIsAfter(this.props.lang.ERRORS.INVALID_DATE_BORDERS, 'dateFrom')(value, {dateFrom})
            : null;
        },
      ],
    };

    this.state = {
      showWarning: true,
      isColumnsFieldsTouched: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      const initValues = getInitialValues(this.props.editingExport);
      this.props.initialize(initValues);
      this.setState({
        showWarning: true,
        isColumnsFieldsTouched: false,
      });
    }

    if (this.props.columnsGroup !== prevProps.columnsGroup) {
      this.validateFormSection(this.props);
    }

    if (this.props.formValues.allowed_for_all === 'true' && !isEmpty(this.props.formValues.users)) {
      this.props.change('users', []);
    }
  }

  validateFormSection = (props) => {
    const columnsGroup = props.columnsGroup ? Object.keys(props.columnsGroup) : [];
    const validate = columnsGroup.some((el) => props.columnsGroup[el] === true);
    this.setState({showWarning: !validate});
  };

  toggleAllColumns = (e, colGroup) => {
    this.setState({isColumnsFieldsTouched: true});

    const result = this.props.columns.reduce(
      (group, item) => ({
        ...group,
        [`_${item.id}`]: e.currentTarget.checked,
      }),
      {},
    );

    this.props.change(colGroup, result);
  };

  uncheckAllColumns = (checkBox) => {
    this.props.change(checkBox, false);
    this.setState({isColumnsFieldsTouched: true});
  };

  onSave = (values) => {
    if (this.state.showWarning) {
      return this.setState({isColumnsFieldsTouched: true});
    }

    const {editingExport} = this.props;
    const columnsGroup = Object.entries(values.columnsGroup);
    const initialTransferItems = get(editingExport, 'lead_transfer_items', []);
    const initialMembershipItems = get(editingExport, 'memberships', []);
    const transferItems = [];

    const initialColumnIds = initialTransferItems.map((i) => {
      return i.column_adapter_id;
    });

    const updatedColumnIds = columnsGroup
      .map((el) => {
        return el[1] ? +el[0].slice(1) : null;
      })
      .filter((i) => i);

    initialColumnIds.forEach((id) => {
      if (!updatedColumnIds.includes(id)) {
        transferItems.push({
          id: find(initialTransferItems, {column_adapter_id: id}).id,
          _destroy: true,
        });
      }
    });

    updatedColumnIds.forEach((id) => {
      if (!initialColumnIds.includes(id)) {
        transferItems.push({
          type: LEAD_EXPORT_TYPES.LEAD_EXPORT_ITEM,
          column_adapter_id: id,
        });
      }
    });

    const exportData = {
      api_lead_export: {
        name: `${this.props.lang.API_EXPORT} ${moment(new Date().toISOString()).format('DD/MM/YYYY')}`,
        comment: values.comment || '',
        client_id: this.props.clientId,
        from: moment.utc(values.dateFrom, 'DD/MM/YYYY').toISOString(),
        to: moment.utc(values.dateTo, 'DD/MM/YYYY').toISOString(),
        lead_transfer_items: transferItems,
        allowed_for_all: values.allowed_for_all === 'true',
        transferable_memberships: updateMembershipsFromForm(initialMembershipItems, values.users),
      },
    };

    if (!editingExport) {
      exportData.api_lead_export.parameters_for_export = this.props.isAll ? this.props.queryParams : {};

      if (!this.props.isAll) {
        exportData.api_lead_export.transferable_leads = this.props.checkedLeads.map((id) => ({
          lead_id: id,
        }));
      }
    }

    const promise = editingExport
      ? this.props.updateApiLeadsExport(editingExport.id, exportData)
      : this.props.apiLeadsExport(exportData);

    return promise.then(this.props.onClose);
  };

  render() {
    const {handleSubmit, columns, users, show, onClose, onCancel, editingExport, formValues, lang} = this.props;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-2 leads-port-layout"
        backdropClassName="modal-window__backdrop"
        onClose={onCancel || onClose}
        title={editingExport ? lang.TITLE_MODIFY_API : lang.TITLE_API}
      >
        <form onSubmit={handleSubmit(this.onSave)}>
          <div className="theme-color-2">
            <FormFieldWrap>
              <div className="main-text main-text--small main-text--color-1">{lang.SET_COLUMNS + '*'}</div>
              <div className="content-group">
                <CustomScrollbars
                  scrollbarProps={{
                    autoHeightMin: 200,
                    autoHeightMax: 300,
                  }}
                >
                  <div className="leads-port-layout__group overflow-hidden">
                    <div className="leads-port-layout__group-header">
                      <Field
                        label={lang.COLUMNS_COLUMN_TITLE}
                        name={LeadsGenerateApiExportModal.checkboxAllColumns}
                        type="checkbox"
                        onChange={(e) => this.toggleAllColumns(e, LeadsGenerateApiExportModal.columnsGroup)}
                        component={fieldTemplate}
                      />
                    </div>
                    <div className="leads-port-layout__group-body">
                      <FormSection name={LeadsGenerateApiExportModal.columnsGroup}>
                        {columns.map((col, index) => {
                          return (
                            <Field
                              key={index}
                              label={col.name}
                              cssModifier="leads-port-layout__checkbox"
                              name={`_${col.id}`}
                              type="checkbox"
                              onChange={() => {
                                this.uncheckAllColumns(LeadsGenerateApiExportModal.checkboxAllColumns);
                              }}
                              component={fieldTemplate}
                            />
                          );
                        })}
                      </FormSection>
                    </div>
                  </div>
                </CustomScrollbars>
              </div>
              {this.state.showWarning && this.state.isColumnsFieldsTouched && (
                <div className="message-error-text">{lang.NO_MATCH_ERROR_MESSAGE}</div>
              )}
            </FormFieldWrap>
            <FormFieldWrap>
              <div className="leads-port-layout__row-title">{lang.PERIOD_LABEL + '*'}</div>
              <div className="flex-container">
                <div className="flex-inline-container">
                  <Field
                    cssModifier="leads-port-layout__datepicker leads-port-layout__datepicker--full-width"
                    label={lang.FROM_LABEL}
                    name="dateFrom"
                    type="datepicker"
                    validate={this.rules.dateFrom}
                    component={fieldTemplate}
                  />
                  <Field
                    cssModifier="leads-port-layout__datepicker leads-port-layout__datepicker--full-width"
                    label={lang.TO_LABEL}
                    name="dateTo"
                    type="datepicker"
                    validate={this.rules.dateTo}
                    component={fieldTemplate}
                  />
                </div>
              </div>
            </FormFieldWrap>
            <FormFieldWrap>
              <div className="leads-port-layout__row-title">{lang.ALLOWED_USERS_LABEL}</div>
              <div className="flex-container margin-bottom--5">
                <div className="leads-port-layout__col-1">
                  <Field
                    label={lang.ALLOW_ALL_LABEL}
                    name="allowed_for_all"
                    value="true"
                    type="radio"
                    component={fieldTemplate}
                  />
                </div>
                <div className="leads-port-layout__col-1">
                  <Field
                    label={lang.ALLOW_SELECTED_LABEL}
                    name="allowed_for_all"
                    value="false"
                    type="radio"
                    component={fieldTemplate}
                  />
                </div>
              </div>
              <AllowedUsersField
                label={lang.USER_ALLOWED_LABEL}
                users={users}
                disabled={formValues.allowed_for_all === 'true'}
              />
            </FormFieldWrap>
            <FormFieldWrap>
              <Field
                cssModifier="leads-port-layout__textarea"
                name="comment"
                type="textarea"
                label={lang.COMMENT_LABEL}
                component={fieldTemplate}
              />
            </FormFieldWrap>
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button
              className="button button--bg-11 modal-window__footer-btn"
              type="button"
              onClick={onCancel || onClose}
            >
              {lang.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-2 modal-window__footer-btn" type="submit">
              {editingExport ? lang.SAVE_BUTTON : lang.GENERATE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const LeadsGenerateApiExportModalForm = reduxForm({
  form: LeadsGenerateApiExportModal.formName,
})(LeadsGenerateApiExportModal);

export default connect(
  (state, {editingExport}) => {
    const selector = formValueSelector(LeadsGenerateApiExportModal.formName);

    return {
      lang: state.languageState.payload.LEADS_PORT.GENERATE_EXPORT_FILE_MODAL,
      columnsGroup: selector(state, LeadsGenerateApiExportModal.columnsGroup),
      dateFrom: selector(state, 'dateFrom'),
      dateTo: selector(state, 'dateTo'),
      formValues: getFormValues(LeadsGenerateApiExportModal.formName)(state),
      initialValues: getInitialValues(editingExport),
    };
  },
  {
    apiLeadsExport,
    updateApiLeadsExport,
  },
)(LeadsGenerateApiExportModalForm);
