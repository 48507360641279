import React, {Component} from 'react';

import isEqual from 'lodash/isEqual';

import {filterOnKeys} from 'client/services/helpers';

/**
 * HOC which only rerenders if certain props have changed
 * @param {ReactComponent} BaseComponent - Component
 * @param {Array} removableKeys - superfluous props
 * @return {Pure} - PureComponent
 */
const pure = (BaseComponent, removableKeys) => {
  class Pure extends Component {
    static displayName = `Pure(${BaseComponent.displayName || BaseComponent.name || 'Component'})`;

    shouldComponentUpdate(nextProps) {
      const nextClearProps = filterOnKeys(nextProps, removableKeys);
      const oldClearProps = filterOnKeys(this.props, removableKeys);

      return !isEqual(oldClearProps, nextClearProps);
    }

    render() {
      return <BaseComponent {...this.props} />;
    }
  }

  Object.entries(BaseComponent).forEach(([key, value]) => {
    Pure[key] = value;
  });

  return Pure;
};

export default pure;
