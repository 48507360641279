import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';

import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import CustomLink from 'client/components/common/custom-link';
import SearchField from 'client/components/common/search-field';

import BCMailingTasksToolbar from './components/bc-mailing-tasks-toolbar';

import cssModule from './bc-mailing-tasks-data-grid.module.scss';

const b = bem('bc-mailing-tasks-data-grid', {cssModule});

class BCMailingTasksDataGrid extends ReactQueryParams {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    lang: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    onSearchClear: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);
  }

  renderSearchField = () => {
    return (
      <SearchField
        placeholder={this.props.lang.SEARCH_PLACEHOLDER}
        defaultSearch={this.queryParams.search}
        onSearch={this.props.onSearch}
        onClear={this.props.onSearchClear}
      />
    );
  };

  renderToolbar = () => {
    return (
      <BCMailingTasksToolbar
        queryParams={this.queryParams}
        meta={this.props.meta}
        language={this.props.lang}
        renderSearchField={this.renderSearchField}
      />
    );
  };

  renderName = ({value, item}) => {
    const {client} = this.props;
    const link = CustomLink.createTaskLink({
      clientType: client.type,
      operationId: item.operation_id,
      clientId: client.id,
      taskType: item.type,
      taskId: item.id,
    });

    return (
      <div className="ellipsis-text">
        <Link to={link} className={b('link')}>
          {value}
        </Link>
      </div>
    );
  };

  renderText = ({value}) => {
    return <div className="ellipsis-text">{value}</div>;
  };

  render() {
    const {meta, data, onPageChange} = this.props;

    return (
      <div>
        <div className={b('toolbar-wrapper')}>
          {this.renderToolbar()}
          <PerPageDropdown
            onChange={(pages) => {
              onPageChange({page: 1, perPage: pages});
            }}
            value={String(this.queryParams.perPage || 5)}
            simpleValue
          />
        </div>
        <ClientTable
          data={data}
          columns={[
            {path: 'name', label: this.props.lang.NAME_COLUMN, render: this.renderName, width: 300},
            {path: 'type', label: this.props.lang.TYPE_COLUMN, render: this.renderText},
          ]}
        />
        <PaginationBar
          data={data}
          perPage={this.queryParams.perPage}
          totalPages={meta.total_pages}
          totalItems={meta.total_count}
          currentPage={meta.current_page}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.languageState.payload.MESSAGE_TASKS.TABLE,
});

export default connect(mapStateToProps)(BCMailingTasksDataGrid);
