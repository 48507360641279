import React from 'react';

import PropTypes from 'prop-types';

function TextCell(props) {
  const {value} = props;
  let content = typeof value === 'object' ? JSON.stringify(value) : '';
  content = typeof value !== 'undefined' ? String(value) : content;

  if (value === null) {
    content = '';
  }
  return (
    <div className="ellipsis-text" title={content}>
      {content}
    </div>
  );
}

TextCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default TextCell;
