import React, {useMemo, useState} from 'react';

import {useDispatch} from 'react-redux';

import {useReduxFetch} from 'client/services/hooks';

import {getExports, deleteExport} from 'client/ducks/statistic-exports/actions';
import {selectExports} from 'client/ducks/statistic-exports/selectors';

import {REPORTS_PER_PAGE} from 'client/components/client-autotask/tabs/data-tab/data-common/constants';
import ViewFilesModal from 'client/components/client-autotask/tabs/data-tab/view-files-modal';
import {Autotask} from 'client/models/autotask/types';
import {getDefaultMeta} from 'client/types/common/helpers';

import {mapStatisticExport} from './helpers';

type ViewStatisticFilesModalProps = {
  autotask: Autotask;
  onClose: () => any;
  onDelete: () => any;
};

const sortFieldMap = {
  date: 'created_at',
  fileName: 'file_blob_filename',
};

const ViewStatisticFilesModal: React.FC<ViewStatisticFilesModalProps> = (props) => {
  const {autotask, onClose, onDelete} = props;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({sortOrder: 'desc', sortField: 'date'});

  const {data = {items: [], meta: getDefaultMeta()}, fetch: fetchExports} = useReduxFetch({
    action: getExports,
    actionArgs: {
      q: {
        g: {
          0: {
            automation_task_id_eq: autotask.id,
          },
          1: {
            m: 'or',
            file_blob_filename_cont: search,
            membership_client_user_full_name_cont: search,
          },
        },
        s: `${sortFieldMap[sort.sortField as keyof typeof sortFieldMap]} ${sort.sortOrder}`,
      },
      page,
      per_page: REPORTS_PER_PAGE,
    },
    selector: selectExports,
  });

  const mappedData = useMemo(() => {
    return {
      meta: data.meta,
      items: data.items.map(mapStatisticExport),
    };
  }, [data]);

  const handleDelete = async (id: number) => {
    await dispatch(deleteExport(id));
    fetchExports();
    onDelete();
  };

  return (
    <ViewFilesModal
      onClose={onClose}
      files={mappedData}
      onPageChange={setPage}
      search={search}
      onSearch={(value) => {
        setPage(1);
        setSearch(value);
      }}
      onDelete={handleDelete}
      sortParams={sort}
      onSortChange={setSort}
      isStatistic
    />
  );
};

export default ViewStatisticFilesModal;
