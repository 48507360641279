import React from 'react';

import bem from 'client/services/bem';

import cssModule from './tags-cell.module.scss';

const b = bem('tags-cell', {cssModule});

type TagsCellProps = {
  tags: string[];
};

const TagsCell: React.FC<TagsCellProps> = (props) => {
  const {tags} = props;

  return (
    <div className={b()}>
      {tags.map((tag) => (
        <span key={tag} className={b('tag')} title={`#${tag}`}>
          {tag}
        </span>
      ))}
    </div>
  );
};

export default TagsCell;
