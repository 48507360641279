import {Langs} from 'client/services/hooks/useLanguage/types';

import validateStockInformation from 'client/components/prizes/fieldsets/prize-diy-stock-information/validate';
import {formValuesProps} from 'client/components/prizes/forms/prizemap-customization-form/type';

export default (values: formValuesProps, args: {lang: Langs<'PRIZES.FIELDSETS.ERRORS'>}) => {
  const errors: Record<string, any> = {
    ...validateStockInformation(values, args),
  };

  return errors;
};
