import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {selectIsAdmin} from 'client/ducks/user/selectors';

import CountBlock from 'client/common/count-block';

// components
import InfoBlock from 'client/components/common/info-block';
import Spinner from 'client/components/common/spinner';

import OptInsList from '../../tables/opt-ins-list';

class OptInsCard extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    onAddClick: PropTypes.func,
    onEditClick: PropTypes.func,
    showOptInInfo: PropTypes.func,
    languageState: PropTypes.object.isRequired,
    scrollbarProps: PropTypes.object,
    showActions: PropTypes.bool,
    isAdmin: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    loadingColor: PropTypes.string,
  };

  static defaultProps = {
    onAddClick: () => {},
    onEditClick: () => {},
    showOptInInfo: () => {},
    scrollbarProps: {},
    showActions: true,
    loading: false,
    loadingColor: 'primary',
  };

  constructor(props) {
    super(props);
    this.LANGUAGE = props.languageState.payload.AGENCY.OPT_INS_CARD;
  }

  render() {
    const {data, onAddClick, onEditClick, showActions, scrollbarProps, showOptInInfo, isAdmin, loading, loadingColor} =
      this.props;

    const {LANGUAGE} = this;

    return (
      <InfoBlock colorTheme="opt-ins">
        <InfoBlock.Title>{LANGUAGE.TITLE}</InfoBlock.Title>
        <InfoBlock.Content>
          <InfoBlock.Header className="clearfix">
            <CountBlock itemName={LANGUAGE.ITEMS_NAME} className="pull-left" num={data.length} />
          </InfoBlock.Header>
          <InfoBlock.Body>
            {loading ? (
              <Spinner color={loadingColor} centered />
            ) : (
              <OptInsList
                onAddClick={onAddClick}
                onEditClick={onEditClick}
                data={data}
                languageState={this.props.languageState}
                showActions={showActions}
                showOptInInfo={showOptInInfo}
                scrollbarProps={scrollbarProps}
                isAdmin={isAdmin}
              />
            )}
          </InfoBlock.Body>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

export default connect((state) => ({
  languageState: state.languageState,
  isAdmin: selectIsAdmin(state),
}))(OptInsCard);
