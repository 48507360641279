import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';

import CountBlock from 'client/common/count-block';

import InfoBlock from 'client/components/common/info-block';

import cssModule from './stores-card.module.scss';

const b = bem('stores-card', {cssModule});

class StoresCard extends Component {
  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.STORES;
  }

  render() {
    const {switcherValue, onSwitcherChange, storesCount, isAdmin} = this.props;
    const {LANGUAGE} = this;

    return (
      <InfoBlock colorTheme="stores" className={b()}>
        <InfoBlock.Title>
          {LANGUAGE.STORES_TITLE}
          {isAdmin && (
            <div className={b('stores-switcher')}>
              <div className={b('stores-switcher-label')}>{LANGUAGE.ADD_STORES_SWITCHER}</div>
              <label className="switcher">
                <div className="switcher__input-wrap">
                  <input
                    className="switcher__input"
                    type="checkbox"
                    name="add-stores"
                    value="0"
                    onChange={({target}) => onSwitcherChange(target.checked)}
                    checked={switcherValue}
                  />
                  <div className="switcher__label" />
                </div>
              </label>
            </div>
          )}
        </InfoBlock.Title>
        <InfoBlock.Content headerOnly>
          <InfoBlock.Header className="clearfix" alone>
            <CountBlock num={storesCount} itemName={LANGUAGE.STORES_TITLE} className="pull-left" />
            {storesCount !== 0 && (
              <div className="info-block__header-link pull-right">
                <Link to={this.props.location.pathname + '/stores'} className="link">
                  {LANGUAGE.GO_TO_STORES_LINK}
                </Link>
              </div>
            )}
          </InfoBlock.Header>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

StoresCard.propTypes = {
  storesCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSwitcherChange: PropTypes.func,
  switcherValue: PropTypes.bool,
  languageState: PropTypes.object,
  location: PropTypes.object,
  isAdmin: PropTypes.bool,
};

StoresCard.defaultProps = {
  storesCount: 0,
  onSwitcherChange: () => {},
  switcherValue: false,
  isAdmin: true,
};

export default withRouter(StoresCard);
