import {getTimeString} from 'client/services/datetime';

const formatDateForBackend = (date) => date.split('/').reverse().join('-');

export default (values) => {
  const {from, to, fromTime, toTime, ...rest} = values;

  return {
    ...rest,
    from: formatDateForBackend(from) + getTimeString(fromTime, '00:00:00'),
    to: formatDateForBackend(to) + getTimeString(toTime, '23:59:59'),
  };
};
