import React, {useCallback, useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {updateDevicesExternal} from 'client/ducks/client-devices/actions';
import {getGlobalMedias, updateMedia, deleteMedia, getLocalMedias} from 'client/ducks/medias/actions';
import {selectMedias} from 'client/ducks/medias/selectors';

import Icon from 'client/common/icon';
import ToggleInput from 'client/common/inputs/toggle-input';
import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {getTotalDurations} from 'client/components/medias/helpers';
import MediasConfigTable from 'client/components/medias/tables/medias-config-table';
import {AccessLevelType} from 'client/models/common/types';
import {Device} from 'client/models/devices/types';
import {MediaFile} from 'client/models/medias/types';
import {ApiDispatch} from 'client/types';

import {MediasConfigValues} from './types';

import MediasAddButton from '../../buttons/medias-add-button';

import cssModule from './medias-config-modal.module.scss';

const b = bem('medias-config-modal', {cssModule});

const formName = 'MultimediaModalForm';

type MediasConfigModalProps = {
  clientId: number;
  onClose: () => void;
  onSave?: () => void;
  level: AccessLevelType;
  device?: Device;
  asForm?: boolean;
  affectationId?: number;
};

const MediasConfigModal: ReduxFormFC<MediasConfigModalProps, MediasConfigValues> = (props) => {
  const {
    clientId,
    onClose,
    onSave,
    level,
    handleSubmit,
    initialize,
    change,
    device = null,
    asForm = false,
    affectationId = null,
  } = props;
  const lang = useLanguage('MEDIAS');
  const dispatch: ApiDispatch = useDispatch();
  const medias = useSelector((state) => selectMedias(state, !!device));
  const [mediaplayerActive, setMediaplayerActive] = useToggle(!!device && device.mediaplayer_active);
  const [loading, toggleLoading] = useToggle(false);
  const [submitting, toggleSubmitting] = useToggle(false);

  const tableHeight = asForm ? 208 : 366;

  const {formValues} = useReduxForm(formName, {
    initialValues: {medias: [], deletedMedias: []},
  });

  useEffect(() => {
    const action = device
      ? getLocalMedias({client_id: clientId, device_affectation_id: affectationId})
      : getGlobalMedias({client_id: clientId});
    toggleLoading();
    dispatch(action).then(toggleLoading).catch(toggleLoading);
  }, [toggleLoading, dispatch, clientId, affectationId, device]);

  useEffect(() => {
    initialize({medias, deletedMedias: []});
  }, [level, medias, initialize]);

  const handleSave = useCallback(
    async (values: MediasConfigValues) => {
      toggleSubmitting();

      const actions = [];

      values.deletedMedias.forEach((media) => {
        actions.push(deleteMedia(media.id));
      });

      values.medias.forEach((media) => {
        const data = {
          media_file: {
            ...media,
            media_configurations: [
              {
                id: media.level_configuration && media.level_configuration.id,
                duration: media.duration,
                loops_number: media.loops,
                active: media.active,
              },
            ],
          },
        };

        actions.push(updateMedia(media.id, data));
      });

      if (device && device.mediaplayer_active !== mediaplayerActive) {
        actions.push(
          updateDevicesExternal(device.id, {
            device: {
              mediaplayer_active: mediaplayerActive,
            },
          }),
        );
      }

      for (const action of actions) {
        await dispatch(action);
      }

      toggleSubmitting();
      if (onSave) {
        onSave();
      }
      onClose();
    },
    [device, dispatch, mediaplayerActive, onClose, onSave, toggleSubmitting],
  );

  const handleDiscard = useCallback(() => {
    const newMedias = [
      ...formValues.medias.filter(({isNew}) => isNew),
      ...formValues.deletedMedias.filter(({isNew}) => isNew),
    ];

    newMedias.forEach((media: MediaFile) => {
      dispatch(deleteMedia(media.id));
    });

    onClose();
  }, [formValues, dispatch, onClose]);

  const pushMedia = useCallback(
    (media) => {
      formValues.medias.forEach((item: MediaFile) => {
        item.default_order += 1;
      });

      const newMedias = [{...media, isNew: true}, ...formValues.medias];

      change('medias', newMedias);
    },
    [formValues.medias, change],
  );

  const content = (
    <form onSubmit={handleSubmit(handleSave)}>
      <div className={b('header')}>
        <div className={b('title')}>
          <Icon className={b('player-icon')} name="media_player" height={28} width={28} />
          <p className={b('subtitle')}>{device ? lang.DEVICE_PROGRAMMING : lang.PROGRAMMING}</p>
        </div>
        <div>
          <p className={b('total')}>{lang.TOTAL_DURATION}</p>
          <p className={b('duration')}>{getTotalDurations(formValues.medias)}</p>
        </div>
      </div>
      <div className={b('body')}>
        <div className={b('row')}>
          {!!device && (
            <div className={b('toggle')}>
              <ToggleInput
                onChange={setMediaplayerActive}
                color="primary"
                label={lang.TOGGLE_OFF}
                checked={mediaplayerActive}
                rounded
              />
              <span>{lang.TOGGLE_ON}</span>
            </div>
          )}
          <MediasAddButton
            onChange={pushMedia}
            device={device}
            className={b('button-add')}
            affectationId={affectationId}
          />
        </div>
        <CustomScrollbars
          scrollbarProps={{autoHeightMax: tableHeight, autoHeightMin: tableHeight}}
          horTrackCssClass={b('hor-scroll')}
        >
          <MediasConfigTable
            formValues={formValues}
            isLocal={!!device}
            change={change}
            loading={loading}
            wide={asForm}
          />
        </CustomScrollbars>
      </div>
      <div className={b('footer')}>
        <button type="button" className={b('button-discard')} onClick={handleDiscard} disabled={submitting}>
          {lang.DISCARD}
        </button>
        <button className={b('button-save')} type="submit" disabled={submitting}>
          {lang.SAVE}
        </button>
      </div>
    </form>
  );

  return asForm ? (
    content
  ) : (
    <Modal className={b()} onClose={onClose} isCloseHidden>
      {content}
    </Modal>
  );
};

export default reduxForm<MediasConfigModalProps, MediasConfigValues>({
  form: formName,
})(MediasConfigModal);
