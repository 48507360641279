import React, {CSSProperties, useState} from 'react';

import GoogleMapReact, {ChangeEventValue} from 'google-map-react';

import {GOOGLE_MAPS_KEY, PARIS_COORDINATES} from 'client/common/config';

import GoogleMapMarker, {GoogleMapMarkerStyle} from './google-map-marker';

export type MarkerType<TData = any> = {
  lat: number;
  lng: number;
  title?: string;
  id: number;
  style?: GoogleMapMarkerStyle;

  /**
   * Data - any data which will be provided to renderMarker. It can be helpful for marker customization
   */
  data: TData;
};

export type GoogleMapInstance = ChangeEventValue;

export type GoogleMapProps = {
  center?: {
    lat: number;
    lng: number;
  };
  mapContainerStyle?: CSSProperties;
  zoom?: number;
  markers?: MarkerType[];
  markerType?: 'pin' | 'circle';

  /**
   * renderMarker - method for rendering custom marker
   * @param marker - marker item
   * @param map - primary data of map (center, zoom, etc.)
   */
  renderMarker?: (marker: MarkerType<any>, map: GoogleMapInstance) => React.ReactNode;
  defaultMarkerStyle?: GoogleMapMarkerStyle;
  googleOptions?: GoogleMapReact.MapOptions;
  fitBounds?: boolean;
};

const GoogleMap: React.FC<GoogleMapProps> = (props) => {
  const {
    googleOptions,
    markers,
    center = markers?.[0] || PARIS_COORDINATES,
    zoom,
    defaultMarkerStyle,
    markerType = 'pin',
    renderMarker,
    fitBounds,
  } = props;

  const [map, setMap] = useState<ChangeEventValue>();
  const [isMarkersVisible, setIsMarkersVisible] = useState(true);

  const handleZoomStart = () => {
    setIsMarkersVisible(false);
  };
  const handleZoomEnd = () => {
    setIsMarkersVisible(true);
  };

  const onLoaded = (config: {map: any; maps: any}) => {
    const {maps} = config;
    if (markers && markers?.length > 1 && fitBounds) {
      const bounds = new maps.LatLngBounds();

      markers.forEach((marker) => {
        bounds.extend(new maps.LatLng(marker.lat, marker.lng));
      });

      config.map?.fitBounds(bounds);
    }
  };

  return (
    <GoogleMapReact
      center={center || markers?.[0]}
      defaultZoom={zoom || ((markers?.length || 0) > 1 ? 5 : 11)}
      onZoomAnimationStart={handleZoomStart}
      onZoomAnimationEnd={handleZoomEnd}
      bootstrapURLKeys={{key: GOOGLE_MAPS_KEY as string}}
      onChange={setMap}
      onGoogleApiLoaded={onLoaded}
      options={{
        fullscreenControl: false,
        ...googleOptions,
      }}
    >
      {isMarkersVisible &&
        map &&
        markers?.map((marker) => {
          return (
            <GoogleMapMarker
              markerType={markerType}
              renderMarker={(data) => renderMarker?.(data, map)}
              defaultMarkerStyle={defaultMarkerStyle}
              marker={marker}
              key={marker.id}
              {...marker}
            />
          );
        })}
    </GoogleMapReact>
  );
};

export default GoogleMap;
