import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {TranslationJsx} from 'client/models/language/types';

import RadioInput from '../radio-input';

import './radio-group-input.scss';

const b = bem('radio-group-input');

const RadioGroupInput = (props) => {
  const {
    /** @deprecated Use classNames */
    classNameOption,
    name,
    disabled,
    value,
    onChange,
    className,
    color,
    options,
    direction,
    asButton,
    legend,
    classNames,
  } = props;

  const commonRadioInput = (opt) => {
    return (
      <RadioInput
        name={name}
        value={opt.value}
        label={opt.label}
        checked={value === opt.value}
        key={opt.value}
        disabled={disabled || opt.disabled}
        className={cn(classNameOption, classNames?.option, b('option'))}
        color={color}
        asButton={asButton}
      />
    );
  };
  return (
    <fieldset name={name} className={cn(b([direction]), className)} onChange={onChange} disabled={disabled}>
      {legend && <legend className={cn(b('legend'), classNames?.legend)}>{legend}</legend>}

      {options.map(
        (opt) =>
          !opt.exclude &&
          (opt?.children ? (
            <div className={b('wrapper')} key={opt.value}>
              {commonRadioInput(opt)}
              {opt?.children}
            </div>
          ) : (
            commonRadioInput(opt)
          )),
      )}
    </fieldset>
  );
};

RadioGroupInput.defaultProps = {
  name: '',
  disabled: false,
  className: '',
  classNameOption: '',
  color: 'primary',
  direction: 'vertical',
  asButton: false,
  legend: '',
};

export const RadioGroupOptionsType = PropTypes.arrayOf(
  PropTypes.shape({
    label: TranslationJsx.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    exclude: PropTypes.bool,
  }),
);

RadioGroupInput.propTypes = {
  name: PropTypes.string,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  classNameOption: PropTypes.string,
  value: PropTypes.string.isRequired,
  color: PropTypes.oneOf(RadioInput.colorVariants),
  options: RadioGroupOptionsType.isRequired,
  asButton: PropTypes.bool,
  legend: TranslationJsx,
  classNames: PropTypes.object,
};

export default RadioGroupInput;
