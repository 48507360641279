import {useEffect, useMemo} from 'react';

import {productFruits} from 'product-fruits';
import {useSelector} from 'react-redux';

import {selectClientUser} from 'client/ducks/user-clients/selectors';
import {selectUser} from 'client/ducks/user/selectors';
import {selectViewMode} from 'client/ducks/user/selectors';

import {PRODUCT_FRUITS_WORKSPACE_CODE, USER_TYPES} from 'client/common/config';

export default () => {
  const defaultUser = useSelector(selectUser);
  const {client_user: clientUser = {}} = useSelector(selectClientUser);
  const viewMode = useSelector(selectViewMode);

  const user = useMemo(() => {
    return viewMode.on ? clientUser : defaultUser;
  }, [viewMode.on, clientUser, defaultUser]);

  useEffect(() => {
    if (PRODUCT_FRUITS_WORKSPACE_CODE && user.id && user.type === USER_TYPES.CLIENT_USER && user.fruit_product_key) {
      productFruits.init(PRODUCT_FRUITS_WORKSPACE_CODE, user.locale, {
        username: user.id,
        firstname: user.first_name,
        lastname: user.last_name,
        hmac: {
          hash: user.fruit_product_key,
          expiration: user.fruit_product_key_expired_at,
        },
      });
    }
  }, [
    user.id,
    user.type,
    user.locale,
    user.first_name,
    user.last_name,
    user.fruit_product_key,
    user.fruit_product_key_expired_at,
  ]);
};
