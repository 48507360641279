import sortBy from 'lodash/sortBy';

import {ParticipationColumn, ParticipationColumnAdapter} from 'client/models/participations/types';

export const filterColumnGroups = (
  initialColumnAdapters: Record<string, ParticipationColumnAdapter[]>,
  selectedColumns: ParticipationColumn[],
) => {
  const result: {
    grouped: Record<string, ParticipationColumnAdapter[]>;
    adapters: ParticipationColumnAdapter[];
  } = {grouped: {}, adapters: []};

  const filteredGroups: Record<string, ParticipationColumnAdapter[]> = {...initialColumnAdapters};

  const participationSorted = sortBy(initialColumnAdapters.Participation || [], ['name']);
  const scenarioSorted = sortBy(initialColumnAdapters.ScenarioExecution || [], ['name']);
  filteredGroups.Participation = participationSorted;
  filteredGroups.ScenarioExecution = scenarioSorted;

  Object.entries(filteredGroups).forEach((entry) => {
    const [adapterGroup, adapters] = entry;

    filteredGroups[adapterGroup] = adapters.filter((adapter) => {
      return !selectedColumns.some((column) => adapter.id === column.column_adapter_id);
    });

    if (!filteredGroups[adapterGroup]?.length) {
      delete filteredGroups[adapterGroup];
    }
    result.adapters.push(...(filteredGroups[adapterGroup] || []));
  });

  result.grouped = filteredGroups;

  return result;
};
