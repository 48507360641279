export const DEFAULT_COLOR = '#DCE1EA';
export const NA_VALUE = 'N/A';

export const CHART_COLORS = [
  'rgb(152, 234, 145)',
  'rgb(137, 182, 249)',
  'rgb(244, 160, 251)',
  'rgb(246, 217, 118)',
  'rgb(133, 137, 241)',
  'rgb(254, 145, 145)',
  'rgb(200, 134, 210)',
];
