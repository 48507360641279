import React, {memo} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Icon from 'client/components/common/icon';

import './medias-type-cell.scss';

const b = bem('medias-type-cell');

const MediasTypeCell = memo((props) => {
  const {item, level} = props;
  const locked = item.level !== level && item.locked;

  return (
    <div className={b({locked})}>
      <Icon className={b('icon')} name={`media-${item.media_type}`} />
      <span className={b('extension')}>{item.file_extension}</span>
    </div>
  );
});

MediasTypeCell.propTypes = {
  item: PropTypes.object.isRequired,
  level: PropTypes.string.isRequired,
};

export default MediasTypeCell;
