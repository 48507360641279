import React from 'react';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons';
import Icon from 'client/common/icon';

import {EmailTemplate} from 'client/models/email-templates/types';

import cssModule from './templates-card.module.scss';

const b = bem('template-card', {cssModule});

type TemplateCardProps = {
  template: EmailTemplate;
  selected: boolean;
  onClick: () => void;
  onDoubleClick: () => void;
};

const TemplateCard: React.FC<TemplateCardProps> = ({template, onClick, selected, onDoubleClick}) => {
  return (
    <AppButton
      className={b({selected})}
      asWrap={true}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      label={
        <>
          <div className={b('image-wrapper')}>
            <img src={template.preview_image_url} alt="" />
            <Icon name="search-1" className={b('icon')} width={48} height={48} />
          </div>
          <span className={b('name')}>{template.name}</span>
        </>
      }
    />
  );
};

export default TemplateCard;
