import {Translation} from 'client/models/language/types';
import {Prize} from 'client/models/prizes/types';

import {WinParamsValues} from './types';

export default (prize: Prize | null, languages: string[]): WinParamsValues => {
  const defaultLanguage = languages[0];

  const getTranslations = (
    prefix: 'external_name' | 'external_description' | 'email_image' | 'legal' | 'picto',
    includeImageId = false,
  ) => {
    return languages.reduce((result: Record<string, any>, lang) => {
      if (includeImageId) {
        result[`${lang}_id`] = prize?.[`${prefix}_translations`]?.[`${lang}_id`] || 0;
      }

      result[lang] = (prize?.[`${prefix}_translations`]?.[lang] || '') as Translation;

      return result;
    }, {});
  };

  return {
    language: defaultLanguage,
    defaultLanguage,
    langUniqueMode: prize?.language_unique_mode || false,
    name: getTranslations('external_name'),
    description: getTranslations('external_description'),
    image: getTranslations('email_image', true),
    picto: getTranslations('picto', true),
    legal: getTranslations('legal'),
  };
};
