import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import Modal from 'client/common/modals/modal';

import PlacesStoresMap from './places-stores-map';

import cssModule from './places-stores-map-modal.module.scss';

const b = bem('places-stores-map-modal', {cssModule});

function PlacesStoresMapModal({show, onClose, lang, places}) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      dialogClassName={b()}
      contentClassName={b('content')}
      bodyClassName={b('body')}
    >
      <PlacesStoresMap places={places} languageState={lang} />
    </Modal>
  );
}

PlacesStoresMapModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  places: PropTypes.array.isRequired,
  lang: PropTypes.object.isRequired,
};

export default connect(({languageState}) => ({
  lang: languageState.payload.PLACES,
}))(PlacesStoresMapModal);
