import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useMount} from 'react-use';

import {setEmail} from 'client/services/cookie-data-source';
import {post} from 'client/services/fetch';
import {useQueryParams} from 'client/services/hooks';

import AwaitContainer from 'client/common/await-container';
import {API_METHODS} from 'client/common/config';

import AuthChangePasswordPasswordForm from './auth-change-password-form';

const AuthChangePassword = () => {
  const history = useHistory();
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const dispatch = useDispatch();
  const [queryParams] = useQueryParams({history, location: history.location});
  const {email, reset_password_token: resetPasswordToken} = queryParams;
  const [loading, setLoading] = useState(true);

  useMount(() => {
    setEmail(email);
  });

  useEffect(() => {
    setLoading(true);
    post(API_METHODS.VERIFY_TOKEN, {
      token_type: 'reset_password_token',
      token: resetPasswordToken,
    })
      .then((res) => {
        if (res) {
          setIsLinkExpired(!res.token_valid);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, resetPasswordToken]);

  return (
    <AwaitContainer loading={loading}>
      <AuthChangePasswordPasswordForm isLinkExpired={isLinkExpired} />
    </AwaitContainer>
  );
};

AuthChangePassword.displayName = 'AuthChangePassword';

AuthChangePassword.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AuthChangePassword;
