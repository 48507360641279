import React from 'react';

import PropTypes from 'prop-types';

import AppButton from 'client/common/buttons';

import {TranslationJsx} from 'client/models/language/types';

class FacebookLogin extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    callback: PropTypes.func.isRequired,
    appId: PropTypes.string.isRequired,
    xfbml: PropTypes.bool,
    cookie: PropTypes.bool,
    reAuthenticate: PropTypes.bool,
    scope: PropTypes.string,
    returnScopes: PropTypes.bool,
    redirectUri: PropTypes.string,
    textButton: TranslationJsx,
    autoLoad: PropTypes.bool,
    size: PropTypes.string,
    fields: PropTypes.string,
    cssClass: PropTypes.string,
    version: PropTypes.string,
    pageId: PropTypes.string,
    language: PropTypes.string,
    onClick: PropTypes.func,
    onFailure: PropTypes.func,
  };

  static defaultProps = {
    textButton: 'Login with Facebook',
    redirectUri: window.location.href,
    scope: 'manage_pages,publish_pages',
    returnScopes: true,
    xfbml: false,
    cookie: false,
    reAuthenticate: false,
    size: 'metro',
    fields: 'name',
    cssClass: 'kep-login-facebook',
    version: '2.10',
    language: 'en_US',
    onFailure: null,
  };

  state = {
    isSdkLoaded: false,
    isProcessing: false,
  };

  componentDidMount() {
    this._isMounted = true;
    if (document.getElementById('facebook-jssdk')) {
      this.sdkLoaded();
      return;
    }
    this.setFbAsyncInit();
    this.loadSdkAsynchronously();
    let fbRoot = document.getElementById('fb-root');
    if (!fbRoot) {
      fbRoot = document.createElement('div');
      fbRoot.id = 'fb-root';
      document.body.appendChild(fbRoot);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.isSdkLoaded && this.props.autoLoad && !prevProps.autoLoad) {
      window.FB.getLoginStatus(this.checkLoginAfterRefresh);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setStateIfMounted(state) {
    if (this._isMounted) {
      this.setState(state);
    }
  }

  setFbAsyncInit() {
    const {appId, xfbml, version, autoLoad} = this.props;
    window.fbAsyncInit = () => {
      window.FB.init({
        appId,
        autoLogAppEvents: true,
        xfbml,
        version: 'v' + version,
      });
      this.setStateIfMounted({isSdkLoaded: true});
      if (autoLoad || window.location.search.includes('facebookdirect')) {
        window.FB.getLoginStatus(this.checkLoginAfterRefresh);
      }
    };
  }

  responseApi = () => {
    // fix api call
    window.FB.api('/me', {}, (me) => {
      this.props.callback(me);
    });
  };

  checkLoginState = (response) => {
    // ID: 100003793065441
    this.setStateIfMounted({isProcessing: false});
    if (response.authResponse) {
      this.responseApi(response);
    } else if (this.props.onFailure) {
      this.props.onFailure({status: response.status});
    } else {
      this.props.callback({status: response.status});
    }
  };

  checkLoginAfterRefresh = (response) => {
    if (response.status === 'connected') {
      this.checkLoginState(response);
    } else {
      window.FB.login(this.checkLoginState, {scope: this.props.scope, return_scopes: this.props.returnScopes});
    }
  };

  click = (e) => {
    if (!this.state.isSdkLoaded || this.state.isProcessing || this.props.disabled) {
      return;
    }
    this.setState({isProcessing: true});
    const {scope, appId, onClick, reAuthenticate, returnScopes, redirectUri} = this.props;

    if (typeof onClick === 'function') {
      onClick(e);
      if (e.defaultPrevented) {
        return;
      }
    }

    const params = {
      client_id: appId,
      redirect_uri: redirectUri,
      state: 'facebookdirect',
      return_scopes: returnScopes,
      scope,
    };

    if (reAuthenticate) {
      params.auth_type = 'reauthenticate';
    }

    window.FB.login(this.checkLoginState, {scope, return_scopes: returnScopes});
  };

  loadSdkAsynchronously() {
    const {language} = this.props;
    ((d, s, id) => {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.src = `https://connect.facebook.net/${language}/sdk.js`;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  sdkLoaded() {
    this.setState({isSdkLoaded: true});
  }

  render() {
    const {cssClass, size, textButton, disabled} = this.props;
    return <AppButton label={textButton} className={`${cssClass} ${size}`} onClick={this.click} disabled={disabled} />;
  }
}

export default FacebookLogin;
