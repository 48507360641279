import React from 'react';

import PropTypes from 'prop-types';

import Popover from 'client/common/popovers/popover';

import CustomLink from 'client/components/common/custom-link';

import {TranslationJsx} from 'client/models/language/types';

function ParticipationsAllColumn(props) {
  const {count, participations, onClick, showMoreText} = props;

  const isParticipations = participations && !!participations.length;
  const participationsSlice = participations.slice(0, 3);

  return (
    <div className="bc-mailing-data-grid__participations-all">
      {isParticipations && (
        <Popover
          position="bottom"
          className="pull-left"
          arrowOffset={35}
          overlayInnerStyle={{
            width: 210,
          }}
          overlay={
            <div className="popover-list theme-color-16">
              {count > 3 && (
                <h3 className="popover-list__title clearfix">
                  <div onClick={onClick} className="popover-list__head-link cursor-pointer">
                    {showMoreText}
                  </div>
                </h3>
              )}
              <div className="custom-popover-new__scroll-wrap">
                <ul className="popover-list__list">
                  {participationsSlice.map((p, index) => {
                    const {
                      created_at: createdAt,
                      updated_at: updatedAt,
                      operation_code: code,
                      client_type: clientType,
                      client_id: clientId,
                      operation_id: operationId,
                      interface_name: interfaceName,
                    } = p;
                    const link = `${CustomLink.createOperationLink({clientType, clientId, operationId})}`;
                    return (
                      <li className="popover-list__list" key={index}>
                        <CustomLink to={link} className="display-block ellipsis-text link main-text main-text--upper">
                          {code}
                        </CustomLink>
                        <div className="main-text main-text--small main-text--color-1">{createdAt}</div>
                        <div className="main-text main-text--small main-text--color-1">{updatedAt}</div>
                        <div className="main-text main-text--small main-text--color-1 ellipsis-text">
                          {interfaceName}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          }
        >
          {count}
        </Popover>
      )}
      {!isParticipations && <span>{count}</span>}
    </div>
  );
}

ParticipationsAllColumn.propTypes = {
  showMoreText: TranslationJsx.isRequired,
  count: PropTypes.number.isRequired,
  participations: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ParticipationsAllColumn;
