import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import types from './types';

export function createLeadsExportFile(body, queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.FILE_LEAD_EXPORT}`,
        method: 'POST',
        types: [
          types.CREATE_FILE_LEADS_EXPORT_REQUEST,
          types.CREATE_FILE_LEADS_EXPORT_SUCCESS,
          types.CREATE_FILE_LEADS_EXPORT_ERROR,
        ],
        body,
        queryParams,
      },
      true,
    );
  };
}

export function getLeadsExportFile(id, queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.FILE_LEAD_EXPORT}/${id}${API_METHODS.PREFORM}`,
        method: 'GET',
        types: [
          types.GET_FILE_LEADS_EXPORT_REQUEST,
          types.GET_FILE_LEADS_EXPORT_SUCCESS,
          types.GET_FILE_LEADS_EXPORT_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}

export function getApiLeadExports(queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.API_LEAD_EXPORT}`,
        method: 'GET',
        noCache: true,
        types: [
          types.GET_API_LEAD_EXPORTS_REQUEST,
          types.GET_API_LEAD_EXPORTS_SUCCESS,
          types.GET_API_LEAD_EXPORTS_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}

export function apiLeadsExport(body) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.API_LEAD_EXPORT}`,
        method: 'POST',
        types: [types.API_LEADS_EXPORT_REQUEST, types.API_LEADS_EXPORT_SUCCESS, types.API_LEADS_EXPORT_ERROR],
        body,
      },
      true,
    );
  };
}

export function updateApiLeadsExport(id, body) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.API_LEAD_EXPORT}/${id}`,
        method: 'PATCH',
        types: [
          types.UPDATE_API_LEADS_EXPORT_REQUEST,
          types.UPDATE_API_LEADS_EXPORT_SUCCESS,
          types.UPDATE_API_LEADS_EXPORT_ERROR,
        ],
        body,
      },
      true,
    );
  };
}

export function deleteApiLeadsExport(id) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.API_LEAD_EXPORT}/${id}`,
        method: 'DELETE',
        types: [
          types.DELETE_API_LEADS_EXPORT_REQUEST,
          types.DELETE_API_LEADS_EXPORT_SUCCESS,
          types.DELETE_API_LEADS_EXPORT_ERROR,
        ],
      },
      true,
    );
  };
}

export function getLeadsExportReport(id, queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEAD_EXPORT_REPORTS}/${id}`,
        method: 'GET',
        noCache: true,
        types: [
          types.GET_LEADS_EXPORT_REPORT_REQUEST,
          types.GET_LEADS_EXPORT_REPORT_SUCCESS,
          types.GET_LEADS_EXPORT_REPORT_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}

export function deleteLeadsExportFile(id) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEAD_EXPORT_REPORTS}/${id}`,
        method: 'PATCH',
        types: [
          types.DELETE_API_LEADS_EXPORT_REQUEST,
          types.DELETE_API_LEADS_EXPORT_SUCCESS,
          types.DELETE_API_LEADS_EXPORT_ERROR,
        ],
        body: {
          lead_export_report: {
            remove_leads_file: true,
          },
        },
      },
      true,
    );
  };
}

export function getLeadsExportReports(queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEAD_EXPORT_REPORTS}`,
        method: 'GET',
        noCache: true,
        types: [
          types.GET_LEADS_EXPORT_REPORTS_REQUEST,
          types.GET_LEADS_EXPORT_REPORTS_SUCCESS,
          types.GET_LEADS_EXPORT_REPORTS_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}

export const deleteFileLeadExport = (id) => {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.FILE_LEAD_EXPORT}/${id}`,
      method: 'DELETE',
      noCache: true,
      types: [
        types.DELETE_FILE_LEAD_EXPORT_REQUEST,
        types.DELETE_FILE_LEAD_EXPORT_SUCCESS,
        types.DELETE_FILE_LEAD_EXPORT_ERROR,
      ],
    });
  };
};

export const shareLeadExport =
  ({id, queryParams} = {}) =>
  (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.FILE_LEAD_EXPORT}/${id}/manage_sharing`,
      method: 'POST',
      types: [types.SHARE_LEAD_EXPORT_REQUEST, types.SHARE_LEAD_EXPORT_SUCCESS, types.SHARE_LEAD_EXPORT_ERROR],
      queryParams,
    });
