import React from 'react';

import cn from 'classnames';
import uniq from 'lodash/uniq';
import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectIsAdmin} from 'client/ducks/user/selectors';

import RadioButtonGroup from 'client/common/button-group/radio-button-group';
import AppButton from 'client/common/buttons';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import SelectDropdown from 'client/common/selects/select-dropdown';

import PreviewModal from 'client/components/email-template-editor/modals/preview-modal';
import TemplatesGalleryModal from 'client/components/email-template-editor/modals/templates-gallery-modal';
import {EmailTemplateKind} from 'client/models/email-templates/types';

import cssModule from './action-bar.module.scss';

const b = bem('actions-bar', {cssModule});

type ActionsBarProps = {
  className?: string;
  currentKind?: EmailTemplateKind | null;
  kinds: EmailTemplateKind[];
  onChangeKind: (kindId: number) => void;
  currentLanguage: string;
  languages: string[];
  onChangeLanguage: (language: string) => void;
  onSave: () => void;
  onRestore: () => Promise<void>;
  loading?: boolean;
  disabled?: boolean;
  hasChanges: boolean;
  hideGallery?: boolean;
};

const ActionsBar: React.FC<ActionsBarProps> = (props) => {
  const {
    className,
    currentKind,
    kinds,
    onChangeKind,
    currentLanguage,
    languages,
    onChangeLanguage,
    onSave,
    onRestore,
    loading,
    disabled,
    hasChanges,
    hideGallery,
  } = props;

  const isAdmin = useSelector(selectIsAdmin);
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.BARS.ACTIONS_BAR');
  const [showPreviewModal, togglePreviewModal] = useToggle(false);
  const [showPreviewConfirmation, togglePreviewConfirmation] = useToggle(false);
  const [showRestoreConfirmation, toggleRestoreConfirmation] = useToggle(false);
  const [showTemplatesGalleryModal, toggleTemplatesGalleryModal] = useToggle(false);

  const isClient = !isAdmin;

  return (
    <div className={cn(b(), className)}>
      {showPreviewModal && <PreviewModal onClose={togglePreviewModal} isClient={isClient} />}
      <ConfirmationModal
        show={showRestoreConfirmation}
        title={lang.RESTORE_CONFIRMATION.TITLE}
        message={lang.RESTORE_CONFIRMATION.MESSAGE}
        cancelText={lang.RESTORE_CONFIRMATION.CANCEL}
        onClose={toggleRestoreConfirmation}
        onCancel={toggleRestoreConfirmation}
        confirmText={lang.RESTORE_CONFIRMATION.RESTORE}
        onConfirm={() => {
          onRestore();
          toggleRestoreConfirmation();
        }}
        clientSide
      />
      <ConfirmationModal
        show={showPreviewConfirmation}
        message={lang.PREVIEW_CONFIRMATION.MESSAGE}
        cancelText={lang.PREVIEW_CONFIRMATION.NO}
        onClose={togglePreviewConfirmation}
        onCancel={togglePreviewConfirmation}
        confirmText={lang.PREVIEW_CONFIRMATION.YES}
        onConfirm={async () => {
          togglePreviewConfirmation();
          await onSave();
          togglePreviewModal();
        }}
        clientSide
      />
      {showTemplatesGalleryModal && (
        <TemplatesGalleryModal
          onClose={toggleTemplatesGalleryModal}
          currentLanguage={currentLanguage}
          fetchData={onRestore}
        />
      )}
      <div className={b('column')}>
        <SelectDropdown
          plainly
          value={currentKind?.id}
          onChange={onChangeKind}
          placeholder={lang.CHOOSE_ACTIVE_TEMPLATE_PLACEHOLDER}
          selectClassName={b('template-name')}
          options={kinds.map((el) => ({label: el.type_name, value: el.id}))}
          classNames={{
            singleValue: () => b('template-name-value'),
          }}
          disabled={disabled}
          simpleValue
        />
      </div>

      <div className={b('column')}>
        {!hideGallery && (
          <AppButton
            label={lang.TEMPLATES_GALLERY_BUTTON}
            color="light-clients"
            onClick={toggleTemplatesGalleryModal}
            size="medium"
            className={b('button')}
            disabled={disabled}
          />
        )}
      </div>

      <div className={b('column')}>
        {languages.length > 1 && (
          <RadioButtonGroup
            name=""
            radioButtons={uniq(languages).map((el) => ({label: el, value: el}))}
            value={currentLanguage}
            labelClassName={b('language')}
            className={b('languages')}
            onChange={(e) => onChangeLanguage(e.target.value)}
            disabled={disabled}
          />
        )}
        <AppButton
          label={isClient ? lang.PREVIEW_AND_TEST_BUTTON : lang.PREVIEW_AND_TEST_BUTTON_ADMIN}
          color="light-clients"
          iconName="eye-opened"
          className={b('button')}
          size="large"
          onClick={() => {
            if (hasChanges) {
              togglePreviewConfirmation();
            } else {
              togglePreviewModal();
            }
          }}
          disabled={disabled}
        />
        <AppButton
          label={lang.RESTORE_BUTTON}
          color="light-clients"
          className={b('button')}
          size="large"
          onClick={toggleRestoreConfirmation}
          disabled={disabled}
        />
        <AppButton
          label={lang.SAVE_BUTTON}
          className={b('button')}
          size="large"
          onClick={onSave}
          disabled={disabled}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ActionsBar;
