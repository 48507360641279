import React, {useMemo} from 'react';

import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {dateToString, timeToString} from 'client/services/helpers';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container';

import './animation-interval-fieldset.scss';

const b = bem('animation-interval-fieldset');

const AnimationIntervalFieldset = ({readOnly}) => {
  const lang = useLanguage('ANIMATIONS.MODALS.ANIMATION_MAIN_MODAL.TABS.GENERAL');
  const {formValues = {}} = useReduxForm(AnimationConfigContainerFormName);
  const {animation_days = []} = formValues;

  const sortedAnimationDays = useMemo(() => orderBy(animation_days, 'from', 'asc'), [animation_days]);
  const fromDate = sortedAnimationDays[0]?.from;
  const endDate = last(sortedAnimationDays)?.to;

  const fromString = timeToString(fromDate, 'HH:mm');
  const toString = timeToString(endDate, 'HH:mm');

  return (
    <div className={b()}>
      <p className={b('title', {light: readOnly})}>{lang.ANIMATION}</p>
      <div className={b('period')}>
        {fromString && (
          <p className={b('date')}>
            <span className={b('date-caption')}>{lang.FROM}</span>
            <span className={b('date')}>{dateToString(fromDate)}</span>
            <span className={b('time')}>{fromString}</span>
          </p>
        )}
        {toString && (
          <p className={b('date')}>
            <span className={b('date-caption')}>{lang.TO}</span>
            <span className={b('date')}>{dateToString(endDate)}</span>
            <span className={b('time')}>{toString}</span>
          </p>
        )}
      </div>
    </div>
  );
};

AnimationIntervalFieldset.propTypes = {
  readOnly: PropTypes.bool.isRequired,
};

export default AnimationIntervalFieldset;
