import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {useLanguage} from 'client/services/hooks';

import {set} from 'client/common/meta/meta.actions';

import LeadsParameters from 'client/components/leads/leads-parameters';

const LeadsParametersPage = () => {
  const lang = useLanguage('LEADS_PARAMETERS');
  const dispatch = useDispatch();
  const params: {clientId: string} = useParams();

  useEffect(() => {
    dispatch(set({title: lang.TITLE}));
  }, [dispatch, lang.TITLE]);

  return <LeadsParameters clientId={+params.clientId} />;
};

export default LeadsParametersPage;
