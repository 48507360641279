import {Reducer} from 'redux';

import {Client} from 'client/models/client/types';
import {PaginationMeta, getDefaultMeta} from 'client/types/common/index';

import types from './types';

type State = {
  allAgencies: Client[];
  allAgencyClients: Client[];
  agencyClient: Client | null;
  agencyClients: {
    items: Client[];
    meta: PaginationMeta;
  };
};

const initialState: State = {
  allAgencies: [],
  allAgencyClients: [],
  agencyClient: null,
  agencyClients: {
    items: [],
    meta: getDefaultMeta(),
  },
};

const reducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_AGENCIES_SUCCESS:
      return {
        ...state,
        allAgencies: action.payload.agencies,
      };

    case types.GET_AGENCY_CLIENTS_SUCCESS:
      return {
        ...state,
        agencyClients: {
          items: action.payload.companies,
          meta: action.payload.meta,
        },
      };

    case types.GET_ALL_AGENCY_CLIENTS_SUCCESS:
      return {
        ...state,
        allAgencyClients: action.payload.companies,
      };

    case types.GET_AGENCY_CLIENT_SUCCESS:
      return {
        ...state,
        agencyClient: action.payload.client,
      };

    default:
      return state;
  }
};

export default reducer;
