import React, {Component} from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {fetchOnlineInteractions, fetchOfflineInteractions} from 'client/ducks/interactions/actions';
import {selectOnlineInteractions, selectOfflineInteractions} from 'client/ducks/interactions/selectors';
import {getOptInsAction} from 'client/ducks/opt-in-columns/actions';
import {selectOptInsForFilterModal} from 'client/ducks/opt-in-columns/selectors';
import {getPrizeNames} from 'client/ducks/prizes/actions';

import Modal from 'client/common/modals/modal';

import AsyncDropdownAny from 'client/components/common/async-dropdown-any';
import CustomSelect from 'client/components/common/custom-select';
import Datepicker from 'client/components/common/datepicker';

import {mapInteractionsToOptions} from 'client/components/participants/participants-list/helper';

import './winners-filters-modal.scss';

const isFilterEmpty = (filter) => {
  return Object.keys(filter).every((item) => !filter[item]);
};

const checkFilterValue = (filters, groupName, groupKey, value) => {
  const checkedValue = get(filters, [groupName, groupKey], null);
  if (groupName && !isNull(checkedValue)) {
    return checkedValue;
  }
  return !isUndefined(value) ? filters[groupKey] === value : filters[groupKey];
};

const setValue = (value) => {
  if (isNull(value)) {
    return value;
  }
  return typeof value === 'string' ? value : !value;
};

const getPrizeNamesSelection = (filters, prizeNames) => {
  const selection = {
    not: true,
  };
  prizeNames.forEach(({value}) => {
    selection[value] = filters[value] || false;
  });
  if (Object.keys(selection).some((item) => item !== 'not' && selection[item])) {
    selection.not = false;
  }
  return selection;
};

const convertFilters = ({phone, email, visuals, gender, origin, optIn, ...filters}, prizes) => ({
  phone,
  email,
  visuals,
  origin,
  optIn,
  participated: {
    not: !filters.participated,
    participated: filters.participated,
    participatedFrom: filters.participatedFrom,
    participatedTo: filters.participatedTo,
  },
  gender,
  optInRadio: {
    not: !filters.optInRadio && filters.optInRadio !== 'no_active',
    optInRadio: filters.optInRadio === true,
    no_active: filters.optInRadio === 'no_active',
  },
  prizeTypes: getPrizeNamesSelection(filters, prizes),
  prizes: filters.prizes,
});

export class FiltersModal extends Component {
  static propTypes = {
    instantApply: PropTypes.bool,
    language: PropTypes.object.isRequired,
    filters: PropTypes.object,
    options: PropTypes.shape({
      scenarios: PropTypes.array,
    }),
    totalResults: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
    onlineInteractions: PropTypes.array.isRequired,
    getOnlineInteractions: PropTypes.func.isRequired,
    offlineInteractions: PropTypes.array.isRequired,
    getOfflineInteractions: PropTypes.func.isRequired,
    getPrizeNames: PropTypes.func.isRequired,
    getPlaces: PropTypes.func.isRequired,
    getCampaigns: PropTypes.func.isRequired,
    getOptIns: PropTypes.func.isRequired,
    prizeNames: PropTypes.array,
    places: PropTypes.array,
    campaigns: PropTypes.array,
    optIns: PropTypes.array,
    taskId: PropTypes.number,
    clientId: PropTypes.number,
  };

  static defaultProps = {
    instantApply: true,
  };

  constructor(props) {
    super(props);
    const {filters} = props;
    this.state = {
      filters: convertFilters(filters, props.prizeNames),
    };
  }

  componentDidMount() {
    this.fetchInteractions();
    this.props.getPrizeNames(this.props.taskId).then(({payload}) => {
      const names = orderBy(payload.participation_prizes.available_prize_names, (item) => item, ['asc']);

      this.setState({
        prizes: [
          {label: 'None', value: 'none'},
          ...names.map((name, idx) => ({
            label: name,
            value: name,
            id: idx + 1,
          })),
        ],
      });
    });
  }

  fetchInteractions = () => {
    const commonParams = {
      q: {interaction_group_automation_task_id_eq: this.props.taskId},
    };

    this.props.getOnlineInteractions({
      ...commonParams,
      include: ['source'],
    });

    this.props.getOfflineInteractions({
      ...commonParams,
      include: ['place'],
    });
  };

  getSourcesOptions = () => {
    const {onlineInteractions, offlineInteractions, language} = this.props;
    const {onlineOptions, offlineOptions} = mapInteractionsToOptions(onlineInteractions, offlineInteractions);

    return [
      {value: '0', label: language.DOESNT_MATTER},
      {label: language.SOURCE, options: onlineOptions},
      {label: language.DEVICE, options: offlineOptions},
    ];
  };

  createGroupsConfig = (language) => ({
    attributesGroupConfig: [
      {
        component: 'button',
        fieldName: 'phone',
        type: 'checkbox',
        label: language.PHONE,
        props: {},
      },
      {
        component: 'button',
        fieldName: 'email',
        type: 'checkbox',
        label: language.EMAIL,
        props: {},
      },
    ],
    participatedGroupConfig: [
      {
        component: 'button',
        groupName: 'participated',
        fieldName: 'not',
        type: 'radio',
        label: language.DOESNT_MATTER,
        props: {},
      },
      {
        component: 'button',
        groupName: 'participated',
        fieldName: 'participated',
        type: 'radio',
        label: language.TIME_PERIOD,
        props: {},
      },
      {
        component: 'datepicker',
        groupName: 'participated',
        fieldName: 'participatedFrom',
        props: {
          name: 'participatedFrom',
          label: language.FROM,
          disabled: !this.state.filters.participated.participated,
          onChange: (date) => this.updateFilter({key: 'participatedFrom', value: date, groupName: 'participated'}),
        },
      },
      {
        component: 'datepicker',
        groupName: 'participated',
        fieldName: 'participatedTo',
        props: {
          name: 'participatedTo',
          label: language.TO,
          disabled: !this.state.filters.participated.participated,
          onChange: (date) => this.updateFilter({key: 'participatedTo', value: date, groupName: 'participated'}),
        },
      },
    ],
    genderGroupConfig: [
      {
        component: 'button',
        fieldName: 'gender',
        value: null,
        type: 'radio',
        label: language.DOESNT_MATTER,
        props: {},
      },
      {
        component: 'button',
        fieldName: 'gender',
        value: 'male',
        type: 'radio',
        label: language.MALE,
        props: {},
      },
      {
        component: 'button',
        fieldName: 'gender',
        value: 'female',
        type: 'radio',
        label: language.FEMALE,
        props: {},
      },
    ],
    prizeGroupConfig: [
      {
        component: 'button',
        groupName: 'prizeTypes',
        fieldName: 'not',
        type: 'checkbox',
        label: language.DOESNT_MATTER,
        props: {},
      },
      ...this.props.prizeNames.map(({value, label}) => ({
        component: 'button',
        groupName: 'prizeTypes',
        fieldName: value,
        type: 'checkbox',
        label,
        props: {},
      })),
    ],
    optInsGroupConfig: [
      {
        component: 'button',
        groupName: 'optInRadio',
        fieldName: 'not',
        type: 'radio',
        label: language.DOESNT_MATTER,
        props: {
          onClick: () => {
            this.setState((state) => ({
              filters: {
                ...state.filters,
                optInRadio: {
                  optInRadio: false,
                  no_active: false,
                  not: true,
                },
                optIn: null,
              },
            }));
            this.props.onFiltersChange({
              optInRadio: false,
              optIn: null,
            });
          },
        },
      },
      {
        component: 'button',
        groupName: 'optInRadio',
        fieldName: 'optInRadio',
        type: 'radio',
        label: language.SELECT_OPTINS,
        props: {},
      },
      {
        component: 'button',
        groupName: 'optInRadio',
        fieldName: 'no_active',
        type: 'radio',
        label: language.NO_ACTIVE,
        props: {
          onClick: () => {
            this.setState((state) => ({
              filters: {
                ...state.filters,
                optInRadio: {
                  optInRadio: false,
                  no_active: true,
                  not: false,
                },
                optIn: null,
              },
            }));
            this.props.onFiltersChange({
              optInRadio: 'no_active',
              optIn: null,
            });
          },
        },
      },
    ],
  });

  createGroupComponent = ({component, groupName = null, fieldName, type, label = '', props, value}) => {
    switch (component) {
      case 'button':
        return this.renderFilterButton({fieldName, value, groupName, type, label, props});

      case 'select':
        return (
          <CustomSelect
            key={fieldName}
            name={fieldName}
            value={value}
            cssModifier="winners-filters-modal__filter-selector"
            {...props}
          />
        );

      case 'datepicker':
        const dateValue = groupName ? this.state.filters[groupName][fieldName] : this.state.filters[fieldName];

        return (
          <Datepicker
            key={fieldName}
            name={fieldName}
            value={dateValue}
            cssModifier="winners-filters-modal__filter-datepicker"
            {...props}
          />
        );

      default:
        return null;
    }
  };

  getOptInsOptions = async (search = '') => {
    // if (!search) {
    //   return [];
    // }
    const params = {
      include_opt_in_column_leads_count: null,
      q: {
        leads_count_gt: 0,
        client_id_eq: this.props.clientId,
        name_cont: search,
      },
    };
    if (this.props.taskId) {
      params.q.automation_task_id_eq = this.props.taskId;
    }

    const response = await this.props.getOptIns(params);

    const optIns = response.payload.opt_in_columns;
    return optIns.filter((optIn) => optIn.leads_count > 0).map((i) => ({label: i.name, value: i.id}));
  };

  handleCheckFilterDropdown = (filter) => (selected) => {
    if (selected.group) {
      delete selected.group;
    }

    const excludableValues = ['none', '0'];

    if (
      excludableValues.includes(selected.value) ||
      (Array.isArray(selected) && selected.some((item) => excludableValues.includes(item.value)))
    ) {
      this.setState({
        filters: {
          ...this.state.filters,
          [filter]: null,
        },
      });
      this.props.onFiltersChange({[filter]: null});
    } else {
      this.setState({
        filters: {
          ...this.state.filters,
          [filter]: selected,
        },
      });
      this.props.onFiltersChange({[filter]: selected});
    }
  };

  handleShowResults = () => {
    const {instantApply, onFiltersChange, onClose} = this.props;

    if (!instantApply) {
      onFiltersChange({filters: this.state.filters});
    }

    onClose();
  };

  updateFilter = ({key, value, groupName, type = 'checkbox'}) => {
    let val;
    if (groupName) {
      val = isNull(value) ? value : value || this.state.filters[groupName][key];
      return this.updateGroupFilter(groupName, key, val, type);
    }
    val = value || this.state.filters[key];
    this.setState((state) => ({
      filters: {
        ...state.filters,
        [key]: setValue(isNull(value) ? value : val),
      },
    }));
    return this.props.onFiltersChange({
      [key]: setValue(isNull(value) ? value : val),
    });
  };

  updateGroupFilter = (groupName, key, value, type) => {
    let newGroup = {...get(this.state.filters, groupName, null)};

    if (type === 'radio') {
      Object.keys(newGroup).forEach((groupKey) => {
        newGroup[groupKey] = typeof newGroup[groupKey] === 'boolean' ? false : '';
      });
      newGroup[key] = setValue(value);
      if (isFilterEmpty(newGroup)) {
        newGroup.not = true;
      }
      this.setState((state) => ({
        filters: {
          ...state.filters,
          [groupName]: newGroup,
        },
      }));

      return this.setTableFilter(groupName, key, value);
    }

    // if group of buttons is checkbox type
    if (key === 'not') {
      Object.keys(newGroup).forEach((filterKey) => {
        newGroup.not = true;
        if (filterKey !== 'not') {
          newGroup[filterKey] = typeof newGroup[filterKey] === 'boolean' ? false : '';
        }
      });
    } else {
      newGroup[key] = setValue(value);
      newGroup.not = false;
    }
    return this.setState(
      (state) => ({
        filters: {
          ...state.filters,
          [groupName]: newGroup,
        },
      }),
      () => {
        this.checkGroupForDefaultValue(groupName);
        this.props.onFiltersChange(newGroup);
      },
    );
  };

  setTableFilter = (groupName, key) => {
    const {onFiltersChange} = this.props;
    if (key === 'not') {
      const group = get(this.state.filters, groupName, null);
      const newGroup = {};
      Object.keys(group).forEach((fieldName) => {
        switch (typeof group[fieldName]) {
          case 'boolean':
            newGroup[fieldName] = false;
            break;

          case 'string':
            newGroup[fieldName] = '';
            break;

          default:
            break;
        }
      });
      delete newGroup.not;
      newGroup[groupName] = false;
      if (!(groupName in newGroup)) {
        newGroup[groupName] = true;
      }
      return onFiltersChange(newGroup);
    }
    return onFiltersChange({[groupName]: key});
  };

  checkGroupForDefaultValue = (groupName) => {
    const group = get(this.state.filters, groupName, null);
    if (group && 'not' in group && isFilterEmpty(group)) {
      this.setState(({filters}) => ({
        filters: {
          ...filters,
          [groupName]: {
            ...group,
            not: true,
          },
        },
      }));
    }
  };

  renderFilterButton = ({fieldName, value, groupName, type = 'checkbox', label = '', props}) => {
    const isFilterChecked = checkFilterValue(this.state.filters, groupName, fieldName, value);
    const clickHandler = () => {
      if (props.onClick) {
        return props.onClick();
      }
      return this.updateFilter({key: fieldName, value, groupName, type});
    };

    return (
      <button
        key={label}
        className={cn('winners-filters-modal__filter-item', {
          'winners-filters-modal__filter-item--set': isFilterChecked,
        })}
        onClick={clickHandler}
      >
        {label}
      </button>
    );
  };

  renderFilterAsyncSelector = (item, additionalProps = {}) => {
    return <AsyncDropdownAny className="select--view-2" value={item} {...additionalProps} />;
  };

  renderFilterSelector = (item, options, additionalProps = {}) => {
    return (
      <CustomSelect
        value={item}
        cssModifier="winners-filters-modal__filter-selector"
        options={options}
        {...additionalProps}
      />
    );
  };

  renderFilterGroup = (config) => {
    return (
      <div className="winners-filters-modal__group">
        {config.map((filterElement) => this.createGroupComponent(filterElement))}
      </div>
    );
  };

  render() {
    const {language, totalResults, onClose} = this.props;
    const {
      filters: {origin, prizes: pn, optInRadio, optIn},
      prizes,
    } = this.state;
    const {attributesGroupConfig, participatedGroupConfig, genderGroupConfig, prizeGroupConfig, optInsGroupConfig} =
      this.createGroupsConfig(language);
    const selectOptions = {
      noResultsText: language.NO_RESULTS,
      placeholder: language.NO_RESULTS,
    };
    const originOptions = this.getSourcesOptions();

    return (
      <Modal title={language.TITLE} dialogClassName="winners-filters-modal" onClose={onClose}>
        <div className="winners-filters-modal__container">
          <div className="winners-filters-modal__row">
            <div className="winners-filters-modal__label">{language.ATTRIBUTES}</div>
            {this.renderFilterGroup(attributesGroupConfig)}
          </div>
          <div className="winners-filters-modal__row">
            <div className="winners-filters-modal__label">{language.PARTICIPATED}</div>
            {this.renderFilterGroup(participatedGroupConfig)}
          </div>
          <div className="winners-filters-modal__row">
            <div className="winners-filters-modal__label">{language.GENDER}</div>
            {this.renderFilterGroup(genderGroupConfig)}
          </div>
          <div className="winners-filters-modal__row">
            <div className="winners-filters-modal__label">{language.ORIGIN}</div>
            {this.renderFilterSelector(origin, originOptions, {
              ...selectOptions,
              placeholder: originOptions.length ? language.DOESNT_MATTER : selectOptions.placeholder,
              onChange: this.handleCheckFilterDropdown('origin'),
            })}
          </div>
          <div className="winners-filters-modal__row">
            <div className="winners-filters-modal__label">{language.PRIZE_TYPE}</div>
            {this.renderFilterGroup(prizeGroupConfig)}
          </div>
          <div className="winners-filters-modal__row">
            <div className="winners-filters-modal__label">{language.PRIZE}</div>
            {this.renderFilterSelector(pn, prizes, {
              multi: true,
              onChange: this.handleCheckFilterDropdown('prizes'),
            })}
          </div>
          <div className="winners-filters-modal__row">
            <div className="winners-filters-modal__label">Opt-In</div>
            {this.renderFilterGroup(optInsGroupConfig)}
          </div>
          <div className="winners-filters-modal__row">
            <div className="winners-filters-modal__label">{language.SELECT_OPTINS}</div>
            {this.renderFilterAsyncSelector(optIn, {
              getOptions: this.getOptInsOptions,
              onChange: this.handleCheckFilterDropdown('optIn'),
              multi: true,
              disabled: !optInRadio.optInRadio,
            })}
          </div>
          <div className="winners-filters-modal__results-button-wrap">
            <button className="winners-filters-modal__results-button" onClick={this.handleShowResults}>
              {language.BUTTON_RESULTS} {totalResults > 0 && `(${totalResults})`}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    optIns: selectOptInsForFilterModal(state),
    onlineInteractions: selectOnlineInteractions(state),
    offlineInteractions: selectOfflineInteractions(state),
  }),
  {
    getOptIns: getOptInsAction,
    getPrizeNames,
    getOnlineInteractions: fetchOnlineInteractions,
    getOfflineInteractions: fetchOfflineInteractions,
  },
)(FiltersModal);
