import {createSelector} from 'reselect';

import {selectAutotaskParam} from 'client/ducks/autotask/selectors';

import {GAME_TYPES} from 'client/models/prizes/constants';
import {Game} from 'client/models/prizes/types';
import {MainStates} from 'client/types';

export const selectGames = (state: MainStates) => {
  return state.games.games;
};

export const selectPrizes = (state: MainStates) => {
  return state.games.prizes;
};

export const selectLevels = (state: MainStates) => {
  return state.games.levels;
};

export const selectPrizeMaps = (state: MainStates) => {
  return state.games.prizeMaps;
};

export const selectedClientGames = createSelector(selectAutotaskParam('client_games'), (clientGames: Game[]) => ({
  instantWinGame: clientGames?.find((game) => game.game_type === GAME_TYPES.INSTANT_WIN),
  lotteryGame: clientGames?.find((game) => game.game_type === GAME_TYPES.PRIZE_DRAW),
}));
