import React, {useEffect} from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import ModalContainer from 'client/common/modals/modal-container';

import {ModalBody, ModalCloseButton, ModalHeader} from './components';
import {ModalProps} from './type';

import cssModule from './modal.module.scss';

const b = bem('modal', {cssModule});

const Modal: React.FC<ModalProps> = ({
  title,
  titleButton,
  description,
  children,
  onClose,
  show = true,
  header = '',
  isCloseHidden = false,

  dialogClassName = '',
  bodyClassName = '',
  contentClassName = '',
  closeClassName = '',
  headerClassName = '',
  titleClassName = '',

  className = dialogClassName,
  classNames = {
    header: headerClassName,
    body: bodyClassName,
    content: contentClassName,
    close: closeClassName,
    title: titleClassName,
  },
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && show && onClose) {
        onClose();
      }
    };

    if (show) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [show, onClose]);

  if (!show) {
    return null;
  }

  return (
    <ModalContainer>
      <div className={cn(b({'is-shown': show, 'has-header': header}), className)} data-type="modal" tabIndex={-1}>
        <div className={b('wrapper')}>
          {!isCloseHidden && !!onClose && (
            <ModalCloseButton onClose={() => onClose()} header={header} className={cn(b('close'), classNames?.close)} />
          )}
          {header && <ModalHeader header={header} className={cn(b('header'), classNames?.header)} />}
          <ModalBody
            className={cn(b('body'), classNames?.body)}
            contentClassName={classNames?.content}
            children={children}
            title={title}
            description={description}
            titleButton={titleButton}
            titleClassName={classNames?.title}
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export default Modal;
