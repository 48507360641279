import types from './types';

const initialState = {
  type: types.NO_DATA,
  payload: [],
  meta: {
    current_page: 0,
    next_page: null,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
  usersForDropDown: [],
  interfacesNamesForDropDown: [],
  leadHistories: [
    {
      source: {
        input_data: null,
      },
    },
  ],
};

export default function (_state = initialState, action) {
  let state = {..._state};

  switch (action.type) {
    case types.NO_DATA:
      state.type = types.NO_DATA;
      state.payload = initialState.payload;
      return state;

    case types.FETCH_LEAD_HISTORIES_SUCCESS:
      state.type = types.FETCH_LEAD_HISTORIES_SUCCESS;
      state.payload = action.payload.lead_histories;
      state.leadHistories = action.payload.lead_histories || initialState.leadHistories;
      state.meta = action.payload.meta;
      return state;

    case types.FETCH_USER_IDS_LEAD_HISTORIES_SUCCESS:
      state.type = types.FETCH_LEAD_HISTORIES_SUCCESS;
      state.usersForDropDown = action.payload.users;
      return state;

    default:
      return state;
  }
}
