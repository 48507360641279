import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Modal from 'client/common/modals/modal';

import Grid from 'client/components/common/grid';

import LinkItem from './components/link-item';

function BCClicksModal(props) {
  const {languageState, onClose, onConfirm, show, links} = props;
  const LANGUAGE = languageState.payload.BROADCASTING_TASK.CLICKS_MODAL;

  return (
    <Modal show={show} onClose={onClose} title={LANGUAGE.TITLE} dialogClassName="modal-window--width-2 theme-color-16">
      <form>
        <Grid>
          <Grid.Row className="bold">
            <Grid.Col size="6">{LANGUAGE.LINK_NAME_LABEL}</Grid.Col>
            <Grid.Col size="3">{LANGUAGE.TOTAL_CLICKS_LABEL}</Grid.Col>
            <Grid.Col size="3">{LANGUAGE.PERC_LABEL}</Grid.Col>
          </Grid.Row>
          {links.map((link, index) => (
            <LinkItem {...link} key={index} />
          ))}
        </Grid>
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-14 modal-window__footer-btn" type="button" onClick={onConfirm}>
            {LANGUAGE.CONFIRM_BUTTON}
          </button>
        </div>
      </form>
    </Modal>
  );
}

BCClicksModal.propTypes = {
  show: PropTypes.bool,
  languageState: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      count: PropTypes.number,
      percent: PropTypes.number,
      kind: PropTypes.string,
    }),
  ).isRequired,
};

BCClicksModal.defaultProps = {
  show: false,
};

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

export default connect(mapStateToProps)(BCClicksModal);
