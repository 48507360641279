import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {eqLength, required} from 'client/services/validator';

import {Translation} from 'client/models/language/types';

import {AgencyCreateClientModalFormValues} from './types';

const SIREN_COUNTRY = 'France';

export default (
  formValues: AgencyCreateClientModalFormValues,
  params: {
    lang: LangsByPath<['AGENCIES.MODALS.AGENCY_CREATE_CLIENT_MODAL.ERRORS']>;
    currentStep: number;
  },
) => {
  const {lang, currentStep} = params;
  const errors: Record<string, Translation> = {};

  if (currentStep === 0) {
    errors.country = required(lang.REQUIRED)(formValues.country);

    if (formValues.country === SIREN_COUNTRY) {
      errors.siren_number =
        required(lang.REQUIRED)(formValues.siren_number?.toString()) ||
        eqLength(9, lang.SIREN_IS_NOT_VALID)(formValues.siren_number?.toString());
    }
  }

  if (currentStep === 1) {
    errors.name = required(lang.REQUIRED)(formValues.name);
    errors.brand_name = required(lang.REQUIRED)(formValues.brand_name);
    errors.category_id = required(lang.REQUIRED)(formValues.category_id);
    errors.subcategory_id = required(lang.REQUIRED)(formValues.subcategory_id);
    errors.poc_agency_membership_id = required(lang.REQUIRED)(formValues.poc_agency_membership_id);
    errors.street_address = required(lang.REQUIRED)(formValues.street_address);
    errors.zip = required(lang.REQUIRED)(formValues.zip);
    errors.city = required(lang.REQUIRED)(formValues.city);
  }

  return errors;
};
