import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {set} from 'client/common/meta/meta.actions';

import AnonymousEmail from '../components/anonymous-email';

class AnonymousEmailContainer extends PureComponent {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    email: PropTypes.shape({
      subject: PropTypes.string,
    }).isRequired,
  };

  componentDidMount() {
    if (this.props.email.subject) {
      this.props.setMetaAction({
        title: this.props.email.subject,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {email: newEmail, setMetaAction} = this.props;
    const {email: oldEmail} = prevProps;

    if (newEmail.subject !== oldEmail.subject) {
      setMetaAction({
        title: newEmail.subject,
      });
    }
  }

  render() {
    const {
      match: {
        params: {token},
      },
    } = this.props;

    return <AnonymousEmail token={token} />;
  }
}

const mapStateToProps = ({anonymousEmails}) => ({
  email: anonymousEmails.email,
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnonymousEmailContainer);
