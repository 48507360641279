import React, {useMemo} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {interpolate} from 'client/ducks/language/helpers';
import {shareTemplateWithClients} from 'client/ducks/templates/actions';
import {selectClientUser} from 'client/ducks/user-clients/selectors';

import AppButton from 'client/common/buttons/app-button';
import CheckListField from 'client/common/fields/check-list-field';
import {useToast} from 'client/common/hooks/useToast';
import Modal from 'client/common/modals/modal';

import mapFormValues from 'client/components/diy-operation/modals/diy-op-template-modal/mapFormValues';
import {DiyOpTemplateValues} from 'client/components/diy-operation/modals/diy-op-template-modal/types';
import {Client} from 'client/models/client/types';
import {ApiDispatch} from 'client/types';

import {getSuccessToastData, filterClients} from './helpers';
import {ShareTemplateResponse} from './types';

import cssModule from './share-with-clients-step.module.scss';

const b = bem('share-with-clients-step', {cssModule});

type ShareWithClientsStepProps = {
  onClose: () => void;
  onSave?: () => Promise<void>;
  formValues: DiyOpTemplateValues;
  count?: number;
  templateId: number;
  allClients: Client[];
};

const ShareWithClientsStep: React.FC<ShareWithClientsStepProps> = (props) => {
  const {onClose, onSave, count, templateId, formValues, allClients} = props;
  const dispatch: ApiDispatch = useDispatch();
  const lang = useLanguage('DIY_OPERATION.MODALS.DIY_OP_TEMPLATE_MODAL.SHARE_WITH_CLIENTS_STEP');
  const langCommon = useLanguage('COMMON');
  const {client_user: user} = useSelector(selectClientUser);
  const [saving, toggleSaving] = useToggle(false);
  const {appendToastNotification} = useToast();

  const countLabel = count ? ` (${count})` : '';

  const clients = useMemo(() => {
    return filterClients(allClients, formValues.sharing.region, formValues.sharing.store);
  }, [allClients, formValues.sharing.region, formValues.sharing.store]);

  const handleSave = async (values: DiyOpTemplateValues, isFirstAttempt = false) => {
    isFirstAttempt && toggleSaving();

    const data = await mapFormValues(values, false, user.locale);

    try {
      const response: ShareTemplateResponse = await dispatch(shareTemplateWithClients(templateId, data));
      const successClientIds = response.payload.client_templates.map((i) => i.client_id);
      const errorClientIds = response.payload.errors.map((i) => i.company_id);

      await onSave?.();

      if (successClientIds.length) {
        const {title, description} = getSuccessToastData({ids: successClientIds, clients, formValues: values}, lang);
        appendToastNotification({type: 'success', title, description});
      }

      if (errorClientIds.length) {
        errorClientIds.forEach((errorClientId) => {
          appendToastNotification({
            type: 'error',
            title: interpolate(lang.TEMPLATE_NOT_SHARED.toString(), {name: values.name}),
            description: clients.find((i) => i.id === errorClientId)?.name,
            closeButtonLabel: lang.CLOSE,
            actions: [
              {
                label: langCommon.REDO,
                onClick: () => handleSave({...values, clients: [errorClientId.toString()]}),
              },
            ],
          });
        });
      }

      isFirstAttempt && onClose();
    } catch {
      appendToastNotification({type: 'error', title: langCommon.ERROR});
    }

    isFirstAttempt && toggleSaving();
  };

  return (
    <Modal className={b()} title={lang.TITLE} onClose={onClose} isCloseHidden>
      <CheckListField
        name="clients"
        options={clients.map((i) => ({
          value: i.id.toString(),
          label: i.name,
        }))}
        maxHeight={380}
        color="clients"
        inversionColor
        allOption
      />
      <div className={b('buttons')}>
        <AppButton label={lang.CANCEL} transparent onClick={onClose} />
        <AppButton
          className={b('ok-button')}
          label={lang.SHARE + countLabel}
          onClick={() => handleSave(formValues, true)}
          size="medium"
          disabled={!count}
          loading={saving}
        />
      </div>
    </Modal>
  );
};

export default ShareWithClientsStep;
