import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {dateToString, timeToString} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {DeviceAffectationInfo} from 'client/models/device-affectations';

import cssModule from './client-device-modal-comments-block.module.scss';

const b = bem('client-device-modal-comments-block', {cssModule});

type ClientDeviceModalCommentsBlockProps = {
  className?: string;
  data: DeviceAffectationInfo[];
};

const ClientDeviceModalCommentsBlock: React.FC<ClientDeviceModalCommentsBlockProps> = (props) => {
  const {data, className} = props;
  const lang = useLanguage('CLIENT_DEVICES.MODAL.COMMENTS_BLOCK');

  const getCommentType = (item: DeviceAffectationInfo) => {
    return (lang[item.record_type.toUpperCase() as keyof typeof lang] || item.record_type).replace('_', ' ');
  };

  return (
    <CustomScrollbars scrollbarProps={{autoHeightMax: 325}}>
      <div className={cn(b(), className)}>
        {data.map((item) => (
          <div key={item.id} className={b('comment-item')}>
            <div className={b('meta')}>
              {item.record_type && (
                <p className={b('type')} title={getCommentType(item)}>
                  {getCommentType(item)}
                </p>
              )}
              {item.timestamp && (
                <div>
                  <p className={b('date')}>{dateToString(item.timestamp, 'DD/MM/YY')}</p>
                  <p className={b('time')}>{timeToString(item.timestamp)}</p>
                </div>
              )}
            </div>
            <p className={b('content')} title={item.comment}>
              {item.comment}
            </p>
          </div>
        ))}
      </div>
    </CustomScrollbars>
  );
};

export default ClientDeviceModalCommentsBlock;
