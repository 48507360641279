import find from 'lodash/find';

import {SmsTemplate, SmsTemplateKind} from 'client/models/sms-templates/types';

import {SmsTemplateFormValues, NewSmsTemplateVariable} from './types';

const getNewVariables = (
  addedVariables: string[],
  kind?: SmsTemplateKind | null,
  template?: SmsTemplate | null,
): NewSmsTemplateVariable[] => {
  const result: NewSmsTemplateVariable[] = [];

  if (!kind || !template) {
    return result;
  }

  addedVariables.forEach((name) => {
    if (!find(template.sms_template_variables, {name})) {
      const kindVariable = find(kind.sms_template_kind_variables, {name});
      result.push({
        sms_template_id: template.id,
        message_template_kind_variable_id: kindVariable?.id,
        name,
        test_value: kindVariable?.test_value,
        column_adapter_id: kindVariable?.column_adapter_id,
        visible_by_client: true,
      });
    }
  });

  return result;
};

export default (values: SmsTemplateFormValues, kind: SmsTemplateKind, addedVariables: Record<string, string[]>) => {
  return kind.sms_templates.map((item) => {
    return {
      id: item.id,
      name: values.name[item.language_tag],
      content: values.message[item.language_tag],
      sms_template_variables: getNewVariables(addedVariables[item.language_tag] || [], kind, item),
      sms_sender_id: values.sms_sender_id,
    };
  });
};
