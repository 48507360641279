import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import SearchInput from 'client/common/inputs/search-input';
import SelectDropdown from 'client/common/selects/select-dropdown';

import {Translation} from 'client/models/language/types';

import cssModule from './catalog-templates-toolbar.module.scss';

type CatalogTemplateToolbarProps = {
  search: string;
  loading: boolean;
  onSearch: (search: string) => void;
  onSortChange: (value: string) => void;

  sort?: 'date';
  className?: string;
  totalCount?: number;
  placeholder?: Translation;
  classNames?: {
    search?: string;
    records?: string;
    selectSort?: string;
    label?: string;
    dropdown?: string;
  };
};

const b = bem('catalog-templates-toolbar', {cssModule});

export const SORT_BY_VALUES = {
  DATE: 'created_at',
} as const;

const CatalogTemplateToolbar: React.FC<CatalogTemplateToolbarProps> = (props) => {
  const {
    className,
    search,
    onSearch,
    onSortChange,
    sort = 'date',
    loading,
    totalCount = 0,
    classNames,
    placeholder,
  } = props;

  const lang = useLanguage('CATALOG_TEMPLATES.COMPONENTS.CATALOG_TAMPLATE_TOOLBAR');
  const options = [{value: SORT_BY_VALUES.DATE, label: lang.DATE}]; // Catalog Temp. P2. Dropdown has the single value Date

  return (
    <div className={cn(b(), className)}>
      <SearchInput
        className={cn(b('search-input'), classNames?.search)}
        placeholder={placeholder || lang.SEARCH_PLACEHOLDER}
        onSearch={onSearch}
        searchDefault={search}
      />
      {!loading && search && (
        <span className={cn(b('records-found'), classNames?.records)}>
          {totalCount} {lang.RECORDS_FOUND}
        </span>
      )}
      <div className={cn(b('select-dropdown-sort'), classNames?.selectSort)}>
        <span className={cn(b('label'), classNames?.label)}>{lang.SORT_BY}</span>
        <SelectDropdown
          type="sort-by"
          options={options}
          className={cn(b('select-dropdown'), classNames?.dropdown)}
          onChange={onSortChange}
          value={sort}
          simpleValue={true}
          disabled={options.length === 1}
        />
      </div>
    </div>
  );
};

export default CatalogTemplateToolbar;
