import find from 'lodash/find';

import {
  diagramKinds,
  diagramLineKinds,
  diagramDotPrecisions,
  diagramMethods,
} from 'client/ducks/results-graphs/constants';

export const showMethod = (formValues) => {
  return [diagramKinds.HORIZONTAL_BARS, diagramKinds.VERTICAL_BARS].includes(formValues.kind);
};

export const showRangeOfInterval = (formValues) => {
  return showMethod(formValues) && formValues.diagram_method === diagramMethods.INTERVAL;
};

export const showLineKind = (formValues) => {
  return formValues.kind === diagramKinds.LINE;
};

export const showIntervalRange = (formValues) => {
  return showLineKind(formValues) && formValues.line_kind === diagramLineKinds.LINE_WITH_INTERVALS;
};

export const showRange = (formValues) => {
  return showIntervalRange(formValues) && formValues.dot_precision === diagramDotPrecisions.FIXED;
};

export const showColor = (formValues) => {
  return [diagramKinds.LINE, diagramKinds.HORIZONTAL_BARS, diagramKinds.VERTICAL_BARS].includes(formValues.kind);
};

export const showTooltip = (formValues) => {
  return formValues.kind === diagramKinds.VERTICAL_BARS;
};

export const isNumberDataSourceSelected = (dataSources, formValues) => {
  const dataSource = find(dataSources, {id: formValues.column_adapter_id});

  return dataSource && ['decimal'].includes(dataSource.kind);
};
