import React, {useMemo, useState} from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectGameTemplate, selectFormTemplate, selectQuizTemplate} from 'client/ducks/templates/selectors';
import {selectCurrentAccessLevel} from 'client/ducks/user-clients/selectors';

import AppButton from 'client/common/buttons/app-button';
import ArticlePanel from 'client/common/panels/article-panel';
import Popover from 'client/common/popovers/popover';

import DiyOpElementCard from 'client/components/diy-operation/cards/diy-op-element-card';
import DIYCustomizationModal from 'client/components/diy-operation/modals/diy-customization-modal';
import {getCatalogsInfoByType} from 'client/components/diy-operation/modals/diy-customization-modal/helpers/get-catalogs-info-by-type';
import {useTemplateParamsByAutotask} from 'client/components/diy-operation/modals/diy-customization-modal/useTemplateParamsByAutotask';
import DiyOpOptinsModal from 'client/components/diy-operation/modals/diy-op-optins-modal';
import {CATALOG_TYPES} from 'client/models/templates/constants';

// helpers
import {DIY_OP_ELEMENTS_MODALS} from './helpers';
import TemplatesElement from './templates-element';

import './diy-op-elements-block.scss';

const b = bem('diy-op-elements-block');

/**
 *
 * @type {React.FC<DiyOpElementsBlock.propTypes>}
 */

// TODO: from jsx => tsx
const DiyOpElementsBlock = (props) => {
  const {operation, fetchResult, emailTemplateKinds, loadingEmailTemplateKinds} = props;
  const {client_interface} = operation;
  const [activeModal, setActiveModal] = useState(null);
  const [formId, setFormId] = useState(null);
  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_ELEMENTS_BLOCK');
  const langModal = useLanguage('DIY_OPERATION.MODALS.OPTINS_MODAL');
  const accessLevel = useSelector(selectCurrentAccessLevel);
  const customizationParams = useTemplateParamsByAutotask({formId});

  const gameTemplate = useSelector(selectGameTemplate);
  const quizTemplate = useSelector(selectQuizTemplate);
  const formTemplate = useSelector(selectFormTemplate);

  const handleActiveModalClose = () => {
    if (activeModal === DIY_OP_ELEMENTS_MODALS.OPTINS_CONFIGURATION_MODAL.id) {
      fetchResult();
    }
    setActiveModal(null);
    setFormId(null);
  };

  const handleModalOpen = (key) => setActiveModal(key);

  const catalogsInfo = useMemo(
    () => getCatalogsInfoByType(client_interface, accessLevel),
    [client_interface, accessLevel],
  );

  const gameCatalogInfo = catalogsInfo[CATALOG_TYPES.GAME];
  const quizCatalogInfo = catalogsInfo[CATALOG_TYPES.QUIZ];
  const formCatalogInfo = catalogsInfo[CATALOG_TYPES.FORM];

  const handleConfigurationModalOpen = (id) => {
    setFormId(id);
    handleModalOpen(DIY_OP_ELEMENTS_MODALS.CLASSIC_CONFIGURATION_MODAL.id);
  };

  const commonButtonProps = {
    iconName: 'settings',
    color: 'light-clients',
    className: b('button'),
    iconConfig: {width: 20, height: 20},
  };

  return (
    <ArticlePanel title={lang.TITLE} className={b()}>
      <div className={b('cards')}>
        <DiyOpElementCard
          name="game"
          title={lang.GAME_TITLE}
          disabled={!gameCatalogInfo.forms}
          headerId="diy-conf-el-game-head"
          documentLink={gameCatalogInfo?.documentationUrl}
          template={gameTemplate}
          buttons={
            <div className={b('buttons')}>
              <Popover overlay={lang.CONFIGURATION_TOOLTIP}>
                <AppButton
                  {...commonButtonProps}
                  iconConfig={{width: 24, height: 24}}
                  iconName="settings"
                  onClick={() => handleConfigurationModalOpen(gameCatalogInfo?.forms?.param?.id)}
                  disabled={!gameCatalogInfo?.forms?.param?.id}
                />
              </Popover>
              <Popover overlay={lang.DESIGN_TOOLTIP}>
                <AppButton
                  {...commonButtonProps}
                  iconName="design"
                  onClick={() => handleConfigurationModalOpen(gameCatalogInfo?.forms?.design?.id)}
                  disabled={!gameCatalogInfo?.forms?.design?.id}
                />
              </Popover>
            </div>
          }
        />
        <DiyOpElementCard
          name="quiz"
          title={lang.QUIZ_TITLE}
          disabled={!quizCatalogInfo.forms}
          headerId="diy-conf-el-quiz-head"
          documentLink={quizCatalogInfo?.documentationUrl}
          template={quizTemplate}
          buttons={
            <div className={b('buttons')}>
              <Popover overlay={lang.CONFIGURATION_TOOLTIP}>
                <AppButton
                  {...commonButtonProps}
                  iconConfig={{width: 24, height: 24}}
                  iconName="settings"
                  onClick={() => handleConfigurationModalOpen(quizCatalogInfo?.forms?.param?.id)}
                  disabled={!quizCatalogInfo?.forms?.param?.id}
                />
              </Popover>
              <Popover overlay={lang.DESIGN_TOOLTIP}>
                <AppButton
                  {...commonButtonProps}
                  iconName="design"
                  onClick={() => handleConfigurationModalOpen(quizCatalogInfo?.forms?.design?.id)}
                  disabled={!quizCatalogInfo?.forms?.design?.id}
                />
              </Popover>
            </div>
          }
        />
        <DiyOpElementCard
          name="form"
          title={lang.FORM_TITLE}
          disabled={!formCatalogInfo.forms}
          headerId="diy-conf-el-form-head"
          documentLink={formCatalogInfo?.documentationUrl}
          template={formTemplate}
          buttons={
            <div className={b('buttons')}>
              <Popover overlay={lang.CONFIGURATION_TOOLTIP}>
                <AppButton
                  {...commonButtonProps}
                  iconConfig={{width: 24, height: 24}}
                  iconName="settings"
                  onClick={() => handleConfigurationModalOpen(formCatalogInfo?.forms?.param?.id)}
                  disabled={!formCatalogInfo?.forms?.param?.id}
                />
              </Popover>
              <Popover overlay={lang.DESIGN_TOOLTIP}>
                <AppButton
                  {...commonButtonProps}
                  iconName="design"
                  onClick={() => handleConfigurationModalOpen(formCatalogInfo?.forms?.design?.id)}
                  disabled={!formCatalogInfo?.forms?.design?.id}
                />
              </Popover>
              <AppButton
                iconName="checked-outlined"
                color="light-clients"
                className={b('button')}
                onClick={() => handleModalOpen(DIY_OP_ELEMENTS_MODALS.OPTINS_CONFIGURATION_MODAL.id)}
              />
            </div>
          }
        />
        <TemplatesElement
          emailTemplateKinds={emailTemplateKinds}
          loadingEmailTemplateKinds={loadingEmailTemplateKinds}
        />
      </div>

      {/* modals */}
      {activeModal === DIY_OP_ELEMENTS_MODALS.OPTINS_CONFIGURATION_MODAL.id && (
        <DiyOpOptinsModal
          formName={DIY_OP_ELEMENTS_MODALS.OPTINS_CONFIGURATION_MODAL.form}
          title={langModal.TITLE}
          onClose={handleActiveModalClose}
          interfaceId={client_interface?.id}
        />
      )}
      {activeModal === DIY_OP_ELEMENTS_MODALS.CLASSIC_CONFIGURATION_MODAL.id && customizationParams.template && (
        <DIYCustomizationModal
          formId={formId}
          clientId={operation.client_id}
          onClose={handleActiveModalClose}
          {...customizationParams}
        />
      )}
    </ArticlePanel>
  );
};

DiyOpElementsBlock.propTypes = {
  operation: PropTypes.shape({
    client_id: PropTypes.number,
    client_interface: PropTypes.object,
  }).isRequired,
  fetchResult: PropTypes.func.isRequired,
  emailTemplateKinds: PropTypes.array.isRequired,
  loadingEmailTemplateKinds: PropTypes.bool.isRequired,
};

export default DiyOpElementsBlock;
