import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getScenarioExecutions, clearScenarioRunTable} from 'client/ducks/scenario-run-table/actions';
import {getVariables} from 'client/ducks/scenario-run-table/actions';
import {selectVariables} from 'client/ducks/scenario-run-table/selectors';
import {getScenarioSteps} from 'client/ducks/scenario/actions';

// components
import Modal from 'client/common/modals/modal';

import StepsList from '../../result-steps-list';

import './participants-result-modal.scss';

class ParticipationResultModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    scenarioExecution: PropTypes.object,
    scenarioExecutions: PropTypes.arrayOf(PropTypes.object),
    languageState: PropTypes.object.isRequired,
    scenarioSteps: PropTypes.array,
    variables: PropTypes.array,
    getScenarioSteps: PropTypes.func.isRequired,
    getScenarioExecutions: PropTypes.func.isRequired,
    getVariables: PropTypes.func.isRequired,
    clearScenarioRunTable: PropTypes.func.isRequired,
  };

  static defaultProps = {
    show: false,
    onClose: () => {},
    usedVariables: [],
    variables: [],
    scenarioSteps: [],
    scenarioExecution: {},
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.RESULT_MODAL;
  }

  componentDidMount() {
    const {scenarioExecution} = this.props;
    const params = {
      q: {
        s: 'position asc',
        participation_id_eq: scenarioExecution.id,
      },
      include: [
        'coupon',
        'scenario_step_snapshots.implementation.coupon',
        {
          scenario_step_snapshots: [
            {
              implementation: ['coupon'],
            },
          ],
        },
        {
          scenario: ['scenario_variables'],
          interface: null,
        },
      ],
    };
    this.props.getScenarioExecutions(params);
  }

  handleClose = () => {
    this.props.clearScenarioRunTable();
    this.props.onClose();
  };

  render() {
    const {LANGUAGE} = this;
    const {show, scenarioExecution, scenarioSteps, variables, scenarioExecutions} = this.props;
    const snapshotSteps = scenarioExecution.scenario_step_snapshots || [];

    const scenarioStepsIds = scenarioSteps.map((step) => step.id);

    const steps = snapshotSteps.reduce(
      (acc, step) => (scenarioStepsIds.includes(step.id) ? acc : [...acc, step]),
      scenarioSteps,
    );

    return (
      <Modal
        show={show}
        onClose={this.handleClose}
        title={LANGUAGE.TITLE}
        dialogClassName="modal-window--width-3 theme-color-9 run-table-result-modal"
      >
        <StepsList scenarioSteps={steps} scenarioExecutions={scenarioExecutions} variables={variables} />
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-4 modal-window__footer-btn" onClick={this.handleClose}>
            {LANGUAGE.OK_BUTTON}
          </button>
        </div>
      </Modal>
    );
  }
}

export default connect(
  ({languageState, scenario, ...state}) => ({
    languageState,
    scenarioSteps: scenario.payload.scenario_steps,
    scenarioExecutions: state.scenarioRunTable.scenarioExecutions,
    variables: selectVariables(state),
  }),
  {
    getScenarioSteps,
    getScenarioExecutions,
    getVariables,
    clearScenarioRunTable,
  },
)(ParticipationResultModal);
