import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import CountBlock from 'client/common/count-block';

// components
import Icon from 'client/components/common/icon';
import InfoBlock from 'client/components/common/info-block';

import MailingList from '../../tables/mailing-list';

class MailingCard extends Component {
  static propTypes = {
    data: PropTypes.array,
    clientId: PropTypes.number.isRequired,
    refresh: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AGENCY.MAILING_LISTS_CARD;

    this.state = {
      data: [],
      editMode: false,
    };
  }

  componentDidMount() {
    this.applyNewData(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.applyNewData(this.props.data);
    }
  }

  applyNewData = (data) => {
    this.setState({
      data: data.filter(({message_tasks_count}) => message_tasks_count > 0),
    });
  };

  handleEditClick = () => {
    this.setState({
      editMode: true,
    });
  };

  handleCancelClick = () => {
    this.props.refresh();

    this.setState({
      editMode: false,
    });
  };

  handleSaveClick = () => {
    this.props.refresh();

    this.setState({
      editMode: false,
    });
  };

  render() {
    const {LANGUAGE} = this;
    const {data} = this.state;

    return (
      <InfoBlock colorTheme="opt-ins">
        <InfoBlock.Title>{LANGUAGE.TITLE}</InfoBlock.Title>
        <InfoBlock.Content>
          <InfoBlock.Header className="clearfix">
            <CountBlock itemName={LANGUAGE.ITEMS_NAME} className="pull-left" num={data.length} />
            {data.length !== 0 && !this.state.editMode && (
              <button className="button button--circle button--bg-2 pull-right" onClick={this.handleEditClick}>
                <Icon name="edit" className="button__icon" width={20} height={20} />
              </button>
            )}
          </InfoBlock.Header>
          <InfoBlock.Body>
            <MailingList
              clientId={this.props.clientId}
              editMode={this.state.editMode}
              onCancelClick={this.handleCancelClick}
              onSaveClick={this.handleSaveClick}
              data={data}
            />
          </InfoBlock.Body>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(MailingCard);
