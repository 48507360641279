import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {WinningSearchType} from 'client/components/winnings/forms/winning-search-form/types';
import {WinningSearchResult} from 'client/models/winnings/types';

import WinningSearchResultItem from './winning-search-result-item';

import cssModule from './winning-search-result-list.module.scss';

const b = bem('winning-search-result-list', {cssModule});

type WinningSearchResultListProps = {
  className?: string;
  searchType: WinningSearchType;
  showCopy?: boolean;
  onSelect?: (prizeId: number) => void;
  data: WinningSearchResult[];
};

const WinningSearchResultList: React.FC<WinningSearchResultListProps> = (props) => {
  const {className, searchType, showCopy, onSelect, data} = props;
  const lang = useLanguage('WINNINGS.LISTS.WINNING_SEARCH_RESULT_LIST');

  return (
    <div className={cn(b(), className)}>
      {data.length > 1 && (
        <p className={b('found-label')}>
          {data.length} {lang.WINNERS_FOUND}
        </p>
      )}
      {data.map((item) => (
        <WinningSearchResultItem
          key={item.lead.id}
          item={item}
          searchType={searchType}
          showCopy={showCopy}
          onSelect={onSelect}
        />
      ))}
    </div>
  );
};

export default WinningSearchResultList;
