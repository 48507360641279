import React, {useCallback} from 'react';

import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import AppButton from 'client/common/buttons';
import {RadioGroupField} from 'client/common/fields';
import {useToast} from 'client/common/hooks/useToast';
import Modal from 'client/common/modals/modal';

import {Translation} from 'client/models/language/types';

import validate from './validate';

import cssModule from './update-settings-modal.module.scss';

const b = bem('update-settings-modal', {cssModule});

export type UpdateSettingsModalFormProps = {
  update_kind?: Translation;
};

export type UpdateSettingsModalProps = {
  show: boolean;
  onClose: () => void;
  onConfirm: (values?: UpdateSettingsModalFormProps) => Promise<void>;
  handleSubmit: () => void;
  updateKind?: Translation;
};

export const UpdateSettingsModalFormName = 'UpdateSettingsModal';

const UpdateSettingsModal: ReduxFormFC<UpdateSettingsModalProps, UpdateSettingsModalFormProps> = (props) => {
  const {show, onClose, onConfirm, handleSubmit, updateKind} = props;

  const lang = useLanguage('AUTOTASK.UPDATE_SETTINGS_MODAL');
  const langErrors = useLanguage('ERRORS');

  const [loading, toggleLoading] = useToggle(false);

  const {appendToastNotification} = useToast();

  const {formValues, invalid} = useReduxForm<UpdateSettingsModalFormProps>(UpdateSettingsModalFormName, {
    initialValues: {update_kind: show && !!updateKind ? updateKind : 'always'},
    validate: (values) => validate(values, lang),
  });

  const onSubmit = useCallback(() => {
    toggleLoading();
    try {
      onConfirm({
        update_kind: formValues?.update_kind,
      });
    } catch (e) {
      appendToastNotification({
        type: 'error',
        title: langErrors.ERROR_MESSAGE,
      });
    }
    toggleLoading();
  }, [appendToastNotification, formValues?.update_kind, langErrors.ERROR_MESSAGE, onConfirm, toggleLoading]);

  const title = `${lang.TITLE} ${!updateKind ? '(5/5)' : ''}`;

  return (
    <Modal show={show} dialogClassName={b('')} onClose={onClose} title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RadioGroupField
          name="update_kind"
          color="interfaces"
          options={[
            {value: 'always', label: lang.UPDATE_ALL_LABEL},
            {value: 'if_source_present', label: lang.UPDATE_EXCEPT_LABEL},
            {value: 'if_target_blank', label: lang.UPDATE_EMPTY_LABEL},
            {value: 'never', label: lang.UPDATE_NOTHING_LABEL},
          ]}
        />

        <div className={b('buttons-container')}>
          <AppButton
            label={lang.CANCEL_BUTTON}
            onClick={onClose}
            disabled={loading}
            nativeStyle={true}
            className="button button--bg-11"
          />

          <AppButton
            label={lang.SAVE_BUTTON}
            disabled={loading || invalid}
            loading={loading}
            submit={true}
            nativeStyle={true}
            className="button button--bg-7"
          />
        </div>
      </form>
    </Modal>
  );
};

export default reduxForm<UpdateSettingsModalProps, UpdateSettingsModalFormProps>({
  form: UpdateSettingsModalFormName,
})(UpdateSettingsModal);
