import {transformDate} from 'client/services/helpers';

export const validate = (values, props) => {
  const {name, code, from, to, status, client_user_id} = values;
  const {
    lang,
    langOperationPage,
    initialValues: {client = {}},
  } = props;
  const {client_network} = client;

  const errors = {};

  const lang2 = langOperationPage.GENERAL_INFORMATION || {};

  if (!name || !name.trim()) {
    errors.name = lang.NAME_IS_REQUIRED_ERROR;
  }

  if (!code) {
    errors.code = lang.CODE_IS_REQUIRED_ERROR;
  }

  if (!status || !status.value) {
    errors.status = lang.STATUS_IS_REQUIRED_ERROR;
  }

  const startDate = new Date(transformDate(from, false));
  if (!from) {
    errors.from = lang.START_DATE_IS_REQUIRED_ERROR;
  } else if (!/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(from)) {
    errors.from = lang2.START_DATE_IS_INVALID_ERROR;
  } else if (startDate.toString() === 'Invalid Date') {
    errors.from = lang2.START_DATE_IS_INVALID_ERROR;
  }

  const endDate = new Date(transformDate(to, false));
  if (!to) {
    errors.to = lang.END_DATE_IS_REQUIRED_ERROR;
  } else if (!/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(to)) {
    errors.to = lang2.END_DATE_IS_INVALID_ERROR;
  } else if (endDate.toString() === 'Invalid Date') {
    errors.to = lang2.END_DATE_IS_INVALID_ERROR;
  } else if (startDate >= endDate) {
    errors.to = errors.from = lang.END_DATE_SMALLER_THEN_START_DATE_ERROR;
  }

  if (client_network && !client_user_id?.value) {
    errors.client_user_id = lang.ATTRIBUTED_TO_IS_REQUIRED_ERROR;
  }

  return errors;
};

export const updateSyncErrors = (values, props) => {
  return {
    type: '@@redux-form/UPDATE_SYNC_ERRORS',
    meta: {
      form: 'generalInformationForm',
    },
    payload: {
      syncErrors: () => validate(values, props),
    },
  };
};
