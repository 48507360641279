import React from 'react';

import sortBy from 'lodash/sortBy';

import DonutChart from 'client/components/common/charts/donut-chart';

const InstoreDonutChart = (props) => {
  const {data, ...rest} = props;

  return <DonutChart data={sortBy(data, 'value').reverse()} {...rest} />;
};

InstoreDonutChart.propTypes = {
  ...DonutChart.propTypes,
};

InstoreDonutChart.defaultProps = {
  ...DonutChart.defaultProps,
  showLegendPercent: true,
  legendValuesOnRight: true,
  legendMaxHeight: 130,
};

export default InstoreDonutChart;
