import React, {Component} from 'react';

import cn from 'classnames';
import find from 'lodash/find';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';
import {deleteMethod} from 'client/services/fetch';
import {getAuthHeaders} from 'client/services/helpers';

import {
  changeClientsPerPageAction,
  changeClientsSortAction,
  changeFinishedOperationsPerPageAction,
  changeFinishedOperationsSortAction,
  changeOngoingOperationsPerPageAction,
  changeOngoingOperationsSortAction,
  changeSelectedUsersAction,
  changeUsersPerPageAction,
  changeUsersSortAction,
  changeUsersSearchAction,
  clearStoreAction,
  deleteAgencyAction,
  getAdminUsersAction,
  getAgencyAction,
  getAgencyUsersAction,
  getAgencyMembershipsAction,
  getClientsAction,
  getClientUserAction,
  getFinishedOperationsAction,
  getOngoingOperationsAction,
  getSubsidiariesAction,
  getUsersAction,
  patchAgencyAction,
  setDefaultNewUserAction,
} from 'client/ducks/agency/actions';
import {selectUsersFullList} from 'client/ducks/agency/selectors';
import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {resendInvitation} from 'client/ducks/client-users/actions';
import {getClientUsersWithMembershipsAction} from 'client/ducks/client-users/actions';
import {selectClientUsers} from 'client/ducks/client-users/selectors';
import {getAvailableColumnAdaptersByClient} from 'client/ducks/column-adapters/actions';
import {getLeadsReports} from 'client/ducks/lead/actions';
import {selectImportExportReports} from 'client/ducks/lead/selectors';
import {deleteApiLeadsExport} from 'client/ducks/leads-export/actions';
import {deleteApiLeadsImport} from 'client/ducks/leads-import/actions';
import {getLeads} from 'client/ducks/leads-list/actions';
import {selectColumnAdaptersForImportExport, selectLeadsTotalCount} from 'client/ducks/leads-list/selectors';
import {getOptInsAction} from 'client/ducks/opt-in-columns/actions';
import {selectOptIns} from 'client/ducks/opt-in-columns/selectors';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {API_METHODS, API_PATH, CLIENT_PAGES} from 'client/common/config';
import Icon from 'client/common/icon';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import TitleBlock from 'client/components/common/title-block';
import VisualsCard from 'client/components/common/visuals-card';

import OngoingOperationsBlock from 'client/components/client-agency/components/blocks/ongoing-operations-block';
import ClientUserDeactivationWizard from 'client/components/client-agency/wizards/client-user-deactivation-wizard';
import LeadsImportFileModal from 'client/components/leads/components/modals/leads-import-file-modal';
import LeadsPortSummaryModal from 'client/components/leads/components/modals/leads-port-summary-modal';
import SelectUserModal from 'client/components/modals/select-user-modal';
import {TranslationJsx} from 'client/models/language/types';

import AddLogoButton from '../components/add-logo-button';
import BasicInformationForm from '../components/agency-basic-information-form';
import AutoCreationAccessCard from '../components/cards/auto-creation-access-card';
import BasicInformationCard from '../components/cards/basic-information-card';
import LeadsCard from '../components/cards/leads-card';
import OptInsCard from '../components/cards/opt-ins-card';
import PasswordExpirationCard from '../components/cards/password-expiration-card';
import PhonesEmailsCard from '../components/cards/phones-emails-card';
import SocialNetworkAccountsCard from '../components/cards/social-network-accounts-card';
import AddNewOperationModal from '../components/modals/add-new-operation-modal';
import AddNewOptInModal from '../components/modals/add-new-opt-in-modal';
import DeleteUsersModal from '../components/modals/delete-users-modal';
import EditAccessLevelModal from '../components/modals/edit-access-level-modal';
import EditUserModal from '../components/modals/edit-user-modal';
import NewUserAccessClientsModal from '../components/modals/new-user/new-user-access-clients-modal';
import NewUserAgencyStep3Modal from '../components/modals/new-user/new-user-agency-step-3-modal';
import NewUserStep1Modal from '../components/modals/new-user/new-user-step-1-modal';
import NewUserStep2Modal from '../components/modals/new-user/new-user-step-2-modal';
import NewUserStep4Modal from '../components/modals/new-user/new-user-step-4-modal';
import AgencyClientsTable from '../components/tables/agency-clients-table';
import UsersTable from '../components/tables/users-table';

import cssModule from './agency.module.scss';

const b = bem('agency', {cssModule});

class Agency extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    languageState: PropTypes.object.isRequired,
    lang: PropTypes.object.isRequired,
    langBC: PropTypes.object.isRequired,
    langLeads: PropTypes.object.isRequired,
    userState: PropTypes.shape({
      payload: PropTypes.shape({
        user: PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      }),
    }),
    usersFullList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: TranslationJsx,
        value: PropTypes.string,
      }),
    ),
    agency: PropTypes.shape({
      agency: PropTypes.object,
      selectedUsers: PropTypes.array,
      noAgency: PropTypes.bool,
      users: PropTypes.object,
      clientUser: PropTypes.any,
      ongoingOperations: PropTypes.object,
      finishedOperations: PropTypes.object,
      clients: PropTypes.object,
      subsidiaries: PropTypes.array,
      social_accounts: PropTypes.array,
      message_senders: PropTypes.array,
      agencyMemberships: PropTypes.array,
      adminUsers: PropTypes.array,
    }),
    optIns: PropTypes.array,

    allColumns: PropTypes.array,
    leadsReports: PropTypes.array.isRequired,
    leadsCount: PropTypes.number,

    history: PropTypes.object,
    isAdmin: PropTypes.bool.isRequired,
    changeSelectedUsers: PropTypes.func,

    changeUsersPerPage: PropTypes.func,
    changeOngoingOperationsPerPage: PropTypes.func,
    changeFinishedOperationsPerPage: PropTypes.func,
    changeClientsPerPage: PropTypes.func,

    changeUsersSort: PropTypes.func,
    changeOngoingOperationsSort: PropTypes.func,
    changeFinishedOperationsSort: PropTypes.func,
    changeUsersSearch: PropTypes.func.isRequired,
    changeClientsSort: PropTypes.func,

    getUsers: PropTypes.func,
    getClients: PropTypes.func,
    getOngoingOperations: PropTypes.func,
    getFinishedOperations: PropTypes.func,
    getSubsidiaries: PropTypes.func,
    getAgencyMemberships: PropTypes.func,
    getOptIns: PropTypes.func,
    getAdminUsers: PropTypes.func,
    patchAgency: PropTypes.func,
    getClientUser: PropTypes.func,
    setDefaultNewUser: PropTypes.func,
    deleteAgency: PropTypes.func,
    getAgency: PropTypes.func,
    getAgencyUsers: PropTypes.func,
    clearStore: PropTypes.func,
    setBreadcrumbs: PropTypes.func,
    clearBreadcrumbs: PropTypes.func,
    getLeadsReports: PropTypes.func.isRequired,
    getAvailableColumnAdaptersByClient: PropTypes.func.isRequired,
    deleteApiLeadsExport: PropTypes.func.isRequired,
    deleteApiLeadsImport: PropTypes.func.isRequired,
    getLeads: PropTypes.func.isRequired,
    clientUsers: PropTypes.array.isRequired,
    getClientUsersWithMemberships: PropTypes.func.isRequired,
    resendInvitation: PropTypes.func.isRequired,
  };

  static NUMBER_OF_STEPS = 4;

  static defaultProps = {
    allColumns: [],
  };

  static DEFAULT_LEADS_FIRST_PAGE = 1;

  static DEFAULT_LEADS_PER_PAGE = 5;

  static MODALS_MAP = {
    IMPORT_FILE_MODAL: 'IMPORT_FILE_TYPE',
    IMPORT_SUMMARY_MODAL: 'IMPORT_SUMMARY_MODAL',
  };

  static mapUsersData(users) {
    return users.map((user) => {
      user.email = user?.client_user?.email;
      return user;
    });
  }

  static mapClients(user, clients) {
    const result = clients.map((i) => ({...i, checked: false}));
    if (user.company_accesses && user.company_accesses.length) {
      for (let company_access of user.company_accesses) {
        const found = find(result, (i) => i.id === company_access.company_id);
        if (found) {
          found.checked = true;
        }
      }
    }
    return (
      result &&
      result.map(({id, name, checked}) => ({
        id,
        label: name,
        checked,
      }))
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      editBasicInformation: false,
      showEditAccessLevelModal: false,
      showDeleteAgencyModal: false,
      showDeleteUsersModal: false,
      showAddNewOperationModal: false,
      showAddNewOptInModal: false,
      showDeleteOptInModal: false,
      showSelectUserModal: false,
      editingUser: {},
      editingOptIn: {},
      showEditClientsAccessModal: 0,
      showEditAgencyAccessModal: 0,
      editAccessLevelModalClientId: 0,
      editAccessLevelCompanyName: '',
      addNewUserStep: 0,
      addNewUserObject: {},
      addNewUserExists: false,
      visibleModal: '',
      selectedUsersWithMemberships: [],
      selectedUsersWithoutMemberships: [],
      isLoading: false,
      deletingUser: {
        index: null,
        user: null,
        soft: false,
      },
      reInviteUserId: null,
      resetUsersSearch: false,
    };
  }

  componentDidMount() {
    const init = {
      agencyId: this.props.id,
      page: 1,
      perPage: 5,
      sort: {name: 'name', order: 'ASC'},
    };

    this.updateMe().then(() => {
      this.updateBreadcrumbs(this.props.agency.agency && this.props.agency.agency.name);
    });
    this.props.getClients(init);
    this.props.getOngoingOperations(init);
    this.props.getFinishedOperations(init);
    this.props.getAgencyMemberships(this.props.id);
    const leadsParamsForTotalCount = {
      page: Agency.DEFAULT_LEADS_FIRST_PAGE,
      per_page: Agency.DEFAULT_LEADS_PER_PAGE,
    };
    this.props.getLeads(leadsParamsForTotalCount, this.props.id);

    this.props.getAvailableColumnAdaptersByClient(this.props.id, {});
    this.getReports();

    this.props.getClientUsersWithMemberships(this.props.id);
    this.props.getAgencyUsers(this.props.id);
  }

  componentDidUpdate(prevProps) {
    const {agency: nextAgency} = this.props;
    const {agency: prevAgency} = prevProps;

    if (nextAgency.noAgency) {
      this.props.history.push(CLIENT_PAGES.CLIENTS_LIST);
    }

    const {users: nextUsers} = nextAgency;
    const {users: prevUsers} = prevAgency;

    if (nextUsers && nextUsers.needToUpdate && (!prevUsers || !prevUsers.needToUpdate)) {
      this.props.getUsers({
        agencyId: this.props.id,
        page: nextUsers.meta.current_page || 1,
        perPage: nextUsers.perPage || 5,
        sort: nextUsers.sort || {name: 'client_user_full_name', order: 'ASC'},
      });
    }
  }

  componentWillUnmount() {
    if (this.props.isAdmin) {
      this.props.clearBreadcrumbs();
    }
    this.props.clearStore();
  }

  generalColumns = () => [
    this.props.langLeads.FIRST_NAME,
    this.props.langLeads.LAST_NAME,
    this.props.langLeads.EMAIL,
    this.props.langLeads.PHONE,
  ];

  updateMe = () => {
    const {id, getAgency, getOptIns} = this.props;

    const params = {
      include: ['user', 'client'],
      include_opt_in_column_leads_count: null,
      include_opt_in_column_interfaces_count: null,
      q: {
        client_id_eq: id,
        s: 'level',
      },
    };
    this.setState({
      showDeleteOptInModal: false,
      showEditUserModal: false,
      editingUser: {},
      isLoading: true,
    });
    return getAgency(id).then((promise) => {
      this.setState({isLoading: false});
      getOptIns(params);

      return promise;
    });
  };

  updateBreadcrumbs = (agencyName) => {
    if (!this.props.isAdmin) {
      return;
    }

    this.props.setBreadcrumbs([
      {
        name: this.props.langBC.CLIENTS,
        href: CLIENT_PAGES.CLIENTS_LIST,
      },
      {
        name: agencyName,
      },
    ]);
  };

  onTablePageChange = (agencyField, getItems, changeItemsPerPage) => {
    return (params) => {
      const items = this.props.agency[agencyField];
      if (typeof params.sort === 'undefined') {
        params.sort = items.sort;
      }
      params.agencyId = this.props.id;
      params.search = items.search;

      getItems(params).then(() => changeItemsPerPage(params.perPage));
    };
  };

  onTableSortChange = (agencyField, getItems, changeItemsSort) => {
    return (name, order) => {
      const items = this.props.agency[agencyField];
      const params = {
        agencyId: this.props.id,
        page: items.meta.current_page,
        perPage: items.perPage,
        sort: {name, order},
        search: items.search,
      };

      getItems(params).then(() => changeItemsSort(params.sort));
    };
  };

  onTableSearchChange = (agencyField, getItems, changeItemsSearch) => {
    return (value) => {
      const items = this.props.agency[agencyField];
      const params = {
        agencyId: this.props.id,
        page: 1,
        perPage: items.perPage,
        sort: items.sort,
        search: value,
      };

      getItems(params).then(() => changeItemsSearch(params.search));
    };
  };

  handleEditBasicInformationClick = () => {
    this.setState({
      editBasicInformation: !this.state.editBasicInformation,
    });
    this.props.getSubsidiaries();
    this.props.getAdminUsers();
  };

  handleEditAccessLevelClick = (clientId, companyName) => {
    this.props.getAgencyMemberships(this.props.id).then(() =>
      this.setState({
        showEditAccessLevelModal: true,
        editAccessLevelModalClientId: clientId,
        editAccessLevelCompanyName: companyName,
      }),
    );
  };

  handleSaveBasicInformationClick = (data) => {
    return this.props
      .patchAgency(data.agency.id, data)
      .then(() => {
        this.handleEditBasicInformationClick();
        this.updateBreadcrumbs(data.name);
      })
      .catch((error) => {
        throw error;
      });
  };

  handleUserCheck = (index, checked) => {
    const users = this.props.agency.agencyMemberships;
    users[index].checked = checked;
  };

  handleEditAccessLevelSave = () => {
    const users = this.props.agency.agencyMemberships.filter(
      (item) =>
        typeof item.checked !== 'undefined' &&
        item.checked !==
          (typeof find(item.company_accesses, (i) => i.company_id === this.state.editAccessLevelModalClientId) !==
            'undefined'),
    );

    const data = {
      company: {
        company_accesses: users.map((user) =>
          user.checked
            ? {
                agency_membership_id: user.id,
              }
            : {
                id: find(user.company_accesses, (i) => i.company_id === this.state.editAccessLevelModalClientId).id,
                _destroy: true,
              },
        ),
      },
    };
    const joins = 'include[]=company_accesses';
    return fetch(`${API_PATH}${API_METHODS.COMPANIES}/${this.state.editAccessLevelModalClientId}?${joins}`, {
      method: 'PUT',
      headers: getAuthHeaders(),
      body: JSON.stringify(data),
    }).then(() => {
      this.setState({
        showEditAccessLevelModal: false,
      });

      const {users: usersData} = this.props.agency;
      const usersParams = {
        agencyId: this.props.id,
        page: usersData.meta.current_page,
        perPage: usersData.perPage,
        sort: usersData.sort,
      };
      this.props.getUsers(usersParams);

      const {clients: clientsData} = this.props.agency;
      const clientsParams = {
        agencyId: this.props.id,
        page: clientsData.meta.current_page,
        perPage: clientsData.perPage,
        sort: clientsData.sort,
      };
      this.props.getClients(clientsParams);

      this.props.getAgencyMemberships(this.props.id);
    });
  };

  setReInviteUserId = (reInviteUserId) => {
    this.setState({reInviteUserId});
  };

  updateCurrentUsers = () =>
    this.props.getUsers({
      agencyId: this.props.id,
      page: this.props.agency.users.meta.current_page,
      perPage: this.props.agency.users.perPage,
      sort: this.props.agency.users.sort,
    });

  handleNewUserModalClose = () => {
    this.handleResetUsersSearch();
    this.setState({
      addNewUserStep: 0,
      addNewUserObject: {},
      addNewUserExists: false,
      showEditUserModal: false,
    });
    this.props.setDefaultNewUser();
    this.props.getAgency(this.props.id);
  };

  handleNewUserModalSave = () => {
    const {reInviteUserId} = this.state;

    if (reInviteUserId) {
      this.props.resendInvitation(reInviteUserId);
      this.setState({reInviteUserId: null});
    }

    this.handleNewUserModalClose();
  };

  fetchClients = () => {
    return this.props.getClients({
      agencyId: this.props.id,
      page: this.props.agency.clients.meta.current_page || 1,
      perPage: this.props.agency.clients.perPage || 5,
      sort: this.props.agency.clients.sort || {name: 'name', order: 'ASC'},
    });
  };

  handleDeleteUsersClick = () => {
    const {id: clientId, agency} = this.props;
    const {
      users: {data: users},
      selectedUsers,
    } = agency;

    const usersToDelete = [];
    const usersWithMemberships = [];

    // Using an access pool to reduce data access time from O(n^2) to O(n)
    const usersPool = {};
    users.forEach((user) => {
      usersPool[user.id] = user;
    });

    selectedUsers.forEach((id) => {
      const clientUser = usersPool[id].client_user;
      const {operations = [], full_name} = clientUser;

      const hasConnectedOperation = operations.some(({client_id}) => client_id === clientId);

      if (hasConnectedOperation) {
        usersWithMemberships.push({id, full_name});
      } else {
        usersToDelete.push(clientUser);
      }
    });

    this.setState(
      {
        selectedUsersWithMemberships: usersWithMemberships,
        selectedUsersWithoutMemberships: usersToDelete,
        showDeleteUsersModal: usersWithMemberships.length > 0,
      },
      () => {
        if (usersWithMemberships.length < 1) {
          this.handleDeleteUsersConfirm();
        }
      },
    );
  };

  handleDeleteUsersConfirm = () => {
    const newState = {showDeleteUsersModal: false};

    if (this.state.selectedUsersWithoutMemberships.length) {
      newState.deletingUser = {
        index: 0,
        user: this.state.selectedUsersWithoutMemberships[0],
        soft: false,
      };
    }

    this.setState(newState);
  };

  onChangeUserClick = (user) => {
    this.setState({
      showEditUserModal: true,
      editingUser: {
        ...user,
        first_name: user.client_user.first_name,
        last_name: user.client_user.last_name,
        email: user.client_user.email,
        civility: {value: user.client_user.title},
        all_companies_access: '' + user.all_companies_access,
      },
    });
  };

  onEditAccessClients = (values) => {
    const newValues = {};
    if (values.title) {
      newValues.title = values.title;
    }
    if (values.first_name) {
      newValues.first_name = values.first_name;
    }
    if (values.last_name) {
      newValues.last_name = values.last_name;
    }
    if (values.civility) {
      newValues.civility = values.civility;
    }
    if (values.phone) {
      newValues.phone = values.phone;
    }
    this.setState({
      showEditUserModal: false,
      showEditClientsAccessModal: 1,
      editingUser: {
        ...this.state.editingUser,
        ...newValues,
      },
    });
  };

  returnToEditUserModal = () => {
    this.setState({
      showEditUserModal: true,
      showEditClientsAccessModal: 0,
      showEditAgencyAccessModal: 0,
    });
  };

  onNewOperationAdded = () => {
    this.setState({showAddNewOperationModal: false});
    const ongoingParams = {
      sort: this.props.agency.ongoingOperations.sort,
      agencyId: this.props.id,
      page: this.props.agency.ongoingOperations.meta.current_page,
      perPage: this.props.agency.ongoingOperations.perPage,
    };
    this.props.getOngoingOperations(ongoingParams);
    const finishedParams = {
      sort: this.props.agency.finishedOperations.sort,
      agencyId: this.props.id,
      page: this.props.agency.finishedOperations.meta.current_page,
      perPage: this.props.agency.finishedOperations.perPage,
    };
    this.props.getFinishedOperations(finishedParams);
  };

  handleShowModal = (name) => {
    this.setState({visibleModal: name});
  };

  handleCloseModal = () => this.setState({visibleModal: ''});

  handleDeleteReport = (id, isImport) => {
    this.setState({
      reportForDelete: {id, isImport},
      showDeleteReport: true,
    });
  };

  getReports = () => {
    this.props.getLeadsReports({
      include: {
        user: null,
        lead_transfer: {
          memberships: {
            client_user: null,
          },
        },
      },
      q: {
        lead_transfer_client_id_eq: this.props.id,
        s: 'created_at DESC',
      },
    });
  };

  onDeleteReport = ({id, isImport}) => {
    if (isImport) {
      this.props.deleteApiLeadsImport(id).then(() => {
        this.getReports();
      });
    } else {
      this.props.deleteApiLeadsExport(id).then(() => {
        this.getReports();
      });
    }
    this.setState({showDeleteReport: false});
  };

  handleResetUsersSearch = () => {
    const onUsersSearchChange = this.onTableSearchChange('users', this.props.getUsers, this.props.changeUsersSearch);
    onUsersSearchChange('');
    this.setState({resetUsersSearch: true});
  };

  handleChangeClientManagement = (nextState) => {
    this.props.patchAgency(this.props.id, {agency: {client_management: nextState}});
  };

  render() {
    const {editingOptIn, visibleModal, reportForDelete, isLoading, deletingUser, selectedUsersWithoutMemberships} =
      this.state;
    const {
      languageState,
      lang,
      changeSelectedUsers,
      getClients,
      getUsers,
      getOngoingOperations,
      getFinishedOperations,
      patchAgency,
      changeUsersPerPage,
      changeClientsPerPage,
      changeOngoingOperationsPerPage,
      changeFinishedOperationsPerPage,
      changeUsersSearch,
      changeClientsSort,
      changeUsersSort,
      changeOngoingOperationsSort,
      changeFinishedOperationsSort,
      isAdmin,
      optIns,
      usersFullList,
    } = this.props;
    const adminId = this.props.userState.payload.user.id;
    const {
      selectedUsers,
      users,
      ongoingOperations,
      finishedOperations,
      clients,
      subsidiaries,
      agencyMemberships,
      adminUsers,
      agency,
      clientUser,
    } = this.props.agency;

    const onClientsPageChange = this.onTablePageChange('clients', getClients, changeClientsPerPage);
    const onUsersPageChange = this.onTablePageChange('users', getUsers, changeUsersPerPage);
    const onOngoingOperationsPageChange = this.onTablePageChange(
      'ongoingOperations',
      getOngoingOperations,
      changeOngoingOperationsPerPage,
    );
    const onFinishedOperationsPageChange = this.onTablePageChange(
      'finishedOperations',
      getFinishedOperations,
      changeFinishedOperationsPerPage,
    );

    const onClientsSortChange = this.onTableSortChange('clients', getClients, changeClientsSort);
    const onUsersSortChange = this.onTableSortChange('users', getUsers, changeUsersSort);
    const onOngoingOperationsSortChange = this.onTableSortChange(
      'ongoingOperations',
      getOngoingOperations,
      changeOngoingOperationsSort,
    );
    const onFinishedOperationsSortChange = this.onTableSortChange(
      'finishedOperations',
      getFinishedOperations,
      changeFinishedOperationsSort,
    );

    const onUsersSearchChange = this.onTableSearchChange('users', getUsers, changeUsersSearch);

    return (
      <div className={b()}>
        <LeadsImportFileModal
          show={visibleModal === Agency.MODALS_MAP.IMPORT_FILE_MODAL}
          onClose={this.handleCloseModal}
          onConfirm={() => this.handleShowModal(Agency.MODALS_MAP.IMPORT_SUMMARY_MODAL)}
        />
        <LeadsPortSummaryModal
          show={visibleModal === Agency.MODALS_MAP.IMPORT_SUMMARY_MODAL}
          onClose={this.handleCloseModal}
        />
        <ConfirmationModal
          show={this.state.showDeleteReport}
          onClose={() => this.setState({showDeleteReport: false})}
          onCancel={() => this.setState({showDeleteReport: false})}
          title={lang.DELETE_REPORT_TITLE_TEXT}
          message={lang.DELETE_REPORT_MODAL_TEXT}
          cancelText={lang.DELETE_CLIENTS_PAGE_MODAL_CANCEL}
          confirmText={lang.DELETE_CLIENTS_PAGE_MODAL_CONFIRM}
          className="modal-window--theme-1"
          buttonConfirmClass="button--bg-1"
          buttonCancelClass="button--bg-5"
          onConfirm={() => this.onDeleteReport(reportForDelete)}
        />

        <NewUserStep1Modal
          show={this.state.addNewUserStep === 1}
          onClose={this.handleNewUserModalClose}
          onCancel={this.handleNewUserModalClose}
          onNext={({email, ...s}) => this.props.getClientUser(email).then(() => this.setState(s))}
          clientId={this.props.id}
          totalSteps={Agency.NUMBER_OF_STEPS}
        />
        <NewUserStep2Modal
          user={this.state.addNewUserObject}
          show={this.state.addNewUserStep === 2}
          onClose={this.handleNewUserModalClose}
          onCancel={this.handleNewUserModalClose}
          initialValues={clientUser}
          onNext={(s) => this.setState(s)}
          totalSteps={Agency.NUMBER_OF_STEPS}
          getClientUser={() => this.props.getClientUser(clientUser.email)}
          onReactivate={this.setReInviteUserId}
        />
        <NewUserAgencyStep3Modal
          user={this.state.addNewUserObject}
          show={this.state.addNewUserStep === 3}
          initialValues={clientUser}
          onClose={this.handleNewUserModalClose}
          onBack={() => this.setState({addNewUserStep: 2})}
          onNext={(s) => this.setState(s)}
          clientId={this.props.id}
        />
        <NewUserAccessClientsModal
          id={this.props.id}
          user={this.state.addNewUserObject}
          show={this.state.addNewUserStep === 31}
          onBack={() => this.setState({addNewUserStep: 3})}
          onClose={this.handleNewUserModalClose}
          mapClients={Agency.mapClients}
          onNext={(s) => this.setState(s)}
        />
        <NewUserStep4Modal
          user={this.state.addNewUserObject}
          show={this.state.addNewUserStep === 4}
          adminId={adminId}
          clientUserId={clientUser && clientUser.id}
          onBack={() => this.setState({addNewUserStep: 3})}
          onClose={this.handleNewUserModalClose}
          onCreate={(s) =>
            s ? this.setState(s) : this.updateCurrentUsers().then(this.fetchClients).then(this.handleNewUserModalSave)
          }
          isAgency
        />
        <EditUserModal
          show={this.state.showEditUserModal}
          isAgency
          editingUser={this.state.editingUser}
          initialValues={this.state.editingUser}
          onClose={this.handleNewUserModalClose}
          onSave={this.updateMe}
          onEditAccessClients={this.onEditAccessClients}
          onEditAccessAgency={(s) => this.setState(s)}
        />
        <NewUserAgencyStep3Modal
          user={this.state.editingUser}
          show={this.state.showEditClientsAccessModal === 1}
          editingUser
          initialValues={this.state.editingUser}
          onClose={this.returnToEditUserModal}
          onBack={this.returnToEditUserModal}
          onNext={(s) => this.setState(s)}
        />
        <NewUserAccessClientsModal
          id={this.props.id}
          user={this.state.editingUser}
          show={this.state.showEditClientsAccessModal === 2}
          editingUser
          onBack={() => this.setState({showEditClientsAccessModal: 1})}
          onClose={this.returnToEditUserModal}
          mapClients={Agency.mapClients}
          onNext={(s) => this.setState(s)}
        />
        <NewUserStep4Modal
          user={this.state.editingUser}
          show={this.state.showEditAgencyAccessModal === 1}
          adminId={adminId}
          editingUser
          clientUser={clientUser}
          clientId={this.props.id}
          onBack={this.returnToEditUserModal}
          onClose={this.returnToEditUserModal}
          onCreate={(s) => this.setState(s)}
          isAgency
        />

        <ConfirmationModal
          show={this.state.showDeleteAgencyModal}
          onClose={() => this.setState({showDeleteAgencyModal: false})}
          onCancel={() => this.setState({showDeleteAgencyModal: false})}
          title={lang.DELETE_AGENCY_PAGE_MODAL_TITLE}
          message={lang.DELETE_AGENCY_PAGE_MODAL_TEXT}
          cancelText={lang.DELETE_CLIENTS_PAGE_MODAL_CANCEL}
          confirmText={lang.DELETE_CLIENTS_PAGE_MODAL_CONFIRM}
          className="modal-window--theme-1"
          buttonConfirmClass="button--bg-1"
          buttonCancelClass="button--bg-5"
          onConfirm={() => this.props.deleteAgency(this.props.id)}
        />

        <DeleteUsersModal
          show={this.state.showDeleteUsersModal}
          onClose={() => {
            this.setState({showDeleteUsersModal: false});
            this.props.changeSelectedUsers([]);
            this.handleResetUsersSearch();
            this.updateUsers();
          }}
          errorMessage={this.state.errorMessageDeleteUserModal}
          users={this.state.selectedUsersWithMemberships}
          onConfirm={this.handleDeleteUsersConfirm}
        />

        <AddNewOperationModal
          show={this.state.showAddNewOperationModal}
          onClose={() => this.setState({showAddNewOperationModal: false})}
          onConfirm={this.onNewOperationAdded}
          clientId={this.props.id}
          isClientNetwork={agency && agency.client_network}
        />
        {this.state.showAddNewOptInModal && (
          <AddNewOptInModal
            onClose={() => this.setState({showAddNewOptInModal: false})}
            onConfirm={() => this.setState({showAddNewOptInModal: false}, this.updateMe)}
            onDelete={() => this.setState({showAddNewOptInModal: false, showDeleteOptInModal: true})}
            editingItem={editingOptIn}
            clientId={this.props.id}
          />
        )}

        <ConfirmationModal
          show={this.state.showDeleteOptInModal}
          onClose={() => this.setState({showDeleteOptInModal: false, showAddNewOptInModal: true})}
          onCancel={() => this.setState({showDeleteOptInModal: false, showAddNewOptInModal: true})}
          message={lang.DELETE_OPT_IN_MODAL.BODY_TEXT}
          title={lang.DELETE_OPT_IN_MODAL.TITLE}
          cancelText={lang.CANCEL_BUTTON}
          confirmText={lang.DELETE_OPT_IN_MODAL.CONFIRM}
          className="theme-color-2"
          buttonConfirmClass="button--bg-2"
          buttonCancelClass="button--bg-11"
          onConfirm={() => deleteMethod(`${API_METHODS.OPT_IN_COLUMNS}/${editingOptIn.id}`).then(this.updateMe)}
        />

        {this.state.showSelectUserModal && (
          <SelectUserModal
            show
            users={usersFullList}
            clientId={this.props.id}
            onClose={() => this.setState({showSelectUserModal: false})}
          />
        )}

        <div className="row">
          <div className="col-xs-9">
            <div className="page__title-block">
              <TitleBlock theme separator>
                <TitleBlock.Item>{agency && `${agency.name} ${agency.brand_name}`}</TitleBlock.Item>
                {isAdmin && <TitleBlock.Item className="">{lang.AGENCY_TITLE}</TitleBlock.Item>}
                <button className="button button--bg-1" onClick={() => this.setState({showSelectUserModal: true})}>
                  <span className="bold">{lang.CLIENT_SHOW_USER_MODAL_BUTTON}</span>
                </button>
              </TitleBlock>
            </div>
          </div>
          <div className="col-xs-3">
            {isAdmin && [
              <div className="pull-right" key="icon">
                <button
                  className="button button--circle button--bg-1 button--delete vertical-align-top"
                  onClick={() => this.setState({showDeleteAgencyModal: true})}
                >
                  <Icon name="trash" className="button__icon" width={17} height={19} />
                </button>
              </div>,
              <AddLogoButton
                key="add-logo-button"
                clientId={this.props.id}
                updateMe={this.updateMe}
                buttonText={lang.ADD_LOGO_BUTTON}
              />,
            ]}
          </div>
        </div>
        <div className="page__top">
          <div className="page__block-container">
            <div className={`page__block page__block--size-${isAdmin ? 1 : 3}`}>
              <BasicInformationCard
                onEditClick={this.handleEditBasicInformationClick}
                onChangeClientManagement={this.handleChangeClientManagement}
                clientManagement={agency?.client_management}
                editMode={this.state.editBasicInformation}
                isAdmin={isAdmin}
              >
                <BasicInformationForm
                  edit={this.state.editBasicInformation}
                  onCancel={this.handleEditBasicInformationClick}
                  onSave={this.handleSaveBasicInformationClick}
                  subsidiaries={subsidiaries}
                  memberships={agencyMemberships}
                  adminUsers={adminUsers}
                />
              </BasicInformationCard>
            </div>
            <div className="page__block page__block--size-1">
              <div className="page__block--height-2">
                <SocialNetworkAccountsCard
                  data={get(agency, 'social_accounts')}
                  accountsCount={get(agency, 'social_accounts.length')}
                  refresh={this.updateMe}
                  clientId={this.props.id}
                  isAdmin={isAdmin}
                />
              </div>
              {isAdmin && (
                <VisualsCard
                  visualsCount={agency && agency.visuals_count}
                  title={lang.VISUALS_CARD.TITLE}
                  goToLinkText={lang.VISUALS_CARD.GO_TO_VISUALS_LINK}
                  totalText={lang.VISUALS_CARD.TOTAL_TEXT}
                  itemText={lang.VISUALS_CARD.NO_OF_VISUALS_TEXT}
                />
              )}
            </div>
            {isAdmin && [
              <div className="page__block page__block--size-2" key="phones-email">
                <PhonesEmailsCard
                  data={agency && agency.message_senders}
                  refresh={this.updateMe}
                  clientId={this.props.id}
                />
              </div>,
              <div className="page__block page__block--size-2" key="password-expiration">
                <PasswordExpirationCard isAgency />
              </div>,
              <div className="page__block page__block--size-1" key="leads">
                <LeadsCard
                  languageState={languageState}
                  client={agency}
                  leadsReports={this.props.leadsReports}
                  clientId={this.props.id}
                  leadsTotalCount={this.props.leadsCount}
                  deleteReport={this.handleDeleteReport}
                  getReports={this.getReports}
                  onImportFile={() => this.handleShowModal(Agency.MODALS_MAP.IMPORT_FILE_MODAL)}
                  columnAdapters={this.props.allColumns}
                  clientUsers={this.props.clientUsers}
                  generalColumns={this.generalColumns()}
                />
              </div>,
              <div className="page__block page__block--size-1" key="opt-ins">
                <OptInsCard
                  languageState={languageState}
                  data={optIns}
                  onAddClick={() => this.setState({showAddNewOptInModal: true, editingOptIn: {}})}
                  onEditClick={(res) => this.setState({showAddNewOptInModal: true, editingOptIn: res})}
                />
              </div>,
              <div className={cn('page-block', b('block', ['accesses']))} key="auto-creation-access">
                {!!agency && (
                  <AutoCreationAccessCard
                    clientType="agency"
                    clientId={this.props.id}
                    isLoading={isLoading}
                    patchClient={patchAgency}
                    accessLevels={agency?.user_access_levels}
                    couponTypes={agency?.available_coupons}
                    permissions={{
                      crm_management: agency?.crm_management,
                      user_creation_allowed: agency?.user_creation_allowed,
                    }}
                    autoConfiguration={agency?.auto_configuration === true}
                    isAgency
                  />
                )}
              </div>,
            ]}
          </div>
        </div>

        <EditAccessLevelModal
          show={this.state.showEditAccessLevelModal}
          onClose={() => this.setState({showEditAccessLevelModal: false})}
          users={agencyMemberships}
          clientId={this.state.editAccessLevelModalClientId}
          companyName={this.state.editAccessLevelCompanyName}
          agencyName={agency && agency.name}
          onCheck={this.handleUserCheck}
          onSave={this.handleEditAccessLevelSave}
        />

        {isAdmin && (
          <AgencyClientsTable
            lang={languageState.payload.AGENCY}
            data={clients.data}
            totalPages={clients.meta.total_pages || 1}
            totalItems={clients.meta.total_count}
            currentPage={clients.meta.current_page}
            onPageChange={onClientsPageChange}
            onSortChange={onClientsSortChange}
            onAccessLevelsClick={this.handleEditAccessLevelClick}
          />
        )}

        <OngoingOperationsBlock
          languageState={languageState}
          ongoingOperations={ongoingOperations}
          finishedOperations={finishedOperations}
          onOngoingOperationsPageChange={onOngoingOperationsPageChange}
          onOngoingOperationsSortChange={onOngoingOperationsSortChange}
          onFinishedOperationsPageChange={onFinishedOperationsPageChange}
          onFinishedOperationsSortChange={onFinishedOperationsSortChange}
          onAddNew={() => this.setState({showAddNewOperationModal: true})}
        />

        <UsersTable
          addNewClick={() => this.setState({addNewUserStep: 1})}
          deleteUsersClick={this.handleDeleteUsersClick}
          changeSelectedRecords={changeSelectedUsers}
          selectedRecords={selectedUsers}
          needToUpdate={users.needToUpdate}
          data={Agency.mapUsersData(users.data)}
          totalPages={users.meta && users.meta.total_pages}
          totalItems={users.meta && users.meta.total_count}
          currentPage={users.meta && users.meta.current_page}
          onPageChange={onUsersPageChange}
          onSortChange={onUsersSortChange}
          perPage={users.perPage}
          onSearch={onUsersSearchChange}
          onChangeUserClick={this.onChangeUserClick}
          isAdmin={isAdmin}
          resetUsersSearch={this.state.resetUsersSearch}
          onResetUsersSearch={() => this.setState({resetUsersSearch: false})}
        />
        {agency?.id && (
          <ClientUserDeactivationWizard
            currentUser={deletingUser}
            clientId={agency.id}
            allUsers={selectedUsersWithoutMemberships}
            changeCurrentUser={(data) => this.setState({deletingUser: data})}
            fetchUsers={() => {
              this.props.changeSelectedUsers([]);
              this.handleResetUsersSearch();
              this.fetchClients();
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  languageState: state.languageState,
  lang: state.languageState.payload.AGENCY,
  langBC: state.languageState.payload.BREADCRUMBS,
  langLeads: state.languageState.payload.LEADS_LIST,
  userState: state.userState,
  agency: state.agency,
  optIns: selectOptIns(state),
  isAdmin: selectIsAdmin(state),
  leadsReports: selectImportExportReports(state),
  allColumns: selectColumnAdaptersForImportExport(state),
  leadsCount: selectLeadsTotalCount(state),
  clientUsers: selectClientUsers(state, ownProps.id),
  usersFullList: selectUsersFullList(state),
});

const mapDispatchToProps = {
  changeSelectedUsers: changeSelectedUsersAction,

  changeUsersPerPage: changeUsersPerPageAction,
  changeOngoingOperationsPerPage: changeOngoingOperationsPerPageAction,
  changeFinishedOperationsPerPage: changeFinishedOperationsPerPageAction,
  changeClientsPerPage: changeClientsPerPageAction,

  changeUsersSort: changeUsersSortAction,
  changeOngoingOperationsSort: changeOngoingOperationsSortAction,
  changeFinishedOperationsSort: changeFinishedOperationsSortAction,
  changeClientsSort: changeClientsSortAction,

  changeUsersSearch: changeUsersSearchAction,

  getUsers: getUsersAction,
  getClients: getClientsAction,
  getOngoingOperations: getOngoingOperationsAction,
  getFinishedOperations: getFinishedOperationsAction,
  getSubsidiaries: getSubsidiariesAction,
  getAgencyMemberships: getAgencyMembershipsAction,
  getAdminUsers: getAdminUsersAction,
  getOptIns: getOptInsAction,
  getAgency: getAgencyAction,
  getAgencyUsers: getAgencyUsersAction,
  patchAgency: patchAgencyAction,
  deleteAgency: deleteAgencyAction,
  getClientUser: getClientUserAction,
  clearStore: clearStoreAction,
  setDefaultNewUser: setDefaultNewUserAction,
  setBreadcrumbs: setBreadcrumbs,
  clearBreadcrumbs: clearBreadcrumbs,
  getLeadsReports,
  getAvailableColumnAdaptersByClient,
  deleteApiLeadsExport,
  deleteApiLeadsImport,
  getLeads,
  getClientUsersWithMemberships: getClientUsersWithMembershipsAction,
  resendInvitation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Agency);
