import {SET_DUB_FA, SET_EMAIL} from './types';

export const setDoubleFactorAuthOptions = (payload) => ({
  type: SET_DUB_FA,
  payload,
});

export const setEmailAction = (payload) => ({
  type: SET_EMAIL,
  payload,
});
