import React from 'react';

import {Field} from 'redux-form';

import BaseFieldComponent from 'client/common/fields/base-field';
import {CheckboxInput} from 'client/common/inputs';

import {BaseFieldComponentProps} from '../base-field/types';

const InputComponent = (props) => (
  <BaseFieldComponent
    Component={CheckboxInput}
    onCheck={(state) => props.input?.onChange?.(state)}
    checked={!!props.input.value}
    {...props}
  />
);

InputComponent.propTypes = BaseFieldComponentProps;

const CheckboxField = (props) => {
  return <Field component={InputComponent} {...props} />;
};

export default CheckboxField;
