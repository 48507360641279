import React from 'react';

import {Field} from 'redux-form';

import BaseFieldComponent from 'client/common/fields/base-field';
import SelectDropdown from 'client/common/selects/select-dropdown';

import {BaseFieldComponentProps} from '../base-field/types';

const InputComponent = (props) => <BaseFieldComponent Component={SelectDropdown} {...props} />;

InputComponent.propTypes = BaseFieldComponentProps;

const SelectField = (props) => {
  return <Field component={InputComponent} {...props} />;
};

export default SelectField;
