import merge from 'lodash/merge';
import {AnyAction} from 'redux';

import {injectUiIdTags} from 'client/services/translations';

import en from 'client/languages/en.json';
import {Language, Translations, TranslationsSourceData} from 'client/models/language/types';

import types from './types';

export type LangTypes = Translations;

const DEFAULT_LANGUAGE = 'en';
const defaultTranslations: TranslationsSourceData = en;
const defaultTranslationsPrepared = injectUiIdTags(defaultTranslations) as LangTypes;

type State = {
  payload: LangTypes;
  currentLanguage: string;
  translations: Record<string, LangTypes>;
  fetchedTranslations: Record<string, boolean>;
  languages: Language[];
  loadingLanguages: boolean;
};

const initialState: State = {
  payload: defaultTranslationsPrepared,
  currentLanguage: DEFAULT_LANGUAGE,
  translations: {
    [DEFAULT_LANGUAGE]: defaultTranslationsPrepared,
  } as Record<string, LangTypes>,
  fetchedTranslations: {},
  languages: [],
  loadingLanguages: false,
};

const reducer = (_state_ = initialState, action: AnyAction) => {
  const state = Object.assign({}, _state_);
  switch (action.type) {
    case types.SET_LANGUAGE: {
      const lang = action.currentLanguage;
      if (state.translations[lang]) {
        state.payload = state.translations[lang];
        state.currentLanguage = lang;
      } else {
        state.payload = defaultTranslationsPrepared;
        state.currentLanguage = DEFAULT_LANGUAGE;
      }
      return state;
    }
    case types.FETCH_TRANSLATIONS_SUCCESS: {
      const lang = action.meta.lang;
      state.translations[lang] = injectUiIdTags(merge({}, defaultTranslations, action.payload)) as LangTypes;
      state.fetchedTranslations[lang] = true;
      state.payload = state.translations[lang];
      return state;
    }
    case types.FETCH_LANGUAGES_REQUEST:
      state.loadingLanguages = true;
      return state;
    case types.FETCH_LANGUAGES_SUCCESS:
      if (Array.isArray(action.payload.languages)) {
        state.languages = action.payload.languages;
      }
      state.loadingLanguages = false;
      return state;
    case types.FETCH_LANGUAGES_ERROR:
      state.loadingLanguages = false;
      return state;
    default:
      return state;
  }
};

export default reducer;
