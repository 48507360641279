import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class TimerStep extends Component {
  static propTypes = {
    step: PropTypes.object,
    variableValues: PropTypes.object,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    step: {},
    variableValues: {},
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.RESULT_MODAL;
  }

  render() {
    const {LANGUAGE} = this;
    const {step, variableValues} = this.props;
    const {implementation} = step;

    let time_value = null;
    switch (implementation.kind) {
      case 'in':
        time_value = implementation.perform_in;
        break;
      case 'at':
        time_value = implementation.perform_at;
        break;
      case 'as':
      case 'in_value':
        time_value = variableValues[implementation.variable_id];
        break;
      default:
        break;
    }

    return (
      <div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{LANGUAGE.TIMER_PERFORM_TYPES[implementation.kind]}</div>
            <div className="fake-input__field">
              {time_value} {LANGUAGE.TIME_UNITS[implementation.time_unit || 'seconds']}
            </div>
          </div>
        </div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{LANGUAGE.COMMENT_LABEL}</div>
            <div className="fake-input__textarea">{step.comment}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(TimerStep);
