import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {CLIENT_PAGES} from 'client/common/config';

import {TranslationJsx} from 'client/models/language/types';

import Icon from '../../../../common/icon';
import AuthButton from '../../components/auth-button';
import AuthField from '../../components/auth-field';
import AuthLink from '../../components/auth-link';
import AuthMessage from '../../components/auth-message';
import {maskEmail} from '../helpers';
import {OPTIONS} from '../index';

import cssModule from '../auth-double-factor.module.scss';

const b = bem('auth-double-factor', {cssModule});

const AuthDoubleFactorSelectType = ({credentials, errorMessage, onChange, option, receiveCode}) => {
  const lang = useLanguage('AUTH.DOUBLE_FACTOR_AUTHENTIFICATION');

  const changeTypeHandler = (e) => {
    onChange(e.target.value);
  };

  const contactEmail = lang.CONTACT_NOTIFICATION_EMAIL.replace('{email}', credentials.subsidiary_email);
  const contactPhone = lang.CONTACT_NOTIFICATION_PHONE.replace('{phone}', credentials.subsidiary_phone);

  return (
    <>
      <AuthMessage text={lang.HEADER} description />
      <AuthField
        name="type"
        type="radio-group-input"
        onChange={changeTypeHandler}
        value={option}
        errorMessage={errorMessage}
        options={[
          {
            label: `${lang.EMAIL_TEXT} ${maskEmail(credentials.email)}`,
            value: OPTIONS.EMAIL,
          },
          {
            label: `${lang.SMS_TEXT} ${credentials.phone}`,
            value: OPTIONS.SMS,
          },
        ]}
      />
      <AuthLink to={CLIENT_PAGES.LOGIN} additional label={lang.BACK_LINK} />
      <AuthLink
        className={b('contact-link')}
        label={
          <>
            {lang.CONTACT_ADMINISTRATOR_LINK}
            <Icon name="arrow-right" />
          </>
        }
        to={CLIENT_PAGES.LOGIN}
        additional
        main
        disabled
        tooltip={
          <div>
            <p>{contactEmail}</p>
            <p>{contactPhone}</p>
          </div>
        }
      />
      <AuthButton onClick={receiveCode} label={lang.CONTINUE} />
    </>
  );
};

AuthDoubleFactorSelectType.propTypes = {
  credentials: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    password: PropTypes.string,
    subsidiary_email: PropTypes.string,
    subsidiary_phone: PropTypes.string,
  }).isRequired,
  receiveCode: PropTypes.func.isRequired,
  option: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
};

export default AuthDoubleFactorSelectType;
