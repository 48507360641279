import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useAppMedia} from 'client/services/hooks';

import NoData from 'client/common/various/no-data';

import Spinner from 'client/components/common/spinner';

import cssModule from './winning-base-container.module.scss';

const b = bem('winning-base-container', {cssModule});

type WinningTasksListProps = {
  className?: string;
  classNames?: {
    content?: string;
  };
  children: React.ReactNode;
  loading?: boolean;
  empty?: boolean;
};

const WinningBaseContainer: React.FC<WinningTasksListProps> = (props) => {
  const {className, children, loading, empty, classNames} = props;
  const {isTablet} = useAppMedia();

  return (
    <div className={cn(b({mobile: isTablet}), className)}>
      {loading && <Spinner pageCentered />}
      {!loading && empty && <NoData pageCentered />}
      {!loading && !empty && <div className={cn(b('content'), classNames?.content)}>{children}</div>}
    </div>
  );
};

export default WinningBaseContainer;
