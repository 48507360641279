import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import moment from 'moment';
import {isLength, matches} from 'validator';

import {passwordRegExp} from 'client/services/helpers';

export const URL_REGEX = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/i;
export const RESTRICTED_CHARACTERS =
  /\s|\u0025|\u003C|\u003E|\u0023|\u0022|\u007B|\u007D|\u007C|\u005C|\u005E|\u007E|\u005B|\u005D|\u0060/g;

const DAY_REGEX = /(0[1-9]|[12]\d|3[01])/;
const MONTH_REGEX = /(0[1-9]|1[012])/;
const YEAR_REGEX = /2\d{3}/;

// DD/(*|MM)/(*|YYYY)
export const REPEATABLE_DATE_REGEX = new RegExp(
  `^${DAY_REGEX.source}\/(\\*|${MONTH_REGEX.source})\/(\\*|${YEAR_REGEX.source})$`,
);

export function convertValue(value = '') {
  if (isNumber(value)) {
    return value.toString();
  }
  if (typeof value === 'string') {
    return value.trim();
  }

  return value;
}

export function required(message) {
  return (value = '') => (isEmpty(convertValue(value)) ? message : null);
}

export const checkRule = (value, callbacks) => {
  if (Array.isArray(callbacks)) {
    return callbacks.find((cb) => cb(value))?.(value);
  }
  return callbacks(value);
};

export function recognizedAllVariables(message, variables = []) {
  return (value = '') => {
    const regex = /{{([^}{]*)}}/g;
    const unknownVars = [];

    let test = regex.exec(value);
    while (test) {
      const id = Number(test[1]);
      if (isNaN(id) || !variables.find((i) => i.id === id)) {
        unknownVars.push(test[1]);
      }

      test = regex.exec(value);
    }

    return unknownVars.length ? message.replace('{VARS}', unknownVars.join(', ')) : null;
  };
}

export function number(message) {
  return (value = '') => (/^-?\d+$/i.test(value) || value === '' ? null : message);
}

export function decimal(message) {
  return (value = '') => (/^[0-9]+\.[0-9]+$/.test(value) || value === '' ? null : message);
}

export function anyDecimal(message) {
  return (value = '') => (/^-?[0-9]+$|^-?[0-9]+[.,][0-9]+$/.test(value) || value === '' ? null : message);
}
export function intAndDecimal(message) {
  return (value = '') => (/^-?[0-9]+$|^-?[0-9]+\.[0-9]+$/.test(value) || value === '' ? null : message);
}
export function string(message) {
  return (value = '') => (/^[a-zA-Z]+$/i.test(value) || value === '' ? null : message);
}

export function anyString(message) {
  return (value = '') => (/^.+$/.test(value) || value === '' ? null : message);
}

export function contains(values, message) {
  return (value) => {
    const numberValue = parseInt(value, 10);

    return values.includes(isNaN(numberValue) ? value : numberValue) ? null : message;
  };
}

export function alphaNumeric(message, withSpace = true) {
  const regex = withSpace ? /^[a-zA-Z0-9 ]+$/ : /^[a-zA-Z0-9]+$/;
  return (value = '') => (regex.test(convertValue(value)) ? null : message);
}

export function maxLength(length, message) {
  return (value = '') => (isLength(convertValue(value), {max: length}) ? null : message);
}

export function minLength(length, message) {
  return (value = '') => (isLength(convertValue(value), {min: length}) ? null : message);
}

export function eqLength(length, message) {
  return (value = '') => (isLength(convertValue(value), {min: length, max: length}) ? null : message);
}

export function greaterThan(_value, message) {
  return (value) => (value > _value || value === '' ? null : message);
}

export function lessThan(_value, message, orEqualTo = false) {
  return (value) => {
    if (value === '') {
      return null;
    }

    if (orEqualTo) {
      return value <= _value ? null : message;
    }
    return value < _value ? null : message;
  };
}

export function match(pattern, message) {
  return (value = '') => (matches(convertValue(value), pattern) ? null : message);
}

export function time(message) {
  return (value = '') => (moment(value, 'HH:mm', true).isValid() ? null : message);
}

export function date(message) {
  return (value = '') => (moment(value, 'DD/MM/YYYY', true).isValid() || value === '' ? null : message);
}

export function dateIsAfter(message, field) {
  return (value = '', fields) =>
    moment(value, 'DD/MM/YYYY', true).isAfter(moment(get(fields, field), 'DD/MM/YYYY', true), 'minutes')
      ? null
      : message;
}

export function datetime(message) {
  return (value = '') => (moment(value).isValid() ? null : message);
}

export function dateTimeIsAfter(message, field) {
  return (value = '', fields) => (moment(value).isAfter(get(fields, field), 'minutes') ? null : message);
}

export function arrayUnique(array = [], message) {
  return (value = '') => (array.indexOf(value) !== -1 ? message : null);
}

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function email(message) {
  return (value = '') => (EMAIL_REGEX.test(value) || value === '' ? null : message);
}

export const PHONE_REGEX = /^\+?\d{1,255}$/;
export const PHONE_REGEX_FR = /^\+33\s?\(?[67]\)?\s?\d{8}$/;

export function isNotEmpty(message) {
  return (value = []) => (value?.length ? null : message);
}

export function phone(message) {
  return (value = '') => (PHONE_REGEX.test(value) || value === '' ? null : message);
}

export function url(message) {
  return (value = '') => (URL_REGEX.test(value) || value === '' ? null : message);
}

export function fileSize(message, sizeMaxSize) {
  return (value = '') => ((value && value[0].size < sizeMaxSize) || value === '' ? null : message);
}

export function fileFormat(message) {
  return (value = '') =>
    (value && value.length > 0 && /^image\/(p?jpe?g|png|(x-)?tiff|gif)$/.test(value[0].type)) || value === ''
      ? null
      : message;
}

export const passwordValidator = (value) => {
  return !passwordRegExp().test(value);
};

export const DATE_REGEX = /^\d{2}\/\d{2}\/\d{4}$/;

export const isEmptyString = (value) => {
  return !value || !value.trim();
};

export const isInvalidDate = (value, format = DATE_REGEX) => {
  return !value.match(format);
};

export const isNotNumber = (value) => {
  return !value.match(/^\d+$/);
};

export const isFromLessThenTo = (from, to, format = 'DD/MM/YYYY') => {
  return moment(to, format).isSameOrBefore(moment(from, format));
};

export function validator(...fns) {
  return (value) => {
    return fns.reduce((acc, item) => (item(value) ? [...acc, item(value)] : acc), []);
  };
}

export const hasRestrictedCharacters = (path) => {
  return RESTRICTED_CHARACTERS.test(path);
};

export const restrictedCharacters = (path) => {
  return path.match(RESTRICTED_CHARACTERS);
};

export const hasObjectTruthyValues = (obj) =>
  Object.values(obj).some((value) => {
    if (typeof value === 'object') {
      return hasObjectTruthyValues(value);
    }
    return Boolean(value);
  });
