import get from 'lodash/get';
import {createSelector} from 'reselect';

import {LangTypes} from 'client/ducks/language/reducer';

import {MainStates} from 'client/types';

export function selectLanguage(state: MainStates) {
  return state.languageState.payload;
}

export const selectCurrentLanguage = (state: MainStates) => state.languageState.currentLanguage;

export function selectLanguageDomain(state: MainStates, domain = '') {
  const language = selectLanguage(state);
  return get(language, domain, {});
}

export const selectAvailableTranslations = (state: MainStates) => state.languageState.translations;
export const selectFetchedTranslations = (state: MainStates) => state.languageState.fetchedTranslations;

export const selectLanguages = (state: MainStates) => state.languageState.languages;

export const selectActiveLanguages = (state: MainStates) => state.languageState.languages.filter((l) => l.active);

export const makeSelectPathLanguage = () =>
  createSelector(
    (state: MainStates) => state.languageState.payload,
    (_: any, path: string) => path,
    (payload: LangTypes, path: string) => get(payload, path, {}),
  );
