import React, {Component} from 'react';

import find from 'lodash/find';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {required, alphaNumeric} from 'client/services/validator';

import {getInterfacesAction, patchInterfaceAction} from 'client/ducks/interfaces/actions';

import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

import './update-interface-name-modal.scss';

class UpdateInterfaceNameModal extends Component {
  static formName = 'UpdateInterfaceNameForm';

  static propTypes = {
    show: PropTypes.bool,
    autotaskId: PropTypes.number,
    interfaceParams: PropTypes.shape({
      interface_name: PropTypes.string,
      interface_code: PropTypes.string,
    }),
    pristine: PropTypes.bool,
    lang: PropTypes.object.isRequired,
    interfaceName: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    fetchInterfaces: PropTypes.func.isRequired,
  };

  rules = {
    name: required(this.props.lang.NAME_IS_REQUIRED_ERROR),
    code: [
      required(this.props.lang.CODE_IS_REQUIRED_ERROR),
      alphaNumeric(this.props.lang.CODE_IS_ALPHA_NUMERIC_ERROR, false),
    ],
  };

  constructor(props) {
    super(props);

    this.state = {
      interfaceParams: this.props.interfaceParams,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.interfaceParams !== this.props.interfaceParams) {
      this.setState({
        interfaceParams: this.props.interfaceParams,
      });
    }
  }

  save = ({interface_name, interface_code}) => {
    const interfaceData = {};
    if (interface_name !== this.state.interfaceParams.interface_name) {
      interfaceData.name_eq = interface_name;
    }
    if (interface_code !== this.state.interfaceParams.interface_code) {
      interfaceData.code_eq = interface_code;
    }
    if (Object.keys(interfaceData).length === 2) {
      interfaceData.m = 'or';
    }

    return this.props
      .fetchInterfaces(
        {
          q: {
            automation_task_id_eq: this.props.autotaskId,
            g: {
              0: {
                ...interfaceData,
              },
            },
          },
        },
        true,
      )
      .then(({payload: {interfaces}}) => {
        const isNameTaken = !!find(interfaces, ({name}) => name === interface_name);
        const isCodeTaken = !!find(interfaces, ({code}) => code === interface_code);
        if (isNameTaken || isCodeTaken) {
          throw new SubmissionError({
            interface_name: isNameTaken ? this.props.lang.NAME_IS_UNIQUE_ERROR : null,
            interface_code: isCodeTaken ? this.props.lang.CODE_IS_UNIQUE_ERROR : null,
          });
        }
      })
      .then(() => {
        this.props.onSubmit({
          name: interface_name,
          code: interface_code,
        });
      });
  };

  render() {
    const {handleSubmit, onClose, lang} = this.props;
    const {rules} = this;

    return (
      <Modal
        show={true}
        dialogClassName="modal-window--width-1 theme-color-8 update-interface-name-modal"
        backdropClassName="modal-window__backdrop"
        title={lang.TITLE_UPDATE}
        onClose={onClose}
        onEnter={() => {
          this.props.change('interface_name', this.props.interfaceParams.interface_name);
          this.props.change('interface_code', this.props.interfaceParams.interface_code);
        }}
      >
        <form noValidate={true} onSubmit={handleSubmit(this.save)}>
          <FormFieldWrap>
            <Field
              label={lang.INTERFACE_NAME_LABEL}
              cssModifier="input--no-min-height"
              name="interface_name"
              type="text"
              component={fieldTemplate}
              validate={rules.name}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              label={lang.INTERFACE_CODE_LABEL}
              cssModifier="input--no-min-height"
              name="interface_code"
              type="text"
              component={fieldTemplate}
              validate={rules.code}
            />
          </FormFieldWrap>
          <div className="update-interface-name-modal__buttons">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onClose}>
              {lang.CANCEL_BUTTON}
            </button>
            <button
              className="button button--bg-7 modal-window__footer-btn"
              type="submit"
              disabled={this.props.pristine}
            >
              {lang.SAVE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const UpdateInterfaceNameForm = reduxForm({
  form: UpdateInterfaceNameModal.formName,
  enableReinitalize: true,
})(UpdateInterfaceNameModal);

export default connect(
  (state, ownProps) => {
    return {
      lang: state.languageState.payload.AUTOTASK.SET_INTERFACE_NAME_MODAL,
      autotaskId: state.autotask.payload.id,
      initialValues: {
        interface_name: ownProps.interfaceParams.interface_name,
        interface_code: ownProps.interfaceParams.interface_code,
      },
    };
  },
  {
    fetchInterfaces: getInterfacesAction,
    patchInterface: patchInterfaceAction,
  },
)(UpdateInterfaceNameForm);
