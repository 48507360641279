import React from 'react';

import PropTypes from 'prop-types';

import BcNavItem from '../../bc-nav-item';

function BcPersonalizeNavItem(props) {
  const {languageState, checked, eventKey, totalCount, mappedCount, ...restProps} = props;

  return (
    <BcNavItem
      title={<div className="break-word">{languageState.PERSONALIZE_NAV}</div>}
      name={
        checked && (
          <div className="break-word">
            {mappedCount} {languageState.OUT_OF_LABEL} {totalCount} {languageState.VARS_MAPPED_LABEL}
          </div>
        )
      }
      eventKey={eventKey}
      checked={checked}
      {...restProps}
    />
  );
}

BcPersonalizeNavItem.propTypes = {
  languageState: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  eventKey: PropTypes.string,
  totalCount: PropTypes.number,
  mappedCount: PropTypes.number,
};

BcPersonalizeNavItem.defaultProps = {
  checked: false,
  eventKey: '',
  totalCount: 0,
  mappedCount: 0,
};

export default BcPersonalizeNavItem;
