import {dateToString} from 'client/services/helpers';

import {LEAD_TRANSFER_TYPES, LEAD_TRANSFER_REPORT_TYPES} from '../../common/config';

export function formatType(type) {
  switch (true) {
    case LEAD_TRANSFER_TYPES.EXPORT === type:
    case LEAD_TRANSFER_TYPES.API_IMPORT === type:
      return 'EXP';
    case LEAD_TRANSFER_TYPES.IMPORT === type:
    case LEAD_TRANSFER_TYPES.API_EXPORT === type:
      return 'IMP';
    default:
      return '';
  }
}

export function formatFormat(format) {
  return format ? format.toUpperCase() : 'API';
}

export function createCode(type, format, id) {
  switch (type) {
    case LEAD_TRANSFER_TYPES.EXPORT:
    case LEAD_TRANSFER_TYPES.API_EXPORT:
      return `EX${format.toUpperCase()}${id}`;
    case LEAD_TRANSFER_TYPES.IMPORT:
    case LEAD_TRANSFER_TYPES.API_IMPORT:
      return `IM${format.toUpperCase()}${id}`;
    default:
      return '';
  }
}

export function getTransfersAmount(type, data) {
  const {total, created, updated} = data;

  switch (type) {
    case LEAD_TRANSFER_REPORT_TYPES.IMPORT:
      return created + updated;
    case LEAD_TRANSFER_REPORT_TYPES.EXPORT:
      return total;
    default:
      return 0;
  }
}

export function mapLeadTransferReports(reports) {
  return reports.map((report) => {
    const {lead_transfer: transfer, total, created, updated, type: reportType, leads_file: file} = report;

    let count = getTransfersAmount(reportType, {total, created, updated});

    return {
      createdAt: dateToString(transfer.created_at),
      id: report.id,
      name: transfer.name,
      transferType: transfer.type,
      type: formatType(transfer.type),
      format: formatFormat(transfer.format),
      leadsCount: count,
      code: createCode(transfer.type, formatFormat(transfer.format), transfer.id),
      comment: transfer.comment,
      status: report.status,
      memberships: report.memberships,
      allowed_for_all: report.lead_transfer.allowed_for_all,
      file,
    };
  });
}
