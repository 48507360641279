import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import types from './types';

export function getDiagrams(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DIAGRAMS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_DIAGRAMS_REQUEST, types.GET_DIAGRAMS_SUCCESS, types.GET_DIAGRAMS_ERROR],
      queryParams,
    });
}

export function getClientDiagrams(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DIAGRAMS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_CLIENT_DIAGRAMS_REQUEST, types.GET_CLIENT_DIAGRAMS_SUCCESS, types.GET_CLIENT_DIAGRAMS_ERROR],
      queryParams,
    });
}

export function getClientDiagram(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENT_DIAGRAMS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_CLIENT_DIAGRAM_REQUEST, types.GET_CLIENT_DIAGRAM_SUCCESS, types.GET_CLIENT_DIAGRAM_ERROR],
      queryParams,
    });
}

export const getDataSources = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.COLUMN_ADAPTER_PERMITTED}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_DATA_SOURCES_REQUEST, types.GET_DATA_SOURCES_SUCCESS, types.GET_DATA_SOURCES_ERROR],
      queryParams,
    });
};

export const createDiagram = (body) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DIAGRAMS}`,
      method: 'POST',
      noCache: true,
      types: [types.CREATE_DIAGRAM_REQUEST, types.CREATE_DIAGRAM_SUCCESS, types.CREATE_DIAGRAM_ERROR],
      body,
    });
};

export const updateDiagram = (id, body) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DIAGRAMS}/${id}`,
      method: 'PUT',
      noCache: true,
      types: [types.UPDATE_DIAGRAM_REQUEST, types.UPDATE_DIAGRAM_SUCCESS, types.UPDATE_DIAGRAM_ERROR],
      body,
    });
};

export const deleteDiagram = (id) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DIAGRAMS}/${id}`,
      method: 'DELETE',
      noCache: true,
      types: [types.DELETE_DIAGRAM_REQUEST, types.DELETE_DIAGRAM_SUCCESS, types.DELETE_DIAGRAM_ERROR],
    });
};

export const clearClientDiagrams = () => ({type: types.CLEAR_CLIENT_DIAGRAMS});
