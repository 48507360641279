import moment from 'moment';

import {DATE_FORMAT, GAME_TYPES} from './constants';

const isEmptyString = (value) => {
  return !value || !value.trim();
};

const DATE_PATTERN = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}(:\d{2})?$/;

const validatePeriod = (values, previousErrors, lang) => {
  const errors = {...previousErrors};

  if (isEmptyString(values.default_from)) {
    errors.default_from = lang.REQUIRED;
  } else if (!values.default_from.match(DATE_PATTERN)) {
    errors.default_from = lang.INVALID_DATE_FORMAT;
  }

  if (isEmptyString(values.default_to)) {
    errors.default_to = lang.REQUIRED;
  } else if (!values.default_to.match(DATE_PATTERN)) {
    errors.default_to = lang.INVALID_DATE_FORMAT;
  }

  if (!errors.default_from && !errors.default_to) {
    const from = moment(values.default_from, DATE_FORMAT);
    const to = moment(values.default_to, DATE_FORMAT);

    if (from.isAfter(to)) {
      errors.default_from = lang.BEFORE_TO;
    }

    if (to.isBefore(from) || to.isSame(from)) {
      errors.default_to = lang.AFTER_FROM;
    }
  }

  return errors;
};

const validateDrawTime = (values, previousErrors, lang) => {
  if (values.game_type !== GAME_TYPES.PRIZE_DRAW) {
    return previousErrors;
  }

  const errors = {...previousErrors};

  if (isEmptyString(values.default_draw_time)) {
    errors.default_draw_time = lang.REQUIRED;
  } else if (!values.default_draw_time.match(DATE_PATTERN)) {
    errors.default_draw_time = lang.INVALID_DATE_FORMAT;
  }

  if (!errors.default_draw_time && !errors.default_to) {
    const draw = moment(values.default_draw_time, DATE_FORMAT);
    const to = moment(values.default_to, DATE_FORMAT);

    if (draw.isBefore(to) || draw.isSame(to)) {
      errors.default_draw_time = lang.AFTER_TO;
    }
  }

  return errors;
};

export default (values, props) => {
  const lang = props.lang.ERRORS;
  let errors = {};

  if (isEmptyString(values.name)) {
    errors.name = lang.GAME_NAME_REQUIRED;
  }

  errors = validatePeriod(values, errors, lang);
  errors = validateDrawTime(values, errors, lang);

  return errors;
};
