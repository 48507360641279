import React from 'react';

import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Popover from 'client/common/popovers/popover';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';

import './user-popover.scss';

const b = bem('user-popover');

const UserPopover = ({users}) => {
  const lang = useLanguage('REGIONS');

  if (users.length === 0) {
    return null;
  }

  return (
    <Popover
      arrowOffset={75}
      overlay={
        <div className={b()}>
          <h3 className={b('title')}>{lang.USERS_OF_THIS_REGION}</h3>
          <CustomScrollbars
            scrollbarProps={{
              autoHeightMax: 110,
            }}
          >
            <ul className={b('list')}>
              {users.map((user) => {
                const {client_user, phone} = user.accessable || {};
                const {full_name, email} = client_user || {};

                return (
                  <li className={b('list-item')} key={user.id}>
                    <p className={b('info-name')}>{full_name}</p>
                    <p className={b('info-email')}>{email}</p>
                    <p>{phone}</p>
                  </li>
                );
              })}
            </ul>
          </CustomScrollbars>
        </div>
      }
    >
      <div className={b('icon-wrapper')}>
        <Icon name="man" />
      </div>
    </Popover>
  );
};

UserPopover.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      accessable: PropTypes.shape({
        client_user: PropTypes.shape({
          full_name: PropTypes.string.isRequired,
          email: PropTypes.string.isRequired,
        }).isRequired,
        phone: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
};

export default withRouter(UserPopover);
