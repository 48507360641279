import React from 'react';

import remove from 'lodash/remove';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';

import './interface-templates-table.scss';

let LANGUAGE = {};

const b = bem('interface-templates-table');

/**
 * !!! WARNING !!!
 * BEWARE OF COPYPASTING THIS FILE
 * TWO ROWS ARE HARDCODED INTO THE TABLE BELOW
 *
 * (it's not a bug, it's a feature)
 */
class InterfaceTemplatesTable extends ReactQueryParams {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.toggleThemAll(false, new Array(props.perPage || 5)),
      header: false,
      data: [],
    };

    LANGUAGE = props.languageState.payload.INTERFACE_TEMPLATES;
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.onAllSelect(false);
      const newData = [...this.props.data];
      newData.unshift({...LANGUAGE.TABLE.UNBOUNCE, hardcoded: true});
      newData.unshift({...LANGUAGE.TABLE.SURVEYQUIZMO, hardcoded: true});
      this.setState({header: false, checked: Array(newData.length).fill(false), data: newData});
    }
  }

  toggleThemAll = (isChecked, list) => {
    return [false, false, ...list.fill(isChecked).slice(2)];
  };

  onPageChange = (perPage) => {
    this.setState({header: false, checked: Array(+perPage).fill(false)});
    this.onAllSelect(false);
    this.props.onPageChange({perPage: +perPage, page: 1});
  };

  onSortChange = (name, order) => {
    this.onAllSelect(false);
    this.setState({header: false, checked: Array(this.props.perPage).fill(false)});
    this.props.onSortChange(name, order);
  };

  renderToolbar = () => {
    const {selectedItems} = this.props;
    return (
      <>
        <button className="button button--bg-7" onClick={this.props.onAddTemplateButtonClick}>
          <Icon name="plus" className="button__icon" />
          <span>{LANGUAGE.TABLE.ADD_TEMPLATE_BUTTON}</span>
        </button>
        {selectedItems.length > 0 && (
          <button className="button button--bg-7" onClick={this.props.onDeleteTemplatesButtonClick}>
            <Icon name="trash" className="button__icon" width={17} height={19} />
            <span>
              {LANGUAGE.TABLE.DELETE_TEMPLATES_BUTTON} ({selectedItems.length})
            </span>
          </button>
        )}
      </>
    );
  };

  renderName = ({item: template}) => {
    return (
      <div
        className="link ellipsis-text"
        onClick={() => !template.hardcoded && this.props.onEditTemplateClick(template)}
        title={template.name}
      >
        {template.name}
      </div>
    );
  };

  renderItems = ({item: template}) => {
    return template.hardcoded ? null : (
      <div className="link" onClick={() => this.props.onEditTemplateItemsClick(template)}>
        {`${template.interface_template_items_count} ${LANGUAGE.TABLE.N_ITEMS}`}
      </div>
    );
  };

  onSelect = (isSelected, row) => {
    const {changeSelectedItems, selectedItems} = this.props;

    if (isSelected) {
      selectedItems.push(row.id);
    } else {
      remove(selectedItems, (i) => i === row.id);
    }
    changeSelectedItems(selectedItems);
  };

  onAllSelect = (isSelected) => {
    const {changeSelectedItems} = this.props;

    if (isSelected) {
      changeSelectedItems(this.props.data.map((i) => i.id));
    } else {
      changeSelectedItems([]);
    }
  };

  render() {
    const checkedRows = this.props.selectedItems.map((id) => ({id}));
    return (
      <div>
        <div className={b('toolbar')}>
          {this.renderToolbar()}
          <PerPageDropdown value={this.props.perPage?.toString()} onChange={this.onPageChange} simpleValue />
        </div>
        <ClientTable
          data={this.state.data}
          checkable
          checkedRows={checkedRows}
          sortField={this.queryParams.sort?.name}
          sortOrder={this.queryParams.sort?.order}
          onSortChange={({sortField, sortOrder}) => this.onSortChange(sortField, sortOrder)}
          onCheckAll={this.onAllSelect}
          onCheck={this.onSelect}
          renderCheckbox={(row) => (row.hardcoded ? '' : null)}
          columns={[
            {
              name: 'name',
              path: 'name',
              sortable: true,
              label: LANGUAGE.TABLE.NAME_COLUMN,
              render: this.renderName,
            },
            {
              name: 'code',
              path: 'code',
              label: LANGUAGE.TABLE.CODE_COLUMN,
            },
            {
              name: 'description',
              path: 'description',
              width: '50%',
              label: LANGUAGE.TABLE.DESCRIPTION_COLUMN,
            },
            {
              name: 'interface_template_items_count',
              path: 'interface_template_items_count',
              sortable: true,
              label: LANGUAGE.TABLE.ITEMS_COLUMN,
              render: this.renderItems,
            },
          ]}
        />
        <PaginationBar {...this.props} data={[{}, {}, ...this.props.data]} />
      </div>
    );
  }
}

InterfaceTemplatesTable.defaultProps = {
  data: [],
};

InterfaceTemplatesTable.propTypes = {
  data: PropTypes.array,
  selectedItems: PropTypes.array,
  children: PropTypes.any,
  totalPages: PropTypes.number,
  totalItems: PropTypes.number,
  perPage: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  onEditTemplateClick: PropTypes.func,
  onEditTemplateItemsClick: PropTypes.func,
  onAddTemplateButtonClick: PropTypes.func,
  onDeleteTemplatesButtonClick: PropTypes.func,
  onSortChange: PropTypes.func,
  changeSelectedItems: PropTypes.func,
  languageState: PropTypes.object,
};

export default connect(({languageState}) => ({languageState}))(InterfaceTemplatesTable);
