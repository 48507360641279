import {APP_ROLES, USER_TYPES} from 'client/common/config';

export function mapRole(user) {
  if (!user) {
    return APP_ROLES.ANON;
  }

  const role = user.role && user.role.toUpperCase();

  if (user.type === 'PrizeTrackingUser') {
    return APP_ROLES.TRACKING_USER;
  }

  return APP_ROLES[role] || APP_ROLES.USER;
}

export function isHavePermission(pagePermissions, userPermission) {
  return pagePermissions && pagePermissions.length ? pagePermissions.indexOf(userPermission) !== -1 : false;
}

export const haveAccessLevel = (role, pageAccessLevels, userAccessLevel) => {
  if (role === APP_ROLES.USER && pageAccessLevels && userAccessLevel) {
    return pageAccessLevels.includes(userAccessLevel);
  }

  return true;
};

export const isFirstClientLogin = (user) => {
  return user && user.type === USER_TYPES.CLIENT_USER && user.first_login;
};
