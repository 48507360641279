import types from './types';

export function setHeaderLinkBack(link) {
  return {
    type: types.SET_HEADER_LINK_BACK,
    payload: link,
  };
}

export function clearHeaderLinkBack() {
  return {
    type: types.CLEAR_HEADER_LINK_BACK,
    payload: {},
  };
}
