import {Reducer} from 'redux';

import {SmsTemplateKind} from 'client/models/sms-templates/types';

import types from './types';

type SmsTemplatesState = {
  smsTemplateKinds: SmsTemplateKind[];
};

const initialState: SmsTemplatesState = {
  smsTemplateKinds: [],
};

const smsTemplatesReducer: Reducer<SmsTemplatesState> = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SMS_TEMPLATE_KINDS_SUCCESS:
      return {
        ...state,
        smsTemplateKinds: action.payload.sms_template_kinds,
      };

    default:
      return state;
  }
};

export default smsTemplatesReducer;
