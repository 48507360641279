import React from 'react';

import moment from 'moment';
import {ApiError} from 'redux-api-middleware';
import {SubmissionError} from 'redux-form';

import bem from 'client/services/bem';
import {normalizeStringToUpperCase} from 'client/services/formatters';
import {useLanguage, useReduxFetch, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';
import {getCurrentTimezone, timezoneOptions} from 'client/services/utils/date';

import {getAvailableForAttributionUsers} from 'client/ducks/client-users/actions';
import {selectAvailableForAttributionUsers} from 'client/ducks/client-users/selectors';
import {createOperation} from 'client/ducks/operations/actions';

import AppButton from 'client/common/buttons';
import {DatepickerField, FieldWrap, SelectField, TextField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import DataRetentionField from 'client/components/common/custom-fields/data-retention-field';
// components
import DataRetentionTooltip from 'client/components/common/tooltips/data-retention-tooltip';

import {Operation} from 'client/models/operations/types';
import {User} from 'client/models/users/types';

import mapping from './mapping';
import validate from './validate';

import cssModule from './add-new-operation-modal.module.scss';

const FormName = 'AddNewOperationModalForm';

type FormValues = Pick<Operation, 'to' | 'from' | 'name' | 'code' | 'timezone'> & {
  common_retention_years: string;
  common_retention_months: string;
  leads_retention_years: string;
  leads_retention_months: string;
};

const b = bem('add-new-operation-modal', {cssModule});

type AddNewOperationModalProps = {
  clientId: number;
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isClientNetwork: boolean;
};

const INITIAL_VALUES = {
  common_retention_years: '0',
  common_retention_months: '6',
  leads_retention_years: '3',
  leads_retention_months: '0',
  timezone: getCurrentTimezone(),
};

const AddNewOperationModal: ReduxFormFC<AddNewOperationModalProps, FormValues> = (props) => {
  const {clientId, show, onClose, onConfirm, handleSubmit, isClientNetwork} = props;

  const lang = useLanguage('OPERATIONS');

  const {formValues, reset} = useReduxForm<FormValues>(FormName, {
    initialValues: INITIAL_VALUES,
    validate: (values) => validate(values, {lang, isClientNetwork}),
  });

  const {data: users = [], loading} = useReduxFetch({
    action: getAvailableForAttributionUsers,
    selector: selectAvailableForAttributionUsers,
    actionArgs: clientId,
    skip: !show,
  });

  const {fetch: create} = useReduxFetch({
    action: createOperation,
    fetchOnMount: false,
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const save = async (data: FormValues) => {
    return create(mapping(clientId, data))
      .then(() => {
        reset();
        onConfirm();
      })
      .catch((res: ApiError<{errors: Record<string, string>}>) => {
        const {errors} = res.response;
        if (errors) {
          const submitErrors: Partial<Record<keyof FormValues, string>> = {};

          if (errors.name) {
            submitErrors.name = lang.NAME_IS_TAKEN_ERROR?.toString();
          }
          if (errors.code) {
            submitErrors.code = lang.CODE_IS_TAKEN_ERROR?.toString();
          }
          if (errors.to) {
            submitErrors.to = lang.END_DATE_SMALLER_THEN_START_DATE_ERROR?.toString();
          }
          throw new SubmissionError(submitErrors);
        }
      });
  };

  const endDate = formValues?.to && moment(formValues.to, 'DD/MM/YYYY').subtract(1, 'day').format('DD/MM/YYYY');
  const startDate = formValues?.from && moment(formValues.from, 'DD/MM/YYYY').add(1, 'day').format('DD/MM/YYYY');

  const normalizeCode = (value: string, prevValue: string) => {
    let str = normalizeStringToUpperCase(value);
    str = str.length > 10 ? prevValue : str;
    str = str.replaceAll(/[^A-Z0-9]/g, '');
    return str;
  };

  return (
    <Modal
      show={show}
      onClose={handleClose}
      title={lang.NEW_OPERATION_MODAL_TITLE}
      className={b()}
      classNames={{body: 'operations-filters-modal'}}
    >
      <form onSubmit={handleSubmit(save)}>
        <TextField label={lang.NEW_OPERATION_MODAL__NAME} name="name" withWrap />
        <TextField label={lang.NEW_OPERATION_MODAL__CODE} withWrap name="code" normalize={normalizeCode} />
        <div className={b('row')}>
          <DatepickerField
            name="from"
            disabledDayAfter={endDate}
            className={b('row-field', ['date'])}
            label={lang.NEW_OPERATION_MODAL__START_DATE}
            withWrap
          />
          <DatepickerField
            name="to"
            disabledDayBefore={startDate}
            className={b('row-field', ['date'])}
            label={lang.NEW_OPERATION_MODAL__END_DATE}
            withWrap
          />
        </div>

        <FieldWrap>
          <div className={b('field-label')}>{lang.PARTICIPATION_DATA_RETENTION}</div>
          <div className={b('row')}>
            <DataRetentionField className={b('row-field', ['date'])} name="common_retention_years" unit="year" />
            <DataRetentionField className={b('row-field', ['date'])} name="common_retention_months" unit="month" />
            <DataRetentionTooltip className={b('tooltip')} />
          </div>
        </FieldWrap>
        <FieldWrap>
          <div className={b('field-label')}>{lang.LEADS_DATA_RETENTION}</div>
          <div className={b('row')}>
            <DataRetentionField name="leads_retention_years" className={b('row-field', ['date'])} unit="year" />
            <DataRetentionField name="leads_retention_months" className={b('row-field', ['date'])} unit="month" />
            <DataRetentionTooltip className={b('tooltip')} />
          </div>
        </FieldWrap>
        <SelectField
          label={lang.ATTRIBUTED_TO_LABEL}
          name="client_user_id"
          isLoading={loading}
          simpleValue
          options={users.map((i: User) => ({label: i.full_name, value: i.id}))}
          withWrap
        />
        <SelectField label={lang.TIMEZONE} name="timezone" searchable options={timezoneOptions} withWrap simpleValue />
        <div className={b('buttons')}>
          <AppButton
            size="s"
            transparent
            color="games"
            onClick={handleClose}
            label={lang.NEW_OPERATION_MODAL__CANCEL_BUTTON}
          />
          <AppButton size="s" type="submit" color="games" label={lang.NEW_OPERATION_MODAL__CREATE_BUTTON} />
        </div>
      </form>
    </Modal>
  );
};

const AddNewOperationModalForm = reduxForm<AddNewOperationModalProps, FormValues>({
  form: FormName,
})(AddNewOperationModal);

export default AddNewOperationModalForm;
