// import {callApi} from 'client/services/call-api';
import {getEmail, getToken} from 'client/services/cookie-data-source';
import {objectToFormData} from 'client/services/formData';

import {API_PATH} from 'client/common/config';

export function addImage(id, kind, file) {
  const formData = new FormData();
  formData.append(`${kind}[image]`, file);
  return () =>
    fetch(`${API_PATH}/${kind}s/${id}`, {
      method: 'PATCH',
      headers: {
        'X-User-Email': getEmail(),
        'X-User-Token': getToken(),
      },
      body: formData,
    });
}

export function removeImage(id, kind) {
  const data = {
    [kind]: {
      remove_image: true,
    },
  };

  return () =>
    fetch(`${API_PATH}/${kind}s/${id}`, {
      method: 'PATCH',
      headers: {
        'X-User-Email': getEmail(),
        'X-User-Token': getToken(),
      },
      body: objectToFormData(data),
    });
}
