import {calculateTotal, checkForEmptyRecords} from './mappers';

const mapSources = (sources) => {
  return [
    {
      name: 'Direct Access',
      participations_count: calculateTotal(sources, 'participations_count'),
      visits_count: calculateTotal(sources, 'visits_count'),
      records: sources,
    },
  ];
};

const flatDevices = (devices) => devices.map((item) => ({...item, records: null}));

export const selectParticipationsStatistics = (state) => {
  return Object.keys(state.participationsStatistics.common).length
    ? state.participationsStatistics.common.participations_statistic
    : {};
};

export const selectSourcesStatistics = ({participationsStatistics}) => {
  const {sources} = participationsStatistics.sources;

  return {
    devices: flatDevices(sources.devices),
    direct_access: mapSources(sources.direct_access.interfaces),
    campaigns: sources.campaigns.map((item) => ({
      ...item,
      participations_count: calculateTotal(item.records, 'participations_count'),
      visits_count: calculateTotal(item.records, 'visits_count'),
    })),
  };
};

export const selectCampaignMainStatistics = ({participationsStatistics}) => {
  const {
    sources: {
      total_direct_and_campaigns_participations,
      total_direct_and_campaigns_visits,
      sources,
      visitsBySourceType,
    },
  } = participationsStatistics;

  return {
    total_direct_and_campaigns_participations: total_direct_and_campaigns_participations,
    total_direct_and_campaigns_visits: total_direct_and_campaigns_visits,
    visitsBySourceType: {
      campaigns: visitsBySourceType.campaigns,
      sources: visitsBySourceType.sources,
      direct_access: visitsBySourceType.direct_access,
    },
    lists: {
      direct_access: [
        {
          to: sources.direct_access.to,
          from: sources.direct_access.from,
          participations_count: calculateTotal(sources.direct_access.interfaces, 'participations_count'),
          visits_count: calculateTotal(sources.direct_access.interfaces, 'visits_count'),
          records: sources.direct_access.interfaces,
        },
      ],
      campaigns: sources.campaigns.map((item) => ({
        ...checkForEmptyRecords(item),
        participations_count: calculateTotal(item.records, 'participations_count'),
        visits_count: calculateTotal(item.records, 'visits_count'),
      })),
    },
  };
};

export const checkCampaignSourcesSelector = ({participationsStatistics}) => {
  const {
    sources: {
      visitsBySourceType: {campaigns, direct_access},
    },
  } = participationsStatistics;

  return !direct_access && !campaigns;
};
