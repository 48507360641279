import React, {useMemo, useState} from 'react';

import placeholderIcon from 'assets/icons/colored/placeholder-no-photo-mini.svg';
import cn from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useDeepCompareEffect} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm from 'client/services/hooks/use-redux-form';
import {hasObjectTruthyValues} from 'client/services/validator';

import {interpolate} from 'client/ducks/language/helpers';
import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

import {ImageField, TextField, TextareaField} from 'client/common/fields';
import Offscreen from 'client/common/offscreen';
import LayoutPanel from 'client/common/panels/layout-panel';
import TabSwitcher from 'client/common/tab-switcher';

import {COMMON_INPUT} from 'client/components/prizes/constants';
import PrizeDiyEmailFieldset from 'client/components/prizes/fieldsets/prize-diy-email-fieldset';
import PrizeDiyTicketFieldset from 'client/components/prizes/fieldsets/prize-diy-ticket-fieldset';
import PrizeDiyWinFieldset from 'client/components/prizes/fieldsets/prize-diy-win-fieldset';
import {PrizeDiyModalFormName} from 'client/components/prizes/modals/prize-diy-modal';

import './prize-diy-modal-representation.scss';

const tabs = {
  WIN_POPUP: 'win_popup',
  TICKET: 'ticket',
  EMAIL: 'email',
};

const b = bem('prize-diy-modal-representation');

const PICTO_SIZE = 256;

const PrizeDiyModalRepresentation = (props) => {
  const {className, disabled, onRemoveImage} = props;
  const {formValues, change, registerField, unregisterField, errors, submitFailed} =
    useReduxForm(PrizeDiyModalFormName);
  const {create_ticket, email_send, language, defaultLanguage} = formValues.main || {};
  const languages = useSelector(selectPrizeLanguages);
  const currentLanguage = formValues?.main?.language || null;

  const lang = useLanguage('PRIZES.MODALS.PRIZE_DIY_MODAL.PRIZE_DIY_MODAL_REPRESENTATION');
  const [activeTab, setActiveTab] = useState(tabs.WIN_POPUP);
  const [deletingImages, setDeletingImages] = useState([]);

  const hasWinPopupErrors = submitFailed && hasObjectTruthyValues(errors.win_pop_up || {});
  const hasTicketErrors = submitFailed && hasObjectTruthyValues(errors.ticket || {});
  const hasEmailErrors = submitFailed && hasObjectTruthyValues(errors.email || {});

  const tabsConfig = useMemo(
    () => [
      {title: lang.WIN_POPUP, value: tabs.WIN_POPUP, className: b('tab'), hasError: hasWinPopupErrors},
      {title: lang.TICKET, value: tabs.TICKET, className: b('tab'), exclude: !create_ticket, hasError: hasTicketErrors},
      {title: lang.EMAIL, value: tabs.EMAIL, className: b('tab'), exclude: !email_send, hasError: hasEmailErrors},
    ],
    [lang, create_ticket, email_send, hasWinPopupErrors, hasTicketErrors, hasEmailErrors],
  );

  const copyDefaultLanguageValue = (event, field) => {
    const value = event?.target?.value ?? event;

    if (language === COMMON_INPUT) {
      languages.forEach((key) => {
        change(`main.${field}.${key}`, value);
      });
    } else if (currentLanguage === defaultLanguage) {
      const group = formValues.main[field];
      const defaultValue = value || group[defaultLanguage];

      if (defaultValue) {
        for (const [key, val] of Object.entries(group)) {
          if (key !== defaultLanguage && !val) {
            change(`main.${field}.${key}`, defaultValue);
          }
        }
      } else {
        change(`main.${field}.${defaultLanguage}`, defaultValue);
      }
    }
  };

  useDeepCompareEffect(() => {
    languages.forEach((key) => {
      registerField(`win_pop_up.win_pop_up_images.${key}`);

      if (email_send) {
        registerField(`email.external_descriptions.${key}`);
        registerField(`email.email_images.${key}`);
      } else {
        unregisterField(`email.external_descriptions.${key}`);
        unregisterField(`email.email_images.${key}`);
      }
      if (create_ticket) {
        registerField(`ticket.visual_types.${key}`);
        registerField(`ticket.ticket_images.${key}`);
      } else {
        unregisterField(`ticket.visual_types.${key}`);
        unregisterField(`ticket.ticket_images.${key}`);
      }
    });
  }, [languages, registerField]);

  const prizeNameFieldName = `main.prize_names.${language === COMMON_INPUT ? defaultLanguage : language}`;
  const prizePictoFieldName = `main.prize_pictos.${language === COMMON_INPUT ? defaultLanguage : language}`;
  const legalFieldName = `main.legal.${language === COMMON_INPUT ? defaultLanguage : language}`;

  const handleChangeImage = (file, field) => {
    copyDefaultLanguageValue(file, field);
  };

  const handleRemoveImage = (field) => {
    const newImageIds = [...deletingImages];

    const addUniqueId = (id) => {
      if (id && !newImageIds.includes(id)) {
        newImageIds.push(id);
      }
    };

    if (language === COMMON_INPUT) {
      for (const langKey of languages) {
        const currentId =
          get(formValues, `${field}_${langKey}_id`) || get(formValues, `${field}.${langKey}_id`) || null;

        addUniqueId(currentId);
      }
    } else {
      const currentId =
        get(formValues, `${field}_${language}_id`) || get(formValues, `${field}.${language}_id`) || null;
      addUniqueId(currentId);
    }

    setDeletingImages(newImageIds);

    onRemoveImage?.(newImageIds);
  };
  return (
    <LayoutPanel className={cn(b(), className)}>
      <div className={b('field')}>
        <TextField
          className={b('name-field')}
          key={prizeNameFieldName}
          name={prizeNameFieldName}
          label={lang.PRIZE_NAME}
          inputHeight="small"
          onBlur={(e) => copyDefaultLanguageValue(e, 'prize_names')}
          disabled={disabled}
          withWrap={true}
          required={true}
        />
        <ImageField
          disabled={disabled}
          className={b('picto-field')}
          name={prizePictoFieldName}
          key={prizePictoFieldName}
          onChange={(file) => handleChangeImage(file, 'prize_pictos')}
          acceptFormats=".png,.jpg,.jpeg"
          placeholderIcon={placeholderIcon}
          placeholders={{
            noValue: lang.PLACEHOLDER_PICTO_INSERT,
            withValue: lang.PLACEHOLDER_PICTO_REPLACE,
          }}
          checks={{
            checks: {
              check_error_size: PICTO_SIZE,
            },
            messages: {
              check_error_size: interpolate(lang.PICTO_SIZE_ERROR?.toString(), {
                width: PICTO_SIZE,
                height: PICTO_SIZE,
              }),
            },
          }}
          onRemove={() => handleRemoveImage('main.prize_pictos')}
          removable={true}
        />
      </div>

      <TabSwitcher tabs={tabsConfig} activeTab={activeTab} onTabClick={setActiveTab} className={b('switcher')} />
      <Offscreen hidden={activeTab !== tabs.WIN_POPUP}>
        <PrizeDiyWinFieldset disabled={disabled} onRemove={handleRemoveImage} />
      </Offscreen>
      <Offscreen hidden={activeTab !== tabs.TICKET}>
        <PrizeDiyTicketFieldset disabled={disabled} onRemove={handleRemoveImage} />
      </Offscreen>
      <Offscreen hidden={activeTab !== tabs.EMAIL}>
        <PrizeDiyEmailFieldset disabled={disabled} onRemove={handleRemoveImage} />
      </Offscreen>
      <TextareaField
        key={legalFieldName}
        name={legalFieldName}
        className={b('legal-field')}
        label={lang.LEGAL}
        onBlur={(e) => copyDefaultLanguageValue(e, 'legal')}
        height={75}
        disabled={disabled}
      />
    </LayoutPanel>
  );
};

PrizeDiyModalRepresentation.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onRemoveImage: PropTypes.func,
};

PrizeDiyModalRepresentation.defaultProps = {
  className: '',
};

export default PrizeDiyModalRepresentation;
