import React, {ChangeEventHandler, KeyboardEventHandler, useEffect, useState} from 'react';

import classNames from 'classnames';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';

import {Translation} from 'client/models/language/types';

import cssModule from './search-input.module.scss';

type SearchInputProps = {
  searchDefault?: string;
  placeholder?: Translation;
  onSearch?: (value: string) => void;
  onChange?: (value: string) => void;
  className?: string;
  disabled?: boolean;
};

const b = bem('search-input', {cssModule});

const SearchInput: React.FC<SearchInputProps> = (props) => {
  const {searchDefault = '', onChange, onSearch, placeholder, className, disabled} = props;

  const [value, setValue] = useState(searchDefault);

  useEffect(() => {
    setValue(searchDefault);
  }, [searchDefault]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      onSearch?.(value.trim());
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.target.value;

    setValue(inputValue);
    onChange?.(inputValue.trim());
  };

  const handleClear = () => {
    setValue('');
    onChange?.('');
    onSearch?.('');
  };

  return (
    <div className={classNames(b(), className)}>
      <Icon
        name="search-1"
        width={24}
        height={24}
        className={b('icon', ['search'])}
        onClick={() => onSearch?.(value.trim())}
      />

      <input
        className={b('input')}
        type="text"
        onKeyDown={handleKeyDown}
        placeholder={placeholder?.toString()}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />

      {value && <Icon name="close" width={16} height={16} className={b('icon', ['clear'])} onClick={handleClear} />}
    </div>
  );
};

export default SearchInput;
