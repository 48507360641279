import React, {Component} from 'react';

import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';

import {TranslationJsx} from 'client/models/language/types';

class SearchField extends Component {
  static propTypes = {
    onSearch: PropTypes.func,
    onClear: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: TranslationJsx,
    defaultSearch: PropTypes.string,
    cssModifier: PropTypes.string,
    reset: PropTypes.bool,
    onReset: PropTypes.func,
  };

  static defaultProps = {
    onSearch: () => {},
    onClear: () => {},
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
    placeholder: '',
    defaultSearch: '',
    cssModifier: '',
    reset: false,
    onReset: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultSearch || '',
      showClear: (props.defaultSearch && props.defaultSearch.length > 0) || false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.reset && this.props.reset) {
      this.setState({value: '', showClear: false});
      this.props.onReset();
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleSearch(e);
    }
  };

  handleChange = (e) => {
    const value = e.target.value;

    this.setState(
      {
        value,
        showClear: false,
      },
      () => {
        this.props.onChange(value.trim());
      },
    );
  };

  handleSearch = (e) => {
    e.preventDefault();

    if (!this.state.value.trim()) {
      this.handleClear();
      return;
    }

    this.setState({
      showClear: !!this.state.value.trim(),
    });

    this.props.onSearch(this.state.value.trim());
  };

  handleClear = () => {
    this.setState({
      value: '',
      showClear: false,
    });

    this.props.onClear();
  };

  handleFocus = () => {
    this.props.onFocus(this.state.value);
  };

  handleBlur = () => {
    this.props.onBlur(this.state.value);
  };

  render() {
    const {placeholder, cssModifier} = this.props;

    return (
      <div className={`input input--search ${cssModifier}`}>
        <input
          className="input__field"
          type="text"
          value={this.state.value}
          placeholder={placeholder?.toString()}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
        />
        {this.state.showClear ? (
          <button className="input__search-btn" onClick={this.handleClear}>
            <Icon name="clear" className="input__clear-icon" />
          </button>
        ) : (
          <button className="input__search-btn" onClick={this.handleSearch}>
            <Icon name="search" className="input__search-icon" />
          </button>
        )}
      </div>
    );
  }
}

export default SearchField;
