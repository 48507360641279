import moment from 'moment';

import {SCHEDULE_TABS} from 'client/components/prizes/fieldsets/prize-diy-schedule-fieldset/constants';
import {Prize} from 'client/models/prizes/types';

const convertPrizeToConfiguration = (prize: Prize) => {
  const {
    prize_timestamps,
    prize_map_timestamps,
    schedule_type,
    id,
    intraday_win = true,
    next_day_win = true,
  } = prize?.schedule_instant_win_configuration || {};

  const timestamps = (prize_timestamps || prize_map_timestamps)?.map((timeStamp) => ({
    value: moment(timeStamp.manual_time).format('DD/MM/YYYY HH:mm:ss'),
    id: timeStamp.id,
  }));

  return schedule_type === SCHEDULE_TABS.CUSTOM_LIST
    ? {
        configurationId: id,
        timestamps,
        initialTimestamps: timestamps,
        intraday_win,
        next_day_win,
      }
    : {timestamps: [], intraday_win, next_day_win};
};

export default convertPrizeToConfiguration;
