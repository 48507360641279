import React from 'react';

import remove from 'lodash/remove';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';
import {doesValueExist, getClientPage} from 'client/services/helpers';

import AppButton from 'client/common/buttons';
import Icon from 'client/common/icon';
import SearchInput from 'client/common/inputs/search-input';
import PaginationBar from 'client/common/paginations/pagination-bar';
import Popover from 'client/common/popovers/popover';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import CustomScrollbars from 'client/components/common/custom-scrollbars';

import cssModule from './places-table.module.scss';

const b = bem('places-table', {cssModule});

let LANGUAGE = {};

class PlacesTable extends ReactQueryParams {
  constructor(props) {
    super(props);

    this.state = {
      checkedRows: [],
    };

    LANGUAGE = props.languageState.payload.PLACES;
  }

  handleAddPlaceClick = () => {
    this.props.addPlaceClick();
  };

  handleNameClick = (place) => {
    this.props.onEditPlaceClick(place);
  };

  renderToolbar = () => {
    const {linkToClientClick, mapViewClick, search, perPage, onPageChange} = this.props;
    const {checkedRows} = this.state;

    return (
      <div className={b('filter-bar')}>
        <div className={b('search-field')}>
          <SearchInput
            placeholder={LANGUAGE.SEARCH_PLACEHOLDER}
            onSearch={this.handleSearchChange}
            onClear={() => this.handleSearchChange('')}
            searchDefault={search}
          />
        </div>
        <div className={b('buttons-container')}>
          <AppButton
            color={checkedRows.length ? 'text-additional' : 'participants'}
            iconName={checkedRows.length ? 'chain' : 'new-map'}
            label={checkedRows.length ? LANGUAGE.LINK_TO_CLIENT_BUTTON : LANGUAGE.MAP_VIEW_BUTTON}
            onClick={checkedRows.length ? linkToClientClick : mapViewClick}
          />
          <AppButton
            color="participants"
            iconName="plus"
            label={LANGUAGE.ADD_PLACE_BUTTON}
            onClick={this.handleAddPlaceClick}
          />
        </div>
        <div className={b('dropdown-container')}>
          <PerPageDropdown
            onChange={(pages) => onPageChange({page: 1, perPage: pages})}
            value={String(perPage)}
            simpleValue={true}
          />
        </div>
      </div>
    );
  };

  renderActions = (row) => {
    const {showSinglePlaceOnMap} = this.props;
    const showOperations = doesValueExist(row.offline_interations) && row.offline_interations.length > 0;
    const showComment = doesValueExist(row.comment) && row.comment.length > 0;

    return (
      <div className={b('icons-container')}>
        <Popover
          position="bottom"
          shiftLeft={5}
          arrowOffset={80}
          overlayInnerStyle={{
            width: 230,
          }}
          overlay={
            <div className={b('popover-overlay')}>
              <h3 className={b('popover-title')}>{LANGUAGE.OPERATIONS_PERFORMED_TITLE}</h3>
              <CustomScrollbars
                cssModifier={b('scroll-container')}
                scrollbarProps={{
                  autoHeightMax: 150,
                }}
              >
                <div className={b('scroll-content')}>
                  <ul className={b('list')}>
                    {row.offline_interations &&
                      row.offline_interations.map((op, i) => (
                        <li className={b('item')} key={i}>
                          <div className={b('code')}>{op.code}</div>
                          <div className={b('name')}>{op.name}</div>
                        </li>
                      ))}
                  </ul>
                </div>
              </CustomScrollbars>
            </div>
          }
        >
          <Icon name="operation" className={showOperations ? '' : 'hidden'} />
        </Popover>
        {row.latitude && row.longitude && (
          <AppButton
            transparent={true}
            rounded={true}
            iconName="marker"
            color="primary"
            onClick={() => showSinglePlaceOnMap(row.id)}
            iconConfig={{
              width: 20,
              height: 20,
            }}
          />
        )}
        <Popover
          position="bottom"
          shiftLeft={5}
          arrowOffset={80}
          overlayInnerStyle={{
            width: 230,
          }}
          overlay={
            <div className={b('popover-overlay')}>
              <h3 className={b('popover-title')}>{LANGUAGE.COMMENTS_TITLE}</h3>
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMax: 150,
                }}
              >
                <div className={b('comment')}>{row.comment}</div>
              </CustomScrollbars>
            </div>
          }
        >
          <Icon name="message" className={showComment ? '' : 'hidden'} />
        </Popover>
      </div>
    );
  };

  renderName = (place) => {
    place.group = place.network ? place.network.group : {};
    place.group.value = place.group.id;
    place.group.label = place.group.name;

    place.zip = place.city;
    place.agglomeration = place.city && place.city.agglomeration ? place.city.agglomeration.name : {};
    place.zone = place.city && place.city.agglomeration ? place.city.agglomeration.zone.name : {};

    return (
      <div className={b('link')} onClick={() => this.handleNameClick(place)}>
        {place.name}
      </div>
    );
  };

  renderAddress = (place) => {
    const address = place.street_address.split('\\r');
    return (
      <p className={b('text')}>
        {address.map((span, idx) => (
          <span key={'span' + idx}>{span}</span>
        ))}
      </p>
    );
  };

  renderClient = (place) =>
    place.client && <Link to={`${getClientPage(place.client)}/${place.client.id}`}>{place.client.name}</Link>;

  renderNetworkName = (place) => decodeURIComponent(place.network_name);

  handleCheck = (isChecked, item) => {
    const {changeSelectedPlaces, selectedPlaces} = this.props;
    let checkedItems;

    if (isChecked) {
      checkedItems = [...this.state.checkedRows, item];
      selectedPlaces.push(item.id);
    } else {
      checkedItems = this.state.checkedRows.filter((row) => row.id !== item.id);
      remove(selectedPlaces, (i) => i === item.id);
    }

    this.setState({checkedRows: checkedItems});
    changeSelectedPlaces(selectedPlaces);
  };

  handleCheckAll = (isChecked) => {
    const {changeSelectedPlaces, data} = this.props;

    this.setState({checkedRows: isChecked ? this.props.data : []});
    changeSelectedPlaces(isChecked ? data.map((i) => i.id) : []);
  };

  handleSortChange = ({sortField, sortOrder}) => {
    this.setState({checkedRows: []});
    this.props.onSortChange(sortField, sortOrder);
  };

  handleAllPlacesSelect(isSelected) {
    const {changeSelectedPlaces, data} = this.props;

    if (isSelected) {
      changeSelectedPlaces(data.map((i) => i.id));
    } else {
      changeSelectedPlaces([]);
    }
  }

  handleSearchChange = (search) => {
    this.handleAllPlacesSelect(false);
    this.setState({checkedRows: []});
    this.props.onSearch(search);
  };

  handleSearchClear = (search) => {
    this.handleAllPlacesSelect(false);
    this.setState({checkedRows: []});
    this.props.onSearchClear(search);
  };

  render() {
    const {data, perPage, totalPages, totalItems, currentPage, onPageChange} = this.props;
    const {checkedRows} = this.state;
    const {sort} = this.queryParams;

    return (
      <div className={b('')}>
        {this.renderToolbar()}
        <ClientTable
          checkable={true}
          onCheck={this.handleCheck}
          onCheckAll={this.handleCheckAll}
          checkedRows={checkedRows}
          data={data}
          onSortChange={this.handleSortChange}
          sortField={sort?.name}
          sortOrder={sort?.order}
          columns={[
            {
              label: LANGUAGE.PLACE_NAME_COLUMN,
              name: 'name',
              render: ({item}) => this.renderName(item),
              sortable: true,
            },
            {
              label: LANGUAGE.GROUP_COLUMN,
              name: 'group_name',
              sortable: true,
            },
            {
              label: LANGUAGE.NETWORK_COLUMN,
              name: 'network_name',
              render: ({item}) => this.renderNetworkName(item),
              sortable: true,
            },
            {
              label: LANGUAGE.ZIP_COLUMN,
              name: 'city_zip',
              sortable: true,
            },
            {
              label: LANGUAGE.CITY_COLUMN,
              name: 'city_name',
              sortable: true,
            },
            {
              label: LANGUAGE.AGGLOMERATION_COLUMN,
              name: 'agglomeration_name',
              sortable: true,
            },
            {
              label: LANGUAGE.ZONE_COLUMN,
              name: 'zone_name',
              sortable: true,
            },
            {
              label: LANGUAGE.ADDRESS_COLUMN,
              name: 'address',
              render: ({item}) => this.renderAddress(item),
              sortable: true,
            },
            {
              label: LANGUAGE.STORE_COLUMN,
              name: 'client_name',
              render: ({item}) => this.renderClient(item),
              sortable: true,
            },
            {
              label: LANGUAGE.ACTIONS_COLUMN,
              name: 'actions',
              render: ({item}) => this.renderActions(item),
            },
          ]}
        />
        <PaginationBar
          data={data}
          perPage={perPage}
          totalPages={totalPages}
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}

PlacesTable.defaultProps = {
  data: [],
  topRightSection: null,
};

PlacesTable.propTypes = {
  onSearchClear: PropTypes.func,
  data: PropTypes.array,
  search: PropTypes.string,
  selectedPlaces: PropTypes.array,
  topRightSection: PropTypes.any,
  linkToClientClick: PropTypes.func,
  totalPages: PropTypes.number,
  totalItems: PropTypes.number,
  perPage: PropTypes.number,
  currentPage: PropTypes.number,
  mapViewClick: PropTypes.func,
  onSearch: PropTypes.func,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  changeSelectedPlaces: PropTypes.func,
  addPlaceClick: PropTypes.func,
  onEditPlaceClick: PropTypes.func,
  languageState: PropTypes.object,
  showSinglePlaceOnMap: PropTypes.func.isRequired,
};

export default PlacesTable;
