import {ValidityValues} from 'client/components/prizes/fieldsets/prize-diy-validity-fieldset/types';
import validateValidity from 'client/components/prizes/fieldsets/prize-diy-validity-fieldset/validate';
import {Translation} from 'client/models/language/types';
import {PRIZE_TYPES} from 'client/models/prizes/constants';

import {MainParamsValues} from './types';

export default (values: MainParamsValues, lang: Record<string, Translation>) => {
  const errors: MainParamsValues = {type: '', loyalty_amount: ''};

  if (values.type === PRIZE_TYPES.LOYALTY && !values.loyalty_amount) {
    errors.loyalty_amount = lang.REQUIRED;
  }

  return {
    ...errors,
    ...validateValidity(values as ValidityValues, lang),
  };
};
