import React, {Component} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {selectIsAdmin} from 'client/ducks/user/selectors';

import VisSettingsPopoverBase from './components/vis-settings-popover-base';

class VisHashtagSettingsPopover extends Component {
  static propTypes = {
    isAdmin: PropTypes.bool,
    languageState: PropTypes.object.isRequired,
    tracker: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    children: PropTypes.any,
  };

  static defaultProps = {
    children: null,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.VISUALS.HASHTAG_SETTINGS_POPOVER;
  }

  render() {
    const {
      children,
      onDelete,
      onEdit,
      isAdmin,
      tracker: {city = {}, ...tracker},
    } = this.props;

    const format = (date) => moment(date).format('DD/MM/YYYY HH:mm');

    return (
      <VisSettingsPopoverBase
        readOnly={!isAdmin}
        onDelete={onDelete}
        onEdit={onEdit}
        messageText={this.LANGUAGE.DELETE_TRACKER_LABEL}
        title={this.LANGUAGE.TITLE}
        overlayContent={
          <div className="main-text main-text--bold">
            <div className="vis-settings-popover__block">
              {format(tracker.from)} - {format(tracker.to)}
            </div>
            {city && tracker.building && tracker.street && tracker.radius && (
              <div className="vis-settings-popover__block">
                <div>
                  {city.name}, {tracker.building}, {tracker.street}
                </div>
                <div>{city.zip}</div>
                <div>
                  {tracker.radius} {this.LANGUAGE.METERS_LABEL}
                </div>
              </div>
            )}
          </div>
        }
      >
        {children}
      </VisSettingsPopoverBase>
    );
  }
}

const mapStateToProps = ({languageState, ...state}) => ({
  languageState,
  isAdmin: selectIsAdmin(state),
});

export default connect(mapStateToProps)(VisHashtagSettingsPopover);
