import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {reject} from 'lodash';
import find from 'lodash/find';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams} from 'client/services/hooks';

import {ACCESS_LEVEL_TYPES} from 'client/ducks/client-users/constants';
import {selectClientAccessLevel} from 'client/ducks/user-clients/selectors';

import SelectDropdown from 'client/common/selects/select-dropdown';

import ClientTable from 'client/components/common/client-table';

import {KPI_TYPES} from 'client/components/animations/constants';
import InstoreAnimationContainer from 'client/components/animations/containers/animation-config-container';
import {renderArrayAnswers} from 'client/components/animations/lists/animation-monitoring-kpi-list';
import {AnimationActionsCell, AnimationBrandPlaceCell, AnimationUserCell} from 'client/components/instore/cells';
import InstoreReportingDownloadButton from 'client/components/instore/components/instore-reporting-download-button';
import {ANIMATION_STATUSES} from 'client/components/instore/constants';
import {getAnimationDate} from 'client/components/instore/helpers';
import {useFetchAnimations} from 'client/components/instore/hooks/use-fetch-animations';
import InstorePlanningAnimationListHeader from 'client/components/instore/lists/instore-planning-animation-list/instore-planning-animation-list-header';
import {useFetchTaskKpis} from 'client/components/instore/lists/instore-reporting-animation-list/hooks';

import cssModule from './instore-reporting-animation-list.module.scss';

const b = bem('instore-reporting-animation-list', {cssModule});

const InstoreReportingAnimationList = (props) => {
  const {task} = props;
  const {loadingKpis, kpis} = useFetchTaskKpis({task});

  const lang = useLanguage('INSTORE_TASK.TABLES.INSTORE_ANIMATION_TABLE');
  const [animation, setAnimation] = useState(null);
  const accessLevel = useSelector(selectClientAccessLevel);

  const [queryParams, setQueryParams] = useQueryParams(
    null,
    {},
    {
      stringify: {
        skipEmptyString: true,
      },
    },
  );

  const {loading, animationsData} = useFetchAnimations({
    filters: {
      ...queryParams?.filters,
      taskId: task.id,
      interaction_group_id: +queryParams.campaignId || null,
      statuses: queryParams?.filters?.status
        ? [queryParams?.filters?.status]
        : [ANIMATION_STATUSES.RUNNING, ANIMATION_STATUSES.FINISHED, ANIMATION_STATUSES.REPORT],
    },
    sortField: queryParams?.sortField,
    sortOrder: queryParams?.sortOrder,
    search: queryParams?.search,
  });

  useEffect(() => {
    if (kpis) {
      setQueryParams({
        kpis: [queryParams.kpis?.[0] || kpis[0]?.id, queryParams.kpis?.[1] || kpis[1]?.id],
      });
    }

    // We need set queryParams for kpi just at the first time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpis]);

  const leftOptions = useMemo(() => {
    return reject(
      kpis?.map((kpi) => ({
        label: kpi.name,
        value: kpi.id,
      })),
      {value: queryParams.kpis?.[1]},
    );
  }, [kpis, queryParams]);

  const rightOptions = useMemo(() => {
    return reject(
      kpis?.map((kpi) => ({
        label: kpi.name,
        value: kpi.id,
      })),
      {value: queryParams.kpis?.[0]},
    );
  }, [kpis, queryParams]);

  const onSubmitFilters = (filterFields) => {
    setQueryParams({filters: filterFields});
  };

  const getFilterStatusOptions = (defaultStatus) => {
    return defaultStatus.filter((option) =>
      [ANIMATION_STATUSES.RUNNING, ANIMATION_STATUSES.FINISHED, ANIMATION_STATUSES.REPORT].includes(option.value),
    );
  };

  const renderKpiColumn = useCallback(
    ({item, column}) => {
      const statistic = find(item.questions_statistic, {id: get(queryParams, column.name)});
      if (Array.isArray(statistic?.data)) {
        return renderArrayAnswers(statistic.data);
      }
      return (
        (statistic?.type === KPI_TYPES.STAT ? parseFloat((+statistic?.data || 0).toFixed(3)) : statistic?.data) || '–'
      );
    },
    [queryParams],
  );
  const hintRender = useCallback(
    (item, {column}) => {
      const statistic = find(item.questions_statistic, {id: get(queryParams, column.name)});
      return Array.isArray(statistic?.data) && statistic?.data.length > 2
        ? renderArrayAnswers(statistic.data, statistic.type)
        : null;
    },
    [queryParams],
  );

  return (
    <div className={b()}>
      <div className={b('toolbar')}>
        <InstorePlanningAnimationListHeader
          onSearch={setQueryParams}
          searchValue={queryParams.search}
          recordsFound={animationsData.length}
          onSubmitFilters={onSubmitFilters}
          defaultFilters={queryParams?.filters}
          getStatusOptions={getFilterStatusOptions}
        />
        {![ACCESS_LEVEL_TYPES.LOCAL, ACCESS_LEVEL_TYPES.REGIONS].includes(accessLevel) && (
          <InstoreReportingDownloadButton />
        )}
      </div>
      <ClientTable
        data={animationsData || []}
        onSortChange={setQueryParams}
        sortField={queryParams.sortField}
        sortOrder={queryParams.sortOrder}
        loading={loading || loadingKpis}
        columns={[
          {
            name: 'place',
            label: lang.PLACE,
            sortable: true,
            render: AnimationBrandPlaceCell,
          },
          {
            name: 'city',
            path: 'place.city_name',
            label: lang.CITY,
            sortable: true,
          },
          {
            name: 'user',
            path: 'instore_task_access.membership',
            label: lang.USER,
            sortable: true,
            render: AnimationUserCell,
          },
          {
            name: 'from',
            path: 'animation_days',
            label: lang.FROM,
            sortable: true,
            formatter: (value) => getAnimationDate(value, 'from'),
          },
          {
            name: 'to',
            path: 'animation_days',
            label: lang.TO,
            sortable: true,
            formatter: (value) => getAnimationDate(value, 'to'),
          },
          {
            name: 'kpis[0]',
            sortable: true,
            width: 250,
            headerClassName: b('header-select-container'),
            renderHeader: () => (
              <SelectDropdown
                simpleValue
                menuPosition="fixed"
                options={leftOptions}
                className={b('header-select')}
                value={queryParams.kpis?.[0]}
                searchable={true}
                classNames={{
                  singleValue: () => b('header-select-value'),
                }}
                onChange={(value) => setQueryParams({kpis: [value, queryParams.kpis?.[1]]}, false, true)}
              />
            ),
            render: renderKpiColumn,
            hintRender: hintRender,
          },
          {
            name: 'kpis[1]',
            sortable: true,
            width: 250,
            headerClassName: b('header-select-container'),
            renderHeader: () => (
              <SelectDropdown
                simpleValue
                menuPosition="fixed"
                options={rightOptions}
                className={b('header-select')}
                value={queryParams.kpis?.[1]}
                searchable={true}
                classNames={{
                  singleValue: () => b('header-select-value'),
                }}
                onChange={(value) => setQueryParams({kpis: [queryParams.kpis?.[0], value]}, false, true)}
              />
            ),
            render: renderKpiColumn,
            hintRender: hintRender,
          },
          {
            name: 'actions',
            zeroPaddings: true,
            render: ({item}) => <AnimationActionsCell item={item} onEdit={() => setAnimation(item)} />,
          },
        ]}
      />
      {!!animation && (
        <InstoreAnimationContainer onClose={() => setAnimation(null)} animation={animation} isReporting />
      )}
    </div>
  );
};

InstoreReportingAnimationList.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

InstoreReportingAnimationList.defaultProps = {
  task: null,
};

export default InstoreReportingAnimationList;
