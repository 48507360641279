import React, {useMemo, useState} from 'react';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {interpolate} from 'client/ducks/language/helpers';
import {createLeadColumn, deleteLeadColumn, updateLeadColumn} from 'client/ducks/leads-list/actions';

import AppButton from 'client/common/buttons';
import {CheckboxField, SelectField, TextField} from 'client/common/fields';
import {useToast} from 'client/common/hooks/useToast';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Modal from 'client/common/modals/modal';

import {LeadColumn} from 'client/models/lead/types';

import validate from './validate';

import cssModule from './leads-custom-column-modal.module.scss';

const b = bem('leads-custom-column-modal', {cssModule});

type LeadsCustomColumnModalProps = {
  onClose: () => void;
  onSubmit: () => void;
  clientId: number;
  initial: LeadColumn | null;
};

const COLUMN_TYPES = {
  TEXT: 'text',
  NUMBER: 'decimal',
  EMAIL: 'email',
  PHONE: 'phone',
  BOOLEAN: 'boolean',
  FILE: 'file',
};

export type LeadsCustomColumnFormValues = {
  kind: string;
  name: string;
  array: boolean;
};

const LeadsCustomColumnModal: ReduxFormFC<LeadsCustomColumnModalProps, LeadsCustomColumnFormValues> = (props) => {
  const {onClose, onSubmit, handleSubmit, clientId, initial} = props;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const lang = useLanguage('LEADS_PARAMETERS.MODALS.LEADS_CUSTOM_COLUMN_MODAL');
  const langCommon = useLanguage('COMMON');

  const {appendToastNotification} = useToast();

  const {invalid, formValues} = useReduxForm<LeadsCustomColumnFormValues>('LeadsCustomColumnModalForm', {
    initialValues: initial || {kind: COLUMN_TYPES.TEXT},
    validate: (values) => validate(values, langCommon),
  });

  const {loading, fetch} = useReduxFetch({
    action: initial ? updateLeadColumn : createLeadColumn,
    fetchOnMount: false,
  });

  const {fetch: deleteFetch} = useReduxFetch({
    action: deleteLeadColumn,
    fetchOnMount: false,
  });

  const typeOptions = useMemo(
    () =>
      Object.entries(COLUMN_TYPES).map(([key, value]) => ({
        label: lang[`${key}_TYPE` as keyof typeof lang],
        value,
      })),
    [lang],
  );

  const submit = handleSubmit(async (values) => {
    const body = {
      id: initial?.id,
      kind: values.kind,
      name: values.name,
      array: values.kind !== COLUMN_TYPES.BOOLEAN && values.array,
      client_id: clientId,
    };

    try {
      await fetch(body);
      onClose();
      onSubmit();
    } catch {
      appendToastNotification({
        type: 'error',
        title: lang.ERROR,
      });
    }
  });

  const handleDelete = async () => {
    if (initial) {
      await deleteFetch(initial.id);
      setShowDeleteConfirmation(false);
      onSubmit();
      onClose();
    }
  };

  return (
    <>
      <Modal title={initial ? lang.EDIT_TITLE : lang.CREATE_TITLE} onClose={onClose} show={!showDeleteConfirmation}>
        <form className={b('form')} onSubmit={submit}>
          <div className={b('row')}>
            <SelectField
              options={typeOptions}
              name="kind"
              label={lang.COLUMN_TYPE}
              className={b('column-type')}
              simpleValue
            />
            {formValues?.kind !== COLUMN_TYPES.BOOLEAN && (
              <CheckboxField
                name="array"
                label={lang.DATA_TABLE_CHECKBOX}
                className={b('checkbox')}
                color="primary"
                inversionColor
              />
            )}
          </div>
          <TextField name="name" label={lang.COLUMN_NAME} required />
          <div className={b('buttons')}>
            {initial && (
              <AppButton
                transparent
                label={lang.DELETE_BUTTON}
                color="error"
                onClick={() => setShowDeleteConfirmation(true)}
              />
            )}
            <AppButton transparent label={lang.CANCEL_BUTTON} color="clients" onClick={onClose} size="large" />
            <AppButton
              label={initial ? lang.SAVE_BUTTON : lang.CREATE_BUTTON}
              color="clients"
              size="large"
              submit
              disabled={invalid}
              loading={loading}
            />
          </div>
        </form>
      </Modal>
      <ConfirmationModal
        show={showDeleteConfirmation}
        title={lang.DELETING_CONFIRMATION_TITLE}
        message={interpolate(lang.DELETING_CONFIRMATION?.toString(), {columnName: initial?.name || ''})}
        clientSide
        buttonConfirm={{
          label: lang.DELETE_BUTTON,

          onClick: handleDelete,
        }}
        buttonCancel={{
          label: lang.CANCEL_BUTTON,
          onClick: () => setShowDeleteConfirmation(false),
        }}
      />
    </>
  );
};

export default reduxForm<LeadsCustomColumnModalProps>({
  form: 'LeadsCustomColumnModalForm',
})(LeadsCustomColumnModal);
