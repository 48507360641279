/* eslint-disable */
import React, {Component} from 'react';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Select from 'react-select-plus';

import {getAppLanguage} from 'client/common/config';

import AsyncDropdownAny from 'client/components/common/async-dropdown-any';

import {TranslationJsx} from 'client/models/language/types';

import CustomScrollbars from '../custom-scrollbars';
import Icon from '../icon';

class DateTimeField extends Component {
  /**
   * Just not-null and not-date string, which makes moment(str).isValid() -> false
   * @type {string}
   */
  static INVALID_STRING = 'invalid';

  constructor(props) {
    super(props);

    this.state = this.updateState(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.input.value !== prevProps.input.value && moment(this.props.input.value).isValid()) {
      this.setState(this.updateState(this.props, true));
    }
  }

  updateState = (props, force = false) => {
    const {
      input: {value},
    } = props;

    const momentDate = value && moment(value).isValid() ? moment(value) : null;

    if (!this.state || !value || force) {
      return {
        timeValue: momentDate,
        dateValue: momentDate,
      };
    }

    return this.state;
  };

  handleValueChanged = (inputFunction) => {
    const {timeFormat, dateFormat} = this.props;

    const {timeValue} = this.state;

    const momentTimeValue = moment(timeValue, timeFormat, true);
    const momentDateValue = moment(this.dateInput.value, dateFormat, true);

    let value = this.dateInput.value && timeValue && DateTimeField.INVALID_STRING;

    if (momentTimeValue.isValid() && momentDateValue.isValid()) {
      value = moment({
        year: momentDateValue.get('year'),
        month: momentDateValue.get('month'),
        date: momentDateValue.get('date'),
        hours: momentTimeValue.get('hours'),
        minutes: momentTimeValue.get('minutes'),
        seconds: 0,
      });
    }

    inputFunction(value);
  };

  handleDateChanged = (date) => {
    const dateValue = moment(date, this.props.dateFormat, true);
    this.setState(
      {
        dateValue: dateValue.isValid() ? dateValue : this.state.dateValue,
      },
      () => this.handleValueChanged(this.props.input.onChange),
    );
  };

  handleTimeChanged = (time) => {
    this.setState(
      {
        timeValue: time,
      },
      () => this.handleValueChanged(this.props.input.onChange),
    );
  };

  handleDayChanged = (e) => {
    this.handleDateChanged(e.target.value);
  };

  handlePickerClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  render() {
    const {
      timeFormat,
      dateFormat,
      cssModifier,
      placeholder,
      label,
      labelBlock,
      meta: {error, touched},
      disabled,
      disabledDayBefore,
      disabledDayAfter,
    } = this.props;

    const {timeValue, dateValue} = this.state;

    const disabledDays = {};

    if (disabledDayBefore) {
      disabledDays.before = disabledDayBefore;
    }

    if (disabledDayAfter) {
      disabledDays.after = disabledDayAfter;
    }

    const classes = classNames('datetimepicker', cssModifier, {
      'datetimepicker--error': touched && error,
    });
    const labelClasses = classNames({
      datetimepicker__label: true,
      'datetimepicker__label--block': labelBlock,
    });

    return (
      <div className={classes} onClick={this.handlePickerClick}>
        <label className="datetimepicker__inner">
          {label && <div className={labelClasses}>{label}</div>}
          <div className="datepicker__field-wrap">
            <DayPickerInput
              className="datepicker__field"
              classNames={this.props.classNames}
              disabled={disabled}
              placeholder={placeholder}
              format={dateFormat}
              dayPickerProps={{
                disabledDays,
                localeUtils: MomentLocaleUtils,
                locale: getAppLanguage(),
              }}
              onDayChange={this.handleDateChanged}
              onChange={this.handleDayChanged}
              value={dateValue ? dateValue.format(dateFormat) : ''}
              onBlur={() => this.handleValueChanged(this.props.input.onBlur)}
              ref={(date) => {
                if (!this.dateInput) {
                  this.dateInput = date && date.input;
                }
              }}
            />
            <div className="datepicker__field-icon-wrap" onClick={() => this.dateInput && this.dateInput.focus()}>
              <Icon name="calendar" className="datepicker__field-icon" />
            </div>
          </div>
          <Datetime
            className="timepicker__field"
            dateFormat={false}
            inputProps={{
              disabled: disabled,
            }}
            timeFormat={timeFormat}
            value={timeValue}
            onChange={this.handleTimeChanged}
            onBlur={() => this.handleValueChanged(this.props.input.onBlur)}
          />
        </label>
        {error && touched && <div className="datetimepicker__message">{error}</div>}
      </div>
    );
  }
}

class Field extends Component {
  static TYPES = {
    SELECT: 'select',
    SELECT_ASYNC: 'select-async',
    ASYNC_DROPDOWN: 'async-dropdown',
    CHECKBOX: 'checkbox',
    SWITCHER: 'switcher',
    RADIO: 'radio',
    TEXTAREA: 'textarea',
    NUMBER: 'number',
    FILE: 'file',
    FILE_SINGLE: 'file-single',
    DATEPICKER: 'datepicker',
    TIMEPICKER: 'timepicker',
    DATETIMEPICKER: 'datetimepicker',
  };

  static SUBTYPES = {
    SWITCHER: 'switcher',
  };

  elRef = null;

  componentDidMount() {
    this.setFocus();
  }

  componentDidUpdate() {
    // setTimeout(this.setFocus,500); // ! temporary solve
  }

  handleFileChanged = (e) => {
    e.preventDefault();
    this.props.input.onChange([...e.target.files]);
  };

  radio() {
    const {input, label, type, cssModifier, cssTextModifier = '', radioType, text, onClick, disabled} = this.props;
    const cssClass = classNames({
      'radio-button': true,
      'radio-button--switcher-1': radioType === 'switcher-1',
      'radio-button--switcher-2': radioType === 'switcher-2',
      'radio-button--base': radioType === 'base',
    });

    return (
      <label className={`${cssClass} ${cssModifier}`}>
        <input className="radio-button__input" disabled={disabled} type={type} {...input} onClick={onClick} />
        {text && <div className="radio-button__add-text">{text}</div>}
        <div className="radio-button__label">
          <span className="radio-button__icon" />
          <span className={`${cssTextModifier || 'radio-button__text'}`}>{label}</span>
        </div>
      </label>
    );
  }

  switcher() {
    const {input, cssModifier, label, disabled, onChange, defaultChecked} = this.props;

    return (
      <label className={`switcher ${cssModifier}`}>
        {label && <span className="switcher__text">{label}</span>}
        <div className="switcher__input-wrap">
          <input
            className="switcher__input"
            type="checkbox"
            onChange={onChange}
            {...input}
            disabled={disabled}
            defaultChecked={defaultChecked}
            ref={this.refLink}
          />
          <div className="switcher__label" />
        </div>
      </label>
    );
  }

  checkbox() {
    const {input, label, type, text, cssModifier, checkboxType, checked, disabled, onChange, tabIndex} = this.props;

    const cssClass = classNames({
      'checkbox-button': true,
      'checkbox-button--switcher-2': checkboxType === 'switcher-2',
    });

    return (
      <label className={`${cssModifier} ${cssClass}`}>
        <input
          tabIndex={tabIndex}
          type={type}
          onChange={onChange}
          {...input}
          checked={checked || input.checked}
          disabled={disabled}
          className="checkbox-button__input"
        />
        {text && <div className="checkbox-button__add-text">{text}</div>}
        <div className="checkbox-button__label">
          <Icon name="check" className="checkbox-button__icon" />
          <span className="checkbox-button__text">{label}</span>
        </div>
      </label>
    );
  }

  selectAsync() {
    const {
      input,
      label,
      meta: {error, touched},
      cssModifier,
      required,
      lang,
      ...props
    } = this.props;

    const errorClass = error && touched ? 'select--error' : '';

    return (
      <div className={`select ${cssModifier} ${errorClass}`}>
        <div className="select__label">
          {label}
          {required && <span className="input__label--required">*</span>}
        </div>
        <Select.Async
          noResultsText={lang.DROPDOWN_NO_DATA}
          {...input}
          {...props}
          onChange={(value) => {
            if (isEmpty(value) || (Array.isArray(value) && value.length === 0)) {
              if (this.props.input.onChange) {
                this.props.input.onChange({});
              }
              if (this.props.onChange) {
                this.props.onChange({});
              }
            } else {
              if (this.props.input.onChange) {
                this.props.input.onChange(value);
              }
              if (this.props.onChange) {
                this.props.onChange(value);
              }
            }
          }}
          className="select__field"
          arrowRenderer={() => <Icon name="dropdown" className="select__icon" />}
          clearable={false}
          onBlur={() => input.onBlur(input.value)}
        />
        {touched && error && <p className="select__message">{error}</p>}
      </div>
    );
  }
  asyncDropdown() {
    const {
      input,
      label,
      meta: {error, touched},
      cssModifier,
      required,
      ...props
    } = this.props;

    const errorClass = error && touched ? 'select--error' : '';

    const dropdownChange = (value = {}) => {
      if (this.props.input.onChange) {
        this.props.input.onChange(value);
      }
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    };

    return (
      <div className={`select ${cssModifier} ${errorClass}`}>
        <div className="select__label">
          {label}
          {required && <span className="input__label--required">*</span>}
        </div>
        <AsyncDropdownAny
          {...input}
          {...props}
          onChange={(value) => {
            if (isEmpty(value) || (Array.isArray(value) && value.length === 0)) {
              dropdownChange();
            } else {
              dropdownChange(value);
            }
          }}
          className="select__field"
          clearable={false}
          onBlur={() => input.onBlur(input.value)}
        />
        {touched && error && <p className="select__message">{error}</p>}
      </div>
    );
  }

  customSelect() {
    const {
      input,
      options = [],
      label,
      meta: {error, touched},
      cssModifier,
      required,
      icon,
      scrollBarProps,
      onChange,
      customOptionRenderer,
      customValueRenderer,
      wrapperEventHandlers = {},
      simpleValue,
      lang,
      ...props
    } = this.props;

    const errorClass = error && touched ? 'select--error' : '';

    const scrollbarProps = {
      autoHeightMax: 190,
      hideTracksWhenNotNeeded: true,
      ...scrollBarProps,
    };

    const menuRenderer = (params) => {
      const menu = Select.defaultProps.menuRenderer(params);

      return (
        <CustomScrollbars
          scrollbarProps={scrollbarProps}
          cssModifier="custom-scrollbars--view-2 custom-scrollbars--hide-hor-scroll"
        >
          <div className="custom-scrollbars__scroll-content">{menu}</div>
        </CustomScrollbars>
      );
    };

    const valueRenderer = (option) => {
      const label = option[props.labelKey] || option.label;

      return (
        <div className="select__value" title={label}>
          {label}
          {option.selectCustomOption && <span className="select__value-custom">{option.selectCustomOption}</span>}
        </div>
      );
    };

    const optionRenderer = (item) => {
      return (
        <div className="select__option-content">
          {icon && <span className="select__option-icon">{icon}</span>}
          <span className="select__option-text">{item[props.labelKey] || item.label}</span>
          {item.selectCustomOption && <span className="select__option-custom">{item.selectCustomOption}</span>}
        </div>
      );
    };

    let val;

    if (input.value) {
      if (input.value.value) {
        val = input.value.value;
      } else {
        val = input.value;
      }
    } else {
      val = undefined; // eslint-disable-line
    }

    const onSelectChange = (value) => {
      if ((!props.clearable && isEmpty(value) && !simpleValue) || (Array.isArray(value) && value.length === 0)) {
        const defaultValue = Array.isArray(value) ? [] : {};
        if (input.onChange) {
          input.onChange(defaultValue);
        }
        if (onChange) {
          onChange(defaultValue);
        }
      } else {
        if (input.onChange) {
          input.onChange(value);
        }
        if (onChange) {
          onChange(value);
        }
      }
    };

    return (
      <div className={`select ${cssModifier} ${errorClass}`} {...wrapperEventHandlers}>
        {label && (
          <div className="select__label">
            {label}
            {required && <span className="input__label--required">*</span>}
          </div>
        )}
        <Select
          noResultsText={lang.DROPDOWN_NO_DATA}
          {...input}
          value={val}
          options={options}
          {...props}
          simpleValue={simpleValue}
          onBlur={() => (input.onBlur ? input.onBlur(input.value) : props.onBlur(input.value))}
          className="select__field"
          clearable={props.clearable || false}
          arrowRenderer={() => <Icon name="dropdown" className="select__icon" />}
          onChange={onSelectChange}
          menuRenderer={menuRenderer}
          optionRenderer={customOptionRenderer || optionRenderer}
          valueRenderer={customValueRenderer || valueRenderer}
          autoFocus={this.props.focusOnMount ? true : false}
        />
        {touched && error && <p className="select__message">{error}</p>}
      </div>
    );
  }

  select() {
    let options = this.props.options || [];

    if (this.props.type === 'select-multiple') {
      this.props.input.multiple = 'multiple';
    }

    return (
      <select id={this.id} className={this.props.className || 'form-control'} {...this.props.input} ref={this.refLink}>
        <option value={null} />
        {options.map((item, index) => {
          if (!item.children || !item.children.length) {
            return (
              <option value={item[this.props.valueKey]} key={index}>
                {item.name}
              </option>
            );
          }

          return (
            <optgroup label={item.name} key={index}>
              {item.children.map((item, index) => (
                <option value={item[this.props.valueKey]} key={index}>
                  {item.name}
                </option>
              ))}
            </optgroup>
          );
        })}
      </select>
    );
  }

  textarea() {
    const {
      disabled,
      getRef = () => {},
      cssModifier,
      maxLength = 1000,
      meta: {error, warning, touched},
      style,
      hideErrorMessage,
    } = this.props;
    return (
      <div className={`textarea ${cssModifier} ${error && touched ? 'input--error' : ''}`}>
        <label className="input__inner">
          <div className="input__label">
            {this.props.label}
            {this.props.required && <span className="input__label--required">*</span>}
          </div>
          <textarea
            {...this.props.input}
            {...style}
            id={this.id}
            className={this.props.className || 'input__field form-control'}
            disabled={disabled}
            maxLength={maxLength}
            placeholder={this.props.placeholder}
            ref={getRef}
          >
            {this.props.input.value}
          </textarea>
        </label>
        {touched && error && !hideErrorMessage && <p className="input__message">{error}</p>}
        {warning && <p className="input__message">{warning}</p>}
      </div>
    );
  }

  setFocus = () => {
    if (this.props.focusOnMount && this.elRef) {
      this.elRef.focus();
    }
  };

  refLink = (el) => {
    this.elRef = el;
  };

  input() {
    const {
      input,
      disabled,
      placeholder,
      link,
      linkText,
      label,
      type,
      meta: {error, warning, touched} = {},
      cssModifier,
      required,
      style,
      onKeyPress,
      tabIndex,
      maxLength = 255,
      isPositive,
      hideErrorMessage,
    } = this.props;
    const isShowPlaceholder = {};

    let mainDivClass = this.props.mainDivClass || 'input';
    if (error && touched) {
      mainDivClass += ' input--error';
    }
    if (warning) {
      mainDivClass += ' input--valid';
    }

    if (placeholder) {
      isShowPlaceholder.placeholder = placeholder;
    }

    const onChange = (e) => {
      if (type === 'number') {
        if (isPositive ? Number(e.target.value) >= 0 : Number(e.target.value)) {
          input.onChange(e);
        }
      } else {
        input.onChange(e);
      }
    };

    return (
      <div className={`${mainDivClass} ${cssModifier}`} style={style}>
        <label className="input__inner">
          {label && (
            <div className="input__label">
              {label}
              {required && <span className="input__label--required">*</span>}
            </div>
          )}
          {linkText && (
            <div className="input__add-text">
              <Link to={link} className="link">
                {linkText}
              </Link>
            </div>
          )}
          <div>
            <input
              tabIndex={tabIndex}
              className="input__field"
              {...isShowPlaceholder}
              disabled={disabled}
              {...input}
              onChange={onChange}
              maxLength={maxLength}
              type={type}
              onKeyPress={onKeyPress}
              ref={this.refLink}
            />
          </div>
        </label>
        {touched && error && !hideErrorMessage && <p className="input__message">{error}</p>}
        {warning && <p className="input__message">{warning}</p>}
      </div>
    );
  }

  file() {
    const {
      input: {
        value, // eslint-disable-line
        ...input
      },
      meta: {error},
      label,
      placeholder,
      multiple,
      disabled,
      fileName,
      type,
      accept,
      cssModifier,
      buttonText,
      htmlFor,
      buttonCssClass,
    } = this.props;

    let mainDivClass = `input-file ${cssModifier}`;
    mainDivClass = error ? `input--error ${mainDivClass}` : mainDivClass;

    return (
      <div className={mainDivClass}>
        <div className="input-file__label">
          <label htmlFor={htmlFor} className="input-file__file-name-wrap">
            <div className={classNames('input-file__btn', 'button', buttonCssClass, {disabled})}>
              <span>{buttonText}</span>
            </div>
            <div className={error ? 'input-file__field input-file__field--error' : 'input-file__field'}>
              {fileName ? <span className="input-file__file-name">{fileName}</span> : placeholder}
            </div>
          </label>
          <input
            id={htmlFor}
            className="input-file__input"
            type={type}
            multiple={multiple}
            accept={accept}
            disabled={disabled}
            onChange={this.handleFileChanged}
          />
        </div>
        {error && <p className="input__message">{error}</p>}
      </div>
    );
  }

  fileSingle() {
    const {input, label, placeholder, fileName, cssModifier, buttonText, htmlFor, buttonCssClass} = this.props;

    return (
      <div className={`input-file ${cssModifier}`}>
        <div className="input-file__label">
          <label htmlFor={htmlFor} className="input-file__file-name-wrap">
            <div className={`input-file__btn button ${buttonCssClass}`}>
              <span>{buttonText}</span>
            </div>
            <div className="input-file__field">
              {fileName ? <span className="input-file__file-name">{fileName}</span> : placeholder}
            </div>
          </label>
          <input id={htmlFor} className="input-file__input" type="file" {...input} />
        </div>
      </div>
    );
  }

  datepicker() {
    let {
      cssModifier = '',
      meta: {error, touched},
      label,
      placeholder,
      input,
      disabled,
      disabledDayBefore,
      disabledDayAfter,
      readOnly,
    } = this.props;

    if (error && touched) {
      cssModifier += ' datepicker--error';
    }

    const disabledDays = {};
    if (disabledDayBefore) {
      disabledDays.before = disabledDayBefore;
    }
    if (disabledDayAfter) {
      disabledDays.after = disabledDayAfter;
    }

    const dayPickerProps = {
      disabledDays,
      localeUtils: MomentLocaleUtils,
      locale: getAppLanguage(),
    };

    return (
      <div className={`datepicker ${cssModifier}`}>
        <label className="datepicker__inner">
          {label && <div className="datepicker__label">{label}</div>}
          <div className="datepicker__field-wrap">
            <DayPickerInput
              className="datepicker__field"
              {...input}
              readOnly={readOnly}
              disabled={disabled}
              placeholder={placeholder}
              format="DD/MM/YYYY"
              dayPickerProps={dayPickerProps}
              classNames={this.props.classNames}
              onDayChange={(day) => input.onChange(day && day.format('DD/MM/YYYY'))}
            />
            <div className="datepicker__field-icon-wrap">
              <Icon name="calendar" className="datepicker__field-icon" />
            </div>
          </div>
        </label>
        {touched && error && <div className="datepicker__message">{error}</div>}
      </div>
    );
  }

  timepicker() {
    let {
      cssModifier,
      timepickerProps,
      input,
      label,
      meta: {error, touched},
    } = this.props;

    const settings = {
      dateFormat: false,
      timeFormat: 'HH:mm',
      ...timepickerProps,
    };

    if (error && touched) {
      cssModifier += ' timepicker--error';
    }

    return (
      <div className={`timepicker ${cssModifier}`}>
        <label className="timepicker__inner">
          {label && <div className="timepicker__label">{label}</div>}
          <Datetime className="timepicker__field" {...settings} {...input} />
        </label>
        {touched && error && <div className="timepicker__message">{error}</div>}
      </div>
    );
  }

  datetimepicker() {
    return <DateTimeField {...this.props} dateFormat="DD/MM/YYYY" timeFormat="HH:mm" />;
  }

  field() {
    switch (this.props.type) {
      case Field.TYPES.SELECT:
        return this.customSelect();

      case Field.TYPES.SELECT_ASYNC:
        return this.selectAsync();

      case Field.TYPES.ASYNC_DROPDOWN:
        return this.asyncDropdown();

      case Field.TYPES.CHECKBOX:
        return this.props.subtype === Field.SUBTYPES.SWITCHER ? this.switcher() : this.checkbox();

      case Field.TYPES.SWITCHER:
        return this.switcher();

      case Field.TYPES.RADIO:
        return this.radio();

      case Field.TYPES.TEXTAREA:
        return this.textarea();

      case Field.TYPES.FILE:
        return this.file();

      case Field.TYPES.FILE_SINGLE:
        return this.fileSingle();

      case Field.TYPES.DATEPICKER:
        return this.datepicker();

      case Field.TYPES.TIMEPICKER:
        return this.timepicker();

      case Field.TYPES.DATETIMEPICKER:
        return this.datetimepicker();

      default:
        return this.input();
    }
  }

  render() {
    return this.field();
  }
}

Field.defaultProps = {
  label: '',
  cssModifier: '',
  placeholder: '',
  buttonCssClass: 'button--bg-1',
  style: {},
  onBlur: () => {},
  meta: {},
  input: {},
  radioType: 'base',
  selectCustomOption: '',
  scrollBarProps: {},
  readOnly: false,
};

Field.propTypes = {
  htmlFor: PropTypes.string,
  id: PropTypes.number,
  input: PropTypes.object,
  minLength: PropTypes.string,
  disabled: PropTypes.bool,
  defaultChecked: PropTypes.any,
  name: PropTypes.string,
  maxLength: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  className: PropTypes.string,
  placeholder: TranslationJsx,
  type: PropTypes.string,
  subtype: PropTypes.string,
  options: PropTypes.array,
  valueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  parent: PropTypes.object,
  meta: PropTypes.object,
  error: TranslationJsx,
  warning: TranslationJsx,
  touched: PropTypes.bool,
  label: PropTypes.any,
  children: PropTypes.object,
  mainDivClass: PropTypes.string,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  cssModifier: PropTypes.string,
  radioType: PropTypes.string,
  style: PropTypes.object,
  fileName: PropTypes.string,
  scrollBarProps: PropTypes.object,
  icon: PropTypes.any,
  buttonCssClass: PropTypes.string,
  buttonText: PropTypes.any,
  selectCustomOption: PropTypes.any,
  timepickerProps: PropTypes.object,
  readOnly: PropTypes.bool,
};
/* eslint-enable react/prop-types */
export default connect((state) => ({
  lang: state.languageState.payload.COMMON,
}))(Field);
