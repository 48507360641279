import React from 'react';

import {Field} from 'redux-form';

import BaseFieldComponent from 'client/common/fields/base-field';
import {ToggleInput} from 'client/common/inputs';

import {BaseFieldComponentProps} from '../base-field/types';

const InputComponent = (props) => (
  <BaseFieldComponent Component={ToggleInput} checked={Boolean(props.input.value)} {...props} />
);

InputComponent.propTypes = BaseFieldComponentProps;

const ToggleField = (props) => {
  return <Field component={InputComponent} {...props} />;
};

export default ToggleField;
