import {useEffect} from 'react';

import {useQueryParams} from 'client/services/hooks';

export default (params) => {
  const {campaigns, setFirst = false} = params;
  const [queryParams, setQueryParams] = useQueryParams(null, {}, {parse: {parseNumbers: true}});

  useEffect(() => {
    if (!queryParams.campaignId && campaigns.length) {
      setQueryParams({campaignId: setFirst ? campaigns[0].id : 0}, {replace: true});
    }
  }, [queryParams.campaignId, setQueryParams, campaigns, setFirst]);
};
