import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import {Translation} from 'client/models/language/types';

import cssModule from './warning-message.module.scss';

const b = bem('warning-message', {cssModule});

type WarningMessageProps = {
  className?: string;
  warningMessage: Translation;
};

const WarningMessage: React.FC<WarningMessageProps> = (props) => {
  const {className, warningMessage} = props;

  return <p className={cn(b(), className)}>{warningMessage}</p>;
};

export default WarningMessage;
