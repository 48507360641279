import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import {arrayUnique, required} from 'client/services/validator';

import {patchSocialAccount, removeSocialAccount} from 'client/ducks/social-accounts/actions';

import Icon from 'client/common/icon';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

import './params-social-modal.scss';

class ParamsSocialModal extends Component {
  static formName = 'ParamsSocialModalForm';

  static defaultProps = {
    show: false,
    subsidiaries: [],
  };

  static propTypes = {
    show: PropTypes.bool,
    lang: PropTypes.object.isRequired,
    data: PropTypes.object,
    subsidiaries: PropTypes.array,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    patchSocialAccount: PropTypes.func.isRequired,
    removeSocialAccount: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    socialAccountsNames: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.rules = {
      name: [
        required(this.props.lang.NAME_IS_REQUIRED_ERROR),
        (value) => {
          return arrayUnique(this.props.socialAccountsNames, this.props.lang.NAME_IS_TAKEN)(value);
        },
      ],
      subsidiary: required(this.props.lang.SUBSIDIARY_IS_REQUIRED),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.props.initialize(this.props.data);
    }
  }

  save = async (form) => {
    const {data} = this.props;

    await this.props.patchSocialAccount(data.id, {
      name: form.name,
      subsidiary_id: form.subsidiary_id,
    });

    this.props.onConfirm();
    this.props.reset();
  };

  handleRemove = async () => {
    const {data} = this.props;

    await this.props.removeSocialAccount(data.id);

    this.props.onConfirm();
    this.props.reset();
  };

  handleClose = () => {
    this.props.onClose();
    this.props.reset();
  };

  render() {
    const {handleSubmit, show, lang, subsidiaries} = this.props;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-7"
        backdropClassName="modal-window__backdrop"
        onClose={this.handleClose}
        title={lang.TITLE}
      >
        <form onSubmit={handleSubmit(this.save)}>
          <FormFieldWrap>
            <Field
              label={lang.NAME_LABEL + ' *'}
              name="name"
              type="text"
              validate={this.rules.name}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              label={lang.SUBSIDIARY_LABEL + ' *'}
              name="subsidiary_id"
              type="select"
              simpleValue
              labelKey="name"
              valueKey="id"
              options={subsidiaries}
              validate={this.rules.subsidiary}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <button className="button button--bg-12" type="button" onClick={this.handleRemove}>
              <Icon name="trash" className="button__icon" width={17} height={19} />
              <span>{lang.REMOVE_BUTTON}</span>
            </button>
          </FormFieldWrap>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={this.handleClose}>
              {lang.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-2 modal-window__footer-btn" type="submit">
              {lang.SAVE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const form = reduxForm({
  form: ParamsSocialModal.formName,
})(ParamsSocialModal);

const mapStateToProps = ({languageState}) => ({
  lang: languageState.payload.PARAMETERS.SOCIAL_MODAL,
});

const mapDispatchToProps = {
  patchSocialAccount,
  removeSocialAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
