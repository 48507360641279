import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError, reset, initialize} from 'redux-form';

import {getAuthHeaders} from 'client/services/helpers';

import {API_PATH, API_METHODS} from 'client/common/config';
// components
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

import './new-autotask-modal.scss';

let LANGUAGE = {};

class NewAutotaskModal extends Component {
  static propTypes = {
    operationId: PropTypes.number,
    show: PropTypes.bool,
    submitting: PropTypes.bool,
    onConfirm: PropTypes.func,
    handleSubmit: PropTypes.func,
    updateErrors: PropTypes.func,
    reset: PropTypes.func,
    initialize: PropTypes.func,
    onClose: PropTypes.func,
    languageState: PropTypes.object,
    formValues: PropTypes.object,
  };

  static defaultProps = {
    show: false,
    onConfirm: () => {},
    onClose: () => {},
    operationId: 0,
  };

  static updateSyncErrors(values) {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'NewAutotaskModalForm',
      },
      payload: {
        syncErrors: NewAutotaskModal.validate(values),
      },
    };
  }

  static validate({name}) {
    const errors = {};

    if (!name || !name.trim()) {
      errors.name = LANGUAGE.NEW_AUTOTASK_MODAL && LANGUAGE.NEW_AUTOTASK_MODAL.NAME_IS_REQUIRED_ERROR;
    }

    return errors;
  }

  constructor(props) {
    super(props);
    LANGUAGE = this.props.languageState.payload.OPERATION_PAGE;
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.props.initialize({});
      this.props.updateErrors({});
    }
  }

  mapData = (data) => {
    return {
      automation_task: {
        operation_id: this.props.operationId,
        name: data.name,
      },
    };
  };

  save = (data) => {
    return fetch(`${API_PATH}${API_METHODS.AUTOMATION_TASKS}`, {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify(this.mapData(data)),
    })
      .then((res) => res.json())
      .then(({errors, automation_task}) => {
        if (errors) {
          const submitErrors = {};
          if (errors.name) {
            submitErrors.name = LANGUAGE.NEW_AUTOTASK_MODAL.NAME_IS_TAKEN_ERROR;
          }
          throw new SubmissionError(submitErrors);
        }
        this.props.reset();
        this.props.onConfirm(automation_task);
      });
  };

  render() {
    const {handleSubmit, submitting, onClose} = this.props;

    return (
      <Modal
        show={this.props.show}
        onClose={onClose}
        title={LANGUAGE.NEW_AUTOTASK_MODAL.TITLE}
        dialogClassName="modal-window--width-1 new-autotask-modal"
      >
        <form
          // noValidate='noValidate'
          onSubmit={handleSubmit(this.save)}
        >
          <div className="form-field-wrap">
            <Field
              label={LANGUAGE.NEW_AUTOTASK_MODAL.NAME_LABEL}
              cssModifier="input--no-min-height"
              name="name"
              type="text"
              component={fieldTemplate}
            />
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onClose}>
              {LANGUAGE.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-autotask modal-window__footer-btn" type="submit" disabled={submitting}>
              {LANGUAGE.NEW_AUTOTASK_MODAL.CREATE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const newAutotaskForm = reduxForm({
  form: 'NewAutotaskModalForm',
  validate: NewAutotaskModal.validate,
})(NewAutotaskModal);

export default connect(
  ({languageState, form}) => ({
    languageState,
    formValues: form.NewAutotaskModalForm,
  }),
  {
    reset,
    initialize,
    updateErrors: NewAutotaskModal.updateSyncErrors,
  },
)(newAutotaskForm);
