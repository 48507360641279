import React, {useCallback, useState} from 'react';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams} from 'client/services/hooks';

import CheckboxButtonGroup from 'client/common/button-group/checkbox-button-group';
import AppButton from 'client/common/buttons';
import SelectDropdown from 'client/common/selects/select-dropdown';

import {Option} from 'client/types/common/index';

import useFetchDataForm from './use-fetch-data-form';

import cssModule from './client-devices-list-filters-form.module.scss';

const b = bem('client-devices-list-filters-form', {cssModule});

type ClientDevicesFiltersFormProps = {
  onClose: () => void;
};

export type ClientDeviceFiltersFormValues = {
  place?: string[];
  region?: string[];
  task?: string[];
  status?: string[] | null;
  activity?: string[] | null;
};

const ClientDevicesFiltersForm: React.FC<ClientDevicesFiltersFormProps> = (props) => {
  const {onClose} = props;

  const lang = useLanguage('CLIENT_DEVICES.FILTER_MODAL');
  const [queryParams, setQueryParams] = useQueryParams(null, {}, {parse: {depth: 4}});

  const [formFields, setFormFields] = useState<ClientDeviceFiltersFormValues>(queryParams.filters || {});
  const {regions, stores, task, loading} = useFetchDataForm(formFields);

  const {FILTER_FORM} = lang;
  const {DEVICE_STATUSES, ACTIVITY_STATUSES} = FILTER_FORM;

  const handleFieldChange = useCallback((key, data) => {
    setFormFields((prev) => ({
      ...prev,
      [key]: data,
    }));
  }, []);

  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();
    setQueryParams({
      filters: formFields,
    });
    onClose();
  };

  const handleChangeStatus = (value: string[], selected: string) => {
    const isNot = selected === 'not' || !value.length;

    handleFieldChange('status', isNot ? null : value.filter((v) => v !== 'not'));
  };

  return (
    <form className={b()} onSubmit={handleSubmit}>
      <SelectDropdown
        multi
        value={formFields.region || []}
        options={regions}
        label={FILTER_FORM.REGION}
        simpleValue
        isLoading={loading}
        onChange={(data: Option) => handleFieldChange('region', data)}
      />

      <SelectDropdown
        multi
        value={formFields.place || []}
        options={stores}
        label={FILTER_FORM.PLACE}
        simpleValue
        isLoading={loading}
        onChange={(data: Option) => handleFieldChange('place', data)}
      />

      <CheckboxButtonGroup
        value={formFields.status || ['not']}
        onChange={handleChangeStatus}
        label={FILTER_FORM.DEVICE_STATUS}
        options={[
          {
            value: 'not',
            label: DEVICE_STATUSES.NO_MATTER,
          },
          {
            value: 'ok',
            label: DEVICE_STATUSES.OK,
          },
          {
            value: 'error',
            label: DEVICE_STATUSES.ERROR,
          },
          {
            value: 'maintenance',
            label: DEVICE_STATUSES.MAINTENANCE,
          },
          {
            value: 'not_activated',
            label: DEVICE_STATUSES.NOT_ACTIVATED,
          },
        ]}
      />

      <SelectDropdown
        multi
        value={formFields.task || []}
        options={task}
        label={FILTER_FORM.CAMPAIGN}
        simpleValue
        isLoading={loading}
        onChange={(data: Option) => handleFieldChange('task', data)}
      />

      <CheckboxButtonGroup
        value={formFields.activity || []}
        onChange={(value) => handleFieldChange('activity', value)}
        label={FILTER_FORM.ACTIVITY_FILTER}
        options={[
          {
            value: 'active',
            label: ACTIVITY_STATUSES.ACTIVE,
          },
          {
            value: 'inactive',
            label: ACTIVITY_STATUSES.INACTIVE,
          },
          {
            value: 'paper',
            label: ACTIVITY_STATUSES.PAPER_ALERT,
          },
          {
            value: 'token',
            label: ACTIVITY_STATUSES.TOKEN_ALERT,
          },
        ]}
      />

      <div className={b('footer')}>
        <AppButton label={FILTER_FORM.CANCEL} className={b('button', ['cancel'])} transparent onClick={onClose} />
        <AppButton label={FILTER_FORM.SHOW_RESULTS} className={b('button', ['submit'])} type="submit" />
      </div>
    </form>
  );
};

export default ClientDevicesFiltersForm;
