import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {formatFileSize} from 'client/services/formatters';
import {useLanguage} from 'client/services/hooks';

import {USER_MEMBERSHIP} from 'client/common/config';

import ClientTable from 'client/components/common/client-table';

import DatetimeCell from 'client/components/tables/common/cells/datetime-cell';
import {Visuals} from 'client/models/visuals/types';

import FilePreviewCell from './file-preview-cell';
import TagsCell from './tags-cell';
import VisualLeadCell from './visual-lead-cell';

import cssModule from './visuals-table.module.scss';

const b = bem('visuals-table', {cssModule});

type VisualsTableProps = {
  visuals: Visuals[];
  accessLevel: string;
  params: Record<string, any>;
  onParamsChange: () => void;
  onTogglePreviewModal: () => void;
  isLoading: boolean;
  className?: string;
};

const VisualsTable: React.FC<VisualsTableProps> = (props) => {
  const {visuals, accessLevel, params, onParamsChange, onTogglePreviewModal, isLoading, className} = props;

  const lang = useLanguage('CLIENT_VISUALS');

  return (
    <div className={cn(b(), className)}>
      <ClientTable
        data={visuals}
        loading={isLoading}
        sortField={params.sortField}
        sortOrder={params.sortOrder}
        onSortChange={onParamsChange}
        noDataText={lang.NO_DATA}
        loadingColor="visuals"
        iconClassName={b('icon')}
        columns={[
          {
            name: 'file_preview',
            label: lang.FILE_PREVIEW,
            zeroPaddings: true,
            render: (data) => <FilePreviewCell visual={data.item} onTogglePreviewModal={onTogglePreviewModal} />,
          },
          {
            name: 'title',
            label: lang.ITEM_NAME,
            path: 'title',
            sortable: true,
          },
          {
            name: 'created_at',
            label: lang.CREATION_DATE,
            path: 'created_at',
            render: DatetimeCell,
            sortable: true,
            initialSortOrder: 'desc',
          },
          {
            name: 'interface_name',
            label: lang.INTERFACE_NAME,
            path: 'interface.name',
            sortable: true,
          },
          {
            name: 'interaction_name',
            label: lang.ORIGIN,
            path: 'interaction',
            formatter: (interaction) => (interaction ? interaction.name : lang.DIRECT),
            sortable: true,
          },
          {
            name: 'lead',
            label: lang.LEAD,
            show: accessLevel === USER_MEMBERSHIP.CLIENT_ADMIN,
            render: (data) => <VisualLeadCell visual={data.item} />,
          },
          {
            name: 'file_extension',
            label: lang.FORMAT,
            path: 'file_extension',
            sortable: true,
          },
          {
            name: 'filesize',
            label: lang.SIZE,
            path: 'filesize',
            formatter: formatFileSize,
            sortable: true,
          },
          {
            name: 'tags',
            label: lang.TAGS,
            path: 'tags',
            render: (data) => <TagsCell tags={data.value} />,
          },
        ]}
      />
    </div>
  );
};

export default VisualsTable;
