import {useMemo} from 'react';

import {useSelector} from 'react-redux';

import {selectUser} from 'client/ducks/user/selectors';

import {HELP_URL, PRODUCT_FRUITS_WORKSPACE_CODE} from 'client/common/config';

export default () => {
  const user = useSelector(selectUser);

  const link = useMemo(() => {
    if (!PRODUCT_FRUITS_WORKSPACE_CODE || !user?.id) {
      return HELP_URL;
    }

    const hmac = {
      username: user.id,
      hmac: {
        hash: user.fruit_product_key,
        expiration: user.fruit_product_key_expired_at,
      },
    };

    return `${HELP_URL}?hmac=${encodeURIComponent(JSON.stringify(hmac))}`;
  }, [user?.id, user?.fruit_product_key, user?.fruit_product_key_expired_at]);

  return link;
};
