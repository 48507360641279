import React from 'react';

import {connect} from 'react-redux';

import {TranslationJsx} from 'client/models/language/types';

const HomeClientEmpty = ({message}) => <div className="text-center">{message}</div>;

HomeClientEmpty.propTypes = {
  message: TranslationJsx.isRequired,
};
const mapStateToProps = (state) => ({
  message: state.languageState.payload.HOME_CLIENT.MESSAGE,
});

export default connect(mapStateToProps)(HomeClientEmpty);
