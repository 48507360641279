import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useToggle, useUpdateEffect} from 'react-use';
import {reduxForm} from 'redux-form';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {useReduxForm} from 'client/services/hooks';
import {useQueryParams} from 'client/services/hooks';

import {getInstoreTaskKpis} from 'client/ducks/instore-dashboards/actions';
import {createDashboardBlock} from 'client/ducks/instore-dashboards/actions';
import {updateDashboardBlock} from 'client/ducks/instore-dashboards/actions';
import {selectInstoreTaskKpis} from 'client/ducks/instore-dashboards/selectors';
import {selectInstoreDashboardBlocks} from 'client/ducks/instore-dashboards/selectors';

import AppButton from 'client/common/buttons';
import {SelectField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import {
  INSTORE_DASHBOARD_BLOCK_TYPES,
  INSTORE_DASHBOARD_CONSOLIDATION_MODES,
  INSTORE_DASHBOARD_CONSOLIDATIONS,
} from 'client/models/instore-dashboard/constants';

import mapFormValues from './mapFormValues';
import mapInitialValues from './mapInitialValues';
import validate from './validateValues';

import cssModule from './instore-dashboard-block-modal.module.scss';

const b = bem('instore-dashboard-block-modal', {cssModule});

const MAP_TYPES_TO_PARAMS = {
  [INSTORE_DASHBOARD_BLOCK_TYPES.STATS_CONSOLIDATED]: 'stat',
  [INSTORE_DASHBOARD_BLOCK_TYPES.MONO_MCQ]: 'graph',
};
const FORM_NAME = 'InstoreDashboardBlockModalForm';

const InstoreDashboardBlockModal = (props) => {
  const {onClose, handleSubmit, editingItem, position, dirty, invalid} = props;

  const lang = useLanguage('INSTORE_DASHBOARDS.MODALS.INSTORE_DASHBOARD_BLOCK_MODAL');
  const dispatch = useDispatch();
  const {taskId} = useParams();
  const [queryParams] = useQueryParams();
  const {dashboardId} = queryParams;
  const {formValues, change} = useReduxForm(FORM_NAME, {
    validate: (values) => validate(values, lang),
    ...(editingItem && {initialValues: mapInitialValues(editingItem)}),
  });
  const [isSaving, toggleSaving] = useToggle(false);
  const taskKpis = useSelector(selectInstoreTaskKpis);
  const blocks = useSelector(selectInstoreDashboardBlocks);
  const newBlockPosition = blocks?.length ? blocks.length + 1 : 1;
  const kpiId = editingItem?.task_kpi_dashboard_blocks[0].id;

  useEffect(() => {
    if (!formValues?.block_type) {
      return;
    }

    dispatch(
      getInstoreTaskKpis({
        q: {
          instore_task_id_eq: taskId,
          kpi_type_eq: MAP_TYPES_TO_PARAMS[formValues.block_type],
        },
      }),
    );
  }, [dispatch, taskId, formValues?.block_type]);

  useUpdateEffect(() => {
    if (formValues?.block_type === INSTORE_DASHBOARD_BLOCK_TYPES.STATS_CONSOLIDATED && !formValues?.consolidation) {
      change('consolidation', INSTORE_DASHBOARD_CONSOLIDATIONS.REGION); // initialize fields on render
      change('consolidation_mode', INSTORE_DASHBOARD_CONSOLIDATION_MODES.AVERAGE); // initialize fields on render
    }
  }, [formValues?.block_type]);

  const isEditing = Boolean(editingItem);

  const handleSave = async (values) => {
    if (!dirty) {
      onClose();
      return;
    }

    toggleSaving(true);
    const positionToSave = isEditing ? position : newBlockPosition;
    const body = mapFormValues(values, {dashboardId, position: positionToSave, kpiId});

    if (isEditing) {
      await dispatch(updateDashboardBlock(editingItem.id, body));
    } else {
      await dispatch(createDashboardBlock(body));
    }

    toggleSaving(false);
    onClose(positionToSave, !isEditing);
  };

  const typeOptions = [
    {
      label: lang.TYPE_OPTIONS.STATS,
      value: INSTORE_DASHBOARD_BLOCK_TYPES.STATS_CONSOLIDATED,
    },
    {label: lang.TYPE_OPTIONS.MONO, value: INSTORE_DASHBOARD_BLOCK_TYPES.MONO_MCQ},
  ];

  const consolidationOptions = [
    {label: lang.CONSOLIDATION_OPTIONS.REGION, value: INSTORE_DASHBOARD_CONSOLIDATIONS.REGION},
    {label: lang.CONSOLIDATION_OPTIONS.SALES, value: INSTORE_DASHBOARD_CONSOLIDATIONS.SALES},
    {label: lang.CONSOLIDATION_OPTIONS.RETAILER, value: INSTORE_DASHBOARD_CONSOLIDATIONS.RETAILER},
    {label: lang.CONSOLIDATION_OPTIONS.KIT, value: INSTORE_DASHBOARD_CONSOLIDATIONS.KIT},
  ];

  const modeOptions = [
    {label: lang.MODE_OPTIONS.TOTAL, value: INSTORE_DASHBOARD_CONSOLIDATION_MODES.TOTAL},
    {label: lang.MODE_OPTIONS.AVERAGE, value: INSTORE_DASHBOARD_CONSOLIDATION_MODES.AVERAGE},
  ];

  const taskKpisOptions = taskKpis.map(({id, name}) => ({label: name, value: id}));

  return (
    <Modal onClose={onClose} title={isEditing ? lang.EDIT_TITLE : lang.ADD_TITLE} className={b()}>
      <form onSubmit={handleSubmit(handleSave)}>
        <SelectField
          name="block_type"
          label={lang.TYPE_LABEL}
          options={typeOptions}
          withWrap={true}
          simpleValue={true}
          required={true}
        />
        <SelectField
          name="task_kpis"
          label={lang.KPI_LABEL}
          options={taskKpisOptions}
          withWrap={true}
          simpleValue={true}
          required={true}
        />
        {formValues?.block_type === INSTORE_DASHBOARD_BLOCK_TYPES.STATS_CONSOLIDATED && (
          <>
            <SelectField
              name="consolidation"
              label={lang.CONSOLIDATION_LABEL}
              options={consolidationOptions}
              withWrap={true}
              simpleValue={true}
              required={true}
            />
            {Boolean(formValues?.consolidation) && (
              <SelectField
                name="consolidation_mode"
                label={lang.MODE_LABEL}
                options={modeOptions}
                simpleValue={true}
                required={true}
              />
            )}
          </>
        )}

        <AppButton
          color="primary"
          className={b('save-button')}
          label={isEditing ? lang.SAVE_BUTTON : lang.CREATE_BUTTON}
          loading={isSaving}
          size="medium"
          disabled={invalid}
          submit
        />
      </form>
    </Modal>
  );
};

InstoreDashboardBlockModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  editingItem: PropTypes.shape({
    block_type: PropTypes.string.isRequired,
    task_kpis: PropTypes.arrayOf(PropTypes.object).isRequired,
    consolidation: PropTypes.string.isRequired,
    consolidation_mode: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    task_kpi_dashboard_blocks: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.number.isRequired})).isRequired,
  }),
  position: PropTypes.number,

  // from redux-form
  handleSubmit: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
};

InstoreDashboardBlockModal.defaultProps = {
  editingItem: null,
  position: null,
};

export default reduxForm({
  form: FORM_NAME,
  keepDirtyOnReinitialize: true,
})(InstoreDashboardBlockModal);
