import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getInternalColumns(queryParams = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        queryParams,
        endpoint: `${API_PATH}${API_METHODS.COLUMN_ADAPTER}`,
        method: 'GET',
        types: [
          types.GET_INTERNAL_COLUMNS_REQUEST,
          types.GET_INTERNAL_COLUMNS_SUCCESS,
          types.GET_INTERNAL_COLUMNS_ERROR,
        ],
      },
      true,
    );
  };
}

export function flushInternalColumnsAllState() {
  return {
    type: types.FLUSH_INTERNAL_COLUMNS_ALL_STATE,
  };
}
