import React from 'react';

import {useSelector} from 'react-redux';

import {selectCurrentMembership} from 'client/ducks/user-clients/selectors';

import {ACCESS_LEVEL} from 'client/common/config';

import SocialNetworkAccountsCard from 'client/components/client-agency/components/cards/social-network-accounts-card';
import {Client} from 'client/models/client/types';

import AccountCard from '../account-card';

import './account-social-network.scss';

type AccountSocialNetworkProps = {
  client: Client;
  socialAccounts: any[];
  updateMe: () => void;
  loading: boolean;
  height?: number | null;
  fullHeight?: boolean;
};

const AccountSocialNetwork: React.FC<AccountSocialNetworkProps> = (props) => {
  const {client, socialAccounts, updateMe, loading, height, fullHeight} = props;
  const membership = useSelector(selectCurrentMembership);

  if (loading) {
    return <AccountCard className="account-social-network" loading={loading} />;
  }

  return (
    <AccountCard className="account-social-network">
      <SocialNetworkAccountsCard
        data={socialAccounts}
        accountsCount={socialAccounts.length}
        refresh={updateMe}
        clientId={client.id}
        isAdmin={false}
        allowEdit={membership?.access_level === ACCESS_LEVEL.CLIENT_ADMIN}
        className="account-social-network__card"
        height={height}
        fullHeight={fullHeight}
      />
    </AccountCard>
  );
};

export default AccountSocialNetwork;
