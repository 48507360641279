import {RSAA} from 'redux-api-middleware';

import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getClientUsersAction(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENT_USERS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_CLIENT_USERS_REQUEST, types.GET_CLIENT_USERS_SUCCESS, types.GET_CLIENT_USERS_ERROR],
      queryParams,
    });
}

export function getClientUsersWithMembershipsAction(clientId) {
  return getClientUsersAction({
    include: ['memberships'],
    q: {
      memberships_client_id_eq: clientId,
    },
  });
}

export const getAvailableForAttributionUsers = (clientId) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENT_USERS}/available_for_attribution`,
      method: 'GET',
      types: [
        types.GET_AVAILABLE_FOR_ATTRIBUTION_USERS_REQUEST,
        types.GET_AVAILABLE_FOR_ATTRIBUTION_USERS_SUCCESS,
        types.GET_AVAILABLE_FOR_ATTRIBUTION_USERS_REQUEST,
      ],
      queryParams: {
        client_id: clientId,
      },
    });
};

export function blockClientUser(id, blocked = true) {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.CLIENT_USERS}/${id}`,
        method: 'PATCH',
        body: JSON.stringify({
          client_user: {
            blocked,
            locked_by: blocked ? 'admin' : 'sign_in',
          },
        }),
        isAuth: true,
        types: [types.CLIENT_USERS_BLOCK_LOADING, types.CLIENT_USERS_BLOCK_SUCCESS, types.CLIENT_USERS_BLOCK_FAILED],
      },
    });
  };
}

export const resendInvitation = (userId) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.USERS}/${userId}/resend_invitation`,
      method: 'POST',
      noCache: true,
      types: [types.RESEND_INVITATION_REQUEST, types.RESEND_INVITATION_SUCCESS, types.RESEND_INVITATION_FAILED],
    });
};

export const resendClientInvitation = (userId, clientId) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENT_USERS}/${userId}/resend_invitation`,
      method: 'POST',
      noCache: true,
      types: [
        types.RESEND_CLIENT_INVITATION_REQUEST,
        types.RESEND_CLIENT_INVITATION_SUCCESS,
        types.RESEND_CLIENT_INVITATION_FAILED,
      ],
      queryParams: {
        client_id: clientId,
      },
    });
};

export const getRegions = (clientId, queryParams) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.REGIONS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_REGION_REQUEST, types.GET_REGION_SUCCESS, types.GET_REGION_FAILED],
      queryParams: {
        client_id_eq: clientId,
        ...queryParams,
      },
    });
};

export const getMemberships = (queryParams) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MEMBERSHIPS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_MEMBERSHIPS_REQUEST, types.GET_MEMBERSHIPS_SUCCESS, types.GET_MEMBERSHIPS_FAILED],
      queryParams,
    });
};

export const getMembership = (id, queryParams) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MEMBERSHIPS}/${id}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_MEMBERSHIPS_REQUEST, types.GET_MEMBERSHIPS_SUCCESS, types.GET_MEMBERSHIPS_FAILED],
      queryParams,
    });
};

export const unlinkClientUser = (userId, clientId, destroyRights) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.CLIENT_USERS}/${userId}/unlink`,
        method: 'POST',
        noCache: true,
        types: [types.UNLINK_CLIENT_USER_REQUEST, types.UNLINK_CLIENT_USER_SUCCESS, types.UNLINK_CLIENT_USER_FAILED],
        queryParams: {
          client_id: clientId,
          destroy_rights: destroyRights,
        },
      },
      true,
    );
};

export const deleteClientUser = (userId) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENT_USERS}/${userId}`,
      method: 'DELETE',
      noCache: true,
      types: [types.DELETE_CLIENT_USER_REQUEST, types.DELETE_CLIENT_USER_SUCCESS, types.DELETE_CLIENT_USER_FAILED],
    });
};

export const createClientUser = (body, queryParams) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.CLIENT_USERS}`,
        method: 'POST',
        noCache: true,
        types: [types.CREATE_CLIENT_USER_REQUEST, types.CREATE_CLIENT_USER_SUCCESS, types.CREATE_CLIENT_USER_FAILED],
        body,
        queryParams,
      },
      true,
    );
};

export const updateClientUser = (id, body, queryParams) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.CLIENT_USERS}/${id}`,
        method: 'PATCH',
        noCache: true,
        types: [types.UPDATE_CLIENT_USER_REQUEST, types.UPDATE_CLIENT_USER_SUCCESS, types.UPDATE_CLIENT_USER_FAILED],
        body,
        queryParams,
      },
      true,
    );
};
