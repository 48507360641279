import {dateToString} from 'client/services/helpers';

export function mapTaskIndex(tasks) {
  return tasks.map((taskItem) => {
    const {task} = taskItem;

    const type = task && task.type;
    const id = task && task.id;
    const createdAt = task && dateToString(task.created_at);
    const name = task && task.name;
    const operationId = task && task.operation_id;

    const clientId = task && task.operation && task.operation.client_id;

    const clientType = task && task.operation && task.operation.client && task.operation.client.type;

    const clientName = task && task.operation && task.operation.client && task.operation.client.name;

    return {
      type,
      id,
      createdAt,
      name,
      operationId,
      clientId,
      clientType,
      clientName,
    };
  });
}
