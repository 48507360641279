export default {
  GET_SMS_TASK_REQUEST: '@@tasks/GET_SMS_TASK_REQUEST',
  GET_SMS_TASK_SUCCESS: '@@tasks/GET_SMS_TASK_SUCCESS',
  GET_SMS_TASK_ERROR: '@@tasks/GET_SMS_TASK_ERROR',

  GET_EMAIL_TASK_REQUEST: '@@tasks/GET_EMAIL_TASK_REQUEST',
  GET_EMAIL_TASK_SUCCESS: '@@tasks/GET_EMAIL_TASK_SUCCESS',
  GET_EMAIL_TASK_ERROR: '@@tasks/GET_EMAIL_TASK_ERROR',

  GET_TASK_COMMENTS_REQUEST: '@@tasks/GET_TASK_COMMENTS_REQUEST',
  GET_TASK_COMMENTS_SUCCESS: '@@tasks/GET_TASK_COMMENTS_SUCCESS',
  GET_TASK_COMMENTS_ERROR: '@@tasks/GET_TASK_COMMENTS_ERROR',

  GET_MORE_TASK_COMMENTS_REQUEST: '@@tasks/GET_MORE_TASK_COMMENTS_REQUEST',
  GET_MORE_TASK_COMMENTS_SUCCESS: '@@tasks/GET_MORE_TASK_COMMENTS_SUCCESS',
  GET_MORE_TASK_COMMENTS_ERROR: '@@tasks/GET_MORE_TASK_COMMENTS_ERROR',

  ADD_TASK_COMMENT_REQUEST: '@@tasks/ADD_TASK_COMMENT_REQUEST',
  ADD_TASK_COMMENT_SUCCESS: '@@tasks/ADD_TASK_COMMENT_SUCCESS',
  ADD_TASK_COMMENT_ERROR: '@@tasks/ADD_TASK_COMMENT_ERROR',
};
