import {useParametrizedSelector} from 'client/services/hooks/useParametrizedSelector';

import {LangTypes} from 'client/ducks/language/reducer';
import {makeSelectPathLanguage} from 'client/ducks/language/selectors';

import {FieldsPaths, GetValuesByPaths} from './types';

export const useLanguage = <TFields extends FieldsPaths<LangTypes>>(
  path: TFields,
): GetValuesByPaths<LangTypes, [TFields]> => {
  return useParametrizedSelector(makeSelectPathLanguage, path);
};
