import React, {Component} from 'react';

import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {deleteMappingItems} from 'client/ducks/message-email-sms-task/actions';
import {selectMappedMessageVariablesByName} from 'client/ducks/message-email-sms-task/selectors';

import CustomTextarea from 'client/components/common/custom-textarea';

import {TranslationJsx} from 'client/models/language/types';

import '../bc-step.scss';

class BCSmsTextStep extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    messageText: TranslationJsx.isRequired,
    mappedItems: PropTypes.object.isRequired,
    deleteMappingItems: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    disabledWhilePerforming: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isMessageChanged: false,
      variables: [],
      isInfoVisible: false,
    };

    this.LANGUAGE = props.languageState.payload.BROADCASTING_TASK.SMS_TEXT_STEP;
  }

  componentDidUpdate({disabledWhilePerforming}) {
    if (!disabledWhilePerforming && this.props.disabledWhilePerforming) {
      this.resetTextarea();
    }
  }

  resetTextarea = () => {
    this.setState(
      {
        isMessageChanged: false,
      },
      this.reset,
    );
  };

  getResetMethod = ({reset}) => {
    this.reset = reset;
  };

  renderVarsList = () => {
    return this.state.variables.map((v) => {
      return <div key={v.id}>{`{{${v.name}}}`}</div>;
    });
  };

  handleTextareaChange = (text, variables) => {
    this.setState({
      text,
      variables: uniqBy(variables, 'name'),
      isMessageChanged: text !== this.props.messageText,
    });
  };

  handleInitialize = (variables) => {
    this.setState({
      variables: uniqBy(variables, 'name'),
    });
  };

  handleSave = () => {
    const body = {
      message_task: {
        message: this.state.text,
      },
    };

    const NAME = 0;
    const ID = 1;
    const {mappedItems} = this.props;
    const varsInMessage = this.state.variables.map((v) => v.name);

    const varsIds = Object.entries(mappedItems).reduce((acc, item) => {
      return varsInMessage.includes(item[NAME]) ? acc : [...acc, item[ID]];
    }, []);

    if (varsIds.length) {
      const queryParams = {
        q: {
          id_in: varsIds,
        },
      };

      return this.props.deleteMappingItems(queryParams).then(() => this.onSave(body));
    }

    return this.onSave(body);
  };

  onSave = (body) => {
    return this.props.onSave(body).then(() => {
      this.setState({
        isMessageChanged: false,
      });
    });
  };

  handleFocusToggle = () => {
    this.setState((prevState) => ({
      isInfoVisible: !prevState.isInfoVisible,
    }));
  };

  render() {
    const {messageText, disabledWhilePerforming} = this.props;
    const {isMessageChanged, isInfoVisible} = this.state;

    return (
      <div className="bc-step">
        <div className="bc-step__save-btn-container bc-step__save-btn-container--view-1">
          <button className="button button--bg-14" disabled={!isMessageChanged} onClick={this.handleSave}>
            {this.LANGUAGE.SAVE_BUTTON}
          </button>
        </div>
        <div className="bc-step__inner bc-step__inner--view-1">
          <div className="flex-container">
            <CustomTextarea
              className="bc-step__sms-text"
              variableCssClass="theme-color-16"
              initialValue={messageText}
              onInitializeDone={(html, text, variables) => this.handleInitialize(variables)}
              onChange={(html, text, variables) => this.handleTextareaChange(text, variables)}
              onBlur={this.handleFocusToggle}
              onFocus={this.handleFocusToggle}
              readOnly={disabledWhilePerforming}
              getMethods={this.getResetMethod}
            />
            <div className="bc-step__merged-vars">
              {this.LANGUAGE.MERGED_VARS_LABEL}
              <div className="theme-color-16 bold">{this.renderVarsList()}</div>
            </div>
            <div
              className="bc-step__textarea-info"
              style={{
                opacity: isInfoVisible ? 1 : 0,
              }}
            >
              <div>* {this.LANGUAGE.VARIABLES_NAMES_INFO}</div>
              <div>* {this.LANGUAGE.LINE_BREAK_INFO}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mappedItems: selectMappedMessageVariablesByName(state),
  languageState: state.languageState,
});

const mapDispatchToProps = {
  deleteMappingItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(BCSmsTextStep);
