import {createSelector} from 'reselect';

export function selectInterfaceTemplatesMeta(state) {
  return state.interfaceTemplates.meta;
}

export function selectInterfaceTemplatesTotalCount(state) {
  return selectInterfaceTemplatesMeta(state).total_count;
}

export function selectActiveTemplateItems(state) {
  return state.interfaceTemplates.activeTemplateItems;
}

export const selectInterfaceTemplatesAsOptions = createSelector(
  (state) => state.interfaceTemplates.templates,
  (templates) => templates.map((item) => ({value: item.id, label: item.name})),
);
