import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function updateDevicesExternal(id, body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DEVICES}/${id}`,
      method: 'PATCH',
      types: [
        types.UPDATE_DEVICE_EXTERNAL_REQUEST,
        types.UPDATE_DEVICE_EXTERNAL_SUCCESS,
        types.UPDATE_DEVICE_EXTERNAL_ERROR,
      ],
      headers: {
        'cache-control': 'no-cache',
      },
      body,
    });
}
