import Cookies from 'js-cookie';

import {USER_MEMBERSHIP} from 'client/common/config';

export const EXPIRATION_TIME = 1; // 24 hours
export const BUZZEO_TOKEN = 'buzzeoToken';
export const BUZZEO_REFRESH_TOKEN = 'buzzeoRefreshToken';
export const BUZZEO_EMAIL = 'buzzeoEmail';
export const BUZZEO_USER_ID = 'buzzeoUserId';
export const BUZZEO_ORG_ID = 'buzzeoOrgId';
export const BUZZEO_ORG_NAME = 'buzzeoOrgName';
export const BUZZEO_USER_ACCESS = 'buzzeoUserAccess';

const cookiesSecurityParams = {
  secure: true,
};

export function clearCookies() {
  Cookies.remove(BUZZEO_TOKEN);
  Cookies.remove(BUZZEO_EMAIL);
  Cookies.remove(BUZZEO_USER_ID);
  Cookies.remove(BUZZEO_ORG_ID);
  Cookies.remove(BUZZEO_ORG_NAME);
  Cookies.remove(BUZZEO_USER_ACCESS);
  Cookies.remove(BUZZEO_REFRESH_TOKEN);
}

// Token
export function getToken() {
  return Cookies.get(BUZZEO_TOKEN);
}

export function setToken(token, params = {}) {
  return Cookies.set(BUZZEO_TOKEN, token, {
    ...params,
    ...cookiesSecurityParams,
  });
}
export function getRefreshToken() {
  return Cookies.get(BUZZEO_REFRESH_TOKEN);
}

export function setRefreshToken(token, params = {}) {
  return Cookies.set(BUZZEO_REFRESH_TOKEN, token, {
    ...params,
    ...cookiesSecurityParams,
  });
}

// Email
export function getEmail() {
  return Cookies.get(BUZZEO_EMAIL);
}

export function setEmail(email, params = {}) {
  return Cookies.set(BUZZEO_EMAIL, email, {
    ...params,
    ...cookiesSecurityParams,
  });
}

// User
export function getUserId() {
  return Cookies.get(BUZZEO_USER_ID);
}

export function setUserId(userId, params = {}) {
  return Cookies.set(BUZZEO_USER_ID, userId, {
    ...params,
    ...cookiesSecurityParams,
  });
}

// Organization
export function setOrganizationId(orgId, params = {}) {
  if (orgId) {
    Cookies.set(BUZZEO_ORG_ID, orgId, {
      ...params,
      ...cookiesSecurityParams,
    });
  }
}

export function clearOrganizationId() {
  return Cookies.remove(BUZZEO_ORG_ID);
}

export function setOrganizationName(orgName, params = {}) {
  return Cookies.set(BUZZEO_ORG_NAME, orgName, {
    ...params,
    ...cookiesSecurityParams,
  });
}

export function getOrganizationId() {
  return Cookies.get(BUZZEO_ORG_ID);
}

export function getOrganizationName() {
  return Cookies.get(BUZZEO_ORG_NAME);
}

export function clearOrganizationName() {
  return Cookies.remove(BUZZEO_ORG_NAME);
}

// Client user access level (can be: 'full', 'regions', 'places')
export function setAccessLevel(accessLevel, params = {}) {
  return Cookies.set(BUZZEO_USER_ACCESS, accessLevel, {
    ...params,
    ...cookiesSecurityParams,
  });
}

export function getAccessLevel() {
  return Cookies.get(BUZZEO_USER_ACCESS);
}

export function clearAccessLevel() {
  return Cookies.remove(BUZZEO_USER_ACCESS);
}

// Common
export function getAll() {
  return {
    email: getEmail(),
    userId: getUserId(),
    token: getToken(),
    orgName: getOrganizationName(),
    orgId: getOrganizationId(),
    accessLevel: getAccessLevel(),
  };
}

export function removeAll() {
  Cookies.remove(BUZZEO_TOKEN);
  Cookies.remove(BUZZEO_USER_ID);
  Cookies.remove(BUZZEO_EMAIL);
  Cookies.remove(BUZZEO_ORG_ID);
  Cookies.remove(BUZZEO_ORG_NAME);
  Cookies.remove(BUZZEO_USER_ACCESS);
  Cookies.remove(BUZZEO_REFRESH_TOKEN);
}

// helpers
export const getIsNational = () => {
  return getAccessLevel() === USER_MEMBERSHIP.NATIONAL;
};

export const getIsClientAdmin = () => {
  return getAccessLevel() === USER_MEMBERSHIP.CLIENT_ADMIN;
};

export const getIsTrackingUser = () => {
  return getToken()?.startsWith('Bearer ');
};
