import React from 'react';

import Icon from 'client/components/common/icon';

import {TranslationJsx} from 'client/models/language/types';

import './participation-tab-not-available.scss';

function ParticipationTabNotAvailable({text}) {
  return (
    <div className="participation-tab-not-available">
      <Icon name="bars" className="participation-tab-not-available__icon" />
      <div className="participation-tab-not-available__text">{text}</div>
    </div>
  );
}

ParticipationTabNotAvailable.propTypes = {
  text: TranslationJsx.isRequired,
};

export default ParticipationTabNotAvailable;
