import types from './types';

const initialState = {
  availableConditionColumnAdapters: {},
  availableColumnAdapters: {},
  columnAdapters: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_AVAILABLE_COLUMN_ADAPTERS_SUCCESS:
      return {
        ...state,
        availableColumnAdapters: action.payload,
      };

    case types.GET_AVAILABLE_CONDITION_COLUMN_ADAPTERS_SUCCESS:
      return {
        ...state,
        availableConditionColumnAdapters: action.payload,
      };

    case types.GET_COLUMN_ADAPTERS_SUCCESS:
      return {
        ...state,
        columnAdapters: action.payload.column_adapters,
      };

    case types.FLUSH_COLUMN_ADAPTERS_ALL_STATE:
      return initialState;

    default:
      return state;
  }
}
