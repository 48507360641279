import React, {Component} from 'react';

import toFormData from 'object-to-formdata';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {touch, getFormValues, isValid} from 'redux-form';

import {getClientPage} from 'client/services/helpers';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getScenario, testScenario, getPossibleTestVariables} from 'client/ducks/scenario/actions';
import {selectScenarioSteps, selectPossibleTestVariables, selectScenarioData} from 'client/ducks/scenario/selectors';

import {CLIENT_PAGES, SCENARIO_STEP_TYPES} from 'client/common/config';

import Icon from 'client/components/common/icon';
import Spinner from 'client/components/common/spinner';
import TitleBlock from 'client/components/common/title-block';

import TestStepsList from './components/test-steps-list';

import './test-parameters.scss';

class TestParameters extends Component {
  static propTypes = {
    scenario_execution: PropTypes.shape({
      scenario: PropTypes.shape({
        automation_task: PropTypes.shape({
          id: PropTypes.number,
          operation: PropTypes.shape({
            client_id: PropTypes.number,
            id: PropTypes.number,
          }),
        }),
        id: PropTypes.number,
        name: PropTypes.string,
      }).isRequired,
      variable_values: PropTypes.arrayOf(PropTypes.object),
    }),
    id: PropTypes.number.isRequired,
    scenario: PropTypes.shape({
      automation_task: PropTypes.shape({
        id: PropTypes.number,
        operation: PropTypes.shape({
          client_id: PropTypes.number,
          id: PropTypes.number,
        }),
      }),
      scenario_steps: PropTypes.array,
      scenario_variables: PropTypes.array,
      id: PropTypes.number,
      name: PropTypes.string,
      submitError: PropTypes.bool,
    }).isRequired,
    getScenario: PropTypes.func.isRequired,
    testScenario: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    ...withRouter.propTypes,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    getPossibleTestVariables: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    strategiesValues: PropTypes.object,
    isValidForm: PropTypes.bool.isRequired,
    touch: PropTypes.func,
    variables: PropTypes.array,
    steps: PropTypes.array,
  };

  static defaultProps = {
    formValues: {},
    steps: [],
    variables: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      notCheckedFields: [],
      loading: false,
    };

    this.LANGUAGE = this.props.languageState.payload.TEST_PARAMETERS;

    this.list = [SCENARIO_STEP_TYPES.instantLottery, SCENARIO_STEP_TYPES.prizeDraw, SCENARIO_STEP_TYPES.timer];

    this.STRATEGIES_MAPPING = {
      [SCENARIO_STEP_TYPES.branch]: (value) => {
        const {name, condition} = value;

        switch (name) {
          case 'skip':
            return {
              name,
              params: {condition},
            };
          default:
            return {name};
        }
      },
      [SCENARIO_STEP_TYPES.instantLottery]: (value) => {
        const {name, PrizeLevel, PrizeName} = value;

        switch (name) {
          case 'win':
            return {
              name,
              params: {
                PrizeLevel,
                PrizeName,
              },
            };
          default:
            return {name};
        }
      },
      [SCENARIO_STEP_TYPES.message.email]: (value) => {
        const {name, MessageOpened, MessageSent, TimeExpired} = value;

        switch (name) {
          case 'skip':
            return {
              name,
              params: {
                MessageOpened,
                MessageSent,
                TimeExpired,
              },
            };
          default:
            return {name};
        }
      },
      [SCENARIO_STEP_TYPES.message.sms]: (value) => {
        const {name, MessageDelivered, MessageSent, TimeExpired} = value;

        switch (name) {
          case 'skip':
            return {
              name,
              params: {
                MessageDelivered,
                MessageSent,
                TimeExpired,
              },
            };
          default:
            return {name};
        }
      },
      [SCENARIO_STEP_TYPES.prizeDraw]: (value) => {
        const {name, PrizeLevel, PrizeName} = value;

        switch (name) {
          case 'win':
            return {
              name,
              params: {
                PrizeLevel,
                PrizeName,
              },
            };
          default:
            return {name};
        }
      },
      [SCENARIO_STEP_TYPES.social.facebook]: (value) => value,
      [SCENARIO_STEP_TYPES.social.twitter]: (value) => value,
      [SCENARIO_STEP_TYPES.timer]: (value) => {
        const {name, seconds} = value;

        switch (name) {
          case 'wait':
            return {
              name,
              params: {seconds},
            };
          default:
            return {name};
        }
      },
    };

    this.STRATEGIES_VALIDATION_MAPPING = {
      [SCENARIO_STEP_TYPES.instantLottery]: (value = {}, id) => {
        const {name = '', PrizeLevel = '', PrizeName = ''} = value;

        switch (name) {
          case 'win':
            let valid = true;
            const fields = [];

            if (PrizeLevel === '') {
              valid = false;
              fields.push(`_${id}.PrizeLevel`);
            }

            if (PrizeName === '') {
              valid = false;
              fields.push(`_${id}.PrizeName`);
            }

            return {valid, fields};
          default:
            return {valid: true};
        }
      },
      [SCENARIO_STEP_TYPES.prizeDraw]: (value = {}, id) => {
        const {name = '', PrizeLevel = '', PrizeName = ''} = value;

        switch (name) {
          case 'win':
            let valid = true;
            const fields = [];

            if (PrizeLevel === '') {
              valid = false;
              fields.push(`_${id}.PrizeLevel`);
            }

            if (PrizeName === '') {
              valid = false;
              fields.push(`_${id}.PrizeName`);
            }

            return {valid, fields};
          default:
            return {valid: true};
        }
      },
      [SCENARIO_STEP_TYPES.timer]: (value = {}, id) => {
        const {name, _seconds = ''} = value;

        switch (name) {
          case 'wait':
            return _seconds === '' ? {valid: false, fields: [`_${id}._seconds`]} : {valid: true};
          default:
            return {valid: true};
        }
      },
    };
  }

  componentDidMount() {
    this.toggleLoading();
    this.props.getPossibleTestVariables(this.props.id);
    this.props
      .getScenario(this.props.id, {
        include: [
          'scenario_variables',
          {
            scenario_steps: {
              implementation: [
                {email_scenario_step_mapping_items: ['email_template_variable', 'variable']},
                'recipient_variable',
                'message_sender',
                'lottery_scenario_step_prizes',
                'social_account',
                {social_scenario_step_images: 'variable'},
                'scenario',
                {branch_scenario_step_conditions: ['variable', 'value_variable']},
                'email_template',
              ],
            },
            automation_task: [
              {
                interfaces: ['interface_items'],
              },
              {
                operation: {
                  client: 'agency',
                },
              },
            ],
            trigger_mapping_items: {
              interface_item: ['interface'],
            },
            primary_scenarios: ['scenario_variables'],
          },
        ],
      })
      .then((scenarioData) => {
        if (!scenarioData?.error) {
          const {
            payload: {
              scenario: {
                id: scenarioId,
                name: scenarioName,
                automation_task: {
                  operation: {client, ...operation},
                  ...automation_task
                },
              },
            },
          } = scenarioData;

          const scenarioHref = `${CLIENT_PAGES.SCENARIO}/${scenarioId}`;

          const clientHref = `${getClientPage(client)}/${client.id}`;
          const agencyBreadcrumb = client.agency
            ? [
                {
                  href: `${getClientPage(client.agency)}/${client.agency.id}`,
                  name: client.agency.name,
                },
              ]
            : [];

          const operationHref = `${clientHref}${CLIENT_PAGES.OPERATIONS}/${operation.id}`;
          const customOpDBHref = `${operationHref}${CLIENT_PAGES.CUSTOM_OPERATION_DATABASE}`;

          const autotaskHref = `${CLIENT_PAGES.AUTOTASK}/${automation_task.id}`;

          const {BREADCRUMBS} = this.props.languageState.payload;

          this.props.setBreadcrumbs([
            {
              href: CLIENT_PAGES.VISUALS,
              name: BREADCRUMBS.VISUALS,
            },
            ...agencyBreadcrumb,
            {
              name: client.name,
              href: clientHref,
            },
            {
              name: BREADCRUMBS.CUSTOM_OPERATION,
              href: customOpDBHref,
            },
            {
              name: operation.name,
              href: operationHref,
            },
            {
              name: automation_task.name,
              href: autotaskHref,
            },
            {
              name: scenarioName,
              href: scenarioHref,
            },
            {
              name: BREADCRUMBS.TEST_PARAMETERS,
            },
          ]);
          this.toggleLoading();
        } else {
          this.props.history.push(CLIENT_PAGES.ERROR_404_URL);
        }
      });
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  toggleLoading = () => {
    this.setState(({loading}) => ({loading: !loading}));
  };

  renderTestResults = () => {
    return (
      <div>
        <div className="test-parameters__message">
          <Icon name="status-error-2" className="test-parameters__message-icon" />
          <span>{this.LANGUAGE.TEST_NOT_OK_TEXT}</span>
        </div>
        <div className="test-parameters__message">
          <Icon name="status-success" className="test-parameters__message-icon" />
          <span>{this.LANGUAGE.TEST_OK_TEXT}</span>
        </div>
      </div>
    );
  };

  checkRequiredFields = (formData, variables, kinds = []) => {
    let keys = [];

    // all kinds of required fields or exact one
    const kindCondition = (item, kindsArr) => {
      return kindsArr ? kindsArr.indexOf(item.kind) !== -1 && item.required : item.required;
    };

    if (formData) {
      keys = Object.keys(formData);
      keys = keys.map((key) => {
        return +key.slice(1);
      });
    }

    return variables
      .filter((v) => kindCondition(v, kinds))
      .map((v) => v.id)
      .filter((v) => {
        return keys.indexOf(v) === -1;
      });
  };

  getScenarioStepsList = (steps) => {
    const list = {};
    steps.forEach((step) => (list[step.implementation.type] = step.id));
    return list;
  };

  getStrategiesValues = (stepsList, formValues) => {
    const values = {};

    Object.keys(stepsList).forEach((key) => {
      const id = stepsList[key];
      const valueGetter = this.STRATEGIES_MAPPING[key];
      values[id] = valueGetter(formValues[`_${id}`]);
    });

    return values;
  };

  mapStepsFormData = (steps, values) => {
    const stepsList = this.getScenarioStepsList(steps);
    return this.getStrategiesValues(stepsList, values);
  };

  validateStrategiesValues = (fieldsList, steps, values) => {
    const stepsList = this.getScenarioStepsList(steps);
    const checkResult = {};
    fieldsList.forEach((fieldName) => {
      const validator = this.STRATEGIES_VALIDATION_MAPPING[fieldName];
      const id = stepsList[fieldName];
      const fieldValue = values[`_${id}`];
      checkResult[fieldName] = validator(fieldValue, id);
    });

    return Object.keys(checkResult).reduce(
      (res, el) => (!checkResult[el].valid ? [...res, ...checkResult[el].fields] : res),
      [],
    );
  };

  handleSubmitClick = () => {
    const {scenario, isValidForm, formValues, strategiesValues} = this.props;
    const clientId = scenario.automation_task.operation.client_id;
    const operationId = scenario.automation_task.operation.id;
    const autotaskId = scenario.automation_task.id;

    const notCheckedFields = this.checkRequiredFields(formValues, scenario.scenario_variables, ['boolean', 'file']);

    const invalidStrategiesFields = this.validateStrategiesValues(this.list, scenario.scenario_steps, strategiesValues);

    if (invalidStrategiesFields.length > 0) {
      this.props.touch('TestScenarioStepsForms', ...invalidStrategiesFields);
    }

    this.setState({
      notCheckedFields: [...notCheckedFields],
    });

    if (!!notCheckedFields.length || !isValidForm || invalidStrategiesFields.length > 0) {
      return;
    }

    const testFormData = this.mapParamsFormData(formValues);
    const testStrategiesData = this.mapStepsFormData(scenario.scenario_steps, strategiesValues);

    this.props
      .testScenario(
        this.props.id,
        toFormData({
          scenario: {
            test_data: testFormData,
            scenario_step_strategies: testStrategiesData,
          },
        }),
      )
      .then(() => {
        if (this.props.scenario.submitError) {
          return;
        }

        this.props.history.push(
          `${CLIENT_PAGES.AGENCIES}/${clientId}${CLIENT_PAGES.OPERATIONS}/${operationId}${CLIENT_PAGES.AUTOTASK}` +
            `/${autotaskId}${CLIENT_PAGES.SCENARIO_RUN_TABLE}?sort={"name":"created_at","order":"desc"}`,
        );
      });
  };

  mapParamsFormData(data) {
    const keys = Object.keys(data);
    let formData = {};

    keys.forEach((key) => {
      let newKey = key.slice(1);
      formData[newKey] = data[key] instanceof Array ? data[key][0] : data[key];
      formData[newKey] = data[key]._isAMomentObject ? data[key].toISOString() : data[key];
    });

    return formData;
  }

  renderButtons = (status) => {
    const {notCheckedFields} = this.state;

    const {isValidForm} = this.props;

    return (
      <div className="test-parameters__message">
        {status === 'running' ? (
          <div>
            <span>{this.LANGUAGE.TEST_IN_PROGRESS_TEXT}</span>
            <button className="button button--bg-13 test-parameters__btn">
              <span>{this.LANGUAGE.STOP_BUTTON}</span>
            </button>
          </div>
        ) : (
          <button
            type="button"
            className="button button--bg-13 test-parameters__btn"
            disabled={!!notCheckedFields.length || !isValidForm}
            onClick={this.handleSubmitClick}
          >
            <Icon name="hammer" className="button__icon" />
            <span>{this.LANGUAGE.TEST_BUTTON}</span>
          </button>
        )}
      </div>
    );
  };

  render() {
    const {formValues, strategiesValues, variables, scenario} = this.props;

    const {notCheckedFields, loading} = this.state;

    if (loading) {
      return <Spinner centered />;
    }

    if (!scenario.id) {
      return null;
    }

    return (
      <div className="theme-color-7">
        <div className="flex-container flex-justify-between">
          <div className="page__title-block">
            <TitleBlock theme={true}>
              <TitleBlock.Item>{this.LANGUAGE.TITLE}</TitleBlock.Item>
            </TitleBlock>
          </div>
          {this.renderButtons('pending')}
        </div>
        {/* {this.renderTestResults()} */}
        <TestStepsList
          languageSteps={this.LANGUAGE.STEPS}
          languageForms={this.LANGUAGE.FORMS}
          languageFormsErrors={this.LANGUAGE.FORMS_ERRORS}
          languageNav={this.LANGUAGE.NAV}
          stepParamsTitle={this.LANGUAGE.STEP_PARAMS_TITLE}
          varsStepTitle={this.LANGUAGE.VARS_STEP_TITLE}
          stepTitle={this.LANGUAGE.STEP_TITLE}
          formValues={formValues}
          steps={this.props.steps}
          strategiesValues={strategiesValues}
          variables={variables}
          notCheckedFields={notCheckedFields}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  scenario: selectScenarioData(state),
  steps: selectScenarioSteps(state),
  formValues: getFormValues('TestParametersForm')(state),
  strategiesValues: getFormValues('TestScenarioStepsForms')(state),
  isValidForm: isValid('TestParametersForm')(state),
  variables: selectPossibleTestVariables(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getScenario,
      testScenario,
      setBreadcrumbs,
      clearBreadcrumbs,
      getPossibleTestVariables,
      touch,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TestParameters));
