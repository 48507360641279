import React from 'react';

import PropTypes from 'prop-types';

import {getAnimationValidationStatus} from 'client/components/instore/helpers';

import AnimationIconCell from '../animation-icon-cell';

const AnimationHrCell = (props) => {
  const {className, item} = props;

  return (
    <AnimationIconCell
      className={className}
      item={item}
      field="hr_status"
      iconPrefix="hr"
      iconNameGetter={getAnimationValidationStatus}
      testId="animation-hr-cell"
    />
  );
};

AnimationHrCell.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    hr_status: PropTypes.string,
    animation_days: PropTypes.arrayOf(
      PropTypes.shape({
        hr_status: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

AnimationHrCell.defaultProps = {
  className: null,
};

export default AnimationHrCell;
