export const VISUAL_KINDS = {
  IMAGE: 'image',
  VIDEO: 'video',
  TEXT: 'text',
};

export const VISUAL_ORIENTATION = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
  SQUARE: 'square',
};
