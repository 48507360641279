import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

import Icon from '../icon';

import './filter-label.scss';

function FilterLabel({text, onDelete, className, theme, name, title}) {
  const cssClass = classNames({
    'filter-label': true,
    'filter-label--theme-color': theme,
  });

  return (
    <div className={`${cssClass} ${className}`}>
      <div className="filter-label__text" title={title}>
        {decodeURIComponent(text)}
      </div>
      <button data-filter-name={name} className="filter-label__btn" onClick={onDelete}>
        <Icon name="clear" className="filter-label__btn-icon" />
      </button>
    </div>
  );
}

FilterLabel.propTypes = {
  text: PropTypes.any,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  title: TranslationJsx,
  theme: PropTypes.bool,
  name: PropTypes.string,
};

FilterLabel.defaultProps = {
  text: '',
  onDelete: () => {},
  className: '',
  theme: false,
  name: '',
};

export default FilterLabel;
