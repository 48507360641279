import React, {Component} from 'react';

import cn from 'classnames';
import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {SOCIAL_ACCOUNT_TYPES} from 'client/common/config';

import Icon from 'client/components/common/icon';

function NameCell({data, rowIndex, field, onClick}) {
  return (
    <Cell>
      <div className="ellipsis-text display-block link" onClick={() => onClick(data[rowIndex])}>
        {data[rowIndex][field]}
      </div>
    </Cell>
  );
}

NameCell.propTypes = {
  data: PropTypes.array.isRequired,
  rowIndex: PropTypes.number,
  field: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function SubsidiaryCell({data, rowIndex}) {
  return (
    <Cell>
      <div className="ellipsis-text">{data[rowIndex].subsidiary.name}</div>
    </Cell>
  );
}

SubsidiaryCell.propTypes = {
  data: PropTypes.array.isRequired,
  rowIndex: PropTypes.number,
  field: PropTypes.string.isRequired,
};

function TypeCell({data, rowIndex}) {
  const field = data[rowIndex];

  return (
    <Cell>
      {field.client_id && field.created_by_client && <div className="client-marker">C</div>}
      <Icon
        name={field.type === SOCIAL_ACCOUNT_TYPES.FACEBOOK ? 'facebook-colored' : 'twitter'}
        className="pull-right"
      />
    </Cell>
  );
}

TypeCell.propTypes = {
  data: PropTypes.array.isRequired,
  rowIndex: PropTypes.number,
  field: PropTypes.string.isRequired,
};

class ParamsSocialsTable extends Component {
  static propTypes = {
    data: PropTypes.array,
    languageState: PropTypes.object.isRequired,
    onAccountClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.PARAMETERS;
  }

  render() {
    const {data, onAccountClick} = this.props;

    return (
      <div
        className={cn(
          'fixed-table',
          'fixed-table--params-card',
          'fixed-table--params-card--with-subsidiary',
          'fixed-table--expand-1',
        )}
      >
        <Table rowsCount={data.length} headerHeight={40} rowHeight={30} height={450} width={550} overflowX="hidden">
          <Column
            header={<Cell>{this.LANGUAGE.ACCOUNT_NAME_COLUMN}</Cell>}
            cell={<NameCell data={data} field="name" onClick={onAccountClick} />}
            width={300}
          />
          <Column
            header={<Cell>{this.LANGUAGE.SUBSIDIARY_COLUMN}</Cell>}
            cell={<SubsidiaryCell data={data} field="subsidiary" />}
            width={150}
          />
          <Column
            header={<Cell>{this.LANGUAGE.TYPE_COLUMN}</Cell>}
            cell={<TypeCell data={data} field="type" />}
            width={100}
          />
        </Table>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(ParamsSocialsTable);
