import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import {TranslationJsx} from 'client/models/language/types';

import Icon from '../icon';

function Datepicker(props) {
  const {value, name, cssModifier, error, label, placeholder, disabled, disabledDayBefore, disabledDayAfter, onChange} =
    props;

  const disabledDays = {};

  if (disabledDayBefore) {
    disabledDays.before = disabledDayBefore;
  }
  if (disabledDayAfter) {
    disabledDays.after = disabledDayAfter;
  }

  const dayPickerProps = {
    disabledDays,
  };

  const cssClass = classNames({
    datepicker: true,
    'datepicker--error': !!error,
  });

  return (
    <div className={`${cssClass} ${cssModifier}`}>
      <label className="datepicker__inner">
        {label && <div className="datepicker__label">{label}</div>}
        <div className="datepicker__field-wrap">
          <DayPickerInput
            value={value}
            name={name}
            className="datepicker__field"
            disabled={disabled}
            placeholder={placeholder}
            format="DD/MM/YYYY"
            dayPickerProps={dayPickerProps}
            onDayChange={(day) => onChange(day && day.format('DD/MM/YYYY'))}
          />
          <div className="datepicker__field-icon-wrap">
            <Icon name="calendar" className="datepicker__field-icon" />
          </div>
        </div>
      </label>
      {error && <div className="datepicker__message">{error}</div>}
    </div>
  );
}

Datepicker.propTypes = {
  value: PropTypes.string,
  cssModifier: PropTypes.string,
  error: TranslationJsx,
  label: PropTypes.any,
  placeholder: TranslationJsx,
  disabled: PropTypes.bool,
  disabledDayBefore: PropTypes.string,
  disabledDayAfter: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

Datepicker.defaultProps = {
  value: '',
  cssModifier: '',
  error: '',
  label: null,
  placeholder: '',
  disabled: false,
  disabledDayBefore: '',
  disabledDayAfter: '',
  onChange: () => {},
  name: '',
};

export default Datepicker;
