import {LeadOptinValues} from './types';

const mapTexts = (values: LeadOptinValues) => {
  const result: Record<string, string> = {};

  const defaultInText = values.optin_texts.fr || values.optin_texts.en;
  const defaultOutText = values.optout_texts.fr || values.optout_texts.en;

  values.initial_languages.forEach((key) => {
    result[`opt_in_text_${key}`] = values.optin_texts[key] || '';
    result[`opt_out_text_${key}`] = values.optout_texts[key] || '';
  });

  values.languages.forEach((key) => {
    result[`opt_in_text_${key}`] = values.optin_texts[key] || defaultInText;
    result[`opt_out_text_${key}`] = values.optout_texts[key] || defaultOutText;
  });

  return result;
};

export default (values: LeadOptinValues, clientId?: number) => {
  return {
    opt_in_column: {
      client_id: clientId,
      level: values.level,
      code: values.code,
      opt_in_type: values.opt_in_type,
      mandatory_order: values.mandatory_order,
      name: values.name,
      comment: values.comment,
      editable: values.editable,
      ...mapTexts(values),
    },
  };
};
