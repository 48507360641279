import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

function getPermittedColumnAdapters(queryParams = {}, forConditions = false) {
  const commonTypes = [
    types.GET_AVAILABLE_COLUMN_ADAPTERS_REQUEST,
    types.GET_AVAILABLE_COLUMN_ADAPTERS_SUCCESS,
    types.GET_AVAILABLE_COLUMN_ADAPTERS_ERROR,
  ];

  const conditionsTypes = [
    types.GET_AVAILABLE_CONDITION_COLUMN_ADAPTERS_REQUEST,
    types.GET_AVAILABLE_CONDITION_COLUMN_ADAPTERS_SUCCESS,
    types.GET_AVAILABLE_CONDITION_COLUMN_ADAPTERS_ERROR,
  ];

  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.COLUMN_ADAPTER_PERMITTED}`,
      method: 'GET',
      noCache: true,
      types: forConditions ? conditionsTypes : commonTypes,
      queryParams,
    });
}

/**
 * Fetches column adapters available to use in conditions, interfaces etc.
 * @param {Number} operationId Operation ID
 * @param {String} scope Your purpose. Scopes map (PERMITTED_COLUMN_ADAPTERS_SCOPES) is available in config.js
 * @returns {Promise} Available column adapters
 */
export function getAvailableColumnAdaptersByOperation(operationId, scope = null) {
  return getPermittedColumnAdapters({
    operation_id: operationId,
    scope,
  });
}

/**
 * Fetches column adapters available to use in conditions, interfaces etc.
 * @param {Number} clientId Client ID
 * @param {String} scope Your purpose. Scopes map (PERMITTED_COLUMN_ADAPTERS_SCOPES) is available in config.js
 * @param {Boolean} forConditions Shows whether action is calling for fetching conditions adapters or not
 * @returns {Promise} Available column adapters
 */
export function getAvailableColumnAdaptersByClient(clientId, scope = null, forConditions = false) {
  return getPermittedColumnAdapters(
    {
      client_id: clientId,
      scope,
    },
    forConditions,
  );
}

/**
 *
 * @param {number|string} taskId
 * @param {string|null} scope
 * @returns {function(*): Promise<RSAAResultAction<unknown>>}
 */
export function getAvailableColumnAdaptersByAutomationTask(taskId, scope = null) {
  return getPermittedColumnAdapters({
    automation_task_id: taskId,
    scope,
  });
}

export function getColumnAdapters(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.COLUMN_ADAPTER}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_COLUMN_ADAPTERS_REQUEST, types.GET_COLUMN_ADAPTERS_SUCCESS, types.GET_COLUMN_ADAPTERS_ERROR],
      queryParams,
    });
}

export function flushColumnAdaptersAllState() {
  return {
    type: types.FLUSH_COLUMN_ADAPTERS_ALL_STATE,
  };
}
