import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getAutotask} from 'client/ducks/autotask/actions';
import {clearAutotaskState} from 'client/ducks/autotask/actions';
import {selectAutotask, selectAutotaskPrizeNames} from 'client/ducks/autotask/selectors';
import {getOperation} from 'client/ducks/operations/actions';
import {selectOperation} from 'client/ducks/operations/selectors';
import {getSourcesStatisticsById} from 'client/ducks/participations-statistics/actions';
import {selectClientAccessLevel} from 'client/ducks/user-clients/selectors';

import {CLIENT_PAGES} from 'client/common/config';

import ClientAutotaskSubheader from 'client/components/client-autotask/client-autotask-subheader';

import CampaignTab from './tabs/campaigns';
import ConfigurationTab from './tabs/configuration-tab';
import DataTabContainer from './tabs/data-tab/data-common';
import {DATA_COMPONENTS} from './tabs/data-tab/data-common/constants';
import ParticipationsTab from './tabs/participations-tab';
import PlansTab from './tabs/plans-tab';
import ResultsTab from './tabs/results-tab';
import VisualsTab from './tabs/visuals-tab';
import WinningsTab from './tabs/winnings-tab';

import './client-autotask.scss';

class ClientAutotask extends Component {
  static propTypes = {
    autotaskId: PropTypes.number.isRequired,
    operationId: PropTypes.number.isRequired,
    autotask: PropTypes.shape({
      id: PropTypes.number,
      interaction_groups: PropTypes.array,
    }).isRequired,
    getAutotask: PropTypes.func.isRequired,
    operation: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    getOperation: PropTypes.func.isRequired,
    accessLevel: PropTypes.string.isRequired,
    activeTab: PropTypes.string,
    prizeNames: PropTypes.arrayOf(
      PropTypes.shape({
        custom_name: PropTypes.string.isRequired,
        prize_type_number: PropTypes.number,
      }),
    ),
    getSourcesStatisticsById: PropTypes.func.isRequired,
    clearAutotaskState: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeTab: CLIENT_PAGES.CLIENT_AUTOTASK_TABS.PARTICIPATIONS,
    operationLanguages: [],
    prizeNames: [],
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.autotaskId !== prevProps.autotaskId) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.props.clearAutotaskState();
  }

  fetchAutotask = () => {
    return this.props.getAutotask(this.props.autotaskId, {
      include: {
        custom_prize_type_names: null,
        interfaces: null,
        interaction_groups: null,
        client_games: {
          game_draws: null,
        },
      },
      include_automation_task_client_visuals_count: null,
      include_automation_task_visible_diagrams_count: null,
      include_automation_task_prize_maps_present: null,
      include_automation_task_started_interactions_count: null,
    });
  };

  fetchOperation = (isConfTab = false) => {
    let queryParams = {};

    const params = {
      include_operation_editable: true,
      include_operation_show_configuration: true,
      include_operation_prize_maps_modified: true,
    };

    queryParams = params;

    if (isConfTab) {
      queryParams = {
        ...params,
        include_operation_online_interactions_count: true,
        include_operation_with_real_participations: true,
        include_catalog_documentation_file: true,
        include_template_form_accesses_editable: true,

        include: {
          client_interface: {
            web_app: null,
            interactions: null,
            templates: {
              catalog: {
                forms: null,
              },
            },
          },
        },
      };
    }

    return this.props.getOperation(this.props.operationId, queryParams);
  };

  fetchSourcesStatistic = () => {
    return this.props.getSourcesStatisticsById({automation_task_id: this.props.autotaskId});
  };

  fetchData = () => {
    this.fetchAutotask();
    this.fetchOperation();
    this.fetchSourcesStatistic();
  };

  renderTab = () => {
    const {autotask, operation, accessLevel, prizeNames} = this.props;
    const tabs = CLIENT_PAGES.CLIENT_AUTOTASK_TABS;

    switch (this.props.activeTab) {
      case tabs.PARTICIPATIONS:
        return <ParticipationsTab autotask={autotask} />;
      case tabs.CAMPAIGNS:
        return <CampaignTab autotask={autotask} />;
      case tabs.PLANS:
        return <PlansTab task={autotask} />;
      case tabs.RESULTS_KPIS:
        return <ResultsTab task={autotask} />;
      case tabs.RESULTS_WINNINGS:
        return <WinningsTab task={autotask} operation={operation} />;
      case tabs.DATA_PARTICIPATIONS:
        return (
          <DataTabContainer
            autotaskId={autotask.id}
            component={DATA_COMPONENTS.PARTICIPATIONS}
            showReportsButtons={true}
            canGenerateFile
          />
        );
      case tabs.DATA_LEADS:
        return (
          <DataTabContainer
            autotaskId={autotask.id}
            component={DATA_COMPONENTS.LEADS}
            showReportsButtons={true}
            canGenerateFile
          />
        );
      case tabs.DATA_WINNERS:
        return (
          <DataTabContainer
            autotaskId={autotask.id}
            component={DATA_COMPONENTS.WINNERS}
            prizeNames={prizeNames}
            showReportsButtons={true}
            canGenerateFile
          />
        );
      case tabs.VISUALS:
        return <VisualsTab task={autotask} accessLevel={accessLevel} />;
      case tabs.CONFIGURATION:
        return (
          <ConfigurationTab
            operation={operation}
            autotask={autotask}
            fetchOperation={this.fetchOperation}
            fetchAutotask={this.fetchAutotask}
            interactionGroups={autotask?.interaction_groups}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const {autotask, operation, activeTab, accessLevel} = this.props;

    if (!autotask.id || !operation.id) {
      return null;
    }

    return (
      <div className="client-autotask">
        <ClientAutotaskSubheader
          autotask={autotask}
          operation={operation}
          activeTab={activeTab}
          accessLevel={accessLevel}
        />
        <div className="client-autotask__content">{this.renderTab()}</div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    languageState: state.languageState,
    autotask: selectAutotask(state),
    operation: selectOperation(state),
    prizeNames: selectAutotaskPrizeNames(state),
    accessLevel: selectClientAccessLevel(state),
  }),
  {
    getAutotask,
    getOperation,
    getSourcesStatisticsById,
    clearAutotaskState,
  },
)(ClientAutotask);
