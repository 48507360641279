import {blobToBase64} from 'client/services/blobToBase64';
import {convertUrlToFile} from 'client/services/convertUrlToFile';

export default async (values) => {
  let body = {};

  await Promise.all(
    Object.entries(values || {}).map(async ([key, value]) => {
      const keyContainsId = key.split('_').includes('id');

      if (value && !keyContainsId) {
        const newValue = typeof value === 'string' ? await convertUrlToFile(value) : value;
        const toSave = {data: await blobToBase64(newValue)};

        body = {
          ...body,
          [key]: toSave,
        };
      }
    }),
  );

  return body;
};
