import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';

import LeadsLabel from '../leads-label';

import './leads-custom-info.scss';

class LeadsCustomInfo extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
  };

  render() {
    const {data, lang} = this.props;
    const customColumns = Object.entries(data).map((value) => ({
      label: value[0],
      value: value[1],
    }));

    return (
      <div className="leads-custom-info">
        <h3 className="leads-custom-info__title">{lang.TITLE}</h3>
        <CustomScrollbars
          scrollbarProps={{
            autoHeightMax: 210,
          }}
        >
          {customColumns.map((item, index) => {
            return <LeadsLabel key={index} label={item.label} value={item.value} />;
          })}

          {!customColumns.length && (
            <div className="client-table__no-data">
              <Icon name="table-no-data" className="client-table__icon" />
              <p className="client-table__no-data-text">{lang.NO_DATA}</p>
            </div>
          )}
        </CustomScrollbars>
      </div>
    );
  }
}

export default connect((state) => ({
  lang: state.languageState.payload.DATA_LEAD.MODAL.LEADS_CUSTOM_INFO,
}))(LeadsCustomInfo);
