import React from 'react';

import find from 'lodash/find';
import pull from 'lodash/pull';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import bem from 'client/services/bem';
import {deleteMethod, patch} from 'client/services/fetch';
import {isEmail, transformDate} from 'client/services/helpers';

import {API_METHODS} from 'client/common/config';
import Icon from 'client/common/icon';
import {CheckboxInput} from 'client/common/inputs';
import SearchInput from 'client/common/inputs/search-input';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import FilterLabel from 'client/components/common/filter-label';

import createCustomEditor, {CustomEditCell} from './custom-edit-cell';

import FiltersModal from '../../modals/custom-operation-filters-modal';
import CustomOperationTableSettingsModal from '../../modals/custom-operation-table-settings-modal';

import cssModule from './custom-operation-data-grid.module.scss';

const b = bem('custom-operation-data-grid', {cssModule});

class CustomOperationDataGrid extends ReactQueryParams {
  constructor(props) {
    super(props);

    this.state = {
      checked: [],
      showFilterModal: false,
      showTableSettingsModal: false,
      showDeleteItemsModal: false,

      data: [],
      columns: [],
      filterColumns: [],
    };

    this.LANGUAGE = props.languageState.payload.CUSTOM_OPERATION_DATABASE;
    this.SYSTEM_COLUMNS = props.languageState.payload.CUSTOM_OPERATION_DATABASE.TABLE_SETTINGS_MODAL.SYSTEM_COLUMNS;
    this.formatTypes = Object.keys(props.languageState.payload.CUSTOM_FORMATS);
    this.formats = props.languageState.payload.CUSTOM_FORMATS;
  }

  componentDidUpdate(prevProps) {
    const {data, visibleColumns, filterColumns} = this.props;

    if (data !== prevProps.data) {
      this.setState({
        data: CustomOperationDataGrid.mapData(this.props.data),
      });
    }

    if (visibleColumns !== prevProps.visibleColumns || filterColumns !== prevProps.filterColumns) {
      this.updateColumns(visibleColumns, filterColumns);
    }
  }

  updateColumns = (visibleColumns, filterColumns) => {
    this.setState({
      columns: sortBy(visibleColumns, 'position')
        .map((i) => i.column_adapter)
        .map((i) => {
          if (i.kind === 'integer') {
            i.kind = 'decimal';
          }
          return i;
        }),
      filterColumns,
    });
  };

  static mapData(dataObj) {
    return dataObj.map(({data, ...o}) => ({...o, ...data}));
  }

  onCheckAll = (isChecked) => {
    this.setState((prev) => ({
      checked: isChecked
        ? [...prev.checked, ...this.state.data]
        : prev.checked.filter((row) => !this.state.data.find(({id}) => id !== row.id)),
    }));
  };

  onCheck = (isChecked, row) => {
    this.setState((prev) => ({
      checked: isChecked ? [...prev.checked, row] : prev.checked.filter((item) => item.id !== row.id),
    }));
  };

  onPageChange = (params) => {
    this.props.onPageChange(params);
  };

  onSortChange = ({sortField: name, sortOrder: order}) => {
    this.setState({checked: []});
    this.props.onSortChange(name, order);
  };

  onSearchChange = (search) => {
    this.setState({checked: []});
    this.props.onSearchChange(search);
  };

  onSearchClear = () => {
    this.setState({checked: []});
    this.props.onSearchClear();
  };

  renderToolbar = () => {
    const checkedCount = this.state.checked.filter((i) => i).length;
    return (
      <>
        <div className={b('toolbar', ['left'])}>
          <div className={b('filter')}>
            <div className={b('filter-row')}>
              <SearchInput
                placeholder={this.LANGUAGE.SEARCH_PLACEHOLDER}
                onSearch={this.onSearchChange}
                searchDefault={this.props.search}
              />
              <button onClick={() => this.setState({showFilterModal: true})} className="button button--bg-4">
                <Icon name="filter" className="button__icon" />
                <span>
                  {`${this.LANGUAGE.OPEN_FILTER_BUTTON}
                  (${this.queryParams.filterColumns?.length})`}
                </span>
              </button>
            </div>
            <div>
              <span className="main-text">{`${this.props.meta.total_count || 0} ${this.LANGUAGE.NUM_OF_RESULTS}`}</span>
            </div>
            <div className={b('filter-labels')}>
              {this.state.filterColumns?.map((column, i) => (
                <FilterLabel key={i} text={this.formats[column]} onDelete={() => this.removeFromFilter(column)} />
              ))}
              {this.state.filterColumns?.length > 0 && (
                <button onClick={this.clearFilter} className="button button--bg-4 ">
                  {this.LANGUAGE.CLEAR_FILTER_BUTTON}
                </button>
              )}
            </div>
          </div>
        </div>

        <div className={b('toolbar', ['right'])}>
          {checkedCount > 0 && (
            <button
              className="button button--bg-4 margin-right-10"
              onClick={() => this.setState({showDeleteItemsModal: true})}
            >
              <Icon name="trash" className="button__icon" width={17} height={19} />
              <span>{`${this.LANGUAGE.DELETE_BUTTON} (${checkedCount})`}</span>
            </button>
          )}
          <button className="button button--bg-4" onClick={() => this.setState({showTableSettingsModal: true})}>
            <Icon name="settings" className="button__icon" />
            <span>{this.LANGUAGE.TABLE_SETTINGS_BUTTON}</span>
          </button>

          <PerPageDropdown
            value={this.props.perPage?.toString()}
            simpleValue
            onChange={(value) => this.onPageChange({page: 1, perPage: +value})}
          />
        </div>
      </>
    );
  };

  renderBoolean = ({value, item}) => <CheckboxInput name={item.id} checked={value} color="games" />;

  renderFile = ({value: file = {}}) => {
    const url = file?.url || file?.record?.file || file;
    const filename = file?.file_identifier || file?.record?.file || 'URL';

    if (!url) {
      return null;
    }

    const link = (
      <a href={file?.url || file} className="link ellipsis-text" target="blank">
        {filename}
      </a>
    );

    if (/\.(jpe?g|png|bmp|gif|ico)$/.test(url)) {
      return <div style={{backgroundImage: 'url(' + url + ')'}} title={filename} />;
    }
    if (/\.pdf$/.test(url)) {
      return (
        <div className={b('file')}>
          <Icon name="file-pdf" /> {link}
        </div>
      );
    }
    if (/\.docx?$/.test(url)) {
      return (
        <div className={b('file')}>
          <Icon name="file-doc" /> {link}
        </div>
      );
    }

    return (
      <div className={b('file')}>
        <Icon name="file" /> {link}
      </div>
    );
  };

  renderText =
    (isNumber = false) =>
    ({value: text}) => (
      <div className="ellipsis-text height--21">
        {text !== '' && text !== null && isNumber && !isNaN(+text) ? +text : text}
      </div>
    );

  renderDatetime = ({value}) => (
    <div className="ellipsis-text">
      {transformDate(value, true, true)
        .split(' ')
        .map((i, k) => (
          <div key={k}>{i}</div>
        ))}
    </div>
  );

  switchArrayRenderer = ({array: a}, renderer) =>
    !a
      ? renderer
      : ({value: array}) => (
          <CustomScrollbars
            scrollbarProps={{
              hideTracksWhenNotNeeded: true,
              autoHeightMax: 200,
              autoHeightMin: 50,
            }}
          >
            {array && array.map((v, k) => renderer({value: v, item: k}))}
          </CustomScrollbars>
        );

  renderEditableCell =
    (type) =>
    ({value, item, column}) => {
      const props = {
        name: item.id,
        defaultValue: Array.isArray(value) ? value.join('\n') : value,
        hoveringMode: true,
        onBlur: (e) => this.onUpdateCell(item, column.path, e.target.value),
      };

      return (
        <CustomEditCell
          type={type}
          {...props}
          onUpdate={(nextValue) => this.onUpdateCell(item, column.path, nextValue)}
        />
      );
    };

  selectRenderer = (column) => {
    const editConfig = CustomOperationDataGrid.getEditable(column);
    if (editConfig.editable) {
      return this.renderEditableCell(editConfig.customEditor.customEditorParameters.type);
    }

    switch (column.kind) {
      case 'boolean':
        return this.switchArrayRenderer(column, this.renderBoolean);
      case 'datetime':
        return this.switchArrayRenderer(column, this.renderDatetime);
      case 'file':
        return this.switchArrayRenderer(column, this.renderFile);
      case 'decimal':
        return this.switchArrayRenderer(column, this.renderText(true));

      default:
        return this.switchArrayRenderer(column, this.renderText());
    }
  };

  removeFromFilter = (column) => {
    this.setState({checked: []});
    const filterColumns = this.state.filterColumns.slice();
    pull(filterColumns, column);
    this.setQueryParams({filterColumns}, true);
    this.updateColumns(this.props.visibleColumns, filterColumns);
    this.props.updateMe(filterColumns);
  };

  clearFilter = () => {
    this.setState({checked: []});
    this.setQueryParams({filterColumns: []}, true);
    this.updateColumns(this.props.visibleColumns, []);
    this.props.updateMe([]);
  };

  applyFilter = (filter) => {
    const filterColumns = [];
    Object.keys(filter).forEach((i) => {
      if (filter[i]) {
        filterColumns.push(i);
      }
    });

    this.setState({checked: []});
    this.setQueryParams({filterColumns}, true);
    this.updateColumns(this.props.visibleColumns, filterColumns);
    this.props.updateMe(filterColumns);
  };

  onUpdateCell = (row, columnId, cellValue) => {
    const column = find(this.state.columns, (c) => c.column_id === +columnId);
    let valueToSave = cellValue;
    if (column.array) {
      valueToSave = cellValue.split('\n').filter((s) => s.trim());
    }
    patch(`${API_METHODS.OPERATION_DATA}/${row.id}`, {
      operation_datum: {
        data: {
          [columnId]: valueToSave,
        },
      },
    }).then(this.props.updateMe);

    return true;
  };

  static validateEmail(email) {
    if (!email || !email.trim()) {
      return true;
    }
    return isEmail(email);
  }

  static validateEmailsArray(emails) {
    return emails
      .split('\n')
      .filter((e) => e.trim())
      .every(CustomOperationDataGrid.validateEmail);
  }

  static getEditable(column) {
    if (column.type === 'SystemColumnAdapter') {
      return {editable: false};
    }
    const result = (type, validator) => ({
      editable: {validator},
      customEditor: {
        getElement: createCustomEditor,
        customEditorParameters: {type},
      },
    });

    switch (column.kind) {
      case 'email':
        if (column.array) {
          return result('emails', CustomOperationDataGrid.validateEmailsArray);
        }
        return result('email', CustomOperationDataGrid.validateEmail);
      case 'string':
        return result(column.array ? 'strings' : 'string');
      case 'decimal':
      case 'number':
        return result(column.array ? 'decimals' : 'decimal');

      default:
        return {editable: false};
    }
  }

  handleDeleteItems = () => {
    const toDelete = this.state.checked.map((item, i) => item && this.state.data[i].id).filter((i) => i);
    const promises = [];

    toDelete.forEach((id) => promises.push(deleteMethod(`${API_METHODS.OPERATION_DATA}/${id}`)));

    Promise.all(promises).then(() => {
      this.props.updateMe();
      this.setState({showDeleteItemsModal: false});
    });
  };

  getColumns = () => {
    return this.state.columns.map((column) => ({
      label: (
        <span>
          <div>{this.SYSTEM_COLUMNS[column.name] || column.name}</div>
          <div>{this.formats[column.kind]}</div>
        </span>
      ),
      name: column.column_id ? column.column_id.toString() : column.name,
      path: column.column_id ? column.column_id.toString() : column.name,
      sortable: !column.array && column.kind !== 'file',
      render: this.selectRenderer(column),
      width: 150,
    }));
  };

  render() {
    const {meta} = this.props;

    return (
      <div className={b()}>
        <div className={b('toolbar-wrapper')}>{this.renderToolbar()}</div>

        <ClientTable
          data={this.state.data}
          {...this.props.sort}
          checkable
          checkedRows={this.state.checked}
          onCheck={this.onCheck}
          onCheckAll={this.onCheckAll}
          checkboxProps={{
            color: 'games',
          }}
          onSortChange={this.onSortChange}
          columns={this.getColumns()}
          fakeEmptyValue={false}
        />
        <PaginationBar
          data={this.state.data}
          currentPage={meta.current_page}
          totalPages={meta.total_pages}
          perPage={this.props.perPage}
          totalItems={meta.total_count}
          onPageChange={this.onPageChange}
        />

        <ConfirmationModal
          title={this.LANGUAGE.DELETE_ITEMS_MODAL.TITLE}
          message={this.LANGUAGE.DELETE_ITEMS_MODAL.BODY_TEXT}
          cancelText={this.LANGUAGE.DELETE_ITEMS_MODAL.CANCEL}
          confirmText={this.LANGUAGE.DELETE_ITEMS_MODAL.CONFIRM}
          className="theme-color-9"
          buttonConfirmClass="button--bg-4"
          onConfirm={this.handleDeleteItems}
          onCancel={() => this.setState({showDeleteItemsModal: false})}
          onClose={() => this.setState({showDeleteItemsModal: false})}
          show={this.state.showDeleteItemsModal}
        />

        <CustomOperationTableSettingsModal
          show={this.state.showTableSettingsModal}
          onClose={() => this.setState({showTableSettingsModal: false})}
          onConfirm={() => this.setState({showTableSettingsModal: false})}
          operationId={this.props.operationId}
          updateMe={this.props.updateMe}
        />

        <FiltersModal
          show={this.state.showFilterModal}
          onClose={() => this.setState({showFilterModal: false})}
          onFilterChange={this.applyFilter}
          totalItems={meta.total_count}
          filter={this.state.filterColumns}
        />
      </div>
    );
  }
}

CustomOperationDataGrid.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  visibleColumns: PropTypes.array,
  filterColumns: PropTypes.array,
  languageState: PropTypes.object,
  meta: PropTypes.object,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  updateMe: PropTypes.func,
  perPage: PropTypes.number,
  operationId: PropTypes.number,
  sort: PropTypes.shape({
    sortField: PropTypes.string,
    sortOrder: PropTypes.string,
  }).isRequired,
};

CustomOperationDataGrid.defaultProps = {
  data: [],
  columns: [],
  visibleColumns: [],
  filterColumns: [],
  meta: {
    current_page: 1,
    total_pages: 1,
    total_count: 0,
  },
  onPageChange: () => {},
  onSortChange: () => {},
  onSearchChange: () => {},
  perPage: 10,
};

export default connect(({languageState}) => ({languageState}))(CustomOperationDataGrid);
