import React from 'react';

import bem from 'client/services/bem';

import {GOOGLE_MAP_COLORS} from './constants';

import {GoogleMapProps, MarkerType} from '../index';

type GoogleMapMarkerProps = {
  marker: MarkerType;
  lat: number;
  lng: number;
  defaultMarkerStyle?: GoogleMapProps['defaultMarkerStyle'];
  renderMarker?: (marker: MarkerType) => React.ReactNode;
  markerType?: GoogleMapProps['markerType'];
};

export type GoogleMapMarkerStyle = {
  border: keyof typeof GOOGLE_MAP_COLORS;
  background: keyof typeof GOOGLE_MAP_COLORS;
  glyph: keyof typeof GOOGLE_MAP_COLORS;
};

const b = bem('google-map-marker');

const GoogleMapMarker: React.FC<GoogleMapMarkerProps> = ({marker, renderMarker}) => {
  // const markerStyle: GoogleMapMarkerStyle = {
  //   ...DEFAULT_GOOGLE_MARKER_STYLE,
  //   ...defaultMarkerStyle,
  // };

  return <div className={b()}>{renderMarker ? renderMarker(marker) : <></>}</div>;
};

export default GoogleMapMarker;
