import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getMessageSenders(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MESSAGE_SENDERS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_MESSAGE_SENDERS_REQUEST, types.GET_MESSAGE_SENDERS_SUCCESS, types.GET_MESSAGE_SENDERS_ERROR],
      queryParams,
    });
}

export function flushMessageSendersAllState() {
  return {
    type: types.FLUSH_MESSAGE_SENDERS_ALL_STATE,
  };
}
