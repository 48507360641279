import types from './types';

const initialState = {
  payload: [],
  visibilityItems: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_LEAD_DISPLAY_ITEM_REQUEST:
      return state;

    case types.UPDATE_LEAD_DISPLAY_ITEMS_BULK_SUCCESS:
    case types.GET_LEAD_DISPLAY_ITEM_SUCCESS:
      return {
        ...state,
        payload: action.payload.lead_display_items,
      };
    case types.GET_LEAD_CLIENT_VISIBILITY_ITEMS_SUCCESS:
      return {
        ...state,
        visibilityItems: action.payload.column_visibility_items,
      };

    default:
      return state;
  }
}
