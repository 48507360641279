import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';

import {getFileSize} from 'client/services/helpers';

import ImgPreview from 'client/components/common/img-preview';

export default class TestDrawStep extends PureComponent {
  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState;
  }

  getFormValue = (values, id) => {
    const targetKey = Object.keys(values).filter((key) => Number(key.slice(1)) === id)[0];
    return values[targetKey] || null;
  };

  renderImage = (image, values) => {
    const {id, file, variable} = image;
    const urlCreator = window.URL || window.webkitURL;
    const isFile = !!file;

    let imageSize = isFile ? getFileSize(file.filesize) : null;
    let imageTitle = isFile ? file.file_identifier : null;
    let imageUrl = isFile ? file.url : null;

    if (variable) {
      const value = this.getFormValue(values, variable.id);

      if (value) {
        imageSize = getFileSize(value[0].size);
        imageTitle = value[0].name;
        imageUrl = urlCreator.createObjectURL(value[0]);
      }
    }

    return (
      <div key={id} className="ats-step__form-item ats-step__form-item--view-2">
        <ImgPreview
          className="ats-step__form-load-img"
          imgWrapClass="ats-step__form-img-wrap"
          imgDescrClass="ats-step__form-img-descr"
          imgTitleClass="ellipsis-text"
          src={imageUrl}
          title={imageTitle}
          descr={imageSize}
        />
      </div>
    );
  };

  renderImages = (images, values) => images.map((image) => this.renderImage(image, values));

  renderMessage = (message) => {
    return (
      <div className="test-step__form-block">
        <div className="fake-input fake-input--disabled">
          <div className="fake-input__label">{this.LANGUAGE.MESSAGE_TEXT}</div>
          <div className="fake-input__textarea">{message}</div>
        </div>
      </div>
    );
  };

  render() {
    const {formValues, step} = this.props;
    const {comment, implementation} = step;
    const {message, social_account = {}, social_scenario_step_images} = implementation;
    const {name = ''} = social_account;

    return (
      <div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{this.LANGUAGE.ACCOUNT_TEXT}</div>
            <div className="fake-input__field">{name}</div>
          </div>
        </div>
        {this.renderMessage(message, formValues)}
        <div className="test-step__form-block">
          <div className="test-step__form-title test-step__form-title--view-3">{this.LANGUAGE.IMAGES_TEXT}</div>
          <div>{this.renderImages(social_scenario_step_images, formValues)}</div>
        </div>
        {comment && comment.length > 0 && (
          <div className="test-step__form-block">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{this.LANGUAGE.COMMENT_TEXT}</div>
              <div className="fake-input__textarea">{comment}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

TestDrawStep.propTypes = {
  formValues: PropTypes.object,
  step: PropTypes.object,
  languageState: PropTypes.object.isRequired,
};
