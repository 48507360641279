import React from 'react';

import bem from 'client/services/bem';

import BasePanel from 'client/common/panels/base-panel';

import {Translation} from 'client/models/language/types';

import cssModule from './access-denied.module.scss';

const b = bem('access-denied', {cssModule});

type AccessDeniedProps = {
  title: Translation;
};

// TODO: waiting for design for AccessDeniedPage
const AccessDenied: React.FC<AccessDeniedProps> = ({title}) => {
  return (
    <BasePanel className={b()}>
      <h1 className={b('title')}>{title}</h1>
    </BasePanel>
  );
};

export default AccessDenied;
