import find from 'lodash/find';
import get from 'lodash/get';
import set from 'lodash/set';
import moment from 'moment';

import {PRIZE_TYPES} from 'client/components/games/game-config-modal/prize-form/constants';

import {getWeekdays} from './helpers';

const getInstantWinConfigurationPrize = (values = {}, isPrizeMap) => {
  const timestampsSource = `schedule_instant_win_configuration.${
    isPrizeMap ? 'prize_map_timestamps' : 'prize_timestamps'
  }`;
  const result = {
    schedule_instant_win_configuration: {
      manual: {intraday_win: true, next_day_win: true},
      fix_week: {intraday_win: true, next_day_win: true},
      calculated_rule: {intraday_win: true, next_day_win: true},
    },
  };
  const instantWinConfig = Object.entries(values).find(
    ([key, value]) => value && key.endsWith('_configuration') && key !== 'instant_win_configuration',
  );
  const instant_win_type = instantWinConfig ? instantWinConfig[0].replace('_configuration', '') : null;

  if (instant_win_type) {
    set(result, 'instant_win_type', instant_win_type);
    if (instant_win_type === 'schedule_instant_win') {
      const scheduleType = get(values, 'schedule_instant_win_configuration.schedule_type', 'manual');
      set(result, 'schedule_instant_win_configuration.schedule_type', scheduleType);

      const intraDayWin = get(values, 'schedule_instant_win_configuration.intraday_win');
      const nextDayWin = get(values, 'schedule_instant_win_configuration.next_day_win');

      if (scheduleType === 'manual') {
        const schedule_win_timestamps = get(values, timestampsSource, [])
          .map(({manual_time}) => moment(manual_time).format('DD/MM/YYYY HH:mm:SS'))
          .join(', ');
        set(
          result,
          `schedule_instant_win_configuration.${scheduleType}.schedule_win_timestamps`,
          schedule_win_timestamps,
        );
      } else if (scheduleType === 'fix_week') {
        const schedule_win_timestamps = get(values, timestampsSource, []).reduce((acc, {weekday, win_time}) => {
          const indexWeekday = getWeekdays({}).findIndex(({value}) => value === weekday);
          const time = win_time ? moment(win_time).format('HH:mm') : '';
          const accTime = acc[indexWeekday] ? acc[indexWeekday].win_time : '';
          acc[indexWeekday] = {
            win_time: [accTime, time].filter(Boolean).join('\n'),
          };
          return acc;
        }, []);
        set(
          result,
          `schedule_instant_win_configuration.${scheduleType}.schedule_win_timestamps`,
          schedule_win_timestamps,
        );

        const datesOff = get(values, 'schedule_instant_win_configuration.dates_off', [])
          .filter(Boolean)
          .map((date) => moment(date).format('DD/MM/YYYY'))
          .join(' ; ');
        set(result, 'schedule_instant_win_configuration.dates_off', datesOff);
      } else if (scheduleType === 'calculated_rule') {
        const schedule_win_timestamps = get(values, timestampsSource, []).reduce(
          (acc, {weekday, win_time_from, win_time_to}) => {
            const indexWeekday = getWeekdays({}).findIndex(({value}) => value === weekday);
            const timeFrom = moment(win_time_from).format('HH:mm');
            const timeTo = moment(win_time_to).format('HH:mm');
            const accTime = acc[indexWeekday] ? [acc[indexWeekday].win_time] : '';
            acc[indexWeekday] = {
              win_time: [accTime, timeFrom, timeTo].filter(Boolean).join('\n'),
            };
            return acc;
          },
          [],
        );
        set(
          result,
          `schedule_instant_win_configuration.${scheduleType}.schedule_win_timestamps`,
          schedule_win_timestamps,
        );
        set(
          result,
          `schedule_instant_win_configuration.${scheduleType}.prizes_number`,
          values.schedule_instant_win_configuration.prizes_number,
        );

        const datesOff = get(values, 'schedule_instant_win_configuration.dates_off', [])
          .filter(Boolean)
          .map((date) => moment(date).format('DD/MM/YYYY'))
          .join('; ');
        set(result, 'schedule_instant_win_configuration.dates_off', datesOff);
      }
      set(result, `schedule_instant_win_configuration.${scheduleType}.intraday_win`, intraDayWin);
      set(result, `schedule_instant_win_configuration.${scheduleType}.next_day_win`, nextDayWin);
    }
  }

  return result;
};

const getInitialSubstitution = (items, substitutionPrizes) => {
  return substitutionPrizes.map((prize) => {
    const item = find(items, {substitute_prize_id: prize.id});

    return {
      prizeId: prize.id,
      name: prize.internal_name,
      substitutionId: item ? item.id : null,
      order: item ? item.order : null,
    };
  });
};

export const getInitialValues = (val, {prizes, prize, isPrizeMap}) => {
  const values = val || {};
  const substitutionPrizes = prizes.filter(({id}) => id !== values.id);

  const type = [PRIZE_TYPES.COUPON, PRIZE_TYPES.LOYALTY].includes(values.type) ? values.type : PRIZE_TYPES.COMMON;

  let defaultLoyaltyOnly = type === PRIZE_TYPES.LOYALTY;

  return isPrizeMap
    ? {
        ...values,
        external_name: values.external_prize_name,
        game_id: values.game_id,
        code: values.prize_code,
        full_win_ratio: prize?.full_win_ratio,
        full_win: prize?.full_win,
        ...getInstantWinConfigurationPrize(values, true),
      }
    : {
        ...(values || {}),
        full_win_ratio: prize?.full_win_ratio,
        full_win: prize?.full_win,
        loyalty_only: values.loyalty_only ?? defaultLoyaltyOnly,
        type,
        geo_level: values.geo_level || 'none',
        online_level: values.online_level || 'none',
        coupon: values.coupon ? {...values.coupon, value: values.coupon.id, label: values.coupon.internal_code} : null,
        ...getInstantWinConfigurationPrize(values),
        substitution: getInitialSubstitution(values.prize_substitution_items, substitutionPrizes),
      };
};
