import isUndefined from 'lodash/isUndefined';

import types from './types';

let init = {
  payload: {
    name: '',
    rootPage: true,
    callback: null,
  },
};

export default function (_state_ = init, action) {
  let state = Object.assign({}, _state_);

  switch (action.type) {
    case types.SET_HEADER_LINK_BACK:
      return {
        ...state,
        payload: {
          name: action.payload.name,
          callback: action.payload.callback,
          rootPage: !isUndefined(action.payload.rootPage) && action.payload.rootPage,
        },
      };
    case types.CLEAR_HEADER_LINK_BACK:
      state.payload = action.payload;
      return state;
    default:
      return state;
  }
}
