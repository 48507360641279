import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {required} from 'client/services/validator';

import {patchCampaign} from 'client/ducks/campaigns/actions';

import Popover from 'client/common/popovers/popover';

import fieldTemplate from 'client/components/common/field';
import Icon from 'client/components/common/icon';

import './camp-settings-popover.scss';

class CampSettingsPopover extends Component {
  static propTypes = {
    children: PropTypes.any,
    languageState: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onNameChanged: PropTypes.func.isRequired,
    onArchive: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    patchCampaign: PropTypes.func.isRequired,
    ableToArchive: PropTypes.bool,
    ableToDelete: PropTypes.bool,
    campaignId: PropTypes.number,
    archived: PropTypes.bool,
    campaignName: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    campaignName: '',
    archived: false,
  };

  static formName = 'CampSettingsPopoverForm';

  handleSubmitForm = this.props.handleSubmit(({name}) => this.handleEdit(name.trim()));

  constructor(props) {
    super(props);

    this.state = {
      edit: false,
    };

    this.LANGUAGE = props.languageState.payload.CAMPAIGN.SETTINGS_POPOVER;

    this.rules = {
      name: required(this.LANGUAGE.ERRORS.NAME_IS_REQUIRED),
    };
  }

  componentDidMount() {
    this.props.initialize({name: this.props.campaignName});
  }

  componentDidUpdate(prevProps) {
    if (this.props.campaignName !== prevProps.campaignName) {
      this.props.initialize({name: this.props.campaignName});
    }
  }

  handleEdit = (name) => {
    if (this.state.edit && name) {
      return this.props
        .patchCampaign(this.props.campaignId, {
          campaign: {
            name,
          },
        })
        .then(({payload: {response: {errors} = {}}}) => {
          if (errors) {
            throw new SubmissionError({name: this.LANGUAGE.ERRORS.NAME_IS_TAKEN});
          }

          this.props.onNameChanged();
          this.setState({edit: false});
        });
    }

    return this.setState({edit: !this.state.edit});
  };

  render() {
    const {children, archived, ableToArchive, ableToDelete, onDelete, onArchive} = this.props;

    const {LANGUAGE} = this;

    return (
      <Popover
        position="bottom"
        arrowOffset={90}
        overlayInnerStyle={{
          width: 340,
        }}
        overlay={
          <div className="popover-list theme-color-10 camp-settings-popover">
            <h3 className="popover-list__title flex-container flex-align-center flex-justify-between">
              {LANGUAGE.TITLE}
              <button className="button button--circle button--bg-6" onClick={() => this.handleEdit()}>
                <Icon name="edit" className="button__icon" width={20} height={20} />
              </button>
            </h3>
            <div className="camp-settings-popover__content">
              <form onSubmit={this.handleSubmitForm}>
                <div className="main-text main-text--bold">
                  <div className="camp-settings-popover__block">
                    <Field
                      cssModifier="input--no-min-height input--disabled-view-1"
                      label={LANGUAGE.NAME_LABEL}
                      name="name"
                      type="text"
                      onBlur={this.handleSubmitForm}
                      component={fieldTemplate}
                      disabled={!this.state.edit}
                      validate={this.rules.name}
                    />
                  </div>
                </div>
              </form>
              {!ableToDelete && ableToArchive && (
                <div>
                  <div className="link" onClick={onArchive}>
                    {archived ? LANGUAGE.RESTORE_LABEL : LANGUAGE.ARCHIVE_LABEL}
                  </div>
                </div>
              )}
              {!ableToArchive && (
                <div>
                  <div className="main-text main-text--small">{LANGUAGE.NOTICE_LABEL}</div>
                </div>
              )}
              {ableToDelete && (
                <div className="message-error-text link link--color-1" onClick={onDelete}>
                  {LANGUAGE.DELETE_LABEL}
                </div>
              )}
            </div>
          </div>
        }
      >
        {children}
      </Popover>
    );
  }
}

const form = reduxForm({
  form: CampSettingsPopover.formName,
})(CampSettingsPopover);

export default connect(({languageState}) => ({languageState}), {
  patchCampaign,
})(form);
