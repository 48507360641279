import {blobToBase64} from 'client/services/blobToBase64';

import {SCREEN_FORMATS} from 'client/components/diy-operation/modals/diy-customization-modal/constants';

import {DiyOpTemplateValues} from './types';

const mapSharing = (values: DiyOpTemplateValues, isSave: boolean) => {
  if (isSave) {
    return {};
  }

  return {user_access_levels: ['region', 'store'].filter((key) => values.sharing[key as keyof typeof values.sharing])};
};

const mapImageFormats = (values: DiyOpTemplateValues) => {
  const result = [];

  if (values.broadcasts.online) {
    result.push(SCREEN_FORMATS.desktop);
    result.push(SCREEN_FORMATS.phone);
  }

  if (values.broadcasts.vertical) {
    result.push(SCREEN_FORMATS.kiosk_vertical);
  }

  if (values.broadcasts.horizontal) {
    result.push(SCREEN_FORMATS.kiosk_horizontal);
  }

  return result;
};

export default async (values: DiyOpTemplateValues, isSave: boolean, locale: string) => {
  const body: Record<string, any> = {
    title: values.name,
    [`description_${locale}`]: values.description,
    icon:
      typeof values.icon === 'string'
        ? values.icon
        : {
            data: await blobToBase64(values.icon),
            filename: values.icon.name,
          },
    languages: Object.keys(values.languages).filter((key) => values.languages[key]),
    image_formats: mapImageFormats(values),
    ...mapSharing(values, isSave),
  };

  if (typeof body.icon === 'string') {
    delete body.icon;
  }

  if (values.clients.length) {
    body.company_ids = values.clients.map((i) => Number(i));
  }

  return body;
};
