import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, FieldArray, getFormValues, SubmissionError} from 'redux-form';

import InterfaceMappingService from 'client/services/InterfaceMappingService';

import {selectColumns} from 'client/ducks/column-adapters/selectors';

import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import FormFieldWrap from 'client/components/common/form-field-wrap';
import Icon from 'client/components/common/icon';

import {TranslationJsx} from 'client/models/language/types';

import AtSetItem from '../../at-set-item';

import './set-items-modal.scss';

class SetItemsModal extends Component {
  static formName = 'SetItemsModalForm';

  static defaultProps = {
    onUpdate: () => {},
    interfaceName: '',
    show: false,
    hideAddItemAction: false,
    isInternal: false,
    formValues: {},
    mappingItems: [],
    error: '',
  };

  static propTypes = {
    lang: PropTypes.object.isRequired,
    langFormats: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    showMappingModal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onKindChange: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSubmit: PropTypes.func.isRequired,
    leadColumns: PropTypes.array.isRequired,
    onUpdate: PropTypes.func,
    interfaceName: PropTypes.string,
    show: PropTypes.bool,
    hideAddItemAction: PropTypes.bool,
    isInternal: PropTypes.bool,
    mappingItems: PropTypes.array,
    error: TranslationJsx,
  };

  constructor(props) {
    super(props);

    this.formats = Object.entries(this.props.langFormats).map((format) => {
      const [value, label] = format;

      return {
        value,
        label,
      };
    });

    this.removedExternalIds = [];

    this.state = {
      showDeletionModal: false,
      itemIndexForDeletion: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      if (this.updating) {
        const items = this.props.items.filter((v) => !this.removedExternalIds.includes(v.external_id));
        let addedItems = [];

        if (this.props.formValues && this.props.formValues.items) {
          addedItems = this.props.formValues.items.filter((v) => !v.external_id);
        }

        this.props.initialize({items: [...items, ...addedItems]});
        this.updating = false;
      } else {
        this.props.initialize({items: this.props.items});
      }
    }
  }

  disableDeletionModal = () =>
    this.setState({
      showDeletionModal: false,
    });

  onConfirmDeleteItem = () =>
    this.setState(
      {
        showDeletionModal: false,
      },
      () => this.removeField(this.state.itemIndexForDeletion),
    );

  /**
   * Remove field if format not boolean
   *
   * @param {number} index - item index
   * @returns {Function} - return closure
   */
  handleFormatFieldChange = (index) => {
    return (e, value) => {
      if (value !== 'boolean') {
        /**
         * @todo Currently in redux-forms 7.2.0 there is no chance to remove field from array on unregister
         * Set it to null and after that remove all nullable fields before request
         * https://github.com/erikras/redux-form/issues/442
         */
        this.props.change(`items[${index}].value_as_true`, null);
      }
    };
  };

  handleUpdateClick = () => {
    this.updating = true;

    return this.props.onUpdate();
  };

  renderItems = ({fields}) => {
    const {lang} = this.props;

    this.removeField = (index) => {
      const field = fields.get(index);

      if (field.external_id) {
        this.removedExternalIds.push(field.external_id);
      }

      return fields.remove(index);
    };

    return (
      <div>
        <div className="set-items-modal__rows">
          <CustomScrollbars
            cssModifier="custom-scrollbars--view-2 custom-scrollbars--hide-hor-scroll"
            scrollbarProps={{
              autoHeightMin: 280,
              autoHeightMax: 450,
              hideTracksWhenNotNeeded: true,
            }}
          >
            <div className="custom-scrollbars__scroll-content">
              {fields.map((member, index) => {
                const itemValues = this.props.formValues.items[index];

                return (
                  <AtSetItem
                    key={index}
                    formats={this.formats}
                    labels={{
                      formatLabel: lang.FORMAT_LABEL,
                      optinLabel: lang.OPTIN_ADDED_LABEL,
                      arrayLabel: lang.ARRAY_LABEL,
                      boolLabel: lang.BOOL_LABEL,
                      itemNameLabel: lang.ITEM_NAME_LABEL,
                    }}
                    placeholders={{
                      formatPlaceholder: lang.FORMAT_PLACEHOLDER,
                      namePlaceholder: lang.NAME_PLACEHOLDER,
                      booleanPlaceholder: lang.NAME_PLACEHOLDER,
                    }}
                    errorMessages={{
                      requiredFormatError: lang.REQUIRED_FORMAT_ERROR,
                      requiredNameError: lang.REQUIRED_NAME_ERROR,
                      uniqueNameError: lang.THIS_NAME_IS_TAKEN_ERROR,
                    }}
                    tooltipMessage={lang.ARRAY_TOOLTIP}
                    className="theme-color-8 set-items-modal__row"
                    onDeleteClick={() => this.setState({showDeletionModal: true, itemIndexForDeletion: index})}
                    onEditClick={() => this.props.showMappingModal(index)}
                    member={member}
                    index={index}
                    onKindChange={this.props.onKindChange}
                    isBoolean={itemValues.kind === 'boolean'}
                    isArray={itemValues.array || false}
                    onFormatChange={this.handleFormatFieldChange(index)}
                    mappingItems={this.props.mappingItems[index]}
                  />
                );
              })}
            </div>
          </CustomScrollbars>
        </div>
        <div className="button button--bg-7" onClick={() => fields.push({})}>
          <Icon name="plus" className="button__icon" />
          <span>{lang.ADD_ITEM_BUTTON}</span>
        </div>
      </div>
    );
  };

  getStepLabel = () => {
    return this.props.isInternal ? '(4/5)' : '(3/5)';
  };

  save = (values) => {
    const mappingItems = this.props.mappingItems.map((data) => ({mapping_items: data}));
    const {lang} = this.props;

    if (!InterfaceMappingService.validateLeadMatchingRule(mappingItems, this.props.leadColumns)) {
      throw new SubmissionError({_error: lang.LEAD_DB_MATCHING_RULE_ERROR});
    }

    this.props.onSubmit(values);
  };

  render() {
    const {show, onClose, onCancel, handleSubmit, interfaceName, lang} = this.props;
    const title = `${lang.TITLE} ${this.getStepLabel()}`;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-2 theme-color-8 set-items-modal"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={title}
      >
        <form onSubmit={handleSubmit(this.save)}>
          <p className="main-text break-word">{interfaceName}</p>
          <FieldArray name="items" component={this.renderItems} rerenderOnEveryChange />
          <p className="edit-items-modal__submit-error">{this.props.error}</p>
          <FormFieldWrap isButtonsContainer>
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onCancel}>
              {lang.GO_BACK_BUTTON}
            </button>
            {this.props.onUpdate && (
              <button
                className="button button--bg-11 modal-window__footer-btn"
                type="button"
                onClick={this.handleUpdateClick}
              >
                {lang.UPDATE_BUTTON}
              </button>
            )}
            <button className="button button--bg-7 modal-window__footer-btn" type="submit">
              {lang.NEXT_BUTTON}
            </button>
          </FormFieldWrap>
        </form>

        <ConfirmationModal
          show={this.state.showDeletionModal}
          title={lang.DELETE_ITEM_MODAL.TITLE}
          message={lang.DELETE_ITEM_MODAL.MESSAGE}
          buttonConfirmClass="button--bg-7"
          className="theme-color-8"
          confirmText={lang.DELETE_ITEM_MODAL.DELETE_BUTTON}
          cancelText={lang.DELETE_ITEM_MODAL.CANCEL_BUTTON}
          onCancel={this.disableDeletionModal}
          onClose={this.disableDeletionModal}
          onConfirm={this.onConfirmDeleteItem}
        />
      </Modal>
    );
  }
}

const SetItemsModalForm = reduxForm({
  form: SetItemsModal.formName,
})(SetItemsModal);

export default connect((state) => {
  return {
    lang: state.languageState.payload.AUTOTASK.SET_ITEMS_MODAL,
    langFormats: state.languageState.payload.CUSTOM_FORMATS,
    formValues: getFormValues(SetItemsModal.formName)(state),
    leadColumns: selectColumns(state, 'Lead'),
  };
})(SetItemsModalForm);
