import React, {useEffect, useMemo} from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import SelectDropdown from 'client/common/selects/select-dropdown';

import {Translation} from 'client/models/language/types';
import {Game, GameDraw} from 'client/models/prizes/types';

import cssModule from './lottery-draw-dropdown.module.scss';

const b = bem('lottery-draw-dropdown', {cssModule});

type LotteryDrawDropdownProps = {
  className?: string;
  name?: string;
  value?: number | null;
  hideLabel?: boolean;
  hasAllOption?: boolean;
  onChange?: (value: GameDraw | null) => void;
  game?: Game;
  disabled?: boolean;
};

const LotteryDrawDropdown: React.FC<LotteryDrawDropdownProps> = (props) => {
  const {game, className, name = 'name', value, onChange, hideLabel, hasAllOption, disabled, ...restProps} = props;
  const lang = useLanguage('LOTTERY.FIELDS.LOTTERY_DRAW_DROPDOWN');

  const gameDraws = useMemo(() => {
    const options: {label: Translation; value: string | number}[] =
      game?.game_draws?.map((draw) => ({
        label: draw.name,
        value: draw.id?.toString(),
      })) || [];

    if (hasAllOption) {
      options.unshift({
        label: lang.ALL_OPTION,
        value: 'ALL',
      });
    }

    return options;
  }, [game?.game_draws, hasAllOption, lang.ALL_OPTION]);

  const findDraw = (id: number) => {
    return game?.game_draws?.find((draw) => +draw?.id === +id) || null;
  };

  useEffect(() => {
    if (!value && !hasAllOption && game?.game_draws?.[0]) {
      onChange?.(game?.game_draws[0]);
    }
  }, [hasAllOption, value, onChange, gameDraws, game?.game_draws]);

  if (gameDraws.length < 2) {
    return null;
  }

  return (
    <SelectDropdown
      className={cn(b(), className)}
      label={hideLabel ? '' : lang.LABEL}
      onChange={(nextValue: number | string) => onChange?.(nextValue === 'ALL' ? null : findDraw(nextValue as number))}
      value={!value && hasAllOption ? 'ALL' : value}
      name={name}
      options={gameDraws}
      disabled={disabled}
      {...restProps}
    />
  );
};

export default LotteryDrawDropdown;
