import React, {Component} from 'react';

import lowerCase from 'lodash/lowerCase';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector, SubmissionError} from 'redux-form';

import {required, number} from 'client/services/validator';

import {getInterfaceTemplatesAction, getInterfaceTemplateItemsAction} from 'client/ducks/interface-templates/actions';
import {getNewSGInterfaceAction} from 'client/ducks/sg-interfaces/actions';
import {getNewUnbounceInterfaceAction} from 'client/ducks/unbounce-interface/actions';

import {INTERFACE_FAMILIES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

class SetInterfaceFamilyModal extends Component {
  static formName = 'SetInterfaceFamilyForm';

  static titleCaption = '(2/5)';

  static defaultProps = {
    show: false,
    interfaceName: '',
    interfaceFamily: {},
  };

  static propTypes = {
    lang: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    fetchInterfaceTemplates: PropTypes.func.isRequired,
    interfaceTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
    onNext: PropTypes.func.isRequired,
    fetchTemplateItems: PropTypes.func.isRequired,
    fetchNewSGInterface: PropTypes.func.isRequired,
    fetchNewUnbounceInterface: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    show: PropTypes.bool,
    interfaceName: PropTypes.string,
    interfaceFamily: PropTypes.object,
  };

  interfaceFamilies = [
    {
      value: INTERFACE_FAMILIES.BUZZEO_APP,
      label: this.props.lang.BUZZEO_APP_FAMILY,
    },
    {
      value: INTERFACE_FAMILIES.SURVEY_GIZMO,
      label: this.props.lang.SURVEY_GIZMO_FAMILY,
    },
    {
      value: INTERFACE_FAMILIES.UNBOUNCE,
      label: this.props.lang.UNBOUNCE_FAMILY,
    },
  ];

  rules = {
    family: required(this.props.lang.FAMILY_IS_REQUIRED_ERROR),
    template: required(this.props.lang.TEMPLATE_REQUIRED_ERROR),
    surveyId: [
      required(this.props.lang.SURVEY_ID_IS_REQUIRED_ERROR),
      number(this.props.lang.SURVEY_ID_IS_NUMERIC_ERROR),
    ],
    unbounceId: [required(this.props.lang.UNBOUNCE_ID_IS_REQUIRED_ERROR)],
  };

  handleFamilyTypeChange = (value) => {
    if (value.value === this.interfaceFamilies[0].value) {
      this.props.fetchInterfaceTemplates({fetchAll: true});
    }

    this.props.onChange();
  };

  handleResponse = (response) => {
    const {lang} = this.props;

    if (!response.error) {
      return this.props.onNext();
    }
    throw new SubmissionError({
      integration_interface_id: lang.INTEGRATION_INTERFACE_ID_IS_INCORRECT_ERROR,
    });
  };

  handleFormSubmit = ({
    interface_family: interfaceFamily,
    interface_template: interfaceTemplate,
    integration_interface_id: integrationInterfaceId,
  }) => {
    switch (interfaceFamily.value) {
      case INTERFACE_FAMILIES.SURVEY_GIZMO:
        return this.props
          .fetchNewSGInterface({
            external_id: integrationInterfaceId,
          })
          .then(this.handleResponse);
      case INTERFACE_FAMILIES.UNBOUNCE:
        return this.props
          .fetchNewUnbounceInterface({
            external_id: integrationInterfaceId,
          })
          .then(this.handleResponse);
      default:
        return this.props.fetchTemplateItems(interfaceTemplate.value).then(this.props.onNext);
    }
  };

  render() {
    const {show, onClose, lang, onCancel, interfaceFamily, handleSubmit, interfaceTemplates, interfaceName} =
      this.props;

    const {interfaceFamilies, handleFamilyTypeChange, rules, handleFormSubmit} = this;

    const isBuzzeoApp = interfaceFamily.value === INTERFACE_FAMILIES.BUZZEO_APP;
    const isSurveyGizmo = interfaceFamily.value === INTERFACE_FAMILIES.SURVEY_GIZMO;
    const showProviderFields = interfaceFamily.value && !isBuzzeoApp;
    const extendedInterfaceTemplates = [
      {
        value: 0,
        label: lang.CREATE_ITEMS_FROM_SCRATCH_LABEL,
      },
      ...sortBy(interfaceTemplates, ({label}) => lowerCase(label)),
    ];
    const title = `${lang.TITLE} ${SetInterfaceFamilyModal.titleCaption}`;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-8"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={title}
      >
        <form noValidate="noValidate" onSubmit={handleSubmit(handleFormSubmit)}>
          <p className="main-text break-word">{interfaceName}</p>
          <FormFieldWrap>
            <Field
              label={lang.INTERFACE_TYPE_LABEL}
              placeholder={lang.CHOOSE_FAMILY_PLACEHOLDER}
              name="interface_family"
              type="select"
              options={interfaceFamilies}
              searchable={false}
              component={fieldTemplate}
              validate={rules.family}
              onChange={handleFamilyTypeChange}
            />
          </FormFieldWrap>
          {isBuzzeoApp && (
            <FormFieldWrap>
              <Field
                cssModifier="select--no-min-height select--view-4"
                placeholder={lang.CHOOSE_TEMPLATE_PLACEHOLDER}
                label={lang.INTERFACE_FAMILY_LABEL}
                name="interface_template"
                type="select"
                options={extendedInterfaceTemplates}
                searchable={false}
                component={fieldTemplate}
                validate={rules.template}
              />
            </FormFieldWrap>
          )}
          {showProviderFields && [
            <FormFieldWrap key="integration_interface_id">
              <Field
                placeholder={isSurveyGizmo ? lang.SURVEY_ID_PLACEHOLDER : lang.UNBOUNCE_ID_PLACEHOLDER}
                cssModifier="input--no-min-height"
                label={isSurveyGizmo ? lang.SURVEY_ID_LABEL : lang.UNBOUNCE_ID_LABEL}
                name="integration_interface_id"
                type="text"
                component={fieldTemplate}
                validate={isSurveyGizmo ? rules.surveyId : rules.unbounceId}
              />
            </FormFieldWrap>,
            <FormFieldWrap key="integration_interface_description">
              <Field
                placeholder={lang.DESCRIPTION_PLACEHOLDER}
                cssModifier="input--no-min-height"
                label={lang.INTEGRATION_INTERFACE_DESCRIPTION}
                name="integration_interface_description"
                type="textarea"
                component={fieldTemplate}
              />
            </FormFieldWrap>,
          ]}
          <FormFieldWrap isButtonsContainer>
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onCancel}>
              {lang.GO_BACK_BUTTON}
            </button>
            <button className="button button--bg-7 modal-window__footer-btn" type="submit">
              {lang.NEXT_BUTTON}
            </button>
          </FormFieldWrap>
        </form>
      </Modal>
    );
  }
}

const SetInterfaceFamilyForm = reduxForm({
  form: SetInterfaceFamilyModal.formName,
  validate: SetInterfaceFamilyModal.validate,
})(SetInterfaceFamilyModal);

export default connect(
  (state) => {
    return {
      lang: state.languageState.payload.AUTOTASK.SET_INTERFACE_FAMILY_MODAL,
      interfaceTemplates: state.interfaceTemplates.templates.map((item) => ({value: item.id, label: item.name})),
      interfaceFamily: formValueSelector(SetInterfaceFamilyModal.formName)(state, 'interface_family'),
    };
  },
  {
    fetchInterfaceTemplates: getInterfaceTemplatesAction,
    fetchTemplateItems: getInterfaceTemplateItemsAction,
    fetchNewSGInterface: getNewSGInterfaceAction,
    fetchNewUnbounceInterface: getNewUnbounceInterfaceAction,
  },
)(SetInterfaceFamilyForm);
