import React from 'react';

import cn from 'classnames';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {TranslationJsx} from 'client/models/language/types';

import './tab-switcher.scss';

const b = bem('tab-switcher');

const TabSwitcher = (props) => {
  const {tabs, onTabClick, activeTab, className, style} = props;

  return (
    <CustomScrollbars cssModifier={className} horTrackCssClass={b('track')}>
      <nav className={b([style])}>
        {tabs
          .filter((i) => !i.exclude)
          .map((tab) => {
            const {value, title, className: tabClassName, customElement, hasError} = tab;

            if (customElement) {
              return customElement;
            }

            const isActive = value === activeTab;
            const externClassName = isFunction(tabClassName) ? tabClassName({isActive}) : tabClassName;

            return (
              <button
                type="button"
                className={cn(b('tab', {active: isActive, error: hasError, [style]: true}), externClassName)}
                key={value}
                disabled={tab.disabled}
                onClick={() => onTabClick(value)}
              >
                {title}
              </button>
            );
          })}
      </nav>
    </CustomScrollbars>
  );
};

TabSwitcher.defaultProps = {
  className: '',
};

TabSwitcher.propTypes = {
  onTabClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  style: PropTypes.oneOf(['title', 'base']),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: TranslationJsx,
      value: TranslationJsx,
      className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      exclude: PropTypes.bool,
      disabled: PropTypes.bool,
      customElement: PropTypes.node,
    }),
  ).isRequired,
  className: PropTypes.string,
};

export default TabSwitcher;
