const scheduleTypes = {
  ParticipationInstantWinConfiguration: 'RATIO_PARTICIPATION',
  TimeInstantWinConfiguration: 'RATIO_TIME',
  manual: 'CUSTOM_LIST',
  fix_week: 'WEEKLY_LIST',
  calculated_rule: 'WEEK_SCHEDULE',
};

const getScheduleType = (configuration) => {
  if (!configuration) {
    return '';
  }

  let key = configuration.type;
  if (configuration.type === 'ScheduleInstantWinConfiguration') {
    key = configuration?.schedule_type;
  }
  return scheduleTypes[key];
};

export const mapPrizes = (prizes) => {
  if (!prizes.length) {
    return prizes;
  }

  return prizes
    .sort((first, second) => first.order - second.order)
    .map((data, idx) => {
      const {
        external_name,
        total_quantities,
        instant_win_configuration,
        email_send,
        ticket_print,
        win_pop_up_image_translations,
        type,
      } = data;

      return {
        index: idx + 1,
        prizeName: external_name,
        totalCurrentStock: total_quantities?.total_current_stock,
        schedule: getScheduleType(instant_win_configuration),
        email: email_send,
        ticket: ticket_print,
        popup: win_pop_up_image_translations && Boolean(Object.keys(win_pop_up_image_translations).length),
        coupon_type: type === 'CouponPrize',
        data,
      };
    });
};
