import React from 'react';

import get from 'lodash/get';

import bem from 'client/services/bem';

import VisualsPlaceholder from 'client/components/client-autotask/tabs/visuals-tab/visuals-placeholder';
import {Visuals} from 'client/models/visuals/types';

import cssModule from './file-preview-cell.module.scss';

const b = bem('file-preview-cell', {cssModule});

type FilePreviewCellProps = {
  visual: Visuals;
  onTogglePreviewModal: (id: number) => void;
};

const FilePreviewCell: React.FC<FilePreviewCellProps> = (props) => {
  const {visual, onTogglePreviewModal} = props;

  const onClick = () => onTogglePreviewModal(visual.id);
  const src = get(visual, 'file.preview.url.url');

  return (
    <div className={b()}>
      {src ? (
        <img className={b('image')} src={src} onClick={onClick} />
      ) : (
        <VisualsPlaceholder visual={visual} onClick={onClick} />
      )}
    </div>
  );
};

export default FilePreviewCell;
