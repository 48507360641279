import React, {Component} from 'react';

import cn from 'classnames';
import {Table, Column, Cell} from 'fixed-data-table-2';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {SOCIAL_ACCOUNT_TYPES, SOCIAL_ICONS} from 'client/common/config';

import Icon from 'client/components/common/icon';

import './social-network-accounts-card-data-grid.scss';

function NameCell({data, rowIndex, field, ...props}) {
  return (
    <Cell {...props}>
      <div className="cell-wrapper">
        <div className="ellipsis-text ellipsis-cell">{data[rowIndex][field]}</div>
        <div className="ellipsis-text ellipsis-cell">{data[rowIndex].value}</div>
      </div>
    </Cell>
  );
}

NameCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

function TypeCell({data, rowIndex, isAdmin, ...props}) {
  const field = data[rowIndex];
  const userRole = isAdmin ? 'admin' : 'user';
  const iconName =
    field.type === SOCIAL_ACCOUNT_TYPES.FACEBOOK ? SOCIAL_ICONS[userRole].FACEBOOK : SOCIAL_ICONS[userRole].TWITTER;
  const fieldId = isAdmin ? field.client_id : field.id;

  return (
    <Cell {...props}>
      {fieldId && field.created_by_client && <div className="client-marker">C</div>}
      <Icon name={iconName} className="pull-right" width={14} height={14} />
    </Cell>
  );
}

TypeCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  isAdmin: PropTypes.bool,
};

function SubsidiaryCell({data, rowIndex, ...props}) {
  return (
    <Cell {...props}>
      <div className="cell-wrapper">
        <div className="ellipsis-text ellipsis-cell">{get(data[rowIndex], 'subsidiary.name')}</div>
      </div>
    </Cell>
  );
}

SubsidiaryCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

class SocialNetworkAccountsDataGrid extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    lang: PropTypes.object.isRequired,
    withSubsidiaries: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    height: PropTypes.number,
  };

  static HEADER_HEIGHT = 40;
  static ROW_HEIGHT = 35;
  static TABLE_WIDTH = 230;
  static TABLE_HEIGHT_BY_SUBSIDIARY = {
    true: 418,
    false: 199,
  };
  static TABLE_HEIGHT_BY_CLIENT = 290;

  constructor(props) {
    super(props);

    const {lang} = this.props;

    this.columns = [
      {
        header: lang.NAME_COLUMN,
        width: 62,
        cell: (data) => <NameCell data={data} field="name" />,
      },
      {
        header: lang.SUBSIDIARY_COLUMN,
        hidden: !props.withSubsidiaries,
        width: 30,
        cell: (data) => <SubsidiaryCell data={data} />,
      },
      {
        header: lang.TYPE_COLUMN,
        width: 138,
        cell: (data) => <TypeCell data={data} isAdmin={this.props.isAdmin} />,
      },
    ];
  }

  render() {
    const {withSubsidiaries, data, isAdmin, height} = this.props;

    const className = cn(
      'fixed-table',
      'fixed-table--expand-1',
      'fixed-table--social-accounts',
      'ph-em-dg',
      'theme-color-social-accounts',
      {
        'fixed-table--social-accounts--with-subsidiary': withSubsidiaries,
      },
    );

    return (
      <div className={className}>
        <Table
          rowsCount={data.length}
          headerHeight={SocialNetworkAccountsDataGrid.HEADER_HEIGHT}
          rowHeight={SocialNetworkAccountsDataGrid.ROW_HEIGHT}
          width={SocialNetworkAccountsDataGrid.TABLE_WIDTH}
          {...this.props}
          height={
            height ||
            (isAdmin
              ? SocialNetworkAccountsDataGrid.TABLE_HEIGHT_BY_SUBSIDIARY[withSubsidiaries]
              : SocialNetworkAccountsDataGrid.TABLE_HEIGHT_BY_CLIENT)
          }
        >
          {this.columns.map(
            (column, key) =>
              !column.hidden && (
                <Column key={key} {...column} cell={column.cell(data)} header={<Cell>{column.header}</Cell>} />
              ),
          )}
        </Table>
      </div>
    );
  }
}

const languageSet = ({
  isAdmin,
  languageState: {
    payload: {AGENCY, ACCOUNT_PARAMETERS},
  },
}) => ({
  lang: isAdmin ? AGENCY.SOCIAL_NETWORK_ACCOUNTS_CARD : ACCOUNT_PARAMETERS.SOCIAL_NETWORK,
});

export default connect(languageSet)(SocialNetworkAccountsDataGrid);
