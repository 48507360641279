import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {selectLeadsTotalCount} from 'client/ducks/leads-list/selectors';

import Modal from 'client/common/modals/modal';

import BcMailingFiltersForm from '../../forms/bc-mailing-filters-form';
import BcOptinsFiltersForm from '../../forms/bc-optins-filters-form';

class BcMailingFiltersModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    filters: PropTypes.object,
    optinsFilters: PropTypes.object,
    optinsControl: PropTypes.string,
    optinsOperator: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
    onOptinsChange: PropTypes.func.isRequired,
    clientId: PropTypes.string.isRequired,
    results: PropTypes.number.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    show: false,
    filters: {},
    optinsFilters: {},
    optinsControl: '',
    optinsOperator: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      initFiltersForm: false,
    };

    this.LANGUAGE = props.languageState.payload.BROADCASTING_TASK.MAILING_FILTERS_MODAL;
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      this.setState({
        initFiltersForm: this.props.show && !prevProps.show,
      });
    }
  }

  render() {
    const {
      show,
      onClose,
      filters,
      results,
      onFiltersChange,
      clientId,
      optinsFilters,
      optinsControl,
      optinsOperator,
      onOptinsChange,
    } = this.props;

    const {initFiltersForm} = this.state;

    const {LANGUAGE} = this;

    return (
      <Modal show={show} onClose={onClose} title={LANGUAGE.TITLE} className="modal-window--width-1 theme-color-16">
        <div className="form-field-wrap form-field-wrap--margin-1">
          <BcMailingFiltersForm
            init={initFiltersForm}
            clientId={clientId}
            filters={filters}
            onFormChange={onFiltersChange}
          />
        </div>
        <div>
          <BcOptinsFiltersForm
            init={initFiltersForm}
            optinsFilters={optinsFilters}
            optinsControl={optinsControl}
            optinsOperator={optinsOperator}
            onFormChange={onOptinsChange}
          />
        </div>
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-14 modal-window__footer-btn" type="button" onClick={onClose}>
            {`${LANGUAGE.RESULTS_BUTTON} (${results})`}
          </button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  results: selectLeadsTotalCount(state),
});

export default connect(mapStateToProps)(BcMailingFiltersModal);
