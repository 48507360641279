export default (dashboard) => {
  return {
    name: dashboard?.name || null,
    sharing:
      dashboard?.sharing_level?.reduce((acc, item) => {
        acc[item] = true;
        return acc;
      }, {}) || {},
  };
};
