import {updateInArray} from 'client/services/helpers';

import types from './types';

const initialState = {
  type: types.NO_DATA,
  payload: {
    scenarios: [],
    autotasks: [],
    operation: {},
  },
  validationLog: [],
  publicationWebAppLog: [],
  publicationDeviceLog: [],
};

export default function (_state = initialState, action) {
  let state = {..._state};

  switch (action.type) {
    case types.CLEAR_AUTOTASK_STATE:
      return initialState;

    case types.NO_DATA:
      state.type = types.NO_DATA;
      state.payload = initialState.payload;
      return state;

    case types.LOADED:
      state.type = types.LOADED;
      state.payload = {
        ...state.payload,
        ...action.payload.automation_task,
      };
      return state;

    case types.SCENARIO_CREATED:
      state.type = types.SCENARIO_CREATED;
      state.payload = {
        ...state.payload,
        scenarios: [action.payload.scenario, ...state.payload.scenarios],
      };

      return state;

    case types.SCENARIO_UPDATED:
      state.type = types.SCENARIO_UPDATED;
      state.payload = {
        ...state.payload,
        scenarios: updateInArray(state.payload.scenarios, action.payload.scenario),
      };
      return state;

    case types.GET_AUTOTASKS_SUCCESS:
      state.payload = {
        ...state.payload,
        autotasks: action.payload.automation_tasks,
      };
      return state;

    case types.PATCH_AUTOMATION_TASKS_SUCCESS:
      state.payload = {
        ...state.payload,
        autotasks: updateInArray(state.payload.autotasks, action.payload.automation_task),
      };

      return state;

    case types.GET_AUTOTASK_VALIDATION_LOG_SUCCESS:
      state.validationLog = action?.payload?.validated || initialState.validationLog;
      return state;

    case types.GET_AUTOTASK_PUBLICATION_WEB_APP_LOG_SUCCESS:
      state.publicationWebAppLog = action?.payload?.state || initialState.publicationWebAppLog;
      return state;

    case types.GET_AUTOTASK_PUBLICATION_DEVICE_LOG_SUCCESS:
      state.publicationDeviceLog = action?.payload?.device_state || initialState.publicationDeviceLog;
      return state;

    default:
      return state;
  }
}
