import React from 'react';

import PropTypes from 'prop-types';

import {NAV_GROUPS_MAP, NAV_LINKS} from 'client/common/config';

import NavigationItem from '../navigation-item';
import NavigationSubitem from '../navigation-subitem';

function NavigationAdminVersion({language, openedItem, isSuperAdmin, currentUrl}) {
  return (
    <div className="navigation__content">
      <div className="navigation__top">
        <NavigationItem
          to={NAV_LINKS.HOME}
          exact={true}
          dataNavItem={NAV_GROUPS_MAP.HOME}
          iconName="nav-home"
          name={language.HOME}
          colorTheme="1"
          className="navigation__item--no-icon-plus"
        />
        <NavigationItem
          dataNavItem="operations"
          iconName="nav-operations"
          name={language.OPERATIONS}
          colorTheme="2"
          opened={openedItem === NAV_GROUPS_MAP.OPERATIONS}
        >
          <NavigationSubitem
            dataChildOf={NAV_GROUPS_MAP.OPERATIONS}
            to={NAV_LINKS.OPERATIONS}
            exact={true}
            colorTheme="1"
            currentUrl={currentUrl}
          >
            {language.ALL_OPERATIONS}
          </NavigationSubitem>
          <NavigationSubitem
            dataChildOf={NAV_GROUPS_MAP.OPERATIONS}
            to={NAV_LINKS.OPERATIONS_MY}
            colorTheme="1"
            currentUrl={currentUrl}
          >
            {language.MY_OPERATIONS}
          </NavigationSubitem>
          {isSuperAdmin && (
            <NavigationSubitem
              dataChildOf={NAV_GROUPS_MAP.OPERATIONS}
              to={NAV_LINKS.OPERATIONS_RUN_TABLE}
              colorTheme="1"
              currentUrl={currentUrl}
            >
              {language.RUN_TABLE}
            </NavigationSubitem>
          )}
        </NavigationItem>
        <NavigationItem
          dataNavItem={NAV_GROUPS_MAP.CLIENTS}
          iconName="nav-clients"
          name={language.CLIENTS}
          colorTheme="3"
          opened={openedItem === NAV_GROUPS_MAP.CLIENTS}
        >
          <NavigationSubitem
            to={NAV_LINKS.CLIENTS}
            dataChildOf={NAV_GROUPS_MAP.CLIENTS}
            colorTheme="2"
            currentUrl={currentUrl}
          >
            {language.ALL_CLIENTS}
          </NavigationSubitem>
          <NavigationSubitem
            to={NAV_LINKS.CLIENTS_MY}
            dataChildOf={NAV_GROUPS_MAP.CLIENTS}
            colorTheme="2"
            currentUrl={currentUrl}
          >
            {language.MY_CLIENTS}
          </NavigationSubitem>
        </NavigationItem>
        <NavigationItem
          dataNavItem={NAV_GROUPS_MAP.DATABASES}
          iconName="nav-database"
          name={language.DATABASES}
          colorTheme="2"
          opened={openedItem === NAV_GROUPS_MAP.DATABASES}
        >
          <NavigationSubitem
            to={NAV_LINKS.LEADS}
            dataChildOf={NAV_GROUPS_MAP.DATABASES}
            colorTheme="3"
            currentUrl={currentUrl}
          >
            {language.LEADS}
          </NavigationSubitem>
          <NavigationSubitem
            to={NAV_LINKS.VISUALS}
            dataChildOf={NAV_GROUPS_MAP.DATABASES}
            colorTheme="4"
            currentUrl={currentUrl}
          >
            {language.VISUALS}
          </NavigationSubitem>
        </NavigationItem>
        <NavigationItem
          dataNavItem={NAV_GROUPS_MAP.REFERENCES}
          iconName="nav-references"
          name={language.REFERENCES}
          colorTheme="4"
          opened={openedItem === NAV_GROUPS_MAP.REFERENCES}
        >
          <NavigationSubitem
            to={NAV_LINKS.PLACES}
            dataChildOf={NAV_GROUPS_MAP.REFERENCES}
            colorTheme="5"
            currentUrl={currentUrl}
          >
            {language.PLACES}
          </NavigationSubitem>
          <NavigationSubitem
            to={NAV_LINKS.INTERFACE_TEMPLATES}
            dataChildOf={NAV_GROUPS_MAP.REFERENCES}
            colorTheme="6"
            currentUrl={currentUrl}
          >
            {language.TYPES_OF_INTERFACES}
          </NavigationSubitem>
          <NavigationSubitem
            to={NAV_LINKS.DEVICES}
            dataChildOf={NAV_GROUPS_MAP.REFERENCES}
            colorTheme="6"
            currentUrl={currentUrl}
          >
            {language.DEVICES}
          </NavigationSubitem>
          <NavigationSubitem
            to={NAV_LINKS.PERMANENT_SOURCES}
            dataChildOf={NAV_GROUPS_MAP.REFERENCES}
            colorTheme="7"
            currentUrl={currentUrl}
          >
            {language.PERMANENT_SOURCES}
          </NavigationSubitem>
          <NavigationSubitem
            to={NAV_LINKS.PRIVACY_POLICY}
            dataChildOf={NAV_GROUPS_MAP.REFERENCES}
            colorTheme="7"
            currentUrl={currentUrl}
          >
            {language.PRIVACY_POLICY}
          </NavigationSubitem>
          <NavigationSubitem
            to={NAV_LINKS.COOKIES_POLICY}
            dataChildOf={NAV_GROUPS_MAP.REFERENCES}
            colorTheme="7"
            currentUrl={currentUrl}
          >
            {language.COOKIES_POLICY}
          </NavigationSubitem>
          {isSuperAdmin && (
            <NavigationSubitem
              to={NAV_LINKS.TRANSLATIONS}
              dataChildOf={NAV_GROUPS_MAP.REFERENCES}
              colorTheme="7"
              currentUrl={currentUrl}
            >
              {language.TRANSLATIONS}
            </NavigationSubitem>
          )}
        </NavigationItem>
      </div>
      {isSuperAdmin && (
        <div className="navigation__bottom">
          <NavigationItem
            exact={true}
            to={NAV_LINKS.ADMINS_LIST}
            dataNavItem={NAV_GROUPS_MAP.ADMINS}
            iconName="nav-admin"
            name={language.ADMINISTRATORS}
            colorTheme="1"
            className="navigation__item--no-icon-plus"
          />
          <NavigationItem
            to={NAV_LINKS.PARAMETERS}
            exact={true}
            dataNavItem={NAV_GROUPS_MAP.PARAMETERS}
            iconName="nav-settings"
            name={language.PARAMETERS}
            colorTheme="1"
            className="navigation__item--no-icon-plus"
          />
        </div>
      )}
    </div>
  );
}

NavigationAdminVersion.propTypes = {
  language: PropTypes.object,
  openedItem: PropTypes.string,
  isSuperAdmin: PropTypes.bool,
  currentUrl: PropTypes.string,
};

NavigationAdminVersion.defaultProps = {
  language: {},
  openedItem: '',
  isSuperAdmin: true,
  currentUrl: '',
};

export default NavigationAdminVersion;
