export default {
  GET_WINNER_EXPORT_REPORTS_REQUEST: '@@winner-export/GET_WINNER_EXPORT_REPORTS_REQUEST',
  GET_WINNER_EXPORT_REPORTS_SUCCESS: '@@winner-export/GET_WINNER_EXPORT_REPORTS_SUCCESS',
  GET_WINNER_EXPORT_REPORTS_ERROR: '@@winner-export/GET_WINNER_EXPORT_REPORTS_ERROR',

  DELETE_FILE_WINNER_EXPORT_REQUEST: '@@winner-export/DELETE_FILE_WINNER_EXPORT_REQUEST',
  DELETE_FILE_WINNER_EXPORT_SUCCESS: '@@winner-export/DELETE_FILE_WINNER_EXPORT_SUCCESS',
  DELETE_FILE_WINNER_EXPORT_ERROR: '@@winner-export/DELETE_FILE_WINNER_EXPORT_ERROR',

  GET_WINNER_EXPORT_REPORT_REQUEST: '@@winner-export/GET_WINNER_EXPORT_REPORT_REQUEST',
  GET_WINNER_EXPORT_REPORT_SUCCESS: '@@winner-export/GET_WINNER_EXPORT_REPORT_SUCCESS',
  GET_WINNER_EXPORT_REPORT_ERROR: '@@winner-export/GET_WINNER_EXPORT_REPORT_ERROR',

  SHARE_WINNER_EXPORT_REQUEST: '@@winner-export/SHARE_WINNER_EXPORT_REQUEST',
  SHARE_WINNER_EXPORT_SUCCESS: '@@winner-export/SHARE_WINNER_EXPORT_SUCCESS',
  SHARE_WINNER_EXPORT_ERROR: '@@winner-export/SHARE_WINNER_EXPORT_ERROR',

  PERFORM_FILE_WINNER_EXPORT_REQUEST: '@@winnings/PERFORM_FILE_WINNER_EXPORT_REQUEST',
  PERFORM_FILE_WINNER_EXPORT_SUCCESS: '@@winnings/PERFORM_FILE_WINNER_EXPORT_SUCCESS',
  PERFORM_FILE_WINNER_EXPORT_ERROR: '@@winnings/PERFORM_FILE_WINNER_EXPORT_ERROR',

  FILE_WINNER_EXPORT_REQUEST: '@@winnings/FILE_WINNER_EXPORT_REQUEST',
  FILE_WINNER_EXPORT_SUCCESS: '@@winnings/FILE_WINNER_EXPORT_SUCCESS',
  FILE_WINNER_EXPORT_ERROR: '@@winnings/FILE_WINNER_EXPORT_ERROR',
};
