import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

import './leads-import-type-modal.scss';

class LeadsImportTypeModal extends Component {
  static formName = 'LeadsImportFormModal';

  static defaultProps = {
    show: false,
  };

  static propTypes = {
    show: PropTypes.bool,
    isAdmin: PropTypes.bool,
    lang: PropTypes.object.isRequired,
    modals: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.props.initialize({import: this.props.modals.GENERATE_IMPORT_MODAL});
    }
  }

  render() {
    const {onClose, show, lang} = this.props;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-2 leads-import-type-modal"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TITLE}
      >
        <div className="theme-color-2">
          <FormFieldWrap>
            <Field
              label={<span className="main-text">{lang.GENERATE_LABEL}</span>}
              name="import"
              type="radio"
              value={this.props.modals.GENERATE_IMPORT_MODAL}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              label={<span className="main-text">{lang.IMPORT_LABEL}</span>}
              name="import"
              type="radio"
              value={this.props.modals.IMPORT_FILE_MODAL}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          {this.props.isAdmin && (
            <FormFieldWrap>
              <Field
                label={<span className="main-text">{lang.JSON_LABEL}</span>}
                name="import"
                type="radio"
                value={this.props.modals.GENERATE_API_IMPORT_MODAL}
                component={fieldTemplate}
              />
            </FormFieldWrap>
          )}
        </div>
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-11 modal-window__footer-btn" onClick={this.props.onClose}>
            {lang.CANCEL_BUTTON}
          </button>
          <button className="button button--bg-2 modal-window__footer-btn" onClick={this.props.onConfirm}>
            {lang.NEXT_BUTTON}
          </button>
        </div>
      </Modal>
    );
  }
}

const LeadsImportFormModal = reduxForm({
  form: LeadsImportTypeModal.formName,
})(LeadsImportTypeModal);

export default connect((state) => {
  return {
    lang: state.languageState.payload.LEADS_PORT.IMPORT_TYPE_MODAL,
  };
})(LeadsImportFormModal);
