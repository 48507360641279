import React from 'react';

import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import partialRight from 'lodash/partialRight';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';

import {getManualVisualGroups} from 'client/ducks/groups/actions';
import {getOperations} from 'client/ducks/operations/actions';
import {getTaskIndex} from 'client/ducks/task-index-items/actions';
import {selectTaskIndexItemsMapped} from 'client/ducks/task-index-items/selectors';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import Modal from 'client/common/modals/modal';

// components
import AsyncDropdownAny from 'client/components/common/async-dropdown-any';
import FormFieldWrap from 'client/components/common/form-field-wrap';

import {mapFilter} from 'client/components/visuals/visuals-client/helpers';
import {TASK_TYPES} from 'client/models/operations/constants';

import VisFiltersForm from '../../forms/vis-filters-form';

import './vis-filters-client-modal.scss';

class VisFiltersClientModal extends ReactQueryParams {
  static propTypes = {
    show: PropTypes.bool,
    initialize: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    langStatuses: PropTypes.object.isRequired,
    langVisibleStatuses: PropTypes.object.isRequired,
    langOrientations: PropTypes.object.isRequired,
    updateMe: PropTypes.func.isRequired,
    getOperations: PropTypes.func.isRequired,
    getManualVisualGroups: PropTypes.func.isRequired,
    allowedFilterGroups: PropTypes.array,
    operations: PropTypes.array.isRequired,
    manualGroups: PropTypes.array.isRequired,
    totalItems: PropTypes.number,
    clientId: PropTypes.number,
    isAdmin: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    show: false,
    totalItems: 0,
    clientId: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      operations: [],
      manualGroups: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.initFilters(this.queryParams.filters);
    }
  }

  initFilters = (filters = {}) => {
    return this.props.initialize(
      Object.keys(filters)
        .map((k) => (filters[k].map ? filters[k].map((v) => `${k}$${v}`) : [filters[k]].map((v) => `${k}$${v}`)))
        .reduce((arr, i) => arr.concat(i), [])
        .reduce((obj, i) => ({...obj, [i]: true}), {}),
    );
  };

  getOperationsOptions = async (search = '') => {
    if (!search) {
      return [];
    }

    await this.props.getOperations({
      q: {
        visuals_count_gt: 0,
        name_cont: search,
        client_id_eq: this.props.clientId,
      },
    });

    return this.props.operations.map(partialRight(pick, ['id', 'name']));
  };

  getGroupsOptions = async (search = '') => {
    if (!search) {
      return [];
    }

    await this.props.getManualVisualGroups({
      q: {
        name_cont: search,
      },
    });

    return this.props.manualGroups.map(partialRight(pick, ['id', 'name']));
  };

  getTasksOptions = async (search = '') => {
    if (!search) {
      return [];
    }

    const {filters = {}} = this.queryParams;

    await this.props.getTaskIndex({
      include: 'task',
      q: {
        task_type_in: [TASK_TYPES.AUTOMATION],
        operation_id_in: map(filters.operation_in, 'id'),
        g: {
          0: {
            m: 'or',
            task_of_AutomationTask_type_name_cont: search,
          },
        },
      },
    });

    return this.props.taskIndex.map(partialRight(pick, ['id', 'name', 'type', 'operationId']));
  };

  handleCheckFilterDropdown = (filtersKey) => (selected) => {
    const filters = {
      ...this.queryParams.filters,
    };

    if (!selected || isEmpty(selected)) {
      delete filters[filtersKey];
    } else {
      filters[filtersKey] = selected;
    }

    if (filtersKey === 'operation_in') {
      if (!filters.operation_in) {
        delete filters.task_in;
      } else if (Array.isArray(filters.task_in)) {
        filters.task_in = filters.task_in.filter(({operationId}) => {
          return filters.operation_in.find(({id}) => id === operationId);
        });
      }
    }

    this.setQueryParams(
      {
        filters: mapFilter(filters),
      },
      true,
    );
    this.props.updateMe();
  };

  render() {
    const {show, onClose, updateMe, totalItems, isAdmin, lang, langStatuses, langVisibleStatuses, langOrientations} =
      this.props;

    const {filters = {}} = this.queryParams;
    const {operation_in: selectedOperations, group_in: selectedGroups, task_in: selectedTasks} = filters;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-13 vis-filters-client-modal"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TITLE}
      >
        <form>
          <FormFieldWrap>
            <AsyncDropdownAny
              className="select--view-2"
              getOptions={this.getOperationsOptions}
              label={lang.OPERATIONS_LABEL}
              value={selectedOperations}
              valueKey="id"
              labelKey="name"
              onChange={this.handleCheckFilterDropdown('operation_in')}
            />
          </FormFieldWrap>
          {Array.isArray(selectedOperations) && selectedOperations.length > 0 && (
            <FormFieldWrap>
              <AsyncDropdownAny
                className="select--view-2"
                getOptions={this.getTasksOptions}
                label={lang.TASKS_LABEL}
                value={selectedTasks}
                valueKey="id"
                labelKey="name"
                onChange={this.handleCheckFilterDropdown('task_in')}
              />
            </FormFieldWrap>
          )}
          <FormFieldWrap>
            <AsyncDropdownAny
              className="select--view-2"
              getOptions={this.getGroupsOptions}
              label={lang.GROUPS_LABEL}
              value={selectedGroups}
              valueKey="id"
              labelKey="name"
              onChange={this.handleCheckFilterDropdown('group_in')}
            />
          </FormFieldWrap>
          <VisFiltersForm
            initFilters={this.initFilters}
            updateMe={updateMe}
            isAdmin={isAdmin}
            lang={lang}
            langStatuses={langStatuses}
            langVisibleStatuses={langVisibleStatuses}
            langOrientations={langOrientations}
          />
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-8 modal-window__footer-btn" type="button" onClick={onClose}>
              {`${lang.RESULTS_BUTTON} (${totalItems})`}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const VisFiltersClientModalForm = reduxForm({
  form: 'VisFiltersClientModalForm',
})(VisFiltersClientModal);

export default connect(
  ({languageState, operations, groups, ...state}) => ({
    lang: languageState.payload.VISUALS.FILTERS_MODAL,
    langStatuses: languageState.payload.VISUALS.PREVIEW_MODAL.STATUSES,
    langVisibleStatuses: languageState.payload.VISUALS.PREVIEW_MODAL.VISIBLE_STATUSES,
    langOrientations: languageState.payload.VISUALS.TABLE.ORIENTATIONS,
    operations: operations.operations,
    manualGroups: groups.manualGroups,
    taskIndex: selectTaskIndexItemsMapped(state),
    isAdmin: selectIsAdmin(state),
  }),
  {
    getTaskIndex,
    getOperations,
    getManualVisualGroups,
  },
)(VisFiltersClientModalForm);
