import types from './types';

const initialState = {
  type: types.NO_DATA,
  payload: {},
  importFile: {},
  importResult: {},
  leadsReports: [],
};

export default function (_state = initialState, action) {
  let state = {..._state};

  switch (action.type) {
    case types.NO_DATA:
      state.type = types.NO_DATA;
      state.payload = initialState.payload;
      return state;

    case types.LEAD_PROFILE_SUCCESS:
      state.type = types.LEAD_PROFILE_SUCCESS;
      state.payload = action.payload.lead;
      return state;

    case types.LEAD_PROFILE_ERROR:
      state.type = types.LEAD_PROFILE_ERROR;
      state.payload = action.payload.response;
      return state;

    case types.GET_IMPORT_FILE_SUCCESS:
      state.type = types.GET_IMPORT_FILE_SUCCESS;
      state.importFile = action.payload;
      return state;

    case types.IMPORT_FILE_SUCCESS:
      state.type = types.IMPORT_FILE_SUCCESS;
      state.importResult = action.payload;
      return state;

    case types.GET_LEADS_REPORTS_SUCCESS:
      state.type = types.GET_LEADS_REPORTS_SUCCESS;
      state.leadsReports = action.payload.lead_transfer_reports;
      return state;

    default:
      return state;
  }
}
