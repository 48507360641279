import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {selectUserMemberships} from 'client/ducks/user-clients/selectors';
import {isFirstClientLogin} from 'client/ducks/user/helper';
import {selectCurrentUser} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';

import './welcome.scss';

class Welcome extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    membershipsCount: PropTypes.number.isRequired,
    ...withRouter.propTypes,
  };

  componentDidUpdate() {
    const {currentUser, membershipsCount} = this.props;

    if (!isFirstClientLogin(currentUser) || membershipsCount === 1) {
      this.props.history.push(CLIENT_PAGES.HOME);
    }
  }

  render() {
    const {lang, currentUser, membershipsCount} = this.props;

    return (
      <div className="welcome">
        {isFirstClientLogin(currentUser) && membershipsCount > 1 && (
          <div className="welcome__select-company">{lang.SELECT_COMPANY}</div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect((state) => ({
    lang: state.languageState.payload.WELCOME,
    currentUser: selectCurrentUser(state),
    membershipsCount: Object.keys(selectUserMemberships(state)).length,
  }))(Welcome),
);
