import React, {useCallback, useEffect} from 'react';

import PropTypes from 'prop-types';
import {connect, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {reduxForm, SubmissionError} from 'redux-form';

import bem from 'client/services/bem';
import {useQueryParams, useAppMedia, useLanguage} from 'client/services/hooks';

import {authenticate, updatePassword} from 'client/ducks/user/actions';

import {CLIENT_PAGES} from 'client/common/config';
import {FieldWrap} from 'client/common/fields';

import validate, {warn} from './validate';

import AuthButton from '../../components/auth-button';
import AuthError from '../../components/auth-error';
import AuthField from '../../components/auth-field';

import cssModule from './auth-change-password-form.module.scss';

const b = bem('auth-change-password-form', {cssModule});

const AuthChangePasswordForm = ({submitting, submitFailed, handleSubmit, error, pristine, valid, isLinkExpired}) => {
  const lang = useLanguage('AUTH.CHANGE_PASSWORD');

  const history = useHistory();

  const dispatch = useDispatch();
  const [queryParams] = useQueryParams({history, location: history.location});
  const {isTablet} = useAppMedia();
  const {email, reset_password_token: resetPasswordToken} = queryParams;

  useEffect(() => {
    if (isLinkExpired) {
      history.replace(history.location.pathname + history.location.search, {expired: isLinkExpired});
    }
  }, [history, isLinkExpired]);

  const changePassword = useCallback(
    ({password, passwordConfirmation, rememberMe}) => {
      return dispatch(
        updatePassword(
          {
            user: {
              email,
              reset_password_token: resetPasswordToken,
              password,
              password_confirmation: passwordConfirmation,
            },
          },
          {user: {email, password, rememberMe}},
        ),
      ).then((res) => {
        if (res.error) {
          let responseError = '';
          if (res.payload?.response?.errors?.password) {
            responseError = lang.PASSWORD_EXIST;
          }

          throw new SubmissionError({_error: responseError});
        }
        dispatch(authenticate({user: {email, password, rememberMe}})).then(() => {
          history.push(CLIENT_PAGES.HOME);
        });
      });
    },
    [dispatch, email, history, lang.PASSWORD_EXIST, resetPasswordToken],
  );

  const handleRequestNewLink = () => {
    history.push(CLIENT_PAGES.PASSWORD_RECOVERY, {expired: true});
  };

  return (
    <form noValidate="noValidate" onSubmit={handleSubmit(changePassword)}>
      {submitFailed && (
        <FieldWrap>
          <AuthError>{error}</AuthError>
        </FieldWrap>
      )}
      {isLinkExpired ? (
        <>
          <p className={b('link-expired-message')}>{lang.LINK_EXPIRED}</p>
          <AuthButton color="clients" label={lang.REQUEST_NEW_LINK} onClick={handleRequestNewLink} />
        </>
      ) : (
        <>
          <AuthField label={lang.EMAIL_LABEL} value={email} type="text-input" name="email" disabled email />
          <AuthField
            tabIndex={1}
            name="password"
            type="password"
            helpOverlay={lang.POPOVER}
            label={lang.PASSWORD_LABEL}
            placeholder={lang.PASSWORD_PLACEHOLDER}
          />
          <AuthField
            tabIndex={2}
            type="password"
            helpOverlay={!isTablet ? lang.POPOVER : ''}
            name="passwordConfirmation"
            label={lang.CONFIRMATION_LABEL}
            placeholder={lang.CONFIRMATION_PLACEHOLDER}
          />
          <AuthField type="checkbox" tabIndex={3} additional name="rememberMe" label={lang.REMEMBER_ME_LABEL} />
          <AuthButton submit disabled={submitting || !valid || pristine} label={lang.CHANGE_BUTTON} />
        </>
      )}
    </form>
  );
};

AuthChangePasswordForm.displayName = 'AuthChangePasswordForm';
AuthChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  error: PropTypes.any,
  isLinkExpired: PropTypes.bool,
};

const changePassword = reduxForm({
  form: 'AuthChangePasswordForm',
  validate,
  warn,
})(AuthChangePasswordForm);

export default connect((state) => ({
  lang: state.languageState.payload.AUTH.CHANGE_PASSWORD,
}))(changePassword);
