import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {TranslationJsx} from 'client/models/language/types';

import './bc-sms-result-vars-modal.scss';

function BCSMSResultClicksModal(props) {
  const {languageState, onClose, show, messageText} = props;
  const LANGUAGE = languageState.payload.BROADCASTING_TASK.RESULT_VARS_MODAL;
  const html = BCSMSResultClicksModal.createHtml(messageText);

  return (
    <Modal show={show} onClose={onClose} title={LANGUAGE.TITLE} dialogClassName="modal-window--width-1 theme-color-16">
      {messageText && (
        <div className="content-group bc-sms-result-vars-modal__content">
          <CustomScrollbars scrollbarProps={{autoHeight: false}}>
            <div className=" break-word custom-scrollbars__scroll-content" dangerouslySetInnerHTML={{__html: html}} />
          </CustomScrollbars>
        </div>
      )}
      <div className="modal-window__footer modal-window__footer--centered">
        <button className="button button--bg-14 modal-window__footer-btn" onClick={onClose}>
          {LANGUAGE.CONFIRM_BUTTON}
        </button>
      </div>
    </Modal>
  );
}

BCSMSResultClicksModal.createHtml = (text) => {
  const regex = /{{([^{}]+)}}/g;

  return text.replace(regex, (str, name) => {
    const result = `<span class="theme-color-16">{{${name}}}</span>`;

    return result;
  });
};

BCSMSResultClicksModal.propTypes = {
  show: PropTypes.bool,
  messageText: TranslationJsx,
  languageState: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

BCSMSResultClicksModal.defaultProps = {
  show: false,
};

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

export default connect(mapStateToProps)(BCSMSResultClicksModal);
