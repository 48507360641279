import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {required} from 'client/services/validator';

import {patchClient} from 'client/ducks/clients-list/actions';
// import {selectSubsidiaries} from 'client/ducks/subsidiaries/selectors';
import {patchSocialAccountsBulk} from 'client/ducks/social-accounts/actions';
import {getSubsidiaries} from 'client/ducks/subsidiaries/actions';

import {SOCIAL_ACCOUNT_TYPES, SOCIAL_ICONS} from 'client/common/config';
import Icon from 'client/common/icon';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';

import AddNewFacebookAccountModal from '../../modals/add-new-facebook-account-modal';
import AddNewSocialAccountModal from '../../modals/add-new-social-account-modal';
import AddNewTwitterAccountModal from '../../modals/add-new-twitter-account-modal';
import SelectSubsidiaryModal from '../../modals/select-subsidiary-modal';

import './social-network-accounts-list.scss';
import './social-network-accounts-modals.scss';

class SocialNetworkAccountsList extends Component {
  static propTypes = {
    patchSocialAccountsBulk: PropTypes.func.isRequired,
    withSubsidiaries: PropTypes.bool.isRequired,
    getSubsidiaries: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    subsidiaries: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isViewMoveOn: PropTypes.bool.isRequired,
    patchClient: PropTypes.func.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    onAddClick: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    formValues: PropTypes.object,
    clientId: PropTypes.number,
    submitting: PropTypes.bool,
    editMode: PropTypes.bool,
    allowEdit: PropTypes.bool,
    data: PropTypes.array,
    fullHeight: PropTypes.bool,
  };

  static defaultProps = {
    data: [],
    editMode: false,
    submitting: false,
    clientId: 0,
    formValues: {},
    isAdmin: false,
  };

  static formName = 'SocialNetworkAccountsForm';

  static modals = {
    [SOCIAL_ACCOUNT_TYPES.FACEBOOK]: 'ADD_FB_ACCOUNT',
    [SOCIAL_ACCOUNT_TYPES.TWITTER]: 'ADD_TWITTER_ACCOUNT',
    DELETE_ACCOUNT: 'DELETE_ACCOUNT',
    SELECT_ACCOUNT: 'SELECT_ACCOUNT',
    SELECT_SUBSIDIARY: 'SELECT_SUBSIDIARY',
    PRIVACY_POLICY_THIRD_PARTY: 'PRIVACY_POLICY_THIRD_PARTY',
  };

  static LIST_HEIGHT_BY_SUBSIDIARIES = {
    true: 335,
    false: 122,
  };

  static LIST_HEIGHT_BY_SUBSIDIARIES_CLIENT = {
    true: 245,
    false: 100,
  };

  static mapData(accounts, values) {
    const socialAccounts = accounts.map((account, index) => {
      if (account.new) {
        return {
          ...account,
          name: values[`name${index}`],
        };
      }

      return {
        ...account,
        name: values[`name${index}`],
        subsidiary_id: values[`subsidiary_id${index}`],
        _destroy: account.deleted,
      };
    });

    return {
      social_account: socialAccounts,
    };
  }

  constructor(props) {
    super(props);

    const {lang} = this.props;

    this.state = {
      accounts: [],
      deletingIndex: 0,

      activeModal: null,
    };

    this.rules = {
      name: required(lang.NAME_IS_REQUIRED_ERROR),
      subsidiary: required(lang.SUBSIDIARY_IS_REQUIRED_ERROR),
    };
  }

  componentDidMount() {
    this.applyNewData(this.props.data);

    if (this.props.withSubsidiaries) {
      this.props.getSubsidiaries();
    }
  }

  componentDidUpdate({data, editMode}) {
    if (this.props.editMode && !editMode) {
      return;
    }
    if (this.props.data !== data) {
      this.applyNewData(this.props.data);
    }
  }

  handleOpenModal =
    (activeModal, additionalState = {}) =>
    () => {
      this.setState({activeModal, ...additionalState});
    };

  handleCloseModal =
    (additionalState = {}, callback) =>
    () => {
      this.setState({activeModal: null, ...additionalState}, callback);
    };

  applyNewData = (accounts) => {
    this.setState({accounts});

    this.props.initialize(
      accounts.reduce((init, item, index) => {
        init[`name${index}`] = item.name;
        init[`subsidiary_id${index}`] = item.subsidiary_id;
        return init;
      }, {}),
    );
  };

  handleSelectAccount = (type) => this.handleOpenModal(SocialNetworkAccountsList.modals[type])();

  handleSelectSubsidiary = (subsidiary_id) =>
    this.handleOpenModal(SocialNetworkAccountsList.modals.SELECT_ACCOUNT, {subsidiary_id})();

  handleSocialAccountAdded = (type) => (data) => {
    const {subsidiary_id} = this.state;
    const accounts = this.state.accounts.slice();

    if (this.props.withSubsidiaries) {
      accounts.push({type, subsidiary_id, ...data});
    } else {
      accounts.push({type, client_id: this.props.clientId, ...data, new: true});
    }

    this.props.onAddClick();

    this.handleCloseModal({accounts}, () => {
      if (this.scrollbarRef) {
        this.scrollbarRef.scrollToBottom();
      }
      this.props.change('name' + (accounts.length - 1), data.name);
      this.props.change('subsidiary_id' + (accounts.length - 1), subsidiary_id);
    })();
  };

  handleDeleteItem = () => {
    const accounts = this.state.accounts.slice();
    const account = accounts[this.state.deletingIndex];
    if (account.id) {
      account.deleted = true;
    } else {
      accounts.splice(this.state.deletingIndex, 1);
    }
    this.handleCloseModal({accounts})();
    this.applyNewData(accounts);
  };

  handleCancelClick = () => {
    this.applyNewData(this.props.data);
    this.props.onCancelClick();
  };

  handleAddAccountClick = () => {
    const modalType = SocialNetworkAccountsList.modals.SELECT_ACCOUNT;
    return this.handleOpenModal(modalType)();
  };

  handlePolicyAcceptClick = () => {
    const modalType = this.props.withSubsidiaries
      ? SocialNetworkAccountsList.modals.SELECT_SUBSIDIARY
      : SocialNetworkAccountsList.modals.SELECT_ACCOUNT;

    return this.handleOpenModal(modalType)();
  };

  save = (values) => {
    const {onSaveClick, lang} = this.props;

    return this.props
      .patchSocialAccountsBulk(SocialNetworkAccountsList.mapData(this.state.accounts, values))
      .then(({payload: {response: {errors} = {}}}) => {
        if (errors) {
          const submitErrors = {};
          for (let i in errors) {
            if (errors[i].name) {
              submitErrors['name' + i] = lang.NAME_IS_TAKEN_ERROR;
            }
          }
          throw new SubmissionError(submitErrors);
        }
        onSaveClick();
      });
  };

  canEditAccount = (account) => {
    if (this.props.isAdmin) {
      return !account.client_id || !account.created_by_client;
    }

    return true;
  };

  getSocialAccountsNames = () => {
    return Object.values(this.props.formValues.values || {});
  };

  renderName = (account, key) => {
    const {lang} = this.props;

    return (
      <Field
        name={`name${key}`}
        type="text"
        cssModifier="input--disabled-view-2 social-network-accounts-list__item__name"
        component={fieldTemplate}
        placeholder={lang.NAME_PLACEHOLDER}
        validate={this.rules.name}
        disabled={!this.canEditAccount(account)}
      />
    );
  };

  renderSubsidiary = (account, key) => {
    const {lang} = this.props;

    return (
      <Field
        name={`subsidiary_id${key}`}
        type="select"
        cssModifier="select--disabled-view-2 social-network-accounts-list__item__name"
        component={fieldTemplate}
        placeholder={lang.SUBSIDIARY_PLACEHOLDER}
        simpleValue
        options={this.props.subsidiaries}
        valueKey="id"
        labelKey="name"
        validate={this.rules.subsidiary}
      />
    );
  };

  renderRemoveButton = (account, key) => {
    return (
      <button
        type="button"
        className="social-network-accounts-list__input-btn"
        onClick={this.handleOpenModal(SocialNetworkAccountsList.modals.DELETE_ACCOUNT, {deletingIndex: key})}
        disabled={!this.canEditAccount(account)}
      >
        <Icon name="trash" className="social-network-accounts-list__delete-icon" width={17} height={19} />
      </button>
    );
  };

  renderAccountType = (account, isAdmin) => {
    const userRole = isAdmin ? 'admin' : 'user';
    const iconName =
      account.type === SOCIAL_ACCOUNT_TYPES.FACEBOOK ? SOCIAL_ICONS[userRole].FACEBOOK : SOCIAL_ICONS[userRole].TWITTER;

    return <Icon name={iconName} className="pull-right social-icon" width={14} height={14} />;
  };

  render() {
    const {handleSubmit, submitting, subsidiaries, withSubsidiaries, lang, isAdmin, isViewMoveOn, fullHeight} =
      this.props;
    const {accounts} = this.state;

    const mainClassName = cn('social-network-accounts-list', 'social-network-accounts-list--expand', {
      'social-network-accounts-list--empty': !this.state.accounts.length,
      'social-network-accounts-list--full-height': fullHeight,
      'social-network-accounts-list--with-subsidiaries': withSubsidiaries,
    });

    return (
      <div className={mainClassName}>
        <SelectSubsidiaryModal
          className={isAdmin ? 'modal-window--theme-8' : 'social-network-accounts-modals'}
          buttonConfirmClass={isAdmin ? 'button--bg-8' : 'button-client--primary'}
          show={this.state.activeModal === SocialNetworkAccountsList.modals.SELECT_SUBSIDIARY}
          subsidiaries={subsidiaries}
          onConfirm={this.handleSelectSubsidiary}
          onClose={this.handleCloseModal()}
        />

        <AddNewSocialAccountModal
          className={isAdmin ? 'modal-window--theme-8' : 'social-network-accounts-modals'}
          buttonConfirmClass={isAdmin ? 'button--bg-8' : 'button-client--primary'}
          radioColor={isAdmin ? 'visuals' : 'primary'}
          show={this.state.activeModal === SocialNetworkAccountsList.modals.SELECT_ACCOUNT}
          onConfirm={this.handleSelectAccount}
          onCancel={withSubsidiaries ? this.handlePolicyAcceptClick : this.handleCloseModal()}
          onClose={this.handleCloseModal()}
          isAdmin={isAdmin}
        />

        <AddNewFacebookAccountModal
          className={isAdmin ? 'modal-window--theme-8' : 'social-network-accounts-modals'}
          buttonConfirmClass={isAdmin ? 'button--bg-8' : 'button-client--primary'}
          show={this.state.activeModal === SocialNetworkAccountsList.modals[SOCIAL_ACCOUNT_TYPES.FACEBOOK]}
          onConfirm={this.handleSocialAccountAdded(SOCIAL_ACCOUNT_TYPES.FACEBOOK)}
          onCancel={() => this.handleSelectSubsidiary(this.state.subsidiary_id)}
          onClose={this.handleCloseModal()}
          isAdmin={isAdmin}
        />

        <AddNewTwitterAccountModal
          className={isAdmin ? 'modal-window--theme-8' : 'social-network-accounts-modals'}
          buttonConfirmClass={isAdmin ? 'button--bg-8' : 'button-client--primary'}
          show={this.state.activeModal === SocialNetworkAccountsList.modals[SOCIAL_ACCOUNT_TYPES.TWITTER]}
          onConfirm={this.handleSocialAccountAdded(SOCIAL_ACCOUNT_TYPES.TWITTER)}
          onCancel={() => this.handleSelectSubsidiary(this.state.subsidiary_id)}
          onClose={this.handleCloseModal()}
          socialAccountsNames={this.getSocialAccountsNames()}
          isAdmin={isAdmin}
          lang={lang}
        />

        <ConfirmationModal
          className={isAdmin ? 'modal-window--theme-8' : 'social-network-accounts-modals'}
          buttonCancelClass={isAdmin ? 'button--bg-11' : 'button-client--primary'}
          buttonConfirmClass={isAdmin ? 'button--bg-8' : 'button-client--primary'}
          show={this.state.activeModal === SocialNetworkAccountsList.modals.DELETE_ACCOUNT}
          onClose={this.handleCloseModal()}
          onCancel={this.handleCloseModal()}
          title={lang.DELETE_ACCOUNT_MODAL.TITLE}
          message={lang.DELETE_ACCOUNT_MODAL.BODY_TEXT}
          cancelText={lang.CANCEL_BUTTON}
          confirmText={lang.DELETE_ACCOUNT_MODAL.CONFIRM}
          onConfirm={this.handleDeleteItem}
        />

        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          <div className="social-network-accounts-list__list">
            {accounts.length === 0 ? (
              <span className="social-network-accounts-list__empty">{lang.NO_ACCOUNTS}</span>
            ) : (
              <CustomScrollbars
                viewCssClass={isAdmin ? 'social-network-accounts-list__scrollbars' : ''}
                scrollbarProps={{
                  autoHeightMax: isAdmin
                    ? SocialNetworkAccountsList.LIST_HEIGHT_BY_SUBSIDIARIES[withSubsidiaries]
                    : SocialNetworkAccountsList.LIST_HEIGHT_BY_SUBSIDIARIES_CLIENT[fullHeight],
                  autoHeightMin: isAdmin
                    ? SocialNetworkAccountsList.LIST_HEIGHT_BY_SUBSIDIARIES[withSubsidiaries]
                    : SocialNetworkAccountsList.LIST_HEIGHT_BY_SUBSIDIARIES_CLIENT[fullHeight],
                  ref: (scrollbar) => {
                    this.scrollbarRef = scrollbar;
                  },
                }}
              >
                <div className="social-network-accounts-list__list-content">
                  {accounts.map(
                    (account, key) =>
                      !account.deleted && (
                        <div className="social-network-accounts-list__item" key={key}>
                          {this.renderName(account, key)}
                          {withSubsidiaries && this.renderSubsidiary(account, key)}
                          {account.id && account.created_by_client && <div className="client-marker">C</div>}
                          {this.renderAccountType(account, isAdmin)}
                          {this.renderRemoveButton(account, key)}
                        </div>
                      ),
                  )}
                </div>
              </CustomScrollbars>
            )}
          </div>
          <div className="social-network-accounts-list__bottom">
            {this.props.allowEdit && (
              <button
                className="button button--bg-8 button--full-width social-network-accounts-list__add-btn"
                onClick={this.handleAddAccountClick}
                disabled={isViewMoveOn}
                type="button"
              >
                <Icon name="plus" className="button__icon" />
                <span>{lang.ADD_ACCOUNT_BUTTON}</span>
              </button>
            )}
            {this.props.editMode && this.props.allowEdit && (
              <div className="social-network-accounts-list__action-btns">
                <button
                  className="button button--bg-11 social-network-accounts-list__action-btn"
                  type="button"
                  onClick={this.handleCancelClick}
                >
                  {lang.CANCEL_BUTTON}
                </button>
                <button
                  className="button button--bg-8 social-network-accounts-list__action-btn"
                  type="submit"
                  disabled={submitting}
                >
                  {lang.SAVE_BUTTON}
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const SocialNetworkAccountsForm = reduxForm({
  form: SocialNetworkAccountsList.formName,
})(SocialNetworkAccountsList);

const mapDispatchToProps = {
  patchSocialAccountsBulk,
  getSubsidiaries,
  patchClient,
};

const languageSet = ({
  form,
  subsidiaries,
  isAdmin,
  languageState: {
    payload: {AGENCY, ACCOUNT_PARAMETERS},
  },
}) => ({
  formValues: form[SocialNetworkAccountsList.formName],
  subsidiaries: subsidiaries.payload,
  lang: isAdmin ? AGENCY.SOCIAL_NETWORK_ACCOUNTS_CARD : ACCOUNT_PARAMETERS.SOCIAL_NETWORK,
});

export default connect(languageSet, mapDispatchToProps)(SocialNetworkAccountsForm);
