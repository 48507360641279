import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {formatNumberSpace} from 'client/services/formatters';
import {dateToString} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import './prize-diy-coupon-import-info.scss';

const b = bem('prize-diy-coupon-import-info');

const PrizeDiyCouponImportInfo = (props) => {
  const {data} = props;
  const lang = useLanguage('PRIZES.ELEMENTS.PRIZE_DIY_COUPON_IMPORT_INFO');

  return (
    <div className={b()}>
      <p className={b('line')}>
        <a className={b('link')} href={data.url} target="_blank" rel="noreferrer">
          {data.name}
        </a>
      </p>
      <p className={b('line')}>
        {formatNumberSpace(data.tokens_imported)} {lang.TOKENS}
      </p>
      <p className={b('line')}>
        {lang.UPLOADED} {dateToString(data.uploaded_at)}
      </p>
    </div>
  );
};

PrizeDiyCouponImportInfo.propTypes = {
  data: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tokens_imported: PropTypes.number.isRequired,
    tokens_used: PropTypes.number.isRequired,
    uploaded_at: PropTypes.string.isRequired,
  }).isRequired,
};

export default PrizeDiyCouponImportInfo;
