import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {selectLead} from 'client/ducks/lead/selectors';
import {selectIsAdmin, selectIsUserLoaded} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import LeadsProfile from '../components/leads/leads-profile';

class LeadsProfilePage extends ReactQueryParams {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    lead: PropTypes.object.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const {lead, setMetaAction, languageState} = this.props;
    const title = languageState.payload.LEADS_PROFILE.TITLE;

    if (prevProps.lead.id !== lead.id) {
      setMetaAction({
        title: `${title} ${lead.id}`,
      });
    }
  }

  render() {
    const {queryParams: params} = this;
    const {
      isAdmin,
      isUserLoaded,
      match: {
        params: {id},
      },
    } = this.props;

    if (!isUserLoaded) {
      return null;
    }

    if (!isAdmin) {
      return <Redirect to={CLIENT_PAGES.HOME} />;
    }

    return <LeadsProfile leadId={id} urlParams={params} />;
  }
}

const mapStateToProps = (state) => ({
  isAdmin: selectIsAdmin(state),
  isUserLoaded: selectIsUserLoaded(state),
  lead: selectLead(state),
  languageState: state.languageState,
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsProfilePage);
