import React from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {dateToString} from 'client/services/helpers';

import Icon from 'client/components/common/icon';

import './coupons-grid.scss';

const CouponGridCell = ({value, extra}) => (
  <Cell>
    <div className="coupons-grid__cell ellipsis-cell ellipsis-text">{value}</div>
    {extra}
  </Cell>
);

CouponGridCell.propTypes = {
  value: PropTypes.node.isRequired,
  extra: PropTypes.node,
};

CouponGridCell.defaultProps = {
  extra: null,
};

const CouponsGrid = (props) => {
  const {lang, data, onNameClick, onEditClick} = props;

  return (
    <div className="coupons-grid fixed-table fixed-table--expand-1 theme-color-14">
      <Table rowsCount={data.length} headerHeight={50} rowHeight={42} width={2000} maxHeight={180}>
        <Column
          header={<Cell>{lang.NAME}</Cell>}
          cell={({rowIndex}) => (
            <CouponGridCell
              value={
                <span className="coupons-grid__name-cell" onClick={() => onNameClick(data[rowIndex])}>
                  {data[rowIndex].internal_name}
                </span>
              }
            />
          )}
          width={100}
        />
        <Column
          header={<Cell>{lang.CODE}</Cell>}
          cell={({rowIndex}) => <CouponGridCell value={data[rowIndex].internal_code} />}
          width={100}
        />
        <Column
          header={<Cell>{lang.TYPE}</Cell>}
          cell={({rowIndex}) => <CouponGridCell value={lang['TYPE_' + data[rowIndex].coupon_type.toUpperCase()]} />}
          width={100}
        />
        <Column
          header={<Cell>{lang.VALIDITY_TO}</Cell>}
          cell={({rowIndex}) => <CouponGridCell value={dateToString(data[rowIndex].validity_to)} />}
          width={100}
        />
        <Column
          header={
            <Cell>
              <div className="coupons-grid__tokens-label">{lang.TOKENS_NUMBER}</div>
            </Cell>
          }
          cell={({rowIndex}) => (
            <CouponGridCell
              value={data[rowIndex].number_of_generated_tokens}
              extra={
                <button
                  className="coupons-grid__edit-button button button--circle button--bg-9 pull-right"
                  onClick={() => onEditClick(data[rowIndex])}
                >
                  <Icon name="edit" className="button__icon" width={20} height={20} />
                </button>
              }
            />
          )}
          width={100}
        />
      </Table>
    </div>
  );
};

CouponsGrid.propTypes = {
  lang: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  onNameClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default connect((state) => ({
  lang: state.languageState.payload.OPERATION_PAGE.COUPONS_CARD.COUPON_FIELDS,
}))(CouponsGrid);
