import React, {Component} from 'react';

import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons/app-button';
import {SCENARIO_STEP_TYPES, SCENARIO_STEP_NAMES} from 'client/common/config';
import Offscreen from 'client/common/offscreen';

import {TranslationJsx} from 'client/models/language/types';

import TestBranchForm from '../forms/test-branch-form';
import TestDrawForm from '../forms/test-draw-form';
import TestEmailForm from '../forms/test-email-form';
import TestPostForm from '../forms/test-post-form';
import TestSmsForm from '../forms/test-sms-form';
import TestTimerForm from '../forms/test-timer-form';
import TestVarsForm from '../forms/test-vars-form';
import TestWinForm from '../forms/test-win-form';
import TestBranchStep from '../steps/test-branch-step';
import TestDrawStep from '../steps/test-draw-step';
import TestEmailStep from '../steps/test-email-step';
import TestPostStep from '../steps/test-post-step';
import TestSmsStep from '../steps/test-sms-step';
import TestTimerStep from '../steps/test-timer-step';
import TestWinStep from '../steps/test-win-step';
import TestStep from '../test-step';
import TestStepNavItem from '../test-step-nav-item';

import cssModule from './test-step-list.module.scss';

const b = bem('test-step-list', {cssModule});

const STEP_MAPPING = {
  [SCENARIO_STEP_TYPES.timer]: {
    step: TestTimerStep,
    form: TestTimerForm,
    language: 'TIMER',
  },
  [SCENARIO_STEP_TYPES.branch]: {
    step: TestBranchStep,
    form: TestBranchForm,
    language: 'BRANCH',
  },
  [SCENARIO_STEP_TYPES.message.email]: {
    step: TestEmailStep,
    form: TestEmailForm,
    language: 'EMAIL',
  },
  [SCENARIO_STEP_TYPES.message.sms]: {
    step: TestSmsStep,
    form: TestSmsForm,
    language: 'SMS',
  },
  [SCENARIO_STEP_TYPES.social.facebook]: {
    step: TestPostStep,
    form: TestPostForm,
    language: 'POST',
  },
  [SCENARIO_STEP_TYPES.social.twitter]: {
    step: TestPostStep,
    form: TestPostForm,
    language: 'POST',
  },
  [SCENARIO_STEP_TYPES.instantLottery]: {
    step: TestWinStep,
    form: TestWinForm,
    language: 'WIN',
  },
  [SCENARIO_STEP_TYPES.prizeDraw]: {
    step: TestDrawStep,
    form: TestDrawForm,
    language: 'DRAW',
  },
};

class TestStepsList extends Component {
  static propTypes = {
    change: PropTypes.func,
    formValues: PropTypes.object,
    initialize: PropTypes.func,
    steps: PropTypes.array,
    strategiesValues: PropTypes.object,
    variables: PropTypes.array,
    notCheckedFields: PropTypes.array,
    languageSteps: PropTypes.object.isRequired,
    languageForms: PropTypes.object.isRequired,
    languageFormsErrors: PropTypes.object.isRequired,
    languageNav: PropTypes.object.isRequired,
    stepParamsTitle: TranslationJsx,
    varsStepTitle: TranslationJsx,
    stepTitle: TranslationJsx,
    untouch: PropTypes.func,
    touch: PropTypes.func,
  };

  static defaultProps = {
    notCheckedFields: [],
    stepParamsTitle: '',
    varsStepTitle: '',
    stepTitle: '',
    formValues: {},
    steps: [],
    variables: [],
  };

  constructor(props) {
    super(props);

    this.initials = {
      [SCENARIO_STEP_TYPES.message.email]: {
        name: 'normal',
      },
      [SCENARIO_STEP_TYPES.prizeDraw]: {
        name: 'loss',
      },
      [SCENARIO_STEP_TYPES.instantLottery]: {
        _name: 'normal',
        name: 'normal',
      },
      [SCENARIO_STEP_TYPES.message.sms]: {
        name: 'normal',
      },
      [SCENARIO_STEP_TYPES.social.facebook]: {
        name: 'normal',
      },
      [SCENARIO_STEP_TYPES.social.twitter]: {
        name: 'normal',
      },
      [SCENARIO_STEP_TYPES.branch]: {
        _condition: 'normal',
        name: 'normal',
      },
      [SCENARIO_STEP_TYPES.timer]: {
        name: 'normal',
        units: {
          selectCustomOption: this.props.languageForms.TIMER.SECONDS_OPTION,
          amount: 1,
        },
      },
    };
  }

  state = {
    activeStep: 'vars',
  };

  componentDidMount() {
    const initial = this.getInitialStrategiesState(this.props.steps);
    this.props.initialize(initial);
  }

  getInitialStrategiesState = (steps) => {
    const initial = {};

    steps.forEach((step) => {
      const {id, implementation_class_name} = step;
      initial[`_${id}`] = this.initials[implementation_class_name];
    });

    return initial;
  };

  renderNavItem = (step) => {
    const {languageNav} = this.props;

    return (
      <AppButton
        key={step.id}
        className={b('nav-item')}
        onClick={() => this.setState({activeStep: step.id})}
        label={
          <TestStepNavItem
            name={step.name}
            type={languageNav[SCENARIO_STEP_NAMES[step.implementation.type]]}
            isActive={step.id === this.state.activeStep}
          />
        }
        asWrap
      />
    );
  };

  renderNavItems = (steps) => {
    const renderedNavItems = steps.map((step) => {
      return this.renderNavItem(step);
    });

    return (
      <div className={b('nav')}>
        <div className={b('nav-title')}>
          <TestStepNavItem type={this.props.stepParamsTitle} isTitle />
        </div>
        <div>{renderedNavItems}</div>
      </div>
    );
  };

  renderNav = (steps) => {
    return (
      <div className={b('navbar')}>
        <div className={b('nav', ['top'])}>
          <AppButton
            className={b('nav-item')}
            onClick={() => this.setState({activeStep: 'vars'})}
            label={
              <TestStepNavItem type={this.props.varsStepTitle} isActive={this.state.activeStep === 'vars'} isTitle />
            }
            asWrap
          />
        </div>
        {this.renderNavItems(steps)}
      </div>
    );
  };

  renderStep = (step, variables, formValues) => {
    const {step: Step, form: Form, language} = STEP_MAPPING[step.implementation.type];
    const {formValues: paramsValues, strategiesValues} = formValues;

    const {languageSteps, languageForms, languageFormsErrors, stepTitle} = this.props;

    return (
      <Offscreen key={step.id} hidden={step.id !== this.state.activeStep}>
        <div className={b('content-inner')}>
          <div className={b('content-col')}>
            <TestStep title={stepTitle}>
              <Step
                step={step}
                variables={variables}
                formValues={paramsValues}
                languageState={languageSteps[language]}
              />
            </TestStep>
          </div>
          <div className={b('content-col')}>
            <TestStep>
              <Form
                change={this.props.change}
                step={step}
                formValues={strategiesValues}
                id={step.id}
                languageState={{...languageForms[language], errors: languageFormsErrors}}
                untouch={this.props.untouch}
                touch={this.props.touch}
              />
            </TestStep>
          </div>
        </div>
      </Offscreen>
    );
  };

  renderSteps = (steps, variables, formValues) => {
    return steps.map((step) => this.renderStep(step, variables, formValues));
  };

  render() {
    const {formValues, languageFormsErrors, languageForms, strategiesValues, steps, variables, notCheckedFields} =
      this.props;

    return (
      <div className={b()}>
        <div id="ats-steps-list-steps" className={b('step')}>
          {this.renderNav(steps)}
          <div className={b('content')}>
            <div className={b('content-inner')}>
              <div className={b('content-col')}>
                <Offscreen hidden={this.state.activeStep !== 'vars'}>
                  <TestStep>
                    <TestVarsForm
                      notCheckedFields={notCheckedFields}
                      variables={variables}
                      formValues={formValues}
                      languageState={{errors: languageFormsErrors, form: languageForms.VARIABLES}}
                    />
                  </TestStep>
                </Offscreen>
              </div>
            </div>
            {this.renderSteps(steps, variables, {formValues, strategiesValues})}
          </div>
        </div>
      </div>
    );
  }
}

export default flow([
  reduxForm({
    form: 'TestScenarioStepsForms',
  }),
])(TestStepsList);
