import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getClientUsersWithMembershipsAction} from 'client/ducks/client-users/actions';
import {selectClientUsers} from 'client/ducks/client-users/selectors';
import {getParticipationsAPIExportReports} from 'client/ducks/participation-export/actions';
import {getAvailableColumnAdaptersByAutomationTaskForExport} from 'client/ducks/participation-export/actions';
import {
  getParticipationExportAPIReportsTableDataSelector,
  getParticipationExportAPIReportsSelector,
  getParticipationExportNum,
} from 'client/ducks/participation-export/selectors';
import {getAvailableExportColumns} from 'client/ducks/participation-export/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import CountBlock from 'client/common/count-block';

import Icon from 'client/components/common/icon';
import InfoBlock from 'client/components/common/info-block';

import {TranslationJsx} from 'client/models/language/types';

import ParticipantsChangeApiExportModal from '../components/modals/participants-change-api-export-modal';
import ParticipantsGenerateApiExportModal from '../components/modals/participants-generate-api-export-modal';
import ParticipantsAPIHistoryCardDataGrid from '../components/tables/participants-api-history-card-data-grid';
import ParticipantsExportHistoryCardDataGrid from '../components/tables/participants-export-history-card-data-grid';
import ParticipantsHistoryCardHeaderDataGrid from '../components/tables/participants-history-card-header-data-grid';

import './participants-export-history-card.scss';

class ParticipantsExportHistoryCard extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    clientId: PropTypes.number.isRequired,
    participationsCount: PropTypes.number.isRequired,
    operationId: PropTypes.number,
    taskId: PropTypes.number,
    dataAPI: PropTypes.array,
    getParticipationsAPIExportReports: PropTypes.func.isRequired,
    isDashboard: PropTypes.bool.isRequired,
    APIReports: PropTypes.array,
    getAvailableColumnAdaptersByAutomationTaskForExport: PropTypes.func.isRequired,
    availableExportColumns: PropTypes.object.isRequired,
    getClientUsersWithMemberships: PropTypes.func.isRequired,
    clientUsers: PropTypes.array.isRequired,
    isGlobal: PropTypes.bool,
    title: TranslationJsx,
  };

  static defaultProps = {
    isGlobal: false,
  };

  static modals = {
    EXPORT_API_MODAL: 'export_api_modal',
    EXPORT_API_CHANGE_MODAL: 'export_api_change_modal',
  };

  constructor(props) {
    super(props);

    this.state = {
      visibleModal: '',
      APIReportChangeid: null,
    };

    this.LANGUAGE = props.languageState.payload.PARTICIPANTS_EXPORT_HISTORY;
  }

  componentDidMount() {
    this.updateAPIExportReports();
    this.props.getAvailableColumnAdaptersByAutomationTaskForExport(this.props.taskId);
    this.props.getClientUsersWithMemberships(this.props.clientId);
  }

  updateAPIExportReports = () => {
    const {isGlobal, taskId, clientId} = this.props;

    const params = {
      include: {
        memberships: {
          client_user: null,
        },
        transferable_memberships: null,
        participation_export_items: null,
      },
    };

    if (isGlobal) {
      params.q = {
        automation_task_operation_client_id_eq: clientId,
      };
    } else {
      params.q = {
        automation_task_id_eq: taskId,
      };
    }

    this.props.getParticipationsAPIExportReports(params);
  };

  handleShowModal = (name, data) => {
    this.setState({visibleModal: name, ...data});
  };

  handleCloseModal = () => this.setState({visibleModal: ''});

  onShowExportAPI = () => this.handleShowModal(ParticipantsExportHistoryCard.modals.EXPORT_API_MODAL);

  onShowChangeExportAPI = (APIReportChangeid) =>
    this.handleShowModal(ParticipantsExportHistoryCard.modals.EXPORT_API_CHANGE_MODAL, {
      APIReportChangeid,
    });

  getAPIRecordById = () => {
    const {APIReports} = this.props;
    const APIid = this.state.APIReportChangeid;
    return APIid ? APIReports.find((report) => report.id === APIid) : null;
  };

  render() {
    const {clientId, operationId, taskId, dataAPI, isDashboard, availableExportColumns, clientUsers, isGlobal, title} =
      this.props;

    const {visibleModal} = this.state;

    return (
      <InfoBlock colorTheme={`${isDashboard ? 'participants-history-dashboard' : 'participants-history'}`}>
        <InfoBlock.Title>{title || this.LANGUAGE.TITLE}</InfoBlock.Title>
        <InfoBlock.Content>
          <InfoBlock.Header className="clearfix">
            <CountBlock
              itemName={this.LANGUAGE.ITEMS_NAME}
              num={this.props.participationsCount}
              className="pull-left"
            />
            {!isGlobal && (
              <InfoBlock.HeaderLink
                link={{
                  url:
                    CLIENT_PAGES.COMPANIES +
                    '/' +
                    String(clientId) +
                    CLIENT_PAGES.OPERATIONS +
                    '/' +
                    String(operationId) +
                    CLIENT_PAGES.AUTOTASK +
                    '/' +
                    String(taskId) +
                    CLIENT_PAGES.PARTICIPANTS_LIST,
                  text: this.LANGUAGE.GO_TO_PARTICIPANTS,
                }}
              />
            )}
          </InfoBlock.Header>
          <InfoBlock.Body limitedHeight={isDashboard} className="info-block" expand>
            {!isGlobal && (
              <ParticipantsGenerateApiExportModal
                show={visibleModal === ParticipantsExportHistoryCard.modals.EXPORT_API_MODAL}
                onClose={this.handleCloseModal}
                onCancel={this.handleCloseModal}
                clientId={clientId}
                taskId={taskId}
                updateCallBack={this.updateAPIExportReports}
                generalParticipationColumns={availableExportColumns}
                users={clientUsers}
              />
            )}
            {!isGlobal && (
              <ParticipantsChangeApiExportModal
                show={visibleModal === ParticipantsExportHistoryCard.modals.EXPORT_API_CHANGE_MODAL}
                onClose={this.handleCloseModal}
                onCancel={this.handleCloseModal}
                clientId={clientId}
                taskId={taskId}
                updateCallBack={this.updateAPIExportReports}
                APIReportChangeid={this.state.APIReportChangeid}
                APIRecord={this.getAPIRecordById()}
                users={clientUsers}
                generalParticipationColumns={availableExportColumns}
                getAvailableColumnAdaptersByAutomationTaskForExport={
                  this.props.getAvailableColumnAdaptersByAutomationTaskForExport
                }
              />
            )}
            <ParticipantsHistoryCardHeaderDataGrid
              show={visibleModal === ParticipantsExportHistoryCard.modals.EXPORT_API_MODAL}
              onClose={this.handleCloseModal}
              onCancel={this.handleCloseModal}
              clientId={Number(clientId)}
              isDashboard={isDashboard}
            />
            <ParticipantsAPIHistoryCardDataGrid
              clientId={clientId}
              data={dataAPI}
              updateCallBack={this.updateAPIExportReports}
              editCallBack={this.onShowChangeExportAPI}
              isDashboard={isDashboard}
            />
            <ParticipantsExportHistoryCardDataGrid
              exportType="file"
              clientId={clientId}
              operationId={operationId}
              taskId={taskId}
              isDashboard={isDashboard}
              isGlobal={isGlobal}
              showPagination={false}
            />
            <ParticipantsExportHistoryCardDataGrid
              exportType="api"
              clientId={clientId}
              operationId={operationId}
              taskId={taskId}
              isDashboard={isDashboard}
              isGlobal={isGlobal}
              showPagination={true}
            />
            {!isDashboard && !isGlobal && (
              <div className="fixed-table--participants-card__bottom">
                <div>
                  <button className={`button button--bg-${isDashboard ? '16' : '4'}`} onClick={this.onShowExportAPI}>
                    <Icon name="export" className="button__icon" />
                    <span>{this.LANGUAGE.EXPORT_API_BUTTON}</span>
                  </button>
                </div>
              </div>
            )}
          </InfoBlock.Body>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    languageState: state.languageState,
    participationsCount: ownProps.participationsCount || getParticipationExportNum(state),
    dataAPI: getParticipationExportAPIReportsTableDataSelector(state),
    APIReports: getParticipationExportAPIReportsSelector(state),
    availableExportColumns: getAvailableExportColumns(state),
    clientUsers: selectClientUsers(state, ownProps.clientId),
  };
};

const mapDispatchToProps = {
  getParticipationsAPIExportReports,
  getAvailableColumnAdaptersByAutomationTaskForExport,
  getClientUsersWithMemberships: getClientUsersWithMembershipsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsExportHistoryCard);
