export const getTimeZone = () => {
  const offset = new Date().getTimezoneOffset();
  const o = Math.abs(offset);
  return (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2);
};

export const getTimeString = (value, defaultValue = '00:00:00') => {
  const tz = getTimeZone();
  let result = defaultValue;

  if (value && value.match(/^\d{2}(:\d{2})?(:\d{2})?$/)) {
    const parts = value.split(':');
    const resultParts = [];

    for (let i = 0; i <= 2; i++) {
      resultParts.push(parts[i] || '00');
    }

    result = resultParts.join(':');
  }

  return `T${result}.000${tz}`;
};
