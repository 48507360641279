export default {
  GET_SLIDESHOWS_REQUEST: '@@slideshows/GET_SLIDESHOWS_REQUEST',
  GET_SLIDESHOWS_SUCCESS: '@@slideshows/GET_SLIDESHOWS_SUCCESS',
  GET_SLIDESHOWS_ERROR: '@@slideshows/GET_SLIDESHOWS_ERROR',

  GET_SLIDESHOW_REQUEST: '@@slideshows/GET_SLIDESHOW_REQUEST',
  GET_SLIDESHOW_SUCCESS: '@@slideshows/GET_SLIDESHOW_SUCCESS',
  GET_SLIDESHOW_ERROR: '@@slideshows/GET_SLIDESHOW_ERROR',

  PATCH_DIAPORAMA_MAPPING_ITEMS_REQUEST: '@@slideshows/PATCH_DIAPORAMA_MAPPING_ITEMS_REQUEST',
  PATCH_DIAPORAMA_MAPPING_ITEMS_SUCCESS: '@@slideshows/PATCH_DIAPORAMA_MAPPING_ITEMS_SUCCESS',
  PATCH_DIAPORAMA_MAPPING_ITEMS_ERROR: '@@slideshows/PATCH_DIAPORAMA_MAPPING_ITEMS_ERROR',
};
