export const DESTINATION_TYPES = {
  VARIABLE: 'variable',
  DATABASE: 'database',
};

export const COMPONENT_TYPES = {
  FIX: 'fix',
  VAR: 'var',
  OPERATION: 'operation',
};

export const IS_FIRST_VERSION = true;
