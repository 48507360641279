import React, {useMemo} from 'react';

import cn from 'classnames';
import {createPortal} from 'react-dom';

import bem from 'client/services/bem';
import {useAppMedia} from 'client/services/hooks';

import {useToast} from 'client/common/hooks/useToast';
import ToastNotification from 'client/common/toast-notification';

import cssModule from './toast-notification-container.module.scss';

const b = bem('toast-notification-container', {cssModule});

type ToastNotificationContainerProps = {
  className?: string;
};

const TOAST_NOTIFICATION_PORTAL = 'toast-notification-portal';

const ToastNotificationContainer: React.FC<ToastNotificationContainerProps> = (props) => {
  const {className} = props;

  const {toastNotifications} = useToast();
  const {isTablet} = useAppMedia();

  const toastNotificationPortal = document.getElementById(TOAST_NOTIFICATION_PORTAL) as HTMLElement;

  const toastNotificationsSliced = useMemo(() => {
    return toastNotifications.slice(0, 3);
  }, [toastNotifications]);

  return createPortal(
    <div className={cn(b({tablet: isTablet}), className)}>
      {toastNotifications.length > 0 &&
        toastNotificationsSliced.map((toastNotification) => (
          <ToastNotification
            className={b('notification', {tablet: isTablet})}
            key={toastNotification?.id}
            {...toastNotification}
            {...props}
          />
        ))}
    </div>,
    toastNotificationPortal,
  );
};

export default ToastNotificationContainer;
