import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {dateToString} from 'client/services/helpers';

import './channel-date-cell.scss';

const b = bem('channel-date-cell');

export const DateCell = ({date: dateString}) => {
  return (
    <div className={b()}>
      <span className={b('first-element')}>{dateToString(dateString, 'DD/MM/YY')}</span>
      <span className={b('second-element')}>{dateToString(dateString, 'HH:mm:ss')}</span>
    </div>
  );
};

DateCell.propTypes = {
  date: PropTypes.string.isRequired,
};
