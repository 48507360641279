import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getInterfacesAction(queryParams = {}, direct = false) {
  const actionTypes = direct
    ? [
        types.GET_DIRECT_ACCESS_STATISTICS_REQUEST,
        types.GET_DIRECT_ACCESS_STATISTICS_SUCCESS,
        types.GET_DIRECT_ACCESS_STATISTICS_ERROR,
      ]
    : [
        types.GET_INTERFACES_STATISTICS_REQUEST,
        types.GET_INTERFACES_STATISTICS_SUCCESS,
        types.GET_INTERFACES_STATISTICS_ERROR,
      ];

  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACES}`,
      method: 'GET',
      types: actionTypes,
      noCache: true,
      queryParams,
    });
  };
}

export function getCampaignsAction(queryParams = {}, interactions = false) {
  const actionTypes = interactions
    ? [
        types.GET_CAMPAIGN_SOURCES_STATISTICS_REQUEST,
        types.GET_CAMPAIGN_SOURCES_STATISTICS_SUCCESS,
        types.GET_CAMPAIGN_SOURCES_STATISTICS_ERROR,
      ]
    : [
        types.GET_CAMPAIGNS_STATISTICS_REQUEST,
        types.GET_CAMPAIGNS_STATISTICS_SUCCESS,
        types.GET_CAMPAIGNS_STATISTICS_ERROR,
      ];

  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CAMPAIGNS}`,
      method: 'GET',
      headers: {
        'cache-control': 'no-cache',
      },
      types: actionTypes,
      queryParams,
    });
}
