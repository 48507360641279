import sortBy from 'lodash/sortBy';

import {calculateTicks} from 'client/components/common/charts/stacked-bars-chart/helpers';

import {roundValue, getIntervalGraphData} from '../helpers';

const getValueGraphData = (data = {}) => {
  const values = [];
  Object.entries(data).forEach(([key, value]) => {
    if (!(key === 'Undefined' && value === 0)) {
      values.push({label: roundValue(key), value});
    }
  });
  return values;
};

const sortNoneValue = (data) => {
  const result = [];
  let noneItem = null;

  data.forEach((item) => {
    if (item.label === 'None') {
      noneItem = item;
    } else {
      result.push(item);
    }
  });

  if (noneItem && noneItem.value) {
    result.push(noneItem);
  }

  return result;
};

export const getVerticalGraphData = ({diagram_method, dot_value, values}) => {
  if (diagram_method === 'value') {
    const data = getValueGraphData(values);
    const maxValue = data.reduce((acc, item) => (item.value > acc ? item.value : acc), 0);
    return {
      data: sortNoneValue(sortBy(data, ({label}) => roundValue(label))),
      yAxisConfig: {
        ...calculateTicks(0, maxValue),
      },
    };
  }

  const data = getIntervalGraphData(values, dot_value);
  // Caused WEEZIO-2657
  // const maxValue = data.reduce((acc, record) => {
  //   const average = record.value / dot_value;
  //   if (average > acc) {
  //     return average;
  //   }
  //   return acc;
  // }, 0);
  const maxValue = data.reduce((acc, item) => (item.value > acc ? item.value : acc), 0);

  return {
    data,
    xAxisConfig: {
      labels: [],
    },
    yAxisConfig: {
      labels: [],
      ...calculateTicks(0, maxValue),
    },
  };
};
