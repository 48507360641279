import React, {useState} from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Offscreen from 'client/common/offscreen';
import TabSwitcher from 'client/common/tab-switcher';

import LeadsParamsColumn from 'client/components/leads/leads-parameters/leads-params-column';
import {Client} from 'client/models/client/types';

import ApiAccessTable from '../api-access-table';
import ExportFilesTable from '../export-files-table';

import cssModule from './leads-exports.module.scss';

const b = bem('leads-exports', {cssModule});

type LeadsExportsProps = {
  className?: string;
  client: Client;
};

const tabs = {
  FILE: 'file',
  API: 'api',
};

const LeadsExports: React.FC<LeadsExportsProps> = (props) => {
  const {client, className} = props;
  const lang = useLanguage('LEADS_PARAMETERS.LEADS_EXPORTS');
  const [activeTab, setActiveTab] = useState(tabs.FILE);

  return (
    <LeadsParamsColumn
      className={cn(b(), className)}
      label={
        <TabSwitcher
          style="title"
          activeTab={activeTab}
          onTabClick={setActiveTab}
          tabs={[
            {title: lang.EXPORT_FILES, value: tabs.FILE, className: b('tab')},
            {title: lang.API_ACCESS, value: tabs.API, className: b('tab')},
          ]}
        />
      }
    >
      <Offscreen hidden={activeTab !== tabs.FILE}>
        <ExportFilesTable clientId={client.id} />
      </Offscreen>
      <Offscreen hidden={activeTab !== tabs.API}>
        <ApiAccessTable clientId={client.id} />
      </Offscreen>
    </LeadsParamsColumn>
  );
};

export default LeadsExports;
