import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {set} from 'client/common/meta/meta.actions';

import Campaign from '../components/campaign';

class CampaignPage extends Component {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.setMetaAction({title: this.props.lang.TITLE});
  }

  render() {
    return <Campaign id={+this.props.match.params.id} />;
  }
}

export default connect(
  ({languageState}) => ({
    lang: languageState.payload.CAMPAIGN,
  }),
  (dispatch) => ({
    setMetaAction: bindActionCreators(set, dispatch),
  }),
)(CampaignPage);
