import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getApiLeadExports} from 'client/ducks/leads-export/actions';
import {selectApiLeadExports} from 'client/ducks/leads-export/selectors';
import {getApiLeadImports} from 'client/ducks/leads-import/actions';
import {selectApiLeadImports} from 'client/ducks/leads-import/selectors';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import CountBlock from 'client/common/count-block';

import InfoBlock from 'client/components/common/info-block';

import LeadsGenerateApiImportModal from 'client/components/leads/components/modals/leads-generate-api-import-modal';

import LeadsCardActions from './leads-card-actions';

import LeadsCardApiExportGrid from '../../tables/leads-card-api-export-grid';
import LeadsCardDataGrid from '../../tables/leads-card-data-grid';
import LeadsCardGridHeader from '../../tables/leads-card-grid-header';

import './leads-card.scss';

class LeadsCard extends Component {
  static propTypes = {
    leadsReports: PropTypes.array,
    leadsTotalCount: PropTypes.number,
    lang: PropTypes.object.isRequired,
    clientId: PropTypes.number,
    client: PropTypes.object,
    showActions: PropTypes.bool,
    onImportFile: PropTypes.func,
    deleteReport: PropTypes.func,
    getReports: PropTypes.func.isRequired,
    getApiLeadExports: PropTypes.func.isRequired,
    apiLeadExports: PropTypes.array.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    columnAdapters: PropTypes.array,
    clientUsers: PropTypes.array,
    apiLeadImports: PropTypes.array.isRequired,
    getApiLeadImports: PropTypes.func.isRequired,
    generalColumns: PropTypes.array.isRequired,
  };

  static defaultProps = {
    leadsReports: [],
    clientUsers: [],
    columnAdapters: [],
    client: {},
    showActions: true,
    onImportFile: () => {},
    deleteReport: () => {},
    leadsTotalCount: null,
    clientId: null,
  };

  state = {
    showApiImportModal: false,
  };

  componentDidMount() {
    if (this.props.clientId) {
      this.fetchApiLeadExports();
      this.fetchApiLeadImports();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.clientId !== prevProps.clientId) {
      this.fetchApiLeadExports(this.props.clientId);
      this.fetchApiLeadImports(this.props.clientId);
    }
  }

  fetchApiLeadExports = (clientId = null) => {
    this.props.getApiLeadExports({
      include: {
        memberships: {
          client_user: null,
        },
        transferable_memberships: null,
        lead_transfer_items: null,
      },
      q: {
        client_id_eq: clientId || this.props.clientId,
        s: 'created_at desc',
      },
    });
  };

  fetchApiLeadImports = (clientId = null) => {
    this.props.getApiLeadImports({
      include: {
        memberships: {
          client_user: null,
        },
        transferable_memberships: null,
        lead_transfer_items: null,
      },
      q: {
        client_id_eq: clientId || this.props.clientId,
        s: 'created_at desc',
      },
    });
  };

  toggleApiImportModal = () => this.setState(({showApiImportModal}) => ({showApiImportModal: !showApiImportModal}));

  renderApiImportModal = () => {
    const {clientId, columnAdapters, generalColumns, clientUsers} = this.props;
    const {showApiImportModal} = this.state;

    return (
      <LeadsGenerateApiImportModal
        show={showApiImportModal}
        onClose={this.toggleApiImportModal}
        clientId={clientId}
        columns={columnAdapters}
        generalColumns={generalColumns}
        users={clientUsers}
        refresh={this.fetchApiLeadImports}
      />
    );
  };

  render() {
    const {
      leadsReports,
      leadsTotalCount,
      client,
      clientId,
      deleteReport,
      getReports,
      apiLeadExports,
      isAdmin,
      columnAdapters,
      clientUsers,
      showActions,
      onImportFile,
      apiLeadImports,
      lang,
    } = this.props;

    const isBodyEmpty = isAdmin ? !leadsReports.length && !apiLeadExports.length : !leadsReports.length;

    return (
      <InfoBlock colorTheme="leads">
        {this.renderApiImportModal()}
        <InfoBlock.Title>{lang.TITLE}</InfoBlock.Title>
        <InfoBlock.Content>
          <InfoBlock.Header className="clearfix">
            <CountBlock itemName={lang.ITEMS_NAME} num={leadsTotalCount} className="pull-left" />
            {leadsTotalCount > 0 && (
              <InfoBlock.HeaderLink
                link={{
                  url: `${CLIENT_PAGES.LEADS_LIST}/${client.id}`,
                  text: lang.GO_TO_LEADS,
                }}
              />
            )}
          </InfoBlock.Header>
          <InfoBlock.Body className="info-block" expand>
            <div className="leads-card__content">
              {isBodyEmpty ? (
                <span className="leads-card__empty">{lang.NO_RECORDS}</span>
              ) : (
                <div>
                  <LeadsCardGridHeader />
                  <LeadsCardApiExportGrid
                    clientId={clientId}
                    data={apiLeadExports}
                    columnAdapters={columnAdapters}
                    clientUsers={clientUsers}
                    fetchApiLeadExports={this.fetchApiLeadExports}
                    hideHeader={true}
                  />
                  <LeadsCardApiExportGrid
                    clientId={clientId}
                    data={apiLeadImports}
                    columnAdapters={columnAdapters}
                    clientUsers={clientUsers}
                    fetchApiLeadExports={this.fetchApiLeadImports}
                    hideHeader={true}
                    isImport
                  />
                  <LeadsCardDataGrid
                    clientId={clientId}
                    data={leadsReports}
                    deleteReport={deleteReport}
                    getReports={getReports}
                    hideHeader={isAdmin}
                  />
                </div>
              )}
            </div>
            {showActions && (
              <LeadsCardActions
                leadsTotalCount={leadsTotalCount}
                clientId={clientId}
                onImportFile={onImportFile}
                onImportApi={this.toggleApiImportModal}
                columnAdapters={columnAdapters}
                clientUsers={clientUsers}
                refresh={this.fetchApiLeadExports}
              />
            )}
          </InfoBlock.Body>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.languageState.payload.AGENCY.LEADS_CARD,
    apiLeadExports: selectApiLeadExports(state),
    apiLeadImports: selectApiLeadImports(state),
    isAdmin: selectIsAdmin(state),
  }),
  {
    getApiLeadExports,
    getApiLeadImports,
  },
)(LeadsCard);
