import types from './types';

export function setBreadcrumbs(links) {
  return {
    type: types.SET_BREADCRUMBS,
    payload: links,
  };
}

export function clearBreadcrumbs() {
  return {
    type: types.CLEAR_BREADCRUMBS,
    payload: [],
  };
}
