import {isEmptyString} from 'client/services/validator';

const validatetranslationsItemFieldset = (formValues, lang) => {
  const errors = {};

  if (isEmptyString(formValues.value)) {
    errors.value = lang.TRANSLATION_REQUIRED;
  }

  return errors;
};

export default (formValues, {langErrors}) => {
  const errors = validatetranslationsItemFieldset(formValues, langErrors);

  return {
    ...errors,
  };
};
