import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './toggle-button.scss';

function ToggleButton(props) {
  const {value, onToggle, disabled, className} = props;

  const onClick = disabled ? null : () => onToggle(!value);

  return (
    <div className={cn('toggle-button', className)} onClick={onClick}>
      <div
        className={cn('toggle-button__button', {
          'toggle-button__left-on': value,
          'toggle-button__left-off': !value,
        })}
      />
      <div
        className={cn('toggle-button__button', {
          'toggle-button__right-on': value,
          'toggle-button__right-off': !value,
        })}
      />
    </div>
  );
}

ToggleButton.propTypes = {
  value: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

ToggleButton.defaultProps = {
  value: false,
  disabled: false,
  className: '',
};

export default ToggleButton;
