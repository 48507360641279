import React, {Component} from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {reduxForm, Field} from 'redux-form';

import {getEmail, getToken} from 'client/services/cookie-data-source';
import {addHashToImage, downloadFile, getClientPage, getFileSize} from 'client/services/helpers';

import {patchDiaporamaMappingItems} from 'client/ducks/slideshows/actions';
import {selectIsAdmin} from 'client/ducks/user/selectors';
import {patchVisual, getAutomaticVisualGroups} from 'client/ducks/visuals/actions';

import {API_METHODS, API_PATH, CLIENT_PAGES, VISUALS_STATUS_COLORS} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import Checkbox from 'client/components/common/checkbox';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';
import Hashtag from 'client/components/common/hashtag';
import Icon from 'client/components/common/icon';
import StatusButtons from 'client/components/common/status-buttons';
import VisibleStatusButtons from 'client/components/common/visible-status-buttons';

import './vis-preview-modal.scss';

class VisPreviewModal extends Component {
  static propTypes = {
    addText: PropTypes.string,
    show: PropTypes.bool,
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    updateMe: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
    selectedIds: PropTypes.array,
    visual: PropTypes.object,
    lang: PropTypes.object.isRequired,
    langFilesType: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    diaporamaValues: PropTypes.object,
    isColAdapterInDiaporama: PropTypes.func,
    getArrivingType: PropTypes.func,
    getVisualOrDiaporamaValue: PropTypes.func,
    submit: PropTypes.func.isRequired,
    patchDiaporamaMappingItems: PropTypes.func.isRequired,
    patchVisual: PropTypes.func.isRequired,
    getAutomaticVisualGroups: PropTypes.func.isRequired,
    showNavigation: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    addText: '',
    show: false,
    visual: {},
    selectedIds: [],
    isColAdapterInDiaporama: () => {},
    diaporamaValues: {},
    getArrivingType: () => {},
    getVisualOrDiaporamaValue: () => {},
  };

  static formName = 'VisPreviewModalForm';

  static KEY_CODES = {
    LEFT: 37,
    RIGHT: 39,
  };

  static IGNORED_TAGS = ['input', 'textarea'];

  constructor(props) {
    super(props);

    this.state = {
      changeStatus: false,
      changeVisible: false,

      visual: {},
      diaporamaValues: {},
    };

    this.submitFunc = props.onClose;
  }

  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && (!prevProps.show || this.props.visual !== prevProps.visual)) {
      this.props.initialize({
        title: this.props.getVisualOrDiaporamaValue('title', this.props.visual, this.props.diaporamaValues),
        comment: this.props.getVisualOrDiaporamaValue('comment', this.props.visual, this.props.diaporamaValues),
      });
      delete this.props.visual.file.tempURL;
      delete this.formData;

      this.setState({
        visual: {
          ...this.props.visual,
          checked: this.props.selectedIds.includes(this.props.visual.id),
        },
      });
      this.changed = false;
    }

    if (this.props.diaporamaValues !== prevProps.diaporamaValues) {
      this.setState({
        diaporamaValues: {
          ...this.props.diaporamaValues,
        },
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  handleKeyUp = (e) => {
    if (VisPreviewModal.IGNORED_TAGS.includes(e.target.tagName.toLowerCase())) {
      return;
    }

    switch (e.keyCode) {
      case VisPreviewModal.KEY_CODES.LEFT:
        this.handleButtonLeftClick();
        this.handleSubmitForm()();
        break;

      case VisPreviewModal.KEY_CODES.RIGHT:
        this.handleButtonRightClick();
        this.handleSubmitForm()();
        break;

      default:
        break;
    }
  };

  handleChangeStatus = (status) => {
    this.setState((prevState) => ({
      changeStatus: !prevState.changeStatus,
      visual: {
        ...prevState.visual,
        status: this.props.isColAdapterInDiaporama('status') ? null : status,
      },
      diaporamaValues: {
        ...prevState.diaporamaValues,
        status: {
          ...prevState.diaporamaValues.status,
          value: this.props.isColAdapterInDiaporama('status') ? status : null,
        },
      },
    }));
    this.changed = true;
  };

  handleChangeVisible = (status) => {
    this.setState((prevState) => ({
      changeVisible: !prevState.changeVisible,
      visual: {
        ...prevState.visual,
        visible_by_client: this.props.isColAdapterInDiaporama('visible_by_client') ? null : status,
      },
      diaporamaValues: {
        ...prevState.diaporamaValues,
        visible_by_client: {
          ...prevState.diaporamaValues.visible_by_client,
          value: this.props.isColAdapterInDiaporama('visible_by_client') ? status : null,
        },
      },
    }));
    this.changed = true;
  };

  handleSelectVisual = (checked) => {
    this.setState((prevState) => ({
      visual: {
        ...prevState.visual,
        checked,
      },
    }));

    this.props.onSelect(checked);
  };

  loadAutomaticGroups = () =>
    this.props.getAutomaticVisualGroups(this.props.visual.id).then((result) => {
      this.setState({
        visual: {
          ...this.state.visual,
          automatic_visual_groups: result.payload.automatic_visual_groups,
        },
      });
    });

  setStateNewTags = (tags) => {
    this.setState((prevState) => ({
      visual: {
        ...prevState.visual,
        tags: this.props.isColAdapterInDiaporama('tags') ? [] : tags,
      },
      diaporamaValues: {
        ...prevState.diaporamaValues,
        tags: {
          ...prevState.diaporamaValues.tags,
          value: this.props.isColAdapterInDiaporama('tags') ? tags : [],
        },
      },
    }));
    this.changed = true;
  };

  saveTags = (index, newTagValue) => {
    const newTags = this.props.getVisualOrDiaporamaValue('tags', this.state.visual, this.state.diaporamaValues).slice();
    if (newTagValue.trim().length === 0) {
      newTags.splice(index, 1);
    } else {
      newTags[index] = newTagValue.trim();
    }
    this.setStateNewTags(newTags);
  };

  addTag = () => {
    const newTags = [
      ...this.props.getVisualOrDiaporamaValue('tags', this.state.visual, this.state.diaporamaValues),
      '',
    ];
    this.setStateNewTags(newTags);
  };

  patchDiaporamaValues = (body) => {
    if (isEmpty(body)) {
      return Promise.resolve();
    }
    if (body.tags) {
      body.tags.value = body.tags.value.join(', ');
    }
    return this.props.patchDiaporamaMappingItems({
      diaporama_mapping_item: Object.keys(body).map((columnName) => {
        const id = body[columnName].id;
        const value = body[columnName].value;
        return {
          id: id,
          value: value,
        };
      }),
    });
  };

  patchVisualFunc = (body) => {
    if (isEmpty(body)) {
      return Promise.resolve();
    }
    return this.props.patchVisual(this.state.visual.id, {
      visual: body,
    });
  };

  save = (formValues) => {
    if (this.changed && this.props.isAdmin) {
      const visualBody = {};
      const diaporamaValuesBody = {};
      const changedValuesArray = ['comment', 'title', 'tags', 'status', 'visible_by_client'];
      changedValuesArray.forEach((field) => {
        if (this.props.isColAdapterInDiaporama(field)) {
          diaporamaValuesBody[field] = this.state.diaporamaValues[field];
          diaporamaValuesBody[field].value = formValues[field] || this.state.diaporamaValues[field].value;
        } else {
          switch (field) {
            case 'title':
              visualBody[field] = formValues[field] || this.state.visual.file_identifier;
              break;
            default:
              visualBody[field] = formValues[field] || this.state.visual[field];
              break;
          }
        }
        return {diaporamaValuesBody, visualBody};
      });

      const patchFunc = () =>
        Promise.all([this.patchDiaporamaValues(diaporamaValuesBody), this.patchVisualFunc(visualBody)]).then(() => {
          this.props.updateMe();
          this.submitFunc();
        });

      if (this.formData) {
        return fetch(`${API_PATH}${API_METHODS.VISUALS}/${this.state.visual.id}`, {
          method: 'PATCH',
          headers: {
            'X-User-Email': getEmail(),
            'X-User-Token': getToken(),
          },
          body: this.formData,
        }).then(patchFunc);
      }
      return patchFunc();
    }
    return this.submitFunc();
  };

  handleChangeImage = (event) => {
    const file = event.target.files[0];
    if (!file || !file.type.startsWith('image/')) {
      return;
    }

    this.changed = true;

    const formData = new FormData();
    const now = new Date();
    const hash = String(now.getTime());
    const hashedName = addHashToImage(file.name, hash);
    formData.append('visual[file]', file, encodeURIComponent(hashedName));
    this.formData = formData;

    const fr = new FileReader();
    fr.onload = () => {
      this.setState((state) => {
        const visual = cloneDeep(state.visual);
        visual.file.tempURL = fr.result;
        return {visual};
      });
    };
    fr.readAsDataURL(file);
  };

  handleButtonLeftClick = () => {
    this.submitFunc = this.props.onPrevious;
  };

  handleButtonRightClick = () => {
    this.submitFunc = this.props.onNext;
  };

  handleSubmitForm = () => this.props.handleSubmit(this.save);

  getLeadName = (lead) => {
    return lead ? [lead.first_name, lead.last_name].filter((i) => i).join(' ') : '';
  };

  render() {
    const {
      show,
      lang,
      langFilesType,
      onClose,
      addText,
      isAdmin,
      isColAdapterInDiaporama,
      getVisualOrDiaporamaValue,
      showNavigation,
    } = this.props;

    const {changeStatus, changeVisible, visual, diaporamaValues} = this.state;

    let fileURL = null;

    if (visual.file) {
      fileURL = visual.file.tempURL || visual.file.url;
    }

    const operation = visual.interaction && visual.interaction.interface.automation_task.operation;
    const client = operation && operation.client;

    const arrivingType = this.props.getArrivingType(visual);

    const hashTrackerTagClassName = (tag) => {
      if (visual.hashtag_tracker && visual.hashtag_tracker.tags.includes(tag)) {
        return 'theme-color-1';
      }
      return '';
    };

    const showGroupsList =
      (visual.manual_visual_groups && visual.manual_visual_groups.length > 0) ||
      (visual.automatic_visual_groups && visual.automatic_visual_groups.length > 0);
    const finalTags = getVisualOrDiaporamaValue('tags', visual, diaporamaValues) || [];

    const diaporamaGroupValues = isColAdapterInDiaporama('group') && diaporamaValues.group.value;

    const hideNavigationClass = showNavigation ? '' : 'visibility-hidden';

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-2 theme-color-13 vis-preview-modal"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
      >
        <form onSubmit={this.handleSubmitForm()}>
          <div className="vis-preview-modal__top">
            {addText && <div className="vis-preview-modal__add-text">{addText}</div>}
            <div className="vis-preview-modal__viewer">
              {visual.kind === 'text' ? (
                <div className="vis-preview-modal__viewer">
                  <div className="vis-preview-modal__message">
                    {getVisualOrDiaporamaValue('comment', visual, diaporamaValues)}
                  </div>
                </div>
              ) : (
                <div
                  className="vis-preview-modal__img"
                  style={{
                    backgroundImage: `url(${fileURL})`,
                  }}
                />
              )}
              {isAdmin && (
                <Checkbox
                  cssModifier="vis-preview-modal__check-img"
                  name="img"
                  checked={this.state.visual.checked}
                  onChange={({target}) => this.handleSelectVisual(target.checked)}
                />
              )}
            </div>
          </div>
          <div className="vis-preview-modal__viewer-tools">
            <button
              className={`button button--bg-8 button--square ${hideNavigationClass}`}
              onClick={this.handleButtonLeftClick}
            >
              <Icon name="arrow" className="button__icon" />
            </button>
            {isAdmin && (
              <div>
                <div className="input-file">
                  <label htmlFor="file_input" className="input-file__label cursor-pointer">
                    <div className="button button--bg-8 vis-preview-modal__viewer-btn">
                      <Icon name="rotate" className="button__icon" />
                      <span>{lang.CHANGE_FILE_BUTTON}</span>
                    </div>
                  </label>
                  <input
                    id="file_input"
                    className="input-file__input"
                    type="file"
                    accept="image/*"
                    onChange={this.handleChangeImage}
                  />
                </div>
                <button
                  type="button"
                  className="button button--bg-8 vis-preview-modal__viewer-btn"
                  onClick={() => downloadFile(visual.file && visual.file.url)}
                  disabled={!visual.file || !visual.file.url}
                >
                  <Icon name="import" className="button__icon" />
                  <span>{lang.DOWNLOAD_FILE_BUTTON}</span>
                </button>
              </div>
            )}
            <button
              className={`button button--bg-8 button--square vis-preview-modal__btn-next ${hideNavigationClass}`}
              onClick={this.handleButtonRightClick}
            >
              <Icon name="arrow" className="button__icon" />
            </button>
          </div>
          <CustomScrollbars
            scrollbarProps={{
              autoHeightMax: 300,
              hideTracksWhenNotNeeded: true,
            }}
          >
            <div className="vis-preview-modal__container">
              <div className="vis-preview-modal__col">
                <Field
                  cssModifier="input--no-min-height vis-preview-modal__field input--disabled-view-1"
                  label={lang.TITLE_LABEL}
                  name="title"
                  onChange={() => {
                    this.changed = true;
                  }}
                  onKeyPress={(e) => {
                    if (e.which === 13 /* Enter */) {
                      e.preventDefault();
                    }
                  }}
                  type="text"
                  component={fieldTemplate}
                  disabled={!isAdmin}
                />
                <div className="fake-input fake-input--disabled fake-input fake-input--one-row">
                  <div className="fake-input__label">{lang.LEAD_LABEL}</div>
                  <div className="fake-input__field">{this.getLeadName(visual.lead)}</div>
                  <div className="fake-input__field">{visual.lead && visual.lead.email}</div>
                </div>
                <div className="fake-input fake-input--disabled fake-input--one-row">
                  <div className="fake-input__label">{lang.AUTHOR_LABEL}</div>
                  <div className="fake-input__field">
                    {getVisualOrDiaporamaValue('author', visual, diaporamaValues)}
                  </div>
                </div>
                {visual.author_avatar && (
                  <div className="fake-input fake-input--disabled fake-input--one-row">
                    <div className="fake-input__label">{lang.AUTHOR_AVATAR_LABEL}</div>
                    <div className="vis-preview-modal__avatar">
                      <div
                        className="vis-preview-modal__img"
                        style={{
                          backgroundImage: `url(${visual.author_avatar.url})`,
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="fake-input fake-input--disabled fake-input--one-row">
                  <div className="fake-input__label">{lang.FILE_TYPE_LABEL}</div>
                  <div className="fake-input__field">
                    {isColAdapterInDiaporama('kind') ? diaporamaValues.kind.value : langFilesType[visual.kind]}
                  </div>
                </div>
                <div className="fake-input fake-input--disabled fake-input--one-row">
                  <div className="fake-input__label">{lang.EXTENSION_LABEL}</div>
                  <div className="fake-input__field">
                    {getVisualOrDiaporamaValue('file_extension', visual, diaporamaValues)}
                  </div>
                </div>
                <div className="fake-input fake-input--disabled fake-input--one-row">
                  <div className="fake-input__label">{lang.SIZE_LABEL}</div>
                  <div className="fake-input__field">
                    {getFileSize(+getVisualOrDiaporamaValue('filesize', visual, diaporamaValues))}
                  </div>
                </div>
                <div className="fake-input fake-input--disabled fake-input--one-row">
                  <div className="fake-input__label">{lang.CREATED_LABEL}</div>
                  <div className="fake-input__field">
                    {moment(getVisualOrDiaporamaValue('created_at', visual, diaporamaValues)).format('DD/MM/YYYY')}
                  </div>
                  <div className="fake-input__field">
                    {moment(getVisualOrDiaporamaValue('created_at', visual, diaporamaValues)).format('HH:mm:ss')}
                  </div>
                </div>
                {isAdmin && (
                  <div className="fake-input fake-input--disabled fake-input--one-row">
                    <div className="fake-input__label">{lang.CLIENT_LABEL}</div>
                    {client && (
                      <Link className="link fake-input__field underline" to={`${getClientPage(client)}/${client.id}`}>
                        {client && client.name}
                      </Link>
                    )}
                  </div>
                )}
                <div className="fake-input fake-input--disabled fake-input--one-row">
                  <div className="fake-input__label">{lang.OPERATION_LABEL}</div>
                  {isAdmin && client && (
                    <Link
                      className="link fake-input__field underline"
                      to={
                        client ? `${getClientPage(client)}/${client.id}${CLIENT_PAGES.OPERATIONS}/${operation.id}` : ''
                      }
                    >
                      {operation && operation.code}
                    </Link>
                  )}
                  <div className="fake-input__field">{operation && operation.name}</div>
                </div>
              </div>
              <div className="vis-preview-modal__col">
                <div className="fake-input fake-input--disabled fake-input--one-row">
                  <div className="fake-input__label">{lang.ARRIVING_LABEL}</div>
                  <div className="fake-input__field">{arrivingType}</div>
                </div>
                <div className="fake-input fake-input--disabled fake-input--one-row">
                  <div className="fake-input__label">{lang.INTERFACE_LABEL}</div>
                  <div className="fake-input__field">
                    {isColAdapterInDiaporama('interface')
                      ? diaporamaValues.interface.value
                      : visual.interaction && visual.interaction.interface.name}
                  </div>
                </div>
                {isAdmin && (
                  <FormFieldWrap>
                    <div className="fake-input fake-input--disabled">
                      <div className="fake-input__label">{lang.STATUS_LABEL}</div>
                      <div className="vis-preview-modal__status">
                        <div className="vis-preview-modal__status-current">
                          <div
                            className="status-icon"
                            style={{
                              color:
                                VISUALS_STATUS_COLORS[getVisualOrDiaporamaValue('status', visual, diaporamaValues)],
                            }}
                          />
                          <div className="vis-preview-modal__status-text">
                            {lang.STATUSES[getVisualOrDiaporamaValue('status', visual, diaporamaValues)]}
                          </div>
                        </div>
                        <div className="vis-preview-modal__status-controls">
                          {changeStatus ? (
                            <StatusButtons
                              className="vis-preview-modal__status-btns"
                              title={lang.STATUS_TEXT}
                              onClick={this.handleChangeStatus}
                            />
                          ) : (
                            <button className="button button--bg-8" onClick={() => this.setState({changeStatus: true})}>
                              {lang.CHANGE_STATUS_BUTTON}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </FormFieldWrap>
                )}
                {isAdmin && (
                  <FormFieldWrap>
                    <div className="fake-input fake-input--disabled">
                      <div className="fake-input__label">{lang.VISIBLE_LABEL}</div>
                      <div className="vis-preview-modal__status">
                        <div className="vis-preview-modal__status-current">
                          <div
                            className="status-icon"
                            style={{
                              color:
                                VISUALS_STATUS_COLORS[
                                  getVisualOrDiaporamaValue('visible_by_client', visual, diaporamaValues)
                                ],
                            }}
                          />
                          <div className="vis-preview-modal__status-text">
                            {
                              lang.VISIBLE_STATUSES[
                                getVisualOrDiaporamaValue('visible_by_client', visual, diaporamaValues)
                              ]
                            }
                          </div>
                        </div>
                        <div className="vis-preview-modal__status-controls">
                          {changeVisible ? (
                            <VisibleStatusButtons
                              className="vis-preview-modal__status-btns"
                              title={lang.VISIBLE_TEXT}
                              onClick={this.handleChangeVisible}
                            />
                          ) : (
                            <button
                              className="button button--bg-8"
                              onClick={() => this.setState({changeVisible: true})}
                            >
                              {lang.CHANGE_STATUS_BUTTON}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </FormFieldWrap>
                )}
                {isAdmin ? (
                  <Field
                    cssModifier="input--no-min-height vis-preview-modal__textarea"
                    label={lang.COMMENT_LABEL}
                    name="comment"
                    readOnly={!isAdmin}
                    onChange={() => {
                      this.changed = true;
                    }}
                    type="textarea"
                    component={fieldTemplate}
                  />
                ) : (
                  <div className="fake-input fake-input--disabled">
                    <div className="fake-input__label">{lang.COMMENT_LABEL}</div>
                    <div className="fake-input__textarea">
                      {getVisualOrDiaporamaValue('comment', visual, diaporamaValues)}
                    </div>
                  </div>
                )}
                <div className="vis-preview-modal__tags hashtags-list">
                  {(isColAdapterInDiaporama('tags') || visual.tags) &&
                    finalTags.map((tag, key) => (
                      <div className="hashtags-list__item hashtags-list__item--limited" key={key}>
                        <Hashtag
                          editable={isAdmin}
                          appendSharp
                          className={`hashtags-list__item--limited ${hashTrackerTagClassName(tag)}`}
                          textClassName="ellipsis-text"
                          name={key}
                          onChange={this.saveTags}
                          onDelete={(index) => this.saveTags(index, '')}
                          deletable={isAdmin}
                        >
                          {tag}
                        </Hashtag>
                      </div>
                    ))}
                  {isAdmin && (
                    <div className="hashtags-list__item">
                      <div className="link" onClick={this.addTag}>
                        {lang.ADD_TAG_BUTTON}
                      </div>
                    </div>
                  )}
                </div>
                {isAdmin && (
                  <FormFieldWrap>
                    <div className="fake-input">
                      <div className="fake-input__label">{lang.GROUPS_LABEL}</div>
                      {!isColAdapterInDiaporama('group') && !visual.automatic_visual_groups && (
                        <div className="fake-input__label cursor-pointer" onClick={() => this.loadAutomaticGroups()}>
                          {lang.SHOW_AUTOMATIC_GROUPS_BUTTON}
                        </div>
                      )}
                      {showGroupsList && (
                        <div className="content-group vis-preview-modal__field">
                          <CustomScrollbars
                            scrollbarProps={{
                              autoHeightMax: 85,
                            }}
                          >
                            <div className="main-text">
                              {!isColAdapterInDiaporama('group') &&
                                visual.manual_visual_groups &&
                                visual.manual_visual_groups.map((group) => (
                                  <div key={group.id} className="ellipsis-text vis-preview-modal__link">
                                    {group.name}
                                  </div>
                                ))}
                              {!isColAdapterInDiaporama('group') &&
                                visual.automatic_visual_groups &&
                                visual.automatic_visual_groups.map((group) => (
                                  <div key={group.id} className="ellipsis-text vis-preview-modal__link">
                                    {group.name}
                                  </div>
                                ))}
                              {isColAdapterInDiaporama('group') &&
                                diaporamaGroupValues.map((group, key) => (
                                  <div key={key} className="ellipsis-text vis-preview-modal__link">
                                    {group}
                                  </div>
                                ))}
                            </div>
                          </CustomScrollbars>
                        </div>
                      )}
                    </div>
                  </FormFieldWrap>
                )}
              </div>
            </div>
          </CustomScrollbars>
          {isAdmin && (
            <div className="modal-window__footer modal-window__footer--centered">
              <button
                className="button button--bg-8 modal-window__footer-btn"
                type="submit"
                onClick={() => {
                  this.submitFunc = onClose;
                }}
              >
                {isAdmin ? lang.SAVE_BUTTON : lang.CONFIRM_BUTTON}
              </button>
            </div>
          )}
        </form>
      </Modal>
    );
  }
}

const VisPreviewModalForm = reduxForm({
  form: VisPreviewModal.formName,
})(VisPreviewModal);

const mapStateToProps = ({languageState, ...state}) => ({
  lang: languageState.payload.VISUALS.PREVIEW_MODAL,
  langFilesType: languageState.payload.VISUALS.FILTERS_MODAL.FILE_TYPES,
  isAdmin: selectIsAdmin(state),
});

export default connect(mapStateToProps, {
  patchDiaporamaMappingItems,
  patchVisual,
  getAutomaticVisualGroups,
})(VisPreviewModalForm);
