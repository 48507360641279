import moment from 'moment-timezone';

export const timezones = moment.tz.names();

export const getTimezoneParams = (timezone?: string) => {
  if (timezone) {
    const currentMoment = moment().tz(timezone);

    const offset = currentMoment.format('Z');
    let regionName = currentMoment.format('z');
    regionName = offset.replace(':', '').startsWith(regionName) ? '' : regionName + ' - ';

    const names = timezone.split('/');
    const name = names[names.length - 1] || names[0];

    return {offsetByMin: currentMoment.utcOffset(), formattedName: `(${offset}) ${regionName}${name}`, timezone};
  }
  return {offsetByMin: 0, formattedName: timezone};
};

export const timezoneOptions = timezones
  .map(getTimezoneParams)
  .sort((a, b) => (a.offsetByMin < b.offsetByMin ? 1 : -1))
  .map((tz) => ({label: tz.formattedName, value: tz.timezone}));

export const getCurrentTimezone = () => moment.tz.guess();
