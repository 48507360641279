import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';
import {connect, useDispatch, useSelector} from 'react-redux';

import {useQueryParams} from 'client/services/hooks';

import {getLeadHistories} from 'client/ducks/lead-histories/actions';
import {selectLeadHistories} from 'client/ducks/lead-histories/selectors';

import Modal from 'client/common/modals/modal';

import Spinner from 'client/components/common/spinner';

import LeadsBasicInfo from './leads-basic-info';
import LeadsCustomInfo from './leads-custom-info';
import LeadHistories from './leads-histories';

import './lead-info-modal.scss';

const LeadInfoModal = (props) => {
  const {leadId, leadInfo, onClose, lang} = props;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const [queryParams] = useQueryParams();

  const leadHistory = useSelector(selectLeadHistories);

  useEffect(() => {
    const params = {
      page: queryParams.page,
      per_page: queryParams.perPage,
      q: {
        lead_id_eq: leadId,
      },
      include: {
        source: null,
      },
    };
    setLoading(true);
    dispatch(getLeadHistories(params)).finally(() => {
      setLoading(false);
    });
  }, [dispatch, leadId, queryParams.page, queryParams.perPage]);

  return (
    <Modal
      dialogClassName="leads-info-modal"
      contentClassName="leads-info-modal__content"
      onClose={onClose}
      title={lang.TITLE}
    >
      {loading && <Spinner color="leads" centered />}
      {!loading && (
        <>
          <div className="leads-info-modal__info">
            <LeadsBasicInfo data={leadInfo} />
            <LeadsCustomInfo data={leadInfo?.input_data || {}} />
          </div>
          <LeadHistories className="leads-info-modal__histories" data={leadHistory} />
        </>
      )}
    </Modal>
  );
};

LeadInfoModal.propTypes = {
  lang: PropTypes.object.isRequired,
  leadInfo: PropTypes.object.isRequired,
  leadId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect((state) => ({
  lang: state.languageState.payload.DATA_LEAD.MODAL,
}))(LeadInfoModal);
