/**
 * Sometimes we don't need to save request data in store.
 */
import {callApi} from 'client/services/call-api';

import {API_PATH} from 'client/common/config';

import {ApiAction} from 'client/types';

type ApiActionParams = {
  url: string;
  queryParams?: Record<any, any>;
  method?: 'GET' | 'PATCH' | 'POST' | 'DELETE';
};

const types = {
  API_ACTION_REQUEST: '@@api-action/API_ACTION_REQUEST',
  API_ACTION_SUCCESS: '@@api-action/API_ACTION_SUCCESS',
  API_ACTION_ERRROR: '@@api-action/API_ACTION_ERROR',
};

export const apiAction: ApiAction = (params: ApiActionParams) => {
  const {url, queryParams, method = 'GET'} = params;

  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${url}`,
        method,
        noCache: true,
        types: [types.API_ACTION_REQUEST, types.API_ACTION_SUCCESS, types.API_ACTION_ERRROR],
        queryParams,
      },
      true,
    );
};
