export default {
  GET_INSTORE_TASK_REQUEST: '@@instore-tasks/GET_INSTORE_TASK_REQUEST',
  GET_INSTORE_TASK_SUCCESS: '@@instore-tasks/GET_INSTORE_TASK_SUCCESS',
  GET_INSTORE_TASK_FAILED: '@@instore-tasks/GET_INSTORE_TASK_FAILED',

  GET_TASK_NETWORKS_REQUEST: '@@instore-tasks/GET_TASK_NETWORKS_REQUEST',
  GET_TASK_NETWORKS_SUCCESS: '@@instore-tasks/GET_TASK_NETWORKS_SUCCESS',
  GET_TASK_NETWORKS_FAILED: '@@instore-tasks/GET_TASK_NETWORKS_FAILED',

  GET_INSTORE_TASK_ACCESSES_REQUEST: '@@instore-tasks/GET_INSTORE_TASK_ACCESSES_REQUEST',
  GET_INSTORE_TASK_ACCESSES_SUCCESS: '@@instore-tasks/GET_INSTORE_TASK_ACCESSES_SUCCESS',
  GET_INSTORE_TASK_ACCESSES_FAILED: '@@instore-tasks/GET_INSTORE_TASK_ACCESSES_FAILED',

  GET_INSTORE_TASK_POSITIONS_REQUEST: '@@instore-tasks/GET_INSTORE_TASK_POSITIONS_REQUEST',
  GET_INSTORE_TASK_POSITIONS_SUCCESS: '@@instore-tasks/GET_INSTORE_TASK_POSITIONS_SUCCESS',
  GET_INSTORE_TASK_POSITIONS_FAILED: '@@instore-tasks/GET_INSTORE_TASK_POSITIONS_FAILED',

  GET_INSTORE_TASK_KITS_REQUEST: '@@instore-tasks/GET_INSTORE_TASK_KITS_REQUEST',
  GET_INSTORE_TASK_KITS_SUCCESS: '@@instore-tasks/GET_INSTORE_TASK_KITS_SUCCESS',
  GET_INSTORE_TASK_KITS_FAILED: '@@instore-tasks/GET_INSTORE_TASK_KITS_FAILED',

  GET_INSTORE_TASK_KIT_MAPPINGS_REQUEST: '@@instore-tasks/GET_INSTORE_TASK_KIT_MAPPINGS_REQUEST',
  GET_INSTORE_TASK_KIT_MAPPINGS_SUCCESS: '@@instore-tasks/GET_INSTORE_TASK_KIT_MAPPINGS_SUCCESS',
  GET_INSTORE_TASK_KIT_MAPPINGS_FAILED: '@@instore-tasks/GET_INSTORE_TASK_KIT_MAPPINGS_FAILED',

  GET_INSTORE_TASK_CAMPAIGNS_REQUEST: '@@instore-tasks/GET_INSTORE_TASK_CAMPAIGNS_REQUEST',
  GET_INSTORE_TASK_CAMPAIGNS_SUCCESS: '@@instore-tasks/GET_INSTORE_TASK_CAMPAIGNS_SUCCESS',
  GET_INSTORE_TASK_CAMPAIGNS_FAILED: '@@instore-tasks/GET_INSTORE_TASK_CAMPAIGNS_FAILED',

  GET_INSTORE_TASK_KIT_AVAILABLE_DAYS_REQUEST: '@@instore-tasks/GET_INSTORE_TASK_KIT_AVAILABLE_DAYS_REQUEST',
  GET_INSTORE_TASK_KIT_AVAILABLE_DAYS_SUCCESS: '@@instore-tasks/GET_INSTORE_TASK_KIT_AVAILABLE_DAYS_SUCCESS',
  GET_INSTORE_TASK_KIT_AVAILABLE_DAYS_FAILED: '@@instore-tasks/GET_INSTORE_TASK_KIT_AVAILABLE_DAYS_FAILED',

  CREATE_INSTORE_TASK_ANIMATION_REQUEST: '@@instore-tasks/CREATE_INSTORE_TASK_ANIMATION_REQUEST',
  CREATE_INSTORE_TASK_ANIMATION_SUCCESS: '@@instore-tasks/CREATE_INSTORE_TASK_ANIMATION_SUCCESS',
  CREATE_INSTORE_TASK_ANIMATION_FAILED: '@@instore-tasks/CREATE_INSTORE_TASK_ANIMATION_FAILED',

  UPDATE_INSTORE_TASK_ANIMATION_REQUEST: '@@instore-tasks/UPDATE_INSTORE_TASK_ANIMATION_REQUEST',
  UPDATE_INSTORE_TASK_ANIMATION_SUCCESS: '@@instore-tasks/UPDATE_INSTORE_TASK_ANIMATION_SUCCESS',
  UPDATE_INSTORE_TASK_ANIMATION_FAILED: '@@instore-tasks/UPDATE_INSTORE_TASK_ANIMATION_FAILED',

  GET_INSTORE_TASK_ANIMATION_PHOTOS_REQUEST: '@@instore-tasks/GET_INSTORE_TASK_ANIMATION_REQUEST',
  GET_INSTORE_TASK_ANIMATION_PHOTOS_SUCCESS: '@@instore-tasks/GET_INSTORE_TASK_ANIMATION_SUCCESS',
  GET_INSTORE_TASK_ANIMATION_PHOTOS_FAILED: '@@instore-tasks/GET_INSTORE_TASK_ANIMATION_FAILED',

  GET_INSTORE_TASK_MONITORING_LIST_REQUEST: '@@instore-tasks/GET_INSTORE_TASK_MONITORING_LIST_REQUEST',
  GET_INSTORE_TASK_MONITORING_LIST_SUCCESS: '@@instore-tasks/GET_INSTORE_TASK_MONITORING_LIST_SUCCESS',
  GET_INSTORE_TASK_MONITORING_LIST_FAILED: '@@instore-tasks/GET_INSTORE_TASK_MONITORING_LIST_FAILED',

  GET_INSTORE_TASK_STATS_FILES_REQUEST: '@@instore-tasks/GET_INSTORE_TASK_STATS_FILES_REQUEST',
  GET_INSTORE_TASK_STATS_FILES_SUCCESS: '@@instore-tasks/GET_INSTORE_TASK_STATS_FILES_SUCCESS',
  GET_INSTORE_TASK_STATS_FILES_FAILED: '@@instore-tasks/GET_INSTORE_TASK_STATS_FILES_FAILED',

  GET_INSTORE_TASK_KPIS_FILES_REQUEST: '@@instore-tasks/GET_INSTORE_TASK_KPIS_FILES_REQUEST',
  GET_INSTORE_TASK_KPIS_FILES_SUCCESS: '@@instore-tasks/GET_INSTORE_TASK_KPIS_FILES_SUCCESS',
  GET_INSTORE_TASK_KPIS_FILES_FAILED: '@@instore-tasks/GET_INSTORE_TASK_KPIS_FILES_FAILED',
};
