import React, {useState} from 'react';

import find from 'lodash/find';
import {useSelector} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {selectActiveTemplate} from 'client/ducks/email-templates/selectors';

import SelectDropdown from 'client/common/selects/select-dropdown';

type ExternalLinkTabProps = {
  onChange: (data: {disabled: false; src?: string; variableName?: string}) => void;
};

const VariableTab: React.FC<ExternalLinkTabProps> = (props) => {
  const {onChange} = props;
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.MODALS.INSERT_IMAGE_MODAL.VARIABLE_TAB');
  const activeTemplate = useSelector(selectActiveTemplate);
  const [imageId, setImageId] = useState<number | null>(null);

  const handleChange = (id: number) => {
    setImageId(id);
    const image = find(activeTemplate?.email_template_images, {id});
    onChange({disabled: false, src: image?.file_url, variableName: image?.title});
  };

  return (
    <SelectDropdown
      label={lang.NAME}
      placeholder={lang.SELECT_VARIABLE}
      options={activeTemplate?.email_template_images?.map((i) => ({label: i.title, value: i.id})) || []}
      value={imageId}
      onChange={handleChange}
      simpleValue
    />
  );
};

export default VariableTab;
