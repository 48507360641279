import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';

export default class TestDrawStep extends PureComponent {
  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState;
  }

  renderPrize = (prize) => {
    const {condition, id, level, name, time_unit} = prize;
    const {kind} = this.props.step.implementation;

    return (
      <div key={id}>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{`${this.LANGUAGE.PRIZE_LEVEL_TEXT} ${level}`}</div>
            <div className="fake-input__field">{name}</div>
          </div>
        </div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{this.LANGUAGE.WINNER_PER_TEXT}</div>
            <div className="fake-input__field">
              {kind === this.LANGUAGE.KIND_LABEL.TIME && `${condition} ${this.LANGUAGE.TIME_UNITS[time_unit]}`}
              {kind === this.LANGUAGE.KIND_LABEL.PERSONS && `${condition} ${this.LANGUAGE.KIND_LABEL.PERSONS}`}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPrizes = (prizes) => {
    return prizes.map((prize) => this.renderPrize(prize));
  };

  render() {
    const {comment, implementation} = this.props.step;
    const {lottery_scenario_step_prizes} = implementation;

    return (
      <div>
        {this.renderPrizes(lottery_scenario_step_prizes)}
        {comment && comment.length > 0 && (
          <div className="test-step__form-block">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{this.LANGUAGE.COMMENT_TEXT}</div>
              <div className="fake-input__textarea">{comment}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

TestDrawStep.propTypes = {
  step: PropTypes.object,
  languageState: PropTypes.object.isRequired,
};
