import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function clearPlansState() {
  return {
    type: types.CLEAR_PLANS_STATE,
  };
}

export function getPlan(id, queryParams = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        queryParams,
        endpoint: `${API_PATH}${API_METHODS.PLANS}/${id}`,
        method: 'GET',
        types: [types.GET_PLAN_REQUEST, types.GET_PLAN_SUCCESS, types.GET_PLAN_ERROR],
      },
      true,
    );
  };
}

export function getPlans(queryParams = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        queryParams,
        endpoint: `${API_PATH}${API_METHODS.PLANS}`,
        method: 'GET',
        types: [types.GET_PLANS_REQUEST, types.GET_PLANS_SUCCESS, types.GET_PLANS_ERROR],
      },
      true,
    );
  };
}
