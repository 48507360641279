import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getVariables} from 'client/ducks/scenario-run-table/actions';
import {selectVariables} from 'client/ducks/scenario-run-table/selectors';
import {getScenarioSteps} from 'client/ducks/scenario/actions';

// components
import Modal from 'client/common/modals/modal';

import StepsList from '../../result-steps-list';

import './run-table-result-modal.scss';

class RunTableResultModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    getScenarioSteps: PropTypes.func.isRequired,
    getVariables: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    scenarioSteps: PropTypes.array,
    variables: PropTypes.array,
    scenarioExecution: PropTypes.object,
  };

  static defaultProps = {
    show: false,
    onClose: () => {},
    usedVariables: [],
    variables: [],
    scenarioSteps: [],
    scenarioExecution: {},
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.RESULT_MODAL;
  }

  componentDidUpdate(prevProps) {
    const {show, scenarioExecution} = this.props;
    if (show && !prevProps.show) {
      this.props.getScenarioSteps({
        q: {
          s: 'position asc',
          scenario_id_eq: scenarioExecution.scenario_id,
        },
        include: [
          {
            implementation: [
              'lottery_scenario_step_prizes',
              'recipient_variable',
              'email_template',
              {
                email_scenario_step_mapping_items: ['email_template_variable', 'variable'],
              },
              'message_sender',
              {
                social_account: ['client'],
              },
              'scenario',
              'social_scenario_step_images',
              'scenario.scenario_step_variables',
              {
                branch_scenario_step_conditions: ['variable', 'value_variable'],
              },
            ],
          },
        ],
      });

      const ids = scenarioExecution.scenario.scenario_variables
        .concat(scenarioExecution.scenario.scenario_step_variables || [])
        .map((i) => i.id);

      if (ids.length > 0) {
        this.props.getVariables({
          q: {
            id_in: ids,
          },
        });
      }
    }
  }

  render() {
    const {LANGUAGE} = this;
    const {show, onClose, scenarioExecution, scenarioSteps, variables} = this.props;
    const snapshotSteps = scenarioExecution.scenario_step_snapshots || [];

    const scenarioStepsIds = scenarioSteps.map((step) => step.id);

    const steps = snapshotSteps.reduce(
      (acc, step) => (scenarioStepsIds.includes(step.id) ? acc : [...acc, step]),
      scenarioSteps,
    );

    return (
      <Modal
        show={show}
        onClose={onClose}
        title={LANGUAGE.TITLE}
        dialogClassName="modal-window--width-3 theme-color-9 run-table-result-modal"
      >
        <StepsList
          scenarioSteps={steps}
          variables={variables}
          stepStates={scenarioExecution.scenario_step_states}
          variableValues={scenarioExecution.variable_values}
          scenarioErrors={scenarioExecution.third_party_interaction_error_log_records}
          stoppedBy={scenarioExecution.stopped_by}
          endedAt={scenarioExecution.ended_at}
        />
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-4 modal-window__footer-btn" onClick={onClose}>
            {LANGUAGE.OK_BUTTON}
          </button>
        </div>
      </Modal>
    );
  }
}

export default connect(
  ({languageState, scenario, ...state}) => ({
    languageState,
    scenarioSteps: scenario.payload.scenario_steps,
    variables: selectVariables(state),
  }),
  {
    getScenarioSteps,
    getVariables,
  },
)(RunTableResultModal);
