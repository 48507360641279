import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getAnonymousLead(token) {
  return (dispatch) => {
    return callApi(dispatch, {
      queryParams: {token},
      endpoint: `${API_PATH}${API_METHODS.ANONYMOUS_LEADS}`,
      method: 'GET',
      types: [types.GET_ANONYMOUS_LEAD_REQUEST, types.GET_ANONYMOUS_LEAD_SUCCESS, types.GET_ANONYMOUS_LEAD_ERROR],
    });
  };
}

export function updateAnonymousLead(token, body) {
  return (dispatch) => {
    return callApi(dispatch, {
      queryParams: {token},
      endpoint: `${API_PATH}${API_METHODS.ANONYMOUS_LEADS}`,
      method: 'PATCH',
      types: [
        types.UPDATE_ANONYMOUS_LEAD_REQUEST,
        types.UPDATE_ANONYMOUS_LEAD_SUCCESS,
        types.UPDATE_ANONYMOUS_LEAD_ERROR,
      ],
      body,
    });
  };
}
