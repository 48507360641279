import types from './types';

let init = {
  payload: [],
};

export default function (_state_ = init, action) {
  let state = Object.assign({}, _state_);

  switch (action.type) {
    case types.SET_BREADCRUMBS:
      state.payload = action.payload;
      return state;
    case types.CLEAR_BREADCRUMBS:
      state.payload = action.payload;
      return state;
    default:
      return state;
  }
}
