import React, {Component} from 'react';

import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {deleteMethod, patch} from 'client/services/fetch';

import {
  getColumnsAction,
  getSystemColumnsAction,
  getVisibleColumnsAction,
} from 'client/ducks/custom-operation-database/actions';

import {API_METHODS} from 'client/common/config';
// components
import Modal from 'client/common/modals/modal';
import Popover from 'client/common/popovers/popover';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';
import Rollout from 'client/components/common/rollout';

import './cod-table-settings-modal.scss';

class CustomOperationTableSettingsModal extends Component {
  static isColumnOn(columns) {
    return columns.some(({checked}) => checked);
  }

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.CUSTOM_OPERATION_DATABASE.TABLE_SETTINGS_MODAL;

    this.state = {
      customColumns: [],
      visibleColumns: [],
      systemColumns: [],
      checkedVisibleColumns: [],
      checkedCustomColumns: false,
      checkedSystemColumns: false,
      enabledSwitcher: false,
      enabledArrowUp: false,
      enabledArrowDown: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      show,
      customOpDB: {visibleColumns, columns: customColumns, systemColumns},
    } = this.props;

    if (show && !prevProps.show) {
      this.setState({
        customColumns: [],
        visibleColumns: [],
        systemColumns: [],
        checkedVisibleColumns: [],
        checkedCustomColumns: false,
        checkedSystemColumns: false,
        enabledSwitcher: false,
        enabledArrowUp: false,
        enabledArrowDown: false,
      });

      const {operationId} = this.props;
      this.props.getVisibleColumns(operationId).then(() => {
        this.props.getCustomColumns(operationId);
        this.props.getSystemColumns(operationId);
      });
    }

    if (visibleColumns !== prevProps.customOpDB.visibleColumns) {
      let newVisibleColumns = sortBy(visibleColumns, 'position');
      if (this.state.checkedVisibleColumns.length) {
        newVisibleColumns = newVisibleColumns.map((item, i) => ({
          ...item,
          checked: this.state.checkedVisibleColumns[i],
        }));
      }
      this.setState({
        visibleColumns: newVisibleColumns,
        checkedVisibleColumns: [],
      });
      this.isMovingNow = false;
    }

    if (customColumns !== prevProps.customOpDB.columns) {
      const visible = visibleColumns.map((i) => i.column_adapter.column_id);
      this.setState({
        customColumns: customColumns.map((c) => (visible.includes(c.id) ? {...c, hidden: true} : c)),
      });
    }

    if (systemColumns !== prevProps.customOpDB.systemColumns) {
      const visible = visibleColumns.map((i) => i.column_adapter_id);
      this.setState({
        systemColumns: systemColumns.map((c) => (visible.includes(c.id) ? {...c, hidden: true} : c)),
      });
    }
  }

  renderTooltip = () => <span id="tooltip-1">{this.LANGUAGE.DELETE_ITEM_TOOLTIP}</span>;

  toggleColumns =
    (isSystem) =>
    ({currentTarget}) => {
      const columnsName = isSystem ? 'systemColumns' : 'customColumns';
      const checkedName = isSystem ? 'checkedSystemColumns' : 'checkedCustomColumns';
      const columns = this.state[columnsName].slice().map((el) => ({
        ...el,
        checked: currentTarget.checked,
      }));
      this.setState({[columnsName]: columns, [checkedName]: currentTarget.checked}, () => {
        this.setState({
          enabledSwitcher:
            CustomOperationTableSettingsModal.isColumnOn(this.state.systemColumns) ||
            CustomOperationTableSettingsModal.isColumnOn(this.state.customColumns),
        });
      });
    };

  handleTransition = () => {
    this.forceUpdate();
  };

  handleColumnCheck = ({target}, isSystem = false) => {
    const chosenName = target.getAttribute('data-name');
    let changedCols = (isSystem ? this.state.systemColumns : this.state.customColumns)
      .slice()
      .map((item) => (item.name === chosenName ? Object.assign(item, {checked: target.checked}) : item));

    if (isSystem) {
      this.setState({systemColumns: changedCols});
      this.setState({
        enabledSwitcher:
          CustomOperationTableSettingsModal.isColumnOn(changedCols) ||
          CustomOperationTableSettingsModal.isColumnOn(this.state.customColumns),
      });
    } else {
      this.setState({customColumns: changedCols});
      this.setState({
        enabledSwitcher:
          CustomOperationTableSettingsModal.isColumnOn(changedCols) ||
          CustomOperationTableSettingsModal.isColumnOn(this.state.systemColumns),
      });
    }
  };

  moveColumn = () => {
    const {systemColumns, customColumns} = this.state;
    const fromSystem = systemColumns.filter(({checked, hidden}) => checked && !hidden);
    const fromCustom = customColumns.filter(({checked, hidden}) => checked && !hidden);
    const {visibleColumns} = this.props.customOpDB;

    const startPosition = visibleColumns.length ? sortBy(visibleColumns, 'position').pop().position + 1 : 0;
    const postData = this.mapDisplayedColumns([...fromSystem, ...fromCustom], startPosition);

    patch(`${API_METHODS.OPERATIONS}/${this.props.operationId}`, {
      operation: {
        operation_datum_display_items: postData,
      },
    }).then(() => {
      this.props.getVisibleColumns(this.props.operationId).then(this.props.updateMe);
      this.setState({
        checkedSystemColumns: false,
        checkedCustomColumns: false,
        enabledSwitcher: false,
        enabledArrowUp: false,
        enabledArrowDown: false,
      });
    });

    fromSystem.concat(fromCustom).forEach((i) => {
      i.hidden = true;
    });
  };

  mapDisplayedColumns = (columns, startPosition = 0) => {
    let position = startPosition;
    return columns.map((el) => ({
      operation_id: this.props.operationId,
      column_adapter_id: el.external_column_adapter ? el.external_column_adapter.id : el.id,
      position: position++,
    }));
  };

  deleteDisplayedColumn = (column) => () => {
    const {systemColumns, customColumns} = this.state;
    const isSystem = column.column_adapter.type === 'SystemColumnAdapter';

    deleteMethod(`${API_METHODS.OPERATION_DATUM_DISPLAY_ITEMS}/${column.id}`).then(() => {
      const found = isSystem
        ? find(systemColumns, (c) => c.id === column.column_adapter.id)
        : find(customColumns, (c) => c.external_column_adapter.id === column.column_adapter.id);
      found.checked = found.hidden = false;

      this.props.getVisibleColumns(this.props.operationId).then(this.props.updateMe);
    });
  };

  handleVisibleColumnCheck =
    (item) =>
    ({target}) => {
      item.checked = target.checked;
      const visibleColumns = this.state.visibleColumns.slice();
      this.setState({visibleColumns});
      this.checkArrowsStatus(visibleColumns);
    };

  checkArrowsStatus = (changedCols) => {
    if (CustomOperationTableSettingsModal.isColumnOn(changedCols)) {
      this.setState({
        enabledArrowUp: !changedCols[0].checked,
        enabledArrowDown: !changedCols[changedCols.length - 1].checked,
      });
    } else {
      this.setState({enabledArrowUp: false, enabledArrowDown: false});
    }
  };

  moveColumnsUpDown =
    (down = true) =>
    () => {
      if (this.isMovingNow) {
        return;
      }
      this.isMovingNow = true;
      let visibleColumns = this.state.visibleColumns.slice();
      const offset = down ? 1 : -1;

      if (down) {
        visibleColumns.reverse();
      }

      for (let i = 0; i < visibleColumns.length; i++) {
        const item = visibleColumns[i];
        if (item.checked) {
          item.position += offset;
          visibleColumns[i - 1].position -= offset;
          visibleColumns = sortBy(visibleColumns, 'position');
          if (down) {
            visibleColumns.reverse();
          }
        }
      }
      if (down) {
        visibleColumns.reverse();
      }

      this.checkArrowsStatus(visibleColumns);
      const checked = visibleColumns.map((i) => i.checked);
      patch(`${API_METHODS.OPERATIONS}/${this.props.operationId}`, {
        operation: {
          operation_datum_display_items: visibleColumns
            .map((i) => ({
              id: i.id,
              _destroy: true,
            }))
            .concat(
              visibleColumns.map((item, i) => ({
                column_adapter_id: item.column_adapter_id,
                position: i,
              })),
            ),
        },
      }).then(() =>
        this.setState({checkedVisibleColumns: checked}, () => this.props.getVisibleColumns(this.props.operationId)),
      );
    };

  render() {
    return (
      <Modal
        show={this.props.show}
        onClose={this.props.onClose}
        title={this.LANGUAGE.TITLE}
        dialogClassName="modal-window--width-2 modal-window--theme-4 cod-table-settings-modal"
      >
        <div className="row pos-rel">
          <div className="modal-window__order-btns">
            <button
              disabled={!this.state.enabledArrowUp}
              onClick={this.moveColumnsUpDown(false)}
              className="button button--bg-4 button--circle modal-window__order-btn-up"
            >
              <Icon className="button__icon theme-color-9" name="back" />
            </button>
            <button
              disabled={!this.state.enabledArrowDown}
              onClick={this.moveColumnsUpDown(true)}
              className="button button--bg-4 button--circle modal-window__order-btn-down"
            >
              <Icon className="button__icon theme-color-9" name="back" />
            </button>
          </div>
          <button
            disabled={!this.state.enabledSwitcher}
            className="button button--bg-4 button--circle modal-window__transfer-btn"
            onClick={this.moveColumn}
          >
            <Icon className="button__icon theme-color-9" name="back" />
          </button>
          <div className="col-xs-6 modal-window__col-left">
            <div className="modal-window__descr">{this.LANGUAGE.ALL_COLUMNS}</div>
            <div className="content-group theme-color-9">
              <CustomScrollbars
                cssModifier="custom-scrollbars--view-1"
                scrollbarProps={{
                  hideTracksWhenNotNeeded: true,
                  autoHeightMax: 482,
                  autoHeightMin: 482,
                }}
              >
                <Rollout
                  title={
                    <label className="checkbox-button theme-color-9">
                      <input
                        className="checkbox-button__input"
                        type="checkbox"
                        name="checkbox-custom"
                        value="0"
                        checked={this.state.checkedCustomColumns}
                        onChange={this.toggleColumns(false)}
                      />
                      <div className="checkbox-button__label">
                        <span className="icon-checked checkbox-button__icon" />
                        <span className="checkbox-button__text">
                          <span className="main-text main-text--bold">{this.LANGUAGE.CUSTOM_COLUMNS_GROUP}</span>
                        </span>
                      </div>
                    </label>
                  }
                  className="rollout--expand-1"
                  onTransition={this.handleTransition}
                  isOpened={true}
                >
                  <CustomScrollbars
                    scrollbarProps={{
                      hideTracksWhenNotNeeded: true,
                      autoHeightMax: 200,
                    }}
                  >
                    <div className="list list--view-3">
                      <div className="list__group">
                        {this.state.customColumns.map((item, index) => (
                          <div key={index} className={`list__item ${item.hidden ? 'hidden' : ''}`}>
                            <label className="checkbox-button checkbox-button--view-1 checkbox-button">
                              <input
                                type="checkbox"
                                data-name={item.name}
                                className="checkbox-button__input"
                                checked={item.checked || false}
                                onChange={this.handleColumnCheck}
                              />
                              <div className="checkbox-button__label">
                                <span className="icon-checked checkbox-button__icon" />
                                <span className="checkbox-button__text">{item.name}</span>
                              </div>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </CustomScrollbars>
                </Rollout>
                <Rollout
                  title={
                    <label className="checkbox-button theme-color-9">
                      <input
                        className="checkbox-button__input"
                        type="checkbox"
                        name="checkbox-system"
                        value="0"
                        checked={this.state.checkedSystemColumns}
                        onChange={this.toggleColumns(true)}
                      />
                      <div className="checkbox-button__label">
                        <span className="icon-checked checkbox-button__icon" />
                        <span className="checkbox-button__text">
                          <span className="main-text main-text--bold">{this.LANGUAGE.SYSTEM_COLUMNS_GROUP}</span>
                        </span>
                      </div>
                    </label>
                  }
                  className="rollout--expand-1 no-border-bottom"
                  onTransition={this.handleTransition}
                  isOpened={true}
                >
                  <CustomScrollbars
                    scrollbarProps={{
                      hideTracksWhenNotNeeded: true,
                      autoHeightMax: 200,
                    }}
                  >
                    <div className="list list--view-3">
                      <div className="list__group">
                        {this.state.systemColumns.map((item, index) => (
                          <div className={`list__item ${item.hidden ? 'hidden' : ''}`} key={index}>
                            <label className="checkbox-button checkbox-button--view-1 checkbox-button">
                              <input
                                type="checkbox"
                                data-name={item.name}
                                className="checkbox-button__input"
                                checked={item.checked || false}
                                onChange={(e) => {
                                  this.handleColumnCheck(e, true);
                                }}
                              />
                              <div className="checkbox-button__label">
                                <span className="icon-checked checkbox-button__icon" />
                                <span className="checkbox-button__text">{this.LANGUAGE.SYSTEM_COLUMNS[item.name]}</span>
                              </div>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </CustomScrollbars>
                </Rollout>
              </CustomScrollbars>
            </div>
          </div>
          <div className="col-xs-6 modal-window__col-right">
            <div className="modal-window__descr">{this.LANGUAGE.DISPLAYED_COLUMNS}</div>
            <div className="content-group theme-color-9">
              <CustomScrollbars
                scrollbarProps={{
                  hideTracksWhenNotNeeded: true,
                  autoHeightMax: 482,
                  autoHeightMin: 482,
                }}
              >
                <div className="list list--view-2">
                  {this.state.visibleColumns.map((item, index) => (
                    <div className="list__item pos-rel" key={index}>
                      <label className="checkbox-button checkbox-button--view-1 checkbox-button">
                        <input
                          type="checkbox"
                          className="checkbox-button__input"
                          data-name={item.name}
                          checked={item.checked || false}
                          onChange={this.handleVisibleColumnCheck(item)}
                        />
                        <div className="checkbox-button__label">
                          <span className="icon-checked checkbox-button__icon" />
                          <span className="checkbox-button__text">
                            {this.LANGUAGE.SYSTEM_COLUMNS[item.column_adapter.name] || item.column_adapter.name}
                          </span>
                        </div>
                      </label>
                      <Popover position="right" overlay={this.renderTooltip()}>
                        <span
                          className="list__del-item-btn"
                          data-name={item.name}
                          onClick={this.deleteDisplayedColumn(item)}
                        >
                          <Icon name="clear" className="list__del-item-icon" />
                        </span>
                      </Popover>
                    </div>
                  ))}
                </div>
              </CustomScrollbars>
            </div>
          </div>
        </div>
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-4 modal-window__footer-btn" onClick={this.props.onConfirm}>
            {this.LANGUAGE.CONFIRM_BUTTON}
          </button>
        </div>
      </Modal>
    );
  }
}

CustomOperationTableSettingsModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  operationId: PropTypes.number,
  getCustomColumns: PropTypes.func,
  getSystemColumns: PropTypes.func,
  updateMe: PropTypes.func,
  getVisibleColumns: PropTypes.func,
  languageState: PropTypes.object.isRequired,
  customOpDB: PropTypes.shape({
    systemColumns: PropTypes.array,
    visibleColumns: PropTypes.array,
    columns: PropTypes.array,
  }),
};

CustomOperationTableSettingsModal.defaultProps = {
  show: false,
  onConfirm: () => {},
  updateMe: () => {},
  onClose: () => {},
};

export default connect(({languageState, customOpDB}) => ({languageState, customOpDB}), {
  getCustomColumns: getColumnsAction,
  getSystemColumns: getSystemColumnsAction,
  getVisibleColumns: getVisibleColumnsAction,
})(CustomOperationTableSettingsModal);
