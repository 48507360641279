import React, {SyntheticEvent, useEffect, useMemo, useRef, useState} from 'react';

import isNumber from 'lodash/isNumber';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import Cropper from 'react-cropper';
import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectActiveTemplate} from 'client/ducks/email-templates/selectors';

import AppButton from 'client/common/buttons';
import Popover from 'client/common/popovers/popover';

import LoadingSpinner from 'client/components/common/loading-spinner';

import {ImageBlockData} from 'client/components/email-template-editor/types';

import {editImage, flipImageHorizontally, flipImageVertically, rotateImage} from './helpers';
import LinkAltFields from './link-alt-fields';
import {Toolbar} from './toolbar';

import cssModule from './image-editor.module.scss';

const b = bem('image-editor', {cssModule});

export type ImageEditorProps = {
  imageBlockData: ImageBlockData;
  isEditMode: boolean;
  defaultAlt?: string;
  defaultUrl?: string;
  onChange: (newData: ImageBlockData) => void;
  onChooseImage: () => void;
};

export type ImageParams = Omit<ImageBlockData, 'src'>;
type UndoRedoStack = (Omit<ImageBlockData, 'src'> & {src?: string})[];

export const ImageEditor: React.FC<ImageEditorProps> = (props) => {
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.MODALS.IMAGE_EDIT');
  const {imageBlockData, isEditMode, onChange, onChooseImage, defaultAlt = '', defaultUrl} = props;
  const activeTemplate = useSelector(selectActiveTemplate);
  const [undoStack, setUndoStack] = useState<UndoRedoStack>([]);
  const [redoStack, setRedoStack] = useState<UndoRedoStack>([]);
  const [isImageLoading, toggleIsImageLoading] = useToggle(false);
  const [isCropperLoading, toggleIsCropperLoading] = useToggle(false);
  const [linkAltFields, setLinkAltField] = useState({
    alt: defaultAlt || '',
    url: defaultUrl || '',
  });
  const [isCropEnabled, toggleCropEnabled] = useToggle(false);
  const [canCrop, toggleCanCrop] = useToggle(false);
  const [cropAspectRatio, setCropAspectRatio] = useState(0);
  const cropperRef = useRef<any>(null);
  const imageWrapperStyles = useMemo(
    () => ({
      paddingLeft: `${imageBlockData.paddingLeft ?? 0}px`,
      paddingRight: `${imageBlockData.paddingRight ?? 0}px`,
      paddingTop: `${imageBlockData.paddingTop ?? 0}px`,
      paddingBottom: `${imageBlockData.paddingBottom ?? 0}px`,
      backgroundColor: imageBlockData.backgroundColor ?? '',
    }),
    [imageBlockData],
  );
  const colorsAccessKey = `common-colors-${activeTemplate?.id}`;

  useEffect(() => {
    if (!isEditMode) {
      toggleCropEnabled(false);
    }
  }, [isEditMode, toggleCropEnabled]);

  useEffect(() => {
    if (!isCropEnabled) {
      toggleIsCropperLoading(false);
    }
  }, [isCropEnabled, toggleIsCropperLoading]);

  useEffect(() => {
    if (imageBlockData.width && imageBlockData.height) {
      setCropAspectRatio(imageBlockData.width / imageBlockData.height);
    }
  }, [imageBlockData.height, imageBlockData.width]);

  const handleImageLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.currentTarget;

    toggleIsImageLoading(false);

    onChange({
      ...imageBlockData,
      width: target.naturalWidth,
      height: target.naturalHeight,
    });
  };

  const handleChangeLinkAlt = (data: {alt: string; url: string}) => {
    setLinkAltField(data);
    onChange({
      ...imageBlockData,
      altText: data.alt,
      redirectUrl: data.url,
    });
  };

  const handleParamsChange = (value: ImageParams) => {
    const currentImageParams = omit(imageBlockData, 'src');

    toggleCropEnabled(false);
    setUndoStack((prevState) => [...prevState, {...currentImageParams}]);

    if ('isHorizontalPaddingsPaired' in value && value.isHorizontalPaddingsPaired) {
      value.paddingRight = currentImageParams.paddingLeft ?? 0;
    } else if ('isVerticalPaddingsPaired' in value && value.isVerticalPaddingsPaired) {
      value.paddingBottom = currentImageParams.paddingTop ?? 0;
    }

    if (currentImageParams.isHorizontalPaddingsPaired !== false) {
      if (isNumber(value.paddingLeft)) {
        value.paddingRight = value.paddingLeft;
      } else if (isNumber(value.paddingRight)) {
        value.paddingLeft = value.paddingRight;
      }
    }

    if (currentImageParams.isVerticalPaddingsPaired !== false) {
      if (isNumber(value.paddingTop)) {
        value.paddingBottom = value.paddingTop;
      } else if (isNumber(value.paddingBottom)) {
        value.paddingTop = value.paddingBottom;
      }
    }

    onChange({
      ...imageBlockData,
      ...value,
    });
  };

  const handleImageChange = async (action: string, value?: string | number | Record<string, string | number>) => {
    toggleIsImageLoading(true);
    toggleCropEnabled(false);

    const urlData = await editImage(imageBlockData.src, (img, canvas) => {
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        return;
      }

      if (action === 'flip-h') {
        flipImageHorizontally(img, canvas, ctx);
      } else if (action === 'flip-v') {
        flipImageVertically(img, canvas, ctx);
      } else if (action === 'rotate-l') {
        rotateImage(img, canvas, ctx, -90);
      } else if (action === 'rotate-r') {
        rotateImage(img, canvas, ctx, 90);
      } else if (action === 'size') {
        if (value && typeof value === 'object' && value.width && value.height) {
          canvas.width = +value.width;
          canvas.height = +value.height;
        }
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
      }
    });

    if (urlData) {
      setUndoStack((prevState) => [...prevState, {src: imageBlockData.src}]);

      onChange({
        ...imageBlockData,
        src: urlData,
      });
    }
  };

  const handleUndo = () => {
    if (!undoStack.length) {
      return;
    }

    toggleCropEnabled(false);

    const changes = undoStack[undoStack.length - 1];
    if (changes.src) {
      toggleIsImageLoading(true);
    }

    onChange({
      ...imageBlockData,
      ...changes,
    });

    setUndoStack((prevState) => prevState.slice(0, -1));
    setRedoStack((prevState) => [...prevState, pick(imageBlockData, Object.keys(changes))]);
  };

  const handleRedo = () => {
    if (!redoStack.length) {
      return;
    }

    toggleCropEnabled(false);

    const changes = redoStack[redoStack.length - 1];
    if (changes.src) {
      toggleIsImageLoading(true);
    }

    onChange({
      ...imageBlockData,
      ...changes,
    });

    setRedoStack((prevState) => prevState.slice(0, -1));
    setUndoStack((prevState) => [...prevState, pick(imageBlockData, Object.keys(changes))]);
  };

  const handleChooseImage = () => {
    onChooseImage();
  };

  const handleCropToggle = (state: boolean) => {
    if (state && !isCropEnabled) {
      toggleIsCropperLoading(true);
    }
    toggleCropEnabled(state);
  };

  const handleCropAspectRatioChange = (value: number) => {
    setCropAspectRatio(value);
    toggleCanCrop(true);
  };

  const handleImageCrop = () => {
    const cropper = cropperRef.current?.cropper as Cropper;
    const newSrc = cropper.getCroppedCanvas().toDataURL();
    if (newSrc) {
      setUndoStack((prevState) => [...prevState, {src: imageBlockData.src}]);
      onChange({
        ...imageBlockData,
        src: newSrc,
      });
    }
    toggleCropEnabled(false);
  };

  return (
    <div className={b()}>
      {isEditMode && (
        <div>
          <Toolbar
            imageBlockData={imageBlockData}
            canUndo={undoStack.length > 0}
            canRedo={redoStack.length > 0}
            colorsAccessKey={colorsAccessKey}
            onImageChange={handleImageChange}
            onParamsChange={handleParamsChange}
            onCropToggle={handleCropToggle}
            onCropAspectRatioChange={handleCropAspectRatioChange}
            onChooseImage={handleChooseImage}
            onUndo={handleUndo}
            onRedo={handleRedo}
          />
          <LinkAltFields className={b('link-alt-panel')} values={linkAltFields} onChange={handleChangeLinkAlt} />
        </div>
      )}
      <figure
        className={b('image-wrap', [`align-${imageBlockData.alignment}`, isEditMode ? '' : 'clickable'])}
        style={imageWrapperStyles}
      >
        {(isImageLoading || isCropperLoading) && (
          <div className={b('image-loader')}>
            <LoadingSpinner loading={true} />
          </div>
        )}
        <span className={b('image-container')}>
          {isCropEnabled && (
            <>
              <Cropper
                ref={cropperRef}
                className={b('image-cropper')}
                src={imageBlockData.src}
                zoomOnWheel={false}
                aspectRatio={cropAspectRatio}
                autoCropArea={1}
                ready={() => toggleIsCropperLoading(false)}
                readOnly={true}
                cropend={() => toggleCanCrop(true)}
              />
              <Popover
                overlay={lang.SAVE_CHANGES}
                className={b('apply-crop-btn-popover')}
                position="top"
                hideOnMouseLeave={true}
              >
                <AppButton
                  className={b('apply-crop-btn')}
                  asWrap={true}
                  iconName="check"
                  onClick={handleImageCrop}
                  disabled={!canCrop}
                />
              </Popover>
            </>
          )}
          <img className={b('image')} src={imageBlockData.src} alt={defaultAlt} onLoad={handleImageLoad} />
        </span>
      </figure>
    </div>
  );
};
