import {callApi} from 'client/services/call-api';

import {API_PATH} from 'client/common/config';

import types from './types';

export function getCoupons(queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}/coupons`,
        method: 'GET',
        types: [types.GET_COUPONS_REQUEST, types.GET_COUPONS_SUCCESS, types.GET_COUPONS_ERROR],
        queryParams,
      },
      true,
    );
  };
}

export const createCoupon = (type, body) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}/${type}_coupons`,
        method: 'POST',
        types: [types.CREATE_COUPON_REQUEST, types.CREATE_COUPON_SUCCESS, types.CREATE_COUPON_ERROR],
        body,
      },
      true,
    );
  };
};

export const updateCoupon = (id, type, body) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}/${type}_coupons/${id}`,
        method: 'PATCH',
        types: [types.UPDATE_COUPON_REQUEST, types.UPDATE_COUPON_SUCCESS, types.UPDATE_COUPON_ERROR],
        body,
      },
      true,
    );
  };
};

export const deleteCoupon = (id, type) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}/${type}_coupons/${id}`,
        method: 'DELETE',
        types: [types.DELETE_COUPON_REQUEST, types.DELETE_COUPON_SUCCESS, types.DELETE_COUPON_ERROR],
      },
      true,
    );
  };
};

export const testSogec = (body) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}/sogec_coupons/test`,
        method: 'POST',
        types: [types.TEST_SOGEC_REQUEST, types.TEST_SOGEC_SUCCESS, types.TEST_SOGEC_ERROR],
        body,
      },
      true,
    );
  };
};
