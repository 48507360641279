import React, {PureComponent} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

export default class TestDrawStep extends PureComponent {
  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState;
  }

  renderPrize = (prize) => {
    const {condition, id, level, name} = prize;

    return (
      <div className="test-step__form-block" key={id}>
        <div className="fake-input fake-input--disabled">
          <div className="test-step__form-field-wrap test-step__form-field-wrap--flex">
            <div className="test-step__form-label text-nowrap">
              <span>{`${this.LANGUAGE.PRIZE_LEVEL_TEXT} ${level}`}</span>
            </div>
            <div className="test-step__form-field test-step__form-field--size-1">
              <div className="fake-input__field">{name}</div>
            </div>
          </div>
          <div className="test-step__form-field-wrap test-step__form-field-wrap--flex">
            <div className="test-step__form-label">{this.LANGUAGE.NUMBER_TEXT}</div>
            <div className="test-step__form-field test-step__form-field--size-1">
              <div className="fake-input__field">{condition}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPrizes = (prizes) => {
    return prizes.map((prize) => this.renderPrize(prize));
  };

  render() {
    const {comment, implementation} = this.props.step;
    const {draw_at, from, to, lottery_scenario_step_prizes} = implementation;

    return (
      <div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{this.LANGUAGE.PARTICIPATION_TEXT}</div>
            <div className="test-step__form-field-wrap test-step__form-field-wrap--flex">
              <div className="test-step__form-label">{this.LANGUAGE.FROM_TEXT}</div>
              <div className="test-step__form-field test-step__form-field--size-1">
                <div className="fake-input__field">{moment(from).format('HH:mm DD/MM/YYYY')}</div>
              </div>
            </div>
            <div className="test-step__form-field-wrap test-step__form-field-wrap--flex">
              <div className="test-step__form-label">{this.LANGUAGE.TO_TEXT}</div>
              <div className="test-step__form-field test-step__form-field--size-1">
                <div className="fake-input__field">{moment(to).format('HH:mm DD/MM/YYYY')}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{this.LANGUAGE.DRAW_TEXT}</div>
            <div className="test-step__form-field-wrap test-step__form-field-wrap--flex">
              <div className="test-step__form-label">{this.LANGUAGE.AT_TEXT}</div>
              <div className="test-step__form-field test-step__form-field--size-1">
                <div className="fake-input__field">{moment(draw_at).format('HH:mm DD/MM/YYYY')}</div>
              </div>
            </div>
          </div>
        </div>
        {this.renderPrizes(lottery_scenario_step_prizes)}
        {comment && comment.length > 0 && (
          <div className="test-step__form-block">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{this.LANGUAGE.COMMENT_TEXT}</div>
              <div className="fake-input__textarea">{comment}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

TestDrawStep.propTypes = {
  formValues: PropTypes.object,
  step: PropTypes.object,
  languageState: PropTypes.object.isRequired,
};
