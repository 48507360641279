import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {ToggleInput} from 'client/common/inputs';
import DatepickerInput from 'client/common/inputs/datepicker-input';
import CarouselImageSelect from 'client/common/selects/carousel-image-select';
import SelectDropdown from 'client/common/selects/select-dropdown';

import LanguageDevicePanel from 'client/components/diy-operation/controls/language-device-panel';
import {
  ANSWER_KEY_MAP,
  INPUT_TYPES,
  FORCED_INPUT,
  OVERALL_INPUT,
} from 'client/components/diy-operation/modals/diy-customization-modal/constants';
import {CUSTOM_INPUTS} from 'client/components/diy-operation/modals/diy-customization-modal/constants';
import {
  removeTags,
  toggleDefaultStyleTag,
} from 'client/components/diy-operation/modals/diy-customization-modal/helpers';
import {TranslationJsx} from 'client/models/language/types';

import {convertDateValue} from './helpers';

import ColorInputItem from '../color-input-item';
import ImageInputItem from '../form-item/image-input-item';
import TextInputItem from '../text-input-item';
import ToggleInputItem from '../toggle-input-item';

import './form-item-field.scss';

const b = bem('form-item-field');

const componentWrapperMap = {
  [INPUT_TYPES.FIELD]: TextInputItem,
  [INPUT_TYPES.DROPDOWN]: SelectDropdown,
  [INPUT_TYPES.TOGGLE]: ToggleInputItem,
  [INPUT_TYPES.COLOR]: ColorInputItem,
  [INPUT_TYPES.CAROUSEL]: (props) => (
    <CarouselImageSelect
      carouselProps={{
        width: 'xs',
        slidesPerView: 4,
        scrollbar: {draggable: true},
        navigation: true,
      }}
      {...props}
    />
  ),
  [INPUT_TYPES.IMAGE]: ImageInputItem,
};

const componentSubtypeMap = {
  [INPUT_TYPES.DATE]: (props) => (
    <DatepickerInput
      {...props}
      type={null}
      className={b('datepicker')}
      errorClassName={b('datepicker-error')}
      useDirectChange={true}
    />
  ),
};

const CONTROL_TYPES = {
  LANGUAGE: 'language',
  DEVICE: 'device',
};

const FormItemField = (props) => {
  const {
    name,
    formItemId,
    type,
    value,
    fieldProps,
    disabled,
    onChange,
    errorMessage,
    lang,
    defaultLanguage,
    languages,
    setLang,
    device,
    defaultDevice,
    devices,
    setDevice,
    langMode,
    deviceMode,
  } = props;

  const translations = useLanguage('DIY_OPERATION.MODALS.CONFIGURATION_MODAL');

  const Component = componentSubtypeMap[fieldProps?.type] || componentWrapperMap[type];

  // method to get template answer by lang/device
  const getValue = (valueLanguage = lang, valueDevice = device) => {
    let fieldValue = value;

    const languageForSelect = CUSTOM_INPUTS.includes(valueLanguage) ? defaultLanguage : valueLanguage;
    const deviceForSelect = CUSTOM_INPUTS.includes(valueDevice) ? defaultDevice : valueDevice;

    switch (type) {
      case INPUT_TYPES.IMAGE:
        const getMediaFieldValue = (val) => val?.url || val?.preview_url || '';
        const currentImage = value.find(
          (val) => val.language === languageForSelect && val.screen_format === deviceForSelect,
        );

        fieldValue = getMediaFieldValue(get(currentImage, ANSWER_KEY_MAP[type], {}));

        break;

      case INPUT_TYPES.FIELD:
        const currentValue = value.find((val) => val.language === languageForSelect);

        if (fieldProps?.type === 'text') {
          fieldValue = get(currentValue, ANSWER_KEY_MAP[INPUT_TYPES.FIELD], '');

          if (!fieldProps.displayStyling) {
            fieldValue = removeTags(fieldValue);
          }
        }

        if (fieldProps?.type === 'number') {
          fieldValue = get(currentValue, 'number_field', '');
        }

        if (type === INPUT_TYPES.FIELD && fieldProps?.type === 'date') {
          const val = value?.[0] || {};
          const rawValue = get(val, ANSWER_KEY_MAP[INPUT_TYPES.DATE], '');
          fieldValue = convertDateValue(rawValue);
        }
        break;

      default:
        fieldValue = value;
        break;
    }

    return fieldValue;
  };

  const synchronizeValues = (controlType) => {
    const defaultValue = getValue(
      controlType === CONTROL_TYPES.LANGUAGE ? defaultLanguage : lang,
      controlType === CONTROL_TYPES.DEVICE ? defaultDevice : device,
      false,
    );

    const langToChange = controlType === CONTROL_TYPES.LANGUAGE ? OVERALL_INPUT : lang;
    const deviceToChange = controlType === CONTROL_TYPES.DEVICE ? OVERALL_INPUT : device;

    onChange(defaultValue, langToChange, deviceToChange, true);
  };

  const handleLanguageCommonToggle = (isCommon) => {
    setLang(isCommon ? OVERALL_INPUT : defaultLanguage);

    if (isCommon) {
      synchronizeValues(CONTROL_TYPES.LANGUAGE);
    }
  };

  const handleDeviceCommonToggle = (isCommon) => {
    setDevice(isCommon ? OVERALL_INPUT : defaultDevice);

    if (isCommon) {
      synchronizeValues(CONTROL_TYPES.DEVICE);
    }
  };

  return (
    <>
      {fieldProps?.displayStyling && (
        <ToggleInput
          className={b('style-toggle')}
          label={translations.DEFAULT_STYLE}
          labelPosition="right"
          rounded={true}
          borderColor="current"
          checked={fieldProps.defaultStyle}
          disabled={disabled}
          onChange={({target}) => onChange(toggleDefaultStyleTag(getValue(), target.checked), lang, device)}
        />
      )}
      <LanguageDevicePanel
        prefix={`${formItemId}-${name}-${type}`}
        language={lang}
        languages={langMode === FORCED_INPUT ? null : languages}
        device={device}
        devices={deviceMode === FORCED_INPUT ? null : devices}
        onChangeLanguage={setLang}
        onChangeDevice={setDevice}
        className={b('language-panel')}
        langInitiallyCommon={langMode === OVERALL_INPUT}
        deviceInitiallyCommon={deviceMode === OVERALL_INPUT}
        onLanguageCommonToggle={handleLanguageCommonToggle}
        onDeviceCommonToggle={handleDeviceCommonToggle}
        disabled={Boolean(errorMessage)}
      />

      <Component
        name={name}
        disabled={disabled}
        value={getValue()}
        {...fieldProps}
        onChange={(val) => onChange(val, lang, device)}
        errorMessage={errorMessage}
      />
    </>
  );
};

FormItemField.propTypes = {
  lang: PropTypes.string,
  defaultLanguage: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.object),
  device: PropTypes.string,
  defaultDevice: PropTypes.string,
  devices: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  formItemId: PropTypes.number.isRequired,
  formId: PropTypes.number.isRequired,
  type: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      template_id: PropTypes.number,
      boolean_field: PropTypes.bool,
      string_field: PropTypes.string,
      text_field: PropTypes.string,
      form_choices: PropTypes.array,
      image_urls: PropTypes.array,
      language: PropTypes.string,
      screen_format: PropTypes.string,
    }),
  ),
  value: PropTypes.any,
  formChoices: PropTypes.arrayOf(
    PropTypes.shape({
      form_labels: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ),
  fieldProps: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lines: PropTypes.number,
    fieldType: PropTypes.string,
    display_multi: PropTypes.bool,
    item_type: PropTypes.string,
    type: PropTypes.string,
    display_image_format: PropTypes.array,
  }),
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
  disabled: PropTypes.bool,
  setLang: PropTypes.func,
  setDevice: PropTypes.func,
  onChange: PropTypes.func,
  langMode: PropTypes.string,
  deviceMode: PropTypes.string,
  setFormData: PropTypes.func.isRequired,
};

FormItemField.defaultProps = {
  lang: 'en',
  errorMessage: null,
  langMode: null,
  deviceMode: null,
};
export default FormItemField;
