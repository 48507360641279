import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useQueryParams} from 'client/services/hooks';

import Offscreen from 'client/common/offscreen';

import Spinner from 'client/components/common/spinner';

import AnimationQuestionnairesBlock from 'client/components/animations/blocks/animation-questionnaires-block';
import {ANIMATION_STATUSES} from 'client/components/instore/constants';
import {useFetchCampaigns} from 'client/components/instore/hooks/use-fetch-campaigns';
import useSetDefaultCampaign from 'client/components/instore/hooks/use-set-default-campaign';
import InstoreCampaignSelect from 'client/components/instore/inputs/instore-campaign-select';
import InstoreReportingAnimationList from 'client/components/instore/lists/instore-reporting-animation-list';

import cssModule from './instore-reporting-container.module.scss';

const b = bem('instore-reporting-container', {cssModule});

const InstoreReportingContainer = (props) => {
  const {task} = props;
  const [queryParams, setQueryParams] = useQueryParams(
    null,
    {},
    {
      stringify: {
        skipEmptyString: true,
      },
    },
  );
  const {loadingCampaigns, campaigns} = useFetchCampaigns({taskId: task.id});
  const isLoaded = loadingCampaigns;

  useSetDefaultCampaign({campaigns});

  if (!task) {
    return null;
  }

  const onChangeCampaign = (campaignId) => {
    if (campaignId !== queryParams.campaignId) {
      setQueryParams({campaignId}, {replace: true});
    }
  };

  const filters = {
    ...queryParams?.filters,
    interaction_group: +queryParams.campaignId || null,
    statuses: queryParams.filters?.status
      ? [queryParams.filters?.status]
      : [ANIMATION_STATUSES.REPORT, ANIMATION_STATUSES.FINISHED],
  };

  return (
    <div className={b()}>
      {isLoaded && <Spinner color="primary" centered />}
      <Offscreen hidden={!!isLoaded}>
        {campaigns.length > 1 && (
          <InstoreCampaignSelect
            campaigns={campaigns}
            value={+queryParams.campaignId}
            onChange={onChangeCampaign}
            showAllOption={true}
          />
        )}
        <AnimationQuestionnairesBlock className={b('questionnaires')} filters={filters} showAllQuestions={true} />
        <InstoreReportingAnimationList task={task} />
      </Offscreen>
    </div>
  );
};

InstoreReportingContainer.propTypes = {
  task: PropTypes.object,
};

InstoreReportingContainer.defaultProps = {
  task: null,
};

export default InstoreReportingContainer;
