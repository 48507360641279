import React, {useMemo} from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import GoogleMap, {MarkerType} from 'client/common/google-map';
import Modal from 'client/common/modals/modal';

import {DeviceAffectation} from 'client/models/device-affectations';

import {DevicesMapMarker} from './devices-map-marker';

import cssModule from './devices-map-modal.module.scss';

const b = bem('devices-map-modal', {cssModule});

type DevicesMapModalProps = {
  points: DeviceAffectation[];
  onClose: () => void;
  className?: string;
};

const DevicesMapModal: React.FC<DevicesMapModalProps> = (props) => {
  const {className, onClose, points} = props;

  const markers: MarkerType<DeviceAffectation>[] = useMemo(
    () =>
      points
        .filter((a) => a.place)
        .map((affectation) => ({
          id: affectation.id,
          lat: affectation.place!.latitude,
          lng: affectation.place!.longitude,
          data: affectation,
        })),
    [points],
  );

  return (
    <Modal
      className={cn(b(), className)}
      onClose={onClose}
      classNames={{
        content: b('content'),
        close: b('close'),
        body: b('body'),
      }}
    >
      <GoogleMap
        markers={markers}
        renderMarker={(marker: MarkerType<DeviceAffectation>, map) => <DevicesMapMarker marker={marker} map={map} />}
      />
    </Modal>
  );
};

export default DevicesMapModal;
