import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {connect} from 'react-redux';

import {deleteApiLeadsExport} from 'client/ducks/leads-export/actions';
import {deleteApiLeadsImport} from 'client/ducks/leads-import/actions';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {API_PATH} from 'client/common/config';
import Icon from 'client/common/icon';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Popover from 'client/common/popovers/popover';

import LeadsGenerateApiExportModal from 'client/components/leads/components/modals/leads-generate-api-export-modal';

import './leads-api-export-settings-popover.scss';

class LeadsApiExportSettingsPopover extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    children: PropTypes.node,
    leadsApiExport: PropTypes.shape({
      id: PropTypes.number,
      full_name: PropTypes.string,
      from: PropTypes.string,
      to: PropTypes.string,
      memberships: PropTypes.array,
      allowed_for_all: PropTypes.bool,
      type: PropTypes.string,
    }).isRequired,
    clientId: PropTypes.number.isRequired,
    deleteApiLeadsExport: PropTypes.func.isRequired,
    deleteApiLeadsImport: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
    columnAdapters: PropTypes.array.isRequired,
    clientUsers: PropTypes.array.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  state = {
    showDeleteModal: false,
    showUpdateModal: false,
  };

  isImport = () => {
    return this.props.leadsApiExport.type === 'ApiLeadImport';
  };

  toggleDeleteModal = () => {
    this.setState(({showDeleteModal}) => {
      return {showDeleteModal: !showDeleteModal};
    });
  };

  toggleUpdateModal = () => {
    this.setState(({showUpdateModal}) => {
      return {showUpdateModal: !showUpdateModal};
    });
  };

  onDeleteExport = () => {
    const method = this.isImport() ? this.props.deleteApiLeadsImport : this.props.deleteApiLeadsExport;

    method(this.props.leadsApiExport.id).then(() => this.props.refresh());

    this.toggleDeleteModal();
  };

  onUpdateExport = () => {
    this.props.refresh();
    this.toggleUpdateModal();
  };

  getShowingModal = () => {
    const {leadsApiExport, clientId, clientUsers, columnAdapters, lang} = this.props;
    const {showDeleteModal, showUpdateModal} = this.state;
    const isImport = this.isImport();

    if (showDeleteModal) {
      return (
        <div>
          <ConfirmationModal
            show={true}
            title={isImport ? lang.SETTINGS_POPOVER_DELETE_IMPORT_TEXT : lang.SETTINGS_POPOVER_DELETE_TEXT}
            message={isImport ? lang.SETTINGS_POPOVER_DELETE_IMPORT_TEXT : lang.SETTINGS_POPOVER_DELETE_MESSAGE}
            buttonConfirmClass="button--bg-2"
            className="theme-color-2"
            cancelText={lang.DELETE_MODAL.CANCEL}
            confirmText={lang.DELETE_MODAL.CONFIRM}
            onCancel={this.toggleDeleteModal}
            onClose={this.toggleDeleteModal}
            onConfirm={this.onDeleteExport}
          />
          <Icon name="settings" />
        </div>
      );
    }

    if (showUpdateModal) {
      return (
        <div>
          <LeadsGenerateApiExportModal
            show={true}
            onClose={this.onUpdateExport}
            onCancel={this.toggleUpdateModal}
            clientId={clientId}
            columns={columnAdapters}
            users={clientUsers}
            editingExport={leadsApiExport}
          />
          <Icon name="settings" />
        </div>
      );
    }

    return null;
  };

  render() {
    const {children, leadsApiExport, isAdmin, lang} = this.props;
    const {id, from, to, memberships, allowed_for_all} = leadsApiExport;
    const isImport = this.isImport();

    const apiUrl = isImport
      ? `${API_PATH}/api_lead_imports/${id}/perform`
      : `${API_PATH}/api_lead_exports/${id}/perform`;

    const modal = this.getShowingModal();

    if (modal) {
      return modal;
    }

    return (
      <Popover
        position="bottom"
        className="pull-left"
        arrowConfig={90}
        overlayInnerStyle={{
          width: 340,
        }}
        overlay={
          <div className="popover-list popover-list--color-2">
            <h3 className="popover-list__title">{lang.SETTINGS_POPOVER_TITLE}</h3>
            <h4 className="input__label leads-api-export-settings-popover__title">{lang.COPY_ID}</h4>
            <div className="leads-api-export-settings-popover__row-container">
              <span className="leads-api-export-settings-popover__text">{id}</span>
              <CopyToClipboard text={id}>
                <button className="button button--circle button--bg-2">
                  <Icon name="copy" />
                </button>
              </CopyToClipboard>
            </div>
            <h4 className="input__label leads-api-export-settings-popover__title">{lang.COPY_URL}</h4>
            <div className="leads-api-export-settings-popover__row-container">
              <span
                className="leads-api-export-settings-popover__text leads-api-export-settings-popover__link-api"
                title={apiUrl}
              >
                {apiUrl}
              </span>
              <CopyToClipboard text={apiUrl}>
                <button className="button button--circle button--bg-2">
                  <Icon name="copy" />
                </button>
              </CopyToClipboard>
            </div>
            <h4 className="input__label leads-api-export-settings-popover__title">
              {isImport ? lang.SETTINGS_POPOVER_PERIOD_IMPORT : lang.SETTINGS_POPOVER_PERIOD_EXPORT}
            </h4>
            <div className="leads-api-export-settings-popover__text">{`${from} - ${to}`}</div>
            <h4 className="input__label leads-api-export-settings-popover__title">
              {lang.SETTINGS_POPOVER_USER_ALLOWED}
            </h4>
            {allowed_for_all && <div className="leads-api-export-settings-popover__text">{lang.ALLOW_ALL_LABEL}</div>}
            {!allowed_for_all && !memberships.length && (
              <div className="leads-api-export-settings-popover__text">{lang.NO_ALLOWED_USERS}</div>
            )}
            {!allowed_for_all &&
              memberships.map((item) => {
                return (
                  <div key={item.id} className="leads-api-export-settings-popover__text">
                    {item.client_user.full_name}
                  </div>
                );
              })}
            {isAdmin && !isImport && (
              <div
                className="
                leads-api-export-settings-popover__row-container
                leads-api-export-settings-popover__container-margin
              "
              >
                <span>{lang.SETTINGS_POPOVER_MODIFY_API}</span>
                <button className="button button--circle button--bg-2" onClick={this.toggleUpdateModal}>
                  <Icon name="edit" width={20} height={20} />
                </button>
              </div>
            )}
            {isAdmin && (
              <div
                className="
                leads-api-export-settings-popover__row-container
                leads-api-export-settings-popover__container-margin
              "
              >
                <span className="leads-api-export-settings-popover__delete-text">
                  {isImport ? lang.SETTINGS_POPOVER_DELETE_IMPORT_TEXT : lang.SETTINGS_POPOVER_DELETE_TEXT}
                </span>
                <button className="button button--circle button--bg-2" onClick={this.toggleDeleteModal}>
                  <Icon name="trash" width={17} height={19} />
                </button>
              </div>
            )}
          </div>
        }
      >
        {children}
      </Popover>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.languageState.payload.LEADS_PORT,
    isAdmin: selectIsAdmin(state),
  }),
  {
    deleteApiLeadsExport,
    deleteApiLeadsImport,
  },
)(LeadsApiExportSettingsPopover);
