import get from 'lodash/get';

import {EmailTemplateVariable} from 'client/models/email-templates/types';
import {Prize} from 'client/models/prizes/types';

const prizePropertyMap = {
  prize_name: 'external_name_translations.{{lang}}',
  code: 'code',
  description: 'external_description_translations.{{lang}}',
  loyalty_amount: 'loyalty_amount',
  loyalty_unit: 'loyalty_unit',
  token: 'email_mocked_params.string_token',
};

const couponPrizePropertyMap = {
  ...prizePropertyMap,
  loyalty_amount: 'coupon.loyalty_amount',
  loyalty_unit: 'coupon.loyalty_unit',
};

const getValue = (variable: EmailTemplateVariable, prize?: Prize, language?: string) => {
  if (Object.keys(prizePropertyMap).includes(variable.name)) {
    const map = prize?.coupon_type ? couponPrizePropertyMap : prizePropertyMap;
    const key = variable.name as keyof typeof map;
    const path = map[key]?.replace('{{lang}}', language || '');
    return get(prize, path) || '';
  }

  return variable.test_value;
};

export const replaceVariables = (
  rawHtml: string,
  variables: EmailTemplateVariable[],
  prize?: Prize,
  language?: string,
) => {
  return variables.reduce((res, variable) => {
    return res.replaceAll(`{{var:${variable.name}}}`, getValue(variable, prize, language));
  }, rawHtml);
};
