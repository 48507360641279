import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';
import Spinner from 'client/components/common/spinner';

import {TranslationJsx} from 'client/models/language/types';

import './dashboard-card.scss';

const DashboardCard = (props) => {
  const {
    children,
    className,
    title,
    iconName,
    empty,
    emptyIconName,
    emptyText,
    emptyHeight,
    contentHeight,
    loading,
    loadingColor,
  } = props;

  const contentStyle = contentHeight ? {height: contentHeight + 'px'} : null;
  const emptyStyle = emptyHeight ? {height: emptyHeight + 'px'} : null;

  return (
    <div className={cn(['dashboard-card', className])}>
      <div className="dashboard-card__header">
        {iconName && <Icon name={iconName} className="dashboard-card__icon" />}
        {title && <span className="dashboard-card__title">{title}</span>}
      </div>
      <div className="dashboard-card__content" style={contentStyle}>
        {loading && <Spinner color={loadingColor} centered />}
        {!empty && !loading && children}
        {empty && !loading && (
          <div className="dashboard-card__empty" style={emptyStyle}>
            <Icon name={emptyIconName} className="dashboard-card__empty-icon" />
            <div className="dashboard-card__empty-text">{emptyText}</div>
          </div>
        )}
      </div>
    </div>
  );
};

DashboardCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: TranslationJsx,
  iconName: PropTypes.string,
  empty: PropTypes.bool,
  emptyIconName: PropTypes.string,
  emptyText: TranslationJsx,
  emptyHeight: PropTypes.number,
  contentHeight: PropTypes.number,
  loading: PropTypes.bool,
  loadingColor: PropTypes.string,
};

DashboardCard.defaultProps = {
  children: null,
  className: null,
  title: null,
  iconName: null,
  empty: false,
  emptyIconName: 'bars',
  emptyText: null,
  emptyHeight: null,
  contentHeight: null,
  loading: false,
  loadingColor: 'primary',
};

export default DashboardCard;
