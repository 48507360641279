import {Dispatch} from 'redux';

import {ToastNotification} from 'client/models/toast-notification/types';

import types from './types';

export const addToastNotifications = (toastNotification: ToastNotification) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.ADD_TOAST_NOTIFICATIONS,
      toastNotification,
    });
  };
};

export const removeToastNotifications = (id: number | string) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.REMOVE_TOAST_NOTIFICATIONS,
      id,
    });
  };
};
