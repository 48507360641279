import React from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectInterfaceDefaultLanguage} from 'client/ducks/operations/selectors';

import {INSTANT_WIN_TABS} from 'client/components/prizes/fieldsets/prize-diy-instant-win-fieldset/constants';
import {SCHEDULE_TABS} from 'client/components/prizes/fieldsets/prize-diy-schedule-fieldset/constants';
import {PrizeMapCustomizationFormName} from 'client/components/prizes/forms/prizemap-customization-form';

import cssModule from './prize-map-name-info.module.scss';

const b = bem('prize-map-name-info', {cssModule});

const PrizeMapNameInfo = () => {
  const lang = useLanguage('PRIZES.ELEMENTS.PRIZE_MAP_NAME_INFO');
  const defaultLanguage = useSelector(selectInterfaceDefaultLanguage);

  const {formValues} = useReduxForm(PrizeMapCustomizationFormName);
  const {
    instant_win_configuration,
    external_prize_name_translations,
    external_prize_name,
    prize_type,
    prize_internal_name,
  } = formValues;
  const {schedule_type, type} = instant_win_configuration || {}; // can be null
  const isFullWin = formValues.prize?.full_win;

  const prizeTypes = {
    Prize: lang.COMMON,
    LoyaltyPrize: lang.LOYALTY,
  };

  const instantWinTypes = {
    [INSTANT_WIN_TABS.RATIO_TIME]: lang.RATIO_TIME,
    [INSTANT_WIN_TABS.RATIO_PARTICIPATION]: lang.RATIO_PARTICIPATION,
    [INSTANT_WIN_TABS.SCHEDULE]: lang.SCHEDULED_MOMENTS,
  };

  const scheduleTypes = {
    [SCHEDULE_TABS.CUSTOM_LIST]: lang.MANUAL,
    [SCHEDULE_TABS.SCHEDULE]: lang.CALCULATED_RULE,
    [SCHEDULE_TABS.WEEKLY_LIST]: lang.FIX_WEEK,
  };

  const scheduleType = scheduleTypes[schedule_type];

  return (
    <div className={b()}>
      <span>
        {external_prize_name_translations[defaultLanguage] || external_prize_name} /{' '}
        {prizeTypes[prize_type as 'Prize' | 'LoyaltyPrize']}
      </span>

      {/* full win prize doesn't have instant win configuration */}
      {!isFullWin && (
        <span>
          {prize_internal_name} {type && '/'} {instantWinTypes[type]} {scheduleType && '/'} {scheduleType}
        </span>
      )}
    </div>
  );
};

export default PrizeMapNameInfo;
