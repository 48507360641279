import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import fieldTemplate from 'client/components/common/field';

class TestBranchForm extends PureComponent {
  constructor() {
    super();
  }

  radioChangeHandler = (e) => {
    const {value} = e.target;
    const {change, id} = this.props;

    switch (value) {
      case 'normal':
        change(`_${id}`, {
          name: 'normal',
        });
        break;
      case 'false':
        change(`_${id}`, {
          name: 'skip',
          condition: false,
        });
        break;
      case 'true':
        change(`_${id}`, {
          name: 'skip',
          condition: true,
        });
        break;
      default:
        break;
    }
  };

  render() {
    const {id, languageState: LANGUAGE} = this.props;
    return (
      <div>
        <div className="test-step__form-block">
          <Field
            label={LANGUAGE.VALIDATE_LABEL}
            name={`_${id}._condition`}
            type="radio"
            value="normal"
            onChange={this.radioChangeHandler}
            component={fieldTemplate}
          />
        </div>
        <div className="test-step__form-block">
          <Field
            label={LANGUAGE.IMITATE_MET_LABEL}
            name={`_${id}._condition`}
            type="radio"
            value="true"
            onChange={this.radioChangeHandler}
            component={fieldTemplate}
          />
        </div>
        <div className="test-step__form-block">
          <Field
            label={LANGUAGE.IMITATE_NOT_MET_LABEL}
            name={`_${id}._condition`}
            type="radio"
            value="false"
            onChange={this.radioChangeHandler}
            component={fieldTemplate}
          />
        </div>
      </div>
    );
  }
}

TestBranchForm.propTypes = {
  id: PropTypes.number,
  change: PropTypes.func,
  initialize: PropTypes.func,
  form: PropTypes.string,
  initialValues: PropTypes.object,
  languageState: PropTypes.object.isRequired,
};

export default TestBranchForm;
