import React from 'react';

import {capitalizeFirstLetter} from 'client/services/formatters';
import {useLanguage, useReduxFetch, useReduxForm} from 'client/services/hooks';

import {getClientsCheckSiren} from 'client/ducks/client-account/actions';
import {interpolate} from 'client/ducks/language/helpers';

import {FieldWrap, TextField, SelectField} from 'client/common/fields';
import {useToast} from 'client/common/hooks/useToast';

import {AgencyCreateClientModalFormName} from '../../index';
import {AgencyCreateClientModalFormValues} from '../../types';

let timeout: ReturnType<typeof setTimeout>;

const SIREN_COUNTRY = 'France';

type AgencyCreateClientStep1Props = {
  formValues: AgencyCreateClientModalFormValues;
};

const AgencyCreateClientStep1: React.FC<AgencyCreateClientStep1Props> = ({formValues}) => {
  const lang = useLanguage('AGENCIES.MODALS.AGENCY_CREATE_CLIENT_MODAL.STEPS.AGENCY_CREATE_CLIENT_STEP1');
  const langCommon = useLanguage('COMMON.COUNTRIES');

  const {appendToastNotification} = useToast();

  const {change, unregisterField, setErrors, setWarnings} = useReduxForm<AgencyCreateClientModalFormValues>(
    AgencyCreateClientModalFormName,
  );

  const {fetch} = useReduxFetch<{real: boolean; clients_count: number}>({
    action: getClientsCheckSiren,
    fetchOnMount: false,
  });

  const options = Object.entries(langCommon).map(([key, label]) => {
    let value = capitalizeFirstLetter(key.toLowerCase());

    if (key === 'UK') {
      value = 'United Kingdom';
    }

    return {
      value,
      label,
    };
  });

  const handleCountryChange = (country: string) => {
    if (country !== SIREN_COUNTRY) {
      change('siren_number', '');
      unregisterField('siren_number');
      setErrors({siren_number: ''});
    }
    change('country', country);
  };

  const handleSirenChange = (e: {target: {value: number}}) => {
    clearTimeout(timeout);
    const sirenNumber = e.target.value;

    const isValidNumber = sirenNumber.toString().length >= 9;

    if (isValidNumber) {
      timeout = setTimeout(async () => {
        if (sirenNumber) {
          setErrors({siren_number: ''});
          try {
            const response = await fetch(sirenNumber);
            if (response?.payload?.real) {
              if (response?.payload.clients_count) {
                setWarnings({
                  siren_number: interpolate(lang.SIREN_EXISTS?.toString(), {
                    siren: response?.payload.clients_count,
                  }),
                });
              }
              setErrors({siren_number: ''});
            } else {
              setErrors({siren_number: lang.SIREN_DOES_NOT_EXIST});
              setWarnings({
                siren_number: '',
              });
            }
          } catch {
            setErrors({siren_number: ''});
            setWarnings({
              siren_number: '',
            });
            appendToastNotification({
              type: 'error',
              title: lang.SIREN_CHECKING_SERVICE_DOES_NOT_RESPOND_TITLE,
              description: lang.SIREN_CHECKING_SERVICE_DOES_NOT_RESPOND_DESCRIPTION,
            });
          }
        }
      }, 300);
    }
  };

  return (
    <>
      <FieldWrap title={lang.COUNTRY_LABEL}>
        <SelectField
          name="country"
          placeholder={lang.COUNTRY_PLACEHOLDER}
          required={true}
          simpleValue={true}
          onChange={handleCountryChange}
          options={options}
          withWrap={true}
        />
      </FieldWrap>
      {formValues.country === SIREN_COUNTRY && (
        <FieldWrap title={lang.SIREN_LABEL}>
          <TextField name="siren_number" required={true} withWrap={true} onChange={handleSirenChange} maxlength={9} />
        </FieldWrap>
      )}
    </>
  );
};

export default AgencyCreateClientStep1;
