import React, {PureComponent} from 'react';

import update from 'immutability-helper';
import flow from 'lodash/flow';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FieldArray, reduxForm} from 'redux-form';

import bem from 'client/services/bem';
import {required, number, maxLength, greaterThan, datetime, dateTimeIsAfter} from 'client/services/validator';

import {deleteLotteryScenarioStepPrize} from 'client/ducks/scenario/actions';

import AppButton from 'client/common/buttons';
import {SelectField, TextField, DatetimePickerField, TextareaField} from 'client/common/fields';

import {maxLengthNormalization, preventDefault, onSubmitSuccess, onSubmitFail} from '../helpers';

// re-use main form styles
// TODO: make own styles
import cssModule from './../../ats-step/ats-step.module.scss';

const createPrize = () => ({
  name: '',
  condition: 1,
});

const b = bem('ats-step', {cssModule});
class AtsPrizeDrawForm extends PureComponent {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    array: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    deleteLotteryScenarioStepPrize: PropTypes.func.isRequired,
    data: PropTypes.object,
  };

  LANGUAGE = this.props.languageState.payload.AUTOTASK_SCENARIO.SCENARIO_FORMS;

  rules = {
    name: [required(this.LANGUAGE.ERRORS.REQUIRED)],
    datetime: [datetime(this.LANGUAGE.ERRORS.INCORRECT_FORMAT)],
    to: [
      datetime(this.LANGUAGE.ERRORS.INCORRECT_FORMAT),
      dateTimeIsAfter(this.LANGUAGE.ERRORS.AFTER_PARTICIPATE_FROM, 'implementation.from'),
    ],
    draw_at: [
      datetime(this.LANGUAGE.ERRORS.INCORRECT_FORMAT),
      dateTimeIsAfter(this.LANGUAGE.ERRORS.AFTER_PARTICIPATE_TO, 'implementation.to'),
    ],
    prizeName: [required(this.LANGUAGE.ERRORS.REQUIRED)],
    prizeNumber: [
      required(this.LANGUAGE.ERRORS.REQUIRED),
      number(this.LANGUAGE.ERRORS.ONLY_NUMBERS),
      greaterThan(0, `${this.LANGUAGE.ERRORS.ONLY_NUMBERS} zero`),
      maxLength(7, `${this.LANGUAGE.ERRORS.MAXIMUM_LENGTH} 7 ${this.LANGUAGE.ERRORS.DIGITS}`),
    ],
    prizeTypeNumber: [required(this.LANGUAGE.ERRORS.REQUIRED)],
  };

  prizeTypeOptions = [
    {label: this.LANGUAGE.WIN_LABEL + '1', value: '1'},
    {label: this.LANGUAGE.WIN_LABEL + '2', value: '2'},
    {label: this.LANGUAGE.WIN_LABEL + '3', value: '3'},
  ];

  removePrize = (fields, index) => {
    const {data} = this.props;
    const field = fields.get(index);

    if (field.id) {
      this.props.deleteLotteryScenarioStepPrize(data.id, field.id).then(() => fields.remove(index));
    } else {
      fields.remove(index);
    }
  };

  renderPrizes = ({fields}) => {
    const onRemove = fields.length > 1 ? (index) => () => this.removePrize(fields, index) : () => null;

    return fields.map((field, index) => (
      <div key={index} className={b('form-item')}>
        {this.renderPrize(field, index, onRemove(index))}
      </div>
    ));
  };

  handleAddPrize = () => {
    this.props.array.push('implementation.lottery_scenario_step_prizes', createPrize());
  };

  renderPrize(prize, index, onRemove) {
    let removeButton = null;

    if (onRemove) {
      removeButton = (
        <AppButton
          onClick={onRemove}
          iconName="trash"
          rounded={true}
          color="text-additional"
          className={b('form-item-delete')}
        />
      );
    }

    return (
      <>
        <TextField
          name={`${prize}.name`}
          label={`${this.LANGUAGE.DRAW.PRIZE_LEVEL_LABEL} ${index + 1}`}
          validate={this.rules.prizeName}
          required={true}
          className={b('form-field-wrap', ['view-2'])}
        />
        <TextField
          label={this.LANGUAGE.DRAW.NUMBER_LABEL}
          name={`${prize}.condition`}
          validate={this.rules.prizeNumber}
          normalize={maxLengthNormalization(7)}
        />
        {removeButton}
      </>
    );
  }

  render() {
    return (
      <form onSubmit={preventDefault} className={b('form')}>
        <TextField
          label={this.LANGUAGE.STEP_NAME_LABEL}
          name="name"
          validate={this.rules.name}
          required={true}
          withWrap
        />
        <SelectField
          simpleValue={true}
          label={this.LANGUAGE.PRIZE_TYPE_LABEL}
          name="implementation.prize_type_number"
          searchable={false}
          options={this.prizeTypeOptions}
          validate={this.rules.prizeTypeNumber}
          required={true}
        />
        <div className={b('form-field', ['size-1'])}>
          <h4 className={b('form-title')}>{this.LANGUAGE.DRAW.PARTICIPATION_LABEL}</h4>
          <div className={b('form-row')}>
            <DatetimePickerField
              label={this.LANGUAGE.FROM_LABEL}
              name="implementation.from"
              validate={this.rules.datetime}
            />
          </div>
          <div className={b('form-row')}>
            <DatetimePickerField label={this.LANGUAGE.TO_LABEL} name="implementation.to" validate={this.rules.to} />
          </div>
        </div>
        <div className={b('form-field', ['size-1'])}>
          <h4 className={b('form-title')}>{this.LANGUAGE.DRAW.DRAW_LABEL}</h4>
          <DatetimePickerField
            label={this.LANGUAGE.AT_LABEL}
            name="implementation.draw_at"
            validate={this.rules.draw_at}
          />
        </div>
        <FieldArray name="implementation.lottery_scenario_step_prizes" component={this.renderPrizes} />
        <AppButton
          iconName="plus"
          onClick={this.handleAddPrize}
          label={this.LANGUAGE.DRAW.ADD_PRIZE_BUTTON}
          fontSize="small"
          color="text-additional"
        />
        <TextareaField label={this.LANGUAGE.COMMENT_LABEL} name="comment" />
      </form>
    );
  }
}

export const createImplementation = () => ({
  lottery_scenario_step_prizes: [createPrize()],
  from: moment(),
  to: moment().add(1, 'minutes'),
  draw_at: moment().add(2, 'minutes'),
});

const transformFormData = (values) =>
  update(values, {
    implementation: {
      lottery_scenario_step_prizes: {
        $apply: (prizes) =>
          prizes.map((prize, index) => ({
            ...prize,
            level: index + 1,
          })),
      },
      $merge: {
        from: values.implementation.from,
        to: values.implementation.to,
        draw_at: values.implementation.draw_at,
      },
    },
  });

const mapStateToProps = (state, props) => ({
  form: props.formId,
  initialValues: update(props.data, {
    implementation: {
      $merge: {
        from: moment(props.data.implementation.from),
        to: moment(props.data.implementation.to),
        draw_at: moment(props.data.implementation.draw_at),
      },
    },
  }),
  languageState: state.languageState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteLotteryScenarioStepPrize,
    },
    dispatch,
  );

export default flow([
  reduxForm({
    touchOnBlur: false,
    onSubmit: (values, dispatch, props) => {
      const transformedValues = transformFormData(values);

      return props.onSubmitForm(transformedValues);
    },
    onSubmitSuccess,
    onSubmitFail,
  }),
  connect(mapStateToProps, mapDispatchToProps),
])(AtsPrizeDrawForm);
