import {generatePath} from 'react-router';
import {routePaths} from 'routes/index';

import {getAccessLevel} from 'client/services/cookie-data-source';

import {CLIENT_PAGES, MEMBERSHIP_TO_ACCESS_LEVEL, USER_MEMBERSHIP} from 'client/common/config';

import {Autotask} from 'client/models/autotask/types';
import {Client} from 'client/models/client/types';
import {AccessLevelType} from 'client/models/common/types';
import {InstoreTask} from 'client/models/instore-tasks/types';
import {TASK_TYPES} from 'client/models/operations/constants';

import {OPERATION_STATUSES} from './constants';
import {Operation} from './types';

export const isOperationArchived = (operation?: Operation) => {
  if (!operation) {
    return false;
  }

  return operation.status === OPERATION_STATUSES.FINISHED;
};

export const isOperationStarted = (operation?: Operation) => {
  if (!operation) {
    return false;
  }

  return Boolean(operation.with_real_participations);
};

type GenerateClientOperationRouteParams = {
  clientId: number;
  operation: Operation;
  task: Autotask | InstoreTask;
  isConfigurationBlock?: boolean;
};

const weightByLevel = {
  [USER_MEMBERSHIP.CLIENT_ADMIN]: 3,
  [USER_MEMBERSHIP.NATIONAL]: 3,
  [USER_MEMBERSHIP.REGION]: 2,
  [USER_MEMBERSHIP.STORE]: 1,
};

export const generateClientOperationRoute = (params: GenerateClientOperationRouteParams) => {
  const {clientId, operation, task, isConfigurationBlock} = params;

  const linkCommon = {
    clientId,
    operationId: operation.id,
  };

  if (task.type === TASK_TYPES.INSTORE) {
    return generatePath(routePaths.client.InstoreTaskPage, {
      ...linkCommon,
      tab: CLIENT_PAGES.CLIENT_INSTORE_TASK_TABS.PLANNING.replace('/', ''),
      taskId: task.id,
    });
  } else if (task.type === TASK_TYPES.AUTOMATION) {
    const userAccessLevel = getAccessLevel();
    const {membership, available_membership} = operation;
    // available_membership - for case when operation is attributed to agency user
    const {access_level: operationAccessLevel} = membership || available_membership || {};

    const isSameLevelOp =
      !!operationAccessLevel &&
      !!userAccessLevel &&
      weightByLevel[+userAccessLevel] === weightByLevel[operationAccessLevel];
    const isRedirectToConfigTab = isSameLevelOp || isConfigurationBlock;

    return generatePath(routePaths.client.ClientAutotaskPage, {
      ...linkCommon,
      autotaskId: task.id,
      tab:
        operation.show_configuration && operation.client_automation_task_id === task.id && isRedirectToConfigTab
          ? CLIENT_PAGES.CLIENT_AUTOTASK_TABS.CONFIGURATION.replace('/', '')
          : CLIENT_PAGES.CLIENT_AUTOTASK_TABS.PARTICIPATIONS.replace('/', ''),
    });
  }

  return '';
};

type OperationHasConfigTabParams = {
  client: Client | null;
  membershipIsEditor: boolean;
  viewUserId?: number;
  accessLevel: AccessLevelType;
};

export const operationHasConfigTab = (params: OperationHasConfigTabParams) => {
  const {client, accessLevel, viewUserId, membershipIsEditor} = params;
  const {client_network, user_access_levels, auto_configuration} = client || {};
  const updatedAccessLevel = MEMBERSHIP_TO_ACCESS_LEVEL[accessLevel]; // updated values of access levels

  let result = client_network
    ? user_access_levels?.includes(updatedAccessLevel)
    : accessLevel === USER_MEMBERSHIP.CLIENT_ADMIN;

  if (auto_configuration) {
    result = !!viewUserId && [USER_MEMBERSHIP.CLIENT_ADMIN, USER_MEMBERSHIP.NATIONAL].includes(accessLevel);
  }

  if (!!viewUserId && client_network && accessLevel !== USER_MEMBERSHIP.LOCAL) {
    result = true;
  }

  if (result && !membershipIsEditor) {
    result = false;
  }

  return result;
};
