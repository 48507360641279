import isNull from 'lodash/isNull';

import {ObjectValues} from 'client/types/common';

const STATUSES = {
  true: 'VALIDATED',
  false: 'UNVALIDATED',
  published: 'PUBLISHED',
  unpublished: 'UNPUBLISHED',
  republished: 'UPDATED',
} as const;

export const getStatus = (
  value_after: boolean | keyof typeof STATUSES,
  value_before: boolean | keyof typeof STATUSES,
): ObjectValues<typeof STATUSES> => {
  if (isNull(value_after)) {
    return STATUSES[`${value_before}`];
  }

  return STATUSES[`${value_after}`];
};
