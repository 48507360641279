import {EmailTemplate} from 'client/models/email-templates/types';

export const getElementNextToHovered = (container: Element, y: number, exclude: Element) => {
  return Array.from(container.children).reduce<{offset: number; element: Element | null}>(
    (closest, child) => {
      if (child === exclude) {
        return closest;
      }

      const box = child.getBoundingClientRect();
      const offset = Math.floor(y - box.top - box.height / 2);
      if (offset < 0 && offset > closest.offset) {
        return {offset: offset, element: child};
      }

      return closest;
    },
    {offset: Number.NEGATIVE_INFINITY, element: null},
  ).element;
};

export const mapTemplateToEmailParams = (template?: EmailTemplate) => ({
  subject: template?.subject || '',
  previewText: template?.subject_preview || '',
  name: template?.sender_name || '',
  senderId: template?.email_sender_id || null,
  senderEmail: template?.sender_email || '',
  replyTo: template?.reply_to || '',
});
