import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {post} from 'client/services/fetch';
import {required, alphaNumeric} from 'client/services/validator';

import {API_METHODS} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

class NewPlanModal extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    autotaskId: PropTypes.number.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool,
  };

  static defaultProps = {
    show: false,
  };

  static formName = 'NewPlanModalForm';

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.NEW_PLAN_MODAL;

    this.rules = {
      name: [required(this.LANGUAGE.NAME_REQUIRED_ERROR), alphaNumeric(this.LANGUAGE.ERRORS.NAME_ALPHANUMERIC_ERROR)],
    };
  }

  submitHandler = ({name}) => {
    const {autotaskId, onConfirm} = this.props;

    return post(API_METHODS.PLANS, {
      plan: {
        name,
        automation_task_id: autotaskId,
      },
    }).then(({plan, errors}) => {
      if (errors) {
        throw new SubmissionError({
          name: errors.name && this.LANGUAGE.ERRORS.NAME_UNIQUE_ERROR,
        });
      }

      onConfirm(plan.id);
    });
  };

  render() {
    const {onClose, show, handleSubmit} = this.props;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-9 set-optin-modal"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={this.LANGUAGE.TITLE}
      >
        <form noValidate="noValidate" onSubmit={handleSubmit(this.submitHandler)}>
          <Field
            label={this.LANGUAGE.SCENARIO_LABEL}
            name="name"
            type="text"
            validate={this.rules.name}
            component={fieldTemplate}
          />
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onClose}>
              {this.LANGUAGE.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-4 modal-window__footer-btn" type="submit">
              {this.LANGUAGE.CREATE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const newPlanModalForm = reduxForm({
  form: NewPlanModal.formName,
})(NewPlanModal);

export default connect((state) => ({
  languageState: state.languageState,
}))(newPlanModalForm);
