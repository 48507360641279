import React from 'react';

import {components, MultiValueRemoveProps} from 'react-select';

import Icon from 'client/common/icon';
import {SelectOptionType} from 'client/common/selects/types';

const MultiValueRemove: React.FC<MultiValueRemoveProps<SelectOptionType>> = ({...props}) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon name="select-dropdown-remove" />
    </components.MultiValueRemove>
  );
};
export default MultiValueRemove;
