import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, initialize} from 'redux-form';

import fieldTemplate from 'client/components/common/field';
import Icon from 'client/components/common/icon';

import {TASK_TYPES} from 'client/models/operations/constants';

import OperationCard from '../operation-card';

class AddNewTaskCard extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onCreateClick: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
  };

  static formName = 'AddNewTaskCardForm';

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.OPERATION_PAGE;

    this.state = {
      btnAddTaskClicked: false,
    };
  }

  componentDidMount() {
    this.props.initialize({
      taskType: TASK_TYPES.AUTOMATION,
    });
  }

  save = ({taskType}) => {
    this.props.onCreateClick(taskType);
    this.setState({btnAddTaskClicked: false});
  };

  render() {
    const {LANGUAGE} = this;
    const {handleSubmit} = this.props;

    return (
      <OperationCard className="no-border-bottom">
        <div className="operation-page__add-task-block">
          {this.state.btnAddTaskClicked ? (
            <form onSubmit={handleSubmit(this.save)}>
              <label className="operation-page__add-task-block__label">{LANGUAGE.CHOOSE_TASK_LABEL}</label>
              <div className="operation-page__add-task-block__radio-set">
                <Field
                  label={LANGUAGE.AUTOMATION_TASK_LABEL}
                  name="taskType"
                  value={TASK_TYPES.AUTOMATION}
                  type="radio"
                  component={fieldTemplate}
                />
                <Field
                  label={LANGUAGE.BROADCASTING_EMAIL_LABEL}
                  name="taskType"
                  value={TASK_TYPES.EMAIL}
                  type="radio"
                  component={fieldTemplate}
                />
                <Field
                  label={LANGUAGE.BROADCASTING_SMS_LABEL}
                  name="taskType"
                  value={TASK_TYPES.SMS}
                  type="radio"
                  component={fieldTemplate}
                />
              </div>

              <div className="operation-page__add-task-block__bottom">
                <button
                  className="button button--bg-11 operation-page__add-task-block__bottom__btn"
                  type="button"
                  onClick={() => this.setState({btnAddTaskClicked: false})}
                >
                  {LANGUAGE.CANCEL_BUTTON}
                </button>
                <button
                  className="button button--bg-operations operation-page__add-task-block__bottom__btn"
                  type="submit"
                >
                  {LANGUAGE.NEXT_BUTTON}
                </button>
              </div>
            </form>
          ) : (
            <div className="operation-page__add-task-block__bottom">
              <button
                className="button button--bg-autotask operation-page__add-task-block__bottom__btn"
                onClick={() => this.setState({btnAddTaskClicked: true})}
              >
                <Icon name="plus" className="button__icon" />
                <span>{LANGUAGE.ADD_TASK_BUTTON}</span>
              </button>
            </div>
          )}
        </div>
      </OperationCard>
    );
  }
}

const addNewTaskForm = reduxForm({
  form: AddNewTaskCard.formName,
})(AddNewTaskCard);

export default connect(({languageState}) => ({languageState}), initialize)(addNewTaskForm);
