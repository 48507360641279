import React from 'react';

import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useMount, useToggle} from 'react-use';

import {getAnomalyOccurrences} from 'client/ducks/animations/actions';
import {selectAnomalyOccurrences} from 'client/ducks/animations/selectors';

import CardList from 'client/common/cards/card-list';

import AnomalyOccurrenceCard from 'client/components/instore/cards/anomaly-occurrence-card';

const AnomaliesContainer = (props) => {
  const {animationId} = props;
  const dispatch = useDispatch();
  const [loading, toggleLoading] = useToggle(false);
  const anomalyOccurrences = useSelector(selectAnomalyOccurrences);

  const fetchAnomalyOccurrences = async () => {
    toggleLoading();
    await dispatch(
      getAnomalyOccurrences({
        q: {
          animation_day_animation_id_eq: animationId,
          s: 'created_at asc',
        },
        include: {
          anomaly: true,
        },
      }),
    );
    toggleLoading();
  };

  useMount(() => {
    fetchAnomalyOccurrences();
  });

  return <CardList Component={AnomalyOccurrenceCard} data={anomalyOccurrences} loading={loading} />;
};

AnomaliesContainer.propTypes = {
  animationId: PropTypes.number.isRequired,
};

export default AnomaliesContainer;
