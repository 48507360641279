import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/components/common/icon';

import {
  hasOnline,
  hasVertical,
  hasHorizontal,
  hasRegionAccess,
  hasStoreAccess,
} from 'client/components/diy-operation/modals/diy-op-template-modal/helpers';
import {ClientTemplate} from 'client/models/templates/types';

import cssModule from './left-section.module.scss';

const b = bem('left-section', {cssModule});

type LeftSectionProps = {
  template: ClientTemplate;
  isModule: boolean;
};

const LeftSection: React.FC<LeftSectionProps> = (props) => {
  const {template, isModule} = props;
  const lang = useLanguage('CATALOG_TEMPLATES.MODALS.TEMPLATE_INFO_MODAL.TEMPLATE_PARAMETERS_TAB.LEFT_SECTION');
  const languageNames = useLanguage('LANGUAGES');

  const isOnline = hasOnline(template.image_formats);
  const isVertical = hasVertical(template.image_formats);
  const isHorizontal = hasHorizontal(template.image_formats);
  const isRegion = hasRegionAccess(template.user_access_levels);
  const isStore = hasStoreAccess(template.user_access_levels);

  return (
    <div className={b()}>
      <div>
        <p className={b('title')}>{isModule ? lang.MODULE_LANGUAGES : lang.TEMPLATE_LANGUAGES}</p>
        {template.languages.map((languageKey: string) => (
          <p key={languageKey} className={b('section-item', ['bold'])}>
            <Icon name={`flag-${languageKey}`} />
            <span>{languageNames[languageKey.toUpperCase() as keyof typeof languageNames]}</span>
          </p>
        ))}
      </div>
      <div>
        <p className={b('title', ['with-subtitle'])}>{lang.BROADCAST_CHANNELS}</p>
        {isOnline && (
          <>
            <p className={b('subtitle')}>{lang.ONLINE}</p>
            <p className={b('section-item')}>
              <Icon name="mobile-desktop" />
              <span>{lang.MOBILE_DESKTOP}</span>
            </p>
          </>
        )}
        {(isVertical || isHorizontal) && (
          <>
            <p className={b('subtitle')}>{lang.DEVICE}</p>
            {isVertical && (
              <p className={b('section-item')}>
                <Icon name="kiosk-vertical" />
                <span>{lang.VERTICAL}</span>
              </p>
            )}
            {isHorizontal && (
              <p className={b('section-item')}>
                <Icon name="kiosk-horizontal" />
                <span>{lang.HORIZONTAL}</span>
              </p>
            )}
          </>
        )}
      </div>
      {(isRegion || isStore) && (
        <div>
          <p className={b('title')}>{lang.SHARE_TO}</p>
          {isRegion && (
            <p className={b('section-item')}>
              <Icon name="share-region" />
              <span>{lang.REGIONAL}</span>
            </p>
          )}
          {isStore && (
            <p className={b('section-item')}>
              <Icon name="share-store" />
              <span>{lang.STORE}</span>
            </p>
          )}
        </div>
      )}
      {template.options.length > 0 && (
        <div>
          <p className={b('title')}>{lang.OPTIONS}</p>
          {template.options.map((item) => (
            <p className={b('section-item', ['big'])}>
              <figure className={b('mark')} />
              <span>{item}</span>
            </p>
          ))}
        </div>
      )}
      {template.data.length > 0 && (
        <div>
          <p className={b('title')}>{lang.DATA}</p>
          {template.data.map((item) => (
            <p className={b('section-item', ['big'])}>
              <figure className={b('mark')} />
              <span>{item}</span>
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default LeftSection;
