import {Langs} from 'client/services/hooks/useLanguage/types';

import {Translation} from 'client/models/language/types';
import {PrizeMap} from 'client/models/prizes/types';

export default (values: PrizeMap, {lang}: {lang: Langs<'PRIZES.FIELDSETS.ERRORS'>}) => {
  const errors: Record<string, Translation> = {};

  if (values.initial_stock && values.initial_stock < 1) {
    errors.initial_stock = lang.LESS_THAN_ONE;
  }

  return errors;
};
