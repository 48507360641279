import React from 'react';

import {useDispatch} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {updateAnonymousLead} from 'client/ducks/opt-out/actions';

import AppButton from 'client/common/buttons/app-button';
import {getAppLanguage} from 'client/common/config';
import CheckboxField from 'client/common/fields/checkbox-field';

import {OptInColumn} from 'client/models/opt-in-column/types';
import {ApiDispatch} from 'client/types';

import mapFormValues from './mapFormValues';
import mapInitialValues from './mapInitialValues';

import cssModule from './preferences-opt-in-form.module.scss';

const b = bem('preferences-opt-in-form', {cssModule});

const PreferencesOptInFormName = 'PreferencesOptInForm';

type PreferencesOptInFormProps = {
  leadToken: string;
  optInColumns: OptInColumn[];
  optIns: Record<string, boolean>;
  onUpdate: () => void;
};

export type PreferencesOptInFormValues = Record<string, boolean>;

const PreferencesOptInForm: ReduxFormFC<PreferencesOptInFormProps, PreferencesOptInFormValues> = (props) => {
  const {handleSubmit, submitting, pristine, optInColumns, leadToken, optIns, onUpdate} = props;
  const dispatch: ApiDispatch = useDispatch();
  const lang = useLanguage('UPDATE_PREFERENCES');
  const appLanguage = getAppLanguage() as string;

  useReduxForm(PreferencesOptInFormName, {initialValues: mapInitialValues(optIns)});

  const getTextTranslation = (column: OptInColumn): string => {
    const translations = column.opt_in_text_translations;
    return translations[appLanguage] || Object.values(translations)?.[0] || column.opt_in_text;
  };

  const save = async (formValues: PreferencesOptInFormValues) => {
    const data = mapFormValues(formValues);
    await dispatch(updateAnonymousLead(leadToken, data));
    onUpdate();
  };

  return (
    <div className={b()}>
      <form onSubmit={handleSubmit(save)}>
        {optInColumns
          .filter((i) => i.id in optIns)
          .map((column) => (
            <CheckboxField
              key={column.id}
              name={`_${column.id}`}
              label={getTextTranslation(column)}
              inversionColor
              withWrap
            />
          ))}
        <AppButton className={b('submit-btn')} label={lang.BUTTON_TEXT} disabled={submitting || pristine} submit />
      </form>
    </div>
  );
};

export default reduxForm<PreferencesOptInFormProps, PreferencesOptInFormValues>({
  form: PreferencesOptInFormName,
})(PreferencesOptInForm);
