import {loadingBarMiddleware} from 'react-redux-loading-bar';
import {legacy_createStore as createStore, applyMiddleware, compose} from 'redux';
import {apiMiddleware} from 'redux-api-middleware';
import thunk from 'redux-thunk';

import {refreshToken, handle401} from 'client/ducks/user/middleware';

import {NODE_ENV, IS_BROWSER} from 'client/common/config';

import {mainReducer} from 'client/main-reducer';

function store(state) {
  let composeEnhancers = compose;
  const middleware = [refreshToken, thunk, handle401, apiMiddleware];

  if (IS_BROWSER && NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }
  middleware.push(
    loadingBarMiddleware({
      promiseTypeSuffixes: ['LOADING', 'LOAD_DONE', 'LOAD_FAIL'],
    }),
  );

  return createStore(mainReducer, state, composeEnhancers(applyMiddleware(...middleware)));
}

export default store;
