import React, {Component} from 'react';

import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, Field, SubmissionError} from 'redux-form';

import {getValueIfExists} from 'client/services/helpers';
import {required} from 'client/services/validator';

import {patchEmailTemplateLink} from 'client/ducks/email-templates/actions';

import fieldTemplate from 'client/components/common/field';

import {TranslationJsx} from 'client/models/language/types';

import './tpl-links-list.scss';

class Item extends Component {
  static propTypes = {
    link: PropTypes.string.isRequired,
    kindValue: PropTypes.string,
    errorMessage: TranslationJsx.isRequired,
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onKindChange: PropTypes.func.isRequired,
    onNameBlur: PropTypes.func.isRequired,
    kinds: PropTypes.array.isRequired,
    isAdmin: PropTypes.bool,
  };

  static LINK_TYPES = {
    REGULAR: 'regular',
    MIRROR: 'mirror',
    UNSUBSCRIBE: 'unsubscribe',
  };

  shouldComponentUpdate({kindValue, link}) {
    return kindValue !== this.props.kindValue || link !== this.props.link;
  }

  render() {
    const {link, index, onKindChange, onNameBlur, kinds, errorMessage, kindValue, isAdmin} = this.props;
    return (
      <div className="field-group">
        <div className="field-group__title theme-color-16">{link}</div>
        {isAdmin && (
          <div className="field-group__row">
            <div className="field-group__field tpl-links-list__field-1">
              <Field
                cssModifier="select--view-3"
                name={`item${index}.kind`}
                type="select"
                onChange={onKindChange}
                searchable={false}
                component={fieldTemplate}
                options={kinds}
                simpleValue
              />
            </div>
            <div className="field-group__field tpl-links-list__field-2">
              <Field
                cssModifier="input--view-3 input--disabled-view-1"
                disabled={kindValue !== Item.LINK_TYPES.REGULAR}
                name={`item${index}.label`}
                onBlur={onNameBlur}
                type="text"
                component={fieldTemplate}
                validate={required(errorMessage)}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

class TemplateLinksList extends Component {
  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.array,
    formValues: PropTypes.object,
    patchEmailTemplateLink: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool,
    isTemplateUpdating: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    className: '',
    items: [],
    formValues: {},
    isAdmin: false,
  };

  static formName = 'TplLinksListForm';

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.TEMPLATE;
  }

  componentDidMount() {
    this.props.initialize(this.mapData(this.props.items));
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.items, prevProps.items) || this.props.isTemplateUpdating !== prevProps.isTemplateUpdating) {
      this.props.initialize(this.mapData(this.props.items));
    }
  }

  mapData = (items) =>
    items.reduce((result, item, index) => {
      result[`item${index}`] = {
        name: item.name ? item.name : item.value,
        label: item.kind === Item.LINK_TYPES.REGULAR ? item.name || item.value : this.LANGUAGE.LINK_TYPES[item.kind],
        id: item.id,
        type: item.type,
        kind: item.kind,
        position: item.position,
        value: item.value,
      };
      return result;
    }, {});

  handleKindChanged = (index) => (e) => {
    // e.preventDefault();
    // const kind = e.value;
    const kind = e;
    if (!kind) {
      return;
    }
    if (kind === Item.LINK_TYPES.REGULAR) {
      this.props.change(`item${index}.label`, this.props.items[index].name);
    } else {
      this.props.change(`item${index}.label`, this.LANGUAGE.LINK_TYPES[kind]);
    }
    this.props.change(`item${index}.kind`, kind);
    if (!this.props.isTemplateUpdating) {
      this.props.patchEmailTemplateLink(this.props.items[index].id, {
        email_template_link: {
          kind,
        },
      });
    }
  };

  handleNameBlurred = (index) => (e) => {
    const {value} = e.target;
    if (!value || !value.trim()) {
      return false;
    }
    this.props.change(`item${index}.name`, value);
    if (!this.props.isTemplateUpdating) {
      return this.props
        .patchEmailTemplateLink(this.props.items[index].id, {
          email_template_link: {
            name: value,
          },
        })
        .then(({payload: {response: {errors} = {}}}) => {
          if (errors) {
            throw new SubmissionError({
              [`item${index}`]: {
                label: this.LANGUAGE.ERRORS.NAME_IS_TAKEN,
              },
            });
          }
        });
    }
    return false;
  };

  render() {
    const {className, items, formValues, handleSubmit, isAdmin} = this.props;
    return (
      <div className={`tpl-links-list ${className}`}>
        <form onSubmit={(e) => e.preventDefault()}>
          {items.map((item, index) => {
            return (
              <div
                className="tpl-links-list__item"
                style={{
                  zIndex: 1000 - index,
                }}
                key={index}
              >
                <Item
                  index={index}
                  link={item.value}
                  kinds={Object.keys(this.LANGUAGE.LINK_TYPES).map((value) => ({
                    value,
                    label: this.LANGUAGE.LINK_TYPES[value],
                  }))}
                  onKindChange={this.handleKindChanged(index)}
                  onNameBlur={(e) => handleSubmit(() => this.handleNameBlurred(index)(e))()}
                  errorMessage={this.LANGUAGE.ERRORS.NAME_IS_REQUIRED}
                  kindValue={getValueIfExists(formValues, ['values', `item${index}`, 'kind'])}
                  isAdmin={isAdmin}
                />
              </div>
            );
          })}
        </form>
      </div>
    );
  }
}

const formComponent = reduxForm({
  form: TemplateLinksList.formName,
})(TemplateLinksList);

export default connect(
  ({languageState, form}) => ({
    languageState,
    formValues: form[TemplateLinksList.formName],
  }),
  {
    patchEmailTemplateLink,
  },
)(formComponent);
