import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';

import {transformDate, urlWithoutLastSlash} from 'client/services/helpers';

import Icon from 'client/components/common/icon';
import InfoBlock from 'client/components/common/info-block';

import './tpl-item.scss';

class TemplateItem extends Component {
  static propTypes = {
    className: PropTypes.string,
    template: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
    onEditClick: PropTypes.func.isRequired,
    editable: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    editable: true,
  };

  render() {
    const {className, labels, onEditClick, location, template, editable} = this.props;

    return (
      <InfoBlock className={`tpl-item ${className}`}>
        <InfoBlock.Content>
          <InfoBlock.Header className="tpl-item__header">
            <div className="tpl-item__header__title">
              <Link
                to={`${urlWithoutLastSlash(location.pathname)}/${template.id}`}
                className="link tpl-item__name ellipsis-text"
              >
                {template.name}
              </Link>
              <div className="tpl-item__id ellipsis-text">{template.mailjet_id}</div>
            </div>
            {editable && (
              <button className="button button--circle button--bg-14" onClick={() => onEditClick(template)}>
                <Icon name="edit" className="button__icon" width={20} height={20} />
              </button>
            )}
          </InfoBlock.Header>
          <InfoBlock.Body className="tpl-item__body">
            <div
              className="tpl-item__img"
              dangerouslySetInnerHTML={{
                __html: template.html_part || '',
              }}
            />
            <div className="tpl-item__content">
              <div className="tpl-item__block">
                <div className="tpl-item__info-text">
                  {labels.merged}: <b>{template.email_template_variables?.length}</b>
                </div>
                <div className="tpl-item__info-text">
                  {labels.links}: <b>{template.email_template_links && template.email_template_links.length}</b>
                </div>
              </div>
              <div className="tpl-item__date">
                {labels.updated} {transformDate(template.updated_at)}
              </div>
              <div className="tpl-item__date">
                {labels.created} {transformDate(template.created_at)}
              </div>
            </div>
          </InfoBlock.Body>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

export default withRouter(TemplateItem);
