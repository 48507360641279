import React, {useEffect, useCallback} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import LoadingSpinner from 'client/components/common/loading-spinner';

import './infinite-scroll.scss';

const b = bem('infinite-scroll');

const InfiniteScroll = (props) => {
  const {loading, children, setPage, totalPages, page} = props;

  const onScroll = useCallback(() => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    const isScrolled = scrollTop + clientHeight >= scrollHeight;
    const hasNextPage = page + 1 <= totalPages;

    if (isScrolled && hasNextPage && !loading) {
      setPage(page + 1);
    }
  }, [loading, page, setPage, totalPages]);

  useEffect(() => {
    onScroll(); // Check on mount in case content is already at bottom ( important )
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return (
    <div className={b()}>
      {children}
      {loading && (
        <div className={b('spinner-wrap')}>
          <LoadingSpinner loading={true} className={b('spinner')} />
        </div>
      )}
    </div>
  );
};

InfiniteScroll.propTypes = {
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  children: PropTypes.node,
  loading: PropTypes.bool,
};

InfiniteScroll.defaultProps = {
  loading: false,
  children: null,
};

export default InfiniteScroll;
