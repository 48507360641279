import React, {useState} from 'react';

import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {blobToBase64} from 'client/services/blobToBase64';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';
import Modal from 'client/common/modals/modal';
import TabSwitcher from 'client/common/tab-switcher';

import {ImageBlockData} from 'client/components/email-template-editor/types';

import ExternalLinkTab from './external-link-tab';
import UploadTab from './upload-tab';
import VariableTab from './variable-tab';

import cssModule from './insert-image-modal.module.scss';

const b = bem('insert-image-modal', {cssModule});

type InsertImageModalProps = {
  onClose: () => void;
  onSubmit: (data: ImageBlockData) => void;
};

const tabs = {
  UPLOAD_NEW: 'upload_new',
  EXTERNAL_LINK: 'external_link',
  DAMS: 'dams',
  VARIABLE: 'variable',
};

const tabComponentMap = {
  [tabs.UPLOAD_NEW]: UploadTab,
  [tabs.EXTERNAL_LINK]: ExternalLinkTab,
  [tabs.VARIABLE]: VariableTab,
};

const InsertImageModal: React.FC<InsertImageModalProps> = (props) => {
  const {onClose, onSubmit} = props;
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.MODALS.INSERT_IMAGE_MODAL');
  const [data, setData] = useState<{
    disabled?: boolean;
    src?: string;
    variableName?: string;
    file?: File;
  }>({disabled: true});
  const [isUploading, toggleIsUploading] = useToggle(false);

  const tabsConfig = [
    {title: lang.UPLOAD_NEW, value: tabs.UPLOAD_NEW},
    {title: lang.EXTERNAL_LINK, value: tabs.EXTERNAL_LINK},
    {title: lang.DAMS, value: tabs.DAMS, disabled: true},
    {title: lang.VARIABLE, value: tabs.VARIABLE},
  ];

  const [activeTab, setActiveTab] = useState(tabs.UPLOAD_NEW);

  const TabComponent = tabComponentMap[activeTab];

  const handleTabClick = (value: string) => {
    setActiveTab(value);
    setData({disabled: true});
  };

  const handleSubmit = async () => {
    if (activeTab === tabs.UPLOAD_NEW && data.file) {
      toggleIsUploading();
      const src = await blobToBase64(data.file);

      toggleIsUploading();

      onSubmit({src, variableName: ''});
    } else if (activeTab === tabs.EXTERNAL_LINK && data.src) {
      const blob = await (await fetch(data.src))?.blob();
      const src = await blobToBase64(blob);

      onSubmit({src, variableName: ''});
    } else if (activeTab === tabs.VARIABLE && data.src) {
      onSubmit({src: data.src, variableName: data.variableName});
    }
    onClose();
  };

  return (
    <Modal dialogClassName={b()} title={lang.TITLE} onClose={onClose} show>
      <TabSwitcher className={b('switcher')} tabs={tabsConfig} activeTab={activeTab} onTabClick={handleTabClick} />
      <div className={b('content')}>
        <TabComponent onChange={setData} />
      </div>
      <div className={b('footer')}>
        <AppButton label={lang.CANCEL} onClick={onClose} transparent />
        <AppButton
          label={lang.INSERT}
          disabled={data.disabled}
          loading={isUploading}
          onClick={handleSubmit}
          size="medium"
        />
      </div>
    </Modal>
  );
};

export default InsertImageModal;
