import React from 'react';

import {Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';

import LeadsApiExportSettingsPopover from 'client/components/leads/components/popovers/leads-api-export-settings-popover';
import LeadsCommentsPopover from 'client/components/leads/components/popovers/leads-comments-popover';

import './api-export-tools-cell.scss';

function ApiExportToolsCell(props) {
  const {data, rowIndex, clientId, columnAdapters, clientUsers, fetchApiLeadExports} = props;
  const comment = data[rowIndex].comment;
  return (
    <Cell>
      <div className="api-export-tools-cell">
        <LeadsApiExportSettingsPopover
          leadsApiExport={data[rowIndex]}
          clientId={clientId}
          columnAdapters={columnAdapters}
          clientUsers={clientUsers}
          refresh={fetchApiLeadExports}
        >
          <Icon name="settings" />
        </LeadsApiExportSettingsPopover>
        {comment && (
          <LeadsCommentsPopover comment={comment}>
            <Icon name="message" />
          </LeadsCommentsPopover>
        )}
      </div>
    </Cell>
  );
}

ApiExportToolsCell.defaultProps = {
  data: [],
};

ApiExportToolsCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
  clientId: PropTypes.number.isRequired,
  fetchApiLeadExports: PropTypes.func.isRequired,
  columnAdapters: PropTypes.array.isRequired,
  clientUsers: PropTypes.array.isRequired,
};

export default ApiExportToolsCell;
