import React, {useEffect, useRef, useState} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';

import bem from 'client/services/bem';

import {TextareaInput, TextInput} from 'client/common/inputs';

import cssModule from './custom-edit-cell.module.scss';

const b = bem('custom-edit-cell', {cssModule});

export const CustomEditCell = (props) => {
  const {onUpdate, defaultValue, type, name} = props;
  const ref = useRef(null);
  const [editable, setEditable] = useState(false);

  const [localValue, setLocalValue] = useState(Array.isArray(defaultValue) ? defaultValue.join('\n') : defaultValue);

  useEffect(() => {
    setLocalValue(defaultValue);
  }, [defaultValue]);

  const handleBlur = (e) => {
    const nextValue = Array.isArray(defaultValue) ? e.target.value.split('\n') : e.target.value;
    onUpdate(nextValue);
    setEditable(false);
  };

  const commonProps = {
    name,
    value: localValue,
    onBlur: handleBlur,
    onChange: (e) => setLocalValue(e.target.value),
    autofocus: true,
    inputRef: ref,
    style: {display: editable ? 'block' : 'none'},
  };

  useEffect(() => {
    if (ref.current && editable) {
      ref.current.focus();
    }
  }, [editable]);

  const handleEditable = () => {
    setEditable(true);
    ref.current?.focus();
  };

  if (!editable) {
    return (
      <button type="button" onDoubleClick={handleEditable} className={b()}>
        <span>{localValue}</span>
      </button>
    );
  }

  return type.endsWith('s') ? <TextareaInput {...commonProps} /> : <TextInput {...commonProps} />;
};

CustomEditCell.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  languageState: PropTypes.object.isRequired,
  type: PropTypes.string,
};

CustomEditCell.defaultProps = {
  type: 'string',
};

const CustomEditForm = connect(({languageState}) => ({languageState}))(
  reduxForm({
    form: 'CustomEditForm',
    validate: CustomEditCell.validate,
  })(CustomEditCell),
);

export default (onUpdate, props) => {
  return <CustomEditForm onUpdate={onUpdate} {...props} />;
};
