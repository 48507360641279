import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, change} from 'redux-form';

import bem from 'client/services/bem';

// components
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

import cssModule from './custom-operation-filters-modal.module.scss';

const b = bem('custom-operation-filters-modal', {cssModule});

class CustomOperationFiltersModal extends Component {
  constructor(props) {
    super(props);
    this.LANGUAGE = this.props.languageState.payload.CUSTOM_OPERATION_DATABASE;
    this.formats = Object.keys(props.languageState.payload.CUSTOM_FORMATS).map((i) => ({
      name: i,
      label: props.languageState.payload.CUSTOM_FORMATS[i],
    }));
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.props.reset();
      this.props.filter.forEach((i) => this.props.changeFieldValue('ClientsFiltersModalForm', i, 'true'));
    }
  }

  save = (data) => {
    this.props.onFilterChange(data);
  };

  render() {
    const {handleSubmit, onClose, totalItems} = this.props;

    return (
      <Modal
        show={this.props.show}
        onClose={onClose}
        title={this.LANGUAGE.FILTER_MODAL_TITLE}
        dialogClassName="modal-window--width-1 modal-window--theme-4"
      >
        <form onSubmit={onClose} onChange={() => setTimeout(handleSubmit(this.save), 200)}>
          <div className="form-field-wrap__group-title">{this.LANGUAGE.FILTER_MODAL_FORMAT_LABEL}</div>
          <div className={b('fields')}>
            {this.formats.map((i) => (
              <Field
                {...i}
                key={i.name}
                type="checkbox"
                checkboxType="switcher-2"
                cssModifier="theme-7"
                component={fieldTemplate}
              />
            ))}
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-4 modal-window__footer-btn" type="button" onClick={onClose}>
              {`${this.LANGUAGE.FILTER_MODAL_SHOW_RESULTS_BUTTON} (${totalItems})`}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

CustomOperationFiltersModal.propTypes = {
  show: PropTypes.bool,
  totalItems: PropTypes.number,
  filter: PropTypes.array,
  onClose: PropTypes.func,
  reset: PropTypes.func,
  changeFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  onFilterChange: PropTypes.func,
  languageState: PropTypes.object.isRequired,
};

CustomOperationFiltersModal.defaultProps = {
  show: false,
  filter: [],
  onFilterChange: () => {},
  onClose: () => {},
};

const CustomOperationFiltersModalForm = reduxForm({
  form: 'ClientsFiltersModalForm',
})(CustomOperationFiltersModal);

export default connect(({languageState}) => ({languageState}), {
  changeFieldValue: change,
})(CustomOperationFiltersModalForm);
