export const diagramKinds = {
  PIE_CHART: 'pie_chart',
  LINE: 'line',
  HORIZONTAL_BARS: 'horizontal_bars',
  VERTICAL_BARS: 'vertical_bars',
};

export const diagramLineKinds = {
  LINE_WITH_VALUES: 'line_with_values',
  LINE_WITH_INTERVALS: 'line_with_intervals',
};

export const diagramDotPrecisions = {
  AUTOMATIC: 'automatic',
  FIXED: 'fixed',
};

export const diagramMethods = {
  VALUE: 'value',
  INTERVAL: 'interval',
};

export const diagramColors = ['#ffa879', '#00629c', '#e12b2b', '#b958c9'];

export const pieCartColors = [
  '#00629c',
  '#e12b2b',
  '#b958c9',
  '#76B136',
  '#F67D3C',
  '#F6D976',
  '#B92A6C',
  '#71C9FB',
  '#98EA91',
  '#FFAAAA',
  '#F4A0FB',
  '#48B7B7',
  '#89B6F9',
  '#FFC267',
  '#42D085',
  '#8CEEEE',
  '#787DF8',
  '#F44E9A',
  '#D0F1AC',
  '#F6D7FF',
];

export const statusesChartColors = {
  DRAFT: '#DCE1EA',
  SENT: '#8ED2F8',
  VALIDATION: '#308ED1',
  RECEIVED: '#00629C',
  PRODUCTION: '#6BFFB8',
  RUNNING: '#0DD280',
  FINISHED: '#059A64',
  REPORTED: '#F4A0FB',
};
