import {blobToBase64} from 'client/services/blobToBase64';

export default async (formValues, {interactionId, id}) => {
  if (interactionId) {
    const devicePrizes = await Promise.all(
      formValues.device_prizes
        ?.filter((p) => p.imports?.data)
        .map(async (devicePrize) => ({
          id: devicePrize.id,
          prize_id: devicePrize.prize?.id,
          device_token_list: {
            filename: devicePrize.imports.data.name,
            data: await blobToBase64(devicePrize.imports.data),
          },
        })),
    );

    return {
      device: {
        interactions: [
          {
            id: interactionId,
            from: formValues?.from,
            to: formValues?.to,
            default_language: formValues?.default_language,
          },
        ],
        device_prizes: devicePrizes,
      },
    };
  }
  return {
    ids: [id],
    from: formValues?.from,
    to: formValues?.to,
  };
};
