import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getUserId} from 'client/services/cookie-data-source';

import {getClients} from 'client/ducks/clients-list/actions';
import {selectClientsCount, selectMyClientsCount, selectClientsMapped} from 'client/ducks/clients-list/selectors';
import {getDevicesAction} from 'client/ducks/devices/actions';
import {selectDevicesTotalCount} from 'client/ducks/devices/selectors';
import {getInterfaceTemplatesAction} from 'client/ducks/interface-templates/actions';
import {selectInterfaceTemplatesTotalCount} from 'client/ducks/interface-templates/selectors';
import {getLeads} from 'client/ducks/leads-list/actions';
import {selectLeadsTotalCount} from 'client/ducks/leads-list/selectors';
import {getLeadsTransferReports} from 'client/ducks/leads-transfers/actions';
import {selectLeadsTransferReportsMapped} from 'client/ducks/leads-transfers/selectors';
import {getOperations} from 'client/ducks/operations/actions';
import {selectOperationsCount, selectMyOperationsCount} from 'client/ducks/operations/selectors';
import {getPlacesAction} from 'client/ducks/places/actions';
import {selectPlacesTotalCount} from 'client/ducks/places/selectors';
import {getScenarioExecutionLogs} from 'client/ducks/scenario/actions';
import {selectScenarioLogsMapped} from 'client/ducks/scenario/selectors';
import {fetchSources} from 'client/ducks/sources/actions';
import {selectSourcesTotalCount} from 'client/ducks/sources/selectors';
import {getTaskIndex} from 'client/ducks/task-index-items/actions';
import {selectTaskIndexItemsMapped} from 'client/ducks/task-index-items/selectors';

import {CLIENT_PAGES} from 'client/common/config';

import Icon from 'client/components/common/icon';
import InfoPanel from 'client/components/common/info-panel';

import HomeCard from './components/cards/home-card';
import HomeClientsTable from './components/tables/home-clients-table';
import HomeErrorsTable from './components/tables/home-errors-table';
import HomeLeadsTable from './components/tables/home-leads-table';
import HomeOpsTable from './components/tables/home-ops-table';

import SelectEmailTemplateModalContainer from '../email-template-editor/modals/select-email-template-modal/select-email-template-modal-container';

import './home.scss';

class Home extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,

    getOperations: PropTypes.func.isRequired,
    getClients: PropTypes.func.isRequired,
    getLeadsTransfers: PropTypes.func.isRequired,
    getLeads: PropTypes.func.isRequired,
    getPlaces: PropTypes.func.isRequired,
    getSources: PropTypes.func.isRequired,
    getInterfaces: PropTypes.func.isRequired,
    getDevices: PropTypes.func.isRequired,
    getScenarioErrors: PropTypes.func.isRequired,
    getTaskIndex: PropTypes.func.isRequired,

    leadsCount: PropTypes.number,
    operationsCount: PropTypes.number,
    myOperationsCount: PropTypes.number,
    myClientsCount: PropTypes.number,
    clientsCount: PropTypes.number,
    placesCount: PropTypes.number,
    sourcesCount: PropTypes.number,
    interfacesCount: PropTypes.number,
    devicesCount: PropTypes.number,

    lastAddedClients: PropTypes.array,
    lastLeadsTransfers: PropTypes.array,
    scenarioErrors: PropTypes.array,
    taskIndex: PropTypes.array,
  };

  static defaultProps = {
    operationsCount: 0,
    myOperationsCount: 0,
    myClientsCount: 0,
    clientsCount: 0,
    leadsCount: 0,
    placesCount: 0,
    sourcesCount: 0,
    interfacesCount: 0,

    lastAddedClients: [],
    lastLeadsTransfers: [],
    scenarioErrors: [],
    taskIndex: [],
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.HOME;
  }

  componentDidMount() {
    Promise.all([
      this.getOperations(),
      this.getClients(),
      this.getLeads(),
      this.getLeadsTransfers(),
      this.getPlaces(),
      this.getInterfaces(),
      this.getDevices(),
      this.getScenarioErrors(),
      this.getSources(),
      this.getTaskIndex(),
    ]);
  }

  getOperations = () => {
    const operationParams = {
      include: 'client',
      page: 1,
      per_page: 1,
    };

    const myOperationParams = {
      q: {
        client_poc_admin_user_id_eq: getUserId(),
      },
      page: 1,
      per_page: 1,
    };

    return Promise.all([this.props.getOperations(operationParams), this.props.getOperations(myOperationParams, true)]);
  };

  getClients = () => {
    const clientsParams = {
      q: {
        s: 'created_at desc',
      },
      page: 1,
      per_page: 10,
    };

    const myClienstParams = {
      q: {
        poc_admin_user_id_eq: getUserId(),
      },
      page: 1,
      per_page: 1,
    };

    return Promise.all([this.props.getClients(clientsParams), this.props.getClients(myClienstParams, true)]);
  };

  getLeads = () => {
    return this.props.getLeads({
      per_page: 1,
      page: 1,
    });
  };

  getLeadsTransfers = () => {
    const params = {
      per_page: 10,
      page: 1,
      q: {
        s: 'created_at desc',
      },
      include: ['lead_transfer'],
    };

    return this.props.getLeadsTransfers(params);
  };

  getPlaces = () => {
    const params = {
      per_page: 1,
      page: 1,
    };
    return this.props.getPlaces(params);
  };

  getInterfaces = () => {
    const params = {
      page: 1,
      perPage: 1,
    };

    return this.props.getInterfaces(params);
  };

  getDevices = () => {
    const params = {
      page: 1,
      perPage: 1,
    };

    return this.props.getDevices(params);
  };

  getScenarioErrors = () => {
    const params = {
      per_page: 10,
      page: 1,
      q: {
        s: 'created_at desc',
      },
      include: [
        {
          scenario_execution: [
            {
              scenario: [
                {
                  automation_task: [
                    {
                      operation: 'client',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };

    return this.props.getScenarioErrors(params);
  };

  getSources = () => {
    const params = {
      per_page: 5,
      page: 1,
    };

    return this.props.getSources(params);
  };

  getTaskIndex = () => {
    const params = {
      per_page: 10,
      page: 1,
      q: {
        s: 'created_at desc',
      },
      include: [
        {
          task: [
            {
              operation: ['client'],
            },
          ],
        },
      ],
    };
    return this.props.getTaskIndex(params);
  };

  render() {
    const panels = this.LANGUAGE.PANELS;
    const cards = this.LANGUAGE.CARDS;

    const {
      operationsCount,
      myOperationsCount,
      myClientsCount,
      leadsCount,
      clientsCount,
      placesCount,
      sourcesCount,
      interfacesCount,
      devicesCount,
      lastAddedClients,
      lastLeadsTransfers,
      scenarioErrors,
      taskIndex,
    } = this.props;

    return (
      <div className="home">
        <div className="home__row grid__row">
          <div className="page__block-container">
            <div className="page__block page__block--size-4 theme-color-9">
              <InfoPanel
                className="home__info-panel"
                icon={<Icon name="nav-operations" />}
                title={panels.TOTAL_OPS_TITLE}
                number={operationsCount}
                href={CLIENT_PAGES.OPERATIONS}
              />
              <InfoPanel
                className="home__info-panel"
                title={panels.MY_OPS_TITLE}
                number={myOperationsCount}
                href={CLIENT_PAGES.OPERATIONS_MY}
              />
            </div>
            <div className="page__block page__block--size-4 theme-color-1">
              <InfoPanel
                className="home__info-panel"
                icon={<Icon name="nav-clients" />}
                title={panels.TOTAL_CLIENTS_TITLE}
                number={clientsCount}
                href={CLIENT_PAGES.CLIENTS_LIST}
              />
              <InfoPanel
                className="home__info-panel"
                title={panels.MY_CLIENTS_TITLE}
                number={myClientsCount}
                href={CLIENT_PAGES.CLIENTS_LIST_MY}
              />
            </div>
            <div className="page__block page__block--size-4 theme-color-2">
              <InfoPanel
                icon={<Icon name="nav-database" />}
                title={panels.TOTAL_LEADS_TITLE}
                number={leadsCount}
                href={CLIENT_PAGES.LEADS_LIST}
                className="info-panel--height-1"
              />
            </div>
          </div>
          <div className="page__block-container">
            <div className="page__block page__block--size-4 theme-color-9">
              <HomeCard className="home__card" title={cards.TASKS_TITLE}>
                <HomeOpsTable data={taskIndex} />
              </HomeCard>
            </div>
            <div className="page__block page__block--size-4 theme-color-1">
              <HomeCard className="home__card" title={cards.CLIENTS_TITLE}>
                <HomeClientsTable data={lastAddedClients} />
              </HomeCard>
            </div>
            <div className="page__block page__block--size-4 theme-color-2">
              <HomeCard className="home__card" title={cards.LEADS_TITLE}>
                <HomeLeadsTable data={lastLeadsTransfers} />
              </HomeCard>
            </div>
          </div>
        </div>
        <div className="home__row">
          <div className="page__block-container">
            <div className="page__block page__block--size-4">
              <InfoPanel
                className="home__info-panel theme-color-3"
                title={panels.PLACES_TITLE}
                number={placesCount}
                href={CLIENT_PAGES.PLACES}
              />
              <InfoPanel
                className="home__info-panel theme-color-10"
                title={panels.SOURCES_TITLE}
                number={sourcesCount}
                href={CLIENT_PAGES.SOURCES}
              />

              <InfoPanel className="home__info-panel home__info-panel--email-templates">
                <SelectEmailTemplateModalContainer
                  title={panels.EMAIL_TEMPLATES_TITLE}
                  buttonLabel={panels.EMAIL_TEMPLATES_BUTTON_LABEL}
                />
              </InfoPanel>
            </div>
            <div className="page__block page__block--size-4 theme-color-8">
              <InfoPanel
                className="home__info-panel"
                title={panels.INTERFACES_TITLE}
                number={interfacesCount}
                href={CLIENT_PAGES.INTERFACE_TEMPLATES}
              />
              <InfoPanel
                className="home__info-panel"
                title={panels.DEVICES_TITLE}
                number={devicesCount}
                href={CLIENT_PAGES.DEVICES}
              />
            </div>
            <div className="page__block page__block--size-4 theme-color-11">
              <HomeCard className="home__card-errors" title={cards.ERRORS_TITLE}>
                <HomeErrorsTable data={scenarioErrors} />
              </HomeCard>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageState: state.languageState,
    operationsCount: selectOperationsCount(state),
    myClientsCount: selectMyClientsCount(state),
    myOperationsCount: selectMyOperationsCount(state),
    clientsCount: selectClientsCount(state),
    lastAddedClients: selectClientsMapped(state),
    lastLeadsTransfers: selectLeadsTransferReportsMapped(state),
    leadsCount: selectLeadsTotalCount(state),
    placesCount: selectPlacesTotalCount(state),
    sourcesCount: selectSourcesTotalCount(state),
    interfacesCount: selectInterfaceTemplatesTotalCount(state),
    devicesCount: selectDevicesTotalCount(state),
    scenarioErrors: selectScenarioLogsMapped(state),
    taskIndex: selectTaskIndexItemsMapped(state),
  };
};

const mapDispatchToProps = {
  getOperations,
  getClients,
  getLeadsTransfers: getLeadsTransferReports,
  getLeads,
  getPlaces: getPlacesAction,
  getSources: fetchSources,
  getInterfaces: getInterfaceTemplatesAction,
  getDevices: getDevicesAction,
  getScenarioErrors: getScenarioExecutionLogs,
  getTaskIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
