import React, {memo} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Icon from 'client/components/common/icon';

import './games-add-buttons.scss';

const b = bem('games-add-buttons');

const GamesAddButtons = ({onClick, winNumber}) => {
  return (
    <div className={b()}>
      <div className={b('win-number')}>{winNumber}</div>
      <button className={b('add-games')} type="button" onClick={onClick}>
        <Icon name="plus" className={b('button-icon')} />
      </button>
    </div>
  );
};

GamesAddButtons.propTypes = {
  winNumber: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(GamesAddButtons);
