import types from './types';

// import omit from 'lodash/omit';

const initialState = {
  interfacesById: {},
  interfacesIds: [],
  interfaces: [],
  interfacesMeta: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_INTERFACES_STATE:
      return initialState;

    case types.GET_INTERFACES_SUCCESS:
      return {
        interfaces: action.payload.interfaces,
        interfacesById: action.payload.interfaces.reduce((result, item) => {
          return {
            ...result,
            [item.id]: item,
          };
        }, {}),
        interfacesIds: action.payload.interfaces.map((item) => item.id),
        interfacesMeta: {
          ...state.interfacesMeta,
          ...action.payload.meta,
        },
      };

    case types.PATCH_INTERFACE_SUCCESS:
      const interfaceItem = action.payload.interface;

      return {
        ...state,
        interfacesById: {
          ...state.interfacesById,
          [interfaceItem.id]: interfaceItem,
        },
      };

    default:
      return state;
  }
}
