import {dateToString, prettyJoin} from 'client/services/helpers';
import {LangsByPath} from 'client/services/hooks/useLanguage/types';

import {WINNING_SEARCH_TYPES} from 'client/components/winnings/forms/winning-search-form/constants';
import {WinningSearchType} from 'client/components/winnings/forms/winning-search-form/types';
import {getFullName, getPrizeName} from 'client/components/winnings/helpers';
import {WinningSearchResult, WinningSearchResultPrize} from 'client/models/winnings/types';

export const getInteractionLine = (prize: WinningSearchResultPrize) => {
  const store = prize.interaction?.store;
  const address = prettyJoin([store?.city_zip, store?.city_name]);
  return prettyJoin([prize.interaction?.name, store?.name, address], ' | ');
};

export const getCopyText = (
  searchType: WinningSearchType,
  item: WinningSearchResult,
  lang: LangsByPath<['COMMON']>,
) => {
  const result = [];

  const email = item.lead.email;
  const phone = searchType === WINNING_SEARCH_TYPES.PHONE && item.lead.phone;
  const token = searchType === WINNING_SEARCH_TYPES.TOKEN && item.prizes?.[0]?.coupon?.token;
  const loyaltyId = searchType === WINNING_SEARCH_TYPES.LOYALTY_ID && item.prizes?.[0]?.loyalty_identifier;

  result.push(prettyJoin([getFullName(item.lead, lang), email, phone, token, loyaltyId], ' | '));
  result.push('');

  item.prizes.forEach((prize) => {
    const winDate = dateToString(prize.win_time);
    const winTime = dateToString(prize.win_time, 'HH:mm');
    result.push(getPrizeName(prize));
    result.push(getInteractionLine(prize));
    result.push(prettyJoin([winDate, winTime]));
    result.push('');
  });

  return result.join('\n');
};
