import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getSubcategories(queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      queryParams,
      endpoint: `${API_PATH}${API_METHODS.SUBCATEGORIES}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_SUBCATEGORIES_REQUEST, types.GET_SUBCATEGORIES_SUCCESS, types.GET_SUBCATEGORIES_ERROR],
    });
  };
}
