import {equals, matches} from 'validator';

import {passwordRegExp} from 'client/services/helpers';

export const warn = ({password, passwordConfirmation}, {lang}) => {
  let warnings = {};

  if (password && passwordConfirmation && equals(passwordConfirmation, password)) {
    warnings.password = lang.PASSWORD_MATCHED;
    warnings.passwordConfirmation = lang.PASSWORD_MATCHED;
  }

  return warnings;
};

export default ({password, passwordConfirmation}, {lang}) => {
  let errors = {};

  if (!password) {
    errors.password = lang.PASSWORD_REQUIRED;
  } else if (matches(password, passwordRegExp())) {
    errors.password = lang.PASSWORD_LENGTH;
  }

  if (!passwordConfirmation) {
    errors.passwordConfirmation = lang.CONFIRMATION_REQUIRED;
  } else if (matches(passwordConfirmation, passwordRegExp())) {
    errors.passwordConfirmation = lang.CONFIRMATION_LENGTH;
  } else if (!equals(passwordConfirmation, password)) {
    errors.passwordConfirmation = lang.PASSWORD_DOENSNT_MATCH;
  }

  return errors;
};
