import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function changeCurrentBroadcast(id) {
  return {
    type: types.CHANGE_CURRENT_BROADCAST,
    id,
  };
}

export function getBroadcasts(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.BROADCAST_LISTS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_BROADCASTS_REQUEST, types.GET_BROADCASTS_SUCCESS, types.GET_BROADCASTS_ERROR],
      queryParams,
    });
}

export function getBroadcast(id, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.BROADCAST_LISTS}/${id}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_BROADCAST_REQUEST, types.GET_BROADCAST_SUCCESS, types.GET_BROADCAST_ERROR],
      queryParams,
    });
}

export function getBroadcastRecipients(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.BROADCAST_RECIPIENTS}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_BROADCAST_RECIPIENTS_REQUEST,
        types.GET_BROADCAST_RECIPIENTS_SUCCESS,
        types.GET_BROADCAST_RECIPIENTS_ERROR,
      ],
      queryParams,
    });
}

export function createBroadcast(body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.BROADCAST_LISTS}`,
      method: 'POST',
      types: [types.CREATE_BROADCAST_REQUEST, types.CREATE_BROADCAST_SUCCESS, types.CREATE_BROADCAST_ERROR],
      body,
    });
}

export function clearBroadcastRecipients() {
  return {
    type: types.CLEAR_BROADCAST_RECIPIENTS_SUCCESS,
  };
}

export function updateBroadcastList(id, body = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.BROADCAST_LISTS}/${id}`,
        method: 'PUT',
        types: [
          types.UPDATE_BROADCAST_LIST_REQUEST,
          types.UPDATE_BROADCAST_LIST_SUCCESS,
          types.UPDATE_BROADCAST_LIST_ERROR,
        ],
        body,
      },
      true,
    );
  };
}

export function getBroadcastDisplayItems(queryParams) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.BROADCAST_LIST_DISPLAY_ITEMS}`,
      method: 'GET',
      types: [
        types.GET_BROADCAST_DISPLAY_ITEMS_REQUEST,
        types.GET_BROADCAST_DISPLAY_ITEMS_SUCCESS,
        types.GET_BROADCAST_DISPLAY_ITEMS_ERROR,
      ],
      noCache: true,
      queryParams,
    });
}

export function createBroadcastDisplayItem(body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.BROADCAST_LIST_DISPLAY_ITEMS}`,
      method: 'GET',
      types: [
        types.CREATE_BROADCAST_DISPLAY_ITEM_REQUEST,
        types.CREATE_BROADCAST_DISPLAY_ITEM_SUCCESS,
        types.CREATE_BROADCAST_DISPLAY_ITEM_ERROR,
      ],
      noCache: true,
      body,
    });
}

export function deleteBroadcastDisplayItems(ids, params = {}) {
  const queryParams = {
    q: {
      id_in: ids,
    },
    ...params,
  };

  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.BROADCAST_LIST_DISPLAY_ITEMS}`,
        method: 'DELETE',
        types: [
          types.DELETE_BROADCAST_DISPLAY_ITEM_REQUEST,
          {
            type: types.DELETE_BROADCAST_DISPLAY_ITEM_SUCCESS,
            payload: () => ({ids}),
          },
          types.DELETE_BROADCAST_DISPLAY_ITEM_ERROR,
        ],
        queryParams,
      },
      true,
    );
}
export function deleteBCDisplayColumns(queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.BROADCAST_LIST_DISPLAY_ITEMS}`,
        queryParams,
        method: 'DELETE',
        types: [
          types.DELETE_BC_DISPLAY_ITEMS_REQUEST,
          types.DELETE_BC_DISPLAY_ITEMS_SUCCESS,
          types.DELETE_BC_DISPLAY_ITEMS_ERROR,
        ],
      },
      true,
    );
  };
}
export function flushBroadcastAllState() {
  return {
    type: types.FLUSH_BROADCAST_ALL_STATE,
  };
}
