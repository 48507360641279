import React, {Component} from 'react';

import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field} from 'redux-form';

import {getInterfaceTemplatesAction, getInterfaceTemplateItemsAction} from 'client/ducks/interface-templates/actions';

import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

import '../../at-set-item/at-set-item.scss';
import './select-interface-template-modal.scss';

class SelectInterfaceTemplateModal extends Component {
  static defaultProps = {
    show: false,
  };

  static propTypes = {
    show: PropTypes.bool,
    interfaceFamily: PropTypes.string,
    interfaceTemplateId: PropTypes.number,
    interfaceTemplates: PropTypes.array,
    lang: PropTypes.object.isRequired,
    langLabels: PropTypes.object.isRequired,
    options: PropTypes.arrayOf(PropTypes.object),
    onClose: PropTypes.func.isRequired,
    fetchInterfaceTemplates: PropTypes.func.isRequired,
    fetchTemplateItems: PropTypes.func.isRequired,
    templateChangeHandler: PropTypes.func.isRequired,
  };

  render() {
    const {show, onClose, interfaceTemplates, interfaceFamily, templateChangeHandler, lang, langLabels} = this.props;
    const extendedInterfaceTemplates = [
      {
        value: 0,
        label: langLabels.CREATE_ITEMS_FROM_SCRATCH_LABEL,
      },
      ...sortBy(interfaceTemplates, [
        function (item) {
          return item.label.toLowerCase();
        },
      ]),
    ];

    return (
      <Modal
        show={show}
        dialogClassName="select-interface-template-modal modal-window--width-1 theme-color-8"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={langLabels.INTERFACE_FAMILY_LABEL}
      >
        <Field
          label={langLabels.INTERFACE_FAMILY_LABEL}
          cssModifier="select--no-min-height"
          name="interface_template"
          type="select"
          options={extendedInterfaceTemplates}
          searchable={false}
          component={fieldTemplate}
          disabled={interfaceFamily !== 'InternalInterface'}
          scrollBarProps={{
            autoHeightMax: 250,
          }}
        />
        <div className="select-interface-template-modal__buttons">
          <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onClose}>
            {lang.CANCEL_BUTTON}
          </button>
          <button
            className="button button--bg-7 modal-window__footer-btn"
            type="button"
            onClick={templateChangeHandler}
          >
            {lang.SAVE_BUTTON}
          </button>
        </div>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.languageState.payload.AUTOTASK.SET_INTERFACE_NAME_MODAL,
    langLabels: state.languageState.payload.AUTOTASK.SET_INTERFACE_FAMILY_MODAL,
  }),
  {
    fetchInterfaceTemplates: getInterfaceTemplatesAction,
    fetchTemplateItems: getInterfaceTemplateItemsAction,
  },
)(SelectInterfaceTemplateModal);
