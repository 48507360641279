import React, {useMemo} from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectActiveLanguages} from 'client/ducks/language/selectors';

import {SelectField, TextField} from 'client/common/fields';

import Icon from 'client/components/common/icon';

import {ClientUserModalFormName} from 'client/components/client-account-parameters/modals/client-user-modal';
import {USER_CIVILITY} from 'client/models/users/constants';

import ClientUserTitle from '../client-user-title';

import cssModule from './client-user-general-step.module.scss';

const b = bem('client-user-general-step', {cssModule});

const ClientUserGeneralStep: React.FC = () => {
  const lang = useLanguage('CLIENT_ACCOUNT_PARAMETERS.MODALS.CLIENT_USER');

  const {formValues} = useReduxForm(ClientUserModalFormName);

  const languages = useSelector(selectActiveLanguages);

  const options = useMemo(() => {
    return (
      languages?.map((language) => ({
        label: (
          <span className={b('language-label')}>
            {language.full_name}
            <Icon name={`flag-${language.name}`} className={b('flag-icon')} />
          </span>
        ),
        value: language.name,
      })) || []
    );
  }, [languages]);

  const civilityOptions = [
    {
      label: lang.MR,
      value: USER_CIVILITY.MALE,
    },
    {
      label: lang.MRS,
      value: USER_CIVILITY.FEMALE,
    },
  ];

  return (
    <div className={b()}>
      <ClientUserTitle email={formValues.email} />
      <SelectField
        className={b('field', ['civility'])}
        options={civilityOptions}
        label={lang.CIVILITY_LABEL}
        simpleValue
        name="title"
      />
      <div className={b('row')}>
        <TextField label={lang.FIRST_NAME_LABEL} required name="first_name" />
        <TextField label={lang.LAST_NAME_LABEL} required name="last_name" />
      </div>
      <SelectField
        className={b('field', ['language'])}
        label={lang.LANGUAGE_LABEL}
        simpleValue
        name="locale"
        options={options}
      />
    </div>
  );
};

export default ClientUserGeneralStep;
