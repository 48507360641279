import React from 'react';

import PropTypes from 'prop-types';

import {CHARACTERS, CHARACTER_FORMATS, PREFIXES} from 'client/ducks/coupons/constants';

import {TextField, DropdownField, TextAreaField} from 'client/components/common/fields';

import ExcludeCharactersLabel from './exclude-characters-label';

const WeezioFieldset = (props) => {
  const {lang, formValues} = props;

  return [
    <TextField
      key="weezio_maximum_tokens_number"
      name="weezio_maximum_tokens_number"
      label={lang.MAXIMUM_TOKENS_NUMBER}
    />,
    <TextField key="length_without_prefix" name="length_without_prefix" label={lang.LENGTH_WITHOUT_PREFIX} />,
    <DropdownField
      key="characters"
      name="characters"
      label={lang.CHARACTERS}
      options={[
        {value: CHARACTERS.ALPHANUMERIC, label: lang.ALPHANUMERIC},
        {value: CHARACTERS.ALPHABETICAL, label: lang.ALPHABETICAL},
        {value: CHARACTERS.NUMERIC, label: lang.NUMERIC},
      ]}
      onChange={(_, value) => {
        if (value === CHARACTERS.NUMERIC) {
          props.change('characters_format', null);
        } else if (!formValues.characters_format) {
          props.change('characters_format', CHARACTER_FORMATS.MIXED);
        }
      }}
    />,
    <DropdownField
      key="characters_format"
      name="characters_format"
      label={lang.CHARACTERS_FORMAT}
      disabled={formValues.characters === CHARACTERS.NUMERIC}
      options={[
        {value: CHARACTER_FORMATS.MIXED, label: lang.MIXED},
        {value: CHARACTER_FORMATS.CAPITAL_LETTERS, label: lang.CAPITAL_LETTERS},
        {value: CHARACTER_FORMATS.NON_CAPITAL_LETTERS, label: lang.NON_CAPITAL_LETTERS},
      ]}
    />,
    <TextAreaField
      key="exclude_characters"
      name="exclude_characters"
      label={<ExcludeCharactersLabel label={lang.EXCLUDE_CHARACTERS} text={lang.EXCLUDE_CHARACTERS_TOOLTIP} />}
    />,
    <DropdownField
      key="prefix1"
      name="prefix1"
      label={lang.PREFIX1}
      options={[
        {value: PREFIXES.WITHOUT, label: lang.WITHOUT},
        {value: PREFIXES.VARIABLE, label: lang.VARIABLE},
        {value: PREFIXES.FIXED_VALUE, label: lang.FIXED_VALUE},
      ]}
    />,
    <TextField
      key="prefix1_fixed_value"
      name="prefix1_fixed_value"
      label={lang.PREFIX1_FIXED_VALUE}
      show={formValues.prefix1 === PREFIXES.FIXED_VALUE}
    />,
    <DropdownField
      key="prefix2"
      name="prefix2"
      label={lang.PREFIX2}
      options={[
        {value: PREFIXES.WITHOUT, label: lang.WITHOUT},
        {value: PREFIXES.VARIABLE, label: lang.VARIABLE},
        {value: PREFIXES.FIXED_VALUE, label: lang.FIXED_VALUE},
      ]}
    />,
    <TextField
      key="prefix2_fixed_value"
      name="prefix2_fixed_value"
      label={lang.PREFIX2_FIXED_VALUE}
      show={formValues.prefix2 === PREFIXES.FIXED_VALUE}
    />,
  ];
};

WeezioFieldset.propTypes = {
  lang: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

export default WeezioFieldset;
