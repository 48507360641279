import React from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {selectAutotask} from 'client/ducks/autotask/selectors';

import ParticipantsExportTypeModal from 'client/components/participants/components/modals/participants-export-type-modal';
import ParticipantsFilterModal from 'client/components/participants/components/modals/participants-filters-modal';
import ParticipantsGenerateApiExportModalForm from 'client/components/participants/components/modals/participants-generate-api-export-modal';
import ParticipantsGenerateExportModal from 'client/components/participants/components/modals/participants-generate-export-modal';
import ParticipantsTableSettingsModal from 'client/components/participants/components/modals/participants-table-settings-modal';
import {MODALS_MAP} from 'client/components/participants/participants-list/constants';

const ParticipantsListModals = (props) => {
  const {
    visibleModal,
    tableSettingsModalTab,
    columnVisibilityItems,
    participationDisplayItems,
    handleCloseModal,
    handleShowModal,
    onFilterChange,
    queryParams,
    exportType,
    isAdmin,
    isErrorExportTypeModal,
    isAll,
    getExportQueryParams,
    checkedParticipations,
    availableExportColumns,
    onFetch,
    clientUsers,
  } = props;

  const autotask = useSelector(selectAutotask);
  const {id: taskId, client: {id: clientId} = {}, operation: {id: operationId} = {}} = autotask;

  const handleCloseAndFetch = () => {
    onFetch();
    handleCloseModal();
  };

  if (!taskId) {
    return null;
  }

  return (
    <>
      {visibleModal === MODALS_MAP.TABLE_SETTINGS && (
        <ParticipantsTableSettingsModal
          onClose={handleCloseModal}
          onSubmit={handleCloseAndFetch}
          defaultTab={tableSettingsModalTab}
          clientDisplayedColumns={columnVisibilityItems}
          adminDisplayedColumns={participationDisplayItems}
        />
      )}
      {visibleModal === MODALS_MAP.FILTERS && (
        <ParticipantsFilterModal
          show
          onClose={handleCloseModal}
          clientId={clientId?.toString()}
          taskId={taskId?.toString()}
          onFilterChange={onFilterChange}
          filter={queryParams.filter}
        />
      )}
      {visibleModal === MODALS_MAP.EXPORT_MODALS.EXPORT_TYPE_MODAL && (
        <ParticipantsExportTypeModal
          show
          onConfirm={() => handleShowModal(exportType)}
          onClose={handleCloseModal}
          modals={MODALS_MAP.EXPORT_MODALS}
          isAdmin={isAdmin}
          isError={isErrorExportTypeModal}
          isAll={isAll}
        />
      )}

      {visibleModal === MODALS_MAP.EXPORT_MODALS.GENERATE_EXPORT_MODAL && (
        <ParticipantsGenerateExportModal
          show
          clientId={clientId?.toString()}
          taskId={taskId?.toString()}
          operationId={operationId?.toString()}
          onClose={handleCloseModal}
          isAll={isAll}
          queryParams={getExportQueryParams()}
          checkedParticipants={checkedParticipations}
          generalParticipationColumns={availableExportColumns}
          onCancel={handleCloseModal}
          users={clientUsers}
        />
      )}
      {visibleModal === MODALS_MAP.EXPORT_MODALS.GENERATE_API_EXPORT_MODAL && (
        <ParticipantsGenerateApiExportModalForm
          show
          clientId={clientId}
          taskId={taskId}
          onClose={handleCloseModal}
          isAll={isAll}
          queryParams={getExportQueryParams()}
          checkedParticipants={checkedParticipations}
          onCancel={handleCloseModal}
          generalParticipationColumns={availableExportColumns}
          users={clientUsers}
        />
      )}
    </>
  );
};

ParticipantsListModals.propTypes = {
  availableExportColumns: PropTypes.object.isRequired,
  tableSettingsModalTab: PropTypes.string.isRequired,
  columnVisibilityItems: PropTypes.array.isRequired,
  participationDisplayItems: PropTypes.array.isRequired,
  onFetch: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleShowModal: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  queryParams: PropTypes.object.isRequired,
  getExportQueryParams: PropTypes.func.isRequired,
  checkedParticipations: PropTypes.array.isRequired,
  clientUsers: PropTypes.array.isRequired,

  isAdmin: PropTypes.bool.isRequired,
  isErrorExportTypeModal: PropTypes.bool.isRequired,
  isAll: PropTypes.bool.isRequired,

  visibleModal: PropTypes.string,
  exportType: PropTypes.string,
};

export default ParticipantsListModals;
