import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getIsNational} from 'client/services/cookie-data-source';

import {set} from 'client/common/meta/meta.actions';

import Template from '../components/templates/template';

class TemplatePage extends Component {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (this.props.template !== prevProps.template) {
      this.props.setMetaAction({title: this.props.template.name});
    }
  }

  render() {
    return <Template isNational={getIsNational()} id={+this.props.match.params.id} />;
  }
}

export default connect(({emailTemplates}) => ({template: emailTemplates.emailTemplate}), {
  setMetaAction: set,
})(TemplatePage);
