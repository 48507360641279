import React from 'react';

import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

import './label-step.scss';

const LabelStep = ({label, value}) => {
  return (
    <div className="label-step">
      <p className="label-step__label">{label}:</p>
      <p className="label-step__value">{value}</p>
    </div>
  );
};

LabelStep.defaultProps = {
  label: '',
  value: '',
};

LabelStep.propTypes = {
  label: TranslationJsx,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
};

export default LabelStep;
