import React, {useEffect, useCallback, useState} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useToggle, useUnmount} from 'react-use';

import {useQueryParams} from 'client/services/hooks';

import {getAnimations} from 'client/ducks/animations/actions';
import {clearAnimations} from 'client/ducks/animations/actions';
import {selectAnimations} from 'client/ducks/animations/selectors';

import {VALUE_FORMAT} from 'client/common/inputs/selects/week-select-input/constants';

import InstoreAnimationContainer from 'client/components/animations/containers/animation-config-container';
import InstoreAnimationTable from 'client/components/instore/tables/instore-animation-table';
import {DEFAULT_INCLUDES} from 'client/components/instore/tables/instore-animation-table/constants';
import {getSortForRequest} from 'client/components/instore/tables/instore-animation-table/helpers';

const InstoreWeekAnimationList = (props) => {
  const {task, week} = props;
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useQueryParams();
  const [loading, toggleLoading] = useToggle(false);
  const [showAnimationModal, toggleShowAnimationModal] = useToggle(false);
  const [animation, setAnimation] = useState(null);

  const animations = useSelector(selectAnimations);

  const fetchAnimations = useCallback(async () => {
    const [sortParamName, sortValue] = getSortForRequest(queryParams.sortField, queryParams.sortOrder);

    const params = {
      include_instore_animation_questions_statistic: null,
      q: {
        instore_task_id_eq: task.id,
        week_eq: moment(week, VALUE_FORMAT).isoWeek(),
        [sortParamName]: sortValue,
      },
      ...DEFAULT_INCLUDES,
    };

    toggleLoading();
    const result = await dispatch(getAnimations(params));
    toggleLoading();

    return result;
  }, [dispatch, task.id, week, queryParams.sortField, queryParams.sortOrder, toggleLoading]);

  useEffect(() => {
    fetchAnimations();
  }, [fetchAnimations]);

  useUnmount(() => {
    dispatch(clearAnimations());
  });

  const onEdit = (item) => {
    setAnimation(item);
    toggleShowAnimationModal();
  };

  const onSave = () => {
    fetchAnimations();
    toggleShowAnimationModal();
  };

  const onClose = useCallback(() => {
    toggleShowAnimationModal();
    setAnimation(null);
  }, [toggleShowAnimationModal]);

  return (
    <div>
      <InstoreAnimationTable
        animations={animations.data}
        onEdit={onEdit}
        onSortChange={setQueryParams}
        sortField={queryParams.sortField}
        sortOrder={queryParams.sortOrder}
        loading={loading}
      />
      {showAnimationModal && <InstoreAnimationContainer onClose={onClose} animation={animation} onSave={onSave} />}
    </div>
  );
};

InstoreWeekAnimationList.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  week: PropTypes.string.isRequired,
};

export default InstoreWeekAnimationList;
