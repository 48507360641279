import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {formatNumberSpace} from 'client/services/formatters';
import {getPercent} from 'client/services/helpers';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {TranslationJsx} from 'client/models/language/types';

import './donut-chart-legend.scss';

const b = bem('donut-chart-legend');

const DonutChartLegend = (props) => {
  const {data, legendOnRight, total, secondTotal, showPercent, legendValuesOnRight, maxHeight, getSecondPercent} =
    props;

  const hasSecondData = data.some(({secondValue}) => !!secondValue?.toString());

  const renderValues = (useSecondValue) => {
    return (
      <>
        <div className={b('legend-values')}>
          {data.map(({value, secondValue}, index) => (
            <div key={index} className={b('legend-value', {'right-align': legendValuesOnRight})}>
              {formatNumberSpace(useSecondValue ? secondValue : value)}
            </div>
          ))}
        </div>
        {showPercent && (
          <div className={b('legend-values', {percent: true})}>
            {data.map(({value, secondValue}, index) => (
              <div key={index} className={b('legend-value', {percent: true, 'right-align': legendValuesOnRight})}>
                {useSecondValue && getSecondPercent
                  ? getSecondPercent({value, secondValue})
                  : getPercent(useSecondValue ? secondTotal : total, useSecondValue ? secondValue : value)}
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <CustomScrollbars
      scrollbarProps={{
        autoHeightMax: maxHeight,
      }}
    >
      <div className={b({'legend-on-right': legendOnRight})}>
        <div className={b('legend-labels')}>
          {data.map(({label, color}, index) => (
            <div key={index} className={b('legend-label')} title={label}>
              <div className={b('marker')} style={{backgroundColor: color}} />
              {label}
            </div>
          ))}
        </div>
        {renderValues()}
        {hasSecondData && renderValues(true)}
      </div>
    </CustomScrollbars>
  );
};

DonutChartLegend.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: TranslationJsx,
      value: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  legendOnRight: PropTypes.bool,
  total: PropTypes.number,
  secondTotal: PropTypes.number,
  showPercent: PropTypes.bool,
  legendValuesOnRight: PropTypes.bool,
  maxHeight: PropTypes.number,
  getSecondPercent: PropTypes.func,
};

DonutChartLegend.defaultProps = {
  chartData: null,
  legendOnRight: false,
  secondTotal: 0,
  total: 0,
  showPercent: false,
  legendValuesOnRight: false,
  maxHeight: 200,
};

export default DonutChartLegend;
