import React, {PureComponent} from 'react';

import flow from 'lodash/flow';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import {required} from 'client/services/validator';

import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

class AddFromVariableModal extends PureComponent {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    variables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onClose: PropTypes.func,
    onAdd: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onClose: () => {},
    onAdd: () => {},
  };

  LANGUAGE = this.props.languageState.payload.AUTOTASK.ADD_IMAGE_MODAL;

  rules = {
    select: [required('REQUIRED')],
  };

  handleClose = () => {
    this.props.onClose();
  };

  formatVariables = (variables) =>
    variables.map((variable) => ({
      value: variable.id,
      label: variable.full_name || variable.name,
    }));

  render() {
    const {show, variables, handleSubmit} = this.props;

    const variablesOptions = this.formatVariables(variables);

    return (
      <Modal
        show={show}
        className="modal-window--width-1 theme-color-7 set-optin-modal"
        onClose={this.handleClose}
        title={this.LANGUAGE.TITLE}
      >
        <form onSubmit={handleSubmit}>
          <div className="form-field-wrap">
            <Field
              simpleValue
              cssModifier="select--no-min-height"
              label={this.LANGUAGE.IMAGE_LABEL}
              type="select"
              name="variableId"
              searchable={false}
              options={variablesOptions}
              component={fieldTemplate}
              validate={this.rules.select}
            />
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button type="button" className="button button--bg-11 modal-window__footer-btn" onClick={this.handleClose}>
              {this.LANGUAGE.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-13 modal-window__footer-btn" type="submit">
              {this.LANGUAGE.CREATE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => ({
  languageState: state.languageState,
  initialValues: {
    variableId: get(props, 'variables[0].id', null),
  },
});

export default flow([
  reduxForm({
    enableReinitialize: true,
    form: 'AddImageFromVariable',
    onSubmit: (values, dispatch, props) => {
      const variableId = Number(values.variableId);

      props.onAdd(variableId);
      props.reset();
    },
  }),
  connect(mapStateToProps),
])(AddFromVariableModal);
