import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import cssModule from './base-panel.module.scss';

const b = bem('base-panel', {cssModule});

type BasePanelProps = {
  children: React.ReactNode;
  className?: string;
};

const BasePanel: React.FC<BasePanelProps> = ({className = '', children}) => {
  return (
    <article className={cn(b(), className)}>
      <div>{children}</div>
    </article>
  );
};

export default BasePanel;
