export default {
  TOGGLE_ADD_PLACE_MODAL: '@@places/TOGGLE_ADD_PLACE_MODAL',
  TOGGLE_MAP_MODAL: '@@places/TOGGLE_MAP_MODAL',
  TOGGLE_LINK_TO_CLIENT_MODAL: '@@places/TOGGLE_LINK_TO_CLIENT_MODAL',

  GET_PLACES_REQUEST: '@@places/GET_PLACES_REQUEST',
  GET_PLACES_SUCCESS: '@@places/GET_PLACES_SUCCESS',
  GET_PLACES_ERROR: '@@places/GET_PLACES_ERROR',

  GET_PLACES_WITH_FILTER_REQUEST: '@@places/GET_PLACES_REQUEST',
  GET_PLACES_WITH_FILTER_SUCCESS: '@@places/GET_PLACES_SUCCESS',
  GET_PLACES_WITH_FILTER_ERROR: '@@places/GET_PLACES_ERROR',

  GET_MAPPED_PLACES_REQUEST: '@@places/GET_MAPPED_PLACES_REQUEST',
  GET_MAPPED_PLACES_SUCCESS: '@@places/GET_MAPPED_PLACES_SUCCESS',
  GET_MAPPED_PLACES_ERROR: '@@places/GET_MAPPED_PLACES_ERROR',

  ADD_PLACE_REQUEST: '@@places/ADD_PLACE_REQUEST',
  ADD_PLACE_SUCCESS: '@@places/ADD_PLACE_SUCCESS',
  ADD_PLACE_ERROR: '@@places/ADD_PLACE_ERROR',

  EDIT_PLACE_REQUEST: '@@places/EDIT_PLACE_REQUEST',
  EDIT_PLACE_SUCCESS: '@@places/EDIT_PLACE_SUCCESS',
  EDIT_PLACE_ERROR: '@@places/EDIT_PLACE_ERROR',

  LINK_TO_CLIENT_REQUEST: '@@places/LINK_TO_CLIENT_REQUEST',
  LINK_TO_CLIENT_SUCCESS: '@@places/LINK_TO_CLIENT_SUCCESS',
  LINK_TO_CLIENT_ERROR: '@@places/LINK_TO_CLIENT_ERROR',

  CHANGE_SELECTED_PLACES: '@@places/CHANGE_SELECTED_PLACES',
};
