import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';

import {selectIsAdmin} from 'client/ducks/user/selectors';

// components
import Modal from 'client/common/modals/modal';

import VisFiltersForm from '../../forms/vis-filters-form';

class VisFiltersModal extends ReactQueryParams {
  static propTypes = {
    show: PropTypes.bool,
    totalItems: PropTypes.number,
    initialize: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    updateMe: PropTypes.func,
    lang: PropTypes.object.isRequired,
    langStatuses: PropTypes.object.isRequired,
    langVisibleStatuses: PropTypes.object.isRequired,
    langOrientations: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    show: false,
    totalItems: 0,
    onClose: () => {},
    updateMe: () => {},
  };

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.initFilters(this.queryParams.filters);
    }
  }

  initFilters = (filters = {}) => {
    return this.props.initialize(
      Object.keys(filters)
        .map((k) => (filters[k].map ? filters[k].map((v) => `${k}$${v}`) : [filters[k]].map((v) => `${k}$${v}`)))
        .reduce((arr, i) => arr.concat(i), [])
        .reduce((obj, i) => ({...obj, [i]: true}), {}),
    );
  };

  render() {
    const {lang, langStatuses, langVisibleStatuses, langOrientations} = this.props;
    const {show, onClose, totalItems, updateMe, isAdmin} = this.props;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-13"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TITLE}
      >
        <form>
          <VisFiltersForm
            lang={lang}
            langStatuses={langStatuses}
            langVisibleStatuses={langVisibleStatuses}
            langOrientations={langOrientations}
            initFilters={this.initFilters}
            updateMe={updateMe}
            isAdmin={isAdmin}
          />
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-8 modal-window__footer-btn" type="button" onClick={onClose}>
              {`${lang.RESULTS_BUTTON} (${totalItems})`}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const VisFiltersModalForm = reduxForm({
  form: 'VisFiltersModalForm',
  enableReinitialize: true,
})(VisFiltersModal);

export default connect(({languageState, ...state}) => ({
  lang: languageState.payload.VISUALS.FILTERS_MODAL,
  langStatuses: languageState.payload.VISUALS.PREVIEW_MODAL.STATUSES,
  langVisibleStatuses: languageState.payload.VISUALS.PREVIEW_MODAL.VISIBLE_STATUSES,
  langOrientations: languageState.payload.VISUALS.TABLE.ORIENTATIONS,
  isAdmin: selectIsAdmin(state),
}))(VisFiltersModalForm);
