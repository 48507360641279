import React from 'react';

import PropTypes from 'prop-types';

import {LINK_KINDS} from 'client/common/config';

import Grid from 'client/components/common/grid';

function LinkItem(props) {
  const {linkName, clicksTime, linkKind} = props;
  const cssClass = linkKind !== LINK_KINDS.REGULAR ? 'theme-color-16' : '';

  return (
    <Grid.Row className="main-text">
      <Grid.Col size="8" className={cssClass}>
        {linkName}
      </Grid.Col>
      <Grid.Col size="4">{clicksTime}</Grid.Col>
    </Grid.Row>
  );
}

LinkItem.propTypes = {
  linkName: PropTypes.string,
  linkKind: PropTypes.string,
  clicksTime: PropTypes.string,
};

export default LinkItem;
