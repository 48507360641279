import cloneDeep from 'lodash/cloneDeep';

export const makeCopyGeneralParticipationColumns = (generalParticipationColumns) => {
  const mappedGeneralParticipationColumns = cloneDeep(generalParticipationColumns);
  const groupKeys = Object.keys(generalParticipationColumns);
  const checkedGroupKeys = groupKeys.filter((groupKey) => generalParticipationColumns[groupKey].checked);
  checkedGroupKeys.forEach((checkedKey) => (mappedGeneralParticipationColumns[checkedKey].checked = true));

  return mappedGeneralParticipationColumns;
};
