import React from 'react';

import bem from 'client/services/bem';
import {capitalizeFirstLetter} from 'client/services/formatters';
import {useLanguage} from 'client/services/hooks';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {Device} from 'client/models/devices/types';

import GeneralDeviceInfo from './general-device-info';

import cssModule from './client-device-modal-info-block.module.scss';

const b = bem('client-device-modal-info-block', {cssModule});

type ClientDeviceModalInfoBlockProps = {
  device: Device;
};

const ClientDeviceModalInfoBlock: React.FC<ClientDeviceModalInfoBlockProps> = ({device}) => {
  const lang = useLanguage('CLIENT_DEVICES.MODAL.GENERAL_TAB');

  const screenSize = `${device.device_family?.screen_height}x${device.device_family?.screen_width} px`;

  return (
    <CustomScrollbars scrollbarProps={{autoHeightMax: 325}}>
      <div className={b()}>
        <GeneralDeviceInfo title={lang.TYPE} values={[device.device_family?.name].map(capitalizeFirstLetter)} />
        <GeneralDeviceInfo title={lang.COMPONENTS} values={device.components.map(capitalizeFirstLetter)} />
        <GeneralDeviceInfo title={lang.SCREEN} values={[device.device_family?.screen_orientation, screenSize]} />
        <GeneralDeviceInfo title={lang.SOFTWARE} values={[device?.software].map(capitalizeFirstLetter)} />
        <GeneralDeviceInfo title={lang.OS} values={[device?.os].map(capitalizeFirstLetter)} />
      </div>
    </CustomScrollbars>
  );
};

export default ClientDeviceModalInfoBlock;
