import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {ClientTableColumnType, ClientTableProps} from 'client/components/common/client-table/types';

const b = bem('client-table');

const ClientTableHeader = (props) => {
  const {column, sortOrder, sortField, rowSpan, classNames = {}, onSortChange, isSub = false} = props;

  const subcolumns = column.subcolumns?.filter(({show}) => show !== false);
  const noSort = sortField !== column.name;
  const sortAsc = noSort || sortOrder === 'asc';
  const sortDesc = noSort || sortOrder === 'desc';
  const colSpan = subcolumns?.length || 1;
  const spans = {};

  if (rowSpan > 1 && !column.subcolumns) {
    spans.rowSpan = rowSpan;
  }
  if (rowSpan > 1 && column.subcolumns) {
    spans.colSpan = colSpan;
  }

  const contentClassName = cn(classNames?.header, b('header', {sortable: column.sortable}));

  return (
    <th
      key={column.key || column.name}
      className={cn(
        b('th', {
          [`align-${column.align}`]: !!column.align,
          sub: isSub,
        }),
        classNames?.th,
        column.headerClassName,
      )}
      style={{minWidth: column.width, maxWidth: column.width, width: column.width}} // setting width only doesn't work with colspan
      {...spans}
    >
      {column.renderHeader ? (
        <div className={contentClassName}>
          {column.sortable && (
            <button type="button" onClick={onSortChange} className={b('sort')}>
              {sortAsc && <div className={b('sort-up')} />}
              {sortDesc && <div className={b('sort-down')} />}
            </button>
          )}
          {column.renderHeader(column)}
        </div>
      ) : (
        <button type="button" className={contentClassName} onClick={onSortChange}>
          {column.sortable && (
            <div className={b('sort')}>
              {sortAsc && <div className={b('sort-up')} />}
              {sortDesc && <div className={b('sort-down')} />}
            </div>
          )}
          <div
            className={cn(b('header-label', {legend: column.hasLegend}), classNames?.label, column.labelClassName)}
            title={column.title}
          >
            {column.label}
          </div>
        </button>
      )}
    </th>
  );
};

ClientTableHeader.propTypes = {
  column: PropTypes.shape(ClientTableColumnType).isRequired,
  onSortChange: PropTypes.func,
  sortOrder: PropTypes.string,
  sortField: PropTypes.string,
  rowSpan: PropTypes.number.isRequired,
  classNames: ClientTableProps.commonHeaderClassNames,
  isSub: PropTypes.bool,
};

ClientTableHeader.defaultProps = {
  onSortChange: null,
  sortOrder: '',
  sortField: '',
  isSub: false,
};

export default ClientTableHeader;
