import sortBy from 'lodash/sortBy';

import {DeviceAffectation, AffectationInfoMode} from 'client/models/device-affectations';

export const getComments = (affectation: DeviceAffectation, mode: AffectationInfoMode) => {
  const data = affectation.device_affectation_infos;
  if (!data?.length) {
    return [];
  }
  return sortBy(
    data.filter((i) => i.mode === mode && i.comment),
    'timestamp',
  ).reverse();
};
