export default {
  // MESSAGE TASK ACTION TYPES
  GET_MESSAGE_TASKS_REQUEST: '@@message-task/GET_MESSAGE_TASKS_REQUEST',
  GET_MESSAGE_TASKS_SUCCESS: '@@message-task/GET_MESSAGE_TASKS_SUCCESS',
  GET_MESSAGE_TASKS_ERROR: '@@message-task/GET_MESSAGE_TASKS_ERROR',

  GET_MESSAGE_TASK_REQUEST: '@@message-task/GET_MESSAGE_TASK_REQUEST',
  GET_MESSAGE_TASK_SUCCESS: '@@message-task/GET_MESSAGE_TASK_SUCCESS',
  GET_MESSAGE_TASK_ERROR: '@@message-task/GET_MESSAGE_TASK_ERROR',

  UPDATE_MESSAGE_TASK_REQUEST: '@@message-task/UPDATE_MESSAGE_TASK_REQUEST',
  UPDATE_MESSAGE_TASK_SUCCESS: '@@message-task/UPDATE_MESSAGE_TASK_SUCCESS',
  UPDATE_MESSAGE_TASK_ERROR: '@@message-task/UPDATE_MESSAGE_TASK_ERROR',

  DELETE_MESSAGE_TASK_REQUEST: '@@message-task/DELETE_MESSAGE_TASK_REQUEST',
  DELETE_MESSAGE_TASK_SUCCESS: '@@message-task/DELETE_MESSAGE_TASK_SUCCESS',
  DELETE_MESSAGE_TASK_ERROR: '@@message-task/DELETE_MESSAGE_TASK_ERROR',

  GET_MESSAGES_REQUEST: '@@message-task/GET_MESSAGES_REQUEST',
  GET_MESSAGES_SUCCESS: '@@message-task/GET_MESSAGES_SUCCESS',
  GET_MESSAGES_ERROR: '@@message-task/GET_MESSAGES_ERROR',

  TOGGLE_MESSAGE_TASK_REQUEST: '@@message-task/TOGGLE_MESSAGE_TASK_REQUEST',
  TOGGLE_MESSAGE_TASK_SUCCESS: '@@message-task/TOGGLE_MESSAGE_TASK_SUCCESS',
  TOGGLE_MESSAGE_TASK_ERROR: '@@message-task/TOGGLE_MESSAGE_TASK_ERROR',

  DELETE_MAPPING_ITEMS_REQUEST: '@@message-task/DELETE_MAPPING_ITEMS_REQUEST',
  DELETE_MAPPING_ITEMS_SUCCESS: '@@message-task/DELETE_MAPPING_ITEMS_SUCCESS',
  DELETE_MAPPING_ITEMS_ERROR: '@@message-task/DELETE_MAPPING_ITEMS_ERROR',

  FLUSH_MESSAGE_TASK_ALL_STATE: '@@message-task/FLUSH_MESSAGE_TASK_ALL_STATE',

  // SMS TASK ACTION TYPES
  CREATE_SMS_TASK_REQUEST: '@@message-task/CREATE_SMS_TASK_REQUEST',
  CREATE_SMS_TASK_SUCCESS: '@@message-task/CREATE_SMS_TASK_SUCCESS',
  CREATE_SMS_TASK_ERROR: '@@message-task/CREATE_SMS_TASK_ERROR',
  // EMAIL TASK ACTION TYPES
  CREATE_EMAIL_TASK_REQUEST: '@@message-task/CREATE_EMAIL_TASK_REQUEST',
  CREATE_EMAIL_TASK_SUCCESS: '@@message-task/CREATE_EMAIL_TASK_SUCCESS',
  CREATE_EMAIL_TASK_ERROR: '@@message-task/CREATE_EMAIL_TASK_ERROR',
};
