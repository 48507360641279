import {CLIENT_TYPES} from 'client/common/config';

export const mapClient = (client) => {
  const {street_address} = client;
  const parts = street_address ? street_address.split('###') : [];

  return {
    ...client,
    address1: parts[0] || null,
    address2: parts[1] || null,
    address3: parts[2] || null,
    isAgency: client.type === CLIENT_TYPES.AGENCY,
    isCompany: client.type === CLIENT_TYPES.COMPANY && !!client.agency_id,
    isDirect: client.type === CLIENT_TYPES.COMPANY && !client.agency_id,
  };
};
