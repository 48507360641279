export default {
  GET_OPERATION_EMAIL_TEMPLATES_REQUEST: '@@email-templates/GET_OPERATION_EMAIL_TEMPLATES_REQUEST',
  GET_OPERATION_EMAIL_TEMPLATES_SUCCESS: '@@email-templates/GET_OPERATION_EMAIL_TEMPLATES_SUCCESS',
  GET_OPERATION_EMAIL_TEMPLATES_ERROR: '@@email-templates/GET_OPERATION_EMAIL_TEMPLATES_ERROR',

  GET_EMAIL_TEMPLATE_REQUEST: '@@email-templates/GET_EMAIL_TEMPLATE_REQUEST',
  GET_EMAIL_TEMPLATE_SUCCESS: '@@email-templates/GET_EMAIL_TEMPLATE_SUCCESS',
  GET_EMAIL_TEMPLATE_ERROR: '@@email-templates/GET_EMAIL_TEMPLATE_ERROR',

  CREATE_EMAIL_TEMPLATE_REQUEST: '@@email-templates/CREATE_EMAIL_TEMPLATE_REQUEST',
  CREATE_EMAIL_TEMPLATE_SUCCESS: '@@email-templates/CREATE_EMAIL_TEMPLATE_SUCCESS',
  CREATE_EMAIL_TEMPLATE_ERROR: '@@email-templates/CREATE_EMAIL_TEMPLATE_ERROR',

  PATCH_EMAIL_TEMPLATE_REQUEST: '@@email-templates/PATCH_EMAIL_TEMPLATE_REQUEST',
  PATCH_EMAIL_TEMPLATE_SUCCESS: '@@email-templates/PATCH_EMAIL_TEMPLATE_SUCCESS',
  PATCH_EMAIL_TEMPLATE_ERROR: '@@email-templates/PATCH_EMAIL_TEMPLATE_ERROR',

  PATCH_EMAIL_TEMPLATE_LINK_REQUEST: '@@email-templates/PATCH_EMAIL_TEMPLATE_LINK_REQUEST',
  PATCH_EMAIL_TEMPLATE_LINK_SUCCESS: '@@email-templates/PATCH_EMAIL_TEMPLATE_LINK_SUCCESS',
  PATCH_EMAIL_TEMPLATE_LINK_ERROR: '@@email-templates/PATCH_EMAIL_TEMPLATE_LINK_ERROR',

  FETCH_EDITED_EMAIL_TEMPLATE_REQUEST: '@@email-templates/FETCH_EDITED_EMAIL_TEMPLATE_REQUEST',
  FETCH_EDITED_EMAIL_TEMPLATE_SUCCESS: '@@email-templates/FETCH_EDITED_EMAIL_TEMPLATE_SUCCESS',
  FETCH_EDITED_EMAIL_TEMPLATE_ERROR: '@@email-templates/FETCH_EDITED_EMAIL_TEMPLATE_ERROR',

  GET_EMAIL_TEMPLATE_KINDS_REQUEST: '@@email-templates/GET_EMAIL_TEMPLATE_KINDS_REQUEST',
  GET_EMAIL_TEMPLATE_KINDS_SUCCESS: '@@email-templates/GET_EMAIL_TEMPLATE_KINDS_SUCCESS',
  GET_EMAIL_TEMPLATE_KINDS_ERROR: '@@email-templates/GET_EMAIL_TEMPLATE_KINDS_ERROR',

  GET_ACTIVE_EMAIL_TEMPLATE_KIND_REQUEST: '@@email-templates/GET_ACTIVE_EMAIL_TEMPLATE_KIND_REQUEST',
  GET_ACTIVE_EMAIL_TEMPLATE_KIND_SUCCESS: '@@email-templates/GET_ACTIVE_EMAIL_TEMPLATE_KIND_SUCCESS',
  GET_ACTIVE_EMAIL_TEMPLATE_KIND_ERROR: '@@email-templates/GET_ACTIVE_EMAIL_TEMPLATE_KIND_ERROR',

  GET_PRIZES_REQUEST: '@@email-templates/GET_PRIZES_REQUEST',
  GET_PRIZES_SUCCESS: '@@email-templates/GET_PRIZES_SUCCESS',
  GET_PRIZES_ERROR: '@@email-templates/GET_PRIZES_ERROR',

  UPLOAD_EMAIL_TEMPLATE_IMAGE_REQUEST: '@@email-templates/UPLOAD_EMAIL_TEMPLATE_IMAGE_REQUEST',
  UPLOAD_EMAIL_TEMPLATE_IMAGE_SUCCESS: '@@email-templates/UPLOAD_EMAIL_TEMPLATE_IMAGE_SUCCESS',
  UPLOAD_EMAIL_TEMPLATE_IMAGE_ERROR: '@@email-templates/UPLOAD_EMAIL_TEMPLATE_IMAGE_ERROR',

  GET_MASTER_EMAIL_TEMPLATES_REQUEST: '@@templates/GET_MASTER_EMAIL_TEMPLATES_REQUEST',
  GET_MASTER_EMAIL_TEMPLATES_SUCCESS: '@@templates/GET_MASTER_EMAIL_TEMPLATES_SUCCESS',
  GET_MASTER_EMAIL_TEMPLATES_ERROR: '@@templates/GET_MASTER_EMAIL_TEMPLATES_ERROR',

  GET_EMAIL_SENDERS_REQUEST: '@@templates/GET_EMAIL_SENDERS_REQUEST',
  GET_EMAIL_SENDERS_SUCCESS: '@@templates/GET_EMAIL_SENDERS_SUCCESS',
  GET_EMAIL_SENDERS_ERROR: '@@templates/GET_EMAIL_SENDERS_ERROR',

  UPDATE_EMAIL_TEMPLATE_PARAMETERS_REQUEST: '@@templates/UPDATE_EMAIL_TEMPLATE_PARAMETERS_REQUEST',
  UPDATE_EMAIL_TEMPLATE_PARAMETERS_SUCCESS: '@@templates/UPDATE_EMAIL_TEMPLATE_PARAMETERS_SUCCESS',
  UPDATE_EMAIL_TEMPLATE_PARAMETERS_ERROR: '@@templates/UPDATE_EMAIL_TEMPLATE_PARAMETERS_ERROR',

  SEND_TEST_EMAIL_REQUEST: '@@templates/SEND_TEST_EMAIL_REQUEST',
  SEND_TEST_EMAIL_SUCCESS: '@@templates/SEND_TEST_EMAIL_SUCCESS',
  SEND_TEST_EMAIL_ERROR: '@@templates/SEND_TEST_EMAIL_ERROR',

  PARSE_MJML_REQUEST: '@@templates/PARSE_MJML_REQUEST',
  PARSE_MJML_SUCCESS: '@@templates/PARSE_MJML_SUCCESS',
  PARSE_MJML_ERROR: '@@templates/PARSE_MJML_ERROR',

  SET_ACTIVE_EMAIL_TEMPLATE: '@@email-templates/SET_ACTIVE_EMAIL_TEMPLATE',
  SET_ACTIVE_EMAIL_TEMPLATE_KIND: '@@email-templates/SET_ACTIVE_EMAIL_TEMPLATE_KIND',
  ADD_EMAIL_TEMPLATE_VARIABLE: '@@email-templates/ADD_EMAIL_TEMPLATE_VARIABLE',

  EDITOR_SET_DOC_DATA: '@@email-templates/EDITOR_SET_DOC_DATA',
  EDITOR_CREATE_SECTION: '@@email-templates/EDITOR_CREATE_SECTION',
  EDITOR_UPDATE_SECTION: '@@email-templates/EDITOR_UPDATE_SECTION',
  EDITOR_MOVE_SECTION: '@@email-templates/EDITOR_MOVE_SECTION',
  EDITOR_DELETE_SECTION: '@@email-templates/EDITOR_DELETE_SECTION',
  EDITOR_CREATE_CONTENT_BLOCK: '@@email-templates/EDITOR_CREATE_CONTENT_BLOCK',
  EDITOR_UPDATE_CONTENT_BLOCK: '@@email-templates/EDITOR_UPDATE_CONTENT_BLOCK',
  EDITOR_DELETE_CONTENT_BLOCK: '@@email-templates/EDITOR_DELETE_CONTENT_BLOCK',
  EDITOR_MOVE_CONTENT_BLOCK: '@@email-templates/EDITOR_MOVE_CONTENT_BLOCK',
  EDITOR_SET_DND_DATA: '@@email-templates/EDITOR_SET_DND_DATA',
  EDITOR_SET_ACTIVE_BLOCK_ID: '@@email-templates/EDITOR_SET_ACTIVE_BLOCK_ID',
  EDITOR_TOGGLE_EDIT_MODE: '@@email-templates/EDITOR_TOGGLE_EDIT_MODE',
  EDITOR_ADD_IMAGE_FOR_DELETE: '@@email-templates/EDITOR_ADD_IMAGE_FOR_DELETE',
  EDITOR_RESET_IMAGE_FOR_DELETE: '@@email-templates/EDITOR_RESET_IMAGE_FOR_DELETE',

  GET_GALLERY_EMAIL_TEMPLATES_REQUEST: '@@email-templates/GET_GALLERY_EMAIL_TEMPLATES_REQUEST',
  GET_GALLERY_EMAIL_TEMPLATES_SUCCESS: '@@email-templates/GET_GALLERY_EMAIL_TEMPLATES_SUCCESS',
  GET_GALLERY_EMAIL_TEMPLATES_ERROR: '@@email-templates/GET_GALLERY_EMAIL_TEMPLATES_ERROR',

  REPLACE_EMAIL_TEMPLATE_REQUEST: '@@email-templates/REPLACE_EMAIL_TEMPLATE_REQUEST',
  REPLACE_EMAIL_TEMPLATE_SUCCESS: '@@email-templates/REPLACE_EMAIL_TEMPLATE_SUCCESS',
  REPLACE_EMAIL_TEMPLATE_ERROR: '@@email_templates/REPLACE_EMAIL_TEMPLATE_ERROR',
};
