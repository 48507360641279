import React, {memo, useMemo, useCallback} from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';

import ClientTable from 'client/components/common/client-table';

import MediasActionsCell from 'client/components/medias/cells/medias-actions-cell';
import MediasDurationCell from 'client/components/medias/cells/medias-duration-cell';
import MediasLockedCell from 'client/components/medias/cells/medias-locked-cell';
import MediasLoopsCell from 'client/components/medias/cells/medias-loops-cell';
import MediasNameCell from 'client/components/medias/cells/medias-name-cell';
import MediasOrderCell from 'client/components/medias/cells/medias-order-cell';
import MediasTypeCell from 'client/components/medias/cells/medias-type-cell';
import {MEDIA_LEVELS} from 'client/components/medias/constants';
import {getMediaLevel} from 'client/components/medias/helpers';

import './medias-config-table.scss';

const b = bem('medias-config-table');

/**
 * @type {React.FC<MediasConfigTable.propTypes>}
 */
const MediasConfigTable = memo((props) => {
  const {formValues, change, isLocal, loading, wide} = props;
  const lang = useSelector((state) => state.languageState.payload.MEDIAS);
  const level = getMediaLevel();
  const compareLevel = isLocal ? MEDIA_LEVELS.DEVICE : level;

  const cellClassName = useCallback(
    (item) => {
      return item.level !== compareLevel && item.locked ? b('locked-cell') : '';
    },
    [compareLevel],
  );

  const columns = useMemo(
    () => [
      {
        name: 'order',
        label: lang.LEVEL_ORDER,
        cellClassName,
        render: (row) => (
          <MediasOrderCell level={level} isLocal={isLocal} medias={formValues.medias} change={change} {...row} />
        ),
      },
      {
        name: 'locked',
        cellClassName,
        show: !isLocal && level !== MEDIA_LEVELS.STORE,
        render: (row) => <MediasLockedCell level={level} isLocal={isLocal} change={change} {...row} />,
      },
      {
        name: 'name',
        label: lang.MEDIA,
        width: wide ? 500 : null,
        cellClassName,
        render: (row) => <MediasNameCell level={level} isLocal={isLocal} {...row} />,
      },
      {
        name: 'type',
        label: lang.TYPE,
        cellClassName,
        render: (row) => <MediasTypeCell level={level} {...row} />,
      },
      {
        name: 'duration',
        label: lang.DURATION,
        width: '85px',
        cellClassName,
        render: (row) => <MediasDurationCell level={level} isLocal={isLocal} {...row} />,
      },
      {
        name: 'loops',
        label: lang.LOOPS,
        cellClassName,
        render: (row) => <MediasLoopsCell level={level} isLocal={isLocal} {...row} />,
      },
      {
        name: 'actions',
        cellClassName,
        label: lang.ACTIONS,
        render: (row) => (
          <MediasActionsCell level={level} isLocal={isLocal} formValues={formValues} change={change} {...row} />
        ),
      },
    ],
    [isLocal, lang, level, formValues, change, cellClassName, wide],
  );

  return (
    <div className={b()}>
      <ClientTable
        data={formValues.medias}
        columns={columns}
        loading={loading}
        loadingColor="primary"
        classNames={{
          cell: b('cell'),
          header: {
            th: b('th'),
          },
        }}
      />
    </div>
  );
});

MediasConfigTable.propTypes = {
  formValues: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  isLocal: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  wide: PropTypes.bool,
};

MediasConfigTable.defaultProps = {
  isLocal: false,
  wide: false,
};

export default MediasConfigTable;
