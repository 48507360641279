import React from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams} from 'client/services/hooks';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';

import AgencyClientsBar from 'client/components/agencies/various/agency-clients-bar';
import DataTabContainer from 'client/components/client-autotask/tabs/data-tab/data-common';
import {DATA_COMPONENTS} from 'client/components/client-autotask/tabs/data-tab/data-common/constants';
import {CLIENT_TYPES} from 'client/models/client/constants';

import cssModule from './leads-database.module.scss';

const b = bem('leads-database', {cssModule});

const LeadsDatabase: React.FC = () => {
  const lang = useLanguage('LEADS_DATABASE');
  const currentClient = useSelector(selectCurrentClient);
  const [{agencyClientId}] = useQueryParams(null, {}, {parse: {parseNumbers: true}});

  const isAgency = currentClient?.type === CLIENT_TYPES.AGENCY && currentClient?.client_management;
  const canGenerateFile =
    currentClient?.type !== CLIENT_TYPES.AGENCY || !agencyClientId || currentClient.id === +agencyClientId
      ? currentClient?.crm_management
      : currentClient?.client_management;

  if (!currentClient?.id) {
    return null;
  }

  return (
    <div className={b({agency: isAgency})}>
      {isAgency && <AgencyClientsBar agencyId={currentClient.id} agencyOption />}
      <h1 className={b('title')}>{lang.TITLE}</h1>
      <DataTabContainer
        component={DATA_COMPONENTS.LEADS_DATABASE}
        canGenerateFile={canGenerateFile}
        showReportsButtons
        hideHeader={true}
        clientId={+(agencyClientId || currentClient.id)}
        canShareFiles={false}
      />
    </div>
  );
};

export default LeadsDatabase;
