export const TYPE_VALUE = {
  MINISITE: 'minisite',
  IFRAME: 'iframe',
};

export const TYPE_LABEL = {
  [TYPE_VALUE.MINISITE]: 'Minisite',
  [TYPE_VALUE.IFRAME]: 'Iframe',
};

export const CLIENT_SIDE_TYPES = [TYPE_VALUE.MINISITE, TYPE_VALUE.IFRAME];
