import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import './typography.scss';

const variantsMapping = {
  'title-h1': 'h1',
  headings: 'h1',
  'title-h2': 'h2',
  'title-h3': 'h3',
  'table-header': 'p',
  'text-additional': 'p',
  text: 'p',
  'text-small': 'p',
};

const Typography = ({variant, id, className, bold, upper, theme, children, ...props}) => {
  const Component = variant ? variantsMapping[variant] : 'p';

  return (
    <Component
      id={id}
      className={classNames(className, {
        [variant]: variant,
        ['bold']: bold,
        ['uppercase']: upper,
        [`headings-${theme}`]: theme,
      })}
      {...props}
    >
      {children}
    </Component>
  );
};

Typography.propTypes = {
  variant: PropTypes.string.isRequired,
  className: PropTypes.string,
  bold: PropTypes.bool,
  upper: PropTypes.bool,
  theme: PropTypes.string,
  children: PropTypes.any,
  id: PropTypes.string,
};

Typography.defaultProps = {
  id: null,
};

export default Typography;
