import {Reducer} from 'redux';

import {ClientAccount} from 'client/models/client/types';
import {Membership} from 'client/models/memberships/types';
import {Store} from 'client/models/stores/types';
import {PaginationMeta, getDefaultMeta} from 'client/types/common/index';

import types from './types';

type ClientAccountState = {
  clientAccount: ClientAccount | null;
  clientMemberships: {
    data: Membership[];
    meta: PaginationMeta;
  };
  clientStores: {
    items: Store[];
    meta: PaginationMeta;
  };
};

const initialState: ClientAccountState = {
  clientAccount: null,
  clientMemberships: {
    data: [],
    meta: getDefaultMeta(),
  },
  clientStores: {
    items: [],
    meta: getDefaultMeta(),
  },
};

const clientAccountReducer: Reducer<ClientAccountState> = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CLIENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        clientAccount: action.payload.client_account,
      };

    case types.GET_MEMBERSHIPS_TEAM_USERS_SUCCESS:
    case types.GET_CLIENT_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        clientMemberships: {
          data:
            action.payload.company_memberships || action.payload.agency_memberships || action.payload.memberships || [],
          meta: action.payload.meta,
        },
      };

    case types.GET_CLIENT_STORES_SUCCESS:
      return {
        ...state,
        clientStores: {
          items: action.payload.places,
          meta: action.payload.meta,
        },
      };

    case types.UPDATE_MEMBERSHIP_DATA_TAB_ACCESS_SUCCESS:
      const id = action.payload.data_tab_access?.membership_id;
      const nextData = state.clientMemberships.data.map((membership) => {
        if (membership.id === id && membership.data_tab_access) {
          return {
            ...membership,
            data_tab_access: {
              id: action.payload.data_tab_access.id,
              leads: action.payload.data_tab_access.leads,
              participations: action.payload.data_tab_access.participations,
              winners: action.payload.data_tab_access.winners,
            },
          };
        }
        return membership;
      });

      return {
        ...state,
        clientMemberships: {
          ...state.clientMemberships,
          data: nextData,
        },
      };

    default:
      return state;
  }
};

export default clientAccountReducer;
