export default {
  GET_DIRECT_ACCESS_STATISTICS_REQUEST: '@@sources-statistics/GET_DIRECT_ACCESS_STATISTICS_REQUEST',
  GET_DIRECT_ACCESS_STATISTICS_SUCCESS: '@@sources-statistics/GET_DIRECT_ACCESS_STATISTICS_SUCCESS',
  GET_DIRECT_ACCESS_STATISTICS_ERROR: '@@sources-statistics/GET_DIRECT_ACCESS_STATISTICS_ERROR',

  GET_INTERFACES_STATISTICS_REQUEST: '@@sources-statistics/GET_INTERFACES_STATISTICS_REQUEST',
  GET_INTERFACES_STATISTICS_SUCCESS: '@@sources-statistics/GET_INTERFACES_STATISTICS_SUCCESS',
  GET_INTERFACES_STATISTICS_ERROR: '@@sources-statistics/GET_INTERFACES_STATISTICS_ERROR',

  GET_CAMPAIGNS_STATISTICS_REQUEST: '@@sources-statistics/GET_CAMPAIGNS_STATISTICS_REQUEST',
  GET_CAMPAIGNS_STATISTICS_SUCCESS: '@@sources-statistics/GET_CAMPAIGNS_STATISTICS_SUCCESS',
  GET_CAMPAIGNS_STATISTICS_ERROR: '@@sources-statistics/GET_CAMPAIGNS_STATISTICS_ERROR',

  GET_CAMPAIGN_SOURCES_STATISTICS_REQUEST: '@@sources-statistics/GET_CAMPAIGN_SOURCES_STATISTICS_REQUEST',
  GET_CAMPAIGN_SOURCES_STATISTICS_SUCCESS: '@@sources-statistics/GET_CAMPAIGN_SOURCES_STATISTICS_SUCCESS',
  GET_CAMPAIGN_SOURCES_STATISTICS_ERROR: '@@sources-statistics/GET_CAMPAIGN_SOURCES_STATISTICS_ERROR',
};
