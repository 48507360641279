import React, {useRef} from 'react';

import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useMount, useToggle} from 'react-use';

import bem from 'client/services/bem';
import {dateToString, timeToString} from 'client/services/helpers';

import {updateAnomalyOccurrence} from 'client/ducks/animations/actions';

import ImageContainer from 'client/common/image-container';
import {CheckboxInput} from 'client/common/inputs';

import './anomaly-occurrence-card.scss';

const b = bem('anomaly-occurrence-card');

const AnomalyOccurrenceCard = (props) => {
  const {item} = props;
  const dispatch = useDispatch();
  const ref = useRef();
  const [checked, toggleChecked] = useToggle(!item.viewed || item.manual_unviewed);
  let timeoutId = null;

  const updateOccurence = (data) => {
    toggleChecked();
    dispatch(updateAnomalyOccurrence(item.id, data));
  };

  useMount(() => {
    if (item.viewed) {
      return;
    }

    const observer = new IntersectionObserver((entries, observerInstance) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          timeoutId = setTimeout(() => {
            updateOccurence({viewed: true});
            observerInstance.unobserve(entry.target);
          }, 3000);
        } else {
          clearTimeout(timeoutId);
        }
      });
    });

    observer.observe(ref.current);
  });

  const descriptionLines = item.description?.split(/\r?\n|\r|\n/g).filter(Boolean) || [];

  return (
    <div className={b()} ref={ref}>
      <div className={b('top')}>
        <div className={b('top-left')}>
          {item.anomaly.icon_url && (
            <ImageContainer
              classNamePreview={b('img')}
              value={item.anomaly.icon_url}
              width={30}
              height={30}
              keepRatio={true}
            />
          )}
          <div>
            <p className={b('name')}>{item.anomaly.name}</p>
            <p className={b('date')}>
              {dateToString(item.created_at, 'DD/MM/YY')}
              <span className={b('time')}>{timeToString(item.created_at, 'HH:mm')}</span>
            </p>
          </div>
        </div>
        <CheckboxInput
          className={b('checkbox-wrap')}
          visualClassName={b('checkbox')}
          checked={checked}
          onCheck={(value) => updateOccurence({manual_unviewed: value})}
        />
      </div>
      <div className={b('bottom')}>
        <div>
          {descriptionLines.map((line, index) => (
            <p key={index} className={b('description')}>
              {line}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

AnomalyOccurrenceCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    viewed: PropTypes.bool.isRequired,
    manual_unviewed: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    anomaly: PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon_url: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default AnomalyOccurrenceCard;
