export default {
  GET_WINNINGS_REGIONS_REQUEST: '@@winnings/GET_WINNINGS_REGIONS_REQUEST',
  GET_WINNINGS_REGIONS_SUCCESS: '@@winnings/GET_WINNINGS_REGIONS_SUCCESS',
  GET_WINNINGS_REGIONS_ERROR: '@@winnings/GET_WINNINGS_REGIONS_ERROR',

  GET_WINNINGS_STORES_REQUEST: '@@winnings/GET_WINNINGS_STORES_REQUEST',
  GET_WINNINGS_STORES_SUCCESS: '@@winnings/GET_WINNINGS_STORES_SUCCESS',
  GET_WINNINGS_STORES_ERROR: '@@winnings/GET_WINNINGS_STORES_ERROR',

  GET_WINNINGS_DEVICES_REQUEST: '@@winnings/GET_WINNINGS_DEVICES_REQUEST',
  GET_WINNINGS_DEVICES_SUCCESS: '@@winnings/GET_WINNINGS_DEVICES_SUCCESS',
  GET_WINNINGS_DEVICES_ERROR: '@@winnings/GET_WINNINGS_DEVICES_ERROR',

  GET_WINNINGS_CAMPAIGNS_REQUEST: '@@winnings/GET_WINNINGS_CAMPAIGNS_REQUEST',
  GET_WINNINGS_CAMPAIGNS_SUCCESS: '@@winnings/GET_WINNINGS_CAMPAIGNS_SUCCESS',
  GET_WINNINGS_CAMPAIGNS_ERROR: '@@winnings/GET_WINNINGS_CAMPAIGNS_ERROR',

  GET_WINNINGS_REQUEST: '@@winnings/GET_WINNINGS_REQUEST',
  GET_WINNINGS_SUCCESS: '@@winnings/GET_WINNINGS_SUCCESS',
  GET_WINNINGS_ERROR: '@@winnings/GET_WINNINGS_ERROR',

  SEARCH_WINNER_REQUEST: '@@winnings/SEARCH_WINNER_REQUEST',
  SEARCH_WINNER_SUCCESS: '@@winnings/SEARCH_WINNER_SUCCESS',
  SEARCH_WINNER_ERROR: '@@winnings/SEARCH_WINNER_ERROR',
};
