import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Modal from 'client/common/modals/modal';

import AddPlaceForm from './add-place-form';

function AddPlaceModal({
  show,
  onClose,
  lang,
  onConfirm,
  initialValues,
  addressDoesNotExist,
  submitting,
  isPlaceEditing,
}) {
  return (
    <Modal
      show={show}
      dialogClassName="modal-window--width-1 modal-window--theme-10"
      backdropClassName="modal-window__backdrop"
      onClose={onClose}
      title={isPlaceEditing ? lang.EDIT_PLACE_TITLE : lang.NEW_PLACE_TITLE}
    >
      <AddPlaceForm languageState={lang} initialValues={initialValues} addressDoesNotExist={addressDoesNotExist} />
      <div className="modal-window__footer">
        <button className="button button--bg-5 modal-window__footer-btn" onClick={onClose}>
          {lang.CANCEL_BUTTON}
        </button>
        <button
          type="submit"
          className="button button--bg-10 modal-window__footer-btn"
          onClick={onConfirm}
          disabled={submitting}
        >
          {isPlaceEditing ? lang.SAVE_PLACE_BUTTON : lang.CREATE_BUTTON}
        </button>
      </div>
    </Modal>
  );
}

AddPlaceModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  isPlaceEditing: PropTypes.bool,
  addressDoesNotExist: PropTypes.array,
};

AddPlaceModal.defaultProps = {
  submitting: false,
  initialValues: {},
  addressDoesNotExist: [false],
};

export default connect(({languageState}) => ({
  lang: languageState.payload.PLACES,
}))(AddPlaceModal);
