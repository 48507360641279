export default {
  GET_MEDIAS_DASHBOARD_REQUEST: '@@medias/GET_MEDIAS_DASHBOARD_REQUEST',
  GET_MEDIAS_DASHBOARD_SUCCESS: '@@medias/GET_MEDIAS_DASHBOARD_SUCCESS',
  GET_MEDIAS_DASHBOARD_ERROR: '@@medias/GET_MEDIAS_DASHBOARD_ERROR',

  GET_GLOBAL_MEDIAS_REQUEST: '@@medias/GET_GLOBAL_MEDIAS_REQUEST',
  GET_GLOBAL_MEDIAS_SUCCESS: '@@medias/GET_GLOBAL_MEDIAS_SUCCESS',
  GET_GLOBAL_MEDIAS_ERROR: '@@medias/GET_GLOBAL_MEDIAS_ERROR',

  GET_LOCAL_MEDIAS_REQUEST: '@@medias/GET_LOCAL_MEDIAS_REQUEST',
  GET_LOCAL_MEDIAS_SUCCESS: '@@medias/GET_LOCAL_MEDIAS_SUCCESS',
  GET_LOCAL_MEDIAS_ERROR: '@@medias/GET_LOCAL_MEDIAS_ERROR',

  UPDATE_MEDIA_REQUEST: '@@medias/UPDATE_MEDIA_REQUEST',
  UPDATE_MEDIA_SUCCESS: '@@medias/UPDATE_MEDIA_SUCCESS',
  UPDATE_MEDIA_ERROR: '@@medias/UPDATE_MEDIA_ERROR',

  DELETE_MEDIA_REQUEST: '@@medias/DELETE_MEDIA_REQUEST',
  DELETE_MEDIA_SUCCESS: '@@medias/DELETE_MEDIA_SUCCESS',
  DELETE_MEDIA_ERROR: '@@medias/DELETE_MEDIA_ERROR',

  UPLOAD_FILE_REQUEST: '@@medias/UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS: '@@medias/UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR: '@@medias/UPLOAD_FILE_ERROR',

  GET_MEDIA_REQUEST: '@@medias/GET_MEDIA_REQUEST',
  GET_MEDIA_SUCCESS: '@@medias/GET_MEDIA_SUCCESS',
  GET_MEDIA_ERROR: '@@medias/GET_MEDIA_ERROR',
};
