import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import {Translation} from 'client/models/language/types';

import cssModule from './toolbar-button.module.scss';

type ToolbarButtonProps = {
  onClick?: () => void;
  iconName: string;
  active?: boolean;
  label?: Translation;
  className?: string;
  iconProps?: {
    className?: string;
    style?: Record<string, string>;
    width?: number;
    height?: number;
  };
  disabled?: boolean;
  bordered?: boolean;
};

const b = bem('toolbar-button', {cssModule});

const ToolbarButton: React.FC<ToolbarButtonProps> = (props) => {
  const {onClick, iconName, label, active, className, iconProps, disabled, bordered = true} = props;

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault(); // prevent focus lose on text editor
  };

  return (
    <Popover
      overlay={label}
      position="top"
      className={cn(b('wrapper'), className)}
      contentClassName={b('overlay')}
      hideOnMouseLeave
      disableFocus={true}
    >
      <button
        className={b({active, disabled, bordered})}
        onClick={onClick}
        onMouseDown={handleMouseDown}
        disabled={disabled}
        data-active={active}
        type="button"
      >
        <Icon name={iconName} {...iconProps} />
      </button>
    </Popover>
  );
};

export default ToolbarButton;
