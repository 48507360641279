import {createSelector} from 'reselect';

export function selectDevicesMeta(state) {
  return state.devices.meta;
}

export function selectDevicesTotalCount(state) {
  return selectDevicesMeta(state).total_count;
}

export const selectDeviceFamilies = (state) => state.devices.device_families;

export const selectDeviceFamiliesOptions = createSelector(selectDeviceFamilies, (deviceFamilies) => {
  return deviceFamilies.map((item) => ({
    label: item.name,
    value: item.id,
    devices: item.devices
      .filter((i) => i.active)
      .map((device) => ({
        label: device.name,
        value: device.id,
      })),
  }));
});
