import React, {DOMAttributes, InputHTMLAttributes, useState} from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import {ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';

import {Translation} from 'client/models/language/types';
import {ObjectValues} from 'client/types/common';

import cssModule from './base-input.module.scss';

const b = bem('base-input', {cssModule});

const POSITION_OPTIONS = {
  TOP: 'top',
  LEFT: 'left',
} as const;

export type BaseInputProps = {
  label?: Translation;
  name?: string;
  type?: string;
  className?: string;
  classNames?: {
    label?: string;
    error?: string;
    warning?: string;
  };
  inputClassName?: string;
  value?: any;
  errorMessage?: Translation | Translation[] | null;
  warningMessage?: Translation | null;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  readOnly?: boolean;
  labelPosition?: ObjectValues<typeof POSITION_OPTIONS>;
  inputRef?: React.RefObject<HTMLInputElement>;
  onHoveredChange?: (state: boolean) => void;
  hoveringMode?: boolean;
  inputHeight?: 'small' | 'default';
  elements?: React.ReactNode | null;
  required?: boolean;
} & {
  type?: string;
} & Pick<DOMAttributes<HTMLInputElement>, 'onKeyUp' | 'onKeyPress'> &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'size' | 'value' | 'defaultValue'>;

const BaseInput: React.FC<BaseInputProps> = React.memo((props) => {
  const {
    hoveringMode,
    label,
    className,
    name,
    inputClassName,
    errorMessage,
    warningMessage = '',
    readOnly,
    labelPosition = POSITION_OPTIONS.TOP,
    inputRef,
    onHoveredChange,
    inputHeight = 'default',
    classNames,
    elements,
    required = false,
    ...inputProps
  } = props;
  const [isHovered, setIsHovered] = useState(false);

  const onHover = () => {
    if (hoveringMode) {
      setIsHovered((prev) => {
        onHoveredChange?.(!prev);
        return !prev;
      });
    }
  };

  return (
    <div
      onMouseEnter={onHover}
      onMouseLeave={onHover}
      className={cn(
        b({error: !!errorMessage, warning: !!warningMessage, column: labelPosition === POSITION_OPTIONS.TOP}),
        className,
      )}
    >
      {label && (
        <label
          htmlFor={name}
          className={cn(
            b('label', {[labelPosition]: true, 'read-only': readOnly, [inputHeight]: true}),
            classNames?.label,
          )}
        >
          {label}
        </label>
      )}
      {readOnly ? (
        <span className={cn(b('field', {'read-only': true}), inputClassName)}>{inputProps.value}</span>
      ) : (
        <div className={b('input-element')}>
          <div className={b('input-wrapper')}>
            <input
              {...inputProps}
              name={name}
              className={cn(
                b('field', {
                  hovered: !hoveringMode || isHovered,
                  inline: labelPosition === POSITION_OPTIONS.LEFT,
                  [inputHeight]: true,
                }),
                inputClassName,
              )}
              ref={inputRef}
              aria-invalid={!!errorMessage}
            />
            {elements}
          </div>
          {!errorMessage && !warningMessage && required && <RequiredLabel />}
          {errorMessage && <ErrorMessage className={classNames?.error} errorMessage={errorMessage} />}
          {warningMessage && !errorMessage && (
            <WarningMessage className={classNames?.warning} warningMessage={warningMessage} />
          )}
        </div>
      )}
    </div>
  );
});

BaseInput.displayName = 'BaseInput';

export default BaseInput;
