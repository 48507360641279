import React from 'react';

import cn from 'classnames';
import moment from 'moment';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons';
import Popover from 'client/common/popovers/popover';

import {DIY_CONFIRMATION_TYPES} from 'client/components/diy-operation/constants';
import {Translation} from 'client/models/language/types';

import cssModule from './autotask-management-card-header-controls.module.scss';

const b = bem('autotask-management-card-header-controls', {cssModule});

type AutotaskManagementCardHeaderProps = {
  title: Translation;
  publishedAt?: string | null;
  isUnPublish?: boolean;
  isValidated?: boolean;
  type: string;
  className?: string;
  openConfirmationModal: (modal: string, type: string) => void;
  disabled: boolean;
};

const AutotaskManagementCardHeaderControls: React.FC<AutotaskManagementCardHeaderProps> = (props) => {
  const {title, publishedAt, isValidated, isUnPublish, className, openConfirmationModal, type, disabled} = props;

  const lang = useLanguage('AUTOTASK.COMPONENTS.CONTROLS.AUTOTASK_MANAGEMENT_CARD_CONTROLS');

  const status = isUnPublish ? (
    <span className={b('status', ['published'])}>
      {`${lang.LAST_PUBLISH} ${moment(publishedAt).format('DD/MM/YYYY HH:mm')}`}
    </span>
  ) : (
    <span className={b('status', ['not-published'])}>{lang.NOT_PUBLISH}</span>
  );

  return (
    <div className={cn(b(), className)}>
      <p className={b('title')}>{title}</p>
      <div className={b('controls')}>
        <span className={b('status')}>{status}</span>
        {isUnPublish && (
          <Popover overlay={<span>{lang.UNPUBLISH_BUTTON}</span>}>
            <AppButton
              onClick={openConfirmationModal(DIY_CONFIRMATION_TYPES.UNPUBLISH, type)}
              iconName="unpublish"
              transparent={true}
              color="devices"
              outline={true}
            />
          </Popover>
        )}

        <AppButton
          disabled={!isValidated || disabled}
          onClick={
            !isUnPublish
              ? openConfirmationModal(DIY_CONFIRMATION_TYPES.PUBLISH, type)
              : openConfirmationModal(DIY_CONFIRMATION_TYPES.UPDATE, type)
          }
          label={!isUnPublish ? lang.PUBLISH : lang.UPDATE}
          color="text-additional"
          fontSize="extra-small"
          iconName="publish"
        />
      </div>
    </div>
  );
};

export default AutotaskManagementCardHeaderControls;
