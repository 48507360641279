import React, {memo, useCallback} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Popover from 'client/common/popovers/popover';

import {DisalableCheckboxField} from 'client/components/common/fields';
import Icon from 'client/components/common/icon';

import {MEDIA_LEVELS} from 'client/components/medias/constants';

import './medias-actions-cell.scss';

const b = bem('medias-actions-cell');

const deleteMedia = (index, formValues, change) => {
  const {medias, deletedMedias} = formValues;
  const newDeletedMedias = [...deletedMedias];
  const newMedias = [...medias];

  newDeletedMedias.push(medias[index]);
  newMedias.splice(index, 1);
  newMedias.forEach((media, idx) => {
    if (idx >= index) {
      media.default_order -= 1;
    }
  });

  change('deletedMedias', newDeletedMedias);
  change('medias', newMedias);
};

const MediasActionsCell = memo((props) => {
  const {index, item, level, formValues, change, isLocal} = props;
  const lang = useLanguage('MEDIAS');
  const compareLevel = isLocal ? MEDIA_LEVELS.DEVICE : level;
  const disabled = item.level !== compareLevel && (item.locked || !item.activated_by_owner);

  const handleDeleteMedia = useCallback(() => {
    deleteMedia(index, formValues, change);
  }, [index, formValues, change]);

  return (
    <div className={b()}>
      <Popover
        key={item.active}
        show={!disabled}
        position="top"
        overlay={<div className={b('hint')}>{item.active ? lang.ACTIVATED_MESSAGE : lang.NOT_ACTIVATED_MESSAGE}</div>}
      >
        <DisalableCheckboxField name={`medias[${index}].active`} disabled={disabled} withWrap={false} />
      </Popover>
      {item.level === compareLevel && <Icon name="bin" onClick={handleDeleteMedia} />}
    </div>
  );
});

MediasActionsCell.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  level: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
  isLocal: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
};

export default MediasActionsCell;
