import React, {Component} from 'react';

import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';
import {formatNumberSpace, formatFloat} from 'client/services/formatters';

import {selectLeadsStatistic} from 'client/ducks/leads-dashboard/selectors';
import {getLeadsOptInStatisticsById} from 'client/ducks/leads-opt-in-statistics/actions';
import {optInLeadsStatisticsSelector} from 'client/ducks/leads-opt-in-statistics/selectors';

import CustomCard from 'client/common/cards/custom-card';
import BarChart from 'client/common/charts/bar-chart';

import {TranslationJsx} from 'client/models/language/types';

import cssModule from './leads-info-block.module.scss';

const b = bem('leads-info-block', {cssModule});

const CardHeader = ({title, amount, textColor, showZero}) => {
  const noAmountSign = showZero ? '0' : '-';
  const value = amount || +amount[0] ? amount : noAmountSign;

  return (
    <div className={b('card-header')}>
      <div className={b('card-header-title')}>{title}</div>
      <div className={b('card-header-total')} style={{color: textColor}}>
        {value}
      </div>
    </div>
  );
};

CardHeader.propTypes = {
  title: TranslationJsx,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textColor: PropTypes.string,
  showZero: PropTypes.bool,
};

CardHeader.defaultProps = {
  title: '',
  color: '#00629C',
  amount: 0,
  showZero: true,
};

class MainInfoBlock extends Component {
  static propTypes = {
    clientId: PropTypes.number,
    language: PropTypes.object,
    getLeadsOptInStatistics: PropTypes.func.isRequired,
    common: PropTypes.object,
    statistics: PropTypes.object,
    leadsStatistic: PropTypes.object,
  };

  state = {
    loading: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.clientId !== prevProps.clientId) {
      this.fetchData();
    }
  }

  toggleLoading = () => this.setState(({loading}) => ({loading: !loading}));

  fetchData = () => {
    const {clientId, getLeadsOptInStatistics} = this.props;
    // const queryParams = {
    //   client_id: clientId
    // };
    // TODO: replace with leads data action
    this.toggleLoading();
    getLeadsOptInStatistics(clientId).then(this.toggleLoading);
  };

  renderProgressBars = (type) => {
    const {common} = this.props;
    let data;
    if (type === 'visits' || type === 'participations') {
      data = common[type].interfaces.filter((elem) => elem && elem.count > 0);
      let total = data.reduce((acc, item) => acc + item.count, 0);

      return data.map((elem, idx) => (
        <BarChart
          key={idx}
          orientation="horizontal"
          title={elem.name}
          number={elem.count}
          percent={Math.floor((elem.count / total) * 100)}
          color={type === 'visits' ? 'visits' : 'participants'}
          barColor="visits"
          classNames={{
            title: b('bar-chart-title'),
            total: b('bar-chart-total'),
            stripe: b('bar-chart-stripe'),
          }}
        />
      ));
    }
    const {opt_in_by_types, total_structural} = common[type];
    return opt_in_by_types.map((elem, idx) => (
      <BarChart
        key={idx}
        orientation="horizontal"
        title={elem.name}
        number={elem.count}
        percent={Math.floor((elem.count / total_structural) * 100)}
        color={!elem.optin_type ? 'primary' : 'text-additional'}
        barColor="visits"
        classNames={{
          title: b('bar-chart-title'),
        }}
      />
    ));
  };

  render() {
    const {
      language,
      statistics: {leads, opt_ins, opt_ins_rate},
      leadsStatistic,
    } = this.props;
    const {loading} = this.state;

    return (
      <div className={b()}>
        <div className={b('row')}>
          <div className={b('col-half')}>
            <CustomCard
              format="column"
              loading={loading}
              loadingColor="leads"
              header={
                <CardHeader
                  title={language.LEADS}
                  amount={formatNumberSpace(leads.total)}
                  textColor="rgba(133, 137, 241, 1)"
                />
              }
            />
            <CustomCard
              format="column"
              loading={loading}
              loadingColor="primary"
              header={
                <CardHeader
                  title={language.PARTICIPATION}
                  amount={formatFloat(Number(leadsStatistic.participations_conversion))}
                  textColor="rgba(0, 98, 156, 1)"
                />
              }
            >
              <div className={b('additional-text')}>{language.AVERAGE}</div>
            </CustomCard>
            <CustomCard
              format="column"
              loading={loading}
              loadingColor="primary"
              header={
                <CardHeader
                  title={language.OPTIN_RATE}
                  amount={!isNull(opt_ins_rate) ? opt_ins_rate + '%' : '-'}
                  textColor="rgba(0, 98, 156, 1)"
                />
              }
            />
          </div>
          <div className={b('col-half')}>
            <CustomCard
              format="row"
              loading={loading}
              loadingColor="primary"
              contentStyles={{
                maxWidth: '68%',
              }}
              header={
                <CardHeader
                  title={language.OPTIN}
                  amount={formatNumberSpace(opt_ins.total_structural)}
                  textColor="rgba(0, 98, 156, 1)"
                />
              }
            >
              <div className={b('bars-section')} style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                {opt_ins.opt_in_by_types
                  .filter(({optin_type}) => optin_type !== 1)
                  .map((elem, idx) => (
                    <BarChart
                      key={idx}
                      orientation="horizontal"
                      title={elem.name}
                      number={elem.count}
                      percent={Math.floor((elem.count / opt_ins.total_structural) * 100)}
                      color={!elem.optin_type ? 'text-normal' : 'light-clients'}
                      textColor={elem.optin_type ? 'text-additional' : null}
                      barColor="lead-optin-bar"
                      classNames={{
                        title: b('bar-chart-title'),
                        total: b('bar-chart-total'),
                        stripe: b('bar-chart-stripe'),
                      }}
                    />
                  ))}
              </div>
            </CustomCard>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({languageState, ...state}) => ({
    language: languageState.payload.LEADS_DASHBOARD,
    statistics: optInLeadsStatisticsSelector(state),
    leadsStatistic: selectLeadsStatistic(state),
  }),
  {getLeadsOptInStatistics: getLeadsOptInStatisticsById},
)(MainInfoBlock);
