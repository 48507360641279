import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import LayoutPanel from 'client/common/panels/layout-panel';

import PrizeDiyRatioTimeFieldset from 'client/components/prizes/fieldsets/prize-diy-ratio-time-fieldset';

import cssModule from './prize-diy-ratio-time-panel.module.scss';

const b = bem('prize-diy-ratio-time-panel', {cssModule});

type PrizeDiyRatioTimePanelProps = {
  disabled?: boolean;
};

const PrizeDiyRatioTimePanel: React.FC<PrizeDiyRatioTimePanelProps> = ({disabled}) => {
  const lang = useLanguage('PRIZES.PANELS.PRIZE_DIY_RATIO_TIME_PANEL');

  return (
    <LayoutPanel className={b()}>
      <p className={b('title')}>{lang.TITLE}</p>
      <PrizeDiyRatioTimeFieldset className={b('body')} disabled={disabled} />
    </LayoutPanel>
  );
};

export default PrizeDiyRatioTimePanel;
