export default {
  GET_PARTICIPATIONS_REQUEST: '@@participations/GET_PARTICIPATIONS_REQUEST',
  GET_PARTICIPATIONS_SUCCESS: '@@participations/GET_PARTICIPATIONS_SUCCESS',
  GET_PARTICIPATIONS_ERROR: '@@participations/GET_PARTICIPATIONS_ERROR',

  GET_PARTICIPATION_TABLE_DATA_REQUEST: '@@participations/GET_PARTICIPATION_TABLE_DATA_REQUEST',
  GET_PARTICIPATION_TABLE_DATA_SUCCESS: '@@participations/GET_PARTICIPATION_TABLE_DATA_SUCCESS',
  GET_PARTICIPATION_TABLE_DATA_ERROR: '@@participations/GET_PARTICIPATION_TABLE_DATA_ERROR',

  UPDATE_PARTICIPATIONS_REQUEST: '@@participations/UPDATE_PARTICIPATIONS_REQUEST',
  UPDATE_PARTICIPATIONS_SUCCESS: '@@participations/UPDATE_PARTICIPATIONS_SUCCESS',
  UPDATE_PARTICIPATIONS_ERROR: '@@participations/UPDATE_PARTICIPATIONS_ERROR',

  GET_OPERATIONS_REQUEST: '@@participations/GET_OPERATIONS_REQUEST',
  GET_OPERATIONS_SUCCESS: '@@participations/GET_OPERATIONS_SUCCESS',
  GET_OPERATIONS_ERROR: '@@participations/GET_OPERATIONS_ERROR',

  CLEAR_PARTICIPATIONS: '@@participations/CLEAR_PARTICIPATIONS',

  GET_LEVEL_OPTIONS_REQUEST: '@@participations/GET_LEVEL_OPTIONS_REQUEST',
  GET_LEVEL_OPTIONS_SUCCESS: '@@participations/GET_LEVEL_OPTIONS_SUCCESS',
  GET_LEVEL_OPTIONS_ERROR: '@@participations/GET_LEVEL_OPTIONS_ERROR',

  GET_PARTICIPATIONS_LEVEL_STATISTIC_REQUEST: '@@participations/GET_PARTICIPATIONS_LEVEL_STATISTIC_REQUEST',
  GET_PARTICIPATIONS_LEVEL_STATISTIC_SUCCESS: '@@participations/GET_PARTICIPATIONS_LEVEL_STATISTIC_SUCCESS',
  GET_PARTICIPATIONS_LEVEL_STATISTIC_ERROR: '@@participations/GET_PARTICIPATIONS_LEVEL_STATISTIC_ERROR',

  GET_PARTICIPATIONS_TIMELINE_STATISTIC_REQUEST: '@@participations/GET_PARTICIPATIONS_TIMELINE_STATISTIC_REQUEST',
  GET_PARTICIPATIONS_TIMELINE_STATISTIC_SUCCESS: '@@participations/GET_PARTICIPATIONS_TIMELINE_STATISTIC_SUCCESS',
  GET_PARTICIPATIONS_TIMELINE_STATISTIC_ERROR: '@@participations/GET_PARTICIPATIONS_TIMELINE_STATISTIC_ERROR',

  UPDATE_PARTICIPATION_FIELD: '@@participations/UPDATE_PARTICIPATION_FIELD',
};
