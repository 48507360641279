import React, {PureComponent} from 'react';

import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';

import bem from 'client/services/bem';

import {SelectField, TextField, TextareaField, RadioGroupField, DatetimePickerField} from 'client/common/fields';

import {TIME_UNITS, TIME_UNITS_VALUES} from './constants';
import {
  getInitialValues,
  getDateTimeScenarioVariables,
  getIntegerAndDecimalScenarioVariables,
  submitForm,
} from './helpers';
import validate from './validation';

import {preventDefault, onSubmitSuccess, onSubmitFail} from '../helpers';

// re-use main form styles
// TODO: make own styles
import cssModule from './../../ats-step/ats-step.module.scss';

const b = bem('ats-step', {cssModule});

class AtsTimerForm extends PureComponent {
  static propTypes = {
    datetimeVariables: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    integerDecimalVariables: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    languageState: PropTypes.object.isRequired,
    performInUnit: PropTypes.string,
  };

  LANGUAGE = this.props.languageState.payload.AUTOTASK_SCENARIO.SCENARIO_FORMS;

  performInOptions = () => {
    let timeUnitSelectRange;

    switch (this.props.performInUnit) {
      case TIME_UNITS_VALUES.HOURS:
        timeUnitSelectRange = 23;
        break;
      case TIME_UNITS_VALUES.DAYS:
        timeUnitSelectRange = 31;
        break;
      default:
        timeUnitSelectRange = 59;
        break;
    }

    return [...new Array(timeUnitSelectRange)].map((i, index) => ({
      label: index + 1,
      value: index + 1,
    }));
  };

  timeOptions = () => {
    return TIME_UNITS.map((option) => {
      const newLabel = this.LANGUAGE.TIMER[`${option.label.toUpperCase()}_OPTION`] || option.label;

      let res = option.default
        ? {
            value: option.value,
            label: newLabel,
            default: option.default,
          }
        : {
            value: option.value,
            label: newLabel,
          };

      return res;
    });
  };

  formatScenarioVariables = (scenarioVariables) =>
    scenarioVariables.map((variable) => ({
      value: variable.id,
      label: variable.full_name || variable.name,
    }));

  render() {
    const datetimeVariables = this.formatScenarioVariables(this.props.datetimeVariables);
    const integerDecimalVariables = this.formatScenarioVariables(this.props.integerDecimalVariables);

    return (
      <form onSubmit={preventDefault} className={b('form')}>
        <TextField label={this.LANGUAGE.STEP_NAME_LABEL} name="name" required={true} />
        <RadioGroupField
          color="default"
          name="form.kind"
          legend={this.LANGUAGE.TIMER.PERFORM_LABEL}
          classNameOption={b('radio-option')}
          options={[
            {
              value: 'in',
              label: this.LANGUAGE.IN_LABEL,
              children: (
                <>
                  <SelectField
                    className={b('form-field', ['size-2'])}
                    simpleValue={true}
                    name="form.performIn.value"
                    options={this.performInOptions()}
                  />
                  <SelectField
                    className={b('form-field', ['size-4'])}
                    simpleValue={true}
                    name="form.performIn.unit"
                    options={this.timeOptions()}
                    searchable={false}
                  />
                </>
              ),
            },
            {
              value: 'in_variable',
              label: this.LANGUAGE.IN_LABEL,
              children: (
                <>
                  <SelectField
                    simpleValue={true}
                    name="form.performInVariable.value"
                    searchable={false}
                    options={integerDecimalVariables}
                  />
                  <SelectField
                    className={b('form-field', ['size-4'])}
                    simpleValue={true}
                    name="form.performInVariable.unit"
                    searchable={false}
                    options={this.timeOptions()}
                  />
                </>
              ),
            },
            {
              value: 'on',
              label: this.LANGUAGE.ON_LABEL,
              children: <DatetimePickerField name="form.performOn.value" />,
            },
            {
              value: 'on_variable',
              label: this.LANGUAGE.ON_LABEL,
              children: (
                <SelectField
                  simpleValue={true}
                  name="form.performOnVariable.value"
                  searchable={false}
                  options={datetimeVariables}
                />
              ),
            },
          ]}
        />
        <TextareaField label={this.LANGUAGE.COMMENT_LABEL} name="comment" />
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {formId, data} = props;

  const {
    scenario: {
      payload: {scenario_variables: scenarioVariables, scenario_step_variables: scenarioStepVariables = []},
    },
  } = state;

  const vars = scenarioVariables.concat(scenarioStepVariables);
  const selectorFormValue = formValueSelector(formId);

  return {
    form: formId,
    performInUnit: selectorFormValue(state, 'form.performIn.unit'),
    initialValues: getInitialValues(data, state),
    datetimeVariables: getDateTimeScenarioVariables(vars),
    integerDecimalVariables: getIntegerAndDecimalScenarioVariables(vars),
    languageState: state.languageState,
  };
};

export default flow([
  reduxForm({
    touchOnBlur: false,
    validate,
    onSubmit: submitForm,
    onSubmitSuccess,
    onSubmitFail,
  }),
  connect(mapStateToProps),
])(AtsTimerForm);
