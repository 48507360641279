import moment from 'moment';

import {Langs} from 'client/services/hooks/useLanguage/types';

import {LotteryPrizeCustomizationValues} from 'client/components/lottery/modals/lottery-prize-customization-modal/types';
import {Translation} from 'client/models/language/types';

export default (
  formValues: LotteryPrizeCustomizationValues,
  args: {lang: Langs<'LOTTERY.MODALS.LOTTERY_PRIZE_CUSTOMIZATION_MODAL.ERRORS'>},
) => {
  const {lang} = args;

  return {
    game_draws: formValues.game_draws?.map((draw) => {
      const errors: Partial<Record<keyof typeof draw, Translation>> = {};

      if (draw.default_from && draw.default_to && moment(draw.default_to).isSameOrBefore(moment(draw.default_from))) {
        errors.default_from = lang.FROM_TO;
      }
      if (draw.default_to && moment(draw.default_to).isSameOrAfter(moment(draw.default_draw_time))) {
        errors.default_to = lang.DRAW_MOMENT;
      }
      return errors;
    }),
  };
};
