import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Grid from 'client/components/common/grid';

import VarItem from './components/var-item';

import './bc-result-vars-modal.scss';

function BCResultClicksModal(props) {
  const {languageState, onClose, show, variables} = props;
  const LANGUAGE = languageState.payload.BROADCASTING_TASK.RESULT_VARS_MODAL;

  return (
    <Modal show={show} onClose={onClose} title={LANGUAGE.TITLE} dialogClassName="modal-window--width-2 theme-color-16">
      <CustomScrollbars
        scrollbarProps={{
          autoHeightMin: 50,
          autoHeightMax: 400,
        }}
      >
        <Grid paddings={true}>
          <Grid.Row className="bold">
            <Grid.Col size="6">{LANGUAGE.VAR_NAME_LABEL}</Grid.Col>
            <Grid.Col size="6">{LANGUAGE.VAL_LABEL}</Grid.Col>
          </Grid.Row>
          {variables.map((variable, key) => (
            <VarItem {...variable} key={key} />
          ))}
        </Grid>
      </CustomScrollbars>
      <div className="modal-window__footer modal-window__footer--centered">
        <button className="button button--bg-14 modal-window__footer-btn" type="button" onClick={onClose}>
          {LANGUAGE.CONFIRM_BUTTON}
        </button>
      </div>
    </Modal>
  );
}

BCResultClicksModal.defaultProps = {
  show: false,
};

BCResultClicksModal.propTypes = {
  show: PropTypes.bool,
  languageState: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

export default connect(mapStateToProps)(BCResultClicksModal);
