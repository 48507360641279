import React, {useEffect} from 'react';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import {useLanguage, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';

import {TextField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

const InterfaceIdModalFormName = 'IdTokenFormModal';

export type InterfaceIdModalForm = {
  interface_id: number;
};

type InterfaceIdModalProps = {
  show: boolean;
  onConfirm: () => void;
  onClose: () => void;
  interfaceId?: number;
  interfaceName?: string;
};

const InterfaceIdModal: React.FC<InterfaceIdModalProps> = (props) => {
  const {show, onClose, onConfirm, interfaceId, interfaceName} = props;
  const lang = useLanguage('AUTOTASK.INTERFACE_ID_MODAL');

  const {change} = useReduxForm<InterfaceIdModalForm>(InterfaceIdModalFormName);

  useEffect(() => {
    change('interface_id', interfaceId);
  }, [change, interfaceId]);

  return (
    <Modal show={show} className="modal-window--width-1 theme-color-8" onClose={onClose} title={lang.TITLE}>
      <form>
        <p className="main-text">{interfaceName}</p>
        <TextField label={lang.ID_LABEL} name="interface_id" type="text" disabled />
        <div className="modal-window__footer modal-window__footer--centered">
          {interfaceId && (
            <CopyToClipboard text={interfaceId.toString()}>
              <button type="button" className="button button--bg-7 modal-window__footer-btn">
                {lang.COPY_BUTTON}
              </button>
            </CopyToClipboard>
          )}
          <button className="button button--bg-7 modal-window__footer-btn" type="button" onClick={onConfirm}>
            {lang.FINISH_BUTTON}
          </button>
        </div>
      </form>
    </Modal>
  );
};

const form = reduxForm<InterfaceIdModalProps, InterfaceIdModalForm>({
  form: InterfaceIdModalFormName,
})(InterfaceIdModal);

export default form;
