// components
import React, {Component} from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import FormatCell from './components/format-cell/index';
import LabelCell from './components/label-cell/index';

import './custom-database-labels-card-data-grid.scss';

class CustomDatabaseLabelsCardDataGrid extends Component {
  static propTypes = {
    data: PropTypes.array,
    languageState: PropTypes.object.isRequired,
    onEditClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = {
      ...props.languageState.payload.OPERATION_PAGE,
      CUSTOM_FORMATS: props.languageState.payload.CUSTOM_FORMATS,
    };
  }

  render() {
    return (
      <div
        style={{paddingTop: '6px'}}
        className="fixed-table fixed-table--expand-1 fixed-table--custom-database-labels-card theme-color-opdb-labels"
      >
        <div
          ref={(container) => {
            this.container = container;
          }}
        />
        <Table
          rowsCount={this.props.data.length}
          headerHeight={40}
          rowHeight={34}
          width={230}
          height={250}
          {...this.props}
        >
          <Column
            header={<Cell>{this.LANGUAGE.CUSTOM_DATABASE_LABELS.LABEL_NAME_COLUMN}</Cell>}
            cell={
              <LabelCell
                popoverText={this.LANGUAGE.CUSTOM_DATABASE_LABELS.POPOVER_TEXT}
                data={this.props.data}
                onEditClick={this.props.onEditClick}
                container={this.container}
              />
            }
            width={100}
          />
          <Column
            header={<Cell>{this.LANGUAGE.CUSTOM_DATABASE_LABELS.FORMAT_COLUMN}</Cell>}
            cell={<FormatCell data={this.props.data} field="kind" formatTextLabels={this.LANGUAGE.CUSTOM_FORMATS} />}
            width={100}
          />
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

export default connect(mapStateToProps)(CustomDatabaseLabelsCardDataGrid);
