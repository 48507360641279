import React, {PureComponent} from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import InterfaceCell from './components/interface-cell';
import LinkCell from './components/link-cell';

import '../../cards/ats-linked-card/ats-linked-card.scss';

class AtsLinkedInterfacesTable extends PureComponent {
  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK_SCENARIO.LINKED_INTERFACES_CARD;
  }

  render() {
    const {data, link, path} = this.props;
    return (
      <div className="fixed-table fixed-table--scenarios-errors-card fixed-table--expand-1">
        <Table rowsCount={data.length} headerHeight={40} rowHeight={40} width={230} height={314} {...this.props}>
          <Column
            header={<Cell>{this.LANGUAGE.INTERFACE}</Cell>}
            cell={<LinkCell data={data} field="name" link={link} path={path} />}
            width={110}
          />
          <Column header={<Cell>{this.LANGUAGE.LEVEL}</Cell>} cell={<InterfaceCell data={data} />} width={230} />
        </Table>
      </div>
    );
  }
}

AtsLinkedInterfacesTable.defaultProps = {
  data: [{name: '', link: ''}],
  title: '',
  header: '',
  link: '',
  path: '',
};

AtsLinkedInterfacesTable.propTypes = {
  languageState: PropTypes.object.isRequired,
  data: PropTypes.array,
  link: PropTypes.string,
  path: PropTypes.string,
};

export default connect(({languageState}) => ({
  languageState,
}))(AtsLinkedInterfacesTable);
