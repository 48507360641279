import {useCallback, useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import {getAnimations} from 'client/ducks/animations/actions';
import {selectAnimations} from 'client/ducks/animations/selectors';

import {getPeriod} from 'client/components/instore/helpers';
import {DEFAULT_INCLUDES} from 'client/components/instore/tables/instore-animation-table/constants';
import {getSortForRequest} from 'client/components/instore/tables/instore-animation-table/helpers';

export const useFetchAnimations = (params) => {
  const {filters: rawFilters, sortField, sortOrder, search, page, perPage, isCampaignRequired = false} = params;
  const dispatch = useDispatch();

  const [loading, toggleLoading] = useToggle(false);

  const animations = useSelector(selectAnimations);

  const stringFilters = JSON.stringify(rawFilters || {});

  const fetchAnimations = useCallback(async () => {
    const filters = JSON.parse(stringFilters);

    const [sortParamName, sortValue] = getSortForRequest(sortField, sortOrder);

    const requestParams = {
      first_animation_day_between: filters?.from && filters?.to && getPeriod(filters?.from, filters?.to),
      include_instore_animation_questions_statistic: null,
      q: {
        instore_task_id_eq: filters?.taskId,
        interaction_group_id_eq: filters?.interaction_group_id,

        [sortParamName]: sortValue,
        status_in: filters?.statuses,
        place_network_id_eq: filters?.network,
        instore_task_access_membership_regions_id_eq: filters?.region,
        kit_mapping_kit_id_eq: filters?.kit,
        instore_task_access_user_id_eq: filters?.user,
        g: {
          0: {
            place_name_cont: search,
            place_city_name_cont: search,
            m: 'or',
          },
        },
      },
      ...DEFAULT_INCLUDES,
      include: {
        ...DEFAULT_INCLUDES.include,
        animation_kpis: null,
        animation_days: {
          anomaly_occurrences: null,
          animation_kpis: {
            task_kpi: null,
          },
        },
      },
      page,
      per_page: perPage,
    };

    const canMakeRequest = !isCampaignRequired || Boolean(filters?.interaction_group_id);

    toggleLoading();
    const result = canMakeRequest ? await dispatch(getAnimations(requestParams)) : await Promise.resolve();
    toggleLoading();

    return result;
  }, [stringFilters, sortField, sortOrder, search, page, perPage, toggleLoading, dispatch, isCampaignRequired]);

  useEffect(() => {
    fetchAnimations();
  }, [fetchAnimations]);

  return {loading, animationsData: animations?.data, animationsMeta: animations?.meta, fetchAnimations};
};
