import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {roundValue} from 'client/components/client-autotask/tabs/results-tab/diagrams/helpers';

import {GraphCanvasProps} from './type';

import cssModule from './graph-canvas.module.scss';

const b = bem('graph-canvas', {cssModule});

const GraphCanvas: React.FC<GraphCanvasProps> = (props) => {
  const {
    yAxisConfig: {...config},
    className,
    children,
  } = props;
  const steps = [...Array(Math.floor(config.stepsCount))];

  const renderRowLine = (index: number, {max, step}: {max: number; step: number}) => {
    return (
      <div key={index} className={b('rows-line-wrapper')}>
        <span className={b('rows-label')}>{roundValue(max - step * index, 2)}</span>
        <div className={b('rows-line')} />
      </div>
    );
  };

  return (
    <div className={cn(className, b())}>
      <div className={b('rows')}>
        <div className={b('rows-lines')}>{steps.map((_, i) => renderRowLine(i, config))}</div>
      </div>
      <CustomScrollbars
        cssModifier={b('bars-container')}
        scrollbarProps={{
          autoHeightMax: 250,
        }}
      >
        {children}
      </CustomScrollbars>
    </div>
  );
};

export default GraphCanvas;
