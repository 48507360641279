export default {
  GET_MANUAL_GROUPS_REQUEST: '@@groups/GET_MANUAL_GROUPS_REQUEST',
  GET_MANUAL_GROUPS_SUCCESS: '@@groups/GET_MANUAL_GROUPS_SUCCESS',
  GET_MANUAL_GROUPS_ERROR: '@@groups/GET_MANUAL_GROUPS_ERROR',

  GET_VISUAL_GROUP_REQUEST: '@@groups/GET_VISUAL_GROUP_REQUEST',
  GET_VISUAL_GROUP_SUCCESS: '@@groups/GET_VISUAL_GROUP_SUCCESS',
  GET_VISUAL_GROUP_ERROR: '@@groups/GET_VISUAL_GROUP_ERROR',

  ADD_MANUAL_GROUP_REQUEST: '@@groups/ADD_MANUAL_GROUP_REQUEST',
  ADD_MANUAL_GROUP_SUCCESS: '@@groups/ADD_MANUAL_GROUP_SUCCESS',
  ADD_MANUAL_GROUP_ERROR: '@@groups/ADD_MANUAL_GROUP_ERROR',
};
