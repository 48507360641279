export default {
  GET_BROADCAST_REQUEST: '@@broadcast/GET_BROADCAST_REQUEST',
  GET_BROADCAST_SUCCESS: '@@broadcast/GET_BROADCAST_SUCCESS',
  GET_BROADCAST_ERROR: '@@broadcast/GET_BROADCAST_ERROR',

  GET_BROADCASTS_REQUEST: '@@broadcast/GET_BROADCASTS_REQUEST',
  GET_BROADCASTS_SUCCESS: '@@broadcast/GET_BROADCASTS_SUCCESS',
  GET_BROADCASTS_ERROR: '@@broadcast/GET_BROADCASTS_ERROR',

  GET_BROADCAST_RECIPIENTS_REQUEST: '@@broadcast/GET_BROADCAST_RECIPIENTS_REQUEST',
  GET_BROADCAST_RECIPIENTS_SUCCESS: '@@broadcast/GET_BROADCAST_RECIPIENTS_SUCCESS',
  GET_BROADCAST_RECIPIENTS_ERROR: '@@broadcast/GET_BROADCAST_RECIPIENTS_ERROR',

  UPDATE_BROADCAST_LIST_REQUEST: '@@broadcast/UPDATE_BROADCAST_LIST_REQUEST',
  UPDATE_BROADCAST_LIST_SUCCESS: '@@broadcast/UPDATE_BROADCAST_LIST_SUCCESS',
  UPDATE_BROADCAST_LIST_ERROR: '@@broadcast/UPDATE_BROADCAST_LIST_ERROR',

  CREATE_BROADCAST_REQUEST: '@@broadcast/CREATE_BROADCAST_REQUEST',
  CREATE_BROADCAST_SUCCESS: '@@broadcast/CREATE_BROADCAST_SUCCESS',
  CREATE_BROADCAST_ERROR: '@@broadcast/CREATE_BROADCAST_ERROR',

  CLEAR_BROADCAST_RECIPIENTS_SUCCESS: '@@broadcast/CLEAR_BROADCAST_RECIPIENTS_SUCCESS',

  GET_BROADCAST_DISPLAY_ITEMS_REQUEST: '@@broadcast/GET_BROADCAST_DISPLAY_ITEMS_REQUEST',
  GET_BROADCAST_DISPLAY_ITEMS_SUCCESS: '@@broadcast/GET_BROADCAST_DISPLAY_ITEMS_SUCCESS',
  GET_BROADCAST_DISPLAY_ITEMS_ERROR: '@@broadcast/GET_BROADCAST_DISPLAY_ITEMS_ERROR',

  CREATE_BROADCAST_DISPLAY_ITEM_REQUEST: '@@broadcast/CREATE_BROADCAST_DISPLAY_ITEM_REQUEST',
  CREATE_BROADCAST_DISPLAY_ITEM_SUCCESS: '@@broadcast/CREATE_BROADCAST_DISPLAY_ITEM_SUCCESS',
  CREATE_BROADCAST_DISPLAY_ITEM_ERROR: '@@broadcast/CREATE_BROADCAST_DISPLAY_ITEM_ERROR',

  DELETE_BROADCAST_DISPLAY_ITEM_REQUEST: '@@broadcast/DELETE_BROADCAST_DISPLAY_ITEM_REQUEST',
  DELETE_BROADCAST_DISPLAY_ITEM_SUCCESS: '@@broadcast/DELETE_BROADCAST_DISPLAY_ITEM_SUCCESS',
  DELETE_BROADCAST_DISPLAY_ITEM_ERROR: '@@broadcast/DELETE_BROADCAST_DISPLAY_ITEM_ERROR',

  DELETE_BC_DISPLAY_ITEMS_REQUEST: '@@broadcast/DELETE_BC_DISPLAY_ITEMS_REQUEST',
  DELETE_BC_DISPLAY_ITEMS_SUCCESS: '@@broadcast/DELETE_BC_DISPLAY_ITEMS_SUCCESS',
  DELETE_BC_DISPLAY_ITEMS_ERROR: '@@broadcast/DELETE_BC_DISPLAY_ITEMS_ERROR',

  CHANGE_CURRENT_BROADCAST: '@@broadcast/CHANGE_CURRENT_BROADCAST',

  FLUSH_BROADCAST_ALL_STATE: '@@broadcast/FLUSH_BROADCAST_ALL_STATE',
};
