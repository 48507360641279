import React, {useMemo, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {fetchLanguages} from 'client/ducks/language/actions';
import {patchLanguage} from 'client/ducks/translations/actions';
import {selectLanguages} from 'client/ducks/translations/selectors';

import ConfirmationModal from 'client/common/modals/confirmation-modal';

import ClientTable from 'client/components/common/client-table';
import ToggleButton from 'client/components/common/custom-buttons/toggle-button';

import {alwaysOnLanguages} from 'client/components/translations/constants';

import {sortLanguages} from './helpers';

import cssModule from './translations-languages.module.scss';

const b = bem('translations-languages', {cssModule});

export const TranslationsLanguages = () => {
  const dispatch = useDispatch();
  const lang = useLanguage('TRANSLATIONS.LANGUAGES');
  const languages = useSelector(selectLanguages);
  const [activeLanguage, setActiveLanguage] = useState(null);

  const statusConfirmationMessage = useMemo(() => {
    if (!activeLanguage) {
      return '';
    }
    return [
      lang.STATUS_CONFIRMATION_MESSAGE_BEGIN,
      activeLanguage.active ? lang.STATUS_CONFIRMATION_DEACTIVATE : lang.STATUS_CONFIRMATION_ACTIVATE,
      activeLanguage.full_name,
      lang.STATUS_CONFIRMATION_MESSAGE_END,
    ].join(' ');
  }, [activeLanguage, lang]);

  const sortedLanguages = useMemo(() => sortLanguages(languages), [languages]);

  const columns = useMemo(
    () => [
      {
        name: 'full_name',
        label: lang.COLUMN_LANGUAGE,
        path: 'full_name',
        headerClassName: b('name-column'),
      },
      {
        name: 'name',
        label: lang.COLUMN_CODE,
        path: 'name',
        headerClassName: b('name-column'),
      },
      {
        name: 'active',
        label: lang.COLUMN_STATUS,
        path: 'active',
        render: ({value, item}) => {
          const disabled = alwaysOnLanguages.includes(item.name);
          return (
            <ToggleButton
              className={b('toggle-btn', {disabled})}
              value={value}
              onToggle={() => setActiveLanguage(item)}
              disabled={disabled}
            />
          );
        },
        headerClassName: b('active-column'),
      },
      {},
    ],
    [lang],
  );

  const handleLanguageToggle = async () => {
    await dispatch(
      patchLanguage({
        ...activeLanguage,
        active: !activeLanguage.active,
      }),
    );
    setActiveLanguage(null);
    dispatch(fetchLanguages());
  };

  return (
    <div className={b()}>
      <ClientTable data={sortedLanguages} columns={columns} noDataText={lang.NO_DATA_TEXT} />
      <ConfirmationModal
        show={!!activeLanguage}
        message={statusConfirmationMessage}
        buttonConfirm={{label: lang.STATUS_CONFIRMATION_OK}}
        buttonCancel={{label: lang.STATUS_CONFIRMATION_CANCEL}}
        onConfirm={handleLanguageToggle}
        onClose={() => setActiveLanguage(null)}
        onCancel={() => setActiveLanguage(null)}
      />
    </div>
  );
};
