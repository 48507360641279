import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {getClientPage} from 'client/services/helpers';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {
  getColumnsAction,
  getOperationDataAction,
  getVisibleColumnsAction,
} from 'client/ducks/custom-operation-database/actions';
import {getOperation} from 'client/ducks/operations/actions';

import {CLIENT_PAGES} from 'client/common/config';

import TitleBlock from 'client/components/common/title-block';

import CustomOperationDataGrid from './components/tables/custom-operation-data-grid';

class CustomOperationDatabase extends ReactQueryParams {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    customOpDB: PropTypes.shape({
      operationData: PropTypes.array,
      columns: PropTypes.array,
      visibleColumns: PropTypes.array,
      meta: PropTypes.object,
    }).isRequired,
    operation: PropTypes.object,
    operationId: PropTypes.number.isRequired,
    clientId: PropTypes.number.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    getOperation: PropTypes.func.isRequired,
    getColumns: PropTypes.func.isRequired,
    getVisibleColumns: PropTypes.func.isRequired,
    getOperationData: PropTypes.func.isRequired,
  };

  static DEFAULT_PAGE = 1;

  static DEFAULT_PER_PAGE = 5;

  static DEFAULT_SORT = 'created_at';

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.CUSTOM_OPERATION_DATABASE;

    this.state = {
      showClear: false,
    };
  }

  componentDidMount() {
    this.props.getOperation(this.props.operationId, {include: 'client.agency'}).then(() => {
      const {operation} = this.props;

      const {
        languageState: {
          payload: {BREADCRUMBS},
        },
      } = this.props;

      const {client} = operation;

      const clientHref = `${getClientPage(client)}/${client.id}`;
      const agencyBreadcrumb = client.agency
        ? [
            {
              href: `${getClientPage(client.agency)}/${client.agency.id}`,
              name: client.agency.name,
            },
          ]
        : [];

      const operationHref = `${clientHref}${CLIENT_PAGES.OPERATIONS}/${operation.id}`;

      this.props.setBreadcrumbs([
        {
          href: CLIENT_PAGES.VISUALS,
          name: BREADCRUMBS.VISUALS,
        },
        {
          name: BREADCRUMBS.CLIENTS,
          href: CLIENT_PAGES.CLIENTS_LIST,
        },
        ...agencyBreadcrumb,
        {
          name: client.name,
          href: clientHref,
        },
        {
          name: operation.name,
          href: operationHref,
        },
        {
          name: BREADCRUMBS.CUSTOM_OPERATION,
          className: 'last-breadcrumb',
        },
      ]);
    });
    this.updateMe();
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  static getSortParam(sort) {
    return isNaN(+sort) ? sort : `data->>${sort}`;
  }

  updateMe = (filterColumns) => {
    const {queryParams: params} = this;
    const {operationId} = this.props;

    const filters = Array.isArray(filterColumns) ? filterColumns : params.filterColumns;
    const operationDataParams = {
      operationId,
      page: params.page || CustomOperationDatabase.DEFAULT_PAGE,
      perPage: +params.perPage || CustomOperationDatabase.DEFAULT_PER_PAGE,
      sort: {
        name:
          (params.sort && CustomOperationDatabase.getSortParam(params.sort.name)) ||
          CustomOperationDatabase.DEFAULT_SORT,
        order: (params.sort && params.sort.order) || '',
      },
      search: params.search || '',
      typeIn: filters,
    };

    if (params.search) {
      this.setState({showClear: true});
    }
    if (!params.filterColumns) {
      this.setQueryParams({filterColumns: []});
    }
    if (!params.perPage) {
      this.setQueryParams({perPage: CustomOperationDatabase.DEFAULT_PER_PAGE});
    }

    window.scrollTo(0, 0);
    return this.props
      .getColumns(operationId)
      .then(() => this.props.getVisibleColumns(operationId))
      .then(() =>
        this.props.getOperationData(
          // TODO: check here for correct request
          operationDataParams,
          this.props.customOpDB.visibleColumns,
          this.props.customOpDB.visibleColumns
            .map((i) => i.column_adapter)
            .filter((i) => filters && filters.includes(i.kind)),
        ),
      );
  };

  onSearchChange = (search) => {
    this.setQueryParams({search, page: CustomOperationDatabase.DEFAULT_PAGE}, true);
    this.setState({search});
    this.updateMe();
  };

  onPageChange = (params) => {
    this.setQueryParams(params, true);
    this.updateMe();
  };

  onSortChange = (name, order) => {
    this.setQueryParams(
      {
        sort: {name, order},
      },
      true,
    );
    this.updateMe();
  };

  render() {
    const {operationData, columns, visibleColumns, meta} = this.props.customOpDB;
    const {filterColumns} = this.queryParams;

    return (
      <div className="theme-color-9">
        <div className="page__title-block">
          <TitleBlock theme>
            <TitleBlock.Item>{this.LANGUAGE.TITLE}</TitleBlock.Item>
          </TitleBlock>
        </div>
        <CustomOperationDataGrid
          data={operationData}
          columns={columns}
          visibleColumns={visibleColumns}
          filterColumns={filterColumns}
          meta={meta}
          sort={{
            sortField: this.queryParams.sort?.name,
            sortOrder: this.queryParams.sort?.order,
          }}
          operationId={this.props.operationId}
          updateMe={this.updateMe}
          perPage={Number(this.queryParams.perPage) || CustomOperationDatabase.DEFAULT_PER_PAGE}
          search={this.queryParams.search}
          onSearchChange={this.onSearchChange}
          onPageChange={this.onPageChange}
          onSortChange={this.onSortChange}
        />
      </div>
    );
  }
}

export default connect(
  ({languageState, operations, customOpDB}) => ({
    languageState,
    operation: operations.operation,
    customOpDB,
  }),
  {
    getOperation,
    getOperationData: getOperationDataAction,
    getColumns: getColumnsAction,
    getVisibleColumns: getVisibleColumnsAction,
    setBreadcrumbs,
    clearBreadcrumbs,
  },
)(CustomOperationDatabase);
