import get from 'lodash/get';
import set from 'lodash/set';
import moment from 'moment';

import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {isEmptyString} from 'client/services/validator';

import {GAME_TYPES} from 'client/components/games/game-config-modal/constants';
import {AddGameFormType} from 'client/components/games/games-card/add-game-modal/index';
import {Translation} from 'client/models/language/types';

export default (values: AddGameFormType, params: {lang: LangsByPath<['GAMES.GAMES_CARD.ADD_GAME_MODAL']>}) => {
  const {lang} = params;

  const errors: Record<string, Translation> = {};

  ['name', 'game_type'].forEach((key) => {
    if (isEmptyString(values[key as keyof typeof values])) {
      set(errors, key, lang.ERRORS.REQUIRED);
    }
  });

  if (values.game_type === GAME_TYPES.PRIZE_DRAW) {
    ['game_draws[0].default_from', 'game_draws[0].default_to', 'game_draws[0].default_draw_time'].forEach((key) => {
      if (isEmptyString(get(values, key))) {
        set(errors, key, lang.ERRORS.REQUIRED);
      }
    });

    if (
      values.game_draws?.[0]?.default_to &&
      values.game_draws?.[0]?.default_from &&
      moment(values.game_draws?.[0]?.default_to).isSameOrBefore(moment(values.game_draws?.[0]?.default_from))
    ) {
      set(errors, 'game_draws[0].default_from', lang.ERRORS.FROM_TO);
    }

    if (
      values.game_draws?.[0]?.default_to &&
      values.game_draws?.[0]?.default_draw_time &&
      moment(values.game_draws?.[0]?.default_draw_time).diff(moment(values.game_draws?.[0]?.default_to), 'minutes') < 2
    ) {
      set(errors, 'game_draws[0].default_draw_time', lang.ERRORS.DRAW_MOMENT);
    }
  }

  return errors;
};
