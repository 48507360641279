import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getAnonymousLead, updateAnonymousLead} from 'client/ducks/opt-out/actions';
import {selectLead} from 'client/ducks/opt-out/selectors';

import './unsubscribe.scss';

class Unsubscribe extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    leadToken: PropTypes.string.isRequired,
    lead: PropTypes.object,
    updateAnonymousLead: PropTypes.func.isRequired,
    getAnonymousLead: PropTypes.func.isRequired,
  };

  static defaultProps = {
    lead: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      done: false,
    };

    this.LANGUAGE = props.languageState.payload.UNSUBSCRIBE;
  }

  componentDidMount() {
    this.props.getAnonymousLead(this.props.leadToken).then(this.unsubscribe);
  }

  // eslint-disable-next-line consistent-return
  unsubscribe = () => {
    const {lead, leadToken} = this.props;

    // do not unsubscribe if opt_ins is empty
    if ('opt_ins' in lead) {
      const optInIds = Object.keys(lead.opt_ins);

      const leadData = {
        lead: {
          opt_ins: optInIds.reduce(
            (result, id) => ({
              ...result,
              [id]: false,
            }),
            {},
          ),
        },
      };

      return this.props.updateAnonymousLead(leadToken, leadData).then(this.setDone);
    }
  };

  setDone = () => {
    this.setState({done: true});
  };

  render() {
    return (
      <div className="unsubscribe">
        <h2 className="unsubscribe__message title">{this.state.done && this.LANGUAGE.MESSAGE}</h2>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  lead: selectLead(state),
});

const mapDispatchToProps = {
  getAnonymousLead,
  updateAnonymousLead,
};

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe);
