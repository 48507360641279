import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {getClientPage} from 'client/services/helpers';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getTokens} from 'client/ducks/coupon-tokens/actions';
import {getOperation} from 'client/ducks/operations/actions';

import {CLIENT_PAGES} from 'client/common/config';

import TitleBlock from 'client/components/common/title-block';

import TokensGrid from 'client/components/tokens/components/tables/tokens-grid';
import TokensFiltersModalForm from 'client/components/tokens/modals/tokens-filter-modal';

class Tokens extends ReactQueryParams {
  static propTypes = {
    language: PropTypes.object.isRequired,
    languageBreadcrumbs: PropTypes.object.isRequired,
    operation: PropTypes.object,
    operationId: PropTypes.number.isRequired,
    clientId: PropTypes.number.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    getOperation: PropTypes.func.isRequired,
    getTokens: PropTypes.func.isRequired,
  };

  static DEFAULT_PAGE = 1;

  static DEFAULT_PER_PAGE = 25;

  static DEFAULT_SORT = 'created_at';

  constructor(props) {
    super(props);

    const {queryParams: params} = this;
    this.state = {
      showClear: false,
      showFiltersModal: false,
      title: props.language.TITLE,
      filters: params.filters || {},
    };
  }

  componentDidMount() {
    this.setQueryParams({sort: {name: 'created_at', order: 'desc'}});
    this.initCurrentOperation();
    this.updateMe();
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  initCurrentOperation = async () => {
    await this.props.getOperation(this.props.operationId, {include: [{client: 'agency'}]});
    const {operation} = this.props;

    this.setState({
      title: `${this.state.title} - ${operation.name}`,
    });

    const {client} = operation;

    const clientHref = `${getClientPage(client)}/${client.id}`;
    const agencyBreadcrumb = client.agency
      ? [
          {
            href: `${getClientPage(client.agency)}/${client.agency.id}`,
            name: client.agency.name,
          },
        ]
      : [];

    const operationHref = `${clientHref}${CLIENT_PAGES.OPERATIONS}/${operation.id}`;

    this.props.setBreadcrumbs([
      {
        href: CLIENT_PAGES.VISUALS,
        name: this.props.languageBreadcrumbs.VISUALS,
      },
      {
        name: this.props.languageBreadcrumbs.CLIENTS,
        href: CLIENT_PAGES.CLIENTS_LIST,
      },
      ...agencyBreadcrumb,
      {
        name: client.name,
        href: clientHref,
      },
      {
        name: operation.name,
        href: operationHref,
      },
      {
        name: this.props.languageBreadcrumbs.TOKENS,
        className: 'last-breadcrumb',
      },
    ]);
  };

  updateMe = async (nextFilters) => {
    const {queryParams: params} = this;
    const filters = nextFilters || params.filters || {};
    const {search} = params;
    const sortName = (params.sort && params.sort.name) || Tokens.DEFAULT_SORT;
    const sortOrder = (params.sort && params.sort.order) || '';

    if (!filters.generatedAt) {
      delete filters.generatedAtFrom;
      delete filters.generatedAtTo;
    }

    if (!filters.createdAt) {
      delete filters.createdAtFrom;
      delete filters.createdAtTo;
    }

    this.setState({
      filters,
    });

    this.setQueryParams({
      ...params,
      filters,
    });

    const testMode =
      typeof filters.testMode !== 'undefined'
        ? {
            test_mode_true: filters.testMode,
          }
        : {};

    const operationDataParams = {
      page: params.page || Tokens.DEFAULT_PAGE,
      per_page: +params.perPage || Tokens.DEFAULT_PER_PAGE,
      sort: {
        name: (params.sort && params.sort.name) || Tokens.DEFAULT_SORT,
        order: (params.sort && params.sort.order) || '',
      },
      include: [
        'visual',
        'coupon',
        'scenario',
        'scenario_step.scenario',
        'scenario_step.implementation',
        'participation.interface',
        'participation_prize.participation.interface',
      ],
      q: {
        s: [sortName, sortOrder].join(' '),
        g: {
          0: {
            coupon_operation_id_eq: this.props.operationId,
            coupon_id_eq: filters.coupon && filters.coupon.id,
            coupon_test_mode_eq: filters.testMode,
            participation_lead_id_not_null: filters.withLeads,
            created_at_lteq_all: filters.createdAt && filters.createdAtTo,
            created_at_gteq_all: filters.createdAt && filters.createdAtFrom,
            generated_at_lteq_all: filters.generatedAt && filters.generatedAtTo,
            generated_at_gteq_all: filters.generatedAt && filters.generatedAtFrom,
            visual_type_eq: filters.visual,
            ...testMode,
          },
          1: {
            m: 'or',
            coupon_internal_name_cont: search,
            participation_interface_name_cont: search,
            scenario_step_name_cont: search,
          },
        },
      },
    };

    if (params.search) {
      this.setState({showClear: true});
    }
    if (!params.perPage) {
      this.setQueryParams({perPage: Tokens.DEFAULT_PER_PAGE});
    }

    return this.props.getTokens(operationDataParams);
  };

  onFilterChange = (data) => {
    this.updateMe(data);
  };

  onSearchChange = (search) => {
    this.setQueryParams({search, page: Tokens.DEFAULT_PAGE}, true);
    this.setState({search});
    this.updateMe();
  };

  onPageChange = (params) => {
    this.setQueryParams(params, true);
    this.updateMe();
  };

  onSortChange = ({sortField, sortOrder}) => {
    this.setQueryParams(
      {
        sort: {name: sortField, order: sortOrder},
      },
      true,
    );
    this.updateMe();
  };

  onCloseFilterModal = () =>
    this.setState({
      showFiltersModal: false,
    });
  onOpenFilterModal = () =>
    this.setState({
      showFiltersModal: true,
    });

  render() {
    const meta = this.props.metaTokens;

    return (
      <div className="theme-color-14">
        <div className="page__title-block">
          <TitleBlock theme>
            <TitleBlock.Item>{this.state.title}</TitleBlock.Item>
          </TitleBlock>
        </div>
        <TokensFiltersModalForm
          operationId={this.props.operationId}
          show={this.state.showFiltersModal}
          totalItems={meta.total_count}
          onFilterChange={this.onFilterChange}
          filters={this.state.filters}
          onClose={this.onCloseFilterModal}
        />
        <TokensGrid
          data={this.props.tokens}
          meta={meta}
          updateMe={this.updateMe}
          openFilterModal={this.onOpenFilterModal}
          perPage={Number(this.queryParams.perPage) || Tokens.DEFAULT_PER_PAGE}
          search={this.queryParams.search}
          onSearchChange={this.onSearchChange}
          onSearchClear={this.onSearchClear}
          showClear={this.state.showClear}
          onPageChange={this.onPageChange}
          onSortChange={this.onSortChange}
          sort={this.queryParams.sort}
        />
      </div>
    );
  }
}

export default connect(
  ({languageState, couponTokens, operations}) => ({
    language: languageState.payload.TOKENS,
    languageBreadcrumbs: languageState.payload.BREADCRUMBS,
    tokens: couponTokens.couponTokens,
    metaTokens: couponTokens.metaTokens,
    operation: operations.operation,
  }),
  {
    getTokens,
    getOperation,
    setBreadcrumbs,
    clearBreadcrumbs,
  },
)(Tokens);
