import React, {PureComponent} from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';
import {formatFileSize} from 'client/services/formatters';

import {VISUAL_KINDS, VISUAL_ORIENTATION} from 'client/ducks/visuals/constants';
import {selectVisualsList} from 'client/ducks/visuals/selectors';

import Modal from 'client/common/modals/modal';

import Icon from 'client/components/common/icon';

import VisualsPlaceholder from 'client/components/client-autotask/tabs/visuals-tab/visuals-placeholder';
import TagsCell from 'client/components/client-autotask/tabs/visuals-tab/visuals-table/tags-cell';
import DatetimeCell from 'client/components/tables/common/cells/datetime-cell';

import cssModule from './visuals-preview-modal.module.scss';

const b = bem('visuals-preview-modal', {cssModule});
class VisualsPreviewModal extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    task: PropTypes.object.isRequired,
    visualsList: PropTypes.object.isRequired,
    visualIndex: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    onParamsChange: PropTypes.func.isRequired,
  };

  static WEB_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'mp4', 'mov'];

  constructor(props) {
    super(props);

    this.state = {
      visualIndex: props.visualIndex,
      isLoading: false,
    };

    this.emptyVisual = {
      interface: {},
      file: {},
      tags: [],
    };

    this.kindLabels = {
      [VISUAL_KINDS.IMAGE]: props.lang.IMAGE,
      [VISUAL_KINDS.VIDEO]: props.lang.VIDEO,
      [VISUAL_KINDS.TEXT]: props.lang.TEXT,
    };
  }

  getVisual = () => {
    const {visualsList} = this.props;
    const {visualIndex, isLoading} = this.state;

    if (isLoading) {
      return this.emptyVisual;
    }

    return visualsList.visuals[visualIndex];
  };

  handleBack = () => {
    if (this.isBackDisabled()) {
      return;
    }

    const {
      visualsList: {meta},
      onParamsChange,
      params,
    } = this.props;

    if (this.state.visualIndex === 0) {
      onParamsChange({page: meta.current_page - 1}).then(() => {
        this.setState({isLoading: false});
      });
      this.setState({visualIndex: params.perPage - 1, isLoading: true});
    } else {
      this.setState(({visualIndex}) => ({visualIndex: visualIndex - 1}));
    }
  };

  handleNext = () => {
    if (this.isNextDisabled()) {
      return;
    }

    const {
      visualsList: {visuals, meta},
      onParamsChange,
    } = this.props;

    if (this.state.visualIndex === visuals.length - 1) {
      onParamsChange({page: meta.current_page + 1}).then(() => {
        this.setState({isLoading: false});
      });
      this.setState({visualIndex: 0, isLoading: true});
    } else {
      this.setState(({visualIndex}) => ({visualIndex: visualIndex + 1}));
    }
  };

  renderNavigation = () => {
    const {
      lang,
      visualsList: {meta},
      params,
    } = this.props;
    const {visualIndex} = this.state;

    const totalIndex = (params.page - 1) * params.perPage + visualIndex;

    return (
      <div className={b('navigation')}>
        <Icon
          name="arrow-left"
          className={b('navigation-arrow', {disabled: this.isBackDisabled()})}
          onClick={this.handleBack}
        />
        <div className={b('navigation-text')}>
          {lang.VISUAL} <strong>{totalIndex + 1}</strong> {lang.OF} <strong>{meta.total_count}</strong>
        </div>
        <Icon
          name="arrow-right"
          className={b('navigation-arrow', {disabled: this.isNextDisabled()})}
          onClick={this.handleNext}
        />
      </div>
    );
  };

  renderPreview = (visual) => {
    const src = get(visual, 'file.preview.url.url');

    if (src) {
      return <img className={b('image')} src={src} />;
    }

    return <VisualsPlaceholder visual={visual} className={b('visuals-placeholder')} />;
  };

  renderVisual = () => {
    const visual = this.getVisual();

    if (!visual.id) {
      return null;
    }

    const ext = visual.file_extension;

    if (ext && !VisualsPreviewModal.WEB_EXTENSIONS.includes(ext)) {
      return this.renderPreview(visual);
    }

    switch (visual.kind) {
      case VISUAL_KINDS.IMAGE:
        return <img className={b('image')} src={visual.file.url} />;
      case VISUAL_KINDS.VIDEO:
        return (
          <video
            className={b('image')}
            controls
            controlsList="nodownload"
            ref={(el) => {
              if (el) {
                el.disablePictureInPicture = true;
              }
            }}
          >
            <source src={visual.file.url} />
          </video>
        );
      case VISUAL_KINDS.TEXT:
        return (
          <div className={b('image')} title={visual.comment}>
            {visual.comment}
          </div>
        );
      default:
        return this.renderPreview(visual);
    }
  };

  isBackDisabled = () => {
    const {
      visualsList: {meta},
    } = this.props;
    const {visualIndex} = this.state;

    return meta.current_page === 1 && visualIndex === 0;
  };

  isNextDisabled = () => {
    const {
      visualsList: {visuals, meta},
    } = this.props;
    const {visualIndex} = this.state;

    return meta.current_page === meta.total_pages && visualIndex === visuals.length - 1;
  };

  handleKeyboard = (e) => {
    e.persist();

    if (e.key === 'ArrowLeft') {
      this.handleBack();
    }

    if (e.key === 'ArrowRight') {
      this.handleNext();
    }
  };

  render() {
    const {lang, onClose, task} = this.props;

    const visual = this.getVisual();
    const interfaceName = visual.interface ? visual.interface.name : null;
    const origin = visual.interaction ? visual.interaction.name : lang.DIRECT;

    return (
      <Modal
        show={true}
        onClose={onClose}
        onKeyDown={this.handleKeyboard}
        className={b({portrait: visual.orientation === VISUAL_ORIENTATION.PORTRAIT})}
        header={this.renderNavigation()}
        classNames={{
          header: b('header'),
          body: b('body'),
          content: b('content'),
        }}
      >
        <div className={b('image-wrap')}>{this.renderVisual()}</div>
        <div className={b('details')}>
          <div className={b('details-column')}>
            <div className={b('details-item')} title={task.name}>
              {task.name}
            </div>
            {interfaceName && (
              <div className={b('details-item')} title={interfaceName}>
                {interfaceName}
              </div>
            )}
            {origin && (
              <div className={b('details-item')} title={origin}>
                {origin}
              </div>
            )}
            {visual.comment && (
              <div className={b('details-item')} title={visual.comment}>
                {visual.comment}
              </div>
            )}
          </div>
          <div className={b('details-column')}>
            <div className={b('details-item')}>
              <DatetimeCell value={visual.created_at} dateFormat="DD/MM/YY" timeFormat="HH:mm" oneLine />
            </div>
            <div className={b('details-item')}>{this.kindLabels[visual.kind]}</div>
            {visual.file_extension && <div className={b('details-item')}>{visual.file_extension}</div>}
            {visual.filesize && <div className={b('details-item')}>{formatFileSize(visual.filesize)}</div>}
          </div>
          <div className={b('details-column', ['download'])}>
            <div className={cn(b('details-item'), b('details-tags'))}>
              <TagsCell tags={visual.tags} />
            </div>
            {visual.kind !== VISUAL_KINDS.TEXT && (
              <div className={cn(b('details-item'), b('download'))}>
                <a href={visual.file.url} target="_blank" className={b('download-link')} rel="noreferrer">
                  <span className={b('download-inner')}>
                    <Icon name="downloads" className={b('download-icon')} />
                    {lang.DOWNLOAD}
                  </span>
                </a>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect((state) => ({
  lang: state.languageState.payload.CLIENT_VISUALS_PREVIEW_MODAL,
  visualsList: selectVisualsList(state),
}))(VisualsPreviewModal);
