import {calculateAge} from 'client/services/helpers';

import {selectOptIns} from 'client/ducks/opt-in-columns/selectors';

import {GENDER_TYPES} from 'client/common/config';

export function selectBroadcasts(state) {
  return state.broadcast.broadcasts;
}

export function selectBroadcast(state) {
  return state.broadcast.broadcast;
}

export function selectBroadcastClient(state) {
  return selectBroadcast(state).client;
}

export function selectBroadcastRecipients(state) {
  return selectBroadcast(state).broadcast_recipients;
}

export function selectBroadcastRecipientsLeads(state) {
  const recipients = state.broadcast.recipients;
  const codes = {};
  const LANG_GENDERS = state.languageState.payload.GENDERS;

  selectOptIns(state).forEach((optIn) => {
    codes[optIn.id] = optIn.code;
  });

  return recipients
    .map((rec) => rec.lead)
    .map((item) => {
      const {first_name, last_name, gender, birth_date, visuals_count, email, phone, opt_ins} = item;

      const keys = Object.keys(opt_ins);

      let optinCodes = [];

      keys.forEach((key) => {
        optinCodes.push(codes[key]);
      });

      return {
        first_name,
        last_name,
        gender: gender ? LANG_GENDERS[GENDER_TYPES[gender]] || gender : LANG_GENDERS.NOT_SPECIFIED,
        birth_date: calculateAge(birth_date),
        visuals_count,
        email: email,
        phone: phone,
        opt_ins: optinCodes,
      };
    });
}

export function selectRecipientsMeta(state) {
  return state.broadcast.recipients_meta;
}

export function selectActiveBroadcasts(state) {
  const lists = state.broadcast.broadcasts;

  return lists.filter((list) => list.active);
}

export function selectBroadcastDisplayItems(state) {
  return state.broadcast && state.broadcast.display_items;
}

export function selectBroadcastDisplayItemsMappedTable(state) {
  const displayItems = selectBroadcastDisplayItems(state);

  return displayItems.map((item) => {
    return {
      name: item.column_adapter && item.column_adapter.name,
      type: item.column_adapter && item.column_adapter.type,
      id: item.column_adapter && item.column_adapter.id,
      column_id: item.column_adapter && item.column_adapter.column_id,
      isArray: item.column_adapter && item.column_adapter.array,
    };
  });
}
