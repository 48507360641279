import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import {greaterThan, lessThan, number, required} from 'client/services/validator';

import fieldTemplate from 'client/components/common/field';

let options;
const secondsMaxVal = Math.round(Number.MAX_SAFE_INTEGER / 86400);

class TestTimerForm extends Component {
  constructor(props) {
    super(props);

    this.rules = {
      seconds: [
        required(this.props.languageState.errors.REQUIRED),
        greaterThan(0, `${this.props.languageState.errors.GREATER_THAN} 0`),
        number(this.props.languageState.errors.INTEGER),
        lessThan(secondsMaxVal, `${this.props.languageState.errors.LESS_THAN} ${secondsMaxVal}`),
      ],
    };
  }

  componentDidMount() {
    const {languageState} = this.props;
    const {SECONDS_OPTION, MINUTES_OPTION, HOURS_OPTION, DAYS_OPTION} = languageState;

    options = [
      {selectCustomOption: SECONDS_OPTION, amount: 1},
      {selectCustomOption: MINUTES_OPTION, amount: 60},
      {selectCustomOption: HOURS_OPTION, amount: 3600},
      {selectCustomOption: DAYS_OPTION, amount: 86400},
    ];
  }

  radioChangeHandler = (e) => {
    const {value} = e.target;
    const {change, id, formValues, untouch} = this.props;
    const {units} = formValues[`_${id}`];

    if (value !== 'wait') {
      change(`_${id}`, {
        name: value,
        units,
      });
      untouch(`_${id}._seconds`);
    }
  };

  secondsChangeHandler = (e) => {
    // case when user changes select value
    if (e.amount && e.selectCustomOption) {
      const {amount, selectCustomOption} = e;
      const {id, change, formValues} = this.props;
      const {_seconds, name} = formValues[`_${id}`];

      if (_seconds) {
        change(`_${id}`, {
          name,
          _seconds,
          seconds: _seconds * amount,
          units: {
            selectCustomOption,
            amount,
          },
        });
      }
    } else {
      // case when user changes input value
      const {value} = e.target;
      const {id, change, formValues} = this.props;
      const {name, units, seconds, _seconds} = formValues[`_${id}`];
      const {selectCustomOption} = units;
      const {amount} = options.filter((option) => selectCustomOption === option.selectCustomOption)[0];

      if (value && /^-?(\d+)?(\.)?(\d+)?$/.test(value)) {
        change(`_${id}`, {
          name,
          _seconds: value,
          seconds: value * amount,
          units: {
            selectCustomOption,
            amount,
          },
        });
      } else {
        e.preventDefault();
        change(`_${id}`, {
          name,
          _seconds,
          seconds,
          units: {
            selectCustomOption,
            amount,
          },
        });
      }
    }
  };

  render() {
    const {id, formValues, languageState: LANGUAGE} = this.props;
    const name = formValues && formValues[`_${id}`] ? formValues[`_${id}`].name : '';
    const showOverlay = name !== 'wait';

    return (
      <div>
        <div className="test-step__form-block">
          <Field
            label={LANGUAGE.RUN_LABEL}
            name={`_${id}.name`}
            type="radio"
            value="normal"
            onChange={this.radioChangeHandler}
            component={fieldTemplate}
          />
        </div>
        <div className="test-step__form-block">
          <Field
            label={LANGUAGE.SKIP_LABEL}
            name={`_${id}.name`}
            type="radio"
            value="skip"
            onChange={this.radioChangeHandler}
            component={fieldTemplate}
          />
        </div>
        <div className="test-step__form-block">
          <Field
            label={LANGUAGE.USE_VALUE_LABEL}
            name={`_${id}.name`}
            type="radio"
            value="wait"
            onChange={this.radioChangeHandler}
            component={fieldTemplate}
          />
          <div className="test-step__form-subblocks">
            {showOverlay && <div className="test-step__form-subblocks--overlay" />}
            <div className="test-step__form-block">
              <div className="test-step__form-subblock-desc test-step__form-subblock-desc--view-1">
                <div className="test-step__form-field-wrap test-step__form-field-wrap--flex">
                  <div className="test-step__form-field test-step__form-field--size-2">
                    <Field
                      cssModifier="input--no-min-height"
                      name={`_${id}._seconds`}
                      type="text"
                      component={fieldTemplate}
                      onChange={this.secondsChangeHandler}
                      validate={this.rules.seconds}
                    />
                  </div>
                  <div className="test-step__form-field test-step__form-field--size-1">
                    <Field
                      cssModifier="select--no-min-height"
                      name={`_${id}.units`}
                      options={options}
                      type="select"
                      onChange={this.secondsChangeHandler}
                      searchable={false}
                      component={fieldTemplate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TestTimerForm.propTypes = {
  change: PropTypes.func,
  formValues: PropTypes.object,
  id: PropTypes.number,
  untouch: PropTypes.func,
  languageState: PropTypes.object.isRequired,
};

export default TestTimerForm;
