import filter from 'lodash/filter';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isNumber from 'lodash/isNumber';
import uniqBy from 'lodash/uniqBy';
import {createSelector} from 'reselect';

export function selectPlacesMeta(state) {
  return state.places.meta;
}

/**
 *
 * @param state
 * @return {import('client/models/places/types').Place[]}
 */
export function selectPlaces(state) {
  return state.places.places;
}

export const selectActivePlaces = createSelector(selectPlaces, (places) => {
  return places.filter((i) => i.active);
});

export function selectPlacesTotalCount(state) {
  return selectPlacesMeta(state).total_count;
}

export function selectRegionsForPlanLinkModal(state) {
  const stores = state.places.places.filter((i) => !!i.client_id && !!i.region_id);
  const storesByRegionId = groupBy(stores, 'region_id');

  const regionIds = Object.keys(storesByRegionId);
  const restOfRegions = state.addPlaceForm.regions
    .filter((i) => !regionIds.includes(String(i.id)))
    .map((i) => ({
      ...i,
      stores: [],
    }));

  return regionIds
    .reduce(
      (regions, regionId) => [
        ...regions,
        {
          ...storesByRegionId[regionId][0].region,
          stores: storesByRegionId[regionId],
        },
      ],
      [],
    )
    .concat(restOfRegions);
}

export function selectPlacesForPlanMapView(state, planId) {
  return state.places.places.map((place) => ({
    ...place,
    offline_interactions:
      place.offline_interactions && place.offline_interactions.filter((i) => i.interaction_group_id === planId),
  }));
}

export const selectPlaceOptions = (state) => {
  return selectPlaces(state).map((place) => ({label: place.name, value: place.id}));
};

export const selectPlaceOptionsByRegions = (regionIds) => (state) => {
  let places = selectPlaces(state);
  places = regionIds?.every(isNumber) ? filter(places, (place) => regionIds.includes(place.region_id)) : places;
  return places.map((place) => ({label: place.name, value: place.id}));
};

export const selectRegionOptions = (state) => {
  return uniqBy(
    selectPlaces(state).map((place) => ({
      label: get(place, 'region.name', null),
      value: get(place, 'region.id', null),
    })),
    'value',
  );
};
