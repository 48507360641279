import React, {useMemo, useState} from 'react';

import bem from 'client/services/bem';
import {dateToString} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';
import Modal from 'client/common/modals/modal';
import TabSwitcher from 'client/common/tab-switcher';

import {ClientTemplate} from 'client/models/templates/types';

import TemplateParametersTab from './template-parameters-tab';
import TemplateScreenshotsTab from './template-screenshots-tab';

import cssModule from './template-info-modal.module.scss';

const b = bem('template-info-modal', {cssModule});

const tabs = {
  PARAMETERS: 'parameters',
  SCREENSHOTS: 'screenshots',
};

type TemplateInfoModalProps = {
  template: ClientTemplate;
  updateTemplate: (id: number, data: Partial<ClientTemplate>) => void;
  onClose: () => void;
  isModule: boolean;
  isMyTemplate: boolean;
};

const TemplateInfoModal: React.FC<TemplateInfoModalProps> = (props) => {
  const {onClose, template, updateTemplate, isModule, isMyTemplate} = props;
  const lang = useLanguage('CATALOG_TEMPLATES.MODALS.TEMPLATE_INFO_MODAL');
  const [activeTab, setActiveTab] = useState(tabs.PARAMETERS);
  const screenshots = useMemo(() => template.screenshots || [], [template.screenshots]);
  const isWeezioTemplate = !template.creator_team_id;

  const tabsConfig = [
    {title: lang.PARAMETERS, value: tabs.PARAMETERS},
    {title: lang.SCREENSHOTS, value: tabs.SCREENSHOTS, exclude: !screenshots.length},
  ];

  return (
    <Modal
      className={b()}
      onClose={onClose}
      isCloseHidden
      title={template.title}
      titleButton={
        <AppButton className={b('close-button')} color="clients" transparent label={lang.CLOSE} onClick={onClose} />
      }
    >
      <p className={b('created')}>
        {!isWeezioTemplate && (
          <span>
            {lang.CREATED_BY} {template?.client_user?.full_name}
          </span>
        )}
        <span>{dateToString(template.created_at)}</span>
      </p>
      <TabSwitcher className={b('tab-switcher')} tabs={tabsConfig} activeTab={activeTab} onTabClick={setActiveTab} />
      {activeTab === tabs.PARAMETERS && (
        <TemplateParametersTab
          template={template}
          updateTemplate={updateTemplate}
          isModule={isModule}
          isMyTemplate={isMyTemplate}
        />
      )}
      {activeTab === tabs.SCREENSHOTS && <TemplateScreenshotsTab screenshots={screenshots} />}
    </Modal>
  );
};

export default TemplateInfoModal;
