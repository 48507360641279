import types from './types';

const initialState = {
  payload: {
    column_adapters: [],
  },
};

export default function (_state = initialState, action) {
  const state = Object.assign({}, _state);

  switch (action.type) {
    case types.GET_INTERNAL_COLUMNS_REQUEST:
      return state;

    case types.GET_INTERNAL_COLUMNS_SUCCESS:
      state.payload = action.payload;
      return state;

    case types.FLUSH_INTERNAL_COLUMNS_ALL_STATE:
      return initialState;

    default:
      return state;
  }
}
