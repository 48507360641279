import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, change, touch} from 'redux-form';

import bem from 'client/services/bem';
import {getAuthHeaders} from 'client/services/helpers';

import {API_PATH, API_METHODS} from 'client/common/config';

import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

import {
  getGroupsAction,
  getNetworksAction,
  getClientsAction,
  getRegionsAction,
  toggleSubmittedAction,
} from '../../../../../ducks/add-place-form/actions';

import cssModule from './add-place-form.module.scss';

let addressDoesNotExist = [false];
let lastStreetAddress = '';

const b = bem('add-place-form', {cssModule});

class AddPlaceForm extends Component {
  static validate({name, network, city, street_address, group}, props) {
    let errors = {};

    if (!name || !name.trim()) {
      errors.name = props.lang.ADD_PLACE_MODAL_NAME_ERROR;
    }
    if (!network || !network.id) {
      errors.network = props.lang.ADD_PLACE_MODAL_REQUIRED_FIELD_ERROR;
    }
    if (!group || !group.value) {
      errors.group = props.lang.ADD_PLACE_MODAL_REQUIRED_FIELD_ERROR;
    }

    if (!city || !city.id) {
      errors.city = errors.zip = props.lang.ADD_PLACE_MODAL_REQUIRED_FIELD_ERROR;
    }

    if (!street_address || !street_address.trim()) {
      errors.street_address = props.lang.ADD_PLACE_MODAL_REQUIRED_FIELD_ERROR;
    }

    if (addressDoesNotExist[0] && street_address === lastStreetAddress) {
      errors.street_address = props.lang.ADDRESS_DOES_NOT_EXIST_ERROR;
    } else if (addressDoesNotExist[0]) {
      addressDoesNotExist[0] = false;
    }

    return errors;
  }

  static fetchSelectData({input, apiMethod, filter = '', perPage = 25, includes = []}) {
    if (!input) {
      return Promise.resolve({options: []});
    }

    return fetch(
      `${API_PATH}${apiMethod}?${filter}&per_page=${perPage}${includes.map((item) => '&include[]=' + item)}`,
      {
        method: 'get',
        headers: getAuthHeaders(),
      },
    )
      .then((response) => response.json())
      .then((json) => {
        return {options: json.cities};
      });
  }

  static updateSyncErrors(values, props) {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'addPlaceForm',
      },
      payload: {
        syncErrors: AddPlaceForm.validate(values, props),
      },
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      showLinkToClient: false,
    };
  }

  componentDidMount() {
    this.props.getGroups();
    this.props.updateErrors(this.props.initialValues || {}, this.props);
    if (this.props.initialValues.group_id) {
      this.props.getNetworks(this.props.initialValues.group_id);
    }
    if (this.props.initialValues.client_id) {
      this.props.getClients();
      this.props.getRegions(this.props.initialValues.client_id);
      this.setState({
        showLinkToClient: true,
      });
    } else {
      this.props.getRegions();
    }
  }

  componentDidUpdate(prevProps) {
    const {values} = this.props.formState;

    if (this.props.addressDoesNotExist !== prevProps.addressDoesNotExist && this.props.addressDoesNotExist[0]) {
      lastStreetAddress = values.street_address;
      this.props.updateErrors(values, this.props);
    }

    if (this.props.addPlaceForm.submitted && !prevProps.addPlaceForm.submitted) {
      const toTouch = Object.keys(this.props.formState.syncErrors);
      this.props.touchField('addPlaceForm', ...toTouch);
      this.props.toggleSubmitted();
    }

    if (values && values.client && !values.client.id) {
      values.client = null;
      this.props.changeFieldValue('client', null);
    }
  }

  onZIPChanged(changeFieldValue) {
    return (city) => {
      if (typeof city.id === 'undefined') {
        changeFieldValue('agglomeration', '');
        changeFieldValue('zone', '');
        changeFieldValue('city', {});
        return;
      }
      changeFieldValue('agglomeration', '' + city.agglomeration.name);
      changeFieldValue('zone', '' + city.agglomeration.zone.name);
      changeFieldValue('city', city);
    };
  }

  onCityChanged(changeFieldValue) {
    return (city) => {
      if (typeof city.id === 'undefined') {
        changeFieldValue('agglomeration', '');
        changeFieldValue('zone', '');
        changeFieldValue('zip', {});
        return;
      }
      changeFieldValue('agglomeration', '' + city.agglomeration.name);
      changeFieldValue('zone', '' + city.agglomeration.zone.name);
      changeFieldValue('zip', city);
    };
  }

  render() {
    const {groups, networks, clients, regions} = this.props.addPlaceForm;
    const {getNetworks, getClients, getRegions, changeFieldValue, isStore, lang} = this.props;

    const networksDisabled = networks.length === 0;
    const regionsDisabled = regions.length === 0;

    return (
      <form className={b()}>
        <FormFieldWrap>
          <Field
            name="name"
            type="text"
            placeholder=""
            label={lang.ADD_PLACE_MODAL_NAME_LABEL}
            required
            component={fieldTemplate}
            props={{
              focusOnMount: true,
            }}
          />
        </FormFieldWrap>
        <div className={b('row', ['empty'])} />

        <label>{lang.BASIC_INFORMATION_LABEL}</label>

        <FormFieldWrap>
          <Field
            name="group"
            label={lang.ADD_PLACE_MODAL_GROUP_LABEL}
            type="select"
            searchable
            options={groups}
            placeholder=""
            required
            component={fieldTemplate}
            onChange={(value) => {
              changeFieldValue('network', null);
              getNetworks(value && value.value);
            }}
          />
        </FormFieldWrap>
        <FormFieldWrap>
          <Field
            name="network"
            label={lang.ADD_PLACE_MODAL_NETWORK_LABEL}
            type="select"
            valueKey="id"
            labelKey="name"
            searchable
            required
            options={networks}
            disabled={networksDisabled}
            placeholder=""
            component={fieldTemplate}
          />
        </FormFieldWrap>
        <FormFieldWrap>
          <Field
            name="zip"
            label={lang.ADD_PLACE_MODAL_ZIP_LABEL}
            type="select-async"
            searchable
            valueKey="id"
            required
            labelKey="zip"
            loadOptions={(input) =>
              AddPlaceForm.fetchSelectData({
                input,
                filter: `q[zip_start]=${input}`,
                apiMethod: API_METHODS.CITIES,
                includes: ['agglomeration.zone'],
              })
            }
            onChange={this.onZIPChanged(changeFieldValue)}
            placeholder=""
            component={fieldTemplate}
          />
        </FormFieldWrap>
        <FormFieldWrap>
          <Field
            name="city"
            label={lang.ADD_PLACE_MODAL_CITY_LABEL}
            type="select-async"
            searchable
            required
            valueKey="id"
            labelKey="name"
            loadOptions={(input) =>
              AddPlaceForm.fetchSelectData({
                input,
                filter: `q[name_start]=${input}`,
                apiMethod: API_METHODS.CITIES,
                includes: ['agglomeration.zone'],
              })
            }
            onChange={this.onCityChanged(changeFieldValue)}
            placeholder=""
            component={fieldTemplate}
          />
        </FormFieldWrap>
        <FormFieldWrap>
          <Field
            name="agglomeration"
            label={lang.ADD_PLACE_MODAL_AGGLOMERATION_LABEL}
            type="text"
            disabled
            required
            placeholder=""
            component={fieldTemplate}
          />
        </FormFieldWrap>
        <FormFieldWrap>
          <Field
            name="zone"
            label={lang.ADD_PLACE_MODAL_ZONE_LABEL}
            type="text"
            required
            disabled
            placeholder=""
            component={fieldTemplate}
          />
        </FormFieldWrap>
        <FormFieldWrap>
          <Field
            name="street_address"
            label={lang.ADD_PLACE_MODAL_STREET_ADDRESS_LABEL}
            type="text"
            required
            placeholder=""
            component={fieldTemplate}
          />
        </FormFieldWrap>
        <div className={b('row', ['empty'])} />
        <label>{lang.ADDITIONAL_INFO_TEXT}</label>
        <FormFieldWrap>
          <Field
            name="comment"
            label={lang.ADD_PLACE_MODAL_COMMENT_LABEL}
            type="textarea"
            placeholder=""
            mainDivClass="randomjdfr2f"
            component={fieldTemplate}
          />
        </FormFieldWrap>
        <div className={b('row', {hidden: this.state.showLinkToClient, center: !this.state.showLinkToClient})}>
          <a
            href="#"
            onClick={() => {
              getClients();
              this.setState({showLinkToClient: true});
            }}
          >
            {lang.LINK_TO_CLIENT_BUTTON}
          </a>
        </div>
        <div className={b('row', {hidden: !this.state.showLinkToClient && isStore})}>
          <Field
            name="client"
            label={lang.ADD_PLACE_MODAL_CLIENT_LABEL}
            type="select"
            searchable
            options={clients}
            valueKey="id"
            labelKey="name"
            placeholder=""
            onChange={(value) => {
              changeFieldValue('region', null);
              getRegions(value && value.id);
            }}
            component={fieldTemplate}
          />
        </div>
        <div className={b('row', {hidden: !this.state.showLinkToClient})}>
          <Field
            name="region"
            label={lang.ADD_PLACE_MODAL_REGION_LABEL}
            type="select"
            searchable
            options={regions}
            disabled={regionsDisabled}
            valueKey="id"
            labelKey="name"
            placeholder=""
            component={fieldTemplate}
          />
        </div>
      </form>
    );
  }
}

AddPlaceForm.propTypes = {
  formState: PropTypes.object,
  getGroups: PropTypes.func,
  getNetworks: PropTypes.func,
  getRegions: PropTypes.func,
  changeFieldValue: PropTypes.func,
  touchField: PropTypes.func,
  toggleSubmitted: PropTypes.func,
  getClients: PropTypes.func,
  updateErrors: PropTypes.func,
  initialValues: PropTypes.object,
  isStore: PropTypes.bool,
  addressDoesNotExist: PropTypes.array,
  addPlaceForm: PropTypes.shape({
    groups: PropTypes.array,
    networks: PropTypes.array,
    clients: PropTypes.array,
    regions: PropTypes.array,
    submitted: PropTypes.bool,
  }),
  lang: PropTypes.object.isRequired,
};

AddPlaceForm.defaultProps = {
  initialValues: {
    name: '',
    group: null,
    network: null,
    zip: null,
    city: null,
    agglomeration: '',
    zone: '',
    street: '',
    building: '',
    comment: '',
    client: null,
    region: null,
  },
  isStore: false,
  addressDoesNotExist: [false],
};

const addPlaceFormComponent = reduxForm({
  form: 'addPlaceForm',
  validate: AddPlaceForm.validate,
})(AddPlaceForm);

const mapStateToProps = ({addPlaceForm, form, languageState}) => ({
  addPlaceForm,
  formState: form.addPlaceForm,
  lang: languageState.payload.PLACES,
});

const mapDispatchToProps = {
  getGroups: getGroupsAction,
  getNetworks: getNetworksAction,
  getClients: getClientsAction,
  getRegions: getRegionsAction,
  changeFieldValue: (field, value) => change('addPlaceForm', field, value),
  touchField: touch,
  toggleSubmitted: toggleSubmittedAction,
  updateErrors: AddPlaceForm.updateSyncErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(addPlaceFormComponent);
