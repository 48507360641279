import React, {memo, useCallback} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Icon from 'client/components/common/icon';

import './disalable-checkbox.scss';

const b = bem('disalable-checkbox');

const DisalableCheckbox = memo((props) => {
  const {active, disabled, onChange} = props;

  const iconName = ['checkbox', active ? 'active' : 'inactive', disabled ? 'disabled' : null]
    .filter((i) => i)
    .join('-');

  const toggle = useCallback(() => {
    onChange(!active);
  }, [active, onChange]);

  return <Icon className={b({disabled})} name={iconName} onClick={disabled ? null : toggle} />;
});

DisalableCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

DisalableCheckbox.defaultProps = {
  disabled: false,
};

export default DisalableCheckbox;
