import types from './types';

const initialState = {
  adminUsers: [],
  adminUsersSubsidiary: {},
  allAdmins: [],
};

export default function (_state = initialState, action) {
  const state = Object.assign({}, _state);

  switch (action.type) {
    case types.GET_ADMIN_USERS_SUCCESS:
      state.adminUsers = action.payload.admin_users;
      return state;

    case types.GET_ADMIN_USERS_SUBSIDIARY_SUCCESS:
      state.adminUsersSubsidiary = action.payload.admin_user.subsidiary;
      return state;

    case types.GET_ALL_ADMINS_SUCCESS:
      state.allAdmins = action?.payload?.admin_users || initialState.adminOptions;
      return state;

    default:
      return state;
  }
}
