import React from 'react';

import PropTypes from 'prop-types';

import BaseToolbar from 'client/components/common/base-toolbar';

import {TranslationJsx} from 'client/models/language/types';

function BCToolbar({
  className,
  searchField,
  resultsNum,
  textLabels,
  filters,
  onShowFilterClick,
  onClearFiltersClick,
  onFiltersClick,
  actionBarChildren,
  withoutFilters,
}) {
  return (
    <BaseToolbar
      withoutFilters={withoutFilters}
      className={className}
      searchField={searchField}
      resultsNum={resultsNum}
      filterBtnClass="button--bg-14"
      clearFiltersBtnClass="button--bg-14"
      onShowFilterClick={onShowFilterClick}
      onClearFiltersClick={onClearFiltersClick}
      textLabels={{
        filtersButton: textLabels.filtersButton,
        resultsNum: ` ${textLabels.resultsNum}`,
        clearFilters: textLabels.clearFilters,
      }}
      filters={filters}
      onFiltersClick={onFiltersClick}
      actionBarChildren={actionBarChildren}
    />
  );
}

BCToolbar.defaultProps = {
  className: '',
  resultsNum: '',
  searchField: null,
  statusButtons: '',
  textLabels: {},
  filters: [],
  onShowFilterClick: () => {},
  showActionBar: true,
  statusActionButtons: {
    onValidateClick: () => {},
    onVisibleClick: () => {},
  },
  onFiltersClick: () => {},
  actionBarChildren: null,
};

BCToolbar.propTypes = {
  className: PropTypes.string,
  resultsNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  searchField: PropTypes.element,
  statusButtons: PropTypes.any,
  textLabels: PropTypes.object,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      applied: PropTypes.bool,
      label: TranslationJsx,
      name: PropTypes.string,
    }),
  ),
  onFiltersClick: PropTypes.func,
  onShowFilterClick: PropTypes.func,
  onClearFiltersClick: PropTypes.func,
  actionBarChildren: PropTypes.node,
  withoutFilters: PropTypes.bool,
};

export default BCToolbar;
