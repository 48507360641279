import React, {PureComponent} from 'react';

import cn from 'classnames';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import {getPercent} from 'client/services/helpers';

import Popover from 'client/common/popovers/popover';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {TranslationJsx} from 'client/models/language/types';

import {calculateTicks} from './helpers';

import './stacked-bars-chart.scss';

class StackedBarsChart extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: TranslationJsx,
        hint: PropTypes.node,
        marked: PropTypes.bool,
        total: PropTypes.number,
        bars: PropTypes.arrayOf(
          PropTypes.shape({
            label: TranslationJsx,
            color: PropTypes.string,
            hint: PropTypes.string,
            value: PropTypes.number,
          }),
        ),
      }),
    ).isRequired,
  };

  static BAR_WIDTH = 80;

  rootRef = null;

  state = {
    rootWidth: 0,
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateRootWidth);

    this.updateRootWidth();
  }

  componentDidUpdate() {
    this.updateRootWidth();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateRootWidth);
  }

  updateRootWidth = () => {
    const rootWidth = get(this.rootRef, 'offsetWidth', 0);

    if (rootWidth !== this.state.rootWidth) {
      this.setState({rootWidth});
    }
  };

  getMaxValue = () => {
    const values = this.props.data.map(({total}) => total);

    return Math.max(...values, 10);
  };

  getLegend = () => {
    const result = {};

    this.props.data.forEach((item) => {
      item.bars.forEach(({label, color}) => {
        if (!result[label]) {
          result[label] = {
            label,
            color,
          };
        }
      });
    });

    return Object.values(result);
  };

  renderRowLabel = (index, config) => {
    return (
      <div key={index} className="stacked-bars-chart__rows-label" style={{height: config.stepHeight}}>
        {index !== config.stepsCount - 1 && (
          <div className="stacked-bars-chart__rows-label-text">{config.max - config.step * index}</div>
        )}
      </div>
    );
  };

  renderRowLine = (index, config) => {
    if (index === config.stepsCount - 1) {
      return null;
    }

    return (
      <div key={index} className="stacked-bars-chart__rows-line" style={{height: config.stepHeight}}>
        {index === config.stepsCount - 1 && <div className="stacked-bars-chart__rows-zero">0</div>}
      </div>
    );
  };

  getPrevBarsHeight = (item, bar, config) => {
    const barIndex = findIndex(item.bars, {label: bar.label});

    if (barIndex === 0) {
      return 0;
    }

    return item.bars.slice(0, barIndex).reduce((acc, {value}) => acc + getPercent(config.max, value, false), 0) + '%';
  };

  renderBar = (bar, item, config) => {
    const prevBarsHeight = this.getPrevBarsHeight(item, bar, config);

    return (
      <div
        key={bar.key}
        className="stacked-bars-chart__column-bar-wrap"
        style={{
          height: getPercent(config.max, bar.value),
          bottom: prevBarsHeight,
        }}
      >
        <Popover
          className="stacked-bars-chart__popover"
          position="top"
          overlay={<span className="stacked-bars-chart__hint">{bar.hint}</span>}
          shiftTop={-20}
          shiftLeft={4}
        >
          <div className="stacked-bars-chart__column-bar" style={{backgroundColor: bar.color}} />
        </Popover>
      </div>
    );
  };

  renderColumn = (index, item, config) => {
    return (
      <div key={index} className="stacked-bars-chart__column">
        {item.marked && <div className="stacked-bars-chart__column-mark" />}
        <div className="stacked-bars-chart__column-bars">
          {item.bars.map((bar) => this.renderBar(bar, item, config))}
        </div>
        <div className="stacked-bars-chart__column-label">
          <div className="stacked-bars-chart__column-label-text-wrap">
            <Popover
              className="stacked-bars-chart__popover"
              position="bottom"
              overlay={<span className="stacked-bars-chart__hint">{item.hint}</span>}
              shiftTop={10}
            >
              <div
                className={cn('stacked-bars-chart__columns-label-text', {
                  'stacked-bars-chart__columns-label-text--marked': item.marked,
                })}
              >
                {item.label}
              </div>
            </Popover>
          </div>
        </div>
      </div>
    );
  };

  renderLegendItem = ({label, color}) => {
    return (
      <div key={label} className="stacked-bars-chart__legend-item">
        <span className="stacked-bars-chart__legend-item-marker" style={{backgroundColor: color}} />
        <span className="stacked-bars-chart__legend-item-text">{label}</span>
      </div>
    );
  };

  getColumnsStyle = (config) => {
    const {data} = this.props;
    const {rootWidth} = this.state;
    const {labelWidth} = config;

    const minWidth = data.length * StackedBarsChart.BAR_WIDTH + labelWidth;
    const result = minWidth > rootWidth ? minWidth : rootWidth - labelWidth;

    return {
      width: result + 'px',
      paddingLeft: labelWidth + 'px',
    };
  };

  render() {
    const {data} = this.props;
    const {rootWidth} = this.state;

    const maxValue = this.getMaxValue();
    const config = calculateTicks(0, maxValue);
    const steps = [...Array(config.stepsCount)];
    const legend = this.getLegend();

    return (
      <div
        className="stacked-bars-chart"
        ref={(element) => {
          this.rootRef = element || null;
        }}
      >
        <div style={{width: rootWidth}}>
          <div className="stacked-bars-chart__rows-labels">{steps.map((_, i) => this.renderRowLabel(i, config))}</div>
          <CustomScrollbars
            scrollbarProps={{
              autoHeight: true,
              autoHeightMin: 350,
            }}
          >
            <div className="stacked-bars-chart__columns" style={this.getColumnsStyle(config)}>
              <div className="stacked-bars-chart__rows">
                <div className="stacked-bars-chart__rows-lines">
                  {steps.map((_, i) => this.renderRowLine(i, config))}
                </div>
              </div>
              {data.map((item, i) => this.renderColumn(i, item, config))}
            </div>
          </CustomScrollbars>
        </div>
        <div className="stacked-bars-chart__legend">{legend.map(this.renderLegendItem)}</div>
      </div>
    );
  }
}

export default StackedBarsChart;
