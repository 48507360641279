import React, {useMemo} from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';

import {INNER_PLACEHOLDER_ID} from '../../constants';
import {useEditor} from '../../hooks/useEditor';
import {useSortableItems} from '../../hooks/useSortableItems';
import {AvailableBlockContent, Column as ColumnType, ContentBlock as ContentBlockType} from '../../types';
import {ContentBlock} from '../content-block';

import cssModule from './column.module.scss';

const b = bem('column', {cssModule});
const placeholderId = INNER_PLACEHOLDER_ID;

type ColumnProps = {
  column: ColumnType | {id: string};
  className: string;
};

export const Column: React.FC<ColumnProps> = ({column, className}) => {
  const {availableBlocks, dndData, createContentBlock, moveContentBlock} = useEditor();
  const allowedTypes = useMemo(() => availableBlocks.content.map((el) => el.type), [availableBlocks]);
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.BLOCKS.COLUMN');

  const {sortableItems, containerRef, hoveredElId, isDragOver, onDragEnter, onDragOver, onDragLeave, onDrop} =
    useSortableItems<ContentBlockType>('contentBlocks' in column ? column.contentBlocks : [], {
      placeholderId,
      allowedTypes,
    });

  const handleDrop = () => {
    onDrop();

    const {id, type} = dndData;

    if (!type || !allowedTypes.includes(type as AvailableBlockContent['type'])) {
      return;
    }

    if (id === INNER_PLACEHOLDER_ID) {
      createContentBlock(column.id, type, hoveredElId);
    } else {
      moveContentBlock(id, column.id, hoveredElId);
    }
  };

  return (
    <div
      ref={containerRef}
      className={cn(className, b())}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={handleDrop}
      data-colid={column.id}
    >
      {sortableItems.length ? (
        <>
          {sortableItems.map((el) => (
            <ContentBlock key={el.id} contentBlock={el} canDrop={dndData?.id === el.id && isDragOver}>
              {el.id}
            </ContentBlock>
          ))}
        </>
      ) : (
        <div className={b('placeholder')}>
          <Icon name="add" width={24} height={24} />
          <span>{lang.EMPTY_STATE}</span>
        </div>
      )}
    </div>
  );
};
