export default {
  FETCH_DEVICES_EXTERNAL_REQUEST: '@@clients-devices/FETCH_DEVICES_EXTERNAL_REQUEST',
  FETCH_DEVICES_EXTERNAL_SUCCESS: '@@clients-devices/FETCH_DEVICES_EXTERNAL_SUCCESS',
  FETCH_DEVICES_EXTERNAL_ERROR: '@@clients-devices/FETCH_DEVICES_EXTERNAL_ERROR',

  FETCH_DEVICES_EXTERNAL_FILTER_REGIONS_REQUEST: '@@clients-devices/FETCH_DEVICES_EXTERNAL_FILTER_REGIONS_REQUEST',
  FETCH_DEVICES_EXTERNAL_FILTER_REGIONS_SUCCESS: '@@clients-devices/FETCH_DEVICES_EXTERNAL_FILTER_REGIONS_SUCCESS',
  FETCH_DEVICES_EXTERNAL_FILTER_REGIONS_ERROR: '@@clients-devices/FETCH_DEVICES_EXTERNAL_FILTER_REGIONS_ERROR',

  FETCH_DEVICES_EXTERNAL_FILTER_STORES_REQUEST: '@@clients-devices/FETCH_DEVICES_EXTERNAL_FILTER_STORES_REQUEST',
  FETCH_DEVICES_EXTERNAL_FILTER_STORES_SUCCESS: '@@clients-devices/FETCH_DEVICES_EXTERNAL_FILTER_STORES_SUCCESS',
  FETCH_DEVICES_EXTERNAL_FILTER_STORES_ERROR: '@@clients-devices/FETCH_DEVICES_EXTERNAL_FILTER_STORES_ERROR',

  FETCH_DEVICES_EXTERNAL_FILTER_TASK_REQUEST: '@@clients-devices/FETCH_DEVICES_EXTERNAL_FILTER_TASK_REQUEST',
  FETCH_DEVICES_EXTERNAL_FILTER_TASK_SUCCESS: '@@clients-devices/FETCH_DEVICES_EXTERNAL_FILTER_TASK_SUCCESS',
  FETCH_DEVICES_EXTERNAL_FILTER_TASK_ERROR: '@@clients-devices/FETCH_DEVICES_EXTERNAL_FILTER_TASK_ERROR',

  UPDATE_DEVICE_EXTERNAL_REQUEST: '@@clients-devices/UPDATE_DEVICE_EXTERNAL_REQUEST',
  UPDATE_DEVICE_EXTERNAL_SUCCESS: '@@clients-devices/UPDATE_DEVICE_EXTERNAL_SUCCESS',
  UPDATE_DEVICE_EXTERNAL_ERROR: '@@clients-devices/UPDATE_DEVICE_EXTERNAL_ERROR',
};
