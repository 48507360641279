import upperFirst from 'lodash/upperFirst';

const timeColumnNames = ['last_participation'];
const getName = (str) => upperFirst(str.replaceAll('_', ' '));

const getKind = ({name, kind}) => {
  return /_at/.test(name) || timeColumnNames.includes(name) ? 'date' : kind;
};

export function selectLeadsDisplayItems(state) {
  return state.leadDisplayItems.payload || [];
}

/**
 * @returns {import('client/models/columns/types').ColumnVisibilityItem[]}
 */
export function selectColumnVisibilityItems(state) {
  return state.leadDisplayItems.visibilityItems;
}

export function selectLeadsDisplayItemsMappedTable(state) {
  const displayItems = selectLeadsDisplayItems(state);

  return displayItems.map((item) => {
    return {
      name: item.column_adapter && item.column_adapter.name,
      type: item.column_adapter && item.column_adapter.type,
      id: item.column_adapter && item.column_adapter.column_id,
    };
  });
}

const columnsMapper = {
  last_participation: 'latest_participated_at',
  last: 'latest_participated_at',
  all: 'participations_count',
  visuals: 'visuals_count',
};

export function selectColumnVisibilityItemsMapped(state) {
  const displayItems = selectColumnVisibilityItems(state);
  return displayItems.map(({column_adapter}) => {
    const pathPrefix = column_adapter.type === 'ExternalColumnAdapter' ? 'input_data.' : '';
    return {
      name: columnsMapper[column_adapter.name] || column_adapter.name,
      label: getName(column_adapter.name),
      path: `${pathPrefix}${column_adapter.name}`,
      kind: getKind(column_adapter),
      key: column_adapter.id,
      // id: column_adapter.id || 0
    };
  });
}
