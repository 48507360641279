import React, {useCallback} from 'react';

import placeholderIcon from 'assets/icons/colored/placeholder-no-photo.svg';
import cn from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm from 'client/services/hooks/use-redux-form';

import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

import {ImageField, SelectField, TextareaField} from 'client/common/fields';

import {COMMON_INPUT} from 'client/components/prizes/constants';
import {getTypeCodes} from 'client/components/prizes/helpers';
import {PrizeDiyModalFormName} from 'client/components/prizes/modals/prize-diy-modal';

import cssModule from './prize-diy-email-fieldset.module.scss';

const b = bem('prize-diy-email-fieldset', {cssModule});

const PrizeDiyEmailFieldset = (props) => {
  const {disabled, onRemove} = props;
  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_EMAIL_FIELDSET');
  const {formValues, change} = useReduxForm(PrizeDiyModalFormName);
  const {language, defaultLanguage} = formValues?.main || {};
  const languages = useSelector(selectPrizeLanguages);
  const formValueLanguage = language === COMMON_INPUT ? defaultLanguage : language;

  const onUpload = useCallback(
    (file) => {
      if (language === COMMON_INPUT) {
        const ticket = languages.reduce((imgs, langKey) => {
          imgs[`email_image_${langKey}`] = file;
          imgs[`external_description_${langKey}`] = get(formValues, `email.external_description_${langKey}`);
          imgs.email_code_type = get(formValues, 'email.email_code_type');
          return imgs;
        }, {});
        change('email', ticket);
      } else if (language === defaultLanguage) {
        const ticket = languages.reduce((imgs, langKey) => {
          const currentValue = get(formValues, `email.email_image_${langKey}`);
          imgs[`email_image_${langKey}`] = langKey === defaultLanguage ? file : currentValue || file;
          imgs[`email_image_${langKey}_id`] = get(formValues, `email.email_image_${langKey}_id`) || 0;
          imgs[`external_description_${langKey}`] = get(formValues, `email.external_description_${langKey}`);
          imgs.email_code_type = get(formValues, 'email.email_code_type');
          return imgs;
        }, {});

        change('email', ticket);
      } else {
        change(`email.email_image_${language}`, file);
      }
    },
    [change, defaultLanguage, formValues, language, languages],
  );

  const onBlurDescription = (e) => {
    const {value} = e.target;

    if (language === COMMON_INPUT) {
      languages.forEach((langKey) => change(`email.external_description_${langKey}`, value));
    } else if (language === defaultLanguage) {
      languages.forEach((langKey) => {
        const currentValue = get(formValues, `email.external_description_${langKey}`)?.trim();
        change(`email.external_description_${langKey}`, currentValue || value);
      });
    } else {
      change(`email.external_description_${language}`, value || '');
    }
  };

  return (
    <div className={cn(b())}>
      <div className={b('row')}>
        <ImageField
          disabled={disabled}
          name={`email.email_image_${formValueLanguage}`}
          className={b('preview')}
          label={lang.IMAGE_TITLE}
          onChange={onUpload}
          keepRatio={true}
          placeholderIcon={placeholderIcon}
          acceptFormats=".png,.jpg,.jpeg"
          classNames={{
            controls: b('email-image-controls'),
          }}
          onRemove={() => onRemove('email.email_image')}
          removable={true}
        />
        <div className={b('column')}>
          <SelectField
            options={getTypeCodes(lang)}
            name="email.email_code_type"
            simpleValue={true}
            required={true}
            label={lang.TYPE_OF_CODE_LABEL}
            disabled={disabled}
          />
        </div>
      </div>

      <TextareaField
        name={`email.external_description_${formValueLanguage}`}
        parse={(value) => value || null}
        onBlur={onBlurDescription}
        maxLength={500}
        label={lang.DESCRIPTION}
        disabled={disabled}
      />
    </div>
  );
};

PrizeDiyEmailFieldset.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default PrizeDiyEmailFieldset;
