import {useCallback, useEffect, useState} from 'react';

import {useHistory} from 'react-router-dom';

export default (when: boolean) => {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (when) {
      history.block((prompt: {pathname: any; search: any}) => {
        setCurrentPath(prompt.pathname + prompt.search);
        setShowPrompt(true);
        return 'true';
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const denyTransition = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const allowTransition = useCallback(() => {
    history.block(() => {});
    history.push(currentPath);
    setShowPrompt(false);
  }, [history, currentPath]);

  return {
    showPrompt,
    denyTransition,
    allowTransition,
  };
};
