export const selectDiyPrizeBlock = (state) => state.diyOperation.prizeBlock.data;

export const selectDiyPrizeGame = (state) => state.diyOperation.prizeBlock.game;

/**
 *
 * @param {import('client/types/common/redux').MainStates} state
 * @return {{
 *     data: import('client/models/devices/types').Device[]
 * }}
 */
export function selectDiyDevices(state) {
  return state.diyOperation.devices;
}
