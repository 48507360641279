import React, {Component} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import {mapSelectList} from 'client/services/helpers';

import {selectAutotasksForFilterModal} from 'client/ducks/autotask/selectors';
import {fetchOnlineInteractions, fetchOfflineInteractions} from 'client/ducks/interactions/actions';
import {selectOnlineInteractions, selectOfflineInteractions} from 'client/ducks/interactions/selectors';
import {getInterfacesAction} from 'client/ducks/interfaces/actions';
import {selectOperationsForFilterModal} from 'client/ducks/operations/selectors';
import {getOptInsAction} from 'client/ducks/opt-in-columns/actions';
import {selectOptInsForFilterModal} from 'client/ducks/opt-in-columns/selectors';
import {getLevelOptions} from 'client/ducks/participations/actions';
import {selectLevelOptions} from 'client/ducks/participations/selectors';
import {getPrizes} from 'client/ducks/scenario/actions';
import {getScenarios} from 'client/ducks/scenario/actions';
import {selectPrizes} from 'client/ducks/scenario/selectors';

import AsyncSelectField from 'client/common/fields/async-select-field';
import Modal from 'client/common/modals/modal';

import AsyncDropdownAny from 'client/components/common/async-dropdown-any';
import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

import {mapInteractionsToOptions} from 'client/components/participants/participants-list/helper';

const preChosenValues = {
  participated: 'false',
  gender: 'false',
  created: 'false',
  client: {value: '0'},
  source: {value: '0'},
  opt_in: [],
  operation: [],
  autotask: [],
  optInRadio: 'false',
  m: 'and',
};

let filterNewProps = preChosenValues;

class ParticipantsFilterModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    clientId: PropTypes.string,
    filter: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    taskId: PropTypes.string.isRequired,
    autotasks: PropTypes.array.isRequired,
    participantsList: PropTypes.object.isRequired,
    operations: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    clientsList: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    getOptIns: PropTypes.func.isRequired,
    getInterfaces: PropTypes.func.isRequired,
    getScenarios: PropTypes.func.isRequired,
    optIns: PropTypes.array,
    interfaces: PropTypes.array,
    scenarios: PropTypes.array,
    getPrizes: PropTypes.func.isRequired,
    prizes: PropTypes.array.isRequired,
    getLevelOptions: PropTypes.func.isRequired,
    levelOptions: PropTypes.array.isRequired,
    onlineInteractions: PropTypes.array.isRequired,
    getOnlineInteractions: PropTypes.func.isRequired,
    offlineInteractions: PropTypes.array.isRequired,
    getOfflineInteractions: PropTypes.func.isRequired,
  };

  static defaultProps = {
    filter: {
      optInRadio: 'false',
    },
    show: false,
    optIns: [],
  };

  static formName = 'ParticipantsFilterModalForm';
  static optInValues = {
    custom: 'true',
    noMatter: 'false',
    noActive: 'noActive',
  };

  constructor(props) {
    super(props);
    this.state = {
      participated: false,
      participatedFrom: null,
      optInsOptions: [],
    };
  }

  componentDidMount() {
    this.fetchPrizes();
    this.fetchLevelOptions();
    this.fetchInteractions();
  }

  componentDidUpdate(prevProps) {
    const {filter} = this.props;

    if (filter !== prevProps.filter) {
      filterNewProps = {...filter};
      Object.keys(preChosenValues).forEach((key) => {
        filterNewProps[key] = filterNewProps[key] || preChosenValues[key];
      });

      const participated = filterNewProps.participated === 'true';
      if (this.state.participated !== participated) {
        this.setState({participated});
      }

      if (this.state.optInsOptions !== filterNewProps.opt_in) {
        this.setState({optInsOptions: filterNewProps.opt_in});
      }
    }
  }

  fetchPrizes = () => {
    return this.props.getPrizes({automation_task_id: this.props.taskId});
  };

  fetchLevelOptions = () => {
    const params = {
      q: {
        interface_automation_task_id_eq: this.props.taskId,
      },
    };
    const {interface: interfaceItem = []} = this.props.filter;
    if (Array.isArray(interfaceItem) && interfaceItem.length) {
      params.q.interface_id_eq = interfaceItem[0].value;
    }
    this.props.getLevelOptions(params);
  };

  fetchInteractions = () => {
    const commonParams = {
      q: {interaction_group_automation_task_id_eq: this.props.taskId},
    };

    this.props.getOnlineInteractions({
      ...commonParams,
      include: ['source'],
    });

    this.props.getOfflineInteractions({
      ...commonParams,
      include: ['place'],
    });
  };

  getOptInsOptions = async (search = '') => {
    if (!search) {
      return [];
    }
    const params = {
      include_opt_in_column_leads_count: null,
      q: {
        leads_count_gt: 0,
        client_id_eq: this.props.clientId,
        name_cont: search,
      },
    };
    if (this.props.taskId) {
      params.q.automation_task_id_eq = this.props.taskId;
    }
    const response = await this.props.getOptIns(params);
    const optIns = response.payload.opt_in_columns;
    const filteredOptIns = optIns.filter((optIn) => optIn.leads_count > 0).map((i) => ({label: i.name, value: i.id}));

    this.setState({
      optInsOptions: filteredOptIns,
    });

    return filteredOptIns;
  };

  getInterfaceOptions = async (search = '') => {
    if (!search) {
      return [];
    }
    const params = {
      q: {
        participations_count_qt: 0,
        client_id_eq: this.props.clientId,
        name_cont: search,
      },
    };

    if (this.props.taskId) {
      params.q.automation_task_id_eq = this.props.taskId;
    }
    await this.props.getInterfaces(params);
    return this.props.interfaces.map((interface_) => ({
      label: interface_.name,
      value: interface_.id,
    }));
  };

  getScenarioOptions = async (search = '') => {
    if (!search) {
      return [];
    }
    const params = {
      q: {
        name_cont: search,
      },
    };
    if (this.props.taskId) {
      params.q.automation_task_id_eq = this.props.taskId;
    }
    await this.props.getScenarios(params);
    return this.props.scenarios.map((scenario) => ({
      label: scenario.name,
      value: scenario.id,
    }));
  };

  formatDate = (date) => {
    return date ? new Date(`${date.substr(6, 4)}-${date.substr(3, 2)}-${date.substr(0, 2)}`) : null;
  };

  save = (rawData) => {
    const data = {...rawData};

    if (data.source) {
      data.source = {value: data.source.value, label: data.source.label};
    }

    if (data.showHidden === false) {
      delete data.showHidden;
    }

    if (data.showNotHidden === false) {
      delete data.showNotHidden;
    }

    this.setState(
      {
        participated: data.participated === 'true',
        optInRadio: data.optInRadio === 'true',
        participatedFrom: data.participated === 'true' ? this.formatDate(data.participatedFrom) : null,
      },
      () => {
        if (!this.state.participated) {
          delete data.participatedFrom;
          delete data.participatedTo;
        }
        if (!this.state.optInRadio) {
          delete data.m;
          delete data.opt_in;
        }
      },
    );
    this.props.onFilterChange(data);
  };

  handleChangeFilters = () => {
    Promise.resolve()
      .then(
        this.props.handleSubmit((params) => {
          return this.save({...params});
        }),
      )
      .then(this.fetchLevelOptions);
  };

  handleChangeHidden = () =>
    Promise.resolve().then(
      this.props.handleSubmit((params) =>
        this.save({
          ...params,
          showNotHidden: false,
        }),
      ),
    );

  handleChangeNotHidden = () =>
    Promise.resolve().then(
      this.props.handleSubmit((params) =>
        this.save({
          ...params,
          showHidden: false,
        }),
      ),
    );

  getPrizesOptions = () => {
    return this.props.prizes.map((item) => ({
      value: item.toLowerCase(),
      label: item,
    }));
  };

  getLevelsOptions = () => {
    return this.props.levelOptions.map((i) => ({value: i, label: i})).sort((item) => item.a - item.b);
  };

  getSourcesOptions = () => {
    const {onlineInteractions, offlineInteractions, lang} = this.props;
    const {onlineOptions, offlineOptions} = mapInteractionsToOptions(onlineInteractions, offlineInteractions);

    return [
      {value: '0', label: lang.TABLE_FILTERS_MODAL_NO_MATTER_RADIO},
      {label: lang.SOURCE, options: onlineOptions},
      {label: lang.DEVICE, options: offlineOptions},
    ];
  };

  render() {
    const {onClose, clientsList, participantsList, filter, show, lang} = this.props;
    const {participated, participatedFrom} = this.state;

    const clients = mapSelectList(clientsList.payload.data);
    clients.unshift({value: '0', label: lang.TABLE_FILTERS_MODAL_NO_MATTER_RADIO});

    const totalItems = get(participantsList, 'meta.total_count', 0);

    return (
      <Modal
        show={show}
        className="modal-window--width-1 modal-window--theme-4"
        onClose={onClose}
        title={lang.TABLE_FILTERS_MODAL_TITLE}
      >
        <form>
          <FormFieldWrap>
            <div className="form-field-wrap__group-title">{lang.TABLE_FILTERS_MODAL_ATTRIBUTES_LABEL}</div>
            <div className="filters__items">
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-3"
                label={lang.TABLE_FILTERS_MODAL_WITH_PHONE_RADIO}
                name="phone"
                type="checkbox"
                onChange={this.handleChangeFilters}
                value="phone"
                checkboxType="switcher-2"
                component={fieldTemplate}
              />
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-3"
                label={lang.TABLE_FILTERS_MODAL_WITH_EMAIL_RADIO}
                name="email"
                onChange={this.handleChangeFilters}
                type="checkbox"
                checkboxType="switcher-2"
                value="email"
                component={fieldTemplate}
              />
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-3"
                label={lang.TABLE_FILTERS_MODAL_WITH_VISUALS_RADIO}
                name="visuals"
                onChange={this.handleChangeFilters}
                type="checkbox"
                value="visuals"
                checkboxType="switcher-2"
                component={fieldTemplate}
              />
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-3"
                label={lang.SHOW_HIDDEN}
                name="showHidden"
                onChange={this.handleChangeHidden}
                type="checkbox"
                value="showHidden"
                checkboxType="switcher-2"
                component={fieldTemplate}
              />
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-3"
                label={lang.SHOW_NOT_HIDDEN}
                name="showNotHidden"
                onChange={this.handleChangeNotHidden}
                type="checkbox"
                value="showNotHidden"
                checkboxType="switcher-2"
                component={fieldTemplate}
              />
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-3"
                label={lang.WITHOUT_TEST}
                name="without_test"
                type="checkbox"
                onChange={this.handleChangeFilters}
                value="without_test"
                checkboxType="switcher-2"
                component={fieldTemplate}
              />
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-3"
                label={lang.PARTICIPATIONS_ONLY}
                name="participations_only"
                type="checkbox"
                onChange={this.handleChangeFilters}
                value="participations_only"
                checkboxType="switcher-2"
                component={fieldTemplate}
              />
            </div>
          </FormFieldWrap>
          <FormFieldWrap>
            <div className="form-field-wrap__group-title">{lang.TABLE_FILTERS_MODAL_PARTICIPATED_LABEL}</div>
            <div>
              <div className="filters__items">
                <Field
                  cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
                  label={lang.TABLE_FILTERS_MODAL_NO_MATTER_RADIO}
                  name="participated"
                  onChange={this.handleChangeFilters}
                  type="radio"
                  value="false"
                  radioType="switcher-2"
                  component={fieldTemplate}
                />
                <Field
                  cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
                  label={lang.TABLE_FILTERS_MODAL_TIME_PERIOD_RADIO}
                  name="participated"
                  onChange={this.handleChangeFilters}
                  type="radio"
                  value="true"
                  radioType="switcher-2"
                  component={fieldTemplate}
                />
              </div>
              <div className="form-field-wrap__group">
                <Field
                  cssModifier="datepicker--theme-color-4 filters__item filters__item--view-4"
                  label={lang.TABLE_FILTERS_MODAL_FROM_LABEL}
                  onChange={this.handleChangeFilters}
                  name="participatedFrom"
                  disabled={!participated}
                  type="datepicker"
                  component={fieldTemplate}
                />
                <Field
                  cssModifier="datepicker--theme-color-4 filters__item filters__item--view-4"
                  label={lang.TABLE_FILTERS_MODAL_TO_LABEL}
                  name="participatedTo"
                  onChange={this.handleChangeFilters}
                  disabled={!participated}
                  disabledDayBefore={participatedFrom}
                  type="datepicker"
                  component={fieldTemplate}
                />
              </div>
              <div className="form-field-wrap__group" style={{marginTop: '5px'}}>
                <Field
                  cssModifier="filters__item filters__item--view-4"
                  onBlur={this.handleChangeFilters}
                  name="participatedFromTime"
                  disabled={!participated}
                  type="text"
                  component={fieldTemplate}
                  placeholder={lang.TABLE_FILTERS_MODAL_TIME_PLACEHOLDER}
                />
                <Field
                  cssModifier="filters__item filters__item--view-4"
                  onBlur={this.handleChangeFilters}
                  name="participatedToTime"
                  disabled={!participated}
                  type="text"
                  component={fieldTemplate}
                  placeholder={lang.TABLE_FILTERS_MODAL_TIME_PLACEHOLDER}
                />
              </div>
            </div>
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              className="select--view-2"
              label={lang.TABLE_FILTERS_MODAL_SCENARIO_LABEL}
              name="scenario"
              onChange={this.handleChangeFilters}
              getOptions={this.getScenarioOptions}
              component={AsyncDropdownAny}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              className="select--view-2"
              label={lang.TABLE_FILTERS_MODAL_INTERFACE_LABEL}
              name="interface"
              onChange={this.handleChangeFilters}
              getOptions={this.getInterfaceOptions}
              component={AsyncDropdownAny}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              cssModifier="select--no-min-height select--view-6"
              label={lang.LEVEL}
              name="levels"
              type="select"
              searchable={false}
              component={fieldTemplate}
              options={this.getLevelsOptions()}
              onChange={this.handleChangeFilters}
              multi
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              cssModifier="select--no-min-height select--view-6"
              label={lang.TABLE_FILTERS_MODAL_SOURCE_LABEL}
              name="source"
              type="select"
              onChange={this.handleChangeFilters}
              searchable={false}
              options={this.getSourcesOptions()}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              cssModifier="select--no-min-height select--view-6"
              label={lang.PRIZE_LABEL}
              name="prize"
              type="select"
              searchable={false}
              component={fieldTemplate}
              options={this.getPrizesOptions()}
              onChange={this.handleChangeFilters}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <div className="form-field-wrap__group-title">{lang.OPTIN_LABEL}</div>
            <div className="filters__items">
              <Field
                cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
                label={lang.TABLE_FILTERS_MODAL_NO_MATTER_RADIO}
                name="optInRadio"
                onChange={this.handleChangeFilters}
                type="radio"
                radioType="switcher-2"
                value={ParticipantsFilterModal.optInValues.noMatter}
                component={fieldTemplate}
              />
              <Field
                cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
                label={lang.SELECT_OPTIN_LABEL}
                name="optInRadio"
                onChange={this.handleChangeFilters}
                type="radio"
                radioType="switcher-2"
                value={ParticipantsFilterModal.optInValues.custom}
                component={fieldTemplate}
              />
              <Field
                cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
                label={lang.NO_ACTIVE_RADIO}
                name="optInRadio"
                onChange={this.handleChangeFilters}
                type="radio"
                radioType="switcher-2"
                value={ParticipantsFilterModal.optInValues.noActive}
                component={fieldTemplate}
              />
            </div>
            <AsyncSelectField
              label={lang.SELECT_OPTIN_LABEL}
              disabled={!(filter.optInRadio === 'true')}
              name="opt_in"
              onChange={this.handleChangeFilters}
              fetchData={this.getOptInsOptions}
              options={this.state.optInsOptions}
              multi={true}
              clearable={true}
            />
          </FormFieldWrap>
          {filter.optInRadio === 'true' && (
            <div className="field-group__row field-group__row--vert-center">
              <div className="field-group__field">
                <div className="field-group__text">{lang.USE_BOOLEAN_LABEL}</div>
              </div>
              <div className="field-group__field">
                <Field
                  label={lang.OR_LABEL}
                  name="m"
                  value="or"
                  type="radio"
                  onChange={this.handleChangeFilters}
                  component={fieldTemplate}
                />
              </div>
              <div className="field-group__field">
                <Field
                  label={lang.AND_LABEL}
                  name="m"
                  value="and"
                  type="radio"
                  onChange={this.handleChangeFilters}
                  component={fieldTemplate}
                />
              </div>
            </div>
          )}
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-4 modal-window__footer-btn" type="button" onClick={onClose}>
              {`${lang.TABLE_FILTERS_MODAL_CONFIRM_BUTTON} (${totalItems})`}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const ParticipantsFilterModalForm = reduxForm({
  form: ParticipantsFilterModal.formName,
  enableReinitialize: true,
})(ParticipantsFilterModal);

export default connect(
  (state) => {
    return {
      lang: state.languageState.payload.PARTICIPANTS_LIST,
      initialValues: filterNewProps,
      clientsList: state.clientsList,
      participantsList: state.participations.participationTableData,
      operations: selectOperationsForFilterModal(state),
      autotasks: selectAutotasksForFilterModal(state),
      optIns: selectOptInsForFilterModal(state),
      interfaces: state.interfaces.interfaces,
      scenarios: state.scenario.list,
      prizes: selectPrizes(state),
      levelOptions: selectLevelOptions(state),
      onlineInteractions: selectOnlineInteractions(state),
      offlineInteractions: selectOfflineInteractions(state),
    };
  },
  {
    getOptIns: getOptInsAction,
    getInterfaces: getInterfacesAction,
    getScenarios,
    getPrizes,
    getLevelOptions,
    getOnlineInteractions: fetchOnlineInteractions,
    getOfflineInteractions: fetchOfflineInteractions,
  },
)(ParticipantsFilterModalForm);
