import {useMemo} from 'react';

import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import {useParams} from 'react-router-dom';

import {useReduxFetch} from 'client/services/hooks';

import {getDeviceAffectationsForFilers} from 'client/ducks/device-affectation/actions';

import {Autotask} from 'client/models/autotask/types';
import {DeviceAffectation} from 'client/models/device-affectations';

import {ClientDeviceFiltersFormValues} from './index';

export default (formFields: ClientDeviceFiltersFormValues) => {
  const {clientId} = useParams<{clientId: string}>();

  const {data, loading} = useReduxFetch<{device_affectations: DeviceAffectation[]}>({
    action: getDeviceAffectationsForFilers,
    actionArgs: {
      include: {
        place: null,
        place_region_name: null,
        device: {
          current_interaction: {
            automation_task: null,
          },
        },
      },
      q: {
        client_id_eq: clientId,
      },
    },
  });

  const regions = useMemo(() => {
    const result =
      uniqBy(
        data?.device_affectations
          .filter((a) => !formFields.place?.length || (a.place && formFields.place.includes(a.place.id.toString())))
          .map((affectation) => ({
            label: affectation.place?.region_name,
            value: affectation.place?.region?.id?.toString(),
          }))
          .filter(({value}) => value),
        'value',
      ) || [];

    return result;
  }, [data?.device_affectations, formFields.place]);

  const stores = useMemo(() => {
    const result =
      uniqBy(
        data?.device_affectations
          .filter(
            (a) =>
              a.place?.id &&
              (!formFields.region?.length || formFields.region?.includes(a.place.region?.id.toString() || '')),
          )
          ?.map((affectation) => ({
            label: affectation.place!.name,
            value: affectation.place!.id.toString(),
          }))
          .filter(({value}) => value),
        'value',
      ) || [];
    return result;
  }, [data?.device_affectations, formFields.region]);

  const task = useMemo(() => {
    const autotasks: Autotask[] =
      data?.device_affectations
        .filter((a) => a.device?.current_interaction?.automation_task)
        .map((affectation) => affectation.device!.current_interaction!.automation_task!) || [];

    return (
      uniqBy(
        autotasks
          ?.map((autotask) => ({
            label: autotask.name,
            value: autotask.id.toString(),
          }))
          .filter(({value}) => value),
        'value',
      ) || []
    );
  }, [data]);

  return {
    loading,
    regions: sortBy(regions, (el) => el.value),
    stores: sortBy(stores, (el) => el.value),
    task: sortBy(task, (el) => el.value),
  };
};
