import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

function Input({
  disabled,
  placeholder,
  label,
  cssModifier,
  style,
  value,
  name,
  type,
  error,
  onChange,
  onFocus,
  onBlur,
}) {
  const cssClass = classNames({
    input: true,
    'input--error': !!error,
  });

  return (
    <div className={`${cssClass} ${cssModifier}`} style={style}>
      <label className="input__inner">
        {label && <div className="input__label">{label}</div>}
        <div>
          <input
            className="input__field"
            placeholder={placeholder?.toString()}
            disabled={disabled}
            name={name}
            value={value}
            type={type}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
      </label>
      {error && <p className="input__message">{error}</p>}
    </div>
  );
}

Input.defaultProps = {
  disabled: false,
  placeholder: '',
  label: '',
  cssModifier: '',
  style: {},
  value: '',
  pattern: null,
  name: '',
  type: 'text',
  error: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
};

Input.propTypes = {
  disabled: PropTypes.bool,
  placeholder: TranslationJsx,
  label: PropTypes.any,
  cssModifier: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  pattern: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.any,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default Input;
