import React from 'react';

import PropTypes from 'prop-types';

import ProfileInfo from './profile-info';
import ProfileOpt from './profile-opt';
import ProfilePassword from './profile-password';
import ProfilePolicies from './profile-policies';

import './profile-client.scss';

const ProfileClient = (props) => {
  const {user, onEditPhotoClick, children, updateProfile, editPassword} = props;

  if (!user) {
    return null;
  }

  return (
    <div className="profile-client">
      <div className="profile-client__row">
        <ProfileInfo user={user} onEditPhotoClick={onEditPhotoClick} updateProfile={updateProfile}>
          {children}
        </ProfileInfo>
        <ProfilePassword user={user} editPassword={editPassword} />
      </div>
      <div className="profile-client__row">
        <ProfileOpt user={user} updateProfile={updateProfile} />
        <ProfilePolicies />
      </div>
    </div>
  );
};

ProfileClient.propTypes = {
  user: PropTypes.object,
  children: PropTypes.any,
  editPassword: PropTypes.bool,
  onEditPhotoClick: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
};

ProfileClient.defaultProps = {
  children: null,
  user: null,
  editPassword: false,
};

export default ProfileClient;
