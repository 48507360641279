import {isEmptyString} from 'client/services/validator';

import {Translation} from 'client/models/language/types';

import {DiyOpTemplateValues, DiyOpTemplateErrors, DiyOpTemplateMode} from './types';

export default (
  values: DiyOpTemplateValues,
  lang: Record<string, Translation>,
  params: {isNameUnique: boolean; initialName: string; mode: DiyOpTemplateMode},
) => {
  const errors: DiyOpTemplateErrors = {languages: {}, broadcasts: {}};
  const {isNameUnique, initialName, mode} = params;

  if (isEmptyString(values.name)) {
    errors.name = lang.REQUIRED;
  } else {
    const currentName = values.name.trim().toLowerCase();
    if ((['duplicate', 'share'].includes(mode) || currentName !== initialName) && !isNameUnique) {
      errors.name = lang.UNIQUE_NAME;
    }
  }

  if (Object.values(values.languages).filter((i) => i).length === 0) {
    errors.languages[Object.keys(values.languages)[0]] = lang.REQUIRED;
  }

  if (Object.values(values.broadcasts).filter((i) => i).length === 0) {
    errors.broadcasts[Object.keys(values.broadcasts)[0]] = lang.REQUIRED;
  }

  if (!values.icon) {
    errors.icon = lang.REQUIRED;
  }

  return errors;
};
