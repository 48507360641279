import React from 'react';

import {generatePath} from 'react-router';
import {Link} from 'react-router-dom';
import {useToggle} from 'react-use';
import {routePaths} from 'routes/index';

import bem from 'client/services/bem';
import {dateToString} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';

import Icon from 'client/components/common/icon';

import LeadsOptinSettingsModal from 'client/components/leads/leads-parameters/leads-optins/leads-optin-settings-modal';
import LeadOptinModal from 'client/components/leads/modals/lead-optin-modal';
import {OPT_IN_COLUMN_TYPES} from 'client/models/opt-in-column/constants';
import {OptInColumn} from 'client/models/opt-in-column/types';

import cssModule from './optin-item.module.scss';

const b = bem('optin-item', {cssModule});

type OptinItemProps = {
  item: OptInColumn;
  canManage: boolean;
  fetchData: () => Promise<any>;
};

const OptinItem: React.FC<OptinItemProps> = (props) => {
  const {item, canManage, fetchData} = props;
  const lang = useLanguage('LEADS_PARAMETERS.LEADS_OPTINS.OPTIN_ITEM');
  const [showInfoModal, toggleInfoModal] = useToggle(false);
  const [showOptinModal, toggleOptinModal] = useToggle(false);

  const getLeadsLink = () => {
    const label = encodeURIComponent(item.name);
    const path = generatePath(routePaths.client.LeadsDatabasePage, {clientId: item.client_id});
    const filter = `?filter={"optInRadio":"true","optIn":[{"value":${item.id},"label":"${label}"}]}`;
    return path + filter;
  };

  const optinTypeMap = {
    [OPT_IN_COLUMN_TYPES.STRUCTURAL]: lang.OPT_IN_TYPES.STRUCTURAL,
    [OPT_IN_COLUMN_TYPES.PUNCTUAL]: lang.OPT_IN_TYPES.PUNCTUAL,
    [OPT_IN_COLUMN_TYPES.EXTERNAL_CLIENT]: lang.OPT_IN_TYPES.EXTERNAL_CLIENT,
  };

  return (
    <div className={b()}>
      {showInfoModal && <LeadsOptinSettingsModal optInColumn={item} onClose={toggleInfoModal} />}
      {showOptinModal && <LeadOptinModal optinColumn={item} onClose={toggleOptinModal} onSave={fetchData} />}
      <div className={b('top')}>
        <AppButton
          className={b('name-button')}
          label={<p className={b('name')}>{item.name}</p>}
          title={item.name}
          onClick={canManage ? toggleOptinModal : toggleInfoModal}
          asWrap
        />
        <div className={b('count')}>
          <Icon name="lead" />
          <Link to={getLeadsLink()} className={b('link')}>
            {item.leads_count}
          </Link>
        </div>
      </div>
      <div className={b('row', ['first'])}>
        <p>
          <span className={b('label')}>{lang.LEVEL}</span> <span className={b('value')}>{item.level}</span>
        </p>
        <p>
          <span className={b('label')}>{lang.CODE}</span> <span className={b('value')}>{item.code}</span>
        </p>
      </div>
      <div className={b('row')}>
        <div>
          <p className={b('label')}>{lang.OPT_IN_TYPE}</p>
          <p className={b('label')}>{lang.CREATED}</p>
        </div>
        <div>
          <p className={b('value')}>{optinTypeMap[item.opt_in_type]}</p>
          <p className={b('value')}>{dateToString(item.created_at, 'DD/MM/YYYY')}</p>
        </div>
      </div>
    </div>
  );
};

export default OptinItem;
