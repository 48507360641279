import React from 'react';

import {useDispatch} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {deleteTrackingUser} from 'client/ducks/client-account/actions';

import {useToast} from 'client/common/hooks/useToast';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import {Translation} from 'client/models/language/types';
import {Membership} from 'client/models/memberships/types';

type AccountUsersRemoveCellProps = {
  membership: Membership | null;
  onDeactivated: () => void;
  onClose: () => void;
};

const boldGap = (text: Translation, value: string, gap = 'user') => {
  const parts = text.toString().split(`{{${gap}}}`);
  return (
    <span>
      {parts[0]}
      <span style={{fontWeight: '600'}}>{value}</span>
      {parts[1]}
    </span>
  );
};

const DeleteTrackingUserModal: React.FC<AccountUsersRemoveCellProps> = (props) => {
  const {membership, onDeactivated, onClose} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('CLIENT_ACCOUNT_PARAMETERS.MODALS.DELETE_TRACKING_USER_MODAL');
  const {appendToastNotification} = useToast();

  const name = `${membership?.prize_tracking_user?.full_name} ${membership?.prize_tracking_user?.login}`;

  const handleDelete = async (id: number, user: string) => {
    try {
      await dispatch(deleteTrackingUser(id));
      onDeactivated();
      appendToastNotification({
        type: 'success',
        description: boldGap(lang.SUCCESS_MESSAGE, user),
      });
    } catch {
      appendToastNotification({
        type: 'error',
        description: boldGap(lang.ERROR_MESSAGE, user),
        actions: [
          {
            label: lang.RETRY,
            onClick: () => handleDelete(id, user),
          },
        ],
      });
    }
    onClose();
  };

  return (
    <ConfirmationModal
      show={!!membership}
      title={lang.TITLE}
      message={boldGap(lang.MESSAGE, name)}
      cancelText={lang.CANCEL}
      confirmText={lang.DELETE}
      buttonConfirmColor="error"
      onCancel={onClose}
      onConfirm={() => membership?.prize_tracking_user_id && handleDelete(membership.prize_tracking_user_id, name)}
      clientSide
    />
  );
};

export default DeleteTrackingUserModal;
