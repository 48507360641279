import React from 'react';

import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {change, Field, reduxForm} from 'redux-form';

import {getValueIfExists} from 'client/services/helpers';
import {required} from 'client/services/validator';

import {getInterfacesAction} from 'client/ducks/interfaces/actions';
import {selectInterfacesWithCodes} from 'client/ducks/interfaces/selectors';
import {getScenarios} from 'client/ducks/scenario/actions';

import {RUN_TABLE_STATUS_COLORS} from 'client/common/config';
// components
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

import './run-table-filters-modal.scss';

class RunTableFiltersModal extends ReactQueryParams {
  static propTypes = {
    show: PropTypes.bool,
    globalView: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    totalItems: PropTypes.number,
    autotaskId: PropTypes.number,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    getTriggeredScenarios: PropTypes.func.isRequired,
    getInterfaces: PropTypes.func.isRequired,
    triggeredScenarios: PropTypes.array,
    interfaces: PropTypes.array,
    onClose: PropTypes.func,
    updateMe: PropTypes.func,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    show: false,
    totalItems: 0,
    triggeredScenarios: [],
    interfaces: [],
    onClose: () => {},
    updateMe: () => {},
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.FILTERS_MODAL;
    this.LANGUAGE_SCENARIO_STEPS = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.SCENARIO_STEPS;
    this.LANGUAGE_STATUSES = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.STATUSES;

    const now = moment(moment().format().split('T')[0] + 'T00:00:00');
    this.initialValues = {
      start_date_type: {value: 'ANY'},
      end_date_type: {value: 'ANY'},
      start_date_value: now,
      end_date_value: now,
    };

    this.rules = {
      date: [required(this.LANGUAGE.DATE_IS_REQUIRED_ERROR)],
    };
  }

  componentDidUpdate(prevProps) {
    const {show, formValues} = this.props;

    if (show && !prevProps.show) {
      const {filters = {}} = this.queryParams;
      const initialValues = {};

      if (filters.startDate) {
        initialValues.start_date_type = {
          value: filters.startDate.type,
        };
        initialValues.start_date_value = moment(filters.startDate.value);
      }
      if (filters.endDate) {
        initialValues.end_date_type = {
          value: filters.endDate.type,
        };
        initialValues.end_date_value = moment(filters.endDate.value);
      }

      if (filters.runningSteps) {
        filters.runningSteps.forEach((i) => {
          initialValues[`scenario_step_implementation_class_name_in$${i}`] = true;
        });
      }
      if (filters.statuses) {
        filters.statuses.forEach((i) => {
          switch (i) {
            case 'sleeping':
              break;
            case 'performing':
              initialValues.state_in$sleeping$performing = true;
              break;
            default:
              initialValues[`state_in$${i}`] = true;
              break;
          }
        });
      }

      initialValues.triggeredScenarios = filters.triggeredScenarios;
      initialValues.interfaces = filters.interfaces;

      this.props.initialize({
        ...this.initialValues,
        ...initialValues,
      });

      const params = this.props.globalView
        ? {
            q: {
              automation_task_id_not_null: true,
            },
          }
        : {
            q: {
              automation_task_id_eq: this.props.autotaskId,
            },
          };

      this.props.getTriggeredScenarios(params);
      this.props.getInterfaces(params);
    }

    if (formValues !== prevProps.formValues) {
      const scenarios = getValueIfExists(formValues, ['values', 'triggeredScenarios']);
      if (scenarios && !Array.isArray(scenarios) && isEmpty(scenarios)) {
        this.props.change('triggeredScenarios', []);
      }

      const interfaces = getValueIfExists(formValues, ['values', 'interfaces']);
      if (interfaces && !Array.isArray(interfaces) && isEmpty(interfaces)) {
        this.props.change('interfaces', []);
      }
    }
  }

  handleChangeFilters = (formValues) => {
    const filters = {};

    if (
      formValues.start_date_type &&
      formValues.start_date_type.value !== 'ANY' &&
      formValues.start_date_value &&
      moment(formValues.start_date_value).isValid()
    ) {
      filters.startDate = {
        type: formValues.start_date_type.value,
        value: formValues.start_date_value.format().split('+')[0],
      };
    }
    if (
      formValues.end_date_type &&
      formValues.end_date_type.value !== 'ANY' &&
      formValues.end_date_value &&
      moment(formValues.end_date_value).isValid()
    ) {
      filters.endDate = {
        type: formValues.end_date_type.value,
        value: formValues.end_date_value.format().split('+')[0],
      };
    }

    const runningSteps = [];
    let statuses = [];
    for (let key in formValues) {
      if (key.startsWith('scenario_step_implementation_class_name_in') && formValues[key]) {
        runningSteps.push(key.split('$')[1]);
      } else if (key.startsWith('state_in') && formValues[key]) {
        statuses = statuses.concat(key.split('$').slice(1));
      }
    }

    if (runningSteps.length > 0) {
      filters.runningSteps = runningSteps;
    }
    if (statuses.length > 0) {
      filters.statuses = statuses;
    }

    if (formValues.triggeredScenarios && formValues.triggeredScenarios.length > 0) {
      filters.triggeredScenarios = formValues.triggeredScenarios;
    }
    if (formValues.interfaces && formValues.interfaces.length > 0) {
      filters.interfaces = formValues.interfaces;
    }

    this.setQueryParams({filters, page: 1}, true);
    setTimeout(() => this.props.updateMe(filters), 500);
  };

  render() {
    const {LANGUAGE, LANGUAGE_SCENARIO_STEPS, LANGUAGE_STATUSES} = this;
    const {show, onClose, totalItems, handleSubmit, formValues, triggeredScenarios, interfaces, valid} = this.props;

    const dateOptions = [
      {
        value: 'FROM',
        label: LANGUAGE.FROM,
      },
      {
        value: 'TILL',
        label: LANGUAGE.TILL,
      },
      {
        value: 'ANY',
        label: LANGUAGE.ANY,
      },
    ];

    const showStartDate = ['FROM', 'TILL'].includes(
      getValueIfExists(formValues, ['values', 'start_date_type', 'value']),
    );
    const showEndDate = ['FROM', 'TILL'].includes(getValueIfExists(formValues, ['values', 'end_date_type', 'value']));

    const debounceSubmit = () => {
      if (!this.timeoutID) {
        this.timeoutID = setTimeout(() => {
          handleSubmit(this.handleChangeFilters)();
          this.timeoutID = 0;
        }, 500);
      }
    };

    return (
      <Modal
        show={show}
        onClose={valid ? onClose : () => {}}
        title={LANGUAGE.TITLE}
        dialogClassName="modal-window--width-1 theme-color-9 run-table-filters-modal"
      >
        <form>
          <div className="form-field-wrap form-field-wrap--margin-1">
            <div className="form-field-wrap__group-title">{LANGUAGE.START_DATE_LABEL}</div>
            <div className="filters__items">
              <Field
                cssModifier={
                  'select--no-min-height filters__item filters__item' + (showStartDate ? '--view-5' : '--expanded')
                }
                name="start_date_type"
                type="select"
                onChange={debounceSubmit}
                options={dateOptions}
                component={fieldTemplate}
              />
              {showStartDate && (
                <Field
                  cssModifier="filters__item filters__item--view-3"
                  name="start_date_value"
                  type="datetimepicker"
                  onChange={debounceSubmit}
                  component={fieldTemplate}
                  validate={this.rules.date}
                />
              )}
            </div>
          </div>
          <div className="form-field-wrap form-field-wrap--margin-1">
            <div className="form-field-wrap__group-title">{LANGUAGE.END_DATE_LABEL}</div>
            <div className="filters__items">
              <Field
                cssModifier={
                  'select--no-min-height filters__item filters__item' + (showEndDate ? '--view-5' : '--expanded')
                }
                name="end_date_type"
                type="select"
                onChange={debounceSubmit}
                options={dateOptions}
                component={fieldTemplate}
              />
              {showEndDate && (
                <Field
                  cssModifier="filters__item filters__item--view-3"
                  name="end_date_value"
                  type="datetimepicker"
                  onChange={debounceSubmit}
                  component={fieldTemplate}
                  validate={this.rules.date}
                />
              )}
            </div>
          </div>
          <div className="form-field-wrap form-field-wrap--margin-1">
            <div className="form-field-wrap__group-title">{LANGUAGE.CURRENTLY_RUNNING_STEPS_LABEL}</div>
            <div className="filters__items">
              {Object.keys(LANGUAGE_SCENARIO_STEPS).map((key, index) => (
                <Field
                  key={index}
                  cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-3"
                  label={LANGUAGE_SCENARIO_STEPS[key]}
                  name={`scenario_step_implementation_class_name_in$${key}`}
                  type="checkbox"
                  checkboxType="switcher-2"
                  onChange={debounceSubmit}
                  component={fieldTemplate}
                />
              ))}
            </div>
          </div>
          <div className="form-field-wrap form-field-wrap--margin-1">
            <div className="form-field-wrap__group-title">{LANGUAGE.STATUS_LABEL}</div>
            <div className="filters__items">
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-3"
                label={
                  <div>
                    <span
                      className="status-icon"
                      style={{
                        color: RUN_TABLE_STATUS_COLORS.sleeping,
                      }}
                    />
                    {LANGUAGE_STATUSES.sleeping}
                  </div>
                }
                name="state_in$sleeping$performing"
                type="checkbox"
                checkboxType="switcher-2"
                onChange={debounceSubmit}
                component={fieldTemplate}
              />
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-3"
                label={
                  <div>
                    <span
                      className="status-icon"
                      style={{
                        color: RUN_TABLE_STATUS_COLORS.failed,
                      }}
                    />
                    {LANGUAGE_STATUSES.failed}
                  </div>
                }
                name="state_in$failed"
                type="checkbox"
                checkboxType="switcher-2"
                onChange={debounceSubmit}
                component={fieldTemplate}
              />
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-3"
                label={
                  <div>
                    <span
                      className="status-icon"
                      style={{
                        color: RUN_TABLE_STATUS_COLORS.stopped,
                      }}
                    />
                    {LANGUAGE_STATUSES.stopped}
                  </div>
                }
                name="state_in$stopped"
                type="checkbox"
                checkboxType="switcher-2"
                onChange={debounceSubmit}
                component={fieldTemplate}
              />
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-3"
                label={
                  <div>
                    <span
                      className="status-icon"
                      style={{
                        color: RUN_TABLE_STATUS_COLORS.finished,
                      }}
                    />
                    {LANGUAGE_STATUSES.finished}
                  </div>
                }
                name="state_in$finished"
                type="checkbox"
                checkboxType="switcher-2"
                onChange={debounceSubmit}
                component={fieldTemplate}
              />
            </div>
          </div>

          <div className="form-field-wrap form-field-wrap--margin-1 multi-select">
            <Field
              cssModifier="select--no-min-height"
              label={LANGUAGE.TRIGGERED_SCENARIOS_LABEL}
              name="triggeredScenarios"
              type="select"
              multi
              onChange={debounceSubmit}
              options={triggeredScenarios}
              valueKey="id"
              labelKey="code"
              component={fieldTemplate}
            />
          </div>

          <div className="form-field-wrap form-field-wrap--margin-1 multi-select">
            <Field
              cssModifier="select--no-min-height"
              label={LANGUAGE.INTERFACES_LABEL}
              name="interfaces"
              type="select"
              multi
              onChange={debounceSubmit}
              options={interfaces}
              valueKey="id"
              labelKey="code"
              component={fieldTemplate}
            />
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button
              className="button button--bg-4 modal-window__footer-btn"
              type="button"
              onClick={valid ? onClose : () => {}}
            >
              {`${LANGUAGE.SHOW_RESULTS_BUTTON} (${totalItems})`}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const RunTableFiltersModalForm = reduxForm({
  form: 'RunTableFiltersModalForm',
})(RunTableFiltersModal);

export default connect(
  ({languageState, form, scenario, ...state}) => ({
    languageState,
    formValues: form.RunTableFiltersModalForm,
    triggeredScenarios: scenario.list,
    interfaces: selectInterfacesWithCodes(state),
  }),
  {
    getTriggeredScenarios: getScenarios,
    getInterfaces: getInterfacesAction,
    change,
  },
)(RunTableFiltersModalForm);
