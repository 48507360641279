import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {selectIsAdmin, selectIsUserLoaded} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import PlacesList from '../components/places';

class PlacesPage extends Component {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.setMetaAction({
      title: this.props.languageState.payload.PLACES.PLACES_TITLE,
    });
  }

  render() {
    const {isUserLoaded, isAdmin} = this.props;

    if (!isUserLoaded) {
      return null;
    }

    if (!isAdmin) {
      return <Redirect to={CLIENT_PAGES.HOME} />;
    }

    return <PlacesList />;
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  isAdmin: selectIsAdmin(state),
  isUserLoaded: selectIsUserLoaded(state),
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlacesPage);
