import PropTypes from 'prop-types';

import {TranslationElement} from 'client/services/translation-element';

import enTranslations from 'client/languages/en.json';
import {ReactNode} from 'client/types';

export type TranslationsSourceData = typeof enTranslations;

export type InjectTranslations<T> = {
  [K in keyof T]: T[K] extends string
    ? TranslationElement
    : T[K] extends string[]
      ? string[]
      : T[K] extends object
        ? InjectTranslations<T[K]>
        : never;
};

export type Translation = (TranslationElement | string[]) | string;

export type Translations = InjectTranslations<TranslationsSourceData>;

export const TranslationJsx = PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(ReactNode)]);

export type Language = {
  id: number;
  name: string;
  full_name: string;
  active: boolean;
  type: string;
  created_at: string;
  updated_at: string;
};
