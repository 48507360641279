import React, {useEffect, useMemo, useState} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {dateToString} from 'client/services/helpers';
import {useLanguage, useQueryParams, useReduxFetch} from 'client/services/hooks';

import {getColumnVisibilityItems} from 'client/ducks/lead-display-items/actions';
import {selectColumnVisibilityItemsMapped} from 'client/ducks/lead-display-items/selectors';
import {getLeads, getLeadsWithImported} from 'client/ducks/leads-list/actions';
import {selectLeadsTable} from 'client/ducks/leads-list/selectors';

import PaginationBar from 'client/common/paginations/pagination-bar';

import Icon from 'client/components/common/icon';

import DatetimeCell from 'client/components/tables/common/cells/datetime-cell';

import {getFetchLeadsParams} from './helpers';
import LeadInfoModal from './lead-info-modal';

import OptinsCell from '../cells/optins-cell';
import ScrollableTable from '../tables/scrollable-table';

import './data-leads.scss';

export const ContactsCell = ({item}) => {
  return (
    <div style={{display: 'flex', gap: 20}}>
      {item.phone && <Icon name="phones" className="visual-lead-cell__icon" />}
      {item.email && <Icon name="email" className="visual-lead-cell__icon" />}
    </div>
  );
};

ContactsCell.propTypes = {
  item: PropTypes.object,
};

const DataLeads = (props) => {
  const {onPageChange, autotaskId, clientId, onSetTotal, pages, sortParams, searchString, filters, onSortChange} =
    props;

  const language = useLanguage('DATA_TAB.LEADS_TABLE');
  const [leadInfo, setLeadInfo] = useState(null);

  const withImported = !autotaskId && !get(filters, 'participated', false);

  const [queryParams] = useQueryParams();

  const {data: initialColumns, loading: loadingColumns} = useReduxFetch({
    action: getColumnVisibilityItems,
    selector: selectColumnVisibilityItemsMapped,
    skip: !clientId,
    actionArgs: [clientId, ['column_adapter']],
  });

  const {data: leads, loading: loadingLeads} = useReduxFetch({
    action: withImported ? getLeadsWithImported : getLeads,
    selector: selectLeadsTable,
    actionArgs: getFetchLeadsParams({
      search: searchString,
      filters: JSON.parse(queryParams.filter || '{}'),
      autotaskId,
      sortParams,
      clientId,
      pages,
      withImported,
    }),
  });
  const leadsMeta = useSelector((state) => state.leadsList.payload.meta);

  const loading = loadingColumns || loadingLeads;

  useEffect(() => {
    if (leadsMeta) {
      onSetTotal(leadsMeta.total_count);
    }
  }, [onSetTotal, leadsMeta.total_count, leadsMeta]);

  const leadModalHandler = (info) => {
    setLeadInfo(info || null);
  };

  const additionalColumns = useMemo(
    () => [
      {
        name: 'contacts',
        label: 'Contacts',
        render: ContactsCell,
      },
      {
        name: 'participations',
        label: 'Participations',
        subcolumns: [
          {
            name: 'latest_participated_at',
            label: language.LAST,
            path: 'participations_count.participations[0].operation_code',
            sortable: true,
            render: ({item}) => {
              return (
                <p className="data-leads__latest-participated-at">
                  <span className="data-leads__latest-participated-at-name">{item?.operation_name}</span>
                  <span className="data-leads__latest-participated-at-date">
                    {dateToString(item?.last_participation)}
                  </span>
                </p>
              );
            },
          },
          {
            name: 'participations_count',
            label: language.ALL,
            path: 'participations_count',
            sortable: true,
          },
        ],
      },
      {
        name: 'visuals_count',
        label: language.VISUALS,
        path: 'visuals_count',
        sortable: true,
      },
      {
        name: 'created_at',
        label: language.CREATED,
        path: 'created',
        render: DatetimeCell,
        sortable: true,
      },
    ],
    [language],
  );

  const columns = useMemo(
    () => [
      {
        name: 'card',
        label: language.CARD,
        // eslint-disable-next-line react/prop-types
        render: ({item}) => (
          <Icon name="card-2" className="visual-lead-cell__icon" onClick={() => leadModalHandler(item)} />
        ),
      },
      ...(initialColumns?.map((col) => {
        const column = {
          ...col,
          label: `${language[col.name.toUpperCase()] || col.label}`,
          sortable: true,
        };
        if (column.kind === 'date') {
          column.render = DatetimeCell;
        }

        if (column.name === 'opt_ins') {
          column.render = OptinsCell;
        }

        if (column.name === 'last_export') {
          column.name = 'last_export_created_at';
        }

        if (column.name === 'first_source') {
          column.name = 'first_source_created_at';
        }

        return column;
      }) || []),
      ...additionalColumns,
    ],
    [additionalColumns, language, initialColumns],
  );

  const isLeadModalActive = !!leadInfo;

  return (
    <div className="data-leads">
      <ScrollableTable
        columns={columns}
        data={leads}
        sortField={sortParams.sortField}
        sortOrder={sortParams.sortOrder}
        onSortChange={onSortChange}
        scrollbarProps={{
          autoHeight: true,
          autoHeightMax: 'initial',
        }}
        loading={loading}
      />
      <PaginationBar
        onPageChange={onPageChange}
        data={leads}
        totalPages={leadsMeta.total_pages}
        totalItems={leadsMeta.total_count}
        perPage={pages.perPage}
        currentPage={leadsMeta.current_page}
        loading={loading}
      />
      {isLeadModalActive && (
        <LeadInfoModal leadId={leadInfo.id} leadInfo={leadInfo} onClose={() => leadModalHandler()} />
      )}
    </div>
  );
};

DataLeads.propTypes = {
  autotaskId: PropTypes.number,
  clientId: PropTypes.number,
  searchString: PropTypes.string,
  onSetTotal: PropTypes.func.isRequired,
  pages: PropTypes.shape({}),
  sortParams: PropTypes.shape({}),
  filters: PropTypes.shape({}),
};

export default DataLeads;
