import React from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import remove from 'lodash/remove';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {Field} from 'redux-form';

import fieldTemplate from 'client/components/common/field';

class VisFiltersForm extends ReactQueryParams {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    langStatuses: PropTypes.object.isRequired,
    langVisibleStatuses: PropTypes.object.isRequired,
    langOrientations: PropTypes.object.isRequired,
    initFilters: PropTypes.func.isRequired,
    updateMe: PropTypes.func,
    isAdmin: PropTypes.bool.isRequired,
  };

  static EXT_MAP = {
    jpg: ['jpeg'],
    mpeg: ['mp4'],
  };

  handleChangeFilters = ({target: {name, checked}}) => {
    const extMap = VisFiltersForm.EXT_MAP;
    const {filters = {}} = this.queryParams;
    const oldFilters = cloneDeep(filters);

    const filterName = name.split('$')[0];
    const filterValue = name.split('$')[1];
    if (!filters[filterName]) {
      filters[filterName] = [];
    }

    if (checked) {
      switch (filterName) {
        case 'comment_present':
        case 'participation_lead_id_null':
        case 'author_avatar_null':
        case 'group':
          filters[filterName] = filterValue;
          this.props.initFilters(filters);
          break;

        case 'file_extension_in':
          if (extMap[filterValue]) {
            filters[filterName].push(filterValue, ...extMap[filterValue]);
            break;
          }
          filters[filterName].push(filterValue);
          break;

        default:
          filters[filterName].push(filterValue);
          break;
      }
    } else {
      switch (filterName) {
        case 'comment_present':
        case 'participation_lead_id_null':
        case 'author_avatar_null':
        case 'group':
          delete filters[filterName];
          break;

        default:
          remove(filters[filterName], (i) => i === filterValue);
          if (extMap[filterValue]) {
            remove(filters[filterName], (i) => extMap[filterValue].includes(i));
          }
          break;
      }
    }

    const page = isEqual(oldFilters, filters) ? this.queryParams.page : 1;

    this.setQueryParams({filters, page}, true);
    this.props.updateMe(this.queryParams);
  };

  render() {
    const {lang, langStatuses, langOrientations, langVisibleStatuses} = this.props;
    const {isAdmin} = this.props;

    return (
      <div>
        <div className="form-field-wrap form-field-wrap--margin-1">
          <div className="form-field-wrap__group-title">{lang.FILE_TYPE_LABEL}</div>
          <div className="filters__items">
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.FILE_TYPES.image}
              name="kind_in$image"
              type="checkbox"
              checkboxType="switcher-2"
              onChange={this.handleChangeFilters}
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.FILE_TYPES.video}
              name="kind_in$video"
              type="checkbox"
              checkboxType="switcher-2"
              onChange={this.handleChangeFilters}
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.FILE_TYPES.text}
              name="kind_in$text"
              type="checkbox"
              checkboxType="switcher-2"
              onChange={this.handleChangeFilters}
              component={fieldTemplate}
            />
          </div>
        </div>
        <div className="form-field-wrap form-field-wrap--margin-1">
          <div className="form-field-wrap__group-title">{lang.FILE_EXTENTION_LABEL}</div>
          <div className="filters__items">
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.FILE_EXTENTION_JPEG}
              name="file_extension_in$jpg"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.FILE_EXTENTION_PNG}
              name="file_extension_in$png"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.FILE_EXTENTION_TIFF}
              name="file_extension_in$tiff"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.FILE_EXTENTION_AVI}
              name="file_extension_in$avi"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.FILE_EXTENTION_MOV}
              name="file_extension_in$mov"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.FILE_EXTENTION_WMV}
              name="file_extension_in$wmv"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.FILE_EXTENTION_MPEG}
              name="file_extension_in$mpeg"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.FILE_EXTENTION_GIF}
              name="file_extension_in$gif"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
          </div>
        </div>
        {isAdmin && (
          <div className="form-field-wrap form-field-wrap--margin-1">
            <div className="form-field-wrap__group-title">{lang.STATUS_LABEL}</div>
            <div className="filters__items">
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
                label={langStatuses.validated}
                name="status_in$validated"
                type="checkbox"
                onChange={this.handleChangeFilters}
                checkboxType="switcher-2"
                component={fieldTemplate}
              />
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
                label={langStatuses.refused}
                name="status_in$refused"
                type="checkbox"
                onChange={this.handleChangeFilters}
                checkboxType="switcher-2"
                component={fieldTemplate}
              />
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
                label={langStatuses.canceled}
                name="status_in$canceled"
                type="checkbox"
                onChange={this.handleChangeFilters}
                checkboxType="switcher-2"
                component={fieldTemplate}
              />
            </div>
          </div>
        )}
        {isAdmin && (
          <div className="form-field-wrap form-field-wrap--margin-1">
            <div className="form-field-wrap__group-title">{lang.VISIBLE_LABEL}</div>
            <div className="filters__items">
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
                label={langVisibleStatuses.validated}
                name="visible_by_client_in$validated"
                type="checkbox"
                onChange={this.handleChangeFilters}
                checkboxType="switcher-2"
                component={fieldTemplate}
              />
              <Field
                cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
                label={langVisibleStatuses.canceled}
                name="visible_by_client_in$canceled"
                type="checkbox"
                onChange={this.handleChangeFilters}
                checkboxType="switcher-2"
                component={fieldTemplate}
              />
            </div>
          </div>
        )}
        <div className="form-field-wrap form-field-wrap--margin-1">
          <div className="form-field-wrap__group-title">{lang.ORIENTATION_LABEL}</div>
          <div className="filters__items">
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={langOrientations.portrait}
              name="orientation_in$portrait"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={langOrientations.landscape}
              name="orientation_in$landscape"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={langOrientations.square}
              name="orientation_in$square"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
          </div>
        </div>
        <div className="form-field-wrap form-field-wrap--margin-1">
          <div className="form-field-wrap__group-title">{lang.ARRIVING_LABEL}</div>
          <div className="filters__items">
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.ARRIVING_TYPES.DEVICE}
              name="arrivingType$participation_interaction_type_in__OfflineInteraction"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.ARRIVING_TYPES.TRACKER}
              name="arrivingType$participation_id_null__t"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.ARRIVING_TYPES.WEB}
              name="arrivingType$participation_interaction_type_in__OnlineInteraction"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
          </div>
        </div>
        <div className="form-field-wrap form-field-wrap--margin-1">
          <div className="form-field-wrap__group-title">{lang.LEAD_LABEL}</div>
          <div className="filters__items">
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.LEAD_EXISTS}
              name="participation_lead_id_null$f"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.LEAD_NO}
              name="participation_lead_id_null$t"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
          </div>
        </div>
        <div className="form-field-wrap form-field-wrap--margin-1">
          <div className="form-field-wrap__group-title">{lang.GROUP_LABEL}</div>
          <div className="filters__items">
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.GROUP_INSIDE}
              name="group$has"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.GROUP_NO}
              name="group$hasNo"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
          </div>
        </div>
        <div className="form-field-wrap form-field-wrap--margin-1">
          <div className="form-field-wrap__group-title">{lang.AVATAR_LABEL}</div>
          <div className="filters__items">
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.AVATAR_EXIST}
              name="author_avatar_null$f"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.AVATAR_NO}
              name="author_avatar_null$t"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
          </div>
        </div>
        <div className="form-field-wrap form-field-wrap--margin-1">
          <div className="form-field-wrap__group-title">{lang.COMMENTS_LABEL}</div>
          <div className="filters__items">
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.COMMENTS_COMMENTS}
              name="comment_present$t"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-5"
              label={lang.COMMENTS_NO}
              name="comment_present$f"
              type="checkbox"
              onChange={this.handleChangeFilters}
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({languageState}) => ({
  languageState,
}))(VisFiltersForm);
