import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reset} from 'redux-form';

import {
  selectTemplateVariables,
  selectTaskTimer,
  selectTemplateVariablesInitialForm,
  selectMessageMappingItems,
  selectStepsStatuses,
  selectBroadcastReadyState,
  selectMessageVariablesInitialForm,
  selectMessageText,
  selectSmsVarsFromMessage,
} from 'client/ducks/message-email-sms-task/selectors';
import {selectMessageSenders} from 'client/ducks/message-senders/selectors';

import {MESSAGE_TASK_STATES} from 'client/common/config';

import StepNav from 'client/components/common/step-nav';
import VTabs, {VTabsPane, VTabsCol} from 'client/components/common/v-tabs';

import {TranslationJsx} from 'client/models/language/types';
import {TASK_TYPES} from 'client/models/operations/constants';

import BcMailingNavItem from '../nav-items/bc-mailing-nav-item';
import BcPersonalizeNavItem from '../nav-items/bc-personalize-nav-item';
import BcSettingsNavItem from '../nav-items/bc-settings-nav-item';
import BcSmsTextNavItem from '../nav-items/bc-sms-text-nav-item';
import BcTemplateNavItem from '../nav-items/bc-template-nav-item';
import BCMailingStep from '../steps/bc-mailing-step';
import BCPersonalizeStep from '../steps/bc-personalize-step';
import BCSettingsStep from '../steps/bc-settings-step';
import BCSmsTextStep from '../steps/bc-sms-text-step';
import BCTemplateStep from '../steps/bc-template-step';

const TASK_SETTINGS_STEPS = {
  MAILING_LIST: 'MAILING_LIST',
  TEMPLATE: 'TEMPLATE',
  PERSONALIZE: 'PERSONALIZE',
  SETTINGS: 'SETTINGS',
  SMS_TEXT: 'SMS_TEXT',
};

class BCSteps extends Component {
  static propTypes = {
    steps: PropTypes.array,
    templateVariables: PropTypes.array,
    broadcastId: PropTypes.string,
    taskType: PropTypes.string.isRequired,
    taskState: PropTypes.string.isRequired,
    clientType: PropTypes.string.isRequired,
    templateInitialValues: PropTypes.object,
    senders: PropTypes.array.isRequired,
    task: PropTypes.object.isRequired,
    languageState: PropTypes.object.isRequired,
    currentBroadcast: PropTypes.object.isRequired,
    timerInitialValues: PropTypes.object.isRequired,
    stepsStatuses: PropTypes.object.isRequired,
    onSortChange: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onToggleTaskState: PropTypes.func.isRequired,
    perPage: PropTypes.number.isRequired,
    taskId: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    operationId: PropTypes.string.isRequired,
    isReadyToStart: PropTypes.bool.isRequired,
    messageText: TranslationJsx.isRequired,
    disabledWhilePerforming: PropTypes.bool.isRequired,
    disabledIfNotNew: PropTypes.bool.isRequired,
    resetForm: PropTypes.func.isRequired,
  };

  static defaultProps = {
    broadcastId: '',
    templateVariables: [],
    templateInitialValues: {},
  };

  state = {
    activeKey: null,
  };

  constructor(props) {
    super(props);

    this.STEPS_LIST = [
      {
        type: TASK_SETTINGS_STEPS.MAILING_LIST,
        renderNav: (p) => <BcMailingNavItem key={p.eventKey} {...p} />,
        renderComponent: (p) => <BCMailingStep noStyle={true} {...p} />,
        forTaskType: [TASK_TYPES.EMAIL, TASK_TYPES.SMS],
      },
      {
        type: TASK_SETTINGS_STEPS.TEMPLATE,
        renderNav: (p) => <BcTemplateNavItem key={p.eventKey} {...p} />,
        renderComponent: (p) => <BCTemplateStep {...p} />,
        forTaskType: [TASK_TYPES.EMAIL],
      },
      {
        type: TASK_SETTINGS_STEPS.SMS_TEXT,
        renderNav: (p) => <BcSmsTextNavItem key={p.eventKey} {...p} />,
        renderComponent: (p) => <BCSmsTextStep {...p} />,
        forTaskType: [TASK_TYPES.SMS],
      },
      {
        type: TASK_SETTINGS_STEPS.PERSONALIZE,
        renderNav: (p) => <BcPersonalizeNavItem key={p.eventKey} {...p} />,
        renderComponent: (p) => <BCPersonalizeStep {...p} />,
        forTaskType: [TASK_TYPES.EMAIL, TASK_TYPES.SMS],
      },
      {
        type: TASK_SETTINGS_STEPS.SETTINGS,
        renderNav: (p) => <BcSettingsNavItem key={p.eventKey} {...p} />,
        renderComponent: (p) => <BCSettingsStep {...p} />,
        forTaskType: [TASK_TYPES.EMAIL, TASK_TYPES.SMS],
      },
    ];

    const payload = props.languageState.payload;

    this.LANGUAGE = payload.BROADCASTING_TASK.EMAIL_STEPS;
  }

  resetForms = (...names) => {
    names.forEach((name) => this.props.resetForm(name));
  };

  handleToggleState = (state) => {
    if (state === MESSAGE_TASK_STATES.PERFORMING) {
      this.resetForms(BCPersonalizeStep.formName, BCSettingsStep.formName);
    }

    return this.props.onToggleTaskState(state);
  };

  renderNav = () => {
    return this.STEPS_LIST.filter((step) => step.forTaskType.includes(this.props.taskType)).map((step) => {
      const {type, renderNav} = step;

      const {task, languageState, currentBroadcast, templateVariables, stepsStatuses, messageText, ...restProps} =
        this.props;

      const navProps = {
        onClick: () => this.setState({activeKey: type}),
        active: this.state.activeKey === type,
        eventKey: type,
        task,
        currentBroadcast,
        languageState: {
          ...languageState.payload.BROADCASTING_TASK.EMAIL_STEPS,
          ...languageState.payload.TIME_UNITS,
        },
        ...restProps,
      };

      switch (type) {
        case TASK_SETTINGS_STEPS.MAILING_LIST:
          const count = task.broadcastRecipientsCount;

          navProps.count = count;
          navProps.checked = stepsStatuses.mailingChecked;
          break;
        case TASK_SETTINGS_STEPS.TEMPLATE:
          const templateName = task.emailTemplate && task.emailTemplate.name;

          navProps.templateName = templateName;
          navProps.checked = stepsStatuses.templateChecked;
          break;
        case TASK_SETTINGS_STEPS.SMS_TEXT:
          navProps.messageText = messageText;
          navProps.checked = !!messageText;
          break;
        case TASK_SETTINGS_STEPS.SETTINGS:
          navProps.checked = stepsStatuses.settingsChecked;
          break;
        case TASK_SETTINGS_STEPS.PERSONALIZE:
          const mappedVarsCount = templateVariables ? templateVariables.length : 0;
          navProps.checked = stepsStatuses.personalizeChecked;
          navProps.totalCount = mappedVarsCount;
          navProps.mappedCount = mappedVarsCount;
          break;
        default:
        //
      }

      return renderNav(navProps);
    });
  };

  renderSteps = () => {
    return this.STEPS_LIST.filter((step) => step.forTaskType.includes(this.props.taskType)).map((step) => {
      const {type, renderComponent} = step;

      let style = type === TASK_SETTINGS_STEPS.MAILING_LIST ? {paddingBottom: 50} : {};

      return (
        <VTabsPane eventKey={type} key={type} activeKey={this.state.activeKey}>
          <VTabsCol style={style}>{renderComponent(this.props)}</VTabsCol>
        </VTabsPane>
      );
    });
  };

  render() {
    const {
      task: {taskState},
      isReadyToStart,
    } = this.props;

    return (
      <VTabs
        id="email-broadcasting"
        className="theme-color-16 broadcasting__steps"
        navbar={
          <div>
            <StepNav>{this.renderNav()}</StepNav>
            <div>
              <div className="broadcasting__nav-actions">
                {/* TODO: will be used in V2 of platform */}
                {false && (
                  <div className="broadcasting__nav-action-btn-1">
                    <button className="button button--bg-14 button--full-width button--no-paddings">
                      {this.LANGUAGE.TEST_BUTTON}
                    </button>
                  </div>
                )}
                {taskState === MESSAGE_TASK_STATES.PERFORMING && isReadyToStart && (
                  <div className="broadcasting__nav-action-btn-2">
                    <button
                      className="button button--bg-12 button--full-width button--no-paddings"
                      onClick={() => this.handleToggleState(MESSAGE_TASK_STATES.SLEEPING)}
                    >
                      {this.LANGUAGE.STOP_BUTTON}
                    </button>
                  </div>
                )}
                {taskState === MESSAGE_TASK_STATES.SLEEPING && (
                  <div className="broadcasting__nav-action-btn-2">
                    <button
                      className="button button--bg-15 button--full-width button--no-paddings"
                      onClick={() => this.handleToggleState(MESSAGE_TASK_STATES.PERFORMING)}
                    >
                      {this.LANGUAGE.START_BUTTON}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        }
      >
        {this.renderSteps()}
      </VTabs>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const stepsStatuses = selectStepsStatuses(state);
  const isSmsTask = ownProps.taskType === TASK_TYPES.SMS;

  const templateVariables = isSmsTask ? selectSmsVarsFromMessage(state) : selectTemplateVariables(state);

  const templateInitialValues = isSmsTask
    ? selectMessageVariablesInitialForm(state)
    : selectTemplateVariablesInitialForm(state);

  return {
    timerInitialValues: selectTaskTimer(state, BCSettingsStep.timerKinds),
    languageState: state.languageState,
    senders: selectMessageSenders(state),
    messageMappingItems: selectMessageMappingItems(state),
    templateVariables,
    templateInitialValues,
    messageText: selectMessageText(state),
    stepsStatuses,
    isReadyToStart: selectBroadcastReadyState(stepsStatuses),
  };
};

const mapDispatchToProps = {
  resetForm: reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(BCSteps);
