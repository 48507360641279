import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {getIsNational} from 'client/services/cookie-data-source';

import {selectTaskBaseInfo} from 'client/ducks/message-email-sms-task/selectors';
import {selectIsUserLoaded, selectIsAdmin} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import MessageTaskResults from 'client/components/broadcasting/message-task-results';

class MessageTaskResultsPage extends Component {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
        taskId: PropTypes.string,
      }),
    }).isRequired,
    task: PropTypes.object.isRequired,
    languageState: PropTypes.object.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  componentDidUpdate(prevProps) {
    const {task, setMetaAction, languageState} = this.props;
    const resultsTitle = languageState.payload.BROADCASTING_TASK.RESULT.TITLE;

    if (prevProps.task.taskName !== task.taskName) {
      setMetaAction({
        title: `${resultsTitle}: ${task.taskName}`,
      });
    }
  }

  render() {
    const {
      match: {
        params: {taskId},
      },
      isUserLoaded,
      isAdmin,
    } = this.props;

    const isNational = getIsNational();

    if (!isUserLoaded) {
      return null;
    }

    if (!isAdmin && !isNational) {
      return <Redirect to={CLIENT_PAGES.HOME} />;
    }

    return <MessageTaskResults isNational={getIsNational()} taskId={taskId} />;
  }
}

const mapStateToProps = (state) => ({
  task: selectTaskBaseInfo(state),
  languageState: state.languageState,
  isUserLoaded: selectIsUserLoaded(state),
  isAdmin: selectIsAdmin(state),
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageTaskResultsPage);
