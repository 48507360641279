import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {CLIENT_PAGES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';

import './leads-visuals-list.scss';

class LeadsVisualsList extends Component {
  static propTypes = {
    clientId: PropTypes.number,
    autotask: PropTypes.object,
    operation: PropTypes.object,
    languageState: PropTypes.object.isRequired,
    leadsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    visualsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    participationsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    leadsCount: 0,
    visualsCount: 0,
    autotask: {},
    operation: {},
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.OPERATION_PAGE;
  }

  renderItem = () => {
    const {leadsCount, visualsCount, clientId, autotask, participationsCount, operation} = this.props;

    const leadsLink = `${CLIENT_PAGES.LEADS_LIST}/${clientId}`;
    const visualsLink = CustomLink.createVisualsLink(clientId);
    const participationsLink = CustomLink.createTaskParticipationsLink(clientId, operation.id, autotask.id);

    return (
      <div className="content-panel__panel leads-visuals-list__item">
        <div className="content-panel__panel-inner">
          <div className="content-panel__row">
            <div className="leads-visuals-list__col ellipsis-text">
              <Link
                className="link"
                to={`${leadsLink}?filter={"autotask":[{"value":${autotask.id},"label":"${encodeURIComponent(
                  autotask.name,
                )}"}]}`}
              >
                {leadsCount}
              </Link>
            </div>
            <div className="leads-visuals-list__col ellipsis-text">
              <Link to={`${visualsLink}?automation_task_id=${autotask.id}`} className="link">
                {visualsCount}
              </Link>
            </div>
            <div className="leads-visuals-list__col ellipsis-text">
              <Link to={participationsLink} className="link">
                {participationsCount}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="content-panel leads-visuals-list">
        <div className="content-panel__header leads-visuals-list__header">
          <div className="leads-visuals-list__col">{this.LANGUAGE.AUTOMATION_TASK.LEADS_GENERATED}</div>
          <div className="leads-visuals-list__col">{this.LANGUAGE.AUTOMATION_TASK.VISUALS_GENERATED}</div>
          <div className="leads-visuals-list__col">{this.LANGUAGE.AUTOMATION_TASK.PARTICIPATIONS_GENERATED}</div>
        </div>
        {this.renderItem()}
      </div>
    );
  }
}

export default connect(({languageState}) => ({
  languageState,
}))(LeadsVisualsList);
