import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector, SubmissionError} from 'redux-form';

import bem from 'client/services/bem';
import {arrayUnique, required} from 'client/services/validator';

import AppButton from 'client/common/buttons/app-button';
import {FACEBOOK_API_VERSION, FACEBOOK_APP_ID} from 'client/common/config';
import {TextField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import FacebookLogin from './fb-button';

import cssModule from './add-new-facebook-account-modal.module.scss';

const b = bem('add-new-facebook-account-modal', {cssModule});
class AddNewFacebookAccountModal extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    buttonCancelClass: PropTypes.string,
    buttonConfirmClass: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    socialAccountsNames: PropTypes.array,
    pristine: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    className: PropTypes.string,
    pageId: PropTypes.string,
    name: PropTypes.string,
    show: PropTypes.bool,
    isAdmin: PropTypes.bool,
  };

  static defaultProps = {
    show: false,
  };

  static formName = 'AddNewFacebookAccountForm';

  static SCOPE = ['manage_pages', 'publish_pages'];

  static API_LANGUAGE = 'en_US';

  constructor(props) {
    super(props);
    this.rules = {
      name: [
        required(this.props.lang.NAME_IS_REQUIRED_ERROR),
        (value) => {
          return arrayUnique(this.props.socialAccountsNames, this.props.lang.NAME_IS_TAKEN)(value);
        },
      ],
      pageId: required(this.props.lang.PAGE_ID_IS_REQUIRED_ERROR),
    };
  }

  componentDidUpdate({show}) {
    if (this.props.show && !show) {
      this.props.initialize({});
    }
  }

  save = (name) => {
    if (name) {
      return this.props.onConfirm({name});
    }

    throw new SubmissionError({pageId: this.props.lang.CANT_ACCESS_PAGE_ERROR});
  };

  render() {
    const {handleSubmit, onClose, onCancel, show, valid, pristine, pageId, className, isAdmin, lang} = this.props;

    return (
      <Modal show={show} className={cn(b(), className)} onClose={onClose} title={lang.TITLE}>
        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          <TextField label={lang.NAME_LABEL} name="name" validate={this.rules.name} required={true} withWrap={true} />
          <TextField
            label={lang.PAGE_ID_LABEL}
            name="pageId"
            validate={this.rules.pageId}
            required={true}
            withWrap={true}
          />
          <div className={b('buttons')}>
            {isAdmin && (
              <AppButton type="button" onClick={onCancel} label={lang.CANCEL_BUTTON} color="text-additional" />
            )}
            <FacebookLogin
              appId={FACEBOOK_APP_ID}
              pageId={pageId}
              returnScopes
              language={AddNewFacebookAccountModal.API_LANGUAGE}
              scope={AddNewFacebookAccountModal.SCOPE.join(',')}
              xfbml
              textButton={lang.SAVE_BUTTON}
              version={FACEBOOK_API_VERSION}
              disabled={!valid || pristine}
              callback={({name}) => handleSubmit(() => this.save(name))()}
            />
          </div>
        </form>
      </Modal>
    );
  }
}

const AddNewFacebookAccountForm = reduxForm({
  form: AddNewFacebookAccountModal.formName,
})(AddNewFacebookAccountModal);

const languageSet = ({
  isAdmin,
  languageState: {
    payload: {AGENCY, ACCOUNT_PARAMETERS},
  },
}) => ({
  lang: isAdmin ? AGENCY.ADD_SOCIAL_ACCOUNT_MODAL : ACCOUNT_PARAMETERS.SOCIAL_NETWORK.ADD_SOCIAL_ACCOUNT_MODAL,
});

const mapStateToProps = (state) => {
  const selector = formValueSelector(AddNewFacebookAccountModal.formName);

  return {
    name: selector(state, 'name'),
    pageId: selector(state, 'pageId'),
  };
};

export default connect(languageSet, mapStateToProps)(AddNewFacebookAccountForm);
