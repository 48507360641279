import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';

import bem from 'client/services/bem';

import Icon from 'client/components/common/icon';

import './visuals-slider.scss';

const b = bem('visuals-slider');

// TODO: due to the library `Slider` we can not move
// 1 this component into tsx
// 2 component into cssModule

const VisualsSlider = (props) => {
  const {onChange, value, className} = props;

  return (
    <div className={cn(b(), className)}>
      <Icon name="picture-purple-small" />
      <Slider defaultValue={0} value={value} className={b('slider')} onChange={onChange} />
      <Icon name="picture-purple-big" />
    </div>
  );
};

VisualsSlider.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default VisualsSlider;
