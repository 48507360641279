import React from 'react';

import PropTypes from 'prop-types';
import {Doughnut} from 'react-chartjs-2';

import bem from 'client/services/bem';

import {TranslationJsx} from 'client/models/language/types';

import {DonutChartTitle, DonutChartLegend} from './../index';

import './donut-chart-doughnut.scss';

const b = bem('donut-chart-doughnut');

const DonutChartDoughnut = (props) => {
  const {
    title,
    secondValueTitle,
    data,
    options,
    showLegend,
    showLegendPercent,
    chartData,
    legendOnRight,
    total,
    secondTotal,
    getSecondPercent,
    legendValuesOnRight,
    width,
    height,
    legendMaxHeight,
  } = props;

  const size = {
    width: `${width}px`,
    height: `${height}px`,
  };

  return (
    <div className={b({'legend-on-right': legendOnRight})}>
      <DonutChartTitle title={title} secondValueTitle={secondValueTitle} />

      <div className={b('donut')}>
        <div className={b('chart-inner')} style={size}>
          <Doughnut type="doughnut" data={data} options={options} />
        </div>
        {showLegend && (
          <DonutChartLegend
            data={chartData}
            legendOnRight={legendOnRight}
            getSecondPercent={getSecondPercent}
            total={total}
            secondTotal={secondTotal}
            showPercent={showLegendPercent}
            legendValuesOnRight={legendValuesOnRight}
            maxHeight={legendMaxHeight}
          />
        )}
      </div>
    </div>
  );
};

DonutChartDoughnut.propTypes = {
  data: PropTypes.object,
  options: PropTypes.object,
  title: TranslationJsx,
  secondValueTitle: TranslationJsx,
  getSecondPercent: PropTypes.func,
  legendOnRight: PropTypes.bool,
  showLegend: PropTypes.bool,
  showLegendPercent: PropTypes.bool,
  legendValuesOnRight: PropTypes.bool,
  legendMaxHeight: PropTypes.number,
  total: PropTypes.number,
  secondTotal: PropTypes.number,
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      label: TranslationJsx,
      value: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  width: PropTypes.number,
  height: PropTypes.number,
};

DonutChartDoughnut.defaultProps = {
  data: {},
  options: {},
  title: null,
  legendOnRight: false,
  showLegendPercent: false,
  legendValuesOnRight: false,
  legendMaxHeight: 200,
  showLegend: true,
  chartData: null,
  total: 0,
  secondTotal: 0,
  width: 108,
  height: 108,
};

export default DonutChartDoughnut;
