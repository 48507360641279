import flow from 'lodash/flow';
import isNil from 'lodash/fp/isNil';
import isPlainObject from 'lodash/fp/isPlainObject';
import mapValues from 'lodash/fp/mapValues';
import omitBy from 'lodash/fp/omitBy';
import get from 'lodash/get';

export const getScenarioVariables = (state, ...types) => {
  const scenarioVariables = get(state, 'scenario.payload.scenario_variables', []);
  const scenarioStepVariables = get(state, 'scenario.payload.scenario_step_variables', []);

  if (types.length > 0) {
    return scenarioVariables.concat(scenarioStepVariables).filter((variable) => types.includes(variable.kind));
  }

  return scenarioVariables;
};

export const filterScenarios = (scenarios, currentId) => scenarios.filter((v) => currentId !== v.id);

export const filterScenarioVariablesByType = (variables, ...types) => {
  return variables.filter((variable) => types.includes(variable.kind));
};

export const compactObject = (obj) => {
  return flow([
    mapValues((item) => {
      if (isPlainObject(item)) {
        return compactObject(item);
      }

      return item;
    }),
    omitBy((item) => isNil(item)),
  ])(obj);
};

export const maxLengthNormalization = (maxLength) => (value) => {
  return value.substr(0, maxLength);
};

export const preventDefault = (e) => {
  e.preventDefault();
};

export const onSubmitSuccess = (result, dispatch, props) => {
  return props.onSubmitResult('success', {
    ...result.payload.scenario_step,
    initialId: props.data.id,
  });
};

export const onSubmitFail = (errors, dispatch, submitError, props) => {
  return props.onSubmitResult('failed', props.data);
};

export const validate = (validator, value, values) => validator(value, values);

export const validateArray = (validators, value, values) => {
  const errorValidator = validators.find((validator) => !!validate(validator, value, values));

  if (errorValidator) {
    return validate(errorValidator, value, values);
  }

  return null;
};
