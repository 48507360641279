export const BARCODE_FORMATS = [
  {
    label: 'Format EAN 8',
    value: 'ean8',
  },
  {
    label: 'Format EAN 13',
    value: 'ean13',
  },
  {
    label: 'Format Code 128',
    value: 'code128',
  },
  {
    label: 'Format Code 39',
    value: 'code39',
  },
];

export const SOGEC_FIELD = [
  {
    label: 'FIRST_NAME_LABEL',
    fieldName: 'first_name',
  },
  {
    label: 'LAST_NAME_LABEL',
    fieldName: 'last_name',
  },
  {
    label: 'EMAIL_LABEL',
    fieldName: 'email',
  },
  {
    label: 'CITY_LABEL',
    fieldName: 'city',
  },
  {
    label: 'ZIP_LABEL',
    fieldName: 'zip',
  },
  {
    label: 'ADDRESS_1_LABEL',
    fieldName: 'address1',
  },
  {
    label: 'ADDRESS_2_LABEL',
    fieldName: 'address2',
  },
  {
    label: 'ADDRESS_3_LABEL',
    fieldName: 'address3',
  },
  {
    label: 'ADDRESS_4_LABEL',
    fieldName: 'address4',
  },
];

export const getVisualOptions = (lang) => [
  {
    label: lang.NONE_LABEL,
    value: 'none',
  },
  {
    label: lang.QR_CODE_LABEL,
    value: 'qr_code',
  },
  {
    label: lang.BARCODE_LABEL,
    value: 'barcode',
  },
];
