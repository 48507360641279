import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import types from './types';

export const getInstoreDashboards = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_DASHBOARDS}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_INSTORE_DASHBOARDS_REQUEST,
        types.GET_INSTORE_DASHBOARDS_SUCCESS,
        types.GET_INSTORE_DASHBOARDS_FAILED,
      ],
      queryParams,
    });
};

export const clearInstoreDashboards = () => {
  return (dispatch) => dispatch({type: types.CLEAR_INSTORE_DASHBOARDS});
};

export const removeInstoreDashboardBlock = (id) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_DASHBOARD_BLOCKS}/${id}`,
      method: 'DELETE',
      noCache: true,
      types: [
        types.DELETE_INSTORE_DASHBOARD_BLOCK_REQUEST,
        types.DELETE_INSTORE_DASHBOARD_BLOCK_SUCCESS,
        types.DELETE_INSTORE_DASHBOARD_BLOCK_FAILED,
      ],
    }).then(() => {
      return dispatch({type: types.DELETE_INSTORE_DASHBOARD_BLOCK_FROM_STORE, payload: id});
    });
};

export const createInternalInstoreDashboard = (body) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_INTERNAL_DASHBOARDS}`,
      method: 'POST',
      noCache: true,
      types: [
        types.CREATE_INTERNAL_INSTORE_DASHBOARD_REQUEST,
        types.CREATE_INTERNAL_INSTORE_DASHBOARD_SUCCESS,
        types.CREATE_INTERNAL_INSTORE_DASHBOARD_FAILED,
      ],
      body,
    });
};

export const updateInternalInstoreDashboard = (id, body) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_INTERNAL_DASHBOARDS}/${id}`,
      method: 'PATCH',
      noCache: true,
      types: [
        types.UPDATE_INTERNAL_INSTORE_DASHBOARD_REQUEST,
        types.UPDATE_INTERNAL_INSTORE_DASHBOARD_SUCCESS,
        types.UPDATE_INTERNAL_INSTORE_DASHBOARD_FAILED,
      ],
      body,
    });
};

export const deleteInternalInstoreDashboard = (id) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_INTERNAL_DASHBOARDS}/${id}`,
      method: 'DELETE',
      noCache: true,
      types: [
        types.DELETE_INTERNAL_INSTORE_DASHBOARD_REQUEST,
        types.DELETE_INTERNAL_INSTORE_DASHBOARD_SUCCESS,
        types.DELETE_INTERNAL_INSTORE_DASHBOARD_FAILED,
      ],
    });
};

export const setDefaultInstoreDashboard = (instoreTaskAccessId, dashboardId) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_TASKS_ACCESSES}/${instoreTaskAccessId}`,
      method: 'PATCH',
      noCache: true,
      types: [
        types.SET_DEFAULT_INSTORE_DASHBOARD_REQUEST,
        types.SET_DEFAULT_INSTORE_DASHBOARD_SUCCESS,
        types.SET_DEFAULT_INSTORE_DASHBOARD_FAILED,
      ],
      body: {
        instore_task_access: {
          default_dashboard_id: dashboardId,
        },
      },
    });
};

export const updateInstoreDashboard = (id, body) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_DASHBOARDS}/${id}`,
      method: 'PUT',
      noCache: true,
      types: [
        types.UPDATE_INSTORE_DASHBOARD_REQUEST,
        types.UPDATE_INSTORE_DASHBOARD_SUCCESS,
        types.UPDATE_INSTORE_DASHBOARD_FAILED,
      ],
      body,
      queryParams: {
        include: {dashboard_blocks: null},
      },
    });
};

export const getInstoreDashboardBlocks = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_DASHBOARD_BLOCKS}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_INSTORE_DASHBOARD_BLOCKS_REQUEST,
        types.GET_INSTORE_DASHBOARD_BLOCKS_SUCCESS,
        types.GET_INSTORE_DASHBOARD_BLOCKS_FAILED,
      ],
      queryParams,
    });
};

export const getInstoreTaskKpis = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_TASK_KPIS}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_INSTORE_DASHBOARD_KPIS_REQUEST,
        types.GET_INSTORE_DASHBOARD_KPIS_SUCCESS,
        types.GET_INSTORE_DASHBOARD_KPIS_FAILED,
      ],
      queryParams,
    });
};

export const createDashboardBlock = (body) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_DASHBOARD_BLOCKS}`,
      method: 'POST',
      noCache: true,
      types: [
        types.CREATE_INSTORE_DASHBOARD_BLOCK_REQUEST,
        types.CREATE_INSTORE_DASHBOARD_BLOCK_SUCCESS,
        types.CREATE_INSTORE_DASHBOARD_BLOCK_FAILED,
      ],
      body,
    });
};

export const updateDashboardBlock = (id, body) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INSTORE_DASHBOARD_BLOCKS}/${id}`,
      method: 'PATCH',
      noCache: true,
      types: [
        types.UPDATE_INSTORE_DASHBOARD_BLOCK_REQUEST,
        types.UPDATE_INSTORE_DASHBOARD_BLOCK_SUCCESS,
        types.UPDATE_INSTORE_DASHBOARD_BLOCK_FAILED,
      ],
      body,
    });
};
