import {SmsTemplateKind} from 'client/models/sms-templates/types';

export default (kind: SmsTemplateKind) => {
  return {
    language: kind.sms_templates[0].language_tag,
    name: kind.sms_templates.reduce((result: Record<string, string>, item) => {
      result[item.language_tag] = item.name || '';
      return result;
    }, {}),
    message: kind.sms_templates.reduce((result: Record<string, string>, item) => {
      result[item.language_tag] = item.content || '';
      return result;
    }, {}),
  };
};
