import React from 'react';

import {useLanguage} from 'client/services/hooks';

import {SelectField} from 'client/common/fields';

import {Translation} from 'client/models/language/types';

type OperationOption = {
  value: '+' | '-' | '*' | '/' | 'concatenation';
  label: Translation;
};

type CalculationOperationFieldProps = {
  number: number;
  className?: string;
};

const CalculationOperationField: React.FC<CalculationOperationFieldProps> = ({number, className}) => {
  const lang = useLanguage('AUTOTASK_SCENARIO.SCENARIO_FORMS');

  const options: OperationOption[] = [
    {value: '+', label: '+'},
    {value: '-', label: '-'},
    {value: '*', label: '*'},
    {value: '/', label: '/'},
    {value: 'concatenation', label: lang.CONCATENATION},
  ];

  return (
    <SelectField
      className={className}
      name={`operation${number}`}
      label={`${lang.OPERATION} ${number}`}
      options={options}
      withWrap={false}
    />
  );
};

export default CalculationOperationField;
