import React, {Component} from 'react';

import find from 'lodash/find';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';
import {deleteMethod} from 'client/services/fetch';
import {mapFreeStores, mapRegionsWithStores} from 'client/services/helpers';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {resendInvitation} from 'client/ducks/client-users/actions';
import {getClientUsersWithMembershipsAction} from 'client/ducks/client-users/actions';
import {selectClientUsers} from 'client/ducks/client-users/selectors';
import {getAvailableColumnAdaptersByClient} from 'client/ducks/column-adapters/actions';
import {
  changeFinishedOperationsPerPageAction,
  changeFinishedOperationsSortAction,
  changeOngoingOperationsPerPageAction,
  changeOngoingOperationsSortAction,
  changeSelectedUsersAction,
  changeUsersPerPageAction,
  changeUsersSortAction,
  changeUsersSearchAction,
  deleteCompanyAction,
  getAdminUsersAction,
  getCompanyAction,
  getCompanyUsersAction,
  getCompanyMembershipsAction,
  getClientUserAction,
  getFinishedOperationsAction,
  getOngoingOperationsAction,
  getSubsidiariesAction,
  getUsersAction,
  patchCompanyAction,
  setDefaultNewUserAction,
  getAgenciesAction,
  getPOCsAgencyAction,
  getAgencyMembershipsAction,
  clearStoreAction,
} from 'client/ducks/company/actions';
import {selectUsersFullList} from 'client/ducks/company/selectors';
import {getLeadsReports} from 'client/ducks/lead/actions';
import {selectImportExportReports} from 'client/ducks/lead/selectors';
import {deleteApiLeadsExport} from 'client/ducks/leads-export/actions';
import {deleteApiLeadsImport} from 'client/ducks/leads-import/actions';
import {getLeads} from 'client/ducks/leads-list/actions';
import {selectColumnAdaptersForImportExport, selectLeadsTotalCount} from 'client/ducks/leads-list/selectors';
import {getOptInsAction} from 'client/ducks/opt-in-columns/actions';
import {selectOptIns} from 'client/ducks/opt-in-columns/selectors';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {API_METHODS, CLIENT_PAGES} from 'client/common/config';
import {ACCESS_LEVEL} from 'client/common/config';
import Icon from 'client/common/icon';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import TitleBlock from 'client/components/common/title-block';
import VisualsCard from 'client/components/common/visuals-card';

import PasswordExpirationCard from 'client/components/client-agency/components/cards/password-expiration-card';
import ClientUserDeactivationWizard from 'client/components/client-agency/wizards/client-user-deactivation-wizard';
import LeadsImportFileModal from 'client/components/leads/components/modals/leads-import-file-modal';
import LeadsPortSummaryModal from 'client/components/leads/components/modals/leads-port-summary-modal';
import SelectUserModal from 'client/components/modals/select-user-modal';
import {TranslationJsx} from 'client/models/language/types';

import AddLogoButton from '../components/add-logo-button';
import OngoingOperationsBlock from '../components/blocks/ongoing-operations-block';
import AutoCreationAccessCard from '../components/cards/auto-creation-access-card';
import BasicInformationCard from '../components/cards/basic-information-card';
import LeadsCard from '../components/cards/leads-card';
import MailingCard from '../components/cards/mailing-card';
import OptInsCard from '../components/cards/opt-ins-card';
import PhonesEmailsCard from '../components/cards/phones-emails-card';
import RegionsCard from '../components/cards/regions-card';
import SocialNetworkAccountsCard from '../components/cards/social-network-accounts-card';
import StoresCard from '../components/cards/stores-card';
import BasicInformationForm from '../components/client-basic-information-form';
import AddNewOperationModal from '../components/modals/add-new-operation-modal';
import AddNewOptInModal from '../components/modals/add-new-opt-in-modal';
import DeleteUsersModal from '../components/modals/delete-users-modal';
import EditAccessLevelModal from '../components/modals/edit-access-level-modal';
import EditUserModal from '../components/modals/edit-user-modal';
import NewUserAccessRegionsModal from '../components/modals/new-user/new-user-access-regions-modal';
import NewUserAccessStoresModal from '../components/modals/new-user/new-user-access-stores-modal';
import NewUserStep1Modal from '../components/modals/new-user/new-user-step-1-modal';
import NewUserStep2Modal from '../components/modals/new-user/new-user-step-2-modal';
import NewUserStep3Modal from '../components/modals/new-user/new-user-step-3-modal';
import UsersTable from '../components/tables/users-table';

import cssModule from './company.module.scss';

const b = bem('company', {cssModule});

class Company extends Component {
  static DEFAULT_LEADS_FIRST_PAGE = 1;

  static DEFAULT_LEADS_PER_PAGE = 5;

  static NUMBER_OF_STEPS = 3;
  static propTypes = {
    id: PropTypes.number.isRequired,
    languageState: PropTypes.object.isRequired,
    lang: PropTypes.object.isRequired,
    langBC: PropTypes.object.isRequired,
    langLeads: PropTypes.object.isRequired,
    company: PropTypes.shape({
      company: PropTypes.object,
      selectedUsers: PropTypes.array,
      noCompany: PropTypes.bool,
      users: PropTypes.object,
      clientUser: PropTypes.any,
      ongoingOperations: PropTypes.object,
      finishedOperations: PropTypes.object,
      subsidiaries: PropTypes.array,
      agencies: PropTypes.array,
      companyMemberships: PropTypes.array,
      agencyMemberships: PropTypes.array,
      social_accounts: PropTypes.array,
      message_senders: PropTypes.array,
      POCsAgency: PropTypes.array,
      adminUsers: PropTypes.array,
    }),
    optIns: PropTypes.array,
    allColumns: PropTypes.array,
    leadsReports: PropTypes.array.isRequired,
    leadsCount: PropTypes.number,

    history: PropTypes.object,
    userState: PropTypes.object,
    usersFullList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: TranslationJsx,
        value: PropTypes.string,
      }),
    ),
    changeSelectedUsers: PropTypes.func,

    changeUsersPerPage: PropTypes.func,
    changeOngoingOperationsPerPage: PropTypes.func,
    changeFinishedOperationsPerPage: PropTypes.func,
    getLeadsReports: PropTypes.func.isRequired,
    deleteApiLeadsImport: PropTypes.func.isRequired,
    changeUsersSort: PropTypes.func,
    changeOngoingOperationsSort: PropTypes.func,
    changeFinishedOperationsSort: PropTypes.func,
    changeUsersSearch: PropTypes.func.isRequired,
    getAvailableColumnAdaptersByClient: PropTypes.func.isRequired,
    deleteApiLeadsExport: PropTypes.func.isRequired,

    getUsers: PropTypes.func,
    getOngoingOperations: PropTypes.func,
    getFinishedOperations: PropTypes.func,
    getSubsidiaries: PropTypes.func,
    getAgencies: PropTypes.func,
    getCompanyMemberships: PropTypes.func,
    getAgencyMemberships: PropTypes.func,
    getAdminUsers: PropTypes.func,
    getOptIns: PropTypes.func,
    getLeads: PropTypes.func.isRequired,
    patchCompany: PropTypes.func,
    getClientUser: PropTypes.func,
    getPOCsAgency: PropTypes.func,
    setDefaultNewUser: PropTypes.func,
    deleteCompany: PropTypes.func,
    clearStore: PropTypes.func,
    isAdmin: PropTypes.bool,
    isNational: PropTypes.bool.isRequired,
    getCompany: PropTypes.func.isRequired,
    getCompanyUsers: PropTypes.func.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    clientUsers: PropTypes.array.isRequired,
    getClientUsersWithMemberships: PropTypes.func.isRequired,
    resendInvitation: PropTypes.func.isRequired,
  };

  static defaultProps = {
    allColumns: [],
    isAdmin: false,
  };

  static MODALS_MAP = {
    IMPORT_FILE_MODAL: 'IMPORT_FILE_TYPE',
    IMPORT_SUMMARY_MODAL: 'IMPORT_SUMMARY_MODAL',
  };

  static mapUsersData(users) {
    return users.map((user) => {
      user.email = user?.client_user?.email;
      return user;
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      editBasicInformation: false,
      showEditAccessLevelModal: false,
      showEditAccessLevelToCompanyModal: false,
      showDeleteClientModal: false,
      showDeleteUsersModal: false,
      errorMessageDeleteUserModal: '',
      errorMessageDeleteClientModal: '',
      showAddNewOperationModal: false,
      showAddNewOptInModal: false,
      showDeleteOptInModal: false,
      showSelectUserModal: false,
      editingUser: {},
      editingOptIn: {},
      showEditClientAccessModal: 0,
      editAccessLevelModalClientId: 0,
      editAccessLevelCompanyName: '',
      addNewUserStep: 0,
      addNewUserObject: {},
      addNewUserExists: false,
      visibleModal: '',
      selectedUsersWithMemberships: [],
      selectedUsersWithoutMemberships: [],
      isLoading: false,
      deletingUser: {
        index: null,
        user: null,
        soft: false,
      },
      reInviteUserId: null,
      resetUsersSearch: false,
    };
  }

  componentDidMount() {
    const init = {
      companyId: this.props.id,
      page: 1,
      perPage: 5,
      sort: {name: 'name', order: 'ASC'},
    };

    this.updateMe().then(() => {
      this.props.setBreadcrumbs(
        this.props.isAdmin
          ? [
              {
                name: this.props.langBC.CLIENTS,
                href: CLIENT_PAGES.CLIENTS_LIST,
              },
              {
                name: this.props.company?.company?.name,
              },
            ]
          : [
              {
                name: this.props.company?.company?.name,
                hidden: this.props.isNational,
              },
            ],
      );
    });

    this.updateUsers();
    this.props.getOngoingOperations(init);
    this.props.getFinishedOperations(init);
    const leadsParamsForTotalCount = {
      page: Company.DEFAULT_LEADS_FIRST_PAGE,
      per_page: Company.DEFAULT_LEADS_PER_PAGE,
    };
    this.props.getLeads(leadsParamsForTotalCount, this.props.id);

    this.props.getAvailableColumnAdaptersByClient(this.props.id);
    this.getReports();

    this.props.getClientUsersWithMemberships(this.props.id);
    this.props.getCompanyMemberships(this.props.id);
    this.props.getCompanyUsers(this.props.id);
  }

  componentDidUpdate(prevProps) {
    const {company: nextCompany} = this.props;
    const {company: prevCompany} = prevProps;

    if (nextCompany.noCompany) {
      this.props.history.push(CLIENT_PAGES.CLIENTS_LIST);
    }

    const {users: nextUsers} = nextCompany;
    const {users: prevUsers} = prevCompany;

    if (nextUsers && nextUsers.needToUpdate && (!prevUsers || !prevUsers.needToUpdate)) {
      this.props.getUsers({
        companyId: this.props.id,
        page: nextUsers.meta.current_page || 1,
        perPage: nextUsers.perPage || 5,
        sort: nextUsers.sort || {name: 'client_user_full_name', order: 'ASC'},
      });
    }
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
    this.props.clearStore();
  }

  deleteCompany = async () => {
    this.props.deleteCompany(this.props.id).then((resp) => {
      if (resp.error) {
        this.setState({
          errorMessageDeleteClientModal: this.props.lang.DELETE_CLIENTS_PAGE_MODAL_ERROR,
        });
      }
    });
  };

  generalColumns = () => [
    this.props.langLeads.FIRST_NAME,
    this.props.langLeads.LAST_NAME,
    this.props.langLeads.EMAIL,
    this.props.langLeads.PHONE,
  ];

  updateMe = () => {
    const {id, getCompany, getOptIns} = this.props;

    const params = {
      include: ['user', 'client'],
      include_opt_in_column_leads_count: null,
      include_opt_in_column_interfaces_count: null,
      q: {
        client_id_eq: id,
        s: 'level',
      },
    };
    this.setState({
      showDeleteOptInModal: false,
      showEditUserModal: false,
      editingUser: {},
      addNewUserObject: {},
      addNewUserStep: 0,
      addNewUserExists: false,
      isLoading: true,
    });

    return getCompany(id).then((promise) => {
      this.setState({isLoading: false});
      getOptIns(params);

      return promise;
    });
  };

  updateUsers = (page = 1) =>
    this.props.getUsers({
      companyId: this.props.id,
      page: page,
      perPage: 5,
      sort: {name: 'client_user_full_name', order: 'ASC'},
    });

  handleEditAccessLevelSave = () => {
    const users = this.props.company.agencyMemberships.filter(
      (item) =>
        typeof item.checked !== 'undefined' &&
        item.checked !== (typeof find(item.company_accesses, (i) => i.company_id === +this.props.id) !== 'undefined'),
    );

    const data = {
      company: {
        company_accesses: users.map((user) =>
          user.checked
            ? {
                agency_membership_id: user.id,
              }
            : {
                id: find(user.company_accesses, (i) => i.company_id === +this.props.id).id,
                _destroy: true,
              },
        ),
      },
    };
    this.props.patchCompany(this.props.id, data).then(() => {
      this.setState({
        showEditAccessLevelToCompanyModal: false,
      });

      this.props.getAgencyMemberships(this.props.company.company.agency.id);
    });
  };

  onTablePageChange = (companyField, getItems, changeItemsPerPage) => {
    return (params) => {
      const items = this.props.company[companyField];
      if (typeof params.sort === 'undefined') {
        params.sort = items.sort;
      }
      params.companyId = this.props.id;
      params.search = items.search;

      getItems(params).then(() => changeItemsPerPage(params.perPage));
    };
  };

  onTableSortChange = (companyField, getItems, changeItemsSort) => {
    return (name, order) => {
      const items = this.props.company[companyField];
      const params = {
        companyId: this.props.id,
        page: items.meta.current_page,
        perPage: items.perPage,
        sort: {name, order},
        search: items.search,
      };

      getItems(params).then(() => changeItemsSort(params.sort));
    };
  };

  onTableSearchChange = (companyField, getItems, changeItemsSearch) => {
    return (value) => {
      const items = this.props.company[companyField];
      const params = {
        companyId: this.props.id,
        page: 1,
        perPage: items.perPage,
        sort: items.sort,
        search: value,
      };

      getItems(params).then(() => changeItemsSearch(params.search));
    };
  };

  toggleStateItem = (item) => {
    return () =>
      this.setState({
        [item]: !this.state[item],
      });
  };

  handleEditBasicInformationClick = () => {
    this.setState({
      editBasicInformation: !this.state.editBasicInformation,
    });
    this.props.getSubsidiaries();
    this.props.getAgencies();
    this.props.getCompanyMemberships(this.props.id);
    this.props.getAdminUsers();
  };

  handleSaveBasicInformationClick = (data) => {
    return this.props
      .patchCompany(data.company.id, data)
      .then(() => {
        this.handleEditBasicInformationClick();
        this.props.setBreadcrumbs([
          {
            name: this.props.langBC.CLIENTS,
            href: CLIENT_PAGES.CLIENTS_LIST,
          },
          {
            name: data.name,
          },
        ]);
      })
      .catch((error) => {
        throw error;
      });
  };

  handleClientNetworkChange = (value) => {
    const data = {
      company: {
        client_network: value,
      },
    };

    // reset user access levels to client_admin on client network switch off
    if (!value && !get(this, 'props.company.company.auto_configuration', false)) {
      data.company.user_access_levels = [ACCESS_LEVEL.CLIENT_ADMIN];
    }

    this.props.patchCompany(this.props.id, data);
  };

  handleUserCheck = (index, checked) => {
    const users = this.props.company.agencyMemberships;
    users[index].checked = checked;
  };

  handleAddStoresChange = (value) => {
    const data = {
      company: {
        add_places: value,
      },
    };
    return this.props.patchCompany(this.props.id, data);
  };

  setReInviteUserId = (reInviteUserId) => {
    this.setState({reInviteUserId});
  };

  handleNewUserModalClose = () => {
    this.handleResetUsersSearch();
    const page = get(this.props.company, 'users.meta.current_page', 1);

    this.props.setDefaultNewUser();
    this.updateMe();
    this.updateUsers(page);
  };

  handleNewUserModalSave = () => {
    const {reInviteUserId} = this.state;

    if (reInviteUserId) {
      this.props.resendInvitation(reInviteUserId);
      this.setState({reInviteUserId: null});
    }

    this.handleNewUserModalClose();
  };

  handleDeleteUsersClick = () => {
    const {id: clientId, company} = this.props;
    const {
      users: {data: users},
      selectedUsers,
    } = company;

    const usersToDelete = [];
    const usersWithMemberships = [];

    // Using an access pool to reduce data access time from O(n^2) to O(n)
    const usersPool = {};
    users.forEach((user) => {
      usersPool[user.id] = user;
    });

    selectedUsers.forEach((id) => {
      const clientUser = usersPool[id].client_user;
      const {operations = [], full_name} = clientUser;

      const hasConnectedOperation = operations.some(({client_id}) => client_id === clientId);

      if (hasConnectedOperation) {
        usersWithMemberships.push({id, full_name});
      } else {
        usersToDelete.push(clientUser);
      }
    });

    this.setState(
      {
        selectedUsersWithMemberships: usersWithMemberships,
        selectedUsersWithoutMemberships: usersToDelete,
        showDeleteUsersModal: usersWithMemberships.length > 0,
      },
      () => {
        if (usersWithMemberships.length < 1) {
          this.handleDeleteUsersConfirm();
        }
      },
    );
  };

  handleDeleteUsersConfirm = () => {
    const newState = {showDeleteUsersModal: false};

    if (this.state.selectedUsersWithoutMemberships.length) {
      newState.deletingUser = {
        index: 0,
        user: this.state.selectedUsersWithoutMemberships[0],
        soft: false,
      };
    }

    this.setState(newState);
  };

  onChangeUserClick = (user) => {
    this.setState({
      showEditUserModal: true,
      editingUser: {
        ...user,
        first_name: user.client_user.first_name,
        last_name: user.client_user.last_name,
        email: user.client_user.email,
        civility: {value: user.client_user.title},
      },
    });
  };

  returnToEditUserModal = () => {
    this.setState({
      showEditUserModal: true,
      showEditCompanyAccessModal: 0,
    });
  };

  handleOpenEditAccessLevelModal = () => {
    const {company} = this.props.company;
    if (company && company.agency && company.agency.id) {
      this.props
        .getAgencyMemberships(company.agency.id)
        .then(this.toggleStateItem('showEditAccessLevelToCompanyModal'));
    }
  };

  onNewOperationAdded = () => {
    this.setState({showAddNewOperationModal: false});
    const ongoingParams = {
      sort: this.props.company.ongoingOperations.sort,
      companyId: this.props.id,
      page: this.props.company.ongoingOperations.meta.current_page,
      perPage: this.props.company.ongoingOperations.perPage,
    };
    this.props.getOngoingOperations(ongoingParams);
    const finishedParams = {
      sort: this.props.company.finishedOperations.sort,
      companyId: this.props.id,
      page: this.props.company.finishedOperations.meta.current_page,
      perPage: this.props.company.finishedOperations.perPage,
    };
    this.props.getFinishedOperations(finishedParams);
  };

  handleShowModal = (name) => {
    this.setState({visibleModal: name});
  };

  handleCloseModal = () => this.setState({visibleModal: ''});

  handleDeleteReport = (id, isImport) => {
    this.setState({
      reportForDelete: {id, isImport},
      showDeleteReport: true,
    });
  };

  getReports = () => {
    this.props.getLeadsReports({
      include: {
        user: null,
        lead_transfer: {
          memberships: {
            client_user: null,
          },
        },
      },
      q: {
        lead_transfer_client_id_eq: this.props.id,
        s: 'created_at DESC',
      },
    });
  };

  onDeleteReport = ({id, isImport}) => {
    if (isImport) {
      this.props.deleteApiLeadsImport(id).then(() => {
        this.getReports();
      });
    } else {
      this.props.deleteApiLeadsExport(id).then(() => {
        this.getReports();
      });
    }
    this.setState({showDeleteReport: false});
  };

  handleResetUsersSearch = () => {
    const onUsersSearchChange = this.onTableSearchChange('users', this.props.getUsers, this.props.changeUsersSearch);
    onUsersSearchChange('');
    this.setState({resetUsersSearch: true});
  };

  render() {
    const {visibleModal, deletingUser, selectedUsersWithoutMemberships} = this.state;
    const {
      lang,
      changeSelectedUsers,
      getUsers,
      getOngoingOperations,
      getFinishedOperations,
      patchCompany,
      changeUsersPerPage,
      changeOngoingOperationsPerPage,
      changeFinishedOperationsPerPage,
      changeUsersSort,
      changeOngoingOperationsSort,
      changeFinishedOperationsSort,
      changeUsersSearch,
      isAdmin,
      optIns,
      usersFullList,
      languageState,
    } = this.props;
    const adminId = this.props.userState.payload.user.id;
    const {
      selectedUsers,
      users,
      ongoingOperations,
      finishedOperations,
      POCsAgency,
      subsidiaries,
      agencies,
      companyMemberships,
      adminUsers,
      company,
      clientUser,
      agencyMemberships,
    } = this.props.company;

    const {editingOptIn, reportForDelete, isLoading} = this.state;

    const onUsersPageChange = this.onTablePageChange('users', getUsers, changeUsersPerPage);
    const onOngoingOperationsPageChange = this.onTablePageChange(
      'ongoingOperations',
      getOngoingOperations,
      changeOngoingOperationsPerPage,
    );
    const onFinishedOperationsPageChange = this.onTablePageChange(
      'finishedOperations',
      getFinishedOperations,
      changeFinishedOperationsPerPage,
    );

    const onUsersSortChange = this.onTableSortChange('users', getUsers, changeUsersSort);
    const onOngoingOperationsSortChange = this.onTableSortChange(
      'ongoingOperations',
      getOngoingOperations,
      changeOngoingOperationsSort,
    );
    const onFinishedOperationsSortChange = this.onTableSortChange(
      'finishedOperations',
      getFinishedOperations,
      changeFinishedOperationsSort,
    );

    const onUsersSearchChange = this.onTableSearchChange('users', getUsers, changeUsersSearch);

    return (
      <div className={b()}>
        <LeadsImportFileModal
          show={visibleModal === Company.MODALS_MAP.IMPORT_FILE_MODAL}
          onClose={this.handleCloseModal}
          onConfirm={() => this.handleShowModal(Company.MODALS_MAP.IMPORT_SUMMARY_MODAL)}
        />
        <LeadsPortSummaryModal
          show={visibleModal === Company.MODALS_MAP.IMPORT_SUMMARY_MODAL}
          onClose={this.handleCloseModal}
        />
        <NewUserStep1Modal
          show={this.state.addNewUserStep === 1}
          onClose={this.handleNewUserModalClose}
          onCancel={this.handleNewUserModalClose}
          onNext={({email, ...s}) => this.props.getClientUser(email).then(() => this.setState(s))}
          clientId={this.props.id}
          totalSteps={Company.NUMBER_OF_STEPS}
        />
        <NewUserStep2Modal
          user={this.state.addNewUserObject}
          show={this.state.addNewUserStep === 2}
          onClose={this.handleNewUserModalClose}
          onCancel={this.handleNewUserModalClose}
          initialValues={clientUser}
          onNext={(s) => this.setState(s)}
          totalSteps={Company.NUMBER_OF_STEPS}
          getClientUser={() => this.props.getClientUser(clientUser.email)}
          onReactivate={this.setReInviteUserId}
        />
        <NewUserStep3Modal
          user={this.state.addNewUserObject}
          show={this.state.addNewUserStep === 3}
          adminId={adminId}
          initialValues={clientUser}
          onClose={this.handleNewUserModalClose}
          onBack={() => this.setState({addNewUserStep: 2})}
          clientId={this.props.id}
          onCreate={(s) => (s ? this.setState(s) : this.handleNewUserModalSave())}
        />
        <NewUserStep3Modal
          user={this.state.editingUser}
          show={this.state.showEditCompanyAccessModal === 1}
          adminId={adminId}
          editingUser={true}
          clientUser={clientUser}
          initialValues={this.state.editingUser}
          onClose={this.returnToEditUserModal}
          onBack={this.returnToEditUserModal}
          onCreate={(s) => this.setState(s)}
        />
        <NewUserAccessRegionsModal
          user={this.state.addNewUserObject}
          show={this.state.addNewUserStep === 31}
          adminId={adminId}
          regions={company?.regions}
          clientUser={clientUser}
          onClose={this.handleNewUserModalClose}
          onCreate={this.handleNewUserModalSave}
          onBack={() => this.setState({addNewUserStep: 3})}
        />
        <NewUserAccessStoresModal
          user={this.state.addNewUserObject}
          show={this.state.addNewUserStep === 32}
          adminId={adminId}
          clientUser={clientUser}
          regionsWithStores={company && company.regions.filter((r) => r.places && r.places.length > 0)}
          freeStores={company && company.places.filter((s) => s.region_id === null)}
          onClose={this.handleNewUserModalClose}
          onCreate={this.handleNewUserModalSave}
          onBack={() => this.setState({addNewUserStep: 3})}
        />

        <EditUserModal
          show={this.state.showEditUserModal}
          editingUser={this.state.editingUser}
          initialValues={this.state.editingUser}
          onClose={this.handleNewUserModalClose}
          onSave={this.updateMe}
          onEditAccessAgency={(s) => this.setState(s)}
        />

        <NewUserAccessRegionsModal
          user={this.state.editingUser}
          show={this.state.showEditCompanyAccessModal === 2}
          editingUser
          clientUser={clientUser}
          adminId={adminId}
          regions={company?.regions}
          onClose={this.returnToEditUserModal}
          onCreate={(s) => this.setState(s)}
          onBack={() => this.setState({showEditCompanyAccessModal: 1})}
        />

        <NewUserAccessStoresModal
          user={this.state.editingUser}
          show={this.state.showEditCompanyAccessModal === 3}
          adminId={adminId}
          clientUser={clientUser}
          regionsWithStores={
            company &&
            mapRegionsWithStores(
              this.state.editingUser,
              company.regions.filter((r) => r.places && r.places.length > 0),
            )
          }
          freeStores={
            company &&
            mapFreeStores(
              this.state.editingUser,
              company.places.filter((s) => s.region_id === null),
            )
          }
          editingUser={true}
          onClose={this.returnToEditUserModal}
          onCreate={(s) => this.setState(s)}
          onBack={() => this.setState({showEditCompanyAccessModal: 1})}
        />

        <ConfirmationModal
          show={this.state.showDeleteReport}
          onClose={() => this.setState({showDeleteReport: false})}
          onCancel={() => this.setState({showDeleteReport: false})}
          title={lang.DELETE_REPORT_TITLE_TEXT}
          message={lang.DELETE_REPORT_MODAL_TEXT}
          cancelText={lang.DELETE_CLIENTS_PAGE_MODAL_CANCEL}
          confirmText={lang.DELETE_CLIENTS_PAGE_MODAL_CONFIRM}
          className="modal-window--theme-1"
          buttonConfirmClass="button--bg-1"
          buttonCancelClass="button--bg-5"
          onConfirm={() => this.onDeleteReport(reportForDelete)}
        />

        <ConfirmationModal
          show={this.state.showDeleteCompanyModal}
          onClose={() => this.setState({showDeleteCompanyModal: false})}
          onCancel={() => this.setState({showDeleteCompanyModal: false})}
          title={lang.DELETE_CLIENTS_PAGE_MODAL_TITLE}
          message={lang.DELETE_CLIENTS_PAGE_MODAL_TEXT}
          cancelText={lang.DELETE_CLIENTS_PAGE_MODAL_CANCEL}
          confirmText={lang.DELETE_CLIENTS_PAGE_MODAL_CONFIRM}
          errorMessage={this.state.errorMessageDeleteClientModal}
          buttonConfirmDisabled={!!this.state.errorMessageDeleteClientModal}
          className="modal-window--theme-1"
          buttonConfirmClass="button--bg-1"
          buttonCancelClass="button--bg-5"
          onConfirm={this.deleteCompany}
        />

        <DeleteUsersModal
          show={this.state.showDeleteUsersModal}
          onClose={() => {
            this.setState({showDeleteUsersModal: false});
            this.props.changeSelectedUsers([]);
            this.handleResetUsersSearch();
            this.updateUsers();
          }}
          errorMessage={this.state.errorMessageDeleteUserModal}
          users={this.state.selectedUsersWithMemberships}
          onConfirm={this.handleDeleteUsersConfirm}
        />

        <EditAccessLevelModal
          show={this.state.showEditAccessLevelToCompanyModal}
          onClose={this.toggleStateItem('showEditAccessLevelToCompanyModal')}
          users={agencyMemberships}
          clientId={+this.props.id}
          companyName={company && company.name}
          agencyName={company && company.agency && company.agency.name}
          onCheck={this.handleUserCheck}
          onSave={this.handleEditAccessLevelSave}
        />

        <AddNewOperationModal
          show={this.state.showAddNewOperationModal}
          onClose={() => this.setState({showAddNewOperationModal: false})}
          onConfirm={this.onNewOperationAdded}
          clientId={this.props.id}
          isClientNetwork={company && company.client_network}
        />

        {this.state.showAddNewOptInModal && (
          <AddNewOptInModal
            onClose={() => this.setState({showAddNewOptInModal: false})}
            onConfirm={() => this.setState({showAddNewOptInModal: false}, this.updateMe)}
            onDelete={() => this.setState({showAddNewOptInModal: false, showDeleteOptInModal: true})}
            editingItem={editingOptIn}
            clientId={this.props.id}
          />
        )}

        <ConfirmationModal
          show={this.state.showDeleteOptInModal}
          onClose={() => this.setState({showDeleteOptInModal: false, showAddNewOptInModal: true})}
          onCancel={() => this.setState({showDeleteOptInModal: false, showAddNewOptInModal: true})}
          message={lang.DELETE_OPT_IN_MODAL.BODY_TEXT}
          title={lang.DELETE_OPT_IN_MODAL.TITLE}
          cancelText={lang.CANCEL_BUTTON}
          confirmText={lang.DELETE_OPT_IN_MODAL.CONFIRM}
          className="theme-color-2"
          buttonConfirmClass="button--bg-2"
          buttonCancelClass="button--bg-11"
          onConfirm={() => deleteMethod(`${API_METHODS.OPT_IN_COLUMNS}/${editingOptIn.id}`).then(this.updateMe)}
        />

        {this.state.showSelectUserModal && (
          <SelectUserModal
            show={this.state.showSelectUserModal}
            users={usersFullList}
            clientId={this.props.id}
            onClose={() => this.setState({showSelectUserModal: false})}
          />
        )}

        <div className="row">
          <div className="col-xs-9">
            <div className={b('title')}>
              <TitleBlock theme separator>
                <TitleBlock.Item>
                  {company && `${company.name} ${company.brand_name}`}
                  {isAdmin && ` ${lang.AGENCY_CLIENTS_ID.replace('{N}', this.props.id)}`}
                </TitleBlock.Item>
                {company && company.agency && isAdmin && (
                  <TitleBlock.Item>
                    {lang.CLIENT_OF_AGENCY_TITLE}{' '}
                    <Link to={CLIENT_PAGES.AGENCIES + '/' + company.agency.id} className={b('title-users')}>
                      {company.agency.name}
                    </Link>
                  </TitleBlock.Item>
                )}
                {company && company.agency && isAdmin && (
                  <TitleBlock.Item>
                    {lang.AGENCY_USERS_TITLE}{' '}
                    <span className={b('title-users')} onClick={this.handleOpenEditAccessLevelModal}>
                      {company.company_accesses.length}
                    </span>
                  </TitleBlock.Item>
                )}
                <button className="button button--bg-1" onClick={() => this.setState({showSelectUserModal: true})}>
                  <span className="bold">{lang.AGENCY_SHOW_USER_MODAL_BUTTON}</span>
                </button>
              </TitleBlock>
            </div>
          </div>
          <div className="col-xs-3">
            {isAdmin && [
              <div className="pull-right" key="icon">
                <button
                  className="button button--circle button--bg-1 button--delete"
                  onClick={() => this.setState({showDeleteCompanyModal: true})}
                >
                  <Icon name="trash" className="button__icon" width={17} height={19} />
                </button>
              </div>,
              <AddLogoButton
                key="add-logo-button"
                clientId={this.props.id}
                updateMe={this.updateMe}
                buttonText={lang.ADD_LOGO_BUTTON}
              />,
            ]}
          </div>
        </div>
        <div className={b('top')}>
          <div className="page__block-container">
            <div className={`page__block page__block--size-${isAdmin ? 1 : 3}`}>
              <BasicInformationCard
                onEditClick={this.handleEditBasicInformationClick}
                editMode={this.state.editBasicInformation}
                isAdmin={isAdmin}
              >
                <BasicInformationForm
                  edit={this.state.editBasicInformation}
                  onCancel={this.handleEditBasicInformationClick}
                  onSave={this.handleSaveBasicInformationClick}
                  subsidiaries={subsidiaries}
                  agencies={agencies}
                  POCsAgency={POCsAgency}
                  getPOCsAgency={this.props.getPOCsAgency}
                  memberships={companyMemberships}
                  adminUsers={adminUsers}
                  isAdmin={isAdmin}
                />
              </BasicInformationCard>
            </div>
            <div className="page__block page__block--size-1">
              <div className="page__block--height-2">
                <RegionsCard
                  isAdmin={isAdmin}
                  data={company?.regions}
                  onSave={(regions) => patchCompany(this.props.id, {company: {regions}})}
                  toggleValue={company?.client_network}
                  onToggleChange={this.handleClientNetworkChange}
                  toggleEnabled={company?.client_network_possible}
                  isLoading={isLoading}
                />
              </div>
              <StoresCard
                languageState={languageState}
                onSwitcherChange={this.handleAddStoresChange}
                switcherValue={company && company.add_places}
                storesCount={company && company.places && company.places.length}
                isAdmin={isAdmin}
              />
            </div>
            <div className="page__block page__block--size-2">
              <div className="page__block--height-2">
                <SocialNetworkAccountsCard
                  data={get(company, 'social_accounts')}
                  accountsCount={get(company, 'social_accounts.length')}
                  refresh={this.updateMe}
                  clientId={this.props.id}
                  isAdmin={isAdmin}
                />
              </div>
              {isAdmin && (
                <VisualsCard
                  visualsCount={company && company.visuals_count}
                  title={lang.VISUALS_CARD.TITLE}
                  goToLinkText={lang.VISUALS_CARD.GO_TO_VISUALS_LINK}
                  totalText={lang.VISUALS_CARD.TOTAL_TEXT}
                  itemText={lang.VISUALS_CARD.NO_OF_VISUALS_TEXT}
                />
              )}
            </div>
            {isAdmin && [
              <div className="page__block page__block--size-2" key="phones-email">
                <PhonesEmailsCard
                  data={company && company.message_senders}
                  refresh={this.updateMe}
                  clientId={this.props.id}
                />
              </div>,
              <div className="page__block page__block--size-1" key="leads">
                <LeadsCard
                  languageState={languageState}
                  client={company}
                  leadsReports={this.props.leadsReports}
                  clientId={this.props.id}
                  leadsTotalCount={this.props.leadsCount}
                  deleteReport={this.handleDeleteReport}
                  getReports={this.getReports}
                  onImportFile={() => this.handleShowModal(Company.MODALS_MAP.IMPORT_FILE_MODAL)}
                  columnAdapters={this.props.allColumns}
                  clientUsers={this.props.clientUsers}
                  generalColumns={this.generalColumns()}
                />
              </div>,
              <div className="page__block page__block--size-1" key="opt-ins">
                <OptInsCard
                  languageState={languageState}
                  data={optIns}
                  onAddClick={() => this.setState({showAddNewOptInModal: true, editingOptIn: {}})}
                  onEditClick={(res) => this.setState({showAddNewOptInModal: true, editingOptIn: res})}
                />
                <PasswordExpirationCard />
              </div>,
              <div className="page__block page__block--size-2" key="mailing-card">
                <MailingCard
                  data={company && company.broadcast_lists}
                  refresh={this.updateMe}
                  clientId={this.props.id}
                />
              </div>,
              <div className="page__block page__block--size-2" key="auto-creation-access">
                {!!company && (
                  <AutoCreationAccessCard
                    clientType="company"
                    clientId={this.props.id}
                    isLoading={isLoading}
                    patchClient={patchCompany}
                    accessLevels={company?.user_access_levels}
                    couponTypes={company?.available_coupons}
                    permissions={{
                      crm_management: company?.crm_management,
                      user_creation_allowed: company?.user_creation_allowed,
                    }}
                    autoConfiguration={company?.auto_configuration === true}
                  />
                )}
              </div>,
            ]}
          </div>
        </div>

        <OngoingOperationsBlock
          languageState={languageState}
          ongoingOperations={ongoingOperations}
          finishedOperations={finishedOperations}
          onOngoingOperationsPageChange={onOngoingOperationsPageChange}
          onOngoingOperationsSortChange={onOngoingOperationsSortChange}
          onFinishedOperationsPageChange={onFinishedOperationsPageChange}
          onFinishedOperationsSortChange={onFinishedOperationsSortChange}
          onAddNew={() => this.setState({showAddNewOperationModal: true})}
        />

        <UsersTable
          clientAgency
          addNewClick={() => this.setState({addNewUserStep: 1})}
          deleteUsersClick={this.handleDeleteUsersClick}
          changeSelectedRecords={changeSelectedUsers}
          selectedRecords={selectedUsers}
          needToUpdate={users.needToUpdate}
          data={Company.mapUsersData(users.data)}
          totalPages={users.meta && users.meta.total_pages}
          totalItems={users.meta && users.meta.total_count}
          currentPage={users.meta && users.meta.current_page}
          onPageChange={onUsersPageChange}
          onSortChange={onUsersSortChange}
          perPage={users.perPage}
          onSearch={onUsersSearchChange}
          onChangeUserClick={this.onChangeUserClick}
          isAdmin={isAdmin}
          resetUsersSearch={this.state.resetUsersSearch}
          onResetUsersSearch={() => this.setState({resetUsersSearch: false})}
        />
        {company?.id && (
          <ClientUserDeactivationWizard
            currentUser={deletingUser}
            clientId={company.id}
            allUsers={selectedUsersWithoutMemberships}
            changeCurrentUser={(data) => this.setState({deletingUser: data})}
            fetchUsers={() => {
              this.props.changeSelectedUsers([]);
              this.handleResetUsersSearch();
              this.updateUsers();
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  languageState: state.languageState,
  lang: state.languageState.payload.AGENCY,
  langBC: state.languageState.payload.BREADCRUMBS,
  langLeads: state.languageState.payload.LEADS_LIST,
  company: state.company,
  optIns: selectOptIns(state),
  isAdmin: selectIsAdmin(state),
  leadsCount: selectLeadsTotalCount(state),
  userState: state.userState,
  leadsReports: selectImportExportReports(state),
  allColumns: selectColumnAdaptersForImportExport(state),
  clientUsers: selectClientUsers(state, ownProps.id),
  usersFullList: selectUsersFullList(state),
});

const mapDispatchToProps = {
  changeSelectedUsers: changeSelectedUsersAction,

  changeUsersPerPage: changeUsersPerPageAction,
  changeOngoingOperationsPerPage: changeOngoingOperationsPerPageAction,
  changeFinishedOperationsPerPage: changeFinishedOperationsPerPageAction,

  changeUsersSort: changeUsersSortAction,
  changeOngoingOperationsSort: changeOngoingOperationsSortAction,
  changeFinishedOperationsSort: changeFinishedOperationsSortAction,

  changeUsersSearch: changeUsersSearchAction,

  getUsers: getUsersAction,
  getLeads,
  getOngoingOperations: getOngoingOperationsAction,
  getFinishedOperations: getFinishedOperationsAction,
  getSubsidiaries: getSubsidiariesAction,
  getAgencies: getAgenciesAction,
  getCompanyMemberships: getCompanyMembershipsAction,
  getAgencyMemberships: getAgencyMembershipsAction,
  getAdminUsers: getAdminUsersAction,
  getOptIns: getOptInsAction,
  getCompany: getCompanyAction,
  getCompanyUsers: getCompanyUsersAction,
  patchCompany: patchCompanyAction,
  deleteCompany: deleteCompanyAction,
  getClientUser: getClientUserAction,
  clearStore: clearStoreAction,
  getPOCsAgency: getPOCsAgencyAction,
  setDefaultNewUser: setDefaultNewUserAction,
  setBreadcrumbs,
  getLeadsReports,
  clearBreadcrumbs,
  getAvailableColumnAdaptersByClient,
  deleteApiLeadsExport,
  deleteApiLeadsImport,
  getClientUsersWithMemberships: getClientUsersWithMembershipsAction,
  resendInvitation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
