import {useCallback, useMemo, useRef} from 'react';

import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import qs from 'qs';
import {useHistory, useLocation} from 'react-router-dom';
import {usePrevious} from 'react-use';

import {QueryParams, UseQueryParams} from 'client/services/hooks/useQueryParams/types';

/**
 * @callback QueryParamsSetter
 * @param {QueryParams} newQueryParams
 * @param {boolean?} replace
 * @param {boolean?} preventScroll
 * @returns {void}
 */

/**
 * Hook for storing and getting query params.
 * @param {*?} _ - Deprecated
 * @param {QueryParams?} [initialValues = {}] - Initial values for queryParams
 * @param {Object?} [options = {}] - qs options for serializing and deserializing
 * @param {Object} [options.parse = {}] - qs options for deserializing
 * @param {Object} [options.stringify = {}] - qs options for serializing
 * @returns {[QueryParams, QueryParamsSetter]} - An array with a query params values object and a setter function
 */
export const useQueryParams: UseQueryParams = (_, initialValues = {}, options = {}) => {
  const history = useHistory();
  const {search} = useLocation();

  const {parse: parseOptions = {}, stringify: stringifyOptions = {}} = options;

  // put object type values into refs to avoid recreation on hook re-render
  const initialValuesRef = useRef(initialValues);
  const parseOptionsRef = useRef(parseOptions);
  const stringifyOptionsRef = useRef(stringifyOptions);

  const queryParams = useMemo(() => {
    const {
      ignoreQueryPrefix = true,
      depth = 1,
      parseBooleans = false,
      parseNumbers = false,
      ...restOptions
    } = parseOptionsRef.current;

    const query = qs.parse(search, {ignoreQueryPrefix, depth, ...restOptions});
    const parsedQuery = parseValues(query, {parseBooleans, parseNumbers});

    return {...initialValuesRef.current, ...parsedQuery};
  }, [search, initialValuesRef]);

  const prevQueryParams = usePrevious(queryParams);

  const setQueryParams = useCallback(
    (newQueryParams, replace = false, preventScroll = false) => {
      const {
        addQueryPrefix = true,
        skipNulls = true,
        encode = false,
        skipEmptyString,
        ...restOptions
      } = stringifyOptionsRef.current;

      // remove params from initialValuesRef as soon as they are set to another value
      // this is done to avoid returning an initial value for params set to null to remove from url
      if (Object.keys(initialValuesRef.current)) {
        const values = initialValuesRef.current;

        for (const key in newQueryParams) {
          if (Object.prototype.hasOwnProperty.call(newQueryParams, key)) {
            delete values[key];
          }
        }

        initialValuesRef.current = values;
      }

      let params = {...queryParams, ...newQueryParams};

      if (skipEmptyString) {
        params = deepSkipEmpty(params);
      }

      let query = qs.stringify(params, {addQueryPrefix, skipNulls, encode, ...restOptions});
      const prevQueryString = qs.stringify(prevQueryParams, {addQueryPrefix, skipNulls, encode, ...restOptions});

      // don't update history if the query has not changed
      const hasQueryChanged = prevQueryString !== query;

      if (hasQueryChanged) {
        query = !query ? history.location?.pathname : query;
        const position = document.documentElement.scrollTop || document.body.scrollTop;

        if (replace) {
          history.replace(query);
        } else {
          history.push(query);
        }

        if (preventScroll) {
          // restore scroll position
          setTimeout(() => window.scrollTo(0, position), 1);
        }
      }
    },
    [queryParams, prevQueryParams, history],
  );

  return [queryParams, setQueryParams];
};

function parseValues(values: QueryParams, options: {parseBooleans: boolean; parseNumbers: boolean}) {
  const {parseBooleans, parseNumbers} = options;

  return mapValues(values, (value) => {
    let parsedValue = value;

    if (isArray(parsedValue)) {
      parsedValue = parsedValue.map((i) => parseValues({value: i}, options).value);
    } else if (isObject(parsedValue)) {
      parsedValue = parseValues(parsedValue, options);
    } else if (parseBooleans && (value === 'true' || value === 'false')) {
      parsedValue = value === 'true';
    } else if (parseNumbers && !Number.isNaN(Number(value)) && typeof value === 'string' && value.trim()) {
      parsedValue = Number(value);
    }

    return parsedValue;
  });
}

function deepSkipEmpty(values: Record<string, any>) {
  const nextValues: Record<string, any> = {};

  Object.entries(values).forEach(([key, value]) => {
    if (isArray(value)) {
      const filtered = value.filter(Boolean);
      if (filtered.length) {
        nextValues[key] = filtered;
      }
    } else if (isObject(value)) {
      const filtered = deepSkipEmpty(value);
      if (Object.values(filtered).length) {
        nextValues[key] = filtered;
      }
    } else if (value) {
      nextValues[key] = value;
    }
  });

  return nextValues;
}
