import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {
  deleteDeviceAction,
  getDeviceFamiliesAction,
  getDevicesAction,
  patchDeviceAction,
  postDeviceAction,
  postDeviceFamilyAction,
} from 'client/ducks/devices/actions';

import TitleBlock from 'client/components/common/title-block';

import AddNewDeviceModal from './components/modals/add-new-device-modal';
import DeleteDevicesModal from './components/modals/delete-devices-modal';
import ManageTypesModal from './components/modals/manage-types-modal';
import DevicesTable from './components/tables/devices-table';

// this mode is temporary, so it will be defined here.
const READ_ONLY_MODE = true;

class DevicesContainer extends ReactQueryParams {
  constructor(props) {
    super(props);

    this.state = {
      editingDevice: {},
      showDeleteDevicesModal: false,
      showManageTypesModal: false,
      showNewDeviceModal: false,

      selectedDevices: [],
    };

    this.LANGUAGE = props.languageState.payload.DEVICES;
  }

  componentDidMount() {
    this.getMyDevices();
  }

  getMyDevices = () => {
    const {queryParams: params} = this;
    window.scrollTo(0, 0);
    this.props.getDevices({
      page: params.page || 1,
      perPage: +params.perPage || 5,
      sort: {
        name: (params.sort && params.sort.name) || 'name',
        order: (params.sort && params.sort.order) || 'ASC',
      },
    });
  };

  onPageChange = (params) => {
    this.setQueryParams(params, true);
    this.getMyDevices();
  };

  onSortChange = (name, order) => {
    this.setQueryParams(
      {
        sort: {name, order},
      },
      true,
    );
    this.getMyDevices();
  };

  onDeleteDevicesClick = () => {
    for (let deviceId of this.state.selectedDevices) {
      this.props.deleteDevice(deviceId);
    }
    this.setState({
      selectedDevices: [],
    });
    this.setState({showDeleteDevicesModal: false});
  };

  // "add device" button above the table
  onAddDeviceButtonClick = () => {
    this.props.getDeviceFamilies().then(() => this.setState({showNewDeviceModal: true}));
  };

  // "create" button in modal
  onNewDeviceClick = (props) => {
    if (isEmpty(this.state.editingDevice)) {
      this.props
        .postDevice({
          device: {
            ...props,
          },
        })
        .then(() => {
          if (this.props.devices.isCodeNew[0] && this.props.devices.isLogisticIdNew[0]) {
            this.setState({showNewDeviceModal: false});
          }
        });
    } else {
      this.props
        .patchDevice(this.state.editingDevice.id, {
          device: {
            ...props,
          },
        })
        .then(() => {
          if (this.props.devices.isCodeNew[0] && this.props.devices.isLogisticIdNew[0]) {
            this.setState({
              showNewDeviceModal: false,
              editingDevice: {},
            });
          }
        });
    }
  };

  onEditDeviceClick = (editingDevice) => {
    this.props.getDeviceFamilies().then(() =>
      this.setState({
        editingDevice: {
          ...editingDevice,
          status: {
            value: editingDevice.status,
            label: this.LANGUAGE.DEVICE_STATUSES[editingDevice.status],
          },
          type: editingDevice.device_family,
        },
        showNewDeviceModal: true,
      }),
    );
  };

  onManageTypesButtonClick = () => {
    const params = {include: ['devices']};
    this.props.getDeviceFamilies(params).then(() => this.setState({showManageTypesModal: true}));
  };

  onSaveTypes = () => {
    this.getMyDevices();
    this.setState({showManageTypesModal: false});
  };

  render() {
    const {devices, meta, needToUpdateDevices, device_families, isCodeNew, isLogisticIdNew} = this.props.devices;

    const {LANGUAGE} = this;

    if (needToUpdateDevices) {
      this.getMyDevices();
    }

    return (
      <div className="theme-color-devices">
        <div className="page__title-block">
          <TitleBlock theme>
            <TitleBlock.Item className="">
              {LANGUAGE.DEVICES_TITLE} ({meta.total_count})
            </TitleBlock.Item>
          </TitleBlock>
        </div>
        <DevicesTable
          needToUpdateItems={needToUpdateDevices}
          data={devices}
          readOnly={READ_ONLY_MODE}
          perPage={Number(this.queryParams.perPage) || 5}
          currentPage={meta.current_page}
          totalPages={meta.total_pages}
          selectedItems={this.state.selectedDevices}
          changeSelectedItems={(selectedDevices) => this.setState({selectedDevices})}
          onPageChange={this.onPageChange}
          deleteDevicesButtonClick={() => this.setState({showDeleteDevicesModal: true})}
          onEditDeviceClick={this.onEditDeviceClick}
          manageTypesButtonClick={this.onManageTypesButtonClick}
          addDeviceButtonClick={this.onAddDeviceButtonClick}
          onSortChange={this.onSortChange}
          totalItems={meta.total_count}
          sort={this.queryParams.sort}
        />

        <DeleteDevicesModal
          show={this.state.showDeleteDevicesModal}
          onCancel={() => this.setState({showDeleteDevicesModal: false})}
          onClose={() => this.setState({showDeleteDevicesModal: false})}
          onConfirm={this.onDeleteDevicesClick}
        />

        {this.state.showNewDeviceModal && (
          <AddNewDeviceModal
            show={true}
            onCancel={() => this.setState({showNewDeviceModal: false, editingDevice: {}})}
            onClose={() => this.setState({showNewDeviceModal: false, editingDevice: {}})}
            onCreate={this.onNewDeviceClick}
            initialValues={this.state.editingDevice}
            types={device_families}
            isLogisticIdNew={isLogisticIdNew}
            isCodeNew={isCodeNew}
            readOnlyReason={READ_ONLY_MODE ? LANGUAGE.REASON_READ_ONLY_DEVICE_PAGE : ''}
          />
        )}

        <ManageTypesModal
          show={this.state.showManageTypesModal}
          onCancel={() => this.setState({showManageTypesModal: false})}
          onClose={() => this.setState({showManageTypesModal: false})}
          onSave={this.onSaveTypes}
          types={device_families}
          patchDeviceFamily={this.props.patchDeviceFamily}
        />
      </div>
    );
  }
}

DevicesContainer.propTypes = {
  match: PropTypes.object,
  devices: PropTypes.shape({
    devices: PropTypes.array,
    device_families: PropTypes.array,
    isCodeNew: PropTypes.array,
    isLogisticIdNew: PropTypes.array,
  }),

  getDevices: PropTypes.func,
  getDeviceFamilies: PropTypes.func,
  postDeviceFamily: PropTypes.func,
  patchDevice: PropTypes.func,
  deleteDevice: PropTypes.func,
  postDevice: PropTypes.func,
  languageState: PropTypes.object,
};

const mapStateToProps = ({devices, languageState}) => ({
  devices,
  languageState,
});

const mapDispatchToProps = {
  getDevices: getDevicesAction,
  deleteDevice: deleteDeviceAction,
  patchDevice: patchDeviceAction,
  postDevice: postDeviceAction,
  postDeviceFamily: postDeviceFamilyAction,
  getDeviceFamilies: getDeviceFamiliesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicesContainer);
