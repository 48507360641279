import React, {useCallback, useRef} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';

import {TranslationJsx} from 'client/models/language/types';

import './file-button.scss';

const b = bem('file-button');

const FileButton = ({
  label,
  disabled,
  onChange,
  errorMessage,
  warningMessage = '',
  className,
  classNameButton,
  accept,
  required = false,
}) => {
  const inputRef = useRef(null);

  const onClick = useCallback(() => {
    inputRef.current.click();
  }, []);

  const onChangeHandler = useCallback(
    async (e) => {
      const file = e.target.files[0];
      if (file) {
        onChange(file);
      }
      // FIXME: UPDATE_ESLINT
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [onChange],
  );

  return (
    <React.Fragment>
      <div className={cn(b('container'), className)}>
        <button className={cn(b(), classNameButton)} onClick={onClick} type="button" disabled={disabled}>
          {label}
        </button>
        {!errorMessage && required && <RequiredLabel />}
        {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        {warningMessage && !errorMessage && <WarningMessage warningMessage={warningMessage} />}
      </div>
      <input
        ref={inputRef}
        name="add-file"
        type="file"
        onChange={onChangeHandler}
        className={b('input')}
        accept={accept}
      />
    </React.Fragment>
  );
};

FileButton.propTypes = {
  label: PropTypes.oneOfType([TranslationJsx, PropTypes.node]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
  warningMessage: TranslationJsx,
  classNameButton: PropTypes.string,
  accept: PropTypes.string,
  required: PropTypes.bool,
};

FileButton.defaultProps = {
  label: '',
  disabled: false,
  onChange: null,
  className: '',
  classNameButton: '',
  errorMessage: '',
  accept: '',
};

export default FileButton;
