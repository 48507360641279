import {useMemo} from 'react';

import {useSelector} from 'react-redux';

import {useLanguage, useReduxFetch} from 'client/services/hooks';
import {apiAction} from 'client/services/hooks/use-redux-fetch/requests';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {selectOperation} from 'client/ducks/operations/selectors';

import {API_METHODS} from 'client/common/config';
import {TextEditorVariable} from 'client/common/text-editor/types';

const variableNames = {
  crm: ['gender', 'first_name', 'last_name', 'email', 'phone', 'address1', 'address2', 'zip', 'city', 'country'],
  place: [
    'place_name',
    'place_city',
    'place_zip',
    'place_address',
    'place_phone',
    'place_email',
    'facebook',
    'website',
    'region',
  ],
  game: ['prize_name', 'code', 'description', 'email_image', 'popup_image', 'loyalty_amount', 'loyalty_unit', 'token'],
};

type ColumnsResponse = {
  operation_datum_columns: {name: string}[];
};

export default () => {
  const lang = useLanguage('DIY_OPERATION.MODALS.CONFIGURATION_MODAL.TEXT_VARIABLE_FAMILIES');
  const operation = useSelector(selectOperation);
  const autotask = useSelector(selectAutotask);

  const {data: customColumnsData} = useReduxFetch<ColumnsResponse>({
    action: apiAction,
    actionArgs: {
      url: API_METHODS.OPERATION_DATUM_COLUMNS,
      queryParams: {
        q: {operation_id_eq: operation?.id},
      },
    },
    skip: !operation?.id,
  });

  const variables: TextEditorVariable[] = useMemo(() => {
    const customColumns = customColumnsData?.operation_datum_columns || [];
    const hasCustom = operation?.id && customColumns.length;
    const hasInstantWin = operation?.id && autotask?.client_games?.find((i) => i.game_type === 'instant_win');
    const hasLottery = operation?.id && autotask?.client_games?.find((i) => i.game_type === 'prize_draw');

    const result = [
      {
        key: 'crm',
        label: lang.CRM,
        items: variableNames.crm,
      },
      {
        key: 'data',
        label: lang.DATA,
        items: hasCustom ? customColumns.map((i) => i.name) : [],
      },
      {
        key: 'place',
        label: lang.PLACE,
        items: variableNames.place,
      },
      {
        key: 'instant_win',
        label: lang.INSTANT_WIN,
        items: hasInstantWin ? variableNames.game.map((name) => `game1_${name}`) : [],
      },
      {
        key: 'lottery',
        label: lang.LOTTERY,
        items: hasLottery ? variableNames.game.map((name) => `game2_${name}`) : [],
      },
    ];

    return result.filter((i) => i.items.length);
  }, [autotask?.client_games, customColumnsData?.operation_datum_columns, lang, operation?.id]);

  return {variables};
};
