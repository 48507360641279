import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {connect} from 'react-redux';
import {reduxForm, reset, initialize} from 'redux-form';

import bem from 'client/services/bem';

import {getPrivacyPolicies} from 'client/ducks/privacy-policies/actions';
import {getLastPrivacyPolicy} from 'client/ducks/privacy-policies/selectors';

import AppButton from 'client/common/buttons/app-button';
import {SOCIAL_ACCOUNT_TYPES} from 'client/common/config';
import {CheckboxField, RadioGroupField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import cssModule from './add-new-social-account-modal.module.scss';

const b = bem('add-new-social-account-modal', {cssModule});

class AddNewSocialAccountModal extends Component {
  static formName = 'AddNewSocialAccountForm';

  static propTypes = {
    show: PropTypes.bool,
    className: PropTypes.string,
    radioColor: PropTypes.string,
    submitting: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    privacyPolicy: PropTypes.object,
    getPrivacyPolicies: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool,
  };

  static defaultProps = {
    show: false,
    isAdmin: false,
  };

  state = {
    checkedPrivacyPolicy: false,
  };

  componentDidMount() {
    this.getPrivacyPolicy();
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.props.initialize({type: SOCIAL_ACCOUNT_TYPES.FACEBOOK});
    }
  }

  getPrivacyPolicy = () => {
    const queryParams = {
      q: {
        s: 'created_at desc',
      },
    };
    this.props.getPrivacyPolicies(queryParams);
  };

  handleChangePrivacyPolicy = () =>
    this.setState({
      checkedPrivacyPolicy: !this.state.checkedPrivacyPolicy,
    });

  save = ({type}) => this.props.onConfirm(type);

  cancel = () => {
    this.props.onClose();
    return this.state.checkedPrivacyPolicy && this.handleChangePrivacyPolicy();
  };

  render() {
    const {handleSubmit, show, className, privacyPolicy, lang, radioColor} = this.props;
    const {checkedPrivacyPolicy} = this.state;

    return (
      <Modal show={show} className={cn(b(), className)} onClose={this.cancel} title={lang.TITLE}>
        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          <RadioGroupField
            legend={lang.SELECT_SOCIAL_NETWORK_LABEL}
            className={b('type')}
            classNameOption={b('type-option')}
            name="type"
            color={radioColor}
            direction="horizontal"
            withWrap={true}
            options={[
              {value: SOCIAL_ACCOUNT_TYPES.FACEBOOK, label: lang.FACEBOOK_LABEL},
              {value: SOCIAL_ACCOUNT_TYPES.TWITTER, label: lang.TWITTER_LABEL},
            ]}
          />
          <CustomScrollbars scrollbarProps={{autoHeightMax: 126}} cssModifier={b('scrollbars')}>
            <div className={b('policy-text')}>
              <ReactMarkdown escapeHtml={false}>{privacyPolicy ? privacyPolicy.text : '...'}</ReactMarkdown>
            </div>
          </CustomScrollbars>
          <CheckboxField
            label={lang.PRIVACY_POLICY}
            name="checkbox"
            checked={checkedPrivacyPolicy}
            onChange={this.handleChangePrivacyPolicy}
            required={true}
            withWrap={true}
            color={radioColor}
          />
          <div className={b('buttons')}>
            <AppButton
              type="submit"
              disabled={!checkedPrivacyPolicy}
              label={lang.NEXT_BUTTON}
              submit={true}
              color={radioColor}
            />
          </div>
        </form>
      </Modal>
    );
  }
}

const AddNewSocialAccountForm = reduxForm({
  form: AddNewSocialAccountModal.formName,
  validate: AddNewSocialAccountModal.validate,
})(AddNewSocialAccountModal);

export default connect(
  (state) => ({
    privacyPolicy: getLastPrivacyPolicy(state),
    lang: state.isAdmin
      ? state.languageState.payload.AGENCY.ADD_SOCIAL_ACCOUNT_MODAL
      : state.languageState.payload.ACCOUNT_PARAMETERS.SOCIAL_NETWORK.ADD_SOCIAL_ACCOUNT_MODAL,
  }),
  {
    reset,
    initialize,
    getPrivacyPolicies,
  },
)(AddNewSocialAccountForm);
