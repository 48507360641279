import isEmpty from 'lodash/isEmpty';

export const getUrlData = (clientDomains, availableDomains, interfaceId) => {
  if (isEmpty(clientDomains)) {
    return null;
  }

  const clientDomain = clientDomains?.filter((el) => el.interface_id === interfaceId);
  const clientDomainFiltered = clientDomain.sort((a, b) => {
    const prevDate = a.updated_at ? new Date(a.updated_at) : new Date(a.created_at);
    const curDate = b.updated_at ? new Date(b.updated_at) : new Date(b.created_at);

    return curDate - prevDate;
  })[0];

  const availableDomain = availableDomains?.filter((el) => el.id === clientDomainFiltered?.available_domain_id)[0];

  return {
    fullUrl: `${availableDomain?.url}/${clientDomainFiltered?.path_name}`,
    domenName: availableDomain?.url,
    pathName: clientDomainFiltered?.path_name,
    availableDomainId: clientDomainFiltered?.available_domain_id,
    clientDomainId: clientDomainFiltered?.id,
  };
};
export const getavailableDomainsOptions = (availableDomains) => {
  return availableDomains.map((availableDomain) => {
    return {
      label: availableDomain.url,
      value: availableDomain.id,
    };
  });
};
