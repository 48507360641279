import React from 'react';

import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {useAppMedia} from 'client/services/hooks';

import {logout as logOut} from 'client/ducks/user/actions';
import {selectUser, selectViewMode} from 'client/ducks/user/selectors';

import AppButton from 'client/common/buttons/app-button';
import {CLIENT_PAGES} from 'client/common/config';
import Icon from 'client/common/icon';
import Dropdown from 'client/common/selects/dropdown';

import cssModule from './header-menu.module.scss';

const b = bem('header-menu', {cssModule});

const HeaderMenu: React.FC = () => {
  const lang = useLanguage('USER_HEADER');
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);
  const viewMode = useSelector(selectViewMode);
  const {isTablet} = useAppMedia();

  const isWoman = user.title.startsWith('Mme.');
  const defaultAvatar = <Icon name={isWoman ? 'gender-woman' : 'gender-man'} width={30} height={30} />;
  const avatarSrc = user.avatar?.url;

  const handleLogout = async () => {
    await dispatch(logOut());
    history.push(CLIENT_PAGES.LOGIN);
  };

  return (
    <div className={b()}>
      <Dropdown
        showToggleArrow={!isTablet}
        disabled={isTablet}
        trigger={
          <div className={b('menu-trigger')}>
            <div className={b('avatar-wrap', {placeholder: !avatarSrc})}>
              {avatarSrc ? <img src={avatarSrc} className={b('avatar')} alt="" /> : defaultAvatar}
            </div>
            <p className={b('name')}>{user.full_name}</p>
          </div>
        }
        options={[
          {
            onClick: () => history.push(CLIENT_PAGES.PROFILE),
            disabled: viewMode.on,
            key: 'profile',
            label: (
              <div className={b('menu-item')}>
                <Icon
                  className={b('menu-item-icon')}
                  name={isWoman ? 'gender-woman' : 'gender-man'}
                  width={17}
                  height={18}
                />
                <span className={b('menu-item-text')}>{lang.MY_PROFILE}</span>
              </div>
            ),
          },
          {
            onClick: handleLogout,
            key: 'logout',
            label: (
              <div className={b('menu-item')}>
                <Icon className={b('menu-item-icon')} name="logout" width={11} height={13} />
                <span className={b('menu-item-text')}>{lang.LOGOUT}</span>
              </div>
            ),
          },
        ]}
      />
      <div className={b('logout')}>
        <AppButton
          className={b('logout-btn')}
          onClick={handleLogout}
          iconName="logout"
          color="light-clients"
          iconConfig={{width: 15, height: 22}}
        />
      </div>
    </div>
  );
};

export default HeaderMenu;
