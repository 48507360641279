import {date, required, isFromLessThenTo} from 'client/services/validator';

export const validate = ({name, from, to, ...formValues}, {lang, shouldAttribute}) => {
  const errors = {};

  const requiredField = required(lang.ERROR_REQUIRED);
  const invalidDate = date(lang.ERROR_INVALID_DATE);

  if (!formValues.online && !formValues.device_horizontal && !formValues.device_vertical) {
    errors.online = errors.device_horizontal = errors.device_vertical = lang.ERROR_REQUIRED;
  }

  errors.name = requiredField(name);
  errors.from = requiredField(from) || invalidDate(from);
  errors.to = requiredField(to) || invalidDate(to);

  if (!errors.from && !errors.to && isFromLessThenTo(from, to)) {
    errors.from = lang.ERROR_INVALID_PERIOD;
  }

  if (shouldAttribute && !formValues.client_user_id) {
    errors.client_user_id = lang.ERROR_REQUIRED;
  }

  return errors;
};
