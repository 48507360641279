import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {set} from 'client/common/meta/meta.actions';

import AdminsList from '../components/admins-list';

class AdminsListPage extends ReactQueryParams {
  constructor() {
    super();
  }

  componentDidMount() {
    this.props.setMetaAction({title: this.props.lang.TITLE});
  }

  render() {
    const {queryParams: params} = this;
    return <AdminsList urlParams={params} />;
  }
}

AdminsListPage.propTypes = {
  setMetaAction: PropTypes.func,
};

export default connect(
  ({languageState}) => ({
    lang: languageState.payload.ADMINS,
  }),
  (dispatch) => ({
    setMetaAction: bindActionCreators(set, dispatch),
  }),
)(AdminsListPage);
