import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getAnonymousEmail} from 'client/ducks/anonymous-emails/actions';

import TitleBlock from 'client/components/common/title-block';

class Campaign extends PureComponent {
  static propTypes = {
    token: PropTypes.string.isRequired,
    email: PropTypes.shape({
      subject: PropTypes.string,
      content: PropTypes.string,
    }).isRequired,
    getAnonymousEmail: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getAnonymousEmail(this.props.token);
  }

  componentDidUpdate() {
    this.styleNormalize();
  }

  styleNormalize() {
    const imgs = document.querySelector('.anonymous-email__img-normalizer').querySelectorAll('img');
    imgs.forEach((img) => {
      const width = img.getAttribute('width');
      const height = img.getAttribute('height');
      if (width) {
        img.style.width = `${width}px`;
      }
      if (height) {
        img.style.height = `${height}px`;
      }
    });
    const tables = document.querySelector('.anonymous-email__img-normalizer').querySelectorAll('table');
    tables.forEach((table) => {
      const bgColor = table.getAttribute('bgcolor');
      if (bgColor) {
        table.style.backgroundColor = `${bgColor}`;
      }
    });
  }

  render() {
    const {email} = this.props;

    return (
      <div className="anonymous-email__img-normalizer">
        <div className="theme-color-10 page__title-block flex-container flex-justify-between">
          <TitleBlock theme ellipsis="1" className="ellipsis-title">
            <TitleBlock.Item>{email.subject}</TitleBlock.Item>
          </TitleBlock>
        </div>

        <div
          dangerouslySetInnerHTML={{
            __html: email.content || '',
          }}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({anonymousEmails}) => {
      return {
        email: anonymousEmails.email,
      };
    },
    {
      getAnonymousEmail,
    },
  )(Campaign),
);
