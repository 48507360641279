import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';

import cssModule from './warning-label.module.scss';

type WarningLabelProps = {
  className?: string;
  label: React.ReactNode;
};

const b = bem('warning-label', {cssModule});

const WarningLabel: React.FC<WarningLabelProps> = ({className, label}) => {
  return (
    <span className={cn(b(), className)}>
      <Icon name="warning-triangle" />
      {label}
    </span>
  );
};

export default WarningLabel;
