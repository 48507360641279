import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import AnimationQuestionnaireCardStatistic from 'client/components/animations/cards/animation-questionnaire-card/animation-questionnaire-card-statistic';
import {KPI_TYPES} from 'client/components/animations/constants';
import {TranslationJsx} from 'client/models/language/types';

import './animation-question-list.scss';

const b = bem('animation-question-list');

const AnimationQuestionList = (props) => {
  const {title, data, selected, onSelect} = props;
  const lang = useLanguage('ANIMATIONS.CARDS.QUESTIONNAIRES');

  return (
    <div className={b()}>
      <div className={b('header')}>{title}</div>
      <CustomScrollbars scrollbarProps={{autoHeightMin: 260, autoHeightMax: 260}}>
        <ul className={b('body')}>
          {data.map((question) => (
            <li className={b('item')} key={question.id}>
              <button
                type="button"
                className={b('button', {selected: selected?.id === question.id})}
                disabled={question.type === KPI_TYPES.STAT || !question.data}
                onClick={() => onSelect(question)}
              >
                <span className={b('label')}>{question.title}</span>
                {question.data ? (
                  <span className={b('value', [question.type])}>
                    {question.type === KPI_TYPES.STAT ? (
                      parseFloat((+question.data).toFixed(3))
                    ) : (
                      <Icon name="diagram-2" />
                    )}
                  </span>
                ) : (
                  <span className={b('value', [question.type])}>–</span>
                )}
              </button>
            </li>
          ))}
          {!data?.length && (
            <div className={b('no-data')}>
              <Icon name="table-no-data" className={cn(b('icon'))} />
              <p className={b('no-data-text')}>{lang.NO_DATA}</p>
            </div>
          )}
        </ul>
      </CustomScrollbars>
    </div>
  );
};

AnimationQuestionList.propTypes = {
  title: TranslationJsx.isRequired,
  data: PropTypes.arrayOf(AnimationQuestionnaireCardStatistic.propTypes.data).isRequired,
  selected: AnimationQuestionnaireCardStatistic.propTypes.data,
  onSelect: PropTypes.func.isRequired,
  selectedAnimationKpiId: PropTypes.string,
};

AnimationQuestionList.defaultProps = {
  selected: null,
};

export default AnimationQuestionList;
