import React, {PureComponent} from 'react';

import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormSection, reduxForm, formValueSelector} from 'redux-form';

import bem from 'client/services/bem';
import {required, recognizedAllVariables} from 'client/services/validator';

import {removeOptInFromMessageScenarioStep} from 'client/ducks/opt-in-columns/actions';

import {TextField, SelectField, TextareaField} from 'client/common/fields';

import {TranslationJsx} from 'client/models/language/types';

import {getSMSRecipients, getInitialValues, submitForm} from './helpers';
import VariablesTextarea from './variables-textarea';

import {preventDefault, onSubmitSuccess, onSubmitFail} from '../helpers';
import OptIns from '../opt-ins';

// re-use main form styles
// TODO: make own styles
import cssModule from './../../ats-step/ats-step.module.scss';

const b = bem('ats-step', {cssModule});
class AtsSmsForm extends PureComponent {
  static propTypes = {
    smsSenders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
    smsRecipients: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    scenarioVariables: PropTypes.array.isRequired,
    messageValue: TranslationJsx,
    checkOptIn: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
    removeOptInFromMessageScenarioStep: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    smsSenders: [],
    smsRecipients: [],
    messageValue: '',
  };

  LANGUAGE = this.props.languageState.payload.AUTOTASK_SCENARIO.SCENARIO_FORMS;

  rules = {
    name: [required(this.LANGUAGE.ERRORS.REQUIRED)],
    select: [required(this.LANGUAGE.ERRORS.REQUIRED)],
    message: [required(this.LANGUAGE.ERRORS.REQUIRED)],
  };

  state = {
    messageValidator: [
      ...this.rules.message,
      recognizedAllVariables(
        this.props.languageState.payload.AUTOTASK_SCENARIO.SCENARIO_FORMS.ERRORS.UNKNOWN_VARIABLES,
        this.props.scenarioVariables,
      ),
    ],
  };

  componentDidUpdate(prevProps) {
    const {scenarioVariables, languageState} = this.props;

    if (scenarioVariables !== prevProps.scenarioVariables) {
      this.setState({
        messageValidator: [
          ...this.rules.message,
          recognizedAllVariables(
            languageState.payload.AUTOTASK_SCENARIO.SCENARIO_FORMS.ERRORS.UNKNOWN_VARIABLES,
            scenarioVariables,
          ),
        ],
      });
    }
  }

  formatSMSSenders = (smsSenders) =>
    smsSenders.map((smsSender) => ({
      value: smsSender.id,
      label: smsSender.value,
    }));

  formatSMSRecipients = (smsRecipients) =>
    smsRecipients.map((smsRecipient) => ({
      value: smsRecipient.id,
      label: smsRecipient.name,
    }));

  removeOptInFromMessageScenarioStep = (id, field) => {
    this.props.removeOptInFromMessageScenarioStep(id).then(() => {
      this.props.change(`${field}.deleted`, true);
    });
  };

  render() {
    const {scenarioVariables, smsRecipients, messageValue, smsSenders, checkOptIn, change} = this.props;

    const smsSendersOptions = this.formatSMSSenders(smsSenders);
    const smsRecipientsOptions = this.formatSMSRecipients(smsRecipients);

    return (
      <form onSubmit={preventDefault} className={b('form')}>
        <TextField label={this.LANGUAGE.STEP_NAME_LABEL} name="name" validate={this.rules.name} required={true} />
        <SelectField
          simpleValue
          label={this.LANGUAGE.SMS.SENDERS_PHONE_LABEL}
          name="implementation.message_sender_id"
          searchable={false}
          options={smsSendersOptions}
          validate={this.rules.select}
        />
        <SelectField
          simpleValue
          label={this.LANGUAGE.SMS.RECEPIENTS_PHONE_LABEL}
          name="implementation.recipient_variable_id"
          searchable={false}
          options={smsRecipientsOptions}
          validate={this.rules.select}
          required={true}
        />
        <VariablesTextarea
          searchPlaceholder={this.LANGUAGE.SMS.FILTER_VARIABLES_PLACEHOLDER}
          searchLabel={this.LANGUAGE.SMS.SELECT_VARIABLE_LABEL}
          label={this.LANGUAGE.SMS.MESSAGE_LABEL}
          changeMessageValue={(value) => change('implementation.message', value)}
          validate={this.state.messageValidator}
          variables={scenarioVariables}
          name="implementation.message"
          messageValue={messageValue}
          required={true}
        />
        <FormSection name="">
          <OptIns
            title={this.LANGUAGE.OPTIN.OPTIN_TYPE_LABEL}
            labels={{
              yes: this.LANGUAGE.OPTIN.YES_LABEL,
              no: this.LANGUAGE.OPTIN.NO_LABEL,
              satisfies: this.LANGUAGE.OPTIN.SATISFIES_LABEL,
              following: this.LANGUAGE.OPTIN.FOLLOWING_LABEL,
              all: this.LANGUAGE.OPTIN.ALL_LABEL,
              any: this.LANGUAGE.OPTIN.ANY_LABEL,
            }}
            checkOptIn={checkOptIn}
            removeOptInFromMessageScenarioStep={this.removeOptInFromMessageScenarioStep}
          />
        </FormSection>
        <TextareaField label={this.LANGUAGE.COMMENT_LABEL} name="comment" />
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {formId, data} = props;

  const {
    scenario: {
      payload: {scenario_variables: scenarioVariables, scenario_step_variables: scenarioStepVariables = []},
    },
    smsSenders: {payload: smsSenders},
  } = state;

  const selector = formValueSelector(formId);

  return {
    form: formId,
    initialValues: getInitialValues(data, state),
    smsSenders,
    scenarioVariables,
    smsRecipients: getSMSRecipients(scenarioVariables.concat(scenarioStepVariables)),
    checkOptIn: !!selector(state, 'implementation.check_opt_ins'),
    messageValue: selector(state, 'implementation.message'),
    languageState: state.languageState,
  };
};

const mapDispatchToProps = {
  removeOptInFromMessageScenarioStep,
};

export default flow([
  reduxForm({
    enableReinitialize: true,
    touchOnBlur: false,
    onSubmit: submitForm,
    onSubmitSuccess,
    onSubmitFail,
  }),
  connect(mapStateToProps, mapDispatchToProps),
])(AtsSmsForm);
