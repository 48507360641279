import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {calculateAge} from 'client/services/helpers';

import Modal from 'client/common/modals/modal';

import FormFieldWrap from 'client/components/common/form-field-wrap';

import './vis-lead-info-modal.scss';

function VisLeadInfoModal({show, onClose, lang, visual}) {
  const {lead, lead_history, lead_first_source, interaction, hashtag_tracker, offline_interaction} = visual;

  const by = lead_history && lead_history.kind === 'import' ? lang.BY_ADMIN_LABEL : lang.BY_LEAD_LABEL;
  const clientName = interaction && interaction.interface.automation_task.operation.client.name;
  let source;
  if (!interaction) {
    // tracker
    source = hashtag_tracker && hashtag_tracker.name;
  } else if (interaction.type === 'OfflineInteraction') {
    // device
    source = offline_interaction && offline_interaction.place.name;
  } else {
    // web
    source = lead_first_source && lead_first_source.source.name;
  }

  return (
    <Modal
      show={show}
      dialogClassName="modal-window--width-2 theme-color-13 vis-lead-info-modal"
      backdropClassName="modal-window__backdrop"
      onClose={onClose}
      title={lang.TITLE}
    >
      <div className="vis-lead-info-modal__container">
        <div className="vis-lead-info-modal__col">
          <FormFieldWrap>
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{lang.NAME_LABEL}</div>
              <div className="fake-input__field">{lead && `${lead.first_name} ${lead.last_name}`}</div>
            </div>
          </FormFieldWrap>
          <FormFieldWrap>
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{lang.AGE_LABEL}</div>
              <div className="fake-input__field">{calculateAge(lead && lead.birth_date)}</div>
            </div>
          </FormFieldWrap>
          <FormFieldWrap>
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{lang.PHONE_LABEL}</div>
              <div className="fake-input__field">{lead && lead.phone}</div>
            </div>
          </FormFieldWrap>
          <FormFieldWrap>
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{lang.EMAIL_LABEL}</div>
              <div className="fake-input__field">{lead && lead.email}</div>
            </div>
          </FormFieldWrap>
          <FormFieldWrap>
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{lang.COMMENT_LABEL}</div>
              <div className="fake-input__textarea">{lead?.comment}</div>
            </div>
          </FormFieldWrap>
        </div>
        <div className="vis-lead-info-modal__col">
          <FormFieldWrap>
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{lang.CREATED_LABEL}</div>
              <div className="fake-input__field">{lead && moment(lead.created_at).format('DD/MM/YYYY HH:mm:ss')}</div>
            </div>
          </FormFieldWrap>
          <FormFieldWrap>
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{lang.SOURCE_LABEL}</div>
              <div className="fake-input__field">{source}</div>
            </div>
          </FormFieldWrap>
          <FormFieldWrap>
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{lang.BY_LABEL}</div>
              <div className="fake-input__field">{by}</div>
            </div>
          </FormFieldWrap>
          <FormFieldWrap>
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{lang.CLIENT_LABEL}</div>
              <div className="fake-input__field">{clientName}</div>
            </div>
          </FormFieldWrap>
          <FormFieldWrap>
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{lang.VISUALS_LABEL}</div>
              <div className="fake-input__field">{lead && lead.visuals_count}</div>
            </div>
          </FormFieldWrap>
          <FormFieldWrap>
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{lang.PARTICIPATIONS_LABEL}</div>
              <div className="fake-input__field">{lead && lead.participations_count}</div>
            </div>
          </FormFieldWrap>
        </div>
      </div>
      <div className="modal-window__footer modal-window__footer--centered">
        <button className="button button--bg-8 modal-window__footer-btn" onClick={onClose}>
          {lang.OK_BUTTON}
        </button>
      </div>
    </Modal>
  );
}

VisLeadInfoModal.propTypes = {
  lang: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  visual: PropTypes.object,
  show: PropTypes.bool,
};

VisLeadInfoModal.defaultProps = {
  show: false,
  visual: {},
};

export default connect(({languageState}) => ({
  lang: languageState.payload.VISUALS.LEAD_INFO_MODAL,
}))(VisLeadInfoModal);
