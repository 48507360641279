import get from 'lodash/get';

import types from './types';

const initialState = {
  offlineInteractions: {
    data: [],
    max_visits: 0,
    meta: {
      current_page: 0,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
  },
  placesWithDevices: {
    data: [],
    max_visits: 0,
    meta: {
      current_page: 0,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
  },
  onlineInteractions: {
    allIds: [],
    byId: {},
    meta: {},
  },
  spreadPlanInteractions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ONLINE_INTERACTIONS_SUCCESS:
      return {
        ...state,
        onlineInteractions: {
          ...state.onlineInteractions,
          ...action.payload.online_interactions.reduce(
            (result, item) => {
              return {
                allIds: [...result.allIds, item.id],
                byId: {...result.byId, [item.id]: item},
              };
            },
            {
              allIds: [],
              byId: {},
            },
          ),
          meta: action.payload.meta || initialState.onlineInteractions.meta,
        },
      };

    case types.FETCH_OFFLINE_INTERACTIONS_SUCCESS:
      return {
        ...state,
        offlineInteractions: {
          data: action.payload.offline_interactions,
          max_visits: get(action, 'payload.aggregations.max_visits'),
          meta: action.payload.meta || initialState.offlineInteractions.meta,
        },
      };

    case types.FETCH_PLACES_WITH_DEVICES_SUCCESS:
      return {
        ...state,
        placesWithDevices: {
          data: action.payload.places,
          max_visits: get(action, 'payload.aggregations.max_visits'),
          meta: action.payload.meta || initialState.placesWithDevices.meta,
        },
      };

    case types.FETCH_SPREAD_PLAN_INTERACTIONS_SUCCESS:
      return {
        ...state,
        spreadPlanInteractions: action.payload.offline_interactions,
      };

    default:
      return state;
  }
};
