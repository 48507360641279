import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';
import {formatDate, formatTimeWithSeconds} from 'client/services/helpers';

import {getLeadHistories} from 'client/ducks/lead-histories/actions';

import ConfirmationModal from 'client/common/modals/confirmation-modal';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import FilterLabel from 'client/components/common/filter-label';
import Icon from 'client/components/common/icon';

import LeadsProfileFiltersModal from '../../modals/leads-profile-filters-modal/leads-profile-filters-modal';

import cssModule from './leads-profile-data-grid.module.scss';

const b = bem('leads-profile-data-grid', {cssModule});

const COL_UPDATE = 'update';

class LeadsMainDataGrid extends Component {
  static propTypes = {
    getLeadHistories: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onSearchClear: PropTypes.func.isRequired,
    onFilterResetData: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    onFilterClear: PropTypes.func.isRequired,
    onFilterFieldClear: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    leadId: PropTypes.string.isRequired,
    showClear: PropTypes.bool,
    data: PropTypes.array,
    filter: PropTypes.object,
    searchDefault: PropTypes.string,
    initialValuesForModal: PropTypes.object,
    meta: PropTypes.object,
    perPage: PropTypes.number,
  };

  static defaultProps = {
    showClear: false,
    data: [],
    filter: {},
    searchDefault: '',
    initialValuesForModal: {},
    meta: {
      current_page: 0,
      total_pages: 0,
      total_count: 0,
    },
    perPage: 1,
  };

  constructor(props) {
    super(props);

    this.state = {
      showFilterModal: false,
      filter: this.props.filter.filters,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter.filters && this.props.filter.filters !== prevProps.filter.filters) {
      this.setState({filter: this.props.filter.filters});
    }
  }

  renderDate = ({value: date}) => (
    <div>
      <div className="main-text main-text--small main-text--bold">{formatDate(date)}</div>
      <div className="main-text main-text--small">{formatTimeWithSeconds(date)}</div>
    </div>
  );

  renderType = ({value}) => {
    const text = value === COL_UPDATE ? this.props.lang.FILTERS_MODAL_EDIT_RADIO : value;
    return text.toUpperCase();
  };

  renderLinks = ({value}) => {
    return (
      <Link to="#" className="main-text main-text--underline">
        {value}
      </Link>
    );
  };

  renderData = ({value}) => {
    return (
      <div className={b('data-col')}>
        {value?.map?.((el, i) => (
          <span key={i}>{el.column_adapter.name} &nbsp;</span>
        ))}
      </div>
    );
  };

  renderToolbar = () => {
    const {lang} = this.props;
    return (
      <div>
        <button className="button button--bg-2" onClick={this.onOpenFilterModal}>
          <Icon name="filter" className="button__icon" />
          <span>
            {`${lang.FILTERS_BUTTON}
              (${Object.keys(this.checkOperationFilter(this.mapFilter(this.state.filter))).length})`}
          </span>
        </button>
        <span className={b('search-result-num')}>
          <span className="main-text main-text--bold">{this.props.meta.total_count}</span>
          <span className="main-text">{` ${this.props.langTable.RESULTS}`}</span>
        </span>
        <div className={b('filters-wrapper')}>
          {this.state.filter.participatedFrom !== '' && (
            <FilterLabel
              text={`${lang.FILTERS_MODAL_PARTICIPATED_FROM}: ${this.state.filter.participatedFrom}`}
              onDelete={() => this.props.onFilterFieldClear('participatedFrom')}
            />
          )}
          {this.state.filter.participatedTo !== '' && (
            <FilterLabel
              text={`${lang.FILTERS_MODAL_PARTICIPATED_TO}: ${this.state.filter.participatedTo}`}
              onDelete={() => this.props.onFilterFieldClear('participatedTo')}
            />
          )}
          {this.state.filter.participation && this.state.filter.participation !== 'false' && (
            <FilterLabel
              text={lang.FILTERS_MODAL_PARTICIPATION_RADIO}
              onDelete={() => this.props.onFilterFieldClear('participation')}
            />
          )}
          {this.state.filter.opt_in && this.state.filter.opt_in !== 'false' && (
            <FilterLabel
              text={lang.FILTERS_MODAL_OPTIN_RADIO}
              onDelete={() => this.props.onFilterFieldClear('opt_in')}
            />
          )}
          {this.state.filter.visual && this.state.filter.visual !== 'false' && (
            <FilterLabel
              text={lang.FILTERS_MODAL_VISUAL_RADIO}
              onDelete={() => this.props.onFilterFieldClear('visual')}
            />
          )}
          {this.state.filter.update && this.state.filter.update !== 'false' && (
            <FilterLabel
              text={lang.FILTERS_MODAL_EDIT_RADIO}
              onDelete={() => this.props.onFilterFieldClear('update')}
            />
          )}
          {this.state.filter.export && this.state.filter.export !== 'false' && (
            <FilterLabel
              text={lang.FILTERS_MODAL_EXPORT_RADIO}
              onDelete={() => this.props.onFilterFieldClear('export')}
            />
          )}
          {this.state.filter.import && this.state.filter.import !== 'false' && (
            <FilterLabel
              text={lang.FILTERS_MODAL_IMPORT_RADIO}
              onDelete={() => this.props.onFilterFieldClear('import')}
            />
          )}
          {this.state.filter.userId && this.state.filter.userId.value !== '0' && (
            <FilterLabel
              text={`${lang.FILTERS_MODAL_ID_LABEL}: ${this.state.filter.userId.label}`}
              onDelete={() => this.props.onFilterFieldClear('userId')}
            />
          )}
          {this.state.filter.interfacesNames && this.state.filter.interfacesNames.value !== '0' && (
            <FilterLabel
              text={`${lang.FILTERS_MODAL_INTERACTION_LABEL}: ${this.state.filter.interfacesNames.label}`}
              onDelete={() => this.props.onFilterFieldClear('interfacesNames')}
            />
          )}
          {Object.keys(this.checkOperationFilter(this.mapFilter(this.state.filter))).length > 0 && (
            <button className="button button--bg-2" onClick={this.props.onFilterClear}>
              {lang.CLEAR_FILTERS_BUTTON}
            </button>
          )}
        </div>
      </div>
    );
  };

  onPageChange = (params) => {
    this.props.onPageChange(params);
  };

  onCloseFilterModal = () => {
    this.setState({showFilterModal: false});
  };

  onOpenFilterModal = () => {
    this.setState({showFilterModal: true});
  };

  mapFilter(data) {
    let obj = {...data};
    Object.keys(obj).map((el) => {
      if (!obj[el] || obj[el] === 'false' || (typeof obj[el] === 'object' && obj[el].value === '0')) {
        delete obj[el];
      }
    });
    return obj;
  }

  checkOperationFilter(data) {
    delete data.participated;
    return data;
  }

  render() {
    const {lang} = this.props;

    const {meta, data, perPage, onPageChange, onSortChange, onFilterChange, onFilterResetData} = this.props;

    const message = lang.DELETE_LEAD_MODAL_MESSAGE
      ? lang.DELETE_LEAD_MODAL_MESSAGE.replace(/\[Name]/, 'Jim Carrey')
      : '';

    return (
      <div className="pos-rel">
        <ConfirmationModal
          show={false}
          title={lang.DELETE_LEAD_MODAL_TITLE}
          className="theme-color-2"
          buttonConfirmClass="button--bg-2"
          message={message}
          cancelText={lang.DELETE_LEAD_MODAL_CANCEL_BUTTON}
          confirmText={lang.DELETE_LEAD_MODAL_CONFIRM_BUTTON}
        />
        <LeadsProfileFiltersModal
          show={this.state.showFilterModal}
          onClose={this.onCloseFilterModal}
          onFilterResetData={onFilterResetData}
          onFilterChange={onFilterChange}
          leadId={this.props.leadId}
          totalItem={this.props.meta.total_count}
          initialValuesForModal={this.props.initialValuesForModal}
        />
        <div className={b('toolbar-wrapper')}>
          {this.renderToolbar()}
          <PerPageDropdown
            value={String(perPage)}
            onChange={(pages) => this.onPageChange({page: 1, perPage: pages})}
            simpleValue
          />
        </div>

        <ClientTable
          data={data}
          onSortChange={onSortChange}
          sortField={this.props.sort?.name}
          sortOrder={this.props.sort?.order}
          className={b()}
          columns={[
            {
              name: 'created_at',
              path: 'created_at',
              label: lang.TABLE.DATE_COLUMN,
              sortable: true,
              render: this.renderDate,
              width: 110,
            },
            {path: 'kind', label: lang.TABLE.TYPE_COLUMN, render: this.renderType, width: 150},
            {path: 'source.name', label: lang.TABLE.LINKS_COLUMN, render: this.renderLinks, width: '25%'},
            {path: 'lead_history_items', label: lang.TABLE.DATA_COLUMN, render: this.renderData},
            {path: 'user.id', label: lang.TABLE.ID_COLUMN, width: 150},
          ]}
        />
        <PaginationBar
          data={data}
          perPage={perPage}
          currentPage={meta.current_page}
          totalPages={meta.total_pages}
          totalItems={meta.total_count}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}

export default connect(
  ({languageState}) => ({lang: languageState.payload.LEADS_PROFILE, langTable: languageState.payload.TABLE}),
  {getLeadHistories},
)(LeadsMainDataGrid);
