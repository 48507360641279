import React, {useState} from 'react';

import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {updateClientTemplate} from 'client/ducks/templates/actions';
import {selectClientUser} from 'client/ducks/user-clients/selectors';

import AppButton from 'client/common/buttons/app-button';
import TextareaInput from 'client/common/inputs/textarea-input';
import TextEditor from 'client/common/text-editor';

import {ClientTemplate} from 'client/models/templates/types';
import {ApiDispatch} from 'client/types';

import LeftSection from './left-section';
import ScreenshotsBlock from './screenshots-block';

import cssModule from './template-parameters-tab.module.scss';

const b = bem('template-parameters-tab', {cssModule});

type TemplateParametersTabProps = {
  template: ClientTemplate;
  updateTemplate: (id: number, data: Partial<ClientTemplate>) => void;
  isModule: boolean;
  isMyTemplate: boolean;
};

const TemplateParametersTab: React.FC<TemplateParametersTabProps> = (props) => {
  const {template, updateTemplate, isModule, isMyTemplate} = props;
  const dispatch: ApiDispatch = useDispatch();
  const lang = useLanguage('CATALOG_TEMPLATES.MODALS.TEMPLATE_INFO_MODAL.TEMPLATE_PARAMETERS_TAB');
  const {client_user: user} = useSelector(selectClientUser);
  const [comments, setComments] = useState(template.comments_translations[user.locale] || '');

  const description = template.description_translations[user.locale] || '';

  const previewUrl = template?.preview_url;

  const handleUpdateComments = async () => {
    const res = await dispatch(
      updateClientTemplate(template.id, {
        client_template: {[`comments_${user.locale}`]: comments},
      }),
    );
    const templateData = res?.payload?.client_template;
    if (templateData) {
      updateTemplate(templateData.id, templateData);
    }
  };

  return (
    <div className={b()}>
      <div className={b('column', ['left'])}>
        <LeftSection template={template} isModule={isModule} />
      </div>
      <div className={b('column', ['middle'])}>
        <div>
          <p className={b('title')}>{lang.DESCRIPTION}</p>
          <div className={b('description')}>
            <TextEditor
              isToolbarVisible={true}
              value={description}
              onChange={() => null}
              commonColorsAccessKey=""
              editableClassName={b('editable')}
              withFocus={false}
              readOnly
            />
          </div>
        </div>
        <div>
          <p className={b('title')}>{lang.SCREENSHOTS}</p>
          <ScreenshotsBlock template={template} editable={isMyTemplate} updateTemplate={updateTemplate} />
        </div>
      </div>
      <div className={b('column', ['right'])}>
        {previewUrl && (
          <AppButton
            className={b('preview-button')}
            iconName="eye-opened"
            label={lang.PREVIEW}
            color="light-clients"
            onClick={() => window.open(previewUrl, '_blank')}
          />
        )}
        <p className={b('title')}>{lang.COMMENTS}</p>
        <TextareaInput
          textareaClassName={b('comments-input', {'full-height': !previewUrl})}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          onBlur={isMyTemplate ? handleUpdateComments : null}
          disabled={!isMyTemplate}
        />
      </div>
    </div>
  );
};

export default TemplateParametersTab;
