import React from 'react';

import moment from 'moment';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import {
  AFFECTATION_ALERT_VALUES,
  AFFECTATION_ALERT_STATUSES,
  DeviceAffectation,
} from 'client/models/device-affectations';

import cssModule from './client-devices-activity-cell.module.scss';

type ClientDevicesActivityCellProps = {
  affectation: DeviceAffectation;
};

const b = bem('client-devices-activity-cell', {cssModule});

const MAP_ICON_STATUSES = {
  inactive: 'status-not-active',
  active: 'status-success',
} as const;

const MAP_ICON_ALERTS = {
  [AFFECTATION_ALERT_VALUES.ERROR]: 'status-error-triangle',
  [AFFECTATION_ALERT_VALUES.WARNING]: 'status-warning-triangle',
  [AFFECTATION_ALERT_VALUES.OK]: 'status-success',
} as const;

const renderTooltip = (datetime?: string) =>
  datetime && (
    <div className={b('alert-tooltip')}>
      <span className={b('alert-date')}>{moment(datetime).format('DD/MM/YY')}</span>
      {` ${moment(datetime).format('HH:mm:SS')}`}
    </div>
  );

const ClientDevicesActivityCell: React.FC<ClientDevicesActivityCellProps> = ({affectation}) => {
  const lang = useLanguage('CLIENT_DEVICES.TABLE.ACTIVITY_CELL');

  if (!affectation.alert_inactivity) {
    return null;
  }

  const active = affectation.alert_inactivity === AFFECTATION_ALERT_STATUSES.ACTIVE;
  const tokenHasAnomaly = affectation.alert_token && affectation.alert_token !== AFFECTATION_ALERT_VALUES.OK;
  const paperHasAnomaly = affectation.alert_paper && affectation.alert_paper !== AFFECTATION_ALERT_VALUES.OK;

  return (
    <div className={b()}>
      <div className={b('icon')}>
        <Icon name={active ? MAP_ICON_STATUSES.active : MAP_ICON_STATUSES.inactive} width={18} height={18} />
      </div>
      <div className={b('status-container')}>
        {!active && affectation.timestamp_inactivity && !paperHasAnomaly && !tokenHasAnomaly && (
          <div className={b('since')}>
            {lang.SINCE}{' '}
            <span className={b('since-date')}>{moment(affectation.timestamp_inactivity).format('DD/MM/YY')}</span>
          </div>
        )}
        {paperHasAnomaly && (
          <Popover triggerClassName={b('alert')} position="top" overlay={renderTooltip(affectation.timestamp_paper)}>
            <Icon name={MAP_ICON_ALERTS[affectation.alert_paper]} width={14} height={12} />
            <span>{lang.PAPER_ALERT}</span>
          </Popover>
        )}
        {tokenHasAnomaly && (
          <Popover triggerClassName={b('alert')} position="top" overlay={renderTooltip(affectation.timestamp_token)}>
            <Icon name={MAP_ICON_ALERTS[affectation.alert_token]} width={14} height={12} />
            <span>{lang.TOKEN_ALERT}</span>
          </Popover>
        )}
      </div>
    </div>
  );
};

export default ClientDevicesActivityCell;
