import {useEffect, useMemo} from 'react';

import {useSelector, useDispatch} from 'react-redux';

import {hideTaskHeader, showTaskHeader as show} from 'client/ducks/table-wrapper/actions';
import {selectShowTaskHeader} from 'client/ducks/table-wrapper/selectors';

const useTaskWrapper = (showTaskHeader = true) => {
  const stateTaskHeader = useSelector(selectShowTaskHeader);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showTaskHeader) {
      dispatch(hideTaskHeader());
    }
  }, [showTaskHeader, dispatch]);

  useEffect(() => {
    return () => dispatch(show());
  }, [dispatch]);

  return useMemo(() => ({showHeader: stateTaskHeader}), [stateTaskHeader]);
};

export default useTaskWrapper;
