import React, {memo} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Icon from 'client/components/common/icon';

import {GAME_TYPES} from 'client/components/games/game-config-modal/constants';

import './games-data.scss';

const b = bem('games-data');

const GamesData = (props) => {
  const {lang, game, onEditClick} = props;

  const gameTypeMap = {
    [GAME_TYPES.INSTANT_WIN]: lang.GAME_TYPE.INSTANT_WIN,
    [GAME_TYPES.PRIZE_DRAW]: lang.GAME_TYPE.PRIZE_DRAW,
    [GAME_TYPES.ATTRIBUTION]: lang.GAME_TYPE.ATTRIBUTION,
  };

  return (
    <div className={b()}>
      <div className={b('win-number')}>{game.win_number}</div>
      <div className={b('name')}>{game.name}</div>
      <div className={b('game-type')}>{gameTypeMap[game.game_type]}</div>
      <div className={b('status')}>{game.validation && <span className="status-icon" />}</div>
      <div className={b('prizes')}>
        <span>{game.prizes_count}</span>
        <button className={b('button-edit')} type="button" onClick={onEditClick}>
          <Icon name="edit" className={b('button-icon')} width={20} height={20} />
        </button>
      </div>
    </div>
  );
};

GamesData.propTypes = {
  lang: PropTypes.object.isRequired,
  game: PropTypes.shape({
    win_number: PropTypes.number,
    name: PropTypes.string,
    game_type: PropTypes.string,
    validation: PropTypes.bool,
    prizes_count: PropTypes.number,
  }).isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default memo(GamesData);
