import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getUserId} from 'client/services/cookie-data-source';
import {mapFilter} from 'client/services/helpers';

import {getClientsAction as getClients} from 'client/ducks/clients-list/actions';
import {selectClients, selectClientsMeta} from 'client/ducks/clients-list/selectors';
import {selectIsAdmin} from 'client/ducks/user/selectors';
import {USER_LOADED} from 'client/ducks/user/types';

import {CLIENT_PAGES} from 'client/common/config';

import TitleBlock from 'client/components/common/title-block';

import ClientsDataGrid from './components/tables/clients-data-grid';
import {mapClientData, mapUrlFilter} from './helper';

class ClientsList extends ReactQueryParams {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    ...withRouter.propTypes,
    getClients: PropTypes.func.isRequired,
    clientsList: PropTypes.array,
    isForCurrentUser: PropTypes.bool,
    isAdmin: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isForCurrentUser: false,
    clientsList: [],
  };

  constructor(props) {
    super(props);
    this.LANGUAGE = props.languageState.payload.CLIENTS_LIST;
    this.perPageDefault = 5;
    this.pageDefault = 1;

    this.state = {
      page: this.pageDefault,
      perPage: this.perPageDefault,
      sort: {
        name: '',
        order: '',
      },
      search: '',
      showClear: false,
      searchDefault: '',
      filter: {},
      loading: false,
    };
  }

  processQueryString = (params) => {
    let filterObj = '';
    let sortQ = '';
    let searchQ = '';
    let currentUserQuery = '';
    let userId = '';
    let page = params.page;
    let perPage = params.perPage;
    let search = params.search;
    let sort = params.sort;
    let filter = params.filter;

    const {isForCurrentUser} = this.props;

    if (isForCurrentUser) {
      userId = getUserId();
      currentUserQuery = `q[poc_admin_user_id_eq]=${userId}`;
    }

    const joins =
      'include[]=operations&include[]=poc_admin_user&include[]=poc_membership.client_user&include[]=companies&' +
      'include[]=agency&distinct=true';

    if (sort && sort.name === 'operations') {
      sortQ = `q[s]=${sort.name}_from ${sort.order}&`;
    } else if (sort) {
      sortQ = sort ? `q[s]=${sort.name} ${sort.order}&` : '';
    }

    if (filter) {
      filterObj = mapUrlFilter(filter);
    }

    searchQ = search
      ? `q[g][search][m]=or&q[g][search][name_cont]=${search}&q[g][search][operations_name_cont]=${search}&`
      : '';

    return `${searchQ}${sortQ}page=${page}&per_page=${perPage}&${joins}&${filterObj}&${currentUserQuery}`;
  };

  componentDidMount() {
    const {
      page,
      perPage,
      sort: {name, order},
      search,
      filter,
    } = this.state;
    const {urlParams, isForCurrentUser} = this.props;

    const clientsPage = isForCurrentUser ? CLIENT_PAGES.CLIENTS_LIST_MY : CLIENT_PAGES.CLIENTS_LIST;

    if (!urlParams.page) {
      this.props.history.replace(`${clientsPage}?page=${this.pageDefault}&perPage=${this.perPageDefault}`);
    }

    this.getClients(
      this.processQueryString({
        page: urlParams.page || page,
        perPage: urlParams.perPage || perPage,
        sort: {
          name: (urlParams.sort && urlParams.sort.name) || name,
          order: (urlParams.sort && urlParams.sort.order) || order,
        },
        search: urlParams.search || search,
        filter: urlParams.filter || filter,
      }),
    );

    this.setState({
      searchDefault: urlParams.search || '',
      showClear: !!urlParams.search,
      filter: urlParams.filter || filter,
      sort: {
        name: (urlParams.sort && urlParams.sort.name) || name,
        order: (urlParams.sort && urlParams.sort.order) || order,
      },
      page: +urlParams.page || page,
      perPage: +urlParams.perPage || perPage,
    });
  }

  componentDidUpdate() {
    const {userState, isAdmin, history} = this.props;

    if (userState.type === USER_LOADED && !isAdmin) {
      history.replace('');
    }

    window.scrollTo(0, 0);
  }

  getClients = (params) => {
    this.setState({loading: true});
    this.props.getClients(params).then(() => this.setState({loading: false}));
  };

  onSortChange = ({sortField, sortOrder}) => {
    this.setQueryParams({sort: {name: sortField, order: sortOrder}}, true);
    this.setState({sort: {name: sortField, order: sortOrder}}, () =>
      this.getClients(this.processQueryString(this.state)),
    );
  };

  onPageChange = (params) => {
    this.setQueryParams(params, true);
    this.setState(
      {
        page: params.page,
        perPage: params.perPage,
        sort: this.queryParams.sort,
        search: this.queryParams.search,
        filter: this.queryParams.filter,
      },
      () => this.getClients(this.processQueryString(this.state)),
    );
  };

  onSearchChange = (search) => {
    if (search) {
      this.setQueryParams({search, page: 1}, true);
      this.setState(
        {
          search,
          showClear: true,
          searchDefault: '',
          page: 1,
        },
        () => this.getClients(this.processQueryString(this.state)),
      );
    }
  };

  onSearchClear = () => {
    this.setQueryParams({search: ''}, true);
    this.setState(
      {
        search: '',
        showClear: false,
        searchDefault: '',
      },
      () => this.getClients(this.processQueryString(this.state)),
    );
  };

  onFilterChange = (filter) => {
    this.setQueryParams({filter: mapFilter(filter), page: 1}, true);
    this.setState(
      {
        filter,
        page: 1,
      },
      () => this.getClients(this.processQueryString(this.state)),
    );
  };

  onFilterClear = () => {
    this.onFilterChange({});
  };

  onFilterFieldClear = (fieldsList) => {
    const obj = Object.assign({}, this.state.filter);
    fieldsList.forEach((el) => delete obj[el]);
    this.onFilterChange(obj);
  };

  render() {
    const {clientsList, meta, isForCurrentUser} = this.props;

    let rows = mapClientData(clientsList);

    return (
      <div className="theme-color-5">
        <div className="page__title-block">
          <TitleBlock theme>
            <TitleBlock.Item>
              {isForCurrentUser ? this.LANGUAGE.PAGE_TITLE_MY_CLIENTS : this.LANGUAGE.PAGE_TITLE}
            </TitleBlock.Item>
          </TitleBlock>
        </div>
        <div>
          <ClientsDataGrid
            data={rows}
            meta={meta}
            perPage={this.state.perPage}
            filter={this.state.filter}
            onPageChange={this.onPageChange}
            onSortChange={this.onSortChange}
            onSearchChange={this.onSearchChange}
            onSearchClear={this.onSearchClear}
            showClear={this.state.showClear}
            searchDefault={this.state.searchDefault}
            onFilterChange={this.onFilterChange}
            onFilterClear={this.onFilterClear}
            onFilterFieldClear={this.onFilterFieldClear}
            loading={this.state.loading}
            sort={this.queryParams.sort}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    languageState: state.languageState,
    userState: state.userState,
    meta: selectClientsMeta(state),
    clientsList: selectClients(state),
    isAdmin: selectIsAdmin(state),
  }),
  {
    getClients,
  },
)(withRouter(ClientsList));
