import React, {Fragment, useEffect, useState} from 'react';

import {useSelector, useDispatch} from 'react-redux';
import {useToggle, useMount} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm from 'client/services/hooks/use-redux-form';

import {interpolate} from 'client/ducks/language/helpers';
import {searchWinnings} from 'client/ducks/prize-distribution/actions';
import {selectWinningSearchResults} from 'client/ducks/prize-distribution/selectors';

import AwaitContainer from 'client/common/await-container';
import Modal from 'client/common/modals/modal';

import {mapSearchParams} from 'client/components/winnings/containers/winning-search-container/helpers';
import {
  winningToolbarSearchFormName,
  winningSearchFormName,
} from 'client/components/winnings/forms/winning-search-form';
import WinningSearchForm from 'client/components/winnings/forms/winning-search-form';
import {WINNING_SEARCH_TYPES} from 'client/components/winnings/forms/winning-search-form/constants';
import {WinningSearchType, WinningSearchValues} from 'client/components/winnings/forms/winning-search-form/types';
import WinningSearchResultList from 'client/components/winnings/lists/winning-search-result-list';
import WinningPrizeModal from 'client/components/winnings/modals/winning-prize-modal';
import {Autotask} from 'client/models/autotask/types';
import {ApiAction} from 'client/types';

import cssModule from './winning-search-modal.module.scss';

const b = bem('winning-search-modal', {cssModule});

type WinningSearchModalProps = {
  onClose: () => void;
  task: Autotask;
};

const WinningSearchModal: React.FC<WinningSearchModalProps> = (props) => {
  const {onClose, task} = props;
  const dispatch: ApiAction = useDispatch();
  const lang = useLanguage('WINNINGS.MODALS.WINNING_SEARCH_MODAL');
  const langFields = useLanguage('WINNINGS.FORMS.WINNING_SEARCH_FORM');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);
  const [loading, toggleLoading] = useToggle(false);
  const data = useSelector(selectWinningSearchResults);
  const [participationPrizeId, setParticipationPrizeId] = useState<number | null>(null);
  const [searchState, setSearchState] = useState<{criteria: WinningSearchType; params: Record<string, string>}>({
    criteria: WINNING_SEARCH_TYPES.EMAIL,
    params: {},
  });

  const {formValues: toolbarValues} = useReduxForm<WinningSearchValues>(winningToolbarSearchFormName);
  const {formValues: modalValues} = useReduxForm<WinningSearchValues>(winningSearchFormName);

  useEffect(() => {
    if (!isResult && isSubmitted) {
      setIsResult(true);
    }
  }, [isResult, isSubmitted]);

  const handleSearch = async (values: WinningSearchValues) => {
    setIsSubmitted(true);
    const hasFullName =
      values.search_type === WINNING_SEARCH_TYPES.FULL_NAME && values.first_name?.trim() && values.last_name?.trim();
    const isValueEmpty = !values[values.search_type as keyof typeof values]?.trim() && !hasFullName;
    setShowEmptyMessage(isValueEmpty);
    if (!isValueEmpty) {
      toggleLoading();
      const nextState = mapSearchParams(values);
      setSearchState({
        criteria: values.search_type,
        params: nextState,
      });
      await dispatch(searchWinnings(task?.id, nextState));
      toggleLoading();
    }
  };

  useMount(() => {
    handleSearch(toolbarValues);
  });

  const getEmptyValueMessage = () => {
    if (!modalValues.search_type) {
      return '';
    }
    const searchType = langFields[modalValues.search_type.toUpperCase() as keyof typeof langFields].toString();
    const messageElements = interpolate(lang.EMPTY_VALUE_MESSAGE.toString(), {type: searchType}).toString().split('|');
    return messageElements.map((i) => (
      <Fragment key={i}>
        {i}
        <br />
      </Fragment>
    ));
  };

  return (
    <Modal className={b()} title={lang.TITLE} onClose={onClose}>
      <WinningSearchForm
        onSearch={handleSearch}
        onChangeForm={setIsSubmitted}
        initialValues={toolbarValues}
        validateEmpty={false}
        inline
      />
      <AwaitContainer loading={loading}>
        {isResult && !loading && !showEmptyMessage && (
          <WinningSearchResultList
            data={data}
            className={b('results')}
            searchType={modalValues.search_type}
            showCopy
            onSelect={setParticipationPrizeId}
          />
        )}
        {!data.length && !loading && isSubmitted && !showEmptyMessage && (
          <p className={b('message')}>{lang.NO_RESULTS}</p>
        )}
        {showEmptyMessage && <p className={b('message')}>{getEmptyValueMessage()}</p>}
        {!!participationPrizeId && (
          <WinningPrizeModal
            onClose={() => setParticipationPrizeId(null)}
            searchParams={searchState?.params}
            searchCriteria={searchState?.criteria}
            participationPrizeId={participationPrizeId}
          />
        )}
      </AwaitContainer>
    </Modal>
  );
};

export default WinningSearchModal;
