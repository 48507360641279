import React, {useState} from 'react';

import {useDispatch} from 'react-redux';

import {updateMembership} from 'client/ducks/memberships/actions';

import ToggleInput from 'client/common/inputs/toggle-input';

import {Membership} from 'client/models/memberships/types';

type UsersTableEditorCellProps = {
  membership: Membership;
};

const UsersTableEditorCell: React.FC<UsersTableEditorCellProps> = (props) => {
  const {membership} = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState<boolean>(membership.editor);

  const handleOnChange = () => {
    setValue(!value);
    dispatch(updateMembership(membership.id, {membership: {editor: !value}}));
  };

  return <ToggleInput checked={value} onChange={handleOnChange} rounded color="users" />;
};

export default UsersTableEditorCell;
