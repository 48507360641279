import React, {useMemo} from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons';

import {StepProgressBarProps} from './types';

import cssModule from './step-progress-bar.module.scss';

const b = bem('step-progress-bar', {cssModule});

const StepProgressBar: React.FC<StepProgressBarProps> = (props) => {
  const {title, totalSteps, currentStep, setCurrentStep, className = '', disabled = false, classNames = {}} = props;
  const amount = totalSteps || 1;

  const nextHandler = () => {
    if (currentStep === totalSteps) {
      return;
    }

    setCurrentStep(currentStep + 1);
  };

  const prevHandler = () => {
    if (currentStep < 0) {
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const renderSteps = useMemo(() => {
    const listSteps = [];

    for (let i = 1; i <= totalSteps; i++) {
      listSteps.push(
        <li
          key={i}
          className={b('step', {
            completed: i < currentStep,
            current: i === currentStep,
          })}
        />,
      );
    }

    return listSteps;
  }, [currentStep, totalSteps]);

  return (
    <header className={cn(b({disabled}), className)}>
      <div className={cn(b('inner'), classNames?.inner)}>
        <h2 className={cn(b('title'), classNames?.title)}>
          <span>{title}&#32;</span>
          <span className={cn(b('step-caption'), classNames?.stepCaption)}>{`${currentStep} of ${amount}`}</span>
        </h2>

        <ol className={cn(b('list'), classNames?.list)}>{renderSteps}</ol>

        <div className={cn(b('button-wrapper'), classNames?.buttonWrapper)}>
          <AppButton
            iconName="arrow-left"
            onClick={prevHandler}
            disabled={currentStep === 1 || disabled}
            transparent={true}
            className={cn(b('button'), classNames?.button)}
          />
          <AppButton
            iconName="arrow-right"
            onClick={nextHandler}
            disabled={currentStep === amount || disabled}
            transparent={true}
            className={cn(b('button'), classNames?.button)}
          />
        </div>
      </div>
    </header>
  );
};

export default StepProgressBar;
