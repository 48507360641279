import React, {useState, useCallback, useEffect} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';

import {selectClient} from 'client/ducks/leads-list/selectors';
import {getPlacesAction} from 'client/ducks/places/actions';
import {selectPlaceOptionsByRegions, selectRegionOptions} from 'client/ducks/places/selectors';

import AppButton from 'client/common/buttons/app-button';
import {SelectField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import './devices-diy-list-filters.scss';

const b = bem('devices-diy-list-filters');

const DevicesDiyListFilters = ({className, history, location}) => {
  const lang = useLanguage('CLIENT_DEVICES.FILTER_MODAL');
  const [show, setShow] = useState(false);
  const [queryParams, setParams] = useQueryParams({history, location});
  const {formValues, initialize, change} = useReduxForm(DevicesDiyListFilters.formName);
  const dispatch = useDispatch();

  const client = useSelector(selectClient);
  const regions = useSelector(selectRegionOptions);
  const stores = useSelector(selectPlaceOptionsByRegions(formValues.region));

  // callbacks: Options
  const fetchRegions = useCallback(() => {
    const params = {
      include: {
        region: null,
      },
      q: {
        region_id_not_null: true,
        client_id_eq: client.id,
      },
    };

    if (client?.id) {
      dispatch(getPlacesAction(params));
    }
  }, [client.id, dispatch]);

  const onClose = () => setShow(false);

  const onOpen = () => setShow(true);

  const onSubmit = (e) => {
    e.preventDefault();
    setParams({
      ...formValues,
      page: '1',
    });
    onClose();
  };

  useEffect(() => {
    fetchRegions();
  }, [fetchRegions]);

  useEffect(() => {
    initialize({
      ...queryParams,
      region: queryParams.region?.map(Number),
      store: queryParams.store?.map(Number),
    });
  }, [initialize, queryParams]);

  const onChangeRegion = (regionIds) => {
    if (Array.isArray(regionIds) && !regionIds.length) {
      change('store', []);
    }
  };

  return (
    <>
      <AppButton
        onClick={onOpen}
        className={classNames(className, b('button'))}
        label={lang.BUTTON}
        iconName="filter"
      />

      <Modal onClose={onClose} show={show} title={lang.TITLE} className={b('modal')}>
        <form className={b('form')} onSubmit={onSubmit}>
          <SelectField
            simpleValue
            multi
            options={regions}
            name="region"
            label={lang.FILTER_FORM.REGION}
            onChange={onChangeRegion}
          />
          <SelectField simpleValue multi options={stores} name="store" label={lang.FILTER_FORM.STORE} />
          <AppButton label={lang.FILTER_FORM.SHOW_RESULTS} className={b('submit-button')} submit />
        </form>
      </Modal>
    </>
  );
};

DevicesDiyListFilters.formName = 'DevicesDiyListFiltersForm';

DevicesDiyListFilters.propTypes = {
  className: PropTypes.string,
  ...withRouter.propTypes,
  location: PropTypes.object.isRequired,
};

DevicesDiyListFilters.defaultProps = {
  className: '',
};

export default reduxForm({
  form: DevicesDiyListFilters.formName,
})(withRouter(DevicesDiyListFilters));
