import {Reducer} from 'redux';

import {ToastNotification} from 'client/models/toast-notification/types';

import types from './types';

type ToastNotificationReducerState = {
  toastNotifications: ToastNotification[];
};

const initialState = {
  toastNotifications: [],
};

const toastNotificatiosReducer: Reducer<ToastNotificationReducerState> = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_TOAST_NOTIFICATIONS:
      return {
        ...state,
        toastNotifications: [
          {
            ...action.toastNotification,
          },
          ...state.toastNotifications,
        ],
      };

    case types.REMOVE_TOAST_NOTIFICATIONS:
      return {
        ...state,
        toastNotifications: state.toastNotifications.filter(({id}) => id !== action.id),
      };

    default:
      return state;
  }
};

export default toastNotificatiosReducer;
