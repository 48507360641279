export function selectParticipations(state) {
  return state.participations.participations;
}

export function selectParticipationsMeta(state) {
  return state.participations.meta;
}

export function selectLevelOptions(state) {
  return state.participations.levelOptions;
}

export function selectLevelStatistic(state) {
  return state.participations.levelStatistic;
}

export function selectTimelineStatistic(state) {
  return state.participations.timelineStatistic;
}

export function selectParticipationsTableDataMeta(state) {
  return state.participations.participationTableData.meta;
}

export const selectParticipantsTableData = (state) => {
  const tableData = state.participations.participationTableData.data;

  return (
    tableData?.map((participant) => {
      return {
        lead_id: participant.lead_id,
        id: participant.id,
        visible_by_client: participant.visible_by_client,
        ...participant.visibility_items,
      };
    }) || []
  );
};
