import {mapParticipationExportReport} from './mappers';
import types from './types';

let init = {
  type: types.NO_DATA,
  exportReport: {},
  exportReports: {
    meta: {},
    items: [],
  },
  exportAPIReports: [],
  exportColumnItems: [],
  availableExportColumns: {},
  fileExportHistory: {
    meta: {current_page: 1, total_pages: 1},
    items: [],
  },
  apiExportHistory: {
    meta: {current_page: 1, total_pages: 1},
    items: [],
  },
};

export default function (_state_ = init, action) {
  let state = Object.assign({}, _state_);
  switch (action.type) {
    case types.GET_API_PARTICIPATION_EXPORT_REPORTS_REQUEST:
      state.exportAPIReports = [];
      return state;

    case types.GET_PARTICIPATION_FILE_EXPORT_HISTORY_REQUEST:
      state.fileExportHistory = init.fileExportHistory;
      return state;

    case types.GET_PARTICIPATION_EXPORT_ITEMS_SECCESS:
      state.exportColumnItems = action.payload.participation_export_items;
      return state;
    case types.GET_PARTICIPATION_EXPORT_REPORT_SUCCESS:
      return {
        ...state,
        type: types.GET_PARTICIPATION_EXPORT_REPORT_SUCCESS,
        exportReport: action.payload.participation_export_report,
      };
    case types.GET_PARTICIPATION_EXPORT_REPORTS_SUCCESS:
      state.exportReports = {
        meta: action.payload.meta,
        items: action.payload.participation_export_reports.map(mapParticipationExportReport),
      };
      return state;
    case types.GET_AVAILABLE_EXPORT_COLUMNS_SECCESS:
      state.availableExportColumns = action.payload;
      return state;
    case types.GET_API_PARTICIPATION_EXPORT_REPORTS_SUCCESS:
      state.exportAPIReports = action.payload.api_participation_exports;
      return state;
    case types.GET_PARTICIPATION_FILE_EXPORT_HISTORY_SUCCESS:
      state.fileExportHistory = {
        meta: action.payload.meta,
        items: action.payload.participation_export_reports.map(mapParticipationExportReport),
      };
      return state;
    case types.GET_PARTICIPATION_API_EXPORT_HISTORY_SUCCESS:
      state.apiExportHistory = {
        meta: action.payload.meta,
        items: action.payload.participation_export_reports.map(mapParticipationExportReport),
      };
      return state;
    default:
      return state;
  }
}
