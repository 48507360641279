import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import types from './types';

export function getExportColumnsItems(exportId) {
  const params = {
    include: {
      column_adapter: null,
    },
    q: {
      participation_export_id_eq: exportId,
    },
  };
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        queryParams: params,
        endpoint: `${API_PATH}${API_METHODS.PARTICIPATION_EXPORT_ITEMS}`,
        method: 'GET',
        types: [
          types.GET_PARTICIPATION_EXPORT_ITEMS_REQUEST,
          types.GET_PARTICIPATION_EXPORT_ITEMS_SECCESS,
          types.GET_PARTICIPATION_EXPORT_ITEMS_ERROR,
        ],
      },
      true,
    );
  };
}

export function createParticipationsExportFile(body, queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.FILE_PARTICIPATION_EXPORT}`,
        method: 'POST',
        types: [
          types.CREATE_FILE_PARTICIPATION_EXPORT_REQUEST,
          types.CREATE_FILE_PARTICIPATION_EXPORT_SUCCESS,
          types.CREATE_FILE_PARTICIPATION_EXPORT_ERROR,
        ],
        body,
        queryParams,
      },
      true,
    );
  };
}

export function getParticipationsExportFile(id, queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.FILE_PARTICIPATION_EXPORT}/${id}${API_METHODS.PREFORM}`,
        method: 'GET',
        types: [
          types.GET_FILE_PARTICIPATION_EXPORT_REQUEST,
          types.GET_FILE_PARTICIPATION_EXPORT_SUCCESS,
          types.GET_FILE_PARTICIPATION_EXPORT_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}

export function apiParticipationsExport(body) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.API_PARTICIPATION_EXPORT}`,
        method: 'POST',
        types: [
          types.API_PARTICIPATION_EXPORT_REQUEST,
          types.API_PARTICIPATION_EXPORT_SUCCESS,
          types.API_PARTICIPATION_EXPORT_ERROR,
        ],
        body,
      },
      true,
    );
  };
}

export function deleteApiParticipationsExport(id) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.API_PARTICIPATION_EXPORT}/${id}`,
        method: 'DELETE',
        types: [
          types.DELETE_API_PARTICIPATION_EXPORT_REQUEST,
          types.DELETE_API_PARTICIPATION_EXPORT_SUCCESS,
          types.DELETE_API_PARTICIPATION_EXPORT_ERROR,
        ],
      },
      true,
    );
  };
}

export const getParticipationsExportReport = (id, queryParams) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.PARTICIPATION_EXPORT_REPORTS}/${id}`,
        method: 'GET',
        noCache: true,
        types: [
          types.GET_PARTICIPATION_EXPORT_REPORT_REQUEST,
          types.GET_PARTICIPATION_EXPORT_REPORT_SUCCESS,
          types.GET_PARTICIPATION_EXPORT_REPORT_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
};

export function getParticipationsExportReports(queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.PARTICIPATION_EXPORT_REPORTS}`,
        method: 'GET',
        noCache: true,
        types: [
          types.GET_PARTICIPATION_EXPORT_REPORTS_REQUEST,
          types.GET_PARTICIPATION_EXPORT_REPORTS_SUCCESS,
          types.GET_PARTICIPATION_EXPORT_REPORTS_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}

export function getParticipationsFileExportHistory(queryParams) {
  const params = cloneDeep(queryParams);
  set(params, 'q.participation_export_type_eq', 'FileParticipationExport');

  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.PARTICIPATION_EXPORT_REPORTS}`,
        method: 'GET',
        noCache: true,
        types: [
          types.GET_PARTICIPATION_FILE_EXPORT_HISTORY_REQUEST,
          types.GET_PARTICIPATION_FILE_EXPORT_HISTORY_SUCCESS,
          types.GET_PARTICIPATION_FILE_EXPORT_HISTORY_ERROR,
        ],
        queryParams: params,
      },
      true,
    );
  };
}

export function getParticipationsApiExportHistory(queryParams) {
  const params = cloneDeep(queryParams);
  set(params, 'q.participation_export_type_eq', 'ApiParticipationExport');

  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.PARTICIPATION_EXPORT_REPORTS}`,
        method: 'GET',
        noCache: true,
        types: [
          types.GET_PARTICIPATION_API_EXPORT_HISTORY_REQUEST,
          types.GET_PARTICIPATION_API_EXPORT_HISTORY_SUCCESS,
          types.GET_PARTICIPATION_API_EXPORT_HISTORY_ERROR,
        ],
        queryParams: params,
      },
      true,
    );
  };
}

export function getParticipationExportFile(link) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: link,
        method: 'GET',
        noCache: true,
        types: ['FILE_EXPORT_REQUEST', 'FILE_EXPORT_SUCCESS', 'FILE_EXPORT_ERROR'],
      },
      true,
    );
  };
}

export function getParticipationsAPIExportReports(queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.API_PARTICIPATION_EXPORT}`,
        method: 'GET',
        noCache: true,
        types: [
          types.GET_API_PARTICIPATION_EXPORT_REPORTS_REQUEST,
          types.GET_API_PARTICIPATION_EXPORT_REPORTS_SUCCESS,
          types.GET_API_PARTICIPATION_EXPORT_REPORTS_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}

export function deleteParticipationsAPIExportReports(id, queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.API_PARTICIPATION_EXPORT}/${id}`,
        method: 'DELETE',
        noCache: true,
        types: [
          types.DELETE_API_PARTICIPATION_EXPORT_REPORTS_REQUEST,
          types.DELETE_API_PARTICIPATION_EXPORT_REPORTS_SUCCESS,
          types.DELETE_API_PARTICIPATION_EXPORT_REPORTS_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}

export function updateParticipationsAPIExportReports(id, body) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.API_PARTICIPATION_EXPORT}/${id}`,
        method: 'PUT',
        noCache: true,
        types: [
          types.PUT_API_PARTICIPATION_EXPORT_REPORTS_REQUEST,
          types.PUT_API_PARTICIPATION_EXPORT_REPORTS_SUCCESS,
          types.PUT_API_PARTICIPATION_EXPORT_REPORTS_ERROR,
        ],
        body,
      },
      true,
    );
  };
}

export function updateParticipationsExportReport(id, body) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PARTICIPATION_EXPORT_REPORTS}/${id}`,
      method: 'PATCH',
      noCache: true,
      types: [
        types.PATCH_PARTICIPATION_EXPORT_REPORTS_REQUEST,
        types.PATCH_PARTICIPATION_EXPORT_REPORTS_SUCCESS,
        types.PATCH_PARTICIPATION_EXPORT_REPORTS_ERROR,
      ],
      body,
    });
  };
}

export function getAvailableColumnAdaptersByAutomationTaskForExport(taskId) {
  const scope = 'participations_export';
  const queryParams = {
    automation_task_id: taskId,
    scope,
  };
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.COLUMN_ADAPTER_PERMITTED}`,
      method: 'GET',
      types: [
        types.GET_AVAILABLE_EXPORT_COLUMNS_REQUEST,
        types.GET_AVAILABLE_EXPORT_COLUMNS_SECCESS,
        types.GET_AVAILABLE_EXPORT_COLUMNS_ERROR,
      ],
      queryParams,
    });
}

export const shareParticipationExport =
  ({id, queryParams} = {}) =>
  (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.FILE_PARTICIPATION_EXPORT}/${id}/manage_sharing`,
      method: 'POST',
      types: [
        types.SHARE_PARTICIPATION_EXPORT_REQUEST,
        types.SHARE_PARTICIPATION_EXPORT_SUCCESS,
        types.SHARE_PARTICIPATION_EXPORT_ERROR,
      ],
      queryParams,
    });

export const deleteFileParticipationExport = (id) => {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.FILE_PARTICIPATION_EXPORT}/${id}`,
      method: 'DELETE',
      noCache: true,
      types: [
        types.DELETE_FILE_PARTICIPATION_EXPORT_REQUEST,
        types.DELETE_FILE_PARTICIPATION_EXPORT_SUCCESS,
        types.DELETE_FILE_PARTICIPATION_EXPORT_ERROR,
      ],
    });
  };
};
