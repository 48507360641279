import {RSAA} from 'redux-api-middleware';

import {callApi} from 'client/services/call-api';
import {getQueryParams} from 'client/services/helpers';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

const getSearchQueryParam = (column, search) => {
  switch (column.kind) {
    case 'string':
    case 'text':
    case 'email':
      return `&q[g][1][data->>${column.column_id}_${column.array ? 'contains_array' : 'cont'}]=${search}`;

    case 'number':
    case 'decimal':
      if (isNaN(+search)) {
        return '';
      }
      return `&q[g][1][data->>${column.column_id}_${column.array ? 'contains_array' : 'eq'}]=${search}`;

    default:
      return '';
  }
};

const mapQueryParams = ({operationId, page, perPage, sort = {}, search, typeIn}, visibleColumns) => {
  let params = 'q[g][0][automation_task_operation_id_eq]=' + operationId;
  params += `&q[s]=${sort.name} ${sort.order}`;
  params += `&page=${page}&per_page=${perPage}`;

  if (search) {
    params += '&q[g][1][m]=or';
    visibleColumns
      .filter((i) => i && i.column_id)
      .forEach((i) => {
        params += getSearchQueryParam(i, search);
      });
  }

  if (Array.isArray(typeIn) && typeIn.length) {
    typeIn.forEach((type) => {
      params += `&q[type_in][]=${type}`;
    });
  }

  return params;
};

export function getOperationDataAction(params, visibleColumns = [], filterColumns = []) {
  return (dispatch) => {
    const queryParams = mapQueryParams(
      params,
      visibleColumns.map((i) => i.column_adapter),
      filterColumns,
    );

    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.OPERATION_DATA}?${queryParams}`,
        method: 'GET',
        types: [
          types.GET_CUSTOM_OPERATION_DATA_REQUEST,
          types.GET_CUSTOM_OPERATION_DATA_SUCCESS,
          types.GET_CUSTOM_OPERATION_DATA_ERROR,
        ],
        isAuth: true,
        noCache: true,
      },
    });
  };
}

export function getColumnsAction(operationId) {
  return (dispatch) => {
    const queryParams = getQueryParams({
      q: {
        operation_id_eq: operationId,
      },
      include: ['external_column_adapter'],
    });
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.OPERATION_DATUM_COLUMNS}?${queryParams}`,
        method: 'GET',
        types: [types.GET_COLUMNS_REQUEST, types.GET_COLUMNS_SUCCESS, types.GET_COLUMNS_ERROR],
        isAuth: true,
        noCache: true,
      },
    });
  };
}

export function getSystemColumnsAction() {
  return (dispatch) => {
    const queryParams = getQueryParams({
      q: {
        record_type_eq: 'OperationDatum',
      },
    });
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.SYSTEM_COLUMN_ADAPTERS}?${queryParams}`,
        method: 'GET',
        types: [types.GET_SYSTEM_COLUMNS_REQUEST, types.GET_SYSTEM_COLUMNS_SUCCESS, types.GET_SYSTEM_COLUMNS_ERROR],
        isAuth: true,
        noCache: true,
      },
    });
  };
}

export function getVisibleColumnsAction(operationId) {
  return (dispatch) => {
    const queryParams = getQueryParams({
      q: {
        operation_id_eq: operationId,
      },
      include: ['column_adapter'],
    });
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.OPERATION_DATUM_DISPLAY_ITEMS}?${queryParams}`,
        method: 'GET',
        types: [types.GET_VISIBLE_COLUMNS_REQUEST, types.GET_VISIBLE_COLUMNS_SUCCESS, types.GET_VISIBLE_COLUMNS_ERROR],
        isAuth: true,
        noCache: true,
      },
    });
  };
}

export function getOperationData(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATION_DATA}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_OPERATION_DATA_REQUEST, types.GET_OPERATION_DATA_SUCCESS, types.GET_OPERATION_DATA_ERROR],
      queryParams,
    });
}

export function patchOperationDatumColumn(id, body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATION_DATUM_COLUMNS}/${id}`,
      method: 'PATCH',
      types: [
        types.PATCH_OPERATION_DATUM_COLUMN_REQUEST,
        types.PATCH_OPERATION_DATUM_COLUMN_SUCCESS,
        types.PATCH_OPERATION_DATUM_COLUMN_ERROR,
      ],
      body,
    });
}

export function deleteOperationDatumColumn(id) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATION_DATUM_COLUMNS}/${id}`,
      method: 'DELETE',
      types: [
        types.DELETE_OPERATION_DATUM_COLUMN_REQUEST,
        types.DELETE_OPERATION_DATUM_COLUMN_SUCCESS,
        types.DELETE_OPERATION_DATUM_COLUMN_ERROR,
      ],
    });
}

export function postOperationDatumColumn(body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATION_DATUM_COLUMNS}`,
      method: 'POST',
      types: [
        types.POST_OPERATION_DATUM_COLUMN_REQUEST,
        types.POST_OPERATION_DATUM_COLUMN_SUCCESS,
        types.POST_OPERATION_DATUM_COLUMN_ERROR,
      ],
      body,
    });
}
