import {mapToOptions} from './mappers';

export function selectOperations(state) {
  return state.operations.operations;
}

export function selectOperationsMeta(state) {
  return state.operations.meta;
}

export function selectMyOperationsMeta(state) {
  return state.operations.metaMyOperations;
}

export function selectMyOperationsCount(state) {
  return selectMyOperationsMeta(state).total_count;
}

export function selectOperationsCount(state) {
  return selectOperationsMeta(state).total_count;
}

/**
 * @param state
 * @returns {import('client/models/operations/types').Operation}
 */
export function selectOperation(state) {
  return state.operations.operation;
}

export function selectOperationEmailTemplates(state) {
  return state.operations.operationEmailTemplates;
}

export function selectOperationMessageTasks(state) {
  return selectOperation(state).message_tasks;
}

export function selectOperationAutomationTasks(state) {
  return selectOperation(state).automation_tasks;
}

export function selectOperationMessageTaskNames(state) {
  const tasks = selectOperationMessageTasks(state) || [];

  return tasks.map((task) => task.name);
}

export function selectOperationsAdaptersMapped(state) {
  const operations = selectOperations(state);

  return operations.map((operation) => {
    return {
      value: operation.id,
      label: operation.name,
      columnAdapters:
        operation.operation_datum_columns &&
        operation.operation_datum_columns.map((column) => {
          return {
            id: column.external_column_adapter.id,
            name: column.external_column_adapter.name,
            kind: column.external_column_adapter.kind,
            array: column.external_column_adapter.array,
          };
        }),
    };
  });
}

/**
 * Selects operations with all kinds of tasks. Tasks ids then look like 'AutomationTask35', 'PostTask22', etc.
 * @param {Object} state Redux state
 * @returns {Array} Operations with united tasks
 */
export function selectOperationsWithUnitedTasks(state) {
  return state.operations.operations.map((operation) => {
    const tasks = operation.automation_tasks
      ? operation.automation_tasks
          .map((i) => ({...i, id: i.type + i.id}))
          .concat(operation.message_tasks.map((i) => ({...i, id: i.type + i.id})))
      : [];

    return {
      ...operation,
      tasks,
    };
  });
}

export function selectOperationsForFilterModal(state) {
  const noMatterTextLabel = state.languageState.payload.LEADS_LIST.TABLE_FILTERS_MODAL_NO_MATTER_RADIO;

  return [
    {
      value: '0',
      label: noMatterTextLabel,
    },
    ...state.operations.operations.map(({id, name}) => ({value: id, label: name})),
  ];
}

export function selectActiveOperations(state) {
  return state.operations.activeOperations;
}

export function selectConfigurationOperations(state) {
  return state.operations.configurationOperations;
}

export function selectFinishedOperations(state) {
  return state.operations.finishedOperations;
}

export function selectClientParams(state) {
  return state.operations.clientParams;
}

export function selectRegionsAsOptions(state) {
  const regions = state.operations.regions;

  return mapToOptions(regions);
}

export function selectPlacesAsOptions(state) {
  const places = state.operations.places;

  return mapToOptions(places);
}

export const selectPrizeLanguages = (state) => {
  const {default_language, languages = []} = state.operations.operation;

  const preparedLanguages = languages.filter((lang) => lang !== default_language).sort();

  return [default_language, ...preparedLanguages].filter((i) => i);
};

export const selectInterfaceDefaultLanguage = (state) => state.operations.operation.client_interface?.default_language;

export const selectClientInterface = (state) => state.operations.operation?.client_interface || null;
