import React, {Component, Suspense} from 'react';

import buzzeoLogo from 'assets/images/buzzeo-logo.png';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {selectLogoForHeader, selectLogoForFooter} from 'client/ducks/user-clients/selectors';

import {APP_ROLES, CLIENT_PAGES, WINNING_PAGES} from 'client/common/config';
import Header from 'client/common/header/common-header';

import Footer from 'client/components/common/footer';
import Navbar from 'client/components/common/navigation/index.jsx';

import BasePageLayout from 'client/components/base-page-layout';
import WinningHeader from 'client/components/winnings/various/winning-header';

import Spinner from '../components/common/spinner';

import './layout.scss';

class LayoutWrapper extends Component {
  static propTypes = {
    isAdmin: PropTypes.bool,
    isViewModeActive: PropTypes.bool,
    isUserLogged: PropTypes.bool,
    isLoading: PropTypes.bool,
    isPolicyAccepted: PropTypes.bool,
    isLogoAlwaysShown: PropTypes.bool,
    backgroundColor: PropTypes.string,
    userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    role: PropTypes.string,
    route: PropTypes.object.isRequired,
    routeParams: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    nobreadcrumbs: PropTypes.bool,
    logoForHeader: PropTypes.string,
    logoForFooter: PropTypes.string,
    clientsList: PropTypes.array,
    onClientChange: PropTypes.func.isRequired,
    setViewModeUserId: PropTypes.func,
    children: PropTypes.node,
    isMobile: PropTypes.bool,
  };

  getHeaderLogo = () => {
    switch (this.props.location.pathname) {
      case CLIENT_PAGES.UPDATE_PREFERENCES:
      case CLIENT_PAGES.UNSUBSCRIBE:
        return buzzeoLogo;

      default:
        return this.props.logoForHeader;
    }
  };

  hasAccess = () => {
    const {isAdmin, isViewModeActive, routeParams, isUserLogged} = this.props;

    if (isUserLogged || (!isUserLogged && routeParams.isNotNeedLogin)) {
      return true;
    }
    // user is admin
    if (isAdmin && !('isNotNeedLogin' in routeParams)) {
      if ('viewMode' in routeParams && routeParams.viewMode) {
        return isViewModeActive;
      }
      return true;
    }
    // user is client
    if ('viewMode' in routeParams && routeParams.viewMode && !('isNotNeedLogin' in routeParams)) {
      return true;
    }

    return false;
  };

  getIsWinning = () => {
    const {role, location} = this.props;
    return role === APP_ROLES.TRACKING_USER || location.pathname === WINNING_PAGES.LOGIN;
  };

  renderHeader = () => {
    const {isUserLogged, role, isViewModeActive, clientsList, isLogoAlwaysShown, userId, onClientChange, isMobile} =
      this.props;
    const headerLogo = this.getHeaderLogo();

    if (this.getIsWinning()) {
      return <WinningHeader isMobile={isMobile} isUserLogged={isUserLogged} />;
    }

    return (
      <Header
        isLoggedUser={isUserLogged}
        clients={clientsList}
        userRole={role}
        isLogoAlwaysShown={isLogoAlwaysShown}
        logo={headerLogo}
        userId={userId}
        onClientChange={onClientChange}
        isViewModeActive={isViewModeActive}
      />
    );
  };

  shouldRenderSidebar = () => {
    const {isUserLogged, isPolicyAccepted, isViewModeActive} = this.props;

    if (this.getIsWinning()) {
      return false;
    }

    if (!(isUserLogged && isPolicyAccepted) && !isViewModeActive) {
      return false;
    }

    return true;
  };

  renderSidebar = () => {
    const {route, isViewModeActive, location} = this.props;
    // todo: add AdminSidebar and ClientSidebar
    return (
      this.shouldRenderSidebar() && <Navbar location={location} route={route} isViewModeActive={isViewModeActive} />
    );
  };

  renderLayoutContainer = () => {
    const {children, isAdmin, nobreadcrumbs, logoForFooter, isUserLogged, isViewModeActive, routeParams, isMobile} =
      this.props;

    const backgroundColor = (isMobile && routeParams?.backgroundMobile) || routeParams?.background || '';

    return (
      <BasePageLayout
        style={{backgroundColor}}
        nobreadcrumbs={nobreadcrumbs}
        isUserLogged={isUserLogged}
        isUserNotAdmin={!isAdmin}
        isViewModeActive={isViewModeActive}
        footer={<Footer footerLogo={logoForFooter} isUserNotAdmin={!isAdmin} isWinning={this.getIsWinning()} />}
        hasSidebar={this.shouldRenderSidebar()}
      >
        {this.hasAccess() ? children : null}
      </BasePageLayout>
    );
  };

  renderAdminLayout = () => {};

  renderClientLayout = () => {};

  render() {
    const {isLoading} = this.props;

    return (
      <div className="layout">
        {!isLoading && this.renderHeader()}
        {!isLoading && this.renderSidebar()}

        <Suspense
          fallback={
            <div className="layout__root-spinner">
              <Spinner centered />
            </div>
          }
        >
          {this.props.isLoading ? (
            <div className="layout__root-spinner">
              <Spinner centered />
            </div>
          ) : (
            this.renderLayoutContainer()
          )}
        </Suspense>
      </div>
    );
  }
}

export default connect((state) => ({
  logoForHeader: selectLogoForHeader(state),
  logoForFooter: selectLogoForFooter(state),
}))(LayoutWrapper);
