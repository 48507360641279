import React from 'react';

import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import fieldTemplate from 'client/components/common/field';

import {TranslationJsx} from 'client/models/language/types';

export function userToOption(user) {
  return {
    value: user.currentMembership,
    label: user.full_name,
    email: user.email,
    selectCustomOption: <div className="leads-port-layout__option-link">{user.email}</div>,
  };
}

function customValueRenderer(item) {
  return <span title={`${item.label} ${item.email}`}>{item.email}</span>;
}

function AllowedUsersField(props) {
  const {name, label, users, ...rest} = props;

  return (
    <Field
      cssModifier="select--view-4"
      options={users.map(userToOption)}
      label={label}
      name={name}
      type="select"
      searchable={false}
      component={fieldTemplate}
      multi={true}
      customValueRenderer={customValueRenderer}
      {...rest}
    />
  );
}

AllowedUsersField.propTypes = {
  name: PropTypes.string,
  label: TranslationJsx,
  users: PropTypes.array,
};

AllowedUsersField.defaultProps = {
  name: 'users',
  label: '',
  users: [],
};

export default AllowedUsersField;
