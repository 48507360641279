import React from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {getOrganizationId} from 'client/services/cookie-data-source';
import {useLanguage} from 'client/services/hooks';
import useWeezioDocLink from 'client/services/hooks/useWeezioDocLink';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {selectClientAccessLevel, selectClientUserMembership} from 'client/ducks/user-clients/selectors';

import {NAV_LINKS, NAV_GROUPS_MAP, CLIENT_PAGES} from 'client/common/config';
import {USER_MEMBERSHIP} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';

import {CLIENT_TYPES} from 'client/models/client/constants';

import NavigationItem from '../navigation-item';
import NavigationSubitem from '../navigation-subitem';

const b = bem('navigation');

type NavigationClientVersionProps = {
  openedItem?: string;
  currentUrl?: string;
  accessLevel: string;
};

const NavigationClientVersion: React.FC<NavigationClientVersionProps> = (props) => {
  const {openedItem, currentUrl} = props;
  const client = useSelector(selectCurrentClient);
  const membership = useSelector(selectClientUserMembership);
  const accessLevel = useSelector(selectClientAccessLevel);

  const weezioDocLink = useWeezioDocLink();

  const {id: clientId} = client;

  const lang = useLanguage('NAVIGATION');

  const organizationId = getOrganizationId();

  return (
    <div className={b('content')}>
      <div className={b('top')}>
        <NavigationItem
          to={`/clients/${organizationId}/operations`}
          dataNavItem={NAV_GROUPS_MAP.HOME}
          iconName="operations"
          name={lang.OPERATIONS}
          colorTheme="9"
          className={b('item', ['no-icon-plus'])}
        />
        <NavigationItem
          to={`/clients/${organizationId}/devices`}
          exact
          dataNavItem={NAV_GROUPS_MAP.DEVICES}
          iconName="devices"
          name={lang.DEVICES}
          colorTheme="9"
          className={b('item', ['no-icon-plus'])}
          disabled={(client.device_affectations_count || 0) < 1}
          disabledTooltip={lang.TOOLTIPS.NO_DEVICES}
        />
        <NavigationItem
          dataNavItem={NAV_GROUPS_MAP.LEADS}
          iconName="users"
          name={lang.CRM}
          colorTheme="9"
          opened={openedItem === NAV_GROUPS_MAP.LEADS}
          isActive={!!currentUrl?.match(/\/clients\/\d+\/leads/)}
        >
          <NavigationSubitem
            to={`/clients/${organizationId}/leads/dashboard`}
            dataChildOf={NAV_GROUPS_MAP.LEADS}
            colorTheme="9"
            currentUrl={currentUrl}
          >
            {lang.CLIENT_VERSION.DASHBOARD}
          </NavigationSubitem>
          {(accessLevel === USER_MEMBERSHIP.CLIENT_ADMIN || membership?.data_tab_access?.leads) && (
            <NavigationSubitem
              to={`/clients/${organizationId}/leads/database`}
              dataChildOf={NAV_GROUPS_MAP.LEADS}
              colorTheme="9"
              currentUrl={currentUrl}
              disableText={lang.HINTS.NO_LEADS}
            >
              {lang.DATABASE}
            </NavigationSubitem>
          )}
          {accessLevel === USER_MEMBERSHIP.CLIENT_ADMIN && (
            <NavigationSubitem
              to={`/clients/${organizationId}/leads/parameters`}
              dataChildOf={NAV_GROUPS_MAP.LEADS}
              colorTheme="9"
              currentUrl={currentUrl}
            >
              {lang.PARAMETERS}
            </NavigationSubitem>
          )}
        </NavigationItem>
        <NavigationItem
          dataNavItem={NAV_GROUPS_MAP.VISUALS}
          iconName="images"
          name={lang.CLIENT_VERSION.VISUALS}
          colorTheme="9"
          opened={openedItem === NAV_GROUPS_MAP.VISUALS}
          disabled
        >
          <NavigationSubitem
            to={NAV_LINKS.HOME}
            exact
            dataChildOf={NAV_GROUPS_MAP.VISUALS}
            colorTheme="9"
            currentUrl={currentUrl}
            disabled
          >
            {lang.CLIENT_VERSION.DASHBOARD}
          </NavigationSubitem>
          <NavigationSubitem
            to={CustomLink.createVisualsLink(clientId)}
            exact
            dataChildOf={NAV_GROUPS_MAP.VISUALS}
            colorTheme="9"
            currentUrl={currentUrl}
            disabled
          >
            {lang.CLIENT_VERSION.GALLERY}
          </NavigationSubitem>
        </NavigationItem>
        <NavigationItem
          to={NAV_LINKS.HOME}
          exact
          dataNavItem={NAV_GROUPS_MAP.PERMANENT_SOURCES}
          iconName="sources"
          name={lang.PERMANENT_SOURCES}
          colorTheme="9"
          className={b('item', ['no-icon-plus'])}
          disabled
        />
        {membership?.client_id === client?.id &&
          membership?.editor &&
          (!!client.user_access_levels?.length || client.auto_configuration) && (
            <NavigationItem
              dataNavItem={NAV_GROUPS_MAP.CATALOGS}
              iconName="nav-catalogs"
              name={lang.CATALOGS}
              colorTheme="9"
              opened={openedItem === NAV_GROUPS_MAP.CATALOGS}
            >
              <NavigationSubitem
                to={`/clients/${organizationId}${NAV_LINKS.CATALOG_TEMPLATES}`}
                exact
                dataChildOf={NAV_GROUPS_MAP.CATALOGS}
                colorTheme="9"
                currentUrl={currentUrl}
              >
                {lang.CATALOGS_TEMPLATES}
              </NavigationSubitem>
              <NavigationSubitem
                to={`/clients/${organizationId}${NAV_LINKS.CATALOG_MODULES}`}
                exact
                dataChildOf={NAV_GROUPS_MAP.CATALOGS}
                colorTheme="9"
                currentUrl={currentUrl}
              >
                {lang.CATALOGS_MODULES}
              </NavigationSubitem>
            </NavigationItem>
          )}
        {client.type === CLIENT_TYPES.AGENCY && (
          <NavigationItem
            to={`/clients/${organizationId}${NAV_LINKS.AGENCY_CLIENTS}`}
            dataNavItem={NAV_GROUPS_MAP.AGENCY_CLIENTS}
            iconName="agency-clients"
            name={lang.CLIENTS}
            colorTheme="9"
            className={b('item', ['no-icon-plus'])}
          />
        )}
      </div>
      <div className="navigation__bottom">
        <NavigationItem
          dataNavItem={NAV_GROUPS_MAP.HELP}
          iconName="help"
          name={lang.HELP}
          colorTheme="9"
          className={b('item', ['no-icon-plus'])}
          externalLink={weezioDocLink}
        />
        <NavigationItem
          to={CLIENT_PAGES.ACCOUNT_PARAMETERS}
          dataNavItem={NAV_GROUPS_MAP.ORG_PARAMETERS}
          iconName="account-settings"
          name={lang.ACCOUNT_PARAMETERS}
          colorTheme="9"
          className={b('item', ['no-icon-plus'])}
        />
      </div>
    </div>
  );
};

export default NavigationClientVersion;
