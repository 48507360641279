import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Popover from 'client/common/popovers/popover';

import {TranslationJsx} from 'client/models/language/types';

import AtsStepDropdown from './components/ats-step-dropdown';
import AtsStepList from './components/ats-step-list/ats-step-list';

import './ats-scenario-editor.scss';

class AtsScenarioEditor extends PureComponent {
  static propTypes = {
    labels: PropTypes.shape({
      stepList: TranslationJsx,
      stepConfig: TranslationJsx,
      addStep: TranslationJsx,
      impossibleToAdd: TranslationJsx,
      pleaseDeleteOne: TranslationJsx,
    }).isRequired,
    stepNames: PropTypes.object.isRequired,
    applyBtnText: TranslationJsx,
    scenarioSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    invalidForms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleAddStep: PropTypes.func.isRequired,
    handleSelectStep: PropTypes.func.isRequired,
    handleSaveStep: PropTypes.func.isRequired,
    handleSaveSingleStep: PropTypes.func.isRequired,
    handleDeleteStep: PropTypes.func.isRequired,
    handleSortSteps: PropTypes.func.isRequired,
    handleSubmitResult: PropTypes.func.isRequired,
    isPossibleToAddStep: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    applyBtnText: '',
  };

  renderTooltip() {
    return <span id="tooltip-1">{this.props.labels.pleaseDeleteOne}</span>;
  }

  render() {
    return (
      <div className="ats-scenario-editor">
        {this.props.isPossibleToAddStep ? (
          <div className="ats-scenario-editor__dropdown">
            <AtsStepDropdown
              onSelect={this.props.handleAddStep}
              text={this.props.labels.addStep}
              stepNames={this.props.stepNames}
            />
          </div>
        ) : (
          <Popover
            position="right"
            overlay={this.renderTooltip()}
            className="ats-scenario-editor__popover"
            shiftLeft={10}
          >
            <div className="ats-scenario-editor__navbar">
              <div className="ats-step-list__col-title">
                <div className="add-step-info">{this.props.labels.impossibleToAdd}</div>
              </div>
            </div>
          </Popover>
        )}
        <div className="ats-scenario-editor__step-list">
          <AtsStepList
            stepNames={this.props.stepNames}
            applyBtnText={this.props.applyBtnText}
            stepConfigTitle={this.props.labels.stepConfig}
            stepListTitle={this.props.labels.stepList}
            steps={this.props.scenarioSteps}
            handleSelectStep={this.props.handleSelectStep}
            handleSaveStep={this.props.handleSaveStep}
            handleSaveSingleStep={this.props.handleSaveSingleStep}
            handleDeleteStep={this.props.handleDeleteStep}
            handleSortSteps={this.props.handleSortSteps}
            handleSubmitResult={this.props.handleSubmitResult}
            invalidForms={this.props.invalidForms}
          />
        </div>
      </div>
    );
  }
}

export default connect(({languageState}) => ({
  languageState,
}))(AtsScenarioEditor);
