import React, {useState} from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';

import {CLIENT_TYPES} from 'client/common/config';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import CustomLink from 'client/components/common/custom-link';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';

import './ongoing-operations-table.scss';

const b = bem('ongoing-operations-table');

const OngoingOperationsTable = (props) => {
  const {languageState, onPageChange, onSortChange, data, currentPage, addNewClick, totalPages, totalItems} = props;

  const [sort, setSort] = useState({sortField: '', sortOrder: ''});
  const [perPage, setPerPage] = useState(5);

  const lang = {
    ...languageState.payload.AGENCY,
    IMAGE_CONTAINER: languageState.payload.OPERATION_PAGE.IMAGE_CONTAINER,
  };

  const renderOperationImage = ({item: operation}) => {
    const url = get(operation, 'operation_image.url', null);
    return (
      <div className={b('image-cell')}>
        {url ? <img className={b('image')} src={operation.operation_image.url} /> : lang.IMAGE_CONTAINER.NO_IMAGE}
      </div>
    );
  };

  const renderName = ({item: operation}) => {
    return (
      <Link
        to={CustomLink.createOperationLink({
          clientType: get(operation, 'client.type', CLIENT_TYPES.AGENCY),
          operationId: operation.id,
          clientId: get(operation, 'client.id', operation.client_id),
        })}
      >
        {operation.name}
      </Link>
    );
  };

  const renderPeriod = ({item: operation}) => {
    return (
      <div>
        {moment(operation.from).format('DD/MM/YYYY')} - {moment(operation.to).format('DD/MM/YYYY')}
      </div>
    );
  };

  const renderTask = (operation, task) => {
    return (
      <Link
        key={task.id}
        to={CustomLink.createTaskLink({
          taskType: task.type,
          clientType: get(operation, 'client.type', CLIENT_TYPES.AGENCY),
          operationId: operation.id,
          clientId: get(operation, 'client.id', operation.client_id),
          taskId: task.id,
        })}
        className={cn(b('link'), 'ellipsis-text')}
      >
        {task.name}
      </Link>
    );
  };

  const renderTasks = ({item: operation}) => {
    return (
      <div className={cn(b('tasks_list'))}>
        <CustomScrollbars
          scrollbarProps={{
            autoHeightMax: 80,
          }}
        >
          <div style={{width: '74%'}}>
            {operation.automation_tasks && operation.automation_tasks.map((task) => renderTask(operation, task))}
            {operation.message_tasks && operation.message_tasks.map((task) => renderTask(operation, task))}
          </div>
        </CustomScrollbars>
      </div>
    );
  };

  const handleSortChange = ({sortField, sortOrder}) => {
    setSort({sortField, sortOrder});
    onSortChange(sortField, sortOrder);
  };

  const handlePerPageChange = (value) => {
    setPerPage(+value);
    onPageChange({perPage: value, page: 1});
  };

  return (
    <div className={b()}>
      <div className={b('toolbar')}>
        <button className="button button--bg-4" onClick={addNewClick}>
          <Icon name="plus" className="button__icon" />
          <span>{lang.ADD_NEW_BUTTON}</span>
        </button>
        <PerPageDropdown
          simpleValue
          value={perPage.toString()}
          options={[5, 10, 15].map((n) => ({value: `${n}`, label: n}))}
          onChange={handlePerPageChange}
        />
      </div>
      <ClientTable
        data={data}
        {...sort}
        onSortChange={handleSortChange}
        columns={[
          {
            name: 'image',
            path: 'image',
            width: 200,
            label: lang.OPERATION_IMAGE_COLUMN,
            render: renderOperationImage,
          },
          {
            name: 'name',
            path: 'name',
            width: 200,
            sortable: true,
            label: lang.OPERATION_NAME_COLUMN,
            render: renderName,
          },
          {
            name: 'code',
            path: 'code',
            label: lang.OPERATION_CODE_COLUMN,
          },
          {
            name: 'period',
            path: 'period',
            label: lang.PERIOD_COLUMN,
            render: renderPeriod,
          },
          {
            name: 'tasks',
            path: 'tasks',
            label: lang.TASKS_COLUMN,
            render: renderTasks,
          },
        ]}
      />
      <PaginationBar
        data={data}
        perPage={perPage}
        onPageChange={onPageChange}
        currentPage={currentPage}
        totalPages={totalPages}
        totalItems={totalItems}
      />
    </div>
  );
};

OngoingOperationsTable.propTypes = {
  data: PropTypes.array,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  totalPages: PropTypes.number,
  totalItems: PropTypes.number,
  currentPage: PropTypes.number,
  children: PropTypes.node,
  onPageChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  addNewClick: PropTypes.func.isRequired,
  languageState: PropTypes.object.isRequired,
};

OngoingOperationsTable.defaultProps = {
  data: [],
  totalPages: 0,
  totalItems: 0,
  currentPage: 0,
};

export default OngoingOperationsTable;
