import React from 'react';

import find from 'lodash/find';
import PropTypes from 'prop-types';

import {PREFIXES} from 'client/ducks/coupons/constants';

import CouponInfoRow from '../coupon-info-row';

const getPrefixValue = (viewingCoupon, number, lang) => {
  const prefix = find(viewingCoupon.coupon_prefixes, {number});

  switch (prefix.prefix_type) {
    case PREFIXES.WITHOUT:
      return lang.WITHOUT;
    case PREFIXES.VARIABLE:
      return lang.VARIABLE;
    case PREFIXES.FIXED_VALUE:
      return prefix.value;
    default:
      return '';
  }
};

const CouponInfoWeezio = (props) => {
  const {lang, viewingCoupon} = props;

  return (
    <div>
      <CouponInfoRow label={lang.MAXIMUM_TOKENS_NUMBER}>{viewingCoupon.max_number_of_tokens}</CouponInfoRow>
      <CouponInfoRow sectionLabel={lang.FORMAT} />
      <CouponInfoRow label={lang.LENGTH_WITHOUT_PREFIX}>{viewingCoupon.token_length}</CouponInfoRow>
      <CouponInfoRow label={lang.CHARACTERS}>{lang[viewingCoupon.allowed_characters.toUpperCase()]}</CouponInfoRow>
      <CouponInfoRow label={lang.CHARACTERS_FORMAT}>
        {lang[viewingCoupon.default_characters.toUpperCase()]}
      </CouponInfoRow>
      <CouponInfoRow label={lang.EXCLUDED_CHARACTERS} scrollable>
        {viewingCoupon.excluded_characters}
      </CouponInfoRow>
      <CouponInfoRow label={lang.PREFIX1}>{getPrefixValue(viewingCoupon, 1, lang)}</CouponInfoRow>
      <CouponInfoRow label={lang.PREFIX2}>{getPrefixValue(viewingCoupon, 2, lang)}</CouponInfoRow>
    </div>
  );
};

CouponInfoWeezio.propTypes = {
  lang: PropTypes.object.isRequired,
  viewingCoupon: PropTypes.shape({
    max_number_of_tokens: PropTypes.number,
    token_length: PropTypes.number,
    allowed_characters: PropTypes.string,
    default_characters: PropTypes.string,
    excluded_characters: PropTypes.string,
    coupon_prefixes: PropTypes.array,
  }).isRequired,
};

export default CouponInfoWeezio;
