import find from 'lodash/find';

import {dateToString} from 'client/services/helpers';

export function mapApiLeadExport(data) {
  return {
    ...data,
    from: dateToString(data.from),
    to: dateToString(data.to),
    memberships: data.memberships
      ? data.memberships.map((item) => ({
          ...item,
          transferableMembershipId: find(data.transferable_memberships, {membership_id: item.id}).id,
          client_user: {
            ...item.client_user,
            currentMembership: item.id,
          },
        }))
      : [],
  };
}

export function mapLeadExportReport(report) {
  return {
    id: report.id,
    exportId: report.lead_transfer?.id,
    export: report.lead_transfer,
    name: report.lead_transfer?.name || '',
    comment: report.lead_transfer?.comment || '',
    total: report.total,
    date: report.created_at,
    userName: report.created_by,
    status: report.status,
    link: report.leads_file.url,
    allowed_for_all: report.lead_transfer?.allowed_for_all || false,
    deletable: report.deletable,
    shareable: report.shareable,
    fileName: `${report.lead_transfer?.name || ''}.${report.lead_transfer?.format || ''}`,
  };
}
