export const getTranslationDescendants = (translationsArray, translationId) => {
  return translationsArray.reduce((acc, el) => {
    if (el.parent_id === translationId) {
      acc.push(el);
      return [...acc, ...getTranslationDescendants(translationsArray, el.id)];
    }
    return acc;
  }, []);
};

export const getUnapprovedTranslations = (translations) => {
  return (
    translations?.reduce((acc, {key, path, translation_items}) => {
      translation_items?.forEach((el) => {
        if (!el.approved) {
          acc.push({...el, key, path});
        }
      });
      return acc;
    }, []) || []
  );
};
