import {get} from 'lodash';

import {CatalogTemplateTabType, ClientTemplate} from 'client/models/templates/types';

import {STEPS, StepType} from '../helpers';

export const MANDATORY_STEPS: StepType[] = [STEPS.GENERAL, STEPS.SUMMARY];

export const calcSteps = (
  groupedTemplates: Record<CatalogTemplateTabType, ClientTemplate[]>,
  presetTemplateId?: number,
): StepType[] => {
  if (presetTemplateId) {
    const template = Object.values(groupedTemplates)
      .flat()
      .find((item) => item.id === presetTemplateId);
    const modules = get(template, 'catalog.catalog_modules', []);
    return [STEPS.GENERAL, ...modules, STEPS.SUMMARY];
  }
  return MANDATORY_STEPS;
};
