import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';

import {getOrganizationId, getIsNational} from 'client/services/cookie-data-source';

import {selectIsAdmin, selectIsUserLoaded} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import VisualsClient from 'client/components/visuals/visuals-client';

class VisualsClientPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    setMetaAction: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.setMetaAction({
      title: this.props.languageState.payload.VISUALS.TITLE,
    });
  }

  render() {
    const {isAdmin, isUserLoaded} = this.props;

    if (!isUserLoaded) {
      return null;
    }

    if (!isAdmin && getOrganizationId() !== this.props.match.params.id) {
      return <Redirect to={CLIENT_PAGES.HOME} />;
    }

    return <VisualsClient isNational={getIsNational()} clientId={+this.props.match.params.id} />;
  }
}
const mapStateToProps = (state) => ({
  languageState: state.languageState,
  isAdmin: selectIsAdmin(state),
  isUserLoaded: selectIsUserLoaded(state),
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(VisualsClientPage);
