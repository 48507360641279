import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import LayoutPanel from 'client/common/panels/layout-panel';

import PrizeDiyRatioParticipationFieldset from 'client/components/prizes/fieldsets/prize-diy-ratio-participation-fieldset';

import cssModule from './prize-diy-ratio-participation-panel.module.scss';

const b = bem('prize-diy-ratio-participation-panel', {cssModule});

type PrizeDiyRatioParticipationPanelProps = {
  disabled?: boolean;
};

const PrizeDiyRatioParticipationPanel: React.FC<PrizeDiyRatioParticipationPanelProps> = ({disabled}) => {
  const lang = useLanguage('PRIZES.PANELS.PRIZE_DIY_RATIO_PARTICIPATION_PANEL');

  return (
    <LayoutPanel className={b()}>
      <p className={b('title')}>{lang.TITLE}</p>
      <PrizeDiyRatioParticipationFieldset className={b('body')} disabled={disabled} />
    </LayoutPanel>
  );
};

export default PrizeDiyRatioParticipationPanel;
