import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {downloadFileWithAuth} from 'client/services/helpers';
import {canManipulateTransfer} from 'client/services/membershipHelpers';

import {deleteLeadsExportFile} from 'client/ducks/leads-export/actions';
import {selectIsAdmin, selectCurrentUser} from 'client/ducks/user/selectors';

import Icon from 'client/common/icon';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Popover from 'client/common/popovers/popover';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import './leads-summary-popover.scss';

class LeadsSummaryPopover extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    children: PropTypes.node,
    leadsReport: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      total: PropTypes.number,
      updated: PropTypes.number,
      rejected: PropTypes.number,
      created: PropTypes.number,
      date: PropTypes.string,
      userName: PropTypes.string,
      status: PropTypes.string,
      leadsFile: PropTypes.object,
      memberships: PropTypes.array,
      allowed_for_all: PropTypes.bool,
    }).isRequired,
    deleteLeadsExportFile: PropTypes.func.isRequired,
    refresh: PropTypes.func,
    isAdmin: PropTypes.bool.isRequired,
    currentUser: PropTypes.object.isRequired,
  };

  static defaultProps = {
    children: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      showDeleteModal: false,
    };

    this.LANGUAGE = props.languageState.payload.LEADS_PORT;
  }

  toggleDeleteModal = () => {
    this.setState(({showDeleteModal}) => {
      return {showDeleteModal: !showDeleteModal};
    });
  };

  deleteFile = () => {
    this.props.deleteLeadsExportFile(this.props.leadsReport.id).then(this.props.refresh);
    this.toggleDeleteModal();
  };

  downloadFile = () => {
    downloadFileWithAuth(this.props.leadsReport.leadsFile.url);
  };

  isImport = () => {
    return this.props.leadsReport.type.endsWith('Import');
  };

  isFileExport = () => {
    return this.props.leadsReport.type === 'FileLeadExport';
  };

  isApiExport = () => {
    return this.props.leadsReport.type === 'ApiLeadExport';
  };

  renderImportBlock = () => {
    if (!this.isImport()) {
      return null;
    }

    const {created, updated, rejected} = this.props.leadsReport;

    return (
      <div className="leads-summary-popover__summary-details">
        <div className="leads-summary-popover__summary-detail">
          <div className="leads-summary-popover__summary-op">{this.LANGUAGE.SUMMARY_POPOVER_LEADS_CREATION}</div>
          <div className="leads-summary-popover__summary-num">{created}</div>
        </div>
        <div className="leads-summary-popover__summary-detail">
          <div className="leads-summary-popover__summary-op">{this.LANGUAGE.SUMMARY_POPOVER_LEADS_UPDATING}</div>
          <div className="leads-summary-popover__summary-num">{updated}</div>
        </div>
        <div className="leads-summary-popover__summary-detail">
          <div className="leads-summary-popover__summary-op">{this.LANGUAGE.SUMMARY_POPOVER_LEADS_REJECTED}</div>
          <div className="leads-summary-popover__summary-num">{rejected}</div>
        </div>
      </div>
    );
  };

  renderExportStatusBlock = () => {
    if (!this.isFileExport() && !this.isApiExport()) {
      return null;
    }

    const {status} = this.props.leadsReport;

    return (
      <div className="leads-summary-popover__summary-stats">
        <div className="leads-summary-popover__export-status">
          <div className="leads-summary-popover__export-status-caption">
            {this.LANGUAGE.SUMMARY_POPOVER_EXPORT_STATUS}:
          </div>
          <div>{this.LANGUAGE[`SUMMARY_POPOVER_EXPORT_STATUS_${status.toUpperCase()}`]}</div>
        </div>
      </div>
    );
  };

  renderAllowedUserBlock = () => {
    const {memberships = [], allowed_for_all} = this.props.leadsReport;

    return (
      <div>
        <h4 className="input__label leads-summary-popover__title">{this.LANGUAGE.SETTINGS_POPOVER_USER_ALLOWED}</h4>
        {allowed_for_all && <div className="leads-summary-popover__text">{this.LANGUAGE.ALLOW_ALL_LABEL}</div>}
        {!allowed_for_all && !memberships.length && (
          <div className="leads-api-export-settings-popover__text">{this.LANGUAGE.NO_ALLOWED_USERS}</div>
        )}
        {!allowed_for_all &&
          memberships.map((item) => {
            return (
              <div key={item.id} className="leads-summary-popover__text">
                {item.client_user.full_name}
              </div>
            );
          })}
      </div>
    );
  };

  renderFileExportBlock = () => {
    const {leadsReport, currentUser} = this.props;

    if (!this.isFileExport() || !canManipulateTransfer(leadsReport, currentUser)) {
      return null;
    }

    const {status, leadsFile} = leadsReport;

    const fileExists = status === 'success' && leadsFile.url;
    const fileDeleted = status === 'success' && !leadsFile.url;

    return (
      <div className="leads-summary-popover__summary-stats">
        <div className="leads-summary-popover__export-action">
          <div>
            <b>{this.LANGUAGE.SUMMARY_POPOVER_EXPORT_DOWNLOAD}</b>
          </div>
          <div className="button__container">
            <button className="button button--bg-2 button--circle" disabled={!fileExists} onClick={this.downloadFile}>
              <Icon name="download" className="button__icon" />
            </button>
          </div>
        </div>
        <div className="leads-summary-popover__export-action">
          <div>{this.LANGUAGE.SUMMARY_POPOVER_EXPORT_DELETE}</div>
          <div className="button__container">
            <button
              className="button button--bg-2 button--circle"
              disabled={!fileExists}
              onClick={this.toggleDeleteModal}
            >
              <Icon name="trash" className="button__icon" width={17} height={19} />
            </button>
          </div>
        </div>
        {fileDeleted && (
          <div className="leads-summary-popover__export-file-deleted">{this.LANGUAGE.SUMMARY_POPOVER_FILE_DELETED}</div>
        )}
      </div>
    );
  };

  render() {
    const {children} = this.props;
    const {total, date, userName} = this.props.leadsReport;
    const {showDeleteModal} = this.state;

    if (showDeleteModal) {
      return (
        <div>
          <ConfirmationModal
            show={true}
            buttonConfirmClass="button--bg-2"
            className="theme-color-2"
            title={this.LANGUAGE.DELETE_MODAL.TITLE}
            message={this.LANGUAGE.DELETE_MODAL.MESSAGE}
            cancelText={this.LANGUAGE.DELETE_MODAL.CANCEL}
            confirmText={this.LANGUAGE.DELETE_MODAL.CONFIRM}
            onCancel={this.toggleDeleteModal}
            onClose={this.toggleDeleteModal}
            onConfirm={this.deleteFile}
          />
          {children}
        </div>
      );
    }

    return (
      <Popover
        position="bottom"
        className="pull-left"
        overlayInnerStyle={{
          width: 344,
        }}
        overlay={
          <div className="popover-list popover-list--color-2">
            <h3 className="popover-list__title">{this.LANGUAGE.SUMMARY_POPOVER_TITLE}</h3>
            <div className="leads-summary-popover">
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMax: 280,
                }}
              >
                <div className="popover-list__list leads-summary-popover__inner">
                  <div className="leads-summary-popover__summary-subtitle">
                    {this.LANGUAGE.SUMMARY_POPOVER_CREATED}: {date}
                    <br />
                    {userName && `${this.LANGUAGE.SUMMARY_POPOVER_BY} ${userName}`}
                  </div>
                  <div className="leads-summary-popover__summary-stats">
                    <div className="leads-summary-popover__summary-descr">
                      {this.isImport()
                        ? this.LANGUAGE.SUMMARY_POPOVER_TOTAL_IMPORTED
                        : this.LANGUAGE.SUMMARY_POPOVER_TOTAL_EXPORTED}
                    </div>
                    <div className="leads-summary-popover__summary-num">{total}</div>
                    {this.renderImportBlock()}
                    {this.renderExportStatusBlock()}
                    {this.renderAllowedUserBlock()}
                    {this.renderFileExportBlock()}
                  </div>
                </div>
              </CustomScrollbars>
            </div>
          </div>
        }
      >
        {children}
      </Popover>
    );
  }
}

export default connect(
  (state) => ({
    languageState: state.languageState,
    isAdmin: selectIsAdmin(state),
    currentUser: selectCurrentUser(state),
  }),
  {deleteLeadsExportFile},
)(LeadsSummaryPopover);
