import {PrizeCustomizationModalForm} from 'client/components/prizes/modals/prize-customization-modal/types';

export default (values: PrizeCustomizationModalForm) => {
  return {
    prize_map: values.prizeMaps.map((prizeMap) => ({
      id: prizeMap.id,
      initial_stock: prizeMap.initial_stock,
      active: prizeMap.active,
      from: values.from,
      to: values.to,
    })),
  };
};
