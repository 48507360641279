import React, {Component} from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';

import TextCell from 'client/components/participants/components/tables/cells/text-cell';

class ResultGraphsCardTableHeader extends Component {
  static propTypes = {
    language: PropTypes.object.isRequired,
  };

  render() {
    const {language} = this.props;
    const data = [];
    return (
      <div className="fixed-table">
        <Table rowsCount={data.length} headerHeight={40} rowHeight={52} width={540} maxHeight={115} data={data}>
          <Column
            header={<Cell>{language.TABLE_HEADERS.NAME}</Cell>}
            cell={<TextCell data={data} field="name" />}
            width={100}
          />
          <Column cell={<TextCell data={data} field="" />} width={50} />
          <Column
            header={<Cell>{language.TABLE_HEADERS.SOURCE}</Cell>}
            cell={<TextCell data={data} field="column_adapter_name" />}
            width={150}
          />
          <Column cell={<div />} width={20} />
        </Table>
      </div>
    );
  }
}

export default ResultGraphsCardTableHeader;
