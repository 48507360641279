import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {selectIsAdmin, selectIsUserLoaded} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import DevicesList from 'client/components/devices/lists/admin-devices-list';

class DevicesPage extends Component {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.setMetaAction({
      title: this.props.languageState.payload.DEVICES.DEVICES_TITLE,
    });
  }

  render() {
    const {isAdmin, isUserLoaded} = this.props;

    if (!isUserLoaded) {
      return null;
    }

    if (isUserLoaded && !isAdmin) {
      return <Redirect to={CLIENT_PAGES.HOME} />;
    }

    return <DevicesList />;
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  isAdmin: selectIsAdmin(state),
  isUserLoaded: selectIsUserLoaded(state),
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicesPage);
