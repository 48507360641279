import {Store} from 'client/models/stores/types';

import {Period, StoreInfoFormValues, Weekday} from './types';

export default (values: StoreInfoFormValues) => {
  const openingHours: Partial<Store['opening_hours']> = {};
  const specialHours: Partial<Store['special_hours']> = {};

  Object.entries(values?.opening_hours || []).forEach(([day, periods]) => {
    const hours: string[] = [];
    (periods as Period[]).forEach((period) => {
      const [start, end] = period.value.split(' ');
      hours.push(start, end);
    });
    openingHours[day as Weekday] = hours;
  });

  values?.special_hours?.forEach((period) => {
    specialHours[period.value] = [];
  });

  return {
    ...values,
    opening_hours: openingHours,
    special_hours: specialHours,
  };
};
