import React from 'react';

import PropTypes from 'prop-types';

import {getPercent} from 'client/services/helpers.js';

import {mapData} from './helpers';

import './line-chart.scss';

const LineChart = ({data, total, isSort}) => {
  // sort for css zIndex layouts
  if (isSort) {
    data.sort((a, b) => b.value - a.value);
  }

  const {values, colors} = mapData(data);

  return (
    <div className="line-chart">
      {colors.map((color, index) => {
        return (
          <p
            key={index}
            className="line-chart__line"
            style={{backgroundColor: color, width: getPercent(total, values[index])}}
          />
        );
      })}
      <p className="line-chart__line line-chart__line--default" />
    </div>
  );
};

LineChart.defaultProps = {
  data: [],
  isSort: false,
  total: 0,
};

LineChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  total: PropTypes.number,
  isSort: PropTypes.bool,
};

export default LineChart;
