import React from 'react';

import {Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

export default function LinkCell({data, rowIndex, field, link, path}) {
  const value = data[rowIndex][field];
  const id = data[rowIndex].id;
  const linkTo = link.length ? link : `${path}/${id}`;

  return (
    <Cell>
      <Link to={linkTo} className="link main-text">
        {value}
      </Link>
    </Cell>
  );
}

LinkCell.propTypes = {
  data: PropTypes.array.isRequired,
  rowIndex: PropTypes.number,
  field: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};
