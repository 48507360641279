import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';

import PrizePopover from './prize-popover';

import cssModule from './prize-block.module.scss';

const b = bem('prize-block', {cssModule});

const PrizeBlock: React.FC = () => {
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.BLOCKS.PRIZE_BLOCK');

  return (
    <PrizePopover>
      <div className={b('placeholder')}>
        <Icon name="prize" />
        <span>{lang.PRIZE_COMPONENT}</span>
      </div>
    </PrizePopover>
  );
};

export default PrizeBlock;
