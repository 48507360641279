import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

import bem from 'client/services/bem';

import {TaskWrapperTabsType} from './type';

import './task-header-menu.scss';

const b = bem('task-header-menu');

const TaskHeaderMenu = ({tabs, className}) => {
  return (
    <div className={classNames(b(), className)}>
      {tabs.map((tab) => (
        <NavLink
          exact
          to={tab.path}
          key={tab.path}
          activeClassName={b('link', ['active'])}
          className={b('link', {disabled: !!tab.disabled})}
        >
          {tab.label}
        </NavLink>
      ))}
    </div>
  );
};

TaskHeaderMenu.propTypes = {
  tabs: TaskWrapperTabsType.isRequired,
  className: PropTypes.string,
};

TaskHeaderMenu.defaultProps = {
  className: '',
};

export default TaskHeaderMenu;
