import React from 'react';

import {Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';

import LeadsCommentsPopover from 'client/components/leads/components/popovers/leads-comments-popover';
import LeadsErrorPopover from 'client/components/leads/components/popovers/leads-error-popover';
import LeadsSettingsPopover from 'client/components/leads/components/popovers/leads-settings-popover';
import LeadsSummaryPopover from 'client/components/leads/components/popovers/leads-summary-popover';

import './import-export-tools-cell.scss';

function ImportExportToolsCell(props) {
  const {data, rowIndex, deleteReport, getReports, ...rest} = props;

  return (
    <Cell {...rest}>
      <div className="flex-container flex-justify-between flex-align-center">
        <div className="import-export-tools-cell__icon">
          <LeadsSummaryPopover leadsReport={data[rowIndex]} refresh={getReports}>
            <Icon name="summary" />
          </LeadsSummaryPopover>
        </div>
        <div className="import-export-tools-cell__icon">
          {data[rowIndex].hasErrors ? (
            <LeadsErrorPopover date={data[rowIndex].date} url={data[rowIndex].url}>
              <Icon name="status-error" />
            </LeadsErrorPopover>
          ) : (
            <LeadsSettingsPopover
              userName={data[rowIndex].userName}
              isImport={data[rowIndex].type.endsWith('Import')}
              memberships={data[rowIndex].memberships}
              allowAll={data[rowIndex].allowed_for_all}
              dateFrom={data[rowIndex].dateFrom}
              dateTo={data[rowIndex].dateTo}
              leadTransferId={String(data[rowIndex].leadTransferId)}
              id={data[rowIndex].id}
              deleteReport={deleteReport}
            >
              <Icon name="settings" className={data[rowIndex].type.startsWith('Api') ? '' : 'hidden'} />
            </LeadsSettingsPopover>
          )}
        </div>
        <div className="import-export-tools-cell__icon">
          <LeadsCommentsPopover comment={data[rowIndex].comment}>
            <Icon name="message" className={data[rowIndex].comment ? '' : 'hidden'} />
          </LeadsCommentsPopover>
        </div>
      </div>
    </Cell>
  );
}

ImportExportToolsCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  deleteReport: PropTypes.func.isRequired,
  getReports: PropTypes.func.isRequired,
};

ImportExportToolsCell.defaultProps = {
  data: [],
};

export default ImportExportToolsCell;
