import React from 'react';

import {useSlate} from 'slate-react';

import bem from 'client/services/bem';

import AlignButtons from 'client/common/text-editor/buttons/align-buttons';
import ColorButton from 'client/common/text-editor/buttons/color-button';
import HistoryButtons from 'client/common/text-editor/buttons/history-buttons';
import StyleButtons from 'client/common/text-editor/buttons/style-buttons';
import FontPropertiesSelect from 'client/common/text-editor/selects/font-properties-select';

import cssModule from './static-toolbar.module.scss';

const b = bem('static-toolbar', {cssModule});

const FONT_SIZE_OPTIONS = Array.from(new Array(21), (_, i) => (i + 5) * 2); // [10, 12, 14 ... 50]

type StaticToolbarProps = {
  colorsAccessKey: string;
  fontFamilyOptions?: string[];
};
const StaticToolbar = (props: StaticToolbarProps) => {
  const {colorsAccessKey, fontFamilyOptions} = props;
  const editor = useSlate();

  return (
    <div className={b()}>
      <div className={b('selectors')}>
        <FontPropertiesSelect
          editor={editor}
          fontFamilyOptions={fontFamilyOptions}
          fontSizeOptions={FONT_SIZE_OPTIONS}
        />
      </div>

      <div className={b('buttons')}>
        <HistoryButtons editor={editor} />
        <div className={b('format-buttons')}>
          <StyleButtons editor={editor} className={b('style-buttons')} />
          <ColorButton editor={editor} className={b('color-button')} colorsAccessKey={colorsAccessKey} />
        </div>
        <AlignButtons editor={editor} />
      </div>
    </div>
  );
};

export default StaticToolbar;
