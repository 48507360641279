import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons/app-button';

import cssModule from './modal-close-button.module.scss';

const b = bem('modal-close-button', {cssModule});

type ModalCloseButtonProps = {
  onClose: () => void;
  header?: React.ReactNode;
  className?: string;
  color?: string;
};

const ModalCloseButton: React.FC<ModalCloseButtonProps> = (props) => {
  const {onClose, header, className, color = 'text-additional'} = props;

  return (
    <AppButton
      className={cn(b({'centered-with-header': header}), className)}
      onClick={onClose}
      data-testid="modal-close"
      iconName="close"
      transparent={true}
      color={color}
    />
  );
};

export default ModalCloseButton;
