import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

export const FilterButton = ({fieldName, value, label = '', onChange, filters}) => {
  const clickHandler = () => {
    if (onChange) {
      onChange(fieldName, value);
    }
  };
  return (
    <button
      key={label}
      type="button"
      className={cn('tokens-filter-modal__filter-button', {
        'tokens-filter-modal__filter-button--active': (filters[fieldName] || null) === (value || null),
      })}
      onClick={clickHandler}
    >
      {label}
    </button>
  );
};

FilterButton.defaultValue = {
  value: null,
};

FilterButton.propTypes = {
  value: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  label: TranslationJsx.isRequired,
  onChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};
