import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {patch} from 'client/services/fetch';
import {required} from 'client/services/validator';

import {API_METHODS} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

class VisEditGroupModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    languageState: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    name: PropTypes.string,
    id: PropTypes.number,
  };

  static defaultProps = {
    show: false,
    name: '',
  };

  static formName = 'VisEditGroupModalForm';

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.POST_MANAGEMENT_TASK.NEW_SUBGROUP_MODAL;

    this.rules = {
      name: required(this.LANGUAGE.ERRORS.NAME_IS_REQUIRED),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.props.initialize({name: this.props.name});
    }
  }

  save = ({name}) => {
    return patch(`${API_METHODS.VISUAL_GROUPS}/${this.props.id}`, {
      visual_group: {
        name,
      },
    }).then(({errors, visual_group}) => {
      if (errors) {
        throw new SubmissionError({name: this.LANGUAGE.ERRORS.NAME_IS_TAKEN});
      }

      return this.props.onConfirm(visual_group);
    });
  };

  render() {
    const {show, onClose, handleSubmit} = this.props;

    return (
      <Modal
        show={show}
        onClose={onClose}
        title={this.LANGUAGE.EDIT_TITLE}
        dialogClassName="modal-window--width-1 theme-color-13"
      >
        <form onSubmit={handleSubmit(this.save)}>
          <Field
            cssModifier="input--no-min-height"
            name="name"
            type="text"
            label={this.LANGUAGE.NAME_LABEL}
            component={fieldTemplate}
            validate={this.rules.name}
          />
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onClose}>
              {this.LANGUAGE.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-8 modal-window__footer-btn" type="submit">
              {this.LANGUAGE.CONFIRM_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const VisEditGroupModalForm = reduxForm({
  form: VisEditGroupModal.formName,
})(VisEditGroupModal);

export default connect(({languageState}) => ({
  languageState,
}))(VisEditGroupModalForm);
