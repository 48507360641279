import {useMemo} from 'react';

import {Pagination as PaginationProps} from 'client/models/pagination/types';

export const DOTS = '...';

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({length}, (_, idx) => idx + start);
};

export const usePagination = (props: PaginationProps) => {
  const {totalItems, totalPages, maxButtons = 1, siblingCount = 1, currentPage} = props;

  const paginationRange = useMemo(() => {
    const totalPageCount = totalPages ? totalPages : Math.ceil(totalItems / maxButtons);

    // totalPageNumbers = siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    if (totalPageCount === 1) {
      return null;
    }

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(+currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(+currentPage + siblingCount, totalPageCount);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    // only rights dots to be shown
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    // only left dots to be shown
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    // Both left and right dots to be shown
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    return null;
  }, [totalPages, totalItems, maxButtons, siblingCount, currentPage]);

  return paginationRange;
};
