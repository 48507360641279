import {COMMON_INPUT} from 'client/components/prizes/constants';

export default (values: any) => {
  const prizeMapNames: Record<string, string> = {};
  Object.entries(values?.external_prize_name_translations).forEach(([langKey, value]) => {
    prizeMapNames[`external_prize_name_${langKey}`] = value as string;
  });

  return {
    ...prizeMapNames,
    language_unique_mode: values.activeLanguage === COMMON_INPUT,
    ticket_print: values.ticket_print,
    email_send: values.email_send,
    sms_send: values.sms_send,
    distribution: values.distribution,
    level: values.level,
  };
};
