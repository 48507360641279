import React from 'react';

import PropTypes from 'prop-types';

import {diagramKinds} from 'client/ducks/results-graphs/constants';

import Field from 'client/components/common/fields/field';

const DiagramKindField = (props) => {
  const {lang} = props;

  return (
    <Field
      name="kind"
      label={lang.TYPE}
      type="select"
      searchable={false}
      options={[
        {value: diagramKinds.PIE_CHART, label: lang.PIE_CHART},
        {value: diagramKinds.LINE, label: lang.LINE},
        {value: diagramKinds.HORIZONTAL_BARS, label: lang.HORIZONTAL_BARS},
        {value: diagramKinds.VERTICAL_BARS, label: lang.VERTICAL_BARS},
      ]}
      simpleValue
      withWrap
    />
  );
};

DiagramKindField.propTypes = {
  lang: PropTypes.object.isRequired,
};

export default DiagramKindField;
