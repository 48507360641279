export const DEVICE_SEGMENTATION = {
  GLOBAL: 'global',
  PER_DEVICE: 'device',
  NONE: 'none',
} as const;

export const ONLINE_SEGMENTATION = {
  GLOBAL: 'global',
  PER_CHANNEL: 'source',
  PER_CAMPAIGN: 'campaign',
  NONE: 'none',
} as const;
