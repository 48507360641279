import types from './types';

const initialState = {
  clients: [],
  regions: [],
  submitted: false,
};

export default function (_state = initialState, action) {
  const state = Object.assign({}, _state);

  switch (action.type) {
    case types.GET_REGIONS_SUCCESS:
      state.regions = action.payload.regions;
      return state;

    case types.GET_REGIONS_ERROR:
      return state;

    case types.GET_CLIENTS_SUCCESS:
      state.clients = action.payload.clients;
      return state;

    case types.GET_CLIENTS_ERROR:
      return state;

    case types.TOGGLE_SUBMITTED:
      state.submitted = !state.submitted;
      return state;

    default:
      return state;
  }
}
