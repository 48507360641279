import React, {Component} from 'react';

import find from 'lodash/find';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {set} from 'client/common/meta/meta.actions';

import ClientAutotask from 'client/components/client-autotask';
import menuConfig from 'client/components/client-autotask/client-autotask-menu/menuConfig';

class ClientAutotaskPage extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    setMetaAction: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.CLIENT_AUTOTASK_MENU.MENU_ITEMS;
  }

  componentDidMount() {
    this.setTitle();
  }

  componentDidUpdate(prevProps) {
    if (this.getTab() !== this.getTab(prevProps)) {
      this.setTitle();
    }
  }

  getTab = (props = null) => {
    const propsObj = props || this.props;
    const tab = get(propsObj, 'match.params.tab');
    return `/${tab}`;
  };

  setTitle = () => {
    const tab = this.getTab();
    let title = '';

    menuConfig.forEach((item) => {
      if (title) {
        return;
      }

      if (item.tab === tab) {
        title = this.LANGUAGE[item.langKey];
        return;
      }

      const subItem = find(item.subItems, {tab});

      if (subItem) {
        const main = this.LANGUAGE[item.langKey];
        const secondary = this.LANGUAGE[subItem.langKey];
        title = `${main} - ${secondary}`;
      }
    });

    this.props.setMetaAction({title});
  };

  render() {
    const {autotaskId, operationId} = this.props.match.params;

    return <ClientAutotask autotaskId={+autotaskId} operationId={+operationId} activeTab={this.getTab()} />;
  }
}

export default withRouter(
  connect(
    (state) => ({
      languageState: state.languageState,
    }),
    {setMetaAction: set},
  )(ClientAutotaskPage),
);
