import React from 'react';

import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {interpolate} from 'client/ducks/language/helpers';
import {selectClient} from 'client/ducks/leads-list/selectors';
import {selectIsAdmin, selectViewMode} from 'client/ducks/user/selectors';

import AppButton from 'client/common/buttons';
import {RadioGroupField, TextField, TextareaField} from 'client/common/fields';
import {useToast} from 'client/common/hooks/useToast';
import Modal from 'client/common/modals/modal';

import {DATA_COMPONENTS} from 'client/components/client-autotask/tabs/data-tab/data-common/constants';
import {DataLeadsFilters} from 'client/components/client-autotask/tabs/data-tab/leads-filters-modal/types';
import {DataParticipationsFilters} from 'client/components/client-autotask/tabs/data-tab/participations-filters-modal/types';
import {DataWinnersFilters} from 'client/components/client-autotask/tabs/data-tab/winners-filters-modal/types';
import {ExportReport} from 'client/models/data-exports/types';
import {ApiAction} from 'client/types';

import {FILE_OPTIONS, EXPORT_FIELD_MAP, MAPPING_METHOD} from './constants';
import {validate} from './helpers';

import cssModule from './generate-file-modal.module.scss';

const b = bem('generate-file-modal', {cssModule});

export type GenerationExportFilters = Partial<DataParticipationsFilters & DataWinnersFilters & DataLeadsFilters> & {
  search?: string;
} & Record<string, any>;

const MAX_RECORDS_FOR_XLSX = 900000;

type GenerateFileModalProps = {
  onClose: () => void;
  onFetch?: () => void;
  component: keyof typeof DATA_COMPONENTS;
  filters: GenerationExportFilters;
  actionGenerate: ApiAction;
  actionPerform: ApiAction;
  options?: Record<string, any>;
  total: number;
};

export type GenerateFileFormValues = {
  name: string;
  comment?: string;
  format: typeof FILE_OPTIONS.CSV | typeof FILE_OPTIONS.XLSX;
};

const formName = 'GenerateFileForm';

const GenerateFileModal: ReduxFormFC<GenerateFileModalProps, GenerateFileFormValues> = ({
  onClose,
  onFetch,
  handleSubmit,
  component,
  filters,
  actionGenerate,
  actionPerform,
  options,
  total,
}) => {
  const lang = useLanguage('DATA_TAB.GENERATE_FILE_MODAL');
  const langCommonExportFile = useLanguage('GENERATING_EXPORT_FILE');

  const autotask = useSelector(selectAutotask);
  const client = useSelector(selectClient);
  const {appendToastNotification} = useToast();
  const [loadingReport, toggleLoadingReport] = useToggle(false);
  const admin = useSelector(selectIsAdmin);
  const viewMode = useSelector(selectViewMode);

  const {invalid} = useReduxForm<GenerateFileFormValues>(formName, {
    initialValues: {format: FILE_OPTIONS.CSV},
    validate: (values) => validate(values, lang),
  });

  const {fetch: fetchGenerate, loading: loadingGenerate} = useReduxFetch<Record<string, ExportReport>>({
    action: actionGenerate,
    fetchOnMount: false,
  });

  const {fetch: fetchPerform, loading: loadingPerform} = useReduxFetch<Record<string, ExportReport>>({
    action: actionPerform,
    fetchOnMount: false,
  });

  const onSubmit = async (values: GenerateFileFormValues) => {
    const exportField = EXPORT_FIELD_MAP[component];
    const fileName = `${values.name}.${values.format}`;

    const mappingFilters = MAPPING_METHOD[component];
    const parametersForExport = mappingFilters(filters || {}, options);

    if (component === DATA_COMPONENTS.LEADS) {
      parametersForExport.query_params.participations_automation_task_id_eq = autotask.id;
    }

    const body: Record<string, any> = {
      ...values,
      client_id: client.id,
      automation_task_id: autotask.id,
      parameters_for_export: parametersForExport,
      allowed_for_all: false,
    };

    if (!admin) {
      body.client_side = true;
    }

    try {
      toggleLoadingReport();

      const exportResponse = await fetchGenerate(
        {
          [exportField]: body,
        },
        {...(viewMode.on && {fake_client_user_id: viewMode.id})},
      );

      await fetchPerform(exportResponse.payload[exportField].id, {
        initial_total: total,
        ...(viewMode.on && {fake_client_user_id: viewMode.id}),
      });

      await onFetch?.();
      toggleLoadingReport();

      const title = interpolate(langCommonExportFile.NOTIFICATION_TITLE?.toString(), {
        fileName: fileName,
      });
      appendToastNotification({
        type: 'success',
        title,
        description: langCommonExportFile.NOTIFICATION_IN_PROCESS,
      });
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const formatOptions = [{value: FILE_OPTIONS.CSV, label: `.${FILE_OPTIONS.CSV}`}];
  if (total <= MAX_RECORDS_FOR_XLSX) {
    formatOptions.push({value: FILE_OPTIONS.XLSX, label: `.${FILE_OPTIONS.XLSX}`});
  }

  return (
    <Modal onClose={onClose} title={lang.GENERATE_FILE}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={b('form-items')}>
          <TextField label={lang.FILE_NAME} name="name" required={true} />
          <TextareaField label={lang.COMMENT} name="comment" />
          <RadioGroupField name="format" direction="horizontal" className={b('radio-group')} options={formatOptions} />
        </div>
        <AppButton
          label={lang.GENERATE}
          className={b('button')}
          disabled={invalid}
          submit
          loading={loadingGenerate || loadingPerform || loadingReport}
        />
      </form>
    </Modal>
  );
};

export default reduxForm<GenerateFileModalProps, GenerateFileFormValues>({form: formName})(GenerateFileModal);
