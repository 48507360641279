import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import {connect} from 'react-redux';

import Modal from 'client/common/modals/modal';

import CustomLink from 'client/components/common/custom-link';
import CustomScrollbars from 'client/components/common/custom-scrollbars';

import './participations-modal.scss';

function ParticipationsModal(props) {
  const LANGUAGE = props.languageState.payload.LEADS_LIST;
  const {leadsId, participations, isAdmin} = props;

  const participationModal = (
    <Modal
      show={props.show}
      onClose={props.onClose}
      dialogClassName="modal-window--width-1 modal-window--theme-2 participations-modal"
      title={`${LANGUAGE.PARTICIPATIONS_MODAL_TITLE} ${leadsId}`}
    >
      <div className="content-group theme-color-2">
        <CustomScrollbars
          scrollbarProps={{
            hideTracksWhenNotNeeded: true,
            autoHeightMax: 482,
            autoHeightMin: 200,
          }}
        >
          <div className="list list--view-2">
            <ul className="list__body">
              {participations &&
                participations.map((participation, index) => {
                  const interfaceName = get(participation, 'interface.name', 'No interface');
                  const operationName = get(participation, 'operation.code', 'No name');
                  // const operationId = get(participation, 'interface.automation_task.operation.id', '');
                  // const client = get(participation, 'interface.automation_task.operation.client') || {};
                  const link = CustomLink.createOperationLink({
                    clientType: 'Company',
                    clientId: participation.operation.client_id,
                    operationId: participation.operation.id,
                  });

                  return (
                    <li key={index} className="list__item">
                      <div className="row">
                        <div className="col-xs-4">
                          <div className="main-text main-text--upper main-text--bold">
                            {isAdmin && (
                              <CustomLink to={link} className="link participations-modal__link" title={operationName}>
                                {operationName}
                              </CustomLink>
                            )}
                            {!isAdmin && <span>{operationName}</span>}
                          </div>
                        </div>
                        <div className="col-xs-8">
                          <div className="main-text main-text--small main-text--color-1">
                            <Moment format="hh:mm A DD/MM/YYYY">{participation.created_at}</Moment>
                          </div>
                          <div className="main-text main-text--small main-text--color-1">
                            <Moment format="hh:mm A DD/MM/YYYY">{participation.updated_at}</Moment>
                          </div>
                          <div className="main-text main-text--small main-text--color-1 interface_name">
                            {interfaceName}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </CustomScrollbars>
      </div>
      <div className="modal-window__footer modal-window__footer--centered">
        <button className="button button--bg-5 modal-window__footer-btn" onClick={props.onClose}>
          {LANGUAGE.PARTICIPATIONS_MODAL_CLOSE_BUTTON}
        </button>
      </div>
    </Modal>
  );

  return participations && participations.length ? participationModal : null;
}

ParticipationsModal.defaultProps = {
  isAdmin: false,
  show: false,
  participations: [],
};

ParticipationsModal.propTypes = {
  isAdmin: PropTypes.bool,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  languageState: PropTypes.object.isRequired,
  participations: PropTypes.array,
  leadsId: PropTypes.number,
};

export default connect(({languageState}) => {
  return {
    languageState,
  };
})(ParticipationsModal);
