import set from 'lodash/set';
import unset from 'lodash/unset';

import {CLIENT_SIDE_TYPES} from './constants';

export default (formValues, campaign, channel) => {
  const body = {
    ...formValues,
    kind: !channel || CLIENT_SIDE_TYPES.includes(channel.kind) ? formValues.kind : channel.kind,
  };
  set(body, 'interaction_group_id', campaign.id);
  set(body, 'region_id', formValues.region?.value);
  set(body, 'store_id', formValues.store?.value);
  set(body, 'source_id', formValues.permanent_source?.value);
  unset(body, 'store');
  unset(body, 'permanent_source');
  unset(body, 'region');

  return body;
};
