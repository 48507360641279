import React from 'react';

import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';
import useReduxFetch from 'client/services/hooks/use-redux-fetch';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import AppButton from 'client/common/buttons/app-button';
import {PasswordField} from 'client/common/fields';
import {useToast} from 'client/common/hooks/useToast';
import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import {passwordChange} from 'client/components/profile/profile.action';

import {ProfilePasswordProps, ProfilePasswordValues, ErrorResponse} from './types';
import validate from './validate';

import ProfilePanel from '../profile-panel';

import cssModule from './profile-password.module.scss';

export const ProfilePasswordFormName = 'ProfilePasswordForm';

const b = bem('profile-password', {cssModule});

const FAKE_PASSWORD = 'xxxxxxxx';

const ProfilePassword: ReduxFormFC<ProfilePasswordProps, ProfilePasswordValues> = (props) => {
  const {user, editPassword, handleSubmit, anyTouched, submitting} = props;

  const lang = useLanguage('PROFILE.PROFILE_PASSWORD');

  const [opened, toggleOpened] = useToggle(editPassword);

  const {appendToastNotification} = useToast();

  const {reset, valid} = useReduxForm<ProfilePasswordValues>(ProfilePasswordFormName, {
    validate: (values) => validate(values, {lang: lang}),
    initialValues: {
      fakePassword: FAKE_PASSWORD,
    },
  });

  const {loading, fetch} = useReduxFetch({
    action: passwordChange,
    fetchOnMount: false,
  });

  const handleClose = () => {
    reset();
    toggleOpened();
  };

  const handleFormError = (error: ErrorResponse) => {
    if (!error.response) {
      appendToastNotification({
        type: 'error',
        title: lang.PASSWORD_UPDATE_ERROR,
      });
    }

    const {errors} = error.response;

    let title = lang.PASSWORD_UPDATE_ERROR;

    if (errors?.current_password?.[0]) {
      title = lang.CURRENT_PASSWORD_ERROR;
    } else if (errors?.password?.[0] === 'taken_in_past') {
      title = lang.PASSWORD_USED_ERROR;
    }

    appendToastNotification({
      type: 'error',
      title,
    });
  };

  const onSubmit = handleSubmit(async (values) => {
    const data = {
      user: {
        current_password: values.currentPassword,
        password: values.newPassword,
        password_confirmation: values.confirmPassword,
      },
    };

    try {
      const result = await fetch(user.id, data);

      if (result?.error) {
        throw result.payload;
      } else {
        appendToastNotification({
          type: 'success',
          title: lang.PASSWORD_UPDATE_SUCCESS,
        });
      }

      handleClose();
    } catch (e) {
      handleFormError(e as ErrorResponse);
    }
  });

  const renderTooltip = () => {
    return (
      <Popover position="bottom" shiftTop={6} overlay={<div className={b('tooltip')}>{lang.TOOLTIP_TEXT}</div>}>
        <Icon name="information" className={b('tooltip-icon')} />
      </Popover>
    );
  };

  return (
    <ProfilePanel title={lang.TITLE} icon={renderTooltip()}>
      <div className={b('profile-password')}>
        <form onSubmit={onSubmit}>
          {opened ? (
            <>
              <PasswordField
                className={b('field-wrap')}
                label={lang.CURRENT_PASSWORD}
                name="currentPassword"
                required={true}
                withWrap={true}
              />
              <PasswordField
                className={b('field-wrap')}
                label={lang.NEW_PASSWORD}
                name="newPassword"
                required={true}
                withWrap={true}
              />
              <PasswordField
                className={b('field-wrap')}
                label={lang.CONFIRM_PASSWORD}
                name="confirmPassword"
                required={true}
                withWrap={true}
              />
              <div className={b('buttons')}>
                <AppButton
                  className={b('cancel-button')}
                  label={lang.CANCEL}
                  transparent={true}
                  color="primary"
                  size="small"
                  onClick={handleClose}
                />
                <AppButton
                  className={b('save-button')}
                  label={lang.SAVE}
                  disabled={!anyTouched || !valid || submitting}
                  color="primary"
                  size="medium"
                  submit={true}
                  loading={loading}
                />
              </div>
            </>
          ) : (
            <>
              <PasswordField
                className={b('field-wrap')}
                label={lang.PASSWORD}
                name="fakePassword"
                disabled={true}
                withWrap={true}
              />

              <AppButton
                className={b('open-button')}
                label={lang.TITLE}
                onClick={toggleOpened}
                color="primary"
                size="large"
              />
            </>
          )}
        </form>
      </div>
    </ProfilePanel>
  );
};

export default reduxForm<ProfilePasswordProps, ProfilePasswordValues>({
  form: ProfilePasswordFormName,
})(ProfilePassword);
