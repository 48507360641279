import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getEmailSenders(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_SENDERS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_EMAIL_SENDERS_REQUEST, types.GET_EMAIL_SENDERS_SUCCESS, types.GET_EMAIL_SENDERS_ERROR],
      queryParams,
    });
}

export function addEmailSender(body) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_SENDERS}`,
      method: 'POST',
      types: [types.ADD_EMAIL_SENDER_REQUEST, types.ADD_EMAIL_SENDER_SUCCESS, types.ADD_EMAIL_SENDER_ERROR],
      body,
    });
  };
}

export function updateEmailSender(id, body) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_SENDERS}/${id}`,
      method: 'PUT',
      types: [types.UPDATE_EMAIL_SENDER_REQUEST, types.UPDATE_EMAIL_SENDER_SUCCESS, types.UPDATE_EMAIL_SENDER_ERROR],
      body,
    });
  };
}

export function removeEmailSender(id) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_SENDERS}/${id}`,
      method: 'DELETE',
      types: [types.REMOVE_EMAIL_SENDER_REQUEST, types.REMOVE_EMAIL_SENDER_SUCCESS, types.REMOVE_EMAIL_SENDER_ERROR],
    });
  };
}
