import React from 'react';

import PropTypes from 'prop-types';
import {components} from 'react-select';

const GroupedMenu = (props) => {
  const {selectProps, children} = props;

  return (
    <components.Menu {...props}>
      {selectProps.menuHeaderElement}
      {children}
      {selectProps.menuFooterElement}
    </components.Menu>
  );
};

GroupedMenu.propTypes = {
  selectProps: PropTypes.shape({
    menuHeaderElement: PropTypes.node,
    menuFooterElement: PropTypes.node,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default GroupedMenu;
