import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {CLIENT_PAGES} from 'client/common/config';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import './plan-list.scss';

class PlanList extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    plans: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.AUTOTASK_PLAN_LIST;
  }

  renderPlan = (plan) => {
    const {id, name, places_count = 0} = plan;

    return (
      <div className="content-panel__panel plan-list__item" key={plan.id}>
        <CustomScrollbars
          scrollbarProps={{
            autoHeightMax: 100,
          }}
        >
          <div className="content-panel__panel-inner">
            <div className="content-panel__row">
              <div className="plan-list__col">
                <Link
                  to={`${CLIENT_PAGES.PLANS}/${id}${CLIENT_PAGES.DEVICES}`}
                  className="link display-block ellipsis-text"
                >
                  {name}
                </Link>
              </div>
              <div className="plan-list__col">{places_count}</div>
            </div>
          </div>
        </CustomScrollbars>
      </div>
    );
  };

  renderPlans = (plans) => plans.map((plan) => this.renderPlan(plan));

  render() {
    const {plans} = this.props;

    return (
      <div className="content-panel plan-list">
        <div className="content-panel__header plan-list__header">
          <div className="plan-list__col">{this.LANGUAGE.NAME_COLUMN}</div>
          <div className="plan-list__col">{this.LANGUAGE.NO_OF_PLACES_COLUMN}</div>
        </div>
        {this.renderPlans(plans)}
      </div>
    );
  }
}

export default connect(({languageState}) => ({
  languageState,
}))(PlanList);
