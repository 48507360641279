import React, {useMemo, useState} from 'react';

import {generatePath} from 'react-router';
import {useHistory} from 'react-router-dom';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxFetch from 'client/services/hooks/use-redux-fetch';

import {getCatalogs} from 'client/ducks/catalogs/actions';
import {selectCatalogs} from 'client/ducks/catalogs/selectors';
import {getEmailTemplateKinds} from 'client/ducks/email-templates/actions';
import {selectEmailTemplateKinds} from 'client/ducks/email-templates/selectors';
import {getTemplates} from 'client/ducks/templates/actions';
import {selectTemplates} from 'client/ducks/templates/selectors';

import AppButton from 'client/common/buttons';
import {ADMIN_PAGES} from 'client/common/config';
import Modal from 'client/common/modals/modal';
import SelectDropdown from 'client/common/selects/select-dropdown';

import {EmailTemplateKind} from 'client/models/email-templates/types';
import {Catalog} from 'client/models/templates/types';
import {Template} from 'client/models/templates/types';

import cssModule from './select-email-template-modal.module.scss';

type SelectEmailTemplateModalProps = {
  onClose: () => void;
};

const b = bem('select-email-template-modal', {cssModule});

const SelectEmailTemplateModal: React.FC<SelectEmailTemplateModalProps> = ({onClose}) => {
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.MODALS.SELECT_EMAIL_TEMPLATE');

  const history = useHistory();

  const [selectedCatalog, setSelectedCatalog] = useState<number | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null);
  const [selectedTemplateKind, setSelectedTemplateKind] = useState<number | null>(null);

  const {data: catalogs, loading: loadingCatalogs} = useReduxFetch<Catalog[]>({
    action: getCatalogs,
    actionArgs: {
      q: {parent_id_null: true},
    },
    selector: selectCatalogs,
  });

  const {data: templates, loading: loadingTemplates} = useReduxFetch<Template[]>({
    action: getTemplates,
    actionArgs: {
      q: {
        catalog_id_eq: selectedCatalog,
      },
    },
    selector: selectTemplates,
    skip: !selectedCatalog,
  });

  const {data: templateKinds, loading: loadingTemplateKinds} = useReduxFetch<EmailTemplateKind[]>({
    action: getEmailTemplateKinds,
    actionArgs: {
      emailTemplateId: selectedTemplate,
    },
    selector: selectEmailTemplateKinds,
    skip: !selectedTemplate,
  });

  const catalogOptions = useMemo(
    () =>
      catalogs?.map((catalog) => ({
        value: catalog.id,
        label: catalog.title,
      })) || [],
    [catalogs],
  );

  const templateOptions = useMemo(
    () =>
      templates?.map((template) => ({
        value: template.id,
        label: template.title,
      })) || [],
    [templates],
  );

  const emailTemplateKindOptions = useMemo(
    () =>
      templateKinds?.map((kind) => ({
        value: kind.id,
        label: kind.type_name,
      })) || [],
    [templateKinds],
  );

  const onChangeCatalog = (value: number) => {
    setSelectedCatalog(value);
    setSelectedTemplate(null);
    setSelectedTemplateKind(null);
  };

  const onChangeTemplate = (value: number) => {
    setSelectedTemplate(value);
    setSelectedTemplateKind(null);
  };

  const onGoToEmailTemplate = () => {
    if (selectedTemplateKind && selectedTemplate) {
      const urlPath = `${generatePath(ADMIN_PAGES.EMAIL_TEMPLATE_EDITOR, {
        templateId: selectedTemplate,
      })}?kindId=${selectedTemplateKind}`;

      history.push(urlPath);
    }
  };

  return (
    <Modal
      onClose={onClose}
      title={lang.TITLE}
      dialogClassName={b('modal')}
      bodyClassName={b('modal-body')}
      titleClassName={b('modal-title')}
    >
      <div className={b('content')}>
        <SelectDropdown
          value={selectedCatalog}
          onChange={onChangeCatalog}
          isLoading={loadingCatalogs}
          options={catalogOptions}
          searchable
          simpleValue
          label={lang.CATALOG_LABEL}
        />
        <SelectDropdown
          value={selectedTemplate}
          onChange={onChangeTemplate}
          isLoading={loadingTemplates}
          options={templateOptions}
          label={lang.TEMPLATE_LABEL}
          simpleValue
          disabled={!selectedCatalog}
        />
        <SelectDropdown
          value={selectedTemplateKind}
          onChange={setSelectedTemplateKind}
          options={emailTemplateKindOptions}
          simpleValue
          isLoading={loadingTemplateKinds}
          label={lang.TEMPLATE_TYPE_LABEL}
          disabled={!selectedCatalog || !selectedTemplate}
        />
        <AppButton
          label={lang.EDIT_TEMPLATE_BUTTON.toUpperCase()}
          className={b('button')}
          color="games"
          onClick={onGoToEmailTemplate}
          disabled={!selectedTemplateKind}
          iconName="edit-pencil"
          disabledOpacity
        />
      </div>
    </Modal>
  );
};

export default SelectEmailTemplateModal;
