import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import SelectDropdown from 'client/common/selects/select-dropdown';

import cssModule from './per-page.module.scss';

const b = bem('per-page', {cssModule});

type PerPageProps = {
  items?: string[];
  currentValue: string;
  onChange: (data: Record<string, string | number>) => void;
};

const PerPage: React.FC<PerPageProps> = (props) => {
  const {items = ['25', '50', '100'], currentValue = '25', onChange} = props;
  const lang = useLanguage('TABLE');

  return (
    <div className={b()}>
      <div className={b('label')}>{lang.SHOW_PER_PAGE_LABEL}</div>
      <SelectDropdown
        className={b('select')}
        value={currentValue}
        options={items.map((item) => ({label: item, value: item}))}
        onChange={(value: string) => onChange({page: 1, perPage: value})}
        type="per-page"
        simpleValue
      />
    </div>
  );
};

export default PerPage;
