import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import VariableValue from 'client/components/common/variable-value';

class EmailStep extends Component {
  static propTypes = {
    step: PropTypes.object,
    variableValues: PropTypes.object,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    step: {},
    variableValues: {},
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.RESULT_MODAL;
  }

  render() {
    const {LANGUAGE} = this;
    const {step, variableValues} = this.props;
    const {implementation} = step;

    return (
      <div className="email-step">
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled var-container">
            <div className="fake-input__label var-name">{LANGUAGE.TEMPLATE_LABEL}:</div>
            <div className="fake-input__field">{implementation.email_template.name}</div>
          </div>
        </div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled var-container">
            <div className="fake-input__label var-name">
              {implementation.recipient_variable.full_name || implementation.recipient_variable.name}:
            </div>
            <div className="fake-input__field">{variableValues[implementation.recipient_variable_id]}</div>
          </div>
        </div>

        {implementation.email_scenario_step_mapping_items.map((item, key) => (
          <div className="test-step__form-block" key={key}>
            <div className="fake-input fake-input--disabled var-container">
              <div className="fake-input__label var-name">
                {item.email_template_variable.full_name || item.email_template_variable.name}:
              </div>
              <div className="fake-input__field fake-input__field--height-auto">
                <VariableValue variable={item.variable} value={variableValues[item.variable_id]} />
              </div>
            </div>
          </div>
        ))}

        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled var-container">
            <div className="fake-input__label var-name">{LANGUAGE.COMMENT_LABEL}</div>
            <div className="fake-input__textarea">{step.comment}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(EmailStep);
