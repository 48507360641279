import React, {Component} from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

function AccountCell({data, rowIndex, field, onClick}) {
  return (
    <Cell>
      <div className="ellipsis-text display-block link" onClick={() => onClick(data[rowIndex])}>
        {data[rowIndex][field]}
      </div>
    </Cell>
  );
}

AccountCell.propTypes = {
  data: PropTypes.array.isRequired,
  rowIndex: PropTypes.number,
  field: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function EmailCell({data, rowIndex, field}) {
  return (
    <Cell>
      <div className="ellipsis-text">{data[rowIndex][field]}</div>
    </Cell>
  );
}

EmailCell.propTypes = {
  data: PropTypes.array.isRequired,
  rowIndex: PropTypes.number,
  field: PropTypes.string.isRequired,
};

class ParamsEmailsTable extends Component {
  static propTypes = {
    data: PropTypes.array,
    languageState: PropTypes.object.isRequired,
    onAccountClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.PARAMETERS;
  }

  render() {
    const {data, onAccountClick} = this.props;

    return (
      <div className="fixed-table fixed-table--params-card fixed-table--expand-1">
        <Table rowsCount={data.length} headerHeight={40} rowHeight={30} width={370} height={450} overflowX="hidden">
          <Column
            header={<Cell>{this.LANGUAGE.ACCOUNT_NAME_COLUMN}</Cell>}
            cell={<AccountCell data={data} field="name" onClick={onAccountClick} />}
            width={220}
          />
          <Column
            header={<Cell>{this.LANGUAGE.EMAIL_COLUMN}</Cell>}
            cell={<EmailCell data={data} field="value" />}
            width={150}
          />
        </Table>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(ParamsEmailsTable);
