import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import {ApiAction} from 'client/types';

import types from './types';

export const getExports: ApiAction = (queryParams: Record<string, any>) => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.STATISTIC_EXPORTS}`,
    method: 'GET',
    noCache: true,
    types: [types.GET_EXPORTS_REQUEST, types.GET_EXPORTS_SUCCESS, types.GET_EXPORTS_ERROR],
    queryParams,
  });
};

export const getExportsCount: ApiAction = (autotaskId: number) => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.STATISTIC_EXPORTS}`,
    method: 'GET',
    noCache: true,
    types: [types.GET_EXPORTS_COUNT_REQUEST, types.GET_EXPORTS_COUNT_SUCCESS, types.GET_EXPORTS_COUNT_ERROR],
    queryParams: {
      q: {
        automation_task_id_eq: autotaskId,
      },
      page: 1,
      per_page: 1,
    },
  });
};

export const createExport: ApiAction = (body: Record<string, any>) => (dispatch) => {
  return callApi(
    dispatch,
    {
      endpoint: `${API_PATH}${API_METHODS.STATISTIC_EXPORTS}`,
      method: 'POST',
      noCache: true,
      types: [types.CREATE_EXPORT_REQUEST, types.CREATE_EXPORT_SUCCESS, types.CREATE_EXPORT_ERROR],
      body,
    },
    true,
  );
};

export const deleteExport: ApiAction = (id: number) => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.STATISTIC_EXPORTS}/${id}`,
    method: 'DELETE',
    noCache: true,
    types: [types.DELETE_EXPORT_REQUEST, types.DELETE_EXPORT_SUCCESS, types.DELETE_EXPORT_ERROR],
  });
};
