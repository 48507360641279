import {useCallback} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {fetchTranslations, setLanguage as setLanguageAction} from 'client/ducks/language/actions';
import {selectAvailableTranslations, selectFetchedTranslations} from 'client/ducks/language/selectors';

import {MainStates} from 'client/types';

export const useTranslations = () => {
  const dispatch = useDispatch();
  const translations = useSelector(selectAvailableTranslations);
  const fetchedTranslations = useSelector(selectFetchedTranslations);
  const currentLanguage = useSelector((state: MainStates) => state.languageState.currentLanguage);

  const setLanguage = useCallback(
    async ({lang = 'en', store = true}) => {
      if (!fetchedTranslations?.[lang] || !translations?.[lang]) {
        await dispatch(fetchTranslations(lang));
      }
      await dispatch(setLanguageAction({lang, store}));
    },
    [dispatch, fetchedTranslations, translations],
  );

  return {
    setLanguage,
    currentLanguage,
  };
};
