import {StatisticExport, StatisticExportMapped} from 'client/models/statistic-exports/types';

export const mapStatisticExport = (item: StatisticExport): StatisticExportMapped => {
  return {
    id: item.id,
    exportId: item.id,
    fileName: item.filename,
    comment: item.comment,
    date: item.created_at,
    userName: item.client_user_full_name,
    link: item.url,
    deletable: item.deletable,
  };
};
