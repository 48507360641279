import React from 'react';

import PropTypes from 'prop-types';

import {diagramLineKinds} from 'client/ducks/results-graphs/constants';

import DropdownField from 'client/components/common/fields/dropdown-field';

const DiagramLineKindField = (props) => {
  const {lang} = props;

  return (
    <DropdownField
      name="line_kind"
      label={lang.TYPE_OF_LINE}
      options={[
        {value: diagramLineKinds.LINE_WITH_VALUES, label: lang.CONTINUOUS_LINE},
        {value: diagramLineKinds.LINE_WITH_INTERVALS, label: lang.LINE_WITH_INTERVALS},
      ]}
      withWrap
    />
  );
};

DiagramLineKindField.propTypes = {
  lang: PropTypes.object.isRequired,
};

export default DiagramLineKindField;
