import {PRIZE_BARCODE_FORMAT, PRIZE_CODE_TYPES} from 'client/components/prizes/constants';
import {validNameExt} from 'client/components/prizes/helpers';

export default (values, {lang, isCommon, defaultLanguage, barcodeFormat}) => {
  const errors = {};

  const hasBarcodeFormat = barcodeFormat && barcodeFormat !== PRIZE_BARCODE_FORMAT.NONE;

  if (!values.visual_type) {
    errors.visual_type = lang.REQUIRED;
  } else if (values.visual_type === PRIZE_CODE_TYPES.BAR_CODE && !hasBarcodeFormat) {
    errors.visual_type = lang.TYPE_CODE_NEED_FORMAT;
  }

  const verifyByLang = (key) => {
    if (values[key] === null) {
      errors[key] = lang.REQUIRED;
    } else if (!validNameExt(values[key]?.name)) {
      errors[key] = lang.INCORRECT_IMAGE_FORMAT;
    }
  };

  if (isCommon) {
    const key = `ticket_image_${defaultLanguage}`;
    verifyByLang(key);
  } else {
    for (let key in values) {
      if (key.startsWith('ticket_image_')) {
        verifyByLang(key);
      } else if (!values[key]) {
        errors[key] = lang.REQUIRED;
      }
    }
  }
  return errors;
};
