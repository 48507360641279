import {transformAgencyData} from 'client/services/helpers';

import {mapClientUserList} from './mappers';
import types from './types';

const initialState = {
  users: {
    data: [],
    perPage: 5,
    sort: {name: 'client_user_full_name', order: 'ASC'},
    search: '',
    needToUpdate: false,
    meta: {},
  },
  usersFullList: [],
  ongoingOperations: {
    data: [],
    perPage: 5,
    sort: {name: 'name', order: 'ASC'},
    needToUpdate: false,
    meta: {},
  },
  finishedOperations: {
    data: [],
    perPage: 5,
    sort: {name: 'name', order: 'ASC'},
    needToUpdate: false,
    meta: {},
  },
  clients: {
    data: [],
    perPage: 5,
    sort: {name: 'name', order: 'ASC'},
    needToUpdate: false,
    meta: {},
  },
  newUserAccessClients: [],
  selectedUsers: [],
  subsidiaries: [],
  agencyMemberships: [],
  adminUsers: [],
  noAgency: false,
};

export default function (_state = initialState, action) {
  const state = Object.assign({}, _state);

  switch (action.type) {
    case types.USERS_CHANGE_PER_PAGE:
      state.users = Object.assign({}, _state.users);
      state.users.perPage = action.perPage;
      return state;

    case types.ONGOING_OPERATIONS_CHANGE_PER_PAGE:
      state.ongoingOperations = Object.assign({}, _state.ongoingOperations);
      state.ongoingOperations.perPage = action.perPage;
      return state;

    case types.FINISHED_OPERATIONS_CHANGE_PER_PAGE:
      state.finishedOperations = Object.assign({}, _state.finishedOperations);
      state.finishedOperations.perPage = action.perPage;
      return state;

    case types.CLIENTS_CHANGE_PER_PAGE:
      state.clients = Object.assign({}, _state.clients);
      state.clients.perPage = action.perPage;
      return state;

    case types.USERS_CHANGE_SORT:
      state.users = Object.assign({}, _state.users);
      state.users.sort = action.sort;
      return state;

    case types.ONGOING_OPERATIONS_CHANGE_SORT:
      state.ongoingOperations = Object.assign({}, _state.ongoingOperations);
      state.ongoingOperations.sort = action.sort;
      return state;

    case types.FINISHED_OPERATIONS_CHANGE_SORT:
      state.finishedOperations = Object.assign({}, _state.finishedOperations);
      state.finishedOperations.sort = action.sort;
      return state;

    case types.CLIENTS_CHANGE_SORT:
      state.clients = Object.assign({}, _state.clients);
      state.clients.sort = action.sort;
      return state;

    case types.USERS_CHANGE_SEARCH:
      state.users = {..._state.users};
      state.users.search = action.search;
      return state;

    case types.CHANGE_SELECTED_USERS:
      state.selectedUsers = action.selectedUsers;
      return state;

    case types.CLEAR_STORE:
      return {...initialState};

    case types.GET_USERS_REQUEST:
      state.users = Object.assign({}, _state.users);
      state.users.needToUpdate = false;
      return state;

    case types.GET_USERS_ERROR:
      return state;

    case types.GET_USERS_SUCCESS:
      state.users = Object.assign({}, _state.users);
      state.users.data = action.payload.memberships;
      state.users.meta = action.payload.meta;
      return state;

    case types.GET_CLIENT_USER_SUCCESS:
      state.clientUser = action.payload.client_users[0] || {};
      return state;

    case types.GET_ONGOING_OPERATIONS_REQUEST:
      state.ongoingOperations = Object.assign({}, _state.ongoingOperations);
      state.ongoingOperations.needToUpdate = false;
      return state;

    case types.GET_ONGOING_OPERATIONS_ERROR:
      return state;

    case types.GET_ONGOING_OPERATIONS_SUCCESS:
      state.ongoingOperations = Object.assign({}, _state.ongoingOperations);
      state.ongoingOperations.data = action.payload.operations;
      state.ongoingOperations.meta = action.payload.meta;
      return state;

    case types.GET_FINISHED_OPERATIONS_REQUEST:
      state.finishedOperations = Object.assign({}, _state.finishedOperations);
      state.finishedOperations.needToUpdate = false;
      return state;

    case types.GET_FINISHED_OPERATIONS_ERROR:
      return state;

    case types.GET_FINISHED_OPERATIONS_SUCCESS:
      state.finishedOperations = Object.assign({}, _state.finishedOperations);
      state.finishedOperations.data = action.payload.operations;
      state.finishedOperations.meta = action.payload.meta;
      return state;

    case types.GET_CLIENTS_REQUEST:
      state.clients = Object.assign({}, _state.clients);
      state.clients.needToUpdate = false;
      return state;

    case types.GET_CLIENTS_ERROR:
      return state;

    case types.GET_CLIENTS_SUCCESS:
      state.clients = Object.assign({}, _state.clients);
      state.clients.data = action.payload.companies;
      state.clients.meta = action.payload.meta;
      return state;

    case types.GET_CLIENTS_BY_ID_SUCCESS:
      state.clients = Object.assign({}, _state.clients);
      state.newUserAccessClients = action.payload.companies;
      return state;

    case types.DELETE_AGENCY_SUCCESS:
      state.noAgency = true;
      return state;

    case types.GET_AGENCY_ERROR:
      state.noAgency = true;
      return state;

    case types.GET_AGENCY_SUCCESS:
      state.noAgency = false;
      state.agency = transformAgencyData(action.payload.agency);
      state.users = Object.assign({}, _state.users);
      state.users.needToUpdate = true;
      return state;

    case types.GET_SUBSIDIARIES_SUCCESS:
      state.subsidiaries = action.payload.subsidiaries;
      return state;

    case types.GET_AGENCY_MEMBERSHIPS_SUCCESS:
      state.agencyMemberships = action.payload.agency_memberships;
      return state;

    case types.GET_ADMIN_USERS_SUCCESS:
      state.adminUsers = action.payload.admin_users;
      return state;

    case types.SET_DEFAULT_NEW_USER:
      delete state.clientUser;
      delete state.agencyMembership;
      delete state.companyMembership;
      return state;

    case types.GET_AGENCY_USERS_FULL_LIST_SUCCESS:
      state.usersFullList = mapClientUserList(action.payload.client_users);
      return state;

    default:
      return state;
  }
}
