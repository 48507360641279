import React, {PureComponent} from 'react';

import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getFormValues, reduxForm} from 'redux-form';

import {createCoupon, updateCoupon, deleteCoupon, testSogec} from 'client/ducks/coupons/actions';
import {COUPON_TYPES} from 'client/ducks/coupons/constants';

import Icon from 'client/common/icon';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Modal from 'client/common/modals/modal';

import {ToggleField} from 'client/components/common/fields';

import AppFieldset from './app-fieldset';
import getInitialValues from './getInitialValues';
import mapFormValues from './mapFormValues';
import SogecFieldset from './sogec-fieldset';
import validate from './validate';
import WeezioFieldset from './weezio-fieldset';

import './coupon-edit-modal.scss';

class CouponEditModal extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    operationId: PropTypes.number.isRequired,
    editingCoupon: PropTypes.object,
    isEdit: PropTypes.bool.isRequired,
    hasTokens: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    createCoupon: PropTypes.func.isRequired,
    updateCoupon: PropTypes.func.isRequired,
    deleteCoupon: PropTypes.func.isRequired,
    testSogec: PropTypes.func.isRequired,
  };

  static defaultProps = {
    editingCoupon: null,
  };

  static formName = 'CouponEditModalForm';

  state = {
    showConfirmModal: false,
    showDeleteModal: false,
    sogecSuccess: null,
    sogecError: null,
  };

  componentDidUpdate(prevProps) {
    this.handleSogecStatusClearing(prevProps);
  }

  handleSogecStatusClearing = (prevProps) => {
    if (!isEqual(this.props.formValues, prevProps.formValues) && this.state.sogecSuccess !== null) {
      this.setState({
        sogecSuccess: null,
        sogecError: null,
      });
    }
  };

  handleSave = (values) => {
    const {operationId, editingCoupon} = this.props;

    const data = mapFormValues({operationId, ...values});

    const promise = editingCoupon
      ? this.props.updateCoupon(editingCoupon.id, values.type, data)
      : this.props.createCoupon(values.type, data);

    return promise.then(this.props.fetchData).then(this.props.onClose);
  };

  handleDelete = () => {
    const {editingCoupon} = this.props;

    return this.props
      .deleteCoupon(editingCoupon.id, editingCoupon.coupon_type)
      .then(this.props.fetchData)
      .then(this.props.onClose);
  };

  handleTestSogec = () => {
    const {formValues} = this.props;

    const data = {
      sogec_coupon: {
        sogec_configuration: {
          login: formValues.login,
          password: formValues.password,
        },
      },
    };

    this.props.testSogec(data).then(({payload}) => {
      this.setState({
        sogecSuccess: payload.success,
        sogecError: payload.errors || null,
      });
    });
  };

  toggleConfirmModal = () => this.setState(({showConfirmModal}) => ({showConfirmModal: !showConfirmModal}));

  toggleDeleteModal = () => this.setState(({showDeleteModal}) => ({showDeleteModal: !showDeleteModal}));

  renderConfirmModal = () => {
    const {lang, handleSubmit} = this.props;

    if (!this.state.showConfirmModal) {
      return null;
    }

    return (
      <ConfirmationModal
        show={true}
        className="theme-color-14"
        buttonConfirmClass="button--bg-9"
        onCancel={this.toggleConfirmModal}
        onClose={this.toggleConfirmModal}
        onConfirm={handleSubmit(this.handleSave)}
        cancelText={lang.CANCEL}
        confirmText={lang.CONFIRM_OK}
        message={
          <div className="coupon-edit-modal__message">
            <p>{lang.CONFIRM_MESSAGE_1}</p>
            <p>{lang.CONFIRM_MESSAGE_2}</p>
          </div>
        }
      />
    );
  };

  renderDeleteModal = () => {
    const {lang} = this.props;

    if (!this.state.showDeleteModal) {
      return null;
    }

    return (
      <ConfirmationModal
        show={true}
        className="theme-color-14"
        buttonConfirmClass="button--bg-9"
        onCancel={this.toggleDeleteModal}
        onClose={this.toggleDeleteModal}
        onConfirm={this.handleDelete}
        cancelText={lang.CANCEL}
        confirmText={lang.DELETE_OK}
        message={
          <div className="coupon-edit-modal__message">
            <p>{lang.DELETE_MESSAGE}</p>
          </div>
        }
      />
    );
  };

  render() {
    const {lang, isEdit, hasTokens, onClose, formValues, handleSubmit, valid, editingCoupon} = this.props;
    const {sogecSuccess, sogecError} = this.state;

    const hasPrizes = isEdit && editingCoupon.prizes.length > 0;

    return (
      <Modal
        dialogClassName={cn('coupon-edit-modal', {
          'coupon-edit-modal--wide': formValues.type === COUPON_TYPES.WEEZIO,
        })}
        onClose={onClose}
      >
        <form onSubmit={handleSubmit(this.handleSave)}>
          {this.renderConfirmModal()}
          {this.renderDeleteModal()}
          <div className="coupon-edit-modal__header">
            <p className="coupon-edit-modal__title">{isEdit ? lang.EDIT_COUPON : lang.ADD_COUPON}</p>
            {isEdit && <ToggleField name="test_mode" label={lang.TEST_MODE} inline withWrap={false} />}
            {isEdit && !hasTokens && (
              <button
                className="coupon-edit-modal__delete button button--bg-5 button--circle"
                type="button"
                onClick={this.toggleDeleteModal}
                disabled={hasPrizes}
              >
                <Icon
                  name="trash"
                  className="button__icon"
                  width={17}
                  height={19}
                  title={hasPrizes ? lang.NON_REMOVABLE : null}
                />
              </button>
            )}
          </div>
          <div className="coupon-edit-modal__content">
            {AppFieldset(this.props)}
            {formValues.type === COUPON_TYPES.WEEZIO && WeezioFieldset(this.props)}
            {formValues.type === COUPON_TYPES.SOGEC && SogecFieldset(this.props)}
          </div>
          <div className="coupon-edit-modal__buttons">
            {isEdit && (
              <button className="button button--bg-5" type="button" onClick={onClose}>
                {lang.CANCEL}
              </button>
            )}
            {formValues.type === COUPON_TYPES.SOGEC && (
              <div>
                <button
                  className="coupon-edit-modal__sogec-test-button button button--bg-9"
                  type="button"
                  onClick={this.handleTestSogec}
                  disabled={!valid}
                >
                  {sogecSuccess === true && <Icon name="sogec-success" />}
                  {sogecSuccess === false && <Icon name="sogec-error" />}
                  {lang.TEST}
                </button>
                <p className="coupon-edit-modal__sogec-test-error">{sogecError}</p>
              </div>
            )}
            {isEdit && (
              <button
                className="button button--bg-9"
                type={hasTokens ? 'button' : 'submit'}
                onClick={hasTokens ? this.toggleConfirmModal : null}
                disabled={!valid}
              >
                {lang.SAVE_CHANGES}
              </button>
            )}
            {!isEdit && (
              <button className="button button--bg-9" type="submit" disabled={!valid}>
                {lang.CREATE_COUPON}
              </button>
            )}
          </div>
        </form>
      </Modal>
    );
  }
}

const CouponEditModalForm = reduxForm({
  form: CouponEditModal.formName,
  shouldValidate: () => true,
  validate,
})(CouponEditModal);

export default connect(
  (state, props) => ({
    lang: {
      ...state.languageState.payload.OPERATION_PAGE.COUPONS_CARD.COUPON_FIELDS,
      ...state.languageState.payload.OPERATION_PAGE.COUPONS_CARD.COUPON_EDIT_MODAL,
    },
    formValues: getFormValues(CouponEditModal.formName)(state) || {},
    initialValues: getInitialValues(props.editingCoupon),
    isEdit: !!props.editingCoupon,
    hasTokens: !!props.editingCoupon && props.editingCoupon.number_of_generated_tokens > 0,
  }),
  {
    createCoupon,
    updateCoupon,
    deleteCoupon,
    testSogec,
  },
)(CouponEditModalForm);
