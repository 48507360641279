import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {TranslationJsx} from 'client/models/language/types';

import RadioInput from '../../inputs/radio-input';

import './radio-panel-tab.scss';

const b = bem('radio-panel-tab');

const RadioPanelTab = (props) => {
  const {value, checked, label, name, content: Content, header: Header, className, disabled} = props;

  return (
    <div className={cn(b({checked}), className)}>
      <div className={b('header')}>
        <RadioInput
          name={name}
          value={value}
          label={label}
          checked={checked}
          className={b('radio')}
          disabled={disabled}
          readOnly
        />
        {Header && <div className={b('extra-header')}>{typeof Header === 'function' ? <Header /> : Header}</div>}
      </div>
      {typeof Content === 'function' ? <Content /> : Content}
    </div>
  );
};

RadioPanelTab.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string,
  label: TranslationJsx.isRequired,
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  content: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

RadioPanelTab.defaultProps = {
  checked: false,
  header: null,
  content: null,
  onChange: null,
  name: '',
  className: '',
  disabled: false,
};

export default RadioPanelTab;
