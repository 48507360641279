import React from 'react';

import bem from 'client/services/bem';

import Graph from './graph';
import GraphCanvas from './graph-canvas';
import {getVerticalGraphData} from './helpers';
import {VerticalBarsDiagramProps} from './type';

import cssModule from './vertical-bars-chart.module.scss';

const b = bem('vertical-bars-chart', {cssModule});

const VerticalBarsDiagram: React.FC<VerticalBarsDiagramProps> = ({diagram}) => {
  const {diagram_method = 'value', dot_value = 0, values, color, tooltip = '', values_count = 0} = diagram;

  const {data, yAxisConfig} = getVerticalGraphData({diagram_method, dot_value, values});

  return (
    <div className={b('')}>
      <GraphCanvas yAxisConfig={yAxisConfig} className={b('columns')}>
        <Graph
          className={b('data')}
          data={data}
          maxValue={yAxisConfig.max}
          step={dot_value}
          method={diagram_method}
          color={color}
          tooltip={tooltip}
          valuesCount={values_count}
        />
      </GraphCanvas>
    </div>
  );
};

export default VerticalBarsDiagram;
