export default {
  CREATE_FILE_LEADS_EXPORT_REQUEST: '@@leads-export-report/CREATE_FILE_LEADS_EXPORT_REQUEST',
  CREATE_FILE_LEADS_EXPORT_SUCCESS: '@@leads-export-report/CREATE_FILE_LEADS_EXPORT_SUCCESS',
  CREATE_FILE_LEADS_EXPORT_ERROR: '@@leads-export-report/CREATE_FILE_LEADS_EXPORT_ERROR',

  GET_API_LEAD_EXPORTS_REQUEST: '@@leads-export-report/GET_API_LEAD_EXPORTS_REQUEST',
  GET_API_LEAD_EXPORTS_SUCCESS: '@@leads-export-report/GET_API_LEAD_EXPORTS_SUCCESS',
  GET_API_LEAD_EXPORTS_ERROR: '@@leads-export-report/GET_API_LEAD_EXPORTS_ERROR',

  API_LEADS_EXPORT_REQUEST: '@@leads-export-report/API_LEADS_EXPORT_REQUEST',
  API_LEADS_EXPORT_SUCCESS: '@@leads-export-report/API_LEADS_EXPORT_SUCCESS',
  API_LEADS_EXPORT_ERROR: '@@leads-export-report/API_LEADS_EXPORT_ERROR',

  UPDATE_API_LEADS_EXPORT_REQUEST: '@@leads-export-report/UPDATE_API_LEADS_EXPORT_REQUEST',
  UPDATE_API_LEADS_EXPORT_SUCCESS: '@@leads-export-report/UPDATE_API_LEADS_EXPORT_SUCCESS',
  UPDATE_API_LEADS_EXPORT_ERROR: '@@leads-export-report/UPDATE_API_LEADS_EXPORT_ERROR',

  DELETE_API_LEADS_EXPORT_REQUEST: '@@leads-export-report/DELETE_API_LEADS_EXPORT_REQUEST',
  DELETE_API_LEADS_EXPORT_SUCCESS: '@@leads-export-report/DELETE_API_LEADS_EXPORT_SUCCESS',
  DELETE_API_LEADS_EXPORT_ERROR: '@@leads-export-report/DELETE_API_LEADS_EXPORT_ERROR',

  GET_LEADS_EXPORT_REPORT_REQUEST: '@@leads-export-report/GET_LEADS_EXPORT_REPORT_REQUEST',
  GET_LEADS_EXPORT_REPORT_SUCCESS: '@@leads-export-report/GET_LEADS_EXPORT_REPORT_SUCCESS',
  GET_LEADS_EXPORT_REPORT_ERROR: '@@leads-export-report/GET_LEADS_EXPORT_REPORT_ERROR',

  GET_FILE_LEADS_EXPORT_REQUEST: '@@leads-export-report/GET_FILE_LEADS_EXPORT_REQUEST',
  GET_FILE_LEADS_EXPORT_SUCCESS: '@@leads-export-report/GET_FILE_LEADS_EXPORT_SUCCESS',
  GET_FILE_LEADS_EXPORT_ERROR: '@@leads-export-report/GET_FILE_LEADS_EXPORT_ERROR',

  DELETE_LEADS_EXPORT_FILE_REQUEST: '@@leads-export-report/DELETE_LEADS_EXPORT_FILE_REQUEST',
  DELETE_LEADS_EXPORT_FILE_SUCCESS: '@@leads-export-report/DELETE_LEADS_EXPORT_FILE_SUCCESS',
  DELETE_LEADS_EXPORT_FILE_ERROR: '@@leads-export-report/DELETE_LEADS_EXPORT_FILE_ERROR',

  GET_LEADS_EXPORT_REPORTS_REQUEST: '@@leads-export-report/GET_LEADS_EXPORT_REPORTS_REQUEST',
  GET_LEADS_EXPORT_REPORTS_SUCCESS: '@@leads-export-report/GET_LEADS_EXPORT_REPORTS_SUCCESS',
  GET_LEADS_EXPORT_REPORTS_ERROR: '@@leads-export-report/GET_LEADS_EXPORT_REPORTS_ERROR',

  DELETE_FILE_LEAD_EXPORT_REQUEST: '@@leads-export-report/DELETE_FILE_LEAD_EXPORT_REQUEST',
  DELETE_FILE_LEAD_EXPORT_SUCCESS: '@@leads-export-report/DELETE_FILE_LEAD_EXPORT_SUCCESS',
  DELETE_FILE_LEAD_EXPORT_ERROR: '@@leads-export-report/DELETE_FILE_LEAD_EXPORT_ERROR',

  SHARE_LEAD_EXPORT_REQUEST: '@@leads-export-report/SHARE_LEAD_EXPORT_REQUEST',
  SHARE_LEAD_EXPORT_SUCCESS: '@@leads-export-report/SHARE_LEAD_EXPORT_SUCCESS',
  SHARE_LEAD_EXPORT_ERROR: '@@leads-export-report/SHARE_LEAD_EXPORT_ERROR',
};
