import React from 'react';

import PropTypes from 'prop-types';

import {getAnimationValidationStatus} from 'client/components/instore/helpers';

import AnimationIconCell from '../animation-icon-cell';

const AnimationInstallCell = (props) => {
  const {className, item} = props;

  return (
    <AnimationIconCell
      className={className}
      item={item}
      field="install_status"
      iconPrefix="install"
      iconNameGetter={getAnimationValidationStatus}
      testId="animation-install-cell"
    />
  );
};

AnimationInstallCell.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    install_status: PropTypes.string,
    animation_days: PropTypes.arrayOf(
      PropTypes.shape({
        install_status: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

AnimationInstallCell.defaultProps = {
  className: null,
};

export default AnimationInstallCell;
