import React, {Component} from 'react';

import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

import './checkbox-list.scss';

class CheckboxList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items,
    };

    this.onCheckChange = this.onCheckChange.bind(this);
  }

  onCheckChange(targetIndex, checked) {
    if (this.props.onCheck) {
      this.props.onCheck(targetIndex, checked);
    }
  }

  render() {
    const {cssModifier} = this.props;
    return (
      <div className="checkbox-list">
        {this.props.items.map(({checked, component, label, value = 'on', name = 'check'}, index) => (
          <div key={index} className={`checkbox-list__row ${cssModifier}`}>
            <label className="checkbox-button">
              <input
                className="checkbox-button__input"
                type="checkbox"
                name={name}
                value={value}
                defaultChecked={checked}
                onChange={({target}) => this.onCheckChange(index, target.checked)}
              />
              <div className="checkbox-button__label">
                <span className="icon-checked checkbox-button__icon" />
                {label && <span className="checkbox-button__text">{label}</span>}
              </div>
            </label>
            {component}
          </div>
        ))}
      </div>
    );
  }
}

CheckboxList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool,
      component: PropTypes.any,
      label: TranslationJsx,
    }),
  ),
  onCheck: PropTypes.func,
  cssModifier: PropTypes.string,
};

CheckboxList.defaultProps = {
  items: [],
  onCheck: () => {},
  cssModifier: '',
};

export default CheckboxList;
