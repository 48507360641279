import React, {memo, useCallback} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Popover from 'client/common/popovers/popover';

import Icon from 'client/components/common/icon';

import {MEDIA_LEVELS} from 'client/components/medias/constants';

import './medias-locked-cell.scss';

const b = bem('medias-locked-cell');

const MediasLockedCell = memo((props) => {
  const {index, item, level, change, isLocal} = props;
  const compareLevel = isLocal ? MEDIA_LEVELS.DEVICE : level;
  const lang = useLanguage('MEDIAS');

  const handleClick = useCallback(() => {
    change(`medias[${index}].locked`, !item.locked);
  }, [change, index, item.locked]);

  return (
    <div className={b()}>
      {item.level === compareLevel && (
        <Popover
          key={item.locked}
          position="top"
          overlay={<div className={b('hint')}>{item.locked ? lang.UNLOCK_MESSAGE : lang.LOCK_MESSAGE}</div>}
        >
          <Icon className={b('icon')} name={`media-${item.locked ? 'unlock' : 'lock'}`} onClick={handleClick} />
        </Popover>
      )}
    </div>
  );
});

MediasLockedCell.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  level: PropTypes.string.isRequired,
  isLocal: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
};

export default MediasLockedCell;
