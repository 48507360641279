import React, {PureComponent} from 'react';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {TranslationJsx} from 'client/models/language/types';

class StepErrorContainer extends PureComponent {
  constructor() {
    super();
  }

  render() {
    return (
      <CustomScrollbars
        scrollbarProps={{
          autoHeight: false,
        }}
        cssModifier="custom-scrollbars--hide-hor-scroll"
      >
        <div className="rt-result-steps__container">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__textarea">{this.props.error_message}</div>
          </div>
        </div>
      </CustomScrollbars>
    );
  }
}

StepErrorContainer.propTypes = {
  error_message: TranslationJsx,
};

export default StepErrorContainer;
