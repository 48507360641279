import moment from 'moment';

import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {isEmptyString} from 'client/services/validator';

import {GENDER_TYPES_VALUES} from 'client/common/config';

import {
  GenerateFileFormValues,
  GenerationExportFilters,
} from 'client/components/client-autotask/tabs/data-tab/generate-file-modal';

export const validate = (values: GenerateFileFormValues, lang: LangsByPath<['DATA_TAB.GENERATE_FILE_MODAL']>) => {
  const errors: Partial<GenerateFileFormValues> = {};
  if (isEmptyString(values?.name)) {
    errors.name = lang.REQUIRED?.toString();
  }

  return errors;
};

type MappingResult = {
  query_params: {
    distinct: true;
    g: [Record<string, string | boolean>];
  } & Record<string, any>;
  query_string?: string;
};
export const mappingParticipationFilters = (filters: GenerationExportFilters = {}) => {
  const result: MappingResult = {
    query_params: {
      distinct: true,
      g: [{}],
    },
  };

  if (filters?.phone) {
    result.query_params.lead_phone_present = 't';
  }

  if (filters?.email) {
    result.query_params.lead_email_present = 't';
  }

  if (filters?.visuals) {
    result.query_params.with_media = true;
  }
  if (filters?.full_participation) {
    result.query_params.min_level_reached_at_null = false;
  }
  if (filters?.scenario?.length) {
    result.query_params.scenarios_id_in = filters.scenario.map((s) => s.value);
  }
  if (filters?.interfaces?.length) {
    result.query_params.interface_ids = filters.interfaces.map((s) => s.value);
  }
  if (filters?.level?.length) {
    result.query_params.level_numbers = filters.level.map((s) => s.value);
  }
  if (filters?.origin) {
    result.query_params.interaction_id_eq = filters.origin.value;
  }
  if (filters?.optInRadio === 'no_active') {
    result.query_params.lead_opt_ins_is_empty_json = true;
  } else if (filters?.optInRadio) {
    filters.optIn?.forEach((optIn) => {
      result.query_params.g[0][`lead_opt_ins->>${optIn.value}_eq`] = true;
    });
  }
  if (filters?.participatedFrom) {
    result.query_params.g[0].created_at_gteq = moment(filters.participatedFrom, 'DD/MM/YYYY').toISOString();
  }
  if (filters?.participatedTo) {
    result.query_params.g[0].created_at_lteq = moment(filters.participatedTo, 'DD/MM/YYYY')
      .hours(23)
      .minutes(59)
      .toISOString();
  }

  return result;
};

// A lot of Filters are like for participation exports
export const mappingWinningFilters = (filters: GenerationExportFilters = {}, extraParams: Record<string, any> = {}) => {
  const participations = mappingParticipationFilters(filters);

  const result: MappingResult = {
    query_params: {
      ...participations.query_params,
      distinct: true,
      g: [participations.query_params.g[0] || {}],
    },
  };

  if (filters.gender) {
    result.query_params.lead_gender_eq =
      GENDER_TYPES_VALUES[filters.gender.toUpperCase() as keyof typeof GENDER_TYPES_VALUES];
  }

  if (filters?.participatedFrom) {
    result.query_params.g[0].level_reached_or_participated_at_gteq = moment(
      filters.participatedFrom,
      'DD/MM/YYYY',
    ).toISOString();
  }
  if (filters?.participatedTo) {
    result.query_params.g[0].level_reached_or_participated_at_lteq = moment(filters.participatedTo, 'DD/MM/YYYY')
      .hours(23)
      .minutes(59)
      .toISOString();
  }
  if (filters.prizes?.length) {
    result.query_params.participation_prizes_name_in = filters.prizes.map((p) => p.value);
  }

  const prizeTypes = extraParams.prizeNames
    .map((prizeName: {label: string; value: string}, index: number) => (filters[prizeName.value] ? index + 1 : null))
    .filter(Boolean);
  if (prizeTypes.length) {
    result.query_params.participation_prizes_db_number_in = prizeTypes;
  }

  return result;
};

export const mappingLeadFilters = (filters: GenerationExportFilters = {}) => {
  const result: MappingResult = {
    query_params: {
      distinct: true,
      g: [{}],
    },
  };

  if (filters?.phone) {
    result.query_params.phone_present = 't';
  }

  if (filters?.email) {
    result.query_params.email_present = 't';
  }

  if (filters?.visuals) {
    result.query_params.visuals_count_gt = 0;
  }

  if (filters?.participatedFrom) {
    result.query_params.g[0].participations_created_at_gteq = moment(
      filters.participatedFrom,
      'DD/MM/YYYY',
    ).toISOString();
  }
  if (filters?.participatedTo) {
    result.query_params.g[0].participations_created_at_lteq = moment(filters.participatedTo, 'DD/MM/YYYY')
      .hours(23)
      .minutes(59)
      .toISOString();
  }

  if (filters.gender) {
    result.query_params.gender_eq =
      GENDER_TYPES_VALUES[filters.gender.toUpperCase() as keyof typeof GENDER_TYPES_VALUES];
  }

  if (filters?.createdFrom) {
    result.query_params.g[0].created_at_gteq = moment(filters.createdFrom, 'DD/MM/YYYY').toISOString();
  }
  if (filters?.createdTo) {
    result.query_params.g[0].created_at_lteq = moment(filters.createdTo, 'DD/MM/YYYY')
      .hours(23)
      .minutes(59)
      .toISOString();
  }

  if (filters?.origin) {
    result.query_params.lead_first_source_source_id_eq = filters.origin.value;
  }

  if (filters.operation?.length) {
    result.query_params.participations_operation_id_in = filters.operation.map((p) => p.value);
  }

  if (filters?.optInRadio === 'no_active') {
    result.query_params.opt_ins_text_not_cont = true;
  } else if (filters?.optInRadio) {
    filters.optIn?.forEach((optIn) => {
      result.query_params.g[0][`opt_ins->>${optIn.value}_eq`] = true;
    });
  }

  return result;
};
