import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {change as changeField, Field, initialize, reduxForm, reset, untouch} from 'redux-form';

import {transformDate} from 'client/services/helpers';

// components
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

const preChosenValues = {
  client: {value: '0'},
  start_date_type: {value: '0'},
  end_date_type: {value: '0'},
};

let filterNewProps = preChosenValues;

class OperationsFiltersModal extends Component {
  static mapFilterData(data) {
    const result = {...data};

    if (!result.client.id || result.client.id === '0') {
      delete result.client;
    } else {
      result.client = {
        id: result.client.id,
        name: result.client.name,
      };
    }

    const startDate = new Date(transformDate(result.start_date_date, false));
    const endDate = new Date(transformDate(result.end_date_date, false));
    if (
      result.start_date_type.value &&
      result.start_date_type.value !== '0' &&
      startDate.toString() !== 'Invalid Date'
    ) {
      result.start_date = {
        type: result.start_date_type.value,
        date: result.start_date_date,
      };
    } else {
      delete result.start_date;
    }
    delete result.start_date_type;
    delete result.start_date_date;

    if (result.end_date_type.value && result.end_date_type.value !== '0' && endDate.toString() !== 'Invalid Date') {
      result.end_date = {
        type: result.end_date_type.value,
        date: result.end_date_date,
      };
    } else {
      delete result.end_date;
    }
    delete result.end_date_type;
    delete result.end_date_date;

    if (!result.status) {
      delete result.status;
    }

    return result;
  }

  constructor(props) {
    super(props);

    this.LANGUAGE = this.props.languageState.payload.OPERATIONS;
  }

  componentDidMount() {
    this.props.initialize(filterNewProps);
  }

  componentDidUpdate(prevProps) {
    if (this.props.clients !== prevProps.clients) {
      this.props.clients.unshift({id: '0', name: this.LANGUAGE.FILTER_MODAL_NO_MATTER_VALUE});
    }

    if (this.props.filters) {
      filterNewProps = {...preChosenValues, ...this.props.filters};
      if (this.props.filters.start_date) {
        filterNewProps.start_date_type = {
          value: this.props.filters.start_date.type,
          label: this.LANGUAGE.FILTER_DATE_TYPE[this.props.filters.start_date.type],
        };
        filterNewProps.start_date_date = this.props.filters.start_date.date;
      }
      if (this.props.filters.end_date) {
        filterNewProps.end_date_type = {
          value: this.props.filters.end_date.type,
          label: this.LANGUAGE.FILTER_DATE_TYPE[this.props.filters.end_date.type],
        };
        filterNewProps.end_date_date = this.props.filters.end_date.date;
      }
    }
  }

  onRadioClick = ({target}) => {
    if (target.checked && target.value === this.props.formValues.values.status) {
      this.props.changeField('OperationsFiltersModalForm', 'status', '');
      setTimeout(this.changeFilter, 500);
    }
  };

  changeFilter = () => {
    const mappedData = OperationsFiltersModal.mapFilterData(this.props.formValues.values);

    this.props.onFilterChange(mappedData);
  };

  save = (data) => {
    const mappedData = OperationsFiltersModal.mapFilterData(data);
    this.props.onFilterChange(mappedData);
  };

  closeFilter = () => {
    this.props.onClose();
  };

  render() {
    const {handleSubmit, itemsFound, clients, formValues} = this.props;

    const startDateType = formValues.values && formValues.values.start_date_type;
    const endDateType = formValues.values && formValues.values.end_date_type;
    const onChange = {onChange: () => setTimeout(this.changeFilter, 500)};

    return (
      <Modal
        show={this.props.show}
        onClose={this.closeFilter}
        title={this.LANGUAGE.FILTER_MODAL_TITLE}
        dialogClassName="modal-window--width-1 modal-window--theme-4 operations-filters-modal"
      >
        <form onSubmit={handleSubmit(this.save)}>
          <div className="form-field-wrap">
            <Field
              cssModifier="select--view-2"
              label={this.LANGUAGE.FILTER_MODAL_CLIENT_NAME_LABEL}
              name="client"
              type="select"
              {...onChange}
              valueKey="id"
              labelKey="name"
              options={clients}
              component={fieldTemplate}
            />
          </div>
          <div className="form-field-wrap filters__row">
            <Field
              cssModifier="select--view-2 filters__item filters__item--half"
              label={this.LANGUAGE.FILTER_MODAL_START_DATE_LABEL}
              name="start_date_type"
              type="select"
              {...onChange}
              searchable={false}
              options={[
                {
                  value: '0',
                  label: this.LANGUAGE.FILTER_MODAL_NO_MATTER_VALUE,
                },
                {
                  value: 'from',
                  label: this.LANGUAGE.FILTER_DATE_TYPE.from,
                },
                {
                  value: 'till',
                  label: this.LANGUAGE.FILTER_DATE_TYPE.till,
                },
              ]}
              component={fieldTemplate}
            />
            {startDateType && startDateType.value !== '0' && (
              <Field
                cssModifier="input--view-1 input--no-min-height filters__item filters__item--half"
                name="start_date_date"
                type="datepicker"
                {...onChange}
                component={fieldTemplate}
              />
            )}
          </div>
          <div className="form-field-wrap filters__row">
            <Field
              cssModifier="select--view-2 filters__item filters__item--half"
              label={this.LANGUAGE.FILTER_MODAL_END_DATE_LABEL}
              name="end_date_type"
              type="select"
              searchable={false}
              {...onChange}
              options={[
                {
                  value: '0',
                  label: this.LANGUAGE.FILTER_MODAL_NO_MATTER_VALUE,
                },
                {
                  value: 'from',
                  label: this.LANGUAGE.FILTER_DATE_TYPE.from,
                },
                {
                  value: 'till',
                  label: this.LANGUAGE.FILTER_DATE_TYPE.till,
                },
              ]}
              component={fieldTemplate}
            />
            {endDateType && endDateType.value !== '0' && (
              <Field
                cssModifier="input--view-1 input--no-min-height filters__item filters__item--half"
                name="end_date_date"
                {...onChange}
                type="datepicker"
                component={fieldTemplate}
              />
            )}
          </div>
          <div className="select__label">{this.LANGUAGE.FILTER_MODAL_STATUS_LABEL}</div>
          <div className="flex-container filters__row filters__row--gap">
            <Field
              cssModifier="filters__item flex-col radio-button__input"
              label={this.LANGUAGE.OPERATION_STATUS.active}
              name="status"
              type="radio"
              {...onChange}
              value="active"
              onClick={this.onRadioClick}
              radioType="switcher-1"
              component={fieldTemplate}
            />
            <Field
              cssModifier="filters__item flex-col radio-button__input"
              label={this.LANGUAGE.OPERATION_STATUS.finished}
              name="status"
              type="radio"
              {...onChange}
              value="finished"
              onClick={this.onRadioClick}
              radioType="switcher-1"
              component={fieldTemplate}
            />
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-4 modal-window__footer-btn" type="submit" onClick={this.closeFilter}>
              {`${this.LANGUAGE.FILTER_MODAL_SHOW_RESULTS_BUTTON} (${itemsFound})`}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

OperationsFiltersModal.propTypes = {
  show: PropTypes.bool,
  itemsFound: PropTypes.number,
  onClose: PropTypes.func,
  onFilterChange: PropTypes.func,
  changeField: PropTypes.func,
  untouch: PropTypes.func,
  reset: PropTypes.func,
  initialize: PropTypes.func,
  handleSubmit: PropTypes.func,
  languageState: PropTypes.object,
  clients: PropTypes.array,
  formValues: PropTypes.object,
  filters: PropTypes.object,
};

OperationsFiltersModal.defaultProps = {
  show: false,
  itemsFound: 0,
  onFilterChange: () => {},
  onClose: () => {},
  clients: [],
  formValues: {},
};

const OperationsFiltersModalForm = reduxForm({
  form: 'OperationsFiltersModalForm',
  enableReinitialize: true,
})(OperationsFiltersModal);

export default connect(
  ({languageState, form}) => {
    return {
      languageState,
      initialValues: filterNewProps,
      formValues: form.OperationsFiltersModalForm,
    };
  },
  {
    changeField,
    untouch,
    reset,
    initialize,
  },
)(OperationsFiltersModalForm);
