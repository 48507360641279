import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import ClientTable from 'client/components/common/client-table';
import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {TranslationJsx} from 'client/models/language/types';

import './scrollable-table.scss';

const b = bem('scrollable-table');

function ScrollableTable(props) {
  const {data, noDataText, iconClassName, loading, className, scrollbarProps = {}, ...restProps} = props;

  if (!loading && !data.length) {
    return (
      <ClientTable columns={restProps.columns} data={data} iconClassName={iconClassName} noDataText={noDataText} />
    );
  }

  return (
    <CustomScrollbars cssModifier={b()} scrollbarProps={scrollbarProps}>
      <ClientTable
        {...restProps}
        className={cn(b('table'), className)}
        data={data}
        noDataText={noDataText}
        loading={loading}
      />
    </CustomScrollbars>
  );
}

ScrollableTable.propTypes = {
  columns: PropTypes.array.isRequired,
  noDataText: TranslationJsx,
  iconClassName: PropTypes.string,
  data: PropTypes.array.isRequired,
  scrollbarProps: PropTypes.object,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

ScrollableTable.defaultProps = {
  noDataText: '',
  iconClassName: '',
  scrollbarProps: {},
  loading: false,
  className: '',
};

export default ScrollableTable;
