import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import {anyString, email, phone, intAndDecimal, maxLength, number, date} from 'client/services/validator';

import {COLUMN_TYPES} from 'client/common/config';

import fieldTemplate from 'client/components/common/field';

import {mapCustomInfo} from 'client/components/leads/leads-list/helper';

class CustomInfoForm extends Component {
  static formName = 'leadsCustomInfoForm';

  static defaultProps = {
    editMode: false,
  };

  static propTypes = {
    editMode: PropTypes.bool,
    languageState: PropTypes.object,
    data: PropTypes.object,
    customColumns: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.LANGUAGE = props.languageState.payload.LEADS_PROFILE.ERRORS;
    this.rules = {
      email: [email(this.LANGUAGE.INVALID_EMAIL)],
      decimal: [intAndDecimal(this.LANGUAGE.SHOULD_BE_DECIMAL)],
      phone: [phone(this.LANGUAGE.INVALID_PHONE)],
      string: [anyString(this.LANGUAGE.STRING), maxLength(255, `${this.LANGUAGE.MAXIMUM_LENGTH.replace('{N}', 255)}`)],
      integer: [number(this.LANGUAGE.SHOULD_BE_INTEGER)],
      text: [maxLength(255, `${this.LANGUAGE.MAXIMUM_LENGTH.replace('{N}', 255)}`), anyString(this.LANGUAGE.TEXT)],
      datetime: [date(this.LANGUAGE.DATE)],
    };
  }
  getValidator = (type) => {
    return this.rules[type] || null;
  };

  render() {
    const {
      editMode,
      handleSubmit,
      data: {data = {}},
      customColumns,
    } = this.props;
    const columns = customColumns.payload.all_lead_columns;

    return (
      <form onSubmit={handleSubmit}>
        <div className="clearfix">
          {columns.map((el, index) => {
            const validator = this.getValidator(el.kind);
            return (
              <div className="col-xs-6" key={index}>
                <div className="form-field-wrap form-field-wrap__group--height-5">
                  {!el.array ? (
                    <Field
                      cssModifier="input--disabled-view-1 input--no-min-height"
                      disabled={el.kind === COLUMN_TYPES.BOOLEAN || el.kind === COLUMN_TYPES.FILE || !editMode}
                      label={el.name}
                      name={'x' + el.id}
                      type="text"
                      component={fieldTemplate}
                      validate={el.kind === COLUMN_TYPES.BOOLEAN || el.kind === COLUMN_TYPES.FILE ? null : validator}
                    />
                  ) : (
                    <div>
                      <label className="input__label">{el.name}</label>
                      {(data[el.id] || []).map((array_el, i) => {
                        return (
                          <Field
                            key={i}
                            cssModifier="input--disabled-view-1 input--no-min-height"
                            disabled={el.kind === COLUMN_TYPES.BOOLEAN || el.kind === COLUMN_TYPES.FILE || !editMode}
                            // name={`${el.id}.${i}`}
                            name={'x' + el.id}
                            type="text"
                            component={fieldTemplate}
                            validate={
                              el.kind === COLUMN_TYPES.BOOLEAN || el.kind === COLUMN_TYPES.FILE ? null : validator
                            }
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </form>
    );
  }
}

const leadsCustomInfoForm = reduxForm({
  form: CustomInfoForm.formName,
  enableReinitialize: true,
})(CustomInfoForm);

export default connect(({languageState, lead}, ownProps) => ({
  lead,
  languageState,
  initialValues: mapCustomInfo(lead.payload),
  onSubmit: ownProps.submitCustomInfoForm,
}))(leadsCustomInfoForm);
