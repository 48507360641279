import React from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams, useReduxFetch} from 'client/services/hooks';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {getLeadsClient} from 'client/ducks/leads-dashboard/actions';
import {selectLeadsClient} from 'client/ducks/leads-dashboard/selectors';

import Spinner from 'client/components/common/spinner';

import AgencyClientsBar from 'client/components/agencies/various/agency-clients-bar';
import {CLIENT_TYPES} from 'client/models/client/constants';

import LeadsCustomColumns from './leads-custom-columns';
import LeadsExports from './leads-exports';
import LeadsOptins from './leads-optins';

import './leads-parameters.scss';

const b = bem('leads-parameters');

type LeadsParametersProps = {
  clientId: number;
};

const LeadsParameters: React.FC<LeadsParametersProps> = (props) => {
  const {clientId} = props;
  const lang = useLanguage('LEADS_PARAMETERS');
  const [{agencyClientId}] = useQueryParams(null, {}, {parse: {parseNumbers: true}});
  const currentClient = useSelector(selectCurrentClient);

  const {data: client, loading: loading} = useReduxFetch({
    action: getLeadsClient,
    selector: selectLeadsClient,
    actionArgs: agencyClientId || clientId,
  });

  if (currentClient?.id !== clientId || loading) {
    return <Spinner pageCentered />;
  }

  const isAgency = currentClient.type === CLIENT_TYPES.AGENCY;
  const showClientBar = isAgency && currentClient.client_management;

  const canManage =
    isAgency && currentClient.id !== client.id ? currentClient.client_management : client.crm_management;

  return (
    <div className={b({agency: showClientBar})}>
      {showClientBar && <AgencyClientsBar agencyId={clientId} agencyOption />}
      <div className={b('inner')}>
        <h1 className={b('title')}>{lang.TITLE}</h1>
        <div className={b('row')}>
          <LeadsOptins client={client} className={b('column-short')} canManage={canManage} />
          <LeadsCustomColumns client={client} className={b('column-short')} />
          <LeadsExports client={client} className={b('column-long')} />
        </div>
      </div>
    </div>
  );
};

export default LeadsParameters;
