import React from 'react';

import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import ReactQueryParams from 'react-query-params';

import {patch} from 'client/services/fetch';

import {API_METHODS} from 'client/common/config';

import Icon from 'client/components/common/icon';
import SearchField from 'client/components/common/search-field';
import ViewToggle from 'client/components/common/view-toggle';

import {TranslationJsx} from 'client/models/language/types';

import {mapFilter} from '../../visuals-client/helpers';
import VisDataGrid from '../tables/vis-data-grid';
import VisGridView from '../vis-grid-view';
import VisToolbar from '../vis-toolbar';

import './visuals-base.scss';

class VisualsBase extends ReactQueryParams {
  static VIEWS = {
    PREVIEW: 'mode-preview',
    LINE: 'mode-line',
  };

  static propTypes = {
    data: PropTypes.array,
    selectedIds: PropTypes.array,
    titleContent: PropTypes.any,
    controlsContent: PropTypes.any,
    actionButtons: PropTypes.any,
    languageState: PropTypes.object.isRequired,
    meta: PropTypes.object,
    showClear: PropTypes.bool,
    onLeadClick: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    onPreviewClick: PropTypes.func.isRequired,
    onSettingsClick: PropTypes.func.isRequired,
    updateMe: PropTypes.func.isRequired,
    onShowFiltersClick: PropTypes.func.isRequired,
    noDataMessage: PropTypes.any,
    gridNoDataMessage: TranslationJsx,
    clientId: PropTypes.number,
    isAdmin: PropTypes.bool.isRequired,
    gridView: PropTypes.bool,
    noToggle: PropTypes.bool,
    visualsType: PropTypes.string,
    diaporamaValues: PropTypes.object,
    isColAdapterInDiaporama: PropTypes.func,
    getArrivingType: PropTypes.func,
    getVisualOrDiaporamaValue: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    selectedIds: [],
    titleContent: null,
    controlsContent: null,
    actionButtons: null,
    onSearchChange: () => {},
    noDataMessage: null,
    gridNoDataMessage: '',
    visualsType: '',
    diaporamaValues: {},
    isColAdapterInDiaporama: () => {},
    getArrivingType: () => {},
    getVisualOrDiaporamaValue: () => {},
  };

  static DEFAULT_PAGE = 1;

  constructor(props) {
    super(props);

    this.state = {
      gridView: props.gridView,
      previewSize: {
        width: 200,
        height: 350,
        fontSize: 12,
      },
    };

    this.LANGUAGE_VIS_TABLE = props.languageState.VISUALS.TABLE;
    this.LANGUAGE_STATUSES = props.languageState.VISUALS.PREVIEW_MODAL.STATUSES;
    this.LANGUAGE_VISIBLE_STATUSES = props.languageState.VISUALS.PREVIEW_MODAL.VISIBLE_STATUSES;
    this.LANGUAGE_FILTERS = props.languageState.VISUALS.FILTERS_MODAL;
    this.LANGUAGE_TABLE = props.languageState.TABLE;
    this.LANGUAGE = props.languageState.VISUALS;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.gridView !== this.props.gridView) {
      this.toggleView(this.props.gridView);
    }
  }

  toggleView = (view) => {
    this.setState({
      gridView: view !== VisualsBase.VIEWS.LINE,
    });
  };

  renderSearchField = () => {
    return (
      <SearchField
        onSearch={this.onSearchChange}
        onClear={() => this.onSearchChange('')}
        defaultSearch={this.queryParams.search}
      />
    );
  };

  patchVisual = (id, visual) => patch(`${API_METHODS.VISUALS}/${id}`, {visual});

  changeValidationState = (field) => (value) => {
    const promises = [];
    this.props.selectedIds.forEach((id) => promises.push(this.patchVisual(id, {[field]: value})));

    return Promise.all(promises).then(() => this.props.updateMe());
  };

  onFiltersClick = (filters) => (data) => {
    delete filters[data];

    if (data === 'operation_in') {
      delete filters.task_in;
    }

    const newFilters = mapFilter(filters);

    this.setQueryParams(
      {
        filters: newFilters,
      },
      true,
    );
    this.props.updateMe({filters: newFilters});
  };

  onClearFiltersClick = () => {
    this.setQueryParams(
      {
        filters: {},
      },
      true,
    );
    this.props.updateMe({});
  };

  onPageChange = (params) => {
    this.setQueryParams(
      {
        ...params,
        filters: mapFilter(this.queryParams.filters),
      },
      true,
    );

    this.props.updateMe(this.queryParams);
  };

  onSortChange = (name, order) => {
    this.setQueryParams(
      {
        sort: {name, order},
        filters: mapFilter(this.queryParams.filters),
      },
      true,
    );
    this.props.updateMe();
  };

  onSearchChange = (search = '') => {
    this.setQueryParams(
      {
        search,
        page: VisualsBase.DEFAULT_PAGE,
        filters: mapFilter(this.queryParams.filters),
      },
      true,
    );
    this.props.updateMe();

    if (this.props.onSearchChange) {
      this.props.onSearchChange(search);
    }
  };

  renderToolbar = () => {
    const {gridView} = this.state;
    const {filters = {}, isEditMode} = this.queryParams;
    const filtersArray = Object.keys(filters)
      .filter((i) => typeof filters[i].length === 'undefined' || filters[i].length > 0)
      .map((i) => filters[i]);

    const {LANGUAGE_FILTERS} = this;
    const fileTypes = this.LANGUAGE_FILTERS.FILE_TYPES;
    const statuses = this.LANGUAGE_STATUSES;
    const visibleStatuses = this.LANGUAGE_VISIBLE_STATUSES;
    const orientations = this.LANGUAGE_VIS_TABLE.ORIENTATIONS;

    let arrivingTypes = filters.arrivingType || [];
    if (arrivingTypes.length) {
      const typesMap = {
        participation_interaction_type_in__OfflineInteraction: LANGUAGE_FILTERS.ARRIVING_TYPES.DEVICE,
        participation_id_null__t: LANGUAGE_FILTERS.ARRIVING_TYPES.TRACKER,
        participation_interaction_type_in__OnlineInteraction: LANGUAGE_FILTERS.ARRIVING_TYPES.WEB,
      };

      arrivingTypes = arrivingTypes.map((i) => typesMap[i]);
    }

    return (
      <div className="vis-data-grid__left-toolbar">
        <VisToolbar
          isGridView={gridView}
          filters={[
            {
              name: 'kind_in',
              applied: filters.kind_in && filters.kind_in.length > 0,
              label: `${LANGUAGE_FILTERS.FILE_TYPE_LABEL}: ${
                filters.kind_in && filters.kind_in.map((i) => fileTypes[i]).join(', ')
              }`,
            },
            {
              name: 'file_extension_in',
              applied: filters.file_extension_in && filters.file_extension_in.length > 0,
              label: `${LANGUAGE_FILTERS.FILE_EXTENTION_LABEL}: ${
                filters.file_extension_in && filters.file_extension_in.join(', ')
              }`,
            },
            {
              name: 'status_in',
              applied: filters.status_in && filters.status_in.length > 0,
              label: `${LANGUAGE_FILTERS.STATUS_LABEL}: ${
                filters.status_in && filters.status_in.map((i) => statuses[i]).join(', ')
              }`,
            },
            {
              name: 'visible_by_client_in',
              applied: filters.visible_by_client_in && filters.visible_by_client_in.length > 0,
              label: `${LANGUAGE_FILTERS.VISIBLE_LABEL}: ${
                filters.visible_by_client_in && filters.visible_by_client_in.map((i) => visibleStatuses[i]).join(', ')
              }`,
            },
            {
              name: 'orientation_in',
              applied: filters.orientation_in && filters.orientation_in.length > 0,
              label: `${LANGUAGE_FILTERS.ORIENTATION_LABEL}: ${
                filters.orientation_in && filters.orientation_in.map((i) => orientations[i]).join(', ')
              }`,
            },
            {
              name: 'arrivingType',
              applied: arrivingTypes.length > 0,
              label: `${LANGUAGE_FILTERS.ARRIVING_LABEL}: ${arrivingTypes.join(', ')}`,
            },
            {
              name: 'participation_lead_id_null',
              applied: typeof filters.participation_lead_id_null !== 'undefined',
              label: `${LANGUAGE_FILTERS.LEAD_LABEL}: ${
                filters.participation_lead_id_null === 't' ? LANGUAGE_FILTERS.LEAD_NO : LANGUAGE_FILTERS.LEAD_EXISTS
              }`,
            },
            {
              name: 'group',
              applied: typeof filters.group !== 'undefined',
              label: filters.group === 'has' ? LANGUAGE_FILTERS.GROUP_INSIDE : LANGUAGE_FILTERS.GROUP_NO,
            },
            {
              name: 'author_avatar_null',
              applied: typeof filters.author_avatar_null !== 'undefined',
              label: `${LANGUAGE_FILTERS.AVATAR_LABEL}: ${
                filters.author_avatar_null === 'f' ? LANGUAGE_FILTERS.AVATAR_EXIST : LANGUAGE_FILTERS.AVATAR_NO
              }`,
            },
            {
              name: 'comment_present',
              applied: typeof filters.comment_present !== 'undefined',
              label:
                filters.comment_present === 't' ? LANGUAGE_FILTERS.COMMENTS_COMMENTS : LANGUAGE_FILTERS.COMMENTS_NO,
            },
            {
              className: 'ellipsis-filter-label',
              name: 'operation_in',
              applied: filters.operation_in && filters.operation_in.length > 0,
              label: `${LANGUAGE_FILTERS.OPERATIONS_LABEL}: ${
                filters.operation_in &&
                filters.operation_in
                  .map((i) => i.name)
                  .map((i) => (i.length > 10 ? i.slice(0, 10) + '...' : i))
                  .join(', ')
              }`,
              title: filters.operation_in && filters.operation_in.map((i) => i.name).join(', '),
            },
            {
              className: 'ellipsis-filter-label',
              name: 'task_in',
              applied: filters.task_in && filters.task_in.length > 0,
              label: `${LANGUAGE_FILTERS.TASKS_LABEL}: ${
                filters.task_in &&
                filters.task_in
                  .map((i) => i.name)
                  .map((i) => (i.length > 10 ? i.slice(0, 10) + '...' : i))
                  .join(', ')
              }`,
              title: filters.task_in && filters.task_in.map((i) => i.name).join(', '),
            },
            {
              className: 'ellipsis-filter-label',
              name: 'group_in',
              applied: filters.group_in && filters.group_in.length > 0,
              label: `${LANGUAGE_FILTERS.GROUPS_LABEL}: ${
                filters.group_in &&
                filters.group_in
                  .map((i) => i.name)
                  .map((i) => (i.length > 10 ? i.slice(0, 10) + '...' : i))
                  .join(', ')
              }`,
              title: filters.group_in && filters.group_in.map((i) => i.name).join(', '),
            },
          ]}
          textLabels={{
            filtersButton: `${this.LANGUAGE_TABLE.FILTERS_BUTTON} (${filtersArray.length})`,
            resultsNum: this.LANGUAGE_TABLE.RESULTS,
            dataSelector1: this.LANGUAGE_VIS_TABLE.OUT_OF_TEXT,
            dataSelector2: this.LANGUAGE_VIS_TABLE.FILES_SELECTED_TEXT,
            buttonValidate: this.LANGUAGE_VIS_TABLE.VALIDATE_BUTTONS_TITLE,
            buttonVisible: this.LANGUAGE_VIS_TABLE.VISIBLE_BUTTONS_TITLE,
            clearFilters: this.LANGUAGE_VIS_TABLE.CLEAR_FILTERS_BUTTON,
          }}
          showActionBar={this.props.selectedIds.length > 0 && !isEditMode}
          searchField={this.renderSearchField()}
          resultsNum={this.props.meta.total_count}
          statusActionButtons={{
            onValidateClick: this.changeValidationState('status'),
            onVisibleClick: this.changeValidationState('visible_by_client'),
          }}
          onShowFilterClick={this.props.onShowFiltersClick}
          onClearFiltersClick={this.onClearFiltersClick}
          onFiltersClick={this.onFiltersClick(filters)}
          actionButtons={this.props.actionButtons}
        />
      </div>
    );
  };

  handleSliderChange = (sliderProgress) => {
    const BASE = 200;
    const additionalWidth = Math.pow(sliderProgress, 0.9);

    const newSize = {
      width: BASE + additionalWidth,
      height: (BASE + additionalWidth) * 1.75,
      fontSize: 12 + additionalWidth * 0.05,
    };

    this.setState({
      previewSize: {
        ...newSize,
      },
    });
  };

  renderData = () => {
    const {
      onLeadClick,
      onPreviewClick,
      onSettingsClick,
      updateMe,
      selectedIds,
      onSelect,
      gridNoDataMessage,
      meta,
      data,
      showClear,
      visualsType,
      diaporamaValues,
      isColAdapterInDiaporama,
      getArrivingType,
      getVisualOrDiaporamaValue,
    } = this.props;

    const {gridView, previewSize} = this.state;

    return (
      <div className="visuals__data-content">
        {gridView ? (
          <div className="visuals__grid-view">
            <VisGridView
              visualsType={visualsType}
              previewSize={previewSize}
              toolbar={this.renderToolbar()}
              showClear={showClear}
              onPageChange={this.onPageChange}
              onPreviewClick={onPreviewClick}
              onSelect={onSelect}
              noDataMessage={gridNoDataMessage}
              selectedIds={selectedIds}
              updateMe={updateMe}
              isAdmin={this.props.isAdmin}
              meta={meta}
              data={data}
              diaporamaValues={diaporamaValues}
              isColAdapterInDiaporama={isColAdapterInDiaporama}
              getArrivingType={getArrivingType}
              getVisualOrDiaporamaValue={getVisualOrDiaporamaValue}
            />
          </div>
        ) : (
          <VisDataGrid
            onLeadClick={onLeadClick}
            showClear={this.props.showClear}
            onPreviewClick={onPreviewClick}
            onSettingsClick={onSettingsClick}
            onSelect={onSelect}
            clientId={this.props.clientId}
            selectedIds={selectedIds}
            onPageChange={this.onPageChange}
            onSortChange={this.onSortChange}
            updateMe={updateMe}
            toolbar={this.renderToolbar()}
            perPage={+this.queryParams.perPage}
            isAdmin={this.props.isAdmin}
            meta={meta}
            data={data}
            diaporamaValues={diaporamaValues}
            isColAdapterInDiaporama={isColAdapterInDiaporama}
            getArrivingType={getArrivingType}
            getVisualOrDiaporamaValue={getVisualOrDiaporamaValue}
            sort={this.queryParams.sort}
          />
        )}
      </div>
    );
  };

  render() {
    const {titleContent, controlsContent, noDataMessage, noToggle} = this.props;

    const {gridView} = this.state;

    const showNoDataMessage = !!noDataMessage;

    return (
      <div className="theme-color-13">
        <div className="page__title-block flex-container flex-justify-between flex-align-center">
          {titleContent}
          <div className="visuals__view-controls">
            {!showNoDataMessage && gridView && (
              <Slider defaultValue={0} className="visuals__slider slider" onChange={this.handleSliderChange} />
            )}
            {!showNoDataMessage && !noToggle && (
              <ViewToggle
                className="visuals__view-toggle"
                defaultActive={gridView ? VisualsBase.VIEWS.PREVIEW : VisualsBase.VIEWS.LINE}
                onClick={this.toggleView}
              >
                <ViewToggle.Item name={VisualsBase.VIEWS.LINE}>
                  <Icon name="mode-line" />
                </ViewToggle.Item>
                <ViewToggle.Item name={VisualsBase.VIEWS.PREVIEW}>
                  <Icon name="mode-preview" />
                </ViewToggle.Item>
              </ViewToggle>
            )}
            {controlsContent}
          </div>
        </div>
        {!showNoDataMessage ? this.renderData() : noDataMessage}
      </div>
    );
  }
}

export default VisualsBase;
