import React, {useCallback} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {changeAutotaskValidated} from 'client/ducks/autotask/actions';
import {selectAvailableDomains} from 'client/ducks/available-domains/selectors';
import {selectClientDomains} from 'client/ducks/client-domains/selectors';

import AppButton from 'client/common/buttons';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import {getUrlData} from 'client/components/diy-operation/modals/diy-op-publishing-url-modal/helper';
import {Autotask} from 'client/models/autotask/types';
import {isOperationArchived} from 'client/models/operations/helpers';
import {Operation} from 'client/models/operations/types';
import {ApiDispatch} from 'client/types';

import cssModule from './diy-op-main-block-validate.module.scss';

const b = bem('diy-op-main-block-validate', {cssModule});

type DiyOpMainBlockValidateProps = {
  fetchOperation: () => void;
  fetchAutotask: () => void;
  autotask: Autotask;
  operation: Operation;
};

const DiyOpMainBlockValidate: React.FC<DiyOpMainBlockValidateProps> = (props) => {
  const {autotask, operation, fetchOperation, fetchAutotask} = props;
  const dispatch: ApiDispatch = useDispatch();
  const [showConfirm, toggleConfirm] = useToggle(false);
  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_MAIN_BLOCK.DIY_OP_MAIN_BLOCK_VALIDATE');

  const interfaceId = operation?.client_interface?.id;

  const availableDomains = useSelector(selectAvailableDomains);
  const clientDomains = useSelector(selectClientDomains);
  const clientDomainInfo = getUrlData(clientDomains, availableDomains, interfaceId);

  const handleConfirm = useCallback(async () => {
    await dispatch(changeAutotaskValidated(autotask?.id, true));
    fetchAutotask();
    fetchOperation(); // operation.editable param checks for autotask.validated too
    toggleConfirm();
  }, [autotask?.id, dispatch, fetchAutotask, fetchOperation, toggleConfirm]);

  const title = autotask?.validated ? lang.INVALIDATED_TITLE : lang.VALIDATED_TITLE;
  const label = autotask?.validated ? lang.VALIDATED : lang.VALIDATE;
  const url = clientDomainInfo?.fullUrl;

  return (
    <div className={b()}>
      <AppButton
        onClick={toggleConfirm}
        iconName="publish"
        className={b('button')}
        color="clients"
        label={label}
        disabled={!url || autotask?.validated || isOperationArchived(operation)}
      />

      <ConfirmationModal
        title={title}
        show={showConfirm}
        onClose={toggleConfirm}
        onConfirm={handleConfirm}
        messageClassName={b('message')}
        message={lang.SUPPORTING_TEXT}
        buttonConfirm={{
          label: lang.CONFIRM_MODAL,
          color: 'clients',
        }}
        buttonCancel={{
          label: lang.CANCEL_MODAL,
          color: 'clients',
          transparent: true,
        }}
        clientSide
      />
    </div>
  );
};

export default DiyOpMainBlockValidate;
