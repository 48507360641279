import React, {Component} from 'react';

import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import pure from 'client/services/hocs/pure';
import {required} from 'client/services/validator';

import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';

import {TranslationJsx} from 'client/models/language/types';

import './at-set-item.scss';

function checkMapItem(obj) {
  return isEmpty(obj) || !obj.type || (obj.fieldType !== 'opt_in' && !obj.column);
}

class AtSetItem extends Component {
  static defaultProps = {
    className: '',
  };

  static propTypes = {
    className: PropTypes.string,
    formats: PropTypes.arrayOf(PropTypes.object).isRequired,
    labels: PropTypes.shape({
      optinLabel: TranslationJsx,
      boolTrueLabel: TranslationJsx,
      boolFalseLabel: TranslationJsx,
      arrayLabel: TranslationJsx,
      formatLabel: TranslationJsx,
      itemNameLabel: TranslationJsx,
    }).isRequired,
    placeholders: PropTypes.shape({
      formatPlaceholder: TranslationJsx,
      namePlaceholder: TranslationJsx,
      booleanTruePlaceholder: TranslationJsx,
      booleanFalsePlaceholder: TranslationJsx,
    }).isRequired,
    errorMessages: PropTypes.shape({
      requiredFormatError: TranslationJsx,
      requiredNameError: TranslationJsx,
      uniqueNameError: TranslationJsx,
      alreadySavedNameError: TranslationJsx,
    }),
    onEditClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    member: PropTypes.string.isRequired,
    isBoolean: PropTypes.bool.isRequired,
    isArray: PropTypes.bool.isRequired,
    onFormatChange: PropTypes.func.isRequired,
    onKindChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    mappingItems: PropTypes.array,
    tooltipMessage: TranslationJsx.isRequired,
    name: PropTypes.string,
    fieldNames: PropTypes.arrayOf(PropTypes.string),
  };

  static ignoredProps = ['onEditClick', 'onDeleteClick', 'onFormatChange', 'onKindChange'];

  constructor(props) {
    super(props);

    this.rules = {
      kind: required(props.errorMessages.requiredFormatError),
      name: [
        required(props.errorMessages.requiredNameError),
        (value, {items: formValues}) => {
          // const index = Number(props.member.match(/\d+/g)[0]);
          // const isFieldPristine = props.fieldNames ? value === props.fieldNames[props.index] : true;
          // const {fieldNames = []} = props;
          const names = formValues.map((field) => field.name);
          const indexFirstFound = names.indexOf(value);

          // if (!isFieldPristine && fieldNames.includes(value)) {
          //   return props.errorMessages.alreadySavedNameError;
          // }

          return names.includes(value, indexFirstFound + 1) ? props.errorMessages.uniqueNameError : null;
        },
      ],
    };
  }

  renderMappingItems = (item, index) => {
    return checkMapItem(item) ? null : (
      <li className="at-set-item__map-item" key={index}>
        <div className="at-set-item__map-text">
          {item.fieldType === 'opt_in' ? item.type.label : `${item.type.label} ${item.column.label}`}
        </div>
        <div className="at-set-item__map-icons">
          {item.required && (
            <span className="icon-new-wrapper-circle at-set-item__map-icon theme-color-8">
              <Icon name="asterix" />
            </span>
          )}
          {item.unique && (
            <span className="icon-new-wrapper-circle at-set-item__map-icon theme-color-8">
              <Icon name="matching" />
            </span>
          )}
        </div>
      </li>
    );
  };

  hasOptIn = () => {
    return (
      this.props.mappingItems &&
      this.props.mappingItems.some((item) => {
        return item.fieldType === 'opt_in';
      })
    );
  };

  render() {
    const {
      labels,
      className,
      formats,
      onEditClick,
      onDeleteClick,
      placeholders,
      member,
      isBoolean,
      isArray,
      onKindChange,
      index,
      tooltipMessage,
    } = this.props;
    const {rules} = this;

    const popoverOldPassword = <span id="old-password">{tooltipMessage}</span>;

    return (
      <div className={`at-set-item ${className}`}>
        <div className="at-set-item__col-1">
          <div className="at-set-item__item-row">
            <div className="at-set-item__field at-set-item__field--size-1">
              <Field
                simpleValue
                label={labels.formatLabel}
                placeholder={placeholders.formatPlaceholder}
                cssModifier="select--no-min-height"
                name={`${member}.kind`}
                type="select"
                options={formats}
                searchable={false}
                component={fieldTemplate}
                onChange={() => {
                  onKindChange(index);
                }}
                validate={rules.kind}
                scrollBarProps={{
                  autoHeightMax: 250,
                }}
              />
            </div>
            <div className="at-set-item__item-checkbox">
              <Field
                label={labels.arrayLabel}
                cssModifier="select--no-min-height"
                name={`${member}.array`}
                type="checkbox"
                onChange={() => {
                  onKindChange(index);
                }}
                component={fieldTemplate}
              />
            </div>
            {isArray && (
              <Popover trigger="both" position="bottom" overlay={popoverOldPassword}>
                <div className="at-set-item__info-icon">
                  <Icon name="info" />
                </div>
              </Popover>
            )}
          </div>
          {isBoolean && (
            <div className="at-set-item__item-row">
              <div className="at-set-item__field at-set-item__field--size-1">
                <Field
                  label={labels.boolTrueLabel}
                  placeholder={placeholders.booleanTruePlaceholder}
                  cssModifier="input--no-min-height"
                  name={`${member}.value_as_true`}
                  type="text"
                  component={fieldTemplate}
                />
                <Field
                  label={labels.boolFalseLabel}
                  placeholder={placeholders.booleanFalsePlaceholder}
                  cssModifier="input--no-min-height"
                  name={`${member}.value_as_false`}
                  type="text"
                  component={fieldTemplate}
                />
              </div>
            </div>
          )}
          <div className="at-set-item__item-row">
            <div className="at-set-item__field">
              <Field
                label={labels.itemNameLabel}
                placeholder={placeholders.namePlaceholder}
                cssModifier="input--no-min-height"
                name={`${member}.name`}
                type="text"
                component={fieldTemplate}
                validate={rules.name}
              />
            </div>
            {this.hasOptIn() && (
              <div className="at-set-item__optin-label">
                {labels.optinLabel}
                <Icon name="star" className="theme-color-8 at-set-item__optin-icon" />
              </div>
            )}
          </div>
          <div className="at-set-item__item-row">
            <div className="at-set-item__field">
              <Field
                label={labels.itemReadableNameLabel}
                cssModifier="input--no-min-height"
                name={`${member}.readable_name`}
                type="text"
                component={fieldTemplate}
              />
            </div>
          </div>
        </div>
        <div className="at-set-item__col-2">
          {this.props.mappingItems && (
            <div className="content-group">
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMin: 130,
                }}
              >
                <ul className="at-set-item__map-list">{this.props.mappingItems.map(this.renderMappingItems)}</ul>
              </CustomScrollbars>
            </div>
          )}
        </div>
        <div className="at-set-item__col-3">
          <button
            className="button button--circle button--bg-7 at-set-item__act-btn"
            type="button"
            onClick={onEditClick}
          >
            <Icon name="edit" className="button__icon" width={20} height={20} />
          </button>
          <button
            className="button button--circle button--bg-7 at-set-item__act-btn"
            type="button"
            onClick={onDeleteClick}
          >
            <Icon name="trash" className="button__icon" width={17} height={19} />
          </button>
        </div>
      </div>
    );
  }
}

export default pure(AtSetItem, AtSetItem.ignoredProps);
