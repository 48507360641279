import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import {ApiAction} from 'client/types';

import types from './types';

export const updateMembership: ApiAction = (id, body = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}/${API_METHODS.MEMBERSHIPS}/${id}`,
      method: 'PATCH',
      types: [types.UPDATE_MEMBERSHIP_REQUEST, types.UPDATE_MEMBERSHIP_SUCCESS, types.UPDATE_MEMBERSHIP_FAILED],
      body,
    });
};
export const createMembership: ApiAction = (body = {}, type) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}/${type}_memberships`,
        method: 'POST',
        types: [types.CREATE_MEMBERSHIP_REQUEST, types.CREATE_MEMBERSHIP_SUCCESS, types.CREATE_MEMBERSHIP_FAILED],
        body,
      },
      true,
    );
};
