import {blobToBase64} from 'client/services/blobToBase64';
import {convertUrlToFile} from 'client/services/convertUrlToFile';

const shouldExcludeKey = (key: string): boolean => {
  return key.split('_').includes('id');
};

const isBlobConvertible = (key: string, value: any): boolean => {
  return typeof value === 'string' && !key.startsWith('visual_type');
};

const buildBodyEntry = async (key: string, value: any) => {
  let newValue = value;
  if (isBlobConvertible(key, value)) {
    newValue = await convertUrlToFile(value);
  }

  return key.startsWith('visual_type') ? newValue : {data: await blobToBase64(newValue)};
};

export default async (values: Record<string, any>, createTicket: boolean) => {
  if (!createTicket) {
    return {visual_type: 'none'};
  }

  const body: Record<string, any> = {};

  const data = Object.entries(values || {}).map(async ([key, value]) => {
    if (value && !shouldExcludeKey(key)) {
      body[key] = await buildBodyEntry(key, value);
    }
  });

  await Promise.all(data);

  return body;
};
