import get from 'lodash/get';

export function selectImportReport(state) {
  return state.leadsImport.importReport;
}

export function selectImportedLeads(state) {
  return state.leadsImport.importedLeads;
}

export function selectImportReportId(state) {
  return get(state.lead.importResult, 'lead_import_report.id');
}

export const selectApiLeadImports = (state) => {
  return state.leadsImport.apiLeadImports;
};
