import React, {Component} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {connect} from 'react-redux';

import {getPrivacyPolicies} from 'client/ducks/privacy-policies/actions';
import {getLastPrivacyPolicy} from 'client/ducks/privacy-policies/selectors';

import Modal from 'client/common/modals/modal';

// components
import CustomScrollbars from 'client/components/common/custom-scrollbars';

import './privacy-policy-third-party-modal.scss';

class PrivacyPolicyThirdPartyModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    className: PropTypes.string,
    buttonConfirmClass: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    getPrivacyPolicies: PropTypes.func.isRequired,
    privacyPolicy: PropTypes.object,
  };

  static defaultProps = {
    show: false,
  };

  componentDidMount() {
    this.getPrivacyPolicy();
  }

  getPrivacyPolicy = () => {
    const queryParams = {
      q: {
        s: 'created_at desc',
      },
    };
    this.props.getPrivacyPolicies(queryParams);
  };

  render() {
    const {onClose, onCancel, onConfirm, show, lang, className, buttonConfirmClass, privacyPolicy} = this.props;

    return (
      <Modal
        show={show}
        dialogClassName={classNames('modal-window--width-1', 'add-new-social-account-modal', className)}
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.CLOSE_BUTTON}
      >
        <CustomScrollbars
          scrollbarProps={{
            autoHeightMax: 295,
          }}
        >
          <div className="privacy-policy-third-party-modal__policy-text">
            <ReactMarkdown escapeHtml={false}>{privacyPolicy ? privacyPolicy.text : '...'}</ReactMarkdown>
          </div>
        </CustomScrollbars>
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onCancel}>
            {lang.CANCEL_BUTTON}
          </button>
          <button className={classNames('button', 'modal-window__footer-btn', buttonConfirmClass)} onClick={onConfirm}>
            {lang.ACCEPT_BUTTON}
          </button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  privacyPolicy: getLastPrivacyPolicy(state),
  lang: state.languageState.payload.AGENCY.PRIVACY_POLICY_THIRD_PARTY_MODAL,
});

const mapDispatchToProps = {
  getPrivacyPolicies,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyThirdPartyModal);
