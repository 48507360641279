import React, {useState} from 'react';

import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams, useReduxFetch} from 'client/services/hooks';

import {getAgencyClients} from 'client/ducks/agencies/actions';
import {selectAgencyClients} from 'client/ducks/agencies/selectors';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {selectCurrentLanguage} from 'client/ducks/language/selectors';

import AppButton from 'client/common/buttons/app-button';
import SearchInput from 'client/common/inputs/search-input';
import PaginationBar from 'client/common/paginations/pagination-bar';

import ClientTable from 'client/components/common/client-table';

import AgencyCreateClientModal from 'client/components/agencies/modals/agency-client-modal';
import PerPage from 'client/components/tables/common/per-page';
import {Client} from 'client/models/client/types';

import AgencyClientsActions from './agency-clients-actions';
import {getFetchParams} from './helpers';

import cssModule from './agency-clients-list.module.scss';

const b = bem('agency-clients-list', {cssModule});

type AgencyClientsListProps = {
  agencyId: number;
};

const AgencyClientsList: React.FC<AgencyClientsListProps> = (props) => {
  const {agencyId} = props;
  const [showAgencyCreateClientModal, toggleAgencyCreateClientModal] = useToggle(false);

  const lang = useLanguage('AGENCIES.LISTS.AGENCY_CLIENTS_LIST');
  const currentLanguage = useSelector(selectCurrentLanguage);
  const client = useSelector(selectCurrentClient);

  const [queryParams, setQueryParams] = useQueryParams(null, {page: 1, perPage: 10});

  const [editedClient, setEditedClient] = useState<Client | null>(null);

  const {data, loading, fetch} = useReduxFetch({
    action: getAgencyClients,
    selector: selectAgencyClients,
    actionArgs: getFetchParams(agencyId, currentLanguage, queryParams),
  });

  const handleCloseClientModal = () => {
    setEditedClient(null);
    toggleAgencyCreateClientModal(false);
  };

  return (
    <div className={b()}>
      <header className={b('header')}>
        <h1 className={b('title')}>{lang.CLIENTS}</h1>
        <div className={b('header-right')}>
          <SearchInput
            className={b('search')}
            onSearch={(search) => setQueryParams({search, page: 1})}
            searchDefault={queryParams.search}
          />
          {client.client_management && (
            <AppButton
              iconName="plus-simple"
              label={lang.ADD_CLIENT}
              color="light-clients"
              size="medium"
              onClick={toggleAgencyCreateClientModal}
            />
          )}
        </div>
      </header>
      <ClientTable
        data={data?.items || []}
        columns={[
          {
            name: 'name',
            path: 'name',
            label: lang.COMPANY_NAME,
          },
          {
            name: 'brand_name',
            path: 'brand_name',
            label: lang.BRAND_NAME,
          },
          {
            name: 'company_contact',
            path: `poc_membership.client_user_full_name_with_title_${currentLanguage}`,
            label: lang.COMPANY_CONTACT,
          },
          {
            name: 'agency_contact',
            path: `poc_agency_membership.client_user_full_name_with_title_${currentLanguage}`,
            label: lang.AGENCY_CONTACT,
          },
          {
            name: 'actions',
            width: '135px',
            render: ({item}) => (
              <AgencyClientsActions client={item} onDelete={fetch} agencyId={agencyId} onEdit={setEditedClient} />
            ),
          },
        ]}
        loading={loading}
      />
      <div className={b('pagination')}>
        <PaginationBar
          data={data?.items || []}
          onPageChange={setQueryParams}
          currentPage={data?.meta?.current_page}
          totalPages={data?.meta?.total_pages}
          totalItems={data?.meta?.total_count}
          perPage={queryParams.perPage}
        />
        <PerPage
          currentValue={queryParams.perPage.toString()}
          onChange={setQueryParams}
          items={['10', '25', '50', '100']}
        />
      </div>
      {(showAgencyCreateClientModal || !!editedClient) && (
        <AgencyCreateClientModal
          onClose={handleCloseClientModal}
          agencyId={agencyId}
          initialData={editedClient}
          onSubmit={fetch}
        />
      )}
    </div>
  );
};

export default AgencyClientsList;
