import {INSTORE_DASHBOARD_BLOCK_TYPES} from 'client/models/instore-dashboard/constants';

export default (values, {dashboardId, position, kpiId}) => ({
  instore_dashboard_block: {
    instore_dashboard_id: dashboardId,
    position: position,
    block_type: values.block_type,
    task_kpi_dashboard_blocks: [{task_kpi_id: values.task_kpis, ...(kpiId && {id: kpiId})}],
    ...(values.block_type === INSTORE_DASHBOARD_BLOCK_TYPES.STATS_CONSOLIDATED && {
      consolidation: values.consolidation,
      consolidation_mode: values.consolidation_mode,
    }),
  },
});
