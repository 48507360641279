import React from 'react';

import PropTypes from 'prop-types';

import Field from '../field';

const TextAreaField = (props) => <Field {...props} />;

TextAreaField.propTypes = {
  ...Field.propTypes,
  type: PropTypes.string,
};

TextAreaField.defaultProps = {
  ...Field.defaultProps,
  type: 'textarea',
};

export default TextAreaField;
