import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getLeadsStatistic(queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEADS_STATISTIC}`,
        method: 'GET',
        types: [types.GET_LEADS_STATISTIC_REQUEST, types.GET_LEADS_STATISTIC_SUCCESS, types.GET_LEADS_STATISTIC_ERROR],
        queryParams,
      },
      true,
    );
  };
}

export function getLeadsTimeline(queryParams) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.LEADS_TIMELINE}`,
      method: 'GET',
      types: [types.GET_LEADS_TIMELINE_REQUEST, types.GET_LEADS_TIMELINE_SUCCESS, types.GET_LEADS_TIMELINE_ERROR],
      queryParams,
    });
}

export function getLeadsOnlineUsers(queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.ONLINE_USERS_STATISTIC}`,
        method: 'GET',
        types: [
          types.GET_LEADS_ONLINE_USERS_REQUEST,
          types.GET_LEADS_ONLINE_USERS_SUCCESS,
          types.GET_LEADS_ONLINE_USERS_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}

export function getLeadsPerCampaign(queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.CAMPAIGN_LEADS_STATISTIC}`,
        method: 'GET',
        types: [
          types.GET_LEADS_PER_CAMPAIGN_REQUEST,
          types.GET_LEADS_PER_CAMPAIGN_SUCCESS,
          types.GET_LEADS_PER_CAMPAIGN_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}

export function getLeadsClient(id, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENTS}/${id}`,
      method: 'GET',
      types: [types.GET_LEADS_CLIENT_REQUEST, types.GET_LEADS_CLIENT_SUCCESS, types.GET_LEADS_CLIENT_ERROR],
      noCache: true,
      queryParams,
    });
}
