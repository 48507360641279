export const DIY_OPERATION_STATUSES = {
  ACTIVE: 'active',
  FINISHED: 'finished',
  ON_CONFIGURATION: 'on_configuration',
  UNVALIDATED: 'Unvalidated',
  VALIDATED: 'Validated',
};

export const DIY_CONFIRMATION_TYPES = {
  PUBLISH: 'publish',
  PUBLISH_ONLINE: 'publish_online',
  PUBLISH_DEVICE: 'publish_device',
  UPDATE: 'update',
  UNPUBLISH: 'unpublish',
  VALIDATED: 'validated',
  ARCHIVE: 'archive',
  FINISHED: 'finished',
};

export const DIY_OPERATION_TYPES_WITH_URL = {
  FULL_ONLINE: 'full_online',
  MANAGED_ONLINE: 'managed_online',
  MANAGED_HYBRID: 'managed_hybrid',
};

export const DIY_OPERATION_TYPES_DEVICE = {
  FULL_DEVICE: 'full_device',
  MANAGED_DEVICE: 'managed_device',
};

export const DIY_OPERATION_TYPES = {
  ...DIY_OPERATION_TYPES_WITH_URL,
  ...DIY_OPERATION_TYPES_DEVICE,
};

export const DIY_OPERATION_STATES = {
  CREATED: 'created',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
  REPUBLISHED: 'republished',
  VALIDATEDED: 'validated',
  FINISHED: 'finished',
};

export const DIY_OPERATION_CONFIRMATION_MODAL_TYPE = {
  WEB_APP: 'web_app',
  DEVICE: 'device',
  VALIDATED: 'validated',
  INVALIDATED: 'invalidated',
};
