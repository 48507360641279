import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';

import cssModule from './catalog-templates-empty-panel.module.scss';

const b = bem('catalog-templates-empty-panel', {cssModule});

const CatalogTemplatesEmptyPanel = () => {
  const lang = useLanguage('CATALOG_TEMPLATES.PANELS.EMPTY_PANEL');

  return (
    <div className={b()}>
      <Icon name="empty-state" className={b('icon')} />
      <span className={b('no-records')}>{lang.NO_RESULT_TEXT}</span>
    </div>
  );
};

export default CatalogTemplatesEmptyPanel;
