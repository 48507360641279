import React, {useCallback, useEffect, useMemo, useState} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect, useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {useMount, usePrevious} from 'react-use';
import {reduxForm, SubmissionError} from 'redux-form';
import {Userpilot} from 'userpilot';

import {getValueIfExists} from 'client/services/helpers';

import {setDoubleFactorAuthOptions} from 'client/ducks/settings/actions';
import {getSSOAuthInfo, setCallbackError} from 'client/ducks/sso-auth-info/actions';
import {selectAuthSsoInfo, selectAuthSsoLink} from 'client/ducks/sso-auth-info/selectors';
import {expirePassword, authenticate} from 'client/ducks/user/actions';
import {isFirstClientLogin} from 'client/ducks/user/helper';
import {selectCurrentUser, selectIsAccessDenied} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Timer from 'client/common/timer';

import {TranslationJsx} from 'client/models/language/types';

import validate from './validate';

import AuthButton from '../components/auth-button';
import AuthError from '../components/auth-error';
import AuthField from '../components/auth-field';
import AuthLink from '../components/auth-link';

const OPTIONAL_STATUSES = {
  clientDeactivated: 'CLIENT_DEACTIVATED',
};

const AuthLogin = ({lang, handleSubmit, history, authWithSSO, error, submitting, pristine, valid}) => {
  const isAccessDenied = useSelector(selectIsAccessDenied);
  const user = useSelector(selectCurrentUser);
  const authLink = useSelector(selectAuthSsoLink);
  const prevAuthLink = usePrevious(authLink);
  const callbackError = useSelector(selectAuthSsoInfo).callbackError;
  const dispatch = useDispatch();
  const [blockedTemporary, setBlockedTemporary] = useState(false);
  const [blockedPermanently, setBlockedPermanently] = useState(false);
  const [blockSeconds, setBlockSeconds] = useState(0);
  const [showSuggestionClassicLogin, setShowSuggestionClassicLogin] = useState(false);

  useEffect(() => {
    if (user?.id) {
      history.push('/');
    }
    if (!prevAuthLink && authLink) {
      window.location.href = authLink;
    }
  }, [prevAuthLink, authLink, history, user?.id]);

  useMount(() => {
    if (authWithSSO && callbackError) {
      setShowSuggestionClassicLogin(true);
    }
  });

  const unblock = () => {
    setBlockedPermanently(false);
    setBlockedTemporary(false);
  };

  const errorText = useMemo(() => {
    switch (true) {
      case blockedTemporary:
        return <Timer seconds={blockSeconds} onFinish={unblock} text={lang.BLOCKED_TEMPORARY} />;
      case isAccessDenied:
        return lang.ACCESS_DENIED;
      default:
        return error;
    }
  }, [blockSeconds, blockedTemporary, error, isAccessDenied, lang.ACCESS_DENIED, lang.BLOCKED_TEMPORARY]);

  const goBackToClassicLogin = async () => {
    await dispatch(setCallbackError());
    setShowSuggestionClassicLogin(false);
    history.push(CLIENT_PAGES.LOGIN);
  };

  const getRedirectPath = useCallback((nextUser, locationObject) => {
    const requestedLocation = getValueIfExists(locationObject, ['state', 'requestedLocation']);

    return requestedLocation || (isFirstClientLogin(nextUser) && CLIENT_PAGES.WELCOME) || CLIENT_PAGES.HOME;
  }, []);

  const login = useCallback(
    ({email, password, rememberMe}) => {
      const locationObject = history.location; // preserve the location state in the variable to avoid requestedLocation loose during redirect after login
      return dispatch(authenticate({user: {email, password, rememberMe: !!rememberMe}})).then((response) => {
        if (response.error) {
          const lockedBy = get(response, 'payload.response.locked_by');
          const responseBlockSeconds = get(response, 'payload.response.unlock_after');
          const responseBlockedPermanently = get(response, 'payload.response.locked_permanently');
          const isOTPCodeNeeded = Boolean(get(response, 'payload.response.exception'));

          if (response.payload.status === 422 && isOTPCodeNeeded) {
            const OTPOptions = get(response, 'payload.response.payload');

            if (get(response, 'payload.response.optionalStatus') === OPTIONAL_STATUSES.clientDeactivated) {
              throw new SubmissionError({_error: lang.DEACTIVATED_FOR_ALL_CLIENTS});
            }

            dispatch(
              setDoubleFactorAuthOptions({
                email,
                password,
                options: {
                  ...OTPOptions,
                },
              }),
            );
            history.push(CLIENT_PAGES.DOUBLE_FACTOR_AUTH);
            return;
          }

          if (lockedBy === 'expired_password') {
            dispatch(expirePassword());
            history.push(CLIENT_PAGES.PASSWORD_RECOVERY);
            return;
          }

          if (responseBlockSeconds) {
            setBlockSeconds(responseBlockSeconds);
            setBlockedTemporary(!!responseBlockSeconds);
            return;
          }

          if (responseBlockedPermanently) {
            setBlockedPermanently(true);
            return;
          }

          if (response.payload.status === 401) {
            if (get(response, 'payload.response.optionalStatus') === OPTIONAL_STATUSES.clientDeactivated) {
              throw new SubmissionError({_error: lang.DEACTIVATED_FOR_ALL_CLIENTS});
            }

            throw new SubmissionError({_error: lang.INVALID_CREDENTIAL});
          }

          if (response.meta && response.meta.status === 0) {
            throw new SubmissionError({_error: lang.NETWORK_ERROR});
          }
        }

        const responseUser = get(response, 'payload.user');
        const path = getRedirectPath(responseUser, locationObject);
        Userpilot.identify(responseUser.id, responseUser);

        history.push(path);
      });
    },
    [dispatch, getRedirectPath, history, lang],
  );

  const openAuchanLoginWindow = () => {
    dispatch(setCallbackError());
    dispatch(getSSOAuthInfo());
  };

  const loginDisabled = !!(submitting || !valid || pristine || blockedTemporary || blockedPermanently);

  return (
    <>
      <form noValidate="noValidate" onSubmit={handleSubmit(login)}>
        {!authWithSSO ? (
          <>
            <AuthField
              tabIndex={1}
              label={lang.EMAIL_LABEL}
              name="email"
              placeholder={lang.EMAIL_PLACEHOLDER}
              disabled={blockedTemporary}
              errorMessage={errorText && ' '}
            />
            <AuthField
              tabIndex={2}
              label={lang.PASSWORD_LABEL}
              name="password"
              placeholder={lang.PASSWORD_PLACEHOLDER}
              errorMessage={errorText}
              disabled={blockedTemporary}
              type="password"
              login
            />
            {!blockedPermanently && (
              <>
                <AuthField tabIndex={3} type="checkbox" name="rememberMe" label={lang.REMEMBER_ME_LABEL} />
                <AuthLink
                  additional
                  to={CLIENT_PAGES.PASSWORD_RECOVERY}
                  disabled={blockedTemporary}
                  tooltip={blockedTemporary && lang.BLOCK_TOOLTIP}
                  label={lang.FORGOT_PASSWORD}
                />
              </>
            )}
            <AuthButton submit label={lang.LOG_IN_BUTTON} disabled={loginDisabled} />
          </>
        ) : (
          <AuthButton label={lang.LOG_IN_SSO_BUTTON} onClick={openAuchanLoginWindow} />
        )}
        {blockedPermanently && (
          <div>
            <AuthError>{lang.ACCOUNT_BLOCKED}</AuthError>
          </div>
        )}
        {callbackError && <AuthError>{lang.SSO_CALLBACK_ERRORS[callbackError]}</AuthError>}
      </form>

      <ConfirmationModal
        clientSide
        show={showSuggestionClassicLogin}
        onClose={() => setShowSuggestionClassicLogin(false)}
        subtitle={lang.SUGGESTION_CLASSIC_LOGIN}
        buttonConfirm={{
          label: lang.OK_CLASSIC_LOGIN,
          color: 'clients',
          onClick: goBackToClassicLogin,
        }}
      />
    </>
  );
};

AuthLogin.displayName = 'AuthLogin';
AuthLogin.formName = 'AuthLoginForm';

AuthLogin.propTypes = {
  authWithSSO: PropTypes.bool,
  error: TranslationJsx,

  submitFailed: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

AuthLogin.defaultProps = {
  error: null,
  authWithSSO: false,
};

const loginForm = reduxForm({
  form: AuthLogin.formName,
  validate,
})(AuthLogin);
loginForm.displayName = 'AuthLogin';

export default withRouter(
  connect((state) => ({
    lang: state.languageState.payload.AUTH.LOGIN,
  }))(loginForm),
);
