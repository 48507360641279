import React, {Component} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getItem, setItem} from 'client/services/localStorage';

import SelectDropdown from 'client/common/selects/select-dropdown';

import ScrollableLineChart from 'client/components/common/charts/scrollable-line-chart';
import CheckboxControl from 'client/components/common/controls/checkbox-control';
import DatepickerRange from 'client/components/common/controls/datepicker-range';
import Spinner from 'client/components/common/spinner';

import {TranslationJsx} from 'client/models/language/types';

import './timeline-chart.scss';

class TimelineChart extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    prefix: PropTypes.string.isRequired,
    uid: PropTypes.number.isRequired,
    onFetch: PropTypes.func.isRequired,
    initialFrom: PropTypes.string,
    initialTo: PropTypes.string,
    points: PropTypes.array.isRequired,
    lines: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        label: TranslationJsx,
        borderColor: PropTypes.string,
        data: PropTypes.array,
      }),
    ).isRequired,
    loadingColor: PropTypes.string,
  };

  static defaultProps = {
    initialFrom: null,
    initialTo: null,
    loadingColor: 'primary',
  };

  static PRECISIONS = {
    MONTH: 'month',
    DAY: 'day',
    HOUR: 'hour',
    MINUTE: 'minute',
  };

  static PERIOD_TYPES = {
    MORE_THAN_MONTH: 'more_than_month',
    LESS_THAN_MONTH: 'less_than_month',
    LESS_THAN_DAY: 'less_than_day',
  };

  constructor(props) {
    super(props);

    const {lang} = props;
    const precisions = TimelineChart.PRECISIONS;
    const periodTypes = TimelineChart.PERIOD_TYPES;

    this.state = this.getInitialState(props);

    const precisionOptions = [
      {label: lang.MONTH, value: precisions.MONTH},
      {label: lang.DAY, value: precisions.DAY},
      {label: lang.HOUR, value: precisions.HOUR},
      {label: lang.MINUTE, value: precisions.MINUTE},
    ];

    this.precisionVariants = {
      [periodTypes.MORE_THAN_MONTH]: precisionOptions.slice(0, 2),
      [periodTypes.LESS_THAN_MONTH]: precisionOptions.slice(0, 3),
      [periodTypes.LESS_THAN_DAY]: precisionOptions.slice(0, 4),
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.uid !== prevProps.uid) {
      this.handleUidChange();
    }

    if (this.props.lines !== prevProps.lines) {
      this.calculateChartConfig();
    }

    this.setStoredParams();
  }

  setStoredParams = () => {
    const {prefix, uid} = this.props;
    const {showLines, precision, periodType, from, to} = this.state;

    const data = getItem(prefix) || {};
    data[uid] = {showLines, precision, periodType, from, to};
    setItem(prefix, data);
  };

  getStoredParams = () => {
    const {prefix, uid} = this.props;
    const storedData = getItem(prefix) || {};
    const taskStoredData = storedData[uid] || {};

    if (taskStoredData.from) {
      taskStoredData.from = new Date(taskStoredData.from);
    }

    if (taskStoredData.to) {
      taskStoredData.to = new Date(taskStoredData.to);
    }

    return taskStoredData;
  };

  getInitialState = (props) => {
    const {initialFrom, initialTo, lines} = props;
    const precisions = TimelineChart.PRECISIONS;

    const from = initialFrom ? new Date(initialFrom) : moment().subtract(1, 'month').toDate();
    const to = initialTo ? new Date(initialTo) : new Date();

    return {
      showLines: lines.reduce((acc, {name}) => {
        acc[name] = true;
        return acc;
      }, {}),
      precision: precisions.MONTH,
      periodType: this.getPeriodType(from, to),
      from,
      to,
      ...this.getStoredParams(),
      chartConfig: {
        labels: [''],
        tooltipDates: [],
        datasets: [],
      },
      isLoading: false,
    };
  };

  fetchData = () => {
    const {from, to, precision} = this.state;

    this.setState({isLoading: true});

    return this.props
      .onFetch({
        // from: moment(from).format('YYYY-MM-DDT00:00:00Z'),
        // to: moment(to).format('YYYY-MM-DDT23:59:59Z'),
        // Changed to this because of #138410
        from: moment(from).format('YYYY-MM-DD') + 'T00:00:00Z',
        to: moment(to).format('YYYY-MM-DD') + 'T23:59:59Z',
        precision,
      })
      .then(() => {
        this.setState({isLoading: false});
      });
  };

  handleUidChange = () => {
    this.setState(this.getInitialState(this.props), this.fetchData);
  };

  getDateFormat = () => {
    const precisions = TimelineChart.PRECISIONS;
    const {precision} = this.state;

    switch (precision) {
      case precisions.MONTH:
        return 'MMM';
      case precisions.DAY:
        return 'DD/MM';
      case precisions.MINUTE:
        return 'HH:mm';
      default:
        return 'DD/MM|HH:mm';
    }
  };

  mapLabels = (labels) => {
    if (!labels.length) {
      return [''];
    }

    const format = this.getDateFormat();

    return labels.map((label) => {
      return moment(label).format(format).split('|');
    });
  };

  mapTooltipDates = (labels, precision) => {
    return labels.map((label) => {
      switch (precision) {
        case TimelineChart.PRECISIONS.MONTH:
          return moment(label).format('MMMM YYYY');
        case TimelineChart.PRECISIONS.DAY:
          return moment(label).format('DD/MM/YY');
        default:
          return moment(label).format('DD/MM/YY|HH:mm:ss').split('|');
      }
    });
  };

  getCurrentDateIndex = (dates, precision) => {
    const resultIndex = dates.reduce((acc, date, index) => {
      const isAfter = moment(date).isAfter(moment(), precision);
      return isAfter && acc === null ? index : acc;
    }, null);

    return resultIndex || dates.length;
  };

  calculateChartConfig = () => {
    this.setState((state) => {
      const {lines, points} = this.props;
      const {showLines, precision} = state;

      const currentDateIndex = this.getCurrentDateIndex(points, precision);
      const datasets = [];

      lines
        .filter(({name}) => showLines[name])
        .forEach((line) => {
          datasets.push({
            label: line.label,
            borderColor: line.borderColor,
            data: line.data.slice(0, currentDateIndex),
          });
        });

      return {
        chartConfig: {
          labels: this.mapLabels(points),
          tooltipDates: this.mapTooltipDates(points, precision),
          datasets,
        },
      };
    });
  };

  toggleShowLine = (name) => {
    this.setState(
      ({showLines}) => ({
        showLines: {
          ...showLines,
          [name]: !showLines[name],
        },
      }),
      this.calculateChartConfig,
    );
  };

  handlePrecisionChange = (precision) => {
    this.setState({precision}, this.fetchData);
  };

  getDateDiff = (from, to, units) => {
    const fromDate = moment(new Date(from));
    const toDate = moment(new Date(to));

    return toDate.diff(fromDate, units);
  };

  getPeriodType = (from, to) => {
    const periodTypes = TimelineChart.PERIOD_TYPES;
    const diffDays = this.getDateDiff(from, to, 'days');
    const diffMonths = this.getDateDiff(from, to, 'months');

    if (diffMonths > 0) {
      return periodTypes.MORE_THAN_MONTH;
    }

    if (diffDays > 0) {
      return periodTypes.LESS_THAN_MONTH;
    }

    return periodTypes.LESS_THAN_DAY;
  };

  handlePeriodChange = ({from, to}) => {
    this.setState((state) => {
      const periodTypes = TimelineChart.PERIOD_TYPES;
      const precisions = TimelineChart.PRECISIONS;

      const periodType = this.getPeriodType(from, to);
      const isMinuteSelected = state.precision === precisions.MINUTE;
      const isHourSelected = state.precision === precisions.HOUR;
      let precision = state.precision;

      if (periodType === periodTypes.MORE_THAN_MONTH && (isMinuteSelected || isHourSelected)) {
        precision = precisions.DAY;
      }

      if (periodType === periodTypes.LESS_THAN_MONTH && isMinuteSelected) {
        precision = precisions.HOUR;
      }

      return {from, to, precision, periodType};
    }, this.fetchData);
  };

  render() {
    const {lang, lines, points, loadingColor} = this.props;
    const {precision, from, to, periodType, chartConfig, isLoading, showLines} = this.state;

    return (
      <div className="timeline-chart">
        {isLoading && (
          <div className="timeline-chart__loader-wrap">
            <Spinner color={loadingColor} />
          </div>
        )}
        <div className="timeline-chart__filters">
          <div className="timeline-chart__filter-label">{lang.PRECISION}</div>
          <div>
            <SelectDropdown
              value={precision}
              options={this.precisionVariants[periodType]}
              onChange={this.handlePrecisionChange}
              type="per-page"
              simpleValue
            />
          </div>
          <div className="timeline-chart__filter-label">{lang.PERIOD}</div>
          <DatepickerRange from={from} to={to} onChange={this.handlePeriodChange} alignRight />
        </div>
        <ScrollableLineChart config={chartConfig} defaultIndex={this.getCurrentDateIndex(points, precision)} />
        {lines.length > 1 && (
          <div className="timeline-chart__legend">
            {lines.map((line) => (
              <CheckboxControl
                key={line.name}
                text={line.label}
                color={line.borderColor}
                checked={showLines[line.name]}
                onChange={() => this.toggleShowLine(line.name)}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default connect((state) => ({
  lang: state.languageState.payload.TIMELINE_CHART,
}))(TimelineChart);
