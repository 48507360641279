import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import {TIME_UNITS} from 'client/common/config';

import BcNavItem from '../../bc-nav-item';

function BcSettingsNavItem(props) {
  const {languageState, checked, eventKey, task, ...restProps} = props;

  const timeUnits = {
    [TIME_UNITS.seconds.name]: languageState[TIME_UNITS.seconds.localeKey],
    [TIME_UNITS.minutes.name]: languageState[TIME_UNITS.minutes.localeKey],
    [TIME_UNITS.hours.name]: languageState[TIME_UNITS.hours.localeKey],
    [TIME_UNITS.days.name]: languageState[TIME_UNITS.days.localeKey],
    [TIME_UNITS.weeks.name]: languageState[TIME_UNITS.weeks.localeKey],
    [TIME_UNITS.months.name]: languageState[TIME_UNITS.months.localeKey],
  };

  let startAt = '';
  let sendByGroups = '';

  switch (true) {
    case !!task.performAt:
      const date = moment(task.performAt);
      const startDate = date.format('DD/MM/YYYY');
      const startTime = date.format('HH:mm');
      startAt = `${languageState.START_ON_LABEL} ${startDate} ${languageState.AT_LABEL} ${startTime}`;
      break;
    case !!task.performIn:
      startAt = `${languageState.IN_LABEL} ${task.performIn} ${timeUnits[task.timeUnit]}`;
      break;
    default:
  }

  if (task.sendInBatches) {
    const timeUnit = timeUnits[task.timeUnitBetweenBatches];

    sendByGroups = `${languageState.BY_LABEL}
      ${task.batchSize}
      ${languageState.EVERY_LABEL}
      ${task.timeBetweenBatches}
      ${timeUnit}`;
  } else {
    sendByGroups = languageState.ALL_TOGETHER;
  }

  return (
    <BcNavItem
      title={<div className="break-word">{languageState.SETTINGS_NAV}</div>}
      name={
        checked && (
          <div>
            {sendByGroups && <div className="break-word">{sendByGroups}</div>}
            {startAt && <div className="break-word">{startAt}</div>}
            {task.messageSender && <div className="break-word">{task.messageSender.name}</div>}
          </div>
        )
      }
      eventKey={eventKey}
      checked={checked}
      {...restProps}
    />
  );
}

BcSettingsNavItem.propTypes = {
  languageState: PropTypes.object.isRequired,
  task: PropTypes.shape({
    performAt: PropTypes.string,
    performIn: PropTypes.string,
    timeUnit: PropTypes.number,
    sendInBatches: PropTypes.bool,
    timeUnitBetweenBatches: PropTypes.string,
    batchSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    timeBetweenBatches: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    messageSender: PropTypes.object,
  }).isRequired,
  checked: PropTypes.bool,
  eventKey: PropTypes.string.isRequired,
};

BcSettingsNavItem.defaultProps = {
  checked: false,
};

export default BcSettingsNavItem;
