import html2canvas from 'html2canvas';

export default async (elementId: string, format = 'image/jpeg') => {
  const element = document.getElementById(elementId);

  if (element) {
    const canvas = await html2canvas(element, {useCORS: true});
    return canvas.toDataURL(format);
  }

  return Promise.resolve(null);
};
