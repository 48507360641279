import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';

import TitleBlock from 'client/components/common/title-block';

import ParticipantsExportHistoryCard from 'client/components/participants/participants-export-history-card';

class ParticipantsConfig extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    client: PropTypes.object,
  };

  static defaultProps = {
    client: {},
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.PARTICIPANTS_CONFIG;
  }

  render() {
    const {client} = this.props;

    if (!client.id) {
      return null;
    }

    return (
      <div className="theme-color-9">
        <div className="page__title-block">
          <TitleBlock theme={true}>
            <TitleBlock.Item>{this.LANGUAGE.TITLE}</TitleBlock.Item>
          </TitleBlock>
        </div>
        <div className="page__block page__block--size-1">
          <ParticipantsExportHistoryCard
            clientId={client.id}
            isDashboard={false}
            isGlobal={true}
            title={this.LANGUAGE.PARTICIPATIONS}
            participationsCount={client.participations_count}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  languageState: state.languageState,
  client: selectCurrentClient(state),
}))(ParticipantsConfig);
