import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';

import {TranslationJsx} from 'client/models/language/types';

function Checkbox(props) {
  const {cssModifier, text, label, checkboxType, checked, onChange, onClick, name, disabled, dataName} = props;

  const cssClass = classNames({
    'checkbox-button': true,
    'checkbox-button--switcher-2': checkboxType === 'switcher-2',
  });

  return (
    <label className={`${cssModifier} ${cssClass}`}>
      <input
        name={name}
        data-name={dataName}
        disabled={disabled}
        type="checkbox"
        className="checkbox-button__input"
        checked={checked}
        onChange={onChange}
        onClick={onClick}
      />
      {text && <div className="checkbox-button__add-text">{text}</div>}
      <div className="checkbox-button__label">
        <Icon name="check" className="checkbox-button__icon" />
        <span className="checkbox-button__text">{label}</span>
      </div>
    </label>
  );
}

Checkbox.defaultProps = {
  cssModifier: '',
  text: '',
  label: '',
  checkboxType: '',
  checked: false,
  disabled: false,
  onChange: () => {},
  dataName: '',
};

Checkbox.propTypes = {
  cssModifier: PropTypes.string,
  text: TranslationJsx,
  label: PropTypes.any,
  checkboxType: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  dataName: PropTypes.string,
};

export default Checkbox;
