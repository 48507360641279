import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import DonutChart from 'client/components/common/charts/donut-chart';

import {mapData} from './helpers';

import './online-users-chart.scss';

const OnlineUsersChart = (props) => {
  const {lang, data} = props;

  return (
    <div className="online-users-chart">
      <DonutChart title={lang.OS} data={mapData(data.os, lang)} />
      <DonutChart title={lang.BROWSER} data={mapData(data.browser, lang)} />
      <DonutChart title={lang.DEVICE} data={mapData(data.device, lang)} />
    </div>
  );
};

OnlineUsersChart.propTypes = {
  lang: PropTypes.object.isRequired,
  data: PropTypes.shape({
    os: PropTypes.array.isRequired,
    browser: PropTypes.array.isRequired,
    device: PropTypes.array.isRequired,
  }).isRequired,
};

export default connect((state) => ({
  lang: state.languageState.payload.ONLINE_USERS_CHART,
}))(OnlineUsersChart);
