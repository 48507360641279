import {isEmail} from 'validator';

export default ({email, password}, {lang}) => {
  let errors = {};

  if (!email) {
    errors.email = lang.EMAIL_REQUIRED;
  } else if (email && !isEmail(email)) {
    errors.email = lang.EMAIL_FORMAT_INCORRECT;
  }

  if (!password) {
    errors.password = lang.PASSWORD_REQUIRED;
  }

  return errors;
};
