export default {
  GET_OPERATIONS_REQUEST: '@@operations/GET_OPERATIONS_REQUEST',
  GET_OPERATIONS_SUCCESS: '@@operations/GET_OPERATIONS_SUCCESS',
  GET_OPERATIONS_ERROR: '@@operations/GET_OPERATIONS_ERROR',

  GET_MY_OPERATIONS_REQUEST: '@@operations/GET_MY_OPERATIONS_REQUEST',
  GET_MY_OPERATIONS_SUCCESS: '@@operations/GET_MY_OPERATIONS_SUCCESS',
  GET_MY_OPERATIONS_ERROR: '@@operations/GET_MY_OPERATIONS_ERROR',

  GET_CLIENTS_REQUEST: '@@operations/GET_CLIENTS_REQUEST',
  GET_CLIENTS_SUCCESS: '@@operations/GET_CLIENTS_SUCCESS',
  GET_CLIENTS_ERROR: '@@operations/GET_CLIENTS_ERROR',

  GET_OPERATION_REQUEST: '@@operations/GET_OPERATION_REQUEST',
  GET_OPERATION_SUCCESS: '@@operations/GET_OPERATION_SUCCESS',
  GET_OPERATION_ERROR: '@@operations/GET_OPERATION_ERROR',

  UPDATE_OPERATION_REQUEST: '@@operations/UPDATE_OPERATION_REQUEST',
  UPDATE_OPERATION_SUCCESS: '@@operations/UPDATE_OPERATION_SUCCESS',
  UPDATE_OPERATION_ERROR: '@@operations/UPDATE_OPERATION_ERROR',

  CREATE_OPERATION_REQUEST: '@@operations/CREATE_OPERATION_REQUEST',
  CREATE_OPERATION_SUCCESS: '@@operations/CREATE_OPERATION_SUCCESS',
  CREATE_OPERATION_ERROR: '@@operations/CREATE_OPERATION_ERROR',

  ARCHIVE_OPERATION_REQUEST: '@@operations/ARCHIVE_OPERATION_REQUEST',
  ARCHIVE_OPERATION_SUCCESS: '@@operations/ARCHIVE_OPERATION_SUCCESS',
  ARCHIVE_OPERATION_ERROR: '@@operations/ARCHIVE_OPERATION_ERROR',

  ADD_OPERATION_REQUEST: '@@operations/ADD_OPERATION_REQUEST',
  ADD_OPERATION_SUCCESS: '@@operations/ADD_OPERATION_SUCCESS',
  ADD_OPERATION_ERROR: '@@operations/ADD_OPERATION_ERROR',

  FLUSH_OPERATIONS_ALL_STATE: '@@operations/FLUSH_OPERATIONS_ALL_STATE',

  GET_ACTIVE_OPERATIONS_REQUEST: '@@operations/GET_ACTIVE_OPERATIONS_REQUEST',
  GET_ACTIVE_OPERATIONS_SUCCESS: '@@operations/GET_ACTIVE_OPERATIONS_SUCCESS',
  GET_ACTIVE_OPERATIONS_ERROR: '@@operations/GET_ACTIVE_OPERATIONS_ERROR',

  GET_CONFIGURATION_OPERATIONS_REQUEST: '@@operations/GET_CONFIGURATION_OPERATIONS_REQUEST',
  GET_CONFIGURATION_OPERATIONS_SUCCESS: '@@operations/GET_CONFIGURATION_OPERATIONS_SUCCESS',
  GET_CONFIGURATION_OPERATIONS_ERROR: '@@operations/GET_CONFIGURATION_OPERATIONS_ERROR',

  GET_FINISHED_OPERATIONS_REQUEST: '@@operations/GET_FINISHED_OPERATIONS_REQUEST',
  GET_FINISHED_OPERATIONS_SUCCESS: '@@operations/GET_FINISHED_OPERATIONS_SUCCESS',
  GET_FINISHED_OPERATIONS_ERROR: '@@operations/GET_FINISHED_OPERATIONS_ERROR',

  GET_OPERATION_EMAIL_TEMPLATES_REQUEST: '@@operations/GET_OPERATION_EMAIL_TEMPLATES_REQUEST',
  GET_OPERATION_EMAIL_TEMPLATES_SUCCESS: '@@operations/GET_OPERATION_EMAIL_TEMPLATES_SUCCESS',
  GET_OPERATION_EMAIL_TEMPLATES_ERROR: '@@operations/GET_OPERATION_EMAIL_TEMPLATES_ERROR',

  UPDATE_OPERATION_EMAIL_TEMPLATE_REQUEST: '@@operations/UPDATE_OPERATION_EMAIL_TEMPLATE_REQUEST',
  UPDATE_OPERATION_EMAIL_TEMPLATE_SUCCESS: '@@operations/UPDATE_OPERATION_EMAIL_TEMPLATE_SUCCESS',
  UPDATE_OPERATION_EMAIL_TEMPLATE_ERROR: '@@operations/UPDATE_OPERATION_EMAIL_TEMPLATE_ERROR',

  UPDATE_MASTER_EMAIL_TEMPLATE_REQUEST: '@@operations/UPDATE_MASTER_EMAIL_TEMPLATE_REQUEST',
  UPDATE_MASTER_EMAIL_TEMPLATE_SUCCESS: '@@operations/UPDATE_MASTER_EMAIL_TEMPLATE_SUCCESS',
  UPDATE_MASTER_EMAIL_TEMPLATE_ERROR: '@@operations/UPDATE_MASTER_EMAIL_TEMPLATE_ERROR',

  GET_REGIONS_REQUEST: '@@operations/GET_REGIONS_REQUEST',
  GET_REGIONS_SUCCESS: '@@operations/GET_REGIONS_SUCCESS',
  GET_REGIONS_ERROR: '@@operations/GET_REGIONS_ERROR',

  GET_PLACES_REQUEST: '@@operations/GET_PLACES_REQUEST',
  GET_PLACES_SUCCESS: '@@operations/GET_PLACES_SUCCESS',
  GET_PLACES_ERROR: '@@operations/GET_PLACES_ERROR',

  SET_CLIENT_PARAMS: '@@operations/SET_CLIENT_PARAMS',
};
