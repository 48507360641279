import {Prize, Game} from 'client/models/prizes/types';

import getMainInitialValues from './main-params-fieldset/getInitialValues';
import getSegmentationInitialValues from './segmentation-params-fieldset/getInitialValues';
import {LotteryPrizeValues} from './types';
import getWinInitialValues from './win-params-fieldset/getInitialValues';

export default (prize: Prize | null, game: Game, languages: string[]): LotteryPrizeValues => {
  return {
    winParams: getWinInitialValues(prize, languages),
    mainParams: getMainInitialValues(prize),
    segmentationParams: getSegmentationInitialValues(game, prize),
  };
};
