import React from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

function LeadsCardGridHeader(props) {
  const LANGUAGE = props.languageState.payload.AGENCY.LEADS_CARD;

  return (
    <div className="fixed-table fixed-table--leads-card theme-color-2">
      <Table rowsCount={0} rowHeight={34} headerHeight={40} width={340} height={50}>
        <Column header={<Cell>{LANGUAGE.CODE_COLUMN}</Cell>} width={80} />
        <Column header={<Cell>{LANGUAGE.END_DATE_COLUMN}</Cell>} width={60} />
        <Column header={<Cell>{LANGUAGE.NO_OF_LEADS_COLUMN}</Cell>} width={80} />
        <Column width={120} />
      </Table>
    </div>
  );
}

LeadsCardGridHeader.propTypes = {
  languageState: PropTypes.object.isRequired,
};

export default connect(({languageState}) => ({languageState}))(LeadsCardGridHeader);
