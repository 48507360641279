export const AFFECTATION_STEPS = {
  PREPARATION: 'preparation',
  SENT: 'sent',
  RECEIVED: 'received',
  VERIFIED: 'verified',
} as const;

export const AFFECTATION_STATUSES = {
  OK: '1',
  UNSET: '0',
  ANOMALY: '-0.5',
  ERROR: '-1',
} as const;

export const AFFECTATION_ALERT_STATUSES = {
  INACTIVE: 'inactive',
  ACTIVE: 'active',
} as const;

export const AFFECTATION_ALERT_VALUES = {
  ERROR: '-1',
  WARNING: '-0.5',
  OK: '1',
} as const;

export const AFFECTATION_ICON_STATUSES = {
  [AFFECTATION_STATUSES.ERROR]: 'status-error-square',
  [AFFECTATION_STATUSES.ANOMALY]: 'status-warning',
  [AFFECTATION_STATUSES.UNSET]: 'status-not-active-2',
  [AFFECTATION_STATUSES.OK]: 'status-success-square',
} as const;

export const AFFECTATION_INFO_MODES = {
  ACTIVITY: 'activity',
  LOGISTIC: 'logistic',
} as const;
