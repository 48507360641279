import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import './offscreen.scss';

const b = bem('offscreen');

const Offscreen = (props) => {
  const {className, hidden, children} = props;

  return <div className={cn(className, b({hidden}))}>{children}</div>;
};

Offscreen.propTypes = {
  className: PropTypes.string,
  hidden: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

Offscreen.defaultProps = {
  className: null,
  children: null,
};

export default Offscreen;
