import React, {Component} from 'react';

import PropTypes from 'prop-types';

// components
import Icon from '../icon';

import './rollout.scss';

class Rollout extends Component {
  static defaultProps = {
    children: '',
    title: '',
    maxHeight: '200px',
    minHeight: '0',
    isOpened: false,
    className: '',
    style: {},
    onToggle: () => {},
    onTransition: () => {},
  };

  static propTypes = {
    maxHeight: PropTypes.string,
    minHeight: PropTypes.string,
    title: PropTypes.any,
    children: PropTypes.any,
    isOpened: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    onToggle: PropTypes.func,
    onTransition: PropTypes.func,
    bodyStyles: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      opened: props.isOpened,
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleTransition = this.handleTransition.bind(this);
  }

  handleToggle(e) {
    e.preventDefault();

    this.setState({
      opened: !this.state.opened,
    });

    this.props.onToggle();
  }

  handleTransition() {
    this.props.onTransition();
  }

  render() {
    const {children, title, maxHeight, minHeight, className, style, bodyStyles = {}} = this.props;

    return (
      <div style={style} className={`rollout ${className} ${this.state.opened ? ' rollout--opened' : ''}`}>
        <div className="rollout__header">
          <div className="rollout__title">{title}</div>
          <button className="rollout__toggle-btn" onClick={this.handleToggle}>
            <Icon name="dropdown" className="rollout__icon" />
          </button>
        </div>
        <div
          className="rollout__body"
          style={{
            maxHeight: this.state.opened ? maxHeight : '0px',
            minHeight: minHeight,
            marginRight: bodyStyles.marginRight ? bodyStyles.marginRight : 0,
            borderRight: '0px',
          }}
          onTransitionEnd={this.handleTransition}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default Rollout;
