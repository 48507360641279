import React, {Component} from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import {getEmail, getToken} from 'client/services/cookie-data-source';
import {addHashToImage, checkImageMimeType} from 'client/services/helpers';

import {API_METHODS, API_PATH} from 'client/common/config';
import Icon from 'client/common/icon';
import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';

import './manage-types-modal.scss';

let LANGUAGE = {};
let responseErrors = {};

class ManageTypesModal extends Component {
  static validate(values) {
    const errors = {};

    for (let field in values) {
      if (values.hasOwnProperty(field)) {
        if (!values[field]) {
          errors[field] = LANGUAGE.TYPE_NAME_IS_REQUIRED_ERROR;
        }
      }
    }

    for (let field in responseErrors) {
      if (responseErrors.hasOwnProperty(field)) {
        errors[field] = responseErrors[field];
      }
    }

    return errors;
  }

  static updateSyncErrors(values) {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'ManageTypesForm',
      },
      payload: {
        syncErrors: ManageTypesModal.validate(values),
      },
    };
  }

  constructor(props) {
    super(props);

    LANGUAGE = this.LANGUAGE = this.props.languageState.payload.DEVICES;

    this.save = this.save.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onAddNewTypeClick = this.onAddNewTypeClick.bind(this);
    this.onTypeDelete = this.onTypeDelete.bind(this);
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleDeleteImage = this.handleDeleteImage.bind(this);

    this.state = {
      types: [],
    };
  }

  componentDidMount() {
    this.intervalID = setInterval(() => {
      if (!isEmpty(LANGUAGE)) {
        clearInterval(this.intervalID);
        this.props.updateErrors({});
      }
    }, 200);
  }

  componentDidUpdate(prevProps) {
    const {types, show} = this.props;

    if (types !== prevProps.types || (show && !prevProps.show)) {
      this.setState({types});
      this.props.initialize(
        types.reduce((init, type, index) => {
          init['a' + index] = type.name;
          return init;
        }, {}),
      );
    }
  }

  onTypeDelete(index) {
    return () => {
      const newTypes = this.state.types.slice();
      const type = this.state.types[index];
      if (type.id) {
        type.deleted = true;
        type.changed = true;
      } else {
        newTypes.splice(index, 1);
      }
      this.setState({types: newTypes});
    };
  }

  onAddNewTypeClick() {
    const newTypes = this.state.types.slice();
    newTypes.push({});
    this.setState({types: newTypes}, this.scrollbarRef.scrollToBottom);
  }

  handleCancel() {
    this.props.onCancel();
  }

  handleClose() {
    this.props.onClose();
  }

  save() {
    responseErrors = {};
    const promises = [];
    // eslint-disable-next-line guard-for-in
    for (let index in this.state?.types) {
      const type = this.state.types[index];
      if (!type.changed) {
        continue;
      }
      const formData = type.formData || new FormData();
      formData.append('device_family[name]', type.name);
      if (type.image && !type.image.url && !type.image.tempUrl) {
        formData.append('device_family[remove_image]', 'true');
      }

      let method;
      let path;

      if (type.deleted) {
        path = `${API_PATH}${API_METHODS.DEVICE_FAMILIES}/${type.id}`;
        method = 'DELETE';
      } else if (type.id) {
        path = `${API_PATH}${API_METHODS.DEVICE_FAMILIES}/${type.id}`;
        method = 'PUT';
      } else {
        path = `${API_PATH}${API_METHODS.DEVICE_FAMILIES}`;
        method = 'POST';
      }
      promises.push(
        fetch(path, {
          method: method,
          headers: {
            'X-User-Email': getEmail(),
            'X-User-Token': getToken(),
          },
          body: formData,
        })
          .then((res) => {
            if (!res.ok) {
              return res.json();
            }
            return new Error();
          })
          // eslint-disable-next-line no-loop-func
          .then((res) => {
            if (res.errors && res.errors.name) {
              if (res.errors.name[0] === 'taken') {
                responseErrors['a' + index] = LANGUAGE.TYPE_NAME_IS_TAKEN_ERROR;
              } else if (res.errors.name[0] === 'blank') {
                responseErrors['a' + index] = LANGUAGE.TYPE_NAME_IS_REQUIRED_ERROR;
              }
            }
          }),
      );
    }

    Promise.all(promises).then(() => {
      if (isEmpty(responseErrors)) {
        this.props.onSave();
      } else {
        this.props.updateErrors(this.props.formValues.values);
      }
    });
  }

  handleChangeImage(type) {
    return (event) => {
      const file = event.target.files[0];
      checkImageMimeType(file).then(() => {
        const formData = new FormData();
        const now = new Date();
        const hash = String(now.getTime());
        const hashedName = addHashToImage(file.name, hash);
        formData.append('device_family[image]', file, encodeURIComponent(hashedName));
        const fr = new FileReader();
        fr.onload = () => {
          type.formData = formData;
          if (!type.image) {
            type.image = {};
          }
          type.image.tempUrl = fr.result;
          type.changed = true;
          this.setState({types: this.state.types.slice()});
        };
        fr.readAsDataURL(file);
      });
    };
  }

  handleDeleteImage(type) {
    return () => {
      type.image.url = null;
      type.image.tempUrl = null;
      delete type.formData;
      type.changed = true;
      this.setState({types: this.state.types.slice()});
    };
  }

  handleChangeName(index) {
    return ({target: {value}}) => {
      const type = this.state.types[index];
      type.name = value;
      type.changed = true;

      const inputName = 'a' + index;
      if (responseErrors.hasOwnProperty(inputName)) {
        delete responseErrors[inputName];
        this.props.updateErrors(this.props.formValues.values);
      }
    };
  }

  render() {
    const {show} = this.props;

    return (
      <Modal
        show={show}
        onClose={this.handleClose}
        title={this.LANGUAGE.MANAGE_TYPES_MODAL_TITLE}
        dialogClassName="modal-window--width-2 modal-window--theme-7 manage-types-modal"
      >
        <form noValidate="noValidate" onSubmit={this.props.handleSubmit(this.save)}>
          <CustomScrollbars
            scrollbarProps={{
              autoHeightMax: 349,
              ref: (scrollbar) => {
                this.scrollbarRef = scrollbar;
              },
            }}
            viewCssClass="scroll-view "
            cssModifier="margin-bottom-20"
          >
            {this.state.types &&
              this.state.types.map(
                (item, index) =>
                  !item.deleted && (
                    <div key={index} className="manage-types-modal__row">
                      <Field
                        name={'a' + index}
                        type="text"
                        cssModifier="input--view-2"
                        component={fieldTemplate}
                        onChange={this.handleChangeName(index)}
                      />
                      {item.image && (item.image.url || item.image.tempUrl) ? (
                        <div className="button--photo-preview">
                          <img
                            src={item.image.tempUrl || item.image.url}
                            alt=""
                            className="button--photo-preview__img"
                          />
                          <img
                            src={item.image.tempUrl || item.image.url}
                            alt=""
                            className="button--photo-preview__img--big"
                          />
                          <span className="button--photo-preview__cross" onClick={this.handleDeleteImage(item)} />
                        </div>
                      ) : (
                        <div className="input-file button--choose-image">
                          <div className="input-file__label">
                            <div className="input-file__btn button--square button button--bg-7">
                              <span>
                                <Icon name="image" className="button__icon" />
                              </span>
                            </div>
                            <label htmlFor={`file_input${index}`} className="input-file__file-name-wrap cursor-pointer">
                              <div className="button-imitator" />
                            </label>
                            <input
                              id={`file_input${index}`}
                              className="input-file__input"
                              type="file"
                              onChange={this.handleChangeImage(item)}
                            />
                          </div>
                        </div>
                      )}
                      {item.devices && item.devices.length > 0 ? (
                        <span className="pull-right devices-count">
                          {item.devices.length} {this.LANGUAGE.DEVICES_ADDED_LABEL}
                        </span>
                      ) : (
                        <button
                          type="button"
                          className="pull-right button button--circle button--bg-7"
                          onClick={this.onTypeDelete(index)}
                        >
                          <Icon name="trash" className="button__icon" width={17} height={19} />
                        </button>
                      )}
                    </div>
                  ),
              )}
          </CustomScrollbars>
          <button type="button" className="button button--bg-7" onClick={this.onAddNewTypeClick}>
            <Icon name="plus" className="button__icon" />
            <span>{this.LANGUAGE.ADD_NEW_TYPE_BUTTON}</span>
          </button>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={this.handleCancel}>
              {this.LANGUAGE.NEW_DEVICE_MODAL_CANCEL_BUTTON}
            </button>
            <button className="button button--bg-7 modal-window__footer-btn" type="submit">
              {this.LANGUAGE.DEVICE_MODAL_SAVE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

ManageTypesModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  handleSubmit: PropTypes.func,
  updateErrors: PropTypes.func,
  untouch: PropTypes.func,
  initialize: PropTypes.func,
  languageState: PropTypes.object,
  formValues: PropTypes.object,
  types: PropTypes.array,
  isCodeNew: PropTypes.array,
  isLogisticIdNew: PropTypes.array,
  initialValues: PropTypes.object,
  valid: PropTypes.bool,
};

ManageTypesModal.defaultProps = {
  show: false,
  types: [],
  isCodeNew: [true],
  isLogisticIdNew: [true],
  onCancel: () => {},
  onClose: () => {},
  onSave: () => {},
};

const ManageTypesForm = reduxForm({
  form: 'ManageTypesForm',
  validate: ManageTypesModal.validate,
})(ManageTypesModal);

export default connect(
  ({languageState, form}) => ({
    languageState,
    formValues: form.ManageTypesForm,
  }),
  {
    updateErrors: ManageTypesModal.updateSyncErrors,
  },
)(ManageTypesForm);
