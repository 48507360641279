import React, {Component} from 'react';

import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import {deleteClientUser} from 'client/ducks/client-users/actions';

import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

class NewUserStep2Modal extends Component {
  static validate({title, first_name, last_name}, {lang}) {
    const errors = {};

    if (!title || !title.value) {
      errors.title = lang.CIVILITY_IS_REQUIRED_ERROR;
    }
    if (!first_name || !first_name.trim()) {
      errors.first_name = lang.FIRST_NAME_IS_REQUIRED_ERROR;
    }
    if (!last_name || !last_name.trim()) {
      errors.last_name = lang.LAST_NAME_IS_REQUIRED_ERROR;
    }

    return errors;
  }

  static updateSyncErrors(values, props) {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'NewUserStep2Form',
      },
      payload: {
        syncErrors: NewUserStep2Modal.validate(values, props),
      },
    };
  }

  state = {
    showReactivationConfirmation: false,
  };

  componentDidUpdate(prevProps) {
    const {initialValues, show, user} = this.props;

    if (show && !prevProps.show) {
      if (initialValues?.id && !initialValues?.memberships?.length) {
        this.toggleReactivation();
      }

      if (user !== prevProps.user) {
        this.initializeForm();
      }
    }
  }

  toggleReactivation = () => {
    this.setState(({showReactivationConfirmation}) => ({showReactivationConfirmation: !showReactivationConfirmation}));
  };

  initializeForm = () => {
    const {user, initialValues} = this.props;

    if (user.first_name) {
      initialValues.first_name = user.first_name;
      initialValues.last_name = user.last_name;
      initialValues.title = {value: user.title};
      initialValues.locale = user.locale;
    }
    if (!initialValues.title) {
      initialValues.title = {value: 'M.'};
    }
    if (!initialValues.locale) {
      initialValues.locale = 'fr';
    }
    this.userExists = !isUndefined(initialValues.id);
    if (this.userExists && typeof initialValues.title !== 'object') {
      initialValues.title = {
        value: initialValues.title,
      };
    }
    this.props.initialize(initialValues);
    this.props.updateErrors(initialValues, this.props);
  };

  handleReactivation = () => {
    this.initializeForm();
    this.toggleReactivation();
    this.props.onReactivate(this.props.initialValues.id);
  };

  handleNewUser = async () => {
    await this.props.deleteClientUser(this.props.initialValues.id);
    await this.props.getClientUser();
    this.initializeForm();
    this.toggleReactivation();
  };

  save = ({title, last_name, first_name, locale}) => {
    this.props.onNext({
      addNewUserStep: 3,
      addNewUserObject: {
        ...this.props.user,
        first_name,
        last_name,
        title: title.value,
        locale,
      },
      addNewUserExists: this.userExists,
    });
  };

  render() {
    const {initialValues, totalSteps, onCancel, onClose, lang} = this.props;
    const {showReactivationConfirmation} = this.state;
    const {email} = this.props.user;
    const memberships = this.userExists && initialValues.memberships;
    const languagesOptions = this.props.languages
      .filter(({active}) => active)
      .map(({name, full_name}) => ({label: full_name, value: name}));

    if (showReactivationConfirmation) {
      return (
        <ConfirmationModal
          show={true}
          title={lang.NEW_USER_MODAL_TITLE}
          buttonConfirmClass="button--bg-3"
          buttonCancelClass="button--bg-3"
          message={lang.USER_REACTIVATION.MESSAGE}
          cancelText={lang.USER_REACTIVATION.REACTIVATE}
          onClose={() => {
            onClose();
            this.toggleReactivation();
          }}
          onCancel={this.handleReactivation}
          confirmText={lang.USER_REACTIVATION.CREATE_NEW_USER}
          onConfirm={this.handleNewUser}
          adminBackground
        />
      );
    }

    return (
      <Modal
        show={this.props.show}
        onClose={onClose}
        dialogClassName="modal-window--width-1 modal-window--theme-3"
        backdropClassName="modal-window__backdrop"
        titleClassName="modal-window__title"
        title={
          <>
            <h3 className="title pull-left">{lang.NEW_USER_MODAL_TITLE}</h3>
            <div className="pull-right modal-window__step">
              {lang.NEW_USER_MODAL_STEP} 2 {lang.NEW_USER_MODAL_STEP_OF} {totalSteps}
            </div>
          </>
        }
      >
        <form noValidate="noValidate" onSubmit={this.props.handleSubmit(this.save)}>
          <div className="modal-window__subtitle modal-window__email">{email}</div>
          {this.userExists && memberships && memberships.length > 0 && (
            <div className="modal-window__descr">
              <span>{lang.NEW_USER_MODAL_MEMBERSHIP} </span>
              <span className="main-text main-text--underline">
                {memberships[0].client && memberships[0].client.name}
              </span>
              {memberships.slice(1).map(({client}) => (
                <span key={client?.id}>
                  {', '}
                  <span className="main-text main-text--underline">{client.name}</span>
                </span>
              ))}
            </div>
          )}
          <div className="form-field-wrap">
            <Field
              cssModifier="select--view-1"
              label={lang.NEW_USER_MODAL_CIVILITY_LABEL}
              name="title"
              type="select"
              searchable={false}
              disabled={this.userExists}
              options={[
                {value: 'M.', label: lang.NEW_USER_MODAL_CIVILITY_M},
                {value: 'Mme.', label: lang.NEW_USER_MODAL_CIVILITY_MME},
              ]}
              component={fieldTemplate}
            />
          </div>
          <div className="form-field-wrap">
            <Field
              name="first_name"
              label={lang.NEW_USER_MODAL_FIRST_NAME_LABEL}
              type="text"
              component={fieldTemplate}
              disabled={this.userExists}
            />
          </div>
          <div className="form-field-wrap">
            <Field
              name="last_name"
              label={lang.NEW_USER_MODAL_LAST_NAME_LABEL}
              type="text"
              component={fieldTemplate}
              disabled={this.userExists}
            />
          </div>
          <div className="form-field-wrap">
            <Field
              cssModifier="select--view-1"
              label={lang.DEFAULT_LANGUAGE}
              name="locale"
              type="select"
              searchable={false}
              disabled={this.userExists}
              options={languagesOptions}
              component={fieldTemplate}
              simpleValue
            />
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onCancel}>
              {lang.NEW_USER_MODAL_CANCEL_BUTTON}
            </button>
            <button className="button button--bg-3 modal-window__footer-btn" type="submit">
              {lang.NEW_USER_MODAL_NEXT_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

NewUserStep2Modal.propTypes = {
  user: PropTypes.object,
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  updateErrors: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  totalSteps: PropTypes.number,
  deleteClientUser: PropTypes.func.isRequired,
  getClientUser: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired,
  onReactivate: PropTypes.func.isRequired,
};

NewUserStep2Modal.defaultProps = {
  show: false,
  onCancel: () => {},
  onClose: () => {},
  onNext: () => {},
  initialValues: {
    title: 'M.',
    locale: 'fr',
    first_name: '',
    last_name: '',
  },
  totalSteps: 3,
};

const NewUserStep2Form = reduxForm({
  form: 'NewUserStep2Form',
  validate: NewUserStep2Modal.validate,
})(NewUserStep2Modal);

export default connect(
  (state) => ({
    lang: state.languageState.payload.AGENCY,
    languages: state.languageState.languages,
  }),
  {
    updateErrors: NewUserStep2Modal.updateSyncErrors,
    deleteClientUser,
  },
)(NewUserStep2Form);
