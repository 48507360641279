import React from 'react';

import moment from 'moment';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';

import {AFFECTATION_ICON_STATUSES, DeviceAffectation} from 'client/models/device-affectations';

import cssModule from './client-devices-logistic-cell.module.scss';

type ClientDevicesLogisticCellProps = {
  affectation: DeviceAffectation;
};

const b = bem('client-devices-logistic-cell', {cssModule});

const ClientDevicesLogisticCell: React.FC<ClientDevicesLogisticCellProps> = ({affectation}) => {
  const lang = useLanguage('CLIENT_DEVICES.LOGISTIC');

  const isReturn = affectation.return_step;
  const isEmpty = !affectation.delivery_step && !affectation.return_step;

  if (isEmpty) {
    return null;
  }

  const status = isReturn ? affectation.return_status : affectation.delivery_status;
  const subStatusDate = isReturn ? affectation.return_timestamp : affectation.delivery_timestamp;
  const step = isReturn ? affectation.return_step : affectation.delivery_step;
  const title = isReturn ? lang.RETURN : lang.DELIVERY;
  const subtitleKey = `${step}_STATUS`;
  const subtitle = lang[subtitleKey.toUpperCase() as keyof typeof lang];

  return (
    <div className={b()}>
      <div className={b('icon')}>
        {status && <Icon name={AFFECTATION_ICON_STATUSES[status]} width={18} height={18} />}
      </div>
      <div className={b('status-container')}>
        <span className={b('status')}>{title}</span>
        <span className={b('sub-status')}>{subtitle}</span>
        {subStatusDate && <span className={b('date')}>{moment(subStatusDate).format('DD/MM/YY')}</span>}
      </div>
    </div>
  );
};

export default ClientDevicesLogisticCell;
