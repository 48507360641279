import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import './games-header.scss';

const GamesHeader = ({lang}) => {
  return (
    <div className="content-panel__header games-header">
      <div className="games-header__col">{lang.GAME_COLUMN}</div>
      <div className="games-header__col">{lang.TYPE_COLUMN}</div>
      <div className="games-header__col">{lang.VALID_COLUMN}</div>
      <div className="games-header__col">{lang.PRIZES_OPTINS_COLUMN}</div>
    </div>
  );
};

GamesHeader.propTypes = {
  lang: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  lang: state.languageState.payload.GAMES.GAMES_CARD.GAMES_TABLE,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GamesHeader);
