import React, {Component} from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {required} from 'client/services/validator';

import {getOperationData, patchOperationDatumColumn} from 'client/ducks/custom-operation-database/actions';
import {selectIsColumnContainsData} from 'client/ducks/custom-operation-database/selectors';

import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import Icon from 'client/components/common/icon';

import './edit-label-modal.scss';

class EditLabelModal extends Component {
  static defaultProps = {
    show: false,
    submitting: false,
  };

  static propTypes = {
    show: PropTypes.bool,
    submitting: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    containsData: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    editingLabel: PropTypes.object.isRequired,
    languageState: PropTypes.object.isRequired,
    getOperationData: PropTypes.func.isRequired,
    onDeleteLabelClick: PropTypes.func.isRequired,
    patchOperationDatumColumn: PropTypes.func.isRequired,
  };

  static formName = 'EditLabelModalForm';

  static SINGLE_OPERATION_DATA = 1;

  static mapData(data) {
    return {
      operation_datum_column: {
        name: data.name,
      },
    };
  }

  constructor(props) {
    super(props);

    this.LANGUAGE = this.props.languageState.payload.OPERATION_PAGE;

    this.rules = {
      name: required(this.LANGUAGE.EDIT_LABEL_MODAL.NAME_IS_REQUIRED_ERROR),
    };
  }

  componentDidUpdate(prevProps) {
    const {show, editingLabel} = this.props;

    if (show && !prevProps.show) {
      this.props.initialize(editingLabel);
      this.props.getOperationData({
        q: {
          [`data->>${editingLabel.id}_not_null`]: true,
        },
        page: EditLabelModal.SINGLE_OPERATION_DATA,
        per_page: EditLabelModal.SINGLE_OPERATION_DATA,
      });
    }
  }

  save = (data) => {
    return this.props
      .patchOperationDatumColumn(data.id, EditLabelModal.mapData(data))
      .then(({payload: {response: {errors} = {}}}) => {
        if (errors) {
          const submitErrors = {};
          if (errors.name) {
            submitErrors.name = this.LANGUAGE.EDIT_LABEL_MODAL.NAME_IS_TAKEN_ERROR;
          }
          throw new SubmissionError(submitErrors);
        }

        this.props.onConfirm();
      });
  };

  render() {
    const {show, onClose, submitting, containsData, editingLabel, handleSubmit, onDeleteLabelClick} = this.props;

    const showDeleteButton = !get(editingLabel.interfaces, 'length') && !editingLabel.conditions_count;

    const nameInputClassName = cn('form-field-wrap', {
      'form-field-wrap--margined': this.props.containsData,
    });

    return (
      <Modal
        show={show}
        onClose={onClose}
        title={this.LANGUAGE.EDIT_LABEL_MODAL.TITLE}
        dialogClassName="modal-window--width-1 edit-label-modal"
      >
        <form onSubmit={handleSubmit(this.save)}>
          <div className={nameInputClassName}>
            <Field
              label={this.LANGUAGE.EDIT_LABEL_MODAL.NAME_LABEL}
              cssModifier="input--no-min-height"
              name="name"
              maxLength={20}
              validate={this.rules.name}
              type="text"
              component={fieldTemplate}
            />
          </div>
          {showDeleteButton && containsData && (
            <div className="edit-label-modal__notification">
              <Icon name="status-error" className="edit-label-modal__notification__icon" />
              <span className="edit-label-modal__notification__text">
                {this.LANGUAGE.EDIT_LABEL_MODAL.THIS_LABEL_CONTAINS_DATA_WARNING}
              </span>
            </div>
          )}
          {showDeleteButton && (
            <button
              className="button button--bg-opdb-labels margin-top--10"
              type="button"
              onClick={() => onDeleteLabelClick(this.props.containsData)}
            >
              {this.LANGUAGE.EDIT_LABEL_MODAL.DELETE_LABEL_BUTTON}
            </button>
          )}
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onClose}>
              {this.LANGUAGE.CANCEL_BUTTON}
            </button>
            <button
              className="button button--bg-opdb-labels modal-window__footer-btn"
              type="submit"
              disabled={submitting}
            >
              {this.LANGUAGE.EDIT_LABEL_MODAL.SAVE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const form = reduxForm({
  form: EditLabelModal.formName,
})(EditLabelModal);

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  containsData: selectIsColumnContainsData(state),
});

const mapDispatchToProps = {
  getOperationData,
  patchOperationDatumColumn,
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
