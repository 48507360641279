import React from 'react';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons';

import cssModule from './ats-step.module.scss';

type AtsStepProps = {
  title: React.ReactNode;
  onSubmit: () => void;
  children: React.ReactNode;
  applyBtnText: string;
};

const b = bem('ats-step', {cssModule});

const AtsStep: React.FC<AtsStepProps> = ({title, onSubmit, children, applyBtnText}) => {
  return (
    <div className={b()}>
      <div className={b('inner')}>
        <div className={b('title')}>
          {title}
          <AppButton
            color="scenario"
            fontSize="small"
            className={b('title-btn')}
            onClick={onSubmit}
            label={applyBtnText}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export default AtsStep;
