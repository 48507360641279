export const graphConfig = {
  indexAxis: 'y',
  maintainAspectRatio: false,
  borderSkipped: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  layout: {
    padding: {
      right: 13, // compensate the absence of tick numbers
    },
  },
  scales: {
    x: {
      grid: {
        drawBorder: false,
        borderDash: [5, 5],
      },
      ticks: {
        display: false,
      },
    },
    y: {
      ticks: {
        color: '#8996AC',
      },
      grid: {
        display: false,
        borderColor: '#DCE1EA',
      },
    },
  },
};

export const getTicksConfig = (graph) => ({
  indexAxis: 'y',
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawTicks: false,
        drawBorder: false,
      },
      ticks: {
        color: '#8996AC',
      },
      afterFit: (context) => {
        context.height += 10;
      },
    },
    y: {
      grid: {
        display: false,
        drawTicks: false,
        drawBorder: false,
      },
      afterFit: (context) => {
        if (graph) {
          context.width += graph.chartArea.left; // compensate absence of labels
          context.paddingRight = context.chart.scales.x.paddingRight + (context.chart.width - graph.width); // compensate the width of scrollbar if it exists on graph
        }
      },
    },
  },
});
