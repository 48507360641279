import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

class ParticipantsExportTypeModal extends Component {
  static defaultProps = {
    show: false,
    isError: false,
    isAll: false,
  };

  static propTypes = {
    show: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isError: PropTypes.bool,
    isAll: PropTypes.bool,
    lang: PropTypes.object.isRequired,
    modals: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static formName = 'LeadsExportFormModal';

  constructor(props) {
    super(props);

    this.props.initialize({export: this.props.modals.GENERATE_EXPORT_MODAL});
  }

  render() {
    const {show, onClose, onConfirm, isAdmin, modals, lang, isError, isAll} = this.props;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-9"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TITLE}
      >
        {isError && !isAll ? (
          <p className="main-text main-text--error">{lang.ERROR_MESSAGE}</p>
        ) : (
          <>
            <FormFieldWrap>
              <Field
                label={<span className="main-text">{lang.GENERATE_LABEL}</span>}
                name="export"
                type="radio"
                value={modals.GENERATE_EXPORT_MODAL}
                component={fieldTemplate}
              />
            </FormFieldWrap>
            {isAdmin && (
              <FormFieldWrap>
                <Field
                  label={<span className="main-text">{lang.JSON_LABEL}</span>}
                  name="export"
                  type="radio"
                  value={modals.GENERATE_API_EXPORT_MODAL}
                  component={fieldTemplate}
                />
              </FormFieldWrap>
            )}
            <div className="modal-window__footer modal-window__footer--centered">
              <button className="button button--bg-11 modal-window__footer-btn" onClick={onClose}>
                {lang.CANCEL_BUTTON}
              </button>
              <button className="button button--bg-4 modal-window__footer-btn" onClick={onConfirm}>
                {lang.NEXT_BUTTON}
              </button>
            </div>
          </>
        )}
      </Modal>
    );
  }
}

const ParticipantsExportFormModal = reduxForm({
  form: ParticipantsExportTypeModal.formName,
})(ParticipantsExportTypeModal);

export default connect(({languageState}) => ({
  lang: languageState.payload.PARTICIPANTS_PORT.EXPORT_TYPE_MODAL,
}))(ParticipantsExportFormModal);
