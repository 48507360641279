import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getLeadsImportReports} from 'client/ducks/leads-import/actions';
import {selectImportReport, selectImportReportId} from 'client/ducks/leads-import/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import Icon from 'client/components/common/icon';

import './leads-port-summary-modal.scss';

class LeadsPortSummaryModal extends Component {
  static defaultProps = {
    show: false,
    leadsImportReport: {},
  };

  static propTypes = {
    show: PropTypes.bool,
    leadsImportReport: PropTypes.object,
    leadImportReportId: PropTypes.number,
    languageState: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    getLeadsImportReports: PropTypes.func.isRequired,
    ...withRouter.propTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      isError: true,
    };

    this.LANGUAGE = props.languageState.payload.LEADS_PORT.IMPORT_SUMMARY_MODAL;
  }

  handleConfirm = () => {
    this.props.history.push(`${CLIENT_PAGES.LEADS_IMPORT}/${this.props.leadImportReportId}`);
  };

  render() {
    const {leadsImportReport, show, onClose} = this.props;
    const link = leadsImportReport.file ? leadsImportReport.file.url : '';
    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-2 leads-port-summary-modal"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={this.LANGUAGE.TITLE}
      >
        <div className="theme-color-2">
          <div className="leads-port-summary-modal__message">
            {!leadsImportReport.rejected ? (
              <div className="message-success-text">{this.LANGUAGE.SUCCESS_MESSAGE}</div>
            ) : (
              <div className="message-error-text">{this.LANGUAGE.ERROR_MESSAGE}</div>
            )}
          </div>
          <ul className="leads-port-summary-modal__list">
            <li className="leads-port-summary-modal__item">
              <div className="leads-port-summary-modal__item-inner">
                <div className="leads-port-summary-modal__col">{this.LANGUAGE.TOTAL_LABEL}</div>
                <div className="leads-port-summary-modal__col">
                  <span className="bold">{leadsImportReport.total}</span>
                </div>
              </div>
            </li>
            <li className="leads-port-summary-modal__item">
              <div className="leads-port-summary-modal__item-inner">
                <div className="leads-port-summary-modal__col">{this.LANGUAGE.ACCEPTED_LABEL}</div>
                <div className="leads-port-summary-modal__col">
                  <span className="bold">{leadsImportReport.created + leadsImportReport.updated}</span>
                </div>
                <div className="leads-port-summary-modal__sub-list">
                  <div className="leads-port-summary-modal__col">
                    <div className="leads-port-summary-modal__sub-item">{this.LANGUAGE.CREATION_LABEL}</div>
                  </div>
                  <div className="leads-port-summary-modal__col">
                    <span className="bold">{leadsImportReport.created}</span>
                  </div>
                  <div className="leads-port-summary-modal__col">
                    <div className="leads-port-summary-modal__sub-item">{this.LANGUAGE.UPDATING_LABEL}</div>
                  </div>
                  <div className="leads-port-summary-modal__col">
                    <span className="bold">{leadsImportReport.updated}</span>
                  </div>
                </div>
              </div>
            </li>
            <li className="leads-port-summary-modal__item">
              <div className="leads-port-summary-modal__item-inner">
                <div className="leads-port-summary-modal__col">{this.LANGUAGE.REJECTED_LABEL}</div>
                <div className="leads-port-summary-modal__col">
                  <span className="bold">{leadsImportReport.rejected}</span>
                </div>
              </div>
            </li>
          </ul>
          {!!leadsImportReport.rejected && (
            <div className="leads-port-summary-modal__upload">
              <Icon name="status-error" className="leads-port-summary-modal__upload-icon" />
              <a href={link} className="link main-text">
                {this.LANGUAGE.DOWNLOAD_LINK}
              </a>
            </div>
          )}
        </div>
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-2 modal-window__footer-btn" type="submit" onClick={this.handleConfirm}>
            {this.LANGUAGE.OK_BUTTON}
          </button>
        </div>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    languageState: state.languageState,
    leadImportReportId: selectImportReportId(state),
    leadsImportReport: selectImportReport(state),
  }),
  {getLeadsImportReports},
)(withRouter(LeadsPortSummaryModal));
