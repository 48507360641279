export const mapData = (data) => {
  const values = [];
  const colors = [];
  data.forEach(({value, color}) => {
    values.push(value);
    colors.push(color);
  });

  return {
    values,
    colors,
  };
};
