import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {ANIMATION_STATUSES} from 'client/components/instore/constants';

import './animation-status-road.scss';

export const STATUS_LANGS = {
  [ANIMATION_STATUSES.EDIT]: 'DRAFT',
  [ANIMATION_STATUSES.ISSUED]: 'SENT',
  [ANIMATION_STATUSES.VALIDATED]: 'VALIDATION',
  [ANIMATION_STATUSES.RECEIVED]: 'RECEIVED',
  [ANIMATION_STATUSES.PRODUCTION]: 'PRODUCTION',
  [ANIMATION_STATUSES.RUNNING]: 'RUNNING',
  [ANIMATION_STATUSES.FINISHED]: 'FINISHED',
  [ANIMATION_STATUSES.REPORT]: 'REPORTED',
};

const statusTypes = Object.values(ANIMATION_STATUSES);

const b = bem('animation-status-road');

const AnimationStatusRoad = ({status, className}) => {
  const lang = useLanguage('ANIMATIONS.STATUS_ROAD');

  return (
    <div className={cn(b(), className)}>
      <hr className={b('cross-line')} />
      <ul className={b('points-list')}>
        {Object.entries(STATUS_LANGS).map(([key, langKey], index) => {
          // temporarily hide validation step until “national_validation” will be available in the kit_mapping
          if (key === ANIMATION_STATUSES.VALIDATED) {
            return null;
          }

          const isPointFulfilled = statusTypes.indexOf(key) <= statusTypes.indexOf(status);
          return (
            <li key={key} className={b('point')}>
              <span
                className={b('point-circle-wrap', {
                  'is-first': index === 0,
                  'is-last': index === statusTypes.length - 1,
                })}
              >
                <span className={b('point-circle', {[key]: true, fulfilled: isPointFulfilled})} />
              </span>
              <span className={b('point-caption')}>{lang[langKey]}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

AnimationStatusRoad.propTypes = {
  status: PropTypes.oneOf(statusTypes),
  className: PropTypes.string,
};

AnimationStatusRoad.defaultProp = {
  className: '',
};

export default AnimationStatusRoad;
