import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Pagination from 'client/common/paginations/pagination';

import {PaginationBarProps} from './type';

import cssModule from './pagination-bar.module.scss';

const b = bem('pagination-bar', {cssModule});

const PaginationBar: React.FC<PaginationBarProps> = (props) => {
  const {
    data = [],
    totalPages = 1,
    totalItems = 1,
    currentPage = 1,
    onPageChange,
    loading,
    perPage = 5,
    className,
  } = props;

  const lang = useLanguage('CLIENT_AUTOTASK_PLANS');

  if (loading) {
    return null;
  }

  return (
    <div className={cn(b(), className)}>
      <div className={b('total-shown')}>
        {lang.PAGINATION_SHOWS_TOTAL__DISPLAYED} <strong>{data?.length} </strong>
        {lang.PAGINATION_SHOWS_TOTAL__OF} <strong>{totalItems}</strong> {lang.PAGINATION_SHOWS_TOTAL__RECORDS}
      </div>
      {!!totalPages && totalPages > 1 && (
        <div className={b('page-list')}>
          <Pagination
            className={b('pagination')}
            totalItems={totalItems}
            totalPages={totalPages}
            currentPage={currentPage}
            onClick={(page: number | string) => {
              onPageChange({page, perPage});
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PaginationBar;
