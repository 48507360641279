import {Langs} from 'client/services/hooks/useLanguage/types';

import {LotteryPrizeCustomizationValues} from 'client/components/lottery/modals/lottery-prize-customization-modal/types';

export default (
  formValues: LotteryPrizeCustomizationValues,
  args: {lang: Langs<'LOTTERY.MODALS.LOTTERY_PRIZE_CUSTOMIZATION_MODAL.ERRORS'>},
) => {
  const {lang} = args;

  const errors = {
    prize_maps: Array(formValues.prize_maps?.length || 0).fill({}),
  };
  formValues.prize_maps?.forEach((prizeMap, index) => {
    if (prizeMap.initial_stock?.toString() && prizeMap.initial_stock < 1) {
      errors.prize_maps[index].initial_stock = lang.STOCK_LESS_ONE;
    }
  });

  return errors;
};
