import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {set} from 'client/common/meta/meta.actions';

import LeadsDatabase from 'client/components/leads/leads-database';

class LeadsDatabasePage extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    setMetaAction: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.setMetaAction({title: this.props.lang.TITLE});
  }

  render() {
    return <LeadsDatabase />;
  }
}

export default connect(
  (state) => ({
    lang: state.languageState.payload.LEADS_DATABASE,
  }),
  {
    setMetaAction: set,
  },
)(LeadsDatabasePage);
