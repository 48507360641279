import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {
  getBroadcasts,
  getBroadcast,
  flushBroadcastAllState,
  getBroadcastRecipients,
  clearBroadcastRecipients,
} from 'client/ducks/broadcast/actions';
import {selectActiveBroadcasts, selectBroadcast} from 'client/ducks/broadcast/selectors';
import {
  getMessageTask,
  updateMessageTask,
  toggleMessageTaskState,
  flushMessageTaskAllState,
} from 'client/ducks/message-email-sms-task/actions';
import {
  selectTaskBaseInfo,
  selectStepsStatuses,
  selectBroadcastReadyState,
  selectBroadcastInfoMessage,
} from 'client/ducks/message-email-sms-task/selectors';
import {getMessageSenders, flushMessageSendersAllState} from 'client/ducks/message-senders/actions';
import {getOptInsAction, flushOptinsColumnsAllState} from 'client/ducks/opt-in-columns/actions';

import {CLIENT_PAGES, MESSAGE_SENDERS_TYPES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';
import TitleBlock from 'client/components/common/title-block';

import {TranslationJsx} from 'client/models/language/types';
import {TASK_TYPES} from 'client/models/operations/constants';

import BCSteps from '../components/bc-steps';
import BroadcastSelect from '../components/broadcast-select';

import '../broadcasting.scss';

class MessageTaskSettings extends ReactQueryParams {
  static propTypes = {
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    getBroadcast: PropTypes.func.isRequired,
    getBroadcastRecipients: PropTypes.func.isRequired,
    getOptIns: PropTypes.func.isRequired,
    getMessageTask: PropTypes.func.isRequired,
    updateMessageTask: PropTypes.func.isRequired,
    toggleMessageTaskState: PropTypes.func.isRequired,
    getMessageSenders: PropTypes.func.isRequired,
    flushMessageTaskAllState: PropTypes.func.isRequired,
    flushBroadcastAllState: PropTypes.func.isRequired,
    flushOptinsColumnsAllState: PropTypes.func.isRequired,
    flushMessageSendersAllState: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    currentBroadcast: PropTypes.object.isRequired,
    taskInfo: PropTypes.object.isRequired,
    taskId: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    operationId: PropTypes.string.isRequired,
    broadcastsList: PropTypes.array.isRequired,
    isReadyToStart: PropTypes.bool.isRequired,
    stepsStatuses: PropTypes.object.isRequired,
    infoMessage: TranslationJsx.isRequired,
  };

  static DEFAULT_FIRST_PAGE = 1;
  static DEFAULT_PER_PAGE = 10;
  static DEFAULT_SORT_FIELD = 'first_name';
  static DEFAULT_SORT_ORDER = 'asc';
  static GET_QUERY_PARAMS = {
    include: {
      broadcast_list: null,
      message_task_mapping_items: {
        column_adapter: null,
      },
      email_template: {
        email_template_variables: {
          email_task_mapping_items: {
            column_adapter: null,
          },
        },
      },
      message_sender: null,
      client: null,
      operation: null,
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedBroadcastsName: '',
    };
  }

  defaultQueryParams = {
    sort: JSON.stringify({}),
    page: '',
    perPage: '',
  };

  componentDidMount() {
    this.props.getOptIns();

    this.getMessageTask()
      .then((response) => {
        const broadcastId = response.payload.message_task.broadcast_list_id;

        this.setBreadcrumbs();
        this.getMessageSenders();

        if (!broadcastId) {
          this.props.clearBroadcastRecipients();
          return Promise.resolve();
        }

        return this.getBroadcast(broadcastId);
      })
      .then((response) => {
        if (response) {
          this.setState({
            selectedBroadcastsName: response.payload.broadcast_list.name,
          });
        }

        return this.getBroadcastRecipients();
      });
  }

  componentWillUnmount() {
    this.props.flushMessageTaskAllState();
    this.props.flushBroadcastAllState();
    this.props.flushOptinsColumnsAllState();
    this.props.flushMessageSendersAllState();
    this.props.clearBreadcrumbs();
  }

  setBreadcrumbs = () => {
    const {
      languageState: {
        payload: {BREADCRUMBS, BROADCASTING_TASK},
      },
      taskInfo: {clientName, clientId, clientType, taskType, taskId, taskName, operationId, operationName},
    } = this.props;

    const clientLink = CustomLink.createClientLink({clientType, clientId});
    const operationLink = CustomLink.createOperationLink({clientId, clientType, operationId});
    const taskLink = CustomLink.createTaskLink({
      taskType,
      clientType,
      operationId,
      clientId,
      taskId,
    });

    const taskBreadcrumb =
      taskType === TASK_TYPES.SMS
        ? BROADCASTING_TASK.EMAIL_STEPS.SETTINGS_SMS_BREADCRUMB
        : BROADCASTING_TASK.EMAIL_STEPS.SETTINGS_EMAIL_BREADCRUMB;

    this.props.setBreadcrumbs([
      {
        name: BREADCRUMBS.CLIENTS,
        href: CLIENT_PAGES.CLIENTS_LIST,
      },
      {
        name: clientName,
        href: clientLink,
      },
      {
        name: operationName,
        href: operationLink,
      },
      {
        name: taskName,
        href: taskLink,
      },
      {
        name: taskBreadcrumb,
      },
    ]);
  };

  getMessageTask = () => {
    return this.props.getMessageTask(this.props.taskId, MessageTaskSettings.GET_QUERY_PARAMS);
  };

  getBroadcastRecipients = () => {
    const {queryParams} = this;
    const {currentBroadcast: broadcast} = this.props;

    // preventing fetching recipients when current email task has no broadcast list yet
    if (!broadcast.id) {
      return Promise.resolve();
    }

    const sort =
      `lead_${(queryParams.sort && queryParams.sort.name) || MessageTaskSettings.DEFAULT_SORT_FIELD} ` +
      `${(queryParams.sort && queryParams.sort.order) || MessageTaskSettings.DEFAULT_SORT_ORDER}`;

    const params = {
      q: {
        broadcast_list_id_eq: broadcast.id,
        s: sort,
      },
      include: 'lead',
      page: queryParams.page || MessageTaskSettings.DEFAULT_FIRST_PAGE,
      per_page: queryParams.perPage || MessageTaskSettings.DEFAULT_PER_PAGE,
    };

    return this.props.getBroadcastRecipients(params);
  };

  getBroadcast = (broadcastId) => {
    return this.props.getBroadcast(broadcastId);
  };

  getMessageSenders = () => {
    const senderType =
      this.props.taskInfo.taskType === TASK_TYPES.SMS ? MESSAGE_SENDERS_TYPES.SMS : MESSAGE_SENDERS_TYPES.EMAIL;

    const params = {
      q: {
        s: 'client_id asc',
        type_eq: senderType,
        client_id_eq: this.props.taskInfo.clientId,
      },
      include: 'client',
    };

    return this.props.getMessageSenders(params);
  };

  updateMessageTask = (body = {}) => {
    return this.props.updateMessageTask(this.props.taskId, body, MessageTaskSettings.GET_QUERY_PARAMS);
  };

  handlePageChange = (params) => {
    this.setQueryParams(params);
    return this.getBroadcastRecipients();
  };

  handleSortChange = (name, order) => {
    this.setQueryParams({
      sort: {
        name,
        order,
      },
    });

    return this.getBroadcastRecipients();
  };

  onClearBroadcastList = () => {
    this.setQueryParams({
      page: '',
      perPage: '',
      sort: {},
    });

    const body = {
      message_task: {
        broadcast_list_id: null,
      },
    };

    return this.updateMessageTask(body)
      .then(() => {
        this.setState({
          selectedBroadcastsName: '',
        });
      })
      .then(this.props.clearBroadcastRecipients);
  };

  onSelectBroadcast = (broadcastId) => {
    const body = {
      message_task: {
        broadcast_list_id: broadcastId,
      },
    };

    return this.updateMessageTask(body).then((res) => {
      const selectedBroadcastsName = get(res, 'payload.message_task.broadcast_list.name');
      this.setState({selectedBroadcastsName});
      return this.getBroadcastRecipients();
    });
  };

  handleToggleTaskState = (state) => {
    const {taskId} = this.props;
    const body = {
      message_task: {
        state,
      },
    };

    return this.props.toggleMessageTaskState(taskId, body, MessageTaskSettings.GET_QUERY_PARAMS);
  };

  render() {
    const {clientId, operationId, taskId, currentBroadcast, taskInfo, stepsStatuses, isReadyToStart, infoMessage} =
      this.props;

    const {selectedBroadcastsName} = this.state;

    const showDropdowns = !selectedBroadcastsName;

    return (
      <div className="theme-color-16">
        <div className="page__title-block flex-container pos-rel z_200">
          {!showDropdowns && (
            <TitleBlock theme={true}>
              <TitleBlock.Item>
                <div className="broadcasting__title-name">{selectedBroadcastsName}</div>
              </TitleBlock.Item>
            </TitleBlock>
          )}
          {!!taskInfo.taskId && (
            <BroadcastSelect
              disabled={taskInfo.disabledIfNotNew}
              className="broadcasting__broadcast-dropdowns"
              selectedBroadcastsName={selectedBroadcastsName}
              clientId={clientId}
              onClearBroadcastList={this.onClearBroadcastList}
              onSelectBroadcast={this.onSelectBroadcast}
              taskType={taskInfo.taskType}
            />
          )}
        </div>
        <div className="main-text">{infoMessage}</div>
        <div className="pos-rel z_100">
          <BCSteps
            isReadyToStart={isReadyToStart}
            stepsStatuses={stepsStatuses}
            currentBroadcast={currentBroadcast}
            task={taskInfo}
            taskType={taskInfo.taskType}
            taskState={taskInfo.taskState}
            disabledWhilePerforming={taskInfo.disabledWhilePerforming}
            disabledIfNotNew={taskInfo.disabledIfNotNew}
            clientType={taskInfo.clientType}
            clientId={clientId}
            operationId={operationId}
            taskId={taskId}
            broadcastId={String(currentBroadcast.id)}
            perPage={MessageTaskSettings.DEFAULT_PER_PAGE}
            onPageChange={this.handlePageChange}
            onSortChange={this.handleSortChange}
            onSave={this.updateMessageTask}
            onToggleTaskState={this.handleToggleTaskState}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const taskInfo = selectTaskBaseInfo(state);
  const stepsStatuses = selectStepsStatuses(state);
  const isReadyToStart = selectBroadcastReadyState(stepsStatuses);
  const taskState = taskInfo.taskState;
  const language = state.languageState.payload.BROADCASTING_TASK.EMAIL_STEPS.INFO_MESSAGES;

  return {
    languageState: state.languageState,
    broadcastsList: selectActiveBroadcasts(state),
    currentBroadcast: selectBroadcast(state),
    taskInfo,
    stepsStatuses,
    isReadyToStart,
    infoMessage: selectBroadcastInfoMessage(taskState, isReadyToStart, language),
  };
};

const mapDispatchToProps = {
  setBreadcrumbs,
  clearBreadcrumbs,
  getBroadcast,
  getBroadcasts,
  getBroadcastRecipients,
  clearBroadcastRecipients,
  getOptIns: getOptInsAction,
  getMessageTask,
  updateMessageTask,
  getMessageSenders,
  toggleMessageTaskState,
  flushMessageTaskAllState,
  flushBroadcastAllState,
  flushOptinsColumnsAllState,
  flushMessageSendersAllState,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageTaskSettings);
