export const KPI_MODES = {
  STAT: {
    TOTAL: 'total',
    AVERAGE: 'average',
  },
  GRAPH: {
    BAR: 'bar',
    PIE: 'pie',
  },
};

export const KPI_TYPES = {
  STAT: 'stat',
  GRAPH: 'graph',
};
