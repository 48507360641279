import {COUPON_TYPES, VALIDITY_TYPES, PREFIXES} from 'client/ducks/coupons/constants';

const convertDate = (value) => (value ? new Date(value.split('/').reverse().join('-')) : null);

const mapAppData = (formValues) => {
  return {
    operation_id: formValues.operationId,
    internal_name: formValues.internal_name,
    internal_code: formValues.internal_code,
    external_name: formValues.external_name,
    validity_duration: formValues.validity_type === VALIDITY_TYPES.DURATION,
    validity_days: formValues.validity_days,
    validity_from: convertDate(formValues.validity_from),
    validity_to: convertDate(formValues.validity_to),
    comment: formValues.comment,
  };
};

const mapWeezioData = (formValues) => {
  if (formValues.type !== COUPON_TYPES.WEEZIO) {
    return {};
  }

  return {
    max_number_of_tokens: formValues.weezio_maximum_tokens_number,
    token_length: formValues.length_without_prefix,
    allowed_characters: formValues.characters,
    default_characters: formValues.characters_format,
    excluded_characters: formValues.exclude_characters,
    coupon_prefixes: [
      {
        number: 1,
        id: formValues.prefix1_id,
        prefix_type: formValues.prefix1,
        value: formValues.prefix1 === PREFIXES.FIXED_VALUE ? formValues.prefix1_fixed_value : null,
      },
      {
        number: 2,
        id: formValues.prefix2_id,
        prefix_type: formValues.prefix2,
        value: formValues.prefix2 === PREFIXES.FIXED_VALUE ? formValues.prefix2_fixed_value : null,
      },
    ],
  };
};

const mapSogecData = (formValues) => {
  if (formValues.type !== COUPON_TYPES.SOGEC) {
    return {};
  }

  return {
    max_number_of_tokens: formValues.sogec_maximum_tokens_number,
    sogec_configuration: {
      id: formValues.sogec_configuration_id,
      external_coupon_id: formValues.coupon_id,
      external_campaign_id: formValues.campaign_id,
      login: formValues.login,
      password: formValues.password,
    },
  };
};

export default (formValues) => {
  return {
    [`${formValues.type}_coupon`]: {
      test_mode: formValues.test_mode,
      ...mapAppData(formValues),
      ...mapWeezioData(formValues),
      ...mapSogecData(formValues),
    },
  };
};
