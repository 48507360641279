import moment from 'moment/moment';

import {GameDraw} from './types';

export const isGameDrawClosed = (draw?: GameDraw | null) => {
  if (!draw) {
    return false;
  }

  return moment(draw.default_draw_time).isSameOrBefore(moment());
};
