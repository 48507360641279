import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {set} from 'client/common/meta/meta.actions';

import StoresList from '../components/stores';

class StoresPage extends ReactQueryParams {
  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.STORES;
  }

  componentDidMount() {
    this.props.setMetaAction({title: this.LANGUAGE.STORES_TITLE});
  }

  componentDidUpdate(prevProps) {
    const {client} = this.props;
    if (client !== prevProps.client) {
      this.props.setMetaAction({title: `${this.LANGUAGE.STORES_OF__TITLE}${client.name}`});
    }
  }

  render() {
    return <StoresList id={+this.props.match.params.id} />;
  }
}

StoresPage.propTypes = {
  setMetaAction: PropTypes.func,
  languageState: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default connect(
  ({stores, languageState}) => ({
    client: stores.client,
    languageState,
  }),
  {
    setMetaAction: set,
  },
)(StoresPage);
