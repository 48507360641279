export const DATA_COMPONENTS = {
  PARTICIPATIONS: 'PARTICIPATIONS',
  LEADS: 'LEADS',
  LEADS_DATABASE: 'LEADS_DATABASE',
  WINNERS: 'WINNERS',
} as const;

export const DATA_TAB_MODAL_NAMES = {
  FILTERS_MODAL: 'FiltersModal',
  EXPORT_API_MODAL: 'ExportAPIModal',
  VIEW_FILES_MODAL: 'ViewFilesModal',
  GENERATE_FILE_MODAL: 'GenerateFileModal',
  SHARE_FILE_ACCESS_MODAL: 'ShareFileAccessModal',
};

export const DEFAULT_PAGE_SETTINGS = {
  current: 1,
  perPage: 25,
};

const INITIAL_LEADS_STATE = {
  filters: {
    phone: false,
    email: false,
    visuals: false,
    participated: false,
    participatedFrom: '',
    participatedTo: '',
    gender: null,
    created: false,
    createdFrom: '',
    createdTo: '',
    origin: null,
    operation: null,
    optInRadio: false,
    opt_in: null,
  },
  search: '',
  searchField: '',
  pages: {
    current: 1,
    perPage: 25,
  },
  total: 0,
  sortParams: {
    sortField: '',
    sortOrder: '',
    page: 1,
  },
  filesModal: {
    sortField: 'date',
    sortOrder: 'desc',
  },
};

export const INITIAL_STATE = {
  [DATA_COMPONENTS.PARTICIPATIONS]: {
    filters: {
      phone: false,
      email: false,
      visuals: false,
      full_participation: false,
      participated: false,
      participatedFrom: '',
      participatedTo: '',
      scenario: null,
      interfaceItem: null,
      level: null,
      origin: null,
      optInRadio: false,
      optIn: null,
    },
    search: '',
    searchField: 'lead_first_name_cont',
    pages: {
      current: 1,
      perPage: 25,
    },
    total: 0,
    sortParams: {
      sortField: '',
      sortOrder: '',
      page: 1,
    },
    filesModal: {
      sortField: 'date',
      sortOrder: 'desc',
    },
  },
  [DATA_COMPONENTS.LEADS]: INITIAL_LEADS_STATE,
  [DATA_COMPONENTS.LEADS_DATABASE]: INITIAL_LEADS_STATE,
  [DATA_COMPONENTS.WINNERS]: {
    filters: {
      phone: false,
      email: false,
      visuals: false,
      participated: false,
      participatedFrom: '',
      participatedTo: '',
      gender: null,
      origin: null,
      prizeTypes: null,
      prizeNames: null,
      optInRadio: false,
      optIn: null,
    },
    search: '',
    searchField: '',
    pages: {
      current: 1,
      perPage: 25,
    },
    total: 0,
    sortParams: {
      sortField: '',
      sortOrder: '',
      page: 1,
    },
    filesModal: {
      sortField: 'date',
      sortOrder: 'desc',
    },
  },
};

export const REPORTS_PER_PAGE = 6;

export const NAME_FIELD_MAPPER = {
  [DATA_COMPONENTS.PARTICIPATIONS]: 'participation_export_name',
  [DATA_COMPONENTS.LEADS]: 'lead_transfer_name',
  [DATA_COMPONENTS.LEADS_DATABASE]: 'lead_transfer_name',
  [DATA_COMPONENTS.WINNERS]: 'participation_export_name',
} as const;
