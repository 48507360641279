import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// components
import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import VariableValue from 'client/components/common/variable-value';

import './run-table-variables-modal.scss';

class RunTableVariablesModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    languageState: PropTypes.object.isRequired,
    variables: PropTypes.array,
    values: PropTypes.object,
  };

  static defaultProps = {
    show: false,
    onClose: () => {},
    variables: [],
    values: {},
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.VARIABLES_MODAL;
  }

  render() {
    const {LANGUAGE} = this;
    const {show, onClose, variables, values} = this.props;

    return (
      <Modal
        show={show}
        onClose={onClose}
        title={LANGUAGE.TITLE}
        dialogClassName="modal-window--width-1 theme-color-9 run-table-variables-modal"
      >
        <form>
          <CustomScrollbars
            scrollbarProps={{
              autoHeightMax: 500,
            }}
          >
            {variables.map((variable, key) => (
              <div key={key} className="form-field-wrap">
                <div className="fake-input fake-input--disabled">
                  <div className="fake-input__label">{variable.full_name || variable.name}</div>
                  <VariableValue variable={variable} value={values[variable.id]} />
                </div>
              </div>
            ))}
          </CustomScrollbars>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-4 modal-window__footer-btn" type="button" onClick={onClose}>
              {LANGUAGE.OK_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(RunTableVariablesModal);
