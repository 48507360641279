export default {
  CLEAR_CAMPAIGNS_STATE: '@@campaigns/CLEAR_CAMPAIGNS_STATE',

  FETCH_CAMPAIGNS_REQUEST: '@@campaigns/FETCH_CAMPAIGNS_REQUEST',
  FETCH_CAMPAIGNS_SUCCESS: '@@campaigns/FETCH_CAMPAIGNS_SUCCESS',
  FETCH_CAMPAIGNS_ERROR: '@@campaigns/FETCH_CAMPAIGNS_ERROR',

  CREATE_CAMPAIGN_REQUEST: '@@campaigns/CREATE_CAMPAIGN_REQUEST',
  CREATE_CAMPAIGN_SUCCESS: '@@campaigns/CREATE_CAMPAIGN_SUCCESS',
  CREATE_CAMPAIGN_ERROR: '@@campaigns/CREATE_CAMPAIGN_ERROR',

  GET_CAMPAIGN_REQUEST: '@@campaigns/GET_CAMPAIGN_REQUEST',
  GET_CAMPAIGN_SUCCESS: '@@campaigns/GET_CAMPAIGN_SUCCESS',
  GET_CAMPAIGN_ERROR: '@@campaigns/GET_CAMPAIGN_ERROR',

  PATCH_CAMPAIGN_REQUEST: '@@campaigns/PATCH_CAMPAIGN_REQUEST',
  PATCH_CAMPAIGN_SUCCESS: '@@campaigns/PATCH_CAMPAIGN_SUCCESS',
  PATCH_CAMPAIGN_ERROR: '@@campaigns/PATCH_CAMPAIGN_ERROR',

  DELETE_CAMPAIGN_REQUEST: '@@campaigns/DELETE_CAMPAIGN_REQUEST',
  DELETE_CAMPAIGN_SUCCESS: '@@campaigns/DELETE_CAMPAIGN_SUCCESS',
  DELETE_CAMPAIGN_ERROR: '@@campaigns/DELETE_CAMPAIGN_ERROR',

  GET_OPERATION_INTERFACES_REQUEST: '@@campaigns/GET_OPERATION_INTERFACES_REQUEST',
  GET_OPERATION_INTERFACES_SUCCESS: '@@campaigns/GET_OPERATION_INTERFACES_SUCCESS',
  GET_OPERATION_INTERFACES_ERROR: '@@campaigns/GET_OPERATION_INTERFACES_ERROR',

  GET_CAMPAIGNS_WITH_CHANNELS_REQUEST: '@@campaigns/GET_CAMPAIGNS_WITH_CHANNELS_REQUEST',
  GET_CAMPAIGNS_WITH_CHANNELS_SUCCESS: '@@campaigns/GET_CAMPAIGNS_WITH_CHANNELS_SUCCESS',
  GET_CAMPAIGNS_WITH_CHANNELS_ERROR: '@@campaigns/GET_CAMPAIGNS_WITH_CHANNELS_ERROR',

  CREATE_CHANNEL_REQUEST: '@@campaigns/CREATE_CHANNEL_REQUEST',
  CREATE_CHANNEL_SUCCESS: '@@campaigns/CREATE_CHANNEL_SUCCESS',
  CREATE_CHANNEL_ERROR: '@@campaigns/CREATE_CHANNEL_ERROR',

  EDIT_CHANNEL_REQUEST: '@@campaigns/EDIT_CHANNEL_REQUEST',
  EDIT_CHANNEL_SUCCESS: '@@campaigns/EDIT_CHANNEL_SUCCESS',
  EDIT_CHANNEL_ERROR: '@@campaigns/EDIT_CHANNEL_ERROR',

  REMOVE_CHANNEL_REQUEST: '@@campaigns/REMOVE_CHANNEL_REQUEST',
  REMOVE_CHANNEL_SUCCESS: '@@campaigns/REMOVE_CHANNEL_SUCCESS',
  REMOVE_CHANNEL_FAILED: '@@campaigns/REMOVE_CHANNEL_FAILED',

  GET_PERMANENT_SOURCES_REQUEST: '@@campaigns/GET_PERMANENT_SOURCES_REQUEST',
  GET_PERMANENT_SOURCES_SUCCESS: '@@campaigns/GET_PERMANENT_SOURCES_SUCCESS',
  GET_PERMANENT_SOURCES_FAILED: '@@campaigns/GET_PERMANENT_SOURCES_FAILED',
};
