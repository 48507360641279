import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// components
import Modal from 'client/common/modals/modal';

function DeleteStoresModal(props) {
  let LANGUAGE = props.languageState.payload.STORES;

  return (
    <Modal
      show={props.show}
      onClose={props.onClose}
      dialogClassName="modal-window--width-1 modal-window--theme-3"
      title={LANGUAGE.DELETE_STORES_MODAL_TITLE}
    >
      <div className="modal-window__text-message">
        <div>{LANGUAGE.DELETE_STORES_MODAL_TEXT_1}</div>
      </div>
      <div className="modal-window__footer modal-window__footer--centered">
        <button className="button button--bg-5 modal-window__footer-btn" onClick={props.onCancel}>
          {LANGUAGE.DELETE_STORES_MODAL_CANCEL}
        </button>
        <button className="button button--bg-3 modal-window__footer-btn" onClick={props.onConfirm}>
          {LANGUAGE.DELETE_STORES_MODAL_CONFIRM}
        </button>
      </div>
    </Modal>
  );
}

DeleteStoresModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  languageState: PropTypes.object,
};

DeleteStoresModal.defaultProps = {
  show: false,
  onConfirm: () => {},
  onCancel: () => {},
  onClose: () => {},
};

export default connect(({languageState}) => {
  return {
    languageState,
  };
})(DeleteStoresModal);
