import types from './types';

const initialState = {
  groups: [],
  networks: [],
  clients: [],
  regions: [],
  submitted: false,
};

export default function (_state = initialState, action) {
  const state = Object.assign({}, _state);

  switch (action.type) {
    case types.GET_GROUPS_SUCCESS:
      state.groups = action.payload.groups.map(({id, name}) => ({value: id, label: name}));
      return state;

    case types.GET_GROUPS_ERROR:
      return state;

    case types.GET_NETWORKS_SUCCESS:
      state.networks = action.payload.networks;
      return state;

    case types.GET_NETWORKS_ERROR:
      return state;

    case types.GET_REGIONS_SUCCESS:
      state.regions = action.payload.regions;
      return state;

    case types.GET_REGIONS_ERROR:
      return state;

    case types.GET_CLIENTS_SUCCESS:
      state.clients = action.payload.clients;
      return state;

    case types.GET_CLIENTS_ERROR:
      return state;

    case types.TOGGLE_SUBMITTED:
      state.submitted = !state.submitted;
      return state;

    default:
      return state;
  }
}
