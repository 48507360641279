import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import Popover from 'client/common/popovers/popover';

import {RenderCallback} from 'client/components/common/client-table/types';

import {ClientDeviceModalTab} from 'client/components/devices/modals/client-device-modal';
import {DeviceAffectation} from 'client/models/device-affectations';

import cssModule from './client-device-list-table-cell-wrapper.module.scss';

type ClientDeviceListTableCellProps = {
  render?: RenderCallback<DeviceAffectation>;
  affectation: DeviceAffectation;
  deviceTab?: ClientDeviceModalTab;
  onOpenDeviceModal?: (affectation: DeviceAffectation, deviceTab: ClientDeviceModalTab) => void;
  tooltip?: React.ReactNode;
  className?: string;
};

const b = bem('client-device-list-table-cell-wrapper', {cssModule});

const ClientDeviceListTableCellWrapper: React.FC<ClientDeviceListTableCellProps> = (props) => {
  const {children, className, tooltip, render, affectation, deviceTab, onOpenDeviceModal} = props;

  const forRender = (
    <div
      className={cn(b(), className)}
      role={deviceTab ? 'button' : 'none'}
      tabIndex={-1}
      onClick={deviceTab && (() => onOpenDeviceModal?.(affectation, deviceTab))}
    >
      {render ? children : <span className={b('cell-clamp')}>{children}</span>}
    </div>
  );

  if (tooltip) {
    return (
      <Popover
        overlay={tooltip}
        className={b('cell-popover')}
        flipOptions={{mainAxis: true}}
        shiftOptions={{mainAxis: true}}
      >
        {forRender}
      </Popover>
    );
  }
  return forRender;
};

export default ClientDeviceListTableCellWrapper;
