import React from 'react';

import {useDispatch} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {interpolate} from 'client/ducks/language/helpers';
import {deleteClientTemplate} from 'client/ducks/templates/actions';

import {useToast} from 'client/common/hooks/useToast';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import {ClientTemplateType} from 'client/components/catalog-templates/types';
import {ClientTemplate} from 'client/models/templates/types';

type TemplateDeleteConfirmationProps = {
  show: boolean;
  template: ClientTemplate;
  templateType: ClientTemplateType;
  onClose: () => void;
  onDelete: () => Promise<void>;
};

const TemplateDeleteConfirmation: React.FC<TemplateDeleteConfirmationProps> = (props) => {
  const {show, template, templateType, onDelete, onClose} = props;
  const dispatch = useDispatch();
  const {appendToastNotification} = useToast();

  const lang = useLanguage('CATALOG_TEMPLATES.CONFIRMATIONS.TEMPLATE_DELETE_CONFIRMATION');
  const langCommon = useLanguage('COMMON');

  const typeTitle = lang[templateType.toUpperCase() as keyof typeof lang];

  const handleDelete = async () => {
    onClose();
    try {
      await dispatch(deleteClientTemplate(template.id));
      appendToastNotification({type: 'success', title: `${typeTitle} ${lang.DELETED}`, description: template.title});
    } catch {
      appendToastNotification({type: 'error', title: langCommon.ERROR});
    }
    await onDelete();
  };

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      onConfirm={handleDelete}
      title={`${lang.DELETE} ${typeTitle}`}
      message={
        // TODO: remove parsing translation to string. it should be resolved in Translation Element
        interpolate(lang.MESSAGE?.toString(), {name: template.title})
      }
      cancelText={lang.CANCEL}
      confirmText={lang.DELETE}
      clientSide
    />
  );
};

export default TemplateDeleteConfirmation;
