import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getLeadsOptInStatisticsById(id) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.LEADS_OPTIN_STATISTICS}`,
      method: 'GET',
      types: [
        types.GET_LEADS_OPTIN_STATISTICS_REQUEST,
        types.GET_LEADS_OPTIN_STATISTICS_SUCCESS,
        types.GET_LEADS_OPTIN_STATISTICS_ERROR,
      ],
      queryParams: {
        client_id: id,
      },
    });
}
