import {useEffect} from 'react';

import PropTypes from 'prop-types';
import qs from 'qs';
import {useDispatch} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {setCallbackError} from 'client/ducks/sso-auth-info/actions';
import {login} from 'client/ducks/user/actions';
import {checkAuthToken} from 'client/ducks/user/actions';

import {CLIENT_PAGES} from 'client/common/config';

const SSOCallbackPage = (props) => {
  const {location, history} = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!location?.search) {
      window.location = CLIENT_PAGES.LOGIN;
    }
    const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true});
    const {access_token, error_status} = queryParams;

    if (error_status) {
      dispatch(setCallbackError(error_status));
      history.push(CLIENT_PAGES.LOGIN_AUCHAN);
      return;
    }

    if (access_token) {
      dispatch(checkAuthToken({access_token})).then((response) => {
        if (response.error) {
          window.location = CLIENT_PAGES.LOGIN;
        } else {
          dispatch(login({user: response.payload.user, rememberMe: false}));
          window.location = CLIENT_PAGES.HOME;
        }
      });
    }
  }, [dispatch, location, history]);

  return null;
};

SSOCallbackPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(SSOCallbackPage);
