import React from 'react';

import bem from 'client/services/bem';
import {dateToString, getAndDownloadFile} from 'client/services/helpers';

import AppButton from 'client/common/buttons';

import {AnimationPhoto} from 'client/models/animations/types';

import cssModule from './animation-photo-card.module.scss';

const b = bem('animation-photo-card', {cssModule});

type AnimationPhotoCardProps = {
  photo: AnimationPhoto;
  onClick: () => void;
};

const AnimationPhotoCard: React.FC<AnimationPhotoCardProps> = (props) => {
  const {photo, onClick} = props;

  const label = photo.animation?.place?.name;

  return (
    <div className={b({'with-label': !!label})}>
      <div className={b('image-wrapper')}>
        <img className={b('image')} src={photo.url} alt="" onClick={onClick} />
      </div>
      <div className={b('bottom')}>
        <div>
          {label && <span className={b('label')}>{label}</span>}
          <p className={b('datetime')}>
            <span className={b('date')}>{dateToString(photo.created_at, 'DD/MM/YY')}</span>
            {!label && <span className={b('time')}>{dateToString(photo.created_at, 'HH:mm')}</span>}
          </p>
        </div>
        <AppButton
          iconName="downloads"
          classNameIcon={b('download-icon')}
          iconConfig={{width: 16, height: 16}}
          onClick={() => getAndDownloadFile(photo.url)}
          asWrap={true}
        />
      </div>
    </div>
  );
};

export default AnimationPhotoCard;
