export const setItem = (key, value) => {
  const data = JSON.stringify(value);
  sessionStorage.setItem(key, data);
};

export const getItem = (key) => {
  const data = sessionStorage.getItem(key);
  return JSON.parse(data);
};

export const removeItem = (key) => {
  sessionStorage.removeItem(key);
};
