import types from './types';

const initialState = {
  email_senders: [],
  email_senders_meta: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_EMAIL_SENDERS_SUCCESS:
      return {
        ...state,
        email_senders: [...action.payload.email_senders],
      };
    default:
      return state;
  }
}
