import React, {useState} from 'react';

import isEqual from 'lodash/isEqual';
import {useDispatch} from 'react-redux';

import bem from 'client/services/bem';
import {getUserId} from 'client/services/cookie-data-source';
import {useLanguage} from 'client/services/hooks';

import {updateColumnVisibilityItems, updateDisplayColumns} from 'client/ducks/participation-display-items/actions';

import AppButton from 'client/common/buttons';
import Modal from 'client/common/modals/modal';

import {ParticipationColumn} from 'client/models/participations/types';
import {ApiDispatch} from 'client/types';

import {prepareBody} from './helpers';
import ParticipantsTableSettingBase from './participants-table-settings-base';

import cssModule from './participants-table-settings-modal.module.scss';

type ParticipantsTableSettingsModalProps = {
  clientDisplayedColumns: ParticipationColumn[];
  adminDisplayedColumns: ParticipationColumn[];
  onSubmit: () => void;
  onClose: () => void;
  defaultTab: 'admin' | 'client';
};

const b = bem('participants-table-settings-modal', {cssModule});

const ParticipantsTableSettingsModal: React.FC<ParticipantsTableSettingsModalProps> = (props) => {
  const {clientDisplayedColumns, adminDisplayedColumns, defaultTab = 'admin', onClose, onSubmit} = props;

  const lang = useLanguage('PARTICIPANTS_LIST');

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const dispatch: ApiDispatch = useDispatch();

  const [activeTab, setActiveTab] = useState<'admin' | 'client'>(defaultTab);

  const [clientColumns, setClientColumns] = useState(clientDisplayedColumns);
  const [adminColumns, setAdminColumns] = useState(adminDisplayedColumns);
  const userId = getUserId();

  const onChangeColumns = activeTab === 'admin' ? setAdminColumns : setClientColumns;

  const onSave = async () => {
    const adminIsEqual = isEqual(adminDisplayedColumns, adminColumns);
    const clientIsEqual = isEqual(clientDisplayedColumns, clientColumns);
    const promises = [];

    if (!adminIsEqual) {
      const adminBody = prepareBody(adminDisplayedColumns, adminColumns, true);
      promises.push(
        dispatch(
          updateDisplayColumns(
            {
              user: {
                display_items: Object.values(adminBody).flat(),
              },
            },
            userId,
          ),
        ),
      );
    }

    if (!clientIsEqual) {
      const clientBody = prepareBody(clientDisplayedColumns, clientColumns, false);
      promises.push(
        dispatch(
          updateColumnVisibilityItems({
            column_visibility_item: Object.values(clientBody).flat(),
          }),
        ),
      );
    }

    setLoadingSubmit(true);
    await Promise.all(promises).finally(() => {
      setLoadingSubmit(false);
      onSubmit();
    });
  };

  return (
    <Modal
      show
      dialogClassName={b()}
      bodyClassName={b('modal-body')}
      contentClassName={b('modal-content')}
      onClose={onClose}
      title={lang.TABLE_SETTINGS_MODAL_TITLE}
    >
      <ParticipantsTableSettingBase
        className={b('container')}
        activeTab={activeTab}
        onChangeTab={setActiveTab}
        onChange={onChangeColumns}
        columns={activeTab === 'admin' ? adminColumns : clientColumns}
      />
      <div className={b('footer')}>
        <AppButton
          className="button button--bg-4 modal-window__footer-btn"
          onClick={onSave}
          loading={loadingSubmit}
          disabled={loadingSubmit}
          color="games"
          size="medium"
          label={lang.TABLE_SETTINGS_MODAL_CONFIRM_BUTTON}
        />
      </div>
    </Modal>
  );
};

export default ParticipantsTableSettingsModal;
