import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import {useMount} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {getPathHash} from 'client/services/translations';

import {selectActiveLanguages, selectTranslationsArray} from 'client/ducks/translations/selectors';

import AppButton from 'client/common/buttons';
import {CheckboxField, TextField} from 'client/common/fields';
import {TextareaField} from 'client/common/fields';
import {TextInput} from 'client/common/inputs';
import Modal from 'client/common/modals/modal';
import SelectDropdown from 'client/common/selects/select-dropdown';
import {getUiIdClassName, getUiIdSelector} from 'client/common/ui-id/helpers';

import {getTranslationFormInitialValues} from './mappers';
import validate from './validate';

import {getTranslationDescendants} from '../mappers';

import './translations-modal.scss';

const b = bem('translations-modal');

const TranslationsModal = (props) => {
  const {translation, onSubmit, onClose, onDelete, handleSubmit} = props;
  const lang = useLanguage('TRANSLATIONS.FORM');
  const translations = useSelector(selectTranslationsArray);
  const activeLanguages = useSelector(selectActiveLanguages);
  const isLoading = useSelector((state) => state.translations.isLoading);
  const {formValues = {}, change, initialize, valid} = useReduxForm(TranslationsModal.formName);

  const parentIdOptions = useMemo(() => {
    let descendants = translations;
    if (formValues.id) {
      const translationDescendantsIds = getTranslationDescendants(translations, formValues.id).map(({id}) => id);
      descendants = translations.filter(({id, translation_items}) => {
        return id !== formValues.id && translationDescendantsIds.indexOf(id) === -1 && !translation_items?.length;
      });
    }

    return [
      {label: lang.NO_PARENT, value: ''},
      ...descendants.map(({id, path}) => ({
        label: path,
        value: id,
      })),
    ];
  }, [formValues.id, lang, translations]);

  const uiIdSelector = useMemo(() => {
    const translationPathHash = getPathHash(`${translation.parentPath}.${translation.key}`);
    return getUiIdSelector(getUiIdClassName(translationPathHash));
  }, [translation.key, translation.parentPath]);

  useMount(() => {
    initialize(
      getTranslationFormInitialValues(
        translation,
        activeLanguages.map(({name}) => name),
      ),
    );
  });

  if (!translation) {
    return null;
  }

  const changeParentNode = ({value}) => {
    change('parent_id', value);
  };

  return (
    <Modal title={translation.id ? lang.TITLE_EDIT : lang.TITLE_ADD} onClose={onClose}>
      <div className={b()}>
        <form className={b('form')} onSubmit={handleSubmit(onSubmit)}>
          <div className={b('form-body')}>
            <TextField
              className={b('text-field')}
              name="key"
              label={lang.KEY_LABEL}
              withWrap
              normalize={(value) => value.toUpperCase()}
            />
            <TextareaField className={b('text-field')} name="description" label={lang.DESCRIPTION_LABEL} withWrap />
            <SelectDropdown
              className={b('select-field')}
              label={lang.PARENT_ID_LABEL}
              name="parent_id"
              value={formValues.parent_id}
              options={parentIdOptions}
              onChange={changeParentNode}
              searchable
              withWrap
            />
            <TextInput
              className={b('text-field')}
              label={lang.UI_ID_SELECTOR_LABEL}
              value={uiIdSelector}
              readOnly
              withWrap
            />
            <div className={b('with-translations-wrap')}>
              <CheckboxField
                withWrap
                className={b('with-translations-checkbox')}
                label={lang.WITH_TRANSLATIONS}
                name="withTranslations"
                inversionColor={true}
                disabled={translation.hasChildren || !!translation.translation_items?.length}
              />
            </div>
            {formValues.withTranslations &&
              activeLanguages?.map(({name: language}) => (
                <TextField
                  withWrap
                  key={language}
                  className={b('text-field')}
                  name={`items[${language}][value]`}
                  label={`${language.toUpperCase()} ${lang.TRANSLATION_LABEL}`}
                />
              ))}
          </div>
          {translation.error && <div className={b('form-error')}>{translation.error}</div>}
          <div className={b('form-buttons')}>
            <AppButton label={lang.SAVE} loading={isLoading} submit={true} disabled={!valid} />
            <AppButton label={lang.CANCEL} onClick={onClose} transparent={true} />
            {translation.id && (
              <AppButton label={lang.DELETE} className={b('delete-btn')} onClick={onDelete} color="error" />
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

TranslationsModal.formName = 'TranslationForm';

TranslationsModal.propTypes = {
  translation: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

TranslationsModal.defaultProps = {
  translation: null,
};

const TranslationsModalForm = reduxForm({
  form: TranslationsModal.formName,
  // shouldValidate: () => true,
  validate,
})(TranslationsModal);

export default connect((state) => ({
  // To use in validate function
  langErrors: state.languageState.payload.TRANSLATIONS.ERRORS,
}))(TranslationsModalForm);
