import React, {useMemo} from 'react';

import filter from 'lodash/filter';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';

import {useLanguage} from 'client/services/hooks';

import AnimationReportingTable from 'client/components/animations/components/animation-reporting-table';
import {KPI_TYPES} from 'client/components/animations/constants';
import {sortQuestions} from 'client/components/animations/helpers';

export const renderArrayAnswers = (answers, type) => {
  if (Array.isArray(answers) && answers.length > 1) {
    return (
      <div>
        {answers?.map((answer) => (
          <div key={uniqueId('answer')}>{answer}</div>
        ))}
      </div>
    );
  }

  const answer = Array.isArray(answers) ? answers[0] : answers;

  if (type === KPI_TYPES.STAT) {
    return parseFloat((+answer).toFixed(3));
  }

  return answer;
};

const AnimationMonitoringKpiList = ({className, data: rawData, type, tableClassName}) => {
  const lang = useLanguage('ANIMATIONS.LISTS.ANIMATION_MONITORING_KPI_LIST');
  const title = type === 'report' ? lang.MONITORING_TITLE : lang.KPI_TITLE;

  const data = useMemo(
    () =>
      sortQuestions(filter(rawData)).map((question) => ({
        question: question.title,
        answer: question.data,
        type: question.type,
      })),
    [rawData],
  );

  const columns = [
    {
      name: 'question',
      path: 'question',
      width: '40%',
      label: lang.QUESTION_COLUMN,
      title: true,
    },
    {
      name: 'answer',
      path: 'answer',
      label: lang.ANSWER_COLUMN,
      render: ({value: answer, item}) => renderArrayAnswers(answer, item.type) || '–',
      hintRender: ({answer, type: answerType}) =>
        typeof renderArrayAnswers(answer, answerType) === 'object' ? renderArrayAnswers(answer, answerType) : null,
    },
  ];

  return (
    <AnimationReportingTable
      className={className}
      tableClassName={tableClassName}
      title={title}
      columns={columns}
      data={data}
      noDataHeight={250}
    />
  );
};

AnimationMonitoringKpiList.propTypes = {
  className: PropTypes.string,
  tableClassName: PropTypes.string,
  type: PropTypes.oneOf(['report', 'kpi']).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value_num: PropTypes.number,
      value_string: PropTypes.string,
      task_kpi: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
};

AnimationMonitoringKpiList.defaultProps = {
  tableClassName: '',
  className: '',
};

export default AnimationMonitoringKpiList;
