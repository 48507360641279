import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import './winnings-table-type-cell.scss';

const b = bem('winnings-table-type-cell');

const WinningsTableTypeCell = (props) => {
  const {item} = props;
  const lang = useLanguage('CLIENT_AUTOTASK.WINNINGS_TAB.WINNINGS_TABLE.CELLS.WINNINGS_TABLE_TYPE_CELL');
  const subType = item.sub_type ? lang[item.sub_type.toUpperCase()] || item.sub_type.replaceAll('_', ' ') : null;

  return (
    <div className={b()}>
      {item.type && <p className={b('type')}>{item.type.replaceAll('_', ' ')}</p>}
      {item.sub_type && <p className={b('subtype')}>{subType}</p>}
    </div>
  );
};

WinningsTableTypeCell.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string,
    sub_type: PropTypes.string,
  }).isRequired,
};

export default WinningsTableTypeCell;
