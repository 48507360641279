import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

// ------------------------------
//     MESSAGE TASK ACTIONS
// ------------------------------

export function getMessageTasks(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MESSAGE_TASKS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_MESSAGE_TASKS_REQUEST, types.GET_MESSAGE_TASKS_SUCCESS, types.GET_MESSAGE_TASKS_ERROR],
      queryParams,
    });
}

export function getMessageTask(id, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MESSAGE_TASKS}/${id}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_MESSAGE_TASK_REQUEST, types.GET_MESSAGE_TASK_SUCCESS, types.GET_MESSAGE_TASK_ERROR],
      queryParams,
    });
}

export function updateMessageTask(id, body = {}, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MESSAGE_TASKS}/${id}`,
      method: 'PUT',
      types: [types.UPDATE_MESSAGE_TASK_REQUEST, types.UPDATE_MESSAGE_TASK_SUCCESS, types.UPDATE_MESSAGE_TASK_ERROR],
      body,
      queryParams,
    });
}

export function deleteMessageTask(id) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MESSAGE_TASKS}/${id}`,
      method: 'DELETE',
      types: [types.DELETE_MESSAGE_TASK_REQUEST, types.DELETE_MESSAGE_TASK_SUCCESS, types.DELETE_MESSAGE_TASK_ERROR],
    });
}

export function getMessages(id, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MESSAGE_TASKS}/${id}${API_METHODS.MESSAGES}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_MESSAGES_REQUEST, types.GET_MESSAGES_SUCCESS, types.GET_MESSAGES_ERROR],
      queryParams,
    });
}

export function toggleMessageTaskState(taskId, body = {}, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MESSAGE_TASKS}/${taskId}${API_METHODS.MESSAGE_TASK_TOGGLE_STATE}`,
      method: 'PUT',
      types: [types.TOGGLE_MESSAGE_TASK_REQUEST, types.TOGGLE_MESSAGE_TASK_SUCCESS, types.TOGGLE_MESSAGE_TASK_ERROR],
      body,
      queryParams,
    });
}

export function deleteMappingItems(queryParams) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MESSAGE_TASK_MAPPING_ITEMS}`,
      method: 'DELETE',
      types: [types.DELETE_MAPPING_ITEMS_REQUEST, types.DELETE_MAPPING_ITEMS_SUCCESS, types.DELETE_MAPPING_ITEMS_ERROR],
      queryParams,
    });
}

export function flushMessageTaskAllState() {
  return {
    type: types.FLUSH_MESSAGE_TASK_ALL_STATE,
  };
}

// ------------------------------
//       SMS TASK ACTIONS
// ------------------------------
export function createSmsTask(body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SMS_TASKS}`,
      method: 'POST',
      types: [types.CREATE_SMS_TASK_REQUEST, types.CREATE_SMS_TASK_SUCCESS, types.CREATE_SMS_TASK_ERROR],
      body,
    });
}

// ------------------------------
//       EMAIL TASK ACTIONS
// ------------------------------
export function createEmailTask(body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.EMAIL_TASKS}`,
      method: 'POST',
      types: [types.CREATE_EMAIL_TASK_REQUEST, types.CREATE_EMAIL_TASK_SUCCESS, types.CREATE_EMAIL_TASK_ERROR],
      body,
    });
}
