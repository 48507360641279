import React, {memo, useCallback} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Icon from 'client/components/common/icon';

import {MEDIA_LEVELS} from 'client/components/medias/constants';

import './medias-order-cell.scss';

const b = bem('medias-order-cell');

const swapMedia = (index, up, medias, change) => {
  const newMedias = [...medias];
  const firstIndex = index;
  const secondIndex = up ? index - 1 : index + 1;

  [newMedias[firstIndex], newMedias[secondIndex]] = [newMedias[secondIndex], newMedias[firstIndex]];

  [newMedias[firstIndex].default_order, newMedias[secondIndex].default_order] = [
    newMedias[secondIndex].default_order,
    newMedias[firstIndex].default_order,
  ];

  change('medias', newMedias);
};

const MediasOrderCell = memo((props) => {
  const {index, item, data, level, medias, change, isLocal} = props;

  const compareLevel = isLocal ? MEDIA_LEVELS.DEVICE : level;

  const lastSwappingMediaIndex = data.reduce((result, media, currentIndex) => {
    return media.level === compareLevel ? currentIndex : result;
  }, 0);

  const swapMediaUp = useCallback(() => {
    swapMedia(index, true, medias, change);
  }, [index, medias, change]);

  const swapMediaDown = useCallback(() => {
    swapMedia(index, false, medias, change);
  }, [index, medias, change]);

  return (
    <div className={b()}>
      {item.level === compareLevel ? (
        <div className={b('swap')}>
          {index > 0 && index <= lastSwappingMediaIndex && <Icon name="triangle-up" onClick={swapMediaUp} />}
          {index < lastSwappingMediaIndex && <Icon name="triangle-down" onClick={swapMediaDown} />}
        </div>
      ) : (
        <Icon name={`level-${item.level}`} />
      )}
      <div className={b('order', {additional: item.level !== compareLevel})}>{index + 1}</div>
    </div>
  );
});

MediasOrderCell.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  level: PropTypes.string.isRequired,
  medias: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  isLocal: PropTypes.bool.isRequired,
};

export default MediasOrderCell;
