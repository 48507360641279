import React, {CSSProperties} from 'react';

import {RenderLeafProps} from 'slate-react';

import bem from 'client/services/bem';

import {CustomText, FONT_FAMILY_OPTIONS} from 'client/common/text-editor/constants';

import cssModule from './leaf.module.scss';

const b = bem('leaf', {cssModule});

type LeafProps = RenderLeafProps & {
  leaf: CustomText;
  style?: CSSProperties;
};

const Leaf: React.FC<LeafProps> = (props) => {
  const {style} = props;
  const TagName = props.leaf.url ? 'a' : 'span';
  return (
    <TagName
      {...props.attributes}
      className={b({
        bold: props.leaf.bold,
        italic: props.leaf.italic,
        underline: props.leaf.underline,
        ['line-through']: props.leaf['line-through'],
      })}
      style={{
        color: props.leaf.color,
        fontSize: props.leaf['font-size'],
        fontFamily: props.leaf['font-family'] || FONT_FAMILY_OPTIONS[0],
        ...style,
      }}
      {...(props.leaf.url && {href: props.leaf.url})}
    >
      {props.children}
    </TagName>
  );
};

export default Leaf;
