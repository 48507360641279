import filter from 'lodash/filter';
import get from 'lodash/get';
import moment from 'moment/moment';
import {getFormValues} from 'redux-form';

import {COLUMN_TYPES} from 'client/common/config';

export function selectEditedFields(state, formName) {
  const values = getFormValues(formName)(state);
  let birth_date = moment(get(values, 'birth_date'), 'DD/MM/YYYY').toISOString(true);
  let first_name = get(values, 'first_name');
  let last_name = get(values, 'last_name');
  let email = get(values, 'email');
  let phone = get(values, 'phone');
  let gender = get(values, 'gender.value');
  let comment = get(values, 'comment');

  return {
    lead: {
      birth_date,
      first_name,
      last_name,
      email,
      phone,
      gender: gender === 'null' ? '' : gender,
      comment,
    },
  };
}
export function selectEditedCustomFields(state, formName) {
  const values = getFormValues(formName)(state) || {};
  const fileColumns = filter(state.lead.payload.external_column_names, (i) => i.kind === COLUMN_TYPES.FILE);
  const filteredValues = Object.entries(values).reduce(
    (result, [xKey, value]) => ({
      ...result,
      [xKey.slice(1)]: value,
    }),
    {},
  );

  fileColumns.forEach(({id}) => delete filteredValues[id]);

  return {
    lead: {
      data: {
        ...filteredValues,
      },
    },
  };
}

export function selectLead(state) {
  return state.lead.payload;
}

function selectUser(state, report) {
  const clientUsers = get(state.clientUsers, 'clientUsers');
  const membershipId = get(report, 'lead_transfer.membership_id', null);
  let userAllowed = '';
  clientUsers.forEach((el) => {
    if (el.memberships.some((membership) => membership.id === membershipId)) {
      userAllowed = el.full_name;
    }
  });
  return userAllowed;
}

export function selectTableReport(state, isImport) {
  const report = isImport ? state.leadsImport.importReport : state.leadsExport.exportReport;
  return {
    id: report.id,
    type: get(report.lead_transfer, 'type', ''),
    date: moment(report.created_at).format('DD/MM/YYYY'),
    updatedDate: moment(report.updated_at).format('DD/MM/YYYY'),
    total: report.total,
    updated: report.updated,
    created: report.created,
    rejected: report.total - report.created - report.updated,
    format: get(report.lead_transfer, 'format'),
    hasErrors: get(report.file, 'url'),
    comment: get(report.lead_transfer, 'comment'),
    url: get(report.file, 'url', ''),
    userName: get(report.user, 'full_name'),
    clientId: get(report.lead_transfer, 'client_id'),
    clientName: get(report.lead_transfer, 'client.name'),
    clientType: get(report.lead_transfer, 'client.type'),
    reportName: get(report.lead_transfer, 'name'),
    transferId: report.lead_transfer_id,
    userAllowed: selectUser(state, report),
    leadTransferId: get(report, 'lead_transfer.id', ''),
    dateFrom: moment(get(report, 'lead_transfer.from', '')).format('DD/MM/YYYY'),
    dateTo: moment(get(report, 'lead_transfer.to', '')).format('DD/MM/YYYY'),
    status: get(report, 'status'),
    leadsFile: get(report, 'leads_file'),
    memberships: get(report, 'memberships'),
    allowed_for_all: get(report, 'lead_transfer.allowed_for_all'),
  };
}

export function selectImportExportReports(state) {
  const reports = state.lead.leadsReports;
  return reports.map((report) => {
    return {
      type: get(report, 'lead_transfer.type', ''),
      date: moment(report.created_at).format('DD/MM/YYYY'),
      updatedDate: moment(report.updated_at).format('DD/MM/YYYY'),
      total: report.total,
      updated: report.updated,
      created: report.created,
      rejected: report.rejected,
      format: get(report, 'lead_transfer.format', ''),
      hasErrors: report.rejected,
      comment: get(report, 'lead_transfer.comment', ''),
      url: get(report, 'file.url', ''),
      userName: get(report, 'user.full_name', ''),
      dateFrom: moment(get(report, 'lead_transfer.from', '')).format('DD/MM/YYYY'),
      dateTo: moment(get(report, 'lead_transfer.to', '')).format('DD/MM/YYYY'),
      leadTransferId: get(report, 'lead_transfer.id', ''),
      id: report.id,
      userAllowed: selectUser(state, report),
      status: get(report, 'status'),
      leadsFile: get(report, 'leads_file'),
      memberships: get(report, 'lead_transfer.memberships'),
      allowed_for_all: get(report, 'lead_transfer.allowed_for_all'),
    };
  });
}
