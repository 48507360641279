import React from 'react';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';
import LayoutPanel from 'client/common/panels/layout-panel';

import {
  AFFECTATION_ICON_STATUSES,
  AFFECTATION_STATUSES,
  AffectationStatus,
  AffectationStep,
} from 'client/models/device-affectations';
import {Translation} from 'client/models/language/types';

import {stepIsBefore} from './helpers';

import cssModule from './road.module.scss';

const b = bem('road', {cssModule});

type DeliveryRoadProps = {
  startIcon: string;
  disabledStartIcon: string;
  endIcon: string;
  disabledEndIcon: string;
  currentStep?: AffectationStep;
  currentStatus?: AffectationStatus;
  steps: {
    title?: Translation;
    titleSide?: 'right' | 'left';
    value?: AffectationStep;
    isDote?: boolean;
    exclude?: boolean;
    subStep?: {
      title?: Translation;
      align?: 'left' | 'right';
    } | null;
  }[];
};

const Road: React.FC<DeliveryRoadProps> = ({
  startIcon,
  disabledStartIcon,
  disabledEndIcon,
  endIcon,
  steps,
  currentStep,
  currentStatus,
}) => {
  const disabled = !currentStep || (steps[0].value === currentStep && !currentStatus);

  const renderStep = (step: DeliveryRoadProps['steps'][number]) => {
    if (step.isDote) {
      return <div className={b('step-status', ['dote'])} />;
    }

    if (!currentStep) {
      return <div className={b('empty-status')} />;
    }

    if (step.value === currentStep) {
      return currentStatus ? (
        <Icon name={AFFECTATION_ICON_STATUSES[currentStatus]} />
      ) : (
        <div className={b('empty-status')} />
      );
    }
    if (step.value && stepIsBefore(currentStep, step.value)) {
      return <Icon name={AFFECTATION_ICON_STATUSES[AFFECTATION_STATUSES.OK]} />;
    }
    return <div className={b('empty-status')} />;
  };

  return (
    <LayoutPanel className={b({disabled})}>
      <div className={b('road')}>
        <div className={b('start-icon', {disabled})}>
          <Icon name={disabled ? disabledStartIcon : startIcon} />
        </div>
        <div className={b('steps')}>
          <div className={b('steps-line')} />
          {steps.map(
            (step, index) =>
              !step.exclude && (
                <div className={b('step')} key={index}>
                  <div className={b('step-content', {[step.titleSide || 'right']: true, dote: step.isDote})}>
                    {step.titleSide === 'left' && <div className={b('step-title')}>{step.title}</div>}
                    <div className={b('step-status')}>{renderStep(step)}</div>
                    {(step.titleSide === 'right' || !step.titleSide) && (
                      <div className={b('step-title', {disabled})}>{step.title}</div>
                    )}
                  </div>
                  {step.subStep && (
                    <div className={b('sub-step')}>
                      <div className={b('sub-step-title', [`align-${step.subStep.align || 'left'}`])}>
                        {step.subStep.title}
                      </div>
                      <div className={b('sub-step-status')} />
                    </div>
                  )}
                </div>
              ),
          )}
        </div>
        <div className={b('end-icon')}>
          <Icon name={disabled ? disabledEndIcon : endIcon} />
        </div>
      </div>
    </LayoutPanel>
  );
};

export default Road;
