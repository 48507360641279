import React, {useEffect} from 'react';

import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {useReduxFetch} from 'client/services/hooks';

import {getExportsCount} from 'client/ducks/statistic-exports/actions';
import {selectExportsCount} from 'client/ducks/statistic-exports/selectors';

import AppButton from 'client/common/buttons/app-button';
import CustomCard from 'client/common/cards/custom-card';
import {MEMBERSHIPS_TYPES} from 'client/common/config';

import GenerateStatisticFileModal from 'client/components/statistic-exports/modals/generate-statistic-file-modal';
import ViewStatisticFilesModal from 'client/components/statistic-exports/modals/view-statistic-files-modal';
import {Autotask} from 'client/models/autotask/types';
import {Membership} from 'client/models/memberships/types';
import {useWs, WS_CHANNELS, WsMessage, WsStatisticExportMessage} from 'client/providers/ws';

import cssModule from './statistic-exports-block.module.scss';

const b = bem('statistic-exports-block', {cssModule});

type StatisticExportsBlockProps = {
  autotask: Autotask;
  membership: Membership;
  loading: boolean;
};

const StatisticExportsBlock: React.FC<StatisticExportsBlockProps> = (props) => {
  const {autotask, membership, loading} = props;
  const lang = useLanguage('STATISTIC_EXPORTS.BLOCKS.STATISTIC_EXPORTS_BLOCK');
  const [showGenerateModal, toggleGenerateModal] = useToggle(false);
  const [showFilesModal, toggleFilesModal] = useToggle(false);
  const {lastJsonMessage} = useWs();

  const {data: exportsCount = 0, fetch: fetchTotal} = useReduxFetch({
    action: getExportsCount,
    actionArgs: autotask.id,
    selector: selectExportsCount,
  });

  useEffect(() => {
    if (lastJsonMessage && 'message' in lastJsonMessage) {
      if (lastJsonMessage.identifier === WS_CHANNELS.STATISTIC_EXPORTS) {
        const {message} = lastJsonMessage as WsMessage<WsStatisticExportMessage>;
        if (message.success && message.record?.automation_task_id === autotask.id) {
          fetchTotal();
        }
      }
    }
  }, [autotask.id, fetchTotal, lastJsonMessage]);

  const totalLabel = exportsCount ? ` (${exportsCount})` : '';

  return (
    <CustomCard format="column" className={b()} iconName="kpi-export" title={lang.TITLE} loading={loading}>
      <div className={b('buttons')}>
        <AppButton
          label={lang.GENERATE_FILE}
          color="light-clients"
          disabled={!autotask.started_interactions_count || membership.type === MEMBERSHIPS_TYPES.AGENCY}
          onClick={toggleGenerateModal}
        />
        <AppButton
          label={`${lang.VIEW_FILES}${totalLabel}`}
          color="light-clients"
          disabled={!exportsCount}
          onClick={toggleFilesModal}
        />
        {showGenerateModal && (
          <GenerateStatisticFileModal autotask={autotask} membership={membership} onClose={toggleGenerateModal} />
        )}
        {showFilesModal && (
          <ViewStatisticFilesModal autotask={autotask} onClose={toggleFilesModal} onDelete={fetchTotal} />
        )}
      </div>
    </CustomCard>
  );
};

export default StatisticExportsBlock;
