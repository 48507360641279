import React from 'react';

import PropTypes from 'prop-types';

import Popover from 'client/common/popovers/popover';

import Icon from 'client/components/common/icon';

function BCSettingsStepPopover(props) {
  const {texts} = props;

  return (
    <Popover
      shiftLeft={5}
      arrowShiftLeft={5}
      overlayInnerStyle={{
        width: 420,
      }}
      overlay={
        <div className="main-text main-text--small">
          {texts.map((item, i) => {
            return (
              <div
                key={i}
                style={{
                  marginBottom: i === 0 ? 15 : 0,
                }}
              >
                {item}
              </div>
            );
          })}
        </div>
      }
    >
      <Icon name="info" className="theme-color-15 bc-step__info-icon" />
    </Popover>
  );
}

BCSettingsStepPopover.propTypes = {
  texts: PropTypes.array.isRequired,
};

export default BCSettingsStepPopover;
