import types from './types';

const initialState = {
  smsTask: {},
  emailTask: {},
  taskComments: {},
};

export default function (_state = initialState, action) {
  let state = {..._state};

  switch (action.type) {
    case types.GET_SMS_TASK_SUCCESS:
      state.smsTask = action.payload.sms_task;
      return state;

    case types.GET_EMAIL_TASK_SUCCESS:
      state.emailTask = action.payload.email_task;
      return state;

    case types.GET_TASK_COMMENTS_SUCCESS:
      state.taskComments = action.payload;

      return state;

    case types.GET_MORE_TASK_COMMENTS_SUCCESS:
      state.taskComments = {
        meta: action.payload.meta,
        task_comments: [...state.taskComments.task_comments, ...action.payload.task_comments],
      };

      return state;

    case types.ADD_TASK_COMMENT_SUCCESS:
      state.taskComments = {
        meta: state.taskComments.meta,
        task_comments: [action.payload.task_comment, ...state.taskComments.task_comments],
      };

      return state;

    default:
      return state;
  }
}
