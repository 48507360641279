import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useRouterPrompt from 'client/services/hooks/useRouterPrompt';

import AppButton from 'client/common/buttons/app-button';
import Modal from 'client/common/modals/modal';

import {ClosePrompt} from 'client/components/email-template-editor/types';

import cssModule from './close-editor-modal.module.scss';

const b = bem('close-editor-modal', {cssModule});

type RouterPromptProps = {
  when: boolean;
  templateName: string;
  onSave: () => void;
  onCancel: () => void;
  onRestore: () => Promise<void>;
  promptType: ClosePrompt;
};

const CloseEditorModal: React.FC<RouterPromptProps> = (props) => {
  const {when, templateName, onCancel, onSave, onRestore, promptType} = props;
  const langBase = useLanguage('EMAIL_TEMPLATE_EDITOR.MODALS.CLOSE_EDITOR_MODAL');
  const lang = promptType === 'language' ? langBase.LANGUAGE : langBase.COMMON;

  const {showPrompt, denyTransition, allowTransition} = useRouterPrompt(when);

  const handleCancel = () => {
    onCancel();
    denyTransition();
  };

  const handleRestore = async () => {
    await onRestore();
    allowTransition();
  };

  const handleSave = () => {
    onSave();
    allowTransition();
  };

  return showPrompt ? (
    <Modal dialogClassName={b()} title={lang.TITLE} onClose={handleCancel} show>
      <p className={b('message')}>{lang.MESSAGE.replace('{{name}}', templateName)}</p>
      <div className={b('buttons')}>
        <AppButton label={lang.CANCEL} onClick={handleCancel} transparent />
        <AppButton label={lang.CLOSE_WITHOUT_SAVING} onClick={handleRestore} transparent />
        <AppButton label={lang.SAVE} onClick={handleSave} size="small" />
      </div>
    </Modal>
  ) : null;
};

export default CloseEditorModal;
