import React, {PureComponent} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './img-preview.scss';

export default class ImgPreview extends PureComponent {
  static propTypes = {
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
    imgWrapClass: PropTypes.string,
    imgDescrClass: PropTypes.string,
    imgTitleClass: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.any,
    descr: PropTypes.any,
    children: PropTypes.any,
  };

  static defaultProps = {
    src: null,
    imgWrapClass: '',
    imgDescrClass: '',
    imgTitleClass: '',
    className: '',
    title: '',
    descr: '',
    children: '',
  };

  state = {
    image: null,
  };

  componentDidMount() {
    this.updateImage(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.updateImage(this.props);
    }
  }

  updateImage = (props) => {
    if (props.src instanceof File) {
      const reader = new FileReader();

      reader.onload = () => {
        const image = reader.result;

        this.setState({
          image,
        });
      };

      reader.readAsDataURL(props.src);
    } else {
      this.setState({
        image: null,
      });
    }
  };

  isImagePresent = (src) => !!src;

  renderImage = (src) => {
    const {imgWrapClass} = this.props;

    if (this.isImagePresent(src)) {
      return (
        <div className={`img-preview__img-wrap ${imgWrapClass}`}>
          <span
            className="img-preview__img"
            style={{
              backgroundImage: `url("${src}")`,
            }}
          />
        </div>
      );
    }

    return null;
  };

  render() {
    const {src, className, title, descr, children, imgDescrClass, imgTitleClass} = this.props;

    const {image} = this.state;

    return (
      <div className={cn('img-preview', className)}>
        {this.renderImage(image || src)}
        <div
          className={cn('img-preview__descr', imgDescrClass, {
            'img-preview__descr--no-image': !this.isImagePresent(src),
          })}
        >
          <div className={`img-preview__title ${imgTitleClass}`}>{title}</div>
          <div className="img-preview__size">{descr}</div>
          {children}
        </div>
      </div>
    );
  }
}
