import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Modal from 'client/common/modals/modal';

import AddPlaceForm from 'client/components/places/components/modals/add-place-modal/add-place-form';

const EditStoreModal = ({show, onClose, editingStore, onEditStore, disabled, lang}) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      dialogClassName="modal-window--width-1 modal-window--theme-3"
      title={lang.EDIT_STORE_TITLE}
    >
      <AddPlaceForm initialValues={editingStore} isStore />

      <div className="modal-window__footer">
        <button className="button button--bg-11 modal-window__footer-btn" onClick={onClose}>
          {lang.CANCEL_BUTTON}
        </button>
        <button
          type="submit"
          className="button button--bg-3 modal-window__footer-btn"
          onClick={onEditStore}
          disabled={disabled}
        >
          {lang.SAVE_BUTTON}
        </button>
      </div>
    </Modal>
  );
};

EditStoreModal.propTypes = {
  editingStore: PropTypes.object.isRequired,
  onEditStore: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
};

EditStoreModal.defaultProps = {
  show: false,
  disabled: false,
};

export default connect((state) => ({
  lang: state.languageState.payload.STORES,
}))(EditStoreModal);
