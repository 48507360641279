import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

import {callApi} from '../../services/call-api';

export function getUserAllClients(userId, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENT_USERS}/${userId}`,
      method: 'GET',
      types: [types.GET_USER_CLIENTS_REQUEST, types.GET_USER_CLIENTS_SUCCESS, types.GET_USER_CLIENTS_ERROR],
      noCache: true,
      queryParams,
    });
}

export function setUserAllClients(clients) {
  return {
    type: types.SET_USER_CLIENTS,
    payload: clients,
  };
}

export function setCurrentOrganization(client) {
  return {
    type: types.SET_CURRENT_ORGANIZATION,
    payload: client,
  };
}
