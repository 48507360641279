import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import {urlWithoutLastSlash} from 'client/services/helpers';

import {CLIENT_PAGES} from 'client/common/config';
import CountBlock from 'client/common/count-block';

import {TranslationJsx} from 'client/models/language/types';

import InfoBlock from '../info-block';

class VisualsCard extends Component {
  static propTypes = {
    visualsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    location: PropTypes.object.isRequired,
    title: TranslationJsx,
    totalText: TranslationJsx,
    itemText: TranslationJsx,
    visualsURL: PropTypes.string,
    underLinkDescription: TranslationJsx,
    goToLinkText: TranslationJsx,
    noMargin: PropTypes.bool,
  };

  static defaultProps = {
    visualsCount: 0,
    title: '',
    goToLinkText: '',
    underLinkDescription: '',
    itemText: '',
    totalText: '',
    noMargin: false,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      visualsCount,
      title,
      goToLinkText,
      visualsURL,
      itemText,
      location,
      totalText,
      underLinkDescription,
      noMargin,
    } = this.props;

    const url = visualsURL || `${urlWithoutLastSlash(location.pathname)}${CLIENT_PAGES.VISUALS}`;

    return (
      <InfoBlock colorTheme="visuals" className={noMargin ? '' : 'margin-top--8'}>
        <InfoBlock.Title>{title}</InfoBlock.Title>
        <InfoBlock.Content headerOnly>
          <InfoBlock.Header alone>
            <CountBlock totalText={totalText} itemText={itemText} num={visualsCount} className="pull-left" />
            {visualsCount !== 0 && (
              <InfoBlock.HeaderLink
                link={{
                  url: url,
                  text: goToLinkText,
                }}
                description={underLinkDescription}
              />
            )}
          </InfoBlock.Header>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

export default withRouter(VisualsCard);
