export default (values, lang) => {
  const errors = {};
  if (!values?.block_type) {
    errors.block_type = lang.REQUIRED_ERROR;
  }

  if (!values?.task_kpis?.length) {
    errors.task_kpis = lang.REQUIRED_ERROR;
  }

  if (!values?.consolidation) {
    errors.consolidation = lang.REQUIRED_ERROR;
  }

  if (!values?.consolidation_mode) {
    errors.consolidation_mode = lang.REQUIRED_ERROR;
  }

  return errors;
};
