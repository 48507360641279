import {PROFILE_NO_DATA, PROFILE_LOADED, PROFILE_UPDATE_SUCCESS, PROFILE_DELETE_SUCCESS} from './profile.action';

let init = {
  type: PROFILE_NO_DATA,
  payload: {},
};

export default function (_state_ = init, action) {
  let state = Object.assign({}, _state_);
  switch (action.type) {
    case PROFILE_NO_DATA:
      state.type = PROFILE_LOADED;
      state.payload = action.payload;
      return state;
    case PROFILE_LOADED:
      state.type = PROFILE_LOADED;
      state.payload = action.payload;
      return state;
    case PROFILE_DELETE_SUCCESS:
      state.type = PROFILE_LOADED;
      state.payload = action.payload;
      return state;
    case PROFILE_UPDATE_SUCCESS:
      state.type = PROFILE_LOADED;
      state.payload.user = {
        ...state.payload.user,
        ...action.payload,
      };
      return state;
    default:
      return state;
  }
}
