import React from 'react';

import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {getMessages} from 'client/ducks/message-email-sms-task/actions';
import {selectMessagesMeta, selectResultTableDataMapping} from 'client/ducks/message-email-sms-task/selectors';

import {TASK_TYPES} from 'client/models/operations/constants';

import BCResultClicksModal from '../../../components/modals/bc-result-clicks-modal';
import BCResultFiltersModal from '../../../components/modals/bc-result-filters-modal';
import BCResultVarsModal from '../../../components/modals/bc-result-vars-modal';
import BCSMSResultVarsModal from '../../../components/modals/bc-sms-result-vars-modal';
import BCResultsDataGrid from '../../../components/tables/bc-results-data-grid';

class BCResultsTableContainer extends ReactQueryParams {
  static propTypes = {
    tableData: PropTypes.array.isRequired,
    taskId: PropTypes.string.isRequired,
    taskType: PropTypes.string.isRequired,
    taskState: PropTypes.string.isRequired,
    meta: PropTypes.object.isRequired,
    getMessages: PropTypes.func.isRequired,
  };

  static DEFAULT_FIRST_PAGE = 1;
  static DEFAULT_PER_PAGE = 5;
  static DEFAULT_SORT_FIELD = 'lead_first_name';
  static DEFAULT_SORT_ORDER = 'asc';
  static MODALS_MAP = {
    CLICKS_STAT: 'CLICKS_STAT',
    FILTERS: 'FILTERS',
    EMAIL_VARIABLES: 'EMAIL_VARIABLES',
    SMS_VARIABLES: 'SMS_VARIABLES',
  };

  constructor(props) {
    super(props);

    this.state = {
      clicksStatistic: [],
      variables: [],
      visibleModal: '',
      messageText: '',
    };
  }

  defaultQueryParams = {
    filters: JSON.stringify({}),
    search: '',
    sort: JSON.stringify({}),
    page: '',
    perPage: '',
  };

  componentDidMount() {
    this.getMessages(this.props.taskId);
  }

  handleModalShow = (name) => {
    this.setState({
      visibleModal: name,
    });
  };

  handleModalClose = () => this.setState({visibleModal: ''});

  handleSearch = (value) => {
    this.setQueryParams(
      {
        search: value,
        page: BCResultsTableContainer.DEFAULT_FIRST_PAGE,
      },
      true,
    );
    this.getMessages();
  };

  handlePageChange = (params) => {
    this.setQueryParams(params);
    return this.getMessages();
  };

  handleSortChange = ({sortField, sortOrder}) => {
    this.setQueryParams({
      sort: {
        name: sortField,
        order: sortOrder,
      },
    });

    return this.getMessages();
  };

  handleSearchChange = (value) => {
    this.setQueryParams(
      {
        search: value,
        page: BCResultsTableContainer.DEFAULT_FIRST_PAGE,
      },
      true,
    );

    this.getMessages();
  };

  handleSearchClear = () => {
    this.setQueryParams(
      {
        search: '',
      },
      true,
    );

    this.getMessages();
  };

  getMessages = () => {
    const {queryParams} = this;

    const sort =
      `${(queryParams.sort && queryParams.sort.name) || BCResultsTableContainer.DEFAULT_SORT_FIELD} ` +
      `${(queryParams.sort && queryParams.sort.order) || BCResultsTableContainer.DEFAULT_SORT_ORDER}`;

    const search = queryParams.search;
    const filters = BCResultFiltersModal.createFiltersQuery(queryParams.filters);

    const params = {
      q: {
        s: sort,
        g: {
          search: {
            m: 'or',
            lead_first_name_cont: search,
            lead_last_name_cont: search,
          },
          ...filters,
        },
      },
      page: queryParams.page || BCResultsTableContainer.DEFAULT_FIRST_PAGE,
      per_page: queryParams.perPage || BCResultsTableContainer.DEFAULT_PER_PAGE,
      include: {
        lead: null,
        email_template_link_clicks: 'email_template_link',
        message_task: 'message_task_mapping_items',
      },
    };
    window.scrollTo(0, 0);
    return this.props.getMessages(this.props.taskId, params);
  };

  handleOpenClicksModal = (clicksStatistic) => {
    this.setState({
      clicksStatistic,
      visibleModal: BCResultsTableContainer.MODALS_MAP.CLICKS_STAT,
    });
  };

  handleFiltersChange = (filters) => {
    this.setQueryParams({filters});
    this.getMessages();
  };

  handleClearFiltersClick = () => {
    this.setQueryParams({filters: {}});
    this.getMessages();
  };

  handleFilterDeleteClick = (filters) => (data) => {
    switch (data) {
      case BCResultFiltersModal.filtersMap.queued_event_successfully.field:
        this.setQueryParams({
          filters: omit(filters, [
            BCResultFiltersModal.filtersMap.queued_event_successfully_true.field,
            BCResultFiltersModal.filtersMap.queued_event_successfully_false.field,
            BCResultFiltersModal.filtersMap.queued_event_successfully_null.field,
          ]),
        });
        break;
      case BCResultFiltersModal.filtersMap.sent_event_successfully.field:
        this.setQueryParams({
          filters: omit(filters, [
            BCResultFiltersModal.filtersMap.sent_event_successfully_true.field,
            BCResultFiltersModal.filtersMap.sent_event_successfully_false.field,
            BCResultFiltersModal.filtersMap.sent_event_successfully_null.field,
          ]),
        });
        break;
      default:
        this.setQueryParams({
          filters: omit(filters, data),
        });
    }

    this.getMessages();
  };

  handleOpenEmailVarsModal = (variables) => {
    this.setState({
      variables,
      visibleModal: BCResultsTableContainer.MODALS_MAP.EMAIL_VARIABLES,
    });
  };

  handleOpenSmsVarsModal = (messageText) => {
    this.setState({
      messageText,
      visibleModal: BCResultsTableContainer.MODALS_MAP.SMS_VARIABLES,
    });
  };

  handleOpenVarsModal = () => {
    const {taskType} = this.props;
    return taskType === TASK_TYPES.SMS ? this.handleOpenSmsVarsModal : this.handleOpenEmailVarsModal;
  };

  render() {
    const {tableData, taskType, meta, taskState} = this.props;
    const {visibleModal, clicksStatistic, variables, messageText} = this.state;
    const {search, filters} = this.queryParams;

    return (
      <div>
        <BCResultClicksModal
          links={clicksStatistic}
          show={visibleModal === BCResultsTableContainer.MODALS_MAP.CLICKS_STAT}
          onClose={this.handleModalClose}
        />

        <BCResultVarsModal
          variables={variables}
          show={visibleModal === BCResultsTableContainer.MODALS_MAP.EMAIL_VARIABLES}
          onClose={this.handleModalClose}
        />

        <BCSMSResultVarsModal
          messageText={messageText}
          show={visibleModal === BCResultsTableContainer.MODALS_MAP.SMS_VARIABLES}
          onClose={this.handleModalClose}
        />

        <BCResultFiltersModal
          results={meta.total_count}
          filters={filters}
          taskType={taskType}
          taskState={taskState}
          show={visibleModal === BCResultsTableContainer.MODALS_MAP.FILTERS}
          onFormChange={this.handleFiltersChange}
          onClose={this.handleModalClose}
        />

        {taskType && (
          <BCResultsDataGrid
            queryParams={this.queryParams}
            taskType={taskType}
            taskState={taskState}
            data={tableData}
            meta={meta}
            onClicksCountClick={this.handleOpenClicksModal}
            onVarsCountClick={this.handleOpenVarsModal()}
            onShowFilterClick={() => this.handleModalShow(BCResultsTableContainer.MODALS_MAP.FILTERS)}
            onClearFiltersClick={this.handleClearFiltersClick}
            onFilterDeleteClick={this.handleFilterDeleteClick}
            onPageChange={this.handlePageChange}
            onSortChange={this.handleSortChange}
            onSearchChange={this.handleSearchChange}
            onSearchClear={this.handleSearchClear}
            defaultSearchValue={search}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  meta: selectMessagesMeta(state),
  tableData: selectResultTableDataMapping(state, ownProps.taskType),
});

const mapDispatchToProps = {
  getMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(BCResultsTableContainer);
