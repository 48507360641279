import {Reducer} from 'redux';

import {DeviceAffectation} from 'client/models/device-affectations';
import {PaginationMeta, getDefaultMeta} from 'client/types/common/index';

import types from './types';

type DeviceAffectationState = {
  deviceAffectations: {
    data: DeviceAffectation[];
    meta: PaginationMeta;
    unlinkedCount: number;
  };
};

const initialState: DeviceAffectationState = {
  deviceAffectations: {
    data: [],
    meta: getDefaultMeta(),
    unlinkedCount: 0,
  },
};

const DeviceAffectationReducer: Reducer<DeviceAffectationState> = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DEVICE_AFFECTATIONS_SUCCESS:
      return {
        ...state,
        deviceAffectations: {
          data: action.payload.device_affectations,
          meta: action.payload.meta,
          unlinkedCount: action.payload.meta.aggregations?.unlinked_count || 0,
        },
      };
    default:
      return state;
  }
};

export default DeviceAffectationReducer;
