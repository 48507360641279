import React from 'react';

import PropTypes from 'prop-types';
import {IMaskMixin} from 'react-imask';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {CheckboxField, TextareaField} from 'client/common/fields';
import {NumberField} from 'client/common/fields';

import './animation-event-fieldset.scss';

const b = bem('animation-event-fieldset');

const MaskedNumberField = IMaskMixin(({inputRef, ...props}) => <NumberField {...props} inputRef={inputRef} />);

const AnimationEventFieldset = ({readOnly}) => {
  const lang = useLanguage('ANIMATIONS.FIELDSETS.ANIMATION_EVENT_FIELDSET');

  return (
    <div className={b()}>
      <div className={b('controls')}>
        {/* IMaskMixin does not support readOnly mode */}
        {readOnly ? (
          <NumberField
            name="surface"
            label={lang.SURFACE}
            className={b('surface', ['read-only'])}
            labelPosition="left"
            readOnly
          />
        ) : (
          <MaskedNumberField
            name="surface"
            label={lang.SURFACE}
            className={b('surface')}
            labelPosition="top"
            mask={Number}
            max={999}
          />
        )}
        <CheckboxField
          color="primary"
          name="electric_plug"
          className={b('checkbox')}
          visualClassName={b('checkbox-visual')}
          label={lang.ELECTRICAL_OUTLET}
          readOnly={readOnly}
          inversionColor
        />
        <CheckboxField
          className={b('checkbox')}
          visualClassName={b('checkbox-visual')}
          color="primary"
          name="dock"
          label={lang.LOADING_DOCK}
          readOnly={readOnly}
          inversionColor
        />
        <CheckboxField
          className={b('checkbox')}
          visualClassName={b('checkbox-visual')}
          color="primary"
          name="stock"
          label={lang.RESERVED_ACCESS}
          readOnly={readOnly}
          inversionColor
        />
      </div>

      <TextareaField
        className={b('log')}
        textareaClassName={b('text-area')}
        name="log_conditions"
        label={lang.ACCESS_CONDITIONS}
        readOnly={readOnly}
      />
      <TextareaField textareaClassName={b('text-area')} name="comments" label={lang.COMMENTS} readOnly={readOnly} />
    </div>
  );
};

AnimationEventFieldset.propTypes = {
  readOnly: PropTypes.bool.isRequired,
};

export default AnimationEventFieldset;
