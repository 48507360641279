import sortBy from 'lodash/sortBy';

import {AffectationInfoMode, DeviceAffectation, DeviceAffectationInfo} from 'client/models/device-affectations';

export const filterComments = (infos: DeviceAffectationInfo[], filter?: AffectationInfoMode | 'all') => {
  const sorted = sortBy(
    infos.filter((i) => i.comment),
    'timestamp',
  ).reverse();
  if (!filter || filter === 'all') {
    return sorted[0]?.comment;
  }
  return sorted.filter((info) => info.mode === filter)[0]?.comment;
};

export const isMedia = (affectation: DeviceAffectation) => affectation.device?.mediaplayer_active;
