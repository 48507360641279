import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import AccountCard from '../account-card';

import './account-regions-stores.scss';

const renderRegion = (region, index) => {
  return (
    <div key={index} className="account-regions-stores__regions-item">
      <p className="account-regions-stores__regions-item-text">{region.name}</p>
      <p className="account-regions-stores__regions-item-text">{region.total_places}</p>
    </div>
  );
};

const AccountRegionsStores = (props) => {
  const {
    clientAccount: {client_regions, client_places},
    lang,
    loading,
  } = props;

  if (loading) {
    return <AccountCard className="account-regions-stores" loading={loading} />;
  }

  return (
    <AccountCard className="account-regions-stores">
      <div className="account-regions-stores__info">
        <div className="account-regions-stores__info-item">
          <p className="account-regions-stores__info-item-name">{lang.REGIONS}</p>
          <p className="account-regions-stores__info-item-value">{client_regions.total}</p>
        </div>
        <div className="account-regions-stores__info-item">
          <p className="account-regions-stores__info-item-name">{lang.STORES}</p>
          <p className="account-regions-stores__info-item-value">{client_places.total}</p>
        </div>
      </div>
      <CustomScrollbars
        scrollbarProps={{
          autoHeightMin: 330,
          autoHeightMax: 330,
        }}
      >
        <div className="account-regions-stores__regions">{client_regions.regions.map(renderRegion)}</div>
      </CustomScrollbars>
    </AccountCard>
  );
};

AccountRegionsStores.propTypes = {
  lang: PropTypes.object.isRequired,
  clientAccount: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  lang: state.languageState.payload.ACCOUNT_PARAMETERS.REGIONS_STORES,
}))(AccountRegionsStores);
