import {
  DIY_CONFIRMATION_TYPES,
  DIY_OPERATION_STATUSES,
  DIY_OPERATION_STATES,
} from 'client/components/diy-operation/constants';

export const getDiyOperationStatus = (operation) => {
  const {status, client_interface} = operation;

  if (status === DIY_OPERATION_STATUSES.FINISHED) {
    return status;
  }

  const webAppState = client_interface?.web_app?.state;
  const deviceState = client_interface?.device_state;

  const states = [webAppState, deviceState];

  const isActive = states.some((i) => [DIY_OPERATION_STATES.PUBLISHED, DIY_OPERATION_STATES.REPUBLISHED].includes(i));
  const isOnConfiguration = states.some((i) =>
    [DIY_OPERATION_STATES.CREATED, DIY_OPERATION_STATES.UNPUBLISHED].includes(i),
  );

  if (isActive) {
    return DIY_OPERATION_STATUSES.ACTIVE;
  }

  if (isOnConfiguration) {
    return DIY_OPERATION_STATUSES.ON_CONFIGURATION;
  }

  return status;
};

export const getModalsConfiguration = (lang, type, loading) => {
  const getTitlesModalsConfiguration = {
    device: {
      publishTitle: lang.DEVICE_PUBLISH_TITLE,
      updatePublishTitle: lang.DEVICE_UPDATE_PUBLISH_TITLE,
      unpublishTitle: lang.DEVICE_UNPUBLISH_TITLE,
    },
    web_app: {
      publishTitle: lang.WEB_APP_PUBLISH_TITLE,
      updatePublishTitle: lang.WEB_APP_UPDATE_PUBLISH_TITLE,
      unpublishTitle: lang.WEB_APP_UNPUBLISH_TITLE,
    },
    validated: {
      title: lang.VALIDATED_TITLE,
      confirm: lang.VALIDATED_CONFIRM,
    },
    invalidated: {
      title: lang.INVALIDATED_TITLE,
      confirm: lang.INVALIDATED_CONFIRM,
    },
  };

  return {
    [DIY_CONFIRMATION_TYPES.PUBLISH]: {
      title: getTitlesModalsConfiguration[type]?.publishTitle,
      message: lang.SUPPORTING_TEXT,
      buttonConfirm: {
        label: lang.PUBLISH_BUTTON,
        color: 'devices',
        loading: loading,
      },
      buttonCancel: {
        label: lang.CANCEL_BUTTON,
        color: 'devices',
      },
    },
    [DIY_CONFIRMATION_TYPES.UPDATE]: {
      title: getTitlesModalsConfiguration[type]?.updatePublishTitle,
      message: lang.SUPPORTING_TEXT,
      buttonConfirm: {
        label: lang.UPDATE_PUBLISH_BUTTON,
        color: 'devices',
        loading: loading,
      },
      buttonCancel: null,
    },
    [DIY_CONFIRMATION_TYPES.VALIDATED]: {
      title: getTitlesModalsConfiguration[type]?.title,
      message: lang.SUPPORTING_TEXT,
      buttonConfirm: {
        label: getTitlesModalsConfiguration[type]?.confirm,
        loading: loading,
      },
      buttonCancel: {label: lang.CANCEL},
    },
    [DIY_CONFIRMATION_TYPES.UNPUBLISH]: {
      title: getTitlesModalsConfiguration[type]?.unpublishTitle,
      message: lang.SUPPORTING_TEXT,
      buttonConfirm: {
        label: lang.UNPUBLISH_BUTTON,
        color: 'error',
        transparent: true,
        loading: loading,
      },
      buttonCancel: null,
    },
  };
};
