import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {deleteMethod} from 'client/services/fetch';
import {getHomeDashboard, getHomeOrFirstDashboard, uid} from 'client/services/helpers';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getGames} from 'client/ducks/games/actions';
import {selectGames} from 'client/ducks/games/selectors';
import {fetchOfflineInteractions} from 'client/ducks/interactions/actions';
import {updateOfflineInteraction} from 'client/ducks/interactions/actions';
import {getPlan} from 'client/ducks/plans/actions';
import {addToastNotifications} from 'client/ducks/toast-notification/actions';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {CLIENT_PAGES, API_METHODS, CLIENT_LEVEL_TYPES, OPERATION_STATUS_TYPES} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import CustomLink from 'client/components/common/custom-link';
import Icon from 'client/components/common/icon';
import TitleBlock from 'client/components/common/title-block';

import {PRIZE_MAP_LEVEL_TYPES, PRIZE_MAP_TYPES} from 'client/components/games/game-config-modal/constants';
import PrizeMapCard from 'client/components/games/prize-map-card';

import AtPlanLinkModal from '../components/modals/at-plan-link-modal';
import AtPlanMapModal from '../components/modals/at-plan-map-modal';
import AtPlanSummaryPopover from '../components/popovers/at-plan-summary-popover';
import AtPlanDataGrid from '../components/tables/at-plan-data-grid';

import './devices-view.scss';

class AutotaskPlan extends ReactQueryParams {
  static propTypes = {
    plan: PropTypes.object,
    id: PropTypes.number.isRequired,
    getPlan: PropTypes.func.isRequired,
    getGames: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    ...withRouter.propTypes,
    isNational: PropTypes.bool.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    langBC: PropTypes.object.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    fetchOfflineInteractions: PropTypes.func.isRequired,
    updateOfflineInteraction: PropTypes.func.isRequired,
    interactions: PropTypes.shape({
      data: PropTypes.array.isRequired,
      meta: PropTypes.object.isRequired,
    }).isRequired,
    games: PropTypes.array.isRequired,
    addToastNotifications: PropTypes.func.isRequired,
  };

  static defaultProps = {
    plan: {},
  };

  static modals = {
    LINK_DEVICE_MODAL: 1,
    UNLINK_DEVICE_MODAL: 2,
    MAP_VIEW_MODAL: 3,
  };

  static DEFAULT_SORT = 'created_at desc';
  static DEFAULT_PAGE = 1;
  static DEFAULT_PER_PAGE = 5;

  static ERROR_RESPONSE_CODES = [403, 404];

  constructor(props) {
    super(props);

    this.state = {
      activeModal: null,
      editingInteraction: {},
      showingPlaceId: null,
    };
  }

  componentDidMount() {
    this.updateMe().then(this.updateBreadcrumbs);
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.plan.id && this.props.plan.id) {
      this.fetchGames();
    }
  }

  fetchGames = () => {
    return this.props.getGames({
      q: {
        automation_task_id_eq: this.props.plan.automation_task_id,
      },
      include: ['game_validation_levels', 'automation_task.operation.client'],
    });
  };

  updateBreadcrumbs = () => {
    const {
      isAdmin,
      isNational,
      langBC,
      plan: {
        name,
        automation_task: {
          id: taskId,
          type: taskType,
          name: taskName,
          dashboards: autotaskDashboards,
          operation: {
            id: operationId,
            name: operationName,
            status: operationStatus,
            dashboards: operationDashboards,
            client: {id: clientId, type: clientType, ...client},
          },
        },
      },
    } = this.props;

    const basicInfo = {
      taskId,
      taskType,
      clientId,
      clientType,
      operationId,
    };

    const autotaskHomeDashboard = getHomeOrFirstDashboard(autotaskDashboards);
    const operationHomeDashboard = getHomeDashboard(operationDashboards);

    const agencyBreadcrumb = client.agency
      ? [
          {
            href: CustomLink.createClientLink({
              clientType: client.agency.type,
              clientId: client.agency.id,
            }),
            name: client.agency.name,
          },
        ]
      : [];

    const operationStatusBreadcrumb = {
      name: operationStatus !== OPERATION_STATUS_TYPES.ACTIVE ? langBC.FINISHED_OPERATIONS : langBC.ONGOING_OPERATIONS,
      href:
        operationStatus !== OPERATION_STATUS_TYPES.ACTIVE
          ? CLIENT_PAGES.OPERATIONS_FINISHED
          : CLIENT_PAGES.OPERATIONS_ACTIVE,
    };

    const planNameBreadcrumb = {
      name,
    };

    const taskDashboardBreadcrumb = {
      name: get(autotaskHomeDashboard, 'name'),
      href:
        autotaskHomeDashboard &&
        CustomLink.createDashboardsLink({
          ...basicInfo,
          dashboardType: CLIENT_LEVEL_TYPES.AUTOMATION_TASK,
          dashboardId: autotaskHomeDashboard.id,
          hidden: !autotaskHomeDashboard,
        }),
    };

    switch (true) {
      case !isNational && !isAdmin:
        return this.props.setBreadcrumbs([operationStatusBreadcrumb, taskDashboardBreadcrumb, planNameBreadcrumb]);

      case !isAdmin && isNational:
        return this.props.setBreadcrumbs([
          operationStatusBreadcrumb,
          {
            name: get(operationHomeDashboard, 'name'),
            href:
              operationHomeDashboard &&
              CustomLink.createDashboardsLink({
                ...basicInfo,
                dashboardType: CLIENT_LEVEL_TYPES.OPERATION,
                dashboardId: operationHomeDashboard.id,
                hidden: !operationHomeDashboard,
              }),
          },
          taskDashboardBreadcrumb,
          planNameBreadcrumb,
        ]);

      default:
        return this.props.setBreadcrumbs([
          {
            href: CLIENT_PAGES.VISUALS,
            name: langBC.VISUALS,
          },
          {
            href: CLIENT_PAGES.CLIENTS_LIST,
            name: langBC.CLIENTS,
          },
          ...agencyBreadcrumb,
          {
            name: client.name,
            href: CustomLink.createClientLink(basicInfo),
          },
          {
            name: langBC.CUSTOM_OPERATION,
            href: CustomLink.createCustomOperationDBLink(basicInfo),
          },
          {
            name: operationName,
            href: CustomLink.createOperationLink(basicInfo),
          },
          {
            name: taskName,
            href: CustomLink.createTaskLink(basicInfo),
          },
          planNameBreadcrumb,
        ]);
    }
  };

  handleActiveModalSet = (activeModal, state = {}) => {
    return () => this.setState({...state, activeModal});
  };

  handleActiveModalClose =
    (update = false, state = {}) =>
    () => {
      this.setState({
        ...state,
        activeModal: null,
        showingPlaceId: null,
      });

      if (update) {
        this.updateMe();
      }
    };

  updateMe = () => {
    const params = this.queryParams;

    this.props.fetchOfflineInteractions({
      include_offline_interaction_prize_maps_present: true,
      include: [
        'device',
        'interface.interface_template',
        'place',
        'accessible_region',
        'accessible_store',
        'geo_prize_maps',
        'interaction_group.geo_prize_maps',
        'accessible_region',
        'accessible_store',
      ],
      q: {
        interaction_group_id_eq: this.props.id,
        s: params.sort ? `${params.sort.name} ${params.sort.order}` : AutotaskPlan.DEFAULT_SORT,
        device_name_cont: params.search,
      },
      page: params.page || AutotaskPlan.DEFAULT_PAGE,
      per_page: params.perPage || AutotaskPlan.DEFAULT_PER_PAGE,
    });
    window.scrollTo(0, 0);
    return this.getPlan();
  };

  getPlan = () => {
    return this.props
      .getPlan(this.props.id, {
        include_plan_prize_maps_present: true,
        include: {
          geo_prize_maps: true,
          automation_task: {
            operation: {
              client: ['agency', 'dashboards'],
              dashboards: null,
            },
            dashboards: null,
          },
        },
      })
      .catch((response) => {
        if (AutotaskPlan.ERROR_RESPONSE_CODES.includes(response.status)) {
          this.props.history.push(CLIENT_PAGES.HOME);
        }
      });
  };

  updateOfflineInteraction = async (id, data) => {
    const response = await this.props.updateOfflineInteraction(id, data);
    if (response.error) {
      const errors = response?.payload?.response?.errors;
      this.props.addToastNotifications({
        id: uid(),
        type: 'error',
        description: errors?.base?.[0] || errors?.device?.[0],
      });
    }
    return await this.updateMe();
  };

  handleUnlinkDevice = async () => {
    const response = await deleteMethod(`${API_METHODS.OFFLINE_INTERACTIONS}/${this.state.editingInteraction.id}`);
    if (response.errors) {
      this.props.addToastNotifications({
        id: uid(),
        type: 'error',
        description: response.errors.base?.[0],
      });
    }
    this.handleActiveModalClose(true, {editingInteraction: {}})();
  };

  onPageChange = (params) => {
    this.setQueryParams(params, true);
    this.updateMe();
  };

  onSearchChange = (search = '') => {
    this.setQueryParams({search, page: AutotaskPlan.DEFAULT_PAGE}, true);
    this.updateMe();
  };

  onSortChange = ({sortField, sortOrder}) => {
    this.setQueryParams(
      {
        sort: {name: sortField, order: sortOrder},
      },
      true,
    );
    this.updateMe();
  };

  handleEditDeviceClick = (editingInteraction) => {
    return this.handleActiveModalSet(AutotaskPlan.modals.LINK_DEVICE_MODAL, {editingInteraction})();
  };

  handlePlacesViewClick = () => {
    this.props.history.push(`${CLIENT_PAGES.PLANS}/${this.props.id}${CLIENT_PAGES.PLACES}`);
  };

  showSinglePlaceOnMap = (showingPlaceId) => {
    this.handleActiveModalSet(AutotaskPlan.modals.MAP_VIEW_MODAL, {showingPlaceId})();
  };

  render() {
    const {interactions, plan, isAdmin, lang, games} = this.props;
    const unlinkDeviceLabels = lang.UNLINK_DEVICE_MODAL;
    const clientId = get(this.props, 'plan.automation_task.operation.client.id');
    const {showingPlaceId} = this.state;

    if (!interactions.meta) {
      return null;
    }

    return (
      <div className="theme-color-9 devices-view">
        <ConfirmationModal
          show={this.state.activeModal === AutotaskPlan.modals.UNLINK_DEVICE_MODAL}
          onClose={this.handleActiveModalClose()}
          onCancel={this.handleActiveModalClose()}
          onConfirm={this.handleUnlinkDevice}
          title={unlinkDeviceLabels.TITLE}
          message={unlinkDeviceLabels.MESSAGE}
          cancelText={unlinkDeviceLabels.CANCEL_BUTTON}
          confirmText={unlinkDeviceLabels.CONFIRM_BUTTON}
          className="theme-color-9"
          buttonConfirmClass="button--bg-4"
        />

        <AtPlanMapModal
          show={this.state.activeModal === AutotaskPlan.modals.MAP_VIEW_MODAL}
          onEditInteraction={this.handleEditDeviceClick}
          onClose={this.handleActiveModalClose()}
          planId={this.props.id}
          readOnly={!isAdmin}
          placeId={showingPlaceId}
        />

        <AtPlanLinkModal
          show={this.state.activeModal === AutotaskPlan.modals.LINK_DEVICE_MODAL}
          onClose={this.handleActiveModalClose(false, {editingInteraction: {}})}
          onConfirm={this.handleActiveModalClose(true, {editingInteraction: {}})}
          onUnlinkClick={this.handleActiveModalSet(AutotaskPlan.modals.UNLINK_DEVICE_MODAL)}
          autotaskId={get(this.props.plan, 'automation_task_id')}
          editingInteraction={this.state.editingInteraction}
          planId={this.props.id}
          clientId={clientId}
        />

        <div className="page__title-block devices-view__title-block">
          <div className="devices-view__title">
            <TitleBlock theme>
              <TitleBlock.Item>{plan.name}</TitleBlock.Item>
            </TitleBlock>
            <AtPlanSummaryPopover plan={plan}>
              <div className="icon-new-wrapper-square">
                <Icon name="summary" />
              </div>
            </AtPlanSummaryPopover>
          </div>
          <PrizeMapCard
            hasContainer
            className="devices-view__prize-map"
            levelKey="PLAN"
            prizeMapParams={{
              type_eq: PRIZE_MAP_TYPES.GEO_PRIZE_MAP,
              geo_level_eq: PRIZE_MAP_LEVEL_TYPES.PLAN,
              geo_source_id_eq: plan.id,
            }}
            displayParams={plan.prize_maps_present}
          />
        </div>
        <AtPlanDataGrid
          data={interactions.data}
          meta={interactions.meta}
          perPage={+this.queryParams.perPage || 5}
          onPageChange={this.onPageChange}
          onSearchChange={this.onSearchChange}
          onSortChange={this.onSortChange}
          onAddDeviceClick={this.handleActiveModalSet(AutotaskPlan.modals.LINK_DEVICE_MODAL)}
          onEditDeviceClick={this.handleEditDeviceClick}
          onMapViewClick={this.handleActiveModalSet(AutotaskPlan.modals.MAP_VIEW_MODAL)}
          onPlacesViewClick={this.handlePlacesViewClick}
          readOnly={!isAdmin}
          showSinglePlaceOnMap={this.showSinglePlaceOnMap}
          updateOfflineInteraction={this.updateOfflineInteraction}
          games={games}
          sort={this.queryParams.sort}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({languageState, plans, interactions, ...state}) => ({
      lang: languageState.payload.AUTOTASK_PLAN,
      langBC: languageState.payload.BREADCRUMBS,
      isAdmin: selectIsAdmin(state),
      plan: plans.plan,
      interactions: interactions.offlineInteractions,
      games: selectGames(state),
    }),
    {
      getPlan,
      getGames,
      setBreadcrumbs,
      clearBreadcrumbs,
      fetchOfflineInteractions,
      updateOfflineInteraction,
      addToastNotifications,
    },
  )(AutotaskPlan),
);
