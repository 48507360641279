import find from 'lodash/find';
import {createSelector} from 'reselect';

import {selectColumnVisibilityItems} from 'client/ducks/lead-display-items/selectors';

import {COLUMN_ADAPTER_TYPES} from 'client/common/config';

export function selectColumns(state, db, isForConditions = false) {
  const databases = isForConditions
    ? state.columnAdapters.availableConditionColumnAdapters
    : state.columnAdapters.availableColumnAdapters;

  const adapters = databases[db];

  return adapters
    ? adapters.map((item) => {
        return {
          value: item.id,
          label: item.name,
          array: item.array,
          kind: item.kind,
          type: item.type,
        };
      })
    : [];
}

export function selectDBsWithColumnAdapters(state) {
  return Object.keys(state.columnAdapters.availableColumnAdapters).reduce((arr, db) => {
    arr.push({
      db,
      columnAdapters: state.columnAdapters.availableColumnAdapters[db],
    });
    return arr;
  }, []);
}

export function selectLocalizedDBs(state, includedDBs = [], excludeArrayColumns = true) {
  const dbs = selectDBsWithColumnAdapters(state);
  const LANGUAGE = state.languageState.payload.DATABASES;

  return dbs
    .filter((i) => includedDBs.includes(i.db))
    .map((i) => {
      i.label = LANGUAGE[i.db];
      if (excludeArrayColumns) {
        i.columnAdapters = i.columnAdapters.filter((c) => !c.array);
      }
      return i;
    });
}

export const selectAvailableColumnAdapters = (state, db = null) => {
  const columnAdapters = state.columnAdapters.availableColumnAdapters;
  return db ? columnAdapters[db] || [] : columnAdapters;
};

export const selectConditionAvailableColumnAdapters = (state, db) => {
  return state.columnAdapters.availableConditionColumnAdapters[db];
};

export const selectGeneralLeadColumns = createSelector(
  [selectAvailableColumnAdapters, selectColumnVisibilityItems],
  (adapterGroups, visibilityItems) => {
    const adapters = adapterGroups.Lead || [];
    const columnNames = [
      'first_name',
      'last_name',
      'gender',
      'birth_date',
      'phone',
      'email',
      'last_participation',
      'participations_count',
      'visuals_count',
      'created_at',
      'first_source',
      'last_export',
      'opt_ins',
      'expires_at',
      'test_mode',
      'min_level_reached_at',
      'accessed_level',
      'store_name',
      'region_name',
    ];

    const columnTypes = [COLUMN_ADAPTER_TYPES.INTERNAL, COLUMN_ADAPTER_TYPES.SYSTEM, COLUMN_ADAPTER_TYPES.ASSOCIATION];

    return adapters
      .filter((column) => find(visibilityItems, (item) => item.column_adapter_id === column.id))
      .filter((i) => columnTypes.includes(i.type) && columnNames.includes(i.name))
      .sort((a, b) => columnNames.indexOf(a.name) - columnNames.indexOf(b.name));
  },
);

export const selectAvailableParticipationColumns = selectAvailableColumnAdapters;
