import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getSlideshows(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SLIDESHOWS}`,
      method: 'GET',
      types: [types.GET_SLIDESHOWS_REQUEST, types.GET_SLIDESHOWS_SUCCESS, types.GET_SLIDESHOWS_ERROR],
      noCache: true,
      queryParams,
    });
}

export function getSlideshow(id, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SLIDESHOWS}/${id}`,
      method: 'GET',
      types: [types.GET_SLIDESHOW_REQUEST, types.GET_SLIDESHOW_SUCCESS, types.GET_SLIDESHOW_ERROR],
      noCache: true,
      queryParams,
    });
}

export function patchDiaporamaMappingItems(body, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SLIDESHOW_MAPPING_ITEMS}${API_METHODS.BULK_ACTION}`,
      method: 'PATCH',
      types: [
        types.PATCH_DIAPORAMA_MAPPING_ITEMS_REQUEST,
        types.PATCH_DIAPORAMA_MAPPING_ITEMS_SUCCESS,
        types.PATCH_DIAPORAMA_MAPPING_ITEMS_ERROR,
      ],
      queryParams,
      body,
    });
}
