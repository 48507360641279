export function selectSourcesMeta(state) {
  return state.sources.meta;
}

export function selectSourcesTotalCount(state) {
  return selectSourcesMeta(state).total_count;
}

export function selectSourcesByKind(state, kind) {
  return state.sources.allIds.reduce((result, id) => {
    const item = state.sources.byId[id];

    return item && item.kind === kind ? [...result, item] : result;
  }, []);
}

export function selectAllSources(state) {
  return state.sources.allIds.map((id) => state.sources.byId[id]);
}

export const selectClientOptions = (state) => {
  return state.sources.clientOptions;
};
