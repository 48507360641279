import React from 'react';

import {Field} from 'redux-form';

import BaseFieldComponent from 'client/common/fields/base-field';
import AsyncDropdown from 'client/common/selects/async-dropdown';

import {BaseFieldComponentProps} from '../base-field/types';

const InputComponent = (props) => <BaseFieldComponent Component={AsyncDropdown} {...props} />;

InputComponent.propTypes = BaseFieldComponentProps;

const AsyncSelectField = (props) => {
  return <Field component={InputComponent} {...props} />;
};

export default AsyncSelectField;
