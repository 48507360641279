import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useTranslations} from 'client/services/hooks';

import Icon from 'client/common/icon';

import {TemplateGroup} from 'client/models/templates/types';

import cssModule from './catalog-modules-card.module.scss';

const b = bem('catalog-modules-card', {cssModule});

type CatalogModulesCardProps = {
  templateGroup: TemplateGroup;
  openedSlider: boolean;
  onClick: (id: number | null) => void;
  className?: string;
  classNames?: {
    imageContainer?: string;
    image?: string;
    footer?: string;
    title?: string;
  };
};

const CatalogModulesCard: React.FC<CatalogModulesCardProps> = (props) => {
  const {className, templateGroup, classNames, openedSlider, onClick} = props;
  const {currentLanguage} = useTranslations();

  const handleClick = () => (openedSlider ? onClick(null) : onClick(templateGroup.id));

  return (
    <div className={cn(b({opened: openedSlider}), className)}>
      <figure
        className={cn(b('image-container'), classNames?.imageContainer)}
        role="button"
        tabIndex={-1}
        onClick={handleClick}
      >
        {templateGroup.icon_url ? (
          <img
            src={templateGroup.icon_url}
            alt={templateGroup.title_translations[currentLanguage || '']}
            className={cn(b('image'), classNames?.image)}
          />
        ) : (
          <div className={cn(b('image', ['placeholder']), classNames?.image)}>
            <Icon name="photos" width={70} height={70} />
          </div>
        )}
      </figure>
      <figcaption
        className={cn(b('footer', {opened: openedSlider}), classNames?.footer)}
        role="button"
        tabIndex={-1}
        onClick={handleClick}
      >
        <span className={cn(b('title'), classNames?.title)}>
          {templateGroup.title_translations[currentLanguage || '']}
        </span>
        <Icon name={openedSlider ? 'arrow-down' : 'arrow-up'} />
      </figcaption>
    </div>
  );
};

export default CatalogModulesCard;
