import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

function getVisualsFromEndpoint(endpoint, params = {}) {
  const queryParams = {
    ...params,
    distinct: true,
    include: [
      'interaction.interface.automation_task.operation.client',
      'lead',
      'lead_history',
      'offline_interaction.place',
      'lead_first_source.source',
      'hashtag_tracker',
      'manual_visual_groups.manual_visual_group_items',
    ],
  };

  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${endpoint}`,
      method: 'GET',
      types: [types.GET_VISUALS_REQUEST, types.GET_VISUALS_SUCCESS, types.GET_VISUALS_ERROR],
      noCache: true,
      queryParams,
    });
}

export function getVisuals(params) {
  return getVisualsFromEndpoint(`${API_METHODS.VISUALS}`, params);
}

export function getVisualsByGroupId(id, params = {}) {
  return getVisualsFromEndpoint(`${API_METHODS.VISUALS}${API_METHODS.GROUP}/${id}`, params);
}

export function getVisualsFromSlideshow(id, params = {}) {
  return getVisualsFromEndpoint(`${API_METHODS.SLIDESHOWS}/${id}${API_METHODS.VISUALS}`, params);
}

export function getVisualDisplayItems(queryParams) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.VISUAL_DISPLAY_ITEMS}`,
      method: 'GET',
      types: [
        types.GET_VISUAL_DISPLAY_ITEMS_REQUEST,
        types.GET_VISUAL_DISPLAY_ITEMS_SUCCESS,
        types.GET_VISUAL_DISPLAY_ITEMS_ERROR,
      ],
      noCache: true,
      queryParams,
    });
}

export function patchVisual(id, body, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.VISUALS}/${id}`,
      method: 'PATCH',
      types: [types.PATCH_VISUAL_REQUEST, types.PATCH_VISUAL_SUCCESS, types.PATCH_VISUAL_ERROR],
      queryParams,
      body,
    });
}

export function getAutomaticVisualGroups(id) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.VISUALS}/${id}${API_METHODS.AUTOMATIC_VISUAL_GROUPS}`,
      method: 'GET',
      types: [
        types.GET_AUTOMATIC_VISUAL_GROUPS_REQUEST,
        {
          type: types.GET_AUTOMATIC_VISUAL_GROUPS_SUCCESS,
          payload: (action, state, res) => {
            return res.json().then((json) => ({automatic_visual_groups: json.automatic_visual_groups, id}));
          },
        },
        types.GET_AUTOMATIC_VISUAL_GROUPS_ERROR,
      ],
      noCache: true,
    });
}

export function getVisualsList(queryParams = {}, isClient = false) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.VISUALS}${isClient ? '/client' : ''}`,
      method: 'GET',
      types: [types.GET_VISUALS_REQUEST, types.GET_VISUALS_SUCCESS, types.GET_VISUALS_ERROR],
      noCache: true,
      queryParams,
    });
}

export function getVisualGroups(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.VISUAL_GROUPS}`,
      method: 'GET',
      types: [types.GET_VISUAL_GROUPS_REQUEST, types.GET_VISUAL_GROUPS_SUCCESS, types.GET_VISUAL_GROUPS_ERROR],
      noCache: true,
      queryParams,
    });
}
