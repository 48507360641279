import React from 'react';

import find from 'lodash/find';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {formValueSelector} from 'redux-form';

import CustomReactQueryParams from 'client/services/CustomReactQueryParams';
import {transformQueryParamsForExport} from 'client/services/exportHelpers';
import {getClientPage, getHomeOrFirstDashboard} from 'client/services/helpers';

import {getAutotask} from 'client/ducks/autotask/actions';
import {selectAutotask} from 'client/ducks/autotask/selectors';
import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getBroadcast} from 'client/ducks/broadcast/actions';
import {selectBroadcast} from 'client/ducks/broadcast/selectors';
import {getClientUsersWithMembershipsAction} from 'client/ducks/client-users/actions';
import {selectClientUsers} from 'client/ducks/client-users/selectors';
import {getClient} from 'client/ducks/clients-list/actions';
import {getAvailableColumnAdaptersByAutomationTask} from 'client/ducks/column-adapters/actions';
import {selectAvailableParticipationColumns} from 'client/ducks/column-adapters/selectors';
import {selectClientName, selectClient} from 'client/ducks/leads-list/selectors';
import {getDisplayColumns} from 'client/ducks/participation-display-items/actions';
import {getColumnVisibilityItems} from 'client/ducks/participation-display-items/actions';
import {selectColumnVisibilityItems} from 'client/ducks/participation-display-items/selectors';
import {getAvailableColumnAdaptersByAutomationTaskForExport} from 'client/ducks/participation-export/actions';
import {getAvailableExportColumns} from 'client/ducks/participation-export/selectors';
import {clearParticipations, getParticipationsTableData} from 'client/ducks/participations/actions';
import {updateParticipation} from 'client/ducks/participations/actions';
import {selectParticipantsTableData, selectParticipationsTableDataMeta} from 'client/ducks/participations/selectors';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {CLIENT_LEVEL_TYPES, CLIENT_PAGES, OPERATION_STATUS_TYPES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';
import Spinner from 'client/components/common/spinner';
import TitleBlock from 'client/components/common/title-block';

import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_PER_PAGE,
  DEFAULT_SEARCH_OPTION,
  DEFAULT_SORT_FIELD,
  DEFAULT_SORT_ORDER,
  MODALS_MAP,
  settingsModalTabKeys,
} from 'client/components/participants/participants-list/constants';
import ParticipantsListModals from 'client/components/participants/participants-list/participants-list-modals';

import {mapFilter, mapFilterForUrl} from './helper';

import ParticipantsExportTypeModal from '../components/modals/participants-export-type-modal';
import ParticipantsMainDataGrid from '../components/tables/participants-main-data-grid/participants-main-data-grid';

const filterQueryParams = (query) => {
  const filters = {};
  Object.keys(query)
    .filter((item) => query[item] || (Array.isArray(query[item]) && query[item].length))
    .forEach((item) => {
      filters[item] = query[item];
    });
  return filters;
};

class ParticipantsList extends CustomReactQueryParams {
  static defaultProps = {
    broadcast: {},
    clientId: '',
    allColumns: [],
  };

  static propTypes = {
    getAdapters: PropTypes.func.isRequired,
    getExportAdapters: PropTypes.func.isRequired,
    columnVisibilityItems: PropTypes.array.isRequired,
    participationDisplayItems: PropTypes.array.isRequired,
    getAdminDisplayedColumns: PropTypes.func.isRequired,
    isNational: PropTypes.bool.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    getClient: PropTypes.func.isRequired,
    getAutotask: PropTypes.func.isRequired,
    generalParticipationColumns: PropTypes.object.isRequired,
    clientName: PropTypes.string,
    importType: PropTypes.string,
    exportType: PropTypes.string,
    allColumns: PropTypes.array,
    isAdmin: PropTypes.bool.isRequired,
    broadcast: PropTypes.object,
    clientId: PropTypes.string,
    operationId: PropTypes.string,
    taskId: PropTypes.string,
    autoTask: PropTypes.object,
    getClientDisplayedColumns: PropTypes.func.isRequired,
    clearParticipations: PropTypes.func.isRequired,
    availableExportColumns: PropTypes.object.isRequired,
    ...withRouter.propTypes,
    getClientUsersWithMemberships: PropTypes.func.isRequired,
    clientUsers: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.LANGUAGE = props.languageState.payload.PARTICIPANTS_LIST;
    this.BREADCRUMBS_LANGUAGE = props.languageState.payload.BREADCRUMBS;

    this.state = {
      page: DEFAULT_FIRST_PAGE,
      perPage: DEFAULT_PER_PAGE,
      sort: {
        name: '',
        order: '',
      },
      search: this.queryParams.search || '',
      showClear: false,
      searchDefault: '',
      filter: {},
      filterParams: null,
      visibleModal: '',
      isAll: false,
      checkedParticipations: [],
      tableSettingsModalTab: settingsModalTabKeys.adminVisibility,
      isLoading: false,
      isLoadingData: false,
    };
  }

  fetchInitData = async () => {
    return Promise.all([
      this.props.getExportAdapters(this.props.taskId),
      this.props.getAdapters(this.props.taskId, 'participations'),
      this.fetchAutotask(),
      this.props.getClientUsersWithMemberships(this.props.clientId),
    ]);
  };

  fetchColumns = () => {
    return Promise.all([
      this.props.getClientDisplayedColumns(this.props.taskId),
      this.props.getAdminDisplayedColumns(this.props.taskId),
    ]);
  };

  fetchAutotask = () => {
    if (this.props.clientId) {
      return this.props
        .getAutotask(this.props.taskId, {
          include: {
            client: null,
            dashboards: null,
            operation: {dashboards: null},
          },
        })
        .catch((error) => {
          if (error.status === 404) {
            this.props.history.push(CLIENT_PAGES.ACCESS_DENIED);
          }
        });
    }
    return Promise.resolve();
  };

  fetchTableData = () => {
    const {clientId, operationId, taskId} = this.props;
    const {queryParams} = this;
    this.setState({isLoadingData: true});

    this.props.clearParticipations();

    const sort =
      `${queryParams.sort?.name || DEFAULT_SORT_FIELD} ` + `${queryParams.sort?.order || DEFAULT_SORT_ORDER}`;

    const params = {
      ...this.getQueryStringParamsForRequest(),
      q: {
        s: sort,
      },
      page: queryParams.page || DEFAULT_FIRST_PAGE,
      per_page: queryParams.perPage || DEFAULT_PER_PAGE,
    };

    if (clientId && operationId && taskId) {
      params.automation_task_id = taskId;
    }

    if (queryParams.filter) {
      const {with_media, q, distinct, level_numbers, interface_ids} = mapFilterForUrl(mapFilter(queryParams.filter));

      params.distinct = distinct;
      params.with_media = with_media;
      params.level_numbers = level_numbers;
      params.interface_ids = interface_ids;
      params.q = {
        ...params.q,
        ...q,
      };
      params.q.g = [params.q.g];

      this.setState({
        filterParams: filterQueryParams(queryParams.filter), // for passing to post request file_export
      });
    }

    window.scrollTo(0, 0);

    return this.props.getParticipationsTableData(params).then(() => this.setState({isLoadingData: false}));
  };

  fetchData = () => {
    this.setState({
      isLoading: true,
    });
    return Promise.all([this.fetchTableData(), this.fetchColumns()]).finally(() => {
      this.setState({
        isLoading: false,
      });
    });
  };

  fetchAllData = async () => {
    this.setState({isLoading: true});
    await Promise.all([this.fetchInitData(), this.fetchColumns(), this.fetchTableData()]);
    this.setState({isLoading: false});
  };

  componentDidMount() {
    this.setState({isLoading: true});
    this.fetchAllData().then(() => {
      this.setState({isLoading: false});
      this.updateBreadcrumbs();
    });
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  updateBreadcrumbs = () => {
    let breadcrumbs = [];

    if (this.props.clientId && this.props.autoTask) {
      if (this.props.isNational) {
        breadcrumbs = this.buildNationalBreadcrumbs();
        return this.props.setBreadcrumbs(breadcrumbs);
      }

      const {clientId, operationId, taskId, autoTask, isAdmin} = this.props;

      const operationDashboard = get(autoTask, 'operation.dashboards[0]', {});
      const homeDashboard = find(autoTask.dashboards, {kind: 'home'}) || {};

      const operationDashboardPath = isAdmin ? '' : `${CLIENT_PAGES.DASHBOARDS}/${operationDashboard.id}`;
      const homeDashboardPath = isAdmin ? '' : `${CLIENT_PAGES.DASHBOARDS}/${homeDashboard.id}`;

      breadcrumbs = breadcrumbs.slice(0, -1).concat([
        {
          href: getClientPage(autoTask.client) + '/' + clientId,
          name: autoTask.client.name,
          hidden: false,
        },
        {
          href:
            `${getClientPage(autoTask.client)}/${clientId}${CLIENT_PAGES.OPERATIONS}/${operationId}` +
            operationDashboardPath,
          name: autoTask.operation.name,
          hidden: false,
        },
        {
          href:
            `${getClientPage(autoTask.client)}/${clientId}${CLIENT_PAGES.OPERATIONS}/${operationId}` +
            `${CLIENT_PAGES.AUTOTASK}/${taskId}` +
            homeDashboardPath,
          name: autoTask.name,
          hidden: false,
        },
        {
          name: 'Participations',
          hidden: false,
        },
      ]);
    }

    return this.props.setBreadcrumbs(breadcrumbs);
  };

  buildNationalBreadcrumbs = () => {
    const {autoTask: task} = this.props;
    const {client} = task;

    const {operation} = task;
    const operationHomeDashboard = getHomeOrFirstDashboard(operation.dashboards);
    const taskHomeDashboard = getHomeOrFirstDashboard(task.dashboards);
    const basicInfo = {
      clientType: client.type,
      clientId: client.id,
      operationId: task.operation_id,
      taskType: task.type,
      taskId: task.id,
    };

    return [
      {
        name:
          operation.status !== OPERATION_STATUS_TYPES.ACTIVE
            ? this.BREADCRUMBS_LANGUAGE.FINISHED_OPERATIONS
            : this.BREADCRUMBS_LANGUAGE.ONGOING_OPERATIONS,
        href:
          operation.status !== OPERATION_STATUS_TYPES.ACTIVE
            ? CLIENT_PAGES.OPERATIONS_FINISHED
            : CLIENT_PAGES.OPERATIONS_ACTIVE,
      },
      {
        name: get(operationHomeDashboard, 'name'),
        href:
          operationHomeDashboard &&
          CustomLink.createDashboardsLink({
            ...basicInfo,
            dashboardType: CLIENT_LEVEL_TYPES.OPERATION,
            dashboardId: operationHomeDashboard.id,
          }),
        hidden: !operationHomeDashboard,
      },
      {
        name: get(taskHomeDashboard, 'name'),
        href:
          taskHomeDashboard &&
          CustomLink.createDashboardsLink({
            ...basicInfo,
            dashboardType: CLIENT_LEVEL_TYPES.POST_TASK,
            dashboardId: taskHomeDashboard.id,
          }),
        hidden: !taskHomeDashboard,
      },
      {
        name: this.LANGUAGE.PAGE_TITLE,
      },
    ];
  };

  getQueryStringParamsForRequest = () => {
    const {queryParams} = this;

    return queryParams.search
      ? {
          query_string: queryParams.search,
          query_param: queryParams.searchOption || DEFAULT_SEARCH_OPTION,
        }
      : {};
  };

  updateParticipation = (id, data) => {
    return this.props.updateParticipation(id, data).then(this.fetchTableData);
  };

  onPaginationChange = ({sortField: name, sortOrder: order, page, perPage}) => {
    this.setQueryParams(
      {
        filter: mapFilter(this.queryParams.filter),
        page,
        perPage,
        sort: {name, order},
      },
      true,
    );
    this.fetchTableData();
  };

  handleShowModal = (name, data) => {
    this.setState({visibleModal: name, ...data});
  };

  handleCloseModal = () => this.setState({visibleModal: '', isAll: false});

  onSearchChange = (search) => {
    this.setQueryParams(
      {
        filter: mapFilter(this.queryParams.filter),
        search: search || '',
        page: DEFAULT_FIRST_PAGE,
      },
      true,
    );
    this.fetchTableData();
  };

  onSearchClear = () => {
    this.setQueryParams(
      {
        filter: mapFilter(this.queryParams.filter),
        search: '',
      },
      true,
    );
    this.fetchTableData();
  };

  onSearchOptionChange = (searchOption) => this.setQueryParams({searchOption}, true);

  onFilterChange = (filter) => {
    const mappedFilter = {
      ...filter,
    };
    if (mappedFilter.source && mappedFilter.source.type) {
      const source = {...mappedFilter.source};
      mappedFilter[source.type] = source;
      delete mappedFilter[source.type].type;
      delete mappedFilter.source;
    }

    this.setQueryParams(
      {
        filter: mapFilter(mappedFilter),
        page: 1,
      },
      true,
    );
    this.fetchTableData();
  };

  onFilterClear = () => {
    this.setState({
      filterParams: null,
    });
    this.onFilterChange({});
  };

  onFilterFieldClear = (fieldsList, value = false) => {
    const obj = {...this.queryParams.filter};

    if (value) {
      obj[fieldsList] = obj[fieldsList].filter((item) => item.value !== value);
    } else {
      delete obj[fieldsList];
    }

    this.onFilterChange(obj);
  };

  onShowClientVisibilitySettings = () =>
    this.handleShowModal(MODALS_MAP.TABLE_SETTINGS, {
      tableSettingsModalTab: settingsModalTabKeys.clientVisibility,
    });

  onShowTableSettings = () =>
    this.handleShowModal(MODALS_MAP.TABLE_SETTINGS, {
      tableSettingsModalTab: settingsModalTabKeys.adminVisibility,
    });

  getExportQueryParams = () => {
    const queryParams = {...mapFilterForUrl(mapFilter(this.queryParams.filter))};

    return {
      query_params: transformQueryParamsForExport(queryParams),
      ...this.getQueryStringParamsForRequest(),
    };
  };

  onFetch = () => {
    this.fetchAllData();
  };

  render() {
    const {
      participantsList: data,
      meta,
      participationDisplayItems,
      columnVisibilityItems,
      isAdmin,
      availableExportColumns,
      clientUsers,
    } = this.props;
    const {visibleModal, tableSettingsModalTab, checkedParticipations, isAll, isLoading} = this.state;
    const {queryParams} = this;
    const isErrorExportTypeModal = !Array.isArray(checkedParticipations) || !checkedParticipations.length;
    const pagination = {
      page: +queryParams.page || DEFAULT_FIRST_PAGE,
      perPage: +queryParams.perPage || DEFAULT_PER_PAGE,
      sortField: queryParams.sort?.name || DEFAULT_SORT_FIELD,
      sortOrder: queryParams.sort?.order || DEFAULT_SORT_ORDER,
    };

    return (
      <div className="theme-color-9">
        <div className="page__title-block">
          <TitleBlock theme={true}>
            <TitleBlock.Item>{`${this.LANGUAGE.PAGE_TITLE}`}</TitleBlock.Item>
          </TitleBlock>
        </div>

        {isLoading ? (
          <Spinner centered />
        ) : (
          <>
            <div>
              <ParticipantsMainDataGrid
                data={data}
                pagination={pagination}
                meta={meta}
                isAdmin={isAdmin}
                isReportTable={false}
                isLoading={this.state.isLoadingData}
                perPage={parseInt(queryParams.perPage, 10)}
                onPaginationChange={this.onPaginationChange}
                onFilterShow={() => this.handleShowModal(MODALS_MAP.FILTERS)}
                onFilterClear={this.onFilterClear}
                onFilterFieldClear={this.onFilterFieldClear}
                onSearchOptionChange={this.onSearchOptionChange}
                searchOption={queryParams.searchOption || DEFAULT_SEARCH_OPTION}
                searchDefault={this.queryParams.search}
                showClear={!!queryParams.search}
                filter={queryParams.filter}
                participationDisplayItems={participationDisplayItems}
                columnVisibilityItems={columnVisibilityItems}
                onChangeCheckedList={(checkedList) => this.setState({checkedParticipations: checkedList})}
                onSearchChange={this.onSearchChange}
                onSearchClear={this.onSearchClear}
                onShowClientVisibilitySettings={this.onShowClientVisibilitySettings}
                onShowTableSettings={this.onShowTableSettings}
                onShowExport={() =>
                  this.handleShowModal(MODALS_MAP.EXPORT_MODALS.EXPORT_TYPE_MODAL, {
                    isAll: false,
                  })
                }
                onShowExportAll={() =>
                  this.handleShowModal(MODALS_MAP.EXPORT_MODALS.EXPORT_TYPE_MODAL, {
                    isAll: true,
                  })
                }
              />
            </div>
            <ParticipantsListModals
              visibleModal={visibleModal}
              tableSettingsModalTab={tableSettingsModalTab}
              columnVisibilityItems={columnVisibilityItems}
              participationDisplayItems={participationDisplayItems}
              handleCloseModal={this.handleCloseModal}
              handleShowModal={this.handleShowModal}
              onFilterChange={this.onFilterChange}
              exportType={this.props.exportType}
              isAdmin={isAdmin}
              isErrorExportTypeModal={isErrorExportTypeModal}
              isAll={isAll}
              getExportQueryParams={this.getExportQueryParams}
              checkedParticipations={checkedParticipations}
              availableExportColumns={availableExportColumns}
              clientUsers={clientUsers}
              queryParams={queryParams}
              onFetch={this.fetchData}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const exportTypeFormSelector = formValueSelector(ParticipantsExportTypeModal.formName);
  return {
    languageState: state.languageState,
    participantsList: selectParticipantsTableData(state),
    customColumns: state.customColumns,
    broadcast: selectBroadcast(state),
    meta: selectParticipationsTableDataMeta(state),
    clientName: selectClientName(state),
    client: selectClient(state),
    autoTask: selectAutotask(state),
    participationDisplayItems: state.participationDisplayItems.payload, // list of displayed columns on admin-side.
    columnVisibilityItems: selectColumnVisibilityItems(state), // list of displayed columns on client-side.
    generalParticipationColumns: selectAvailableParticipationColumns(state), // grouped columns that can be displayed (full list)
    isAdmin: selectIsAdmin(state),
    exportType: exportTypeFormSelector(state, 'export'),
    availableExportColumns: getAvailableExportColumns(state),
    clientUsers: selectClientUsers(state, ownProps.clientId),
  };
};

const mapDispatchToProps = {
  getAutotask,
  getClient,
  getParticipationsTableData,
  setBreadcrumbs,
  clearBreadcrumbs,
  getAdminDisplayedColumns: getDisplayColumns,
  getExportAdapters: getAvailableColumnAdaptersByAutomationTaskForExport,
  getAdapters: getAvailableColumnAdaptersByAutomationTask,
  getClientDisplayedColumns: getColumnVisibilityItems,
  getBroadcast,
  clearParticipations,
  updateParticipation,
  getClientUsersWithMemberships: getClientUsersWithMembershipsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ParticipantsList));
