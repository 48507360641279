import React from 'react';

import findIndex from 'lodash/findIndex';
import pull from 'lodash/pull';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {reset} from 'redux-form';

import {deleteMethod} from 'client/services/fetch';

import {selectIsAdmin} from 'client/ducks/user/selectors';
import {getVisualGroups} from 'client/ducks/visuals/actions';
import {selectVisualsIdsInsideGroup, selectManualGroupItemsByVisualId} from 'client/ducks/visuals/selectors';

import {API_METHODS, POST_PREVIEW_TYPES, GROUP_TYPES, VISUAL_INTERACTION_TYPES} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal/index';

import VisSelectDropdown from '../components/dropdowns/vis-select-dropdown';
import VisGroupsForm from '../components/forms/vis-groups-form';
import VisCreateGroupModal from '../components/modals/vis-create-group-modal';
import VisFiltersClientModal from '../components/modals/vis-filters-client-modal';
import VisLeadInfoModal from '../components/modals/vis-lead-info-modal';
import VisPreviewModal from '../components/modals/vis-preview-modal';
import VisTableSettingsModal from '../components/modals/vis-table-settings-modal';
import VisualsBase from '../components/visuals-base/index';
import {mapFilter} from '../visuals-client/helpers';

import '../visuals.scss';

class VisClient extends ReactQueryParams {
  static propTypes = {
    showDeleteFromDbButton: PropTypes.bool.isRequired,
    showPublishButton: PropTypes.bool.isRequired,
    languageState: PropTypes.object.isRequired,
    clientId: PropTypes.number.isRequired,
    allowedFilterGroups: PropTypes.array,
    updateMe: PropTypes.func.isRequired,
    additionalModals: PropTypes.any,
    controlsContent: PropTypes.any,
    gridView: PropTypes.bool,
    isAdmin: PropTypes.bool,
    noToggle: PropTypes.bool,
    titles: PropTypes.any,
    visuals: PropTypes.shape({
      visuals: PropTypes.array,
      meta: PropTypes.object,
    }),
    historyPush: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired,
    groupId: PropTypes.number,
    defaultSelectedIds: PropTypes.array.isRequired,
    groupItemsByVisualId: PropTypes.object.isRequired,
    isEditMode: PropTypes.bool,
    getVisualGroups: PropTypes.func.isRequired,
    handleVisualsGroupChange: PropTypes.func,
    diaporamaValues: PropTypes.object,
  };

  static defaultProps = {
    groupId: 0,
    isEditMode: false,
  };

  static modals = {
    CONFIRM_PUBLICATION: 1,
    TABLE_SETTINGS: 2,
    CREATE_GROUP: 3,
    LEAD_INFO: 4,
    FILTERS: 5,
    PREVIEW: 6,
    DELETE: 7,
  };

  constructor(props) {
    super(props);

    this.state = {
      showingExecution: {},
      activeVisual: {},
      selectedIds: [],
    };

    this.payload = props.languageState.payload;
    this.LANGUAGE = props.languageState.payload.VISUALS;
    this.LANGUAGE_ARRIVING_TYPES = props.languageState.payload.VISUALS.FILTERS_MODAL.ARRIVING_TYPES;
  }

  componentDidMount() {
    this.getVisualGroups();
  }

  componentDidUpdate(prevProps) {
    const {isEditMode, defaultSelectedIds} = prevProps;

    if (this.props.isEditMode && defaultSelectedIds !== this.props.defaultSelectedIds) {
      this.setDefaultSelectedRows(this.props.defaultSelectedIds);
    }

    if (isEditMode && !this.props.isEditMode) {
      this.setDefaultSelectedRows();
    }
  }

  getVisualGroups = () => {
    const params = {
      q: {
        type_eq: GROUP_TYPES.MANUAL,
        client_id_eq: this.props.clientId,
      },
      include: {
        children: null,
      },
    };

    return this.props.getVisualGroups(params);
  };

  setDefaultSelectedRows = (selectedIds = []) => {
    this.setState({selectedIds: [...selectedIds]});
  };

  handleActiveModalSet =
    (activeModal, state = {}) =>
    () =>
      this.setState({...state, activeModal});

  handleActiveModalClose =
    (update = false, state = {}) =>
    () => {
      this.setState({
        ...state,
        activeModal: null,
      });

      if (update) {
        this.props.updateMe();
      }
    };

  handlePreviewClick = (activeVisual) => this.handleActiveModalSet(VisClient.modals.PREVIEW, {activeVisual})();

  handlePreviewNextClick = () => {
    const {visuals} = this.props.visuals;
    const index = findIndex(visuals, (i) => i.id === this.state.activeVisual.id);

    if (index < visuals.length - 1) {
      return this.setState({
        activeVisual: visuals[(index + 1) % visuals.length],
      });
    }

    this.setQueryParams(
      {
        filters: mapFilter(this.queryParams.filters),
        page: (+this.queryParams.page % this.props.visuals.meta.total_pages) + 1,
      },
      true,
    );

    return this.props.updateMe().then(() =>
      this.setState({
        activeVisual: this.props.visuals.visuals[0],
      }),
    );
  };

  handlePreviewPreviousClick = () => {
    const {visuals} = this.props.visuals;
    const index = findIndex(visuals, (i) => i.id === this.state.activeVisual.id);

    if (index > 0) {
      return this.setState({
        activeVisual: visuals[index - 1],
      });
    }

    const totalPages = this.props.visuals.meta.total_pages;
    this.setQueryParams(
      {
        filters: mapFilter(this.queryParams.filters),
        page: ((this.queryParams.page - 2 + totalPages) % totalPages) + 1,
      },
      true,
    );

    return this.props.updateMe().then(() =>
      this.setState({
        activeVisual: this.props.visuals.visuals[this.props.visuals.visuals.length - 1],
      }),
    );
  };

  handleLeadClick = (activeVisual) => this.handleActiveModalSet(VisClient.modals.LEAD_INFO, {activeVisual})();

  handleVisualSelect =
    (...ids) =>
    (checked) => {
      // const {groupItemsByVisualId} = this.props;
      const selectedIds = this.state.selectedIds.slice();

      if (checked) {
        for (let id of ids) {
          if (!selectedIds.includes(id)) {
            selectedIds.push(id);
          }
        }
      } else {
        pull(selectedIds, ...ids);
      }

      this.setState({selectedIds});

      const {groupId, isGroupNew, isEditMode} = this.queryParams;

      if (!!groupId && (isGroupNew || isEditMode)) {
        this.props.handleVisualsGroupChange({checked, ids});
      }
    };

  handleDeleteVisuals = () =>
    deleteMethod(API_METHODS.VISUALS, {
      q: {
        id_in: this.state.selectedIds,
      },
    }).then(this.handleActiveModalClose(true, {selectedIds: []}));

  handlePostClick = (showingExecution) => {
    return this.handleActiveModalSet(VisClient.modals.CONFIRM_PUBLICATION, {showingExecution})();
  };

  getVisualsType = () => {
    const {trackerId} = this.queryParams;

    if (trackerId) {
      return POST_PREVIEW_TYPES.HASHTAG_TRACKER;
    }

    return POST_PREVIEW_TYPES.MAIN;
  };

  handleCreateGroup = ({groupId, parentGroupId, fromScratch}) => {
    this.handleActiveModalClose(false, {selectedIds: []})();

    const parent = parentGroupId && fromScratch ? {parentGroupId} : {};

    this.setQueryParams(
      {
        filters: mapFilter(this.queryParams.filters),
        groupId,
        isEditMode: fromScratch,
        ...parent,
      },
      true,
    );

    this.props.updateMe().then(this.getVisualGroups);
  };

  handleGroupConfirm = ({id: groupId, parentGroupId}) => {
    const idsToSet = parentGroupId ? {groupId, parentGroupId} : {groupId};

    this.setQueryParams(
      {
        filters: mapFilter(this.queryParams.filters),
        isEditMode: true,
        ...idsToSet,
      },
      true,
    );

    this.props.setGroupId(groupId); // new callback to set group id
    this.props.updateMe();
  };

  isColAdapterInDiaporama = (colAdapterName) =>
    this.props.diaporamaValues[colAdapterName] &&
    typeof this.props.diaporamaValues[colAdapterName].value !== 'undefined' &&
    this.props.diaporamaValues[colAdapterName].value !== 'null';

  getArrivingType = (visual) => {
    switch (true) {
      case this.isColAdapterInDiaporama('arriving_type'):
        return this.props.diaporamaValues.arriving_type.value;
      case !visual.interaction:
        return this.LANGUAGE_ARRIVING_TYPES.TRACKER;
      case visual.interaction.type === VISUAL_INTERACTION_TYPES.OFFLINE:
        return this.LANGUAGE_ARRIVING_TYPES.DEVICE;
      default:
        return this.LANGUAGE_ARRIVING_TYPES.WEB;
    }
  };

  getVisualOrDiaporamaValue = (fieldName, visual, diaporamaValue) =>
    this.isColAdapterInDiaporama(fieldName) ? diaporamaValue[fieldName].value : visual[fieldName];

  render() {
    const {activeVisual, selectedIds, activeModal, showClear} = this.state;

    const {
      showDeleteFromDbButton,
      allowedFilterGroups,
      showPublishButton,
      additionalModals,
      controlsContent,
      visuals,
      isAdmin,
      titles,
      clientId,
    } = this.props;

    const {configId, slideshowId, groupId, trackerId, isGroupNew, isEditMode} = this.queryParams;
    const showConfigDropdowns = isAdmin && !(configId || slideshowId || groupId || trackerId);
    const data = visuals.visuals;

    return (
      <div>
        <VisCreateGroupModal
          show={activeModal === VisClient.modals.CREATE_GROUP}
          clientId={clientId}
          selectedIds={selectedIds}
          onConfirm={this.handleCreateGroup}
          onClose={this.handleActiveModalClose()}
        />

        <VisFiltersClientModal
          show={activeModal === VisClient.modals.FILTERS}
          onClose={this.handleActiveModalClose()}
          allowedFilterGroups={allowedFilterGroups}
          totalItems={visuals.meta.total_count}
          clientId={clientId}
          updateMe={this.props.updateMe}
        />

        <VisPreviewModal
          show={activeModal === VisClient.modals.PREVIEW}
          visual={activeVisual}
          onClose={this.handleActiveModalClose(false, {activeVisual: {}})}
          updateMe={this.props.updateMe}
          onNext={this.handlePreviewNextClick}
          onPrevious={this.handlePreviewPreviousClick}
          selectedIds={selectedIds}
          onSelect={this.handleVisualSelect(activeVisual.id)}
          diaporamaValues={this.props.diaporamaValues}
          isColAdapterInDiaporama={this.isColAdapterInDiaporama}
          getArrivingType={this.getArrivingType}
          getVisualOrDiaporamaValue={this.getVisualOrDiaporamaValue}
          showNavigation={data.length > 1}
        />

        <VisTableSettingsModal
          show={activeModal === VisClient.modals.TABLE_SETTINGS}
          updateMe={this.props.updateMe}
          onClose={this.handleActiveModalClose()}
        />

        <VisLeadInfoModal
          show={activeModal === VisClient.modals.LEAD_INFO}
          visual={activeVisual}
          onClose={this.handleActiveModalClose(false, {activeVisual: {}})}
        />

        <ConfirmationModal
          title={this.LANGUAGE.DELETE_VISUALS_MODAL.TITLE}
          message={this.LANGUAGE.DELETE_VISUALS_MODAL.MESSAGE}
          cancelText={this.LANGUAGE.DELETE_VISUALS_MODAL.CANCEL_BUTTON}
          confirmText={this.LANGUAGE.DELETE_VISUALS_MODAL.CONFIRM_BUTTON}
          className="theme-color-13"
          buttonConfirmClass="button--bg-8"
          onConfirm={this.handleDeleteVisuals}
          onCancel={this.handleActiveModalClose()}
          onClose={this.handleActiveModalClose()}
          show={activeModal === VisClient.modals.DELETE}
        />

        {additionalModals}

        <VisualsBase
          clientId={this.props.clientId}
          gridView={this.props.gridView}
          noToggle={this.props.noToggle}
          isAdmin={this.props.isAdmin}
          visualsType={this.getVisualsType()}
          data={data}
          meta={visuals.meta}
          selectedIds={selectedIds}
          showClear={showClear}
          onSelect={this.handleVisualSelect}
          onLeadClick={this.handleLeadClick}
          onPreviewClick={this.handlePreviewClick}
          onSettingsClick={this.handleActiveModalSet(VisClient.modals.TABLE_SETTINGS)}
          updateMe={this.props.updateMe}
          forceUpdate={() => this.forceUpdate()}
          onShowFiltersClick={this.handleActiveModalSet(VisClient.modals.FILTERS)}
          languageState={this.payload}
          gridNoDataMessage={this.LANGUAGE.NO_DATA_TEXT}
          diaporamaValues={this.props.diaporamaValues}
          isColAdapterInDiaporama={this.isColAdapterInDiaporama}
          getArrivingType={this.getArrivingType}
          getVisualOrDiaporamaValue={this.getVisualOrDiaporamaValue}
          titleContent={
            <div className="visuals__page-title">
              {titles}
              {showConfigDropdowns && (
                <div className="visuals__select-dropdown">
                  <VisSelectDropdown value={this.LANGUAGE.SELECT_DROPDOWN.GROUP_VALUE} customDropdown={this.editGroup}>
                    <VisGroupsForm
                      onGroupConfirm={this.handleGroupConfirm}
                      onCreateGroupClick={this.handleActiveModalSet(VisClient.modals.CREATE_GROUP, {selectedIds: []})}
                      getListRef={(list) => {
                        this.editGroup = list;
                      }}
                    />
                  </VisSelectDropdown>
                </div>
              )}
            </div>
          }
          actionButtons={
            <div className="vis-data-grid__action-btns">
              {showDeleteFromDbButton && (
                <div>
                  <button className="button button--bg-8 " onClick={this.handleActiveModalSet(VisClient.modals.DELETE)}>
                    {`${this.LANGUAGE.TABLE.DELETE_FROM_DB_BUTTON} (${selectedIds.length})`}
                  </button>
                </div>
              )}
              <div>
                <button className="button button--bg-8" onClick={() => this.setState({selectedIds: []})}>
                  {`${this.LANGUAGE.TABLE.DESELECT_ALL_BUTTON} (${selectedIds.length})`}
                </button>
              </div>
              {showPublishButton && selectedIds.length > 0 && (
                <div>
                  <button
                    className="button button--bg-8 "
                    onClick={this.handleActiveModalSet(VisClient.modals.CONFIRM_PUBLICATION)}
                  >
                    {this.LANGUAGE.TABLE.PUBLISH_BUTTON}
                  </button>
                </div>
              )}
              {selectedIds.length > 0 && !isGroupNew && !isEditMode && (
                <div>
                  <button
                    className="button button--bg-8 "
                    onClick={this.handleActiveModalSet(VisClient.modals.CREATE_GROUP)}
                  >
                    {this.LANGUAGE.TABLE.CREATE_GROUP_BUTTON}
                  </button>
                </div>
              )}
            </div>
          }
          controlsContent={controlsContent}
        />
      </div>
    );
  }
}

const mapStateToProps = ({languageState, ...state}, {groupId}) => {
  return {
    visuals: state.visuals,
    defaultSelectedIds: selectVisualsIdsInsideGroup(state, groupId),
    groupItemsByVisualId: selectManualGroupItemsByVisualId(state),
    languageState,
    isAdmin: selectIsAdmin(state),
  };
};

export default connect(mapStateToProps, {
  resetForm: reset,
  getVisualGroups,
})(VisClient);
