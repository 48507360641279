export default {
  USERS_CHANGE_PER_PAGE: '@@company/USERS_CHANGE_PER_PAGE',
  ONGOING_OPERATIONS_CHANGE_PER_PAGE: '@@company/ONGOING_OPERATIONS_CHANGE_PER_PAGE',
  FINISHED_OPERATIONS_CHANGE_PER_PAGE: '@@company/FINISHED_OPERATIONS_CHANGE_PER_PAGE',

  USERS_CHANGE_SORT: '@@company/USERS_CHANGE_SORT',
  ONGOING_OPERATIONS_CHANGE_SORT: '@@company/ONGOING_OPERATIONS_CHANGE_SORT',
  FINISHED_OPERATIONS_CHANGE_SORT: '@@company/FINISHED_OPERATIONS_CHANGE_SORT',

  USERS_CHANGE_SEARCH: '@@company/USERS_CHANGE_SEARCH',

  CHANGE_SELECTED_USERS: '@@company/CHANGE_SELECTED_USERS',
  CLEAR_STORE: '@@company/CLEAR_STORE',

  GET_USERS_REQUEST: '@@company/GET_USERS_REQUEST',
  GET_USERS_SUCCESS: '@@company/GET_USERS_SUCCESS',
  GET_USERS_ERROR: '@@company/GET_USERS_ERROR',

  GET_ONGOING_OPERATIONS_REQUEST: '@@company/GET_ONGOING_OPERATIONS_REQUEST',
  GET_ONGOING_OPERATIONS_SUCCESS: '@@company/GET_ONGOING_OPERATIONS_SUCCESS',
  GET_ONGOING_OPERATIONS_ERROR: '@@company/GET_ONGOING_OPERATIONS_ERROR',

  GET_FINISHED_OPERATIONS_REQUEST: '@@company/GET_FINISHED_OPERATIONS_REQUEST',
  GET_FINISHED_OPERATIONS_SUCCESS: '@@company/GET_FINISHED_OPERATIONS_SUCCESS',
  GET_FINISHED_OPERATIONS_ERROR: '@@company/GET_FINISHED_OPERATIONS_ERROR',

  GET_COMPANY_REQUEST: '@@company/GET_COMPANY_REQUEST',
  GET_COMPANY_SUCCESS: '@@company/GET_COMPANY_SUCCESS',
  GET_COMPANY_ERROR: '@@company/GET_COMPANY_ERROR',

  DELETE_COMPANY_REQUEST: '@@company/DELETE_COMPANY_REQUEST',
  DELETE_COMPANY_SUCCESS: '@@company/DELETE_COMPANY_SUCCESS',
  DELETE_COMPANY_ERROR: '@@company/DELETE_COMPANY_ERROR',

  GET_CLIENT_USER_REQUEST: '@@company/GET_CLIENT_USER_REQUEST',
  GET_CLIENT_USER_SUCCESS: '@@company/GET_CLIENT_USER_SUCCESS',
  GET_CLIENT_USER_ERROR: '@@company/GET_CLIENT_USER_ERROR',

  GET_POCS_AGENCY_REQUEST: '@@company/GET_POCS_AGENCY_REQUEST',
  GET_POCS_AGENCY_SUCCESS: '@@company/GET_POCS_AGENCY_SUCCESS',
  GET_POCS_AGENCY_ERROR: '@@company/GET_POCS_AGENCY_ERROR',

  GET_SUBSIDIARIES_REQUEST: '@@company/GET_SUBSIDIARIES_REQUEST',
  GET_SUBSIDIARIES_SUCCESS: '@@company/GET_SUBSIDIARIES_SUCCESS',
  GET_SUBSIDIARIES_ERROR: '@@company/GET_SUBSIDIARIES_ERROR',

  GET_AGENCIES_REQUEST: '@@company/GET_AGENCIES_REQUEST',
  GET_AGENCIES_SUCCESS: '@@company/GET_AGENCIES_SUCCESS',
  GET_AGENCIES_ERROR: '@@company/GET_AGENCIES_ERROR',

  GET_COMPANY_MEMBERSHIPS_REQUEST: '@@company/GET_COMPANY_MEMBERSHIPS_REQUEST',
  GET_COMPANY_MEMBERSHIPS_SUCCESS: '@@company/GET_COMPANY_MEMBERSHIPS_SUCCESS',
  GET_COMPANY_MEMBERSHIPS_ERROR: '@@company/GET_COMPANY_MEMBERSHIPS_ERROR',

  GET_AGENCY_MEMBERSHIPS_REQUEST: '@@company/GET_AGENCY_MEMBERSHIPS_REQUEST',
  GET_AGENCY_MEMBERSHIPS_SUCCESS: '@@company/GET_AGENCY_MEMBERSHIPS_SUCCESS',
  GET_AGENCY_MEMBERSHIPS_ERROR: '@@company/GET_AGENCY_MEMBERSHIPS_ERROR',

  GET_ADMIN_USERS_REQUEST: '@@company/GET_ADMIN_USERS_REQUEST',
  GET_ADMIN_USERS_SUCCESS: '@@company/GET_ADMIN_USERS_SUCCESS',
  GET_ADMIN_USERS_ERROR: '@@company/GET_ADMIN_USERS_ERROR',

  GET_COMPANY_USERS_FULL_LIST_REQUEST: '@@company/GET_COMPANY_USERS_FULL_LIST_REQUEST',
  GET_COMPANY_USERS_FULL_LIST_SUCCESS: '@@company/GET_COMPANY_USERS_FULL_LIST_SUCCESS',
  GET_COMPANY_USERS_FULL_LIST_ERROR: '@@company/GET_COMPANY_USERS_FULL_LIST_ERROR',

  SET_DEFAULT_NEW_USER: '@@company/SET_DEFAULT_NEW_USER',
};
