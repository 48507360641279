import {LangsByPath} from 'client/services/hooks/useLanguage/types';

import {Translation} from 'client/models/language/types';

type Timestamp = {
  value: string;
  id: string;
};

type ValidateProps = (
  values: {
    timestamps?: Timestamp[];
  },
  config: {lang: LangsByPath<['PRIZES.FIELDSETS.ERRORS']>},
) => Record<string, string>;

export default ((values, {lang}, shouldValidate = true) => {
  const errors: Record<string, Translation> = {};

  if (!shouldValidate || !values) {
    return errors;
  }

  if (!Array.isArray(values?.timestamps) || values?.timestamps.length < 1) {
    errors.timestamps = lang.REQUIRED;
  }

  return errors;
}) as ValidateProps;
