import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getSourcesStatisticsById} from 'client/ducks/participations-statistics/actions';
import {selectCampaignMainStatistics} from 'client/ducks/participations-statistics/selectors';
import {getInterfacesAction, getCampaignsAction} from 'client/ducks/sources-statistics/actions';
import {selectCampaignSources} from 'client/ducks/sources-statistics/selectors';

import SourcesList from './campaigns-list';
import CampaignsMainInfoBlock from './campaigns-main-info-block';

import './campaigns-tab.scss';

class CampaignTab extends Component {
  static propTypes = {
    autotask: PropTypes.object.isRequired,
    language: PropTypes.object,
    sources: PropTypes.object,
    campaigns: PropTypes.object,
    getSourcesStatistics: PropTypes.func,
    getCamapigns: PropTypes.func.isRequired,
    getInterfaces: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
  };

  componentDidMount() {
    this.getTabData();
  }

  toggleLoading = () => this.setState(({loading}) => ({loading: !loading}));

  getTabData = () => {
    const {autotask, getSourcesStatistics} = this.props;
    const queryParams = {
      automation_task_id: autotask.id,
    };
    this.toggleLoading();
    getSourcesStatistics(queryParams).then(this.toggleLoading);
  };

  render() {
    const {
      autotask,
      sources: {
        total_direct_and_campaigns_visits,
        total_direct_and_campaigns_participations,
        visitsBySourceType,
        lists,
      },
      language,
    } = this.props;
    const {loading} = this.state;
    const data = {
      visits: total_direct_and_campaigns_visits,
      participations: total_direct_and_campaigns_participations,
      visitsBySourceType: visitsBySourceType,
    };
    const {campaigns: campaignsList, direct_access} = lists;
    const totalValues = {
      visits: total_direct_and_campaigns_visits,
      participations: total_direct_and_campaigns_participations,
    };
    const showDirect =
      direct_access.length > 0 && (direct_access[0].participations_count > 0 || direct_access[0].visits_count > 0);

    return (
      <div className="campaigns-tab">
        <CampaignsMainInfoBlock autotaskId={autotask.id} data={data} loading={loading} />
        {showDirect && (
          <SourcesList
            name={language.DIRECT_ACCESS}
            description={language.INTERFACE_DESCRIPTION}
            sources={direct_access}
            totalValues={totalValues}
            periodText={language.PERIOD}
          />
        )}
        {campaignsList.length > 0 && (
          <SourcesList sources={campaignsList} totalValues={totalValues} periodText={language.PERIOD} />
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    sources: selectCampaignMainStatistics(state),
    campaigns: selectCampaignSources(state),
    language: state.languageState.payload.CAMPAIGNS_TAB,
  }),
  {
    getCamapigns: getCampaignsAction,
    getInterfaces: getInterfacesAction,
    getSourcesStatistics: getSourcesStatisticsById,
  },
)(CampaignTab);
