export const selectAnimations = (state) => state.animations.animations;

export const selectAnimationKpis = (state) => state.animations.animation_kpis;

export const selectAnimation = (state) => state.animations.animation;

export const selectAnomalyOccurrences = (state) => state.animations.anomalyOccurrences;

/**
 * @return {import('client/models/animations').AnimationStatistic}
 */
export const selectStatistics = (state) => state.animations.statistics;

export const selectAnimationPhotos = (state) => state.animations.photos;
