import React, {useState, useEffect} from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import TextInput from 'client/common/inputs/text-input';

import cssModule from './external-link-tab.module.scss';

const b = bem('external-link-tab', {cssModule});

type ExternalLinkTabProps = {
  onChange: (data: {disabled: boolean; src?: string}) => void;
};

const ExternalLinkTab: React.FC<ExternalLinkTabProps> = (props) => {
  const {onChange} = props;
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.MODALS.INSERT_IMAGE_MODAL.EXTERNAL_LINK_TAB');
  const [url, setUrl] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (!isError && url) {
      onChange({disabled: false, src: url});
    }
  }, [isError, url, onChange]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    setUrl(e.target.value);
  };

  const handleError = () => {
    setIsError(true);
    onChange({disabled: true});
  };

  return (
    <div className={b()}>
      <TextInput
        label={lang.URL}
        placeholder={lang.PUT_URL?.toString()}
        value={url}
        errorMessage={isError ? lang.INVALID_URL : null}
        onChange={handleChange}
      />
      {url && !isError && (
        <div className={b('image-wrap')}>
          <img className={b('image-preview')} src={url} alt="" onError={handleError} crossOrigin="anonymous" />
        </div>
      )}
    </div>
  );
};

export default ExternalLinkTab;
