import React from 'react';

import bem from 'client/services/bem';
import useReduxForm from 'client/services/hooks/use-redux-form';

import PrizeCustomizationCampaignPeriod from 'client/components/prizes/fields/prize-customization-campaign-period';
import {PrizeCustomizationModalFormName} from 'client/components/prizes/modals/prize-customization-modal';
import {PrizeCustomizationModalForm} from 'client/components/prizes/modals/prize-customization-modal/types';
import PrizeCustomizationPrizesTable from 'client/components/prizes/tables/prize-customization-prizes-table';

import cssModule from './prize-customization-form.module.scss';

const b = bem('prize-customization-form', {cssModule});

type PrizeCustomizationFormProps = {
  editable: boolean;
  disabled: boolean;
  onSelectPrizeMap: (index: number) => void;
};

const PrizeCustomizationForm: React.FC<PrizeCustomizationFormProps> = (props) => {
  const {editable, disabled, onSelectPrizeMap} = props;
  const {formValues} = useReduxForm<PrizeCustomizationModalForm>(PrizeCustomizationModalFormName);

  return (
    <div className={b()}>
      <PrizeCustomizationCampaignPeriod editable={editable && !formValues.disabledTime} />
      <PrizeCustomizationPrizesTable
        prizes={formValues.prizeMaps || []}
        editable={editable && !disabled}
        className={b('table')}
        onSelectPrizeMap={onSelectPrizeMap}
      />
    </div>
  );
};

export default PrizeCustomizationForm;
