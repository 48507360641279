import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import {getSourcesStatisticsById} from 'client/ducks/participations-statistics/actions';
import {selectSourcesStatistics} from 'client/ducks/participations-statistics/selectors';

import SourceCard from './source-card';

import SourcesList from '../../campaigns/campaigns-list';
import ParticipationTabCard from '../participation-tab-card';
import ParticipationTabNotAvailable from '../participation-tab-not-available';

import './sources-block.scss';

const b = bem('sources-block');

const mapList = (list) =>
  list.map((item) => ({
    ...item,
    isOpen: false,
  }));

class SourcesBlock extends PureComponent {
  static propTypes = {
    autotask: PropTypes.object,
    language: PropTypes.object,
    lists: PropTypes.object,
    totalParticipations: PropTypes.number,
    getSourcesStatistics: PropTypes.func.isRequired,
  };

  state = {
    isCollapsed: false,
    loading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      devices: props.lists.devices,
      direct_access: mapList(props.lists.direct_access),
      campaigns: mapList(props.lists.campaigns),
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.autotask.id !== this.props.autotask.id) {
      await this.fetchData();
      this.setLists(this.props.lists);
    }
  }

  setLists = (lists) => {
    this.setState({
      devices: lists.devices,
      direct_access: mapList(lists.direct_access),
      campaigns: mapList(lists.campaigns),
    });
  };

  toggleLoading = () => this.setState(({loading}) => ({loading: !loading}));

  fetchData = () => {
    const {autotask, getSourcesStatistics} = this.props;
    const queryParams = {
      automation_task_id: autotask.id,
      with_empty_campaigns: true,
    };
    this.toggleLoading();
    getSourcesStatistics(queryParams).then(this.toggleLoading);
  };

  toggleCampaignSubItems = (name) => {
    const index = this.state.sources.findIndex((elem) => elem.name === name);
    const foundSource = {
      ...this.state.sources.find((elem) => elem.name === name),
    };
    foundSource.isOpen = !foundSource.isOpen;
    const newItems = [...this.state.sources];
    newItems[index] = foundSource;
    this.setState({
      sources: newItems,
    });
  };

  renderDirectAccesses = (list) => {
    const {language, totalParticipations} = this.props;
    if (list.length && !list[0].participations_count) {
      return null;
    }

    return (
      <SourcesList
        listName="Direct Access"
        sources={list}
        renderListItem={(item) => (
          <SourceCard
            key={item.name}
            source={{...item, name: language.DIRECT_ACCESS}}
            totalCount={totalParticipations}
            showSubItemsIfNoTotal={true}
            openable={true}
          />
        )}
      />
    );
  };

  renderDevicesList = (list) => {
    const {language, totalParticipations} = this.props;
    return (
      <SourcesList
        listName={language.DEVICES}
        sources={list}
        renderListItem={(item) => (
          <SourceCard
            key={item.name}
            source={{...item, name: `${language.DEVICES} - ${item.name}`}}
            totalCount={totalParticipations}
            openable={true}
          />
        )}
      />
    );
  };

  renderCampaignsList = (list) => {
    const {language, totalParticipations} = this.props;
    return (
      <SourcesList
        listName={language.CAMPAIGN}
        sources={list}
        renderListItem={(item) => (
          <SourceCard
            key={item.name}
            source={item}
            totalCount={totalParticipations}
            showSubItemsIfNoTotal={true}
            openable={true}
          />
        )}
      />
    );
  };

  render() {
    const {
      autotask,
      lists: {devices, direct_access, campaigns},
      language,
    } = this.props;
    const {loading} = this.state;
    const isEmpty = !autotask.started_interactions_count;

    return (
      <div className={b()}>
        <ParticipationTabCard
          iconName="participation-mediatisation-colored"
          title={language.TITLE}
          noPrimaryIconClass={true}
          loading={loading}
          loadingColor="participants"
        >
          {isEmpty ? (
            <ParticipationTabNotAvailable text={language.NOT_AVAILABLE} />
          ) : (
            <div className={b('content')}>
              {this.renderDevicesList(devices)}
              {this.renderDirectAccesses(direct_access)}
              {this.renderCampaignsList(campaigns)}
            </div>
          )}
        </ParticipationTabCard>
      </div>
    );
  }
}

export default connect(
  ({languageState, ...state}) => ({
    language: languageState.payload.PARTICIPATIONS_TAB.SOURCES,
    totalParticipations: state.participationsStatistics.common.participations_statistic.participations.total,
    lists: selectSourcesStatistics(state),
  }),
  {
    getSourcesStatistics: getSourcesStatisticsById,
  },
)(SourcesBlock);
