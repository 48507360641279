import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import {diagramKinds} from 'client/ducks/results-graphs/constants';

import Icon from 'client/components/common/icon';
import Spinner from 'client/components/common/spinner';

import {Diagram} from 'client/models/diagrams/types';

import DiagramEmpty from './diagram-empty';
import DiagramStats from './diagram-stats';
import {componentMap, hasLongStringLabels} from './helpers';

import cssModule from './diagram-card.module.scss';

const b = bem('diagram-card', {cssModule});

type DiagramCardProps = {
  diagram: Diagram;
  className?: string;
};

const DiagramCard: React.FC<DiagramCardProps> = (props) => {
  const {diagram, className} = props;
  const {loading, name, data_overhead, values_count, kind, values} = diagram;

  const noData = data_overhead || !values_count;
  let Component = componentMap[kind];

  if (kind === diagramKinds.VERTICAL_BARS && hasLongStringLabels(values)) {
    Component = componentMap[diagramKinds.HORIZONTAL_BARS];
  }

  if (loading) {
    return <Spinner className={cn(b(), className)} color="primary" centered />;
  }

  return (
    <div className={cn(b(), className)}>
      <div className={b('header')}>
        <Icon name="diagram" />
        <p className={b('header-title')}>{name}</p>
      </div>
      {!noData && <DiagramStats diagram={diagram} className={b('diagram-stats')} />}
      <div className={b('content')}>
        {noData ? <DiagramEmpty overhead={data_overhead} /> : <Component diagram={diagram} />}
      </div>
    </div>
  );
};

export default DiagramCard;
