import {uid} from 'client/services/helpers';

import {NEW_TIMESTAMP_PREFIX} from 'client/common/inputs/datetime-list-input/constants';

export const DATE_TIME_PATTERN =
  /^(3[01]|[12][0-9]|0?[1-9])\/(1[012]|0?[1-9])\/((?:19|20)\d\d) ([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/; // dd/mm/yyyy hh:mm:ss

type ValidatedValue = {
  id: string;
  value: string;
};

export const validateImportedValues = (values: string[]): ValidatedValue[] =>
  values.filter((value) => DATE_TIME_PATTERN.test(value)).map((value) => ({id: uid(NEW_TIMESTAMP_PREFIX), value}));
