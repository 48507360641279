import React from 'react';

import {Field} from 'redux-form';

import BaseFieldComponent from 'client/common/fields/base-field';
import {PasswordInput} from 'client/common/inputs';

const InputComponent = (props) => <BaseFieldComponent Component={PasswordInput} {...props} />;

const PasswordField = (props) => {
  return <Field component={InputComponent} {...props} />;
};

export default PasswordField;
