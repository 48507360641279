export const PARTICIPANTS_SORT_MAP = {
  participation__created_at: 'created_at',
  interface__name: 'interface_name',
  lead__visuals_count: 'lead_visuals_count',
  lead__latest_participated_at: 'lead_latest_participated_at',
  participation__participated_at: 'participated_at',
  participation__accessed_level: 'accessed_level',
};

/**
 * @type {Record<string, string>}
 */
export const PARTICIPANTS_COLUMN_MAP = {
  'Source id': 'PermanentSource id',
  'Source kind': 'PermanentSource type',
  'Source name': 'PermanentSource name',
  'Source created_at': 'PermanentSource created_at',
  'PermanentSource kind': 'PermanentSource type',
  'Interaction kind': 'Interaction source_type',
};
