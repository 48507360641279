import {phone, email} from 'client/services/validator';

const validateAnimator = (formValues, {lang}) => {
  const errors = {};

  errors.reco_email = email(lang.ERRORS.EMAIL_FORMAT_INCORRECT)(formValues.reco_email || '');
  errors.reco_phone = phone(lang.ERRORS.PHONE_FORMAT_INCORRECT)(formValues.reco_phone || '');

  return errors;
};

export default validateAnimator;
