import moment from 'moment';

import {TIME_FORMAT} from 'client/components/prizes/constants';
import {SCHEDULE_TABS} from 'client/components/prizes/fieldsets/prize-diy-schedule-fieldset/constants';

export default (prize) => {
  const {
    prize_timestamps,
    schedule_type,
    id,
    intraday_win = true,
    next_day_win = true,
  } = prize?.schedule_instant_win_configuration || {};

  const weeklyList = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  };

  let configurationId;
  let initialTimestamps = [];

  if (schedule_type === SCHEDULE_TABS.WEEKLY_LIST) {
    prize_timestamps?.forEach((timeStamp) => {
      weeklyList[timeStamp.weekday] = weeklyList[timeStamp.weekday] || [];
      weeklyList[timeStamp.weekday].push({value: moment(timeStamp.win_time).format(TIME_FORMAT), id: timeStamp.id});
    });

    Object.entries(weeklyList).forEach(([day, timestamps]) => {
      if (timestamps.length > 0) {
        weeklyList[day] = timestamps.sort((first, second) =>
          moment(first.value, TIME_FORMAT).diff(moment(second.value, TIME_FORMAT), 'seconds'),
        );
      }
    });

    configurationId = id;
    initialTimestamps = prize_timestamps;
  }

  return {
    timestamps: weeklyList,
    configurationId,
    intraday_win,
    next_day_win,
    initialTimestamps,
  };
};
