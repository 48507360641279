import React, {useMemo, useState} from 'react';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {createClientUser, updateClientUser} from 'client/ducks/client-users/actions';
import {interpolate} from 'client/ducks/language/helpers';

import AppButton from 'client/common/buttons';
import {USER_MEMBERSHIP} from 'client/common/config';
import {useToast} from 'client/common/hooks/useToast';
import Modal from 'client/common/modals/modal';

import {Client} from 'client/models/client/types';
import {Membership} from 'client/models/memberships/types';
import {User} from 'client/models/users/types';
import {ObjectValues} from 'client/types/common';

import initial from './initial';
import mapping from './mapping';
import * as stepsComponents from './steps';
import {ClientUserModalForm} from './types';
import validate from './validate';

import cssModule from './client-user-modal.module.scss';

const {
  ClientUserAgencyMembershipStep,
  ClientUserEmailStep,
  ClientUserGeneralStep,
  ClientUserAgencyClientsStep,
  ClientUserRegionsStep,
  ClientUserStoresStep,
  ClientUserMembershipStep,
} = stepsComponents;

type ClientUserModalProps = {
  client: Client;
  initialData?: Membership | null;
  onClose: () => void;
  onSubmit: () => void;
};

const b = bem('client-user-modal', {cssModule});

export const ClientUserModalFormName = 'ClientUserForm';

const ClientUserModal: ReduxFormFC<ClientUserModalProps, ClientUserModalForm> = (props) => {
  const {initialData, onClose, onSubmit, client, handleSubmit} = props;

  const isEdit = !!initialData;

  const lang = useLanguage('CLIENT_ACCOUNT_PARAMETERS.MODALS.CLIENT_USER');
  const langCommon = useLanguage('COMMON');

  const [step, setStep] = useState(0);
  const [userByEmail, setUserByEmail] = useState<User | null>(initialData?.client_user || null);

  const {appendToastNotification} = useToast();

  const isAgency = client.type === 'Agency';

  const {valid, reset, formValues, isAsyncValidating} = useReduxForm<ClientUserModalForm>(ClientUserModalFormName, {
    validate: (values) =>
      validate(values, {lang: lang.ERRORS, currentStep: step, client, userByEmail, hasEmailStep: !initialData}),
    validateDeps: [step],
    initialValues: initial(initialData),
  });

  const {fetch: postUser, loading: loadingUser} = useReduxFetch({
    action: createClientUser,
    actionArgs: {},
    fetchOnMount: false,
  });
  const {fetch: patchUser, loading: loadingPatchUser} = useReduxFetch({
    action: updateClientUser,
    actionArgs: {},
    fetchOnMount: false,
  });
  const loading = loadingUser || loadingPatchUser;

  const hasClientsStep =
    isAgency &&
    formValues.memberships?.[0]?.access_level === USER_MEMBERSHIP.NATIONAL &&
    !formValues.memberships?.[0]?.all_companies_access;

  const hasRegionsStep = [USER_MEMBERSHIP.REGION, USER_MEMBERSHIP.LOCAL].includes(
    formValues.memberships?.[0]?.access_level as string,
  );
  const hasStoresStep = formValues.memberships?.[0]?.access_level === USER_MEMBERSHIP.STORE;

  const steps = useMemo(() => {
    const result: ObjectValues<typeof stepsComponents>[] = [];

    if (!isEdit) {
      result.push(ClientUserEmailStep);
    }

    if (!userByEmail || isEdit) {
      result.push(ClientUserGeneralStep);
    }

    if (isAgency) {
      result.push(ClientUserAgencyMembershipStep);
      if (hasClientsStep) {
        result.push(ClientUserAgencyClientsStep);
      }
    } else {
      result.push(ClientUserMembershipStep);
      if (hasRegionsStep) {
        result.push(ClientUserRegionsStep);
      } else if (hasStoresStep) {
        result.push(ClientUserStoresStep);
      }
    }

    return result;
  }, [isEdit, hasStoresStep, userByEmail, hasClientsStep, isAgency, hasRegionsStep]);

  const CurrentStep = steps[step] || (() => null);

  const handleClickNext = () => {
    if (step !== steps.length - 1) {
      setStep((prev) => prev + 1);
    }
  };
  const handleClickBack = () => {
    if (step) {
      setStep((prev) => prev - 1);
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSave = handleSubmit(async (values) => {
    try {
      const body = mapping(values, {client, userId: userByEmail?.id, initial: initialData});
      if (userByEmail) {
        await patchUser(userByEmail?.id, {client_user: body}, {client_id: client.id});
      } else {
        await postUser({client_user: body}, {client_id: client.id});
      }
      appendToastNotification({
        type: 'success',
        title: interpolate(
          isEdit ? lang.SUCCESS_UPDATING_NOTIFICATION?.toString() : lang.SUCCESS_SAVING_NOTIFICATION?.toString(),
          {
            firstName: values.first_name,
            lastName: values.last_name,
          },
        ),
      });
      onSubmit();
      onClose();
    } catch {
      appendToastNotification({
        title: langCommon.ERROR,
        type: 'error',
      });
    }
  });

  const stepTitle = interpolate(lang.STEP?.toString(), {
    current: (step + 1)?.toString(),
    total: steps.length.toString(),
  });

  return (
    <Modal
      title={isEdit ? lang.EDIT_TITLE : lang.CREATE_TITLE}
      titleButton={<span className={b('step-title')}>{stepTitle}</span>}
      className={b()}
      onClose={handleClose}
      classNames={{content: b('container')}}
    >
      <CurrentStep emailExists={!!userByEmail} onCheckEmail={setUserByEmail} key={step} client={client} />

      <div className={b('footer')}>
        {step === 0 && <AppButton label={lang.CANCEL_BUTTON} transparent onClick={handleClose} />}
        {step !== 0 && <AppButton label={lang.BACK_BUTTON} transparent onClick={handleClickBack} />}
        {step !== steps.length - 1 && (
          <AppButton
            label={lang.NEXT_BUTTON}
            size="small"
            disabled={!valid || isAsyncValidating}
            onClick={handleClickNext}
            loading={isAsyncValidating}
          />
        )}
        {step === steps.length - 1 && (
          <AppButton
            label={lang.SAVE_BUTTON}
            size="small"
            disabled={!valid || isAsyncValidating}
            loading={loading || isAsyncValidating}
            onClick={handleSave}
          />
        )}
      </div>
    </Modal>
  );
};

export default reduxForm<ClientUserModalProps, ClientUserModalForm>({
  form: ClientUserModalFormName,
})(ClientUserModal);
