import React, {useCallback, useState} from 'react';

import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Input from '../input/input';

import './hovering-input.scss';

const b = bem('hovering-input');

const HoveringInput = (props) => {
  const {input, readOnly} = props;
  const [value, setValue] = useState(input.value);
  const [prevValue, setPrevValue] = useState(input.value);
  const [isHovered, setIsHovered] = useState(false);

  const onChangeHandler = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const onBlurHandler = useCallback(
    (e) => {
      if (!e.target.value) {
        setValue(prevValue);
      } else {
        setPrevValue(e.target.value);
        setValue(e.target.value);
        input.onChange(e.target.value);
      }
    },
    [input, prevValue],
  );

  const onMouseEnter = useCallback(() => setIsHovered(true), []);
  const onMouseLeave = useCallback(() => setIsHovered(false), []);

  if (readOnly) {
    return (
      <div className={b({'read-only': readOnly})} title={value}>
        {value}
      </div>
    );
  }

  return (
    <div className={b({hovered: isHovered})} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Input
        {...props}
        value={isNumber(value) ? value.toString() : value}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
      />
    </div>
  );
};

HoveringInput.propTypes = {
  ...Input.propTypes,
  readOnly: PropTypes.bool,
};

HoveringInput.defaultProps = {
  ...Input.defaultProps,
  readOnly: false,
};

export default HoveringInput;
