import React, {useMemo} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons';

import ClientTable from 'client/components/common/client-table';

import './translations-search-results.scss';

const b = bem('translations-search-results');

const TranslationsSearchResults = ({searchResults, query, onItemClick}) => {
  const lang = useLanguage('TRANSLATIONS.SEARCH_MODAL');
  const columns = useMemo(() => {
    return [
      {
        name: 'id',
        path: 'id',
        cellClassName: cn(b('table-cell'), b('id-cell')),
        noWrap: true,
      },
      {
        name: 'key',
        path: 'key',
        cellClassName: cn(b('table-cell'), b('key-cell')),
        noWrap: true,
        render: ({value, item}) => (
          <div>
            <AppButton
              className={b('key-btn')}
              onClick={() => onItemClick(item)}
              label={
                <>
                  <span className={b('key-btn-prefix')}>
                    {item.parentPath}
                    {item.parentPath && '.'}
                  </span>
                  <span className={b('key-btn-value')}>{value}</span>
                </>
              }
              asWrap={true}
            />
          </div>
        ),
      },
    ];
  }, [onItemClick]);

  return (
    <div className={b()}>
      <div className={b('header')}>
        <span className={b('query')}>
          {lang.SEARCH_BY}: {query}
        </span>
        <span className={b('found-qty')}>
          {lang.FOUND}: {searchResults.length}
        </span>
      </div>
      {searchResults.length > 0 && (
        <ClientTable
          className={b('table')}
          columns={columns}
          data={searchResults}
          commonHeaderClassName={b('table-th')}
          noDataText={lang.NO_DATA}
        />
      )}
    </div>
  );
};

TranslationsSearchResults.propTypes = {
  searchResults: PropTypes.array.isRequired,
  query: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
};

TranslationsSearchResults.defaultProps = {
  query: '',
};

export default TranslationsSearchResults;
