import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getAnonymousEmail(token) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.ANONYMOUS_EMAILS}?token=${token}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_ANONYMOUS_EMAIL_REQUEST, types.GET_ANONYMOUS_EMAIL_SUCCESS, types.GET_ANONYMOUS_EMAIL_ERROR],
    });
}
