const mapImportItem = (item) => {
  return {
    file: null,
    url: item?.file_url,
    name: item?.file_name,
    tokens_imported: item?.tokens_imported,
    tokens_used: item?.tokens_used,
    uploaded_at: item?.uploaded_at,
  };
};

export default ({imports = {}, device_level_code_list} = {}, {device_online_segmentation} = {}) => {
  const {device, online, common, device_level} = imports;
  return {
    segmentationImports: device_online_segmentation && !common?.uploaded_at,
    device_level_code_list,
    device: mapImportItem(device),
    device_level: mapImportItem(device_level),
    online: mapImportItem(online),
    common: mapImportItem(common),
  };
};
