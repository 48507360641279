import React, {useState} from 'react';

import bem from 'client/services/bem';

import Icon from 'client/components/common/icon';

import {Visuals} from 'client/models/visuals/types';

import VisualsLeadModal from '../../visuals-lead-modal';

import cssModule from './visual-lead-cell.module.scss';

const b = bem('visual-lead-cell', {cssModule});

type VisualLeadCellProps = {
  visual: Visuals;
};

const VisualLeadCell: React.FC<VisualLeadCellProps> = ({visual}) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };

  if (!visual?.lead) {
    return null;
  }

  return (
    <div className={b()}>
      {showModal && <VisualsLeadModal visual={visual} onClose={toggleModal} />}
      <Icon name="card-2" className={b('icon')} onClick={toggleModal} />
    </div>
  );
};

export default VisualLeadCell;
