import types from './types';

const initialState = {
  broadcast: {},
  broadcasts: [],
  broadcastsById: {},
  recipients: [],
  display_items: [],
  recipients_meta: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_BROADCASTS_SUCCESS:
      return {
        ...state,
        broadcasts: action.payload.broadcast_lists,
        broadcastsById: action.payload.broadcast_lists.reduce((acc, broadcast) => {
          return {
            ...acc,
            [broadcast.id]: broadcast,
          };
        }, state.broadcastsById),
      };
    case types.GET_BROADCAST_SUCCESS:
      return {
        ...state,
        broadcast: action.payload.broadcast_list,
      };
    case types.GET_BROADCAST_RECIPIENTS_SUCCESS:
      return {
        ...state,
        recipients: [...action.payload.broadcast_recipients],
        recipients_meta: {
          ...state.recipients_meta,
          ...action.payload.meta,
        },
      };
    case types.CLEAR_BROADCAST_RECIPIENTS_SUCCESS:
      return {
        ...state,
        broadcast: initialState.broadcast,
        recipients: initialState.recipients,
        recipients_meta: initialState.recipients_meta,
      };
    case types.GET_BROADCAST_DISPLAY_ITEMS_SUCCESS:
      return {
        ...state,
        display_items: [...action.payload.broadcast_list_display_items],
      };
    case types.DELETE_BROADCAST_DISPLAY_ITEM_SUCCESS:
      const deletedIds = action.payload.ids;
      return {
        ...state,
        display_items: state.display_items.filter((item) => !deletedIds.includes(item.id)),
      };
    case types.CREATE_BROADCAST_SUCCESS:
      return {
        ...state,
        broadcast: action.payload.broadcast_list,
        broadcasts: [...state.broadcasts, action.payload.broadcast_list],
        broadcastsById: {
          ...state.broadcastsById,
          [action.payload.broadcast_list.id]: action.payload.broadcast_list,
        },
      };
    case types.CHANGE_CURRENT_BROADCAST:
      return {
        ...state,
        broadcast: state.broadcastsById[action.id],
      };
    case types.FLUSH_BROADCAST_ALL_STATE:
      return initialState;
    default:
      return state;
  }
}
