export const getFieldsConfig = (isDevicesType, isOnlineType, segmentationImports, prizeSegmentation) => {
  const showBoth = isDevicesType && isOnlineType && prizeSegmentation && segmentationImports;

  return {
    device: {
      show: showBoth,
      labelKey: showBoth ? 'DEVICE_CODE_LIST' : 'CODE_LIST',
    },
    online: {
      show: showBoth,
      labelKey: showBoth ? 'ONLINE_CODE_LIST' : 'CODE_LIST',
    },
    common: {
      show: !showBoth,
      labelKey: 'CODE_LIST',
    },
  };
};
