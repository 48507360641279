import React, {Component} from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector, getFormValues} from 'redux-form';

import {required} from 'client/services/validator';

import {PARTICIPANTS_COLUMN_MAP} from 'client/ducks/participants-list/constants';
import {createParticipationsExportFile, getParticipationsExportFile} from 'client/ducks/participation-export/actions';

import {LEAD_IMPORT_EXPORT_FILE_TYPES, PARTICIPATION_EXPORT_TYPES} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Modal from 'client/common/modals/modal';

import AllowedUsersField from 'client/components/common/custom-fields/allowed-users-field';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';
import Rollout from 'client/components/common/rollout';

const sortColumns = (data) => {
  if (Object.keys(data).length) {
    const columns = {
      ...data,
      Participation: sortBy(data.Participation, [
        function (item) {
          return item.name.toLowerCase();
        },
      ]),
      ScenarioExecution: sortBy(data.ScenarioExecution, [
        function (item) {
          return item.name.toLowerCase();
        },
      ]),
    };
    return columns;
  }
  return data;
};

class ParticipantsGenerateExportModal extends Component {
  static defaultProps = {
    show: false,
    columnsGroup: {},
    columns: [],
    users: [],
    formValues: {},
    queryParams: {},
  };

  static propTypes = {
    show: PropTypes.bool,
    isAll: PropTypes.bool,
    lang: PropTypes.object.isRequired,
    columnsGroup: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    change: PropTypes.func.isRequired,
    createParticipationsExportFile: PropTypes.func.isRequired,
    getParticipationsExportFile: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    clientId: PropTypes.string.isRequired,
    checkedParticipants: PropTypes.array.isRequired,
    generalParticipationColumns: PropTypes.object.isRequired,
    taskId: PropTypes.string.isRequired,
    operationId: PropTypes.string.isRequired,
    queryParams: PropTypes.object,
    users: PropTypes.array,
    formValues: PropTypes.object,
  };

  static formName = 'LeadsGenerateExportModalForm';

  static checkboxAllColumns = 'checkboxAllColumns';

  static columnsGroup = 'columnsGroup';

  rules = {
    name: [required(this.props.lang.REQUIRED)],
  };

  constructor(props) {
    super(props);
    const generalParticipationColumns = cloneDeep(this.props.generalParticipationColumns);

    const initValues = {
      columnsGroup: {},
      format: LEAD_IMPORT_EXPORT_FILE_TYPES.XLS,
      users: [],
      allowed_for_all: 'true',
    };
    this.props.initialize(initValues);

    this.state = {
      showWarning: true,
      isColumnsFieldsTouched: false,
      generalParticipationColumns: sortColumns(generalParticipationColumns),
      allColumnsChecked: false,
      showInProgressMessage: false,
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {formValues, columnsGroup, change} = this.props;

    if (columnsGroup !== prevProps.columnsGroup) {
      this.validateFormSection();
    }

    if (
      formValues.allowed_for_all === 'true' &&
      !isEmpty(formValues.users) &&
      formValues.users !== prevProps.formValues.users
    ) {
      change('users', []);
    }
  }

  detectChange = (obj1, obj2) => {
    return !isEqual(obj1, obj2);
  };

  validateFormSection = () => {
    const validate = this.getCheckedColumns().length;
    this.setState({showWarning: !validate});
  };

  getCheckedColumns = () => {
    return [].concat(...Object.values(this.state.generalParticipationColumns)).filter((column) => {
      return typeof column === 'object' && column.checked;
    });
  };

  toggleAllColumns = () => {
    const mappedGeneralParticipationColumns = this.state.generalParticipationColumns;
    const allColumnsChecked = this.state.allColumnsChecked;
    Object.keys(mappedGeneralParticipationColumns).forEach((groupName) => {
      mappedGeneralParticipationColumns[groupName].checked = !allColumnsChecked;
      mappedGeneralParticipationColumns[groupName].forEach((item) => {
        if (typeof item === 'object') {
          item.checked = !allColumnsChecked;
        }
      });
    });
    this.setState({
      allColumnsChecked: !allColumnsChecked,
      generalParticipationColumns: mappedGeneralParticipationColumns,
      isColumnsFieldsTouched: true,
    });
    this.validateFormSection();
  };

  handleColumnCheck = (columnGroupName, item, index) => {
    const mappedGeneralParticipationColumns = this.state.generalParticipationColumns;
    mappedGeneralParticipationColumns[columnGroupName][index].checked =
      !mappedGeneralParticipationColumns[columnGroupName][index].checked;
    this.setState({
      generalParticipationColumns: mappedGeneralParticipationColumns,
      isColumnsFieldsTouched: true,
    });
    this.validateFormSection();
  };

  toggleGroupColumns = (columnGroupName) => {
    const mappedGeneralParticipationColumns = this.state.generalParticipationColumns;
    const isGroupChecked = !!mappedGeneralParticipationColumns[columnGroupName].checked;
    mappedGeneralParticipationColumns[columnGroupName].forEach((item) => {
      if (typeof item === 'object') {
        item.checked = !isGroupChecked;
      }
    });
    mappedGeneralParticipationColumns[columnGroupName].checked = !isGroupChecked;
    this.setState({
      generalParticipationColumns: mappedGeneralParticipationColumns,
      isColumnsFieldsTouched: true,
    });
    this.validateFormSection();
  };

  onSave = (values) => {
    if (this.state.showWarning) {
      this.setState({isColumnsFieldsTouched: true});
      return;
    }

    if (!this.props.checkedParticipants.length && !this.props.isAll) {
      return;
    }

    this.setState({isLoading: true});

    const transferItems = this.getCheckedColumns().map((item) => ({
      type: PARTICIPATION_EXPORT_TYPES.PARTICIPATION_EXPORT_ITEM,
      column_adapter_id: item.id,
    }));

    const importData = {
      file_participation_export: {
        name: values.name,
        comment: values.comment || '',
        automation_task_id: this.props.taskId,
        format: values.format,
        participation_export_items: transferItems,
        allowed_for_all: values.allowed_for_all === 'true',
        transferable_memberships: values.users?.map(({value}) => ({
          membership_id: value,
        })),
        parameters_for_export: this.props.isAll ? this.props.queryParams : {},
      },
    };

    if (!this.props.isAll) {
      importData.file_participation_export.exported_participations = this.props.checkedParticipants.map(({id}) => ({
        participation_id: id,
      }));
    }

    this.props
      .createParticipationsExportFile(importData)
      .then((res) => {
        return this.props.getParticipationsExportFile(res.payload.file_participation_export.id);
      })
      .finally(() => {
        this.setState({isLoading: false, showInProgressMessage: true});
      });
  };

  getColumnName = ({record_type, name}) => {
    const columnName = `${record_type} ${name}`;
    return PARTICIPANTS_COLUMN_MAP[columnName] || columnName;
  };

  renderAvailableColumns = () => {
    return Object.entries(this.state.generalParticipationColumns).map((entry, idx) => {
      const columnGroupName = entry[0];
      const columnGroupItems = entry[1];
      return columnGroupName === 'checked' ? null : (
        <Rollout
          key={idx}
          title={
            <label className="checkbox-button theme-color-9">
              <input
                className="checkbox-button__input"
                type="checkbox"
                name="checkbox-general"
                checked={!!columnGroupItems.checked}
                onChange={() => {
                  this.toggleGroupColumns(columnGroupName);
                }}
              />
              <div className="checkbox-button__label">
                <span className="icon-checked checkbox-button__icon" />
                <span className="checkbox-button__text">
                  <span className="main-text main-text--bold">{columnGroupName}</span>
                </span>
              </div>
            </label>
          }
          bodyStyles={{
            marginRight: '13px',
          }}
          className="rollout--expand-1"
          onTransition={this.handleTransition}
          isOpened={false}
        >
          <CustomScrollbars
            scrollbarProps={{
              hideTracksWhenNotNeeded: true,
              autoHeightMax: 191,
              autoHeightMin: 191,
            }}
          >
            <div className="list list--view-3">
              <div className="list__group">
                {columnGroupItems.map((item, index) => (
                  <div className={`list__item ${item.hidden ? 'hidden' : ''}`} key={index}>
                    <label className="checkbox-button checkbox-button--view-1 checkbox-button">
                      <input
                        type="checkbox"
                        data-name={item.name}
                        className="checkbox-button__input"
                        checked={!!item.checked}
                        onChange={() => {
                          this.handleColumnCheck(columnGroupName, item, index);
                        }}
                      />
                      <div className="checkbox-button__label">
                        <span className="icon-checked checkbox-button__icon" />
                        <span className="checkbox-button__text">{this.getColumnName(item)}</span>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </CustomScrollbars>
        </Rollout>
      );
    });
  };

  closeInProgressMessage = () => {
    this.setState({showInProgressMessage: false});
    this.props.onClose();
  };

  render() {
    const {handleSubmit, onCancel, onClose, show, checkedParticipants, isAll, users, formValues, lang} = this.props;
    const {showInProgressMessage, isLoading} = this.state;

    if (showInProgressMessage) {
      return (
        <ConfirmationModal
          show={true}
          message={lang.EXPORT_IN_PROGRESS}
          buttonConfirmClass="button--bg-4"
          className="theme-color-9"
          confirmText="ok"
          onClose={this.closeInProgressMessage}
          onConfirm={this.closeInProgressMessage}
        />
      );
    }

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-9 leads-port-layout"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TITLE}
      >
        <form onSubmit={handleSubmit(this.onSave)}>
          <FormFieldWrap>
            <Field
              type="text"
              name="name"
              label={lang.FILE_NAME_LABEL + '*'}
              component={fieldTemplate}
              validate={this.rules.name}
            />
          </FormFieldWrap>
          <div className="main-text main-text--small main-text--color-1">{lang.COLUMNS_LABEL + '*'}</div>
          <div className="content-group">
            <CustomScrollbars
              scrollbarProps={{
                autoHeightMin: 200,
                autoHeightMax: 360,
                hideTracksWhenNotNeeded: true,
              }}
            >
              <div style={{width: 337}}>
                <div className="content-group__header">
                  <label className="checkbox-button">
                    <input
                      className="checkbox-button__input"
                      type="checkbox"
                      name="checkbox-all"
                      value="0"
                      checked={this.state.allColumnsChecked}
                      onChange={this.toggleAllColumns}
                    />
                    <div className="checkbox-button__label">
                      <span className="icon-checked checkbox-button__icon" />
                      <span className="checkbox-button__text">
                        <span className="main-text main-text--bold">{lang.ALL_COLUMNS}</span>
                      </span>
                    </div>
                  </label>
                </div>
                {this.renderAvailableColumns()}
              </div>
            </CustomScrollbars>
          </div>
          {this.state.showWarning && this.state.isColumnsFieldsTouched && (
            <div className="message-error-text">{lang.NO_MATCH_ERROR_MESSAGE}</div>
          )}
          {!checkedParticipants.length && !isAll && <div className="message-error-text">{lang.NO_LEADS_SELECTED}</div>}
          <div className="leads-port-layout__row">
            <div className="leads-port-layout__row-title">{lang.FORMAT_LABEL}</div>
            <div className="flex-container">
              <div className="leads-port-layout__col-1">
                <Field
                  label={<span className="main-text">{lang.XLS}</span>}
                  name="format"
                  value={LEAD_IMPORT_EXPORT_FILE_TYPES.XLS}
                  type="radio"
                  component={fieldTemplate}
                />
              </div>
              <div className="leads-port-layout__col-1">
                <Field
                  label={<span className="main-text">{lang.CSV}</span>}
                  name="format"
                  value={LEAD_IMPORT_EXPORT_FILE_TYPES.CSV}
                  type="radio"
                  component={fieldTemplate}
                />
              </div>
            </div>
          </div>
          <div className="leads-port-layout__row">
            <div className="leads-port-layout__row-title">{lang.ALLOWED_USERS_LABEL}</div>
            <div className="flex-container margin-bottom--5">
              <div className="leads-port-layout__col-1">
                <Field
                  label={lang.ALLOW_ALL_LABEL}
                  name="allowed_for_all"
                  value="true"
                  type="radio"
                  component={fieldTemplate}
                />
              </div>
              <div className="leads-port-layout__col-1">
                <Field
                  label={lang.ALLOW_SELECTED_LABEL}
                  name="allowed_for_all"
                  value="false"
                  type="radio"
                  component={fieldTemplate}
                />
              </div>
            </div>
            <AllowedUsersField
              label={lang.USER_ALLOWED_LABEL}
              users={users}
              disabled={formValues.allowed_for_all === 'true'}
            />
          </div>
          <div className="leads-port-layout__row">
            <Field
              cssModifier="leads-port-layout__textarea"
              name="comment"
              type="textarea"
              label={lang.COMMENT_LABEL}
              component={fieldTemplate}
            />
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button
              className="button button--bg-11 modal-window__footer-btn"
              type="button"
              onClick={onCancel || onClose}
            >
              {lang.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-4 modal-window__footer-btn" type="submit" disabled={isLoading}>
              {lang.GENERATE_FILE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const ParticipantsGenerateExportModalForm = reduxForm({
  form: ParticipantsGenerateExportModal.formName,
})(ParticipantsGenerateExportModal);

export default connect(
  (state) => {
    const selector = formValueSelector(ParticipantsGenerateExportModal.formName);
    return {
      lang: state.languageState.payload.PARTICIPANTS_PORT.GENERATE_EXPORT_FILE_MODAL,
      columnsGroup: selector(state, ParticipantsGenerateExportModal.columnsGroup),
      formValues: getFormValues(ParticipantsGenerateExportModal.formName)(state),
    };
  },
  {
    createParticipationsExportFile,
    getParticipationsExportFile,
  },
)(ParticipantsGenerateExportModalForm);
