import React from 'react';

import {useToggle} from 'react-use';

import {useLanguage, useReduxFetch} from 'client/services/hooks';

import {getOptinColumns} from 'client/ducks/leads-parameters/actions';
import {selectOptinColumns} from 'client/ducks/leads-parameters/selectors';

import LeadsParamsColumn from 'client/components/leads/leads-parameters/leads-params-column';
import LeadOptinModal from 'client/components/leads/modals/lead-optin-modal';
import {Client} from 'client/models/client/types';
import {OptInColumn} from 'client/models/opt-in-column/types';

import OptinItem from './optin-item';

type LeadsOptinsProps = {
  className?: string;
  client: Client;
  canManage: boolean;
};

const LeadsOptins: React.FC<LeadsOptinsProps> = (props) => {
  const {client, className, canManage} = props;
  const lang = useLanguage('LEADS_PARAMETERS.LEADS_OPTINS');
  const [showOptinModal, toggleOptinModal] = useToggle(false);

  const {
    data = [],
    fetch,
    loading,
  } = useReduxFetch<OptInColumn[]>({
    action: getOptinColumns,
    selector: selectOptinColumns,
    actionArgs: client.id,
  });

  return (
    <>
      {showOptinModal && <LeadOptinModal onClose={toggleOptinModal} onSave={fetch} clientId={client.id} />}
      <LeadsParamsColumn
        className={className}
        label={lang.OPTINS}
        count={data.length}
        loading={loading}
        empty={!data.length}
        buttonTitle={lang.ADD_OPTINS}
        onButtonClick={canManage ? toggleOptinModal : null}
        emptyText={lang.NO_OPTINS}
      >
        {data.map((item) => (
          <OptinItem key={item.id} item={item} canManage={canManage} fetchData={fetch} />
        ))}
      </LeadsParamsColumn>
    </>
  );
};

export default LeadsOptins;
