import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {connect} from 'react-redux';

import {getApiLeadExports} from 'client/ducks/leads-export/actions';
import {selectApiLeadExports} from 'client/ducks/leads-export/selectors';

import {API_PATH} from 'client/common/config';

import ClientTable from 'client/components/common/client-table';
import Icon from 'client/components/common/icon';

function getAPIUrl(id) {
  return `${API_PATH}/api_lead_exports/${id}/perform`;
}

class ApiAccessTable extends Component {
  static propTypes = {
    language: PropTypes.object,
    clientId: PropTypes.number,
    apiHistoryExports: PropTypes.array,
    getApiLeadExports: PropTypes.func.isRequired,
  };

  static defaultProps = {
    apiHistoryExports: [],
  };

  state = {
    loading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
    const {language} = props;
    this.apiHistoryExportsColumns = [
      {
        name: 'code',
        label: 'Code',
        path: 'format',
        render: () => <span>EXAPI</span>,
      },
      {
        name: 'finish',
        label: language.END_DATE,
        path: 'to',
      },
      {
        name: 'settings',
        label: language.SETTINGS,
        hintRender: this.renderSettingsHint,
        render: this.renderSettings,
        align: 'center',
      },
      {
        name: 'comments',
        label: language.COMMENTS,
        hintRender: this.renderCommentHint,
        render: this.renderComment,
        align: 'center',
      },
    ];
  }

  componentDidMount() {
    this.fetchData();
  }

  toggleLoading = () => this.setState(({loading}) => ({loading: !loading}));

  fetchData = () => {
    const params = {
      include: {
        memberships: {
          client_user: null,
        },
        transferable_memberships: null,
      },
      q: {
        s: 'id desc',
        client_id_eq: this.props.clientId,
      },
    };
    this.toggleLoading();
    this.props.getApiLeadExports(params).then(this.toggleLoading);
  };

  renderSettingsHint = ({id, from, to, allowed_for_all, memberships}) => {
    const API_URL = getAPIUrl(id);
    const {language} = this.props;
    return (
      <div className="settings-popover">
        <h4 className="settings-popover__value leads-parameters__icon-leads">{language.SETTINGS}</h4>
        <div className="participants-api-settings-popover__row-container settings-popover__row">
          <span>{language.COPY_ID}</span>
          <span className="settings-popover__value">{id}</span>
          <CopyToClipboard text={id} className="settings-popover__copy-button">
            <Icon name="copy" />
          </CopyToClipboard>
        </div>
        <div className="participants-api-settings-popover__row-container settings-popover__row">
          <span>{language.COPY_ENDPOINT}</span>
          <CopyToClipboard text={API_URL} className="settings-popover__copy-button">
            <Icon name="copy" />
          </CopyToClipboard>
        </div>
        <div className="participants-api-settings-popover__row-container settings-popover__row">
          <span className="participants-api-settings-popover__link-api bold" style={{width: '90%'}}>
            {API_URL}
          </span>
        </div>
        <div className="participants-api-settings-popover__row-container settings-popover__row">
          <span>{language.ACCESS_PERIOD}</span>
          <span className="settings-popover__value">{`${from} - ${to}`}</span>
        </div>
        <div className="participants-api-settings-popover__row-container settings-popover__row">
          <span className="">{language.USERS_ALLOWED}</span>
          <span className="settings-popover__value">
            {allowed_for_all
              ? language.ALL
              : memberships.map((item) => <div key={item.client_user?.id}>{item.client_user.full_name}</div>)}
          </span>
        </div>
      </div>
    );
  };

  renderSettings = () => <Icon name="settings" className="leads-parameters__table-icon" />;

  renderCommentHint = ({comment}) => (
    <div>
      <div className="bold leads-parameters__icon-leads">{this.props.language.COMMENTS}</div>
      <p>{comment}</p>
    </div>
  );

  renderComment = ({item}) => {
    return item.comment ? <Icon name="comments" className="leads-parameters__table-icon" /> : null;
  };

  render() {
    const {language, apiHistoryExports} = this.props;
    const {apiHistoryExportsColumns} = this;
    const {loading} = this.state;
    return (
      <div className="leads-parameters">
        <ClientTable
          className="leads-parameters__export-table"
          columns={apiHistoryExportsColumns}
          data={apiHistoryExports}
          noDataText={language.NO_API_DATA}
          classNames={{
            icon: 'leads-parameters__icon-leads',
          }}
          loading={loading}
        />
      </div>
    );
  }
}

export default connect(
  ({languageState, ...state}) => ({
    language: languageState.payload.DATA_TAB.EXPORT_API_MODAL,
    apiHistoryExports: selectApiLeadExports(state),
  }),
  {
    getApiLeadExports,
  },
)(ApiAccessTable);
