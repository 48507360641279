import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect, withRouter} from 'react-router-dom';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {selectIsAdmin, selectIsUserLoaded} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import Autotask from 'client/components/autotask';

class AutotaskPage extends Component {
  static propTypes = {
    ...withRouter.propTypes,
    setMetaAction: PropTypes.func.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    autotask: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const {autotask, setMetaAction} = this.props;

    if (autotask !== prevProps.autotask) {
      setMetaAction({
        title: autotask.name,
      });
    }
  }

  render() {
    const {
      match: {
        params: {id, clientId, operationId},
      },
      history,
      isAdmin,
      isUserLoaded,
    } = this.props;

    if (!isUserLoaded) {
      return null;
    }

    if (isUserLoaded && !isAdmin) {
      return <Redirect to={CLIENT_PAGES.HOME} />;
    }

    return <Autotask id={+id} companiesId={+clientId} operationId={+operationId} history={history} isAdmin={isAdmin} />;
  }
}

const mapStateToProps = (state) => ({
  autotask: selectAutotask(state),
  isAdmin: selectIsAdmin(state),
  isUserLoaded: selectIsUserLoaded(state),
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutotaskPage);
