import React, {useRef} from 'react';

import {ColorState} from 'react-color';
import {ReactEditor} from 'slate-react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {getItem, setItem} from 'client/services/sessionStorage';

import ColorPickerSimple from 'client/common/inputs/color-picker-simple';
import ToolbarButton from 'client/common/text-editor/buttons/toolbar-button';
import {CustomEditor} from 'client/common/text-editor/constants';
import {editorMethods} from 'client/common/text-editor/helpers';

import cssModule from './color-button.module.scss';

const b = bem('color-button', {cssModule});

const DEFAULT_TEXT_COLOR = '#404F6B';

type ColorButtonProps = {
  editor: CustomEditor;
  colorsAccessKey: string;
  className?: string;
};

const ColorButton = (props: ColorButtonProps) => {
  const {editor, colorsAccessKey, className} = props;

  const lang = useLanguage('COMMON.TEXT_EDITOR.LABELS');
  const colorPickerVisibilityRef = useRef(false);

  const handleColorChange = ({hex}: ColorState) => {
    editorMethods.applyProperty(editor, 'color', hex);

    const colors = getItem(colorsAccessKey) || [];
    if (!colors.includes(hex)) {
      colors.push(hex);
      setItem(colorsAccessKey, colors.slice(-5));
    }
  };

  return (
    <ColorPickerSimple
      value={editorMethods.getPropertyValue(editor, 'color') || DEFAULT_TEXT_COLOR}
      onChange={handleColorChange}
      colorSwatches={getItem(colorsAccessKey)}
      position="bottom"
    >
      {(isPickerOpen: boolean) => {
        if (!isPickerOpen && colorPickerVisibilityRef.current) {
          ReactEditor.focus(editor); // focus editor on picker close
        }
        colorPickerVisibilityRef.current = isPickerOpen;

        return (
          <ToolbarButton
            iconName="text-color"
            label={lang.TEXT_COLOR}
            className={className}
            iconProps={{
              style: {color: editorMethods.getPropertyValue(editor, 'color') || DEFAULT_TEXT_COLOR},
              className: b('icon'),
            }}
            active={isPickerOpen}
          />
        );
      }}
    </ColorPickerSimple>
  );
};

export default ColorButton;
