import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

export function mapInterfaceLevels(levels) {
  const result = [];

  levels.forEach((level) => {
    const scenarios = level.scenarios || [];
    const relations = level.interface_level_scenarios || [];

    result.push({
      ...level,
      scenarios: scenarios.map((scenario) => {
        return {
          ...scenario,
          relation_id: find(relations, {scenario_id: scenario.id}).id,
        };
      }),
    });
  });

  return sortBy(result, 'created_at');
}
