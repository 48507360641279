import {SCENARIO_STEP_TYPES} from 'client/common/config';

export const SOCIAL_ACCOUNT_IMPLEMENTATION_TYPE_MAPPING = {
  [SCENARIO_STEP_TYPES.social.facebook]: 'FacebookAccount',
  [SCENARIO_STEP_TYPES.social.twitter]: 'TwitterAccount',
};

export const SOCIAL_IMAGES_TYPE_MAPPING = {
  file: 'FileSocialScenarioStepImage',
  variable: 'VariableSocialScenarioStepImage',
};

export const SOCIAL_IMAGES_QUANTITY_LIMIT = {
  [SCENARIO_STEP_TYPES.social.facebook]: 10,
  [SCENARIO_STEP_TYPES.social.twitter]: 4,
};

export const SOCIAL_MESSAGE_LENGTH_LIMIT = {
  [SCENARIO_STEP_TYPES.social.facebook]: 1000,
  [SCENARIO_STEP_TYPES.social.twitter]: 280,
};
