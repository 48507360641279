export default {
  GET_CUSTOM_OPERATION_DATA_REQUEST: '@@custom-op-db/GET_CUSTOM_OPERATION_DATA_REQUEST',
  GET_CUSTOM_OPERATION_DATA_SUCCESS: '@@custom-op-db/GET_CUSTOM_OPERATION_DATA_SUCCESS',
  GET_CUSTOM_OPERATION_DATA_ERROR: '@@custom-op-db/GET_CUSTOM_OPERATION_DATA_ERROR',

  GET_COLUMNS_REQUEST: '@@custom-op-db/GET_COLUMNS_REQUEST',
  GET_COLUMNS_SUCCESS: '@@custom-op-db/GET_COLUMNS_SUCCESS',
  GET_COLUMNS_ERROR: '@@custom-op-db/GET_COLUMNS_ERROR',

  GET_SYSTEM_COLUMNS_REQUEST: '@@custom-op-db/GET_SYSTEM_COLUMNS_REQUEST',
  GET_SYSTEM_COLUMNS_SUCCESS: '@@custom-op-db/GET_SYSTEM_COLUMNS_SUCCESS',
  GET_SYSTEM_COLUMNS_ERROR: '@@custom-op-db/GET_SYSTEM_COLUMNS_ERROR',

  GET_VISIBLE_COLUMNS_REQUEST: '@@custom-op-db/GET_VISIBLE_COLUMNS_REQUEST',
  GET_VISIBLE_COLUMNS_SUCCESS: '@@custom-op-db/GET_VISIBLE_COLUMNS_SUCCESS',
  GET_VISIBLE_COLUMNS_ERROR: '@@custom-op-db/GET_VISIBLE_COLUMNS_ERROR',

  GET_OPERATION_DATA_REQUEST: '@@custom-op-db/GET_OPERATION_DATA_REQUEST',
  GET_OPERATION_DATA_SUCCESS: '@@custom-op-db/GET_OPERATION_DATA_SUCCESS',
  GET_OPERATION_DATA_ERROR: '@@custom-op-db/GET_OPERATION_DATA_ERROR',

  PATCH_OPERATION_DATUM_COLUMN_REQUEST: '@@custom-op-db/PATCH_OPERATION_DATUM_COLUMN_REQUEST',
  PATCH_OPERATION_DATUM_COLUMN_SUCCESS: '@@custom-op-db/PATCH_OPERATION_DATUM_COLUMN_SUCCESS',
  PATCH_OPERATION_DATUM_COLUMN_ERROR: '@@custom-op-db/PATCH_OPERATION_DATUM_COLUMN_ERROR',

  DELETE_OPERATION_DATUM_COLUMN_REQUEST: '@@custom-op-db/DELETE_OPERATION_DATUM_COLUMN_REQUEST',
  DELETE_OPERATION_DATUM_COLUMN_SUCCESS: '@@custom-op-db/DELETE_OPERATION_DATUM_COLUMN_SUCCESS',
  DELETE_OPERATION_DATUM_COLUMN_ERROR: '@@custom-op-db/DELETE_OPERATION_DATUM_COLUMN_ERROR',

  POST_OPERATION_DATUM_COLUMN_REQUEST: '@@custom-op-db/POST_OPERATION_DATUM_COLUMN_REQUEST',
  POST_OPERATION_DATUM_COLUMN_SUCCESS: '@@custom-op-db/POST_OPERATION_DATUM_COLUMN_SUCCESS',
  POST_OPERATION_DATUM_COLUMN_ERROR: '@@custom-op-db/POST_OPERATION_DATUM_COLUMN_ERROR',
};
