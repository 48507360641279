import React, {useState} from 'react';

import {capitalize} from 'lodash';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectCurrentLanguage} from 'client/ducks/language/selectors';
import {selectClientUserMembership} from 'client/ducks/user-clients/selectors';

import AppButton from 'client/common/buttons';
import Popover from 'client/common/popovers/popover';

import Icon from 'client/components/common/icon';

import {Client, ClientAccount} from 'client/models/client/types';

import UpdateAgencyContactModal from './update-agency-contact-modal';

import AccountCard from '../account-card';

import cssModule from './account-contact.module.scss';

const b = bem('account-contact', {cssModule});

type AccountContactProps = {
  clientAccount: ClientAccount | null;
  client: Client;
  onContactUpdate: () => void;
  loading: boolean;
};

const AccountContact: React.FC<AccountContactProps> = (props) => {
  const {loading, clientAccount, client, onContactUpdate} = props;
  const lang = useLanguage('ACCOUNT_PARAMETERS.CONTACT');
  const currentLanguage = useSelector(selectCurrentLanguage);
  const clientMembership = useSelector(selectClientUserMembership);

  const [showUpdateAgencyUserModal, setShowUpdateAgencyUserModal] = useState(false);

  if (loading) {
    return <AccountCard className={b()} loading={loading} />;
  }

  let name = '';
  let organization = '';
  let email = '';
  let phone = '';
  let avatarUrl = '';

  if (client.poc_agency_membership) {
    const poc = client.poc_agency_membership;
    name = poc?.[`client_user_full_name_with_title_${currentLanguage}`];
    organization = clientAccount?.contact?.organization || '';
    email = clientAccount?.contact?.email || '';
    phone = clientAccount?.contact?.phone || '';
    avatarUrl = clientAccount?.contact?.avatar?.url || '';
  } else if (clientAccount?.admin_contact?.name) {
    name = clientAccount?.admin_contact?.name;
    organization = clientAccount?.admin_contact?.qualification?.split('_').map(capitalize).join(' ') || '';
    avatarUrl = clientAccount?.admin_contact?.avatar?.url || '';
  } else {
    name = `${client.subsidiary?.first_name || ''} ${client.subsidiary?.last_name || ''}`.trim();
    organization = client.subsidiary?.name || '';
    email = client.subsidiary?.email || '';
    phone = client.subsidiary?.phone || '';
    avatarUrl = client.subsidiary?.logo?.url || '';
  }

  const isAgency = client.agency_id && clientMembership.client.client_management;
  const hasContactInfo = !!(name || organization || email || phone);

  return (
    <AccountCard className={b()} title={lang.TITLE}>
      <div className={b('content')}>
        {avatarUrl ? (
          <img src={avatarUrl} className={b('avatar')} alt="" />
        ) : (
          <Icon name="avatar-placeholder-small" className={b('avatar')} width={80} height={80} />
        )}
        <div className={b('info')}>
          {hasContactInfo ? (
            <>
              <p className={b('info-item')}>{name}</p>
              <p className={b('info-item')}>{organization}</p>
              <p className={b('info-item')}>{email}</p>
              <p className={b('info-item')}>{phone}</p>
            </>
          ) : (
            <p className={b('info-item')}>{lang.NO_CONTACT_INFO}</p>
          )}
        </div>
      </div>

      {isAgency && (
        <>
          <Popover overlay={lang.UPDATE_AGENCY_CONTACT_HINT} triggerClassName={b('edit-client')}>
            <AppButton
              className={b('edit-client-button')}
              iconName="edit"
              asWrap={true}
              onClick={() => setShowUpdateAgencyUserModal(true)}
            />
          </Popover>
          {showUpdateAgencyUserModal && (
            <UpdateAgencyContactModal
              client={client}
              onClose={() => setShowUpdateAgencyUserModal(false)}
              onUpdate={onContactUpdate}
            />
          )}
        </>
      )}
    </AccountCard>
  );
};

export default AccountContact;
