import React, {useState, useCallback, useEffect} from 'react';

import get from 'lodash/get';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import bem from 'client/services/bem';
import {dateToString} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import {setHeaderLinkBack, clearHeaderLinkBack} from 'client/ducks/header-link-back/actions';
import {selectClientParams} from 'client/ducks/operations/selectors';

import LayoutPanel from 'client/common/panels/layout-panel';

import CustomLink from 'client/components/common/custom-link';
import Icon from 'client/components/common/icon';

import ClientAutotaskMenu from 'client/components/client-autotask/client-autotask-menu';

import ClientAutotaskSubheaderOperationInfoBlock from './blocks/client-autotask-subheader-operation-info-block';
import {ClientAutotaskSubheaderProps} from './types';

import cssModule from './client-autotask-subheader.module.scss';

const b = bem('client-autotask-subheader', {cssModule});

const ClientAutotaskSubheader: React.FC<ClientAutotaskSubheaderProps> = (props) => {
  const {autotask, operation, activeTab, accessLevel} = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const [scrolled, setScrolled] = useState(false);

  const lang = useLanguage('CLIENT_AUTOTASK_SUBHEADER');

  const clientParams = useSelector(selectClientParams);

  const handleScroll = useCallback(() => {
    const offset = window.scrollY;

    if (offset > 1 && !scrolled) {
      setScrolled(true);
    } else if (offset === 0 && scrolled) {
      setScrolled(false);
    }
  }, [scrolled]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    dispatch(
      setHeaderLinkBack({
        name: autotask.name,
        rootPage: false,
      }),
    );

    return () => {
      window.removeEventListener('scroll', handleScroll);
      dispatch(clearHeaderLinkBack());
    };
  }, [dispatch, handleScroll, autotask.name]);

  const handleBackClick = () => {
    const link = CustomLink.createClientOperationsLink({
      clientId: clientParams?.clientId || operation.client_id,
    });
    history.push(link + clientParams?.qs || '');
  };

  const imageUrl = get(autotask, 'image.url');

  return (
    <header className={b({scrolled})}>
      <div className={b('content')}>
        <div className={b('autotask')}>
          <figure className={b('image')}>
            {imageUrl ? (
              <img className={b('image-pic')} src={imageUrl} alt={autotask.name} />
            ) : (
              <Icon className={b('image-pic')} name="task-image-placeholder" />
            )}
          </figure>
          <div className={b('autotask-name')} title={autotask.name}>
            <Icon name="arrow-left" className={b('back')} onClick={handleBackClick} />
            {autotask.name}
          </div>
          <ClientAutotaskMenu
            autotask={autotask}
            operation={operation}
            activeTab={activeTab}
            accessLevel={accessLevel}
            className={b('menu')}
          />
        </div>
        <LayoutPanel className={b('operation')}>
          <ClientAutotaskSubheaderOperationInfoBlock label={lang.OPERATION.toString()} value={operation.name} />
          <ClientAutotaskSubheaderOperationInfoBlock
            label={lang.PERIOD.toString()}
            value={`${dateToString(operation.from)} - ${dateToString(operation.to)}`}
          />
        </LayoutPanel>
      </div>
    </header>
  );
};

export default ClientAutotaskSubheader;
