import {Reducer} from 'redux';

import types from './types';

export const DEFAULT_PASSWORD_EXPIRATION = 180;

export type UserPasswordExpirationConfig = {
  full: number;
  regions: number;
  places: number;
  local: number;
  id?: number;
};

const initialState: UserPasswordExpirationConfig = {
  full: DEFAULT_PASSWORD_EXPIRATION,
  regions: DEFAULT_PASSWORD_EXPIRATION,
  places: DEFAULT_PASSWORD_EXPIRATION,
  local: DEFAULT_PASSWORD_EXPIRATION,
};

const userPasswordExpirationReducer: Reducer<UserPasswordExpirationConfig> = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PASSWORD_EXPIRATION_SUCCESS:
      return {
        ...initialState,
        ...action.payload.user_password_expiration_configs[0],
      };
    case types.SET_PASSWORD_EXPIRATION_SUCCESS:
      return {
        ...state,
        ...action.payload.user_password_expiration_config,
      };
    default:
      return state;
  }
};

export default userPasswordExpirationReducer;
