export default {
  CLEAR_AUTOTASK_STATE: '@@autotask/CLEAR_AUTOTASK_STATE',

  NO_DATA: '@@autotask/NO_DATA',
  LOADING: '@@autotask/LOADING',
  LOADED: '@@autotask/LOADED',
  LOAD_FAILED: '@@autotask/LOAD_FAILED',

  SCENARIO_UPDATING: '@@autotask/SCENARIO_UPDATING',
  SCENARIO_UPDATED: '@@autotask/SCENARIO_UPDATED',
  SCENARIO_UPDATE_FAILED: '@@autotask/SCENARIO_UPDATE_FAILED',

  GET_AUTOTASKS_REQUEST: '@@autotask/GET_AUTOTASKS_REQUEST',
  GET_AUTOTASKS_SUCCESS: '@@autotask/GET_AUTOTASKS_SUCCESS',
  GET_AUTOTASKS_ERROR: '@@autotask/GET_AUTOTASKS_ERROR',

  SCENARIO_CREATING: '@@autotask/SCENARIO_CREATING',
  SCENARIO_CREATED: '@@autotask/SCENARIO_CREATED',
  SCENARIO_CREATE_FAILED: '@@autotask/SCENARIO_CREATE_FAILED',

  PATCH_AUTOMATION_TASKS_REQUEST: '@@autotask/PATCH_AUTOMATION_TASKS_REQUEST',
  PATCH_AUTOMATION_TASKS_SUCCESS: '@@autotask/PATCH_AUTOMATION_TASKS_SUCCESS',
  PATCH_AUTOMATION_TASKS_ERROR: '@@autotask/PATCH_AUTOMATION_TASKS_ERROR',

  DELETE_AUTOMATION_TASKS_REQUEST: '@@autotask/DELETE_AUTOMATION_TASKS_REQUEST',
  DELETE_AUTOMATION_TASKS_SUCCESS: '@@autotask/DELETE_AUTOMATION_TASKS_SUCCESS',
  DELETE_AUTOMATION_TASKS_ERROR: '@@autotask/DELETE_AUTOMATION_TASKS_ERROR',

  CHANGE_AUTOMATION_TASK_HOME_REQUEST: '@@autotask/CHANGE_AUTOMATION_TASK_HOME_REQUEST',
  CHANGE_AUTOMATION_TASK_HOME_SUCCESS: '@@autotask/CHANGE_AUTOMATION_TASK_HOME_SUCCESS',
  CHANGE_AUTOMATION_TASK_HOME_ERROR: '@@autotask/CHANGE_AUTOMATION_TASK_HOME_ERROR',

  CHANGE_AUTOTASK_VALIDATED_REQUEST: '@@autotask/CHANGE_AUTOTASK_VALIDATED_REQUEST',
  CHANGE_AUTOTASK_VALIDATED_SUCCESS: '@@autotask/CHANGE_AUTOTASK_VALIDATED_SUCCESS',
  CHANGE_AUTOTASK_VALIDATED_ERROR: '@@autotask/CHANGE_AUTOTASK_VALIDATED_ERROR',

  GET_AUTOTASK_VALIDATION_LOG_REQUEST: '@@autotask/GET_AUTOTASK_VALIDATION_LOG_REQUEST',
  GET_AUTOTASK_VALIDATION_LOG_SUCCESS: '@@autotask/GET_AUTOTASK_VALIDATION_LOG_SUCCESS',
  GET_AUTOTASK_VALIDATION_LOG_ERROR: '@@autotask/GET_AUTOTASK_VALIDATION_LOG_ERROR',

  GET_AUTOTASK_PUBLICATION_WEB_APP_LOG_REQUEST: '@@autotask/GET_AUTOTASK_PUBLICATION_WEB_APP_LOG_REQUEST',
  GET_AUTOTASK_PUBLICATION_WEB_APP_LOG_SUCCESS: '@@autotask/GET_AUTOTASK_PUBLICATION_WEB_APP_LOG_SUCCESS',
  GET_AUTOTASK_PUBLICATION_WEB_APP_LOG_ERROR: '@@autotask/GET_AUTOTASK_PUBLICATION_WEB_APP_LOG_ERROR',

  GET_AUTOTASK_PUBLICATION_DEVICE_LOG_REQUEST: '@@autotask/GET_AUTOTASK_PUBLICATION_DEVICE_LOG_REQUEST',
  GET_AUTOTASK_PUBLICATION_DEVICE_LOG_SUCCESS: '@@autotask/GET_AUTOTASK_PUBLICATION_DEVICE_LOG_SUCCESS',
  GET_AUTOTASK_PUBLICATION_DEVICE_LOG_ERROR: '@@autotask/GET_AUTOTASK_PUBLICATION_DEVICE_LOG_ERROR',

  UPDATE_AUTOTASK_STATISTIC_REQUEST: '@@autotask/UPDATE_AUTOTASK_STATISTIC_REQUEST',
  UPDATE_AUTOTASK_STATISTIC_SUCCESS: '@@autotask/UPDATE_AUTOTASK_STATISTIC_SUCCESS',
  UPDATE_AUTOTASK_STATISTIC_ERROR: '@@autotask/UPDATE_AUTOTASK_STATISTIC_ERROR',

  UPDATE_WEB_APP_REQUEST: '@@autotask/UPDATE_WEB_APP_REQUEST',
  UPDATE_WEB_APP_SUCCESS: '@@autotask/UPDATE_WEB_APP_SUCCESS',
  UPDATE_WEB_APP_ERROR: '@@autotask/UPDATE_WEB_APP_ERROR',
};
