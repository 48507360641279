import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import PrizeDiyCustomListFieldset from 'client/components/prizes/fieldsets/prize-diy-custom-list-fieldset';
import {PrizeMapCustomizationFormName} from 'client/components/prizes/forms/prizemap-customization-form';

import cssModule from './prize-diy-custom-list-panel.module.scss';

const b = bem('prize-diy-custom-list-panel', {cssModule});

type PrizeDiyCustomListPanelProps = {
  disabled?: boolean;
  hasAdditionalControls?: boolean;
  className?: string;
};

const PrizeDiyCustomListPanel: React.FC<PrizeDiyCustomListPanelProps> = (props) => {
  const {className, disabled} = props;
  const lang = useLanguage('PRIZES.PANELS.PRIZE_DIY_CUSTOM_LIST_PANEL');

  return (
    <div className={cn(b(), className)}>
      <p className={b('title')}>{lang.TITLE}</p>
      <PrizeDiyCustomListFieldset
        formName={PrizeMapCustomizationFormName}
        disabled={disabled}
        classNames={{
          inputFields: b('input-fields'),
        }}
      />
    </div>
  );
};

export default PrizeDiyCustomListPanel;
