import React from 'react';

import {useParams} from 'react-router-dom';
import {useToggle} from 'react-use';

import {urlWithoutLastSlash} from 'client/services/helpers';
import {useLanguage, useReduxFetch} from 'client/services/hooks';

import {getEmailTemplates} from 'client/ducks/email-templates/actions';
import {selectOperationEmailTemplates} from 'client/ducks/email-templates/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import CountBlock from 'client/common/count-block';

import Icon from 'client/components/common/icon';
// components
import InfoBlock from 'client/components/common/info-block';
import Spinner from 'client/components/common/spinner';

import NewTemplateModal from 'client/components/operation/components/modals/new-template-modal';

import TemplatesCardDataGrid from '../../tables/templates-card-data-grid';

import './templates-card.scss';

type TemplatesCardProps = {
  clientType: string;
};

const TemplatesCard: React.FC<TemplatesCardProps> = (props) => {
  const {clientType} = props;
  const {clientId, id: operationId} = useParams<{clientId: string; id: string}>();
  const [showModal, toggleModal] = useToggle(false);

  const lang = useLanguage('OPERATION_PAGE');
  const {
    loading,
    data: templates = [],
    fetch,
  } = useReduxFetch({
    action: getEmailTemplates,
    selector: selectOperationEmailTemplates,
    actionArgs: {
      q: {
        operation_id_eq: operationId,
        template_email_templates_message_template_kind_id_null: true,
      },
      per_page: 100,
      page: 1,
    },
    fetchOnMount: true,
  });

  const handleConfirm = () => {
    toggleModal();
    fetch();
  };

  return (
    <InfoBlock colorTheme="templates">
      <InfoBlock.Title>{lang.TEMPLATES.TITLE}</InfoBlock.Title>
      <InfoBlock.Content>
        {loading && <Spinner centered />}
        {!loading && (
          <>
            <InfoBlock.Header className="clearfix">
              <CountBlock num={templates.length} itemName={lang.TEMPLATES.TEMPLATES_TITLE} className="pull-left" />
              {templates.length !== 0 && (
                <InfoBlock.HeaderLink
                  link={{
                    url: `${urlWithoutLastSlash(location.pathname)}${CLIENT_PAGES.TEMPLATES}`,
                    text: lang.TEMPLATES.GO_TO_TEMPLATES_LINK,
                  }}
                  description={lang.OF_THIS_OPERATION__DESCRIPTION}
                />
              )}
            </InfoBlock.Header>
            <InfoBlock.Body limitedHeight className="templates-card">
              {templates.length !== 0 ? (
                <TemplatesCardDataGrid
                  data={templates}
                  clientType={clientType}
                  clientId={+clientId}
                  operationId={+operationId}
                />
              ) : (
                <span className="templates-card__empty">{lang.TEMPLATES.NO_TEMPLATES}</span>
              )}
              <div className="templates-card__bottom">
                <button type="button" className="button button--bg-templates templates-card__btn" onClick={toggleModal}>
                  <Icon name="plus" className="button__icon" />
                  <span>{lang.TEMPLATES.CREATE_NEW__BUTTON}</span>
                </button>
              </div>
            </InfoBlock.Body>
          </>
        )}
      </InfoBlock.Content>
      <NewTemplateModal operationId={+operationId} show={showModal} onClose={toggleModal} onConfirm={handleConfirm} />
    </InfoBlock>
  );
};

export default TemplatesCard;
