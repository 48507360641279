import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/components/common/icon';

import {Translation} from 'client/models/language/types';

import {getLanguages} from './helpers';

import './diy-op-main-block-langs.scss';

const b = bem('diy-op-main-block-langs');

type DiyOpMainBlockLangsProps = {
  defaultLanguage?: string | null;
  languages?: string[];
  className?: string;
  title: Translation;
};

const DiyOpMainBlockLangs: React.FC<DiyOpMainBlockLangsProps> = (props) => {
  const {defaultLanguage, languages, title, className} = props;
  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_MAIN_BLOCK.DIY_OP_MAIN_BLOCK_LANGS');
  const valuesLanguages: Record<string, string> = getLanguages(lang);

  return (
    <fieldset className={cn(b(), className)}>
      <legend className={b('legend')}>{title}</legend>
      <div className={b('languages')}>
        {!!languages &&
          languages.map((value: string) => (
            <div className={b('checkbox-button')} key={value}>
              <input
                checked={defaultLanguage === value}
                className={b('checkbox-input')}
                type="checkbox"
                disabled={true}
                value={value}
                id={value}
              />
              <label htmlFor={value} className={b('checkbox-label')}>
                <span className={b('checkbox-value')}>
                  <Icon name={`flag-${value}`} className={b('checkbox-icon')} testId={`flag-${value}`} />
                  <span>{valuesLanguages[value]}</span>
                </span>
              </label>
            </div>
          ))}
      </div>
    </fieldset>
  );
};

export default DiyOpMainBlockLangs;
