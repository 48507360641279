import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import cssModule from './modal-body.module.scss';

const b = bem('modal-body', {cssModule});

type ModalBodyProps = {
  children: React.ReactNode;
  title?: string | React.ReactNode;
  description?: React.ReactNode;
  titleButton?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  titleClassName?: string;
};

const ModalBody: React.FC<ModalBodyProps> = (props) => {
  const {description, className, contentClassName, children, title, titleButton, titleClassName} = props;
  return (
    <div className={cn(b(), className)}>
      {(title || titleButton) && (
        <div className={b('header')}>
          {title && (
            <div className={b('title-wrapper')}>
              <p className={cn(b('title'), titleClassName)}>{title}</p>
              {description && <span className={b('description')}>{description}</span>}
            </div>
          )}
          {titleButton}
        </div>
      )}
      <div className={cn(b('content'), contentClassName)}>{children}</div>
    </div>
  );
};

export default ModalBody;
