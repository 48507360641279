import {ImageBlockData} from 'client/components/email-template-editor/types';

export default (imageBlockContent: ImageBlockData, isPreview: boolean) => {
  const varName = imageBlockContent.variableName;
  const src = imageBlockContent.src;

  if (varName && !isPreview) {
    const varImage = `<mj-image src="{{var:${varName}}}"></mj-image>`;
    const defaultImage = `<mj-image src="${src}"></mj-image>`;
    // eslint-disable-next-line
    return `<mj-raw>{% if var:${varName}:false %}</mj-raw>${varImage}<mj-raw>{% else %}</mj-raw>${defaultImage}<mj-raw>{% endif %}</mj-raw>`;
  }

  if (!src) {
    return '';
  }

  const href = imageBlockContent.redirectUrl || '';
  const alt = imageBlockContent.altText || '';
  const width = imageBlockContent.width || '';
  const paddingLeft = `${imageBlockContent.paddingLeft ?? 0}px`;
  const paddingRight = `${imageBlockContent.paddingRight ?? 0}px`;
  const paddingTop = `${imageBlockContent.paddingLeft ?? 0}px`;
  const paddingBottom = `${imageBlockContent.paddingRight ?? 0}px`;

  const image = document.createElement('mj-image');
  image.setAttribute('src', src);
  image.setAttribute('href', href);
  image.setAttribute('alt', alt);
  image.setAttribute('padding-left', paddingLeft);
  image.setAttribute('padding-right', paddingRight);
  image.setAttribute('padding-top', paddingTop);
  image.setAttribute('padding-bottom', paddingBottom);

  if (imageBlockContent.fluidOnMobile) {
    image.setAttribute('fluid-on-mobile', 'true');
  }

  if (imageBlockContent.backgroundColor) {
    image.setAttribute('container-background-color', imageBlockContent.backgroundColor);
  }

  if (width) {
    image.setAttribute('width', `${width}px`);
  }

  return image.outerHTML;
};
