import React from 'react';

import bem from 'client/services/bem';
import {dateToString} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';
import Modal from 'client/common/modals/modal';

import {EmailTemplate} from 'client/models/email-templates/types';
import {Translation} from 'client/models/language/types';

import cssModule from './gallery-preview-modal.module.scss';

const b = bem('gallery-preview-modal', {cssModule});

type GalleryPreviewModalProps = {
  onClose: () => void;
  template: EmailTemplate;
};

const GalleryPreviewModal: React.FC<GalleryPreviewModalProps> = (props) => {
  const {onClose, template} = props;
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.MODALS.GALLERY_PREVIEW_MODAL');

  const renderInfoRow = (key: Translation, value: string) => (
    <div className={b('info-row')}>
      <span className={b('info-key')}>{key}</span>
      <span className={b('info-value')} title={value}>
        {value}
      </span>
    </div>
  );

  return (
    <Modal
      dialogClassName={b()}
      onClose={onClose}
      isCloseHidden
      headerClassName={b('header-wrap')}
      header={
        <div className={b('header')}>
          <span className={b('title')}>{template.name}</span>
          <AppButton label={lang.OK} onClick={onClose} />
        </div>
      }
    >
      <div className={b('content')}>
        <div className={b('col-preview')}>
          <img className={b('preview-image')} src={template.preview_image_url} alt="" />
        </div>
        <div className={b('col-info')}>
          {renderInfoRow(lang.CREATED, template.created_by)}
          {renderInfoRow(lang.MODIFIED, dateToString(template.updated_at, 'DD/MM/YY HH:mm'))}
        </div>
      </div>
    </Modal>
  );
};

export default GalleryPreviewModal;
