import update from 'immutability-helper';
import defaultsDeep from 'lodash/defaultsDeep';
import flow from 'lodash/flow';
import groupBy from 'lodash/fp/groupBy';
import get from 'lodash/get';
import toFormData from 'object-to-formdata';

import {
  selectSocialAccountsGroupedByType,
  selectFacebookAccounts,
  selectTwitterAccounts,
} from 'client/ducks/social-accounts/selectors';

import {SCENARIO_STEP_TYPES} from 'client/common/config';

import {
  SOCIAL_ACCOUNT_IMPLEMENTATION_TYPE_MAPPING,
  SOCIAL_IMAGES_TYPE_MAPPING,
  SOCIAL_IMAGES_QUANTITY_LIMIT,
  SOCIAL_MESSAGE_LENGTH_LIMIT,
} from './constants';

import {compactObject} from '../helpers';

export const createImplementation = () => ({
  social_account_id: null,
  message: '',
});

export const getSocialAccounts = (data, state) => {
  const {
    implementation: {type},
  } = data;
  let socialAccounts = null;
  switch (type) {
    case SCENARIO_STEP_TYPES.social.twitter:
      socialAccounts = selectTwitterAccounts(state);
      break;
    case SCENARIO_STEP_TYPES.social.facebook:
      socialAccounts = selectFacebookAccounts(state);
      break;
    default:
      socialAccounts = selectSocialAccountsGroupedByType(state);
      const socialAccountType = SOCIAL_ACCOUNT_IMPLEMENTATION_TYPE_MAPPING[type];
      return socialAccounts[socialAccountType];
  }
  return socialAccounts;
};

export const getImageQuantityLimit = (data) => {
  const {
    implementation: {type},
  } = data;

  return SOCIAL_IMAGES_QUANTITY_LIMIT[type];
};

export const getMessageLengthLimit = (data) => {
  const {
    implementation: {type},
  } = data;

  return SOCIAL_MESSAGE_LENGTH_LIMIT[type];
};

export const getInitialValues = (data, state) => {
  const socialAccounts = getSocialAccounts(data, state);
  const images = flow([(values) => get(values, 'implementation.social_scenario_step_images', []), groupBy('type')])(
    data,
  );

  return defaultsDeep({}, compactObject(data), {
    images: {
      files: get(images, SOCIAL_IMAGES_TYPE_MAPPING.file, []),
      variables: get(images, SOCIAL_IMAGES_TYPE_MAPPING.variable, []),
      newFiles: [],
      newVariables: [],
    },
    implementation: {
      social_account_id: get(socialAccounts, '[0].id', null),
      message: '',
      social_scenario_step_images: [],
    },
  });
};

const transformFormData = (values) => {
  return update(values, {
    $unset: ['images'],
    implementation: {
      social_scenario_step_images: {
        $set: values.images.newVariables.map((variable) => ({
          variable_id: variable.variable_id,
          type: SOCIAL_IMAGES_TYPE_MAPPING.variable,
        })),
      },
    },
  });
};

export const submitForm = (values, dispatch, props) => {
  const transformedValues = transformFormData(values);

  return props.onSubmitForm(transformedValues).then((res) => {
    const stepId = get(res, 'payload.scenario_step.implementation.id', null);

    const uploadingImages = values.images.newFiles.map((file) => {
      const formdata = toFormData({
        file_social_scenario_step_image: {
          file,
          social_scenario_step_id: stepId,
        },
      });

      return props.uploadSocialScenarioStepImage(values.id, formdata);
    });

    return Promise.all(uploadingImages).then(() => res);
  });
};
