import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import cssModule from './modal-header.module.scss';

const b = bem('modal-header', {cssModule});

type ModalHeaderProps = {
  header?: React.ReactNode;
  className?: string;
};
const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  const {header, className} = props;

  return <div className={cn(b(), className)}>{header}</div>;
};

export default ModalHeader;
