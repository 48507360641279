import React, {Component} from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import DateCell from '../cells/date-cell';
import NbOfParticipantsCell from '../cells/nb-of-participants-cell';
import TextCell from '../cells/text-cell';

class ParticipantsHistoryCardHeaderDataGrid extends Component {
  static propTypes = {
    clientId: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    isDashboard: PropTypes.bool.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.PARTICIPANTS_EXPORT_HISTORY;
  }

  render() {
    const {clientId, data, isDashboard} = this.props;

    return (
      <div
        className={`fixed-table fixed-table--leads-card
      theme-color-${isDashboard ? '17' : '9 participants-export-history-card-table'}`}
      >
        <Table rowsCount={data.length} headerHeight={40} rowHeight={34} width={540} maxHeight={115} data={data}>
          <Column
            header={<Cell>{this.LANGUAGE.FORMAT_COLUMN}</Cell>}
            cell={<TextCell data={this.props.data} field="format" />}
            width={40}
          />
          <Column
            header={<Cell>{this.LANGUAGE.END_DATE_COLUMN}</Cell>}
            cell={<DateCell data={this.props.data} field="endDate" />}
            width={40}
            flexGrow={1}
          />
          <Column
            header={<Cell>{this.LANGUAGE.NB_OF_PARTICIPANTS_COLUMN}</Cell>}
            cell={<NbOfParticipantsCell data={this.props.data} field="participantsReportLink" clientId={clientId} />}
            width={120}
            flexGrow={1}
          />
          <Column
            header={<Cell>{this.LANGUAGE.NO_OF_LEADS_COLUMN}</Cell>}
            cell={<DateCell data={this.props.data} field="endDate" />}
            width={40}
            flexGrow={1}
          />
        </Table>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(ParticipantsHistoryCardHeaderDataGrid);
