import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {formatNumberSpace} from 'client/services/formatters';

import {BarChartProps} from './types';

import cssModule from './bar-chart.module.scss';

const b = bem('bar-chart', {cssModule});

const BarChart: React.FC<BarChartProps> = (props) => {
  const {
    title = '',
    number,
    orientation = 'horizontal',
    percent,
    color = 'primary',
    titleColor = 'text-additional',
    textColor = 'text-additional',
    showPercent = true,
    showBrackets = true,
    bgColor = 'line-chart-bg',
    titleWidth = 100,
    titleFontWeight = 400,
    titlePercentFontWeight = 400,
    barColor = 'primary',
    showEmptyPercent = false,
    className = '',
    classNameTotal,
    classNameStripe,
    classNames,
  } = props;

  return (
    <div className={cn(b([orientation]), className)}>
      <div className={cn(b('total'), classNames?.total, classNameTotal)}>
        {title && (
          <span
            className={cn(b('title', [titleColor]), classNames?.title)}
            title={title?.toString()}
            style={{width: `${titleWidth}%`, fontWeight: `${titleFontWeight}`}}
          >
            {title}
          </span>
        )}
        <span
          title={formatNumberSpace(number)}
          className={cn(b('number', [color]), classNames?.number)}
          style={{fontWeight: `${titleFontWeight}`}}
        >
          {`${formatNumberSpace(number)} `}
        </span>
        {showPercent && (
          <span
            title={showBrackets ? `(${percent}%)` : `${percent}%`}
            className={cn(b('percent', [textColor]), classNames?.percent)}
            style={{width: `${titleWidth}%`, fontWeight: `${titlePercentFontWeight}`}}
          >
            {showBrackets ? `(${percent}%)` : `${percent}%`}
          </span>
        )}
        {!showPercent && showEmptyPercent && (
          <span className={cn(b('percent', [textColor]), classNames?.percent)} style={{width: `${titleWidth}%`}} />
        )}
      </div>
      <div className={cn(b('stripe', [bgColor]), classNames?.stripe, classNameStripe)}>
        <div className={cn(b('stripe-bar', [barColor || color]), classNames?.stripeBar)} style={{width: `${percent}%`}}>
          {' '}
        </div>
      </div>
    </div>
  );
};

export default BarChart;
