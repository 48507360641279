import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {selectTaskBaseInfo} from 'client/ducks/message-email-sms-task/selectors';
import {selectIsUserLoaded, selectIsAdmin} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import MessageTaskSettings from 'client/components/broadcasting/message-task-settings';

class MessageTaskSettingsPage extends Component {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        taskId: PropTypes.string,
        clientId: PropTypes.string,
        operationId: PropTypes.string,
      }),
    }).isRequired,
    task: PropTypes.object.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  componentDidUpdate(prevProps) {
    const {task, setMetaAction} = this.props;

    if (prevProps.task.taskName !== task.taskName) {
      setMetaAction({
        title: task.taskName,
      });
    }
  }

  render() {
    const {
      match: {
        params: {taskId, clientId, operationId},
      },
      isUserLoaded,
      isAdmin,
    } = this.props;

    if (!isUserLoaded) {
      return null;
    }

    if (!isAdmin) {
      return <Redirect to={CLIENT_PAGES.HOME} />;
    }

    return <MessageTaskSettings taskId={taskId} clientId={clientId} operationId={operationId} />;
  }
}

const mapStateToProps = (state) => ({
  task: selectTaskBaseInfo(state),
  isUserLoaded: selectIsUserLoaded(state),
  isAdmin: selectIsAdmin(state),
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageTaskSettingsPage);
