import React from 'react';

import {useLanguage} from 'client/services/hooks';

import ToolbarButton from 'client/common/text-editor/buttons/toolbar-button';
import {CustomEditor, TEXT_ALIGN_OPTIONS, TEXT_ALIGN_TO_LANG} from 'client/common/text-editor/constants';
import {editorMethods} from 'client/common/text-editor/helpers';

type AlignButtonsProps = {
  editor: CustomEditor;
  className?: string;
};
const AlignButtons = (props: AlignButtonsProps) => {
  const {editor, className} = props;

  const lang = useLanguage('COMMON.TEXT_EDITOR.LABELS');
  return (
    <div className={className}>
      {TEXT_ALIGN_OPTIONS.map((alignment) => (
        <ToolbarButton
          key={alignment}
          onClick={() => editorMethods.toggleBlock(editor, alignment)}
          iconName={`align-${alignment}`}
          label={lang[TEXT_ALIGN_TO_LANG[alignment]]}
          active={editorMethods.isBlockActive(editor, alignment, 'align')}
          bordered={false}
        />
      ))}
    </div>
  );
};
export default AlignButtons;
