import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import {doesValueExist} from 'client/services/helpers';

import fieldTemplate from 'client/components/common/field';

import Modal from '../../../common/modals/modal';

// import './select-user-modal.scss';

class SelectUserModal extends Component {
  static propTypes = {
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ).isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    clientId: PropTypes.number.isRequired,
    userState: PropTypes.object.isRequired,
    operationId: PropTypes.number,
    automationTaskId: PropTypes.number,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    operationId: null,
    automationTaskId: null,
  };

  static formName = 'SelectModalModalForm';

  state = {
    clientUserId: null,
  };

  getClientId = (client_user_id) => {
    this.setState({clientUserId: client_user_id});
  };

  redirect = () => {
    const {clientId, operationId, automationTaskId} = this.props;
    const {clientUserId} = this.state;
    const link =
      doesValueExist(operationId) && doesValueExist(automationTaskId)
        ? // eslint-disable-next-line max-len
          `/clients/${clientId}/operations/${operationId}/autotask/${automationTaskId}/participations?selectedOperationId=${operationId}#view_user_id=${clientUserId}`
        : `/clients/${clientId}/operations#view_user_id=${clientUserId}`;
    window.open(link);
  };

  render() {
    const {onClose, show, className, lang, users} = this.props;
    const {clientUserId} = this.state;
    const isDisabled = !doesValueExist(clientUserId);
    const selectLength = users.length >= 10 ? 320 : 'auto';
    return (
      <Modal show={show} title={lang.TITLE} onClose={onClose} dialogClassName={`modal-window--width-1 ${className}`}>
        <form name="SelectUser">
          <div className="form-field-wrap">
            <Field
              name="client"
              label={lang.SELECT_LABEL}
              type="select"
              options={users}
              component={fieldTemplate}
              onChange={({id}) => this.getClientId(id)}
              scrollBarProps={{
                autoHeightMin: selectLength,
              }}
            />
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-1 modal-window__footer-btn" type="button" onClick={onClose}>
              {lang.CANCEL_BUTTON}
            </button>
            <button
              className={cn('button', 'button--bg-1', 'modal-window__footer-btn', 'button__extended', {
                disabled: isDisabled,
              })}
              type="button"
              onClick={this.redirect}
              disabled={isDisabled}
            >
              {lang.GO_TO_CLIENT_SIDE}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const Form = reduxForm({
  form: SelectUserModal.formName,
})(SelectUserModal);

const mapStateToProps = ({userState, languageState}) => ({
  userState: userState,
  lang: languageState.payload.AGENCY.SELECT_USER_MODAL,
});

export default connect(mapStateToProps)(Form);
