import React, {Component} from 'react';

import PropTypes from 'prop-types';

import GoogleMap from 'client/common/google-map';
import Popover from 'client/common/popovers/popover';

import PlaceAddressHint from '../place-address-hint';

import './spread-map.scss';

class SpreadMap extends Component {
  static propTypes = {
    isPreview: PropTypes.bool,
    places: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        count: PropTypes.number,
        hint: PropTypes.string,
      }),
    ).isRequired,
  };

  static defaultProps = {
    isPreview: false,
  };

  static Marker({place}) {
    return (
      <Popover position="left" overlay={<PlaceAddressHint place={place} />}>
        <div className="spread-map__marker">{place.count}</div>
      </Popover>
    );
  }

  render() {
    const {places, isPreview} = this.props;

    return (
      <>
        <GoogleMap
          forceCenter={isPreview}
          zoom={isPreview ? 11 : 0}
          fitBounds={isPreview}
          googleOptions={{
            zoomControl: !isPreview,
            draggable: !isPreview,
          }}
          markers={places.map((place) => ({
            ...place,
            lat: place.latitude,
            lng: place.longitude,
            data: place,
          }))}
          renderMarker={(marker) => (
            <SpreadMap.Marker place={marker.data} lat={marker.data.latitude} lng={marker.data.longitude} />
          )}
          googleProps={{
            defaultZoom: 11,
            yesIWantToUseGoogleMapApiInternals: true,
          }}
        />
      </>
    );
  }
}

export default SpreadMap;
