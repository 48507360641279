import React, {useState, useRef, useMemo, useCallback, useEffect} from 'react';

import {
  useFloating,
  useHover,
  useClick,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useId,
  arrow,
  offset,
  shift,
  safePolygon,
  autoUpdate,
  FloatingArrow,
  flip,
  FloatingPortal,
  FloatingFocusManager,
} from '@floating-ui/react';
import cn from 'classnames';
import isNull from 'lodash/isNull';

import bem from 'client/services/bem';

import {DEFAULT_ARROW_CONFIG} from './constants';
import {getShiftPosition, setShift} from './helpers';
import {PopoverProps} from './types';

import cssModule from './popover.module.scss';

export const POPOVER_PORTAL_ID = 'portal-root';

const b = bem('popover', {cssModule});
const Popover: React.FC<PopoverProps> = (props) => {
  const {
    trigger = 'hover',
    shiftLeft = 0,
    shiftTop = 0,
    className,
    triggerClassName,
    positionOptions,
    overlayInnerStyle,
    contentClassName,
    position = 'bottom',
    flipOptions = {mainAxis: false}, // it doesn't work due to FloatingPortal
    shiftOptions = {mainAxis: false}, // it doesn't work due to FloatingPortal
    overlay,
    children,
    arrowOffset = 0,
    arrowConfig,
    show,
    hideOnMouseLeave,
    disableFocus,
    setIsPopoverShown,
    disabled,
  } = props;
  const exterArrowConfig = {...DEFAULT_ARROW_CONFIG, ...arrowConfig};
  const [isOpen, setIsOpenDirect] = useState(show || false);
  const arrowRef = useRef(null);

  const setIsOpen = useCallback(
    (value) => {
      if (!disabled) {
        setIsOpenDirect(value);
      }
    },
    [disabled],
  );

  const offsetParams = useCallback(
    ({rects}) => {
      if (positionOptions) {
        return positionOptions;
      }
      const percent = arrowOffset / 100 || 0;
      const floatingWidth = rects.floating.width;
      const referenceWidth = rects.reference.width;
      return {
        mainAxis: 5,
        crossAxis: percent ? floatingWidth / 2 - floatingWidth * percent - referenceWidth / 2 : 0,
      };
    },
    [arrowOffset, positionOptions],
  );

  const shiftPosition = useMemo(() => {
    return getShiftPosition({
      shiftLeft,
      shiftTop,
      position,
      arrowOffset,
    });
  }, [arrowOffset, position, shiftLeft, shiftTop]);

  const {refs, floatingStyles, context} = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: position,
    middleware: [
      flip(flipOptions),
      offset(offsetParams),
      shift(shiftOptions),
      arrow({element: arrowRef}),
      setShift(shiftPosition.shiftLeft, shiftPosition.shiftTop),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    enabled: trigger === 'hover' || trigger === 'both',
    handleClose: hideOnMouseLeave ? null : safePolygon(),
  });
  const focus = useFocus(context);
  const click = useClick(context, {
    enabled: ['both', 'click'].includes(trigger),
  });

  useEffect(() => {
    const modal = document.querySelector('[data-type="modal"]');
    const mainPortal = document.body.querySelector(`#${POPOVER_PORTAL_ID}`);
    const portal = document.createElement('div');
    portal.id = POPOVER_PORTAL_ID;
    if (isNull(mainPortal)) {
      document.body.appendChild(portal);
    }
    if (!isNull(modal)) {
      document.body.querySelectorAll(`#${POPOVER_PORTAL_ID}`).forEach((elem) => elem.remove());
      modal.appendChild(portal);
    }
    return () => {
      document.body.querySelectorAll(`#${POPOVER_PORTAL_ID}`).forEach((elem) => elem.remove());
      if (!isNull(modal)) {
        modal.querySelector(`#${POPOVER_PORTAL_ID}`)?.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (setIsPopoverShown && isOpen) {
      setIsPopoverShown(isOpen);
    }

    return () => {
      if (setIsPopoverShown && isOpen) {
        setIsPopoverShown(!isOpen);
      }
    };
  }, [isOpen, setIsPopoverShown]);

  const interactions = useMemo(() => {
    if (trigger === 'both') {
      return [hover, click];
    } else if (trigger === 'click') {
      return [click];
    }
    return [hover];
  }, [click, hover, trigger]);

  const dismiss = useDismiss(context);
  const role = useRole(context, {role: 'tooltip'});
  const headingId = useId();

  const {getReferenceProps, getFloatingProps} = useInteractions([...interactions, focus, dismiss, role]);

  return (
    <>
      <div ref={refs.setReference} className={cn(className, triggerClassName, b())} {...getReferenceProps()}>
        {children}
      </div>
      {isOpen && overlay && (
        <FloatingPortal preserveTabOrder={false} id={POPOVER_PORTAL_ID}>
          <FloatingFocusManager
            context={context}
            initialFocus={refs.floating}
            modal={false}
            closeOnFocusOut={true}
            returnFocus={false}
            disabled={disableFocus}
          >
            <div
              className={b('overlay-container')}
              ref={refs.setFloating}
              style={floatingStyles}
              aria-labelledby={headingId}
              {...getFloatingProps()}
            >
              <FloatingArrow
                ref={arrowRef}
                context={context}
                staticOffset={arrowOffset ? `${arrowOffset}%` : null}
                {...exterArrowConfig}
              />
              <div
                className={cn(contentClassName, b('overlay'))}
                style={{...overlayInnerStyle, backgroundColor: exterArrowConfig?.fill}}
              >
                {typeof overlay === 'function' ? overlay({isOpen, setIsOpen}) : overlay}
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};
export default Popover;
