import React from 'react';

import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useMount} from 'react-use';
import {reduxForm} from 'redux-form';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {
  getWinningsRegions,
  getWinningsStores,
  getWinningsDevices,
  getWinningsCampaigns,
} from 'client/ducks/winnings/actions';
import {
  selectWinningsRegions,
  selectWinningsStores,
  selectWinningsDevices,
  selectWinningsCampaigns,
} from 'client/ducks/winnings/selectors';

import AppButton from 'client/common/buttons';
import {CheckboxField, SelectField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import {mapOptions} from './helpers';

import './winnings-tab-filters-modal.scss';

const b = bem('winnings-tab-filters-modal');

const WinningsTabFiltersModal = (props) => {
  const {task, operation, onClose, handleSubmit, onSave} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('CLIENT_AUTOTASK.WINNINGS_TAB.WINNINGS_TAB_FILTERS_MODAL');
  const {formValues = {}, change} = useReduxForm(WinningsTabFiltersModal.formName);

  const regions = useSelector(selectWinningsRegions);
  const stores = useSelector(selectWinningsStores);
  const devices = useSelector(selectWinningsDevices);
  const campaigns = useSelector(selectWinningsCampaigns);

  const fetchRegions = () =>
    dispatch(
      getWinningsRegions({
        q: {
          client_id_eq: operation.client_id,
          interactions_interface_automation_task_id_eq: task.id,
        },
        distinct: true,
      }),
    );

  const fetchStores = () =>
    dispatch(
      getWinningsStores({
        q: {
          client_id_eq: operation.client_id,
          interactions_interface_automation_task_id_eq: task.id,
        },
        distinct: true,
      }),
    );

  const fetchDevices = () =>
    dispatch(
      getWinningsDevices({
        q: {
          interface_automation_task_id_eq: task.id,
        },
        include: {device: null},
      }),
    );

  const fetchCampaigns = () =>
    dispatch(
      getWinningsCampaigns({
        q: {
          automation_task_id_eq: task.id,
        },
      }),
    );

  useMount(() => {
    fetchRegions();
    fetchStores();
    fetchDevices();
    fetchCampaigns();
  });

  const handleSave = (values) => {
    onSave({filters: values});
    onClose();
  };

  const clearFields = (fields) => (value) => {
    if (value === false) {
      fields.forEach((field) => change(field, null));
    }
  };

  return (
    <Modal dialogClassName={b()} onClose={onClose} title={lang.TITLE}>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className={b('fields')}>
          <div className={b('column-left')}>
            <CheckboxField
              label={lang.DEVICES}
              name="offline"
              withWrap
              onChange={clearFields(['region', 'store', 'device'])}
            />
            <SelectField
              label={lang.REGION}
              name="region"
              options={mapOptions(regions)}
              disabled={!formValues.offline}
              withWrap
              simpleValue
              searchable
              multi
            />
            <SelectField
              label={lang.STORE}
              name="store"
              options={mapOptions(stores)}
              disabled={!formValues.offline}
              withWrap
              simpleValue
              searchable
              multi
            />
            <SelectField
              label={lang.DEVICE}
              name="device"
              options={mapOptions(devices)}
              disabled={!formValues.offline}
              withWrap
              simpleValue
              searchable
              multi
            />
          </div>
          <div className={b('column-right')}>
            <CheckboxField label={lang.ONLINE} name="online" withWrap onChange={clearFields(['campaign', 'source'])} />
            <SelectField
              label={lang.CAMPAIGN}
              name="campaign"
              options={mapOptions(campaigns)}
              disabled={!formValues.online}
              withWrap
              simpleValue
              searchable
              multi
            />
            <SelectField
              label={lang.SOURCE}
              name="source"
              options={[
                {label: 'Iframe', value: 'iframe'},
                {label: 'Minisite', value: 'minisite'},
              ]}
              disabled={!formValues.online}
              withWrap
              simpleValue
              searchable
            />
          </div>
        </div>
        <div className={b('footer')}>
          <AppButton label={lang.APPLY_FILTERS} color="primary" submit />
        </div>
      </form>
    </Modal>
  );
};

WinningsTabFiltersModal.formName = 'WinningsTabFiltersModalForm';

WinningsTabFiltersModal.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  operation: PropTypes.shape({
    client_id: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: WinningsTabFiltersModal.formName,
})(WinningsTabFiltersModal);
