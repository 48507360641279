import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError, reset} from 'redux-form';

import {getStringFromArrayMap} from 'client/services/helpers';
import {required} from 'client/services/validator';

import {createBroadcast} from 'client/ducks/broadcast/actions';

import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

class BCNewBroadcastListModal extends Component {
  static defaultProps = {
    show: false,
  };

  static propTypes = {
    show: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    broadcastId: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    languageState: PropTypes.object.isRequired,
    reset: PropTypes.func.isRequired,
    createBroadcast: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  static formName = 'BCNewBroadcastListModalForm';

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.BROADCASTING_TASK.NEW_BROADCAST_LIST_MODAL;

    this.rules = {
      name: required(this.LANGUAGE.REQUIRED_ERROR),
    };

    this.serverErrorsMap = {
      taken: this.LANGUAGE.NAME_IS_TAKEN_ERROR,
    };
  }

  getServerErrorsString = (errors, map) => getStringFromArrayMap(errors, map);

  save = (data) => {
    const {broadcastId, clientId} = this.props;

    const body = {
      broadcast_list: {
        name: data.name,
        client_id: clientId,
      },
    };

    // if creating new list from existed
    if (+broadcastId) {
      body.broadcast_list.parent_id = broadcastId;
    }

    return this.props.createBroadcast(body).then((res) => {
      const {
        payload: {response},
      } = res;

      if (response && response.errors && response.errors.name) {
        const name = this.getServerErrorsString(response.errors.name, this.serverErrorsMap);

        throw new SubmissionError({
          name,
        });
      }
      this.props.reset();
      this.props.onConfirm(res.payload.broadcast_list);
    });
  };

  handleClose = () => {
    this.props.reset();
    this.props.onClose();
  };

  render() {
    const {show, handleSubmit} = this.props;

    const {LANGUAGE, rules, handleClose} = this;

    return (
      <Modal
        show={show}
        onClose={handleClose}
        title={LANGUAGE.TITLE}
        dialogClassName="modal-window--width-1 theme-color-16"
      >
        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          <div className="form-field-wrap">
            <Field
              label={LANGUAGE.NAME_LABEL + ' *'}
              name="name"
              type="text"
              validate={rules.name}
              component={fieldTemplate}
            />
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={handleClose}>
              {LANGUAGE.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-14 modal-window__footer-btn" type="submit">
              {LANGUAGE.CONFIRM_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const form = reduxForm({
  form: BCNewBroadcastListModal.formName,
})(BCNewBroadcastListModal);

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

const mapDispatchToProps = {
  reset,
  createBroadcast,
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
