import React from 'react';

import PropTypes from 'prop-types';

import {TextField} from 'client/components/common/fields';

const SogecFieldset = (props) => {
  const {lang} = props;

  return [
    <TextField
      key="sogec_maximum_tokens_number"
      name="sogec_maximum_tokens_number"
      label={lang.MAXIMUM_TOKENS_NUMBER}
    />,
    <TextField key="coupon_id" name="coupon_id" label={lang.COUPON_ID} />,
    <TextField key="campaign_id" name="campaign_id" label={lang.CAMPAIGN_ID} />,
    <TextField key="login" name="login" label={lang.LOGIN} />,
    <TextField key="password" name="password" label={lang.PASSWORD} />,
  ];
};

SogecFieldset.propTypes = {
  lang: PropTypes.object.isRequired,
};

export default SogecFieldset;
