import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {CLIENT_PAGES} from 'client/common/config';
import CountBlock from 'client/common/count-block';

import Icon from 'client/components/common/icon';
// components
import InfoBlock from 'client/components/common/info-block';

import CustomDatabaseLabelsCardDataGrid from '../../tables/custom-database-labels-card-data-grid';

import './custom-database-labels-card.scss';

class CustomDatabaseLabelsCard extends Component {
  static propTypes = {
    data: PropTypes.array,
    languageState: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onCreateClick: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.OPERATION_PAGE;
  }

  render() {
    const {data, onEditClick, onCreateClick} = this.props;
    const {LANGUAGE} = this;

    return (
      <InfoBlock colorTheme="opdb-labels">
        <InfoBlock.Title>{LANGUAGE.CUSTOM_DATABASE_LABELS.TITLE}</InfoBlock.Title>
        <InfoBlock.Content>
          <InfoBlock.Header className="clearfix">
            <CountBlock
              num={data.length}
              itemName={LANGUAGE.CUSTOM_DATABASE_LABELS.LABELS_TITLE}
              className="pull-left"
            />
            {data.length !== 0 && (
              <InfoBlock.HeaderLink
                link={{
                  url: this.props.location.pathname + CLIENT_PAGES.CUSTOM_OPERATION_DATABASE,
                  text: LANGUAGE.CUSTOM_DATABASE_LABELS.GO_TO_CUSTOM_DATABASE_LINK,
                }}
                description={LANGUAGE.OF_THIS_OPERATION__DESCRIPTION}
              />
            )}
          </InfoBlock.Header>
          <InfoBlock.Body limitedHeight className="custom-database-labels-card">
            {data.length !== 0 ? (
              <CustomDatabaseLabelsCardDataGrid data={data} onEditClick={onEditClick} />
            ) : (
              <span className="custom-database-labels-card__empty">{LANGUAGE.CUSTOM_DATABASE_LABELS.NO_LABELS}</span>
            )}
            <div className="custom-database-labels-card__bottom">
              <button className="button button--bg-operations custom-database-labels-card__btn" onClick={onCreateClick}>
                <Icon name="plus" className="button__icon" />
                <span>{LANGUAGE.CUSTOM_DATABASE_LABELS.CREATE_NEW__BUTTON}</span>
              </button>
            </div>
          </InfoBlock.Body>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

export default withRouter(connect(mapStateToProps)(CustomDatabaseLabelsCard));
