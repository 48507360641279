import React, {useMemo} from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {NumberField, SelectField} from 'client/common/fields';

import {PRIZE_RATIO_TIME_UNITS} from './constants';
import {PrizeDiyRatioTimeFieldsetProps} from './type';

import cssModule from './prize-diy-ratio-time-fieldset.module.scss';

const b = bem('prize-diy-ratio-time-fieldset', {cssModule});

const PrizeDiyRatioTimeFieldset: React.FC<PrizeDiyRatioTimeFieldsetProps> = (props) => {
  const {disabled, className, required, classNames} = props;

  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_RATIO_TIME_FIELDSET');
  const langTimeUnits = useLanguage('TIME_UNITS');

  const measurementOptions = useMemo(
    () => [
      {value: PRIZE_RATIO_TIME_UNITS.SECONDS, label: langTimeUnits.SECONDS},
      {value: PRIZE_RATIO_TIME_UNITS.MINUTES, label: langTimeUnits.MINUTES},
      {value: PRIZE_RATIO_TIME_UNITS.HOURS, label: langTimeUnits.HOURS},
    ],
    [langTimeUnits],
  );

  return (
    <div className={cn(b(), className)}>
      <span className={cn(b('description'), classNames?.description)}>{lang.DESCRIPTION}</span>
      <NumberField
        name="ratioTime.units_number"
        className={cn(b('input'), classNames?.input)}
        disabled={disabled}
        required={required}
      />
      <SelectField
        name="ratioTime.time_unit"
        className={cn(b('input'), classNames?.input)}
        options={measurementOptions}
        normalize={(option: {value: string}) => option?.value}
        disabled={disabled}
      />
    </div>
  );
};

export default PrizeDiyRatioTimeFieldset;
