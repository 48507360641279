import React, {ReactNode, useEffect, useMemo, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useTranslations} from 'client/services/hooks';
import {destroyProductFruits} from 'client/services/product-fruits/helpers';

import {selectLanguages} from 'client/ducks/language/selectors';

import {getAppLanguage} from 'client/common/config';
import {set as setMetaAction} from 'client/common/meta/meta.actions';
import SelectDropdown from 'client/common/selects/select-dropdown';

import ProfileAvatar from 'client/components/profile-avatar';
import {User} from 'client/models/users/types';

import ProfilePanel from '../profile-panel';

import './profile-info.scss';

const b = bem('profile-info');

type ProfileInfoProps = {
  user: User;
  onEditPhotoClick: () => void;
  updateProfile: (value: Partial<User>) => void;
  children?: ReactNode;
};

const ProfileInfo: React.FC<ProfileInfoProps> = (props) => {
  const {user, onEditPhotoClick, updateProfile, children} = props;
  const lang = useLanguage('PROFILE.PROFILE_INFO');
  const {setLanguage} = useTranslations();
  const [userLanguage, setUserLanguage] = useState(user.locale || (getAppLanguage() as string));
  const [reload, setReload] = useState(false);
  const appLanguages = useSelector(selectLanguages);

  const dispatch = useDispatch();

  const languages = useMemo(
    () => appLanguages.filter(({active}) => !!active).map(({name, full_name}) => ({label: full_name, value: name})),
    [appLanguages],
  );

  const initialUserLanguage = user.locale || getAppLanguage();
  const src = user?.avatar?.url || '';
  const isWoman = user?.title === 'Mme.';
  // TODO: Object keys should be constant value (USER_CIVILITY)
  const genderMap = {
    'M.': lang.CIVILITY_MALE,
    'Mme.': lang.CIVILITY_FEMALE,
  };
  type GenderMap = typeof genderMap;

  const reloadPage = () => window.location.reload();

  useEffect(() => {
    dispatch(setMetaAction({title: lang.TITLE}));
  }, [dispatch, lang.TITLE]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (reload) {
      interval = setInterval(() => {
        reloadPage();
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [reload]);

  const handleChange = (value: string) => {
    setUserLanguage(value);
  };

  const saveUserLanguage = async () => {
    await updateProfile({locale: userLanguage});
    await setLanguage({lang: userLanguage});
    destroyProductFruits();
    setReload(true);
  };

  return (
    <ProfilePanel title={lang.TITLE}>
      <div className={b()}>
        <ProfileAvatar onEditClick={onEditPhotoClick} isWoman={isWoman} src={src} />
        <div className={b('data')}>
          <p className={b('data-item')}>{genderMap[user.title as keyof GenderMap]}</p>
          <p className={b('data-item')}>{user.full_name}</p>
          <p className={b('data-item')}>{user.email}</p>
          <p className={b('data-item')}>{user.phone}</p>
          <div className={b('lang')}>
            <p className={b('lang-title')}>{lang.LANGUAGE}</p>
            <SelectDropdown
              className={b('lang-dropdown')}
              classNames={{control: () => b('lang-dropdown-control')}}
              value={userLanguage}
              options={languages}
              onChange={handleChange}
              simpleValue
            />
            {initialUserLanguage !== userLanguage && (
              <button className={b('lang-button')} onClick={saveUserLanguage}>
                {lang.OK}
              </button>
            )}
          </div>
        </div>
        {children}
      </div>
    </ProfilePanel>
  );
};

export default ProfileInfo;
