import React, {useCallback, useEffect, useRef} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {matchRoutes} from 'routes/index';
import {Route} from 'routes/types';

import {getIsTrackingUser, getToken, getUserId} from 'client/services/cookie-data-source';
import {getDaysDiff, getMinutesDiff} from 'client/services/helpers';
import * as viewModeService from 'client/services/userViewModeService';

import {dubFactorAuthSettingsSelector} from 'client/ducks/settings/selectors';
import {selectClientAccessLevel} from 'client/ducks/user-clients/selectors';
import {expirePassword, logout} from 'client/ducks/user/actions';
import {haveAccessLevel, isHavePermission} from 'client/ducks/user/helper';
import {selectUser} from 'client/ducks/user/selectors';
import {USER_LOADED, USER_NOT_LOADED} from 'client/ducks/user/types';

import {CLIENT_PAGES} from 'client/common/config';

import PasswordExpirationNotification from 'client/components/notifications/password-expiration-notification';
import {ApiDispatch, MainStates} from 'client/types';

type AuthProviderProps = {
  children: React.ReactNode;
  isUserReady: boolean;
  isViewModeActive: boolean;
  route: {routes: Route[]};
};

export const AuthProvider: React.FC<AuthProviderProps> = ({children, isUserReady, isViewModeActive, route}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch: ApiDispatch = useDispatch();

  const timerIdRef = useRef<NodeJS.Timeout | null>(null);

  const accessLevel = useSelector(selectClientAccessLevel);
  const user = useSelector(selectUser);
  const userState = useSelector((state: MainStates) => state.userState);

  const dub2FASettings = useSelector(dubFactorAuthSettingsSelector);

  const {isPasswordExpired} = userState;
  const isTrackingUser = getIsTrackingUser();

  const branch = matchRoutes(route.routes, location.pathname)[0];
  const branchParams = branch.route.params;
  const {permissions, isNotNeedLogin, accessLevels} = branchParams;
  const isProfilePage = branch.route.path === CLIENT_PAGES.PROFILE;

  // Callbacks
  const checkAccess = useCallback(() => {
    if (!isUserReady) {
      return false;
    }

    const token = getToken();
    const isDub2FactorSet = dub2FASettings.isSet;
    const userRole = userState.payload.role;

    switch (true) {
      case isPasswordExpired && location.pathname !== CLIENT_PAGES.PASSWORD_RECOVERY:
        history.replace(CLIENT_PAGES.PASSWORD_RECOVERY);
        break;

      case userState.type === USER_NOT_LOADED && !isDub2FactorSet && !isNotNeedLogin:
        history.replace(CLIENT_PAGES.LOGIN, {requestedLocation: location}); // fix logic for 2FA
        break;

      case userState.type === USER_LOADED && !token:
        dispatch(logout()).then(() => {
          history.push(CLIENT_PAGES.LOGIN);
        });
        break;

      case userState.type === USER_LOADED && permissions && !isHavePermission(permissions, userRole):
        history.replace(CLIENT_PAGES.HOME);
        break;

      case !haveAccessLevel(userRole, accessLevels, accessLevel):
        history.replace(CLIENT_PAGES.HOME);
        break;

      default:
    }

    return true;
  }, [
    accessLevel,
    accessLevels,
    dispatch,
    dub2FASettings.isSet,
    history,
    isNotNeedLogin,
    isPasswordExpired,
    isUserReady,
    location,
    permissions,
    userState.payload.role,
    userState.type,
  ]);

  const checkAuthState = useCallback(() => {
    const {viewMode} = userState;

    if (viewMode.on || isTrackingUser) {
      return;
    }

    const storedUserId = getUserId();
    const userLogout = !storedUserId && user && user.id;
    const userLogin = storedUserId && (!user || !user.id || user.id !== +storedUserId);
    const shouldReload = userLogout || userLogin;

    if (shouldReload) {
      window.location.reload();
    }
  }, [isTrackingUser, user, userState]);

  const needToCheckPasswordExpiration = useCallback(() => {
    if (viewModeService.getViewModeStorageParam().on) {
      return false;
    }

    return !!(user?.id && user?.password_expiration_date);
  }, [user?.id, user?.password_expiration_date]);

  const getPasswordExpirationDays = useCallback(() => {
    return getDaysDiff(user.password_expiration_date);
  }, [user?.password_expiration_date]);

  const getPasswordExpirationMinutes = useCallback(() => {
    return getMinutesDiff(user.password_expiration_date);
  }, [user.password_expiration_date]);

  const checkPasswordExpiration = useCallback(() => {
    if (!needToCheckPasswordExpiration()) {
      return false;
    }

    const days = getPasswordExpirationDays();

    const timerId = timerIdRef.current;
    if (days < 1) {
      dispatch(expirePassword());
      return true;
    } else if (days === 1 && !timerId) {
      const minutes = getPasswordExpirationMinutes();
      const delay = minutes > 2 ? (minutes - 2) * 60 * 1000 : 0;
      timerIdRef.current = setTimeout(() => {
        dispatch(expirePassword());
        checkAccess();
      }, delay);
    } else if (days > 1 && timerId) {
      clearTimeout(timerId);
      timerIdRef.current = null;
    }

    return false;
  }, [checkAccess, dispatch, getPasswordExpirationDays, getPasswordExpirationMinutes, needToCheckPasswordExpiration]);

  useEffect(() => {
    checkAccess();
  }, [checkAccess]);

  useEffect(() => {
    checkPasswordExpiration();
  }, [checkPasswordExpiration]);

  useEffect(() => {
    window.addEventListener('visibilitychange', checkAuthState);

    return () => {
      window.removeEventListener('visibilitychange', checkAuthState);
    };
  }, [checkAuthState]);

  const renderPasswordExpirationNotification = () => {
    if (needToCheckPasswordExpiration()) {
      const passwordExpirationDays = getPasswordExpirationDays();

      if (passwordExpirationDays > 0 && passwordExpirationDays < 8) {
        return <PasswordExpirationNotification days={passwordExpirationDays} />;
      }
    }

    return null;
  };

  return (
    <>
      {!isProfilePage && !isViewModeActive && renderPasswordExpirationNotification()}
      {children}
    </>
  );
};
