export const createImplementation = () => ({
  type: 'CouponScenarioStep',
});

export const mapFormData = (values, initialData) => {
  const data = {
    id: initialData.id,
    name: values.name,
    scenario_id: initialData.scenario_id,
    implementation_type: initialData.implementation_type,
    position: initialData.position,
    comment: values.comment,
    implementation: {
      type: initialData.implementation.type,
    },
  };

  const implementation = {
    type: `${values.coupon.type}ScenarioStep`,
    coupon_id: values.coupon.id,
  };
  if (values.coupon.type === 'AppCoupon') {
    implementation.visual_type = values.visual_type;
    implementation.barcode_format = values.barcode_format;
    implementation.token_id_variable_id = values.token_id_variable_id;
    implementation.token_date_variable_id = values.token_date_variable_id;
  } else if (values.coupon.type === 'WeezioCoupon') {
    implementation.visual_type = values.visual_type;
    implementation.barcode_format = values.barcode_format;
  } else if (values.coupon.type === 'SogecCoupon') {
    implementation.city_id = values.city_variable_id;
    implementation.email_id = values.email_variable_id;
    implementation.first_name_id = values.first_name_variable_id;
    implementation.last_name_id = values.last_name_variable_id;
    implementation.zip_id = values.zip_variable_id;
    implementation.address1_id = values.address1_variable_id;
    implementation.address2_id = values.address2_variable_id;
    implementation.address3_id = values.address3_variable_id;
    implementation.address4_id = values.address4_variable_id;
  }

  data.implementation = implementation;

  return data;
};

export const getInitialValues = (step) => {
  return {
    name: step.name,
    coupon: step.implementation.coupon,
    visual_type: step.implementation.visual_type,
    barcode_format: step.implementation.barcode_format,
    comment: step.comment,
    token_id_variable_id: step.implementation.token_id_variable_id,
    token_date_variable_id: step.implementation.token_date_variable_id,
    city_variable_id: step.implementation.city_id,
    email_variable_id: step.implementation.email_id,
    first_name_variable_id: step.implementation.first_name_id,
    last_name_variable_id: step.implementation.last_name_id,
    zip_variable_id: step.implementation.zip_id,
    address1_variable_id: step.implementation.address1_id,
    address2_variable_id: step.implementation.address2_id,
    address3_variable_id: step.implementation.address3_id,
    address4_variable_id: step.implementation.address4_id,
  };
};

export const validate = (values, props) => {
  const {lang} = props;
  const errors = {};
  const isSogec = !!values.coupon && values.coupon.type === 'SogecCoupon';
  const isApp = !!values.coupon && values.coupon.type === 'AppCoupon';

  const requiredField = [
    'name',
    'coupon',
    !isSogec && 'visual_type',
    values.visual_type === 'barcode' && 'barcode_format',
    isApp && 'token_date_variable_id',
    isApp && 'token_id_variable_id',
    isSogec && 'first_name_variable_id',
    isSogec && 'last_name_variable_id',
    isSogec && 'email_variable_id',
    isSogec && 'city_variable_id',
    isSogec && 'zip_variable_id',
  ].filter(Boolean);

  requiredField.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = lang.ERRORS.REQUIRED;
    }
  });

  Object.entries(values).forEach(([fieldName, value]) => {
    if (fieldName.endsWith('variable_id')) {
      const hasSameValue = Object.entries(values).some(
        ([key, val]) => key !== fieldName && val && value && val === value,
      );
      if (hasSameValue) {
        errors[fieldName] = lang.ERRORS.UNIQUE_VARIABLES;
      }
    }
  });

  return errors;
};

export const submitForm = (values, dispatch, props) => {
  const mappedData = mapFormData(values, props.data);

  return props.onSubmitForm(mappedData);
};
