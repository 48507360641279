export const selectWinningsRegions = (state) => state.winnings.regions;

export const selectWinningsStores = (state) => state.winnings.stores;

export const selectWinningsDevices = (state) => state.winnings.devices;

export const selectWinningsCampaigns = (state) => state.winnings.campaigns;

export const selectWinnings = (state) => state.winnings.winnings;

export const selectNotWinnersData = (state) => state.winnings.notWinnersData;

export const selectWinner = (state) => state.winnings.winner;
