export const mapWinnerExportReport = (report) => {
  return {
    id: report.id,
    exportId: report.participation_export?.id,
    export: report.participation_export,
    name: report.participation_export?.name || '',
    comment: report.participation_export?.comment || '',
    total: report.total,
    date: report.created_at,
    userName: report.created_by,
    status: report.status,
    link: report.participations_file.url,
    allowed_for_all: report.participation_export?.allowed_for_all || false,
    deletable: report.deletable,
    shareable: report.shareable,
    fileName: `${report.participation_export?.name || ''}.${report.participation_export?.format || ''}`,
  };
};
