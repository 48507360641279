import React, {memo} from 'react';

import PropTypes from 'prop-types';

import DisalableCheckbox from 'client/components/common/controls/disalable-checkbox';
import Field from 'client/components/common/fields/field';

const DisalableCheckboxFieldComponent = (props) => {
  const {input, disabled} = props;

  return <DisalableCheckbox active={input.value || false} onChange={input.onChange} disabled={disabled} />;
};

DisalableCheckboxFieldComponent.propTypes = {
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

DisalableCheckboxFieldComponent.defaultProps = {
  disabled: false,
};

const DisalableCheckboxField = memo((props) => <Field {...props} />);

DisalableCheckboxField.propTypes = {
  ...Field.propTypes,
};

DisalableCheckboxField.defaultProps = {
  ...Field.defaultProps,
  component: DisalableCheckboxFieldComponent,
};

export default DisalableCheckboxField;
