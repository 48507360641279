import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, updateSyncWarnings} from 'redux-form';

import {addressTransform} from 'client/services/helpers';
import {required} from 'client/services/validator';

import {getClientsCheckSiren} from 'client/ducks/client-account/actions';
import {interpolate} from 'client/ducks/language/helpers';

import {TextField} from 'client/common/fields';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';

import './basic-information-form.scss';

class BasicInformationForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    languageState: PropTypes.object.isRequired,
    valid: PropTypes.bool.isRequired,
    edit: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    subsidiaries: PropTypes.array.isRequired,
    memberships: PropTypes.array.isRequired,
    adminUsers: PropTypes.array.isRequired,
    initialize: PropTypes.func.isRequired,
  };

  static formName = 'basicInformationForm';

  static splitAgencyAddress(agency) {
    let streets;
    let res = {
      ...agency.agency,
      street_address_0: '',
      street_address_1: '',
      street_address_2: '',
    };

    if (agency.agency && agency.agency.street_address) {
      streets = addressTransform.splitAddress(agency.agency.street_address);
      res = {
        ...agency.agency,
        street_address_0: streets[0],
        street_address_1: streets[1],
        street_address_2: streets[2],
      };
    }

    return res;
  }

  static async validateAsync({siren_number}, dispatch, props) {
    let errors = {};
    const langCommon = props.languageState.payload.COMMON;

    if (!siren_number) {
      dispatch(
        updateSyncWarnings(
          BasicInformationForm.formName,
          {
            siren_number: '',
          },
          '',
        ),
      );

      return Promise.resolve({});
    }
    if (siren_number?.toString().length !== 9) {
      errors.siren_number = langCommon.SIREN.SIREN_IS_NOT_VALID;
    } else {
      try {
        const response = await props.getClientsCheckSiren(siren_number);
        if (response?.payload?.real) {
          if (response?.payload.clients_count) {
            dispatch(
              updateSyncWarnings(
                BasicInformationForm.formName,
                {
                  siren_number: interpolate(langCommon.SIREN.SIREN_EXISTS?.toString(), {
                    siren: response?.payload.clients_count,
                  }),
                },
                '',
              ),
            );
          }
        } else {
          errors.siren_number = langCommon.SIREN.SIREN_DOES_NOT_EXIST;
          dispatch(
            updateSyncWarnings(
              BasicInformationForm.formName,
              {
                siren_number: '',
              },
              '',
            ),
          );
        }
      } catch {
        errors.siren_number = langCommon.SIREN.SIREN_CHECKING_SERVICE_DOES_NOT_RESPOND_TITLE;
        dispatch(
          updateSyncWarnings(
            BasicInformationForm.formName,
            {
              siren_number: '',
            },
            '',
          ),
        );
      }
    }

    if (Object.values(errors).length) {
      return Promise.reject(errors);
    }

    return Promise.resolve();
  }

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AGENCY;
    this.langCommon = props.languageState.payload.COMMON;

    this.rules = {
      name: required(this.LANGUAGE.NAME_REQUIRED_ERROR),
      brand_name: required(this.LANGUAGE.BRAND_NAME_REQUIRED_ERROR),
      subsidiary: required(this.LANGUAGE.SUBSIDIARY_REQUIRED_ERROR),
      country: required(this.LANGUAGE.REQUIRED),
    };
  }

  handleCancelClick = () => {
    this.props.reset();
    this.props.onCancel();
  };

  save = ({
    id,
    brand_name,
    city,
    comment,
    country,
    name,
    street_address_0,
    street_address_1,
    street_address_2,
    website,
    zip,
    poc_admin_user,
    poc_membership_id,
    subcategory,
    subsidiary,
    siren_number,
  }) => {
    const street_address = addressTransform.joinAddress([street_address_0, street_address_1, street_address_2]);

    this.props
      .onSave({
        agency: {
          id,
          brand_name,
          city,
          comment,
          country,
          name,
          street_address,
          website,
          zip,
          siren_number,
          poc_admin_user_id: poc_admin_user && poc_admin_user.id,
          poc_membership_id: poc_membership_id && poc_membership_id,
          subcategory_id: subcategory && subcategory.id,
          subsidiary_id: subsidiary && subsidiary.id,
        },
      })
      .then(() => this.props.initialize(this.props.initialValues));
  };

  render() {
    const {handleSubmit, valid, pristine, edit, formValues, subsidiaries, memberships, adminUsers} = this.props;

    let POCBuzzeoExists;
    let POCClientExists;
    let websiteExists;
    let commentExists;
    let zipExists;
    let cityExists;
    let countryExists;
    let streetAddressExists;
    let logo;

    if (formValues && formValues.values) {
      const {values: v} = formValues;
      POCBuzzeoExists = v.poc_admin_user_id;
      POCClientExists = v.poc_membership_id;
      websiteExists = v.website;
      commentExists = v.comment;
      zipExists = v.zip;
      cityExists = v.city;
      countryExists = v.country;
      streetAddressExists = v.street_address;
      logo = v.logo;
    }

    return (
      <div>
        <form
          className="basic-information-form basic-information-form--expand"
          noValidate="noValidate"
          onSubmit={handleSubmit(this.save)}
        >
          <CustomScrollbars
            scrollbarProps={{
              autoHeightMax: edit ? 247 : 300,
            }}
          >
            <div className="basic-information-form__inner">
              {!edit && logo && logo.url && (
                <div className="form-field-wrap">
                  <div className="fake-input__label">{this.LANGUAGE.LOGO_LABEL}</div>
                  <img src={logo.url} className="basic-information-form__logo" />
                </div>
              )}
              <div className="form-field-wrap">
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.AGENCY_NAME_LABEL}
                  name="name"
                  type="text"
                  component={fieldTemplate}
                  validate={this.rules.name}
                />
              </div>
              <div className="form-field-wrap">
                {edit ? (
                  <TextField label={this.langCommon.SIREN.SIREN_LABEL} name="siren_number" maxlength={9} />
                ) : (
                  <Field
                    cssModifier="input--disabled-view-1 input--view-3 input--ellipsis"
                    disabled
                    label={this.langCommon.SIREN.SIREN_LABEL}
                    name="siren_number"
                    type="text"
                    component={fieldTemplate}
                  />
                )}
              </div>
              <div className="form-field-wrap">
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.BRAND_NAME_LABEL}
                  name="brand_name"
                  type="text"
                  component={fieldTemplate}
                  validate={this.rules.brand_name}
                />
              </div>
              <div className="form-field-wrap">
                <Field
                  cssModifier="select--disabled-view-1 select--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.SUBSIDIARY_LABEL}
                  name="subsidiary"
                  labelKey="name"
                  valueKey="id"
                  type="select"
                  searchable={false}
                  options={subsidiaries}
                  component={fieldTemplate}
                  validate={this.rules.subsidiary}
                />
              </div>

              <div className={`form-field-wrap${!edit && !POCBuzzeoExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier={`select--disabled-view-${edit ? 1 : 2} select--view-3`}
                  disabled={!edit}
                  label={this.LANGUAGE.POC_BUZZEO_LABEL}
                  name="poc_admin_user"
                  labelKey="full_name"
                  valueKey="id"
                  type="select"
                  searchable={false}
                  options={adminUsers}
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${edit || !POCBuzzeoExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-2 input--no-min-height"
                  disabled
                  name="poc_buzzeo_email"
                  type="email"
                  component={fieldTemplate}
                />
                <Field
                  cssModifier="input--disabled-view-2 input--no-min-height"
                  disabled
                  name="poc_buzzeo_phone"
                  type="phone"
                  component={fieldTemplate}
                />
              </div>

              <div className={`form-field-wrap${!edit && !POCClientExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier={`select--disabled-view-${edit ? 1 : 2} select--view-3`}
                  disabled={!edit}
                  label={this.LANGUAGE.POC_CLIENT_LABEL}
                  name="poc_membership_id"
                  type="select"
                  searchable={true}
                  options={memberships.map((i) => ({value: i.id, label: i.client_user.full_name}))}
                  component={fieldTemplate}
                  simpleValue
                />
              </div>
              <div className={`form-field-wrap${edit || !POCClientExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-2 input--no-min-height"
                  disabled
                  name="poc_client_phone"
                  type="phone"
                  component={fieldTemplate}
                />
              </div>

              <div className={`form-field-wrap${!edit && !websiteExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.WEBSITE_LABEL}
                  name="website"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${!edit && !streetAddressExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.ADDRESS_LABEL}
                  name="street_address_0"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${!edit && !streetAddressExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.ADDRESS_LABEL}
                  name="street_address_1"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${!edit && !streetAddressExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.ADDRESS_LABEL}
                  name="street_address_2"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${!edit && !cityExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.CITY_LABEL}
                  name="city"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${!edit && !countryExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.COUNTRY_LABEL}
                  name="country"
                  type="text"
                  component={fieldTemplate}
                  validate={this.rules.country}
                />
              </div>
              <div className={`form-field-wrap${!edit && !zipExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.ZIP_LABEL}
                  name="zip"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${!edit && !commentExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.COMMENTS_LABEL}
                  name="comment"
                  type="textarea"
                  component={fieldTemplate}
                />
              </div>
            </div>
          </CustomScrollbars>

          {edit && (
            <div className="basic-information-form__bottom">
              <button className="button button--bg-11 basic-information-form__btn" onClick={this.handleCancelClick}>
                {this.LANGUAGE.CANCEL_BUTTON}
              </button>
              <button
                className="button button--bg-1 basic-information-form__btn"
                type="submit"
                disabled={!valid || pristine}
              >
                {this.LANGUAGE.SAVE_BUTTON}
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }
}

const basicInformationForm = reduxForm({
  form: BasicInformationForm.formName,
  enableReinitialize: true,
  asyncValidate: BasicInformationForm.validateAsync,
  asyncChangeFields: ['siren_number'],
})(BasicInformationForm);

export default connect(
  ({languageState, agency, form}) => ({
    languageState,
    initialValues: BasicInformationForm.splitAgencyAddress(agency),
    formValues: form[BasicInformationForm.formName],
  }),
  {
    getClientsCheckSiren,
  },
)(basicInformationForm);
