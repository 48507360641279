import {Lead} from 'client/models/lead/types';

import {PreferencesOptInFormValues} from './index';

export default (formValues: PreferencesOptInFormValues): {lead: Pick<Lead, 'opt_ins'>} => {
  return {
    lead: {
      opt_ins: Object.entries(formValues).reduce(
        (result, [id, value]) => ({
          ...result,
          [id.slice(1)]: value,
        }),
        {},
      ),
    },
  };
};
