import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getLead, anonymizeLead} from 'client/ducks/lead/actions';
import {selectLead} from 'client/ducks/lead/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import Icon from 'client/common/icon';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import TitleBlock from 'client/components/common/title-block';

import LeadHistories from './lead-histories/lead-histories';
import LeadInformation from './lead-information/lead-information';

class LeadsProfile extends Component {
  static propTypes = {
    langBC: PropTypes.object.isRequired,
    lang: PropTypes.object.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    anonymizeLead: PropTypes.func.isRequired,
    getLead: PropTypes.func.isRequired,
    leadId: PropTypes.string.isRequired,
    lead: PropTypes.object.isRequired,
    urlParams: PropTypes.object.isRequired,
    ...withRouter.propTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      showDeleteModal: false,
    };
  }

  componentDidMount() {
    this.props
      .getLead(this.props.leadId, {
        include: 'client.agency',
      })
      .then(() => {
        const {
          lead: {...lead},
        } = this.props;

        return this.props.setBreadcrumbs([
          {
            name: this.props.langBC.LEADS,
            href: CLIENT_PAGES.LEADS_LIST,
          },
          {
            name: this.getLeadName(lead),
          },
        ]);
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lead.error !== this.props.lead.error || prevProps.lead.status !== this.props.lead.status) {
      if (this.props.lead.error && this.props.lead.status === 404) {
        this.props.history.push(CLIENT_PAGES.ERROR_404_URL);
      }
    }
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  getLeadName(lead) {
    const {id, first_name, last_name} = lead;

    if (!first_name && !last_name) {
      return `${id}`;
    }

    return [first_name, last_name].filter((i) => i).join(' ');
  }

  anonymizeLead = (leadID) => {
    this.props.anonymizeLead(leadID).then(() => {
      this.props.history.push(CLIENT_PAGES.LEADS_LIST);
    });
  };

  toggleModalDeleteLead = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
    });
  };

  render() {
    let {lead, lang} = this.props;
    const message = lang.DELETE_LEAD_MODAL_MESSAGE
      ? lang.DELETE_LEAD_MODAL_MESSAGE.replace(/\[Name]/, this.getLeadName(lead))
      : '';

    return (
      <div className="theme-color-2">
        <ConfirmationModal
          show={this.state.showDeleteModal}
          title={lang.DELETE_LEAD_MODAL_TITLE}
          className="theme-color-2"
          buttonConfirmClass="button--bg-2"
          onClose={this.toggleModalDeleteLead}
          onCancel={this.toggleModalDeleteLead}
          onConfirm={() => this.anonymizeLead(lead.id)}
          message={message}
          cancelText={lang.DELETE_LEAD_MODAL_CANCEL_BUTTON}
          confirmText={lang.DELETE_LEAD_MODAL_CONFIRM_BUTTON}
        />
        <div className="clearfix">
          <div className="page__title-block pull-left">
            <TitleBlock theme>
              <TitleBlock.Item>{`${lang.TITLE}  ${this.props.leadId}`}</TitleBlock.Item>
            </TitleBlock>
          </div>
          <div className="pull-right">
            <button
              className="button button--circle button--bg-2 vertical-align-top"
              onClick={this.toggleModalDeleteLead}
            >
              <Icon name="trash" className="button__icon" width={17} height={19} />
            </button>
          </div>
        </div>
        <LeadInformation leadId={this.props.leadId} />
        <LeadHistories leadId={this.props.leadId} urlParams={this.props.urlParams} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.languageState.payload.LEADS_PROFILE,
    langBC: state.languageState.payload.BREADCRUMBS,
    lead: selectLead(state),
  }),
  {
    setBreadcrumbs,
    clearBreadcrumbs,
    getLead,
    anonymizeLead,
  },
)(withRouter(LeadsProfile));
