import React from 'react';

import placeholder from 'assets/icons/colored/pic-placeholder.svg';

import bem from 'client/services/bem';

import ImageContainer from 'client/common/image-container';
import {CheckboxInput} from 'client/common/inputs';

import cssModule from './image-select.module.scss';

type Image = {
  id: string;
  image: string;
  label: string;
};

type ImageSelectProps = {
  width?: number | string;
  height?: number | string;
  value: Image | null;
  checked?: boolean;
  disabled?: boolean;
  onChange: (value: Image) => void;
};

const b = bem('image-select', {cssModule});

const ImageSelect: React.FC<ImageSelectProps> = ({
  width = 86,
  height = 86,
  value,
  checked = false,
  disabled = false,
  onChange,
}) => {
  const handleClick = () => {
    if (value?.id) {
      onChange(value);
    }
  };

  return (
    <button className={b()} disabled={disabled} onClick={handleClick}>
      <ImageContainer
        className={b({reversed: true})}
        classNamePreview={b('image')}
        label={value?.label}
        width={width}
        height={height}
        value={value?.image || (placeholder as unknown as string)} // setting the type of placeholder as a string by first casting it to unknown and then to string.
      />
      {/* note: this is a customer request to remove value?.image */}
      {value && <CheckboxInput color="primary" checked={checked} inversionColor={true} className={b('checkbox')} />}
    </button>
  );
};

export default ImageSelect;
