import React from 'react';

import PropTypes from 'prop-types';

import Field from '../field';

const CheckboxField = (props) => <Field {...props} />;

CheckboxField.propTypes = {
  ...Field.propTypes,
  type: PropTypes.string,
};

CheckboxField.defaultProps = {
  ...Field.defaultProps,
  type: 'checkbox',
  cssModifier: 'checkbox-field',
};

export default CheckboxField;
