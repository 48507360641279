import {generateColor} from 'client/services/helpers';

import {pieCartColors, statusesChartColors} from 'client/ducks/results-graphs/constants';

const getColor = (key, index) => {
  if (Object.keys(statusesChartColors).includes(key)) {
    return statusesChartColors[key];
  }

  return pieCartColors[index] || generateColor();
};

export const getDonutChartData = (values, lang) => {
  const chartData = Object.entries(values).map(([key, val], index) => ({
    label: lang[key] || key,
    value: Array.isArray(val) ? val[0] : val,
    secondValue: Array.isArray(val) ? val[1] ?? 0 : null,
    color: getColor(key, index),
  }));

  const hasSecondValue =
    chartData.some(({secondValue}) => secondValue?.toString()) &&
    !!chartData.reduce((acc, {secondValue}) => acc + secondValue, 0);

  return {
    chartData: hasSecondValue ? chartData : chartData.map((item) => ({...item, secondValue: null})),
    hasSecondValue,
  };
};
