import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import cssModule from './dnd-file-input.module.scss';

const b = bem('dnd-file-input', {cssModule});

type DndFileInputProps = {
  className?: string;
  children: React.ReactNode;
  onChange: (file: File) => void;
};

const DndFileInput: React.FC<DndFileInputProps> = (props) => {
  const {className, children, onChange} = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isDragActive, setIsDragActive] = React.useState(false);

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true);
    } else if (e.type === 'dragleave') {
      setIsDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
    const file = e.dataTransfer?.files?.[0];
    if (file) {
      onChange(file);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (file) {
      onChange(file);
    }
  };

  const onClick = () => {
    inputRef?.current?.click();
  };

  return (
    <div className={cn(b(), className)} onDragEnter={handleDrag} onClick={onClick}>
      <input className={b('input')} ref={inputRef} type="file" onChange={handleInputChange} accept="image/*" />
      {isDragActive && (
        <div
          className={b('drop-area')}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
      {children}
    </div>
  );
};

export default DndFileInput;
