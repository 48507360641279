import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {SelectField} from 'client/common/fields';

const years = (langTimeUnits) =>
  Array(11)
    .fill(0)
    .map((_, index) => ({
      label: `${index} ${langTimeUnits.YEARS}`,
      value: `${index}`,
    }));

const months = (langTimeUnits) =>
  Array(12)
    .fill(0)
    .map((_, index) => ({
      label: `${index} ${langTimeUnits.MONTHS}`,
      value: `${index}`,
    }));

function getOptions(unit, langTimeUnits) {
  switch (unit) {
    case 'year':
      return years(langTimeUnits || {});
    case 'month':
      return months(langTimeUnits || {});
    default:
      return [];
  }
}

export const getValue = (unit, val, langTimeUnits) => {
  if (val) {
    const option = getOptions(unit, langTimeUnits).find(({value}) => value === val);
    return option ? option.label : '';
  }
  return val ? getOptions(unit, langTimeUnits).find(({value}) => value === val).label : '';
};

function DataRetentionField({unit, langTimeUnits, ...rest}) {
  return <SelectField simpleValue searchable={false} options={getOptions(unit, langTimeUnits)} {...rest} />;
}

DataRetentionField.propTypes = {
  unit: PropTypes.oneOf(['year', 'month']).isRequired,
  langTimeUnits: PropTypes.object.isRequired,
};

export default connect(({languageState}) => ({
  langTimeUnits: languageState.payload.COMMON.TIME_UNITS,
}))(DataRetentionField);
