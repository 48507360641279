import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import types from './types';

export function getWinnerExportReports(queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.WINNER_EXPORT_REPORTS}`,
        method: 'GET',
        noCache: true,
        types: [
          types.GET_WINNER_EXPORT_REPORTS_REQUEST,
          types.GET_WINNER_EXPORT_REPORTS_SUCCESS,
          types.GET_WINNER_EXPORT_REPORTS_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}
export function getWinnerExportReport(id, queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.WINNER_EXPORT_REPORTS}/${id}`,
        method: 'GET',
        noCache: true,
        types: [
          types.GET_WINNER_EXPORT_REPORT_REQUEST,
          types.GET_WINNER_EXPORT_REPORT_SUCCESS,
          types.GET_WINNER_EXPORT_REPORT_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}

export const shareWinnerExport =
  ({id, queryParams} = {}) =>
  (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.FILE_WINNER_EXPORT}/${id}/manage_sharing`,
      method: 'POST',
      types: [types.SHARE_WINNER_EXPORT_REQUEST, types.SHARE_WINNER_EXPORT_SUCCESS, types.SHARE_WINNER_EXPORT_ERROR],
      queryParams,
    });

export const deleteFileWinnerExport = (id) => {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.FILE_WINNER_EXPORT}/${id}`,
      method: 'DELETE',
      noCache: true,
      types: [
        types.DELETE_FILE_WINNER_EXPORT_REQUEST,
        types.DELETE_FILE_WINNER_EXPORT_SUCCESS,
        types.DELETE_FILE_WINNER_EXPORT_ERROR,
      ],
    });
  };
};

export function createWinnerExportFile(body, queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.FILE_WINNER_EXPORT}`,
        method: 'POST',
        types: [types.FILE_WINNER_EXPORT_REQUEST, types.FILE_WINNER_EXPORT_SUCCESS, types.FILE_WINNER_EXPORT_ERROR],
        body,
        queryParams,
      },
      true,
    );
  };
}

export function performWinnerExportFile(id, queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.FILE_WINNER_EXPORT}/${id}/perform`,
        method: 'GET',
        types: [
          types.PERFORM_FILE_WINNER_EXPORT_REQUEST,
          types.PERFORM_FILE_WINNER_EXPORT_SUCCESS,
          types.PERFORM_FILE_WINNER_EXPORT_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}
