import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {TranslationJsx} from 'client/models/language/types';

import './auth-message.scss';

const b = bem('auth-message');

const AuthMessage = ({text, highlight, description}) => {
  return <span className={b({highlight, description})}>{text}</span>;
};

AuthMessage.propTypes = {
  text: TranslationJsx.isRequired,
  highlight: PropTypes.bool,
  description: PropTypes.bool,
};

AuthMessage.defaultProps = {
  highlight: false,
  description: false,
};

export default AuthMessage;
