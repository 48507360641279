import {uid} from 'client/services/helpers';

import {NEW_OPT_IN_ID_PREFIX} from './constants';

export default (values, interfaceId) => {
  const mappedOptIns = values.optIns.map((optIn) => {
    const isNewOptIn = String(optIn.id).startsWith(NEW_OPT_IN_ID_PREFIX);

    const mappedOptIn = {
      ...optIn,
      id: isNewOptIn ? null : optIn.id, // drop custom id of new optins
      column_adapter: null, // drop column adapter
      interface_item: {
        ...optIn.interface_item,
        ...(isNewOptIn ? {name: uid('OptInColumn'), interface_id: interfaceId, kind: 'boolean'} : {}),
        readable_name: optIn.readable_name || optIn.column_adapter?.name,
      },
    };

    if (optIn.opt_in_text_translations) {
      Object.entries(optIn.opt_in_text_translations).forEach(([lang, text]) => {
        mappedOptIn[`opt_in_text_${lang}`] = text;
      });
    }

    return mappedOptIn;
  });

  return {opt_in_mapping_item: mappedOptIns};
};
