import isEmpty from 'lodash/isEmpty';

import {mapParticipationExportReport, mapMembership} from './mappers';

export function getAvailableExportColumns(state) {
  return state.participationsExport.availableExportColumns;
}

export function getExportColumnsItemsSelector(state) {
  return state.participationsExport.exportColumnItems;
}

export function getExportReportSelector(state) {
  const report = state.participationsExport.exportReport;
  return isEmpty(report) ? null : mapParticipationExportReport(report);
}

export function selectParticipationExportReports(state) {
  return state.participationsExport.exportReports;
}
export function selectParticipationExportReport(state) {
  return state.participationsExport.exportReport
    ? {
        ...state.participationsExport.exportReport,
        transfer_id: state.participationsExport.exportReport?.participation_export_id,
      }
    : null;
}

export function selectParticipationFileExportHistory(state) {
  return state.participationsExport.fileExportHistory;
}

export function selectParticipationApiExportHistory(state) {
  return state.participationsExport.apiExportHistory;
}

export function getParticipationExportAPIReportsTableDataSelector(state) {
  return state.participationsExport.exportAPIReports.map((report) => {
    return {
      format: 'EXAPI',
      endDate: report.to,
      nullStr: '',
      APITools: {
        comment: report.comment || '',
        id: report.id,
        from: report.from,
        to: report.to,
        memberships: report.memberships.map((item) => mapMembership(item, report)),
        allowed_for_all: report.allowed_for_all,
      },
    };
  });
}

export function getParticipationExportAPIReportsSelector(state) {
  return state.participationsExport.exportAPIReports.map((report) => {
    return {
      ...report,
      memberships: report.memberships.map((item) => mapMembership(item, report)),
    };
  });
}

export function getParticipationExportNum(state) {
  return state.autotask.payload.participations_count || 0;
}
