import React, {Component} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {connect} from 'react-redux';
import rehypeRaw from 'rehype-raw';

import {getPrivacyPolicies} from 'client/ducks/privacy-policies/actions';
import {getMyPrivacyPolicy} from 'client/ducks/privacy-policies/selectors';
import {selectUser, selectIsUserLoaded} from 'client/ducks/user/selectors';

import {UNITY_KINDS_TIME_FORMAT} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.LANGUAGE = props.languageState.payload.PRIVACY_POLICY;
  }

  componentDidMount() {
    const title = this.LANGUAGE.PAGE_TITLE;
    this.props.setMetaAction({title});

    this.getPrivacyPolicy();
  }

  getPrivacyPolicy = () => {
    const queryParams = {
      q: {
        s: 'created_at desc',
      },
    };
    this.props.getPrivacyPolicies(queryParams);
  };

  render() {
    const {privacyPolicy, user, isUserLoaded} = this.props;
    return (
      <div className="privacy-policy-accept__container">
        <ReactMarkdown escapeHtml={false} rehypePlugins={[rehypeRaw]}>
          {privacyPolicy ? privacyPolicy.text : '...'}
        </ReactMarkdown>
        {isUserLoaded && (
          <span>
            {`${this.LANGUAGE.ACCEPT_DATE} ${moment(user.privacy_policy_accepted_at).format(
              UNITY_KINDS_TIME_FORMAT.DAYS.LONG,
            )}`}
          </span>
        )}
      </div>
    );
  }
}

PrivacyPolicy.propTypes = {
  getPrivacyPolicies: PropTypes.func.isRequired,
  privacyPolicy: PropTypes.object,
  languageState: PropTypes.object,
  user: PropTypes.object,
  setMetaAction: PropTypes.func.isRequired,
  isUserLoaded: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  privacyPolicy: getMyPrivacyPolicy(state),
  languageState: state.languageState,
  user: selectUser(state),
  isUserLoaded: selectIsUserLoaded(state),
});

const mapDispatchToProps = {
  getPrivacyPolicies,
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
