import moment from 'moment';

import {Device} from 'client/models/devices/types';
import {Operation} from 'client/models/operations/types';

export const STATUS_ICONS = {
  ok: 'status-success',
  error: 'status-error-2',
  maintenance: 'status-under-construction',
  not_activated: 'status-not-installed',
};

export const getCurrentInteraction = (device: Device, operation: Operation) => {
  return device?.interactions
    ?.sort((a, b) => {
      if (a.from < b.from) {
        return -1;
      }
      return 0;
    })
    .find(
      (interaction) =>
        interaction.operation?.id === operation?.id && interaction.interface_id === operation?.client_interface?.id,
    );
};

export const getNearestInteraction = (device: Device, operation: Operation) => {
  return device?.interactions
    ?.sort((a, b) => {
      if (a.from < b.from) {
        return -1;
      }
      return 0;
    })
    .find((interaction) => {
      const fromIsBefore = moment(interaction.from).isSameOrBefore(operation.to);
      const fromIsAfter = moment(interaction.from).isSameOrAfter(operation.from);
      const toIsBefore = moment(interaction.to).isSameOrBefore(operation.to);
      const toIsAfter = moment(interaction.to).isSameOrAfter(operation.from);

      return (fromIsBefore && fromIsAfter) || (toIsBefore && toIsAfter) || (toIsAfter && fromIsBefore);
    });
};
