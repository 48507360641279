import React, {Component} from 'react';

import PropTypes from 'prop-types';

import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import {TranslationJsx} from 'client/models/language/types';

import '../../vis-settings-popover.scss';

class VisSettingsPopoverBase extends Component {
  static defaultProps = {
    children: null,
    overlayContent: null,
    onEdit: () => {},
    onDelete: () => {},
    messageText: '',
  };

  static propTypes = {
    readOnly: PropTypes.any,
    children: PropTypes.any,
    overlayContent: PropTypes.any,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    messageText: TranslationJsx,
    title: TranslationJsx,
  };

  render() {
    const {overlayContent, children, messageText, onDelete, onEdit, title, readOnly} = this.props;

    return (
      <Popover
        position="bottom"
        arrowOffset={90}
        overlayInnerStyle={{
          width: 340,
        }}
        overlay={
          <div className="popover-list theme-color-13 vis-settings-popover">
            <h3 className="popover-list__title flex-container flex-align-center flex-justify-between">
              {title}
              {!readOnly && (
                <button className="button button--circle button--bg-8" onClick={onEdit}>
                  <Icon name="edit" className="button__icon" width={20} height={20} />
                </button>
              )}
            </h3>
            <div className="vis-settings-popover__content">
              {overlayContent}
              {!readOnly && (
                <div className="flex-container flex-justify-between flex-align-center">
                  <div className="message-error-text">{messageText}</div>
                  <button className="button button--circle button--bg-8" onClick={onDelete}>
                    <Icon name="trash" className="button__icon" width={17} height={19} />
                  </button>
                </div>
              )}
            </div>
          </div>
        }
      >
        {children}
      </Popover>
    );
  }
}

export default VisSettingsPopoverBase;
