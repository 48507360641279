import types from './types';

const initialState = {
  direct_access: [],
  interfaces: [],
  campaigns: [],
  sources: [],
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case types.GET_DIRECT_ACCESS_STATISTICS_SUCCESS:
      return {
        ...state,
        direct_access: [...payload.interfaces],
      };

    case types.GET_INTERFACES_STATISTICS_SUCCESS:
      return {
        ...state,
        interfaces: [...payload.interfaces],
      };

    case types.GET_CAMPAIGNS_STATISTICS_SUCCESS:
      return {
        ...state,
        campaigns: [...payload.campaigns],
      };

    case types.GET_CAMPAIGN_SOURCES_STATISTICS_SUCCESS:
      return {
        ...state,
        sources: [...payload.campaigns],
      };

    default:
      return state;
  }
};
