import React, {Component} from 'react';

import findIndex from 'lodash/findIndex';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {deleteBroadcastDisplayItems} from 'client/ducks/broadcast/actions';
import {getBroadcastDisplayItems} from 'client/ducks/broadcast/actions';
import {selectBroadcastDisplayItems} from 'client/ducks/broadcast/selectors';
import {selectAllCustomColumns} from 'client/ducks/custom-columns/selectors';
import {getInternalColumns} from 'client/ducks/internal-columns/actions';
import {selectColumnAdapters} from 'client/ducks/internal-columns/selectors';
import {getColumnVisibilityItems} from 'client/ducks/lead-display-items/actions';
import {selectColumnVisibilityItems} from 'client/ducks/lead-display-items/selectors';
import {updateUser} from 'client/ducks/users/actions';

import {DISPLAY_ITEM_TYPES, COLUMN_ADAPTER_TYPES} from 'client/common/config';
import Modal from 'client/common/modals/modal';
import Popover from 'client/common/popovers/popover';

import Checkbox from 'client/components/common/checkbox';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';
import Rollout from 'client/components/common/rollout';
import SearchField from 'client/components/common/search-field';

class BCTableSettingsModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    internalColumns: PropTypes.array.isRequired,
    columnVisibilityItems: PropTypes.array.isRequired,
    customColumns: PropTypes.array.isRequired,
    displayItems: PropTypes.array.isRequired,
    userId: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    languageState: PropTypes.object.isRequired,
    deleteBroadcastDisplayItems: PropTypes.func.isRequired,
    getColumnVisibilityItems: PropTypes.func.isRequired,
    getBroadcastDisplayItems: PropTypes.func.isRequired,
    getInternalColumns: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
  };

  static defaultProps = {
    show: false,
  };

  static directionsMap = {
    up: 'up',
    down: 'down',
  };

  static columnKinds = {
    general: 'generalCols',
    custom: 'customCols',
    displayed: 'displayedColumns',
  };

  static checkboxGroups = {
    general: 'general',
    custom: 'custom',
    all: 'all',
  };

  static sortingParam = 'position';

  static excludedColumns = {
    email: 'email',
    participationsCount: 'participations_count',
  };

  constructor(props) {
    super(props);

    const langPayload = props.languageState.payload;

    this.LANGUAGE = {
      TABLE: langPayload.BROADCASTING_TASK.MAILING.TABLE,
      MODAL: langPayload.BROADCASTING_TASK.TABLE_SETTINGS_MODAL,
    };

    this.generalCols = [
      {
        locale: this.LANGUAGE.TABLE.FIRST_NAME_COLUMN,
        adapter: ['first_name'],
        name: 'firstName',
      },
      {
        locale: this.LANGUAGE.TABLE.LAST_NAME_COLUMN,
        adapter: ['last_name'],
        name: 'lastName',
      },
      {
        locale: this.LANGUAGE.TABLE.GENDER_COLUMN,
        adapter: ['gender'],
        name: 'gender',
      },
      {
        locale: this.LANGUAGE.TABLE.AGE_COLUMN,
        adapter: ['birth_date'],
        name: 'age',
      },
      {
        locale: this.LANGUAGE.TABLE.CONTACTS_COLUMN,
        adapter: ['phone', BCTableSettingsModal.excludedColumns.email],
        name: 'contacts',
      },
      {
        locale: this.LANGUAGE.TABLE.PARTICIPATIONS_COLUMN,
        adapter: ['last_participation', BCTableSettingsModal.excludedColumns.participationsCount],
        name: 'participations',
      },
      {
        locale: this.LANGUAGE.TABLE.VISUALS_COLUMN,
        adapter: ['visuals_count'],
        name: 'visuals',
      },
      {
        locale: this.LANGUAGE.TABLE.CREATED_COLUMN,
        adapter: ['created_at'],
        name: 'created',
      },
      {
        locale: this.LANGUAGE.TABLE.SOURCE_COLUMN,
        adapter: ['first_source'],
        name: 'source',
      },
      {
        locale: this.LANGUAGE.TABLE.EXPORTED_COLUMN,
        adapter: ['last_export'],
        name: 'exported',
      },
      {
        locale: this.LANGUAGE.TABLE.OPTINS_COLUMN,
        adapter: ['opt_ins'],
        name: 'optIns',
      },
    ];

    this.state = {
      generalCols: this.generalCols,
      customCols: [],
      displayedColumns: [],
      enabledSwitcher: false,
      enabledArrowUp: false,
      enabledArrowDown: false,
      allColumnSearch: '',
      displayColumnSearch: '',
      checkedAllColumns: false,
      checkedGeneralColumns: false,
      checkedCustomColumns: false,
      accordionEnabled: true,
    };

    this.deleteFlag = false;
  }

  componentDidMount() {
    this.getInternalColumns();
  }

  componentDidUpdate(prevProps) {
    const {customColumns, displayItems} = this.props;

    if (displayItems !== prevProps.displayItems || customColumns !== prevProps.customColumns) {
      const displayedColumns = this.mapColumns(displayItems);
      const hiddenColumns = this.hideDisplayedColumns(displayedColumns, this.state.generalCols, customColumns);
      this.setState({displayedColumns, ...hiddenColumns});
    }
  }

  getBroadcastDisplayItems = () => {
    const params = {
      q: {
        user_id_eq: this.props.userId,
      },
      include: 'column_adapter',
    };

    return this.props.getBroadcastDisplayItems(params);
  };

  getInternalColumns = () => {
    const params = {
      q: {
        record_type_eq: 'Lead',
      },
    };

    return this.props.getInternalColumns(params);
  };

  setColumnsToState = (columns, checked) => columns.map((el) => ({...el, checked}));

  setColumnsToStateByName = (columns, name, checked) => columns.map((c) => (c.name === name ? {...c, checked} : c));

  getVisibleColumnsState = (columns) => columns.filter((item) => !item.hidden).every((item) => item.checked);

  getAdapterId(item) {
    const columns = item.adapter ? this.props.internalColumns : this.props.customColumns;

    return columns.reduce((acc, column) => {
      if (item.adapter) {
        return item.adapter[0] === column.name ? [column.id, ...acc] : acc;
      }

      return column.name === item.name ? [column.external_column_adapter.id, ...acc] : acc;
    }, []);
  }
  checkArrows = (changedCols) => {
    return {
      enabledArrowUp: this.isColumnOn(changedCols) ? !changedCols[0].checked : false,
      enabledArrowDown: this.isColumnOn(changedCols) ? !changedCols[changedCols.length - 1].checked : false,
    };
  };

  handleDisplayedColumnCheck = (name) => (e) => {
    const {
      target: {checked},
    } = e;
    const displayedColumns = this.setColumnsToStateByName(this.state.displayedColumns, name, checked);

    this.setState({
      displayedColumns,
      ...this.checkArrows(displayedColumns),
    });
  };

  addCheckedItems = (displayedColumns, checkedNames) => {
    const newDisplayedColumns = this.state.displayedColumns.map((item) => {
      return {
        ...item,
        checked: checkedNames.includes(item.column_adapter.name),
      };
    });

    return {
      displayedColumns: newDisplayedColumns,
      ...this.checkArrows(newDisplayedColumns),
    };
  };

  getRemovalList = (displayedColumns) => {
    return displayedColumns.reduce((prev, item) => {
      const newData = item.idList.map((id, index) => {
        return {
          id,
          position: item.position + index,
          _destroy: true,
        };
      });

      return prev.concat(newData);
    }, []);
  };

  getDisplayList = (displayedColumns, direction) => {
    const methods = {
      up: (array, immutable = true) => (immutable ? array.slice() : array),
      down: (array, immutable = true) => (immutable ? array.slice().reverse() : array.reverse()),
    };

    let displayList = methods[direction](displayedColumns);

    displayList.forEach((item, i) => {
      if (!displayList[i].checked) {
        return;
      }
      let saved;
      saved = displayList[i - 1];
      displayList[i - 1] = displayList[i];
      displayList[i] = saved;
    });

    return this.createDisplayedItemsBodyRequest(methods[direction](displayList, false));
  };

  moveColumns = (direction) => () => {
    if (
      !direction &&
      (direction !== BCTableSettingsModal.directionsMap.down || direction !== BCTableSettingsModal.directionsMap.up)
    ) {
      return;
    }
    const {displayedColumns} = this.state;
    const removalList = this.getRemovalList(displayedColumns);
    const displayList = this.getDisplayList(displayedColumns, direction);
    const checkedNames = displayedColumns.reduce((prev, item) => {
      return item.checked ? prev.concat(item.column_adapter.name) : prev;
    }, []);

    const body = {
      user: {
        display_items: [...removalList, ...displayList],
      },
    };

    this.updateUser(body)
      .then(this.getBroadcastDisplayItems)
      .then(() => {
        this.setState({
          ...this.addCheckedItems(displayedColumns, checkedNames),
        });
      });
  };

  getColumns = (columns) => {
    return columns.map((item) => {
      const {column_adapter, adapter, id, position} = item;
      let columnName = column_adapter.name;
      let name = column_adapter.name;
      let newAdapter = adapter;

      if (column_adapter.type !== COLUMN_ADAPTER_TYPES.EXTERNAL) {
        const isEmail = column_adapter.name === BCTableSettingsModal.excludedColumns.email;
        const isParticipationCount = column_adapter.name === BCTableSettingsModal.excludedColumns.participationsCount;
        const generalColumn = this.generalCols.find(
          (col) => col.adapter[isEmail || isParticipationCount ? 1 : 0] === column_adapter.name,
        );

        name = generalColumn.name;
        columnName = generalColumn.locale;
        newAdapter = [column_adapter.name];
      }

      return {id, position, column_adapter, columnName, adapter: newAdapter, name, idList: [id]};
    });
  };

  reduceColumns = (columns) => {
    return columns.reduce((acc, current) => {
      let newItem = current;
      let newAcc = acc;

      if (
        current.column_adapter.name === BCTableSettingsModal.excludedColumns.participationsCount ||
        current.column_adapter.name === BCTableSettingsModal.excludedColumns.email
      ) {
        newItem = {
          ...acc[acc.length - 1],
          // adapter: [...acc[acc.length - 1].adapter, ...current.adapter],
          // idList: [...acc[acc.length - 1].idList, ...current.idList]
        };
        newAcc = acc.slice(0, -1);
      }

      return [...newAcc, newItem];
    }, []);
  };

  mapColumns = (columns) => {
    if (!columns && !columns.length) {
      return [];
    }

    const displayedColumnsList = this.getColumns(columns);
    const reducedColumns = this.reduceColumns(displayedColumnsList);
    return sortBy(reducedColumns, BCTableSettingsModal.sortingParam);
  };

  hideColumns = (cols, names) => {
    return cols.map((col) => {
      const name = col.external_column_adapter ? col.external_column_adapter.name : col.adapter[0];
      return {
        ...col,
        hidden: names.includes(name) || col.hidden,
      };
    });
  };

  hideDisplayedColumns = (displayedColumns, generalColumns, customColumns) => {
    const names = displayedColumns.map((displayedColumn) => {
      const {
        column_adapter: {type},
      } = displayedColumn;
      return type === COLUMN_ADAPTER_TYPES.EXTERNAL ? displayedColumn.column_adapter.name : displayedColumn.adapter[0];
    });

    const generalCols = this.hideColumns(generalColumns, names);
    const customCols = this.hideColumns(customColumns, names);

    return {generalCols, customCols};
  };

  searchColumn = (searchField, field) => {
    const regExp = new RegExp(searchField, 'i');

    if (!searchField) {
      return field && field === BCTableSettingsModal.columnKinds.displayed
        ? this.clearDisplayedColumn()
        : this.clearAllColumns();
    }

    const newColumns = this.state[field].filter((item) => regExp.test(item.name));
    return this.setState({[field]: newColumns});
  };

  searchDisplayedColumn = (value) => {
    this.searchColumn(value, BCTableSettingsModal.columnKinds.displayed);
    this.setState({displayColumnSearch: value});
  };

  clearDisplayedColumn = () => {
    this.setState({
      displayColumnSearch: '',
      displayedColumns: this.mapColumns(this.props.displayItems),
    });
  };

  searchAllColumns = (value) => {
    this.searchColumn(value, BCTableSettingsModal.columnKinds.general);
    this.searchColumn(value, BCTableSettingsModal.columnKinds.custom);
    this.setState({allColumnSearch: value, accordionEnabled: true});
  };

  clearAllColumns = () => {
    this.setState({
      allColumnSearch: '',
      generalCols: this.generalCols,
      customCols: this.props.customColumns,
    });
  };

  deleteDisplayedColumn = (itemName) => {
    const {displayedColumns} = this.state;
    const deletedIndex = findIndex(displayedColumns, ['name', itemName]);

    const columnsKind = !displayedColumns[deletedIndex].adapter
      ? BCTableSettingsModal.columnKinds.custom
      : BCTableSettingsModal.columnKinds.general;
    const ids = displayedColumns[deletedIndex].idList;

    if (ids.length) {
      this.props.deleteBroadcastDisplayItems(ids).then(() => {
        const displayIndex = findIndex(this.state[columnsKind], ['name', itemName]);

        this.setState({
          [columnsKind]: this.state[columnsKind].map((col, index) => {
            return displayIndex === index ? {...col, hidden: false, checked: false} : col;
          }),
          enabledArrowUp: false,
          enabledArrowDown: false,
        });
      });
    }
  };

  toggleGroupControl =
    (type = '') =>
    ({target: {checked}}) => {
      let general;
      let custom;
      let newState = {};

      switch (type) {
        case BCTableSettingsModal.checkboxGroups.all:
          general = this.setColumnsToState(this.state.generalCols, checked);
          custom = this.setColumnsToState(this.state.customCols, checked);
          newState = {
            generalCols: general,
            customCols: custom,
            checkedAllColumns: checked,
            checkedGeneralColumns: checked,
            checkedCustomColumns: checked,
            enabledSwitcher: this.toggleSwitcher(general, custom),
          };
          break;
        case BCTableSettingsModal.checkboxGroups.general:
          general = this.setColumnsToState(this.state.generalCols, checked);
          newState = {
            generalCols: general,
            checkedGeneralColumns: checked,
            checkedAllColumns: this.state.checkedCustomColumns && checked,
            enabledSwitcher: this.toggleSwitcher(this.state.customCols, general),
          };
          break;
        case BCTableSettingsModal.checkboxGroups.custom:
          custom = this.setColumnsToState(this.state.customCols, checked);
          newState = {
            customCols: custom,
            checkedCustomColumns: checked,
            checkedAllColumns: this.state.checkedGeneralColumns && checked,
            enabledSwitcher: this.toggleSwitcher(this.state.generalCols, custom),
          };
          break;
        default:
      }

      this.setState(newState);
    };

  handleColumnCheck =
    (name, type = '') =>
    (e) => {
      const {
        target: {checked},
      } = e;
      let newState = {};

      switch (type) {
        case BCTableSettingsModal.columnKinds.general:
          const generalCols = this.setColumnsToStateByName(this.state.generalCols, name, checked);
          const checkedGeneralColumns = this.getVisibleColumnsState(generalCols);
          newState = {
            generalCols,
            checkedGeneralColumns,
            enabledSwitcher: this.toggleSwitcher(generalCols, this.state.customCols),
            checkedAllColumns: checkedGeneralColumns && this.getVisibleColumnsState(this.state.customCols),
          };
          break;
        case BCTableSettingsModal.columnKinds.custom:
          const customCols = this.setColumnsToStateByName(this.state.customCols, name, checked);
          const checkedCustomColumns = this.getVisibleColumnsState(customCols);
          newState = {
            customCols,
            checkedCustomColumns,
            enabledSwitcher: this.toggleSwitcher(customCols, this.state.generalCols),
            checkedAllColumns: checkedCustomColumns && this.getVisibleColumnsState(this.state.generalCols),
          };
          break;
        default:
      }

      this.setState(newState);
    };

  toggleSwitcher = (columns1, columns2) => this.isColumnOn(columns1) || this.isColumnOn(columns2);

  handleTransition = () => this.forceUpdate();

  updateUser = (body) => {
    return this.props.updateUser(this.props.userId, body);
  };

  addColumnsToDisplayed = () => {
    const {generalCols, customCols} = this.state;
    const fromGeneral = generalCols.filter(({checked, hidden}) => checked && !hidden);
    const fromCustom = customCols.filter(({checked, hidden}) => checked && !hidden);
    const {displayItems} = this.props;

    const startPosition = displayItems.length
      ? last(sortBy(displayItems, BCTableSettingsModal.sortingParam)).position + 1
      : 0;
    const postData = this.createDisplayedItemsBodyRequest([...fromGeneral, ...fromCustom], startPosition);

    const body = {
      user: {
        display_items: postData,
      },
    };

    this.updateUser(body)
      .then(this.getBroadcastDisplayItems)
      .then(() =>
        this.setState({
          enabledSwitcher: false,
          checkedGeneralColumns: false,
          checkedCustomColumns: false,
          checkedAllColumns: false,
          enabledArrowUp: false,
          enabledArrowDown: false,
        }),
      );
  };

  onClose = () => {
    this.setState(
      {
        displayedColumns: this.mapColumns(this.props.displayItems),
        enabledSwitcher: false,
        checkedGeneralColumns: false,
        checkedCustomColumns: false,
        checkedAllColumns: false,
        enabledArrowUp: false,
        enabledArrowDown: false,
      },
      this.props.onClose,
    );
  };

  isColumnOn(columns) {
    return columns.some(({checked}) => checked);
  }

  createDisplayedItemsBodyRequest(columns, startPosition = 0) {
    return columns.reduce((acc, column) => {
      const accLength = acc.length;
      const items = this.getAdapterId(column).map((item, index) => {
        return {
          client_id: this.props.clientId,
          column_adapter_id: item,
          type: DISPLAY_ITEM_TYPES.BROADCAST_LIST,
          position: startPosition + accLength + index,
        };
      });
      return acc.concat(items);
    }, []);
  }

  renderTooltip() {
    return <span id="tooltip-1">{this.LANGUAGE.MODAL.DELETE_ITEM_TOOLTIP}</span>;
  }

  render() {
    const {
      generalCols,
      customCols,
      enabledSwitcher,
      displayedColumns,
      enabledArrowUp,
      enabledArrowDown,
      checkedAllColumns,
      checkedGeneralColumns,
      checkedCustomColumns,
      accordionEnabled,
      displayColumnSearch,
    } = this.state;

    return (
      <Modal
        show={this.props.show}
        onClose={this.onClose}
        title={this.LANGUAGE.MODAL.TITLE}
        dialogClassName="modal-window--width-2 theme-color-16"
      >
        <div className="row pos-rel">
          <div className="modal-window__order-btns">
            <button
              disabled={!enabledArrowUp || displayColumnSearch}
              onClick={this.moveColumns(BCTableSettingsModal.directionsMap.up)}
              className="button button--bg-14 button--circle modal-window__order-btn-up"
            >
              <Icon className="button__icon theme-color-16" name="back" />
            </button>
            <button
              disabled={!enabledArrowDown || displayColumnSearch}
              onClick={this.moveColumns(BCTableSettingsModal.directionsMap.down)}
              className="button button--bg-14 button--circle modal-window__order-btn-down"
            >
              <Icon className="button__icon theme-color-16" name="back" />
            </button>
          </div>
          <button
            disabled={!enabledSwitcher}
            className="button button--bg-14 button--circle modal-window__transfer-btn"
            onClick={this.addColumnsToDisplayed}
          >
            <Icon className="button__icon theme-color-16" name="back" />
          </button>
          <div className="col-xs-6 modal-window__col-left">
            <div className="modal-window__descr main-text">{this.LANGUAGE.MODAL.ALL_COLUMNS_TITLE}</div>
            <div className="content-group theme-color-16">
              <div className="content-group__header">
                <Checkbox
                  name="checkbox-all"
                  label={<span className="bold">{this.LANGUAGE.MODAL.ALL_COLUMNS_TITLE}</span>}
                  checked={checkedAllColumns}
                  onChange={this.toggleGroupControl(BCTableSettingsModal.checkboxGroups.all)}
                />
                <SearchField
                  cssModifier="input--full-width content-group__search-field"
                  placeholder={this.LANGUAGE.MODAL.PLACEHOLDER}
                  onSearch={this.searchAllColumns}
                  onClear={this.clearAllColumns}
                  onChange={this.handleSearchChange}
                />
              </div>
              <Rollout
                title={
                  <Checkbox
                    cssModifier="theme-color-16"
                    name="checkbox-general"
                    label={<span className="bold">{this.LANGUAGE.MODAL.GENERAL_COLUMNS_TITLE}</span>}
                    checked={checkedGeneralColumns}
                    onChange={this.toggleGroupControl(BCTableSettingsModal.checkboxGroups.general)}
                  />
                }
                className="rollout--expand-1"
                onTransition={this.handleTransition}
                isOpened={accordionEnabled}
              >
                <CustomScrollbars
                  scrollbarProps={{
                    autoHeightMax: 192,
                  }}
                >
                  <div className="list list--view-3">
                    <div className="list__group">
                      {generalCols.map((item, index) => {
                        return (
                          <div className={`list__item ${item.hidden ? 'hidden' : ''}`} key={index}>
                            <Checkbox
                              cssModifier="theme-color-16 checkbox-button--view-1 "
                              dataName={item.name}
                              name={item.name}
                              label={item.locale}
                              checked={item.checked}
                              onChange={this.handleColumnCheck(item.name, BCTableSettingsModal.columnKinds.general)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </CustomScrollbars>
              </Rollout>
              <Rollout
                title={
                  <Checkbox
                    cssModifier="theme-color-16"
                    name="checkbox-custom"
                    label={<span className="bold">{this.LANGUAGE.MODAL.CUSTOM_COLUMNS_TITLE}</span>}
                    checked={checkedCustomColumns}
                    onChange={this.toggleGroupControl(BCTableSettingsModal.checkboxGroups.custom)}
                  />
                }
                className="rollout--expand-1 rollout--no-border-bottom"
                onTransition={this.handleTransition}
                isOpened={accordionEnabled}
              >
                <CustomScrollbars
                  scrollbarProps={{
                    autoHeightMax: 191,
                  }}
                >
                  <div className="list list--view-3">
                    <div className="list__group">
                      {customCols.map((item, index) => {
                        return (
                          <div key={index} className={`list__item ${item.hidden ? 'hidden' : ''}`}>
                            <Checkbox
                              cssModifier="theme-color-16 checkbox-button--view-1"
                              dataName={item.name}
                              name={item.name}
                              label={item.name}
                              checked={item.checked}
                              onChange={this.handleColumnCheck(item.name, BCTableSettingsModal.columnKinds.custom)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </CustomScrollbars>
              </Rollout>
            </div>
          </div>
          <div className="col-xs-6 modal-window__col-right">
            <div className="modal-window__descr main-text">{this.LANGUAGE.MODAL.DISPLAYED_COLUMNS_TITLE}</div>
            <div className="content-group theme-color-2">
              <div className="content-group__header leads-table-settings-modal__col-header">
                <SearchField
                  cssModifier="input--full-width content-group__search-field"
                  placeholder={this.LANGUAGE.MODAL.PLACEHOLDER}
                  onSearch={this.searchDisplayedColumn}
                  onClear={this.clearDisplayedColumn}
                />
              </div>
              <CustomScrollbars
                scrollbarProps={{
                  hideTracksWhenNotNeeded: true,
                  autoHeightMax: 482,
                  autoHeightMin: 482,
                }}
              >
                <div className="list list--view-2">
                  {displayedColumns.map((item, index) => {
                    return (
                      <div className="list__item pos-rel" key={index}>
                        <Checkbox
                          cssModifier="theme-color-16 checkbox-button--view-1 "
                          dataName={item.name}
                          name={item.name}
                          label={item.columnName}
                          checked={item.checked}
                          onChange={this.handleDisplayedColumnCheck(item.name)}
                        />
                        <Popover position="right" overlay={this.renderTooltip()}>
                          <span className="list__del-item-btn" onClick={() => this.deleteDisplayedColumn(item.name)}>
                            <Icon name="clear" className="list__del-item-icon" />
                          </span>
                        </Popover>
                      </div>
                    );
                  })}
                </div>
              </CustomScrollbars>
            </div>
          </div>
        </div>
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-14 modal-window__footer-btn" onClick={this.onClose}>
            {this.LANGUAGE.MODAL.CONFIRM_BUTTON}
          </button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageState: state.languageState,
    internalColumns: selectColumnAdapters(state),
    customColumns: selectAllCustomColumns(state),
    displayItems: selectBroadcastDisplayItems(state),
    columnVisibilityItems: selectColumnVisibilityItems(state),
  };
};

const mapDispatchToProps = {
  getColumnVisibilityItems,
  deleteBroadcastDisplayItems,
  updateUser,
  getInternalColumns,
  getBroadcastDisplayItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(BCTableSettingsModal);
