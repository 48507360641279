export default {
  GET_OPTIN_COLUMNS_REQUEST: '@@leads-parameters/GET_OPTIN_COLUMNS_REQUEST',
  GET_OPTIN_COLUMNS_SUCCESS: '@@leads-parameters/GET_OPTIN_COLUMNS_SUCCESS',
  GET_OPTIN_COLUMNS_ERROR: '@@leads-parameters/GET_OPTIN_COLUMNS_ERROR',

  GET_LEAD_COLUMNS_REQUEST: '@@leads-parameters/GET_LEAD_COLUMNS_REQUEST',
  GET_LEAD_COLUMNS_SUCCESS: '@@leads-parameters/GET_LEAD_COLUMNS_SUCCESS',
  GET_LEAD_COLUMNS_ERROR: '@@leads-parameters/GET_LEAD_COLUMNS_ERROR',

  CREATE_OPTIN_COLUMN_REQUEST: '@@leads-parameters/CREATE_OPTIN_COLUMN_REQUEST',
  CREATE_OPTIN_COLUMN_SUCCESS: '@@leads-parameters/CREATE_OPTIN_COLUMN_SUCCESS',
  CREATE_OPTIN_COLUMN_ERROR: '@@leads-parameters/CREATE_OPTIN_COLUMN_ERROR',

  UPDATE_OPTIN_COLUMN_REQUEST: '@@leads-parameters/UPDATE_OPTIN_COLUMN_REQUEST',
  UPDATE_OPTIN_COLUMN_SUCCESS: '@@leads-parameters/UPDATE_OPTIN_COLUMN_SUCCESS',
  UPDATE_OPTIN_COLUMN_ERROR: '@@leads-parameters/UPDATE_OPTIN_COLUMN_ERROR',

  DELETE_OPTIN_COLUMN_REQUEST: '@@leads-parameters/DELETE_OPTIN_COLUMN_REQUEST',
  DELETE_OPTIN_COLUMN_SUCCESS: '@@leads-parameters/DELETE_OPTIN_COLUMN_SUCCESS',
  DELETE_OPTIN_COLUMN_ERROR: '@@leads-parameters/DELETE_OPTIN_COLUMN_ERROR',
};
