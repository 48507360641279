import {useCallback} from 'react';

import {useSelector} from 'react-redux';

import {selectOperation} from 'client/ducks/operations/selectors';
import {selectInitialFormItems, selectModifiedFormItems} from 'client/ducks/templates/selectors';
import {selectClientAccessLevel} from 'client/ducks/user-clients/selectors';

import {isOperationArchived} from 'client/models/operations/helpers';

import {checkVisibility} from '../helpers';

/**
 *
 * @param template
 * @param disabled
 * @param isSetToKiosk
 * @param isSetToOnline
 * @returns {function(*, *): {visible: *, disabled}}
 */
export const useFormElementVisibilityCheck = ({template, disabled, device: isSetToKiosk, online: isSetToOnline}) => {
  const initialFormItems = useSelector(selectInitialFormItems);
  const modifiedFormItem = useSelector(selectModifiedFormItems);

  const accessLevel = useSelector(selectClientAccessLevel);

  const checkItem = useCallback(
    (item, params) => {
      const {lang = '', device = ''} = params;
      const formItem = {
        ...item,
      };
      if (item?.form_conditions?.length) {
        const form_conditions = item.form_conditions.map((form_condition) => {
          const formItemId = form_condition?.form_item_id;
          return {
            ...form_condition,
            condition_source:
              modifiedFormItem?.[formItemId] ||
              initialFormItems?.[formItemId] ||
              form_condition?.condition_source ||
              {},
          };
        });
        formItem.form_conditions = form_conditions;
      }

      return {
        disabled: item?.no_edit_if_started && disabled,
        visible: checkVisibility(formItem, {
          userRole: accessLevel,
          isSetToKiosk,
          isSetToOnline,
          template,
          currentLanguage: lang,
          currentDevice: device,
        }),
      };
    },
    [initialFormItems, isSetToKiosk, isSetToOnline, modifiedFormItem, template, accessLevel, disabled],
  );

  return checkItem;
};

export function useFormElementDisabilityCheck() {
  const operation = useSelector(selectOperation);

  return {
    disabled: !operation.editable || isOperationArchived(operation),
  };
}
