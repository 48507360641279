import {dateToString, prettyJoin} from 'client/services/helpers';

import {PresentWinner} from 'client/models/winnings';

import {PRIZE_DISTRIBUTION_STATUSES} from './constants';

export const getCardTitle = (prize: PresentWinner['prize']) => {
  const {coupon} = prize;
  if (coupon) {
    const loyalty = prettyJoin([coupon.loyalty_amount, coupon.loyalty_unit]);
    const from = dateToString(coupon.token_validity_from);
    const to = dateToString(coupon.token_validity_to);
    const period = prettyJoin([from, to], ' - ');
    return [loyalty, prize.prize_name, period].filter(Boolean).flat();
  }
  return [prize.prize_name];
};

export const getDistributionStatus = (data: PresentWinner) => {
  if (data.history.distribution?.distributed && data.prize.prize_distribution) {
    return PRIZE_DISTRIBUTION_STATUSES.DISTRIBUTED;
  }
  if (!data.prize.validated) {
    return PRIZE_DISTRIBUTION_STATUSES.UNVALIDATED;
  }
  if (data.prize.transferred) {
    return PRIZE_DISTRIBUTION_STATUSES.TRANSFERRED;
  }
  if (data.prize.expired) {
    return PRIZE_DISTRIBUTION_STATUSES.EXPIRED;
  }
  return null;
};
