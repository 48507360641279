import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import {ApiAction} from 'client/types';

import types from './types';

export const getAllAgencies: ApiAction = () => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.AGENCIES}`,
    method: 'GET',
    noCache: true,
    types: [types.GET_ALL_AGENCIES_REQUEST, types.GET_ALL_AGENCIES_SUCCESS, types.GET_ALL_AGENCIES_ERROR],
  });
};

export const getAllAgencyClients: ApiAction = (agencyId) => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.COMPANIES}`,
    method: 'GET',
    noCache: true,
    types: [
      types.GET_ALL_AGENCY_CLIENTS_REQUEST,
      types.GET_ALL_AGENCY_CLIENTS_SUCCESS,
      types.GET_ALL_AGENCY_CLIENTS_ERROR,
    ],
    queryParams: {
      q: {agency_id_eq: agencyId},
      s: ['name asc', 'brand_name asc'],
    },
  });
};

export const getAgencyClients: ApiAction =
  (queryParams = {}) =>
  (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.COMPANIES}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_AGENCY_CLIENTS_REQUEST, types.GET_AGENCY_CLIENTS_SUCCESS, types.GET_AGENCY_CLIENTS_ERROR],
      queryParams,
    });
  };

export const getAgencyClient: ApiAction = (clientId) => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.CLIENTS}/${clientId}`,
    method: 'GET',
    noCache: true,
    types: [types.GET_AGENCY_CLIENT_REQUEST, types.GET_AGENCY_CLIENT_SUCCESS, types.GET_AGENCY_CLIENT_ERROR],
  });
};

export const deleteAgencyClient: ApiAction = (clientId) => (dispatch) => {
  return callApi(
    dispatch,
    {
      endpoint: `${API_PATH}${API_METHODS.COMPANIES}/${clientId}`,
      method: 'DELETE',
      noCache: true,
      types: [types.DELETE_AGENCY_CLIENT_REQUEST, types.DELETE_AGENCY_CLIENT_SUCCESS, types.DELETE_AGENCY_CLIENT_ERROR],
    },
    true,
  );
};

export const createAgencyClient: ApiAction = (body) => (dispatch) => {
  return callApi(
    dispatch,
    {
      endpoint: `${API_PATH}${API_METHODS.COMPANIES}`,
      method: 'POST',
      noCache: true,
      types: [types.UPDATE_AGENCY_CLIENT_REQUEST, types.UPDATE_AGENCY_CLIENT_SUCCESS, types.UPDATE_AGENCY_CLIENT_ERROR],
      body: {
        company: body,
      },
    },
    true,
  );
};

export const updateAgencyClient: ApiAction = (id, body) => (dispatch) => {
  return callApi(
    dispatch,
    {
      endpoint: `${API_PATH}${API_METHODS.COMPANIES}/${id}`,
      method: 'PATCH',
      noCache: true,
      types: [types.UPDATE_AGENCY_CLIENT_REQUEST, types.UPDATE_AGENCY_CLIENT_SUCCESS, types.UPDATE_AGENCY_CLIENT_ERROR],
      body: {
        company: body,
      },
    },
    true,
  );
};
