import React from 'react';

import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import Icon from 'client/components/common/icon';

function CardColumn(props) {
  const {link} = props;

  return (
    <div className="leads-main-data-grid__card">
      <Link to={link}>
        <span className="theme-color-2">
          <Icon name="comment" />
        </span>
      </Link>
    </div>
  );
}

CardColumn.propTypes = {
  link: PropTypes.string.isRequired,
};

export default CardColumn;
