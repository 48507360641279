import React, {PureComponent} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

export default class TestDrawStep extends PureComponent {
  constructor() {
    super();
  }

  getPerformIn = (implementation) => {
    const {kind, perform_in, perform_at, time_unit, variable_id} = implementation;
    const {formValues} = this.props;
    const value = formValues[`_${variable_id}`];

    switch (kind) {
      case 'in':
        return perform_in && time_unit ? `${perform_in} ${time_unit}` : '';
      case 'in_variable':
        return value && time_unit ? `${value} ${time_unit}` : '';
      case 'at':
        return perform_at ? moment(perform_at).format('DD/MM/YYYY HH:mm') : '';
      case 'as':
        return value ? moment(value).format('DD/MM/YYYY HH:mm') : '';
      default:
        break;
    }

    return '';
  };

  render() {
    const {
      step: {comment, implementation},
      languageState: LANGUAGE,
    } = this.props;
    const performIn = this.getPerformIn(implementation);

    return (
      <div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{LANGUAGE.PERFORM_IN_TEXT}</div>
            <div className="fake-input__field">{performIn}</div>
          </div>
        </div>
        {comment && comment.length > 0 && (
          <div className="test-step__form-block">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{LANGUAGE.COMMENT_TEXT}</div>
              <div className="fake-input__textarea">{comment}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

TestDrawStep.propTypes = {
  formValues: PropTypes.object,
  step: PropTypes.object,
  languageState: PropTypes.object.isRequired,
  variables: PropTypes.array,
};
