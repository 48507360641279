import union from 'lodash/union';

import types from './types';

const initialState = {
  plansById: {},
  plansIds: [],
  plan: {},
};

export default function (_state = initialState, action) {
  let state = {..._state};

  switch (action.type) {
    case types.CLEAR_PLANS_STATE:
      return initialState;

    case types.GET_PLAN_SUCCESS:
      state.plan = action.payload.plan;
      return state;

    case types.GET_PLANS_SUCCESS:
      return {
        plansById: action.payload.plans.reduce((result, item) => {
          return {
            ...result,
            [item.id]: item,
          };
        }, {}),
        plansIds: union(
          state.plansIds,
          action.payload.plans.map((item) => item.id),
        ),
      };

    default:
      return state;
  }
}
