import get from 'lodash/get';
import moment from 'moment';

import {LEAD_SOURCE_TYPES} from 'client/common/config';

export function selectSourceParams(data = {}) {
  let name = '';
  const date = data.created_at ? moment(data.created_at).format('DD/MM/YYYY') : '';
  const time = data.created_at ? moment(data.created_at).format('HH:mm') : '';
  const id = data.id || 0;

  switch (data.type) {
    case LEAD_SOURCE_TYPES.API_LEAD_IMPORT:
      name = data.membership_id;
      break;
    case LEAD_SOURCE_TYPES.PARTICIPATION:
      name = get(data, 'interaction.interface.automation_task.operation.name', '');
      break;
    case LEAD_SOURCE_TYPES.FILE_LEAD_IMPORT:
      name = `${data.name}.${data.format}`;
      break;
    case LEAD_SOURCE_TYPES.OPERATION:
      name = `Op. ${data.name}`;
      break;
    default:
  }

  return {date, name, time, id};
}

export function selectFirstSourcesLeadsFilterMapped(state) {
  return state.firstSources.payload
    .filter((item) => item.source_type !== LEAD_SOURCE_TYPES.OPERATION)
    .map((item) => {
      const source = selectSourceParams(item.source);
      return {
        value: String(source.id),
        label: String(source.name).toUpperCase(),
        selectCustomOption: `${source.date} ${source.time}`,
      };
    });
}
