import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import cssModule from './skeleton.module.scss';

type SkeletonProps = {
  className?: string;
  children?: React.ReactElement;
  loading?: boolean;
};

const b = bem('skeleton', {cssModule});

const Skeleton: React.FC<SkeletonProps> = (props) => {
  const {children = null, className, loading = true} = props;

  return loading ? <div className={cn(b(), className)} /> : children;
};

export default Skeleton;
