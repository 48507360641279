import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {interpolate} from 'client/ducks/language/helpers';
import {createExport} from 'client/ducks/statistic-exports/actions';

import AppButton from 'client/common/buttons';
import {RadioGroupField, TextField, TextareaField} from 'client/common/fields';
import CheckboxField from 'client/common/fields/checkbox-field';
import {useToast} from 'client/common/hooks/useToast';
import Modal from 'client/common/modals/modal';

import {Autotask} from 'client/models/autotask/types';
import {Membership} from 'client/models/memberships/types';

import {GenerateStatisticFileValues} from './types';
import validateValues from './validateValues';

import cssModule from './generate-statistic-file-modal.module.scss';

const b = bem('generate-statistic-file-modal', {cssModule});

type GenerateStatisticFileModalProps = {
  autotask: Autotask;
  membership: Membership;
  onClose: () => void;
};

const formName = 'GenerateStatisticFileForm';

const GenerateStatisticFileModal: ReduxFormFC<GenerateStatisticFileModalProps, GenerateStatisticFileValues> = ({
  autotask,
  membership,
  onClose,
  handleSubmit,
}) => {
  const dispatch = useDispatch();
  const lang = useLanguage('STATISTIC_EXPORTS.MODALS.GENERATE_STATISTIC_FILE_MODAL');
  const langCommonExportFile = useLanguage('GENERATING_EXPORT_FILE');
  const {appendToastNotification} = useToast();
  const [submitting, toggleSubmitting] = useToggle(false);

  const {formValues, invalid} = useReduxForm<GenerateStatisticFileValues>(formName, {
    initialValues: {export_format: 'csv'},
    validate: (values) => validateValues(values, lang),
  });

  useEffect(() => {
    if ((formValues.export_kpi === true || formValues.export_winners === true) && formValues.export_format === 'csv') {
      // Implemented like this because it doesn't work properly using redux form change function
      document.getElementsByName('export_format')[2].click();
    }
  }, [formValues.export_format, formValues.export_kpi, formValues.export_winners]);

  const onSubmit = async (values: GenerateStatisticFileValues) => {
    toggleSubmitting();
    const ext = `.${values.export_format === 'csv' ? 'csv' : 'xlsx'}`;
    const fileName = values.file_name + ext;
    const title = interpolate(langCommonExportFile.NOTIFICATION_TITLE?.toString(), {
      fileName,
    });
    try {
      const data = {
        statistic_export: {
          automation_task_id: autotask.id,
          membership_id: membership.id,
          client_id: membership.client_id,
          comment: values.comment,
        },
        ...values,
      };
      dispatch(createExport(data));

      appendToastNotification({
        type: 'success',
        title,
        description: langCommonExportFile.NOTIFICATION_IN_PROCESS,
      });
      onClose();
    } catch (e) {
      console.error(e);
    }
    toggleSubmitting();
  };

  return (
    <Modal onClose={onClose} title={lang.GENERATE_FILE}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={b('form-items')}>
          <TextField label={lang.FILE_NAME} name="file_name" required />
          <div className={b('type-row')}>
            <CheckboxField name="export_statistics" label={lang.STATISTICS} inversionColor />
            <CheckboxField name="export_kpi" label={lang.KPI_DATA} inversionColor />
            <CheckboxField name="export_winners" label={lang.WINNERS} inversionColor />
          </div>
          <TextareaField label={lang.COMMENT} name="comment" />
          <RadioGroupField
            name="export_format"
            direction="horizontal"
            className={b('radio-group')}
            options={[
              {value: 'csv', label: '.csv', disabled: formValues.export_kpi || formValues.export_winners},
              {value: 'xls', label: '.xlsx'},
            ]}
          />
        </div>
        <div className={b('buttons')}>
          <AppButton label={lang.CANCEL} onClick={onClose} transparent />
          <AppButton label={lang.GENERATE} className={b('button')} disabled={invalid} loading={submitting} submit />
        </div>
      </form>
    </Modal>
  );
};

export default reduxForm<GenerateStatisticFileModalProps, GenerateStatisticFileValues>({
  form: formName,
})(GenerateStatisticFileModal);
