import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Modal from 'client/common/modals/modal';

import LinkToClientForm from './link-to-client-form';

function LinkToClientModal({show, onClose, lang, onUnlinkClientButtonClick, linkedClient, onConfirm}) {
  return (
    <Modal
      show={show}
      dialogClassName="modal-window--width-1 modal-window--theme-10"
      backdropClassName="modal-window__backdrop"
      onClose={onClose}
      title={lang.LINK_PLACES_TITLE}
    >
      <LinkToClientForm initialValues={linkedClient} onUnlinkClientButtonClick={onUnlinkClientButtonClick} />
      <div className="modal-window__footer">
        <button className="button button--bg-5 modal-window__footer-btn" onClick={onClose}>
          {lang.CANCEL_BUTTON}
        </button>
        <button type="submit" className="button button--bg-10 modal-window__footer-btn" onClick={onConfirm}>
          {lang.LINK_BUTTON}
        </button>
      </div>
    </Modal>
  );
}

LinkToClientModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onUnlinkClientButtonClick: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
  linkedClient: PropTypes.object,
};

LinkToClientModal.defaultProps = {
  linkedClient: {},
};

export default connect(({languageState}) => ({
  lang: languageState.payload.PLACES,
}))(LinkToClientModal);
