import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {ToggleField} from 'client/common/fields';

import {Translation} from 'client/models/language/types';

import cssModule from './lottery-loss-result-announcement.module.scss';

const b = bem('lottery-loss-result-announcement', {cssModule});

type LotteryLossResultAnnouncementProps = {
  title?: Translation;
  isReadOnly?: boolean;
  className?: string;
  classNames?: {
    title?: string;
    loss?: string;
    customLabel?: string;
  };
  name?: string;
};
const LotteryLossResultAnnouncement: React.FC<LotteryLossResultAnnouncementProps> = (props) => {
  const {title, isReadOnly, className, classNames, name} = props;
  const lang = useLanguage('LOTTERY.FIELDSETS.LOTTERY_LOSS_RESULT_ANNOUNCEMENT');

  return (
    <div className={cn(b(), className)}>
      <p className={cn(b('title'), classNames?.title)}>{title || lang.TITLE}</p>
      <div className={cn(b('loss'), classNames?.loss)}>
        <ToggleField
          name={[name, 'loss_email'].filter(Boolean).join('.')}
          label={lang.SEND_EMAIL}
          classNames={{label: cn(b('custom-label'), classNames?.customLabel)}}
          disabled={isReadOnly}
          rounded
        />
        <ToggleField
          name={[name, 'loss_sms'].filter(Boolean).join('.')}
          label={lang.SEND_SMS}
          classNames={{label: cn(b('custom-label'), classNames?.customLabel)}}
          disabled={isReadOnly}
          rounded
        />
      </div>
    </div>
  );
};

export default LotteryLossResultAnnouncement;
