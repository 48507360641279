import types from './types';

const initialState = {
  subcategories: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        subcategories: action.payload.subcategories,
      };

    default:
      return state;
  }
}
