import getValidityInitialValues from 'client/components/prizes/fieldsets/prize-diy-validity-fieldset/initialValues';
import {PRIZE_TYPES} from 'client/models/prizes/constants';
import {Prize} from 'client/models/prizes/types';

import {MainParamsValues} from './types';

export default (prize: Prize | null): MainParamsValues => {
  return {
    type: prize?.type || PRIZE_TYPES.COMMON,
    loyalty_amount: prize?.loyalty_amount || '',
    loyalty_unit: prize?.loyalty_unit || '',
    loyalty_only: prize?.loyalty_only || false,
    loss_email: prize?.email_send || false,
    loss_sms: prize?.sms_send || false,
    level: prize?.level,
    distribution: prize?.distribution,
    ...getValidityInitialValues(prize),
  };
};
