import {useCallback, useEffect, useMemo, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {getClientFonts} from 'client/ducks/templates/actions';
import {selectClientFonts} from 'client/ducks/templates/selectors';

import {ApiDispatch} from 'client/types';

type useClientFontsParams = {
  fetchOnMount?: boolean;
};

// Keep track of fonts added to document
const loadedFonts = new Set();

const useClientFonts = (params?: useClientFontsParams) => {
  const {fetchOnMount} = params || {};

  const dispatch: ApiDispatch = useDispatch();
  const clientId = useSelector(selectCurrentClient)?.id;
  const clientFonts = useSelector(selectClientFonts);

  const [loading, setLoading] = useState(false);

  const fetchFonts = useCallback(() => {
    setLoading(true);
    return dispatch(getClientFonts(clientId)).then((promise) => {
      setLoading(false);
      return promise;
    });
  }, [clientId, dispatch]);

  const injectFonts = useCallback((fonts) => {
    if (fonts) {
      fonts.forEach((font: {name: string; file_url: string}) => {
        if (!loadedFonts.has(font.name)) {
          const fontFace = new FontFace(font.name, `url(${font.file_url})`);

          fontFace.load().then(() => {
            loadedFonts.add(font.name);
            document.fonts.add(fontFace);
          });
        }
      });
    }
  }, []);

  const initFonts = useCallback(() => {
    fetchFonts().then(({payload}) => injectFonts(payload.fonts));
  }, [fetchFonts, injectFonts]);

  useEffect(() => {
    if (fetchOnMount) {
      initFonts();
    }
  }, [fetchOnMount, initFonts]);

  const fontNames = useMemo(() => clientFonts?.map((font: {name: string}) => font.name), [clientFonts]);

  return {loading, fonts: clientFonts, fontNames};
};

export default useClientFonts;
