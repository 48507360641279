import React, {Component} from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError, change} from 'redux-form';

import {patch} from 'client/services/fetch';

import {API_METHODS} from 'client/common/config';
import Icon from 'client/common/icon';
import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';

import './set-items-modal.scss';

let LANGUAGE = {};
let items = [];

class SetItemsModal extends Component {
  static validate(vals) {
    const values = vals || {};
    const errors = {};
    const lang = LANGUAGE.SET_ITEMS_MODAL || {};

    for (let i = 0; i < items.length; i++) {
      if (items[i].deleted) {
        continue;
      }
      let name = 'name' + i;
      let kind = 'kind' + i;

      if (!values[name] || !values[name].trim()) {
        errors[name] = lang.NAME_IS_REQUIRED_ERROR;
      }
      if (!values[kind] || !values[kind].value) {
        errors[kind] = lang.FORMAT_IS_REQUIRED_ERROR;
      }
    }

    return errors;
  }

  static updateSyncErrors(values) {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'SetItemsForm',
      },
      payload: {
        syncErrors: SetItemsModal.validate(values),
      },
    };
  }

  static mapData(templateItems) {
    return {
      interface_template: {
        interface_template_items: templateItems.map((item) => ({
          id: item.id,
          name: item.name,
          kind: item.kind,
          array: item.array,
          value_as_true: (item.kind === 'boolean' && item.value_as_true) || null,
          _destroy: item.deleted,
        })),
      },
    };
  }

  constructor(props) {
    super(props);

    LANGUAGE = this.LANGUAGE = props.languageState.payload.INTERFACE_TEMPLATES;
    this.formats = props.languageState.payload.CUSTOM_FORMATS;

    this.formats = Object.keys(this.formats).map((key) => ({
      value: key,
      label: this.formats[key],
    }));

    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    this.intervalID = setInterval(() => {
      if (!isEmpty(LANGUAGE)) {
        clearInterval(this.intervalID);
        this.props.updateErrors({});
      }
    }, 200);
  }

  componentDidUpdate(prevProps) {
    const {editingTemplate, show} = this.props;

    if (editingTemplate !== prevProps.editingTemplate || (show && !prevProps.show)) {
      if (!editingTemplate.id) {
        return;
      }
      items = editingTemplate.interface_template_items || [];
      this.setState({items});
      this.props.initialize(
        items.reduce((init, item, index) => {
          init['name' + index] = item.name;
          init['kind' + index] = {value: item.kind};
          init['array' + index] = item.array;
          init['istrue' + index] = item.value_as_true;
          return init;
        }, {}),
      );
    }
  }

  save = () => {
    return patch(
      `${API_METHODS.INTERFACE_TEMPLATES}/${this.props.editingTemplate.id}`,
      SetItemsModal.mapData(this.state.items),
    ).then(({errors}) => {
      if (errors) {
        const submitErrors = {};
        for (let i in errors.interface_template_items) {
          if (errors.interface_template_items[i].name) {
            submitErrors['name' + i] = LANGUAGE.SET_ITEMS_MODAL.NAME_IS_TAKEN_ERROR;
          }
        }
        throw new SubmissionError(submitErrors);
      }
      this.props.onSave();
    });
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  handleClose = () => {
    this.props.onClose();
  };

  onItemDelete = (index) => () => {
    const newItems = this.state.items.slice();
    const type = this.state.items[index];
    if (type.id) {
      type.deleted = true;
      type.changed = true;
    } else {
      newItems.splice(index, 1);
    }
    this.setState({items: newItems});
    items = newItems;
  };

  onAddNewItemClick = () => {
    const newItems = this.state.items.slice();
    newItems.push({kind: 'text'});
    items = newItems;
    this.setState({items: newItems}, () => {
      this.scrollbarRef.scrollToBottom();
      this.props.changeFieldValue('SetItemsForm', 'kind' + (newItems.length - 1), {value: 'text'});
      this.props.updateErrors(this.props.formValues.values);
    });
  };

  handleChangeName =
    (index) =>
    ({target: {value}}) => {
      const item = this.state.items[index];
      item.name = value;
      item.changed = true;
    };

  handleChangeFormat =
    (index) =>
    ({value}) => {
      const item = this.state.items[index];
      item.kind = value;
      item.changed = true;
    };

  handleChangeIsTrue =
    (index) =>
    ({target: {value}}) => {
      const item = this.state.items[index];
      item.value_as_true = value;
      item.changed = true;
    };

  handleChangeIsArray =
    (index) =>
    ({target: {checked}}) => {
      const item = this.state.items[index];
      item.array = checked;
      item.changed = true;
    };

  render() {
    const {
      show,
      editingTemplate: {name},
    } = this.props;

    return (
      <Modal
        show={show}
        onClose={this.handleClose}
        dialogClassName="modal-window--width-1 modal-window--theme-7 set-items-modal"
        titleClassName="modal-window__title"
        title={
          <>
            <h3 className="title pull-left">{LANGUAGE.SET_ITEMS_MODAL.TITLE}</h3>
            <div className="template-name ellipsis-text" title={name}>
              {name}
            </div>
          </>
        }
      >
        <form noValidate="noValidate" onSubmit={this.props.handleSubmit(this.save)}>
          <CustomScrollbars
            scrollbarProps={{
              autoHeightMax: 349,
              ref: (scrollbar) => {
                this.scrollbarRef = scrollbar;
              },
            }}
            viewCssClass="scroll-view "
            cssModifier="margin-bottom-20"
          >
            {this.state.items &&
              this.state.items.map(
                (item, index) =>
                  !item.deleted && (
                    <div key={index} className="template-item">
                      <div>
                        <Field
                          name={'kind' + index}
                          type="select"
                          searchable={false}
                          placeholder={LANGUAGE.SET_ITEMS_MODAL.FORMAT_PLACEHOLDER}
                          cssModifier="input--view-210"
                          component={fieldTemplate}
                          options={this.formats}
                          onChange={this.handleChangeFormat(index)}
                        />
                        <button
                          type="button"
                          className="pull-right button button--circle button--bg-7"
                          onClick={this.onItemDelete(index)}
                        >
                          <Icon name="trash" className="button__icon" width={17} height={19} />
                        </button>
                      </div>
                      {item.kind === 'boolean' && (
                        <div className="is-true-container">
                          <Field
                            name={'istrue' + index}
                            type="text"
                            cssModifier="input--view-210"
                            component={fieldTemplate}
                            onChange={this.handleChangeIsTrue(index)}
                          />
                          <span className="is-true-label">{LANGUAGE.SET_ITEMS_MODAL.IS_TRUE_LABEL}</span>
                        </div>
                      )}
                      <Field
                        name={'name' + index}
                        type="text"
                        cssModifier="input--no-min-height"
                        component={fieldTemplate}
                        placeholder={LANGUAGE.SET_ITEMS_MODAL.NAME_PLACEHOLDER}
                        onChange={this.handleChangeName(index)}
                      />
                      <Field
                        name={'array' + index}
                        type="checkbox"
                        label={LANGUAGE.SET_ITEMS_MODAL.ARRAY_CHECKBOX}
                        cssModifier="theme-color-8"
                        component={fieldTemplate}
                        onChange={this.handleChangeIsArray(index)}
                      />
                    </div>
                  ),
              )}
          </CustomScrollbars>
          <button type="button" className="button button--bg-7" onClick={this.onAddNewItemClick}>
            <Icon name="plus" className="button__icon" />
            <span>{this.LANGUAGE.SET_ITEMS_MODAL.ADD_ITEM_BUTTON}</span>
          </button>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={this.handleCancel}>
              {this.LANGUAGE.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-7 modal-window__footer-btn" type="submit">
              {this.LANGUAGE.SAVE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

SetItemsModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  handleSubmit: PropTypes.func,
  updateErrors: PropTypes.func,
  untouch: PropTypes.func,
  initialize: PropTypes.func,
  changeFieldValue: PropTypes.func,
  languageState: PropTypes.object,
  formValues: PropTypes.object,
  editingTemplate: PropTypes.object,
  initialValues: PropTypes.object,
  valid: PropTypes.bool,
};

SetItemsModal.defaultProps = {
  show: false,
  editingTemplate: {},
  onCancel: () => {},
  onClose: () => {},
  onSave: () => {},
};

const SetItemsForm = reduxForm({
  form: 'SetItemsForm',
  validate: SetItemsModal.validate,
})(SetItemsModal);

export default connect(
  ({languageState, form}) => ({
    languageState,
    formValues: form.SetItemsForm,
  }),
  {
    updateErrors: SetItemsModal.updateSyncErrors,
    changeFieldValue: change,
  },
)(SetItemsForm);
