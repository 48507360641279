import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getClientsDomains(queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENTS_DOMAINS}`,
      method: 'GET',
      types: [types.GET_CLIENTS_DOMAINS_REQUEST, types.GET_CLIENTS_DOMAINS_SUCCESS, types.GET_CLIENTS_DOMAINS_ERROR],
      queryParams,
    });
  };
}

export function createClientsDomain(body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENTS_DOMAINS}`,
      method: 'POST',
      noCache: true,
      types: [
        types.CREATE_CLIENTS_DOMAINS_REQUEST,
        types.CREATE_CLIENTS_DOMAINS_SUCCESS,
        types.CREATE_CLIENTS_DOMAINS_ERROR,
      ],
      body,
    });
}

export function updateClientsDomain(id, body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENTS_DOMAINS}/${id}`,
      method: 'PATCH',
      noCache: true,
      types: [
        types.UPDATE_CLIENTS_DOMAINS_REQUEST,
        types.UPDATE_CLIENTS_DOMAINS_SUCCESS,
        types.UPDATE_CLIENTS_DOMAINS_ERROR,
      ],
      body,
    });
}
