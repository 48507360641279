import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Modal from 'client/common/modals/modal';

import PlanMap from 'client/components/places/plan-map';

import './plan-map-modal.scss';

class PlanMapModal extends Component {
  static propTypes = {
    readOnly: PropTypes.bool,
    show: PropTypes.bool,
    onEditInteraction: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    planId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    places: PropTypes.array.isRequired,
    placeId: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK_PLAN.MAP_MARKER_POPOVER;
  }

  render() {
    const {onClose, places, placeId, onEditInteraction} = this.props;

    const filteredPlaces = placeId
      ? places.filter((i) => i.id === placeId)
      : places.filter((i) => i.offline_interactions_count !== 0);

    return (
      <Modal
        className="plan-map-modal"
        onClose={onClose}
        classNames={{
          content: 'plan-map-modal__content',
          close: 'plan-map-modal__close',
          body: 'plan-map-modal__body',
        }}
      >
        <PlanMap
          readOnly
          onEditInteraction={onEditInteraction}
          data={filteredPlaces}
          labels={{
            markerPopoverTitle: this.LANGUAGE.TITLE,
            markerPopoverInterfaces: this.LANGUAGE.INTERFACES_LABEL,
          }}
        />
      </Modal>
    );
  }
}

export default connect(({languageState}) => ({
  languageState: languageState,
}))(PlanMapModal);
