import types from './types';

const initialState = {
  mapModalToggle: false,
  addPlaceModalToggle: false,
  selectRegionModalToggle: false,
  needToUpdateStores: false,
  addPlaceSubmitting: false,
  perPage: 5,
  search: '',
  selectedStores: [],
  mappedStores: [],
  sort: {name: 'name', order: 'ASC'},
  stores: [],
  client: {},
};

export default function (_state = initialState, action) {
  const state = Object.assign({}, _state);

  switch (action.type) {
    case types.GET_CLIENT_SUCCESS:
      state.client = action.payload.client;
      return state;

    case types.CLEAR_STORE:
      return {...initialState};

    case types.TOGGLE_ADD_STORE_MODAL:
      state.addPlaceModalToggle = !state.addPlaceModalToggle;
      return state;

    case types.TOGGLE_MAP_MODAL:
      state.mapModalToggle = !state.mapModalToggle;
      return state;

    case types.TOGGLE_SELECT_REGION_MODAL:
      state.selectRegionModalToggle = !state.selectRegionModalToggle;
      return state;

    case types.PATCH_STORE_SUCCESS:
      state.selectRegionModalToggle = false;
      state.needToUpdateStores = true;
      return state;

    case types.GET_STORES_REQUEST:
      state.needToUpdateStores = false;
      return state;

    case types.GET_STORES_SUCCESS:
      state.stores = action.payload.places;
      state.meta = action.payload.meta;
      return state;

    case types.GET_MAPPED_STORES_SUCCESS:
      state.mappedStores = action.payload.places;
      return state;

    case types.GET_STORES_ERROR:
      state.addPlaceSubmitting = false;
      return state;

    case types.EDIT_STORE_REQUEST:
      state.addPlaceSubmitting = true;
      return state;

    case types.EDIT_STORE_SUCCESS:
      state.addPlaceSubmitting = false;
      state.addPlaceModalToggle = !state.addPlaceModalToggle;
      state.needToUpdateStores = true;
      return state;

    case types.EDIT_STORE_ERROR:
      return state;

    case types.CHANGE_SELECTED_STORES:
      state.selectedStores = action.selectedStores;
      return state;

    default:
      return state;
  }
}
