import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getParticipationWinners(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.WINNERS}`,
      method: 'GET',
      types: [
        types.GET_PARTICIPATIONS_WINNERS_REQUEST,
        types.GET_PARTICIPATIONS_WINNERS_SUCCESS,
        types.GET_PARTICIPATIONS_WINNERS_ERROR,
      ],
      queryParams,
    });
}
