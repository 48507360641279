import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getLeadsStatistic} from 'client/ducks/leads-dashboard/actions';
import {selectLeadsStatistic} from 'client/ducks/leads-dashboard/selectors';
import {selectLeadsTotal} from 'client/ducks/leads-opt-in-statistics/selectors';

import DashboardCard from 'client/components/common/cards/dashboard-card';
import ContactBars from 'client/components/common/charts-custom/contact-bars';
import GenderDonutChart from 'client/components/common/charts-custom/gender-donut-chart';

import './leads-staticstic.scss';

class LeadsStatistic extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    empty: PropTypes.bool.isRequired,
    leadsStatistic: PropTypes.object.isRequired,
    getLeadsStatistic: PropTypes.func.isRequired,
    leadsTotal: PropTypes.number.isRequired,
  };

  state = {
    loading: false,
  };

  componentDidMount() {
    this.fetchLeadsStatistic();
  }

  toggleLoading = () => this.setState(({loading}) => ({loading: !loading}));

  fetchLeadsStatistic = () => {
    this.toggleLoading();
    return this.props
      .getLeadsStatistic({
        client_id: this.props.client.id,
      })
      .then(this.toggleLoading);
  };

  render() {
    const {lang, empty, leadsStatistic, leadsTotal} = this.props;
    const {loading} = this.state;

    return (
      <div className="leads-statistic">
        <DashboardCard
          title={lang.GENDER}
          iconName="leads-card"
          contentHeight={125}
          empty={empty}
          emptyText={lang.NO_DATA}
          loading={loading}
          loadingColor="leads"
        >
          <GenderDonutChart data={leadsStatistic.gender} legendOnRight />
        </DashboardCard>
        <DashboardCard
          title={lang.CONTACT_INFO}
          iconName="leads-contact"
          contentHeight={125}
          empty={empty}
          emptyText={lang.NO_DATA}
          loading={loading}
          loadingColor="leads"
        >
          <ContactBars
            data={{
              total: leadsTotal,
              ...leadsStatistic.coordinations,
            }}
          />
        </DashboardCard>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.languageState.payload.LEADS_DASHBOARD,
    leadsStatistic: selectLeadsStatistic(state),
    leadsTotal: selectLeadsTotal(state),
  }),
  {
    getLeadsStatistic,
  },
)(LeadsStatistic);
