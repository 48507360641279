import React, {useState} from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectActiveTemplateKind} from 'client/ducks/email-templates/selectors';

import SelectDropdown from 'client/common/selects/select-dropdown';

import usePrizeVariableOptions from 'client/components/email-template-editor/hooks/usePrizeVariableOptions';
import {getVariableOptions} from 'client/components/email-template-editor/inputs/parameters-variable-dropdown/helpers';
import {Translation} from 'client/models/language/types';

import ToolbarButton from '../../buttons/toolbar-button';

import cssModule from './variable-input.module.scss';

const b = bem('variable-input', {cssModule});

type VariableFamily = 'lead' | 'participation' | 'device' | 'operation_datum' | 'instant_win' | 'lottery' | 'visual';

type FamilyOption = {
  value: VariableFamily;
  label: Translation;
};

type VariableInputProps = {
  onSubmit: (value: string) => void;
};

const VariableInput: React.FC<VariableInputProps> = ({onSubmit}) => {
  const familyLabelsLang = useLanguage('EMAIL_TEMPLATE_EDITOR.INPUTS.PARAMETERS_VARIABLE_DROPDOWN');
  const lang = useLanguage('COMMON.TEXT_EDITOR.LABELS');

  const activeTemplateKind = useSelector(selectActiveTemplateKind);
  const prizeOptions = usePrizeVariableOptions(activeTemplateKind?.category);

  const allOptions = {
    ...getVariableOptions(activeTemplateKind?.email_template_kind_variables || []),
    instant_win: prizeOptions.instantWin?.options || [],
    lottery: prizeOptions.lottery?.options || [],
  };

  const allFamilyOptions = [
    {value: 'lead', label: familyLabelsLang.LEADS},
    {value: 'participation', label: familyLabelsLang.PARTICIPATIONS},
    {value: 'device', label: familyLabelsLang.DEVICE},
    {value: 'operation_datum', label: familyLabelsLang.OPERATION_DATUM},
    {value: 'visual', label: familyLabelsLang.VISUALS},
  ] as FamilyOption[];

  if (prizeOptions.instantWin) {
    allFamilyOptions.push({value: 'instant_win', label: prizeOptions.instantWin.label});
  }

  if (prizeOptions.lottery) {
    allFamilyOptions.push({value: 'lottery', label: prizeOptions.lottery.label});
  }

  const familyOptions = allFamilyOptions.filter(({value}) => allOptions[value]?.length);

  const [variableFamily, setVariableFamily] = useState<VariableFamily>(familyOptions[0]?.value as VariableFamily);
  const [variableName, setVariableName] = useState(allOptions.lead[0]?.value);

  const handleFamilyChange = (family: VariableFamily) => {
    setVariableFamily(family);
    setVariableName(allOptions[family][0]?.value);
  };

  return (
    <div className={b()}>
      <SelectDropdown
        label={lang.FAMILY}
        labelClassName={b('select-label')}
        options={familyOptions}
        value={variableFamily}
        onChange={handleFamilyChange}
        simpleValue
      />
      <SelectDropdown
        label={lang.NAME}
        labelClassName={b('select-label')}
        options={allOptions[variableFamily] || []}
        value={variableName}
        onChange={setVariableName}
        simpleValue
      />
      <ToolbarButton
        label={lang.INSERT}
        iconName="check"
        iconProps={{width: 24, height: 24}}
        onClick={() => onSubmit(variableName)}
        disabled={!variableName}
      />
    </div>
  );
};

export default VariableInput;
