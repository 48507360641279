import React, {useCallback, useEffect, useState} from 'react';

import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useToggle, useUnmount} from 'react-use';

import bem from 'client/services/bem';
import {useQueryParams} from 'client/services/hooks';

import {getInstoreDashboards, clearInstoreDashboards} from 'client/ducks/instore-dashboards/actions';
import {selectInstoreDashboards} from 'client/ducks/instore-dashboards/selectors';
import {selectCurrentUser} from 'client/ducks/user/selectors';

import Offscreen from 'client/common/offscreen';

import Spinner from 'client/components/common/spinner';

import InstoreDashboardBlocksContainer from 'client/components/instore-dashboards/containers/instore-dashboard-blocks-container';
import InstoreDashboardSelect from 'client/components/instore-dashboards/inputs/instore-dashboard-select';
import InstoreDashboardModal from 'client/components/instore-dashboards/modals/instore-dashboard-modal';
import InstoreDashboardToolbar from 'client/components/instore-dashboards/toolbars/instore-dashboard-toolbar';
import {useFetchCampaigns} from 'client/components/instore/hooks/use-fetch-campaigns';
import useSetDefaultCampaign from 'client/components/instore/hooks/use-set-default-campaign';
import InstoreCampaignSelect from 'client/components/instore/inputs/instore-campaign-select';

import cssModule from './instore-dashboards-container.module.scss';

const b = bem('instore-dashboards-container', {cssModule});

const InstoreDashboardsContainer = (props) => {
  const {task, fetchTask} = props;
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useQueryParams(null, {}, {parse: {parseNumbers: true}});
  const {loadingCampaigns, campaigns} = useFetchCampaigns({taskId: task.id});
  const [loadingDashboards, toggleLoadingDashboards] = useToggle(false);
  const [showDashboardModal, toggleDashboardModal] = useToggle(false);
  const currentUser = useSelector(selectCurrentUser);
  const dashboards = useSelector(selectInstoreDashboards);
  const [currentDashboard, setCurrentDashboard] = useState(null);
  const [editingDashboard, setEditingDashboard] = useState(null);

  const loading = loadingCampaigns || loadingDashboards;

  useSetDefaultCampaign({campaigns});

  const onChangeCampaign = (campaignId) => {
    if (campaignId !== queryParams.campaignId) {
      setQueryParams({campaignId});
    }
  };

  const fetchDashboards = useCallback(async () => {
    if (!isNumber(queryParams.campaignId)) {
      return Promise.resolve();
    }

    toggleLoadingDashboards();
    const result = await dispatch(
      getInstoreDashboards({
        q: {instore_task_id_eq: task.id},
        include_instore_dashboard_dashboard_blocks_count: true,
      }),
    );
    toggleLoadingDashboards();
    return result;
  }, [queryParams.campaignId, dispatch, task.id, toggleLoadingDashboards]);

  useEffect(() => {
    fetchDashboards();
  }, [fetchDashboards]);

  useUnmount(() => {
    dispatch(clearInstoreDashboards());
  });

  return (
    <div className={b()}>
      {loading && <Spinner color="primary" centered={true} />}
      <Offscreen hidden={loading}>
        <>
          {showDashboardModal && (
            <InstoreDashboardModal
              dashboard={editingDashboard}
              task={task}
              onClose={() => {
                setEditingDashboard(null);
                toggleDashboardModal();
              }}
              fetchDashboards={fetchDashboards}
              user={currentUser}
              onCreate={(dashboardId) => dashboardId && setQueryParams({dashboardId})}
            />
          )}
          <div className={b('selects')}>
            <InstoreDashboardSelect
              task={task}
              dashboards={dashboards}
              fetchDashboards={fetchDashboards}
              setDashboard={setCurrentDashboard}
              user={currentUser}
              onAddDashboard={toggleDashboardModal}
            />
            {campaigns.length > 1 && (
              <InstoreCampaignSelect
                campaigns={campaigns}
                value={queryParams.campaignId}
                onChange={onChangeCampaign}
                showAllOption={true}
              />
            )}
          </div>
          {currentDashboard && (
            <div className={b('content')}>
              <InstoreDashboardToolbar
                dashboard={currentDashboard}
                task={task}
                fetchTask={fetchTask}
                onEditDashboard={() => {
                  setEditingDashboard(currentDashboard);
                  toggleDashboardModal();
                }}
                onDeleteDashboard={async () => {
                  await fetchDashboards();
                  setQueryParams({dashboardId: null});
                }}
              />
              {currentDashboard && (
                <InstoreDashboardBlocksContainer dashboard={currentDashboard} campaignId={queryParams.campaignId} />
              )}
            </div>
          )}
        </>
      </Offscreen>
    </div>
  );
};

InstoreDashboardsContainer.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  fetchTask: PropTypes.func.isRequired,
};

export default InstoreDashboardsContainer;
