import omit from 'lodash/omit';
import {RSAA} from 'redux-api-middleware';

import {getUserById} from 'client/ducks/user/actions';

import {API_PATH, API_METHODS} from 'client/common/config';

export const PROFILE_NO_DATA = 'PROFILE_NO_DATA';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_LOADED = 'PROFILE_LOADED';
export const PROFILE_LOAD_FAILED = 'PROFILE_LOAD_FAILED';

export const PROFILE_UPDATE_LOADING = 'PROFILE_UPDATE_LOADING';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILED = 'PROFILE_UPDATE_FAILED';

export const PROFILE_DELETE_LOADING = 'PROFILE_DELETE_LOADING';
export const PROFILE_DELETE_SUCCESS = 'PROFILE_DELETE_SUCCESS';
export const PROFILE_DELETE_FAILED = 'PROFILE_DELETE_FAILED';

export const PROFILE_PASSWORD_CHANGE_LOADING = 'PROFILE_PASSWORD_CHANGE_LOADING';
export const PROFILE_PASSWORD_CHANGE_SUCCESS = 'PROFILE_PASSWORD_CHANGE_SUCCESS';
export const PROFILE_PASSWORD_CHANGE_FAILED = 'PROFILE_PASSWORD_CHANGE_FAILED';

export function getProfile(id) {
  return (dispatch, getState) => {
    const currentUserId = getState().userState.payload.user.id;

    if (String(id) === String(currentUserId)) {
      dispatch(getUserById(currentUserId));
    }

    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.USERS}/${id}`,
        method: 'GET',
        isAuth: true,
        types: [PROFILE_LOADING, PROFILE_LOADED, PROFILE_LOAD_FAILED],
      },
    });
  };
}

export function clearProfile() {
  return (dispatch) => {
    return dispatch({
      type: PROFILE_NO_DATA,
      payload: {},
    });
  };
}

export function updateProfile(data) {
  return (dispatch, getState) => {
    const currentUserId = getState().userState.payload.user.id;

    if (String(data.id) === String(currentUserId)) {
      dispatch(getUserById(currentUserId));
    }

    return dispatch({
      type: PROFILE_UPDATE_SUCCESS,
      payload: omit(data, 'avatar'),
    });
  };
}

export function passwordChange(id, data) {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.USERS}/${id}${API_METHODS.UPDATE_PASSWORD}`,
        method: 'PUT',
        body: JSON.stringify(data),
        isAuth: true,
        types: [PROFILE_PASSWORD_CHANGE_LOADING, PROFILE_PASSWORD_CHANGE_SUCCESS, PROFILE_PASSWORD_CHANGE_FAILED],
      },
    });
  };
}
