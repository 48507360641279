import React from 'react';

import {Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Popover from 'client/common/popovers/popover';

import './text-cell.scss';

const b = bem('text-cell');

function TextCell(props) {
  const {data, rowIndex, field, className, clickHandler} = props;
  const cellData = data[rowIndex][field];

  return (
    <div
      onClick={() => {
        if (clickHandler) {
          clickHandler(rowIndex);
        }
      }}
      className={b()}
    >
      <Popover
        position="left"
        overlayInnerStyle={{width: 200}}
        contentCssClass="custom-popover-new__content"
        overlay={<Cell>{cellData}</Cell>}
      >
        <Cell className={className}>{cellData}</Cell>
      </Popover>
    </div>
  );
}

TextCell.defaultProps = {
  data: [],
  className: '',
};

TextCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
  className: PropTypes.string,
  clickHandler: PropTypes.func,
};

export default TextCell;
