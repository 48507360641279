import React, {Component} from 'react';

import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';
import {downloadFileWithAuth} from 'client/services/helpers';
import {canManipulateTransfer} from 'client/services/membershipHelpers';

import {updateParticipationsExportReport} from 'client/ducks/participation-export/actions';
import {getParticipationExportFile} from 'client/ducks/participation-export/actions';
import {selectCurrentUser, selectIsAdmin} from 'client/ducks/user/selectors';

import AppButton from 'client/common/buttons/app-button';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Popover from 'client/common/popovers/popover';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import ParticipantsSummaryPopoverBlockInfo from 'client/components/participants/components/blocks/participants-summary-popover-block-info';

import cssModule from './participants-summary-popover.module.scss';

const b = bem('participants-summary-popover', {cssModule});

class ParticipantsSummaryPopover extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    children: PropTypes.node,
    exportReport: PropTypes.shape({
      id: PropTypes.number,
      total: PropTypes.number,
      date: PropTypes.string,
      userName: PropTypes.string,
      status: PropTypes.string,
      link: PropTypes.string,
      format: PropTypes.string,
      memberships: PropTypes.array,
      allowed_for_all: PropTypes.bool,
    }).isRequired,
    position: PropTypes.string,
    getParticipationExportFile: PropTypes.func.isRequired,
    updateParticipationsExportReport: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    children: '',
    position: 'top',
  };

  constructor(props) {
    super(props);

    this.state = {
      showDeleteModal: false,
    };

    this.LANGUAGE = props.languageState.payload.PARTICIPANTS_PORT;
  }

  downloadFile = () => {
    downloadFileWithAuth(this.props.exportReport.link);
  };

  toggleDeleteModal = () => {
    this.setState(({showDeleteModal}) => {
      return {showDeleteModal: !showDeleteModal};
    });
  };

  deleteFile = () => {
    this.props
      .updateParticipationsExportReport(this.props.exportReport.id, {
        participation_export_report: {
          remove_participations_file: true,
        },
      })
      .then(this.props.refresh);
    this.toggleDeleteModal();
  };

  renderActionsBlock = () => {
    const {exportReport, currentUser} = this.props;

    if (!canManipulateTransfer(exportReport, currentUser)) {
      return null;
    }

    const {status, link, format} = exportReport;

    return (
      <>
        <div className={b('summary-export-action')}>
          <span className={b('info-text')}>{this.LANGUAGE.SUMMARY_POPOVER_EXPORT_DOWNLOAD}</span>
          <AppButton
            className={b('button')}
            onClick={this.downloadFile}
            iconName="download"
            disabled={status !== 'success' || !link}
            transparent={true}
            color="clients"
          />
        </div>
        <div className={b('summary-export-action')}>
          <div className={b('info-text', [status])}>{this.LANGUAGE.SUMMARY_POPOVER_EXPORT_DELETE}</div>
          <AppButton
            className={b('button')}
            onClick={this.toggleDeleteModal}
            iconName="trash"
            disabled={status !== 'success' || !link}
            transparent={true}
            color="clients"
          />
        </div>
        {status === 'success' && !link && format !== 'EXAPI' && (
          <div className={b('info-caption')}>{this.LANGUAGE.SUMMARY_POPOVER_FILE_DELETED}</div>
        )}
      </>
    );
  };

  renderOverlay = () => {
    const {
      exportReport: {total, date, userName, status, memberships, allowed_for_all},
    } = this.props;

    return (
      <div className={b()}>
        <h3 className={b('title')}>{this.LANGUAGE.SUMMARY_POPOVER_TITLE}</h3>
        <CustomScrollbars
          cssModifier="custom-scrollbars--hide-hor-scroll"
          scrollbarProps={{
            autoHeightMax: 280,
            hideTracksWhenNotNeeded: true,
          }}
        >
          <div className={b('inner')}>
            <ParticipantsSummaryPopoverBlockInfo
              caption={this.LANGUAGE.SUMMARY_POPOVER_CREATED}
              data={moment(date).format('DD/MM/YYYY')}
            />
            <ParticipantsSummaryPopoverBlockInfo caption={this.LANGUAGE.SUMMARY_POPOVER_BY} data={userName} />
            <ParticipantsSummaryPopoverBlockInfo caption={this.LANGUAGE.SUMMARY_POPOVER_TOTAL_EXPORTED} data={total} />
            <ParticipantsSummaryPopoverBlockInfo
              caption={this.LANGUAGE.SUMMARY_POPOVER_EXPORT_STATUS}
              data={this.LANGUAGE[`SUMMARY_POPOVER_EXPORT_STATUS_${status.toUpperCase()}`]}
            />
            <ParticipantsSummaryPopoverBlockInfo
              caption={this.LANGUAGE.SETTINGS_POPOVER.USER_ALLOWED}
              data={
                allowed_for_all ? (
                  this.LANGUAGE.ALLOW_ALL_LABEL
                ) : (
                  <div className={b('info-text', 'info-text-container')}>
                    {memberships?.length ? (
                      <span className={b('info-text')}>
                        {memberships.map((item) => item.client_user.full_name).join(', ')}
                      </span>
                    ) : (
                      <span>{this.LANGUAGE.NO_ALLOWED_USERS}</span>
                    )}
                  </div>
                )
              }
            />
            {this.renderActionsBlock()}
          </div>
        </CustomScrollbars>
      </div>
    );
  };

  render() {
    const {children, position, isAdmin} = this.props;
    const {showDeleteModal} = this.state;

    if (showDeleteModal) {
      return (
        <ConfirmationModal
          show={true}
          title={this.LANGUAGE.DELETE_MODAL.TITLE}
          message={this.LANGUAGE.DELETE_MODAL.MESSAGE}
          buttonConfirmClass={`button--bg-${isAdmin ? '7' : '4'}`}
          className={`theme-color-${isAdmin ? '8' : '9'}`}
          cancelText="Cancel"
          confirmText="Delete"
          onCancel={this.toggleDeleteModal}
          onClose={this.toggleDeleteModal}
          onConfirm={this.deleteFile}
        />
      );
    }

    return (
      <Popover
        position={position}
        arrowOffset={80}
        shiftLeft={-5}
        shiftTop={-5}
        className="pull-left"
        overlayInnerStyle={{
          width: 370,
        }}
        arrowConfig={{
          width: 30,
          height: 10,
        }}
        overlay={this.renderOverlay()}
      >
        {children}
      </Popover>
    );
  }
}

export default connect(
  (state) => ({
    languageState: state.languageState,
    currentUser: selectCurrentUser(state),
    isAdmin: selectIsAdmin(state),
  }),
  {
    getParticipationExportFile,
    updateParticipationsExportReport,
  },
)(ParticipantsSummaryPopover);
