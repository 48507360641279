import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {required} from 'client/services/validator';

import {postOperationDatumColumn} from 'client/ducks/custom-operation-database/actions';

import {COLUMN_TYPES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

import './new-label-modal.scss';

const initialValues = {
  format: COLUMN_TYPES.STRING,
};

class NewLabelModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    submitting: PropTypes.bool,
    operationId: PropTypes.number,
    change: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    postOperationDatumColumn: PropTypes.func.isRequired,
  };

  static defaultProps = {
    show: false,
  };

  static formName = 'NewLabelModalForm';

  constructor(props) {
    super(props);
    this.LANGUAGE = {
      ...props.languageState.payload.OPERATION_PAGE,
      CUSTOM_FORMATS: props.languageState.payload.CUSTOM_FORMATS,
    };

    this.formats = Object.keys(this.LANGUAGE.CUSTOM_FORMATS).map((key) => ({
      value: key,
      label: this.LANGUAGE.CUSTOM_FORMATS[key],
    }));

    this.rules = {
      name: required(this.LANGUAGE.NEW_LABEL_MODAL.NAME_IS_REQUIRED_ERROR),
      format: required(this.LANGUAGE.NEW_LABEL_MODAL.FORMAT_IS_REQUIRED_ERROR),
    };
  }

  componentDidUpdate({show}) {
    if (this.props.show && !show) {
      this.props.initialize(initialValues);
    }
  }

  mapData = (data) => {
    return {
      operation_datum_column: {
        name: data.name,
        kind: data.format,
        array: data.array,
        operation_id: this.props.operationId,
      },
    };
  };

  save = (data) => {
    return this.props.postOperationDatumColumn(this.mapData(data)).then(({payload: {response: {errors} = {}}}) => {
      if (errors) {
        const submitErrors = {};
        if (errors.name) {
          submitErrors.name = this.LANGUAGE.NEW_LABEL_MODAL.NAME_IS_TAKEN_ERROR;
        }
        throw new SubmissionError(submitErrors);
      }
      this.props.onConfirm();
    });
  };

  render() {
    const {handleSubmit, submitting, onClose, show} = this.props;

    return (
      <Modal
        show={show}
        onClose={onClose}
        title={this.LANGUAGE.NEW_LABEL_MODAL.TITLE}
        dialogClassName="modal-window--width-1 new-label-modal"
      >
        <form onSubmit={handleSubmit(this.save)}>
          <div className="form-field-wrap">
            <Field
              label={this.LANGUAGE.NEW_LABEL_MODAL.NAME_LABEL}
              cssModifier="input--no-min-height"
              name="name"
              validate={this.rules.name}
              maxLength={20}
              type="text"
              component={fieldTemplate}
            />
          </div>
          <div className="form-field-wrap">
            <Field
              cssModifier="select--view-2 input--no-min-height"
              label={this.LANGUAGE.NEW_LABEL_MODAL.FORMAT_LABEL}
              name="format"
              type="select"
              validate={this.rules.format}
              simpleValue
              searchable={false}
              options={this.formats}
              component={fieldTemplate}
            />
          </div>
          <div className="form-field-wrap">
            <Field
              cssModifier="theme-color-9"
              label={this.LANGUAGE.NEW_LABEL_MODAL.ARRAY_DATA}
              name="array"
              type="checkbox"
              component={fieldTemplate}
            />
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onClose}>
              {this.LANGUAGE.CANCEL_BUTTON}
            </button>
            <button
              className="button button--bg-opdb-labels modal-window__footer-btn"
              type="submit"
              disabled={submitting}
            >
              {this.LANGUAGE.NEW_LABEL_MODAL.CREATE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const form = reduxForm({
  form: NewLabelModal.formName,
})(NewLabelModal);

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

const mapDispatchToProps = {
  postOperationDatumColumn,
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
