import React from 'react';

import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {Prize} from 'client/models/prizes/types';

import {headers} from '../helpers';
import PrizesTableCol from '../prizes-table-col';

const b = bem('prizes-table');

type PrizeTableRow = {
  prize: Prize;
  onSelectPrize: () => void;
  onDelete: (id: string) => void;
  onActivate: (id: string, active: boolean) => void;
  isPrizeMap: boolean;
  disabled: boolean;
  disabledActivation: boolean;
  order: number;
  index: number;
};

const PrizesTableRow: React.FC<PrizeTableRow> = ({isPrizeMap, ...props}) => {
  const lang = useLanguage('GAMES.GAME_CONFIG_MODAL.PRIZES_TABLE');
  const {transform, transition, setNodeRef, active} = useSortable({id: props.prize?.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: active?.id === props.prize?.id ? 1 : 0,
  };

  return (
    <div
      className={b('row', {disabled: props.prize.active === false})}
      ref={props.prize.full_win ? null : setNodeRef}
      style={style}
    >
      {headers(lang, isPrizeMap).map(({fieldName, key}) => {
        const colProps = {
          colKey: key,
          fieldName,
          ...props,
        };
        return (
          <div className={b('col', [key])} key={key}>
            <PrizesTableCol {...colProps} disabledDrag={isPrizeMap || props.prize.full_win} />
          </div>
        );
      })}
    </div>
  );
};

export default PrizesTableRow;
