import DropdownIndicator from './dropdown-indicator';
import grouped from './grouped';
import MultiValueRemove from './multi-value-remove';
import Option from './option';

const baseComponents = {
  Option,
  MultiValueRemove,
  DropdownIndicator,
};

export const groupedComponents = {
  ...grouped,
  ...baseComponents,
};
export const components = {
  ...baseComponents,
};
