import React, {useMemo} from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import DiyOpPublishingCard from 'client/components/diy-operation/cards/diy-op-publishing-card';
import {DIY_OPERATION_TYPES} from 'client/components/diy-operation/constants';
import {Autotask} from 'client/models/autotask/types';
import {Translation} from 'client/models/language/types';
import {Operation} from 'client/models/operations/types';

import cssModule from './diy-op-publishing-block.module.scss';

const b = bem('diy-op-publishing-block', {cssModule});

type TypesOfElement = {
  clientInterfaceType: boolean;
  type: string;
  title: Translation;
  classNameType: string;
};

type DiyOpPublishingBlockProps = {
  isAutoConfiguration: boolean;
  fetchOperation: () => void;
  fetchAutotask: () => void;
  operation: Operation;
  autotask: Autotask;
  className?: string;
};

const DiyOpPublishingBlock: React.FC<DiyOpPublishingBlockProps> = (props) => {
  const {isAutoConfiguration, fetchOperation, fetchAutotask, operation, className, autotask} = props;
  const {client_interface} = operation;

  const online = !!client_interface?.online;
  const device = !!client_interface?.device;

  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_MAIN_BLOCK');

  const types: TypesOfElement[] = useMemo(
    () => [
      {
        clientInterfaceType: !isAutoConfiguration && online,
        type: DIY_OPERATION_TYPES.FULL_ONLINE,
        title: lang.OPERATION_TITLE_ONLINE,
        classNameType: b('card-online'),
      },
      {
        clientInterfaceType: !isAutoConfiguration && device,
        type: DIY_OPERATION_TYPES.FULL_DEVICE,
        title: lang.OPERATION_TITLE_DEVICE,
        classNameType: b('card-device'),
      },
      {
        clientInterfaceType: isAutoConfiguration && online,
        type: DIY_OPERATION_TYPES.MANAGED_ONLINE,
        title: lang.OPERATION_TITLE_ONLINE,
        classNameType: b('card-online'),
      },
      {
        clientInterfaceType: isAutoConfiguration && device,
        type: DIY_OPERATION_TYPES.MANAGED_DEVICE,
        title: lang.OPERATION_TITLE_DEVICE,
        classNameType: b('card-device'),
      },
    ],
    [device, online, isAutoConfiguration, lang.OPERATION_TITLE_DEVICE, lang.OPERATION_TITLE_ONLINE],
  );

  return (
    <div className={className}>
      {types.map(({clientInterfaceType, title, classNameType, type}, key: number) => {
        return clientInterfaceType ? (
          <DiyOpPublishingCard
            isAutoConfiguration={isAutoConfiguration}
            fetchOperation={fetchOperation}
            fetchAutotask={fetchAutotask}
            className={classNameType}
            operation={operation}
            autotask={autotask}
            title={title}
            type={type}
            key={key}
          />
        ) : null;
      })}
    </div>
  );
};

export default DiyOpPublishingBlock;
