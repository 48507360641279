import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export const getOptInMappingItems = (interfaceId) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.OPT_IN_MAPPING_ITEMS}`,
        method: 'GET',
        types: [
          types.GET_OPT_IN_MAPPING_ITEMS_REQUEST,
          types.GET_OPT_IN_MAPPING_ITEMS_SUCCESS,
          types.GET_OPT_IN_MAPPING_ITEMS_ERROR,
        ],
        queryParams: {
          include: ['interface_item', 'column_adapter.opt_in_column'],
          q: {
            interface_item_interface_id_eq: interfaceId,
          },
        },
      },
      true,
    );
  };
};

export const updateOptInMappingItems = (optInMappingItems) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.OPT_IN_MAPPING_ITEMS}/bulk_action`,
        method: 'PUT',
        types: [
          types.UPDATE_OPT_IN_MAPPING_ITEMS_REQUEST,
          types.UPDATE_OPT_IN_MAPPING_ITEMS_SUCCESS,
          types.UPDATE_OPT_IN_MAPPING_ITEMS_ERROR,
        ],
        body: optInMappingItems,
        queryParams: {
          include: ['interface_item'],
        },
      },
      true,
    );
  };
};

export const getOptInColumns = (params) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.OPT_IN_COLUMNS}`,
        method: 'GET',
        types: [types.GET_OPT_IN_COLUMNS_REQUEST, types.GET_OPT_IN_COLUMNS_SUCCESS, types.GET_OPT_IN_COLUMNS_ERROR],
        queryParams: params,
      },
      true,
    );
  };
};

export const deleteOptInMappingItem = (interfaceId) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.INTERFACE_ITEMS}/${interfaceId}`,
        method: 'DELETE',
        types: [
          types.DELETE_OPT_IN_MAPPING_ITEM_REQUEST,
          types.DELETE_OPT_IN_MAPPING_ITEM_SUCCESS,
          types.DELETE_OPT_IN_MAPPING_ITEM_ERROR,
        ],
      },
      true,
    );
  };
};
