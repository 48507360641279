import {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {IS_BROWSER} from '../config';

class MetaComponent extends Component {
  render() {
    if (IS_BROWSER) {
      Object.keys(this.props.metaState.payload).forEach((name) => {
        let content = this.props.metaState.payload[name];

        if (name && name.length) {
          if (name === 'title') {
            document.title = content;
          } else {
            const meta = document.createElement('meta');

            meta.setAttribute('name', name);
            meta.setAttribute('content', content);
            document.getElementsByTagName('head')[0].appendChild(meta);
          }
        }
      });
    }
    return this.props.children;
  }
}

MetaComponent.propTypes = {
  metaState: PropTypes.any,
  children: PropTypes.any,
};

export default connect(({metaState}) => {
  return {
    metaState,
  };
})(MetaComponent);
