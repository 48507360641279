import React from 'react';

import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getClientUsersAction} from 'client/ducks/client-users/actions';
import {selectTableReport} from 'client/ducks/lead/selectors';
import {getLeadsImportReport, getImportedLeads} from 'client/ducks/leads-import/actions';
import {deleteApiLeadsImport} from 'client/ducks/leads-import/actions';
import {selectImportedLeads} from 'client/ducks/leads-import/selectors';
import {getLeads} from 'client/ducks/leads-list/actions';
import {selectLeadsMailingTableMapped, selectLeadsMeta} from 'client/ducks/leads-list/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import CustomLink from 'client/components/common/custom-link';
import Icon from 'client/components/common/icon';
import TitleBlock from 'client/components/common/title-block';

import LeadsFilterModal from 'client/components/leads/components/modals/leads-filters-modal/leads-filters-modal';
import LeadsCommentsPopover from 'client/components/leads/components/popovers/leads-comments-popover';
import LeadsErrorPopover from 'client/components/leads/components/popovers/leads-error-popover';
import LeadsSettingsPopover from 'client/components/leads/components/popovers/leads-settings-popover';
import LeadsSummaryPopover from 'client/components/leads/components/popovers/leads-summary-popover';
import LeadsMainDataGrid from 'client/components/leads/components/tables/leads-main-data-grid/leads-main-data-grid';

import {mapFilter, mapFilterForUrl} from '../leads-list/helper';

import './leads-import.scss';

class LeadsImport extends ReactQueryParams {
  static defaultProps = {
    leadsImportTableReport: {},
    importedLeads: [],
  };

  static propTypes = {
    languageState: PropTypes.object.isRequired,
    ...withRouter.propTypes,
    leadsImportTableReport: PropTypes.object,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    importId: PropTypes.string.isRequired,
    getLeadsImportReport: PropTypes.func.isRequired,
    getImportedLeads: PropTypes.func.isRequired,
    getClientUsersAction: PropTypes.func.isRequired,
    deleteApiLeadsImport: PropTypes.func.isRequired,
    importedLeads: PropTypes.object,
  };

  static MODALS_MAP = {
    FILTERS: 'FILTERS',
    TABLE_SETTINGS: 'TABLE_SETTINGS',
  };

  static DEFAULT_SORT_FIELD = 'first_name';
  static DEFAULT_SORT_ORDER = 'asc';
  static DEFAULT_FIRST_PAGE = 1;
  static DEFAULT_PER_PAGE = 5;

  constructor(props) {
    super(props);
    this.LANGUAGE = props.languageState.payload.LEADS_PORT;
    this.LANGUAGE_LEADS = props.languageState.payload.AGENCY;
    this.state = {
      sort: {
        name: '',
        order: '',
      },
      search: '',
      showClear: false,
      searchDefault: '',
      filter: {},
      visibleModal: '',
    };
  }

  componentDidMount() {
    this.props
      .getLeadsImportReport(this.props.importId, {
        include: {
          user: null,
          lead_transfer: {
            client: null,
          },
          memberships: {
            client_user: null,
          },
        },
      })
      .then(() => {
        this.props.getClientUsersAction({
          include: ['memberships'],
          q: {
            memberships_client_id_eq: this.props.leadsImportTableReport.clientId,
          },
        });
        this.setBreadCrumbs();
        this.getImportedLeads();
      });
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  getImportedLeads = () => {
    const {queryParams} = this;

    const sort =
      `${(queryParams.sort && queryParams.sort.name) || LeadsImport.DEFAULT_SORT_FIELD} ` +
      `${(queryParams.sort && queryParams.sort.order) || LeadsImport.DEFAULT_SORT_ORDER}`;

    const search = queryParams.search;

    const params = {
      distinct: true,
      include_lead_expires_at: null,
      include: {
        client: null,
        last_participation: 'operation.client',
        participations: {
          interaction: 'interface',
          operation: 'client',
        },
        broadcast_recipients: null,
        last_export: 'client',
        first_source: {
          client: null,
          interaction: 'interface.automation_task.operation.client',
        },
        opt_in_columns: true,
      },
      q: {
        lead_histories_source_id_eq: this.props.leadsImportTableReport.transferId,
        lead_histories_source_type_eq: 'LeadTransfer',
        s: sort,
        first_name_or_last_name_cont: search,
      },
      page: queryParams.page || LeadsImport.DEFAULT_FIRST_PAGE,
      per_page: queryParams.perPage || LeadsImport.DEFAULT_PER_PAGE,
    };

    if (queryParams.filter) {
      const {q, distinct} = mapFilterForUrl(mapFilter(queryParams.filter));

      params.distinct = distinct;
      params.q = {
        ...params.q,
        ...q,
      };
      params.q.g = [params.q.g];
    }

    if (queryParams.broadcast_list_id) {
      params.q = {
        ...params.q,
        broadcast_recipients_broadcast_list_id_eq: queryParams.broadcast_list_id,
      };
    }

    return this.props.getLeads(params);
  };

  setBreadCrumbs = () => {
    const {
      languageState: {
        payload: {BREADCRUMBS},
      },
      leadsImportTableReport,
    } = this.props;

    this.props.setBreadcrumbs([
      {
        name: BREADCRUMBS.CLIENTS,
        href: CLIENT_PAGES.CLIENTS_LIST,
      },
      {
        name: leadsImportTableReport.clientName,
        href: CustomLink.createClientLink({
          clientType: leadsImportTableReport.clientType,
          clientId: leadsImportTableReport.clientId,
        }),
      },
      {
        name: BREADCRUMBS.LEADS,
        href: CLIENT_PAGES.LEADS_LIST + '/' + leadsImportTableReport.clientId,
      },
      {
        name: leadsImportTableReport.reportName,
      },
    ]);
  };

  onSortChange = (name, order) => {
    this.setQueryParams({sort: {name, order}}, true);
    this.getImportedLeads();
  };

  onPageChange = ({page, perPage}) => {
    this.setQueryParams({page, perPage}, true);
    this.getImportedLeads();
  };

  onSearchChange = (search) => {
    this.setQueryParams(
      {
        search,
        page: LeadsImport.DEFAULT_FIRST_PAGE,
      },
      true,
    );
    this.getImportedLeads();
  };

  onSearchClear = () => {
    this.setQueryParams({search: ''}, true);
    this.getImportedLeads();
  };

  onFilterChange = (filter) => {
    this.setQueryParams({filter: mapFilter(filter), page: LeadsImport.DEFAULT_FIRST_PAGE}, true);
    this.getImportedLeads();
  };

  onFilterClear = () => {
    this.onFilterChange({});
  };

  onFilterFieldClear = (fieldsList) => {
    const obj = omit(this.queryParams.filter, fieldsList);
    this.onFilterChange(obj);
  };

  handleShowModal = (name) => {
    this.setState({visibleModal: name});
  };

  handleCloseModal = () => this.setState({visibleModal: ''});

  handleDeleteReport = (id) => {
    this.setState({
      reportForDelete: id,
      showDeleteReport: true,
    });
  };

  onDeleteReport = (id) => {
    this.props.deleteApiLeadsImport(id).then(() => {
      this.props.history.push(CLIENT_PAGES.LEADS_LIST + '/' + this.props.leadsImportTableReport.clientId);
    });
    this.setState({showDeleteReport: false});
  };

  render() {
    const {leadsImportTableReport, leadsList: data, meta} = this.props;
    const {visibleModal} = this.state;
    const {queryParams} = this;
    return (
      <div className="theme-color-2">
        <ConfirmationModal
          show={this.state.showDeleteReport}
          onClose={() => this.setState({showDeleteReport: false})}
          onCancel={() => this.setState({showDeleteReport: false})}
          title={this.LANGUAGE_LEADS.DELETE_REPORT_TITLE_TEXT}
          message={this.LANGUAGE_LEADS.DELETE_REPORT_MODAL_TEXT}
          cancelText={this.LANGUAGE_LEADS.DELETE_CLIENTS_PAGE_MODAL_CANCEL}
          confirmText={this.LANGUAGE_LEADS.DELETE_CLIENTS_PAGE_MODAL_CONFIRM}
          className="modal-window--theme-1"
          buttonConfirmClass="button--bg-1"
          buttonCancelClass="button--bg-5"
          onConfirm={() => this.onDeleteReport(this.state.reportForDelete)}
        />

        <LeadsFilterModal
          show={visibleModal === LeadsImport.MODALS_MAP.FILTERS}
          onClose={this.handleCloseModal}
          onFilterChange={this.onFilterChange}
          filter={queryParams.filter}
        />
        <div className="flex-container flex-justify-between">
          <div className="page__title-block">
            <TitleBlock theme={true} separator={true}>
              <TitleBlock.Item>{leadsImportTableReport.reportName}</TitleBlock.Item>
              {leadsImportTableReport.format && (
                <TitleBlock.Item>
                  {`${this.LANGUAGE.PAGE_TITLE_FORMAT}: `}
                  <b>{leadsImportTableReport.format}</b>
                </TitleBlock.Item>
              )}
            </TitleBlock>
          </div>
          <div className="leads-import__header-icons">
            <div className="flex-container flex-align-center">
              <div>
                <LeadsSummaryPopover leadsReport={leadsImportTableReport}>
                  <div className="leads-import__header-icon">
                    <Icon name="summary" />
                  </div>
                </LeadsSummaryPopover>
              </div>
              <div>
                {leadsImportTableReport.hasErrors ? (
                  <LeadsErrorPopover date={leadsImportTableReport.created_at} url={leadsImportTableReport.url}>
                    <div className="leads-import__header-icon">
                      <Icon
                        name="status-error"
                        className={
                          leadsImportTableReport.type && leadsImportTableReport.type.startsWith('Api') ? '' : 'hidden'
                        }
                      />
                    </div>
                  </LeadsErrorPopover>
                ) : (
                  <LeadsSettingsPopover
                    userName={leadsImportTableReport.userName}
                    memberships={leadsImportTableReport.memberships}
                    allowAll={leadsImportTableReport.allowed_for_all}
                    dateFrom={leadsImportTableReport.dateFrom}
                    dateTo={leadsImportTableReport.dateTo}
                    leadTransferId={String(leadsImportTableReport.leadTransferId)}
                    deleteReport={this.handleDeleteReport}
                    isImport
                  >
                    <Icon
                      name="settings"
                      className={
                        leadsImportTableReport.type && leadsImportTableReport.type.startsWith('Api') ? '' : 'hidden'
                      }
                    />
                  </LeadsSettingsPopover>
                )}
              </div>
              <div>
                <LeadsCommentsPopover comment={leadsImportTableReport.comment}>
                  <div className="leads-import__header-icon">
                    <Icon name="message" className={leadsImportTableReport.comment ? '' : 'hidden'} />
                  </div>
                </LeadsCommentsPopover>
              </div>
            </div>
          </div>
        </div>
        <div>
          <LeadsMainDataGrid
            data={data}
            meta={meta}
            filter={queryParams.filter}
            clientId={String(leadsImportTableReport.clientId)}
            onPageChange={this.onPageChange}
            onSortChange={this.onSortChange}
            onSearchChange={this.onSearchChange}
            onSearchClear={this.onSearchClear}
            showClear={!!queryParams.search}
            searchDefault={queryParams.search || ''}
            onFilterShow={() => this.handleShowModal(LeadsImport.MODALS_MAP.FILTERS)}
            onFilterChange={this.onFilterChange}
            onFilterClear={this.onFilterClear}
            onFilterFieldClear={this.onFilterFieldClear}
            isReportTable={true}
            perPage={queryParams.perPage}
            sort={queryParams.sort}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    languageState: state.languageState,
    importedLeads: selectImportedLeads(state),
    leadsImportTableReport: selectTableReport(state, true),
    leadsList: selectLeadsMailingTableMapped(state).tableData,
    meta: selectLeadsMeta(state),
  }),
  {
    setBreadcrumbs,
    clearBreadcrumbs,
    getLeadsImportReport,
    getImportedLeads,
    getLeads,
    getClientUsersAction,
    deleteApiLeadsImport,
  },
)(withRouter(LeadsImport));
