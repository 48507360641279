import React, {useMemo} from 'react';

import PropTypes from 'prop-types';

import {generateColor} from 'client/services/helpers';

import {pieCartColors} from 'client/ducks/results-graphs/constants';

import DonutChart from 'client/components/common/charts/donut-chart';

import './pie-chart-diagram.scss';

export const getColor = (index) => {
  return pieCartColors[index] || generateColor();
};

const PieChartDiagram = (props) => {
  const {diagram} = props;

  const data = useMemo(() => {
    return Object.entries(diagram.values).map((array, index) => ({
      label: array[0],
      value: array[1],
      color: getColor(index),
    }));
  }, [diagram.values]);

  return (
    <div className="pie-chart-diagram">
      <DonutChart
        data={data}
        total={diagram.values_count}
        customProps={{
          height: 215,
          width: 215,
        }}
        customOptions={{
          cutoutPercentage: 75,
        }}
        legendOnRight
      />
    </div>
  );
};

PieChartDiagram.propTypes = {
  diagram: PropTypes.object.isRequired,
};

export default PieChartDiagram;
