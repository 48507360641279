import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Modal from 'client/common/modals/modal';

import './updated-items-modal.scss';

function UpdatedItemsModal({show, languageState, onClose, createdItemsCount, deletedItemsCount, updatedItemsNames}) {
  const LANGUAGE = languageState.payload.AUTOTASK.UPDATED_ITEMS_MODAL;

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={LANGUAGE.TITLE}
      dialogClassName="modal-window--width-1 theme-color-8 updated-items-modal"
    >
      <div className="updated-items-modal__item">
        {LANGUAGE.NEW_ITEMS_TITLE}: {createdItemsCount}
      </div>
      <div className="updated-items-modal__item">
        {LANGUAGE.DELETED_ITEMS_TITLE}: {deletedItemsCount}
      </div>
      <div className="updated-items-modal__item">
        {LANGUAGE.UPDATED_ITEMS_TITLE}: {updatedItemsNames.join(', ')}
      </div>
      <div className="modal-window__footer modal-window__footer--centered">
        <button className="button button--bg-11 modal-window__footer-btn" onClick={onClose}>
          {LANGUAGE.CLOSE_BUTTON}
        </button>
      </div>
    </Modal>
  );
}

UpdatedItemsModal.defaultProps = {
  show: false,
  createdItemsCount: 0,
  deletedItemsCount: 0,
  updatedItemsNames: [],
};

UpdatedItemsModal.propTypes = {
  show: PropTypes.bool,
  languageState: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  createdItemsCount: PropTypes.number,
  deletedItemsCount: PropTypes.number,
  updatedItemsNames: PropTypes.arrayOf(PropTypes.string),
};

export default connect(({languageState}) => ({
  languageState,
}))(UpdatedItemsModal);
