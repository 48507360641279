import React, {useEffect} from 'react';

import cn from 'classnames';
import isNull from 'lodash/isNull';
import {createPortal} from 'react-dom';

import bem from 'client/services/bem';

import cssModule from './modal-container.module.scss';

const b = bem('modal-container', {cssModule});

const MODAL_PORTAL_ID = 'modal-portal';

type ModalContainerProps = {
  className?: string;
  children: React.ReactNode;
};

const ModalContainer: React.FC<ModalContainerProps> = ({className, children}) => {
  const modalPortal = document.body.querySelector(`#${MODAL_PORTAL_ID}`);

  useEffect(() => {
    const portal = document.createElement('div');
    portal.id = MODAL_PORTAL_ID;

    if (isNull(modalPortal)) {
      document.body.appendChild(portal);
    }
  }, [modalPortal]);

  if (!document.body.contains(modalPortal) || !modalPortal) {
    return null;
  }

  return createPortal(<div className={cn(b(['backdrop', 'is-shown']), className)}>{children}</div>, modalPortal);
};

export default ModalContainer;
