import React, {Component} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {MESSAGE_TASK_STATES} from 'client/common/config';

class BCBroadcastControls extends Component {
  static propTypes = {
    taskId: PropTypes.number.isRequired,
    onToggleMessageTaskState: PropTypes.func.isRequired,
    onToggleWebhooks: PropTypes.func.isRequired,
    taskState: PropTypes.string.isRequired,
    isWebhooksOpened: PropTypes.bool.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  handleToggleState = (state) => {
    return this.props.onToggleMessageTaskState({
      message_task: {
        state,
      },
    });
  };

  handleToggleWebhooks = (open) => {
    return this.props.onToggleWebhooks({
      message_task: {
        open: !open,
      },
    });
  };

  renderToggleStateButton = (text, taskState) => {
    const cssClass = classNames({
      button: true,
      'button--bg-15': taskState === MESSAGE_TASK_STATES.SLEEPING,
      'button--bg-12': taskState === MESSAGE_TASK_STATES.PERFORMING,
    });

    return (
      <button className={cssClass} onClick={() => this.handleToggleState(taskState)}>
        {text}
      </button>
    );
  };

  renderToggleOpenButton = (text, openStatus) => {
    const cssClass = classNames({
      button: true,
      'button--bg-15': openStatus,
      'button--bg-12': !openStatus,
    });

    return (
      <button className={cssClass} onClick={() => this.handleToggleWebhooks(openStatus)}>
        {text}
      </button>
    );
  };

  renderButton = () => {
    const {languageState, taskState, isWebhooksOpened} = this.props;

    const LANGUAGE = languageState.payload.BROADCASTING_TASK.TASK_LIST;

    switch (true) {
      case isWebhooksOpened && taskState === MESSAGE_TASK_STATES.PERFORMING:
        return this.renderToggleStateButton(LANGUAGE.STOP_BROADCAST_BUTTON, taskState);
      case isWebhooksOpened && taskState === MESSAGE_TASK_STATES.SLEEPING:
        return this.renderToggleStateButton(LANGUAGE.START_BROADCAST_BUTTON, taskState);
      case isWebhooksOpened && taskState === MESSAGE_TASK_STATES.FINISHED:
        return this.renderToggleOpenButton(LANGUAGE.CLOSE_BUTTON, isWebhooksOpened);
      case !isWebhooksOpened && taskState === MESSAGE_TASK_STATES.FINISHED:
        return this.renderToggleOpenButton(LANGUAGE.OPEN_BUTTON, isWebhooksOpened);
      default:
        return null;
    }
  };

  render() {
    return this.renderButton();
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

export default connect(mapStateToProps)(BCBroadcastControls);
