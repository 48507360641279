import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';

import ClientTable from 'client/components/common/client-table';

import {AnimationDayCell, AnimationAnomalyCell} from 'client/components/animations/cells';

import './animation-days-info.scss';

const b = bem('animation-days-info');

const AnimationDaysInfo = (props) => {
  const {animation, loading} = props;
  const lang = useLanguage('ANIMATIONS.COMPONENTS.ANIMATION_DAYS_INFO');
  const amountOfAnimationDays = animation?.animation_days?.length || 0;

  return (
    <div className={b()}>
      <ClientTable
        loading={loading}
        className={b('client-table')}
        classNames={{
          header: b('table-header'),
          cell: b('table-cell'),
        }}
        data={animation?.animation_days || []}
        columns={[
          {
            name: 'from',
            path: 'from',
            label: (
              <span>
                {amountOfAnimationDays} {lang.DAY}
              </span>
            ),
            render: AnimationDayCell,
          },
          {
            name: 'hr_status',
            path: 'hr_status',
            width: '60px',
            label: <Icon name="hr" />,
            render: ({item}) => <Icon name={`hr-${item.hr_status}`} />,
          },
          {
            name: 'install_status',
            path: 'install_status',
            width: '60px',
            label: <Icon name="install" />,
            render: ({item}) => <Icon name={`install-${item.install_status}`} />,
          },
          {
            name: 'anomalies',
            path: 'anomalies',
            width: '60px',
            label: <Icon name="anomalies" />,
            render: ({item}) => <AnimationAnomalyCell item={item.anomaly_occurrences} />,
          },
        ]}
      />
    </div>
  );
};

AnimationDaysInfo.propTypes = {
  animation: PropTypes.shape({
    animation_days: PropTypes.array.isRequired,
  }),
  loading: PropTypes.bool,
};

AnimationDaysInfo.defaultProps = {
  animation: null,
  loading: false,
};

export default AnimationDaysInfo;
