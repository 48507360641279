import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {NoData} from 'client/common/various';

import Spinner from 'client/components/common/spinner';

import {TranslationJsx} from 'client/models/language/types';

import './card-list.scss';

const b = bem('card-list');

const CardList = (props) => {
  const {className, Component, data, loading, loadingColor, noDataText, componentProps} = props;

  return (
    <div className={cn(b(), className)}>
      {loading && <Spinner className={b('spinner')} color={loadingColor} centered={true} />}
      {!loading && data.map((item) => <Component key={item.id} item={item} {...componentProps} />)}
      {!loading && !data.length && <NoData text={noDataText} />}
    </div>
  );
};

CardList.propTypes = {
  className: PropTypes.string,
  Component: PropTypes.func.isRequired,
  componentProps: PropTypes.object,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  loadingColor: PropTypes.string,
  noDataText: TranslationJsx,
};

CardList.defaultProps = {
  className: null,
  componentProps: null,
  loading: false,
  loadingColor: 'primary',
  noDataText: null,
};

export default CardList;
