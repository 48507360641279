// import {bindActionCreators} from 'redux';
import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';

import {getUserId} from 'client/services/cookie-data-source';
import {useLanguage} from 'client/services/hooks';

import {set as setMetaAction} from 'client/common/meta/meta.actions';

import Profile from '../components/profile/profile';

const ProfileComponent = () => {
  const lang = useLanguage('PROFILE');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMetaAction({title: lang.TITLE}));
  }, [dispatch, lang.TITLE]);

  return <Profile id={getUserId()} isLastSA={true} />;
};

export default ProfileComponent;
