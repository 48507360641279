import set from 'lodash/set';

import {required, number, maxLength, datetime} from 'client/services/validator';

import {validateArray} from '../helpers';

export default (values, props) => {
  const {name, form: {kind, performIn, performInVariable, performOn, performOnVariable} = {}} = values;

  const errors = {};

  const ERRORS = props.languageState.payload.AUTOTASK_SCENARIO.SCENARIO_FORMS.ERRORS;

  set(errors, 'name', required(ERRORS.REQUIRED)(name));

  switch (kind) {
    case 'in': {
      const valueValidation = [
        required(ERRORS.REQUIRED),
        number(ERRORS.ONLY_NUMBERS),
        maxLength(7, `${ERRORS.MAXIMUM_LENGTH} 7 ${ERRORS.MAXIMUM_LENGTH}`),
      ];

      set(errors, 'form.performIn.value', validateArray(valueValidation, performIn.value));
      set(errors, 'form.performIn.unit', required(ERRORS.REQUIRED)(performIn.unit));

      break;
    }
    case 'in_variable': {
      set(errors, 'form.performInVariable.value', required(ERRORS.REQUIRED)(performInVariable.value));
      set(errors, 'form.performInVariable.unit', required(ERRORS.REQUIRED)(performInVariable.unit));

      break;
    }
    case 'on': {
      set(errors, 'form.performOn.value', datetime(ERRORS.INCORRECT_FORMAT)(performOn.value));

      break;
    }
    case 'on_variable': {
      set(errors, 'form.performOnVariable.value', required(ERRORS.REQUIRED)(performOnVariable.value));

      break;
    }
    default:
      break;
  }

  return errors;
};
