import {getInstoreDashboardType} from 'client/components/instore-dashboards/helpers';
import {INSTORE_DASHBOARD_TYPES} from 'client/models/instore-dashboard/constants';
import {InstoreDashboard} from 'client/models/instore-dashboard/types';
import {InstoreTask} from 'client/models/instore-tasks/types';
import {User} from 'client/models/users/types';

type Option = {
  label: string;
  value: number;
  dashboard_blocks_count: number;
  labelClassName?: string;
};

type GroupedOptions = Record<string, Option[]>;

export const getOptions = (dashboards: InstoreDashboard[] | null, user: User): GroupedOptions => {
  return (dashboards || []).reduce(
    (result: GroupedOptions, item: InstoreDashboard) => {
      const dashboardType = getInstoreDashboardType(item, user.id);

      if (dashboardType) {
        result[dashboardType].push({
          label: item.name,
          value: item.id,
          dashboard_blocks_count: item.dashboard_blocks_count || 0,
        });
      }

      return result;
    },
    {
      [INSTORE_DASHBOARD_TYPES.USER]: [],
      [INSTORE_DASHBOARD_TYPES.GLOBAL]: [],
      [INSTORE_DASHBOARD_TYPES.WEEZIO]: [],
    },
  );
};

export const getDefaultDashboardId = (task: InstoreTask, options: GroupedOptions, user: User): number => {
  const access = task?.instore_task_accesses?.find(({user_id}) => user_id === user.id);

  if (access && access.default_dashboard_id) {
    return access.default_dashboard_id;
  }

  const userDashboard = options[INSTORE_DASHBOARD_TYPES.USER].find((i) => i.dashboard_blocks_count > 0);
  const globalDashboard = options[INSTORE_DASHBOARD_TYPES.GLOBAL]?.[0];
  const weezioDashboard = options[INSTORE_DASHBOARD_TYPES.WEEZIO]?.[0];
  const initialDashboard = options[INSTORE_DASHBOARD_TYPES.USER]?.[0];

  return userDashboard?.value || globalDashboard?.value || weezioDashboard?.value || initialDashboard?.value;
};
