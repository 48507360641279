import React from 'react';

import findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import {getVisualsList} from 'client/ducks/visuals/actions';
import {selectVisualsList} from 'client/ducks/visuals/selectors';

import PaginationBar from 'client/common/paginations/pagination-bar';

import {mapFiltersForApi} from './helpers';
import VisualsGrid from './visuals-grid';
import VisualsPreviewModal from './visuals-preview-modal';
import VisualsTable from './visuals-table';
import VisualsToolbar from './visuals-toolbar';

import cssModule from './visuals-tab.module.scss';

const b = bem('visuals-tab', {cssModule});

class VisualsTab extends ReactQueryParams {
  static propTypes = {
    task: PropTypes.object.isRequired,
    accessLevel: PropTypes.string.isRequired,
    visualsList: PropTypes.object.isRequired,
    getVisualsList: PropTypes.func.isRequired,
  };

  static DEFAULT_PAGE = '1';
  static DEFAULT_PER_PAGE = '25';
  static DEFAULT_SORT = 'created_at desc';

  constructor(props) {
    super(props);

    this.state = {
      gridIncrement: 0,
      isGrid: false,
      showPreviewModal: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchVisuals();
  }

  componentDidUpdate(prevProps) {
    if (this.props.task.id !== prevProps.task.id) {
      this.fetchVisuals();
    }
  }

  getParams = () => {
    const qp = this.queryParams;

    return {
      page: qp.page || VisualsTab.DEFAULT_PAGE,
      perPage: qp.perPage || VisualsTab.DEFAULT_PER_PAGE,
      sortField: qp.sortField || '',
      sortOrder: qp.sortOrder || '',
      search: qp.search || '',
      filters: qp.filters || {},
    };
  };

  fetchVisuals = () => {
    this.setState({isLoading: true});

    const params = this.getParams();

    return this.props
      .getVisualsList(
        {
          page: params.page,
          per_page: params.perPage,
          automation_task_id: this.props.task.id,
          q: {
            s: params.sortField ? `${params.sortField} ${params.sortOrder}` : VisualsTab.DEFAULT_SORT,
            title_or_interface_name_or_interaction_name_cont: params.search,
            ...mapFiltersForApi(params.filters),
          },
          include: {
            lead: null,
            interaction: null,
            lead_history: null,
            lead_first_source: null,
            hashtag_tracker: null,
          },
        },
        true,
      )
      .then(() => this.setState({isLoading: false}));
  };

  handleParamsChange = (params) => {
    this.setQueryParams(params, false);
    return this.fetchVisuals();
  };

  handleGridIncrementChange = (gridIncrement) => this.setState({gridIncrement});

  handleIsGridChange = () => this.setState(({isGrid}) => ({isGrid: !isGrid}));

  togglePreviewModal = (id = null) => {
    this.setState(({showPreviewModal}) => ({
      visualIndex: findIndex(this.props.visualsList.visuals, {id}),
      showPreviewModal: !showPreviewModal,
    }));
  };

  renderPreviewModal = () => {
    const {task} = this.props;
    const {showPreviewModal, visualIndex} = this.state;

    if (!showPreviewModal) {
      return null;
    }

    return (
      <VisualsPreviewModal
        task={task}
        visualIndex={visualIndex}
        params={this.getParams()}
        onParamsChange={this.handleParamsChange}
        onClose={this.togglePreviewModal}
      />
    );
  };

  render() {
    const {
      visualsList: {visuals, meta},
      accessLevel,
    } = this.props;
    const {gridIncrement, isGrid, isLoading} = this.state;
    const params = this.getParams();

    return (
      <div className={b()}>
        {this.renderPreviewModal()}
        <VisualsToolbar
          params={params}
          totalCount={meta.total_count}
          onParamsChange={this.handleParamsChange}
          gridIncrement={gridIncrement}
          onGridIncrementChange={this.handleGridIncrementChange}
          accessLevel={accessLevel}
          isGrid={isGrid}
          onIsGridChange={this.handleIsGridChange}
        />
        {isGrid ? (
          <VisualsGrid
            visuals={visuals}
            isLoading={isLoading}
            gridIncrement={gridIncrement}
            onTogglePreviewModal={this.togglePreviewModal}
            className={b('visuals')}
          />
        ) : (
          <VisualsTable
            visuals={visuals}
            accessLevel={accessLevel}
            params={params}
            onParamsChange={this.handleParamsChange}
            onTogglePreviewModal={this.togglePreviewModal}
            isLoading={isLoading}
            className={b('visuals')}
          />
        )}
        <PaginationBar
          onPageChange={this.handleParamsChange}
          data={visuals}
          totalPages={meta.total_pages}
          totalItems={meta.total_count}
          perPage={+params.perPage}
          currentPage={meta.current_page}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    visualsList: selectVisualsList(state),
  }),
  {
    getVisualsList,
  },
)(VisualsTab);
