import React from 'react';

import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {dateToString, timeToString} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';

import {DEVICE_SEGMENTATION, ONLINE_SEGMENTATION} from 'client/components/lottery/constants';
import DrawParametersModal from 'client/components/lottery/modals/draw-parameters-modal';
import {Translation} from 'client/models/language/types';
import {Game, Prize} from 'client/models/prizes/types';

import cssModule from './lottery-block-header.module.scss';

const b = bem('lottery-block-header', {cssModule});

const renderDate = (date: string, label?: Translation) => (
  <p className={b('text')}>
    {label && <span>{label}</span>}
    <span>{dateToString(date)}</span>
    <span className={b('time')}>{timeToString(date, 'HH:mm')}</span>
  </p>
);

type LotteryBlockHeaderProps = {
  game: Game;
  fetchAutotask: () => Promise<void>;
  openPrizeModal: () => void;
  onDeleteDraw: () => void;
  disabled: boolean;
  prizes: Prize[];
  fetchPrizes: () => Promise<any>;
};

const LotteryBlockHeader: React.FC<LotteryBlockHeaderProps> = (props) => {
  const {game, fetchAutotask, openPrizeModal, disabled, onDeleteDraw, prizes, fetchPrizes} = props;
  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_LOTTERY_BLOCK.LOTTERY_BLOCK_HEADER');
  const [showDrawModal, toggleDrawModal] = useToggle(false);

  const deviceLabels = {
    [DEVICE_SEGMENTATION.GLOBAL]: lang.DEVICES_GLOBAL,
    [DEVICE_SEGMENTATION.PER_DEVICE]: lang.PER_DEVICE,
  };

  const onlineLabels = {
    [ONLINE_SEGMENTATION.GLOBAL]: lang.ONLINE_GLOBAL,
    [ONLINE_SEGMENTATION.PER_CHANNEL]: lang.PER_CHANNEL,
    [ONLINE_SEGMENTATION.PER_CAMPAIGN]: lang.PER_CAMPAIGN,
  };

  const hasDeviceSegmentation = Object.keys(deviceLabels).includes(game.geo_level);
  const hasOnlineSegmentation = Object.keys(onlineLabels).includes(game.online_level);

  return (
    <div className={b()}>
      <div className={b('left-block')}>
        {showDrawModal && (
          <DrawParametersModal
            onClose={toggleDrawModal}
            onSave={async () => {
              await fetchAutotask();
              await fetchPrizes();
            }}
            onDelete={async () => {
              onDeleteDraw();
              await fetchAutotask();
            }}
            editingData={game}
            prizes={prizes}
            disabled={disabled}
          />
        )}
        <AppButton color="light-clients" label={`+ ${lang.ADD_PRIZE}`} onClick={openPrizeModal} disabled={disabled} />
        <div>
          {hasDeviceSegmentation && (
            <p className={b('text')}>{deviceLabels[game.geo_level as keyof typeof deviceLabels]}</p>
          )}
          {hasOnlineSegmentation && (
            <p className={b('text')}>{onlineLabels[game.online_level as keyof typeof onlineLabels]}</p>
          )}
        </div>
        <div>
          {renderDate(game.actual_from, lang.FROM)}
          {renderDate(game.actual_to, lang.TO)}
        </div>
        {game.game_draws?.length === 1 && (
          <div>
            <p className={b('text')}>{lang.DRAWING}</p>
            {renderDate(game?.default_draw_time || '')}
          </div>
        )}
        <AppButton
          className={b('edit-button')}
          color="light-clients"
          iconName="edit-pencil"
          onClick={toggleDrawModal}
          title={lang.EDIT}
        />
      </div>
    </div>
  );
};

export default LotteryBlockHeader;
