import React, {useMemo} from 'react';

import {Link} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';

import {fetchSpreadPlanInteractions} from 'client/ducks/interactions/actions';
import {selectSpreadPlanInteractions} from 'client/ducks/interactions/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import CustomLink from 'client/components/common/custom-link';

import {Autotask} from 'client/models/autotask/types';
import {Interaction} from 'client/models/interactions/types';
import {Place} from 'client/models/places/types';

import SpreadMap from './spread-map';
import SpreadTable from './spread-table';

import ParticipationTabCard from '../participation-tab-card';

import cssModule from './spread-plan.module.scss';

const b = bem('spread-plan', {cssModule});

type SpreadPlanProps = {
  autotask: Autotask;
};

const SpreadPlan: React.FC<SpreadPlanProps> = (props) => {
  const {autotask} = props;
  const lang = useLanguage('SPREAD_PLAN');
  const params = useParams<{clientId: string; operationId: string}>();
  const [showMapModal, toggleMapModal] = useToggle(false);

  const {data = []} = useReduxFetch<Interaction[]>({
    action: fetchSpreadPlanInteractions,
    selector: selectSpreadPlanInteractions,
    actionArgs: {
      q: {
        s: 'created_at desc',
        interface_automation_task_id_eq: autotask.id,
      },
      include: {
        place: null,
      },
    },
  });

  const placesForMap = useMemo(() => {
    const result: Record<number, Place & {count: number}> = {};

    const allPlaces = data.map((item) => item.place);

    allPlaces.forEach((place) => {
      if (result[place.id]) {
        result[place.id].count += 1;
      } else {
        result[place.id] = {
          ...place,
          count: 1,
        };
      }
    });

    return Object.values(result);
  }, [data]);

  const getPlanTabLink = () => {
    return CustomLink.createClientAutotaskTabLink({
      clientId: params.clientId,
      operationId: params.operationId,
      autotaskId: autotask.id,
      tab: CLIENT_PAGES.CLIENT_AUTOTASK_TABS.PLANS,
    });
  };

  if (!data.length) {
    return null;
  }

  return (
    <div className={b()}>
      <ParticipationTabCard iconName="spread-colored" title={lang.TITLE}>
        <div className={b('content')}>
          <div className={b('table')}>
            <Link to={getPlanTabLink()} className={b('more-details')}>
              {lang.MORE_DETAILS}
            </Link>
            <SpreadTable data={data} />
          </div>
          <div className={b('map')} onClick={toggleMapModal}>
            <SpreadMap places={placesForMap} isPreview />
          </div>
          <Modal
            className={b('modal')}
            classNames={{
              content: b('modal-content'),
              body: b('modal-body'),
            }}
            show={showMapModal}
            onClose={toggleMapModal}
          >
            <SpreadMap places={placesForMap} />
          </Modal>
        </div>
      </ParticipationTabCard>
    </div>
  );
};

export default SpreadPlan;
