import React, {useMemo} from 'react';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch, useQueryParams} from 'client/services/hooks';

import {getAllAgencyClients} from 'client/ducks/agencies/actions';
import {selectAllAgencyClients} from 'client/ducks/agencies/selectors';

import SelectDropdown from 'client/common/selects/select-dropdown';

import {Translation} from 'client/models/language/types';

import cssModule from './agency-clients-bar.module.scss';

const b = bem('agency-clients-bar', {cssModule});

type AgencyClientsBarProps = {
  agencyId: number;
  onChange?: (params: Record<string, string>) => any;
  title?: Translation;
  allOption?: boolean;
  agencyOption?: boolean;
  agencyClientId?: number;
};

const AgencyClientsBar: React.FC<AgencyClientsBarProps> = (props) => {
  const {agencyId, onChange, title, allOption, agencyOption, agencyClientId} = props;
  const lang = useLanguage('AGENCIES.VARIOUS.AGENCY_CLIENTS_BAR');
  const [queryParams, setQueryParams] = useQueryParams({});

  const clientId =
    queryParams.agencyClientId || (agencyOption && (agencyClientId?.toString() || agencyId.toString())) || null;

  const {data = []} = useReduxFetch({
    action: getAllAgencyClients,
    selector: selectAllAgencyClients,
    actionArgs: agencyId,
  });

  const options = useMemo(() => {
    const result = [];
    if (allOption) {
      result.push({value: null, label: lang.ALL});
    }
    if (agencyOption) {
      result.push({value: agencyId.toString(), label: lang.MY_AGENCY});
    }
    return [...result, ...data.map((i) => ({value: i.id.toString(), label: i.name}))];
  }, [data, lang.ALL, lang.MY_AGENCY, agencyId, allOption, agencyOption]);

  const getTitle = () => {
    if (title) {
      return title;
    }
    if (clientId === agencyId.toString()) {
      return '';
    }
    return clientId ? options.find((i) => i.value === clientId)?.label : lang.GENERAL_DASHBOARD;
  };

  return (
    <div className={b()}>
      <p className={b('title')}>{getTitle()}</p>
      <div className={b('select-wrap')}>
        <p className={b('select-label')}>{lang.CLIENTS}</p>
        <SelectDropdown
          value={clientId}
          options={options}
          classNames={{
            control: () => b('control'),
            option: () => (agencyOption ? b('option') : null),
          }}
          onChange={(value: string) => {
            const params = {agencyClientId: value};
            if (onChange) {
              onChange(params);
            } else {
              setQueryParams(params);
            }
          }}
          simpleValue
        />
      </div>
    </div>
  );
};

export default AgencyClientsBar;
