import React, {Fragment, useState} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useMount, useToggle} from 'react-use';
import {reduxForm, SubmissionError} from 'redux-form';

import InterfaceMappingService from 'client/services/InterfaceMappingService';
import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectColumns} from 'client/ducks/column-adapters/selectors';
import {getInterfaceTemplatesAction, getInterfaceTemplateItemsAction} from 'client/ducks/interface-templates/actions';
import {selectInterfaceTemplatesAsOptions} from 'client/ducks/interface-templates/selectors';
import {createInterfaceItem, patchInterfaceItem, deleteInterfaceItem} from 'client/ducks/interfaces/actions';

import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';
import Spinner from 'client/components/common/spinner';

import {TranslationJsx} from 'client/models/language/types';

import AtSetItem from '../../at-set-item';
import MapItemsModal from '../map-items-modal';
import SelectInterfaceTemplateModal from '../select-interface-template-modal';

import cssModule from './edit-interface-items-modal.module.scss';

const b = bem('edit-interface-items-modal', {cssModule});

const defaultTemplate = {label: '', value: 0};

const EditInterfaceItemsModalFormName = 'EditInterfaceItemsModalForm';

const EditInterfaceItemsModal = (props) => {
  const {items, onClose, onSubmit, change, pristine, handleSubmit} = props;

  const lang = useLanguage('AUTOTASK.SET_ITEMS_MODAL');
  const langButtonsLabels = useLanguage('AUTOTASK.SET_INTERFACE_NAME_MODAL');
  const langFormats = useLanguage('CUSTOM_FORMATS');
  const langDataBases = useLanguage('DATABASES');

  const leadColumns = useSelector((state) => selectColumns(state, 'Lead'));
  const interfaceTemplatesOptions = useSelector(selectInterfaceTemplatesAsOptions);

  const dispatch = useDispatch();

  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [showSelectTemplateModal, setShowSelectTemplateModal] = useState(false);
  const [showMapItemsModal, setShowMapItemsModal] = useState(false);

  const [selectedTemplate, setSelectedTemplate] = useState(props.interfaceTemplateId || defaultTemplate);
  const [activeInterfaceItemIndex, setActiveInterfaceItemIndex] = useState(null);
  const [mappingItems, setMappingItems] = useState([]);
  const [itemIndexForDeletion, setItemIndexForDeletion] = useState(null);
  const [isInitializing, toggleIsInitializing] = useToggle(false);

  const {formValues} = useReduxForm(EditInterfaceItemsModalFormName, {
    initialValues: {
      items: [],
      interface_template: {value: 0, label: ''},
    },
  });

  const formats = Object.entries(langFormats).map(([value, label]) => ({value, label}));

  const formInitialization = (initialItems, interfaceTemplateId) => {
    toggleIsInitializing();
    dispatch(getInterfaceTemplatesAction({fetchAll: true})).then(({payload: {interface_templates}}) => {
      const selectedInterfaceTemplate =
        interfaceTemplateId && interface_templates && interface_templates.length
          ? interface_templates.find((template) => template.id === interfaceTemplateId)
          : {name: '', id: 0};

      setSelectedTemplate({
        value: selectedInterfaceTemplate.id,
        label: selectedInterfaceTemplate.name,
      });

      setMappingItems(
        InterfaceMappingService.convertMappingItems(initialItems.map((item) => item.mapping_items || [])),
      );

      props.initialize({
        items: initialItems,
        interface_template: {
          value: selectedInterfaceTemplate.id,
          label: selectedInterfaceTemplate.name,
        },
      });
      toggleIsInitializing();
    });
  };

  useMount(() => {
    InterfaceMappingService.init(langDataBases, MapItemsModal.COLUMN_TYPES);
    formInitialization(items, props.interfaceTemplateId);
  });

  const showMappingModal = (index) => {
    setActiveInterfaceItemIndex(index);
    setShowMapItemsModal(true);
  };

  const disableDeletionModal = () => setShowDeletionModal(false);

  const onKindChange = (index) => {
    const newMappingItems = [...mappingItems];
    newMappingItems[index] = [];
    change(`items[${index}].mapping_items`, []);
    setMappingItems(newMappingItems);
  };

  const onSaveMappingItems = (values) => {
    change(`items[${activeInterfaceItemIndex}].mapping_items`, values.map_items[activeInterfaceItemIndex]);
    setActiveInterfaceItemIndex(null);
    setShowMapItemsModal(false);
    setMappingItems(values.map_items);
  };

  const onConfirmDeleteItem = () => {
    setShowDeletionModal(false);
    setMappingItems((prevMappingItems) => {
      const newMappingItems = [...prevMappingItems];
      newMappingItems.splice(itemIndexForDeletion, 1);
      return newMappingItems;
    });

    const newItems = [...formValues.items];
    newItems.splice(itemIndexForDeletion, 1);
    change('items', newItems);
  };
  /**
   * Remove field if format not boolean
   *
   * @param {number} index - item index
   * @returns {Function} - return closure
   */
  const handleFormatFieldChange = (index) => {
    return (e, value) => {
      if (value !== 'boolean') {
        /**
         * @todo Currently in redux-forms 7.2.0 there is no chance to remove field from array on unregister
         * Set it to null and after that remove all nullable fields before request
         * https://github.com/erikras/redux-form/issues/442
         */
        change(`items[${index}].value_as_true`, null);
      }
    };
  };

  const templateChangeHandler = () => {
    const interfaceTemplate = formValues.interface_template;
    dispatch(getInterfaceTemplateItemsAction(interfaceTemplate.value)).then(({payload}) => {
      setShowSelectTemplateModal(false);
      setSelectedTemplate(interfaceTemplate);
      setMappingItems([]);
      change('items', payload?.interface_template_items);
    });
  };

  const onCloseSelectTemplateModal = () => {
    change('interface_template', selectedTemplate);
    setShowSelectTemplateModal(false);
  };

  /** Save interface items
   * @param {Array} values Form values
   * @return {null} null
   */
  const save = async (values) => {
    if (pristine) {
      onClose();
      return;
    }

    if (!InterfaceMappingService.validateLeadMatchingRule(values.items, leadColumns)) {
      throw new SubmissionError({_error: lang.LEAD_DB_MATCHING_RULE_ERROR});
    }

    const include = {
      include: [
        {
          mapping_items: {
            column_adapter: 'opt_in_column',
          },
        },
      ],
    };
    const template = selectedTemplate.label ? selectedTemplate.value : null;
    if (template && template !== props.interfaceTemplateId) {
      onSubmit({template});
    }

    const createdItems = values.items.filter((item) => !item.id || item.type === 'InterfaceTemplateItem');
    const modifiedItems = InterfaceMappingService.mapModifiedInterfaceItems(
      values.items.filter((item) => item.id && item.type !== 'InterfaceTemplateItem'),
      items,
    );
    const deletedItems = InterfaceMappingService.mapDeletedInterfaceItems(
      values.items.filter((item) => item.id),
      items,
    );
    let err = false;

    if (deletedItems.length) {
      const deleted = [];
      for (let item of deletedItems) {
        deleted.push(dispatch(deleteInterfaceItem(item.id)));
      }
      await Promise.all(deleted).catch(() => {
        err = true;
      });
    }
    if (createdItems.length) {
      const created = [];
      for (let item of createdItems) {
        created.push(
          dispatch(
            createInterfaceItem(
              {
                interface_item: {
                  ...InterfaceMappingService.mapCreatedItem(item).interface_item,
                  interface_id: props.interfaceId,
                },
              },
              include,
            ),
          ),
        );
      }
      await Promise.all(created).catch(() => {
        err = true;
      });
    }
    if (modifiedItems.length) {
      const modified = [];
      for (let item of modifiedItems) {
        modified.push(
          dispatch(
            patchInterfaceItem(
              item.id,
              {
                interface_item: {
                  ...item,
                },
              },
              include,
            ),
          ),
        );
      }
      await Promise.all(modified).catch(() => {
        err = true;
      });
    }
    if (!err) {
      await props.fetchInterfaces();
      onClose();
    }
  };

  const renderItems = () => {
    return (
      <Fragment>
        <div className={b('rows')}>
          <CustomScrollbars
            cssModifier="custom-scrollbars--view-2 custom-scrollbars--hide-hor-scroll"
            scrollbarProps={{
              autoHeightMin: 280,
              autoHeightMax: 460,
              hideTracksWhenNotNeeded: true,
            }}
          >
            <div className="custom-scrollbars__scroll-content">
              {formValues?.items?.map((item, index) => {
                return (
                  <AtSetItem
                    key={index}
                    formats={formats}
                    labels={{
                      formatLabel: lang.FORMAT_LABEL,
                      optinLabel: lang.OPTIN_ADDED_LABEL,
                      arrayLabel: lang.ARRAY_LABEL,
                      boolTrueLabel: lang.BOOL_TRUE_LABEL,
                      boolFalseLabel: lang.BOOL_FALSE_LABEL,
                      itemNameLabel: lang.ITEM_NAME_LABEL,
                      itemReadableNameLabel: lang.ITEM_READABLE_NAME_LABEL,
                    }}
                    placeholders={{
                      formatPlaceholder: lang.FORMAT_PLACEHOLDER,
                      namePlaceholder: lang.NAME_PLACEHOLDER,
                      booleanTruePlaceholder: lang.NAME_PLACEHOLDER,
                      booleanFalsePlaceholder: lang.NAME_PLACEHOLDER,
                    }}
                    errorMessages={{
                      requiredFormatError: lang.REQUIRED_FORMAT_ERROR,
                      requiredNameError: lang.REQUIRED_NAME_ERROR,
                      uniqueNameError: lang.THIS_NAME_IS_TAKEN_ERROR,
                      alreadySavedNameError: lang.THIS_NAME_ALREADY_SAVED,
                    }}
                    tooltipMessage={lang.ARRAY_TOOLTIP}
                    className={classNames('theme-color-8', b('row'))}
                    onDeleteClick={() => {
                      setShowDeletionModal(true);
                      setItemIndexForDeletion(index);
                    }}
                    onEditClick={() => showMappingModal(index)}
                    member={`items[${index}]`}
                    index={index}
                    onKindChange={onKindChange}
                    isBoolean={item?.kind === 'boolean'}
                    isArray={item?.array || false}
                    onFormatChange={handleFormatFieldChange(index)}
                    mappingItems={mappingItems[index]}
                  />
                );
              })}
            </div>
          </CustomScrollbars>
        </div>
        <div className={b('buttons-set-item')}>
          <div
            className="button button--bg-7"
            onClick={() => {
              const newItems = [...formValues.items, {}];
              change('items', newItems);
            }}
          >
            <Icon name="plus" className="button__icon" />
            <span>{lang.ADD_ITEM_BUTTON}</span>
          </div>
          <div className="button button--bg-7" onClick={() => setShowSelectTemplateModal(true)}>
            <span>{langButtonsLabels.SELECT_NEW_INTERFACE_TEMPLATE}</span>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Modal
        show={true}
        className={classNames('theme-color-8', b())}
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TITLE}
      >
        {isInitializing ? (
          <Spinner centered color="current" />
        ) : (
          <form onSubmit={handleSubmit(save)}>
            <div className="main-text break-word">{props.interfaceName}</div>
            {renderItems()}
            <div className={b('submit-error')}>{props.error}</div>
            <div className={b('buttons')}>
              <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onClose}>
                {langButtonsLabels.CANCEL_BUTTON}
              </button>
              <button className="button button--bg-7 modal-window__footer-btn" type="submit">
                {langButtonsLabels.SAVE_BUTTON}
              </button>
            </div>
          </form>
        )}
      </Modal>

      {showDeletionModal && (
        <ConfirmationModal
          show={true}
          title={lang.DELETE_ITEM_MODAL.TITLE}
          buttonConfirmClass="button--bg-7"
          className="theme-color-8"
          confirmText={lang.DELETE_ITEM_MODAL.DELETE_BUTTON}
          onCancel={disableDeletionModal}
          onClose={disableDeletionModal}
          onConfirm={onConfirmDeleteItem}
        />
      )}

      {showSelectTemplateModal && (
        <SelectInterfaceTemplateModal
          show={true}
          interfaceFamily={props.interfaceFamily}
          interfaceTemplates={interfaceTemplatesOptions}
          templateChangeHandler={templateChangeHandler}
          onClose={onCloseSelectTemplateModal}
        />
      )}

      <MapItemsModal
        show={showMapItemsModal}
        onClose={() => setShowMapItemsModal(false)}
        onCancel={() => setShowMapItemsModal(false)}
        onSubmit={onSaveMappingItems}
        interfaceItemIndex={activeInterfaceItemIndex}
        parentFormName={EditInterfaceItemsModalFormName}
        mappingItems={mappingItems}
      />
    </Fragment>
  );
};

EditInterfaceItemsModal.defaultProps = {
  interfaceTemplateId: 0,
  error: null,
};

EditInterfaceItemsModal.propTypes = {
  interfaceId: PropTypes.number,
  interfaceName: PropTypes.string,
  interfaceFamily: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideAddItemAction: PropTypes.bool,
  interfaceTemplateId: PropTypes.number,
  fetchInterfaces: PropTypes.func,
  error: TranslationJsx,
};

export default reduxForm({
  form: EditInterfaceItemsModalFormName,
})(EditInterfaceItemsModal);
