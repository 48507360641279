import * as storage from 'client/services/sessionStorage';

export const getHashViewModeUserId = () => {
  const {hash} = window.location;
  return hash ? +hash.split('=')[1] : 0;
};

export const setHashViewModeQueryParam = (id) => {
  if (id) {
    window.location.href += `#view_user_id=${id}`;
  }
};

export const setViewModeStorageParam = (id) => {
  if (id) {
    storage.setItem('view_mode', true);
    storage.setItem('view_mode_user_id', id);
  }
};

export const getViewModeStorageParam = () => {
  return {
    on: storage.getItem('view_mode'),
    id: storage.getItem('view_mode_user_id'),
  };
};

export const clearViewModeStorageParam = () => {
  storage.removeItem('view_mode');
  storage.removeItem('view_mode_user_id');
};

export const initViewMode = () => {
  setViewModeStorageParam(getHashViewModeUserId());
};
