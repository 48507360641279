import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getGroupsAction() {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.GROUPS}`,
      method: 'GET',
      types: [types.GET_GROUPS_REQUEST, types.GET_GROUPS_SUCCESS, types.GET_GROUPS_ERROR],
    });
}

export function getNetworksAction(groupId) {
  return (dispatch) => {
    if (typeof groupId === 'undefined') {
      return dispatch({type: types.GET_NETWORKS_SUCCESS, payload: {networks: []}});
    }
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.NETWORKS}`,
      method: 'GET',
      types: [types.GET_NETWORKS_REQUEST, types.GET_NETWORKS_SUCCESS, types.GET_NETWORKS_ERROR],
      queryParams: {
        q: {group_id_eq: groupId},
      },
    });
  };
}

export function getRegionsAction(companyId, includeClientless = false) {
  return (dispatch) => {
    if (typeof companyId === 'undefined' || companyId === null) {
      return dispatch({type: types.GET_REGIONS_SUCCESS, payload: {regions: []}});
    }

    const addition = includeClientless ? '&q[client_id_null]=t&q[m]=or' : '';

    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.REGIONS}?q[client_id_eq]=${companyId}${addition}`,
      method: 'GET',
      types: [types.GET_REGIONS_REQUEST, types.GET_REGIONS_SUCCESS, types.GET_REGIONS_ERROR],
    });
  };
}

export function getClientsAction() {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENTS}`,
      method: 'GET',
      types: [types.GET_CLIENTS_REQUEST, types.GET_CLIENTS_SUCCESS, types.GET_CLIENTS_ERROR],
      queryParams: {
        q: {
          add_places_eq: true,
          type_eq: 'Company',
        },
      },
    });
}

export function toggleSubmittedAction() {
  return {
    type: types.TOGGLE_SUBMITTED,
  };
}
