import React, {Component} from 'react';

import {connect} from 'react-redux';

import TitleBlock from 'client/components/common/title-block';

class Logs extends Component {
  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.LOGS;
  }

  render() {
    return (
      <div className="theme-color-operations">
        <div className="page__title-block">
          <TitleBlock theme ellipsis="1">
            <TitleBlock.Item>{this.LANGUAGE.LOGS_TITLE}</TitleBlock.Item>
          </TitleBlock>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({languageState}) => {
  return {
    languageState,
  };
};

export default connect(mapStateToProps)(Logs);
