import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS, SOCIAL_ACCOUNT_TYPES} from 'client/common/config';

import types from './types';

export function getSocialAccounts(queryParams = {}, type = null) {
  let actionTypes = null;
  switch (type) {
    case SOCIAL_ACCOUNT_TYPES.TWITTER:
      actionTypes = [
        types.GET_TWITTER_ACCOUNTS_REQUEST,
        types.GET_TWITTER_ACCOUNTS_SUCCESS,
        types.GET_TWITTER_ACCOUNTS_ERROR,
      ];
      break;
    case SOCIAL_ACCOUNT_TYPES.FACEBOOK:
      actionTypes = [
        types.GET_FACEBOOK_ACCOUNTS_REQUEST,
        types.GET_FACEBOOK_ACCOUNTS_SUCCESS,
        types.GET_FACEBOOK_ACCOUNTS_ERROR,
      ];
      break;
    default:
      actionTypes = [
        types.GET_SOCIAL_ACCOUNTS_REQUEST,
        types.GET_SOCIAL_ACCOUNTS_SUCCESS,
        types.GET_SOCIAL_ACCOUNTS_ERROR,
      ];
      break;
  }
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SOCIAL_ACCOUNTS}`,
      method: 'GET',
      noCache: true,
      types: actionTypes,
      queryParams,
    });
}

export function patchSocialAccount(id, body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SOCIAL_ACCOUNTS}/${id}`,
      method: 'PATCH',
      types: [types.PATCH_SOCIAL_ACCOUNT_REQUEST, types.PATCH_SOCIAL_ACCOUNT_SUCCESS, types.PATCH_SOCIAL_ACCOUNT_ERROR],
      body,
    });
}

export function removeSocialAccount(id) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SOCIAL_ACCOUNTS}/${id}`,
      method: 'DELETE',
      types: [
        types.REMOVE_SOCIAL_ACCOUNT_REQUEST,
        types.REMOVE_SOCIAL_ACCOUNT_SUCCESS,
        types.REMOVE_SOCIAL_ACCOUNT_ERROR,
      ],
    });
}

export function patchSocialAccountsBulk(body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SOCIAL_ACCOUNTS}${API_METHODS.BULK_ACTION}`,
      method: 'PATCH',
      types: [
        types.PATCH_SOCIAL_ACCOUNTS_BULK_REQUEST,
        types.PATCH_SOCIAL_ACCOUNTS_BULK_SUCCESS,
        types.PATCH_SOCIAL_ACCOUNTS_BULK_ERROR,
      ],
      body,
    });
}
