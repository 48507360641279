export default {
  GET_ADMIN_USERS_REQUEST: '@@admin-users/GET_ADMIN_USERS_REQUEST',
  GET_ADMIN_USERS_SUCCESS: '@@admin-users/GET_ADMIN_USERS_SUCCESS',
  GET_ADMIN_USERS_ERROR: '@@admin-users/GET_ADMIN_USERS_ERROR',

  GET_ADMIN_USERS_SUBSIDIARY_REQUEST: '@@admin-users/GET_ADMIN_USERS_SUBSIDIARY_REQUEST',
  GET_ADMIN_USERS_SUBSIDIARY_SUCCESS: '@@admin-users/GET_ADMIN_USERS_SUBSIDIARY_SUCCESS',
  GET_ADMIN_USERS_SUBSIDIARY_ERROR: '@@admin-users/GET_ADMIN_USERS_SUBSIDIARY_ERROR',

  GET_ALL_ADMINS_REQUEST: '@@operations/GET_ALL_ADMINS_REQUEST',
  GET_ALL_ADMINS_SUCCESS: '@@operations/GET_ALL_ADMINS_SUCCESS',
  GET_ALL_ADMINS_ERROR: '@@operations/GET_ALL_ADMINS_ERROR',
};
