export default {
  GET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_REQUEST:
    '@@participations/GET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_REQUEST',
  GET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_SUCCESS:
    '@@participations/GET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_SUCCESS',
  GET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_ERROR: '@@participations/GET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_ERROR',

  DELETE_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_REQUEST:
    '@@participations/DELETE_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_REQUEST',
  DELETE_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_SUCCESS:
    '@@participations/DELETE_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_SUCCESS',
  DELETE_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_ERROR:
    '@@participations/DELETE_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_ERROR',

  SET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_REQUEST:
    '@@participations/SET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_REQUEST',
  SET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_SUCCESS:
    '@@participations/SET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_SUCCESS',
  SET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_ERROR: '@@participations/SET_PARTICIPATION_CLIENT_VISIBILITY_ITEMS_ERROR',

  ADD_PARTICIPATION_CLIENT_VISIBILITY_ITEM_REQUEST: '@@participations/ADD_PARTICIPATION_CLIENT_VISIBILITY_ITEM_REQUEST',
  ADD_PARTICIPATION_CLIENT_VISIBILITY_ITEM_SUCCESS: '@@participations/ADD_PARTICIPATION_CLIENT_VISIBILITY_ITEM_SUCCESS',
  ADD_PARTICIPATION_CLIENT_VISIBILITY_ITEM_ERROR: '@@participations/ADD_PARTICIPATION_CLIENT_VISIBILITY_ITEM_ERROR',

  GET_PARTICIPATION_DISPLAY_ITEM_REQUEST: '@@participations/GET_PARTICIPATION_DISPLAY_ITEM_REQUEST',
  GET_PARTICIPATION_DISPLAY_ITEM_SUCCESS: '@@participations/GET_PARTICIPATION_DISPLAY_ITEM_SUCCESS',
  GET_PARTICIPATION_DISPLAY_ITEM_ERROR: '@@participations/GET_PARTICIPATION_DISPLAY_ITEM_ERROR',

  DELETE_PARTICIPATION_DISPLAY_ITEM_REQUEST: '@@participations/DELETE_PARTICIPATION_DISPLAY_ITEM_REQUEST',
  DELETE_PARTICIPATION_DISPLAY_ITEM_SUCCESS: '@@participations/DELETE_PARTICIPATION_DISPLAY_ITEM_SUCCESS',
  DELETE_PARTICIPATION_DISPLAY_ITEM_ERROR: '@@participations/DELETE_PARTICIPATION_DISPLAY_ITEM_ERROR',

  DELETE_PARTICIPATION_DISPLAY_ITEMS_REQUEST: '@@participations/DELETE_PARTICIPATION_DISPLAY_ITEMS_REQUEST',
  DELETE_PARTICIPATION_DISPLAY_ITEMS_SUCCESS: '@@participations/DELETE_PARTICIPATION_DISPLAY_ITEMS_SUCCESS',
  DELETE_PARTICIPATION_DISPLAY_ITEMS_ERROR: '@@participations/DELETE_PARTICIPATION_DISPLAY_ITEMS_ERROR',

  UPDATE_PARTICIPATION_DISPLAY_ITEMS_BULK_REQUEST: '@@participations/UPDATE_PARTICIPATION_DISPLAY_ITEMS_BULK_REQUEST',
  UPDATE_PARTICIPATION_DISPLAY_ITEMS_BULK_SUCCESS: '@@participations/UPDATE_PARTICIPATION_DISPLAY_ITEMS_BULK_SUCCESS',
  UPDATE_PARTICIPATION_DISPLAY_ITEMS_BULK_ERROR: '@@participations/UPDATE_PARTICIPATION_DISPLAY_ITEMS_BULK_ERROR',
};
