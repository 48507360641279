import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import ToggleButton from 'client/components/common/custom-buttons/toggle-button';
import Field from 'client/components/common/fields/field';

import {TranslationJsx} from 'client/models/language/types';

import './toggle-field.scss';

const ToggleFieldComponent = (props) => {
  const {input, label, disabled, inline, cssModifier, className} = props;

  return (
    <div
      className={cn(
        'toggle-field',
        cssModifier,
        {
          'toggle-field--inline': inline,
        },
        className,
      )}
    >
      {label && <div className="toggle-field__label">{label}</div>}
      <ToggleButton value={input.value || false} onToggle={input.onChange} disabled={disabled} />
    </div>
  );
};

ToggleFieldComponent.propTypes = {
  input: PropTypes.object.isRequired,
  label: TranslationJsx,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  cssModifier: PropTypes.string,
};

ToggleFieldComponent.defaultProps = {
  label: null,
  disabled: false,
  inline: false,
  className: null,
  cssModifier: null,
};

const ToggleField = (props) => <Field {...props} />;

ToggleField.propTypes = {
  ...Field.propTypes,
  inline: PropTypes.bool,
};

ToggleField.defaultProps = {
  ...Field.defaultProps,
  component: ToggleFieldComponent,
  inline: false,
};

export default ToggleField;
