import React, {useMemo} from 'react';

import find from 'lodash/find';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useReduxForm} from 'client/services/hooks';

import {selectInstoreTask} from 'client/ducks/instore-tasks/selectors';

import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container';
import AnimationAnomaliesList from 'client/components/animations/lists/animation-anomalies-list';
import AnimationDaysList from 'client/components/animations/lists/animation-days-list';
import AnimationMonitoringKpiList from 'client/components/animations/lists/animation-monitoring-kpi-list';
import {useFetchTaskKpis} from 'client/components/instore/lists/instore-reporting-animation-list/hooks';

import './animation-main-modal-reporting-tab.scss';

const b = bem('animation-main-modal-reporting-tab');

const AnimationMainModalReportingTab = ({isReporting}) => {
  const {formValues} = useReduxForm(AnimationConfigContainerFormName);
  const task = useSelector(selectInstoreTask);
  const {kpis} = useFetchTaskKpis({task});

  const lists = useMemo(() => {
    const report = [];
    const kpi = [];

    formValues.questions_statistic?.forEach((question) => {
      if (question.nature === 'kpi') {
        kpi.push(question);
      } else {
        report.push(question);
      }
    });

    kpis?.forEach((item) => {
      const question = {...item, title: item.name, data: null};
      if (item.nature === 'kpi' && !find(kpi, {id: item.id})) {
        kpi.push(question);
      } else if (item.nature === 'report' && !find(report, {id: item.id})) {
        report.push(question);
      }
    });

    return {report, kpi};
  }, [formValues.questions_statistic, kpis]);

  return (
    <div className={b()}>
      {!isReporting && (
        <div className={b('row')}>
          <AnimationDaysList className={b('cell')} />
          <AnimationAnomaliesList className={b('cell')} />
        </div>
      )}
      <div className={b('row')}>
        <AnimationMonitoringKpiList
          className={b('cell')}
          tableClassName={b('monitoring-table', {reporting: isReporting})}
          data={lists.report || []}
          type="report"
        />
        <AnimationMonitoringKpiList
          className={b('cell')}
          tableClassName={b('monitoring-table', {reporting: isReporting})}
          data={lists.kpi || []}
          type="kpi"
        />
      </div>
    </div>
  );
};

AnimationMainModalReportingTab.propTypes = {
  isReporting: PropTypes.bool,
};

AnimationMainModalReportingTab.defaultProps = {
  isReporting: false,
};

export default AnimationMainModalReportingTab;
