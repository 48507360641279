import React, {useMemo} from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {NumberField, ToggleField} from 'client/common/fields';
import LayoutPanel from 'client/common/panels/layout-panel';

import ClientTable from 'client/components/common/client-table';
import {ClientTableColumn} from 'client/components/common/client-table/types';

import {PRIZE_TYPES} from 'client/components/prizes/constants';
import {PrizeMap} from 'client/models/prizes/types';

import cssModule from './prize-customization-prizes-table.module.scss';

const b = bem('prize-customization-prizes-table', {cssModule});

type PrizeCustomizationPrizesTableProps = {
  className?: string;
  editable?: boolean;
  prizes: PrizeMap[];
  onSelectPrizeMap: (index: number) => void;
};

const PrizeCustomizationPrizesTable: React.FC<PrizeCustomizationPrizesTableProps> = (props) => {
  const {className, prizes, editable, onSelectPrizeMap} = props;

  const lang = useLanguage('PRIZES.MODALS.CUSTOMIZATION_MODAL.TABLE');

  const columns: ClientTableColumn[] = useMemo(
    () => [
      {
        name: 'prize_internal_name',
        path: 'prize_internal_name',
        label: lang.NAME_COLUMN,
        cellClassName: (item) => b('cell', {active: item.active && item.prize_type !== PRIZE_TYPES.COUPON, name: true}),
        render: ({item, value, index}) => (
          <button
            type="button"
            className={b('name')}
            onClick={() => onSelectPrizeMap(index)}
            disabled={!item.active || item.prize_type === PRIZE_TYPES.COUPON}
          >
            {value}
          </button>
        ),
      },
      {
        name: 'coupon',
        path: 'coupon',
        label: lang.COUPON_COLUMN,
        cellClassName: (item) => b('cell', {active: item.active}),
      },
      {
        name: 'current_stock',
        path: 'current_stock',
        width: 150,
        label: lang.CURRENT_STOCK_COLUMN,
        cellClassName: (item) => b('cell', {active: item.active}),
      },
      {
        name: 'initial_stock',
        path: 'initial_stock',
        label: lang.INITIAL_STOCK_COLUMN,
        width: 150,
        render: ({index, item}) => (
          <NumberField
            hoveringMode
            name={`prizeMaps[${index}].initial_stock`}
            inputClassName={b('field', {
              'initial-stock': true,
              active: item.active && editable,
            })}
            key={item.active?.toString()}
            readOnly={!item.active || !editable}
            min={0}
          />
        ),
      },
      {
        name: 'active',
        path: 'active',
        label: lang.ACTIVE_COLUMN,
        render: ({index}) => {
          return <ToggleField name={`prizeMaps[${index}].active`} color="primary" rounded disabled={!editable} />;
        },
      },
    ],
    [lang, editable, onSelectPrizeMap],
  );

  return (
    <LayoutPanel className={cn(b(), className)}>
      <p className={b('title')}>{lang.PRIZES_TITLE}</p>
      <p className={b('description')}>{lang.DESCRIPTION}</p>
      <ClientTable data={prizes} columns={columns} noDataText={lang.NO_DATA} className={b('table')} />
    </LayoutPanel>
  );
};

export default PrizeCustomizationPrizesTable;
