import React, {useState} from 'react';

import placeholder from 'assets/icons/colored/pic-placeholder.svg';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {createObjectUrl} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';
import FileButton from 'client/common/buttons/file-button';
import ImageContainer from 'client/common/image-container';
import {ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import ConfirmationPopover from 'client/common/popovers/confirmation-popover';

import {getImageProperties} from 'client/components/diy-operation/modals/diy-customization-modal/helpers';
import {TranslationJsx} from 'client/models/language/types';

import validate from './validate';

import cssModule from './image-input.module.scss';

const b = bem('image-input', {cssModule});

const ImageInput = (props) => {
  const {
    className,
    classNames,
    buttonClassName,
    label,
    name,
    value,
    onChange,
    errorMessage,
    warningMessage = '',
    width = 0,
    height = 0,
    acceptFormats,
    placeholderIcon = placeholder,
    keepRatio,
    onBlur,
    disabled,
    disableClassNamePreview,
    imageStyle,
    classNamePreview,
    required = false,
    requirementsComment = '',
    base64 = false,
    checks = {},
    placeholders = {},
    removable = true,
    onRemove,
  } = props;
  const [localErrorMessage, setLocalErrorMessage] = useState({});
  const lang = useLanguage('COMMON');

  const handleChange = async (file) => {
    let errors = {};

    if (!isEmpty(checks)) {
      const imageProps = await getImageProperties(file);

      errors = !isEmpty(checks) ? validate(imageProps.width, imageProps.height, checks) : {};
      setLocalErrorMessage(errors);
    }

    if (isEmpty(errors)) {
      onChange(file);
      onBlur?.();
    }
  };

  const handleRemove = () => {
    onRemove?.();
    onChange(null);
  };

  const placeholderText = value ? placeholders?.withValue ?? lang.REPLACE : placeholders?.noValue ?? lang.UPLOAD;

  return (
    <div className={cn(b({error: !!errorMessage, warning: !!warningMessage}), className)}>
      {label && (
        <label htmlFor={name} className={cn(b('label'), classNames?.label)}>
          {label}
        </label>
      )}
      <div className={cn(b('controls'), classNames?.controls)}>
        <FileButton
          className={cn(b('file-button', buttonClassName))}
          name={name}
          disabled={disabled}
          base64={base64}
          onChange={handleChange}
          acceptFormats={acceptFormats}
          asWrap={true}
          label={
            <div className={b('container', {preview: !value, 'without-value': !value, 'with-value': !!value})}>
              <ImageContainer
                className={cn(b('image-container', {'without-value': !value}), classNames?.imageContainer)}
                classNamePreview={cn(!disableClassNamePreview && b('preview'), classNamePreview)}
                value={value ? createObjectUrl(value) : placeholderIcon}
                width={width}
                height={height}
                keepRatio={!!value && keepRatio}
                imageStyle={value ? imageStyle : {}}
              />
              <span className={b('placeholder-text')}>{placeholderText}</span>
            </div>
          }
        />
        {removable && value && !disabled && (
          <div className={b('delete-wrap')}>
            <ConfirmationPopover
              title={lang.DELETE_THE_IMAGE}
              okButtonProps={{
                label: lang.DELETE_CONFIRM,
                onClick: handleRemove,
              }}
            >
              <AppButton
                className={b('delete-button')}
                title={lang.DELETE}
                iconConfig={{
                  name: 'close',
                  width: 22,
                  height: 22,
                  className: b('delete-icon'),
                }}
                asWrap={true}
              />
            </ConfirmationPopover>
          </div>
        )}
        {requirementsComment && (
          <p className={cn(b('requirements'), classNames?.requirements)}>{requirementsComment}</p>
        )}
      </div>
      {!errorMessage && required && <RequiredLabel />}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {warningMessage && !errorMessage && <WarningMessage warningMessage={warningMessage} />}

      {!isEmpty(localErrorMessage) && (
        <ConfirmationModal
          show={true}
          onConfirm={() => setLocalErrorMessage({})}
          onClose={() => setLocalErrorMessage({})}
          messageClassName={b('message-container')}
          message={Object.values(localErrorMessage).map((item, idx) => (
            <span className={b('message')} key={idx}>
              {item}
            </span>
          ))}
          clientSide={true}
          title={lang.UPLOAD_ERROR}
          buttonConfirm={{
            label: lang.OK,
            color: 'devices',
          }}
        />
      )}
    </div>
  );
};

ImageInput.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.shape({
    label: PropTypes.string,
    imageContainer: PropTypes.string,
    requirements: PropTypes.string,
  }),
  buttonClassName: PropTypes.string,
  label: TranslationJsx,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
  warningMessage: TranslationJsx,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  acceptFormats: PropTypes.string,
  imageStyle: PropTypes.object,
  placeholderIcon: PropTypes.string,
  keepRatio: PropTypes.bool,
  disabled: PropTypes.bool,
  disableClassNamePreview: PropTypes.bool,
  classNamePreview: PropTypes.string,
  onBlur: PropTypes.func,
  requied: PropTypes.bool,
  requirementsComment: PropTypes.string,
  base64: PropTypes.bool,
  checks: PropTypes.object,
  placeholderText: TranslationJsx,
  onRemove: PropTypes.func,
};

ImageInput.defaultProps = {
  className: null,
  classNames: null,
  buttonClassName: null,
  label: null,
  value: null,
  errorMessage: null,
  width: null,
  height: null,
  acceptFormats: '',
  keepRatio: false,
  disabled: false,
  disableClassNamePreview: false,
  classNamePreview: '',
  onBlur: null,
  imageStyle: {},
};

export default ImageInput;
