import {Translation} from 'client/models/language/types';

export const getTitle = (hasEmails: boolean, hasSms: boolean, lang: Record<string, Translation>) => {
  if (hasEmails && !hasSms) {
    return lang.EMAILS_TITLE;
  }

  if (!hasEmails && hasSms) {
    return lang.SMS_TITLE;
  }

  return `${lang.EMAILS_TITLE} / ${lang.SMS_TITLE}`;
};
