import React, {Component} from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {required, phone} from 'client/services/validator';

import {addSMSSender, updateSMSSender, removeSMSSender} from 'client/ducks/sms-senders/actions';

import Icon from 'client/common/icon';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

import './params-phone-modal.scss';

class ParamsPhoneModal extends Component {
  static formName = 'ParamsPhoneModalForm';

  static defaultProps = {
    show: false,
  };

  static propTypes = {
    show: PropTypes.bool,
    lang: PropTypes.object.isRequired,
    data: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    addSMSSender: PropTypes.func.isRequired,
    updateSMSSender: PropTypes.func.isRequired,
    removeSMSSender: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.rules = {
      name: required(this.props.lang.NAME_REQUIRED),
      value: [required(this.props.lang.PHONE_REQUIRED), phone(this.props.lang.PHONE_FORMAT_INCORRECT)],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.props.initialize(this.props.data);
    }
  }

  handleSaveErrors = (response) => {
    const {error, payload} = response;
    const formErrors = {};

    if (!error) {
      return;
    }

    ['name', 'value'].forEach((key) => {
      const item = get(payload, `response.errors.${key}`);
      if (Array.isArray(item) && item.includes('taken')) {
        formErrors[key] = this.props.lang.VALUE_TAKEN;
      }
    });

    if (!isEmpty(formErrors)) {
      throw new SubmissionError({
        ...formErrors,
      });
    }
  };

  save = async (form) => {
    const {data} = this.props;

    if (!data) {
      await this.props.addSMSSender(form).then(this.handleSaveErrors);

      this.props.reset();
    } else {
      await this.props.updateSMSSender(data.id, form).then(this.handleSaveErrors);
    }

    this.props.onConfirm();
  };

  handleRemove = async () => {
    const {data} = this.props;

    await this.props.removeSMSSender(data.id);

    this.props.onConfirm();
  };

  handleClose = () => {
    this.props.onClose();
    this.props.reset();
  };

  render() {
    const {handleSubmit, show, data, lang} = this.props;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-7"
        backdropClassName="modal-window__backdrop"
        onClose={this.handleClose}
        title={lang.TITLE}
      >
        <form onSubmit={handleSubmit(this.save)}>
          <FormFieldWrap>
            <Field
              label={lang.NAME_LABEL + ' *'}
              name="name"
              type="text"
              validate={this.rules.name}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              label={lang.PHONE_LABEL + ' *'}
              name="value"
              type="text"
              validate={this.rules.value}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          {data && (
            <FormFieldWrap>
              <button className="button button--bg-12" type="button" onClick={this.handleRemove}>
                <Icon name="trash" className="button__icon" width={17} height={19} />
                <span>{lang.REMOVE_BUTTON}</span>
              </button>
            </FormFieldWrap>
          )}
          <div className="modal-window__footer modal-window__footer--centered params-phone-modal__footer">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={this.handleClose}>
              {lang.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-2 modal-window__footer-btn" type="submit">
              {data ? lang.SAVE_BUTTON : lang.ADD_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const form = reduxForm({
  form: ParamsPhoneModal.formName,
})(ParamsPhoneModal);

const mapStateToProps = ({languageState}) => ({
  lang: languageState.payload.PARAMETERS.PHONE_MODAL,
});

const mapDispatchToProps = {
  addSMSSender,
  updateSMSSender,
  removeSMSSender,
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
