import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import cssModule from './visuals-placeholder.module.scss';

const b = bem('visuals-placeholder', {cssModule});

type VisualsPlaceholderProps = {
  visual: {
    file_extension?: string;
    kind?: string;
  };
  onClick?: () => void;
  className?: string;
};

const VisualsPlaceholder: React.FC<VisualsPlaceholderProps> = (props) => {
  const {visual, onClick, className} = props;

  return (
    <div className={cn(b({clickable: onClick !== null}), className)} onClick={onClick}>
      {visual?.file_extension || visual?.kind}
    </div>
  );
};

export default VisualsPlaceholder;
