import get from 'lodash/get';

import {PrizeBlockConfigItem} from 'client/components/email-template-editor/types';
import {Prize} from 'client/models/prizes/types';

import {prizeBlockConfig} from './configs';

const buildPrizeElement = (config: PrizeBlockConfigItem, language?: string, prize?: Prize) => {
  const {type, style, valuePath, varName} = config;
  const preparedValuePath = valuePath.replace('{{lang}}', language || '');
  const preparedValue = prize ? get(prize, preparedValuePath) : `{{var:${varName}}}`;
  const width = config.name === 'visual' ? ' width="168px"' : '';

  if (!preparedValue) {
    return '';
  }

  const result =
    type === 'text'
      ? `<mj-text><p style="${style}">${preparedValue}</p></mj-text>`
      : `<mj-image src="${preparedValue}"${width}></mj-image>`;

  if (!prize) {
    return `<mj-raw>{% if var:${varName}:false %}</mj-raw>${result}<mj-raw>{% endif %}</mj-raw>`;
  }

  return result;
};

export default (language?: string, prize?: Prize) => {
  return prizeBlockConfig.reduce((res, item) => res + buildPrizeElement(item, language, prize), '');
};
