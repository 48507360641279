import React, {PureComponent} from 'react';

import cx from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm} from 'redux-form';

import {scenarioVariableSave} from 'client/services/serverValitator';

import {updateScenarioVariable, deleteScenarioVariable} from 'client/ducks/scenario/actions';

import Modal from 'client/common/modals/modal';

import AtsVarForm, {FORM_NAME} from '../../forms/ats-var-form/ats-var-form';

import './ats-edit-var-modal.scss';

class AtsEditVarModal extends PureComponent {
  static propTypes = {
    show: PropTypes.bool,
    languageState: PropTypes.object.isRequired,
    data: PropTypes.object,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    updateScenarioVariable: PropTypes.func.isRequired,
    deleteScenarioVariable: PropTypes.func.isRequired,
    autotaskId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    show: false,
    onConfirm: () => {},
    onClose: () => {},
    onCancel: () => {},
    onDelete: () => {},
  };

  LANGUAGE = this.props.languageState.payload.AUTOTASK_SCENARIO.EDIT_VAR_MODAL;

  save = (data) => {
    return this.props
      .updateScenarioVariable(
        this.props.data.id,
        {
          scenario_id: this.props.data.scenario_id,
          name: data.name,
          source_type: data.source_type,
          source_id: data.source_id,
          required: data.required,
        },
        {
          include: [
            {
              source: ['interface', 'scenario', 'scenario_step'],
            },
          ],
        },
      )
      .then(this.props.onConfirm)
      .catch(scenarioVariableSave);
  };

  delete = () => {
    return this.props.deleteScenarioVariable(this.props.data.id).then(this.props.onDelete);
  };

  render() {
    const {onClose, onCancel, show, data, handleSubmit, submitting, valid, pristine, autotaskId} = this.props;

    const deleteDisabled = !get(data, 'is_not_used', false);

    return (
      <Modal
        show={show}
        onClose={onClose}
        dialogClassName="modal-window--width-1 theme-color-7 ats-edit-var-modal"
        title={this.LANGUAGE.TITLE}
      >
        <form onSubmit={handleSubmit(this.save)}>
          <AtsVarForm data={data} autotaskId={autotaskId} />
          <div className="modal-window__footer ats-edit-var-modal__footer">
            <button
              type="button"
              className={cx('button', `button--bg-${deleteDisabled ? '11' : '12'}`)}
              onClick={this.delete}
            >
              {this.LANGUAGE.DELETE_BUTTON}
            </button>
            <button type="button" className="button button--bg-11" onClick={onCancel}>
              {this.LANGUAGE.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-13" type="submit" disabled={submitting || !valid || pristine}>
              {this.LANGUAGE.CONFIRM_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const AtsEditVarModalForm = reduxForm({
  form: FORM_NAME,
})(AtsEditVarModal);

const mapStateToProps = ({languageState}) => ({
  languageState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateScenarioVariable,
      deleteScenarioVariable,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AtsEditVarModalForm);
