import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {set} from 'client/common/meta/meta.actions';

import NotFound from 'client/components/not-found';

// TODO: waiting for design for PageNotFound
const PageNotFound = () => {
  const lang = useLanguage('PAGE_NOT_FOUND');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set({title: lang.TITLE}));
  }, [dispatch, lang.TITLE]);

  return <NotFound title={lang.TITLE} />;
};

export default PageNotFound;
