import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {email, phone} from 'client/services/validator';

import {AnimationBrandInfoForm} from './index';

type ValidateMethod = (
  formValues: AnimationBrandInfoForm,
  config: {lang: LangsByPath<['ANIMATIONS.ERRORS']>},
) => Record<string, string>;

export default ((formValues = {}, {lang}) => {
  const errors: Partial<Record<keyof AnimationBrandInfoForm, string>> = {};

  errors.contact_email = email(lang.EMAIL_FORMAT_INCORRECT)(formValues.contact_email || '');
  errors.security_email = email(lang.EMAIL_FORMAT_INCORRECT)(formValues.security_email || '');

  errors.contact_phone = phone(lang.PHONE_FORMAT_INCORRECT)(formValues.contact_phone || '');
  errors.security_fax = phone(lang.PHONE_FORMAT_INCORRECT)(formValues.security_fax || '');

  return errors;
}) as ValidateMethod;
