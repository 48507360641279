import moment from 'moment';

import {VALUE_FORMAT} from './constants';

const getFirstWeek = (value) => {
  let week = moment().startOf('year').startOf('isoWeek');

  while (week.week() !== 1) {
    week.add(7, 'days').startOf('isoWeek');
  }

  week = week.format(VALUE_FORMAT);

  if (!value) {
    return week;
  }

  return week < value ? week : value;
};

const getLastWeek = (value) => {
  const week = moment().endOf('year').startOf('isoWeek').format(VALUE_FORMAT);

  if (!value) {
    return week;
  }

  return week > value ? week : value;
};

export const getWeeks = (value) => {
  let start = getFirstWeek(value);
  const end = getLastWeek(value);

  const weeks = [];
  while (start <= end) {
    weeks.push(start);
    start = moment(start).add(7, 'days').format(VALUE_FORMAT);
  }

  return weeks;
};

export const getCurrentWeek = () => moment().startOf('isoWeek').format(VALUE_FORMAT);
