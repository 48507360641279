const campaignRegex = /(Campaign|Campagne) [0-9]{1,}/;

export const getNewCampaignName = (campaigns, campaignTranslation) => {
  const names = campaigns.allIds
    .map((id) => campaigns.byId[id].name)
    .filter((name) => name.search(campaignRegex) !== -1)
    .map((name) => name.match(campaignRegex)[0]);

  if (names.length) {
    const numbers = Math.max(...names.map((name) => +name.split(' ')[1]));

    return `${campaignTranslation} ${numbers + 1}`;
  }

  return `${campaignTranslation} 1`;
};
