import types from './types';

export function showTaskHeader() {
  return {
    type: types.SHOW_TASK_HEADER,
  };
}
export function hideTaskHeader() {
  return {
    type: types.HIDE_TASK_HEADER,
  };
}
