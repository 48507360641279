import {EXCLUDABLE_KEYS} from './constants';

export const roundValue = (value, digit = 2) => {
  const numValue = +value;
  if (isNaN(numValue)) {
    return value;
  }
  const digitKoef = Math.pow(10, digit);
  return Math.round(numValue * digitKoef) / digitKoef;
};

export const getIntervalGraphData = (data, step) => {
  const numbericKeys = Object.keys(data)
    .filter((key) => !EXCLUDABLE_KEYS.includes(key))
    .sort((a, b) => a - b);

  const intervals = Array.from(numbericKeys, (item, index) => ({
    min: +item,
    max: (index + 1) * step - 1,
    total: data[item],
  }));
  const mappedData = intervals.map(({min, max, total}) => ({
    label: [min, max],
    value: total,
  }));
  const undefinedKeys = Object.keys(data).filter((key) => EXCLUDABLE_KEYS.includes(key) && data[key]);
  if (undefinedKeys.length) {
    undefinedKeys.forEach((key) =>
      mappedData.push({
        value: data[key],
        label: key,
      }),
    );
  }
  return mappedData;
};
