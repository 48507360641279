import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import ClientTable from 'client/components/common/client-table';

import {
  AnimationStatusCell,
  AnimationBrandPlaceCell,
  AnimationUserCell,
  AnimationHrCell,
  AnimationInstallCell,
  AnimationAnomalyCell,
  AnimationActionsCell,
} from 'client/components/instore/cells';
import {getAnimationDate} from 'client/components/instore/helpers';

import './instore-animation-table.scss';

const b = bem('instore-animation-table');

const InstoreAnimationTable = (props) => {
  const {animations, onEdit, onSortChange, sortField, sortOrder, loading} = props;
  const lang = useLanguage('INSTORE_TASK.TABLES.INSTORE_ANIMATION_TABLE');

  return (
    <ClientTable
      className={b()}
      data={animations}
      onSortChange={onSortChange}
      sortField={sortField}
      sortOrder={sortOrder}
      loading={loading}
      columns={[
        {
          name: 'status',
          label: lang.STATUS,
          sortable: true,
          render: ({item}) => <AnimationStatusCell item={item} />,
        },
        {
          name: 'place',
          label: lang.PLACE,
          sortable: true,
          render: AnimationBrandPlaceCell,
        },
        {
          name: 'city',
          path: 'place.city_name',
          label: lang.CITY,
          sortable: true,
        },
        {
          name: 'user',
          path: 'instore_task_access.membership',
          label: lang.USER,
          sortable: true,
          render: AnimationUserCell,
          hintRender: (item) => item.instore_task_access.membership.client_user_full_name,
        },
        {
          name: 'from',
          path: 'animation_days',
          label: lang.FROM,
          sortable: true,
          formatter: (value) => getAnimationDate(value, 'from'),
        },
        {
          name: 'to',
          path: 'animation_days',
          label: lang.TO,
          sortable: true,
          formatter: (value) => getAnimationDate(value, 'to'),
        },
        {
          name: 'hr',
          label: lang.HR,
          zeroPaddings: true,
          render: AnimationHrCell,
        },
        {
          name: 'install',
          label: lang.INSTALL,
          zeroPaddings: true,
          render: AnimationInstallCell,
        },
        {
          name: 'anomalies',
          label: lang.ANOMALIES,
          zeroPaddings: true,
          render: AnimationAnomalyCell,
        },
        {
          name: 'actions',
          zeroPaddings: true,
          render: ({item}) => <AnimationActionsCell item={item} onEdit={onEdit} />,
        },
      ]}
    />
  );
};

InstoreAnimationTable.propTypes = {
  animations: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  loading: PropTypes.bool,
};

InstoreAnimationTable.defaultProps = {
  sortField: null,
  sortOrder: null,
  loading: false,
};

export default InstoreAnimationTable;
