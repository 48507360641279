import types from './types';

const initialState = {
  trackers: [],
  tracker: {},
  temporaryTracker: {},
};

export default function (_state = initialState, action) {
  const state = Object.assign({}, _state);

  switch (action.type) {
    case types.GET_TRACKERS_SUCCESS:
      state.trackers = action.payload.twitter_hashtag_trackers;
      return state;

    case types.GET_TRACKER_SUCCESS:
      state.tracker = action.payload.twitter_hashtag_tracker;
      return state;

    case types.FETCH_NEW_TRACKER_SUCCESS:
      state.temporaryTracker = action.payload.twitter_hashtag_tracker;
      return state;

    default:
      return state;
  }
}
