import React, {useState, useCallback} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import CountBlock from 'client/common/count-block';
import ToggleInput from 'client/common/inputs/toggle-input';

// components
import Icon from 'client/components/common/icon';
import InfoBlock from 'client/components/common/info-block';

import {isNewRegionId} from './helpers';

import RegionsList from '../../tables/regions-list';

import './regions-card.scss';

const b = bem('regions-card');

const RegionsCard = (props) => {
  const {data, isAdmin, onSave, toggleValue, onToggleChange, isLoading, toggleEnabled} = props;

  const [isEditMode, setIsEditMode] = useState(false);

  const lang = useLanguage('REGIONS');

  const handleSaveClick = useCallback(
    (regions) => {
      const normalizedRegions = regions
        .filter((region) => Boolean(region.name))
        .map((region) => {
          const {id, name, _destroy} = region;

          if (isNewRegionId(id)) {
            return {name};
          }

          return {id, name, _destroy};
        });

      onSave(normalizedRegions).then(() => setIsEditMode(false));
    },
    [onSave],
  );

  const handleToggleChange = useCallback(
    (event) => {
      const {checked} = event.target;

      onToggleChange(checked);
    },
    [onToggleChange],
  );

  const isEditButtonVisible = isAdmin && !isEditMode && data.length !== 0;

  return (
    <InfoBlock colorTheme="regions">
      <div className={b('title')}>
        <InfoBlock.Title>{lang.REGIONS_TITLE}</InfoBlock.Title>
        <ToggleInput
          className={b('network-toggle')}
          color="operationsAdmin"
          label={lang.CLIENT_NETWORK_LABEL}
          onChange={handleToggleChange}
          checked={toggleValue}
          disabled={!toggleEnabled}
        />
      </div>
      <InfoBlock.Content>
        <InfoBlock.Header className="clearfix">
          <CountBlock itemName={lang.REGIONS_TITLE} className="pull-left" num={data.length} />
          {isEditButtonVisible && (
            <button className="button button--circle button--bg-3 pull-right" onClick={() => setIsEditMode(true)}>
              <Icon name="edit" className="button__icon" width={20} height={20} />
            </button>
          )}
        </InfoBlock.Header>
        <InfoBlock.Body>
          <RegionsList
            editMode={isEditMode}
            setIsEditMode={setIsEditMode}
            onSaveClick={handleSaveClick}
            data={data}
            lang={lang}
            isLoading={isLoading}
          />
        </InfoBlock.Body>
      </InfoBlock.Content>
    </InfoBlock>
  );
};

RegionsCard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onSave: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  toggleValue: PropTypes.bool,
  onToggleChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  toggleEnabled: PropTypes.bool,
};

RegionsCard.defaultProps = {
  data: [],
  isAdmin: false,
  toggleValue: false,
  isLoading: false,
  toggleEnabled: false,
};

export default RegionsCard;
