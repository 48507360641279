import React from 'react';

import PropTypes from 'prop-types';

function BCTaskData(props) {
  const {data, taskType} = props;

  return (
    <div className="content-panel__panel bc-list__panel">
      <div className="content-panel__panel-inner">
        <div className="content-panel__row">
          {data
            .filter((item) => item.forTaskType.includes(taskType))
            .map((item, key) => (
              <div className="bc-list__col" key={key}>
                {item.renderMethod()}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

BCTaskData.propTypes = {
  taskType: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      renderMethod: PropTypes.func.isRequired,
      forTaskType: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
};

export default BCTaskData;
