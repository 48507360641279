export default {
  GET_CUSTOM_COLUMNS_REQUEST: '@@custom-columns/GET_CUSTOM_COLUMNS_REQUEST',
  GET_CUSTOM_COLUMNS_SUCCESS: '@@custom-columns/GET_CUSTOM_COLUMNS_SUCCESS',
  GET_CUSTOM_COLUMNS_ERROR: '@@custom-columns/GET_CUSTOM_COLUMNS_ERROR',
  GET_ALL_CUSTOM_COLUMNS_REQUEST: '@@custom-columns/GET_ALL_CUSTOM_COLUMNS_REQUEST',
  GET_ALL_CUSTOM_COLUMNS_SUCCESS: '@@custom-columns/GET_ALL_CUSTOM_COLUMNS_SUCCESS',
  GET_ALL_CUSTOM_COLUMNS_ERROR: '@@custom-columns/GET_ALL_CUSTOM_COLUMNS_ERROR',

  FLUSH_CUSTOM_COLUMNS_ALL_STATE: '@@custom-columns/FLUSH_CUSTOM_COLUMNS_ALL_STATE',

  DELETE_CUSTOM_COLUMN_REQUEST: '@@custom-columns/DELETE_CUSTOM_COLUMN_REQUEST',
  DELETE_CUSTOM_COLUMN_SUCCESS: '@@custom-columns/DELETE_CUSTOM_COLUMN_SUCCESS',
  DELETE_CUSTOM_COLUMN_ERROR: '@@custom-columns/DELETE_CUSTOM_COLUMN_ERROR',
};
