import update from 'immutability-helper';

import {callApi} from 'client/services/call-api';
import {getAuthHeaders} from 'client/services/helpers';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getScenarios(queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SCENARIOS}`,
      method: 'GET',
      types: [types.LIST_LOADING, types.LIST_LOADED, types.LIST_LOAD_FAILED],
      queryParams,
    });
  };
}

export function getPossibleTestVariables(scenarioId, queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SCENARIOS}/${scenarioId}${API_METHODS.POSSIBLE_VARIABLES}`,
      method: 'GET',
      types: [
        types.GET_POSSIBLE_TEST_VARIABLES_REQUEST,
        types.GET_POSSIBLE_TEST_VARIABLES_SUCCESS,
        types.GET_POSSIBLE_TEST_VARIABLES_ERROR,
      ],
      queryParams,
    });
  };
}

export function getScenario(id, queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SCENARIOS}/${id}`,
      method: 'GET',
      headers: {
        'cache-control': 'no-cache',
      },
      types: [types.LOADING, types.LOADED, types.LOAD_FAILED],
      queryParams,
    });
  };
}

export function updateScenario(id, body) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.SCENARIOS}/${id}`,
        method: 'PUT',
        types: [types.UPDATING, types.UPDATED, types.UPDATE_FAILED],
        body,
      },
      true,
    );
  };
}

export function deleteScenario(id) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.SCENARIOS}/${id}`,
        method: 'DELETE',
        types: [types.DELETING, types.DELETED, types.DELETE_FAILED],
      },
      true,
    );
  };
}

export function getScenarioVariables(queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SCENARIO_VARIABLES}`,
      method: 'GET',
      types: [types.VARIABLE_LIST_LOADING, types.VARIABLE_LIST_LOADED, types.VARIABLE_LIST_LOAD_FAILED],
      queryParams,
    });
  };
}

export function createScenarioVariable(body, queryParams = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.SCENARIO_VARIABLES}`,
        method: 'POST',
        types: [types.VARIABLE_CREATING, types.VARIABLE_CREATED, types.VARIABLE_CREATE_FAILED],
        body,
        queryParams,
      },
      true,
    );
  };
}

export function updateScenarioVariable(id, body, queryParams = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.SCENARIO_VARIABLES}/${id}`,
        method: 'PUT',
        types: [types.VARIABLE_UPDATING, types.VARIABLE_UPDATED, types.VARIABLE_UPDATE_FAILED],
        body,
        queryParams,
      },
      true,
    );
  };
}

export function deleteScenarioVariable(id) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.SCENARIO_VARIABLES}/${id}`,
        method: 'DELETE',
        types: [
          types.VARIABLE_DELETING,
          {
            type: types.VARIABLE_DELETED,
            meta: {id},
          },
          types.VARIABLE_DELETE_FAILED,
        ],
      },
      true,
    );
  };
}

export function getScenarioSteps(queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SCENARIO_STEPS}`,
      method: 'GET',
      types: [types.STEP_LIST_LOADING, types.STEP_LIST_LOADED, types.STEP_LIST_LOAD_FAILED],
      queryParams,
    });
  };
}

export function createScenarioStep(idInStore, body, queryParams = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.SCENARIO_STEPS}`,
        method: 'POST',
        types: [
          types.STEP_CREATING,
          {
            type: types.STEP_CREATED,
            meta: {id: idInStore},
          },
          types.STEP_CREATE_FAILED,
        ],
        body,
        queryParams,
      },
      true,
    );
  };
}

export function getScenarioStep(id, queryParams = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.SCENARIO_STEPS}/${id}`,
        method: 'GET',
        types: [types.STEP_UPDATING, types.STEP_UPDATED, types.STEP_UPDATE_FAILED],
        queryParams,
      },
      true,
    );
  };
}

export function updateScenarioStep(id, body, queryParams = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.SCENARIO_STEPS}/${id}`,
        method: 'PUT',
        types: [types.STEP_UPDATING, types.STEP_UPDATED, types.STEP_UPDATE_FAILED],
        body,
        queryParams,
      },
      true,
    );
  };
}

export function deleteScenarioStep(id) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.SCENARIO_STEPS}/${id}`,
        method: 'DELETE',
        types: [
          types.STEP_DELETING,
          {
            type: types.STEP_DELETED,
            meta: {id},
          },
          types.STEP_DELETE_FAILED,
        ],
      },
      true,
    );
  };
}

export function addScenarioStepIntoStore(data) {
  return (dispatch) => dispatch({type: types.STEP_ADD_INTO_STORE, payload: data});
}

export function sortScenarioStepsInStore(oldIndex, newIndex, startPosition) {
  return {
    type: types.STEP_SORT_IN_STORE,
    payload: {
      oldIndex,
      newIndex,
      startPosition,
    },
  };
}

export function deleteScenarioStepFromStore(id) {
  return (dispatch) => dispatch({type: types.STEP_DELETED, meta: {id}});
}

export function deleteNotSavedScenarioSteps() {
  return (dispatch) => dispatch({type: types.STEP_LIST_REMOVE_NOT_SAVED});
}

export function getScenarioExecutionLogs(queryParams = {}) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SCENARIO_EXECUTION_LOGS}`,
      method: 'GET',
      types: [types.EXECUTION_LOG_LIST_LOADING, types.EXECUTION_LOG_LIST_LOADED, types.EXECUTION_LOG_LIST_LOAD_FAILED],
      queryParams,
    });
  };
}

export function deleteBranchScenarioStepCondition(scenarioStepId, conditionId) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.BRANCH_SCENARIO_STEP_CONDITIONS}/${conditionId}`,
        method: 'DELETE',
        types: [
          types.BRANCH_SCENARIO_STEP_DELETING,
          {
            type: types.BRANCH_SCENARIO_STEP_DELETED,
            meta: {
              scenarioStepId,
              conditionId,
            },
          },
          types.BRANCH_SCENARIO_STEP_DELETE_FAILED,
        ],
      },
      true,
    );
  };
}

export function deleteLotteryScenarioStepPrize(scenarioStepId, prizeId) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LOTTERY_SCENARIO_STEP_PRIZES}/${prizeId}`,
        method: 'DELETE',
        types: [
          types.INSTANT_LOTTERY_SCENARIO_STEP_DELETING,
          {
            type: types.INSTANT_LOTTERY_SCENARIO_STEP_DELETED,
            meta: {
              scenarioStepId,
              prizeId,
            },
          },
          types.INSTANT_LOTTERY_SCENARIO_STEP_DELETE_FAILED,
        ],
      },
      true,
    );
  };
}

export function uploadSocialScenarioStepImage(scenarioStepId, body) {
  return (dispatch) => {
    dispatch({
      type: types.FILE_SCENARIO_STEP_IMAGE_UPLOADING,
    });

    const headers = update(getAuthHeaders(), {
      $unset: ['Content-Type'],
    });

    return fetch(`${API_PATH}${API_METHODS.FILE_SOCIAL_SCENARIO_STEP_IMAGES}`, {
      method: 'POST',
      headers,
      body,
    })
      .then(() => {
        dispatch({
          type: types.FILE_SCENARIO_STEP_IMAGE_UPLOADED,
          meta: {
            scenarioStepId,
            body,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: types.FILE_SCENARIO_STEP_IMAGE_UPLOAD_FAILED,
        });
      });
  };
}

export function deleteSocialScenarioStepImage(scenarioStepId, imageId) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.SOCIAL_SCENARIO_STEP_IMAGES}/${imageId}`,
        method: 'DELETE',
        types: [
          types.SOCIAL_SCENARIO_STEP_IMAGE_DELETING,
          {
            type: types.SOCIAL_SCENARIO_STEP_IMAGE_DELETED,
            meta: {
              scenarioStepId,
              imageId,
            },
          },
          types.SOCIAL_SCENARIO_STEP_IMAGE_DELETE_FAILED,
        ],
      },
      true,
    );
  };
}

export function testScenario(id, body) {
  return (dispatch) => {
    dispatch({
      type: types.TEST_SCENARIO_REQUEST,
    });

    const headers = update(getAuthHeaders(), {
      $unset: ['Content-Type'],
    });

    return fetch(`${API_PATH}${API_METHODS.SCENARIOS}/${id}/test`, {
      method: 'POST',
      headers,
      body,
    })
      .then((data) => {
        if (!data.ok) {
          throw new Error(data.statusText);
        }

        return data.json();
      })
      .then((data) => {
        dispatch({
          type: types.TEST_SCENARIO_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.TEST_SCENARIO_ERROR,
          error,
        });
      });
  };
}

export function getPrizes(queryParams) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PARTICIPATION_PRIZES}${API_METHODS.PARTICIPATION_PRIZENAMES_AVAILABLE}`,
      method: 'GET',
      types: [types.GET_PRIZES_REQUEST, types.GET_PRIZES_SUCCESS, types.GET_PRIZES_ERROR],
      queryParams,
    });
  };
}

export function getColumnAdapters(queryParams) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.COLUMN_ADAPTER_PERMITTED}`,
      method: 'GET',
      types: [types.GET_COLUMN_ADAPTERS_REQUEST, types.GET_COLUMN_ADAPTERS_SUCCESS, types.GET_COLUMN_ADAPTERS_ERROR],
      queryParams,
    });
  };
}

export function getParticipationColumnAdapters(autotaskId) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.COLUMN_ADAPTER_PERMITTED}`,
      method: 'GET',
      types: [
        types.GET_PARTICIPATION_COLUMN_ADAPTERS_REQUEST,
        types.GET_PARTICIPATION_COLUMN_ADAPTERS_SUCCESS,
        types.GET_PARTICIPATION_COLUMN_ADAPTERS_ERROR,
      ],
      queryParams: {
        automation_task_id: autotaskId,
        scope: 'participations',
      },
    });
  };
}
