import React, {useCallback} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {changeAutotaskValidated} from 'client/ducks/autotask/actions';
import {selectOperation} from 'client/ducks/operations/selectors';

import ConfirmationModal from 'client/common/modals/confirmation-modal';

import {DIY_OPERATION_TYPES_WITH_URL} from 'client/components/diy-operation/constants';
import DiyOpStatusControl from 'client/components/diy-operation/controls/diy-op-status-control';
import {Autotask} from 'client/models/autotask/types';
import {isOperationArchived} from 'client/models/operations/helpers';
import {ApiDispatch} from 'client/types';

import './diy-op-validation-block.scss';

const b = bem('diy-op-validation-block');

type DiyOpValidationBlockProps = {
  autotask: Autotask;
  type: string;
  fetchAutotask: () => void;
  fetchOperation: () => void;
  handleOpenModal: (key: string | null) => void;
  url?: string;
};

const DiyOpValidationBlock: React.FC<DiyOpValidationBlockProps> = (props) => {
  const {autotask, fetchAutotask, fetchOperation, type, handleOpenModal, url} = props;

  const operation = useSelector(selectOperation);

  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_VALIDATION_BLOCK');
  const dispatch: ApiDispatch = useDispatch();
  const [showConfirm, toggleConfirm] = useToggle(false);

  const handleConfirm = useCallback(async () => {
    await dispatch(changeAutotaskValidated(autotask?.id, true));
    fetchAutotask();
    fetchOperation(); // operation.editable param checks for autotask.validated too
    toggleConfirm();
  }, [autotask?.id, dispatch, fetchAutotask, fetchOperation, toggleConfirm]);

  const deviceState = operation?.client_interface?.device_state || '';
  const webAppState = operation?.client_interface?.web_app?.state || '';
  const isPublished = useCallback((state: string) => {
    return ['published', 'republished'].includes(state);
  }, []);

  const hasOperationUrl = Object.values(DIY_OPERATION_TYPES_WITH_URL).includes(type);
  const isHybrid = operation?.client_interface?.device && operation?.client_interface?.online;
  const validatedDisabled = autotask?.validated || (hasOperationUrl ? !url : false);
  const editUrlButtonDisabled = !!url || !autotask?.validated;
  const showEditUrlButton = !url || !autotask?.validated;

  return (
    <div className={b()}>
      <DiyOpStatusControl
        label={autotask?.validated ? lang.VALIDATED : lang.VALIDATE}
        handleOpenModal={handleOpenModal}
        onClick={toggleConfirm}
        disabled={validatedDisabled}
        editUrlButtonDisabled={editUrlButtonDisabled}
        isPublished={isPublished(hasOperationUrl ? webAppState : deviceState) && autotask?.validated}
        showEditUrlButton={showEditUrlButton}
        hasOperationUrl={hasOperationUrl}
        type={type}
        url={url}
        show={!isHybrid}
        isOperationArchived={isOperationArchived(operation)}
      />

      <ConfirmationModal
        title={autotask?.validated ? lang.INVALIDATED_TITLE : lang.VALIDATED_TITLE}
        show={showConfirm}
        onClose={toggleConfirm}
        onConfirm={handleConfirm}
        messageClassName={b('message')}
        message={lang.CONFIRM_VALIDATION_MESSAGE}
        buttonConfirm={{
          label: autotask?.validated ? lang.INVALIDATED_CONFIRM : lang.VALIDATED_CONFIRM,
          color: 'clients',
        }}
        buttonCancel={{
          label: lang.CANCEL_MODAL,
          color: 'clients',
          transparent: true,
        }}
        clientSide
      />
    </div>
  );
};

export default DiyOpValidationBlock;
