import React, {useCallback, useEffect, useState} from 'react';

import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'qs';
import {connect, useDispatch} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';

import bem from 'client/services/bem';
import {getClientPage, getFileSize} from 'client/services/helpers';

import {patchDiaporamaMappingItems} from 'client/ducks/slideshows/actions';
import {patchVisual, getAutomaticVisualGroups} from 'client/ducks/visuals/actions';

import {VISUALS_STATUS_COLORS, VISUAL_INTERACTION_TYPES} from 'client/common/config';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import CustomLink from 'client/components/common/custom-link';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Hashtag from 'client/components/common/hashtag';
import Icon from 'client/components/common/icon';

import {VIS_DATA_COLUMNS} from './constants';

import './vis-data-grid.scss';

const b = bem('vis-data-grid');

const VisDataGrid = (props) => {
  const {
    perPage,
    meta,
    onPageChange,
    onSortChange,
    displayItems,
    selectedIds,
    onSelect,
    onLeadClick,
    isColAdapterInDiaporama,
    data,
    diaporamaValues,
    toolbar,
    isAdmin,
    onSettingsClick,
    onPreviewClick,
    getVisualOrDiaporamaValue,
    getArrivingType,
    updateMe,
    sort,
  } = props;

  const dispatch = useDispatch();

  const calcVisualsTags = useCallback(
    () =>
      isColAdapterInDiaporama('tags')
        ? []
        : data.map((visual) => ({
            value: visual.tags,
            id: visual.id,
          })),
    [data, isColAdapterInDiaporama],
  );

  const calcDiaporamaTags = useCallback(
    () =>
      isColAdapterInDiaporama('tags')
        ? data.map((visual) => ({
            value: diaporamaValues.tags?.value,
            id: visual.id,
          }))
        : [],
    [data, diaporamaValues.tags?.value, isColAdapterInDiaporama],
  );

  const [visualsTags, setVisualsTags] = useState(calcVisualsTags);
  const [diaporamaTags, setDiaporamaTags] = useState(calcDiaporamaTags);

  const {search} = useLocation();
  const queryParams = qs.parse(search);
  const {configId} = queryParams;

  const langPayload = props.languageState.payload;
  const LANGUAGE = langPayload.VISUALS.TABLE;
  const LANGUAGE_FILE_TYPES = langPayload.VISUALS.FILTERS_MODAL.FILE_TYPES;
  const client_column_adapters = VIS_DATA_COLUMNS;

  useEffect(() => {
    setDiaporamaTags(calcDiaporamaTags());
    setVisualsTags(calcVisualsTags());
  }, [calcDiaporamaTags, calcVisualsTags, configId]);

  const loadAutomaticGroups = (visual) => dispatch(getAutomaticVisualGroups(visual.id));

  const renderFilePreview = ({value: file, item: visual}) => {
    let url = null;
    if (visual.kind !== 'text') {
      url = file.url;
    }
    return (
      <div
        className={b('cell', ['image', 'preview'])}
        style={{
          backgroundImage: `url(${url})`,
        }}
        onClick={() => onPreviewClick(visual)}
      />
    );
  };

  const renderType = ({value: type}) => (
    <span>{isColAdapterInDiaporama('kind') ? diaporamaValues.kind.value : LANGUAGE_FILE_TYPES[type]}</span>
  );

  const renderText = ({value: text, column}) => {
    return (
      <div className={cn(b('cell', ['text']), 'ellipsis-text')} title={text} style={{maxWidth: column?.width}}>
        {text}
      </div>
    );
  };

  const renderFileSize = ({value: cell, item: row, column}) => {
    const size = +getVisualOrDiaporamaValue('filesize', row, diaporamaValues) || cell;
    return renderText({value: getFileSize(size), column});
  };

  const renderCreated = ({value: createdAt, item: row}) => {
    const dateCreated = getVisualOrDiaporamaValue('created_at', row, diaporamaValues) || createdAt;
    return (
      <div>
        <div className="bold">{moment(dateCreated).format('DD/MM/YYYY')}</div>
        <div>{moment(dateCreated).format('HH:mm:ss')}</div>
      </div>
    );
  };

  const renderDate = ({value: date}) => {
    return (
      <div>
        <div className="bold">{date ? moment(date).format('DD/MM/YYYY') : LANGUAGE.NO_VALUE}</div>
      </div>
    );
  };

  const renderInterface = ({item: {interaction}, column}) => {
    const name = interaction && interaction.interface.name;
    if (isColAdapterInDiaporama('interface')) {
      return diaporamaValues.interface.value && renderText({value: diaporamaValues.interface.value, column});
    }
    return name && renderText({value: name, column});
  };

  const renderClient = ({item: {interaction}}) => {
    const client = interaction && interaction.interface.automation_task.operation.client;
    return (
      client && (
        <Link to={`${getClientPage(client)}/${client.id}`} className="link main-text">
          {client.name}
        </Link>
      )
    );
  };

  const renderArrivingType = ({value: arrivedType, item: row, column}) => {
    return renderText({value: getArrivingType(row) || arrivedType, column});
  };

  const renderOrientation = ({value: orientation, column}) => {
    const finalOrientation = isColAdapterInDiaporama('orientation')
      ? diaporamaValues.orientation.value
      : LANGUAGE.ORIENTATIONS[orientation];
    return renderText({value: finalOrientation, column});
  };

  const renderGroups = ({value: groups, item: visual}) => {
    const diaporamaGroupValues = isColAdapterInDiaporama('group') && diaporamaValues.group.value;

    return (
      <CustomScrollbars
        scrollbarProps={{
          autoHeightMax: 55,
        }}
      >
        {!isColAdapterInDiaporama('group') ? (
          <div className="custom-scrollbars__scroll-content">
            <div className="main-text main-text--small">
              {groups &&
                groups.map((group) => (
                  <div key={group.id} className="ellipsis-text">
                    {group.name}
                  </div>
                ))}
              {visual.automatic_visual_groups ? (
                visual.automatic_visual_groups.map((group) => (
                  <div key={group.id} className="ellipsis-text">
                    {group.name}
                  </div>
                ))
              ) : (
                <div className="main-text--underline cursor-pointer" onClick={() => loadAutomaticGroups(visual)}>
                  {LANGUAGE.SHOW_AUTOMATIC_GROUPS_BUTTON}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="custom-scrollbars__scroll-content">
            <div className="main-text main-text--small">
              {diaporamaGroupValues &&
                diaporamaGroupValues.map((group, key) => (
                  <div key={key} className="ellipsis-text">
                    {group}
                  </div>
                ))}
            </div>
          </div>
        )}
      </CustomScrollbars>
    );
  };

  const renderComment = ({item: row}) => (
    <CustomScrollbars
      scrollbarProps={{
        autoHeightMax: 66,
      }}
    >
      <div className="custom-scrollbars__scroll-content display-flex">
        {getVisualOrDiaporamaValue('comment', row, diaporamaValues)}
      </div>
    </CustomScrollbars>
  );

  const renderOperation = ({item: visual}) => {
    const operation = visual.interaction?.interface.automation_task.operation;
    return (
      operation && (
        <div>
          <Link
            to={CustomLink.createOperationLink({
              clientId: operation.client_id,
              clientType: operation.client.type,
              operationId: operation.id,
            })}
            className="link main-text ellipsis-text max-width-100-percent"
            title={operation.code}
          >
            {operation.code}
          </Link>
          <div className="main-text main-text--color-1 main-text--small ellipsis-text" title={operation.name}>
            {operation.name}
          </div>
        </div>
      )
    );
  };

  const renderLead = ({value: lead, item: visual}) =>
    lead && (
      <div onClick={() => onLeadClick(visual)}>
        <Icon name="comment" className="theme-color-13 cursor-pointer" />
      </div>
    );

  const renderStatus = ({value: status, item: row}) => {
    const colorStatusType = getVisualOrDiaporamaValue('status', row, diaporamaValues) || status;
    return (
      <div className="text-center">
        <span
          className="status-icon"
          style={{
            color: VISUALS_STATUS_COLORS[colorStatusType],
          }}
        />
      </div>
    );
  };

  const renderForClientStatus = ({value: status, item: row}) => {
    const colorClientStatusType = getVisualOrDiaporamaValue('visible_by_client', row, diaporamaValues) || status;
    return (
      <div className="text-center">
        <span
          className="status-icon"
          style={{
            color: VISUALS_STATUS_COLORS[colorClientStatusType],
          }}
        />
      </div>
    );
  };

  const renderAuthorAvatar = ({value: avatar}) => (
    <div
      className={b('cell', ['image'])}
      style={{
        backgroundImage: `url(${avatar.url})`,
      }}
    />
  );

  const getSource = (visual) => {
    const {lead_first_source, interaction, hashtag_tracker, offline_interaction} = visual;
    switch (true) {
      case isColAdapterInDiaporama('source_name'):
        return diaporamaValues.source_name.value;
      case !interaction:
        return hashtag_tracker && hashtag_tracker.name;
      case interaction.type === VISUAL_INTERACTION_TYPES.OFFLINE:
        return offline_interaction && offline_interaction.place.name;
      default:
        return lead_first_source && lead_first_source.source.name;
    }
  };

  const renderSource = ({value: cell, item: row, column}) => {
    const source = getSource(row) || cell;

    return renderText({value: source, column});
  };

  const renderDefinedValueOrDefault =
    (fieldName) =>
    ({value: cell, item: visual, column}) => {
      return renderText({value: getVisualOrDiaporamaValue(fieldName, visual, diaporamaValues) || cell, column});
    };

  const newVisualOrDiaporamaTags = (visual, tags, newTags) =>
    tags.map((tagObject) => {
      if (tagObject.id !== visual.id) {
        return tagObject;
      }
      return {...tagObject, value: newTags};
    });

  const initializeNewTags = (visual, tags) => {
    const newVisualsTags = newVisualOrDiaporamaTags(visual, visualsTags, tags);
    const newDiaporamaTags = newVisualOrDiaporamaTags(visual, diaporamaTags, tags);

    setVisualsTags(newVisualsTags);
    setDiaporamaTags(newDiaporamaTags);
  };

  const addTag = (visual) => {
    const newTags = [...getVisualOrDiaporamaValue('tags', visual, diaporamaValues), ''];
    initializeNewTags(visual, newTags);
  };

  const saveTags = (visual) => (index, newTagValue) => {
    const newTags = getVisualOrDiaporamaValue('tags', visual, diaporamaValues).slice();

    if (newTagValue.trim().length === 0) {
      newTags.splice(index, 1);
    } else {
      newTags[index] = newTagValue.trim();
    }

    initializeNewTags(visual, newTags);

    if (isColAdapterInDiaporama('tags')) {
      dispatch(
        patchDiaporamaMappingItems({
          diaporama_mapping_item: {
            id: diaporamaValues.tags.id,
            value: newTags.join(', '),
          },
        }),
      ).then(() => updateMe());
    } else {
      dispatch(
        patchVisual(visual.id, {
          visual: {
            tags: newTags,
          },
        }),
      ).then(() => updateMe());
    }
  };

  const removeTag = (visual, index) => {
    saveTags(visual)(index, '');
  };

  const handleSelectAll = (checked) => {
    onSelect(...data.map((r) => r.id))(checked);
  };

  const handleSelect = (checked, row) => {
    onSelect(row.id)(checked);
  };

  const getSort = (sortable) => {
    if (data.length && sortable !== false) {
      return {
        dataSort: true,
      };
    }

    return {};
  };

  const renderTags = ({item: row}) => {
    let finalTags = isColAdapterInDiaporama('tags')
      ? diaporamaTags.filter((tag) => row.id === tag.id)[0]
      : visualsTags.filter((tag) => row.id === tag.id)[0];

    return (
      <div className="theme-color-13">
        <div className="hashtags-list">
          {!isEmpty(finalTags) &&
            finalTags.value.map((tag, key) => (
              <div className="hashtags-list__item hashtags-list__item--limited" key={key}>
                <Hashtag
                  editable={isAdmin}
                  deletable={isAdmin}
                  appendSharp
                  onDelete={(index) =>
                    isColAdapterInDiaporama('tags') ? removeTag(diaporamaValues, index) : removeTag(row, index)
                  }
                  className="hashtags-list__item--limited"
                  textClassName="ellipsis-text"
                  name={key}
                  onChange={saveTags(row)}
                >
                  {tag}
                </Hashtag>
              </div>
            ))}
        </div>
        {isAdmin && (
          <div className="link" onClick={() => addTag(row)}>
            {LANGUAGE.ADD_TAG_BUTTON}
          </div>
        )}
      </div>
    );
  };

  const columnMapping = {
    id: {
      dataField: 'id',
      hidden: true,
      isKey: true,
    },
    file: {
      dataField: 'file',
      width: '100',
      dataFormat: renderFilePreview,
      header: LANGUAGE.FILE_PREVIEW_COLUMN,
    },
    kind: {
      dataField: 'kind',
      width: '70',
      dataFormat: renderType,
      header: LANGUAGE.TYPE_COLUMN,
    },
    created_at: {
      dataField: 'created_at',
      width: '100',
      dataFormat: renderCreated,
      header: LANGUAGE.CREATED_COLUMN,
    },
    groups: {
      dataField: 'manual_visual_groups',
      width: '120',
      dataFormat: renderGroups,
      header: LANGUAGE.GROUPS_COLUMN,
    },
    source_name: {
      dataField: 'source',
      width: '90',
      dataFormat: renderSource,
      header: LANGUAGE.SOURCE_COLUMN,
    },
    interface: {
      dataField: 'interaction_interface_name',
      dataFormat: renderInterface,
      width: '120',
      header: LANGUAGE.INTERFACE_COLUMN,
    },
    operation: {
      dataField: 'interaction_interface_automation_task_operation_name',
      width: '100',
      dataFormat: renderOperation,
      header: LANGUAGE.OPERATION_COLUMN,
    },
    lead: {
      dataField: 'lead',
      width: '50',
      dataFormat: renderLead,
      header: LANGUAGE.LEAD_COLUMN,
    },
    status: {
      dataField: 'status',
      width: '80',
      dataFormat: renderStatus,
      header: LANGUAGE.STATUS_COLUMN,
    },
    visible_by_client: {
      dataField: 'visible_by_client',
      width: '140',
      dataFormat: renderForClientStatus,
      header: LANGUAGE.VISIBLE_COLUMN,
    },
    tags: {
      dataField: 'tags',
      width: '120',
      dataFormat: renderTags,
      header: LANGUAGE.TAGS_COLUMN,
    },
    file_extension: {
      dataField: 'file_extension',
      width: '120',
      dataFormat: renderDefinedValueOrDefault('file_extension'),
      header: LANGUAGE.FORMAT_TYPE_COLUMN,
    },
    arriving_type: {
      dataField: 'arriving_type',
      width: '120',
      dataFormat: renderArrivingType,
      header: LANGUAGE.ARRIVING_TYPE_COLUMN,
    },
    client: {
      dataField: 'interaction_interface_automation_task_operation_client_name',
      width: '120',
      dataFormat: renderClient,
      header: LANGUAGE.CLIENT_NAME_COLUMN,
    },
    file_identifier: {
      dataField: 'file_identifier',
      width: '120',
      dataFormat: renderDefinedValueOrDefault('file_identifier'),
      header: LANGUAGE.FILE_NAME_COLUMN,
    },
    title: {
      dataField: 'title',
      width: '120',
      dataFormat: renderDefinedValueOrDefault('title'),
      header: LANGUAGE.TITLE_COLUMN,
    },
    author: {
      dataField: 'author',
      width: '120',
      dataFormat: renderDefinedValueOrDefault('author'),
      header: LANGUAGE.AUTHOR_COLUMN,
    },
    author_avatar: {
      dataField: 'author_avatar',
      width: '120',
      dataFormat: renderAuthorAvatar,
      header: LANGUAGE.AUTHOR_AVATAR_COLUMN,
    },
    comment: {
      dataField: 'comment',
      width: '120',
      dataFormat: renderComment,
      header: LANGUAGE.COMMENTS_COLUMN,
    },
    likes_count: {
      dataField: 'likes_count',
      width: '120',
      dataFormat: renderDefinedValueOrDefault('likes_count'),
      header: LANGUAGE.LIKES_NUM_COLUMN,
    },
    width: {
      dataField: 'width',
      width: '80',
      dataFormat: renderDefinedValueOrDefault('width'),
      header: LANGUAGE.WIDTH_COLUMN,
    },
    height: {
      dataField: 'height',
      width: '80',
      dataFormat: renderDefinedValueOrDefault('height'),
      header: LANGUAGE.HEIGHT_COLUMN,
    },
    orientation: {
      dataField: 'orientation',
      width: '120',
      dataFormat: renderOrientation,
      header: LANGUAGE.ORIENTATION_COLUMN,
    },
    filesize: {
      dataField: 'filesize',
      width: '90',
      dataFormat: renderFileSize,
      header: LANGUAGE.SIZE_COLUMN,
    },
    expires_at: {
      dataField: 'expires_at',
      width: '100',
      dataFormat: renderDate,
      header: LANGUAGE.EXPIRES_AT,
      dataSort: false,
    },
  };

  /**
   * @type {(columnAdapter: any) => import('client/components/common/client-table/types').ClientTableColumn}
   */
  const renderColumnAdapter = (columnAdapter) => {
    const columnData = {...columnMapping[columnAdapter.name]};
    return {
      name: columnData.dataField,
      path: columnData.dataField,
      label: columnData.header,
      sortable: getSort(columnData.dataSort).dataSort,
      key: columnAdapter.id,
      render: columnData.dataFormat,
      width: +columnData.width,
    };
  };

  const getDisplayItems = () => {
    return isAdmin
      ? [
          ...displayItems
            .filter(({column_adapter}) => !!column_adapter)
            .map(({column_adapter}) => renderColumnAdapter(column_adapter)),
        ]
      : [...client_column_adapters.filter(Boolean).map(renderColumnAdapter)];
  };
  const columns = getDisplayItems();

  return (
    <div className={b()}>
      <div className={b('toolbar')}>
        <div className={b('toolbar-left')}>{toolbar}</div>
        <div className={b('toolbar-right')}>
          {isAdmin && (
            <>
              <button className="button button--bg-8" onClick={onSettingsClick}>
                <Icon name="settings" className="button__icon icon-new-settings--size-1" />
                <span>{LANGUAGE.TABLE_SETTINGS_BUTTON}</span>
              </button>
            </>
          )}
          <PerPageDropdown
            simpleValue
            size="small"
            value={perPage?.toString()}
            onChange={(value) => onPageChange({page: 1, perPage: +value})}
            className={b('per-page')}
          />
        </div>
      </div>
      <ClientTable
        data={data}
        columns={columns}
        className={b('table')}
        sortField={sort?.name}
        sortOrder={sort?.order}
        onSortChange={({sortField, sortOrder}) => onSortChange(sortField, sortOrder)}
        onCheck={handleSelect}
        checkedRows={selectedIds.map((id) => ({id}))}
        onCheckAll={handleSelectAll}
        checkboxProps={{
          inversionColor: true,
          color: 'visuals',
        }}
        checkable
      />
      <PaginationBar
        data={data}
        onPageChange={onPageChange}
        currentPage={meta.current_page}
        totalPages={meta.total_pages}
        totalItems={meta.total_count}
        perPage={+perPage}
      />
    </div>
  );
};

VisDataGrid.propTypes = {
  data: PropTypes.array,
  displayItems: PropTypes.array.isRequired,
  selectedIds: PropTypes.array,
  languageState: PropTypes.object.isRequired,
  meta: PropTypes.object,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  onPreviewClick: PropTypes.func,
  onLeadClick: PropTypes.func,
  onSettingsClick: PropTypes.func,
  updateMe: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  perPage: PropTypes.number,
  clientId: PropTypes.number,
  toolbar: PropTypes.element,
  isAdmin: PropTypes.bool,
  diaporamaValues: PropTypes.object.isRequired,
  isColAdapterInDiaporama: PropTypes.func.isRequired,
  getArrivingType: PropTypes.func.isRequired,
  getVisualOrDiaporamaValue: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    name: PropTypes.string,
    order: PropTypes.string,
  }),
};

VisDataGrid.defaultProps = {
  data: [],
  selectedIds: [],
  meta: {
    current_page: 1,
    total_pages: 1,
    total_count: 0,
  },
  onPageChange: () => {},
  onSortChange: () => {},
  onPreviewClick: () => {},
  onLeadClick: () => {},
  onSettingsClick: () => {},
  updateMe: () => {},
  perPage: 5,
  sort: {},
  toolbar: null,
};

export default connect(({languageState, visuals}) => ({
  languageState,
  displayItems: visuals.displayItems,
}))(VisDataGrid);
