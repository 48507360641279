export function selectEmailSenders(state) {
  return state.emailSenders.email_senders;
}

export function selectEmailSendersSettings(state) {
  return selectEmailSenders(state).map((s) => ({
    value: s.id,
    label: s.name,
  }));
}
