import React, {MouseEventHandler, useRef, useState} from 'react';

import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectIsAdmin} from 'client/ducks/user/selectors';

import AppButton from 'client/common/buttons';
import Popover from 'client/common/popovers/popover';

import RemoveBlockConfirmation from 'client/components/email-template-editor/modals/remove-block-confirmation';

import {useEditor} from '../../hooks/useEditor';
import {Section as SectionType, Column as ColumnType} from '../../types';
import {Column} from '../column';

import cssModule from './section.module.scss';

const b = bem('section', {cssModule});

type SectionProps = {
  section: SectionType | {id: string; isLocked?: boolean; columns?: ColumnType[]};
  canDrop: boolean;
};

export const Section: React.FC<SectionProps> = ({section, canDrop}) => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const {setDndData, deleteSection, updateSection, activeBlockId} = useEditor();

  const isAdmin = useSelector(selectIsAdmin);
  const isLocked = !!section?.isLocked;

  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.BLOCKS.SECTION');

  const [showRemoveConfirmation, toggleRemoveConfirmation] = useToggle(false);
  const [isDraggable, setIsDraggable] = useState(false);

  const handleDragEnd = () => {
    setDndData({
      id: null,
      type: null,
    });
    sectionRef.current?.removeEventListener('dragend', handleDragEnd);
    sectionRef.current = null;

    setIsDraggable(false);
  };

  const handleDragStart = (e: React.DragEvent) => {
    if (activeBlockId || isLocked) {
      e.preventDefault();
      return;
    }

    sectionRef.current = e.target as HTMLDivElement;
    setTimeout(() => {
      setDndData({
        id: section.id,
        type: 'section',
      });
    }, 0);

    sectionRef.current?.addEventListener('dragend', handleDragEnd);
  };

  const handleDeleteSection = () => {
    deleteSection(section as SectionType);
  };

  const handleLocked = () => {
    updateSection({
      id: section.id,
      type: 'section',
      isLocked: !isLocked,
      columns: section.columns,
    });
  };

  // putting inputs in draggable container causes issues with caret in Firefox https://bugzilla.mozilla.org/show_bug.cgi?id=800050
  // enable dragging only if non-editable area is hold
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    const target = e.target as HTMLElement;
    if (!target.isContentEditable) {
      setIsDraggable(true);
    }
  };

  const children = (
    <>
      <div className={b('content', {'is-locked': isLocked})}>
        {'columns' in section && (
          <>
            {section.columns?.map((el) => (
              <Column
                className={b('column', {
                  'has-content': !!el.contentBlocks.length,
                  empty: !el.contentBlocks.length,
                })}
                key={el.id}
                column={el}
              />
            ))}
          </>
        )}
      </div>
      <AppButton
        className={b('delete-btn')}
        iconName="close"
        asWrap={true}
        onClick={toggleRemoveConfirmation}
        disabled={isLocked}
      />
      {isAdmin && (
        <Popover
          overlay={<span>{isLocked ? lang.BUTTON_UNLOCKED_TEXT : lang.BUTTON_LOCKED_TEXT}</span>}
          className={b('locked-btn', {lock: isLocked, unlock: !isLocked})}
        >
          <AppButton iconName={isLocked ? 'lock' : 'unlock'} onClick={handleLocked} asWrap={true} />
        </Popover>
      )}
      <RemoveBlockConfirmation
        type="layout"
        show={showRemoveConfirmation}
        onCancel={toggleRemoveConfirmation}
        onRemove={handleDeleteSection}
      />
    </>
  );

  return (
    <div
      className={b([canDrop ? 'is-dragging' : ''])}
      data-draggable-id={section.id}
      onDragStart={handleDragStart}
      draggable={isDraggable}
      onMouseDown={onMouseDown}
      onMouseUp={() => setIsDraggable(false)}
    >
      {isLocked ? (
        <Popover
          overlayInnerStyle={{width: 165}}
          className={b('popover')}
          triggerClassName={b('trigger')}
          contentClassName={b('popover-content')}
          position="top"
          arrowConfig={{
            fill: '#4384AB',
            stroke: '#4384AB',
            tipRadius: 1,
            width: 24,
            height: 10,
          }}
          overlay={<span>{lang.TOOLTIP_LOCKED_TEXT}</span>}
        >
          {children}
        </Popover>
      ) : (
        children
      )}
    </div>
  );
};
