import React, {Component} from 'react';

import find from 'lodash/find';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getUserId} from 'client/services/cookie-data-source';

import {selectAvailableColumnAdapters} from 'client/ducks/column-adapters/selectors';
import {getCustomColumns, deleteCustomColumnAction} from 'client/ducks/custom-columns/actions';
import {selectAllCustomColumns, selectCustomColumns} from 'client/ducks/custom-columns/selectors';
import {
  getDisplayColumns,
  updateDisplayColumns,
  deleteDisplayColumn,
  updateColumnPosition,
  getColumnVisibilityItems,
} from 'client/ducks/lead-display-items/actions';
import {selectColumnVisibilityItems} from 'client/ducks/lead-display-items/selectors';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import Modal from 'client/common/modals/modal';
import Popover from 'client/common/popovers/popover';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';
import Rollout from 'client/components/common/rollout';
import SearchField from 'client/components/common/search-field';

import './leads-table-settings-modal.scss';

// FIXME: this component has a lot of anti-patterns. need to refactor this
class LeadsTableSettingsModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    clientId: PropTypes.string.isRequired,
    customColumns: PropTypes.array.isRequired,
    allCustomColumns: PropTypes.array.isRequired,
    internalColumns: PropTypes.array.isRequired,
    updateDisplayColumns: PropTypes.func.isRequired,
    leadDisplayItems: PropTypes.object.isRequired,
    deleteDisplayColumn: PropTypes.func.isRequired,
    getColumnVisibilityItems: PropTypes.func.isRequired,
    getDisplayColumns: PropTypes.func.isRequired,
    getCustomColumns: PropTypes.func.isRequired,
    updateColumnPosition: PropTypes.func.isRequired,
    deleteCustomColumnAction: PropTypes.func.isRequired,
    columnVisibilityItems: PropTypes.array.isRequired,
  };

  static defaultProps = {
    onConfirm: () => {},
    onCancel: () => {},
  };

  constructor(props) {
    super(props);

    this.generalCols = [
      {
        name: this.props.lang.FIRST_NAME?.toString(),
        adapter: ['first_name'],
      },
      {
        name: this.props.lang.LAST_NAME?.toString(),
        adapter: ['last_name'],
      },
      {
        name: this.props.lang.GENDER_SMALL?.toString(),
        adapter: ['gender'],
      },
      {
        name: this.props.lang.BIRTH_DATE?.toString(),
        adapter: ['birth_date'],
      },
      {
        name: this.props.lang.CONTACTS?.toString(),
        adapter: ['phone', 'email'],
      },
      {
        name: this.props.lang.OPT_IN_TYPES?.toString(),
        adapter: ['opt_ins'],
      },
      {
        name: this.props.lang.PARTICIPATION?.toString(),
        adapter: ['last_participation', 'participations_count'],
      },
      {
        name: this.props.lang.VISUALS_COUNT?.toString(),
        adapter: ['visuals_count'],
      },
      {
        name: this.props.lang.CREATED_AT?.toString(),
        adapter: ['created_at'],
      },
      {
        name: this.props.lang.SOURCE?.toString(),
        adapter: ['first_source'],
      },
      {
        name: this.props.lang.LAST_EXPORT?.toString(),
        adapter: ['last_export'],
      },
      {
        name: this.props.lang.EXPIRES_AT?.toString(),
        adapter: ['expires_at'],
      },
      {
        name: this.props.lang.ADDRESS1?.toString(),
        adapter: ['address1'],
      },
      {
        name: this.props.lang.ADDRESS2?.toString(),
        adapter: ['address2'],
      },
      {
        name: this.props.lang.ZIP?.toString(),
        adapter: ['zip'],
      },
      {
        name: this.props.lang.CITY?.toString(),
        adapter: ['city'],
      },
      {
        name: this.props.lang.COUNTRY?.toString(),
        adapter: ['country'],
      },
    ];

    this.state = {
      generalCols: this.generalCols,
      customCols: [],
      displayedColumns: [],
      displayedColumnsData: [],
      enabledSwitcher: false,
      enabledArrowUp: false,
      enabledArrowDown: false,
      allColumnSearch: '',
      displayColumnSearch: '',
      checkedAllColumns: false,
      checkedGeneralColumns: false,
      checkedCustomColumns: false,
      accordionEnabled: true,
      movedItems: [],
    };
    this.deleteFlag = false;
    this.userId = getUserId();
  }

  componentDidUpdate(prevProps, prevState) {
    const {customColumns, allCustomColumns, show, leadDisplayItems, isAdmin} = this.props;

    if (!show) {
      return;
    }

    if ((customColumns.length && !this.isCustomInit) || !prevProps.show) {
      this.setState({customCols: isAdmin ? allCustomColumns : customColumns}, () => {
        const columns = this.mapColumns(leadDisplayItems.payload);
        this.hideColumns(columns.hideColumns);
        this.setState({displayedColumns: columns.displayedColumns});
        this.isCustomInit = true;
      });
    }

    const columns = this.mapColumns(leadDisplayItems.payload);

    if (
      leadDisplayItems.payload &&
      !this.deleteFlag &&
      !isEqual(leadDisplayItems.payload, this.state.displayedColumnsData)
    ) {
      if (columns.hideColumns?.length) {
        this.hideColumns(columns.hideColumns);
      }
      if (!isEqual(columns.displayedColumns, prevState.displayedColumns)) {
        this.setState({displayedColumns: columns.displayedColumns});
      }
    }

    if (this.props.columnVisibilityItems !== prevProps.columnVisibilityItems) {
      this.props.getDisplayColumns(this.props.clientId);
      this.props.getCustomColumns(this.props.clientId, this.props.isAdmin);
      this.initializeGeneralColumns();
    }
  }

  getId = (item) => {
    if (item.adapter) {
      const found = this.props.internalColumns.find((el) => el.name === item.adapter[0]);
      return get(found, 'id');
    }

    const found = this.props.allCustomColumns.find((el) => el.name === item.name);
    return get(found, 'external_column_adapter.id');
  };

  onClose = () => {
    this.props.onClose();
    this.props.getCustomColumns(this.props.clientId, this.props.isAdmin);

    this.props.getDisplayColumns(this.props.clientId).then(() => {
      this.checkArrowsStatus(this.state.displayedColumns);

      this.setState((prevState) => {
        const resetGenerals = prevState.generalCols.map((item) => ({...item, checked: false}));
        return {
          generalCols: resetGenerals,
          enabledSwitcher: false,
          checkedGeneralColumns: false,
          checkedCustomColumns: false,
          checkedAllColumns: false,
        };
      });
    });
  };

  getAdapterId(item) {
    const columns = item.adapter ? this.props.internalColumns : this.props.allCustomColumns;

    return columns.reduce((acc, column) => {
      if (item.adapter) {
        return item.adapter.includes(column.name) ? [column.id, ...acc] : acc;
      }

      return column.name === item.name ? [column.external_column_adapter.id, ...acc] : acc;
    }, []);
  }

  getColumnVisibilityItemId(id) {
    return find(this.props.columnVisibilityItems, ({column_adapter_id}) => column_adapter_id === id).id;
  }

  isPermitted = (id) => {
    return this.props.isAdmin
      ? true
      : !!find(this.props.columnVisibilityItems, ({column_adapter_id}) => column_adapter_id === id);
  };

  initializeGeneralColumns = () => {
    this.setState((prevState) => ({
      generalCols: prevState.generalCols.map((item) => ({
        ...item,
        hidden: !this.isPermitted(this.getId(item)),
      })),
    }));
  };

  getVisibleColumnsState = (columns) => columns.filter((item) => !item.hidden).every((item) => item.checked);

  deleteDisplayedColumn = (e) => {
    const {displayedColumns, generalCols, customCols} = this.state;
    this.deleteFlag = true;
    const chosenName = e.currentTarget.getAttribute('data-name');
    const deletedIndex = displayedColumns.map(({name}) => name).indexOf(chosenName);
    const isCustom = !displayedColumns[deletedIndex].adapter;
    const ids = displayedColumns[deletedIndex].idList;
    ids.forEach((id, index) => {
      this.props.deleteDisplayColumn(id).then(() => {
        if (isCustom) {
          const customDisplayIndex = customCols.map(({name}) => name).indexOf(chosenName);

          this.setState((prevState) => {
            prevState.customCols[customDisplayIndex].hidden = false;
            prevState.customCols[customDisplayIndex].checked = false;
            return {
              customCols: prevState.customCols,
              checkedCustomColumns: false,
              checkedAllColumns: false,
            };
          });
        } else {
          const generalDisplayIndex = generalCols.map(({name}) => name).indexOf(chosenName);
          this.setState((prevState) => {
            prevState.generalCols[generalDisplayIndex].hidden = false;
            prevState.generalCols[generalDisplayIndex].checked = false;
            return {
              generalCols: prevState.generalCols,
              checkedGeneralColumns: false,
              checkedAllColumns: false,
            };
          });
        }
        this.deleteFlag = !(index === ids.length - 1);
        this.props.getDisplayColumns(this.props.clientId);
      });
    });
    this.setState(
      (prevState) => {
        prevState.displayedColumns.splice(deletedIndex, 1);
        return {displayedColumns: prevState.displayedColumns};
      },
      () => {
        this.checkArrowsStatus(this.state.displayedColumns);
      },
    );
  };

  deleteCustomColumn = (e) => {
    const {customCols} = this.state;
    this.deleteFlag = true;
    const chosenName = e.currentTarget.getAttribute('data-name');
    const deletedIndex = customCols.map(({name}) => name).indexOf(chosenName);
    const deletedId = customCols[deletedIndex].id;

    this.props.deleteCustomColumnAction(deletedId).then(() => {
      this.deleteFlag = !(deletedIndex === customCols.length - 1);
      this.props.getCustomColumns(this.props.clientId, this.props.isAdmin);
    });

    this.setState(
      (prevState) => {
        prevState.customCols.splice(deletedIndex, 1);
      },
      () => {
        this.checkArrowsStatus(this.state.customCols);
      },
    );
  };

  toggleAllColumns = (e) => {
    this.toggleGeneralColumns(e);
    this.toggleCustomColumns(e);
    this.setState({
      enabledSwitcher: e.currentTarget.checked,
      checkedAllColumns: e.currentTarget.checked,
    });
  };

  toggleGeneralColumns = ({currentTarget}) => {
    const generalCols = this.state.generalCols.slice().map((el) => ({
      ...el,
      checked: currentTarget.checked,
    }));
    this.setState({
      generalCols,
      checkedGeneralColumns: currentTarget.checked,
      enabledSwitcher: this.isColumnOn(generalCols) || this.isColumnOn(this.state.customCols),
      checkedAllColumns: this.state.checkedCustomColumns && currentTarget.checked,
    });
  };

  toggleCustomColumns = ({currentTarget}) => {
    const customCols = this.state.customCols.slice().map((el) => ({
      ...el,
      checked: currentTarget.checked,
    }));
    this.setState({
      customCols,
      enabledSwitcher: this.isColumnOn(this.state.generalCols) || this.isColumnOn(customCols),
      checkedCustomColumns: currentTarget.checked,
      checkedAllColumns: this.state.checkedGeneralColumns && currentTarget.checked,
    });
  };

  moveColumn = () => {
    const {generalCols, customCols} = this.state;
    const fromGeneral = generalCols.slice().filter(({checked, hidden}) => checked && !hidden);
    const fromCustom = customCols.slice().filter(({checked, hidden}) => checked && !hidden);
    const leadsDisplayItems = this.props.leadDisplayItems.payload;

    const startPosition = leadsDisplayItems.length ? sortBy(leadsDisplayItems, 'position').pop().position + 1 : 0;
    const postData = this.mapDisplayedColumns([...fromGeneral, ...fromCustom], startPosition);

    this.props.updateDisplayColumns({user: {display_items: postData}}, this.userId).then(() => {
      this.props.getDisplayColumns(this.props.clientId);
      this.setState({
        enabledSwitcher: false,
        checkedGeneralColumns: false,
        checkedCustomColumns: false,
        checkedAllColumns: false,
        enabledArrowUp: false,
        enabledArrowDown: false,
      });
    });
  };

  mapDisplayedColumns = (columns, startPosition = 0) => {
    const data = columns.map((el) => ({
      client_id: this.props.clientId,
      column_adapter_id: this.getAdapterId(el),
      column_visibility_item_id: this.props.isAdmin ? null : this.getColumnVisibilityItemId(this.getId(el)),
    }));
    const finalData = [];
    let pos = 0;

    data.forEach((el) => {
      el.position = pos + startPosition;
      const colAdapterList = el.column_adapter_id.slice();
      el.column_adapter_id = colAdapterList[0];
      el.type = 'LeadDisplayItem';
      finalData.push(el);
      pos += 1;
    });
    return finalData;
  };

  handleTransition = () => {
    this.forceUpdate();
  };

  isColumnOn = (columns) => {
    return columns.map(({checked}) => checked).includes(true);
  };

  handleColumnCheck = ({target}, isGeneral = false) => {
    const chosenName = target.getAttribute('data-name');
    let changedCols = (isGeneral ? this.state.generalCols : this.state.customCols)
      .slice()
      .map((item) => (item.name === chosenName ? {...item, checked: target.checked} : item));

    if (isGeneral) {
      this.setState({
        generalCols: changedCols,
        enabledSwitcher: this.isColumnOn(changedCols) || this.isColumnOn(this.state.customCols),
        checkedGeneralColumns: this.getVisibleColumnsState(changedCols),
        checkedAllColumns:
          this.getVisibleColumnsState(changedCols) && this.getVisibleColumnsState(this.state.customCols),
      });
    } else {
      this.setState({
        customCols: changedCols,
        enabledSwitcher: this.isColumnOn(changedCols) || this.isColumnOn(this.state.generalCols),
        checkedCustomColumns: this.getVisibleColumnsState(changedCols),
        checkedAllColumns:
          this.getVisibleColumnsState(changedCols) && this.getVisibleColumnsState(this.state.generalCols),
      });
    }
  };

  checkArrowsStatus = (changedCols) => {
    if (this.isColumnOn(changedCols)) {
      this.setState({
        enabledArrowUp: !changedCols[0].checked,
        enabledArrowDown: !changedCols[changedCols.length - 1].checked,
      });
    } else {
      this.setState({enabledArrowUp: false, enabledArrowDown: false});
    }
  };

  handleDisplayedColumnCheck = (e) => {
    const chosenName = e.target.getAttribute('data-name');
    const changedCols = this.state.displayedColumns.map((item) =>
      item.name === chosenName ? {...item, checked: e.target.checked} : item,
    );
    this.setState({displayedColumns: changedCols});
    this.checkArrowsStatus(changedCols);
  };

  addCheckedItems = (checkedNames) => {
    this.setState(
      (prevState) => {
        prevState.displayedColumns.map((item) => {
          if (checkedNames.includes(item.column_adapter.name)) {
            item.checked = true;
          }
          return item;
        });
        return {displayedColumns: prevState.displayedColumns};
      },
      () => {
        this.checkArrowsStatus(this.state.displayedColumns);
      },
    );
  };

  moveColumnsUp = () => {
    const displayList = this.state.displayedColumns.slice();
    let i;
    let saved;
    let updateList;
    let removalList = [];

    const checkedNames = [];
    displayList.forEach(({id, idList, position}) => {
      removalList.push({
        id,
        position,
        _destroy: true,
      });

      if (idList.length > 1) {
        removalList.push({
          id: idList[1],
          position: position + 1,
          _destroy: true,
        });
      }
    });

    for (i = 0; i < displayList.length; i++) {
      if (displayList[i].checked) {
        checkedNames.push(displayList[i].column_adapter.name);
        saved = displayList[i - 1];

        displayList[i - 1] = displayList[i];
        displayList[i] = saved;
      }
    }

    updateList = this.mapDisplayedColumns(displayList);
    this.props.updateColumnPosition(+this.userId, [...removalList, ...updateList]).then(() => {
      this.props.getDisplayColumns(this.props.clientId).then(() => {
        this.addCheckedItems(checkedNames);
      });
      this.setState({enabledArrowUp: false, enabledArrowDown: false});
    });
  };

  moveColumnsDown = () => {
    const displayList = this.state.displayedColumns.slice();
    const checkedNames = [];
    let i;
    let saved;
    let updateList;
    let removalList = [];

    displayList.forEach(({id, idList, position}) => {
      removalList.push({
        id,
        position,
        _destroy: true,
      });

      if (idList.length > 1) {
        removalList.push({
          id: idList[1],
          position: position + 1,
          _destroy: true,
        });
      }
    });

    for (i = displayList.length - 1; i >= 0; i--) {
      if (displayList[i].checked) {
        checkedNames.push(displayList[i].column_adapter.name);
        saved = displayList[i + 1];

        displayList[i + 1] = displayList[i];
        displayList[i] = saved;
      }
    }
    updateList = this.mapDisplayedColumns(displayList);

    this.props.updateColumnPosition(this.userId, [...removalList, ...updateList]).then(() => {
      this.props.getDisplayColumns(this.props.clientId).then(() => {
        this.addCheckedItems(checkedNames);
      });
      this.setState({enabledArrowUp: false, enabledArrowDown: false});
    });
  };

  mapColumns = (columns) => {
    const displayedColumnsList = [];
    const displayedColumns = [];
    const hideColumns = [];

    if (columns && columns.length) {
      columns.forEach((el) => {
        let isExternal = el.column_adapter.type === 'ExternalColumnAdapter';

        if (isExternal) {
          el.name = el.column_adapter.name;
        } else {
          const isEmail = el.column_adapter.name === 'email';
          const isParticipationCount = el.column_adapter.name === 'participations_count';

          const found =
            this.generalCols.find((item) => {
              return item.adapter[isEmail || isParticipationCount ? 1 : 0] === el.column_adapter.name;
            }) || {};

          el.name = found.name;
          el.adapter = [el.column_adapter.name];
        }
        displayedColumnsList.push(el);
      });
      displayedColumnsList.forEach((item, index) => {
        if (item.adapter && item.adapter[0] === 'participations_count') {
          hideColumns.push(item);
        } else {
          displayedColumnsList[index].idList = [item.id];
          displayedColumns.push(displayedColumnsList[index]);
          hideColumns.push(item);
        }
      });
    }

    return {
      displayedColumns: sortBy(displayedColumns, 'position'),
      hideColumns,
    };
  };

  hideColumns = (columns) => {
    columns.forEach((column) => {
      this.hideColumn(column.column_adapter);
    });
  };

  hideColumn = ({name, type}) => {
    let indexOf = -1;
    if (type === 'ExternalColumnAdapter') {
      indexOf = this.state.customCols.findIndex((el) => name === el.name && !el.hidden);
      if (indexOf !== -1) {
        this.setState((prevState) => {
          prevState.customCols[indexOf].hidden = true;
          return prevState;
        });
      }
    } else {
      indexOf = this.state.generalCols.findIndex((el) => name === el.name && !el.hidden);
      if (indexOf !== -1) {
        this.setState((prevState) => {
          prevState.generalCols[indexOf].hidden = true;
          return prevState;
        });
      }
    }
  };

  detectChange = (obj1, obj2) => {
    return isEqual(obj1, obj2);
  };

  searchColumn = (searchField, field) => {
    const regExp = new RegExp(searchField, 'i');

    if (!searchField) {
      return field && field === 'displayedColumns' ? this.clearDisplayedColumn() : this.clearAllColumns();
    }

    const newColumns = this.state[field].slice().filter((item) => regExp.test(item.name));
    return this.setState({[field]: newColumns});
  };

  searchDisplayedColumn = (value) => {
    this.searchColumn(value, 'displayedColumns');
    this.setState({displayColumnSearch: value});
  };

  clearDisplayedColumn = () => {
    this.setState({displayColumnSearch: ''}, () => this.props.getDisplayColumns(this.props.clientId));
  };

  searchAllColumns = (value) => {
    this.searchColumn(value, 'generalCols');
    this.searchColumn(value, 'customCols');
    this.setState({allColumnSearch: value, accordionEnabled: true});
  };

  clearAllColumns = () => {
    this.setState({allColumnSearch: ''}, () => {
      this.props.getDisplayColumns(this.props.clientId);
      this.setState(
        {
          generalCols: this.generalCols,
          customCols: this.props.isAdmin ? this.props.allCustomColumns : this.props.customColumns,
        },
        () => {
          const columns = this.mapColumns(this.props.leadDisplayItems.payload);
          this.hideColumns(columns.hideColumns);
          return columns.displayedColumns;
        },
      );
    });
  };

  renderTooltip() {
    return <span id="tooltip-1">{this.props.lang.TABLE_SETTINGS_DELETE_ITEM_TOOLTIP}</span>;
  }

  render() {
    const {show, lang} = this.props;
    const {
      generalCols,
      customCols,
      enabledSwitcher,
      displayedColumns,
      enabledArrowUp,
      enabledArrowDown,
      checkedAllColumns,
      checkedGeneralColumns,
      checkedCustomColumns,
      accordionEnabled,
    } = this.state;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-2 modal-window--theme-2 leads-table-settings-modal"
        backdropClassName="modal-window__backdrop"
        onClose={this.onClose}
        title={lang.TABLE_SETTINGS_MODAL_TITLE}
      >
        <div className="row pos-rel">
          <div className="modal-window__order-btns">
            <button
              disabled={!enabledArrowUp}
              onClick={this.moveColumnsUp}
              className="button button--bg-2 button--circle modal-window__order-btn-up"
            >
              <Icon className="button__icon theme-color-2" name="back" />
            </button>
            <button
              disabled={!enabledArrowDown}
              onClick={this.moveColumnsDown}
              className="button button--bg-2 button--circle modal-window__order-btn-down"
            >
              <Icon className="button__icon theme-color-2" name="back" />
            </button>
          </div>
          <button
            disabled={!enabledSwitcher}
            className="button button--bg-2 button--circle modal-window__transfer-btn"
            onClick={this.moveColumn}
          >
            <Icon className="button__icon theme-color-2" name="back" />
          </button>
          <div className="col-xs-6 modal-window__col-left">
            <div className="modal-window__descr main-text">{lang.TABLE_SETTINGS_MODAL_ALL_COLUMNS}</div>
            <div className="content-group theme-color-2">
              <div className="content-group__header">
                <label className="checkbox-button">
                  <input
                    className="checkbox-button__input"
                    type="checkbox"
                    name="checkbox-all"
                    value="0"
                    checked={checkedAllColumns}
                    onChange={this.toggleAllColumns}
                  />
                  <div className="checkbox-button__label">
                    <span className="icon-checked checkbox-button__icon" />
                    <span className="checkbox-button__text">
                      <span className="main-text main-text--bold">{lang.TABLE_SETTINGS_MODAL_ALL_COLUMNS_GROUP}</span>
                    </span>
                  </div>
                </label>
                <SearchField
                  cssModifier="input--full-width content-group__search-field"
                  placeholder={lang.TABLE_SETTINGS_MODAL_SEARCH_COLUMNS}
                  onSearch={this.searchAllColumns}
                  onClear={this.clearAllColumns}
                  onChange={this.handleSearchChange}
                />
              </div>

              <Rollout
                title={
                  <label className="checkbox-button theme-color-2">
                    <input
                      className="checkbox-button__input"
                      type="checkbox"
                      name="checkbox-general"
                      checked={checkedGeneralColumns}
                      onChange={this.toggleGeneralColumns}
                    />
                    <div className="checkbox-button__label">
                      <span className="icon-checked checkbox-button__icon" />
                      <span className="checkbox-button__text">
                        <span className="main-text main-text--bold">
                          {lang.TABLE_SETTINGS_MODAL_GENERAL_COLUMNS_GROUP}
                        </span>
                      </span>
                    </div>
                  </label>
                }
                className="rollout--expand-1"
                onTransition={this.handleTransition}
                isOpened={accordionEnabled}
              >
                <CustomScrollbars
                  scrollbarProps={{
                    hideTracksWhenNotNeeded: true,
                    autoHeightMax: 191,
                    autoHeightMin: 191,
                  }}
                >
                  <div className="list list--view-3">
                    <div className="list__group">
                      {generalCols.map((item, index) => (
                        <div
                          key={index}
                          className={`list__item ${item.hidden ? 'leads-table-settings-modal__hidden' : ''}`}
                        >
                          <label className="checkbox-button checkbox-button--view-1 checkbox-button">
                            <input
                              type="checkbox"
                              data-name={item.name}
                              className="checkbox-button__input"
                              checked={item.checked}
                              onChange={(e) => {
                                this.handleColumnCheck(e, true);
                              }}
                            />
                            <div className="checkbox-button__label">
                              <span className="icon-checked checkbox-button__icon" />
                              <span className="checkbox-button__text">{item.name}</span>
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </CustomScrollbars>
              </Rollout>
              <Rollout
                title={
                  <label className="checkbox-button theme-color-2">
                    <input
                      className="checkbox-button__input"
                      type="checkbox"
                      name="checkbox-custom"
                      value="0"
                      checked={checkedCustomColumns}
                      onChange={this.toggleCustomColumns}
                    />
                    <div className="checkbox-button__label">
                      <span className="icon-checked checkbox-button__icon" />
                      <span className="checkbox-button__text">
                        <span className="main-text main-text--bold">
                          {lang.TABLE_SETTINGS_MODAL_CUSTOM_COLUMNS_GROUP}
                        </span>
                      </span>
                    </div>
                  </label>
                }
                className="rollout--expand-1 rollout--no-border-bottom"
                onTransition={this.handleTransition}
                isOpened={accordionEnabled}
              >
                <CustomScrollbars
                  scrollbarProps={{
                    hideTracksWhenNotNeeded: true,
                    autoHeightMax: 192,
                    autoHeightMin: 192,
                  }}
                >
                  <div className="list list--view-3">
                    <div className="list__group">
                      {customCols.map((item, index) => (
                        <div
                          key={index}
                          className={`list__item ${item.hidden ? 'leads-table-settings-modal__hidden' : ''}`}
                        >
                          <label className="checkbox-button checkbox-button--view-1 checkbox-button">
                            <input
                              type="checkbox"
                              data-name={item.name}
                              className="checkbox-button__input"
                              checked={item.checked}
                              onChange={this.handleColumnCheck}
                            />
                            <div className="checkbox-button__label">
                              <span className="icon-checked checkbox-button__icon" />
                              <span className="checkbox-button__text">{item.name}</span>
                            </div>
                          </label>
                          <Popover position="right" overlay={this.renderTooltip()}>
                            <span
                              className="list__del-item-btn"
                              data-name={item.name}
                              onClick={this.deleteCustomColumn}
                            >
                              <Icon name="clear" className="list__del-item-icon" />
                            </span>
                          </Popover>
                        </div>
                      ))}
                    </div>
                  </div>
                </CustomScrollbars>
              </Rollout>
            </div>
          </div>
          <div className="col-xs-6 modal-window__col-right">
            <div className="modal-window__descr main-text">{lang.TABLE_SETTINGS_MODAL_DISPLAYED_COLUMNS}</div>
            <div className="content-group theme-color-2">
              <div className="content-group__header leads-table-settings-modal__col-header">
                <SearchField
                  cssModifier="input--full-width content-group__search-field"
                  placeholder={lang.TABLE_SETTINGS_MODAL_SEARCH_COLUMNS}
                  onSearch={this.searchDisplayedColumn}
                  onClear={this.clearDisplayedColumn}
                />
              </div>
              <CustomScrollbars
                scrollbarProps={{
                  hideTracksWhenNotNeeded: true,
                  autoHeightMax: 482,
                  autoHeightMin: 482,
                }}
              >
                <div className="list list--view-2">
                  {displayedColumns.map((item, index) => {
                    return (
                      <div className="list__item pos-rel" key={index}>
                        <label className="checkbox-button checkbox-button--view-1 checkbox-button">
                          <input
                            type="checkbox"
                            className="checkbox-button__input"
                            data-name={item.name}
                            checked={item.checked}
                            onChange={this.handleDisplayedColumnCheck}
                          />
                          <div className="checkbox-button__label">
                            <span className="icon-checked checkbox-button__icon" />
                            <span className="checkbox-button__text">{item.name}</span>
                          </div>
                        </label>
                        <Popover position="right" overlay={this.renderTooltip()}>
                          <span
                            className="list__del-item-btn"
                            data-name={item.name}
                            onClick={this.deleteDisplayedColumn}
                          >
                            <Icon name="clear" className="list__del-item-icon" />
                          </span>
                        </Popover>
                      </div>
                    );
                  })}
                </div>
              </CustomScrollbars>
            </div>
          </div>
        </div>
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-2 modal-window__footer-btn" onClick={this.onClose}>
            {lang.TABLE_SETTINGS_MODAL_CONFIRM_BUTTON}
          </button>
        </div>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    isAdmin: selectIsAdmin(state),
    lang: state.languageState.payload.LEADS_LIST,
    internalColumns: selectAvailableColumnAdapters(state, 'Lead'),
    leadDisplayItems: state.leadDisplayItems,
    allCustomColumns: selectAllCustomColumns(state),
    customColumns: selectCustomColumns(state),
    columnVisibilityItems: selectColumnVisibilityItems(state),
  }),
  {
    updateDisplayColumns,
    deleteDisplayColumn,
    getColumnVisibilityItems,
    getDisplayColumns,
    getCustomColumns,
    updateColumnPosition,
    deleteCustomColumnAction,
  },
)(LeadsTableSettingsModal);
