import types from './types';

const initialState = {
  couponTokens: [],
  coupons: [],
  metaTokens: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
  metaCoupons: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
};

export default function (_state = initialState, action) {
  let state = {..._state};

  switch (action.type) {
    case types.GET_COUPON_TOKENS_SUCCESS:
      state.couponTokens = action.payload.coupon_tokens;
      state.metaTokens = {
        ...state.metaTokens,
        ...action.payload.meta,
      };

      return state;

    case types.GET_COUPON_SUCCESS:
      state.coupons = action.payload.coupons;
      state.metaCoupons = {
        ...state.metaCoupons,
        ...action.payload.meta,
      };

      return state;

    default:
      return state;
  }
}
