import get from 'lodash/get';

import types from './types';

const initialState = {
  allIds: [],
  byId: {},
  meta: {},
  clientOptions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SOURCES_SUCCESS:
      const sources = get(action, 'payload.permanent_sources', []);
      const meta = get(action, 'payload.meta', {});

      return {
        ...state,
        ...sources.reduce(
          (result, item) => {
            return {
              allIds: [...result.allIds, item.id],
              byId: {...result.byId, [item.id]: item},
            };
          },
          {
            allIds: [],
            byId: {},
          },
        ),
        meta,
      };

    case types.GET_CLIENT_OPTIONS_SUCCESS:
      return {
        ...state,
        clientOptions: action?.payload?.clients || initialState.clientOptions,
      };

    default:
      return state;
  }
};
