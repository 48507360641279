import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons';
import Popover from 'client/common/popovers/popover';

import cssModule from './save-template-button.module.scss';

const b = bem('save-template-button', {cssModule});

type SaveTemplateButtonProps = {
  className?: string;
  onClick: () => void;
  disabled?: boolean;
};

const SaveTemplateButton: React.FC<SaveTemplateButtonProps> = (props) => {
  const {className, onClick, disabled} = props;
  const lang = useLanguage('DIY_OPERATION.BUTTONS.SAVE_TEMPLATE_BUTTON');

  return (
    <Popover
      position="top"
      overlay={!disabled && <p className={b('popover-text')}>{lang.POPOVER_TEXT}</p>}
      shiftTop={-20}
      shiftLeft={-5}
      arrowConfig={{
        fill: '#4384AB',
        stroke: '#8996ac',
        tipRadius: 1,
        width: 35,
        height: 16,
      }}
    >
      <AppButton className={cn(b(), className)} iconName="save" onClick={onClick} asWrap disabled={disabled} />
    </Popover>
  );
};

export default SaveTemplateButton;
