import {createSelector} from 'reselect';

import {buildTranslationsTree} from './mappers';

const prepareTranslationsArray = (state) => state.translations.translations;

const prepareTranslationsTree = (state) => buildTranslationsTree(state.translations.translations);

export const selectTranslationsTree = createSelector(prepareTranslationsTree, (tree) => tree);
export const selectTranslationsArray = createSelector(prepareTranslationsArray, (array) => array);

export const selectLanguages = (state) => state.translations.languages;

export const selectActiveLanguages = (state) => state.translations.languages.filter(({active}) => active);
