import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class SmsStep extends Component {
  static propTypes = {
    step: PropTypes.object,
    variableValues: PropTypes.object,
    languageState: PropTypes.object.isRequired,
    getValuedMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    step: {},
    variableValues: {},
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.RESULT_MODAL;
  }

  render() {
    const {LANGUAGE} = this;
    const {step, variableValues, getValuedMessage} = this.props;
    const {implementation} = step;

    return (
      <div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{LANGUAGE.SENDERS_PHONE_NUMBER_LABEL}</div>
            <div className="fake-input__field">{implementation.message_sender.value}</div>
          </div>
        </div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{LANGUAGE.RECIPIENTS_PHONE_NUMBER_LABEL}</div>
            <div className="fake-input__field">{variableValues[implementation.recipient_variable_id]}</div>
          </div>
        </div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{LANGUAGE.MESSAGE_LABEL}</div>
            <div className="fake-input__textarea">{getValuedMessage(implementation.message)}</div>
          </div>
        </div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{LANGUAGE.COMMENT_LABEL}</div>
            <div className="fake-input__textarea">{step.comment}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(SmsStep);
