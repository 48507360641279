import React, {Component} from 'react';

import cx from 'classnames';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {replaceText} from 'client/services/helpers';

import {SCENARIO_STEP_NAMES, CLIENT_PAGES} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Field from 'client/components/common/field';
import Icon from 'client/components/common/icon';

import './scenario-list.scss';

class ScenarioList extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    scenarios: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        active: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        scenario_executions_count: PropTypes.number.isRequired,
        first_scenario_execution_created_at: PropTypes.string,
        last_scenario_execution_created_at: PropTypes.string,
        scenario_steps: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            implementation_type: PropTypes.string.isRequired,
          }),
        ),
        trigger_mapping_items: PropTypes.arrayOf(
          PropTypes.shape({
            interface_item: PropTypes.shape({
              interface: PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
              }).isRequired,
            }).isRequired,
          }),
        ),
      }),
    ),
    changeScenarioActiveState: PropTypes.func.isRequired,
  };

  state = {
    activateScenarioModal: false,
    deactivateScenarioModal: false,
    changeScenarioStatusId: null,
  };

  LANGUAGE = this.props.languageState.payload.AUTOTASK;

  renderScenarioStep = (step) => {
    const stepNames = this.LANGUAGE.AUTOTASK_SCENARIOS_LIST.STEPS;

    return (
      <div key={step.id} className="content-panel__item">
        {stepNames[SCENARIO_STEP_NAMES[step.implementation.type]]}
      </div>
    );
  };

  renderScenarioSteps = (steps) => {
    if (steps && steps.length > 0) {
      const renderedSteps = steps.map((step) => this.renderScenarioStep(step));

      return (
        <div className="scenario-list__col">
          <div className="content-panel__title">{this.LANGUAGE.AUTOTASK_SCENARIOS_LIST.STEPS_TITLE}</div>
          {renderedSteps}
        </div>
      );
    }

    return null;
  };

  renderInterface = (_interface) => (
    <div key={_interface.id} className="content-panel__item">
      {_interface.name}
    </div>
  );

  renderInterfaces = (interfaces) => {
    if (interfaces && interfaces.length > 0) {
      const renderedInterfaces = interfaces.map((_interface) => this.renderInterface(_interface));

      return (
        <div className="scenario-list__col">
          <div className="content-panel__title">{this.LANGUAGE.AUTOTASK_SCENARIOS_LIST.INTERFACES_TITLE}</div>
          {renderedInterfaces}
        </div>
      );
    }

    return null;
  };

  renderRunTime = (time) => {
    if (time) {
      return (
        <div>
          <Moment element="div" format="DD-MM-YYYY">
            {time}
          </Moment>
          <Moment element="div" format="HH:mm:ss">
            {time}
          </Moment>
        </div>
      );
    }

    return <div>-</div>;
  };

  handleCloseModal = () => {
    this.setState({
      activateScenarioModal: false,
      deactivateScenarioModal: false,
      changeScenarioStatusId: null,
    });
  };

  handleChangeScenarioStatus = (id, value) => {
    this.setState({
      activateScenarioModal: value,
      deactivateScenarioModal: !value,
      changeScenarioStatusId: id,
    });
  };

  changeScenarioActiveStatus = (id, state) => {
    this.props.changeScenarioActiveState(id, state).then(() => {
      this.handleCloseModal();
    });
  };

  renderItem = (scenario) => {
    const {
      id,
      active: isActive,
      name,
      scenario_executions_count: executionsCount,
      first_scenario_execution_created_at: firstScenarioExecutionTime,
      last_scenario_execution_created_at: lastScenarioExecutionTime,
      scenario_steps: scenarioSteps = [],
      trigger_mapping_items: triggerMappingItems = [],
    } = scenario;

    const interfaces = uniqBy(
      triggerMappingItems.map((item) => item.interface_item.interface),
      'id',
    );

    const hasErrors = scenario.failed_logs_present;

    return (
      <div className="content-panel__panel scenario-list__item">
        <CustomScrollbars
          scrollbarProps={{
            autoHeightMax: 170,
          }}
        >
          <div className="content-panel__panel-inner">
            <div className="content-panel__row">
              <div className="scenario-list__col">
                <Field
                  cssModifier="theme-color-7 content-panel__switcher"
                  name="test-1"
                  type="switcher"
                  input={{
                    checked: isActive,
                    onChange: () => this.handleChangeScenarioStatus(scenario.id, !isActive),
                  }}
                />
              </div>
              <div className="scenario-list__col">{this.renderRunTime(firstScenarioExecutionTime)}</div>
              <div className="scenario-list__col">{this.renderRunTime(lastScenarioExecutionTime)}</div>
              <div className="scenario-list__col">{executionsCount}</div>
            </div>
            <div className="content-panel__row">
              <div className="scenario-list__col">
                <div className="flex-container flex-align-center">
                  <Link
                    to={`${CLIENT_PAGES.SCENARIO}/${id}`}
                    target="_blank"
                    className={cx('link', 'scenario-list__link', {'scenario-list__error': hasErrors})}
                    style={{
                      width: '100%',
                    }}
                  >
                    {this.LANGUAGE.AUTOTASK_SCENARIOS_LIST.SCENARIO_TEXT} {name}
                  </Link>
                  {hasErrors && <Icon name="status-error" className="scenario-list__error-icon" />}
                </div>
              </div>
              {this.renderScenarioSteps(scenarioSteps)}
              {this.renderInterfaces(interfaces)}
            </div>
          </div>
        </CustomScrollbars>
      </div>
    );
  };

  renderScenario = (scenario) => (
    <div key={scenario.id} className="content-panel scenario-list">
      <div className="content-panel__header scenario-list__header">
        <div className="scenario-list__col">{this.LANGUAGE.AUTOTASK_SCENARIOS_LIST.NAME_COLUMN}</div>
        <div className="scenario-list__col">{this.LANGUAGE.AUTOTASK_SCENARIOS_LIST.FIRST_RUN_COLUMN}</div>
        <div className="scenario-list__col">{this.LANGUAGE.AUTOTASK_SCENARIOS_LIST.LAST_RUN_COLUMN}</div>
        <div className="scenario-list__col">{this.LANGUAGE.AUTOTASK_SCENARIOS_LIST.ALL_RUNS_COLUMN}</div>
      </div>
      {this.renderItem(scenario)}
    </div>
  );

  renderScenarios = (scenarios) => scenarios.map(this.renderScenario);

  render() {
    const {scenarios} = this.props;

    const {activateScenarioModal, deactivateScenarioModal, changeScenarioStatusId} = this.state;

    const scenarioToChange = scenarios.find((scenario) => scenario.id === changeScenarioStatusId);
    const scenarioName = get(scenarioToChange, 'name', '');

    const deactivateScenarioMessage = this.LANGUAGE.DEACTIVATE_SCENARIO_MODAL.MESSAGE;
    const activateScenarioMessage = this.LANGUAGE.ACTIVATE_SCENARIO_MODAL.MESSAGE;

    return (
      <div>
        {this.renderScenarios(scenarios)}
        <ConfirmationModal
          show={activateScenarioModal}
          title={this.LANGUAGE.ACTIVATE_SCENARIO_MODAL.TITLE}
          message={replaceText(/\[NAME]/, activateScenarioMessage, scenarioName)}
          buttonConfirmClass="button--bg-7"
          className="theme-color-8"
          cancelText={this.LANGUAGE.ACTIVATE_SCENARIO_MODAL.CANCEL_BUTTON}
          confirmText={this.LANGUAGE.ACTIVATE_SCENARIO_MODAL.ACTIVATE_BUTTON}
          onClose={this.handleCloseModal}
          onCancel={this.handleCloseModal}
          onConfirm={() => this.changeScenarioActiveStatus(changeScenarioStatusId, true)}
        />
        <ConfirmationModal
          show={deactivateScenarioModal}
          title={this.LANGUAGE.DEACTIVATE_SCENARIO_MODAL.TITLE}
          message={replaceText(/\[NAME]/, deactivateScenarioMessage, scenarioName)}
          buttonConfirmClass="button--bg-7"
          className="theme-color-8"
          cancelText={this.LANGUAGE.DEACTIVATE_SCENARIO_MODAL.CANCEL_BUTTON}
          confirmText={this.LANGUAGE.DEACTIVATE_SCENARIO_MODAL.DEACTIVATE_BUTTON}
          onClose={this.handleCloseModal}
          onCancel={this.handleCloseModal}
          onConfirm={() => this.changeScenarioActiveStatus(changeScenarioStatusId, false)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

export default connect(mapStateToProps)(ScenarioList);
