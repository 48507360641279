import React, {Component} from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {CLIENT_PAGES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';

function ErrorsCell({data, rowIndex}) {
  const id = data[rowIndex].scenarioId;

  return (
    <Cell>
      <Link to={`${CLIENT_PAGES.SCENARIO}/${id}`} className="link link--color-1 display-block ellipsis-text">
        {data[rowIndex].scenarioName}
      </Link>
    </Cell>
  );
}

ErrorsCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

function OperationCell({data, rowIndex}) {
  const linkData = {
    clientType: data[rowIndex].clientType,
    clientId: data[rowIndex].clientId,
  };
  const clientPage = CustomLink.createClientLink(linkData);
  const operationId = data[rowIndex].operationId;
  const href = `${clientPage}${CLIENT_PAGES.OPERATIONS}/${operationId}`;

  return (
    <Cell>
      <Link to={href} className="link display-block ellipsis-text">
        {data[rowIndex].operationName}
      </Link>
    </Cell>
  );
}

OperationCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

class HomeErrorsTable extends Component {
  static propTypes = {
    data: PropTypes.array,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.HOME.ERRORS_TABLE;
  }

  render() {
    const {data} = this.props;

    return (
      <div className="fixed-table fixed-table--home-errors-card fixed-table--expand-1">
        <Table rowsCount={data.length} headerHeight={40} rowHeight={30} width={370} height={342} overflowX="hidden">
          <Column
            header={<Cell>{this.LANGUAGE.ERROR_COLUMN}</Cell>}
            cell={<ErrorsCell data={data} field="scenario" />}
            width={220}
          />
          <Column
            header={<Cell>{this.LANGUAGE.OPERATION_COLUMN}</Cell>}
            cell={<OperationCell data={data} field="operation" />}
            width={150}
          />
        </Table>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(HomeErrorsTable);
