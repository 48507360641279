export const INSTANT_WIN_TYPE = {
  TIME: 'time_instant_win',
  PARTICIPATION: 'participation_instant_win',
  SCHEDULE: 'schedule_instant_win',
};

export const SCHEDULE_INSTANT_WIN_TYPE = {
  MANUAL: 'manual',
  FIX_WEEK: 'fix_week',
  CALCULATED: 'calculated_rule',
};

export const REGEXP_SPLIT = /\s?[;,\n\t]\s?/;

export const PRIZE_TYPES = {
  COUPON: 'CouponPrize',
  LOYALTY: 'LoyaltyPrize',
  COMMON: 'Prize',
};
