import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import LayoutPanel from 'client/common/panels/layout-panel';

import {TranslationJsx} from 'client/models/language/types';

import cssModule from './profile-panel.module.scss';

const b = bem('profile-panel', {cssModule});

const ProfilePanel = (props) => {
  const {title, icon, children} = props;

  return (
    <LayoutPanel className={b()}>
      <div className={b('header')}>
        {title && <p className={b('title')}>{title}</p>}
        {icon}
      </div>
      <div className={b('body')}>{children}</div>
    </LayoutPanel>
  );
};

ProfilePanel.propTypes = {
  title: TranslationJsx,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
};

ProfilePanel.defaultProps = {
  title: null,
  icon: null,
};

export default ProfilePanel;
