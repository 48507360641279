import React from 'react';

import PropTypes from 'prop-types';

import Field from '../field';

import './datepicker-field.scss';

const DatepickerField = (props) => <Field {...props} />;

DatepickerField.propTypes = {
  ...Field.propTypes,
  type: PropTypes.string,
};

DatepickerField.defaultProps = {
  ...Field.defaultProps,
  type: 'datepicker',
  cssModifier: 'datepicker-field',
};

export default DatepickerField;
