import get from 'lodash/get';
import {isRSAA, RSAA} from 'redux-api-middleware';

import {getToken, getEmail, removeAll, getIsTrackingUser} from 'client/services/cookie-data-source';

import {WINNING_PAGES} from 'client/common/config';

const signsToReplace = {
  ' ': '%20',
  '>': '%3E',
};

const strEncoder = (str) =>
  Object.keys(signsToReplace).reduce(
    (stringToReplace, replacer) => stringToReplace.replace(new RegExp(replacer, 'g'), signsToReplace[replacer]),
    str,
  );

export function refreshToken() {
  return (next) => (action) => {
    if (!isRSAA(action)) {
      return next(action);
    }

    let callAPI = action[RSAA];

    if (callAPI.endpoint) {
      callAPI.endpoint = strEncoder(callAPI.endpoint);
    }

    if (!callAPI.headers) {
      callAPI.headers = {};
    }

    if (!callAPI.headers['Content-Type']) {
      callAPI.headers['Content-Type'] = 'application/json';
    }

    if (callAPI.noCache) {
      callAPI.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
      callAPI.headers.Pragma = 'no-cache';
      callAPI.headers.Expires = '0';
      delete callAPI.noCache;
    }

    if (!callAPI.isAuth) {
      return next(action);
    }

    delete callAPI.isAuth;

    const token = getToken();
    const email = getEmail();

    if (token) {
      if (getIsTrackingUser()) {
        callAPI.headers.Authorization = token;
      } else {
        callAPI.headers['X-User-Email'] = email;
        callAPI.headers['X-User-Token'] = token;
      }
      return next(action);
    }

    return next(action);
  };
}

export function handle401() {
  return (next) => (action) => {
    if (!isRSAA(action)) {
      return next(action);
    }

    return next(action).then((data) => {
      if (get(data, 'payload.status') === 401 && getToken()) {
        const path = getIsTrackingUser() ? WINNING_PAGES.LOGIN : '/';
        removeAll();
        window.location.href = path;
      }

      return data;
    });
  };
}
