import types from './types';

const initialState = {
  type: types.NO_DATA,
  payload: [],
};

export default function (_state = initialState, action) {
  let state = {..._state};

  switch (action.type) {
    case types.NO_DATA:
      state.type = types.NO_DATA;
      state.payload = initialState.payload;
      return state;

    case types.GET_FIRST_SOURCES_SUCCESS:
      state.type = types.GET_FIRST_SOURCES_SUCCESS;
      state.payload = action.payload.lead_first_sources;
      return state;

    default:
      return state;
  }
}
