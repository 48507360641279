import moment from 'moment';

import mapAnimatorValues from 'client/components/animations/fieldsets/animation-animator-fieldset/mapping';
import mapEventInstallationValues from 'client/components/animations/fieldsets/animation-event-fieldset/mapFormValues';
import mapProductValues from 'client/components/animations/fieldsets/animation-products-fieldset/mapFormValues';

const mapAnimationDays = (values, initial) => {
  const deleteDays = initial.animation_days.map(({id}) => ({
    id,
    _destroy: true,
  }));

  const recreateDays = values.animation_days.map((day) => ({
    from: day.from,
    to: day.to,
    id: null,
  }));

  return [...deleteDays, ...recreateDays];
};

export default ({status, formValues, campaignId, animation}) => {
  return {
    id: animation?.id,
    interaction_group_id: campaignId,
    status,
    instore_task_access_id: formValues?.access?.id,
    place_id: formValues?.place?.id,
    task_position_id: formValues?.task_position_id,
    animation_days: animation ? mapAnimationDays(formValues, animation) : formValues?.animation_days,
    week: formValues?.week || moment().isoWeek(),
    year: formValues?.year || moment().year(),
    kit_affectation: {
      id: formValues?.kit_affectation_id,
      kit_mapping_id: formValues?.kit_mapping?.id,
      quantity: '1',
    },
    animation_data: mapProductValues(formValues.products),
    contact_name: formValues.contact_name,
    contact_email: formValues.contact_email,
    contact_phone: formValues.contact_phone,
    security_email: formValues.security_email,
    security_fax: formValues.security_fax,

    ...mapAnimatorValues({formValues}),
    ...mapEventInstallationValues(formValues),
  };
};
