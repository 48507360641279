import React, {useEffect, useState, useMemo} from 'react';

import {useHistory, useParams} from 'react-router-dom';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';

import {getSmsTemplateKinds} from 'client/ducks/sms-templates/actions';
import {selectSmsTemplateKinds} from 'client/ducks/sms-templates/selectors';

import AppButton from 'client/common/buttons/app-button';
import SelectDropdown from 'client/common/selects/select-dropdown';

import DiyOpElementCard from 'client/components/diy-operation/cards/diy-op-element-card';
import SmsTemplateModal from 'client/components/sms-templates/modals/sms-template-modal';
import {EmailTemplateKind} from 'client/models/email-templates/types';
import {SmsTemplateKind} from 'client/models/sms-templates/types';

import {getTitle} from './helpers';

import cssModule from './templates-element.module.scss';

const b = bem('templates-element', {cssModule});

type TemplatesElementProps = {
  emailTemplateKinds: EmailTemplateKind[];
  loadingEmailTemplateKinds: boolean;
};

const TemplatesElement: React.FC<TemplatesElementProps> = (props) => {
  const {emailTemplateKinds, loadingEmailTemplateKinds} = props;
  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_ELEMENTS_BLOCK.TEMPLATES_ELEMENT');
  const history = useHistory();
  const params: Record<string, string> = useParams();
  const [kindId, setKindId] = useState<number | null>(null);
  const [kindType, setKindType] = useState<'sms' | 'email' | null>(null);
  const [showSmsModal, toggleSmsModal] = useToggle(false);

  const {
    data: smsTemplateKinds = [],
    loading: loadingSmsTemplateKinds,
    fetch: fetchSmsTemplateKinds,
  } = useReduxFetch({
    action: getSmsTemplateKinds,
    selector: selectSmsTemplateKinds,
    actionArgs: {
      include: {
        sms_templates: {
          template_sms_templates: null,
          sms_template_variables: null,
        },
        sms_template_kind_variables: {
          column_adapter: null,
        },
      },
      q: {
        operation_id_eq: params.operationId,
      },
    },
  });

  const hasEmails = Boolean(emailTemplateKinds.length);
  const hasSms = Boolean(smsTemplateKinds?.filter((i) => i?.sms_templates?.length)?.length);

  useEffect(() => {
    if (loadingEmailTemplateKinds || loadingSmsTemplateKinds) {
      return;
    }

    const noKindId = !kindId && (emailTemplateKinds.length || smsTemplateKinds?.length);
    const wrongKindId =
      kindId &&
      (emailTemplateKinds.length || smsTemplateKinds?.length) &&
      !emailTemplateKinds.some((i) => i.id === kindId) &&
      !smsTemplateKinds?.some((i) => i.id === kindId);

    if (noKindId || wrongKindId) {
      const firstKind = emailTemplateKinds?.[0] || smsTemplateKinds?.[0];
      setKindId(firstKind.id);
      setKindType(firstKind.type === 'EmailTemplateKind' ? 'email' : 'sms');
    }
  }, [kindId, emailTemplateKinds, smsTemplateKinds, loadingSmsTemplateKinds, loadingEmailTemplateKinds]);

  const options = useMemo(() => {
    const emailOptions = emailTemplateKinds.map((i) => ({label: i.type_name, value: i.id, type: 'email'}));
    const smsOptions = smsTemplateKinds
      ?.filter((i) => i?.sms_templates?.length)
      ?.map((i) => ({label: i.type_name, value: i.id, type: 'sms'}));

    if (emailOptions.length && smsOptions?.length) {
      return [
        {label: lang.EMAIL_TEMPLATES, options: emailOptions},
        {label: lang.SMS_TEMPLATES, options: smsOptions},
      ];
    }

    if (emailOptions.length) {
      return emailOptions;
    }

    return smsOptions;
  }, [emailTemplateKinds, smsTemplateKinds, lang.EMAIL_TEMPLATES, lang.SMS_TEMPLATES]);

  const handleEditClick = () => {
    if (kindType === 'sms') {
      toggleSmsModal();
    } else {
      history.push(
        `/clients/${params.clientId}` +
          `/operations/${params.operationId}` +
          `/autotask/${params.autotaskId}` +
          `/configuration/email-templates?kindId=${kindId}`,
      );
    }
  };

  return (
    <DiyOpElementCard
      className={b()}
      name="email-closed"
      title={getTitle(hasEmails, hasSms, lang)}
      disabled={!hasEmails && !hasSms}
      headerId="diy-conf-el-templates-head"
      hideSaveButton
      buttons={
        <>
          {showSmsModal && (
            <SmsTemplateModal
              onClose={toggleSmsModal}
              kind={smsTemplateKinds?.find((i) => i.id === kindId) as SmsTemplateKind}
              fetchData={fetchSmsTemplateKinds}
            />
          )}
          <SelectDropdown
            options={options}
            className={b('select')}
            classNames={{
              group: () => b('select-group'),
              groupHeading: () => b('select-group-header'),
            }}
            value={kindId}
            onChange={(item: {value: number; type: 'email' | 'sms'}) => {
              setKindId(item.value);
              setKindType(item.type);
            }}
            disabled={!hasEmails && !hasSms}
          />
          <AppButton
            iconName="edit-pencil"
            color="light-clients"
            className={b('button')}
            disabled={!hasEmails && !hasSms}
            onClick={handleEditClick}
          />
        </>
      }
    />
  );
};

export default TemplatesElement;
