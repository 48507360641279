import {getAccessLevel} from 'client/services/cookie-data-source';

import {MEDIA_LEVEL_MAP} from './constants';

export const getMediaLevel = () => {
  return MEDIA_LEVEL_MAP[getAccessLevel()] || null;
};

export const formatDuration = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 60 / 60);
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const seconds = (totalSeconds % 60) % 60;

  const result = [hours ? `${hours}h` : null, hours || minutes ? `${minutes}min` : null, `${seconds}s`];

  return result.filter((i) => i).join(' ');
};

export const getTotalDurations = (files = []) => {
  const totalSeconds = files.reduce((acc, file) => {
    return acc + (file.loops || 1) * ((file.active && file.duration) || 0);
  }, 0);

  return formatDuration(totalSeconds);
};
