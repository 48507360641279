import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import InstoreMonitorPanel from '../../panels/instore-monitor-panel';

import './instore-monitoring-list.scss';

const b = bem('instore-monitoring-list');

const InstoreMonitoringList = ({monitoringList}) => {
  return (
    <div className={b()}>
      {monitoringList?.map(({task_kpi, value_num, value_string}) => (
        <InstoreMonitorPanel key={task_kpi?.id} title={task_kpi?.name} description={value_num || value_string} />
      ))}
    </div>
  );
};

InstoreMonitoringList.propTypes = {
  monitoringList: PropTypes.arrayOf(
    PropTypes.shape({
      value_num: PropTypes.number,
      value_string: PropTypes.string,
      task_kpi: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        comment: PropTypes.string,
      }),
    }).isRequired,
  ),
};

export default InstoreMonitoringList;
