import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';

import {selectUser} from 'client/ducks/user/selectors';

import useTextVariables from 'client/components/diy-operation/modals/diy-customization-modal/useTextVariables';

import FormSection from './form-elements/form-section';

import './diy-customization-form.scss';

const defaultLabels = {
  title: '',
  title_more: '',
  comments: '',
};

const b = bem('diy-customization-form');

const DiyCustomizationForm = (props) => {
  const {
    page,
    setFormData,
    setFormAccessLevel,
    checkFormItem,
    templateId,
    pageAccesses,
    isAccessGranted = false,
    className = '',
    withoutPreview = false,
  } = props;
  const formSections = page?.form_sections || [];
  const formSectionsAccesses = pageAccesses?.form_sections || [];
  const formPageId = page?.id || 0;
  const {locale: lang} = useSelector(selectUser);

  const {variables} = useTextVariables();

  if (!formSections.length) {
    return null;
  }

  const getAccessBySectionId = (id) => formSectionsAccesses.find((item) => item.id === id);

  return (
    <div className={cn(b(), className)}>
      <div className={b('section-list')}>
        {formSections.map(({form_items, id, form_labels, media_storage_item, ...formSection}) => {
          const labels = form_labels?.length ? form_labels?.find(({language}) => language === lang) : defaultLabels;
          const title = labels?.title;
          const help = labels?.help;
          const comments = labels?.comments;
          const icon = media_storage_item?.media_storage?.url;
          const {access, form_items: formItemsAccesses} = getAccessBySectionId(id);

          return (
            <FormSection
              className={b('section', {'without-preview': withoutPreview})}
              key={id}
              id={id}
              templateId={templateId}
              pageId={formPageId}
              title={title}
              help={help}
              comments={comments}
              icon={icon}
              access={access}
              formItemsAccesses={formItemsAccesses}
              formItems={form_items}
              accessParams={{...formSection}}
              setFormData={(value, err, params = {}) => setFormData({...value, formPageId}, err, params)}
              setFormAccessLevel={(value) => setFormAccessLevel?.({...value, formPageId})}
              checkFormItem={checkFormItem}
              isAccessGranted={isAccessGranted}
              variables={variables}
            />
          );
        })}
      </div>
    </div>
  );
};

DiyCustomizationForm.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.number.isRequired,
    form_sections: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        form_items: PropTypes.array,
      }),
    ).isRequired,
  }),
  setFormData: PropTypes.func.isRequired,
  withoutPreview: PropTypes.bool.isRequired,
  setFormAccessLevel: PropTypes.func,
  checkFormItem: PropTypes.func,
  templateId: PropTypes.number,
  isAccessGranted: PropTypes.bool,
  className: PropTypes.string,
};

export default DiyCustomizationForm;
