import get from 'lodash/get';

import {mapLeadsStatistic} from './mappers';
import types from './types';

let init = {
  type: types.NO_DATA,
  payload: {
    data: [],
    meta: {
      current_page: 0,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
  },
  leadsStatistic: {
    gender: {
      male_count: 0,
      female_count: 0,
      unknown_count: 0,
    },
    participations_conversion: 0,
    coordinations: {
      with_phone: 0,
      with_email: 0,
      with_address: 0,
    },
    total: 0,
  },
  onlineUsersStatistic: {
    os: [],
    browser: [],
    device: [],
  },
};

export default function (_state_ = init, action) {
  let state = Object.assign({}, _state_);
  switch (action.type) {
    case types.NO_DATA:
      state.type = types.NO_DATA;
      state.payload = {data: action.payload.data, meta: action.payload.meta};
      return state;

    case types.GET_LEADS_REQUEST:
      state.payload.data = [];
      return state;

    case types.GET_LEADS_SUCCESS:
      const {
        payload: {leads, meta},
      } = action;
      state.type = types.LOADED;
      state.payload = {
        data: leads,
        meta: {
          ...state.payload.meta,
          ...meta,
        },
      };
      return state;

    case types.ADDED:
      state.type = types.ADDED;
      state.payload.data.push(action.payload);
      state.payload.meta.total_count += 1;
      return state;

    case types.FLUSH_LEADS_ALL_STATE:
      return init;

    case types.GET_LEADS_STATISTIC_SUCCESS:
      state.type = types.GET_LEADS_STATISTIC_SUCCESS;
      state.leadsStatistic = mapLeadsStatistic(get(action, 'payload.result.leads_statistic', init.leadsStatistic));
      return state;

    case types.GET_ONLINE_USERS_STATISTIC_SUCCESS:
      state.type = types.GET_ONLINE_USERS_STATISTIC_SUCCESS;
      state.onlineUsersStatistic = get(action, 'payload.result.online_user_statistic', init.onlineUsersStatistic);
      return state;

    default:
      return state;
  }
}
