import {useMedia} from 'react-use';

import {MEDIA_BREAKPOINTS} from 'client/common/config';

const getMaxWidthString = (width) => `(max-width: ${width})`;

export default () => {
  const isMobile = useMedia(getMaxWidthString(MEDIA_BREAKPOINTS.MOBILE));
  const isTablet = useMedia(getMaxWidthString(MEDIA_BREAKPOINTS.TABLET));
  const isPreLaptop = useMedia(getMaxWidthString(MEDIA_BREAKPOINTS.PRELAPTOP));
  const isLaptop = useMedia(getMaxWidthString(MEDIA_BREAKPOINTS.LAPTOP));
  const isFull = useMedia(getMaxWidthString(MEDIA_BREAKPOINTS.FULL));

  return {isMobile, isTablet, isPreLaptop, isLaptop, isFull};
};
