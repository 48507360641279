import React from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getUserId} from 'client/services/cookie-data-source';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {selectBroadcastDisplayItemsMappedTable} from 'client/ducks/broadcast/selectors';
import {flushCustomColumnsAllState} from 'client/ducks/custom-columns/actions';
import {flushInternalColumnsAllState} from 'client/ducks/internal-columns/actions';
import {getLeads, flushLeadsAllState} from 'client/ducks/leads-list/actions';
import {selectLeadsMeta, selectLeadsMailingTableMapped} from 'client/ducks/leads-list/selectors';
import {getMessageTask, flushMessageTaskAllState} from 'client/ducks/message-email-sms-task/actions';
import {selectTaskBaseInfo} from 'client/ducks/message-email-sms-task/selectors';
import {getOptInsAction, flushOptinsColumnsAllState} from 'client/ducks/opt-in-columns/actions';

import {CLIENT_PAGES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';
import TitleBlock from 'client/components/common/title-block';

import BCMailingFiltersForm from '../components/forms/bc-mailing-filters-form';
import BCOptinsFiltersForm from '../components/forms/bc-optins-filters-form';
import BCMailingFiltersModal from '../components/modals/bc-mailing-filters-modal';
import BCParticipationsModal from '../components/modals/bc-participations-modal';
import BCTableSettingsModal from '../components/modals/bc-table-settings-modal';
import BCMailingDataGrid from '../components/tables/bc-mailing-data-grid';

class MailingList extends ReactQueryParams {
  static propTypes = {
    messageTaskInfo: PropTypes.object.isRequired,
    languageState: PropTypes.object.isRequired,
    ...withRouter.propTypes,
    broadcastId: PropTypes.string.isRequired,
    taskId: PropTypes.string.isRequired,
    operationId: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    getMessageTask: PropTypes.func.isRequired,
    getOptIns: PropTypes.func.isRequired,
    getLeads: PropTypes.func.isRequired,
    flushMessageTaskAllState: PropTypes.func.isRequired,
    flushOptinsColumnsAllState: PropTypes.func.isRequired,
    flushLeadsAllState: PropTypes.func.isRequired,
    flushCustomColumnsAllState: PropTypes.func.isRequired,
    flushInternalColumnsAllState: PropTypes.func.isRequired,
    displayItems: PropTypes.array.isRequired,
  };

  static DEFAULT_FIRST_PAGE = 1;
  static DEFAULT_PER_PAGE = 5;
  static DEFAULT_SORT_FIELD = 'first_name';
  static DEFAULT_SORT_ORDER = 'asc';

  static MODALS_MAP = {
    FILTERS: 'FILTERS',
    SETTINGS: 'SETTINGS',
    PARTICIPATIONS: 'PARTICIPATIONS',
  };

  constructor() {
    super();

    this.state = {
      visibleModal: '',
      participationsModalData: {},
      loading: false,
    };

    this.userId = getUserId();
  }

  defaultQueryParams = {
    filters: JSON.stringify({}),
    opt_ins: JSON.stringify({}),
    optinsControl: '',
    m: '',
    search: '',
    sort: JSON.stringify({}),
    page: '',
    perPage: '',
  };

  componentDidMount() {
    this.getMessageTask().then(() => {
      const {
        languageState: {
          payload: {BREADCRUMBS},
        },
        messageTaskInfo: {
          broadcastName,
          taskName,
          taskId,
          taskType,
          operationName,
          operationId,
          clientName,
          clientId,
          clientType,
        },
      } = this.props;

      const clientLink = CustomLink.createClientLink({
        clientType,
        clientId,
      });

      const operationLink = CustomLink.createOperationLink({
        clientType,
        operationId,
        clientId,
      });

      const taskLink = CustomLink.createTaskLink({
        taskType,
        clientType,
        operationId,
        clientId,
        taskId,
      });

      const settingsLink = `${taskLink}${CLIENT_PAGES.MESSAGE_TASK_SETTINGS}`;

      this.props.setBreadcrumbs([
        {
          name: BREADCRUMBS.CLIENTS,
          href: CLIENT_PAGES.CLIENTS_LIST,
        },
        {
          name: clientName,
          href: clientLink,
        },
        {
          name: operationName,
          href: operationLink,
        },
        {
          name: taskName,
          href: taskLink,
        },
        {
          name: BREADCRUMBS.TASK_SETTINGS,
          href: settingsLink,
        },
        {
          name: broadcastName,
        },
      ]);
    });

    this.props
      .getOptIns({
        q: {
          client_id_eq: this.props.clientId,
        },
      })
      .then(() => {
        this.fetchData();
      });
  }

  componentWillUnmount() {
    this.props.flushMessageTaskAllState();
    this.props.flushOptinsColumnsAllState();
    this.props.flushLeadsAllState();
    this.props.flushCustomColumnsAllState();
    this.props.flushInternalColumnsAllState();
    this.props.clearBreadcrumbs();
  }

  getMessageTask = () => {
    const {taskId} = this.props;

    const params = {
      include_client_leads_count: null,
      include: ['client', 'operation', 'broadcast_list'],
    };

    this.setState({loading: true});
    return this.props.getMessageTask(taskId, params).then((res) => {
      this.setState({loading: false});
      return res;
    });
  };

  handleSave = () => {
    const {
      messageTaskInfo: {taskId, taskType, operationId, clientId, clientType},
    } = this.props;

    const taskLink = CustomLink.createTaskLink({
      taskType,
      clientType,
      operationId,
      clientId,
      taskId,
    });

    const link = `${taskLink}${CLIENT_PAGES.MESSAGE_TASK_SETTINGS}`;

    this.props.history.push(link);
  };

  handleShowModal = (name) => {
    this.setState({
      visibleModal: name,
    });
  };

  handleCloseModal = () => {
    this.setState({
      visibleModal: '',
    });
  };

  handleParticipationsClick = (data) => {
    const {leadId, participations} = data;

    this.setState({
      participationsModalData: {
        leadId,
        participations,
      },
    });
    this.handleShowModal(MailingList.MODALS_MAP.PARTICIPATIONS);
  };

  handleSearch = (value) => {
    this.setQueryParams(
      {
        search: value,
        page: MailingList.DEFAULT_FIRST_PAGE,
      },
      true,
    );
    this.fetchData();
  };

  handlePageChange = (params) => {
    this.setQueryParams(params);
    return this.fetchData();
  };

  handleSortChange = (name, order) => {
    this.setQueryParams({
      sort: {
        name,
        order,
      },
    });
    return this.fetchData();
  };

  handleClear = () => {
    this.setQueryParams(
      {
        search: '',
      },
      true,
    );
    this.fetchData();
  };

  handleShowFilters = () => {
    this.setState({
      visibleModal: MailingList.MODALS_MAP.FILTERS,
    });
  };

  handleShowSettings = () => {
    this.setState({
      visibleModal: MailingList.MODALS_MAP.SETTINGS,
    });
  };

  handleFiltersChange = (filters) => {
    this.setQueryParams({
      [BCMailingFiltersForm.qsNames.filters]: filters,
    });
    this.fetchData();
  };

  handleOptinsChange = (obj) => {
    let filters = {};
    const historyName = BCMailingFiltersForm.filtersMap.lead_histories_source_id_eq.field;

    if (!isEmpty(get(this.queryParams.filters, historyName))) {
      filters = {
        [BCMailingFiltersForm.qsNames.filters]: {
          ...this.queryParams.filters,
          [historyName]: {
            ...this.queryParams.filters[historyName],
            label: encodeURIComponent(this.queryParams.filters[historyName].label),
          },
        },
      };
    }

    this.setQueryParams({
      ...filters,
      [BCOptinsFiltersForm.qsNames.opt_ins]: obj[BCOptinsFiltersForm.qsNames.opt_ins],
      [BCOptinsFiltersForm.qsNames.optinsControl]: obj[BCOptinsFiltersForm.qsNames.optinsControl],
      [BCOptinsFiltersForm.qsNames.m]: obj[BCOptinsFiltersForm.qsNames.m],
    });

    this.fetchData();
  };

  handleFiltersClear = () => {
    this.setQueryParams({
      [BCMailingFiltersForm.qsNames.filters]: {},
      [BCOptinsFiltersForm.qsNames.opt_ins]: {},
      [BCOptinsFiltersForm.qsNames.m]: '',
      [BCOptinsFiltersForm.qsNames.optinsControl]: '',
    });
    this.fetchData();
  };

  handleFilterDelete = (filters) => (data) => {
    if (data === BCOptinsFiltersForm.qsNames.opt_ins) {
      this.setQueryParams({
        [BCOptinsFiltersForm.qsNames.opt_ins]: {},
        [BCOptinsFiltersForm.qsNames.m]: '',
        [BCOptinsFiltersForm.qsNames.optinsControl]: '',
      });
    } else {
      delete filters[data];
      this.setQueryParams({
        [BCMailingFiltersForm.qsNames.filters]: filters,
      });
    }
    this.fetchData();
  };

  getOptinsQuery = () => {
    const {queryParams} = this;
    let optIns = {};

    switch (queryParams[BCOptinsFiltersForm.qsNames.optinsControl]) {
      case BCOptinsFiltersForm.controlNames.custom:
        const filteredOptins = Object.keys(queryParams[BCOptinsFiltersForm.qsNames.opt_ins]).reduce((acc, key) => {
          return queryParams[BCOptinsFiltersForm.qsNames.opt_ins][key]
            ? {
                ...acc,
                [key]: queryParams[BCOptinsFiltersForm.qsNames.opt_ins][key],
              }
            : acc;
        }, {});

        optIns = queryParams[BCOptinsFiltersForm.qsNames.m]
          ? {
              [BCOptinsFiltersForm.qsNames.m]: queryParams[BCOptinsFiltersForm.qsNames.m],
              ...filteredOptins,
            }
          : filteredOptins;
        break;
      case BCOptinsFiltersForm.controlNames.noActive:
      case BCOptinsFiltersForm.controlNames.all:
        optIns = queryParams[BCOptinsFiltersForm.qsNames.m]
          ? {
              [BCOptinsFiltersForm.qsNames.m]: queryParams[BCOptinsFiltersForm.qsNames.m],
              ...queryParams[BCOptinsFiltersForm.qsNames.opt_ins],
            }
          : queryParams[BCOptinsFiltersForm.qsNames.opt_ins];
        break;
      case BCOptinsFiltersForm.controlNames.noMatter:
      default:
    }

    return optIns;
  };

  getFiltersQuery = () => {
    const {queryParams} = this;
    const map = BCMailingFiltersForm.filtersMap;
    const source =
      queryParams[BCMailingFiltersForm.qsNames.filters] &&
      queryParams[BCMailingFiltersForm.qsNames.filters][map.lead_histories_source_id_eq.field];

    return source
      ? {
          ...queryParams[BCMailingFiltersForm.qsNames.filters],
          [map.lead_histories_source_id_eq.field]: source.value,
        }
      : queryParams[BCMailingFiltersForm.qsNames.filters];
  };

  fetchData = () => {
    const {queryParams} = this;
    const {clientId, displayItems} = this.props;

    // get sort query
    const sort =
      `${(queryParams.sort && queryParams.sort.name) || MailingList.DEFAULT_SORT_FIELD} ` +
      `${(queryParams.sort && queryParams.sort.order) || MailingList.DEFAULT_SORT_ORDER}`;

    // get search query
    const search = queryParams.search;

    // get opt ins filters query
    const opt_ins = this.getOptinsQuery();

    // get filters query
    const filters = this.getFiltersQuery();

    const searchByCustomColumns = search
      ? displayItems
          .filter(({type}) => type === 'ExternalColumnAdapter')
          .reduce((result, {column_id, isArray}) => {
            if (isArray) {
              result[`data->>${column_id}_in`] = [`{${search}}`];
            } else {
              result[`data->>${column_id}_cont`] = search;
            }
            return result;
          }, {})
      : {};

    // get query
    const params = {
      q: {
        client_id_eq: clientId,
        s: sort,
        g: {
          search: {
            m: 'or',
            first_name_cont: search,
            last_name_cont: search,
            ...searchByCustomColumns,
          },
          [BCMailingFiltersForm.qsNames.filters]: filters,
          [BCOptinsFiltersForm.qsNames.opt_ins]: opt_ins,
        },
      },
      include: {
        last_participation: 'operation.client',
        participations: {
          interaction: 'interface',
          operation: 'client',
        },
        broadcast_recipients: null,
        last_export: 'client',
        first_source: {
          client: null,
          interaction: 'interface.automation_task.operation.client',
        },
      },
      page: queryParams.page || MailingList.DEFAULT_FIRST_PAGE,
      per_page: queryParams.perPage || MailingList.DEFAULT_PER_PAGE,
    };
    window.scrollTo(0, 0);
    return this.props.getLeads(params);
  };

  render() {
    const {
      broadcastId,
      taskId,
      operationId,
      clientId,
      messageTaskInfo: {broadcastName},
      meta,
      data,
      languageState,
      isAdmin,
    } = this.props;

    const {visibleModal, participationsModalData} = this.state;

    return (
      <div className="theme-color-16">
        <BCTableSettingsModal
          userId={this.userId}
          clientId={clientId}
          show={MailingList.MODALS_MAP.SETTINGS === visibleModal}
          onClose={this.handleCloseModal}
        />
        <BCMailingFiltersModal
          clientId={clientId}
          filters={this.queryParams.filters}
          optinsFilters={this.queryParams.opt_ins}
          optinsControl={this.queryParams.optinsControl}
          optinsOperator={this.queryParams.m}
          show={MailingList.MODALS_MAP.FILTERS === visibleModal}
          onClose={this.handleCloseModal}
          onFiltersChange={this.handleFiltersChange}
          onOptinsChange={this.handleOptinsChange}
        />
        <BCParticipationsModal
          show={MailingList.MODALS_MAP.PARTICIPATIONS === visibleModal}
          leadId={participationsModalData.leadId}
          participations={participationsModalData.participations}
          onClose={this.handleCloseModal}
          isAdmin={isAdmin}
        />
        <div className="page__title-block">
          <div>
            <TitleBlock theme ellipsis="1">
              <TitleBlock.Item>
                {`${languageState.payload.MESSAGE_TASK.MAILING_LIST.TITLE} ${broadcastName}`}
              </TitleBlock.Item>
            </TitleBlock>
          </div>
        </div>
        {this.userId && (
          <BCMailingDataGrid
            data={data}
            meta={meta}
            loading={this.state.loading}
            userId={this.userId}
            broadcastId={broadcastId}
            taskId={taskId}
            operationId={operationId}
            clientId={clientId}
            onShowFilterClick={this.handleShowFilters}
            onShowSettingsClick={this.handleShowSettings}
            onSettings={this.handleShowSettings}
            onSave={this.handleSave}
            onParticipationsClick={this.handleParticipationsClick}
            onSearchClear={this.handleClear}
            onSearch={this.handleSearch}
            onSortChange={this.handleSortChange}
            onPageChange={this.handlePageChange}
            onFiltersClear={this.handleFiltersClear}
            onFilterDelete={this.handleFilterDelete}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const task = selectTaskBaseInfo(state);

  return {
    languageState: state.languageState,
    messageTaskInfo: task,
    data: selectLeadsMailingTableMapped(state, ownProps.broadcastId, task.taskType),
    meta: selectLeadsMeta(state),
    displayItems: selectBroadcastDisplayItemsMappedTable(state),
  };
};

const mapDispatchToProps = {
  setBreadcrumbs,
  clearBreadcrumbs,
  getMessageTask,
  getLeads,
  getOptIns: getOptInsAction,
  flushMessageTaskAllState,
  flushOptinsColumnsAllState,
  flushLeadsAllState,
  flushCustomColumnsAllState,
  flushInternalColumnsAllState,
};

export default connect(mapStateToProps, mapDispatchToProps)(MailingList);
