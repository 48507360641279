import React, {useCallback, useEffect, useMemo} from 'react';

import sortBy from 'lodash/sortBy';
import {useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {getAndDownloadFile} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import {getInstoreStatsFiles} from 'client/ducks/instore-tasks/actions';
import {selectInstoreTask, selectInstoreTaskStatsFiles} from 'client/ducks/instore-tasks/selectors';

import AppButton from 'client/common/buttons';
import DateCell from 'client/common/cells/date-cell';
import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import cssModule from './instore-reporting-download-button.module.scss';

const b = bem('instore-reporting-download-button', {cssModule});

const InstoreReportingDownloadButton = () => {
  const task = useSelector(selectInstoreTask);
  const files = useSelector(selectInstoreTaskStatsFiles);
  const dispatch = useDispatch();
  const lang = useLanguage('INSTORE_TASK.CONTAINERS.INSTORE_REPORTING_CONTAINER');

  const fetch = useCallback(() => {
    if (task?.id) {
      const queryParams = {
        q: {
          instore_task_id_eq: task?.id,
        },
      };
      dispatch(getInstoreStatsFiles(queryParams));
    }
  }, [dispatch, task?.id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const sortedFiles = useMemo(() => sortBy(files, 'updated_by_erp'), [files]);

  const onDownload = (file) => {
    getAndDownloadFile(file.url, file.filename);
  };

  const overlay = !!sortedFiles.length && (
    <ul className={b('files')}>
      {sortedFiles.map((file) => (
        <li className={b('file')} key={file.id}>
          <button className={b('file-button')} type="button" onClick={() => onDownload(file)}>
            <Icon name="downloads" className={b('file-icon')} />
            <div className={b('file-info')}>
              <span className={b('file-name')}>{file.name}</span>
              <DateCell value={file.updated_by_erp} timeFormat="HH[h]mm" className={b('file-time')} />
            </div>
          </button>
        </li>
      ))}
    </ul>
  );

  return (
    <Popover overlay={overlay} contentCssClass={b('overlay')} position="top">
      <AppButton label={lang.DOWNLOAD_BUTTON} iconName="downloads" disabled={!files?.length} />
    </Popover>
  );
};

export default InstoreReportingDownloadButton;
