import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {set} from 'client/common/meta/meta.actions';

import LeadsImport from 'client/components/leads/leads-import';

class LeadsImportPage extends ReactQueryParams {
  componentDidMount() {
    this.props.setMetaAction({
      title: this.props.languageState.payload.LEADS_PORT.TITLE_IMPORT,
    });
  }

  render() {
    const {queryParams: params} = this;
    return <LeadsImport urlParams={params} importId={this.props.match.params.importId} />;
  }
}

LeadsImportPage.propTypes = {
  setMetaAction: PropTypes.func.isRequired,
  languageState: PropTypes.object.isRequired,
};

export default connect(({languageState}) => ({languageState}), {
  setMetaAction: set,
})(LeadsImportPage);
