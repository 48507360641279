import React from 'react';

import {useLanguage} from 'client/services/hooks';

import CustomLink from 'client/components/common/custom-link';

import {MessageTask} from 'client/models/message-task/types';
import {TASK_TYPES} from 'client/models/operations/constants';

import MessageTaskListHeader from '../message-task-list-header';
import MessageTaskListPanel from '../message-task-list-panel';

type MessageTaskListAdditionalProps = {
  task: MessageTask;
  onClicksCountClick: (clicksStatistic: any) => void;
  className?: string;
  classNames?: {panel?: string; col?: string; link?: string; row?: string};
};

const MessageTaskListAdditional: React.FC<MessageTaskListAdditionalProps> = (props) => {
  const {task, onClicksCountClick, className = '', classNames} = props;

  const lang = useLanguage('OPERATION_PAGE.MESSAGE_TASK_CARD');

  const renderResultsLink = (currentTask: MessageTask) => (
    <CustomLink className={classNames?.link} to={CustomLink.createMessageTaskResultsLink(currentTask)}>
      {lang.SEE_RESULTS_LABEL}
    </CustomLink>
  );

  const renderClicksCount = (currentTask: MessageTask) => (
    <div className={classNames?.link} onClick={onClicksCountClick}>
      {currentTask?.clicksCount}
    </div>
  );

  const columnsMap = [
    {
      name: lang.N_CLICKS_COLUMN,
      forTaskType: [TASK_TYPES.EMAIL],
      renderMethod: renderClicksCount,
    },
    {
      name: lang.RESULT_COLUMN,
      forTaskType: [TASK_TYPES.EMAIL, TASK_TYPES.SMS],
      renderMethod: renderResultsLink,
    },
  ];

  return (
    <div className={className}>
      <MessageTaskListHeader headers={columnsMap} taskType={task.taskType} classNames={classNames} />
      <MessageTaskListPanel data={columnsMap} taskType={task.taskType} classNames={classNames} task={task} />
    </div>
  );
};

export default MessageTaskListAdditional;
