import React, {useEffect} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useQueryParams, useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';
import WeekSelectInput from 'client/common/inputs/selects/week-select-input';
import {getCurrentWeek} from 'client/common/inputs/selects/week-select-input/helpers';

import AnimationQuestionnairesBlock from 'client/components/animations/blocks/animation-questionnaires-block';
import AnimationPhotosModal from 'client/components/animations/modals/animation-photos-modal';
import InstoreWeekAnimationList from 'client/components/instore/lists/instore-week-animation-list';

import './instore-week-view-container.scss';

const b = bem('instore-week-view-container');

const InstoreWeekViewContainer = (props) => {
  const {task} = props;
  const [queryParams, setQueryParams] = useQueryParams();
  const lang = useLanguage('INSTORE_TASK.CONTAINERS.INSTORE_WEEK_VIEW_CONTAINER');
  const [showPhotosModal, togglePhotosModal] = useToggle(false);

  useEffect(() => {
    if (!queryParams.week) {
      setQueryParams({week: getCurrentWeek()}, true);
    }
  }, [queryParams.week, setQueryParams]);

  if (!task || !queryParams.week) {
    return null;
  }

  return (
    <div className={b()}>
      <div className={b('toolbar')}>
        <WeekSelectInput value={queryParams.week} onChange={(week) => setQueryParams({week})} />
        <AppButton iconName="camera-3" label={lang.VIEW_PHOTOS} onClick={togglePhotosModal} />
        {showPhotosModal && <AnimationPhotosModal task={task} onClose={togglePhotosModal} week={queryParams.week} />}
      </div>
      <AnimationQuestionnairesBlock
        className={b('questionnaires')}
        filters={{
          week: moment(queryParams.week).isoWeek(),
        }}
        showAllQuestions={true}
      />
      <InstoreWeekAnimationList task={task} week={queryParams.week} />
    </div>
  );
};

InstoreWeekViewContainer.propTypes = {
  task: PropTypes.object,
};

InstoreWeekViewContainer.defaultProps = {
  task: null,
};

export default InstoreWeekViewContainer;
