import {Translation} from 'client/models/language/types';

const checkSize = (width: number, height: number, check: number, errorMessage: Translation) =>
  width > check && height > check ? {check_error_size: errorMessage} : null;

const checkWidth = (width: number, check: number, errorMessage: Translation) =>
  width > check ? {check_error_width: errorMessage} : null;

const checkHeight = (height: number, check: number, errorMessage: Translation) =>
  height > check ? {check_error_height: errorMessage} : null;

const validate = (
  width: number,
  height: number,
  {
    checks,
    messages,
  }: {
    checks: {check_error_size: number; check_error_width: number; check_error_height: number};
    messages: {check_error_size: Translation; check_error_width: Translation; check_error_height: Translation};
  },
): {[key: string]: Translation} => {
  return {
    ...checkSize(width, height, checks.check_error_size, messages.check_error_size),
    ...checkWidth(width, checks.check_error_width, messages.check_error_width),
    ...checkHeight(height, checks.check_error_height, messages.check_error_height),
  };
};

export default validate;
