import React from 'react';

import PropTypes from 'prop-types';

import Popover from 'client/common/popovers/popover';

import {TranslationJsx} from 'client/models/language/types';

import './leads-label.scss';

const LeadsLabel = ({label, value, hasTooltip}) => {
  return (
    <div className="leads-label">
      <p className="leads-label__label">{label}</p>
      {hasTooltip ? (
        <Popover
          position="bottom"
          overlay={value}
          overlayInnerStyle={{
            width: 280,
          }}
        >
          <p className="leads-label__value">{value}</p>
        </Popover>
      ) : (
        <p className="leads-label__value">{value}</p>
      )}
    </div>
  );
};

LeadsLabel.defaultProps = {
  label: '',
  value: '',
  hasTooltip: false,
};

LeadsLabel.propTypes = {
  label: TranslationJsx,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool]),
  hasTooltip: PropTypes.bool,
};

export default LeadsLabel;
