import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import SearchField from 'client/components/common/search-field';

import {mapFilter} from 'client/components/leads/leads-list/helper';

import ParticipantsListToolbarLabels, {checkOperationFilter} from './participants-list-toolbar-labels';

import ParticipantsListSearchOptions from '../participants-list-search-options';

import cssModule from './participants-list-toolbar.module.scss';

const b = bem('participants-list-toolbar', {cssModule});

const ParticipantsListToolbar = (props) => {
  const {
    onFilterFieldClear,
    filter,
    totalCount,
    onFilterShow,
    onShowTableSettings,
    onShowClientVisibilitySettings,
    onSearchChange,
    onShowExport,
    onShowExportAll,
    isReportTable,
    searchOption,
    onSearchOptionChange,
    perPage,
    searchDefault,
    onChangePerPage,
    showClear,
    onFilterClear,
  } = props;
  const lang = useLanguage('PARTICIPANTS_LIST');
  const langTable = useLanguage('TABLE');

  return (
    <div className={b()}>
      <div className={b('filters')}>
        {!isReportTable && <ParticipantsListSearchOptions value={searchOption} onChange={onSearchOptionChange} />}
        <SearchField
          cssModifier={b('field', ['search'])}
          placeholder={lang.SEARCH_PLACEHOLDER}
          onSearch={onSearchChange}
          onClear={onSearchChange}
          showClear={showClear}
          searchDefault={searchDefault}
        />
        <AppButton
          nativeStyle
          label={`${lang.FILTERS_BUTTON}
              (${Object.keys(checkOperationFilter(mapFilter(filter))).length})`}
          iconName="filter"
          className="button button--bg-4"
          onClick={onFilterShow}
        />
        <span className={b('total-count')}>
          <strong>{totalCount}</strong>
          <span>{` ${langTable.RESULTS}`}</span>
        </span>
      </div>

      <ParticipantsListToolbarLabels
        onFilterFieldClear={onFilterFieldClear}
        onFilterClear={onFilterClear}
        filter={filter}
      />

      <div className={b('buttons')}>
        {!isReportTable && (
          <AppButton
            label={lang.CLIENT_VISIBILITY}
            nativeStyle
            className="button button--bg-4"
            onClick={onShowClientVisibilitySettings}
          />
        )}
        <AppButton
          iconName="export"
          nativeStyle
          label={lang.EXPORT_ALL_BUTTON}
          className="button button--bg-4"
          onClick={onShowExportAll}
        />
        {!isReportTable && (
          <>
            <AppButton
              iconName="export"
              nativeStyle
              label={lang.EXPORT_BUTTON}
              className="button button--bg-4"
              onClick={onShowExport}
            />

            <AppButton
              iconName="settings"
              nativeStyle
              label={lang.TABLE_SETTINGS_BUTTON}
              className="button button--bg-4"
              onClick={onShowTableSettings}
            />
          </>
        )}
        <PerPageDropdown
          value={perPage?.toString()}
          onChange={onChangePerPage}
          simpleValue
          size="small"
          options={[5, 15, 30].map((n) => ({value: `${n}`, label: n}))}
          labelClassName={b('per-page-label')}
          className={b('per-page-container')}
        />
      </div>
    </div>
  );
};

ParticipantsListToolbar.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isReportTable: PropTypes.bool,
  onFilterFieldClear: PropTypes.func.isRequired,
  onFilterClear: PropTypes.func.isRequired,
  filter: PropTypes.object,
  onFilterShow: PropTypes.func.isRequired,
  onShowExport: PropTypes.func.isRequired,
  onShowExportAll: PropTypes.func.isRequired,
  onShowTableSettings: PropTypes.func.isRequired,
  onShowClientVisibilitySettings: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  searchOptionsComponent: PropTypes.node,
  perPage: PropTypes.number.isRequired,
  onChangePerPage: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  searchDefault: PropTypes.string,
  showClear: PropTypes.bool.isRequired,
  onSearchOptionChange: PropTypes.func.isRequired,
  searchOption: PropTypes.string,
};

ParticipantsListToolbar.defaultProps = {
  filter: {},
  searchOptionsComponent: null,
};

export default ParticipantsListToolbar;
