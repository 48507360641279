import React, {Component} from 'react';

import PropTypes from 'prop-types';

import ClientLeads from './client-leads';
import ClientOnlineUsers from './client-online-users';
import MainInfoBlock from './participation-info-block';
import ParticipationTunnel from './participation-tunnel';
import ParticipationsTimeline from './participations-timeline';
import SourcesBlock from './sources-block';
import SpreadPlan from './spread-plan';

import './participations-tab.scss';

class ParticipationsTab extends Component {
  static propTypes = {
    autotask: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {autotask} = this.props;

    return (
      <div className="participations-tab">
        <div className="participations-tab__row">
          <MainInfoBlock autotask={autotask} />
        </div>
        <div className="participations-tab__row">
          <div className="participations-tab__col-full">
            <ParticipationsTimeline autotask={autotask} />
          </div>
        </div>
        <div className="participations-tab__row">
          <div className="participations-tab__col-full">
            <SpreadPlan autotask={autotask} />
          </div>
        </div>
        <div className="participations-tab__row">
          <div className="participations-tab__col-full">
            <div className="participations-tab__col-flex">
              <ParticipationTunnel autotask={autotask} />
              <SourcesBlock autotask={autotask} />
            </div>
          </div>
        </div>
        <div className="participations-tab__row">
          <div className="participations-tab__col-half">
            <ClientLeads autotask={autotask} />
          </div>
          <div className="participations-tab__col-half">
            <ClientOnlineUsers autotask={autotask} />
          </div>
        </div>
      </div>
    );
  }
}

export default ParticipationsTab;
