import {useMemo} from 'react';

import {useSelector} from 'react-redux';

import {selectClientInterface, selectOperation} from 'client/ducks/operations/selectors';

import {getTemplateByFormId} from 'client/components/diy-operation/modals/diy-customization-modal/helpers';
import {Template} from 'client/models/templates/types';

export const useTemplateParamsByAutotask = (params: {formId?: number | null}) => {
  const {formId} = params;

  const clientInterface = useSelector(selectClientInterface);
  const operation = useSelector(selectOperation);

  const template = useMemo(() => {
    if (clientInterface && formId) {
      return getTemplateByFormId(formId, clientInterface.templates || []) as Template;
    }
  }, [clientInterface, formId]);

  return {
    template,
    device: !!clientInterface?.device,
    online: !!clientInterface?.online,
    disabled: !!operation?.with_real_participations,
  };
};
