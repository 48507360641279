import React from 'react';

import {Link} from 'react-router-dom';

import bem from 'client/services/bem';
import {useLanguage, useAppMedia} from 'client/services/hooks';

import {CLIENT_PAGES} from 'client/common/config';

import cssModule from './footer.module.scss';

const b = bem('footer', {cssModule});

type FooterProps = {
  footerLogo?: string;
  isUserNotAdmin?: boolean;
  isWinning: boolean;
};

const Footer: React.FC<FooterProps> = (props) => {
  const {footerLogo = '', isUserNotAdmin = false, isWinning} = props;
  const lang = useLanguage('FOOTER');
  const {isTablet} = useAppMedia();

  if (isWinning && isTablet) {
    return null;
  }

  return (
    <footer className={b({client: isUserNotAdmin})}>
      <nav className={b('nav')}>
        <Link to={CLIENT_PAGES.CONTACTS} className={b('nav-link')}>
          {lang.LINK_CONTACTS}
        </Link>
        <Link to={CLIENT_PAGES.PRIVACY_POLICY} className={b('nav-link')}>
          {lang.LINK_PRIVACY}
        </Link>
        <Link to={CLIENT_PAGES.TERMS} className={b('nav-link')}>
          {lang.LINK_TERMS}
        </Link>
        <Link to={CLIENT_PAGES.COOKIES_POLICY} className={b('nav-link')}>
          {lang.LINK_COOKIES}
        </Link>
      </nav>
      {footerLogo && (
        <div className={b('logo-block')}>
          <div className={b('text-item')}>{lang.POWERED_LABEL}</div>
          <img className={b('logo')} src={footerLogo} alt={lang.NO_LOGO.toString()} />
        </div>
      )}
    </footer>
  );
};

export default Footer;
