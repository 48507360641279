import React, {Component} from 'react';

import PropTypes from 'prop-types';

import AppButton from 'client/common/buttons/app-button';

class TwitterLogin extends Component {
  constructor(props) {
    super(props);
  }

  onButtonClick = () => {
    return this.getRequestToken();
  };

  getRequestToken = () => {
    const popup = this.openPopup();

    return window
      .fetch(this.props.requestTokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          oauth: {
            oauth_callback: location.origin,
          },
        }),
      })
      .then((response) => {
        return response.text();
      })
      .then((oauth_token) => {
        popup.location = `https://api.twitter.com/oauth/authorize?oauth_token=${oauth_token}`;
        this.polling(popup);
      })
      .catch((error) => {
        popup.close();
        return this.props.onFailure(error);
      });
  };

  openPopup = () => {
    const w = this.props.dialogWidth;
    const h = this.props.dialogHeight;
    const left = screen.width / 2 - w / 2;
    const top = screen.height / 2 - h / 2;

    return window.open(
      '',
      '',
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, ' +
        'resizable=no, copyhistory=no, width=' +
        w +
        ', height=' +
        h +
        ', top=' +
        top +
        ', left=' +
        left,
    );
  };

  polling = (popup) => {
    // eslint-disable-next-line consistent-return
    const polling = setInterval(() => {
      if (!popup || popup.closed || typeof popup.closed === 'undefined') {
        clearInterval(polling);
        this.props.onFailure(new Error('Popup has been closed by user'));
      }

      const closeDialog = () => {
        clearInterval(polling);
        popup.close();
      };

      try {
        if (popup.location.hostname && !popup.location.hostname.includes('api.twitter.com')) {
          if (popup.location.href) {
            const query = new URLSearchParams(popup.location.href.split('?')[1]);

            const oauth_token = query.get('oauth_token');
            const oauth_verifier = query.get('oauth_verifier');

            closeDialog();
            return this.props.onSuccess({oauth_token, oauth_verifier});
          }
          closeDialog();
          return this.props.onFailure(
            new Error(
              'OAuth redirect has occurred but no query or hash parameters were found. ' +
                'They were either not set during the redirect, or were removed—typically by a ' +
                'routing library—before Twitter react component could read it.',
            ),
          );
        }
      } catch (error) {
        // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
        // A hack to get around same-origin security policy errors in IE.
      }
    }, 500);
  };

  render() {
    return (
      <AppButton
        onClick={this.onButtonClick}
        disabled={this.props.disabled}
        className={this.props.className}
        label={this.props.children}
      />
    );
  }
}

TwitterLogin.propTypes = {
  className: PropTypes.string,
  requestTokenUrl: PropTypes.string.isRequired,
  onFailure: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  dialogWidth: PropTypes.number,
  dialogHeight: PropTypes.number,
};

TwitterLogin.defaultProps = {
  disabled: false,
  dialogWidth: 600,
  dialogHeight: 400,
  onFailure: () => {},
};

export default TwitterLogin;
