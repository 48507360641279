import React from 'react';

import {Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';

import ParticipantsAPISettingsPopover from '../../../popovers/participants-api-settings-popover';
import ParticipantsCommentPopover from '../../../popovers/participants-comments-popover';

function ExportAPIHistoryToolsCell(props) {
  const {data, rowIndex, field, updateCallBack, editCallBack, isDashboard} = props;
  const comment = data[rowIndex][field].comment;
  return (
    <Cell>
      <div className={`export-history-tools-cell__container${isDashboard ? '-dashboard' : ''}`}>
        <ParticipantsAPISettingsPopover
          id={data[rowIndex][field].id}
          memberships={data[rowIndex][field].memberships}
          allowed_for_all={data[rowIndex][field].allowed_for_all}
          from={data[rowIndex][field].from}
          to={data[rowIndex][field].to}
          updateCallBack={updateCallBack}
          editCallBack={editCallBack}
        >
          <div className="leads-import__header-icon">
            <Icon name="settings" />
          </div>
        </ParticipantsAPISettingsPopover>
        {comment && (
          <ParticipantsCommentPopover comment={comment}>
            <div className="participants-export__header-icon">
              <Icon name="message" />
            </div>
          </ParticipantsCommentPopover>
        )}
      </div>
    </Cell>
  );
}

ExportAPIHistoryToolsCell.defaultProps = {
  data: [],
};

ExportAPIHistoryToolsCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
  updateCallBack: PropTypes.func.isRequired,
  editCallBack: PropTypes.func.isRequired,
  isDashboard: PropTypes.bool,
};

export default ExportAPIHistoryToolsCell;
