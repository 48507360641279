import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons';

import './auth-buttons.scss';

const b = bem('auth-button');

function AuthButton(props) {
  const {className, oversize, additional, ...rest} = props;

  return <AppButton className={cn(b({oversize, additional, link: rest.link}), className)} {...rest} />;
}

AuthButton.propTypes = {
  ...AppButton.propTypes,
  oversize: PropTypes.bool,
  additional: PropTypes.bool,
  link: PropTypes.bool,
};

AuthButton.defaultProps = {
  ...AppButton.defaultProps,
  color: 'primary',
  oversize: false,
  additional: false,
  link: false,
};

export default AuthButton;
