import moment from 'moment';

export const dateToTime = (value) => {
  return value ? moment(value).format('HH:mm') : '';
};

const getHoursSafely = (value) => {
  if (value > 23) {
    return 23;
  }

  if (value < 0) {
    return 0;
  }

  return value;
};

export const calculateTimeConstraints = (from, to, maxDiff) => {
  const fromHoursValue = moment(from).get('hours');
  const toHoursValue = moment(to).get('hours');

  const fromMin = maxDiff ? getHoursSafely(toHoursValue - maxDiff) : null;
  const fromMax = toHoursValue;

  const toMin = fromHoursValue;
  const toMax = maxDiff ? getHoursSafely(fromHoursValue + maxDiff) : null;

  const timeConstraints = {
    from: {
      hours: {
        min: fromMin,
        max: fromMax,
      },
      ...(fromHoursValue === toHoursValue && {minutes: {max: moment(to).get('minutes')}}),
    },
    to: {
      hours: {
        min: toMin,
        max: toMax,
      },
      ...(fromHoursValue === toHoursValue && {minutes: {min: moment(from).get('minutes')}}),
    },
  };

  return (type) => timeConstraints[type];
};
