import React, {useMemo} from 'react';

import bem from 'client/services/bem';
import {useQueryParams} from 'client/services/hooks';

import CatalogTemplateToolbar, {
  SORT_BY_VALUES,
} from 'client/components/catalog-templates/components/catalog-template-toolbar';
import CatalogTemplatesGrid from 'client/components/catalog-templates/lists/catalog-templates-grid';
import CatalogTemplatesNoResultPanel from 'client/components/catalog-templates/panels/catalog-templates-no-result-panel';
import {Client} from 'client/models/client/types';
import {CATALOG_TEMPLATE_TABS} from 'client/models/templates/constants';
import {ClientTemplate} from 'client/models/templates/types';
import {ObjectValues} from 'client/types/common';

import cssModule from './catalog-templates-tabs-container.module.scss';

type CatalogTemplatesTabsContainerProps = {
  type: ObjectValues<typeof CATALOG_TEMPLATE_TABS>;
  fetchData: () => Promise<any>;
  updateTemplate: (id: number, data: Partial<ClientTemplate>) => void;
  templates: Record<string, ClientTemplate[]>;
  agencyClient: Client | null;
};

const b = bem('catalog-templates-tabs-container', {cssModule});

const CatalogTemplatesTabsContainer: React.FC<CatalogTemplatesTabsContainerProps> = (props) => {
  const {type, fetchData, updateTemplate, templates, agencyClient} = props;

  const [queryParams, setQueryParams] = useQueryParams(null, {sortBy: SORT_BY_VALUES.DATE});

  const data = templates[type];

  const filteredList = useMemo(
    () =>
      data?.filter((template) => template.title.toLowerCase().includes(queryParams.search?.toLowerCase() || '')) || [],
    [data, queryParams.search],
  );

  return (
    <div className={b()}>
      <CatalogTemplateToolbar
        loading={false}
        className={b('toolbar')}
        totalCount={filteredList.length}
        sort={queryParams.sortBy}
        search={queryParams.search}
        onSearch={(value: string) => setQueryParams({search: value})}
        onSortChange={(value: string) => setQueryParams({sortBy: value})}
      />
      {filteredList.length ? (
        <CatalogTemplatesGrid
          list={filteredList}
          fetchData={fetchData}
          updateTemplate={updateTemplate}
          agencyClient={agencyClient}
        />
      ) : (
        <CatalogTemplatesNoResultPanel />
      )}
    </div>
  );
};

export default CatalogTemplatesTabsContainer;
