import React from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle, useMount} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {getAnonymousLead} from 'client/ducks/opt-out/actions';
import {selectClientName, selectLead, selectOptInColumns} from 'client/ducks/opt-out/selectors';

import Spinner from 'client/components/common/spinner';

import {Lead} from 'client/models/lead/types';
import {OptInColumn} from 'client/models/opt-in-column/types';
import {ApiDispatch} from 'client/types';

import PreferencesOptInForm from './preferences-opt-in-form';

import cssModule from './update-preferences.module.scss';

const b = bem('update-preferences', {cssModule});

type UpdatePreferencesProps = {
  leadToken: string;
};

const UpdatePreferences: React.FC<UpdatePreferencesProps> = (props) => {
  const {leadToken} = props;
  const dispatch: ApiDispatch = useDispatch();
  const lang = useLanguage('UPDATE_PREFERENCES');
  const lead: Lead = useSelector(selectLead);
  const clientName: string = useSelector(selectClientName);
  const optInColumns: OptInColumn[] = useSelector(selectOptInColumns);
  const [loading, toggleLoading] = useToggle(false);
  const [isUpdated, toggleIsUpdated] = useToggle(false);
  const notFound = !lead?.id;

  const fetchLead = async () => {
    toggleLoading();
    await dispatch(getAnonymousLead(leadToken));
    toggleLoading();
  };

  useMount(fetchLead);

  if (loading) {
    return <Spinner centered />;
  }

  return (
    <div className={b()}>
      {notFound && (
        <div className={b()}>
          <h2 className={b('not-found')}>{lang.USER_NOT_FOUND}</h2>
        </div>
      )}
      {isUpdated && (
        <div className={b()}>
          <h2 className={b('title')}>{lang.THANK_TEXT}</h2>
        </div>
      )}
      {!notFound && !isUpdated && (
        <>
          <h2 className={b('title')}>{lang.TITLE}</h2>
          <p className={b('description')}>{lang.DESCRIPTION.replace('{COMPANY}', clientName)}</p>
          {lead.id && (
            <PreferencesOptInForm
              leadToken={leadToken}
              optInColumns={optInColumns}
              optIns={lead.opt_ins}
              onUpdate={toggleIsUpdated}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UpdatePreferences;
