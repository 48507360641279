import {Route, RoutePaths} from 'routes/types';

export const preparePaths = <TRoutes extends Route[]>(routes: TRoutes) => {
  return routes.reduce<Partial<RoutePaths<TRoutes>>>(
    (acc, route) => ({
      ...acc,
      [route.name]: route.path,
    }),
    {},
  ) as RoutePaths<TRoutes>;
};
