import React, {useCallback, useState, useEffect} from 'react';

import isEmpty from 'lodash/isEmpty';
import {useSelector, useDispatch} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {selectAvailableDomains} from 'client/ducks/available-domains/selectors';
import {createClientsDomain, updateClientsDomain} from 'client/ducks/client-domains/actions';
import {selectClientDomains} from 'client/ducks/client-domains/selectors';

import AppButton from 'client/common/buttons';
import {SelectField, TextField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import validate from 'client/components/diy-operation/modals/diy-op-publishing-url-modal/validate';
import {Operation} from 'client/models/operations/types';
import {ApiDispatch} from 'client/types';

import {getUrlData, getavailableDomainsOptions} from './helper';

import cssModule from './diy-op-publishing-url-modal.module.scss';

const b = bem('diy-op-publishing-url-modal', {cssModule});

const DiyOpPublishingUrlModalFormName = 'DiyOpPublishingUrlModalFormName';

export type DiyOpPublishingUrlModalForm = {
  path_name?: string;
  domain_name?: string | number;
};

type DiyOpPublishingUrlModalProps = {
  onClose: () => void;
  operation: Operation;
  fetchOperation: () => void;
};

const DiyOpPublishingUrlModal: ReduxFormFC<DiyOpPublishingUrlModalProps, DiyOpPublishingUrlModalForm> = (props) => {
  const {onClose, operation, fetchOperation, handleSubmit} = props;
  const [clientDomainId, setClientDomainId] = useState(null);
  const [loading, setLoading] = useState(false);

  const lang = useLanguage('DIY_OPERATION.MODALS.PUBLISHING_URL_MODAL');
  const langErrors = useLanguage('DIY_OPERATION.ERRORS');

  const interfaceId = operation?.client_interface?.id;

  const availableDomains = useSelector(selectAvailableDomains);
  const clientDomains = useSelector(selectClientDomains);

  const {formValues, change, invalid, initialize} = useReduxForm(DiyOpPublishingUrlModalFormName, {
    validate: (values) => validate(values, availableDomains, {lang: langErrors}),
  });

  const dispatch: ApiDispatch = useDispatch();

  const clientDomainInfo = getUrlData(clientDomains, availableDomains, interfaceId);
  const options = getavailableDomainsOptions(availableDomains);

  const handleSubmitClick = useCallback(async () => {
    const queryParams = {
      client_domain: {
        path_name: formValues?.path_name,
        available_domain_id: formValues?.domain_name,
        interface_id: interfaceId,
      },
    };

    setLoading(true);
    if (clientDomainId) {
      await dispatch(updateClientsDomain(clientDomainId, queryParams));
    } else {
      await dispatch(createClientsDomain(queryParams));
    }
    setLoading(false);
    fetchOperation();
    onClose();
  }, [clientDomainId, dispatch, formValues?.domain_name, formValues?.path_name, interfaceId, fetchOperation, onClose]);

  const handleChangeDomainName = (availableDomainId: number) => {
    if (!isEmpty(clientDomains)) {
      const clientDomain = clientDomains.filter(
        (el: {available_domain_id: number}) => el.available_domain_id === availableDomainId,
      )[0];

      if (clientDomain?.id) {
        setClientDomainId(clientDomain?.id);
        change('path_name', clientDomain?.path_name);
      } else {
        change('path_name', '');
      }
    }
  };

  useEffect(() => {
    if (!clientDomainId) {
      setClientDomainId(clientDomainInfo?.clientDomainId);
    }
    initialize({
      domain_name: clientDomainInfo?.availableDomainId,
      path_name: clientDomainInfo?.pathName,
    });
  }, [
    availableDomains,
    clientDomainId,
    clientDomainInfo?.availableDomainId,
    clientDomainInfo?.clientDomainId,
    clientDomainInfo?.pathName,
    initialize,
  ]);

  return (
    <Modal onClose={onClose} show={true} title={lang.TITLE} dialogClassName={b()} bodyClassName={b('body')}>
      <form onSubmit={handleSubmit(handleSubmitClick)}>
        <SelectField
          onChange={handleChangeDomainName}
          label={lang.DOMAIN_NAME_FIELD}
          name="domain_name"
          simpleValue={true}
          options={options}
          withWrap={true}
          required={true}
          placeholder={lang.DOMAIN_NAME_FIELD_PLACEHOLDER}
        />
        <TextField
          name="path_name"
          label={lang.PATH_NAME_FIELD}
          withWrap={true}
          required={true}
          placeholder={lang.PATH_NAME_FIELD_PLACEHOLDER}
        />

        <div className={b('button-container')}>
          <AppButton
            label={lang.CANCEL_MODAL}
            className={b('button')}
            transparent={true}
            onClick={onClose}
            color="devices"
            size="small"
          />
          <AppButton
            onClick={handleSubmitClick}
            label={lang.SAVE_MODAL}
            className={b('button')}
            disabled={invalid}
            loading={loading}
            color="devices"
            size="small"
          />
        </div>
      </form>
    </Modal>
  );
};

const form = reduxForm<DiyOpPublishingUrlModalProps, DiyOpPublishingUrlModalForm>({
  form: DiyOpPublishingUrlModalFormName,
})(DiyOpPublishingUrlModal);

export default form;
