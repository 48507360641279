import React, {useState} from 'react';

import {useDispatch} from 'react-redux';
import {useToggle} from 'react-use';
import {SubmissionError} from 'redux-form';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {createOptinColumn, updateOptinColumn, deleteOptinColumn} from 'client/ducks/leads-parameters/actions';

import AppButton from 'client/common/buttons/app-button';
import NumberField from 'client/common/fields/number-field';
import SelectField from 'client/common/fields/select-field';
import TextField from 'client/common/fields/text-field';
import TextAreaField from 'client/common/fields/textarea-field';
import {useToast} from 'client/common/hooks/useToast';
import Modal from 'client/common/modals/modal';
import ConfirmationPopover from 'client/common/popovers/confirmation-popover';

import {OPT_IN_COLUMN_TYPES} from 'client/models/opt-in-column/constants';
import {OptInColumn} from 'client/models/opt-in-column/types';
import {ApiDispatch} from 'client/types';

import getInitialValues from './getInitialValues';
import LeadOptinTexts from './lead-optin-texts';
import mapFormValues from './mapFormValues';
import {LeadOptinValues} from './types';
import validateValues from './validateValues';

import cssModule from './lead-optin-modal.module.scss';

const b = bem('lead-optin-modal', {cssModule});

export const leadOptinFormName = 'LeadOptinForm';

const LEVEL_OPTIONS = Array.from({length: 10}, (v, index) => ({label: index + 1, value: index + 1}));

type LeadOptinModalProps = {
  onClose: () => void;
  onSave: () => Promise<any>;
  optinColumn?: OptInColumn;
  clientId?: number;
};

const LeadOptinModal: ReduxFormFC<LeadOptinModalProps, LeadOptinValues> = (props) => {
  const {onClose, optinColumn, handleSubmit, clientId, onSave, anyTouched} = props;
  const dispatch: ApiDispatch = useDispatch();
  const lang = useLanguage('LEADS_PARAMETERS.LEAD_OPTIN_MODAL');
  const langCommon = useLanguage('COMMON');
  const [saving, toggleSaving] = useToggle(false);
  const {appendToastNotification} = useToast();
  const [shouldDisableSave, setShouldDisableSave] = useState(false);

  const isEdit = !!optinColumn;

  const {invalid, change, formValues} = useReduxForm(leadOptinFormName, {
    initialValues: getInitialValues(optinColumn),
    validate: (values) => validateValues(values, lang.ERRORS),
  });

  const handleSave = handleSubmit(async (values: LeadOptinValues) => {
    toggleSaving();
    const data = mapFormValues(values, clientId || optinColumn?.client_id);
    const response = isEdit
      ? await dispatch<{response: any}>(updateOptinColumn(optinColumn.id, data))
      : await dispatch<{response: any}>(createOptinColumn(data));
    if (response.error) {
      toggleSaving();
      if (response.payload?.response?.errors?.code?.[0] === 'taken') {
        throw new SubmissionError({code: lang.ERRORS.CODE_TAKEN.toString()});
      } else {
        appendToastNotification({type: 'error', title: langCommon.ERROR});
      }
    } else {
      await onSave();
      onClose();
    }
  });

  const handleDelete = async () => {
    await dispatch(deleteOptinColumn(optinColumn?.id));
    onSave();
    onClose();
  };

  return (
    <Modal
      className={b()}
      title={isEdit ? lang.EDIT_OPTIN : lang.CREATE_OPTIN}
      titleButton={
        <div className={b('buttons')}>
          <AppButton label={lang.CANCEL} transparent onClick={onClose} />
          {isEdit && (
            <ConfirmationPopover
              title={lang.DELETE_THIS_OPTIN}
              okButtonProps={{
                label: lang.YES,
                onClick: handleDelete,
              }}
              cancelButtonProps={{label: lang.NO}}
            >
              <AppButton label={lang.DELETE} color="error" transparent />
            </ConfirmationPopover>
          )}
          <AppButton
            label={isEdit ? lang.SAVE : lang.CREATE}
            onClick={() => {
              handleSave();
              if (!shouldDisableSave) {
                setShouldDisableSave(true);
              }
            }}
            loading={saving}
            disabled={anyTouched && invalid && shouldDisableSave}
          />
        </div>
      }
      onClose={onClose}
      isCloseHidden
    >
      <form>
        <div className={b('row')}>
          <SelectField
            className={b('level-select')}
            name="level"
            label={lang.LEVEL}
            options={LEVEL_OPTIONS}
            simpleValue
          />
          <TextField className={b('code-input')} name="code" label={lang.CODE} />
        </div>
        <div className={b('row')}>
          <SelectField
            className={b('type-select')}
            name="opt_in_type"
            label={lang.OPTIN_TYPE}
            options={[
              {value: OPT_IN_COLUMN_TYPES.STRUCTURAL, label: lang.STRUCTURAL},
              {value: OPT_IN_COLUMN_TYPES.PUNCTUAL, label: lang.PUNCTUAL},
            ]}
            simpleValue
          />
          <NumberField
            name="mandatory_order"
            label={lang.ORDER}
            type="text"
            format={(value: string) => {
              if (value) {
                if (value.startsWith('0')) {
                  return value.slice(1);
                }
                return value.replace(/\D/g, '');
              }
            }}
          />
        </div>
        <div className={b('row')}>
          <TextField name="name" label={lang.TITLE} placeholder={lang.TITLE_PLACEHOLDER} />
        </div>
        <div className={b('row')}>
          <LeadOptinTexts formValues={formValues} change={change} />
        </div>
        <div className={b('row')}>
          <TextAreaField className={b('comment-input')} height={115} name="comment" label={lang.COMMENT} />
        </div>
      </form>
    </Modal>
  );
};

export default reduxForm<LeadOptinModalProps, LeadOptinValues>({
  form: leadOptinFormName,
})(LeadOptinModal);
