import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {TranslationJsx} from 'client/models/language/types';

import './image-container.scss';

const b = bem('image-container');

const ImageContainer = (props) => {
  const {value, alt, label, width, height, className, classNamePreview, keepRatio, imageStyle, testId} = props;
  return (
    <div className={cn(b(), className)} data-testid={testId}>
      {label && <span className={b('label')}>{label}</span>}
      <figure className={classNamePreview && cn(b('preview'), classNamePreview)} style={{width, height}}>
        {value && <img alt={alt} src={value} className={b('image', {'keep-ratio': keepRatio})} style={imageStyle} />}
      </figure>
    </div>
  );
};

ImageContainer.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  classNamePreview: PropTypes.string,
  imageStyle: PropTypes.object,
  alt: TranslationJsx,
  label: TranslationJsx,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  keepRatio: PropTypes.bool,
  testId: PropTypes.string,
};

ImageContainer.defaultProps = {
  value: '',
  className: '',
  classNamePreview: '',
  alt: 'Image preview',
  label: '',
  width: 170,
  height: 100,
  keepRatio: false,
  imageStyle: {},
  testId: 'image-container',
};

export default ImageContainer;
