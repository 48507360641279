import {callApi} from 'client/services/call-api';
import {getEmail, getToken} from 'client/services/cookie-data-source';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function updateUser(id, body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.USERS}/${id}`,
      method: 'PUT',
      types: [types.UPDATE_USER_REQUEST, types.UPDATE_USER_SUCCESS, types.UPDATE_USER_ERROR],
      body,
    });
}

export function updateUserProfile(id, body = {}) {
  return () =>
    fetch(`${API_PATH}${API_METHODS.USERS}/${id}`, {
      method: 'PUT',
      headers: {
        'X-User-Email': getEmail(),
        'X-User-Token': getToken(),
      },
      body,
    });
}
