import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';

import './profile-avatar.scss';

const ProfileAvatar = (props) => {
  const {src, onEditClick, isWoman} = props;

  return (
    <div className="profile-avatar">
      {src ? (
        <div
          className="profile-avatar__img"
          style={{
            backgroundImage: `url(${src})`,
          }}
        />
      ) : (
        <div className={cn('profile-avatar__placeholder')}>
          <Icon name={isWoman ? 'gender-woman' : 'gender-man'} />
        </div>
      )}
      <button className="profile-avatar__edit-btn" onClick={onEditClick}>
        <Icon name="camera-2" />
      </button>
    </div>
  );
};

ProfileAvatar.propTypes = {
  src: PropTypes.string,
  onEditClick: PropTypes.func.isRequired,
  isWoman: PropTypes.bool.isRequired,
};

ProfileAvatar.defaultProps = {
  src: null,
};

export default ProfileAvatar;
