import React from 'react';

import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

function TemplateColumn(props) {
  const {emailTemplate, idText} = props;

  return (
    <div>
      <div className="break-word" key="0">
        {emailTemplate.name}
      </div>
      <div className="break-word uppercase" key="1">
        {idText} {emailTemplate.id}
      </div>
    </div>
  );
}

TemplateColumn.propTypes = {
  idText: TranslationJsx.isRequired,
  emailTemplate: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

export default TemplateColumn;
