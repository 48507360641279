import React from 'react';

import bem from 'client/services/bem';

import CalculationConsiderEmptyField from './fields/calculation-consider-empty-field';
import CalculationOperationField from './fields/calculation-operation-field';
import CalculationTypeField from './fields/calculation-type-field';
import CalculationValueField from './fields/calculation-value-field';
import CalculationVariableField from './fields/calculation-variable-field';

import {COMPONENT_TYPES} from '../constants';

import cssModule from './calculation-components.module.scss';

type CalculationComponentsProps = {
  formValues: {
    type1: string;
    type2: string;
    type3: string;
    type4: string;
  };
};

const b = bem('calculation-components', {cssModule});

const isVar = (type: string) => {
  return type === COMPONENT_TYPES.VAR;
};

const CalculationComponents: React.FC<CalculationComponentsProps> = ({formValues}) => {
  return (
    <div className={b('')}>
      <hr />
      <CalculationConsiderEmptyField />
      <div className={b('row')}>
        <span className={b('bracket')}>(</span>
        <CalculationTypeField number={1} className={b('type-field')} />
        {isVar(formValues.type1) ? (
          <CalculationVariableField number={1} className={b('variable-field')} />
        ) : (
          <CalculationValueField number={1} className={b('value-field')} />
        )}
        <CalculationOperationField number={1} className={b('operation-field')} />
        <CalculationTypeField number={2} className={b('type-field')} />
        {isVar(formValues.type2) ? (
          <CalculationVariableField number={2} className={b('variable-field')} />
        ) : (
          <CalculationValueField number={2} className={b('value-field')} />
        )}
        <span className={b('bracket')}>)</span>
      </div>
      <div className={b('row-middle')}>
        <CalculationOperationField number={2} className={b('operation-field')} />
      </div>
      <div className={b('row')}>
        <span className={b('bracket')}>(</span>
        <CalculationTypeField number={3} className={b('type-field')} />
        {isVar(formValues.type3) ? (
          <CalculationVariableField number={3} className={b('variable-field')} />
        ) : (
          <CalculationValueField number={3} className={b('value-field')} />
        )}
        <CalculationOperationField number={3} className={b('operation-field')} />
        <CalculationTypeField number={4} className={b('type-field')} />
        {isVar(formValues.type4) ? (
          <CalculationVariableField number={4} className={b('variable-field')} />
        ) : (
          <CalculationValueField number={4} className={b('value-field')} />
        )}
        <span className={b('bracket')}>)</span>
      </div>
      <hr />
    </div>
  );
};

export default CalculationComponents;
