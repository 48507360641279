import React from 'react';

import cn from 'classnames';
import moment from 'moment/moment';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import LayoutPanel from 'client/common/panels/layout-panel';

import {PresentWinner} from 'client/models/winnings';

import cssModule from './winning-prize-card-history.module.scss';

const b = bem('winning-prize-card-history', {cssModule});

type WinningPrizeCardHistoryProps = {
  isDesktop?: boolean;
  className?: string;
  data: PresentWinner;
};

const WinningPrizeCardHistory: React.FC<WinningPrizeCardHistoryProps> = ({isDesktop, className, data}) => {
  const lang = useLanguage('WINNINGS.CARDS.PRIZE_CARD');

  const history = data.history;
  const distributed = history.distribution?.distributed;
  const participationHistory = history.participation;
  const addressSource = participationHistory?.place || participationHistory?.store;
  const placeAddress = [addressSource?.city_zip, addressSource?.city_name].filter(Boolean).join(' ');
  const distStoreAddress = [
    history.distribution.distribution_store?.city_zip,
    history.distribution.distribution_store?.city_name,
  ]
    .filter(Boolean)
    .join(' ');

  const emailHistory = history.email || '';
  const emails = {
    sent: emailHistory.original_message?.sent?.at,
    received: emailHistory.last_resent_message?.delivered?.at || emailHistory.original_message?.delivered?.at,
    resent: emailHistory.last_resent_message?.sent?.at,
  };
  const hideEmailHistory = !Object.values(emailHistory).filter(Boolean).length;

  const renderTime = (datetime: string) => (
    <div className={b('datetime', {desktop: isDesktop})}>
      <span className={b('date')}>{moment(datetime).format('DD/MM/YYYY')}</span>
      <span className={b('time')}>{moment(datetime).format('HH:mm')}</span>
    </div>
  );

  return (
    <LayoutPanel bgColor="main-layout-bg" className={cn(b(), className)}>
      <p className={b('block-title', ['history'])}>{lang.HISTORY_LABEL}</p>
      <div className={b('grid-values', ['history'])}>
        {distributed && (
          <>
            <span className={b('history-label')}>{lang.DISTRIBUTION_LABEL}</span>
            <div className={b('history-value')}>
              <div className={b('history-value-row')}>
                <div>
                  <div>{history.distribution?.distribution_store?.name}</div>
                  <div>{distStoreAddress}</div>
                  {history.distribution?.distribution_user && (
                    <p className={b('username')}>{`@${history.distribution?.distribution_user}`}</p>
                  )}
                </div>
                {renderTime(moment(history.distribution?.distributed_at).toISOString())}
              </div>
            </div>
          </>
        )}

        {!hideEmailHistory && (
          <>
            <span className={b('history-label')}>{lang.EMAIL_LABEL}</span>
            <div className={b('history-value')}>
              {emails.resent && (
                <div className={b('history-value-row')}>
                  <div>
                    <span>{lang.EMAIL_RESENT}</span>
                    {emailHistory.last_resent_by && (
                      <p className={b('username')}>{`@${emailHistory.last_resent_by}`}</p>
                    )}
                  </div>
                  {renderTime(emails.resent)}
                </div>
              )}
              {emails.received && (
                <div className={b('history-value-row')}>
                  <div>
                    <span>{lang.EMAIL_RECEIVED}</span>
                  </div>
                  {renderTime(emails.received)}
                </div>
              )}
              {emails.sent && (
                <div className={b('history-value-row')}>
                  <span>{lang.EMAIL_SENT}</span>
                  {renderTime(emails.sent)}
                </div>
              )}
            </div>
          </>
        )}

        <span className={b('history-label')}>{lang.WIN_LABEL}</span>
        <div className={b('history-value')}>
          <div className={b('history-value-row')}>
            <span>{lang.GAME_TYPES[data.history.win.game_type.toUpperCase() as keyof typeof lang.GAME_TYPES]}</span>
            {renderTime(moment(data.history.win.win_time).toISOString())}
          </div>
        </div>

        <span className={b('history-label')}>{lang.PARTICIPATION_LABEL}</span>
        <div className={b('history-value')}>
          <div className={b('history-value-row')}>
            <div>
              <div>{participationHistory.game_name || data.history.participation.device_name}</div>
              {placeAddress && (
                <>
                  {participationHistory.place?.name && <div>{participationHistory.place?.name}</div>}
                  <div>{placeAddress}</div>
                </>
              )}
              <div>{participationHistory.store?.name}</div>
            </div>

            {participationHistory.participated_at && renderTime(participationHistory.participated_at)}
          </div>
        </div>
      </div>
    </LayoutPanel>
  );
};

export default WinningPrizeCardHistory;
