export function mapLogs(logs) {
  return logs.map((log) => {
    const {scenario_execution} = log;

    const scenarioId = scenario_execution && scenario_execution.scenario_id;

    const scenarioName = scenario_execution && scenario_execution.scenario && scenario_execution.scenario.name;

    const operationId =
      scenario_execution &&
      scenario_execution.scenario &&
      scenario_execution.scenario.automation_task &&
      scenario_execution.scenario.automation_task.operation_id;

    const operationName =
      scenario_execution &&
      scenario_execution.scenario &&
      scenario_execution.scenario.automation_task &&
      scenario_execution.scenario.automation_task.operation &&
      scenario_execution.scenario.automation_task.operation.name;

    const client =
      scenario_execution &&
      scenario_execution.scenario &&
      scenario_execution.scenario.automation_task &&
      scenario_execution.scenario.automation_task.operation &&
      scenario_execution.scenario.automation_task.operation.client;

    const clientId = client && client.id;
    const clientType = client && client.type;

    return {
      scenarioId,
      scenarioName,
      operationId,
      operationName,
      clientId,
      clientType,
    };
  });
}
