export default {
  CLEAR_TEMPLATES_STATE: '@@interface_templates/CLEAR_TEMPLATES_STATE',

  GET_TEMPLATES_REQUEST: '@@interface_templates/GET_TEMPLATES_REQUEST',
  GET_TEMPLATES_SUCCESS: '@@interface_templates/GET_TEMPLATES_SUCCESS',
  GET_TEMPLATES_ERROR: '@@interface_templates/GET_TEMPLATES_ERROR',

  DELETE_TEMPLATE_REQUEST: '@@interface_templates/DELETE_TEMPLATE_REQUEST',
  DELETE_TEMPLATE_SUCCESS: '@@interface_templates/DELETE_TEMPLATE_SUCCESS',
  DELETE_TEMPLATE_ERROR: '@@interface_templates/DELETE_TEMPLATE_ERROR',

  GET_TEMPLATE_ITEMS_REQUEST: '@@interface_templates/GET_TEMPLATE_ITEMS_REQUEST',
  GET_TEMPLATE_ITEMS_SUCCESS: '@@interface_templates/GET_TEMPLATE_ITEMS_SUCCESS',
  GET_TEMPLATE_ITEMS_ERROR: '@@interface_templates/GET_TEMPLATE_ITEMS_ERROR',
};
