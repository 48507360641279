import React from 'react';

import bem from 'client/services/bem';

import LayoutPanel from 'client/common/panels/layout-panel';

import PrizeDiyMainFieldset from 'client/components/prizes/fieldsets/prize-diy-main-fieldset';
import PrizeDiySegmentationFieldset from 'client/components/prizes/fieldsets/prize-diy-segmentation-fieldset';
import PrizeDiyQuantitiesTable from 'client/components/prizes/tables/prize-diy-quantities-table';
import {Prize} from 'client/models/prizes/types';

import GeneralLanguageSelector from './general-language-selector';

import PrizeDiyModalRepresentation from '../prize-diy-modal-representation';

import cssModule from './prize-diy-modal-general.module.scss';

const b = bem('prize-diy-modal-general', {cssModule});

type PrizeDiyModalGeneralProps = {
  prize?: Prize | null;
  nextPrize?: Prize;
  prevPrize?: Prize;
  isEdit: boolean;
  disabled: boolean;
  hasWins: boolean;
  onRemoveImage?: (value: number[]) => void;
};

const PrizeDiyModalGeneral: React.FC<PrizeDiyModalGeneralProps> = (props) => {
  const {prize = null, disabled, hasWins, prevPrize, nextPrize, onRemoveImage, isEdit} = props;
  return (
    <div className={b()}>
      <div className={b('column-left')}>
        <GeneralLanguageSelector langUniqueMode={Boolean(prize?.language_unique_mode)} onRemoveImage={onRemoveImage} />
        <PrizeDiyModalRepresentation
          disabled={disabled}
          className={b('representation')}
          onRemoveImage={onRemoveImage}
        />
      </div>
      <div className={b('column-right')}>
        <LayoutPanel bgColor="main-layout-bg" className={b('column-right-content')}>
          <PrizeDiyMainFieldset disabled={disabled} nextPrize={nextPrize} prevPrize={prevPrize} isEdit={isEdit} />
          <PrizeDiyQuantitiesTable prize={prize} />
          <PrizeDiySegmentationFieldset hasWins={hasWins} disabled={disabled} />
        </LayoutPanel>
      </div>
    </div>
  );
};

export default PrizeDiyModalGeneral;
