export default {
  GET_PRIVACY_POLICIES_REQUEST: '@@privacy-policies/GET_PRIVACY_POLICIES_REQUEST',
  GET_PRIVACY_POLICIES_SUCCESS: '@@privacy-policies/GET_PRIVACY_POLICIES_SUCCESS',
  GET_PRIVACY_POLICIES_ERROR: '@@privacy-policies/GET_PRIVACY_POLICIES_ERROR',

  GET_MY_PRIVACY_POLICY_REQUEST: '@@privacy-policies/GET_PRIVACY_POLICIES_REQUEST',
  GET_MY_PRIVACY_POLICY_SUCCESS: '@@privacy-policies/GET_PRIVACY_POLICIES_SUCCESS',
  GET_MY_PRIVACY_POLICY_ERROR: '@@privacy-policies/GET_PRIVACY_POLICIES_ERROR',

  POST_ACCEPT_PRIVACY_POLICY_REQUEST: '@@accept-privacy-policy/ACCEPT_PRIVACY_POLICY_REQUEST',
  POST_ACCEPT_PRIVACY_POLICY_SUCCESS: '@@accept-privacy-policy/ACCEPT_PRIVACY_POLICY_SUCCESS',
  POST_ACCEPT_PRIVACY_POLICY_ERROR: '@@accept-privacy-policy/ACCEPT_PRIVACY_POLICY_ERROR',
};
