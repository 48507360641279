import types from './types';

const initialState = {
  type: types.NO_DATA,
  payload: [],
};

export default function (_state = initialState, action) {
  let state = {..._state};

  switch (action.type) {
    case types.NO_DATA:
      state.type = types.NO_DATA;
      state.payload = initialState.payload;
      return state;

    case types.LOADED:
      state.type = types.LOADED;
      state.payload = action.payload.sms_senders;
      return state;

    default:
      return state;
  }
}
