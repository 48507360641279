import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {set} from 'client/common/meta/meta.actions';

import EmailTemplateEditorAdmin from 'client/components/email-template-editor-admin';

const EmailTemplateEditorAdminPage: React.FC = () => {
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.ADMIN');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set({title: lang.TITLE}));
  }, [dispatch, lang.TITLE]);

  return <EmailTemplateEditorAdmin />;
};

export default EmailTemplateEditorAdminPage;
