import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {getIsNational} from 'client/services/cookie-data-source';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import ParticipantsExport from '../components/participants/participants-export';

class ParticipantsExportPage extends ReactQueryParams {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const title = this.props.languageState.payload.PARTICIPANTS_LIST.PAGE_TITLE;

    this.props.setMetaAction({title});
  }

  render() {
    const {queryParams: params} = this;
    const {
      isUserLoaded,
      match: {
        params: {clientId, operationId, taskId, exportId},
      },
    } = this.props;

    if (isUserLoaded) {
      return <Redirect to={CLIENT_PAGES.HOME} />;
    }

    return (
      <ParticipantsExport
        urlParams={params}
        clientId={clientId}
        operationId={operationId}
        taskId={taskId}
        exportId={exportId}
        isNational={getIsNational()}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsExportPage);
