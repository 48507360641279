import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import RadioPanelTab from 'client/common/radio-panel/radio-panel-tab';

import PrizeDiyRatioTimeHeader from 'client/components/prizes/fieldsets/prize-diy-ratio-time-fieldset';

import {PrizeDiyRatioTimeCheckboxPanelProps} from './type';

import cssModule from './prize-diy-ratio-time-checkbox-panel.module.scss';

const b = bem('prize-diy-ratio-time-checkbox-panel', {cssModule});

const PrizeDiyRatioTimeCheckboxPanel: React.FC<PrizeDiyRatioTimeCheckboxPanelProps> = (props) => {
  const {value, checked, name, disabled, required = false} = props;

  const lang = useLanguage('PRIZES.PANELS.PRIZE_DIY_RATIO_TIME_CHECKBOX_PANEL');

  return (
    <RadioPanelTab
      className={b()}
      value={value}
      label={lang.LABEL}
      name={name}
      checked={checked}
      header={<PrizeDiyRatioTimeHeader disabled={!checked || disabled} required={required} />}
      disabled={disabled}
    />
  );
};

export default PrizeDiyRatioTimeCheckboxPanel;
