import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';

import {CLIENT_PAGES} from 'client/common/config';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import Icon from 'client/components/common/icon';

import AddNewUserModal from '../../modals/add-new-user-modal';

import cssModule from './admins-data-grid.module.scss';

const b = bem('admins-data-grid', {cssModule});

class AdminsDataGrid extends Component {
  static defaultProps = {
    data: [],
    meta: {
      current_page: 1,
      total_pages: 10,
      total_count: 100,
    },
    perPage: 5,
  };

  static propTypes = {
    lang: PropTypes.object.isRequired,
    onPageChange: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
    perPage: PropTypes.number,
    meta: PropTypes.object,
    data: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  getRoles = (lang) => ({
    admin: lang.ROLES_ADMIN,
    super_admin: lang.ROLES_SUPER_ADMIN,
  });

  getQualifications = (lang) => ({
    account_manager: lang.QUALIFICATION_AM,
    project_manager: lang.QUALIFICATION_PM,
    technical_staff: lang.QUALIFICATION_TS,
  });

  handleShowModalClick = () => {
    this.setState(
      {
        showModal: !this.state.showModal,
      },
      () => {
        if (!this.state.showModal) {
          this.props.updateData();
        }
      },
    );
  };

  nameFormatter = ({value, item}) => {
    return value ? (
      <Link to={`${CLIENT_PAGES.ADMINS_LIST}/${item.id}`} className={b('link')}>
        {value}
      </Link>
    ) : (
      ''
    );
  };

  renderBlocked({value}) {
    return <Icon name="role-user" className={value ? 'theme-color-12' : 'theme-color-3'} />;
  }

  renderRole = ({value}) => this.getRoles(this.props.lang)[value];

  renderQualification = ({value}) => this.getQualifications(this.props.lang)[value];

  render() {
    const {meta, onPageChange, data, perPage} = this.props;

    return (
      <div>
        <AddNewUserModal show={this.state.showModal} onClose={this.handleShowModalClick} />
        <div className={b('toolbar')}>
          <button className="button button--bg-1 pull-right" onClick={this.handleShowModalClick}>
            <Icon name="plus" className="button__icon" />
            <span>{this.props.lang.ADD_NEW_BUTTON}</span>
          </button>

          <PerPageDropdown
            onChange={(pages) => {
              onPageChange({page: 1, perPage: pages});
            }}
            value={String(perPage)}
            simpleValue
          />
        </div>
        <ClientTable
          data={data}
          className={b()}
          columns={[
            {
              path: 'title',
              label: this.props.lang.CIVILITY_TABLE_COLUMN,
              width: '4%',
            },
            {
              path: 'full_name',
              label: this.props.lang.FULLNAME_TABLE_COLUMN,
              render: this.nameFormatter,
              width: 100,
            },
            {path: 'blocked', render: this.renderBlocked, width: '7%'},
            {path: 'email', label: this.props.lang.EMAIL_TABLE_COLUMN, width: '21%'},
            {path: 'phone', label: this.props.lang.PHONE_TABLE_COLUMN, width: '14.5%'},
            {path: 'role', label: this.props.lang.ROLE_TABLE_COLUMN, render: this.renderRole, width: '9.5%'},
            {
              path: 'qualification',
              label: this.props.lang.QUALIFICATION_TABLE_COLUMN,
              render: this.renderQualification,
              width: '13%',
            },
            {
              path: 'subsidiary.name',
              label: this.props.lang.SUBSIDIARY_TABLE_COLUMN,

              width: '13%',
            },
          ]}
        />
        <PaginationBar
          data={data}
          perPage={perPage}
          totalPages={meta.total_pages}
          totalItems={meta.total_count}
          currentPage={meta.current_page}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}

export default connect(({languageState}) => ({
  lang: languageState.payload.ADMINS,
}))(AdminsDataGrid);
