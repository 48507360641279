import types from './types';

const initialState = {
  clientUsers: [],
  regions: [],
  memberships: null,
  availableForAttributionUsers: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_CLIENT_USERS_SUCCESS:
      return {
        ...state,
        clientUsers: action.payload.client_users,
      };
    case types.GET_REGION_SUCCESS:
      return {
        ...state,
        regions: action.payload.regions,
      };
    case types.GET_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        memberships: action.payload.memberships,
      };
    case types.GET_AVAILABLE_FOR_ATTRIBUTION_USERS_SUCCESS:
      return {
        ...state,
        availableForAttributionUsers: action.payload.client_users,
      };
    default:
      return state;
  }
}
