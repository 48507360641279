import React, {Component} from 'react';

import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

import CampaignCard from './campaign-card';
import CampaignCardHeader from './campaign-card-header';

import './campaigns-list.scss';

class SourcesList extends Component {
  static propTypes = {
    sources: PropTypes.arrayOf(PropTypes.object),
    totalValues: PropTypes.shape({
      visits: PropTypes.number,
      participations: PropTypes.number,
    }),
    name: PropTypes.string,
    description: TranslationJsx,
    renderListItem: PropTypes.func,
    periodText: TranslationJsx,
  };

  LANGUAGE = {
    TITLE: 'Title',
  };

  renderCampaignCards = () => {
    const {sources = [], totalValues, name, description, renderListItem, periodText} = this.props;
    return sources.map((source, idx) => {
      return renderListItem ? (
        renderListItem(source)
      ) : (
        <CampaignCard
          key={idx}
          title={
            <CampaignCardHeader
              name={source.name || name}
              start={source.from}
              end={source.to}
              periodText={periodText}
            />
          }
          campaign={source}
          totalValues={totalValues}
          collapsible={true}
          description={source.description || description}
          periodText={periodText}
        />
      );
    });
  };

  render() {
    return <div className="campaigns-list">{this.renderCampaignCards()}</div>;
  }
}

export default SourcesList;
