import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import cssModule from './layout-panel.module.scss';

type LayoutPanelProps = {
  className?: string;
  bgColor?: 'main-layout-bg' | 'white';
};

const b = bem('layout-panel', {cssModule});

const LayoutPanel: React.FC<LayoutPanelProps> = (props) => {
  const {className, children, bgColor = 'white'} = props;

  return <article className={cn(className, b([bgColor]))}>{children}</article>;
};

export default LayoutPanel;
