import React, {Component} from 'react';

import 'chart.js/auto';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import {Line} from 'react-chartjs-2';

import {drawVerticalLineOnHover} from 'client/services/chartjsPlugins';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {defaultChartOptions, defaultDatasetOptions} from './constants';

import './scrollable-line-chart.scss';

class ScrollableLineChart extends Component {
  static propTypes = {
    config: PropTypes.shape({
      labels: PropTypes.array,
      tooltipDates: PropTypes.array,
      minPointWidth: PropTypes.number,
      datasets: PropTypes.array,
    }).isRequired,
    chartOptions: PropTypes.object,
    datasetOptions: PropTypes.object,
    defaultIndex: PropTypes.number,
  };

  static defaultProps = {
    defaultIndex: null,
    chartOptions: defaultChartOptions,
    datasetOptions: defaultDatasetOptions,
  };

  static DEFAULT_MIN_POINT_WIDTH = 70;

  static chartPlugins = [{beforeDraw: drawVerticalLineOnHover}];

  constructor(props) {
    super(props);

    this.rootRef = null;
    this.scrollbarRef = null;
    this.scrollLeft = 0;

    this.state = {
      pointWidth: null,
      pointCount: null,
      chartWidth: null,
      offset: null,
      defaultIndex: null,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.calculateChartParams);

    this.calculateChartParams();
  }

  componentDidUpdate(prevProps) {
    const isInit = !this.state.pointCount && this.rootRef;
    const isConfigChanged = this.props.config !== prevProps.config;

    if (isInit || isConfigChanged) {
      const chartParams = this.calculateChartParams();

      if (this.props.defaultIndex && !this.scrollLeft) {
        this.scrollToDefaultIndex(chartParams);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateChartParams);
  }

  scrollToDefaultIndex = (chartParams) => {
    if (!this.scrollbarRef) {
      return;
    }

    const {defaultIndex} = this.props;
    const {pointCount, pointWidth} = chartParams;

    const rightOffset = Math.floor(pointCount / 4);
    const scrollLeft = (defaultIndex - pointCount + rightOffset) * pointWidth;

    if (scrollLeft > 0) {
      this.scrollbarRef.scrollbar.scrollLeft(scrollLeft);
    }
  };

  getData = () => {
    const {config, datasetOptions} = this.props;
    const {pointCount, offset} = this.state;

    return {
      labels: config.labels.slice(offset, offset + pointCount),
      tooltipDates: config.tooltipDates.slice(offset, offset + pointCount),
      datasets: config.datasets.map((dataset) => ({
        pointRadius: dataset.data.length > 1 ? 0 : 3,
        pointHoverBackgroundColor: dataset.borderColor,
        pointBackgroundColor: dataset.borderColor,
        tooltipDates: config.tooltipDates.slice(offset, offset + pointCount),
        ...datasetOptions,
        ...dataset,
        data: dataset.data.slice(offset, offset + pointCount),
        label: dataset.label?.toString(),
      })),
    };
  };

  getOptions = () => {
    const {
      config: {datasets},
      chartOptions,
    } = this.props;
    const values = datasets.reduce((acc, current) => [...acc, ...current.data], [10]);
    const options = cloneDeep(chartOptions);
    options.cubicInterpolationMode = 'monotone';
    options.scales.yAxes.suggestedMax = Math.max(...values);
    options.scales.yAxes.suggestedMin = 0;

    return options;
  };

  calculateChartParams = ({scrollLeft} = {}) => {
    if (!this.rootRef) {
      return null;
    }

    if (Number.isInteger(scrollLeft)) {
      this.scrollLeft = scrollLeft;
    }

    const {config} = this.props;
    const rootWidth = this.rootRef.offsetWidth;
    const initPointWidth = Math.floor(rootWidth / config.labels.length);
    const minPointWidth = config.minPointWidth || ScrollableLineChart.DEFAULT_MIN_POINT_WIDTH;

    const pointWidth = Math.max(initPointWidth, minPointWidth);
    const pointCount = Math.floor(rootWidth / pointWidth);
    const chartWidth = pointWidth * pointCount;
    const offset = Math.floor(this.scrollLeft / pointWidth);

    const params = {
      pointWidth,
      pointCount,
      chartWidth,
      offset,
    };

    this.setState(params);

    return params;
  };

  render() {
    const {labels} = this.props.config;
    const {chartWidth, pointWidth} = this.state;

    const width = chartWidth + 'px';
    const fullWidth = labels.length * pointWidth + 'px';

    return (
      <div
        className="scrollable-line-chart"
        ref={(element) => {
          this.rootRef = element || null;
        }}
      >
        {this.rootRef && chartWidth && (
          <div className="scrollable-line-chart__content">
            <div className="scrollable-line-chart__chart" style={{width}}>
              <Line
                height={240}
                data={this.getData()}
                options={this.getOptions()}
                plugins={ScrollableLineChart.chartPlugins}
              />
            </div>
            <div className="scrollable-line-chart__scrollbar" style={{width}}>
              <CustomScrollbars
                ref={(element) => {
                  this.scrollbarRef = element;
                }}
                scrollbarProps={{
                  onScrollFrame: this.calculateChartParams,
                }}
              >
                <div className="scrollable-line-chart__scrollbar-inner" style={{width: fullWidth}} />
              </CustomScrollbars>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ScrollableLineChart;
