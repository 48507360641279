import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {formatNumberSpace} from 'client/services/formatters';
import {useLanguage} from 'client/services/hooks';

import './winnings-tab-stats.scss';

const b = bem('winnings-tab-stats');

const WinningsTabStats = (props) => {
  const {percent, number} = props;
  const lang = useLanguage('CLIENT_AUTOTASK.WINNINGS_TAB');

  return (
    <div className={b()}>
      <span className={b('label')}>{lang.NON_WINNERS}:</span>
      <span className={b('percent')}>({percent}%)</span>
      <span className={b('number')}>{formatNumberSpace(number)}</span>
    </div>
  );
};

WinningsTabStats.propTypes = {
  percent: PropTypes.number,
  number: PropTypes.number,
};

WinningsTabStats.defaultProps = {
  percent: 0,
  number: 0,
};

export default WinningsTabStats;
