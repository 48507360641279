export const LEVEL_SCREENSHOT_TYPES = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
  KIOSK: 'kiosk',
  THUMBNAIL: 'thumbnail',
};

export const INTERFACE_TYPES = {
  INTERNAL: 'InternalInterface',
  SURVEYGIZMO: 'SurveyGizmoInterface',
  UNBOUNCE: 'UnbounceInterface',
};
