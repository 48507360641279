import React from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {formatNumberSpace} from 'client/services/formatters';
import {useLanguage, useAppMedia} from 'client/services/hooks';

import {selectCurrentMembershipLevel} from 'client/ducks/user-clients/selectors';

import AppButton from 'client/common/buttons';
import {USER_MEMBERSHIP} from 'client/common/config';
import SearchInput from 'client/common/inputs/search-input';

import InstorePlanningAnimationListFilters from '../instore-planning-animation-list-filters';

import './instore-planning-animation-list-header.scss';

const b = bem('instore-planning-animation-list-header');

const InstorePlanningAnimationListHeader = ({
  recordsFound,
  onSearch,
  onSubmitFilters,
  onClickAdd,
  onClickMap,
  onClickDaysView,
  searchValue,
  defaultFilters,
  getStatusOptions,
  loading,
}) => {
  const lang = useLanguage('INSTORE_TASK.LISTS.INSTORE_PLANNING_ANIMATION_LIST.HEADER');
  const {isTablet} = useAppMedia();
  const membershipLevel = useSelector(selectCurrentMembershipLevel);

  const [showFiltersModal, toggleFiltersModal] = useToggle(false);

  return (
    <div className={b()}>
      {isTablet && membershipLevel === USER_MEMBERSHIP.LOCAL && (
        <AppButton
          label={lang.NEW_ANIMATION}
          iconName="plus-simple"
          className={b('new-btn')}
          onClick={onClickAdd}
          disabled={loading}
        />
      )}
      <div className={b('search-field-wrap')}>
        <SearchInput
          placeholder={lang.SEARCH_PLACEHOLDER}
          searchDefault={searchValue}
          onSearch={(search) => onSearch({search}, {replace: true})}
          disabled={loading}
        />
      </div>
      <AppButton
        className={b('filter-btn')}
        label={isTablet ? null : lang.FILTER}
        iconName="filter"
        disabled={isTablet || loading}
        onClick={toggleFiltersModal}
      />
      {!isTablet && (
        <span className={b('records-found')}>
          {formatNumberSpace(recordsFound) || 0} {lang.RECORDS_FOUND}
        </span>
      )}
      {!isTablet && (
        <div className={b('action-buttons')}>
          {onClickDaysView && (
            <AppButton label={lang.DAYS_VIEW} color="light-clients" onClick={onClickDaysView} disabled={true} />
          )}
          {onClickMap && (
            <AppButton
              label={lang.MAP}
              iconName="map-line"
              color="light-clients"
              onClick={onClickMap}
              disabled={true}
            />
          )}
          {onClickAdd && (
            <AppButton
              label={lang.ADD}
              iconName="plus-simple"
              className={b('add-btn')}
              onClick={onClickAdd}
              disabled={loading}
            />
          )}
        </div>
      )}

      {showFiltersModal && (
        <InstorePlanningAnimationListFilters
          onClose={toggleFiltersModal}
          onSubmit={onSubmitFilters}
          defaultFilters={defaultFilters}
          getStatusOptions={getStatusOptions}
        />
      )}
    </div>
  );
};

InstorePlanningAnimationListHeader.propTypes = {
  recordsFound: PropTypes.number.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSubmitFilters: PropTypes.func.isRequired,
  onClickAdd: PropTypes.func,
  onClickMap: PropTypes.func,
  onClickDaysView: PropTypes.func,
  searchValue: PropTypes.string,
  loading: PropTypes.bool,
  defaultFilters: InstorePlanningAnimationListFilters.propTypes.defaultFilters,
  getStatusOptions: InstorePlanningAnimationListFilters.propTypes.getStatusOptions,
};

InstorePlanningAnimationListHeader.defaultProps = {
  searchValue: null,
  onClickAdd: null,
  onClickMap: null,
  onClickDaysView: null,
  loading: false,
};

export default InstorePlanningAnimationListHeader;
