import {PrizeBlockConfigItem} from 'client/components/email-template-editor/types';

export const prizeBlockConfig: PrizeBlockConfigItem[] = [
  {
    type: 'text',
    name: 'name',
    style: 'text-align: center; font-size: 20px; fontWeight: 600',
    valuePath: 'external_name_translations.{{lang}}',
    varName: 'predefined_prize_name',
  },
  {
    type: 'image',
    name: 'image',
    style: '',
    valuePath: 'email_image_translations.{{lang}}',
    varName: 'predefined_prize_image',
  },
  {
    type: 'text',
    name: 'description',
    style: 'text-align: center',
    valuePath: 'external_description_translations.{{lang}}',
    varName: 'predefined_prize_description',
  },
  {
    type: 'image',
    name: 'visual',
    style: '',
    valuePath: 'email_mocked_params.visual_token',
    varName: 'predefined_email_visual',
  },
  {
    type: 'text',
    name: 'token',
    style: 'text-align: center',
    valuePath: 'email_mocked_params.string_token',
    varName: 'predefined_email_token',
  },
];
