import React from 'react';

import {components, OptionProps} from 'react-select';

import bem from 'client/services/bem';

import CheckboxInput from 'client/common/inputs/checkbox-input';
import {SelectOptionType} from 'client/common/selects/types';

import cssModule from './option.module.scss';

const b = bem('option', {cssModule});

const Option: React.FC<OptionProps<SelectOptionType>> = ({children, ...props}) => {
  const {isMulti} = props.selectProps;

  if (isMulti && !props.data?.options?.length) {
    const checked = props.getValue().some((option) => option?.value === props.data?.value);
    return (
      <components.Option {...props}>
        <div className={b('multi-container')}>
          <CheckboxInput
            name={props.data?.value?.toString() || 'need-add-value'}
            checked={checked}
            inversionColor
            color="primary"
          />
          {children}
        </div>
      </components.Option>
    );
  }

  return <components.Option {...props}>{children}</components.Option>;
};
export default Option;
