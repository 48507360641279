import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function clearUnbounceInterfacesState() {
  return {
    type: types.CLEAR_UNBOUNCE_INTERFACES_STATE,
  };
}

export function getNewUnbounceInterfaceAction(body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.UNBOUNCE_INTERFACES_NEW}`,
      method: 'POST',
      types: [
        types.GET_NEW_UNBOUNCE_INTERFACE_REQUEST,
        types.GET_NEW_UNBOUNCE_INTERFACE_SUCCESS,
        types.GET_NEW_UNBOUNCE_INTERFACE_ERROR,
      ],
      body,
    });
}

export function createUnbounceInterfaceAction(body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.UNBOUNCE_INTERFACES}`,
      method: 'POST',
      types: [
        types.CREATE_UNBOUNCE_INTERFACE_REQUEST,
        types.CREATE_UNBOUNCE_INTERFACE_SUCCESS,
        types.CREATE_UNBOUNCE_INTERFACE_ERROR,
      ],
      body,
    });
}
