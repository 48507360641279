export default {
  GET_ALL_AGENCIES_REQUEST: '@@agencies/GET_ALL_AGENCIES_REQUEST',
  GET_ALL_AGENCIES_SUCCESS: '@@agencies/GET_ALL_AGENCIES_SUCCESS',
  GET_ALL_AGENCIES_ERROR: '@@agencies/GET_ALL_AGENCIES_ERROR',

  GET_AGENCY_CLIENTS_REQUEST: '@@agencies/GET_AGENCY_CLIENTS_REQUEST',
  GET_AGENCY_CLIENTS_SUCCESS: '@@agencies/GET_AGENCY_CLIENTS_SUCCESS',
  GET_AGENCY_CLIENTS_ERROR: '@@agencies/GET_AGENCY_CLIENTS_ERROR',

  GET_ALL_AGENCY_CLIENTS_REQUEST: '@@agencies/GET_ALL_AGENCY_CLIENTS_REQUEST',
  GET_ALL_AGENCY_CLIENTS_SUCCESS: '@@agencies/GET_ALL_AGENCY_CLIENTS_SUCCESS',
  GET_ALL_AGENCY_CLIENTS_ERROR: '@@agencies/GET_ALL_AGENCY_CLIENTS_ERROR',

  GET_AGENCY_CLIENT_REQUEST: '@@agencies/GET_AGENCY_CLIENT_REQUEST',
  GET_AGENCY_CLIENT_SUCCESS: '@@agencies/GET_AGENCY_CLIENT_SUCCESS',
  GET_AGENCY_CLIENT_ERROR: '@@agencies/GET_AGENCY_CLIENT_ERROR',

  DELETE_AGENCY_CLIENT_REQUEST: '@@agencies/DELETE_AGENCY_CLIENT_REQUEST',
  DELETE_AGENCY_CLIENT_SUCCESS: '@@agencies/DELETE_AGENCY_CLIENT_SUCCESS',
  DELETE_AGENCY_CLIENT_ERROR: '@@agencies/DELETE_AGENCY_CLIENT_ERROR',

  UPDATE_AGENCY_CLIENT_REQUEST: '@@agencies/UPDATE_AGENCY_CLIENT_REQUEST',
  UPDATE_AGENCY_CLIENT_SUCCESS: '@@agencies/UPDATE_AGENCY_CLIENT_SUCCESS',
  UPDATE_AGENCY_CLIENT_ERROR: '@@agencies/UPDATE_AGENCY_CLIENT_ERROR',
};
