import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {set} from 'client/common/meta/meta.actions';

import AccessDenied from 'client/components/access-denied';

// TODO: waiting for design for AccessDeniedPage
const AccessDeniedPage = () => {
  const lang = useLanguage('ACCESS_DENIED');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set({title: lang.TITLE}));
  }, [dispatch, lang.TITLE]);

  return <AccessDenied title={lang.TITLE} />;
};

export default AccessDeniedPage;
