export const OPERATION_STATUSES = {
  ACTIVE: 'active',
  FINISHED: 'finished',
} as const;

export const TASK_TYPES = {
  SMS: 'SmsTask',
  EMAIL: 'EmailTask',
  POST: 'PostTask',
  MESSAGE: 'MessageTask',
  AUTOMATION: 'AutomationTask',
  INSTORE: 'InstoreTask',
} as const;
