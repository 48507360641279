import React, {PureComponent} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';

export default class TestDrawStep extends PureComponent {
  static propTypes = {
    formValues: PropTypes.object,
    step: PropTypes.object,
    languageState: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState;
  }

  getFormValue = (values, id) => {
    const targetKey = Object.keys(values).filter((key) => Number(key.slice(1)) === id)[0];
    return values[targetKey] || '';
  };

  renderMessage = (message) => {
    return (
      <div className="test-step__form-block">
        <div className="fake-input fake-input--disabled">
          <div className="fake-input__label">{this.LANGUAGE.MESSAGE_TEXT}</div>
          <div className="fake-input__textarea">{message}</div>
        </div>
      </div>
    );
  };

  render() {
    const {formValues, step} = this.props;
    const {comment, implementation} = step;
    const {message, message_sender = {}, recipient_variable = {}} = implementation;
    const senderNumber = get(message_sender, 'value', '');
    const recipientId = get(recipient_variable, 'id', '');
    const recipientNumber = this.getFormValue(formValues, recipientId);

    return (
      <div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{this.LANGUAGE.SENDERS_PHONE_TEXT}</div>
            <div className="fake-input__field">{senderNumber}</div>
          </div>
        </div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{this.LANGUAGE.RECEPIENTS_PHONE_TEXT}</div>
            <div className="fake-input__field">{recipientNumber}</div>
          </div>
        </div>
        {this.renderMessage(message, formValues)}
        {comment && comment.length > 0 && (
          <div className="test-step__form-block">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{this.LANGUAGE.COMMENT_TEXT}</div>
              <div className="fake-input__textarea">{comment}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
