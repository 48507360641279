import React from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormSection, getFormSyncErrors, getFormValues, reduxForm} from 'redux-form';

import bem from 'client/services/bem';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {createPrize, updatePrize} from 'client/ducks/games/actions';

import {Field} from 'client/components/common/fields';

import {getRadioInstantWinType, getScheduleModeOptions, getTimeUnitOptions, getWeekdays} from '../helpers';
import {validate} from '../validate';

import './prize-form-instant-win.scss';

const b = bem('prize-form-instant-win');

class PrizeFormInstantWin extends React.Component {
  static formName = 'PrizeForm';

  onChangeInType = (currentType) => () => {
    const {resetSection, isPrizeMap} = this.props;
    if (!isPrizeMap) {
      resetSection(
        ...[
          'time_instant_win_configuration',
          'participation_instant_win_configuration',
          'schedule_instant_win_configuration',
        ].filter((t) => t !== currentType),
      );
    }
  };

  onChangeType = (e) => {
    const currentType = e.target.value;
    const {change} = this.props;

    switch (currentType) {
      case 'time_instant_win':
        change('time_instant_win_configuration.time_unit', 'seconds');
        break;
      case 'schedule_instant_win':
        change('schedule_instant_win_configuration.schedule_type', 'manual');
        break;
    }
  };

  onChangeWeekTimestamps = (indexWeek) => {
    const {change, formValues} = this.props;
    const prevValues = get(formValues, 'schedule_instant_win_configuration.touchedWeeks', []);
    if (!prevValues.includes(prevValues)) {
      prevValues.push(indexWeek);
    }
    change('schedule_instant_win_configuration.touchedWeeks', prevValues);
  };

  renderCarryForward = (scheduleType) => (
    <div className={cn(b('row'), 'form-field-wrap')}>
      <strong className={b('field')}>{this.props.lang.CARRY_FORWARD}</strong>
      <Field
        onChange={this.onChangeInType('schedule_instant_win_configuration')}
        name={`${scheduleType}.intraday_win`}
        type="checkbox"
        cssModifier={b('field')}
        withWrap={false}
        label={this.props.lang.INTRADAY_CHECKBOX}
      />
      <Field
        onChange={this.onChangeInType('schedule_instant_win_configuration')}
        name={`${scheduleType}.next_day_win`}
        type="checkbox"
        cssModifier={b('field')}
        withWrap={false}
        label={this.props.lang.NEXT_DAY_CHECKBOX}
      />
    </div>
  );

  renderErrors = (key) => {
    const {validate: formValidate, formValues} = this.props;
    const errors = formValidate(formValues, this.props) || {};
    const fieldErrors = get(errors, key, '');

    return fieldErrors && <p className={b('error')}>{fieldErrors}</p>;
  };

  render() {
    const {lang, formValues, goBack, isPrizeMap} = this.props;

    return (
      <div className={b()}>
        {!isPrizeMap && (
          <div className={cn(b('radio'))}>
            {getRadioInstantWinType(lang).map(({label, value}) => (
              <Field
                key={value}
                value={value}
                label={label}
                onChange={this.onChangeType}
                name="instant_win_type"
                type="radio"
              />
            ))}
          </div>
        )}

        <div className={b('content', [formValues.instant_win_type])}>
          {formValues.instant_win_type === 'time_instant_win' && (
            <FormSection name="time_instant_win_configuration">
              <div className={b('row', ['align-start'])}>
                <span className={b('field')}>{lang.ONE_WINNER_EVERY_TEXT}</span>
                <Field
                  cssModifier={b('field', ['units'])}
                  name="units_number"
                  onChange={this.onChangeInType('time_instant_win_configuration')}
                  withWrap={false}
                />
                <Field
                  cssModifier={b('field', ['time-unit'])}
                  name="time_unit"
                  type="select"
                  simpleValue
                  onChange={this.onChangeInType('time_instant_win_configuration')}
                  options={getTimeUnitOptions(lang)}
                  withWrap={false}
                />
              </div>
            </FormSection>
          )}
          {formValues.instant_win_type === 'participation_instant_win' && (
            <FormSection name="participation_instant_win_configuration">
              <div className={b('row', ['align-start'])}>
                <span className={b('field')}>{lang.ONE_WINNER_EVERY_TEXT}</span>
                <Field
                  onChange={this.onChangeInType('participation_instant_win_configuration')}
                  cssModifier={b('field', ['units'])}
                  name="units_number"
                  withWrap={false}
                />
                <span>{lang.PARTICIPATION_TEXT}</span>
              </div>
            </FormSection>
          )}
          {formValues.instant_win_type === 'schedule_instant_win' && (
            <FormSection name="schedule_instant_win_configuration">
              <div>
                {!isPrizeMap && (
                  <div className={cn(b('row'), 'form-field-wrap')}>
                    <Field
                      onChange={this.onChangeInType('schedule_instant_win_configuration')}
                      cssModifier={b('field', ['schedule-mode'])}
                      name="schedule_type"
                      type="select"
                      options={getScheduleModeOptions(lang)}
                      simpleValue
                      withWrap={false}
                    />
                    <span>{lang.MODE_TEXT}</span>
                  </div>
                )}
                {get(formValues, 'schedule_instant_win_configuration.schedule_type') === 'manual' && (
                  <>
                    <FormSection name="manual">
                      <div className={b('row', ['align-start', 'timestamps'])}>
                        <strong className={b('field')}>{lang.INSTANT_WIN_MOMENTS_LABEL}</strong>
                        <Field
                          onChange={this.onChangeInType('schedule_instant_win_configuration')}
                          cssModifier={b('field', ['timestamps'])}
                          name="schedule_win_timestamps"
                          placeholder={lang.INSTANT_WIN_MOMENTS_PLACEHOLDER}
                          type="textarea"
                          withWrap={false}
                        />
                      </div>
                    </FormSection>
                    {this.renderCarryForward('manual')}
                  </>
                )}
                {get(formValues, 'schedule_instant_win_configuration.schedule_type') === 'fix_week' && (
                  <div>
                    <div className={cn(b('row', ['justify-between']), 'form-field-wrap')}>
                      <strong className={b('field')}>{lang.WINNING_MOMENTS}</strong>
                      <span>{lang.MOMENTS_EXAMPLE}</span>
                    </div>

                    <FormSection name="fix_week">
                      <div
                        className={b('table', {
                          error: !!this.renderErrors(
                            'schedule_instant_win_configuration.fix_week.schedule_win_timestamps_table',
                          ),
                          'fix-week': true,
                        })}
                      >
                        <div className={cn(b('row', ['week']))}>
                          {getWeekdays(lang).map(({label, value}, index) => (
                            <div className={b('weekday')} key={value}>
                              <div className={b('weekday-title')}>{label}</div>
                              <div>
                                <Field
                                  onChange={() => {
                                    this.onChangeInType('schedule_instant_win_configuration');
                                    this.onChangeWeekTimestamps(index);
                                  }}
                                  name={`schedule_win_timestamps[${index}].win_time`}
                                  type="textarea"
                                  hideErrorMessage
                                  withWrap={false}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      {this.renderErrors('schedule_instant_win_configuration.fix_week.schedule_win_timestamps_table')}
                    </FormSection>
                    {this.renderCarryForward('fix_week')}
                    <div className={cn(b('row', ['align-start']))}>
                      <strong className={b('field')}>{lang.SPECIAL_OFF_DAYS}</strong>

                      <Field
                        onChange={this.onChangeInType('schedule_instant_win_configuration')}
                        cssModifier={b('field', ['w-100'])}
                        name="dates_off"
                        type="textarea"
                        placeholder={lang.SPECIAL_OFF_DAYS_EXAMPLE}
                        withWrap={false}
                      />
                    </div>
                  </div>
                )}
                {get(formValues, 'schedule_instant_win_configuration.schedule_type') === 'calculated_rule' && (
                  <div>
                    <div className={cn(b('row', ['justify-between', 'calculated']), 'form-field-wrap')}>
                      <strong className={b('field')}>{lang.WIN_TIMES}</strong>
                      <span className={b('description')}>{lang.WIN_TIMES_INSTRUCTION}</span>
                    </div>

                    <FormSection name="calculated_rule">
                      <div
                        className={b('table', {
                          error: !!this.renderErrors(
                            'schedule_instant_win_configuration.calculated_rule.schedule_win_timestamps_table',
                          ),
                          'fix-week': true,
                        })}
                      >
                        <div className={cn(b('row', ['week'], 'form-field-wrap'))}>
                          <div className={b('weekday', ['info'])}>
                            <div className={b('weekday-title')} />
                            <div>N</div>
                            <div>{lang.OPENING_HOURS}</div>
                          </div>
                          {getWeekdays(this.props.lang).map(({label, value}, index) => (
                            <div className={b('weekday')} key={value}>
                              <div className={b('weekday-title')}>{label}</div>
                              <div>
                                <Field
                                  onChange={this.onChangeInType('schedule_instant_win_configuration')}
                                  name={`prizes_number.${value}`}
                                  isPositive
                                  hideErrorMessage
                                  withWrap={false}
                                />
                                <Field
                                  onChange={() => {
                                    this.onChangeInType('schedule_instant_win_configuration');
                                    this.onChangeWeekTimestamps(index);
                                  }}
                                  name={`schedule_win_timestamps[${index}].win_time`}
                                  hideErrorMessage
                                  type="textarea"
                                  withWrap={false}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      {this.renderErrors(
                        'schedule_instant_win_configuration.calculated_rule.schedule_win_timestamps_table',
                      )}
                    </FormSection>

                    {this.renderCarryForward('calculated_rule')}
                    <div className={cn(b('row', ['align-start']))}>
                      <strong className={b('field')}>{lang.SPECIAL_OFF_DAYS}</strong>

                      <Field
                        onChange={this.onChangeInType('schedule_instant_win_configuration')}
                        name="dates_off"
                        cssModifier={b('field', ['w-100'])}
                        type="textarea"
                        placeholder={lang.SPECIAL_OFF_DAYS_EXAMPLE}
                        withWrap={false}
                      />
                    </div>
                  </div>
                )}
              </div>
            </FormSection>
          )}
        </div>

        <button className={cn('button button--bg-4', b('back'))} type="button" onClick={goBack}>
          {lang.BACK_TO_PRIZE}
        </button>
      </div>
    );
  }
}

PrizeFormInstantWin.propTypes = {
  game: PropTypes.shape({
    id: PropTypes.number.isRequired,
    game_type: PropTypes.string.isRequired,
  }).isRequired,
  prize: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  isPrizeMap: PropTypes.bool.isRequired,

  // from state:
  lang: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  resetSection: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
};

PrizeFormInstantWin.defaultProps = {
  formValues: {},
};

const PrizeInstantWinForm = reduxForm({
  form: PrizeFormInstantWin.formName,
  validate,
  destroyOnUnmount: false,
})(PrizeFormInstantWin);

export default connect(
  (state) => {
    return {
      lang: state.languageState.payload.GAMES.GAME_CONFIG_MODAL.PRIZE_FORM,
      formValues: getFormValues(PrizeInstantWinForm.formName)(state),
      formErrors: getFormSyncErrors(PrizeInstantWinForm.formName)(state),
      autotask: selectAutotask(state),
    };
  },
  {
    createPrize,
    updatePrize,
  },
)(PrizeInstantWinForm);
