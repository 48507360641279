import React, {useCallback, useEffect, useMemo} from 'react';

import find from 'lodash/find';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useQueryParams} from 'client/services/hooks';
import useReduxFetch from 'client/services/hooks/use-redux-fetch';

import {getActiveEmailTemplateKind, getEmailTemplateKinds} from 'client/ducks/email-templates/actions';
import {selectActiveTemplateKind, selectEmailTemplateKinds} from 'client/ducks/email-templates/selectors';
import {updateMasterEmailTemplate} from 'client/ducks/operations/actions';

import {EmailTemplateEditor} from 'client/components/email-template-editor';
import {TemplateData} from 'client/components/email-template-editor/types';
import {EmailTemplateKind} from 'client/models/email-templates/types';
import {ApiDispatch} from 'client/types';

import {getNewVariables} from '../diy-operation/dashboards/diy-op-email-templates/helpers';

import './email-template-editor-admin.scss';

const b = bem('email-template-editor-admin');

const EmailTemplateEditorAdmin: React.FC = () => {
  const {templateId} = useParams<{templateId: string}>();
  const [queryParams, setQueryParams] = useQueryParams(null, {}, {parse: {parseNumbers: true}});
  const dispatch: ApiDispatch = useDispatch();
  const [isSaving, toggleIsSaving] = useToggle(false);
  const [loadingKind, toggleLoadingKind] = useToggle(false);
  const currentKind = useSelector(selectActiveTemplateKind);

  const {data: emailTemplateKinds, loading} = useReduxFetch<EmailTemplateKind[]>({
    action: getEmailTemplateKinds,
    actionArgs: {
      emailTemplateId: templateId,
    },
    selector: selectEmailTemplateKinds,
  });

  const currentKindId =
    queryParams.kindId || find(emailTemplateKinds, {id: queryParams.kindId})?.id || emailTemplateKinds?.[0]?.id || null;

  useEffect(() => {
    if (currentKind) {
      currentKind.email_templates = currentKind.email_templates?.filter((emailTemplate) =>
        emailTemplate.template_email_templates?.find(
          (template) => template.template_id === +templateId && template.message_template_kind_id === +currentKindId,
        ),
      );
    }
  }, [currentKind, currentKindId, templateId]);

  const languages = useMemo(
    () => currentKind?.email_templates?.map((i) => i.language_tag) || [],
    [currentKind?.email_templates],
  );

  useEffect(() => {
    if (languages.length && !languages.includes(queryParams.language)) {
      setQueryParams({language: languages[0]}, true);
    }
  }, [languages, queryParams.language, setQueryParams]);

  const currentTemplate = useMemo(() => {
    if (currentKind && queryParams.language) {
      return find(currentKind?.email_templates || [], {language_tag: queryParams.language}) || null;
    }
    return null;
  }, [currentKind, queryParams.language]);

  const fetchActiveTemplateKind = useCallback(async () => {
    toggleLoadingKind();
    await dispatch(getActiveEmailTemplateKind(currentKindId));
    toggleLoadingKind();
  }, [currentKindId, dispatch, toggleLoadingKind]);

  useEffect(() => {
    if (currentKindId && currentKindId !== currentKind?.id) {
      fetchActiveTemplateKind();
    }
  }, [currentKindId, currentKind, fetchActiveTemplateKind]);

  const handleSaveTemplate = async (
    data: TemplateData,
    mjml: string,
    addedVariables: string[],
    hasPrize: boolean,
    extraData: Record<string, any>,
  ) => {
    if (!currentTemplate?.id) {
      return;
    }
    toggleIsSaving();
    await dispatch(
      updateMasterEmailTemplate(currentTemplate.id, {
        master_email_template: {
          json_part: data,
          mjml_part: mjml,
          email_template_variables: getNewVariables(addedVariables, currentKind, currentTemplate),
          prize_block: hasPrize,
          ...extraData,
        },
      }),
    );
    await fetchActiveTemplateKind();
    toggleIsSaving();
  };

  return (
    <div className={b()}>
      <EmailTemplateEditor
        fetchData={fetchActiveTemplateKind}
        onSave={handleSaveTemplate}
        loading={loading || loadingKind || isSaving}
        activeTemplate={currentTemplate}
        hideParameters
        hideGallery
        currentKind={currentKind}
        kinds={emailTemplateKinds || []}
        onChangeKind={(kindId: number) => setQueryParams({kindId, language: null}, true)}
        currentLanguage={queryParams.language}
        languages={languages}
        onChangeLanguage={(language: string) => setQueryParams({language}, true)}
      />
    </div>
  );
};

export default EmailTemplateEditorAdmin;
