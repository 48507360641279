import {Reducer} from 'redux';

import {StatisticExport} from 'client/models/statistic-exports/types';
import {PaginationMeta, getDefaultMeta} from 'client/types/common/index';

import types from './types';

type State = {
  exports: {
    items: StatisticExport[];
    meta: PaginationMeta;
  };
  count: number;
};

const initialState: State = {
  exports: {
    items: [],
    meta: getDefaultMeta(),
  },
  count: 0,
};

const reducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EXPORTS_SUCCESS:
      return {
        ...state,
        exports: {
          items: action.payload.statistic_exports,
          meta: action.payload.meta,
        },
      };

    case types.GET_EXPORTS_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload.meta.total_count,
      };

    default:
      return state;
  }
};

export default reducer;
