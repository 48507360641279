import React from 'react';

import placeholder from 'assets/icons/colored/placeholder-no-photo.svg';
import cn from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm from 'client/services/hooks/use-redux-form';

import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

import {ImageField} from 'client/common/fields';

import {COMMON_INPUT} from 'client/components/prizes/constants';
import {PrizeDiyModalFormName} from 'client/components/prizes/modals/prize-diy-modal';

import './prize-diy-win-fieldset.scss';

const b = bem('prize-diy-win-fieldset');

const PrizeDiyWinFieldset = (props) => {
  const {disabled, onRemove} = props;

  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_WIN_FIELDSET');
  const {change, formValues} = useReduxForm(PrizeDiyModalFormName);
  const {language, defaultLanguage} = formValues?.main || {};
  const languages = useSelector(selectPrizeLanguages);
  const formValueLanguage = language === COMMON_INPUT ? defaultLanguage : language;

  const handleChange = (file) => {
    if (language === COMMON_INPUT) {
      const images = languages.reduce((imgs, langKey) => {
        imgs[`win_pop_up_image_${langKey}`] = file;
        return imgs;
      }, {});
      change('win_pop_up', images);
    } else if (language === defaultLanguage) {
      const images = languages.reduce((imgs, langKey) => {
        const currentValue = get(formValues, `win_pop_up.win_pop_up_image_${langKey}`);
        imgs[`win_pop_up_image_${langKey}`] = langKey === defaultLanguage ? file : currentValue || file;
        imgs[`win_pop_up_image_${langKey}_id`] = get(formValues, `win_pop_up.win_pop_up_image_${langKey}_id`) || 0;
        return imgs;
      }, {});
      change('win_pop_up', images);
    } else {
      change(`win_pop_up.win_pop_up_image_${language}`, file);
    }
  };

  return (
    <div className={cn(b())}>
      <ImageField
        disabled={disabled}
        label={lang.IMAGE_TITLE}
        classNames={{
          controls: b('controls'),
        }}
        name={`win_pop_up.win_pop_up_image_${formValueLanguage}`}
        acceptFormats=".png,.jpg,.jpeg"
        onChange={handleChange}
        keepRatio={true}
        placeholderIcon={placeholder}
        onRemove={() => onRemove('win_pop_up.win_pop_up_image')}
        removable={true}
      />
    </div>
  );
};

PrizeDiyWinFieldset.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default PrizeDiyWinFieldset;
