import React from 'react';

import {MessageTask} from 'client/models/message-task/types';

type Data = {
  forTaskType: string[];
  renderMethod: (task: MessageTask) => void;
};

type MessageTaskListPanelProps = {
  taskType: string;
  data: Data[];
  task: MessageTask;
  classNames?: {panel?: string; col?: string; row?: string};
};

const MessageTaskListPanel: React.FC<MessageTaskListPanelProps> = (props) => {
  const {taskType, data, task, classNames} = props;

  return (
    <div className={classNames?.panel}>
      <div className={classNames?.row}>
        {data
          .filter((c) => c.forTaskType.includes(taskType))
          .map((column, index) => {
            return (
              <div className={classNames?.col} key={index}>
                {column.renderMethod(task)}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MessageTaskListPanel;
