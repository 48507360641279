import React, {useMemo} from 'react';

import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import DayPicker, {DateUtils} from 'react-day-picker';

import bem from 'client/services/bem';
import {useCalendarUnitTranslations} from 'client/services/hooks';

import './animation-datepicker.scss';

const b = bem('animation-datepicker');

const rangeToDate = (range) => ({
  from: range.from ? moment.utc(range.from).toDate() : range.from,
  to: range.to ? moment.utc(range.to).toDate() : range.to,
});

const renderDay = (day) => {
  return (
    <div className={b('day-wrapper')}>
      <div className={b('day')}>{day.getDate()}</div>
    </div>
  );
};

const DATE_FORMAT = 'YYYY-MM-DD';

const AnimationDatepicker = (props) => {
  const {className, availableDays, range, onClick, onChangeMonth, disabled, selectedMonth} = props;

  const calendarUnitTranslations = useCalendarUnitTranslations();

  const clickHandler = (date, {available}) => {
    if (available && !disabled) {
      const preparedDate = moment(date).format(DATE_FORMAT) + 'T00:00:00.000Z';

      const nextRange = DateUtils.addDayToRange(moment(preparedDate).toDate(), rangeToDate(range));
      nextRange.from = !nextRange.from ? nextRange.from : moment(nextRange.from).toISOString();
      nextRange.to = !nextRange.to ? nextRange.to : moment(nextRange.to).toISOString();

      onClick(nextRange);
    }
  };

  const changeMonthHandler = (date) => {
    onChangeMonth(moment(date).toISOString());
  };

  const borderDays = useMemo(() => {
    const firstOfWeeks = {};
    const lastOfWeeks = {};
    Object.entries(availableDays || {}).forEach(([day, status]) => {
      const week = moment(day).isoWeek();
      if (status === 'available') {
        firstOfWeeks[week] = firstOfWeeks[week] || day;
        lastOfWeeks[week] = day;
      }
    });
    return {
      firstOfWeeks,
      lastOfWeeks,
    };
  }, [availableDays]);

  const checkModificatorStatus = (status) => (date) => availableDays?.[moment(date).format(DATE_FORMAT)] === status;
  const checkModificatorFirst = (date) =>
    borderDays.firstOfWeeks[moment(date).isoWeek()] === moment(date).format(DATE_FORMAT);
  const checkModificatorLast = (date) =>
    borderDays.lastOfWeeks[moment(date).isoWeek()] === moment(date).format(DATE_FORMAT);

  const checkModificatorSelectedStart = (date) => {
    return moment(range.from).format(DATE_FORMAT) === moment(date).format(DATE_FORMAT);
  };
  const checkModificatorSelectedEnd = (date) =>
    moment(range.to).format(DATE_FORMAT) === moment(date).format(DATE_FORMAT);

  return (
    <div className={classNames(className, b('wrapper'))}>
      <div className={b('header')} />
      <DayPicker
        mode="range"
        className={b()}
        onMonthChange={changeMonthHandler}
        enableOutsideDays
        month={selectedMonth && moment(selectedMonth).toDate()}
        renderDay={renderDay}
        onDayClick={clickHandler}
        selectedDays={range.from || range.to ? [rangeToDate(range).from, rangeToDate(range)] : []}
        disabledDays={[checkModificatorStatus('temporary_unavailable'), checkModificatorStatus('unavailable')]}
        firstDayOfWeek={1}
        modifiers={{
          available: checkModificatorStatus('available'),
          'temporary-unavailable': checkModificatorStatus('temporary_unavailable'),
          unavailable: checkModificatorStatus('unavailable'),
          first: checkModificatorFirst,
          last: checkModificatorLast,
          'selected-start': checkModificatorSelectedStart,
          'selected-end': checkModificatorSelectedEnd,
        }}
        {...calendarUnitTranslations}
      />
    </div>
  );
};

AnimationDatepicker.propTypes = {
  className: PropTypes.string,
  availableDays: PropTypes.object,
  range: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onChangeMonth: PropTypes.func.isRequired,
  selectedMonth: PropTypes.string,
};

AnimationDatepicker.defaultProps = {
  className: '',
  disabled: false,
  availableDays: null,
  selectedMonth: null,
  range: {
    from: null,
    to: null,
  },
};

export default AnimationDatepicker;
