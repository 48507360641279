import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getValueIfExists, getFileSize} from 'client/services/helpers';

import ImgPreview from 'client/components/common/img-preview';

class SocialPostStep extends Component {
  static propTypes = {
    step: PropTypes.object,
    variableValues: PropTypes.object,
    getValuedMessage: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    step: {},
    variableValues: {},
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.RESULT_MODAL;
  }

  render() {
    const {LANGUAGE} = this;
    const {step, variableValues, getValuedMessage} = this.props;
    const {implementation} = step;

    return (
      <div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{LANGUAGE.ACCOUNT_LABEL}</div>
            <div className="fake-input__field">
              {implementation.social_account.name} {getValueIfExists(implementation.social_account, ['client', 'name'])}
            </div>
          </div>
        </div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{LANGUAGE.MESSAGE_LABEL}</div>
            <div className="fake-input__textarea">{getValuedMessage(implementation.message)}</div>
          </div>
        </div>
        <div className="test-step__form-block">
          <div className="test-step__form-title test-step__form-title--view-3">{LANGUAGE.IMAGES_LABEL}</div>
          <div>
            {implementation.social_scenario_step_images.map((image, key) => {
              if (image.type === 'VariableSocialScenarioStepImage') {
                const filename = getValueIfExists(variableValues[image.variable_id], ['url']) || '';
                return (
                  filename && (
                    <ImgPreview
                      key={key}
                      src={filename}
                      className="test-step__form-load-img"
                      title={<span className="break-word">{filename.split('/').reverse()[0]}</span>}
                    />
                  )
                );
              }
              return (
                <ImgPreview
                  key={key}
                  src={getValueIfExists(image, ['file', 'url'])}
                  className="test-step__form-load-img"
                  title={<span className="break-word">{image.file_identifier}</span>}
                  descr={getFileSize(image.filesize)}
                />
              );
            })}
          </div>
        </div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{LANGUAGE.COMMENT_LABEL}</div>
            <div className="fake-input__textarea">{step.comment}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(SocialPostStep);
