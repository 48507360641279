import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {formatDate} from 'client/services/helpers';

import {GENDER_TYPES} from 'client/common/config';

import Icon from 'client/components/common/icon';

import BCToolbar from 'client/components/broadcasting/components/bc-toolbar';
import BCMailingFiltersForm from 'client/components/broadcasting/components/forms/bc-mailing-filters-form';
import BCOptinsFiltersForm from 'client/components/broadcasting/components/forms/bc-optins-filters-form';

import cssModule from './bc-mailing-toolbar.module.scss';

const b = bem('bc-mailing-toolbar', {cssModule});

function BCMailingToolbar(props) {
  const {
    meta: {total_count: totalItems},
    messageTask: {broadcastRecipientsCount, leadsCount},
    queryParams,
    language,
    updatedData: {addedItems, deletedItems},
    handleShowFilters,
    handleShowSettings,
    renderSearchField,
    handleFilterDelete,
    handleFiltersClear,
    handleSave,
    className,
  } = props;

  const filters = queryParams[BCMailingFiltersForm.qsNames.filters] || {};
  const optinsControl = queryParams[BCOptinsFiltersForm.qsNames.optinsControl];
  const {LANGUAGE_FILTERS, LANGUAGE_GENDERS, LANGUAGE_TABLE, LANGUAGE_MAILING_TABLE} = language;
  const filtersMap = BCMailingFiltersForm.filtersMap;
  const updatedDataIsChanged = addedItems.length > 0 || deletedItems.length > 0;
  const optinsCount = optinsControl && optinsControl !== BCOptinsFiltersForm.controlNames.noMatter ? 1 : 0;
  const numFiltersApplied = Object.keys(filters).length + optinsCount;

  return (
    <div className={cn(b(), className)}>
      <BCToolbar
        onShowFilterClick={handleShowFilters}
        className={b('left')}
        searchField={renderSearchField()}
        resultsNum={totalItems}
        textLabels={{
          filtersButton: `${LANGUAGE_TABLE.FILTERS_BUTTON} (${numFiltersApplied})`,
          clearFilters: LANGUAGE_TABLE.CLEAR_FILTER_BUTTON,
          resultsNum: LANGUAGE_TABLE.RESULTS,
          dataSelector1: LANGUAGE_MAILING_TABLE.OUT_OF_TEXT,
          dataSelector2: LANGUAGE_MAILING_TABLE.INSIDE_LIST_TEXT,
        }}
        actionBarChildren={
          <div className="main-text main-text--small">
            <span>{` ${broadcastRecipientsCount} `}</span>
            {LANGUAGE_MAILING_TABLE.OUT_OF_TEXT}
            <span className="underline">{` ${leadsCount} `}</span>
            {LANGUAGE_MAILING_TABLE.INSIDE_LIST_TEXT}
          </div>
        }
        filters={[
          {
            name: filtersMap.email_present.field,
            applied: filters[filtersMap.email_present.field],
            label: LANGUAGE_FILTERS[filtersMap.email_present.localeKey],
          },
          {
            name: filtersMap.phone_present.field,
            applied: filters[filtersMap.phone_present.field],
            label: LANGUAGE_FILTERS[filtersMap.phone_present.localeKey],
          },
          {
            name: filtersMap.visuals_present.field,
            applied: filters[filtersMap.visuals_present.field],
            label: LANGUAGE_FILTERS[filtersMap.visuals_present.localeKey],
          },
          {
            name: filtersMap.participated_at_gteq.field,
            applied: !!filters[filtersMap.participated_at_gteq.field],
            label:
              filters[filtersMap.participated_at_gteq.field] &&
              `${LANGUAGE_FILTERS[filtersMap.participated_at_gteq.localeKey]}:
                ${formatDate(filters[filtersMap.participated_at_gteq.field], true)}`,
          },
          {
            name: filtersMap.participated_at_lteq.field,
            applied: !!filters[filtersMap.participated_at_lteq.field],
            label:
              filters[filtersMap.participated_at_lteq.field] &&
              `${LANGUAGE_FILTERS[filtersMap.participated_at_lteq.localeKey]}:
                ${formatDate(filters[filtersMap.participated_at_lteq.field], true)}`,
          },
          {
            name: filtersMap.gender_eq.field,
            applied: !!filters[filtersMap.gender_eq.field],
            label: `${LANGUAGE_FILTERS[filtersMap.gender_eq.localeKey]}:
                ${LANGUAGE_GENDERS[GENDER_TYPES[filters[filtersMap.gender_eq.field]]]}`,
          },
          {
            name: filtersMap.created_at_gteq.field,
            applied: !!filters[filtersMap.created_at_gteq.field],
            label:
              filters[filtersMap.created_at_gteq.field] &&
              `${LANGUAGE_FILTERS[filtersMap.created_at_gteq.localeKey]}:
                ${formatDate(filters[filtersMap.created_at_gteq.field], true)}`,
          },
          {
            name: filtersMap.created_at_lteq.field,
            applied: !!filters[filtersMap.created_at_lteq.field],
            label:
              filters[filtersMap.created_at_lteq.field] &&
              `${LANGUAGE_FILTERS[filtersMap.created_at_lteq.localeKey]}:
                ${formatDate(filters[filtersMap.created_at_lteq.field], true)}`,
          },
          {
            name: filtersMap.lead_histories_source_id_eq.field,
            applied: !!filters[filtersMap.lead_histories_source_id_eq.field],
            label:
              filters[filtersMap.lead_histories_source_id_eq.field] &&
              `${LANGUAGE_FILTERS[filtersMap.lead_histories_source_id_eq.localeKey]}:
                ${filters[filtersMap.lead_histories_source_id_eq.field].label}`,
          },
          {
            name: BCOptinsFiltersForm.qsNames.opt_ins,
            applied: !!optinsControl && optinsControl !== BCOptinsFiltersForm.controlNames.noMatter,
            label: LANGUAGE_FILTERS.OPT_INS,
          },
        ]}
        onClearFiltersClick={handleFiltersClear}
        onFiltersClick={handleFilterDelete(filters)}
      />
      <div className={b('right')}>
        {updatedDataIsChanged && (
          <button className="button button--bg-14" onClick={handleSave}>
            {LANGUAGE_MAILING_TABLE.SAVE_CHANGES_BUTTON}
          </button>
        )}
        <button className="button button--bg-14" onClick={handleShowSettings}>
          <Icon name="settings" className="button__icon icon-new-settings--size-1" />
          <span>{LANGUAGE_MAILING_TABLE.TABLE_SETTINGS_BUTTON}</span>
        </button>
      </div>
    </div>
  );
}

BCMailingToolbar.propTypes = {
  meta: PropTypes.shape({
    total_count: PropTypes.number,
  }).isRequired,
  messageTask: PropTypes.shape({
    broadcastRecipientsCount: PropTypes.number,
    leadsCount: PropTypes.number,
  }).isRequired,
  updatedData: PropTypes.shape({
    addedItems: PropTypes.array,
    deletedItems: PropTypes.array,
  }).isRequired,
  queryParams: PropTypes.object.isRequired,
  language: PropTypes.shape({
    LANGUAGE_FILTERS: PropTypes.object,
    LANGUAGE_GENDERS: PropTypes.object,
    LANGUAGE_TABLE: PropTypes.object,
    LANGUAGE_MAILING_TABLE: PropTypes.object,
  }).isRequired,
  renderSearchField: PropTypes.func.isRequired,
  handleShowFilters: PropTypes.func.isRequired,
  handleShowSettings: PropTypes.func.isRequired,
  handleFiltersClear: PropTypes.func.isRequired,
  handleFilterDelete: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default BCMailingToolbar;
