export const CHANNEL_TYPES = {
  MINISITE: 'minisite',
  IFRAME: 'iframe',
} as const;

export const INTERACTION_STATUSES = {
  NEW: 1,
  IN_PROGRESS: 2,
  PAUSED: 3,
  FINISHED: 4,
  CLOSED: 5,
} as const;

export const MAP_ICONS_INTERACTION_STATUSES: Record<number, string> = {
  [INTERACTION_STATUSES.CLOSED]: 'status-closed-finished',
  [INTERACTION_STATUSES.FINISHED]: 'status-finished',
  [INTERACTION_STATUSES.PAUSED]: 'status-paused',
  [INTERACTION_STATUSES.IN_PROGRESS]: 'source-in-process',
  [INTERACTION_STATUSES.NEW]: 'status-new',
};
