import React, {useState} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {FileButtonField} from 'client/common/fields';

import TokensReimportPopover from 'client/components/prizes/popovers/tokens-reimport-popover';

import DeviceDiyParametersModal from '../index';

import './device-diy-parameters-modal-prizes.scss';

const b = bem('device-diy-parameters-modal-prizes');

const DeviceDiyParametersModalPrizes = (props) => {
  const {className, disabled, isOperationStarted} = props;
  const lang = useLanguage('CLIENT_DEVICES.DIY_PARAMETERS_MODAL');
  const {formValues} = useReduxForm(DeviceDiyParametersModal.formName);
  const [fileButtonRef, setFileButtonRef] = useState(null);

  if (!formValues?.device_prizes?.length) {
    return null;
  }

  return (
    <div className={classNames(b(), className)}>
      <p className={b('title')}>{lang.TITLE_DEVICE_PRIZES}</p>
      <div className={b('list')}>
        {formValues?.device_prizes.map((item, index) => {
          const usedTokensCount = item.imports?.tokens_used || 0;
          const showWarning = isOperationStarted && usedTokensCount && item.imports?.file_url && !item.imports?.data;
          return (
            <div className={b('prize')} key={item.id || item.prize_id}>
              <div className={b('column', ['first'])}>{item?.prize?.internal_name}</div>
              <div className={b('column', ['second'])}>
                {item?.imports?.data?.name || item?.imports?.file_name || (
                  <span className={b('error')}>{lang.ERRORS.NO_FILE_IMOPRTED}</span>
                )}
              </div>
              <div className={b('column', ['third'])}>
                <TokensReimportPopover
                  usedTokensCount={usedTokensCount}
                  onConfirm={() => fileButtonRef.current.click()}
                  disabled={!showWarning}
                >
                  <FileButtonField
                    name={`device_prizes[${index}].imports.data`}
                    iconName="exports"
                    transparent
                    color="clients"
                    className={b('upload-button')}
                    acceptFormats=".txt"
                    disabled={disabled}
                    onClick={(ref) => {
                      if (showWarning) {
                        setFileButtonRef(ref);
                      } else {
                        ref.current.click();
                      }
                    }}
                  />
                </TokensReimportPopover>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

DeviceDiyParametersModalPrizes.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  isOperationStarted: PropTypes.bool.isRequired,
};

DeviceDiyParametersModalPrizes.defaultProps = {
  className: '',
};

export default DeviceDiyParametersModalPrizes;
