import React, {useCallback, useState} from 'react';

import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useMount, useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {getAnimationPhotos} from 'client/ducks/animations/actions';
import {selectAnimationPhotos} from 'client/ducks/animations/selectors';

import AppButton from 'client/common/buttons/app-button';
import Icon from 'client/common/icon';
import {VALUE_FORMAT} from 'client/common/inputs/selects/week-select-input/constants';
import Modal from 'client/common/modals/modal';

import Spinner from 'client/components/common/spinner';

import AnimationPhotoCard from 'client/components/animations/cards/animation-photo-card';

import AnimationPhotosModalHeader from './animation-photos-modal-header';

import './animation-photos-modal.scss';

const b = bem('animation-photos-modal');

const AnimationPhotosModal = (props) => {
  const {onClose, task, week} = props;
  const dispatch = useDispatch();
  const photos = useSelector(selectAnimationPhotos);
  const [loading, toggleLoading] = useToggle(false);
  const [photoIndex, setPhotoIndex] = useState(null);
  const lang = useLanguage('ANIMATIONS.MODALS.ANIMATION_PHOTOS_MODAL');

  const fetchPhotos = useCallback(async () => {
    const params = {
      q: {
        instore_task_id_eq: task.id,
        animation_week_eq: moment(week, VALUE_FORMAT).isoWeek(),
        s: 'created_at desc',
      },
      include: {
        animation: {place: null},
      },
    };

    toggleLoading();
    await dispatch(getAnimationPhotos(params));
    toggleLoading();
  }, [dispatch, task.id, toggleLoading, week]);

  useMount(() => {
    fetchPhotos();
  });

  const handleKeyDown = (e) => {
    if (photoIndex === null) {
      return;
    }

    if (e.key === 'ArrowLeft' && photoIndex > 0) {
      setPhotoIndex((i) => i - 1);
    }

    if (e.key === 'ArrowRight' && photoIndex < photos.length - 1) {
      setPhotoIndex((i) => i + 1);
    }
  };

  return (
    <Modal
      show={true}
      header={<AnimationPhotosModalHeader photo={photos[photoIndex]} onBack={() => setPhotoIndex(null)} />}
      headerClassName={b('header')}
      onClose={onClose}
      dialogClassName={b()}
      bodyClassName={b('body')}
      closeClassName={b('close')}
      onKeyDown={handleKeyDown}
    >
      {loading ? (
        <Spinner color="primary" centered={true} />
      ) : (
        <div className={b('content')}>
          {photoIndex !== null && (
            <>
              <AppButton
                iconName="arrow-left"
                color="devices"
                className={b('prev')}
                disabled={photoIndex === 0}
                onClick={() => setPhotoIndex((i) => i - 1)}
              />
              <img className={b('image')} src={photos[photoIndex].url} alt="" />
              <AppButton
                iconName="arrow-right"
                color="devices"
                className={b('next')}
                disabled={photoIndex === photos.length - 1}
                onClick={() => setPhotoIndex((i) => i + 1)}
              />
            </>
          )}
          {photoIndex === null &&
            photos.map((photo, index) => (
              <AnimationPhotoCard key={photo.id} photo={photo} onClick={() => setPhotoIndex(index)} />
            ))}
          {photoIndex === null && !photos.length && (
            <div className={b('empty-wrap')}>
              <div className={b('empty')}>
                <p className={b('empty-text')}>{lang.NO_PHOTOS}</p>
                <Icon name="empty-state" />
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

AnimationPhotosModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  week: PropTypes.string.isRequired,
};

export default AnimationPhotosModal;
