import React from 'react';

import PropTypes, {InferProps} from 'prop-types';

import {TranslationElement} from 'client/services/translation-element';

import {Translation, TranslationJsx} from 'client/models/language/types';
import {ReactNode} from 'client/types';

type RenderHeaderCallback = (column: ClientTableColumn) => React.ReactNode;
export type RenderCallback<TRow extends Record<string, any>> = (config: {
  value: any;
  item: TRow;
  data: TRow[];
  index: number;
  column: ClientTableColumn;
}) => React.ReactNode;

export type ClientTableFormatter = (value: any) => React.ReactNode | string | number | TranslationElement;

export type ClientTableColumn<TRow extends Record<string, any> = Record<string, any>> = {
  name?: string;
  label?: Translation;
  key?: string | number;
  renderHeader?: RenderHeaderCallback;
  sortable?: boolean;
  hasLegend?: boolean;
  width?: string | number;
  strictWidth?: string | number;
  path?: string;
  formatter?: ClientTableFormatter;
  render?: RenderCallback<TRow>;
  noWrap?: boolean;
  show?: boolean;
  initialSortOrder?: string;
  zeroPaddings?: boolean;
  ellipsisText?: boolean;

  // TODO: make one props classNames?: {header?: string | ((row: TRow) => string), value, value, cell}
  headerClassName?: string;
  labelClassName?: string;
  valueClassName?: string | ((row: TRow) => string);
  cellClassName?: string | ((row: TRow) => string);
  title?: string;

  subcolumns?: Omit<ClientTableColumn<TRow>, 'subcolumns'>[];
};

export const ClientTableColumnType = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([TranslationJsx, ReactNode]),
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  headerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  valueClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  renderHeader: PropTypes.func,
  sortable: PropTypes.bool,
  hasLegend: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  path: PropTypes.string,
  formatter: PropTypes.func,
  render: PropTypes.func,
  noWrap: PropTypes.bool,
  show: PropTypes.bool,
  initialSortOrder: PropTypes.string,
  zeroPaddings: PropTypes.bool,
  cellClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  align: PropTypes.oneOf(['right', 'center']),
  title: PropTypes.string,
};

export type ClientTablePropsType = InferProps<typeof ClientTableProps>;

export const ClientTableProps = {
  lang: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      ...ClientTableColumnType,
      subcolumns: PropTypes.arrayOf(PropTypes.shape(ClientTableColumnType)),
    }),
  ).isRequired,
  rowKey: PropTypes.string,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  onSortChange: PropTypes.func,
  renderHeaderChildren: PropTypes.any,
  noDataText: TranslationJsx,
  noDataHeight: PropTypes.number,
  loading: PropTypes.bool,
  checkable: PropTypes.bool,
  disableCheck: PropTypes.func,
  checkedRows: PropTypes.arrayOf(PropTypes.object),
  checkedAll: PropTypes.bool,
  onCheck: PropTypes.func,
  onCheckAll: PropTypes.func,
  loadingColor: PropTypes.string,
  renderCheckbox: PropTypes.func,
  showNoData: PropTypes.bool,
  draggable: PropTypes.bool,
  onDragEnd: PropTypes.func,
  getRowTitle: PropTypes.func,
  fakeEmptyValue: PropTypes.bool,
  checkboxProps: PropTypes.object,
  excludeRowsFromDndSorting: PropTypes.arrayOf(PropTypes.number),

  className: PropTypes.string,
  classNames: PropTypes.shape({
    cell: PropTypes.string,
    row: PropTypes.string,
    header: PropTypes.shape({
      label: PropTypes.string,
      th: PropTypes.string,
      header: PropTypes.string,
    }),
    icon: PropTypes.string,
    odd: PropTypes.string,
    even: PropTypes.string,
  }),

  /**
   * @deprecated use classNames.cell
   */
  commonCellClassName: PropTypes.string,

  /**
   * @deprecated use 'classNames.header*'
   */
  commonHeaderClassName: PropTypes.string,

  /**
   * @deprecated use 'classNames.header*'
   */
  commonHeaderClassNames: PropTypes.shape({
    th: PropTypes.string,
    header: PropTypes.string,
    label: PropTypes.string,
  }),

  /**
   * @deprecated use 'classNames.icon'
   */
  iconClassName: PropTypes.string,
  /**
   * @deprecated use 'classNames.odd'
   */
  oddClassName: PropTypes.string,
  /**
   * @deprecated use 'classNames.event'
   */
  evenClassName: PropTypes.string,
};

export const ClientTableDefaultProps = {
  className: '',
  commonCellClassName: '',
  commonHeaderClassName: '',
  commonHeaderClassNames: null,
  sortField: '',
  sortOrder: '',
  iconCssModifier: '',
  onSortChange: null,
  noDataText: null,
  loading: false,
  checkable: false,
  disableCheck: null,
  checkedRows: [],
  onCheck: null,
  iconClassName: '',
  loadingColor: 'primary',
  renderCheckbox: null,
  showNoData: true,
  oddClassName: null,
  evenClassName: null,
  fakeEmptyValue: true,
  checkboxProps: null,
  draggable: false,
  onDragEnd: null,
  getRowTitle: null,
  rowKey: 'id',
  excludeRowsFromDndSorting: [],
};
