import React, {Component} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';

import {TranslationJsx} from 'client/models/language/types';

import './dropdown-any.scss';

class DropdownAny extends Component {
  static defaultProps = {
    defaultOpen: false,
    value: null,
    label: null,
    className: '',
    children: null,
    onToggle: () => {},
    onSelect: () => {},
    closeWhenOutside: true,
    closeOnSelect: false,
    style: {},
    title: '',
    fullActive: false,
    customDropdown: null,
  };

  static propTypes = {
    defaultOpen: PropTypes.bool,
    value: PropTypes.node,
    label: PropTypes.node,
    className: PropTypes.string,
    children: PropTypes.node,
    onToggle: PropTypes.func,
    onSelect: PropTypes.func,
    closeWhenOutside: PropTypes.bool,
    closeOnSelect: PropTypes.bool,
    style: PropTypes.object,
    title: TranslationJsx,
    fullActive: PropTypes.bool,
    customDropdown: PropTypes.instanceOf(Element),
  };

  static DropdownBody({children, scrollbarProps, className, innerClassName}) {
    return (
      <div className={`dropdown-any__dropdown-body ${className}`}>
        {children && (
          <CustomScrollbars
            scrollbarProps={{
              autoHeightMax: 190,
              hideTracksWhenNotNeeded: true,
              ...scrollbarProps,
            }}
          >
            <div className={classNames('dropdown-any__dropdown-body-inner', innerClassName)}>{children}</div>
          </CustomScrollbars>
        )}
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      open: this.props.defaultOpen,
    };
  }

  componentDidMount() {
    if (this.props.closeWhenOutside) {
      document.documentElement.addEventListener('click', this.handleOutsideClick);
    }
  }

  componentWillUnmount() {
    if (this.props.closeWhenOutside) {
      document.documentElement.removeEventListener('click', this.handleOutsideClick);
    }
  }

  handleOutsideClick = (e) => {
    const target = e.target;

    const zone = this.props.customDropdown || this.dropdown;

    if (!zone.contains(target)) {
      this.setState({
        open: false,
      });
    }
  };

  handleToggleClick = (e) => {
    this.setState({
      open: !this.state.open,
    });

    this.props.onToggle(e);
  };

  handleDropdownClick = (e) => {
    e.stopPropagation();

    if (this.props.closeOnSelect) {
      this.setState({
        open: false,
      });
    }

    this.props.onSelect(e);
  };

  render() {
    const {children, value, className, style, label, fullActive, dropdownCssClass, title} = this.props;

    let cssClass = classNames({
      'dropdown-any': true,
      'dropdown-any--full-active': fullActive,
      'dropdown-any--opened': this.state.open,
    });

    return (
      <div
        className={`${cssClass} ${className}`}
        style={style}
        ref={(dropdown) => {
          this.dropdown = dropdown;
          return this.dropdown;
        }}
      >
        {label && <div className="dropdown-any__label">{label}</div>}
        <div className="dropdown-any__inner" title={title}>
          <div className="dropdown-any__field">{value}</div>
          <div className="dropdown-any__btn" onClick={this.handleToggleClick}>
            <Icon name="dropdown" className="dropdown-any__icon" />
          </div>
        </div>
        <div className={`dropdown-any__dropdown ${dropdownCssClass}`} onClick={this.handleDropdownClick}>
          {children}
        </div>
      </div>
    );
  }
}

DropdownAny.DropdownBody.defaultProps = {
  children: '',
  scrollbarProps: {},
  fullActive: false,
  className: '',
  dropdownCssClass: '',
};

DropdownAny.DropdownBody.propTypes = {
  children: PropTypes.any,
  fullActive: PropTypes.bool,
  scrollbarProps: PropTypes.object,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  dropdownCssClass: PropTypes.string,
};

export default DropdownAny;
