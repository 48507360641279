import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

function AddImageModal({languageState, onClose, onConfirm, onCancel, show}) {
  const LANGUAGE = languageState.payload.AUTOTASK.ADD_IMAGE_MODAL;

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={LANGUAGE.TITLE}
      dialogClassName="modal-window--width-1 theme-color-7 set-optin-modal"
    >
      <form>
        <div className="modal-window__form">
          <div className="form-field-wrap">
            <Field
              label={LANGUAGE.IMAGE_LABEL}
              name="name"
              type="select"
              searchable={false}
              component={fieldTemplate}
            />
          </div>
        </div>
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-11 modal-window__footer-btn" type="submit" onClick={onCancel}>
            {LANGUAGE.CANCEL_BUTTON}
          </button>
          <button className="button button--bg-13 modal-window__footer-btn" type="submit" onClick={onConfirm}>
            {LANGUAGE.CREATE_BUTTON}
          </button>
        </div>
      </form>
    </Modal>
  );
}

AddImageModal.propTypes = {
  show: false,
  onConfirm: () => {},
  onClose: () => {},
  onCancel: () => {},
};

AddImageModal.propTypes = {
  show: PropTypes.bool,
  languageState: PropTypes.object.isRequired,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};

const addImageModalForm = reduxForm({
  form: 'AddImageModalForm',
})(AddImageModal);

export default connect(({languageState}) => ({
  languageState,
}))(addImageModalForm);
