import React from 'react';

import bem from 'client/services/bem';

import cssModule from './test-step-nav-item.module.scss';

const b = bem('test-step-nav-item', {cssModule});

type TestStepNavItemProps = {
  type: string;
  name?: string;
  isTitle?: boolean;
  isActive?: boolean;
};

const TestStepNavItem: React.FC<TestStepNavItemProps> = (props) => {
  const {type, name, isTitle, isActive} = props;

  return (
    <div className={b({title: isTitle, active: isActive})}>
      {name && <div className={b('name')}>{name}</div>}
      <div className={b('type')}>{type}</div>
    </div>
  );
};

export default TestStepNavItem;
