import {DEVICE_SEGMENTATION, ONLINE_SEGMENTATION} from 'client/components/lottery/constants';
import {Translation} from 'client/models/language/types';

import {SegmentationParamsValues} from './types';

export default (values: SegmentationParamsValues, lang: Record<string, Translation>) => {
  const errors: SegmentationParamsValues = {};

  if ((values.fullGlobal || values.fullNone) && !values.commonQuantity) {
    errors.commonQuantity = lang.REQUIRED;
    return errors;
  }

  if (values.deviceSegmentation !== DEVICE_SEGMENTATION.NONE && !values.deviceQuantity) {
    errors.deviceQuantity = lang.REQUIRED;
  }

  if (values.onlineSegmentation !== ONLINE_SEGMENTATION.NONE && !values.onlineQuantity) {
    errors.onlineQuantity = lang.REQUIRED;
  }

  return errors;
};
