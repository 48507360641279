import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import MailingListTasks from 'client/components/broadcasting/mailing-list-tasks';

import {set} from '../common/meta/meta.actions';

class MailingListTasksPage extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    setMetaAction: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        clientId: PropTypes.string,
        broadcastId: PropTypes.string,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.BROADCASTING_TASK;
  }

  componentDidMount() {
    const {setMetaAction} = this.props;

    setMetaAction({
      title: this.LANGUAGE.MAILING_LIST_TITLE,
    });
  }

  render() {
    const {match} = this.props;

    return <MailingListTasks clientId={match.params.clientId} broadcastId={match.params.broadcastId} />;
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(MailingListTasksPage);
