import {getOrGenerateColor} from 'client/services/helpers';

import {NO_DATA_LABEL, COLORS} from './constants';

import './online-users-chart.scss';

const sortItems = (a, b) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  const noData = NO_DATA_LABEL.toUpperCase();

  if (nameA === noData) {
    return 1;
  }

  if (nameB === noData) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  if (nameA < nameB) {
    return -1;
  }

  return 0;
};

export const mapData = (data, lang) => {
  if (!data.length) {
    return null;
  }

  return data.sort(sortItems).map(({name, value}, index) => ({
    label: name === NO_DATA_LABEL ? lang.UNKNOWN : name,
    value,
    color: name === NO_DATA_LABEL ? '#DCE1EA' : getOrGenerateColor(COLORS, index),
  }));
};
