import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

export default (prize, state) => {
  const languages = selectPrizeLanguages(state);

  const result = {
    visual_type: prize?.visual_type,
  };

  languages.forEach((lang) => {
    result[`ticket_image_${lang}`] = prize?.ticket_image_translations?.[lang] || null;
    result[`ticket_image_${lang}_id`] = prize?.ticket_image_translations?.[`${lang}_id`] || null;
  });

  return result;
};
