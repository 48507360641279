import React, {useEffect, useState, useCallback} from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useAppMedia, useLanguage} from 'client/services/hooks';

import {useToast} from 'client/common/hooks/useToast';

import {ToastNotification as ToastNotificationType} from 'client/models/toast-notification/types';

import AppButton from '../buttons';
import Icon from '../icon';

import cssModule from './toast-notification.module.scss';

const b = bem('toast-notification', {cssModule});

type classnamesProps = {
  wrapperClassName?: string;
  iconClassName?: string;
  notificationContentClassName?: string;
  buttonClassName?: string;
  titleClassName?: string;
  descriptionClassName?: string;
};

type ToastNotificationProps = ToastNotificationType & {
  className?: string;
  classNames?: classnamesProps;
};

const NOTIFICATION_DELAY = 5000;
const HANDLE_DELAY = 400;

const ToastNotification: React.FC<ToastNotificationProps> = (props) => {
  const {
    hideCloseButton,
    closeButtonLabel,
    id,
    classNames,
    className,
    type,
    title,
    description,
    shouldPersist,
    actions,
  } = props;
  const {
    wrapperClassName,
    iconClassName,
    notificationContentClassName,
    titleClassName,
    descriptionClassName,
    buttonClassName,
  } = classNames || {};

  const [show, setShow] = useState(true);
  const [isHidden, setIsHidden] = useState(false);

  const {isDeleted, removeToastNotification} = useToast();
  const {isTablet} = useAppMedia();

  const lang = useLanguage('COMMON');

  const handleStartTimer = useCallback(() => {
    setShow(true);

    return setTimeout(() => {
      if (!shouldPersist) {
        setIsHidden(true);

        setTimeout(() => {
          setShow(false);
        }, HANDLE_DELAY);
      }
    }, NOTIFICATION_DELAY);
  }, [shouldPersist]);

  useEffect(() => {
    const timerId = handleStartTimer();

    return () => clearTimeout(timerId);
  }, [handleStartTimer]);

  return show ? (
    <div
      className={cn(b({'is-shown': show, 'is-hidden': isHidden, 'is-deleted': isDeleted, tablet: isTablet}), className)}
    >
      <div className={cn(b('wrapper'), wrapperClassName)}>
        <Icon name="info" width={16} className={cn(b('icon', [type]), iconClassName)} />
        <div className={cn(b('notification-content'), notificationContentClassName)}>
          {title && <p className={cn(b('title'), titleClassName)}>{title}</p>}
          {description && <p className={cn(b('description'), descriptionClassName)}>{description}</p>}
        </div>
        {!!actions?.length && (
          <div className={b('actions')}>
            {actions.map((action) => (
              <AppButton
                {...action}
                onClick={() => {
                  action.onClick();
                  removeToastNotification(id);
                }}
                transparent={true}
                color="visits"
                key={action.label?.toString()}
                className={cn(b('button'), action.className)}
              />
            ))}
          </div>
        )}
        {!hideCloseButton && (
          <AppButton
            className={cn(b('button'), buttonClassName)}
            transparent={true}
            label={closeButtonLabel || lang.OK}
            color="visits"
            onClick={() => removeToastNotification(id)}
          />
        )}
      </div>
    </div>
  ) : null;
};

export default ToastNotification;
