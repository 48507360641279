import {RSAA} from 'redux-api-middleware';

import {callApi} from 'client/services/call-api';
import {getEmail, getToken} from 'client/services/cookie-data-source';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getAdminUsersAction() {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.ADMIN_USERS}`,
        method: 'GET',
        types: [types.GET_ADMIN_USERS_REQUEST, types.GET_ADMIN_USERS_SUCCESS, types.GET_ADMIN_USERS_ERROR],
        isAuth: true,
        noCache: true,
      },
    });
  };
}

export function updateAdminUserProfile(id, body = {}) {
  return () =>
    fetch(`${API_PATH}${API_METHODS.ADMIN_USERS}/${id}`, {
      method: 'PUT',
      headers: {
        'X-User-Email': getEmail(),
        'X-User-Token': getToken(),
      },
      body,
    });
}

export function getAdminSubsidiary(id) {
  const queryParams = {
    include: ['subsidiary'],
  };
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        queryParams,
        endpoint: `${API_PATH}${API_METHODS.ADMIN_USERS}/${id}`,
        method: 'GET',
        types: [
          types.GET_ADMIN_USERS_SUBSIDIARY_REQUEST,
          types.GET_ADMIN_USERS_SUBSIDIARY_SUCCESS,
          types.GET_ADMIN_USERS_SUBSIDIARY_ERROR,
        ],
      },
      true,
    );
  };
}

export const getAllAdmins = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.ADMIN_USERS}`,
      method: 'GET',
      types: [types.GET_ALL_ADMINS_REQUEST, types.GET_ALL_ADMINS_SUCCESS, types.GET_ALL_ADMINS_ERROR],
      queryParams,
    });
};
