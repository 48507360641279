import {mapApiLeadExport} from './mappers';

export function selectApiLeadExports(state) {
  return state.leadsExport.apiLeadExports.map(mapApiLeadExport);
}

export function selectLeadsExportReports(state) {
  return state.leadsExport.exportReports;
}

export function selectLeadsExportReport(state) {
  return state.leadsExport.exportReport
    ? {
        ...state.leadsExport.exportReport,
        transfer_id: state.leadsExport.exportReport?.lead_transfer_id,
      }
    : null;
}
