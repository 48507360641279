export default {
  GET_DIAGRAMS_REQUEST: '@@results-graphs/GET_DIAGRAMS_REQUEST',
  GET_DIAGRAMS_SUCCESS: '@@results-graphs/GET_DIAGRAMS_SUCCESS',
  GET_DIAGRAMS_ERROR: '@@results-graphs/GET_DIAGRAMS_ERROR',

  GET_CLIENT_DIAGRAMS_REQUEST: '@@results-graphs/GET_CLIENT_DIAGRAMS_REQUEST',
  GET_CLIENT_DIAGRAMS_SUCCESS: '@@results-graphs/GET_CLIENT_DIAGRAMS_SUCCESS',
  GET_CLIENT_DIAGRAMS_ERROR: '@@results-graphs/GET_CLIENT_DIAGRAMS_ERROR',

  GET_CLIENT_DIAGRAM_REQUEST: '@@results-graphs/GET_CLIENT_DIAGRAM_REQUEST',
  GET_CLIENT_DIAGRAM_SUCCESS: '@@results-graphs/GET_CLIENT_DIAGRAM_SUCCESS',
  GET_CLIENT_DIAGRAM_ERROR: '@@results-graphs/GET_CLIENT_DIAGRAM_ERROR',

  GET_DATA_SOURCES_REQUEST: '@@results-graphs/GET_DATA_SOURCES_REQUEST',
  GET_DATA_SOURCES_SUCCESS: '@@results-graphs/GET_DATA_SOURCES_SUCCESS',
  GET_DATA_SOURCES_ERROR: '@@results-graphs/GET_DATA_SOURCES_ERROR',

  CREATE_DIAGRAM_REQUEST: '@@results-graphs/CREATE_DIAGRAM_REQUEST',
  CREATE_DIAGRAM_SUCCESS: '@@results-graphs/CREATE_DIAGRAM_SUCCESS',
  CREATE_DIAGRAM_ERROR: '@@results-graphs/CREATE_DIAGRAM_ERROR',

  UPDATE_DIAGRAM_REQUEST: '@@results-graphs/UPDATE_DIAGRAM_REQUEST',
  UPDATE_DIAGRAM_SUCCESS: '@@results-graphs/UPDATE_DIAGRAM_SUCCESS',
  UPDATE_DIAGRAM_ERROR: '@@results-graphs/UPDATE_DIAGRAM_ERROR',

  DELETE_DIAGRAM_REQUEST: '@@results-graphs/DELETE_DIAGRAM_REQUEST',
  DELETE_DIAGRAM_SUCCESS: '@@results-graphs/DELETE_DIAGRAM_SUCCESS',
  DELETE_DIAGRAM_ERROR: '@@results-graphs/DELETE_DIAGRAM_ERROR',

  CLEAR_CLIENT_DIAGRAMS: '@@results-graphs/CLEAR_CLIENT_DIAGRAMS',
};
