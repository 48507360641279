import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {fetchSources, deleteSources} from 'client/ducks/sources/actions';
import {selectAllSources} from 'client/ducks/sources/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import TitleBlock from 'client/components/common/title-block';

import SrcAddSourceModal from './components/modals/src-add-source-modal';
import SourcesDataGrid from './components/tables/sources-data-grid';

class Sources extends ReactQueryParams {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    fetchSources: PropTypes.func.isRequired,
    deleteSources: PropTypes.func.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
  };

  static DEFAULT_PER_PAGE = 5;

  static DEFAULT_FIRST_PAGE = 1;

  static DEFAULT_SORT_FIELD = 'name';

  static DEFAULT_SORT_ORDER = 'asc';

  state = {
    showDeleteSource: false,
    showAddSource: false,
    editingSource: null,
    selectedIds: [],
  };

  componentDidMount() {
    const {langBC} = this.props;

    this.props.setBreadcrumbs([
      {
        href: CLIENT_PAGES.VISUALS,
        name: langBC.VISUALS,
      },
      {
        href: CLIENT_PAGES.CLIENTS_LIST,
        name: langBC.CLIENTS,
      },
      {
        href: CLIENT_PAGES.OPERATIONS,
        name: langBC.OPERATIONS,
      },
      {
        name: langBC.PERMANENT_SOURCES,
      },
    ]);

    this.updateMe();
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  handlePageChange = (params) => {
    this.setQueryParams(params);
    this.updateMe();
  };

  handleSortChange = (name, order) => {
    this.setQueryParams({sort: {name, order}});
    this.updateMe();
  };

  handleSelectedRecordsChanged = (selectedIds) => {
    this.setState({selectedIds});
  };

  updateMe = () => {
    const {queryParams: params} = this;
    window.scrollTo(0, 0);
    const sortField = params.sort?.name || Sources.DEFAULT_SORT_FIELD;
    const sortOrder = params.sort?.order || Sources.DEFAULT_SORT_ORDER;
    this.props.fetchSources({
      q: {
        s: `${sortField} ${sortOrder}`,
      },
      page: params.page || Sources.DEFAULT_FIRST_PAGE,
      per_page: params.perPage || Sources.DEFAULT_PER_PAGE,
    });
  };

  handleShowAddSource = (editingSource) => {
    this.setState(({showAddSource}) => ({
      showAddSource: !showAddSource,
      editingSource,
    }));
  };

  handleSourceCreated = () => {
    this.handleShowAddSource();
    this.updateMe();
  };

  handleShowDeleteSource = () => {
    this.setState({
      showDeleteSource: !this.state.showDeleteSource,
    });
  };

  handleDeleteSource = () => {
    if (this.state.selectedIds.length === 0) {
      return;
    }

    this.props
      .deleteSources({
        q: {
          id_in: this.state.selectedIds,
        },
      })
      .then(this.updateMe);

    this.handleShowDeleteSource();
    this.setState({selectedIds: []});
  };

  render() {
    const {showDeleteSource, showAddSource, editingSource} = this.state;

    const {lang, sources, meta} = this.props;

    const deleteLabels = lang.DELETE_SOURCE_MODAL;

    return (
      <div className="theme-color-10">
        <ConfirmationModal
          show={showDeleteSource}
          onClose={this.handleShowDeleteSource}
          onCancel={this.handleShowDeleteSource}
          onConfirm={this.handleDeleteSource}
          title={deleteLabels.TITLE}
          message={deleteLabels.MESSAGE}
          cancelText={deleteLabels.CANCEL_BUTTON}
          confirmText={deleteLabels.CONFIRM_BUTTON}
          className="theme-color-10"
          buttonConfirmClass="button--bg-6"
        />

        {showAddSource && (
          <SrcAddSourceModal
            editingSource={editingSource}
            onClose={() => this.handleShowAddSource({})}
            onConfirm={this.handleSourceCreated}
          />
        )}

        <div className="page__title-block flex-container flex-justify-between">
          <div>
            <TitleBlock theme>
              <TitleBlock.Item>{lang.TITLE}</TitleBlock.Item>
            </TitleBlock>
          </div>
        </div>
        <SourcesDataGrid
          data={sources}
          onShowAddSource={() => this.handleShowAddSource(null)}
          onDeleteSource={this.handleShowDeleteSource}
          onEditSourceClick={this.handleShowAddSource}
          perPage={Number(this.queryParams.perPage) || Sources.DEFAULT_PER_PAGE}
          meta={meta}
          onPageChange={this.handlePageChange}
          onSortChange={this.handleSortChange}
          selectedIds={this.state.selectedIds}
          onSelectedRecordsChanged={this.handleSelectedRecordsChanged}
        />
      </div>
    );
  }
}

export default connect(
  ({languageState, ...state}) => ({
    lang: languageState.payload.SOURCES,
    langBC: languageState.payload.BREADCRUMBS,
    sources: selectAllSources(state),
    meta: state.sources.meta,
  }),
  {
    setBreadcrumbs,
    clearBreadcrumbs,
    fetchSources,
    deleteSources,
  },
)(Sources);
