import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class InstantLotteryStep extends Component {
  static propTypes = {
    step: PropTypes.object,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    step: {},
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.RESULT_MODAL;
  }

  render() {
    const {LANGUAGE} = this;
    const {step} = this.props;
    const {implementation} = step;

    return (
      <div>
        {implementation.lottery_scenario_step_prizes.map((prize, key) => (
          <div key={key}>
            <div className="test-step__form-block">
              <div className="fake-input fake-input--disabled">
                <div className="fake-input__label">
                  {LANGUAGE.PRIZE_LEVEL_LABEL} {prize.level}
                </div>
                <div className="fake-input__field">{prize.name}</div>
              </div>
            </div>
            <div className="test-step__form-block">
              <div className="fake-input fake-input--disabled">
                <div className="fake-input__label">{LANGUAGE.ONE_WINNER_PER_LABEL}</div>
                <div className="fake-input__field">
                  {implementation.kind === LANGUAGE.KIND_LABEL.TIME &&
                    `${prize.condition} ${LANGUAGE.TIME_UNITS[prize.time_unit]}`}
                  {implementation.kind === LANGUAGE.KIND_LABEL.PERSONS &&
                    `${prize.condition} ${LANGUAGE.KIND_LABEL.PERSONS}`}
                </div>
              </div>
            </div>
            {prize.limit && (
              <div className="test-step__form-block">
                <div className="fake-input fake-input--disabled">
                  <div className="fake-input__label">{LANGUAGE.NUMBER_OF_PRIZE}</div>
                  <div className="fake-input__field">{prize.limit}</div>
                </div>
              </div>
            )}
          </div>
        ))}
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{LANGUAGE.COMMENT_LABEL}</div>
            <div className="fake-input__textarea">{step.comment}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(InstantLotteryStep);
