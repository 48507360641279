import {DESTINATION_TYPES} from './constants';

export const showVariable = (formValues) => {
  return formValues.destination_type === DESTINATION_TYPES.VARIABLE;
};

export const showDatabase = (formValues) => {
  return formValues.destination_type === DESTINATION_TYPES.DATABASE;
};

export const isVariableSelected = (formValues) => {
  return showVariable(formValues) && formValues.variable_id;
};

export const isDatabaseSelected = (formValues) => {
  return showDatabase(formValues) && formValues.database;
};

export const isColumnSelected = (formValues) => {
  return showDatabase(formValues) && formValues.column_adapter_id;
};

export const showComponents = (formValues) => {
  return isVariableSelected(formValues) || isColumnSelected(formValues);
};
