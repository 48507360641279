import {ACCESS_LEVEL} from 'client/models/common/constants';
import {AccessLevelType} from 'client/models/common/types';

import {hasOnline, hasVertical, hasHorizontal} from './helpers';
import {DiyOpTemplateMode, DiyOpTemplateValues, TemplateData} from './types';

const getLanguages = (template: TemplateData, isSave: boolean) => {
  const availableLanguages = isSave ? template.languages : template.source_template_languages;

  return availableLanguages.reduce((result: Record<string, boolean>, key: string) => {
    result[key as keyof typeof result] = isSave ? false : template.languages.includes(key);
    return result;
  }, {});
};

const getBroadcasts = (template: TemplateData, isSave: boolean) => {
  const availableBroadcasts = isSave ? template.image_formats : template.source_template_image_formats;
  const map = {online: hasOnline, vertical: hasVertical, horizontal: hasHorizontal};

  return Object.entries(map).reduce((result: Record<string, boolean>, [key, checker]) => {
    if (checker(availableBroadcasts)) {
      result[key] = isSave ? false : checker(template.image_formats);
    }
    return result;
  }, {});
};

const getSharing = (template: TemplateData, params: {mode: DiyOpTemplateMode; accessLevel: AccessLevelType}) => {
  const {mode, accessLevel} = params;

  if (['save', 'share'].includes(mode)) {
    return {region: false, store: false};
  }

  return {
    region:
      ([ACCESS_LEVEL.NATIONAL, ACCESS_LEVEL.CLIENT_ADMIN] as AccessLevelType[]).includes(accessLevel) &&
      template.user_access_levels.includes('region'),
    store: accessLevel !== ACCESS_LEVEL.STORE && template.user_access_levels.includes('store'),
  };
};

const getTitle = (template: TemplateData, mode: DiyOpTemplateMode) => {
  switch (mode) {
    case 'duplicate':
      return `${template.title}_copy`;
    case 'save':
      return '';
    default:
      return template.title;
  }
};

export default (
  template: TemplateData,
  params: {mode: DiyOpTemplateMode; accessLevel: AccessLevelType; locale: string},
): DiyOpTemplateValues => {
  const {mode, accessLevel, locale} = params;

  const isSave = mode === 'save';
  return {
    name: getTitle(template, mode),
    description: template.description_translations[locale] || '',
    icon: template.icon_url,
    languages: getLanguages(template, isSave),
    broadcasts: getBroadcasts(template, isSave),
    sharing: getSharing(template, {mode, accessLevel}),
    clients: [],
  };
};
