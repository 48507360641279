import get from 'lodash/get';
import {Reducer} from 'redux';

import {Game, Prize, PrizeMap} from 'client/models/prizes/types';

import {mapLevels} from './mappers';
import types from './types';

type GameStore = {
  games: Game[];
  prizes: Prize[];
  prizeMaps: PrizeMap[];
  levels: null[]; // TODO: Add level type
};

const initialState: GameStore = {
  games: [],
  prizes: [],
  levels: [],
  prizeMaps: [],
};

const gameReducer: Reducer<GameStore> = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_GAMES_SUCCESS:
      return {
        ...state,
        games: get(action, 'payload.games', initialState.games),
      };

    case types.UPDATE_PRIZE_ORDER:
      return {
        ...state,
        prizes: action.payload,
      };

    case types.UPDATE_PRIZE_MAP_ORDER:
      return {
        ...state,
        prizeMaps: action.payload,
      };

    case types.GET_PRIZES_REQUEST:
      return {
        ...state,
        prizes: [],
      };

    case types.GET_PRIZES_SUCCESS:
      return {
        ...state,
        prizes: get(action, 'payload.prizes', initialState.prizes),
      };

    case types.GET_LEVELS_SUCCESS:
      return {
        ...state,
        levels: mapLevels(get(action, 'payload.interface_levels', initialState.levels)),
      };

    case types.GET_PRIZE_MAPS_SUCCESS:
      return {
        ...state,
        prizeMaps: get(action, 'payload.prize_maps', initialState.prizeMaps),
      };

    default:
      return state;
  }
};

export default gameReducer;
