export const DEFAULT_SORT_FIELD = 'id';
export const DEFAULT_SORT_ORDER = 'asc';
export const DEFAULT_FIRST_PAGE = 1;
export const DEFAULT_PER_PAGE = 5;
export const DEFAULT_CUSTOM_COLUMNS_PER_PAGE = 100;
export const DEFAULT_SEARCH_OPTION = 'lead_first_name_cont';

export const MODALS_MAP = {
  FILTERS: 'FILTERS',
  TABLE_SETTINGS: 'TABLE_SETTINGS',
  CLIENT_VISIBILITY_SETTINGS: 'CLIENT_VISIBILITY_SETTINGS',
  PARTICIPATIONS: 'PARTICIPATIONS',
  EXPORT_MODALS: {
    EXPORT_TYPE_MODAL: 'EXPORT_TYPE_MODAL',
    GENERATE_API_EXPORT_MODAL: 'GENERATE_API_EXPORT_MODAL',
    GENERATE_EXPORT_MODAL: 'GENERATE_EXPORT_MODAL',
  },
};

export const settingsModalTabKeys = {
  adminVisibility: 'admin',
  clientVisibility: 'client',
  adminExport: 'admin',
  clientExport: 'client',
};
