import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import Spinner, {SpinnerColors} from 'client/components/common/spinner';

import cssModule from './await-container.module.scss';

type AwaitContainerProps = {
  loading: boolean;
  overlay?: boolean;
  overlayClassName?: string;
  className?: string;
  spinnerClassName?: string;
  color?: (typeof SpinnerColors)[number];
};

const b = bem('await-container', {cssModule});

const AwaitContainer: React.FC<AwaitContainerProps> = (props) => {
  const {loading, className, spinnerClassName, children, overlay, overlayClassName, color = 'clients'} = props;

  if (!overlay) {
    return loading ? (
      <Spinner color={color} className={cn(b('spinner'), spinnerClassName)} centered />
    ) : (
      <>{children}</>
    );
  }

  return (
    <div className={cn(b(), className)}>
      {loading && (
        <div className={cn(b('spinner-container'), overlayClassName)}>
          <Spinner color={color} className={cn(b('spinner', {overlay}), spinnerClassName)} />
        </div>
      )}
      {children}
    </div>
  );
};

export default AwaitContainer;
