import React from 'react';

import {useLanguage} from 'client/services/hooks';

import ConfirmationModal from 'client/common/modals/confirmation-modal';

import {Translation} from 'client/models/language/types';

type RemoveBlockConfirmationProps = {
  show: boolean;
  type: 'layout' | 'content';
  onRemove: () => void;
  onCancel: () => void;
};

const RemoveBlockConfirmation: React.FC<RemoveBlockConfirmationProps> = (props) => {
  const {show, type, onRemove, onCancel} = props;
  const lang: Record<string, Translation> = useLanguage('EMAIL_TEMPLATE_EDITOR.MODALS.REMOVE_BLOCK_CONFIRMATION');

  return (
    <ConfirmationModal
      show={show}
      title={lang[`REMOVE_${type.toUpperCase()}_BLOCK`]}
      message={lang.MESSAGE}
      buttonCancel={{label: lang.REMOVE, transparent: true, color: 'error'}}
      buttonConfirm={{label: lang.CANCEL, transparent: true, color: 'clients'}}
      onCancel={onRemove}
      onClose={onCancel}
      onConfirm={onCancel}
      clientSide
    />
  );
};

export default RemoveBlockConfirmation;
