import React from 'react';

import PropTypes from 'prop-types';

import Datepicker from 'client/components/common/datepicker';

export const FilterDatepicker = ({fieldName, groupName, onChange, filters, props}) => {
  const dateValue = filters[fieldName];

  const onChangeDate = (date) => {
    if (onChange) {
      onChange(fieldName, date);
    }
  };

  return (
    <Datepicker
      key={fieldName}
      name={fieldName}
      value={dateValue}
      onChange={onChangeDate}
      disabled={!filters[groupName]}
      cssModifier="tokens-filter-modal__filter-datepicker"
      {...props}
    />
  );
};

FilterDatepicker.propTypes = {
  fieldName: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};
