import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

// components
import SelectDropdown from 'client/common/selects/select-dropdown';

import './per-page-dropdown.scss';

const b = bem('per-page-dropdown');

const PerPageDropdown = (props) => {
  const {className, selectClassName, labelClassName, ...restProps} = props;
  const perPageLabel = useLanguage('COMMON.TABLE_SHOW_PER_PAGE');

  return (
    <div className={cn(b(), className)}>
      <span className={cn(b('label'), labelClassName)}>{perPageLabel}</span>
      <SelectDropdown {...restProps} className={cn(b(), selectClassName)} />
    </div>
  );
};

PerPageDropdown.propTypes = {
  ...SelectDropdown.propTypes,
};

PerPageDropdown.defaultProps = {
  ...SelectDropdown.defaultProps,
  options: [5, 10, 15, 25].map((n) => ({value: `${n}`, label: n})),
};

export default PerPageDropdown;
