import moment from 'moment';

import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {isNotEmpty, required} from 'client/services/validator';

import {GENDER_TYPES_VALUES} from 'client/common/config';

import {LeadsGenerateFileFormValues, LeadsGenerationExportFilters} from './index';

export const validate = (
  values: LeadsGenerateFileFormValues,
  lang: LangsByPath<['LEADS_DATABASE.MODALS.GENERATE_FILE_MODAL']>,
) => {
  const errors: Partial<LeadsGenerateFileFormValues> = {
    name: required(lang.REQUIRED)(values?.name),
  };

  if (!values.allowed_for_all) {
    errors.memberships = isNotEmpty(lang.REQUIRED)(values?.memberships);
  }

  return errors;
};

type MappingResult = {
  query_params: {
    distinct: true;
    g: [Record<string, string | boolean>];
  } & Record<string, any>;
  query_string?: string;
};

export const mappingLeadFilters = (filters: LeadsGenerationExportFilters = {}) => {
  const result: MappingResult = {
    query_params: {
      distinct: true,
      g: [{}],
    },
  };

  if (filters?.phone) {
    result.query_params.phone_present = 't';
  }

  if (filters?.email) {
    result.query_params.email_present = 't';
  }

  if (filters?.visuals) {
    result.query_params.visuals_count_gt = 0;
  }

  if (filters?.participatedFrom) {
    result.query_params.g[0].participations_created_at_gteq = moment(
      filters.participatedFrom,
      'DD/MM/YYYY',
    ).toISOString();
  }
  if (filters?.participatedTo) {
    result.query_params.g[0].participations_created_at_lteq = moment(filters.participatedTo, 'DD/MM/YYYY')
      .hours(23)
      .minutes(59)
      .toISOString();
  }

  if (filters.gender) {
    result.query_params.gender_eq =
      GENDER_TYPES_VALUES[filters.gender.toUpperCase() as keyof typeof GENDER_TYPES_VALUES];
  }

  if (filters?.createdFrom) {
    result.query_params.g[0].created_at_gteq = moment(filters.createdFrom, 'DD/MM/YYYY').toISOString();
  }
  if (filters?.createdTo) {
    result.query_params.g[0].created_at_lteq = moment(filters.createdTo, 'DD/MM/YYYY')
      .hours(23)
      .minutes(59)
      .toISOString();
  }

  if (filters?.origin) {
    result.query_params.lead_first_source_source_id_eq = filters.origin.value;
  }

  if (filters.operation?.length) {
    result.query_params.participations_operation_id_in = filters.operation.map((p) => p.value);
  }

  if (filters?.optInRadio === 'no_active') {
    result.query_params.opt_ins_text_not_cont = true;
  } else if (filters?.optInRadio) {
    filters.optIn?.forEach((optIn) => {
      result.query_params.g[0][`opt_ins->>${optIn.value}_eq`] = true;
    });
  }

  return result;
};
