import React from 'react';

import bem from 'client/services/bem';

import {INSTANT_WIN_TABS} from 'client/components/prizes/fieldsets/prize-diy-instant-win-fieldset/constants';
import PrizeDiyStockInformation from 'client/components/prizes/fieldsets/prize-diy-stock-information';
import PrizeMapCustomizationGeneralFieldset from 'client/components/prizes/fieldsets/prizemap-customization-general-fieldset';
import PrizeDiyRatioParticipationPanel from 'client/components/prizes/panels/prize-diy-ratio-participation-panel';
import PrizeDiyRatioTimePanel from 'client/components/prizes/panels/prize-diy-ratio-time-panel';
import {PrizeInstantWinConfiguration} from 'client/models/prizes/types';

import cssModule from './prizemap-customization-form-general.module.scss';

const b = bem('prizemap-customization-form-general', {cssModule});

type PrizeMapCustomizationFormGeneralProps = {
  instantWinType?: PrizeInstantWinConfiguration['type'];
  editable?: boolean;
};

const PrizeMapCustomizationFormGeneral: React.FC<PrizeMapCustomizationFormGeneralProps> = ({
  instantWinType,
  editable,
}) => {
  return (
    <div className={b()}>
      <div className={b('left-column')}>
        <PrizeMapCustomizationGeneralFieldset disabled={!editable} />
        <PrizeDiyStockInformation disabled={!editable} />

        {instantWinType === INSTANT_WIN_TABS.RATIO_TIME && <PrizeDiyRatioTimePanel disabled={!editable} />}
        {instantWinType === INSTANT_WIN_TABS.RATIO_PARTICIPATION && (
          <PrizeDiyRatioParticipationPanel disabled={!editable} />
        )}
      </div>
    </div>
  );
};

export default PrizeMapCustomizationFormGeneral;
