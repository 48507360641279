import React from 'react';

import {useDispatch} from 'react-redux';
import {generatePath} from 'react-router';
import {useHistory} from 'react-router-dom';
import {routePaths} from 'routes/index';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {deleteAgencyClient} from 'client/ducks/agencies/actions';
import {interpolate} from 'client/ducks/language/helpers';

import AppButton from 'client/common/buttons/app-button';
import {useToast} from 'client/common/hooks/useToast';
import ConfirmationPopover from 'client/common/popovers/confirmation-popover';

import {Client} from 'client/models/client/types';

import cssModule from './agency-clients-actions.module.scss';

const b = bem('agency-clients-actions', {cssModule});

type AgencyClientsActionsProps = {
  client: Client;
  onDelete: () => any;
  onEdit: (client: Client) => any;
  agencyId: number;
};

const AgencyClientsActions: React.FC<AgencyClientsActionsProps> = (props) => {
  const {client, onDelete, agencyId} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('AGENCIES.LISTS.AGENCY_CLIENTS_LIST.AGENCY_CLIENTS_ACTIONS');
  const langCommon = useLanguage('COMMON');
  const history = useHistory();
  const {appendToastNotification} = useToast();

  const hpLink =
    generatePath(routePaths.client.ClientOperationsPage, {clientId: agencyId}) + `?agencyClientId=${client.id}`;
  const clientLink = generatePath(routePaths.client.AgencyClientPage, {clientId: agencyId, agencyClientId: client.id});

  const canDelete = client.allowed_to_destroy;

  const handleDelete = async () => {
    try {
      await dispatch(deleteAgencyClient(client.id));
      appendToastNotification({
        title: interpolate(lang.DELETED_MESSAGE.toString(), {name: client.name}),
        type: 'error',
      });
      onDelete();
    } catch {
      appendToastNotification({
        title: langCommon.ERROR,
        type: 'error',
      });
    }
  };

  return (
    <div className={b()}>
      <AppButton title={lang.GO_TO_OPERATIONS} iconName="operations" onClick={() => history.push(hpLink)} asWrap />
      <AppButton
        title={lang.GO_TO_CLIENT_INFO}
        iconName="account-settings"
        onClick={() => history.push(clientLink)}
        asWrap
      />
      <ConfirmationPopover
        title={interpolate(lang.DELETING_MESSAGE.toString(), {name: client.name})}
        popoverProps={{
          shiftLeft: -1,
          arrowOffset: 85,
          disabled: !canDelete,
        }}
        okButtonProps={{
          label: lang.CONFIRM,
          onClick: handleDelete,
        }}
      >
        <AppButton
          title={lang.DELETE}
          iconName="bin"
          asWrap
          disabled={!canDelete}
          className={b('delete', {disabled: !canDelete})}
        />
      </ConfirmationPopover>
    </div>
  );
};

export default AgencyClientsActions;
