import {getColor} from 'client/components/instore/charts/instore-donut-chart/helpers';

export const mapHorizontalBarsData = (data) => {
  return {
    labels: data.map((item) => item.name),
    datasets: [
      {
        data: data.map((item) => item.average),
        backgroundColor: '#98EA91',
        borderRadius: 20,
        barThickness: 9,
      },
    ],
  };
};

export const mapConsolidationDonutData = (data) => {
  return data.map((item) => ({
    answer: item.name,
    value: item.total,
  }));
};

export const mapMonoDonutData = (data) => {
  return data.map((item, index) => ({
    value: item.count,
    label: item.value,
    color: getColor(index),
  }));
};
