import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {CLIENT_PAGES} from 'client/common/config';
import CountBlock from 'client/common/count-block';

// components
import InfoBlock from 'client/components/common/info-block';

import './operation-leads-card.scss';

class OperationLeadsCard extends Component {
  static propTypes = {
    leadsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    languageState: PropTypes.object.isRequired,
    operation: PropTypes.object.isRequired,
  };

  static defaultProps = {
    leadsCount: 0,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.OPERATION_PAGE;
  }

  render() {
    const {leadsCount, operation} = this.props;
    const {LANGUAGE} = this;

    const clientLeadsLink = `${CLIENT_PAGES.LEADS_LIST}/${operation.client_id}`;

    return (
      <InfoBlock colorTheme="leads">
        <InfoBlock.Title>{LANGUAGE.OPERATION_LEADS_DATABASE.TITLE}</InfoBlock.Title>
        <InfoBlock.Content headerOnly>
          <InfoBlock.Header className="clearfix" alone>
            <CountBlock
              totalText={LANGUAGE.OPERATION_LEADS_DATABASE.LEADS_GENERATED}
              itemText={LANGUAGE.IN_THIS_OPERATION__DESCRIPTION}
              num={leadsCount}
              className="pull-left"
            />
            {leadsCount !== 0 && (
              <InfoBlock.HeaderLink
                link={{
                  url: `${clientLeadsLink}?filter={"operation":[{"value":${operation.id},"label":"${encodeURIComponent(
                    operation.name,
                  )}"}]}`,
                  text: LANGUAGE.OPERATION_LEADS_DATABASE.GO_TO_LEADS_LINK,
                }}
                description={LANGUAGE.OF_THIS_OPERATION__DESCRIPTION}
              />
            )}
          </InfoBlock.Header>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(OperationLeadsCard);
