import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';

import bem from 'client/services/bem';
import {getScenarioStepDescription} from 'client/services/helpers';

import AppButton from 'client/common/buttons/app-button';
import {SCENARIO_STEP_TYPES, SCENARIO_STEP_NAMES} from 'client/common/config';
import Offscreen from 'client/common/offscreen';

import Icon from 'client/components/common/icon';

import {TranslationJsx} from 'client/models/language/types';

import AtsStep from '../ats-step';
import AtsStepNavItem from '../ats-step-nav-item/ats-step-nav-item';
import {AtsTimerForm, AtsSmsForm, AtsEmailForm, AtsSocialForm, AtsCouponForm} from '../forms';
import AtsBranchForm from '../forms/ats-branch-form/ats-branch-form';
import AtsCalculationForm from '../forms/ats-calculation-form/ats-calculation-form';
import AtsInstantWinForm from '../forms/ats-instant-win-form/ats-instant-win-form';
import AtsPrizeDrawForm from '../forms/ats-prize-draw-form/ats-prize-draw-form';

import './ats-step-list.scss';

const b = bem('ats-step-list');

export const getFormId = (postfix) => `AtsStepForm_${postfix}`;

const STEP_MAPPING = {
  [SCENARIO_STEP_TYPES.timer]: AtsTimerForm,
  [SCENARIO_STEP_TYPES.branch]: AtsBranchForm,
  [SCENARIO_STEP_TYPES.calculation]: AtsCalculationForm,
  [SCENARIO_STEP_TYPES.message.email]: AtsEmailForm,
  [SCENARIO_STEP_TYPES.message.sms]: AtsSmsForm,
  [SCENARIO_STEP_TYPES.social.facebook]: AtsSocialForm,
  [SCENARIO_STEP_TYPES.social.twitter]: AtsSocialForm,
  [SCENARIO_STEP_TYPES.instantLottery]: AtsInstantWinForm,
  [SCENARIO_STEP_TYPES.prizeDraw]: AtsPrizeDrawForm,
  [SCENARIO_STEP_TYPES.coupons]: AtsCouponForm,
  [SCENARIO_STEP_TYPES.weezioCoupons]: AtsCouponForm,
  [SCENARIO_STEP_TYPES.appCoupons]: AtsCouponForm,
  [SCENARIO_STEP_TYPES.sogecCoupons]: AtsCouponForm,
};

const DragHandle = SortableHandle(() => <Icon name="sandwich" className={b('drag-handle')} />);

const NavTab = SortableElement((props) => {
  const {selectStep, step, scenarioVariables, invalidForms, handleDeleteStep, stepNames, activeTab} = props;

  const navStepNamePrefix = stepNames[SCENARIO_STEP_NAMES[step.implementation.type]];

  if (navStepNamePrefix) {
    return (
      <AppButton
        className={b('nav-item')}
        onClick={() => selectStep(step.id)}
        label={
          <>
            <DragHandle />
            <AtsStepNavItem
              id={step.id}
              name={`${navStepNamePrefix} ${step.name}`}
              description={getScenarioStepDescription(step.implementation, scenarioVariables)}
              comment={step.comment}
              formIsInvalid={!!invalidForms.find((v) => v.id === step.id)}
              onDelete={handleDeleteStep}
              isActive={step.id === activeTab}
            />
          </>
        }
        asWrap
      />
    );
  }

  return null;
});

const NavTabs = SortableContainer((props) => {
  const {selectStep, steps, scenarioVariables, invalidForms, handleDeleteStep, stepNames, activeTab} = props;

  return (
    <nav className={b('nav')}>
      {steps.map((step, index) => (
        <NavTab
          stepNames={stepNames}
          key={step.id}
          index={index}
          step={step}
          scenarioVariables={scenarioVariables}
          invalidForms={invalidForms}
          handleDeleteStep={handleDeleteStep}
          selectStep={selectStep}
          activeTab={activeTab}
        />
      ))}
    </nav>
  );
});

class AtsStepList extends Component {
  static propTypes = {
    // TODO: Describe data structure
    steps: PropTypes.arrayOf(PropTypes.shape({})),
    scenarioVariables: PropTypes.array.isRequired,
    invalidForms: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ).isRequired,
    stepNames: PropTypes.object.isRequired,
    applyBtnText: TranslationJsx,
    handleSelectStep: PropTypes.func.isRequired,
    handleSortSteps: PropTypes.func.isRequired,
    handleSaveStep: PropTypes.func.isRequired,
    handleSaveSingleStep: PropTypes.func.isRequired,
    handleDeleteStep: PropTypes.func.isRequired,
    handleSubmitResult: PropTypes.func.isRequired,
    stepListTitle: TranslationJsx,
    stepConfigTitle: TranslationJsx,
  };

  static defaultProps = {
    applyBtnText: '',
    stepListTitle: '',
    stepConfigTitle: '',
  };

  state = {
    activeTab: null,
  };

  submitForm = (stepId) => {
    this.props.handleSaveSingleStep(stepId).then(({success}) => {
      if (success) {
        this.setState({
          activeTab: null,
        });
      }
    });
  };

  renderTab = (step) => {
    const StepFormComponent = STEP_MAPPING[step.implementation.type];

    const {stepNames, applyBtnText} = this.props;

    if (StepFormComponent) {
      const navStepNamePrefix = stepNames[SCENARIO_STEP_NAMES[step.implementation.type]];
      const tabStep = {...step};

      return (
        <Offscreen key={step.id} hidden={step.id !== this.state.activeTab}>
          <AtsStep applyBtnText={applyBtnText} title={navStepNamePrefix} onSubmit={() => this.submitForm(step.id)}>
            <StepFormComponent
              data={tabStep}
              formId={getFormId(step.id)}
              onSubmitForm={this.props.handleSaveStep}
              onSubmitResult={this.props.handleSubmitResult}
            />
          </AtsStep>
        </Offscreen>
      );
    }

    return null;
  };

  renderTabs = (steps) => steps.map(this.renderTab).filter(Boolean);

  handleSelectStep = (stepId) => {
    this.setState({
      activeTab: stepId,
    });
  };

  render() {
    const {
      steps,
      invalidForms,
      scenarioVariables,
      handleSelectStep,
      handleDeleteStep,
      handleSortSteps,
      stepConfigTitle,
      stepListTitle,
      stepNames,
    } = this.props;

    if (!steps.length) {
      return null;
    }

    return (
      <div className={b()}>
        <div id="ats-steps-list-steps" className={cn(b('step'), 'theme-color-7')} onSelect={handleSelectStep}>
          <div className={b('navbar')}>
            <div className={b('col-title')}>{stepListTitle}</div>
            <NavTabs
              stepNames={stepNames}
              selectStep={this.handleSelectStep}
              steps={steps}
              invalidForms={invalidForms}
              scenarioVariables={scenarioVariables}
              handleDeleteStep={handleDeleteStep}
              onSortEnd={({oldIndex, newIndex}) => handleSortSteps(oldIndex, newIndex)}
              activeTab={this.state.activeTab}
              useDragHandle
            />
          </div>
          <div className={b('content')}>
            <div className={b('col-title', {config: true})}>{stepConfigTitle}</div>
            <div className={b('config')}>{this.renderTabs(steps)}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    scenario: {
      payload: {scenario_variables: scenarioVariables, scenario_step_variables: scenarioStepVariables = []},
    },
  } = state;

  return {
    scenarioVariables: scenarioVariables.concat(scenarioStepVariables),
  };
};

export default connect(mapStateToProps)(AtsStepList);
