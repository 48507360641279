import React, {useCallback, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {
  postTranslation,
  patchTranslation,
  deleteTranslation,
  postTranslationsItem,
  patchTranslationsItem,
  deleteTranslationFromStore,
} from 'client/ducks/translations/actions';
import {selectTranslationsTree} from 'client/ducks/translations/selectors';

import SearchInput from 'client/common/inputs/search-input';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import Spinner from 'client/components/common/spinner';

import TranslationsList from '../translations-list';
import TranslationsModal from '../translations-modal';
import TranslationsSearchModal from '../translations-search-modal';

import './translations-structure.scss';

const b = bem('translations-structure');
const newTranslation = {
  parent_id: null,
  key: null,
  parents: [],
};

const TranslationsStructure = () => {
  const lang = useLanguage('TRANSLATIONS');
  const dispatch = useDispatch();
  const [selectedTranslation, setSelectedTranslation] = useState(null);
  const [showDeleteConfirmation, toggleShowDeleteConfirmation] = useToggle(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearchModal, toggleSearchModal] = useToggle(false);
  const translationsTree = useSelector(selectTranslationsTree);
  const isLoading = useSelector((state) => state.translations.isLoading);

  const handleTranslationCreate = useCallback(
    (parentId) => {
      setSelectedTranslation({
        ...newTranslation,
        parent_id: parentId,
      });
    },
    [setSelectedTranslation],
  );

  const handleTranslationUpdate = useCallback(
    (translation) => {
      setSelectedTranslation(translation);
    },
    [setSelectedTranslation],
  );

  const handleTranslationDelete = useCallback(async () => {
    toggleShowDeleteConfirmation();
  }, [toggleShowDeleteConfirmation]);

  const handleTranslationSave = useCallback(
    async (formValues) => {
      const {items, ...translation} = formValues;
      const fetch = formValues.id ? patchTranslation : postTranslation;
      const translationResponse = await dispatch(fetch(translation));
      const translationId = translationResponse.payload.translation?.id;
      if (!translationId) {
        setSelectedTranslation((prevState) => ({
          ...prevState,
          error: lang.ERRORS.SAVE,
        }));
        return;
      }

      if (!translation.withTranslations) {
        setSelectedTranslation(null);
        return;
      }

      const translationsItemsPromises = Object.entries(items).map(([language, item]) => {
        if (item.id) {
          return dispatch(patchTranslationsItem(item));
        }
        return dispatch(postTranslationsItem({...item, language, id: null, translation_id: translationId}));
      });
      const translationsItemsResponse = await Promise.all(translationsItemsPromises);
      const translationsItemsSaveFailed = translationsItemsResponse.some((el) => !el.payload.translation_item?.id);

      if (translationsItemsSaveFailed) {
        setSelectedTranslation((prevState) => ({
          ...prevState,
          error: lang.ERRORS.SAVE_ITEM,
        }));
        return;
      }

      setSelectedTranslation(null);
    },
    [dispatch, lang],
  );

  const handleTranslationConfirmDelete = async () => {
    await dispatch(deleteTranslation(selectedTranslation));
    await dispatch(deleteTranslationFromStore(selectedTranslation));
    setSelectedTranslation(null);
    toggleShowDeleteConfirmation();
  };

  const handleSearch = (query) => {
    if (query.trim().length > 1) {
      setSearchQuery(query);
      toggleSearchModal();
    }
  };

  return (
    <div className={b()}>
      <div className={b('search-field')}>
        <SearchInput placeholder={lang.SEARCH_PLACEHOLDER} onSearch={handleSearch} />
      </div>
      {isLoading && <Spinner className={b('spinner')} centered color="primary" />}
      <TranslationsList
        translations={translationsTree}
        show={true}
        onTranslationCreate={handleTranslationCreate}
        onTranslationUpdate={handleTranslationUpdate}
      />
      {!!selectedTranslation && (
        <TranslationsModal
          translation={selectedTranslation}
          onSubmit={handleTranslationSave}
          onClose={() => setSelectedTranslation(null)}
          onDelete={handleTranslationDelete}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationModal
          show={true}
          onConfirm={handleTranslationConfirmDelete}
          onCancel={toggleShowDeleteConfirmation}
          onClose={toggleShowDeleteConfirmation}
          title={lang.CONFIRM_DELETE.TITLE}
          message={lang.CONFIRM_DELETE.MESSAGE}
          confirmText={lang.CONFIRM_DELETE.DELETE}
          cancelText={lang.CONFIRM_DELETE.CANCEL}
          buttonConfirm={{
            loading: isLoading,
            disabled: isLoading,
          }}
        />
      )}
      {showSearchModal && (
        <TranslationsSearchModal query={searchQuery} onItemClick={setSelectedTranslation} onClose={toggleSearchModal} />
      )}
    </div>
  );
};

export default TranslationsStructure;
