import React, {useMemo, useState} from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';
import Popover from 'client/common/popovers/popover';
import FullStepProgressBar from 'client/common/step-progress-bars/full-step-progress-bar';

import Icon from 'client/components/common/icon';

import DiyOpAccessLevelPopover from 'client/components/diy-operation/popovers/diy-op-access-level-popover';

import {DIYCustomizationModalHeaderClassicProps} from './types';

import cssModule from './diy-customization-modal-header-classic.module.scss';

const b = bem('diy-customization-modal-header-classic', {cssModule});

const DIYCustomizationModalHeaderClassic: React.FC<DIYCustomizationModalHeaderClassicProps> = (props) => {
  const {
    currentStep,
    onClose,
    onSave,
    setCurrentStep,
    blocked,
    disabled,
    loading,
    submitting,
    isAccessGranted = false,
    pagesAccesses,
    setFormAccessLevel,
    headerInfo,
  } = props;
  const lang = useLanguage('DIY_OPERATION.MODALS.DIY_CUSTOMIZATION_MODAL.DIY_CUSTOMIZATION_MODAL_HEADER_CLASSIC');
  const [isPopoverShown, setIsPopoverShown] = useState(false);

  const stepNumberContents = useMemo(() => {
    if (!isAccessGranted || !pagesAccesses.length) {
      return [];
    }

    return pagesAccesses.map(({id, access}: {id: number; access: string}) => {
      return (
        <DiyOpAccessLevelPopover
          key={id}
          access={access}
          title={lang.ACCESS_LEVEL_TITLE}
          description={lang.ACCESS_LEVEL_DESCRIPTION}
          onChange={(value: string) => setFormAccessLevel?.({value, formPageId: id, type: 'formPage'})}
          setIsPopoverShown={setIsPopoverShown}
          isPopoverShown={isPopoverShown}
        />
      );
    });
  }, [
    isAccessGranted,
    pagesAccesses,
    lang.ACCESS_LEVEL_TITLE,
    lang.ACCESS_LEVEL_DESCRIPTION,
    isPopoverShown,
    setFormAccessLevel,
  ]);

  return (
    <div className={b({disabled: blocked || disabled})}>
      <div className={b('container', ['info-bar'])}>
        {headerInfo.labels?.help && (
          <Popover
            overlayInnerStyle={{width: 200}}
            className={b('popover')}
            overlay={<span>{headerInfo.labels?.help}</span>}
          >
            <span className={b('popover-trigger')}>
              <Icon name="question" className={b('icon-popover')} width="16" height="16" />
              <span className={b('text-popover')}>{lang.HELP}</span>
            </span>
          </Popover>
        )}

        {!loading && (
          <div className={b('title-container')}>
            {headerInfo?.formTitle && <p className={b('form-title')}>{headerInfo?.formTitle}</p>}
            {headerInfo.labels?.title && <p className={b('title')}>{headerInfo.labels?.title}</p>}
          </div>
        )}

        <div className={b('buttons-wrapper')}>
          <AppButton
            onClick={onClose}
            color="devices"
            label={<span className={b('cancel-text')}>{lang.CANCEL_BUTTON} </span>}
            link
          />
          {!loading && (
            <AppButton
              disabled={blocked || disabled}
              onClick={onSave}
              color="devices"
              label={lang.SAVE_BUTTON}
              loading={submitting}
              submit
            />
          )}
        </div>
      </div>

      <div className={b('container', ['step-progress-bar'])}>
        {!loading && (
          <FullStepProgressBar
            titles={headerInfo.titles}
            totalSteps={headerInfo.totalSteps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            disabled={blocked}
            content={stepNumberContents}
            isPopoverShown={isPopoverShown}
            setIsPopoverShown={setIsPopoverShown}
          />
        )}
      </div>
    </div>
  );
};

export default DIYCustomizationModalHeaderClassic;
