import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {formatNumberSpace} from 'client/services/formatters';
import {useLanguage} from 'client/services/hooks';

import CustomCard from 'client/common/cards/custom-card';

import DonutChart from 'client/components/common/charts/donut-chart';

import {getChartData, calculateVisits} from '../helpers';

import './campaigns-main-info-block.scss';

const b = bem('campaigns-main-info-block');

// eslint-disable-next-line react/prop-types
const CardHeader = ({title, amount, textColor}) => (
  <>
    <div className={b('header-title')}>{title}</div>
    <div className={b('header-total', [textColor])}>{amount}</div>
  </>
);

const CampaignsMainInfoBlock = (props) => {
  const {
    data: {visits, participations, visitsBySourceType},
    loading,
  } = props;

  const lang = useLanguage('CAMPAIGNS_TAB');
  const langSourceTypes = useLanguage('SOURCE_TYPES');

  const conversion = visits ? Math.round((participations / visits) * 100) : 0;

  return (
    <div className={b()}>
      <div className={b('row')}>
        <CustomCard
          format="column"
          loading={loading}
          loadingColor="visits"
          header={
            <CardHeader
              title={lang.VISITS_PER_SOURCE_TYPE}
              amount={formatNumberSpace(calculateVisits(visitsBySourceType))}
              textColor="visits"
            />
          }
        >
          <DonutChart legendOnRight={true} data={getChartData(visitsBySourceType, langSourceTypes)} />
        </CustomCard>

        <CustomCard
          format="column"
          loading={loading}
          loadingColor="visits"
          header={<CardHeader title={lang.VISITS} amount={formatNumberSpace(visits)} textColor="visits" />}
        />

        <CustomCard
          format="column"
          loading={loading}
          loadingColor="primary"
          header={<CardHeader title={lang.CONVERSION} amount={`${conversion}%`} textColor="primary" />}
        />

        <CustomCard
          format="column"
          loading={loading}
          loadingColor="participants"
          header={
            <CardHeader
              title={lang.PARTICIPATIONS}
              amount={formatNumberSpace(participations)}
              textColor="participations"
            />
          }
        />
      </div>
    </div>
  );
};

CampaignsMainInfoBlock.propTypes = {
  autotaskId: PropTypes.number,
  data: PropTypes.object,
  lang: PropTypes.object,
  sources: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

export default CampaignsMainInfoBlock;
