import {useCallback, useEffect, useMemo, useState} from 'react';

import uniqBy from 'lodash/uniqBy';
import {useDispatch, useSelector} from 'react-redux';

import {ACCESS_LEVEL_TYPES} from 'client/ducks/client-users/constants';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {getInstoreTaskAccesses, getInstoreTaskKits, getTaskNetworks} from 'client/ducks/instore-tasks/actions';
import {
  selectInstoreTask,
  selectInstoreTaskAccesses,
  selectInstoreTaskKits,
  selectTaskNetworks,
} from 'client/ducks/instore-tasks/selectors';
import {selectCurrentMembershipLevel} from 'client/ducks/user-clients/selectors';
import {selectClientAccessLevel} from 'client/ducks/user-clients/selectors';
import {selectCurrentUser} from 'client/ducks/user/selectors';

import {USER_MEMBERSHIP} from 'client/common/config';

import {useFetchRegionAccesses} from 'client/components/instore/hooks/use-fetch-region-accesses';

export const useGetNetworks = () => {
  const dispatch = useDispatch();
  const task = useSelector(selectInstoreTask);
  const networks = useSelector(selectTaskNetworks);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(() => {
    const queryParams = {
      include: ['network'],
      q: {
        instore_task_id_eq: task?.id,
      },
    };

    setLoading(true);
    return dispatch(getTaskNetworks(queryParams)).finally(() => setLoading(false));
  }, [dispatch, task?.id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const options = useMemo(
    () =>
      networks?.map((taskNetwork) => ({
        label: taskNetwork.network?.name,
        value: taskNetwork.network?.id?.toString(),
      })) || [],
    [networks],
  );

  return {fetch, loading, networks, options};
};

export const useGetKits = () => {
  const dispatch = useDispatch();
  const task = useSelector(selectInstoreTask);
  const kits = useSelector(selectInstoreTaskKits);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(() => {
    if (task?.id) {
      const queryParams = {
        q: {
          instore_task_id_eq: task?.id,
        },
      };

      setLoading(true);
      return dispatch(getInstoreTaskKits(queryParams)).finally(() => setLoading(false));
    }
    return Promise.resolve();
  }, [dispatch, task?.id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const options = useMemo(
    () =>
      kits?.map((kit) => ({
        label: kit.name,
        value: kit.id?.toString(),
      })) || [],
    [kits],
  );

  return {fetch, loading, kits, options};
};

export const useGetTaskAccesses = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {loading: loadingRegionAccesses, regionIds} = useFetchRegionAccesses();

  const task = useSelector(selectInstoreTask);
  const currentClient = useSelector(selectCurrentClient);
  const currentUser = useSelector(selectCurrentUser);
  const currentLevel = useSelector(selectCurrentMembershipLevel);
  const accesses = useSelector(selectInstoreTaskAccesses);
  const clientAccessLevel = useSelector(selectClientAccessLevel);

  const fetch = useCallback(() => {
    if (task?.id && !loadingRegionAccesses) {
      const queryParams = {
        include_membership_client_user_full_name: null,
        include: {
          membership: {region_accesses: {region: null}},
          kit_mappings: {kit: true},
        },
        q: {
          instore_task_id_eq: task?.id,
          membership_client_id_eq: currentClient?.id,
          membership_access_level_eq: ACCESS_LEVEL_TYPES.LOCAL,
          membership_client_user_id_eq: currentLevel === USER_MEMBERSHIP.LOCAL ? currentUser.id : null,
        },
      };

      if (clientAccessLevel === ACCESS_LEVEL_TYPES.REGIONS) {
        queryParams.q.membership_region_accesses_region_id_in = regionIds;
      }

      setLoading(true);
      return dispatch(getInstoreTaskAccesses(queryParams)).finally(() => setLoading(false));
    }
    return Promise.resolve();
  }, [
    task?.id,
    loadingRegionAccesses,
    currentClient?.id,
    clientAccessLevel,
    dispatch,
    regionIds,
    currentLevel,
    currentUser.id,
  ]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const userOptions = useMemo(
    () =>
      accesses.map((access) => ({
        label: access.membership?.client_user_full_name,
        value: access.membership?.client_user_id?.toString(),
      })),
    [accesses],
  );

  const regionOptions = useMemo(
    () =>
      uniqBy(
        accesses.flatMap((access) =>
          access.membership?.region_accesses?.flatMap((regionAccess) => ({
            label: regionAccess.region?.name,
            value: regionAccess.region?.id?.toString(),
          })),
        ),
        'value',
      ).filter(Boolean),
    [accesses],
  );

  return {fetch, loading, accesses, userOptions, regionOptions};
};
