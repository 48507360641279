import get from 'lodash/get';
import moment from 'moment';

import {ACCESS_LEVEL_TYPES} from 'client/ducks/client-users/constants';

import getInitialAnimator from 'client/components/animations/fieldsets/animation-animator-fieldset/initial';
import getInitialEventInstallation from 'client/components/animations/fieldsets/animation-event-fieldset/initialValues';
import getInitialProducts from 'client/components/animations/fieldsets/animation-products-fieldset/initialValues';

const getMonth = (animationDay, kitMapping) => {
  const from = animationDay?.from || kitMapping?.from;
  return from ? moment(from).toISOString() : moment().toISOString();
};

/**
 *
 * @param {object}
 * @return {object}
 */
export default ({access = null, animation}) => {
  const accessLevel = get(access, 'membership.access_level', '');

  if (animation) {
    return {
      contact_name: animation.contact_name,
      contact_email: animation.contact_email,
      contact_phone: animation.contact_phone,
      security_email: animation.security_email,
      security_fax: animation.security_fax,

      animation_days: animation.animation_days,
      task_position_id: animation.task_position_id,
      network_id: animation?.place?.network_id,
      access: animation.instore_task_access,
      place: animation.place,
      week: animation.week,
      year: animation.year,
      month: getMonth(animation.animation_days?.[0], animation.kit_mapping),
      kit_mapping: animation.kit_mapping,
      kit_affectation_id: animation.kit_affectation?.id,
      id: animation.id,
      status: animation.status,
      products: getInitialProducts(animation),
      questions_statistic: animation.questions_statistic,
      ...getInitialAnimator({animation}),
      ...getInitialEventInstallation(animation),
    };
  } else if (accessLevel === ACCESS_LEVEL_TYPES.LOCAL) {
    const kitMappingCount = access?.kit_mappings?.length || 0;
    return {
      access,
      kit_mapping: kitMappingCount === 1 ? access.kit_mappings[0] : null,
      month: getMonth(null, kitMappingCount === 1 ? access.kit_mappings[0] : null),
      products: [],
    };
  }

  return {
    products: [],
  };
};
