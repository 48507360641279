import React, {useState} from 'react';

import {useDispatch} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {patchClient} from 'client/ducks/clients-list/actions';

import {ToggleInput} from 'client/common/inputs';

import {Client} from 'client/models/client/types';

import AccountCard from '../account-card';

import cssModule from './account-permissions.module.scss';

const b = bem('account-permissions', {cssModule});

type AccountPermissionsProps = {
  client: Client;
};

const AccountPermissions: React.FC<AccountPermissionsProps> = (props) => {
  const {client} = props;

  const lang = useLanguage('ACCOUNT_PARAMETERS.PERMISSIONS');
  const dispatch = useDispatch();

  const [localValue, setLocalValue] = useState({
    user_creation_allowed: client.user_creation_allowed,
    crm_management: client.crm_management,
  });

  const handleChange = (value: boolean, field: 'crm_management' | 'user_creation_allowed') => {
    setLocalValue((prev) => ({
      ...prev,
      [field]: value,
    }));
    dispatch(patchClient(client.id, {client: {[field]: value}}));
  };

  return (
    <AccountCard className={b()} title={lang.TITLE}>
      <div className={b('body')}>
        <ToggleInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.checked, 'user_creation_allowed')}
          checked={localValue.user_creation_allowed}
          label={lang.USER_CREATION_ALLOWED}
          labelPosition="right"
          rounded
        />
        <ToggleInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.checked, 'crm_management')}
          checked={localValue.crm_management}
          label={lang.CRM_MANAGEMENT}
          labelPosition="right"
          rounded
        />
      </div>
    </AccountCard>
  );
};

export default AccountPermissions;
