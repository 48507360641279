export function selectLeadHistories(state) {
  return state?.leadHistories?.payload;
}

export function selectLeadHistoriesMeta(state) {
  return state.leadHistories.meta;
}

export function selectClientId(state) {
  return state.lead.payload.client_id;
}

export function selectInterfacesForDropdown(state) {
  const noMatterTextLabel = state.languageState.payload.LEADS_LIST.TABLE_FILTERS_MODAL_NO_MATTER_RADIO;
  const interfacesForDropDown = state.interfaces.interfaces;
  return [
    {
      value: '0',
      label: noMatterTextLabel,
    },
    ...interfacesForDropDown.map(({id, name}) => ({value: id, label: name})),
  ];
}

export function selectUsersForDropdown(state) {
  const noMatterTextLabel = state.languageState.payload.LEADS_LIST.TABLE_FILTERS_MODAL_NO_MATTER_RADIO;
  const usersForDropdown = state.leadHistories.usersForDropDown;
  return [
    {
      value: '0',
      label: noMatterTextLabel,
    },
    ...usersForDropdown.map(({id, full_name}) => ({value: id, label: full_name})),
  ];
}
