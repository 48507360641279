export const COMPONENT_TYPES = {
  FIX: 'fix',
  VAR: 'var',
  OPERATION: 'operation',
};

export const DESTINATION_TYPES = {
  VARIABLE: 'variable',
  DATABASE: 'database',
};

export const isVarOrFix = (type) => {
  return type === COMPONENT_TYPES.VAR || type === COMPONENT_TYPES.FIX;
};

export const isVar = (type) => {
  return type === COMPONENT_TYPES.VAR;
};
