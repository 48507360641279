import types from './types';

const initialState = {
  email: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ANONYMOUS_EMAIL_REQUEST:
      return initialState;

    case types.GET_ANONYMOUS_EMAIL_SUCCESS:
      return {
        ...state,
        email: action.payload.message,
      };

    default:
      return state;
  }
};
