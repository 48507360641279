import React from 'react';

import {Cell} from 'fixed-data-table-2';
import moment from 'moment';
import PropTypes from 'prop-types';

function DateCell(props) {
  const {data, rowIndex, field} = props;
  let date = data[rowIndex][field];
  if (date) {
    date = moment(date).format('DD/MM/YYYY');
  }
  return <Cell>{date}</Cell>;
}
DateCell.defaultProps = {
  data: [],
};

DateCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

export default DateCell;
