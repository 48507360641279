import React, {useState, useCallback} from 'react';

import map from 'lodash/map';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams} from 'client/services/hooks';
import useReduxFetch from 'client/services/hooks/use-redux-fetch';

import {getDeviceAffectations} from 'client/ducks/device-affectation/actions';
import {selectDeviceAffectations} from 'client/ducks/device-affectation/selectors';
import {linkAllDevice, linkDevice} from 'client/ducks/devices/actions';

import AppButton from 'client/common/buttons';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import DevicesDiyListTable from 'client/components/devices/lists/devices-diy-list/devices-diy-list-table';
import DeviceDiyParametersModal from 'client/components/devices/modals/device-diy-parameters-modal';
import {isOperationArchived} from 'client/models/operations/helpers';

import DevicesDiyListFilters from './devices-diy-list-filters';

import './devices-diy-list.scss';

const b = bem('devices-diy-list');

const DEFAULT_PER_PAGE = 25;

const DevicesDiyList = React.memo((props) => {
  const {operation, fetchOperation, fetchGame} = props;
  const history = useHistory();
  const location = useLocation();

  const [queryParams, setQueryParams] = useQueryParams(
    {history, location},
    {
      page: 1,
      perPage: DEFAULT_PER_PAGE,
    },
  );
  const [checkedDeviceAffectations, setCheckedDeviceAffectations] = useState([]);
  const [checkedAllDeviceAffectations, setCheckedAllDeviceAffectations] = useState(false);

  const [editedDeviceAffectation, setEditedDeviceAffectation] = useState(null);

  const dispatch = useDispatch();

  const lang = useLanguage('CLIENT_DEVICES');

  const isOpArchived = isOperationArchived(operation);

  const {
    data: deviceAffectations,
    loading,
    fetch: fetchData,
  } = useReduxFetch({
    action: getDeviceAffectations,
    selector: selectDeviceAffectations,
    actionArgs: {
      include_place_region_name: null,
      include_device_media_status: null,
      include_device_prize_maps_modified: true,
      include_interaction_prize_maps_present: true,
      include_aggregations: true,
      include: [
        'device',
        'device.current_interaction.operation',
        'device.interactions.operation',
        'device.device_prizes',
        'place',
      ],
      q: {
        s: [queryParams.sortField, queryParams.sortOrder].filter(Boolean).join(' '),
        client_id_eq: operation.client_id,
        place_region_id_in: queryParams.region,
        place_id_in: queryParams.store,
      },
      page: queryParams.page,
      per_page: queryParams.perPage,
      allow_non_client: true,
    },
  });

  const handleCurrentPageChange = useCallback(
    ({page}) => {
      setQueryParams({
        ...queryParams,
        page,
      });
    },
    [queryParams, setQueryParams],
  );

  const closeModal = () => {
    setEditedDeviceAffectation(null);
  };

  const onChangePerPage = (nextOption) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      perPage: nextOption?.value,
    });
  };

  const onCheckDevices = (checked, deviceAffectation) => {
    setCheckedDeviceAffectations((prev) => {
      if (checked) {
        return [...prev, deviceAffectation];
      }
      return prev.filter((item) => item?.id !== deviceAffectation.id);
    });
  };

  const onCheckAllDevices = (checked) => {
    setCheckedDeviceAffectations([]);
    setCheckedAllDeviceAffectations(checked);
  };

  const openEditDevice = (device) => {
    setEditedDeviceAffectation(device);
  };

  const onSaveDeviceParameters = async () => {
    setEditedDeviceAffectation(null);
    await fetchData();
  };

  const linkDevices = async () => {
    if (checkedAllDeviceAffectations) {
      await dispatch(linkAllDevice(operation.id, map(checkedDeviceAffectations, 'id')));
    } else {
      await dispatch(linkDevice(operation.id, {ids: map(checkedDeviceAffectations, 'id')}));
    }
    onCheckAllDevices(false);
    fetchData();
    fetchGame();
    fetchOperation();
  };

  const onUpdateTable = useCallback(() => {
    fetchData();
    fetchOperation();
  }, [fetchData, fetchOperation]);

  const selectedDeviceCount = checkedAllDeviceAffectations
    ? deviceAffectations?.unlinkedCount - checkedDeviceAffectations?.length
    : checkedDeviceAffectations.length;

  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('link')}>
          <AppButton
            label={lang.LINK_TO_INTERFACE}
            iconConfig={{
              name: 'check',
              className: b('check-icon'),
            }}
            onClick={linkDevices}
            disabled={
              !deviceAffectations.data ||
              (!checkedDeviceAffectations.length && !checkedAllDeviceAffectations) ||
              isOpArchived
            }
          />
          {!!selectedDeviceCount && (
            <span className={b('selected-count')}>{`${selectedDeviceCount} ${lang.DEVICE_SELECTED}`}</span>
          )}
        </div>
        <DevicesDiyListFilters />

        <PerPageDropdown
          name="per-page"
          value={String(queryParams.perPage)}
          onChange={onChangePerPage}
          className={b('per-page')}
        />
      </div>
      <DevicesDiyListTable
        data={deviceAffectations.data}
        loading={loading}
        onCheckAll={onCheckAllDevices}
        checkedAllDevices={checkedAllDeviceAffectations}
        openEditDevice={openEditDevice}
        sortParams={queryParams}
        onParamsChange={setQueryParams}
        checkedDevices={checkedDeviceAffectations}
        openLinkDevice={openEditDevice}
        onCheckDevices={onCheckDevices}
        onUpdateTable={onUpdateTable}
        fetchDeviceList={fetchData}
        disabled={isOpArchived}
      />

      <div className={b('pagination')}>
        <PaginationBar
          data={deviceAffectations.data}
          onPageChange={handleCurrentPageChange}
          currentPage={deviceAffectations.meta?.current_page}
          totalPages={deviceAffectations.meta?.total_pages}
          totalItems={deviceAffectations.meta?.total_count}
          perPage={Number(queryParams.perPage)}
        />
      </div>

      {!!editedDeviceAffectation && (
        <DeviceDiyParametersModal
          deviceAffectation={editedDeviceAffectation}
          onClose={closeModal}
          onSave={onSaveDeviceParameters}
          disabled={isOpArchived}
        />
      )}
    </div>
  );
});

DevicesDiyList.propTypes = {
  operation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    client_id: PropTypes.number.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
  fetchOperation: PropTypes.func.isRequired,
  fetchGame: PropTypes.func.isRequired,
};

export default DevicesDiyList;
