import React from 'react';

import cn from 'classnames';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';

import {selectCurrentMembership} from 'client/ducks/user-clients/selectors';

import Carousel from 'client/common/carousel';

import {sortTemplates} from 'client/components/catalog-modules/components/catalog-modules-slider/helpers';
import CatalogTemplateCard from 'client/components/catalog-templates/components/catalog-template-card';
import {getTemplateType} from 'client/components/catalog-templates/helpers';
import {getTemplateCategory} from 'client/components/catalog-templates/helpers';
import {Client} from 'client/models/client/types';
import {ClientTemplate} from 'client/models/templates/types';

import cssModule from './catalog-modules-slider.module.scss';

const b = bem('catalog-modules-slider', {cssModule});

type CatalogModulesSliderProps = {
  className?: string;
  templates: ClientTemplate[];
  perRow: number;
  fetchData: () => Promise<void>;
  updateTemplate: (id: number, data: Partial<ClientTemplate>) => void;
  style?: Record<string, string | number>;
  clientId: number;
  agencyClient?: Client | null;
};

const CatalogModulesSlider: React.FC<CatalogModulesSliderProps> = (props) => {
  const {className, templates, perRow, fetchData, updateTemplate, style, clientId, agencyClient} = props;
  const currentMembership = useSelector(selectCurrentMembership);

  const sortedTemplates = sortTemplates(templates, +clientId, currentMembership?.access);

  const slides = sortedTemplates?.map((template) => (
    <CatalogTemplateCard
      template={template}
      key={template.id}
      agencyClient={agencyClient}
      type={getTemplateCategory(template, currentMembership?.access)}
      isModule
      templateType={getTemplateType(template)}
      fetchData={fetchData}
      updateTemplate={updateTemplate}
    />
  ));

  return (
    <div className={cn(b(), className)} style={style}>
      <div className={b('wrapper')}>
        {sortedTemplates.length > perRow ? (
          <Carousel
            width="full"
            arrowVariant="arrow-alt"
            slidesPerView={perRow}
            hasArrows={true}
            loop={true}
            slides={slides}
          />
        ) : (
          <div className={b('no-slider')}>{slides}</div>
        )}
      </div>
    </div>
  );
};

export default CatalogModulesSlider;
