import React, {Component} from 'react';

import find from 'lodash/find';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// components
import Modal from 'client/common/modals/modal';

import CheckboxList from 'client/components/common/checkbox-list';
import CustomScrollbars from 'client/components/common/custom-scrollbars';

import './edit-access-level-modal.scss';

let LANGUAGE = {};

class EditAccessLevelModal extends Component {
  constructor(props) {
    super(props);

    LANGUAGE = this.props.languageState.payload.AGENCY;

    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleClose() {
    this.props.onClose();
  }

  handleConfirm() {
    this.props.onSave();
  }

  mapUsersToCheckboxList(users, clientId) {
    return users.map((user) => ({
      checked: typeof find(user.company_accesses, (i) => i.company_id === clientId) !== 'undefined',
      component: (
        <div className="user-info">
          <div className="agency-user-name">{user.client_user.full_name}</div>
          <div className="agency-user-email">{user.client_user.email}</div>
        </div>
      ),
    }));
  }

  render() {
    const {companyName, agencyName, users, clientId, onCheck} = this.props;
    return (
      <Modal
        show={this.props.show}
        onClose={this.handleClose}
        title={LANGUAGE.EDIT_ACCESS_LEVEL_MODAL_TITLE}
        dialogClassName="modal-window--width-1 modal-window--theme-1 edit-access-level-modal"
      >
        <h3>{companyName}</h3>
        <div className="modal-window__descr">{LANGUAGE.EDIT_ACCESS_LEVEL_MODAL_DESCRIPTION}</div>
        <div
          className="content-group content-group--expand"
          style={{
            color: '#B375C4',
          }}
        >
          <CustomScrollbars
            scrollbarProps={{
              hideTracksWhenNotNeeded: true,
              autoHeight: true,
              autoHeightMax: 500,
            }}
          >
            <div className="list">
              <h3 className="list__title">{`${agencyName} users`}</h3>
              <CheckboxList
                cssModifier="checkbox-list--color-1"
                items={this.mapUsersToCheckboxList(users, clientId)}
                onCheck={onCheck}
              />
            </div>
          </CustomScrollbars>
        </div>
        <div className="modal-window__footer modal-window__footer--centered">
          <button type="button" className="button button--bg-11 modal-window__footer-btn" onClick={this.handleClose}>
            {LANGUAGE.CANCEL_BUTTON}
          </button>
          <button className="button button--bg-1 modal-window__footer-btn" onClick={this.handleConfirm}>
            {LANGUAGE.SAVE_BUTTON}
          </button>
        </div>
      </Modal>
    );
  }
}

EditAccessLevelModal.defaultProps = {
  show: false,
  users: [],
};

EditAccessLevelModal.propTypes = {
  show: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onCheck: PropTypes.func,
  languageState: PropTypes.object,
  companyName: PropTypes.string,
  agencyName: PropTypes.string,
  clientId: PropTypes.number,
  users: PropTypes.array,
};

export default connect(({languageState}) => {
  return {
    languageState,
  };
})(EditAccessLevelModal);
