export default (weeklyList, lang, shouldValidate = true) => {
  const errors = {};

  if (!shouldValidate) {
    return errors;
  }

  const hasData = Object.values(weeklyList?.timestamps).some((dailyTimeStamps) => dailyTimeStamps.length > 0);

  if (!hasData) {
    Object.keys(weeklyList?.timestamps).forEach((day) => {
      errors.timestamps = {
        ...errors.timestamps,
        [day]: lang.REQUIRED,
      };
    });
  }

  return errors;
};
