import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

import './leads-export-type-modal.scss';

class LeadsExportTypeModal extends Component {
  static defaultProps = {
    show: false,
  };

  static propTypes = {
    show: PropTypes.bool,
    isAdmin: PropTypes.bool,
    lang: PropTypes.object.isRequired,
    modals: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static formName = 'LeadsExportFormModal';

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.props.initialize({export: this.props.modals.GENERATE_EXPORT_MODAL});
    }
  }

  render() {
    const {show, onClose, onConfirm, isAdmin, modals, lang} = this.props;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-2 leads-export-type-modal"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TITLE}
      >
        <div className="theme-color-2">
          <div className="leads-export-type-modal__row">
            <Field
              label={<span className="main-text">{lang.GENERATE_LABEL}</span>}
              name="export"
              type="radio"
              value={modals.GENERATE_EXPORT_MODAL}
              component={fieldTemplate}
            />
          </div>
          {isAdmin && (
            <div className="leads-export-type-modal__row">
              <Field
                label={<span className="main-text">{lang.JSON_LABEL}</span>}
                name="export"
                type="radio"
                value={modals.GENERATE_API_EXPORT_MODAL}
                component={fieldTemplate}
              />
            </div>
          )}
        </div>
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-11 modal-window__footer-btn" onClick={onClose}>
            {lang.CANCEL_BUTTON}
          </button>
          <button className="button button--bg-2 modal-window__footer-btn" onClick={onConfirm}>
            {lang.NEXT_BUTTON}
          </button>
        </div>
      </Modal>
    );
  }
}

const LeadsExportFormModal = reduxForm({
  form: LeadsExportTypeModal.formName,
})(LeadsExportTypeModal);

export default connect(({languageState}) => ({
  lang: languageState.payload.LEADS_PORT.EXPORT_TYPE_MODAL,
}))(LeadsExportFormModal);
