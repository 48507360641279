import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import PercentageBar from 'client/components/common/percentage-bar';

import './winnings-table-rate-cell.scss';

const b = bem('winnings-table-rate-cell');

const WinningsTableRateCell = ({value}) => (
  <div className={b()}>
    <span className={b('value')}>{value}%</span>
    <PercentageBar value={value} className={b('bar')} barBackClassName={b('bar-back')} />
  </div>
);

WinningsTableRateCell.propTypes = {
  value: PropTypes.number.isRequired,
};

export default WinningsTableRateCell;
