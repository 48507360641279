import React, {useCallback, useState, useMemo} from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {selectCurrentAccessLevel} from 'client/ducks/user-clients/selectors';

import ArticlePanel from 'client/common/panels/article-panel';

import DiyOpPublishingBlock from 'client/components/diy-operation/blocks/diy-op-publishing-block';
import DIYCustomizationModal from 'client/components/diy-operation/modals/diy-customization-modal';
import {getCatalogsInfoByType} from 'client/components/diy-operation/modals/diy-customization-modal/helpers/get-catalogs-info-by-type';
import {useTemplateParamsByAutotask} from 'client/components/diy-operation/modals/diy-customization-modal/useTemplateParamsByAutotask';
import DiyOpOptinsModal from 'client/components/diy-operation/modals/diy-op-optins-modal';
import {Autotask} from 'client/models/autotask/types';
import {Operation} from 'client/models/operations/types';
import {CATALOG_TYPES} from 'client/models/templates/constants';

import {DIY_OP_MAIN_BLOCK_MODALS} from './constants';
import DiyOpMainBlockButtons from './diy-op-main-block-buttons';
import DiyOpMainBlockIcon from './diy-op-main-block-icon';
import DiyOpMainBlockInputs from './diy-op-main-block-inputs';
import DiyOpMainBlockLangs from './diy-op-main-block-langs';
import DiyOpMainBlockValidate from './diy-op-main-block-validate';

import './diy-op-main-block.scss';

const b = bem('diy-op-main-block');

const CUSTOMIZATION_MODAL: (string | null)[] = [
  DIY_OP_MAIN_BLOCK_MODALS.CONFIGURATION_MODAL.id,
  DIY_OP_MAIN_BLOCK_MODALS.DESIGN_MODAL.id,
];

type DIYOpMainBlockProps = {
  fetchOperation: () => void;
  fetchAutotask: () => void;
  operation: Operation;
  autotask: Autotask;
  fetchResult: () => void;
};

const DIYOpMainBlock: React.FC<DIYOpMainBlockProps> = (props) => {
  const {operation, fetchOperation, autotask, fetchAutotask, fetchResult} = props;
  const {client_interface} = operation;

  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [formId, setFormId] = useState<number | null>(null);
  const customizationParams = useTemplateParamsByAutotask({formId});

  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_MAIN_BLOCK');
  const langModal = useLanguage('DIY_OPERATION.MODALS.OPTINS_MODAL');

  const accessLevel = useSelector(selectCurrentAccessLevel);
  const catalogsInfo = useMemo(
    () => client_interface && getCatalogsInfoByType(client_interface, accessLevel),
    [client_interface, accessLevel],
  );

  const templateCatalogInfo = catalogsInfo?.[CATALOG_TYPES.TEMPLATE];

  const client = useSelector(selectCurrentClient);
  const isAutoConfiguration = !!client.auto_configuration;
  const isHybrid = client_interface?.device && client_interface?.online;

  const handleActiveModalClose = useCallback(() => {
    if (activeModal === DIY_OP_MAIN_BLOCK_MODALS.OPTINS_MODAL.id) {
      fetchResult();
    }
    setActiveModal('null');
    setFormId(null);
  }, [activeModal, fetchResult]);

  const handleModalOpen = (key: string | null, id?: number | null) => {
    setActiveModal(key);
    if (id) {
      setFormId(id);
    }
  };

  return (
    <div className={b()}>
      <DiyOpPublishingBlock
        isAutoConfiguration={isAutoConfiguration}
        fetchOperation={fetchOperation}
        fetchAutotask={fetchAutotask}
        operation={operation}
        className={b('row')}
        autotask={autotask}
      />

      <ArticlePanel>
        {isAutoConfiguration && isHybrid && (
          <DiyOpMainBlockValidate
            autotask={autotask}
            operation={operation}
            fetchOperation={fetchOperation}
            fetchAutotask={fetchAutotask}
          />
        )}
        <div className={b('controls')}>
          <DiyOpMainBlockInputs fetchOperation={fetchOperation} className={b('block-inputs')} operation={operation} />
          <DiyOpMainBlockIcon className={b('block-icons')} operation={operation} />
          <DiyOpMainBlockLangs
            defaultLanguage={operation.default_language}
            languages={operation.languages}
            className={b('block-langs')}
            title={lang.TITLE}
          />

          <DiyOpMainBlockButtons
            templateCatalogInfo={templateCatalogInfo}
            className={b('block-buttons')}
            onClick={handleModalOpen}
          />
        </div>
      </ArticlePanel>

      {/* modals */}
      {activeModal === DIY_OP_MAIN_BLOCK_MODALS.OPTINS_MODAL.id && (
        <DiyOpOptinsModal
          formName={DIY_OP_MAIN_BLOCK_MODALS.OPTINS_MODAL.form}
          title={langModal.TITLE}
          onClose={handleActiveModalClose}
          interfaceId={client_interface?.id}
        />
      )}

      {CUSTOMIZATION_MODAL.includes(activeModal) && Boolean(formId) && customizationParams?.template && (
        <DIYCustomizationModal
          formId={formId}
          clientId={operation.client_id}
          onClose={handleActiveModalClose}
          {...customizationParams}
        />
      )}
    </div>
  );
};

export default DIYOpMainBlock;
