import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Popover from 'client/common/popovers/popover';

import Icon from 'client/components/common/icon';

class EditGroupPopover extends Component {
  static propTypes = {
    children: PropTypes.any,
    languageState: PropTypes.object.isRequired,
    group: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  };

  static defaultProps = {
    children: null,
  };

  render() {
    const {group, onEdit, onDelete, children, languageState} = this.props;

    const LANGUAGE = languageState.payload.POST_MANAGEMENT_TASK.EDIT_GROUP_POPOVER;

    return (
      <Popover
        position="bottom"
        overlayInnerStyle={{
          width: 350,
        }}
        overlay={
          <div className="popover-list theme-color-13 pmt-settings-popover">
            <h3 className="popover-list__title flex-container flex-align-center flex-justify-between">
              {LANGUAGE.TITLE}
              <button className="button button--circle button--bg-8" onClick={onEdit}>
                <Icon name="edit" className="button__icon" width={20} height={20} />
              </button>
            </h3>
            <div className="popover-list__list">
              <div className="popover-list__item">
                <div className="fake-input fake-input--disabled">
                  <div className="fake-input__label">{LANGUAGE.LEVEL_LABEL}</div>
                  <div className="fake-input__field">
                    {group.parent_id ? LANGUAGE.LEVELS.SUBGROUP : LANGUAGE.LEVELS.GROUP}
                  </div>
                </div>
              </div>
              <div className="popover-list__item">
                <div className="fake-input fake-input--disabled">
                  <div className="fake-input__label">{LANGUAGE.TYPE_LABEL}</div>
                  <div className="fake-input__field">{LANGUAGE.TYPES[group.type]}</div>
                </div>
              </div>
              <div className="popover-list__item">
                <div className="link link--color-1" onClick={onDelete}>
                  {LANGUAGE.DELETE_LABEL}
                </div>
              </div>
            </div>
          </div>
        }
      >
        {children}
      </Popover>
    );
  }
}

export default connect(({languageState}) => ({
  languageState: languageState,
}))(EditGroupPopover);
