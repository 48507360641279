import {isEmptyString} from 'client/services/validator';

import {CommonCouponValues} from 'client/components/prizes/fieldsets/prize-diy-common-coupon-fieldset/types';
import {COUPON_TOKEN_MODES} from 'client/models/prizes/constants';

export default (values: CommonCouponValues, lang: Record<string, string>, shouldValidate: boolean) => {
  const errors = {
    token_code: '',
    coupon_code_intervals: values.coupon_code_intervals.map(() => ({from: '', to: '', code: ''})),
  };

  if (!shouldValidate) {
    return errors;
  }

  if (values.token_mode === COUPON_TOKEN_MODES.PERMANENT && isEmptyString(values.token_code)) {
    errors.token_code = lang.REQUIRED;
  }

  if (values.token_mode !== COUPON_TOKEN_MODES.PERMANENT) {
    values.coupon_code_intervals.forEach((item, index) => {
      if (isEmptyString(item.from)) {
        errors.coupon_code_intervals[index].from = lang.REQUIRED;
      }
      if (errors.coupon_code_intervals[index].from && isEmptyString(item.to)) {
        errors.coupon_code_intervals[index].to = lang.REQUIRED;
      }
      if (isEmptyString(item.code)) {
        errors.coupon_code_intervals[index].code = lang.REQUIRED;
      }
    });
  }

  return errors;
};
