import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import AppButton from 'client/common/buttons';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Modal from 'client/common/modals/modal';
import Offscreen from 'client/common/offscreen';
import TabSwitcher from 'client/common/tab-switcher';

import AnimationBrandTitle from 'client/components/animations/components/animation-brand-title';
import AnimationStatusRoad from 'client/components/animations/components/animation-status-road';
import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container';
import AnimationMainModalReportingTab from 'client/components/animations/modals/animation-main-modal/tabs/animation-main-modal-reporting-tab';
import {ANIMATION_STATUSES} from 'client/components/instore/constants';
import {AnimationShape} from 'client/components/instore/types';

import AnimationMainModalGeneralTab from './tabs/animation-main-modal-general-tab';

import './animation-main-modal.scss';

const b = bem('animation-main-modal');

const getTabLinkClassName = ({isActive}) => b('tab-link', {active: isActive});

const TABS = {
  GENERAL: 'general',
  REPORTING: 'reporting',
};

const AnimationMainModal = (props) => {
  const {onClose, onSubmit, onSaveDraft, onDelete, animation, readOnly, onCancelSubmission, isReporting} = props;
  const lang = useLanguage('ANIMATIONS.MODALS.ANIMATION_MAIN_MODAL');
  const {formValues, invalid} = useReduxForm(AnimationConfigContainerFormName);
  const [activeTab, setActiveTab] = useState(isReporting ? TABS.REPORTING : TABS.GENERAL);
  const [isDeleteModalVisible, toggleIsDeleteModalVisible] = useToggle(false);

  const regionName = formValues?.place?.name;

  const membership = formValues?.access?.membership;
  const userName = membership?.client_user_full_name || membership?.client_user?.full_name;

  let buttons = null;
  const disabledSaveDraft = !formValues?.animation_days?.length;

  if (animation?.status === ANIMATION_STATUSES.ISSUED) {
    buttons = <AppButton label={lang.CANCEL_SUBMISSION} color="clients" onClick={onCancelSubmission} />;
  } else if (!readOnly) {
    buttons = (
      <>
        <AppButton label={lang.CANCEL_BUTTON} transparent onClick={onClose} />
        <AppButton label={lang.SAVE_DRAFT_BUTTON} transparent onClick={onSaveDraft} disabled={disabledSaveDraft} />
        <AppButton
          label={lang.SUBMIT_BUTTON}
          color="clients"
          onClick={onSubmit}
          disabled={invalid || disabledSaveDraft}
        />
      </>
    );
  }

  const isDeleteAvailable = !readOnly && animation?.status === ANIMATION_STATUSES.EDIT;

  const modalTitle = isReporting ? (
    <span>
      <span className={b('reporting-label')}>{lang.REPORTING_TITLE}:</span> {`${userName} - ${regionName}`}
    </span>
  ) : (
    `${userName} - ${regionName}`
  );

  return (
    <Modal
      onClose={onClose}
      title={modalTitle}
      bodyClassName={b('body')}
      dialogClassName={b()}
      contentClassName={b('table-content')}
      titleButton={buttons && <div className={b('buttons')}>{buttons}</div>}
    >
      <div className={b('header')}>
        <AnimationBrandTitle readOnly={readOnly} />
        <AnimationStatusRoad status={animation?.status} className={b('status-road')} />
      </div>
      {!isReporting && (
        <TabSwitcher
          className={b('tabs')}
          tabs={[
            {title: lang.TABS.GENERAL_TAB_TITLE, value: TABS.GENERAL, className: getTabLinkClassName},
            {title: lang.TABS.REPORTING_TAB_TITLE, value: TABS.REPORTING, className: getTabLinkClassName},
          ]}
          onTabClick={setActiveTab}
          activeTab={activeTab}
        />
      )}

      <Offscreen hidden={isReporting || activeTab !== TABS.GENERAL} className={b('tab')}>
        <AnimationMainModalGeneralTab readOnly={readOnly} />
      </Offscreen>
      <Offscreen hidden={activeTab !== TABS.REPORTING} className={b('tab')}>
        <AnimationMainModalReportingTab readOnly={readOnly} isReporting={isReporting} />
      </Offscreen>
      {isDeleteAvailable && activeTab === TABS.GENERAL && (
        <>
          <AppButton
            label={lang.DELETE_BUTTON}
            transparent
            iconName="trash"
            iconConfig={{width: 16, height: 16}}
            color="error"
            className={b('delete-button')}
            onClick={toggleIsDeleteModalVisible}
            link
          />
          <ConfirmationModal
            show={isDeleteModalVisible}
            message={lang.DELETE_CONFIRMATION}
            buttonConfirm={{label: lang.YES_BUTTON}}
            buttonCancel={{label: lang.NO_BUTTON}}
            onCancel={toggleIsDeleteModalVisible}
            onClose={toggleIsDeleteModalVisible}
            onConfirm={onDelete}
            clientSide
          />
        </>
      )}
    </Modal>
  );
};

AnimationMainModal.propTypes = {
  animation: AnimationShape,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isReporting: PropTypes.bool,
  onCancelSubmission: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

AnimationMainModal.defaultProps = {
  isReporting: false,
};

export default AnimationMainModal;
