import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {dateToString, dayToString} from 'client/services/helpers';

import './animation-day-cell.scss';

const b = bem('animation-day-cell');

const AnimationDayCell = (props) => {
  const {className, item} = props;

  return (
    <p className={cn(b(), className)}>
      <span className={b('date')}>{dateToString(item.from, 'DD/MM/YY')}</span>
      <span className={b('day')}>{dayToString(item.from)}</span>
    </p>
  );
};

AnimationDayCell.propTypes = {
  item: PropTypes.shape({
    from: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

AnimationDayCell.defaultProps = {
  className: '',
};

export default AnimationDayCell;
