import React from 'react';

import {Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';

export default function InterfaceCell({data, rowIndex}) {
  const item = data[rowIndex];

  return (
    <Cell>
      <span>{item.level && `${item.level.number} - ${item.level.level_name}`}</span>
    </Cell>
  );
}

InterfaceCell.propTypes = {
  data: PropTypes.array.isRequired,
  rowIndex: PropTypes.number,
};
