import find from 'lodash/find';

import {EmailTemplateKind, EmailTemplate, EmailTemplateVariable} from 'client/models/email-templates/types';

type NewEmailTemplateVariable = Partial<EmailTemplateVariable>;

export const getNewVariables = (
  addedVariables: string[],
  kind?: EmailTemplateKind | null,
  template?: EmailTemplate | null,
): NewEmailTemplateVariable[] => {
  const result: NewEmailTemplateVariable[] = [];

  if (!kind || !template) {
    return result;
  }

  addedVariables.forEach((name) => {
    if (!find(template.email_template_variables, {name})) {
      const kindVariable = find(kind.email_template_kind_variables, {name});
      result.push({
        email_template_id: template.id,
        message_template_kind_variable_id: kindVariable?.id,
        name,
        test_value: kindVariable?.test_value,
        column_adapter_id: kindVariable?.column_adapter_id,
        visible_by_client: true,
      });
    }
  });

  return result;
};
