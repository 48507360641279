import React from 'react';

import {Field} from 'redux-form';

import BaseFieldComponent from 'client/common/fields/base-field';
import {TextInput} from 'client/common/inputs';

const InputComponent = (props) => <BaseFieldComponent Component={TextInput} {...props} />;

const TextField = (props) => {
  return <Field component={InputComponent} {...props} />;
};

export default TextField;
