import React, {useEffect} from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectClientAccessLevel, selectCurrentMembership} from 'client/ducks/user-clients/selectors';

import {USER_MEMBERSHIP} from 'client/common/config';
import {RadioGroupField, TextField} from 'client/common/fields';
import {RadioGroupInput} from 'client/common/inputs';
import WarningLabel from 'client/common/typography/warning-label';

import {ClientUserModalFormName} from 'client/components/client-account-parameters/modals/client-user-modal';
import {ClientUserModalForm} from 'client/components/client-account-parameters/modals/client-user-modal/types';

import ClientUserTitle from '../client-user-title';

import cssModule from './client-user-agency-membership-step.module.scss';

const b = bem('client-user-agency-membership-step', {cssModule});

const ClientUserAgencyMembershipStep: React.FC = () => {
  const lang = useLanguage('CLIENT_ACCOUNT_PARAMETERS.MODALS.CLIENT_USER');

  const {formValues, change} = useReduxForm<ClientUserModalForm>(ClientUserModalFormName);

  const accessLevel = useSelector(selectClientAccessLevel);
  const isClientAdmin = accessLevel === USER_MEMBERSHIP.CLIENT_ADMIN;

  const currentMembership = useSelector(selectCurrentMembership);
  const membership = formValues.memberships?.[0];
  const isCurrentUser = currentMembership?.id && membership?.id === currentMembership?.id;

  useEffect(() => {
    if (!formValues.memberships[0].access_level) {
      change('memberships[0].access_level', isClientAdmin ? USER_MEMBERSHIP.CLIENT_ADMIN : USER_MEMBERSHIP.NATIONAL);
      if (!isClientAdmin) {
        change('memberships[0].all_companies_access', true);
      }
    }
  }, [change, formValues.memberships, isClientAdmin]);

  const handleChangeAccessLevel: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value === USER_MEMBERSHIP.CLIENT_ADMIN) {
      change('memberships[0].all_companies_access', null);
    } else {
      change('memberships[0].all_companies_access', true);
    }
  };

  return (
    <div className={b()}>
      <ClientUserTitle
        email={formValues.email}
        civility={formValues.title}
        firstName={formValues.first_name}
        lastName={formValues.last_name}
      />
      <TextField label={lang.TITLE_LABEL} name="memberships[0].title" />
      <TextField label={lang.PHONE_LABEL} name="memberships[0].phone" />
      <p className={b('block-title')}>
        {lang.ACCESS_LEVEL}
        {isCurrentUser && <WarningLabel label={lang.ERRORS.CANT_MODIFY} />}
      </p>
      <RadioGroupField
        className={b('options')}
        name="memberships[0].access_level"
        disabled={isCurrentUser}
        onChange={handleChangeAccessLevel}
        options={[
          {
            exclude: !isClientAdmin,
            label: lang.CLIENT_ADMIN_LEVEL,
            value: USER_MEMBERSHIP.CLIENT_ADMIN,
          },
          {
            label: lang.NATIONAL_LEVEL,
            value: USER_MEMBERSHIP.NATIONAL,
          },
        ]}
      />
      {formValues.memberships[0].access_level === USER_MEMBERSHIP.NATIONAL && (
        <>
          <p className={b('block-title')}>{lang.ACCESS_TO_CLIENTS}</p>
          <RadioGroupInput
            value={Boolean(formValues.memberships[0].all_companies_access).toString()}
            onChange={(event) => {
              change('memberships[0].all_companies_access', event.target.value === 'true');
            }}
            className={b('options')}
            disabled={isCurrentUser}
            name="memberships[0].all_companies_access"
            options={[
              {
                label: lang.ALL_CLIENTS_LABEL,
                value: 'true',
              },
              {
                label: lang.SOME_CLIENTS_LABEL,
                value: 'false',
              },
            ]}
          />
        </>
      )}
    </div>
  );
};

export default ClientUserAgencyMembershipStep;
