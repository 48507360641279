import React, {ChangeEvent, useEffect, useState, KeyboardEvent, useMemo, MouseEvent} from 'react';

import cn from 'classnames';
import {ColorState} from 'react-color';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {getItem, setItem} from 'client/services/sessionStorage';

import AppButton from 'client/common/buttons';
import Icon from 'client/common/icon';
import {NumberInput, TextInput, ToggleInput} from 'client/common/inputs';
import ColorPickerSimple from 'client/common/inputs/color-picker-simple';
import LayoutPanel from 'client/common/panels/layout-panel';
import Popover from 'client/common/popovers/popover';

import {ImageBlockData} from 'client/components/email-template-editor/types';
import {Translation} from 'client/models/language/types';

import {ButtonGroup} from '../button-group';
import {ImageParams} from '../index';

import cssModule from './toolbar.module.scss';

const b = bem('toolbar', {cssModule});
const DEFAULT_BG_COLOR = '#FFFFFF';

type ToolbarProps = {
  imageBlockData: ImageBlockData;
  canUndo: boolean;
  canRedo: boolean;
  colorsAccessKey: string;
  onChooseImage: () => void;
  onImageChange: (action: string, value?: string | number | Record<string, string | number>) => void;
  onParamsChange: (value: ImageParams) => void;
  onCropToggle: (state: boolean) => void;
  onCropAspectRatioChange: (value: number) => void;
  onUndo: () => void;
  onRedo: () => void;
};
type ImageBlockDataKeys = keyof ImageBlockData;

type CropAspectRatioOption = {
  title: Translation;
  value: number | null;
};
const defaultCropAspectRatioOptions: CropAspectRatioOption[] = [
  {title: '1:1', value: 1},
  {title: '3:2', value: 3 / 2},
  {title: '4:3', value: 4 / 3},
  {title: '16:9', value: 16 / 9},
];

export const Toolbar: React.FC<ToolbarProps> = (props) => {
  const {
    imageBlockData,
    canUndo,
    canRedo,
    colorsAccessKey,
    onImageChange,
    onParamsChange,
    onCropToggle,
    onCropAspectRatioChange,
    onUndo,
    onRedo,
    onChooseImage,
  } = props;
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.MODALS.IMAGE_EDIT.TOOLBAR');
  const [imageWidth, setImageWidth] = useState(imageBlockData.width || 0);
  const [imageHeight, setImageHeight] = useState(imageBlockData.height || 0);
  const [isImageAspectRatioLocked, toggleIsImageAspectRatioLocked] = useToggle(
    Boolean(imageBlockData.isAspectRatioLocked),
  );
  const [imageAspectRatio, setImageAspectRatio] = useState(imageWidth / imageHeight);
  const [backgroundColor, setBackgroundColor] = useState(imageBlockData.backgroundColor || DEFAULT_BG_COLOR);
  const [currentCropAspectRatioOption, setCurrentCropAspectRatioOption] = useState<CropAspectRatioOption | null>(null);
  const cropAspectRatioOptions = useMemo(() => {
    const options = [...defaultCropAspectRatioOptions];
    if (imageBlockData.width && imageBlockData.height) {
      options.unshift({
        title: lang.ORIGINAL,
        value: imageBlockData.width / imageBlockData.height,
      });
    }
    return options;
  }, [imageBlockData.height, imageBlockData.width, lang.ORIGINAL]);

  useEffect(() => {
    const width = imageBlockData.width || 0;
    const height = imageBlockData.height || 0;
    setImageWidth(width);
    setImageHeight(height);
    if (width && height) {
      setImageAspectRatio(width / height);
    }
  }, [imageBlockData.width, imageBlockData.height]);

  const handleSizeChange = () => {
    onImageChange('size', {
      width: imageWidth,
      height: imageHeight,
    });
  };

  const handleWidthChange = (e: ChangeEvent<HTMLInputElement>) => {
    const width = Math.max(Math.min(+e.target.value, imageBlockData.width ?? 0), 1);

    setImageWidth(width);
    if (isImageAspectRatioLocked) {
      setImageHeight(Math.floor(width / imageAspectRatio));
    }
  };

  const handleHeightChange = (e: ChangeEvent<HTMLInputElement>) => {
    const height = Math.max(Math.min(+e.target.value, imageBlockData.height ?? 0), 1);

    setImageHeight(height);
    if (isImageAspectRatioLocked) {
      setImageWidth(Math.floor(height * imageAspectRatio));
    }
  };

  const handleIsImageAspectRatioLockedChange = () => {
    if (!isImageAspectRatioLocked && imageWidth && imageHeight) {
      setImageAspectRatio(imageWidth / imageHeight);
    }
    toggleIsImageAspectRatioLocked();
  };

  const handlePaddingChange = (key: ImageBlockDataKeys, value: number) => {
    onParamsChange({
      [key]: Math.max(0, value),
    });
  };

  const handleBgColorChange = ({hex}: ColorState) => {
    onParamsChange({
      backgroundColor: hex,
    });
    setBackgroundColor(hex);
    const colors = getItem(colorsAccessKey) || [];
    if (!colors.includes(hex)) {
      colors.push(hex);
      setItem(colorsAccessKey, colors.slice(-5));
    }
  };
  const handleBgColorInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBackgroundColor(e.target.value.trim());
  };
  const handleBgColorInputBlur = () => {
    let newValue = imageBlockData.backgroundColor || DEFAULT_BG_COLOR;
    if (backgroundColor.match(/^#[0-9A-F]{6}$/i)) {
      newValue = backgroundColor;
    } else {
      setBackgroundColor(newValue);
    }
    onParamsChange({
      backgroundColor: newValue,
    });
  };
  const handleBgColorInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  const handleCropStart = () => {
    onCropToggle(true);
  };

  const handleCropAspectRatioChange = (e: MouseEvent<HTMLButtonElement>, option: CropAspectRatioOption) => {
    e.nativeEvent.stopImmediatePropagation();
    onCropAspectRatioChange(Number(option.value));
    setCurrentCropAspectRatioOption(option);
  };

  return (
    <LayoutPanel className={b()}>
      <div className={b('buttons-row')}>
        <ButtonGroup
          buttons={[
            {
              icon: 'undo',
              tooltip: lang.UNDO,
              onClick: onUndo,
              disabled: !canUndo,
            },
            {
              icon: 'redo',
              tooltip: lang.REDO,
              onClick: onRedo,
              disabled: !canRedo,
            },
          ]}
        />
      </div>
      <div className={b('buttons-row')}>
        <ButtonGroup
          buttons={[
            {
              icon: 'align-left',
              tooltip: lang.LEFT,
              isActive: imageBlockData.alignment === 'left',
              onClick: () => onParamsChange({alignment: 'left'}),
            },
            {
              icon: 'align-center',
              tooltip: lang.CENTER,
              isActive: imageBlockData.alignment === 'center',
              onClick: () => onParamsChange({alignment: 'center'}),
            },
            {
              icon: 'align-right',
              tooltip: lang.RIGHT,
              isActive: imageBlockData.alignment === 'right',
              onClick: () => onParamsChange({alignment: 'right'}),
            },
          ]}
        />

        <AppButton
          className={b('choose-image-btn')}
          iconName="image-2"
          label={lang.CHOOSE_IMAGE}
          asWrap={true}
          onClick={onChooseImage}
        />
      </div>
      <div className={b('section-divider')} />
      <div>
        <h3 className={b('section-label')}>{lang.EDIT_IMAGE}</h3>
        <div className={b('controls-line')}>
          <div className={b('input-pair')}>
            <label className={b('input-container')}>
              <span className={b('input-label')}>{lang.WIDTH}</span>
              <NumberInput inputClassName={b('input-field')} value={imageWidth} onChange={handleWidthChange} min={1} />
            </label>
            <div>
              <Popover overlay={lang.LOCK_ASPECT_RATIO} className={b('tooltip')} position="top" hideOnMouseLeave>
                <AppButton
                  className={b('input-pair-lock')}
                  iconName={isImageAspectRatioLocked ? 'lock-2' : 'lock-2-unlocked'}
                  onClick={handleIsImageAspectRatioLockedChange}
                  asWrap={true}
                />
              </Popover>
            </div>
            <label className={b('input-container')}>
              <span className={b('input-label')}>{lang.HEIGHT}</span>
              <NumberInput
                inputClassName={b('input-field')}
                value={imageHeight}
                onChange={handleHeightChange}
                min={1}
              />
            </label>
            <AppButton className={b('apply-size-change')} iconName="check" asWrap={true} onClick={handleSizeChange} />
          </div>
          <div className={b('horizontal-divider')} />
          <div className={b('crop-and-rotate-wrap')}>
            <span className={b('input-label')}>{lang.CROP_AND_ROTATE}</span>
            <div className={b('crop-and-rotate')}>
              <div className={b('crop-wrap')}>
                <Popover overlay={lang.CROP} className={b('tooltip')} position="top" hideOnMouseLeave>
                  <Icon name="crop" />
                </Popover>
                <Popover
                  overlay={
                    <ul className={b('crop-aspect-ratio-select')}>
                      {cropAspectRatioOptions.map((el) => (
                        <li
                          key={String(el.title)}
                          className={cn(
                            b('crop-aspect-ratio-select-option'),
                            b('crop-aspect-ratio-select-option', {'is-active': el === currentCropAspectRatioOption}),
                          )}
                        >
                          <AppButton
                            className={b('crop-aspect-ratio-select-option-btn')}
                            label={el.title}
                            onMouseDown={(e: MouseEvent<HTMLButtonElement>) => handleCropAspectRatioChange(e, el)}
                            asWrap={true}
                            tabIndex={-1}
                          />
                        </li>
                      ))}
                    </ul>
                  }
                  contentClassName={b('crop-aspect-ratio-select-wrap')}
                  trigger="click"
                  hideOnMouseLeave
                >
                  <AppButton
                    className={b('crop-btn')}
                    label={<Icon name="arrow-down" />}
                    asWrap={true}
                    onClick={() => handleCropStart()}
                  />
                </Popover>
              </div>
              <Popover overlay={lang.FLIP_HORIZONTALLY} className={b('tooltip')} position="top" hideOnMouseLeave>
                <AppButton iconName="flip-h" asWrap={true} onClick={() => onImageChange('flip-h')} />
              </Popover>
              <Popover overlay={lang.FLIP_VERTICALLY} className={b('tooltip')} position="top" hideOnMouseLeave>
                <AppButton iconName="flip-v" asWrap={true} onClick={() => onImageChange('flip-v')} />
              </Popover>
              <Popover overlay={lang.ROTATE_LEFT} className={b('tooltip')} position="top" hideOnMouseLeave>
                <AppButton iconName="rotate-left" asWrap={true} onClick={() => onImageChange('rotate-l')} />
              </Popover>
              <Popover overlay={lang.ROTATE_RIGHT} className={b('tooltip')} position="top" hideOnMouseLeave>
                <AppButton iconName="rotate-right" asWrap={true} onClick={() => onImageChange('rotate-r')} />
              </Popover>
            </div>
          </div>
        </div>
      </div>
      <div className={b('section-divider')} />
      <div>
        <h3 className={b('section-label')}>{lang.PADDING}</h3>
        <div className={b('controls-line')}>
          <div className={b('input-pair')}>
            <label className={b('input-container')}>
              <span className={b('input-label')}>{lang.LEFT}</span>
              <NumberInput
                inputClassName={b('input-field')}
                value={imageBlockData.paddingLeft ?? 0}
                onChange={(e) => handlePaddingChange('paddingLeft', +e.target.value)}
              />
            </label>
            <div>
              <Popover overlay={lang.LOCK} className={b('tooltip')} position="top" hideOnMouseLeave>
                <AppButton
                  className={b('input-pair-lock')}
                  iconName={imageBlockData.isHorizontalPaddingsPaired ? 'lock-2' : 'lock-2-unlocked'}
                  onClick={() => {
                    onParamsChange({isHorizontalPaddingsPaired: !imageBlockData.isHorizontalPaddingsPaired});
                  }}
                  asWrap={true}
                />
              </Popover>
            </div>
            <label className={b('input-container')}>
              <span className={b('input-label')}>{lang.RIGHT}</span>
              <NumberInput
                inputClassName={b('input-field')}
                value={imageBlockData.paddingRight ?? 0}
                onChange={(e) => handlePaddingChange('paddingRight', +e.target.value)}
              />
            </label>
          </div>
          <div className={b('horizontal-divider')} />
          <div className={b('input-pair')}>
            <label className={b('input-container')}>
              <span className={b('input-label')}>{lang.TOP}</span>
              <NumberInput
                inputClassName={b('input-field')}
                value={imageBlockData.paddingTop ?? 0}
                onChange={(e) => handlePaddingChange('paddingTop', +e.target.value)}
              />
            </label>
            <div>
              <Popover overlay={lang.LOCK} className={b('tooltip')} position="top" hideOnMouseLeave>
                <AppButton
                  className={b('input-pair-lock')}
                  iconName={imageBlockData.isVerticalPaddingsPaired ? 'lock-2' : 'lock-2-unlocked'}
                  onClick={() => onParamsChange({isVerticalPaddingsPaired: !imageBlockData.isVerticalPaddingsPaired})}
                  asWrap={true}
                />
              </Popover>
            </div>
            <label className={b('input-container')}>
              <span className={b('input-label')}>{lang.BOTTOM}</span>
              <NumberInput
                inputClassName={b('input-field')}
                value={imageBlockData.paddingBottom ?? 0}
                onChange={(e) => handlePaddingChange('paddingBottom', +e.target.value)}
              />
            </label>
          </div>
        </div>
      </div>
      <div className={b('section-divider')} />
      <div className={b('bg-color-section')}>
        <h3 className={cn(b('section-label'), b('bg-color-section-heading'))}>{lang.BACKGROUND_COLOR}</h3>
        <div className={b('bg-color-picker-wrap')}>
          <ColorPickerSimple
            value={imageBlockData.backgroundColor || DEFAULT_BG_COLOR}
            onChange={handleBgColorChange}
            colorSwatches={getItem(colorsAccessKey)}
            position="top"
            hideInput={true}
          >
            <div
              className={b('bg-color-picker-inner')}
              style={{backgroundColor: imageBlockData.backgroundColor || DEFAULT_BG_COLOR}}
            />
          </ColorPickerSimple>
        </div>
        <TextInput
          onChange={handleBgColorInputChange}
          onBlur={handleBgColorInputBlur}
          onKeyDown={handleBgColorInputKeyDown}
          value={backgroundColor}
          className={b('bg-color-input-wrap')}
          inputClassName={b('bg-color-input')}
        />
      </div>
      <div className={b('section-divider')} />
      <div>
        <h3 className={b('section-label')}>{lang.SCALE_UP_HEADING}</h3>
        <div className={b('fluid-on-mobile')}>
          <ToggleInput
            rounded={true}
            borderColor="current"
            color="primary"
            checked={imageBlockData.fluidOnMobile}
            onChange={() => onParamsChange({fluidOnMobile: !imageBlockData.fluidOnMobile})}
          />
          <span>{lang.SCALE_UP_TEXT}</span>
        </div>
      </div>
    </LayoutPanel>
  );
};
