import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {CLIENT_PAGES} from 'client/common/config';

import Icon from 'client/components/common/icon';

import ProfilePanel from '../profile-panel';

import './profile-policies.scss';

const renderLink = (title, link) => {
  return (
    <div className="profile-policies__link" onClick={() => window.open(link, '_blank')}>
      <span className="profile-policies__link-text">{title}</span>
      <Icon name="arrow-right" className="profile-policies__link-icon" />
    </div>
  );
};

const ProfilePolicies = (props) => {
  const {lang} = props;

  return (
    <ProfilePanel title={lang.TITLE}>
      <div className="profile-policies">
        <div className="profile-policies__info">
          <div className="profile-policies__info-line">{lang.AWARE_TEXT}</div>
          <div className="profile-policies__info-line">{lang.MANAGE_TEXT}</div>
        </div>
        <div className="profile-policies__links">
          {renderLink(lang.PRIVACY_POLICY, CLIENT_PAGES.PRIVACY_POLICY)}
          {renderLink(lang.COOLIES_POLICY, CLIENT_PAGES.COOKIES_POLICY)}
          {renderLink(lang.TERMS, CLIENT_PAGES.TERMS)}
        </div>
      </div>
    </ProfilePanel>
  );
};

ProfilePolicies.propTypes = {
  lang: PropTypes.object.isRequired,
};

export default connect((state) => ({
  lang: state.languageState.payload.PROFILE.PROFILE_POLICIES,
}))(ProfilePolicies);
