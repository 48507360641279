import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {set} from 'client/common/meta/meta.actions';

class Terms extends Component {
  constructor(props) {
    super(props);
    this.LANGUAGE = props.languageState.payload.TERMS;
  }

  componentDidMount() {
    const title = this.LANGUAGE.PAGE_TITLE;
    this.props.setMetaAction({title});
  }

  render() {
    return <div>{this.LANGUAGE.CONTENT}</div>;
  }
}

Terms.propTypes = {
  languageState: PropTypes.object,
  setMetaAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
