import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';
import LayoutPanel from 'client/common/panels/layout-panel';

import cssModule from './catalog-modules-no-result-panel.module.scss';

const b = bem('catalog-modules-no-result-panel', {cssModule});

const CatalogModulesNoResultPanel = () => {
  const lang = useLanguage('CATALOG_MODULES.PANELS.CATALOG_MODULES_NO_RESULT_PANEL');

  return (
    <LayoutPanel className={b()}>
      <Icon name="home-page--filled" className={b('icon')} />
      <p className={b('no-records')}>{lang.NO_RESULT_TEXT}</p>
    </LayoutPanel>
  );
};

export default CatalogModulesNoResultPanel;
