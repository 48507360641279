import types from './types';

const initialState = {
  mappingItems: null,
  columns: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_OPT_IN_MAPPING_ITEMS_SUCCESS:
      return {
        ...state,
        mappingItems: action?.payload?.opt_in_mapping_items,
      };

    case types.GET_OPT_IN_COLUMNS_SUCCESS:
      return {
        ...state,
        columns: action?.payload?.opt_in_columns,
      };

    default:
      return state;
  }
}
