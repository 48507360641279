import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getOnlineUsersStatistic} from 'client/ducks/leads-list/actions';
import {selectOnlineUsersStatistic} from 'client/ducks/leads-list/selectors';

import DashboardCard from 'client/components/common/cards/dashboard-card';
import OnlineUsersChart from 'client/components/common/charts-custom/online-users-chart';

import './client-online-users.scss';

class ClientOnlineUsers extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    autotask: PropTypes.object.isRequired,
    onlineUsersStatistic: PropTypes.object.isRequired,
    getOnlineUsersStatistic: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
  };

  componentDidMount() {
    this.fetchOnlineUsersStatistic();
  }

  componentDidUpdate(prevProps) {
    if (this.props.autotask.id !== prevProps.autotask.id) {
      this.fetchOnlineUsersStatistic();
    }
  }

  toggleLoading = () => this.setState(({loading}) => ({loading: !loading}));

  fetchOnlineUsersStatistic = () => {
    this.toggleLoading();
    return this.props.getOnlineUsersStatistic({automation_task_id: this.props.autotask.id}).then(this.toggleLoading);
  };

  render() {
    const {lang, autotask, onlineUsersStatistic} = this.props;
    const {loading} = this.state;
    const isEmpty = !autotask.started_interactions_count;

    return (
      <DashboardCard
        className="client-online-users"
        title={lang.TITLE}
        iconName="leads-online-users-colored"
        empty={isEmpty}
        emptyText={lang.NOT_AVAILABLE}
        emptyHeight={255}
        loading={loading}
        loadingColor="leads"
      >
        <OnlineUsersChart data={onlineUsersStatistic} />
      </DashboardCard>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.languageState.payload.CLIENT_ONLINE_USERS,
    onlineUsersStatistic: selectOnlineUsersStatistic(state),
  }),
  {
    getOnlineUsersStatistic,
  },
)(ClientOnlineUsers);
