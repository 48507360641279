export function mapUrlFilter(data) {
  const filter = [];

  if (data.poc_admin_user_id && data.poc_admin_user_id.value !== '0') {
    filter.push(`q[poc_admin_user_id_eq]=${data.poc_admin_user_id.value}`);
  }

  if (data.no_operations && data.no_operations.value !== '0' && data.no_operations_num) {
    filter.push(`q[operations_count_${data.no_operations.value}]=${data.no_operations_num}`);
  }

  if (data.type) {
    filter.push(`q[type_eq]=${data.type}`);
  }

  if (data.stores) {
    filter.push(data.stores === 'true' ? 'q[places_count_gt]=0' : 'q[places_count_eq]=0');
  }

  if (data.comments) {
    filter.push(`q[comment_present]=${data.comments}`);
  }

  return filter.join('&');
}

export function mapNewClientUsers(obj) {
  const mappedObj = obj.length
    ? obj.map(({client_user: {full_name, email}, id}) => ({
        id,
        full_name,
        email,
        checked: false,
      }))
    : [];

  return mappedObj;
}

export function mapClientData(clients) {
  return clients.map(
    ({
      id,
      name,
      poc_admin_user,
      poc_membership,
      operations,
      places_count,
      operations_count,
      type,
      agency_id,
      comment,
      agency,
      companies,
    }) => {
      let status = '';
      if (type === 'Agency') {
        status = 'Agency';
      } else if (agency_id !== null) {
        status = 'Client of agency';
      }
      return {
        id,
        name,
        poc_admin_user_full_name: poc_admin_user ? poc_admin_user.full_name : '',
        postFromClient: poc_membership ? poc_membership.title : '',
        operations: operations,
        stores: places_count,
        operations_count,
        status,
        type: status,
        comment,
        agency,
        companies,
      };
    },
  );
}
