import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {selectLanguageDomain} from 'client/ducks/language/selectors';

import Popover from 'client/common/popovers/popover';

import Icon from 'client/components/common/icon';

function DataRetentionTooltip(props) {
  return (
    <Popover
      position="right"
      triggerClassName={props.className}
      overlayInnerStyle={{
        width: 200,
      }}
      overlay={<span id="popover-positioned-scrolling-right">{props.languageState.DATA_RETENTION_NOTE}</span>}
    >
      <Icon name="question" />
    </Popover>
  );
}

DataRetentionTooltip.propTypes = {
  languageState: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default connect((state) => ({
  languageState: selectLanguageDomain(state, 'OPERATIONS'),
}))(DataRetentionTooltip);
