const UNITS = {
  width: 'px',
  height: 'px',
  size: 'Mb',
};

export const getImageRequirementsLabel = (requirements, lang) => {
  return (
    Object.entries(requirements)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, value]) => Boolean(Array.isArray(value) ? value.length : value))
      .map(([requirement, value]) => `${lang[requirement.toUpperCase()]}: ${value}${UNITS[requirement] || ''}`)
      .join('\n')
  );
};
