export const VIS_DATA_COLUMNS = [
  {
    id: 'file_preview',
    name: 'file',
  },
  {
    id: 'type',
    name: 'kind',
  },
  {
    id: 'file_extension',
    name: 'file_extension',
  },
  {
    id: 'created_at',
    name: 'created_at',
  },
  {
    id: 'arriving_type',
    name: 'arriving_type',
  },
  {
    id: 'lead',
    name: 'lead',
  },
  {
    id: 'tags',
    name: 'tags',
  },
  {
    id: 'title',
    name: 'title',
  },
  {
    id: 'author',
    name: 'author',
  },
  {
    id: 'author_avatar',
    name: 'author_avatar',
  },
  {
    id: 'likes_count',
    name: 'likes_count',
  },
  {
    id: 'orientation',
    name: 'orientation',
  },
  {
    id: 'filesize',
    name: 'filesize',
  },
  {
    id: 'expires_at',
    name: 'expires_at',
  },
];
