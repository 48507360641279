import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {formatFloat} from 'client/services/formatters';

import {getLeadsStatistic} from 'client/ducks/leads-list/actions';
import {selectLeadsStatistic} from 'client/ducks/leads-list/selectors';

import ContactBars from 'client/components/common/charts-custom/contact-bars';
import GenderDonutChart from 'client/components/common/charts-custom/gender-donut-chart';

import ParticipationTabCard from '../participation-tab-card';
import ParticipationTabNotAvailable from '../participation-tab-not-available';

import './client-leads.scss';

class ClientLeads extends PureComponent {
  static propTypes = {
    autotask: PropTypes.object.isRequired,
    language: PropTypes.object.isRequired,
    leadsStatistic: PropTypes.object.isRequired,
    getLeadsStatistic: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
  };

  componentDidMount() {
    this.fetchLeadsStatistic();
  }

  componentDidUpdate(prevProps) {
    if (this.props.autotask.id !== prevProps.autotask.id) {
      this.fetchLeadsStatistic();
    }
  }

  toggleLoading = () => this.setState(({loading}) => ({loading: !loading}));

  fetchLeadsStatistic = () => {
    this.toggleLoading();
    return this.props.getLeadsStatistic({automation_task_id: this.props.autotask.id}).then(this.toggleLoading);
  };

  render() {
    const {language, leadsStatistic, autotask} = this.props;
    const {loading} = this.state;

    const notAvailable = !autotask.started_interactions_count;

    return (
      <div className="client-leads">
        <ParticipationTabCard title={language.TITLE} iconName="leads-card" loading={loading} loadingColor="leads">
          {notAvailable ? (
            <div className="client-leads__content">
              <ParticipationTabNotAvailable text={language.NOT_AVAILABLE} />
            </div>
          ) : (
            <div className="client-leads__content">
              <div className="client-leads__column-left">
                <GenderDonutChart title={language.GENDER} data={leadsStatistic.gender} />
              </div>
              <div className="client-leads__column-right">
                <div className="client-leads__conversion">
                  <span className="client-leads__conversion-value">
                    {formatFloat(leadsStatistic.participations_conversion)}
                  </span>
                  <span className="client-leads__conversion-title">{language.AVERAGE_PARTICIPATIONS}</span>
                </div>
                <ContactBars
                  title={language.COORDINATIONS}
                  data={{
                    total: leadsStatistic.total,
                    ...leadsStatistic.coordinations,
                  }}
                />
              </div>
            </div>
          )}
        </ParticipationTabCard>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    language: state.languageState.payload.CLIENT_AUTOTASK_LEADS,
    leadsStatistic: selectLeadsStatistic(state),
  }),
  {
    getLeadsStatistic,
  },
)(ClientLeads);
