import {SCREEN_FORMATS} from 'client/components/diy-operation/modals/diy-customization-modal/constants';
import {ClientTemplate} from 'client/models/templates/types';
import {ObjectValues} from 'client/types/common/index';

export type StepType = ObjectValues<typeof STEPS>;

export const STEPS = {
  GENERAL: 'general',
  FORM: 'form',
  GAME: 'game',
  QUIZ: 'quiz',
  SUMMARY: 'summary',
} as const;

export const filterTemplatesByLangs = (clientTemplates: ClientTemplate[]) => {
  const result: string[] = [];
  clientTemplates.forEach((template, index, templates) => {
    template.languages.forEach((lng) => {
      if (!result.includes(lng) && templates.every((tmpl) => tmpl?.languages.includes(lng))) {
        result.push(lng);
      }
    });
  });
  return result;
};

export const mapBroadcasts = (formats: string[]) => {
  return {
    online: formats.includes(SCREEN_FORMATS.desktop) && formats.includes(SCREEN_FORMATS.desktop),
    vertical: formats.includes(SCREEN_FORMATS.kiosk_vertical),
    horizontal: formats.includes(SCREEN_FORMATS.kiosk_horizontal),
  };
};

export const unionBroadcasts = (templates: ClientTemplate[]) => {
  if (!templates) {
    return {};
  }
  return {
    online: templates.every(
      ({image_formats: formats}) =>
        formats.includes(SCREEN_FORMATS.desktop) && formats.includes(SCREEN_FORMATS.desktop),
    ),
    vertical: templates.every(({image_formats: formats}) => formats.includes(SCREEN_FORMATS.kiosk_vertical)),
    horizontal: templates.every(({image_formats: formats}) => formats.includes(SCREEN_FORMATS.kiosk_horizontal)),
  };
};
