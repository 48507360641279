import {Reducer} from 'redux';

import {DndData, TemplateData} from 'client/components/email-template-editor/types';
import {EmailSender} from 'client/models/email-senders/types';
import {EmailTemplate, EmailTemplateImage, EmailTemplateKind} from 'client/models/email-templates/types';
import {Prize} from 'client/models/prizes/types';

import {
  addArrayEl,
  addContentBlock,
  updateContentBlock,
  deleteContentBlock,
  moveArrayEl,
  moveContentBlock,
  removeArrayEl,
  updateSection,
  updateEmailParams,
} from './mappers';
import types from './types';

type State = {
  operationEmailTemplates: {
    data: EmailTemplate[];
    meta?: {
      current_page: number;
      next_page: number;
      prev_page?: number;
      total_count: number;
      total_pages: number;
    };
  };
  masterEmailTemplates: EmailTemplate[];
  emailTemplate: EmailTemplate | null;
  editor: {
    doc: TemplateData;
    dndData: DndData;
    activeBlockId: string | null;
  };
  emailTemplateKinds: EmailTemplateKind[];
  activeTemplate: EmailTemplate | null;
  activeTemplateKind: EmailTemplateKind | null;
  prizes: Prize[];
  emailSenders: EmailSender[];
  addedVariables: string[];
  emailTemplateImagesForDelete: EmailTemplateImage[];
  galleryEmailTemplates: EmailTemplate[];
};

const initialState: State = {
  operationEmailTemplates: {data: []},
  masterEmailTemplates: [],
  emailTemplate: null,
  // meta: {},
  emailTemplateKinds: [],
  activeTemplate: null,
  activeTemplateKind: null,
  prizes: [],
  emailSenders: [],
  addedVariables: [],
  editor: {
    doc: {
      // head: [],
      body: [],
    },
    dndData: {
      id: null,
      type: null,
    },
    activeBlockId: null,
  },
  emailTemplateImagesForDelete: [],
  galleryEmailTemplates: [],
};

const reducer: Reducer<State> = (_state = initialState, action) => {
  const state = {..._state};

  switch (action.type) {
    case types.GET_OPERATION_EMAIL_TEMPLATES_SUCCESS:
      state.operationEmailTemplates.data = action.payload.operation_email_templates;
      state.operationEmailTemplates.meta = action.payload.meta;
      return state;

    case types.GET_EMAIL_TEMPLATE_SUCCESS:
    case types.PATCH_EMAIL_TEMPLATE_SUCCESS:
      state.emailTemplate = action.payload.operation_email_template;
      return state;

    case types.FETCH_EDITED_EMAIL_TEMPLATE_SUCCESS:
      state.emailTemplate = {
        ...state.emailTemplate,
        ...action.payload.operation_email_template,
      };
      return state;
    case types.GET_EMAIL_TEMPLATE_KINDS_SUCCESS:
      state.emailTemplateKinds = action.payload.email_template_kinds;
      return state;
    case types.GET_PRIZES_SUCCESS:
      state.prizes = action.payload.prizes;
      return state;
    case types.GET_EMAIL_SENDERS_SUCCESS:
      state.emailSenders = action.payload.email_senders;
      return state;
    case types.UPDATE_EMAIL_TEMPLATE_PARAMETERS_SUCCESS:
      if (state.activeTemplate) {
        state.activeTemplate = updateEmailParams(state.activeTemplate, action.payload.operation_email_template);
      }
      return state;

    case types.SET_ACTIVE_EMAIL_TEMPLATE:
      state.activeTemplate = action.payload.template;
      return state;

    case types.SET_ACTIVE_EMAIL_TEMPLATE_KIND:
      state.activeTemplateKind = action.payload.templateKind;
      return state;

    case types.GET_ACTIVE_EMAIL_TEMPLATE_KIND_SUCCESS:
      state.activeTemplateKind = action.payload.email_template_kind;
      return state;

    case types.ADD_EMAIL_TEMPLATE_VARIABLE:
      if (action.payload.variable) {
        state.addedVariables = [...new Set([...state.addedVariables, action.payload.variable])];
      } else {
        state.addedVariables = initialState.addedVariables;
      }
      return state;

    case types.EDITOR_SET_DOC_DATA:
      state.editor = {
        ...state.editor,
        doc: action.payload.doc,
      };
      return state;
    case types.EDITOR_CREATE_SECTION:
      state.editor = {
        ...state.editor,
        doc: {
          ...state.editor.doc,
          body: addArrayEl(state.editor.doc.body, action.payload.section, action.payload.beforeSectionId),
        },
      };
      return state;
    case types.EDITOR_UPDATE_SECTION:
      state.editor = {
        ...state.editor,
        doc: {
          body: updateSection(state.editor.doc.body, action.payload.section),
        },
      };
      return state;
    case types.EDITOR_MOVE_SECTION:
      state.editor = {
        ...state.editor,
        doc: {
          body: moveArrayEl(state.editor.doc.body, action.payload.sectionId, action.payload.afterSectionId),
        },
      };
      return state;
    case types.EDITOR_DELETE_SECTION:
      state.editor = {
        ...state.editor,
        doc: {
          body: removeArrayEl(state.editor.doc.body, action.payload.sectionId),
        },
      };
      return state;
    case types.EDITOR_CREATE_CONTENT_BLOCK:
      state.editor = {
        ...state.editor,
        doc: {
          body: addContentBlock(
            state.editor.doc.body,
            action.payload.columnId,
            action.payload.contentBlock,
            action.payload.afterContentBlockId,
          ),
        },
      };
      return state;
    case types.EDITOR_UPDATE_CONTENT_BLOCK:
      state.editor = {
        ...state.editor,
        doc: {
          body: updateContentBlock(state.editor.doc.body, action.payload.contentBlock),
        },
      };
      return state;
    case types.EDITOR_DELETE_CONTENT_BLOCK:
      state.editor = {
        ...state.editor,
        doc: {
          body: deleteContentBlock(state.editor.doc.body, action.payload.contentBlockId),
        },
      };
      return state;
    case types.EDITOR_MOVE_CONTENT_BLOCK:
      state.editor = {
        ...state.editor,
        doc: {
          body: moveContentBlock(
            state.editor.doc.body,
            action.payload.contentBlockId,
            action.payload.targetColumnId,
            action.payload.afterContentBlockId,
          ),
        },
      };
      return state;
    case types.EDITOR_SET_DND_DATA:
      state.editor.dndData = {
        ...state.editor.dndData,
        ...(action.payload || {}),
      };
      return state;
    case types.EDITOR_SET_ACTIVE_BLOCK_ID:
      state.editor.activeBlockId = action.payload;
      return state;
    case types.GET_MASTER_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        masterEmailTemplates: action.payload.master_email_templates,
      };
    case types.EDITOR_ADD_IMAGE_FOR_DELETE:
      return {
        ...state,
        emailTemplateImagesForDelete: [...(state.emailTemplateImagesForDelete || []), ...action.payload],
      };
    case types.EDITOR_RESET_IMAGE_FOR_DELETE:
      return {
        ...state,
        emailTemplateImagesForDelete: [],
      };
    case types.GET_GALLERY_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        galleryEmailTemplates: action.payload.operation_email_templates,
      };
    default:
      return state;
  }
};

export default reducer;
