import React from 'react';

import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {selectClientAccessLevel, selectCurrentMembership} from 'client/ducks/user-clients/selectors';
import {selectViewMode} from 'client/ducks/user/selectors';

import Icon from 'client/common/icon';
import LayoutPanel from 'client/common/panels/layout-panel';

import ClientDeviceContentCell from 'client/components/devices/components/client-device-content-cell';
import ClientDevicePeriod from 'client/components/devices/components/client-device-period';
import {DeviceAffectation} from 'client/models/device-affectations';
import {Interaction} from 'client/models/interactions/types';
import {generateClientOperationRoute, operationHasConfigTab} from 'client/models/operations/helpers';

import cssModule from './client-device-modal-activity-block.module.scss';

const b = bem('client-device-modal-activity-block', {cssModule});

type ClientDeviceModalActivityBlockProps = {
  affectation: DeviceAffectation;
};

const ClientDeviceModalActivityBlock: React.FC<ClientDeviceModalActivityBlockProps> = ({affectation}) => {
  const lang = useLanguage('CLIENT_DEVICES.MODAL.ACTIVITY_BLOCK');

  const current = affectation.device?.current_interaction;
  const next = affectation.device?.next_interaction;
  const history = useHistory();
  const {clientId} = useParams<{clientId: string}>();
  const {id: viewUserId} = useSelector(selectViewMode);
  const client = useSelector(selectCurrentClient);
  const accessLevel = useSelector(selectClientAccessLevel);
  const clientUserMembership = useSelector(selectCurrentMembership);

  const hasConfigTab = operationHasConfigTab({
    client,
    accessLevel,
    membershipIsEditor: clientUserMembership?.editor,
    viewUserId,
  });

  const goToAutotask = (interaction: Interaction) => {
    if (interaction.operation && interaction.automation_task) {
      const link = generateClientOperationRoute({
        operation: interaction.operation,
        task: interaction.automation_task,
        isConfigurationBlock: hasConfigTab,
        clientId: +clientId,
      });
      if (link) {
        history.push(link);
      }
    }
  };

  const renderInteraction = (interaction: Interaction) => {
    return (
      <button className={b('button')} onClick={() => goToAutotask(interaction)}>
        <LayoutPanel className={b('row')}>
          <ClientDeviceContentCell interaction={interaction} />
          {interaction?.operation && <ClientDevicePeriod {...interaction?.operation} className={b('period')} />}
        </LayoutPanel>
      </button>
    );
  };

  return (
    <div className={b()}>
      {current && renderInteraction(current)}
      {next && renderInteraction(next)}
      {!current && !next && affectation.device?.mediaplayer_active && (
        <LayoutPanel className={b('row', ['media'])}>
          <ClientDeviceContentCell isMedia />
        </LayoutPanel>
      )}
      {!current && !next && !affectation.device?.mediaplayer_active && (
        <div className={b('no-activity')}>
          <Icon name="no_activity" />
          <span>{lang.NO_ACTIVITY}</span>
        </div>
      )}
    </div>
  );
};

export default ClientDeviceModalActivityBlock;
