import React, {useMemo, useRef} from 'react';

import {useSelector} from 'react-redux';
import {useClickAway} from 'react-use';

import bem from 'client/services/bem';

import {selectActiveTemplate} from 'client/ducks/email-templates/selectors';

import TextEditor from 'client/common/text-editor';
import {FONT_FAMILY_OPTIONS} from 'client/common/text-editor/constants';
import FloatingToolbar from 'client/common/text-editor/floating-toolbar';

import useClientFonts from 'client/components/diy-operation/modals/diy-customization-modal/useClientFonts';
import {useEditor} from 'client/components/email-template-editor/hooks/useEditor';
import {ContentBlockData, TextBlock as TextBlockType} from 'client/components/email-template-editor/types';

import cssModule from './text-block.module.scss';

const b = bem('text-block', {cssModule});

type TextBlockProps = {
  contentBlock: TextBlockType;
  onSubmit: (data: ContentBlockData) => void;
};

export const TextBlock: React.FC<TextBlockProps> = (props) => {
  const {contentBlock, onSubmit} = props;
  const {activeBlockId, setActiveBlockId} = useEditor();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const activeTemplate = useSelector(selectActiveTemplate);
  const isToolbarVisible = useMemo(() => {
    return activeBlockId === contentBlock.id;
  }, [activeBlockId, contentBlock.id]);

  const {fontNames = []} = useClientFonts({fetchOnMount: false});
  const fontFamilyOptions = [...fontNames.sort(), ...FONT_FAMILY_OPTIONS];

  useClickAway(wrapperRef, () => {
    if (activeBlockId === contentBlock.id) {
      setActiveBlockId(null);
    }
  });

  const handleToggleEditMode = (state: boolean) => {
    if (state && activeBlockId !== contentBlock.id) {
      setActiveBlockId(contentBlock.id);
    } else if (!state && activeBlockId === contentBlock.id) {
      setActiveBlockId(null);
    }
  };

  const commonColorsAccessKey = `common-colors-email-${activeTemplate?.id}`;

  return (
    <div className={b()} ref={wrapperRef}>
      <TextEditor
        key={activeTemplate?.id}
        isToolbarVisible={isToolbarVisible}
        value={contentBlock.content?.html}
        onToggleEditMode={handleToggleEditMode}
        commonColorsAccessKey={commonColorsAccessKey}
        onChange={(value) => onSubmit({html: value})}
        hasPlaceholder={true}
      >
        <FloatingToolbar colorsAccessKey={commonColorsAccessKey} fontFamilyOptions={fontFamilyOptions} />
      </TextEditor>
    </div>
  );
};
