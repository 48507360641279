import React from 'react';

import {ReactEditor} from 'slate-react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Popover from 'client/common/popovers/popover';
import SelectDropdown from 'client/common/selects/select-dropdown';
import {
  CustomEditor,
  DEFAULT_FONT_SIZE,
  FONT_FAMILY_OPTIONS,
  FONT_SIZE_OPTIONS,
} from 'client/common/text-editor/constants';
import {editorMethods} from 'client/common/text-editor/helpers';

import cssModule from './font-properties-select.module.scss';

const b = bem('font-properties-select', {cssModule});

type FontPropertiesSelectProps = {
  editor: CustomEditor;
  fontFamilyOptions?: string[];
  fontSizeOptions?: number[];
};

const FontPropertiesSelect = (props: FontPropertiesSelectProps) => {
  const {editor, fontFamilyOptions = FONT_FAMILY_OPTIONS, fontSizeOptions = FONT_SIZE_OPTIONS} = props;

  const lang = useLanguage('COMMON.TEXT_EDITOR.LABELS');

  const fontFamilySelectOptions = fontFamilyOptions.map((family) => ({
    value: family,
    label: <span style={{fontFamily: family}}>{family}</span>,
  }));

  const chosenFontFamily = fontFamilySelectOptions.find(
    (option) => editorMethods.getPropertyValue(editor, 'font-family') === option.value,
  );

  const fontSizeSelectOptions = fontSizeOptions.map((size) => ({value: `${size}px`, label: size}));

  return (
    <>
      <Popover
        overlay={lang.FONT_FAMILY}
        position="top"
        contentClassName={b('overlay')}
        triggerClassName={b('popover-trigger')}
        hideOnMouseLeave
        disableFocus
      >
        <SelectDropdown
          options={fontFamilySelectOptions}
          simpleValue
          onChange={(family: string) => {
            editorMethods.applyProperty(editor, 'font-family', family);
            setTimeout(() => {
              ReactEditor.focus(editor);
            }, 100);
          }}
          value={chosenFontFamily?.value || FONT_FAMILY_OPTIONS[0]}
        />
      </Popover>
      <Popover
        overlay={lang.FONT_SIZE}
        position="top"
        contentClassName={b('overlay')}
        triggerClassName={b('popover-trigger')}
        hideOnMouseLeave
        disableFocus
      >
        <SelectDropdown
          options={fontSizeSelectOptions}
          simpleValue
          onChange={(size: string) => {
            editorMethods.applyProperty(editor, 'font-size', size);
            setTimeout(() => {
              ReactEditor.focus(editor);
            }, 100);
          }}
          value={editorMethods.getPropertyValue(editor, 'font-size') || DEFAULT_FONT_SIZE}
        />
      </Popover>
    </>
  );
};

export default FontPropertiesSelect;
