import React, {useCallback} from 'react';

import {useToggle} from 'react-use';

import {useLanguage} from 'client/services/hooks';

import Icon from 'client/components/common/icon';
import InfoBlock from 'client/components/common/info-block';

import {Operation} from 'client/models/operations/types';

import GeneralInformationForm from './general-information-form';

type GeneralInformationCardProps = {
  operation: Operation;
  clientId: number;
  onSave: () => void;
};

const GeneralInformationCard: React.FC<GeneralInformationCardProps> = (props) => {
  const {operation, clientId, onSave} = props;

  const lang = useLanguage('OPERATION_PAGE.GENERAL_INFORMATION');

  const [editMode, toggleEditMode] = useToggle(false);

  const handleSave = useCallback(() => {
    toggleEditMode();
    onSave();
  }, [toggleEditMode, onSave]);

  return (
    <InfoBlock colorTheme="operations">
      <InfoBlock.Title className="info-block--color-operations">{lang.TITLE}</InfoBlock.Title>
      <InfoBlock.Content>
        <InfoBlock.Header className="clearfix">
          {!editMode && (
            <button className="button button--circle button--bg-operations pull-right" onClick={toggleEditMode}>
              <Icon name="edit" className="button__icon" width={20} height={20} />
            </button>
          )}
        </InfoBlock.Header>
        <InfoBlock.Body>
          {operation.id && (
            <GeneralInformationForm
              clientId={clientId}
              edit={editMode}
              onCancel={toggleEditMode}
              onSave={handleSave}
              operation={operation}
            />
          )}
        </InfoBlock.Body>
      </InfoBlock.Content>
    </InfoBlock>
  );
};

export default GeneralInformationCard;
