import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {dateToString, timeToString, prettyJoin} from 'client/services/helpers.js';

import {getFullName, getAge} from './helpers.js';

import LeadsLabel from '../leads-label';

import './leads-basic-info.scss';

const LeadsBasicInfo = ({data, lang}) => {
  return (
    <div className="leads-basic-info">
      <LeadsLabel label={lang.NAME} value={getFullName(data.first_name, data.last_name)} />
      <LeadsLabel label={lang.AGE} value={getAge(data.birth_date)} />
      <LeadsLabel label={lang.PHONE} value={data.phone} />
      <LeadsLabel label={lang.EMAIL} value={data.email} />
      <LeadsLabel
        label={lang.ADDRESS}
        value={
          <span className="leads-basic-info__address">
            {data.address1 && <span>{data.address1}</span>}
            {data.address2 && <span>{data.address2}</span>}
            {(data.zip || data.city) && <span>{prettyJoin([data.zip, data.city])}</span>}
            {data.country && <span>{data.country}</span>}
          </span>
        }
      />
      <LeadsLabel
        label={lang.CREATED}
        value={
          <span className="leads-basic-info__created">
            <span>{dateToString(data.created)}</span>
            <span>{timeToString(data.created)}</span>
          </span>
        }
      />
      <LeadsLabel label={lang.ORIGIN} value={data.first_source} />
      <LeadsLabel label={lang.BY} value={data.client.name} />
      <div className="leads-basic-info__row">
        <LeadsLabel label={lang.MEDIA} value={data.visuals_count} />
        <LeadsLabel label={lang.PARTICIPATIONS} value={data.participations_count} />
      </div>
      <LeadsLabel label={lang.COMMENT} value={data.comment} hasTooltip />
    </div>
  );
};

LeadsBasicInfo.propTypes = {
  lang: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default connect((state) => ({
  lang: state.languageState.payload.DATA_LEAD.MODAL.LEADS_BASIC_INFO,
}))(LeadsBasicInfo);
