import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getDiagrams} from 'client/ducks/results-graphs/actions';
import {selectDiagrams} from 'client/ducks/results-graphs/selectors';

import Icon from 'client/components/common/icon';
import InfoBlock from 'client/components/common/info-block';

import DiagramModal from './components/diagram-modal';
import ResultGraphsCardTable from './components/tables';
import ResultGraphsCardTableHeader from './components/tables/table-header';

import './results-graph-card.scss';

class ResultGraphsCard extends Component {
  static propTypes = {
    language: PropTypes.object.isRequired,
    autotaskId: PropTypes.number.isRequired,
    clientId: PropTypes.number.isRequired,
    operationId: PropTypes.number.isRequired,
    getDiagrams: PropTypes.func.isRequired,
    diagrams: PropTypes.array.isRequired,
  };

  static defaultProps = {
    isGlobal: false,
  };

  state = {
    showDiagramModal: false,
    editingDiagram: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.autotaskId !== prevProps.autotaskId) {
      this.fetchData();
    }
  }

  fetchData = () => {
    const {autotaskId} = this.props;

    const params = {
      q: {
        automation_task_id_eq: autotaskId,
        s: 'position asc',
      },
    };

    this.props.getDiagrams(params);
  };

  toggleDiagramModal = (editingDiagram = null) =>
    this.setState(({showDiagramModal}) => ({
      showDiagramModal: !showDiagramModal,
      editingDiagram,
    }));

  renderModal = () => {
    const {clientId, operationId, autotaskId} = this.props;
    const {editingDiagram} = this.state;

    return (
      <DiagramModal
        clientId={clientId}
        operationId={operationId}
        autotaskId={autotaskId}
        editingDiagram={editingDiagram}
        fetchData={this.fetchData}
        onClose={this.toggleDiagramModal}
      />
    );
  };

  render() {
    const {language, autotaskId, diagrams} = this.props;
    const {showDiagramModal} = this.state;

    return (
      <InfoBlock colorTheme="participants-history">
        {showDiagramModal && this.renderModal()}
        <InfoBlock.Title>{language.TITLE}</InfoBlock.Title>
        <InfoBlock.Content>
          <InfoBlock.Header className="clearfix">
            <button className="button pull-right button--bg-4" onClick={() => this.toggleDiagramModal(null)}>
              <Icon name="plus" className="button__icon" />
              <span>{language.ADD_GRAPH_BUTTON}</span>
            </button>
          </InfoBlock.Header>
          <InfoBlock.Body className="info-block" expand>
            <div style={{paddingRight: 10, paddingLeft: 10}}>
              <ResultGraphsCardTableHeader language={language} />
              <ResultGraphsCardTable
                autotaskId={autotaskId}
                language={language}
                items={diagrams}
                fetchData={this.fetchData}
                onEdit={this.toggleDiagramModal}
              />
            </div>
          </InfoBlock.Body>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

export default connect(
  (state) => ({
    language: state.languageState.payload.RESULTS_GRAPHS,
    diagrams: selectDiagrams(state),
  }),
  {
    getDiagrams,
  },
)(ResultGraphsCard);
