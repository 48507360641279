import types from './types';

let init = {
  showTaskHeader: true,
};

export default (state = init, action) => {
  switch (action.type) {
    case types.SHOW_TASK_HEADER:
      return {
        ...state,
        showTaskHeader: true,
      };
    case types.HIDE_TASK_HEADER:
      return {
        ...state,
        showTaskHeader: false,
      };
    default:
      return state;
  }
};
