import isUndefined from 'lodash/isUndefined';

import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {isEmptyString} from 'client/services/validator';

import {Translation} from 'client/models/language/types';

import {UpdateSettingsModalFormProps} from './index';

type ValidateProps = (
  formValues: UpdateSettingsModalFormProps,
  lang: LangsByPath<['AUTOTASK.UPDATE_SETTINGS_MODAL']>,
) => Record<string, Translation>;

export default ((formValues, lang) => {
  const errors: Partial<UpdateSettingsModalFormProps> = {};

  if (isEmptyString(formValues.update_kind) || isUndefined(formValues.update_kind)) {
    errors.update_kind = lang.UPDATE_KIND_ERROR;
  }

  return errors;
}) as ValidateProps;
