import {alwaysOnLanguages} from 'client/components/translations/constants';

export const sortLanguages = (languages) => {
  return languages.sort((langA, langB) => {
    if (alwaysOnLanguages.includes(langA.name) || alwaysOnLanguages.includes(langB.name)) {
      return 1;
    }
    if (langA.name > langB.name) {
      return 1;
    }
    if (langA.name < langB.name) {
      return -1;
    }
    return 0;
  });
};
