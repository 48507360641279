import React from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import 'common-styles';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/fr';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Userpilot} from 'userpilot';

import {initViewMode} from 'client/services/userViewModeService';

import {BUGSNAG_API_KEY, BUGSNAG_RELEASE_STAGE, USERPILOT_APP_TOKEN} from 'client/common/config';
import ToastNotificationContainer from 'client/common/containers/toast-notification-container';

import {routeList} from './routes';
import store from './store';

Userpilot.initialize(USERPILOT_APP_TOKEN);

initViewMode();

const cachedStore = store({});

const App = () => (
  <Provider store={cachedStore}>
    <BrowserRouter
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      <Switch>
        {routeList.map((renderRoute, index) => (
          <Route
            key={renderRoute.key || index}
            path={renderRoute.path}
            exact={renderRoute.exact}
            strict={renderRoute.strict}
            render={(props) => <renderRoute.component {...props} route={renderRoute} />}
          />
        ))}
      </Switch>
    </BrowserRouter>
    <ToastNotificationContainer />
  </Provider>
);

Bugsnag.start({
  releaseStage: BUGSNAG_RELEASE_STAGE,
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'stage', 'development'],
  enabledBreadcrumbTypes: ['error', 'navigation', 'request', 'user'],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root'),
);
