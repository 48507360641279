import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';

import {getClientsById} from 'client/ducks/agency/actions';

import Modal from 'client/common/modals/modal';

import CheckboxList from 'client/components/common/checkbox-list';
import CustomScrollbars from 'client/components/common/custom-scrollbars';

class NewUserAccessClientsModal extends Component {
  constructor(props) {
    super(props);

    this.LANGUAGE = this.props.languageState.payload.AGENCY;
  }

  componentDidMount() {
    this.props.getClientsById({
      agencyId: this.props.id,
    });
  }

  save = () => {
    if (this.props.editingUser) {
      this.props.onNext({
        showEditClientsAccessModal: 0,
        showEditUserModal: true,
        editingUser: {
          ...this.props.user,
          all_companies_access: 'false',
          company_accesses: this.props.user.company_accesses
            .filter((i) => i.id)
            .map(({id}) => ({id, _destroy: true}))
            .concat(this.props.newUserAccessClients.filter((i) => i.checked).map((i) => ({company_id: i.id}))),
        },
      });
    } else {
      this.props.onNext({
        addNewUserStep: 4,
        addNewUserObject: {
          ...this.props.user,
          memberships: [
            {
              ...this.props.user.memberships[0],
              company_accesses: this.props.newUserAccessClients
                .filter((i) => i.checked)
                .map((i) => ({company_id: i.id})),
            },
          ],
        },
      });
    }
  };

  onClientCheck = (targetIndex, checked) => {
    this.props.newUserAccessClients[targetIndex].checked = checked;
  };

  render() {
    const {newUserAccessClients, show, editingUser, onBack, onClose} = this.props;
    const {first_name: firstName, last_name: lastName, title: civility, email} = this.props.user;
    return (
      <Modal
        show={show}
        onClose={onClose}
        className="modal-window--width-1 modal-window--theme-3"
        classNames={{
          title: 'modal-window__title',
        }}
        title={
          <>
            <h3 className="title pull-left">
              {editingUser ? this.LANGUAGE.EDIT_USER_MODAL_TITLE : this.LANGUAGE.NEW_USER_MODAL_TITLE}
            </h3>
            {!editingUser && (
              <div className="pull-right modal-window__step">
                {this.LANGUAGE.NEW_USER_MODAL_STEP} 3 {this.LANGUAGE.NEW_USER_MODAL_STEP_OF} 4
              </div>
            )}
          </>
        }
      >
        <form noValidate="noValidate" onSubmit={this.props.handleSubmit(this.save)}>
          <div className="modal-window__subtitle modal-window__email">{email}</div>
          <div className="modal-window__subtitle modal-window__name">
            {this.LANGUAGE.CIVILITY[civility]} {firstName} {lastName}
          </div>
          <div className="modal-window__descr main-text main-text--bold modal-window--margin-bottom-5">
            {this.LANGUAGE.NEW_USER_MODAL_CLIENTS_ACCCESS}
          </div>
          <div className="content-group theme-color-3 min-height-200">
            <CustomScrollbars
              scrollbarProps={{
                autoHeightMax: 482,
              }}
            >
              <div className="list list--view-2">
                <CheckboxList
                  cssModifier="checkbox-button--view-1"
                  items={this.props.mapClients(this.props.user, newUserAccessClients)}
                  onCheck={this.onClientCheck}
                />
              </div>
            </CustomScrollbars>
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onBack}>
              {this.LANGUAGE.NEW_USER_MODAL_BACK_BUTTON}
            </button>
            <button className="button button--bg-3 modal-window__footer-btn" type="submit">
              {this.LANGUAGE.NEW_USER_MODAL_NEXT_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

NewUserAccessClientsModal.propTypes = {
  id: PropTypes.number,
  user: PropTypes.object,
  show: PropTypes.bool,
  editingUser: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  handleSubmit: PropTypes.func,
  languageState: PropTypes.object,
  newUserAccessClients: PropTypes.array,
  getClientsById: PropTypes.func,
  mapClients: PropTypes.func,
};

NewUserAccessClientsModal.defaultProps = {
  show: false,
  editingUser: false,
  onBack: () => {},
  onClose: () => {},
  onNext: () => {},
  newUserAccessClients: [],
};

const NewUserAccessClientsForm = reduxForm({
  form: 'NewUserAccessClientsForm',
})(NewUserAccessClientsModal);

export default connect(
  (state) => ({
    languageState: state.languageState,
    newUserAccessClients: state.agency.newUserAccessClients,
  }),
  {
    getClientsById,
  },
)(NewUserAccessClientsForm);
