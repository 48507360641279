import React from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectColumnVisibilityItems} from 'client/ducks/participation-display-items/selectors';

import AppButton from 'client/common/buttons';
import SearchInput from 'client/common/inputs/search-input';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import {
  DATA_COMPONENTS,
  DATA_TAB_MODAL_NAMES,
} from 'client/components/client-autotask/tabs/data-tab/data-common/constants';
import ParticipantsListSearchOptions from 'client/components/participants/components/tables/participants-main-data-grid/components/participants-list-search-options';
import {OPTION_NAMES} from 'client/components/participants/components/tables/participants-main-data-grid/components/participants-list-search-options/constants';

import './tool-bar.scss';

const b = bem('tool-bar');

const BUTTON_COLORS = {
  [DATA_COMPONENTS.PARTICIPATIONS]: 'participants',
  [DATA_COMPONENTS.LEADS]: 'leads',
  [DATA_COMPONENTS.LEADS_DATABASE]: 'clients',
  [DATA_COMPONENTS.WINNERS]: 'participants',
};

const PAGINATION_OPTIONS = [25, 50, 100].map((n) => ({value: `${n}`, label: n}));

const ToolBar = (props) => {
  const {
    onSearchChange,
    onPerPageChange,
    searchDefault,
    perPage,
    setActiveModal,
    appliedFiltersAmount,
    totalFoundItems,
    component,
    filesNumber,
    showReportsButtons,
    canGenerateFile,
    onSearchFieldChange,
    searchField,
  } = props;

  const language = useLanguage('DATA_TAB');
  const columns = useSelector(selectColumnVisibilityItems);

  const columnSearchKeys = columns.map((column) => {
    let recordType = column.column_adapter?.record_type;
    if (column.column_adapter?.record_type.includes('ParticipationPrize')) {
      recordType = 'participation_prizes';
    }
    if (column.column_adapter?.record_type.includes('OperationDatum')) {
      return 'participation_data';
    }
    const result = `${recordType}_${column.column_adapter?.name}`.toLowerCase();

    if (result === 'participation_prizes_token') {
      return 'participation_prizes_coupon_token_token';
    }
    if (result === 'participation_session_id') {
      return 'session_id';
    }

    return result;
  });

  const filterSearchOptions = (option) => {
    return columnSearchKeys.some((key) => option.value.includes(key));
  };

  const showParticipationSearch =
    Boolean(searchDefault || appliedFiltersAmount || totalFoundItems) &&
    Object.values(OPTION_NAMES).filter((option) => {
      return columnSearchKeys.some((key) => option.includes(key));
    }).length > 0;

  return (
    <div className={b()}>
      <div className={b('search-field')}>
        {component === DATA_COMPONENTS.PARTICIPATIONS && showParticipationSearch && (
          <ParticipantsListSearchOptions
            value={searchField}
            className={b('search-options')}
            onChange={onSearchFieldChange}
            filterOptions={filterSearchOptions}
          />
        )}
        {(component !== DATA_COMPONENTS.PARTICIPATIONS || showParticipationSearch) && (
          <SearchInput
            className={b('search-value')}
            placeholder={language.SEARCH_BY_PLANS_PLACEHOLDER}
            onSearch={onSearchChange}
            searchDefault={searchDefault}
          />
        )}
      </div>
      <div className={b('controls')}>
        <div className={b('buttons')}>
          <AppButton
            color={BUTTON_COLORS[component]}
            iconName="filters"
            label={`${language.FILTERS_MODAL.BUTTON_CAPTION} (${appliedFiltersAmount})`}
            onClick={() => setActiveModal(DATA_TAB_MODAL_NAMES.FILTERS_MODAL)}
          />
          <span className={b('total')}>{`${totalFoundItems} ${language.RECORDS_FOUND}`}</span>
          {showReportsButtons && (
            <>
              {canGenerateFile && (
                <AppButton
                  color={BUTTON_COLORS[component]}
                  label={language.GENERATE_FILE}
                  disabled={!totalFoundItems}
                  onClick={() => setActiveModal(DATA_TAB_MODAL_NAMES.GENERATE_FILE_MODAL)}
                />
              )}
              <AppButton
                color={BUTTON_COLORS[component]}
                label={`${language.VIEW_FILES} (${filesNumber || 0})`}
                onClick={() => setActiveModal(DATA_TAB_MODAL_NAMES.VIEW_FILES_MODAL)}
                disabled={filesNumber === 0}
              />
            </>
          )}
        </div>

        <PerPageDropdown
          options={PAGINATION_OPTIONS}
          onChange={(pages) => onPerPageChange(Number(pages))}
          value={String(perPage)}
          simpleValue
          className={b('dropdown')}
        />
      </div>
    </div>
  );
};

ToolBar.propTypes = {
  searchField: PropTypes.string,
  setActiveModal: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSearchFieldChange: PropTypes.func.isRequired,
  onPerPageChange: PropTypes.func.isRequired,
  searchDefault: PropTypes.string,
  showClear: PropTypes.bool,
  perPage: PropTypes.number,
  cssModifier: PropTypes.string,
  appliedFiltersAmount: PropTypes.number,
  totalFoundItems: PropTypes.number,
  component: PropTypes.oneOf(Object.values(DATA_COMPONENTS)),
  filesNumber: PropTypes.number.isRequired,
  showReportsButtons: PropTypes.bool,
  canGenerateFile: PropTypes.bool,
};

ToolBar.defaultProps = {
  showClear: false,
  readOnly: false,
  perPage: 25,
  plans: {},
  onSearchChange: () => {},
  onSearchFieldChange: () => {},
  onSearchClear: () => {},
  searchDefault: '',
  cssModifier: '',
  appliedFiltersAmount: 0,
  totalFoundItems: 0,
  showReportsButtons: false,
  canGenerateFile: false,
};

export default ToolBar;
