import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {createSource, patchSource, getClientOptions} from 'client/ducks/sources/actions';
import {selectClientOptions} from 'client/ducks/sources/selectors';

import {SOURCE_TYPES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

import validate from './validate';

class SrcAddSourceModal extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    createSource: PropTypes.func.isRequired,
    patchSource: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    editingSource: PropTypes.object,
    getClientOptions: PropTypes.func.isRequired,
    clientOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  };

  static defaultProps = {
    editingSource: null,
  };

  static formName = 'SrcAddSourceModalForm';

  componentDidMount() {
    this.props.getClientOptions();
  }

  save = (values) => {
    const apiFunction = !this.props.editingSource
      ? this.props.createSource
      : (body) => this.props.patchSource(this.props.editingSource.id, body);

    return apiFunction(values).then(({payload: {response: {errors} = {}}}) => {
      if (errors && errors.name) {
        throw new SubmissionError({
          name: this.props.lang.ERRORS.NAME_IS_TAKEN,
        });
      }

      this.props.onConfirm();
    });
  };

  render() {
    const {lang, onClose, handleSubmit, editingSource, clientOptions} = this.props;

    return (
      <Modal
        show={true}
        onClose={onClose}
        title={editingSource ? lang.EDIT_TITLE : lang.TITLE}
        dialogClassName="modal-window--width-1 theme-color-10"
      >
        <form onSubmit={handleSubmit(this.save)}>
          <div className="form-field-wrap">
            <Field label={lang.NAME_LABEL} name="name" type="text" component={fieldTemplate} />
          </div>
          <div className="form-field-wrap">
            <Field
              simpleValue
              label={lang.TYPE_LABEL}
              name="kind"
              type="select"
              searchable={false}
              options={Object.keys(SOURCE_TYPES).map((key) => {
                return {
                  value: key,
                  label: lang.SOURCE_TYPES[SOURCE_TYPES[key]],
                };
              })}
              component={fieldTemplate}
              scrollBarProps={{
                autoHeightMin: 230,
                autoHeightMax: 250,
              }}
            />
          </div>
          <div className="form-field-wrap">
            <Field
              label={lang.CLIENT_LABEL}
              name="client_id"
              type="select"
              options={clientOptions.map(({id, name}) => ({value: id, label: name}))}
              component={fieldTemplate}
              simpleValue
              searchable
            />
          </div>
          <div className="form-field-wrap">
            <Field label={lang.COMMENT_LABEL} name="comment" type="text" component={fieldTemplate} />
          </div>
          <div className="form-field-wrap">
            <Field
              cssModifier="input--no-min-height"
              label={lang.DESCRIPTION_LABEL}
              name="description"
              type="text"
              component={fieldTemplate}
            />
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onClose}>
              {lang.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-6 modal-window__footer-btn" type="submit">
              {editingSource ? lang.SAVE_BUTTON : lang.CONFIRM_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const form = reduxForm({
  form: SrcAddSourceModal.formName,
  validate,
})(SrcAddSourceModal);

export default connect(
  (state, {editingSource}) => ({
    lang: {
      ...state.languageState.payload.SOURCES.ADD_SOURCE_MODAL,
      SOURCE_TYPES: state.languageState.payload.SOURCE_TYPES,
    },
    clientOptions: selectClientOptions(state),
    initialValues: editingSource,
  }),
  {
    createSource,
    patchSource,
    getClientOptions,
  },
)(form);
