import React, {Component} from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, change, SubmissionError, reset} from 'redux-form';

import {getAuthHeaders} from 'client/services/helpers';

import {API_METHODS, API_PATH} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

let LANGUAGE = {};

class AddNewTemplateModal extends Component {
  static validate({name, code}) {
    const lang = LANGUAGE.NEW_TEMPLATE_MODAL || {};
    const errors = {};

    if (!name || !name.trim()) {
      errors.name = lang.NAME_IS_REQUIRED_ERROR;
    }
    if (!code || !code.trim()) {
      errors.code = lang.CODE_IS_REQUIRED_ERROR;
    }

    return errors;
  }

  static updateSyncErrors(values) {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'AddNewTemplateForm',
      },
      payload: {
        syncErrors: AddNewTemplateModal.validate(values),
      },
    };
  }

  static mapData(data) {
    return {
      interface_template: {
        ...data,
      },
    };
  }

  constructor(props) {
    super(props);

    LANGUAGE = this.LANGUAGE = this.props.languageState.payload.INTERFACE_TEMPLATES;

    this.save = this.save.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.intervalID = setInterval(() => {
      if (!isEmpty(LANGUAGE)) {
        clearInterval(this.intervalID);
        this.props.updateErrors({});
      }
    }, 200);
  }

  componentDidUpdate(prevProps) {
    const {initialValues, show, formValues} = this.props;

    if (show && !prevProps.show) {
      this.props.initialize(initialValues);
      this.props.updateErrors(initialValues);
    }

    const oldCode = (prevProps.formValues && prevProps.formValues.values && prevProps.formValues.values.code) || '';
    const newCode = formValues && formValues.values && formValues.values.code && formValues.values.code.toUpperCase();
    if (newCode && oldCode !== newCode) {
      if (/^[A-Z0-9]*$/.test(newCode) && newCode.length <= 10) {
        this.props.changeFieldValue('AddNewTemplateForm', 'code', newCode);
      } else {
        this.props.changeFieldValue('AddNewTemplateForm', 'code', oldCode);
      }
    }
  }

  save(data) {
    const edit = typeof this.props.initialValues.name !== 'undefined';
    let method;
    let path;

    if (edit) {
      method = 'PATCH';
      path = `${API_PATH}${API_METHODS.INTERFACE_TEMPLATES}/${this.props.initialValues.id}`;
    } else {
      method = 'POST';
      path = `${API_PATH}${API_METHODS.INTERFACE_TEMPLATES}?include[]=interface_templates_items`;
    }

    return fetch(path, {
      method,
      headers: getAuthHeaders(),
      body: JSON.stringify(AddNewTemplateModal.mapData(data)),
    })
      .then((res) => res.json())
      .then((result) => {
        const {errors, interface_template} = result;
        if (errors) {
          const submitErrors = {};
          if (errors.name) {
            submitErrors.name = LANGUAGE.NEW_TEMPLATE_MODAL.NAME_IS_TAKEN_ERROR;
          }
          if (errors.code) {
            submitErrors.code = LANGUAGE.NEW_TEMPLATE_MODAL.CODE_IS_TAKEN_ERROR;
          }
          throw new SubmissionError(submitErrors);
        }
        this.props.onConfirm(interface_template);
      });
  }

  handleCancel() {
    this.props.onCancel();
  }

  handleClose() {
    this.props.onClose();
  }

  render() {
    const {valid, initialValues, show} = this.props;
    const edit = typeof initialValues.name !== 'undefined';

    return (
      <Modal
        show={show}
        onClose={this.handleClose}
        dialogClassName="modal-window--width-1 modal-window--theme-7"
        title={edit ? LANGUAGE.EDIT_TEMPLATE_MODAL.TITLE : LANGUAGE.NEW_TEMPLATE_MODAL.TITLE}
      >
        <form noValidate="noValidate" onSubmit={this.props.handleSubmit(this.save)}>
          <Field
            name="name"
            type="text"
            required
            component={fieldTemplate}
            label={LANGUAGE.NEW_TEMPLATE_MODAL.NAME_LABEL}
          />
          <Field
            name="code"
            type="text"
            required
            label={LANGUAGE.NEW_TEMPLATE_MODAL.CODE_LABEL}
            component={fieldTemplate}
          />
          <Field
            name="description"
            type="text"
            label={LANGUAGE.NEW_TEMPLATE_MODAL.DESCRIPTION_LABEL}
            component={fieldTemplate}
          />
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={this.handleCancel}>
              {this.LANGUAGE.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-7 modal-window__footer-btn" type="submit" disabled={!valid}>
              {edit ? LANGUAGE.SAVE_BUTTON : LANGUAGE.NEW_TEMPLATE_MODAL.NEXT_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

AddNewTemplateModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  handleSubmit: PropTypes.func,
  updateErrors: PropTypes.func,
  untouch: PropTypes.func,
  initialize: PropTypes.func,
  reset: PropTypes.func,
  changeFieldValue: PropTypes.func,
  languageState: PropTypes.object,
  formValues: PropTypes.object,
  initialValues: PropTypes.object,
  valid: PropTypes.bool,
};

AddNewTemplateModal.defaultProps = {
  show: false,
  onCancel: () => {},
  onClose: () => {},
  onConfirm: () => {},
};

const AddNewTemplateForm = reduxForm({
  form: 'AddNewTemplateForm',
  validate: AddNewTemplateModal.validate,
})(AddNewTemplateModal);

export default connect(
  ({languageState, form}) => ({
    languageState,
    formValues: form.AddNewTemplateForm,
  }),
  {
    updateErrors: AddNewTemplateModal.updateSyncErrors,
    changeFieldValue: change,
    reset,
  },
)(AddNewTemplateForm);
