import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Icon from 'client/components/common/icon';

import {TranslationJsx} from 'client/models/language/types';

import './tag.scss';

const b = bem('tag');

const Tag = (props) => {
  const {text, iconName, color, className} = props;
  return (
    <div className={classNames(b({[color]: true, 'has-icon': iconName}), className)}>
      {iconName && <Icon name={iconName} className={b('icon')} />}
      <span className={b('text')}>{text}</span>
    </div>
  );
};

Tag.defaultProps = {
  iconName: '',
  color: 'primary',
  className: '',
};

Tag.propTypes = {
  text: TranslationJsx.isRequired,
  iconName: PropTypes.string,
  // can be extended with color variable names from ui-kit
  color: PropTypes.oneOf(['primary', 'participants', 'active', 'finished']),
  className: PropTypes.string,
};

export default Tag;
