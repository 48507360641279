import orderBy from 'lodash/orderBy';

import {dateToString} from 'client/services/helpers';

import {
  ANIMATION_VALIDATION_STATUSES,
  ANIMATION_ANOMALY_STATUSES,
  ANIMATION_STATUSES,
} from 'client/components/instore/constants';

export const getAnimationDate = (list, field, withTime = true) => {
  const order = {from: 'asc', to: 'desc'}[field];
  const date = orderBy(list, field, order)[0]?.[field];
  return dateToString(date, `DD/MM/YYYY${withTime ? ' HH:mm' : ''}`);
};

const isOk = (status) => status === ANIMATION_VALIDATION_STATUSES.OK;

const isAnomaly = (status) => status === ANIMATION_VALIDATION_STATUSES.ANOMALY;

const hasDay = (days, field, comparator) => days.filter((i) => comparator(i[field])).length > 0;

export const getAnimationValidationStatus = (item, field) => {
  if (!item) {
    return null;
  }

  const mainStatus = item[field];

  const hasOkDay = hasDay(item.animation_days, field, isOk);
  const hasAnomalyDay = hasDay(item.animation_days, field, isAnomaly);

  switch (true) {
    case isAnomaly(mainStatus) || (!mainStatus && hasAnomalyDay):
      return ANIMATION_VALIDATION_STATUSES.ANOMALY;
    case isOk(mainStatus) || (!mainStatus && hasOkDay):
      return ANIMATION_VALIDATION_STATUSES.OK;
    default:
      return null;
  }
};

export const getAnimationAnomalyStatus = (item, field) => {
  if (!item) {
    return null;
  }

  const days = item[field];

  const counters = days.reduce(
    (result, day) => {
      result.total += day.anomaly_occurrences?.length;
      result.unviewed += day.anomaly_occurrences?.filter((i) => !i.viewed).length;
      result.manual_unviewed += day.anomaly_occurrences?.filter((i) => i.manual_unviewed).length;
      return result;
    },
    {total: 0, unviewed: 0, manual_unviewed: 0},
  );

  if (!counters.total) {
    return null;
  } else if (counters.unviewed || counters.manual_unviewed) {
    return ANIMATION_ANOMALY_STATUSES.UNVIEWED;
  }

  return ANIMATION_ANOMALY_STATUSES.VIEWED;
};

export const canEditAnimationDetails = (status) => {
  return (
    !status ||
    [
      ANIMATION_STATUSES.EDIT,
      ANIMATION_STATUSES.ISSUED,
      ANIMATION_STATUSES.VALIDATED,
      ANIMATION_STATUSES.RECEIVED,
    ].includes(status)
  );
};

export const getPeriod = (from, to) => {
  const period = {
    from: from && `${from} 00:00:01`,
    to: to && `${to} 23:59:59`,
  };

  return Object.values(period).join(',');
};
