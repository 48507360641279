import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Icon from 'client/components/common/icon';

import './spinner.scss';

const b = bem('spinner');

export const SpinnerColors = [
  'current',
  'primary',
  'participants',
  'leads',
  'winners',
  'visuals',
  'visits',
  'green',
  'clients',
];

function Spinner(props) {
  const {centered, pageCentered, color, className} = props;

  return (
    <div className={cn(b([centered && 'centered', pageCentered && 'page-centered', color]), className)}>
      <div className={b('content')}>
        <div className={b('circle')} />
        <div className={b('icon')}>
          <Icon name="empty-data" />
        </div>
      </div>
    </div>
  );
}

Spinner.propTypes = {
  centered: PropTypes.bool,
  pageCentered: PropTypes.bool,
  // synchronize with colors variable in styles file
  color: PropTypes.oneOf(SpinnerColors),
  className: PropTypes.string,
};

Spinner.defaultProps = {
  centered: false,
  pageCentered: false,
  color: 'primary',
  className: '',
};

export default Spinner;
