import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Carousel from 'client/common/carousel';
import {ErrorMessage, ImageInput, RequiredLabel, WarningMessage} from 'client/common/inputs';

import {TranslationJsx} from 'client/models/language/types';

import cssModule from './carousel-input.module.scss';

const b = bem('carousel-input', {cssModule});

const carouselDefaultProps = {
  ...Carousel.defaultProps,
  width: 'xxs',
  slidesPerView: 4,
};

const slideDefaultProps = {
  ...ImageInput.defaultProps,
  width: '100%',
  height: '100%', // set fixed value in pixels to limit height
};

const CarouselInput = (props) => {
  const {
    className,
    label,
    name,
    value,
    onChange,
    errorMessage = '',
    warningMessage,
    onBlur,
    carouselProps,
    slideProps,
    required = false,
  } = props;

  const handleChange = (file, index) => {
    const newValue = [...value];
    newValue[index] = file;
    onChange(newValue);
    onBlur?.();
  };

  return (
    <div className={cn(b({error: !!errorMessage, warning: !!warningMessage}), className)}>
      {label && (
        <label htmlFor={name} className={b('label')}>
          {label}
        </label>
      )}
      <Carousel
        {...carouselDefaultProps}
        {...carouselProps}
        slides={value.map((item, index) => (
          <ImageInput
            {...slideDefaultProps}
            {...slideProps}
            buttonClassName={b('slide-button')}
            key={index}
            name={`${name}[${index}]`}
            value={item}
            onChange={(file) => handleChange(file, index)}
          />
        ))}
      />
      {!errorMessage && required && <RequiredLabel />}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {warningMessage && !errorMessage && <WarningMessage warningMessage={warningMessage} />}
    </div>
  );
};

CarouselInput.propTypes = {
  className: PropTypes.string,
  label: TranslationJsx,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
  warningMessage: TranslationJsx,
  onBlur: PropTypes.func,
  carouselProps: PropTypes.shape({
    className: PropTypes.string,
    spaceBetween: PropTypes.number,
    slidesPerView: PropTypes.number,
    centeredSlides: PropTypes.bool,
    scrollbar: PropTypes.bool,
    width: PropTypes.oneOf(Carousel.widthOptions),
  }),
  slideProps: PropTypes.shape({
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    acceptFormats: PropTypes.string,
    placeholderIcon: PropTypes.string,
    keepRatio: PropTypes.bool,
  }),
  required: PropTypes.bool,
};

CarouselInput.defaultProps = {
  className: null,
  label: null,
  value: [],
  errorMessage: null,
  onBlur: null,
  carouselProps: carouselDefaultProps,
  slideProps: slideDefaultProps,
};

export default CarouselInput;
