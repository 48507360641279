import update from 'immutability-helper';
import defaultsDeep from 'lodash/defaultsDeep';
import fromPairs from 'lodash/fp/fromPairs';
import get from 'lodash/get';

import {required} from 'client/services/validator';

import {filterScenarioVariablesByType, compactObject} from '../helpers';
import {getOptInsOptions, OPT_INS_OPERATOR_OPTIONS} from '../opt-ins';

export const createImplementation = () => ({
  check_opt_ins: false,
  check_opt_ins_operator: OPT_INS_OPERATOR_OPTIONS[0].value,
  can_update_email_template_id: true,
  email_template_id: null,
  recipient_variable_id: null,
});

const getMapping = (data) => {
  const mappingItems = get(data, 'implementation.email_scenario_step_mapping_items', []);

  return fromPairs(
    mappingItems.map((mappingItem) => [
      mappingItem.email_template_variable_id,
      {variable_id: mappingItem.variable_id, id: mappingItem.id},
    ]),
  );
};

export const getEmailScenarioVariables = (scenarioVariables) => {
  return filterScenarioVariablesByType(scenarioVariables, 'email');
};

export const getInitialValues = (data, {scenarioVariables, emailTemplates, optIns}) => {
  const emailScenarioVariables = getEmailScenarioVariables(scenarioVariables);
  const mapping = getMapping(data, emailTemplates, scenarioVariables);
  const optInsOptions = getOptInsOptions(data, optIns);

  return defaultsDeep({}, compactObject(data), {
    mapping,
    optInsOptions,
    implementation: {
      check_opt_ins: false,
      check_opt_ins_operator: OPT_INS_OPERATOR_OPTIONS[0].value,
      email_template_id: get(emailTemplates || [], '[0].id', null),
      message_sender_id: get(data, 'implementation.message_sender_id', null),
      recipient_variable_id: get(emailScenarioVariables, '[0].id', null),
    },
  });
};

const transformFormData = (values, {emailTemplates}) => {
  const {
    mapping,
    optInsOptions,
    implementation: {check_opt_ins, email_template_id},
  } = values;

  const currentTemplate = emailTemplates.find((template) => template.id === email_template_id);

  let mappedOptIns = [];

  if (check_opt_ins) {
    mappedOptIns = optInsOptions
      .filter((optIn) => optIn.active)
      .map((optIn) => ({
        id: optIn.id,
        opt_in_column_id: optIn.opt_in_column_id,
      }));
  }

  const email_scenario_step_mapping_items =
    currentTemplate?.email_template_variables
      ?.map((variable) => ({
        email_template_variable_id: variable.id,
        id: mapping[variable.id]?.id,
        variable_id: mapping[variable.id]?.variable_id,
      }))
      .filter((item) => !!item.variable_id) || [];

  return update(values, {
    $unset: ['mapping', 'optInsOptions'],
    implementation: {
      check_opt_ins_operator: (operator) => (check_opt_ins ? operator : null),
      $merge: {
        message_scenario_step_to_opt_in_columns: mappedOptIns,
        email_scenario_step_mapping_items,
      },
    },
  });
};

export const validate = (values = {}, {lang}) => {
  const errors = {
    name: required(lang.ERRORS.REQUIRED)(values.name),
    implementation: {
      email_template_id: required(lang.ERRORS.REQUIRED)(values.implementation?.email_template_id),
      reply_to_id: required(lang.ERRORS.REQUIRED)(values.implementation?.reply_to_id),
      recipient_variable_id: required(lang.ERRORS.REQUIRED)(values.implementation?.recipient_variable_id),
    },
  };

  return errors;
};

export const submitForm = (values, dispatch, props) => {
  const transformedValues = transformFormData(values, {emailTemplates: props.emailTemplates});

  return props.onSubmitForm(transformedValues);
};
