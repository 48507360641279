import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {set as setMetaAction} from 'client/common/meta/meta.actions';

import LoadingSpinner from 'client/components/common/loading-spinner';

import Profile from '../components/profile/profile';

const AdminProfilePage = ({location}) => {
  const [id, setId] = useState(null);

  const lang = useLanguage('ADMINS');

  const dispatch = useDispatch();

  useEffect(() => {
    const urlPath = location.pathname.split('/');
    const userId = urlPath[2];
    setId(userId);
    dispatch(setMetaAction({title: lang.TITLE}));
  }, [location.pathname, dispatch, lang]);

  if (!id) {
    return <LoadingSpinner loading={true} />;
  }

  return <Profile id={id} />;
};

AdminProfilePage.propTypes = {
  location: PropTypes.object,
};

export default AdminProfilePage;
