import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';
import {useToggle} from 'react-use';
import {SubmissionError} from 'redux-form';

import bem from 'client/services/bem';
import {removeSpaces} from 'client/services/formatters';
import {useLanguage, useReduxFetch} from 'client/services/hooks';
import {apiAction} from 'client/services/hooks/use-redux-fetch/requests';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {createTrackingUser, updateTrackingUser} from 'client/ducks/client-account/actions';

import AppButton from 'client/common/buttons/app-button';
import {API_METHODS} from 'client/common/config';
import PasswordField from 'client/common/fields/password-field';
import SelectField from 'client/common/fields/select-field';
import TextField from 'client/common/fields/text-field';
import {useToast} from 'client/common/hooks/useToast';
import Modal from 'client/common/modals/modal';

import {Client} from 'client/models/client/types';
import {Membership} from 'client/models/memberships/types';
import {Place} from 'client/models/places/types';
import {ApiDispatch} from 'client/types';

import getInitialValues from './getInitialValues';
import mapFormValues from './mapFormValues';
import {TrackingUserValues} from './types';
import validateValues from './validateValues';

import cssModule from './tracking-user-modal.module.scss';

const b = bem('tracking-user-modal', {cssModule});

type TrackingUserModalProps = {
  onClose: () => void;
  locale: string;
  client: Client;
  initialData?: Membership | null;
  onSubmit: () => void;
};

const trackingUserFormName = 'TrackingUserForm';

const TrackingUserModal: ReduxFormFC<TrackingUserModalProps, TrackingUserValues> = (props) => {
  const {onClose, initialData, handleSubmit, client, locale, onSubmit} = props;
  const dispatch: ApiDispatch = useDispatch();
  const lang = useLanguage('CLIENT_ACCOUNT_PARAMETERS.MODALS.TRACKING_USER_MODAL');
  const langCommon = useLanguage('COMMON');
  const [saving, toggleSaving] = useToggle(false);
  const {appendToastNotification} = useToast();

  const isEdit = Boolean(initialData);

  const {invalid, change, formValues} = useReduxForm(trackingUserFormName, {
    initialValues: getInitialValues(initialData, locale),
    validate: (values) => validateValues(values, lang.ERRORS, isEdit),
  });

  const {data: {places = []} = {}} = useReduxFetch<{places: Place[]}>({
    action: apiAction,
    actionArgs: {
      url: API_METHODS.PLACES,
      queryParams: {
        q: {client_id_eq: client.id, active_eq: true},
      },
    },
  });

  useEffect(() => {
    if (!formValues.store_id && places.length) {
      change('store_id', places[0].id);
    }
  }, [change, formValues.store_id, places]);

  const handleSave = async (values: TrackingUserValues) => {
    toggleSaving();

    const data = mapFormValues(values, client.id, initialData);

    const response: {payload?: {response?: any}; error?: boolean} = initialData
      ? await dispatch(updateTrackingUser(initialData.prize_tracking_user_id, data))
      : await dispatch(createTrackingUser(data));

    toggleSaving();

    if (response.payload?.response?.errors?.login?.[0] === 'taken') {
      throw new SubmissionError({login: lang.ERRORS.LOGIN_UNIQUE.toString()});
    } else if (response.error) {
      appendToastNotification({type: 'error', title: langCommon.ERROR});
    } else {
      await onSubmit();
      onClose();
    }
  };

  return (
    <Modal title={isEdit ? lang.TITLE_EDIT : lang.TITLE_CREATE} className={b()} onClose={onClose}>
      <form onSubmit={handleSubmit(handleSave)}>
        <TextField className={b('field')} name="name" label={lang.NAME} />
        <TextField className={b('field')} name="login" label={lang.LOGIN} format={removeSpaces} />
        <PasswordField className={b('field')} name="password" label={lang.PASSWORD} format={removeSpaces} />
        {places.length > 1 && (
          <SelectField
            className={b('field')}
            name="store_id"
            label={lang.STORE}
            options={places.map((i) => ({label: i.name, value: i.id}))}
            simpleValue
          />
        )}
        <div className={b('buttons')}>
          <AppButton label={lang.CANCEL} color="clients" onClick={onClose} transparent />
          <AppButton
            label={isEdit ? lang.UPDATE : lang.CREATE}
            color="clients"
            size="small"
            loading={saving}
            disabled={invalid}
            submit
          />
        </div>
      </form>
    </Modal>
  );
};

export default reduxForm<TrackingUserModalProps, TrackingUserValues>({
  form: trackingUserFormName,
})(TrackingUserModal);
