import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Grid from 'client/components/common/grid';

import LinkItem from './components/link-item';

function BCResultClicksModal(props) {
  const {languageState, onClose, show, links} = props;
  const LANGUAGE = languageState.payload.BROADCASTING_TASK.RESULT_CLICKS_MODAL;

  return (
    <Modal show={show} onClose={onClose} title={LANGUAGE.TITLE} dialogClassName="modal-window--width-2 theme-color-16">
      <form>
        <CustomScrollbars
          scrollbarProps={{
            autoHeightMax: 400,
          }}
        >
          <Grid paddings={true}>
            <Grid.Row className="bold">
              <Grid.Col size="8">{LANGUAGE.LINK_NAME_LABEL}</Grid.Col>
              <Grid.Col size="4">{LANGUAGE.TIMESTAMP_LABEL}</Grid.Col>
            </Grid.Row>
            {links.map((link, index) => (
              <LinkItem {...link} key={index} />
            ))}
          </Grid>
        </CustomScrollbars>
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-14 modal-window__footer-btn" type="button" onClick={onClose}>
            {LANGUAGE.CONFIRM_BUTTON}
          </button>
        </div>
      </form>
    </Modal>
  );
}

BCResultClicksModal.propTypes = {
  show: PropTypes.bool,
  languageState: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      linkName: PropTypes.string,
      linkKind: PropTypes.string,
      clicksTime: PropTypes.string,
    }),
  ).isRequired,
};

BCResultClicksModal.defaultTypes = {
  show: false,
};

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

export default connect(mapStateToProps)(BCResultClicksModal);
