import React, {useState} from 'react';

import {useToggle} from 'react-use';
import {Transforms} from 'slate';
import {ReactEditor, useSlate} from 'slate-react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import SelectDropdown from 'client/common/selects/select-dropdown';
import ToolbarButton from 'client/common/text-editor/buttons/toolbar-button';
import {TextEditorVariable} from 'client/common/text-editor/types';

import cssModule from './static-variable-bar.module.scss';

const b = bem('static-variable-bar', {cssModule});

type StaticVariableBarProps = {
  variables: TextEditorVariable[];
};

const StaticVariableBar: React.FC<StaticVariableBarProps> = (props) => {
  const {variables} = props;
  const lang = useLanguage('COMMON.TEXT_EDITOR.LABELS');
  const editor = useSlate();
  const [opened, toggleOpened] = useToggle(false);
  const [variableFamily, setVariableFamily] = useState<string>(variables[0].key);
  const [variableName, setVariableName] = useState<string>(variables[0].items[0]);

  const familyOptions = variables.map((i) => ({value: i.key, label: i.label}));

  const getFamilyItems = (family: string) => variables.find((i) => i.key === family)?.items || [];

  const handleFamilyChange = (family: string) => {
    setVariableFamily(family);
    setVariableName(getFamilyItems(family)?.[0] || '');
  };

  const addVariable = (name: string) => {
    Transforms.insertText(editor, `{{var:${name}}} `);
    requestAnimationFrame(() => ReactEditor.focus(editor));
  };

  return (
    <div className={b({opened})}>
      <ToolbarButton
        className={b('variable-button')}
        iconName="variable"
        iconProps={{className: b('variable-icon')}}
        onClick={toggleOpened}
        disabled={!variableName}
      />
      {opened && (
        <div className={b('bar')}>
          <SelectDropdown
            label={lang.FAMILY}
            labelClassName={b('select-label')}
            options={familyOptions}
            value={variableFamily}
            onChange={handleFamilyChange}
            simpleValue
          />
          <SelectDropdown
            label={lang.NAME}
            labelClassName={b('select-label')}
            options={getFamilyItems(variableFamily)?.map((i) => ({value: i, label: i}))}
            value={variableName}
            onChange={setVariableName}
            simpleValue
          />
          <ToolbarButton
            className={b('check-button')}
            label={lang.INSERT}
            iconName="check"
            iconProps={{width: 24, height: 24, className: b('check-icon')}}
            onClick={() => addVariable(variableName)}
            disabled={!variableName}
          />
        </div>
      )}
    </div>
  );
};

export default StaticVariableBar;
