export default {
  POST_DEVICE_FAMILY_REQUEST: '@@devices/POST_DEVICE_FAMILY_REQUEST',
  POST_DEVICE_FAMILY_SUCCESS: '@@devices/POST_DEVICE_FAMILY_SUCCESS',
  POST_DEVICE_FAMILY_ERROR: '@@devices/POST_DEVICE_FAMILY_ERROR',

  GET_DEVICE_FAMILIES_REQUEST: '@@devices/GET_DEVICE_FAMILIES_REQUEST',
  GET_DEVICE_FAMILIES_SUCCESS: '@@devices/GET_DEVICE_FAMILIES_SUCCESS',
  GET_DEVICE_FAMILIES_ERROR: '@@devices/GET_DEVICE_FAMILIES_ERROR',

  GET_DEVICES_REQUEST: '@@devices/GET_DEVICES_REQUEST',
  GET_DEVICES_SUCCESS: '@@devices/GET_DEVICES_SUCCESS',
  GET_DEVICES_ERROR: '@@devices/GET_DEVICES_ERROR',

  PATCH_DEVICE_REQUEST: '@@devices/PATCH_DEVICE_REQUEST',
  PATCH_DEVICE_SUCCESS: '@@devices/PATCH_DEVICE_SUCCESS',
  PATCH_DEVICE_ERROR: '@@devices/PATCH_DEVICE_ERROR',

  DELETE_DEVICE_REQUEST: '@@devices/DELETE_DEVICE_REQUEST',
  DELETE_DEVICE_SUCCESS: '@@devices/DELETE_DEVICE_SUCCESS',
  DELETE_DEVICE_ERROR: '@@devices/DELETE_DEVICE_ERROR',

  POST_DEVICE_REQUEST: '@@devices/POST_DEVICE_REQUEST',
  POST_DEVICE_SUCCESS: '@@devices/POST_DEVICE_SUCCESS',
  POST_DEVICE_ERROR: '@@devices/POST_DEVICE_ERROR',

  UNLINK_DEVICE_REQUEST: '@@devices/UNLINK_DEVICE_REQUEST',
  UNLINK_DEVICE_SUCCESS: '@@devices/UNLINK_DEVICE_SUCCESS',
  UNLINK_DEVICE_ERROR: '@@devices/UNLINK_DEVICE_ERROR',

  LINK_DEVICE_REQUEST: '@@devices/LINK_DEVICE_REQUEST',
  LINK_DEVICE_SUCCESS: '@@devices/LINK_DEVICE_SUCCESS',
  LINK_DEVICE_ERROR: '@@devices/LINK_DEVICE_ERROR',

  LINK_ALL_DEVICE_REQUEST: '@@devices/LINK_ALL_DEVICE_REQUEST',
  LINK_ALL_DEVICE_SUCCESS: '@@devices/LINK_ALL_DEVICE_SUCCESS',
  LINK_ALL_DEVICE_ERROR: '@@devices/LINK_ALL_DEVICE_ERROR',
};
