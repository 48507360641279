import types from './types';

const initialState = {
  optInColumns: [],
  clientName: '',
  lead: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_ANONYMOUS_LEAD_SUCCESS:
      return {
        ...state,
        optInColumns: action.payload.opt_in_columns,
        clientName: action.payload.client.name,
        lead: action.payload.lead,
      };

    default:
      return state;
  }
}
