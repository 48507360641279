import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {USER_CIVILITY} from 'client/models/users/constants';
import {UserCivility} from 'client/models/users/types';

import cssModule from './client-user-title.module.scss';

const b = bem('client-user-title', {cssModule});

type CreatClientUserTitleProps = {
  email: string;
  civility?: UserCivility;
  firstName?: string;
  lastName?: string;
};

const ClientUserTitle: React.FC<CreatClientUserTitleProps> = ({email, firstName, lastName, civility}) => {
  const lang = useLanguage('CLIENT_ACCOUNT_PARAMETERS.MODALS.CLIENT_USER');

  const civilityOptions = {
    [USER_CIVILITY.FEMALE]: lang.MRS,
    [USER_CIVILITY.MALE]: lang.MR,
  } as const;

  return (
    <div className={b()}>
      <p className={b('info')}>{email}</p>
      {civility && <p className={b('info')}>{`${civilityOptions[civility]} ${firstName} ${lastName}`}</p>}
    </div>
  );
};

export default ClientUserTitle;
