import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {
  deleteParticipationsAPIExportReports,
  updateParticipationsAPIExportReports,
} from 'client/ducks/participation-export/actions';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {API_PATH} from 'client/common/config';
import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import './participants-api-settings-popover.scss';

function CopyToClipBoard(str) {
  const textArea = document.createElement('textarea');
  textArea.value = str;
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
}

function getAPIUrl(id) {
  return `${API_PATH}/api_participation_exports/${id}/perform`;
}

function ParticipantsAPISettingsPopover(props) {
  const {languageState, children, id, memberships, from, to, isAdmin, allowed_for_all} = props;
  const API_URL = getAPIUrl(id);
  let LANGUAGE = languageState.payload.PARTICIPANTS_PORT;

  return (
    <Popover
      position="top"
      className="pull-left"
      arrowOffset={90}
      overlayInnerStyle={{
        width: 340,
      }}
      overlay={
        <div className="popover-list popover-list--color-3">
          <h3 className="popover-list__title">{LANGUAGE.API_SETTINGS_POPOVER_TITLE}</h3>
          <h4 className="input__label participants-api-settings-popover__title">{LANGUAGE.SETTINGS_POPOVER.COPY_ID}</h4>
          <div className="participants-api-settings-popover__row-container">
            <span className="participants-api-settings-popover__text">{id}</span>
            <button
              className="button button--circle button--bg-4"
              onClick={() => {
                CopyToClipBoard(id);
              }}
            >
              <Icon name="copy" />
            </button>
          </div>
          <h4 className="input__label participants-api-settings-popover__title">
            {LANGUAGE.SETTINGS_POPOVER.COPY_URL}
          </h4>
          <div className="participants-api-settings-popover__row-container">
            <span
              className="
                participants-api-settings-popover__text
                participants-api-settings-popover__link-api
              "
            >
              {API_URL}
            </span>
            <button
              className="button button--circle button--bg-4"
              onClick={() => {
                CopyToClipBoard(API_URL);
              }}
            >
              <Icon name="copy" />
            </button>
          </div>
          <h4 className="input__label participants-api-settings-popover__title">{LANGUAGE.SETTINGS_POPOVER.PERIOD}</h4>
          <div className="participants-api-settings-popover__text">
            {moment(from).format('DD/MM/YYYY')} - {moment(to).format('DD/MM/YYYY')}
          </div>
          <h4 className="input__label participants-api-settings-popover__title">
            {LANGUAGE.SETTINGS_POPOVER.USER_ALLOWED}
          </h4>
          {allowed_for_all && <div className="participants-api-settings-popover__text">{LANGUAGE.ALLOW_ALL_LABEL}</div>}
          {!allowed_for_all && !memberships.length && (
            <div className="leads-api-export-settings-popover__text">{LANGUAGE.NO_ALLOWED_USERS}</div>
          )}
          {!allowed_for_all &&
            memberships.map((item) => {
              return (
                <div key={item.id} className="participants-api-settings-popover__text">
                  {item.client_user.full_name}
                </div>
              );
            })}
          {isAdmin && (
            <div>
              <div
                className="
                participants-api-settings-popover__row-container
                participants-api-settings-popover__container-margin
              "
              >
                <span>{LANGUAGE.SETTINGS_POPOVER.MODIFY_API}</span>
                <button
                  className="button button--circle button--bg-4"
                  onClick={() => {
                    props.editCallBack(id);
                  }}
                >
                  <Icon name="edit" width={20} height={20} />
                </button>
              </div>
              <div
                className="
                participants-api-settings-popover__row-container
                participants-api-settings-popover__container-margin
              "
              >
                <span className="participants-api-settings-popover__delete-text">
                  {LANGUAGE.SETTINGS_POPOVER.DELETE_API}
                </span>
                <button
                  className="button button--circle button--bg-4"
                  onClick={() => {
                    props.deleteParticipationsAPIExportReports(id, {}).then(() => {
                      props.updateCallBack();
                    });
                  }}
                >
                  <Icon name="trash" width={17} height={19} />
                </button>
              </div>
            </div>
          )}
        </div>
      }
    >
      {children}
    </Popover>
  );
}

ParticipantsAPISettingsPopover.defaultProps = {
  children: '',
};

ParticipantsAPISettingsPopover.propTypes = {
  languageState: PropTypes.object.isRequired,
  children: PropTypes.node,
  id: PropTypes.number,
  memberships: PropTypes.array,
  allowed_for_all: PropTypes.bool,
  from: PropTypes.string,
  to: PropTypes.string,
  deleteParticipationsAPIExportReports: PropTypes.func.isRequired,
  updateParticipationsAPIExportReports: PropTypes.func.isRequired,
  updateCallBack: PropTypes.func.isRequired,
  editCallBack: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    languageState: state.languageState,
    isAdmin: selectIsAdmin(state),
  }),
  {
    deleteParticipationsAPIExportReports,
    updateParticipationsAPIExportReports,
  },
)(ParticipantsAPISettingsPopover);
