import React, {useCallback, useMemo} from 'react';

import {connect, useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';

import {selectEmailSendersSettings} from 'client/ducks/email-senders/selectors';
import {selectOperationEmailTemplates, selectTemplatesStepsMapped} from 'client/ducks/email-templates/selectors';
import {removeOptInFromMessageScenarioStep} from 'client/ducks/opt-in-columns/actions';
import {selectOptIns} from 'client/ducks/opt-in-columns/selectors';
import {selectConcatScenarioVariables} from 'client/ducks/scenario/selectors';

import {TextField, TextareaField, SelectField} from 'client/common/fields';

import OptIns from 'client/components/autotask-scenario/components/ats-scenario-editor/components/forms/opt-ins';
import {EmailTemplate} from 'client/models/email-templates/types';
import {ScenarioStep, ScenarioVariable} from 'client/models/scenarios/types';
import {ApiDispatch, MainStates} from 'client/types';

import {getEmailScenarioVariables, getInitialValues, submitForm, validate} from './helpers';

import {onSubmitSuccess, onSubmitFail} from '../helpers';

import cssModule from './../../ats-step/ats-step.module.scss';

const b = bem('ats-step', {cssModule});

type AtsEmailFormProps = {
  data: ScenarioStep;
  formId: string;
  emailTemplates: EmailTemplate[];
};

const AtsEmailForm: React.FC<AtsEmailFormProps> = (props) => {
  const {data, formId, emailTemplates} = props;

  const lang = useLanguage('AUTOTASK_SCENARIO.SCENARIO_FORMS');

  const emailTemplatesOptions = useSelector(selectTemplatesStepsMapped);
  const emailSenders = useSelector(selectEmailSendersSettings);
  const scenarioVariables: ScenarioVariable[] = useSelector(selectConcatScenarioVariables);
  const optIns = useSelector(selectOptIns);

  const {formValues, change} = useReduxForm(formId, {
    initialValues: getInitialValues(data, {scenarioVariables, emailTemplates, optIns}),
    validate: (values) => validate(values, {lang}),
  });
  const dispatch: ApiDispatch = useDispatch();

  const disabledTemplateField = !!data.implementation?.email_template_id;

  const emailScenarioVariables = useMemo(() => getEmailScenarioVariables(scenarioVariables), [scenarioVariables]);

  const emailRecipientsOptions = useMemo(
    () =>
      emailScenarioVariables.map((emailRecipient: Record<string, any>) => ({
        value: emailRecipient.id,
        label: emailRecipient.name,
      })),
    [emailScenarioVariables],
  );

  const scenarioVariablesOptions = useMemo(
    () =>
      scenarioVariables.map((scenarioVariable) => ({
        label: scenarioVariable.full_name || scenarioVariable.name,
        value: scenarioVariable.id,
        kind: scenarioVariable.kind,
      })),
    [scenarioVariables],
  );

  const getVariableOptions = useCallback(
    (fieldName: string) => {
      if (fieldName === 'test_image') {
        return scenarioVariablesOptions.filter((variable) => variable.kind === 'image');
      }

      return scenarioVariablesOptions;
    },
    [scenarioVariablesOptions],
  );

  const getEmailTemplate = useCallback(
    (id: number) => {
      return emailTemplates.find((template) => {
        return template.id === id;
      });
    },
    [emailTemplates],
  );

  const currentEmailTemplate = useMemo(
    () => getEmailTemplate(formValues.implementation?.email_template_id),
    [formValues.implementation?.email_template_id, getEmailTemplate],
  );

  const onRemoveOptIn = (id: number, field: string) => {
    dispatch(removeOptInFromMessageScenarioStep(id)).then(() => {
      change(`${field}.deleted`, true);
    });
  };

  return (
    <form className={b('form')}>
      <TextField label={lang.STEP_NAME_LABEL} name="name" required={true} />
      <SelectField
        options={emailTemplatesOptions}
        label={lang.EMAIL.TEMPLATE_LABEL}
        name="implementation.email_template_id"
        simpleValue={true}
        disabled={disabledTemplateField}
      />
      <SelectField
        options={emailSenders}
        label={lang.EMAIL.EMAIL_SENDER_LABEL}
        name="implementation.message_sender_id"
        simpleValue={true}
      />
      <SelectField
        label={lang.EMAIL.REPLY_TO_LABEL}
        name="implementation.reply_to_id"
        options={emailSenders}
        simpleValue={true}
        required={true}
      />
      <SelectField
        label={lang.EMAIL.EMAIL_LABEL}
        name="implementation.recipient_variable_id"
        options={emailRecipientsOptions}
        simpleValue={true}
        required={true}
      />

      {!!currentEmailTemplate?.email_template_variables?.length && (
        <div className={b('form-row')}>
          <div className={b('form-title')}>{lang.EMAIL.MAPPING_LABEL}</div>
          <div className={b('form-field-wrap')}>
            {currentEmailTemplate.email_template_variables.map((variable) => (
              <div className={b('form-field', ['size-3'])} key={variable.id}>
                <SelectField
                  simpleValue={true}
                  name={`mapping.${variable.id}.variable_id`}
                  label={variable.name}
                  options={getVariableOptions(variable.name)}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      <OptIns
        title={lang.OPTIN.OPTIN_TYPE_LABEL}
        labels={{
          yes: lang.OPTIN.YES_LABEL,
          no: lang.OPTIN.NO_LABEL,
          satisfies: lang.OPTIN.SATISFIES_LABEL,
          following: lang.OPTIN.FOLLOWING_LABEL,
          all: lang.OPTIN.ALL_LABEL,
          any: lang.OPTIN.ANY_LABEL,
        }}
        checkOptIn={!!formValues?.implementation?.check_opt_ins}
        removeOptInFromMessageScenarioStep={onRemoveOptIn}
      />
      <TextareaField label={lang.COMMENT_LABEL} name="comment" />
    </form>
  );
};

const Form = connect((state: MainStates, props: AtsEmailFormProps) => ({
  form: props.formId,
  emailTemplates: selectOperationEmailTemplates(state),
}))(
  reduxForm<AtsEmailFormProps>({
    onSubmit: submitForm,
    onSubmitSuccess,
    onSubmitFail,
  })(AtsEmailForm),
);

export default Form;
