import React, {useMemo} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {RadioButtonGroupField} from 'client/common/fields';
import RadioPanelTab from 'client/common/radio-panel/radio-panel-tab';

import PrizeDiyCustomListFieldset from 'client/components/prizes/fieldsets/prize-diy-custom-list-fieldset';
import PrizeDiyWeekScheduleFieldset from 'client/components/prizes/fieldsets/prize-diy-week-schedule-fieldset';
import PrizeDiyWeeklyListFieldset from 'client/components/prizes/fieldsets/prize-diy-weekly-list-fieldset';
import {PrizeDiyModalFormName} from 'client/components/prizes/modals/prize-diy-modal';

import {SCHEDULE_TABS, SCHEDULE_TYPE_FIELD_NAME} from './constants';

import './prize-diy-schedule-fieldset.scss';

const b = bem('prize-diy-schedule-fieldset');

const TAB_CONTENTS = {
  [SCHEDULE_TABS.CUSTOM_LIST]: ({disabled}) => (
    <PrizeDiyCustomListFieldset formName={PrizeDiyModalFormName} hasAdditionalControls={true} disabled={disabled} />
  ),
  [SCHEDULE_TABS.WEEKLY_LIST]: PrizeDiyWeeklyListFieldset,
  [SCHEDULE_TABS.SCHEDULE]: PrizeDiyWeekScheduleFieldset,
};

const PrizeDiyScheduleFieldset = (props) => {
  const {value, checked, name, disabled, required = false} = props;
  const {formValues} = useReduxForm(PrizeDiyModalFormName);

  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_SCHEDULE_FIELDSET');

  const header = useMemo(
    () => (
      <RadioButtonGroupField
        labelClassName={b('tab-label')}
        name={SCHEDULE_TYPE_FIELD_NAME}
        disabled={!checked}
        radioButtons={[
          {value: SCHEDULE_TABS.CUSTOM_LIST, label: lang.CUSTOM_LIST_TAB_LABEL},
          {value: SCHEDULE_TABS.WEEKLY_LIST, label: lang.WEEKLY_LIST_TAB_LABEL},
          {value: SCHEDULE_TABS.SCHEDULE, label: lang.WEEK_SCHEDULE_TAB_LABEL},
        ]}
      />
    ),
    [checked, lang],
  );

  const Content = TAB_CONTENTS[formValues[SCHEDULE_TYPE_FIELD_NAME]];

  return (
    <RadioPanelTab
      className={b('panel-tab')}
      value={value}
      label={lang.LABEL}
      name={name}
      checked={checked}
      header={header}
      content={<Content disabled={!checked || disabled} required={required} />}
      disabled={disabled}
    />
  );
};

PrizeDiyScheduleFieldset.propTypes = {
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool,
};

export default PrizeDiyScheduleFieldset;
