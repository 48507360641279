export default {
  GET_COUPONS_REQUEST: '@@coupons/GET_COUPONS_REQUEST',
  GET_COUPONS_SUCCESS: '@@coupons/GET_COUPONS_SUCCESS',
  GET_COUPONS_ERROR: '@@coupons/GET_COUPONS_ERROR',

  CREATE_COUPON_REQUEST: '@@coupons/CREATE_COUPON_REQUEST',
  CREATE_COUPON_SUCCESS: '@@coupons/CREATE_COUPON_SUCCESS',
  CREATE_COUPON_ERROR: '@@coupons/CREATE_COUPON_ERROR',

  UPDATE_COUPON_REQUEST: '@@coupons/UPDATE_COUPON_REQUEST',
  UPDATE_COUPON_SUCCESS: '@@coupons/UPDATE_COUPON_SUCCESS',
  UPDATE_COUPON_ERROR: '@@coupons/UPDATE_COUPON_ERROR',

  DELETE_COUPON_REQUEST: '@@coupons/DELETE_COUPON_REQUEST',
  DELETE_COUPON_SUCCESS: '@@coupons/DELETE_COUPON_SUCCESS',
  DELETE_COUPON_ERROR: '@@coupons/DELETE_COUPON_ERROR',

  TEST_SOGEC_REQUEST: '@@coupons/TEST_SOGEC_REQUEST',
  TEST_SOGEC_SUCCESS: '@@coupons/TEST_SOGEC_SUCCESS',
  TEST_SOGEC_ERROR: '@@coupons/TEST_SOGEC_ERROR',
};
