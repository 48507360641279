import React, {useCallback, useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useToggle} from 'react-use';

import {useQueryParams} from 'client/services/hooks';

import {getInstoreTaskCampaigns} from 'client/ducks/instore-tasks/actions';
import {getInstoreTask} from 'client/ducks/instore-tasks/actions';
import {selectInstoreTask} from 'client/ducks/instore-tasks/selectors';
import {selectInstoreTaskCampaigns} from 'client/ducks/instore-tasks/selectors';
import {getOperation} from 'client/ducks/operations/actions';
import {selectOperation} from 'client/ducks/operations/selectors';

import AnimationWrapper from 'client/components/animation-wrapper';
import AnimationPageContainer from 'client/components/animations/containers/animation-page-container';

const queryParamsConfig = {
  parse: {
    parseNumbers: true,
  },
};

// This page is used only in mobile view
const AnimationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const [queryParams, setQueryParams] = useQueryParams({history, location}, {}, queryParamsConfig);
  const [loading, toggleLoading] = useToggle(false);
  const task = useSelector(selectInstoreTask);
  const operation = useSelector(selectOperation);
  const campaigns = useSelector(selectInstoreTaskCampaigns);

  const fetchTask = useCallback(() => {
    return dispatch(getInstoreTask(params.taskId, {}));
  }, [dispatch, params.taskId]);

  const fetchOperation = useCallback(() => {
    return dispatch(getOperation(params.operationId, {}));
  }, [dispatch, params.operationId]);

  const fetchCampaigns = useCallback(() => {
    dispatch(getInstoreTaskCampaigns({q: {instore_task_id_eq: params.taskId}}));
  }, [dispatch, params.taskId]);

  useEffect(() => {
    toggleLoading(true);
    Promise.all([fetchOperation(), fetchTask(), fetchCampaigns()]).then(() => {
      toggleLoading(false);
    });
  }, [fetchCampaigns, fetchOperation, fetchTask, toggleLoading]);

  useEffect(() => {
    if (!queryParams.campaignId && campaigns.length) {
      setQueryParams({campaignId: campaigns[0].id}, {replace: true});
    }
  }, [queryParams.campaignId, setQueryParams, campaigns]);

  return (
    <AnimationWrapper task={task} operation={operation} loading={loading}>
      <AnimationPageContainer campaignId={queryParams.campaignId} />
    </AnimationWrapper>
  );
};

export default AnimationPage;
