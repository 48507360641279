import React from 'react';

import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useMount} from 'react-use';
import {SubmissionError, clearSubmitErrors} from 'redux-form';

import bem from 'client/services/bem';
import {getIsTrackingUser} from 'client/services/cookie-data-source';
import {useLanguage, useAppMedia} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {authenticate} from 'client/ducks/user/actions';

import AppButton from 'client/common/buttons/app-button';
import PasswordField from 'client/common/fields/password-field';
import TextField from 'client/common/fields/text-field';

import WinningBaseContainer from 'client/components/winnings/containers/winning-base-container';
import {ApiDispatch} from 'client/types';

import {WinningLoginValues} from './types';
import validateValues from './validateValues';

import cssModule from './winning-login.module.scss';

const b = bem('winning-login', {cssModule});

type TestProps = {
  id?: number;
};

const winningLoginFormName = 'WinningLoginForm';

const WinningLogin: ReduxFormFC<TestProps, WinningLoginValues> = (props) => {
  const {form, handleSubmit, error} = props;
  const dispatch: ApiDispatch = useDispatch();
  const lang = useLanguage('WINNINGS.VARIOUS.WINNING_LOGIN');
  const {isTablet} = useAppMedia();
  const history = useHistory();

  useMount(() => {
    if (getIsTrackingUser()) {
      history.push('/');
    }
  });

  const {invalid} = useReduxForm(form, {
    initialValues: {login: '', password: ''},
    validate: (values) => validateValues(values, lang.ERRORS),
  });

  const handleLogin = async (values: WinningLoginValues) => {
    const response = await dispatch(authenticate({prize_tracking_user: {...values}}, 'winning'));
    if (response.error) {
      throw new SubmissionError({
        _error: lang.ERRORS.WRONG_CREDENTIALS,
      });
    } else {
      history.push('/');
    }
  };

  return (
    <WinningBaseContainer
      className={b({mobile: isTablet})}
      classNames={{
        content: b('content'),
      }}
    >
      <h1 className={b('title')}>{lang.TITLE}</h1>
      <form onSubmit={handleSubmit(handleLogin)}>
        <TextField
          className={b('login-field')}
          name="login"
          label={lang.LOGIN}
          inputClassName={b('input')}
          errorMessage={error && ' '}
        />
        <PasswordField
          className={b('password-field')}
          name="password"
          label={lang.PASSWORD}
          inputClassName={b('input')}
          errorMessage={error}
        />
        <div className={b('bottom')}>
          <AppButton className={b('login-button')} label={lang.LOG_IN} size="extra-large" disabled={invalid} submit />
        </div>
      </form>
    </WinningBaseContainer>
  );
};

export default reduxForm<TestProps, WinningLoginValues>({
  form: winningLoginFormName,
  onChange: (values, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(winningLoginFormName));
    }
  },
})(WinningLogin);
