export default {
  GET_VISUALS_REQUEST: '@@visuals/GET_VISUALS_REQUEST',
  GET_VISUALS_SUCCESS: '@@visuals/GET_VISUALS_SUCCESS',
  GET_VISUALS_ERROR: '@@visuals/GET_VISUALS_ERROR',

  GET_VISUAL_DISPLAY_ITEMS_REQUEST: '@@visuals/GET_VISUAL_DISPLAY_ITEMS_REQUEST',
  GET_VISUAL_DISPLAY_ITEMS_SUCCESS: '@@visuals/GET_VISUAL_DISPLAY_ITEMS_SUCCESS',
  GET_VISUAL_DISPLAY_ITEMS_ERROR: '@@visuals/GET_VISUAL_DISPLAY_ITEMS_ERROR',

  PATCH_VISUAL_REQUEST: '@@visuals/PATCH_VISUAL_REQUEST',
  PATCH_VISUAL_SUCCESS: '@@visuals/PATCH_VISUAL_SUCCESS',
  PATCH_VISUAL_ERROR: '@@visuals/PATCH_VISUAL_ERROR',

  GET_AUTOMATIC_VISUAL_GROUPS_REQUEST: '@@visuals/GET_AUTOMATIC_VISUAL_GROUPS_REQUEST',
  GET_AUTOMATIC_VISUAL_GROUPS_SUCCESS: '@@visuals/GET_AUTOMATIC_VISUAL_GROUPS_SUCCESS',
  GET_AUTOMATIC_VISUAL_GROUPS_ERROR: '@@visuals/GET_AUTOMATIC_VISUAL_GROUPS_ERROR',

  GET_VISUAL_GROUPS_REQUEST: '@@visuals/GET_VISUAL_GROUPS_REQUEST',
  GET_VISUAL_GROUPS_SUCCESS: '@@visuals/GET_VISUAL_GROUPS_SUCCESS',
  GET_VISUAL_GROUPS_ERROR: '@@visuals/GET_VISUAL_GROUPS_ERROR',
};
