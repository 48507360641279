import React from 'react';

// components
import InfoBlock from 'client/components/common/info-block';

import {Translation} from 'client/models/language/types';
import {Pagination} from 'client/models/pagination/types';

type AutotaskCardProps = {
  title?: Translation;
  header?: React.ReactNode;
  children?: React.ReactNode;
  aboveHeader?: React.ReactNode;
  className?: string;
  paginationProps?: Pagination;
  showPagination?: boolean;
  emptyText?: Translation;
};

const AutotaskCard: React.FC<AutotaskCardProps> = (props) => {
  const {
    title = '',
    header = '',
    children = '',
    aboveHeader = null,
    className = '',
    paginationProps = {},
    showPagination = true,
    emptyText = '',
  } = props;

  return (
    <InfoBlock className={className} paginationProps={paginationProps} showPagination={showPagination}>
      <InfoBlock.Title>{title}</InfoBlock.Title>
      <InfoBlock.Content>
        {aboveHeader}
        {header && <InfoBlock.Header className="clearfix">{header}</InfoBlock.Header>}
        <InfoBlock.Body emptyText={emptyText}>{children}</InfoBlock.Body>
      </InfoBlock.Content>
    </InfoBlock>
  );
};

export default AutotaskCard;
