import React, {useMemo} from 'react';

import {getMediaLevel} from 'client/components/medias/helpers';
import MediasConfigModal from 'client/components/medias/modals/medias-config-modal';
import {Device} from 'client/models/devices/types';

type ClientDeviceModalMediaTabProps = {
  clientId: number;
  device: Device;
  onClose: () => void;
  onSave: () => void;
  affectationId?: number;
};

const ClientDeviceModalMediaTab: React.FC<ClientDeviceModalMediaTabProps> = (props) => {
  const {clientId, onClose, onSave, device, affectationId} = props;
  const level = useMemo(getMediaLevel, []);

  return (
    <MediasConfigModal
      clientId={clientId}
      level={level}
      device={device}
      onClose={onClose}
      onSave={onSave}
      asForm
      affectationId={affectationId}
    />
  );
};

export default ClientDeviceModalMediaTab;
