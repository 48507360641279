import React from 'react';

import moment from 'moment';

import bem from 'client/services/bem';
import {downloadFileWithAuth} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons';
import Icon from 'client/common/icon';
import SearchInput from 'client/common/inputs/search-input';
import Modal from 'client/common/modals/modal';
import PaginationBar from 'client/common/paginations/pagination-bar';
import Popover from 'client/common/popovers/popover';

import ClientTable from 'client/components/common/client-table';

import {REPORTS_PER_PAGE} from 'client/components/client-autotask/tabs/data-tab/data-common/constants';
import {ParticipationExport} from 'client/models/participation-export/types';
import {StatisticExportMapped} from 'client/models/statistic-exports/types';

import cssModule from './view-files.modal.module.scss';

const b = bem('view-files-modal', {cssModule});

type ReportFile = {
  id: number;
  exportId: number;
  name: string;
  fileName: string;
  comment: string;
  total: number;
  date: string;
  userName: string;
  status: string;
  link: string;
  deletable: boolean;
  shareable: boolean;
  export: ParticipationExport;
};

type ViewFilesModalProps = {
  onClose: () => void;
  files: {
    meta: {
      current_page: number;
      total_count: number;
      total_pages: number;
    };
    items: ReportFile[] | StatisticExportMapped[];
  };
  onPageChange: (page: number) => void;
  search: string;
  onSearch: (search: string) => void;
  openAccessModal?: (fileId: string) => void;
  onDelete: (id: number) => void;
  sortParams: {
    sortOrder: string;
    sortField: string;
  };
  onSortChange: (sortParams: {sortOrder: string; sortField: string}) => void;
  isStatistic?: boolean;
  canShareFiles?: boolean;
};

const ViewFilesModal: React.FC<ViewFilesModalProps> = (props) => {
  const {
    onClose,
    files,
    onPageChange,
    search,
    onSearch,
    openAccessModal,
    onDelete,
    sortParams,
    onSortChange,
    isStatistic,
    canShareFiles = true,
  } = props;

  const lang = useLanguage('DATA_TAB.VIEW_FILES_MODAL');
  const langTable = lang.FILES_TABLE;

  const {sortField, sortOrder} = sortParams;

  const handleSortChange = ({sortOrder: order, sortField: field}: {sortOrder: 'asc' | 'desc'; sortField: string}) => {
    onSortChange({sortOrder: order, sortField: field});
  };

  return (
    <Modal title={lang.VIEW_FILES} onClose={onClose} dialogClassName={b()}>
      <div className={b('search-bar')}>
        <SearchInput
          placeholder={lang.SEARCH_PLACEHOLDER}
          className={b('search-input')}
          onSearch={onSearch}
          searchDefault={search}
        />
        {search && (
          <span>
            {files.meta.total_count} {lang.RECORDS_FOUND}
          </span>
        )}
      </div>
      <ClientTable
        className={b('table')}
        commonHeaderClassNames={{header: b('table-header-label'), th: b('table-header-cell')}}
        commonCellClassName={b('cell')}
        iconClassName={b('no-data-icon')}
        noDataText={langTable.NO_DATA}
        data={files.items}
        sortField={sortField}
        sortOrder={sortOrder}
        onSortChange={handleSortChange}
        columns={[
          {
            label: langTable.FILE_NAME,
            path: 'fileName',
            name: 'fileName',
            width: isStatistic ? 390 : 220,
            sortable: true,
          },
          {
            label: langTable.CREATED_BY,
            path: 'userName',
            name: 'userName',
          },
          {
            label: langTable.RECORDS_NUMBER,
            width: 88,
            cellClassName: b('cell', ['align-right']),
            name: 'total',
            show: !isStatistic,
            render: ({item}) => {
              return item.status === 'pending' ? lang.FILES_TABLE.IN_PROGRESS : item.total;
            },
          },
          {
            label: langTable.COMMENT,
            path: 'comment',
            name: 'comment',
            width: 100,
            render: ({value}: {value: string}) =>
              value && (
                <Popover
                  trigger="both"
                  shiftTop={-5}
                  shiftLeft={5}
                  arrowOffset={10}
                  overlay={
                    <>
                      <p className={b('comment-title')}>{langTable.COMMENT}</p>
                      <p className={b('comment-body')}>{value}</p>
                    </>
                  }
                  position="top"
                  contentClassName={b('comment-container')}
                >
                  <Icon className={b('icon')} name="comments" width={23} height={21} />
                </Popover>
              ),
            cellClassName: b('cell', ['with-icon']),
          },
          {
            label: langTable.CREATED_DATE,
            path: 'date',
            name: 'date',
            width: 94,
            sortable: true,
            render: ({value}: {value: string}) => moment(value).format('DD/MM/YY'),
          },
          {
            label: langTable.SHARE,
            width: 60,
            path: 'id',
            name: 'share',
            show: !isStatistic && canShareFiles,
            render: ({value, item}: {value: string; item: ReportFile}) => {
              return (
                <button
                  type="button"
                  onClick={() => openAccessModal?.(value)}
                  className={b('action-button')}
                  disabled={!item.shareable}
                >
                  <Icon name="share" width={23} height={23} className={b('icon', {disabled: !item.shareable})} />
                </button>
              );
            },
            cellClassName: b('cell', ['with-icon']),
          },
          {
            label: langTable.DOWNLOAD,
            path: 'link',
            name: 'download',
            width: 80,
            render: ({item}) =>
              item.link && (
                <button
                  type="button"
                  onClick={() => downloadFileWithAuth(item.link, item.fileName)}
                  className={b('action-button')}
                >
                  <Icon name="downloads" width={23} height={23} />
                </button>
              ),
            cellClassName: b('cell', ['with-icon']),
          },
          {
            label: langTable.DELETE,
            width: 80,
            path: 'fileName',
            name: 'delete',
            render: ({value, item}: {value: string; item: ReportFile}) => (
              <Popover
                trigger="both"
                contentClassName={b('delete-popover')}
                shiftTop={5}
                shiftLeft={5}
                arrowOffset={90}
                overlay={
                  item.deletable && item.status !== 'pending'
                    ? ({setIsOpen}: {setIsOpen: (state: boolean) => void}) => (
                        <>
                          <p>
                            {lang.DELETE_POPOVER.LABEL} "{value}"?
                          </p>
                          <div className={b('delete-buttons')}>
                            <AppButton
                              className={b('button')}
                              label={lang.DELETE_POPOVER.CANCEL}
                              transparent
                              onClick={() => setIsOpen(false)}
                            />
                            <AppButton
                              className={b('button')}
                              label={lang.DELETE_POPOVER.YES}
                              onClick={() => {
                                onDelete(item.exportId);
                                setIsOpen(false);
                              }}
                            />
                          </div>
                        </>
                      )
                    : null
                }
              >
                <Icon
                  name="trash"
                  width={23}
                  height={23}
                  className={b('icon', {disabled: !item.deletable || item.status === 'pending'})}
                />
              </Popover>
            ),
            cellClassName: b('cell', ['with-icon']),
          },
        ]}
      />
      <PaginationBar
        onPageChange={({page}: {page: number}) => onPageChange(page)}
        data={files.items}
        totalPages={files.meta.total_pages}
        totalItems={files.meta.total_count}
        perPage={REPORTS_PER_PAGE}
        currentPage={files.meta.current_page}
      />
    </Modal>
  );
};

export default ViewFilesModal;
