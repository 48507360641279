import React from 'react';

import PropTypes from 'prop-types';

import {dateToString} from 'client/services/helpers';

import {TextField} from 'client/components/common/fields';

import {DATE_FORMAT} from '../constants';

import './game-period-block.scss';

const GamePeriodBlock = (props) => {
  const {lang, game, isPrizeMap, editable} = props;

  return (
    <div className="game-period-block">
      <div>
        <div className="game-period-block__item">
          <span className="game-period-block__label">{lang.FROM}</span>
          <TextField name="default_from" placeholder={lang.FROM_PLACEHOLDER} withWrap={false} readOnly={!editable} />
        </div>
        {!isPrizeMap && (
          <div className="game-period-block__item game-period-block__item--actual">
            <span className="game-period-block__label">{lang.FROM}</span>
            <span className="game-period-block__value">{dateToString(game.actual_from, DATE_FORMAT)}</span>
          </div>
        )}
      </div>
      <div>
        <div className="game-period-block__item">
          <span className="game-period-block__label">{lang.TO}</span>
          <TextField name="default_to" placeholder={lang.TO_PLACEHOLDER} withWrap={false} readOnly={!editable} />
        </div>
        {!isPrizeMap && (
          <div className="game-period-block__item  game-period-block__item--actual">
            <span className="game-period-block__label">{lang.TO}</span>
            <span className="game-period-block__value">{dateToString(game.actual_to, DATE_FORMAT)}</span>
          </div>
        )}
      </div>
    </div>
  );
};

GamePeriodBlock.propTypes = {
  lang: PropTypes.object.isRequired,
  game: PropTypes.shape({
    actual_from: PropTypes.string,
    actual_to: PropTypes.string,
  }).isRequired,
  isPrizeMap: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default GamePeriodBlock;
