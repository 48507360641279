import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import {RegionAccess} from 'client/models/regions/types';
import {ApiAction} from 'client/types';

import types from './types';

export const getRegionAccesses: ApiAction<{region_accesses: RegionAccess[]}> = (queryParams: Record<string, any>) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}/${API_METHODS.REGION_ACCESS}`,
      method: 'GET',
      types: [types.GET_REGION_ACCESSES_REQUEST, types.GET_REGION_ACCESSES_SUCCESS, types.GET_REGION_ACCESSES_ERROR],
      queryParams,
    });
};
