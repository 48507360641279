import React from 'react';

import bem from 'client/services/bem';
import {blobToBase64} from 'client/services/blobToBase64';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import LogoButton from 'client/common/buttons/logo-button';
import {FieldWrap, SelectField, TextField, TextareaField} from 'client/common/fields';

import {AgencyCreateClientModalFormName} from '../../index';
import {AgencyCreateClientModalFormValues} from '../../types';
import useFetchDataForm from '../../use-fetch-data-form';

import cssModule from './agency-create-client-step-2.module.scss';

type AgencyCreateClientStep2Props = {
  formValues: AgencyCreateClientModalFormValues;
};

const b = bem('agency-create-client-step-2', {cssModule});

const SIREN_COUNTRY = 'FRANCE';

const AgencyCreateClientStep2: React.FC<AgencyCreateClientStep2Props> = () => {
  const lang = useLanguage('AGENCIES.MODALS.AGENCY_CREATE_CLIENT_MODAL.STEPS.AGENCY_CREATE_CLIENT_STEP2');
  const langCommon = useLanguage('COMMON.COUNTRIES');

  const {change, unregisterField, formValues} = useReduxForm<AgencyCreateClientModalFormValues>(
    AgencyCreateClientModalFormName,
  );

  const {categoriesOptions, subCategoriesOptions, agencyOptions} = useFetchDataForm(formValues.category_id);

  const handleUploadLogo = async (file: any) => {
    const src = await blobToBase64(file);
    change('logo_image.data', src);
  };

  const handleCategoryChange = () => {
    change('subcategory', '');
    unregisterField('subcategory');
  };

  return (
    <div className={b()}>
      <div className={b('block')}>
        <p className={b('title')}>
          <span>{langCommon[formValues.country as keyof typeof langCommon]}</span>
          {formValues.country === SIREN_COUNTRY && (
            <span>
              {lang.SIREN_LABEL} {formValues.siren_number}
            </span>
          )}
        </p>
        <LogoButton
          name="logo_image"
          url={formValues?.logo_image?.data || ''}
          className={b('logo')}
          showButton={true}
          label={lang.LOGO_LABEL}
          buttonLabel={lang.CHANGE_LOGO}
          onUpload={handleUploadLogo}
          acceptFormats=".jpg,.jpeg,.png"
        />
      </div>
      <div className={b('block')}>
        <FieldWrap title={lang.CLIENT_TITLE} full={true}>
          <TextField name="name" required={true} />
        </FieldWrap>
        <FieldWrap title={lang.BRAND_NAME_TITLE} full={true}>
          <TextField name="brand_name" required={true} />
        </FieldWrap>
      </div>
      <div className={b('block')}>
        <FieldWrap title={lang.CATEGORY_TITLE} full={true}>
          <SelectField
            name="category_id"
            options={categoriesOptions}
            searchable={false}
            required={true}
            onChange={handleCategoryChange}
            simpleValue={true}
          />
        </FieldWrap>
        <FieldWrap title={lang.SUBCATEGORY_TITLE} full={true}>
          <SelectField
            name="subcategory_id"
            options={subCategoriesOptions}
            searchable={false}
            required={true}
            simpleValue={true}
          />
        </FieldWrap>
      </div>
      <FieldWrap title={lang.ADDRESS_TITLE}>
        <TextareaField classNames={{input: b('textarea')}} name="street_address" withWrap={true} required rows={3} />
        <FieldWrap className={b('address')}>
          <TextField name="zip" label={lang.ZIP_LABEL} className={b('zip-field')} required />
          <TextField name="city" label={lang.CITY_LABEL} className={b('city-field')} required />
        </FieldWrap>
      </FieldWrap>
      <hr />
      <FieldWrap title={lang.AGENCY_CONTACT_TITLE}>
        <SelectField name="poc_agency_membership_id" required={true} options={agencyOptions} simpleValue={true} />
      </FieldWrap>
    </div>
  );
};

export default AgencyCreateClientStep2;
