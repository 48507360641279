export default (values, {validationLang}) => {
  const errors = {optIns: Array.from(values.optIns || [], () => ({}))};

  values.optIns?.forEach((optIn, index) => {
    if (!optIn.column_adapter_id) {
      errors.optIns[index].column_adapter_id = validationLang.REQUIRED;
    }
  });

  return errors;
};
