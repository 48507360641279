import React, {useCallback, useEffect, useRef, useState} from 'react';

import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

function formatNumber(number) {
  return number.toString().length === 1 ? `0${number}` : number;
}

const Timer = ({text, seconds: defaultSeconds, onFinish}) => {
  const [currentSeconds, setCurrentSeconds] = useState(defaultSeconds);
  const timerId = useRef(null);

  const startCountdown = useCallback(() => {
    timerId.current = setInterval(() => {
      setCurrentSeconds((prev) => prev - 1);
    }, 1000);
  }, []);

  const stopCountdown = useCallback(() => {
    if (timerId.current) {
      clearInterval(timerId.current);
      timerId.current = null;
    }
  }, []);

  const finish = useCallback(() => {
    stopCountdown();

    onFinish?.();
  }, [onFinish, stopCountdown]);

  useEffect(() => {
    startCountdown();

    return stopCountdown;
  }, [startCountdown, stopCountdown]);

  useEffect(() => {
    if (!currentSeconds) {
      finish();
    }
  }, [currentSeconds, finish]);

  const hours = formatNumber(Math.floor(currentSeconds / 60 / 60));
  const minutes = formatNumber(Math.floor(currentSeconds / 60) % 60);
  const seconds = formatNumber((currentSeconds % 60) % 60);

  return (
    <span className="timer">
      {text && text + ' '}
      {hours !== '00' && `${hours}:`}
      {minutes}:{seconds}
    </span>
  );
};

Timer.propTypes = {
  seconds: PropTypes.number.isRequired,
  onFinish: PropTypes.func,
  text: TranslationJsx,
};

Timer.defaultProps = {
  onFinish: null,
  text: null,
};

export default Timer;
