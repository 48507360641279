import React from 'react';

import PropTypes from 'prop-types';

import Popover from 'client/common/popovers/popover';

import {TranslationJsx} from 'client/models/language/types';

function BCPopoverStatusState(props) {
  const {texts, status, labels} = props;

  const STATUSES = {
    new: {
      label: labels.new,
      text: texts.new,
      color: '#9B9B9B',
    },
    performing: {
      label: labels.performing,
      text: texts.performing,
      color: '#4A90E2',
    },
    finished: {
      label: labels.finished,
      text: texts.finished,
      color: '#417505',
    },
    sleeping: {
      label: labels.sleeping,
      text: texts.sleeping,
      color: '#F5A623',
    },
  };

  return (
    <Popover
      position="bottom"
      shiftTop={5}
      overlayInnerCssClass="main-text"
      overlayInnerStyle={{
        width: 200,
      }}
      overlay={STATUSES[status] && STATUSES[status].text}
    >
      <div
        style={{
          color: STATUSES[status] ? STATUSES[status].color : '',
        }}
      >
        {STATUSES[status] && STATUSES[status].label}
      </div>
    </Popover>
  );
}

BCPopoverStatusState.propTypes = {
  texts: PropTypes.shape({
    new: TranslationJsx.isRequired,
    performing: TranslationJsx.isRequired,
    finished: TranslationJsx.isRequired,
    sleeping: TranslationJsx.isRequired,
  }).isRequired,
  labels: PropTypes.shape({
    new: TranslationJsx.isRequired,
    performing: TranslationJsx.isRequired,
    finished: TranslationJsx.isRequired,
    sleeping: TranslationJsx.isRequired,
  }).isRequired,
  status: PropTypes.string.isRequired,
};

export default BCPopoverStatusState;
