export const EVENTS_META = {
  TYPE_META_SET: 'TYPE_META_SET',
};

export function set(meta) {
  return (dispatch) => {
    return dispatch({
      type: EVENTS_META.TYPE_META_SET,
      payload: meta,
    });
  };
}
