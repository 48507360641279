export default {
  GET_EXPORTS_REQUEST: '@@statistic-exports/GET_EXPORTS_REQUEST',
  GET_EXPORTS_SUCCESS: '@@statistic-exports/GET_EXPORTS_SUCCESS',
  GET_EXPORTS_ERROR: '@@statistic-exports/GET_EXPORTS_ERROR',

  GET_EXPORTS_COUNT_REQUEST: '@@statistic-exports/GET_EXPORTS_COUNT_REQUEST',
  GET_EXPORTS_COUNT_SUCCESS: '@@statistic-exports/GET_EXPORTS_COUNT_SUCCESS',
  GET_EXPORTS_COUNT_ERROR: '@@statistic-exports/GET_EXPORTS_COUNT_ERROR',

  CREATE_EXPORT_REQUEST: '@@statistic-exports/CREATE_EXPORT_REQUEST',
  CREATE_EXPORT_SUCCESS: '@@statistic-exports/CREATE_EXPORT_SUCCESS',
  CREATE_EXPORT_ERROR: '@@statistic-exports/CREATE_EXPORT_ERROR',

  DELETE_EXPORT_REQUEST: '@@statistic-exports/DELETE_EXPORT_REQUEST',
  DELETE_EXPORT_SUCCESS: '@@statistic-exports/DELETE_EXPORT_SUCCESS',
  DELETE_EXPORT_ERROR: '@@statistic-exports/DELETE_EXPORT_ERROR',
};
