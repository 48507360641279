import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getOptInsAction(params = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        queryParams: params,
        endpoint: `${API_PATH}${API_METHODS.OPT_IN_COLUMNS}`,
        method: 'GET',
        types: [types.GET_OPT_INS_REQUEST, types.GET_OPT_INS_SUCCESS, types.GET_OPT_INS_ERROR],
      },
      true,
    );
  };
}

export function removeOptInFromMessageScenarioStep(mappingId) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.MESSAGE_SCENARIO_STEP_TO_OPT_IN_COLUMNS}/${mappingId}`,
        method: 'DELETE',
        types: [
          types.OPT_IN_FROM_MESSAGE_SCENARIO_STEP_DELETING,
          types.OPT_IN_FROM_MESSAGE_SCENARIO_STEP_DELETED,
          types.OPT_IN_FROM_MESSAGE_SCENARIO_STEP_DELETE_FAILED,
        ],
      },
      true,
    );
  };
}

export function flushOptinsColumnsAllState() {
  return {
    type: types.FLUSH_OPTINS_COLUMNS_ALL_STATE,
  };
}
