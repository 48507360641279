import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import {Prize} from 'client/models/prizes/types';
import {ApiAction} from 'client/types';

import types from './types';

export const getLotteryPrizes: ApiAction<Prize[]> = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PRIZES}`,
      noCache: true,
      method: 'GET',
      types: [types.GET_LOTTERY_PRIZES_REQUEST, types.GET_LOTTERY_PRIZES_SUCCESS, types.GET_LOTTERY_PRIZES_ERROR],
      queryParams,
    });
};

export const changeLotteryPrizeOrder = (prizes: Prize[]) => ({
  type: types.CHANGE_LOTTERY_PRIZE_ORDER,
  payload: {prizes},
});
