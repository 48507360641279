import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {NumberField} from 'client/common/fields';

import cssModule from './prize-diy-ratio-participation-fieldset.module.scss';

const b = bem('prize-diy-ratio-participation-fieldset', {cssModule});

type PrizeDiyRatioParticipationFieldsetProps = {
  disabled?: boolean;
  className?: string;
  classNames?: {
    label?: string;
    input?: string;
  };
  required?: boolean;
};

const PrizeDiyRatioParticipationFieldset: React.FC<PrizeDiyRatioParticipationFieldsetProps> = (props) => {
  const {disabled, className, classNames, required = false} = props;
  const lang = useLanguage('PRIZES.ELEMENTS.PRIZE_DIY_PARTICIOATION_HEADER');

  return (
    <div className={cn(b(), className)}>
      <span className={cn(b('description'), classNames?.label)}>{lang.INPUT_LABEL_1}</span>
      <NumberField
        name="ratioParticipation.units_number"
        className={cn(b('input'), classNames?.input)}
        disabled={disabled}
        required={required}
      />
      <span className={cn(b('description'), classNames?.label)}>{lang.INPUT_LABEL_2}</span>
    </div>
  );
};
export default PrizeDiyRatioParticipationFieldset;
