import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import fieldTemplate from 'client/components/common/field';

class TestPostForm extends PureComponent {
  constructor() {
    super();
  }

  render() {
    const {id, languageState: LANGUAGE} = this.props;

    return (
      <div>
        <div className="test-step__form-block">
          <Field
            label={LANGUAGE.RUN_LABEL}
            name={`_${id}.name`}
            type="radio"
            value="normal"
            component={fieldTemplate}
          />
        </div>
        <div className="test-step__form-block">
          <Field label={LANGUAGE.SKIP_LABEL} name={`_${id}.name`} type="radio" value="skip" component={fieldTemplate} />
        </div>
      </div>
    );
  }
}

TestPostForm.propTypes = {
  id: PropTypes.number,
  change: PropTypes.func,
  initialize: PropTypes.func,
  form: PropTypes.string,
  formValues: PropTypes.object,
  initialValues: PropTypes.object,
  languageState: PropTypes.object.isRequired,
};

export default TestPostForm;
