import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';

export default class TestBranchStep extends PureComponent {
  getFormValue = (values, id, defaultVal = false) => {
    const targetKey = Object.keys(values).filter((key) => Number(key.slice(1)) === id)[0];
    return values[targetKey] || defaultVal;
  };

  renderCondition = (condition) => {
    const {operator, value, value_variable, variable} = condition;
    const variableText = this.props.languageState.VARIABLE_TEXT;

    let displayedValue;

    if (value_variable) {
      displayedValue = value_variable.full_name || value_variable.name;
    } else if (['is_null', 'is_not_null'].includes(operator)) {
      displayedValue = null;
    } else {
      displayedValue = value;
    }

    return (
      <div className="test-step__form-row" key={condition.id}>
        <div className="fake-input fake-input--disabled">
          <div className="fake-input__field">
            {variableText} {variable.full_name || variable.name} {operator} {displayedValue}
          </div>
        </div>
      </div>
    );
  };

  renderConditions = (conditions, formValues) => {
    return conditions.map((condition) => this.renderCondition(condition, formValues));
  };

  render() {
    const {
      implementation: {branch_scenario_step_conditions, operator, scenario_id, scenario},
      comment,
    } = this.props.step;
    const {formValues, languageState: LANGUAGE} = this.props;

    return (
      <div>
        <div className="test-step__form-block">
          <div className="test-step__form-title">
            {LANGUAGE.IF_SATISFIED_TEXT} {operator} {LANGUAGE.FOLLOWING_TEXT}:
          </div>
          {this.renderConditions(branch_scenario_step_conditions, formValues)}
        </div>
        <div className="test-step__form-block">
          <div className="test-step__form-title">
            {LANGUAGE.THEN_TEXT}:{' '}
            {scenario_id === null
              ? LANGUAGE.STOP_CURRENT_SCENARIO_TEXT
              : `${LANGUAGE.GO_TO_SCENARIO_TEXT} ${scenario.name}`}
          </div>
        </div>
        <div className="test-step__form-block">
          <div className="test-step__form-title">{LANGUAGE.CONTINUE_SCENARIO_TEXT}</div>
        </div>
        {comment && comment.length > 0 && (
          <div className="test-step__form-block">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{LANGUAGE.COMMENT_TEXT}</div>
              <div className="fake-input__textarea">{comment}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

TestBranchStep.propTypes = {
  formValues: PropTypes.object,
  step: PropTypes.object,
  variables: PropTypes.arrayOf(PropTypes.object),
  languageState: PropTypes.object.isRequired,
};
