import React, {useCallback, useEffect, useMemo, useState} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect, useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {reduxForm, SubmissionError} from 'redux-form';

import bem from 'client/services/bem';
import {maskEmail} from 'client/services/helpers';
import {useReduxForm} from 'client/services/hooks';

import {recover} from 'client/ducks/user/actions';
import {selectCurrentUser, selectIsPasswordExpired} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import Timer from 'client/common/timer';

import {TranslationJsx} from 'client/models/language/types';

import validate from './validate';

import AuthButton from '../components/auth-button';
import AuthField from '../components/auth-field';
import AuthLink from '../components/auth-link';
import AuthMessage from '../components/auth-message';

import './auth-password-recovery.scss';

const b = bem('auth-password-recovery');

const AuthPasswordRecovery = ({
  history,
  handleSubmit,
  submitting,
  valid,
  lang,
  submitFailed,
  error,
  pristine,
  errors,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const isPasswordExpired = useSelector(selectIsPasswordExpired);
  const [blockedTemporary, setBlockedTemporary] = useState(false);
  const [blockSeconds, setBlockSeconds] = useState(0);
  const [blockedByOtp, setBlockedByOtp] = useState(false);
  const [isRecoverySuccess, setIsRecoverySuccess] = useState(0);
  const {formValues: {email} = {}} = useReduxForm(AuthPasswordRecovery.formName);

  const isExpired = !!history.location?.state?.expired;

  const unblock = useCallback(() => {
    setBlockedByOtp(false);
    setBlockedTemporary(false);
    setBlockSeconds(0);
  }, []);

  const errorText = useMemo(() => {
    switch (true) {
      case blockedTemporary:
        return <Timer seconds={blockSeconds} onFinish={unblock} text={lang.BLOCKED_TEMPORARY} />;
      case blockedByOtp:
        return lang.BLOCKED_BY_OTP;
      case isExpired:
        return lang.EXPIRED_LINK;
      case isPasswordExpired:
        return lang.PASSWORD_EXPIRED;
      case submitFailed:
        return error;
      default:
        return errors?.email;
    }
  }, [
    blockedTemporary,
    blockSeconds,
    unblock,
    lang,
    blockedByOtp,
    isExpired,
    isPasswordExpired,
    submitFailed,
    error,
    errors?.email,
  ]);

  useEffect(() => {
    if (user?.id) {
      history.push('/');
    }
  }, [user?.id, history]);

  const recoverPassword = useCallback(
    (formValues) => {
      return dispatch(recover({user: {email: formValues.email}})).then((response) => {
        if (response.error) {
          const unlockAfter = get(response, 'payload.response.unlock_after');
          const responseBlockOtp = get(response, 'payload.response.locked_by') === 'otp';

          if (unlockAfter) {
            setBlockSeconds(unlockAfter);
            setBlockedTemporary(!!unlockAfter);
            return;
          }
          if (responseBlockOtp) {
            setBlockedByOtp(responseBlockOtp);
            return;
          }
          throw new SubmissionError({email: lang.NO_EMAIL_ERROR});
        }
        setIsRecoverySuccess(true);
      });
    },

    [dispatch, lang.NO_EMAIL_ERROR],
  );

  return (
    <form noValidate="noValidate" onSubmit={handleSubmit(recoverPassword)} className={b()}>
      {isRecoverySuccess ? (
        <div>
          <AuthMessage text={`${lang.SENT_TO_TEXT} ${maskEmail(email)}`} />
          <AuthMessage text={lang.CHECK_EMAIL_TEXT} />
        </div>
      ) : (
        <div className={b('field')}>
          <AuthField
            name="email"
            label={lang.EMAIL_PLACEHOLDER}
            placeholder={lang.EMAIL_PLACEHOLDER}
            disabled={blockedTemporary}
            errorMessage={errorText}
          />
        </div>
      )}
      <div className={b('field')}>
        <AuthLink nativeLink to={CLIENT_PAGES.LOGIN} disabled={blockedTemporary} label={lang.BACK_LINK} />
      </div>
      <AuthButton
        submit
        label={lang.REQUEST_BUTTON}
        disabled={submitting || !valid || pristine || blockedTemporary || isRecoverySuccess}
      />
    </form>
  );
};

AuthPasswordRecovery.displayName = 'AuthPasswordRecovery';
AuthPasswordRecovery.formName = 'AuthPasswordRecoveryForm';

AuthPasswordRecovery.propTypes = {
  history: PropTypes.object.isRequired,
  lang: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  error: TranslationJsx,
};

AuthPasswordRecovery.defaultProps = {
  error: null,
};

const passwordRecoveryForm = reduxForm({
  form: AuthPasswordRecovery.formName,
  validate,
})(AuthPasswordRecovery);

export default withRouter(
  connect((state) => ({
    lang: state.languageState.payload.AUTH.PASSWORD_RECOVERY,
  }))(passwordRecoveryForm),
);
