import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import MessageTaskList from 'client/components/operation/components/tables/message-task-list';
import {MessageTask} from 'client/models/message-task/types';
import {TASK_TYPES} from 'client/models/operations/constants';

import OperationCard from '../operation-card';

import cssModule from './operation-message-task-card.module.scss';

const b = bem('operation-message-task-card', {cssModule});

type OperationMessageTaskCardProps = {
  task: MessageTask;
  onClicksCountClick: (clicksStatistic: any) => void;
};

const OperationMessageTaskCard: React.FC<OperationMessageTaskCardProps> = ({task, onClicksCountClick}) => {
  const lang = useLanguage('OPERATION_PAGE.MESSAGE_TASK_CARD');

  const getTitle = (taskType: string) => {
    return taskType !== TASK_TYPES.EMAIL ? lang.SMS_TITLE : lang.EMAIL_TITLE;
  };

  return (
    <OperationCard className={b()} title={getTitle(task.taskType)}>
      <MessageTaskList
        task={task}
        onClicksCountClick={() => onClicksCountClick(task.clicksStatistic)}
        className={b('block')}
      />
    </OperationCard>
  );
};

export default OperationMessageTaskCard;
