import React from 'react';

import {useDispatch} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {createGame} from 'client/ducks/games/actions';

import {DatetimePickerField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import {TextField, DropdownField} from 'client/components/common/fields';

import {GAME_TYPES} from 'client/models/prizes/constants';
import {Game, GameDraw} from 'client/models/prizes/types';
import {ApiDispatch} from 'client/types';

import validate from './validate';

import cssModule from './add-game-modal.module.scss';

const b = bem('add-game-modal', {cssModule});

type AddGameModalProps = {
  winNumber: number;
  fetchGames: () => Promise<any>;
  onClose: () => void;
  autotaskId: number;
};

export type AddGameFormType = Pick<Game, 'name' | 'game_type'> & {
  game_draws?: Pick<GameDraw, 'default_draw_time' | 'default_to' | 'default_from'>[];
};

const AddGameModal: ReduxFormFC<AddGameModalProps, AddGameFormType> = (props) => {
  const {handleSubmit, winNumber, valid, onClose, fetchGames, autotaskId} = props;

  const lang = useLanguage('GAMES.GAMES_CARD.ADD_GAME_MODAL');
  const dispatch: ApiDispatch = useDispatch();

  const {formValues} = useReduxForm<AddGameFormType>('AddGameModalForm', {
    initialValues: {
      game_type: GAME_TYPES.INSTANT_WIN,
    },
    validate: (values) => validate(values, {lang}),
  });

  const handleSave = async (values: AddGameFormType) => {
    const data: {game: Partial<Game>} = {
      game: {
        automation_task_id: autotaskId,
        win_number: winNumber,
        name: values.name,
        game_type: values.game_type,
      },
    };

    if (values.game_type === GAME_TYPES.PRIZE_DRAW) {
      data.game.game_draws = values.game_draws as GameDraw[];
      data.game.default_to = values.game_draws?.[0]?.default_to;
      data.game.default_from = values.game_draws?.[0]?.default_from;
      data.game.default_draw_time = values.game_draws?.[0].default_draw_time;
      data.game.game_draws[0].name = values.name;
    }

    dispatch(createGame(data)).then(fetchGames).then(onClose);
  };

  return (
    <Modal className={b()} show={true} title={`${lang.ADD_GAME} ${winNumber}`} onClose={onClose}>
      <form onSubmit={handleSubmit(handleSave)} className={b('form')}>
        <TextField name="name" label={lang.GAME_NAME} withWrap />
        <DropdownField
          name="game_type"
          label={lang.GAME_TYPE}
          withWrap
          options={[
            {value: GAME_TYPES.INSTANT_WIN, label: lang.INSTANT_WIN},
            {value: GAME_TYPES.PRIZE_DRAW, label: lang.PRIZE_DRAW},
            {value: GAME_TYPES.ATTRIBUTION_STEP, label: lang.ATTRIBUTION},
          ]}
        />
        {formValues?.game_type === GAME_TYPES.PRIZE_DRAW && (
          <>
            <DatetimePickerField name="game_draws[0].default_from" withWrap label={lang.FROM_BY_DEFAULT} />
            <DatetimePickerField name="game_draws[0].default_to" withWrap label={lang.TO_BY_DEFAULT} />
            <DatetimePickerField
              name="game_draws[0].default_draw_time"
              withWrap
              label={lang.DRAW_MOMENT}
              className={b('field')}
            />
          </>
        )}
        <div className={b('buttons')}>
          <button className="button button--bg-4" type="submit" disabled={!valid}>
            {lang.CREATE_GAME}
          </button>
        </div>
      </form>
    </Modal>
  );
};

const AddGameModalForm = reduxForm<AddGameModalProps, AddGameFormType>({
  form: 'AddGameModalForm',
})(AddGameModal);

export default AddGameModalForm;
