import React, {Component} from 'react';

import {matchRoutes} from 'app/routes';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {APP_ROLES} from 'client/common/config';

import NavigationAdminVersion from './components/navigation-admin-version';
import NavigationClientVersion from './components/navigation-client-version';

// components
import CustomScrollbars from '../custom-scrollbars';

import './navigation.scss';

class Navbar extends Component {
  static propTypes = {
    route: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    lang: PropTypes.object.isRequired,
    userState: PropTypes.object.isRequired,
    isViewModeActive: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      openedItem: '',
      itemWithActiveLink: '',
      currentUrl: '',
    };
  }

  componentDidMount() {
    const {route, location} = this.props;
    const branch = matchRoutes(route.routes, location.pathname);
    const params = branch[0].route.params;

    // get current route to open corresponding parent item
    if (params && params.navGroup) {
      this.setState({
        openedItem: params.navGroup,
        itemWithActiveLink: params.navGroup,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {route, location} = this.props;

    if (route !== prevProps.route || location !== prevProps.location) {
      const branch = matchRoutes(route.routes, location.pathname);

      this.setState({
        openedItem: branch[0].route.params.navGroup,
        itemWithActiveLink: branch[0].route.params.navGroup,
        currentUrl: branch[0].match.url,
      });
    }
  }

  handleLinkClick = (e) => {
    const currentTarget = e.currentTarget;

    let target = e.target;
    let dataNavItem = null;
    let dataChildOf = null;
    let dataOpened = '';

    while (target !== currentTarget) {
      dataNavItem = target.getAttribute('data-nav-item');
      dataChildOf = target.getAttribute('data-child-of');

      if (dataNavItem) {
        dataOpened = target.getAttribute('data-nav-item-opened');

        // if true: item has child links
        // if false: item has no child links, it is link itself
        if (dataOpened !== null) {
          this.setState({
            openedItem: dataOpened === 'true' ? '' : dataNavItem,
          });
        } else {
          this.setState({
            openedItem: '',
            itemWithActiveLink: '',
          });
        }

        return true;
      }

      // if item is child link
      if (dataChildOf) {
        this.setState({
          itemWithActiveLink: dataChildOf,
        });
        // prevent bubbling
        return false;
      }

      target = target.parentNode;
    }

    return true;
  };

  handleMenuLeave = () => {
    this.scrollbar.scrollToTop();

    this.setState({
      openedItem: this.state.itemWithActiveLink,
    });
  };

  render() {
    const {lang, userState, isViewModeActive} = this.props;
    const {openedItem, currentUrl} = this.state;
    let isSuperAdmin = userState.payload.role === APP_ROLES.SUPER_ADMIN;
    let isAdmin = userState.payload.role === APP_ROLES.ADMIN;
    let navBar;
    let navigationModifier = '';

    const adminNavbar = (
      <NavigationAdminVersion
        openedItem={openedItem}
        currentUrl={currentUrl}
        language={lang}
        isSuperAdmin={isSuperAdmin}
      />
    );

    const clientNavbar = <NavigationClientVersion openedItem={openedItem} currentUrl={currentUrl} language={lang} />;

    switch (true) {
      case (isAdmin || isSuperAdmin) && !isViewModeActive:
        navBar = adminNavbar;
        break;
      default:
        navBar = clientNavbar;
        navigationModifier = 'navigation--client-version';
    }

    return (
      <nav
        className={`navigation ${navigationModifier}`}
        onClick={this.handleLinkClick}
        onMouseLeave={this.handleMenuLeave}
      >
        <div className="navigation__content-wrapper">
          <CustomScrollbars
            cssModifier="custom-scrollbars--view-3"
            getRef={(scrollbar) => {
              this.scrollbar = scrollbar;
            }}
            scrollbarProps={{
              autoHide: true,
              autoHideTimeout: 200,
              autoHideDuration: 100,
              autoHeight: false,
            }}
          >
            {navBar}
          </CustomScrollbars>
        </div>
      </nav>
    );
  }
}

export default connect((state) => ({
  lang: state.languageState.payload.NAVIGATION,
  userState: state.userState,
}))(Navbar);
