import {RSAA} from 'redux-api-middleware';

import {callApi} from 'client/services/call-api';
import {getQueryParams} from 'client/services/helpers';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function toggleMapModalAction() {
  return {
    type: types.TOGGLE_MAP_MODAL,
  };
}

export function toggleAddPlaceModalAction() {
  return {
    type: types.TOGGLE_ADD_PLACE_MODAL,
  };
}

export function toggleLinkToClientModalAction() {
  return {
    type: types.TOGGLE_LINK_TO_CLIENT_MODAL,
  };
}

export function changeSelectedPlacesAction(selectedPlaces) {
  return {
    type: types.CHANGE_SELECTED_PLACES,
    selectedPlaces,
  };
}

export function getPlacesAction(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PLACES}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_PLACES_REQUEST, types.GET_PLACES_SUCCESS, types.GET_PLACES_ERROR],
      queryParams,
    });
}

export function getPlacesWithInteractionsAction(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PLACES_WITH_FILTER}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_PLACES_WITH_FILTER_REQUEST,
        types.GET_PLACES_WITH_FILTER_SUCCESS,
        types.GET_PLACES_WITH_FILTER_ERROR,
      ],
      queryParams,
    });
}

export function getMappedPlacesAction(search) {
  return (dispatch) => {
    const queryParams = getQueryParams({
      include: ['client'],
      q: {
        latitude_not_null: true,
        name_cont: search,
      },
    });
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.PLACES}?${queryParams}q[s][]=latitude&q[s][]=longitude`,
        method: 'GET',
        types: [types.GET_MAPPED_PLACES_REQUEST, types.GET_MAPPED_PLACES_SUCCESS, types.GET_MAPPED_PLACES_ERROR],
        isAuth: true,
      },
    });
  };
}

export function addPlaceAction(place) {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.PLACES}`,
        method: 'POST',
        types: [types.ADD_PLACE_REQUEST, types.ADD_PLACE_SUCCESS, types.ADD_PLACE_ERROR],
        isAuth: true,
        body: JSON.stringify({
          place,
        }),
      },
    });
  };
}

export function editPlaceAction(id, place) {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.PLACES}/${id}`,
        method: 'PUT',
        types: [types.EDIT_PLACE_REQUEST, types.EDIT_PLACE_SUCCESS, types.EDIT_PLACE_ERROR],
        isAuth: true,
        body: JSON.stringify({
          place,
        }),
      },
    });
  };
}

export function linkToClientAction({id, ...place}) {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.PLACES}/${id}`,
        method: 'PATCH',
        types: [types.LINK_TO_CLIENT_REQUEST, types.LINK_TO_CLIENT_SUCCESS, types.LINK_TO_CLIENT_ERROR],
        isAuth: true,
        body: JSON.stringify({
          place,
        }),
      },
    });
  };
}
