import React from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import bem from 'client/services/bem';
import {useAppMedia} from 'client/services/hooks';

import {selectClientParams} from 'client/ducks/operations/selectors';

import TaskHeader from 'client/common/header/task-header';

import CustomLink from 'client/components/common/custom-link';
import LoadingSpinner from 'client/components/common/loading-spinner';

import './animation-wrapper.scss';

const b = bem('animation-wrapper');

const AnimationWrapper = (props) => {
  const {loading, task, children} = props;
  const {isTablet} = useAppMedia();
  const params = useParams();
  const history = useHistory();
  const clientParams = useSelector(selectClientParams);

  const handleBackClick = () => {
    const link = CustomLink.createClientOperationsLink({
      clientId: params.clientId,
    });
    history.push(link + clientParams);
  };

  return (
    <div className={b()}>
      <LoadingSpinner loading={loading} className={b('loader')}>
        {isTablet && (
          <>
            <TaskHeader task={task} handleBackClick={handleBackClick} />
            <div className={b('content')}>{children}</div>
          </>
        )}
      </LoadingSpinner>
    </div>
  );
};

AnimationWrapper.propTypes = {
  loading: PropTypes.bool,
  task: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image_url: PropTypes.string,
  }),
  children: PropTypes.node,
};

AnimationWrapper.defaultProps = {
  loading: false,
  task: null,
};

export default AnimationWrapper;
