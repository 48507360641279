import types from './types';

const initialState = {
  scenarioExecutions: [],
  scenario_execution: {
    scenario: {
      autotask: {},
      id: null,
      scenario_steps: [],
    },
    variable_values: [],
  },
  variables: [],
  meta: {},
};

export default function (_state = initialState, action) {
  const state = Object.assign({}, _state);

  switch (action.type) {
    case types.GET_SCENARIO_EXECUTIONS_SUCCESS:
      state.scenarioExecutions = action.payload.scenario_executions;
      state.meta = action.payload.meta;
      return state;

    case types.GET_VARIABLES_SUCCESS:
      state.variables = action.payload.variables;
      return state;

    case types.CLEAR_SCENARIO_RUN_TABLE:
      return {...initialState};

    default:
      return state;
  }
}
