import moment from 'moment';

import {COUPON_TOKEN_MODES} from 'client/models/prizes/constants';

import {CommonCouponValues} from './types';

export default (values: CommonCouponValues, shouldMap = true) => {
  if (!shouldMap) {
    return {};
  }

  const result: Record<string, any> = {token_mode: values.token_mode};

  if (values.token_mode === COUPON_TOKEN_MODES.PERMANENT) {
    result.token_code = values.token_code;
  } else {
    result.coupon_code_intervals = [
      ...values.initial_interval_ids.map((id) => ({id, _destroy: true})),
      ...values.coupon_code_intervals.map((interval) => ({
        ...interval,
        from: moment(interval.from, 'DD/MM/YYYY').hour(0).minutes(0).seconds(0).toISOString(),
        to: moment(interval.to, 'DD/MM/YYYY').hour(23).minutes(59).seconds(59).toISOString(),
      })),
    ];
  }

  return result;
};
