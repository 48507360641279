import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Popover from 'client/common/popovers/popover';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import './store-popover.scss';

const b = bem('store-popover');

const StorePopover = (props) => {
  const {name, id, stores = [], location} = props;
  const {pathname} = location;

  const lang = useLanguage('REGIONS');

  if (stores.length === 0) {
    return <span className={b('text')}>0</span>;
  }

  const storesHref = `${pathname}/stores?regionId=${id}`;

  return (
    <Popover
      arrowOffset={75}
      overlay={
        <div className={b()}>
          <h3 className={b('title')}>
            {name}
            <Link to={storesHref} className={b('title-link')}>
              {stores.length} {lang.STORES_LABEL}
            </Link>
          </h3>
          <CustomScrollbars
            scrollbarProps={{
              autoHeightMax: 150,
            }}
          >
            <ul className={cn('u-list', b('list'))}>
              {stores.map((store) => (
                <li className="u-list__item" key={store.id}>
                  <span className={b('info-city')}>{store.city_name}</span>
                  <span className={b('info-name')}>{store.name}</span>
                </li>
              ))}
            </ul>
          </CustomScrollbars>
        </div>
      }
    >
      <span className={b('text')}>{stores.length}</span>
    </Popover>
  );
};

StorePopover.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      city_name: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ),

  // from react-router
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(StorePopover);
