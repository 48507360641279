import React, {useMemo} from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch, useReduxForm} from 'client/services/hooks';

import {selectRegions} from 'client/ducks/client-users/selectors';
import {getPlacesAction} from 'client/ducks/places/actions';
import {selectCurrentMembership} from 'client/ducks/user-clients/selectors';

import Accordion from 'client/common/accordion';
import AwaitContainer from 'client/common/await-container';
import {CheckListField} from 'client/common/fields';
import WarningLabel from 'client/common/typography/warning-label';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {ClientUserModalFormName} from 'client/components/client-account-parameters/modals/client-user-modal';
import {ClientUserModalForm} from 'client/components/client-account-parameters/modals/client-user-modal/types';
import {Client} from 'client/models/client/types';
import {Place} from 'client/models/places/types';

import ClientUserTitle from '../client-user-title';

import cssModule from './client-user-stores-step.module.scss';

const b = bem('client-user-stores-step', {cssModule});

type ClientUserStoresStepProps = {
  client: Client;
};

const ClientUserStoresStep: React.FC<ClientUserStoresStepProps> = ({client}) => {
  const lang = useLanguage('CLIENT_ACCOUNT_PARAMETERS.MODALS.CLIENT_USER');

  const {formValues} = useReduxForm<ClientUserModalForm>(ClientUserModalFormName);

  // Regions were requested in '../client-user-membership-step
  const regions = useSelector(selectRegions);

  const currentMembership = useSelector(selectCurrentMembership);
  const membership = formValues.memberships?.[0];
  const isCurrentUser = currentMembership?.id && membership?.id === currentMembership?.id;

  const {data, loading} = useReduxFetch<{places: Place[]}>({
    action: getPlacesAction,
    actionArgs: {
      include: ['places'],
      q: {
        client_id_eq: client.id,
        region_id_null: true,
      },
    },
  });

  const options = useMemo(() => {
    const result = regions.map((region) => ({
      value: region.id.toString(),
      label: region.name,
      options:
        region.places?.map((place) => ({
          value: place.id.toString(),
          label: place.name,
        })) || [],
    }));

    if (data?.places.length) {
      result.unshift({
        value: 'without_region',
        label: lang.STORES_WITHOUT_REGION?.toString(),
        options: data.places.map((place) => ({
          value: place.id.toString(),
          label: place.name,
        })),
      });
    }

    return result;
  }, [lang, regions, data?.places]);

  return (
    <AwaitContainer loading={loading} className={b()}>
      <ClientUserTitle
        email={formValues.email}
        civility={formValues.title}
        firstName={formValues.first_name}
        lastName={formValues.last_name}
      />
      <p className={b('block-title')}>
        {lang.ACCESS_TO_STORES}
        {isCurrentUser && <WarningLabel label={lang.ERRORS.CANT_MODIFY} />}
      </p>
      <CustomScrollbars
        scrollbarProps={{
          autoHeightMax: 301,
        }}
      >
        {options.map(
          (option) =>
            !!option.options.length && (
              <Accordion
                title={option.label}
                key={option.value}
                classNames={{title: b('region-title')}}
                className={b('region')}
                destroy={false}
              >
                <CheckListField
                  options={option.options}
                  name="memberships[0].place_accesses"
                  inversionColor
                  color="primary"
                  disabled={isCurrentUser}
                  className={b('region-body')}
                />
              </Accordion>
            ),
        )}
      </CustomScrollbars>
    </AwaitContainer>
  );
};

export default ClientUserStoresStep;
