import React, {Component} from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Popover from 'client/common/popovers/popover';

import CustomLink from 'client/components/common/custom-link';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';

function CodeCell({data, rowIndex, field}) {
  return (
    <Cell>
      <div className="ellipsis-text">{data[rowIndex][field]}</div>
    </Cell>
  );
}

CodeCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

function TypeCell({data, rowIndex, field}) {
  return <Cell>{data[rowIndex][field]}</Cell>;
}

TypeCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

function NumCell({data, rowIndex, field}) {
  const linkData = {
    transferType: data[rowIndex].transferType,
    transferId: data[rowIndex].id,
  };

  return (
    <Cell>
      <CustomLink to={CustomLink.createLeadTransferLink(linkData)} className="link ellipsis-text display-block">
        {data[rowIndex][field]}
      </CustomLink>
    </Cell>
  );
}

NumCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

function DateCell({data, rowIndex, field}) {
  return <Cell>{data[rowIndex][field]}</Cell>;
}

DateCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

function FormatCell({data, rowIndex, field}) {
  return (
    <Cell>
      <div className="flex-container flex-align-center flex-justify-between">
        {data[rowIndex][field]}
        {data[rowIndex].comment && (
          <Popover
            overlayInnerStyle={{
              width: 200,
            }}
            arrowConfig={20}
            shiftTop={3}
            overlay={
              <div className="custom-popover-new__scroll-wrap">
                <CustomScrollbars>
                  <div className="main-text break-word custom-scrollbars__scroll-content">{data[rowIndex].comment}</div>
                </CustomScrollbars>
              </div>
            }
          >
            <div className="cursor-pointer">
              <Icon name="comment" className="theme-color-2" />
            </div>
          </Popover>
        )}
      </div>
    </Cell>
  );
}

FormatCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

class HomeLeadsTable extends Component {
  static propTypes = {
    data: PropTypes.array,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.HOME.LEADS_TABLE;
  }

  render() {
    const {data} = this.props;

    return (
      <div className="fixed-table fixed-table--home-leads-card fixed-table--expand-1">
        <Table rowsCount={data.length} headerHeight={40} rowHeight={30} width={370} height={342} overflowX="hidden">
          <Column
            header={<Cell>{this.LANGUAGE.CODE_COLUMN}</Cell>}
            cell={<CodeCell data={data} field="code" />}
            width={80}
          />
          <Column
            header={<Cell>{this.LANGUAGE.TYPE_COLUMN}</Cell>}
            cell={<TypeCell data={data} field="type" />}
            width={50}
          />
          <Column
            header={<Cell>{this.LANGUAGE.DATE_COLUMN}</Cell>}
            cell={<DateCell data={data} field="createdAt" />}
            width={80}
          />
          <Column
            header={<Cell>{this.LANGUAGE.NUM_COLUMN}</Cell>}
            cell={<NumCell data={data} field="leadsCount" />}
            width={80}
          />
          <Column
            header={<Cell>{this.LANGUAGE.FORMAT_COLUMN}</Cell>}
            cell={<FormatCell data={data} field="format" />}
            width={80}
          />
        </Table>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(HomeLeadsTable);
