export const operatorOptions = [
  {value: 'all', label: 'all'},
  {value: 'any', label: 'any'},
];

export const conditionOperatorOptions = [
  {label: 'equals', value: '=='},
  {label: 'is greater than', value: '>'},
  {label: 'is less than', value: '<'},
  {label: 'is greater than or equals', value: '>='},
  {label: 'is less than or equals', value: '<='},
  {label: 'is null value', value: 'is_null'},
  {label: 'is not null value', value: 'is_not_null'},
  {label: 'in', value: 'in'},
  {label: 'includes', value: 'includes'},
];

export const THEN_TYPE = {
  goto: 'goto',
  end: 'end',
};

export const VALUE_KIND = {
  scalar: 'scalar',
  variable: 'variable',
};

export const valueKindOptions = [
  {label: 'value', value: 'scalar'},
  {label: 'variable', value: 'variable'},
];
