import types from './types';

const initialState = {
  errorMessage: '',
};

export default function (_state = initialState, action) {
  const state = Object.assign({}, _state);

  switch (action.type) {
    case types.SHOW_ERROR:
      state.errorMessage = action.errorMessage;
      return state;
    case types.CLEAR_ERROR:
      state.errorMessage = '';
      return state;
    default:
      return state;
  }
}
