import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';

import {selectCurrentOrganization} from 'client/ducks/user-clients/selectors';
import {selectIsAdmin, selectIsUserLoaded} from 'client/ducks/user/selectors';

import {set} from 'client/common/meta/meta.actions';

import CustomLink from 'client/components/common/custom-link';

import VisualsGeneral from 'client/components/visuals/visuals-general';

class VisualsGeneralPage extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    setMetaAction: PropTypes.func.isRequired,
    currentOrganization: PropTypes.object.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.setMetaAction({
      title: this.props.languageState.payload.VISUALS.GENERAL_PAGE_TITLE,
    });
  }

  render() {
    const {isAdmin, isUserLoaded, currentOrganization} = this.props;

    if (!isUserLoaded) {
      return null;
    }

    if (!isAdmin) {
      return <Redirect to={CustomLink.createVisualsLink(currentOrganization.id)} />;
    }

    return <VisualsGeneral />;
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  isAdmin: selectIsAdmin(state),
  currentOrganization: selectCurrentOrganization(state),
  isUserLoaded: selectIsUserLoaded(state),
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(VisualsGeneralPage);
