import get from 'lodash/get';

import {mapLogs} from './helpers';

export function selectScenarioData(state) {
  return state.scenario.payload;
}

export function selectScenarioLogs(state) {
  return selectScenarioData(state).scenario_execution_logs;
}

export function selectScenarioLogsMapped(state) {
  return mapLogs(selectScenarioLogs(state));
}

/**
 * @type {[import('client/models/scenarios/types').ScenarioVariable]}
 */
export function selectScenarioVariables(state) {
  return state.scenario.payload.scenario_variables;
}

/**
 * @type {[import('client/models/scenarios/types').ScenarioVariable]}
 */
export function selectConcatScenarioVariables(state) {
  return state.scenario.payload.scenario_variables.concat(state.scenario.payload.scenario_step_variables || []);
}

export function selectOperationId(state) {
  return get(state.scenario.payload.automation_task.operation, 'id');
}

export function selectScenarioSteps(state) {
  return state.scenario.payload.scenario_steps.slice().sort((step1, step2) => step1.position - step2.position);
}

export function selectPossibleTestVariables(state) {
  return state.scenario.possibleTestVariables;
}

export function selectPrizes(state) {
  return state.scenario.prizes;
}

export function selectScenarios(state) {
  return state.scenario.list;
}

export function selectColumnAdapters(state) {
  return state.scenario.columnAdapters;
}

export const selectAllScenarioVariables = (state) => {
  const {
    scenario: {
      payload: {scenario_variables, scenario_step_variables = []},
    },
  } = state;

  return scenario_variables.concat(scenario_step_variables);
};
