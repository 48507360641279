export default {
  GET_AVAILABLE_CONDITION_COLUMN_ADAPTERS_REQUEST: '@@column-adapters/GET_AVAILABLE_CONDITION_COLUMN_ADAPTERS_REQUEST',
  GET_AVAILABLE_CONDITION_COLUMN_ADAPTERS_SUCCESS: '@@column-adapters/GET_AVAILABLE_CONDITION_COLUMN_ADAPTERS_SUCCESS',
  GET_AVAILABLE_CONDITION_COLUMN_ADAPTERS_ERROR: '@@column-adapters/GET_AVAILABLE_CONDITION_COLUMN_ADAPTERS_ERROR',

  GET_AVAILABLE_COLUMN_ADAPTERS_REQUEST: '@@column-adapters/GET_AVAILABLE_COLUMN_ADAPTERS_REQUEST',
  GET_AVAILABLE_COLUMN_ADAPTERS_SUCCESS: '@@column-adapters/GET_AVAILABLE_COLUMN_ADAPTERS_SUCCESS',
  GET_AVAILABLE_COLUMN_ADAPTERS_ERROR: '@@column-adapters/GET_AVAILABLE_COLUMN_ADAPTERS_ERROR',

  GET_COLUMN_ADAPTERS_REQUEST: '@@column-adapters/GET_COLUMN_ADAPTERS_REQUEST',
  GET_COLUMN_ADAPTERS_SUCCESS: '@@column-adapters/GET_COLUMN_ADAPTERS_SUCCESS',
  GET_COLUMN_ADAPTERS_ERROR: '@@column-adapters/GET_COLUMN_ADAPTERS_ERROR',

  FLUSH_COLUMN_ADAPTERS_ALL_STATE: '@@column-adapters/FLUSH_COLUMN_ADAPTERS_ALL_STATE',
};
