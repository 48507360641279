import {formatNumberSpace} from 'client/services/formatters';

export const defaultChartOptions = {
  animation: {duration: 0},
  plugins: {
    legend: {display: false},

    tooltip: {
      callbacks: {
        title: (data) => {
          return data[0].dataset.label;
        },
        label: (item) => {
          return formatNumberSpace(item.formattedValue || '0');
        },
        labelTextColor: (data) => {
          return data.dataset.borderColor;
        },
        footer: (data) => {
          const index = data[0].dataIndex;
          return data[0].dataset.tooltipDates[index];
        },
      },
      displayColors: false,
      backgroundColor: '#fff',
      titleFont: {
        size: 16,
        family: 'barlow, serif',
        weight: 'normal',
      },
      titleColor: '#404f6b',
      bodyFont: {
        size: 20,
        family: 'barlow, serif',
        weight: 'bold',
      },
      bodyColor: '#404f6b',
      footerFont: {
        size: 16,
        family: 'barlow, serif',
        weight: 'normal',
      },
      footerColor: '#404f6b',
      borderColor: '#dce1ea',
      borderWidth: 1,
      xPadding: 10,
      yPadding: 10,
    },
  },
  maintainAspectRatio: false,
  scales: {
    xAxes: {
      grid: {
        display: false,
      },
      ticks: {
        autoSkip: false,
        maxRotation: 0,
        minRotation: 0,
        color: '#8996AC',
        font: {
          family: 'Barlow',
          size: 12,
          weight: 'normal',
        },
      },
    },
    yAxes: {
      grid: {
        drawBorder: false,
        borderDash: [4, 4],
        color: function (context) {
          if (context.tick.value === 0) {
            return 'rgba(0, 0, 0, 0)';
          }
          return 'rgba(0, 0, 0, 0.1)';
        },
      },
      ticks: {
        min: 0,
        maxTicksLimit: 5,
        suggestedMax: 10,
        color: '#8996AC',
        font: {
          family: 'Barlow',
          size: 14,
          weight: 300,
        },
        callback: (value) => value + '          ',
      },
    },
  },
};

export const defaultDatasetOptions = {
  fill: false,
  pointHitRadius: 50,
  pointBorderWidth: 0,
  pointHoverBorderColor: '#fff',
  pointHoverBorderWidth: 2,
  pointHoverRadius: 7,
};
