import moment from 'moment';

export default (formFields, {langErrors}) => {
  const errors = {};

  if (moment(formFields.from).isSameOrAfter(moment(formFields.to))) {
    errors.fromTo = langErrors.DATE;
  }

  if (!formFields.name) {
    errors.name = langErrors.NAME_IS_REQUIRED;
  }

  return errors;
};
