export default {
  TOGGLE_ADD_STORE_MODAL: '@@stores/TOGGLE_ADD_STORE_MODAL',
  TOGGLE_MAP_MODAL: '@@stores/TOGGLE_MAP_MODAL',
  TOGGLE_SELECT_REGION_MODAL: '@@stores/TOGGLE_SELECT_REGION_MODAL',

  GET_STORES_REQUEST: '@@stores/GET_STORES_REQUEST',
  GET_STORES_SUCCESS: '@@stores/GET_STORES_SUCCESS',
  GET_STORES_ERROR: '@@stores/GET_STORES_ERROR',

  GET_CLIENT_REQUEST: '@@stores/GET_CLIENT_REQUEST',
  GET_CLIENT_SUCCESS: '@@stores/GET_CLIENT_SUCCESS',
  GET_CLIENT_ERROR: '@@stores/GET_CLIENT_ERROR',

  GET_MAPPED_STORES_REQUEST: '@@stores/GET_MAPPED_STORES_REQUEST',
  GET_MAPPED_STORES_SUCCESS: '@@stores/GET_MAPPED_STORES_SUCCESS',
  GET_MAPPED_STORES_ERROR: '@@stores/GET_MAPPED_STORES_ERROR',

  EDIT_STORE_REQUEST: '@@stores/EDIT_STORE_REQUEST',
  EDIT_STORE_SUCCESS: '@@stores/EDIT_STORE_SUCCESS',
  EDIT_STORE_ERROR: '@@stores/EDIT_STORE_ERROR',

  PATCH_STORE_REQUEST: '@@stores/PATCH_STORE_REQUEST',
  PATCH_STORE_SUCCESS: '@@stores/PATCH_STORE_SUCCESS',
  PATCH_STORE_ERROR: '@@stores/PATCH_STORE_ERROR',

  CHANGE_SELECTED_STORES: '@@stores/CHANGE_SELECTED_STORES',
  CLEAR_STORE: '@@stores/CLEAR_STORE',
};
