import React from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {getClientPage} from 'client/services/helpers';

import {selectAutotask} from 'client/ducks/autotask/selectors';

import {CLIENT_PAGES} from 'client/common/config';

const OperationIdCell = ({disabled}) => {
  const {
    client,
    operation_id,
    operation: {name},
  } = useSelector(selectAutotask);

  if (disabled) {
    return <>{operation_id}</>;
  }

  return (
    <Link
      to={`${getClientPage(client)}/${client?.id}${CLIENT_PAGES.OPERATIONS}/${operation_id}`}
      className="link ellipsis-text participants-main-data-grid__fix-link-text-overflow"
    >
      {name}
    </Link>
  );
};

OperationIdCell.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default OperationIdCell;
