import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';
import {calculateAge, prettyJoin} from 'client/services/helpers';

import Modal from 'client/common/modals/modal';

import DatetimeCell from 'client/components/tables/common/cells/datetime-cell';

import cssModule from './visuals-lead-modal.module.scss';

const b = bem('visuals-lead-modal', {cssModule});

const getOrigin = (visual, lang) => {
  const {lead_first_source, interaction, hashtag_tracker} = visual;

  const origin = interaction || (lead_first_source && lead_first_source.source) || hashtag_tracker;

  return origin ? origin.name : lang.DIRECT;
};

const getBy = (visual, lang) => {
  const {lead_history} = visual;
  return lead_history && lead_history.kind === 'import' ? lang.BY_ADMIN : lang.BY_LEAD;
};

const renderRow = (label, value, addHint = true) => {
  return (
    <div className={b('row')}>
      <div className={b('label')}>{label}</div>
      <div className={b('value')} title={addHint ? value : null}>
        {value}
      </div>
    </div>
  );
};

const renderRowAddress = (label, lead) => {
  return (
    <div className={b('row')}>
      <div className={b('label')}>{label}</div>
      <div className={b('value-address')}>
        <p className={b('value-text')}>{lead.address1}</p>
        <p className={b('value-text')}>{lead.address2}</p>
        <p className={b('value-text')}>
          {lead.zip} {lead.city}{' '}
        </p>
        <p className={b('value-text')}>{lead.country}</p>
      </div>
    </div>
  );
};

const VisualsLeadModal = (props) => {
  const {lang, onClose, visual} = props;
  const {lead} = visual;

  return (
    <Modal title={lang.TITLE} className={b()} onClose={onClose}>
      <div className={b('content')}>
        <div className={b('column')}>
          {renderRow(lang.NAME, prettyJoin([lead.first_name, lead.last_name]))}
          {renderRow(lang.AGE, calculateAge(lead.birth_date))}
          {renderRow(lang.PHONE, lead.phone)}
          {renderRow(lang.EMAIL, lead.email)}
          {renderRowAddress(lang.ADDRESS, lead)}
        </div>
        <div className={b('column')}>
          {renderRow(lang.CREATED, <DatetimeCell value={lead.created_at} oneLine />, false)}
          {renderRow(lang.ORIGIN, getOrigin(visual, lang))}
          {renderRow(lang.BY, getBy(visual, lang))}
          {renderRow(lang.VISUALS, lead.visuals_count)}
          {renderRow(lang.PARTICIPATIONS, lead.participations_count)}
          {renderRow(lang.COMMENT, lead.comment)}
        </div>
      </div>
    </Modal>
  );
};

VisualsLeadModal.propTypes = {
  lang: PropTypes.object.isRequired,
  visual: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect((state) => ({
  lang: state.languageState.payload.CLIENT_LEAD_INFORMATION_MODAL,
}))(VisualsLeadModal);
