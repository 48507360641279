import React from 'react';

import get from 'lodash/get';

import bem from 'client/services/bem';

import {SCENARIO_STEP_TYPES, SCENARIO_STEP_NAMES} from 'client/common/config';
import Dropdown from 'client/common/selects/dropdown';

import cssModule from './ats-step-dropdown.module.scss';

const b = bem('ats-step-dropdown', {cssModule});

type FooProps = {
  onSelect: (value: string) => void;
  text: string;
  stepNames: Record<string, string>;
};

const AtsStepDropdown: React.FC<FooProps> = (props) => {
  const {onSelect, text, stepNames} = props;

  const buildOption = (name: string) => {
    const value = get(SCENARIO_STEP_TYPES, name);
    return {
      label: stepNames[SCENARIO_STEP_NAMES[value]],
      value,
    };
  };

  const options = [
    {
      key: 1,
      options: [buildOption('timer'), buildOption('branch'), buildOption('coupons'), buildOption('calculation')],
    },
    {
      key: 2,
      options: [
        buildOption('message.sms'),
        buildOption('message.email'),
        buildOption('social.facebook'),
        buildOption('social.twitter'),
      ],
    },
    {
      key: 3,
      options: [buildOption('instantLottery'), buildOption('prizeDraw')],
    },
  ];

  return <Dropdown className={b()} trigger={text} options={options} commonOnClick={onSelect} showToggleArrow grouped />;
};

export default AtsStepDropdown;
