import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {fetchGame, updateGame, updatePrizeMaps} from 'client/ducks/games/actions';
import {selectOperation} from 'client/ducks/operations/selectors';
import {getPrizeMapsByLevel} from 'client/ducks/prizes/actions';

import AwaitContainer from 'client/common/await-container';
import AppButton from 'client/common/buttons';
import Modal from 'client/common/modals/modal';
import LayoutPanel from 'client/common/panels/layout-panel';

import {LotteryDrawDropdown} from 'client/components/lottery/fields';
import {LotteryCampaignDateTimeFieldset} from 'client/components/lottery/fieldsets';
import LotteryPrizeMapsTable from 'client/components/lottery/tables/lottery-prize-maps-table';
import {PrizeLevel} from 'client/components/prizes/types';
import {isGameDrawClosed} from 'client/models/prizes/helpers';
import {Game, GameDraw, PrizeMap} from 'client/models/prizes/types';
import {ApiDispatch} from 'client/types';

import initial from './initial';
import mapFormValues from './mapFormValues';
import {LotteryPrizeCustomizationValues} from './types';
import validate from './validate';

import cssModule from './lottery-prize-customization-modal.module.scss';

const b = bem('lottery-prize-customization-modal', {cssModule});

export const LotteryPrizeCustomizationModalFormName = 'LotteryPrizeCustomizationModalForm';

type LotteryPrizeCustomizationModalProps = {
  onClose: () => void;
  level: PrizeLevel;
  game: Game;
  sourceIds?: number[];
  sourceName?: string;
  disabled: boolean;
};

const LotteryPrizeCustomizationModal: ReduxFormFC<
  LotteryPrizeCustomizationModalProps,
  LotteryPrizeCustomizationValues
> = (props) => {
  const {handleSubmit, game: initialGame, level = 'DEVICE', sourceIds, sourceName, onClose, disabled} = props;
  const lang = useLanguage('LOTTERY.MODALS.LOTTERY_PRIZE_CUSTOMIZATION_MODAL');
  const langErrors = useLanguage('LOTTERY.MODALS.LOTTERY_PRIZE_CUSTOMIZATION_MODAL.ERRORS');
  const [submitting, toggleSubmitting] = useToggle(false);
  const [draw, setDraw] = useState<GameDraw | null>(null);
  const dispatch: ApiDispatch = useDispatch();
  const operation = useSelector(selectOperation);

  const drawIsDisabled = isGameDrawClosed(draw);

  const isReadOnly = disabled || !operation.editable || drawIsDisabled;

  const {loading: loadingGame, data: {game} = {}} = useReduxFetch<{game: Game}>({
    action: fetchGame,
    actionArgs: {
      id: initialGame.id,
      queryParams: {
        include: ['game_draws'],
      },
    },
  });

  const {loading: loadingPrizeMaps, data: {prize_maps = []} = {}} = useReduxFetch<{prize_maps: PrizeMap[]}>({
    action: getPrizeMapsByLevel,
    actionArgs: {
      sourceIds,
      level,
      queryParams: {
        include_prize_map_prize_draw_active: null,
        q: {
          game_draw_id_eq: draw?.id,
        },
      },
    },
    skip: !level || !draw?.id,
  });

  const {invalid, initialize, formValues} = useReduxForm<LotteryPrizeCustomizationValues>(
    LotteryPrizeCustomizationModalFormName,
    {
      initialValues: initial({game: initialGame, prize_maps}),
      validate: (values) => validate(values, {lang: langErrors}),
    },
  );
  const prizeMaps = formValues.prize_maps;

  const gameDrawIndex = game?.game_draws?.findIndex((d) => draw?.id && d.id === +draw?.id);

  useEffect(() => {
    if (!loadingGame && !loadingPrizeMaps && game) {
      initialize(initial({game, prize_maps}));
    }
  }, [game, prize_maps, initialize, loadingGame, loadingPrizeMaps]);

  const handleSave = handleSubmit(async (values) => {
    toggleSubmitting(true);

    try {
      const body = mapFormValues(values);
      await Promise.all([
        dispatch(updateGame(initialGame.id, {game: body})),
        values.prize_maps?.length ? dispatch(updatePrizeMaps({prize_map: values.prize_maps})) : Promise.resolve(),
      ]);
      onClose();
    } finally {
      toggleSubmitting(false);
    }
  });

  const levelName = sourceName || lang.LEVELS[level];

  return (
    <Modal
      className={b()}
      onClose={onClose}
      isCloseHidden
      title={
        <>
          {lang.TITLE}
          {level && <span className={b('level')}>{`${initialGame?.name} / ${levelName}`}</span>}
        </>
      }
      titleButton={
        <div className={b('buttons')}>
          <AppButton
            color="clients"
            transparent
            label={isReadOnly ? lang.CLOSE_BUTTON : lang.CANCEL_BUTTON}
            onClick={onClose}
          />
          {!isReadOnly && (
            <AppButton
              color="clients"
              label={lang.SAVE_BUTTON}
              onClick={handleSubmit(handleSave)}
              loading={submitting}
              disabled={invalid}
            />
          )}
        </div>
      }
    >
      <AwaitContainer loading={loadingGame || loadingPrizeMaps} className={b('body')}>
        <LotteryDrawDropdown
          className={b('block', ['draw'])}
          value={draw?.id}
          onChange={setDraw}
          game={game}
          disabled={isReadOnly}
        />
        <LayoutPanel className={b('block')}>
          <LotteryCampaignDateTimeFieldset name={`game_draws[${gameDrawIndex}]`} disabled={isReadOnly} />
        </LayoutPanel>

        {!!prizeMaps?.length && (
          <LayoutPanel className={b('block', ['prize-maps'])}>
            <h3 className={b('prize-maps-title')}>{lang.PRIZE_TABLE_TITLE}</h3>
            <p className={b('prize-maps-description')}>{lang.PRIZE_TABLE_DESCRIPTION}</p>
            <LotteryPrizeMapsTable
              data={prizeMaps}
              location="PRIZE_CUSTOMIZATION"
              hasSegmentation={false}
              className={b('prize-maps-table')}
              disabled={isReadOnly}
            />
          </LayoutPanel>
        )}
      </AwaitContainer>
    </Modal>
  );
};

export default reduxForm<LotteryPrizeCustomizationModalProps, LotteryPrizeCustomizationValues>({
  form: LotteryPrizeCustomizationModalFormName,
})(LotteryPrizeCustomizationModal);
