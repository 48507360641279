import React from 'react';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import bem from 'client/services/bem';
import {useReduxFetch, useLanguage} from 'client/services/hooks';

import {getPrivacyPolicies} from 'client/ducks/privacy-policies/actions';
import {getLastPrivacyPolicy} from 'client/ducks/privacy-policies/selectors';

import AppButton from 'client/common/buttons/app-button';

import cssModule from './privacy-policy-accept.module.scss';

const b = bem('privacy-policy-accept', {cssModule});

type PrivacyPolicyAcceptProps = {
  userLanguage: string;
  onAccept: () => void;
};

const PrivacyPolicyAccept: React.FC<PrivacyPolicyAcceptProps> = (props) => {
  const {userLanguage, onAccept} = props;
  const lang = useLanguage('PRIVACY_POLICY');

  const {data: privacyPolicy} = useReduxFetch({
    action: getPrivacyPolicies,
    selector: getLastPrivacyPolicy,
    actionArgs: {
      q: {
        s: 'created_at desc',
      },
      language: userLanguage,
    },
  });

  return (
    <div className={b()}>
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{privacyPolicy ? privacyPolicy.text : '...'}</ReactMarkdown>
      <AppButton onClick={onAccept} color="primary" className={b('button-accept')} label={lang.ACCEPT_BUTTON} />
    </div>
  );
};

export default PrivacyPolicyAccept;
