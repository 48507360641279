import moment from 'moment/moment';

import {isEmptyString} from 'client/services/validator';

import {Translation} from 'client/models/language/types';

import {DrawParametersValues} from './types';

export default (values: DrawParametersValues, lang: Record<string, Translation>) => {
  const errors: Record<string, Translation> = {};

  ['name', 'draw_moment', 'from', 'to', 'device_segmentation', 'online_segmentation'].forEach((key) => {
    if (isEmptyString(values[key as keyof typeof values])) {
      errors[key] = lang.REQUIRED;
    }
  });

  if (moment(values.to).isSameOrBefore(moment(values.from))) {
    errors.from = lang.FROM_TO;
  }

  if (moment(values.draw_moment).diff(moment(values.to), 'minutes') < 2) {
    errors.draw_moment = lang.DRAW_MOMENT;
  }

  return errors;
};
