import React from 'react';

import {Cell} from 'fixed-data-table-2';
import get from 'lodash/get';

import bem from 'client/services/bem';
import {replaceText} from 'client/services/helpers';

import AppButton from 'client/common/buttons/app-button';
import Popover from 'client/common/popovers/popover';

import {Interfaces} from 'client/models/interfaces/types';

import cssModule from './label-cell.module.scss';

const b = bem('label-cell', {cssModule});

type Label = {
  name: string;
  interfaces: Interfaces[];
};

type LabelCellProps = {
  rowIndex: number;
  interfaceName: string;
  data: Label[];
  onEditClick: (label: Label) => void;
  popoverText: string;
};

const LabelCell: React.FC<LabelCellProps> = (props) => {
  const {data, rowIndex, onEditClick, popoverText, ...rest} = props;
  const label = data[rowIndex];

  const mainCell = (
    <AppButton
      label={
        <span className={b('name')} title={label.name}>
          {label.name}
        </span>
      }
      onClick={() => onEditClick(label)}
      asWrap
    />
  );

  const interfaceName = get(label.interfaces, '[0].name');
  if (!interfaceName) {
    return mainCell;
  }

  return (
    <Cell className={b()} {...rest}>
      {interfaceName ? (
        <Popover shiftTop={8} position="bottom" overlay={replaceText(/\[N]/, popoverText, interfaceName)}>
          {mainCell}
        </Popover>
      ) : (
        mainCell
      )}
    </Cell>
  );
};

export default LabelCell;
