import {RSAA} from 'redux-api-middleware';

import {getQueryParams} from 'client/services/helpers';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function toggleMapModalAction() {
  return {
    type: types.TOGGLE_MAP_MODAL,
  };
}

export function toggleAddStoreModalAction() {
  return {
    type: types.TOGGLE_ADD_STORE_MODAL,
  };
}

export function toggleSelectRegionModalAction() {
  return {
    type: types.TOGGLE_SELECT_REGION_MODAL,
  };
}

export function clearStoreAction() {
  return {
    type: types.CLEAR_STORE,
  };
}

export function changeSelectedStoresAction(selectedStores) {
  return {
    type: types.CHANGE_SELECTED_STORES,
    selectedStores: selectedStores,
  };
}

export function getStoresAction({clientId, page, perPage, search, sort = {}, regionId}) {
  return (dispatch) => {
    const queryParams = getQueryParams({
      include: ['network.group', 'city.agglomeration.zone', 'client', 'region', 'offline_interactions'],
      q: {
        client_id_eq: clientId,
        region_id_eq: regionId,
        s: sort && `${sort.name} ${sort.order}`,
        name_cont: search,
      },
      page,
      per_page: perPage,
    });
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.PLACES}?${queryParams}`,
        method: 'GET',
        types: [types.GET_STORES_REQUEST, types.GET_STORES_SUCCESS, types.GET_STORES_ERROR],
        isAuth: true,
        noCache: true,
      },
    });
  };
}

export function getClientAction(clientId) {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.CLIENTS}/${clientId}`,
        method: 'GET',
        types: [types.GET_CLIENT_REQUEST, types.GET_CLIENT_SUCCESS, types.GET_CLIENT_ERROR],
        isAuth: true,
        noCache: true,
      },
    });
  };
}

export function getMappedStoresAction(clientId, storeId) {
  return (dispatch) => {
    const queryParams = getQueryParams({
      include: ['client'],
      q: {
        id_eq: storeId,
        client_id_eq: clientId,
        latitude_not_null: true,
      },
    });
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.PLACES}?${queryParams}`,
        method: 'GET',
        types: [types.GET_MAPPED_STORES_REQUEST, types.GET_MAPPED_STORES_SUCCESS, types.GET_MAPPED_STORES_ERROR],
        isAuth: true,
      },
    });
  };
}

export function editStoreAction(id, place) {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.PLACES}/${id}`,
        method: 'PUT',
        types: [types.EDIT_STORE_REQUEST, types.EDIT_STORE_SUCCESS, types.EDIT_STORE_ERROR],
        isAuth: true,
        body: JSON.stringify({
          place,
        }),
      },
    });
  };
}

export function patchStoreAction({id, ...place}) {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.PLACES}/${id}`,
        method: 'PATCH',
        types: [types.PATCH_STORE_REQUEST, types.PATCH_STORE_SUCCESS, types.PATCH_STORE_ERROR],
        isAuth: true,
        body: JSON.stringify({
          place,
        }),
      },
    });
  };
}
