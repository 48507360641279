import {Translation} from 'client/models/language/types';

/**
 * interpolate - helper for translations for adding variable inside translation string
 * @param template - string with special characters for replacing them, example 'Hello {{name}} {{surname}}'
 * @param map - object with variables, example: `{name: 'John', surname: 'Smith'}`
 */
export const interpolate = (template: string, map: Record<string, Translation | number>): Translation => {
  return Object.entries(map).reduce((acc, [key, value]) => {
    return acc.replace(`{{${key}}}`, String(value));
  }, template);
};
