import {updateInArray} from 'client/services/helpers';

import types from './types';

const initialState = {
  messageTask: {
    broadcast_list: {},
    email_template: {},
    message_sender: {},
  },
  messageTasks: [],
  messages: [],
  meta: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
  metaRecipients: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload.messages,
        metaRecipients: {
          ...state.metaRecipients,
          ...action.payload.meta,
        },
      };
    case types.GET_MESSAGE_TASKS_SUCCESS:
      return {
        ...state,
        messageTasks: action.payload.message_tasks,
        meta: {
          ...state.meta,
          ...action.payload.meta,
        },
      };
    case types.GET_MESSAGE_TASK_SUCCESS:
      return {
        ...state,
        messageTask: action.payload.message_task,
      };
    case types.UPDATE_MESSAGE_TASK_SUCCESS:
    case types.TOGGLE_MESSAGE_TASK_SUCCESS:
      return {
        ...state,
        messageTasks: updateInArray(state.messageTasks, action.payload.message_task),
        messageTask: action.payload.message_task,
      };
    case types.FLUSH_MESSAGE_TASK_ALL_STATE:
      return initialState;
    default:
      return state;
  }
}
