import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getEmailSenders} from 'client/ducks/email-senders/actions';
import {selectEmailSenders} from 'client/ducks/email-senders/selectors';
import {getSMSSenders} from 'client/ducks/sms-senders/actions';
import {selectSMSSenders} from 'client/ducks/sms-senders/selectors';
import {getSocialAccounts, patchSocialAccountsBulk} from 'client/ducks/social-accounts/actions';
import {selectSocialAccounts, selectSocialAccountsNames} from 'client/ducks/social-accounts/selectors';
import {getSubsidiaries} from 'client/ducks/subsidiaries/actions';
import {selectSubsidiaries} from 'client/ducks/subsidiaries/selectors';

import {SOCIAL_ACCOUNT_TYPES} from 'client/common/config';

import TitleBlock from 'client/components/common/title-block';

import AddNewFacebookAccountModal from 'client/components/client-agency/components/modals/add-new-facebook-account-modal';
import AddNewSocialAccountModal from 'client/components/client-agency/components/modals/add-new-social-account-modal';
import AddNewTwitterAccountModal from 'client/components/client-agency/components/modals/add-new-twitter-account-modal';
import PrivacyPolicyThirdPartyModal from 'client/components/client-agency/components/modals/privacy-policy-third-party-modal';
import SelectSubsidiaryModal from 'client/components/client-agency/components/modals/select-subsidiary-modal';

import ParamsCard from './components/cards/params-card';
import ParamsEmailModal from './components/modals/params-email-modal';
import ParamsPhoneModal from './components/modals/params-phone-modal';
import ParamsSocialModal from './components/modals/params-social-modal';
import ParamsEmailsTable from './components/tables/params-emails-table';
import ParamsPhonesTable from './components/tables/params-phones-table';
import ParamsSocialsTable from './components/tables/params-socials-table';

class Parameters extends Component {
  static propTypes = {
    smsSenders: PropTypes.array.isRequired,
    getSocialAccounts: PropTypes.func.isRequired,
    getSMSSenders: PropTypes.func.isRequired,
    getSubsidiaries: PropTypes.func.isRequired,
    patchSocialAccountsBulk: PropTypes.func.isRequired,
    subsidiaries: PropTypes.array.isRequired,
    socialAccounts: PropTypes.array.isRequired,
    socialAccountsNames: PropTypes.array.isRequired,
    languageState: PropTypes.object.isRequired,
    getEmailSenders: PropTypes.func.isRequired,
    emailSenders: PropTypes.array.isRequired,
  };

  static Modals = {
    PHONE: ParamsPhoneModal.formName,
    EMAIL: ParamsEmailModal.formName,
    SOCIAL: ParamsSocialModal.formName,
    [SOCIAL_ACCOUNT_TYPES.FACEBOOK]: 'ADD_FB_ACCOUNT',
    [SOCIAL_ACCOUNT_TYPES.TWITTER]: 'ADD_TWITTER_ACCOUNT',
    DELETE_SOCIAL_ACCOUNT: 'DELETE_SOCIAL_ACCOUNT',
    SELECT_SOCIAL_ACCOUNT: 'SELECT_SOCIAL_ACCOUNT',
    SELECT_SOCIAL_SUBSIDIARY: 'SELECT_SOCIAL_SUBSIDIARY',
    PRIVACY_POLICY_THIRD_PARTY: 'PRIVACY_POLICY_THIRD_PARTY',
  };

  constructor(props) {
    super(props);

    this.state = {
      visibleModal: '',
      activePhone: null,
      activeEmail: null,
      activeSocial: null,
    };

    this.LANGUAGE = props.languageState.payload.PARAMETERS;
  }

  componentDidMount() {
    this.props.getSubsidiaries();
    this.getSocialAccounts();
    this.getSmsSenders();
    this.getEmailSenders();
  }

  getSmsSenders() {
    return this.props.getSMSSenders();
  }

  getEmailSenders() {
    return this.props.getEmailSenders();
  }

  getSocialAccounts = () =>
    this.props.getSocialAccounts({
      include: 'subsidiary',
      q: {
        subsidiary_id_not_null: true,
      },
    });

  handleOpenPhoneModal = (phone) => {
    this.setState({
      activePhone: phone,
      visibleModal: Parameters.Modals.PHONE,
    });
  };

  handleOpenSocialModal = (social) => {
    this.setState({
      activeSocial: social,
      visibleModal: Parameters.Modals.SOCIAL,
    });
  };

  handleOpenEmailModal = (email) => {
    this.setState({
      activeEmail: email,
      visibleModal: Parameters.Modals.EMAIL,
    });
  };

  handleShowModal = (id, additionalState = {}) => {
    return () =>
      this.setState({
        visibleModal: id,
        ...additionalState,
      });
  };

  handleCloseModal = (additionalState = {}) => {
    return () => {
      this.setState({
        visibleModal: '',
        ...additionalState,
      });
    };
  };

  handleSelectAccount = (type) => this.handleShowModal(Parameters.Modals[type])();

  handleSelectSubsidiary = (subsidiary_id) =>
    this.handleShowModal(Parameters.Modals.SELECT_SOCIAL_ACCOUNT, {subsidiary_id})();

  handleSocialAccountAdded = (type) => async (data) => {
    const {subsidiary_id} = this.state;
    const accounts = this.props.socialAccounts.slice();

    accounts.push({type, subsidiary_id, ...data});

    const socialAccounts = accounts.map((account) => ({
      id: account.id,
      name: account.name,
      subsidiary_id: account.subsidiary_id,
      access_token: account.access_token,
      oauth_token: account.oauth_token,
      oauth_verifier: account.oauth_verifier,
      type: account.type,
      _destroy: account.deleted,
    }));

    await this.props.patchSocialAccountsBulk({
      social_account: socialAccounts,
    });

    this.getSocialAccounts().then(
      this.setState({
        visibleModal: '',
      }),
    );
  };

  handleConfirmSocialModal = () => {
    this.getSocialAccounts();

    this.setState({
      visibleModal: '',
      activeEmail: null,
      activeSocial: null,
    });
  };

  handleConfirmEmailModal = async () => {
    await this.getEmailSenders();

    this.setState({
      activeEmail: null,
      visibleModal: '',
    });
  };

  handleConfirmPhoneModal = async () => {
    await this.getSmsSenders();

    this.setState({
      activePhone: null,
      visibleModal: '',
    });
  };

  render() {
    const {visibleModal} = this.state;
    const {socialAccounts, socialAccountsNames, subsidiaries, emailSenders, smsSenders, languageState} = this.props;

    return (
      <div className="theme-color-7">
        <ParamsEmailModal
          data={this.state.activeEmail}
          show={visibleModal === Parameters.Modals.EMAIL}
          onConfirm={this.handleConfirmEmailModal}
          onCancel={this.handleCloseModal({activeEmail: null})}
          onClose={this.handleCloseModal({activeEmail: null})}
        />

        <ParamsPhoneModal
          show={visibleModal === Parameters.Modals.PHONE}
          data={this.state.activePhone}
          onConfirm={this.handleConfirmPhoneModal}
          onCancel={this.handleCloseModal({activePhone: null})}
          onClose={this.handleCloseModal({activePhone: null})}
        />

        <ParamsSocialModal
          show={visibleModal === Parameters.Modals.SOCIAL}
          data={this.state.activeSocial}
          subsidiaries={subsidiaries}
          socialAccountsNames={socialAccountsNames}
          onConfirm={this.handleConfirmSocialModal}
          onCancel={this.handleCloseModal({activeSocial: null})}
          onClose={this.handleCloseModal({activeSocial: null})}
        />

        <PrivacyPolicyThirdPartyModal
          className="modal-window--theme-8"
          buttonConfirmClass="button--bg-8"
          show={visibleModal === Parameters.Modals.PRIVACY_POLICY_THIRD_PARTY}
          onConfirm={this.handleShowModal(Parameters.Modals.SELECT_SOCIAL_SUBSIDIARY)}
          onCancel={this.handleCloseModal()}
          onClose={this.handleCloseModal()}
        />

        <SelectSubsidiaryModal
          className="theme-color-7"
          buttonConfirmClass="button--bg-5"
          show={visibleModal === Parameters.Modals.SELECT_SOCIAL_SUBSIDIARY}
          subsidiaries={subsidiaries}
          onConfirm={this.handleSelectSubsidiary}
          onClose={this.handleCloseModal()}
        />

        <AddNewSocialAccountModal
          className="theme-color-7"
          buttonConfirmClass="button--bg-5"
          radioClassName="radio-button--switcher-theme-3"
          show={visibleModal === Parameters.Modals.SELECT_SOCIAL_ACCOUNT}
          onConfirm={this.handleSelectAccount}
          onCancel={this.handleShowModal(Parameters.Modals.SELECT_SOCIAL_SUBSIDIARY)}
          onClose={this.handleCloseModal()}
        />

        <AddNewFacebookAccountModal
          className="theme-color-7"
          buttonConfirmClass="button--bg-5"
          show={visibleModal === Parameters.Modals[SOCIAL_ACCOUNT_TYPES.FACEBOOK]}
          socialAccountsNames={socialAccountsNames}
          onConfirm={this.handleSocialAccountAdded(SOCIAL_ACCOUNT_TYPES.FACEBOOK)}
          onCancel={() => this.handleSelectSubsidiary(this.state.subsidiary_id)}
          onClose={this.handleCloseModal()}
        />

        <AddNewTwitterAccountModal
          className="theme-color-7"
          buttonConfirmClass="button--bg-5"
          show={visibleModal === Parameters.Modals[SOCIAL_ACCOUNT_TYPES.TWITTER]}
          socialAccountsNames={socialAccountsNames}
          onConfirm={this.handleSocialAccountAdded(SOCIAL_ACCOUNT_TYPES.TWITTER)}
          onCancel={() => this.handleSelectSubsidiary(this.state.subsidiary_id)}
          onClose={this.handleCloseModal()}
          lang={languageState}
        />

        <div className="page__title-block">
          <TitleBlock theme>
            <TitleBlock.Item>{this.LANGUAGE.TITLE}</TitleBlock.Item>
          </TitleBlock>
        </div>
        <div className="page__block-container">
          <div className="page__block page__block--size-4">
            <ParamsCard
              title={this.LANGUAGE.TITLE_PHONES}
              onAddClick={this.handleShowModal(Parameters.Modals.PHONE)}
              buttonText={this.LANGUAGE.ADD_PHONE_BUTTON}
            >
              <ParamsPhonesTable data={smsSenders} onAccountClick={this.handleOpenPhoneModal} />
            </ParamsCard>
          </div>
          <div className="page__block page__block--size-4">
            <ParamsCard
              title={this.LANGUAGE.TITLE_EMAILS}
              onAddClick={this.handleShowModal(Parameters.Modals.EMAIL)}
              buttonText={this.LANGUAGE.ADD_EMAIL_BUTTON}
            >
              <ParamsEmailsTable data={emailSenders} onAccountClick={this.handleOpenEmailModal} />
            </ParamsCard>
          </div>
          <div className="page__block page__block--size-4">
            <ParamsCard
              title={this.LANGUAGE.TITLE_SOCIALS}
              onAddClick={this.handleShowModal(Parameters.Modals.PRIVACY_POLICY_THIRD_PARTY)}
              buttonText={this.LANGUAGE.ADD_ACCOUNT_BUTTON}
            >
              <ParamsSocialsTable data={socialAccounts} onAccountClick={this.handleOpenSocialModal} />
            </ParamsCard>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  socialAccounts: selectSocialAccounts(state),
  subsidiaries: selectSubsidiaries(state),
  smsSenders: selectSMSSenders(state),
  emailSenders: selectEmailSenders(state),
  socialAccountsNames: selectSocialAccountsNames(state),
});

const mapDispatchToProps = {
  getSocialAccounts,
  getSMSSenders,
  patchSocialAccountsBulk,
  getSubsidiaries,
  getEmailSenders,
};

export default connect(mapStateToProps, mapDispatchToProps)(Parameters);
