import React from 'react';

import {Field} from 'redux-form';

import BaseFieldComponent from 'client/common/fields/base-field';
import {ImageInput} from 'client/common/inputs';

const InputComponent = (props) => <BaseFieldComponent Component={ImageInput} {...props} />;

const ImageField = (props) => {
  return <Field component={InputComponent} {...props} />;
};

export default ImageField;
