import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import AnimationBrandCell from '../animation-brand-cell';

import './animation-brand-place-cell.scss';

const b = bem('animation-brand-place-cell');

const AnimationBrandPlaceCell = (props) => {
  const {item} = props;

  return (
    <div className={b()}>
      <AnimationBrandCell item={item} size={16} renderNoValue={true} />
      <span>{item.place?.name}</span>
    </div>
  );
};

AnimationBrandPlaceCell.propTypes = {
  item: PropTypes.shape({
    place: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AnimationBrandPlaceCell;
