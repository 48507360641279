import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getParticipationsStatisticsById(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.STATISTICS_PARTICIPATIONS}`,
      method: 'GET',
      types: [
        types.GET_PARTICIPATIONS_STATISTICS_REQUEST,
        types.GET_PARTICIPATIONS_STATISTICS_SUCCESS,
        types.GET_PARTICIPATIONS_STATISTICS_ERROR,
      ],
      queryParams,
    });
}

export function getSourcesStatisticsById(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.STATISTICS_SOURCES}`,
      method: 'GET',
      types: [
        types.GET_SOURCES_STATISTICS_REQUEST,
        types.GET_SOURCES_STATISTICS_SUCCESS,
        types.GET_SOURCES_STATISTICS_ERROR,
      ],
      queryParams,
    });
}
