import React from 'react';

import {Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';

function TextCell(props) {
  const {data, rowIndex, field} = props;
  return <Cell>{data[rowIndex][field]}</Cell>;
}

TextCell.defaultProps = {
  data: [],
};

TextCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

export default TextCell;
