import {callApi} from 'client/services/call-api';
import {getAuthHeaders} from 'client/services/helpers';

import {API_METHODS, API_PATH} from 'client/common/config';

import types from './types';

export function getTranslations() {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.TRANSLATIONS}`,
      method: 'GET',
      types: [types.GET_TRANSLATIONS_REQUEST, types.GET_TRANSLATIONS_SUCCESS, types.GET_TRANSLATIONS_ERROR],
      queryParams: {
        with_full_structure: true,
      },
    });
}

export function postTranslation(data) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.TRANSLATIONS}`,
      method: 'POST',
      types: [types.POST_TRANSLATION_REQUEST, types.POST_TRANSLATION_SUCCESS, types.POST_TRANSLATION_ERROR],
      headers: getAuthHeaders(),
      body: data,
    });
}

export function patchTranslation(data) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.TRANSLATIONS}/${data.id}`,
      method: 'PATCH',
      types: [types.PATCH_TRANSLATION_REQUEST, types.PATCH_TRANSLATION_SUCCESS, types.PATCH_TRANSLATION_ERROR],
      headers: getAuthHeaders(),
      body: {
        translation: data,
      },
    });
}

export function deleteTranslation(data) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.TRANSLATIONS}/${data.id}`,
      method: 'DELETE',
      types: [types.DELETE_TRANSLATION_REQUEST, types.DELETE_TRANSLATION_SUCCESS, types.DELETE_TRANSLATION_ERROR],
      headers: getAuthHeaders(),
    });
}

// @todo ask backend team to give response in DELETE method
export function deleteTranslationFromStore(translation) {
  return (dispatch) => {
    return dispatch({
      type: types.DELETE_TRANSLATION,
      payload: translation,
    });
  };
}

export function postTranslationsItem(data) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.TRANSLATION_ITEMS}`,
      method: 'POST',
      types: [
        types.POST_TRANSLATION_ITEM_REQUEST,
        types.POST_TRANSLATION_ITEM_SUCCESS,
        types.POST_TRANSLATION_ITEM_ERROR,
      ],
      headers: getAuthHeaders(),
      body: {
        translation_item: data,
      },
    });
}

export function patchTranslationsItem(data) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.TRANSLATION_ITEMS}/${data.id}`,
      method: 'PATCH',
      types: [
        types.PATCH_TRANSLATION_ITEM_REQUEST,
        types.PATCH_TRANSLATION_ITEM_SUCCESS,
        types.PATCH_TRANSLATION_ITEM_ERROR,
      ],
      headers: getAuthHeaders(),
      body: data,
    });
}

export function getLanguages(data) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.LANGUAGES}`,
      method: 'GET',
      types: [types.GET_LANGUAGES_REQUEST, types.GET_LANGUAGES_SUCCESS, types.GET_LANGUAGES_ERROR],
      headers: getAuthHeaders(),
      body: data,
    });
}

export function patchLanguage(data) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.LANGUAGES}/${data.id}`,
      method: 'PATCH',
      types: [types.PATCH_LANGUAGE_REQUEST, types.PATCH_LANGUAGE_SUCCESS, types.PATCH_LANGUAGE_ERROR],
      headers: getAuthHeaders(),
      body: data,
    });
}
