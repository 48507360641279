import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getIsTrackingUser} from 'client/services/cookie-data-source';

import {acceptPrivacyPolicy} from 'client/ducks/privacy-policies/actions';
import {getPrivacyPolicyUserInfo} from 'client/ducks/privacy-policies/selectors';
import {selectIsAdmin, selectUser} from 'client/ducks/user/selectors';

import {WINNING_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import Home from 'client/components/home';
import HomeClient from 'client/components/home-client';
import PrivacyPolicyAccept from 'client/components/privacy-policy-accept';

class HomePage extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    setMetaAction: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.props.setMetaAction({
      title: this.props.languageState.payload.HOME.TITLE_HOME,
    });

    if (getIsTrackingUser()) {
      this.props.history.push(WINNING_PAGES.WINNING_TASKS);
    }
  }

  acceptPrivacyPolicy = () => {
    const userId = this.props.user.id;
    this.props.acceptPrivacyPolicy(userId);
  };

  render() {
    const {
      isAdmin,
      privacy_policy_accepted,
      history: {push},
    } = this.props;

    if (privacy_policy_accepted) {
      return isAdmin ? <Home /> : <HomeClient historyPush={push} />;
    } else if (privacy_policy_accepted === false) {
      return <PrivacyPolicyAccept onAccept={this.acceptPrivacyPolicy} />;
    }
    return null;
  }
}

HomePage.propTypes = {
  privacy_policy_accepted: PropTypes.bool,
  isAdmin: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  acceptPrivacyPolicy: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  isAdmin: selectIsAdmin(state),
  user: selectUser(state),
  ...getPrivacyPolicyUserInfo(state),
});

const mapDispatchToProps = {
  setMetaAction: set,
  acceptPrivacyPolicy,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
