import React from 'react';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';

import AppButton from 'client/common/buttons';
import {TextField} from 'client/common/fields';

import {Animation} from 'client/models/animations/types';
import {Place} from 'client/models/places/types';

import initial from './initial';
import validate from './validate';

import './animation-brand-info.scss';

const b = bem('animation-brand-info');

const AnimationBrandInfoFormName = 'AnimationBrandEditForm';

export type AnimationBrandInfoForm = Pick<
  Animation,
  'contact_name' | 'contact_email' | 'contact_phone' | 'security_email' | 'security_fax' | 'place'
> &
  Pick<Place, 'network'>;

type AnimationBrandInfoProps = {
  animation: Animation;
  onEdit: () => void;
  onClose: () => void;
  onSubmit: (form: AnimationBrandInfoForm) => void;
  showEditForm: boolean;
};

const AnimationBrandInfo: React.FC<AnimationBrandInfoProps> = (props) => {
  const {onEdit, onClose, animation, showEditForm, onSubmit} = props;

  const lang = useLanguage('ANIMATIONS.ANIMATION_BRAND_INFO');
  const langErrors = useLanguage('ANIMATIONS.ERRORS');

  const {formValues, invalid} = useReduxForm<AnimationBrandInfoForm>(AnimationBrandInfoFormName, {
    initialValues: initial(animation),
    validate: (values) => validate(values, {lang: langErrors}),
  });

  const {place, network} = formValues || {};

  const handleSubmitClick = () => {
    onSubmit(formValues);
    onClose();
  };

  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('brand-identity-wrap')}>
          {!!network?.icon_url && <img className={b('brand-logo')} src={network?.icon_url} alt="" />}
          <span className={b('brand-name')}>{place?.name}</span>
        </div>
        {!showEditForm && (
          <AppButton
            className={b('edit-btn')}
            color="light-clients"
            iconName="edit"
            disabled={!animation.id}
            onClick={onEdit}
          />
        )}
      </div>
      <div className={b('body')}>
        <div className={b('section')}>
          <span>
            {place?.city_zip} {place?.city_name} {place?.street_address}
          </span>
          {!!place?.app_street_address && (
            <span>
              {place.app_zip} {place.app_city_name} {place.app_street_address}
            </span>
          )}
        </div>
        <div className={b('section-label')}>
          <span>{lang.STORE_CONTACT}</span>
        </div>
        <div className={b('section')}>
          {showEditForm ? (
            <>
              <TextField name="contact_name" label={lang.NAME_SURNAME} withWrap />
              <TextField name="contact_email" label={lang.EMAIL} withWrap />
              <TextField name="contact_phone" label={lang.TEL} />
            </>
          ) : (
            <>
              <span>
                {lang.NAME_SURNAME}: {formValues.contact_name}
              </span>
              <span>
                {lang.EMAIL}: {formValues.contact_email}
              </span>
              <span>
                {lang.TEL}: {formValues.contact_phone}
              </span>
            </>
          )}
        </div>
        <div className={b('section-label')}>
          <span>{lang.RESPONSIBLE_FOR_ANIMATION}</span>
        </div>
        <div className={b('section')}>
          {showEditForm ? (
            <>
              <TextField name="security_email" label={lang.EMAIL} withWrap />
              <TextField name="security_fax" label={lang.FAX} withWrap />
            </>
          ) : (
            <>
              <span>
                {lang.EMAIL}: {formValues.security_email}
              </span>
              <span>
                {lang.FAX}: {formValues.security_fax}
              </span>
            </>
          )}
        </div>
      </div>
      {showEditForm && (
        <AppButton
          label={lang.CONFIRM_BUTTON}
          className={b('button')}
          onClick={handleSubmitClick}
          disabled={invalid}
          size="small"
        />
      )}
    </div>
  );
};

const form = reduxForm<AnimationBrandInfoProps, AnimationBrandInfoForm>({
  form: AnimationBrandInfoFormName,
})(AnimationBrandInfo);

export default form;
