import React from 'react';

import {Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {CLIENT_PAGES} from 'client/common/config';

function NoOfLeadsCell({data, rowIndex, field}) {
  const isImport = data[rowIndex].type.endsWith('Import');
  return (
    <Cell>
      <Link
        to={
          isImport
            ? `${CLIENT_PAGES.LEADS_IMPORT}/${data[rowIndex].id}`
            : `${CLIENT_PAGES.LEADS_EXPORT}/${data[rowIndex].id}`
        }
        className="link"
      >
        {data[rowIndex][field]}
      </Link>
    </Cell>
  );
}

NoOfLeadsCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

NoOfLeadsCell.defaultProps = {
  data: [],
};

export default NoOfLeadsCell;
