import React, {Component} from 'react';

import isArray from 'lodash/isArray';
import partialRight from 'lodash/partialRight';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getCoupons} from 'client/ducks/coupon-tokens/actions';

import Modal from 'client/common/modals/modal';

// components
import AsyncDropdownAny from 'client/components/common/async-dropdown-any';

import {createGroupsConfig, FilterGroup} from 'client/components/tokens/modals/tokens-filter-group';

import './tokens-filter-modal.scss';

class TokensFiltersModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {},
    };
  }

  getCouponOptions = async (search = '') => {
    if (!search || !this.props.operationId) {
      return [];
    }

    await this.props.getCoupons({
      include: ['coupon_tokens'],
      q: {
        operation_id_eq: this.props.operationId.toString(),
        internal_name_cont: search,
      },
    });

    return this.props.coupons.map(partialRight(pick, ['id', 'internal_name']));
  };

  handleChangeFilter = (filtersKey, value) => {
    const filters = {
      ...this.props.filters,
    };

    const selectedItem = isArray(value) ? value[0] : value;
    if (!selectedItem) {
      delete filters[filtersKey];
    } else {
      filters[filtersKey] = selectedItem;
    }

    this.props.onFilterChange(filters);
  };

  render() {
    const {
      onClose,
      totalItems,
      language,
      filters: {coupon},
    } = this.props;
    const {visualGroupConfig, testModeGroupConfig, leadsGroupConfig, createdGroupConfig, generatedGroupConfig} =
      createGroupsConfig(language);

    return (
      <Modal
        show={this.props.show}
        onClose={this.props.onClose}
        title={language.TITLE}
        dialogClassName="tokens-filter-modal"
      >
        <form onSubmit={onClose}>
          <div className="form-field-wrap">
            <AsyncDropdownAny
              label={language.INTERNAL_COUPON_NAME}
              getOptions={this.getCouponOptions}
              onChange={([value]) => this.handleChangeFilter('coupon', value)}
              value={[coupon]}
              labelKey="internal_name"
              valueKey="id"
            />
          </div>

          <FilterGroup
            config={generatedGroupConfig}
            onChange={this.handleChangeFilter}
            label={language.GENERATION_DATE}
            filters={this.props.filters}
          />

          <FilterGroup
            config={createdGroupConfig}
            onChange={this.handleChangeFilter}
            label={language.CREATION_DATE}
            filters={this.props.filters}
          />

          <FilterGroup
            config={testModeGroupConfig}
            onChange={this.handleChangeFilter}
            label={language.TEST_MODE}
            filters={this.props.filters}
          />

          <FilterGroup
            config={visualGroupConfig}
            onChange={this.handleChangeFilter}
            label={language.VISUAL}
            filters={this.props.filters}
          />

          <FilterGroup
            config={leadsGroupConfig}
            onChange={this.handleChangeFilter}
            label={language.LEADS}
            filters={this.props.filters}
          />
          <div className="tokens-filter-modal__footer">
            <button className="button" type="button" onClick={onClose}>
              {`${language.SHOW_RESULTS} (${totalItems})`}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

TokensFiltersModal.propTypes = {
  show: PropTypes.bool,
  totalItems: PropTypes.number,
  coupons: PropTypes.array,
  filters: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  getCoupons: PropTypes.func.isRequired,
  operationId: PropTypes.number.isRequired,
  language: PropTypes.object.isRequired,
};

TokensFiltersModal.defaultProps = {
  show: false,
  totalItems: 0,
  coupons: [],
};

export default connect(
  ({languageState, couponTokens}) => ({
    language: languageState.payload.TOKENS_FILTERS,
    coupons: couponTokens.coupons,
  }),
  {
    getCoupons,
  },
)(TokensFiltersModal);
