import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getLeadsImportReport(id, queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEAD_IMPORT_REPORTS}/${id}`,
        method: 'GET',
        noCache: true,
        types: [
          types.GET_LEADS_IMPORT_REPORT_REQUEST,
          types.GET_LEADS_IMPORT_REPORT_SUCCESS,
          types.GET_LEADS_IMPORT_REPORT_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}

export function getLeadsImportReports(queryParams = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEAD_IMPORT_REPORTS}`,
        method: 'GET',
        noCache: true,
        types: [
          types.GET_LEADS_IMPORT_REPORTS_REQUEST,
          types.GET_LEADS_IMPORT_REPORTS_SUCCESS,
          types.GET_LEADS_IMPORT_REPORTS_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}

export function getImportedLeads(queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEADS}`,
        method: 'GET',
        noCache: true,
        types: [types.GET_IMPORTED_LEADS_REQUEST, types.GET_IMPORTED_LEADS_SUCCESS, types.GET_IMPORTED_LEADS_ERROR],
        queryParams,
      },
      true,
    );
  };
}

export function deleteApiLeadsImport(id) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.API_LEAD_IMPORTS}/${id}`,
        method: 'DELETE',
        types: [
          types.DELETE_API_LEADS_IMPORT_REQUEST,
          types.DELETE_API_LEADS_IMPORT_SUCCESS,
          types.DELETE_API_LEADS_IMPORT_ERROR,
        ],
      },
      true,
    );
  };
}

export function getApiLeadImports(queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.API_LEAD_IMPORTS}`,
        method: 'GET',
        noCache: true,
        types: [
          types.GET_API_LEAD_IMPORTS_REQUEST,
          types.GET_API_LEAD_IMPORTS_SUCCESS,
          types.GET_API_LEAD_IMPORTS_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
}
