import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getScenarioExecutions(queryParams) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SCENARIO_EXECUTIONS}`,
      method: 'GET',
      types: [
        types.GET_SCENARIO_EXECUTIONS_REQUEST,
        types.GET_SCENARIO_EXECUTIONS_SUCCESS,
        types.GET_SCENARIO_EXECUTIONS_ERROR,
      ],
      noCache: true,
      queryParams,
    });
}

export function getVariables(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.VARIABLES}`,
      method: 'GET',
      types: [types.GET_VARIABLES_REQUEST, types.GET_VARIABLES_SUCCESS, types.GET_VARIABLES_ERROR],
      noCache: true,
      queryParams,
    });
}

export function clearScenarioRunTable() {
  return (dispatch) => dispatch({type: types.CLEAR_SCENARIO_RUN_TABLE});
}
