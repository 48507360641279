import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';
import {getClientPage, transformDate} from 'client/services/helpers';

import {CLIENT_PAGES} from 'client/common/config';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import FilterLabel from 'client/components/common/filter-label';
import Icon from 'client/components/common/icon';
import SearchField from 'client/components/common/search-field';

import cssModule from './operations-table.module.scss';

const b = bem('operations-table', {cssModule});

let LANGUAGE = {};

class OperationsTable extends ReactQueryParams {
  constructor(props) {
    super(props);

    LANGUAGE = props.languageState.payload.OPERATIONS;
  }

  componentDidUpdate(prevProps) {
    const {filters: nextFilters} = this.props;
    const {filters: prevFilters} = prevProps;

    if (nextFilters !== prevFilters) {
      const filteredLength = Object.keys(nextFilters).filter((key) => typeof nextFilters[key] !== 'undefined').length;

      this.setState({filteredLength});
    }
  }

  renderToolbar = () => {
    const {filters} = this.props;
    return (
      <div>
        <SearchField
          placeholder={LANGUAGE.SEARCH_BY_OPERATION_PLACEHOLDER}
          onSearch={this.props.onSearch}
          onClear={this.props.onSearchClear}
          showClear={this.props.showClear}
          searchDefault={this.props.search}
          cssModifier={b('search-input')}
        />

        <button className="button button--bg-4" onClick={this.props.editFiltersButtonClick}>
          <Icon className="button__icon" name="filter" />
          <span>
            {LANGUAGE.FILTERS_BUTTON} ({Object.keys(filters).length})
          </span>
        </button>
        {this.props.filters.length > 0 && (
          <span className={b('search-result-num')}>
            <span className="main-text main-text--bold">{this.props.totalItems}</span>
            <span className="main-text">{` ${LANGUAGE.RESULTS}`}</span>
          </span>
        )}
        <div className={b('filter-labels')}>
          {filters.client && (
            <FilterLabel
              text={`${LANGUAGE.CLIENT_FILTER_LABEL}: ${filters.client.name}`}
              onDelete={() => this.props.deleteFilterClick(['client'])}
            />
          )}
          {filters.start_date && (
            <FilterLabel
              text={
                `${LANGUAGE.FILTER_MODAL_START_DATE_LABEL} ${LANGUAGE.FILTER_DATE_TYPE[filters.start_date.type]} ` +
                filters.start_date.date
              }
              onDelete={() => this.props.deleteFilterClick(['start_date'])}
            />
          )}
          {filters.end_date && (
            <FilterLabel
              text={
                `${LANGUAGE.FILTER_MODAL_END_DATE_LABEL} ${LANGUAGE.FILTER_DATE_TYPE[filters.end_date.type]} ` +
                filters.end_date.date
              }
              onDelete={() => this.props.deleteFilterClick(['end_date'])}
            />
          )}
          {filters.status && (
            <FilterLabel
              text={LANGUAGE.OPERATION_STATUS[filters.status]}
              onDelete={() => this.props.deleteFilterClick(['status'])}
            />
          )}
          {this.props.filters.length > 0 && (
            <button className="button button--bg-4" onClick={this.props.clearFiltersButtonClick}>
              {LANGUAGE.CLEAR_FILTERS_BUTTON}
            </button>
          )}
        </div>
      </div>
    );
  };

  renderName = ({item}) => {
    const clientPage = `${getClientPage(item.client)}/${item.client_id}`;
    return (
      <Link
        to={{
          pathname: `${clientPage}${CLIENT_PAGES.OPERATIONS}/${item.id}`,
          state: {
            fromOperations: this.props.operationsType,
          },
        }}
        className={b('link')}
      >
        {item.name}
      </Link>
    );
  };

  renderClient = ({item}) => {
    return (
      item.client && (
        <Link to={`${getClientPage(item.client)}/${item.client.id}`} className={b('link')}>
          {item.client.name}
        </Link>
      )
    );
  };

  renderStatus = ({item}) => {
    return LANGUAGE.OPERATION_STATUS[item.status];
  };

  renderTasks = ({item}) => {
    const {automation_tasks = [], message_tasks = [], client} = item;

    const operationPage = `${getClientPage(client)}/${client.id}${CLIENT_PAGES.OPERATIONS}/${item.id}`;

    const mapTask = (task, path) => ({
      ...task,
      link: `${operationPage}${path}/${task.id}`,
      uid: `${path}${task.id}`,
    });

    const tasks = [
      ...automation_tasks.map((task) => mapTask(task, CLIENT_PAGES.AUTOTASK)),
      ...message_tasks.map((task) => mapTask(task, CLIENT_PAGES.MESSAGE_TASK)),
    ];

    return (
      <div className="u-list u-list--no-markers">
        <CustomScrollbars
          scrollbarProps={{
            autoHeightMax: 84,
          }}
        >
          <div style={{width: '74%'}}>
            {tasks.map((task) => (
              <div className="u-list__item" key={task.uid}>
                <Link to={task.link} className={b('link')}>
                  <span className="ellipsis-text">{task.name}</span>
                </Link>
              </div>
            ))}
          </div>
        </CustomScrollbars>
      </div>
    );
  };

  renderDate = ({value}) => {
    return transformDate(value);
  };

  render() {
    return (
      <>
        <div className={b('toolbar-wrapper')}>
          {this.renderToolbar()}
          <PerPageDropdown
            onChange={(pages) => {
              this.props.onPageChange({page: 1, perPage: pages});
            }}
            value={String(this.props.perPage)}
            simpleValue
          />
        </div>

        <ClientTable
          data={this.props.data}
          sortField={this.props.sort?.name}
          sortOrder={this.props.sort?.order}
          onSortChange={this.props.onSortChange}
          columns={[
            {
              label: LANGUAGE.OPERATION_NAME_COLUMN,
              render: this.renderName,
              sortable: true,
              name: 'name',
              width: '25%',
            },
            {label: LANGUAGE.CODE_COLUMN, path: 'code', width: '9%'},
            {
              label: LANGUAGE.START_DATE_COLUMN,
              path: 'from',
              name: 'from',
              render: this.renderDate,
              sortable: true,
              width: '9%',
            },
            {
              label: LANGUAGE.END_DATE_COLUMN,
              path: 'to',
              name: 'to',
              render: this.renderDate,
              sortable: true,
              width: '9%',
            },
            {
              label: LANGUAGE.STATUS_COLUMN,
              path: 'status',
              name: 'status',
              render: this.renderStatus,
              sortable: true,
              width: '8%',
            },
            {
              label: LANGUAGE.CLIENT_COLUMN,
              path: 'client_name',
              name: 'client_name',
              render: this.renderClient,
              sortable: true,
              width: '20%',
            },
            {label: LANGUAGE.TASKS_COLUMN, path: 'tasks', render: this.renderTasks, width: '20%'},
          ]}
        />
        <PaginationBar
          data={this.props.data}
          perPage={this.props.perPage}
          totalPages={this.props.totalPages}
          totalItems={this.props.totalItems}
          currentPage={this.props.currentPage}
          onPageChange={this.props.onPageChange}
        />
      </>
    );
  }
}

OperationsTable.defaultProps = {
  data: [],
  filters: [],
  operationsType: '',
};

OperationsTable.propTypes = {
  filters: PropTypes.object,
  onSearchClear: PropTypes.func,
  showClear: PropTypes.bool,
  data: PropTypes.array,
  search: PropTypes.string,
  children: PropTypes.any,
  totalPages: PropTypes.number,
  totalItems: PropTypes.number,
  perPage: PropTypes.number,
  currentPage: PropTypes.number,
  onSearch: PropTypes.func,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  editFiltersButtonClick: PropTypes.func,
  clearFiltersButtonClick: PropTypes.func,
  deleteFilterClick: PropTypes.func,
  languageState: PropTypes.object,
  operationsType: PropTypes.string,
};

export default connect(({languageState}) => ({languageState}))(OperationsTable);
