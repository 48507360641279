import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import {formatNumberSpace} from 'client/services/formatters';
import {getOrGenerateColor, generateRandomString} from 'client/services/helpers';

import {tunnelColors} from './constants';

const createBar = (level) => {
  return {
    key: generateRandomString(),
    label: level.interface_name,
    color: level.color,
    value: level.participations_count,
    hint: `${level.interface_name}: ${formatNumberSpace(level.participations_count)}`,
  };
};

export const mapData = (levelStatistic) => {
  const result = [];
  const allLevels = [];

  levelStatistic.forEach((statItem, statIndex) => {
    const color = getOrGenerateColor(tunnelColors, statIndex);
    const lowestNumber = Math.min(...statItem.interface_levels.map(({number}) => number));

    statItem.interface_levels.forEach((level) => {
      allLevels.push({
        ...level,
        color,
        interface_name: statItem.name,
        lowest_number: lowestNumber,
      });
    });
  });

  const allLevelNumbers = allLevels.map((item) => item.number);
  const levelNumbers = [...new Set(allLevelNumbers)].sort((a, b) => a - b);

  levelNumbers.forEach((number) => {
    const levelsByNumber = allLevels.filter((item) => item.number === number);
    const levelsWithParticipations = levelsByNumber.filter((item) => item.participations_count > 0);
    const levelsWithoutParticipations = levelsByNumber.filter((item) => item.participations_count === 0);
    const minLevelAlreadySet = find(result, {marked: true});

    if (levelsWithParticipations.length > 0) {
      // Combine levels to one point on X axis
      const levelsSortedByLowestNumber = sortBy(levelsWithParticipations, 'lowest_number');
      const levelsSortedByDate = sortBy(levelsWithParticipations, (level) => new Date(level.min_participated));

      result.push({
        label: levelsSortedByDate[0].code,
        hint: levelsSortedByDate[0].name,
        bars: [...levelsSortedByLowestNumber.map(createBar), ...levelsWithoutParticipations.map(createBar)],
        total: levelsSortedByDate.reduce((acc, item) => acc + item.participations_count, 0),
        marked: !minLevelAlreadySet && levelsByNumber.filter((level) => level.minimum_level).length > 0,
      });
    } else {
      // Add every level as a separate point on X axis
      const levelsSortedByCode = sortBy(levelsByNumber, 'code');

      levelsSortedByCode.forEach((level) => {
        result.push({
          label: level.code,
          hint: level.name,
          bars: [createBar(level)],
          total: 0,
          marked: !minLevelAlreadySet && level.minimum_level,
        });
      });
    }
  });

  return result;
};
