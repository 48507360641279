export const getTranslationFormInitialValues = (translation, languages) => {
  const initialValues = {};

  if (!translation) {
    return initialValues;
  }

  const {id, parent_id, key, translation_items, description} = translation;
  const translationsItems = languages.reduce((prev, language) => {
    return {
      ...prev,
      [language]: {id: null, value: null},
    };
  }, {});

  Object.assign(initialValues, {id, parent_id, key, description, withTranslations: false});

  if (!translation.hasChildren) {
    translation_items?.forEach(({language, ...item}) => {
      if (translationsItems[language]) {
        translationsItems[language] = item;
      }
    });
    initialValues.items = translationsItems;
    initialValues.withTranslations = true;
  }

  return initialValues;
};
