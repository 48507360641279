import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons';
import Popover from 'client/common/popovers/popover';

import {TranslationJsx} from 'client/models/language/types';

import './overall-input-button.scss';

const b = bem('overall-input-button');

const OverallInputButton = (props) => {
  const {size, iconName, showTooltip, label, tooltipText, onClick, disabled} = props;

  return showTooltip ? (
    <Popover
      position="top"
      shiftTop={-10}
      arrowConfig={{
        fill: '#4384ab',
        width: 30,
        height: 10,
      }}
      overlayInnerStyle={{
        color: 'white',
      }}
      overlay={<span>{tooltipText}</span>}
    >
      <AppButton
        label={label}
        iconName={iconName}
        size={size}
        color="light-clients"
        onClick={onClick}
        className={b('button', ['icon'])}
        disabled={disabled}
      />
    </Popover>
  ) : (
    <AppButton
      label={label}
      iconName={iconName}
      size={size}
      color="light-clients"
      onClick={onClick}
      className={b('button')}
      disabled={disabled}
    />
  );
};

OverallInputButton.propTypes = {
  showTooltip: PropTypes.bool,
  iconName: PropTypes.string,
  label: TranslationJsx,
  tooltipText: TranslationJsx,
  size: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

OverallInputButton.defaultProps = {
  iconName: '',
  showTooltip: false,
  size: 'default',
  tooltipText: '',
  label: '',
  disabled: false,
};

export default OverallInputButton;
