import React from 'react';

import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';

import ParametersModal from 'client/components/email-template-editor/modals/parameters-modal';
import {EmailParams} from 'client/components/email-template-editor/types';
import {EmailTemplateKind} from 'client/models/email-templates/types';

import {getFromString} from './helpers';
import ParametersBarSkeleton from './parameters-bar-skeleton';

import cssModule from './parameters-bar.module.scss';

const b = bem('parameters-bar', {cssModule});

type ParametersBarProps = {
  loading?: boolean;
  emailParams: EmailParams;
  setEmailParams: (params: EmailParams) => void;
  kind: EmailTemplateKind;
};

const ParametersBar: React.FC<ParametersBarProps> = (props) => {
  const {loading, emailParams, setEmailParams, kind} = props;
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.BARS.PARAMETERS_BAR');
  const [showParametersModal, toggleParametersModal] = useToggle(false);
  const from = getFromString(emailParams);

  if (loading) {
    return (
      <div className={b()}>
        <ParametersBarSkeleton />
      </div>
    );
  }

  return (
    <div className={b()}>
      {showParametersModal && (
        <ParametersModal
          emailParams={emailParams}
          setEmailParams={setEmailParams}
          onCancel={toggleParametersModal}
          kind={kind}
        />
      )}
      <div className={b('params')}>
        <div className={b('labels')}>
          <p className={b('label')}>{lang.SUBJECT}</p>
          <p className={b('label')}>{lang.FROM}</p>
        </div>
        <div className={b('values')}>
          <p className={b('value')} title={emailParams.subject}>
            {emailParams.subject}
          </p>
          <p className={b('value')} title={from}>
            {from}
          </p>
        </div>
      </div>
      <AppButton
        label={lang.EDIT_PARAMETERS}
        iconName="edit-pencil"
        color="light-clients"
        onClick={toggleParametersModal}
      />
    </div>
  );
};

export default ParametersBar;
