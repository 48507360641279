import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import './results-found.scss';

function ResultsFound(props) {
  const {lang, value} = props;

  return (
    <div className="results-found">
      {value} {lang.RESULTS}
    </div>
  );
}

ResultsFound.propTypes = {
  lang: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired,
};

export default connect((state) => ({
  lang: state.languageState.payload.TABLE,
}))(ResultsFound);
