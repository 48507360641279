import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getLeadsTimeline} from 'client/ducks/leads-dashboard/actions';
import {selectLeadsTimeline} from 'client/ducks/leads-dashboard/selectors';

import DashboardCard from 'client/components/common/cards/dashboard-card';
import TimelineChart from 'client/components/common/charts-custom/timeline-chart';

class LeadsTimeline extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    empty: PropTypes.bool.isRequired,
    timeline: PropTypes.object.isRequired,
    getLeadsTimeline: PropTypes.func.isRequired,
  };

  fetchTimeline = (params) => {
    return this.props.getLeadsTimeline({
      client_id: this.props.client.id,
      ...params,
    });
  };

  render() {
    const {lang, client, empty, timeline} = this.props;

    return (
      <DashboardCard
        title={lang.LEADS_TIMELINE}
        iconName="leads-timeline"
        contentHeight={290}
        empty={empty}
        emptyText={lang.NO_DATA}
      >
        <TimelineChart
          prefix="leads-timeline"
          uid={client.id}
          onFetch={this.fetchTimeline}
          points={timeline.points}
          loadingColor="leads"
          lines={[
            {
              name: 'leads',
              label: lang.LEADS,
              borderColor: '#8589F1',
              data: timeline.leads,
            },
            {
              name: 'leads_with_optins',
              label: lang.LEADS_WITH_OPTINS,
              borderColor: '#00629C',
              data: timeline.leads_with_optins,
            },
          ]}
        />
      </DashboardCard>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.languageState.payload.LEADS_DASHBOARD,
    timeline: selectLeadsTimeline(state),
  }),
  {
    getLeadsTimeline,
  },
)(LeadsTimeline);
