import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getFirstSources(queryParams = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        queryParams,
        endpoint: `${API_PATH}${API_METHODS.LEAD_FIRST_SOURCES}`,
        method: 'GET',
        types: [types.GET_FIRST_SOURCES_REQUEST, types.GET_FIRST_SOURCES_SUCCESS, types.GET_FIRST_SOURCES_ERROR],
      },
      true,
    );
  };
}

export function getSources(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.LEAD_FIRST_SOURCES}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_FIRST_SOURCES_REQUEST, types.GET_FIRST_SOURCES_SUCCESS, types.GET_FIRST_SOURCES_ERROR],
      queryParams,
    });
}
