import React, {useCallback, useMemo, useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {dateToString} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import {
  getAutotaskValidationLog,
  getAutotaskPublicationWebAppLog,
  getAutotaskPublicationDevicepLog,
} from 'client/ducks/autotask/actions';
import {
  selectAutotaskValidationLog,
  selectAutotaskPublicationWebAppLog,
  selectAutotaskPublicationDeviceLog,
} from 'client/ducks/autotask/selectors';

import ClientTable from 'client/components/common/client-table';
import CustomScrollbars from 'client/components/common/custom-scrollbars';

import AutotaskCard from 'client/components/autotask/components/cards/autotask-card/autotask-card';
import {Autotask} from 'client/models/autotask/types';
import {isOperationArchived} from 'client/models/operations/helpers';
import {ApiDispatch} from 'client/types';

import AutotaskManagementCardHeader from './autotask-management-card-header';
import {getStatus} from './helpers';

import cssModule from './autotask-management-card.module.scss';

const b = bem('autotask-management-card', {cssModule});

type AutotaskManagementCardProps = {
  autotask: Autotask;
  fetchAutotask: () => void;
};

const AutotaskManagementCard: React.FC<AutotaskManagementCardProps> = (props) => {
  const {autotask, fetchAutotask} = props;
  const {operation} = autotask;

  const clientInterface = autotask?.operation?.client_interface;
  const isAssisted = autotask?.operation?.client?.auto_configuration; // Assisted = true
  const lang = useLanguage('AUTOTASK.COMPONENTS.CARDS.AUTOTASK_MANAGEMENT_CARD');
  const dispatch: ApiDispatch = useDispatch();

  const [loading, toggleLoading] = useToggle(false);

  const isDevice = operation?.client_interface?.device;
  const isWebApp = operation?.client_interface?.online;

  const validationLog = useSelector(selectAutotaskValidationLog);
  const publicationWebAppLog = useSelector(selectAutotaskPublicationWebAppLog);
  const publicationDeviceLog = useSelector(selectAutotaskPublicationDeviceLog);

  const isOpArchived = isOperationArchived(operation);

  const fetchValidationLog = useCallback(() => {
    if (isAssisted) {
      dispatch(getAutotaskValidationLog(autotask.id));
    }
    if (clientInterface) {
      dispatch(getAutotaskPublicationDevicepLog(clientInterface?.id));
    }
    if (clientInterface?.web_app?.id) {
      dispatch(getAutotaskPublicationWebAppLog(clientInterface?.web_app?.id));
    }
  }, [autotask.id, dispatch, clientInterface, isAssisted]);

  const handleFetchUpdate = useCallback(() => {
    if (isAssisted) {
      fetchAutotask();
    }
    fetchValidationLog();
  }, [fetchAutotask, fetchValidationLog, isAssisted]);

  useEffect(() => {
    toggleLoading();
    fetchValidationLog();
    toggleLoading();
  }, [dispatch, fetchValidationLog, clientInterface?.id, toggleLoading]);

  const data = useMemo(
    () => [...validationLog, ...publicationWebAppLog, ...publicationDeviceLog],
    [publicationWebAppLog, publicationDeviceLog, validationLog],
  )
    .filter((item) => item?.value_after !== 'created')
    .sort((first, second) => new Date(second.changed_at).getTime() - new Date(first.changed_at).getTime());

  const deviceColumn = useMemo(() => {
    return isDevice
      ? {
          name: 'device_status',
          label: lang.DEVICE_STATUS,
          render: ({item}: any) =>
            item.type === 'device' || item.type === 'validation'
              ? lang[getStatus(item?.value_after, item?.value_before)]
              : '–',
        }
      : null;
  }, [isDevice, lang]);

  const webAppColumn = useMemo(() => {
    return isWebApp
      ? {
          name: 'web_app_status',
          label: lang.ONLINE_STATUS,
          render: ({item}: any) =>
            item.type === 'web_app' || item.type === 'validation'
              ? lang[getStatus(item?.value_after, item?.value_before)]
              : '–',
        }
      : null;
  }, [isWebApp, lang]);

  const columns = useMemo(() => {
    return [
      {
        name: 'date',
        path: 'changed_at',
        label: lang.DATE,
        formatter: (value: Date) => dateToString(value, 'DD/MM/YYYY HH:mm:ss'),
      },
      {
        name: 'user',
        path: 'changed_by.full_name',
        label: lang.USER,
      },
      deviceColumn,
      webAppColumn,
    ].filter(Boolean);
  }, [deviceColumn, lang.DATE, lang.USER, webAppColumn]);

  return (
    <AutotaskCard
      title={lang.TITLE}
      showPagination={false}
      className={b()}
      header={
        isAssisted ? (
          <AutotaskManagementCardHeader
            autotask={autotask}
            onUpdate={handleFetchUpdate}
            isDevice={isDevice}
            isWebApp={isWebApp}
            disabled={isOpArchived}
          />
        ) : null
      }
    >
      <CustomScrollbars
        scrollbarProps={{
          autoHeightMax: 300,
        }}
      >
        <ClientTable data={data} loading={loading} columns={columns} rowKey="changed_at" />
      </CustomScrollbars>
    </AutotaskCard>
  );
};

export default AutotaskManagementCard;
