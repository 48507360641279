import {useSelector} from 'react-redux';

import {selectCurrentMembershipLevel} from 'client/ducks/user-clients/selectors';

import {USER_MEMBERSHIP} from 'client/common/config';

export default () => {
  const level = useSelector(selectCurrentMembershipLevel);

  const isClientAdmin = level === USER_MEMBERSHIP.CLIENT_ADMIN;
  const isNational = level === USER_MEMBERSHIP.NATIONAL;
  const isRegion = level === USER_MEMBERSHIP.REGION;
  const isStore = level === USER_MEMBERSHIP.STORE;
  const isLocal = level === USER_MEMBERSHIP.LOCAL;

  return {
    isClientAdmin,
    isNational,
    isRegion,
    isStore,
    isLocal,
    isAdminOrNational: isClientAdmin || isNational,
  };
};
