import React, {useState} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {useToggle} from 'react-use';
import {Field} from 'redux-form';

import bem from 'client/services/bem';
import {blobToBase64} from 'client/services/blobToBase64';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {interpolate} from 'client/ducks/language/helpers';

import FileButton from 'client/common/buttons/file-button';
import ToggleField from 'client/common/fields/toggle-field';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import PrizeDiyCouponImportInfo from 'client/components/prizes/elements/prize-diy-coupon-import-info';
import TokensReimportPopover from 'client/components/prizes/popovers/tokens-reimport-popover';
import {TranslationJsx} from 'client/models/language/types';

import {getMessageKey} from './helpers';

import {PrizeDiyModalFormName} from '../../modals/prize-diy-modal';

import './prize-diy-coupon-import-field.scss';

const b = bem('prize-diy-coupon-import-field');

const PrizeDiyCouponImportFieldComponent = (props) => {
  const {
    label,
    showToggle,
    disabled,
    isOperationStarted,
    input: {name, value},
  } = props;
  const lang = useLanguage('PRIZES.FIELDS.PRIZE_DIY_COUPON_IMPORT_FIELD');
  const {change, formValues} = useReduxForm(PrizeDiyModalFormName);
  const [showImportedConfirmation, toggleImportedConfirmation] = useToggle(false);
  const [fileButtonRef, setFileButtonRef] = useState(null);

  const fieldValues = get(formValues, name);

  const isDeviceLevelCodeList = showToggle && formValues.coupon.imports.device_level_code_list;
  const isPrizeLevelCodeList = !showToggle || !isDeviceLevelCodeList;
  const messageKey = getMessageKey(disabled, isPrizeLevelCodeList, value);
  const showFileName = (isPrizeLevelCodeList && !!value.file) || !!value.filename;
  const showInfo = isPrizeLevelCodeList && !value.file && value.url;

  const showWarning = isOperationStarted && fieldValues.tokens_used && value.url;

  const importedOnDeviceLevel = formValues.coupon.imports.device_level.tokens_imported;
  const importedOnPrizeLevel = fieldValues.tokens_imported;

  const onChangeFile = async (nativeFile) => {
    const file = await blobToBase64(nativeFile);
    change(`${name}.file`, file);
    change(`${name}.filename`, nativeFile.name);
    change(`${name}.name`, nativeFile.name);
  };

  const changeCodeList = () => {
    change(`${name}`, {});

    // to show confirmation only on first switching
    if (isPrizeLevelCodeList && importedOnDeviceLevel) {
      change('coupon.imports.device_level.tokens_imported', 0);
    }
  };

  const onChangeCodeList = (event) => {
    const checked = event.target.checked;

    if ((checked && fieldValues.url) || (!checked && importedOnDeviceLevel)) {
      toggleImportedConfirmation();
    } else {
      changeCodeList();
    }
  };

  return (
    <div className={b()}>
      <p className={b('label')}>{label}</p>
      {showToggle && (
        <ToggleField
          label={lang.DEVICE_LEVEL}
          name="coupon.imports.device_level_code_list"
          backgroundColor="current"
          borderColor="current"
          labelPosition="left"
          title={isOperationStarted ? lang.MESSAGES.SEGMENTATION_DISABLED : null}
          disabled={disabled || isOperationStarted}
          onChange={onChangeCodeList}
          rounded
          withWrap
        />
      )}
      <TokensReimportPopover
        usedTokensCount={fieldValues.tokens_used}
        onConfirm={() => fileButtonRef.current.click()}
        disabled={!showWarning}
      >
        <FileButton
          className={b('button')}
          label={lang.IMPORT_FILE}
          name={`${name}.file`}
          iconName="exports"
          color="light-clients"
          acceptFormats=".txt"
          onChange={onChangeFile}
          onClick={(ref) => {
            if (showWarning) {
              setFileButtonRef(ref);
            } else {
              ref.current.click();
            }
          }}
          disabled={disabled || (showToggle && isDeviceLevelCodeList) || value.filename}
        />
      </TokensReimportPopover>
      {messageKey && <p className={b('message')}>{lang.MESSAGES[messageKey]}</p>}
      {showFileName && <p className={b('message')}>{value.name || value.filename}</p>}
      {showInfo && <PrizeDiyCouponImportInfo data={value} />}
      <ConfirmationModal
        show={showImportedConfirmation}
        clientSide
        message={interpolate(lang.MESSAGES.TOKENS_IMPORTED, {
          imported: isDeviceLevelCodeList ? importedOnPrizeLevel : importedOnDeviceLevel,
          level: isDeviceLevelCodeList ? lang.MESSAGES.GLOBAL : lang.MESSAGES.DEVICE,
        })}
        buttonConfirm={{
          label: lang.YES,
          onClick: () => {
            changeCodeList();
            toggleImportedConfirmation();
          },
        }}
        buttonCancel={{
          label: lang.NO,
          onClick: () => {
            change('coupon.imports.device_level_code_list', !isDeviceLevelCodeList);
            toggleImportedConfirmation();
          },
        }}
      />
    </div>
  );
};

PrizeDiyCouponImportFieldComponent.propTypes = {
  label: TranslationJsx.isRequired,
  showToggle: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  isOperationStarted: PropTypes.bool.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
      file: PropTypes.object,
      url: PropTypes.string,
      name: PropTypes.string,
      filename: PropTypes.string,
      tokens_used: PropTypes.number,
      tokens_imported: PropTypes.number,
      uploaded_at: PropTypes.string,
      device_level_code_list: PropTypes.bool,
    }).isRequired,
  }).isRequired,
};

const PrizeDiyCouponImportField = (props) => {
  const {show, ...rest} = props;

  return show ? <Field component={PrizeDiyCouponImportFieldComponent} {...rest} /> : null;
};

PrizeDiyCouponImportField.propTypes = {
  name: PropTypes.string.isRequired,
  showToggle: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
};

export default PrizeDiyCouponImportField;
