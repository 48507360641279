import React, {Component} from 'react';

import find from 'lodash/find';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import pure from 'client/services/hocs/pure';
import {required, alphaNumeric} from 'client/services/validator';

import {getInterfacesAction} from 'client/ducks/interfaces/actions';

import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

class SetInterfaceNameModal extends Component {
  static propTypes = {
    autotaskId: PropTypes.number.isRequired,
    lang: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    fetchInterfaces: PropTypes.func.isRequired,
    show: PropTypes.bool,
  };

  static defaultProps = {
    show: false,
  };

  static formName = 'SetInterfaceNameForm';

  static ignoredProps = ['onSubmit', 'onClose'];

  static titleCaption = '(1/5)';

  rules = {
    name: [required(this.props.lang.NAME_IS_REQUIRED_ERROR)],
    code: [
      required(this.props.lang.CODE_IS_REQUIRED_ERROR),
      alphaNumeric(this.props.lang.CODE_IS_ALPHA_NUMERIC_ERROR, false),
    ],
  };

  save = ({interface_name, interface_code}) => {
    const {lang} = this.props;
    return this.props
      .fetchInterfaces(
        {
          q: {
            g: {
              0: {
                automation_task_id_eq: this.props.autotaskId,
                name_eq: interface_name,
              },
              1: {
                code_eq: interface_code,
              },
            },
            m: 'or',
          },
        },
        true,
      )
      .then(({payload: {interfaces}}) => {
        const isNameTaken = !!find(interfaces, ({name}) => name === interface_name);
        const isCodeTaken = !!find(interfaces, ({code}) => code === interface_code);
        if (isNameTaken || isCodeTaken) {
          throw new SubmissionError({
            interface_name: isNameTaken ? lang.NAME_IS_UNIQUE_ERROR : null,
            interface_code: isCodeTaken ? lang.CODE_IS_UNIQUE_ERROR : null,
          });
        }
      })
      .then(this.props.onSubmit);
  };

  render() {
    const {show, handleSubmit, onClose, lang} = this.props;
    const {rules} = this;
    const title = `${lang.TITLE} ${SetInterfaceNameModal.titleCaption}`;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-8"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={title}
      >
        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          <FormFieldWrap>
            <Field
              label={lang.INTERFACE_NAME_LABEL}
              name="interface_name"
              type="text"
              component={fieldTemplate}
              validate={rules.name}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              label={lang.INTERFACE_CODE_LABEL}
              name="interface_code"
              type="text"
              component={fieldTemplate}
              validate={rules.code}
            />
          </FormFieldWrap>
          <FormFieldWrap isButtonsContainer>
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onClose}>
              {lang.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-7 modal-window__footer-btn" type="submit">
              {lang.NEXT_BUTTON}
            </button>
          </FormFieldWrap>
        </form>
      </Modal>
    );
  }
}

const SetInterfaceNameForm = reduxForm({
  form: SetInterfaceNameModal.formName,
})(SetInterfaceNameModal);

export default connect(
  (state) => {
    return {
      lang: state.languageState.payload.AUTOTASK.SET_INTERFACE_NAME_MODAL,
    };
  },
  {
    fetchInterfaces: getInterfacesAction,
  },
)(pure(SetInterfaceNameForm, SetInterfaceNameForm.ignoredProps));
