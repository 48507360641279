import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {TextInput} from 'client/common/inputs';
import LayoutPanel from 'client/common/panels/layout-panel';

import cssModule from './link-alt-fields.module.scss';

const b = bem('link-alt-fields', {cssModule});

type LinkAltFieldsProps = {
  className?: string;
  values: {
    alt: string;
    url: string;
  };
  onChange: (nextValues: LinkAltFieldsProps['values']) => void;
};

const LinkAltFields: React.FC<LinkAltFieldsProps> = ({className, values, onChange}) => {
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.MODALS.IMAGE_EDIT');

  return (
    <LayoutPanel className={cn(b(), className)}>
      <TextInput
        label={lang.IMAGE_LINK}
        className={b('field')}
        classNames={{
          label: b('field-label'),
        }}
        value={values?.url}
        onChange={(e) =>
          onChange({
            ...values,
            url: e.target.value,
          })
        }
      />
      <TextInput
        label={lang.ALT_TEXT}
        className={b('field')}
        classNames={{
          label: b('field-label'),
        }}
        value={values?.alt}
        onChange={(e) =>
          onChange({
            ...values,
            alt: e.target.value,
          })
        }
      />
    </LayoutPanel>
  );
};

export default LinkAltFields;
