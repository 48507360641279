import {useCallback, useMemo} from 'react';

import {keyBy} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import {uid} from 'client/services/helpers';

import {updateEmailTemplateImage, uploadEmailTemplateImage} from 'client/ducks/email-templates/actions';
import {selectActiveTemplate} from 'client/ducks/email-templates/selectors';

import {ImageBlockData, TemplateData} from 'client/components/email-template-editor/types';
import {ApiDispatch} from 'client/types';

/**
 * NOTE:
 * After creation Operation Email Template from Master, backend has copied 'email_template_images' from master but with new ID.
 * 'json_form' is used only by frontend and BE can't copy 'email_template_images' to there too.
 * So when we remove or edit 'email_template_image' in 'json_form', we must use email_template_image and their 'ids' only from 'email_template.email_template_images'.
 * We can detect a right image from 'email_template_images' by 'title'. It's a field with unique value, which we generate every time when create a new image.
 */

const usePrepareTemplateData = () => {
  const [loading, toggleLoading] = useToggle(false);
  const dispatch: ApiDispatch = useDispatch();
  const activeTemplate = useSelector(selectActiveTemplate);

  const emailTemplateImagesByTitle = useMemo(
    () => keyBy(activeTemplate?.email_template_images, 'title'),
    [activeTemplate?.email_template_images],
  );

  const updateImage = useCallback(
    (dataUrl: string, id?: number) => {
      if (id) {
        return updateEmailTemplateImage(id, {
          email_template_image: {
            file: {
              data: dataUrl,
            },
          },
        });
      }
      return uploadEmailTemplateImage({
        email_template_image: {
          file: {
            filename: uid(),
            data: dataUrl,
          },
          title: uid(),
          email_template_id: activeTemplate?.id,
        },
      });
    },
    [activeTemplate?.id],
  );

  const prepare = useCallback(
    async (data: TemplateData) => {
      toggleLoading();

      const nextData = {...data};

      try {
        nextData.body = await Promise.all(
          nextData.body.map(async (section) => {
            const nextSection = {...section};

            nextSection.columns = await Promise.all(
              nextSection.columns.map(async (column) => {
                const nextColumn = {...column};

                nextColumn.contentBlocks = await Promise.all(
                  nextColumn.contentBlocks.map(async (contentBlock) => {
                    const nextContentBlock = {...contentBlock};

                    if (nextContentBlock.type === 'image') {
                      const content = nextContentBlock.content as ImageBlockData;

                      if (content.src?.startsWith('data:image')) {
                        const response = await dispatch(
                          updateImage(
                            content.src,
                            emailTemplateImagesByTitle[content.emailTemplateImage?.title || '']?.id,
                          ),
                        );

                        content.src = response.payload.email_template_image.file_url;
                        content.emailTemplateImage = response.payload.email_template_image;
                      }
                    }

                    return nextContentBlock;
                  }),
                );
                return nextColumn;
              }),
            );

            return section;
          }),
        );
      } finally {
        toggleLoading();
      }

      return nextData;
    },
    [dispatch, emailTemplateImagesByTitle, toggleLoading, updateImage],
  );

  return {
    prepare,
    loading,
  };
};

export default usePrepareTemplateData;
