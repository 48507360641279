export default {
  GET_CLIENTS_REQUEST: '@@linkToClient/GET_CLIENTS_REQUEST',
  GET_CLIENTS_SUCCESS: '@@linkToClient/GET_CLIENTS_SUCCESS',
  GET_CLIENTS_ERROR: '@@linkToClient/GET_CLIENTS_ERROR',

  GET_REGIONS_REQUEST: '@@linkToClient/GET_REGIONS_REQUEST',
  GET_REGIONS_SUCCESS: '@@linkToClient/GET_REGIONS_SUCCESS',
  GET_REGIONS_ERROR: '@@linkToClient/GET_REGIONS_ERROR',

  TOGGLE_SUBMITTED: '@@linkToClient/TOGGLE_SUBMITTED',
};
