export const DEFAULT_SORT = 'created_at desc';

export const DEFAULT_PER_PAGE = 10;

export const DEFAULT_INCLUDES = {
  include_membership_client_user_full_name: null,
  include: {
    animation_kpis: {
      task_kpi: null,
    },
    place: {network: true},
    kit_mapping: null,
    kit_affectation: null,
    instore_task_access: {
      membership: {
        client_user: null,
      },
      kit_mappings: {kit: null},
    },
    animation_days: {anomaly_occurrences: {anomaly: null}},
    animation_data: {
      task_product: null,
    },
  },
};

export const SORT_MAP = {
  status: 'status',
  place: 'place_name',
  city: 'place_city_name',
  user: 'membership_client_user_full_name',
  from: 'animation_days_from',
  to: 'animation_days_to',
};
