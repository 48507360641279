import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {transformDate} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/components/common/icon';

import {mapSourceType} from 'client/components/client-autotask/tabs/campaigns/helpers';

import {CampaignCardHeaderProps} from './types';

import cssModule from './campaign-card-header.module.scss';

const b = bem('campaign-card-header', {cssModule});

const CampaignCardHeader: React.FC<CampaignCardHeaderProps> = (props) => {
  const {
    className = '',
    name = '',
    start = '',
    end = '',
    description = '',
    comment = '',
    status,
    source_type = '',
    periodText,
    isSource = false,
  } = props;
  const lang = useLanguage('SOURCE_TYPES');
  const desc = /{interface_name}/.test(description?.toString())
    ? description?.toString().replace('{interface_name}', name)
    : description;
  const caption = source_type || periodText;
  const period = !!start && !!end ? `${transformDate(start)} - ${transformDate(end)}` : null;

  return (
    <div className={cn(b({source: isSource}), className)}>
      <div className={b('caption', {source: isSource})}>
        {status && <Icon name={status} className={b('status-icon')} />}
        {name}
      </div>
      {period && (
        <div className={b('period')}>
          {caption && <span>{isSource ? mapSourceType(lang, caption) : caption}</span>}
          <span className={b('date')}>{period}</span>
        </div>
      )}
      {description && <div className={b('description')}>{desc}</div>}
      {comment && <div className={b('comment')}>{comment}</div>}
    </div>
  );
};

export default CampaignCardHeader;
