import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Popover from 'client/common/popovers/popover';

import './leads-comments-popover.scss';

function LeadsCommentsPopover({languageState, children, comment}) {
  let LANGUAGE = languageState.payload.LEADS_PORT;

  return (
    <Popover
      position="bottom"
      className="pull-left"
      overlayInnerStyle={{
        width: 344,
      }}
      overlay={
        <div className="popover-list popover-list--color-2">
          <h3 className="popover-list__title">{LANGUAGE.COMMENTS_POPOVER_TITLE}</h3>
          <ul className="popover-list__list leads-comments-popover">
            <li>
              <div className="main-text main-text--color-1">{comment}</div>
            </li>
          </ul>
        </div>
      }
    >
      {children}
    </Popover>
  );
}

LeadsCommentsPopover.defaultProps = {
  children: '',
};

LeadsCommentsPopover.propTypes = {
  languageState: PropTypes.object.isRequired,
  children: PropTypes.node,
  comment: PropTypes.string,
};

export default connect(({languageState}) => ({
  languageState,
}))(LeadsCommentsPopover);
