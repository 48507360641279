import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {set} from 'client/common/meta/meta.actions';

import LogsContainer from 'client/components/logs';

class LogsPage extends ReactQueryParams {
  componentDidMount() {
    this.props.setMetaAction({
      title: this.props.languageState.payload.LOGS.LOGS_TITLE,
    });
  }

  render() {
    return <LogsContainer />;
  }
}

LogsPage.propTypes = {
  setMetaAction: PropTypes.func.isRequired,
  languageState: PropTypes.object.isRequired,
};

export default connect(({languageState}) => ({languageState}), {
  setMetaAction: set,
})(LogsPage);
