import {Reducer} from 'redux';

import {WinningHeaderData} from 'client/components/winnings/various/winning-header/types';
import {Autotask} from 'client/models/autotask/types';
import {WinningSearchResult} from 'client/models/winnings/types';

import types from './types';

type State = {
  winningTasks: Autotask[];
  winningTask: Autotask | null;
  winningHeaderData: WinningHeaderData;
  searchResults: WinningSearchResult[];
};

const initialState: State = {
  winningTasks: [],
  winningTask: null,
  winningHeaderData: {},
  searchResults: [],
};

const reducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WINNING_TASKS_SUCCESS:
      return {
        ...state,
        winningTasks: action.payload.automation_tasks,
      };

    case types.GET_WINNING_TASK_SUCCESS:
      return {
        ...state,
        winningTask: action.payload.automation_task,
      };

    case types.SET_WINNING_HEADER_DATA:
      return {
        ...state,
        winningHeaderData: action.payload.data,
      };

    case types.SEARCH_WINNINGS_SUCCESS:
      return {
        ...state,
        searchResults: action.payload.result,
      };

    default:
      return state;
  }
};

export default reducer;
