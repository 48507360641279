import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Carousel from 'client/common/carousel';
import {ImageInput, ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';

import {TranslationJsx} from 'client/models/language/types';

import ImageSelect from '../image-select';

import './carousel-image-select.scss';

const b = bem('carousel-image-select');

const carouselDefaultProps = {
  ...Carousel.defaultProps,
  width: 'xxs',
  slidesPerView: 4,
};

const slideDefaultProps = {
  ...ImageInput.defaultProps,
};

const CarouselImageSelect = (props) => {
  const {
    className,
    label,
    images,
    name,
    value,
    disabled,
    onChange,
    errorMessage,
    warningMessage = '',
    onBlur,
    carouselProps,
    multiselect,
    required = false,
  } = props;
  const hasArrows = carouselProps.slidesPerView < images.length;

  const handleChange = ({id}) => {
    let newValue;
    if (multiselect) {
      newValue = value.includes(id) ? [...value.filter((item) => item !== id)] : [...value, id];
    } else {
      newValue = value.includes(id) ? [] : [id];
    }
    onChange(newValue);
    onBlur?.();
  };

  return (
    <div className={cn(b({error: !!errorMessage}), className)}>
      {label && (
        <label htmlFor={name} className={b('label')}>
          {label}
        </label>
      )}
      <Carousel
        {...carouselDefaultProps}
        {...carouselProps}
        className={b('carousel')}
        hasArrows={hasArrows}
        slides={images.map((item) => (
          <ImageSelect
            key={item.id}
            {...slideDefaultProps}
            disabled={disabled}
            checked={value.includes(item.id)}
            value={item}
            onChange={handleChange}
            classNamePreview={b('preview')}
          />
        ))}
      />
      {!errorMessage && required && <RequiredLabel />}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {warningMessage && !errorMessage && <WarningMessage warningMessage={warningMessage} />}
    </div>
  );
};

CarouselImageSelect.propTypes = {
  className: PropTypes.string,
  label: TranslationJsx,
  name: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      label: TranslationJsx,
    }),
  ),
  disabled: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
  warningMessage: TranslationJsx,
  onBlur: PropTypes.func,
  carouselProps: PropTypes.shape({
    className: PropTypes.string,
    spaceBetween: PropTypes.number,
    slidesPerView: PropTypes.number,
    centeredSlides: PropTypes.bool,
    scrollbar: PropTypes.bool,
    width: PropTypes.oneOf(Carousel.widthOptions),
  }),
  multiselect: PropTypes.bool,
  imageClassName: PropTypes.string,
  required: PropTypes.bool,
};

CarouselImageSelect.defaultProps = {
  className: null,
  label: null,
  value: [],
  images: [
    {
      id: 0,
      image: '',
      value: '',
    },
  ],
  errorMessage: null,
  onBlur: null,
  carouselProps: carouselDefaultProps,
  multiselect: false,
};

export default CarouselImageSelect;
