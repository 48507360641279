import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {isEmptyString} from 'client/services/validator';

import {LeadOptinValues, LeadOptinErrors} from './types';

export default (values: LeadOptinValues, lang: LangsByPath<['LEADS_PARAMETERS.LEAD_OPTIN_MODAL.ERRORS']>) => {
  const errors: LeadOptinErrors = {};

  if (isEmptyString(values.code)) {
    errors.code = lang.REQUIRED;
  }

  if (isEmptyString(values.name)) {
    errors.name = lang.REQUIRED;
  }

  if (!values.opt_in_type) {
    errors.opt_in_type = lang.REQUIRED;
  }

  if (isEmptyString(values.optin_texts.fr) && isEmptyString(values.optin_texts.en)) {
    errors.optin_texts = {[values.current_language]: lang.OPT_TEXT_REQUIRED};
  }

  if (isEmptyString(values.optout_texts.fr) && isEmptyString(values.optout_texts.en)) {
    errors.optout_texts = {[values.current_language]: lang.OPT_TEXT_REQUIRED};
  }

  return errors;
};
