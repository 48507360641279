import groupBy from 'lodash/groupBy';
import {createSelector} from 'reselect';

const selectAllIds = (state) => state.socialAccounts.allIds;
const selectById = (state) => state.socialAccounts.byId;
const selectAllTwitterIds = (state) => state.socialAccounts.allTwitterIds;
const selectByTwitterId = (state) => state.socialAccounts.byTwitterId;
const selectAllFacebookIds = (state) => state.socialAccounts.allFacebookIds;
const selectByFacebookId = (state) => state.socialAccounts.byFacebookId;
const reducer = (allIds, byId, names = false) =>
  allIds.reduce((result, id) => {
    const account = byId[id];
    return account ? [...result, names ? account.name : account] : result;
  }, []);
export const selectSocialAccounts = createSelector([selectAllIds, selectById], reducer);
export const selectTwitterAccounts = createSelector([selectAllTwitterIds, selectByTwitterId], reducer);
export const selectFacebookAccounts = createSelector([selectAllFacebookIds, selectByFacebookId], reducer);
export const selectSocialAccountsNames = createSelector([selectAllIds, selectById], (allIds, byId) =>
  reducer(allIds, byId, true),
);
export const selectSocialAccountsGroupedByType = createSelector(selectSocialAccounts, (socialAccounts) =>
  groupBy(socialAccounts, 'type'),
);

/**
 * If default pagination will be implemented in backend, it will be something like
 *   state => state.socialAccounts.meta.total_count;
 */
export const selectSocialAccountsCount = createSelector(selectSocialAccounts, (accounts) => accounts.length);
