const prefix = '@@diy-operation';

export default {
  GET_DIY_OPERATION_PRIZEBLOCK_REQUEST: `${prefix}/GET_PRIZEBLOCK_REQUEST`,
  GET_DIY_OPERATION_PRIZEBLOCK_SUCCESS: `${prefix}/GET_PRIZEBLOCK_SUCCESS`,
  GET_DIY_OPERATION_PRIZEBLOCK_ERROR: `${prefix}/GET_PRIZEBLOCK_ERROR`,

  GET_DIY_DEVICES_REQUEST: `${prefix}/GET_DIY_DEVICES_REQUEST`,
  GET_DIY_DEVICES_SUCCESS: `${prefix}/GET_DIY_DEVICES_SUCCESS`,
  GET_DIY_DEVICES_ERROR: `${prefix}/GET_DIY_DEVICES_ERROR`,

  CLEAR_DIY_OPERATION_BLOCKS: `${prefix}/CLEAR_DIY_OPERATION_BLOCKS`,

  CHANGE_PRIZE_ORDER: `${prefix}/CHANGE_PRIZE_ORDER`,

  UPDATE_GAME_REQUEST: `${prefix}/UPDATE_GAME_REQUEST`,
  UPDATE_GAME_SUCCESS: `${prefix}/UPDATE_GAME_SUCCESS`,
  UPDATE_GAME_ERROR: `${prefix}/UPDATE_GAME_ERROR`,
};
