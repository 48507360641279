import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {set} from 'client/common/meta/meta.actions';

import CatalogTemplates from 'client/components/catalog-templates';
import {CATALOG_TEMPLATE_TABS} from 'client/models/templates/constants';
import {CatalogTemplateTabType} from 'client/models/templates/types';

export const DEFAULT_SUB_TUB: CatalogTemplateTabType = CATALOG_TEMPLATE_TABS.MY_TEMPLATES;

const CatalogTemplatesPage = () => {
  const lang = useLanguage('CATALOG_TEMPLATES');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set({title: lang.TITLE}));
  }, [dispatch, lang.TITLE]);

  return <CatalogTemplates />;
};

export default CatalogTemplatesPage;
