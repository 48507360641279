import moment from 'moment';

export const addDay = (date, number) => {
  if (!date || number === 0) {
    return date;
  }

  const value = moment(date, 'DD/MM/YYYY');
  const newValue = number > 0 ? value.add(number, 'day') : value.subtract(Math.abs(number), 'day');

  return newValue.format('DD/MM/YYYY');
};

export const getMembershipsQueryParams = (clientId) => {
  return {
    include_membership_client_user_full_name: null,
    q: {
      client_id_eq: clientId,
      access_level_in: ['full', 'client_admin'],
      editor_eq: true,
      s: 'client_user_full_name asc',
    },
  };
};

export const getInitialDefaultLanguage = (commonLanguages, userLocale) => {
  if (commonLanguages.includes(userLocale)) {
    return userLocale;
  }
  if (commonLanguages.includes('fr')) {
    return 'fr';
  }
  return commonLanguages[0];
};
