import React from 'react';

import PropTypes from 'prop-types';

import CustomLink from 'client/components/common/custom-link';

function ParticipationsLastColumn(props) {
  const {date, name, clientType, clientId, operationId} = props;

  const link = `${CustomLink.createOperationLink({clientType, clientId, operationId})}`;

  return (
    <div>
      <div className="ellipsis-text">
        <CustomLink to={link} className="uppercase">
          {name}
        </CustomLink>
      </div>
      <div className="main-text main-text--small main-text--color-1">{date}</div>
    </div>
  );
}

ParticipationsLastColumn.propTypes = {
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  clientType: PropTypes.string.isRequired,
  clientId: PropTypes.number.isRequired,
  operationId: PropTypes.number.isRequired,
};

export default ParticipationsLastColumn;
