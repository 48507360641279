export default {
  GET_CATALOGS_REQUEST: '@@templates/GET_CATALOGS_REQUEST',
  GET_CATALOGS_SUCCESS: '@@templates/GET_CATALOGS_SUCCESS',
  GET_CATALOGS_ERROR: '@@templates/GET_CATALOGS_ERROR',

  GET_CLIENT_CATALOGS_REQUEST: '@@templates/GET_CLIENT_CATALOGS_REQUEST',
  GET_CLIENT_CATALOGS_SUCCESS: '@@templates/GET_CLIENT_CATALOGS_SUCCESS',
  GET_CLIENT_CATALOGS_ERROR: '@@templates/GET_CLIENT_CATALOGS_ERROR',

  GET_TEMPLATE_GROUPS_REQUEST: '@@templates/GET_TEMPLATE_GROUPS_REQUEST',
  GET_TEMPLATE_GROUPS_SUCCESS: '@@templates/GET_TEMPLATE_GROUPS_SUCCESS',
  GET_TEMPLATE_GROUPS_ERROR: '@@templates/GET_TEMPLATE_GROUPS_ERROR',

  SET_CURRENT_AGENCY_CLIENT_ID: '@@templates/SET_CURRENT_AGENCY_CLIENT_ID',
};
