export function mapLevelOptions(data = []) {
  const numbers = data.map((item) => item.number);
  const uniqueNumbers = [...new Set(numbers)];
  return uniqueNumbers.sort();
}

export const mapTimelineStatistic = (data) => {
  return {
    points: data.map(({time}) => time),
    visits: data.map(({visits_count}) => visits_count),
    participations: data.map(({participations_count}) => participations_count),
  };
};
