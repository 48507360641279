import React, {useCallback, useMemo, useState} from 'react';

import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {unlinkDevice} from 'client/ducks/devices/actions';
import {selectedClientGames} from 'client/ducks/games/selectors';
import {selectOperation} from 'client/ducks/operations/selectors';

import AppButton from 'client/common/buttons';
import {useToast} from 'client/common/hooks/useToast';

import ClientTable from 'client/components/common/client-table';
import {ClientTableColumn} from 'client/components/common/client-table/types';
import Icon from 'client/components/common/icon';

import LotteryPrizeCustomizationModal from 'client/components/lottery/modals/lottery-prize-customization-modal';
import PrizeMapSelectingButton from 'client/components/prizes/controls/prize-map-selecting-button';
import {hasPrizeMaps} from 'client/components/prizes/helpers';
import PrizeCustomizationModal from 'client/components/prizes/modals/prize-customization-modal';
import DatetimeCell from 'client/components/tables/common/cells/datetime-cell';
import {DeviceAffectation} from 'client/models/device-affectations';
import {Device} from 'client/models/devices/types';
import {INTERACTION_STATUSES} from 'client/models/interactions/constants';
import {GAME_TYPES} from 'client/models/prizes/constants';
import {GameType} from 'client/models/prizes/types';
import {ApiDispatch} from 'client/types';

import {getCurrentInteraction, getNearestInteraction, STATUS_ICONS} from './helpers';

import './devices-diy-list-table.scss';

const b = bem('devices-diy-list-table');

type DevicesDiyListTableProps = {
  loading: boolean;
  data: DeviceAffectation[];
  sortParams: {sortField?: string; sortOrder?: string};
  onParamsChange: () => void;
  checkedDevices: {id: number}[];
  checkedAllDevices: boolean;
  onCheckDevices: (state: boolean, deviceAffectation: DeviceAffectation) => void;
  onCheckAll: () => void;
  onUpdateTable: () => void;
  openEditDevice: (device: DeviceAffectation) => void;
  openLinkDevice: (device: DeviceAffectation) => void;
  fetchDeviceList: () => Promise<void>;
  disabled: boolean;
};

type DeviceToCustomizeState = {name: string; sourceIds: number[]; type: GameType};

const DevicesDiyListTable: React.FC<DevicesDiyListTableProps> = React.memo(
  ({
    data,
    loading,
    onParamsChange,
    sortParams,
    checkedDevices,
    onCheckDevices,
    openEditDevice,
    onUpdateTable,
    onCheckAll,
    checkedAllDevices,
    openLinkDevice,
    fetchDeviceList,
    disabled,
  }) => {
    const dispatch: ApiDispatch = useDispatch();
    const lang = useLanguage('CLIENT_DEVICES');
    const operation = useSelector(selectOperation);
    const {instantWinGame, lotteryGame} = useSelector(selectedClientGames);

    const currentClient = useSelector(selectCurrentClient);
    const [deviceToCustomize, setDeviceToCustomize] = useState<DeviceToCustomizeState | null>(null);
    const {appendToastNotification} = useToast();

    const handleUnlinkDevice = useCallback(
      async (deviceAffectation: DeviceAffectation) => {
        const response: any = await dispatch(
          unlinkDevice({
            deviceId: deviceAffectation.device_id,
            operationId: operation.id,
            deviceAffectationId: deviceAffectation.id,
          }),
        );
        if (response.error) {
          appendToastNotification({
            type: 'error',
            description: response.payload?.response?.errors?.base?.[0] || response.payload?.response?.exception,
          });
        } else {
          onUpdateTable();
        }
      },
      [onUpdateTable, dispatch, operation.id, appendToastNotification],
    );

    const hasStartedInteraction = useCallback(
      (device: Device) => {
        const int = getCurrentInteraction(device, operation);
        return int && int.current_status !== INTERACTION_STATUSES.NEW;
      },
      [operation],
    );

    const isLinked = useCallback(
      (row: Device) => !!getCurrentInteraction(row, operation) || !!getNearestInteraction(row, operation),
      [operation],
    );

    const disableCheck = useCallback((row: Device) => isLinked(row), [isLinked]);

    const cellClassName = useCallback(
      (row: DeviceAffectation) =>
        b('cell', {
          linked: isLinked(row.device!),
          available: !isLinked(row.device!) || !!getCurrentInteraction(row.device!, operation),
        }),
      [operation, isLinked],
    );

    const columns = useMemo(() => {
      const result: ClientTableColumn<DeviceAffectation>[] = [
        {
          name: 'name',
          label: lang.TABLE.DEVICES,
          path: 'device.name',
          sortable: true,
          ellipsisText: true,
          width: '130px',
        },
        {
          name: 'status_activity',
          label: lang.TABLE.STATUS,
          sortable: true,
          path: 'device.status_activity',
          width: '80px',
          render: ({item}) => {
            return (
              item.device &&
              STATUS_ICONS[item.device.status_activity] && (
                <Icon width={20} height={20} name={STATUS_ICONS[item.device.status_activity]} />
              )
            );
          },
        },
        {
          name: 'place_region_name',
          label: lang.TABLE.REGION,
          sortable: true,
          path: 'place.region_name',
          width: '115px',
          ellipsisText: true,
        },
        {
          name: 'place_city_name',
          label: lang.TABLE.CITY,
          path: 'place.city_name',
          width: '115px',
          ellipsisText: true,
        },
        {
          name: 'place_name',
          sortable: true,
          label: lang.TABLE.STORE,
          path: 'place.name',
          width: '115px',
          ellipsisText: true,
        },
        {
          name: 'operationImage',
          label: '',
          width: '100px',
          path: 'operationImage',
          cellClassName,
          render: ({item}) => {
            const interaction =
              getCurrentInteraction(item.device!, operation) || getNearestInteraction(item.device!, operation);
            const value = get(interaction, 'operation.operation_image.url', '');
            return value && <img alt="" className={b('operation-image')} src={value} />;
          },
        },
        {
          name: 'operationName',
          label: lang.TABLE.OPERATION_NAME,
          path: 'device.current_interaction.automation_task.operation.name',
          cellClassName,
          width: '215px',
          ellipsisText: true,
          render: ({item}) => {
            const interaction =
              getCurrentInteraction(item.device!, operation) || getNearestInteraction(item.device!, operation);
            return get(interaction, 'operation.name', '');
          },
        },
        {
          name: 'from',
          label: lang.TABLE.FROM,
          path: 'from',
          cellClassName,
          width: '115px',
          render: ({item}) => {
            const interaction =
              getCurrentInteraction(item.device!, operation) || getNearestInteraction(item.device!, operation);
            return (
              <DatetimeCell
                showBase={false}
                value={get(interaction, 'from')}
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm:SS"
              />
            );
          },
        },
        {
          name: 'to',
          label: lang.TABLE.TO,
          path: 'to',
          cellClassName,
          width: '115px',
          render: ({item}) => {
            const interaction =
              getCurrentInteraction(item.device!, operation) || getNearestInteraction(item.device!, operation);
            return (
              <DatetimeCell
                showBase={false}
                value={get(interaction, 'to')}
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm:SS"
              />
            );
          },
        },
      ];

      if (instantWinGame || lotteryGame) {
        result.push({
          name: 'prize',
          label: '',
          path: 'prize',
          cellClassName,
          width: '30px',
          render: ({item}) => {
            const relatedInteractions =
              item.device?.interactions?.filter((interaction) => interaction.operation?.id === operation.id) || [];
            const instantWinPrizeMaps = [...relatedInteractions].some(
              (interaction) => hasPrizeMaps({instantWinGame}, interaction.prize_maps_present).instantWinGame,
            );
            const lotteryPrizeMaps = [...relatedInteractions].some(
              (interaction) => hasPrizeMaps({lotteryGame}, interaction.prize_maps_present).lotteryGame,
            );

            const sourceIds = relatedInteractions?.map((i) => i.id);

            return (
              <PrizeMapSelectingButton
                budget={item.device?.prize_maps_modified?.includes('geo_prize_map')}
                exclude={{
                  prizeDrawGame: !lotteryPrizeMaps,
                  instantWinGame: !instantWinPrizeMaps,
                }}
                onSelect={(type) =>
                  item.device && setDeviceToCustomize({name: item.device?.name, sourceIds: sourceIds, type})
                }
              />
            );
          },
        });
      }

      result.push(
        ...([
          {
            name: 'edit',
            label: '',
            path: 'edit',
            cellClassName,
            width: '30px',
            render: ({item}) =>
              !!getCurrentInteraction(item.device!, operation) && (
                <AppButton
                  asWrap
                  label={<Icon name="edit" width={20} height={20} />}
                  className="client-table__button-edit"
                  onClick={() => openEditDevice(item)}
                />
              ),
          },
          {
            name: 'unlink',
            label: '',
            path: 'unlink',
            width: '50px',
            cellClassName,
            render: ({item}) =>
              item.client_id === currentClient.id &&
              !!getCurrentInteraction(item.device!, operation) &&
              !hasStartedInteraction(item.device!) &&
              !disabled && (
                <AppButton iconName="remove" color="error" onClick={() => handleUnlinkDevice(item)} transparent />
              ),
          },
        ] as ClientTableColumn<DeviceAffectation>[]),
      );
      return result;
    }, [
      lang,
      cellClassName,
      operation,
      instantWinGame,
      lotteryGame,
      openEditDevice,
      hasStartedInteraction,
      handleUnlinkDevice,
      currentClient.id,
      disabled,
    ]);

    const renderCheckbox = (item: DeviceAffectation) => {
      if (!isLinked(item.device!)) {
        return null;
      }

      return !getCurrentInteraction(item.device!, operation) && !!getNearestInteraction(item.device!, operation) ? (
        <AppButton
          asWrap
          label={<Icon name="calendar-edit" width={30} height={30} />}
          className={b('custom-link')}
          onClick={() => openLinkDevice(item)}
        />
      ) : (
        <></>
      );
    };

    return (
      <>
        <ClientTable
          columns={columns}
          data={data}
          checkable={!!data.length && !disabled}
          checkedAll={checkedAllDevices}
          onCheckAll={onCheckAll}
          disableCheck={disableCheck}
          checkedRows={checkedDevices}
          onCheck={onCheckDevices}
          loading={loading || !currentClient.id}
          onSortChange={onParamsChange}
          renderCheckbox={renderCheckbox}
          {...sortParams}
        />

        {deviceToCustomize?.type === GAME_TYPES.INSTANT_WIN && instantWinGame && (
          <PrizeCustomizationModal
            onClose={() => setDeviceToCustomize(null)}
            level="DEVICE"
            game={instantWinGame}
            levelName={deviceToCustomize?.name}
            sourceIds={deviceToCustomize?.sourceIds}
            onFetch={fetchDeviceList}
            disabled={disabled}
          />
        )}

        {deviceToCustomize?.type === GAME_TYPES.PRIZE_DRAW && lotteryGame && (
          <LotteryPrizeCustomizationModal
            onClose={() => setDeviceToCustomize(null)}
            level="DEVICE"
            game={lotteryGame}
            sourceIds={deviceToCustomize?.sourceIds}
            sourceName={deviceToCustomize.name}
            disabled={disabled}
          />
        )}
      </>
    );
  },
);

DevicesDiyListTable.displayName = 'DevicesDiyListTable';

export default DevicesDiyListTable;
