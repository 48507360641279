import React, {useMemo, useRef} from 'react';

import {useDispatch} from 'react-redux';
import {useMount, useToggle} from 'react-use';
import {Transforms} from 'slate';
import {ReactEditor, useSlate} from 'slate-react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {addEmailTemplateVariable} from 'client/ducks/email-templates/actions';

import Popover from 'client/common/popovers/popover';
import SelectDropdown from 'client/common/selects/select-dropdown';
import AlignButtons from 'client/common/text-editor/buttons/align-buttons';
import ColorButton from 'client/common/text-editor/buttons/color-button';
import HistoryButtons from 'client/common/text-editor/buttons/history-buttons';
import ListButtons from 'client/common/text-editor/buttons/list-buttons';
import StyleButtons from 'client/common/text-editor/buttons/style-buttons';
import VariableInput from 'client/common/text-editor/floating-toolbar/variable-input';
import {editorMethods} from 'client/common/text-editor/helpers';
import FontPropertiesSelect from 'client/common/text-editor/selects/font-properties-select';

import LinkInput from './link-input';

import ToolbarButton from '../buttons/toolbar-button';
import {TYPOGRAPHY_TYPES, TypographyOption, TYPOGRAPHY_TO_SIZE} from '../constants';

import cssModule from './floating-toolbar.module.scss';

const b = bem('floating-toolbar', {cssModule});

type FloatingToolbarProps = {
  colorsAccessKey: string;
  fontFamilyOptions?: string[];
};

const FloatingToolbar: React.FC<FloatingToolbarProps> = ({colorsAccessKey, fontFamilyOptions}) => {
  const editor = useSlate();
  const lang = useLanguage('COMMON.TEXT_EDITOR');
  const langLabels = lang.LABELS;
  const langTypography = lang.TYPOGRAPHY_OPTIONS;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isInversePosition, toggleIsInversePosition] = useToggle(false);
  const [isLinkInputOpen, toggleLinkInputOpen] = useToggle(false);
  const [isVariableInputOpen, toggleVariableInputOpen] = useToggle(false);
  const dispatch = useDispatch();

  useMount(() => {
    const wrapperElement = wrapperRef.current;
    if (wrapperElement) {
      const {right} = wrapperElement.getBoundingClientRect();
      if (right >= window.innerWidth) {
        toggleIsInversePosition(true);
      }
    }
  });

  const typographySelectOptions = useMemo(
    () => [
      {
        label: langTypography.PARAGRAPH,
        value: TYPOGRAPHY_TYPES.PARAGRAPH,
      },
      {
        label: <span className={b('heading', ['one'])}>{langTypography.HEADING_ONE}</span>,
        value: TYPOGRAPHY_TYPES.HEADING_ONE,
      },
      {
        label: <span className={b('heading', ['two'])}>{langTypography.HEADING_TWO}</span>,
        value: TYPOGRAPHY_TYPES.HEADING_TWO,
      },
      {
        label: <span className={b('heading', ['three'])}>{langTypography.HEADING_THREE}</span>,
        value: TYPOGRAPHY_TYPES.HEADING_THREE,
      },
    ],
    [langTypography],
  );

  const handleLinking = (linkUrl: string, linkText: string) => {
    Transforms.insertNodes(editor, {text: linkText, url: linkUrl});
    toggleLinkInputOpen(false);
    requestAnimationFrame(() => ReactEditor.focus(editor)); // focus editor back after the input process is finished
  };

  const addVariable = (variableName: string) => {
    Transforms.insertText(editor, `{{var:${variableName}}}`);
    dispatch(addEmailTemplateVariable(variableName));
    toggleVariableInputOpen(false);
    requestAnimationFrame(() => ReactEditor.focus(editor)); // focus editor back after the input process is finished
  };

  const chosenTypography = typographySelectOptions.find((option) => editorMethods.isBlockActive(editor, option.value));

  return (
    <div
      className={b({inverse: isInversePosition})}
      ref={wrapperRef}
      draggable={true}
      onDragStart={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      {isLinkInputOpen && <LinkInput onSubmit={handleLinking} />}
      {isVariableInputOpen && <VariableInput onSubmit={addVariable} />}
      <div className={b('main-content')}>
        <div className={b('action-buttons')}>
          <HistoryButtons editor={editor} />

          <div className={b('insert-buttons')}>
            <ToolbarButton iconName="pen" onClick={() => {}} label={langLabels.EDIT} disabled />
            <ToolbarButton
              iconName="link"
              onClick={() => {
                toggleLinkInputOpen();
                toggleVariableInputOpen(false);
              }}
              label={langLabels.INSERT_LINK}
              active={isLinkInputOpen}
            />
            <ToolbarButton
              iconName="variable"
              onClick={() => {
                toggleVariableInputOpen();
                toggleLinkInputOpen(false);
              }}
              label={langLabels.INSERT_VARIABLE}
              active={isVariableInputOpen}
            />
          </div>
        </div>
        <div className={b('format-buttons')}>
          <StyleButtons editor={editor} />
          <ColorButton editor={editor} colorsAccessKey={colorsAccessKey} />
          <AlignButtons editor={editor} className={b('align-buttons')} />
          <ListButtons editor={editor} />
        </div>

        <div className={b('text-options')}>
          <Popover
            overlay={langLabels.STYLES}
            position="top"
            contentClassName={b('overlay')}
            triggerClassName={b('popover-trigger')}
            hideOnMouseLeave
            disableFocus
          >
            <SelectDropdown
              options={typographySelectOptions}
              simpleValue
              onChange={(value: TypographyOption) => {
                editorMethods.toggleBlock(editor, value);
                editorMethods.applyProperty(editor, 'font-size', TYPOGRAPHY_TO_SIZE[value]);
                setTimeout(() => {
                  ReactEditor.focus(editor);
                }, 100);
              }}
              value={chosenTypography?.value || TYPOGRAPHY_TYPES.PARAGRAPH}
            />
          </Popover>
          <FontPropertiesSelect editor={editor} fontFamilyOptions={fontFamilyOptions} />
        </div>
      </div>
    </div>
  );
};

export default FloatingToolbar;
