import {useCallback, useEffect, useState} from 'react';

import {EmailTemplate} from 'client/models/email-templates/types';

const usePreloadTemplateImages = (template: EmailTemplate | null) => {
  const [preloadingImages, togglePreloadingImages] = useState(true);

  const preloadImages = useCallback(async () => {
    if (!template) {
      return;
    }
    if (template.email_template_images?.length) {
      const images = template?.email_template_images || [];
      for (const i of images) {
        try {
          await fetch(i.file_url);
        } catch {
          continue;
        }
      }
    }
    togglePreloadingImages(false);
  }, [template]);

  useEffect(() => {
    preloadImages();
  }, [preloadImages]);

  return preloadingImages;
};

export default usePreloadTemplateImages;
