import React, {Component} from 'react';

import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError, reset} from 'redux-form';

import {getStringFromArrayMap} from 'client/services/helpers';
import {required} from 'client/services/validator';

import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

class BCEditTaskModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    initialValues: PropTypes.object.isRequired,
    languageState: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    show: false,
  };

  static formName = 'BCEditTaskModalForm';

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.BROADCASTING_TASK.EDIT_TASK_MODAL;

    this.rules = {
      required: required(this.LANGUAGE.ERRORS.REQUIRED),
    };

    this.serverErrorsMap = {
      taken: this.LANGUAGE.ERRORS.NAME_IS_TAKEN,
    };
  }

  handleClose = () => {
    this.props.reset();
    this.props.onClose();
  };

  save = (data) => {
    const body = {
      message_task: {
        name: data.task_name,
      },
    };

    if (data.broadcast_id) {
      body.message_task.broadcast_list = {
        id: data.broadcast_id,
        name: data.broadcast_name,
      };
    }

    return this.props
      .onConfirm(body)
      .then((res) => {
        const {
          payload: {response},
        } = res;

        if (response && response.errors) {
          const task_name = getStringFromArrayMap(response.errors.name, this.serverErrorsMap);
          const broadcast_name =
            response.errors.broadcast_list &&
            getStringFromArrayMap(response.errors.broadcast_list[0].name, this.serverErrorsMap);

          throw new SubmissionError({
            task_name,
            broadcast_name,
          });
        }
      })
      .then(this.props.onClose);
  };

  render() {
    const {handleSubmit, show, initialValues} = this.props;
    const {LANGUAGE} = this;

    return (
      <Modal
        show={show}
        onClose={this.handleClose}
        title={LANGUAGE.TITLE}
        dialogClassName="modal-window--width-1 theme-color-16"
      >
        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          <div className="form-field-wrap">
            <Field
              label={LANGUAGE.TASK_NAME_LABEL + ' *'}
              name="task_name"
              type="text"
              component={fieldTemplate}
              validate={this.rules.required}
            />
          </div>
          {!!initialValues.broadcast_id && [
            <div key="0" className="form-field-wrap form-field-wrap--margin-6">
              <div className="main-text main-text--small main-text--color-1">{LANGUAGE.NOTE_TEXT}</div>
            </div>,
            <div key="1" className="form-field-wrap">
              <Field
                label={LANGUAGE.BROADCAST_NAME_LABEL + ' *'}
                name="broadcast_name"
                type="text"
                component={fieldTemplate}
                validate={this.rules.required}
              />
            </div>,
          ]}
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={this.handleClose}>
              {LANGUAGE.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-14 modal-window__footer-btn" type="submit">
              {LANGUAGE.CONFIRM_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  languageState: state.languageState,
  initialValues: ownProps.initialValues,
});

const mapDispatchToProps = {
  reset,
};

export default flow([
  reduxForm({
    form: BCEditTaskModal.formName,
    enableReinitialize: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
])(BCEditTaskModal);
