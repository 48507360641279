import React, {Component} from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError, reset, initialize, change} from 'redux-form';

import {getAuthHeaders} from 'client/services/helpers';

import {API_PATH, API_METHODS} from 'client/common/config';
// components
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

import './new-template-modal.scss';

let LANGUAGE = {};

class NewTemplateModal extends Component {
  static updateSyncErrors(values) {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'NewTemplateModalForm',
      },
      payload: {
        syncErrors: NewTemplateModal.validate(values),
      },
    };
  }

  static validate({name, id}) {
    const errors = {};

    if (!name || !name.trim()) {
      errors.name = LANGUAGE.NEW_TEMPLATE_MODAL && LANGUAGE.NEW_TEMPLATE_MODAL.NAME_IS_REQUIRED_ERROR;
    }
    if (!id || !id.trim()) {
      errors.id = LANGUAGE.NEW_TEMPLATE_MODAL && LANGUAGE.NEW_TEMPLATE_MODAL.ID_IS_REQUIRED_ERROR;
    }

    return errors;
  }

  constructor(props) {
    super(props);
    LANGUAGE = this.props.languageState.payload.OPERATION_PAGE;

    this.handleClose = this.handleClose.bind(this);
    this.save = this.save.bind(this);
    this.mapData = this.mapData.bind(this);
  }

  componentDidMount() {
    this.intervalID = setInterval(() => {
      if (!isEmpty(LANGUAGE)) {
        clearInterval(this.intervalID);
        this.props.updateErrors({});
      }
    }, 200);
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.props.initialize({});
      this.props.updateErrors({});
    }
  }

  handleClose() {
    this.props.onClose();
  }

  mapData(data) {
    return {
      operation_email_template: {
        mailjet_id: data.id,
        name: data.name,
        operation_id: this.props.operationId,
      },
    };
  }

  save(data) {
    return fetch(`${API_PATH}${API_METHODS.EMAIL_TEMPLATES}`, {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify(this.mapData(data)),
    })
      .then((res) => res.json())
      .then(({errors}) => {
        if (errors) {
          const submitErrors = {};
          if (errors.name) {
            submitErrors.name = LANGUAGE.NEW_TEMPLATE_MODAL.NAME_IS_TAKEN_ERROR;
          }
          if (errors.mailjet_id) {
            submitErrors.id = LANGUAGE.NEW_TEMPLATE_MODAL.ID_IS_TAKEN_ERROR;
          }
          throw new SubmissionError(submitErrors);
        }
        this.props.reset();
        this.props.onConfirm();
      });
  }

  render() {
    const {handleSubmit, submitting} = this.props;

    return (
      <Modal
        show={this.props.show}
        onClose={this.handleClose}
        title={LANGUAGE.NEW_TEMPLATE_MODAL.TITLE}
        dialogClassName="modal-window--width-1 new-template-modal"
      >
        <form
          // noValidate='noValidate'
          onSubmit={handleSubmit(this.save)}
        >
          <div className="form-field-wrap">
            <Field
              label={LANGUAGE.NEW_TEMPLATE_MODAL.NAME_LABEL}
              cssModifier="input--no-min-height"
              name="name"
              type="text"
              component={fieldTemplate}
            />
          </div>
          <div className="form-field-wrap">
            <Field
              label={LANGUAGE.NEW_TEMPLATE_MODAL.ID_LABEL}
              cssModifier="input--no-min-height"
              name="id"
              type="text"
              component={fieldTemplate}
            />
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button
              className="button button--bg-11 modal-window__footer-btn"
              type="button"
              onClick={this.props.onClose}
            >
              {LANGUAGE.CANCEL_BUTTON}
            </button>
            <button
              className="button button--bg-templates modal-window__footer-btn"
              type="submit"
              disabled={submitting}
            >
              {LANGUAGE.NEW_TEMPLATE_MODAL.CREATE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

NewTemplateModal.defaultProps = {
  show: false,
  onConfirm: () => {},
  onClose: () => {},
};

NewTemplateModal.propTypes = {
  operationId: PropTypes.number,
  show: PropTypes.bool,
  submitting: PropTypes.bool,
  onConfirm: PropTypes.func,
  handleSubmit: PropTypes.func,
  updateErrors: PropTypes.func,
  reset: PropTypes.func,
  initialize: PropTypes.func,
  onClose: PropTypes.func,
  changeFieldValue: PropTypes.func,
  languageState: PropTypes.object,
  formValues: PropTypes.object,
};

const newTemplateForm = reduxForm({
  form: 'NewTemplateModalForm',
  validate: NewTemplateModal.validate,
})(NewTemplateModal);

/**
 * @typedef {import('client/services/hooks/use-redux-form/types').ReduxFormFC} ReduxFormFC
 */

/**
 * @type ReduxFormFC<NewTemplateModal.propTypes>
 */
export default connect(
  ({languageState, form}) => ({
    languageState,
    formValues: form.NewTemplateModalForm,
  }),
  {
    reset,
    initialize,
    updateErrors: NewTemplateModal.updateSyncErrors,
    changeFieldValue: change,
  },
)(newTemplateForm);
