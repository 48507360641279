import React from 'react';

import PropTypes from 'prop-types';

import {formatNumberSpace} from 'client/services/formatters';

import PercentageBar from 'client/components/common/percentage-bar';

import './bars-list.scss';

const BarsList = (props) => {
  const {data} = props;

  return (
    <div className="bars-list">
      <div className="bars-list__names">
        {data.map(({name}, index) => (
          <div key={index} className="bars-list__name" title={name}>
            {name}
          </div>
        ))}
      </div>
      <div className="bars-list__values">
        {data.map(({value}, index) => (
          <div key={index} className="bars-list__value">
            {formatNumberSpace(value)}
          </div>
        ))}
      </div>
      <div className="bars-list__bars">
        {data.map(({percent}, index) => (
          <div key={index} className="bars-list__bar">
            <PercentageBar value={percent} />
          </div>
        ))}
      </div>
    </div>
  );
};

BarsList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default BarsList;
