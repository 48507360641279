import ReactQueryParams from 'react-query-params';

const getPreparedStringObject = (string) => {
  return string
    .split('')
    .map((el) => {
      if (el === '%') {
        return '%25';
      }

      return el;
    })
    .join('');
};

export default class CustomReactQueryParams extends ReactQueryParams {
  constructor(props) {
    super(props);

    this.defaultQueryParams = props.defaultQueryParams || {};
  }

  setQueryParams(paramsObject) {
    let preparedString = '';

    if (typeof paramsObject === 'object') {
      preparedString = getPreparedStringObject(JSON.stringify(paramsObject));
    }

    const preparedObject = preparedString ? JSON.parse(preparedString) : {};

    return super.setQueryParams(preparedObject);
  }

  render() {
    return this.props.children;
  }
}
