export const EXTERNAL_INSTORE_DASHBOARD_TYPES = {
  INTERNAL: 'Instore::InternalDashboard',
  WEEZIO: 'Instore::WeezioDashboard',
} as const;

export const INSTORE_DASHBOARD_TYPES = {
  USER: 'user',
  GLOBAL: 'global',
  WEEZIO: 'weezio',
} as const;

export const INSTORE_DASHBOARD_BLOCK_TYPES = {
  STATS_CONSOLIDATED: 'stats_consolidated',
  MONO_MCQ: 'mono_mcq',
} as const;

export const INSTORE_DASHBOARD_CONSOLIDATION_MODES = {
  TOTAL: 'total',
  AVERAGE: 'average',
} as const;

export const INSTORE_DASHBOARD_CONSOLIDATIONS = {
  REGION: 'region',
  SALES: 'field_sales',
  RETAILER: 'retailer',
  KIT: 'kit',
} as const;
