import React from 'react';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';

import {getPlacesAction} from 'client/ducks/places/actions';
import {selectPlacesForPlanMapView} from 'client/ducks/places/selectors';

import Modal from 'client/common/modals/modal';

import PlanMap from 'client/components/places/plan-map';
import {Place} from 'client/models/places/types';

import './at-plan-map-modal.scss';

type AtPlanMapModalProps = {
  readOnly?: boolean;
  onEditInteraction: () => void;
  planId: number;

  onClose: () => void;
  show: boolean;
  placeId: number;
};

const b = bem('at-plan-map-modal');

const AtPlanMapModal: React.FC<AtPlanMapModalProps> = (props) => {
  const {show, planId, onClose, onEditInteraction, placeId} = props;
  const lang = useLanguage('AUTOTASK_PLAN.MAP_MARKER_POPOVER');

  const {data: places = []} = useReduxFetch<Place[]>({
    action: getPlacesAction,
    selector: (state) => selectPlacesForPlanMapView(state, planId),
    actionArgs: {
      include: {
        offline_interactions: ['device', 'interface', 'place', 'location.region'],
      },
      q: {
        offline_interactions_interaction_group_id_eq: planId,
      },
      distinct: true,
    },
    skip: !show,
  });

  const filteredPlaces = placeId ? places.filter((i) => i.id === placeId) : places;

  return (
    <Modal
      dialogClassName={b()}
      contentClassName={b('content')}
      bodyClassName={b('body')}
      onClose={onClose}
      show={show}
    >
      <PlanMap
        readOnly
        onEditInteraction={onEditInteraction}
        data={filteredPlaces}
        labels={{
          markerPopoverTitle: lang.TITLE,
          markerPopoverInterfaces: lang.INTERFACES_LABEL,
        }}
      />
    </Modal>
  );
};

export default AtPlanMapModal;
