import {mapParticipants} from './mappers';
import types from './types';

const initialState = {
  winners: [],
  meta: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_PARTICIPATIONS_WINNERS_REQUEST:
      return {
        ...state,
        winners: [],
      };

    case types.GET_PARTICIPATIONS_WINNERS_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload.meta,
        },
        winners: mapParticipants(action.payload.winners),
      };

    case types.CLEAR_PARTICIPATIONS_WINNERS:
      return initialState;

    default:
      return state;
  }
}
