import React, {useState} from 'react';

import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {getLeadColumns} from 'client/ducks/leads-parameters/actions';
import {selectLeadColumns} from 'client/ducks/leads-parameters/selectors';

import AppButton from 'client/common/buttons/app-button';

import ClientTable from 'client/components/common/client-table';
import {ClientTableColumn} from 'client/components/common/client-table/types';

import LeadsParamsColumn from 'client/components/leads/leads-parameters/leads-params-column';
import LeadsCustomColumnModal from 'client/components/leads/leads-parameters/modals/leads-custom-column-modal';
import {CLIENT_TYPES} from 'client/models/client/constants';
import {Client} from 'client/models/client/types';
import {LEAD_COLUMN_KINDS} from 'client/models/lead/constants';
import {LeadColumn} from 'client/models/lead/types';

import cssModule from './leads-custom-columns.module.scss';

const b = bem('leads-custom-columns', {cssModule});

type LeadsCustomColumnsProps = {
  client: Client;
  className?: string;
};

const LeadsCustomColumns: React.FC<LeadsCustomColumnsProps> = (props) => {
  const {client, className} = props;
  const lang = useLanguage('LEADS_PARAMETERS.LEADS_CUSTOM_COLUMNS');
  const langTypes = useLanguage('LEADS_PARAMETERS.LEAD_COLUMN_TYPES');

  const currentClient = useSelector(selectCurrentClient);

  const [creationColumn, toggleCreationColumn] = useToggle(false);
  const [editedColumn, setEditedColumn] = useState<LeadColumn | null>(null);

  const {
    data = [],
    loading,
    fetch,
  } = useReduxFetch({
    action: getLeadColumns,
    selector: selectLeadColumns,
    actionArgs: client.id,
  });

  const typeMap = Object.entries(LEAD_COLUMN_KINDS).reduce((result: Record<string, any>, [key, value]) => {
    result[value] = langTypes[key as keyof typeof langTypes];
    return result;
  }, {});

  const canManage =
    currentClient.id === client.id
      ? currentClient.crm_management
      : currentClient.type === CLIENT_TYPES.AGENCY && currentClient.client_management;

  const handleClose = () => {
    toggleCreationColumn(false);
    setEditedColumn(null);
  };

  return (
    <LeadsParamsColumn
      className={className}
      label={lang.CUSTOM_COLUMNS}
      buttonTitle={lang.ADD_CUSTOM_COLUMN}
      onButtonClick={canManage ? toggleCreationColumn : null}
      count={loading ? 0 : data.length}
      loading={loading}
    >
      {(creationColumn || editedColumn) && (
        <LeadsCustomColumnModal onClose={handleClose} onSubmit={fetch} clientId={client.id} initial={editedColumn} />
      )}
      <ClientTable
        className={b('table')}
        data={data}
        loading={loading}
        classNames={{icon: b('empty')}}
        noDataText={lang.EMPTY_TEXT}
        columns={
          [
            {
              name: 'name',
              path: 'name',
              label: lang.COLUMN_NAME,
            },
            {
              name: 'kind',
              path: 'kind',
              label: lang.DATA_TYPE,
              cellClassName: b('type-cell'),
              formatter: (value) => typeMap[value] || value,
            },
            {
              name: 'actions',
              width: 60,
              show: canManage,
              render: ({item}) => (
                <AppButton
                  iconConfig={{
                    name: 'edit-alt',
                    className: b('edit-icon'),
                    title: lang.EDIT,
                  }}
                  onClick={() => setEditedColumn(item)}
                  asWrap
                />
              ),
            },
          ] as ClientTableColumn<LeadColumn>[]
        }
      />
    </LeadsParamsColumn>
  );
};

export default LeadsCustomColumns;
