import {mapApiLeadImports} from './mappers';
import types from './types';

let init = {
  type: types.NO_DATA,
  importReport: {},
  apiImportReport: {},
  importReports: [],
  importedLeads: {},
  apiLeadImports: [],
};

export default function (_state_ = init, action) {
  let state = Object.assign({}, _state_);
  switch (action.type) {
    case types.GET_LEADS_IMPORT_REPORT_SUCCESS:
      state.type = types.GET_LEADS_IMPORT_REPORT_SUCCESS;
      state.importReport = action.payload.lead_import_report;
      return state;

    case types.GET_LEADS_API_IMPORT_REPORT_SUCCESS:
      state.type = types.GET_LEADS_API_IMPORT_REPORT_SUCCESS;
      state.apiImportReport = action.payload.api_lead_import;
      return state;

    case types.GET_LEADS_IMPORT_REPORTS_SUCCESS:
      state.type = types.GET_LEADS_IMPORT_REPORT_SUCCESS;
      state.importReports = action.payload.lead_import_reports;
      return state;

    case types.GET_IMPORTED_LEADS_SUCCESS:
      state.type = types.GET_IMPORTED_LEADS_SUCCESS;
      state.importedLeads = action.payload;
      return state;

    case types.GET_API_LEAD_IMPORTS_SUCCESS:
      state.type = types.GET_API_LEAD_IMPORTS_SUCCESS;
      state.apiLeadImports = mapApiLeadImports(action.payload.api_lead_imports);
      return state;

    default:
      return state;
  }
}
