import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Icon from 'client/components/common/icon';

import GameConfigModal from 'client/components/games/game-config-modal';

import './prize-map-cell.scss';

const b = bem('prize-map-cell');

class PrizeMapCell extends PureComponent {
  static propTypes = {
    game: PropTypes.object.isRequired,
    prizeMapParams: PropTypes.object.isRequired,
    variant: PropTypes.oneOf(['campaigns', 'plans']).isRequired,
    levelKey: PropTypes.string.isRequired,
  };

  state = {
    showModal: false,
  };

  toggleModal = () => this.setState(({showModal}) => ({showModal: !showModal}));

  render() {
    const {game, prizeMapParams, variant, levelKey} = this.props;
    const {showModal} = this.state;

    return (
      <React.Fragment>
        <button
          type="button"
          className={b({
            campaigns: variant === 'campaigns',
            plans: variant === 'plans',
          })}
          onClick={this.toggleModal}
        >
          <Icon name="gear" />
        </button>
        {showModal && (
          <GameConfigModal onClose={this.toggleModal} game={game} prizeMapParams={prizeMapParams} levelKey={levelKey} />
        )}
      </React.Fragment>
    );
  }
}

export default PrizeMapCell;
