import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Modal from 'client/common/modals/modal';

import WinningPrizeContainer from 'client/components/winnings/containers/winning-prize-container';
import {WinningSearchType} from 'client/components/winnings/forms/winning-search-form/types';

import cssModule from './winning-prize-modal.module.scss';

const b = bem('winning-prize-modal', {cssModule});

type WinningPrizeModalProps = {
  onClose: () => void;
  participationPrizeId: number;
  searchParams: Record<string, string>;
  searchCriteria: WinningSearchType;
};

const WinningPrizeModal: React.FC<WinningPrizeModalProps> = (props) => {
  const {onClose, participationPrizeId, searchParams, searchCriteria} = props;

  const lang = useLanguage('WINNINGS.CARDS.PRIZE_CARD');

  return (
    <Modal onClose={onClose} className={b()} classNames={{body: b('body'), content: b('content')}} title={lang.TITLE}>
      <WinningPrizeContainer
        participationPrizeId={participationPrizeId}
        isDesktop
        searchParams={searchParams}
        searchCriteria={searchCriteria}
        classNames={{
          body: b('prize-card'),
        }}
      />
    </Modal>
  );
};

export default WinningPrizeModal;
