import PropTypes, {InferType} from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';
import {ReactNode} from 'client/types';

export const SelectOption = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  label: ReactNode.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  labelClassName: PropTypes.string,
});

export type SelectOptionType = InferType<typeof SelectOption>;

export const SelectCommonProps = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.node,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  selectClassName: PropTypes.string,
  prefixClassName: PropTypes.string,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  clearable: PropTypes.bool,
  plainly: PropTypes.bool,
  noResultsText: TranslationJsx,
  placeholder: TranslationJsx,
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
  warningMessage: TranslationJsx,
  size: PropTypes.oneOf(['small', 'medium', 'modal-min-height']),
  type: PropTypes.oneOf(['dropdown', 'sort-by', 'per-page', 'admin-per-page']),
  simpleValue: PropTypes.bool,
  classNames: PropTypes.object,
  styles: PropTypes.object,
  isLoading: PropTypes.bool,
};
