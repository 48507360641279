export default {
  NO_DATA: '@@lead-histories/NO_DATA',
  FETCH_LEAD_HISTORIES_REQUEST: '@@lead-histories/FETCH_LEAD_HISTORIES_REQUEST',
  FETCH_LEAD_HISTORIES_SUCCESS: '@@lead-histories/FETCH_LEAD_HISTORIES_SUCCESS',
  FETCH_LEAD_HISTORIES_ERROR: '@@lead-histories/FETCH_LEAD_HISTORIES_ERROR',

  FETCH_USER_IDS_LEAD_HISTORIES_REQUEST: '@@lead-histories/FETCH_USER_IDS_LEAD_HISTORIES_REQUEST',
  FETCH_USER_IDS_LEAD_HISTORIES_SUCCESS: '@@lead-histories/FETCH_USER_IDS_LEAD_HISTORIES_SUCCESS',
  FETCH_USER_IDS_LEAD_HISTORIES_ERROR: '@@lead-histories/FETCH_USER_IDS_LEAD_HISTORIES_ERROR',
};
