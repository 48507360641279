import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import {FilterButton} from 'client/components/tokens/modals/tokens-filter-button';
import {FilterDatepicker} from 'client/components/tokens/modals/tokens-filter-datepicker';
import {TranslationJsx} from 'client/models/language/types';

export const createGroupsConfig = (language) => ({
  visualGroupConfig: [
    {
      component: 'button',
      fieldName: 'visual',
      type: 'radio',
      value: null,
      label: language.DOESNT_MATTER,
      props: {},
    },
    {
      component: 'button',
      fieldName: 'visual',
      type: 'radio',
      label: language.NONE,
      value: 'none',
      props: {},
    },
    {
      component: 'button',
      fieldName: 'visual',
      type: 'radio',
      label: language.BARCODE,
      value: 'barcode',
      props: {},
    },
    {
      component: 'button',
      fieldName: 'visual',
      type: 'radio',
      label: language.QR_CODE,
      value: 'qr_code',
      props: {},
    },
  ],
  testModeGroupConfig: [
    {
      component: 'button',
      fieldName: 'testMode',
      type: 'radio',
      value: null,
      label: language.DOESNT_MATTER,
      props: {},
    },
    {
      component: 'button',
      fieldName: 'testMode',
      type: 'radio',
      label: language.ON,
      value: 'true',
      props: {},
    },
    {
      component: 'button',
      fieldName: 'testMode',
      type: 'radio',
      label: language.OFF,
      value: 'false',
      props: {},
    },
  ],
  leadsGroupConfig: [
    {
      component: 'button',
      fieldName: 'withLeads',
      type: 'radio',
      value: null,
      label: language.DOESNT_MATTER,
      props: {},
    },
    {
      component: 'button',
      fieldName: 'withLeads',
      type: 'radio',
      label: language.WITH_LEADS,
      value: 'true',
      props: {},
    },
    {
      component: 'button',
      fieldName: 'withLeads',
      type: 'radio',
      label: language.WITHOUT_LEADS,
      value: 'false',
      props: {},
    },
  ],
  createdGroupConfig: [
    {
      component: 'button',
      groupName: 'createdAt',
      fieldName: 'createdAt',
      type: 'radio',
      value: null,
      label: language.DOESNT_MATTER,
      props: {},
    },
    {
      component: 'button',
      groupName: 'createdAt',
      fieldName: 'createdAt',
      type: 'radio',
      value: 'true',
      label: language.TIME_PERIOD,
      props: {},
    },
    {
      component: 'datepicker',
      groupName: 'createdAt',
      fieldName: 'createdAtFrom',
      props: {
        name: 'createdAtFrom',
        label: language.FROM,
      },
    },
    {
      component: 'datepicker',
      groupName: 'createdAt',
      fieldName: 'createdAtTo',
      props: {
        name: 'createdAtTo',
        label: language.TO,
      },
    },
  ],
  generatedGroupConfig: [
    {
      component: 'button',
      groupName: 'generatedAt',
      fieldName: 'generatedAt',
      type: 'radio',
      value: null,
      label: language.DOESNT_MATTER,
      props: {},
    },
    {
      component: 'button',
      groupName: 'generatedAt',
      fieldName: 'generatedAt',
      type: 'radio',
      value: 'true',
      label: language.TIME_PERIOD,
      props: {},
    },
    {
      component: 'datepicker',
      groupName: 'generatedAt',
      fieldName: 'generatedAtFrom',
      props: {
        name: 'generatedAtFrom',
        label: language.FROM,
      },
    },
    {
      component: 'datepicker',
      groupName: 'generatedAt',
      fieldName: 'generatedAtTo',
      props: {
        name: 'generatedAtTo',
        label: language.TO,
      },
    },
  ],
});

export const GroupComponent = ({component, ...props}) => {
  switch (component) {
    case 'button':
      return <FilterButton {...props} />;

    case 'datepicker':
      return <FilterDatepicker {...props} />;
    default:
      return null;
  }
};

GroupComponent.propTypes = {
  component: PropTypes.string.isRequired,
};

export const FilterGroup = ({config, label, ...propsItems}) => {
  const key = (fieldName, value) => {
    return `${fieldName}_${value || 'none-option'}`;
  };
  const groupName = config[0].groupName;

  return (
    <div className="form-field-wrap">
      <div
        className={cn(
          'tokens-filter-modal__group-title',
          `tokens-filter-modal__group-title--${groupName}`,
          'text-additional',
        )}
      >
        {label}
      </div>
      <div className="tokens-filter-modal__group">
        {config.map((filterElement) => (
          <GroupComponent key={key(filterElement.fieldName, filterElement.value)} {...filterElement} {...propsItems} />
        ))}
      </div>
    </div>
  );
};

FilterGroup.propTypes = {
  config: PropTypes.array.isRequired,
  label: TranslationJsx.isRequired,
};
