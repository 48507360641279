import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect, withRouter} from 'react-router-dom';

import {getIsClientAdmin, getIsNational} from 'client/services/cookie-data-source';

import {selectCurrentOrganization} from 'client/ducks/user-clients/selectors';
import {selectIsAdmin, selectIsUserLoaded} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import Agency from '../components/client-agency/agency';

class AgencyContainer extends Component {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    ...withRouter.propTypes,
    agency: PropTypes.shape({
      agency: PropTypes.object,
    }),
    currentOrganization: PropTypes.object.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  componentDidUpdate(prevProps) {
    const oldName = prevProps.agency.agency && prevProps.agency.agency.name;
    const newName = this.props.agency.agency && this.props.agency.agency.name;
    if (newName !== oldName) {
      this.props.setMetaAction({
        title: newName,
      });
    }
  }

  render() {
    const {
      isAdmin,
      isUserLoaded,
      currentOrganization,
      match: {
        params: {id},
      },
    } = this.props;

    const orgId = +id;

    if (!isUserLoaded) {
      return null;
    }

    if (
      !isAdmin &&
      !getIsClientAdmin() &&
      (!getIsNational() || (currentOrganization.id && currentOrganization.id !== orgId))
    ) {
      return <Redirect to={CLIENT_PAGES.HOME} />;
    }

    return <Agency id={orgId} history={this.props.history} />;
  }
}

const mapStateToProps = (state) => ({
  agency: state.agency,
  isAdmin: selectIsAdmin(state),
  isUserLoaded: selectIsUserLoaded(state),
  currentOrganization: selectCurrentOrganization(state),
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyContainer);
