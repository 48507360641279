import React from 'react';

import moment from 'moment';

import {useLanguage} from 'client/services/hooks';

import {AFFECTATION_STEPS, DeviceAffectation} from 'client/models/device-affectations';

import Road from '../road';

type ReturnRoadProps = {
  affectation: DeviceAffectation;
};

const ReturnRoad: React.FC<ReturnRoadProps> = ({affectation}) => {
  const lang = useLanguage('CLIENT_DEVICES.LOGISTIC');

  return (
    <Road
      endIcon="weezio-transparent-color"
      disabledEndIcon="weezio-transparent-monochrome"
      disabledStartIcon="weezio-home"
      startIcon="weezio-home-transparent"
      currentStep={affectation.return_step}
      currentStatus={affectation.return_status}
      steps={[
        {
          title: lang.PREPARATION_STATUS,
          value: AFFECTATION_STEPS.PREPARATION,
        },
        {
          title: lang.SENT_STATUS,
          value: AFFECTATION_STEPS.SENT,
          subStep: {
            title: affectation.return_timestamp ? moment(affectation.return_timestamp).format('DD/MM/YY') : '',
          },
        },
        {
          title: lang.RECEIVED_STATUS,
          value: AFFECTATION_STEPS.RECEIVED,
        },
        {
          title: lang.VERIFIED_STATUS,
          value: AFFECTATION_STEPS.VERIFIED,
        },
      ]}
    />
  );
};

export default ReturnRoad;
