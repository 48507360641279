import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import useTaskWrapper from 'client/services/hooks/use-task-wrapper';

import AnimationAvailableFieldset from 'client/components/animations/fieldsets/animation-available-fieldset';
import AnimationPlanningFieldset from 'client/components/animations/fieldsets/animation-planning-fieldset';

import './animation-config-mobile-container-available-days.scss';

const b = bem('animation-config-mobile-container-available-days');

const AnimationConfigMobileContainerAvailableDays = (props) => {
  const {readOnly} = props;
  useTaskWrapper(false);

  return (
    <div className={b()}>
      <AnimationAvailableFieldset readOnly={readOnly} />

      <div className={b('planning-activation')}>
        <AnimationPlanningFieldset readOnly={readOnly} showHeader={false} />
      </div>
    </div>
  );
};

AnimationConfigMobileContainerAvailableDays.propTypes = {
  readOnly: PropTypes.bool.isRequired,
};

export default AnimationConfigMobileContainerAvailableDays;
