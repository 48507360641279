import React from 'react';

import {useLanguage} from 'client/services/hooks';

import {SelectField} from 'client/common/fields';

import {COMPONENT_TYPES} from '../../../constants';

type CalculationTypeFieldProps = {
  number: number;
  className?: string;
};

const CalculationTypeField: React.FC<CalculationTypeFieldProps> = ({number, className, ...props}) => {
  const lang = useLanguage('AUTOTASK_SCENARIO.SCENARIO_FORMS');

  return (
    <SelectField
      className={className}
      name={`type${number}`}
      label={`${lang.TYPE} ${number}`}
      options={[
        {value: COMPONENT_TYPES.FIX, label: lang.FIX},
        {value: COMPONENT_TYPES.VAR, label: lang.VAR},
      ]}
      withWrap={false}
      {...props}
    />
  );
};

export default CalculationTypeField;
