import {RSAA} from 'redux-api-middleware';

import {API_PATH, API_METHODS} from 'client/common/config';

export const COMPANIES_NO_DATA = 'COMPANIES_NO_DATA';
export const COMPANIES_LOADING = 'COMPANIES_LOADING';
export const COMPANIES_LOADED = 'COMPANIES_LOADED';
export const COMPANIES_LOAD_FAILED = 'COMPANIES_LOAD_FAILED';

export function getCompanies() {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.COMPANIES}`,
        method: 'GET',
        isAuth: true,
        types: [COMPANIES_LOADING, COMPANIES_LOADED, COMPANIES_LOAD_FAILED],
      },
    });
  };
}
