import React from 'react';

import {useSelector} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {selectAllScenarioVariables} from 'client/ducks/scenario/selectors';

import {SelectField} from 'client/common/fields';

type CalculationVariableFieldProps = {
  number: number;
  className?: string;
};

const CalculationVariableField: React.FC<CalculationVariableFieldProps> = ({number, className, ...props}) => {
  const lang = useLanguage('AUTOTASK_SCENARIO.SCENARIO_FORMS');
  const variables = useSelector(selectAllScenarioVariables);

  return (
    <SelectField
      className={className}
      name={`component_dropdown${number}`}
      label={`${lang.COMPONENT} ${number}`}
      options={variables.map((scenarioVariable: {id: number; full_name?: string; name?: string}) => ({
        value: scenarioVariable.id,
        label: scenarioVariable?.full_name || scenarioVariable?.name,
      }))}
      withWrap={false}
      {...props}
    />
  );
};

export default CalculationVariableField;
