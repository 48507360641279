// components
import React, {Component} from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';

import './phones-emails-card-data-grid.scss';

let LANGUAGE = {};

function NameCell({data, rowIndex, field, ...props}) {
  return (
    <Cell {...props}>
      <div className="cell-wrapper">
        <div className="ellipsis-text ellipsis-cell">{data[rowIndex][field]}</div>
        <div className="ellipsis-text ellipsis-cell">{data[rowIndex].value}</div>
      </div>
    </Cell>
  );
}

NameCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

function TypeCell({data, rowIndex, field, ...props}) {
  return (
    <Cell {...props}>
      <div className="ellipsis-text ellipsis-cell">{LANGUAGE.TYPES[data[rowIndex][field]]}</div>
    </Cell>
  );
}

TypeCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

class PhonesEmailsCardDataGrid extends Component {
  constructor(props) {
    super(props);

    LANGUAGE = props.languageState.payload.AGENCY.PHONES_EMAILS_CARD;
  }

  render() {
    return (
      <div
        style={{paddingTop: '6px'}}
        className="fixed-table fixed-table--expand-1 fixed-table--phones-emails-card theme-color-8 ph-em-card-dg"
      >
        <Table
          rowsCount={this.props.data.length}
          headerHeight={40}
          rowHeight={65}
          width={230}
          height={309}
          {...this.props}
        >
          <Column
            header={<Cell>{LANGUAGE.NAME_COLUMN}</Cell>}
            cell={<NameCell data={this.props.data} field="name" />}
            width={62}
          />
          <Column
            header={<Cell>{LANGUAGE.TYPE_COLUMN}</Cell>}
            cell={<TypeCell data={this.props.data} field="type" />}
            width={45}
            flexGrow={1}
          />
        </Table>
      </div>
    );
  }
}

PhonesEmailsCardDataGrid.propTypes = {
  data: PropTypes.array,
  languageState: PropTypes.object,
};

PhonesEmailsCardDataGrid.defaultProps = {
  data: [],
};

export default PhonesEmailsCardDataGrid;
