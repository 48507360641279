import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {dateToString, timeToString} from 'client/services/helpers';

import cssModule from './datetime-cell.module.scss';

const b = bem('datetime-cell', {cssModule});

type DatetimeCellProps = {
  value?: Date | string;
  oneLine?: boolean;
  fullWidth?: boolean;
  showBase?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  additionalClassName?: string;
};

const DatetimeCell: React.FC<DatetimeCellProps> = (props) => {
  const {value, oneLine, fullWidth, dateFormat, timeFormat, showBase = true, additionalClassName = ''} = props;

  if ((!value || value === ' ') && showBase) {
    return null;
  }

  return (
    <div
      className={cn(b({'one-line': oneLine, 'full-width': fullWidth}), additionalClassName)}
      title={dateToString(value, `${dateFormat || 'DD/MM/YYYY'} ${timeFormat || 'HH:mm:ss'}`)}
    >
      <div className={b('date')}>{dateToString(value, dateFormat)}</div>
      <div className={b('time')}>{timeToString(value, timeFormat)}</div>
    </div>
  );
};

export default DatetimeCell;
