import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';

import {Interaction} from 'client/models/interactions/types';

import cssModule from './client-device-content-cell.module.scss';

type ClientDeviceContentCellProps = {
  onClick?: () => void;
  interaction?: Interaction;
  isMedia?: boolean;
};

const b = bem('client-device-content-cell', {cssModule});

const ClientDeviceContentCell: React.FC<ClientDeviceContentCellProps> = ({onClick, interaction, isMedia}) => {
  const lang = useLanguage('CLIENT_DEVICES.TABLE');

  const operation = interaction?.operation && (
    <>
      <figure className={b('figure')}>
        <img src={interaction.operation.operation_image?.url} className={b('image')} alt="" />
      </figure>
      <div className={b('content')}>{interaction.operation.name}</div>
    </>
  );

  const media = (
    <>
      <Icon name="media_player" />
      <div className={b('content')}>{lang.MEDIA_PLAYER}</div>
    </>
  );

  return (
    <div className={b()} tabIndex={-1} role={onClick ? 'button' : 'none'} onClick={onClick}>
      {isMedia ? media : operation}
    </div>
  );
};

export default ClientDeviceContentCell;
