import types from './types';

const initialState = {
  slideshows: [],
  slideshow: {},
};

export default function (_state = initialState, action) {
  const state = Object.assign({}, _state);

  switch (action.type) {
    case types.GET_SLIDESHOWS_SUCCESS:
      state.slideshows = action.payload.diaporamas;
      return state;

    case types.GET_SLIDESHOW_SUCCESS:
      state.slideshow = action.payload.diaporama;
      return state;

    default:
      return state;
  }
}
