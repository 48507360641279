import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getValueIfExists} from 'client/services/helpers';

import 'client/components/test-parameters/components/test-step/test-step.scss';

class BranchStep extends Component {
  static propTypes = {
    step: PropTypes.object,
    variableValues: PropTypes.object,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    step: {},
    variableValues: {},
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.RESULT_MODAL;
  }

  render() {
    const {LANGUAGE} = this;
    const {step, variableValues} = this.props;
    const {implementation} = step;

    return (
      <div>
        <div className="test-step__form-block">
          <div className="test-step__form-title">
            {LANGUAGE.BRANCH_CONDITION.replace('{{CONDITION_TYPE}}', LANGUAGE.CONDITION_TYPES[implementation.operator])}
          </div>
          {implementation.branch_scenario_step_conditions.map((condition, key) => {
            let variableName = getValueIfExists(condition, ['variable', 'name']);
            let variableValue = condition.value;

            if (condition.value_kind === 'variable') {
              variableName = getValueIfExists(condition, ['value_variable', 'name']);
              variableValue = variableValues[condition.value_variable_id];
            }

            return (
              <div className="test-step__form-row" key={key}>
                <div className="fake-input fake-input--disabled">
                  <div className="fake-input__label">{variableName}</div>
                  <div className="fake-input__field">
                    {LANGUAGE.COMPARISON_OPERATORS[condition.operator]} {`${variableValue}`}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="test-step__form-block">
          <div className="test-step__form-title">
            {/* no scenario prop in implementation */}
            {LANGUAGE.THEN_LABEL}{' '}
            {implementation.scenario_id === null || !implementation.scenario
              ? LANGUAGE.STOP_CURRENT_SCENARIO
              : `${LANGUAGE.GO_TO_SCENARIO} ${implementation.scenario && implementation.scenario.name}`}
            .
          </div>
        </div>
        <div className="test-step__form-block">
          <div className="test-step__form-title">{LANGUAGE.ELSE_CONTINUE_CURRENT_SCENARIO}</div>
        </div>
        <div className="test-step__form-block">
          <div className="fake-input fake-input--disabled">
            <div className="fake-input__label">{LANGUAGE.COMMENT_LABEL}</div>
            <div className="fake-input__textarea">{step.comment}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(BranchStep);
