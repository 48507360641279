import {last} from 'lodash';
import moment from 'moment';

import {uid} from 'client/services/helpers';

import {DateTimeInputTypeOptionType, TIME_PATTERNS} from 'client/common/inputs/datetime-input';

import {NEW_TIMESTAMP_PREFIX} from './constants';
import {DateTimeValueType} from './index';

export const addEmptyValueSafely = (values: DateTimeValueType[], maxLength: number) => {
  if (values.length === 0 || (values.length < maxLength && last(values)?.value !== '')) {
    return [...values, {value: '', id: uid(NEW_TIMESTAMP_PREFIX)}];
  }

  return [...values]; // return a link to a new array
};

export const filterEmptyValues = (values: DateTimeValueType[]) => values.filter(({value}) => Boolean(value));

const validate = (value: string, pattern: RegExp | string) => {
  if (typeof pattern === 'object' && 'test' in pattern) {
    return pattern.test(value);
  }

  return moment(value, pattern, true).isValid();
};

export const validateInput = (
  value: string,
  isPeriod: boolean,
  type: DateTimeInputTypeOptionType,
  validationPattern: RegExp | string = TIME_PATTERNS[type],
) => {
  if (type === 'date-time') {
    return validate(value, validationPattern);
  }

  const inputValues = value.split(' ');
  if (isPeriod && inputValues.length < 2) {
    return false;
  }

  const isValid = inputValues.every((inputValue) => validate(inputValue, validationPattern));

  return isValid;
};
