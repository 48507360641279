import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getManualVisualGroups(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MANUAL_VISUAL_GROUPS}`,
      method: 'GET',
      types: [types.GET_MANUAL_GROUPS_REQUEST, types.GET_MANUAL_GROUPS_SUCCESS, types.GET_MANUAL_GROUPS_ERROR],
      noCache: true,
      queryParams,
    });
}

export function addManualVisualGroup(body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MANUAL_VISUAL_GROUPS}`,
      method: 'POST',
      types: [types.ADD_MANUAL_GROUP_REQUEST, types.ADD_MANUAL_GROUP_SUCCESS, types.ADD_MANUAL_GROUP_ERROR],
      body,
    });
}

export function addManualVisualGroupItem(body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MANUAL_VISUAL_GROUP_ITEMS}`,
      method: 'POST',
      types: [types.ADD_MANUAL_GROUP_REQUEST, types.ADD_MANUAL_GROUP_SUCCESS, types.ADD_MANUAL_GROUP_ERROR],
      body,
    });
}

export function getVisualGroup(id, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.VISUAL_GROUPS}/${id}`,
      method: 'GET',
      types: [types.GET_VISUAL_GROUP_REQUEST, types.GET_VISUAL_GROUP_SUCCESS, types.GET_VISUAL_GROUP_ERROR],
      noCache: true,
      queryParams,
    });
}
