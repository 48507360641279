import React from 'react';

import PropTypes from 'prop-types';

import FilterBar from '../filter-bar';
import Icon from '../icon';

import './base-toolbar.scss';

function BaseToolbar({
  searchField,
  resultsNum,
  actionBarChildren,
  textLabels,
  onShowFilterClick,
  onClearFiltersClick,
  className,
  searchBarClass,
  searchClass,
  filterBtnClass,
  actionBarClass,
  filters,
  onFiltersClick,
  clearFiltersBtnClass,
  withoutFilters,
  withoutSearch,
}) {
  return (
    <div className={`base-toolbar ${className}`}>
      {!withoutSearch && (
        <div className={`base-toolbar__search-bar ${searchBarClass}`}>
          {React.cloneElement(searchField, {
            cssModifier: `base-toolbar__search-input ${searchClass}`,
          })}
          {!withoutFilters && (
            <button className={`button base-toolbar__btn ${filterBtnClass}`} onClick={onShowFilterClick}>
              <Icon name="filter" className="button__icon" />
              <span>{textLabels.filtersButton}</span>
            </button>
          )}
          <span className="base-toolbar__search-result-num">
            <span className="main-text main-text--bold">{resultsNum}</span>
            <span className="main-text">{` ${textLabels.resultsNum}`}</span>
          </span>
          {!!filters.filter((i) => i.applied).length && (
            <div className="base-toolbar__filter-labels">
              <FilterBar
                buttonClassName={clearFiltersBtnClass}
                filters={filters}
                onClick={onFiltersClick}
                onClearFiltersClick={onClearFiltersClick}
                buttonText={textLabels.clearFilters}
              />
            </div>
          )}
        </div>
      )}
      <div className={`base-toolbar__action-bar ${actionBarClass}`}>{actionBarChildren}</div>
    </div>
  );
}

BaseToolbar.defaultProps = {
  filters: [],
  onFiltersClick: () => {},
  resultsNum: '',
  searchField: null,
  textLabels: {},
  actionBarChildren: null,
  filtersChildren: null,
  onShowFilterClick: () => {},
  onClearFiltersClick: () => {},
  className: '',
  searchBarClass: '',
  searchClass: '',
  filterBtnClass: '',
  actionBarClass: '',
  clearFiltersBtnClass: 'button--bg-8',
  withoutSearch: false,
};

BaseToolbar.propTypes = {
  filters: PropTypes.array,
  onFiltersClick: PropTypes.func,
  resultsNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  searchField: PropTypes.element,
  actionBarChildren: PropTypes.node,
  filtersChildren: PropTypes.any,
  textLabels: PropTypes.object,
  onShowFilterClick: PropTypes.func,
  onClearFiltersClick: PropTypes.func,
  className: PropTypes.string,
  searchBarClass: PropTypes.string,
  searchClass: PropTypes.string,
  filterBtnClass: PropTypes.string,
  actionBarClass: PropTypes.string,
  clearFiltersBtnClass: PropTypes.string,
  withoutFilters: PropTypes.bool,
  withoutSearch: PropTypes.bool,
};

export default BaseToolbar;
