import React, {KeyboardEvent, useCallback, useState} from 'react';

import cn from 'classnames';
import {useMount} from 'react-use';
import {Descendant, createEditor} from 'slate';
import {withHistory} from 'slate-history';
import {Slate, withReact, Editable} from 'slate-react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';
import {initializeColorSwatches} from 'client/common/text-editor/buttons/color-button/helpers';

import {CustomEditor, CustomElement, CustomText} from './constants';
import Element from './element';
import {deserialize, serialize} from './helpers';
import Leaf from './leaf';
import StaticVariableBar from './static-variable-bar';
import {TextEditorProps} from './types';

import cssModule from './text-editor.module.scss';

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const b = bem('text-editor', {cssModule});

const TextEditor: React.FC<TextEditorProps> = (props) => {
  const {
    isToolbarVisible,
    value = '',
    onToggleEditMode,
    onChange,
    commonColorsAccessKey,
    children: toolbar,
    hasPlaceholder,
    errorMessage,
    warningMessage = '',
    editableRef,
    isLinesLimited,
    editableStyle,
    leafStyle,
    withFocus = true,
    readOnly,
    editableClassName,
    disabled = false,
    required = false,
    variables,
  } = props;
  const [editor] = useState(() => withReact(withHistory(createEditor())));

  const lang = useLanguage('COMMON.TEXT_EDITOR');

  const document = new DOMParser().parseFromString(value || '<p></p>', 'text/html');

  // Run through body's child nodes and wrap them in paragraph tags if needed to avoid content losses
  for (const node of document.body.childNodes) {
    if (node.nodeName.toLowerCase() === 'p') {
      continue;
    }

    const wrapperParagraph = document.createElement('p');
    wrapperParagraph.appendChild(node.cloneNode(true));
    node.replaceWith(wrapperParagraph);
  }

  const renderElement = useCallback((elementProps) => <Element {...elementProps} />, []);
  const renderLeaf = useCallback((leafProps) => <Leaf {...leafProps} style={leafStyle} />, [leafStyle]);

  useMount(() => {
    if (value) {
      initializeColorSwatches(value, commonColorsAccessKey);
    }
  });

  const handleEditableClick = () => {
    if (!isToolbarVisible) {
      onToggleEditMode?.(true);
    }
  };

  const handleEditableKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Tab') {
      e.preventDefault();
    } else if (e.key === 'Escape') {
      onToggleEditMode?.(false);
      editor.deselect();
    }
  };

  const handleChange = (content: Descendant[]) => {
    const serializedValue = serialize({children: content} as CustomElement);
    onChange(serializedValue === '<p></p>' ? '' : serializedValue);
  };

  return (
    <div className={b()}>
      <Slate editor={editor} initialValue={deserialize(document.body) as Descendant[]} onChange={handleChange}>
        {variables && <StaticVariableBar variables={variables} />}
        {isToolbarVisible && toolbar}
        <div ref={editableRef} style={editableStyle}>
          <Editable
            className={cn(
              b('editable', {
                'non-selectable': !isToolbarVisible,
                'is-limited': isLinesLimited,
                'with-focus': withFocus,
                error: !!errorMessage,
                warning: !!warningMessage,
                disabled,
              }),
              editableClassName,
            )}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={hasPlaceholder ? lang.PLACEHOLDER?.toString() : ''}
            renderPlaceholder={({children, attributes}) => (
              <span {...attributes} className={b('placeholder')}>
                {children}
              </span>
            )}
            tabIndex={-1}
            onClick={handleEditableClick}
            onKeyDown={handleEditableKeyDown}
            autoFocus={false}
            onFocus={() => true} // if onFocus is not handled(checked by return value), slate performs the actions that blurs the empty input. This is a hack to prevent that. Should be fixed with update of slate and react>17
            readOnly={readOnly || disabled}
          />
        </div>
        {!errorMessage && required && <RequiredLabel />}
        {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        {warningMessage && !errorMessage && <WarningMessage warningMessage={warningMessage} />}
      </Slate>
    </div>
  );
};

export default TextEditor;
