import {required, alphaNumeric} from 'client/services/validator';

export default (values, props) => {
  const lang = props.lang.ERRORS;

  return {
    name: required(lang.NAME_IS_REQUIRED)(values.name) || alphaNumeric(lang.NAME_NOT_ALPHANUMERIC)(values.name),
    kind: required(lang.TYPE_IS_REQUIRED)(values.kind),
    client_id: required(lang.CLIENT_IS_REQUIRED)(values.client_id),
  };
};
