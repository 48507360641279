import React, {useState, useCallback} from 'react';

import {useDispatch} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {changeAutotaskValidated} from 'client/ducks/autotask/actions';
import {
  createWebAppInterface,
  updateWebAppInterface,
  deleteWebAppInterface,
  createDeviceInterface,
  updateDeviceInterface,
} from 'client/ducks/interfaces/actions';

import AppButton from 'client/common/buttons';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import AutotaskManagementCardHeaderControls from 'client/components/autotask/components/controls/autotask-management-card-controls';
import {
  DIY_CONFIRMATION_TYPES,
  DIY_OPERATION_STATES,
  DIY_OPERATION_CONFIRMATION_MODAL_TYPE,
} from 'client/components/diy-operation/constants';
import {getModalsConfiguration} from 'client/components/diy-operation/helpers';
import {Autotask} from 'client/models/autotask/types';
import {ApiDispatch} from 'client/types';

import cssModule from './autotask-management-card-header.module.scss';

const b = bem('autotask-management-card-header', {cssModule});

type AutotaskManagementCardHeaderProps = {
  autotask: Autotask;
  onUpdate: () => void;
  isDevice?: boolean;
  isWebApp?: boolean;
  disabled: boolean;
};

const AutotaskManagementCardHeader: React.FC<AutotaskManagementCardHeaderProps> = (props) => {
  const {autotask, onUpdate, isDevice, isWebApp, disabled} = props;
  const {operation} = autotask;
  const interfaceId = operation?.client_interface?.id;

  const deviceState = operation?.client_interface?.device_state || '';
  const webAppState = operation?.client_interface?.web_app?.state || '';

  const isAssisted = operation?.client?.auto_configuration; // Assisted = true
  const isValidated = autotask?.validated;

  const lang = useLanguage('AUTOTASK.COMPONENTS.CARDS.AUTOTASK_MANAGEMENT_CARD.AUTOTASK_MANAGEMENT_CARD_HEADER');

  const dispatch: ApiDispatch = useDispatch();

  const [confirmationModal, setConfirmationModal] = useState<{modal: string; type: string}>({modal: '', type: ''});
  const [loading, toggleLoading] = useToggle(false);

  const modalsConfiguration = getModalsConfiguration(lang, confirmationModal.type, loading);

  const handleConfirm = useCallback(async () => {
    toggleLoading();

    if (confirmationModal.modal === DIY_CONFIRMATION_TYPES.VALIDATED) {
      await dispatch(changeAutotaskValidated(autotask?.id, false));
    }

    if (confirmationModal.modal === DIY_CONFIRMATION_TYPES.PUBLISH) {
      if (confirmationModal.type === DIY_OPERATION_CONFIRMATION_MODAL_TYPE.WEB_APP) {
        await dispatch(createWebAppInterface(interfaceId));
      }

      if (confirmationModal.type === DIY_OPERATION_CONFIRMATION_MODAL_TYPE.DEVICE) {
        await dispatch(createDeviceInterface(interfaceId, DIY_OPERATION_STATES.PUBLISHED));
      }
    }

    if (confirmationModal.modal === DIY_CONFIRMATION_TYPES.UPDATE) {
      if (confirmationModal.type === DIY_OPERATION_CONFIRMATION_MODAL_TYPE.WEB_APP) {
        await dispatch(updateWebAppInterface(interfaceId));
      }

      if (confirmationModal.type === DIY_OPERATION_CONFIRMATION_MODAL_TYPE.DEVICE) {
        await dispatch(updateDeviceInterface(interfaceId));
      }
    }

    if (confirmationModal.modal === DIY_CONFIRMATION_TYPES.UNPUBLISH) {
      if (confirmationModal.type === DIY_OPERATION_CONFIRMATION_MODAL_TYPE.WEB_APP) {
        await dispatch(deleteWebAppInterface(interfaceId));
      }

      if (confirmationModal.type === DIY_OPERATION_CONFIRMATION_MODAL_TYPE.DEVICE) {
        await dispatch(createDeviceInterface(interfaceId, DIY_OPERATION_STATES.UNPUBLISHED));
      }
    }

    onUpdate();
    toggleLoading();
    setConfirmationModal({modal: '', type: ''});
  }, [autotask?.id, confirmationModal, dispatch, interfaceId, onUpdate, toggleLoading]);

  const openConfirmationModal = (modal: string, type: string) => () => setConfirmationModal({modal, type});
  const closeConfirmationModal = () => setConfirmationModal({modal: '', type: ''});

  const isUnPublish = useCallback((state: string) => {
    return ['published', 'republished'].includes(state);
  }, []);

  return (
    <div className={b()}>
      {isAssisted && (
        <div className={b('controls')}>
          <span className={b('subtitle')}>
            {`${lang.STATUS}: ${autotask?.validated ? lang.VALIDATED : lang.UNVALIDATED}`}
          </span>
          <AppButton
            disabled={!isValidated}
            onClick={openConfirmationModal(
              DIY_CONFIRMATION_TYPES.VALIDATED,
              autotask?.validated
                ? DIY_OPERATION_CONFIRMATION_MODAL_TYPE.INVALIDATED
                : DIY_OPERATION_CONFIRMATION_MODAL_TYPE.VALIDATED,
            )}
            label={lang.INVALIDATE}
            color="text-additional"
            fontSize="extra-small"
          />
        </div>
      )}
      {isDevice && (
        <AutotaskManagementCardHeaderControls
          title={lang.DEVICE}
          publishedAt={operation?.client_interface?.device_synchronized_at}
          isUnPublish={isUnPublish(deviceState)}
          isValidated={isValidated}
          type={DIY_OPERATION_CONFIRMATION_MODAL_TYPE.DEVICE}
          openConfirmationModal={openConfirmationModal}
          disabled={disabled}
        />
      )}
      {isWebApp && (
        <AutotaskManagementCardHeaderControls
          title={lang.ONLINE}
          publishedAt={operation?.client_interface?.web_app_synchronized_at}
          isUnPublish={isUnPublish(webAppState)}
          isValidated={isValidated}
          type={DIY_OPERATION_CONFIRMATION_MODAL_TYPE.WEB_APP}
          openConfirmationModal={openConfirmationModal}
          disabled={disabled}
        />
      )}

      <ConfirmationModal
        title={modalsConfiguration[confirmationModal.modal]?.title}
        show={!!confirmationModal.modal}
        onClose={closeConfirmationModal}
        onConfirm={handleConfirm}
        message={modalsConfiguration[confirmationModal.modal]?.message}
        messageClassName={b('modal-message')}
        buttonConfirm={modalsConfiguration[confirmationModal.modal]?.buttonConfirm}
        buttonCancel={modalsConfiguration[confirmationModal.modal]?.buttonCancel}
        clientSide={true}
      />
    </div>
  );
};

export default AutotaskManagementCardHeader;
