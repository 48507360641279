export default {
  GET_SOCIAL_ACCOUNTS_REQUEST: '@@social-accounts/GET_SOCIAL_ACCOUNTS_REQUEST',
  GET_SOCIAL_ACCOUNTS_SUCCESS: '@@social-accounts/GET_SOCIAL_ACCOUNTS_SUCCESS',
  GET_SOCIAL_ACCOUNTS_ERROR: '@@social-accounts/GET_SOCIAL_ACCOUNTS_ERROR',

  GET_FACEBOOK_ACCOUNTS_REQUEST: '@@social-accounts/GET_FACEBOOK_ACCOUNTS_REQUEST',
  GET_FACEBOOK_ACCOUNTS_SUCCESS: '@@social-accounts/GET_FACEBOOK_ACCOUNTS_SUCCESS',
  GET_FACEBOOK_ACCOUNTS_ERROR: '@@social-accounts/GET_FACEBOOK_ACCOUNTS_ERROR',

  GET_TWITTER_ACCOUNTS_REQUEST: '@@social-accounts/GET_TWITTER_ACCOUNTS_REQUEST',
  GET_TWITTER_ACCOUNTS_SUCCESS: '@@social-accounts/GET_TWITTER_ACCOUNTS_SUCCESS',
  GET_TWITTER_ACCOUNTS_ERROR: '@@social-accounts/GET_TWITTER_ACCOUNTS_ERROR',

  PATCH_SOCIAL_ACCOUNTS_BULK_REQUEST: '@@social-accounts/PATCH_SOCIAL_ACCOUNTS_BULK_REQUEST',
  PATCH_SOCIAL_ACCOUNTS_BULK_SUCCESS: '@@social-accounts/PATCH_SOCIAL_ACCOUNTS_BULK_SUCCESS',
  PATCH_SOCIAL_ACCOUNTS_BULK_ERROR: '@@social-accounts/PATCH_SOCIAL_ACCOUNTS_BULK_ERROR',

  PATCH_SOCIAL_ACCOUNT_REQUEST: '@@social-accounts/PATCH_SOCIAL_ACCOUNT_REQUEST',
  PATCH_SOCIAL_ACCOUNT_SUCCESS: '@@social-accounts/PATCH_SOCIAL_ACCOUNT_SUCCESS',
  PATCH_SOCIAL_ACCOUNT_ERROR: '@@social-accounts/PATCH_SOCIAL_ACCOUNT_ERROR',

  REMOVE_SOCIAL_ACCOUNT_REQUEST: '@@social-accounts/REMOVE_SOCIAL_ACCOUNT_REQUEST',
  REMOVE_SOCIAL_ACCOUNT_SUCCESS: '@@social-accounts/REMOVE_SOCIAL_ACCOUNT_SUCCESS',
  REMOVE_SOCIAL_ACCOUNT_ERROR: '@@social-accounts/REMOVE_SOCIAL_ACCOUNT_ERROR',
};
