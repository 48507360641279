import {MainStates} from 'client/types';

export const selectCatalogs = (state: MainStates) => {
  return state.catalogs.catalogs;
};

export const selectClientCatalogs = (state: MainStates) => {
  return state.catalogs.clientCatalogs;
};

export const selectCurrentAgencyClientId = (state: MainStates) => {
  return state.catalogs.currentAgencyClientId;
};
