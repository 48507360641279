import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import DonutChart from 'client/components/common/charts/donut-chart';

import {TranslationJsx} from 'client/models/language/types';

const mapData = (data, lang) => {
  const {male_count, female_count, unknown_count} = data;
  let unknownData = [];

  if (!unknown_count && !male_count && !female_count) {
    return null;
  }

  if (unknown_count !== 0) {
    unknownData = [
      {
        label: lang.UNKNOWN,
        value: unknown_count,
        color: '#DCE1EA',
      },
    ];
  }

  return [
    {
      label: lang.MALE,
      value: male_count,
      color: '#FE9191',
    },
    {
      label: lang.FEMALE,
      value: female_count,
      color: '#98EA91',
    },
    ...unknownData,
  ];
};

const GenderDonutChart = (props) => {
  const {lang, title, data, legendOnRight} = props;

  return <DonutChart title={title} data={mapData(data, lang)} legendOnRight={legendOnRight} />;
};

GenderDonutChart.propTypes = {
  lang: PropTypes.object.isRequired,
  title: TranslationJsx,
  data: PropTypes.shape({
    male_count: PropTypes.number.isRequired,
    female_count: PropTypes.number.isRequired,
    unknown_count: PropTypes.number.isRequired,
  }).isRequired,
  legendOnRight: PropTypes.bool,
};

GenderDonutChart.defaultProps = {
  title: null,
  legendOnRight: false,
};

export default connect((state) => ({
  lang: state.languageState.payload.GENDER_DONUT_CHART,
}))(GenderDonutChart);
