import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {CLIENT_PAGES, CLIENT_TYPES, LEAD_TRANSFER_TYPES, CLIENT_LEVEL_TYPES} from 'client/common/config';

import {TranslationJsx} from 'client/models/language/types';
import {TASK_TYPES} from 'client/models/operations/constants';

class CustomLink extends Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    title: TranslationJsx,
  };

  static defaultProps = {
    children: '',
    className: '',
    title: '',
  };

  static createClientLink(data) {
    const {clientType, clientId} = data;

    switch (clientType) {
      case CLIENT_TYPES.AGENCY:
        return `${CLIENT_PAGES.AGENCIES}/${clientId}`;
      case CLIENT_TYPES.COMPANY:
        return `${CLIENT_PAGES.COMPANIES}/${clientId}`;
      default:
        return '';
    }
  }

  static createOperationLink(data) {
    const {clientId, clientType, operationId} = data;

    const clientPage = CustomLink.createClientLink({clientId, clientType});
    return `${clientPage}${CLIENT_PAGES.OPERATIONS}/${operationId}`;
  }

  static createCustomOperationDBLink(data) {
    const operationPage = CustomLink.createOperationLink(data);
    return `${operationPage}${CLIENT_PAGES.CUSTOM_OPERATION_DATABASE}`;
  }

  static createTaskLink(data) {
    const {taskType, clientType, operationId, clientId, taskId} = data;

    const clientPage = CustomLink.createClientLink({clientId, clientType});

    switch (taskType) {
      case TASK_TYPES.AUTOMATION:
        return `${clientPage}${CLIENT_PAGES.OPERATIONS}/${operationId}` + `${CLIENT_PAGES.AUTOTASK}/${taskId}`;

      case TASK_TYPES.SMS:
      case TASK_TYPES.EMAIL:
      case TASK_TYPES.MESSAGE:
        return `${clientPage}${CLIENT_PAGES.OPERATIONS}/${operationId}` + `${CLIENT_PAGES.MESSAGE_TASK}/${taskId}`;

      default:
        return '';
    }
  }

  static createHomeDashboardLink({levelType, levelId}) {
    switch (levelType) {
      case TASK_TYPES.SMS:
      case TASK_TYPES.EMAIL:
        return `${CLIENT_PAGES.DASHBOARDS_HOME}?levelType=${TASK_TYPES.MESSAGE}&levelId=${levelId}`;
      default:
        return `${CLIENT_PAGES.DASHBOARDS_HOME}?levelType=${levelType}&levelId=${levelId}`;
    }
  }

  static createDashboardsLink(data) {
    const {clientType, clientId, operationId, taskType, taskId, dashboardType, dashboardId} = data;

    switch (dashboardType) {
      case CLIENT_LEVEL_TYPES.AUTOMATION_TASK:
      case CLIENT_LEVEL_TYPES.MESSAGE_TASK:
      case CLIENT_LEVEL_TYPES.POST_TASK:
        const taskLink = CustomLink.createTaskLink({
          taskType,
          taskId,
          clientId,
          clientType,
          operationId,
        });

        return `${taskLink}${CLIENT_PAGES.DASHBOARDS}/${dashboardId}`;

      case CLIENT_LEVEL_TYPES.OPERATION:
        const operationLink = CustomLink.createOperationLink({
          clientId,
          clientType,
          operationId,
        });

        return `${operationLink}${CLIENT_PAGES.DASHBOARDS}/${dashboardId}`;

      case CLIENT_LEVEL_TYPES.CLIENT:
        const clientLink = CustomLink.createClientLink({
          clientType,
          clientId,
        });

        return `${clientLink}${CLIENT_PAGES.DASHBOARDS}/${dashboardId}`;

      default:
        return '';
    }
  }

  static createLeadTransferLink(data) {
    const {transferType, transferId} = data;

    switch (transferType) {
      case LEAD_TRANSFER_TYPES.EXPORT:
      case LEAD_TRANSFER_TYPES.API_EXPORT:
        return `${CLIENT_PAGES.LEADS_EXPORT}/${transferId}`;
      case LEAD_TRANSFER_TYPES.IMPORT:
      case LEAD_TRANSFER_TYPES.API_IMPORT:
        return `${CLIENT_PAGES.LEADS_IMPORT}/${transferId}`;
      default:
        return '';
    }
  }

  static createVisualsLink(clientId) {
    if (clientId) {
      return `${CLIENT_PAGES.COMPANIES}/${clientId}${CLIENT_PAGES.VISUALS}`;
    }

    return CLIENT_PAGES.VISUALS;
  }

  static createDashboardLink(data) {
    const {clientType, clientId, dashboardId} = data;

    const clientPage = CustomLink.createClientLink({clientType, clientId});

    return `${clientPage}${CLIENT_PAGES.DASHBOARDS}/${dashboardId}`;
  }

  static createMailingListLink(data) {
    const {taskType, clientType, operationId, clientId, taskId, broadcastId} = data;

    const taskLink = CustomLink.createTaskLink({
      taskType,
      clientType,
      operationId,
      clientId,
      taskId,
    });

    return `${taskLink}${CLIENT_PAGES.MAILING_LIST}/${broadcastId}`;
  }

  static createMailingListTasksLink(data) {
    const {clientType, clientId, broadcastId} = data;

    const clientLink = CustomLink.createClientLink({
      clientType,
      clientId,
    });

    return `${clientLink}${CLIENT_PAGES.MAILING_LIST}/${broadcastId}${CLIENT_PAGES.MESSAGE_TASKS}`;
  }

  static createOperationTemplateLink({templateId, ...data}) {
    const operationPage = CustomLink.createOperationLink(data);

    return `${operationPage}${CLIENT_PAGES.TEMPLATES}/${templateId}`;
  }

  static createMessageTaskResultsLink(data) {
    const {taskType, clientType, operationId, clientId, taskId} = data;

    const taskLink = CustomLink.createTaskLink({
      taskType,
      clientType,
      operationId,
      clientId,
      taskId,
    });

    return `${taskLink}${CLIENT_PAGES.MESSAGE_TASK_RESULTS}`;
  }

  static createVisualsByConfigLink(data) {
    const {companyId, configId} = data;

    return `${CLIENT_PAGES.COMPANIES}/${companyId}${CLIENT_PAGES.VISUALS}?configId=${configId}`;
  }

  static createTaskParticipationsLink(clientId, operationId, taskId) {
    return (
      CLIENT_PAGES.COMPANIES +
      `/${clientId}` +
      CLIENT_PAGES.OPERATIONS +
      `/${operationId}` +
      CLIENT_PAGES.AUTOTASK +
      `/${taskId}` +
      CLIENT_PAGES.PARTICIPANTS_LIST
    );
  }

  static createClientOperationsLink(data) {
    const {clientId} = data;

    return [CLIENT_PAGES.CLIENTS, `/${clientId}`, CLIENT_PAGES.OPERATIONS].join('');
  }

  static createClientAutotaskTabLink(data) {
    const {operationId, autotaskId, tab} = data;

    return [
      CustomLink.createClientOperationsLink(data),
      `/${operationId}`,
      CLIENT_PAGES.AUTOTASK,
      `/${autotaskId}`,
      tab,
    ].join('');
  }

  static createClientInstoreTaskTabLink(data) {
    const {operationId, taskId, tab} = data;

    return [
      CustomLink.createClientOperationsLink(data),
      `/${operationId}`,
      CLIENT_PAGES.INSTORE_TASKS,
      `/${taskId}`,
      tab,
    ].join('');
  }

  render() {
    const {children, to, className, title} = this.props;

    return (
      <Link className={className} to={to} title={title}>
        {children}
      </Link>
    );
  }
}

export default CustomLink;
