import moment from 'moment';

export default (values, {lang}) => {
  const errors = {};

  if (!values?.from) {
    errors.from = lang.REQUIRED;
  }

  if (!values?.to) {
    errors.to = lang.REQUIRED;
  }

  if (values?.from && values?.to && !moment(values.to).isAfter(values.from)) {
    errors.to = lang.AFTER_FROM_ERROR;
    errors.from = lang.BEFORE_TO_ERROR;
  }

  return errors;
};
