import React, {useState} from 'react';

import {DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy} from '@dnd-kit/sortable';
import {sortBy} from 'lodash';
import PropTypes from 'prop-types';
import {useDeepCompareEffect} from 'react-use';

import bem from 'client/services/bem';
import useUserAccessLevel from 'client/services/hooks/useUserAccessLevel';

import Spinner from 'client/components/common/spinner';

import InstoreDashboardBlockCard from 'client/components/instore-dashboards/cards/instore-dashboard-block-card';
import useDashboardType from 'client/components/instore-dashboards/hooks/useDashboardType';
import useFetchDashboardBlocks from 'client/components/instore/containers/instore-dashboards-container/hooks/use-fetch-dashboard-blocks';

import './instore-dashboard-blocks-container.scss';

const b = bem('instore-dashboard-blocks-container');

const InstoreDashboardBlocksContainer = (props) => {
  const {dashboard, campaignId} = props;
  const {blocks, loading, updateBlocksOrders, removeBlock} = useFetchDashboardBlocks({
    dashboardId: dashboard.id,
    campaignId,
  });
  const [sortedBlocks, setSortedBlocks] = useState(blocks);
  const {isUser, isGlobal} = useDashboardType(dashboard);
  const {isAdminOrNational} = useUserAccessLevel();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useDeepCompareEffect(() => {
    setSortedBlocks(sortBy(blocks, 'position'));
  }, [blocks]);

  const handleDragEnd = (event) => {
    const {active, over} = event;

    if (active.id !== over.id) {
      setSortedBlocks((items) => {
        const oldIndex = items.findIndex((block) => block.id === active.id);
        const newIndex = items.findIndex((block) => block.id === over.id);

        const next = arrayMove(items, oldIndex, newIndex);
        updateBlocksOrders(next);
        return next;
      });
    }
  };

  return (
    <div className={b()}>
      {loading ? (
        <Spinner color="primary" centered={true} className={b('loader')} />
      ) : (
        <>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={sortedBlocks} strategy={rectSortingStrategy}>
              {sortedBlocks.map((block, index) => (
                <InstoreDashboardBlockCard
                  key={block.id}
                  index={index}
                  block={block}
                  onRemove={removeBlock}
                  dashboard={dashboard}
                  position={index + 1}
                />
              ))}
            </SortableContext>
          </DndContext>
          {(isUser || (isGlobal && isAdminOrNational)) && <InstoreDashboardBlockCard />}
        </>
      )}
    </div>
  );
};

InstoreDashboardBlocksContainer.displayName = 'InstoreDashboardBlocksContainer';

InstoreDashboardBlocksContainer.propTypes = {
  dashboard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    user_id: PropTypes.number,
  }).isRequired,
  campaignId: PropTypes.number.isRequired,
};

export default InstoreDashboardBlocksContainer;
