import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {dateToString} from 'client/services/helpers';

import AppButton from 'client/common/buttons/app-button';
import Icon from 'client/common/icon';

import {Autotask} from 'client/models/autotask/types';

import cssModule from './winning-task-card.module.scss';

const b = bem('winning-task-card', {cssModule});

type WinningTaskCardProps = {
  className?: string;
  task: Autotask;
  onClick?: () => void;
};

const WinningTaskCard: React.FC<WinningTaskCardProps> = (props) => {
  const {className, task, onClick} = props;

  const from = task.interactions?.map((i) => i.from)?.sort()?.[0];
  const to = task.interactions
    ?.map((i) => i.to)
    ?.sort()
    ?.reverse()?.[0];

  const content = (
    <div className={b('content', {'alt-bg': !onClick})}>
      {task?.image?.url ? <img className={b('image')} src={task?.image?.url} /> : <Icon name="prize-placeholder" />}
      <div>
        <p className={b('name')}>{task.name}</p>
        <p className={b('period')}>
          {dateToString(from)} - {dateToString(to)}
        </p>
      </div>
    </div>
  );

  return onClick ? (
    <AppButton className={cn(b(), className)} onClick={onClick} label={content} asWrap />
  ) : (
    <div className={cn(b(), className)}>{content}</div>
  );
};

export default WinningTaskCard;
