import React from 'react';

import cn from 'classnames';
import {components} from 'react-select';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';

import cssModule from './grouped-group.module.scss';

const b = bem('grouped-group', {cssModule});

const GroupedGroup = (props) => {
  const {
    label,
    data: {labelClassName},
  } = props;
  const [open, toggleOpen] = useToggle(true);

  return (
    <div className={b()}>
      <div className={b('summary')} onClick={toggleOpen}>
        <span className={cn(b('label'), labelClassName)}>{label}</span>
        <Icon className={b('arrow')} name={open ? 'arrow-up' : 'arrow-down'} />
      </div>
      {open && <components.Group {...props}>{props.children}</components.Group>}
    </div>
  );
};

GroupedGroup.propTypes = {
  ...components.Group.propTypes,
};

export default GroupedGroup;
