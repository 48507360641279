import React from 'react';

import PropTypes from 'prop-types';
import {FieldArray} from 'redux-form';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import {TextField} from 'client/components/common/fields';

import './prize-form-substitution.scss';

const formatOrder = (value) => `${value}`.replace(/\D/g, '');

const PrizeFormSubstitutionItem = ({fields}) => {
  return (
    <div>
      {fields.map((fieldName, index) => {
        const {name} = fields.get(index);
        return (
          <div className="prize-form-substitution__item-row" key={fieldName}>
            <TextField
              name={`${fieldName}.order`}
              cssModifier="prize-form-substitution__item-input"
              format={formatOrder}
              withWrap={false}
            />
            <span className="prize-form-substitution__item-label" title={name}>
              {name}
            </span>
          </div>
        );
      })}
    </div>
  );
};

PrizeFormSubstitutionItem.propTypes = {
  fields: PropTypes.object.isRequired,
};

const PrizeFormSubstitution = (props) => {
  const {lang, errors} = props;

  return (
    <div className="prize-form-substitution">
      <p className="prize-form-substitution__title">{lang.SUBSTITUTION_ORDER}</p>
      <div className="prize-form-substitution__content">
        <CustomScrollbars scrollbarProps={{autoHeightMax: 100}}>
          <FieldArray name="substitution" component={PrizeFormSubstitutionItem} />
        </CustomScrollbars>
      </div>
      {errors.length > 0 && <span className="prize-form-substitution__error">{lang.UNIQUE_VALUES_REQUIRED}</span>}
    </div>
  );
};

PrizeFormSubstitution.propTypes = {
  lang: PropTypes.object.isRequired,
  errors: PropTypes.array,
};

PrizeFormSubstitution.defaultProps = {
  errors: [],
};

export default PrizeFormSubstitution;
