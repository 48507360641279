import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

export default (prize, state) => {
  const languages = selectPrizeLanguages(state);

  const result = {
    email_code_type: prize?.email_code_type,
  };

  languages.forEach((lang) => {
    result[`email_image_${lang}`] = prize?.email_image_translations?.[lang] || null;
    result[`email_image_${lang}_id`] = prize?.email_image_translations?.[`${lang}_id`] || null;

    result[`external_description_${lang}`] = prize?.external_description_translations?.[lang] || null;
  });

  return result;
};
