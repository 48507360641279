import React from 'react';

import PropTypes from 'prop-types';

import Field from 'client/components/common/fields/field';
import HoveringInput from 'client/components/common/hovering-input';

const HoveringFieldComponent = (props) => <HoveringInput {...props} />;

const HoveringField = (props) => <Field {...props} component={HoveringFieldComponent} />;

HoveringField.propTypes = {
  ...Field.propTypes,
  readOnly: PropTypes.bool,
};

HoveringField.defaultProps = {
  ...Field.defaultProps,
  readOnly: false,
};

export default HoveringField;
