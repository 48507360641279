import get from 'lodash/get';
import {createSelector} from 'reselect';

import {EmailTemplateLink} from 'client/models/email-templates/types';
import {MainStates} from 'client/types';

export const selectOperationEmailTemplates = (state: MainStates) => state.emailTemplates.operationEmailTemplates.data;

export const selectTemplatesStepsMapped = createSelector(selectOperationEmailTemplates, (templates) =>
  templates.map((template) => {
    return {
      value: template.id,
      label: template.name,
    };
  }),
);

export function selectEmailTemplateLinks(state: MainStates) {
  const templateLinks: EmailTemplateLink[] = get(state, 'emailTemplates.emailTemplate.email_template_links', []);

  return templateLinks
    .filter((link) => !link._destroy)
    .sort((firstLink, secondLink) => firstLink.position - secondLink.position);
}

export const selectEmailTemplateEditor = (state: MainStates) => {
  return state.emailTemplates.editor;
};

export const selectEmailTemplateKinds = (state: MainStates) => state.emailTemplates.emailTemplateKinds;

export const selectPrizes = (state: MainStates) => state.emailTemplates.prizes;

export const selectEmailSenders = (state: MainStates) => state.emailTemplates.emailSenders;

export const selectActiveTemplate = (state: MainStates) => state.emailTemplates.activeTemplate;

export const selectActiveTemplateKind = (state: MainStates) => state.emailTemplates.activeTemplateKind;

export const selectAddedVariables = (state: MainStates) => state.emailTemplates.addedVariables;

export const selectMasterEmailTemplates = (state: MainStates) => state.emailTemplates.masterEmailTemplates;

export const selectEmailTemplateImagesForDelete = (state: MainStates) =>
  state.emailTemplates.emailTemplateImagesForDelete;

export const selectGalleryEmailTemplates = (state: MainStates) => state.emailTemplates.galleryEmailTemplates;
