import {passwordRegExp} from 'client/services/helpers';
import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {isEmptyString} from 'client/services/validator';

import {TrackingUserValues, TrackingUserErrors} from './types';

export default (
  values: TrackingUserValues,
  lang: LangsByPath<['CLIENT_ACCOUNT_PARAMETERS.MODALS.TRACKING_USER_MODAL.ERRORS']>,
  isEdit: boolean,
) => {
  const errors: TrackingUserErrors = {};

  const login = values.login?.trim() || '';
  const password = values.password?.trim() || '';

  if (isEmptyString(values.name)) {
    errors.name = lang.REQUIRED;
  }

  if (!login) {
    errors.login = lang.REQUIRED;
  } else if (login.length < 5 || login.length > 20) {
    errors.login = lang.LOGIN_LENGTH;
  }

  if (!password && !isEdit) {
    errors.password = lang.REQUIRED;
  } else if (password && passwordRegExp().test(password)) {
    errors.password = lang.PASSWORD_INVALID;
  }

  if (!values.store_id) {
    errors.store_id = lang.REQUIRED;
  }

  return errors;
};
