import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {formatTimeWithSeconds} from 'client/services/helpers';

import Popover from 'client/common/popovers/popover';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {mapBasicInfo, formatDateOptions} from '../../leads-list/helper';
import BasicInfoForm from '../forms/basic-info-form/basic-info-form';

import './leads-basic-info.scss';

function LeadsBasicInfo({editMode, languageState, lead, data, submitBasicInfoForm}) {
  const LANGUAGE = languageState.payload.LEADS_PROFILE;
  return (
    <div className="row">
      <CustomScrollbars scrollbarProps={{autoHeightMax: 300}}>
        <div className="col-xs-6">
          <BasicInfoForm editMode={editMode} lead={lead} submitBasicInfoForm={submitBasicInfoForm} />
        </div>
        <div className="col-xs-6">
          <div className="form-field-wrap form-field-wrap__group--height-5">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{LANGUAGE.BASIC_INFO_ACTIVE_OPTINS_LABEL}</div>
              <CustomScrollbars>
                <div className="fake-input__field leads-basic-info__optins-display">
                  <div className="flex-container leads-basic-info__optins-container">
                    {lead.active_opt_ins &&
                      lead.active_opt_ins.map((el, index) => {
                        return (
                          <Popover
                            key={index}
                            arrowConfig={85}
                            overlayInnerStyle={{width: 240}}
                            overlay={
                              <div className="custom-popover__content popover-list popover-list--color-2">
                                <h3 className="popover-list__title clearfix">
                                  {el.name} ({LANGUAGE.LEVEL_LABEL.replace('{N}', el.level)})
                                  <div className="popover-list__head-link">
                                    <div>
                                      {`${formatDateOptions(el.created_at)} ${formatTimeWithSeconds(el.created_at)} `}
                                    </div>
                                  </div>
                                </h3>
                                <ul className="popover-list__list">
                                  <div className="main-text">
                                    <div>{`${el.code} ${el.confirmed ? LANGUAGE.CONFIRMED_LABEL : ''}`}</div>
                                    <div>{el.opt_in_text}</div>
                                  </div>
                                </ul>
                              </div>
                            }
                          >
                            <CustomScrollbars
                              scrollbarProps={{
                                autoHeightMax: 40,
                              }}
                            >
                              <div className="leads-basic-info__level">
                                {el.name} ({LANGUAGE.LEVEL_LABEL.replace('{N}', el.level)})
                              </div>
                            </CustomScrollbars>
                          </Popover>
                        );
                      })}
                  </div>
                </div>
              </CustomScrollbars>
            </div>
          </div>
          <div className="form-field-wrap">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{LANGUAGE.BASIC_INFO_ACTIVE_CREATED_LABEL}</div>
              <div className="fake-input__field">{lead.created}</div>
            </div>
          </div>
          <div className="form-field-wrap">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{LANGUAGE.BASIC_INFO_ACTIVE_SOURCE_LABEL}</div>
              <div className="fake-input__field">{data.source_name}</div>
            </div>
          </div>
          <div className="form-field-wrap">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{LANGUAGE.BASIC_INFO_ACTIVE_BY_LABEL}</div>
              <div className="fake-input__field">{lead.firstSourceBy}</div>
            </div>
          </div>
          <div className="form-field-wrap">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{LANGUAGE.BASIC_INFO_ACTIVE_CLIENT_LABEL}</div>
              <div className="fake-input__field">{lead.client}</div>
            </div>
          </div>
          <div className="form-field-wrap">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{LANGUAGE.BASIC_INFO_ACTIVE_VISUALS_LABEL}</div>
              <div className="fake-input__field">{lead.visuals}</div>
            </div>
          </div>
          <div className="form-field-wrap">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{LANGUAGE.BASIC_INFO_ACTIVE_PARTICIPATIONS_LABEL}</div>
              <div className="fake-input__field">{lead.participations}</div>
            </div>
          </div>
          <div className="form-field-wrap">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{LANGUAGE.BASIC_INFO_ACTIVE_LAST_EXPORT_LABEL}</div>
              <div className="fake-input__field">{lead.lastExport}</div>
            </div>
          </div>
          <div className="form-field-wrap">
            <div className="fake-input fake-input--disabled">
              <div className="fake-input__label">{LANGUAGE.BASIC_INFO_ACTIVE_BY_LABEL}</div>
              <div className="fake-input__field">{lead.lastExportBy}</div>
            </div>
          </div>
        </div>
      </CustomScrollbars>
    </div>
  );
}

LeadsBasicInfo.defaultProps = {
  editMode: false,
  data: {},
};

LeadsBasicInfo.propTypes = {
  editMode: PropTypes.bool,
  languageState: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  submitBasicInfoForm: PropTypes.func.isRequired,
};

export default connect(({languageState, lead}) => ({
  languageState,
  lead: mapBasicInfo(lead.payload),
}))(LeadsBasicInfo);
