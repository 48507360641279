import React, {Component} from 'react';

import cn from 'classnames';
import {Table, Column} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';
import {uid} from 'client/services/helpers';

import {updateDiagram} from 'client/ducks/results-graphs/actions';
import {addToastNotifications} from 'client/ducks/toast-notification/actions';

import Icon from 'client/components/common/icon';

import TextCell from 'client/components/participants/components/tables/cells/text-cell';

import './results-graph-table.scss';

const b = bem('results-graph-table');

export const grpahTypesMap = {
  pie_chart: 'pie',
  line: 'line',
  horizontal_bars: 'hor-bars',
  vertical_bars: 'ver-bars',
};

class ResultGraphsCardTable extends Component {
  static propTypes = {
    autotaskId: PropTypes.number,
    language: PropTypes.object,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        kind: PropTypes.string,
        column_adapter_name: PropTypes.string,
        position: PropTypes.number,
        visible: PropTypes.bool,
      }),
    ).isRequired,
    updateDiagram: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    addToastNotifications: PropTypes.func.isRequired,
  };

  nameClickHandler = (rowIndex) => {
    this.props.onEdit(this.props.items[rowIndex]);
  };

  renderGraphIcon = ({rowIndex}) => {
    const {items} = this.props;
    const {kind, visible} = items[rowIndex];

    return (
      <div className={b('order-cell', {visible: visible})}>
        <Icon name={grpahTypesMap[kind]} />
      </div>
    );
  };

  renderOrderArrows = ({rowIndex}) => {
    const {items} = this.props;
    const lastElem = items.length - 1;
    const {id, position} = items[rowIndex];
    const showUp = position >= 1;
    const showDown = position < lastElem && items.length > 1;

    return (
      <div className={b('order-cell')}>
        {showUp && (
          <Icon
            name="arrow-closed-up"
            className={b('icon')}
            onClick={() => this.handlePosition({id, position}, 'up')}
          />
        )}
        {showDown && (
          <Icon
            name="arrow-closed-down"
            className={b('icon')}
            onClick={() => this.handlePosition({id, position}, 'down')}
          />
        )}
      </div>
    );
  };

  handlePosition = async ({id, position}, direction) => {
    const data = {
      position: direction === 'up' ? position - 1 : position + 1,
    };

    const response = await this.props.updateDiagram(id, data);
    if (response.error) {
      const errors = response?.payload?.response?.errors;
      this.props.addToastNotifications({
        id: uid(),
        type: 'error',
        description: errors?.base?.[0],
      });
    }
    this.props.fetchData();
  };

  render() {
    const {language, items} = this.props;
    const tableHeight = items.length <= 3 ? 149 : 158;
    return (
      <div className={cn('fixed-table', b())}>
        {items.length ? (
          <Table rowsCount={items.length} headerHeight={0} rowHeight={52} height={tableHeight} width={540} data={items}>
            <Column
              cell={
                <TextCell
                  data={items}
                  field="name"
                  className={cn(b('text'), b('text-link'))}
                  clickHandler={this.nameClickHandler}
                />
              }
              width={100}
            />
            <Column cell={this.renderGraphIcon} width={50} />
            <Column cell={<TextCell data={items} field="column_adapter_name" className={b('text')} />} width={150} />
            <Column cell={this.renderOrderArrows} width={35} />
          </Table>
        ) : (
          <div className={b('no-graph')}>{language.NO_GRAPH}</div>
        )}
      </div>
    );
  }
}

export default connect(null, {
  updateDiagram,
  addToastNotifications,
})(ResultGraphsCardTable);
