import React, {useCallback, useState} from 'react';

import {debounce} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useReduxFetch} from 'client/services/hooks';
import {useLanguage} from 'client/services/hooks';

import {getGalleryEmailTemplates, replaceEmailTemplate} from 'client/ducks/email-templates/actions';
import {
  selectActiveTemplate,
  selectActiveTemplateKind,
  selectGalleryEmailTemplates,
} from 'client/ducks/email-templates/selectors';

import RadioButtonGroup from 'client/common/button-group/radio-button-group';
import AppButton from 'client/common/buttons';
import Icon from 'client/common/icon';
import SearchInput from 'client/common/inputs/search-input';
import Modal from 'client/common/modals/modal';
import Popover from 'client/common/popovers/popover';

import ClientTable from 'client/components/common/client-table';
import Spinner from 'client/components/common/spinner';

import GalleryPreviewModal from 'client/components/email-template-editor/modals/gallery-preview-modal';
import {EmailTemplate} from 'client/models/email-templates/types';
import {ApiDispatch} from 'client/types';

import TemplateCard from './template-card';

import cssModule from './templates-gallery-modal.module.scss';

const b = bem('templates-gallery-modal', {cssModule});

type TemplatesGalleryModalProps = {
  onClose: () => void;
  currentLanguage: string;
  fetchData: () => Promise<void>;
};

const TemplatesGalleryModal: React.FC<TemplatesGalleryModalProps> = (props) => {
  const {onClose, currentLanguage, fetchData} = props;
  const {clientId} = useParams<{clientId: string}>();
  const language = useLanguage('EMAIL_TEMPLATE_EDITOR.MODALS.TEMPLATES_GALLERY_MODAL');

  const [search, setSearch] = useState('');
  const [viewMode, setViewMode] = useState('card-view');
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | null>();
  const [previewTemplate, setPreviewTemplate] = useState<EmailTemplate | null>();
  const [isReplaceLoading, toggleIsReplaceLoading] = useToggle(false);

  const templateKind = useSelector(selectActiveTemplateKind);
  const activeTemplate = useSelector(selectActiveTemplate);

  const dispatch: ApiDispatch = useDispatch();

  const {data = [], loading} = useReduxFetch({
    action: getGalleryEmailTemplates,
    selector: selectGalleryEmailTemplates,
    actionArgs: {
      client_id: clientId,
      language_tag: currentLanguage,
      email_template_kind_id: templateKind?.parent_id,
      q: {
        name_cont: search,
      },
    },
  });

  const handleTemplateClick = useCallback(
    (template: EmailTemplate) => {
      if (selectedTemplate?.id === template.id) {
        setSelectedTemplate(null);
      } else {
        setSelectedTemplate(template);
      }
    },
    [selectedTemplate?.id],
  );

  const debouncedSelect = debounce(handleTemplateClick, 200);

  const handleTemplateDoubleClick = (template: EmailTemplate) => {
    debouncedSelect.cancel();
    setPreviewTemplate(template);
  };

  const handleSelectClick = () => {
    if (!activeTemplate || !selectedTemplate) {
      return;
    }
    toggleIsReplaceLoading();

    dispatch(replaceEmailTemplate(activeTemplate.id, selectedTemplate.id))
      .then(() => {
        fetchData().then(() => onClose());
      })
      .finally(() => toggleIsReplaceLoading(false));
  };

  const renderContent = () => {
    if (loading) {
      return <Spinner className={b('spinner')} />;
    }

    if (search && !data.length) {
      return (
        <div className={b('placeholder')}>
          <Icon name="task-image-placeholder" width={90} height={90} />
          <p>{language.PLACEHOLDER_TEXT}</p>
        </div>
      );
    }

    if (!data.length) {
      return null;
    }

    if (viewMode === 'card-view') {
      return (
        <div className={b('card-container')}>
          {data.map((template) => (
            <TemplateCard
              key={template.id}
              template={template}
              selected={selectedTemplate?.id === template.id}
              onClick={() => debouncedSelect(template)}
              onDoubleClick={() => handleTemplateDoubleClick(template)}
            />
          ))}
        </div>
      );
    }

    return (
      <ClientTable
        columns={[
          {
            name: 'name',
            label: 'Name',
            path: 'name',
            render: ({value, item}: {value: string; item: EmailTemplate}) => {
              return (
                <AppButton
                  asWrap={true}
                  onClick={() => debouncedSelect(item)}
                  className={b('table-row', {selected: selectedTemplate?.id === item.id})}
                  iconName="email-open"
                  iconConfig={{width: 24, height: 24}}
                  label={value}
                  onDoubleClick={() => handleTemplateDoubleClick(item)}
                />
              );
            },
          },
        ]}
        data={data}
        className={b('table', {overflow: data.length > 10})}
        commonCellClassName={b('table-cell')}
      />
    );
  };

  return (
    <Modal show={true} title={language.TITLE} onClose={onClose} dialogClassName={b()} contentClassName={b('content')}>
      <div className={b('search-bar')}>
        <SearchInput className={b('search-input')} searchDefault={search} onSearch={setSearch} />
        {search && (
          <span className={b('results-label')}>
            {data.length} {language.RECORDS_FOUND}
          </span>
        )}
        <RadioButtonGroup
          className={b('view-mode')}
          labelClassName={b('view-mode-label')}
          name="view-mode"
          value={viewMode}
          onChange={({target}) => setViewMode(target.value)}
          radioButtons={[
            {
              label: (
                <Popover overlay={language.CARD_VIEW} position="top" triggerClassName={b('popover-trigger')}>
                  <Icon name="card-view" width={20} height={20} />
                </Popover>
              ),
              value: 'card-view',
            },
            {
              label: (
                <Popover overlay={language.LIST_VIEW} position="top" triggerClassName={b('popover-trigger')}>
                  <Icon name="list-view" width={28} height={20} />
                </Popover>
              ),
              value: 'list-view',
            },
          ]}
        />
      </div>

      {renderContent()}

      <AppButton
        onClick={handleSelectClick}
        label={language.SELECT_BUTTON}
        size="medium"
        className={b('button')}
        disabled={!selectedTemplate}
        loading={isReplaceLoading}
      />

      {previewTemplate && (
        <GalleryPreviewModal
          template={previewTemplate}
          onClose={() => {
            setPreviewTemplate(null);
          }}
        />
      )}
    </Modal>
  );
};

export default TemplatesGalleryModal;
