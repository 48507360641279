import React, {useEffect, useMemo, useState} from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useDispatch, useSelector} from 'react-redux';
import {useToggle, useUnmount} from 'react-use';

import bem from 'client/services/bem';
import htmlToImage from 'client/services/htmlToImage';

import {
  setActiveTemplate,
  setActiveTemplateKind,
  addEmailTemplateVariable,
  resetEmailTemplateImagesForDelete,
} from 'client/ducks/email-templates/actions';
import {selectAddedVariables, selectEmailTemplateImagesForDelete} from 'client/ducks/email-templates/selectors';

import useClientFonts from 'client/components/diy-operation/modals/diy-customization-modal/useClientFonts';
import usePrepareTemplateData from 'client/components/email-template-editor/hooks/usePrepareTemplateData';
import generateMjml from 'client/components/email-template-editor/services/generate-mjml';
import {EmailTemplateKind, EmailTemplate} from 'client/models/email-templates/types';

import ActionsBar from './bars/actions-bar';
import ParametersBar from './bars/parameters-bar';
import Sidebar from './bars/sidebar';
import {Content} from './content';
import {mapTemplateToEmailParams} from './helpers';
import {useEditor} from './hooks/useEditor';
import usePreloadTemplateImages from './hooks/usePreloadTemplateImages';
import CloseEditorModal from './modals/close-editor-modal';
import {TemplateData, ClosePrompt, EmailParams} from './types';

import cssModule from './email-template-editor.module.scss';

const b = bem('email-template-editor', {cssModule});

type EmailTemplateEditorProps = {
  onSave: (
    templateData: TemplateData,
    mjml: string,
    addedVariables: string[],
    hasPrize: boolean,
    extraData: Record<string, any>,
  ) => Promise<void>;
  currentKind?: EmailTemplateKind | null;
  kinds: EmailTemplateKind[];
  onChangeKind: (kindId: number) => void;
  currentLanguage: string;
  languages: string[];
  onChangeLanguage: (language: string) => void;
  activeTemplate: EmailTemplate | null;
  loading?: boolean;
  hideParameters?: boolean;
  hideGallery?: boolean;
  fetchData: () => Promise<any>;
};

export const EmailTemplateEditor: React.FC<EmailTemplateEditorProps> = (props) => {
  const {
    onSave,
    loading,
    activeTemplate,
    currentKind,
    kinds,
    onChangeKind,
    currentLanguage,
    languages,
    onChangeLanguage,
    hideParameters,
    fetchData,
    hideGallery,
  } = props;
  const dispatch = useDispatch();
  const {doc, setDocData, hasPrize, width, docId, setDocId} = useEditor();
  const [promptType, setPromptType] = useState<ClosePrompt>('common');
  const [emailParams, setEmailParams] = useState<EmailParams>(mapTemplateToEmailParams());
  const addedVariables = useSelector(selectAddedVariables);
  const imagesForDelete = useSelector(selectEmailTemplateImagesForDelete);

  // Images should be preloaded before they are rendered in <img> tag.
  // This is necessary so that they are cached with Access-Control-Allow-Origin header.
  // This header is needed to generate template preview image via html2canvas without CORS errors.
  const preloadingImages = usePreloadTemplateImages(activeTemplate);

  // Fonts are used in TextBlock
  useClientFonts({fetchOnMount: true});

  const [loadingSave, toggleLoadingSave] = useToggle(false);
  const {prepare} = usePrepareTemplateData();

  useEffect(() => {
    if (activeTemplate) {
      dispatch(setActiveTemplate(activeTemplate));
    }
  }, [activeTemplate, dispatch]);

  useEffect(() => {
    if (activeTemplate?.json_part) {
      setPromptType('common');
      dispatch(addEmailTemplateVariable(null));
      setDocId(activeTemplate?.id);
      setDocData(cloneDeep(activeTemplate?.json_part));
    }
  }, [setDocData, setDocId, activeTemplate?.id, activeTemplate?.json_part, dispatch]);

  useEffect(() => {
    if (activeTemplate) {
      setEmailParams(mapTemplateToEmailParams(activeTemplate));
    }
  }, [activeTemplate]);

  useUnmount(() => {
    dispatch(setActiveTemplate(null));
    dispatch(setActiveTemplateKind(null));
  });

  const hasChanges = useMemo(() => {
    const originBody = activeTemplate?.json_part?.body;
    const currentBody = doc.body;
    const isTemplateChanged = activeTemplate?.id !== docId;

    if (loading || !originBody || isTemplateChanged) {
      return false;
    }

    return !isEqual(originBody, currentBody);
  }, [loading, doc, activeTemplate?.json_part, activeTemplate?.id, docId]);

  const handleSave = async () => {
    const previewImage = await htmlToImage('email-template-container');

    toggleLoadingSave();
    let response;
    try {
      const nextDoc = await prepare(doc);

      const mjml = generateMjml(nextDoc, {width, previewText: emailParams.previewText});
      const extraData: Record<string, any> = {};
      if (imagesForDelete?.length) {
        extraData.email_template_images = Array.from(
          new Set(
            activeTemplate?.email_template_images
              ?.filter((image) => {
                return imagesForDelete.some((i) => i.title === image.title);
              })
              .map(({id}) => ({
                id,
                _destroy: true,
              })),
          ),
        );
      }
      extraData.preview_image = {
        data: previewImage,
      };
      response = onSave(nextDoc, mjml, addedVariables, hasPrize(), extraData);
    } finally {
      dispatch(resetEmailTemplateImagesForDelete());
      toggleLoadingSave();
    }

    return response;
  };

  return (
    <div className={b()}>
      <CloseEditorModal
        when={hasChanges}
        templateName={currentKind?.type_name || ''}
        onSave={handleSave}
        onRestore={fetchData}
        promptType={promptType}
        onCancel={() => setPromptType('common')}
      />
      <ActionsBar
        currentKind={currentKind}
        kinds={kinds}
        onChangeKind={onChangeKind}
        currentLanguage={currentLanguage}
        languages={languages}
        onChangeLanguage={(value) => {
          setPromptType('language');
          onChangeLanguage(value);
        }}
        onSave={handleSave}
        onRestore={fetchData}
        loading={loadingSave}
        disabled={loading}
        hasChanges={hasChanges}
        hideGallery={hideGallery}
      />
      <div className={b('body')}>
        <Sidebar loading={loading || loadingSave} />
        <div className={b('main')}>
          {!hideParameters && currentKind && (
            <ParametersBar
              loading={loading || loadingSave}
              emailParams={emailParams}
              setEmailParams={setEmailParams}
              kind={currentKind}
            />
          )}
          <div className={b('content')}>
            <Content loading={loading || preloadingImages || loadingSave} />
          </div>
        </div>
      </div>
    </div>
  );
};
