export default (formValues) => ({
  instore_animation: {
    reco_name: formValues.reco_name,
    reco_phone: formValues.reco_phone,
    reco_email: formValues.reco_email,
    surface: formValues.surface,
    electric_plug: formValues.electric_plug,
    stock: formValues.stock,
    dock: formValues.dock,
    log_conditions: formValues.log_conditions,
    comments: formValues.comments,
    animation_data: formValues.products?.map(({id, quantity}) => ({
      id,
      value_num: Number(quantity),
    })),
  },
});
