import React from 'react';

import PropTypes from 'prop-types';

import BCToolbar from 'client/components/broadcasting/components/bc-toolbar';

function BCMailingTasksToolbar({meta: {total_count: totalItems}, language, renderSearchField}) {
  return (
    <div className="flex-container flex-align-bottom flex-justify-around">
      <BCToolbar
        withoutFilters
        searchField={renderSearchField()}
        resultsNum={totalItems}
        textLabels={{
          resultsNum: language.RESULTS,
        }}
      />
    </div>
  );
}

BCMailingTasksToolbar.propTypes = {
  meta: PropTypes.shape({
    total_count: PropTypes.number,
  }).isRequired,
  language: PropTypes.object.isRequired,
  renderSearchField: PropTypes.func.isRequired,
};

export default BCMailingTasksToolbar;
