import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import CountBlock from 'client/common/count-block';

// components
import Icon from 'client/components/common/icon';
import InfoBlock from 'client/components/common/info-block';

import PhonesEmailsDataGrid from '../../tables/phones-emails-card-data-grid';
import PhonesEmailsList from '../../tables/phones-emails-list';

class PhonesEmailsCard extends Component {
  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AGENCY.PHONES_EMAILS_CARD;

    this.state = {
      editMode: false,
    };
  }

  handleEditClick = () => {
    this.setState({
      editMode: true,
    });
  };

  handleCancelClick = () => {
    this.props.refresh();
    this.setState({
      editMode: false,
    });
  };

  render() {
    const {data, clientId} = this.props;
    const {LANGUAGE} = this;
    return (
      <InfoBlock colorTheme="phones">
        <InfoBlock.Title>{LANGUAGE.TITLE}</InfoBlock.Title>
        <InfoBlock.Content>
          <InfoBlock.Header className="clearfix">
            <CountBlock itemName={LANGUAGE.ITEMS_NAME} className="pull-left" num={data.length} />
            {data.length !== 0 && !this.state.editMode && (
              <button className="button button--circle button--bg-7 pull-right" onClick={this.handleEditClick}>
                <Icon name="edit" className="button__icon" width={20} height={20} />
              </button>
            )}
          </InfoBlock.Header>
          <InfoBlock.Body>
            {this.state.editMode || !data.length ? (
              <PhonesEmailsList
                onCancelClick={this.handleCancelClick}
                onSaveClick={this.handleCancelClick}
                onAddClick={this.handleEditClick}
                editMode={this.state.editMode}
                data={data}
                clientId={clientId}
                languageState={this.props.languageState}
              />
            ) : (
              <PhonesEmailsDataGrid data={data} languageState={this.props.languageState} />
            )}
          </InfoBlock.Body>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

PhonesEmailsCard.propTypes = {
  data: PropTypes.array,
  clientId: PropTypes.number,
  refresh: PropTypes.func,
  onSave: PropTypes.func,
  languageState: PropTypes.object,
};

PhonesEmailsCard.defaultProps = {
  data: [],
};

export default connect(({languageState}) => ({languageState}))(PhonesEmailsCard);
