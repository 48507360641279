import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import {CheckboxInput} from 'client/common/inputs';

import {ParticipationColumn} from 'client/models/participations/types';

import {getColumnName} from '../helpers';

import cssModule from './participants-table-settings-modal-selected-list.module.scss';

type ParticipantsTableSettingsModalSelectedListProps = {
  className?: string;
  selectedColumns: ParticipationColumn[];
  checkedColumns: ParticipationColumn[];
  onCheckColumn: (column: ParticipationColumn, nextState: boolean) => void;
  activeTab?: boolean;
};

const b = bem('participants-table-settings-modal-selected-list', {cssModule});

const ParticipantsTableSettingsModalSelectedList: React.FC<ParticipantsTableSettingsModalSelectedListProps> = ({
  className,
  selectedColumns,
  checkedColumns,
  onCheckColumn,
}) => {
  return (
    <div className={cn(className)}>
      <div className="list list--view-2">
        {selectedColumns.map((column) => {
          const checked = checkedColumns.some(({column_adapter_id}) => column_adapter_id === column.column_adapter_id);
          return (
            column.column_adapter && (
              <div className="list__item pos-rel tab__item" key={column.column_adapter_id}>
                <CheckboxInput
                  color="games"
                  name={`checkbox-${column.column_adapter_id}`}
                  classNames={{
                    label: b('column-title'),
                  }}
                  label={getColumnName(column.column_adapter?.record_type, column.column_adapter?.name)}
                  checked={checked}
                  onCheck={() => {
                    onCheckColumn(column, !checked);
                  }}
                />
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default ParticipantsTableSettingsModalSelectedList;
