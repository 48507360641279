import {mapFilter, mapLeadsFilterForUrl} from 'client/components/client-autotask/tabs/data-tab/helpers';

type GetFetchLeadsParams = {
  queryParams: Record<string, any>;
  autotaskId?: number;
  clientId?: number;
  pages?: {current?: number; perPage?: number};
  filters?: Record<string, any>;
  search?: string;
  sortParams?: {
    sortField?: string;
    sortOrder?: string;
  };
  withImported: boolean;
};
const DEFAULT_FIRST_PAGE = 1;

const DEFAULT_PER_PAGE = 25;

const DEFAULT_SORT_FIELD = 'first_participated_at';
const DEFAULT_SORT_ORDER = 'desc';

export const getFetchLeadsParams = (config: GetFetchLeadsParams) => {
  const {filters, search, sortParams, autotaskId, clientId, pages, withImported} = config;

  const sortField = sortParams?.sortField || DEFAULT_SORT_FIELD;
  const sortOrder = sortParams?.sortOrder || DEFAULT_SORT_ORDER;

  const params: Record<string, any> = {
    include_lead_expires_at: null,
    include: {
      client: null,
      opt_in_columns: null,
      first_source: null,
      last_participation: {
        operation: null,
      },
    },
    q: {
      s: sortField && `${sortField} ${sortOrder}`,
    },
    page: pages?.current || DEFAULT_FIRST_PAGE,
    per_page: pages?.perPage || DEFAULT_PER_PAGE,
    distinct: true,
  };
  if (autotaskId) {
    params.q.participations_automation_task_id_eq = autotaskId;
  }
  if (clientId && !autotaskId) {
    params.q.client_id_eq = clientId;
  }
  if (search) {
    params.q.first_name_or_last_name_or_email_or_phone_cont = search;
  }
  if (filters) {
    const {q, distinct} = mapLeadsFilterForUrl(mapFilter(filters));

    params.distinct = distinct;
    params.q = {
      ...params.q,
      ...q,
    };
    params.q.g = [params.q.g];
  }

  if (!withImported) {
    params.q.participations_test_mode_eq = false;
    params.q.participations_visible_by_client_eq = true;
  }

  if (['last_export_created_at', 'first_source_created_at'].some((i) => sortField?.includes(i))) {
    delete params.distinct;
  }
  return params;
};
