import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getSubsidiaries(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SUBSIDIARIES}`,
      method: 'GET',
      types: [types.GET_SUBSIDIARIES_REQUEST, types.GET_SUBSIDIARIES_SUCCESS, types.GET_SUBSIDIARIES_ERROR],
      queryParams,
    });
}
