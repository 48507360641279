import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';
import PaginationBar from 'client/common/paginations/pagination-bar';
import Popover from 'client/common/popovers/popover';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';

import cssModule from './devices-table.module.scss';

const b = bem('devices-table', {cssModule});

class DevicesTable extends ReactQueryParams {
  constructor(props) {
    super(props);

    this.state = {
      checkedRows: [],
    };
  }

  onPageChange = (params) => {
    this.setState({checkedRows: []});
    this.props.onPageChange(params);
  };

  onSortChange = ({sortField, sortOrder}) => {
    this.setState({checkedRows: []});
    this.props.onSortChange(sortField, sortOrder);
  };

  renderToolbar = () => {
    const {selectedItems, readOnly, lang} = this.props;
    return (
      <div className={b('toolbar')}>
        <button className="button button--bg-7" onClick={this.props.manageTypesButtonClick}>
          <Icon name="settings" className="button__icon button__icon--size-2" />
          <span>{lang.MANAGE_TYPES_BUTTON}</span>
        </button>
        <Popover
          overlay={lang.REASON_READ_ONLY_DEVICE_PAGE}
          trigger={readOnly ? 'hover' : 'none'}
          contentClassName={b('read-only-popover')}
        >
          <button
            className="button button--bg-7"
            onClick={this.props.addDeviceButtonClick}
            type="button"
            disabled={readOnly}
          >
            <Icon name="plus" className="button__icon" />
            <span>{lang.ADD_DEVICE_BUTTON}</span>
          </button>
        </Popover>
        {selectedItems.length > 0 && (
          <button className="button button--bg-7" onClick={this.props.deleteDevicesButtonClick}>
            <Icon name="trash" className="button__icon" width={17} height={19} />
            <span>
              {lang.DELETE_DEVICES_BUTTON} ({selectedItems.length})
            </span>
          </button>
        )}
      </div>
    );
  };

  renderName = ({item: device}) => {
    return (
      <div>
        <div className={b('link')} onClick={() => this.props.onEditDeviceClick(device)}>
          {device.name}
        </div>
        <div className="bold margin-top--10">{`${this.props.lang.ID_LABEL} = ${device.id}`}</div>
      </div>
    );
  };

  renderStatus = ({value}) => {
    return (
      <div>
        {this.props.lang.DEVICE_STATUSES[value]}
        <div className={b('indicator')}>
          <div className={b('indicator-inner', [value])} />
        </div>
      </div>
    );
  };

  renderType = ({value: type}) => {
    return <div>{type && type.name}</div>;
  };

  onSelect = (isChecked, item) => {
    let checkedItems;
    if (isChecked) {
      checkedItems = [...this.state.checkedRows, item];
    } else {
      checkedItems = this.state.checkedRows.filter((row) => row.id !== item.id);
    }

    this.setState({checkedRows: checkedItems});
  };

  render() {
    const {data, sort, readOnly, lang} = this.props;

    return (
      <>
        <div className={b('toolbar-wrapper')}>
          {this.renderToolbar()}
          <PerPageDropdown
            onChange={(pages) => {
              this.onPageChange({page: 1, perPage: pages});
            }}
            value={String(this.props.perPage)}
            simpleValue
          />
        </div>
        <ClientTable
          data={data}
          onSortChange={this.onSortChange}
          className={b()}
          sortField={sort?.name}
          sortOrder={sort?.order}
          checkable={!readOnly}
          onCheck={this.onSelect}
          onCheckAll={(isChecked) => {
            this.setState({checkedRows: isChecked ? data : []});
          }}
          checkedRows={this.state.checkedRows}
          columns={[
            {
              name: 'name',
              path: 'name',
              label: lang.DEVICE_NAME_COLUMN,
              render: this.renderName,
              sortable: true,
              width: '18%',
            },
            {
              name: 'code',
              path: 'code',
              label: lang.CODE_COLUMN,
              sortable: true,
              width: '10%',
            },
            {
              name: 'device_family_name',
              path: 'device_family',
              label: lang.TYPE_COLUMN,
              render: this.renderType,
              sortable: true,
              width: '11%',
            },
            {
              name: 'logistic_id',
              path: 'logistic_id',
              label: lang.LOGISTIC_ID_COLUMN,
              sortable: true,
              width: '9%',
            },
            {
              name: 'description',
              path: 'description',
              label: lang.DESCRIPTION_COLUMN,
              sortable: true,
              width: '37%',
            },
            {
              name: 'status',
              path: 'status',
              label: lang.STATUS_COLUMN,
              render: this.renderStatus,
              sortable: true,
              width: '15%',
            },
          ]}
        />
        <PaginationBar
          data={data}
          onPageChange={this.onPageChange}
          currentPage={this.props.currentPage}
          totalPages={this.props.totalPages}
          totalItems={this.props.totalItems}
          perPage={this.props.perPage}
        />
      </>
    );
  }
}

DevicesTable.defaultProps = {
  data: [],
};

DevicesTable.propTypes = {
  data: PropTypes.array,
  selectedItems: PropTypes.array,
  children: PropTypes.any,
  needToUpdateItems: PropTypes.bool,
  totalPages: PropTypes.number,
  totalItems: PropTypes.number,
  perPage: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  onEditDeviceClick: PropTypes.func,
  manageTypesButtonClick: PropTypes.func,
  addDeviceButtonClick: PropTypes.func,
  deleteDevicesButtonClick: PropTypes.func,
  onSortChange: PropTypes.func,
  changeSelectedItems: PropTypes.func,
  languageState: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default connect(({languageState}) => ({lang: languageState.payload.DEVICES}))(DevicesTable);
