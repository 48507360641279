export const COUPON_TYPES = {
  WEEZIO: 'weezio',
  SOGEC: 'sogec',
  APP: 'app',
  CLIENT: 'client',
};

export const COUPON_TYPE_MAP = {
  WeezioCoupon: COUPON_TYPES.WEEZIO,
  SogecCoupon: COUPON_TYPES.SOGEC,
  AppCoupon: COUPON_TYPES.APP,
  ClientCoupon: COUPON_TYPES.CLIENT,
};

export const VALIDITY_TYPES = {
  FIXED_VALUE: 'fixed_value',
  DURATION: 'duration',
};

export const CHARACTERS = {
  ALPHANUMERIC: 'alphanumeric',
  ALPHABETICAL: 'alphabetical',
  NUMERIC: 'numeric',
};

export const CHARACTER_FORMATS = {
  MIXED: 'mixed',
  CAPITAL_LETTERS: 'capital',
  NON_CAPITAL_LETTERS: 'non_capital',
};

export const PREFIXES = {
  WITHOUT: 'without',
  VARIABLE: 'variable',
  FIXED_VALUE: 'fixed_value',
};
