import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// components
import {Field, reduxForm} from 'redux-form';

import {email} from 'client/services/validator';

import {editLeadInfo} from 'client/ducks/lead/actions';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import fieldTemplate from 'client/components/common/field';

import {mapBasicInfo} from '../../../leads-list/helper';

let LANGUAGE = {};

class BasicInfoForm extends Component {
  static formName = 'leadsBasicInfoForm';
  static defaultProps = {
    editMode: false,
  };

  static propTypes = {
    leadGender: PropTypes.string,
    languageState: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    editLeadInfoBasic: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    LANGUAGE = props.languageState.payload.LEADS_PROFILE;

    this.rules = {emailField: email(LANGUAGE.ERRORS.INVALID_EMAIL)};
  }

  getGenderOptions = () => {
    const {leadGender} = this.props;

    const male = LANGUAGE.TABLE.GENDER_MALE;
    const female = LANGUAGE.TABLE.GENDER_FEMALE;
    const other = LANGUAGE.TABLE.GENDER_OTHER;
    const empty = LANGUAGE.TABLE.GENDER_NA;

    const base = [
      {value: male, label: male},
      {value: female, label: female},
      {value: other, label: other},
      {value: 'null', label: empty},
    ];

    if ([male, female, other].includes(leadGender)) {
      return base;
    }

    return base.concat([{value: leadGender, label: leadGender}]);
  };

  render() {
    const {editMode, handleSubmit, isAdmin} = this.props;
    const editable = editMode && isAdmin;
    return (
      <form onSubmit={handleSubmit}>
        <div className="basic-info-form">
          <div className="form-field-wrap form-field-wrap__group--height-5">
            <Field
              cssModifier="select--disabled-view-1 select--no-min-height"
              disabled={!editable}
              label={LANGUAGE.BASIC_INFO_CIVILITY_LABEL}
              name="gender"
              type="select"
              searchable={false}
              options={this.getGenderOptions()}
              component={fieldTemplate}
            />
          </div>
          <div className="form-field-wrap">
            <Field
              cssModifier="input--disabled-view-1 input--no-min-height"
              disabled={!editable}
              label={LANGUAGE.BASIC_INFO_FIRST_NAME_LABEL}
              name="first_name"
              type="text"
              component={fieldTemplate}
            />
          </div>
          <div className="form-field-wrap">
            <Field
              cssModifier="input--disabled-view-1 input--no-min-height"
              disabled={!editable}
              label={LANGUAGE.BASIC_INFO_LAST_NAME_LABEL}
              name="last_name"
              type="text"
              component={fieldTemplate}
            />
          </div>
          <div className="form-field-wrap form-field-wrap--width-3">
            <Field
              cssModifier={`datepicker--disabled-view-1 ${editable ? '' : 'disabled'} `}
              label={LANGUAGE.BASIC_INFO_BIRTHDATE_LABEL}
              name="birth_date"
              type="datepicker"
              disabled={!editable}
              component={fieldTemplate}
            />
          </div>
          {!editable && (
            <div className="form-field-wrap">
              <div className="fake-input fake-input--disabled">
                <div className="fake-input__label">{LANGUAGE.BASIC_INFO_AGE_LABEL}</div>
                <div className="fake-input__field">
                  <Field
                    cssModifier="input--disabled-view-1"
                    disabled={true}
                    label={LANGUAGE.PHONE_LABEL}
                    name="age"
                    type="text"
                    component={fieldTemplate}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="form-field-wrap">
            <Field
              cssModifier="input--disabled-view-1 input--no-min-height"
              disabled={!editable}
              label={LANGUAGE.BASIC_INFO_PHONE_LABEL}
              name="phone"
              type="text"
              component={fieldTemplate}
            />
          </div>
          <div className="form-field-wrap">
            <Field
              cssModifier="input--disabled-view-1 input--no-min-height"
              disabled={!editable}
              label={LANGUAGE.BASIC_INFO_EMAIL_LABEL}
              name="email"
              type="text"
              component={fieldTemplate}
              validate={this.rules.emailField}
            />
          </div>
          <div className="form-field-wrap">
            <Field
              cssModifier="input--disabled-view-1"
              disabled={!editMode}
              label={LANGUAGE.BASIC_INFO_COMMENT_LABEL}
              name="comment"
              type="textarea"
              component={fieldTemplate}
            />
          </div>
        </div>
      </form>
    );
  }
}

const leadsBasicInfoForm = reduxForm({
  form: BasicInfoForm.formName,
  enableReinitialize: true,
})(BasicInfoForm);

export default connect(
  (state, ownProps) => ({
    leadGender: state.lead.payload.gender,
    languageState: state.languageState,
    initialValues: mapBasicInfo(state.lead.payload),
    onSubmit: ownProps.submitBasicInfoForm,
    isAdmin: selectIsAdmin(state),
  }),
  {editLeadInfoBasic: editLeadInfo},
)(leadsBasicInfoForm);
