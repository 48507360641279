import React, {useCallback, useEffect, useState} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';

import SelectDropdown from 'client/common/selects/select-dropdown';
import {SelectCommonProps, SelectOption} from 'client/common/selects/types';

import '../select-dropdown/select-dropdown.scss';

const InfinityScrollDropdown = (props) => {
  const {fetchData, dataMap, dataKey, dependenciesKey, ...selectProps} = props;
  const [config, setConfig] = useState({
    options: [],
    page: 1,
    total: null,
  });
  const [loading, setLoading] = useState(false);

  const loadOptions = useCallback(
    (reset) => {
      let {options, page, total} = config;
      if (reset) {
        options = [];
        page = 1;
        total = null;
      }
      if (((!total && total !== 0) || options?.length !== total) && !loading) {
        setLoading(true);
        fetchData(page).then((response) => {
          const data = get(response, dataKey, []);
          const {meta} = response;
          setConfig({
            options: [...(options || []), ...data.map(dataMap)],
            page: page + 1,
            total: meta.total_count,
          });

          setLoading(false);
        });
      }
    },
    [dataKey, dataMap, fetchData, loading, config],
  );

  useEffect(() => {
    loadOptions(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadOptions(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependenciesKey]);

  return (
    <SelectDropdown {...selectProps} options={config.options} isLoading={loading} onMenuScrollToBottom={loadOptions} />
  );
};

InfinityScrollDropdown.propTypes = {
  ...SelectCommonProps,
  value: PropTypes.oneOfType([PropTypes.arrayOf(SelectOption), SelectOption]),

  /**
   * Method for refetch new data for dropdown
   * @return {Promise} - Return data with pagination.
   * For example: return {places: [{id: 1 }, meta: {total_count: 120}]}
   */
  fetchData: PropTypes.func.isRequired,

  /**
   * Method for mapping values of fetched data
   */
  dataMap: PropTypes.func.isRequired,

  /**
   * Key of fetched data
   */
  dataKey: PropTypes.string.isRequired,

  /**
   * Dependencies string. after changing it will reset of options
   */
  dependenciesKey: PropTypes.string,
};

InfinityScrollDropdown.defaultProps = {
  ...SelectDropdown.defaultProps,
  value: null,
  dependenciesKey: '',
};

export default InfinityScrollDropdown;
