import React, {Component} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {getStringFromArrayMap} from 'client/services/helpers';
import {required} from 'client/services/validator';

import {addColumnVisibilityItem} from 'client/ducks/lead-display-items/actions';
import {createLeadColumn} from 'client/ducks/leads-list/actions';

import {COLUMN_TYPES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

const initData = {
  kind: {
    value: COLUMN_TYPES.TEXT,
  },
};

class AddNewColumnModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    createLeadColumn: PropTypes.func.isRequired,
    addColumnVisibilityItem: PropTypes.func.isRequired,
    clientId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    show: false,
  };

  static formName = 'AddNewColumnModalForm';

  dataTypes = [
    {
      label: this.props.lang.TABLE_NEW_COLUMN_TEXT,
      value: COLUMN_TYPES.TEXT,
    },
    {
      label: this.props.lang.TABLE_NEW_COLUMN_NUMBER,
      value: COLUMN_TYPES.DECIMAL,
    },
    {
      label: this.props.lang.TABLE_NEW_COLUMN_EMAIL,
      value: COLUMN_TYPES.EMAIL,
    },
    {
      label: this.props.lang.TABLE_NEW_COLUMN_PHONE,
      value: COLUMN_TYPES.PHONE,
    },
    {
      label: this.props.lang.TABLE_NEW_COLUMN_BOOLEAN,
      value: COLUMN_TYPES.BOOLEAN,
    },
    {
      label: this.props.lang.TABLE_NEW_COLUMN_FILE,
      value: COLUMN_TYPES.FILE,
    },
  ];

  serverErrorsMap = {
    taken: this.props.lang.TABLE_NEW_COLUMN_NAME_UNIQUE_ERROR,
  };

  rules = {
    name: [
      required(this.props.lang.TABLE_NEW_COLUMN_NAME_REQUIRED),
      (value) => {
        return /[~!@#$%^&*()+=|\/?><"]/g.test(value) ? this.props.lang.TABLE_NEW_COLUMN_NAME_SYMBOLS : null;
      },
    ],
  };

  getServerErrorsString = (errors, map) => getStringFromArrayMap(errors, map);

  save = ({name, kind, array}) => {
    const body = {
      name,
      kind: kind.value,
      array: array,
      client_id: this.props.clientId,
    };

    return this.props
      .createLeadColumn(body)
      .then((response) => {
        const errors = get(response, 'payload.response.errors');
        if (errors) {
          const nameError = this.getServerErrorsString(errors.name, this.serverErrorsMap);
          throw new SubmissionError({name: nameError});
        }
        return get(response, 'payload.lead_column.external_column_adapter.id');
      })
      .then((column_adapter_id) =>
        this.props.addColumnVisibilityItem({
          column_visibility_item: {
            client_id: this.props.clientId,
            column_adapter_id,
          },
        }),
      )
      .then(this.props.onConfirm)
      .then(this.props.reset);
  };

  handleCancel = () => {
    this.props.reset();
    this.props.onClose();
  };

  render() {
    const {handleSubmit, submitting, valid, pristine, onClose, show, lang} = this.props;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 modal-window--theme-2"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TABLE_NEW_COLUMN_MODAL_TITLE}
      >
        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          <FormFieldWrap>
            <Field
              name="name"
              label={lang.TABLE_NEW_COLUMN_MODAL_COL_NAME_LABEL}
              type="text"
              component={fieldTemplate}
              validate={this.rules.name}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              label={lang.TABLE_NEW_COLUMN_MODAL_COL_TYPE_LABEL}
              name="kind"
              type="select"
              searchable={false}
              options={this.dataTypes}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              label={lang.TABLE_NEW_COLUMN_ARRAY_DATA}
              name="array"
              type="checkbox"
              cssModifier="theme-color-2"
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={this.handleCancel}>
              {lang.TABLE_NEW_COLUMN_MODAL_CANCEL_BUTTON}
            </button>
            <button
              className="button button--bg-2 modal-window__footer-btn"
              type="submit"
              disabled={submitting || !valid || pristine}
            >
              {lang.TABLE_NEW_COLUMN_MODAL_CONFIRM_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const AddNewColumnModalForm = reduxForm({
  form: AddNewColumnModal.formName,
})(AddNewColumnModal);

export default connect(
  (state) => ({
    lang: state.languageState.payload.LEADS_LIST,
    initialValues: initData,
  }),
  {createLeadColumn, addColumnVisibilityItem},
)(AddNewColumnModalForm);
