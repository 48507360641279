import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';
import LayoutPanel from 'client/common/panels/layout-panel';

import cssModule from './catalog-templates-no-result-panel.module.scss';

const b = bem('catalog-templates-no-result-panel', {cssModule});

const CatalogTemplatesNoResultPanel = () => {
  const lang = useLanguage('CATALOG_TEMPLATES.PANELS.CATALOG_TEMPLATES_NO_RESULT_PANEL');

  return (
    <LayoutPanel className={b()}>
      <Icon name="table-no-data" className={b('icon')} />
      <p className={b('no-records')}>{lang.NO_RESULT_TEXT}</p>
    </LayoutPanel>
  );
};

export default CatalogTemplatesNoResultPanel;
