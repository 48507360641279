import React from 'react';

import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

import BcNavItem from '../../bc-nav-item';

function BcSmsTextNavItem(props) {
  const {languageState, checked, eventKey, messageText, ...restProps} = props;

  return (
    <BcNavItem
      title={<div className="break-word">{languageState.SMS_TEXT_NAV}</div>}
      name={checked && <div className="break-word">{messageText}</div>}
      eventKey={eventKey}
      checked={checked}
      {...restProps}
    />
  );
}

BcSmsTextNavItem.propTypes = {
  languageState: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  eventKey: PropTypes.string,
  messageText: TranslationJsx,
};

BcSmsTextNavItem.defaultProps = {
  checked: false,
  eventKey: '',
  messageText: '',
};

export default BcSmsTextNavItem;
