import React from 'react';

import PropTypes from 'prop-types';
import {Field as ReduxFormField} from 'redux-form';

import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

const Field = (props) => {
  const {show, withWrap, ...rest} = props;

  if (!show) {
    return null;
  }

  const field = <ReduxFormField {...rest} className={withWrap ? '' : rest.className || props.cssModifier} />;

  if (withWrap) {
    return <FormFieldWrap className={rest.className || props.cssModifier}>{field}</FormFieldWrap>;
  }

  return field;
};

Field.propTypes = {
  ...ReduxFormField.propTypes,
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.node, PropTypes.elementType]),
  withWrap: PropTypes.bool,
  show: PropTypes.bool,
};

Field.defaultProps = {
  ...ReduxFormField.defaultProps,
  className: '',
  component: fieldTemplate,
  withWrap: true,
  show: true,
};

export default Field;
