import React from 'react';

import {generatePath, useHistory, useParams} from 'react-router-dom';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons';
import {CLIENT_PAGES} from 'client/common/config';
import Icon from 'client/common/icon';
import TextareaInput from 'client/common/inputs/textarea-input';

import {getAnimationValidationStatus, getAnimationAnomalyStatus} from 'client/components/instore/helpers';
import {Animation} from 'client/models/animations/types';

import cssModule from './animation-anomalies-info.module.scss';

const b = bem('animation-anomalies-info', {cssModule});

const anomaliesPath =
  CLIENT_PAGES.CLIENTS +
  '/:clientId' +
  CLIENT_PAGES.OPERATIONS +
  '/:operationId' +
  CLIENT_PAGES.INSTORE_TASKS +
  '/:taskId' +
  CLIENT_PAGES.ANIMATIONS +
  '/:animationId' +
  CLIENT_PAGES.ANOMALIES;

type AnimationAnomaliesInfoProps = {
  animation: Animation;
};

const AnimationAnomaliesInfo: React.FC<AnimationAnomaliesInfoProps> = (props) => {
  const {animation} = props;

  const lang = useLanguage('ANIMATIONS.COMPONENTS.ANIMATION_ANOMALIES_INFO');
  const history = useHistory();
  const params = useParams();

  const handleAnomaliesClick = () => {
    history.push(generatePath(anomaliesPath, params));
  };

  if (!animation) {
    return null;
  }

  const iconNameHr = getAnimationValidationStatus(animation, 'hr_status');
  const iconNameInstallStatus = getAnimationValidationStatus(animation, 'install_status');
  const iconNameAnomaly = getAnimationAnomalyStatus(animation, 'animation_days');

  const statuses = [
    {
      prefix: 'hr',
      iconName: iconNameHr,
      caption: lang.HR,
    },
    {
      prefix: 'install',
      iconName: iconNameInstallStatus,
      caption: lang.INSTALL,
    },
    {
      prefix: 'anomaly',
      iconName: iconNameAnomaly,
      caption: lang.ANOMALIES,
    },
  ];

  const getComments = (comments = '') => {
    const newline = String.fromCharCode(13, 10);

    return comments?.replaceAll('\\r\\n' || '\\n', newline) || '';
  };

  return (
    <div className={b()}>
      <div className={b('header-info')}>
        {statuses.map(
          (status) =>
            status.iconName && (
              <AppButton
                key={status.prefix}
                className={b('status-button', [status.prefix])}
                label={
                  <div className={b('status', [status.prefix])}>
                    <Icon name={`${status.prefix}-${status.iconName}`} />
                    <span>{status.caption}</span>
                  </div>
                }
                onClick={handleAnomaliesClick}
                asWrap
              />
            ),
        )}
      </div>
      {animation?.comments && (
        <div className={b('comments')}>
          <TextareaInput
            readOnly
            name="animation.comments"
            label={lang.COMMENT}
            value={getComments(animation?.comments)}
            valueClassName={b('comments-value')}
          />
        </div>
      )}
    </div>
  );
};

export default AnimationAnomaliesInfo;
