import types from './types';

const initialState = {
  templates: [],
  activeTemplateItems: [],
  meta: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_TEMPLATES_STATE:
      return initialState;

    case types.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.payload.interface_templates,
        meta: action.payload.meta
          ? {
              ...action.payload.meta,
              total_count: action.payload.meta.total_count + 2,
            }
          : state.meta,
      };

    case types.GET_TEMPLATE_ITEMS_SUCCESS:
      return {
        ...state,
        activeTemplateItems: action.payload.interface_template_items,
      };

    default:
      return state;
  }
}
