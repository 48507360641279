import {RSAA} from 'redux-api-middleware';

import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

export const ADMINS_LIST_NO_DATA = 'ADMINS_LIST_NO_DATA';
export const ADMINS_LIST_LOADING = 'ADMINS_LIST_LOADING';
export const ADMINS_LIST_LOADED = 'ADMINS_LIST_LOADED';
export const ADMINS_LIST_LOAD_FAILED = 'ADMINS_LIST_LOAD_FAILED';

export const ADMINS_LIST_ADDING = 'ADMINS_LIST_ADDING';
export const ADMINS_LIST_ADMIN_ADDED = 'ADMINS_LIST_ADMIN_ADDED';
export const ADMINS_LIST_ADD_FAILED = 'ADMINS_LIST_ADD_FAILED';

export const ADMIN_DELETE_LOADING = 'ADMIN_DELETE_LOADING';
export const ADMIN_DELETE_SUCCESS = 'ADMIN_DELETE_SUCCESS';
export const ADMIN_DELETE_FAILED = 'ADMIN_DELETE_FAILED';

export const ADMIN_BLOCK_LOADING = 'ADMIN_BLOCK_LOADING';
export const ADMIN_BLOCK_SUCCESS = 'ADMIN_BLOCK_SUCCESS';
export const ADMIN_BLOCK_FAILED = 'ADMIN_BLOCK_FAILED';

export function getAdmins({page, perPage, query}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.ADMIN_USERS}`,
      method: 'GET',
      isAuth: true,
      types: [ADMINS_LIST_LOADING, ADMINS_LIST_LOADED, ADMINS_LIST_LOAD_FAILED],
      queryParams: {
        ...query,
        page,
        per_page: perPage,
      },
    });
}

export function addAdmin(data) {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.ADMIN_USERS}`,
        method: 'POST',
        body: JSON.stringify(data),
        isAuth: true,
        types: [ADMINS_LIST_ADDING, ADMINS_LIST_ADMIN_ADDED, ADMINS_LIST_ADD_FAILED],
      },
    });
  };
}

export function deleteAdmin(id) {
  return (dispatch) => {
    return dispatch({
      type: ADMIN_DELETE_SUCCESS,
      payload: {id},
    });
  };
}

export function blockAdmin(id, blocked = true) {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.ADMIN_USERS}/${id}`,
        method: 'PATCH',
        body: JSON.stringify({
          admin_user: {
            blocked,
            locked_by: blocked ? 'admin' : 'sign_in',
          },
        }),
        isAuth: true,
        types: [ADMIN_BLOCK_LOADING, ADMIN_BLOCK_SUCCESS, ADMIN_BLOCK_FAILED],
      },
    });
  };
}
