import React, {Component} from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getScenarioStepDescription} from 'client/services/helpers';

import {SCENARIO_STEP_NAMES} from 'client/common/config';

const NoCell = ({rowIndex}) => (
  <Cell>
    <div>{rowIndex + 1}</div>
  </Cell>
);

NoCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

const StepTypeCell = ({data, rowIndex, field}) => (
  <Cell>
    <div className="ellipsis-text">{SCENARIO_STEP_NAMES[get(data[rowIndex], field)]}</div>
  </Cell>
);

StepTypeCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

const InfoCell = ({data, scenarioVariables, rowIndex, field}) => (
  <Cell>
    <div className="ellipsis-text">
      {getScenarioStepDescription(get(data[rowIndex], field), scenarioVariables, data[rowIndex])}
    </div>
  </Cell>
);

InfoCell.propTypes = {
  data: PropTypes.array,
  scenarioVariables: PropTypes.array.isRequired,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

class AtsStepCardTable extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    scenarioVariables: PropTypes.array.isRequired,
    data: PropTypes.array,
  };

  static defaultProps = {
    data: [],
  };

  state = {
    showEditModal: false,
  };

  LANGUAGE = this.props.languageState.payload.AUTOTASK_SCENARIO.STEP_CARD_TABLE;

  handleClick = () => {
    this.setState({
      showEditModal: true,
    });
  };

  render() {
    const {scenarioVariables} = this.props;

    return (
      <div className="fixed-table fixed-table--scenarios-step-card fixed-table--expand-1">
        <Table
          rowsCount={this.props.data.length}
          headerHeight={40}
          rowHeight={34}
          width={230}
          height={298}
          {...this.props}
        >
          <Column
            header={<Cell>{this.LANGUAGE.NO_COLUMN}</Cell>}
            cell={<NoCell data={this.props.data} field="position" onClick={this.handleClick} />}
            width={50}
          />
          <Column
            header={<Cell>{this.LANGUAGE.STEP_TYPE_COLUMN}</Cell>}
            cell={<StepTypeCell data={this.props.data} field="implementation.type" />}
            width={130}
          />
          <Column
            header={<Cell>{this.LANGUAGE.INFO_COLUMN}</Cell>}
            cell={<InfoCell data={this.props.data} scenarioVariables={scenarioVariables} field="implementation" />}
            width={400}
          />
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    scenario: {
      payload: {scenario_variables: scenarioVariables, scenario_step_variables: scenarioStepVariables = []},
    },
    languageState,
  } = state;

  return {
    languageState,
    scenarioVariables: scenarioVariables.concat(scenarioStepVariables),
  };
};

export default connect(mapStateToProps)(AtsStepCardTable);
