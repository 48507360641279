import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';
import {API_URL} from 'client/common/config';

import types from './types';

export function getSSOAuthInfo() {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.AUCHAN_SSO_LOGIN}`,
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': API_URL,
      },
      types: [types.GET_AUTHINFO_REQUEST, types.GET_AUTHINFO_SUCCESS, types.GET_AUTHINFO_ERROR],
    });
}

export const setCallbackError = (callbackError) => {
  return {
    type: types.SET_CALLBACK_ERROR,
    payload: {callbackError},
  };
};
