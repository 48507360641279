import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {FieldArray} from 'redux-form';

import bem from 'client/services/bem';

import {CheckboxField, RadioGroupField, SelectField} from 'client/common/fields';

import {TranslationJsx} from 'client/models/language/types';

import {normalizeBoolean} from './helpers';

// re-use main form styles
// TODO: make own styles
import cssModule from './../../ats-step/ats-step.module.scss';

const b = bem('ats-step', {cssModule});

export default class OptIns extends Component {
  static propTypes = {
    checkOptIn: PropTypes.bool.isRequired,
    removeOptInFromMessageScenarioStep: PropTypes.func.isRequired,
    title: TranslationJsx,
    labels: PropTypes.object,
  };

  static defaultProps = {
    title: '',
    labels: {},
  };

  handleOptInChanged = (field, newValue, values) => {
    const {id, deleted} = values;

    if (newValue || !id || deleted) {
      return;
    }

    this.props.removeOptInFromMessageScenarioStep(id, field);
  };

  renderOptIn = (field, values) => {
    const {opt_in_column_id, name} = values;

    return (
      <CheckboxField
        key={opt_in_column_id}
        label={name}
        name={`${field}.active`}
        onChange={(e, value) => this.handleOptInChanged(field, value, values)}
        color="default"
      />
    );
  };

  renderOptIns = ({fields}) => {
    const renderedFields = fields.map((fieldName, index) => {
      return this.renderOptIn(fieldName, fields.get(index));
    });

    return <div className={b('checkboxes')}>{renderedFields}</div>;
  };

  render() {
    const {checkOptIn, title, labels} = this.props;

    return (
      <>
        <div className={b('form-row')}>
          <div className={b('form-title')}>{title}</div>
          <RadioGroupField
            color="default"
            direction="horizontal"
            name="implementation.check_opt_ins"
            normalize={normalizeBoolean}
            options={[
              {
                label: labels.yes,
                value: true,
              },
              {
                label: labels.no,
                value: false,
              },
            ]}
          />
        </div>
        {checkOptIn && (
          <>
            <div className={b('form-field-wrap', ['flex'])}>
              <div className={b('form-label', ['view-1'])}>{labels.satisfies}</div>
              <div className={b('form-field', ['size-5'])}>
                <SelectField
                  simpleValue
                  name="implementation.check_opt_ins_operator"
                  searchable={false}
                  options={[
                    {
                      label: labels.all,
                      value: 'all',
                    },
                    {
                      label: labels.any,
                      value: 'any',
                    },
                  ]}
                />
              </div>
              <div className={b('form-label')}>{labels.following}:</div>
            </div>
            <FieldArray name="optInsOptions" component={this.renderOptIns} />
          </>
        )}
      </>
    );
  }
}
