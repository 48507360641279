import {dateToString} from 'client/services/helpers';

import {DATE_FORMAT} from './constants';

const mapInitialValues = (game, prizeMaps) => {
  const prizeMap = prizeMaps && prizeMaps[0];
  const from = prizeMap ? prizeMap.from : game.default_from;
  const to = prizeMap ? prizeMap.to : game.default_to;
  let drawTime = prizeMap ? prizeMap.draw_time : game.default_draw_time;
  drawTime = drawTime || game.default_draw_time;

  return {
    name: game.name,
    game_type: game.game_type,
    validation: game.validation,
    default_from: dateToString(from, DATE_FORMAT),
    default_to: dateToString(to, DATE_FORMAT),
    default_draw_time: dateToString(drawTime, DATE_FORMAT),
    levels: game.game_validation_levels.map(({interface_level_id}) => interface_level_id).join(','),
  };
};

export default mapInitialValues;
