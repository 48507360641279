import React, {useCallback} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect, useDispatch} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {reduxForm, SubmissionError} from 'redux-form';

import {useQueryParams, useAppMedia} from 'client/services/hooks';

import {authenticate, register} from 'client/ducks/user/actions';
import {isFirstClientLogin} from 'client/ducks/user/helper';

import {CLIENT_PAGES, getAppLanguage} from 'client/common/config';

import validate, {warn} from './validate';

import AuthButton from '../../components/auth-button';
import AuthError from '../../components/auth-error';
import AuthField from '../../components/auth-field';

const AuthRegistrationForm = ({
  lang,
  handleSubmit,
  submitFailed,
  submitting,
  pristine,
  error,
  invitationToken,
  valid,
  history,
}) => {
  const dispatch = useDispatch();
  const [queryParams] = useQueryParams({history, location: history.location});
  const {isTablet} = useAppMedia();
  const {email} = queryParams;

  const signUp = useCallback(
    ({password, passwordConfirmation, rememberMe}) => {
      return dispatch(
        register(
          {
            user: {
              invitation_token: invitationToken,
              password,
              password_confirmation: passwordConfirmation,
              locale: getAppLanguage(),
            },
          },
          {user: {email, password, rememberMe}},
        ),
      ).then(({error: responseError, payload}) => {
        if (responseError) {
          if (payload?.response?.errors?.password?.includes('taken_in_past')) {
            throw new SubmissionError({_error: lang.PASSWORD_EXIST});
          } else {
            throw new SubmissionError({_error: lang.INVALID_TOKEN});
          }
        }
        dispatch(authenticate({user: {email, password, rememberMe}})).then((response) => {
          const user = get(response, 'payload.user');
          const path = isFirstClientLogin(user) ? CLIENT_PAGES.WELCOME : CLIENT_PAGES.HOME;
          history.push(path);
        });
      });
    },
    [email, dispatch, history, invitationToken, lang],
  );

  return (
    <form noValidate="noValidate" onSubmit={handleSubmit(signUp)}>
      {submitFailed && <AuthError>{error}</AuthError>}
      <AuthField name="email" type="text-input" value={email} label={lang.EMAIL_LABEL} email disabled />
      <AuthField
        name="password"
        type="password"
        helpOverlay={lang.TOOLTIP_PASSWORD}
        label={lang.PASSWORD_LABEL}
        placeholder={lang.PASSWORD_PLACEHOLDER}
      />
      <AuthField
        name="passwordConfirmation"
        type="password"
        helpOverlay={!isTablet ? lang.TOOLTIP_CONFIRMATION : ''}
        label={lang.CONFIRMATION_LABEL}
        placeholder={lang.CONFIRMATION_PLACEHOLDER}
      />
      <AuthField type="checkbox" additional name="rememberMe" label={lang.REMEMBER_ME_LABEL} />
      <AuthButton submit disabled={submitting || !valid || pristine} label={lang.SIGN_UP_BUTTON} />
    </form>
  );
};

AuthRegistrationForm.displayName = 'AuthRegistrationForm';
AuthRegistrationForm.formName = 'AuthRegistrationForm';

AuthRegistrationForm.propTypes = {
  history: PropTypes.object.isRequired,
  lang: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  pristine: PropTypes.bool,
  invitationToken: PropTypes.string,
  submitFailed: PropTypes.bool,
  error: PropTypes.any,
};

const registration = reduxForm({
  form: AuthRegistrationForm.formName,
  validate,
  warn,
})(withRouter(AuthRegistrationForm));

export default connect(({languageState}) => ({
  lang: languageState.payload.AUTH.REGISTRATION,
}))(registration);
