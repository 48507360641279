import {useMemo} from 'react';

import {useSelector} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {TEMPLATE_KIND_CATEGORIES} from 'client/models/email-templates/constants';
import {TemplateKindCategory} from 'client/models/email-templates/types';
import {Translation} from 'client/models/language/types';
import {GAME_TYPES} from 'client/models/prizes/constants';

type ResultItem = {
  label: Translation;
  options: {value: string; label: string}[];
};

type Result = {
  instantWin?: ResultItem;
  lottery?: ResultItem;
};

const prizeVariableNames = ['prize_name', 'code', 'description', 'loyalty_amount', 'loyalty_unit', 'token'];

const getVariableOptions = (addLoyalty: boolean) => {
  return prizeVariableNames
    .filter((name) => !name.startsWith('loyalty') || addLoyalty)
    .map((name) => ({value: name, label: name}));
};

const prizeVariables = prizeVariableNames.map((name) => `{{var:${name}}}`);

export const hasPrizeVariable = (text: string) => {
  return prizeVariables.some((name) => text.includes(name));
};

export default (category?: TemplateKindCategory) => {
  const lang = useLanguage('DIY_OPERATION.MODALS.CONFIGURATION_MODAL.TEXT_VARIABLE_FAMILIES');
  const autotask = useSelector(selectAutotask);
  const client = useSelector(selectCurrentClient);
  const isAdmin = useSelector(selectIsAdmin);

  const variables = useMemo(() => {
    const iwGame = autotask?.client_games?.find((i) => i.game_type === GAME_TYPES.INSTANT_WIN);
    const lotteryGame = autotask?.client_games?.find((i) => i.game_type === GAME_TYPES.PRIZE_DRAW);

    const hasInstantWin = category === TEMPLATE_KIND_CATEGORIES.INSTANT_WIN && (isAdmin || iwGame?.prizes_count);
    const hasLottery = category === TEMPLATE_KIND_CATEGORIES.LOTTERY && (isAdmin || lotteryGame?.prizes_count);

    const options = getVariableOptions(isAdmin || !!client?.loyalty_program_id);

    const result: Result = {};

    if (hasInstantWin) {
      result.instantWin = {
        label: lang.INSTANT_WIN,
        options,
      };
    }

    if (hasLottery) {
      result.lottery = {
        label: lang.LOTTERY,
        options,
      };
    }

    return result;
  }, [autotask?.client_games, category, client?.loyalty_program_id, isAdmin, lang.INSTANT_WIN, lang.LOTTERY]);

  return variables;
};
