export const mapClientDiagrams = (data = []) => {
  return data.map((item) => ({...item, loading: true}));
};

export const replaceDiagram = (diagrams, diagram) => {
  if (!diagram) {
    return diagrams;
  }

  return diagrams.map((item) => (item.id === diagram.id ? diagram : item));
};
