import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Icon from 'client/components/common/icon';
import LoadingSpinner from 'client/components/common/loading-spinner';

import {TranslationJsx} from 'client/models/language/types';
import {ReactNode} from 'client/types';

import './app-button.scss';

const b = bem('app-button');

/**
 * AppButton - button component
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<AppButton.propTypes> & React.RefAttributes<HTMLButtonElement>>}
 */
const AppButton = React.forwardRef((props, ref) => {
  const {
    className,
    classNameIcon,
    submit,
    label,
    onClick,
    color,
    nativeStyle,
    iconName,
    transparent,
    outline,
    disabled,
    link,
    rounded,
    size,
    fontSize,
    loading,
    iconConfig,
    asWrap,
    id,
    disabledOpacity,
    ...buttonProps
  } = props;

  const type = submit ? 'submit' : 'button';

  return (
    <button
      type={type}
      id={id}
      ref={ref}
      className={cn(
        b({
          [color]: true,
          [size]: true,
          [`font-size-${fontSize}`]: true,
          ['disabled-opacity']: disabledOpacity,
          'has-label': !!label,
          'as-wrap': asWrap,
          'not-as-wrap': !(nativeStyle || asWrap),
          transparent,
          outline,
          disabled,
          link,
          rounded,
          loading,
        }),
        className,
      )}
      disabled={disabled}
      onClick={loading ? () => {} : onClick}
      {...buttonProps}
    >
      {(iconName || iconConfig?.name) && (
        <Icon name={iconName} className={cn(b('icon', {'has-label': !!label}), classNameIcon)} {...iconConfig} />
      )}
      {label}
      {loading && (
        <div className={b('loader')}>
          <LoadingSpinner loading />
        </div>
      )}
    </button>
  );
});

AppButton.displayName = 'AppButton';

export const ButtonColors = [
  'primary',
  'secondary',
  'visuals',
  'visits',
  'leads',
  'participants',
  'games',
  'devices',
  'clients',
  'light-clients',
  'error',
  'campaigns',
  'text-normal',
  'header-link',
  'users',
  'text-additional',
  'white',
  'scenario',
  'coupons',
];
export const ButtonSizes = ['extra-large', 'large', 'medium', 'small', 'extra-small', 'default', 'full'];

export const ButtonColorType = PropTypes.oneOf(ButtonColors);
export const ButtonSizeType = PropTypes.oneOf(ButtonSizes);
export const ButtonFontSizeType = PropTypes.oneOf(ButtonSizes);

export const AppButtonProps = {
  label: PropTypes.oneOfType([TranslationJsx, ReactNode]),
  className: PropTypes.string,
  classNameIcon: PropTypes.string,
  submit: PropTypes.bool,
  nativeStyle: PropTypes.bool,
  onClick: PropTypes.func,
  // can be extended with color variable names from ui-kit
  color: ButtonColorType,
  iconName: PropTypes.string,
  link: PropTypes.bool,
  transparent: PropTypes.bool,
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
  loading: PropTypes.bool,
  size: ButtonSizeType,
  fontSize: ButtonFontSizeType,
  iconConfig: PropTypes.shape({
    name: PropTypes.string,
    title: TranslationJsx,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    budget: PropTypes.bool,
  }),
  asWrap: PropTypes.bool,
  id: PropTypes.string,
  disabledOpacity: PropTypes.bool,
};

AppButton.propTypes = AppButtonProps;

AppButton.defaultProps = {
  label: '',
  iconName: '',
  className: '',
  classNameIcon: '',
  color: 'clients',
  submit: false,
  nativeStyle: false,
  transparent: false,
  outline: false,
  disabled: false,
  link: false,
  rounded: false,
  loading: false,
  size: 'default',
  fontSize: 'default',
  onClick: null,
  iconConfig: {},
  asWrap: false,
  id: null,
};

export default AppButton;
