import React from 'react';

import {Field} from 'redux-form';

import BaseFieldComponent from 'client/common/fields/base-field';
import DateTimeListInput from 'client/common/inputs/datetime-list-input';

const InputComponent = (props) => <BaseFieldComponent Component={DateTimeListInput} {...props} />;

const DateTimeListField = (props) => {
  return <Field component={InputComponent} {...props} />;
};

export default DateTimeListField;
