import React from 'react';

import bem from 'client/services/bem';

import Skeleton from 'client/common/various/skeleton';

import cssModule from './content-skeleton.module.scss';

const b = bem('content-skeleton', {cssModule});

const ContentSkeleton: React.FC = () => {
  return (
    <div className={b()}>
      <div className={b('layout')}>
        <Skeleton className={b('item')} />
        <Skeleton className={b('item')} />
        <Skeleton className={b('item')} />
      </div>
      <div className={b('layout')}>
        <Skeleton className={b('item')} />
        <Skeleton className={b('item')} />
      </div>
      <div className={b('layout')}>
        <Skeleton className={b('item')} />
        <Skeleton className={b('item')} />
        <Skeleton className={b('item')} />
        <Skeleton className={b('item')} />
      </div>
    </div>
  );
};

export default ContentSkeleton;
