import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';
import {downloadFileWithAuth} from 'client/services/helpers';

import {deleteLeadsExportFile} from 'client/ducks/leads-export/actions';
import {getLeadsTransferReports} from 'client/ducks/leads-transfers/actions';
import {selectLeadsTransferReportsMapped} from 'client/ducks/leads-transfers/selectors';

import AppButton from 'client/common/buttons/app-button';
import Icon from 'client/common/icon';

import ClientTable from 'client/components/common/client-table';

const b = bem('leads-parameters');

class ExportFilesTable extends Component {
  static propTypes = {
    language: PropTypes.object,
    clientId: PropTypes.number,
    fileReportsColumns: PropTypes.shape({
      items: PropTypes.array,
    }),
    fileExportHistory: PropTypes.array,
    deleteLeadsExportFile: PropTypes.func.isRequired,
    getLeadsTransferReports: PropTypes.func.isRequired,
  };

  static defaultProps = {
    fileReportsColumns: {
      items: [],
    },
  };

  state = {
    loading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
    const {language} = props;
    this.fileReportsColumns = [
      {
        name: 'code',
        label: language.NAME,
        path: 'name',
      },
      {
        name: 'date',
        label: language.DATE,
        path: 'createdAt',
      },
      {
        name: 'participations',
        label: language.LEADS,
        align: 'right',
        path: 'leadsCount',
      },
      {
        name: 'comments',
        label: language.COMMENTS,
        align: 'center',
        hintRender: this.renderCommentHint,
        render: this.renderComment,
      },
    ];
  }

  componentDidMount() {
    this.fetchData();
  }

  toggleLoading = () => this.setState(({loading}) => ({loading: !loading}));

  fetchData = () => {
    const params = {
      include: {
        user: null,
        lead_transfer: null,
        memberships: {
          client_user: null,
        },
        client_user: null,
      },
      q: {
        lead_transfer_client_id_eq: this.props.clientId,
        s: 'created_at desc',
      },
    };
    this.toggleLoading();
    this.props.getLeadsTransferReports(params).then(this.toggleLoading);
  };

  downloadExportFile = (fileUrl) => {
    downloadFileWithAuth(fileUrl);
  };

  deleteExportFile = async (transferId) => {
    await this.props.deleteLeadsExportFile(transferId);
    this.fetchData();
  };

  renderSummary = () => <Icon name="summary-1" className={b('table-icon')} />;

  renderSummaryHint = (row) => {
    const {createdAt, allowed_for_all, leadsCount, memberships, id, file, status = ''} = row;
    const {language} = this.props;
    return (
      <div className={b(['export-files-popover'])}>
        <h4 className={b('popover-title')}>{language.SUMMARY}</h4>
        <div className={b('row')}>
          <div className={b('row-text-cell')}>{language.CREATED}</div>
          <div className={b('row-value')}>{createdAt}</div>
        </div>
        <div className={b('row')}>
          <div className={b('row-text-cell')}>{language.TOTAL}</div>
          <div className={b('row-value')}>{leadsCount}</div>
        </div>
        <div className={b('row')}>
          <div className={b('row-text-cell')}>{language.STATUS}</div>
          <div className={cn('bold', b('icon-leads'))}>{language[`STATUS_${status.toUpperCase()}`]}</div>
        </div>
        <div className={b('row')}>
          <span className={b('row-text-cell')}>{language.USERS_ALLOWED}</span>
          <span className={b('row-value')}>
            {allowed_for_all
              ? language.ALL
              : memberships.map((item) => (
                  <div key={item.client_user?.id}>{item.client_user ? item.client_user.full_name : ''}</div>
                ))}
          </span>
        </div>
        {file?.url?.length > 0 && (
          <>
            <div className={b('row')}>
              <span className={b('row-text-cell')}>{language.DOWNLOAD_FILE}</span>
              <span className={b('row-btn-cell')}>
                <AppButton
                  className={b('file-btn')}
                  color="leads"
                  iconName="download"
                  transparent={true}
                  onClick={() => this.downloadExportFile(file.url)}
                />
              </span>
            </div>
            <div className={b('row')}>
              <span className={b('row-text-cell')}>{language.DELETE_FILE}</span>
              <span className={b('row-btn-cell')}>
                <AppButton
                  className={b('file-btn')}
                  color="leads"
                  iconName="trash"
                  transparent={true}
                  width={17}
                  height={19}
                  onClick={() => this.deleteExportFile(id)}
                />
              </span>
            </div>
          </>
        )}
      </div>
    );
  };

  renderCommentHint = ({comment}) => (
    <div>
      <div className={cn('bold', b('icon-leads'))}>{this.props.language.COMMENTS}</div>
      <p>{comment}</p>
    </div>
  );

  renderComment = ({item}) => {
    return item.comment ? <Icon name="comments" className={b('table-icon')} /> : null;
  };

  render() {
    const {language, fileExportHistory} = this.props;
    const {fileReportsColumns} = this;
    const {loading} = this.state;

    return (
      <ClientTable
        className={b('export-table')}
        columns={fileReportsColumns}
        data={fileExportHistory}
        noDataText={language.NO_EXPORT_FILES_DATA}
        classNames={{
          icon: b('icon-leads'),
        }}
        loading={loading}
      />
    );
  }
}

export default connect(
  ({languageState, ...state}) => ({
    language: languageState.payload.LEADS_PARAMETERS.EXPORT_FILES_TABLE,
    fileExportHistory: selectLeadsTransferReportsMapped(state),
  }),
  {
    deleteLeadsExportFile,
    getLeadsTransferReports,
  },
)(ExportFilesTable);
