import React from 'react';

import PropTypes from 'prop-types';

function BCHeader(props) {
  const {headers, taskType} = props;

  return (
    <div className="content-panel__header bc-list__header">
      {headers
        .filter((item) => item.forTaskType.includes(taskType))
        .map((header, key) => (
          <div className="bc-list__col" key={key}>
            {header.name}
          </div>
        ))}
    </div>
  );
}

BCHeader.propTypes = {
  taskType: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      forTaskType: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
};

export default BCHeader;
