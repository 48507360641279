import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {useLanguage} from 'client/services/hooks';

import {set} from 'client/common/meta/meta.actions';

import AgencyClientsList from 'client/components/agencies/lists/agency-clients-list';

const AgencyClientsPage = () => {
  const lang = useLanguage('NAVIGATION');
  const dispatch = useDispatch();
  const params: {clientId: string} = useParams();

  useEffect(() => {
    dispatch(set({title: lang.CLIENTS}));
  }, [dispatch, lang.CLIENTS]);

  return <AgencyClientsList agencyId={Number(params.clientId)} />;
};

export default AgencyClientsPage;
