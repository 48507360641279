import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Icon from 'client/components/common/icon';

import LeadsGenerateApiExportModal from 'client/components/leads/components/modals/leads-generate-api-export-modal';

import './leads-card-actions.scss';

class LeadsCardActions extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    leadsTotalCount: PropTypes.number,
    clientId: PropTypes.number,
    onImportFile: PropTypes.func.isRequired,
    onImportApi: PropTypes.func.isRequired,
    columnAdapters: PropTypes.array.isRequired,
    clientUsers: PropTypes.array.isRequired,
    refresh: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AGENCY.LEADS_CARD;

    this.state = {
      showCreateApiExportModal: false,
    };
  }

  toggleCreateApiExportModal = () => {
    this.setState(({showCreateApiExportModal}) => {
      return {showCreateApiExportModal: !showCreateApiExportModal};
    });
  };

  onCreateApiExport = () => {
    this.props.refresh();
    this.toggleCreateApiExportModal();
  };

  renderModals = () => {
    const {clientId, columnAdapters, clientUsers} = this.props;

    if (this.state.showCreateApiExportModal) {
      return (
        <LeadsGenerateApiExportModal
          show={true}
          onClose={this.onCreateApiExport}
          onCancel={this.toggleCreateApiExportModal}
          clientId={clientId}
          columns={columnAdapters}
          users={clientUsers}
        />
      );
    }

    return null;
  };

  render() {
    const {leadsTotalCount, onImportFile, onImportApi} = this.props;

    return (
      <div className="leads-card-actions">
        {this.renderModals()}
        <div className="port-button">
          <button className="button button--bg-2" onClick={onImportFile}>
            <Icon name="import" className="button__icon" />
            <span>{this.LANGUAGE.IMPORT_FILE_BUTTON}</span>
          </button>
        </div>
        <div className="port-button">
          <button className="button button--bg-2" onClick={onImportApi}>
            <Icon name="import" className="button__icon" />
            <span>{this.LANGUAGE.IMPORT_API_BUTTON}</span>
          </button>
        </div>
        {leadsTotalCount > 0 && (
          <div className="port-button">
            <button className="button button--bg-2" onClick={this.toggleCreateApiExportModal}>
              <Icon name="export" className="button__icon" />
              <span>{this.LANGUAGE.EXPORT_API_BUTTON}</span>
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(LeadsCardActions);
