import get from 'lodash/get';

import {mapClients} from './helpers';

export function selectClients(state) {
  return get(state, 'clientsList.payload.data');
}

/**
 * @param state
 * @returns {import('client/models/client/types').Client}
 */
export function selectCurrentClient(state) {
  return get(state, 'clientsList.client');
}

export function selectClientsCount(state) {
  const meta = selectClientsMeta(state) || {};

  return meta.total_count || 0;
}

export function selectMyClientsCount(state) {
  const meta = selectMyClientsMeta(state) || {};

  return meta.total_count || 0;
}

export function selectClientsMapped(state) {
  return mapClients(selectClients(state));
}

export function selectClientsMeta(state) {
  return get(state, 'clientsList.payload.meta');
}

export function selectMyClientsMeta(state) {
  return get(state, 'clientsList.metaMyClients');
}
