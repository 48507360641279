import React from 'react';

import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';
import DatepickerField from 'client/common/fields/datepicker-field';
import SelectField from 'client/common/fields/select-field';
import TextField from 'client/common/fields/text-field';

import {PrizeDiyModalFormName} from 'client/components/prizes/modals/prize-diy-modal';
import {COUPON_TOKEN_MODES} from 'client/models/prizes/constants';
import {CouponCodeInterval} from 'client/models/prizes/types';

import cssModule from './prize-diy-common-coupon-fieldset.module.scss';

const b = bem('prize-diy-common-coupon-fieldset', {cssModule});

type PrizeDiyCommonCouponFieldsetProps = {
  disabled: boolean;
};

const DATE_FORMAT = 'DD/MM/YYYY';
const getDisabledDayBefore = (date?: string) => date && moment(date, DATE_FORMAT).add(1, 'day').toISOString();

const PrizeDiyCommonCouponFieldset: React.FC<PrizeDiyCommonCouponFieldsetProps> = (props) => {
  const {disabled} = props;
  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_COMMON_COUPON_FIELDSET');

  const {formValues, change} = useReduxForm(PrizeDiyModalFormName);

  const intervals = formValues.coupon.coupon_code_intervals;
  const lastInt = intervals.slice(-1)[0];
  const showAddButton = !disabled && (!lastInt || Boolean(lastInt?.code?.trim()));

  const handleAddPeriod = () => {
    const codes = intervals;
    const from = lastInt && moment(lastInt.to, DATE_FORMAT).add(1, 'day').format(DATE_FORMAT);
    change('coupon.coupon_code_intervals', [...codes, {from, to: '', code: ''}]);
  };

  const handleChangeFrom = (value: string, index: number) => {
    const interval = intervals[index];
    if (!value || (interval.to && moment(value, DATE_FORMAT).isAfter(moment(interval.to, DATE_FORMAT)))) {
      change(`coupon.coupon_code_intervals[${index}].to`, '');
    }
  };

  const handleRemovePeriod = (index: number) => {
    const nextInts: CouponCodeInterval[] = cloneDeep(intervals);
    nextInts.splice(index, 1);
    change('coupon.coupon_code_intervals', nextInts);
  };

  return (
    <div className={b()}>
      <p className={b('title')}>{lang.CODE}</p>
      <div className={b('row')}>
        <SelectField
          className={b('mode-field')}
          name="coupon.token_mode"
          options={[
            {value: COUPON_TOKEN_MODES.PERMANENT, label: lang.PERMANENT},
            {value: COUPON_TOKEN_MODES.MOMENT_OF_WINNING, label: lang.MOMENT_OF_WINNING},
            {value: COUPON_TOKEN_MODES.MOMENT_OF_USING, label: lang.MOMENT_OF_USING},
          ]}
          disabled={disabled}
          simpleValue
        />
        {formValues.coupon.token_mode === COUPON_TOKEN_MODES.PERMANENT && (
          <TextField className={b('permanent-code-field')} name="coupon.token_code" disabled={disabled} />
        )}
      </div>
      {formValues.coupon.token_mode !== COUPON_TOKEN_MODES.PERMANENT && (
        <>
          {intervals.map((item: CouponCodeInterval, index: number, arr: CouponCodeInterval[]) => (
            <div key={index} className={b('row')}>
              <DatepickerField
                className={b('date-field')}
                name={`coupon.coupon_code_intervals[${index}].from`}
                label={lang.FROM}
                value={item.from}
                onChange={(value: string) => handleChangeFrom(value, index)}
                disabledDayBefore={index !== 0 ? getDisabledDayBefore(arr[index - 1].to) : ''}
                disabled={disabled || index < arr.length - 1}
              />
              <DatepickerField
                className={b('date-field')}
                name={`coupon.coupon_code_intervals[${index}].to`}
                label={lang.TO}
                disabledDayBefore={intervals[index].from}
                disabled={disabled || !intervals[index].from || index < arr.length - 1}
              />
              <TextField
                name={`coupon.coupon_code_intervals[${index}].code`}
                label={lang.CODE}
                inputHeight="small"
                disabled={disabled || !intervals[index].to || index < arr.length - 1}
              />
              <div className={b('remove')}>
                {index === arr.length - 1 && index !== 0 && (
                  <AppButton transparent iconName="trash" onClick={() => handleRemovePeriod(index)} />
                )}
              </div>
            </div>
          ))}
          {showAddButton && (
            <AppButton
              onClick={handleAddPeriod}
              className={b('add-button')}
              iconConfig={{
                className: b('plus-icon'),
                name: 'plus-simple',
                width: 25,
                height: 25,
              }}
              label={lang.ADD_NEW_PERIOD}
              color="primary"
              transparent
            />
          )}
        </>
      )}
    </div>
  );
};

export default PrizeDiyCommonCouponFieldset;
