import React from 'react';

import PropTypes from 'prop-types';

import InfoBlock from 'client/components/common/info-block';

import {TranslationJsx} from 'client/models/language/types';

import './ats-table-card.scss';

function AtsLinkedCard({title, header, children}) {
  return (
    <InfoBlock className="ats-table-card">
      <InfoBlock.Title>{title}</InfoBlock.Title>
      <InfoBlock.Content>
        <InfoBlock.Header>{header}</InfoBlock.Header>
        <InfoBlock.Body className="ats-table-card__body">{children}</InfoBlock.Body>
      </InfoBlock.Content>
    </InfoBlock>
  );
}

AtsLinkedCard.defaultProps = {
  children: '',
  title: '',
  header: '',
};

AtsLinkedCard.propTypes = {
  children: PropTypes.any,
  title: TranslationJsx,
  header: PropTypes.any,
};

export default AtsLinkedCard;
