import React from 'react';

import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import RadioButtonGroup from 'client/common/button-group/radio-button-group';
import BaseFieldComponent from 'client/common/fields/base-field';

const InputComponent = (props) => {
  return (
    <BaseFieldComponent
      Component={RadioButtonGroup}
      fieldsetValue={props.input.value}
      name={props.input.name}
      {...props}
    />
  );
};

InputComponent.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

const RadioButtonGroupField = (props) => {
  return <Field component={InputComponent} {...props} />;
};

export default RadioButtonGroupField;
