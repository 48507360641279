import React, {useCallback, useState} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {uploadGlobalMediaFile, uploadLocalMediaFile, getMedia, deleteMedia} from 'client/ducks/medias/actions';

import FileButton from 'client/components/common/controls/file-button';
import Icon from 'client/components/common/icon';
import LoadingSpinner from 'client/components/common/loading-spinner';

import './medias-add-button.scss';

const b = bem('medias-add-button');

const MAX_SIZE = 1073741824; // 1 Gigabyte to Bytes

const ACCEPT_EXTENSIONS = '.jpeg,.jpg,.png,.tiff,.gif,.avi,.mov,.wmv,.mpeg4,.mp4,.webm';

const MediasAddButton = (props) => {
  const {onChange, className, device, affectationId} = props;
  const lang = useLanguage('MEDIAS');
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleError = useCallback(() => {
    setError(lang.ERROR);
    setIsLoading(false);
  }, [lang]);

  const handlerOnChange = useCallback(
    async (file) => {
      setError('');

      if (file.size > MAX_SIZE) {
        handleError();
        return;
      }

      setIsLoading(true);

      const uploadResponse = await dispatch(
        device ? uploadLocalMediaFile(file, affectationId) : uploadGlobalMediaFile(file),
      );

      if (uploadResponse.errors) {
        handleError();
        return;
      }

      const mediaId = uploadResponse[device ? 'local_media_file' : 'global_media_file'].id;

      let processedMedia = null;

      while (!processedMedia) {
        await new Promise((r) => setTimeout(r, 3000));
        const mediaResponse = await dispatch(getMedia(mediaId, !!device));
        const mediaData = mediaResponse.payload[device ? 'local_media_file' : 'global_media_file'];

        if (mediaData.analyzed) {
          if (!mediaData.resolution_valid) {
            await dispatch(deleteMedia(mediaId));
            handleError();
            return;
          }

          const {default_media_configuration, ...data} = mediaData;
          processedMedia = data;
          processedMedia.active = default_media_configuration.active;
          processedMedia.duration = default_media_configuration.duration;
          processedMedia.loops = default_media_configuration.loops_number;
        }
      }

      setIsLoading(false);

      onChange(processedMedia);
    },
    [dispatch, device, affectationId, onChange, handleError],
  );

  return (
    <FileButton
      className={cn(b({loading: isLoading}), className)}
      label={
        <React.Fragment>
          <Icon name="plus-simple" className={b('icon')} />
          <span className={b('text')}>{lang.ADD_BUTTON}</span>
          {isLoading && (
            <div className={b('spinner-wrap')}>
              <LoadingSpinner cssModifier={b('spinner')} loading={true} />
            </div>
          )}
        </React.Fragment>
      }
      errorMessage={error}
      onChange={handlerOnChange}
      accept={ACCEPT_EXTENSIONS}
      disabled={isLoading}
    />
  );
};

MediasAddButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  affectationId: PropTypes.number,
  device: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

MediasAddButton.defaultProps = {
  className: '',
  device: null,
  affectationId: null,
};

export default MediasAddButton;
