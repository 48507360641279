import React, {useEffect, useMemo} from 'react';

import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {useLanguage} from 'client/services/hooks/useLanguage';

import {set as setMetaAction} from 'client/common/meta/meta.actions';

import AuthWrapper from 'client/components/auth/components/auth-wrapper';

import {CLIENT_PAGES} from '../common/config';
import AuthChangePassword from '../components/auth/auth-change-password';
import AuthDoubleFactor from '../components/auth/auth-double-factor';
import AuthLogin from '../components/auth/auth-login';
import AuthPasswordRecovery from '../components/auth/auth-password-recovery';
import AuthRegistration from '../components/auth/auth-registration';

const Auth = () => {
  const lang = useLanguage('AUTH');

  const dispatch = useDispatch();
  const {state, pathname} = useLocation();

  const title = useMemo(() => {
    switch (pathname) {
      case CLIENT_PAGES.LOGIN:
        return lang.LOGIN_TITLE;
      case CLIENT_PAGES.LOGIN_AUCHAN:
        return lang.LOGIN_TITLE;
      case CLIENT_PAGES.DOUBLE_FACTOR_AUTH:
        return lang.DOUBLE_FACTOR_AUTHENTIFICATION_TITLE;
      case CLIENT_PAGES.SIGN_UP:
        return state?.expired ? lang.DEACTIVATED_LINK_TITLE : lang.REGISTRATION_TITLE;
      case CLIENT_PAGES.PASSWORD_RECOVERY:
        return lang.PASSWORD_RECOVERY_TITLE;
      case CLIENT_PAGES.PASSWORD_CHANGE:
        return lang.CHANGE_PASSWORD_TITLE;
      default:
        return lang.LOGIN_TITLE;
    }
  }, [lang, pathname, state?.expired]);

  const popover = useMemo(() => {
    switch (pathname) {
      case CLIENT_PAGES.LOGIN:
        return lang.LOGIN.POPOVER;
      case CLIENT_PAGES.LOGIN_AUCHAN:
        return lang.LOGIN.POPOVER;
      case CLIENT_PAGES.DOUBLE_FACTOR_AUTH:
        return lang.DOUBLE_FACTOR_AUTHENTIFICATION.POPOVER;
      case CLIENT_PAGES.SIGN_UP:
        return lang.REGISTRATION.POPOVER;
      case CLIENT_PAGES.PASSWORD_RECOVERY:
        return lang.PASSWORD_RECOVERY.POPOVER;
      default:
        return '';
    }
  }, [lang, pathname]);

  const content = useMemo(() => {
    switch (pathname) {
      case CLIENT_PAGES.LOGIN:
        return <AuthLogin />;
      case CLIENT_PAGES.LOGIN_AUCHAN:
        return <AuthLogin authWithSSO />;
      case CLIENT_PAGES.SIGN_UP:
        return <AuthRegistration />;
      case CLIENT_PAGES.PASSWORD_CHANGE:
        return <AuthChangePassword />;
      case CLIENT_PAGES.PASSWORD_RECOVERY:
        return <AuthPasswordRecovery />;
      case CLIENT_PAGES.DOUBLE_FACTOR_AUTH:
        return <AuthDoubleFactor />;
      default:
        return <></>;
    }
  }, [pathname]);

  useEffect(() => {
    dispatch(setMetaAction({title}));
  }, [dispatch, title]);

  return (
    <AuthWrapper title={title} popover={popover}>
      {content}
    </AuthWrapper>
  );
};

Auth.displayName = 'Auth';

export default Auth;
