import moment from 'moment';

const fields = [
  'kind',
  'source_id',
  'name',
  'comment',
  'url',
  'parameter1',
  'parameter2',
  'parameter3',
  'region_id',
  'store_id',
  'from',
  'to',
  'active',
];

const defaultValues = {
  fromTime: '00:00:00',
  toTime: '23:59:59',
};

export default (onlineInteraction = {}) => {
  return Object.entries(onlineInteraction).reduce(
    (formValues, [key, value]) => {
      if (fields.includes(key)) {
        if (['from', 'to'].includes(key)) {
          formValues[key] = value && moment(value).format('DD/MM/YYYY');
          formValues[`${key}Time`] = value ? moment(value).format('HH:mm:ss') : defaultValues[`${key}Time`];
        } else {
          formValues[key] = value;
        }
      }
      return formValues;
    },
    {...defaultValues},
  );
};
