import {useCallback, useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {getAnimation, updateAnimationStatus} from 'client/ducks/animations/actions';
import {selectAnimation} from 'client/ducks/animations/selectors';
import {updateInstoreTaskAnimation} from 'client/ducks/instore-tasks/actions';

export const useGetAnimation = (animationId, campaignId) => {
  const [loading, setLoading] = useState(true);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const dispatch = useDispatch();

  const animation = useSelector(selectAnimation);

  const fetchAnimation = useCallback(async () => {
    if (campaignId && animationId) {
      setLoading(true);
      await dispatch(
        getAnimation(animationId, {
          q: {
            interaction_group_id_eq: campaignId,
          },
          include: {
            kit_mapping: true,
            kit_affectation: true,
            place: {network: true},
            membership: {client_user: true},
            instore_task_access: {
              membership: true,
              kit_mappings: {
                kit: null,
              },
            },
            animation_days: {anomaly_occurrences: true},
            animation_data: {
              task_product: null,
            },
          },
          distinct: true,
        }),
      );
      setLoading(false);
    }
  }, [campaignId, animationId, dispatch]);

  const changeStatus = useCallback(
    async (status) => {
      if (animationId) {
        setIsLoadingStatus(true);
        await dispatch(updateAnimationStatus(animationId, status));
        setIsLoadingStatus(false);
      }
    },
    [animationId, dispatch],
  );

  const saveAnimation = useCallback(
    async (body) => {
      if (animationId) {
        await dispatch(updateInstoreTaskAnimation(animationId, body));
      }
    },
    [animationId, dispatch],
  );

  useEffect(() => {
    fetchAnimation();
  }, [fetchAnimation]);

  return {
    loading,
    loadingSave: isLoadingStatus,
    animation,
    fetchAnimation,
    changeStatus,
    saveAnimation,
  };
};
