import React, {Component} from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, change} from 'redux-form';

import Modal from 'client/common/modals/modal';
import Popover from 'client/common/popovers/popover';

import fieldTemplate from 'client/components/common/field';

let LANGUAGE = {};
let codeIsNew = [true];
let logisticIdIsNew = [true];
let lastCode = '';
let lastLogisticId = '';

class AddNewDeviceModal extends Component {
  static validate({name, code, type, logistic_id, status}) {
    const errors = {};

    if (!name || !name.trim()) {
      errors.name = LANGUAGE.NAME_IS_REQUIRED_ERROR;
    }
    if (!code || !code.trim()) {
      errors.code = LANGUAGE.CODE_IS_REQUIRED_ERROR;
    }
    if (!type || !type.id) {
      errors.type = LANGUAGE.TYPE_IS_REQUIRED_ERROR;
    }
    if (!logistic_id || !logistic_id.trim()) {
      errors.logistic_id = LANGUAGE.LOGISTIC_ID_IS_REQUIRED_ERROR;
    }
    if (!status || !status.value) {
      errors.status = LANGUAGE.STATUS_IS_REQUIRED_ERROR;
    }

    if (!codeIsNew[0] && code === lastCode) {
      errors.code = LANGUAGE.CODE_EXISTS;
    } else if (!codeIsNew[0]) {
      codeIsNew[0] = true;
    }
    if (!logisticIdIsNew[0] && logistic_id === lastLogisticId) {
      errors.logistic_id = LANGUAGE.LOGISTIC_ID_EXISTS;
    } else if (!logisticIdIsNew[0]) {
      logisticIdIsNew[0] = true;
    }

    return errors;
  }

  static updateSyncErrors(values) {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'AddNewDeviceForm',
      },
      payload: {
        syncErrors: AddNewDeviceModal.validate(values),
      },
    };
  }

  constructor(props) {
    super(props);

    LANGUAGE = this.LANGUAGE = this.props.languageState.payload.DEVICES;

    this.save = this.save.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.intervalID = setInterval(() => {
      if (!isEmpty(LANGUAGE)) {
        clearInterval(this.intervalID);
        this.props.updateErrors({});
      }
    }, 200);
  }

  componentDidUpdate(prevProps) {
    const {initialValues, show, types, formValues, isCodeNew, isLogisticIdNew} = this.props;
    const {values: {code: oldCode, logistic_id: oldLogisticID} = {}} = prevProps.formValues || {};
    const {values: {code: newCode, logistic_id: newLogisticID} = {}} = formValues || {};

    const init = initialValues.name
      ? initialValues
      : {
          status: {
            value: 'in_use',
            label: this.LANGUAGE.DEVICE_STATUSES.in_use,
          },
          type: types[0],
        };

    if (initialValues !== prevProps.initialValues || (show && !prevProps.show)) {
      this.props.initialize(init);
      this.props.updateErrors(init);
    }

    if (isCodeNew !== prevProps.isCodeNew) {
      lastCode = formValues.values && formValues.values.code;
      this.props.updateErrors(formValues.values || init);
    }

    if (isLogisticIdNew !== prevProps.isLogisticIdNew) {
      lastLogisticId = formValues.values && formValues.values.logistic_id;
      this.props.updateErrors(formValues.values || init);
    }

    if (newCode && oldCode !== newCode) {
      const isValidCode = /^[A-Z0-9]*$/.test(newCode) && newCode.length <= 16;
      this.props.changeFieldValue('AddNewDeviceForm', 'code', isValidCode ? newCode.toUpperCase() : oldCode);
    }

    if (newLogisticID && oldLogisticID !== newLogisticID) {
      const isValidLogisticID = /^[A-Za-z0-9]*$/.test(newLogisticID) && newLogisticID.length <= 16;
      this.props.changeFieldValue('AddNewDeviceForm', 'logistic_id', isValidLogisticID ? newLogisticID : oldLogisticID);
    }
  }

  save() {}

  handleCancel() {
    this.props.onCancel();
  }

  handleCreate({name, type, logistic_id, code, description, status}) {
    this.props.onCreate({name, device_family_id: type.id, logistic_id, code, description, status: status.value});
  }

  handleClose() {
    this.props.onClose();
  }

  render() {
    const {valid, formValues, types, initialValues, show, readOnlyReason} = this.props;
    const isDeviceEditing = typeof initialValues.name !== 'undefined';

    return (
      <Modal
        show={show}
        onClose={this.handleClose}
        title={isDeviceEditing ? this.LANGUAGE.EDIT_DEVICE_MODAL_TITLE : this.LANGUAGE.NEW_DEVICE_MODAL_TITLE}
        className="modal-window--width-1 modal-window--theme-7"
      >
        <form noValidate="noValidate" onSubmit={this.props.handleSubmit(this.save)}>
          <Field
            name="name"
            type="text"
            required
            component={fieldTemplate}
            placeholder={this.LANGUAGE.NEW_DEVICE_MODAL__NAME_PLACEHOLDER}
          />
          <Field
            name="code"
            type="text"
            required
            label={this.LANGUAGE.NEW_DEVICE_MODAL__CODE_LABEL}
            component={fieldTemplate}
          />
          <Field
            name="type"
            label={this.LANGUAGE.NEW_DEVICE_MODAL__TYPE_LABEL}
            type="select"
            valueKey="id"
            labelKey="name"
            searchable
            required
            options={types}
            placeholder=""
            component={fieldTemplate}
          />
          <Field
            name="logistic_id"
            type="text"
            required
            label={this.LANGUAGE.NEW_DEVICE_MODAL__LOGISTIC_ID_LABEL}
            component={fieldTemplate}
          />
          <Field
            name="description"
            type="text"
            label={this.LANGUAGE.NEW_DEVICE_MODAL__DESCRIPTION_LABEL}
            component={fieldTemplate}
          />
          <Field
            name="status"
            label={this.LANGUAGE.NEW_DEVICE_MODAL__STATUS_LABEL}
            type="select"
            required
            options={[
              {value: 'in_use', label: this.LANGUAGE.DEVICE_STATUSES.in_use},
              {value: 'out_of_order', label: this.LANGUAGE.DEVICE_STATUSES.out_of_order},
            ]}
            placeholder=""
            component={fieldTemplate}
          />
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={this.handleCancel}>
              {this.LANGUAGE.NEW_DEVICE_MODAL_CANCEL_BUTTON}
            </button>
            <Popover
              overlay={readOnlyReason}
              trigger={readOnlyReason ? 'hover' : 'none'}
              className="modal-window__footer-btn"
            >
              <button
                className="button button--bg-7"
                type="submit"
                disabled={!!readOnlyReason}
                onClick={valid ? () => this.handleCreate(formValues && formValues.values) : () => {}}
              >
                {isDeviceEditing
                  ? this.LANGUAGE.DEVICE_MODAL_SAVE_BUTTON
                  : this.LANGUAGE.NEW_DEVICE_MODAL_CREATE_BUTTON}
              </button>
            </Popover>
          </div>
        </form>
      </Modal>
    );
  }
}

AddNewDeviceModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  handleSubmit: PropTypes.func,
  updateErrors: PropTypes.func,
  untouch: PropTypes.func,
  initialize: PropTypes.func,
  changeFieldValue: PropTypes.func,
  languageState: PropTypes.object,
  formValues: PropTypes.object,
  types: PropTypes.array,
  isCodeNew: PropTypes.array,
  isLogisticIdNew: PropTypes.array,
  initialValues: PropTypes.object,
  valid: PropTypes.bool,
  readOnlyReason: PropTypes.string,
};

AddNewDeviceModal.defaultProps = {
  show: false,
  types: [],
  isCodeNew: [true],
  isLogisticIdNew: [true],
  onCancel: () => {},
  onClose: () => {},
  onCreate: () => {},
};

const AddNewDeviceForm = reduxForm({
  form: 'AddNewDeviceForm',
  validate: AddNewDeviceModal.validate,
})(AddNewDeviceModal);

export default connect(
  ({languageState, form}) => ({
    languageState,
    formValues: form.AddNewDeviceForm,
  }),
  {
    updateErrors: AddNewDeviceModal.updateSyncErrors,
    changeFieldValue: change,
  },
)(AddNewDeviceForm);
