import React from 'react';

import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

import FilterLabel from '../filter-label';

import './filter-bar.scss';

function FilterBar({filters, buttonText, onClick, onClearFiltersClick, className, itemClassName, buttonClassName}) {
  const handleFiltersClick = (e) => {
    const currentTarget = e.currentTarget;

    let target = e.target;
    let data = null;

    while (target !== currentTarget) {
      data = target.getAttribute('data-filter-name');

      if (data) {
        onClick(data);

        return false;
      }

      target = target.parentNode;
    }

    return false;
  };

  return (
    <div className={className} onClick={handleFiltersClick}>
      {filters.map((item) => {
        if (!item.applied) {
          return null;
        }

        return (
          <FilterLabel
            name={item.name}
            key={item.name}
            text={item.label}
            title={item.title}
            className={`filter-bar__item ${itemClassName} ${item.className}`}
          />
        );
      })}
      <button className={`button filter-bar__clear-btn ${buttonClassName}`} onClick={onClearFiltersClick}>
        {buttonText}
      </button>
    </div>
  );
}

FilterBar.defaultProps = {
  filters: [],
  buttonText: 'clear',
  onClick: () => {},
  onClearFiltersClick: () => {},
  className: '',
  itemClassName: '',
  buttonClassName: '',
};

FilterBar.propTypes = {
  onClick: PropTypes.func,
  onClearFiltersClick: PropTypes.func,
  filters: PropTypes.array,
  buttonText: TranslationJsx,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
};

export default FilterBar;
