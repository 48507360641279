import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams, useReduxForm} from 'client/services/hooks';

import {getInstoreTaskPositions} from 'client/ducks/instore-tasks/actions';
import {selectInstoreTaskPositions} from 'client/ducks/instore-tasks/selectors';

import SelectDropdown from 'client/common/selects/select-dropdown';

import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container';
import {ANIMATION_STATUSES} from 'client/components/instore/constants';

import cssModule from './animation-position-kit-fieldset.module.scss';

const b = bem('animation-position-kit-fieldset', {cssModule});

const AnimationPositionKitFieldset = () => {
  const lang = useLanguage('ANIMATIONS.MODALS.ANIMATION_MAIN_MODAL.TABS.GENERAL');
  const [loadingPosition, setLoadingPosition] = useState(false);
  const [loadingKit] = useState(false);
  const positions = useSelector(selectInstoreTaskPositions);
  const {formValues, change} = useReduxForm(AnimationConfigContainerFormName);
  const {taskId} = useParams();
  const [queryParams] = useQueryParams(null, {}, {parse: {parseNumbers: true}});

  const kitMappings = formValues?.access?.kit_mappings;
  const readOnly = formValues.status && ![ANIMATION_STATUSES.EDIT].includes(formValues.status);

  const dispatch = useDispatch();

  const fetchPositions = useCallback(async () => {
    setLoadingPosition(true);
    await dispatch(
      getInstoreTaskPositions({
        q: {
          instore_task_id_eq: taskId,
        },
      }),
    );
    setLoadingPosition(false);
  }, [dispatch, taskId]);

  useEffect(() => {
    fetchPositions();
  }, [dispatch, fetchPositions]);

  const positionOptions = useMemo(
    () =>
      positions?.map((position) => ({
        label: position.name,
        value: position.id,
      })),
    [positions],
  );

  const kitOptions = useMemo(
    () =>
      kitMappings
        ?.filter((i) => !queryParams.campaignId || i.interaction_group_id === queryParams.campaignId)
        .map(({id, kit, name, ...kitMapping}) => ({
          label: `${kit.name} - ${name}`,
          value: id,
          id,
          ...kitMapping,
        })) || [],
    [kitMappings, queryParams.campaignId],
  );

  const onChangeKit = (kitMapping) => {
    change('kit_mapping', kitMapping);
    change('animation_days', []);
    change('month', null);
  };

  return (
    <div className={b()}>
      <SelectDropdown
        selectClassName={b('field')}
        name="kit_mapping"
        value={formValues?.kit_mapping?.id}
        onChange={onChangeKit}
        disabled={readOnly}
        label={lang.KIT_FIELD_LABEL}
        isLoading={loadingKit}
        options={kitOptions}
        withWrap={true}
        required={true}
      />
      <SelectDropdown
        selectClassName={b('field')}
        name="task_position_id"
        value={formValues?.task_position_id}
        onChange={(value) => change('task_position_id', value)}
        label={lang.POSITION_FIELD_LABEL}
        isLoading={loadingPosition}
        options={positionOptions}
        disabled={readOnly}
        simpleValue
      />
    </div>
  );
};

export default AnimationPositionKitFieldset;
