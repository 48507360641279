import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';

import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {mapNewClientUsers} from '../../../helper';

class NewClientSelectUsersModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
    };
    this.LANGUAGE = props.languageState.payload.CLIENTS_LIST;
    this.save = this.save.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.agencyUsersMapped !== prevProps.agencyUsersMapped) {
      this.setState({users: this.props.agencyUsersMapped});
    }
  }

  save() {}

  handleBack() {
    this.props.onSave(this.state.users);
    this.props.onBack();
  }

  handleCreate() {
    this.props.onSave(this.state.users);
    this.props.onCreate(this.state.users);
  }

  handleClose() {
    this.props.onClose();
  }

  handleUserChange({target}) {
    const id = Number(target.getAttribute('name'));
    const copyUsers = this.state.users.slice();
    const changeIndex = copyUsers.map((el) => el.id).indexOf(id);
    copyUsers[changeIndex].checked = target.checked;
    this.setState({users: copyUsers});
  }

  render() {
    const {handleSubmit, data} = this.props;
    const {users} = this.state;

    return (
      <Modal
        show={this.props.show}
        onClose={this.handleClose}
        dialogClassName="modal-window--width-1 modal-window--theme-1"
        titleClassName="modal-window__title"
        title={
          <>
            <h3 className="title">{this.LANGUAGE.NEW_CLIENT_MODAL_TITLE}</h3>
            <div className="modal-window__subtitle">{data.name}</div>
          </>
        }
      >
        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          <div className="modal-window__descr">{this.LANGUAGE.NEW_CLIENT_MODAL_SUBTITLE}</div>
          <div>
            <div
              className="content-group content-group--expand"
              style={{
                color: '#B375C4',
              }}
            >
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMax: 540,
                }}
              >
                <div className="list">
                  <h4 className="list__title">
                    {`${this.props.agencyName} ${this.LANGUAGE.NEW_CLIENT_MODAL_LIST_TITLE}`}
                  </h4>

                  <ul className="list__body">
                    {users.length &&
                      users.map((el, index) => {
                        return (
                          <li key={index} className="list__item">
                            <span className="list__item-check">
                              <label className="checkbox-button checkbox-button--view-1">
                                <input
                                  name={el.id}
                                  onChange={this.handleUserChange}
                                  checked={el.checked}
                                  type="checkbox"
                                  className="checkbox-button__input"
                                />
                                <div className="checkbox-button__label">
                                  <span className="icon-checked checkbox-button__icon" />
                                  <span className="checkbox-button__text">{el.full_name}</span>
                                </div>
                              </label>
                            </span>
                            <span className="list__item-text link main-text">{el.email}</span>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </CustomScrollbars>
            </div>
          </div>
          <div className="modal-window__note">{this.LANGUAGE.NEW_CLIENT_MODAL_NOTE}</div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={this.handleBack}>
              {this.LANGUAGE.NEW_CLIENT_MODAL_BACK_BUTTON}
            </button>
            <button className="button button--bg-1 modal-window__footer-btn" type="button" onClick={this.handleCreate}>
              {this.LANGUAGE.NEW_CLIENT_MODAL_CREATE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

NewClientSelectUsersModal.defaultProps = {
  show: false,
  onBack: () => {},
  onClose: () => {},
  onCreate: () => {},
};

NewClientSelectUsersModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onBack: PropTypes.func,
  onCreate: PropTypes.func,
  languageState: PropTypes.object,
  agencyUsers: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  data: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  agencyName: PropTypes.string,
};

const NewClientSelectUsersForm = reduxForm({
  form: 'NewClientModalSelectUserForm',
})(NewClientSelectUsersModal);

export default connect(({languageState, agencyUsers}) => ({
  languageState,
  agencyUsersMapped: mapNewClientUsers(agencyUsers.payload),
}))(NewClientSelectUsersForm);
