import React from 'react';

import PropTypes from 'prop-types';

import {diagramLineKinds} from 'client/ducks/results-graphs/constants';

import ScrollableLineChart from 'client/components/common/charts/scrollable-line-chart';
import {defaultDatasetOptions} from 'client/components/common/charts/scrollable-line-chart/constants';

const getConfig = (diagram) => {
  const labels = [];
  const tooltips = [];
  const values = [];

  const data = diagram.values;
  const preparedData = Object.entries(data).sort((a, b) => a[0] - b[0]);

  const valueStep = preparedData.length > 1 ? preparedData[1][0] - preparedData[0][0] : 0;
  const isInterval = valueStep > 1;
  const labelStep = isInterval ? 5 : 50;

  preparedData.forEach((item, index) => {
    const key = +item[0];
    const value = item[1];
    const label = index % labelStep === 0 ? key : '';
    const tooltip = !isInterval ? key : `${key} - ${key + valueStep - 1}`;

    labels.push(label);
    tooltips.push(tooltip);
    values.push(value);
  });

  return {
    labels: labels,
    minPointWidth: isInterval ? 20 : 1,
    tooltipDates: tooltips,
    datasets: [
      {
        label: '',
        data: values,
        borderColor: diagram.color,
        pointHitRadius: 5,
      },
    ],
  };
};

const getDatasetOptions = (diagram) => {
  const datasetOptions = {
    ...defaultDatasetOptions,
  };

  if (diagram.line_kind === diagramLineKinds.LINE_WITH_INTERVALS) {
    datasetOptions.pointRadius = 3;
  }

  return datasetOptions;
};

const LineDiagram = (props) => {
  const {diagram} = props;

  return <ScrollableLineChart config={getConfig(diagram)} datasetOptions={getDatasetOptions(diagram)} />;
};

LineDiagram.propTypes = {
  diagram: PropTypes.object.isRequired,
};

export default LineDiagram;
