import React, {useEffect} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import AppButton from 'client/common/buttons';
import {TextareaField, CheckboxField} from 'client/common/fields';
import {RadioButtonGroupField} from 'client/common/fields';
import SelectDropdown from 'client/common/selects/select-dropdown';

import cssModule from './diy-op-optins-modal-field.module.scss';

const b = bem('diy-op-optins-modal-field', {cssModule});

const DiyOpOptinsModalField = (props) => {
  const {
    columns,
    languageOptions,
    onDragStart,
    onDragEnd,
    onDragEnter,
    index,
    activeLanguage,
    hasMandatoryOrder,
    textEditable,
    onDeleteClick,
    disabled,
    onColumnChange,
    columnAdapterId,
    isNewOptIn,
  } = props;

  const lang = useLanguage('DIY_OPERATION.MODALS.OPTINS_MODAL');
  const draggable = !disabled && !hasMandatoryOrder;

  const {registerField, unregisterField} = useReduxForm('DiyOptinsModalForm');

  useEffect(() => {
    registerField(`optIns[${index}].column_adapter_id`);

    return () => {
      unregisterField(`optIns[${index}].column_adapter_id`);
    };
  }, [index, registerField, unregisterField]);

  return (
    <div className={b()} onDragOver={(event) => event.preventDefault()} onDragEnter={onDragEnter}>
      <div className={b('summary')}>
        <div
          className={b('drag-button', {draggable})}
          draggable={draggable}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        />
        <span className={b('label')}>
          {lang.LABEL_OPTIN} {index + 1}
        </span>
        <CheckboxField
          label={lang.OPTIN_CHECKBOX_LABEL}
          color="primary"
          name={`optIns[${index}].blocked`}
          disabled={disabled}
          inversionColor
        />
        <AppButton
          label={lang.DELETE_BUTTON}
          color="error"
          className={b('delete-button')}
          onClick={onDeleteClick}
          disabled={disabled}
          transparent
        />
      </div>
      <div className={b('optin-wrapper')}>
        <SelectDropdown
          className={b('select')}
          name={`optIns[${index}].column_adapter_id`}
          options={columns}
          placeholder={lang.TEXTAREA_PLACEHOLDER}
          disabled={disabled || (hasMandatoryOrder && !isNewOptIn)}
          simpleValue={true}
          withWrap={true}
          value={columnAdapterId}
          onChange={onColumnChange}
        />

        {languageOptions?.length > 1 && (
          <RadioButtonGroupField
            controlClassName={b('langs-grouped-control')}
            legend={lang.OPTIN_LANGUAGES_LEGEND}
            name={`optIns[${index}].activeLanguage`}
            radioButtons={languageOptions}
            withWrap
          />
        )}
        <TextareaField
          textareaClassName={b('textarea')}
          name={`optIns[${index}].opt_in_text_translations.${activeLanguage}`}
          disabled={!textEditable || disabled}
        />
      </div>
    </div>
  );
};

DiyOpOptinsModalField.propTypes = {
  columns: PropTypes.array.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDragEnter: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  activeLanguage: PropTypes.string.isRequired,
  hasMandatoryOrder: PropTypes.bool,
  textEditable: PropTypes.bool,
  onDeleteClick: PropTypes.func.isRequired,
  onColumnChange: PropTypes.func.isRequired,
  columnAdapterId: PropTypes.number.isRequired,
  isNewOptIn: PropTypes.bool.isRequired,
};

DiyOpOptinsModalField.defaultProps = {
  hasMandatoryOrder: false,
  textEditable: true,
};

export default DiyOpOptinsModalField;
