export const USER_LOADING = 'USER_LOADING';
export const USER_NOT_LOADED = 'USER_NOT_LOADED';
export const USER_LOADED = 'USER_LOADED';
export const USER_LOAD_FAILED = 'USER_LOAD_FAILED';

export const USER_LOGOUT_LOADING = 'USER_LOGOUT_LOADING';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

export const USER_ACCESS_DENIED = 'USER_ACCESS_DENIED';
export const USER_SET_2FA_AUTH = 'USER_SET_2FA_AUTH';
export const USER_PASSWORD_EXPIRED = 'USER_PASSWORD_EXPIRED';

export const USER_UPDATE_ACCESS_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_UPDATE_ACCESS_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_UPDATE_ACCESS_FAILED = 'USER_LOGOUT_FAILED';

export const SET_VIEW_MODE_USER_ID = 'SET_VIEW_MODE_USER_ID';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUSCCES = 'USER_LOGIN_SUSCCES';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';

export const CHECK_AUTH_TOKEN_REQUEST = 'CHECK_AUTH_TOKEN_REQUEST';
export const CHECK_AUTH_TOKEN_SUCCESS = 'CHECK_AUTH_TOKEN_SUCCESS';
export const CHECK_AUTH_TOKEN_ERROR = 'CHECK_AUTH_TOKEN_ERROR';

export const USER_SIGNING_UP = 'USER_SIGNING_UP';
export const USER_SIGNED_UP = 'USER_SIGNED_UP';
export const USER_SIGN_UP_FAILED = 'USER_SIGN_UP_FAILED';

export const USER_UPDATE_PASSWORD = 'USER_UPDATE_PASSWORD';
export const USER_UPDATED_PASSWORD = 'USER_UPDATED_PASSWORD';
export const USER_UPDATED_PASSWORD_FAIL = 'USER_UPDATED_PASSWORD_FAIL';

export const PASSWORD_RECOVERING = 'PASSWORD_RECOVERING';
export const PASSWORD_RECOVERED = 'PASSWORD_RECOVERED';
export const PASSWORD_RECOVERING_FAILED = 'PASSWORD_RECOVERING_FAILED';

export const SEND_OTP_REQUEST = 'SEND_OTP_REQUEST';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_FAILED = 'SEND_OTP_FAILED';

export const FIND_USER_REQUEST = '@@users/FIND_USER_REQUEST';
export const FIND_USER_SUCCESS = '@@users/FIND_USER_SUCCESS';
export const FIND_USER_ERROR = '@@users/FIND_USER_ERROR';
