/* eslint-disable max-len */

export default {
  CREATE_FILE_PARTICIPATION_EXPORT_REQUEST: '@@participation-export-report/CREATE_FILE_PARTICIPATION_EXPORT_REQUEST',
  CREATE_FILE_PARTICIPATION_EXPORT_SUCCESS: '@@participation-export-report/CREATE_FILE_PARTICIPATION_EXPORT_SUCCESS',
  CREATE_FILE_PARTICIPATION_EXPORT_ERROR: '@@participation-export-report/CREATE_FILE_PARTICIPATION_EXPORT_ERROR',

  API_PARTICIPATION_EXPORT_REQUEST: '@@participation-export-report/API_PARTICIPATION_EXPORT_REQUEST',
  API_PARTICIPATION_EXPORT_SUCCESS: '@@participation-export-report/API_PARTICIPATION_EXPORT_SUCCESS',
  API_PARTICIPATION_EXPORT_ERROR: '@@participation-export-report/API_PARTICIPATION_EXPORT_ERROR',

  DELETE_API_PARTICIPATION_EXPORT_REQUEST: '@@participation-export-report/DELETE_API_PARTICIPATION_EXPORT_REQUEST',
  DELETE_API_PARTICIPATION_EXPORT_SUCCESS: '@@participation-export-report/DELETE_API_PARTICIPATION_EXPORT_SUCCESS',
  DELETE_API_PARTICIPATION_EXPORT_ERROR: '@@participation-export-report/DELETE_API_PARTICIPATION_EXPORT_ERROR',

  GET_PARTICIPATION_EXPORT_REPORT_REQUEST: '@@participation-export-report/GET_PARTICIPATION_EXPORT_REPORT_REQUEST',
  GET_PARTICIPATION_EXPORT_REPORT_SUCCESS: '@@participation-export-report/GET_PARTICIPATION_EXPORT_REPORT_SUCCESS',
  GET_PARTICIPATION_EXPORT_REPORT_ERROR: '@@participation-export-report/GET_PARTICIPATION_EXPORT_REPORT_ERROR',

  GET_PARTICIPATION_EXPORT_REPORTS_REQUEST: '@@participation-export-report/GET_PARTICIPATION_EXPORT_REPORTS_REQUEST',
  GET_PARTICIPATION_EXPORT_REPORTS_SUCCESS: '@@participation-export-report/GET_PARTICIPATION_EXPORT_REPORTS_SUCCESS',
  GET_PARTICIPATION_EXPORT_REPORTS_ERROR: '@@participation-export-report/GET_PARTICIPATION_EXPORT_REPORTS_ERROR',

  GET_PARTICIPATION_FILE_EXPORT_HISTORY_REQUEST:
    '@@participation-export-report/GET_PARTICIPATION_FILE_EXPORT_HISTORY_REQUEST',
  GET_PARTICIPATION_FILE_EXPORT_HISTORY_SUCCESS:
    '@@participation-export-report/GET_PARTICIPATION_FILE_EXPORT_HISTORY_SUCCESS',
  GET_PARTICIPATION_FILE_EXPORT_HISTORY_ERROR:
    '@@participation-export-report/GET_PARTICIPATION_FILE_EXPORT_HISTORY_ERROR',

  GET_PARTICIPATION_API_EXPORT_HISTORY_REQUEST:
    '@@participation-export-report/GET_PARTICIPATION_API_EXPORT_HISTORY_REQUEST',
  GET_PARTICIPATION_API_EXPORT_HISTORY_SUCCESS:
    '@@participation-export-report/GET_PARTICIPATION_API_EXPORT_HISTORY_SUCCESS',
  GET_PARTICIPATION_API_EXPORT_HISTORY_ERROR:
    '@@participation-export-report/GET_PARTICIPATION_API_EXPORT_HISTORY_ERROR',

  GET_FILE_PARTICIPATION_EXPORT_REQUEST: '@@participation-export-report/GET_FILE_PARTICIPATION_EXPORT_REQUEST',
  GET_FILE_PARTICIPATION_EXPORT_SUCCESS: '@@participation-export-report/GET_FILE_PARTICIPATION_EXPORT_SUCCESS',
  GET_FILE_PARTICIPATION_EXPORT_ERROR: '@@participation-export-report/GET_FILE_PARTICIPATION_EXPORT_ERROR',

  GET_AVAILABLE_EXPORT_COLUMNS_REQUEST: '@@participation-export-report/GET_AVAILABLE_EXPORT_COLUMNS_REQUEST',
  GET_AVAILABLE_EXPORT_COLUMNS_SECCESS: '@@participation-export-report/GET_AVAILABLE_EXPORT_COLUMNS_SUCCESS',
  GET_AVAILABLE_EXPORT_COLUMNS_ERROR: '@@participation-export-report/GET_AVAILABLE_EXPORT_COLUMNS_ERROR',

  GET_PARTICIPATION_EXPORT_ITEMS_REQUEST: '@@participation-export-report/GET_PARTICIPATION_EXPORT_ITEMS_REQUEST',
  GET_PARTICIPATION_EXPORT_ITEMS_SECCESS: '@@participation-export-report/GET_PARTICIPATION_EXPORT_ITEMS_SUCCESS',
  GET_PARTICIPATION_EXPORT_ITEMS_ERROR: '@@participation-export-report/GET_PARTICIPATION_EXPORT_ITEMS_ERROR',

  PATCH_PARTICIPATION_EXPORT_REPORTS_REQUEST:
    '@@participation-export-report/PATCH_PARTICIPATION_EXPORT_REPORTS_REQUEST',
  PATCH_PARTICIPATION_EXPORT_REPORTS_SUCCESS:
    '@@participation-export-report/PATCH_PARTICIPATION_EXPORT_REPORTS_SUCCESS',
  PATCH_PARTICIPATION_EXPORT_REPORTS_ERROR: '@@participation-export-report/PATCH_PARTICIPATION_EXPORT_REPORTS_ERROR',

  GET_API_PARTICIPATION_EXPORT_REPORTS_REQUEST:
    '@@participation-export-report/GET_API_PARTICIPATION_EXPORT_REPORTS_REQUEST',
  GET_API_PARTICIPATION_EXPORT_REPORTS_SUCCESS:
    '@@participation-export-report/GET_API_PARTICIPATION_EXPORT_REPORTS_SUCCESS',
  GET_API_PARTICIPATION_EXPORT_REPORTS_ERROR:
    '@@participation-export-report/GET_API_PARTICIPATION_EXPORT_REPORTS_ERROR',

  DELETE_API_PARTICIPATION_EXPORT_REPORTS_REQUEST:
    '@@participation-export-report/DELETE_API_PARTICIPATION_EXPORT_REPORTS_REQUEST',
  DELETE_API_PARTICIPATION_EXPORT_REPORTS_SUCCESS:
    '@@participation-export-report/DELETE_API_PARTICIPATION_EXPORT_REPORTS_SUCCESS',
  DELETE_API_PARTICIPATION_EXPORT_REPORTS_ERROR:
    '@@participation-export-report/DELETE_API_PARTICIPATION_EXPORT_REPORTS_ERROR',

  PUT_API_PARTICIPATION_EXPORT_REPORTS_REQUEST:
    '@@participation-export-report/PUT_API_PARTICIPATION_EXPORT_REPORTS_REQUEST',
  PUT_API_PARTICIPATION_EXPORT_REPORTS_SUCCESS:
    '@@participation-export-report/PUT_API_PARTICIPATION_EXPORT_REPORTS_SUCCESS',
  PUT_API_PARTICIPATION_EXPORT_REPORTS_ERROR:
    '@@participation-export-report/PUT_API_PARTICIPATION_EXPORT_REPORTS_ERROR',

  DELETE_FILE_PARTICIPATION_EXPORT_REQUEST: '@@participation-export-report/DELETE_FILE_PARTICIPATION_EXPORT_REQUEST',
  DELETE_FILE_PARTICIPATION_EXPORT_SUCCESS: '@@participation-export-report/DELETE_FILE_PARTICIPATION_EXPORT_SUCCESS',
  DELETE_FILE_PARTICIPATION_EXPORT_ERROR: '@@participation-export-report/DELETE_FILE_PARTICIPATION_EXPORT_ERROR',

  SHARE_PARTICIPATION_EXPORT_REQUEST: '@@participation-export-report/SHARE_PARTICIPATION_EXPORT_REQUEST',
  SHARE_PARTICIPATION_EXPORT_SUCCESS: '@@participation-export-report/SHARE_PARTICIPATION_EXPORT_SUCCESS',
  SHARE_PARTICIPATION_EXPORT_ERROR: '@@participation-export-report/SHARE_PARTICIPATION_EXPORT_ERROR',
};
