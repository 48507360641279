import React, {useMemo} from 'react';

import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectOperation} from 'client/ducks/operations/selectors';
import {selectCurrentAccessLevel} from 'client/ducks/user-clients/selectors';

import AppButton from 'client/common/buttons/app-button';

import {getCatalogsInfoByType} from 'client/components/diy-operation/modals/diy-customization-modal/helpers/get-catalogs-info-by-type';
import DiyOpPublishingPreviewModal from 'client/components/diy-operation/modals/diy-op-publishing-preview-modal';
import {CATALOG_TYPES} from 'client/models/templates/constants';

import cssModule from './diy-op-publishing-preview-card.module.scss';

const b = bem('diy-op-publishing-preview-card', {cssModule});

const DiyOpPublishingPreviewCard = () => {
  const lang = useLanguage('DIY_OPERATION.CARDS.DIY_OP_PUBLISHING_PREVIEW_CARD');
  const [isPreviewModalVisible, togglePreviewModalVisibility] = useToggle(false);

  const {client_interface} = useSelector(selectOperation);
  const accessLevel = useSelector(selectCurrentAccessLevel);
  const catalogsInfo = useMemo(
    () => client_interface && getCatalogsInfoByType(client_interface, accessLevel),
    [client_interface, accessLevel],
  );

  const templateCatalogInfo = catalogsInfo?.[CATALOG_TYPES.TEMPLATE];

  return (
    <>
      <AppButton
        disabled={!templateCatalogInfo?.forms?.general?.available}
        onClick={togglePreviewModalVisibility}
        className={b('button')}
        iconName="eye-opened"
        color="light-clients"
        label={lang.PREVIEW}
        size="medium"
      />

      {isPreviewModalVisible && <DiyOpPublishingPreviewModal onClose={togglePreviewModalVisibility} />}
    </>
  );
};

export default DiyOpPublishingPreviewCard;
