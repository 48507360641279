import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {deleteMethod, post} from 'client/services/fetch';
import {getArrayWithElement, getHomeOrFirstDashboard} from 'client/services/helpers';

import {getAutotask, clearAutotaskState} from 'client/ducks/autotask/actions';
import {selectAutotask} from 'client/ducks/autotask/selectors';
import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getClient} from 'client/ducks/clients-list/actions';
import {getAvailableColumnAdaptersByOperation} from 'client/ducks/column-adapters/actions';
import {getVisualGroup} from 'client/ducks/groups/actions';
import {getSlideshow} from 'client/ducks/slideshows/actions';
import {getTracker} from 'client/ducks/twitter-hashtag-trackers/actions';
import {selectIsAdmin} from 'client/ducks/user/selectors';
import {
  getVisualDisplayItems,
  getVisuals,
  getVisualsByGroupId,
  getVisualsFromSlideshow,
} from 'client/ducks/visuals/actions';
import {selectVisualsTotalCount} from 'client/ducks/visuals/selectors';
import {selectManualGroupIdsByVisualId, selectManualGroupItemsByVisualId} from 'client/ducks/visuals/selectors';

import {API_METHODS, GROUP_TYPES, CLIENT_PAGES, OPERATION_STATUS_TYPES, CLIENT_LEVEL_TYPES} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import CustomLink from 'client/components/common/custom-link';
import Hashtag from 'client/components/common/hashtag';
import Icon from 'client/components/common/icon';
import TitleBlock from 'client/components/common/title-block';

import {TASK_TYPES} from 'client/models/operations/constants';

import {mapFilter} from './helpers';

import VisEditGroupModal from '../components/modals/vis-edit-group-modal';
import EditGroupPopover from '../components/popovers/edit-group-popover';
import VisHashtagSettingsPopover from '../components/popovers/vis-settings-popover/vis-hashtag-settings-popover';
import VisSlideshowSettingsPopover from '../components/popovers/vis-settings-popover/vis-slideshow-settings-popover';
import VisualsClientBase from '../visuals-client-base';

class VisualsClientPage extends ReactQueryParams {
  static propTypes = {
    getAvailableColumnAdaptersByOperation: PropTypes.func.isRequired,
    getPostedVisualsByConfigId: PropTypes.func.isRequired,
    getVisualDisplayItems: PropTypes.func.isRequired,
    getVisualsByGroupId: PropTypes.func.isRequired,
    visualsTotalCount: PropTypes.number.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    getVisualGroup: PropTypes.func.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    visualGroup: PropTypes.object.isRequired,
    getSlideshow: PropTypes.func.isRequired,
    slideshow: PropTypes.object.isRequired,
    getVisuals: PropTypes.func.isRequired,
    getTracker: PropTypes.func.isRequired,
    clientId: PropTypes.number.isRequired,
    isNational: PropTypes.bool.isRequired,
    getClient: PropTypes.func.isRequired,
    ...withRouter.propTypes,
    tracker: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    getAutotask: PropTypes.func.isRequired,
    clearAutotaskState: PropTypes.func.isRequired,
    autotask: PropTypes.object.isRequired,
  };

  static modals = {
    [GROUP_TYPES.AUTOMATIC]: 1,
    [GROUP_TYPES.MANUAL]: 2,
    DELETE_TRACKER: 3,
    EDIT_TRACKER: 4,
    DELETE_GROUP: 5,
    EDIT_SLIDESHOW: 6,
    DELETE_SLIDESHOW: 7,
    EDIT_CONFIG: 8,
    DELETE_CONFIG: 9,
  };

  // This number is defined in specification
  static MAX_COLUMNS_COUNT = 25;
  static DEFAULT_PAGE = 1;
  static DEFAULT_PER_PAGE = 5;

  constructor(props) {
    super(props);

    this.state = {
      activeModal: null,
      lastGroupId: null,
      checked: [],
      groupId: +this.queryParams.groupId,
      groupItemsByVisualId: null,
    };

    this.manualVisualGroupItemsByVisualIds = {};
    this.LANGUAGE = {
      ...props.languageState.payload.VISUALS,
      NETWORKS: props.languageState.payload.POST_MANAGEMENT_TASK.HASHTAG_TRACKER.NETWORKS,
      BREADCRUMBS: props.languageState.payload.BREADCRUMBS,
    };
  }

  defaultQueryParams = {
    filters: JSON.stringify({}),
    search: '',
    sort: JSON.stringify({}),
    page: '',
    perPage: '',
    groupId: false,
    configId: false,
    configToPublish: false,
    isGroupNew: false,
    isEditMode: false,
    parentGroupId: '',
  };

  componentDidMount() {
    this.props
      .getClient(this.props.clientId, {
        include: ['dashboards'],
      })
      .then(() => this.updateBreadcrumbs());
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
    this.props.clearAutotaskState();
  }

  componentDidUpdate(prevProps) {
    if (this.queryParams.groupId !== this.state.lastGroupId) {
      this.setState({
        groupId: +this.queryParams.groupId,
      });
      this.setLastGroupId(this.queryParams.groupId);
    }
    if (this.props.visualGroup.id !== prevProps.visualGroup.id) {
      this.setState({
        checked: this.props.visualItemsByGroupId[this.props.visualGroup.id] || [],
      });
    }

    if (['slideshow', 'config', 'visualGroup', 'tracker', 'autotask'].some((i) => this.props[i] !== prevProps[i])) {
      this.updateBreadcrumbs(this.props);
    }
  }

  setLastGroupId = (lastGroupId) => {
    this.setState(
      {
        lastGroupId,
      },
      () => {
        this.updateMe().then(() => this.updateBreadcrumbs());
      },
    );
  };

  updateBreadcrumbs = (newProps) => {
    const props = newProps || this.props;
    const {
      isNational,
      languageState: {
        payload: {BREADCRUMBS},
      },
      client: {id: clientId, type: clientType, name: clientName, agency},
    } = props;

    const agencyBreadcrumb = agency
      ? [
          {
            href: CustomLink.createClientLink({clientId: agency.id, clientType: agency.type}),
            name: agency.name,
          },
        ]
      : [];

    const mainBreadcrumbs = [
      ...agencyBreadcrumb,
      {
        name: clientName,
        href: CustomLink.createClientLink({clientId, clientType}),
      },
    ];

    if (!this.queryParams.slideshowId) {
      mainBreadcrumbs.unshift({
        name: BREADCRUMBS.CLIENTS,
        href: CLIENT_PAGES.CLIENTS_LIST,
      });

      mainBreadcrumbs.push({
        name: BREADCRUMBS.VISUALS,
        href: CustomLink.createVisualsLink(clientId),
      });
    }

    let pageName;

    switch (true) {
      case !!this.queryParams.groupId:
        pageName = props.visualGroup.name;
        break;

      case !!this.queryParams.configId:
        pageName = props.config.name;
        break;

      case !!this.queryParams.trackerId:
        pageName = props.tracker.name;
        break;

      case !!this.queryParams.slideshowId:
        pageName = props.slideshow.name;
        break;

      default:
        pageName = this.LANGUAGE.BREADCRUMBS.VISUALS;
        break;
    }

    const autotask = props.autotask.id ? props.autotask : null;

    const breadcrumbs = isNational ? this.buildNationalBreadcrumbs(autotask, props.client, pageName) : mainBreadcrumbs;

    return this.props.setBreadcrumbs(breadcrumbs);
  };

  buildNationalBreadcrumbs = (task, client, pageName) => {
    if (!task) {
      return [];
    }

    const {operation} = task;
    const operationHomeDashboard = getHomeOrFirstDashboard(operation.dashboards);
    const taskHomeDashboard = getHomeOrFirstDashboard(task.dashboards);
    const basicInfo = {
      clientType: client.type,
      clientId: client.id,
      operationId: task.operation_id,
      taskType: task.type,
      taskId: task.id,
    };

    return [
      {
        name:
          operation.status !== OPERATION_STATUS_TYPES.ACTIVE
            ? this.LANGUAGE.BREADCRUMBS.FINISHED_OPERATIONS
            : this.LANGUAGE.BREADCRUMBS.ONGOING_OPERATIONS,
        href:
          operation.status !== OPERATION_STATUS_TYPES.ACTIVE
            ? CLIENT_PAGES.OPERATIONS_FINISHED
            : CLIENT_PAGES.OPERATIONS_ACTIVE,
      },
      {
        name: get(operationHomeDashboard, 'name'),
        href:
          operationHomeDashboard &&
          CustomLink.createDashboardsLink({
            ...basicInfo,
            dashboardType: CLIENT_LEVEL_TYPES.OPERATION,
            dashboardId: operationHomeDashboard.id,
          }),
        hidden: !operationHomeDashboard,
      },
      {
        name: get(taskHomeDashboard, 'name'),
        href:
          taskHomeDashboard &&
          CustomLink.createDashboardsLink({
            ...basicInfo,
            dashboardType: CLIENT_LEVEL_TYPES.POST_TASK,
            dashboardId: taskHomeDashboard.id,
          }),
        hidden: !taskHomeDashboard,
      },
      {
        name: pageName,
      },
    ];
  };

  updateAdditionalData = () => {
    const {
      groupId,
      trackerId,
      slideshowId,
      filters: {autotask_id},
    } = this.queryParams;
    const promises = [];

    if (groupId) {
      promises.push(this.props.getVisualGroup(groupId));
    }

    if (trackerId) {
      promises.push(
        this.props.getTracker(trackerId, {
          include: ['city'],
        }),
      );
    }

    if (slideshowId) {
      promises.push(
        this.props.getSlideshow(slideshowId, {
          include: [
            'diaporama_visual_groups',
            'allowed_user',
            'visual_groups',
            'diaporama_mapping_items.column_adapter',
          ],
        }),
      );
    }

    if (autotask_id) {
      promises.push(
        this.props.getAutotask(autotask_id, {
          include: {
            dashboards: null,
            operation: {
              dashboards: null,
            },
          },
        }),
      );
    }

    return Promise.all(promises);
  };

  getVisualsQueryParams = (params) => {
    const filter = mapFilter(params.filters);
    const sort = {
      name: (params.sort && params.sort.name) || 'id',
      order: (params.sort && params.sort.order) || 'ASC',
    };

    if (!params.page) {
      this.setQueryParams({
        filters: filter,
        page: VisualsClientPage.DEFAULT_PAGE,
      });
    }
    if (!params.perPage) {
      this.setQueryParams({
        filters: filter,
        perPage: VisualsClientPage.DEFAULT_PER_PAGE,
      });
    }

    const {
      arrivingType,
      group: hasGroup,
      group_in = [],
      operation_in = [],
      task_in = [],
      ...filters
    } = params.filters || {};

    task_in.forEach((task) => {
      switch (task.type) {
        case TASK_TYPES.AUTOMATION:
          filters.interface_automation_task_id_in = getArrayWithElement(filters.automation_task_id_in, task.id);
          break;
        default:
          return;
      }
    });

    filters.operation_id_in = operation_in && operation_in.map((i) => i.id);
    filters.manual_visual_groups_id_in = group_in && group_in.map((i) => i.id);

    filters.g = {};

    if (arrivingType && arrivingType.length) {
      filters.g = {
        0: {
          m: 'or',
        },
      };

      arrivingType.forEach((type) => {
        const key = type.split('__')[0];
        const value = type.split('__')[1];

        if (key === 'participation_interaction_type_in') {
          if (filters.g[0][key]) {
            filters.g[0][key].push(value);
          } else {
            filters.g[0][key] = [value];
          }
        } else {
          filters.g[0][key] = value;
        }
      });
    }

    if (hasGroup === 'has') {
      filters.participation_interaction_type_eq = 'OfflineInteraction';
    } else if (hasGroup === 'hasNo') {
      filters.g[1] = {
        m: 'or',
        participation_id_null: 't',
        participation_interaction_type_not_eq: 'OfflineInteraction',
      };
    }

    let fetchFunction;

    switch (true) {
      case !!params.groupId && params.isEditMode:
        fetchFunction = !params.parentGroupId
          ? this.props.getVisuals
          : (qParams) => this.props.getVisualsByGroupId(params.parentGroupId || params.groupId, qParams);
        break;
      case !!params.groupId && (!!params.parentGroupId || !params.isGroupNew):
        fetchFunction = (qParams) => this.props.getVisualsByGroupId(params.parentGroupId || params.groupId, qParams);
        break;
      case !!params.slideshowId:
        fetchFunction = (qParams) => this.props.getVisualsFromSlideshow(params.slideshowId, qParams);
        break;
      default:
        fetchFunction = this.props.getVisuals;
    }

    if (params.trackerId) {
      filters.g[2] = {
        hashtag_tracker_id_eq: params.trackerId,
      };
    }

    const autotaskId = params.automation_task_id || params.filters.autotask_id;
    if (autotaskId) {
      filters.automation_task_id_eq = autotaskId;
    }

    return {
      fetchFunction,
      page: params.page || VisualsClientPage.DEFAULT_PAGE,
      per_page: +params.perPage || VisualsClientPage.DEFAULT_PER_PAGE,
      q: {
        s: `${sort.name} ${sort.order}`,
        client_id_eq: this.props.clientId,
        ...filters,
        title_cont: params.search || '',
      },
    };
  };

  handleActiveModalSet =
    (activeModal, state = {}) =>
    () =>
      this.setState({...state, activeModal});

  handleActiveModalClose =
    (update = false, state = {}) =>
    () => {
      this.setState({
        ...state,
        activeModal: null,
      });

      if (update) {
        this.updateMe();
      }
    };

  updateMe = () =>
    new Promise((resolve) =>
      this.forceUpdate(() => {
        const {fetchFunction, ...params} = this.getVisualsQueryParams(this.queryParams);
        resolve(fetchFunction(params));
      }),
    )
      .then(this.updateColumns)
      .then(this.updateAdditionalData);

  updateColumns = () => {
    this.props.getVisualDisplayItems({
      include: ['column_adapter'],
      q: {
        s: 'position asc',
      },
      per_page: VisualsClientPage.MAX_COLUMNS_COUNT,
    });
  };

  handleCancelGroupOrConfig = () => {
    this.setState({groupId: 0});
    this.setQueryParams({
      groupId: false,
      configToPublish: false,
      isGroupNew: false,
      isEditMode: false,
      parentGroupId: '',
    });
    this.updateMe().then(() => this.updateBreadcrumbs());
  };

  handleRemoveVisualGroup = (apiMethod, object) => () => {
    deleteMethod(`${apiMethod}/${object.id}`)
      .then(this.handleActiveModalClose(true))
      .then(() => {
        this.props.history.push(this.props.history.location.pathname);
      });
  };

  handleVisualGroupEdited = () => {
    this.handleActiveModalClose()();
    this.updateMe();
  };

  setGroupId = (groupId) => this.setState({groupId});

  handleVisualsGroupChange = ({checked, ids}) => {
    this.setState((state) => {
      return {
        checked: checked
          ? [...state.checked, ...ids.filter((id) => !state.checked.includes(id))]
          : state.checked.filter((id) => !ids.includes(id)),
      };
    });
  };

  handleVisualsGroupSave = () => {
    const {checked} = this.state;
    const groupId = this.state.groupId || +this.state.lastGroupId;
    const {groupItemsByVisualId, visualItemsByGroupId} = this.props;

    const groupVisualItems = visualItemsByGroupId[groupId] || [];

    const addList = checked.filter((id) => {
      return !groupVisualItems.includes(id);
    });

    const removeList = groupVisualItems.filter((id) => {
      return !checked.includes(id);
    });

    if (addList.length) {
      addList.forEach((id) => {
        post(API_METHODS.MANUAL_VISUAL_GROUP_ITEMS, {
          manual_visual_group_item: {
            visual_id: id,
            manual_visual_group_id: groupId,
          },
        }).then(({manual_visual_group_item: {id: itemId}}) => {
          if (itemId) {
            this.manualVisualGroupItemsByVisualIds[id] = itemId;
          }
        });
      });
    }
    if (removeList.length) {
      removeList.forEach((id) => {
        const deletedItemId = this.manualVisualGroupItemsByVisualIds[id] || groupItemsByVisualId[id][groupId];
        deleteMethod(`${API_METHODS.MANUAL_VISUAL_GROUP_ITEMS}/${deletedItemId}`);
      });
    }
    this.handleCancelGroupOrConfig();
  };

  getControlsContent = () => {
    if (!this.props.isAdmin) {
      return null;
    }

    const {groupId, trackerId, slideshowId} = this.queryParams;

    switch (true) {
      case !!groupId:
        return (
          <div>
            <EditGroupPopover
              group={this.props.visualGroup}
              onEdit={this.handleActiveModalSet(VisualsClientPage.modals[this.props.visualGroup.type])}
              onDelete={this.handleActiveModalSet(VisualsClientPage.modals.DELETE_GROUP)}
            >
              <Icon name="settings" />
            </EditGroupPopover>
          </div>
        );

      case !!trackerId:
        return (
          <div>
            <VisHashtagSettingsPopover
              tracker={this.props.tracker}
              onEdit={this.handleActiveModalSet(VisualsClientPage.modals.EDIT_TRACKER)}
              onDelete={this.handleActiveModalSet(VisualsClientPage.modals.DELETE_TRACKER)}
            >
              <Icon className="visuals__title-settings" name="settings" />
            </VisHashtagSettingsPopover>
          </div>
        );

      case !!slideshowId:
        return (
          <div>
            <VisSlideshowSettingsPopover
              slideshow={this.props.slideshow}
              onEdit={this.handleActiveModalSet(VisualsClientPage.modals.EDIT_SLIDESHOW)}
              onDelete={this.handleActiveModalSet(VisualsClientPage.modals.DELETE_SLIDESHOW)}
            >
              <Icon className="visuals__title-settings" name="settings" />
            </VisSlideshowSettingsPopover>
          </div>
        );

      default:
        return null;
    }
  };

  getTitles = () => {
    const {groupId, trackerId, slideshowId} = this.queryParams;

    switch (true) {
      case !!groupId:
        return [
          <TitleBlock theme key={0}>
            <TitleBlock.Item>{this.LANGUAGE.TITLE}</TitleBlock.Item>
          </TitleBlock>,
          <Hashtag
            key={1}
            className="hashtag--view-1 hashtag--width-300"
            onDelete={this.handleCancelGroupOrConfig}
            textClassName="ellipsis-text"
          >
            {this.props.visualGroup.name}
          </Hashtag>,
          <button
            key={2}
            className="button base-toolbar__btn button--bg-8 save-group__btn"
            onClick={this.handleVisualsGroupSave}
          >
            {this.LANGUAGE.SAVE_BUTTON}
          </button>,
        ];

      case !!trackerId:
        return [
          <TitleBlock theme ellipsis="1" className="ellipsis-title" separator key={0}>
            <TitleBlock.Item>{this.props.tracker.name}</TitleBlock.Item>
            <TitleBlock.Item>
              <b>{this.LANGUAGE.NETWORKS.TWITTER}</b>
              <span className="visuals__title-tags theme-color-post-management-task">
                <Hashtag appendSharp deletable={false}>
                  {this.props.tracker.hashtag}
                </Hashtag>
              </span>
            </TitleBlock.Item>
          </TitleBlock>,
        ];

      case !!slideshowId:
        return [
          <TitleBlock theme ellipsis="1" className="ellipsis-title" key={0}>
            <TitleBlock.Item>{this.props.slideshow.name}</TitleBlock.Item>
          </TitleBlock>,
        ];

      default:
        return [
          <TitleBlock theme key={0}>
            <TitleBlock.Item>{this.LANGUAGE.TITLE}</TitleBlock.Item>
          </TitleBlock>,
        ];
    }
  };

  getDiaporamaValues = (diaporamaArray = []) => {
    const result = diaporamaArray.reduce((accumulator, currentValue) => {
      if (currentValue.value) {
        if (currentValue.column_adapter.name === 'name') {
          accumulator[currentValue.column_adapter.record_type.toLowerCase()] = {
            value: currentValue.value,
            id: currentValue.id,
          };
        }
        accumulator[currentValue.column_adapter.name] = {value: currentValue.value, id: currentValue.id};
      }
      return accumulator;
    }, {});
    if (result.tags) {
      result.tags.value = result.tags.value
        .split(', ')
        .map((val) => val.trim())
        .filter((tag) => tag);
    }
    if (result.group) {
      result.group.value = result.group.value
        .split(',')
        .map((val) => val.trim())
        .filter((tag) => tag);
    }
    return result;
  };

  render() {
    const {clientId, client, isAdmin, history, slideshow} = this.props;
    const {activeModal} = this.state;

    const {groupId, slideshowId, trackerId, isEditMode} = this.queryParams;

    const deleteGroupLabels = this.LANGUAGE.DELETE_GROUP_MODAL;
    const deleteTrackerLabels = this.LANGUAGE.DELETE_TRACKER_MODAL;
    const deleteSlideshowLabels = this.LANGUAGE.DELETE_DIAPO_MODAL;

    const diaporamaValues = this.getDiaporamaValues(slideshow.diaporama_mapping_items);

    return (
      <VisualsClientBase
        historyPush={history.push}
        clientId={clientId}
        client={client}
        groupId={+groupId}
        isEditMode={isEditMode}
        updateMe={this.updateMe}
        // onSelect={this.handleVisualSelect}
        setGroupId={this.setGroupId}
        handleVisualsGroupChange={this.handleVisualsGroupChange}
        titles={this.getTitles()}
        gridView={!!trackerId || !!slideshowId}
        noToggle={trackerId && !isAdmin}
        allowedFilterGroups={this.props.slideshow.visual_groups}
        showDeleteFromDbButton={!slideshowId}
        diaporamaValues={diaporamaValues}
        additionalModals={
          <div>
            <VisEditGroupModal
              onClose={this.handleActiveModalClose()}
              show={activeModal === VisualsClientPage.modals[GROUP_TYPES.MANUAL]}
              onConfirm={this.handleVisualGroupEdited}
              name={this.props.visualGroup.name}
              id={+groupId}
            />

            <ConfirmationModal
              onCancel={this.handleActiveModalClose()}
              onClose={this.handleActiveModalClose()}
              confirmText={deleteGroupLabels.CONFIRM_BUTTON}
              cancelText={deleteGroupLabels.CANCEL_BUTTON}
              show={activeModal === VisualsClientPage.modals.DELETE_GROUP}
              message={deleteGroupLabels.MESSAGE}
              onConfirm={this.handleRemoveVisualGroup(API_METHODS.VISUAL_GROUPS, this.props.visualGroup)}
              buttonConfirmClass="button--bg-8"
              title={deleteGroupLabels.TITLE}
            />

            <ConfirmationModal
              onCancel={this.handleActiveModalClose()}
              onClose={this.handleActiveModalClose()}
              confirmText={deleteTrackerLabels.CONFIRM_BUTTON}
              cancelText={deleteTrackerLabels.CANCEL_BUTTON}
              show={activeModal === VisualsClientPage.modals.DELETE_TRACKER}
              message={deleteTrackerLabels.MESSAGE}
              onConfirm={this.handleRemoveVisualGroup(API_METHODS.TWITTER_HASHTAG_TRACKERS, this.props.tracker)}
              title={deleteTrackerLabels.TITLE}
              buttonConfirmClass="button--bg-8"
              className="theme-color-13"
            />

            <ConfirmationModal
              onCancel={this.handleActiveModalClose()}
              onClose={this.handleActiveModalClose()}
              confirmText={deleteSlideshowLabels.CONFIRM_BUTTON}
              cancelText={deleteSlideshowLabels.CANCEL_BUTTON}
              show={activeModal === VisualsClientPage.modals.DELETE_SLIDESHOW}
              message={deleteSlideshowLabels.MESSAGE}
              onConfirm={this.handleRemoveVisualGroup(API_METHODS.SLIDESHOWS, this.props.slideshow)}
              title={deleteSlideshowLabels.TITLE}
              buttonConfirmClass="button--bg-8"
              className="theme-color-13"
            />
          </div>
        }
        controlsContent={this.getControlsContent()}
      />
    );
  }
}

export default withRouter(
  connect(
    ({languageState, groups, twitterTrackers, slideshows, clientsList, ...state}) => ({
      languageState,
      visualGroup: groups.visualGroup,
      tracker: twitterTrackers.tracker,
      slideshow: slideshows.slideshow,
      visualItemsByGroupId: selectManualGroupIdsByVisualId(state),
      groupItemsByVisualId: selectManualGroupItemsByVisualId(state),
      visualsTotalCount: selectVisualsTotalCount(state),
      client: clientsList.client,
      isAdmin: selectIsAdmin(state),
      autotask: selectAutotask(state),
    }),
    {
      getAvailableColumnAdaptersByOperation,
      getVisualsFromSlideshow,
      getVisualDisplayItems,
      getVisualsByGroupId,
      clearBreadcrumbs,
      setBreadcrumbs,
      getVisualGroup,
      getSlideshow,
      getVisuals,
      getTracker,
      getClient,
      getAutotask,
      clearAutotaskState,
    },
  )(VisualsClientPage),
);
