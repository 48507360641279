import {date} from 'client/services/validator';

// Native validation that does not depend on the checking params from json
export default (value, item_type, lang) => {
  switch (item_type) {
    case 'date':
      return date(lang.INVALID_DATE)(value);
    default:
      return null;
  }
};
