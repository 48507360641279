import React, {useState} from 'react';

import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';

import {getLotteryPrizes} from 'client/ducks/lottery/actions';
import {selectLotteryPrizes} from 'client/ducks/lottery/selectors';

import LotteryPrizeModal from 'client/components/lottery/modals/lottery-prize-modal';
import {isOperationArchived} from 'client/models/operations/helpers';
import {Operation} from 'client/models/operations/types';
import {Game, Prize} from 'client/models/prizes/types';

import LotteryBlockHeader from './lottery-block-header';
import LotteryBlockTable from './lottery-block-table';

import cssModule from './diy-op-lottery-block.module.scss';

const b = bem('diy-op-lottery-block', {cssModule});

type DiyOpLotteryBlockProps = {
  fetchAutotask: () => Promise<void>;
  game: Game;
  operation: Operation;
  onDeleteDraw: () => void;
};

const DiyOpLotteryBlock: React.FC<DiyOpLotteryBlockProps> = (props) => {
  const {fetchAutotask, game, operation, onDeleteDraw} = props;
  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_LOTTERY_BLOCK');
  const [showPrizeModal, togglePrizeModal] = useToggle(false);
  const [editingPrize, setEditingPrize] = useState<Prize | null>(null);

  const isOpArchived = isOperationArchived(operation);
  const disabled = isOpArchived || !operation.editable;

  const {
    data: prizes = [],
    loading: loadingPrizes,
    fetch: fetchPrizes,
  } = useReduxFetch({
    action: getLotteryPrizes,
    selector: selectLotteryPrizes,
    actionArgs: {
      q: {
        s: 'order asc',
        game_id_eq: game.id,
        active_eq: true,
      },
      include_prize_total_quantities: true,
      include_prize_email_send_state: true,
      include_prize_sms_send_state: true,
      include_prize_email_image_translations: true,
      include_prize_picto_translations: 'null',
      include_prize_images: 'null',
    },
  });

  const openPrizeModal = (prize: Prize | null) => {
    setEditingPrize(prize);
    togglePrizeModal();
  };

  return (
    <div className={b()}>
      {showPrizeModal && prizes && (
        <LotteryPrizeModal
          game={game}
          operation={operation}
          editingPrize={editingPrize}
          onSave={fetchPrizes}
          onClose={() => {
            setEditingPrize(null);
            togglePrizeModal();
          }}
          disabled={disabled}
        />
      )}
      <p className={b('title')}>{lang.TITLE}</p>
      <LotteryBlockHeader
        game={game}
        prizes={prizes}
        fetchPrizes={fetchPrizes}
        fetchAutotask={fetchAutotask}
        openPrizeModal={togglePrizeModal}
        onDeleteDraw={onDeleteDraw}
        disabled={disabled}
      />
      {prizes && (
        <LotteryBlockTable
          prizes={prizes}
          loading={loadingPrizes}
          game={game}
          openPrizeModal={openPrizeModal}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default DiyOpLotteryBlock;
