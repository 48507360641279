import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useReduxForm} from 'client/services/hooks';

import LayoutPanel from 'client/common/panels/layout-panel';

import AnimationDetailsBlock from 'client/components/animations/blocks/animation-details-block';
import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container';
import InstoreFieldsetAvailable from 'client/components/animations/fieldsets/animation-available-fieldset';
import AnimationIntervalFieldset from 'client/components/animations/fieldsets/animation-interval-fieldset';
import AnimationPlanningFieldset from 'client/components/animations/fieldsets/animation-planning-fieldset';
import InstoreFieldsetPositionKit from 'client/components/animations/fieldsets/animation-position-kit-fieldset';

import './animation-main-modal-general-tab.scss';

const b = bem('animation-main-modal-general-tab');

const AnimationMainModalGeneralTab = ({readOnly}) => {
  const {formValues} = useReduxForm(AnimationConfigContainerFormName);

  return (
    <div className={b()}>
      <LayoutPanel className={b('fieldsets')}>
        <div className={b('column', ['first'])}>
          <InstoreFieldsetPositionKit readOnly={readOnly} />
          <AnimationDetailsBlock />
        </div>
        <div className={b('column', {second: true, ['read-only']: readOnly})}>
          {!!formValues?.animation_days?.length && (
            <>
              <AnimationIntervalFieldset readOnly={readOnly} />
              <AnimationPlanningFieldset readOnly={readOnly} />
            </>
          )}
        </div>
      </LayoutPanel>
      <div className={b('datepicker-container')}>
        <InstoreFieldsetAvailable readOnly={readOnly} />
      </div>
    </div>
  );
};

AnimationMainModalGeneralTab.propTypes = {
  readOnly: PropTypes.bool.isRequired,
};

export default AnimationMainModalGeneralTab;
