import {isEmail} from 'client/services/helpers';
import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {isEmptyString} from 'client/services/validator';

import {WINNING_SEARCH_TYPES} from './constants';
import {WinningSearchValues, WinningSearchErrors} from './types';

export default (
  values: WinningSearchValues,
  lang: LangsByPath<['WINNINGS.FORMS.WINNING_SEARCH_FORM.ERRORS']>,
  validateEmpty: boolean,
) => {
  const errors: WinningSearchErrors = {};

  if (validateEmpty && values.search_type === WINNING_SEARCH_TYPES.FULL_NAME) {
    if (isEmptyString(values.first_name)) {
      errors.first_name = lang.REQUIRED;
    }
    if (isEmptyString(values.last_name)) {
      errors.last_name = lang.REQUIRED;
    }
    if (errors.first_name || errors.last_name) {
      return errors;
    }
  } else if (validateEmpty && isEmptyString(values[values.search_type as keyof typeof values])) {
    errors[values.search_type as keyof typeof errors] = lang.REQUIRED;
    return errors;
  }

  if (isEmptyString(values[values.search_type as keyof typeof values])) {
    return errors;
  }

  switch (values.search_type) {
    case WINNING_SEARCH_TYPES.EMAIL:
      if (!isEmail(values.email)) {
        errors.email = lang.EMAIL;
      }
      break;
    case WINNING_SEARCH_TYPES.PHONE:
      if (!/^0\d{9}$|^\+\d{9,}$/.test(values.phone)) {
        errors.phone = lang.PHONE;
      }
      break;
  }

  return errors;
};
