import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import FileButton from 'client/common/buttons/file-button';
import Icon from 'client/common/icon';

import {Translation} from 'client/models/language/types';

import cssModule from './logo-button.module.scss';

const b = bem('logo-button', {cssModule});

type LogoButtonProps = {
  name: string;
  label?: string | Translation;
  buttonLabel?: Translation;
  url: string;
  showButton?: boolean;
  onUpload?: (file: any) => void;
  className?: string;
  acceptFormats?: string;
  classNames?: {
    label?: string;
    preview?: string;
    button?: string;
  };
};

const LogoButton: React.FC<LogoButtonProps> = (props) => {
  const {acceptFormats, buttonLabel, name, label, url, showButton = true, onUpload, className, classNames} = props;

  const lang = useLanguage('COMMON');

  return (
    <div className={cn(b(), className)}>
      <span className={cn(b('label'), classNames?.label)}>{label}</span>
      <div className={cn(b('preview'), classNames?.preview)}>
        {url ? (
          <img alt={label?.toString() || ''} src={url} className={b('image')} />
        ) : (
          <Icon name="image-placeholder" className={b('icon')} width={30} height={20} />
        )}
      </div>
      {showButton && (
        <FileButton
          name={name}
          acceptFormats={acceptFormats}
          className={cn(b('button'), classNames?.button)}
          label={buttonLabel || lang.CLICK_TO_SELECT}
          onChange={onUpload}
          fontSize="small"
          color="primary"
          link={true}
        />
      )}
    </div>
  );
};

export default LogoButton;
