export const DIY_OP_MAIN_BLOCK_MODALS = {
  OPTINS_MODAL: {
    id: 'OPTINS',
    form: 'formOptins',
  },
  CONFIGURATION_MODAL: {
    id: 'CONFIGURATION',
    form: 'formConfiguration',
  },
  DESIGN_MODAL: {
    id: 'DESIGN',
    form: 'formDesign',
  },
};
