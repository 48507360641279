import get from 'lodash/get';

import types from './types';

const initialState = {
  mediasDashboard: {
    total_duration: 0,
    items_number: 0,
  },
  globalMedias: [],
  globalMediasDuration: 0,
  localMedias: [],
  localMediasDuration: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_GLOBAL_MEDIAS_REQUEST:
      return {
        ...state,
        globalMedias: initialState.globalMedias,
        globalMediasDuration: initialState.globalMediasDuration,
      };

    case types.GET_GLOBAL_MEDIAS_SUCCESS:
      return {
        ...state,
        globalMedias: get(action, 'payload.media_files'),
        globalMediasDuration: get(action, 'payload.total_duration'),
      };

    case types.GET_LOCAL_MEDIAS_REQUEST:
      return {
        ...state,
        localMedias: initialState.localMedias,
        localMediasDuration: initialState.localMediasDuration,
      };

    case types.GET_LOCAL_MEDIAS_SUCCESS:
      return {
        ...state,
        localMedias: get(action, 'payload.media_files'),
        localMediasDuration: get(action, 'payload.total_duration'),
      };

    case types.GET_MEDIAS_DASHBOARD_SUCCESS:
      return {
        ...state,
        mediasDashboard: action.payload,
      };

    default:
      return state;
  }
}
