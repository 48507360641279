import React, {Component} from 'react';

import differenceBy from 'lodash/differenceBy';
import find from 'lodash/find';
import pull from 'lodash/pull';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {post, deleteMethod} from 'client/services/fetch';
import {swap} from 'client/services/helpers';

import {getColumnAdapters} from 'client/ducks/column-adapters/actions';

import {API_METHODS} from 'client/common/config';
import Modal from 'client/common/modals/modal';
import Popover from 'client/common/popovers/popover';

import Checkbox from 'client/components/common/checkbox';
// components
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';
import Rollout from 'client/components/common/rollout';

import './vis-table-settings-modal.scss';

class VisTableSettingsModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    getColumnAdapters: PropTypes.func.isRequired,
    updateMe: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    langTable: PropTypes.object.isRequired,
    displayItems: PropTypes.array.isRequired,
    columnAdapters: PropTypes.array.isRequired,
  };

  static defaultProps = {
    show: false,
    onClose: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      displayedColumns: [],
      columnAdapters: [],
      isLoading: false,
    };

    this.columnsNamesMapping = {
      file: this.props.langTable.FILE_PREVIEW_COLUMN,
      title: this.props.langTable.TITLE_COLUMN,
      kind: this.props.langTable.TYPE_COLUMN,
      created_at: this.props.langTable.CREATED_COLUMN,
      groups: this.props.langTable.GROUPS_COLUMN,
      source_name: this.props.langTable.SOURCE_COLUMN,
      interface: this.props.langTable.INTERFACE_COLUMN,
      operation: this.props.langTable.OPERATION_COLUMN,
      lead: this.props.langTable.LEAD_COLUMN,
      status: this.props.langTable.STATUS_COLUMN,
      visible_by_client: this.props.langTable.VISIBLE_COLUMN,
      tags: this.props.langTable.TAGS_COLUMN,
      file_extension: this.props.langTable.FORMAT_TYPE_COLUMN,
      arriving_type: this.props.langTable.ARRIVING_TYPE_COLUMN,
      client: this.props.langTable.CLIENT_NAME_COLUMN,
      file_identifier: this.props.langTable.FILE_NAME_COLUMN,
      author: this.props.langTable.AUTHOR_COLUMN,
      author_avatar: this.props.langTable.AUTHOR_AVATAR_COLUMN,
      comment: this.props.langTable.COMMENTS_COLUMN,
      likes_count: this.props.langTable.LIKES_NUM_COLUMN,
      width: this.props.langTable.WIDTH_COLUMN,
      height: this.props.langTable.HEIGHT_COLUMN,
      orientation: this.props.langTable.ORIENTATION_COLUMN,
      filesize: this.props.langTable.SIZE_COLUMN,
      expires_at: this.props.langTable.EXPIRES_AT,
    };

    this.props.getColumnAdapters({
      q: {
        record_type_eq: 'Visual',
        name_in: Object.keys(this.columnsNamesMapping),
        type_not_eq: 'VisualColumnAdapter',
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.setState({
        displayedColumns: this.props.displayItems.map((i) => i.column_adapter),
        columnAdapters: this.props.columnAdapters.map((i) => {
          const displayed = find(this.props.displayItems, (item) => item.column_adapter_id === i.id);
          if (displayed) {
            return {
              ...i,
              checked: false,
              displayed: true,
            };
          }
          return i;
        }),
      });
    }
  }

  handleTransition = () => {
    this.forceUpdate();
  };

  handleCheck =
    (arrayName, index) =>
    ({target}) => {
      const columns = this.state[arrayName].slice();
      columns[index].checked = target.checked;

      this.setState({
        [arrayName]: columns,
      });
    };

  displayCheckedColumns = () => {
    const columnAdaptersChecked = this.state.columnAdapters.filter((i) => i.checked);
    const checkedDisplayedColumns = this.state.displayedColumns.filter((i) => i.checked);

    const checked = differenceBy(columnAdaptersChecked, checkedDisplayedColumns, 'id');

    const promises = [];

    checked.forEach((i, index) => {
      i.checked = false;
      i.displayed = true;

      const lastDisplayItem = this.props.displayItems[this.props.displayItems.length - 1];
      i.position = ((lastDisplayItem && lastDisplayItem.position + 1) || 0) + index;

      this.state.displayedColumns.push(i);
      promises.push(
        post(API_METHODS.VISUAL_DISPLAY_ITEMS, {
          visual_display_item: {
            column_adapter_id: i.id,
            position: i.position,
          },
        }),
      );
    });

    this.setState({
      columnAdapters: this.state.columnAdapters.slice(),
      displayedColumns: this.state.displayedColumns.slice(),
    });

    Promise.all(promises).then(() => this.props.updateMe());
  };

  deleteDisplayedColumn = (column) => () => {
    const displayed = find(this.props.displayItems, (i) => i.column_adapter_id === column.id);
    if (!displayed) {
      return;
    }

    deleteMethod(`${API_METHODS.VISUAL_DISPLAY_ITEMS}/${displayed.id}`).then(() => this.props.updateMe());

    pull(this.state.displayedColumns, column);
    const columnAdapter = find(this.state.columnAdapters, (i) => i.id === column.id);
    if (!columnAdapter) {
      return;
    }
    columnAdapter.displayed = false;

    this.setState({
      columnAdapters: this.state.columnAdapters.slice(),
      displayedColumns: this.state.displayedColumns.slice(),
    });
  };

  applyDisplayedColumns = (displayedColumns) => {
    this.setState({
      displayedColumns: displayedColumns.slice(),
    });

    deleteMethod(API_METHODS.VISUAL_DISPLAY_ITEMS)
      .then(() => {
        const promises = [];

        displayedColumns.forEach((column, position) =>
          promises.push(
            post(API_METHODS.VISUAL_DISPLAY_ITEMS, {
              visual_display_item: {
                position,
                column_adapter_id: column.id,
              },
            }),
          ),
        );

        return Promise.all(promises);
      })
      .then(() => this.props.updateMe())
      .then(() => this.setState({isLoading: false}));
  };

  handleUpClick = () => {
    let {displayedColumns} = this.state;

    this.setState({isLoading: true});

    for (let i = 1; i < displayedColumns.length; i++) {
      if (displayedColumns[i].checked) {
        displayedColumns = swap(displayedColumns, i, i - 1);
      }
    }

    this.applyDisplayedColumns(displayedColumns);
  };

  handleDownClick = () => {
    let {displayedColumns} = this.state;

    this.setState({isLoading: true});

    for (let i = displayedColumns.length - 2; i >= 0; i--) {
      if (displayedColumns[i].checked) {
        displayedColumns = swap(displayedColumns, i, i + 1);
      }
    }

    this.applyDisplayedColumns(displayedColumns);
  };

  renderTooltip() {
    return <span id="tooltip-1">{this.props.lang.DELETE_ITEM_TOOLTIP}</span>;
  }

  render() {
    const {show, onClose, lang} = this.props;
    const {displayedColumns, columnAdapters, isLoading} = this.state;

    const columnAdaptersChecked = columnAdapters.some((i) => i.checked);
    const displayedColumnsChecked = displayedColumns.some((i) => i.checked);

    const arrowUpEnabled = displayedColumns.length > 0 && displayedColumnsChecked && !displayedColumns[0].checked;
    const arrowDownEnabled =
      displayedColumns.length > 0 && displayedColumnsChecked && !displayedColumns[displayedColumns.length - 1].checked;

    const disabledUp = isLoading || !arrowUpEnabled;
    const disabledDown = isLoading || !arrowDownEnabled;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-2 theme-color-13 vis-table-settings-modal"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TITLE}
      >
        <div className="row pos-rel">
          <div className="modal-window__order-btns">
            <button
              disabled={disabledUp}
              onClick={this.handleUpClick}
              className="button button--bg-2 button--circle modal-window__order-btn-up"
            >
              <Icon className="button__icon theme-color-13" name="back" />
            </button>
            <button
              disabled={disabledDown}
              onClick={this.handleDownClick}
              className="button button--bg-2 button--circle modal-window__order-btn-down"
            >
              <Icon className="button__icon theme-color-13" name="back" />
            </button>
          </div>
          <button
            disabled={!columnAdaptersChecked}
            onClick={this.displayCheckedColumns}
            className="button button--bg-2 button--circle modal-window__transfer-btn"
          >
            <Icon className="button__icon theme-color-13" name="back" />
          </button>
          <div className="col-xs-6 modal-window__col-left">
            <div className="modal-window__descr main-text">{lang.ALL_COLUMNS_TITLE}</div>
            <div className="content-group theme-color-13">
              <CustomScrollbars
                cssModifier="custom-scrollbars--view-1 custom-scrollbars--hide-hor-scroll"
                scrollbarProps={{
                  hideTracksWhenNotNeeded: true,
                  autoHeightMax: 482,
                  autoHeightMin: 482,
                }}
                viewCssClass="custom-scrollbars--hide-hor-scroll"
              >
                <Rollout
                  title={<span>{lang.ALL_COLUMNS_TITLE}</span>}
                  maxHeight="433px"
                  className="rollout--expand-1"
                  onTransition={this.handleTransition}
                  isOpened={true}
                >
                  <CustomScrollbars
                    scrollbarProps={{
                      autoHeightMax: 433,
                    }}
                    viewCssClass="custom-scrollbars--hide-hor-scroll"
                  >
                    <div className="list list--view-3">
                      <div className="list__group">
                        {columnAdapters.map(
                          (column, key) =>
                            !column.displayed && (
                              <div className="list__item" key={key}>
                                <Checkbox
                                  label={this.columnsNamesMapping[column.name]}
                                  name={column.name}
                                  checked={column.checked}
                                  onChange={this.handleCheck('columnAdapters', key)}
                                />
                              </div>
                            ),
                        )}
                      </div>
                    </div>
                  </CustomScrollbars>
                </Rollout>
              </CustomScrollbars>
            </div>
          </div>
          <div className="col-xs-6 modal-window__col-right">
            <div className="modal-window__descr main-text">{lang.DISPLAYED_COLUMNS_TITLE}</div>
            <div className="content-group theme-color-13">
              <CustomScrollbars
                scrollbarProps={{
                  hideTracksWhenNotNeeded: true,
                  autoHeightMax: 482,
                  autoHeightMin: 482,
                }}
                viewCssClass="custom-scrollbars--hide-hor-scroll"
              >
                <div className="list list--view-2">
                  {displayedColumns.map((column, key) => (
                    <div key={key} className="list__item pos-rel vis-table-settings-modal__list-item">
                      <Checkbox
                        label={this.columnsNamesMapping[column.name]}
                        name={column.name}
                        checked={column.checked}
                        onChange={this.handleCheck('displayedColumns', key)}
                      />
                      <Popover position="right" overlay={this.renderTooltip()}>
                        <span className="list__del-item-btn" onClick={this.deleteDisplayedColumn(column)}>
                          <Icon name="clear" className="list__del-item-icon" />
                        </span>
                      </Popover>
                    </div>
                  ))}
                </div>
              </CustomScrollbars>
            </div>
          </div>
        </div>
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-8 modal-window__footer-btn" onClick={this.props.onClose}>
            {lang.CONFIRM_BUTTON}
          </button>
        </div>
      </Modal>
    );
  }
}

export default connect(
  ({languageState, columnAdapters, visuals}) => ({
    lang: languageState.payload.VISUALS.TABLE_SETTINGS_MODAL,
    langTable: languageState.payload.VISUALS.TABLE,
    columnAdapters: columnAdapters.columnAdapters,
    displayItems: visuals.displayItems,
  }),
  {
    getColumnAdapters,
  },
)(VisTableSettingsModal);
