import React, {useState, useEffect, useCallback} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useAppMedia, useLanguage, useReduxFetch} from 'client/services/hooks';

import {
  getWinningTask,
  getWinningTasks,
  setWinningHeaderData,
  searchWinnings,
} from 'client/ducks/prize-distribution/actions';
import {
  selectWinningHeaderData,
  selectWinningTask,
  selectWinningTasks,
  selectWinningSearchResults,
} from 'client/ducks/prize-distribution/selectors';

import AwaitContainer from 'client/common/await-container';
import {WINNING_PAGES} from 'client/common/config';
import Icon from 'client/common/icon';
import NoData from 'client/common/various/no-data';

import WinningBackButton from 'client/components/winnings/buttons/winning-back-button';
import WinningTaskCard from 'client/components/winnings/cards/winning-task-card';
import WinningBaseContainer from 'client/components/winnings/containers/winning-base-container';
import WinningPrizeContainer from 'client/components/winnings/containers/winning-prize-container';
import WinningSearchForm from 'client/components/winnings/forms/winning-search-form';
import {WINNING_SEARCH_TYPES} from 'client/components/winnings/forms/winning-search-form/constants';
import {WinningSearchType, WinningSearchValues} from 'client/components/winnings/forms/winning-search-form/types';
import WinningSearchResultList from 'client/components/winnings/lists/winning-search-result-list';
import WinningPrizeModal from 'client/components/winnings/modals/winning-prize-modal';

import {mapSearchParams} from './helpers';

import cssModule from './winning-search-container.module.scss';

const b = bem('winning-search-container', {cssModule});

type WinningSearchContainerProps = {
  taskId: number;
};

const WinningSearchContainer: React.FC<WinningSearchContainerProps> = (props) => {
  const {taskId} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('WINNINGS.CONTAINERS.WINNING_SEARCH_CONTAINER');
  const prizeCardLang = useLanguage('WINNINGS.CARDS.PRIZE_CARD');
  const {isTablet} = useAppMedia();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const [searchType, setSearchType] = useState<WinningSearchType>(WINNING_SEARCH_TYPES.EMAIL);
  const [searchParams, setSearchParams] = useState<Record<string, string>>({});
  const [prizeModal, setPrizeModal] = useState<number | null>(null);
  const [loading, toggleLoading] = useToggle(false);

  const headerData = useSelector(selectWinningHeaderData);
  const data = useSelector(selectWinningSearchResults);

  useEffect(() => {
    if (!isResult && isSubmitted) {
      setIsResult(true);
    }
  }, [isResult, isSubmitted]);

  const {data: task, loading: loadingTask} = useReduxFetch({
    action: getWinningTask,
    selector: selectWinningTask,
    actionArgs: taskId,
  });

  const {data: tasks = [], loading: loadingTasks} = useReduxFetch({
    action: getWinningTasks,
    selector: selectWinningTasks,
  });

  const setSearchHeader = useCallback(() => {
    dispatch(
      setWinningHeaderData({
        icon: tasks.length > 1 ? 'arrow-left' : null,
        label: isResult || tasks.length < 2 ? task?.name : lang.TASKS,
        path: WINNING_PAGES.WINNING_TASKS,
      }),
    );
  }, [dispatch, isResult, lang.TASKS, task?.name, tasks.length]);

  useEffect(() => {
    setSearchHeader();
  }, [setSearchHeader]);

  const handleSearch = async (values: WinningSearchValues) => {
    toggleLoading();
    setIsSubmitted(true);
    setSearchType(values.search_type);

    const nextSearchParams = mapSearchParams(values);
    setSearchParams(nextSearchParams);

    await dispatch(searchWinnings(task?.id, nextSearchParams));
    toggleLoading();
  };

  const handleClosePrizeModal = () => {
    setPrizeModal(null);
    setSearchHeader();
  };

  const handleSelectPrize = (id: number) => {
    setPrizeModal(id);
    dispatch(
      setWinningHeaderData({
        icon: 'close',
        label: prizeCardLang.TITLE,
        callback: handleClosePrizeModal,
      }),
    );
  };

  const prizeContainerProps = {
    isDesktop: !isTablet,
    searchParams: searchParams,
    searchCriteria: searchType,
    participationPrizeId: prizeModal!,
  };

  return (
    <>
      {(!prizeModal || !isTablet) && (
        <WinningBaseContainer className={b()} loading={loadingTask || loadingTasks}>
          {!isTablet && <WinningBackButton className={b('back-button')} {...headerData} />}
          {task && !isResult && <WinningTaskCard className={b('card')} task={task} />}
          <div className={b('label-wrap')}>
            {!isResult && <Icon name="winnings" />}
            <p className={b('label', {black: isResult})}>{isResult ? lang.SEARCH_WINNER : lang.WINNINGS}</p>
          </div>
          <WinningSearchForm onSearch={handleSearch} onChangeForm={setIsSubmitted} />
          <AwaitContainer loading={loading}>
            {isResult && (
              <WinningSearchResultList
                data={data}
                className={b('results')}
                searchType={searchType}
                onSelect={handleSelectPrize}
              />
            )}
            {!data.length && isSubmitted && (
              <NoData className={b('no-data')} iconClassName={b('no-data-icon')} text={lang.NO_RESULTS} />
            )}
          </AwaitContainer>
        </WinningBaseContainer>
      )}
      {!isTablet && !!prizeModal && <WinningPrizeModal onClose={handleClosePrizeModal} {...prizeContainerProps} />}
      {isTablet && !!prizeModal && <WinningPrizeContainer {...prizeContainerProps} />}
    </>
  );
};

export default WinningSearchContainer;
