import {SEGMENTATION_TYPES} from 'client/components/prizes/constants';

export default (values) => {
  if (values.device_online_type) {
    if (values.device_online_segmentation) {
      return {
        online_stock: values.online_stock,
        online_level: values.online_level === SEGMENTATION_TYPES.NONE ? SEGMENTATION_TYPES.GLOBAL : values.online_level, // required by BACKEND,
        device_stock: values.device_stock,
        geo_level: values.geo_level ? SEGMENTATION_TYPES.PER_DEVICE : SEGMENTATION_TYPES.GLOBAL, // required by BACKEND
      };
    }
    return {
      default_initial_stock: values.default_initial_stock,
      geo_level: SEGMENTATION_TYPES.NONE,
      online_level: SEGMENTATION_TYPES.NONE,
      online_stock: 0,
      device_stock: 0,
    };
  }

  return {
    online_stock: values.online_stock,
    online_level: values.online_level,
    device_stock: values.device_stock,
    geo_level: values.geo_level ? SEGMENTATION_TYPES.PER_DEVICE : SEGMENTATION_TYPES.NONE,
  };
};
