import {callApi} from 'client/services/call-api';

import types from 'client/ducks/games/types';

import {API_METHODS, API_PATH} from 'client/common/config';

import {ApiAction} from 'client/types';

export {
  updateGame,
  createGame,
  createPrize,
  deleteGame,
  deletePrize,
  fetchGame,
  getGames,
  getPrizes,
  getPrizeMaps,
  getPrizeMapsByLevel,
  updatePrizeOrdersInStore,
  updatePrize,
  updatePrizeMap,
  getLevels,
  updatePrizeMaps,
  updatePrizeMapsOrdersInStore,
} from './actions-js';

export const updatePrizeOrder: ApiAction = (gameId: number, prizesOrders: Record<number, number>) => (dispatch) =>
  callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.GAMES}/${gameId}/update_prizes_order`,
    method: 'POST',
    types: [types.UPDATE_PRIZE_ORDERS_REQUEST, types.UPDATE_PRIZE_ORDERS_SUCCESS, types.UPDATE_PRIZE_ORDERS_ERROR],
    body: {
      prizes: prizesOrders,
    },
  });
