import {useEffect} from 'react';

import {useDispatch} from 'react-redux';

import {getInstoreTaskKitAvailableDays} from 'client/ducks/instore-tasks/actions';

import {getPeriod} from 'client/components/animations/containers/animation-config-container/helpers';

const useFetchAvailableDays = ({kitMappingId, month, date, campaignId}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (kitMappingId) {
      const period = getPeriod(month, date);

      const queryParams = {
        from: period.start,
        to: period.end,
        interaction_group_id: campaignId,
      };
      dispatch(getInstoreTaskKitAvailableDays(kitMappingId, queryParams));
    }
  }, [campaignId, date, dispatch, kitMappingId, month]);
};

export default useFetchAvailableDays;
