import React, {Component} from 'react';

import PropTypes from 'prop-types';
import Moment from 'react-moment';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {required, maxLength, match} from 'client/services/validator';

import {updateScenario} from 'client/ducks/scenario/actions';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';
import Icon from 'client/components/common/icon';
import InfoBlock from 'client/components/common/info-block';

import './ats-basic-info-card.scss';

class AtsBasicInfoCard extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    scenario: PropTypes.object,
    submitting: PropTypes.bool,
    valid: PropTypes.bool,
    pristine: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    updateScenario: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      edit: false,
    };

    this.LANGUAGE = props.languageState.payload.AUTOTASK_SCENARIO.BASIC_INFO_CARD;
    this.rules = {
      name: [required(this.LANGUAGE.NAME_REQUIRED_ERROR), maxLength(25, this.LANGUAGE.NAME_MAX_LENGTH_ERROR)],
      code: [required(this.LANGUAGE.CODE_REQUIRED_ERROR), match(/^\S+$/, this.LANGUAGE.CODE_MATCH_ERROR)],
    };
  }

  componentDidMount() {
    this.props.initialize(this.props.scenario);
  }

  componentDidUpdate(prevProps) {
    if (this.props.scenario !== prevProps.scenario) {
      this.props.initialize(this.props.scenario);
    }
  }

  handleEditClick = () => {
    this.props.initialize(this.props.scenario);
    this.props.reset();
    this.setState({
      edit: !this.state.edit,
    });
  };

  handleSubmitClick = (data) =>
    this.props
      .updateScenario(this.props.scenario.id, data)
      .then(() => this.setState({edit: false}))
      .catch((error) => {
        const {
          response: {errors},
        } = error;
        const submitErrors = {};

        if (errors.name) {
          submitErrors.name = this.LANGUAGE.NAME_TAKEN_ERROR;
        }

        if (errors.code) {
          submitErrors.code = this.LANGUAGE.CODE_TAKEN_ERROR;
        }

        throw new SubmissionError(submitErrors);
      });

  render() {
    const {scenario, handleSubmit, submitting, valid, pristine} = this.props;

    return (
      <InfoBlock className="ats-basic-info-card">
        <InfoBlock.Title>{this.LANGUAGE.TITLE}</InfoBlock.Title>
        <InfoBlock.Content>
          <InfoBlock.Header className="clearfix">
            <button
              disabled={!valid}
              className="button button--circle pull-right button--bg-5"
              onClick={this.handleEditClick}
            >
              <Icon name="edit" className="button__icon" width={20} height={20} />
            </button>
          </InfoBlock.Header>
          <InfoBlock.Body limitedHeight={true} expandRight={true}>
            <CustomScrollbars
              scrollbarProps={{
                autoHeightMax: this.state.edit ? 247 : 300,
              }}
            >
              <div className="ats-basic-info-card__inner">
                <form>
                  <div className="form-field-wrap">
                    <Field
                      cssModifier="input--disabled-view-1"
                      type="text"
                      name="name"
                      disabled={!this.state.edit}
                      label={this.LANGUAGE.SCENARIO_NAME_LABEL}
                      component={fieldTemplate}
                      validate={this.rules.name}
                    />
                  </div>
                  <div className="form-field-wrap">
                    <Field
                      cssModifier="input--disabled-view-1"
                      type="text"
                      name="code"
                      disabled={!this.state.edit}
                      label={this.LANGUAGE.SCENARIO_CODE_LABEL}
                      component={fieldTemplate}
                      validate={this.rules.code}
                    />
                  </div>
                  <div className="form-field-wrap">
                    <div className="form-field-wrap__group-title">{this.LANGUAGE.FIRST_PERFORMANCE_LABEL}</div>
                    <div className="form-field-wrap__text-content">
                      <div className="main-text main-text--bold">
                        {scenario.first_scenario_execution_created_at && (
                          <Moment format="DD/MM/YYYY HH:mm">{scenario.first_scenario_execution_created_at}</Moment>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-field-wrap">
                    <div className="form-field-wrap__group-title">{this.LANGUAGE.LAST_PERFORMANCE_LABEL}</div>
                    <div className="form-field-wrap__text-content">
                      <div className="main-text main-text--bold">
                        {scenario.last_scenario_execution_created_at && (
                          <Moment format="DD/MM/YYYY HH:mm">{scenario.last_scenario_execution_created_at}</Moment>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-field-wrap">
                    <div className="form-field-wrap__group-title">{this.LANGUAGE.TIMES_PERFORMED_LABEL}</div>
                    <div className="form-field-wrap__text-content">
                      <div className="main-text main-text--bold">{scenario.scenario_executions_count}</div>
                    </div>
                  </div>
                </form>
              </div>
            </CustomScrollbars>
            {this.state.edit && (
              <div className="ats-basic-info-card__bottom">
                <button className="button button--bg-11 ats-basic-info-card__btn" onClick={this.handleEditClick}>
                  {this.LANGUAGE.CANCEL_BUTTON}
                </button>
                <button
                  className="button button--bg-5 ats-basic-info-card__btn"
                  type="submit"
                  onClick={handleSubmit(this.handleSubmitClick)}
                  disabled={submitting || !valid || pristine}
                >
                  {this.LANGUAGE.SAVE_BUTTON}
                </button>
              </div>
            )}
          </InfoBlock.Body>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

AtsBasicInfoCard.propTypes = {
  languageState: PropTypes.object.isRequired,
  scenario: PropTypes.object,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  pristine: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  updateScenario: PropTypes.func.isRequired,
};

const AtsBasicInfoCardForm = reduxForm({
  form: 'AtsBasicInfoCardForm',
})(AtsBasicInfoCard);

export default connect(
  ({languageState, scenario}) => ({
    languageState,
    scenario: scenario.payload,
  }),
  (dispatch) => ({
    updateScenario: bindActionCreators(updateScenario, dispatch),
  }),
)(AtsBasicInfoCardForm);
