import types from './types';

const initialState = {
  senders: [],
  meta: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_MESSAGE_SENDERS_SUCCESS:
      return {
        ...state,
        senders: [...action.payload.message_senders],
        meta: {
          ...state.meta,
          ...action.payload.meta,
        },
      };
    case types.FLUSH_MESSAGE_SENDERS_ALL_STATE:
      return initialState;
    default:
      return state;
  }
}
