import React, {Component} from 'react';

import cn from 'classnames';
import snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import {selectIsAdmin} from 'client/ducks/user/selectors';

import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import FilterLabel from 'client/components/common/filter-label';
import SearchField from 'client/components/common/search-field';

import LeadsFiltersModal from 'client/components/leads/components/modals/leads-filters-modal/leads-filters-modal';
import {mapFilter} from 'client/components/leads/leads-list/helper';

import cssModule from './leads-list-toolbar.module.scss';

const b = bem('leads-list-toolbar', {cssModule});

class LeadsListToolbar extends Component {
  static defaultProps = {
    isClientVersion: false,
    filter: {},
    meta: {},
    isPossibleToAddColumn: false,
    showImport: () => null,
    showExport: () => null,
    showExportAll: () => null,
    addNewColumn: () => null,
    openTableSettings: () => null,
    openClientVisibilitySettings: () => null,
    loadingColumns: false,
  };

  static propTypes = {
    isClientVersion: PropTypes.bool,
    isAdmin: PropTypes.bool.isRequired,
    isReportTable: PropTypes.bool,
    onFilterFieldClear: PropTypes.func.isRequired,
    showImport: PropTypes.func,
    showExport: PropTypes.func,
    showExportAll: PropTypes.func,
    onFilterClear: PropTypes.func.isRequired,
    LANGUAGE: PropTypes.object.isRequired,
    LANGUAGE_TABLE: PropTypes.object.isRequired,
    filter: PropTypes.object,
    showFilter: PropTypes.func.isRequired,
    deleteLeads: PropTypes.func.isRequired,
    addNewColumn: PropTypes.func,
    openTableSettings: PropTypes.func,
    openClientVisibilitySettings: PropTypes.func,
    meta: PropTypes.object,
    isPossibleToAddColumn: PropTypes.bool,
    loadingColumns: PropTypes.bool,
    onSearchChange: PropTypes.func.isRequired,
  };
  onFilterClear = () => {
    this.props.onFilterClear();
  };

  checkOperationFilter(data) {
    delete data.created;
    delete data.participated;
    delete data.m;
    if (data.optInRadio !== LeadsFiltersModal.optInValues.noActive) {
      delete data.optInRadio;
    }
    return data;
  }

  renderTooltip() {
    return <span id="tooltip-1">{this.props.LANGUAGE.PLEASE_DELETE_ONE}</span>;
  }

  render() {
    const {
      isClientVersion,
      onSearchChange,
      onFilterFieldClear,
      LANGUAGE,
      LANGUAGE_TABLE,
      filter,
      meta,
      checkedLength,
      showFilter,
      deleteLeads,
      addNewColumn,
      openTableSettings,
      openClientVisibilitySettings,
      showImport,
      showExport,
      showExportAll,
      isReportTable,
      isPossibleToAddColumn,
      isAdmin,
      loadingColumns,
    } = this.props;
    return (
      <div className={cn('flex-container flex-justify-between flex-align-bottom', b('toolbar'))}>
        <div>
          <SearchField
            placeholder={LANGUAGE.SEARCH_PLACEHOLDER}
            onSearch={onSearchChange}
            onClear={() => onSearchChange('')}
            searchDefault={this.props.searchDefault}
            cssModifier={cn('input', b('search-input'))}
          />

          <button className="button button--bg-2 " onClick={showFilter}>
            <Icon name="filter" className="button__icon" />
            <span>
              {`${LANGUAGE.FILTERS_BUTTON}
              (${Object.keys(this.checkOperationFilter(mapFilter(filter))).length})`}
            </span>
          </button>
          <span className={b('search-result-num')}>
            <span className="main-text main-text--bold">{meta.total_count}</span>
            <span className="main-text">{` ${LANGUAGE_TABLE.RESULTS}`}</span>
          </span>
          <div className={b('filter-labels')}>
            {['phone', 'email', 'visuals']
              .filter((key) => filter[key] && filter[key] !== 'false')
              .map((key) => (
                <FilterLabel
                  key={key}
                  text={LANGUAGE[`WITH_${key.toUpperCase()}`]}
                  onDelete={() => onFilterFieldClear([key])}
                />
              ))}

            {['participatedFrom', 'participatedTo', 'createdFrom', 'createdTo']
              .filter((key) => filter[key])
              .map((key) => (
                <FilterLabel
                  key={key}
                  text={`${LANGUAGE[snakeCase(key).toUpperCase()]}: ${filter[key]}`}
                  onDelete={() => onFilterFieldClear([key])}
                />
              ))}

            {filter.gender && filter.gender !== 'false' && (
              <FilterLabel
                text={`${LANGUAGE.GENDER}: ${filter.gender}`}
                onDelete={() => onFilterFieldClear(['gender'])}
              />
            )}
            {filter.optInRadio && filter.optInRadio === LeadsFiltersModal.optInValues.noActive && (
              <FilterLabel
                text={`${LANGUAGE.OPT_IN_RADIO}: ${filter.optInRadio}`}
                className="leads-filter-label"
                onDelete={() => onFilterFieldClear(['optInRadio'])}
              />
            )}

            {['operation', 'autotask', 'opt_in']
              .filter((key) => filter[key])
              .map((key) =>
                filter[key].map(({label, value}) => (
                  <FilterLabel
                    key={`${key}-${value}`}
                    text={`${LANGUAGE[key.toUpperCase()]}: ${decodeURIComponent(label)}`}
                    className="leads-filter-label"
                    onDelete={() => onFilterFieldClear([key], value)}
                  />
                )),
              )}

            {['client', 'source']
              .filter((key) => filter[key] && filter[key].value !== '0')
              .map((key) => (
                <FilterLabel
                  key={key}
                  text={`${LANGUAGE[key.toUpperCase()]}: ${decodeURIComponent(filter[key].label)}`}
                  className="leads-filter-label"
                  onDelete={() => onFilterFieldClear([key])}
                />
              ))}

            {Object.keys(this.checkOperationFilter(mapFilter(filter))).length > 0 && (
              <button onClick={this.onFilterClear} className="button button--bg-2">
                {LANGUAGE.CLEAR_FILTERS_BUTTON}
              </button>
            )}
          </div>
        </div>
        <div className={b('action-bar')}>
          {isClientVersion && !isReportTable && isAdmin && (
            <button className="button button--bg-2" onClick={showExportAll}>
              <Icon name="export" className="button__icon" />
              <span>{LANGUAGE.EXPORT_ALL_BUTTON}</span>
            </button>
          )}
          {isAdmin && checkedLength > 0 && (
            <button className="button button--bg-2" onClick={deleteLeads}>
              <Icon name="trash" className="button__icon" width={17} height={19} />
              <span>{`${LANGUAGE.DELETE_LEADS_BUTTON} (${checkedLength})`}</span>
            </button>
          )}
          {isClientVersion && isAdmin && !isReportTable && (
            <button className="button button--bg-2" onClick={openClientVisibilitySettings}>
              <span>{LANGUAGE.CLIENT_VISIBILITY}</span>
            </button>
          )}
          {isClientVersion &&
            !isReportTable &&
            isAdmin &&
            (isPossibleToAddColumn ? (
              <button className="button button--bg-2" onClick={addNewColumn}>
                <Icon name="plus" className="button__icon" />
                <span>{LANGUAGE.LEAD_ADD_COLUMNS_BUTTON}</span>
              </button>
            ) : (
              <Popover position="right" overlay={this.renderTooltip()}>
                <div className="add-column-info">
                  <span className="main-text">{LANGUAGE.IMPOSSIBLE_TO_ADD}</span>
                </div>
              </Popover>
            ))}
          {isClientVersion && !isReportTable && isAdmin && (
            <button className="button button--bg-2" onClick={showExport}>
              <Icon name="export" className="button__icon" />
              <span>{LANGUAGE.EXPORT_BUTTON}</span>
            </button>
          )}
          {isClientVersion && !isReportTable && isAdmin && (
            <button className="button button--bg-2" onClick={showImport}>
              <Icon name="import" className="button__icon" />
              <span>{LANGUAGE.IMPORT_BUTTON}</span>
            </button>
          )}
          {isClientVersion && !isReportTable && (
            <button className="button button--bg-2" onClick={openTableSettings} disabled={loadingColumns}>
              <Icon name="settings" className="button__icon icon-new-settings--size-1" />
              <span>{LANGUAGE.TABLE_SETTINGS_BUTTON}</span>
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default connect((state) => ({isAdmin: selectIsAdmin(state)}))(LeadsListToolbar);
