import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {SubmissionError, submit} from 'redux-form';

import {getCustomColumns} from 'client/ducks/custom-columns/actions';
import {getLead, editLeadInfo} from 'client/ducks/lead/actions';
import {selectEditedFields, selectEditedCustomFields, selectLead} from 'client/ducks/lead/selectors';

import LeadsInformationCard from '../../components/cards/leads-information-card/leads-information-card';
import BasicInfoForm from '../../components/forms/basic-info-form/basic-info-form';
import CustomInfoForm from '../../components/forms/custom-info-form/custom-info-form';
import LeadsBasicInfo from '../../components/leads-basic-info/leads-basic-info';
import LeadsCustomInfo from '../../components/leads-custom-info/leads-custom-info';

class LeadsBasicInformation extends Component {
  static propTypes = {
    languageState: PropTypes.object,
    leadId: PropTypes.string.isRequired,
    getLead: PropTypes.func.isRequired,
    editLeadInfo: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    lead: PropTypes.object.isRequired,
    basicInfoFormValues: PropTypes.object,
    customInfoFormValues: PropTypes.object,
    getCustomColumns: PropTypes.func.isRequired,
    customColumns: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      editBasicInfo: false,
      editCustomInfo: false,
    };

    this.state = {
      editBasicInfo: false,
      editCustomInfo: false,
      newBasicInfo: {},
      validPhone: true,
    };

    this.LANGUAGE = props.languageState.payload.LEADS_PROFILE;
  }

  componentDidMount() {
    this.fetchBasicInfo().then(() => {
      this.props.getCustomColumns(this.props.lead.client.id, true);
    });
  }

  fetchBasicInfo = () => {
    const params = {
      include: [
        'participations',
        'client',
        'last_participation',
        'last_export',
        'first_source',
        'active_opt_ins',
        {
          participations: {
            interaction: {
              interface: {
                automation_task: {
                  '[operation]': '',
                },
              },
            },
          },
        },
        {
          first_source: {
            client: '',
          },
        },
        {
          last_export: {
            client: '',
          },
        },
        {
          first_source: {
            interaction: {
              interface: {
                automation_task: {
                  '[operation][automation_task]': 'operation',
                },
              },
            },
          },
        },
      ],
    };
    return this.props.getLead(this.props.leadId, params);
  };

  submitBasicInfoForm = () => {
    const {basicInfoFormValues, leadId} = this.props;
    return this.props.editLeadInfo(leadId, basicInfoFormValues).catch((error) => {
      if (error.status === 422 && error.response.errors.phone) {
        throw new SubmissionError({phone: this.LANGUAGE.ERRORS.INVALID_PHONE});
      }
    });
  };
  submitCustomInfoForm = () => {
    const {customInfoFormValues, leadId} = this.props;
    return this.props.editLeadInfo(leadId, customInfoFormValues);
  };

  handleEditBasicInfoClick = () => {
    if (this.state.editBasicInfo) {
      this.props.submit(BasicInfoForm.formName);
    }
    this.setState((prevState) => ({editBasicInfo: !prevState.editBasicInfo}));
  };

  handleEditCustomInfoClick = () => {
    if (this.state.editCustomInfo) {
      this.props.submit(CustomInfoForm.formName);
    }
    this.setState((prevState) => ({editCustomInfo: !prevState.editCustomInfo}));
  };

  render() {
    const {customColumns} = this.props;

    return (
      <div className="page__block-container page__row">
        <div className="page__block page__block--size-3">
          <LeadsInformationCard title={this.LANGUAGE.BASIC_INFO_TITLE} onEditClick={this.handleEditBasicInfoClick}>
            <LeadsBasicInfo
              editMode={this.state.editBasicInfo}
              data={this.props.lead}
              validPhone={this.state.validPhone}
              submitBasicInfoForm={this.submitBasicInfoForm}
            />
          </LeadsInformationCard>
        </div>
        <div className="page__block page__block--size-3">
          <LeadsInformationCard
            title={this.LANGUAGE.CUSTOM_INFO_TITLE}
            onEditClick={this.handleEditCustomInfoClick}
            canEdit={customColumns.payload.all_lead_columns.length > 0}
          >
            <LeadsCustomInfo
              editMode={this.state.editCustomInfo}
              data={this.props.lead}
              customColumns={customColumns}
              submitCustomInfoForm={this.submitCustomInfoForm}
            />
          </LeadsInformationCard>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    languageState: state.languageState,
    lead: selectLead(state),
    basicInfoFormValues: selectEditedFields(state, BasicInfoForm.formName),
    customInfoFormValues: selectEditedCustomFields(state, CustomInfoForm.formName),
    customColumns: state.customColumns,
  }),
  {getLead, editLeadInfo, submit, getCustomColumns},
)(LeadsBasicInformation);
