import {hasRestrictedCharacters, restrictedCharacters} from 'client/services/validator';

const isNotUniqPathName = (pathName, domainName, availableDomains) => {
  return availableDomains
    .filter((availableDomain) => availableDomain?.id === domainName)
    .map((item) => item?.path_name_list.includes(pathName.toLowerCase()))[0];
};

export default (formValues = {}, availableDomains = [], {lang}) => {
  const {path_name, domain_name} = formValues;
  const errors = {};

  if (!path_name) {
    errors.path_name = lang.REQUIRED;
  }

  if (!domain_name) {
    errors.domain_name = lang.REQUIRED;
  }

  if (path_name && hasRestrictedCharacters(path_name)) {
    let errorMessages = '';
    restrictedCharacters(path_name).map((error) => {
      errorMessages += `${lang.INVALID_CHARACTERS.split('<X>')[0]} ${error} ${
        lang.INVALID_CHARACTERS.split('<X>')[1]
      } \n`;
    });
    errors.path_name = errorMessages;
  }

  if (path_name && isNotUniqPathName(path_name, domain_name, availableDomains)) {
    errors.path_name = lang.URL_EXISTED;
  }

  return errors;
};
