import React, {Component} from 'react';

import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, SubmissionError} from 'redux-form';

import {getAvailableColumnAdaptersByClient, flushColumnAdaptersAllState} from 'client/ducks/column-adapters/actions';
import {getOperations, flushOperationsAllState} from 'client/ducks/operations/actions';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {TranslationJsx} from 'client/models/language/types';
import {TASK_TYPES} from 'client/models/operations/constants';

import BCVarBlock from './components/bc-var-block';

import '../bc-step.scss';

class BCPersonalizeStep extends Component {
  static propTypes = {
    templateVariables: PropTypes.array,
    taskId: PropTypes.string.isRequired,
    taskType: PropTypes.string.isRequired,
    operationId: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    getAdapters: PropTypes.func.isRequired,
    getOperations: PropTypes.func.isRequired,
    flushOperationsAllState: PropTypes.func.isRequired,
    flushColumnAdaptersAllState: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    templateInitialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    disabledWhilePerforming: PropTypes.bool.isRequired,
    error: TranslationJsx,
  };

  static defaultProps = {
    templateVariables: [],
    templateInitialValues: {},
    error: '',
  };

  static formName = 'BCPersonalizeStepForm';

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.BROADCASTING_TASK.PERSONALIZE_STEP;
  }

  componentDidMount() {
    this.props.getAdapters(this.props.clientId);
    this.getOperations();
  }

  componentWillUnmount() {
    this.props.flushOperationsAllState();
    this.props.flushColumnAdaptersAllState();
  }

  getOperations = () => {
    const params = {
      q: {
        client_id_eq: this.props.clientId,
      },
      include: 'operation_datum_columns.external_column_adapter',
    };

    return this.props.getOperations(params);
  };

  save = (params) => {
    const {taskType} = this.props;

    const FIELD_MAP = {
      [TASK_TYPES.SMS]: 'name',
      [TASK_TYPES.EMAIL]: 'email_template_variable_id',
    };

    const ID_OR_NAME = 0;
    const VALUE = 1;

    const variables = Object.entries(params).map((item) => {
      const idOrName = item[ID_OR_NAME].slice(1);

      const {required, column_adapter, operation, mapping_item_id} = item[VALUE];

      return {
        required: required || false,
        message_task_id: this.props.taskId,
        id: mapping_item_id || null,
        [FIELD_MAP[taskType]]: idOrName,
        column_adapter_id: column_adapter.id,
        operation_id: (operation && operation.value) || null,
      };
    });

    const body = {
      message_task: {
        message_task_mapping_items: variables,
      },
    };

    return this.props.onSave(body).then(({error}) => {
      if (error) {
        throw new SubmissionError({_error: this.LANGUAGE.ERRORS.SUBMISSION});
      }
    });
  };

  render() {
    const {templateVariables, handleSubmit, pristine, disabledWhilePerforming: disabled, error} = this.props;

    return (
      <div className="bc-step">
        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          {templateVariables.length > 0 && (
            <div className="bc-step__save-btn-container bc-step__save-btn-container--view-1">
              {error && <div className="message-error-text bc-step__submit-error-message">{error}</div>}
              <button type="submit" className="button button--bg-14" disabled={pristine}>
                {this.LANGUAGE.SAVE_BUTTON}
              </button>
            </div>
          )}
          <CustomScrollbars
            cssModifier="custom-scrollbars--hide-hor-scroll"
            scrollbarProps={{
              autoHeightMax: 620,
            }}
          >
            <div className="bc-step__inner bc-step__inner--view-1">
              <div className="bc-step__condition-form">
                {templateVariables && templateVariables.length > 0 ? (
                  templateVariables.map((v, index) => {
                    return (
                      <div style={{zIndex: 1000 - index}} key={v.id} className="bc-step__var-block">
                        <BCVarBlock
                          disabled={disabled}
                          formName={BCPersonalizeStep.formName}
                          varId={v.id}
                          isVarArray={v.array}
                          varName={v.name}
                          varRequired={v.required}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div>{this.LANGUAGE.NO_VARS_LABEL}</div>
                )}
              </div>
            </div>
          </CustomScrollbars>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: ownProps.templateInitialValues,
    languageState: state.languageState,
  };
};

const mapDispatchToProps = {
  getOperations,
  getAdapters: getAvailableColumnAdaptersByClient,
  flushOperationsAllState,
  flushColumnAdaptersAllState,
};

export default flow([
  reduxForm({
    form: BCPersonalizeStep.formName,
    enableReinitialize: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
])(BCPersonalizeStep);
