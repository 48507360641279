import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {Translation} from 'client/models/language/types';

import cssModule from './count-block.module.scss';

type CountBlockProps = {
  className?: string;
  num?: string | number;
  totalText?: Translation;
  itemText?: Translation;
  itemName?: Translation;
};

const b = bem('count-block', {cssModule});

const CountBlock: React.FC<CountBlockProps> = (props) => {
  const {className = '', num = 0, totalText, itemText, itemName} = props;

  const lang = useLanguage('COMMON');

  return (
    <div className={cn(b(), className)}>
      <div className={b('num')} role="contentinfo" aria-label="number">
        {num}
      </div>
      <div className={b('text')}>
        <p>{totalText || lang.COUNT_BLOCK.TOTAL}</p>
        <p>
          {itemText || lang.COUNT_BLOCK.ITEM_TEXT} {itemName}
        </p>
      </div>
    </div>
  );
};

export default CountBlock;
