import React, {PureComponent} from 'react';

import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import {USER_MEMBERSHIP} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import cssModule from './visuals-filters-modal.module.scss';

const b = bem('visuals-filters-modal', {cssModule});

class VisualsFiltersModal extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    filters: PropTypes.object,
    onFiltersChange: PropTypes.func.isRequired,
    changeImmediately: PropTypes.bool,
    accessLevel: PropTypes.string.isRequired,
    totalCount: PropTypes.number,
  };

  static defaultProps = {
    filters: {},
    totalCount: 0,
    changeImmediately: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: {
        fileType: props.filters.fileType || [],
        fileFormat: props.filters.fileFormat || [],
        orientation: props.filters.orientation || [],
        origin: props.filters.origin || '',
        lead: props.filters.lead || '',
        comment: props.filters.comment || '',
      },
    };
  }

  updateFilters = (name, value) => {
    this.setState((state) => {
      const filters = cloneDeep(state.filters);
      const isArray = Array.isArray(filters[name]);

      if (isArray) {
        if (filters[name].includes(value)) {
          filters[name] = filters[name].filter((item) => item !== value);
        } else {
          filters[name].push(value);
        }
      }

      if (!isArray) {
        if (filters[name] === value) {
          delete filters[name];
        } else {
          filters[name] = value;
        }
      }

      if (this.props.changeImmediately) {
        this.props.onFiltersChange({filters});
      }

      return {filters};
    });
  };

  isFilterSet = (name, value) => {
    const {filters} = this.state;
    const isArray = Array.isArray(filters[name]);

    if (isArray) {
      return filters[name].includes(value);
    }

    return filters[name] === value;
  };

  renderFilterButton = (label, name, value) => {
    return (
      <button
        className={b('filter-button', {set: this.isFilterSet(name, value)})}
        onClick={() => this.updateFilters(name, value)}
      >
        {label}
      </button>
    );
  };

  handleShowResults = () => {
    const {changeImmediately, onFiltersChange, onClose} = this.props;

    if (!changeImmediately) {
      onFiltersChange({filters: this.state.filters});
    }

    onClose();
  };

  render() {
    const {lang, onClose, accessLevel, changeImmediately, totalCount} = this.props;

    return (
      <Modal title={lang.TITLE} className={b()} onClose={onClose} classNames={{body: b('body')}}>
        <div className={b('content')}>
          <div className={b('group')}>
            <div className={b('group-label')}>{lang.FILE_TYPE}</div>
            <div>
              {this.renderFilterButton(lang.IMAGE, 'fileType', 'image')}
              {this.renderFilterButton(lang.VIDEO, 'fileType', 'video')}
              {this.renderFilterButton(lang.TEXT, 'fileType', 'text')}
              {this.renderFilterButton('PDF', 'fileType', 'pdf')}
            </div>
          </div>
          <div className={b('group')}>
            <div className={b('group-label')}>{lang.FILE_FORMAT}</div>
            <div>
              {this.renderFilterButton('JPG', 'fileFormat', 'jpg')}
              {this.renderFilterButton('PNG', 'fileFormat', 'png')}
              {this.renderFilterButton('TIFF', 'fileFormat', 'tiff')}
              {this.renderFilterButton('GIF', 'fileFormat', 'gif')}
              {this.renderFilterButton('PDF', 'fileFormat', 'pdf')}
              {this.renderFilterButton('AVI', 'fileFormat', 'avi')}
              {this.renderFilterButton('MOV', 'fileFormat', 'mov')}
              {this.renderFilterButton('WMV', 'fileFormat', 'wmv')}
              {this.renderFilterButton('MPEG', 'fileFormat', 'mpeg')}
            </div>
          </div>
          <div className={b('group')}>
            <div className={b('group-label')}>{lang.ORIENTATION}</div>
            <div>
              {this.renderFilterButton(lang.PORTRAIT, 'orientation', 'portrait')}
              {this.renderFilterButton(lang.LANDSCAPE, 'orientation', 'landscape')}
              {this.renderFilterButton(lang.SQUARE, 'orientation', 'square')}
            </div>
          </div>
          <div className={b('group')}>
            <div className={b('group-label')}>{lang.ORIGIN}</div>
            <div>
              {this.renderFilterButton(lang.DEVICE, 'origin', 'device')}
              {this.renderFilterButton(lang.ONLINE, 'origin', 'online')}
              {this.renderFilterButton(lang.DIRECT, 'origin', 'direct')}
            </div>
          </div>
          {accessLevel === USER_MEMBERSHIP.CLIENT_ADMIN && (
            <div className={b('group')}>
              <div className={b('group-label')}>{lang.LEAD}</div>
              <div>
                {this.renderFilterButton(lang.EXISTS, 'lead', 'exists')}
                {this.renderFilterButton(lang.NO_LEAD, 'lead', 'no_lead')}
              </div>
            </div>
          )}
          <div className={b('group')}>
            <div className={b('group-label')}>{lang.COMMENTS}</div>
            <div>
              {this.renderFilterButton(lang.WITH_COMMENTS, 'comment', 'with')}
              {this.renderFilterButton(lang.NO_COMMENTS, 'comment', 'no')}
            </div>
          </div>
          <div className={b('results-button-wrap')}>
            <button className={b('results-button')} onClick={this.handleShowResults}>
              {lang.SHOW_RESULTS} {changeImmediately && `(${totalCount})`}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect((state) => ({
  lang: state.languageState.payload.CLIENT_VISUALS_FILTERS_MODAL,
}))(VisualsFiltersModal);
