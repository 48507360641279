import {Membership} from 'client/models/memberships/types';

import {TrackingUserValues} from './types';

const mapMembership = (values: TrackingUserValues, clientId: number, initialData?: Membership | null) => {
  if (initialData?.place_accesses?.[0].place_id === values.store_id) {
    return {};
  } else if (initialData) {
    return {
      membership: {
        id: initialData.id,
        place_accesses: [{id: initialData?.place_accesses?.[0]?.id, _destroy: true}, {place_id: values.store_id}],
      },
    };
  }

  return {
    membership: {
      client_id: clientId,
      type: 'CompanyMembership',
      access_level: 'places',
      place_accesses: [{place_id: values.store_id}],
    },
  };
};

export default (values: TrackingUserValues, clientId: number, initialData?: Membership | null) => {
  return {
    prize_tracking_user: {
      login: values.login,
      password: values.password || null,
      full_name: values.name,
      locale: values.locale,
      ...mapMembership(values, clientId, initialData),
    },
  };
};
