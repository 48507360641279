import update from 'immutability-helper';
import defaultsDeep from 'lodash/defaultsDeep';
import get from 'lodash/get';
import moment from 'moment';

import {TIME_UNITS} from './constants';

import {filterScenarioVariablesByType, compactObject} from '../helpers';

export const createImplementation = () => ({
  kind: 'in',
  time_unit: TIME_UNITS.find((unit) => unit.default).value,
  perform_in: 1,
  perform_at: null,
  variable_id: null,
});

export const getDateTimeScenarioVariables = (scenarioVariables) => {
  return filterScenarioVariablesByType(scenarioVariables, 'datetime');
};

export const getIntegerAndDecimalScenarioVariables = (scenarioVariables) => {
  const integer = filterScenarioVariablesByType(scenarioVariables, 'integer');
  const decimal = filterScenarioVariablesByType(scenarioVariables, 'decimal');

  return [...integer, ...decimal];
};

const calculateKind = (values) => {
  switch (values.kind) {
    case 'at':
      return 'on';
    case 'as':
      return 'on_variable';
    default:
      return values.kind;
  }
};

const getForm = (kind, values, scenarioVariables) => {
  const {time_unit, perform_in, perform_at, variable_id} = values;

  const defaultTimeUnit = TIME_UNITS.find((unit) => unit.default).value;
  const dateTimeScenarioVars = getDateTimeScenarioVariables(scenarioVariables);
  const integerAndDecimalVars = getIntegerAndDecimalScenarioVariables(scenarioVariables);
  const datetime = get(dateTimeScenarioVars, '[0].id', null);
  const integerdecimal = get(integerAndDecimalVars, '[0].id', null);

  const defaultForm = {
    performIn: {
      value: 1,
      unit: defaultTimeUnit,
    },
    performInVariable: {
      value: integerdecimal,
      unit: defaultTimeUnit,
    },
    performOn: {
      value: moment(),
    },
    performOnVariable: {
      value: datetime,
    },
  };

  switch (kind) {
    case 'in':
      return {
        ...defaultForm,
        performIn: {
          value: perform_in,
          unit: time_unit,
        },
      };
    case 'in_variable':
      return {
        ...defaultForm,
        performInVariable: {
          value: variable_id,
          unit: time_unit,
        },
      };
    case 'on':
      return {
        ...defaultForm,
        performOn: {
          value: moment(perform_at),
        },
      };
    case 'on_variable':
      return {
        ...defaultForm,
        performOnVariable: {
          value: variable_id,
        },
      };
    default:
      return defaultForm;
  }
};

export const getInitialValues = (data, state) => {
  const {
    scenario: {
      payload: {scenario_variables: scenarioVariables, scenario_step_variables: scenarioStepVariables = []},
    },
  } = state;

  const vars = scenarioVariables.concat(scenarioStepVariables);

  const dateTimeScenarioVariables = getDateTimeScenarioVariables(vars);
  const integerAndDecimalScenarioVariables = getIntegerAndDecimalScenarioVariables(vars);

  const kind = calculateKind(data.implementation);
  const form = {
    kind,
    ...getForm(kind, data.implementation, [...dateTimeScenarioVariables, ...integerAndDecimalScenarioVariables]),
  };

  const initial = defaultsDeep({}, compactObject(data), {
    form,
  });

  return initial;
};

const getImplementationForm = (form) => {
  const {kind, performIn, performInVariable, performOn, performOnVariable} = form;

  switch (kind) {
    case 'in':
      return {
        kind: 'in',
        perform_in: performIn.value,
        time_unit: performIn.unit,
        perform_at: null,
        variable_id: null,
      };
    case 'in_variable':
      return {
        kind: 'in_variable',
        perform_in: null,
        time_unit: performInVariable.unit,
        perform_at: null,
        variable_id: performInVariable.value,
      };
    case 'on':
      return {
        kind: 'at',
        perform_in: null,
        time_unit: null,
        perform_at: performOn.value,
        variable_id: null,
      };
    case 'on_variable':
      return {
        kind: 'as',
        perform_in: null,
        time_unit: null,
        perform_at: null,
        variable_id: performOnVariable.value,
      };
    default:
      return {};
  }
};

const transformFormData = (values) => {
  return update(values, {
    $unset: ['form'],
    implementation: {
      $merge: {
        ...getImplementationForm(values.form),
      },
    },
  });
};

export const submitForm = (values, dispatch, props) => {
  const transformedValues = transformFormData(values);

  return props.onSubmitForm(transformedValues);
};
