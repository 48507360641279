import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {set} from 'client/common/meta/meta.actions';

import AutotaskPlanPlaces from '../components/autotask-plan/places-view';

class AutotaskPlanPlacesPage extends Component {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const {plan: newPlan, setMetaAction} = this.props;
    const {plan: oldPlan} = prevProps;

    if (newPlan !== oldPlan) {
      setMetaAction({
        title: newPlan.name,
      });
    }
  }

  render() {
    return <AutotaskPlanPlaces id={+this.props.match.params.id} />;
  }
}

export default connect(({plans}) => ({plan: plans.plan}), {
  setMetaAction: set,
})(AutotaskPlanPlacesPage);
