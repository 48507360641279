export default {
  GET_ANIMATIONS_REQUEST: '@@animations/GET_ANIMATIONS_REQUEST',
  GET_ANIMATIONS_SUCCESS: '@@animations/GET_ANIMATIONS_SUCCESS',
  GET_ANIMATIONS_ERROR: '@@animations/GET_ANIMATIONS_ERROR',

  GET_ANIMATION_REQUEST: '@@animations/GET_ANIMATION_REQUEST',
  GET_ANIMATION_SUCCESS: '@@animations/GET_ANIMATION_SUCCESS',
  GET_ANIMATION_ERROR: '@@animations/GET_ANIMATION_ERROR',

  GET_ANOMALY_OCCURRENCES_REQUEST: '@@animations/GET_ANOMALY_OCCURRENCES_REQUEST',
  GET_ANOMALY_OCCURRENCES_SUCCESS: '@@animations/GET_ANOMALY_OCCURRENCES_SUCCESS',
  GET_ANOMALY_OCCURRENCES_ERROR: '@@animations/GET_ANOMALY_OCCURRENCES_ERROR',

  UPDATE_ANOMALY_OCCURRENCE_REQUEST: '@@animations/UPDATE_ANOMALY_OCCURRENCE_REQUEST',
  UPDATE_ANOMALY_OCCURRENCE_SUCCESS: '@@animations/UPDATE_ANOMALY_OCCURRENCE_SUCCESS',
  UPDATE_ANOMALY_OCCURRENCE_ERROR: '@@animations/UPDATE_ANOMALY_OCCURRENCE_ERROR',

  GET_ANIMATIONS_STATISTICS_REQUEST: '@@animations/GET_ANIMATIONS_STATISTICS_REQUEST',
  GET_ANIMATIONS_STATISTICS_SUCCESS: '@@animations/GET_ANIMATIONS_STATISTICS_SUCCESS',
  GET_ANIMATIONS_STATISTICS_ERROR: '@@animations/GET_ANIMATIONS_STATISTICS_ERROR',

  CHANGE_ANIMATIONS_STATUS_REQUEST: '@@animations/CHANGE_ANIMATIONS_STATUS_REQUEST',
  CHANGE_ANIMATIONS_STATUS_SUCCESS: '@@animations/CHANGE_ANIMATIONS_STATUS_SUCCESS',
  CHANGE_ANIMATIONS_STATUS_ERROR: '@@animations/CHANGE_ANIMATIONS_STATUS_ERROR',

  DELETE_ANIMATION_REQUEST: '@@animations/DELETE_ANIMATION_REQUEST',
  DELETE_ANIMATION_SUCCESS: '@@animations/DELETE_ANIMATION_SUCCESS',
  DELETE_ANIMATION_ERROR: '@@animations/DELETE_ANIMATION_ERROR',

  UPDATE_ANIMATION_DETAILS_REQUEST: '@@animations/UPDATE_ANIMATION_DETAILS_REQUEST',
  UPDATE_ANIMATION_DETAILS_SUCCESS: '@@animations/UPDATE_ANIMATION_DETAILS_SUCCESS',
  UPDATE_ANIMATION_DETAILS_ERROR: '@@animations/UPDATE_ANIMATION_DETAILS_ERROR',

  SAVE_ANIMATION_DETAILS_REQUEST: '@@animations/SAVE_ANIMATION_DETAILS_REQUEST',
  SAVE_ANIMATION_DETAILS_SUCCESS: '@@animations/SAVE_ANIMATION_DETAILS_SUCCESS',
  SAVE_ANIMATION_DETAILS_ERROR: '@@animations/SAVE_ANIMATION_DETAILS_ERROR',

  GET_ANIMATION_KPIS_REQUEST: '@@animations/GET_ANIMATION_KPIS_REQUEST',
  GET_ANIMATION_KPIS_SUCCESS: '@@animations/GET_ANIMATION_KPIS_SUCCESS',
  GET_ANIMATION_KPIS_ERROR: '@@animations/GET_ANIMATION_KPIS_ERROR',

  GET_ANIMATION_QUESTIONS_REQUEST: '@@animations/GET_ANIMATION_QUESTIONS_REQUEST',
  GET_ANIMATION_QUESTIONS_SUCCESS: '@@animations/GET_ANIMATION_QUESTIONS_SUCCESS',
  GET_ANIMATION_QUESTIONS_ERROR: '@@animations/GET_ANIMATION_QUESTIONS_ERROR',

  GET_ANIMATION_PHOTOS_REQUEST: '@@animations/GET_ANIMATION_PHOTOS_REQUEST',
  GET_ANIMATION_PHOTOS_SUCCESS: '@@animations/GET_ANIMATION_PHOTOS_SUCCESS',
  GET_ANIMATION_PHOTOS_ERROR: '@@animations/GET_ANIMATION_PHOTOS_ERROR',

  CHANGE_CONTACT_INFO_REQUEST: '@@animations/CHANGE_CONTACT_INFO_REQUEST',
  CHANGE_CONTACT_INFO_SUCCESS: '@@animations/CHANGE_CONTACT_INFO_SUCCESS',
  CHANGE_CONTACT_INFO_ERROR: '@@animations/CHANGE_CONTACT_INFO_ERROR',
};
