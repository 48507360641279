import React from 'react';

import moment from 'moment';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import {
  AFFECTATION_ALERT_VALUES,
  AFFECTATION_ALERT_STATUSES,
  DeviceAffectation,
} from 'client/models/device-affectations';

import cssModule from './client-device-modal-status-block.module.scss';

const b = bem('client-device-modal-status-block', {cssModule});

const renderDatetime = (date?: string) =>
  date && (
    <span className={b('datetime')}>
      <span className={b('date')}>{moment(date).format('DD/MM/YY')}</span>{' '}
      <span className={b('time')}>{moment(date).format('HH:mm:SS')}</span>
    </span>
  );

type ClientDeviceModalStatusBlockProps = {
  affectation: DeviceAffectation;
};

const MAP_ICON_ALERTS = {
  [AFFECTATION_ALERT_VALUES.ERROR]: 'status-error-triangle',
  [AFFECTATION_ALERT_VALUES.WARNING]: 'status-warning-triangle',
  [AFFECTATION_ALERT_VALUES.OK]: 'status-success',
} as const;

const ClientDeviceModalStatusBlock: React.FC<ClientDeviceModalStatusBlockProps> = ({affectation}) => {
  const lang = useLanguage('CLIENT_DEVICES.MODAL.STATUS_BLOCK');

  const active = affectation.alert_inactivity === AFFECTATION_ALERT_STATUSES.ACTIVE;

  return (
    <div className={b()}>
      <div className={b('column')}>
        <p className={b('title')}>{lang.ACTIVITY}</p>
        {affectation.alert_inactivity ? (
          <Popover overlay={active ? lang.ACTIVE_TOOLTIP : lang.INACTIVE_TOOLTIP} triggerClassName={b('icon')}>
            <Icon name={active ? 'status-success' : 'status-not-active'} />
          </Popover>
        ) : (
          '–'
        )}
        {!active && affectation.timestamp_inactivity && moment(affectation.timestamp_inactivity).format('DD/MM/YY')}
      </div>
      <div className={b('column')}>
        <p className={b('title')}>{lang.PAPER}</p>
        {affectation.alert_paper ? (
          <Icon name={MAP_ICON_ALERTS[affectation.alert_paper]} className={b('icon')} height={18} width={16} />
        ) : (
          '–'
        )}
        {renderDatetime(affectation.timestamp_paper)}
      </div>
      <div className={b('column')}>
        <p className={b('title')}>{lang.TOKEN}</p>
        {affectation.alert_token ? (
          <Icon
            name={MAP_ICON_ALERTS[affectation.alert_token] || 'status-success'}
            className={b('icon')}
            height={18}
            width={16}
          />
        ) : (
          '–'
        )}
        {renderDatetime(affectation.timestamp_token)}
      </div>
    </div>
  );
};

export default ClientDeviceModalStatusBlock;
