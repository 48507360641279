import mapValidityValues from 'client/components/prizes/fieldsets/prize-diy-validity-fieldset/mapFormValues';
import {ValidityValues} from 'client/components/prizes/fieldsets/prize-diy-validity-fieldset/types';
import {PRIZE_TYPES} from 'client/models/prizes/constants';

import {MainParamsValues} from './types';

export default (values: MainParamsValues) => {
  return {
    type: values.type === PRIZE_TYPES.COMMON ? null : values.type,
    email_send: values.loss_email,
    sms_send: values.loss_sms,
    loyalty_only: values.loyalty_only,
    level: values.level,
    distribution: values.distribution,
    ...(values.type === PRIZE_TYPES.LOYALTY && {
      loyalty_unit: values.loyalty_unit,
      loyalty_amount: values.loyalty_amount,
    }),
    ...mapValidityValues(values as ValidityValues),
  };
};
