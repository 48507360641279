import React from 'react';

import {useParams} from 'react-router-dom';

import bem from 'client/services/bem';

import CatalogTemplateCard from 'client/components/catalog-templates/components/catalog-template-card';
import {Client} from 'client/models/client/types';
import {ClientTemplate, CatalogTemplateTabType} from 'client/models/templates/types';

import cssModule from './catalog-templates-grid.module.scss';

type CatalogTemplatesGridProps = {
  list: ClientTemplate[];
  fetchData: () => Promise<any>;
  updateTemplate: (id: number, data: Partial<ClientTemplate>) => void;
  agencyClient: Client | null;
};

const b = bem('catalog-templates-grid', {cssModule});

const CatalogTemplatesGrid: React.FC<CatalogTemplatesGridProps> = (props) => {
  const {list, fetchData, updateTemplate, agencyClient} = props;
  const {type} = useParams<{type: CatalogTemplateTabType; clientId: string}>();

  return (
    <div className={b()}>
      {list?.map((template) => (
        <CatalogTemplateCard
          template={template}
          key={template.id}
          type={type}
          templateType="general"
          fetchData={fetchData}
          updateTemplate={updateTemplate}
          agencyClient={agencyClient}
        />
      ))}
    </div>
  );
};

export default CatalogTemplatesGrid;
