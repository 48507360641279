import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/components/common/icon';

import cssModule from './diagram-empty.module.scss';

const b = bem('diagram-empty', {cssModule});

type DiagramEmptyProps = {
  overhead?: boolean;
};

const DiagramEmpty: React.FC<DiagramEmptyProps> = (props) => {
  const {overhead} = props;
  const lang = useLanguage('RESULTS_GRAPHS_CLIENT');

  return (
    <div className={b()}>
      <Icon name="diagram-empty" />
      <p className={b('text')}>{overhead ? lang.DATA_OVERHEAD : lang.NO_DATA}</p>
    </div>
  );
};

export default DiagramEmpty;
