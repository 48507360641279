import React from 'react';

import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

import './tooltip.scss';

function Tooltip({title, name, children}) {
  return (
    <div>
      <p className="tooltip__title">{title}:</p>
      <p className="tooltip__name">{name}</p>
      <div className="tooltip__content">{children}</div>
    </div>
  );
}

Tooltip.propTypes = {
  title: TranslationJsx,
  name: PropTypes.string,
  children: PropTypes.any,
};

export default Tooltip;
