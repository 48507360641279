import React from 'react';

import bem from 'client/services/bem';

import {Translation} from 'client/models/language/types';

import cssModule from './general-device-info.module.scss';

type GeneralDeviceInfoProps = {
  title: Translation;
  values: React.ReactNode[];
};

const b = bem('general-device-info', {cssModule});

const GeneralDeviceInfo: React.FC<GeneralDeviceInfoProps> = ({title, values}) =>
  !values?.filter(Boolean).length ? null : (
    <div className={b()}>
      <span className={b('title')}>{title}</span>
      <span className={b('value')}>
        {values.map((v) => (
          <span>{v}</span>
        ))}
      </span>
    </div>
  );

export default GeneralDeviceInfo;
