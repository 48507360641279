import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

export default (prize, state) => {
  const languages = selectPrizeLanguages(state);

  const result = {};

  languages.forEach((lang) => {
    result[`win_pop_up_image_${lang}`] = prize?.win_pop_up_image_translations?.[lang] || null;
    result[`win_pop_up_image_${lang}_id`] = prize?.win_pop_up_image_translations?.[`${lang}_id`] || null;
  });

  return result;
};
