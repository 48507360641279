import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {getAutotask} from 'client/ducks/autotask/actions';
import {selectAutotask} from 'client/ducks/autotask/selectors';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {addImage} from 'client/ducks/images/actions';

import FileButton from 'client/common/buttons/file-button';

import {isOperationArchived} from 'client/models/operations/helpers';

import './diy-op-main-block-icon.scss';

const b = bem('diy-op-main-block-icon');

const DiyOpMainBlockIcon = (props) => {
  const {className, operation} = props;
  const langCommon = useLanguage('COMMON');
  const autotask = useSelector(selectAutotask);
  const dispatch = useDispatch();
  const client = useSelector(selectCurrentClient);

  const fetchAutotask = () => {
    return dispatch(
      getAutotask(autotask.id, {
        include: {
          custom_prize_type_names: null,
        },
        include_automation_task_client_visuals_count: null,
        include_automation_task_visible_diagrams_count: null,
      }),
    );
  };

  const onUpload = (file) => {
    dispatch(addImage(autotask.id, 'automation_task', file)).then(fetchAutotask);
  };

  const showButton = client.id && !client.auto_configuration && !isOperationArchived(operation);

  return (
    <div className={cn(b(), className)}>
      <span className={b('label')}>{langCommon.ICON}</span>
      <div className={b('preview')}>
        {autotask?.image?.url && <img src={autotask?.image?.url} className={b('image')} />}
      </div>
      {showButton && (
        <FileButton
          className={b('button')}
          label={langCommon.CLICK_TO_SELECT}
          onChange={onUpload}
          fontSize="small"
          color="primary"
          link={true}
        />
      )}
    </div>
  );
};

DiyOpMainBlockIcon.propTypes = {
  className: PropTypes.string,
  operation: PropTypes.object.isRequired,
};

DiyOpMainBlockIcon.defaultProps = {
  className: '',
};

export default DiyOpMainBlockIcon;
