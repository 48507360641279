import React, {Component} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, getFormValues, reduxForm} from 'redux-form';

import {patch} from 'client/services/fetch';

import {blockClientUser} from 'client/ducks/client-users/actions';
import {ACCESS_LEVEL_TYPES} from 'client/ducks/client-users/constants';

import {API_METHODS, APP_ROLES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import ToggleButton from 'client/components/common/custom-buttons/toggle-button';
import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';
import Icon from 'client/components/common/icon';

import './edit-user-modal.scss';

class EditUserModal extends Component {
  static validate({civility, first_name, last_name, phone}, props) {
    const errors = {};
    const regex = /^[+0-9][0-9]*$/;

    if (!civility || !civility.value) {
      errors.civility = props.lang.CIVILITY_IS_REQUIRED_ERROR;
    }
    if (!first_name || !first_name.trim()) {
      errors.first_name = props.lang.FIRST_NAME_IS_REQUIRED_ERROR;
    }
    if (!last_name || !last_name.trim()) {
      errors.last_name = props.lang.LAST_NAME_IS_REQUIRED_ERROR;
    }
    if (errors.phone && !regex.test(phone)) {
      errors.phone = props.lang.PHONE_IS_INVALID_ERROR;
    }

    return errors;
  }

  componentDidUpdate(prevProps) {
    const {initialValues, show} = this.props;
    if (show && !prevProps.show) {
      this.props.initialize(initialValues);
      this.updateSyncErrors(initialValues);
    }
  }

  updateSyncErrors = (values) => {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'EditUserForm',
      },
      payload: {
        syncErrors: EditUserModal.validate(values),
      },
    };
  };

  getAccessLevelLabel = (accessLevel) => {
    switch (accessLevel) {
      case ACCESS_LEVEL_TYPES.NATIONAL:
        return this.props.lang.NATIONAL_ACCESS_LEVEL;
      case ACCESS_LEVEL_TYPES.REGIONS:
        return this.props.lang.REGIONS_ACCESS_LEVEL;
      case ACCESS_LEVEL_TYPES.STORES:
        return this.props.lang.STORES_ACCESS_LEVEL;
      case ACCESS_LEVEL_TYPES.CLIENT_ADMIN:
        return this.props.lang.CLIENT_ADMIN_ACCESS_LEVEL;
      case ACCESS_LEVEL_TYPES.LOCAL:
        return this.props.lang.LOCAL_ACCESS_LEVEL;
      default:
        return '';
    }
  };

  save = (values) => {
    patch(`${API_METHODS.CLIENT_USERS}/${this.props.editingUser.client_user.id}`, {
      client_user: {
        first_name: values.first_name,
        last_name: values.last_name,
        title: values.civility.value,
        memberships: [
          {
            ...this.props.editingUser,
            client_user: undefined, // eslint-disable-line no-undefined
            phone: values.phone,
            title: values.title,
          },
        ],
      },
    });
    this.props.onSave();
  };

  blockUser = () => {
    this.props
      .blockClientUser(this.props.editingUser.client_user.id, !this.props.editingUser.client_user.blocked)
      .then(() => this.props.onSave());
  };

  render() {
    const {show, isAgency, onClose, currentUserIsAdmin, lang, formValues} = this.props;
    const {email, all_companies_access, access_level} = this.props.editingUser;

    return (
      <Modal
        show={show}
        onClose={onClose}
        title={
          <div>
            <h3 className="title pull-left">{lang.EDIT_USER_MODAL_TITLE}</h3>
            <div className="clearfix" />
            <div className="modal-window__subtitle modal-window__email">{email}</div>
          </div>
        }
        dialogClassName="edit-user-modal modal-window--width-1 modal-window--theme-3"
        backdropClassName="modal-window__backdrop"
      >
        <span className="text-additional">
          {lang.BLOCK_CLIENT_USER}
          <ToggleButton
            className="edit-user-modal__toggle"
            disabled={!currentUserIsAdmin}
            value={get(this.props.editingUser, 'client_user.blocked')}
            onToggle={this.blockUser}
          />
        </span>
        <form className="edit-user-modal__form" noValidate="noValidate" onSubmit={this.props.handleSubmit(this.save)}>
          <FormFieldWrap>
            <Field
              cssModifier="select--view-1"
              label={lang.NEW_USER_MODAL_CIVILITY_LABEL}
              name="civility"
              type="select"
              searchable={false}
              clearable={false}
              options={[
                {value: 'M.', label: lang.NEW_USER_MODAL_CIVILITY_M},
                {value: 'Mme.', label: lang.NEW_USER_MODAL_CIVILITY_MME},
              ]}
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field
              name="first_name"
              label={lang.NEW_USER_MODAL_FIRST_NAME_LABEL}
              type="text"
              component={fieldTemplate}
            />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field name="last_name" label={lang.NEW_USER_MODAL_LAST_NAME_LABEL} type="text" component={fieldTemplate} />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field name="title" label={lang.NEW_USER_MODAL_TITLE_LABEL} type="text" component={fieldTemplate} />
          </FormFieldWrap>
          <FormFieldWrap>
            <Field name="phone" label={lang.NEW_USER_MODAL_PHONE_LABEL} type="text" component={fieldTemplate} />
          </FormFieldWrap>
          <FormFieldWrap>
            {isAgency && (
              <div className="list list--view-1 list--bg-1" style={{marginBottom: '20px'}}>
                <h4 className="list__title">{lang.CLIENT_ACCESS_LEVEL_COLUMN}</h4>
                <div className="list__item">
                  <Field
                    cssModifier="radio-button--view-1"
                    name="all_companies_access"
                    label={
                      `${all_companies_access}` === 'true'
                        ? lang.ALL_CLIENTS_ACCESS_LEVEL
                        : lang.SOME_CLIENTS_ACCESS_LEVEL
                    }
                    type="radio"
                    component={fieldTemplate}
                    value={`${all_companies_access}`}
                    disabled
                  />
                  <button
                    className="button button--circle button--bg-3 pull-right"
                    type="button"
                    onClick={() => this.props.onEditAccessClients(formValues.values || {})}
                  >
                    <Icon name="edit" className="button__icon" width={20} height={20} />
                  </button>
                </div>
              </div>
            )}
            <div className="list list--view-1 list--bg-1">
              <h4 className="list__title">
                {isAgency ? lang.AGENCY_ACCESS_LEVEL_COLUMN : lang.NEW_USER_MODAL_ACCESS_LEVEL_TITLE}
              </h4>
              <div className="list__item">
                <Field
                  cssModifier="radio-button--view-1"
                  name="access_level"
                  label={this.getAccessLevelLabel(access_level)}
                  value={access_level}
                  disabled
                  type="radio"
                  component={fieldTemplate}
                />
                <button
                  className="button button--circle button--bg-3 pull-right"
                  type="button"
                  onClick={() =>
                    this.props.onEditAccessAgency({
                      showEditUserModal: false,
                      showEditAgencyAccessModal: 1,
                      showEditCompanyAccessModal: 1,
                      editingUser: {
                        ...this.props.initialValues,
                        ...formValues,
                      },
                    })
                  }
                >
                  <Icon name="edit" className="button__icon" width={20} height={20} />
                </button>
              </div>
            </div>
          </FormFieldWrap>
          <FormFieldWrap isButtonsContainer>
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onClose}>
              {lang.NEW_USER_MODAL_CANCEL_BUTTON}
            </button>
            <button className="button button--bg-3 modal-window__footer-btn" type="submit">
              {lang.SAVE_BUTTON}
            </button>
          </FormFieldWrap>
        </form>
      </Modal>
    );
  }
}

EditUserModal.propTypes = {
  show: PropTypes.bool,
  isAgency: PropTypes.bool,
  editingUser: PropTypes.object,
  initialValues: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  initialize: PropTypes.func,
  handleSubmit: PropTypes.func,
  lang: PropTypes.object,
  formValues: PropTypes.object,
  onEditAccessClients: PropTypes.func,
  onEditAccessAgency: PropTypes.func,
  blockClientUser: PropTypes.func.isRequired,
  currentUserIsAdmin: PropTypes.bool.isRequired,
};

EditUserModal.defaultProps = {
  show: false,
  isAgency: false,
  onClose: () => {},
  onSave: () => {},
  onEditAccessClients: () => {},
  onEditAccessAgency: () => {},
  editingUser: {
    client_user: {},
  },
  initialValues: {},
  formValues: {},
};

const EditUserForm = reduxForm({
  form: 'EditUserForm',
  validate: EditUserModal.validate,
})(EditUserModal);

export default connect(
  ({languageState, userState, ...state}) => ({
    lang: languageState.payload.AGENCY,
    formValues: getFormValues('EditUserForm')(state),
    currentUserIsAdmin: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN].includes(userState.payload.role),
  }),
  {
    blockClientUser,
  },
)(EditUserForm);
