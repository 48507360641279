import React from 'react';

import PropTypes from 'prop-types';

import {getAnimationAnomalyStatus} from 'client/components/instore/helpers';

import AnimationIconCell from '../animation-icon-cell';

const AnimationAnomalyCell = (props) => {
  const {className, item} = props;

  return (
    <AnimationIconCell
      className={className}
      item={item}
      field="animation_days"
      iconPrefix="anomaly"
      iconNameGetter={getAnimationAnomalyStatus}
      testId="animation-anomaly-cell"
    />
  );
};

AnimationAnomalyCell.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    animation_days: PropTypes.arrayOf(
      PropTypes.shape({
        anomaly_occurrences: PropTypes.arrayOf(
          PropTypes.shape({
            viewed: PropTypes.bool.isRequired,
            manual_unviewed: PropTypes.bool.isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

AnimationAnomalyCell.defaultProps = {
  className: null,
};

export default AnimationAnomalyCell;
