import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';

import {CLIENT_PAGES} from 'client/common/config';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';

import cssModule from './agency-clients-table.module.scss';

const b = bem('agency-clients-table', {cssModule});

const AgencyClientsTable = (props) => {
  const {lang, data} = props;
  const [perPage, setPerPage] = useState('5');
  const [sortState, setSortState] = useState({});

  const renderName = ({item}) => {
    return (
      <Link to={`${CLIENT_PAGES.COMPANIES}/${item.id}`} className={b('link')}>
        {item.name}
      </Link>
    );
  };

  const renderAgencyUsersCount = ({item: client}) => (
    <div className="agency-clients-table__link" onClick={() => props.onAccessLevelsClick(client.id, client.name)}>
      {client.full_company_accesses_count}
    </div>
  );

  const handleSortChange = (sort) => {
    props.onSortChange(sort.sortField, sort.sortOrder);
    setSortState(sort);
  };

  return (
    <>
      <div className={b('toolbar-wrapper')}>
        <span className={b('title')}>{`${lang.AGENCY_CLIENTS_TABLE_TITLE}  (${props.totalItems || 0})`}</span>
        <PerPageDropdown
          onChange={(pages) => {
            setPerPage(pages);
            props.onPageChange({page: 1, perPage: pages});
          }}
          value={perPage}
          simpleValue
        />
      </div>
      <ClientTable
        {...sortState}
        data={data}
        onSortChange={handleSortChange}
        className={b()}
        columns={[
          {name: 'name', label: lang.CLIENT_NAME_COLUMN, render: renderName, sortable: true, width: '20%'},
          {
            name: 'poc_agency_membership_title',
            path: 'poc_agency_membership.title',
            label: lang.POC_AGENCY_COLUMN,
            sortable: true,
          },
          {
            name: 'poc_membership_title',
            path: 'poc_membership.title',
            label: lang.POC_CLIENT_COLUMN,
          },
          {
            name: 'operations_count',
            path: 'operations_count',
            sortable: true,
            label: lang.OPERATIONS_COLUMN,
            title: lang.OPERATIONS_COLUMN,
            labelClassName: b('titled-label'),
          },
          {
            name: 'company_accesses_count',
            sortable: true,
            label: lang.ACCESSED_AGENCY_USERS_COLUMN,
            title: lang.ACCESSED_AGENCY_USERS_COLUMN,
            render: renderAgencyUsersCount,
            labelClassName: b('titled-label'),
          },
          {
            name: 'subsidiary_name',
            path: 'subsidiary.name',
            label: lang.SUBSIDIARY_COLUMN,
            sortable: true,
            width: '10%',
          },
        ]}
      />
      <PaginationBar
        data={data}
        perPage={perPage}
        onPageChange={props.onPageChange}
        currentPage={props.currentPage}
        totalPages={props.totalPages}
        totalItems={props.totalItems}
        className={b('pagination')}
      />
    </>
  );
};

AgencyClientsTable.defaultProps = {
  data: [],
  topRightSection: null,
};

AgencyClientsTable.propTypes = {
  data: PropTypes.array,
  children: PropTypes.any,
  topRightSection: PropTypes.any,
  totalPages: PropTypes.number,
  totalItems: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  onAccessLevelsClick: PropTypes.func,
  languageState: PropTypes.object,
};

export default AgencyClientsTable;
