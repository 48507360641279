import find from 'lodash/find';

function mapExportFormat(format) {
  switch (format) {
    case 'csv':
      return 'EXCSV';
    case 'xlsx':
      return 'EXXLS';
    default:
      return 'EXAPI';
  }
}

export function mapParticipationExportReport(report) {
  return {
    id: report.id,
    exportId: report.participation_export?.id,
    name: report.participation_export?.name || '',
    comment: report.participation_export?.comment || '',
    export: report.participation_export,
    total: report.total,
    date: report.created_at,
    userName: report.created_by,
    status: report.status,
    link: report.participations_file.url,
    format: mapExportFormat(report.participation_export?.format || ''),
    participantsReportLink: {
      reportId: report.id,
      total: report.total,
    },
    columnExportItems: report.participation_export?.participation_export_items || [],
    memberships: report.participation_export?.memberships || [],
    allowed_for_all: report.participation_export?.allowed_for_all || false,
    deletable: report.deletable,
    shareable: report.shareable,
    fileName: `${report.participation_export?.name || ''}.${report.participation_export?.format || ''}`,
  };
}

export function mapMembership(membership, report) {
  return {
    ...membership,
    transferableMembershipId: find(report.transferable_memberships, {membership_id: membership.id}).id,
    client_user: {
      ...membership.client_user,
      currentMembership: membership.id,
    },
  };
}
