import sortBy from 'lodash/sortBy';

import {getTemplateCategory} from 'client/components/catalog-templates/helpers';
import {CATALOG_TEMPLATE_TABS} from 'client/models/templates/constants';
import {ClientTemplate} from 'client/models/templates/types';

export const sortTemplates = (templates: ClientTemplate[], clientId: number, access?: string) => {
  const categories: Record<string, ClientTemplate[]> = {
    [CATALOG_TEMPLATE_TABS.WEEZIO]: [],
    [CATALOG_TEMPLATE_TABS.SHARED]: [],
    [CATALOG_TEMPLATE_TABS.MY_TEMPLATES]: [],
  };

  if (access) {
    templates.forEach((template) => {
      if (template && template.client_id === clientId && template.type === 'ClientTemplate') {
        const category = getTemplateCategory(template, access);
        categories[category].push(template);
      }
    });
  }

  return Object.values(categories).reduce((result, category) => {
    return [...result, ...sortBy(category, (obj) => -new Date(obj.created_at).getTime())];
  }, []);
};
