import React from 'react';

import authExpiredLinkImage from 'assets/images/auth-expired-link.png';
import company from 'assets/images/weezio.png';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {useMedia} from 'react-use';

import bem from 'client/services/bem';

import {CLIENT_PAGES} from 'client/common/config';
import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import {TranslationJsx} from 'client/models/language/types';

import './auth-wrapper.scss';

const b = bem('auth-wrapper');

function AuthWrapper(props) {
  const {title, children, popover} = props;
  const isMobile = useMedia('(max-width: 768px)');

  const location = useLocation();

  const expiredPassword = location.pathname === CLIENT_PAGES.PASSWORD_CHANGE && !!location.state?.expired;

  return (
    <div className={b()}>
      <div className={b('container')}>
        <div className={b('column')}>
          <div className={b('image')} key="image">
            <img src={expiredPassword ? authExpiredLinkImage : company} alt="" />
          </div>
        </div>
        <div className={b('column')}>
          <div className={b('form', {'expired-password': expiredPassword})}>
            <div className={b('title')}>
              <span className={b('title-span')}>{title}</span>
              {popover && isMobile && <p className={b('help')}>{popover}</p>}
              {popover && !isMobile && (
                <Popover
                  position="bottom"
                  overlayInnerStyle={{
                    width: 300,
                  }}
                  overlay={<div className={b('notification')}>{popover}</div>}
                >
                  <div className={b('info-icon')}>
                    <Icon name="info-small" width={16} height={16} />
                  </div>
                </Popover>
              )}
            </div>
            <div className={b('content')}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

AuthWrapper.propTypes = {
  title: PropTypes.oneOfType([TranslationJsx, PropTypes.element]).isRequired,
  popover: PropTypes.oneOfType([TranslationJsx, PropTypes.element]),
  children: PropTypes.any.isRequired,
};

AuthWrapper.defaultProps = {
  popover: null,
};

export default React.memo(AuthWrapper);
