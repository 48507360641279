import React from 'react';

import cn from 'classnames';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';

import LayoutPanel from 'client/common/panels/layout-panel';

import Icon from 'client/components/common/icon';

import SaveTemplateButton from 'client/components/diy-operation/buttons/save-template-button';
import DiyOpTemplateModal from 'client/components/diy-operation/modals/diy-op-template-modal';
import {Translation} from 'client/models/language/types';
import {Template} from 'client/models/templates/types';

import cssModule from './diy-op-element-card.module.scss';

const b = bem('diy-op-element-card', {cssModule});

type DiyOpElementCardProps = {
  name: string;
  title: Translation;
  buttons: React.ReactNode;
  documentLink?: string;
  headerId?: string;
  disabled?: boolean;
  className?: string;
  hideSaveButton?: boolean;
  template?: Template;
};

const DiyOpElementCard: React.FC<DiyOpElementCardProps> = (props) => {
  const {name, title, buttons, className, disabled, documentLink, headerId, hideSaveButton, template} = props;
  const [showTemplateModal, toggleTemplateModal] = useToggle(false);

  return (
    <LayoutPanel className={cn(b({disabled: disabled}), className)}>
      {showTemplateModal && template && (
        <DiyOpTemplateModal onClose={toggleTemplateModal} type={name} mode="save" template={template} />
      )}
      <header className={b('header')}>
        <div className={b('left-block')}>
          <Icon name={name} className={b('icon')} width={30} height={30} />
          <h4 className={b('title')} id={headerId}>
            {title}
          </h4>
        </div>
        <div className={b('right-block')}>
          {!hideSaveButton && !disabled && <SaveTemplateButton onClick={toggleTemplateModal} />}
          {documentLink && (
            <a href={documentLink} target="_blank" className={b('document-icon')}>
              <Icon name="document" width={24} height={24} />
            </a>
          )}
        </div>
      </header>
      <div className={b('buttons')}>{buttons}</div>
    </LayoutPanel>
  );
};

export default DiyOpElementCard;
