import React, {Component} from 'react';

import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';

import './custom-textarea.scss';

class CustomTextarea extends Component {
  static propTypes = {
    className: PropTypes.string,
    initialValue: PropTypes.string,
    onInitializeDone: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    getMethods: PropTypes.func,
    variableStyle: PropTypes.string,
    variableCssClass: PropTypes.string,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    initialValue: '',
    onInitializeDone: () => {},
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    getMethods: () => {},
    variableStyle: '',
    variableCssClass: '',
    readOnly: false,
  };

  static keyCodes = {
    ArrowLeft: 37,
    ArrowUp: 38,
    ArrowRight: 39,
    ArrowDown: 40,
    Backspace: 8,
    Delete: 46,
    SquareBraceOpen: 219,
    SquareBraceClose: 221,
    Space: 32,
    Enter: 13,
    Shift: 16,
  };

  constructor(props) {
    super(props);

    this.state = {
      id: 1,
      variables: [],
      html: '',
      text: '',
    };

    this.codes = [];
  }

  getNextSibling = (node) => {
    let next = node;
    while (next.nextSibling instanceof Comment) {
      next = next.nextSibling;
    }
    return next.nextSibling;
  };

  getPreviousSibling = (node) => {
    let prev = node;
    while (prev.previousSibling instanceof Comment) {
      prev = prev.previousSibling;
    }
    return prev.previousSibling;
  };

  setRteContentRef = (rteContent) => {
    if (this.rteContent) {
      return;
    }

    this.rteContent = rteContent;

    this.props.getMethods({
      reset: this.initialize,
    });

    if (rteContent) {
      this.initialize();
    }
  };

  initialize = () => {
    const data = this.initializeTextarea(this.props.initialValue);
    this.rteContent.innerHTML = data.html;
    this.setState({...data}, () => {
      this.props.onInitializeDone(this.state.html, this.state.text, this.state.variables);
    });
  };

  setCaret = (elem, start = 0) => {
    const range = document.createRange();
    const sel = window.getSelection();

    range.setStart(elem.childNodes.length ? elem.childNodes[0] : elem, start);
    range.collapse(true);

    sel.removeAllRanges();
    sel.addRange(range);

    if (elem.childNodes.length) {
      elem.focus();
    }
  };

  initializeTextarea = (value = '') => {
    const regex = /{{([^{}]+)}}/g;
    const {variableStyle, variableCssClass} = this.props;
    let id = this.state.id;
    let variables = [];

    const html = value.replace(regex, (str, name) => {
      const varName = name.replace(/[{}]/g, '').trim();
      const result =
        `<span data-var="${id}" data-var-name="${varName}" id="rte-var-${id}" ` +
        `class="${variableCssClass}" style="${variableStyle}">{{${varName}}}</span>`;

      variables.push({
        id: id,
        name: varName,
      });

      id++;

      return result;
    });

    return {html, variables, id, text: value};
  };

  handleTypingVarBegin = (parentNode, e) => {
    const prevSibling = this.getPreviousSibling(parentNode);
    if (prevSibling && prevSibling instanceof Text) {
      prevSibling.textContent += e.key;
    } else {
      const newElem = document.createTextNode(e.key);
      parentNode.parentNode.insertBefore(newElem, parentNode);
    }
    this.setCaret(this.getPreviousSibling(parentNode), this.getPreviousSibling(parentNode).length);
  };

  handleTypingVarEnd = (parentNode, e) => {
    switch (true) {
      case this.codes.includes(CustomTextarea.keyCodes.Enter) && !this.codes.includes(CustomTextarea.keyCodes.Shift): // when Enter key is pressed
        e.preventDefault();
        break;
      case this.codes.includes(CustomTextarea.keyCodes.Enter) && this.codes.includes(CustomTextarea.keyCodes.Shift): // when Enter key is pressed
        const next = this.getNextSibling(parentNode);

        if (next && next instanceof Text) {
          // if there is a text node after </span>
          next.textContent = '\n' + next.textContent;
          this.setCaret(next, 1);
        } else {
          const newElem = document.createTextNode('\n\n');
          parentNode.parentNode.insertBefore(newElem, parentNode.nextSibling);
          this.setCaret(this.getNextSibling(parentNode), this.getNextSibling(parentNode).length);
        }
        break;
      case e.key.length === 1:
        const nextSibling = this.getNextSibling(parentNode);
        if (nextSibling && nextSibling instanceof Text) {
          // if there is a text node after </span>
          nextSibling.textContent = e.key + nextSibling.textContent;
        } else {
          // otherwise create new text node
          const newElem = document.createTextNode(e.key);
          parentNode.parentNode.insertBefore(newElem, parentNode.nextSibling);
        }
        this.setCaret(this.getNextSibling(parentNode), 1);
        break;
      case e.keyCode === CustomTextarea.keyCodes.Backspace:
        const prevSibling = this.getPreviousSibling(parentNode);
        if (prevSibling) {
          const prevSiblingLength = prevSibling.length || prevSibling.innerHTML.length;
          this.setCaret(prevSibling, prevSiblingLength);
        }

        parentNode.parentNode.removeChild(parentNode);

        const dataVar = parentNode.dataset.var;

        if (dataVar) {
          this.setState({
            variables: this.deleteVar(Number(dataVar), this.state.variables),
          });
        }
        break;
      default:
      //
    }
  };

  handleTypingInsideVar = (parentNode, e, range) => {
    switch (true) {
      // skip "Arrows" pressing
      case e.keyCode === CustomTextarea.keyCodes.ArrowDown ||
        e.keyCode === CustomTextarea.keyCodes.ArrowUp ||
        e.keyCode === CustomTextarea.keyCodes.ArrowLeft ||
        e.keyCode === CustomTextarea.keyCodes.ArrowRight:
        return;
      // handle typing in the beginning of a variable: <span>cursor{{
      case range.endOffset === 0 && e.key.length === 1:
        this.handleTypingVarBegin(parentNode, e);
        e.preventDefault();
        break;
      // handle typing in the end of a variable: }}cursor</span>
      case range.endOffset === range.commonAncestorContainer.length:
        this.handleTypingVarEnd(parentNode, e);
        e.preventDefault();
        break;
      // disallow typing another braces inside {{}},
      // disallow Enter pressing {{}},
      // disallow typing between {{, prevent typing between }}
      // disallow typing "space" just after "}}" or just before "{{"
      // disallow deleting }}
      // disallow deleting {{
      case e.keyCode === CustomTextarea.keyCodes.Enter:
      case range.endOffset > range.commonAncestorContainer.length - 2 || range.endOffset < 2:
      case e.keyCode === CustomTextarea.keyCodes.SquareBraceOpen ||
        e.keyCode === CustomTextarea.keyCodes.SquareBraceClose:
      case e.keyCode === CustomTextarea.keyCodes.Space &&
        (range.endOffset > range.commonAncestorContainer.length - 3 || range.endOffset < 3):
      case e.keyCode === CustomTextarea.keyCodes.Delete && range.endOffset > range.commonAncestorContainer.length - 3:
      case e.keyCode === CustomTextarea.keyCodes.Backspace && range.endOffset < 3:
        e.preventDefault();
        break;
      // handle deleting empty variables {{}}
      case (e.keyCode === CustomTextarea.keyCodes.Backspace || e.keyCode === CustomTextarea.keyCodes.Delete) &&
        range.commonAncestorContainer.length === 5:
        const dataVar = parentNode.dataset.var;
        if (dataVar) {
          this.setState({
            variables: this.deleteVar(Number(dataVar), this.state.variables),
          });
        }

        parentNode.parentNode.removeChild(parentNode);
        e.preventDefault();
        break;
      default:
      //
    }
  };

  handlePaste = (e) => {
    if (this.props.readOnly) {
      return false;
    }

    e.preventDefault();
    e.stopPropagation();

    const paste = (e.clipboardData || window.clipboardData).getData('text');
    const selection = window.getSelection();

    // Paste the modified clipboard content where it was intended to go
    const range = selection.getRangeAt(0);
    const ancestorContainer = range.commonAncestorContainer;

    if (ancestorContainer.parentNode.dataset && ancestorContainer.parentNode.dataset.var) {
      return false;
    }

    if (selection.toString()) {
      range.deleteContents();
    }

    const newNode = document.createTextNode(paste);
    range.insertNode(newNode);

    const data = this.initializeTextarea(this.rteContent.textContent);
    this.rteContent.innerHTML = data.html;
    this.rteContent.blur();

    return this.setState({...data}, () => {
      this.props.onChange(this.state.html, this.state.text, uniqBy(this.state.variables, 'name'));
    });
  };

  handleCut = () => {
    if (this.props.readOnly) {
      return false;
    }

    let timer;

    timer = setTimeout(() => {
      clearTimeout(timer);
      const data = this.initializeTextarea(this.rteContent.textContent);
      this.rteContent.innerHTML = data.html;
      this.rteContent.blur();

      this.setState({...data}, () => {
        this.props.onChange(this.state.html, this.state.text, uniqBy(this.state.variables, 'name'));
      });
    });

    return timer;
  };

  handleKeyDown = (event) => {
    if (this.props.readOnly) {
      return false;
    }

    const {nativeEvent: e} = event;
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const ancestorContainer = range.commonAncestorContainer;
    const parentNode = ancestorContainer.parentNode;

    if (!this.codes.includes(e.keyCode)) {
      this.codes.push(e.keyCode);
    }

    switch (true) {
      // if there is a user text selection
      case selection.toString() &&
        e.keyCode !== CustomTextarea.keyCodes.ArrowDown &&
        e.keyCode !== CustomTextarea.keyCodes.ArrowUp &&
        e.keyCode !== CustomTextarea.keyCodes.ArrowRight &&
        e.keyCode !== CustomTextarea.keyCodes.ArrowLeft &&
        (e.key.length === 1 ||
          e.keyCode === CustomTextarea.keyCodes.Delete ||
          e.keyCode === CustomTextarea.keyCodes.Backspace):
        this.handleCut();
        break;

      // handle typing inside <span>...</span>
      case !!parentNode.dataset.var:
        this.handleTypingInsideVar(parentNode, e, range);
        break;
      case this.codes.includes(CustomTextarea.keyCodes.Enter) && !this.codes.includes(CustomTextarea.keyCodes.Shift):
        e.preventDefault();
        break;
      default:
      //
    }

    if (e.keyCode === CustomTextarea.keyCodes.Delete && !selection.toString()) {
      let dataVar = null;
      let containerElem = null;
      let removedElem = null;

      switch (true) {
        case range.endOffset === ancestorContainer.length: // var is not very first in message
          const nextSibling = this.getNextSibling(ancestorContainer);
          dataVar = nextSibling && nextSibling.dataset.var;
          containerElem = ancestorContainer.parentNode;
          removedElem = nextSibling;
          break;
        case !range.endOffset: // var is very first in message
          dataVar = ancestorContainer.parentNode.dataset.var;
          containerElem = ancestorContainer.parentNode.parentNode;
          removedElem = ancestorContainer.parentNode;
          break;
        default:
        //
      }

      if (!dataVar) {
        return false;
      }

      containerElem.removeChild(removedElem);
      this.setState({
        variables: this.deleteVar(Number(dataVar), this.state.variables),
      });
    }
    return false;
  };

  handleKeyUp = () => {
    if (this.props.readOnly) {
      return false;
    }

    const {variableStyle, variableCssClass} = this.props;
    let resStr = this.rteContent.innerHTML;
    let addedVars = [];
    let count = this.state.id;
    const htmlStr = this.rteContent.innerHTML;
    const regex = /(\<span[^\>]*\>)?{{(.+?)}}(\<\/span\>)?/g;
    const node = window.getSelection().getRangeAt(0).commonAncestorContainer.parentNode;

    if (regex.test(htmlStr)) {
      resStr = htmlStr.replace(regex, (str, ...rest) => {
        const groups = rest.slice(0, -2);
        const varName = groups[1].replace(/[{}]/g, '').trim();
        /*
          groups[0] - <tagName>
          groups[1] - tagName
          groups[2] - variable name without braces
          groups[3] - </tagName>
          groups[4] - /tagName
        */
        // if {{some text}} is not surrounded by html-tags yet, then add it as new variable
        if (!groups[0] && !groups[2] && varName) {
          const result =
            `<span data-var="${this.state.id}" data-var-name="${varName}" ` +
            `id="rte-var-${this.state.id}" class="${variableCssClass}" style="${variableStyle}">{{${varName}}}</span>`;

          addedVars.push({
            name: varName,
            id: count++,
          });
          return result;
        }
        return str;
      });

      if (htmlStr !== resStr) {
        // if text was changed in "replace" we need to set caret position manualy
        this.rteContent.innerHTML = resStr;
        const currentId = this.state.id;
        const currentEditedVar = this.rteContent.querySelector(`#rte-var-${currentId}`);
        const varText = currentEditedVar.lastChild;
        this.setCaret(varText, varText.length - 2);
      }
    }

    return this.setState((prevState) => {
      let updatedVars = prevState.variables;

      // whether call this.props.onChange handler or not
      if (prevState.text !== this.rteContent.textContent) {
        // updating variable name
        if (node.dataset.var) {
          const id = Number(node.dataset.var);
          const newName = node.textContent.slice(2, -2);
          updatedVars = this.updateVarName(id, newName, prevState.variables);
        }
        this.props.onChange(resStr, this.rteContent.textContent, uniqBy([...updatedVars, ...addedVars], 'name'));
      }

      this.codes.length = 0;

      return {
        variables: [...updatedVars, ...addedVars],
        html: resStr,
        text: this.rteContent.textContent,
        id: count,
      };
    });
  };

  deleteVar = (id, collection) => {
    return collection.filter((item) => {
      return item.id !== id;
    });
  };

  updateVarName = (varId, newName, collection) => {
    return collection.map((v) => {
      return v.id === varId ? {...v, name: newName} : v;
    });
  };

  render() {
    const {className, readOnly, onFocus, onBlur} = this.props;

    return (
      <div
        className={`custom-textarea ${className}`}
        contentEditable={!readOnly}
        suppressContentEditableWarning={!readOnly}
        role="textbox"
        spellCheck={!readOnly}
        onKeyDown={this.handleKeyDown}
        onKeyUp={this.handleKeyUp}
        onPaste={this.handlePaste}
        onCut={this.handleCut}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={this.setRteContentRef}
      />
    );
  }
}

export default CustomTextarea;
