export default {
  CLEAR_PLANS_STATE: '@@plans/CLEAR_PLANS_STATE',

  GET_PLAN_REQUEST: '@@plans/GET_PLAN_REQUEST',
  GET_PLAN_SUCCESS: '@@plans/GET_PLAN_SUCCESS',
  GET_PLAN_ERROR: '@@plans/GET_PLAN_ERROR',

  GET_PLANS_REQUEST: '@@plans/GET_PLANS_REQUEST',
  GET_PLANS_SUCCESS: '@@plans/GET_PLANS_SUCCESS',
  GET_PLANS_ERROR: '@@plans/GET_PLANS_ERROR',
};
