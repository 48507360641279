import {mapPrizes} from './mappers';
import types from './types';

const initialState = {
  prizeBlock: {
    data: {},
    meta: {},
    isLoading: false,
    error: {},
  },
  game: null,
  devices: {
    data: [],
    meta: {},
    unlinkedCount: 0,
  },
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case types.GET_DIY_OPERATION_PRIZEBLOCK_REQUEST:
      return {
        ...state,
        prizeBlock: {
          ...state.prizeBlock,
          isLoading: true,
        },
      };

    case types.GET_DIY_OPERATION_PRIZEBLOCK_SUCCESS:
      return {
        ...state,
        prizeBlock: {
          ...state.prizeBlock,
          data: {
            ...payload.game,
            prizes: mapPrizes(payload.game.prizes),
          },
          game: payload.game,
          meta: {
            ...payload?.meta,
          },
          isLoading: false,
        },
      };

    case types.GET_DIY_DEVICES_SUCCESS:
      return {
        ...state,
        devices: {
          data: payload.devices,
          meta: payload.meta,
          unlinkedCount: payload.aggregations?.unlinked_count,
        },
      };

    case types.GET_DIY_OPERATION_PRIZEBLOCK_ERROR:
      return {
        ...state,
        prizeBlock: {
          ...state.prizeBlock,
          error: payload.error,
          isLoading: false,
        },
      };

    case types.CLEAR_DIY_OPERATION_BLOCKS:
      return initialState;

    case types.CHANGE_PRIZE_ORDER:
      return {
        ...state,
        prizeBlock: {
          ...state.prizeBlock,
          data: {
            ...state.prizeBlock.data,
            prizes: payload.prizes,
          },
        },
      };

    default:
      return state;
  }
};
