import React, {useState, useCallback} from 'react';

import {useDispatch} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {
  createWebAppInterface,
  updateWebAppInterface,
  deleteWebAppInterface,
  createDeviceInterface,
  updateDeviceInterface,
} from 'client/ducks/interfaces/actions';

import ConfirmationModal from 'client/common/modals/confirmation-modal';

import {
  DIY_OPERATION_TYPES_WITH_URL,
  DIY_OPERATION_STATUSES,
  DIY_OPERATION_TYPES_DEVICE,
  DIY_CONFIRMATION_TYPES,
  DIY_OPERATION_STATES,
  DIY_OPERATION_CONFIRMATION_MODAL_TYPE,
} from 'client/components/diy-operation/constants';
import DiyOpStatusControl from 'client/components/diy-operation/controls/diy-op-status-control';
import {getModalsConfiguration} from 'client/components/diy-operation/helpers';
import {isOperationArchived} from 'client/models/operations/helpers';
import {Operation} from 'client/models/operations/types';
import {ApiDispatch} from 'client/types';

import cssModule from './diy-op-status-block.module.scss';

const b = bem('diy-op-status-block', {cssModule});

type DiyOpStatusBlockProps = {
  handleOpenModal: (key: string | null) => void;
  fetchOperation: () => void;
  fetchAutotask: () => void;
  operation: Operation;
  type: string;
  url?: string;
};

const DiyOpStatusBlock: React.FC<DiyOpStatusBlockProps> = React.memo((props) => {
  const {operation, fetchOperation, fetchAutotask, type, handleOpenModal, url} = props;
  const dispatch: ApiDispatch = useDispatch();

  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_STATUS_BLOCK');

  const [confirmationModal, setConfirmationModal] = useState('');
  const [loading, toggleLoading] = useToggle(false);

  const hasOperationUrl = Object.values(DIY_OPERATION_TYPES_WITH_URL).includes(type);
  const isDevice = Object.values(DIY_OPERATION_TYPES_DEVICE).includes(type);
  const modalType = hasOperationUrl
    ? DIY_OPERATION_CONFIRMATION_MODAL_TYPE.WEB_APP
    : DIY_OPERATION_CONFIRMATION_MODAL_TYPE.DEVICE;

  const interfaceId = operation?.client_interface?.id;
  const deviceState = operation?.client_interface?.device_state || '';
  const webAppState = operation?.client_interface?.web_app?.state || '';

  const modalsConfiguration = getModalsConfiguration(lang, modalType, loading);

  const openConfirmationModal = (modal: string) => () => setConfirmationModal(modal);
  const closeConfirmationModal = () => setConfirmationModal('');

  const handleConfirm = useCallback(async () => {
    toggleLoading();

    if (confirmationModal === DIY_CONFIRMATION_TYPES.PUBLISH) {
      if (hasOperationUrl) {
        await dispatch(createWebAppInterface(interfaceId));
      } else {
        await dispatch(createDeviceInterface(interfaceId, DIY_OPERATION_STATES.PUBLISHED));
      }
    }

    if (confirmationModal === DIY_CONFIRMATION_TYPES.UPDATE) {
      if (hasOperationUrl) {
        await dispatch(updateWebAppInterface(interfaceId));
      } else {
        await dispatch(updateDeviceInterface(interfaceId));
      }
    }

    if (confirmationModal === DIY_CONFIRMATION_TYPES.UNPUBLISH) {
      if (hasOperationUrl) {
        await dispatch(deleteWebAppInterface(interfaceId));
      } else {
        await dispatch(createDeviceInterface(interfaceId, DIY_OPERATION_STATES.UNPUBLISHED));
      }
    }

    fetchOperation();
    fetchAutotask();
    toggleLoading();
    closeConfirmationModal();
  }, [toggleLoading, confirmationModal, fetchOperation, fetchAutotask, hasOperationUrl, dispatch, interfaceId]);
  const publishDisabled = !isDevice && (operation.status === DIY_OPERATION_STATUSES.FINISHED || !url);

  const isPublished = useCallback((state: string) => {
    return ['published', 'republished'].includes(state);
  }, []);

  const editUrlButtonDisabled = hasOperationUrl && ['published', 'republished'].includes(webAppState || '');

  return (
    <div className={b()}>
      <DiyOpStatusControl
        handleOpenModal={handleOpenModal}
        hasOperationUrl={hasOperationUrl}
        disabled={publishDisabled}
        editUrlButtonDisabled={editUrlButtonDisabled}
        onClick={
          !isPublished(hasOperationUrl ? webAppState : deviceState)
            ? openConfirmationModal(DIY_CONFIRMATION_TYPES.PUBLISH)
            : openConfirmationModal(DIY_CONFIRMATION_TYPES.UPDATE)
        }
        onUnPublish={openConfirmationModal(DIY_CONFIRMATION_TYPES.UNPUBLISH)}
        label={!isPublished(hasOperationUrl ? webAppState : deviceState) ? lang.PUBLISH : lang.UPDATE}
        isPublished={isPublished(hasOperationUrl ? webAppState : deviceState)}
        type={type}
        url={url}
        isOperationArchived={isOperationArchived(operation)}
      />

      <ConfirmationModal
        title={modalsConfiguration[confirmationModal]?.title}
        show={!!confirmationModal}
        onClose={closeConfirmationModal}
        onConfirm={handleConfirm}
        messageClassName={b('message')}
        message={modalsConfiguration[confirmationModal]?.message}
        buttonConfirm={modalsConfiguration[confirmationModal]?.buttonConfirm}
        buttonCancel={modalsConfiguration[confirmationModal]?.buttonCancel}
        clientSide
      />
    </div>
  );
});

export default DiyOpStatusBlock;
