import React, {useCallback, useEffect} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {getAndDownloadFile} from 'client/services/helpers';

import {getAnimation} from 'client/ducks/animations/actions';
import {selectAnimation} from 'client/ducks/animations/selectors';
import {addInstoreTaskAnimationPhotos, getInstoreTaskAnimationPhotos} from 'client/ducks/instore-tasks/actions';
import {selectAnimationPhotos} from 'client/ducks/instore-tasks/selectors';

import AppButton from 'client/common/buttons';
import FileButton from 'client/common/buttons/file-button';
import Icon from 'client/common/icon';

import Spinner from 'client/components/common/spinner';

import {ANIMATION_STATUSES} from 'client/components/instore/constants';

import './animation-photos-container.scss';

const b = bem('animation-photos-container');

export const AnimationPhotosContainer = ({animationId}) => {
  const {animation_days, status} = useSelector(selectAnimation) || {};
  const photos = useSelector(selectAnimationPhotos);
  const [isLoading, toggleIsLoading] = useToggle(true);
  const [isUploading, toggleIsUploading] = useToggle(false);
  const dispatch = useDispatch();

  const fetchPhotos = useCallback(() => dispatch(getInstoreTaskAnimationPhotos(animationId)), [animationId, dispatch]);

  useEffect(() => {
    toggleIsLoading(true);
    fetchPhotos().then(() => toggleIsLoading());
  }, [fetchPhotos, toggleIsLoading]);

  useEffect(() => {
    dispatch(
      getAnimation(animationId, {
        include: ['animation_days'],
      }),
    );
  }, [animationId, dispatch]);

  const uploadImage = useCallback(
    (file) => {
      toggleIsUploading(true);

      const data = new FormData();

      data.append('instore_animation_photo[file]', file);
      data.append('instore_animation_photo[animation_day_id]', animation_days[0].id);

      dispatch(addInstoreTaskAnimationPhotos(data))
        .then(() => {
          fetchPhotos();
        })
        .finally(() => {
          toggleIsUploading(false);
        });
    },
    [animation_days, dispatch, fetchPhotos, toggleIsUploading],
  );

  const uploadAllowed = [
    ANIMATION_STATUSES.PRODUCTION,
    ANIMATION_STATUSES.RUNNING,
    ANIMATION_STATUSES.FINISHED,
  ].includes(status);

  return (
    <>
      {animation_days?.length > 0 && uploadAllowed && (
        <FileButton
          className={b('upload-button')}
          acceptFormats="image/png, image/jpeg, image/HEIC"
          iconName="exports"
          color="light-clients"
          onChange={uploadImage}
          iconConfig={{width: 24, height: 24}}
          loading={isUploading}
        />
      )}

      {isLoading ? (
        <Spinner centered />
      ) : (
        <ul className={b('list')}>
          {photos.map((photo) => (
            <li className={b('item')} key={photo.id}>
              <div className={b('controls')}>
                <Icon name="camera-3" className={b('camera-icon')} width={30} height={30} />
                <div>
                  <span className={b('date')}>{moment(photo.updated_at).format('DD/MM/YY')}</span>{' '}
                  <span className={b('time')}>{moment(photo.updated_at).format('HH:mm')}</span>
                </div>

                <AppButton
                  iconName="downloads"
                  color="light-clients"
                  className={b('download-button')}
                  iconConfig={{width: 24, height: 24}}
                  onClick={() => getAndDownloadFile(photo.url)}
                />
              </div>
              <div className={b('image-wrapper')}>
                <img src={photo.url} alt="" />
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

AnimationPhotosContainer.propTypes = {
  animationId: PropTypes.string.isRequired,
};
