import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, reset, initialize} from 'redux-form';

import bem from 'client/services/bem';
import {arrayUnique, required} from 'client/services/validator';

import AppButton from 'client/common/buttons/app-button';
import {API_URL} from 'client/common/config';
import {TextField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import TwitterLogin from './twitter-login';

import cssModule from './add-new-twitter-account-modal.module.scss';

const b = bem('add-new-twitter-account-modal', {cssModule});
class AddNewTwitterAccountModal extends Component {
  static formName = 'AddNewTwitterAccountForm';

  static propTypes = {
    show: PropTypes.bool,
    className: PropTypes.string,
    buttonConfirmClass: PropTypes.string,
    buttonCancelClass: PropTypes.string,
    pristine: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    submitting: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    socialAccountsNames: PropTypes.array,
    isAdmin: PropTypes.bool,
  };

  static defaultProps = {
    show: false,
    isAdmin: false,
    formValues: {},
  };

  constructor(props) {
    super(props);

    this.rules = {
      name: [
        required(this.props.lang.NAME_IS_REQUIRED_ERROR),
        (value) => arrayUnique(this.props.socialAccountsNames, this.props.lang.NAME_IS_TAKEN)(value),
      ],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.props.initialize({});
    }
  }

  save = () => {
    this.props.onClose();
  };

  render() {
    const {handleSubmit, onClose, onCancel, show, valid, pristine, formValues, className, isAdmin, lang} = this.props;

    return (
      <Modal show={show} className={cn((b(), className))} onClose={onClose} title={lang.TITLE}>
        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          <TextField label={lang.NAME_LABEL} name="name" validate={this.rules.name} required={true} withWrap={true} />
          <div className={b('buttons')}>
            {isAdmin && (
              <AppButton type="button" onClick={onCancel} label={lang.CANCEL_BUTTON} color="text-additional" />
            )}
            <TwitterLogin
              onSuccess={(data) => this.props.onConfirm({...data, name: formValues.values && formValues.values.name})}
              requestTokenUrl={`${API_URL}/oauth/twitter/request_token`}
              disabled={!valid || pristine}
            >
              {lang.SAVE_BUTTON}
            </TwitterLogin>
          </div>
        </form>
      </Modal>
    );
  }
}

const AddNewTwitterAccountForm = reduxForm({
  form: AddNewTwitterAccountModal.formName,
})(AddNewTwitterAccountModal);

export default connect(
  ({
    form,
    isAdmin,
    languageState: {
      payload: {AGENCY, ACCOUNT_PARAMETERS},
    },
  }) => ({
    lang: isAdmin ? AGENCY.ADD_SOCIAL_ACCOUNT_MODAL : ACCOUNT_PARAMETERS.SOCIAL_NETWORK.ADD_SOCIAL_ACCOUNT_MODAL,
    formValues: form.AddNewTwitterAccountForm,
  }),
  {
    reset,
    initialize,
  },
)(AddNewTwitterAccountForm);
