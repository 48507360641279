import {dateToString} from 'client/services/helpers';

export function mapClients(clients) {
  return clients.map(({created_at, id, type, name}) => {
    return {
      createdAt: dateToString(created_at),
      id,
      type,
      name,
    };
  });
}
