import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';
import {blobToBase64} from 'client/services/blobToBase64';
import {checkImageMimeType} from 'client/services/helpers';

import {patchClient} from 'client/ducks/clients-list/actions';

import FileButton from 'client/common/buttons/file-button';

import {TranslationJsx} from 'client/models/language/types';

import cssModule from './add-logo-button.module.scss';

const b = bem('add-logo-button', {cssModule});

class AddLogoButton extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    updateMe: PropTypes.func.isRequired,
    clientId: PropTypes.number.isRequired,
    buttonText: TranslationJsx.isRequired,
    patchClient: PropTypes.func.isRequired,
  };

  handleChangeImage = (file) => {
    if (!file) {
      return;
    }

    checkImageMimeType(file)
      .then(async () => {
        const base64 = await blobToBase64(file);

        return this.props.patchClient(this.props.clientId, {
          client: {
            logo_image: {
              data: base64,
            },
          },
        });
      })
      .then(this.props.updateMe);
  };

  render() {
    const {loading, buttonText} = this.props;
    return (
      <FileButton
        className={b()}
        acceptFormats="image/png, image/jpeg, image/HEIC"
        iconName="exports"
        color="visuals"
        onChange={this.handleChangeImage}
        loading={loading}
        label={buttonText}
        fontSize="small"
      />
    );
  }
}

export default connect(
  (state) => ({
    loading: state.clientsList.loading,
  }),
  {patchClient},
)(AddLogoButton);
