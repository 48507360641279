import {useMemo} from 'react';

import {useLanguage} from 'client/services/hooks';

const useCalendarUnitTranslations = () => {
  const m = useLanguage('COMMON.CALENDAR_UNITS.MONTHS');
  const w = useLanguage('COMMON.CALENDAR_UNITS.WEEKDAYS');
  const ws = useLanguage('COMMON.CALENDAR_UNITS.WEEKDAYS_SHORT');

  const result = useMemo(() => {
    return {
      months: [
        m.JANUARY,
        m.FEBRUARY,
        m.MARCH,
        m.APRIL,
        m.MAY,
        m.JUNE,
        m.JULY,
        m.AUGUST,
        m.SEPTEMBER,
        m.OCTOBER,
        m.NOVEMBER,
        m.DECEMBER,
      ],
      weekdaysLong: [w.SUNDAY, w.MONDAY, w.TUESDAY, w.WEDNESDAY, w.THURSDAY, w.FRIDAY, w.SATURDAY],
      weekdaysShort: [ws.SUNDAY, ws.MONDAY, ws.TUESDAY, ws.WEDNESDAY, ws.THURSDAY, ws.FRIDAY, ws.SATURDAY],
    };
  }, [m, w, ws]);

  return result;
};

export default useCalendarUnitTranslations;
