export const mapToOptions = (arr) =>
  arr.map((item) => ({
    value: String(item.id),
    label: item.name,
  }));

export const updateOperationEmailTemplate = (templates, updatedTemplate) => {
  const updatedTemplateIndex = templates.findIndex((el) => el.id === updatedTemplate.id);
  if (updatedTemplateIndex === -1) {
    return templates;
  }

  return [
    ...templates.slice(0, updatedTemplateIndex),
    {
      ...templates[updatedTemplateIndex],
      ...updatedTemplate,
    },
    ...templates.slice(updatedTemplateIndex + 1),
  ];
};
