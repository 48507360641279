import types from './types';

const initialState = {
  needToUpdateDevices: false,
  devices: [],
  device_families: [],
  isLogisticIdNew: [true],
  isCodeNew: [true],
  meta: {},
};

export default function (_state = initialState, action) {
  const state = Object.assign({}, _state);

  switch (action.type) {
    case types.GET_DEVICES_REQUEST:
      state.needToUpdateDevices = false;
      return state;

    case types.GET_DEVICES_SUCCESS:
      state.devices = action.payload.devices;
      state.meta = action.payload.meta;
      return state;

    case types.GET_DEVICE_FAMILIES_SUCCESS:
      state.device_families = action.payload.device_families;
      return state;

    case types.PATCH_DEVICE_ERROR:
      if (action.payload.status === 422) {
        const {errors} = action.payload.response;
        state.isLogisticIdNew = [!(errors.logistic_id && errors.logistic_id[0] === 'taken')];
        state.isCodeNew = [!(errors.code && errors.code[0] === 'taken')];
      }
      return state;

    case types.PATCH_DEVICE_SUCCESS:
      state.needToUpdateDevices = true;
      return state;

    case types.DELETE_DEVICE_SUCCESS:
      state.needToUpdateDevices = true;
      return state;

    case types.POST_DEVICE_SUCCESS:
      state.needToUpdateDevices = true;
      return state;

    case types.POST_DEVICE_ERROR:
      if (action.payload.status === 422) {
        const {errors} = action.payload.response;
        state.isLogisticIdNew = [!(errors.logistic_id && errors.logistic_id[0] === 'taken')];
        state.isCodeNew = [!(errors.code && errors.code[0] === 'taken')];
      }
      return state;

    default:
      return state;
  }
}
