import React from 'react';

import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router';
import {useMount} from 'react-use';
import {reduxForm, propTypes as reduxFormPropTypes, formValueSelector} from 'redux-form';

import bem from 'client/services/bem';
import {getAccessLevel} from 'client/services/cookie-data-source';
import {useLanguage, useQueryParams} from 'client/services/hooks';

import {getRegions, getPlaces} from 'client/ducks/operations/actions';
import {selectRegionsAsOptions, selectPlacesAsOptions} from 'client/ducks/operations/selectors';

import AppButton from 'client/common/buttons';
import {USER_MEMBERSHIP} from 'client/common/config';
import {SelectField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import {getValues} from './helpers';

import './operations-filters-modal.scss';

const b = bem('operations-filters-modal');

const OperationsFiltersModalFormName = 'OperationsFiltersModalForm';

const REGIONS_NAME = 'regions';
const STORES_NAME = 'stores';

const OperationsFiltersModal = (props) => {
  const {
    show,
    hideModal,
    updateFilters,
    clientId,
    // from redux-form
    handleSubmit,
    initialize,
    dirty,
    change,
  } = props;

  const dispatch = useDispatch();

  const lang = useLanguage('OPERATIONS');

  const [queryParams] = useQueryParams(props);

  const regionOptions = useSelector(selectRegionsAsOptions);
  const storeOptions = useSelector(selectPlacesAsOptions);

  const selectFormValue = formValueSelector(OperationsFiltersModalFormName);
  const storesValue = useSelector((state) => selectFormValue(state, STORES_NAME));

  const {regions, stores} = queryParams;

  useMount(() => {
    // get select options
    dispatch(getRegions(clientId));
    dispatch(getPlaces(clientId, regions));
  });

  useMount(() => {
    initialize({regions: regions || [], stores: stores || []});
  });

  const onSubmit = (values) => {
    if (dirty) {
      updateFilters({
        regions: getValues(values.regions),
        stores: getValues(values.stores),
      });

      // redux store is initialized with new values to reset the dirty flag
      initialize({regions: values.regions, stores: values.stores});
    }

    hideModal();
  };

  const handleRegionsChange = (selectedRegions) => {
    // fetch stores available in selected regions
    const regionValues = getValues(selectedRegions);

    dispatch(getPlaces(clientId, regionValues)).then((response) => {
      // remove stores which are not available in selected region
      const {places} = response.payload;

      const storesIds = places.map((store) => String(store.id));
      const filteredStores = storesValue.filter((storeId) => storesIds.includes(storeId));

      change(STORES_NAME, filteredStores);
    });
  };

  const accessLevel = getAccessLevel();
  const hasAccessToRegionField =
    accessLevel === USER_MEMBERSHIP.NATIONAL || accessLevel === USER_MEMBERSHIP.CLIENT_ADMIN;

  return (
    <Modal title={lang.FILTER_MODAL_TITLE} show={show} onClose={hideModal}>
      <form className={b()} onSubmit={handleSubmit(onSubmit)}>
        {hasAccessToRegionField && (
          <SelectField
            withWrap
            name={REGIONS_NAME}
            label={lang.FILTER_MODAL_REGION_LABEL}
            options={regionOptions}
            onChange={handleRegionsChange}
            multi
            simpleValue
          />
        )}
        <SelectField
          withWrap
          name={STORES_NAME}
          label={lang.FILTER_MODAL_STORE_LABEL}
          options={storeOptions}
          multi
          simpleValue
        />
        <AppButton submit color="primary" className={b('button')} label={lang.FILTER_MODAL_SHOW_RESULTS_BUTTON} />
      </form>
    </Modal>
  );
};

OperationsFiltersModal.propTypes = {
  ...reduxFormPropTypes,
  show: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
};

export default withRouter(
  reduxForm({
    form: OperationsFiltersModalFormName,
  })(OperationsFiltersModal),
);
