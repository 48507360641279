import validator from 'validator';

export default ({email}, {lang}) => {
  let errors = {};

  if (!email) {
    errors.email = lang.REQUIRED_EMAIL_ERROR;
  } else if (email && !validator.isEmail(email)) {
    errors.email = lang.INCORRECT_EMAIL_ERROR;
  }

  return errors;
};
