import React from 'react';

import {Translation} from 'client/models/language/types';

type Header = {
  name: Translation;
  forTaskType: string[];
};

type MessageTaskListHeaderProps = {
  taskType: string;
  headers: Header[];
  classNames?: {header?: string; col?: string};
};

const MessageTaskListHeader: React.FC<MessageTaskListHeaderProps> = (props) => {
  const {taskType, headers, classNames} = props;

  return (
    <div className={classNames?.header}>
      {headers
        .filter((header) => header.forTaskType.includes(taskType))
        .map((header, index) => (
          <div key={index} className={classNames?.col}>
            {header.name}
          </div>
        ))}
    </div>
  );
};

export default MessageTaskListHeader;
