import React from 'react';

import PropTypes from 'prop-types';

import Pagination from 'client/common/paginations/pagination';

function BasePagination(props) {
  return <Pagination {...props} />;
}

BasePagination.propTypes = {
  prev: PropTypes.bool,
  next: PropTypes.bool,
  ellipsis: PropTypes.bool,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

BasePagination.defaultProps = {
  prev: true,
  next: true,
  ellipsis: true,
  maxButtons: 3,
  totalPages: 0,
  currentPage: 0,
};

export default BasePagination;
