import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getToken, removeAll, setEmail} from 'client/services/cookie-data-source';
import {post} from 'client/services/fetch';
import {useLanguage} from 'client/services/hooks';

import {API_METHODS} from 'client/common/config';
import {set as setMetaAction} from 'client/common/meta/meta.actions';

import PrivacyPolicyAccept from 'client/components/privacy-policy-accept';

import AuthRegistrationForm from './auth-registration-form';

const AuthRegistration = ({history}) => {
  const lang = useLanguage('AUTH');
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [isLinkExpired, setIsLinkExpired] = useState();
  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const [userLanguage, setUserLanguage] = useState('');
  const dispatch = useDispatch();

  const invitationToken = new URLSearchParams(history.location.search).get('invitation_token');
  const email = new URLSearchParams(history.location.search).get('email');

  useEffect(() => {
    setEmail(email);
  }, [email]);

  useEffect(() => {
    if (getToken()) {
      removeAll();
      window.location.reload();
      return;
    }
    dispatch(
      setMetaAction({
        title: lang.REGISTRATION_TITLE,
      }),
    );

    if (invitationToken) {
      post(API_METHODS.VERIFY_TOKEN, {
        token: invitationToken,
        token_type: 'invitation_token',
      }).then((res) => {
        if (!res.token_valid) {
          setIsLinkExpired(true);
          history.replace({...history.location, state: {expired: true}});
        }
        setUserLanguage(res.locale);
        setIsTokenVerified(true);
      });
    }
  }, [dispatch, invitationToken, lang.REGISTRATION_TITLE, history, history.replace]);

  const acceptPrivacyPolicy = useCallback(() => {
    setPrivacyPolicyAccepted(true);
  }, []);

  if (invitationToken && !isTokenVerified) {
    return null;
  }

  if (isLinkExpired) {
    return <h4 dangerouslySetInnerHTML={{__html: lang.DEACTIVATED_LINK_BODY}} />;
  }

  return privacyPolicyAccepted ? (
    <AuthRegistrationForm invitationToken={invitationToken} />
  ) : (
    <PrivacyPolicyAccept onAccept={acceptPrivacyPolicy} userLanguage={userLanguage} />
  );
};

AuthRegistration.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(AuthRegistration);
