export default {
  GET_GROUPS_REQUEST: '@@addPlaceForm/GET_GROUPS_REQUEST',
  GET_GROUPS_SUCCESS: '@@addPlaceForm/GET_GROUPS_SUCCESS',
  GET_GROUPS_ERROR: '@@addPlaceForm/GET_GROUPS_ERROR',

  GET_NETWORKS_REQUEST: '@@addPlaceForm/GET_NETWORKS_REQUEST',
  GET_NETWORKS_SUCCESS: '@@addPlaceForm/GET_NETWORKS_SUCCESS',
  GET_NETWORKS_ERROR: '@@addPlaceForm/GET_NETWORKS_ERROR',

  GET_CLIENTS_REQUEST: '@@addPlaceForm/GET_CLIENTS_REQUEST',
  GET_CLIENTS_SUCCESS: '@@addPlaceForm/GET_CLIENTS_SUCCESS',
  GET_CLIENTS_ERROR: '@@addPlaceForm/GET_CLIENTS_ERROR',

  GET_REGIONS_REQUEST: '@@addPlaceForm/GET_REGIONS_REQUEST',
  GET_REGIONS_SUCCESS: '@@addPlaceForm/GET_REGIONS_SUCCESS',
  GET_REGIONS_ERROR: '@@addPlaceForm/GET_REGIONS_ERROR',

  TOGGLE_SUBMITTED: '@@addPlaceForm/TOGGLE_SUBMITTED',
};
