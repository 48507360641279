import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {normalizePhone} from 'client/services/normilizers';

import Popover from 'client/common/popovers/popover';

import CheckboxControl from 'client/components/common/controls/checkbox-control';
import Icon from 'client/components/common/icon';

import ProfilePanel from '../profile-panel';

import './profile-opt.scss';

class ProfileOpt extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string,
      otp_phone: PropTypes.string,
      otp_enabled: PropTypes.bool,
    }),
  };

  static PHONE_PATTERN = /^\+33[67]\d{8}$/;

  constructor(props) {
    super(props);

    this.state = {
      showActivationForm: false,
      showDeactivationForm: false,
      phone: null,
      isPhoneValid: true,
    };
  }

  handleOtpActivate = () => {
    const {isPhoneValid} = this.state;
    const normPhone = normalizePhone(this.getPhoneValue());

    if (isPhoneValid) {
      this.props
        .updateProfile({
          otp_enabled: true,
          otp_phone: normPhone,
        })
        .then(() =>
          this.setState({
            showActivationForm: false,
            phone: normPhone,
          }),
        );
    }
  };

  handleOtpDeactivate = () => {
    this.props
      .updateProfile({
        otp_enabled: false,
      })
      .then(() => this.setState({showDeactivationForm: false}));
  };

  handleCancel = () => {
    this.setState({
      showActivationForm: false,
      showDeactivationForm: false,
      phone: null,
      isPhoneValid: true,
    });
  };

  onPhoneChange = ({target}) => {
    const normPhone = normalizePhone(target.value);

    this.setState({
      isPhoneValid: !normPhone || ProfileOpt.PHONE_PATTERN.test(normPhone),
      phone: target.value,
    });
  };

  getPhoneValue = () => {
    const {user} = this.props;
    const {phone} = this.state;

    if (phone !== null) {
      return phone;
    }

    if (user.otp_phone) {
      return user.otp_phone;
    }

    return '';
  };

  renderInfo = () => {
    const {lang} = this.props;

    return (
      <Popover
        position="top"
        shiftTop={-3}
        arrowShiftLeft={3}
        arrowCssClass="profile-opt__tooltip-arrow"
        overlay={
          <div className="profile-opt__tooltip">
            <div className="profile-opt__tooltip-info">{lang.OTP_DESCRIPTION}</div>
            <div className="profile-opt__tooltip-note">{lang.OTP_NOTE}</div>
          </div>
        }
      >
        <Icon name="information" className="profile-opt__tooltip-icon" />
      </Popover>
    );
  };

  renderTitle = (title, withInfo = true) => {
    return (
      <div className="profile-opt__title">
        {title}
        {withInfo && this.renderInfo()}
      </div>
    );
  };

  render() {
    const {lang, user} = this.props;
    const {showActivationForm, showDeactivationForm, isPhoneValid} = this.state;
    const isEdit = user.otp_enabled;
    const phoneValue = this.getPhoneValue();

    return (
      <ProfilePanel title={lang.TITLE}>
        <div className="profile-opt">
          {!isEdit &&
            !showActivationForm &&
            !showDeactivationForm &&
            this.renderTitle(
              <CheckboxControl
                checked={showActivationForm}
                text={lang.OTP_MESSAGE}
                onChange={() => this.setState({showActivationForm: true})}
                textElement={this.renderInfo()}
              />,
              false,
            )}
          {isEdit && !showActivationForm && !showDeactivationForm && (
            <div className="profile-opt__edit-options">
              {this.renderTitle(lang.OTP_ACTIVATED)}
              <CheckboxControl
                checked={false}
                text={lang.MODIFY_2FA_PHONE_NUMBER}
                onChange={() => this.setState({showActivationForm: true})}
              />
              <CheckboxControl
                checked={false}
                text={lang.DEACTIVATE_2FA}
                onChange={() => this.setState({showDeactivationForm: true})}
              />
            </div>
          )}
          {showActivationForm && (
            <div>
              {this.renderTitle(isEdit ? lang.EDIT_2FA_PHONE_NUMBER : lang.OTP_MESSAGE)}
              <div className="profile-opt__email-linked-message">
                {isEdit ? lang.EMAIL_2FA_IS_LINKED : lang.EMAIL_2FA_WILL_BE_LINKED}
              </div>
              <div className="profile-opt__email">{user.email}</div>
              <div className="profile-opt__phone-linked-message">
                {isEdit ? lang.PHONE_2FA_IS_LINKED : lang.PHONE_2FA_WILL_BE_LINKED}
              </div>
              <div className="profile-opt__phone-input-wrap">
                <div className="profile-opt__phone-input-label">{lang.PHONE_NUMBER}</div>
                <input
                  className={cn('profile-opt__phone-input', {
                    'profile-opt__phone-input--error': !isPhoneValid,
                  })}
                  name="phone"
                  value={phoneValue}
                  placeholder={lang.PHONE_PLACEHOLDER}
                  onChange={this.onPhoneChange}
                />
                {!isPhoneValid && <p className="profile-opt__error-message">{lang.INVALID_PHONE}</p>}
              </div>
              <div className="profile-opt__buttons">
                <div className="profile-opt__button-cancel" onClick={this.handleCancel}>
                  {lang.CANCEL}
                </div>
                <button
                  className="profile-opt__button-save"
                  onClick={this.handleOtpActivate}
                  disabled={!phoneValue || !isPhoneValid}
                >
                  {isEdit ? lang.MODIFY_2FA : lang.REQUIRE_2FA}
                </button>
              </div>
            </div>
          )}
          {showDeactivationForm && (
            <div>
              {this.renderTitle(lang.DEACTIVATE_2FA_TITLE)}
              <div className="profile-opt__title">{lang.DEACTIVATE_2FA_CONFIRMATION}</div>
              <div className="profile-opt__buttons">
                <div className="profile-opt__button-cancel" onClick={this.handleCancel}>
                  {lang.CANCEL}
                </div>
                <button className="profile-opt__button-save" onClick={this.handleOtpDeactivate}>
                  {lang.DEACTIVATE_2FA}
                </button>
              </div>
            </div>
          )}
        </div>
      </ProfilePanel>
    );
  }
}

export default connect((state) => ({
  lang: state.languageState.payload.PROFILE.PROFILE_OTP,
}))(ProfileOpt);
