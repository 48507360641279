import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage, useAppMedia} from 'client/services/hooks';

import NumberField from 'client/common/fields/number-field';

import ClientTable from 'client/components/common/client-table';
import CustomScrollbars from 'client/components/common/custom-scrollbars';

import './animation-products-fieldset.scss';

const b = bem('animation-products-fieldset');

const AnimationProductsFieldset = (props) => {
  const {readOnly, values} = props;
  const lang = useLanguage('ANIMATIONS.FIELDSETS.ANIMATION_PRODUCTS_FIELDSET');
  const {isTablet} = useAppMedia();

  return (
    <div className={b()}>
      <CustomScrollbars
        scrollbarProps={{
          autoHeightMax: isTablet ? 226 : 182,
        }}
      >
        <ClientTable
          data={values}
          showNoData={false}
          oddClassName={isTablet ? b('odd-row') : null}
          evenClassName={isTablet ? b('even-row') : null}
          columns={[
            {
              name: 'name',
              path: 'name',
              label: lang.PRODUCT,
              headerClassName: b('header'),
              labelClassName: b('label'),
              cellClassName: cn(b('cell'), b('name-cell')),
              valueClassName: b('value'),
              width: 180,
            },
            {
              name: 'quantity',
              label: isTablet ? null : lang.QUANTITY,
              headerClassName: b('header'),
              labelClassName: b('label'),
              cellClassName: cn(b('cell'), b('quantity-cell')),
              valueClassName: b('value'),
              width: 100,
              render: ({index}) => (
                <NumberField
                  name={`products[${index}].quantity`}
                  inputClassName={b('quantity-input')}
                  valueClassName={b('quantity-value')}
                  readOnly={readOnly}
                />
              ),
            },
          ]}
        />
      </CustomScrollbars>
    </div>
  );
};

AnimationProductsFieldset.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      quantity: PropTypes.string,
    }),
  ).isRequired,
};

export default AnimationProductsFieldset;
