import React from 'react';

import classNames from 'classnames';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';

import {TranslationJsx} from 'client/models/language/types';

function NavigationSubitem(props) {
  const {
    children,
    to,
    dataChildOf,
    exact,
    isActive,
    colorTheme,
    className,
    currentUrl,
    disabled,
    disableText,
    languageState,
  } = props;

  const LANGUAGE = languageState.payload.NAVIGATION;

  let obj = {};

  if (typeof isActive === 'function') {
    obj = {
      isActive,
    };
  }

  if (isUndefined(isActive) && currentUrl) {
    obj.isActive = () => to === currentUrl;
  }

  const isTheme = !!colorTheme.trim().length;

  const cssClass = classNames({
    'navigation__subnav-link': true,
    [`navigation__subnav-link--theme-${colorTheme}`]: isTheme,
    'navigation__subnav-link--disabled': disabled,
  });

  return (
    <NavLink
      data-child-of={dataChildOf}
      className={`${cssClass} ${className}`}
      activeClassName="navigation__subnav-link--active"
      to={disabled ? '#' : to}
      exact={exact}
      title={disabled ? disableText || LANGUAGE.SECTION_AVAILABLE_SOON : null}
      {...obj}
    >
      <span className="navigation__subnav-link-text">{children}</span>
    </NavLink>
  );
}

NavigationSubitem.defaultProps = {
  children: '',
  className: '',
  exact: false,
  colorTheme: '',
  currentUrl: '',
  disabled: false,
  disableText: null,
};

NavigationSubitem.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  dataChildOf: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  isActive: PropTypes.func,
  colorTheme: PropTypes.string,
  currentUrl: PropTypes.string,
  disabled: PropTypes.bool,
  disableText: TranslationJsx,
  languageState: PropTypes.object.isRequired,
};

export default connect((state) => ({
  languageState: state.languageState,
}))(NavigationSubitem);
