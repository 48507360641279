import {calculateTotal} from 'client/ducks/participations-statistics/mappers';

function formatDate(date) {
  return `${date.substr(8, 2)}/${date.substr(5, 2)}/${date.substr(0, 4)}`;
}

export const selectCampaignSources = ({sourcesStatistics}) => {
  const directAccess = sourcesStatistics.direct_access;
  const campaignSources = sourcesStatistics.sources;
  if (directAccess.length && campaignSources.length) {
    const campaigns = sourcesStatistics.sources.map(
      ({name, to, from, accomplished_participations_count, participations_count, interactions}) => ({
        name,
        from: formatDate(from),
        to: formatDate(to),
        visits: participations_count,
        participations: accomplished_participations_count,
        records: interactions.map((interaction) => ({
          name: interaction.name,
          description: interaction.source ? interaction.source.description : '',
          from: formatDate(interaction.from),
          to: formatDate(interaction.to),
          visits: interaction.participations_count,
          participations: interaction.accomplished_participations_count,
          status: interaction.current_status,
        })),
      }),
    );
    return {
      direct_access: [
        {
          name,
          from: formatDate(directAccess[0].created_at),
          to: formatDate(directAccess[directAccess.length - 1].updated_at),
          participations: calculateTotal(directAccess, 'accomplished_participations_count'),
          visits: calculateTotal(directAccess, 'participations_count'),
          records: directAccess.map(({name, accomplished_participations_count, participations_count, description}) => ({
            name,
            visits: participations_count,
            participations: accomplished_participations_count,
            description,
          })),
        },
      ],
      campaigns,
    };
  }

  return {
    direct_access: [],
    campaigns: [],
  };
};
