import {blobToBase64} from 'client/services/blobToBase64';
import {prettyJoin} from 'client/services/helpers';
import {convertUrlToFile} from 'client/services/convertUrlToFile';

import {Translation} from 'client/models/language/types';
import {WinParamsValues} from './types';

const mapTranslations = async (
  values: WinParamsValues,
  prefix: string | null,
  fieldName: 'name' | 'description' | 'image' | 'legal' | 'picto',
) => {
  const result: Record<string, Translation | {data: string}> = {};

  for (const key of Object.keys(values[fieldName])) {
    const value = values[fieldName][key];
    const keyContainsId = key.split('_').includes('id');

    if (value && !keyContainsId) {
      if (['image', 'picto'].includes(fieldName)) {
        const newValue = typeof value === 'string' ? await convertUrlToFile(value) : value;

        result[prettyJoin([prefix, fieldName, key], '_')] = {
          data: await blobToBase64(newValue),
        };
      } else {
        result[prettyJoin([prefix, fieldName, key], '_')] = value;
      }
    }
  }

  return result;
};

export default async (values: WinParamsValues) => {
  const external_name =
    (values.language && values.name[values.language]) || Object.values(values.name).filter((i) => i)[0];

  const nameTranslations = await mapTranslations(values, 'external', 'name');
  const descriptionTranslations = await mapTranslations(values, 'external', 'description');
  const imageTranslations = await mapTranslations(values, 'email', 'image');
  const pictoTranslations = await mapTranslations(values, null, 'picto');
  const legalTranslations = await mapTranslations(values, null, 'legal');

  return {
    external_name,
    internal_name: external_name,
    language_unique_mode: values.langUniqueMode,
    ...nameTranslations,
    ...descriptionTranslations,
    ...imageTranslations,
    ...pictoTranslations,
    ...legalTranslations,
  };
};
