import React, {FC, MouseEvent} from 'react';

import cn from 'classnames';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {getItem} from 'client/services/localStorage';

import {useToast} from 'client/common/hooks/useToast';

import {getUiIdClassName, getUiIdSelector} from './helpers';

import cssModule from './ui-id.module.scss';

type UiIdProps = {
  content: string;
  hash: string;
};

const b = bem('ui-id', {cssModule});

export const UiId: FC<UiIdProps> = ({content, hash}) => {
  const lang = useLanguage('UI_ID');
  const {appendToastNotification} = useToast();

  const show = Boolean(getItem('DISPLAY_UI_ID_ELEMENTS'));
  const hashClassName = getUiIdClassName(hash);
  const uiIdSelector = getUiIdSelector(hashClassName);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    appendToastNotification({
      type: 'success',
      description: lang.SELECTOR_COPIED,
    });
  };

  return (
    <>
      {content}
      {show ? (
        <CopyToClipboard text={uiIdSelector}>
          <i className={cn(b(), hashClassName)} onClick={handleClick} title={`${lang.COPY_SELECTOR_TITLE}`} />
        </CopyToClipboard>
      ) : (
        <i className={hashClassName} />
      )}
    </>
  );
};
