import React from 'react';

import cn from 'classnames';
import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectGeneralTemplate} from 'client/ducks/templates/selectors';

import AppButton from 'client/common/buttons';
import Icon from 'client/common/icon';

import {DIY_OP_MAIN_BLOCK_MODALS} from 'client/components/diy-operation/blocks/diy-op-main-block/constants';
import SaveTemplateButton from 'client/components/diy-operation/buttons/save-template-button';
import {TemplateCatalogInfoType} from 'client/components/diy-operation/modals/diy-customization-modal/helpers/get-catalogs-info-by-type';
import DiyOpTemplateModal from 'client/components/diy-operation/modals/diy-op-template-modal';

import cssModule from './diy-op-main-block-buttons.module.scss';

const b = bem('diy-op-main-block-buttons', {cssModule});

type DiyOpMainBlockButtonsProps = {
  templateCatalogInfo?: TemplateCatalogInfoType;
  onClick: (key: string, id?: number) => void;
  className?: string;
};

const DiyOpMainBlockButtons: React.FC<DiyOpMainBlockButtonsProps> = (props) => {
  const {onClick, templateCatalogInfo, className} = props;
  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_MAIN_BLOCK.DIY_OP_MAIN_BLOCK_BUTTONS');
  const modals = DIY_OP_MAIN_BLOCK_MODALS;
  const [showTemplateModal, toggleTemplateModal] = useToggle(false);
  const generalTemplate = useSelector(selectGeneralTemplate);

  return (
    <div className={cn(b(), className)}>
      {showTemplateModal && (
        <DiyOpTemplateModal onClose={toggleTemplateModal} mode="save" type="general" template={generalTemplate} />
      )}
      <div className={b('left-column')}>
        {templateCatalogInfo?.documentationUrl && (
          <a href={templateCatalogInfo?.documentationUrl} target="_blank" className={b('document-link')}>
            <Icon name="document" width={24} height={24} />
          </a>
        )}
        <SaveTemplateButton className={b('save-button')} onClick={toggleTemplateModal} disabled={!generalTemplate} />
      </div>
      <div className={b('right-column')}>
        <AppButton
          onClick={() => onClick(modals.OPTINS_MODAL.id)}
          className={b('button')}
          iconName="checked-icon"
          color="light-clients"
          label={lang.OPTINS}
          id="diy-conf-main-optins"
        />
        <AppButton
          disabled={!templateCatalogInfo?.forms?.param?.id}
          onClick={() => onClick(modals.CONFIGURATION_MODAL.id, templateCatalogInfo?.forms?.param?.id)}
          label={lang.CONFIGURATION}
          className={b('button')}
          color="light-clients"
          iconName="settings"
          id="diy-conf-main-configuration"
        />
        <AppButton
          disabled={!templateCatalogInfo?.forms?.design?.id}
          onClick={() => onClick(modals.DESIGN_MODAL.id, templateCatalogInfo?.forms?.design?.id)}
          className={b('button')}
          color="light-clients"
          label={lang.DESIGN}
          iconName="design"
          iconConfig={{width: 20, height: 20}}
          id="diy-conf-main-design"
        />
      </div>
    </div>
  );
};

export default DiyOpMainBlockButtons;
