import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';

// get default value - react-select
export const getDefaultValue = (defaultValue, options) => {
  let defaultOptions = [defaultValue];
  if (Array.isArray(defaultValue)) {
    defaultOptions = defaultValue.map((objOrStr) => {
      if (!isObject(objOrStr)) {
        return options.find((opt) => opt.value === objOrStr);
      }
      return options.find((opt) => opt.value === objOrStr.value || objOrStr.id);
    });
    // case when options are grouped
  } else if (options?.[0]?.options) {
    options.forEach((group) => {
      const option = group.options.find((opt) => opt.value === defaultValue || defaultValue?.value);
      if (option) {
        defaultOptions = [option];
      }
    });
  } else if (!isObject(defaultValue)) {
    defaultOptions = [options.find((opt) => opt.value === defaultValue)];
  } else if (isObject(defaultValue) && !isEmpty(options)) {
    defaultOptions = [options.find((opt) => opt.value === defaultValue.value)];
  }
  return defaultOptions;
};

export const getSimpleValues = (optionValues) => {
  if (Array.isArray(optionValues)) {
    return optionValues.map((option) => {
      return isObject(option) ? option?.value : option;
    });
  }
  return isObject(optionValues) ? optionValues?.value : optionValues;
};
