import {isEmptyString} from 'client/services/validator';

import {Translation} from 'client/models/language/types';

import {WinParamsValues} from './types';

export default (values: WinParamsValues, lang: Record<string, Translation>) => {
  const errors: WinParamsValues = {name: {}, image: {}, description: {}, legal: {}, picto: {}};

  for (const [key, value] of Object.entries(values.name)) {
    if (isEmptyString(value)) {
      errors.name[key] = lang.REQUIRED;
    }
  }

  return errors;
};
