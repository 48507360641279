import React from 'react';

import bem from 'client/services/bem';

import {TranslationJsx} from 'client/models/language/types';

import './donut-chart-title.scss';

const b = bem('donut-chart-title');

const DonutChartTitle = ({title, secondValueTitle}) =>
  title && (
    <p className={b()}>
      {title}
      {secondValueTitle && <span className={b('second-value')}>{secondValueTitle}</span>}
    </p>
  );

DonutChartTitle.propTypes = {
  title: TranslationJsx,
  secondValueTitle: TranslationJsx,
};

DonutChartTitle.defaultProps = {
  title: '',
};

export default DonutChartTitle;
