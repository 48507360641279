import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';
import {transformDate} from 'client/services/helpers';

import {CLIENT_PAGES} from 'client/common/config';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';

import './opt-ins-list.scss';

let LANGUAGE = {};

const b = bem('opt-ins-list');

class OptInsList extends Component {
  static propTypes = {
    onAddClick: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
    showOptInInfo: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    languageState: PropTypes.object.isRequired,
    scrollbarProps: PropTypes.object.isRequired,
    showActions: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
    isAdmin: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    LANGUAGE = props.languageState.payload.AGENCY.OPT_INS_CARD;
  }

  getOperationsLink = (item) => {
    return this.props.isAdmin
      ? `${CLIENT_PAGES.OPERATIONS}?filters={"client":{"id":${item.client_id},"name":"${
          item.client && item.client.name
        }"}}`
      : CLIENT_PAGES.OPERATIONS_ACTIVE;
  };

  getItemData = (item) => ({
    id: item.id,
    level: item.level,
    name: item.name,
    code: item.code,
    opt_in_type: item.opt_in_type,
    opt_in_text: item.opt_in_text,
    opt_in_text_translations: item.opt_in_text_translations,
    opt_out_text: item.opt_out_text,
    opt_out_text_translations: item.opt_out_text_translations,
    comment: item.comment,
    blockToDelete: Boolean(item.leads_count || item.interfaces_count),
    mandatory_order: item.mandatory_order,
    editable: item.editable,
  });

  getLeadsLink = (item) => {
    const {isAdmin} = this.props;

    const label = encodeURIComponent(item.name);

    const path = isAdmin ? CLIENT_PAGES.LEADS_LIST : `/clients/${item.client_id}/leads/database`;

    const filter = isAdmin
      ? `?filter={"optInRadio":"true","opt_in":[{"value":${item.id},"label":"${label}"}]}`
      : `?filter={"optInRadio":"true","optIn":[{"value":${item.id},"label":"${label}"}]}`;

    return path + filter;
  };

  render() {
    const {data, onAddClick, onEditClick, scrollbarProps, showActions, showOptInInfo, isAdmin} = this.props;
    const OptInsListEmpty = !data.length;

    return (
      <div className={b({expand: true, 'is-admin': isAdmin, empty: OptInsListEmpty})}>
        <div className={b('list')}>
          {OptInsListEmpty ? (
            <div className={b('empty-wrapper')}>
              <Icon name="table-no-data" className={b('icon')} />
              <p className={b('no-data-text')}>{LANGUAGE.CLIENT_NO_RECORDS}</p>
            </div>
          ) : (
            <CustomScrollbars
              scrollbarProps={{
                autoHeightMax: 260,
                autoHeightMin: 260,
                ...scrollbarProps,
              }}
            >
              <div className={b('list-content')}>
                {data.map((item, i) => (
                  <div key={i} className={b('item')}>
                    <div className={b('item-header')}>
                      <div
                        className={b('item-name')}
                        onClick={() => (showActions ? onEditClick(this.getItemData(item)) : showOptInInfo(item))}
                        title={item.name}
                      >
                        {item.name}
                      </div>
                      <div className={b('item-counts')}>
                        {isAdmin && (
                          <div className={b('interfaces')}>
                            <Icon name="interface" className={b('interfaces-icon')} />
                            <Link to={this.getOperationsLink(item)} className="link">
                              {item.interfaces_count}
                            </Link>
                          </div>
                        )}
                        <div className={b('leads')}>
                          <Icon name="lead" />
                          <Link to={this.getLeadsLink(item)} className="link">
                            {item.leads_count}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className={b('info')}>
                        <span className={b('info-label')}>{LANGUAGE.LEVEL_LABEL}</span>
                        <span className={b('info-text')}>{` ${item.level}`}</span>
                      </span>
                      <span className={b('info')}>
                        <span className={b('info-label')}>{LANGUAGE.CODE_LABEL}</span>
                        <span className={b('info-text')}>{` ${item.code}`}</span>
                      </span>
                      <span className={b('info')}>
                        <span className={b('info-label')}>{LANGUAGE.OPT_IN_TYPE_LABEL}</span>
                        <span className={b('info-text')}>
                          {` ${LANGUAGE.OPT_IN_TYPES[item.opt_in_type.toUpperCase()]}`}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span className={b('info')}>
                        <span className={b('info-label')}>{LANGUAGE.CREATED_LABEL}</span>
                        <span className={b('info-text')}>{` ${transformDate(item.created_at)}`}</span>
                      </span>
                      {item.user && (
                        <span className={b('info')}>
                          <span className={b('info-label')}>{LANGUAGE.BY_LABEL}</span>
                          {isAdmin && (
                            <div className={cn(b('info-username'), 'ellipsis-text')} title={item.user.full_name}>
                              {` ${item.user.full_name}`}
                            </div>
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </CustomScrollbars>
          )}
        </div>
        {showActions && (
          <div className={b('bottom')}>
            <button className="button button--bg-2" onClick={onAddClick}>
              <Icon name="plus" className="button__icon" />
              <span>{LANGUAGE.ADD_OPT_IN_BUTTON}</span>
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(OptInsList);
