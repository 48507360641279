import types from './types';

const initialState = {
  privacy_policies: [],
  meta: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_PRIVACY_POLICIES_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...(action.payload?.meta || {}),
        },
        privacy_policies: action.payload.privacy_policies || [],
      };

    default:
      return state;
  }
}
