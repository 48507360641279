import React, {Component} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {connect} from 'react-redux';

import Icon from 'client/components/common/icon';

import VisSettingsPopoverBase from './components/vis-settings-popover-base';

class VisSlideshowSettingsPopover extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    slideshow: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    children: PropTypes.any,
  };

  static defaultProps = {
    children: null,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.VISUALS.DIAPO_SETTINGS_POPOVER;
  }

  render() {
    const {
      slideshow: {allowed_user, ...slideshow},
      children,
      onDelete,
      onEdit,
    } = this.props;

    return (
      <VisSettingsPopoverBase
        onDelete={onDelete}
        onEdit={onEdit}
        messageText={this.LANGUAGE.DELETE_DIAPO_LABEL}
        title={this.LANGUAGE.TITLE}
        overlayContent={
          <div>
            <div className="vis-settings-popover__block">
              <div className="vis-settings-popover__row">
                <div className="form-field-wrap form-field-wrap--width-1">
                  <div className="fake-input">
                    <div className="fake-input__label">{this.LANGUAGE.ID_LABEL}</div>
                    <div className="fake-input__field">{slideshow.id}</div>
                  </div>
                </div>
                <CopyToClipboard text={slideshow.id}>
                  <button className="button button--circle button--bg-8">
                    <Icon name="copy" className="button__icon" />
                  </button>
                </CopyToClipboard>
              </div>
            </div>
            <div className="vis-settings-popover__block">
              <div className="fake-input fake-input--disabled">
                <div className="fake-input__label">{this.LANGUAGE.NAME_LABEL}</div>
                <div className="fake-input__field">{(allowed_user && allowed_user.full_name) || ''}</div>
              </div>
              <div className="fake-input fake-input--disabled">
                <div className="fake-input__label">{this.LANGUAGE.START_DATE_LABEL}</div>
                <div className="fake-input__field">{moment(slideshow.from).format('DD/MM/YYYY HH:mm:ss')}</div>
              </div>
              <div className="fake-input fake-input--disabled">
                <div className="fake-input__label">{this.LANGUAGE.END_DATE_LABEL}</div>
                <div className="fake-input__field">{moment(slideshow.to).format('DD/MM/YYYY HH:mm:ss')}</div>
              </div>
            </div>
          </div>
        }
      >
        {children}
      </VisSettingsPopoverBase>
    );
  }
}

export default connect(({languageState}) => ({
  languageState: languageState,
}))(VisSlideshowSettingsPopover);
