import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getParticipations(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PARTICIPATIONS}`,
      method: 'GET',
      types: [types.GET_PARTICIPATIONS_REQUEST, types.GET_PARTICIPATIONS_SUCCESS, types.GET_PARTICIPATIONS_ERROR],
      queryParams: {
        page: 1,
        per_page: 10,
        ...queryParams,
      },
    });
}

export function getParticipationsTableData(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PARTICIPATIONS}/table_data`,
      method: 'GET',
      types: [
        types.GET_PARTICIPATION_TABLE_DATA_REQUEST,
        types.GET_PARTICIPATION_TABLE_DATA_SUCCESS,
        types.GET_PARTICIPATION_TABLE_DATA_ERROR,
      ],
      queryParams: {
        page: 1,
        per_page: 10,
        ...queryParams,
      },
    });
}

export function updateParticipation(id, body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PARTICIPATIONS}/${id}`,
      method: 'PUT',
      types: [
        types.UPDATE_PARTICIPATIONS_REQUEST,
        types.UPDATE_PARTICIPATIONS_SUCCESS,
        types.UPDATE_PARTICIPATIONS_ERROR,
      ],
      body,
    });
}

export function getOperationsAction() {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATIONS}`,
      method: 'GET',
      types: [types.GET_OPERATIONS_REQUEST, types.GET_OPERATIONS_SUCCESS, types.GET_OPERATIONS_ERROR],
      isAuth: true,
      noCache: true,
    });
}

export function clearParticipations() {
  return (dispatch) => dispatch({type: types.CLEAR_PARTICIPATIONS});
}

export function getLevelOptions(queryParams) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACE_LEVELS}`,
      method: 'GET',
      types: [types.GET_LEVEL_OPTIONS_REQUEST, types.GET_LEVEL_OPTIONS_SUCCESS, types.GET_LEVEL_OPTIONS_ERROR],
      queryParams,
    });
}

export function getLevelStatistic(queryParams) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PARTICIPATIONS_LEVEL_STATISTIC}`,
      method: 'GET',
      types: [
        types.GET_PARTICIPATIONS_LEVEL_STATISTIC_REQUEST,
        types.GET_PARTICIPATIONS_LEVEL_STATISTIC_SUCCESS,
        types.GET_PARTICIPATIONS_LEVEL_STATISTIC_ERROR,
      ],
      queryParams,
    });
}

export function getTimelineStatistic(queryParams) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PARTICIPATIONS_TIMELINE_STATISTIC}`,
      method: 'GET',
      types: [
        types.GET_PARTICIPATIONS_TIMELINE_STATISTIC_REQUEST,
        types.GET_PARTICIPATIONS_TIMELINE_STATISTIC_SUCCESS,
        types.GET_PARTICIPATIONS_TIMELINE_STATISTIC_ERROR,
      ],
      queryParams,
    });
}

export const updateParicipationField = (id, path, value) => {
  return (dispatch) =>
    dispatch({
      type: types.UPDATE_PARTICIPATION_FIELD,
      payload: {
        id,
        path,
        value,
      },
    });
};
