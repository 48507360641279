import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

import './form-field-wrap.scss';

function FormFieldWrap({children, fieldClassNames, isButtonsContainer, label, ...rest}) {
  const classNames = cn('form-field-wrap', fieldClassNames, {'form-field-wrap--buttons': isButtonsContainer});

  return (
    <div className={classNames} {...rest}>
      {label && <p className="form-field-wrap__label">{label}</p>}
      {children}
    </div>
  );
}

FormFieldWrap.propTypes = {
  children: PropTypes.node.isRequired,
  fieldClassNames: PropTypes.string,
  label: TranslationJsx,
  isButtonsContainer: PropTypes.bool,
};

FormFieldWrap.defaultProps = {
  fieldClassNames: '',
  label: '',
  isButtonsContainer: false,
};

export default FormFieldWrap;
