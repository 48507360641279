export const getPopoverPosition = (marker, map) => {
  if (!('bounds' in map)) {
    return 'top';
  }

  const {
    bounds: {
      nw: {lat: topBound},
      sw: {lat: bottomBound},
    },
  } = map;

  // marker is located in upper one fourth part of the map
  if (marker.lat > topBound - (topBound - bottomBound) / 4) {
    return 'bottom';
  }

  return 'top';
};
