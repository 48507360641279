import {useCallback, useEffect, useMemo} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import {getRegionAccesses} from 'client/ducks/regions/actions';
import {selectRegionAccesses} from 'client/ducks/regions/selectors';
import {selectClientUserMembership} from 'client/ducks/user-clients/selectors';

import {ApiDispatch} from 'client/types';

export const useFetchRegionAccesses = () => {
  const [loading, toggleLoading] = useToggle(true);

  const dispatch: ApiDispatch = useDispatch();
  const clientUserMembership = useSelector(selectClientUserMembership);
  const regionAccesses = useSelector(selectRegionAccesses);

  const fetchRegionAccesses = useCallback(() => {
    const queryParams = {
      q: {
        accessable_id_eq: clientUserMembership.id,
      },
    };
    toggleLoading(true);
    return dispatch(getRegionAccesses(queryParams)).finally(() => toggleLoading(false));
  }, [clientUserMembership.id, dispatch, toggleLoading]);

  useEffect(() => {
    fetchRegionAccesses();
  }, [fetchRegionAccesses]);

  const regionIds = useMemo(() => regionAccesses?.map((access) => access.region_id), [regionAccesses]);

  return {regionIds, loading};
};
