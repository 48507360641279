import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {GoogleMapInstance, MarkerType} from 'client/common/google-map';
import Popover from 'client/common/popovers/popover';

import {DeviceAffectation} from 'client/models/device-affectations';

import {getPopoverPosition} from './helpers';

import {DevicesMapMarkerTooltip} from '../devices-map-marker-tooltip';

import cssModule from './devices-map-marker.module.scss';

const b = bem('devices-map-marker', {cssModule});

type DevicesMapMarkerProps = {
  marker: MarkerType<DeviceAffectation>;
  map: GoogleMapInstance;
};

export const DevicesMapMarker: React.FC<DevicesMapMarkerProps> = (props) => {
  const {marker, map} = props;

  const lang = useLanguage('CLIENT_DEVICES');

  const labels = {
    media_player: lang.ACTIVITY_DESCRIPTIONS.MEDIA_PLAYER,
    no_activity: lang.ACTIVITY_DESCRIPTIONS.NO_ACTIVITY,
  };

  const position = getPopoverPosition(marker, map);

  return (
    <Popover
      position={position}
      overlay={<DevicesMapMarkerTooltip affectation={marker.data} lang={labels} />}
      triggerClassName={b()}
    >
      <></>
    </Popover>
  );
};
