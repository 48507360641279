import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Popover from 'client/common/popovers/popover';

import './leads-error-popover.scss';

function LeadsErrorPopover({children, languageState, date, url}) {
  const LANGUAGE = languageState.payload.LEADS_PORT;

  return (
    <Popover
      position="bottom"
      className="pull-left"
      overlayInnerStyle={{
        width: 344,
      }}
      overlay={
        <div className="popover-list popover-list--color-2">
          <h3 className="popover-list__title">{LANGUAGE.ERRORS_POPOVER_TITLE}</h3>
          <div className="popover-list__list leads-error-popover">
            <div className="leads-error-popover__error-item">
              <a href={url} className="leads-error-popover__error-link">
                {LANGUAGE.ERRORS_POPOVER_DOWNLOAD && LANGUAGE.ERRORS_POPOVER_DOWNLOAD.replace(/\[TYPE\]/, 'XLS')}
              </a>
              <div className="leads-error-popover__error-date">{`${LANGUAGE.ERRORS_POPOVER_DATE_CREATED} ${date}`}</div>
            </div>
          </div>
        </div>
      }
    >
      {children}
    </Popover>
  );
}

LeadsErrorPopover.defaultProps = {
  children: '',
};

LeadsErrorPopover.propTypes = {
  languageState: PropTypes.object,
  children: PropTypes.node,
  url: PropTypes.string,
  date: PropTypes.string,
};

export default connect(({languageState}) => ({
  languageState,
}))(LeadsErrorPopover);
