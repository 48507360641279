import React, {useEffect, useMemo, useState} from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectTranslationsArray} from 'client/ducks/translations/selectors';

import AppButton from 'client/common/buttons';
import SearchInput from 'client/common/inputs/search-input';
import Modal from 'client/common/modals/modal';

import TranslationsSearchResults from './translations-search-results';

import './translations-search-modal.scss';

const b = bem('translations-search-modal');

const TranslationsSearchModal = ({query: initialQuery, onItemClick, onClose}) => {
  const lang = useLanguage('TRANSLATIONS.SEARCH_MODAL');
  const [query, setQuery] = useState(null);
  const translations = useSelector(selectTranslationsArray);

  const searchResults = useMemo(() => {
    if (!query || !Array.isArray(translations)) {
      return [];
    }

    const preparedQuery = query.toLowerCase().trim();

    return translations.filter(
      (el) =>
        el.path.toLowerCase().includes(preparedQuery) ||
        el.description?.toLowerCase().includes(preparedQuery) ||
        el.translation_items?.some(({value}) => value.toLowerCase().includes(preparedQuery)),
    );
  }, [query, translations]);

  useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  return (
    <Modal title={lang.TITLE} onClose={onClose} className={b('modal-dialog')}>
      <div className={b()}>
        <div className={b('search-field')}>
          <SearchInput placeholder={lang.PLACEHOLDER} onSearch={(value) => setQuery(value)} />
        </div>
        <TranslationsSearchResults searchResults={searchResults} query={query} onItemClick={onItemClick} />
        <div className={b('close-button-wrap')}>
          <AppButton label={lang.CLOSE} color="primary" onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
};

TranslationsSearchModal.propTypes = {
  query: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export default TranslationsSearchModal;
