import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getTrackers(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.TWITTER_HASHTAG_TRACKERS}`,
      method: 'GET',
      types: [types.GET_TRACKERS_REQUEST, types.GET_TRACKERS_SUCCESS, types.GET_TRACKERS_ERROR],
      noCache: true,
      queryParams,
    });
}

export function getTracker(id, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.TWITTER_HASHTAG_TRACKERS}/${id}`,
      method: 'GET',
      types: [types.GET_TRACKER_REQUEST, types.GET_TRACKER_SUCCESS, types.GET_TRACKER_ERROR],
      noCache: true,
      queryParams,
    });
}

export function fetchNewTracker(body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.TWITTER_HASHTAG_TRACKERS_NEW}`,
      method: 'POST',
      types: [types.FETCH_NEW_TRACKER_REQUEST, types.FETCH_NEW_TRACKER_SUCCESS, types.FETCH_NEW_TRACKER_ERROR],
      noCache: true,
      body,
    });
}
