import {checkRule, date, dateIsAfter, required} from 'client/services/validator';

export default (formValues, {langErrors}) => {
  return {
    kind: checkRule(formValues.kind, required(langErrors.REQUIRED)),
    name: checkRule(formValues.name, required(langErrors.REQUIRED)),
    from: checkRule(formValues.from, [required(langErrors.REQUIRED), date(langErrors.INVALID_DATE)]),
    to: checkRule(formValues.to, [
      required(langErrors.REQUIRED),
      date(langErrors.INVALID_DATE),
      (value) =>
        formValues.from && !date('.')(formValues.from)
          ? dateIsAfter(langErrors.MUST_BE_LATER_THAN_FROM, 'from')(value, {from: formValues.from})
          : null,
    ]),
    active: typeof formValues.active !== 'boolean' ? langErrors.REQUIRED : null,
  };
};
