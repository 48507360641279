import {AGENCY_USERS_LIST_NO_DATA, AGENCY_USERS_LIST_LOADED} from './agency-users.action';

let init = {
  type: AGENCY_USERS_LIST_NO_DATA,
  payload: [],
};

export default function (_state_ = init, action) {
  let state = Object.assign({}, _state_);
  switch (action.type) {
    case AGENCY_USERS_LIST_NO_DATA:
      state.type = AGENCY_USERS_LIST_NO_DATA;
      state.payload = action.payload;
      return state;
    case AGENCY_USERS_LIST_LOADED:
      state.type = AGENCY_USERS_LIST_LOADED;
      state.payload = action.payload.agency_memberships;
      return state;
    default:
      return state;
  }
}
