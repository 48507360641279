import React from 'react';

import PropTypes from 'prop-types';

import {TextField} from 'client/components/common/fields';

import './game-prize-draw-block.scss';

const GamePrizeDrawBlock = (props) => {
  const {lang, editable} = props;

  return (
    <div className="game-prize-draw-block">
      <p className="game-prize-draw-block__title">{lang.PRIZE_DRAW}</p>
      <div className="game-prize-draw-block__content">
        <span className="game-prize-draw-block__label">{lang.DRAWING}</span>
        <TextField
          name="default_draw_time"
          placeholder={lang.DRAWING_PLACEHOLDER}
          withWrap={false}
          readOnly={!editable}
        />
      </div>
    </div>
  );
};

GamePrizeDrawBlock.propTypes = {
  lang: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default GamePrizeDrawBlock;
