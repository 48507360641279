import moment from 'moment';

import {DATE_REGEX} from 'client/services/validator';

import {COUPON_TYPES, VALIDITY_TYPES, PREFIXES} from 'client/ducks/coupons/constants';

const datePickerFormat = 'DD/MM/YYYY';

const isEmptyString = (value) => {
  return !value || !value.trim() || +value < 1;
};

const isInvalidDate = (value) => {
  return !value.match(DATE_REGEX);
};

const isNotNumber = (value) => {
  return !value.match(/^\d+$/);
};

const isFromDateLessThenTo = (from, to) => {
  const toUnix = moment(to, datePickerFormat).unix();
  const fromUnix = moment(from, datePickerFormat).unix();

  return toUnix > fromUnix;
};

const validateAppFieldset = (formValues, lang) => {
  const errors = {};

  if (isEmptyString(formValues.internal_name)) {
    errors.internal_name = lang.INTERNAL_NAME_REQUIRED;
  }

  if (isEmptyString(formValues.internal_code)) {
    errors.internal_code = lang.INTERNAL_CODE_REQUIRED;
  }

  if (isEmptyString(formValues.external_name)) {
    errors.external_name = lang.EXTERNAL_NAME_REQUIRED;
  }

  if (isEmptyString(formValues.type)) {
    errors.type = lang.TYPE_REQUIRED;
  }

  if (formValues.validity_type === VALIDITY_TYPES.FIXED_VALUE) {
    if (isEmptyString(formValues.validity_from)) {
      errors.validity_from = lang.DATE_REQUIRED;
    }

    if (!errors.validity_from && isInvalidDate(formValues.validity_from)) {
      errors.validity_from = lang.INVALID_DATE;
    }

    if (isEmptyString(formValues.validity_to)) {
      errors.validity_to = lang.DATE_REQUIRED;
    }

    if (!errors.validity_to && isInvalidDate(formValues.validity_to)) {
      errors.validity_to = lang.INVALID_DATE;
    }

    if (
      !errors.validity_to &&
      !errors.validity_from &&
      !isFromDateLessThenTo(formValues.validity_from, formValues.validity_to)
    ) {
      errors.validity_from = lang.DATE_SHOULD_BE_LESS;
    }
  }

  if (formValues.validity_type === VALIDITY_TYPES.DURATION) {
    if (isEmptyString(formValues.validity_days)) {
      errors.validity_days = lang.DURATION_REQUIRED;
    }
  }

  return errors;
};

const validateWeezioFieldset = (formValues, lang) => {
  const errors = {};

  if (isEmptyString(formValues.weezio_maximum_tokens_number)) {
    errors.weezio_maximum_tokens_number = lang.NUMBER_REQUIRED;
  }

  if (!errors.weezio_maximum_tokens_number && isNotNumber(formValues.weezio_maximum_tokens_number)) {
    errors.weezio_maximum_tokens_number = lang.NUMERIC_REQUIRED;
  }

  if (isEmptyString(formValues.length_without_prefix)) {
    errors.length_without_prefix = lang.LENGTH_REQUIRED;
  } else if (isNotNumber(formValues.length_without_prefix) || +formValues.length_without_prefix < 6) {
    errors.length_without_prefix = lang.LENGTH_INVALID;
  }

  if (formValues.prefix1 === PREFIXES.FIXED_VALUE && isEmptyString(formValues.prefix1_fixed_value)) {
    errors.prefix1_fixed_value = lang.FIXED_VALUE_REQUIRED;
  }

  if (formValues.prefix2 === PREFIXES.FIXED_VALUE && isEmptyString(formValues.prefix2_fixed_value)) {
    errors.prefix2_fixed_value = lang.FIXED_VALUE_REQUIRED;
  }

  return errors;
};

const validateSogecFieldset = (formValues, lang) => {
  const errors = {};

  if (isEmptyString(formValues.sogec_maximum_tokens_number)) {
    errors.sogec_maximum_tokens_number = lang.NUMBER_REQUIRED;
  }

  if (!errors.sogec_maximum_tokens_number && isNotNumber(formValues.sogec_maximum_tokens_number)) {
    errors.sogec_maximum_tokens_number = lang.NUMERIC_REQUIRED;
  }

  if (isEmptyString(formValues.coupon_id)) {
    errors.coupon_id = lang.COUPON_ID_REQUIRED;
  }

  if (isEmptyString(formValues.campaign_id)) {
    errors.campaign_id = lang.CAMPAIGN_ID_REQUIRED;
  }

  if (isEmptyString(formValues.login)) {
    errors.login = lang.LOGIN_REQUIRED;
  }

  if (isEmptyString(formValues.password)) {
    errors.password = lang.PASSWORD_REQUIRED;
  }

  return errors;
};

export default (formValues, props) => {
  const lang = props.lang.ERRORS;

  const appErrors = validateAppFieldset(formValues, lang);
  const weezioErrors = formValues.type === COUPON_TYPES.WEEZIO ? validateWeezioFieldset(formValues, lang) : {};
  const sogecErrors = formValues.type === COUPON_TYPES.SOGEC ? validateSogecFieldset(formValues, lang) : {};

  return {
    ...appErrors,
    ...weezioErrors,
    ...sogecErrors,
  };
};
