import React from 'react';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams} from 'client/services/hooks';

import AppButton from 'client/common/buttons';
import SearchInput from 'client/common/inputs/search-input';

import cssModule from './client-devices-list-tool-bar.module.scss';

const b = bem('client-devices-list-tool-bar', {cssModule});

type ClientDevicesListFilterBarProps = {
  totalCount?: number;
  onOpenFilterModal?: () => void;
  onOpenMapModal?: () => void;
};

const ClientDevicesListFilterBar: React.FC<ClientDevicesListFilterBarProps> = (props) => {
  const {totalCount, onOpenFilterModal, onOpenMapModal} = props;
  const [queryParams, setQueryParams] = useQueryParams();

  const lang = useLanguage('CLIENT_DEVICES');

  return (
    <div className={b()}>
      <div className={b('search-field')}>
        <SearchInput
          onSearch={(value) => setQueryParams({search: value, page: 1})}
          searchDefault={queryParams.search}
        />
      </div>
      <div className={b('buttons-container')}>
        <AppButton label={lang.FILTER_BUTTON} iconName="filters" onClick={onOpenFilterModal} />
        <div>
          <span className={b('applied-filter-number')}>{totalCount}</span>
          <span className={b('applied-filter-number')}>{lang.FOUND}</span>
        </div>
        <AppButton className={b('button')} iconName="map-line" onClick={onOpenMapModal} label={lang.MAP_VIEW_BUTTON} />
      </div>
    </div>
  );
};

export default ClientDevicesListFilterBar;
