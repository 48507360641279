import React from 'react';

import PropTypes from 'prop-types';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import CustomInfoForm from '../forms/custom-info-form/custom-info-form';

function LeadsCustomInfo({editMode, data, customColumns, submitCustomInfoForm}) {
  return (
    <div className="row">
      <CustomScrollbars
        scrollbarProps={{
          autoHeightMax: 300,
        }}
      >
        <CustomInfoForm
          editMode={editMode}
          data={data}
          customColumns={customColumns}
          submitCustomInfoForm={submitCustomInfoForm}
        />
      </CustomScrollbars>
    </div>
  );
}

LeadsCustomInfo.defaultProps = {
  editMode: false,
};

LeadsCustomInfo.propTypes = {
  editMode: PropTypes.bool,
  data: PropTypes.object.isRequired,
  customColumns: PropTypes.object.isRequired,
  submitCustomInfoForm: PropTypes.func,
};

export default LeadsCustomInfo;
