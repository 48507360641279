import React from 'react';

import bem from 'client/services/bem';

import Carousel from 'client/common/carousel';

import {TemplateScreenshot} from 'client/models/templates/types';

import cssModule from './template-screenshots-tab.module.scss';

const b = bem('template-screenshots-tab', {cssModule});

type TemplateScreenshotsTabProps = {
  screenshots: TemplateScreenshot[];
};

const TemplateScreenshotsTab: React.FC<TemplateScreenshotsTabProps> = (props) => {
  const {screenshots} = props;

  return (
    <div className={b()}>
      <Carousel
        slides={screenshots.map((item) => (
          <div key={item.id} className={b('slider-item-wrap')}>
            <img className={b('slider-item')} src={item.url} alt="" />
          </div>
        ))}
        width="full"
        arrowVariant="arrow-alt"
        slidesPerView={1}
        showIndex
        hasArrows
        loop
      />
    </div>
  );
};

export default TemplateScreenshotsTab;
