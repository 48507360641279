import React, {useCallback, useMemo, useState} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {CLIENT_PAGES} from 'client/common/config';
import {FieldWrap} from 'client/common/fields';
import Icon from 'client/common/icon';
import Timer from 'client/common/timer';

import AuthButton from '../../components/auth-button';
import AuthError from '../../components/auth-error';
import AuthField from '../../components/auth-field';
import AuthLink from '../../components/auth-link';
import AuthMessage from '../../components/auth-message';

import cssModule from '../auth-double-factor.module.scss';

const MAX_CODE_LENGTH = 6;

const NEW_CODE_TIMER = 59;

const MAX_ATTEMPTS_NUMBER = 5;

const b = bem('auth-double-factor', {cssModule});

const AuthDoubleFactorCodeInput = (props) => {
  const {
    credentials,
    attempts,
    lastAttemptCode,
    contact,
    blocked,
    unlockAfter,
    authenticate,
    changeMode,
    unBlock,
    resendAuthCode,
  } = props;
  const lang = useLanguage('AUTH.DOUBLE_FACTOR_AUTHENTIFICATION');
  const [codeValue, setCodeValue] = useState('');
  const [showNewCodeTimer, setShowNewCodeTimer] = useState(true);

  const resendCode = useCallback(() => {
    if (!showNewCodeTimer) {
      resendAuthCode().then(() => setShowNewCodeTimer(true));
    }
  }, [resendAuthCode, showNewCodeTimer]);

  const onCodeChangeHandler = ({target}) => {
    let nextCodeValue;
    if (target.value.length <= MAX_CODE_LENGTH) {
      nextCodeValue = target.value;
      setCodeValue(nextCodeValue);
    }
  };

  const hasError = useMemo(() => {
    const comparedCodeChanged = codeValue !== lastAttemptCode;
    return attempts > 0 && !comparedCodeChanged;
  }, [codeValue, lastAttemptCode, attempts]);

  const newCodeTimer = useMemo(() => {
    return (
      attempts <= MAX_ATTEMPTS_NUMBER && (
        <AuthButton
          nativeStyle
          link
          additional
          disabled={showNewCodeTimer}
          onClick={resendCode}
          label={
            <>
              {showNewCodeTimer ? (
                <Timer seconds={NEW_CODE_TIMER} text={lang.RESEND_CODE} onFinish={() => setShowNewCodeTimer(false)} />
              ) : (
                lang.RESEND_CODE
              )}
            </>
          }
        />
      )
    );
  }, [attempts, lang, showNewCodeTimer, resendCode]);

  const blockTimer = useMemo(() => {
    return (
      !!unlockAfter && (
        <FieldWrap>
          <AuthError>
            <Timer seconds={unlockAfter} text={lang.BLOCKED_TEMPORARY} onFinish={unBlock} />
            {!unlockAfter && lang.ACCOUNT_BLOCKED}
          </AuthError>
        </FieldWrap>
      )
    );
  }, [lang, unlockAfter, unBlock]);

  const contactEmail = lang.CONTACT_NOTIFICATION_EMAIL.replace('{email}', credentials.subsidiary_email);
  const contactPhone = lang.CONTACT_NOTIFICATION_PHONE.replace('{phone}', credentials.subsidiary_phone);

  const errorFieldMessage = (hasError && !blocked && lang.INVALID_CODE) || '';

  return (
    <div>
      <AuthMessage text={`${lang.CODE_SENT} ${contact}`} />
      <AuthField
        type="password-input"
        label={lang.INPUT_LABEL}
        placeholder={lang.PLACEHOLDER}
        name="code"
        value={codeValue}
        onChange={onCodeChangeHandler}
        errorMessage={errorFieldMessage}
      />
      {blocked ? blockTimer : newCodeTimer}
      <AuthButton link label={lang.CHOOSE_OPTION} oversize nativeStyle onClick={changeMode} />
      <AuthLink
        className={b('contact-link')}
        label={
          <>
            {lang.CONTACT_ADMINISTRATOR_LINK}
            <Icon name="arrow-right" />
          </>
        }
        to={CLIENT_PAGES.LOGIN}
        additional
        oversize
        main
        disabled
        tooltip={
          <div>
            <p>{contactEmail}</p>
            <p>{contactPhone}</p>
          </div>
        }
      />
      <AuthButton
        label={lang.LOGIN}
        disabled={codeValue.length < MAX_CODE_LENGTH || blocked}
        onClick={() => authenticate(codeValue)}
      />
    </div>
  );
};

AuthDoubleFactorCodeInput.propTypes = {
  credentials: PropTypes.shape({
    subsidiary_email: PropTypes.string,
    subsidiary_phone: PropTypes.string,
  }).isRequired,
  attempts: PropTypes.number.isRequired,
  lastAttemptCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contact: PropTypes.string,
  blocked: PropTypes.bool,
  unlockAfter: PropTypes.number,
  authenticate: PropTypes.func,
  changeMode: PropTypes.func,
  resendAuthCode: PropTypes.func,
  unBlock: PropTypes.func.isRequired,
};

AuthDoubleFactorCodeInput.defaultProps = {
  blocked: false,
  unlockAfter: 0,
};

export default AuthDoubleFactorCodeInput;
