import PropTypes from 'prop-types';

export const AnimationShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  contact_email: PropTypes.string,
  contact_name: PropTypes.string,
  contact_phone: PropTypes.string,
  reco_email: PropTypes.string,
  reco_name: PropTypes.string,
  reco_phone: PropTypes.string,
  security_email: PropTypes.string,
  security_fax: PropTypes.string,
  security_phone: PropTypes.string,
  week: PropTypes.number,
  place: PropTypes.shape({
    id: PropTypes.number.isRequired,
    city_name: PropTypes.string,
    city_zip: PropTypes.string,
    street_address: PropTypes.string,
    app_city_name: PropTypes.string,
    app_zip: PropTypes.string,
    app_phone: PropTypes.string,
    app_street_address: PropTypes.string,
  }),
  network: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    icon_url: PropTypes.string,
  }),
});
