import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import {CATALOG_TYPES} from 'client/models/templates/constants';
import {TemplateGroup} from 'client/models/templates/types';
import {ApiAction} from 'client/types';

import types from './types';

export const getCatalogs: ApiAction = (queryParams: Record<string, any>) => (dispatch) =>
  callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.CATALOGS}`,
    method: 'GET',
    noCache: true,
    types: [types.GET_CATALOGS_REQUEST, types.GET_CATALOGS_SUCCESS, types.GET_CATALOGS_ERROR],
    queryParams,
  });

export const getClientCatalogs: ApiAction =
  ({clientId, search = '', sort = ''}) =>
  (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CATALOGS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_CLIENT_CATALOGS_REQUEST, types.GET_CLIENT_CATALOGS_SUCCESS, types.GET_CLIENT_CATALOGS_ERROR],
      queryParams: {
        q: {
          templates_client_id_eq: clientId,
          type_in: [CATALOG_TYPES.FORM, CATALOG_TYPES.GAME, CATALOG_TYPES.QUIZ],
          title_cont: search,
          s: `${sort} desc`,
        },
        include_template_form_accesses_editable: true,
        include_catalog_icon_url: null,
        include_template_screenshots: null,
        include: {
          templates: {
            creator_team: null,
            catalog: {
              forms: null,
              parent: {
                forms: null,
              },
            },
          },
        },
        distinct: true,
      },
    });

export const getTemplateGroups: ApiAction<{template_groups: TemplateGroup[]}> =
  ({clientId, search = '', sort = ''}) =>
  (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.TEMPLATE_GROUPS}`,
      method: 'GET',
      types: [types.GET_TEMPLATE_GROUPS_REQUEST, types.GET_TEMPLATE_GROUPS_SUCCESS, types.GET_TEMPLATE_GROUPS_ERROR],
      queryParams: {
        include: ['templates.catalog.forms', 'templates.creator_team', 'templates.client_user'],
        include_template_screenshots: null,
        include_template_form_accesses_editable: true,
        q: {
          templates_client_id_eq: clientId,
          templates_title_cont: search,
          s: sort,
        },
        distinct: true,
      },
    });

export const setCurrentAgencyClientId = (agencyClientId: number | null) => {
  return {
    type: types.SET_CURRENT_AGENCY_CLIENT_ID,
    payload: agencyClientId,
  };
};
