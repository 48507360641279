export default {
  CLEAR_UNBOUNCE_INTERFACES_STATE: '@@unbounce_interfaces/CLEAR_UNBOUNCE_INTERFACES_STATE',

  GET_NEW_UNBOUNCE_INTERFACE_REQUEST: '@@unbounce_interfaces/GET_NEW_UNBOUNCE_INTERFACE_REQUEST',
  GET_NEW_UNBOUNCE_INTERFACE_SUCCESS: '@@unbounce_interfaces/GET_NEW_UNBOUNCE_INTERFACE_SUCCESS',
  GET_NEW_UNBOUNCE_INTERFACE_ERROR: '@@unbounce_interfaces/GET_NEW_UNBOUNCE_INTERFACE_ERROR',

  CREATE_UNBOUNCE_INTERFACE_REQUEST: '@@unbounce_interfaces/CREATE_UNBOUNCE_INTERFACE_REQUEST',
  CREATE_UNBOUNCE_INTERFACE_SUCCESS: '@@unbounce_interfaces/CREATE_UNBOUNCE_INTERFACE_SUCCESS',
  CREATE_UNBOUNCE_INTERFACE_ERROR: '@@unbounce_interfaces/CREATE_UNBOUNCE_INTERFACE_ERROR',
};
