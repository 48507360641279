import update from 'immutability-helper';
import defaultsDeep from 'lodash/defaultsDeep';
import get from 'lodash/get';

import {filterScenarioVariablesByType, compactObject} from '../helpers';
import {getOptInsOptions, OPT_INS_OPERATOR_OPTIONS} from '../opt-ins';

export const createImplementation = () => ({
  check_opt_ins: false,
  check_opt_ins_operator: OPT_INS_OPERATOR_OPTIONS[0].value,
  message_sender_id: null,
  recipient_variable_id: null,
  message: '',
});

const transformFormData = (values) => {
  let mappedOptIns = [];

  if (values.implementation.check_opt_ins) {
    mappedOptIns = values.optInsOptions
      .filter((optIn) => optIn.active)
      .map((optIn) => ({
        id: optIn.id,
        opt_in_column_id: optIn.opt_in_column_id,
      }));
  }

  return update(values, {
    $unset: ['optInsOptions'],
    implementation: {
      check_opt_ins_operator: (operator) => (values.implementation.check_opt_ins ? operator : null),
      $merge: {
        message_scenario_step_to_opt_in_columns: mappedOptIns,
      },
    },
  });
};

export const getSMSRecipients = (scenarioVariables) => {
  return filterScenarioVariablesByType(scenarioVariables, 'phone');
};

export const getInitialValues = (data, state) => {
  const {
    scenario: {
      payload: {scenario_variables: scenarioVariables, scenario_step_variables: scenarioStepVariables = []},
    },
    smsSenders: {payload: smsSenders},
    optIns: {optIns},
  } = state;

  const smsRecipients = getSMSRecipients(scenarioVariables.concat(scenarioStepVariables));

  return defaultsDeep({}, compactObject(data), {
    optInsOptions: getOptInsOptions(data, optIns),
    implementation: {
      check_opt_ins: false,
      check_opt_ins_operator: OPT_INS_OPERATOR_OPTIONS[0].value,
      message_sender_id: get(smsSenders, '[0].id', null),
      recipient_variable_id: get(smsRecipients, '[0].id', null),
      message: '',
    },
  });
};

export const submitForm = (values, dispatch, props) => {
  const transformedValues = transformFormData(values);

  return props.onSubmitForm(transformedValues);
};
