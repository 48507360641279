import React from 'react';

import bem from 'client/services/bem';
import {numberWithSpaces} from 'client/services/helpers';

import ArticlePanel from 'client/common/panels/article-panel';

import {Translation} from 'client/models/language/types';

import cssModule from './table-info.module.scss';

const b = bem('table-info', {cssModule});

type TableInfoProps = {
  className?: string;
  title: Translation;
  data: {
    device: number;
    online: number;
    total: number;
  }[];
  noDataText?: Translation;
  columns: {
    name: string;
    header: Translation;
    path: string;
  }[];
  rowNames: Translation[];
};

const TableInfo: React.FC<TableInfoProps> = (props) => {
  const {className, columns, rowNames, data, noDataText, title} = props;

  if (!data || !data.length) {
    return (
      <ArticlePanel className={className} title={title}>
        <div className={b()}>
          <div className={b('no-data')}>{noDataText}</div>
        </div>
      </ArticlePanel>
    );
  }

  const lastRowIndex = data.length - 1;
  const lastColumnIndex = columns.length - 1;

  return (
    <ArticlePanel className={className} classNames={{title: b('title')}} title={title}>
      <div className={b()}>
        <div className={b('col-headers')}>
          {columns.map((column, index) => (
            <div
              key={index}
              className={b('col-header', {
                bold: index === lastColumnIndex,
              })}
            >
              {column.header}
            </div>
          ))}
        </div>
        <div className={b('columns')}>
          <div className={b('row-names')}>
            {rowNames.map((rowName, index) => (
              <div
                key={index}
                className={b('row-name', {
                  highlighted: !index || index === lastRowIndex,
                  bold: !index || index === lastRowIndex,
                })}
              >
                {rowName}
              </div>
            ))}
          </div>
          <div className={b('rows')}>
            {data.map((row, rowIndex) => (
              <div key={rowIndex} className={b('row')}>
                {columns.map((column, colIndex) => (
                  <div
                    key={colIndex}
                    className={b('item', {
                      highlighted:
                        (!rowIndex && colIndex === lastColumnIndex) ||
                        (rowIndex === lastRowIndex && colIndex === lastColumnIndex),
                      bold: !rowIndex || rowIndex === lastRowIndex,
                    })}
                  >
                    {numberWithSpaces(row[column.path as keyof typeof row])}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </ArticlePanel>
  );
};

export default TableInfo;
