import {transformDate} from 'client/services/helpers';

export default (values, {lang, isClientNetwork}) => {
  const {name, code, from, to, client_user_id} = values;

  const errors = {};

  if (!name || !name.trim()) {
    errors.name = lang.NAME_IS_REQUIRED_ERROR;
  }
  if (!code) {
    errors.code = lang.CODE_IS_REQUIRED_ERROR;
  }

  const startDate = new Date(transformDate(from, false));
  if (!from) {
    errors.from = lang.START_DATE_IS_REQUIRED_ERROR;
  } else if (startDate.toString() === 'Invalid Date') {
    errors.from = lang.START_DATE_IS_INVALID_ERROR;
  }

  const endDate = new Date(transformDate(to, false));
  if (!to) {
    errors.to = lang.END_DATE_IS_REQUIRED_ERROR;
  } else if (endDate.toString() === 'Invalid Date') {
    errors.to = lang.END_DATE_IS_INVALID_ERROR;
  }

  if (endDate < startDate) {
    errors.from = lang.START_DATE_IS_AFTER_TO;
  }

  if (isClientNetwork && !client_user_id) {
    errors.client_user_id = lang.ATTRIBUTED_TO_IS_REQUIRED_ERROR;
  }

  return errors;
};
