import React from 'react';

import {useParams} from 'react-router-dom';

import WinningSearchContainer from 'client/components/winnings/containers/winning-search-container';

const WinningSearchPage = () => {
  const params: {taskId: string} = useParams();

  return <WinningSearchContainer taskId={+params.taskId} />;
};

export default WinningSearchPage;
