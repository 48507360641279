import React from 'react';

import PropTypes from 'prop-types';

import './test-step.scss';

const TestStep = ({children, title}) => {
  return (
    <div className="test-step content-group">
      <div className="test-step__inner">
        {title && <h3 className="test-step__title">{title}</h3>}
        {children}
      </div>
    </div>
  );
};

TestStep.defaultProps = {
  children: '',
  title: '',
};

TestStep.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
};

export default TestStep;
