const prizeHeaders = (lang) => [
  {
    title: '',
    key: 'order',
    fieldName: 'order',
  },
  {
    title: lang.NAME_COLUMN,
    key: 'name',
    fieldName: 'internal_name',
  },
  {
    title: lang.COUPON_COLUMN,
    key: 'coupon',
    fieldName: 'type',
  },
  {
    title: lang.CURRENT_STOCK_COLUMN,
    key: 'cs',
    fieldName: 'total_quantities.total_current_stock',
  },
  {
    title: lang.INITIAL_STOCK_COLUMN,
    key: 'is',
    fieldName: 'total_quantities.total_initial_stock',
  },
  {
    title: '',
    key: 'delete',
    fieldName: 'delete',
  },
];

const prizeMapHeaders = (lang) => [
  {
    title: '',
    key: 'order',
    fieldName: 'order',
  },
  {
    title: lang.NAME_COLUMN,
    key: 'name',
    fieldName: 'prize_internal_name',
  },
  {
    title: lang.COUPON_COLUMN,
    key: 'coupon',
    fieldName: 'prize_coupon_type',
  },
  {
    title: lang.CURRENT_STOCK_COLUMN,
    key: 'cs',
    fieldName: 'current_stock',
  },
  {
    title: lang.INITIAL_STOCK_COLUMN,
    key: 'is',
    fieldName: 'initial_stock',
  },
  {
    title: '',
    key: 'active',
    fieldName: 'active',
  },
];

export const headers = (lang, isPrizeMap) => (isPrizeMap ? prizeMapHeaders(lang) : prizeHeaders(lang));
