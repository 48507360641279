import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {set} from 'client/common/meta/meta.actions';

import ClientOperationsContainer from 'client/components/operations-client/components/containers/client-operations-container';

class ClientOperationsPage extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    setMetaAction: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.setMetaAction({
      title: this.props.lang.MY_OPERATIONS_TITLE,
    });
  }

  render() {
    const {clientId} = this.props.match.params;

    return <ClientOperationsContainer clientId={Number(clientId)} />;
  }
}

export default withRouter(
  connect(
    (state) => ({
      lang: state.languageState.payload.OPERATIONS,
    }),
    {setMetaAction: set},
  )(ClientOperationsPage),
);
