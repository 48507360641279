import React, {PureComponent} from 'react';

import {find} from 'lodash';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FieldArray, reduxForm, formValueSelector} from 'redux-form';

import bem from 'client/services/bem';
import {required} from 'client/services/validator';

import {getPlacesAction} from 'client/ducks/places/actions';
import {deleteBranchScenarioStepCondition} from 'client/ducks/scenario/actions';

import AppButton from 'client/common/buttons';
import {CheckboxField, RadioGroupField, SelectField, TextField} from 'client/common/fields';

import {TextAreaField} from 'client/components/common/fields';

import {VALUE_KIND, THEN_TYPE} from './constants';
import {getInitialValues, getScenarios, getScenarioVariables, createCondition, submitForm} from './helpers';

import {preventDefault, onSubmitSuccess, onSubmitFail} from '../helpers';

// re-use main form styles
// TODO: make own styles
import cssModule from './../../ats-step/ats-step.module.scss';

const b = bem('ats-step', {cssModule});
class AtsBranchForm extends PureComponent {
  static propTypes = {
    scenarioVariables: PropTypes.array.isRequired,
    scenarios: PropTypes.array.isRequired,
    hasNextScenario: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    deleteBranchScenarioStepCondition: PropTypes.func.isRequired,
    getPlacesAction: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    places: PropTypes.object.isRequired,
    automationTaskId: PropTypes.number.isRequired,
  };

  LANGUAGE = this.props.languageState.payload.AUTOTASK_SCENARIO.SCENARIO_FORMS;

  operatorOptions = [
    {value: 'all', label: this.LANGUAGE.BRANCH.ALL_OPTION},
    {value: 'any', label: this.LANGUAGE.BRANCH.ANY_OPTION},
  ];

  conditionOperatorOptions = [
    {label: this.LANGUAGE.BRANCH.EQUALS_OPTION, value: '=='},
    {label: this.LANGUAGE.BRANCH.GREATER_OPTION, value: '>'},
    {label: this.LANGUAGE.BRANCH.LESS_OPTION, value: '<'},
    {label: this.LANGUAGE.BRANCH.GREATER_EQUALS_OPTION, value: '>='},
    {label: this.LANGUAGE.BRANCH.LESS_EQUALS_OPTION, value: '<='},
    {label: this.LANGUAGE.BRANCH.DOES_NOT_EQUALS_OPTION, value: '!='},
    {label: this.LANGUAGE.BRANCH.IS_NULL_OPTION, value: 'is_null'},
    {label: this.LANGUAGE.BRANCH.IS_NOT_NULL_OPTION, value: 'is_not_null'},
    {label: this.LANGUAGE.BRANCH.IN_OPTION, value: 'in'},
    {label: this.LANGUAGE.BRANCH.INCLUDES_OPTION, value: 'includes'},
  ];

  conditionOperatorWithoutRightValue = ['is_null', 'is_not_null'];

  valueKindOptions = [
    {label: this.LANGUAGE.BRANCH.VALUE_OPTION, value: 'scalar'},
    {label: this.LANGUAGE.BRANCH.VARIABLE_OPTION, value: 'variable'},
  ];

  rules = {
    name: required(this.LANGUAGE.ERRORS.REQUIRED),
    value: required(this.LANGUAGE.ERRORS.REQUIRED),
  };

  componentDidMount() {
    if (!this.props.places.places.length && !this.props.places.isLoadingPlaces) {
      // places for exact scenario
      this.props.getPlacesAction({
        q: {
          interactions_interaction_group_automation_task_id_eq: this.props.automationTaskId,
        },
        distinct: true,
      });
    }
  }

  renderFieldLabel = (text) => <span className="main-text">{text}</span>;

  removeCondition = (fields, index) => {
    const {data} = this.props;
    const field = fields.get(index);

    if (field.id) {
      this.props.deleteBranchScenarioStepCondition(data.id, field.id).then(() => fields.remove(index));
    } else {
      fields.remove(index);
    }
  };

  formatScenarioVariables = (scenarioVariables) =>
    scenarioVariables.map((scenarioVariable) => ({
      value: scenarioVariable.id,
      label: scenarioVariable.full_name || scenarioVariable.name,
    }));

  formatPlaces = (places) =>
    places.map((place) => {
      return {
        value: place.id,
        label: place.name,
      };
    });

  renderCondition = (condition, fields, onRemove, scenarioVariables) => {
    let removeButton = null;

    if (onRemove) {
      removeButton = (
        <AppButton
          onClick={onRemove}
          iconName="trash"
          rounded={true}
          color="text-additional"
          className={b('form-item-delete')}
        />
      );
    }

    const variableOptions = this.formatScenarioVariables(scenarioVariables);
    const placesOptions = this.formatPlaces(this.props.places.places);
    let variableSource = {};

    if (fields && fields.variable_id) {
      const foundVariable = find(scenarioVariables, (obj) => obj.id === fields.variable_id);
      if (foundVariable && foundVariable.source) {
        variableSource = foundVariable.source;
      }
    }
    const isPlaceId = !!variableSource && variableSource.record_type === 'Place' && variableSource.name === 'id';

    return (
      <div className={b('form-item', ['view-4'])}>
        <SelectField
          className={b('form-field', ['size-1'])}
          simpleValue={true}
          name={`${condition}.variable_id`}
          searchable={true}
          options={variableOptions}
          validate={this.rules.name}
          required={true}
        />
        <SelectField
          className={b('form-field', ['size-1'])}
          simpleValue={true}
          name={`${condition}.operator`}
          searchable={false}
          options={this.conditionOperatorOptions}
          validate={this.rules.name}
        />
        {!this.conditionOperatorWithoutRightValue.includes(fields.operator) && (
          <>
            <SelectField
              className={b('form-field', ['size-1'])}
              simpleValue={true}
              name={`${condition}.value_kind`}
              searchable={false}
              options={this.valueKindOptions}
              validate={this.rules.name}
            />

            {fields.value_kind === VALUE_KIND.scalar &&
              (isPlaceId ? (
                <SelectField
                  className={b('form-field', ['size-2'])}
                  simpleValue={true}
                  name={`${condition}.value`}
                  searchable={true}
                  options={placesOptions}
                  validate={this.rules.name}
                />
              ) : (
                <TextField
                  className={b('form-field', ['size-2'])}
                  name={`${condition}.value`}
                  validate={this.rules.value}
                />
              ))}
            {fields.value_kind === VALUE_KIND.variable && (
              <SelectField
                className={b('form-field', ['size-1'])}
                simpleValue={true}
                name={`${condition}.value_variable_id`}
                searchable={false}
                options={variableOptions}
                validate={this.rules.name}
              />
            )}
            {removeButton}
          </>
        )}
      </div>
    );
  };

  renderConditionsArray = ({fields, scenarioVariables}) => {
    const onRemove = fields.length > 1 ? (index) => () => this.removeCondition(fields, index) : () => null;

    const renderedFields = fields.map((field, index) => {
      return (
        <div key={index} className={b('form-item', ['view-1'])}>
          {this.renderCondition(field, fields.get(index), onRemove(index), scenarioVariables)}
        </div>
      );
    });

    return (
      <div>
        {renderedFields}
        <AppButton
          iconName="plus"
          label={this.LANGUAGE.BRANCH.ADD_CONDITION_BUTTON}
          onClick={() => fields.push(createCondition(scenarioVariables))}
          color="text-additional"
          fontSize="small"
          className={b('form-add-btn', ['view-1'])}
        />
      </div>
    );
  };

  formatScenarios = (scenarios) =>
    scenarios.map((scenario) => ({
      value: scenario.id,
      label: scenario.name,
    }));

  render() {
    const {scenarios, scenarioVariables, hasNextScenario} = this.props;

    const scenarioOptions = this.formatScenarios(scenarios);

    return (
      <form onSubmit={preventDefault} className={b('form')}>
        <TextField label={this.LANGUAGE.STEP_NAME_LABEL} name="name" validate={this.rules.name} required={true} />
        <SelectField
          label={this.LANGUAGE.BRANCH.SATISFIES_LABEL}
          simpleValue={true}
          name="implementation.operator"
          searchable={false}
          options={this.operatorOptions}
          validate={this.rules.name}
        />
        <FieldArray
          name="implementation.branch_scenario_step_conditions"
          component={this.renderConditionsArray}
          props={{
            scenarioVariables,
          }}
          rerenderOnEveryChange
        />
        <RadioGroupField
          color="default"
          legend={this.LANGUAGE.BRANCH.THEN_LABEL}
          name="thenType"
          options={[
            {
              value: THEN_TYPE.goto,
              label: this.LANGUAGE.BRANCH.GO_TO_LABEL,
            },
            {value: THEN_TYPE.end, label: this.LANGUAGE.BRANCH.STOP_SCENARIO_LABEL},
          ]}
          validate={this.rules.name}
        />
        {hasNextScenario && (
          <div className={b('form-field', ['size-1', 'column'])}>
            <SelectField
              simpleValue={true}
              name="implementation.scenario_id"
              searchable={false}
              options={scenarioOptions}
              validate={this.rules.name}
            />
            <CheckboxField color="default" name="thenGoToContinue" label={this.LANGUAGE.BRANCH.CONTINUE_LABEL} />
          </div>
        )}
        <div className={b('form-title')}>{this.LANGUAGE.BRANCH.CONTINUE_CURRENT_LABEL}</div>
        <TextAreaField label={this.LANGUAGE.COMMENT_LABEL} name="comment" />
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {formId, data} = props;

  const selector = formValueSelector(formId);

  return {
    form: formId,
    initialValues: getInitialValues(data, state),
    scenarioVariables: getScenarioVariables(state),
    scenarios: getScenarios(state),
    hasNextScenario: selector(state, 'thenType') !== THEN_TYPE.end,
    languageState: state.languageState,
    places: state.places,
    automationTaskId: state.scenario.payload.automation_task ? state.scenario.payload.automation_task.id : 0,
  };
};

const mapDispatchToProps = {
  deleteBranchScenarioStepCondition,
  getPlacesAction,
};

export default flow([
  reduxForm({
    enableReinitialize: true,
    onSubmit: submitForm,
    onSubmitSuccess,
    onSubmitFail,
  }),
  connect(mapStateToProps, mapDispatchToProps),
])(AtsBranchForm);
