export default {
  GET_EMAIL_SENDERS_REQUEST: '@@email-senders/GET_EMAIL_SENDERS_REQUEST',
  GET_EMAIL_SENDERS_SUCCESS: '@@email-senders/GET_EMAIL_SENDERS_SUCCESS',
  GET_EMAIL_SENDERS_ERROR: '@@email-senders/GET_EMAIL_SENDERS_ERROR',

  ADD_EMAIL_SENDER_REQUEST: '@@email-senders/ADD_EMAIL_SENDER_REQUEST',
  ADD_EMAIL_SENDER_SUCCESS: '@@email-senders/ADD_EMAIL_SENDER_SUCCESS',
  ADD_EMAIL_SENDER_ERROR: '@@email-senders/ADD_EMAIL_SENDER_ERROR',

  UPDATE_EMAIL_SENDER_REQUEST: '@@email-senders/UPDATE_EMAIL_SENDER_REQUEST',
  UPDATE_EMAIL_SENDER_SUCCESS: '@@email-senders/UPDATE_EMAIL_SENDER_SUCCESS',
  UPDATE_EMAIL_SENDER_ERROR: '@@email-senders/UPDATE_EMAIL_SENDER_ERROR',

  REMOVE_EMAIL_SENDER_REQUEST: '@@email-senders/REMOVE_EMAIL_SENDER_REQUEST',
  REMOVE_EMAIL_SENDER_SUCCESS: '@@email-senders/REMOVE_EMAIL_SENDER_SUCCESS',
  REMOVE_EMAIL_SENDER_ERROR: '@@email-senders/REMOVE_EMAIL_SENDER_ERROR',
};
