import React, {Component} from 'react';

import find from 'lodash/find';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import VariableValue from 'client/components/common/variable-value';

import './step-container.scss';

class StepContainer extends Component {
  static propTypes = {
    variables: PropTypes.array,
    values: PropTypes.object,
    children: PropTypes.any,
    languageState: PropTypes.object.isRequired,
    isLottery: PropTypes.bool.isRequired,
    passedScrollbarProps: PropTypes.object,
  };

  static defaultProps = {
    variables: [],
    values: {},
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.RESULT_MODAL;
  }

  renderVariableName = (name) => {
    const isLong = name.length > 43;
    const text = isLong ? name.substring(0, 40) + '…' : name;
    const title = isLong ? name : null;

    return (
      <span className="fake-input__label var-name" title={title}>
        {text.replace('.', '. ')}:
      </span>
    );
  };

  render() {
    const {LANGUAGE} = this;
    const {variables, values, children, isLottery, passedScrollbarProps} = this.props;

    const prizeLevelVar = find(variables, {name: 'PrizeLevel'});
    const isWon = prizeLevelVar && values[prizeLevelVar.id] > 0;

    return (
      <CustomScrollbars
        scrollbarProps={{
          ...passedScrollbarProps,
          autoHeightMax: 600,
        }}
        cssModifier="custom-scrollbars--hide-hor-scroll"
      >
        <div className="rt-result-steps__container interface-variables">
          {' '}
          {/* change class? */}
          <div className="title-text">{LANGUAGE.STEP_SETTINGS_TITLE}</div>
          {children}
          {variables.length > 0 && <div className="title-text">{LANGUAGE.OUTPUT_VARIABLES_TITLE}</div>}
          {isLottery && (
            <div className="form-field-wrap">
              <div className="fake-input fake-input--disabled">
                <div className="fake-input__label">{isWon ? LANGUAGE.WIN.WON_LABEL : LANGUAGE.WIN.NOT_WON_LABEL}</div>
              </div>
            </div>
          )}
          {variables.map((variable, key) => {
            const name = variable.full_name || variable.name;
            return (
              <div key={key} className="form-field-wrap">
                <div className="fake-input fake-input--disabled var-container">
                  {this.renderVariableName(name)}
                  <VariableValue
                    variable={variable}
                    value={
                      isNull(values[variable.id]) || isUndefined(isNull(values[variable.id]))
                        ? 'N/A'
                        : values[variable.id]
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      </CustomScrollbars>
    );
  }
}
export default connect(({languageState}) => ({languageState}))(StepContainer);
