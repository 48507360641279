export const mapFiltersForApi = (filters = {}) => {
  const result = {};

  if (filters.fileType && filters.fileType.length) {
    result.kind_in = filters.fileType;
  }

  if (filters.fileFormat && filters.fileFormat.length) {
    result.file_extension_in = filters.fileFormat;
    if (filters.fileFormat.includes('mpeg')) {
      result.file_extension_in.push('mp4');
    }
  }

  if (filters.orientation && filters.orientation.length) {
    result.orientation_in = filters.orientation;
  }

  if (filters.origin) {
    switch (filters.origin) {
      case 'device':
        result.interaction_type_eq = 'OfflineInteraction';
        break;
      case 'online':
        result.interaction_type_eq = 'OnlineInteraction';
        break;
      case 'direct':
        result.interaction_id_null = true;
        break;
    }
  }

  if (filters.lead) {
    result.participation_lead_id_null = filters.lead === 'no_lead';
  }

  if (filters.comment) {
    result.comment_present = filters.comment === 'with';
  }

  return result;
};
