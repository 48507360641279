import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';

import {required, maxLength, convertValue} from 'client/services/validator';

import {validateArray} from '../helpers';

export default (values, props) => {
  const {name, images, implementation: {social_account_id, message} = {}} = values;

  const {messageLengthLimit, languageState} = props;

  const errors = {};

  const ERRORS = languageState.payload.AUTOTASK_SCENARIO.SCENARIO_FORMS.ERRORS;

  const isImagesEmpty = ['files', 'newFiles', 'variables', 'newVariables'].every((key) => !images?.[key]?.length);

  const messageValidation = [
    (value = '') => (isEmpty(convertValue(value)) && isImagesEmpty ? ERRORS.REQUIRED_MESSAGE_OR_IMAGES : null),
    maxLength(messageLengthLimit, `${ERRORS.MAXIMUM_LENGTH} ${messageLengthLimit} ${ERRORS.SYMBOLS}`),
  ];

  const selectValidation = required(ERRORS.REQUIRED);

  set(errors, 'name', required(ERRORS.REQUIRED)(name));
  set(errors, 'implementation.social_account_id', selectValidation(social_account_id));
  set(errors, 'implementation.message', validateArray(messageValidation, message));

  return errors;
};
