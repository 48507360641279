import React, {useMemo} from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectOperation} from 'client/ducks/operations/selectors';
import {selectCurrentAccessLevel} from 'client/ducks/user-clients/selectors';

import Modal from 'client/common/modals/modal';
import WebPreview from 'client/common/web-preview';

import Spinner from 'client/components/common/spinner';

import {getCatalogsInfoByType} from 'client/components/diy-operation/modals/diy-customization-modal/helpers/get-catalogs-info-by-type';
import useCustomizationPreviewLanguagesDevices from 'client/components/diy-operation/modals/diy-customization-modal/useCustomizationPreviewLanguagesDevices';
import useFetchCustomizationForm from 'client/components/diy-operation/modals/diy-customization-modal/useFetchCustomizationForm';
import {useTemplateParamsByAutotask} from 'client/components/diy-operation/modals/diy-customization-modal/useTemplateParamsByAutotask';
import {CATALOG_TYPES} from 'client/models/templates/constants';

import cssModule from './diy-op-publishing-preview-modal.module.scss';

const b = bem('diy-op-publishing-preview-modal', {cssModule});

type DiyOpPublishingPreviewModalProps = {
  onClose: () => void;
};

const DiyOpPublishingPreviewModal: React.FC<DiyOpPublishingPreviewModalProps> = (props) => {
  const {onClose} = props;
  const lang = useLanguage('DIY_OPERATION.MODALS.PUBLISHING_PREVIEW_MODAL');
  const {client_interface} = useSelector(selectOperation);
  const {client_id} = useSelector(selectOperation);

  const accessLevel = useSelector(selectCurrentAccessLevel);
  const catalogsInfo = useMemo(
    () => client_interface && getCatalogsInfoByType(client_interface, accessLevel),
    [client_interface, accessLevel],
  );

  const templateCatalogInfo = catalogsInfo?.[CATALOG_TYPES.TEMPLATE];
  const formId = templateCatalogInfo?.forms?.general?.id;
  const params = useTemplateParamsByAutotask({formId});

  const {result, page, loading} = useFetchCustomizationForm({
    formId,
    clientId: client_id,
    ...params,
  });
  const {languages, devices, defaultLanguage, defaultDevice} = useCustomizationPreviewLanguagesDevices({});

  return (
    <Modal
      onClose={onClose}
      title={lang.TITLE}
      className={b()}
      classNames={{
        body: b('body'),
        content: b('content'),
      }}
    >
      {loading ? (
        <Spinner centered />
      ) : (
        page?.form_preview && (
          <WebPreview
            className={b('preview')}
            languages={languages}
            devices={devices}
            defaultLanguage={defaultLanguage}
            defaultDevice={defaultDevice}
            preview={page.form_preview}
            data={result}
            pageName={page?.name}
          />
        )
      )}
    </Modal>
  );
};

export default DiyOpPublishingPreviewModal;
