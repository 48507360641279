import {ClientTemplateType} from 'client/components/catalog-templates/types';
import {CATALOG_TEMPLATE_TABS, CATALOG_TYPE_MAP} from 'client/models/templates/constants';
import {ClientTemplate} from 'client/models/templates/types';

export const getTemplateType = (template: ClientTemplate): ClientTemplateType => {
  return template.catalog
    ? (CATALOG_TYPE_MAP[template.catalog.type as keyof typeof CATALOG_TYPE_MAP] as ClientTemplateType)
    : 'general';
};

export const getTemplateCategory = (template: ClientTemplate, access: string) => {
  if (template.creator_team_id === null) {
    return CATALOG_TEMPLATE_TABS.WEEZIO;
  }

  if (template.creator_team?.access === access) {
    return CATALOG_TEMPLATE_TABS.MY_TEMPLATES;
  }

  return CATALOG_TEMPLATE_TABS.SHARED;
};
