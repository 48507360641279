import React, {useMemo, useRef} from 'react';

import cn from 'classnames';
import {cloneDeep} from 'lodash';
import moment from 'moment';
import {IMask, IMaskInput} from 'react-imask';

import bem from 'client/services/bem';

import {ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';

import {Translation} from 'client/models/language/types';

import {serialize, deserialize} from './helpers';

import cssModule from './datetime-input.module.scss';

const b = bem('datetime-input', {cssModule});

export const TIME_PATTERNS = {
  date: 'DD/MM/YYYY',
  time: 'HH:mm:ss',
  'time-short': 'HH:mm',
  'date-time': 'DD/MM/YYYY HH:mm:ss',
};

export const IMASK_TIME_PATTERNS = {
  date: 'DD`/MM`/YYYY`',
  time: 'HH:mm:ss',
  'time-short': 'HH:mm',
  'date-time': 'DD`/MM`/YYYY` HH:mm:ss',
};

export type DateTimeInputTypeOptionType = (typeof DateTimeInputTypeOptions)[number];
type TimeStampType = 'from' | 'to';

type DateTimeInputProps = {
  value: string;
  onChange: (value: string) => void;
  type: DateTimeInputTypeOptionType;
  period?: boolean;
  label?: string;
  onBlur?: () => void;
  errorMessage?: string | string[];
  warningMessage?: string;
  className?: string;
  disabled?: boolean;
  maskChars?: {
    second?: string;
    minute?: string;
    hour?: string;
    day?: string;
    month?: string;
    year?: string;
  };
  placeholder?:
    | Translation
    | {
        from: string;
        to: string;
      };
  classNames?: {
    field?: string;
  };
  required?: boolean;
};

const DateTimeInput: React.FC<DateTimeInputProps> = (props) => {
  const {
    type = 'date',
    period = false,
    label,
    value: initialValue,
    onChange,
    onBlur,
    errorMessage,
    warningMessage = '',
    className,
    classNames,
    disabled,
    maskChars,
    placeholder,
    required = false,
    ...inputProps
  } = props;

  const deserializedValue = useMemo<{from: string; to?: string}>(
    () => deserialize(initialValue, period, type),
    [initialValue, period, type],
  );
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleChange = (value: string, timeStamp: 'from' | 'to') => {
    const updatedValues = cloneDeep(deserializedValue);
    updatedValues[timeStamp] = value;
    onChange(serialize(updatedValues));
  };

  const handleBlur = () => {
    const wrapperNode = wrapperRef.current;

    // https://muffinman.io/blog/catching-the-blur-event-on-an-element-and-its-children/
    // Give browser time to focus the next element
    requestAnimationFrame(() => {
      // Check if the new focused element is a child of the original container
      if (wrapperNode && !wrapperNode.contains(document.activeElement)) {
        onBlur?.();
      }
    });
  };

  const timePattern = TIME_PATTERNS[type];

  const maskOptions = useMemo(() => {
    return {
      mask: Date,
      autofix: false,
      parse: (str: string) => moment(str, timePattern).toDate(),
      format: (date: Date | null) => moment(date).format(timePattern),
      blocks: {
        DD: {mask: IMask.MaskedRange, from: 1, to: 31, placeholderChar: maskChars?.day || 'd'},
        MM: {mask: IMask.MaskedRange, from: 1, to: 12, placeholderChar: maskChars?.month || 'm'},
        YYYY: {mask: IMask.MaskedRange, from: 2020, to: 3020, placeholderChar: maskChars?.year || 'y'},
        HH: {mask: IMask.MaskedRange, from: 0, to: 23, placeholderChar: maskChars?.hour || 'h'},
        mm: {mask: IMask.MaskedRange, from: 0, to: 59, placeholderChar: maskChars?.minute || 'm'},
        ss: {mask: IMask.MaskedRange, from: 0, to: 59, placeholderChar: maskChars?.second || 's'},
      },
    };
  }, [maskChars, timePattern]);

  return (
    <div className={cn(b({error: !!errorMessage, warning: !!warningMessage}), className)}>
      {label && <label className={b('label')}>{label}</label>}
      <div ref={wrapperRef} className={cn(b('field', {disabled}), classNames?.field)}>
        {Object.keys(deserializedValue).map((timeStamp) => (
          <IMaskInput
            key={timeStamp}
            className={b('input')}
            value={deserializedValue[timeStamp as TimeStampType]}
            disabled={disabled}
            pattern={IMASK_TIME_PATTERNS[type]}
            onAccept={(value) => handleChange(value as string, timeStamp as TimeStampType)}
            onBlur={handleBlur}
            placeholder={placeholder?.[timeStamp as keyof typeof placeholder] || placeholder?.toString()}
            lazy={Boolean(placeholder && !deserializedValue[timeStamp as TimeStampType])}
            {...maskOptions}
            {...inputProps}
          />
        ))}
      </div>
      {!errorMessage && required && <RequiredLabel />}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {warningMessage && !errorMessage && <WarningMessage warningMessage={warningMessage} />}
    </div>
  );
};

export const DateTimeInputTypeOptions = ['date', 'time', 'time-short', 'date-time'] as const;

export default DateTimeInput;
