import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Offscreen from 'client/common/offscreen';

import './v-tabs.scss';

function VTabsPane({className, children, eventKey, activeKey, ...rest}) {
  return (
    <Offscreen hidden={eventKey !== activeKey}>
      <div className={`v-tabs__pane ${className}`} {...rest}>
        <div className="v-tabs__pane-inner">{children}</div>
      </div>
    </Offscreen>
  );
}

VTabsPane.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  eventKey: PropTypes.string,
};

VTabsPane.defaultProps = {
  className: '',
  children: null,
  eventKey: '',
};

function VTabsCol({className, children, width, noStyle, style}) {
  const cssClass = classNames({
    'v-tabs__col': true,
    'v-tabs__col--no-style': noStyle,
    [`v-tabs__col--width_${width}`]: true,
  });

  return (
    <div className={`${cssClass} ${className}`} style={style}>
      <div className="v-tabs__col-inner">{children}</div>
    </div>
  );
}

VTabsCol.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  width: PropTypes.string,
  noStyle: PropTypes.bool,
  style: PropTypes.object,
};

VTabsCol.defaultProps = {
  className: '',
  children: null,
  width: 'equal',
  noStyle: false,
  style: {},
};

function VTabs({className, navbar, children, ...rest}) {
  return (
    <div className={`v-tabs ${className}`} {...rest}>
      <div className="v-tabs__navbar">{navbar}</div>
      <div className="v-tabs__content">
        <div className="tab-content">{children}</div>
      </div>
    </div>
  );
}

VTabs.propTypes = {
  className: PropTypes.string,
  navbar: PropTypes.any,
  children: PropTypes.any,
};

VTabs.defaultProps = {
  className: '',
  navbar: null,
  children: null,
};

export {VTabsPane};
export {VTabsCol};
export default VTabs;
