import {isEmptyString} from 'client/services/validator';

const validateTranslationFieldset = (formValues, lang) => {
  const errors = {
    items: {},
  };

  if (isEmptyString(formValues.key)) {
    errors.key = lang.KEY_REQUIRED;
  }

  if (formValues.withTranslations && formValues.items) {
    Object.entries(formValues.items).forEach(([language, {value}]) => {
      if (isEmptyString(value)) {
        errors.items[language] = {value: lang.TRANSLATION_REQUIRED};
      }
    });
  }

  return errors;
};

export default (formValues, {langErrors}) => {
  const errors = validateTranslationFieldset(formValues, langErrors);

  return {
    ...errors,
  };
};
