import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {selectLanguageDomain} from 'client/ducks/language/selectors';

import {set} from 'client/common/meta/meta.actions';

import ParticipantsConfig from 'client/components/participants/participants-config';

class ParticipantsConfigPage extends Component {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.setMetaAction({
      title: this.props.languageState.TITLE,
    });
  }

  render() {
    return <ParticipantsConfig />;
  }
}

export default connect(
  (state) => ({
    languageState: selectLanguageDomain(state, 'PARTICIPANTS_CONFIG'),
  }),
  {setMetaAction: set},
)(ParticipantsConfigPage);
