import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {useLanguage} from 'client/services/hooks';

import {set} from 'client/common/meta/meta.actions';

import CatalogModules from 'client/components/catalog-modules';

const CatalogModulesPage = () => {
  const lang = useLanguage('CATALOG_MODULES');
  const dispatch = useDispatch();
  const params: {clientId: string} = useParams();

  useEffect(() => {
    dispatch(set({title: lang.TITLE}));
  }, [dispatch, lang.TITLE]);

  return <CatalogModules clientId={+params.clientId} />;
};

export default CatalogModulesPage;
