import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function createOnlineInteraction(body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.ONLINE_INTERACTIONS}`,
      method: 'POST',
      types: [
        types.CREATE_ONLINE_INTERACTION_REQUEST,
        types.CREATE_ONLINE_INTERACTION_SUCCESS,
        types.CREATE_ONLINE_INTERACTION_ERROR,
      ],
      body,
    });
}

export function updateOnlineInteraction(id, body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.ONLINE_INTERACTIONS}/${id}`,
      method: 'PATCH',
      types: [
        types.UPDATE_ONLINE_INTERACTION_REQUEST,
        types.UPDATE_ONLINE_INTERACTION_SUCCESS,
        types.UPDATE_ONLINE_INTERACTION_ERROR,
      ],
      body,
    });
}

export function updateOfflineInteraction(id, body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OFFLINE_INTERACTIONS}/${id}`,
      method: 'PATCH',
      types: [
        types.UPDATE_OFFLINE_INTERACTION_REQUEST,
        types.UPDATE_OFFLINE_INTERACTION_SUCCESS,
        types.UPDATE_OFFLINE_INTERACTION_ERROR,
      ],
      body,
    });
}

export function createOfflineInteraction(id, body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OFFLINE_INTERACTIONS}/${id}`,
      method: 'POST',
      types: [
        types.CREATE_OFFLINE_INTERACTION_REQUEST,
        types.CREATE_OFFLINE_INTERACTION_SUCCESS,
        types.CREATE_OFFLINE_INTERACTION_ERROR,
      ],
      body,
    });
}

export function fetchOnlineInteractions(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.ONLINE_INTERACTIONS}`,
      method: 'GET',
      types: [
        types.FETCH_ONLINE_INTERACTIONS_REQUEST,
        types.FETCH_ONLINE_INTERACTIONS_SUCCESS,
        types.FETCH_ONLINE_INTERACTIONS_ERROR,
      ],
      headers: {
        'cache-control': 'no-cache',
      },
      queryParams,
    });
}

export function fetchOfflineInteractions(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OFFLINE_INTERACTIONS}`,
      method: 'GET',
      types: [
        types.FETCH_OFFLINE_INTERACTIONS_REQUEST,
        types.FETCH_OFFLINE_INTERACTIONS_SUCCESS,
        types.FETCH_OFFLINE_INTERACTIONS_ERROR,
      ],
      headers: {
        'cache-control': 'no-cache',
      },
      queryParams,
    });
}

export function fetchPlacesWithDevices(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PLACES_WITH_DEVICES}`,
      method: 'GET',
      types: [
        types.FETCH_PLACES_WITH_DEVICES_REQUEST,
        types.FETCH_PLACES_WITH_DEVICES_SUCCESS,
        types.FETCH_PLACES_WITH_DEVICES_ERROR,
      ],
      headers: {
        'cache-control': 'no-cache',
      },
      queryParams,
    });
}

export function fetchSpreadPlanInteractions(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OFFLINE_INTERACTIONS}`,
      method: 'GET',
      types: [
        types.FETCH_SPREAD_PLAN_INTERACTIONS_REQUEST,
        types.FETCH_SPREAD_PLAN_INTERACTIONS_SUCCESS,
        types.FETCH_SPREAD_PLAN_INTERACTIONS_ERROR,
      ],
      queryParams,
    });
}

export function deleteOnlineInteraction(id) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.ONLINE_INTERACTIONS}/${id}`,
      method: 'DELETE',
      types: [
        types.DELETE_ONLINE_INTERACTION_REQUEST,
        types.DELETE_ONLINE_INTERACTION_SUCCESS,
        types.DELETE_ONLINE_INTERACTION_ERROR,
      ],
    });
}
