import React from 'react';

import cn from 'classnames';
import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';

import {selectAvailableDomains} from 'client/ducks/available-domains/selectors';
import {selectClientDomains} from 'client/ducks/client-domains/selectors';

import ArticlePanel from 'client/common/panels/article-panel';

import DiyOpStatusBlock from 'client/components/diy-operation/blocks/diy-op-status-block';
import DiyOpValidationBlock from 'client/components/diy-operation/blocks/diy-op-validation-block';
import {DIY_OPERATION_TYPES_WITH_URL, DIY_OPERATION_STATES} from 'client/components/diy-operation/constants';
import DiyOpPublishingUrlModal from 'client/components/diy-operation/modals/diy-op-publishing-url-modal';
import {getUrlData} from 'client/components/diy-operation/modals/diy-op-publishing-url-modal/helper';
import DiyOpPublishingStatus from 'client/components/diy-operation/statuses/diy-op-publishing-status';
import {Autotask} from 'client/models/autotask/types';
import {Translation} from 'client/models/language/types';
import {Operation} from 'client/models/operations/types';

import cssModule from './diy-op-publishing-card.module.scss';

const b = bem('diy-op-publishing-card', {cssModule});

type DiyOpPublishingCardProps = {
  isAutoConfiguration?: boolean;
  fetchOperation: () => void;
  fetchAutotask: () => void;
  operation: Operation;
  autotask: Autotask;
  className?: string;
  title: Translation;
  type: string;
};

const DiyOpPublishingCard: React.FC<DiyOpPublishingCardProps> = (props) => {
  const {isAutoConfiguration, fetchOperation, fetchAutotask, operation, className, autotask, type, title} = props;

  const interfaceId = operation?.client_interface?.id;
  const webAppState = operation?.client_interface?.web_app?.state || '';
  const deviceState = operation?.client_interface?.device_state || '';

  const [isPublishingUrlModalVisible, togglePublishingUrlModalVisibility] = useToggle(false);

  const availableDomains = useSelector(selectAvailableDomains);
  const clientDomains = useSelector(selectClientDomains);

  const clientDomainInfo = getUrlData(clientDomains, availableDomains, interfaceId);

  const hasOperationUrl = Object.values(DIY_OPERATION_TYPES_WITH_URL).includes(type);
  const publishedAt = hasOperationUrl
    ? operation?.client_interface?.web_app_synchronized_at
    : operation?.client_interface?.device_synchronized_at;

  const isActive = (state: string) =>
    [DIY_OPERATION_STATES.PUBLISHED, DIY_OPERATION_STATES.REPUBLISHED].includes(state);
  const isPublished = isActive(hasOperationUrl ? webAppState : deviceState);

  const articleTitle = (
    <p className={b('title')}>
      <span>{title}</span>
      <DiyOpPublishingStatus publishedAt={publishedAt} isPublished={isPublished} />
    </p>
  );

  return (
    <>
      <ArticlePanel title={articleTitle} className={cn(b(), className)}>
        {isAutoConfiguration ? (
          <DiyOpValidationBlock
            autotask={autotask}
            fetchAutotask={fetchAutotask}
            fetchOperation={fetchOperation}
            url={clientDomainInfo?.fullUrl}
            handleOpenModal={togglePublishingUrlModalVisibility}
            type={type}
          />
        ) : (
          <DiyOpStatusBlock
            operation={operation}
            fetchAutotask={fetchAutotask}
            fetchOperation={fetchOperation}
            url={clientDomainInfo?.fullUrl}
            handleOpenModal={togglePublishingUrlModalVisibility}
            type={type}
          />
        )}
      </ArticlePanel>

      {/* Modals */}
      {isPublishingUrlModalVisible && (
        <DiyOpPublishingUrlModal
          operation={operation}
          onClose={togglePublishingUrlModalVisibility}
          fetchOperation={fetchOperation}
        />
      )}
    </>
  );
};

export default DiyOpPublishingCard;
