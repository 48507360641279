import React from 'react';

import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import BaseFieldComponent from 'client/common/fields/base-field';
import {CheckList} from 'client/common/inputs';

const Component = (props) => {
  return <BaseFieldComponent Component={CheckList} {...props} checked={props.input.value} />;
};

Component.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
  }),
};

const CheckListField = (props) => {
  return <Field component={Component} {...props} />;
};

export default CheckListField;
