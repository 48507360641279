import {AFFECTATION_ALERT_VALUES} from 'client/models/device-affectations';

import {ClientDeviceFiltersFormValues} from './client-devices-list-filters-form';

export const getFilters = (filters: ClientDeviceFiltersFormValues) => {
  const params: Record<string, any> = {};
  const statuses: string[] = [];

  if (filters.region?.length) {
    params.place_region_id_in = filters.region;
  }

  if (filters.place?.length) {
    params.place_id_in = filters.place;
  }

  if (filters.task?.length) {
    params.device_current_interaction_automation_task_id_in = filters.task;
  }

  if (filters.status?.length) {
    filters.status.map((status) => {
      statuses.push(status);
    });

    params.device_status_activity_in = statuses;
  }

  if (filters.activity) {
    const activity = filters.activity.filter((a) => a === 'active' || a === 'inactive');

    if (filters.activity.includes('paper')) {
      params.alert_paper_in = [AFFECTATION_ALERT_VALUES.ERROR, AFFECTATION_ALERT_VALUES.WARNING];
    }
    if (filters.activity.includes('token')) {
      params.alert_token_in = [AFFECTATION_ALERT_VALUES.ERROR, AFFECTATION_ALERT_VALUES.WARNING];
    }
    if (activity.length) {
      params.alert_inactivity_in = activity;
    }
  }

  return params;
};
