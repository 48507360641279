export const tunnelColors = [
  '#98EA91',
  '#FE9191',
  '#8589F1',
  '#F4A0FB',
  '#85EEEE',
  '#8ED2F8',
  '#F6D976',
  '#E12B2B',
  '#1EAFAF',
  '#B958C9',
  '#0074D9',
  '#2ECC40',
  '#3D9970',
  '#FF851B',
  '#85144b',
  '#FF6F61',
  '#6B5B95',
  '#88B04B',
  '#F7CAC9',
  '#92A8D1',
];
