import React, {Component} from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, formValueSelector, getFormValues, reduxForm} from 'redux-form';

import {date, dateIsAfter, required} from 'client/services/validator';

import {PARTICIPANTS_COLUMN_MAP} from 'client/ducks/participants-list/constants';
import {apiParticipationsExport} from 'client/ducks/participation-export/actions';

import {LEAD_IMPORT_EXPORT_FILE_TYPES, PARTICIPATION_EXPORT_TYPES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import AllowedUsersField from 'client/components/common/custom-fields/allowed-users-field';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';
import Rollout from 'client/components/common/rollout';

import {makeCopyGeneralParticipationColumns} from './helpers';

const sortColumns = (data) => {
  if (Object.keys(data).length) {
    const columns = {
      ...data,
      Participation: sortBy(data.Participation, [
        function (item) {
          return item.name.toLowerCase();
        },
      ]),
      ScenarioExecution: sortBy(data.ScenarioExecution, [
        function (item) {
          return item.name.toLowerCase();
        },
      ]),
    };
    return columns;
  }
  return data;
};

class ParticipantsGenerateApiExportModal extends Component {
  static defaultProps = {
    show: false,
    columnsGroup: {},
    columns: [],
    users: [],
    getClientUsersAction: () => null,
    formValues: {},
    isAll: true,
    queryParams: {},
    checkedParticipants: [],
  };

  static propTypes = {
    show: PropTypes.bool,
    lang: PropTypes.object.isRequired,
    columnsGroup: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    getClientUsersAction: PropTypes.func,
    apiParticipationsExport: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    clientId: PropTypes.number.isRequired,
    users: PropTypes.array,
    generalParticipationColumns: PropTypes.object.isRequired,
    taskId: PropTypes.number.isRequired,
    updateCallBack: PropTypes.func,
    formValues: PropTypes.object,
    change: PropTypes.func.isRequired,
    isAll: PropTypes.bool,
    queryParams: PropTypes.object,
    checkedParticipants: PropTypes.array,
  };

  static formName = 'LeadsGenerateApiExportModalForm';

  static checkboxAllColumns = 'checkboxAllColumns';

  static columnsGroup = 'columnsGroup';

  rules = {
    dateFrom: [
      date(this.props.lang.ERRORS.INVALID_DATE_FORMAT),
      required(this.props.lang.REQUIRED),
      (value) => {
        return moment.utc(value, 'DD-MM-YYYY').isSameOrAfter(moment.utc(), 'day')
          ? null
          : this.props.lang.ERRORS.INVALID_DATE_START;
      },
    ],
    dateTo: [
      date(this.props.lang.ERRORS.INVALID_DATE_FORMAT),
      required(this.props.lang.REQUIRED),
      (value, {dateFrom}) => {
        return value && dateFrom && !date('.')(dateFrom)
          ? dateIsAfter(this.props.lang.ERRORS.INVALID_DATE_BORDERS, 'dateFrom')(value, {dateFrom})
          : null;
      },
    ],
  };

  constructor(props) {
    super(props);

    const initValues = {
      columnsGroup: {},
      format: LEAD_IMPORT_EXPORT_FILE_TYPES.XLS,
      users: [],
      allowed_for_all: 'true',
    };
    this.props.initialize(initValues);

    this.state = {
      showWarning: true,
      isColumnsFieldsTouched: false,
      generalParticipationColumns: {},
      allColumnsChecked: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.generalParticipationColumns !== prevState.generalParticipationColumns) {
      if (typeof nextProps.generalParticipationColumns === 'object' && !prevState.isColumnsFieldsTouched) {
        return {
          generalParticipationColumns: sortColumns(nextProps.generalParticipationColumns),
        };
      }
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.columnsGroup !== prevProps.columnsGroup) {
      this.validateFormSection();
    }

    const {formValues} = this.props;
    if (formValues.allowed_for_all === 'true' && !isEmpty(formValues.users)) {
      this.props.change('users', []);
    }
  }

  detectChange = (obj1, obj2) => {
    return !isEqual(obj1, obj2);
  };

  validateFormSection = () => {
    const validate = this.getCheckedColumns().length;
    this.setState({showWarning: !validate, isColumnsFieldsTouched: Boolean(validate)});
  };

  getCheckedColumns = () => {
    const filtered = [];
    filtered.push(...Object.values(this.state.generalParticipationColumns));

    const filteredCheck = filtered.reduce((result, itemFamily) => {
      const checkedItems = itemFamily.filter((column) => {
        return typeof column === 'object' && column.checked;
      });

      return [...result, ...checkedItems];
    }, []);

    return filteredCheck.length ? filteredCheck.flat() : [];
  };

  onSave = (values) => {
    if (this.state.showWarning) {
      return this.setState({isColumnsFieldsTouched: true});
    }

    const transferItems = [...this.getCheckedColumns()].map((item) => ({
      type: PARTICIPATION_EXPORT_TYPES.PARTICIPATION_EXPORT_ITEM,
      column_adapter_id: item.id,
    }));
    const exportData = {
      api_participation_export: {
        name: `${this.props.lang.API_EXPORT} ${moment(new Date().toISOString()).format('DD/MM/YYYY')}`,
        comment: values.comment || '',
        automation_task_id: this.props.taskId,
        from: moment.utc(values.dateFrom, 'DD/MM/YYYY').toISOString(),
        to: moment.utc(values.dateTo, 'DD/MM/YYYY').toISOString(),
        participation_export_items: transferItems,
        allowed_for_all: values.allowed_for_all === 'true',
        transferable_memberships: values.users?.map(({value}) => ({
          membership_id: value,
        })),
        parameters_for_export: this.props.isAll ? this.props.queryParams : {},
      },
    };

    if (!this.props.isAll) {
      exportData.api_participation_export.exported_participations = this.props.checkedParticipants.map(({id}) => ({
        participation_id: id,
      }));
    }

    return this.props.apiParticipationsExport(exportData).then(() => {
      if (this.props.updateCallBack) {
        this.props.updateCallBack();
      }
      this.props.onClose();
    });
  };

  toggleAllColumns = () => {
    this.setState((state) => {
      const mappedGeneralParticipationColumns = cloneDeep(state.generalParticipationColumns);
      const allColumnsChecked = this.state.allColumnsChecked;
      Object.keys(mappedGeneralParticipationColumns).forEach((groupName) => {
        mappedGeneralParticipationColumns[groupName].checked = !allColumnsChecked;
        mappedGeneralParticipationColumns[groupName].forEach((item) => {
          if (typeof item === 'object') {
            item.checked = !allColumnsChecked;
          }
        });
      });
      return {
        allColumnsChecked: !allColumnsChecked,
        generalParticipationColumns: mappedGeneralParticipationColumns,
        isColumnsFieldsTouched: true,
      };
    }, this.validateFormSection);
  };

  handleColumnCheck = (columnGroupName, item, index) => {
    this.setState((state) => {
      const mappedGeneralParticipationColumns = makeCopyGeneralParticipationColumns(state.generalParticipationColumns);

      mappedGeneralParticipationColumns[columnGroupName][index].checked =
        !mappedGeneralParticipationColumns[columnGroupName][index].checked;

      mappedGeneralParticipationColumns[columnGroupName].checked = mappedGeneralParticipationColumns[
        columnGroupName
      ].every((element) => element.checked);

      return {
        generalParticipationColumns: mappedGeneralParticipationColumns,
        isColumnsFieldsTouched: true,
      };
    }, this.validateFormSection);
  };

  toggleGroupColumns = (columnGroupName) => {
    this.setState((state) => {
      const mappedGeneralParticipationColumns = makeCopyGeneralParticipationColumns(state.generalParticipationColumns);
      const isGroupChecked = !!mappedGeneralParticipationColumns[columnGroupName].checked;

      mappedGeneralParticipationColumns[columnGroupName].forEach((item) => {
        if (typeof item === 'object') {
          item.checked = !isGroupChecked;
        }
      });
      mappedGeneralParticipationColumns[columnGroupName].checked = !isGroupChecked;

      return {
        generalParticipationColumns: mappedGeneralParticipationColumns,
        isColumnsFieldsTouched: true,
      };
    }, this.validateFormSection);
  };

  getColumnName = ({record_type, name}) => {
    const columnName = `${record_type} ${name}`;
    return PARTICIPANTS_COLUMN_MAP[columnName] || columnName;
  };

  renderAvailableColumns = () => {
    return Object.entries(this.state.generalParticipationColumns).map((entry, idx) => {
      const columnGroupName = entry[0];
      const columnGroupItems = entry[1];

      return columnGroupName === 'checked' ? null : (
        <Rollout
          key={idx}
          title={
            <label className="checkbox-button theme-color-9">
              <input
                className="checkbox-button__input"
                type="checkbox"
                name="checkbox-general"
                checked={!!columnGroupItems.checked}
                onChange={() => {
                  this.toggleGroupColumns(columnGroupName);
                }}
              />
              <div className="checkbox-button__label">
                <span className="icon-checked checkbox-button__icon" />
                <span className="checkbox-button__text">
                  <span className="main-text main-text--bold">{columnGroupName}</span>
                </span>
              </div>
            </label>
          }
          bodyStyles={{
            marginRight: '13px',
          }}
          className="rollout--expand-1"
          onTransition={this.handleTransition}
          isOpened={false}
        >
          <CustomScrollbars
            scrollbarProps={{
              hideTracksWhenNotNeeded: true,
              autoHeightMax: 191,
              autoHeightMin: 191,
            }}
          >
            <div className="list list--view-3">
              <div className="list__group">
                {columnGroupItems.map((item, index) => (
                  <div className={`list__item ${item.hidden ? 'hidden' : ''}`} key={index}>
                    <label className="checkbox-button checkbox-button--view-1 checkbox-button">
                      <input
                        type="checkbox"
                        data-name={item.name}
                        className="checkbox-button__input"
                        checked={!!item.checked}
                        onChange={() => {
                          this.handleColumnCheck(columnGroupName, item, index);
                        }}
                      />
                      <div className="checkbox-button__label">
                        <span className="icon-checked checkbox-button__icon" />
                        <span className="checkbox-button__text">{this.getColumnName(item)}</span>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </CustomScrollbars>
        </Rollout>
      );
    });
  };

  render() {
    const {handleSubmit, users, show, onClose, onCancel, formValues, lang} = this.props;
    const {showWarning, allColumnsChecked, isColumnsFieldsTouched} = this.state;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-1 theme-color-9 leads-port-layout"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TITLE_API}
      >
        <form onSubmit={handleSubmit(this.onSave)}>
          <div className="theme-color-9">
            <div className="main-text main-text--small main-text--color-1">{lang.SET_COLUMNS + '*'}</div>
            <div className="content-group">
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMin: 200,
                  autoHeightMax: 360,
                  hideTracksWhenNotNeeded: true,
                }}
              >
                <div style={{width: 337}}>
                  <div className="content-group__header">
                    <label className="checkbox-button">
                      <input
                        className="checkbox-button__input"
                        type="checkbox"
                        name="checkbox-all"
                        value="0"
                        checked={allColumnsChecked}
                        onChange={this.toggleAllColumns}
                      />
                      <div className="checkbox-button__label">
                        <span className="icon-checked checkbox-button__icon" />
                        <span className="checkbox-button__text">
                          <span className="main-text main-text--bold">{lang.ALL_COLUMNS}</span>
                        </span>
                      </div>
                    </label>
                  </div>
                  {this.renderAvailableColumns()}
                </div>
              </CustomScrollbars>
            </div>
            {showWarning && isColumnsFieldsTouched && (
              <div className="message-error-text">{lang.NO_MATCH_ERROR_MESSAGE}</div>
            )}
            <div className="leads-port-layout__row form-field-wrap">
              <div className="leads-port-layout__row-title">{lang.PERIOD_LABEL + '*'}</div>
              <div className="flex-container">
                <div className="flex-inline-container">
                  <Field
                    cssModifier="leads-port-layout__datepicker leads-port-layout__datepicker--full-width"
                    label={lang.FROM_LABEL}
                    name="dateFrom"
                    type="datepicker"
                    validate={this.rules.dateFrom}
                    component={fieldTemplate}
                  />
                  <Field
                    cssModifier="leads-port-layout__datepicker leads-port-layout__datepicker--full-width"
                    label={lang.TO_LABEL}
                    name="dateTo"
                    type="datepicker"
                    validate={this.rules.dateTo}
                    component={fieldTemplate}
                  />
                </div>
              </div>
            </div>
            <div className="leads-port-layout__row">
              <div className="leads-port-layout__row-title">{lang.ALLOWED_USERS_LABEL}</div>
              <div className="flex-container margin-bottom--5">
                <div className="leads-port-layout__col-1">
                  <Field
                    label={lang.ALLOW_ALL_LABEL}
                    name="allowed_for_all"
                    value="true"
                    type="radio"
                    component={fieldTemplate}
                  />
                </div>
                <div className="leads-port-layout__col-1">
                  <Field
                    label={lang.ALLOW_SELECTED_LABEL}
                    name="allowed_for_all"
                    value="false"
                    type="radio"
                    component={fieldTemplate}
                  />
                </div>
              </div>
              <AllowedUsersField
                label={lang.USER_ALLOWED_LABEL}
                users={users}
                disabled={formValues.allowed_for_all === 'true'}
              />
            </div>
            <Field
              cssModifier="leads-port-layout__textarea"
              name="comment"
              type="textarea"
              label={lang.COMMENT_LABEL}
              component={fieldTemplate}
            />
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button
              className="button button--bg-11 modal-window__footer-btn"
              type="button"
              onClick={onCancel || onClose}
            >
              {lang.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-4 modal-window__footer-btn" type="submit">
              {lang.GENERATE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const ParticipantsGenerateApiExportModalForm = reduxForm({
  form: ParticipantsGenerateApiExportModal.formName,
})(ParticipantsGenerateApiExportModal);

export default connect(
  (state) => {
    const selector = formValueSelector(ParticipantsGenerateApiExportModal.formName);
    return {
      lang: state.languageState.payload.PARTICIPANTS_PORT.GENERATE_EXPORT_FILE_MODAL,
      columnsGroup: selector(state, ParticipantsGenerateApiExportModal.columnsGroup),
      dateFrom: selector(state, 'dateFrom'),
      dateTo: selector(state, 'dateTo'),
      formValues: getFormValues(ParticipantsGenerateApiExportModal.formName)(state),
    };
  },
  {
    apiParticipationsExport,
  },
)(ParticipantsGenerateApiExportModalForm);
