/* eslint-disable no-inline-comments */
import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {toggleSubmittedAction} from 'client/ducks/add-place-form/actions';
import {setBreadcrumbs as setBreadcrumbsAction} from 'client/ducks/breadcrumbs/actions';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import PlacesStoresMapModal from 'client/components/common/places-stores-map-modal';
import TitleBlock from 'client/components/common/title-block';

import DeleteStoresModal from './components/modals/delete-stores-modal';
import EditStoreModal from './components/modals/edit-store-modal';
import StoresTable from './components/tables/stores-table';

import {toggleSubmittedAction as toggleLinkToClientSubmittedAction} from '../../ducks/link-to-client-form/actions';
import {
  getStoresAction,
  toggleAddStoreModalAction,
  toggleMapModalAction,
  changeSelectedStoresAction,
  getMappedStoresAction,
  editStoreAction,
  toggleSelectRegionModalAction,
  patchStoreAction,
  getClientAction,
  clearStoreAction,
} from '../../ducks/stores/actions';
import SelectRegionForm from '../places/components/modals/link-to-client-modal/link-to-client-form';

class StoresContainer extends ReactQueryParams {
  constructor(props) {
    super(props);

    this.state = {
      editingStore: {},
      showDeleteStoresModal: false,
      search: '',
      isItAgency: true,
    };

    this.LANGUAGE = props.languageState.payload.STORES;
  }

  componentDidMount() {
    this.props.getClient(this.props.id).then(() => {
      this.setState(
        {
          isItAgency: this.props.stores.client && this.props.stores.client.type === 'Agency',
        },
        () => {
          const mainPage = this.state.isItAgency ? CLIENT_PAGES.AGENCIES : CLIENT_PAGES.COMPANIES;

          const {
            languageState: {
              payload: {BREADCRUMBS},
            },
          } = this.props;

          let links = [
            {
              name: this.props.stores.client.name,
              href: `${mainPage}/${this.props.id}`,
            },
            {
              name: this.LANGUAGE.STORES_TITLE,
            },
          ];

          if (this.props.isAdmin) {
            links = [
              {
                name: BREADCRUMBS.CLIENTS,
                href: CLIENT_PAGES.CLIENTS_LIST,
              },
              ...links,
            ];
          }

          this.props.setBreadcrumbs(links);
        },
      );
    });
    this.getMyStores();
  }

  componentWillUnmount() {
    this.props.clearStore();
    this.setState({
      editingStore: {},
      showDeleteStoresModal: false,
      search: '',
    });
  }

  getMyStores = () => {
    const {queryParams: params} = this;
    this.props.getStores({
      clientId: this.props.id,
      page: params.page || 1,
      perPage: params.perPage || 5,
      sort: {
        name: (params.sort && params.sort.name) || 'name',
        order: (params.sort && params.sort.order) || 'ASC',
      },
      regionId: params.regionId,
      search: params.search,
    });
  };

  static validateEditStoreFormValues(addPlaceForm) {
    const addPlaceFormValues = addPlaceForm && addPlaceForm.values ? addPlaceForm.values : {};
    const addPlaceFormErrors = addPlaceForm && addPlaceForm.syncErrors ? addPlaceForm.syncErrors : {};

    if (
      isEmpty(addPlaceFormErrors) &&
      addPlaceFormValues.name &&
      addPlaceFormValues.network &&
      addPlaceFormValues.city &&
      addPlaceFormValues.street_address
    ) {
      return {
        name: addPlaceFormValues.name,
        network_id: addPlaceFormValues.network.id,
        city_id: addPlaceFormValues.city.id,
        street_address: addPlaceFormValues.street_address,
        client_id: (addPlaceFormValues.client && addPlaceFormValues.client.id) || null,
        region_id: (addPlaceFormValues.region && addPlaceFormValues.region.id) || null,
        comment: addPlaceFormValues.comment || null,
      };
    }
    return null;
  }

  onEditStoreButtonClick = () => {
    const store = StoresContainer.validateEditStoreFormValues(this.props.addPlaceForm);
    if (store) {
      this.props.editStore(this.state.editingStore.id, store).catch(() => this.props.toggleSubmitted());
    } else {
      this.props.toggleSubmitted();
    }
  };

  onPageChange = (params) => {
    this.setQueryParams(params, true);
    this.getMyStores();
  };

  onSortChange = (name, order) => {
    this.setQueryParams(
      {
        sort: {name, order},
      },
      true,
    );
    this.getMyStores();
  };

  onSearchChange = (search) => {
    this.setQueryParams({search, page: 1}, true);
    this.setState({search}, this.getMyStores);
  };

  onMapViewButtonClick = (storeId) => {
    this.props.getMappedStores(this.props.id, storeId).then(this.props.toggleMapModal);
  };

  onEditStoreClick = (editingStore) => {
    this.setState(
      {
        editingStore,
      },
      () => this.props.toggleAddStoreModal(),
    );
  };

  // green button above the table
  selectRegionClick = () => {
    this.setState(
      {
        editingStore: {client_id: this.props.id},
      },
      this.props.toggleSelectRegionModal,
    );
  };

  // link in table cell
  onSelectRegionClick = (editingStore) => {
    this.setState(
      {
        editingStore: editingStore,
      },
      this.props.toggleSelectRegionModal,
    );
  };

  // submit button in SelectRegionModal
  onSelectRegionButtonClick = () => {
    const {toggleLinkToClientSubmitted, patchStore, linkToClientForm, toggleSelectRegionModal} = this.props;
    const selectedStores = this.props.stores.selectedStores.length
      ? this.props.stores.selectedStores
      : [this.state.editingStore.id];

    const linkToClientFormValues = linkToClientForm && linkToClientForm.values ? linkToClientForm.values : {};

    if (linkToClientFormValues.region && linkToClientFormValues.region.id) {
      for (let storeId of selectedStores.slice().reverse()) {
        patchStore({
          id: storeId,
          region_id: linkToClientFormValues.region.id,
        }).catch(() => toggleLinkToClientSubmitted());
      }
    } else {
      toggleSelectRegionModal();
    }
  };

  onDetachRegionButtonClick = () => {
    this.props
      .patchStore({
        id: this.state.editingStore.id,
        region_id: null,
      })
      .catch(this.props.toggleLinkToClientSubmitted);
  };

  onDeleteStoresClick = () => {
    for (let storeId of this.props.stores.selectedStores) {
      this.props.patchStore({
        id: storeId,
        client_id: null,
        region_id: null,
      });
    }
    this.props.changeSelectedStores([]);
    this.setState({showDeleteStoresModal: false});
  };

  render() {
    const {toggleMapModal, toggleAddStoreModal, toggleSelectRegionModal, changeSelectedStores, languageState, isAdmin} =
      this.props;
    const {
      stores,
      meta = {},
      mappedStores,
      mapModalToggle,
      addPlaceModalToggle,
      selectRegionModalToggle,
      needToUpdateStores,
      selectedStores,
      addPlaceSubmitting,
      client,
    } = this.props.stores;

    const {LANGUAGE} = this;

    if (needToUpdateStores) {
      this.getMyStores();
    }

    return (
      <div className="theme-color-3">
        <div className="page__title-block">
          <TitleBlock theme separator>
            <TitleBlock.Item>{client.brand_name}</TitleBlock.Item>
            <TitleBlock.Item className="">{LANGUAGE.STORES_TITLE}</TitleBlock.Item>
          </TitleBlock>
        </div>
        <StoresTable
          needToUpdateStores={needToUpdateStores}
          data={stores}
          isAdmin={isAdmin}
          perPage={Number(this.queryParams.perPage) || 5}
          search={this.state.search}
          currentPage={meta.current_page}
          totalPages={meta.total_pages}
          selectedStores={selectedStores}
          changeSelectedStores={changeSelectedStores}
          onPageChange={this.onPageChange.bind(this)}
          onSortChange={this.onSortChange.bind(this)}
          onSearch={this.onSearchChange.bind(this)}
          totalItems={meta.total_count}
          mapViewClick={this.onMapViewButtonClick.bind(this)}
          lang={languageState.payload.STORES}
          onSearchClear={() => this.onSearchChange('')}
          showClear={Boolean(this.state.search)}
          onEditStoreClick={this.onEditStoreClick.bind(this)}
          selectRegionClick={this.selectRegionClick.bind(this)}
          onSelectRegionClick={this.onSelectRegionClick.bind(this)}
          deleteStoresClick={() => this.setState({showDeleteStoresModal: true})}
          sort={this.queryParams.sort}
        />

        <DeleteStoresModal
          show={this.state.showDeleteStoresModal}
          onCancel={() => this.setState({showDeleteStoresModal: false})}
          onClose={() => this.setState({showDeleteStoresModal: false})}
          onConfirm={this.onDeleteStoresClick}
        />

        <EditStoreModal
          show={addPlaceModalToggle}
          onClose={toggleAddStoreModal}
          onEditStore={this.onEditStoreButtonClick}
          editingStore={this.state.editingStore}
          disabled={addPlaceSubmitting}
        />

        <PlacesStoresMapModal show={mapModalToggle} onClose={toggleMapModal} places={mappedStores || []} />

        {/* SelectRegionModal */}
        <Modal
          show={selectRegionModalToggle}
          onClose={toggleSelectRegionModal}
          dialogClassName="modal-window--width-1 modal-window--theme-3"
          title={LANGUAGE.SELECT_REGION_TITLE}
        >
          <SelectRegionForm
            languageState={languageState}
            initialValues={this.state.editingStore}
            onDetachRegionButtonClick={this.onDetachRegionButtonClick}
            isStore
          />
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" onClick={toggleSelectRegionModal}>
              {LANGUAGE.CANCEL_BUTTON}
            </button>
            <button
              type="submit"
              className="button button--bg-3 modal-window__footer-btn"
              onClick={this.onSelectRegionButtonClick.bind(this)}
            >
              {LANGUAGE.SAVE_BUTTON}
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

StoresContainer.propTypes = {
  id: PropTypes.number.isRequired,
  toggleMapModal: PropTypes.func,
  toggleAddStoreModal: PropTypes.func,
  toggleSelectRegionModal: PropTypes.func,
  toggleSubmitted: PropTypes.func,
  changeSelectedStores: PropTypes.func,
  editStore: PropTypes.func,
  getMappedStores: PropTypes.func,
  getStores: PropTypes.func,
  getClient: PropTypes.func,
  clearStore: PropTypes.func,
  changePerPage: PropTypes.func,
  changeSort: PropTypes.func,
  toggleLinkToClientSubmitted: PropTypes.func,
  patchStore: PropTypes.func,
  changeSearchFieldValue: PropTypes.func,
  stores: PropTypes.shape({
    client: PropTypes.object,
    stores: PropTypes.array,
    meta: PropTypes.shape({
      current_page: PropTypes.number,
      next_page: PropTypes.any,
      prev_page: PropTypes.any,
      total_count: PropTypes.number,
      total_pages: PropTypes.number,
    }),

    mapModalToggle: PropTypes.bool,
    addPlaceModalToggle: PropTypes.bool,
    selectRegionModalToggle: PropTypes.bool,
    needToUpdateStores: PropTypes.bool,
    selectedStores: PropTypes.array,
    mappedStores: PropTypes.array,
    addPlaceSubmitting: PropTypes.bool,
  }),
  addPlaceForm: PropTypes.shape({
    values: PropTypes.object,
    syncErrors: PropTypes.object,
  }),
  linkToClientForm: PropTypes.shape({
    values: PropTypes.object,
    syncErrors: PropTypes.object,
  }),
  languageState: PropTypes.object,
  setBreadcrumbs: PropTypes.func,
};

const mapStateToProps = ({stores, form, languageState, ...state}) => ({
  stores,
  addPlaceForm: form.addPlaceForm,
  linkToClientForm: form.linkToClientForm,
  languageState,
  isAdmin: selectIsAdmin(state),
});

const mapDispatchToProps = {
  toggleMapModal: toggleMapModalAction,
  toggleAddStoreModal: toggleAddStoreModalAction,
  toggleSelectRegionModal: toggleSelectRegionModalAction,
  toggleLinkToClientSubmitted: toggleLinkToClientSubmittedAction,
  patchStore: patchStoreAction,
  getStores: getStoresAction,
  getClient: getClientAction,
  clearStore: clearStoreAction,
  getMappedStores: getMappedStoresAction,
  editStore: editStoreAction,
  toggleSubmitted: toggleSubmittedAction,
  changeSelectedStores: changeSelectedStoresAction,
  setBreadcrumbs: setBreadcrumbsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoresContainer);
