import React from 'react';

import {Field} from 'redux-form';

import BaseFieldComponent from 'client/common/fields/base-field';
import {RadioGroupInput} from 'client/common/inputs';

const InputComponent = (props) => <BaseFieldComponent Component={RadioGroupInput} {...props} />;

const RadioGroupField = (props) => {
  return <Field component={InputComponent} {...props} />;
};

export default RadioGroupField;
