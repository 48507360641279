import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {getIsNational} from 'client/services/cookie-data-source';

import {selectCurrentOrganization} from 'client/ducks/user-clients/selectors';
import {selectIsAdmin, selectIsUserLoaded} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import LeadsList from '../components/leads/leads-list/leads-list';

class LeadsListPage extends ReactQueryParams {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const title = this.props.languageState.payload.LEADS_LIST.PAGE_TITLE;

    this.props.setMetaAction({title});
  }

  render() {
    const {queryParams: params} = this;
    const {
      isAdmin,
      isUserLoaded,
      currentOrganization,
      match: {
        params: {id},
      },
    } = this.props;

    const isNational = getIsNational();

    if (isUserLoaded && !isAdmin && isNational && currentOrganization.id) {
      return currentOrganization.id !== +id ? (
        <Redirect to={CLIENT_PAGES.HOME} />
      ) : (
        <LeadsList urlParams={params} clientId={String(currentOrganization.id)} isNational={getIsNational()} />
      );
    }

    if (isUserLoaded && !isAdmin && !isNational) {
      return <Redirect to={CLIENT_PAGES.HOME} />;
    }

    return <LeadsList urlParams={params} clientId={id} isNational={getIsNational()} />;
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  isAdmin: selectIsAdmin(state),
  isUserLoaded: selectIsUserLoaded(state),
  currentOrganization: selectCurrentOrganization(state),
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsListPage);
