import React, {Component} from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

function VarNameCell({data, rowIndex, field, onClick}) {
  return (
    <Cell>
      <div className="ellipsis-text underline cursor-pointer" onClick={() => onClick(data[rowIndex])}>
        {data[rowIndex][field]}
      </div>
    </Cell>
  );
}

VarNameCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
  onClick: PropTypes.func,
};

VarNameCell.defaultProps = {
  onClick: () => {},
};

const getSourceNameText = (source) => {
  if (!source) {
    return '';
  }

  if (source.interface) {
    return source.interface.name;
  }

  if (source.record_type) {
    return source.record_type;
  }

  if (source.scenario) {
    return source.scenario.name;
  }

  if (source.scenario_step) {
    if (source.scenario_step.scenario) {
      return source.scenario_step.scenario.name;
    }

    return source.scenario_step.name;
  }

  return '';
};

function SourceNameCell({data, rowIndex}) {
  const {source} = data[rowIndex];

  return (
    <Cell>
      <div className="ellipsis-text">{getSourceNameText(source)}</div>
    </Cell>
  );
}

SourceNameCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

function ItemCell({data, rowIndex}) {
  const {source} = data[rowIndex];

  return (
    <Cell>
      <div className="ellipsis-text">{source && (source.full_name || source.name)}</div>
    </Cell>
  );
}

ItemCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
};

class AtsVarsCardTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditModal: false,
    };

    this.LANGUAGE = props.languageState.payload.AUTOTASK_SCENARIO.VARS_CARD_TABLE;
  }

  render() {
    const {onClick, data} = this.props;

    return (
      <div className="fixed-table fixed-table--scenarios-vars-card fixed-table--expand-1">
        <Table rowsCount={data.length} headerHeight={40} rowHeight={34} width={230} height={298} {...this.props}>
          <Column
            header={<Cell>{this.LANGUAGE.VAR_NAME_COLUMN}</Cell>}
            cell={<VarNameCell data={data} field="name" onClick={onClick} />}
            width={215}
          />
          <Column
            header={<Cell>{this.LANGUAGE.SOURCE_NAME_COLUMN}</Cell>}
            cell={<SourceNameCell data={data} />}
            width={165}
          />
          <Column header={<Cell>{this.LANGUAGE.ITEM_COLUMN}</Cell>} cell={<ItemCell data={data} />} width={200} />
        </Table>
      </div>
    );
  }
}

AtsVarsCardTable.defaultProps = {
  data: [],
  onClick: () => {},
};

AtsVarsCardTable.propTypes = {
  languageState: PropTypes.object.isRequired,
  data: PropTypes.array,
  onClick: PropTypes.func,
};

export default connect(({languageState}) => ({
  languageState,
}))(AtsVarsCardTable);
