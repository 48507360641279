import {Reducer} from 'redux';

import {LeadColumn} from 'client/models/lead/types';
import {OptInColumn} from 'client/models/opt-in-column/types';

import types from './types';

type State = {
  leadColumns: LeadColumn[];
  optinColumns: OptInColumn[];
};

const initialState: State = {
  leadColumns: [],
  optinColumns: [],
};

const reducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LEAD_COLUMNS_SUCCESS:
      return {
        ...state,
        leadColumns: action.payload.lead_columns,
      };

    case types.GET_OPTIN_COLUMNS_SUCCESS:
      return {
        ...state,
        optinColumns: action.payload.opt_in_columns,
      };

    default:
      return state;
  }
};

export default reducer;
