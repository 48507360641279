import React from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectAutotaskTypes} from 'client/ducks/autotask/selectors';

import {ToggleField} from 'client/common/fields';

import PrizeDiyCouponImportField from 'client/components/prizes/fields/prize-diy-coupon-import-field';
import {PrizeDiyModalFormName} from 'client/components/prizes/modals/prize-diy-modal';
import {isOperationStarted} from 'client/models/operations/helpers';

import {getFieldsConfig} from './helpers';

const PrizeDiyCouponImportsFieldset = (props) => {
  const {operation, disabled} = props;
  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_COUPON_IMPORTS_FIELDSET');
  const {isDevicesType, isOnlineType} = useSelector(selectAutotaskTypes);
  const {
    formValues: {segmentation, coupon: {imports = {}} = {}},
  } = useReduxForm(PrizeDiyModalFormName);
  const {segmentationImports} = imports;

  const config = getFieldsConfig(
    isDevicesType,
    isOnlineType,
    segmentationImports,
    !!segmentation?.device_online_segmentation,
  );
  const isOpStarted = isOperationStarted(operation);

  return (
    <>
      {!!segmentation?.device_online_segmentation && (
        <ToggleField
          name="coupon.imports.segmentationImports"
          withWrap
          label={lang.DEVICE_LEVEL_CODE_LIST}
          backgroundColor="current"
          borderColor="current"
          disabled={disabled}
          rounded
        />
      )}
      <PrizeDiyCouponImportField
        name="coupon.imports.device"
        label={lang[config.device.labelKey]}
        show={config.device.show}
        showToggle={!!segmentation?.geo_level}
        disabled={disabled}
        isOperationStarted={isOpStarted}
      />
      <PrizeDiyCouponImportField
        name="coupon.imports.online"
        label={lang[config.online.labelKey]}
        show={config.online.show}
        showToggle={false}
        disabled={disabled}
        isOperationStarted={isOpStarted}
      />
      <PrizeDiyCouponImportField
        name="coupon.imports.common"
        label={lang[config.common.labelKey]}
        show={config.common.show}
        showToggle={!!segmentation?.geo_level && !(isOnlineType && isDevicesType)}
        disabled={disabled}
        isOperationStarted={isOpStarted}
      />
    </>
  );
};

PrizeDiyCouponImportsFieldset.propTypes = {
  operation: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default PrizeDiyCouponImportsFieldset;
