import types from './types';

const initialState = {
  manualGroups: [],
  visualGroup: {},
};

export default function (_state = initialState, action) {
  const state = Object.assign({}, _state);

  switch (action.type) {
    case types.GET_MANUAL_GROUPS_SUCCESS:
      state.manualGroups = action.payload.manual_visual_groups;
      return state;

    case types.GET_VISUAL_GROUP_SUCCESS:
      state.visualGroup = action.payload.visual_group;
      return state;

    default:
      return state;
  }
}
