/* eslint-disable*/
import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Modal from 'client/common/modals/modal';

class NewClientNoSelectsModal extends Component {
  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.CLIENTS_LIST;

    this.save = this.save.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  save(data) {}

  handleBack() {
    this.props.onBack();
  }

  handleClose() {
    this.props.onClose();
  }

  render() {
    const {data, onCreate} = this.props;
    return (
      <Modal
        show={this.props.show}
        onClose={this.handleClose}
        dialogClassName="modal-window--width-1 modal-window--theme-1"
        titleClassName="modal-window__title"
        title={
          <>
            <h3 className="title">{this.LANGUAGE.NEW_CLIENT_MODAL_TITLE}</h3>
            <div className="modal-window__subtitle">{data.name}</div>
          </>
        }
      >
        <div className="modal-window__descr">{this.LANGUAGE.NEW_CLIENT_MODAL_NO_SELECT}</div>
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-11 modal-window__footer-btn" type="submit" onClick={this.handleBack}>
            {this.LANGUAGE.NEW_CLIENT_MODAL_BACK_BUTTON}
          </button>
          <button className="button button--bg-1 modal-window__footer-btn" type="submit" onClick={onCreate}>
            {this.LANGUAGE.NEW_CLIENT_MODAL_CREATE_BUTTON}
          </button>
        </div>
      </Modal>
    );
  }
}

NewClientNoSelectsModal.defaultProps = {
  show: false,
  onBack: () => {},
  onClose: () => {},
  onCreate: () => {},
};

NewClientNoSelectsModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onBack: PropTypes.func,
  onCreate: PropTypes.func,
  languageState: PropTypes.object,
};

export default connect(({languageState}) => ({
  languageState,
}))(NewClientNoSelectsModal);
