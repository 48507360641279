import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, Field, getFormValues} from 'redux-form';

import {GENDER_TYPES_VALUES, MESSAGE_TASK_STATES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

import {TASK_TYPES} from 'client/models/operations/constants';

class BCResultFiltersModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    filters: PropTypes.object,
    formValues: PropTypes.object,
    results: PropTypes.number.isRequired,
    taskType: PropTypes.string.isRequired,
    taskState: PropTypes.string.isRequired,
    initialize: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onFormChange: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    show: false,
    formValues: {},
    filters: {},
  };

  static formName = 'BCResultFiltersModalForm';

  static filtersMap = {
    lead_gender_eq: {
      field: 'lead_gender_eq',
      default: 'no',
    },
    lead_phone_present: {
      field: 'lead_phone_present',
      default: false,
    },
    lead_email_present: {
      field: 'lead_email_present',
      default: false,
    },
    queued_event_successfully: {
      field: 'queued_event_successfully',
    },
    queued_event_successfully_true: {
      field: 'queued_event_successfully_true',
      default: false,
    },
    queued_event_successfully_false: {
      field: 'queued_event_successfully_false',
      default: false,
    },
    queued_event_successfully_null: {
      field: 'queued_event_successfully_null',
      default: false,
    },
    sent_event_successfully: {
      field: 'sent_event_successfully',
    },
    sent_event_successfully_true: {
      field: 'sent_event_successfully_true',
      default: false,
    },
    sent_event_successfully_false: {
      field: 'sent_event_successfully_false',
      default: false,
    },
    sent_event_successfully_null: {
      field: 'sent_event_successfully_null',
      default: false,
    },
    open_event_successfully: {
      field: 'open_event_successfully',
      default: 'no',
      opened: 'true',
      notOpened: 'null',
    },
    delivered_event_successfully: {
      field: 'delivered_event_successfully',
      default: 'no',
      delivered: 'true',
      notDelivered: 'null',
    },
    unsubscribed: {
      field: 'unsubscribed',
      default: 'no',
      clicked: 'true',
      notClicked: 'false',
    },
  };

  static createFiltersQuery = (filters = {}) => {
    const map = BCResultFiltersModal.filtersMap;
    const base = {};
    const queued = {};
    const sent = {};

    Object.keys(filters).forEach((key) => {
      switch (key) {
        case map.open_event_successfully.field:
        case map.delivered_event_successfully.field:
        case map.unsubscribed.field:
          base[`${key}_${filters[key]}`] = true;
          break;
        case map.queued_event_successfully_false.field:
        case map.queued_event_successfully_true.field:
        case map.queued_event_successfully_null.field:
          queued.m = 'or';
          queued[key] = true;
          break;
        case map.sent_event_successfully_false.field:
        case map.sent_event_successfully_true.field:
        case map.sent_event_successfully_null.field:
          sent.m = 'or';
          sent[key] = true;
          break;
        default:
          base[key] = filters[key];
      }
    });

    return {base, queued, sent};
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.BROADCASTING_TASK.RESULT_FILTERS_MODAL;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this.initForm(this.props.filters);
    }
  }

  initForm = (filters = {}) => {
    const KEY = 0;
    const VALUE = 1;
    const map = BCResultFiltersModal.filtersMap;

    const values = Object.entries(filters).reduce((prevValues, item) => {
      const key = item[KEY];
      const filterValue = item[VALUE];
      let result = {};

      switch (key) {
        case map.lead_email_present.field:
        case map.lead_phone_present.field:
          result[key] = filterValue;
          break;
        case map.queued_event_successfully_true.field:
        case map.queued_event_successfully_false.field:
        case map.queued_event_successfully_null.field:
        case map.sent_event_successfully_true.field:
        case map.sent_event_successfully_false.field:
        case map.sent_event_successfully_null.field:
          result[key] = true;
          break;
        default:
        //
      }
      return {...prevValues, ...result};
    }, {});

    const fieldNames = [
      map.lead_gender_eq.field,
      map.unsubscribed.field,
      map.open_event_successfully.field,
      map.delivered_event_successfully.field,
    ];

    fieldNames.forEach((key) => {
      values[map[key].field] = filters[map[key].field] || map[key].default;
    });

    this.props.initialize(values);
  };

  handleChange = () => {
    const map = BCResultFiltersModal.filtersMap;
    const {formValues} = this.props;

    const filters = Object.keys(formValues).reduce((prevFilters, key) => {
      const isDefault = formValues[key] === map[key].default;
      const keys = Object.keys(map);

      if (!isDefault && keys.includes(key)) {
        prevFilters[map[key].field] = formValues[key];
      }
      return prevFilters;
    }, {});

    this.props.onFormChange(filters);
  };

  handleFieldChange = () => {
    Promise.resolve().then(this.handleChange);
  };

  renderSmsFilters = () => {
    const map = BCResultFiltersModal.filtersMap;

    return (
      <div key="delivered" className="form-field-wrap form-field-wrap--margin-1 fake-input">
        <div className="fake-input__label">{this.LANGUAGE.DELIVERED_LABEL}</div>
        <div className="field-group">
          <div className="field-group__row">
            <div className="field-group__field">
              <Field
                cssModifier="radio-button--switcher-theme-1"
                label={this.LANGUAGE.NO_MATTER_RADIO}
                name={map.delivered_event_successfully.field}
                type="radio"
                value={map.delivered_event_successfully.default}
                radioType="switcher-2"
                onChange={this.handleFieldChange}
                component={fieldTemplate}
              />
            </div>
            <div className="field-group__field">
              <Field
                cssModifier="radio-button--switcher-theme-1"
                label={this.LANGUAGE.DELIVERED_RADIO}
                name={map.delivered_event_successfully.field}
                value={map.delivered_event_successfully.delivered}
                type="radio"
                radioType="switcher-2"
                onChange={this.handleFieldChange}
                component={fieldTemplate}
              />
            </div>
            <div className="field-group__field">
              <Field
                cssModifier="radio-button--switcher-theme-1"
                label={this.LANGUAGE.NOT_DELIVERED_RADIO}
                name={map.delivered_event_successfully.field}
                value={map.delivered_event_successfully.notDelivered}
                type="radio"
                radioType="switcher-2"
                onChange={this.handleFieldChange}
                component={fieldTemplate}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderEmailFilters = () => {
    const map = BCResultFiltersModal.filtersMap;

    return [
      <div key="open" className="form-field-wrap form-field-wrap--margin-1 fake-input">
        <div className="fake-input__label">{this.LANGUAGE.OPEN_LABEL}</div>
        <div className="field-group">
          <div className="field-group__row">
            <div className="field-group__field">
              <Field
                cssModifier="radio-button--switcher-theme-1"
                label={this.LANGUAGE.NO_MATTER_RADIO}
                name={map.open_event_successfully.field}
                type="radio"
                value={map.open_event_successfully.default}
                radioType="switcher-2"
                onChange={this.handleFieldChange}
                component={fieldTemplate}
              />
            </div>
            <div className="field-group__field">
              <Field
                cssModifier="radio-button--switcher-theme-1"
                label={this.LANGUAGE.OPENED_RADIO}
                name={map.open_event_successfully.field}
                value={map.open_event_successfully.opened}
                type="radio"
                radioType="switcher-2"
                onChange={this.handleFieldChange}
                component={fieldTemplate}
              />
            </div>
            <div className="field-group__field">
              <Field
                cssModifier="radio-button--switcher-theme-1"
                label={this.LANGUAGE.NOT_OPENED_RADIO}
                name={map.open_event_successfully.field}
                value={map.open_event_successfully.notOpened}
                type="radio"
                radioType="switcher-2"
                onChange={this.handleFieldChange}
                component={fieldTemplate}
              />
            </div>
          </div>
        </div>
      </div>,
      <div key="unsubscribe" className="form-field-wrap form-field-wrap--margin-1 fake-input">
        <div className="fake-input__label">{this.LANGUAGE.UNSUBSCRIBE_LABEL}</div>
        <div className="field-group">
          <div className="field-group__row">
            <div className="field-group__field">
              <Field
                cssModifier="radio-button--switcher-theme-1"
                label={this.LANGUAGE.NO_MATTER_RADIO}
                name={map.unsubscribed.field}
                type="radio"
                value={map.unsubscribed.default}
                radioType="switcher-2"
                onChange={this.handleFieldChange}
                component={fieldTemplate}
              />
            </div>
            <div className="field-group__field">
              <Field
                cssModifier="radio-button--switcher-theme-1"
                label={this.LANGUAGE.CLICKED_RADIO}
                name={map.unsubscribed.field}
                value={map.unsubscribed.clicked}
                type="radio"
                radioType="switcher-2"
                onChange={this.handleFieldChange}
                component={fieldTemplate}
              />
            </div>
            <div className="field-group__field">
              <Field
                cssModifier="radio-button--switcher-theme-1"
                label={this.LANGUAGE.NOT_CLICKED_RADIO}
                name={map.unsubscribed.field}
                value={map.unsubscribed.notClicked}
                type="radio"
                radioType="switcher-2"
                onChange={this.handleFieldChange}
                component={fieldTemplate}
              />
            </div>
          </div>
        </div>
      </div>,
    ];
  };

  renderEventsFilters = () => {
    const {LANGUAGE} = this;
    const map = BCResultFiltersModal.filtersMap;
    const isSmsTask = this.props.taskType === TASK_TYPES.SMS;

    return [
      <div key="0" className="form-field-wrap form-field-wrap--margin-1 fake-input">
        <div className="fake-input__label">{isSmsTask ? LANGUAGE.SENT_TO_TWILIO_LABEL : LANGUAGE.SENT_TO_LABEL}</div>
        <div className="field-group">
          <div className="field-group__row">
            <div className="field-group__field">
              <Field
                cssModifier="checkbox-button--switcher-theme-1"
                label={LANGUAGE.SENT_RADIO}
                name={map.queued_event_successfully_true.field}
                type="checkbox"
                checkboxType="switcher-2"
                onChange={this.handleFieldChange}
                component={fieldTemplate}
              />
            </div>
            <div className="field-group__field">
              <Field
                cssModifier="checkbox-button--switcher-theme-1"
                label={LANGUAGE.NOT_SENT_RADIO}
                name={map.queued_event_successfully_null.field}
                type="checkbox"
                checkboxType="switcher-2"
                onChange={this.handleFieldChange}
                component={fieldTemplate}
              />
            </div>
            <div className="field-group__field">
              <Field
                cssModifier="checkbox-button--switcher-theme-1"
                label={LANGUAGE.ERROR_RADIO}
                name={map.queued_event_successfully_false.field}
                type="checkbox"
                checkboxType="switcher-2"
                onChange={this.handleFieldChange}
                component={fieldTemplate}
              />
            </div>
          </div>
        </div>
      </div>,
      <div key="1" className="form-field-wrap form-field-wrap--margin-1 fake-input">
        <div className="fake-input__label">{isSmsTask ? LANGUAGE.SENT_BY_TWILIO_LABEL : LANGUAGE.SENT_BY_LABEL}</div>
        <div className="field-group">
          <div className="field-group__row">
            <div className="field-group__field">
              <Field
                cssModifier="checkbox-button--switcher-theme-1"
                label={LANGUAGE.SENT_RADIO}
                name={map.sent_event_successfully_true.field}
                type="checkbox"
                checkboxType="switcher-2"
                onChange={this.handleFieldChange}
                component={fieldTemplate}
              />
            </div>
            <div className="field-group__field">
              <Field
                cssModifier="checkbox-button--switcher-theme-1"
                label={LANGUAGE.NOT_SENT_RADIO}
                name={map.sent_event_successfully_null.field}
                type="checkbox"
                checkboxType="switcher-2"
                onChange={this.handleFieldChange}
                component={fieldTemplate}
              />
            </div>
            <div className="field-group__field">
              <Field
                cssModifier="checkbox-button--switcher-theme-1"
                label={LANGUAGE.ERROR_RADIO}
                name={map.sent_event_successfully_false.field}
                type="checkbox"
                checkboxType="switcher-2"
                onChange={this.handleFieldChange}
                component={fieldTemplate}
              />
            </div>
          </div>
        </div>
      </div>,
      isSmsTask ? this.renderSmsFilters() : this.renderEmailFilters(),
    ];
  };

  render() {
    const {show, onClose, taskState, results} = this.props;
    const {LANGUAGE} = this;
    const isNewTask = taskState === MESSAGE_TASK_STATES.NEW;
    const map = BCResultFiltersModal.filtersMap;

    return (
      <Modal
        show={show}
        onClose={onClose}
        dialogClassName="modal-window--width-1 theme-color-16"
        title={LANGUAGE.TITLE}
      >
        <form>
          <div className="form-field-wrap form-field-wrap--margin-1 fake-input">
            <div className="fake-input__label">{LANGUAGE.GENDER_LABEL}</div>
            <div className="field-group">
              <div className="field-group__row">
                <div className="field-group__field">
                  <Field
                    cssModifier="radio-button--switcher-theme-1"
                    label={LANGUAGE.NO_MATTER_RADIO}
                    name={map.lead_gender_eq.field}
                    type="radio"
                    value={map.lead_gender_eq.default}
                    radioType="switcher-2"
                    onChange={this.handleFieldChange}
                    component={fieldTemplate}
                  />
                </div>
                <div className="field-group__field">
                  <Field
                    cssModifier="radio-button--switcher-theme-1"
                    label={LANGUAGE.FEMALE_RADIO}
                    name={map.lead_gender_eq.field}
                    type="radio"
                    value={GENDER_TYPES_VALUES.FEMALE}
                    radioType="switcher-2"
                    onChange={this.handleFieldChange}
                    component={fieldTemplate}
                  />
                </div>
                <div className="field-group__field">
                  <Field
                    cssModifier="radio-button--switcher-theme-1"
                    label={LANGUAGE.MALE_RADIO}
                    name={map.lead_gender_eq.field}
                    type="radio"
                    value={GENDER_TYPES_VALUES.MALE}
                    radioType="switcher-2"
                    onChange={this.handleFieldChange}
                    component={fieldTemplate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-field-wrap form-field-wrap--margin-1 fake-input">
            <div className="fake-input__label">{LANGUAGE.CONTACTS_LABEL}</div>
            <div className="field-group">
              <div className="field-group__row">
                <div className="field-group__field">
                  <Field
                    cssModifier="checkbox-button--switcher-theme-1"
                    label={LANGUAGE.WITH_PHONE_RADIO}
                    name={map.lead_phone_present.field}
                    type="checkbox"
                    checkboxType="switcher-2"
                    onChange={this.handleFieldChange}
                    component={fieldTemplate}
                  />
                </div>
                <div className="field-group__field">
                  <Field
                    cssModifier="checkbox-button--switcher-theme-1"
                    label={LANGUAGE.WITH_EMAIL_RADIO}
                    name={map.lead_email_present.field}
                    type="checkbox"
                    checkboxType="switcher-2"
                    onChange={this.handleFieldChange}
                    component={fieldTemplate}
                  />
                </div>
              </div>
            </div>
          </div>
          {!isNewTask ? this.renderEventsFilters() : <div className="main-text">{LANGUAGE.EVENT_FILTERS_MESSAGE}</div>}
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-14 modal-window__footer-btn" type="button" onClick={onClose}>
              {`${LANGUAGE.RESULTS_BUTTON} (${results})`}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const BCResultFiltersModalForm = reduxForm({
  form: BCResultFiltersModal.formName,
})(BCResultFiltersModal);

const mapStateToProps = (state) => ({
  formValues: getFormValues(BCResultFiltersModal.formName)(state),
  languageState: state.languageState,
});

export default connect(mapStateToProps)(BCResultFiltersModalForm);
