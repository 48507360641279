import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import moment from 'moment';

import {GENDER_TYPES_VALUES} from 'client/common/config';

import LeadsFiltersModal from 'client/components/leads/components/modals/leads-filters-modal/leads-filters-modal';

const genderMap = {
  male: GENDER_TYPES_VALUES.MALE,
  female: GENDER_TYPES_VALUES.FEMALE,
  other: GENDER_TYPES_VALUES.OTHER,
  'N/A': GENDER_TYPES_VALUES.NOT_SPECIFIED,
};

const DEFAULT_SELECT = {label: '', value: '0'};

// const getName = (id) => `opt_ins->>${id}_eq`;

const dropdownParticipationsFilters = [
  {
    key: 'source',
    filter: 'source_id_eq',
  },
  {
    key: 'scenario',
    filter: 'scenarios_id_in',
  },
  {
    key: 'optIn',
    filter: null,
  },
  {
    key: 'optInRadio',
    filter: 'lead_opt_ins_is_empty_json',
  },
  {
    key: 'origin',
    filter: 'interaction_id_eq',
  },
];
const dropdownLeadsFilters = [
  {
    key: 'client',
    filter: 'client_id_eq',
  },
  {
    key: 'operation',
    filter: 'participations_operation_id_in',
  },
  {
    key: 'autotask',
    filter: 'participations_automation_task_id_in',
  },
  {
    key: 'optIn',
    filter: null,
  },
  {
    key: 'optInRadio',
    filter: 'opt_ins_is_empty_json',
  },
];

export function mapFilter(data) {
  const obj = {...data};

  if ('optInRadio' in obj && obj.optInRadio === 'selectOptIn') {
    delete obj.optInRadio;
  }

  ['client', 'source'].forEach((key) => {
    if (!obj[key]) {
      return;
    }

    if (obj[key].value === '0') {
      delete obj[key];
    } else {
      obj[key] = {
        ...obj[key],
        label: encodeURIComponent(obj[key].label),
      };
    }
  });

  ['phone', 'email', 'visuals'].forEach((key) => {
    if (!obj[key] || obj[key] === 'false') {
      delete obj[key];
    }
  });

  ['operation', 'autotask', 'opt_in', 'scenario', 'levels'].forEach((key) => {
    if (!Array.isArray(obj[key]) || obj[key].length === 0) {
      delete obj[key];
    } else {
      obj[key] = obj[key].map((item) => ({
        ...item,
        label: encodeURIComponent(item.label),
      }));
    }
  });

  ['participated', 'gender', 'created', 'optInRadio'].forEach((key) => {
    if (obj[key] === 'false') {
      delete obj[key];
    }
  });
  if (obj.m === 'and') {
    delete obj.m;
  }
  Object.keys(obj).forEach((key) => {
    if (!obj[key]) {
      delete obj[key];
    }
  });

  return obj;
}

const formatDateForBackend = (date, dateType) => {
  if (dateType === 'start') {
    return moment(date, 'DD/MM/YYYY').toISOString();
  }
  return moment(date, 'DD/MM/YYYY').add(1, 'd').toISOString();
};

export function mapParticipationsFilterForUrl(data) {
  const params = {
    distinct: true,
    q: {
      g: {},
    },
    level_numbers: null,
    interface_ids: null,
  };

  // const formatDateForBackend = date => moment(date, 'DD/MM/YYYY').toISOString();

  if (data.participated && data.participatedFrom) {
    params.q.g.created_at_gteq = formatDateForBackend(data.participatedFrom, 'start');
  }

  if (data.participated && data.participatedTo) {
    params.q.g.created_at_lteq = formatDateForBackend(data.participatedTo, 'end');
  }

  ['phone', 'email'].forEach((key) => {
    if (data[key]) {
      params.q[`lead_${key}_present`] = 't';
    }
  });

  if (data.visuals) {
    params.with_media = true;
  }

  if (data.full_participation) {
    params.q.min_level_reached_at_null = false;
  }

  if (data.prize) {
    const {prize} = data;
    const prizeData = {[`${prize.value}`]: prize.label};
    params.q.scenario_executions_data_contains_json = JSON.stringify(prizeData);
  }

  if (data.without_test) {
    params.q.test_mode_eq = false;
  }

  if (data.participations_only) {
    params.q.min_level_reached_at_null = false;
  }

  if (data.level && data.level.length) {
    params.level_numbers = data.level.map((i) => i.value);
  }

  if (data.interfaces && data.interfaces.length) {
    params.interface_ids = data.interfaces.map((i) => i.value);
  }

  if (data.optInRadio && data.optInRadio === 'no_active') {
    params.q.lead_opt_ins_is_empty_json = true;
  }

  dropdownParticipationsFilters.forEach(({key, filter}) => {
    const selected = data[key];
    if (data[key]) {
      if (Array.isArray(selected)) {
        if (key === 'optIn') {
          map(selected, 'value').forEach((el) => {
            params.q.g[`lead_opt_ins->>${el}_eq`] = true;
            return params;
          });
          params.q.g.m = data.m;
        } else {
          params.q[filter] = map(selected, 'value');
        }
      } else if (selected.value) {
        const filterName = isObject(filter) ? filter[selected.type] : filter;
        params.q[filterName] = selected.value;
      } else if (selected === LeadsFiltersModal.optInValues.noActive) {
        params.q[filter] = true;
      }
    }
  });

  return params;
}

export function mapLeadsFilterForUrl(data) {
  const params = {
    distinct: true,
    q: {
      g: {},
    },
  };

  params.q.gender_eq = genderMap[data.gender] || data.gender;

  if (data.participated && data.participatedFrom) {
    params.q.g.participations_created_at_gteq = formatDateForBackend(data.participatedFrom, 'start');
  }

  if (data.participated && data.participatedTo) {
    params.q.g.participations_created_at_lteq = formatDateForBackend(data.participatedTo, 'end');
  }

  ['phone', 'email'].forEach((key) => {
    if (data[key]) {
      params.q[`${key}_present`] = 't';
    }
  });

  if (data.visuals) {
    params.q.visuals_count_gt = 0;
  }

  if (data.created && data.createdFrom) {
    params.q.g.created_at_gteq = formatDateForBackend(data.createdFrom, 'start');
  }

  if (data.created && data.createdTo) {
    params.q.g.created_at_lteq = formatDateForBackend(data.createdTo, 'end');
  }

  if (data.optInRadio && data.optInRadio === 'no_active') {
    params.q.opt_ins_text_not_cont = true;
  }

  if (data.origin) {
    if (data.origin.type === 'Operation') {
      params.q.lead_first_source_source_id_eq = data.origin.value;
      params.q.source_type_eq = 'Operation';
    } else {
      params.q.lead_histories_source_id_eq = data.origin.value;
    }
  }

  dropdownLeadsFilters.forEach(({key, filter}) => {
    const selected = data[key];
    if (selected) {
      if (Array.isArray(selected)) {
        if (key === 'optIn') {
          map(selected, 'value').forEach((el) => {
            params.q.g[`opt_ins->>${el}_eq`] = true;
            return params;
          });
          params.q.g.m = data.m;
        } else {
          params.q[filter] = map(selected, 'value');
        }
      } else if (data[key].value) {
        params.q[filter] = data[key].value;
      } else if (data[key] === LeadsFiltersModal.optInValues.noActive) {
        params.q[filter] = true;
      }
    }
  });

  return params;
}

export function mapWinnersFilterForUrl(data, prizeTypes) {
  const params = {
    distinct: true,
    q: {
      g: {},
    },
  };

  params.q.lead_gender_eq = genderMap[data.gender] || data.gender;
  if (data.participated && data.participatedFrom) {
    params.q.g.created_at_gteq = formatDateForBackend(data.participatedFrom, 'start');
  }

  if (data.participated && data.participatedTo) {
    params.q.g.created_at_lteq = formatDateForBackend(data.participatedTo, 'end');
  }

  // const {showHidden, showNotHidden} = data;
  // if (showHidden && !showNotHidden) {
  //   params.q.visible_by_client_eq = false;
  // }
  // if (!showHidden && showNotHidden) {
  //   params.q.visible_by_client_eq = true;
  // }

  ['phone', 'email'].forEach((key) => {
    if (data[key]) {
      params.q[`lead_${key}_present`] = 't';
    }
  });

  if (data.visuals) {
    params.q.lead_visuals_count_gteq = 't';
  }

  if (data.prize) {
    const {prize} = data;
    const prizeData = {[`${prize.value}`]: prize.label};
    params.q.scenario_executions_data_contains_json = JSON.stringify(prizeData);
  }

  if (data.without_test) {
    params.q.test_mode_eq = false;
  }

  if (data.participations_only) {
    params.q.min_level_reached_at_null = false;
  }

  if (data.levels && data.levels.length) {
    params.q.interface_level_histories_interface_level_number_in = data.levels.map((i) => i.value);
  }

  if (data.optInRadio && data.optInRadio === 'no_active') {
    params.q.lead_opt_ins_text_not_cont = true;
  }

  if (data.prizes && data.prizes.length) {
    params.q.participation_prizes_name_in = data.prizes.map(({value}) => encodeURI(value));
  }

  if (prizeTypes && prizeTypes.length) {
    const participation_prizes_db_number_in = [];
    const prizes = prizeTypes.reduce((acc, {custom_name, db_number, prize_type_number}) => {
      return {...acc, [custom_name]: prize_type_number || db_number};
    }, {});

    Object.keys(data).forEach((prize) => {
      if (prize in prizes) {
        participation_prizes_db_number_in.push(prizes[prize]);
      }
    });
    if (participation_prizes_db_number_in.length) {
      params.q.participation_prizes_db_number_in = participation_prizes_db_number_in;
    }
  }

  dropdownParticipationsFilters.forEach(({key, filter}) => {
    const selected = data[key];
    if (data[key]) {
      if (Array.isArray(selected)) {
        if (key === 'optIn') {
          map(selected, 'value').forEach((el) => {
            params.q.g[`[lead_opt_ins->>${el}_eq`] = true;
            return params;
          });
          params.q.g.m = data.m;
        } else {
          params.q[filter] = map(selected, 'value');
        }
      } else if (selected.value) {
        const filterName = isObject(filter) ? filter[selected.type] : filter;
        params.q[filterName] = selected.value;
      } else if (selected === LeadsFiltersModal.optInValues.noActive) {
        params.q[filter] = true;
      }
    }
  });
  return params;
}

export const emptyObject = (obj) =>
  typeof obj === 'object' && !isNull(obj) ? !Object.keys(obj).length || !obj.length : false;

export const clearFilters = (filters) => {
  const clearedFilters = {};

  if ('optInRadio' in filters && filters.optInRadio === 'select_optIn') {
    clearedFilters.optInRadio = true;
  }
  Object.keys(filters).forEach((key) => {
    const filter = filters[key];
    if (filter) {
      if (key === 'interfaceItem') {
        if (!isNull(filter) && !emptyObject(filter)) {
          clearedFilters.interface = filter;
        }
      }
      if ((!emptyObject(filter) && filter.value !== '0') || !isEqual(filter[0], DEFAULT_SELECT)) {
        clearedFilters[key] = key === filter ? true : filter;
      }
    }
  });
  return clearedFilters;
};

/**
 * Function for mapping next and prev search params
 * @param {Object} prevProps previous props
 * @param {Object} nextProps next props
 * @returns {Object} object of prev and next params
 */
export const getSearchParams = (prevProps, {pages, searchString, searchField, filters, sortParams}) => {
  let prevParams = {};
  if (prevProps.sortParams) {
    prevParams = {...prevProps.sortParams};
  }
  if (prevProps.pages && prevProps.pages.current) {
    prevParams.page = prevProps.pages.current;
  }
  if (prevProps.pages && prevProps.pages.perPage) {
    prevParams.perPage = prevProps.pages.perPage;
  }
  prevParams.search = prevProps.searchString || '';
  prevParams.searchField = prevProps.searchField || '';

  if (!isEmpty(prevProps.filters)) {
    prevParams.filter = prevProps.filters;
  }

  const nextParams = {
    ...sortParams,
    page: pages.current,
    perPage: pages.perPage,
    search: searchString,
    searchField: searchField,
    filter: filters,
  };

  return {
    prevParams,
    nextParams,
  };
};
