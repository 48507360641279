import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import RadioPanelTab from 'client/common/radio-panel/radio-panel-tab';

import PrizeDiyRatioParticipationFieldset from 'client/components/prizes/fieldsets/prize-diy-ratio-participation-fieldset';

import {PrizeDiyRatioParticipationCheckboxPanelProps} from './type';

import cssModule from './prize-diy-ratio-participation-checkbox-panel.module.scss';

const b = bem('prize-diy-ratio-participation-checkbox-panel', cssModule);

const PrizeDiyRatioParticipationCheckboxPanel: React.FC<PrizeDiyRatioParticipationCheckboxPanelProps> = (props) => {
  const {value, checked, name, disabled, required = false} = props;
  const lang = useLanguage('PRIZES.PANELS.PRIZE_DIY_RATIO_PARTICIPATION_CHECKBOX_PANEL');

  return (
    <RadioPanelTab
      className={b()}
      value={value}
      label={lang.LABEL}
      name={name}
      checked={checked}
      header={<PrizeDiyRatioParticipationFieldset disabled={!checked || disabled} required={required} />}
      disabled={disabled}
    />
  );
};

export default PrizeDiyRatioParticipationCheckboxPanel;
