import React from 'react';

import PropTypes from 'prop-types';

import {COLUMN_TYPES} from 'client/common/config';

import Grid from 'client/components/common/grid';

function VarItem(props) {
  const {name, value, kind} = props;

  return (
    <Grid.Row className="main-text">
      <Grid.Col size="6">
        <div className="ellipsis-text">{name}</div>
      </Grid.Col>
      <Grid.Col size="6">
        {kind !== COLUMN_TYPES.FILE ? (
          <div className="ellipsis-text">{value}</div>
        ) : (
          <div className="bc-result-vars-modal__img-wrap">
            <img src={value} className="bc-result-vars-modal__img" />
          </div>
        )}
      </Grid.Col>
    </Grid.Row>
  );
}

VarItem.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  kind: PropTypes.string,
};

export default VarItem;
