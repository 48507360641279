import React from 'react';

import bem from 'client/services/bem';

import Icon from 'client/components/common/icon';

import cssModule from './visuals-switcher.module.scss';

const b = bem('visuals-switcher', {cssModule});

type VisualsSwitcherProps = {
  isGrid: boolean;
  onIsGridChange: () => void;
};

const VisualsSwitcher: React.FC<VisualsSwitcherProps> = (props) => {
  const {isGrid, onIsGridChange} = props;

  return (
    <div className={b()} onClick={onIsGridChange}>
      <Icon name={isGrid ? 'grid-switcher-purple-left' : 'grid-switcher-purple-right'} />
    </div>
  );
};

export default VisualsSwitcher;
