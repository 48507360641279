import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import moment from 'moment';
import {createSelector} from 'reselect';

import {calculateAge} from 'client/services/helpers';

import {TIME_UNITS, COLUMN_TYPES, GENDER_TYPES, LINK_KINDS, MESSAGE_TASK_STATES} from 'client/common/config';

import {TASK_TYPES} from 'client/models/operations/constants';

export function selectMessageTask(state) {
  return state.messageEmailSmsTask.messageTask;
}

export function selectMessageTasks(state) {
  return state.messageEmailSmsTask.messageTasks;
}

export function selectMessages(state) {
  return state.messageEmailSmsTask.messages;
}

export function selectMessageTaskMeta(state) {
  return state.messageEmailSmsTask.meta;
}

export function selectMessagesMeta(state) {
  return state.messageEmailSmsTask.metaRecipients;
}

export function selectClicksStatistic(clicksStatistic = {}) {
  const linkKindsMap = {
    [LINK_KINDS.REGULAR]: 1,
    [LINK_KINDS.UNSUBSCRIBE]: 2,
    [LINK_KINDS.UPDATE_SETTINGS]: 3,
    [LINK_KINDS.MIRROR]: 4,
  };

  const linkArray = Object.keys(clicksStatistic).map((linkName) => {
    return {
      name: linkName,
      count: clicksStatistic[linkName].count,
      percent: clicksStatistic[linkName].percent,
      kind: clicksStatistic[linkName].kind,
      linkOrder: linkKindsMap[clicksStatistic[linkName].kind],
    };
  });

  return sortBy(linkArray, 'linkOrder');
}

export function selectTaskBaseInfo(state) {
  const task = selectMessageTask(state);
  const clicksStatistic = selectClicksStatistic(task.email_template_link_click_statistics);
  const taskState = task.state || '';

  return {
    archived: !!task.archived,
    broadcastName: get(task, 'broadcast_list.name', ''),
    recipientsCount: task.messages_count || 0,
    broadcastList: task.broadcast_list || {},
    broadcastRecipientsCount: get(task, 'broadcast_list.broadcast_recipients_count', 0),
    broadcastId: get(task, 'broadcast_list.id', 0),
    taskName: task.name || '',
    taskId: task.id || 0,
    taskType: task.type || '',
    taskDashboards: task.dashboards || [],
    messageSender: task.message_sender || {},
    messageSenderId: task.message_sender_id || 0,
    timerKind: task.timer_kind,
    timeUnit: task.time_unit,
    timeUnitBetweenBatches: task.time_unit_between_batches,
    timeBetweenBatches: task.time_between_batches,
    sendInBatches: task.send_in_batches,
    performIn: task.perform_in,
    performAt: task.perform_at,
    batchSize: task.batch_size,
    operationName: get(task, 'operation.name', ''),
    operationStatus: get(task, 'operation.status', ''),
    operationDashboards: get(task, 'operation.dashboards', []),
    operationId: get(task, 'operation_id'),
    clientName: get(task, 'client.name', 0),
    clientId: get(task, 'client.id', 0),
    clientType: get(task, 'client.type', ''),
    leadsCount: get(task, 'client.leads_count'),
    emailTemplateId: task.email_template_id || 0,
    emailTemplate: get(task, 'email_template') || {},
    agency: get(task, 'client.agency'),
    sentTo: task.message_queued_events_count || 0,
    sentBy: task.message_sent_events_count || 0,
    delivered: task.message_delivered_events_count || 0, // only for sms task
    open: task.message_open_events_count || 0,
    clicksCount: task.email_template_link_clicks_count || 0, // only for email task
    taskState, // possible values: new, performing, sleeping, finished
    isWebhooksOpened: !!task.open, // possible values: true, false
    clicksStatistic,
    disabledWhilePerforming: taskState === MESSAGE_TASK_STATES.PERFORMING || taskState === MESSAGE_TASK_STATES.FINISHED,
    disabledIfNotNew: taskState !== MESSAGE_TASK_STATES.NEW,
  };
}

export function selectMessageTaskMapped(task) {
  const clicksStatistic = selectClicksStatistic(task.email_template_link_click_statistics);

  return {
    ...task,
    broadcastName: get(task, 'broadcast_list.name') || '',
    broadcastId: task.broadcast_list_id,
    taskName: task.name,
    taskId: task.id,
    taskType: task.type,
    home: task.home,
    dashboards: task.dashboards,
    clientType: get(task, 'operation.client.type') || '',
    clientId: get(task, 'operation.client_id'),
    operationId: task.operation_id,
    clicksStatistic,
    leadsCount: task.messages_count,
    emailTemplate: get(task, 'email_template') || null,
    delivered: task.message_delivered_events_count || 0, // only for sms task
    clicksCount: task.email_template_link_clicks_count || 0, // only for email task
    taskState: task.state, // possible values: new, performing, sleeping, finished
    isWebhooksOpened: task.open, // possible values: true, false
  };
}

export const selectMessageTasksMapped = createSelector(selectMessageTasks, (tasks) => {
  return tasks && tasks.map(selectMessageTaskMapped);
});

export function selectTaskTimer(state, timerKinds) {
  const {
    timer_kind,
    time_unit,
    time_unit_between_batches,
    time_between_batches,
    send_in_batches,
    perform_in,
    perform_at,
    batch_size,
    message_sender_id,
    reply_to_id,
  } = selectMessageTask(state);

  return {
    timer_kind: timer_kind || timerKinds.in.name,
    time_unit: time_unit || TIME_UNITS.minutes.name,
    time_unit_between_batches: time_unit_between_batches || TIME_UNITS.minutes.name,
    time_between_batches,
    send_in_batches,
    perform_in,
    perform_at: perform_at ? moment(perform_at) : null,
    batch_size: batch_size || '',
    message_sender_id,
    reply_to_id,
  };
}

export function selectMappingItem(mappingItem, databasesLocale, variable = {}) {
  const dbType = get(mappingItem, 'column_adapter.record_type');
  const dbNumber = get(mappingItem, 'column_adapter.db_number') || '';
  const dbName = dbType + dbNumber;

  const database = dbType
    ? {
        db: dbName,
        label: databasesLocale[dbName],
      }
    : null;

  const column_adapter = database
    ? {
        id: get(mappingItem, 'column_adapter.id', 0),
        name: get(mappingItem, 'column_adapter.name', ''),
      }
    : null;

  const operation = mappingItem.operation_id
    ? {
        value: mappingItem.operation_id,
      }
    : null;

  return {
    mapping_item_id: mappingItem.id,
    database,
    column_adapter,
    required: variable.required || mappingItem.required,
    operation,
  };
}

export function selectTemplateVariables(state) {
  const task = selectMessageTask(state);
  const variables = get(task, 'email_template.email_template_variables');

  return (
    variables &&
    variables.map((variable) => {
      const mappingItem =
        (variable.email_task_mapping_items &&
          variable.email_task_mapping_items.find((item) => item.message_task_id === task.id)) ||
        {};

      return {
        id: variable.id,
        name: variable.full_name || variable.name,
        array: variable.array,
        required: variable.required,
        mappingItem,
      };
    })
  );
}

export function selectTemplateVariablesInitialForm(state) {
  const variables = selectTemplateVariables(state);
  const databasesLocale = state.languageState.payload.DATABASES;
  return (
    variables &&
    variables.reduce((acc, variable) => {
      const {id, mappingItem} = variable;

      return {
        ...acc,
        [`_${id}`]: selectMappingItem(mappingItem, databasesLocale, variable),
      };
    }, {})
  );
}

export function selectPersonalizeStepStatus(state) {
  const variables = selectTemplateVariables(state);

  return (variables && variables.length > 0 && variables.every((v) => !!v.mappingItem.column_adapter_id)) || false;
}

export function selectColumnOptionsByKinds(columns, currentKind) {
  const kindsMap = {
    [COLUMN_TYPES.BOOLEAN]: [COLUMN_TYPES.BOOLEAN],
    [COLUMN_TYPES.STRING]: [COLUMN_TYPES.STRING, COLUMN_TYPES.TEXT, COLUMN_TYPES.PHONE, COLUMN_TYPES.EMAIL],
    [COLUMN_TYPES.TEXT]: [COLUMN_TYPES.STRING, COLUMN_TYPES.TEXT, COLUMN_TYPES.PHONE, COLUMN_TYPES.EMAIL],
    [COLUMN_TYPES.FILE]: [COLUMN_TYPES.STRING, COLUMN_TYPES.TEXT, COLUMN_TYPES.FILE],
    [COLUMN_TYPES.DECIMAL]: [COLUMN_TYPES.DECIMAL, COLUMN_TYPES.INTEGER],
    [COLUMN_TYPES.INTEGER]: [COLUMN_TYPES.DECIMAL, COLUMN_TYPES.INTEGER],
    [COLUMN_TYPES.DATETIME]: [COLUMN_TYPES.DATETIME],
    [COLUMN_TYPES.EMAIL]: [COLUMN_TYPES.STRING, COLUMN_TYPES.TEXT, COLUMN_TYPES.EMAIL],
    [COLUMN_TYPES.PHONE]: [COLUMN_TYPES.STRING, COLUMN_TYPES.TEXT, COLUMN_TYPES.PHONE],
  };

  return columns.filter((column) => {
    return currentKind && !column.array && kindsMap[currentKind].includes(column.kind);
  });
}

export function selectRecipientLead(lead, langGenders) {
  return {
    lead_first_name: lead.first_name,
    lead_last_name: lead.last_name,
    lead_gender: lead.gender ? langGenders[GENDER_TYPES[lead.gender]] || lead.gender : langGenders.NOT_SPECIFIED,
    birth_date: lead.birth_date && calculateAge(lead.birth_date),
    lead_birth_date: lead.birth_date && calculateAge(lead.birth_date),
    hasEmail: !!lead.email,
    hasPhone: !!lead.phone,
  };
}

export function selectEventData(event = {}) {
  return {
    status: event.successfully,
    date: event.at && moment(event.at).format('DD/MM/YYYY'),
    time: event.at && moment(event.at).format('HH:mm'),
  };
}

export function selectDeliveredEvent(event = {}) {
  const newEvent = {
    ...event,
    successfully: event.successfully === false ? null : event.successfully,
  };

  return selectEventData(newEvent);
}

export function selectVariablesCount(variables) {
  return Object.keys(variables).filter((key) => variables[key].value !== null && String(variables[key].value)).length;
}

export function selectResultsClicksStatistic(clicksStatistic) {
  const linkKindsMap = {
    [LINK_KINDS.REGULAR]: 1,
    [LINK_KINDS.UNSUBSCRIBE]: 2,
    [LINK_KINDS.UPDATE_SETTINGS]: 3,
    [LINK_KINDS.MIRROR]: 4,
  };

  const clicksArray = clicksStatistic.map((linkClick) => {
    const linkKind = get(linkClick, 'email_template_link.kind');

    return {
      clickId: linkClick.id,
      linkId: get(linkClick, 'email_template_link.id'),
      linkName: get(linkClick, 'email_template_link.name'),
      linkKind,
      linkOrder: linkKind && linkKindsMap[linkKind],
      clicksTime: linkClick.created_at ? moment(linkClick.created_at).format('DD/MM/YYYY, HH:mm') : '',
    };
  });

  return sortBy(clicksArray, ['linkOrder', 'linkId', 'clickId']);
}

export function selectMergedVariables(variables) {
  const KEY = 0;
  const VALUE = 1;

  return Object.entries(variables).map((variable) => {
    return {
      name: variable[KEY],
      value: variable[VALUE].value !== null ? String(variable[VALUE].value) : '',
      kind: variable[VALUE].kind,
    };
  });
}

export function selectResultTableDataMapping(state, taskType) {
  const recipients = selectMessages(state);
  const LANG_GENDERS = state.languageState.payload.GENDERS;
  const isSmsTask = taskType === TASK_TYPES.SMS;

  return recipients.map((recipient) => {
    const {events, email_template_link_clicks_count, unsubscribed, variables, email_template_link_clicks} = recipient;

    const lead = recipient.lead || {};

    const variables_count = isSmsTask
      ? get(recipient, 'message_task.message_task_mapping_items', []).length
      : selectVariablesCount(variables);

    const vars = isSmsTask ? get(recipient, 'message_task.message', '') : selectMergedVariables(variables);

    return {
      ...selectRecipientLead(lead, LANG_GENDERS),
      delivered_event: selectDeliveredEvent(events.delivered),
      queued_event: selectEventData(events.queued),
      sent_event: selectEventData(events.sent),
      open_event: selectDeliveredEvent(events.open),
      variables: vars,
      variables_count,
      clicksStatistic: selectResultsClicksStatistic(email_template_link_clicks),
      clicks_count: email_template_link_clicks_count,
      unsubscribed,
    };
  });
}

export function selectSmsPersonalizeStepStatus(state) {
  const mappingItems = selectMessageMappingItems(state);
  const countVarsFromMessage = selectSmsVarsFromMessage(state).length;

  return (
    mappingItems.length > 0 &&
    mappingItems.length === countVarsFromMessage &&
    mappingItems.every((i) => i.column_adapter_id)
  );
}

export function selectStepsStatuses(state) {
  const task = selectTaskBaseInfo(state);
  const personalizeStatus = selectPersonalizeStepStatus(state);
  const personalizeSmsStatus = selectSmsPersonalizeStepStatus(state);

  return {
    mailingChecked: task.broadcastList && !!task.broadcastList.broadcast_recipients_count,
    templateChecked: task.taskType === TASK_TYPES.SMS ? !!selectMessageText(state).length : !!task.emailTemplateId,
    settingsChecked: !!task.messageSenderId,
    personalizeChecked: task.taskType === TASK_TYPES.SMS ? personalizeSmsStatus : personalizeStatus,
  };
}

export function selectBroadcastReadyState(stepsStatuses) {
  return Object.keys(stepsStatuses).every((key) => stepsStatuses[key]);
}

export function selectMessageMappingItems(state) {
  const task = selectMessageTask(state);
  return task.message_task_mapping_items || [];
}

export function selectMessageText(state) {
  const task = selectMessageTask(state);
  return task.message || '';
}

export function selectBroadcastInfoMessage(taskState, isReadyToStart, language) {
  const textMap = {
    [MESSAGE_TASK_STATES.NEW]: language.NEW,
    [MESSAGE_TASK_STATES.PERFORMING]: language.PERFORMING,
    [MESSAGE_TASK_STATES.SLEEPING]: language.SLEEPING,
    [MESSAGE_TASK_STATES.FINISHED]: language.FINISHED,
  };

  switch (true) {
    case taskState && taskState !== MESSAGE_TASK_STATES.NEW:
    case taskState && taskState === MESSAGE_TASK_STATES.NEW && isReadyToStart:
      return textMap[taskState];
    case taskState && taskState === MESSAGE_TASK_STATES.NEW && !isReadyToStart:
      return language.DEFAULT;
    default:
      return '';
  }
}

export function selectSmsVarsFromMessage(state) {
  const message = selectMessageText(state);
  const regex = /{{([^{}]+)}}/g;
  const VAR_NAME = 1;
  const variables = [];
  let match;

  do {
    match = regex.exec(message);
    if (match) {
      variables.push(match[VAR_NAME]);
    }
  } while (match);

  return uniq(variables).map((v) => {
    return {
      id: v,
      name: v,
      array: false,
    };
  });
}

export function selectMessageVariablesInitialForm(state) {
  const mappingItems = selectMessageMappingItems(state);
  const databasesLocale = state.languageState.payload.DATABASES;
  return mappingItems.reduce((acc, mappingItem) => {
    return {
      ...acc,
      [`_${mappingItem.name}`]: selectMappingItem(mappingItem, databasesLocale),
    };
  }, {});
}

export function selectMappedMessageVariablesByName(state) {
  const items = selectMessageMappingItems(state);

  return items.reduce((acc, item) => {
    return {
      ...acc,
      [item.name]: item.id,
    };
  }, {});
}
