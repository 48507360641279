import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import {Operation} from 'client/models/operations/types';
import {ApiAction} from 'client/types';

import types from './types';

export * from './actions-legacy';

export const createOperation: ApiAction = (body) => (dispatch) =>
  callApi(
    dispatch,
    {
      endpoint: `${API_PATH}${API_METHODS.OPERATIONS}`,
      method: 'POST',
      types: [types.CREATE_OPERATION_REQUEST, types.CREATE_OPERATION_SUCCESS, types.CREATE_OPERATION_ERROR],
      body,
    },
    true,
  );

export const updateOperation: ApiAction<{operation: Operation}> = (id, body) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.OPERATIONS}/${id}`,
        method: 'PATCH',
        types: [types.UPDATE_OPERATION_REQUEST, types.UPDATE_OPERATION_SUCCESS, types.UPDATE_OPERATION_ERROR],
        body,
        noCache: true,
      },
      true,
    );
};
