export default function (values) {
  return {
    surface: values.surface,
    electric_plug: values.electric_plug,
    dock: values.dock,
    stock: values.stock,
    log_conditions: values.log_conditions,
    comments: values.comments,
  };
}
