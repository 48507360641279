import {RSAA} from 'redux-api-middleware';

import {API_PATH, API_METHODS} from 'client/common/config';

export const AGENCY_USERS_LIST_NO_DATA = 'AGENCY_USERS_LIST_NO_DATA';
export const AGENCY_USERS_LIST_LOADING = 'AGENCY_USERS_LIST_LOADING';
export const AGENCY_USERS_LIST_LOADED = 'AGENCY_USERS_LIST_LOADED';
export const AGENCY_USERS_LIST_LOAD_FAILED = 'AGENCY_USERS_LIST_LOAD_FAILED';
// agencyId = 26
export function getAgencyUsers(agencyId) {
  return (dispatch) => {
    const joins = `include=client_user&q[client_id_eq]=${agencyId}`;
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.AGENCY_MEMBERSHIPS}?${joins}`,
        method: 'GET',
        isAuth: true,
        types: [AGENCY_USERS_LIST_LOADING, AGENCY_USERS_LIST_LOADED, AGENCY_USERS_LIST_LOAD_FAILED],
      },
    });
  };
}
