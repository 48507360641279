import {DEVICE_SEGMENTATION, ONLINE_SEGMENTATION} from 'client/components/lottery/constants';
import {Prize} from 'client/models/prizes/types';

import {DrawParametersValues} from './types';

export default (values: DrawParametersValues, autotaskId: number, prizes?: Prize[]) => {
  const mappedPrizes = prizes
    ? {
        prizes: prizes.map((prize) => ({
          id: prize.id,
          geo_level: values.device_segmentation,
          online_level: values.online_segmentation,
        })),
      }
    : {};

  return {
    game: {
      name: values.name,
      game_type: 'prize_draw',
      win_number: 2,
      is_for_client: true,
      default_from: values.from,
      default_to: values.to,
      default_draw_time: values.draw_moment,
      geo_level: values.device_segmentation || DEVICE_SEGMENTATION.NONE,
      online_level: values.online_segmentation || ONLINE_SEGMENTATION.NONE,
      automation_task_id: autotaskId,
      game_draws: [
        {
          id: values.draw_id,
          name: values.name,
          default_from: values.from,
          default_to: values.to,
          default_draw_time: values.draw_moment,
          loss_email: values.send_email,
          loss_sms: values.send_sms,
        },
      ],
      ...mappedPrizes,
    },
  };
};
