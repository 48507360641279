import {ClientUserModalForm} from 'client/components/client-account-parameters/modals/client-user-modal/types';
import {Membership} from 'client/models/memberships/types';
import {USER_CIVILITY} from 'client/models/users/constants';

export default (values?: Membership | null): Partial<ClientUserModalForm> => {
  const user = values?.client_user;

  if (!user) {
    return {
      locale: 'fr',
      title: USER_CIVILITY.MALE,
      memberships: [{}],
    };
  }

  return {
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    locale: user.locale,
    title: user.title,
    memberships: [
      {
        ...values,
        company_accesses: values.company_accesses?.map(({company_id}) => company_id?.toString()) || [],
        region_accesses: values.region_accesses?.map(({region_id}) => region_id?.toString()) || [],
        place_accesses: values.place_accesses?.map(({place_id}) => place_id?.toString()) || [],
      },
    ],
  };
};
