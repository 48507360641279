import {sourcesMapper} from './mappers';
import types from './types';

const initialState = {
  common: {
    participations_statistic: {
      leads: {
        new_leads: 0,
        old_leads: 0,
      },
      average_duration: 0,
      conversation_rate: 0,
      opt_ins_rate: 0,
      visits: {
        total: 0,
        interfaces: [],
      },
      participations: {
        total: 0,
        interfaces: [],
      },
      opt_ins: {
        total: 0,
        total_structural: 0,
        opt_in_by_types: [],
        rate: 0,
      },
    },
  },
  sources: {
    total_direct_and_campaigns_participations: 0,
    total_direct_and_campaigns_visits: 0,
    sources: {
      campaigns: [],
      devices: [],
      direct_access: {
        interfaces: [],
      },
    },
    visitsBySourceType: {
      campaigns: 0,
      deveices: 0,
      sources: [],
      direct_access: 0,
    },
  },
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case types.GET_PARTICIPATIONS_STATISTICS_SUCCESS:
      if (!Object.keys(payload.result).length) {
        return {
          ...state,
          common: initialState.common,
        };
      }
      return {
        ...state,
        common: {
          ...payload.result,
        },
      };

    case types.GET_SOURCES_STATISTICS_SUCCESS:
      return {
        ...state,
        sources: sourcesMapper(payload.result.grouped_participations_statistic),
      };

    default:
      return state;
  }
};
