import {useCallback, useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import {
  getInstoreDashboardBlocks,
  removeInstoreDashboardBlock,
  updateInstoreDashboard,
} from 'client/ducks/instore-dashboards/actions';
import {selectInstoreDashboardBlocks} from 'client/ducks/instore-dashboards/selectors';

const useFetchDashboardBlocks = ({dashboardId, campaignId}, fetchOnMount = true) => {
  const [loading, toggleLoading] = useToggle(false);
  const dispatch = useDispatch();
  const dashboardBlocks = useSelector(selectInstoreDashboardBlocks);

  const fetch = useCallback(() => {
    if (dashboardId) {
      const queryParams = {
        include: {
          task_kpis: null,
          task_kpi_dashboard_blocks: null,
        },
        q: {
          instore_dashboard_id_eq: dashboardId,
        },
        include_instore_dashboard_block_data: true,
      };
      if (Number(campaignId)) {
        queryParams.interaction_group_id = campaignId;
      }
      toggleLoading();
      return dispatch(getInstoreDashboardBlocks(queryParams)).finally(toggleLoading);
    }
    return Promise.resolve();
  }, [dashboardId, toggleLoading, dispatch, campaignId]);

  const updateBlocksOrders = useCallback(
    (nextBlocks) => {
      if (dashboardId) {
        const body = {
          instore_dashboard: {
            id: dashboardId,
            dashboard_blocks: nextBlocks.map(({id}, index) => ({
              id,
              position: index,
            })),
          },
        };

        return dispatch(updateInstoreDashboard(dashboardId, body));
      }

      return Promise.resolve;
    },
    [dashboardId, dispatch],
  );

  const removeBlock = useCallback(
    (block) => {
      if (block.id) {
        return dispatch(removeInstoreDashboardBlock(block.id));
      }

      return Promise.resolve;
    },
    [dispatch],
  );

  useEffect(() => {
    if (fetchOnMount) {
      fetch();
    }
  }, [fetch, fetchOnMount]);

  return {
    // TODO: filter should be work on backend side
    blocks: dashboardBlocks?.filter((block) => !!block.task_kpis?.length) || [],
    loading,
    fetch,
    updateBlocksOrders,
    removeBlock,
  };
};

export default useFetchDashboardBlocks;
