import {PRIZE_BARCODE_FORMAT, PRIZE_CODE_TYPES} from 'client/components/prizes/constants';
import {validNameExt} from 'client/components/prizes/helpers';

export default (values, {lang, isCommon, defaultLanguage, barcodeFormat}) => {
  const errors = {};

  const hasBarcodeFormat = barcodeFormat && barcodeFormat !== PRIZE_BARCODE_FORMAT.NONE;

  if (!values.email_code_type) {
    errors.email_code_type = lang.REQUIRED;
  } else if (values.email_code_type === PRIZE_CODE_TYPES.BAR_CODE && !hasBarcodeFormat) {
    errors.email_code_type = lang.TYPE_CODE_NEED_FORMAT;
  }

  const verifyByLang = (key) => {
    if (!validNameExt(values[key]?.name)) {
      errors[key] = lang.INCORRECT_IMAGE_FORMAT;
    }
  };

  if (isCommon) {
    verifyByLang(`email_image_${defaultLanguage}`);
  } else {
    for (let key in values) {
      if (key.startsWith('email_image_')) {
        verifyByLang(key);
      }
    }
  }

  return errors;
};
