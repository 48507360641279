import React from 'react';

import PropTypes from 'prop-types';

function ExportedColumn(props) {
  const {name, date, time, clientName} = props;
  return (
    <div>
      <div className="main-text main-text--upper ellipsis-text">{name}</div>
      <div className="main-text main-text--small main-text--color-1">{date}</div>
      <div className="main-text main-text--small main-text--color-1">{time}</div>
      <div className="main-text main-text--small main-text--color-1 ellipsis-text">{clientName}</div>
    </div>
  );
}

ExportedColumn.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
};

export default ExportedColumn;
