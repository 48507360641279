import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {checkRule, date, required, dateIsAfter} from 'client/services/validator';

import {InitialValuesForModalProps} from './types';

type ValidateProps = (
  formValues: InitialValuesForModalProps,
  config: {lang: LangsByPath<['LEADS_PROFILE']>},
) => Record<string, string>;

export default ((formValues, {lang}) => {
  if (!formValues) {
    return {};
  }

  const {filters} = formValues;

  return formValues.participated === 'true'
    ? {
        filters: {
          participatedFrom: checkRule(filters.participatedFrom, [
            required(lang.ERRORS.REQUIRED),
            date(lang.ERRORS.INVALID_DATE_FORMAT),
          ]),
          participatedTo: checkRule(filters.participatedTo, [
            required(lang.ERRORS.REQUIRED),
            date(lang.ERRORS.INVALID_DATE_FORMAT),
            (value: any) =>
              value && filters.participatedFrom && !date('.')(filters.participatedFrom)
                ? dateIsAfter(lang.ERRORS.INVALID_DATE_BORDERS, 'participatedFrom')(value, {
                    participatedFrom: filters.participatedFrom,
                  })
                : null,
          ]),
        },
      }
    : {};
}) as ValidateProps;
