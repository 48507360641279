import React from 'react';

import PropTypes from 'prop-types';

import Icon from 'client/components/common/icon';

function ContactsColumn(props) {
  const {hasPhone, hasEmail} = props;

  return (
    <div className="clearfix theme-color-16 bc-email-steps-data-grid__contacts">
      {hasPhone && (
        <div className="pull-left">
          <Icon name="phone" />
        </div>
      )}
      {hasEmail && (
        <div className="pull-right">
          <Icon name="mail" />
        </div>
      )}
    </div>
  );
}

ContactsColumn.propTypes = {
  hasPhone: PropTypes.bool.isRequired,
  hasEmail: PropTypes.bool.isRequired,
};

export default ContactsColumn;
