import React from 'react';

import {useSelector} from 'react-redux';

import {useLanguage, useReduxFetch, useReduxForm} from 'client/services/hooks';
import {useReduxFormValidation} from 'client/services/hooks/useReduxFormValidation';
import {email} from 'client/services/validator';

import {getMemberships as getMembershipsAction} from 'client/ducks/client-users/actions';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {findUser as findUserAction} from 'client/ducks/user/actions';

import {CLIENT_TYPES} from 'client/common/config';
import {TextField} from 'client/common/fields';

import {ClientUserModalFormName} from 'client/components/client-account-parameters/modals/client-user-modal';
import {Client} from 'client/models/client/types';
import {Membership} from 'client/models/memberships/types';
import {User} from 'client/models/users/types';

type ClientUserEmailStepProps = {
  onCheckEmail: (user: User | null) => void;
  emailExists: boolean;
  client: Client;
};

const ClientUserEmailStep: React.FC<ClientUserEmailStepProps> = ({onCheckEmail, emailExists, client}) => {
  const lang = useLanguage('CLIENT_ACCOUNT_PARAMETERS.MODALS.CLIENT_USER');
  const {change, formValues, errors: formErrors} = useReduxForm(ClientUserModalFormName);
  const currentClient = useSelector(selectCurrentClient);
  const creationAgencyUser = currentClient.type === CLIENT_TYPES.AGENCY && client.id === currentClient.id;
  const creationCompanyUser =
    currentClient.type === CLIENT_TYPES.COMPANY && !!currentClient.agency_id && client.id === currentClient.id;

  const {fetch: findUser, data: userResponse} = useReduxFetch<{user: User} | {error: boolean; status: number}>({
    action: findUserAction,
    actionArgs: {},
    fetchOnMount: false,
  });
  const {fetch: getMemberships} = useReduxFetch<{memberships: Membership[]}>({
    action: getMembershipsAction,
    actionArgs: {},
    fetchOnMount: false,
  });

  const validate = async () => {
    const emailValue = formValues.email;
    const errors: {email?: string} = {};

    if (email('error')(emailValue)) {
      onCheckEmail(null);
    } else if (emailValue) {
      const response = await findUser({
        email: emailValue,
      });
      let user: User | null = null;

      if ('status' in response.payload) {
        if (response.payload.status === 403) {
          errors.email = lang.ERRORS.IS_ADMIN?.toString();
        }
      } else {
        user = response?.payload?.user;
        // Checking that current client doesn't have found user.
        const responseMemberships = await getMemberships({
          q: {
            client_id_eq: client.id,
            client_user_id_eq: user.id,
          },
        });
        if (responseMemberships.payload.memberships?.length) {
          errors.email = lang.ERRORS.EMAIL_IS_USED?.toString();
        }

        // If we create user for agency we check that agency's companies doesn't have found user
        if (creationAgencyUser) {
          const clientsMemberships = await getMemberships({
            q: {
              client_user_id_eq: user.id,
              client_agency_id_eq: client.id,
            },
          });
          if (clientsMemberships.payload.memberships?.length) {
            errors.email = lang.COMPANIES_HAVE_THE_SAME_USER?.toString();
          }
        } else if (creationCompanyUser) {
          // If we create user for company we check that our agency doesn't have found user
          const agencyMemberships = await getMemberships({
            q: {
              client_id_eq: client.agency_id,
              client_user_id_eq: user.id,
            },
          });
          if (agencyMemberships.payload.memberships?.length) {
            errors.email = lang.AGENCY_HAS_THE_SAME_USER?.toString();
          }
        }
      }

      onCheckEmail(user || null);
      change('title', user?.title);
      change('last_name', user?.last_name);
      change('first_name', user?.first_name);
    } else {
      onCheckEmail(null);
    }

    return errors;
  };

  useReduxFormValidation(
    {
      formName: ClientUserModalFormName,
      validateAsync: () => validate(),
    },
    [formValues.email],
  );

  let warning = emailExists ? lang.EMAIL_EXIST_WARNING : '';
  if (!formErrors.email && userResponse && 'user' in userResponse && userResponse?.user?.blocked) {
    warning = lang.USER_DEACTIVATED_WARNING;
  }

  return (
    <div>
      <TextField required label={lang.EMAIL_LABEL} name="email" warningMessage={warning} />
    </div>
  );
};

export default ClientUserEmailStep;
