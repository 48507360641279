import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectAutotaskTypes} from 'client/ducks/autotask/selectors';
import {selectOperation} from 'client/ducks/operations/selectors';

import {NumberField, SelectField, ToggleField} from 'client/common/fields';

import {SEGMENTATION_TYPES} from 'client/components/prizes/constants';
import {PrizeDiyModalFormName} from 'client/components/prizes/modals/prize-diy-modal';

import {isAnyLevelRequired} from './validate';

import './prize-diy-segmentation-fieldset.scss';

const b = bem('prize-diy-segmentation-fieldset');

const PrizeDiySegmentationFieldset = (props) => {
  const {hasWins, disabled} = props;
  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_SEGMENTATION_FIELDSET');
  const {
    formValues: {segmentation: segmentationFormValues = {}},
  } = useReduxForm(PrizeDiyModalFormName);
  const {isDevicesType, isOnlineType} = useSelector(selectAutotaskTypes);
  const {devices_count, online_interactions_count} = useSelector(selectOperation);

  const segmentationOptions = useMemo(
    () => [
      {
        label: lang.SEGMENTATION_SELECT_LABELS.PER_CHANNEL,
        value: SEGMENTATION_TYPES.PER_CHANNEL,
        disabled: online_interactions_count < 1,
      },
      {
        label: lang.SEGMENTATION_SELECT_LABELS.PER_CAMPAIGN,
        value: SEGMENTATION_TYPES.PER_CAMPAIGN,
      },
      {
        label: lang.SEGMENTATION_SELECT_LABELS.NONE,
        value: SEGMENTATION_TYPES.NONE,
      },
    ],
    [lang, online_interactions_count],
  );

  const onlineSegmentationTitles = useMemo(
    () => ({
      [SEGMENTATION_TYPES.PER_CAMPAIGN]: lang.PER_CAMPAIGN_DOTATION,
      [SEGMENTATION_TYPES.PER_CHANNEL]: lang.PER_CHANNEL_DOTATION,
      [SEGMENTATION_TYPES.NONE]: lang.TOTAL_DOTATION,
    }),
    [lang],
  );

  const isDeviceAndOnlineType = isDevicesType && isOnlineType;
  const isDeviceSegmentationVisible = isDeviceAndOnlineType
    ? segmentationFormValues.device_online_segmentation
    : isDevicesType;
  const isOnlineSegmentationVisible = isDeviceAndOnlineType
    ? segmentationFormValues.device_online_segmentation
    : isOnlineType;
  const isTotalDotationVisible = isDeviceAndOnlineType && !segmentationFormValues.device_online_segmentation;

  const isDeviceLevelSet = segmentationFormValues.geo_level;
  const isOnlineLevelSet = segmentationFormValues.online_level !== SEGMENTATION_TYPES.NONE;

  const isAnyLevelReq = isAnyLevelRequired(segmentationFormValues);
  const isDeviceReq =
    !isDeviceAndOnlineType || isDeviceLevelSet || (isAnyLevelReq && !segmentationFormValues.online_stock);
  const isOnlineReq =
    !isDeviceAndOnlineType || isOnlineLevelSet || (isAnyLevelReq && !segmentationFormValues.device_stock);

  const isMainToggleDisabled =
    segmentationFormValues.device_online_segmentation && (isDeviceLevelSet || isOnlineLevelSet);

  return (
    <div className={b()}>
      {isDeviceAndOnlineType && (
        <ToggleField
          name="segmentation.device_online_segmentation"
          label={lang.DEVICES_ONLINE_SEGMENTATION}
          borderColor="current"
          backgroundColor="current"
          className={b('main-toggle')}
          rounded
          disabled={hasWins || isMainToggleDisabled || disabled}
        />
      )}
      {isDeviceSegmentationVisible && (
        <div className={b('box', {sibling: isOnlineType})}>
          {isOnlineType && <p className={b('box-title')}>{lang.DEVICES}</p>}
          <ToggleField
            name="segmentation.geo_level"
            label={lang.DEVICE_SEGMENTATION}
            borderColor="current"
            backgroundColor="current"
            rounded
            disabled={hasWins || disabled || devices_count < 1}
          />

          <NumberField
            name="segmentation.device_stock"
            inputClassName={b('field', ['number'])}
            label={isDeviceLevelSet ? lang.DEVICE_DOTATION : lang.TOTAL_DOTATION}
            labelPosition="left"
            disabled={disabled}
            required={isDeviceReq}
            inputHeight="small"
          />
        </div>
      )}
      {isOnlineSegmentationVisible && (
        <div className={b('box', {sibling: isDevicesType})}>
          {isDevicesType && <p className={b('box-title')}>{lang.ONLINE}</p>}

          <div className={b('row')}>
            <span>{lang.SEGMENTATION}</span>
            <SelectField
              name="segmentation.online_level"
              className={b('field', ['select'])}
              options={segmentationOptions}
              disabled={hasWins || disabled}
              isOptionDisabled={(option) => option.disabled}
              simpleValue={true}
              inputHeight="small"
            />
          </div>

          <NumberField
            name="segmentation.online_stock"
            inputClassName={b('field', ['number'])}
            label={onlineSegmentationTitles[segmentationFormValues.online_level]}
            labelPosition="left"
            disabled={disabled}
            required={isOnlineReq}
            inputHeight="small"
          />
        </div>
      )}

      {isTotalDotationVisible && (
        <div className={b('box', ['sibling'])}>
          <p className={b('box-title')}>{lang.GLOBAL}</p>
          <NumberField
            name="segmentation.default_initial_stock"
            inputClassName={b('field', ['number'])}
            label={lang.TOTAL_DOTATION}
            labelPosition="left"
            disabled={disabled}
            required={true}
            inputHeight="small"
          />
        </div>
      )}
    </div>
  );
};

PrizeDiySegmentationFieldset.propTypes = {
  hasWins: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default PrizeDiySegmentationFieldset;
