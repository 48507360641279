import React, {useEffect, useMemo, useRef, useState} from 'react';

import {useToggle} from 'react-use';

import {useEditor} from './useEditor';

import {getElementNextToHovered} from '../helpers';

type Options = {
  allowedTypes: string[];
  placeholderId: string;
};
export const useSortableItems = <T extends {id: string}>(sourceItems: T[], {allowedTypes, placeholderId}: Options) => {
  const {dndData} = useEditor();
  const [draggingElId, setDraggingElId] = useState<string | null>(null);
  const [hoveredElId, setHoveredElId] = useState<string | null>(null);
  const [isDragOver, setIsDragOver] = useToggle(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const sortableItems = useMemo(() => {
    const elements: (T | {id: string})[] = Array.isArray(sourceItems) ? [...sourceItems] : [];

    const draggingEl = elements.find((el) => el.id === draggingElId);
    const hoveredEl = elements.find((el) => el.id === hoveredElId);
    const placeholder = {id: placeholderId};
    const element = draggingElId ? draggingEl || {id: draggingElId} : placeholder;

    if (!isDragOver) {
      return elements;
    }
    if (draggingEl) {
      elements.splice(elements.indexOf(draggingEl), 1);
    }
    if (hoveredEl) {
      elements.splice(elements.indexOf(hoveredEl), 0, element);
    } else {
      elements.push(element);
    }

    return elements;
  }, [sourceItems, placeholderId, draggingElId, isDragOver, hoveredElId]);

  useEffect(() => {
    if (dndData?.type && allowedTypes.includes(dndData?.type)) {
      setDraggingElId(dndData?.id || null);
    }
  }, [allowedTypes, dndData]);

  const onDragEnter = () => {
    if (dndData?.type && allowedTypes.includes(dndData?.type)) {
      setIsDragOver(true);
    }
  };

  const onDragOver = (e: React.DragEvent) => {
    if (!dndData?.type || !allowedTypes.includes(dndData?.type)) {
      return;
    }

    e.preventDefault();

    const draggingElIdOrPlaceholder = dndData?.id || placeholderId;
    const draggingEl = containerRef.current?.querySelector(`[data-draggable-id="${draggingElIdOrPlaceholder}"]`);

    if (draggingEl && containerRef.current) {
      const hoveredEl = getElementNextToHovered(containerRef.current, e.clientY, draggingEl);
      const currentHoveredElId = hoveredEl?.getAttribute('data-draggable-id') || null;

      if (hoveredElId !== currentHoveredElId) {
        setHoveredElId(currentHoveredElId);
      }
    }
  };

  const onDragLeave = (e: React.DragEvent) => {
    if (e.currentTarget instanceof HTMLElement && e.relatedTarget instanceof HTMLElement) {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        setIsDragOver(false);
        setHoveredElId(null);
      }
    }
  };

  const onDrop = () => {
    if (!dndData?.type || !allowedTypes.includes(dndData.type)) {
      return;
    }

    setIsDragOver(false);
  };

  return {
    sortableItems,
    hoveredElId,
    isDragOver,
    containerRef,
    onDragEnter,
    onDragOver,
    onDragLeave,
    onDrop,
  };
};
