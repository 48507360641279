import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';

import Spinner from 'client/components/common/spinner';

import {Visuals} from 'client/models/visuals/types';

import VisualCard from './visual-card';

import cssModule from './visuals-grid.module.scss';

const b = bem('visuals-grid', {cssModule});

type VisualsGridProps = {
  visuals: Visuals[];
  gridIncrement: number;
  onTogglePreviewModal: () => void;
  isLoading: boolean;
  className?: string;
};

const VisualsGrid: React.FC<VisualsGridProps> = (props) => {
  const {visuals, gridIncrement, onTogglePreviewModal, isLoading, className} = props;

  const lang = useLanguage('CLIENT_VISUALS');

  if (isLoading) {
    return <Spinner color="visuals" centered />;
  }

  return (
    <div className={cn(b(), className)}>
      {visuals.map((visual) => (
        <VisualCard
          key={visual.id}
          visual={visual}
          gridIncrement={gridIncrement}
          onTogglePreviewModal={onTogglePreviewModal}
        />
      ))}
      {!visuals.length && (
        <div className={b('no-data-wrap')}>
          <Icon name="table-no-data" className={b('empty-icon')} />
          <div className={b('no-data')}>{lang.NO_DATA}</div>
        </div>
      )}
    </div>
  );
};

export default VisualsGrid;
