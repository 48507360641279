import React from 'react';

import get from 'lodash/get';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {addressTransform} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import {selectCurrentLanguage} from 'client/ducks/language/selectors';

import AppButton from 'client/common/buttons';
import Popover from 'client/common/popovers/popover';

import {Client} from 'client/models/client/types';

import AccountCard from '../account-card';

import './account-basic-info.scss';

const b = bem('account-basic-info');

type AccountBasicInfoProps = {
  client: Client;
  onEditClick: () => void;
  allowEditClient: boolean;
};

const AccountBasicInfo: React.FC<AccountBasicInfoProps> = (props) => {
  const {client, onEditClick, allowEditClient} = props;
  const lang = useLanguage('ACCOUNT_PARAMETERS.BASIC_INFO');
  const currentLanguage = useSelector(selectCurrentLanguage);

  const logoUrl = get(client, 'logo_url') || get(client, 'agency.logo_url') || get(client, 'subsidiary.logo.url');

  const nameField = `client_user_full_name_with_title_${currentLanguage}` as const;
  const contact = client.poc_membership?.[nameField];

  return (
    <AccountCard className={b()}>
      {logoUrl && (
        <div className={b('logo')}>
          <img className={b('logo-img')} src={logoUrl} />
        </div>
      )}
      {allowEditClient && (
        <Popover overlay={lang.EDIT_CLIENT} triggerClassName={b('edit-client')}>
          <AppButton iconName="edit" asWrap className={b('edit-client-button')} onClick={onEditClick} />
        </Popover>
      )}
      <div className={b('item')}>
        <div className={b('item-name')}>{lang.CLIENT}</div>
        <div className={b('item-value')}>{client.name}</div>
      </div>
      <div className={b('item')}>
        <div className={b('item-name')}>{lang.BRAND}</div>
        <div className={b('item-value')}>{client.brand_name}</div>
      </div>
      {contact && (
        <div className={b('item')}>
          <div className={b('item-name')}>{lang.CONTACT}</div>
          <div className={b('item-value')}>{contact}</div>
        </div>
      )}
      <div className={b('item')}>
        <div className={b('item-name')}>{lang.ADDRESS}</div>
        <div className={b('item-value')}>
          {addressTransform
            .splitAddress(client.street_address || '')
            .filter((address) => !!address)
            .map((address) => (
              <div>{address}</div>
            ))}
          <div>
            {client.zip} {client.city}
          </div>
          <div>{client.country}</div>
        </div>
      </div>
    </AccountCard>
  );
};

export default AccountBasicInfo;
