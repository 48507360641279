import {callApi} from 'client/services/call-api';
import {getEmail, getToken} from 'client/services/cookie-data-source';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getLead(id, queryParams = {}) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        queryParams,
        endpoint: `${API_PATH}${API_METHODS.LEADS}/${id + API_METHODS.PROFILE}`,
        method: 'GET',
        types: [types.LEAD_PROFILE_REQUEST, types.LEAD_PROFILE_SUCCESS, types.LEAD_PROFILE_ERROR],
      },
      true,
    );
  };
}

export function editLeadInfo(id, body) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEADS}/${id}`,
        method: 'PUT',
        types: [types.EDIT_LEAD_INFO_REQUEST, types.EDIT_LEAD_INFO_SUCCESS, types.EDIT_LEAD_INFO_ERROR],
        body,
      },
      true,
    );
  };
}

export function updateLeadHistories(body, userId) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        // queryParams: params,
        endpoint: `${API_PATH}${API_METHODS.USERS}/${userId}`,
        method: 'PUT',
        types: [types.UPDATING, types.UPDATED, types.UPDATE_FAILED],
        body,
      },
      true,
    );
  };
}

export function deleteLead(id) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEADS}/${id}`,
        method: 'DELETE',
        types: [types.DELETE_LEAD_PROFILE_REQUEST, types.DELETE_LEAD_PROFILE_SUCCESS, types.DELETE_LEAD_PROFILE_ERROR],
      },
      true,
    );
  };
}

export function anonymizeLead(id) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEADS}/${id}/anonymize`,
        method: 'PATCH',
        types: [
          types.ANONYMIZE_LEAD_PROFILE_REQUEST,
          types.ANONYMIZE_LEAD_PROFILE_SUCCESS,
          types.ANONYMIZE_LEAD_PROFILE_ERROR,
        ],
      },
      true,
    );
  };
}

export function leadsGenerateImportFile(body) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.FILE_LEAD_IMPORTS}`,
        method: 'POST',
        types: [types.LEAD_GENERATE_FILE_REQUEST, types.LEAD_GENERATE_FILE_SUCCESS, types.LEAD_GENERATE_FILE_ERROR],
        body,
      },
      true,
    );
  };
}

export function getImportFile(id) {
  return (dispatch) => {
    dispatch({
      type: types.GET_IMPORT_FILE_REQUEST,
    });
    return fetch(`${API_PATH}${API_METHODS.FILE_LEAD_IMPORTS}/${id}${API_METHODS.TEMPLATE}`, {
      method: 'GET',
      headers: {
        'X-User-Email': getEmail(),
        'X-User-Token': getToken(),
      },
    })
      .then((payload) => {
        dispatch({
          type: types.GET_IMPORT_FILE_SUCCESS,
          payload,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_IMPORT_FILE_ERROR,
          payload: error,
        });
      });
  };
}

export function leadsImportFile(body) {
  return (dispatch) => {
    dispatch({
      type: types.IMPORT_FILE_REQUEST,
    });
    return fetch(`${API_PATH}${API_METHODS.FILE_LEAD_IMPORTS}${API_METHODS.PREFORM}`, {
      method: 'POST',
      headers: {
        'X-User-Email': getEmail(),
        'X-User-Token': getToken(),
      },
      body,
    })
      .then((res) => res.json())
      .then((payload) => {
        dispatch({
          type: types.IMPORT_FILE_SUCCESS,
          payload,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.IMPORT_FILE_ERROR,
          payload: error,
        });
      });
  };
}

export function leadsImportApi(body) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.API_LEAD_IMPORTS}`,
        method: 'POST',
        types: [types.LEAD_GENERATE_API_REQUEST, types.LEAD_GENERATE_API_SUCCESS, types.LEAD_GENERATE_API_ERROR],
        body,
      },
      true,
    );
  };
}

export function getLeadsReports(queryParams) {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.LEADS_REPORTS}`,
        method: 'GET',
        noCache: true,
        types: [types.GET_LEADS_REPORTS_REQUEST, types.GET_LEADS_REPORTS_SUCCESS, types.GET_LEADS_REPORTS_ERROR],
        queryParams,
      },
      true,
    );
  };
}
