import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import SelectDropdown from 'client/common/selects/select-dropdown';

import {Campaign} from 'client/models/campaign/types';

import cssModule from './instore-campaign-select.module.scss';

const b = bem('instore-campaign-select', {cssModule});

type InstoreCampaignSelectProps = {
  campaigns: Campaign[];
  onChange: () => void;
  value: number;
  showAllOption: boolean;
};

const InstoreCampaignSelect: React.FC<InstoreCampaignSelectProps> = (props) => {
  const {campaigns, onChange, value, showAllOption} = props;
  const lang = useLanguage('INSTORE_TASK.INPUTS.INSTORE_CAMPAIGN_SELECT');

  let options = campaigns.map(({id, name}) => ({value: id, label: name}));

  if (showAllOption) {
    options = [{value: 0, label: lang.All_CAMPAIGNS.toString()}, ...options];
  }

  return (
    <SelectDropdown
      className={b()}
      selectClassName={b('select')}
      labelClassName={b('label')}
      classNames={{control: () => b('control')}}
      label={lang.CAMPAIGN}
      options={options}
      onChange={onChange}
      value={value}
      simpleValue={true}
    />
  );
};

export default InstoreCampaignSelect;
