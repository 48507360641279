import {mapLeadExportReport} from './mappers';
import types from './types';

let init = {
  type: types.NO_DATA,
  exportReport: {},
  apiLeadExports: [],
  exportReports: {
    meta: {},
    items: [],
  },
};

export default function (_state_ = init, action) {
  let state = Object.assign({}, _state_);
  switch (action.type) {
    case types.GET_LEADS_EXPORT_REPORT_SUCCESS:
      state.type = types.GET_LEADS_EXPORT_REPORT_SUCCESS;
      state.exportReport = action.payload.lead_export_report;
      return state;

    case types.GET_API_LEAD_EXPORTS_SUCCESS:
      state.type = types.GET_API_LEAD_EXPORTS_SUCCESS;
      state.apiLeadExports = action.payload.api_lead_exports;
      return state;

    case types.GET_LEADS_EXPORT_REPORTS_SUCCESS:
      state.type = types.GET_LEADS_EXPORT_REPORTS_SUCCESS;
      state.exportReports = {
        meta: action.payload.meta,
        items: action.payload.lead_export_reports.map(mapLeadExportReport),
      };
      return state;

    default:
      return state;
  }
}
