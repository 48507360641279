import mapCustomListFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-custom-list-fieldset/mapFormValues';
import {INSTANT_WIN_TABS} from 'client/components/prizes/fieldsets/prize-diy-instant-win-fieldset/constants';
import mapRatioParticipationFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-ratio-participation-fieldset/mapFormValues';
import mapRatioTimeFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-ratio-time-fieldset/mapFormValues';
import {SCHEDULE_TABS} from 'client/components/prizes/fieldsets/prize-diy-schedule-fieldset/constants';
import mapStockInformationValues from 'client/components/prizes/fieldsets/prize-diy-stock-information/mapFormValues';
import mapGeneralFieldsetValues from 'client/components/prizes/fieldsets/prizemap-customization-general-fieldset/mapFormValues';

export default (values: any) => {
  const {instant_win_configuration} = values;
  const {type, schedule_type} = instant_win_configuration || {};

  return {
    prize_map: {
      ...mapGeneralFieldsetValues(values),
      ...mapStockInformationValues(values),

      ...(type === INSTANT_WIN_TABS.RATIO_TIME ? mapRatioTimeFieldsetValues(values) : {}),
      ...(type === INSTANT_WIN_TABS.RATIO_PARTICIPATION ? mapRatioParticipationFieldsetValues(values) : {}),
      ...(schedule_type === SCHEDULE_TABS.CUSTOM_LIST ? mapCustomListFieldsetValues(values, true) : {}),
    },
  };
};
