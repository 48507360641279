import React from 'react';

import PropTypes from 'prop-types';

function OptinsColumn(props) {
  const {codes} = props;

  return (
    <div>
      {codes.map((code, index) => {
        return (
          <div key={index} className="break-word uppercase">
            {code}
          </div>
        );
      })}
    </div>
  );
}

OptinsColumn.propTypes = {
  codes: PropTypes.array.isRequired,
};

export default OptinsColumn;
