import React, {useState, useCallback, useEffect} from 'react';

import cn from 'classnames';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';
import Spinner from 'client/components/common/spinner';

import {NEW_REGION_ID_PREFIX} from 'client/components/client-agency/components/cards/regions-card/helpers';

import Region from './region';

import './regions-list.scss';

const b = bem('regions-list');

const RegionsList = (props) => {
  const {editMode, data, setIsEditMode, onSaveClick, isLoading} = props;

  const lang = useLanguage('REGIONS');

  const [regions, setRegions] = useState(data);

  useEffect(() => {
    setRegions(data);
  }, [data]);

  const updateRegions = useCallback(({id, name, destroy = false}) => {
    setRegions((prevValue) => {
      const updatedRegions = prevValue.map((region) => {
        if (region.id === id) {
          return {...region, name, _destroy: destroy};
        }

        return region;
      });

      return updatedRegions;
    });
  }, []);

  const handleSaveClick = () => {
    onSaveClick(regions);
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
    setRegions(data);
  };

  const handleAddClick = () => {
    setIsEditMode(true);
    setRegions((prevValue) => [...prevValue, {name: '', id: uniqueId(NEW_REGION_ID_PREFIX)}]);
  };

  const size = editMode ? 120 : 200;
  const hasRegions = regions.length > 0;

  if (isLoading) {
    return (
      <div className={b()}>
        <Spinner color="green" className={b('spinner')} />
      </div>
    );
  }

  return (
    <div className={b({edit: editMode})}>
      <div className={b('list')}>
        {hasRegions && (
          <div className={b('list-content')}>
            <CustomScrollbars
              scrollbarProps={{
                autoHeightMin: size,
                autoHeightMax: size,
              }}
            >
              {regions
                .filter((region) => !region._destroy)
                .map((region) => (
                  <Region key={region.id} isEditMode={editMode} region={region} updateRegions={updateRegions} />
                ))}
            </CustomScrollbars>
          </div>
        )}
        {!hasRegions && <span className={b('empty')}>{lang.NO_REGIONS}</span>}
      </div>
      {(!hasRegions || editMode) && (
        <div className={b('bottom')}>
          <button
            type="button"
            className={cn('button', 'button--bg-3', 'button--full-width', b('add-btn'))}
            onClick={handleAddClick}
          >
            <Icon name="plus" className="button__icon" />
            <span>{lang.ADD_REGION_BUTTON}</span>
          </button>
          {editMode && (
            <div className={b('action-btns')}>
              <button
                type="button"
                className={cn('button', 'button--bg-11', b('action-btn'))}
                onClick={handleCancelClick}
              >
                {lang.CANCEL_BUTTON}
              </button>
              <button type="button" className={cn('button', 'button--bg-3', b('action-btn'))} onClick={handleSaveClick}>
                {lang.SAVE_BUTTON}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

RegionsList.propTypes = {
  editMode: PropTypes.bool.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  setIsEditMode: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      _destroy: PropTypes.bool.isRequired,
    }),
  ),
  isLoading: PropTypes.bool.isRequired,
};

RegionsList.defaultProps = {
  data: [],
};

export default RegionsList;
