import types from './types';

let init = {
  type: '',
  payload: {},
  currentOrganization: {},
  isLoading: false,
};

export default function (state = init, action) {
  switch (action.type) {
    case types.SET_USER_CLIENTS:
      const memberships =
        state.payload.client_user?.memberships?.filter(({client: {id}}) => {
          return action.payload.find(({id: idClient}) => id === idClient);
        }) || [];
      return {
        ...state,
        type: types.SET_USER_CLIENTS,
        payload: {
          ...state.payload,
          client_user: {
            ...state.payload.client_user,
            memberships,
          },
        },
      };
    case types.GET_USER_CLIENTS_REQUEST:
      return {
        ...state,
        type: types.GET_USER_CLIENTS_REQUEST,
        payload: {},
        isLoading: true,
      };
    case types.GET_USER_CLIENTS_SUCCESS:
      return {
        ...state,
        type: types.GET_USER_CLIENTS_SUCCESS,
        payload: action.payload,
        isLoading: false,
      };
    case types.GET_USER_CLIENTS_ERROR:
      return {
        ...state,
        type: types.GET_USER_CLIENTS_ERROR,
        payload: {},
        isLoading: false,
      };
    case types.SET_CURRENT_ORGANIZATION:
      return {
        ...state,
        currentOrganization: action.payload,
      };
    default:
      return state;
  }
}
