import React, {PureComponent} from 'react';

import cn from 'classnames';
import {find} from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import {selectGames} from 'client/ducks/games/selectors';

import Icon from 'client/components/common/icon';

import GameConfigModal from 'client/components/games/game-config-modal';

import './prize-map-card.scss';

const prepareGamesArr = (games) => {
  return [1, 2, 3].map((winNumber) => {
    return find(games, {win_number: winNumber});
  });
};

const b = bem('prize-map-card');

class PrizeMapCard extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    games: PropTypes.array.isRequired,
    hasContainer: PropTypes.bool,
    className: PropTypes.string,
    prizeMapParams: PropTypes.object.isRequired,
    levelKey: PropTypes.string.isRequired,
    displayParams: PropTypes.shape({
      win1: PropTypes.bool,
      win2: PropTypes.bool,
      win3: PropTypes.bool,
    }),
  };

  static defaultProps = {
    className: '',
    hasContainer: false,
    displayParams: {
      win1: false,
      win2: false,
      win3: false,
    },
  };

  state = {
    showConfigModal: false,
    game: null,
  };

  toggleConfigModal = (game) => {
    this.setState(({showConfigModal}) => ({
      game,
      showConfigModal: !showConfigModal,
    }));
  };

  render() {
    const {lang, games, hasContainer, className, prizeMapParams, levelKey, displayParams} = this.props;
    const {showConfigModal, game} = this.state;

    const prepared = prepareGamesArr(games);

    return (
      <div className={cn(b({'has-container': hasContainer}), className)}>
        {showConfigModal && (
          <GameConfigModal
            onClose={() => this.toggleConfigModal(null)}
            game={game}
            prizeMapParams={prizeMapParams}
            levelKey={levelKey}
          />
        )}
        <div className={b('row', ['header'])}>
          {[1, 2, 3].map((val) => (
            <div className={b('col', ['header'])} key={val}>{`${lang.GAME} ${val}`}</div>
          ))}
        </div>
        <div className={b('row')}>
          {prepared.map((gameItem, index) => (
            <div className={b('col')} key={gameItem?.id || index}>
              {displayParams[`win${index + 1}`] && (
                <button type="button" className={b('gear')} onClick={() => this.toggleConfigModal(gameItem)}>
                  <Icon name="gear" />
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  lang: state.languageState.payload.GAMES.PRIZE_MAP_CARD,
  games: selectGames(state),
}))(PrizeMapCard);
