import React from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';

import {ToggleField} from 'client/common/fields';
import CheckboxField from 'client/common/fields/checkbox-field';
import NumberField from 'client/common/fields/number-field';
import SelectField from 'client/common/fields/select-field';
import TextField from 'client/common/fields/text-field';
import LayoutPanel from 'client/common/panels/layout-panel';

import LotteryLossResultAnnouncement from 'client/components/lottery/fieldsets/lottery-loss-result-announcement';
import {LotteryPrizeModalFormName} from 'client/components/lottery/modals/lottery-prize-modal';
import PrizeDiyValidityFieldset from 'client/components/prizes/fieldsets/prize-diy-validity-fieldset';
import {Translation} from 'client/models/language/types';
import {PRIZE_TYPES} from 'client/models/prizes/constants';

import cssModule from './main-params-fieldset.module.scss';

const b = bem('main-params-fieldset', {cssModule});

type MainParamsFieldsetProps = {
  disabled: boolean;
};

const MainParamsFieldset: React.FC<MainParamsFieldsetProps> = (props) => {
  const {disabled} = props;
  const lang = useLanguage('LOTTERY.MODALS.LOTTERY_PRIZE_MODAL.MAIN_PARAMS_FIELDSET');
  const client = useSelector(selectCurrentClient);
  const autotask = useSelector(selectAutotask);

  const {
    formValues: {mainParams = {}},
    change,
  } = useReduxForm(LotteryPrizeModalFormName);

  const getTypeOptions = () => {
    const result: {value: string; label: Translation}[] = [{value: PRIZE_TYPES.COMMON, label: lang.COMMON}];
    if (client.loyalty_program_id) {
      result.push({value: PRIZE_TYPES.LOYALTY, label: lang.LOYALTY});
    }
    return result;
  };

  const handleTypeChange = (type: string) => {
    if (type === PRIZE_TYPES.LOYALTY) {
      change('mainParams.loyalty_only', true);
    }
  };

  return (
    <div className={b()}>
      <LayoutPanel className={b('panel')}>
        <div className={b('type-row')}>
          <SelectField
            name="mainParams.type"
            label={lang.PRIZE_TYPE}
            className={b('prize-type')}
            inputHeight="small"
            options={getTypeOptions()}
            onChange={handleTypeChange}
            simpleValue
            disabled={disabled}
          />
          <div className={b('toggle-distribution')}>
            <span className={b('distribution-label')}>{lang.DISTRIBUTION}</span>
            <ToggleField
              name="mainParams.distribution"
              disabled={client.auto_configuration && autotask.validated}
              rounded
            />
          </div>
        </div>
        {mainParams.type === PRIZE_TYPES.LOYALTY && (
          <div className={b('loyalty-fields')}>
            <NumberField
              name="mainParams.loyalty_amount"
              label={lang.AMOUNT}
              inputHeight="small"
              disabled={disabled}
              required={true}
            />
            <TextField name="mainParams.loyalty_unit" label={lang.UNIT} inputHeight="small" disabled={disabled} />
          </div>
        )}
        <PrizeDiyValidityFieldset prefix="mainParams" validityType={mainParams.validity_type} disabled={disabled} />
        <div className={b('level-loyalty-row')}>
          <NumberField
            name="mainParams.level"
            label={lang.LEVEL}
            className={b('level-field')}
            inputHeight="small"
            disabled={client.auto_configuration && autotask.validated}
          />
          {client.loyalty_program_id && (
            <CheckboxField
              className={b('loyalty-field')}
              label={lang.LOYALTY_ONLY}
              name="mainParams.loyalty_only"
              inversionColor
              disabled={disabled}
            />
          )}
        </div>
      </LayoutPanel>
      <LayoutPanel className={b('panel')}>
        <LotteryLossResultAnnouncement title={lang.WIN_ANNOUNCEMENT} name="mainParams" isReadOnly={disabled} />
      </LayoutPanel>
    </div>
  );
};

export default MainParamsFieldset;
