import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import Field from '../field';

import './text-field.scss';

const TextField = (props) => {
  const {cssModifier, inline, readOnly, ...rest} = props;

  return (
    <Field
      cssModifier={cn('text-field', cssModifier, {
        'text-field--inline': inline,
        'text-field--read-only': readOnly,
      })}
      disabled={readOnly}
      {...rest}
    />
  );
};

TextField.propTypes = {
  ...Field.propTypes,
  type: PropTypes.string,
  cssModifier: PropTypes.string,
  inline: PropTypes.bool,
  readOnly: PropTypes.bool,
};

TextField.defaultProps = {
  ...Field.defaultProps,
  type: 'text',
  cssModifier: null,
  inline: false,
  readOnly: false,
};

export default TextField;
