import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import ConfirmationModal from 'client/common/modals/confirmation-modal';
import MessageModal from 'client/common/modals/message-modal';

import TitleBlock from 'client/components/common/title-block';

import AddNewTemplateModal from './components/modals/add-new-template-modal';
import SetTemplateItemsModal from './components/modals/set-items-modal';
import InterfaceTemplatesTable from './components/tables/interface-templates-table';

import {deleteInterfaceTemplateAction, getInterfaceTemplatesAction} from '../../ducks/interface-templates/actions';

import './interface-templates.scss';

class InterfaceTemplatesContainer extends ReactQueryParams {
  constructor(props) {
    super(props);

    this.state = {
      editingTemplate: {},
      showDeleteTemplatesModal: false,
      showNewTemplateModal: false,
      showEditTemplateItemsModal: false,

      selectedTemplates: [],
      showError: false,
    };

    this.LANGUAGE = props.languageState.payload.INTERFACE_TEMPLATES;
  }

  componentDidMount() {
    this.getMyInterfaceTemplates();
  }

  getMyInterfaceTemplates = () => {
    const {queryParams: params} = this;
    window.scrollTo(0, 0);
    this.props.getInterfaceTemplates({
      page: params.page || 1,
      perPage: +params.perPage - 2 || 3,
      sort: {
        name: (params.sort && params.sort.name) || 'name',
        order: (params.sort && params.sort.order) || 'ASC',
      },
    });
  };

  onPageChange = (params) => {
    this.setQueryParams(params, true);
    this.getMyInterfaceTemplates();
  };

  onSortChange = (name, order) => {
    this.setQueryParams(
      {
        sort: {name, order},
      },
      true,
    );
    this.getMyInterfaceTemplates();
  };

  onDeleteTemplatesClick = async () => {
    const deletes = [];
    for (let templateId of this.state.selectedTemplates) {
      deletes.push(this.props.deleteInterfaceTemplate(templateId));
    }
    this.setState({
      selectedTemplates: [],
      showDeleteTemplatesModal: false,
    });
    try {
      await Promise.all(deletes);
      this.getMyInterfaceTemplates();
    } catch {
      this.setState({showError: true});
    }
  };

  // "save" or "next" button in add-new-template-modal
  onSaveTemplateClick = (pushedTemplate) => {
    this.setState({
      showNewTemplateModal: false,
      editingTemplate: isEmpty(this.state.editingTemplate) ? pushedTemplate : {},
      showEditTemplateItemsModal: isEmpty(this.state.editingTemplate),
    });
    this.getMyInterfaceTemplates();
  };

  onEditTemplateClick = (editingTemplate) => {
    this.setState({
      editingTemplate: {
        ...editingTemplate,
      },
      showNewTemplateModal: true,
    });
  };

  onEditTemplateItemsClick = (editingTemplate) => {
    this.setState({
      editingTemplate: {
        ...editingTemplate,
      },
      showEditTemplateItemsModal: true,
    });
  };

  onSaveTemplateItemsClick = () => {
    this.setState({
      showEditTemplateItemsModal: false,
      editingTemplate: {},
    });
    this.getMyInterfaceTemplates();
  };

  render() {
    const {templates, meta} = this.props.interfaceTemplates;

    const {LANGUAGE} = this;

    return (
      <div className="theme-color-devices">
        <div className="page__title-block">
          <TitleBlock theme>
            <TitleBlock.Item className="">
              {LANGUAGE.TITLE} ({meta.total_count})
            </TitleBlock.Item>
          </TitleBlock>
        </div>
        <InterfaceTemplatesTable
          data={templates}
          perPage={Number(this.queryParams.perPage) || 5}
          currentPage={meta.current_page}
          totalPages={meta.total_pages}
          selectedItems={this.state.selectedTemplates}
          changeSelectedItems={(selectedTemplates) => this.setState({selectedTemplates})}
          onPageChange={this.onPageChange}
          onDeleteTemplatesButtonClick={() => this.setState({showDeleteTemplatesModal: true})}
          onEditTemplateClick={this.onEditTemplateClick}
          onEditTemplateItemsClick={this.onEditTemplateItemsClick}
          onAddTemplateButtonClick={() => this.setState({showNewTemplateModal: true})}
          onSortChange={this.onSortChange}
          totalItems={meta.total_count}
        />

        <AddNewTemplateModal
          show={this.state.showNewTemplateModal}
          onCancel={() => this.setState({showNewTemplateModal: false, editingTemplate: {}})}
          onClose={() => this.setState({showNewTemplateModal: false, editingTemplate: {}})}
          onConfirm={this.onSaveTemplateClick}
          initialValues={this.state.editingTemplate}
        />

        <SetTemplateItemsModal
          show={this.state.showEditTemplateItemsModal}
          onCancel={() => this.setState({showEditTemplateItemsModal: false, editingTemplate: {}})}
          onClose={() => this.setState({showEditTemplateItemsModal: false, editingTemplate: {}})}
          onSave={this.onSaveTemplateItemsClick}
          editingTemplate={this.state.editingTemplate}
        />

        <ConfirmationModal
          show={this.state.showDeleteTemplatesModal}
          onClose={() => this.setState({showDeleteTemplatesModal: false})}
          onCancel={() => this.setState({showDeleteTemplatesModal: false})}
          message={LANGUAGE.DELETE_TEMPLATES_MODAL.BODY_TEXT}
          title={LANGUAGE.DELETE_TEMPLATES_MODAL.TITLE}
          cancelText={LANGUAGE.CANCEL_BUTTON}
          confirmText={LANGUAGE.DELETE_TEMPLATES_MODAL.CONFIRM}
          className="delete-templates-modal"
          buttonConfirmClass="button--bg-7"
          buttonCancelClass="button--bg-11"
          onConfirm={this.onDeleteTemplatesClick}
        />

        <MessageModal
          message={LANGUAGE.ERROR}
          show={this.state.showError}
          onClose={() => this.setState({showError: false})}
        />
      </div>
    );
  }
}

InterfaceTemplatesContainer.propTypes = {
  match: PropTypes.object,
  interfaceTemplates: PropTypes.shape({
    templates: PropTypes.array,
  }),

  getInterfaceTemplates: PropTypes.func,
  deleteInterfaceTemplate: PropTypes.func,
  languageState: PropTypes.object,
};

const mapStateToProps = ({interfaceTemplates, languageState}) => ({
  interfaceTemplates,
  languageState,
});

const mapDispatchToProps = {
  getInterfaceTemplates: getInterfaceTemplatesAction,
  deleteInterfaceTemplate: deleteInterfaceTemplateAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterfaceTemplatesContainer);
