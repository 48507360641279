import {getRefreshToken, setRefreshToken, setToken} from 'client/services/cookie-data-source';

import {API_METHODS, API_PATH} from 'client/common/config';

export const refreshBearerToken = async () => {
  const token = getRefreshToken();
  if (!token) {
    return;
  }

  const response = await fetch(`${API_PATH}${API_METHODS.TRACKING_USER_REFRESH_TOKEN}`, {
    method: 'POST',
    headers: {
      Authorization: token,
    },
  });

  if (response.ok) {
    const data = await response.json();
    const {prize_tracking_user: {access_token = null, refresh_token = null} = {}} = data;
    token && setToken(`Bearer ${access_token}`);
    token && setRefreshToken(`Bearer ${refresh_token}`);
  }
};
