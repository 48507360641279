import {DEVICE_SEGMENTATION, ONLINE_SEGMENTATION} from 'client/components/lottery/constants';
import {Prize, Game} from 'client/models/prizes/types';

import {SegmentationParamsValues} from './types';

export default (game: Game, prize: Prize | null): SegmentationParamsValues => {
  const deviceSegmentation = game.geo_level;
  const onlineSegmentation = game.online_level;
  const fullGlobal =
    deviceSegmentation === DEVICE_SEGMENTATION.GLOBAL && onlineSegmentation === ONLINE_SEGMENTATION.GLOBAL;
  const fullNone = deviceSegmentation === DEVICE_SEGMENTATION.NONE && onlineSegmentation === ONLINE_SEGMENTATION.NONE;

  return {
    deviceSegmentation,
    deviceSegmentationToggle: deviceSegmentation === DEVICE_SEGMENTATION.PER_DEVICE,
    onlineSegmentation,
    fullGlobal,
    fullNone,
    deviceOnlineToggle: !fullGlobal,
    deviceQuantity: prize?.device_stock || '',
    onlineQuantity: prize?.online_stock || '',
    commonQuantity: prize?.default_initial_stock || '',
  };
};
