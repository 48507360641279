import React from 'react';

import {Field} from 'redux-form';

import BaseFieldComponent from 'client/common/fields/base-field';
import {NumberInput} from 'client/common/inputs';

const NumberInputComponent = (props) => <BaseFieldComponent Component={NumberInput} {...props} />;

const NumberField = (props) => {
  return <Field component={NumberInputComponent} {...props} />;
};

export default NumberField;
