import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import EmptyCell from 'client/components/participants/lists/participants-common-list/cells/empty-cell';

const OptInsCell = ({value}) => {
  if (!Array.isArray(value) || isEmpty(value)) {
    return <EmptyCell />;
  }

  return (
    <div className="text-center">
      {value.map(({name, id}) => (
        <div className="ellipsis-text" key={id}>
          {name}
        </div>
      ))}
    </div>
  );
};

OptInsCell.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      is: PropTypes.number.isRequired,
    }),
  ),
};

OptInsCell.defaultProps = {
  value: [],
};

export default OptInsCell;
