import React from 'react';

import cn from 'classnames';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';

import './info-panel.scss';

type InfoPanelProps = {
  className?: string;
  fullSize?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  href?: string;
  title?: React.ReactNode;
  number?: number;
  onClick?: () => void;
  children?: React.ReactNode;
};

const b = bem('info-panel');

const InfoPanel: React.FC<InfoPanelProps> = ({
  children,
  className,
  fullSize,
  icon,
  href,
  title,
  number,
  disabled,
  onClick,
}) => {
  const containerProps = onClick ? {type: 'button' as const, onClick} : {};
  const ContainerTag = onClick ? 'button' : 'div';

  return (
    <ContainerTag
      {...containerProps}
      className={cn(
        b({
          'full-size': fullSize,
          link: !!href,
          disabled,
        }),
        className,
      )}
    >
      {icon && <div className={b('icon')}>{icon}</div>}
      {children || (
        <div className={b('text-content')}>
          {title && <div className={b('title')}>{title}</div>}
          {typeof number === 'number' && <div className={b('number')}>{number}</div>}
        </div>
      )}

      {href && !disabled && <Link to={href} className={b('link')} />}
    </ContainerTag>
  );
};
export default InfoPanel;
