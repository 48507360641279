import {WINNING_SEARCH_TYPES} from 'client/components/winnings/forms/winning-search-form/constants';
import {WinningSearchValues} from 'client/components/winnings/forms/winning-search-form/types';

const mapPhone = (value: string) => {
  let result = value;
  if (result[0] === '0') {
    result = '+13' + result.slice(1);
  }
  if (result[0] === '+') {
    result = '%2B' + result.slice(1);
  }
  return result;
};

export const mapSearchParams = (values: WinningSearchValues) => {
  const result: Record<string, string> = {};
  if (values.search_type === WINNING_SEARCH_TYPES.FULL_NAME) {
    result.first_name = values.first_name;
    result.last_name = values.last_name;
  } else if (values.search_type === WINNING_SEARCH_TYPES.PHONE) {
    result.phone = mapPhone(values.phone);
  } else {
    result[values.search_type] = values[values.search_type as keyof typeof values];
  }
  return result;
};
