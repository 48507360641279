import React from 'react';

import placeholderNoPhoto from 'assets/images/placeholder-no-photo.png';
import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Popover from 'client/common/popovers/popover';

import Icon from 'client/components/common/icon';

import {TranslationJsx} from 'client/models/language/types';

import './template-card.scss';

const b = bem('template-card');

const TemplateCard = (props) => {
  const {onClick, size, name, info, image, isSelected, placeholder, className} = props;

  const WrapperTag = onClick ? 'button' : 'div';
  const wrapperProps = onClick ? {type: 'button', onClick} : {};

  const hasData = Boolean(name || image);

  return (
    <WrapperTag className={cn(b({[size]: true, selected: isSelected}), className)} {...wrapperProps}>
      <div className={b('image-wrapper')}>{hasData && <img alt="" src={image || placeholderNoPhoto} />}</div>

      <div className={b('content')}>
        <p className={b('description')}>{name || placeholder}</p>

        {hasData && info && (
          <Popover
            overlay={info}
            position="top"
            contentCssClass={b('popover-content')}
            className={b('icon-wrapper')}
            arrowCssClass={b('popover-arrow')}
            shiftLeft={0}
            shiftTop={-5}
            overlayInnerStyle={{width: 270}}
          >
            <Icon name="information" className={b('icon')} />
          </Popover>
        )}
      </div>
    </WrapperTag>
  );
};

TemplateCard.defaultProps = {
  size: 'medium',
  isSelected: false,
  info: null,
  name: '',
  image: null,
  placeholder: '',
  className: '',
};

TemplateCard.propTypes = {
  size: PropTypes.oneOf(['medium', 'small']),
  name: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  info: PropTypes.oneOfType([TranslationJsx, PropTypes.node]),
  placeholder: TranslationJsx,
  className: PropTypes.string,
};

export default TemplateCard;
