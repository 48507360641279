export const getMessageKey = (disabled, isCustomImport, value) => {
  if (disabled) {
    return 'OPERATION_STARTED';
  }

  if (!isCustomImport) {
    return 'IMPORT_DEVICE_LEVEL';
  }

  if (isCustomImport && !value.file && !value.url) {
    return 'IMPORT_TXT_FILE';
  }

  return null;
};
