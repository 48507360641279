import React, {Component} from 'react';

import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {error} from 'react-notification-system-redux';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';

import {INTERFACE_TYPES} from 'client/ducks/interfaces/constants';

import Icon from 'client/common/icon';
import ToggleInput from 'client/common/inputs/toggle-input';
import Popover from 'client/common/popovers/popover';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Field from 'client/components/common/field';

import cssModule from 'client/components/autotask/autotask-interfaces/autotask-interfaces.module.scss';
import {hasStartedInteraction} from 'client/models/interactions/helpers';

import './interface-item.scss';

const b = bem('interface-item', {cssModule});

class InterfaceItem extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    interfaceItem: PropTypes.object.isRequired,
    handleInterfaceActivationChange: PropTypes.func.isRequired,
    handleEditInterface: PropTypes.func.isRequired,
    handleDeleteInterfaceClick: PropTypes.func.isRequired,
    handleChangeWebAppBeta: PropTypes.func.isRequired,
    handleChangeWebAppUpdate: PropTypes.func.isRequired,
    errorNotification: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const {lang} = props;
    this.state = {
      typesMap: {
        [INTERFACE_TYPES.INTERNAL]: lang.INTERFACE_TYPES.INTERNAL,
        [INTERFACE_TYPES.SURVEYGIZMO]: lang.INTERFACE_TYPES.SURVEYGIZMO,
        [INTERFACE_TYPES.UNBOUNCE]: lang.INTERFACE_TYPES.UNBOUNCE,
      },
    };
    this.TYPE_MAP = {
      [INTERFACE_TYPES.INTERNAL]: lang.INTERFACE_TYPES.INTERNAL,
      [INTERFACE_TYPES.SURVEYGIZMO]: lang.INTERFACE_TYPES.SURVEYGIZMO,
      [INTERFACE_TYPES.UNBOUNCE]: lang.INTERFACE_TYPES.UNBOUNCE,
    };
  }

  renderInterfaceItem = (item, itemsWithOptIns) => {
    return (
      <div className="content-panel__item" key={item.id}>
        {item.name}
        {itemsWithOptIns.indexOf(item.id) !== -1 && (
          <Icon name="star" className="theme-color-8 interface-item__optin-added-icon" />
        )}
      </div>
    );
  };

  renderLevel = (level) => {
    return (
      <div key={level.id}>
        <div>
          <span>
            {level.number} - {level.level_name}
          </span>
          {level.minimum_level && (
            <span className="interface-item__min-icon icon-new-wrapper-circle theme-color-8">
              <Icon name="asterix" />
            </span>
          )}
        </div>
        <div className="interface-item__screenshots">{level.screenshots.map(this.renderScreenshot)}</div>
      </div>
    );
  };

  renderScreenshot = ({id, image}) => {
    return (
      <div key={id} className="interface-item__screenshot-preview">
        <a href={image.url} target="_blank" rel="noreferrer">
          <img className="image-preview" src={image.url} alt="" />
        </a>
      </div>
    );
  };

  renderOptIns = (optIns = []) => {
    const {lang} = this.props;
    return optIns.map((item, index) => {
      return (
        <div className="content-panel__item" key={index}>
          <div className="flex-container flex-align-center">
            {item.name}
            <div className="interface-item__int-level">
              {lang.LEVEL_LABEL} {item.level}
            </div>
            {item.text && (
              <Popover
                shiftTop={5}
                overlayInnerStyle={{
                  width: 200,
                }}
                contentCssClass="custom-popover-new__content"
                overlay={
                  <div className="custom-popover-new__scroll-wrap">
                    <CustomScrollbars
                      scrollbarProps={{
                        autoHeightMax: 100,
                      }}
                    >
                      <div className="custom-popover-new__scroll-content">{item.text}</div>
                    </CustomScrollbars>
                  </div>
                }
              >
                <Icon name="interface" className="theme-color-8 interface-item__int-icon" />
              </Popover>
            )}
          </div>
        </div>
      );
    });
  };

  render() {
    const {interfaceItem: item, lang} = this.props;
    const {handleInterfaceActivationChange, handleEditInterface, handleDeleteInterfaceClick, errorNotification} =
      this.props;
    const interfaceItems = item.interface_items || [];
    const canDelete = !hasStartedInteraction(item.interactions);
    const hasScenarioVariables = interfaceItems.some((i) => i.scenario_variables.length !== 0);
    const {typesMap} = this.state;

    return (
      <div className="content-panel__panel interface-item" key={item.id}>
        <CustomScrollbars
          scrollbarProps={{
            autoHeightMax: 300,
          }}
        >
          <div className="content-panel__panel-inner">
            <div className="content-panel__row">
              <div className="interface-item__col">
                <Field
                  cssModifier="theme-color-8 content-panel__switcher"
                  name="active"
                  type="switcher"
                  input={{
                    checked: item.active,
                    onChange: () => handleInterfaceActivationChange(item.active, item.name, item.id),
                  }}
                />
                <Link
                  title={item.name}
                  to=""
                  className="interface-item__link"
                  onClick={(e) => handleEditInterface(item, e)}
                >
                  {item.name}
                </Link>
                <div className="interface-item__type-wrap">
                  <div>{lang.TYPE}:</div>
                  <div>{typesMap[item.type]}</div>
                </div>
              </div>
              <div className="interface-item__col">{sortBy(item.interface_levels, 'number').map(this.renderLevel)}</div>
              <div className="interface-item__col">
                {item.interface_items?.map((interfaceItem) => {
                  return this.renderInterfaceItem(interfaceItem, item.itemsWithOptIns);
                })}
              </div>
              <div className="interface-item__col">{this.renderOptIns(item.optIns)}</div>
              <div className="interface-item__col">
                {item.id}
                <div className="interface-item__copy-btn">
                  <CopyToClipboard text={item.id}>
                    <button className="interface-item__copy-button button button--bg-7">{lang.COPY_ID_BUTTON}</button>
                  </CopyToClipboard>
                  <button
                    type="button"
                    className="interface-item__delete-button button button--bg-7 button--circle"
                    onClick={() => {
                      if (!canDelete || hasScenarioVariables) {
                        errorNotification({
                          message: lang.ERRORS.DELETE_INTERFACE_ERROR,
                          position: 'tc',
                        });
                      } else {
                        handleDeleteInterfaceClick(item.id, item.name);
                      }
                    }}
                  >
                    <Icon name="trash" className="button__icon" width={17} height={19} />
                  </button>
                </div>

                {item.web_app && (
                  <div className={b('togglers')}>
                    <div className={b('toggle')}>
                      <span>{lang.ENABLE_BETA}</span>
                      <ToggleInput
                        rounded
                        color="interfaces"
                        checked={item.web_app?.beta_enabled}
                        onChange={(e) => this.props.handleChangeWebAppBeta(item, e.target.checked)}
                      />
                    </div>
                    <div className={b('toggle')}>
                      <span>{lang.ENABLE_UPDATE}</span>
                      <ToggleInput
                        rounded
                        color="interfaces"
                        checked={item.web_app?.update_enabled}
                        onChange={(e) => this.props.handleChangeWebAppUpdate(item, e.target.checked)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </CustomScrollbars>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  errorNotification: (...args) => dispatch(error(...args)),
});

export default connect(null, mapDispatchToProps)(InterfaceItem);
