import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getSMSSenders(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SMS_SENDERS}`,
      method: 'GET',
      headers: {
        'cache-control': 'no-cache',
      },
      types: [types.LOADING, types.LOADED, types.LOAD_FAILED],
      queryParams,
    });
}

export function addSMSSender(body) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SMS_SENDERS}`,
      method: 'POST',
      types: [types.ADD_SMS_SENDER_REQUEST, types.ADD_SMS_SENDER_SUCCESS, types.ADD_SMS_SENDER_ERROR],
      body,
    });
  };
}

export function updateSMSSender(id, body) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SMS_SENDERS}/${id}`,
      method: 'PUT',
      types: [types.UPDATE_SMS_SENDER_REQUEST, types.UPDATE_SMS_SENDER_SUCCESS, types.UPDATE_SMS_SENDER_ERROR],
      body,
    });
  };
}

export function removeSMSSender(id) {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SMS_SENDERS}/${id}`,
      method: 'DELETE',
      types: [types.REMOVE_SMS_SENDER_REQUEST, types.REMOVE_SMS_SENDER_SUCCESS, types.REMOVE_SMS_SENDER_ERROR],
    });
  };
}
