import React, {useMemo} from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch, useReduxForm} from 'client/services/hooks';

import {getAllAgencyClients} from 'client/ducks/agencies/actions';
import {selectAllAgencyClients} from 'client/ducks/agencies/selectors';
import {selectCurrentMembership} from 'client/ducks/user-clients/selectors';

import AwaitContainer from 'client/common/await-container';
import {CheckListField} from 'client/common/fields';
import WarningLabel from 'client/common/typography/warning-label';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {ClientUserModalFormName} from 'client/components/client-account-parameters/modals/client-user-modal';
import {ClientUserModalForm} from 'client/components/client-account-parameters/modals/client-user-modal/types';
import {Client} from 'client/models/client/types';

import ClientUserTitle from '../client-user-title';

import cssModule from './client-user-agency-clients-step.module.scss';

const b = bem('client-user-agency-clients-step', {cssModule});

type ClientUserAgencyClientsStepProps = {
  client: Client;
};

const ClientUserAgencyClientsStep: React.FC<ClientUserAgencyClientsStepProps> = ({client}) => {
  const lang = useLanguage('CLIENT_ACCOUNT_PARAMETERS.MODALS.CLIENT_USER');

  const clientId = client.id;

  const {formValues} = useReduxForm<ClientUserModalForm>(ClientUserModalFormName);

  const currentMembership = useSelector(selectCurrentMembership);
  const membership = formValues.memberships?.[0];
  const isCurrentUser = currentMembership?.id && membership?.id === currentMembership?.id;

  const {data = [], loading} = useReduxFetch({
    action: getAllAgencyClients,
    selector: selectAllAgencyClients,
    actionArgs: clientId,
  });

  const clients = useMemo(
    () =>
      data.map((teamCLient) => ({
        value: teamCLient.id?.toString(),
        label: teamCLient.name,
      })),
    [data],
  );

  return (
    <div className={b()}>
      <ClientUserTitle
        email={formValues.email}
        civility={formValues.title}
        firstName={formValues.first_name}
        lastName={formValues.last_name}
      />
      <p className={b('block-title')}>
        {lang.ACCESS_TO_CLIENTS}
        {isCurrentUser && <WarningLabel label={lang.ERRORS.CANT_MODIFY} />}
      </p>
      <AwaitContainer loading={loading}>
        <CustomScrollbars
          scrollbarProps={{
            autoHeightMax: 301,
          }}
        >
          <CheckListField
            options={clients}
            name="memberships[0].company_accesses"
            inversionColor
            color="primary"
            disabled={isCurrentUser}
          />
        </CustomScrollbars>
      </AwaitContainer>
    </div>
  );
};

export default ClientUserAgencyClientsStep;
