import moment from 'moment/moment';

import {TYPE_VALUE} from './constants';

export default (channel, operation, channelIndex = 0) => {
  if (channel) {
    return {
      name: channel.name,
      kind: channel.kind === TYPE_VALUE.IFRAME ? TYPE_VALUE.IFRAME : TYPE_VALUE.MINISITE,
      from: channel.from,
      to: channel.to,
      permanent_source: channel.permanent_source_id && {
        id: channel.permanent_source_id,
      },
      region: channel.region_id && {
        value: channel.region_id,
        label: channel.region_name,
      },
      store: channel.store_id && {
        value: channel.store_id,
        label: channel.store_name,
      },
    };
  }
  return {
    name: `Channel ${channelIndex + 1}`,
    kind: TYPE_VALUE.MINISITE,
    from: moment(operation.from).toISOString(),
    to: moment(operation.to).toISOString(),
  };
};
