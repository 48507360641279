import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getAdmins, addAdmin} from 'client/ducks/admins-list/admins-list.action';

import {CLIENT_PAGES} from 'client/common/config';

import TitleBlock from 'client/components/common/title-block';

import AdminsDataGrid from '../tables/admins-data-grid';

class AdminsList extends ReactQueryParams {
  static propTypes = {
    getAdmins: PropTypes.func.isRequired,
    adminsList: PropTypes.object.isRequired,
    languageState: PropTypes.object.isRequired,
    urlParams: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.perPageDefault = 5;
    this.pageDefault = 1;
    this.state = {
      page: props.urlParams.page || 1,
    };

    this.LANGUAGE = props.languageState.payload.ADMINS;
  }

  componentDidMount() {
    const {urlParams, history} = this.props;

    if (!urlParams.page) {
      history.replace(`${CLIENT_PAGES.ADMINS_LIST}?page=${this.pageDefault}&perPage=${this.perPageDefault}`);
    }

    this.getAdmins(urlParams.page, urlParams.perPage);
  }

  componentDidUpdate(prevProps) {
    const {
      urlParams: {page, perPage},
    } = this.props;
    const {page: currentPage} = this.state;

    if (page && page !== String(currentPage) && page !== prevProps.urlParams.page) {
      window.scrollTo(0, 0);
      this.setState({page}, () => {
        this.getAdmins(page, perPage);
      });
    }
  }

  buildQueryObject = (search = '', sort = null, isFilterParam = null) => ({
    include: ['network.group', 'city.agglomeration.zone', 'company.operations', 'region', 'subsidiary'],
    q: {
      name_cont: search,
      qualification_in: isFilterParam && ['project_manager', 'account_manager'],
      s: sort && `${sort.name} ${sort.order}`,
    },
  });

  getAdmins = (page = this.pageDefault, perPage = this.perPageDefault, search, sort, isFilterParam) => {
    if (!Number.isNaN(parseInt(page, 10)) && !Number.isNaN(parseInt(perPage, 10))) {
      this.props.getAdmins({page, perPage, query: this.buildQueryObject(search, sort, isFilterParam)});
    }
  };

  updateData = () => {
    const {page, perPage} = this.props.urlParams;
    return this.getAdmins(page, perPage);
  };

  handlePageChange = ({page, perPage}) => {
    this.setQueryParams({page, perPage}, true);
    this.setState({page}, () => this.getAdmins(page, perPage));
  };

  render() {
    const {urlParams} = this.props;
    const {data, meta} = this.props.adminsList.payload;

    return (
      <div>
        <div className="page__title">
          <TitleBlock>
            <TitleBlock.Item>{this.LANGUAGE.PAGE_TITLE}</TitleBlock.Item>
          </TitleBlock>
        </div>
        <div className="theme-color-5">
          <AdminsDataGrid
            updateData={this.updateData}
            perPage={+urlParams.perPage}
            data={data}
            meta={meta}
            onPageChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  ({adminsList, languageState}) => ({
    languageState,
    adminsList,
  }),
  {
    getAdmins,
    addAdmin,
  },
)(withRouter(AdminsList));
