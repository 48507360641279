export const paginateList = (list: any[], perPage: number) => {
  if (!list.length) {
    return [];
  }

  const pages = list.length <= perPage ? 1 : Math.ceil(list.length / perPage) || 1;

  const result = [];

  for (let i = 0; i < pages; i++) {
    result.push(list.slice(i * perPage, i * perPage + perPage));
  }

  return result;
};
