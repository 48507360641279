import {formatToSelectOption} from 'client/services/formatters';
import {transformDate} from 'client/services/helpers';

import {Operation} from 'client/models/operations/types';

export default (operation: Operation) => {
  const {
    from,
    to,
    status,
    common_retention_years,
    common_retention_months,
    leads_retention_years,
    leads_retention_months,
    client_user_id,
    client: {name} = {},
  } = operation;

  return {
    ...operation,
    client_name: name,
    from: transformDate(from),
    to: transformDate(to),
    status: formatToSelectOption(status),
    client_user_id: formatToSelectOption(client_user_id),
    common_retention_years: String(common_retention_years),
    common_retention_months: String(common_retention_months),
    leads_retention_years: String(leads_retention_years),
    leads_retention_months: String(leads_retention_months),
  };
};
