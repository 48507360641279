import React, {Component} from 'react';

import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {connect} from 'react-redux';
import rehypeRaw from 'rehype-raw';

import {set} from 'client/common/meta/meta.actions';

class CookiesPolicy extends Component {
  constructor(props) {
    super(props);
    this.LANGUAGE = props.languageState.payload.COOKIES_POLICY;
  }

  componentDidMount() {
    const title = this.LANGUAGE.PAGE_TITLE;
    this.props.setMetaAction({title});
  }

  render() {
    return (
      <div className="privacy-policy-accept__container">
        <ReactMarkdown escapeHtml={false} rehypePlugins={[rehypeRaw]}>
          {this.LANGUAGE.POLICY?.toString()}
        </ReactMarkdown>
      </div>
    );
  }
}

CookiesPolicy.propTypes = {
  languageState: PropTypes.object,
  setMetaAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  languageState: state.languageState,
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(CookiesPolicy);
