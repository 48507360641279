import types from './types';

const initialState = {
  clientDomains: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CLIENTS_DOMAINS_SUCCESS:
      return {
        ...state,
        clientDomains: action.payload.client_domains,
      };

    default:
      return state;
  }
};
