import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';

import {TASK_TYPES} from 'client/models/operations/constants';

import BCPopoverStatusState from '../../popovers/bc-popover-status-state';

function StateStatusColumn(props) {
  const {taskState, taskType, languageState} = props;

  const popoverEmail = get(languageState, 'payload.BROADCASTING_TASK.STATUS_POPOVER') || {};
  const popoverSms = get(languageState, 'payload.BROADCASTING_TASK.SMS_STATUS_POPOVER') || {};
  const popover = taskType === TASK_TYPES.SMS ? popoverSms : popoverEmail;
  const labels = get(languageState, 'payload.BROADCASTING_TASK.TASK_LIST.STATUS') || {};

  const language = {
    texts: {
      new: popover.NEW,
      performing: popover.IN_PROGRESS,
      finished: popover.FINISHED,
      sleeping: popover.PAUSED,
    },
    labels: {
      new: labels.NEW,
      performing: labels.IN_PROGRESS,
      finished: labels.FINISHED,
      sleeping: labels.PAUSED,
    },
  };

  return <BCPopoverStatusState status={taskState} {...language} />;
}

StateStatusColumn.propTypes = {
  taskType: PropTypes.string.isRequired,
  taskState: PropTypes.string.isRequired,
  languageState: PropTypes.object.isRequired,
};

export default StateStatusColumn;
