import {PRIZE_TYPES} from 'client/components/prizes/constants';
import {COUPON_TYPES} from 'client/models/prizes/constants';

export const getPrizeTypeOptions = (client, lang) => {
  const options = [{label: lang.COMMON, value: PRIZE_TYPES.COMMON}];

  if (client.available_coupons?.includes(COUPON_TYPES.CLIENT)) {
    options.push({label: lang.COUPON, value: PRIZE_TYPES.COUPON});
  }

  if (client.loyalty_program_id) {
    options.push({label: lang.LOYALTY, value: PRIZE_TYPES.LOYALTY});
  }

  return options;
};
