import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';

import {mapBroadcasts} from 'client/components/operations-client/components/modals/diy-operation-modal/helpers';
import {ClientTemplate} from 'client/models/templates/types';

import './operation-template-popover-content.scss';

const b = bem('operation-template-popover-content');

const MAX_DESCRIPTION_LENGTH = 300;

type OperationTemplatePopoverContentProps = {
  template: ClientTemplate;
};

export const OperationTemplatePopoverContent: React.FC<OperationTemplatePopoverContentProps> = (props) => {
  const {template} = props;
  const {languages, description} = template;
  const modules = template.catalog?.catalog_modules || [];

  const lang = useLanguage('OPERATIONS.DIY_OPERATION_MODAL.POPOVER');
  const broadcasts = mapBroadcasts(template.image_formats);

  return (
    <div className={b()}>
      <div className={b('row')}>
        {(broadcasts.vertical || broadcasts.horizontal) && (
          <div className={b('icon-wrapper', {active: true})}>
            <span>{lang.DEVICE}</span>
            <div className={b('icon-row')}>
              {broadcasts.vertical && <Icon name="kiosk-vertical" className={b('icon')} width={28} height={28} />}
              {broadcasts.horizontal && <Icon name="kiosk-horizontal" className={b('icon')} width={28} height={28} />}
            </div>
          </div>
        )}
        {broadcasts.online && (
          <div className={b('icon-wrapper', {active: true})}>
            <span>{lang.ONLINE}</span>
            <Icon name="mobile-desktop-covered" className={b('icon')} />
          </div>
        )}
      </div>
      {languages.length > 0 && (
        <div>
          <span>{lang.LANGUAGES}</span>
          <div className={b('languages')}>
            {languages.map((language) => (
              <Icon key={language} name={`flag-${language}`} className={b('icon')} />
            ))}
          </div>
        </div>
      )}
      <div className={b('row')}>
        <span className={b('icon-wrapper', {active: modules.includes('game')})}>
          <span>{lang.GAME}</span>
          <Icon name="box" className={b('icon')} />
        </span>
        <span className={b('icon-wrapper', {active: modules.includes('quiz')})}>
          <span>{lang.QUIZ}</span>
          <Icon name="quiz" className={b('icon')} />
        </span>
        <span className={b('icon-wrapper', {active: modules.includes('form')})}>
          <span>{lang.FORM}</span>
          <Icon name="list-outline" className={b('icon')} />
        </span>
      </div>
      {description && <p className={b('description')}>{description.slice(0, MAX_DESCRIPTION_LENGTH)}</p>}
    </div>
  );
};
