import React from 'react';

import {useLanguage} from 'client/services/hooks';

import {TextField} from 'client/common/fields';

type CalculationValueFieldProps = {
  number: number;
  className?: string;
};

const CalculationValueField: React.FC<CalculationValueFieldProps> = ({number, className, ...props}) => {
  const lang = useLanguage('AUTOTASK_SCENARIO.SCENARIO_FORMS');

  return (
    <TextField
      className={className}
      name={`component_text${number}`}
      label={`${lang.COMPONENT} ${number}`}
      withWrap={false}
      {...props}
    />
  );
};

export default CalculationValueField;
