import React from 'react';

import PropTypes from 'prop-types';

function TextColumn(props) {
  const {value} = props;

  return (
    <div className="ellipsis-text" title={value}>
      {value}
    </div>
  );
}

TextColumn.propTypes = {
  value: PropTypes.string.isRequired,
};

export default TextColumn;
