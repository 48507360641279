import {Translation} from 'client/models/language/types';

import validateMainParamsValues from './main-params-fieldset/validateValues';
import validateSegmentationParamsValues from './segmentation-params-fieldset/validateValues';
import {LotteryPrizeValues} from './types';
import validateWinParamsValues from './win-params-fieldset/validateValues';

export default (values: LotteryPrizeValues, lang: Record<string, Translation>) => {
  return {
    winParams: validateWinParamsValues(values.winParams, lang),
    mainParams: validateMainParamsValues(values.mainParams, lang),
    segmentationParams: validateSegmentationParamsValues(values.segmentationParams, lang),
  };
};
