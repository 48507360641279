import {Route} from 'routes/types';

import {APP_ROLES, WINNING_PAGES, PAGES_BACKGROUND_COLORS} from 'client/common/config';

import WinningSearchPage from 'client/pages/winning-search-page';
import WinningTasksPage from 'client/pages/winning-tasks-page';
import WinningLoginPage from 'client/pages/winnings-login-page';

export default [
  {
    component: WinningLoginPage,
    name: 'WinningLoginPage',
    path: WINNING_PAGES.LOGIN,
    params: {
      isNotNeedLogin: true,
      nobreadcrumbs: true,
    },
  } as Route<'WinningLoginPage'>,
  {
    component: WinningTasksPage,
    name: 'WinningTasksPage',
    path: WINNING_PAGES.WINNING_TASKS,
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      backgroundMobile: PAGES_BACKGROUND_COLORS.WINNINGS_MOBILE,
      permissions: [APP_ROLES.TRACKING_USER],
      viewMode: true,
    },
    exact: true,
  } as Route<'WinningTasksPage'>,
  {
    component: WinningSearchPage,
    name: 'WinningSearchPage',
    path: WINNING_PAGES.WINNING_TASKS + '/:taskId' + WINNING_PAGES.WINNING_SEARCH,
    params: {
      background: PAGES_BACKGROUND_COLORS.CLIENT_SIDE,
      backgroundMobile: PAGES_BACKGROUND_COLORS.WINNINGS_MOBILE,
      permissions: [APP_ROLES.TRACKING_USER],
      viewMode: true,
    },
    exact: true,
  } as Route<'WinningSearchPage'>,
];
