import React, {PureComponent} from 'react';

import flow from 'lodash/flow';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import reduce from 'lodash/reduce';
import some from 'lodash/some';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FieldArray, reduxForm, formValueSelector} from 'redux-form';

import bem from 'client/services/bem';
import {getFileSize} from 'client/services/helpers';
import {required, maxLength} from 'client/services/validator';

import {uploadSocialScenarioStepImage, deleteSocialScenarioStepImage} from 'client/ducks/scenario/actions';

import AppButton from 'client/common/buttons';
import {SelectField, TextField, TextareaField, FileButtonField} from 'client/common/fields';

import ImgPreview from 'client/components/common/img-preview';

import AddFromVariableModal from './add-from-variable-modal';
import {submitForm, getInitialValues, getSocialAccounts, getImageQuantityLimit, getMessageLengthLimit} from './helpers';
import validate from './validation';

import {maxLengthNormalization, getScenarioVariables, preventDefault, onSubmitSuccess, onSubmitFail} from '../helpers';

// re-use main form styles
// TODO: make own styles
import cssModule from './../../ats-step/ats-step.module.scss';

const b = bem('ats-step', {cssModule});
class AtsPostForm extends PureComponent {
  static propTypes = {
    formId: PropTypes.string.isRequired,
    data: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      implementation: PropTypes.shape({}),
    }),
    socialAccounts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    scenarioVariables: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    availableScenarioVariables: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    imageQuantity: PropTypes.number.isRequired,
    imageQuantityLimit: PropTypes.number.isRequired,
    messageLengthLimit: PropTypes.number.isRequired,
    array: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    deleteSocialScenarioStepImage: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    socialAccounts: [],
  };

  LANGUAGE = this.props.languageState.payload.AUTOTASK_SCENARIO.SCENARIO_FORMS;

  state = {
    showAddFromVariableModal: false,
  };

  rules = {
    name: [required(this.LANGUAGE.ERRORS.REQUIRED)],
    select: [required(this.LANGUAGE.ERRORS.REQUIRED)],
    message: [
      required(this.LANGUAGE.ERRORS.REQUIRED),
      maxLength(280, `${this.LANGUAGE.ERRORS.MAXIMUM_LENGTH} 280 ${this.LANGUAGE.ERRORS.DIGITS}`),
    ],
  };

  formatSocialAccounts = (socialAccounts) =>
    socialAccounts.map((socialAccount) => ({
      value: socialAccount.id,
      label: socialAccount.name,
    }));

  concatFiles = (value, previousValue = []) => [...previousValue, value];

  getVariableName = (variables, id) => {
    const variable = variables.find((_variable) => _variable.id === id);

    if (variable) {
      return `${this.LANGUAGE.POST.VAR_IMAGE_LABEL} "${variable.full_name || variable.name}"`;
    }

    return '';
  };

  removeImage = (fields, index) => {
    const {data} = this.props;
    const field = fields.get(index);

    if (isNumber(field.id)) {
      this.props.deleteSocialScenarioStepImage(data.id, field.id).then(() => fields.remove(index));
    } else {
      fields.remove(index);
    }
  };

  renderImage = (field, values, variables, onRemove) => {
    const {file_identifier, filesize, variable_id} = values;

    const imageRelativeUrl = get(values, 'file.url');

    const isFile = !!imageRelativeUrl;

    const imageUrl = isFile ? imageRelativeUrl : null;
    const imageTitle = isFile ? file_identifier : this.getVariableName(variables, variable_id);
    const imageSize = isFile ? getFileSize(filesize) : '';

    return (
      <div key={values.id} className={b('form-item', ['view-2'])}>
        <ImgPreview
          className={b('form-load-img')}
          imgWrapClass={b('form-img-wrap')}
          imgDescrClass={b('form-img-descr')}
          imgTitleClass="ellipsis-text"
          src={imageUrl}
          title={imageTitle}
          descr={imageSize}
        />
        <AppButton iconName="trash" onClick={onRemove} color="text-additional" rounded={true} />
      </div>
    );
  };

  renderImages = ({fields, scenarioVariables}) => {
    const onRemove = (index) => () => this.removeImage(fields, index);

    const renderedFields = fields.map((field, index) => {
      return this.renderImage(field, fields.get(index), scenarioVariables, onRemove(index));
    });

    return <div>{renderedFields}</div>;
  };

  renderNewImage = (field, values, onRemove) => {
    return (
      <div key={`${field}_${values.name}`} className={b('form-item', ['view-2'])}>
        <ImgPreview
          className={b('form-load-img')}
          imgWrapClass={b('form-img-wrap')}
          imgDescrClass={b('form-img-descr')}
          imgTitleClass="ellipsis-text"
          src={values}
          title={values.name}
          descr={getFileSize(values.size)}
        />
        <AppButton iconName="trash" onClick={onRemove} color="text-additional" rounded={true} />
      </div>
    );
  };

  renderNewImages = ({fields}) => {
    const onRemove = (index) => () => this.removeImage(fields, index);

    const renderedFields = fields.map((field, index) => {
      return this.renderNewImage(field, fields.get(index), onRemove(index));
    });

    return <div className={b('form-variables')}>{renderedFields}</div>;
  };

  handleCloseAddFromVariableModal = () => this.toggleAddFromVariableModalVisibility(false);

  toggleAddFromVariableModalVisibility = (show) => {
    this.setState({
      showAddFromVariableModal: show,
    });
  };

  handleAddImageFromVariable = (variableId) => {
    this.props.array.push('images.newVariables', {
      id: uniqueId('variable_image_'),
      variable_id: variableId,
    });

    this.handleCloseAddFromVariableModal();
  };

  renderVariable = (field, values, variables, onRemove) => {
    return (
      <div key={values.id} className={b('form-item', ['view-2'])}>
        <ImgPreview className={b('form-load-img')} title={this.getVariableName(variables, values.variable_id)} />
        <AppButton iconName="trash" onClick={onRemove} color="text-additional" rounded={true} />
      </div>
    );
  };

  renderVariables = ({fields, scenarioVariables}) => {
    const onRemove = (index) => () => this.removeImage(fields, index);

    const renderedFields = fields.map((field, index) => {
      return this.renderImage(field, fields.get(index), scenarioVariables, onRemove(index));
    });

    return <div>{renderedFields}</div>;
  };

  render() {
    const {
      formId,
      socialAccounts,
      scenarioVariables,
      availableScenarioVariables,
      imageQuantity,
      imageQuantityLimit,
      messageLengthLimit,
    } = this.props;

    const {showAddFromVariableModal} = this.state;

    const socialAccountsOptions = this.formatSocialAccounts(socialAccounts);

    return (
      <form onSubmit={preventDefault} className="ats-step__form">
        <TextField label={this.LANGUAGE.STEP_NAME_LABEL} name="name" />
        <SelectField
          simpleValue
          label={this.LANGUAGE.POST.ACCOUNT_LABEL}
          name="implementation.social_account_id"
          options={socialAccountsOptions}
          searchable={false}
        />
        <TextareaField
          label={`${this.LANGUAGE.POST.MESSAGE_LABEL_1}
                  (max. ${messageLengthLimit} ${this.LANGUAGE.POST.MESSAGE_LABEL_2})`}
          name="implementation.message"
          normalize={maxLengthNormalization(messageLengthLimit)}
        />

        <div className={b('form-block')}>
          <h4 className={b('form-title')}>{this.LANGUAGE.POST.IMAGES_LABEL}</h4>
          <div className={b('form-load-buttons')}>
            <FileButtonField
              label={this.LANGUAGE.POST.LOAD_IMAGE_BUTTON}
              htmlFor={`post-image-${formId}`}
              name="images.newFiles"
              acceptFormats="image/*"
              normalize={this.concatFiles}
              disabled={imageQuantity >= imageQuantityLimit}
              color="text-additional"
              fontSize="small"
            />
          </div>
          <FieldArray name="images.files" component={this.renderImages} />
          <FieldArray name="images.newFiles" component={this.renderNewImages} />
        </div>
        <div className={b('form-block')}>
          <h4 className={b('form-title')}>{this.LANGUAGE.POST.VAR_IMAGES_LABEL}</h4>
          <AppButton
            onClick={() => this.toggleAddFromVariableModalVisibility(true)}
            disabled={availableScenarioVariables.length === 0 || imageQuantity >= imageQuantityLimit}
            label={this.LANGUAGE.POST.ADD_IMAGE_BUTTON}
            color="text-additional"
            fontSize="small"
          />
          <FieldArray name="images.variables" component={this.renderVariables} props={{scenarioVariables}} />
          <FieldArray name="images.newVariables" component={this.renderVariables} props={{scenarioVariables}} />
        </div>
        <TextareaField label={this.LANGUAGE.COMMENT_LABEL} name="comment" />
        <AddFromVariableModal
          show={showAddFromVariableModal}
          variables={availableScenarioVariables}
          onClose={() => this.toggleAddFromVariableModalVisibility(false)}
          onAdd={this.handleAddImageFromVariable}
        />
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {formId, data} = props;

  const selector = formValueSelector(formId);

  const images = selector(state, 'images');

  const imageVariables = [...get(images, 'variables', []), ...get(images, 'newVariables', [])];

  const imageQuantity = reduce(images, (result, value) => result + value.length, 0);

  const scenarioVariables = getScenarioVariables(state, 'file');
  const availableScenarioVariables = scenarioVariables.filter((scenarioVariable) => {
    return !some(imageVariables, (image) => image.variable_id === scenarioVariable.id);
  });

  return {
    form: formId,
    initialValues: getInitialValues(data, state),
    socialAccounts: getSocialAccounts(data, state),
    scenarioVariables,
    availableScenarioVariables,
    imageQuantity,
    imageQuantityLimit: getImageQuantityLimit(data),
    messageLengthLimit: getMessageLengthLimit(data),
    languageState: state.languageState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadSocialScenarioStepImage,
      deleteSocialScenarioStepImage,
    },
    dispatch,
  );

export default flow([
  reduxForm({
    enableReinitialize: true,
    touchOnBlur: false,
    validate,
    onSubmit: submitForm,
    onSubmitSuccess,
    onSubmitFail,
  }),
  connect(mapStateToProps, mapDispatchToProps),
])(AtsPostForm);
