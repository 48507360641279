import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {isEmptyString} from 'client/services/validator';

import {WinningLoginValues, WinningLoginErrors} from './types';

export default (values: WinningLoginValues, lang: LangsByPath<['WINNINGS.VARIOUS.WINNING_LOGIN.ERRORS']>) => {
  const errors: WinningLoginErrors = {};

  if (isEmptyString(values.login)) {
    errors.login = lang.REQUIRED;
  }

  if (isEmptyString(values.password)) {
    errors.password = lang.REQUIRED;
  }

  return errors;
};
