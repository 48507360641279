import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, FieldArray, reduxForm} from 'redux-form';

import {numberNormalizer} from 'client/services/normilizers';
import {required} from 'client/services/validator';

import {patchInterfaceLevels} from 'client/ducks/interfaces/actions';
import {LEVEL_SCREENSHOT_TYPES} from 'client/ducks/interfaces/constants';
import {getScenarios} from 'client/ducks/scenario/actions';
import {selectScenarios} from 'client/ducks/scenario/selectors';

import Icon from 'client/common/icon';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';
import LoadingSpinner from 'client/components/common/loading-spinner';

import {mapLevelsForForm, mapLevelsForSave} from './helpers';

import './set-interface-levels-modal.scss';

function createLevel(data = {}) {
  return {
    screenshots: [],
    scenarios: [],
    ...data,
  };
}

function createScreenshot(data = {}) {
  return {
    screenshot_type: {value: LEVEL_SCREENSHOT_TYPES.DESKTOP},
    ...data,
  };
}

class SetInterfaceLevelsModal extends Component {
  static formName = 'SetInterfaceLevelsForm';

  static titleCaptionNotEdited = '(3/5)';

  static defaultProps = {
    onNext: null,
    fetchInterfaces: null,
    edit: false,
  };

  static propTypes = {
    lang: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onNext: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    getScenarios: PropTypes.func.isRequired,
    scenarios: PropTypes.array.isRequired,
    patchInterfaceLevels: PropTypes.func.isRequired,
    fetchInterfaces: PropTypes.func,
    edit: PropTypes.bool,
    array: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    interfaceData: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      automation_task_id: PropTypes.number.isRequired,
      has_real_participations: PropTypes.bool,
      interface_levels: PropTypes.array,
    }).isRequired,
  };

  rules = {
    number: required(this.props.lang.REQUIRED),
    level_name: required(this.props.lang.REQUIRED),
    code: required(this.props.lang.REQUIRED),
  };

  screenshotTypeOptions = [
    {value: LEVEL_SCREENSHOT_TYPES.DESKTOP, label: this.props.lang.SCREENSHOT_TYPES.DESKTOP},
    {value: LEVEL_SCREENSHOT_TYPES.MOBILE, label: this.props.lang.SCREENSHOT_TYPES.MOBILE},
    {value: LEVEL_SCREENSHOT_TYPES.KIOSK, label: this.props.lang.SCREENSHOT_TYPES.KIOSK},
    {value: LEVEL_SCREENSHOT_TYPES.THUMBNAIL, label: this.props.lang.SCREENSHOT_TYPES.THUMBNAIL},
  ];

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.screenshotLevel = null;
    this.screenshotInput = null;

    this.setScreenshotInputRef = (element) => {
      this.screenshotInput = element;
    };
  }

  componentDidMount() {
    this.fetchScenarios();
  }

  fetchScenarios = () => {
    const params = {
      q: {
        automation_task_id_eq: this.props.interfaceData.automation_task_id,
      },
    };

    return this.props.getScenarios(params);
  };

  toggleLoading = () => {
    this.setState(({isLoading}) => ({isLoading: !isLoading}));
  };

  handleFormSubmit = (values) => {
    const {interfaceData, edit} = this.props;

    if (edit) {
      const data = mapLevelsForSave(values.levels, interfaceData.interface_levels);
      this.toggleLoading();
      this.props.patchInterfaceLevels(interfaceData.id, data).then(this.props.fetchInterfaces).then(this.props.onClose);
    } else {
      this.props.onNext(values.levels);
    }
  };

  getScenarioOptions = () => {
    return this.props.scenarios.map((scenario) => ({
      label: scenario.name,
      value: scenario.id,
    }));
  };

  addScreenshot = (event) => {
    const file = event.currentTarget.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64 = reader.result;
      const screenshot = createScreenshot({image: file, base64});
      this.props.array.push(`${this.screenshotLevel}.screenshots`, screenshot);
    };

    reader.readAsDataURL(file);
    this.screenshotInput.value = null;
  };

  openBase64 = (base64) => {
    const tab = window.open();
    tab.document.body.innerHTML = `<img src="${base64}" alt=""/>`;
  };

  renderImage = (src) => <img className="image-preview" src={src} alt="" />;

  renderScreenshots = ({fields}) => {
    const {lang} = this.props;

    if (!fields.length) {
      return null;
    }

    return (
      <div className="form-field-wrap">
        <div className="input__label">{lang.SCREENSHOTS}</div>
        <div className="set-interface-levels-modal__screenshots-block">
          {fields.map((screenshot, index) => {
            const {
              base64,
              image: {url},
            } = fields.get(index);
            return (
              <div key={index} className="set-interface-levels-modal__screenshots-block-item">
                <Field
                  cssModifier="set-interface-levels-modal__screenshot-type-field"
                  name={`${screenshot}.screenshot_type`}
                  type="select"
                  searchable={false}
                  options={this.screenshotTypeOptions}
                  component={fieldTemplate}
                />
                <div className="set-interface-levels-modal__screenshot-preview">
                  {base64 && (
                    <a href="#" onClick={() => this.openBase64(base64)}>
                      {this.renderImage(base64)}
                    </a>
                  )}
                  {url && (
                    <a href={url} target="_blank" rel="noreferrer">
                      {this.renderImage(url)}
                    </a>
                  )}
                </div>
                <button
                  className="set-interface-levels-modal__screenshot-delete-button icon-cross"
                  onClick={() => fields.remove(index)}
                  type="button"
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  renderLevels = ({fields}) => {
    const {lang} = this.props;
    const {has_real_participations = false} = this.props.interfaceData;
    const {rules} = this;

    return (
      <div className="set-interface-levels-modal__levels">
        <input type="file" accept="image/*" ref={this.setScreenshotInputRef} onChange={this.addScreenshot} />
        {fields.map((level, index) => {
          return (
            <div key={index} className="set-interface-levels-modal__level-item">
              <FormFieldWrap>
                <Field
                  cssModifier="set-interface-levels-modal__number-field"
                  label={lang.NUMBER_LABEL + '*'}
                  name={`${level}.number`}
                  type="text"
                  component={fieldTemplate}
                  validate={rules.number}
                  normalize={numberNormalizer}
                  disabled={has_real_participations}
                />
                <Field
                  cssModifier="set-interface-levels-modal__name-field"
                  label={lang.NAME_LABEL + '*'}
                  name={`${level}.level_name`}
                  type="text"
                  component={fieldTemplate}
                  validate={rules.level_name}
                />
                <Field
                  cssModifier="set-interface-levels-modal__code-field"
                  label={lang.CODE_LABEL + '*'}
                  name={`${level}.code`}
                  type="text"
                  component={fieldTemplate}
                  validate={rules.code}
                  maxLength="6"
                />
                <div className="set-interface-levels-modal__delete-button">
                  <button
                    className="button button--circle button--bg-7"
                    type="button"
                    onClick={() => fields.remove(index)}
                    disabled={fields.length === 1 || has_real_participations}
                  >
                    <Icon name="trash" className="button__icon" width={17} height={19} />
                  </button>
                </div>
              </FormFieldWrap>
              <FormFieldWrap>
                <Field
                  cssModifier="set-interface-levels-modal__scenario-field select--view-2"
                  label={lang.SCENARIO_LABEL}
                  name={`${level}.scenarios`}
                  type="select"
                  multi
                  searchable={true}
                  options={this.getScenarioOptions()}
                  component={fieldTemplate}
                />
                <Field
                  cssModifier="set-interface-levels-modal__min-level-field"
                  label={lang.MINIMUM_LEVEL_LABEL}
                  name={`${level}.minimum_level`}
                  type="checkbox"
                  component={fieldTemplate}
                />
                <button
                  className="set-interface-levels-modal__image-button button button--bg-7"
                  type="button"
                  onClick={() => {
                    this.screenshotLevel = level;
                    this.screenshotInput.click();
                  }}
                >
                  <Icon name="plus" />
                  <Icon name="image" />
                </button>
              </FormFieldWrap>
              <FieldArray name={`${level}.screenshots`} component={this.renderScreenshots} />
            </div>
          );
        })}

        <div className="set-interface-levels-modal__add-level-wrap">
          <button
            className="button button--bg-7"
            onClick={() => fields.push(createLevel())}
            type="button"
            disabled={has_real_participations}
          >
            <Icon name="plus" className="button__icon" />
            <span>{lang.ADD_LEVEL}</span>
          </button>
        </div>
      </div>
    );
  };

  render() {
    const {onClose, onCancel, handleSubmit, interfaceData, edit, lang} = this.props;
    const {isLoading} = this.state;
    const title = `${lang.TITLE} ${!edit ? SetInterfaceLevelsModal.titleCaptionNotEdited : ''}`;

    return (
      <Modal
        show={true}
        dialogClassName="set-interface-levels-modal theme-color-8"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={title}
      >
        <form noValidate="noValidate" onSubmit={handleSubmit(this.handleFormSubmit)}>
          <div className="main-text break-word">{interfaceData.name}</div>
          <FieldArray name="levels" component={this.renderLevels} />
          <div className="set-interface-levels-modal__buttons">
            <button
              className="button button--bg-11 modal-window__footer-btn"
              type="button"
              onClick={onCancel}
              disabled={isLoading}
            >
              {edit ? lang.CANCEL_BUTTON : lang.GO_BACK_BUTTON}
            </button>
            <button className="button button--bg-7 modal-window__footer-btn" type="submit" disabled={isLoading}>
              {isLoading && <LoadingSpinner cssModifier="loader_gray" loading={true} />}
              {!isLoading && (edit ? lang.SAVE_BUTTON : lang.NEXT_BUTTON)}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const SetInterfaceLevelsForm = reduxForm({
  form: SetInterfaceLevelsModal.formName,
})(SetInterfaceLevelsModal);

export default connect(
  (state, props) => {
    const {
      edit,
      interfaceData: {interface_levels = []},
    } = props;

    let levels;

    if (edit) {
      levels = mapLevelsForForm(interface_levels);
    } else if (interface_levels) {
      levels = interface_levels;
    } else {
      levels = [createLevel({number: 1, code: 'HP', level_name: 'Homepage'})];
    }

    return {
      lang: state.languageState.payload.AUTOTASK.SET_INTERFACE_LEVELS_MODAL,
      scenarios: selectScenarios(state),
      initialValues: {levels},
    };
  },
  {
    getScenarios,
    patchInterfaceLevels,
  },
)(SetInterfaceLevelsForm);
