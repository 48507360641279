import React, {RefObject, useEffect, useMemo, useState} from 'react';

import {IMaskMixin, ReactMaskOpts} from 'react-imask';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch, useReduxForm} from 'client/services/hooks';

import {selectAutotaskParam} from 'client/ducks/autotask/selectors';
import {getPrizesByGamesId} from 'client/ducks/email-templates/actions';
import {selectPrizes} from 'client/ducks/email-templates/selectors';
import {getSMSSenders} from 'client/ducks/sms-senders/actions';
import {selectSMSSenders} from 'client/ducks/sms-senders/selectors';
import {sendTestSms} from 'client/ducks/sms-templates/actions';

import AppButton from 'client/common/buttons';
import {SelectField} from 'client/common/fields';
import {useToast} from 'client/common/hooks/useToast';
import Icon from 'client/common/icon';
import {TextInput} from 'client/common/inputs';
import {BaseInputProps} from 'client/common/inputs/base-input';
import Popover from 'client/common/popovers/popover';
import SelectDropdown from 'client/common/selects/select-dropdown';

import usePrizeVariableOptions, {
  hasPrizeVariable,
} from 'client/components/email-template-editor/hooks/usePrizeVariableOptions';
import {SMS_TEMPLATE_FORM_NAME} from 'client/components/sms-templates/modals/sms-template-modal';
import {Game} from 'client/models/prizes/types';
import {SmsTemplateKind} from 'client/models/sms-templates/types';

import cssModule from './sms-template-testing.module.scss';

const b = bem('sms-template-testing', {cssModule});

type MaskOpts = Omit<BaseInputProps, 'label'> & ReactMaskOpts;

const PhoneMaskInput = IMaskMixin<HTMLInputElement, MaskOpts>(({inputRef, ...props}) => (
  <TextInput {...props} inputRef={inputRef as RefObject<HTMLInputElement>} />
));

type SmsTemplateTestingProps = {
  template: {
    sms_sender_id: number | null;
    id: number;
  };
  message: string;
  onSend: (close: boolean) => Promise<void>;
  templateKind: SmsTemplateKind;
};

const SmsTemplateTesting: React.FC<SmsTemplateTestingProps> = (props) => {
  const {template, message, onSend, templateKind} = props;
  const dispatch = useDispatch();
  const {sms_sender_id} = template;
  const lang = useLanguage('SMS_TEMPLATES.MODALS.SMS_TEMPLATE_MODAL.TESTING');
  const {clientId} = useParams<{clientId: string}>();
  const [sending, toggleSending] = useToggle(false);
  const [prizeOption, setPrizeOption] = useState<number | null>(null);
  const prizes = useSelector(selectPrizes);
  const {lottery, instantWin} = usePrizeVariableOptions(templateKind?.category);
  const showPrize = Boolean(lottery || instantWin);
  const clientGames: Game[] = useSelector(selectAutotaskParam('client_games'));

  const {appendToastNotification} = useToast();

  const {formValues, change} = useReduxForm(SMS_TEMPLATE_FORM_NAME);

  const {data = [], loading} = useReduxFetch({
    action: getSMSSenders,
    selector: selectSMSSenders,
    actionArgs: {
      q: {
        client_id_eq: clientId,
        client_id_null: true,
        m: 'or',
        s: 'name',
      },
    },
  });

  useEffect(() => {
    if (showPrize && clientGames?.length) {
      const params = {
        gameIds: clientGames?.map(({id}) => id),
        sms: true,
      };
      dispatch(getPrizesByGamesId(params));
    }
  }, [showPrize, clientGames, dispatch]);

  useEffect(() => {
    const noPrize = !prizeOption && prizes.length;
    const wrongPrize = prizeOption && prizes.length && !prizes.map((i) => i.id).includes(prizeOption);

    if (noPrize || wrongPrize) {
      setPrizeOption(prizes[0].id);
    }
  }, [prizeOption, prizes]);

  const senderOptions = useMemo(
    () =>
      data.map((item: {id: number; name: string}) => ({
        value: item.id,
        label: item.name,
      })),
    [data],
  );

  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (!loading && !formValues?.sms_sender_id) {
      change('sms_sender_id', senderOptions[0]?.value);
    }
  }, [change, formValues?.sms_sender_id, loading, senderOptions]);

  useEffect(() => {
    change('sms_sender_id', sms_sender_id);
  }, [change, sms_sender_id]);

  const handleTestClick = async () => {
    toggleSending();
    await onSend(false);
    const formData = new FormData();
    formData.append('sms_sender_id', formValues?.sms_sender_id);
    formData.append('to', phone);
    formData.append('content', message);
    if (prizeOption && hasPrizeVariable(message)) {
      formData.append('prize_id', String(prizeOption));
    }

    const result = await sendTestSms(template.id, formData);

    if (result.ok) {
      appendToastNotification({type: 'success', title: lang.SUCCESS_MSG});
    } else {
      appendToastNotification({type: 'error', title: lang.ERROR});
    }
    toggleSending();
  };

  const testEnabled = formValues?.sms_sender_id && phone?.length > 11 && message;

  return (
    <div className={b({'with-prize': showPrize})}>
      {showPrize && <div className={b('background')} />}
      <div className={b('inputs')}>
        <p className={b('title')}>{lang.SENDER}</p>
        <SelectField
          name="sms_sender_id"
          selectClassName={b('sender')}
          options={senderOptions}
          maxMenuHeight={130}
          disabled={loading}
          simpleValue
        />
        {showPrize && (
          <>
            <p className={b('title')}>{lang.PRIZE}</p>
            <SelectDropdown
              className={b('prize')}
              value={prizeOption}
              onChange={setPrizeOption}
              options={prizes.map((i) => ({label: i.external_name, value: i.id}))}
              disabled={prizes.length === 0 || !hasPrizeVariable(message)}
              placeholder={prizes.length === 0 ? lang.NO_PRIZE_AVAILABLE : ''}
              simpleValue
            />
          </>
        )}
        <p className={b('title')}>{lang.TEST_PHONE}</p>
        <div className={b('test-inputs')}>
          <PhoneMaskInput
            className={b('phone-wrapper')}
            inputClassName={b('phone')}
            value={phone}
            onChange={(event) => setPhone((event.target as HTMLInputElement).value)}
            placeholder="+33612345678"
            mask="+{33}000000000"
            elements={<span className={b('phone-required')}>{lang.REQUIRED}</span>}
          />
          <AppButton
            label={lang.SEND}
            transparent
            disabled={!testEnabled}
            onClick={handleTestClick}
            loading={sending}
          />
          <Popover overlay={<span className={b('sending-hint-overlay')}>{lang.SENDING_HINT}</span>}>
            <Icon className={b('sending-hint-icon')} name="info" width={16} height={16} />
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default SmsTemplateTesting;
