import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {selectIsAdmin, selectIsUserLoaded} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import OperationsList from '../components/operations';

class OperationsPage extends Component {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const title = window.location.pathname.endsWith('/my')
      ? this.props.lang.MY_OPERATIONS_TITLE
      : this.props.lang.OPERATIONS_TITLE;

    this.props.setMetaAction({title: title});
  }

  render() {
    const {isAdmin, isUserLoaded} = this.props;

    if (!isUserLoaded) {
      return null;
    }

    if (!isAdmin) {
      return <Redirect to={CLIENT_PAGES.HOME} />;
    }

    return <OperationsList />;
  }
}

const mapStateToProps = (state) => ({
  lang: state.languageState.payload.OPERATIONS,
  isAdmin: selectIsAdmin(state),
  isUserLoaded: selectIsUserLoaded(state),
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationsPage);
