export function participantsWinnersSelector({participantsWinners}) {
  return participantsWinners.winners.map((winner) => ({
    ...winner,
    latest_participated_at: winner.min_level_reached_at
      ? winner.min_level_reached_at
      : winner.level_reached_or_participated_at,
  }));
}

export function participantsWinnersMappedSelector({participantsWinners}) {
  return participantsWinners.participations;
}

export function participantsWinnersMetaSelector({participantsWinners}) {
  return participantsWinners.meta;
}
