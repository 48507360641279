import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Icon from 'client/components/common/icon';
import Typography from 'client/components/common/typography';

import {TranslationJsx} from 'client/models/language/types';

import './new-operation-card.scss';

const b = bem('new-operation-card');

const NewOperationCard = (props) => {
  const {className, onClick, text} = props;

  return (
    <button type="button" className={classNames(b(), className)} onClick={onClick}>
      <Icon name="add" className={b('icon')} />
      <Typography variant="text" className={b('text')}>
        {text}
      </Typography>
    </button>
  );
};

NewOperationCard.propTypes = {
  text: TranslationJsx.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

NewOperationCard.defaultProps = {
  className: '',
};

export default NewOperationCard;
