import React from 'react';

import cn from 'classnames';
import moment from 'moment';

import bem from 'client/services/bem';

import cssModule from './client-device-period.module.scss';

type ClientDevicePeriodProps = {
  from: string;
  to: string;
  className?: string;
};

const b = bem('client-device-period', {cssModule});

const ClientDevicePeriod: React.FC<ClientDevicePeriodProps> = ({from, to, className}) => {
  return (
    <div className={cn(b(), className)}>
      <span>{moment(from).format('DD/MM/YY')}</span>
      <span>–</span>
      <span>{moment(to).format('DD/MM/YY')}</span>
    </div>
  );
};

export default ClientDevicePeriod;
