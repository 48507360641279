import React from 'react';

import PropTypes from 'prop-types';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons';
import BarChart from 'client/common/charts/bar-chart';

import './source-card.scss';

const b = bem('source-card');

const SourceCard = (props) => {
  const {source, totalCount, openable, showSubItemsIfNoTotal} = props;
  const [open, toggleOpen] = useToggle(false);

  const {participations_count = 0, name, listName} = source;
  const percent = totalCount > 0 ? Math.floor((participations_count / totalCount) * 100) : 0;
  const hasSubItems = source.records && source.records.length > 0;
  const barChartStyles = openable
    ? {
        barColor: 'participants',
        titleColor: 'text-normal',
        color: 'text-normal',
        textColor: 'text-normal',
        titleFontWeight: '600',
        titleWidth: 'auto',
      }
    : {
        barColor: 'participants-light',
        titleColor: 'text-additional',
        color: 'text-additional',
        textColor: 'text-additional',
        titleFontWeight: '400',
        titleWidth: 'auto',
      };

  return (
    <div className={b()}>
      <div className={b('item', {main: openable})}>
        <div className={b('item-title')}>{name || listName}</div>
        <div className={b('item-bar')}>
          <BarChart
            number={participations_count}
            percent={percent}
            bgColor="line-chart-bg-textfield"
            orientation="horizontal"
            classNameTotal={b('bar-total')}
            classNameStripe={b('bar-stripe')}
            {...barChartStyles}
          />
        </div>
        {openable && showSubItemsIfNoTotal && hasSubItems && (
          <AppButton
            transparent={true}
            className={b('item-switcher')}
            onClick={toggleOpen}
            color="text-normal"
            iconName={`arrow-${open ? 'up' : 'down'}`}
          />
        )}
      </div>
      {hasSubItems &&
        open &&
        source.records.map((record) => (
          <SourceCard key={record.id} source={record} totalCount={totalCount} isOpen={false} openable={false} />
        ))}
    </div>
  );
};

SourceCard.propTypes = {
  source: PropTypes.object.isRequired,
  totalCount: PropTypes.number,
  openable: PropTypes.bool,
  showSubItemsIfNoTotal: PropTypes.bool,
};

SourceCard.defaultProps = {
  totalCount: 0,
  openable: false,
  showSubItemsIfNoTotal: false,
};

export default SourceCard;
