export const getStatus = (validated: boolean, required: boolean, locked: boolean) => {
  if (!validated && required && !locked) {
    return {
      status: 'none',
      langKey: 'TO_BE_VALIDATED',
    };
  }

  if (!validated && required && locked) {
    return {
      status: 'fail',
      langKey: 'REFUSED',
    };
  }

  return {
    status: 'success',
    langKey: 'VALIDATED',
  };
};
