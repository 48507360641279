import React from 'react';

import pull from 'lodash/pull';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import {updateBroadcastList, getBroadcastDisplayItems} from 'client/ducks/broadcast/actions';
import {selectBroadcastDisplayItemsMappedTable} from 'client/ducks/broadcast/selectors';
import {getCustomColumns} from 'client/ducks/custom-columns/actions';
import {selectTaskBaseInfo} from 'client/ducks/message-email-sms-task/selectors';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {CLIENT_PAGES, COLUMN_ADAPTER_TYPES} from 'client/common/config';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import Icon from 'client/components/common/icon';
import SearchField from 'client/components/common/search-field';

import BCMailingToolbar from './components/bc-mailing-toolbar';
import CardColumn from './components/columns/card-column';
import ContactsColumn from './components/columns/contacts-column';
import ExportedColumn from './components/columns/exported-column';
import NameColumn from './components/columns/name-column';
import OptinsColumn from './components/columns/optins-column';
import ParticipationsAllColumn from './components/columns/participations-all-column';
import ParticipationsLastColumn from './components/columns/participations-last-column';

import cssModule from './bc-mailing-data-grid.module.scss';

const b = bem('bc-mailing-data-grid', {cssModule});

class BCMailingDataGrid extends ReactQueryParams {
  static TABLE_HEADER = 'Header';

  constructor(props) {
    super(props);

    this.state = {
      selectedRows: [],
      tableData: [],
      updatedData: {
        addedItems: [],
        deletedItems: [],
      },
      dataMap: {},
      unselectableRows: [],
      loading: false,
    };

    const langPayload = props.languageState.payload;

    this.LANGUAGE = {
      GENDERS: langPayload.GENDERS,
      MAILING_TABLE: langPayload.BROADCASTING_TASK.MAILING.TABLE,
      FILTERS: langPayload.BROADCASTING_TASK.MAILING.FILTERS,
      TABLE: langPayload.TABLE,
    };

    this.columnsMap = {
      card: {
        dataField: 'card',
        width: 70,
        dataFormat: this.renderCard,
        header: this.LANGUAGE.MAILING_TABLE.CARD_COLUMN,
      },
      first_name: {
        dataField: 'first_name',
        width: '165',
        dataSort: true,
        header: this.LANGUAGE.MAILING_TABLE.FIRST_NAME_COLUMN,
      },
      last_name: {
        dataField: 'last_name',
        width: '165',
        dataSort: true,
        header: this.LANGUAGE.MAILING_TABLE.LAST_NAME_COLUMN,
      },
      gender: {
        dataField: 'gender',
        width: '90',
        dataSort: true,
        header: this.LANGUAGE.MAILING_TABLE.GENDER_COLUMN,
      },
      birth_date: {
        dataField: 'birth_date',
        width: '60',
        dataSort: true,
        header: this.LANGUAGE.MAILING_TABLE.AGE_COLUMN,
      },
      contacts: {
        dataField: 'contacts',
        width: '70',
        dataFormat: this.renderContacts,
        header: this.LANGUAGE.MAILING_TABLE.CONTACTS_COLUMN,
      },
      opt_ins: {
        dataField: 'opt_ins',
        width: '110',
        dataSort: true,
        dataFormat: this.renderOptins,
        header: this.LANGUAGE.MAILING_TABLE.OPTINS_COLUMN,
      },
      visuals_count: {
        dataField: 'visuals_count',
        width: '85',
        dataSort: true,
        header: this.LANGUAGE.MAILING_TABLE.VISUALS_COLUMN,
      },
      created_at: {
        dataField: 'created_at',
        width: '95',
        dataSort: true,
        header: this.LANGUAGE.MAILING_TABLE.CREATED_COLUMN,
      },
      first_source: {
        name: 'first_source_created_at',
        dataField: 'first_source',
        width: '115',
        dataSort: true,
        dataFormat: this.renderExported,
        header: this.LANGUAGE.MAILING_TABLE.SOURCE_COLUMN,
      },
      last_export: {
        name: 'last_export_created_at',
        dataField: 'last_export',
        width: '115',
        dataSort: true,
        dataFormat: this.renderExported,
        header: this.LANGUAGE.MAILING_TABLE.EXPORTED_COLUMN,
      },
      last_participation: {
        dataField: 'last_participation',
        header: this.LANGUAGE.MAILING_TABLE.PARTICIPATIONS_COLUMN,
        subcolumns: [
          {
            dataField: 'latest_participated_at',
            width: '75',
            dataSort: true,
            header: this.LANGUAGE.MAILING_TABLE.PARTICIPATIONS_LAST_COLUMN,
            dataFormat: this.renderParticipationsLast,
            row: 1,
          },
          {
            dataField: 'participations_count',
            width: '90',
            dataSort: true,
            header: this.LANGUAGE.MAILING_TABLE.PARTICIPATIONS_ALL_COLUMN,
            dataFormat: this.renderParticipationsAll,
            row: 1,
          },
        ],
      },
      custom: {
        width: '100',
        dataSort: true,
        dataFormat: this.renderName,
      },
    };
  }

  componentDidMount() {
    this.setState({loading: true});
    Promise.all([this.props.getCustomColumns(this.props.clientId, true), this.getBroadcastDisplayItems()]).then(() =>
      this.setState({loading: false}),
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        selectedRows: this.props.data.defaultSelectedRows,
      });
    }
  }

  renderCard = (cell, row) => <CardColumn link={`${CLIENT_PAGES.LEADS_PROFILE}/${row.id}`} />;

  renderContacts = (cell, row) => <ContactsColumn hasEmail={row.hasEmail} hasPhone={row.hasPhone} />;

  renderName = (name) => <NameColumn name={name} />;

  renderOptins = (codes) => <OptinsColumn codes={codes} />;

  renderExported = (cell) => <ExportedColumn {...cell} />;

  renderParticipationsLast = (cell) => {
    const {isAdmin} = this.props;
    const {
      created_at: date,
      operation_name: name,
      client_type: clientType,
      client_id: clientId,
      operation_id: operationId,
    } = cell;

    return (
      <ParticipationsLastColumn
        date={date}
        name={name}
        clientType={clientType}
        clientId={clientId}
        operationId={operationId}
        isAdmin={isAdmin}
      />
    );
  };

  renderParticipationsAll = (cell) => {
    const {isAdmin} = this.props;
    const {lead_id, count, participations} = cell;

    return (
      <ParticipationsAllColumn
        count={count}
        participations={participations}
        showMoreText={this.LANGUAGE.MAILING_TABLE.SHOW_MORE_BUTTON}
        onClick={() =>
          this.props.onParticipationsClick({
            leadId: lead_id,
            participations: participations,
          })
        }
        isAdmin={isAdmin}
      />
    );
  };

  renderSearchField = () => {
    return (
      <SearchField
        placeholder={this.LANGUAGE.TABLE.SEARCH_PLACEHOLDER}
        defaultSearch={this.queryParams.search}
        onSearch={this.handleSearch}
        onClear={this.handleClear}
      />
    );
  };

  handleSearch = (value) => this.props.onSearch(value);

  handleClear = () => this.props.onSearchClear();

  renderToolbar = () => {
    return (
      <BCMailingToolbar
        className={b('toolbar', ['left'])}
        messageTask={this.props.messageTask}
        queryParams={this.queryParams}
        meta={this.props.meta}
        language={{
          LANGUAGE_FILTERS: this.LANGUAGE.FILTERS,
          LANGUAGE_GENDERS: this.LANGUAGE.GENDERS,
          LANGUAGE_TABLE: this.LANGUAGE.TABLE,
          LANGUAGE_MAILING_TABLE: this.LANGUAGE.MAILING_TABLE,
        }}
        handleSave={this.handleSave}
        updatedData={this.state.updatedData}
        renderSearchField={this.renderSearchField}
        handleShowFilters={this.props.onShowFilterClick}
        handleShowSettings={this.props.onShowSettingsClick}
        handleFiltersClear={this.props.onFiltersClear}
        handleFilterDelete={this.props.onFilterDelete}
      />
    );
  };

  handleSave = () => {
    const {broadcastId} = this.props;
    const {updatedData, dataMap} = this.state;

    const body = this.createDataBody(updatedData, dataMap);

    return this.props.updateBroadcastList(broadcastId, body).then(() => {
      this.setState({
        updatedData: {
          addedItems: [],
          deletedItems: [],
        },
      });
      this.props.onSave();
    });
  };

  handleCancel = () => {
    this.setState({
      updatedData: {
        addedItems: [],
        deletedItems: [],
      },
    });
  };

  getBroadcastDisplayItems = () => {
    const params = {
      q: {
        user_id_eq: this.props.userId,
      },
      include: 'column_adapter',
    };

    return this.props.getBroadcastDisplayItems(params);
  };

  setRowsState = (selectedRows, unselectableRows, ids, checked) => {
    const selectedRowsCopy = [...selectedRows];

    if (checked) {
      ids.forEach((id) => {
        if (!selectedRowsCopy.includes(id) && !unselectableRows.includes(id)) {
          selectedRowsCopy.push(id);
        }
      });
    } else {
      pull(selectedRowsCopy, ...ids);
    }

    return selectedRowsCopy;
  };

  setNextSelectedState = (checked, selectAll, row) => {
    let ids = [];
    let dataMap = {};

    const {updatedData, selectedRows} = this.state;

    const {
      data: {tableData, defaultSelectedRows, unselectableRows},
    } = this.props;

    if (selectAll) {
      ids = tableData.map((item) => {
        dataMap[item.id] = item.recipient_id;
        return item.id;
      });
    } else {
      dataMap[row.id] = row.recipient_id;
      ids = [row.id];
    }

    const newSelectedRows = this.setRowsState(selectedRows, unselectableRows, ids, checked);

    const newCalculatedData = this.calculateUpdatedData(
      updatedData,
      unselectableRows,
      defaultSelectedRows,
      ids,
      checked,
    );

    this.setState({
      selectedRows: newSelectedRows,
      updatedData: newCalculatedData,
      dataMap: {
        ...this.state.dataMap,
        ...dataMap,
      },
    });
  };

  handleSelect = (checked, row) => {
    this.setNextSelectedState(checked, false, row);
  };

  handleSelectAll = (checked) => {
    this.setNextSelectedState(checked, true);
  };

  calculateUpdatedData = (
    updatedData = {addedItems: [], deletedItems: []},
    unselectableRows = [],
    defaultSelectedRows = [],
    ids = [],
    checked = false,
  ) => {
    const ud = {...updatedData};

    ids.forEach((id) => {
      const defaultCase =
        !ud.addedItems.includes(id) && !unselectableRows.includes(id) && !defaultSelectedRows.includes(id);
      const notDefaultCase =
        !ud.deletedItems.includes(id) && !unselectableRows.includes(id) && defaultSelectedRows.includes(id);

      switch (true) {
        case checked && defaultCase:
          ud.addedItems.push(id);
          break;
        case checked && !defaultCase:
          pull(ud.deletedItems, id);
          break;
        case !checked && notDefaultCase:
          ud.deletedItems.push(id);
          break;
        case !checked && !notDefaultCase:
          pull(ud.addedItems, id);
          break;
        default:
        //
      }
    });

    return {
      addedItems: ud.addedItems,
      deletedItems: ud.deletedItems,
    };
  };

  createDataBody = (data, dataMap) => {
    const {addedItems, deletedItems} = data;

    const deleted = deletedItems.map((item) => {
      return {
        id: dataMap[item],
        _destroy: true,
      };
    });

    const added = addedItems.map((item) => {
      return {
        lead_id: item,
      };
    });

    return {
      broadcast_list: {
        broadcast_recipients: [...deleted, ...added],
      },
    };
  };

  getColumns = (displayItems) => {
    let columns = [];

    columns.push({...this.columnsMap.id});
    columns.push({...this.columnsMap.card});

    displayItems.forEach((item) => {
      const {type: adapterType, name: adapterName, column_id: adapterColumnId} = item;

      switch (adapterType) {
        case COLUMN_ADAPTER_TYPES.EXTERNAL: // custom column
          columns.push({
            ...this.columnsMap.custom,
            header: adapterName,
            dataField: adapterColumnId.toString(),
          });
          break;
        case COLUMN_ADAPTER_TYPES.INTERNAL: // default column
        case COLUMN_ADAPTER_TYPES.ASSOCIATION:
        case COLUMN_ADAPTER_TYPES.SYSTEM:
          switch (adapterName) {
            case 'phone':
              columns.push({
                ...this.columnsMap.contacts,
              });
              break;
            case 'email':
              break;
            default:
              columns.push({
                ...this.columnsMap[adapterName],
              });
          }
          break;
        default:
        //
      }
    });

    return columns.filter((i) => i.dataField);
  };

  parseColumnToClientTable = (column) => ({
    label: column.header || ' ',
    name: column.name || column.dataField,
    path: column.dataField,
    render: column.dataFormat && (({value, item}) => column.dataFormat(value, item)),
    width: +column.width,
    sortable: column.dataSort,
    subcolumns: column?.subcolumns?.map(this.parseColumnToClientTable),
  });

  render() {
    const {
      meta,
      displayItems,
      data: {tableData, unselectableRows},
      onPageChange,
      onSortChange,
    } = this.props;

    const {selectedRows} = this.state;

    return (
      <div>
        <div className={b('toolbar-wrapper')}>
          {this.renderToolbar()}
          <PerPageDropdown
            value={this.queryParams.perPage || '5'}
            onChange={(value) => onPageChange({perPage: value, page: 1})}
            simpleValue
          />
        </div>
        <ClientTable
          data={tableData}
          checkedRows={selectedRows.map((id) => ({id}))}
          sortField={this.queryParams.sort?.name}
          sortOrder={this.queryParams.sort?.order}
          loading={this.state.loading || this.props.loading}
          onSortChange={({sortField, sortOrder}) => onSortChange(sortField, sortOrder)}
          options={{
            toolBar: this.renderToolbar,
          }}
          renderCheckbox={(row) =>
            unselectableRows.includes(row.id) ? (
              <Icon name="lock" className={b('lock-icon')} height={24} width={23} />
            ) : null
          }
          onCheck={this.handleSelect}
          onCheckAll={this.handleSelectAll}
          checkable
          columns={this.getColumns(displayItems).map(this.parseColumnToClientTable)}
        />
        <PaginationBar
          data={tableData}
          currentPage={meta.current_page}
          totalPages={meta.total_pages}
          totalItems={meta.total_count}
          perPage={+this.queryParams.perPage || 5}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}
BCMailingDataGrid.propTypes = {
  data: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  languageState: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  messageTask: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  operationId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  broadcastId: PropTypes.string.isRequired,
  getCustomColumns: PropTypes.func.isRequired,
  onParticipationsClick: PropTypes.func.isRequired,
  onSearchClear: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onShowFilterClick: PropTypes.func.isRequired,
  onShowSettingsClick: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onFiltersClear: PropTypes.func.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

BCMailingDataGrid.defaultProps = {
  data: {},
};

const mapStateToProps = (state) => ({
  isAdmin: selectIsAdmin(state),
  languageState: state.languageState,
  messageTask: selectTaskBaseInfo(state),
  displayItems: selectBroadcastDisplayItemsMappedTable(state),
});

const mapDispatchToProps = {
  updateBroadcastList,
  getCustomColumns,
  getBroadcastDisplayItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(BCMailingDataGrid);
