import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';
import {useParams} from 'react-router';

import {useLanguage} from 'client/services/hooks';

import {clearHeaderLinkBack, setHeaderLinkBack} from 'client/ducks/header-link-back/actions';

import {AnimationPhotosContainer} from 'client/components/animations/components/animation-photos-container';

const AnimationPhotos = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const lang = useLanguage('ANIMATIONS.PAGES.PHOTOS');

  useEffect(() => {
    dispatch(
      setHeaderLinkBack({
        name: lang.HEADER_NAME,
        rootPage: false,
      }),
    );

    return () => {
      dispatch(clearHeaderLinkBack());
    };
  }, [dispatch, lang.HEADER_NAME]);

  return <AnimationPhotosContainer animationId={params.animationId} />;
};

export default AnimationPhotos;
