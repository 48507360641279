import React, {useMemo} from 'react';

import moment from 'moment';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import cssModule from './diy-op-publishing-status.module.scss';

const b = bem('diy-op-publishing-status', {cssModule});

type DiyOpPublishingStatusProps = {
  publishedAt?: string | null;
  isPublished: boolean;
};

const DiyOpPublishingStatus: React.FC<DiyOpPublishingStatusProps> = (props) => {
  const {publishedAt, isPublished} = props;

  const lang = useLanguage('DIY_OPERATION.STATUSES');

  const status = useMemo(() => {
    return isPublished ? (
      <span className={b('status', ['published'])}>
        {`${lang.LAST_PUBLISH} ${moment(publishedAt).format('DD/MM/YYYY HH:mm')}`}
      </span>
    ) : (
      <span className={b('status', ['not-published'])}>{lang.NOT_PUBLISH}</span>
    );
  }, [lang.LAST_PUBLISH, lang.NOT_PUBLISH, isPublished, publishedAt]);

  return <span className={b()}>{status}</span>;
};

export default DiyOpPublishingStatus;
