import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';
import LayoutPanel from 'client/common/panels/layout-panel';

import Spinner from 'client/components/common/spinner';

import {Translation} from 'client/models/language/types';

import cssModule from './custom-card.module.scss';

const b = bem('custom-card', {cssModule});

type CustomCardProps = {
  className?: string;
  iconName?: string;
  header?: React.ReactNode;
  title?: Translation;
  format?: string;
  contentStyles?: any;
  children?: React.ReactNode;
  loading?: boolean;
  loadingColor?: string;
};

const CustomCard: React.FC<CustomCardProps> = (props) => {
  const {
    className,
    iconName,
    title,
    header,
    format = 'row',
    contentStyles,
    children,
    loading,
    loadingColor = 'primary',
  } = props;

  return (
    <LayoutPanel className={cn(b(), className)}>
      <div className={b('content', [format])}>
        {loading ? (
          <Spinner color={loadingColor} centered />
        ) : (
          <>
            <div className={b('header-wrap')}>
              {iconName && <Icon name={iconName} />}
              <div className={b('header')}>{header ? header : title}</div>
            </div>
            <div className={b('body', [format])} style={contentStyles}>
              {children}
            </div>
          </>
        )}
      </div>
    </LayoutPanel>
  );
};

export default CustomCard;
