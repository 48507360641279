import React, {Component} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, FormSection, formValueSelector, reduxForm} from 'redux-form';

import {date, dateIsAfter, required} from 'client/services/validator';

import {leadsImportApi} from 'client/ducks/lead/actions';
import {selectTableReport} from 'client/ducks/lead/selectors';

import {LEAD_IMPORT_UPDATE_TYPES, LEAD_IMPORT_TYPES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';

const initialValues = {
  dateFrom: moment().format('DD/MM/YYYY'),
  dateTo: moment().add(1, 'day').format('DD/MM/YYYY'),
};

class LeadsGenerateApiImportModal extends Component {
  static defaultProps = {
    show: false,
    columnsGroup: {},
    users: [],
    columns: [],
    refresh: null,
  };

  static propTypes = {
    show: PropTypes.bool,
    lang: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    change: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    columns: PropTypes.array,
    users: PropTypes.array,
    acceptingRuleGroup: PropTypes.array,
    handleSubmit: PropTypes.func.isRequired,
    leadsImportApi: PropTypes.func.isRequired,
    generalColumns: PropTypes.array.isRequired,
    clientId: PropTypes.number.isRequired,
    columnsGroup: PropTypes.object,
    leadImportReportId: PropTypes.number,
    matchingRuleGroup: PropTypes.object,
    refresh: PropTypes.func,
  };

  static formName = 'LeadsGenerateApiImportModalForm';

  static checkboxColumns = {
    columnsGroup: 'columnsGroup',
    acceptingRuleGroup: 'acceptingRuleGroup',
    matchingRuleGroup: 'matchingRuleGroup',
    checkboxAllColumns: 'checkboxAllColumns',
    checkboxAllMatchingRule: 'checkboxAllMatchingRule',
    checkboxAllAcceptingRule: 'checkboxAllAcceptingRule',
  };

  constructor(props) {
    super(props);

    const {lang} = props;

    this.state = {
      showMatchingRuleWarning: true,
      isMatchingFieldTouched: false,
    };

    this.rules = {
      dateFrom: [
        date(lang.ERRORS.INVALID_DATE_FORMAT),
        required(lang.REQUIRED),
        (value) => {
          return moment.utc(value, 'DD-MM-YYYY').isSameOrAfter(moment.utc(), 'day')
            ? null
            : lang.ERRORS.INVALID_DATE_START;
        },
      ],
      dateTo: [
        date(lang.ERRORS.INVALID_DATE_FORMAT),
        required(lang.REQUIRED),
        (value, {dateFrom}) => {
          return value && dateFrom && !date('.')(dateFrom)
            ? dateIsAfter(lang.ERRORS.INVALID_DATE_BORDERS, 'dateFrom')(value, {dateFrom})
            : null;
        },
      ],
      user: [required(lang.REQUIRED)],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.props.initialize({
        matchingRuleGroup: {},
        acceptingRuleGroup: {},
        columnsGroup: {},
        update_kind: LEAD_IMPORT_UPDATE_TYPES.UPDATE_ALL,
        ...initialValues,
      });
      this.setState({
        showMatchingRuleWarning: true,
        isMatchingFieldTouched: false,
      });
    }

    if (
      this.props.matchingRuleGroup !== prevProps.matchingRuleGroup ||
      this.props.columnsGroup !== prevProps.columnsGroup
    ) {
      this.validateFormSection(this.props);
    }
  }

  onSave = (values) => {
    const {lang} = this.props;

    if (this.state.showMatchingRuleWarning) {
      this.setState({isMatchingFieldTouched: true});
      return;
    }

    const columnsGroup = Object.entries(values.columnsGroup);
    let transferItems = [];
    columnsGroup.forEach((el) => {
      if (el[1]) {
        transferItems.push({
          type: LEAD_IMPORT_TYPES.LEAD_IMPORT_ITEM,
          column_adapter_id: el[0].slice(1),
          required: !!values.acceptingRuleGroup[el[0]],
          unique: !!values.matchingRuleGroup[el[0]],
        });
      }
    });

    const importData = {
      api_lead_import: {
        name: `${lang.API_IMPORT} ${moment(new Date().toISOString()).format('DD/MM/YYYY')}`,
        comment: values.comment || '',
        client_id: this.props.clientId,
        from: moment(values.dateFrom, 'DD/MM/YYYY').toISOString(),
        to: moment(values.dateTo, 'DD/MM/YYYY').toISOString(),
        update_kind: values.update_kind,
        lead_transfer_items: transferItems,
        transferable_memberships: [
          {
            membership_id: values.user.value,
          },
        ],
      },
    };

    this.props.leadsImportApi(importData).then(() => {
      if (this.props.refresh) {
        this.props.refresh();
      }

      this.props.onClose();
    });
  };

  validateFormSection = (props) => {
    const matchingCols = props.matchingRuleGroup ? Object.keys(props.matchingRuleGroup) : [];
    const columnsGroup = props.columnsGroup ? Object.keys(props.columnsGroup) : [];
    const validate =
      matchingCols.some((el) => props.matchingRuleGroup[el]) && columnsGroup.some((el) => props.columnsGroup[el]);
    this.setState({showMatchingRuleWarning: !validate});
  };

  uncheckAllColumns = (checkBox) => {
    this.props.change(checkBox, false);
    this.setState({isMatchingFieldTouched: true});
  };

  toggleAllColumns = (e, colGroup) => {
    this.setState({isMatchingFieldTouched: true});
    let checkboxGroup;
    switch (colGroup) {
      case LeadsGenerateApiImportModal.checkboxColumns.columnsGroup:
      case LeadsGenerateApiImportModal.checkboxColumns.acceptingRuleGroup:
        checkboxGroup = this.props.columns;
        break;
      case LeadsGenerateApiImportModal.checkboxColumns.matchingRuleGroup:
        checkboxGroup = this.props.columns.filter(
          (el) => this.props.generalColumns.includes(el.name) && this.props.columnsGroup[`_${el.id}`],
        );
        break;
      default:
        checkboxGroup = [];
    }

    const result = checkboxGroup.reduce(
      (group, item) => ({
        ...group,
        [`_${item.id}`]: e.currentTarget.checked,
      }),
      {},
    );

    if (!e.currentTarget.checked && colGroup === LeadsGenerateApiImportModal.checkboxColumns.columnsGroup) {
      this.uncheckAllColumns(LeadsGenerateApiImportModal.checkboxColumns.checkboxAllMatchingRule);
      this.props.change(LeadsGenerateApiImportModal.checkboxColumns.matchingRuleGroup, {
        ...this.props.matchingRuleGroup,
        ...result,
      });
      this.uncheckAllColumns(LeadsGenerateApiImportModal.checkboxColumns.checkboxAllAcceptingRule);
      this.props.change(LeadsGenerateApiImportModal.checkboxColumns.acceptingRuleGroup, {
        ...this.props.acceptingRuleGroup,
        ...result,
      });
    }

    this.props.change(colGroup, result);
  };

  checkMatchingRule = (e) => {
    const names = this.props.columns.filter(
      (el) => el.name === this.props.generalColumns[0] || el.name === this.props.generalColumns[1],
    );
    const namesCols = names.reduce(
      (group, item) => ({
        ...group,
        [`_${item.id}`]: e.currentTarget.checked,
      }),
      {},
    );

    if (e.currentTarget.checked) {
      this.props.change(LeadsGenerateApiImportModal.checkboxColumns.columnsGroup, {
        ...this.props.columnsGroup,
        ...namesCols,
      });
      this.props.change(LeadsGenerateApiImportModal.checkboxColumns.matchingRuleGroup, {
        ...this.props.matchingRuleGroup,
        ...namesCols,
      });
    } else {
      this.props.change(LeadsGenerateApiImportModal.checkboxColumns.matchingRuleGroup, {
        ...this.props.matchingRuleGroup,
        ...namesCols,
      });
    }
  };

  checkName = (e, id) => {
    const names = this.props.columns.filter(
      (el) => el.name === this.props.generalColumns[0] || el.name === this.props.generalColumns[1],
    );
    const namesCols = names.reduce(
      (group, item) => ({
        ...group,
        [`_${item.id}`]: e.currentTarget.checked,
      }),
      {},
    );

    const otherCol = names.find((el) => el.id !== id).id;
    if (!e.currentTarget.checked) {
      this.props.change(LeadsGenerateApiImportModal.checkboxColumns.matchingRuleGroup, {
        ...this.props.matchingRuleGroup,
        ...namesCols,
      });
    }
    if (e.currentTarget.checked && this.props.matchingRuleGroup[`_${otherCol}`]) {
      this.props.change(LeadsGenerateApiImportModal.checkboxColumns.matchingRuleGroup, {
        ...this.props.matchingRuleGroup,
        ...namesCols,
      });
    }
  };

  render() {
    const {handleSubmit, columns, columnsGroup, users, show, onClose, onCancel, generalColumns, lang} = this.props;

    return (
      <Modal
        show={show}
        dialogClassName="modal-window--width-2 theme-color-2 leads-port-layout"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={lang.TITLE_API}
      >
        <form onSubmit={handleSubmit(this.onSave)}>
          <div className="theme-color-2">
            <div className="main-text main-text--small main-text--color-1">{lang.FILE_USED_COLS_LABEL}</div>
            <div className="content-group">
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMin: 200,
                  autoHeightMax: 360,
                }}
              >
                <div className="leads-port-layout__group">
                  <div className="leads-port-layout__group-header flex-container">
                    <div className="leads-port-layout__col-1">
                      <Field
                        label={lang.COLUMNS_COLUMN_TITLE}
                        name={LeadsGenerateApiImportModal.checkboxColumns.checkboxAllColumns}
                        type="checkbox"
                        onChange={(e) =>
                          this.toggleAllColumns(e, LeadsGenerateApiImportModal.checkboxColumns.columnsGroup)
                        }
                        component={fieldTemplate}
                      />
                    </div>
                    <div className="leads-port-layout__col-2">
                      <div className="flex-container flex-align-center">
                        <Field
                          label={lang.USE_ACCEPTING_RULE_COLUMN_TITLE}
                          name={LeadsGenerateApiImportModal.checkboxColumns.checkboxAllAcceptingRule}
                          type="checkbox"
                          onChange={(e) =>
                            this.toggleAllColumns(e, LeadsGenerateApiImportModal.checkboxColumns.acceptingRuleGroup)
                          }
                          component={fieldTemplate}
                        />
                      </div>
                    </div>
                    <div className="leads-port-layout__col-3">
                      <div className="flex-container flex-align-center">
                        <Field
                          label={lang.USE_MATCHING_RULE_COLUMN_TITLE + '*'}
                          name={LeadsGenerateApiImportModal.checkboxColumns.checkboxAllMatchingRule}
                          type="checkbox"
                          onChange={(e) =>
                            this.toggleAllColumns(e, LeadsGenerateApiImportModal.checkboxColumns.matchingRuleGroup)
                          }
                          component={fieldTemplate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="leads-port-layout__group-body flex-container">
                    <div className="leads-port-layout__col-1">
                      <FormSection name={LeadsGenerateApiImportModal.checkboxColumns.columnsGroup}>
                        {columns.map((col) => {
                          return (
                            <Field
                              key={col.id}
                              cssModifier="leads-port-layout__checkbox"
                              label={col.name}
                              name={`_${col.id}`}
                              type="checkbox"
                              onChange={(e) => {
                                if (col.name === generalColumns[0] || col.name === generalColumns[1]) {
                                  this.checkName(e);
                                }
                                this.uncheckAllColumns(LeadsGenerateApiImportModal.checkboxColumns.checkboxAllColumns);
                              }}
                              component={fieldTemplate}
                            />
                          );
                        })}
                      </FormSection>
                    </div>
                    <div className="leads-port-layout__col-2">
                      <FormSection name={LeadsGenerateApiImportModal.checkboxColumns.acceptingRuleGroup}>
                        {columns.map((col) => {
                          return columnsGroup[`_${col.id}`] ? (
                            <Field
                              key={col.id}
                              cssModifier="leads-port-layout__checkbox"
                              name={`_${col.id}`}
                              onChange={() =>
                                this.uncheckAllColumns(
                                  LeadsGenerateApiImportModal.checkboxColumns.checkboxAllAcceptingRule,
                                )
                              }
                              type="checkbox"
                              component={fieldTemplate}
                            />
                          ) : (
                            <div key={col.id} className="leads-port-layout__empty" />
                          );
                        })}
                      </FormSection>
                    </div>
                    <div className="leads-port-layout__col-3">
                      <FormSection name={LeadsGenerateApiImportModal.checkboxColumns.matchingRuleGroup}>
                        {columns.map((col) => {
                          return columnsGroup[`_${col.id}`] && generalColumns.includes(col.name) ? (
                            <Field
                              key={col.id}
                              cssModifier="leads-port-layout__checkbox"
                              name={`_${col.id}`}
                              onChange={(e) => {
                                if (
                                  col.name === this.props.generalColumns[0] ||
                                  col.name === this.props.generalColumns[1]
                                ) {
                                  this.checkMatchingRule(e, col.id);
                                }
                                this.uncheckAllColumns(
                                  LeadsGenerateApiImportModal.checkboxColumns.checkboxAllMatchingRule,
                                );
                              }}
                              type="checkbox"
                              component={fieldTemplate}
                            />
                          ) : (
                            <div key={col.id} className="leads-port-layout__empty" />
                          );
                        })}
                      </FormSection>
                    </div>
                  </div>
                </div>
              </CustomScrollbars>
            </div>
            {this.state.showMatchingRuleWarning && this.state.isMatchingFieldTouched && (
              <div className="message-error-text">{lang.NO_MATCH_ERROR_MESSAGE}</div>
            )}
            <div className="leads-port-layout__row">
              <div className="leads-port-layout__row-title">{lang.UPDATE_SETTINGS_TITLE}</div>
              <div className="flex-container flex-container--space-between">
                <Field
                  label={<span className="main-text">{lang.UPDATE_ALL_LABEL}</span>}
                  name="update_kind"
                  value={LEAD_IMPORT_UPDATE_TYPES.UPDATE_ALL}
                  type="radio"
                  component={fieldTemplate}
                />
                <Field
                  label={<span className="main-text">{lang.UPDATE_ONLY_EMPTY_LABEL}</span>}
                  name="update_kind"
                  value={LEAD_IMPORT_UPDATE_TYPES.UPDATE_ONLY_EMPTY_FIELDS}
                  type="radio"
                  component={fieldTemplate}
                />
                <Field
                  label={<span className="main-text">{lang.UPDATE_EXCEPT_EMPTY_LABEL}</span>}
                  name="update_kind"
                  value={LEAD_IMPORT_UPDATE_TYPES.UPDATE_EXCEPT_EMPTY}
                  type="radio"
                  component={fieldTemplate}
                />
                <Field
                  label={<span className="main-text">{lang.UPDATE_NOTHING}</span>}
                  name="update_kind"
                  value={LEAD_IMPORT_UPDATE_TYPES.UPDATE_NOTHING}
                  type="radio"
                  component={fieldTemplate}
                />
              </div>
            </div>

            <div className="leads-port-layout__row">
              <div className="leads-port-layout__row-title">{lang.PERIOD_IMPORTATION_TITLE}</div>
              <div className="flex-container">
                <div className="leads-port-layout__col-9 flex-inline-container">
                  <Field
                    cssModifier="leads-port-layout__datepicker"
                    label={lang.FROM_LABEL}
                    name="dateFrom"
                    type="datepicker"
                    validate={this.rules.dateFrom}
                    component={fieldTemplate}
                  />
                  <Field
                    cssModifier="leads-port-layout__datepicker"
                    label={lang.TO_LABEL}
                    name="dateTo"
                    type="datepicker"
                    validate={this.rules.dateTo}
                    component={fieldTemplate}
                  />
                </div>
                <div className="leads-port-layout__col-10">
                  <Field
                    cssModifier="select--view-4"
                    options={users.map((user) => {
                      return {
                        value: user.currentMembership,
                        label: user.full_name,
                        selectCustomOption: <div className="leads-port-layout__option-link">{user.email}</div>,
                      };
                    })}
                    label={lang.USER_ALLOWED_LABEL}
                    name="user"
                    type="select"
                    searchable={false}
                    component={fieldTemplate}
                    validate={this.rules.user}
                  />
                </div>
              </div>
            </div>
            <div className="leads-port-layout__row">
              <div className="leads-port-layout__col-4">
                <Field
                  cssModifier="leads-port-layout__textarea"
                  name="comment"
                  type="textarea"
                  label={lang.COMMENT_LABEL}
                  component={fieldTemplate}
                />
              </div>
            </div>
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button
              className="button button--bg-11 modal-window__footer-btn"
              type="button"
              onClick={onCancel || onClose}
            >
              {lang.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-2 modal-window__footer-btn" type="submit">
              {lang.GENERATE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const LeadsGenerateApiImportModalForm = reduxForm({
  form: LeadsGenerateApiImportModal.formName,
})(LeadsGenerateApiImportModal);

export default connect(
  (state) => {
    const selector = formValueSelector(LeadsGenerateApiImportModal.formName);
    return {
      lang: state.languageState.payload.LEADS_PORT.GENERATE_IMPORT_MODAL,
      columnsGroup: selector(state, LeadsGenerateApiImportModal.checkboxColumns.columnsGroup),
      matchingRuleGroup: selector(state, LeadsGenerateApiImportModal.checkboxColumns.matchingRuleGroup),
      dateFrom: selector(state, 'dateFrom'),
      dateTo: selector(state, 'dateTo'),
      leadsImportReport: selectTableReport(state, true),
    };
  },
  {leadsImportApi},
)(LeadsGenerateApiImportModalForm);
