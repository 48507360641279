import React from 'react';

import PropTypes from 'prop-types';

import CouponInfoRow from '../coupon-info-row';

const CouponInfoSogec = (props) => {
  const {lang, viewingCoupon} = props;

  return (
    <div>
      <CouponInfoRow label={lang.MAXIMUM_TOKENS_NUMBER}>{viewingCoupon.max_number_of_tokens}</CouponInfoRow>
      <CouponInfoRow sectionLabel={lang.SOGEC_PARAMETERS} />
      <CouponInfoRow label={lang.CODE}>{viewingCoupon.sogec_configuration.external_coupon_id}</CouponInfoRow>
      <CouponInfoRow label={lang.ID}>{viewingCoupon.sogec_configuration.external_campaign_id}</CouponInfoRow>
      <CouponInfoRow label={lang.LOGIN}>{viewingCoupon.sogec_configuration.login}</CouponInfoRow>
      <CouponInfoRow label={lang.PASSWORD}>{viewingCoupon.sogec_configuration.password}</CouponInfoRow>
    </div>
  );
};

CouponInfoSogec.propTypes = {
  lang: PropTypes.object.isRequired,
  viewingCoupon: PropTypes.shape({
    max_number_of_tokens: PropTypes.number,
    sogec_configuration: PropTypes.shape({
      external_coupon_id: PropTypes.string,
      external_campaign_id: PropTypes.string,
      login: PropTypes.string,
      password: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default CouponInfoSogec;
