import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import {required, arrayUnique} from 'client/services/validator';

import {selectCampaignNames} from 'client/ducks/campaigns/selectors';
import {selectInterfacesByType} from 'client/ducks/interfaces/selectors';

import {INTERFACE_TYPES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

class AtNewCampaignModal extends Component {
  static propTypes = {
    interfaces: PropTypes.arrayOf(PropTypes.object).isRequired,
    campaignNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    languageState: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static formName = 'NewCampaignModalForm';

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK.NEW_CAMPAIGN_MODAL;
    this.rules = {
      name: [
        required(this.LANGUAGE.NAME_REQUIRED_ERROR),
        (value) => {
          return arrayUnique(this.props.campaignNames, this.LANGUAGE.NAME_UNIQUE_ERROR)(value);
        },
      ],
      interfaceId: required(this.LANGUAGE.INTERFACE_ID_REQUIRED_ERROR),
    };
  }

  render() {
    const {onClose, handleSubmit, interfaces} = this.props;
    const {LANGUAGE, rules} = this;

    return (
      <Modal
        show={true}
        className="modal-window--width-1 theme-color-10 set-optin-modal"
        backdropClassName="modal-window__backdrop"
        onClose={onClose}
        title={LANGUAGE.TITLE}
      >
        <form noValidate="noValidate" onSubmit={handleSubmit}>
          <div className="form-field-wrap">
            <Field
              label={LANGUAGE.NAME_LABEL}
              name="name"
              type="text"
              component={fieldTemplate}
              validate={rules.name}
            />
          </div>
          <div className="form-field-wrap">
            <Field
              simpleValue
              label={LANGUAGE.INTERFACE_LABEL}
              name="interface_id"
              type="select"
              searchable={false}
              component={fieldTemplate}
              options={interfaces.map((item) => ({value: item.id, label: item.name}))}
              validate={rules.interfaceId}
            />
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onClose}>
              {LANGUAGE.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-6 modal-window__footer-btn" type="submit">
              {LANGUAGE.CONFIRM_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const form = reduxForm({
  form: AtNewCampaignModal.formName,
})(AtNewCampaignModal);

export default connect((state) => ({
  languageState: state.languageState,
  interfaces: [
    ...selectInterfacesByType(state, INTERFACE_TYPES.SURVEY_GIZMO),
    ...selectInterfacesByType(state, INTERFACE_TYPES.SURVEY_GIZMO),
    ...selectInterfacesByType(state, INTERFACE_TYPES.INTERNAL),
  ],
  campaignNames: selectCampaignNames(state),
}))(form);
