import React, {Component} from 'react';

import infoIconBlack from 'assets/images/Info_black.png';
import infoIconWhite from 'assets/images/Info_white.png';
import arrowRight from 'assets/images/arrow_after_branch_white.png';
import arrowLeft from 'assets/images/arrow_previous_branch_white.png';
import flagWhite from 'assets/images/flag_white.png';
import cn from 'classnames';
import find from 'lodash/find';
import isNull from 'lodash/isNull';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {transformDate} from 'client/services/helpers';

// import StepNav, {StepNavItem} from 'client/components/common/step-nav';
import {SCENARIO_STEP_TYPES} from 'client/common/config';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
// import {SCENARIO_STEP_NAMES} from 'client/common/config';
import {VTabsCol, VTabsPane} from 'client/components/common/v-tabs';

import BranchScenarioStep from '../../../scenario-run-table/components/steps/branch-step';
import CalculationScenarioStep from '../../../scenario-run-table/components/steps/calculation-scenario-step';
import CouponScenarioStep from '../../../scenario-run-table/components/steps/coupon-step';
import EmailScenarioStep from '../../../scenario-run-table/components/steps/email-step';
import InstantLotteryScenarioStep from '../../../scenario-run-table/components/steps/instant-lottery-step';
import ScheduledLotteryScenarioStep from '../../../scenario-run-table/components/steps/scheduled-lottery-step';
import SmsScenarioStep from '../../../scenario-run-table/components/steps/sms-step';
import SocialPostScenarioStep from '../../../scenario-run-table/components/steps/social-post-step';
import StepContainer from '../../../scenario-run-table/components/steps/step-container';
import TimerScenarioStep from '../../../scenario-run-table/components/steps/timer-step';
// import StepErrorContainer from '../../../scenario-run-table/components/steps/step-error-container';
import VariableValuesStep from '../../../scenario-run-table/components/steps/variable-values';

// import {VariablesList} from '../variables';
import './result-steps-list.scss';

const STEPS_MAPPING = {
  TimerScenarioStep: (props) => <TimerScenarioStep {...props} />,
  BranchScenarioStep: (props) => <BranchScenarioStep {...props} />,
  TwitterScenarioStep: (props) => <SocialPostScenarioStep {...props} />,
  FacebookScenarioStep: (props) => <SocialPostScenarioStep {...props} />,
  SmsScenarioStep: (props) => <SmsScenarioStep {...props} />,
  EmailScenarioStep: (props) => <EmailScenarioStep {...props} />,
  CalculationScenarioStep: (props) => <CalculationScenarioStep {...props} />,
  InstantLotteryScenarioStep: (props) => <InstantLotteryScenarioStep {...props} />,
  ScheduledLotteryScenarioStep: (props) => <ScheduledLotteryScenarioStep {...props} />,
  AppCouponScenarioStep: (props) => <CouponScenarioStep {...props} />,
  SogecCouponScenarioStep: (props) => <CouponScenarioStep {...props} />,
  WeezioCouponScenarioStep: (props) => <CouponScenarioStep {...props} />,
};

export const getScenarioIcon = (isInitial = false, index) => {
  if (!index) {
    return flagWhite;
  }
  return isInitial ? arrowLeft : arrowRight;
};

class ResultModalStepsList extends Component {
  static propTypes = {
    variables: PropTypes.array,
    lang: PropTypes.object.isRequired,
    scenarioSteps: PropTypes.array,
    scenarioExecutions: PropTypes.arrayOf(PropTypes.object),
    variableValues: PropTypes.object,
  };

  static defaultProps = {
    variables: [],
    variableValues: {},
    scenarioExecutions: [],
  };

  state = {
    selectedScenario: 0,
    selectedStep: null,
  };

  constructor(props) {
    super(props);
  }

  getValuedMessage = (message) => {
    const {variables, variableValues} = this.props;
    const variableIds = message.match(/{{[0-9]+}}/g);

    let result = message;

    if (!variableIds) {
      return result;
    }

    variableIds
      .map((i) => Number(i.slice(2, -2)))
      .forEach((id) => {
        const variable = find(variables, (v) => v.id === id);
        if (!variable) {
          return;
        }

        let valueToReplace = variableValues[id];

        switch (variable.kind) {
          case 'file':
            valueToReplace = valueToReplace && valueToReplace.url;
            break;

          case 'datetime':
            valueToReplace = valueToReplace && transformDate(valueToReplace, true, true);
            break;

          default:
            break;
        }
        result = result.replace(`{{${id}}}`, `{{${variable.full_name || variable.name}: ${valueToReplace}}}`);
      });

    return result;
  };

  renderInterfaceInfo = (scenarioExecution) => {
    const {lang} = this.props;
    if (scenarioExecution && scenarioExecution.interface) {
      const {type} = scenarioExecution.interface;
      return (
        <div>
          <h4 className="content-panel__title">{lang.INPUT_VARIABLES_TITLE}</h4>
          <div className="interface-variables">
            <div className="">
              <span className="variables-list--item">{`${lang.FROM} `}</span>
              <span>{`Interface "${scenarioExecution.interface.name}"`}</span>
            </div>
            <div className="">
              <span className="variables-list--item">ID: </span>
              <span>{scenarioExecution.interface.id}</span>
            </div>
            <div className="">
              <span className="variables-list--item">{`${lang.FAMILY_TYPE} `}</span>
              <span>{type === 'InternalInterface' ? 'Buzzeo App' : type.split(/(?=[A-Z])/).join(' ')}</span>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  renderScenarioExecutions = (scenarioExecutions) => {
    if (scenarioExecutions && scenarioExecutions.length) {
      const renderedExecutions = scenarioExecutions.map(this.renderScenarioExecution);

      return (
        <div className="custom-scrollbars__scroll-content">
          <h4 className="content-panel__title">{this.props.lang.TITLE}</h4>
          <div style={{marginTop: 20}}>{renderedExecutions}</div>
        </div>
      );
    }

    return null;
  };

  renderScenarioExecution = (scenarioExecution, index) => {
    return (
      <div key={scenarioExecution.id}>
        <div
          className="modal-scenario-list__item"
          onClick={() => this.setState({selectedScenario: index, selectedStep: null})}
        >
          <div className="step-header">
            <img
              className="step-header__icon arrow-icon"
              src={getScenarioIcon(scenarioExecution.initial, index)} // put flag here
              alt=""
            />
            <span>{`SCENARIO "${scenarioExecution.scenario.name}"`}</span>
          </div>
          {/* {stepNames[SCENARIO_STEP_NAMES[step.implementation.type]]} */}
          <div className="item__creation">
            {moment(scenarioExecution.created_at).format('DD.MM.YYYY HH:mm')}
            <span className="icon-info">
              <img className="step-header__icon" src={infoIconWhite} alt="" />
            </span>
          </div>
        </div>
        {scenarioExecution.scenario_step_snapshots.map((snapshot, idx) =>
          this.renderScenarioExecutionSteps(snapshot, idx, index),
        )}
      </div>
    );
  };

  renderScenarioExecutionSteps = (snapshot, index, parentIndex) => {
    const {lang} = this.props;

    return (
      <div key={snapshot.id}>
        <div
          className="item__information"
          onClick={() => this.setState({selectedStep: {parentIndex, index}, selectedScenario: null})}
        >
          <span className="item__name ellipsis-text">
            {lang[snapshot.implementation_type]} "{snapshot.name}"
          </span>
          <div className="item__creation">
            {moment(snapshot.snapshot_created_at).format('DD.MM.YYYY HH:mm')}
            <span className="icon-info">
              <img className="step-header__icon" src={infoIconBlack} alt="" />
            </span>
          </div>
        </div>
        {this.renderVariableList(snapshot, parentIndex)}
      </div>
    );
  };

  renderVariableList = (snapshot, parentIndex) => {
    const {variable_values} = this.props.scenarioExecutions[parentIndex];

    return (
      <ul className="variables-list">
        {snapshot.scenario_step_variables.map((variable) => {
          const name = variable.full_name || variable.name;
          let value;
          if (variable_values[variable.id] === 0) {
            value = variable_values[variable.id];
          } else {
            value = variable_values[variable.id] || 'N/A';
          }

          return (
            <li key={variable.id} className="variables-list--item">
              <div className="variables-list__label ellipsis-text" title={name}>
                {name}
              </div>
              <span>{`: ${value}`}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  renderInfoPanel = (scenarioExecutions) => {
    const {selectedScenario, selectedStep} = this.state;
    const currentStep = !isNull(selectedStep)
      ? scenarioExecutions[selectedStep.parentIndex].scenario_step_snapshots[selectedStep.index]
      : {};
    const values = !isNull(selectedStep) ? scenarioExecutions[selectedStep.parentIndex].variable_values : {};
    if (!scenarioExecutions.length) {
      return null;
    }

    return (
      <div>
        {!isNull(selectedScenario) && selectedScenario >= 0 ? (
          <VariableValuesStep
            variables={scenarioExecutions[selectedScenario].scenario.scenario_variables}
            values={scenarioExecutions[selectedScenario].variable_values}
          />
        ) : (
          <StepContainer
            passedScrollbarProps={{
              autoHeight: true,
              autoHeightMin: 600,
            }}
            variables={currentStep.scenario_step_variables}
            values={values}
            isLottery={currentStep.implementation_type === SCENARIO_STEP_TYPES.instantLottery}
          >
            {STEPS_MAPPING[currentStep.implementation_class_name]({
              step: currentStep,
              variableValues: values,
              getValuedMessage: this.getValuedMessage,
            })}
          </StepContainer>
        )}
      </div>
    );
  };

  render() {
    const {scenarioExecutions} = this.props;
    const {selectedScenario} = this.state;

    return (
      <div className="result-container">
        <VTabsPane
          eventKey="scenarios"
          className={cn('test-step-list__content-col', 'result-container__col-left')}
          activeKey="scenarios"
        >
          <VTabsCol>
            <div className="content-panel__panel modal-scenarios-list">
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMin: 500,
                  autoHeightMax: 600,
                  hideTracksWhenNotNeeded: true,
                }}
                cssModifier="custom-scrollbars--view-2 custom-scrollbars--hide-hor-scroll"
                style={{height: '100%'}}
              >
                {this.renderScenarioExecutions(scenarioExecutions)}
              </CustomScrollbars>
            </div>
          </VTabsCol>
        </VTabsPane>

        <VTabsPane
          eventKey="output"
          className={cn('test-step-list__content-col', 'result-container__col-right')}
          activeKey="output"
        >
          <VTabsCol>
            <div className="content-panel__panel scenarios-list">
              {!isNull(selectedScenario) && this.renderInterfaceInfo(scenarioExecutions[0])}
              {!isNull(selectedScenario) && <h4 className="content-panel__title">{this.props.lang.VARIABLES_TITLE}</h4>}
              {this.renderInfoPanel(scenarioExecutions)}
            </div>
          </VTabsCol>
        </VTabsPane>
      </div>
    );
  }
}

export default connect((state) => ({
  lang: state.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.SCENARIO_STEPS,
}))(ResultModalStepsList);
