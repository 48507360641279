import React, {useEffect, useState} from 'react';

import isEqual from 'lodash/isEqual';

import bem from 'client/services/bem';
import {removeSpaces} from 'client/services/formatters';
import {useLanguage, useAppMedia} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import AppButton from 'client/common/buttons/app-button';
import SelectField from 'client/common/fields/select-field';
import TextField from 'client/common/fields/text-field';
import Icon from 'client/common/icon';

import {WINNING_SEARCH_TYPES} from './constants';
import getInitialValues from './getInitialValues';
import {WinningSearchValues} from './types';
import validateValues from './validateValues';

import cssModule from './winning-search-form.module.scss';

const b = bem('winning-search-form', {cssModule});

export const winningSearchFormName = 'WinningSearchForm';

export const winningToolbarSearchFormName = 'WinningToolbarSearchForm';

type WinningSearchFormProps = {
  onSearch: (values: WinningSearchValues) => void;
  onChangeForm?: (value: boolean) => void;
  inline?: boolean;
  validateEmpty?: boolean;
  initialValues?: WinningSearchValues;
  showSearchControls?: boolean;
};

const WinningSearchForm: ReduxFormFC<WinningSearchFormProps, WinningSearchValues> = (props) => {
  const {
    form,
    touch,
    handleSubmit,
    onSearch,
    onChangeForm,
    inline,
    validateEmpty = true,
    initialValues,
    showSearchControls,
  } = props;
  const lang = useLanguage('WINNINGS.FORMS.WINNING_SEARCH_FORM');
  const {isTablet} = useAppMedia();
  const [submittedValues, setSubmittedValues] = useState<WinningSearchValues | null>(null);

  const {invalid, formValues, change} = useReduxForm(form, {
    initialValues: initialValues || getInitialValues(),
    validate: (values) => validateValues(values, lang.ERRORS, validateEmpty),
  });

  useEffect(() => {
    if (submittedValues && !isEqual(submittedValues, formValues)) {
      onChangeForm?.(false);
      setSubmittedValues(null);
    }
  }, [formValues, onChangeForm, submittedValues]);

  const handleSearch = (values: WinningSearchValues) => {
    setSubmittedValues({...values});
    onSearch(values);
  };

  return (
    <div className={b({mobile: isTablet, inline})}>
      <form onSubmit={handleSubmit(handleSearch)}>
        <div className={b('content')}>
          <SelectField
            className={b('type-select')}
            name="search_type"
            label={lang.SELECT_SEARCH_TYPE}
            options={[
              {value: WINNING_SEARCH_TYPES.FULL_NAME, label: lang.FULL_NAME},
              {value: WINNING_SEARCH_TYPES.EMAIL, label: lang.EMAIL},
              {value: WINNING_SEARCH_TYPES.PHONE, label: lang.PHONE},
              {value: WINNING_SEARCH_TYPES.TOKEN, label: lang.TOKEN},
              {value: WINNING_SEARCH_TYPES.LOYALTY_ID, label: lang.LOYALTY_ID},
            ]}
            simpleValue
          />
          <div className={b('row')}>
            {formValues.search_type === WINNING_SEARCH_TYPES.FULL_NAME ? (
              <>
                <TextField name="first_name" label={lang.FIRST_NAME} format={removeSpaces} />
                <TextField name="last_name" label={lang.LAST_NAME} format={removeSpaces} />
              </>
            ) : (
              <>
                {showSearchControls && (
                  <Icon
                    name="search-1"
                    width={24}
                    height={24}
                    className={b('icon', ['search'])}
                    onClick={handleSubmit(handleSearch)}
                  />
                )}
                <TextField
                  inputClassName={showSearchControls ? b('input-with-controls') : null}
                  name={formValues.search_type}
                  label={lang[formValues.search_type.toUpperCase() as keyof typeof lang]}
                  format={removeSpaces}
                  onKeyPress={({charCode}: {charCode: number}) => {
                    if (charCode === 13) {
                      touch(formValues.search_type);
                    }
                  }}
                />
                {showSearchControls && formValues[formValues.search_type] && (
                  <Icon
                    name="close"
                    width={16}
                    height={16}
                    className={b('icon', ['clear'])}
                    onClick={() => change(formValues.search_type, '')}
                  />
                )}
              </>
            )}
          </div>
          <div className={b('bottom')}>
            <AppButton className={b('search-button')} iconName="search" label={lang.SEARCH} disabled={invalid} submit />
          </div>
        </div>
      </form>
    </div>
  );
};

export const WinningToolbarSearchForm = reduxForm<WinningSearchFormProps, WinningSearchValues>({
  form: winningToolbarSearchFormName,
})(WinningSearchForm);

export default reduxForm<WinningSearchFormProps, WinningSearchValues>({
  form: winningSearchFormName,
})(WinningSearchForm);
