import React from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {useQueryParams} from 'client/services/hooks';
import useUserAccessLevel from 'client/services/hooks/useUserAccessLevel';

import {deleteInternalInstoreDashboard, setDefaultInstoreDashboard} from 'client/ducks/instore-dashboards/actions';
import {selectCurrentUser} from 'client/ducks/user/selectors';

import AppButton from 'client/common/buttons/app-button';
import {CheckboxInput} from 'client/common/inputs';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import useDashboardType from 'client/components/instore-dashboards/hooks/useDashboardType';
import InstoreDashboardBlockModal from 'client/components/instore-dashboards/modals/instore-dashboard-block-modal';
import useFetchDashboardBlocks from 'client/components/instore/containers/instore-dashboards-container/hooks/use-fetch-dashboard-blocks';

import cssModule from './instore-dashboard-toolbar.module.scss';

const b = bem('instore-dashboard-toolbar', {cssModule});

const InstoreDashboardToolbar = (props) => {
  const {dashboard, task, fetchTask, onEditDashboard, onDeleteDashboard} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('INSTORE_DASHBOARDS.TOOLBARS.INSTORE_DASHBOARD_TOOLBAR');
  const [showConfirmation, toggleConfirmation] = useToggle(false);
  const [showGraphModal, toggleShowGraphModal] = useToggle(false);
  const user = useSelector(selectCurrentUser);
  const [queryParams] = useQueryParams();
  const {dashboardId, campaignId} = queryParams;
  const {fetch} = useFetchDashboardBlocks({dashboardId, campaignId}, false);
  const {isUser, isGlobal, isWeezio} = useDashboardType(dashboard);
  const {isAdminOrNational} = useUserAccessLevel();

  const access = task.instore_task_accesses.find(({user_id}) => user_id === user.id);

  const handleDelete = async () => {
    await dispatch(deleteInternalInstoreDashboard(dashboard.id));
    toggleConfirmation();
    onDeleteDashboard();
  };

  const handleSetDefault = async () => {
    const value = access.default_dashboard_id === dashboard.id ? null : dashboard.id;
    await dispatch(setDefaultInstoreDashboard(access.id, value));
    fetchTask();
  };

  const onModalClose = (positionToSave, shouldScroll) => {
    toggleShowGraphModal();

    if (positionToSave) {
      fetch().then(() => {
        if (shouldScroll) {
          location.hash = positionToSave;
        }
      });
    }
  };

  return (
    <div className={b()}>
      <ConfirmationModal
        show={showConfirmation}
        title={lang.DELETING.TITLE.replace('{{NAME}}', dashboard.name)}
        message={lang.DELETING.MESSAGE}
        cancelText={lang.DELETING.CANCEL}
        confirmText={lang.DELETING.OK}
        onClose={toggleConfirmation}
        clientSide={true}
        onConfirm={handleDelete}
      />
      {showGraphModal && <InstoreDashboardBlockModal onClose={onModalClose} />}
      <div className={b('section')}>
        <h1 className={b('name')}>{dashboard.name}</h1>
        {!isUser && (
          <span className={b('tag', {global: isGlobal, weezio: isWeezio})}>{isGlobal ? lang.GLOBAL : lang.WEEZIO}</span>
        )}
        {isUser && (
          <>
            <AppButton
              className={b('edit-button')}
              iconName="edit-dashboard"
              asWrap={true}
              iconConfig={{title: lang.EDIT_DASHBOARD}}
              onClick={onEditDashboard}
            />
            {!dashboard.initial && (
              <AppButton
                className={b('delete-button')}
                iconName="delete-dashboard"
                asWrap={true}
                iconConfig={{title: lang.REMOVE_DASHBOARD}}
                onClick={toggleConfirmation}
              />
            )}
          </>
        )}
        <CheckboxInput
          className={b('default-checkbox')}
          label={lang.DEFAULT}
          checked={access?.default_dashboard_id === dashboard.id}
          color="primary"
          inversionColor={true}
          onCheck={handleSetDefault}
        />
      </div>
      {(isUser || (isGlobal && isAdminOrNational)) && (
        <div className={b('section')}>
          <AppButton iconName="plus-simple" label={lang.CREATE_GRAPH} onClick={toggleShowGraphModal} />
        </div>
      )}
    </div>
  );
};

InstoreDashboardToolbar.propTypes = {
  dashboard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    initial: PropTypes.bool,
  }).isRequired,
  task: PropTypes.shape({
    instore_task_accesses: PropTypes.arrayOf(
      PropTypes.shape({
        default_dashboard_id: PropTypes.number,
      }),
    ).isRequired,
  }).isRequired,
  fetchTask: PropTypes.func.isRequired,
  onEditDashboard: PropTypes.func.isRequired,
  onDeleteDashboard: PropTypes.func.isRequired,
};

export default InstoreDashboardToolbar;
