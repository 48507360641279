import React, {useState} from 'react';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {createAgencyClient, updateAgencyClient} from 'client/ducks/agencies/actions';
import {interpolate} from 'client/ducks/language/helpers';
import {getSubsidiaries} from 'client/ducks/subsidiaries/actions';
import {selectSubsidiaries} from 'client/ducks/subsidiaries/selectors';

import AppButton from 'client/common/buttons';
import {useToast} from 'client/common/hooks/useToast';
import Modal from 'client/common/modals/modal';

import initial from './initial';
import {AgencyCreateClientStep1, AgencyCreateClientStep2} from './steps';
import {AgencyCreateClientModalFormValues, AgencyCreateClientModalProps, ErrorResponse} from './types';
import validate from './validate';

import cssModule from './agency-client-modal.module.scss';

const b = bem('agency-client-modal', {cssModule});

const STEPS = [AgencyCreateClientStep1, AgencyCreateClientStep2];

export const AgencyCreateClientModalFormName = 'AgencyCreateClientModalForm';

const AgencyClientModal: ReduxFormFC<AgencyCreateClientModalProps, AgencyCreateClientModalFormValues> = (props) => {
  const {initialData, onClose, agencyId, onSubmit, handleSubmit} = props;

  const [step, setStep] = useState(0);

  const {appendToastNotification} = useToast();

  const lang = useLanguage('AGENCIES.MODALS.AGENCY_CREATE_CLIENT_MODAL');
  const langErrors = useLanguage('AGENCIES.MODALS.AGENCY_CREATE_CLIENT_MODAL.ERRORS');

  const {formValues, valid, reset} = useReduxForm<AgencyCreateClientModalFormValues>(AgencyCreateClientModalFormName, {
    validate: (values) => validate(values, {lang: langErrors, currentStep: step}),
    validateDeps: [step],
    initialValues: initial(initialData),
  });

  const {loading: loadingCreate, fetch: fetchCreate} = useReduxFetch({
    action: createAgencyClient,
    fetchOnMount: false,
  });
  const {loading: loadingUpdate, fetch: fetchUpdate} = useReduxFetch({
    action: updateAgencyClient,
    fetchOnMount: false,
  });

  const {data: subsidiaries, fetch: fetchSubsidiaries} = useReduxFetch({
    action: getSubsidiaries,
    selector: selectSubsidiaries,
    actionArgs: {
      q: {
        name_eq: 'Weezio',
      },
    },
  });

  const CurrentStep = STEPS[step] || (() => null);

  const loading = loadingUpdate || loadingCreate;

  const handleClickNext = async () => {
    setStep((prev) => prev + 1);

    if (step === 0) {
      await fetchSubsidiaries();
    }
  };

  const handleClickBack = () => {
    setStep((prev) => prev - 1);
  };

  const handleClose = () => {
    reset();
    setStep(0);
    onClose();
  };

  const handleClickSave = handleSubmit(async (values) => {
    try {
      const body = {
        ...values,
        agency_id: agencyId,
        subsidiary_id: subsidiaries[0].id,
      };
      if (body.logo_image?.data?.startsWith('http')) {
        delete body.logo_image;
      }
      if (initialData) {
        await fetchUpdate(initialData.id, body);
      } else {
        await fetchCreate(body);
      }
      onSubmit();
      handleClose();
      appendToastNotification({
        type: 'success',
        title: interpolate((initialData ? lang.UPDATED_SUCCESS : lang.SAVE_SUCCESS)?.toString(), {
          name: formValues.name,
        }),
      });
    } catch (e: unknown) {
      const error = e as ErrorResponse;

      if (error.response && error.response.exception) {
        appendToastNotification({
          type: 'error',
          title: error.response.exception,
        });
      } else {
        appendToastNotification({
          type: 'error',
          title: langErrors.SAVE_ERROR,
        });
      }
    }
  });

  const stepTitle = interpolate(lang.STEP_ELEMENT?.toString(), {
    current: (step + 1)?.toString(),
    total: STEPS.length.toString(),
  });

  return (
    <Modal
      onClose={handleClose}
      className={b()}
      title={initialData ? lang.TITLE_EDIT : lang.TITLE_CREATE}
      titleButton={<span className={b('step-title')}>{stepTitle}</span>}
    >
      <form className={b('form')}>
        <CurrentStep key={step} formValues={formValues} />
        <div className={b('footer')}>
          {step === 0 && <AppButton label={lang.CANCEL_BUTTON} size="small" transparent={true} onClick={handleClose} />}
          {step > 0 && <AppButton label={lang.BACK_BUTTON} size="small" transparent={true} onClick={handleClickBack} />}
          {step !== STEPS.length - 1 && (
            <AppButton label={lang.NEXT_BUTTON} size="small" onClick={handleClickNext} disabled={!valid} />
          )}
          {step === STEPS.length - 1 && (
            <AppButton
              label={lang.SAVE_BUTTON}
              size="small"
              onClick={handleClickSave}
              disabled={!valid}
              loading={loading}
            />
          )}
        </div>
      </form>
    </Modal>
  );
};

const Form = reduxForm<AgencyCreateClientModalProps, AgencyCreateClientModalFormValues>({
  form: AgencyCreateClientModalFormName,
})(AgencyClientModal);

Form.displayName = 'AgencyClientModalForm';

export default Form;
