import React, {useMemo} from 'react';

import cn from 'classnames';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectCurrentLanguage} from 'client/ducks/language/selectors';

import AppButton from 'client/common/buttons/app-button';
import SelectDropdown from 'client/common/selects/select-dropdown';

import {TranslationJsx} from 'client/models/language/types';

import {VALUE_FORMAT} from './constants';
import {getWeeks} from './helpers';

import './week-select-input.scss';

const b = bem('week-select-input');

const WeekSelectInput = (props) => {
  const {className, value, onChange, label} = props;
  const lang = useLanguage('COMMON.INPUTS.SELECTS.WEEK_SELECT_INPUT');
  const currentLanguage = useSelector(selectCurrentLanguage);

  const options = useMemo(() => {
    return getWeeks(value).map((date) => {
      const start = moment(date);
      const end = moment(date).add(6, 'days');

      const week = start.week();
      const startDay = start.format('DD');
      const startMonth = start.locale(currentLanguage).format('MMM');
      const endDay = end.format('DD');
      const endMonth = end.locale(currentLanguage).format('MMM');

      return {
        value: date,
        label: (
          <span className={b('option')}>
            <span className={b('option-week')}>{`${lang.WEEK_ABBR}${week}`}</span>
            <span className={b('option-period')}>{`${startDay} ${startMonth}-${endDay} ${endMonth}`}</span>
          </span>
        ),
      };
    });
  }, [lang, currentLanguage, value]);

  const handleBackClick = () => {
    onChange(moment(value).subtract(7, 'days').format(VALUE_FORMAT));
  };

  const handleNextClick = () => {
    onChange(moment(value).add(7, 'days').format(VALUE_FORMAT));
  };

  return (
    <div className={cn(b(), className)}>
      <label className={b('label')}>{label || lang.WEEK}</label>
      <AppButton iconName="arrow-left" onClick={handleBackClick} asWrap={true} />
      <SelectDropdown className={b('select')} options={options} value={value} onChange={onChange} simpleValue={true} />
      <AppButton iconName="arrow-right" onClick={handleNextClick} asWrap={true} />
    </div>
  );
};

WeekSelectInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: TranslationJsx,
};

WeekSelectInput.defaultProps = {
  className: null,
  value: null,
  label: null,
};

export default WeekSelectInput;
