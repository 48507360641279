import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import useReduxForm from 'client/services/hooks/use-redux-form';

import PrizeDiyScheduleFieldset from 'client/components/prizes/fieldsets/prize-diy-schedule-fieldset';
import {PrizeDiyModalFormName} from 'client/components/prizes/modals/prize-diy-modal';
import PrizeDiyRatioParticipationCheckboxPanel from 'client/components/prizes/panels/prize-diy-ratio-participation-checkbox-panel';
import PrizeDiyRatioTimeFieldset from 'client/components/prizes/panels/prize-diy-ratio-time-checkbox-panel';

import {INSTANT_WIN_TABS, INSTANT_WIN_TYPE_FIELD_NAME} from './constants';

import './prize-diy-instant-win-fieldset.scss';

const b = bem('prize-diy-instant-win-fieldset');

const FIELDSETS = {
  [INSTANT_WIN_TABS.RATIO_TIME]: PrizeDiyRatioTimeFieldset,
  [INSTANT_WIN_TABS.RATIO_PARTICIPATION]: PrizeDiyRatioParticipationCheckboxPanel,
  [INSTANT_WIN_TABS.SCHEDULE]: PrizeDiyScheduleFieldset,
};

const PrizeDiyInstantWinFieldset = (props) => {
  const {disabled} = props;
  const {formValues, change} = useReduxForm(PrizeDiyModalFormName);

  const handleChange = (event) => {
    const {value, name} = event.target;

    // ignore the change of other inputs located in fieldset
    if (name === INSTANT_WIN_TYPE_FIELD_NAME) {
      change(INSTANT_WIN_TYPE_FIELD_NAME, value);
    }
  };

  const activeTab = formValues[INSTANT_WIN_TYPE_FIELD_NAME];

  return (
    <fieldset className={b()} onChange={handleChange}>
      {Object.entries(FIELDSETS).map((fieldset) => {
        const [value, Component] = fieldset;

        return (
          <Component
            key={value}
            name={INSTANT_WIN_TYPE_FIELD_NAME}
            value={value}
            checked={value === activeTab}
            disabled={disabled}
            required={value === activeTab}
          />
        );
      })}
    </fieldset>
  );
};

PrizeDiyInstantWinFieldset.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default PrizeDiyInstantWinFieldset;
