export const maskEmail = (email) => {
  if (!email) {
    return '';
  }
  const [user, server] = email.split('@');
  const domains = server.split('.');

  return `${user}@${server.slice(0, 1)}**.${domains[domains.length - 1]}`;
};

export const maskPhoneNumber = (phone) => {
  if (!phone) {
    return '';
  }
  const stars = Array(phone.length - 4)
    .fill('X')
    .join('');
  return `${phone.slice(0, 2)}${stars}${phone.slice(0, -2)}`;
};
