import React from 'react';

import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {getClientPage} from 'client/services/helpers';

import GoogleMap from 'client/common/google-map';
import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import cssModule from './places-stores-map.module.scss';

const MARKER_TYPES = {
  PLACE: 'PLACE',
  STORE: 'STORE',
  GROUP_OF_PLACES: 'GROUP_OF_PLACES',
  PLAN: 'PLAN',
};

let LANGUAGE = {};

const b = bem('places-stores-map', {cssModule});

export class PlacesStoresMap extends React.Component {
  static Marker({type, place, ...props}) {
    // eslint-disable-next-line react/prop-types
    const Base = ({children, content}) => (
      <Popover
        shiftTop={6}
        shiftLeft={20}
        arrowOffset={70}
        overlayInnerStyle={{
          width: 280,
          left: -100,
        }}
        overlay={
          <CustomScrollbars
            scrollbarProps={{
              autoHeightMax: 111,
            }}
          >
            {content}
          </CustomScrollbars>
        }
      >
        {children}
      </Popover>
    );

    const clientPage = (client) => `${getClientPage(client)}/${client.id}`;

    switch (type) {
      case MARKER_TYPES.PLACE:
      case MARKER_TYPES.STORE:
        return (
          <Base
            content={
              <div>
                <h4>{place.name}</h4>
                <div>{place.street_address}</div>
                <div>{place.city_name}</div>
                <div>{place.city_zip}</div>
                {type === MARKER_TYPES.STORE && (
                  <div>
                    {LANGUAGE.STORE_OF__LABEL} <a href={clientPage(place.client)}>{place.client.name}</a>
                  </div>
                )}
              </div>
            }
          >
            <Icon className={b('marker', [type === MARKER_TYPES.STORE ? 'green' : 'blue'])} name="marker" />
          </Base>
        );

      case MARKER_TYPES.PLAN:
        return null;

      case MARKER_TYPES.GROUP_OF_PLACES:
        const first = props.places[0];
        return (
          <Base
            content={
              <div>
                <div>
                  <div>{first.street_address}</div>
                  <div>{first.city_name}</div>
                  <div>{first.city_zip}</div>
                </div>
                {props.places.map((item) => (
                  <div key={item.id}>
                    <h4>{item.name}</h4>
                    {item.client && (
                      <div>
                        {LANGUAGE.STORE_OF__LABEL} <a href={clientPage(item.client)}>{item.client.name}</a>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            }
          >
            <Icon className={b('marker', [type === MARKER_TYPES.STORE ? 'green' : 'blue'])} name="marker" />
          </Base>
        );

      default:
        return null;
    }
  }

  constructor(props) {
    super(props);

    setInterval(() => {
      const elems = document.getElementsByClassName('in-google-map');

      for (let elem of elems) {
        if (!elem.onwheel) {
          elem.addEventListener(
            'wheel',
            function (event) {
              if (event.stopPropagation) {
                event.stopPropagation();
              } else {
                event.cancelBubble = true;
              }
            },
            true,
          );
        }
      }
    }, 400);

    this.state = {
      groupedPlaces: [],
    };

    LANGUAGE = props.languageState;
  }

  componentDidMount() {
    this.setNewPlaces(this.props.places);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.setNewPlaces(this.props.places);
    }
  }

  setNewPlaces = (places) => {
    const groupedPlaces = places
      .reduce((array, place, index) => {
        if (index === 0) {
          array.push([place]);
        } else if (
          Math.abs(place.latitude - places[index - 1].latitude) <= 1e-6 &&
          Math.abs(place.longitude - places[index - 1].longitude) <= 1e-6
        ) {
          array[array.length - 1].push(place);
        } else {
          array.push([place]);
        }

        return array;
      }, [])
      .map((group) => {
        if (group.length === 1) {
          const data = {
            place: group[0],
            id: group[0].id,
            lat: group[0].latitude,
            lng: group[0].longitude,
            type: group[0].client ? MARKER_TYPES.STORE : MARKER_TYPES.PLACE,
          };
          return {
            data,
            ...data,
          };
        }

        let isItStore = group[0].client !== null;
        for (let place of group) {
          if ((place.client !== null) !== isItStore) {
            const data = {
              places: group,
              id: group[0].id,
              lat: group[0].latitude,
              lng: group[0].longitude,
              type: MARKER_TYPES.GROUP_OF_PLACES,
              content: 'both',
            };
            return {
              data,
              ...data,
            };
          }
        }

        const data = {
          places: group,
          id: group[0].id,
          lat: group[0].latitude,
          lng: group[0].longitude,
          type: MARKER_TYPES.GROUP_OF_PLACES,
          content: isItStore ? 'stores' : 'places',
        };
        return {
          ...data,
          data,
        };
      });
    this.setState({groupedPlaces});
  };

  render() {
    return (
      <GoogleMap markers={this.state.groupedPlaces} renderMarker={({data}) => <PlacesStoresMap.Marker {...data} />} />
    );
  }
}

PlacesStoresMap.propTypes = {
  places: PropTypes.array.isRequired,
  languageState: PropTypes.object,
  center: PropTypes.object,
  zoom: PropTypes.number,
};

export default PlacesStoresMap;
