import {LangsByPath} from 'client/services/hooks/useLanguage/types';

import {API_METHODS} from 'client/common/config';

import {SCREEN_FORMATS} from 'client/components/diy-operation/modals/diy-customization-modal/constants';
import {DIY_OP_TEMPLATE_MODAL_MODE} from 'client/components/diy-operation/modals/diy-op-template-modal/constants';
import {DiyOpTemplateMode} from 'client/components/diy-operation/modals/diy-op-template-modal/types';
import {Client} from 'client/models/client/types';
import {ACCESS_LEVEL} from 'client/models/common/constants';
import {AccessLevelType} from 'client/models/common/types';

export const hasOnline = (list: string[]) => {
  return list.includes(SCREEN_FORMATS.desktop) && list.includes(SCREEN_FORMATS.phone);
};

export const hasVertical = (list: string[]) => list.includes(SCREEN_FORMATS.kiosk_vertical);

export const hasHorizontal = (list: string[]) => list.includes(SCREEN_FORMATS.kiosk_horizontal);

export const hasDevice = (list: string[]) => hasVertical(list) || hasHorizontal(list);

export const hasRegionAccess = (list: string[]) => list.includes(ACCESS_LEVEL.REGION);

export const hasStoreAccess = (list: string[]) => list.includes(ACCESS_LEVEL.STORE);

export const getLabels = (
  data: {mode: DiyOpTemplateMode; type: string},
  lang: LangsByPath<['DIY_OPERATION.MODALS.DIY_OP_TEMPLATE_MODAL']>,
) => {
  const {type, mode} = data;

  const result = {
    titleModal: lang[`${mode}_${type}`.toUpperCase() as keyof typeof lang],
    templateName:
      type === 'general' ? lang.TEMPLATE_NAME : lang[`TEMPLATE_${type}_NAME`.toUpperCase() as keyof typeof lang],
    saveButton: mode === 'save' ? lang.SAVE_TO_CATALOG : lang.SAVE,
    saveToast: lang[`${type}_SAVED`.toUpperCase() as keyof typeof lang],
  };

  if (mode === DIY_OP_TEMPLATE_MODAL_MODE.DUPLICATE) {
    result.saveButton = lang.DUPLICATE;
    result.saveToast = lang[`${type}_DUPLICATED`.toUpperCase() as keyof typeof lang];
  }

  if (mode === DIY_OP_TEMPLATE_MODAL_MODE.SHARE) {
    result.saveButton = lang.NEXT;
  }

  return result;
};

export const getSearchNameArgs = (params: {isShare: boolean; clientId: string; search: string}) => {
  const {isShare, clientId, search} = params;
  return {
    url: API_METHODS.CLIENT_TEMPLATES,
    queryParams: {
      q: {
        [isShare ? 'client_agency_id_eq' : 'client_id_eq']: clientId,
        title_matches: search,
      },
      page: 1,
      per_page: 1,
    },
  };
};

export const getFetchClientsArgs = (clientId: string) => {
  return {
    url: API_METHODS.CLIENTS,
    queryParams: {
      include_client_available_places_count: null,
      include_client_available_regions_count: null,
      q: {agency_id_eq: clientId},
      s: ['name asc'],
    },
  };
};

export const getShareToVisibility = (params: {
  accessLevel: AccessLevelType;
  isSave: boolean;
  isShare: boolean;
  isAgency: boolean;
  clients: Client[];
}) => {
  const {accessLevel, isSave, isShare, isAgency, clients} = params;

  if (isShare) {
    const hasRegions = clients.some((i) => i.client_network && i.available_regions_count);
    const hasStores = clients.some((i) => i.client_network && i.available_places_count);

    return {
      showBlock: !isAgency && (hasRegions || hasStores),
      showRegion: hasRegions,
      showStore: hasStores,
    };
  }

  return {
    showBlock: !isSave && !isAgency && accessLevel !== ACCESS_LEVEL.STORE,
    showRegion: accessLevel === ACCESS_LEVEL.NATIONAL || accessLevel === ACCESS_LEVEL.CLIENT_ADMIN,
    showStore: true,
  };
};
