import React from 'react';

import PropTypes from 'prop-types';

import {COUPON_TYPES, VALIDITY_TYPES} from 'client/ducks/coupons/constants';

import {TextField, DropdownField, TextAreaField, DatepickerField} from 'client/components/common/fields';

const AppFieldset = (props) => {
  const {lang, formValues, isEdit} = props;
  const isClientType = formValues.type === COUPON_TYPES.CLIENT;

  const typeOptions = [
    {value: COUPON_TYPES.WEEZIO, label: lang.TYPE_WEEZIO},
    {value: COUPON_TYPES.SOGEC, label: lang.TYPE_SOGEC},
    {value: COUPON_TYPES.APP, label: lang.TYPE_APP},
  ];

  if (isClientType) {
    typeOptions.push({value: COUPON_TYPES.CLIENT, label: lang.TYPE_CLIENT});
  }

  return [
    <TextField key="internal_name" name="internal_name" label={lang.INTERNAL_NAME} disabled={isClientType} />,
    <TextField key="internal_code" name="internal_code" label={lang.INTERNAL_CODE} disabled={isClientType} />,
    <TextField key="external_name" name="external_name" label={lang.EXTERNAL_NAME} disabled={isClientType} />,
    <DropdownField key="type" name="type" label={lang.TYPE} readOnly={isEdit} options={typeOptions} />,
    <DropdownField
      key="validity_type"
      name="validity_type"
      label={lang.VALIDITY_TYPE}
      disabled={isClientType}
      options={[
        {value: VALIDITY_TYPES.FIXED_VALUE, label: lang.FIXED_VALUE},
        {value: VALIDITY_TYPES.DURATION, label: lang.DURATION},
      ]}
    />,
    <TextField
      key="validity_days"
      name="validity_days"
      label={lang.VALIDITY_DAYS}
      disabled={isClientType}
      show={formValues.validity_type === VALIDITY_TYPES.DURATION}
    />,
    <DatepickerField
      key="validity_from"
      name="validity_from"
      label={lang.VALIDITY_FROM}
      disabled={isClientType}
      show={formValues.validity_type === VALIDITY_TYPES.FIXED_VALUE}
    />,
    <DatepickerField
      key="validity_to"
      name="validity_to"
      label={lang.VALIDITY_TO}
      disabled={isClientType}
      show={formValues.validity_type === VALIDITY_TYPES.FIXED_VALUE}
    />,
    <TextAreaField key="comment" name="comment" label={lang.COMMENTS} disabled={isClientType} />,
  ];
};

AppFieldset.propTypes = {
  lang: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default AppFieldset;
