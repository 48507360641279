import {RSAA} from 'redux-api-middleware';

import {getQueryParams} from 'client/services/helpers';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function changeUsersPerPageAction(perPage) {
  return {
    type: types.USERS_CHANGE_PER_PAGE,
    perPage,
  };
}
export function changeOngoingOperationsPerPageAction(perPage) {
  return {
    type: types.ONGOING_OPERATIONS_CHANGE_PER_PAGE,
    perPage,
  };
}
export function changeFinishedOperationsPerPageAction(perPage) {
  return {
    type: types.FINISHED_OPERATIONS_CHANGE_PER_PAGE,
    perPage,
  };
}

export function changeUsersSortAction(sort) {
  return {
    type: types.USERS_CHANGE_SORT,
    sort,
  };
}
export function changeOngoingOperationsSortAction(sort) {
  return {
    type: types.ONGOING_OPERATIONS_CHANGE_SORT,
    sort,
  };
}
export function changeFinishedOperationsSortAction(sort) {
  return {
    type: types.FINISHED_OPERATIONS_CHANGE_SORT,
    sort,
  };
}

export function changeUsersSearchAction(search) {
  return {
    type: types.USERS_CHANGE_SEARCH,
    search,
  };
}

export function clearStoreAction() {
  return {
    type: types.CLEAR_STORE,
  };
}

export function changeSelectedUsersAction(selectedUsers) {
  return {
    type: types.CHANGE_SELECTED_USERS,
    selectedUsers,
  };
}

export function getUsersAction({companyId, page, perPage, sort, search = ''}) {
  return (dispatch) => {
    const searchParamName =
      ['client_user_email', 'client_user_first_name', 'client_user_last_name'].join('_or_') + '_cont';

    const queryParams = getQueryParams({
      type: 'CompanyMembership',
      include: [
        'client_user.operations',
        'place_accesses.place',
        'region_accesses.region',
        'client_user.memberships',
        'prize_tracking_user',
      ],
      q: {
        client_id_eq: companyId,
        s: sort && `${sort.name} ${sort.order}`,
        [searchParamName]: encodeURIComponent(search),
      },
      page,
      per_page: perPage,
    });
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.MEMBERSHIPS}?${queryParams}`,
        method: 'GET',
        types: [types.GET_USERS_REQUEST, types.GET_USERS_SUCCESS, types.GET_USERS_ERROR],
        isAuth: true,
      },
    });
  };
}

export function getOngoingOperationsAction({companyId, page, perPage, sort}) {
  return (dispatch) => {
    const queryParams = getQueryParams({
      include: ['automation_tasks', 'automation_tasks', 'message_tasks', 'client'],
      q: {
        client_id_eq: companyId,
        status_eq: 'active',
        s: sort && `${sort.name} ${sort.order}`,
      },
      page,
      per_page: perPage,
    });
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.OPERATIONS}?${queryParams}`,
        method: 'GET',
        types: [
          types.GET_ONGOING_OPERATIONS_REQUEST,
          types.GET_ONGOING_OPERATIONS_SUCCESS,
          types.GET_ONGOING_OPERATIONS_ERROR,
        ],
        isAuth: true,
        noCache: true,
      },
    });
  };
}

export function getFinishedOperationsAction({companyId, page, perPage, sort}) {
  return (dispatch) => {
    const queryParams = getQueryParams({
      include: ['automation_tasks', 'client'],
      q: {
        client_id_eq: companyId,
        status_eq: 'finished',
        s: sort && `${sort.name} ${sort.order}`,
      },
      page,
      per_page: perPage,
    });
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.OPERATIONS}?${queryParams}`,
        method: 'GET',
        types: [
          types.GET_FINISHED_OPERATIONS_REQUEST,
          types.GET_FINISHED_OPERATIONS_SUCCESS,
          types.GET_FINISHED_OPERATIONS_ERROR,
        ],
        isAuth: true,
        noCache: true,
      },
    });
  };
}

export function getSubsidiariesAction() {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.SUBSIDIARIES}/`,
        method: 'GET',
        types: [types.GET_SUBSIDIARIES_REQUEST, types.GET_SUBSIDIARIES_SUCCESS, types.GET_SUBSIDIARIES_ERROR],
        isAuth: true,
      },
    });
  };
}

export function getAgenciesAction() {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.AGENCIES}/`,
        method: 'GET',
        types: [types.GET_AGENCIES_REQUEST, types.GET_AGENCIES_SUCCESS, types.GET_AGENCIES_ERROR],
        isAuth: true,
      },
    });
  };
}

export function getCompanyMembershipsAction(companyId) {
  return (dispatch) => {
    const queryParams = getQueryParams({
      include: ['company_accesses', 'client_user'],
      q: {
        client_id_eq: companyId,
      },
    });
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.COMPANY_MEMBERSHIPS}?${queryParams}`,
        method: 'GET',
        types: [
          types.GET_COMPANY_MEMBERSHIPS_REQUEST,
          types.GET_COMPANY_MEMBERSHIPS_SUCCESS,
          types.GET_COMPANY_MEMBERSHIPS_ERROR,
        ],
        isAuth: true,
      },
    });
  };
}

export function getAgencyMembershipsAction(agencyId) {
  return (dispatch) => {
    const queryParams = getQueryParams({
      include: ['company_accesses', 'client_user'],
      q: {
        client_id_eq: agencyId,
      },
    });
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.AGENCY_MEMBERSHIPS}?${queryParams}`,
        method: 'GET',
        types: [
          types.GET_AGENCY_MEMBERSHIPS_REQUEST,
          types.GET_AGENCY_MEMBERSHIPS_SUCCESS,
          types.GET_AGENCY_MEMBERSHIPS_ERROR,
        ],
        isAuth: true,
        noCache: true,
      },
    });
  };
}

export function getAdminUsersAction() {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.ADMIN_USERS}/`,
        method: 'GET',
        types: [types.GET_ADMIN_USERS_REQUEST, types.GET_ADMIN_USERS_SUCCESS, types.GET_ADMIN_USERS_ERROR],
        isAuth: true,
      },
    });
  };
}

const COMPANY_JOINS = getQueryParams({
  include: [
    'poc_admin_user',
    'subsidiary',
    'poc_membership.client_user',
    'subcategory',
    'dashboards',
    'places.place_accesses.accessable.client_user',
    'regions.places',
    'regions.region_accesses.accessable.client_user',
    'agency',
    'poc_agency_membership',
    'company_accesses.agency_membership',
    'social_accounts',
    'message_senders',
    'broadcast_lists.message_tasks',
  ],
  include_client_network_possible: true,
  include_email_sender_email_templates_present: true,
  include_sms_sender_sms_templates_present: true,
});

export function getCompanyAction(companyId) {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.COMPANIES}/${companyId}?${COMPANY_JOINS}`,
        method: 'GET',
        types: [types.GET_COMPANY_REQUEST, types.GET_COMPANY_SUCCESS, types.GET_COMPANY_ERROR],
        isAuth: true,
        noCache: true,
      },
    });
  };
}

export function patchCompanyAction(companyId, data) {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.COMPANIES}/${companyId}?${COMPANY_JOINS}`,
        method: 'PATCH',
        types: [types.GET_COMPANY_REQUEST, types.GET_COMPANY_SUCCESS, types.GET_COMPANY_ERROR],
        isAuth: true,
        body: JSON.stringify(data),
      },
    });
  };
}

export function deleteCompanyAction(companyId) {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.COMPANIES}/${companyId}?`,
        method: 'DELETE',
        types: [types.DELETE_COMPANY_REQUEST, types.DELETE_COMPANY_SUCCESS, types.DELETE_COMPANY_ERROR],
        isAuth: true,
      },
    });
  };
}

export function getClientUserAction(email) {
  return (dispatch) => {
    const queryParams = getQueryParams({
      include: ['memberships.client'],
      q: {
        email_eq: email,
      },
    });
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.CLIENT_USERS}?${queryParams}`,
        method: 'GET',
        types: [types.GET_CLIENT_USER_REQUEST, types.GET_CLIENT_USER_SUCCESS, types.GET_CLIENT_USER_ERROR],
        isAuth: true,
      },
    });
  };
}

export function getPOCsAgencyAction(agencyId) {
  return (dispatch) => {
    const queryParams = getQueryParams({
      q: {
        client_id_eq: agencyId,
      },
    });
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.AGENCY_MEMBERSHIPS}?${queryParams}`,
        method: 'GET',
        types: [types.GET_POCS_AGENCY_REQUEST, types.GET_POCS_AGENCY_SUCCESS, types.GET_POCS_AGENCY_ERROR],
        isAuth: true,
      },
    });
  };
}

export function setDefaultNewUserAction() {
  return {
    type: types.SET_DEFAULT_NEW_USER,
  };
}

export function getCompanyUsersAction(id) {
  return (dispatch) => {
    const queryParams = getQueryParams({
      q: {
        memberships_client_id_eq: id,
        s: 'client_user_full_name asc',
      },
    });
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.CLIENT_USERS}?${queryParams}`,
        method: 'GET',
        types: [
          types.GET_COMPANY_USERS_FULL_LIST_REQUEST,
          types.GET_COMPANY_USERS_FULL_LIST_SUCCESS,
          types.GET_COMPANY_USERS_FULL_LIST_ERROR,
        ],
        isAuth: true,
      },
    });
  };
}
