import {mapInteraction} from './mappers';

export function selectOnlineInteractions(state) {
  return state.interactions.onlineInteractions.allIds.reduce((result, id) => {
    const interaction = state.interactions.onlineInteractions.byId[id];

    return interaction ? [...result, mapInteraction(interaction)] : result;
  }, []);
}

export function selectOnlineInteractionById(state, id) {
  return state.interactions.onlineInteractions.byId[id];
}

export function selectOfflineInteractions(state) {
  return state.interactions.offlineInteractions.data;
}

export function selectSpreadPlanInteractions(state) {
  return state.interactions.spreadPlanInteractions;
}
