import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {CheckboxInput} from 'client/common/inputs';

// components
import Icon from 'client/components/common/icon';
import InfoBlock from 'client/components/common/info-block';

import cssModule from './basic-information-card.module.scss';

type BasicInformationCardProps = {
  children: React.ReactNode;
  editMode: boolean;
  isAdmin: boolean;
  clientManagement?: boolean;
  onChangeClientManagement?: (nextState: boolean) => void;
  onEditClick: () => void;
};

const b = bem('basic-information-card', {cssModule});

const BasicInformationCard: React.FC<BasicInformationCardProps> = (props) => {
  const {children, onEditClick, editMode, isAdmin, onChangeClientManagement, clientManagement} = props;

  const lang = useLanguage('AGENCY');

  return (
    <InfoBlock colorTheme="basic-info">
      <InfoBlock.Title className="info-block--color-1">{lang.BASIC_INFORMATION_TITLE}</InfoBlock.Title>
      <InfoBlock.Content>
        <InfoBlock.Header className={b('header')}>
          {onChangeClientManagement && (
            <CheckboxInput
              name="client_management"
              checked={!!clientManagement}
              inversionColor
              label={lang.CLIENT_MANAGEMENT}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeClientManagement(e.target.checked)}
            />
          )}
          {!editMode && isAdmin && (
            <button className={b('edit-button')} onClick={onEditClick}>
              <Icon name="edit" width={20} height={20} />
            </button>
          )}
        </InfoBlock.Header>
        <InfoBlock.Body limitedHeight>{children}</InfoBlock.Body>
      </InfoBlock.Content>
    </InfoBlock>
  );
};

export default BasicInformationCard;
