import React, {useMemo, useState} from 'react';

import {useDispatch} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {interpolate} from 'client/ducks/language/helpers';
import {createTemplateScreenshot, deleteTemplateScreenshot} from 'client/ducks/templates/actions';

import AppButton from 'client/common/buttons/app-button';
import FileButton from 'client/common/buttons/file-button';
import Carousel from 'client/common/carousel';
import {useToast} from 'client/common/hooks/useToast';
import Icon from 'client/common/icon';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import Spinner from 'client/components/common/spinner';

import {ClientTemplate, TemplateScreenshot} from 'client/models/templates/types';
import {ApiDispatch} from 'client/types';

import cssModule from './screenshots-block.module.scss';

const b = bem('screenshots-block', {cssModule});

type ScreenshotsBlockProps = {
  template: ClientTemplate;
  updateTemplate: (id: number, data: Partial<ClientTemplate>) => void;
  editable: boolean;
};

const getScreenshotName = (url: string) => {
  const match = url.matchAll(/filename=(.+)&/g);
  const matchArray = Array.from(match);
  return matchArray?.[0]?.[1] || '';
};

const ScreenshotsBlock: React.FC<ScreenshotsBlockProps> = (props) => {
  const {template, editable, updateTemplate} = props;
  const dispatch = useDispatch<ApiDispatch>();
  const lang = useLanguage('CATALOG_TEMPLATES.MODALS.TEMPLATE_INFO_MODAL.TEMPLATE_PARAMETERS_TAB.SCREENSHOTS_BLOCK');
  const langCommon = useLanguage('COMMON');
  const [deletingScreenshot, setDeletingScreenshot] = useState<TemplateScreenshot | null>(null);
  const [loading, toggleLoading] = useToggle(false);
  const {appendToastNotification} = useToast();

  const screenshots = useMemo(() => template.screenshots || [], [template.screenshots]);
  const hasScreenshots = screenshots.length > 0;

  const handleAdd = async (fileData: string, fileName: string) => {
    toggleLoading();
    try {
      const response = await dispatch(
        createTemplateScreenshot(
          template.id,
          {screenshots: [{data: fileData, filename: fileName}]},
          {include_template_screenshots: null},
        ),
      );
      updateTemplate(template.id, {screenshots: response.payload.template.screenshots});
      appendToastNotification({type: 'success', title: lang.SCREENSHOT_ADDED});
    } catch {
      appendToastNotification({type: 'error', title: langCommon.ERROR});
    }
    toggleLoading();
  };

  const handleDelete = async () => {
    if (deletingScreenshot) {
      toggleLoading();
      try {
        const screenshotId = deletingScreenshot.id;
        setDeletingScreenshot(null);
        await dispatch(deleteTemplateScreenshot(template.id, screenshotId));
        updateTemplate(template.id, {screenshots: screenshots.filter((i) => i.id !== screenshotId)});
        appendToastNotification({type: 'success', title: lang.SCREENSHOT_DELETED});
      } catch {
        appendToastNotification({type: 'error', title: langCommon.ERROR});
      }
      toggleLoading();
    }
  };

  return (
    <div className={b()}>
      <ConfirmationModal
        show={Boolean(deletingScreenshot)}
        title={lang.DELETE_TITLE}
        message={
          // TODO: Remove using toString(). it should be fixed in class TranslationElement
          interpolate(lang.DELETE_MESSAGE?.toString(), {name: getScreenshotName(deletingScreenshot?.url || '')})
        }
        cancelText={lang.CANCEL}
        confirmText={lang.DELETE}
        onCancel={() => setDeletingScreenshot(null)}
        onConfirm={handleDelete}
        onClose={() => setDeletingScreenshot(null)}
        clientSide
      />
      {loading && <Spinner className={b('spinner')} centered />}
      {hasScreenshots && !loading && (
        <Carousel
          slides={screenshots.map((item: TemplateScreenshot) => (
            <div key={item.id} className={b('slider-item-wrap')}>
              {editable && (
                <AppButton
                  className={b('delete-button')}
                  iconConfig={{name: 'trash', title: lang.DELETE, width: 25, height: 25}}
                  onClick={() => setDeletingScreenshot(item)}
                  asWrap
                />
              )}
              <img className={b('slider-item')} src={item.url} alt="" />
            </div>
          ))}
          width="full"
          arrowVariant="arrow-alt"
          slidesPerView={4}
          hasArrows
          loop={screenshots.length > 4}
        />
      )}
      {!hasScreenshots && !loading && (
        <div className={b('empty-container')}>
          <Icon name="task-image-placeholder" />
          <span className={b('empty-text')}>{lang.NO_SCREENSHOTS}</span>
        </div>
      )}
      {!loading && (
        <div className={b('footer', {editable, empty: !hasScreenshots})}>
          {hasScreenshots && (
            <span className={b('total')}>
              {screenshots.length} {lang.SCREENSHOTS}
            </span>
          )}
          {editable && (
            <FileButton
              label={lang.ADD_SCREENSHOT}
              className={b('upload-button')}
              color="primary"
              acceptFormats=".png,.jpg,.jpeg"
              onChange={handleAdd}
              base64
              link
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ScreenshotsBlock;
