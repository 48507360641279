import React from 'react';

import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {connect} from 'react-redux';

import {selectIsAdmin} from 'client/ducks/user/selectors';

import {API_PATH} from 'client/common/config';
import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import './leads-settings-popover.scss';

function LeadsSettingsPopover({
  languageState,
  children,
  memberships,
  dateTo,
  dateFrom,
  leadTransferId,
  isImport,
  deleteReport,
  isAdmin,
  allowAll,
}) {
  let LANGUAGE = languageState.payload.LEADS_PORT;

  const apiUrl = `${API_PATH}/api_lead_exports/${leadTransferId}/perform`;

  return (
    <Popover
      position="bottom"
      className="pull-left"
      overlayInnerStyle={{
        width: 344,
      }}
      overlay={
        <div className="popover-list popover-list--color-2">
          <h3 className="popover-list__title flex-container flex-align-center flex-justify-between">
            {LANGUAGE.SETTINGS_POPOVER_TITLE}
          </h3>
          <h4 className="input__label leads-settings-popover__title">{LANGUAGE.COPY_ID}</h4>
          <div className="leads-settings-popover__row-container">
            <span className="leads-settings-popover__text">{leadTransferId}</span>
            <CopyToClipboard text={leadTransferId}>
              <button className="button button--circle button--bg-2">
                <Icon name="copy" />
              </button>
            </CopyToClipboard>
          </div>
          {!isImport && (
            <div>
              <h4 className="input__label leads-settings-popover__title">{LANGUAGE.COPY_URL}</h4>
              <div className="leads-settings-popover__row-container">
                <span className="leads-settings-popover__text leads-settings-popover__link-api" title={apiUrl}>
                  {apiUrl}
                </span>
                <CopyToClipboard text={apiUrl}>
                  <button className="button button--circle button--bg-2">
                    <Icon name="copy" />
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          )}
          <h4 className="input__label leads-settings-popover__title">
            {isImport ? LANGUAGE.SETTINGS_POPOVER_PERIOD_IMPORT : LANGUAGE.SETTINGS_POPOVER_PERIOD_EXPORT}
          </h4>
          <div className="leads-settings-popover__text">{`${dateFrom} - ${dateTo}`}</div>
          <h4 className="input__label leads-settings-popover__title">{LANGUAGE.SETTINGS_POPOVER_USER_ALLOWED}</h4>
          {allowAll && <div className="leads-settings-popover__text">{LANGUAGE.ALLOW_ALL_LABEL}</div>}
          {!allowAll && !memberships.length && (
            <div className="leads-settings-popover__text">{LANGUAGE.NO_ALLOWED_USERS}</div>
          )}
          {!allowAll &&
            memberships.map((item) => {
              return (
                <div key={item.id} className="leads-settings-popover__text">
                  {item.client_user.full_name}
                </div>
              );
            })}
          {isAdmin && (
            <div
              className="
              leads-settings-popover__row-container
              leads-settings-popover__container-margin
            "
            >
              <span className="leads-settings-popover__delete-text">
                {isImport ? LANGUAGE.SETTINGS_POPOVER_DELETE_IMPORT_TEXT : LANGUAGE.SETTINGS_POPOVER_DELETE_TEXT}
              </span>
              <button
                className="button button--circle button--bg-2"
                onClick={() => deleteReport(leadTransferId, isImport)}
              >
                <Icon name="trash" width={17} height={19} />
              </button>
            </div>
          )}
        </div>
      }
    >
      {children}
    </Popover>
  );
}

LeadsSettingsPopover.defaultProps = {
  children: '',
  dateFrom: '',
  isImport: false,
  dateTo: '',
  memberships: [],
  allowAll: false,
};

LeadsSettingsPopover.propTypes = {
  languageState: PropTypes.object.isRequired,
  children: PropTypes.any,
  memberships: PropTypes.array,
  allowAll: PropTypes.bool,
  dateTo: PropTypes.string,
  isImport: PropTypes.bool,
  isAdmin: PropTypes.bool.isRequired,
  leadTransferId: PropTypes.string,
  id: PropTypes.number,
  dateFrom: PropTypes.string,
  deleteReport: PropTypes.func.isRequired,
};

export default connect((state) => ({
  languageState: state.languageState,
  isAdmin: selectIsAdmin(state),
}))(LeadsSettingsPopover);
