import React from 'react';

import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import {RadioInput} from 'client/common/inputs';

const RadioFieldComponent = (props) => {
  const {input, ...restProps} = props;

  return <RadioInput {...input} checked={Boolean(input.value)} {...restProps} />;
};

RadioFieldComponent.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  }),
};

const RadioField = (props) => {
  return <Field component={RadioFieldComponent} {...props} />;
};

export default RadioField;
