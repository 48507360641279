export const LEVEL_OPTIONS = Array.from({length: 10}, (v, index) => ({label: index + 1, value: index + 1}));

export const LANGUAGE_LIST = [
  {label: 'Bg', value: 'bg', labelLong: 'Bulgarian'},
  {label: 'Cs', value: 'cs', labelLong: 'Czech'},
  {label: 'Da', value: 'da', labelLong: 'Danish'},
  {label: 'En', value: 'en', labelLong: 'English'},
  {label: 'De', value: 'de', labelLong: 'German'},
  {label: 'El', value: 'el', labelLong: 'Greek'},
  {label: 'Es', value: 'es', labelLong: 'Spanish'},
  {label: 'Fi', value: 'fi', labelLong: 'Finnish'},
  {label: 'Fr', value: 'fr', labelLong: 'French'},
  {label: 'Ga', value: 'ga', labelLong: 'Irish'},
  {label: 'Hr', value: 'hr', labelLong: 'Croatian'},
  {label: 'Hu', value: 'hu', labelLong: 'Hungarian'},
  {label: 'It', value: 'it', labelLong: 'Italian'},
  {label: 'Lv', value: 'lv', labelLong: 'Latvian'},
  {label: 'Nl', value: 'nl', labelLong: 'Dutch'},
  {label: 'Pl', value: 'pl', labelLong: 'Polish'},
  {label: 'Pt', value: 'pt', labelLong: 'Portuguese'},
  {label: 'Ro', value: 'ro', labelLong: 'Romanian'},
  {label: 'Sk', value: 'sk', labelLong: 'Slovak'},
  {label: 'Sl', value: 'sl', labelLong: 'Slovenian'},
  {label: 'Sv', value: 'sv', labelLong: 'Swedish'},
];
