import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import PaginationBar from 'client/common/paginations/pagination-bar';

import ClientTable from 'client/components/common/client-table';
import Icon from 'client/components/common/icon';

import './bc-email-steps-data-grid.scss';

class BCEmailStepsDataGrid extends ReactQueryParams {
  static propTypes = {
    data: PropTypes.array,
    className: PropTypes.string,
    languageState: PropTypes.object.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
    perPage: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
  };

  static defaultProps = {
    data: [],
    className: '',
  };

  constructor(props) {
    super(props);

    const langPayload = props.languageState.payload;
    this.LANGUAGE_MAIL_TABLE = langPayload.BROADCASTING_TASK.EMAIL_STEPS_TABLE;
  }

  renderContacts = (cell, row) => {
    const {phone, email} = row;

    return (
      <div className="clearfix theme-color-16 bc-email-steps-data-grid__contacts">
        {phone && (
          <div className="pull-left">
            <Icon name="phone" />
          </div>
        )}
        {email && (
          <div className="pull-right">
            <Icon name="mail" />
          </div>
        )}
      </div>
    );
  };

  renderName = (name) => {
    return <div className="break-word">{name}</div>;
  };

  renderOptins = (codes) => {
    return (
      <div>
        {codes.map((code, index) => {
          return (
            <div key={index} className="break-word uppercase">
              {code}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const {currentPage, totalPages, totalItems, perPage, onPageChange, data, onSortChange} = this.props;

    return (
      <div>
        <ClientTable
          data={data}
          sortField={this.queryParams.sort?.name}
          sortOrder={this.queryParams.sort?.order}
          onSortChange={({sortField, sortOrder}) => onSortChange(sortField, sortOrder)}
          columns={[
            {
              label: this.LANGUAGE_MAIL_TABLE.FIRST_NAME_COLUMN,
              name: 'first_name',
              path: 'first_name',
              sortable: true,
              width: 120,
              render: ({value, item}) => this.renderName(value, item),
            },
            {
              label: this.LANGUAGE_MAIL_TABLE.LAST_NAME_COLUMN,
              name: 'last_name',
              path: 'last_name',
              sortable: true,
              width: 120,
              render: ({value, item}) => this.renderName(value, item),
            },
            {
              label: this.LANGUAGE_MAIL_TABLE.GENDER_COLUMN,
              name: 'gender',
              path: 'gender',
              width: 80,
            },
            {
              label: this.LANGUAGE_MAIL_TABLE.AGE_COLUMN,
              name: 'birth_date',
              path: 'birth_date',
              sortable: true,
              width: 60,
            },
            {
              label: this.LANGUAGE_MAIL_TABLE.CONTACTS_COLUMN,
              name: 'contacts',
              path: 'contacts',
              width: 80,
              render: ({value, item}) => this.renderContacts(value, item),
            },
            {
              label: this.LANGUAGE_MAIL_TABLE.OPTINS_COLUMN,
              name: 'opt_ins',
              path: 'opt_ins',
              width: 100,
              render: ({value, item}) => this.renderOptins(value, item),
            },
            {
              label: this.LANGUAGE_MAIL_TABLE.VISUALS_COLUMN,
              name: 'visuals_count',
              path: 'visuals_count',
              width: 110,
              sortable: true,
            },
          ]}
        />
        <PaginationBar
          data={data}
          perPage={perPage}
          currentPage={currentPage}
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}

export default connect(({languageState}) => ({
  languageState,
}))(BCEmailStepsDataGrid);
