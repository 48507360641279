import React from 'react';

import PropTypes from 'prop-types';

import CampaignsDiyList from 'client/components/campaigns/lists/campaigns-diy-list';

/**
 *
 * @type {React.FC<DiyOnlineDashboard.PropTypes>}
 */

// TODO: from jsx => tsx
const DiyOnlineDashboard = ({operation, fetchOperation, fetchGame}) => {
  return (
    <div>
      <CampaignsDiyList operation={operation} fetchOperation={fetchOperation} fetchGame={fetchGame} />
    </div>
  );
};

DiyOnlineDashboard.propTypes = {
  operation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    client_id: PropTypes.number.isRequired,
  }).isRequired,
  fetchOperation: PropTypes.func.isRequired,
  fetchGame: PropTypes.func.isRequired,
};

export default DiyOnlineDashboard;
