import React from 'react';

import sha1 from 'crypto-js/sha1';
import _ from 'lodash';

import {TranslationElement} from 'client/services/translation-element';

import {UiId} from 'client/common/ui-id';

import {NestedObject} from 'client/types/common';

export const getPathHash = (path: string) => sha1(path).toString();

export const injectUiIdTags = (translations: NestedObject<string | string[]>, path = '') => {
  return Object.entries(translations).reduce<NestedObject<TranslationElement | string[]>>((acc, [key, value]) => {
    const fullPath = path ? `${path}.${key}` : key;
    const fullPathHash = getPathHash(fullPath);
    if (typeof value === 'string') {
      const translationEl = React.createElement(UiId, {
        key: fullPathHash,
        content: value,
        hash: fullPathHash,
      });

      acc[key] = new TranslationElement(translationEl);
    } else if (Array.isArray(value)) {
      acc[key] = value;
    } else if (_.isPlainObject(value)) {
      acc[key] = injectUiIdTags(value, fullPath);
    }

    return acc;
  }, {});
};
