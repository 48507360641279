import React from 'react';

import cn from 'classnames';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';

import {distributePrize, resentEmail} from 'client/ducks/prize-distribution/actions';

import AppButton from 'client/common/buttons';
import Icon from 'client/common/icon';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {WINNING_SEARCH_TYPES} from 'client/components/winnings/forms/winning-search-form/constants';
import {WinningSearchType} from 'client/components/winnings/forms/winning-search-form/types';
import {getFullName} from 'client/components/winnings/helpers';
import {PresentWinner} from 'client/models/winnings';

import {getCardTitle, getDistributionStatus} from './helpers';
import WinningPrizeCardHistory from './winning-prize-card-history';

import cssModule from './winning-prize-card.module.scss';

export type WinningPrizeCardProps = {
  data: PresentWinner;
  isDesktop?: boolean;
  className?: string;
  searchCriteria: WinningSearchType;
  id: number;
  onDistribute: () => void;
  classNames?: {
    footer?: string;
    body?: string;
  };
};

const b = bem('winning-prize-card', {cssModule});

const WinningPrizeCard: React.FC<WinningPrizeCardProps> = (props) => {
  const {data, isDesktop, className, classNames, searchCriteria, id, onDistribute} = props;
  const {prize, lead} = data;

  const lang = useLanguage('WINNINGS.CARDS.PRIZE_CARD');
  const langCommon = useLanguage('COMMON');

  const distributionStatus = getDistributionStatus(data);

  const distributed = data.history.distribution?.distributed;

  const [showDistributeConfirmation, toggleDistributeConfirmation] = useToggle(false);

  const {loading: loadingDistribute, fetch: distribute} = useReduxFetch({
    action: distributePrize,
    actionArgs: id,
    fetchOnMount: false,
  });
  const {loading: loadingResend, fetch: resend} = useReduxFetch({
    action: resentEmail,
    actionArgs: id,
    fetchOnMount: false,
    toasts: {
      success: {
        title: lang.RESEND.SUCCESS_MESSAGE,
      },
      error: {
        title: lang.RESEND.ERROR_MESSAGE,
      },
    },
  });

  const allowDistribute = !distributed && prize.prize_distribution;

  const title = getCardTitle(prize).flatMap((t, index, arr) =>
    index === arr.length - 1
      ? t
      : [
          t,
          <span className={b('title-separator')} key={t}>
            |
          </span>,
        ],
  );

  const handleConfirmDistribute = async () => {
    await distribute();
    toggleDistributeConfirmation();
    onDistribute();
  };

  const body = (
    <div className={cn(b('body', {desktop: isDesktop}), classNames?.body)}>
      <h3 className={b('title')}>{title}</h3>
      <figure className={b('figure', {placeholder: !prize.prize_image})}>
        {prize.prize_image ? (
          <img alt={prize.prize_name} src={prize.prize_image} className={b('img')} />
        ) : (
          <div className={b('image-placeholder-wrap')}>
            <Icon name="prize-thin" className={b('image-placeholder')} width={95} height={95} />
          </div>
        )}
        {distributionStatus && (
          <div className={b('status-tag-wrap', [distributionStatus])}>
            <div className={b('status-tag', [distributionStatus])}>
              <Icon name="distributed-prize" width={16} height={16} />
              <span>{lang.STATUSES[distributionStatus.toUpperCase() as keyof typeof lang.STATUSES]}</span>
            </div>
          </div>
        )}
      </figure>
      <div className={b('row')}>
        <div className={b('block')}>
          <p className={b('block-title')}>{lang.WINNER_LABEL}</p>
          <div className={b('grid-values')}>
            <span className={b('label')}>{lang.WINNER_LABEL}</span>
            <span className={b('value')}>{getFullName(lead, langCommon)}</span>

            {lead.email && searchCriteria === WINNING_SEARCH_TYPES.EMAIL && (
              <>
                <span className={b('label')}>{lang.EMAIL_LABEL}</span>
                <span className={b('value')}>{lead.email}</span>
              </>
            )}

            {lead.phone && searchCriteria === WINNING_SEARCH_TYPES.PHONE && (
              <>
                <span className={b('label')}>{lang.PHONE_LABEL}</span>
                <span className={b('value')}>{lead.phone}</span>
              </>
            )}
          </div>
        </div>
        {allowDistribute && prize.win_email_present && lead.email && (
          <AppButton
            label={lang.RESEND_EMAIL_BUTTON}
            iconName="email-open"
            loading={loadingResend}
            onClick={() => resend()}
            iconConfig={{
              width: 20,
              height: 20,
            }}
            color="light-clients"
            className={b('resend-button')}
            size={isDesktop ? 'medium' : 'full'}
          />
        )}
      </div>
      <WinningPrizeCardHistory isDesktop={isDesktop} className={b('history')} data={data} />
    </div>
  );

  return (
    <div className={cn(b(), className)}>
      {!isDesktop ? body : <CustomScrollbars scrollbarProps={{autoHeightMax: 550}}>{body}</CustomScrollbars>}
      {allowDistribute && (
        <div className={cn(b('footer'), classNames?.footer)}>
          {distributionStatus && <p className={b('hint')}>{lang.CANNOT_DISTRIBUTE_HINT}</p>}
          <AppButton
            label={lang.DISTRIBUTE_BUTTON}
            size={isDesktop ? 'default' : 'full'}
            className={b('distribute-button')}
            onClick={toggleDistributeConfirmation}
            disabled={!!distributionStatus}
          />
        </div>
      )}

      <ConfirmationModal
        clientSide
        classNames={{body: b('distribution-modal-body', {mobile: !isDesktop})}}
        className={b('distribution-modal', {mobile: !isDesktop})}
        show={showDistributeConfirmation}
        buttonConfirm={{label: lang.DISTRIBUTION_OK, onClick: handleConfirmDistribute, loading: loadingDistribute}}
        buttonCancel={{label: lang.DISTRIBUTION_CANCEL, onClick: () => toggleDistributeConfirmation()}}
        title={lang.DISTRIBUTION_CONFIRMATION}
        message={lang.DISTRIBUTION_DESCRIPTION}
      />
    </div>
  );
};

export default WinningPrizeCard;
