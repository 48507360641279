const init = {
  error: false,
  response: null,
};

export default (state = init, action) => {
  const payload = action.payload && action.payload.response && action.payload.response.payload;
  return {
    ...state,
    error: action.error,
    response: action.error ? payload : null,
  };
};
