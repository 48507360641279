import React from 'react';

import bem from 'client/services/bem';

import BasePanel from 'client/common/panels/base-panel';

import {Translation} from 'client/models/language/types';

import cssModule from './not-found.module.scss';

type NotFoundProps = {
  title: Translation;
};

const b = bem('not-found', {cssModule});

// TODO: waiting for design for PageNotFound
const NotFound: React.FC<NotFoundProps> = ({title}) => {
  return (
    <BasePanel className={b()}>
      <h1 className={b('title')}>{title}</h1>
    </BasePanel>
  );
};

export default NotFound;
