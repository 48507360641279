import React from 'react';

import {Table, Column, Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import ApiExportToolsCell from '../cells/api-export-tools-cell';
import CodeCell from '../cells/code-cell';
import DateCell from '../cells/date-cell';
import TextCell from '../cells/text-cell';

const LeadsCardApiExportGrid = (props) => {
  const {data, clientId, hideHeader, columnAdapters, clientUsers, fetchApiLeadExports, isImport, lang} = props;

  const hideHeaderClass = hideHeader ? 'fixed-table_hide-header' : '';

  return (
    <div className={`fixed-table fixed-table--leads-card theme-color-2 ${hideHeaderClass}`}>
      <div className="fixed-table__title">
        <div className="main-text main-text--bold">
          {isImport ? lang.API_IMPORT_GENERATED : lang.API_EXPORT_GENERATED}
        </div>
      </div>
      <Table
        rowsCount={data.length}
        headerHeight={hideHeader ? 0 : 40}
        rowHeight={34}
        maxHeight={145}
        width={340}
        data={data}
      >
        <Column header={<Cell>{lang.CODE_COLUMN}</Cell>} cell={<CodeCell data={data} />} width={60} />
        <Column header={<Cell>{lang.END_DATE_COLUMN}</Cell>} cell={<DateCell data={data} field="to" />} width={80} />
        <Column cell={<TextCell data={data} />} width={60} />
        <Column
          cell={
            <ApiExportToolsCell
              data={data}
              clientId={clientId}
              fetchApiLeadExports={fetchApiLeadExports}
              columnAdapters={columnAdapters}
              clientUsers={clientUsers}
            />
          }
          width={140}
          flexGrow={1}
        />
      </Table>
    </div>
  );
};

LeadsCardApiExportGrid.propTypes = {
  lang: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  fetchApiLeadExports: PropTypes.func.isRequired,
  columnAdapters: PropTypes.array.isRequired,
  clientUsers: PropTypes.array.isRequired,
  hideHeader: PropTypes.bool,
  isImport: PropTypes.bool,
};

LeadsCardApiExportGrid.defaultProps = {
  data: [],
  hideHeader: false,
  isImport: false,
};

export default connect((state) => ({
  lang: state.languageState.payload.AGENCY.LEADS_CARD,
}))(LeadsCardApiExportGrid);
