import React from 'react';

import PropTypes from 'prop-types';

import Field from '../field';

import './color-field.scss';

const ColorFieldItem = ({value}) => {
  return <div className="color-field__item" style={{backgroundColor: value}} />;
};

ColorFieldItem.propTypes = {
  value: PropTypes.string.isRequired,
};

const ColorField = (props) => {
  const {colors} = props;

  return (
    <Field
      cssModifier="color-field"
      searchable={false}
      simpleValue
      options={colors.map((value) => ({value}))}
      customValueRenderer={ColorFieldItem}
      customOptionRenderer={ColorFieldItem}
      {...props}
    />
  );
};

ColorField.propTypes = {
  ...Field.propTypes,
  type: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ColorField.defaultProps = {
  ...Field.defaultProps,
  type: 'select',
};

export default ColorField;
