import React, {useMemo, useState} from 'react';

import cn from 'classnames';
import {isNumber} from 'lodash';
import {useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router';
import {generatePath, useHistory, useParams} from 'react-router-dom';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {selectedClientGames} from 'client/ducks/games/selectors';
import {selectOperation} from 'client/ducks/operations/selectors';

import Button from 'client/common/buttons';
import {RadioGroupInput} from 'client/common/inputs';
import Modal from 'client/common/modals/modal';
import LayoutPanel from 'client/common/panels/layout-panel';

import LotteryPrizeCustomizationModal from 'client/components/lottery/modals/lottery-prize-customization-modal';
import {hasPrizeMaps} from 'client/components/prizes/helpers';
import PrizeCustomizationModal from 'client/components/prizes/modals/prize-customization-modal';
import {isOperationArchived} from 'client/models/operations/helpers';
import {GAME_TYPES} from 'client/models/prizes/constants';
import {GameType} from 'client/models/prizes/types';
import {ObjectValues} from 'client/types/common';

import cssModule from './diy-op-diffusion-card.module.scss';

const b = bem('diy-op-diffusion-card', {cssModule});

type DiyOpDiffusionCardProps = {
  title: string;
  type: 'devices' | 'online';
  value?: number;
  total?: number;
  className?: string;
  disabledConfiguration: boolean;
  disabledConfigurationPrize: boolean;
  showButton?: boolean;
  fetchOperation: () => Promise<void>;
};

const DiyOpDiffusionCard: React.FC<DiyOpDiffusionCardProps> = (props) => {
  const {
    fetchOperation,
    title,
    type,
    value,
    total,
    className,
    disabledConfiguration,
    disabledConfigurationPrize,
    showButton,
  } = props;
  const history = useHistory();
  const params = useParams();
  const {path} = useRouteMatch();

  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_DIFFUSION_BLOCK');
  const operation = useSelector(selectOperation);
  const {instantWinGame, lotteryGame} = useSelector(selectedClientGames);

  const autotask = useSelector(selectAutotask);

  const [openedPrizeModal, togglePrizeModal] = useToggle(false);
  const [openedSelectingPrizeModal, toggleSelectingPrizeModal] = useToggle(false);
  const [prizeType, setPrizeType] = useState<ObjectValues<typeof GAME_TYPES>>(GAME_TYPES.INSTANT_WIN);

  const isOpArchived = isOperationArchived(operation);

  const allowConfigurePrizeMaps = hasPrizeMaps(
    {instantWinGame, lotteryGame},
    autotask?.prize_maps_present?.[type === 'online' ? 'online' : 'geo'],
  );

  const goToConfiguration = (tab: string) => {
    history.push(generatePath(path, {...params, subtab: tab}));
  };

  const prizeMapModified = useMemo(() => {
    if (type === 'devices') {
      return operation?.prize_maps_modified?.includes('geo_prize_map');
    }
    return operation?.prize_maps_modified?.includes('online_prize_map');
  }, [operation?.prize_maps_modified, type]);

  const onChangePrizeType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrizeType(e.target.value as GameType);
  };

  const onToggleSelectingPrizeModal = () => {
    if (allowConfigurePrizeMaps.lotteryGame && allowConfigurePrizeMaps.instantWinGame) {
      toggleSelectingPrizeModal();
    } else {
      setPrizeType(allowConfigurePrizeMaps.lotteryGame ? GAME_TYPES.PRIZE_DRAW : GAME_TYPES.INSTANT_WIN);
      togglePrizeModal();
    }
  };

  const onSelectPrizeType = () => {
    togglePrizeModal();
    toggleSelectingPrizeModal();
  };

  return (
    <LayoutPanel className={cn(b(), className)}>
      <h2 className={b('title')}>{title}</h2>
      <span className={b('counts')}>
        <span className={b('value')}>{value}</span>
        {isNumber(total) && <span className={b('total')}>/{total}</span>}
      </span>
      <div className={b('buttons')}>
        {allowConfigurePrizeMaps.total && (
          <Button
            iconName="prize"
            iconConfig={{
              budget: prizeMapModified,
            }}
            label={lang.CONFIGURE_PRIZE}
            color="light-clients"
            className={b('button')}
            onClick={onToggleSelectingPrizeModal}
            disabled={disabledConfigurationPrize}
          />
        )}

        {showButton && (
          <Button
            label={lang.CONFIGURATION}
            iconName="settings"
            color="light-clients"
            className={b('button')}
            onClick={() => goToConfiguration(type)}
            disabled={disabledConfiguration}
          />
        )}
      </div>

      {openedSelectingPrizeModal && (
        <Modal
          onClose={toggleSelectingPrizeModal}
          show
          title={lang.CONFIGURE_PRIZE}
          classNames={{content: b('prize-type')}}
        >
          <RadioGroupInput
            className={b('prize-type-radio')}
            name="selecting-prize-type"
            onChange={onChangePrizeType}
            options={[
              {label: lang.WIN_PRIZE_TYPE, value: GAME_TYPES.INSTANT_WIN},
              {label: lang.LOTTERY_PRIZE_TYPE, value: GAME_TYPES.PRIZE_DRAW},
            ]}
            value={prizeType}
          />
          <Button className={b('prize-type-button')} label={lang.NEXT_PRIZE_CONFIG} onClick={onSelectPrizeType} />
        </Modal>
      )}

      {openedPrizeModal && prizeType === GAME_TYPES.INSTANT_WIN && instantWinGame && (
        <>
          <PrizeCustomizationModal
            onClose={togglePrizeModal}
            game={instantWinGame}
            level={type === 'online' ? 'ONLINE_GLOBAL' : 'DEVICE_GLOBAL'}
            onFetch={fetchOperation}
            disabled={isOpArchived}
          />
        </>
      )}

      {openedPrizeModal && prizeType === GAME_TYPES.PRIZE_DRAW && lotteryGame && (
        <>
          <LotteryPrizeCustomizationModal
            onClose={togglePrizeModal}
            game={lotteryGame}
            level={type === 'online' ? 'ONLINE_GLOBAL' : 'DEVICE_GLOBAL'}
            disabled={isOpArchived}
          />
        </>
      )}
    </LayoutPanel>
  );
};

export default DiyOpDiffusionCard;
