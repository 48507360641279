import {EmailParams} from 'client/components/email-template-editor/types';

export const getFromString = (emailParams: EmailParams) => {
  const result = [];

  if (emailParams.name) {
    result.push(emailParams.name);
  }

  if (emailParams.senderEmail) {
    result.push(`<${emailParams.senderEmail}>`);
  }

  return result.join(' ');
};
