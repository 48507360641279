import React from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams, useReduxForm} from 'client/services/hooks';

import {ACCESS_LEVEL_TYPES} from 'client/ducks/client-users/constants';
import {selectInstoreTask} from 'client/ducks/instore-tasks/selectors';
import {selectClientAccessLevel} from 'client/ducks/user-clients/selectors';

import AppButton from 'client/common/buttons';
import {FieldWrap} from 'client/common/fields';
import Modal from 'client/common/modals/modal';
import SelectDropdown from 'client/common/selects/select-dropdown';

import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container';
import {useFetchRegionAccesses} from 'client/components/instore/hooks/use-fetch-region-accesses';
import {InstoreTaskAccess} from 'client/models/instore-tasks/types';
import {Place} from 'client/models/places/types';

import {useFetchLocalUsers, useFetchPlaces, useFetchTaskNetworks} from './hooks';

import './animation-base-modal.scss';

const b = bem('animation-base-modal');

type AnimationBaseModalProps = {
  onClose: () => void;
  onCreate: () => void;
};

const AnimationBaseModal: React.FC<AnimationBaseModalProps> = (props) => {
  const {onClose, onCreate} = props;
  const lang = useLanguage('ANIMATIONS.MODALS.ANIMATION_BASE_MODAL');
  const task = useSelector(selectInstoreTask);
  const clientAccessLevel = useSelector(selectClientAccessLevel);
  const {formValues, change} = useReduxForm(AnimationConfigContainerFormName);
  const [queryParams] = useQueryParams(null, {}, {parse: {parseNumbers: true}});

  const {loading: loadingRegionAccesses, regionIds} = useFetchRegionAccesses();

  const skipFetchLocalUsers =
    ![ACCESS_LEVEL_TYPES.REGIONS, ACCESS_LEVEL_TYPES.CLIENT_ADMIN, ACCESS_LEVEL_TYPES.NATIONAL].includes(
      clientAccessLevel,
    ) ||
    (clientAccessLevel === ACCESS_LEVEL_TYPES.REGIONS && loadingRegionAccesses);

  const {loading: loadingLocalUsers, userOptions} = useFetchLocalUsers(
    {regionIds, taskId: task.id, clientAccessLevel},
    skipFetchLocalUsers,
  );
  const {loading: loadingBrands, brandOptions} = useFetchTaskNetworks({taskId: task.id});
  const {
    loading: loadingPlaces,
    cityOptions,
    placeOptions,
  } = useFetchPlaces({
    networkId: formValues?.place?.network_id,
    cityId: formValues?.place?.city_id,
  });

  const isLocal = clientAccessLevel === ACCESS_LEVEL_TYPES.LOCAL;

  const resetMembership = () => {
    if (!isLocal) {
      change('access', null);
    }
  };

  const onChangeCity = (value: number) => {
    change('place', {
      city_id: value,
      network_id: formValues.place?.network_id,
    });
    resetMembership();
  };

  const onChangeBrand = (value: number) => {
    change('place', {
      network_id: value,
    });
    resetMembership();
  };

  const isValid = !!(
    formValues?.place?.network_id &&
    formValues?.place?.city_id &&
    formValues?.place?.id &&
    (clientAccessLevel === ACCESS_LEVEL_TYPES.LOCAL || formValues?.access?.id)
  );

  const onChangeAccess = (access: InstoreTaskAccess) => {
    change('access', access);

    const availableKitMappings =
      access?.kit_mappings?.filter(
        (i) => !queryParams.campaignId || i.interaction_group_id === queryParams.campaignId,
      ) || [];

    if (availableKitMappings.length === 1) {
      change('kit_mapping', availableKitMappings[0]);
    }
  };

  const onChangeStore = (place: Place) => {
    change('place', place);
  };

  return (
    <Modal onClose={onClose} title={lang.NEW_ANIMATION_TITLE} bodyClassName={b('body')} dialogClassName={b()}>
      <FieldWrap>
        <SelectDropdown
          options={brandOptions}
          label={lang.BRAND_LABEL}
          isLoading={loadingBrands}
          name="place.network_id"
          value={formValues?.place?.network_id}
          onChange={onChangeBrand}
          simpleValue
        />
      </FieldWrap>
      <FieldWrap>
        <SelectDropdown
          options={cityOptions}
          isLoading={loadingPlaces}
          label={lang.CITY_LABEL}
          searchable
          name="place.city_id"
          disabled={!formValues?.place?.network_id}
          value={formValues?.place?.city_id}
          onChange={onChangeCity}
          simpleValue
        />
      </FieldWrap>
      <FieldWrap>
        <SelectDropdown
          options={placeOptions}
          isLoading={loadingPlaces}
          label={lang.STORE_LABEL}
          value={formValues?.place?.id}
          onChange={onChangeStore}
          disabled={!formValues?.place?.city_id}
          name="place"
        />
      </FieldWrap>
      {!isLocal && (
        <FieldWrap>
          <SelectDropdown
            options={userOptions}
            isLoading={loadingRegionAccesses || loadingLocalUsers}
            label={lang.USER_LABEL}
            value={formValues?.access}
            onChange={onChangeAccess}
            name="access"
            disabled={!formValues?.place?.id}
          />
        </FieldWrap>
      )}
      <AppButton
        label={lang.CREATE_BUTTON}
        color="clients"
        iconName="calendar-2"
        className={b('button')}
        disabled={!isValid}
        onClick={onCreate}
      />
    </Modal>
  );
};

export default AnimationBaseModal;
