import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import Modal from 'client/common/modals/modal';

import './message-modal.scss';

const b = bem('message-modal');

const MessageModal = ({show, onClose, message, className}) => {
  return (
    <Modal onClose={onClose} show={show} dialogClassName={cn(b(), className)} bodyClassName={b('body')}>
      <button className={b('message')} type="button" onClick={onClose}>
        {message && <p className="main-text">{message}</p>}
      </button>
    </Modal>
  );
};

MessageModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.node,
  className: PropTypes.string,
};

MessageModal.defaultProps = {
  show: false,
  message: '',
  className: '',
};

export default MessageModal;
