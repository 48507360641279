import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import {replaceText} from 'client/services/helpers';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getEmailSenders} from 'client/ducks/email-senders/actions';
import {getEmailTemplates} from 'client/ducks/email-templates/actions';
import {getOptInsAction as getOptIns} from 'client/ducks/opt-in-columns/actions';
import {
  getScenario,
  getScenarioVariables,
  getScenarioSteps,
  getScenarioExecutionLogs,
  deleteScenario,
} from 'client/ducks/scenario/actions';
import {selectOperationId, selectScenarioSteps} from 'client/ducks/scenario/selectors';
import {getSMSSenders} from 'client/ducks/sms-senders/actions';
import {getSocialAccounts} from 'client/ducks/social-accounts/actions';

import {CLIENT_PAGES, SOCIAL_ACCOUNT_TYPES} from 'client/common/config';
import Icon from 'client/common/icon';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import CustomLink from 'client/components/common/custom-link';
import TitleBlock from 'client/components/common/title-block';

import AtsBasicInfoCard from './components/cards/ats-basic-info-card/ats-basic-info-card';
import AtsLinkedCard from './components/cards/ats-linked-card/ats-linked-card';
import AtsTableCard from './components/cards/ats-table-card/ats-table-card';
import AddImageModal from './components/modals/add-image-modal/add-image-modal';
import AtsAddVarModal from './components/modals/ats-add-var-modal/ats-add-var-modal';
import AtsEditVarModal from './components/modals/ats-edit-var-modal/ats-edit-var-modal';
import AtsScenarioEditorModal from './components/modals/ats-scenario-editor-modal/ats-scenario-editor-modal';
import AtsErrorsCardTable from './components/tables/ats-errors-card-table/ats-errors-card-table';
import AtsLinkedInterfacesTable from './components/tables/ats-linked-interfaces-table/ats-linked-interfaces-table';
import AtsStepCardTable from './components/tables/ats-step-card-table/ats-step-card-table';
import AtsVarsCardTable from './components/tables/ats-vars-card-table/ats-vars-card-table';

import './autotask-scenario.scss';

class AutotaskScenario extends PureComponent {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    scenario: PropTypes.shape({
      automation_task: PropTypes.object,
      id: PropTypes.number,
      name: PropTypes.string,
      scenario_variables: PropTypes.array,
      scenario_steps: PropTypes.array,
      linked_interfaces: PropTypes.array,
      next_scenarios: PropTypes.array,
      scenario_execution_logs: PropTypes.array,
      scenario_step_variables: PropTypes.array,
    }).isRequired,
    scenarioSteps: PropTypes.array.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    getScenario: PropTypes.func,
    getOptIns: PropTypes.func,
    id: PropTypes.number.isRequired,
    getScenarioVariables: PropTypes.func.isRequired,
    getScenarioSteps: PropTypes.func.isRequired,
    getScenarioExecutionLogs: PropTypes.func.isRequired,
    getSMSSenders: PropTypes.func.isRequired,
    getEmailSenders: PropTypes.func.isRequired,
    getEmailTemplates: PropTypes.func.isRequired,
    getSocialAccounts: PropTypes.func.isRequired,
    operationId: PropTypes.number,
    deleteScenario: PropTypes.func.isRequired,
  };

  static DEFAULT_PER_PAGE = 30;
  static DEFAULT_PAGE = 1;

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK_SCENARIO;

    this.state = {
      showDeleteModal: false,
      showAddVarModal: false,
      showEditVarModal: false,
      showScenarioEditorModal: false,
      showAddImageModal: false,
    };
  }

  componentDidMount() {
    this.updateMe().then(this.getCommonData);
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this.updateMe(this.props.id);
    }
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  getScenarioVariables = () =>
    this.props.getScenarioVariables({
      include: [
        {
          source: [
            'interface',
            'scenario',
            {
              scenario_step: 'scenario',
            },
          ],
        },
      ],
      q: {
        scenario_id_eq: this.props.id,
        s: 'name',
      },
    });

  getScenarioSteps = () =>
    this.props.getScenarioSteps({
      include: [
        {
          implementation: [
            {
              branch_scenario_step_conditions: ['variable', 'value_variable'],
            },
            'scenario',
            'lottery_scenario_step_prizes',
            'coupon.coupon_tokens',
            'coupon_scenario_step',
            'email_template_variables',
            'message_scenario_step_to_opt_in_columns',
            'email_scenario_step_mapping_items',
            'social_scenario_step_images',
            'calculation_step_components',
            'column_adapter',
          ],
        },
      ],
      q: {
        scenario_id_eq: this.props.id,
        s: ['position', 'implementation.lottery_scenario_step_prizes.level'],
      },
      page: AutotaskScenario.DEFAULT_PAGE,
      per_page: AutotaskScenario.DEFAULT_PER_PAGE,
    });

  getScenarioExecutionLogs = () =>
    this.props.getScenarioExecutionLogs({
      include: ['scenario_execution', 'variable'],
      q: {
        scenario_execution_scenario_id_eq: this.props.id,
        level_in: ['error', 'fatal'],
        s: 'created_at DESC',
      },
      per_page: 5,
      page: 1,
    });

  getScenario = (scenarioId) => {
    return this.props.getScenario(scenarioId || this.props.id, {
      include: [
        {
          automation_task: [
            'scenarios',
            {
              operation: {
                client: 'agency',
              },
            },
            {
              interfaces: ['interface_items'],
            },
          ],
          trigger_mapping_items: [
            {
              interface_item: ['interface'],
            },
          ],
          interface_levels: null,
          primary_scenarios: ['scenario_variables', 'scenario_step_variables'],
        },
        'plan',
        'next_scenarios',
        'scenario_step_variables',
      ],
    });
  };

  updateBreadcrumbs = ({payload}) => {
    const {
      scenario: {
        name: scenarioName,
        automation_task: {
          name: taskName,
          id: taskId,
          type: taskType,
          operation: {
            id: operationId,
            name: operationName,
            client: {id: clientId, type: clientType, name: clientName, agency},
          },
        },
      },
    } = payload;

    const agencyBreadcrumb = agency
      ? [
          {
            href: CustomLink.createClientLink({clientId: agency.id, clientType: agency.type}),
            name: agency.name,
          },
        ]
      : [];

    const {
      languageState: {
        payload: {BREADCRUMBS},
      },
    } = this.props;

    this.autotaskLink = CustomLink.createTaskLink({clientId, clientType, operationId, taskId, taskType});

    this.props.setBreadcrumbs([
      {
        href: CLIENT_PAGES.VISUALS,
        name: BREADCRUMBS.VISUALS,
      },
      ...agencyBreadcrumb,
      {
        name: clientName,
        href: CustomLink.createClientLink({clientId, clientType}),
      },
      {
        name: BREADCRUMBS.CUSTOM_OPERATION,
        href: CustomLink.createCustomOperationDBLink({clientId, clientType, operationId}),
      },
      {
        name: operationName,
        href: CustomLink.createOperationLink({clientId, clientType, operationId}),
      },
      {
        name: taskName,
        href: this.autotaskLink,
      },
      {
        name: scenarioName,
      },
    ]);

    return clientId;
  };

  updateMe = (scenarioId, {steps = true} = {}) => {
    return this.getScenario(scenarioId).then((response) =>
      Promise.all([
        this.updateBreadcrumbs(response),
        this.props.getOptIns({
          q: {
            client_id_eq: response.payload.scenario.automation_task.operation.client_id,
          },
        }),
        this.getScenarioVariables(),
        steps ? this.getScenarioSteps() : Promise.resolve(),
        this.getScenarioExecutionLogs(),
      ]),
    );
  };

  getEmailSenders = (clientId) => {
    return this.props.getEmailSenders({
      q: {
        client_id_eq: clientId,
      },
    });
  };

  getCommonData = () => {
    const clientId = this.props.scenario.automation_task.operation.client_id;
    const subsidiaryId = this.props.scenario.automation_task.operation.client.subsidiary_id;
    const g = {
      0: {
        client_id_eq: clientId || 0,
        m: 'or',
        subsidiary_id_eq: subsidiaryId || 0,
      },
    };
    this.props.getSMSSenders();
    this.getEmailSenders(clientId);
    this.props.getSocialAccounts(
      {
        q: {
          type_eq: SOCIAL_ACCOUNT_TYPES.FACEBOOK,
          g,
        },
      },
      SOCIAL_ACCOUNT_TYPES.FACEBOOK,
    );
    this.props.getSocialAccounts(
      {
        q: {
          type_eq: SOCIAL_ACCOUNT_TYPES.TWITTER,
          g,
        },
      },
      SOCIAL_ACCOUNT_TYPES.TWITTER,
    );

    // TODO Change to infinity scroll
    this.props.getEmailTemplates({
      include: ['email_template_variables'],
      q: {
        operation_id_eq: this.props.operationId,
        template_email_templates_message_template_kind_id_null: true,
      },
    });
  };

  handleOpenModal = (key) => {
    return () => {
      this.setState({
        [key]: true,
      });
    };
  };

  handleCloseModal = (key) => {
    return () => {
      this.setState({
        [key]: false,
      });
    };
  };

  handleOpenEditVarModal = (data) => {
    this.setState({
      showEditVarModal: true,
      editVarModalData: data,
    });
  };

  handleCloseEditVarModal = () => {
    this.setState({
      showEditVarModal: false,
      editVarModalData: {},
    });
  };

  handleDeleteScenario = () => {
    const {id} = this.props.scenario.automation_task;
    const route = `${CLIENT_PAGES.AUTOTASK}/${id}`;
    this.props.deleteScenario(this.props.id).then(() => {
      this.props.history.push(route);
    }); // TODO: add error
  };

  handleScenarioStepsChanged = () => {
    this.getScenarioVariables();
    this.getScenario();
  };

  render() {
    const {
      id,
      scenario: {
        name: scenarioName,
        automation_task: {name: automationTaskName, id: autotaskId},
      },
      scenario,
      scenarioSteps,
    } = this.props;

    const DELETE_SCENARIO_MODAL = this.LANGUAGE.DELETE_SCENARIO_MODAL;
    const deleteScenarioMessage = replaceText(/\[NAME]/, DELETE_SCENARIO_MODAL.MESSAGE, scenario.name);

    const INCORRECT_NAME_MODAL = this.LANGUAGE.INCORRECT_NAME_MODAL;
    const incorrectNameMessage = replaceText(/\[NAME]/, INCORRECT_NAME_MODAL.MESSAGE, scenario.name);
    const isPossibleToAddStep = scenario.scenario_steps.length < AutotaskScenario.DEFAULT_PER_PAGE;

    if (!scenario.id) {
      return null;
    }

    return (
      <div className="theme-color-7">
        <ConfirmationModal
          show={this.state.showDeleteModal}
          title={DELETE_SCENARIO_MODAL.TITLE}
          message={deleteScenarioMessage}
          buttonCancelClass="button--bg-11"
          buttonConfirmClass="button--bg-5"
          className="theme-color-7"
          cancelText={DELETE_SCENARIO_MODAL.CANCEL_BUTTON}
          confirmText={DELETE_SCENARIO_MODAL.CONFIRM_BUTTON}
          onConfirm={this.handleDeleteScenario}
          onCancel={this.handleCloseModal('showDeleteModal')}
          onClose={this.handleCloseModal('showDeleteModal')}
        />

        <ConfirmationModal
          show={false}
          title={INCORRECT_NAME_MODAL.TITLE}
          message={incorrectNameMessage}
          buttonConfirmClass="button--bg-5"
          className="theme-color-7"
          cancelText={INCORRECT_NAME_MODAL.CLOSE_BUTTON}
        />

        <AtsAddVarModal
          show={this.state.showAddVarModal}
          onClose={this.handleCloseModal('showAddVarModal')}
          onCancel={this.handleCloseModal('showAddVarModal')}
          onConfirm={this.handleCloseModal('showAddVarModal')}
          autotaskId={autotaskId}
        />

        <AtsEditVarModal
          show={this.state.showEditVarModal}
          data={this.state.editVarModalData}
          onConfirm={this.handleCloseEditVarModal}
          onClose={this.handleCloseEditVarModal}
          onCancel={this.handleCloseEditVarModal}
          onDelete={this.handleCloseEditVarModal}
          autotaskId={autotaskId}
        />

        <AtsScenarioEditorModal
          getScenarioSteps={this.getScenarioSteps}
          scenarioId={this.props.id}
          show={this.state.showScenarioEditorModal}
          updateMe={(conditions) => this.updateMe(this.props.id, conditions)}
          onConfirm={this.handleCloseModal('showScenarioEditorModal')}
          onCancel={this.handleCloseModal('showScenarioEditorModal')}
          onStepsChanged={this.handleScenarioStepsChanged}
          variables={scenario.scenario_variables.concat(scenario.scenario_step_variables || [])}
          isPossibleToAddStep={isPossibleToAddStep}
        />

        <AddImageModal show={false} />

        <div className="page__title-block flex-container flex-justify-between">
          <TitleBlock className="autotask-scenario__title-block" theme={true} separator={true} ellipsis="1">
            <TitleBlock.Item>
              {this.LANGUAGE.TITLE_AUTOTASK} "{automationTaskName}"
            </TitleBlock.Item>
            <TitleBlock.Item>
              <b>
                {this.LANGUAGE.TITLE_SCENARIO} "{scenarioName}"
              </b>
            </TitleBlock.Item>
          </TitleBlock>
          <button
            type="button"
            className="button button--circle button--bg-13"
            onClick={this.handleOpenModal('showDeleteModal')}
          >
            <Icon name="trash" className="button__icon" width={17} height={19} />
          </button>
        </div>
        <div className="page__row">
          <div className="page__block-container">
            <div className="page__block page__block--size-1">
              <AtsBasicInfoCard />
            </div>
            <div className="page__block page__block--size-2 theme-color-8">
              <AtsTableCard
                header={<div className="info-block__header-link pull-right" />}
                title={this.LANGUAGE.LINKED_INTERFACES_CARD.TITLE}
              >
                <AtsLinkedInterfacesTable data={scenario.linked_interfaces} link={this.autotaskLink} />
              </AtsTableCard>
            </div>
            <div className="page__block page__block--size-2">
              <AtsLinkedCard
                data={scenario.next_scenarios}
                title={this.LANGUAGE.LINKED_SCENARIOS_CARD.TITLE}
                path={CLIENT_PAGES.SCENARIO}
              />
            </div>
            <div className="page__block page__block--size-1">
              <AtsTableCard
                header={<div className="info-block__header-link pull-right" />}
                title={this.LANGUAGE.ERRORS_CARD.TITLE}
              >
                <AtsErrorsCardTable data={scenario.scenario_execution_logs} />
              </AtsTableCard>
            </div>
          </div>
        </div>
        <div className="page__row">
          <div className="page__block-container">
            <div className="page__block page__block--size-3">
              <AtsTableCard
                header={
                  <div className="pull-right">
                    <button
                      type="button"
                      className="button button--bg-13"
                      onClick={this.handleOpenModal('showAddVarModal')}
                    >
                      <Icon name="plus" className="button__icon" />
                      <span>{this.LANGUAGE.VARS_CARD.ADD_VARS_BUTTON}</span>
                    </button>
                  </div>
                }
                title={this.LANGUAGE.VARS_CARD.TITLE}
              >
                <AtsVarsCardTable data={scenario.scenario_variables} onClick={this.handleOpenEditVarModal} />
              </AtsTableCard>
            </div>
            <div className="page__block page__block--size-3">
              <AtsTableCard
                header={
                  <div className="flex-container flex-justify-end">
                    <Link to={`/scenarios/${id}/test-parameters`} className="button button--bg-13 margin-right-10">
                      <Icon name="hammer" className="button__icon" />
                      <span>{this.LANGUAGE.STEP_CARD.TEST_SCENARIO_BUTTON}</span>
                    </Link>
                    <button
                      className="button button--circle button--bg-13"
                      onClick={this.handleOpenModal('showScenarioEditorModal')}
                    >
                      <Icon name="edit" className="button__icon" width={20} height={20} />
                    </button>
                  </div>
                }
                title={this.LANGUAGE.STEP_CARD.TITLE}
              >
                <AtsStepCardTable data={scenarioSteps} />
              </AtsTableCard>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  scenario: state.scenario.payload,
  scenarioSteps: selectScenarioSteps(state),
  operationId: selectOperationId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getScenario,
      getScenarioVariables,
      getScenarioSteps,
      getScenarioExecutionLogs,
      deleteScenario,
      getOptIns,
      getSMSSenders,
      getEmailSenders,
      getEmailTemplates,
      getSocialAccounts,
      setBreadcrumbs,
      clearBreadcrumbs,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AutotaskScenario);
