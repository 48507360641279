export const selectInstoreTask = (state) => {
  return state.instoreTasks.instoreTask;
};

/**
 *
 * @param state
 * @return {null|import('client/models/instore-tasks/types').TaskNetwork[]}
 */
export const selectTaskNetworks = (state) => {
  return state.instoreTasks.taskNetworks;
};

/**
 *
 * @param state
 * @return {[]|import('client/models/instore-tasks/types').InstoreTaskAccess[]}
 */
export const selectInstoreTaskAccesses = (state) => {
  return state.instoreTasks.accesses;
};

export const selectInstoreTaskPositions = (state) => {
  return state.instoreTasks.positions;
};

export const selectInstoreTaskKits = (state) => {
  return state.instoreTasks.kits;
};

export const selectInstoreTaskKitMappings = (state) => {
  return state.instoreTasks.kitMappings;
};

export const selectInstoreTaskCampaigns = (state) => state.instoreTasks.campaigns;

export const selectAnimationAvailableDays = (state) => state.instoreTasks.availableDays;

export const selectAnimationPhotos = (state) => state.instoreTasks.photos;

export const selectInstoreTaskMonitoringList = (state) => state.instoreTasks.monitoringList;

export const selectInstoreTaskStatsFiles = (state) => state.instoreTasks.statsFiles || [];

export const selectInstoreTaskKpis = (state) => state.instoreTasks.taskKpis || [];
