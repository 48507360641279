import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import cssModule from './pie-chart.module.scss';

const b = bem('pie-chart', {cssModule});

type PieChartProps = {
  value?: number;
  bgColor?: string;
  percentageColor?: string;
  className?: string;
  classNames?: {
    figure?: string;
  };
};

// This designed component PieChart. See storybook
const PieChart: React.FC<PieChartProps> = (props) => {
  const {value = null, bgColor = '#8ed2f8', percentageColor = '#5dc4c4', className, classNames} = props;
  const defaultColor = '#f0f4f7';
  const styles = {
    '--percentage': value + '%',
    '--bgColor': value ? bgColor : defaultColor,
    '--percentageColor': percentageColor,
  } as React.CSSProperties;

  return (
    <div className={cn(b(), className)}>
      <figure
        className={cn(b('figure'), classNames?.figure)}
        data-percentage={value !== null ? `${value}%` : ' - '}
        style={styles}
      />
    </div>
  );
};

export default PieChart;
