import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {HoveringField} from 'client/components/common/fields';

import {MEDIA_LEVELS, MEDIA_TYPES} from 'client/components/medias/constants';

import './medias-loops-cell.scss';

const b = bem('medias-loops-cell');

const MediasLoopsCell = (props) => {
  const {index, item, level, isLocal} = props;
  const compareLevel = isLocal ? MEDIA_LEVELS.DEVICE : level;

  const isImage = item.media_type === MEDIA_TYPES.IMAGE;
  const isLockedByOwner = item.level !== compareLevel && item.locked;

  return (
    <div className={b()}>
      <HoveringField name={`medias[${index}].loops`} readOnly={isImage || isLockedByOwner} />
    </div>
  );
};

MediasLoopsCell.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  isLocal: PropTypes.bool.isRequired,
  level: PropTypes.string.isRequired,
};

export default MediasLoopsCell;
