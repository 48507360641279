import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons';
import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {TranslationJsx} from 'client/models/language/types';

import './ats-step-nav-item.scss';

const b = bem('ats-step-nav-item');

class AtsStepNavItem extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    name: PropTypes.string,
    description: TranslationJsx,
    comment: TranslationJsx,
    formIsInvalid: PropTypes.bool,
    onDelete: PropTypes.func,
    isActive: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    name: '',
    description: '',
    comment: '',
    formIsInvalid: false,
    onDelete: () => {},
    isActive: false,
  };

  render() {
    const {id, className, name, description, comment, formIsInvalid, onDelete, isActive} = this.props;
    const isComment = !!comment;

    return (
      <div className={cn(b({active: isActive}), className, 'theme-color-7')}>
        <div className={b('text')}>
          <div className={b('name')}>
            {name}
            {formIsInvalid && (
              <div className={b('icon-error')}>
                <Icon name="status-error" />
              </div>
            )}
          </div>
          <div className={cn(b('descr', {error: formIsInvalid}))}>{description}</div>
        </div>
        <div className={b('btns')}>
          <AppButton
            onClick={(e) => {
              e.stopPropagation();
              return onDelete(id);
            }}
            iconName="trash"
            rounded={true}
            color="text-additional"
          />
          {isComment && (
            <Popover
              overlayInnerStyle={{
                width: 200,
                zIndex: 2000,
              }}
              overlay={
                <div className={b('scroll-wrap')}>
                  <CustomScrollbars
                    scrollbarProps={{
                      autoHeightMax: 150,
                    }}
                  >
                    <div className={b('scroll-content')}>
                      <div className="main-text break-word">{comment}</div>
                    </div>
                  </CustomScrollbars>
                </div>
              }
            >
              <div className={b('icon-comment')}>
                <Icon name="message" />
              </div>
            </Popover>
          )}
        </div>
      </div>
    );
  }
}

export default AtsStepNavItem;
