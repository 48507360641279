import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import LayoutPanel from 'client/common/panels/layout-panel';

import cssModule from './diy-operation-no-access.module.scss';

const b = bem('diy-operation-no-access', {cssModule});

type DiyOperationNoAccessProps = {
  className?: string;
};

const DiyOperationNoAccess: React.FC<DiyOperationNoAccessProps> = ({className}) => {
  const lang = useLanguage('DIY_OPERATION.PANELS.DIY_OPERATION_NO_ACCESS_PANEL');

  return <LayoutPanel className={cn(b(), className)}>{lang.TITLE_NO_ACCESS}</LayoutPanel>;
};

export default DiyOperationNoAccess;
