import React, {DragEvent, MouseEventHandler, useRef, useState} from 'react';

import {useToggle} from 'react-use';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons';

import RemoveBlockConfirmation from 'client/components/email-template-editor/modals/remove-block-confirmation';

import {useEditor} from '../../hooks/useEditor';
import {ContentBlockData, ContentBlock as ContentBlockType, ContentBlockTypes} from '../../types';
import {ImageBlock} from '../image-block';
import PrizeBlock from '../prize-block';
import {TextBlock} from '../text-block';

import cssModule from './content-block.module.scss';

const b = bem('content-block', {cssModule});

const CONTENT_TYPES = {
  TEXT: 'text',
  IMAGE: 'image',
  PRIZE: 'prize',
};

type ContentBlockProps = {
  contentBlock: ContentBlockType | {id: string};
  canDrop: boolean;
};

export const ContentBlock: React.FC<ContentBlockProps> = ({contentBlock, canDrop}) => {
  const {setDndData, updateContentBlock, deleteContentBlock, activeBlockId} = useEditor();
  const contentBlockRef = useRef<HTMLElement | null>(null);
  const [showRemoveConfirmation, toggleRemoveConfirmation] = useToggle(false);
  const [isDraggable, setIsDraggable] = useState(false);

  const handleDragEnd = () => {
    setDndData({
      id: null,
      type: null,
    });
    contentBlockRef.current?.removeEventListener('dragend', handleDragEnd);
    contentBlockRef.current = null;
    setIsDraggable(false);
  };

  const handleDragStart = (e: DragEvent) => {
    e.stopPropagation();

    if (activeBlockId) {
      e.preventDefault();
      return;
    }

    contentBlockRef.current = e.target as HTMLElement;

    if ('type' in contentBlock) {
      const {id, type} = contentBlock;
      setTimeout(() => {
        setDndData({id, type});
      }, 0);
    }

    contentBlockRef.current?.addEventListener('dragend', handleDragEnd);
  };

  // putting inputs in draggable container causes issues with caret in Firefox https://bugzilla.mozilla.org/show_bug.cgi?id=800050
  // enable dragging only if non-editable area is hold
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    const target = e.target as HTMLElement;
    if (!target.isContentEditable) {
      setIsDraggable(true);
    }
  };

  const getBlockComponent = (type: ContentBlockTypes) => {
    switch (type) {
      case CONTENT_TYPES.TEXT:
        return TextBlock;
      case CONTENT_TYPES.IMAGE:
        return ImageBlock;
      case CONTENT_TYPES.PRIZE:
        return PrizeBlock;
      default:
        return () => <span className={b()}>Content block #{contentBlock?.id}</span>;
    }
  };

  // Handle form save
  const handleFormSubmit = (data: ContentBlockData) => {
    if ('content' in contentBlock) {
      updateContentBlock({
        ...contentBlock,
        content: {
          ...contentBlock.content,
          ...data,
        },
      });
    }
  };

  const handleDelete = () => {
    deleteContentBlock(contentBlock as ContentBlockType);
  };

  const BlockComponent = 'content' in contentBlock ? getBlockComponent(contentBlock.type) : null;

  return (
    <div
      className={b([canDrop ? 'is-dragging' : ''])}
      draggable={isDraggable}
      data-draggable-id={contentBlock.id}
      onDragStart={handleDragStart}
      onMouseDown={onMouseDown}
      onMouseUp={() => setIsDraggable(false)}
    >
      {'content' in contentBlock && BlockComponent && (
        <div className={b('content')}>
          <BlockComponent contentBlock={contentBlock as any} onSubmit={handleFormSubmit} />
        </div>
      )}
      <AppButton
        className={b('delete-btn')}
        iconName="close"
        asWrap={true}
        onClick={toggleRemoveConfirmation}
        iconConfig={{width: 16, height: 16}}
      />
      <RemoveBlockConfirmation
        type="content"
        show={showRemoveConfirmation}
        onCancel={toggleRemoveConfirmation}
        onRemove={handleDelete}
      />
    </div>
  );
};
