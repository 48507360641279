import React, {useState} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import SwiperCore, {Navigation, Scrollbar} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons';

// Import Swiper styles
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import './carousel.scss';

const b = bem('carousel');

SwiperCore.use([Navigation, Scrollbar]);

const Carousel = (props) => {
  const {
    className = '',
    width = '',
    slides,
    spaceBetween = 10,
    slidesPerView = 1,
    centeredSlides = false,
    scrollbar = false,
    hasArrows = false,
    loop = false,
    showIndex = false,
    arrowVariant = 'arrow',
  } = props;
  const lang = useLanguage('COMMON');
  const [index, setIndex] = useState(1);

  const isAllowTouchMove = slidesPerView < slides.length;

  return (
    <div
      className={cn(
        b({
          'has-arrows': hasArrows,
          [width]: width,
          scrollbar: scrollbar && isAllowTouchMove,
        }),
        className,
      )}
    >
      <div className={b('swiper-slider')}>
        <Swiper
          loop={loop}
          onSlideChange={({realIndex}) => setIndex(realIndex + 1)}
          spaceBetween={spaceBetween}
          slidesPerView={slidesPerView}
          centeredSlides={centeredSlides}
          scrollbar={scrollbar}
          allowTouchMove={isAllowTouchMove}
          navigation={{
            prevEl: '.carousel__button-left',
            nextEl: '.carousel__button-right',
          }}
          observeParents={true}
          observer={true}
        >
          {slides.map((item, key) => (
            <SwiperSlide key={key}>{item}</SwiperSlide>
          ))}
        </Swiper>
        {showIndex && <p className={b('index')}>{`${index} ${lang.OF?.toString()} ${slides.length}`}</p>}
      </div>

      {hasArrows && (
        <div className={b('button-wrapper')}>
          <AppButton iconName={`${arrowVariant}-left`} transparent={true} className={cn(b('button-left'))} />
          <AppButton iconName={`${arrowVariant}-right`} transparent={true} className={cn(b('button-right'))} />
        </div>
      )}
    </div>
  );
};

/*

  Width

  376px - xxs. Extra extra small
  400px	– xs. Extra small
  576px	– sm. Small
  768px	– md. Medium
  992px	– lg. Large
  1200px – xl. Extra large
  1400px – xxl. Extra extra large
  100% – full. Full width
*/

export const widthOptions = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'full'];

Carousel.widthOptions = widthOptions;

Carousel.propTypes = {
  slides: PropTypes.array.isRequired,
  className: PropTypes.string,
  spaceBetween: PropTypes.number,
  slidesPerView: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  centeredSlides: PropTypes.bool,
  scrollbar: PropTypes.bool,
  hasArrows: PropTypes.bool,
  width: PropTypes.oneOf(widthOptions),
  loop: PropTypes.bool,
  showIndex: PropTypes.bool,
  arrowVariant: PropTypes.oneOf(['arrow', 'arrow-alt']),
};

export default Carousel;
