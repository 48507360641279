import {CLIENT_PAGES} from 'client/common/config';

const tabs = CLIENT_PAGES.CLIENT_AUTOTASK_TABS;

export default [
  {
    tab: tabs.PARTICIPATIONS,
    langKey: 'PARTICIPATIONS',
  },
  {
    tab: tabs.CAMPAIGNS,
    langKey: 'CAMPAIGNS',
  },
  {
    tab: tabs.PLANS,
    langKey: 'PLANS',
  },
  {
    langKey: 'RESULTS',
    subItems: [
      {
        tab: tabs.RESULTS_KPIS,
        langKey: 'KPIS',
      },
      {
        tab: tabs.RESULTS_WINNINGS,
        langKey: 'WINNINGS',
      },
    ],
  },
  {
    langKey: 'DATA',
    check: 'data_tab_access',
    subItems: [
      {
        tab: tabs.DATA_PARTICIPATIONS,
        langKey: 'PARTICIPATIONS',
        check: 'participations',
      },
      {
        tab: tabs.DATA_LEADS,
        langKey: 'LEADS',
        check: 'leads',
      },
      {
        tab: tabs.DATA_WINNERS,
        langKey: 'WINNERS',
        check: 'winners',
      },
    ],
  },
  {
    tab: tabs.VISUALS,
    langKey: 'VISUALS',
  },
  {
    tab: tabs.CONFIGURATION,
    langKey: 'CONFIGURATION',
  },
];
