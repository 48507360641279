export default {
  GET_LEADS_STATISTIC_REQUEST: '@@leads-dashboard/GET_LEADS_STATISTIC_REQUEST',
  GET_LEADS_STATISTIC_SUCCESS: '@@leads-dashboard/GET_LEADS_STATISTIC_SUCCESS',
  GET_LEADS_STATISTIC_ERROR: '@@leads-dashboard/GET_LEADS_STATISTIC_ERROR',

  GET_LEADS_TIMELINE_REQUEST: '@@leads-dashboard/GET_LEADS_TIMELINE_REQUEST',
  GET_LEADS_TIMELINE_SUCCESS: '@@leads-dashboard/GET_LEADS_TIMELINE_SUCCESS',
  GET_LEADS_TIMELINE_ERROR: '@@leads-dashboard/GET_LEADS_TIMELINE_ERROR',

  GET_LEADS_ONLINE_USERS_REQUEST: '@@leads-dashboard/GET_LEADS_ONLINE_USERS_REQUEST',
  GET_LEADS_ONLINE_USERS_SUCCESS: '@@leads-dashboard/GET_LEADS_ONLINE_USERS_SUCCESS',
  GET_LEADS_ONLINE_USERS_ERROR: '@@leads-dashboard/GET_LEADS_ONLINE_USERS_ERROR',

  GET_LEADS_PER_CAMPAIGN_REQUEST: '@@leads-dashboard/GET_LEADS_PER_CAMPAIGN_REQUEST',
  GET_LEADS_PER_CAMPAIGN_SUCCESS: '@@leads-dashboard/GET_LEADS_PER_CAMPAIGN_SUCCESS',
  GET_LEADS_PER_CAMPAIGN_ERROR: '@@leads-dashboard/GET_LEADS_PER_CAMPAIGN_ERROR',

  GET_LEADS_CLIENT_REQUEST: '@@leads-dashboard/GET_LEADS_CLIENT_REQUEST',
  GET_LEADS_CLIENT_SUCCESS: '@@leads-dashboard/GET_LEADS_CLIENT_SUCCESS',
  GET_LEADS_CLIENT_ERROR: '@@leads-dashboard/GET_LEADS_CLIENT_ERROR',
};
