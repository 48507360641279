import React from 'react';

import moment, {MomentInput} from 'moment';

import bem from 'client/services/bem';
import {useReduxForm} from 'client/services/hooks';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';
import {TimeInput} from 'client/common/inputs';

import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container';
import {dateToTime} from 'client/components/animations/fieldsets/animation-planning-fieldset/helpers';

import {calculateTimeConstraints} from '../../fieldsets/animation-planning-fieldset/helpers';

import cssModule from './animation-timestamp-block.module.scss';

const b = bem('animation-timestamp-block', {cssModule});

const renderDate = (date: MomentInput, weekDayLettersNumber = 10) => (
  <span className={b('date')}>
    <b>{moment(date).format('DD/MM/YY')}</b> {moment(date).format('dddd').slice(0, weekDayLettersNumber)}
  </span>
);

type AnimationTimestampBlockProps = {
  data: {
    id: number;
    from: string;
    to: string;
  }[];
  day: {
    from: string;
    to: string;
  };
  index: number;
  lastIndex: number;
};

const AnimationTimestampBlock: React.FC<AnimationTimestampBlockProps> = (props) => {
  const {data, day, index, lastIndex} = props;

  const lang = useLanguage('ANIMATIONS.MODALS.ANIMATION_MAIN_MODAL.TABS.GENERAL');

  const {formValues = {}, change} = useReduxForm(AnimationConfigContainerFormName);

  const getTimeConstraints = calculateTimeConstraints(day.from, day.to, formValues?.kit_mapping?.max_hours);

  const deleteTimeStamp = (idx: number) => {
    const updatedAnimationDays = [...data];
    updatedAnimationDays.splice(idx, 1);
    change('animation_days', updatedAnimationDays);
  };

  const timeChangeHandler = (value: MomentInput, idx: number, type: 'from' | 'to') => {
    const prevValue = moment(data[idx]?.[type]);
    const {hours, minutes} = moment(value, 'HH:mm').toObject();

    change(`animation_days[${idx}][${type}]`, prevValue.set({hours, minutes}).format());
  };

  return (
    <div className={b()}>
      {renderDate(day.from, 3)}
      <TimeInput
        key={`from${day.to}${index}`}
        className={b('input')}
        label={lang.FROM}
        value={dateToTime(day.from)}
        onChange={(value: MomentInput) => timeChangeHandler(value, index, 'from')}
        timeConstraints={getTimeConstraints('from')}
      />
      <TimeInput
        key={`to${day.from}${index}`}
        className={b('input')}
        label={lang.TO}
        value={dateToTime(day.to)}
        onChange={(value: MomentInput) => timeChangeHandler(value, index, 'to')}
        timeConstraints={getTimeConstraints('to')}
      />
      <Icon
        name="trash"
        className={b('icon', {hidden: index !== 0 && index !== lastIndex})}
        width={17}
        height={19}
        onClick={() => deleteTimeStamp(index)}
      />
    </div>
  );
};

export default AnimationTimestampBlock;
