import {PRIZE_TYPES} from 'client/components/prizes/constants';
import {PRIZE_CODE_TYPES} from 'client/components/prizes/constants';
import {Game, Prize, PrizeMapsPresent} from 'client/models/prizes/types';

export const isCoupon = (prize: Prize) => prize?.type === PRIZE_TYPES.COUPON;

export const validNameExt = (name: string) => {
  return !name || /(\.jpeg)$/.test(name) || /(\.jpg)$/.test(name) || /(\.png)$/.test(name);
};

export const getTypeCodes = (lang: any) => [
  {
    label: lang.TYPE_OF_CODE_QR,
    value: PRIZE_CODE_TYPES.QR_CODE,
  },
  {
    label: lang.TYPE_OF_CODE_PLAIN_TEXT,
    value: PRIZE_CODE_TYPES.PLAIN_TEXT,
  },
  {
    label: lang.TYPE_OF_CODE_BAR,
    value: PRIZE_CODE_TYPES.BAR_CODE,
  },
  {
    label: lang.TYPE_OF_CODE_NONE,
    value: PRIZE_CODE_TYPES.NONE,
  },
];

type WinNumber = 'win1' | 'win2' | 'win3';
export const hasPrizeMaps = (
  games: {instantWinGame?: Game; lotteryGame?: Game},
  prizeMapsPresent?: PrizeMapsPresent,
) => {
  const result = {
    instantWinGame:
      !!games.instantWinGame && !!prizeMapsPresent?.[`win${games.instantWinGame?.win_number}` as WinNumber],
    lotteryGame: !!games.lotteryGame && !!prizeMapsPresent?.[`win${games.lotteryGame?.win_number}` as WinNumber],
  };
  return {
    ...result,
    total: result.lotteryGame || result.instantWinGame,
  };
};

export const excludePrizeMaps = (
  games: {instantWinGame?: Game; lotteryGame?: Game},
  prizeMapsPresent?: PrizeMapsPresent,
) => {
  const states = hasPrizeMaps(games, prizeMapsPresent);
  return {
    prizeDrawGame: !states?.lotteryGame,
    instantWinGame: !states?.instantWinGame,
    total: !states?.total,
  };
};
