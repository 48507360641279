import React, {useState, useEffect, useCallback} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {dateToString} from 'client/services/helpers';
import {useLanguage, useAppMedia} from 'client/services/hooks';

import {selectOperation} from 'client/ducks/operations/selectors';

import TaskHeaderMenu from 'client/common/header/task-header/task-header-menu';
import Icon from 'client/common/icon';

import './task-header.scss';

const b = bem('task-header');

const TaskHeader = (props) => {
  const {handleBackClick, tabs, task} = props;

  const {isTablet} = useAppMedia();
  const lang = useLanguage('COMMON.TASK_HEADER');
  const [scrolled, setScrolled] = useState(false);
  const operation = useSelector(selectOperation);

  const operationName = operation?.name;
  const operationFrom = dateToString(operation?.from);
  const operationTo = dateToString(operation?.to);
  const imageUrl = get(task, 'image_url');

  const taskImageSize = {
    width: isTablet ? 36 : 90,
    height: isTablet ? 36 : 90,
  };

  const handleScroll = useCallback(() => {
    const offset = window.pageYOffset;

    if (offset > 1 && !scrolled) {
      setScrolled(true);
    } else if (offset === 0 && scrolled) {
      setScrolled(false);
    }
  }, [scrolled]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <header className={b('header', {scrolled: scrolled})}>
      <div className={b('header-content')}>
        <div className={b('task')}>
          <div className={b('task-column')}>
            <div className={b('task-image-container')}>
              {imageUrl ? (
                <img className={b('task-image')} src={imageUrl} alt="" {...taskImageSize} />
              ) : (
                <Icon className={b('task-image')} name="task-image-placeholder" {...taskImageSize} />
              )}
            </div>
          </div>
          <div className={b('task-column', ['space'])}>
            <span className={b('task-name')} title={task?.name}>
              <button className={b('back')} type="button" onClick={handleBackClick}>
                <Icon name="arrow-left" className={b('back-icon')} />
              </button>
              {task?.name}
            </span>
            {tabs && <TaskHeaderMenu tabs={tabs} className={b('menu')} />}
          </div>
        </div>
        <div className={b('operation')}>
          <div className={b('operation-name')}>
            <span className={b('operation-label')}>{lang.OPERATION}</span>
            <span className={b('operation-value')} title={operationName}>
              {operationName}
            </span>
          </div>
          <div className={b('operation-period')}>
            <span className={b('operation-label')}>{lang.PERIOD}</span>
            <span className={b('operation-value', ['date'])} title={operationName}>
              {operationFrom} - {operationTo}
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

TaskHeader.propTypes = {
  handleBackClick: PropTypes.func,
  tabs: PropTypes.array,
  task: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image_url: PropTypes.string,
  }),
};

TaskHeader.defaultProps = {
  handleBackClick: null,
  tabs: [],
  task: null,
};

export default TaskHeader;
