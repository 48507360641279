import React, {useEffect, useCallback, useState, useMemo} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {generatePath, useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {useMount, useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {getEmailTemplateKinds} from 'client/ducks/email-templates/actions';
import {selectEmailTemplateKinds} from 'client/ducks/email-templates/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import LayoutPanel from 'client/common/panels/layout-panel';
import TabSwitcher from 'client/common/tab-switcher';
import StatusTag from 'client/common/tags/status-tag';

import Spinner from 'client/components/common/spinner';

import DiyDevices from 'client/components/diy-operation/dashboards/diy-devices-dashboard';
import DiyOnlineDashboard from 'client/components/diy-operation/dashboards/diy-online-dashboard';
import {DIYOpDashboard} from 'client/components/diy-operation/dashboards/diy-op-dashboard';
import {DiyOpEmailTemplates} from 'client/components/diy-operation/dashboards/diy-op-email-templates';
import {getDiyOperationStatus} from 'client/components/diy-operation/helpers';
import {useFetchDiyGame} from 'client/components/diy-operation/hooks';
import {Autotask} from 'client/models/autotask/types';
import {InteractionGroups} from 'client/models/interaction-groups/types';
import {Operation} from 'client/models/operations/types';

import './configuration-tab.scss';

const PAGES = CLIENT_PAGES.CLIENT_AUTOTASK_TABS;

const TABS = {
  OPERATION: PAGES.CONFIGURATION_OPERATION.replace('/', ''),
  DEVICES: PAGES.CONFIGURATION_DEVICES.replace('/', ''),
  ONLINE: PAGES.CONFIGURATION_ONLINE.replace('/', ''),
  EMAIL_TEMPLATES: PAGES.CONFIGURATION_EMAIL_TEMPLATES.replace('/', ''),
};

const b = bem('configuration-tab');

const getTabClassName = ({isActive}: {isActive: boolean}) => b('switcher-tab', {active: isActive});

type ConfigurationTabProps = {
  operation: Operation;
  fetchOperation: (isConfTab: boolean) => Promise<void | never>;
  fetchAutotask: () => Promise<void>;
  interactionGroups?: InteractionGroups[];
  autotask: Autotask;
};

const ConfigurationTab: React.FC<ConfigurationTabProps> = (props) => {
  const {fetchOperation, fetchAutotask, operation, autotask, interactionGroups} = props;

  const dispatch = useDispatch();
  const params = useParams<{subtab: string}>();
  const history = useHistory();

  const {subtab} = params;
  const lang = useLanguage('CONFIGURATION_TAB');
  const client = useSelector(selectCurrentClient);
  const emailTemplateKinds = useSelector(selectEmailTemplateKinds);
  const match = useRouteMatch();
  const {fetchGame} = useFetchDiyGame({onMount: true});

  const [loading, setLoading] = useState(false);
  const [loadingTemplates, toggleLoadingTemplates] = useToggle(false);

  const fetchOperationConfiguration = useCallback(() => {
    setLoading(true);
    return fetchOperation(true).then(() => setLoading(false));
  }, [fetchOperation]);

  const status = useMemo(() => getDiyOperationStatus(operation), [operation]);

  const fetchEmailTemplateKinds = useCallback(async () => {
    toggleLoadingTemplates();
    await dispatch(getEmailTemplateKinds({operationId: operation.id}));
    toggleLoadingTemplates();
  }, [dispatch, operation.id, toggleLoadingTemplates]);

  useEffect(() => {
    if (!subtab && match) {
      history.replace(generatePath(match?.path, params) + PAGES.CONFIGURATION_OPERATION);
    }
  }, [params, subtab, history, match]);

  useMount(() => {
    fetchOperationConfiguration();
    fetchEmailTemplateKinds();
  });

  const onTabClick = (tabValue: string) => {
    if (match) {
      history.push(generatePath(match?.path, {...params, subtab: tabValue}));
    }
  };

  const hasDevicesTab =
    !!interactionGroups && interactionGroups.some((interaction: {type: string}) => interaction.type === 'Plan');
  const hasOnlineTab =
    !!interactionGroups && interactionGroups.some((interaction: {type: string}) => interaction.type === 'Campaign');

  const tabsConfig = [
    {
      title: lang.OPERATION_TAB,
      value: TABS.OPERATION,
      className: getTabClassName,
    },
    {
      title: lang.DEVICES_TAB,
      value: TABS.DEVICES,
      className: getTabClassName,
      exclude: !hasDevicesTab || client.auto_configuration,
    },
    {
      title: lang.ONLINE_TAB,
      value: TABS.ONLINE,
      className: getTabClassName,
      exclude: !hasOnlineTab || client.auto_configuration,
    },
    {
      title: lang.EMAIL_TEMPLATES_TAB,
      value: TABS.EMAIL_TEMPLATES,
      className: getTabClassName,
      exclude: !emailTemplateKinds.length,
    },
  ];

  if (operation.show_configuration === false || operation.client_automation_task_id !== autotask.id) {
    return null;
  }

  return (
    <div className={b()}>
      <LayoutPanel className={b('layout-panel')}>
        {loading && !client.id ? (
          <div className={b('spinner-wrap')}>
            <Spinner color="clients" />
          </div>
        ) : (
          <>
            <div className={b('switcher-container')}>
              <TabSwitcher
                className={b('switcher')}
                tabs={tabsConfig}
                onTabClick={onTabClick}
                activeTab={subtab || TABS.OPERATION}
              />
              {!loading && subtab === TABS.OPERATION && (
                <StatusTag status={status} className={b('status')} id="diy-conf-status-tag" />
              )}
            </div>
            {loading ? (
              <div className={b('spinner-wrap')}>
                <Spinner color="clients" />
              </div>
            ) : (
              <div className={b('content')}>
                {subtab === TABS.OPERATION && (
                  <DIYOpDashboard
                    operation={operation}
                    autotask={autotask}
                    fetchOperation={fetchOperationConfiguration}
                    fetchAutotask={fetchAutotask}
                    emailTemplateKinds={emailTemplateKinds}
                    loadingEmailTemplateKinds={loadingTemplates}
                  />
                )}
                {subtab === TABS.DEVICES && (
                  <DiyDevices
                    operation={operation}
                    fetchOperation={fetchOperationConfiguration}
                    fetchGame={fetchGame}
                  />
                )}
                {subtab === TABS.ONLINE && (
                  <DiyOnlineDashboard
                    operation={operation}
                    fetchOperation={fetchOperationConfiguration}
                    fetchGame={fetchGame}
                  />
                )}
                {subtab === TABS.EMAIL_TEMPLATES && (
                  <DiyOpEmailTemplates emailTemplateKinds={emailTemplateKinds} loading={loadingTemplates} />
                )}
              </div>
            )}
          </>
        )}
      </LayoutPanel>
    </div>
  );
};

ConfigurationTab.displayName = 'ConfigurationTab';

export default ConfigurationTab;
