import React from 'react';

import {useReduxFetch} from 'client/services/hooks';
import {apiAction} from 'client/services/hooks/use-redux-fetch/requests';

import AwaitContainer from 'client/common/await-container';
import {API_METHODS} from 'client/common/config';

import WinningPrizeCard, {WinningPrizeCardProps} from 'client/components/winnings/cards/winning-prize-card';
import WinningBaseContainer from 'client/components/winnings/containers/winning-base-container';
import {WinningSearchType} from 'client/components/winnings/forms/winning-search-form/types';
import {PresentWinner} from 'client/models/winnings';

export type WinningPrizeContainerProps = {
  isDesktop?: boolean;
  searchParams: Record<string, string>;
  participationPrizeId: number;
  searchCriteria: WinningSearchType;
} & Pick<WinningPrizeCardProps, 'className' | 'classNames'>;

const WinningPrizeContainer: React.FC<WinningPrizeContainerProps> = (props) => {
  const {isDesktop, searchParams, participationPrizeId, ...restProps} = props;

  const {data, loading, fetch} = useReduxFetch<PresentWinner>({
    action: apiAction,
    actionArgs: {
      url: `${API_METHODS.PARTICIPATION_PRIZES}/${participationPrizeId}${API_METHODS.PRESENT_WINNER}`,
      queryParams: searchParams,
    },
  });

  const handleDistribute = () => {
    fetch();
  };

  const card = data && (
    <WinningPrizeCard
      {...restProps}
      data={data}
      isDesktop={isDesktop}
      id={participationPrizeId}
      onDistribute={handleDistribute}
    />
  );

  if (isDesktop) {
    return <AwaitContainer loading={loading}>{card}</AwaitContainer>;
  }

  return <WinningBaseContainer loading={loading}>{card}</WinningBaseContainer>;
};

export default WinningPrizeContainer;
