export default {
  FETCH_SOURCES_REQUEST: '@@sources/FETCH_SOURCES_REQUEST',
  FETCH_SOURCES_SUCCESS: '@@sources/FETCH_SOURCES_SUCCESS',
  FETCH_SOURCES_ERROR: '@@sources/FETCH_SOURCES_ERROR',

  CREATE_SOURCE_REQUEST: '@@sources/CREATE_SOURCE_REQUEST',
  CREATE_SOURCE_SUCCESS: '@@sources/CREATE_SOURCE_SUCCESS',
  CREATE_SOURCE_ERROR: '@@sources/CREATE_SOURCE_ERROR',

  PATCH_SOURCE_REQUEST: '@@sources/PATCH_SOURCE_REQUEST',
  PATCH_SOURCE_SUCCESS: '@@sources/PATCH_SOURCE_SUCCESS',
  PATCH_SOURCE_ERROR: '@@sources/PATCH_SOURCE_ERROR',

  DELETE_SOURCE_REQUEST: '@@sources/DELETE_SOURCE_REQUEST',
  DELETE_SOURCE_SUCCESS: '@@sources/DELETE_SOURCE_SUCCESS',
  DELETE_SOURCE_ERROR: '@@sources/DELETE_SOURCE_ERROR',

  GET_CLIENT_OPTIONS_REQUEST: '@@sources/GET_CLIENT_OPTIONS_REQUEST',
  GET_CLIENT_OPTIONS_SUCCESS: '@@sources/GET_CLIENT_OPTIONS_SUCCESS',
  GET_CLIENT_OPTIONS_ERROR: '@@sources/GET_CLIENT_OPTIONS_ERROR',
};
