import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router';

import {useLanguage} from 'client/services/hooks';

import {dubFactorAuthSettingsSelector} from 'client/ducks/settings/selectors';
import {authenticate as login, sendOtpCode} from 'client/ducks/user/actions';

import {CLIENT_PAGES} from 'client/common/config';

import AuthDoubleFactorCodeInput from './auth-double-factor-code-input';
import AuthDoubleFactorSelectType from './auth-select-type-select-type';

export const OPTIONS = {
  EMAIL: 'email',
  SMS: 'sms',
};

const AuthDoubleFactor = ({history}) => {
  const lang = useLanguage('AUTH.DOUBLE_FACTOR_AUTHENTIFICATION');
  const [option, setOption] = useState(OPTIONS.EMAIL);
  const [isCodeReceived, setIsCodeReceived] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [lastAttemptCode, setLastAttemptCode] = useState('');
  const [accountBlocked, setAccountBlocked] = useState(false);
  const [unlockAfter, setUnlockAfter] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const userCreds = useSelector(dubFactorAuthSettingsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userCreds.email) {
      history.push(CLIENT_PAGES.LOGIN);
    }
  }, [history, userCreds.email]);

  const receiveCode = useCallback(() => {
    return dispatch(sendOtpCode(userCreds.email, option)).then((res) => {
      const {exception} = res;

      if (exception) {
        const langKey = exception.toUpperCase().replaceAll(' ', '_');
        setErrorMessage(lang[langKey] || exception);

        return Promise.resolve();
      }

      setIsCodeReceived(true);
      setErrorMessage('');
      return Promise.resolve();
    });
  }, [dispatch, lang, option, userCreds.email]);

  const authenticate = useCallback(
    (code) => {
      dispatch(
        login({
          user: {
            email: userCreds.email,
            password: userCreds.password,
            otp_attempt: code,
          },
        }),
      ).then((res) => {
        if (res.payload.response) {
          const {exception, locked_permanently, unlock_after, locked_by} = res.payload.response;

          if (exception === 'invalid otp code') {
            setAttempts((prev) => prev + 1);
            setLastAttemptCode(code);
          }
          if (locked_by === 'otp') {
            const temporaryBlocked = !locked_permanently && !!unlock_after;
            setAccountBlocked(true);
            setUnlockAfter(temporaryBlocked ? unlock_after : 0);
          }
        }
        if (!res.error) {
          history.push(CLIENT_PAGES.HOME);
        }
      });
    },
    [dispatch, history, userCreds.email, userCreds.password],
  );

  const unBlock = useCallback(() => {
    setAccountBlocked(false);
    setUnlockAfter(0);
  }, []);

  const contact = option === OPTIONS.EMAIL ? userCreds.options.email : userCreds.options.phone;

  return (
    <div>
      {isCodeReceived ? (
        <AuthDoubleFactorCodeInput
          attempts={attempts}
          lastAttemptCode={lastAttemptCode}
          contact={contact}
          blocked={accountBlocked}
          unlockAfter={unlockAfter}
          authenticate={authenticate}
          changeMode={() => setIsCodeReceived(false)}
          resendAuthCode={receiveCode}
          unBlock={unBlock}
          credentials={userCreds.options}
        />
      ) : (
        <AuthDoubleFactorSelectType
          option={option}
          onChange={setOption}
          receiveCode={receiveCode}
          credentials={userCreds.options}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};

AuthDoubleFactor.displayName = 'AuthDoubleFactor';
AuthDoubleFactor.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(AuthDoubleFactor);
