import React, {useState} from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import TabSwitcher from 'client/common/tab-switcher';

import OngoingOperationsTable from 'client/components/client-agency/components/tables/ongoing-operations-table';
import {Operation} from 'client/models/operations/types';

import cssModule from './ongoing-operations-block.module.scss';

const b = bem('ongoing-operations-block', {cssModule});

const TABS = {
  ONGOING: 'ongoing',
  FINISHED: 'finished',
};

type Operations = {
  data: Operation[];
  meta: {
    total_count: number;
    total_pages: number;
    current_page: number;
  };
};

type OngoingOperationsBlockProps = {
  languageState: any; // TODO: inject directly to OngoingOperationsTable and remove from here
  ongoingOperations: Operations;
  finishedOperations: Operations;
  onOngoingOperationsPageChange: () => void;
  onOngoingOperationsSortChange: () => void;
  onFinishedOperationsPageChange: () => void;
  onFinishedOperationsSortChange: () => void;
  onAddNew: () => void;
};

const OngoingOperationsBlock: React.FC<OngoingOperationsBlockProps> = (props) => {
  const {
    languageState,
    ongoingOperations,
    finishedOperations,
    onOngoingOperationsPageChange,
    onOngoingOperationsSortChange,
    onFinishedOperationsPageChange,
    onFinishedOperationsSortChange,
    onAddNew,
  } = props;
  const lang = useLanguage('AGENCY');
  const [activeTab, setActiveTab] = useState(TABS.ONGOING);

  const tabs = [
    {
      title: `${lang.ONGOING_OPERATIONS_TITLE} (${ongoingOperations.meta.total_count || 0})`,
      value: TABS.ONGOING,
    },
    {
      title: `${lang.FINISHED_OPERATIONS_TITLE} (${finishedOperations.meta.total_count || 0})`,
      value: TABS.FINISHED,
    },
  ];

  return (
    <div className={b()}>
      <TabSwitcher tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />
      {activeTab === TABS.ONGOING && (
        <OngoingOperationsTable
          languageState={languageState}
          data={ongoingOperations.data}
          totalPages={ongoingOperations.meta.total_pages || 1}
          totalItems={ongoingOperations.meta.total_count}
          currentPage={ongoingOperations.meta.current_page}
          onPageChange={onOngoingOperationsPageChange}
          onSortChange={onOngoingOperationsSortChange}
          addNewClick={onAddNew}
        />
      )}
      {activeTab === TABS.FINISHED && (
        <OngoingOperationsTable
          languageState={languageState}
          data={finishedOperations.data}
          totalPages={finishedOperations.meta.total_pages || 1}
          totalItems={finishedOperations.meta.total_count}
          currentPage={finishedOperations.meta.current_page}
          onPageChange={onFinishedOperationsPageChange}
          onSortChange={onFinishedOperationsSortChange}
          addNewClick={onAddNew}
        />
      )}
    </div>
  );
};

export default OngoingOperationsBlock;
