import {RSAA} from 'redux-api-middleware';

import {API_PATH, API_METHODS} from 'client/common/config';

export const CATEGORIES_NO_DATA = 'CATEGORIES_NO_DATA';
export const CATEGORIES_LOADING = 'CATEGORIES_LOADING';
export const CATEGORIES_LOADED = 'CATEGORIES_LOADED';
export const CATEGORIES_LOAD_FAILED = 'CATEGORIES_LOAD_FAILED';

export function getCategories() {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.CATEGORIES}`,
        method: 'GET',
        isAuth: true,
        types: [CATEGORIES_LOADING, CATEGORIES_LOADED, CATEGORIES_LOAD_FAILED],
      },
    });
  };
}
