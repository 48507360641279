import React, {useEffect, useState, useCallback} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {getClientDiagrams, getClientDiagram, clearClientDiagrams} from 'client/ducks/results-graphs/actions';
import {selectClientDiagrams} from 'client/ducks/results-graphs/selectors';

import {useToast} from 'client/common/hooks/useToast';

import Spinner from 'client/components/common/spinner';

import {Diagram} from 'client/models/diagrams/types';
import {ApiDispatch} from 'client/types';

import DiagramCard from './diagram-card';

import cssModule from './results-tab.module.scss';

const b = bem('results-tab', {cssModule});

type ResultsTabProps = {
  task: {id: number};
};

const ResultsTab: React.FC<ResultsTabProps> = ({task}) => {
  const [loading, setLoading] = useState(false);

  const langError = useLanguage('ERRORS');
  const dispatch: ApiDispatch = useDispatch();

  const {appendToastNotification} = useToast();

  const diagrams = useSelector(selectClientDiagrams);

  const fetchDiagrams = useCallback(async () => {
    const params = {
      q: {
        automation_task_id_eq: task.id,
        s: 'position',
      },
    };

    setLoading(true);

    try {
      const response = await dispatch(getClientDiagrams(params));
      setLoading(false);

      const ids = response.payload?.diagrams.map(({id}: {id: number}) => id);

      if (ids) {
        for (const id of ids) {
          await dispatch(
            getClientDiagram({
              automation_task_id: task.id,
              q: {id_eq: id},
            }),
          );
        }
      }
    } catch (error) {
      appendToastNotification({
        type: 'error',
        title: langError.ERROR_MESSAGE,
      });
    }
  }, [appendToastNotification, dispatch, langError.ERROR_MESSAGE, task.id]);

  useEffect(() => {
    fetchDiagrams();

    return () => {
      clearClientDiagrams();
    };
  }, [fetchDiagrams]);

  if (loading) {
    return <Spinner centered color="primary" className={b('spinner')} />;
  }

  return (
    <div className={b()}>
      {diagrams.map((diagram: Diagram) => (
        <DiagramCard className={b('diagram-card')} key={diagram.id} diagram={diagram} />
      ))}
    </div>
  );
};

export default ResultsTab;
