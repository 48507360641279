import React, {Component} from 'react';

import PropTypes from 'prop-types';

import {transformDate, transformDateTimeZone} from 'client/services/helpers';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';

class VariableValue extends Component {
  static defaultProps = {
    withTimeZone: false,
  };

  static propTypes = {
    variable: PropTypes.shape({
      array: PropTypes.bool,
      kind: PropTypes.string,
    }),
    value: PropTypes.any,
    withTimeZone: PropTypes.bool,
  };

  renderFile = (file = {}, key = 1) => {
    if (!file || !file.url) {
      return null;
    }

    const url = file.url;
    const filename = url.split('/').reverse()[0];

    switch (true) {
      case /\.(jpe?g|png|bmp|gif|ico)$/.test(url):
        return <div style={{backgroundImage: 'url(' + url + ')'}} title={filename} key={key} />;
      case /\.pdf$/.test(url):
        return (
          <div className="icon-box" key={key}>
            <Icon name="file-pdf" />
            <a href={url} className="link ellipsis-text" target="blank">
              {filename}
            </a>
          </div>
        );
      case /\.docx?$/.test(url):
        return (
          <div className="icon-box" key={key}>
            <Icon name="file-doc" />
            <a href={url} className="link ellipsis-text" target="blank">
              {filename}
            </a>
          </div>
        );
      default:
        return (
          <div className="icon-box" key={key}>
            <Icon name="file" />
            <a href={url} className="link ellipsis-text" target="blank">
              {filename}
            </a>
          </div>
        );
    }
  };

  renderDatetime = (value, key = 1) => (
    <div className="ellipsis-text fake-input__field" key={key}>
      {this.props.withTimeZone ? transformDateTimeZone(value) : transformDate(value, true, true)}
    </div>
  );

  renderText = (text, key = 1) => {
    if (text === null) {
      return <div key={key} className="fake-input__field" />;
    }
    // const textNew = 'une gourde sport has many of these products';
    const fieldClass = text && text.length > 20 ? 'fake-input__field--long-text' : '';
    return (
      <div key={key} className={`ellipsis-text fake-input__field ${fieldClass}`}>
        {`${text}`}
      </div>
    );
  };

  switchArrayRenderer = ({array: a}, renderer) =>
    !a
      ? renderer
      : (array) => (
          <CustomScrollbars
            scrollbarProps={{
              hideTracksWhenNotNeeded: true,
              autoHeightMax: 200,
              autoHeightMin: 30,
            }}
          >
            {array && array.map(renderer)}
          </CustomScrollbars>
        );

  selectRenderer = (column) => {
    switch (column.kind) {
      case 'datetime':
        return this.switchArrayRenderer(column, this.renderDatetime);
      case 'file':
        return this.switchArrayRenderer(column, this.renderFile);

      default:
        return this.switchArrayRenderer(column, this.renderText);
    }
  };

  render() {
    const {variable, value} = this.props;

    return this.selectRenderer(variable)(value);
  }
}

export default VariableValue;
