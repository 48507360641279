import React, {useCallback, useMemo, useState} from 'react';

import {reject} from 'lodash/collection';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons';

import ParticipantsCommonList from 'client/components/participants/lists/participants-common-list';
import {DEFAULT_PER_PAGE} from 'client/components/participants/participants-list/constants';

import CardColumn from './components/columns/card-column';
import ClientHiddenColumn from './components/columns/client-hidden-column';
import ParticipantsListToolbar from './components/participants-list-toolbar';

import ParticipationResultModal from '../../modals/participants-result-modal';

import './participants-main-data-grid.scss';

const b = bem('participants-main-data-grid');

const ParticipantsMainDataGrid = (props) => {
  const {
    data,
    isLoading,
    meta,
    isAdmin,
    isReportTable,
    participationDisplayItems,
    columnExportItems,
    columnVisibilityItems,
    onPaginationChange,
    pagination,
    onChangeCheckedList,
  } = props;

  const [checkedParticipants, setCheckedParticipants] = useState([]);
  const [showingResult, setShowingResult] = useState({});
  const [showResultModal, setShowResultModal] = useState(false);
  const lang = useLanguage('PARTICIPANTS_LIST');

  const onCheckAll = (state) => {
    const nextCheckedList = state ? uniqBy([...checkedParticipants, ...data], 'id') : [];
    setCheckedParticipants(nextCheckedList);
    onChangeCheckedList(nextCheckedList);
  };

  const onCheckRow = (checkedState, row) => {
    const nextCheckedList = checkedState
      ? uniqBy([...checkedParticipants, row], 'id')
      : reject([...checkedParticipants], {id: row.id});
    setCheckedParticipants(nextCheckedList);
    onChangeCheckedList(nextCheckedList);
  };

  const onClickSeeResult = (result) => {
    setShowResultModal(true);
    setShowingResult(result);
  };

  const getColumns = useCallback(
    (defaultColumns) => {
      const result = isAdmin
        ? [
            {
              name: 'lead_card',
              path: 'lead_id',
              width: 80,
              label: lang.LEAD_ID_TABLE_COLUMN,
              render: ({value}) => <CardColumn value={value} />,
            },
            {
              name: 'client_hidden',
              path: 'visible_by_client',
              width: 120,
              label: lang.CLIENT_HIDDEN_COLUMN,
              render: ({value, item}) => (
                <ClientHiddenColumn isVisible={value} participation={item} disabled={isReportTable} />
              ),
            },
            {
              name: 'see_result',
              width: 120,
              label: 'See result',
              render: ({item}) => (
                <AppButton
                  link
                  asWrap
                  label="See result"
                  className={b('see-result-button')}
                  onClick={() => onClickSeeResult(item)}
                />
              ),
            },
          ]
        : [];

      return [...result, ...defaultColumns];
    },
    [isAdmin, lang, isReportTable],
  );

  const displayColumns = useMemo(() => {
    const result = [];
    if (isReportTable) {
      result.push(...(columnExportItems || []));
    } else if (isAdmin) {
      result.push(...(participationDisplayItems || []));
    } else {
      return result.push(...(columnVisibilityItems || []));
    }
    return result;
  }, [columnExportItems, columnVisibilityItems, isAdmin, isReportTable, participationDisplayItems]);

  const handleChangePerPage = (value) => {
    onPaginationChange({
      page: 1,
      perPage: value || DEFAULT_PER_PAGE,
      sortField: pagination?.sortField,
      sortOrder: pagination?.sortOrder,
    });
  };

  return (
    <div className={b()}>
      <div className={b('toolbar')}>
        <ParticipantsListToolbar
          {...props}
          filter={props.filter}
          totalCount={meta.total_count}
          isReportTable={isReportTable}
          isAdmin={isAdmin}
          perPage={pagination.perPage}
          onChangePerPage={handleChangePerPage}
        />
      </div>
      <ParticipantsCommonList
        data={data}
        displayColumns={displayColumns}
        isAdmin
        loading={isLoading}
        meta={meta}
        getColumns={getColumns}
        tableProps={{
          checkable: true,
          checkedRows: checkedParticipants,
          checkboxProps: {color: 'games', className: b('checkbox')},
          commonHeaderClassName: b('header'),
          commonCellClassName: b('cell'),
          onCheckAll,
          onCheck: onCheckRow,
        }}
        onPaginationChange={onPaginationChange}
        pagination={pagination}
      />

      {showResultModal && (
        <ParticipationResultModal
          show={showResultModal}
          scenarioExecution={showingResult}
          onClose={() => setShowResultModal(false)}
        />
      )}
    </div>
  );
};

ParticipantsMainDataGrid.propTypes = {
  data: PropTypes.array,
  meta: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isReportTable: PropTypes.bool.isRequired,
  participationDisplayItems: PropTypes.array.isRequired,
  columnVisibilityItems: PropTypes.array.isRequired,
  columnExportItems: PropTypes.array,
  onShowTableSettings: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  searchOption: PropTypes.string,
  onSearchOptionChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  onPaginationChange: PropTypes.func,
  searchDefault: PropTypes.string,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    sortField: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
  }).isRequired,
  onFilterFieldClear: PropTypes.func.isRequired,
  onFilterClear: PropTypes.func.isRequired,
  filter: PropTypes.object,
  onFilterShow: PropTypes.func.isRequired,
  showClear: PropTypes.bool.isRequired,
  onShowExport: PropTypes.func.isRequired,
  onShowExportAll: PropTypes.func.isRequired,
  onShowClientVisibilitySettings: PropTypes.func.isRequired,
  onChangeCheckedList: PropTypes.func.isRequired,
};

ParticipantsMainDataGrid.defaultProps = {
  filter: {},
  data: [],
  isReportTable: false,
  isLoading: false,
  updateParticipation: null,
  searchOption: null,
  onSearchOptionChange: null,
  onSearchChange: null,
  searchDefault: '',
};

export default ParticipantsMainDataGrid;
