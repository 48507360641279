import React, {Component} from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, FormSection, change, formValueSelector, arrayPush, arrayRemoveAll} from 'redux-form';

import {required} from 'client/services/validator';

import {selectLocalizedDBs} from 'client/ducks/column-adapters/selectors';
import {selectOperationsAdaptersMapped} from 'client/ducks/operations/selectors';

import {DATABASES} from 'client/common/config';

import fieldTemplate from 'client/components/common/field';

class BCVarBlock extends Component {
  static propTypes = {
    database: PropTypes.object,
    change: PropTypes.func.isRequired,
    arrayPush: PropTypes.func.isRequired,
    arrayRemoveAll: PropTypes.func.isRequired,
    operations: PropTypes.array.isRequired,
    databases: PropTypes.array.isRequired,
    languageState: PropTypes.object.isRequired,
    varId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    formName: PropTypes.string.isRequired,
    varName: PropTypes.string.isRequired,
    varRequired: PropTypes.bool,
    columnsOptions: PropTypes.array,
    conditionColumnOptions: PropTypes.array,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    database: {},
    columnsOptions: [],
    conditionColumnOptions: [],
    disabled: false,
    varRequired: false,
  };

  static getFieldFullName = (name, sectionName) => `_${sectionName}.${name}`;

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.BROADCASTING_TASK.PERSONALIZE_STEP;

    this.rules = {
      required: required(this.LANGUAGE.ERRORS.REQUIRED),
    };
  }

  changeField = (...fields) => {
    fields.forEach((field) => {
      this.props.change(this.props.formName, BCVarBlock.getFieldFullName(field.name, this.props.varId), field.value);
    });
  };

  handleDBChange = (e, newValue, prevValue) => {
    if (prevValue && newValue.db === prevValue.db) {
      return;
    }
    this.changeField({name: 'operation', value: null}, {name: 'column_adapter', value: null});
  };

  handleOperationChange = (e, newValue, prevValue) => {
    if (prevValue && newValue.value === prevValue.value) {
      return;
    }
    this.changeField({name: 'column_adapter', value: null});
  };

  render() {
    const {LANGUAGE} = this;

    const {varName, varId, varRequired, database, databases, operations, columnsOptions, disabled} = this.props;

    const sectionName = '_' + varId;
    const showColumns = !isEmpty(database);
    const showOperations = database && database.db === DATABASES.CUSTOM_OPERATION;

    return (
      <FormSection name={sectionName}>
        <div className="field-group pos-rel z_200">
          <div className="field-group__title theme-color-16 break-word">{`{{${varName}}}`}</div>
          <div className="field-group__row field-group__row--vert-top">
            <div className="field-group__field field-group__field--size-6">
              <Field
                scrollBarProps={{
                  autoHeightMax: 205,
                }}
                placeholder={LANGUAGE.SELECT_DB_PLACEHOLDER}
                cssModifier="select--no-min-height select--color-2"
                searchable={false}
                name="database"
                valueKey="db"
                type="select"
                options={databases}
                onChange={this.handleDBChange}
                component={fieldTemplate}
                validate={this.rules.required}
                disabled={disabled}
              />
            </div>
            {showOperations && (
              <div className="field-group__field field-group__field--size-6">
                <Field
                  scrollBarProps={{
                    autoHeightMax: 200,
                  }}
                  placeholder={LANGUAGE.SELECT_OP_PLACEHOLDER}
                  cssModifier="select--no-min-height select--color-1 select--view-6"
                  searchable={false}
                  name="operation"
                  type="select"
                  options={operations}
                  onChange={this.handleOperationChange}
                  component={fieldTemplate}
                  validate={this.rules.required}
                  disabled={disabled}
                />
              </div>
            )}
            {showColumns && (
              <div className="field-group__field field-group__field--size-6">
                <Field
                  placeholder={LANGUAGE.SELECT_COL_PLACEHOLDER}
                  cssModifier="select--no-min-height select--color-3 select--view-6"
                  options={columnsOptions}
                  labelKey="name"
                  valueKey="id"
                  searchable={false}
                  name="column_adapter"
                  type="select"
                  onChange={this.handleColumnChange}
                  component={fieldTemplate}
                  validate={this.rules.required}
                  disabled={disabled}
                />
              </div>
            )}
          </div>
          <div className="field-group__row">
            <div className="field-group__field">
              <Field
                type="checkbox"
                name="required"
                label={LANGUAGE.MANDATORY_LABEL}
                component={fieldTemplate}
                disabled={disabled || varRequired}
              />
            </div>
          </div>
        </div>
      </FormSection>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {varId, isVarArray} = ownProps;

  const selector = formValueSelector(ownProps.formName);
  const database = selector(state, BCVarBlock.getFieldFullName('database', varId)) || null;
  const operation = selector(state, BCVarBlock.getFieldFullName('operation', varId));
  const operations = selectOperationsAdaptersMapped(state);
  const databases = selectLocalizedDBs(
    state,
    [DATABASES.LEADS, DATABASES.VISUALS1, DATABASES.VISUALS2, DATABASES.VISUALS3, DATABASES.CUSTOM_OPERATION],
    false,
  );

  const currentOp = operation && operations.find((o) => o.value === operation.value);
  const currentOpColumns = currentOp ? currentOp.columnAdapters : [];
  const currentDb = database && databases.find((d) => d.db === database.db);
  const currentColums = currentDb ? currentDb.columnAdapters : [];
  const conditionColumnOptions =
    database && database.db === DATABASES.CUSTOM_OPERATION ? currentOpColumns : currentColums;
  const columnsOptions = conditionColumnOptions && conditionColumnOptions.filter((c) => c.array === isVarArray);

  return {
    databases,
    database,
    operation,
    operations,
    languageState: state.languageState,
    columnsOptions,
  };
};

const mapDispatchToProps = {
  change,
  arrayPush,
  arrayRemoveAll,
};

export default connect(mapStateToProps, mapDispatchToProps)(BCVarBlock);
