import React from 'react';

import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {CLIENT_PAGES} from 'client/common/config';

import Icon from 'client/components/common/icon';

function CardColumn(props) {
  const {value} = props;

  if (!value) {
    return null;
  }

  return (
    <Link to={`${CLIENT_PAGES.LEADS_PROFILE}/${value}`}>
      <span className="theme-color-9">
        <Icon name="comment" />
      </span>
    </Link>
  );
}

CardColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CardColumn;
