import flow from 'lodash/flow';
import fromPairs from 'lodash/fp/fromPairs';
import map from 'lodash/fp/map';
import get from 'lodash/get';
import has from 'lodash/has';

export const getOptInsOptions = (data, optIns) => {
  const activeOptIns = flow([
    (columns) => get(columns, 'message_scenario_step_to_opt_in_columns', []),
    map((column) => [column.opt_in_column_id, column.id]),
    fromPairs,
  ])(data.implementation);

  return optIns.map((optIn) => ({
    id: activeOptIns[optIn.id],
    opt_in_column_id: optIn.id,
    name: optIn.name,
    active: has(activeOptIns, optIn.id),
    deleted: false,
  }));
};

export const normalizeBoolean = (value) => value === 'true';
