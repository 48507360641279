import {COMPANIES_NO_DATA, COMPANIES_LOADED} from './companies.action';

let init = {
  type: COMPANIES_NO_DATA,
  payload: [],
};

export default function (_state_ = init, action) {
  let state = Object.assign({}, _state_);
  switch (action.type) {
    case COMPANIES_NO_DATA:
      state.type = COMPANIES_NO_DATA;
      state.payload = action.payload;
      return state;
    case COMPANIES_LOADED:
      state.type = COMPANIES_LOADED;
      state.payload = action.payload.companies;
      return state;
    default:
      return state;
  }
}
