import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function clearSGInterfacesState() {
  return {
    type: types.CLEAR_SG_INTERFACES_STATE,
  };
}

export function getNewSGInterfaceAction(body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SG_INTERFACES_NEW}`,
      method: 'POST',
      types: [types.GET_NEW_SG_INTERFACE_REQUEST, types.GET_NEW_SG_INTERFACE_SUCCESS, types.GET_NEW_SG_INTERFACE_ERROR],
      body,
    });
}

export function createSGInterfaceAction(body = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SG_INTERFACES}`,
      method: 'POST',
      types: [types.CREATE_SG_INTERFACE_REQUEST, types.CREATE_SG_INTERFACE_SUCCESS, types.CREATE_SG_INTERFACE_ERROR],
      body,
    });
}
