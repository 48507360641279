import React from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';

import {getClientPage} from 'client/services/helpers';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';

import {CLIENT_PAGES} from 'client/common/config';

const AutomationTaskIdCell = ({disabled}) => {
  const {clientId} = useParams();
  const {client, id, name, operation_id} = useSelector(selectAutotask);
  const currentClient = useSelector(selectCurrentClient);

  if (disabled) {
    return id;
  }

  return (
    <Link
      to={`${getClientPage(client || currentClient)}/${clientId}${CLIENT_PAGES.OPERATIONS}/${operation_id}${
        CLIENT_PAGES.AUTOTASK
      }/${id}`}
      className="link ellipsis-text "
    >
      {name}
    </Link>
  );
};

AutomationTaskIdCell.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default AutomationTaskIdCell;
