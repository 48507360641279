import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import LayoutPanel from 'client/common/panels/layout-panel';

import './instore-monitor-panel.scss';

const b = bem('instore-monitor-panel');

const InstoreMonitorPanel = ({title, description}) => {
  return (
    <LayoutPanel>
      <div className={b('content')}>
        <span className={b('title')}>{title}</span>
        <span className={b('description')}>{description}</span>
      </div>
    </LayoutPanel>
  );
};

InstoreMonitorPanel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

InstoreMonitorPanel.defaultProps = {
  title: '',
  description: '',
};

export default InstoreMonitorPanel;
