import React from 'react';

import {Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';

function CodeCell({data, rowIndex, ...props}) {
  let code = '';
  const row = data[rowIndex];
  if (row.type.endsWith('Import')) {
    code += 'IM';
  } else if (row.type.endsWith('Export')) {
    code += 'EX';
  }

  if (row.type.startsWith('Api')) {
    code += 'API';
  } else if (row.type.startsWith('File')) {
    switch (row.format) {
      case 'csv':
        code += 'CSV';
        break;
      case 'xls':
      case 'xlsx':
        code += 'XLS';
        break;
      default:
        break;
    }
  }
  return <Cell {...props}>{code}</Cell>;
}

CodeCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
};

CodeCell.defaultProps = {
  data: [],
};

export default CodeCell;
