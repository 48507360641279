import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {calcSymbols} from '../helpers';

import cssModule from './sms-template-modal-counter.module.scss';

const b = bem('sms-template-modal-counter', {cssModule});

type SmsTemplateModalCounterProps = {
  className?: string;
  text?: string;
  hasVariable?: boolean;
};

const SmsTemplateModalCounter: React.FC<SmsTemplateModalCounterProps> = ({className, text = '', hasVariable}) => {
  const lang = useLanguage('SMS_TEMPLATES.MODALS.SMS_TEMPLATE_MODAL.COUNTER');

  const state = calcSymbols(text);

  return (
    <div className={cn(b(), className)}>
      {hasVariable ? (
        <span>{lang.HAS_VARIABLE_CONTENT}</span>
      ) : (
        <>
          <span>{`${state.remaining} ${lang.REMAINING}`}</span>
          <span>{`${text.length} ${lang.CHARACTERS} | ${state.smsCount} SMS`}</span>
        </>
      )}
    </div>
  );
};

export default SmsTemplateModalCounter;
