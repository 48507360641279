import React, {useCallback, useMemo} from 'react';

import cn from 'classnames';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectAvailableParticipationColumns} from 'client/ducks/column-adapters/selectors';

import {CheckboxInput} from 'client/common/inputs';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Rollout from 'client/components/common/rollout';

import {getColumnName} from 'client/components/participants/components/modals/participants-table-settings-modal/helpers';
import {ParticipationColumn, ParticipationColumnAdapter} from 'client/models/participations/types';

import {filterColumnGroups} from './helpers';

import cssModule from './participants-table-settings-modal-all-list.module.scss';

type ParticipantsTableSettingsModalAllListProps = {
  className?: string;
  selectedColumns: ParticipationColumn[];

  onCheckAdapters: (checkedAdapters: ParticipationColumnAdapter[], state: boolean) => void;
  checkedAdapters: ParticipationColumnAdapter[];
};

const b = bem('participants-table-settings-modal-all-list', {cssModule});

const ParticipantsTableSettingsModalAllList: React.FC<ParticipantsTableSettingsModalAllListProps> = ({
  className,
  selectedColumns,
  onCheckAdapters,
  checkedAdapters,
}) => {
  const lang = useLanguage('PARTICIPANTS_LIST');
  const columnAdapters: Record<string, ParticipationColumnAdapter[]> = useSelector(selectAvailableParticipationColumns);

  const filteredAdapters = useMemo(() => {
    return filterColumnGroups(columnAdapters, selectedColumns);
  }, [columnAdapters, selectedColumns]);

  const onCheckAll = () => {
    onCheckAdapters(filteredAdapters.adapters, checkedAdapters.length !== filteredAdapters.adapters.length);
  };

  const isEmpty = !Object.values(filteredAdapters).length;

  const isCheckedAdapter = useCallback(
    (adapter: ParticipationColumnAdapter) => !!checkedAdapters.find(({id}) => id === adapter.id),
    [checkedAdapters],
  );

  const isCheckedGroup = useCallback(
    (adapters: ParticipationColumnAdapter[]) =>
      adapters.every(({id}) => checkedAdapters.some((checkedAdapter) => id === checkedAdapter.id)),
    [checkedAdapters],
  );

  return (
    <div className={cn(b(), className)}>
      {!isEmpty && (
        <div className="content-group__header">
          <CheckboxInput
            color="games"
            classNames={{
              label: b('all-title'),
            }}
            name="all"
            label={lang.TABLE_SETTINGS_MODAL_ALL_COLUMNS_GROUP}
            checked={checkedAdapters.length === filteredAdapters.adapters.length}
            onCheck={onCheckAll}
          />
        </div>
      )}
      {Object.entries(filteredAdapters.grouped).map(([groupName, adapters]) => {
        const checked = isCheckedGroup(adapters);
        return (
          <Rollout
            key={groupName}
            title={
              <CheckboxInput
                color="games"
                classNames={{
                  label: b('group-title'),
                }}
                name={groupName}
                label={groupName}
                checked={checked}
                onCheck={() => {
                  onCheckAdapters(adapters, !checked);
                }}
              />
            }
            className="rollout--expand-1"
          >
            <CustomScrollbars
              scrollbarProps={{
                hideTracksWhenNotNeeded: true,
                autoHeightMax: 191,
                autoHeightMin: 191,
              }}
            >
              <div className="list list--view-3">
                <div className="list__group">
                  {adapters.map((adapter) => {
                    const checkedAdapter = isCheckedAdapter(adapter);

                    return (
                      <div className="list__item" key={adapter.id}>
                        <CheckboxInput
                          color="games"
                          name={adapter.name}
                          classNames={{
                            label: b('adapter-title'),
                          }}
                          label={getColumnName(adapter.record_type, adapter.name)}
                          checked={checkedAdapter}
                          onCheck={() => {
                            onCheckAdapters([adapter], !checkedAdapter);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </CustomScrollbars>
          </Rollout>
        );
      })}
    </div>
  );
};

export default ParticipantsTableSettingsModalAllList;
