import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import PrizeDiyInstantWinFieldset from 'client/components/prizes/fieldsets/prize-diy-instant-win-fieldset';

import './prize-diy-modal-instant-win.scss';

const b = bem('prize-diy-modal-instant-win');

const PrizeDiyModalInstantWin = (props) => {
  const {disabled} = props;

  return (
    <div className={b()}>
      <PrizeDiyInstantWinFieldset disabled={disabled} />
    </div>
  );
};

PrizeDiyModalInstantWin.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default PrizeDiyModalInstantWin;
