import React, {Component} from 'react';

import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, formValueSelector, getFormValues, reduxForm} from 'redux-form';

import {updateMembershipsFromForm} from 'client/services/membershipHelpers';
import {date, dateIsAfter, required} from 'client/services/validator';

import {PARTICIPANTS_COLUMN_MAP} from 'client/ducks/participants-list/constants';
import {updateParticipationsAPIExportReports} from 'client/ducks/participation-export/actions';

import {LEAD_IMPORT_EXPORT_FILE_TYPES, PARTICIPATION_EXPORT_TYPES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import AllowedUsersField, {userToOption} from 'client/components/common/custom-fields/allowed-users-field';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';
import Rollout from 'client/components/common/rollout';

const sortColumns = (data) => {
  if (Object.keys(data).length) {
    const columns = {
      ...data,
      Participation: sortBy(data.Participation, [
        function (item) {
          return item.name.toLowerCase();
        },
      ]),
      ScenarioExecution: sortBy(data.ScenarioExecution, [
        function (item) {
          return item.name.toLowerCase();
        },
      ]),
    };
    return columns;
  }
  return data;
};

class ParticipantsChangeApiExportModal extends Component {
  static defaultProps = {
    show: false,
    columnsGroup: {},
    columns: [],
    users: [],
    formValues: {},
  };

  static propTypes = {
    APIRecord: PropTypes.object,
    show: PropTypes.bool,
    pristine: PropTypes.bool,
    languageState: PropTypes.object.isRequired,
    columnsGroup: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    updateParticipationsAPIExportReports: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    clientId: PropTypes.number.isRequired,
    users: PropTypes.array,
    generalParticipationColumns: PropTypes.object.isRequired,
    taskId: PropTypes.number.isRequired,
    updateCallBack: PropTypes.func,
    APIReportChangeid: PropTypes.number,
    getAvailableColumnAdaptersByAutomationTaskForExport: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    formValues: PropTypes.object,
  };

  static formName = 'LeadsGenerateApiExportModalForm';

  static checkboxAllColumns = 'checkboxAllColumns';

  static columnsGroup = 'columnsGroup';

  constructor(props) {
    super(props);
    this.LANGUAGE = props.languageState.payload.PARTICIPANTS_PORT.GENERATE_EXPORT_FILE_MODAL;

    this.rules = {
      dateFrom: [
        date(this.LANGUAGE.ERRORS.INVALID_DATE_FORMAT),
        required(this.LANGUAGE.REQUIRED),
        (value) => {
          if (this.props.pristine) {
            return null;
          }
          return moment.utc(value, 'DD-MM-YYYY').isSameOrAfter(moment.utc(), 'day')
            ? null
            : this.LANGUAGE.ERRORS.INVALID_DATE_START;
        },
      ],
      dateTo: [
        date(this.LANGUAGE.ERRORS.INVALID_DATE_FORMAT),
        required(this.LANGUAGE.REQUIRED),
        (value, {dateFrom}) => {
          return value && dateFrom && !date('.')(dateFrom)
            ? dateIsAfter(this.LANGUAGE.ERRORS.INVALID_DATE_BORDERS, 'dateFrom')(value, {dateFrom})
            : null;
        },
      ],
    };

    this.state = {
      showWarning: true,
      isColumnsFieldsTouched: false,
      generalParticipationColumns: {},
      initialGeneralParticipationColumns: [],
      allColumnsChecked: false,
      isInited: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {formValues, generalParticipationColumns, APIRecord, change} = this.props;
    const {generalParticipationColumns: prevGeneralParticipationColumns} = prevProps;

    if (this.detectChange(generalParticipationColumns, prevGeneralParticipationColumns)) {
      this.setState(
        {
          generalParticipationColumns: sortColumns(generalParticipationColumns),
        },
        () => {
          if (APIRecord && APIRecord.participation_export_items) {
            this.initColumns(APIRecord.participation_export_items);
          }
          this.validateFormSection();
        },
      );
    }

    if (APIRecord && APIRecord.id !== prevProps.APIRecord.id) {
      const memberships = APIRecord.memberships || [];
      const users = memberships.map((membership) => userToOption(membership.client_user));
      change('users', users);
    }

    if (formValues.allowed_for_all === 'true' && !isEmpty(formValues.users)) {
      change('users', []);
    }
  }

  reinitalizeForm = () => {
    const {APIRecord} = this.props;
    const initValues = {
      dateFrom: APIRecord ? moment(APIRecord.from).format('DD/MM/YYYY') : '',
      dateTo: APIRecord ? moment(APIRecord.to).format('DD/MM/YYYY') : '',
      user: APIRecord ? APIRecord.membership_id : null,
      columnsGroup: {},
      format: LEAD_IMPORT_EXPORT_FILE_TYPES.XLS,
    };
    this.props.initialize(initValues);
  };

  initColumns = (items) => {
    const mappedGeneralParticipationColumns = this.state.generalParticipationColumns;
    Object.keys(mappedGeneralParticipationColumns).forEach((groupName) => {
      mappedGeneralParticipationColumns[groupName].forEach((item) => {
        if (typeof item === 'object') {
          const findRes = find(items, ['column_adapter_id', item.id]);
          const checked = !!findRes;
          if (checked) {
            item.itemID = findRes.id;
          }
          item.checked = checked;
        }
      });
    });
    Object.keys(mappedGeneralParticipationColumns).forEach((groupName) => {
      mappedGeneralParticipationColumns[groupName].checked = !find(mappedGeneralParticipationColumns[groupName], [
        'checked',
        false,
      ]);
    });
    const allColumnsChecked = !find(Object.values(mappedGeneralParticipationColumns), ['checked', false]);
    const initialPositions = [].concat(...Object.values(mappedGeneralParticipationColumns)).map((column) => {
      return {
        id: column.id,
        checked: column.checked,
        itemID: column.itemID,
      };
    });
    this.setState({
      allColumnsChecked: allColumnsChecked,
      generalParticipationColumns: mappedGeneralParticipationColumns,
      initialGeneralParticipationColumns: initialPositions,
      isColumnsFieldsTouched: false,
      isInited: true,
    });
  };

  detectChange = (obj1, obj2) => {
    return !isEqual(obj1, obj2);
  };

  validateFormSection = () => {
    const validate = Boolean(this.getCheckedColumns().length);
    this.setState({showWarning: !validate});
  };

  getCheckedColumns = () => {
    return [].concat(...Object.values(this.state.generalParticipationColumns)).filter((column) => {
      return typeof column === 'object' && column.checked;
    });
  };

  onSave = (values) => {
    const {APIRecord} = this.props;

    if (this.state.showWarning) {
      return this.setState({isColumnsFieldsTouched: true});
    }

    const exportData = {
      api_participation_export: {
        name: `${this.LANGUAGE.API_EXPORT} ${moment(new Date().toISOString()).format('DD/MM/YYYY')}`,
        comment: values.comment || '',
        automation_task_id: this.props.taskId,
        from: moment.utc(values.dateFrom, 'DD/MM/YYYY').toISOString(),
        to: moment.utc(values.dateTo, 'DD/MM/YYYY').toISOString(),
        allowed_for_all: values.allowed_for_all === 'true',
        transferable_memberships: updateMembershipsFromForm(APIRecord.memberships, values.users),
      },
    };
    if (this.state.isColumnsFieldsTouched) {
      exportData.api_participation_export.participation_export_items = this.getDiffItemsForUpdate();
    }
    return this.props.updateParticipationsAPIExportReports(this.props.APIReportChangeid, exportData).then(async () => {
      if (this.props.updateCallBack) {
        this.props.updateCallBack();
      }
      await this.props.getAvailableColumnAdaptersByAutomationTaskForExport(this.props.taskId);
      this.props.onClose();
    });
  };

  getDiffItemsForUpdate = () => {
    const initialColumns = this.state.initialGeneralParticipationColumns;
    const delInitial = initialColumns
      .filter((item) => typeof item === 'object' && item.checked)
      .map((item) => {
        return {
          id: item.itemID,
          type: PARTICIPATION_EXPORT_TYPES.PARTICIPATION_EXPORT_ITEM,
          column_adapter_id: item.id,
          _destroy: true,
        };
      });
    const addNew = []
      .concat(...Object.values(this.state.generalParticipationColumns))
      .filter((column) => {
        return typeof column === 'object' && column.checked;
      })
      .map((item) => {
        return {
          type: PARTICIPATION_EXPORT_TYPES.PARTICIPATION_EXPORT_ITEM,
          column_adapter_id: item.id,
        };
      });
    return [].concat(delInitial, addNew);
  };

  toggleAllColumns = () => {
    const mappedGeneralParticipationColumns = this.state.generalParticipationColumns;
    const allColumnsChecked = this.state.allColumnsChecked;
    Object.keys(mappedGeneralParticipationColumns).forEach((groupName) => {
      mappedGeneralParticipationColumns[groupName].checked = !allColumnsChecked;
      mappedGeneralParticipationColumns[groupName].forEach((item) => {
        if (typeof item === 'object') {
          item.checked = !allColumnsChecked;
        }
      });
    });
    this.setState({
      allColumnsChecked: !allColumnsChecked,
      generalParticipationColumns: mappedGeneralParticipationColumns,
      isColumnsFieldsTouched: true,
    });
    this.validateFormSection();
  };

  handleColumnCheck = (columnGroupName, item, index) => {
    const mappedGeneralParticipationColumns = this.state.generalParticipationColumns;
    mappedGeneralParticipationColumns[columnGroupName][index].checked =
      !mappedGeneralParticipationColumns[columnGroupName][index].checked;
    this.setState({
      generalParticipationColumns: mappedGeneralParticipationColumns,
      isColumnsFieldsTouched: true,
    });
    this.validateFormSection();
  };

  toggleGroupColumns = (columnGroupName) => {
    const mappedGeneralParticipationColumns = this.state.generalParticipationColumns;
    const isGroupChecked = !!mappedGeneralParticipationColumns[columnGroupName].checked;
    mappedGeneralParticipationColumns[columnGroupName].forEach((item) => {
      if (typeof item === 'object') {
        item.checked = !isGroupChecked;
      }
    });
    mappedGeneralParticipationColumns[columnGroupName].checked = !isGroupChecked;
    this.setState({
      generalParticipationColumns: mappedGeneralParticipationColumns,
      isColumnsFieldsTouched: true,
    });
    this.validateFormSection();
  };

  getColumnName = ({record_type, name}) => {
    const columnName = `${record_type} ${name}`;
    return PARTICIPANTS_COLUMN_MAP[columnName] || columnName;
  };

  renderAvailableColumns = () => {
    return Object.entries(this.state.generalParticipationColumns).map((entry, idx) => {
      const columnGroupName = entry[0];
      const columnGroupItems = entry[1];
      return columnGroupName === 'checked' ? null : (
        <Rollout
          key={idx}
          title={
            <label className="checkbox-button theme-color-9">
              <input
                className="checkbox-button__input"
                type="checkbox"
                name="checkbox-general"
                checked={!!columnGroupItems.checked}
                onChange={() => {
                  this.toggleGroupColumns(columnGroupName);
                }}
              />
              <div className="checkbox-button__label">
                <span className="icon-checked checkbox-button__icon" />
                <span className="checkbox-button__text">
                  <span className="main-text main-text--bold">{columnGroupName}</span>
                </span>
              </div>
            </label>
          }
          bodyStyles={{
            marginRight: '13px',
          }}
          className="rollout--expand-1"
          onTransition={this.handleTransition}
          isOpened={false}
        >
          <CustomScrollbars
            scrollbarProps={{
              hideTracksWhenNotNeeded: true,
              autoHeightMax: 191,
              autoHeightMin: 191,
            }}
          >
            <div className="list list--view-3">
              <div className="list__group">
                {columnGroupItems.map((item, index) => (
                  <div className={`list__item ${item.hidden ? 'hidden' : ''}`} key={index}>
                    <label className="checkbox-button checkbox-button--view-1 checkbox-button">
                      <input
                        type="checkbox"
                        data-name={item.name}
                        className="checkbox-button__input"
                        checked={!!item.checked}
                        onChange={() => {
                          this.handleColumnCheck(columnGroupName, item, index);
                        }}
                      />
                      <div className="checkbox-button__label">
                        <span className="icon-checked checkbox-button__icon" />
                        <span className="checkbox-button__text">{this.getColumnName(item)}</span>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </CustomScrollbars>
        </Rollout>
      );
    });
  };

  render() {
    const {handleSubmit, users, show, onClose, onCancel, formValues} = this.props;
    return (
      <Modal
        show={show}
        onClose={onClose}
        title={this.LANGUAGE.TITLE_API}
        dialogClassName="modal-window--width-1 theme-color-9 leads-port-layout"
      >
        <form onSubmit={handleSubmit(this.onSave)}>
          <div className="theme-color-9">
            <div className="main-text main-text--small main-text--color-1">{this.LANGUAGE.SET_COLUMNS + '*'}</div>
            <div className="content-group">
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMin: 200,
                  autoHeightMax: 400,
                }}
              >
                <div style={{width: 337}}>
                  <div className="content-group__header">
                    <label className="checkbox-button">
                      <input
                        className="checkbox-button__input"
                        type="checkbox"
                        name="checkbox-all"
                        value="0"
                        checked={this.state.allColumnsChecked}
                        onChange={this.toggleAllColumns}
                      />
                      <div className="checkbox-button__label">
                        <span className="icon-checked checkbox-button__icon" />
                        <span className="checkbox-button__text">
                          <span className="main-text main-text--bold">{this.LANGUAGE.ALL_COLUMNS}</span>
                        </span>
                      </div>
                    </label>
                  </div>
                  {this.renderAvailableColumns()}
                </div>
              </CustomScrollbars>
            </div>
            {this.state.showWarning && this.state.isColumnsFieldsTouched && (
              <div className="message-error-text">{this.LANGUAGE.NO_MATCH_ERROR_MESSAGE}</div>
            )}
            <div className="leads-port-layout__row form-field-wrap">
              <div className="leads-port-layout__row-title">{this.LANGUAGE.PERIOD_LABEL + '*'}</div>
              <div className="flex-container">
                <div className="flex-inline-container">
                  <Field
                    cssModifier="leads-port-layout__datepicker leads-port-layout__datepicker--full-width"
                    label={this.LANGUAGE.FROM_LABEL}
                    name="dateFrom"
                    type="datepicker"
                    validate={this.rules.dateFrom}
                    component={fieldTemplate}
                  />
                  <Field
                    cssModifier="leads-port-layout__datepicker leads-port-layout__datepicker--full-width"
                    label={this.LANGUAGE.TO_LABEL}
                    name="dateTo"
                    type="datepicker"
                    validate={this.rules.dateTo}
                    component={fieldTemplate}
                  />
                </div>
              </div>
            </div>
            <div className="leads-port-layout__row">
              <div className="leads-port-layout__row-title">{this.LANGUAGE.ALLOWED_USERS_LABEL}</div>
              <div className="flex-container margin-bottom--5">
                <div className="leads-port-layout__col-1">
                  <Field
                    label={this.LANGUAGE.ALLOW_ALL_LABEL}
                    name="allowed_for_all"
                    value="true"
                    type="radio"
                    component={fieldTemplate}
                  />
                </div>
                <div className="leads-port-layout__col-1">
                  <Field
                    label={this.LANGUAGE.ALLOW_SELECTED_LABEL}
                    name="allowed_for_all"
                    value="false"
                    type="radio"
                    component={fieldTemplate}
                  />
                </div>
              </div>
              <AllowedUsersField
                label={this.LANGUAGE.USER_ALLOWED_LABEL}
                users={users}
                disabled={formValues.allowed_for_all === 'true'}
              />
            </div>
            <Field
              cssModifier="leads-port-layout__textarea"
              name="comment"
              type="textarea"
              label={this.LANGUAGE.COMMENT_LABEL}
              component={fieldTemplate}
            />
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button
              className="button button--bg-11 modal-window__footer-btn"
              type="button"
              onClick={onCancel || onClose}
            >
              {this.LANGUAGE.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-4 modal-window__footer-btn" type="submit">
              {this.LANGUAGE.SAVE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const selector = formValueSelector(ParticipantsChangeApiExportModal.formName);

const ParticipantsChangeApiExportModalForm = reduxForm({
  form: ParticipantsChangeApiExportModal.formName,
  enableReinitialize: true,
  // keepDirtyOnReinitialize: true
})(ParticipantsChangeApiExportModal);

export default connect(
  (state, ownProps) => {
    const {APIRecord} = ownProps;

    return {
      initialValues: {
        dateFrom: APIRecord ? moment(APIRecord.from).format('DD/MM/YYYY') : '',
        dateTo: APIRecord ? moment(APIRecord.to).format('DD/MM/YYYY') : '',
        columnsGroup: selector(state, ParticipantsChangeApiExportModal.columnsGroup),
        format: LEAD_IMPORT_EXPORT_FILE_TYPES.XLS,
        comment: APIRecord ? APIRecord.comment : '',
        users: [],
        allowed_for_all: APIRecord && !APIRecord.allowed_for_all ? 'false' : 'true',
      },
      languageState: state.languageState,
      columnsGroup: selector(state, ParticipantsChangeApiExportModal.columnsGroup),
      formValues: getFormValues(ParticipantsChangeApiExportModalForm.formName)(state),
    };
  },
  {
    updateParticipationsAPIExportReports,
  },
)(ParticipantsChangeApiExportModalForm);
