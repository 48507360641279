import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {clearError as clearErrorAction} from 'client/ducks/errors/actions';

import ConfirmationModal from 'client/common/modals/confirmation-modal';

import {TranslationJsx} from 'client/models/language/types';

function ErrorModal({message, clearError, languageState}) {
  const LANGUAGE = languageState.payload.ERROR_MODAL;

  return (
    <ConfirmationModal
      className="theme-color-11"
      show={!!message}
      title={LANGUAGE.TITLE}
      message={<span className="theme-color-11">{message}</span>}
      onClose={clearError}
      buttonConfirmColor="primary"
      buttonCancelColor="primary"
    />
  );
}

ErrorModal.propTypes = {
  clearError: PropTypes.func.isRequired,
  message: TranslationJsx.isRequired,
  languageState: PropTypes.object.isRequired,
};

export default connect(
  ({errors, languageState}) => ({
    languageState,
    message: errors.errorMessage,
  }),
  (dispatch) => ({
    clearError: bindActionCreators(clearErrorAction, dispatch),
  }),
)(ErrorModal);
