import {
  INSTANT_WIN_TYPE,
  SCHEDULE_INSTANT_WIN_TYPE,
} from 'client/components/games/game-config-modal/prize-form/constants';

export const geographicOptions = (lang) => [
  {
    label: lang.GEOGRAPHIC_NONE_LABEL,
    value: 'none',
  },
  {
    label: lang.GEOGRAPHIC_GLOBAL_LABEL,
    value: 'global',
  },
  {
    label: lang.GEOGRAPHIC_PLAN_LABEL,
    value: 'plan',
  },
  {
    label: lang.GEOGRAPHIC_PLACE_LABEL,
    value: 'place',
  },
  {
    label: lang.GEOGRAPHIC_DEVICE_LABEL,
    value: 'device',
  },
];

export const onlineOptions = (lang) => [
  {
    label: lang.ONLINE_NONE_LABEL,
    value: 'none',
  },
  {
    label: lang.ONLINE_GLOBAL_LABEL,
    value: 'global',
  },
  {
    label: lang.ONLINE_CAMPAIGN_LABEL,
    value: 'campaign',
  },
  {
    label: lang.ONLINE_SOURCE_LABEL,
    value: 'source',
  },
];

export const getRadioInstantWinType = (lang) => [
  {
    label: lang.TIME_INSTANT_WIN,
    value: 'time_instant_win',
  },
  {
    label: lang.PARTICIPATION_INSTANT_WIN,
    value: 'participation_instant_win',
  },
  {
    label: lang.SCHEDULE_INSTANT_WIN,
    value: 'schedule_instant_win',
  },
];

export const getTimeUnitOptions = (lang) =>
  [
    {
      label: lang.SECONDS_LABEL,
      value: 'seconds',
    },
    {
      label: lang.MINUTES_LABEL,
      value: 'minutes',
    },
    {
      label: lang.HOURS_LABEL,
      value: 'hours',
    },
    {
      label: lang.DAYS_LABEL,
      value: 'days',
    },
  ].filter(Boolean);

export const getScheduleModeOptions = (lang) => [
  {
    label: lang.MANUAL_LABEL,
    value: 'manual',
  },
  {
    label: lang.FIX_WEEK_LABEL,
    value: 'fix_week',
  },
  {
    label: lang.CALCULATED_LABEL,
    value: 'calculated_rule',
  },
];

export const getWeekdays = (lang) => [
  {
    label: lang.MONDAY,
    value: 'monday',
  },
  {
    label: lang.TUESDAY,
    value: 'tuesday',
  },
  {
    label: lang.WEDNESDAY,
    value: 'wednesday',
  },
  {
    label: lang.THURSDAY,
    value: 'thursday',
  },
  {
    label: lang.FRIDAY,
    value: 'friday',
  },
  {
    label: lang.SATURDAY,
    value: 'saturday',
  },
  {
    label: lang.SUNDAY,
    value: 'sunday',
  },
];

const addTitleScheduleInstantWin = (init = '', type, lang) => {
  const result = [init];

  switch (type) {
    case SCHEDULE_INSTANT_WIN_TYPE.MANUAL:
      result.push(lang.MANUAL_LABEL);
      break;
    case SCHEDULE_INSTANT_WIN_TYPE.FIX_WEEK:
      result.push(lang.FIX_WEEK_LABEL);
      break;
    case SCHEDULE_INSTANT_WIN_TYPE.CALCULATED:
      result.push(lang.CALCULATED_LABEL);
      break;
  }

  return result.join(' – ');
};

export const addTitleInstantWin = (init = '', values, lang) => {
  const result = [init];
  const instantWinConfig = Object.entries(values).find(
    ([key, value]) => value && key.endsWith('_configuration') && key !== 'instant_win_configuration',
  );
  const instant_win_type = instantWinConfig ? instantWinConfig[0].replace('_configuration', '') : null;
  if (!values.prize?.full_win) {
    switch (instant_win_type) {
      case INSTANT_WIN_TYPE.TIME:
        result.push(lang.TIME_INSTANT_WIN);
        break;
      case INSTANT_WIN_TYPE.PARTICIPATION:
        result.push(lang.PARTICIPATION_INSTANT_WIN);
        break;
      case INSTANT_WIN_TYPE.SCHEDULE:
        result.push(addTitleScheduleInstantWin(lang.SCHEDULE_INSTANT_WIN, instantWinConfig[1].schedule_type, lang));
        break;
    }
  }

  return result.join(' – ');
};
