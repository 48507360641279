import React from 'react';

import PropTypes from 'prop-types';

const OptinsCell = (props) => {
  const {value} = props;

  return (
    <div>
      {value
        .filter(({isHidden}) => !isHidden)
        .map(({name}, index) => (
          <div key={index}>{name}</div>
        ))}
    </div>
  );
};

OptinsCell.propTypes = {
  value: PropTypes.array,
};

OptinsCell.defaultProps = {
  value: [],
};

export default OptinsCell;
