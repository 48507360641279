import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reset} from 'redux-form';

import {getBroadcasts, changeCurrentBroadcast} from 'client/ducks/broadcast/actions';
import {selectActiveBroadcasts} from 'client/ducks/broadcast/selectors';

import Hashtag from 'client/components/common/hashtag';

import BCSelectDropdown from '../dropdowns/bc-select-dropdown';
import BCMailingListForm from '../forms/bc-mailing-list-form';
import BCNewBroadcastListModal from '../modals/bc-new-broadcast-list-modal';

class BroadcastSelect extends Component {
  static propTypes = {
    className: PropTypes.string,
    selectedBroadcastsName: PropTypes.string,
    onClearBroadcastList: PropTypes.func.isRequired,
    onSelectBroadcast: PropTypes.func.isRequired,
    clientId: PropTypes.string.isRequired,
    broadcastsList: PropTypes.array.isRequired,
    resetForm: PropTypes.func.isRequired,
    getBroadcasts: PropTypes.func.isRequired,
    changeCurrentBroadcast: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    taskType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    className: '',
    selectedBroadcastsName: '',
  };

  static SELECT_FORM_NAME = 'selectBroadcastListForm';
  static CREATE_FORM_NAME = 'createBroadcastListForm';
  static FROM_SCRATCH_ID = 0;

  static MODALS_MAP = {
    NEW_BROADCAST_LIST: 'NEW_BROADCAST_LIST',
  };

  constructor(props) {
    super(props);

    this.state = {
      openedModal: '',
      existedBroadcastId: '',
    };

    this.LANGUAGE = props.languageState.payload.BROADCASTING_TASK.EMAIL_STEPS;
  }

  componentDidMount() {
    this.getBroadcasts();
  }

  getBroadcasts = () => {
    const {clientId, taskType} = this.props;

    const params = {
      q: {
        client_id_eq: clientId,
        message_tasks_type_eq: taskType,
      },
      distinct: null,
    };

    return this.props.getBroadcasts(params);
  };

  handleClearForm = (name) => this.props.resetForm(name);

  handleClearBroadcastList = () => {
    this.handleClearForm(BroadcastSelect.SELECT_FORM_NAME);
    this.handleClearForm(BroadcastSelect.CREATE_FORM_NAME);
    this.props.onClearBroadcastList();
  };

  handleCreateBroadcast = (existedBroadcastId) => {
    this.setState({
      existedBroadcastId,
      openedModal: BroadcastSelect.MODALS_MAP.NEW_BROADCAST_LIST,
    });
  };

  handleConfirmCreateBroadcast = (data) => {
    this.props.onSelectBroadcast(data.id);
    this.handleCloseModal();
  };

  handleSelectBroadcast = (id) => {
    this.props.changeCurrentBroadcast(id);
    this.props.onSelectBroadcast(id);
  };

  handleOpenModal = (name) => {
    this.setState({
      openedModal: name,
    });
  };

  handleCloseModal = () => {
    this.setState({
      openedModal: '',
    });
  };

  extendsBroadcastsList = (broadcastsList) => {
    return [
      {
        id: BroadcastSelect.FROM_SCRATCH_ID,
        name: this.LANGUAGE.SELECT_DROPDOWN.FROM_SCRATCH_LABEL,
      },
      ...broadcastsList,
    ];
  };

  render() {
    const {clientId, broadcastsList, className, selectedBroadcastsName, disabled} = this.props;

    const {openedModal, existedBroadcastId} = this.state;

    const showDropdowns = !selectedBroadcastsName;
    const extendedBroadcastsList = this.extendsBroadcastsList(broadcastsList);

    return (
      <div className={className}>
        <BCNewBroadcastListModal
          clientId={clientId}
          broadcastId={existedBroadcastId}
          onClose={this.handleCloseModal}
          onConfirm={this.handleConfirmCreateBroadcast}
          show={openedModal === BroadcastSelect.MODALS_MAP.NEW_BROADCAST_LIST}
        />
        {!showDropdowns && (
          <Hashtag
            deletable={!disabled}
            className="hashtag--view-1 broadcasting__hashtag"
            onDelete={this.handleClearBroadcastList}
          >
            {selectedBroadcastsName}
          </Hashtag>
        )}
        {showDropdowns && [
          <BCSelectDropdown
            customDropdown={this.selectList}
            key={BroadcastSelect.SELECT_FORM_NAME}
            className="broadcasting__select-dropdown"
            value={<div className="text-center">{this.LANGUAGE.SELECT_DROPDOWN.CONFIG_VALUE}</div>}
          >
            <BCMailingListForm
              getListRef={(list) => {
                this.selectList = list;
              }}
              formName={BroadcastSelect.SELECT_FORM_NAME}
              data={broadcastsList}
              onConfirm={this.handleSelectBroadcast}
              onCancel={() => this.handleClearForm(BroadcastSelect.SELECT_FORM_NAME)}
              cancelButtonText={this.LANGUAGE.SELECT_DROPDOWN.CANCEL_BUTTON}
              confirmButtonText={this.LANGUAGE.SELECT_DROPDOWN.CONFIRM_BUTTON}
            />
          </BCSelectDropdown>,
          <BCSelectDropdown
            customDropdown={this.createList}
            key={BroadcastSelect.CREATE_FORM_NAME}
            className="broadcasting__select-dropdown"
            value={<div className="text-center">{this.LANGUAGE.SELECT_DROPDOWN.CREATE_VALUE}</div>}
          >
            <BCMailingListForm
              getListRef={(list) => {
                this.createList = list;
              }}
              formName={BroadcastSelect.CREATE_FORM_NAME}
              data={extendedBroadcastsList}
              onConfirm={this.handleCreateBroadcast}
              onCancel={() => this.handleClearForm(BroadcastSelect.CREATE_FORM_NAME)}
              cancelButtonText={this.LANGUAGE.SELECT_DROPDOWN.CANCEL_BUTTON}
              confirmButtonText={this.LANGUAGE.SELECT_DROPDOWN.CONFIRM_BUTTON}
            />
          </BCSelectDropdown>,
        ]}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageState: state.languageState,
    broadcastsList: selectActiveBroadcasts(state),
  };
};

const mapDispatchToProps = {
  resetForm: reset,
  getBroadcasts,
  changeCurrentBroadcast,
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastSelect);
