import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {set} from 'client/common/meta/meta.actions';

import LeadsExport from 'client/components/leads/leads-export';

class LeadsExportPage extends ReactQueryParams {
  componentDidMount() {
    this.props.setMetaAction({
      title: this.props.languageState.payload.LEADS_PORT.TITLE_EXPORT,
    });
  }

  render() {
    const {queryParams: params} = this;
    return <LeadsExport urlParams={params} exportId={this.props.match.params.exportId} />;
  }
}

LeadsExportPage.propTypes = {
  setMetaAction: PropTypes.func.isRequired,
  languageState: PropTypes.object.isRequired,
};

export default connect(({languageState}) => ({languageState}), {
  setMetaAction: set,
})(LeadsExportPage);
