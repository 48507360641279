import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {useMedia} from 'react-use';

import bem from 'client/services/bem';

import {CheckboxField, PasswordField, TextField} from 'client/common/fields';
import Icon from 'client/common/icon';
import {PasswordInput, RadioGroupInput, TextInput} from 'client/common/inputs';
import {RadioGroupOptionsType} from 'client/common/inputs/radio-group-input';
import Popover from 'client/common/popovers/popover';

import {TranslationJsx} from 'client/models/language/types';

import './auth-field.scss';

const b = bem('auth-field');

function AuthField({type, helpOverlay, additional, oversize, login, email, ...props}) {
  const className = b({[type]: true, additional, oversize, login, email});
  const isMobile = useMedia('(max-width: 768px)');

  const content = useMemo(() => {
    switch (type) {
      case 'text':
        return <TextField {...props} className={className} />;
      case 'text-input':
        return <TextInput {...props} className={className} />;
      case 'password':
        return <PasswordField {...props} />;
      case 'password-input':
        return <PasswordInput {...props} />;
      case 'checkbox':
        return <CheckboxField {...props} color="primary" />;
      case 'radio-group-input':
        return <RadioGroupInput {...props} className={className} classNameOption={b('option')} />;
      default:
        return <></>;
    }
  }, [className, props, type]);

  return (
    <div className={type !== 'radio-group-input' ? className : ''}>
      {content}
      {helpOverlay && isMobile && <p className={b('help')}>{helpOverlay}</p>}
      {helpOverlay && !isMobile && (
        <Popover
          trigger="both"
          shiftLeft={10}
          position="right"
          className={b('popover', ['help'])}
          overlayInnerStyle={{
            width: 200,
          }}
          overlay={<span id="popover-positioned-scrolling-right">{helpOverlay}</span>}
        >
          <Icon name="info" width={28} height={28} />
        </Popover>
      )}
    </div>
  );
}

AuthField.propTypes = {
  type: PropTypes.oneOf(['password', 'text', 'text-input', 'checkbox', 'radio-group-input', 'password-input']),
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  label: TranslationJsx,
  placeholder: TranslationJsx,
  helpOverlay: PropTypes.oneOf([TranslationJsx, PropTypes.element]),
  options: RadioGroupOptionsType,
  value: PropTypes.string,
  onChange: PropTypes.func,
  additional: PropTypes.bool,
  oversize: PropTypes.bool,
  login: PropTypes.bool,
  email: PropTypes.bool,
  successMessage: TranslationJsx,
};

AuthField.defaultProps = {
  type: 'text',
  helpOverlay: null,
  tabIndex: null,
  errorMessage: '',
  placeholder: '',
  label: '',
  value: null,
  options: null,
  additional: false,
  oversize: false,
  login: false,
  disabled: false,
  email: false,
};

export default AuthField;
