import get from 'lodash/get';
import {createSelector} from 'reselect';

import {DEFAULT_PRIZE_NAMES} from 'client/common/config';

/**
 * @param {import('client/types/common/redux').MainStates} state
 * @returns {import('client/models/autotask/types').Autotask}
 */
export function selectAutotask(state) {
  return state.autotask.payload;
}

export function selectAutotasks(state) {
  return state.autotask.payload.autotasks;
}

export function selectAutotaskPrizeNames({autotask: {payload}}) {
  const prizeNames = get(payload, 'custom_prize_type_names', []).map((el) => {
    if (el.custom_name) {
      return el;
    }

    // In case of empty custom_name replace with default one
    return {
      ...el,
      custom_name: DEFAULT_PRIZE_NAMES.find(({db_number}) => db_number === el.prize_type_number)?.custom_name || '',
    };
  });
  const sorted = prizeNames.sort((a, b) => {
    return a.prize_type_number - b.prize_type_number;
  });

  if (!prizeNames.length) {
    return DEFAULT_PRIZE_NAMES;
  }
  if (sorted.length >= DEFAULT_PRIZE_NAMES.length) {
    return sorted;
  }

  // const additionalCols = DEFAULT_PRIZE_NAMES.length - sorted.length;
  const columns = [];
  for (let i = 0; i <= 2; i++) {
    const found = sorted.find((elem) => elem.prize_type_number === i + 1);
    columns.push(found || DEFAULT_PRIZE_NAMES[i]);
  }

  return columns;
}

export function selectScenariosForMappingItems(state) {
  return state.autotask.payload.scenarios.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
}

export function selectAutotasksForFilterModal(state) {
  const noMatterTextLabel = state.languageState.payload.LEADS_LIST.TABLE_FILTERS_MODAL_NO_MATTER_RADIO;

  return [
    {
      value: '0',
      label: noMatterTextLabel,
    },
    ...state.autotask.payload.autotasks.map(({id, name}) => ({value: id, label: name})),
  ];
}

export function selectScenariosNames(state) {
  return state.autotask.payload.scenarios.reduce((result, scenario) => [...result, scenario.name], []);
}

export function selectScenariosCodes(state) {
  return state.autotask.payload.scenarios.reduce((result, scenario) => [...result, scenario.code], []);
}

export function selectOperationClient({autotask}) {
  return get(autotask, 'payload.operation.client');
}

export const selectAutotaskParam = (param) => createSelector(selectAutotask, (autotaks) => autotaks?.[param]);

export const selectAutotaskTypes = (state) => {
  const autotask = selectAutotask(state);
  const interactionGroups = autotask?.interaction_groups || [];

  return {
    isDevicesType: interactionGroups.some((interaction) => interaction.type === 'Plan'),
    isOnlineType: interactionGroups.some((interaction) => interaction.type === 'Campaign'),
  };
};

export const selectAutotaskValidationLog = (state) => {
  const validationLog = [...state.autotask.validationLog];
  return validationLog.map((item) => ({...item, type: 'validation'}));
};

export const selectAutotaskPublicationWebAppLog = (state) => {
  const webAppLog = [...state.autotask.publicationWebAppLog];
  return webAppLog.map((item) => ({...item, type: 'web_app'}));
};

export const selectAutotaskPublicationDeviceLog = (state) => {
  const deviceLog = [...state.autotask.publicationDeviceLog];
  return deviceLog.map((item) => ({...item, type: 'device'}));
};
