import React, {Component} from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {post} from 'client/services/fetch';
import {required} from 'client/services/validator';

import {createEmailTemplate, patchEmailTemplate} from 'client/ducks/email-templates/actions';

import {API_METHODS} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

class AddTemplateModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    submitting: PropTypes.bool.isRequired,
    editingTemplate: PropTypes.object,
    languageState: PropTypes.object.isRequired,
    operationId: PropTypes.number.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    createEmailTemplate: PropTypes.func.isRequired,
    patchEmailTemplate: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    show: false,
    editingTemplate: {},
  };

  static formName = 'TplAddModalForm';

  static CORRECT_RESPONSE_STATUSES = [200, 204, 400];

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.TEMPLATES.ADD_MODAL;

    this.rules = {
      name: required(this.LANGUAGE.ERRORS.NAME_IS_REQUIRED),
      mailjet_id: required(this.LANGUAGE.ERRORS.TEMPLATE_ID_IS_REQUIRED),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.props.initialize(this.props.editingTemplate);
    }
  }

  createTemplate = (values) => {
    return post(API_METHODS.EMAIL_TEMPLATES, values).then(({error, ...data}) => {
      return {
        payload: {
          response: {
            error,
            ...data,
          },
        },
      };
    });
  };

  save = (values) => {
    const apiFunction = isEmpty(this.props.editingTemplate)
      ? this.createTemplate
      : (body) => this.props.patchEmailTemplate(this.props.editingTemplate.id, body);

    return apiFunction({...values, operation_id: this.props.operationId}).then(
      ({payload: {response: {errors, status} = {}}}) => {
        if (errors) {
          const submitErrors = {};

          submitErrors.name = errors.name && this.LANGUAGE.ERRORS.NAME_IS_TAKEN;
          submitErrors.mailjet_id = errors.mailjet_id && this.LANGUAGE.ERRORS.TEMPLATE_ID_IS_TAKEN;

          throw new SubmissionError(submitErrors);
        }

        if (status && !AddTemplateModal.CORRECT_RESPONSE_STATUSES.includes(status)) {
          throw new SubmissionError({mailjet_id: this.LANGUAGE.ERRORS.TEMPLATE_NOT_FOUND});
        }

        this.props.onConfirm();
      },
    );
  };

  render() {
    const {handleSubmit, onClose, show, editingTemplate, submitting} = this.props;

    return (
      <Modal
        show={show}
        onClose={onClose}
        dialogClassName="modal-window--width-1 theme-color-16"
        title={isEmpty(editingTemplate) ? this.LANGUAGE.TITLE : this.LANGUAGE.EDIT_TITLE}
      >
        <form onSubmit={handleSubmit(this.save)}>
          <div className="form-field-wrap">
            <Field
              label={this.LANGUAGE.NAME_LABEL}
              name="name"
              type="text"
              component={fieldTemplate}
              validate={this.rules.name}
            />
          </div>
          {isEmpty(editingTemplate) && (
            <div className="form-field-wrap">
              <Field
                cssModifier="input--no-min-height"
                label={this.LANGUAGE.ID_LABEL}
                name="mailjet_id"
                type="text"
                component={fieldTemplate}
                validate={this.rules.mailjet_id}
              />
            </div>
          )}
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onClose}>
              {this.LANGUAGE.CANCEL_BUTTON}
            </button>
            <button className="button button--bg-14 modal-window__footer-btn" type="submit" disabled={submitting}>
              {this.LANGUAGE.CONFIRM_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const form = reduxForm({
  form: AddTemplateModal.formName,
})(AddTemplateModal);

export default connect(
  ({languageState}) => ({
    languageState,
  }),
  {
    createEmailTemplate,
    patchEmailTemplate,
  },
)(form);
