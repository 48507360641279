import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getTokens(queryParams = {}) {
  const actionTypes = [types.GET_COUPON_TOKENS_REQUEST, types.GET_COUPON_TOKENS_SUCCESS, types.GET_COUPON_TOKENS_ERROR];

  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.COUPON_TOKENS}`,
      method: 'GET',
      types: actionTypes,
      noCache: true,
      queryParams,
    });
}
export function getCoupons(queryParams = {}) {
  const actionTypes = [types.GET_COUPON_REQUEST, types.GET_COUPON_SUCCESS, types.GET_COUPON_ERROR];

  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.COUPONS}`,
      method: 'GET',
      types: actionTypes,
      noCache: true,
      queryParams,
    });
}

export function setTestMode(couponId, state = false) {
  const actionTypes = [
    types.SET_COUPON_TEST_MODE_REQUEST,
    types.SET_COUPON_TEST_MODE_SUCCESS,
    types.SET_COUPON_TEST_MODE_ERROR,
  ];

  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.COUPONS}/${couponId}`,
      method: 'PATCH',
      types: actionTypes,
      noCache: true,
      body: {
        test_mode: state,
      },
    });
}
