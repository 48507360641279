import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getGames} from 'client/ducks/games/actions';
import {selectGames} from 'client/ducks/games/selectors';

import AutotaskCard from 'client/components/autotask/components/cards/autotask-card/autotask-card';

import AddGameModal from './add-game-modal';
import GamesTable from './games-table';

import GameConfigModal from '../game-config-modal';

import './games-card.scss';

class GamesCard extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    games: PropTypes.array.isRequired,
    getGames: PropTypes.func.isRequired,
    autotaskId: PropTypes.number.isRequired,
  };

  state = {
    winNumber: null,
    showAddGameModal: false,
    editingGame: null,
    showGameConfigModal: false,
  };

  componentDidMount() {
    this.fetchGames();
  }

  fetchGames = () => {
    return this.props.getGames({
      q: {
        automation_task_id_eq: this.props.autotaskId,
      },
      include: ['game_validation_levels', 'automation_task.operation.client', 'game_draws'],
    });
  };

  toggleAddGameModal = (winNumber) => {
    this.setState(({showAddGameModal}) => ({
      winNumber: winNumber,
      showAddGameModal: !showAddGameModal,
    }));
  };

  toggleGameConfigModal = (editingGame) => {
    this.setState(({showGameConfigModal}) => ({
      editingGame,
      showGameConfigModal: !showGameConfigModal,
    }));
  };

  render() {
    const {lang, games, autotaskId} = this.props;
    const {winNumber, showAddGameModal, editingGame, showGameConfigModal} = this.state;

    return (
      <AutotaskCard title={lang.TITLE} showPagination={false} className="games-card">
        {showAddGameModal && (
          <AddGameModal
            onClose={() => this.toggleAddGameModal(null)}
            winNumber={winNumber}
            autotaskId={autotaskId}
            fetchGames={this.fetchGames}
          />
        )}
        {showGameConfigModal && (
          <GameConfigModal
            onClose={() => this.toggleGameConfigModal(null)}
            fetchGames={this.fetchGames}
            game={editingGame}
          />
        )}
        <GamesTable games={games} onAddGame={this.toggleAddGameModal} onEditClick={this.toggleGameConfigModal} />
      </AutotaskCard>
    );
  }
}

const mapStateToProps = (state) => ({
  games: selectGames(state),
  lang: state.languageState.payload.GAMES.GAMES_CARD.GAMES_TABLE,
});

const mapDispatchToProps = {
  getGames,
};

export default connect(mapStateToProps, mapDispatchToProps)(GamesCard);
