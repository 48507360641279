import React from 'react';

import {Cell} from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {CLIENT_PAGES} from 'client/common/config';

function NbOfParticipantsCell(props) {
  const {data, rowIndex, field, clientId, operationId, taskId} = props;
  const count = data[rowIndex][field].total;

  if (!operationId || !taskId) {
    return <Cell>{count}</Cell>;
  }

  const url =
    CLIENT_PAGES.COMPANIES +
    '/' +
    String(clientId) +
    CLIENT_PAGES.OPERATIONS +
    '/' +
    String(operationId) +
    CLIENT_PAGES.AUTOTASK +
    '/' +
    String(taskId) +
    CLIENT_PAGES.PARTICIPANTS_EXPORT +
    '/' +
    String(data[rowIndex][field].reportId);

  return (
    <Cell>
      <Link to={url}>
        <span className="link">{count}</span>
      </Link>
    </Cell>
  );
}

NbOfParticipantsCell.defaultProps = {
  data: [],
};

NbOfParticipantsCell.propTypes = {
  data: PropTypes.array,
  rowIndex: PropTypes.number,
  field: PropTypes.string,
  clientId: PropTypes.number.isRequired,
  operationId: PropTypes.number,
  taskId: PropTypes.number,
};

export default NbOfParticipantsCell;
