import React from 'react';

import PropTypes from 'prop-types';

import CustomLink from 'client/components/common/custom-link';

function ClientsColumn(props) {
  const link = CustomLink.createClientLink(props);

  return (
    <div className="main-text main-text--underline ellipsis-text">
      <CustomLink to={link}>{props.clientName}</CustomLink>
    </div>
  );
}

ClientsColumn.propTypes = {
  clientName: PropTypes.string,
  clientType: PropTypes.string,
  clientId: PropTypes.number,
};

export default ClientsColumn;
