export default {
  GET_LEAD_DISPLAY_ITEM_REQUEST: '@@leads/GET_LEAD_DISPLAY_ITEM_REQUEST',
  GET_LEAD_DISPLAY_ITEM_SUCCESS: '@@leads/GET_LEAD_DISPLAY_ITEM_SUCCESS',
  GET_LEAD_DISPLAY_ITEM_ERROR: '@@leads/GET_LEAD_DISPLAY_ITEM_ERROR',

  GET_LEAD_CLIENT_VISIBILITY_ITEMS_REQUEST: '@@leads/GET_LEAD_CLIENT_VISIBILITY_ITEMS_REQUEST',
  GET_LEAD_CLIENT_VISIBILITY_ITEMS_SUCCESS: '@@leads/GET_LEAD_CLIENT_VISIBILITY_ITEMS_SUCCESS',
  GET_LEAD_CLIENT_VISIBILITY_ITEMS_ERROR: '@@leads/GET_LEAD_CLIENT_VISIBILITY_ITEMS_ERROR',

  SET_LEAD_CLIENT_VISIBILITY_ITEMS_REQUEST: '@@leads/SET_LEAD_CLIENT_VISIBILITY_ITEMS_REQUEST',
  SET_LEAD_CLIENT_VISIBILITY_ITEMS_SUCCESS: '@@leads/SET_LEAD_CLIENT_VISIBILITY_ITEMS_SUCCESS',
  SET_LEAD_CLIENT_VISIBILITY_ITEMS_ERROR: '@@leads/SET_LEAD_CLIENT_VISIBILITY_ITEMS_ERROR',

  ADD_LEAD_CLIENT_VISIBILITY_ITEM_REQUEST: '@@leads/ADD_LEAD_CLIENT_VISIBILITY_ITEM_REQUEST',
  ADD_LEAD_CLIENT_VISIBILITY_ITEM_SUCCESS: '@@leads/ADD_LEAD_CLIENT_VISIBILITY_ITEM_SUCCESS',
  ADD_LEAD_CLIENT_VISIBILITY_ITEM_ERROR: '@@leads/ADD_LEAD_CLIENT_VISIBILITY_ITEM_ERROR',

  DELETE_LEAD_DISPLAY_ITEM_REQUEST: '@@leads/DELETE_LEAD_DISPLAY_ITEM_REQUEST',
  DELETE_LEAD_DISPLAY_ITEM_SUCCESS: '@@leads/DELETE_LEAD_DISPLAY_ITEM_SUCCESS',
  DELETE_LEAD_DISPLAY_ITEM_ERROR: '@@leads/DELETE_LEAD_DISPLAY_ITEM_ERROR',

  DELETE_LEAD_DISPLAY_ITEMS_REQUEST: '@@leads/DELETE_LEAD_DISPLAY_ITEMS_REQUEST',
  DELETE_LEAD_DISPLAY_ITEMS_SUCCESS: '@@leads/DELETE_LEAD_DISPLAY_ITEMS_SUCCESS',
  DELETE_LEAD_DISPLAY_ITEMS_ERROR: '@@leads/DELETE_LEAD_DISPLAY_ITEMS_ERROR',

  UPDATE_LEAD_DISPLAY_ITEMS_BULK_REQUEST: '@@leads/UPDATE_LEAD_DISPLAY_ITEMS_BULK_REQUEST',
  UPDATE_LEAD_DISPLAY_ITEMS_BULK_SUCCESS: '@@leads/UPDATE_LEAD_DISPLAY_ITEMS_BULK_SUCCESS',
  UPDATE_LEAD_DISPLAY_ITEMS_BULK_ERROR: '@@leads/UPDATE_LEAD_DISPLAY_ITEMS_BULK_ERROR',
};
