import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import set from 'lodash/set';

import {mapLevelOptions, mapTimelineStatistic} from './mappers';
import types from './types';

const initialState = {
  participations: [],
  participationTableData: {
    data: [],
    meta: {
      current_page: 0,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
  },
  meta: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
  levelOptions: [],
  levelStatistic: [],
  timelineStatistic: {
    points: [],
    visits: [],
    participations: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_PARTICIPATIONS_REQUEST:
      return {
        ...state,
        participations: initialState.participations,
        meta: initialState.meta,
      };

    case types.GET_PARTICIPATIONS_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload.meta,
        },
        participations: action.payload.participations,
      };

    case types.GET_OPERATIONS_SUCCESS:
      state.operations = action.payload.operations;
      return state;

    case types.CLEAR_PARTICIPATIONS:
      return {
        ...state,
        participations: [],
        meta: {...initialState.meta},
      };

    case types.GET_LEVEL_OPTIONS_SUCCESS:
      return {
        ...state,
        levelOptions: mapLevelOptions(action.payload.interface_levels),
      };

    case types.GET_PARTICIPATIONS_LEVEL_STATISTIC_SUCCESS:
      return {
        ...state,
        levelStatistic: get(action, 'payload.result.participations_level_statistic.interfaces', []),
      };

    case types.GET_PARTICIPATIONS_TIMELINE_STATISTIC_REQUEST:
      return {
        ...state,
        timelineStatistic: initialState.timelineStatistic,
      };

    case types.GET_PARTICIPATIONS_TIMELINE_STATISTIC_SUCCESS:
      const timelineData = get(action, 'payload.result.participations_timeline_statistic.time_lines', []);
      return {
        ...state,
        timelineStatistic: mapTimelineStatistic(timelineData),
      };
    case types.GET_PARTICIPATION_TABLE_DATA_SUCCESS:
      return {
        ...state,
        participationTableData: {
          data: action.payload.participations,
          meta: action.payload.meta,
        },
      };

    case types.UPDATE_PARTICIPATION_FIELD:
      const nextParticipants = [...state.participationTableData.data];
      const index = findIndex(nextParticipants, {id: action.payload.id});
      if (index >= 0) {
        set(nextParticipants[index], action.payload.path, action.payload.value);
      }

      return {
        ...state,
        participationTableData: {
          ...state.participationTableData,
          data: nextParticipants,
        },
      };

    default:
      return state;
  }
}
