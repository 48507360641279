import React, {useState, useEffect} from 'react';

import bem from 'client/services/bem';
import {useAppMedia} from 'client/services/hooks';

import InfiniteScroll from 'client/common/various/infinite-scroll';

import AnimationTimestampBlock from 'client/components/animations/blocks/animation-timestamp-block';

import cssModule from './animation-timestamps-block.module.scss';

const PER_PAGE = 5;

const b = bem('animation-timestamps-block', {cssModule});

type AnimationTimestampsBlockProps = {
  data: {
    id: number;
    from: string;
    to: string;
  }[];
};

const AnimationTimestampsBlock: React.FC<AnimationTimestampsBlockProps> = ({data}) => {
  const {isTablet} = useAppMedia();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState(data.slice(0, PER_PAGE));

  const totalPages = Math.ceil(data.length / PER_PAGE);

  useEffect(() => {
    if (isTablet) {
      setLoading(true);

      const handler = setTimeout(() => {
        setItems(data.slice(0, PER_PAGE * page));
        setLoading(false);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [data, isTablet, page]);

  return (
    <div className={b('', {laptop: !isTablet})}>
      {isTablet ? (
        <InfiniteScroll loading={loading} page={page} totalPages={totalPages} setPage={setPage}>
          {items.map((day: {from: string; to: string; id: number}, index: number) => (
            <AnimationTimestampBlock
              data={items}
              day={day}
              key={day.id || index}
              index={index}
              lastIndex={data.length - 1}
            />
          ))}
        </InfiniteScroll>
      ) : (
        data.map((day: {from: string; to: string; id: number}, index: number) => (
          <AnimationTimestampBlock
            data={data}
            day={day}
            key={day.id || index}
            index={index}
            lastIndex={data.length - 1}
          />
        ))
      )}
    </div>
  );
};

export default AnimationTimestampsBlock;
