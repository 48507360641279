import {get} from 'lodash';

import types from './types';

const initialState = {
  allIds: [],
  byId: {},
  campaign: {},
  campaignsWithChannels: {
    allIds: [],
    byId: {},
  },
  loadingCampaignsWithChannels: false,
  permanentSources: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_CAMPAIGNS_STATE:
      return initialState;

    case types.FETCH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        ...action.payload.campaigns.reduce(
          (result, item) => {
            return {
              allIds: [...result.allIds, item.id],
              byId: {...result.byId, [item.id]: item},
            };
          },
          {
            allIds: [],
            byId: {},
          },
        ),
      };

    case types.CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        allIds: [action.payload.campaign.id, ...state.allIds],
        byId: {...state.byId, [action.payload.campaign.id]: action.payload.campaign},
      };

    case types.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.payload.campaign,
      };

    case types.GET_CAMPAIGNS_WITH_CHANNELS_SUCCESS:
      const campaignsWithChannels = get(action.payload, 'interface.campaigns');
      let normalizedCampaigns = state.campaignsWithChannels;

      if (campaignsWithChannels) {
        normalizedCampaigns = campaignsWithChannels.reduce(
          (result, item) => {
            return {
              allIds: [...result.allIds, item.id],
              byId: {...result.byId, [item.id]: item},
            };
          },
          {
            allIds: [],
            byId: {},
          },
        );
      }

      return {
        ...state,
        loadingCampaignsWithChannels: false,
        campaignsWithChannels: normalizedCampaigns,
      };

    case types.GET_CAMPAIGNS_WITH_CHANNELS_REQUEST:
      return {
        ...state,
        loadingCampaignsWithChannels: true,
      };

    case types.GET_OPERATION_INTERFACES_SUCCESS:
      let interfaceId = state.interfaceId;
      const operationInterfaces = get(action.payload, 'operation.interfaces');

      if (operationInterfaces) {
        interfaceId = operationInterfaces[0].id;
      }

      return {...state, interfaceId};

    case types.GET_PERMANENT_SOURCES_SUCCESS:
      const permanentSources = get(action.payload, 'permanent_sources');

      return {...state, permanentSources};

    default:
      return state;
  }
};
