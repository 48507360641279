import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {set} from 'client/common/meta/meta.actions';

import Welcome from 'client/components/welcome';

class WelcomePage extends Component {
  componentDidMount() {
    this.props.setMetaAction({
      title: this.props.lang.WELCOME,
    });
  }

  render() {
    return <Welcome />;
  }
}

WelcomePage.propTypes = {
  lang: PropTypes.object.isRequired,
  setMetaAction: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    lang: state.languageState.payload.WELCOME,
  }),
  {
    setMetaAction: set,
  },
)(WelcomePage);
