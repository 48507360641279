import get from 'lodash/get';

import {APP_ROLES} from 'client/common/config';

import {USER_LOADED} from './types';

export const selectIsAdmin = (state) => {
  const userRole = get(state, 'userState.payload.role');
  return userRole === APP_ROLES.ADMIN || userRole === APP_ROLES.SUPER_ADMIN;
};

export const selectIsAccessDenied = (state) => {
  return state.userState.isAccessDenied;
};

export const selectIsPasswordExpired = (state) => {
  return state.userState.isPasswordExpired;
};

export function selectUser(state) {
  return state.userState.payload.user;
}

export function selectIsUserLoaded(state) {
  return state.userState.type === USER_LOADED;
}

export function selectRole(state) {
  return state.userState.payload.role;
}

export function selectCurrentUser(state) {
  return {
    ...selectUser(state),
    role: selectRole(state),
  };
}

export const selectViewMode = (state) => state.userState.viewMode;
