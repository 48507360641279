import React from 'react';

import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {TranslationJsx} from 'client/models/language/types';

import './data-selector.scss';

function DataSelector({val1, val2, to1, to2, active, className, text1, text2}) {
  const renderItem = (n, to, isLink) => {
    return (
      <span>
        {isLink ? (
          <Link to={to} className="link">
            {n}
          </Link>
        ) : (
          n
        )}
      </span>
    );
  };

  return (
    <div className={`data-selector ${className}`}>
      {renderItem(val1, to1, active)}
      {` ${text1} `}
      {renderItem(val2, to2, !active)}
      {` ${text2} `}
    </div>
  );
}

DataSelector.defaultProps = {
  to1: '',
  to2: '',
  val1: '',
  val2: '',
  active: true,
  className: '',
  text1: 'out of',
  text2: 'files selected',
};

DataSelector.propTypes = {
  to1: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  to2: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  val1: PropTypes.string,
  val2: PropTypes.string,
  active: PropTypes.bool,
  className: PropTypes.string,
  text1: TranslationJsx,
  text2: TranslationJsx,
};

export default DataSelector;
