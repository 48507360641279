import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {TextField} from 'client/common/fields';

import './animation-animator-fieldset.scss';

const b = bem('animation-animator-fieldset');

const AnimationAnimatorFieldset = ({readOnly}) => {
  const lang = useLanguage('ANIMATIONS.FIELDSETS.ANIMATION_ANIMATOR_FIELDSET');

  return (
    <div className={b()}>
      <TextField name="reco_name" label={lang.ANIMATOR_NAME_LABEL} withWrap readOnly={readOnly} />
      <TextField name="reco_email" label={lang.ANIMATOR_MAIL_LABEL} withWrap readOnly={readOnly} />
      <TextField name="reco_phone" label={lang.ANIMATOR_PHONE_LABEL} withWrap readOnly={readOnly} />
    </div>
  );
};

AnimationAnimatorFieldset.propTypes = {
  readOnly: PropTypes.bool.isRequired,
};

export default AnimationAnimatorFieldset;
