import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {
  getMessageTask,
  toggleMessageTaskState,
  updateMessageTask,
  deleteMessageTask,
  flushMessageTaskAllState,
} from 'client/ducks/message-email-sms-task/actions';
import {selectTaskBaseInfo} from 'client/ducks/message-email-sms-task/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import CustomLink from 'client/components/common/custom-link';
import Icon from 'client/components/common/icon';
import TitleBlock from 'client/components/common/title-block';

import {TASK_TYPES} from 'client/models/operations/constants';

import BCCard from '../components/cards/bc-card/bc-card';
import BCList from '../components/lists/bc-list';
import BCClicksModal from '../components/modals/bc-clicks-modal';
import BCEditTaskModal from '../components/modals/bc-edit-task-modal';
import BCSettingsPopover from '../components/popovers/bc-settings-popover';

class MessageTask extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    messageTask: PropTypes.object.isRequired,
    editFormInitialValues: PropTypes.object.isRequired,
    getMessageTask: PropTypes.func.isRequired,
    updateMessageTask: PropTypes.func.isRequired,
    toggleMessageTaskState: PropTypes.func.isRequired,
    deleteMessageTask: PropTypes.func.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    historyPush: PropTypes.func.isRequired,
    flushMessageTaskAllState: PropTypes.func.isRequired,
  };

  static MODALS_MAP = {
    CLICKS: 'CLICKS',
    EDIT_TASK: 'EDIT_TASK',
    DELETE_TASK: 'DELETE_TASK',
  };

  constructor(props) {
    super(props);

    this.state = {
      visibleModal: '',
    };

    this.LANGUAGE = props.languageState.payload.BROADCASTING_TASK;
  }

  componentDidMount() {
    this.props
      .getMessageTask(this.props.id, {
        include: ['operation', 'client.agency', 'broadcast_list', 'email_template'],
      })
      .then(() => {
        const {
          languageState: {
            payload: {BREADCRUMBS},
          },
          messageTask: {clientName, clientId, clientType, agency, operationId, operationName, taskName},
        } = this.props;

        const clientLink = CustomLink.createClientLink({clientId, clientType});
        const operationLink = CustomLink.createOperationLink({clientId, clientType, operationId});

        const agencyBreadcrumb = agency
          ? [
              {
                href: CustomLink.createClientLink({
                  clientId: agency.id,
                  clientType: agency.type,
                }),
                name: agency.name,
              },
            ]
          : [];

        const customOperationLink = CustomLink.createCustomOperationDBLink({clientId, clientType, operationId});

        this.props.setBreadcrumbs([
          {
            href: CLIENT_PAGES.VISUALS,
            name: BREADCRUMBS.VISUALS,
          },
          {
            href: CLIENT_PAGES.CLIENTS_LIST,
            name: BREADCRUMBS.CLIENTS,
          },
          ...agencyBreadcrumb,
          {
            name: clientName,
            href: clientLink,
          },
          {
            name: BREADCRUMBS.CUSTOM_OPERATION,
            href: customOperationLink,
          },
          {
            name: operationName,
            href: operationLink,
          },
          {
            name: taskName,
          },
        ]);
      });
  }

  componentWillUnmount() {
    this.props.flushMessageTaskAllState();
    this.props.clearBreadcrumbs();
  }

  handleShowModal = (name) => () => {
    this.setState({
      visibleModal: name,
    });
  };

  handleCloseModal = () => {
    this.setState({
      visibleModal: '',
    });
  };

  updateMessageTask = (body) => {
    const params = {
      include: ['operation', 'client.agency', 'broadcast_list', 'email_template'],
    };

    return this.props.updateMessageTask(this.props.id, body, params);
  };

  handleDeleteTask = () => {
    const {
      id,
      messageTask: {clientId, clientType, operationId},
    } = this.props;

    const operationLink = CustomLink.createOperationLink({clientId, clientType, operationId});

    return this.props.deleteMessageTask(id).then((response) => {
      if (response.error) {
        return this.handleCloseModal();
      }
      return this.props.historyPush(operationLink);
    });
  };

  handleToggleArchive = (archived) => {
    return this.updateMessageTask({
      message_task: {
        archived,
      },
    });
  };

  handleToggleMessageTaskState = (body) => {
    return this.props.toggleMessageTaskState(this.props.messageTask.taskId, body);
  };

  getConfirmModalTitle = () => {
    const {messageTask} = this.props;
    return messageTask.taskType === TASK_TYPES.SMS
      ? this.LANGUAGE.DELETE_CONFIRM_MODAL.TITLE_SMS
      : this.LANGUAGE.DELETE_CONFIRM_MODAL.TITLE_EMAIL;
  };

  render() {
    const {visibleModal} = this.state;
    const {messageTask, editFormInitialValues} = this.props;
    const confirmModalTitle = this.getConfirmModalTitle();

    return (
      <div className="theme-color-16">
        <BCEditTaskModal
          initialValues={editFormInitialValues}
          show={visibleModal === MessageTask.MODALS_MAP.EDIT_TASK}
          onClose={this.handleCloseModal}
          onConfirm={this.updateMessageTask}
        />
        <BCClicksModal
          links={messageTask.clicksStatistic}
          show={visibleModal === MessageTask.MODALS_MAP.CLICKS}
          onConfirm={this.handleCloseModal}
          onClose={this.handleCloseModal}
        />
        <ConfirmationModal
          show={visibleModal === MessageTask.MODALS_MAP.DELETE_TASK}
          onClose={this.handleCloseModal}
          onCancel={this.handleCloseModal}
          onConfirm={this.handleDeleteTask}
          title={confirmModalTitle}
          message={this.LANGUAGE.DELETE_CONFIRM_MODAL.MESSAGE}
          cancelText={this.LANGUAGE.DELETE_CONFIRM_MODAL.CANCEL_BUTTON}
          confirmText={this.LANGUAGE.DELETE_CONFIRM_MODAL.CONFIRM_BUTTON}
          className="theme-color-16"
          buttonConfirmClass="button--bg-14"
        />
        <div className="page__title-block flex-container flex-justify-between flex-align-center">
          <TitleBlock theme ellipsis="2" className="broadcasting__title-block">
            <TitleBlock.Item>{messageTask.taskName}</TitleBlock.Item>
          </TitleBlock>
          <BCSettingsPopover
            {...messageTask}
            onEditClick={this.handleShowModal(MessageTask.MODALS_MAP.EDIT_TASK)}
            onDeleteClick={this.handleShowModal(MessageTask.MODALS_MAP.DELETE_TASK)}
            onToggleArchiveClick={this.handleToggleArchive}
          >
            <Icon name="settings" />
          </BCSettingsPopover>
        </div>
        <BCCard title={this.LANGUAGE.TASK_LIST.TITLE}>
          <BCList
            onToggleMessageTaskState={this.handleToggleMessageTaskState}
            onToggleWebhooks={this.updateMessageTask}
            onClicksCountClick={this.handleShowModal(MessageTask.MODALS_MAP.CLICKS)}
          />
        </BCCard>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const messageTask = selectTaskBaseInfo(state);
  const editFormInitialValues = {
    broadcast_id: messageTask.broadcastId,
    task_name: messageTask.taskName,
    broadcast_name: messageTask.broadcastList.name,
  };

  return {
    languageState: state.languageState,
    messageTask,
    editFormInitialValues,
  };
};

const mapDispatchToProps = {
  setBreadcrumbs,
  clearBreadcrumbs,
  getMessageTask,
  updateMessageTask,
  toggleMessageTaskState,
  deleteMessageTask,
  flushMessageTaskAllState,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageTask);
