import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect, withRouter} from 'react-router-dom';

import {selectTaskBaseInfo} from 'client/ducks/message-email-sms-task/selectors';
import {selectIsUserLoaded, selectIsAdmin} from 'client/ducks/user/selectors';

import {CLIENT_PAGES} from 'client/common/config';
import {set} from 'client/common/meta/meta.actions';

import MailingList from 'client/components/broadcasting/mailing-list';

class MailingListPage extends Component {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    ...withRouter.propTypes,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
        clientId: PropTypes.string,
        taskId: PropTypes.string,
        operationId: PropTypes.string,
        broadcastId: PropTypes.string,
      }),
    }).isRequired,
    languageState: PropTypes.object.isRequired,
    task: PropTypes.object.isRequired,
    isUserLoaded: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  componentDidUpdate(prevProps) {
    const {task, setMetaAction, languageState} = this.props;
    const title = languageState.payload.BROADCASTING_TASK.MAILING_LIST_TITLE;

    if (prevProps.task.broadcastName !== task.broadcastName) {
      setMetaAction({
        title: `${task.broadcastName} | ${title}`,
      });
    }
  }

  render() {
    const {
      match: {
        params: {taskId, clientId, operationId, broadcastId},
      },
      history,
      isUserLoaded,
      isAdmin,
    } = this.props;

    if (!isUserLoaded) {
      return null;
    }

    if (!isAdmin) {
      return <Redirect to={CLIENT_PAGES.HOME} />;
    }

    return (
      <MailingList
        history={history}
        taskId={taskId}
        clientId={clientId}
        operationId={operationId}
        broadcastId={broadcastId}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  task: selectTaskBaseInfo(state),
  languageState: state.languageState,
  isUserLoaded: selectIsUserLoaded(state),
  isAdmin: selectIsAdmin(state),
});

const mapDispatchToProps = {
  setMetaAction: set,
};

export default connect(mapStateToProps, mapDispatchToProps)(MailingListPage);
