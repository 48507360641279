import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Modal from 'client/common/modals/modal';

import './update-interface-modal.scss';

const UpdateInterfaceModal = ({onClose, modalNames, selectModal, lang}) => {
  return (
    <Modal
      show={true}
      dialogClassName="modal-window--width-1 theme-color-8 update-interface-modal"
      backdropClassName="modal-window__backdrop"
      title={lang.TITLE}
      onClose={onClose}
    >
      {/* TODO: add props to ClientModal, after additing class */}
      <div className="update-interface-modal__buttons">
        <button
          className="button button--bg-7 modal-window__footer-btn"
          type="button"
          onClick={() => selectModal(modalNames.UPDATE_INTERFACE_NAME_MODAL)}
        >
          {lang.EDIT_INTERFACE_NAME}
        </button>
        <button
          className="button button--bg-7 modal-window__footer-btn"
          type="button"
          onClick={() => selectModal(modalNames.SET_INTERFACE_LEVELS_MODAL)}
        >
          {lang.EDIT_INTERFACE_LEVELS}
        </button>
        <button
          className="button button--bg-7 modal-window__footer-btn"
          type="button"
          onClick={() => selectModal(modalNames.EDIT_INTERFACE_ITEMS_MODAL)}
        >
          {lang.EDIT_INTERFACE_ITEMS}
        </button>
        <button
          className="button button--bg-7 modal-window__footer-btn"
          type="button"
          onClick={() => selectModal(modalNames.UPDATE_SETTINGS_MODAL)}
        >
          {lang.EDIT_INTERFACE_SETTINGS}
        </button>
      </div>
    </Modal>
  );
};

UpdateInterfaceModal.propTypes = {
  lang: PropTypes.object.isRequired,
  modalNames: PropTypes.shape({
    SET_INTERFACE_LEVELS_MODAL: PropTypes.string,
    UPDATE_INTERFACE_NAME_MODAL: PropTypes.string,
    EDIT_INTERFACE_ITEMS_MODAL: PropTypes.string,
    UPDATE_SETTINGS_MODAL: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  selectModal: PropTypes.func.isRequired,
};

export default connect((state) => {
  return {
    lang: state.languageState.payload.AUTOTASK.UPDATE_INTERFACE_MODAL,
  };
}, {})(UpdateInterfaceModal);
