import React, {useState, useMemo} from 'react';

import {connect, useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';
import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {hasObjectTruthyValues} from 'client/services/validator';

import {selectAutotaskTypes} from 'client/ducks/autotask/selectors';
import {selectAutotask} from 'client/ducks/autotask/selectors';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {
  createDiyPrize,
  updateDiyPrize,
  deleteDiyPrize,
  deleteDiyPrizeImage,
  deleteDiyPrizeCoupon,
} from 'client/ducks/prizes/actions';

import AppButton from 'client/common/buttons/app-button';
import {useToast} from 'client/common/hooks/useToast';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Modal from 'client/common/modals/modal';
import Offscreen from 'client/common/offscreen';
import TabSwitcher from 'client/common/tab-switcher';

import {PRIZE_TYPES} from 'client/components/prizes/constants';
import {isCoupon} from 'client/components/prizes/helpers';
import {Operation} from 'client/models/operations/types';
import {Prize, PrizeBlock} from 'client/models/prizes/types';
import {ApiDispatch, MainStates} from 'client/types';

import getInitialValues from './initialValues';
import mapFormValues from './mapFormValues';
import PrizeDiyModalCoupon from './prize-diy-modal-coupon';
import PrizeDiyModalGeneral from './prize-diy-modal-general';
import PrizeDiyModalInstantWin from './prize-diy-modal-instant-win';
import validate from './validate';

import './prize-diy-modal.scss';

const b = bem('prize-diy-modal');

const tabs = {
  GENERAL: 'general',
  INSTANT_WIN: 'instant_win',
  COUPONS: 'coupons',
};

export type PrizeDiyModalProps = {
  game: PrizeBlock;
  operation: Operation;
  fetchData: () => Promise<any>;
  onClose: () => void;
  isEdit?: boolean;
  editingPrize?: Prize;
  nextPrize?: Prize;
  prevPrize?: Prize;
  validationLang: LangsByPath<['PRIZES.FIELDSETS.ERRORS']>;
  autotaskTypes: any;
  isOperationArchived: boolean;
};
export const PrizeDiyModalFormName = 'DIY_PRIZE_MODAL_FORM';

const PrizeDiyModal: ReduxFormFC<PrizeDiyModalProps> = (props) => {
  const {
    game,
    operation,
    fetchData,
    onClose,
    handleSubmit,
    isEdit,
    editingPrize,
    invalid,
    isOperationArchived,
    submitFailed,
    prevPrize,
    nextPrize,
  } = props;
  const dispatch: ApiDispatch = useDispatch();
  const lang = useLanguage('PRIZES.MODALS.PRIZE_DIY_MODAL');

  const [deletingImages, setDeletingImages] = useState<number[]>([]);
  const [activeTab, setActiveTab] = useState(tabs.GENERAL);
  const [showDeleteModal, toggleDeleteModal] = useToggle(false);
  const [loading, toggleLoading] = useToggle(false);
  const autotask = useSelector(selectAutotask);
  const client = useSelector(selectCurrentClient);

  const {formValues, errors} = useReduxForm(PrizeDiyModalFormName);
  const {fetch: saveDiyPrize} = useReduxFetch<{prize: Prize}>({
    action: isEdit && editingPrize ? (data: any) => updateDiyPrize(editingPrize?.id, data) : createDiyPrize,
    fetchOnMount: false,
  });

  const {appendToastNotification} = useToast();
  const gameId = game.id;

  const disabled = !operation.editable || isOperationArchived;
  const hideInstantWinTab = !!formValues?.main.full_win;

  const handleDeleteCoupon = () => {
    editingPrize?.coupon && dispatch(deleteDiyPrizeCoupon(editingPrize.coupon.id));
  };

  const handleSave = async (values: Record<string, any>) => {
    try {
      const body = (await mapFormValues(values)) as unknown as Prize;
      const data: {prize: Prize} = {
        prize: {
          ...body,
          game_id: gameId,
          ...(!isEdit && {code: operation.code}),
        },
      };
      if (isCoupon(data.prize) && data.prize.coupon) {
        data.prize.coupon.operation_id = operation.id;
      }

      toggleLoading();

      if (isEdit && deletingImages.length > 0) {
        await dispatch(deleteDiyPrizeImage(editingPrize?.id, deletingImages));
        setDeletingImages([]);
      }

      const response = await saveDiyPrize(data);

      if (isEdit && editingPrize && isCoupon(editingPrize) && !isCoupon(data.prize)) {
        await handleDeleteCoupon();
      }

      await fetchData();
      onClose();

      if ((response?.payload?.prize as unknown as any)?.token_list_errors?.filter((i: any) => !i.common)?.length) {
        appendToastNotification({
          type: 'warning',
          description: lang.PRIZE_TOKEN_ERROR,
        });
      }
      appendToastNotification({
        type: 'success',
        description: lang.PRIZE_SAVED_SUCCESSED,
      });
    } catch (error) {
      appendToastNotification({
        type: 'error',
        description: lang.PRIZE_NO_SAVED,
      });
    }
  };

  const handleDelete = async () => {
    if (editingPrize && isCoupon(editingPrize)) {
      await handleDeleteCoupon();
    }

    await dispatch(deleteDiyPrize(editingPrize?.id));
    await fetchData();
    onClose();
  };

  const generalTabErrors = {
    ...errors.main,
    ...errors.win_pop_up,
    ...errors.segmentation,
    ...errors.ticket,
    ...errors.email,
  };

  const instantWinTabErrors = {
    ...errors.ratioTime,
    ...errors.ratioParticipation,
    ...errors.customList,
    ...errors.weeklyList,
    ...errors.weekSchedule,
  };

  const hasGeneralTabErrors = submitFailed && hasObjectTruthyValues(generalTabErrors);
  const hasInstantWinTabErrors = submitFailed && hasObjectTruthyValues(instantWinTabErrors);
  const hasCouponTabErrors = submitFailed && hasObjectTruthyValues(errors.coupon || {});

  const tabsConfig = useMemo(
    () => [
      {
        title: lang.GENERAL,
        value: tabs.GENERAL,
        hasError: hasGeneralTabErrors,
      },
      {
        title: lang.INSTANT_WIN,
        value: tabs.INSTANT_WIN,
        exclude: hideInstantWinTab,
        hasError: hasInstantWinTabErrors,
      },
      {
        title: lang.COUPONS,
        value: tabs.COUPONS,
        exclude: formValues?.main?.type !== PRIZE_TYPES.COUPON,
        hasError: hasCouponTabErrors,
      },
    ],
    [lang, hasGeneralTabErrors, hideInstantWinTab, hasInstantWinTabErrors, formValues?.main?.type, hasCouponTabErrors],
  );

  const hasWins = Boolean(
    editingPrize?.total_quantities?.total_prize_won_non_validated ||
      editingPrize?.total_quantities?.total_prize_won_validated,
  );

  const handleRemoveImage = (ids: number[]) => {
    setDeletingImages(ids);
  };

  return (
    <Modal className={b()} title={lang.TITLE} onClose={onClose} isCloseHidden classNames={{body: b('body')}}>
      <form onSubmit={handleSubmit(handleSave)} noValidate>
        <div className={b('buttons')}>
          {isEdit && (
            <>
              <ConfirmationModal
                show={showDeleteModal}
                onClose={toggleDeleteModal}
                onConfirm={handleDelete}
                message={lang.DELETE_THIS_PRIZE}
                buttonCancel={{
                  label: lang.CANCEL,
                  color: 'devices',
                  transparent: true,
                  size: 'small',
                }}
                buttonConfirm={{
                  label: lang.OK,
                  color: 'devices',
                  size: 'small',
                }}
                clientSide
              />
              <AppButton
                label={lang.DELETE_PRIZE}
                onClick={toggleDeleteModal}
                color="error"
                transparent
                disabled={disabled || hasWins}
              />
            </>
          )}
          <AppButton label={lang.CANCEL} onClick={onClose} transparent />
          <AppButton
            label={lang.SAVE}
            className={b('save-button')}
            loading={loading}
            disabled={client.auto_configuration && autotask.validated}
            submit
          />
        </div>
        {invalid && <p className={b('error')}>{lang.FILL_ALL_FIELDS}</p>}
        <TabSwitcher
          className={b('tab-switcher')}
          tabs={tabsConfig}
          activeTab={activeTab}
          onTabClick={setActiveTab}
          style="title"
        />
        <div className={b('content')}>
          <Offscreen hidden={activeTab !== tabs.GENERAL}>
            <PrizeDiyModalGeneral
              prize={editingPrize}
              disabled={disabled}
              hasWins={hasWins}
              isEdit={!!isEdit}
              nextPrize={nextPrize}
              prevPrize={prevPrize}
              onRemoveImage={handleRemoveImage}
            />
          </Offscreen>
          {!hideInstantWinTab && (
            <Offscreen hidden={activeTab !== tabs.INSTANT_WIN}>
              <PrizeDiyModalInstantWin disabled={disabled} />
            </Offscreen>
          )}
          <Offscreen hidden={activeTab !== tabs.COUPONS}>
            <PrizeDiyModalCoupon operation={operation} disabled={disabled} couponType={formValues.coupon.type} />
          </Offscreen>
        </div>
      </form>
    </Modal>
  );
};

export default connect((state: MainStates, {editingPrize}: PrizeDiyModalProps) => ({
  validationLang: state.languageState.payload.PRIZES.FIELDSETS.ERRORS,
  isEdit: !!editingPrize,
  initialValues: getInitialValues({prize: editingPrize}, state),
  autotaskTypes: selectAutotaskTypes(state),
}))(
  reduxForm<PrizeDiyModalProps>({
    form: PrizeDiyModalFormName,
    shouldValidate: () => true,
    forceUnregisterOnUnmount: false,
    validate: validate as any,
  })(PrizeDiyModal),
);
