import React from 'react';

import PropTypes from 'prop-types';

import BaseToolbar from 'client/components/common/base-toolbar';
import DataSelector from 'client/components/common/data-selector';
import StatusButtons from 'client/components/common/status-buttons';
import VisibleStatusButtons from 'client/components/common/visible-status-buttons';

import {TranslationJsx} from 'client/models/language/types';

import './vis-toolbar.scss';

function VisToolbar({
  searchField,
  resultsNum,
  textLabels,
  filters,
  dataSelector,
  actionButtons,
  onShowFilterClick,
  onClearFiltersClick,
  showActionBar,
  statusActionButtons,
  onFiltersClick,
  isGridView,
}) {
  return (
    <div className="vis-toolbar">
      <BaseToolbar
        withoutSearch={isGridView}
        searchField={searchField}
        resultsNum={resultsNum}
        filterBtnClass="button--bg-8"
        onShowFilterClick={onShowFilterClick}
        onClearFiltersClick={onClearFiltersClick}
        textLabels={{
          filtersButton: textLabels.filtersButton,
          resultsNum: ` ${textLabels.resultsNum}`,
          clearFilters: textLabels.clearFilters,
        }}
        filters={filters}
        onFiltersClick={onFiltersClick}
        actionBarChildren={
          showActionBar && (
            <div className="vis-data-grid__action-bar">
              {dataSelector && (
                <div className="vis-data-grid__data-selector">
                  <DataSelector
                    to1={dataSelector.link1}
                    to2={dataSelector.link2}
                    val1={dataSelector.num1}
                    val2={dataSelector.num2}
                    text1={textLabels.dataSelector1}
                    text2={textLabels.dataSelector2}
                    active={dataSelector.active}
                  />
                </div>
              )}
              <StatusButtons
                title={textLabels.buttonValidate}
                onClick={(data) => statusActionButtons.onValidateClick(data)}
              />
              <VisibleStatusButtons
                title={textLabels.buttonVisible}
                onClick={(data) => statusActionButtons.onVisibleClick(data)}
              />
              {actionButtons}
            </div>
          )
        }
      />
    </div>
  );
}

VisToolbar.defaultProps = {
  resultsNum: '',
  searchField: null,
  statusButtons: '',
  textLabels: {},
  filters: [],
  onShowFilterClick: () => {},
  onClearFiltersClick: () => {},
  showActionBar: true,
  statusActionButtons: {
    onValidateClick: () => {},
    onVisibleClick: () => {},
  },
  onFiltersClick: () => {},
  actionButtons: null,
  isGridView: false,
};

VisToolbar.propTypes = {
  resultsNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  searchField: PropTypes.element,
  statusButtons: PropTypes.any,
  textLabels: PropTypes.object,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      applied: PropTypes.bool,
      label: TranslationJsx,
      name: PropTypes.string,
    }),
  ),
  onFiltersClick: PropTypes.func,
  dataSelector: PropTypes.shape({
    link1: PropTypes.string,
    link2: PropTypes.string,
    active: PropTypes.bool,
    num1: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    num2: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onShowFilterClick: PropTypes.func,
  onClearFiltersClick: PropTypes.func,
  showActionBar: PropTypes.bool,
  statusActionButtons: PropTypes.shape({
    onValidateClick: PropTypes.func,
    onVisibleClick: PropTypes.func,
  }),
  actionButtons: PropTypes.any,
  isGridView: PropTypes.bool,
};

export default VisToolbar;
