export const OPTION_NAMES = {
  LEAD_FIRST_NAME: 'lead_first_name_cont',
  LEAD_LAST_NAME: 'lead_last_name_cont',
  LEAD_EMAIL: 'lead_email_cont',
  PARTICIPATION_DATA: 'participation_data',
  PRIZE_NAME: 'participation_prizes_name_cont',
  PRIZE_LEVEL: 'participation_prizes_level_eq',
  PRIZE_WIN_CODE: 'participation_prizes_win_code_cont',
  PRIZE_COUPON_TOKEN: 'participation_prizes_coupon_token_token_cont',
  DEVICE_ID: 'device_id_eq',
  SOURCE_ID: 'source_id_eq',
  DEVICE_NAME: 'device_name_cont',
  SOURCE_NAME: 'source_name_cont',
  CITY_NAME: 'city_name_cont',
  AGGLOMERATION_NAME: 'agglomeration_name_cont',
  ZONE_NAME: 'zone_name_cont',
  SESSION_ID: 'session_id_eq',
  PARTICIPATION_ID: 'participation_id',
};
