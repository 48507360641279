import React, {useMemo, useState} from 'react';

import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons';
import CheckboxField from 'client/common/fields/checkbox-field';
import RadioButtonGroupField from 'client/common/fields/radio-button-group-field';
import TextAreaField from 'client/common/fields/textarea-field';
import SelectDropdown from 'client/common/selects/select-dropdown';

import {ALL_LANGUAGES, MAX_LANGUAGES, DEFAULT_LANGUAGE} from './constants';

import {LeadOptinValues} from '../types';

import cssModule from './lead-optin-texts.module.scss';

const b = bem('lead-optin-texts', {cssModule});

type LeadOptinTextsProps = {
  formValues: LeadOptinValues;
  change: (field: string, value: any) => void;
};

const LeadOptinTexts: React.FC<LeadOptinTextsProps> = (props) => {
  const {formValues, change} = props;
  const lang = useLanguage('LEADS_PARAMETERS.LEAD_OPTIN_MODAL');
  const languageNames = useLanguage('LANGUAGES');
  const [showAddingLanguage, toggleAddingLanguage] = useToggle(false);
  const [addingLanguage, setAddingLanguage] = useState<string>('');
  const {languages, current_language, optin_texts, optout_texts} = formValues;

  const languageOptions = useMemo(() => {
    return ALL_LANGUAGES.filter((i) => !languages.includes(i)).map((value) => ({
      value,
      label: languageNames[value.toUpperCase() as keyof typeof languageNames],
    }));
  }, [languageNames, languages]);

  const handleAddLanguage = () => {
    change('languages', [...languages, addingLanguage]);
    setAddingLanguage('');
    toggleAddingLanguage();
  };

  const handleDeleteLanguage = () => {
    change(
      'languages',
      languages.filter((i) => i !== current_language),
    );
    change('optin_texts', {...optin_texts, [current_language]: null});
    change('optout_texts', {...optout_texts, [current_language]: null});
    change('current_language', 'fr');
  };

  return (
    <div className={b()}>
      <div className={b('lang-switch')}>
        <RadioButtonGroupField
          name="current_language"
          radioButtons={languages.map((i) => ({value: i, label: i}))}
          labelClassName={b('lang-label')}
        />
        {languages.length < MAX_LANGUAGES && (
          <AppButton iconName="add" onClick={toggleAddingLanguage} title={lang.ADD_LANGUAGE} asWrap />
        )}
        {current_language !== DEFAULT_LANGUAGE && <AppButton iconName="minus" onClick={handleDeleteLanguage} asWrap />}
      </div>
      {showAddingLanguage && (
        <div className={b('add-lang')}>
          <SelectDropdown
            className={b('select-lang')}
            label={lang.ADD_LANGUAGE}
            options={languageOptions}
            onChange={setAddingLanguage}
            value={addingLanguage}
            simpleValue
            searchable
          />
          <AppButton label={lang.CANCEL} transparent color="clients" onClick={toggleAddingLanguage} />
          <AppButton
            label={lang.ADD}
            transparent
            color="clients"
            onClick={handleAddLanguage}
            disabled={!addingLanguage}
          />
        </div>
      )}
      <div className={b('editable-wrap')}>
        <CheckboxField name="editable" label={lang.EDITABLE} color="primary" inversionColor />
      </div>
      <TextAreaField
        className={b('textarea-in')}
        label={lang.OPTIN}
        name={`optin_texts.${current_language}`}
        height={90}
      />
      <TextAreaField
        className={b('textarea-out')}
        label={lang.OPTOUT}
        name={`optout_texts.${current_language}`}
        height={90}
      />
    </div>
  );
};

export default LeadOptinTexts;
