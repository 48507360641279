export function mapLeadsStatistic(data) {
  const {gender} = data;

  if (data.hasOwnProperty('total')) {
    return data;
  }

  return {
    ...data,
    total: gender.male_count + gender.female_count + gender.unknown_count,
  };
}
