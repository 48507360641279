import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getLeadHistories(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.LEAD_HISTORIES}`,
      method: 'GET',
      headers: {
        'cache-control': 'no-cache',
      },
      types: [types.FETCH_LEAD_HISTORIES_REQUEST, types.FETCH_LEAD_HISTORIES_SUCCESS, types.FETCH_LEAD_HISTORIES_ERROR],
      queryParams,
    });
}

export function getUsersByLead(queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.USERS}`,
      method: 'GET',
      headers: {
        'cache-control': 'no-cache',
      },
      types: [
        types.FETCH_USER_IDS_LEAD_HISTORIES_REQUEST,
        types.FETCH_USER_IDS_LEAD_HISTORIES_SUCCESS,
        types.FETCH_USER_IDS_LEAD_HISTORIES_ERROR,
      ],
      queryParams,
    });
}
