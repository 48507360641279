import {stringify} from 'qs';

import {API_PATH} from 'client/common/config';

import {getAuthHeaders} from './helpers';

export function downLoadFileByLink(link) {
  const options = {
    headers: getAuthHeaders(),
  };

  return fetch(link, options)
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob));
}

export function send(path, options, isAuth = true) {
  if (isAuth) {
    options.headers = getAuthHeaders();
  }

  return fetch(`${API_PATH}${path}`, options).then((res) => (res.status === 204 ? res : res.json()));
}

export function get(path, queryParams = {}, isAuth = true) {
  const options = {
    method: 'GET',
  };

  return send(`${path}?${stringify(queryParams, {arrayFormat: 'brackets', encode: false})}`, options, isAuth);
}

export function post(path, body = {}, isAuth = true) {
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
  };

  return send(path, options, isAuth);
}

export function patch(path, body = {}, isAuth = true) {
  const options = {
    method: 'PATCH',
    body: JSON.stringify(body),
  };

  return send(path, options, isAuth);
}

export function deleteMethod(path, queryParams = {}, isAuth = true) {
  const options = {
    method: 'DELETE',
  };

  return send(`${path}?${stringify(queryParams, {arrayFormat: 'brackets', encode: false})}`, options, isAuth);
}
