import {LOCATION_TYPES} from 'client/ducks/interactions/constants';

export function mapInteraction(data) {
  const interaction = {...data};
  const {location} = data;

  if (location) {
    switch (location.type) {
      case LOCATION_TYPES.REGION:
        interaction.region = location;
        break;
      case LOCATION_TYPES.PLACE:
        interaction.store = location;
        interaction.region = location.region;
        break;
    }
  }

  return interaction;
}
