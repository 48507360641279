import React, {Component} from 'react';

import upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import bem from 'client/services/bem';
import {getClientPage} from 'client/services/helpers';

import {
  getAutotask,
  patchAutotask,
  deleteAutotask,
  updateScenario,
  clearAutotaskState,
  updateStatistic,
} from 'client/ducks/autotask/actions';
import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';

import AppButton from 'client/common/buttons';
import {CLIENT_PAGES} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import Icon from 'client/components/common/icon';
import ImagePanel from 'client/components/common/image-panel/image-panel';
import Spinner from 'client/components/common/spinner';
import TitleBlock from 'client/components/common/title-block';

import AutotaskManagementCard from 'client/components/autotask/components/cards/autotask-management-card';
import GamesCard from 'client/components/games/games-card';
import ParticipantsExportHistoryCard from 'client/components/participants/participants-export-history-card';
import ResultGraphsCard from 'client/components/results-graph-card';

import AutotaskCampaigns from './autotask-campaigns';
import AutotaskInterfaces from './autotask-interfaces';
import AutotaskPlans from './autotask-plans';
import AutotaskCard from './components/cards/autotask-card/autotask-card';
import ScenarioList from './components/lists/scenario-list/scenario-list';
import AtSettingsModal from './components/modals/at-settings-modal/at-settings-modal';
import NewScenarioModal from './components/modals/new-scenario-modal/new-scenario-modal';

import cssModule from './autotask.module.scss';

const b = bem('autotask', {cssModule});

class Autotask extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    companiesId: PropTypes.number.isRequired,
    operationId: PropTypes.number.isRequired,
    autotask: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      archived: PropTypes.bool,
      scenarios: PropTypes.array,
      api_key: PropTypes.string,
      participations_count: PropTypes.number,
      custom_prize_type_names: PropTypes.array,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      operation: PropTypes.shape({
        id: PropTypes.number,
        client_id: PropTypes.number,
        client: PropTypes.object,
      }),
    }).isRequired,
    ...withRouter.propTypes,
    setBreadcrumbs: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    langImage: PropTypes.object.isRequired,
    languageState: PropTypes.object.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    clearAutotaskState: PropTypes.func.isRequired,
    updateStatistic: PropTypes.func.isRequired,
    getAutotask: PropTypes.func.isRequired,
    patchAutotask: PropTypes.func.isRequired,
    deleteAutotask: PropTypes.func.isRequired,
    updateScenario: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  state = {
    showNewScenarioModal: false,
    showDeleteAutotaskModal: false,
    showAtSettingsModal: false,
    loading: false,
    loadingStatistic: false,
  };

  componentDidMount() {
    this.props.clearAutotaskState();
    this.getMyAutotask();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this.props.clearAutotaskState();
      this.getMyAutotask();
    }
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
    this.props.clearAutotaskState();
  }

  toggleLoading = () => {
    this.setState(({loading}) => ({loading: !loading}));
  };

  getMyAutotask = () =>
    this.props
      .getAutotask(this.props.id, {
        include_scenario_failed_logs_present: true,
        include_automation_task_prize_maps_present: true,
        include: [
          {
            scenario: ['first_scenario_execution_created_at', 'last_scenario_execution_created_at'],
            scenarios: [
              {
                scenario_steps: ['implementation'],
              },
              {
                trigger_mapping_items: {
                  interface_item: 'interface',
                },
              },
            ],
            interactions: null,
            interfaces: {
              interface_items: 'mapping_items',
              opt_in_mapping_items: {
                column_adapter: 'opt_in_column',
              },
            },
          },
          'interaction_groups',
          'custom_prize_type_names',
          {
            operation: {
              client: 'agency',
              client_interface: {
                web_app: null,
              },
            },
          },
        ],
      })
      .then((data) => {
        if (!data.error) {
          this.updateBreadcrumbs();
        } else {
          this.props.history.push(CLIENT_PAGES.ERROR_404_URL);
        }
      });

  updateBreadcrumbs = () => {
    const {
      autotask: {name: taskName, operation: {id: operationId, name: operationName, client} = {}},
      setBreadcrumbs: setBc,
      languageState: {
        payload: {BREADCRUMBS},
      },
    } = this.props;

    const clientHref = `${getClientPage(client)}/${client?.id}`;
    const agencyBreadcrumb = client?.agency
      ? [
          {
            href: `${getClientPage(client?.agency)}/${client?.agency?.id}`,
            name: client?.agency?.name,
          },
        ]
      : [];

    const operationHref = `${clientHref}${CLIENT_PAGES.OPERATIONS}/${operationId}`;
    const customOpDBHref = `${operationHref}${CLIENT_PAGES.CUSTOM_OPERATION_DATABASE}`;

    setBc([
      {
        href: CLIENT_PAGES.VISUALS,
        name: BREADCRUMBS.VISUALS,
      },
      {
        name: BREADCRUMBS.CLIENTS,
        href: CLIENT_PAGES.CLIENTS_LIST,
      },
      ...agencyBreadcrumb,
      {
        name: client?.name,
        href: clientHref,
      },
      {
        name: BREADCRUMBS.CUSTOM_OPERATION,
        href: customOpDBHref,
      },
      {
        name: operationName,
        href: operationHref,
      },
      {
        name: taskName,
      },
    ]);
  };

  handleOpenModal = (key) => () => {
    this.setState({
      [`show${upperFirst(key)}`]: true,
    });
  };

  handleCloseModal = (key) => () => {
    this.setState({
      [`show${upperFirst(key)}`]: false,
    });
  };

  changeScenarioActiveState = (id, state) => {
    return this.props.updateScenario(id, {
      active: state,
    });
  };

  handleRemoveAutotask = () => {
    const {
      autotask: {
        operation: {id: operation_id, client_id},
      },
      history,
    } = this.props;

    this.props
      .deleteAutotask(this.props.id)
      .then(this.handleCloseModal('deleteAutotaskModal'))
      .then(() => history.push(`${CLIENT_PAGES.COMPANIES}/${client_id}${CLIENT_PAGES.OPERATIONS}/${operation_id}`));
  };

  handleArchiveClick = () => {
    this.toggleLoading();
    this.props
      .patchAutotask(this.props.id, {
        automation_task: {
          archived: !this.props.autotask.archived,
        },
      })
      .then(() => {
        this.toggleLoading();
        this.getMyAutotas();
      });
  };
  handleUpdateStatistic = () => {
    this.setState({
      loadingStatistic: true,
    });
    this.props
      .updateStatistic(this.props.id)
      .then((res) => {
        return res;
      })
      .finally(() => {
        this.setState({
          loadingStatistic: false,
        });
      });
  };

  goToWinnersTablePage = () => {
    const {
      autotask: {
        operation: {id: operationId, client_id: clientId},
        id: taskId,
      },
      history,
    } = this.props;
    // eslint-disable-next-line max-len
    const url = `${CLIENT_PAGES.COMPANIES}/${clientId}${CLIENT_PAGES.OPERATIONS}/${operationId}${CLIENT_PAGES.AUTOTASK}/${taskId}${CLIENT_PAGES.WINNERS}`;
    history.push(url);
  };

  render() {
    const {
      companiesId,
      isAdmin,
      lang,
      langImage,
      autotask: {id, name, archived, scenarios, operation: {id: operationId, client_id: clientId} = {}},
    } = this.props;

    const {showNewScenarioModal, showDeleteAutotaskModal, showAtSettingsModal, loading} = this.state;

    if (loading) {
      return <Spinner />;
    }

    if (!id) {
      return null;
    }

    const deleteAutotaskLabels = lang.DELETE_AUTOTASK_MODAL;
    const {autotask} = this.props;
    const url = (autotask && autotask.image && autotask.image.url) || null;

    return (
      <div className="theme-color-7">
        <NewScenarioModal
          show={showNewScenarioModal}
          automationTaskId={id}
          onClose={this.handleCloseModal('newScenarioModal')}
        />

        <ConfirmationModal
          show={showDeleteAutotaskModal}
          onClose={this.handleCloseModal('deleteAutotaskModal')}
          onCancel={this.handleCloseModal('deleteAutotaskModal')}
          onConfirm={this.handleRemoveAutotask}
          title={deleteAutotaskLabels.TITLE}
          message={deleteAutotaskLabels.MESSAGE}
          cancelText={deleteAutotaskLabels.CANCEL_BUTTON}
          confirmText={deleteAutotaskLabels.CONFIRM_BUTTON}
          className="theme-color-7"
          buttonConfirmClass="button--bg-13"
        />

        {showAtSettingsModal && (
          <AtSettingsModal
            onClose={this.handleCloseModal('atSettingsModal')}
            onDelete={this.handleOpenModal('deleteAutotaskModal')}
            onArchive={this.handleArchiveClick}
            fetchAutotask={this.getMyAutotask}
            autotask={autotask}
            client={autotask.operation.client}
          />
        )}

        <div className={b('title')}>
          <TitleBlock theme ellipsis="1" className="ellipsis-title">
            <TitleBlock.Item>
              {lang.TITLE} "{name}"{isAdmin && ` ${lang.AUTOTASK_ID.replace('{N}', id)}`}
            </TitleBlock.Item>
          </TitleBlock>
          <AppButton
            label={lang.SAVE_STATISTICS}
            color="text-additional"
            iconName="return"
            loading={this.state.loadingStatistic}
            className={b('statistic-button')}
            onClick={this.handleUpdateStatistic}
          />
          <button
            className="button button--circle button--bg-13"
            type="button"
            onClick={this.handleOpenModal('atSettingsModal')}
          >
            <Icon name="settings" />
          </button>
        </div>
        <div className="page__block-container">
          <div className="page__block page__block--size-4">
            <GamesCard title={lang.GAMES} autotaskId={id} />
            <AutotaskManagementCard autotask={autotask} fetchAutotask={this.getMyAutotask} />
            <AutotaskCard
              title={lang.SCENARIO_CARD_TITLE}
              emptyText={lang.SCENARIO_CARD_EMPTY}
              header={
                <button
                  className={'button pull-right button--bg-5' + (archived ? ' hidden' : '')}
                  onClick={this.handleOpenModal('newScenarioModal')}
                >
                  <Icon name="plus" className="button__icon" />
                  <span>{lang.ADD_SCENARIO_BUTTON}</span>
                </button>
              }
              showPagination={false}
            >
              {scenarios && scenarios.length > 0 && (
                <ScenarioList scenarios={scenarios} changeScenarioActiveState={this.changeScenarioActiveState} />
              )}
            </AutotaskCard>
          </div>
          <div className="page__block page__block--size-7 theme-color-8">
            <AutotaskInterfaces
              autotaskId={id}
              clientId={clientId}
              companiesId={companiesId}
              operationId={operationId}
              archived={archived}
            />
          </div>
          <div className="page__block page__block--size-2">
            <div className="page__block--size-2-color-9 theme-color-9">
              <ImagePanel
                id={id}
                kind="automation_task"
                imageUrl={url}
                className=""
                language={langImage}
                callback={this.getMyAutotask}
              />
            </div>
            <div className="theme-color-9">
              <AutotaskPlans autotaskId={id} archived={archived} autotask={autotask} />
            </div>
            <div className="theme-color-10">
              <AutotaskCampaigns autotaskId={id} archived={archived} autotask={autotask} />
            </div>
            <button className={b('winners-link')} type="button" onClick={this.goToWinnersTablePage}>
              {lang.GO_TO_WINNERS}
            </button>
            <div className="theme-color-9">
              <ParticipantsExportHistoryCard
                clientId={companiesId}
                operationId={this.props.operationId}
                taskId={this.props.id}
                isDashboard={false}
              />
            </div>
            <div className="theme-color-9">
              <ResultGraphsCard autotaskId={id} clientId={clientId} operationId={operationId} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  autotask: state.autotask.payload,
  lang: state.languageState.payload.AUTOTASK,
  langImage: state.languageState.payload.IMAGE_CONTAINER,
});

const mapDispatchToProps = {
  clearAutotaskState,
  getAutotask,
  patchAutotask,
  deleteAutotask,
  updateScenario,
  setBreadcrumbs,
  clearBreadcrumbs,
  updateStatistic,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Autotask));
