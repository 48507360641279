import get from 'lodash/get';

export function selectClientUsers(state, clientId) {
  const clientUsers = get(state.clientUsers, 'clientUsers');
  return (
    clientUsers &&
    clientUsers.map((user) => {
      return {
        ...user,
        currentMembership: get(
          user.memberships.find((membership) => +membership.client_id === +clientId),
          'id',
          '',
        ),
      };
    })
  );
}

/**
 *
 * @return {import('client/models/regions/types').Region[]}
 */
export function selectRegions(state) {
  return state.clientUsers.regions;
}

/**
 *
 * @return {import('client/models/memberships/types').Membership[]}
 */
export function selectMemberships(state) {
  return state.clientUsers.memberships;
}

export const selectAvailableForAttributionUsers = (state) => {
  return state.clientUsers.availableForAttributionUsers;
};
