import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, change as changeField, untouch, reset, initialize} from 'redux-form';

import {mapSelectList} from 'client/services/helpers';

import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';

const preChosenValues = {
  poc_admin_user_id: {value: '0', label: "Doesn't matter"},
  no_operations: {value: '0', label: "Doesn't matter"},
};

let filterNewProps = preChosenValues;

class ClientsFiltersModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    changeField: PropTypes.func.isRequired,
    untouch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    adminsList: PropTypes.object.isRequired,
    clientsList: PropTypes.object.isRequired,
  };

  static defaultProps = {
    show: false,
  };

  static formName = 'ClientsFiltersModalForm';

  constructor(props) {
    super(props);
    this.LANGUAGE = props.languageState.payload.CLIENTS_LIST;

    this.state = {
      operationNumDisabled: true,
    };
  }

  componentDidUpdate(prevProps) {
    const {filter} = this.props;

    if (filter && filter !== prevProps.filter) {
      filterNewProps = {...filter};

      if (!filterNewProps.poc_admin_user_id) {
        filterNewProps.poc_admin_user_id = preChosenValues.poc_admin_user_id;
      }

      if (!filterNewProps.no_operations) {
        filterNewProps.no_operations = preChosenValues.no_operations;
      } else if (filterNewProps.no_operations && filterNewProps.no_operations.value !== '0') {
        this.setState({operationNumDisabled: false});
      }
    }
  }

  closeFilter = () => {
    this.props.onClose();
  };

  save = (data, e, field, value) => {
    const {onFilterChange, initialValues} = this.props;
    let operationNum = data.no_operations_num;
    // reset radiobutton
    if (initialValues[field] === value) {
      delete data[field];
    }

    if (operationNum && !/^[\d]*$/.test(operationNum)) {
      this.props.changeField(ClientsFiltersModal.formName, 'no_operations_num', operationNum.replace(/\D/g, ''));
      return;
    }

    if (data.no_operations && data.no_operations.value !== '0') {
      this.setState({operationNumDisabled: false});
    } else {
      this.setState({operationNumDisabled: true});
    }

    const mappedData = this.mapFilterData(data);
    onFilterChange(mappedData);
  };

  handleNoOperationChange = (e, {value}) => {
    if (!value) {
      this.props.changeField(ClientsFiltersModal.formName, 'no_operations_num', '');
      this.props.untouch(ClientsFiltersModal.formName, 'no_operations_num');
    }
  };

  mapFilterData(data) {
    let obj = Object.assign({}, data);

    if (obj.no_operations && obj.no_operations.value) {
      obj = Object.assign({}, obj, {no_operations: obj.no_operations});
    } else if (data.no_operations && data.no_operations.value === '0') {
      delete obj.no_operations;
    }

    if (obj.poc_admin_user_id && obj.poc_admin_user_id.value) {
      obj = Object.assign({}, obj, {poc_admin_user_id: obj.poc_admin_user_id});
    } else if (obj.poc_admin_user_id && obj.poc_admin_user_id.value === '0') {
      delete obj.poc_admin_user_id;
    }
    return obj;
  }

  render() {
    const {handleSubmit, onClose, adminsList, clientsList} = this.props;

    const contactList = mapSelectList(adminsList.payload.data, 'full_name');
    contactList.unshift({value: '0', label: this.LANGUAGE.FILTER_MODAL_NO_MATTER_VALUE});
    const totalItems = clientsList.payload.meta ? clientsList.payload.meta.total_count : 0;
    return (
      <Modal
        show={this.props.show}
        onClose={this.closeFilter}
        dialogClassName="modal-window--width-1 modal-window--theme-1"
        title={this.LANGUAGE.FILTER_MODAL_TITLE}
      >
        <form
          onSubmit={handleSubmit(this.save)}
          onChange={() => setTimeout(handleSubmit((params) => this.save(params)))}
        >
          <div className="form-field-wrap form-field-wrap--margin-1">
            <Field
              cssModifier="select--no-min-height"
              label={this.LANGUAGE.FILTER_MODAL_PERSON_LABEL}
              name="poc_admin_user_id"
              type="select"
              onChange={() => setTimeout(handleSubmit((params) => this.save(params)))}
              searchable={false}
              options={contactList}
              component={fieldTemplate}
              icon={<span className="icon-checked select__option-icon" />}
            />
          </div>
          <div className="form-field-wrap form-field-wrap--margin-1">
            <div className="form-field-wrap__group-title">{this.LANGUAGE.FILTER_MODAL_NO_OPERATIONS_LABEL}</div>
            <div className="form-field-wrap__fields">
              <div className="form-field-wrap__field form-field-wrap__field--size-3">
                <Field
                  cssModifier="select--no-min-height"
                  name="no_operations"
                  onChange={(e, val) =>
                    setTimeout(
                      handleSubmit((params) => {
                        this.save(params);
                        this.handleNoOperationChange(e, val);
                      }),
                    )
                  }
                  type="select"
                  searchable={false}
                  options={[
                    {
                      value: '0',
                      label: this.LANGUAGE.FILTER_MODAL_NO_MATTER_RADIO,
                    },
                    {
                      value: 'gt',
                      label: this.LANGUAGE.FILTER_MODAL_MORE_THAN_VALUE,
                    },
                    {
                      value: 'lt',
                      label: this.LANGUAGE.FILTER_MODAL_LESS_THAN_VALUE,
                    },
                  ]}
                  icon={<span className="icon-checked select__option-icon" />}
                  component={fieldTemplate}
                />
              </div>
              <div className="form-field-wrap__field form-field-wrap__field--size-1">
                <Field
                  cssModifier="input--no-min-height"
                  name="no_operations_num"
                  disabled={this.state.operationNumDisabled}
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className="form-field-wrap__add-text">{this.LANGUAGE.FILTER_MODAL_OPERATIONS_TEXT}</div>
            </div>
          </div>
          <div className="form-field-wrap form-field-wrap--margin-1">
            <div className="form-field-wrap__group-title">{this.LANGUAGE.FILTER_MODAL_STATUS_LABEL}</div>
            <div className="form-field-wrap__group filters__items">
              <Field
                cssModifier="filters__item filters__item--view-3 radio-button--switcher-theme-1"
                label={this.LANGUAGE.FILTER_MODAL_AGENCY_RADIO}
                name="type"
                type="radio"
                value="Agency"
                radioType="switcher-2"
                onClick={(proxy, e) => setTimeout(handleSubmit((params) => this.save(params, e, 'type', 'Agency')))}
                component={fieldTemplate}
              />
              <Field
                cssModifier="filters__item filters__item--view-3 radio-button--switcher-theme-1"
                label={this.LANGUAGE.FILTER_MODAL_CLIENT_RADIO}
                name="type"
                type="radio"
                value="Company"
                onClick={(proxy, e) => setTimeout(handleSubmit((params) => this.save(params, e, 'type', 'Company')))}
                radioType="switcher-2"
                component={fieldTemplate}
              />
            </div>
          </div>
          <div className="form-field-wrap form-field-wrap--margin-1">
            <div className="form-field-wrap__group-title">{this.LANGUAGE.FILTER_MODAL_STORES_LABEL}</div>
            <div className="form-field-wrap__group filters__items">
              <Field
                cssModifier="filters__item filters__item--view-3 radio-button--switcher-theme-1"
                label={this.LANGUAGE.FILTER_MODAL_WITH_STORES_RADIO}
                name="stores"
                value="true"
                type="radio"
                onClick={(proxy, e) => setTimeout(handleSubmit((params) => this.save(params, e, 'stores', 'true')))}
                radioType="switcher-2"
                component={fieldTemplate}
              />
              <Field
                cssModifier="filters__item filters__item--view-3 radio-button--switcher-theme-1"
                label={this.LANGUAGE.FILTER_MODAL_NO_STORES_RADIO}
                name="stores"
                value="false"
                type="radio"
                onClick={(proxy, e) => setTimeout(handleSubmit((params) => this.save(params, e, 'stores', 'false')))}
                radioType="switcher-2"
                component={fieldTemplate}
              />
            </div>
          </div>
          <div className="form-field-wrap form-field-wrap--margin-1">
            <div className="form-field-wrap__group-title">{this.LANGUAGE.FILTER_MODAL_COMMENTS_LABEL}</div>
            <div className="form-field-wrap__group filters__items">
              <Field
                cssModifier="filters__item filters__item--view-3 radio-button--switcher-theme-1"
                label={this.LANGUAGE.FILTER_MODAL_WITH_COMMENTS_RADIO}
                name="comments"
                type="radio"
                onClick={(proxy, e) => setTimeout(handleSubmit((params) => this.save(params, e, 'comments', '1')))}
                value="1"
                radioType="switcher-2"
                component={fieldTemplate}
              />
              <Field
                cssModifier="filters__item filters__item--view-3 radio-button--switcher-theme-1"
                label={this.LANGUAGE.FILTER_MODAL_NO_COMMENTS_RADIO}
                name="comments"
                value="0"
                type="radio"
                onClick={(proxy, e) => setTimeout(handleSubmit((params) => this.save(params, e, 'comments', '0')))}
                radioType="switcher-2"
                component={fieldTemplate}
              />
            </div>
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-1 modal-window__footer-btn" type="submit" onClick={onClose}>
              {`${this.LANGUAGE.FILTER_MODAL_SHOW_RESULTS_BUTTON} (${totalItems})`}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const ClientsFiltersModalForm = reduxForm({
  form: ClientsFiltersModal.formName,
  enableReinitialize: true,
})(ClientsFiltersModal);

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  initialValues: filterNewProps,
  clientsList: state.clientsList,
});

const mapDispatchToProps = {
  changeField,
  untouch,
  reset,
  initialize,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsFiltersModalForm);
