import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getLeadHistories} from 'client/ducks/lead-histories/actions';
import {selectLeadHistories, selectLeadHistoriesMeta} from 'client/ducks/lead-histories/selectors';

import TitleBlock from 'client/components/common/title-block';

import LeadsProfileDataGrid from '../../components/tables/leads-profile-data-grid/leads-profile-data-grid';

class LeadHistories extends ReactQueryParams {
  static propTypes = {
    ...withRouter.propTypes,
    languageState: PropTypes.object,
    getLeadHistories: PropTypes.func,
    setLeadHistoriesTotal: PropTypes.func,
    leadHistories: PropTypes.array,
    leadHistoriesTotal: PropTypes.number,
    leadId: PropTypes.string.isRequired,
    meta: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.LEADS_PROFILE;
    this.state = {
      page: LeadHistories.DEFAULT_FIRST_PAGE,
      perPage: LeadHistories.DEFAULT_PER_PAGE,
      sortField: LeadHistories.DEFAULT_SORT_FIELD,
      sort: {
        name: '',
        order: '',
      },
      search: '',
      showClear: false,
      searchDefault: '',
      filter: LeadHistories.initialValuesForModal,
    };
  }

  static initialValuesForModal = {
    participated: 'false',
    filters: {
      participation: false,
      opt_in: false,
      update: false,
      export: false,
      import: false,
      visual: false,
      participatedFrom: '',
      participatedTo: '',
      interfacesNames: {value: '0'},
      userId: {value: '0'},
    },
  };

  static DEFAULT_PER_PAGE = 5;

  static DEFAULT_FIRST_PAGE = 1;

  static DEFAULT_SORT_FIELD = 'created_at';

  componentDidMount() {
    this.fetchHistories();
  }

  fetchHistories = () => {
    const {queryParams: params} = this;
    if (!this.props.urlParams.page) {
      this.props.history.replace(`${Number(this.props.leadId)}?page=${this.state.page}&perPage=${this.state.perPage}`);
    }

    let request = {
      include: {
        user: null,
        source: ['automation_task', 'operation'],
        lead_history_items: {
          column_adapter: 'column',
        },
      },
      q: {
        lead_id_eq: Number(this.props.leadId),
        user_id_eq:
          params.filter && params.filter.filters.userId.value !== '0' ? params.filter.filters.userId.value : '',
        interface_id_eq:
          params.filter && params.filter.filters.interfacesNames.value !== '0'
            ? params.filter.filters.interfacesNames.value
            : '',
        source_interaction_id_in: [1, 2, 3],
        s: `${(params.sort && params.sort.name) || this.state.sortField} ${params.sort && params.sort.order}`,
      },

      page: params.page || this.state.page,
      per_page: params.perPage || this.state.perPage,
      sort: params.sort || {},
    };

    // add filters to request
    if (params.filter && params.filter.filters) {
      const filters = Object.keys(params.filter.filters);
      const kind_in = filters.filter((el) => {
        return params.filter.filters[el] === true;
      });
      if (kind_in.length) {
        request.q.kind_in = kind_in;
      }
      // add date filters to request
      if (params.filter.participated === 'true') {
        if (params.filter.filters.participatedFrom) {
          // convert time to UTC
          request.q.created_at_gteq = moment(params.filter.filters.participatedFrom, 'DD/MM/YYYY').toISOString();
        }
        if (params.filter.filters.participatedTo) {
          request.q.created_at_lteq = moment(params.filter.filters.participatedTo, 'DD/MM/YYYY').toISOString();
        }
      }
    }

    this.props.getLeadHistories(request);
  };

  onPageChange = (params) => {
    this.setQueryParams(params);
    this.setState(
      {
        ...this.state,
        filter: this.state.filter,
      },
      () => this.fetchHistories(),
    );
  };

  onSearchChange = (search) => {
    if (search) {
      this.setQueryParams({search});
      this.fetchHistories(search);
    }
  };

  onSearchClear = () => {
    this.setQueryParams({search: ''});
    this.fetchHistories();
  };

  onSortChange = ({sortField, sortOrder}) => {
    this.setQueryParams({sort: {name: sortField, order: sortOrder}}, true);
    this.fetchHistories();
  };

  onFilterChange = (filter) => {
    this.setQueryParams({filter: filter, page: LeadHistories.DEFAULT_FIRST_PAGE}, true);
    this.setState(
      {
        ...this.state,
        filter,
      },
      () => this.fetchHistories(),
    );
  };

  onFilterClear = () => {
    this.onFilterChange({...LeadHistories.initialValuesForModal});
  };

  onFilterFieldClear = (field) => {
    let value;
    switch (field) {
      case 'participatedFrom':
      case 'participatedTo':
        value = '';
        break;
      case 'userId':
      case 'interfacesNames':
        value = {value: '0'};
        break;
      default:
        value = false;
    }

    const obj = {...this.state.filter, filters: {...this.state.filter.filters, [field]: value}};
    this.onFilterChange(obj);
  };

  onFilterResetData = () => {
    const obj = {
      ...this.state.filter,
      filters: {...this.state.filter.filters, participatedTo: '', participatedFrom: ''},
    };
    this.onFilterChange(obj);
  };

  render() {
    const {leadHistories, leadHistoriesMeta} = this.props;
    return (
      <div>
        <TitleBlock theme={true}>
          <TitleBlock.Item>{this.LANGUAGE.TITLE_HISTORY}</TitleBlock.Item>
        </TitleBlock>
        <LeadsProfileDataGrid
          data={leadHistories}
          meta={{
            current_page: leadHistoriesMeta.current_page,
            total_pages: leadHistoriesMeta.total_pages,
            total_count: leadHistoriesMeta.total_count,
          }}
          leadId={this.props.leadId}
          onPageChange={this.onPageChange}
          perPage={Number(this.queryParams.perPage) || LeadHistories.DEFAULT_PER_PAGE}
          onSearchChange={this.onSearchChange}
          onSearchClear={this.onSearchClear}
          showClear={this.state.showClear}
          searchDefault={this.state.searchDefault}
          onSortChange={this.onSortChange}
          onFilterChange={this.onFilterChange}
          onFilterClear={this.onFilterClear}
          onFilterFieldClear={this.onFilterFieldClear}
          onFilterResetData={this.onFilterResetData}
          filter={this.state.filter}
          initialValuesForModal={LeadHistories.initialValuesForModal}
          sort={this.queryParams.sort}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    languageState: state.languageState,
    leadHistories: selectLeadHistories(state),
    leadHistoriesMeta: selectLeadHistoriesMeta(state),
  }),
  {getLeadHistories},
)(withRouter(LeadHistories));
