import {blobToBase64} from 'client/services/blobToBase64';
import {fetchFile} from 'client/services/helpers';

type modifierCallbackType = (img: HTMLImageElement, canvas: HTMLCanvasElement) => void;

export const editImage = async (src: string, modifierCallback: modifierCallbackType): Promise<string> => {
  const base64 = src.startsWith('data:image')
    ? src
    : await fetchFile(src)
        .then((res) => res.blob())
        .then(blobToBase64);

  return new Promise((resolve, reject) => {
    const img = document.createElement('img');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      if (!ctx) {
        reject('No canvas context');
        return;
      }

      modifierCallback(img, canvas);

      resolve(canvas.toDataURL());
    };

    img.onerror = () => {
      reject('Error loading image');
    };

    img.crossOrigin = 'anonymous';
    img.src = base64;
  });
};

export const flipImageHorizontally = (
  img: HTMLImageElement,
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
) => {
  ctx.translate(canvas.width, 1);
  ctx.scale(-1, 1);
  ctx.drawImage(img, 0, 0);
};

export const flipImageVertically = (
  img: HTMLImageElement,
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
) => {
  ctx.translate(1, canvas.height);
  ctx.scale(1, -1);
  ctx.drawImage(img, 0, 0);
};

export const rotateImage = (
  img: HTMLImageElement,
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  angle: number,
) => {
  canvas.width = img.height;
  canvas.height = img.width;
  const dx = canvas.width / 2;
  const dy = canvas.height / 2;
  ctx.translate(dx, dy);
  ctx.rotate((angle * Math.PI) / 180);
  ctx.translate(-dy, -dx);
  ctx.drawImage(img, 0, 0);
};
