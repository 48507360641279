import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {formValueSelector, initialize, change, reset, untouch} from 'redux-form';

import bem from 'client/services/bem';
import {required, maxLength, match} from 'client/services/validator';

import {getInternalColumns} from 'client/ducks/internal-columns/actions';
import {getScenarios} from 'client/ducks/scenario/actions';
import {getParticipationColumnAdapters} from 'client/ducks/scenario/actions';

import {RadioGroupField, TextField, SelectField, CheckboxField} from 'client/common/fields';

import Spinner from 'client/components/common/spinner';

import cssModule from './ats-var-form.module.scss';

const b = bem('ats-var-form', {cssModule});

export const FORM_NAME = 'AtsAddVarModalForm';

export const SOURCE_TYPES = {
  interface: 'InterfaceItem',
  database: 'ColumnAdapter',
  variable: 'Variable',
};

class AtsVarForm extends Component {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    getInternalColumns: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    untouch: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    data: PropTypes.shape({
      source_type: PropTypes.string,
      source: PropTypes.object,
    }),
    formValues: PropTypes.shape({
      source_type: PropTypes.string,
      source_index: PropTypes.object,
      source_id: PropTypes.number,
    }).isRequired,
    getParticipationColumnAdapters: PropTypes.func.isRequired,
    autotaskId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK_SCENARIO.VAR_FORM;
    this.rules = {
      name: [
        required(this.LANGUAGE.NAME_REQUIRED_ERROR),
        maxLength(25, this.LANGUAGE.NAME_MAX_LENGTH_ERROR),
        match(/^\S+$/, this.LANGUAGE.NAME_MATCH_ERROR),
      ],
      source_index: [required(this.LANGUAGE.SOURCE_REQUIRED_ERROR)],
      source: [required(this.LANGUAGE.SOURCE_INDEX_REQUIRED_ERROR)],
      source_id: [required(this.LANGUAGE.SOURCE_ID_REQUIRED_ERROR)],
    };
    this.cachedValues = {
      [SOURCE_TYPES.interface]: {},
      [SOURCE_TYPES.database]: {},
      [SOURCE_TYPES.variable]: {},
    };
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.toggleLoading();

    Promise.all([
      this.props.getInternalColumns({
        q: {
          m: 'and',
          g: [
            {
              m: 'or',
              record_type_not_eq: 'Visual',
              type_eq: 'VisualColumnAdapter',
            },
            {
              m: 'or',
              record_type_not_eq: 'ParticipationPrize',
              type_eq: 'ParticipationPrizeColumnAdapter',
            },
          ],
        },
      }),
      this.props.getParticipationColumnAdapters(this.props.autotaskId),
    ]).then(() => {
      this.toggleLoading();
      this.initialize();
    });
  }

  componentWillUnmount() {
    this.props.reset(FORM_NAME);
  }

  toggleLoading = () => {
    this.setState(({loading}) => ({loading: !loading}));
  };

  initialize = () => {
    const data = {...this.props.data};

    if (this.props.data.source) {
      let compareValue;

      if (this.props.data.source.interface_id) {
        compareValue = data.source.interface_id;
      } else if (this.props.data.source.record_type) {
        compareValue = ['VisualColumnAdapter', 'ParticipationPrizeColumnAdapter'].includes(data.source.type)
          ? data.source.record_type + data.source.db_number
          : data.source.record_type;
      } else if (this.props.data.source.scenario_id) {
        compareValue = data.source.scenario_id;
      } else if (this.props.data.source.scenario_step_id) {
        compareValue = data.source.scenario_step.scenario_id;
      }

      data.source_index = this.props.options[data.source_type].find((v) => v.value === compareValue);
    }

    this.props.initialize(FORM_NAME, data);
  };

  resetSources = (e, type) => {
    const {
      formValues: {source_type, source_index, source_id},
    } = this.props;

    this.cachedValues[source_type] = {
      source_index,
      source_id,
    };

    this.props.change(FORM_NAME, 'source_index', this.cachedValues[type].source_index || null);
    this.props.change(FORM_NAME, 'source_id', this.cachedValues[type].source_id || null);
    this.props.untouch(FORM_NAME, 'source_index', 'source_id');
  };

  resetSourceId = () => {
    this.props.change(FORM_NAME, 'source_id', null);
    this.props.untouch(FORM_NAME, 'source_id');
  };

  getSourceLabel = () => {
    const {
      formValues: {source_type},
    } = this.props;

    switch (source_type) {
      case SOURCE_TYPES.database:
        return this.LANGUAGE.DATABASE_NAME_LABEL;
      case SOURCE_TYPES.variable:
        return this.LANGUAGE.SCENARIO_NAME_LABEL;
      default:
        return this.LANGUAGE.INTERFACE_NAME_LABEL;
    }
  };

  getSourceOptions = () => {
    const {
      options,
      formValues: {source_type},
    } = this.props;

    if (!source_type || !options[source_type]) {
      return [];
    }

    return options[source_type];
  };

  getSourceItemOptions = () => {
    const {
      formValues: {source_index},
    } = this.props;

    if (!source_index) {
      return [];
    }

    return source_index.additionalOptions;
  };

  render() {
    if (this.state.loading) {
      return (
        <div className={b('spinner-wrap')}>
          <Spinner centered />
        </div>
      );
    }

    return (
      <div className={cn(b(), 'theme-color-7')}>
        <TextField className={b('field')} label={this.LANGUAGE.VAR_NAME_LABEL} name="name" validate={this.rules.name} />
        <RadioGroupField
          className={b('field')}
          color="default"
          name="source_type"
          onChange={this.resetSources}
          legend={this.LANGUAGE.TYPE_TITLE}
          options={[
            {value: SOURCE_TYPES.interface, label: this.LANGUAGE.INTERFACE_LABEL},
            {value: SOURCE_TYPES.database, label: this.LANGUAGE.DATABASE_LABEL},
            {value: SOURCE_TYPES.variable, label: this.LANGUAGE.SCENARIO_LABEL},
          ]}
        />
        <div className={b('field')}>
          <div className={b('legend')}>{this.LANGUAGE.SOURCE_TITLE}</div>
          <SelectField
            label={this.getSourceLabel()}
            name="source_index"
            options={this.getSourceOptions()}
            validate={this.rules.source_index}
            onChange={this.resetSourceId}
            searchable={true}
          />
          <SelectField
            simpleValue
            label={this.LANGUAGE.DATA_ITEM_NAME_LABEL}
            name="source_id"
            searchable={true}
            options={this.getSourceItemOptions()}
            validate={this.rules.source_id}
          />
        </div>
        <CheckboxField
          className={b('field')}
          label={this.LANGUAGE.MANDATORY_LABEL}
          color="default"
          name="required"
          type="checkbox"
        />
      </div>
    );
  }
}

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  options: state.scenario.scenario_variable_options,
  formValues: {
    source_type: selector(state, 'source_type'),
    source_index: selector(state, 'source_index'),
    source_id: selector(state, 'source_id'),
  },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInternalColumns,
      getScenarios,
      getParticipationColumnAdapters,
      change,
      reset,
      untouch,
      initialize,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AtsVarForm);
