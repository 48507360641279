import React, {PureComponent} from 'react';

import flow from 'lodash/flow';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, Field, getFormMeta} from 'redux-form';

import {
  formatToString,
  normalizeStringToBoolean,
  formatToSelectOption,
  normalizeToSelectOption,
} from 'client/services/formatters';
import {required, number, datetime, lessThan, greaterThan, dateTimeIsAfter} from 'client/services/validator';

import {TIME_UNITS} from 'client/common/config';

import fieldTemplate from 'client/components/common/field';

import {TASK_TYPES} from 'client/models/operations/constants';

import BCSettingsStepPopover from '../../popovers/bc-settings-step-popover';

import '../bc-step.scss';

class BCSettingsStep extends PureComponent {
  static propTypes = {
    fields: PropTypes.object,
    senders: PropTypes.array.isRequired,
    taskType: PropTypes.string.isRequired,
    languageState: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    untouch: PropTypes.func.isRequired,
    timerInitialValues: PropTypes.object.isRequired,
    pristine: PropTypes.bool.isRequired,
    disabledIfNotNew: PropTypes.bool.isRequired,
  };

  static formName = 'BCSettingsStepForm';

  static timerKinds = {
    at: {
      name: 'at',
      localeKey: 'AT',
    },
    in: {
      name: 'in',
      localeKey: 'IN',
    },
  };

  static limits = {
    max: 9999999,
    min: 0,
  };

  static getNumberError = (value, errors) => {
    return (
      required(errors.REQUIRED)(value) ||
      number(errors.NUMBER)(value) ||
      lessThan(BCSettingsStep.limits.max, `${errors.NO_MORE_THAN} ${BCSettingsStep.limits.max}`, true)(value) ||
      greaterThan(BCSettingsStep.limits.min, errors.POSITIVE)(value)
    );
  };

  static validate = (values, props) => {
    const {
      batch_size,
      message_sender_id,
      reply_to_id,
      perform_at,
      perform_in,
      send_in_batches,
      time_between_batches,
      timer_kind,
    } = values;

    const errors = {};
    const ERRORS = props.languageState.payload.BROADCASTING_TASK.SETTINGS_STEP.ERRORS;

    errors.batch_size = send_in_batches && BCSettingsStep.getNumberError(batch_size, ERRORS);
    errors.time_between_batches = send_in_batches && BCSettingsStep.getNumberError(time_between_batches, ERRORS);

    switch (timer_kind) {
      case BCSettingsStep.timerKinds.in.name:
        errors.perform_in = BCSettingsStep.getNumberError(perform_in, ERRORS);
        break;
      case BCSettingsStep.timerKinds.at.name:
        errors.perform_at =
          required(ERRORS.REQUIRED)(perform_at) ||
          datetime(ERRORS.INCORRECT_FORMAT)(perform_at) ||
          (props.fields.perform_at &&
            props.fields.perform_at.touched &&
            dateTimeIsAfter(`${ERRORS.DATE_LATER} ${moment().format('DD/MM/YYYY HH:mm')}`, 'perform_at')(perform_at));
        break;
      default:
      //
    }

    errors.message_sender_id = required(ERRORS.REQUIRED)(message_sender_id);
    errors.reply_to_id = required(ERRORS.REQUIRED)(reply_to_id);

    return errors;
  };

  constructor(props) {
    super(props);

    const payload = props.languageState.payload;
    this.LANGUAGE = payload.BROADCASTING_TASK.SETTINGS_STEP;
    this.LANGUAGE_TIME_UNITS = payload.TIME_UNITS;

    this.sendFreqOptions = [
      {
        value: TIME_UNITS.minutes.name,
        label: this.LANGUAGE_TIME_UNITS[TIME_UNITS.minutes.localeKey],
      },
      {
        value: TIME_UNITS.hours.name,
        label: this.LANGUAGE_TIME_UNITS[TIME_UNITS.hours.localeKey],
      },
      {
        value: TIME_UNITS.days.name,
        label: this.LANGUAGE_TIME_UNITS[TIME_UNITS.days.localeKey],
      },
    ];

    this.performInOptions = [...Array(10)].map((i, index) => ({
      label: index + 1,
      value: index + 1,
    }));
  }

  save = (params) => {
    const {
      perform_at,
      message_sender_id,
      reply_to_id,
      time_unit,
      time_unit_between_batches,
      time_between_batches,
      send_in_batches,
      perform_in,
      batch_size,
      timer_kind,
    } = params;

    const body = {
      message_task: {
        perform_at: timer_kind === BCSettingsStep.timerKinds.at.name ? moment(perform_at).toISOString() : null,
        perform_in: timer_kind === BCSettingsStep.timerKinds.in.name ? perform_in : null,
        time_unit,
        message_sender_id,
        reply_to_id,
        time_unit_between_batches,
        time_between_batches,
        send_in_batches,
        batch_size,
        timer_kind,
      },
    };

    this.props.onSave(body).then(() => this.props.untouch('perform_at'));
  };

  getPopoverContent = () => {
    const {taskType} = this.props;

    switch (taskType) {
      case TASK_TYPES.EMAIL:
        return this.LANGUAGE.POPOVER_EMAIL;
      case TASK_TYPES.SMS:
        return this.LANGUAGE.POPOVER_SMS;
      default:
        return [];
    }
  };

  render() {
    const {senders, handleSubmit, pristine, disabledIfNotNew: disabled, taskType} = this.props;
    const disabledDays = moment().toDate();

    return (
      <div className="bc-step">
        <div className="bc-step__inner">
          <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
            <div className="bc-step__save-btn-container">
              <button type="submit" className="button button--bg-14" disabled={pristine}>
                {this.LANGUAGE.SAVE_BUTTON}
              </button>
            </div>
            <div className="form-field-wrap form-field-wrap--margin-1">
              <div className="field-group">
                <div className="field-group__title theme-color-16">
                  <div className="flex-container flex-align-center">
                    {this.LANGUAGE.GROUPS_LABEL}
                    <BCSettingsStepPopover texts={this.getPopoverContent()} />
                  </div>
                </div>
                <div className="field-group__row">
                  <div className="field-group__field">
                    <Field
                      label={this.LANGUAGE.ALL_LABEL}
                      name="send_in_batches"
                      value="false"
                      type="radio"
                      format={formatToString}
                      normalize={normalizeStringToBoolean}
                      disabled={disabled}
                      component={fieldTemplate}
                    />
                  </div>
                </div>
                <div className="field-group__row field-group__row--vert-top ">
                  <div className="field-group__field">
                    <Field
                      label={this.LANGUAGE.BY_GROUPS_LABEL}
                      name="send_in_batches"
                      value="true"
                      type="radio"
                      format={formatToString}
                      normalize={normalizeStringToBoolean}
                      disabled={disabled}
                      component={fieldTemplate}
                    />
                  </div>
                  <div className="field-group__field field-group__field--size-11">
                    <Field
                      cssModifier="input--no-min-height"
                      name="batch_size"
                      type="text"
                      format={formatToString}
                      disabled={disabled}
                      component={fieldTemplate}
                    />
                  </div>
                  <div className="field-group__field field-group__text field-group__text--view-1">
                    {this.LANGUAGE.LEADS_LABEL}
                  </div>
                  <div className="field-group__field field-group__text field-group__text--view-1">
                    {this.LANGUAGE.EVERY_LABEL}
                  </div>
                  <div className="field-group__field field-group__field--size-11">
                    <Field
                      cssModifier="input--no-min-height"
                      name="time_between_batches"
                      type="text"
                      disabled={disabled}
                      component={fieldTemplate}
                    />
                  </div>
                  <div className="field-group__field field-group__field--size-6">
                    <Field
                      scrollBarProps={{
                        autoHeightMax: 105,
                      }}
                      cssModifier="select--no-min-height"
                      name="time_unit_between_batches"
                      type="select"
                      searchable={false}
                      format={formatToSelectOption}
                      normalize={normalizeToSelectOption}
                      component={fieldTemplate}
                      disabled={disabled}
                      options={this.sendFreqOptions}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-field-wrap form-field-wrap--margin-1">
              <div className="field-group">
                <div className="field-group__title theme-color-16">{this.LANGUAGE.START_LABEL}</div>
                <div className="field-group__row field-group__row--vert-top">
                  <div className="field-group__field">
                    <Field
                      label={this.LANGUAGE.IN_LABEL}
                      name="timer_kind"
                      value={BCSettingsStep.timerKinds.in.name}
                      type="radio"
                      disabled={disabled}
                      component={fieldTemplate}
                    />
                  </div>
                  <div className="field-group__field field-group__field--size-11">
                    <Field
                      cssModifier="input--no-min-height perform-in-select"
                      name="perform_in"
                      type="select"
                      options={this.performInOptions}
                      simpleValue
                      disabled={disabled}
                      component={fieldTemplate}
                    />
                  </div>
                  <div className="field-group__field field-group__field--size-6">
                    <Field
                      scrollBarProps={{
                        autoHeightMax: 105,
                      }}
                      cssModifier="select--no-min-height"
                      name="time_unit"
                      type="select"
                      searchable={false}
                      component={fieldTemplate}
                      format={formatToSelectOption}
                      normalize={normalizeToSelectOption}
                      disabled={disabled}
                      options={this.sendFreqOptions}
                    />
                  </div>
                </div>
                <div className="field-group__row field-group__row--vert-top">
                  <div className="field-group__field">
                    <Field
                      label={this.LANGUAGE.ON_LABEL}
                      name="timer_kind"
                      value={BCSettingsStep.timerKinds.at.name}
                      type="radio"
                      disabled={disabled}
                      component={fieldTemplate}
                    />
                  </div>
                  <Field
                    name="perform_at"
                    type="datetimepicker"
                    disabledDayBefore={disabledDays}
                    labelBlock={true}
                    disabled={disabled}
                    component={fieldTemplate}
                  />
                </div>
              </div>
            </div>
            <div className="form-field-wrap form-field-wrap--margin-1">
              <div className="field-group__field field-group__field--size-9">
                <Field
                  simpleValue={true}
                  label={this.LANGUAGE.SENDER_LABEL}
                  cssModifier="select--no-min-height"
                  name="message_sender_id"
                  type="select"
                  options={senders}
                  searchable={false}
                  valueKey="id"
                  labelKey="name"
                  disabled={disabled}
                  component={fieldTemplate}
                />
              </div>
            </div>
            {taskType === TASK_TYPES.EMAIL && (
              <div className="form-field-wrap form-field-wrap--margin-1">
                <div className="field-group__field field-group__field--size-9">
                  <Field
                    simpleValue
                    label={this.LANGUAGE.REPLY_TO_LABEL}
                    cssModifier="select--no-min-height"
                    name="reply_to_id"
                    type="select"
                    options={senders}
                    searchable={false}
                    valueKey="id"
                    labelKey="name"
                    disabled={disabled}
                    component={fieldTemplate}
                  />
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const now = moment();

  const performAt =
    ownProps.timerInitialValues.perform_at ||
    moment({
      year: now.get('year'),
      month: now.get('month'),
      date: now.get('date'),
      hours: now.get('hours'),
      minutes: now.get('minutes'),
      seconds: 0,
    });

  return {
    initialValues: {
      ...ownProps.timerInitialValues,
      perform_at: performAt,
    },
    languageState: state.languageState,
    fields: getFormMeta(BCSettingsStep.formName)(state),
  };
};

export default flow([
  reduxForm({
    form: BCSettingsStep.formName,
    touchOnBlur: false,
    touchOnChange: true,
    enableReinitialize: true,
    validate: BCSettingsStep.validate,
  }),
  connect(mapStateToProps),
])(BCSettingsStep);
