import {Game} from 'client/models/prizes/types';

import {DrawParametersValues} from './types';

export default (data?: Game): DrawParametersValues => {
  return {
    draw_id: data?.game_draws?.[0].id,
    name: data?.name || '',
    draw_moment: data?.game_draws?.[0].default_draw_time || '',
    from: data?.game_draws?.[0].default_from || '',
    to: data?.game_draws?.[0].default_to || '',
    send_email: data?.game_draws?.[0].loss_email || false,
    send_sms: data?.game_draws?.[0].loss_sms || false,
    device_segmentation: data?.geo_level || '',
    online_segmentation: data?.online_level || '',
  };
};
