import React from 'react';

import PropTypes from 'prop-types';

function StatusColumn(props) {
  const {event} = props;

  return (
    <div className="text-center">
      <div
        className="status-icon"
        style={{
          color: StatusColumn.pickStateColor(event.status),
        }}
      />
      {event.date && <div className="main-text">{event.date}</div>}
      {event.time && <div className="main-text main-text--small main-text--color-1">{event.time}</div>}
    </div>
  );
}

StatusColumn.pickStateColor = (status) => {
  const BROADCAST_STATUS_COLORS = {
    SUCCESS: '#0EAA58',
    ERROR: '#D23C53',
    DEFAULT: '#879397',
  };

  switch (status) {
    case true:
      return BROADCAST_STATUS_COLORS.SUCCESS;
    case false:
      return BROADCAST_STATUS_COLORS.ERROR;
    default:
      return BROADCAST_STATUS_COLORS.DEFAULT;
  }
};

StatusColumn.propTypes = {
  event: PropTypes.shape({
    status: PropTypes.bool,
    date: PropTypes.string,
    time: PropTypes.string,
  }).isRequired,
};

export default StatusColumn;
