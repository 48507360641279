import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {doesValueExist} from 'client/services/helpers';

import {isVarOrFix, isVar, DESTINATION_TYPES} from './helpers';

import LabelStep from '../label-step';

class CalculationScenarioStep extends Component {
  static propTypes = {
    step: PropTypes.object,
    variableValues: PropTypes.object,
    lang: PropTypes.object.isRequired,
  };

  static defaultProps = {
    step: {},
    variableValues: {},
  };

  renderTypeLabel = ({component_type, number}) => {
    const {lang} = this.props;

    return (
      <LabelStep
        label={`${lang.TYPE} ${number}`}
        value={`${isVar(component_type) ? lang.COMPONENT_TYPES_LABEL.VARIABLES : lang.COMPONENT_TYPES_LABEL.FIXED}`}
      />
    );
  };

  render() {
    const {step, variableValues, lang} = this.props;
    const {implementation} = step;
    const isCalcalationStepComponents = doesValueExist(implementation.calculation_step_components);
    const isDatabase = DESTINATION_TYPES.VARIABLE !== implementation.destination_type;
    const considerEmptyValues = implementation.consider_empty_values ? lang.CONSIDER_EMPTY_ON : lang.CONSIDER_EMPTY_OFF;

    return (
      <div className="calculation-scenario-step">
        {/* TODO:  only Database destination is available in Version 1, remove isDatabase */}
        {isDatabase && (
          <div>
            <LabelStep label={lang.DESTIONATION_TYPE} value={implementation.destination_type} />
            <LabelStep label={lang.DESTIONATION_NAME} value={step.name} />
            {isCalcalationStepComponents &&
              implementation.calculation_step_components
                .sort((a, b) => a.number - b.number)
                .map((item) => (
                  <div key={item.id}>
                    {isVarOrFix(item.component_type) && this.renderTypeLabel(item)}
                    <LabelStep
                      label={`${isVarOrFix(item.component_type) ? lang.COMPONENT : lang.OPERATION} ${item.number}`}
                      value={isVar(item.component_type) ? variableValues[item.variable_id] : item.value}
                    />
                  </div>
                ))}
            <LabelStep label={lang.CONSIDER_EMPTY_OFF_TITLE} value={considerEmptyValues} />
            <LabelStep label={lang.COMMENT_LABEL} value={step.comment} />
          </div>
        )}
      </div>
    );
  }
}

export default connect((state) => ({
  lang: state.languageState.payload.AUTOTASK.SCENARIO_RUN_TABLE.RESULT_MODAL,
}))(CalculationScenarioStep);
