import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getQueryParams, transformDate} from 'client/services/helpers';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getClientsAction, getOperationsAction} from 'client/ducks/operations/actions';

import {OPERATIONS_TYPES, CLIENT_PAGES} from 'client/common/config';

import TitleBlock from 'client/components/common/title-block';

import EditFiltersModal from './components/modals/operations-filters-modal';
import OperationsTable from './components/tables/operations-table';

const trimString = (str, totalLenght) => str.slice(0, totalLenght);

class OperationsContainer extends ReactQueryParams {
  static propTypes = {
    operations: PropTypes.shape({
      operations: PropTypes.array,
      clients: PropTypes.array,
      needToUpdateOperations: PropTypes.bool,
      meta: PropTypes.object,
    }).isRequired,
    userState: PropTypes.shape({
      user: PropTypes.object,
    }).isRequired,

    getOperations: PropTypes.func.isRequired,
    getClients: PropTypes.func.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  static DEFAULT_SORT_NAME = 'name';
  static DEFAULT_SORT_ORDER = 'ASC';
  static DEFAULT_PAGE = 1;
  static DEFAULT_PER_PAGE = 5;
  static MAX_SEARCH_LENGTH = 20;

  constructor(props) {
    super(props);

    this.state = {
      showFiltersModal: false,
      showOnlyMyOperations: false,

      search: '',
      showClear: false,
      filters: {},
    };

    this.LANGUAGE = props.languageState.payload.OPERATIONS;
  }

  static dateTypesMap = {
    till: 'till',
    from: 'from',
  };

  componentDidMount() {
    const {BREADCRUMBS} = this.props.languageState.payload;
    this.props.setBreadcrumbs([
      {
        href: CLIENT_PAGES.VISUALS,
        name: BREADCRUMBS.VISUALS,
      },
      {
        name: BREADCRUMBS.CLIENTS,
        href: CLIENT_PAGES.CLIENTS_LIST,
      },
      {
        name: BREADCRUMBS.OPERATIONS,
      },
    ]);

    const intervalID = setInterval(() => {
      if (this.props.userState.type === 'USER_LOADED') {
        clearInterval(intervalID);
        this.getMyOperations();
      }
    });

    this.setState({showOnlyMyOperations: this.props.location.pathname.endsWith('/my')});
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState(
        {
          showOnlyMyOperations: this.props.location.pathname.endsWith('/my'),
          filters: {},
        },
        this.getMyOperations,
      );
    }
  }

  getMyOperations = () => {
    const {queryParams: params} = this;

    let queryParams = getQueryParams({
      include: ['client', 'automation_tasks', 'message_tasks'],
      q: {
        s: `${get(params, 'sort.name', OperationsContainer.DEFAULT_SORT_NAME)} ${get(
          params,
          'sort.order',
          OperationsContainer.DEFAULT_SORT_ORDER,
        )}`,
        name_cont: params.search ? params.search : '',
      },
      page: params.page || OperationsContainer.DEFAULT_PAGE,
      per_page: +params.perPage || OperationsContainer.DEFAULT_PER_PAGE,
    });

    queryParams += get(params, 'filters.client.id') ? `q[client_id_eq]=${params.filters.client.id}&` : '';

    queryParams += this.state.showOnlyMyOperations
      ? `q[client_poc_admin_user_id_eq]=${this.props.userState.payload.user.id}&`
      : '';

    switch (get(params, 'filters.start_date.type')) {
      case OperationsContainer.dateTypesMap.from:
        queryParams += `q[from_gteq]=${transformDate(params.filters.start_date.date, false)}&`;
        break;
      case OperationsContainer.dateTypesMap.till:
        queryParams += `q[from_lteq]=${transformDate(params.filters.start_date.date, false)}&`;
        break;
      default:
        break;
    }

    switch (get(params, 'filters.end_date.type')) {
      case OperationsContainer.dateTypesMap.from:
        queryParams += `q[to_gteq]=${transformDate(params.filters.end_date.date, false)}&`;
        break;
      case OperationsContainer.dateTypesMap.till:
        queryParams += `q[to_lteq]=${transformDate(params.filters.end_date.date, false)}&`;
        break;
      default:
        break;
    }

    queryParams += get(params, 'filters.status') ? `q[status_eq]=${params.filters.status}&` : '';

    if (params.broadcast_list_id) {
      queryParams += `q[message_tasks_broadcast_list_id_eq]=${params.broadcast_list_id}&`;
      queryParams += 'distinct=true&';
    }
    window.scrollTo(0, 0);
    return this.props.getOperations(queryParams);
  };

  onSearchChange = (search) => {
    if (search) {
      const trimmedSearchStr = trimString(search, OperationsContainer.MAX_SEARCH_LENGTH);
      this.setQueryParams({search: trimmedSearchStr, page: OperationsContainer.DEFAULT_PAGE}, true);
      this.setState(
        {
          search: trimmedSearchStr,
          showClear: true,
        },
        this.getMyOperations,
      );
    }
  };

  onSearchClear = () => {
    this.setQueryParams({search: ''}, true);
    this.setState({search: '', showClear: false}, this.getMyOperations);
  };

  onPageChange = (params) => {
    this.setQueryParams(params, true);
    this.getMyOperations();
  };

  onSortChange = ({sortField, sortOrder}) => {
    this.setQueryParams(
      {
        sort: {name: sortField, order: sortOrder},
      },
      true,
    );
    this.getMyOperations();
  };

  onEditFiltersClick = () => {
    this.props
      .getClients(this.state.showOnlyMyOperations && this.props.userState.payload.user.id)
      .then(() => this.setState({showFiltersModal: true}));
  };

  onApplyFilters = (filters) => {
    const newFilters = {...filters};
    delete newFilters.length;
    this.setState({filters});
    this.setQueryParams({filters: newFilters, page: OperationsContainer.DEFAULT_PAGE}, true);
    this.getMyOperations();
  };

  onFiltersClear = () => {
    this.onApplyFilters({});
  };

  onFiltersFieldsClear = (fieldsList) => {
    const obj = Object.assign({}, this.state.filters);
    fieldsList.forEach((el) => delete obj[el]);
    this.onApplyFilters(obj);
  };

  render() {
    const {operations, meta, needToUpdateOperations, clients} = this.props.operations;

    const {showOnlyMyOperations} = this.state;

    const {LANGUAGE} = this;

    if (needToUpdateOperations) {
      this.getMyOperations();
    }

    return (
      <div className="theme-color-operations">
        <div className="page__title-block">
          <TitleBlock theme ellipsis="1">
            <TitleBlock.Item>
              {`${this.state.showOnlyMyOperations ? LANGUAGE.MY_OPERATIONS_TITLE : LANGUAGE.OPERATIONS_TITLE} (${get(
                meta,
                'total_count',
                0,
              )})`}
            </TitleBlock.Item>
          </TitleBlock>
        </div>
        <OperationsTable
          operationsType={showOnlyMyOperations ? OPERATIONS_TYPES.MY : OPERATIONS_TYPES.ALL}
          needToUpdateItems={needToUpdateOperations}
          data={operations}
          perPage={Number(this.queryParams.perPage) || OperationsContainer.DEFAULT_PER_PAGE}
          search={this.queryParams.search}
          onSearch={this.onSearchChange.bind(this)}
          onSearchClear={this.onSearchClear}
          showClear={this.state.showClear}
          currentPage={meta.current_page}
          totalPages={meta.total_pages}
          onPageChange={this.onPageChange}
          editFiltersButtonClick={this.onEditFiltersClick}
          clearFiltersButtonClick={this.onFiltersClear}
          deleteFilterClick={this.onFiltersFieldsClear}
          onSortChange={this.onSortChange}
          sort={this.queryParams.sort}
          totalItems={meta.total_count}
          filters={{...this.state.filters, ...this.queryParams.filters}}
        />

        <EditFiltersModal
          show={this.state.showFiltersModal}
          onClose={() => this.setState({showFiltersModal: false})}
          onFilterChange={this.onApplyFilters}
          clients={clients}
          itemsFound={meta.total_count}
          filters={{...this.state.filters, ...this.queryParams.filters}}
        />
      </div>
    );
  }
}

const mapStateToProps = ({operations, languageState, userState}) => ({
  operations,
  userState,
  languageState,
});

const mapDispatchToProps = {
  setBreadcrumbs,
  clearBreadcrumbs,
  getOperations: getOperationsAction,
  getClients: getClientsAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OperationsContainer));
