import types from './types';

export function showError(message) {
  return {
    type: types.SHOW_ERROR,
    errorMessage: message,
  };
}

export function clearError() {
  return {
    type: types.CLEAR_ERROR,
  };
}
