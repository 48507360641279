import React, {useRef} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Bar} from 'react-chartjs-2';

import bem from 'client/services/bem';

import {graphConfig, getTicksConfig} from './configs';

import cssModule from './instore-horizontal-bars-chart.module.scss';

const b = bem('instore-horizontal-bars-chart', {cssModule});

const InstoreHorizontalBarsChart = ({data, className}) => {
  const graphRef = useRef(null);

  const graphWrapperHeight = data.labels.length * 30; // to maintain specific distance between bars in case of overflow

  return (
    <div className={classNames(b(), className)}>
      <div className={b('graph')}>
        <div style={{minHeight: '100%', height: `${graphWrapperHeight}px`}}>
          <Bar data={data} options={graphConfig} ref={graphRef} />
        </div>
      </div>
      <div className={b('ticks')}>
        <Bar
          data={{
            labels: [],
            datasets: data.datasets,
          }}
          options={getTicksConfig(graphRef.current)}
        />
      </div>
    </div>
  );
};

InstoreHorizontalBarsChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.number).isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

InstoreHorizontalBarsChart.defaultProps = {
  className: '',
};

export default InstoreHorizontalBarsChart;
