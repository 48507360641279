import types from './types';

const initialState = {
  instoreTask: null,
  taskNetworks: null,
  accesses: [],
  positions: null,
  kits: null,
  kitMappings: null,
  campaigns: [],
  availableDays: null,
  photos: [],
  monitoringList: null,
  statsFiles: null,
  taskKpis: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_INSTORE_TASK_SUCCESS:
      return {
        ...state,
        instoreTask: action?.payload?.instore_task || initialState.instoreTask,
      };
    case types.GET_TASK_NETWORKS_SUCCESS:
      return {
        ...state,
        taskNetworks: action.payload.instore_task_networks,
      };
    case types.GET_INSTORE_TASK_ACCESSES_SUCCESS:
      return {
        ...state,
        accesses: action?.payload?.instore_task_accesses || initialState.accesses,
      };
    case types.GET_INSTORE_TASK_POSITIONS_SUCCESS:
      return {
        ...state,
        positions: action.payload.instore_task_positions,
      };
    case types.GET_INSTORE_TASK_KITS_SUCCESS:
      return {
        ...state,
        kits: action.payload.instore_kits,
      };
    case types.GET_INSTORE_TASK_KIT_MAPPINGS_SUCCESS:
      return {
        ...state,
        kitMappings: action.payload.instore_kit_mappings,
      };
    case types.GET_INSTORE_TASK_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action?.payload?.interaction_groups || initialState.campaigns,
      };
    case types.GET_INSTORE_TASK_KIT_AVAILABLE_DAYS_SUCCESS:
      return {
        ...state,
        availableDays: action?.payload,
      };
    case types.GET_INSTORE_TASK_ANIMATION_PHOTOS_SUCCESS:
      return {
        ...state,
        photos: action?.payload?.instore_animation_photos,
      };
    case types.GET_INSTORE_TASK_MONITORING_LIST_SUCCESS:
      return {
        ...state,
        monitoringList: action?.payload?.instore_animation_kpis,
      };
    case types.GET_INSTORE_TASK_STATS_FILES_SUCCESS:
      return {
        ...state,
        statsFiles: action?.payload?.instore_stats_files,
      };

    case types.GET_INSTORE_TASK_KPIS_FILES_SUCCESS:
      return {
        ...state,
        taskKpis: action?.payload?.instore_task_kpis,
      };

    default:
      return state;
  }
}
