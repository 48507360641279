import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import Icon from 'client/common/icon';

import {Translation} from 'client/models/language/types';

import cssModule from './no-data.module.scss';

const b = bem('no-data', {cssModule});

type NoDataProps = {
  className?: string;
  iconClassName?: string;
  textClassName?: string;
  text?: Translation;
  showText?: boolean;
  pageCentered?: boolean;
};

const NoData: React.FC<NoDataProps> = (props) => {
  const {className, iconClassName, textClassName, text, showText = true, pageCentered} = props;
  const lang = useLanguage('COMMON.VARIOUS.NO_DATA');

  return (
    <div className={cn(b({'page-centered': pageCentered}), className)}>
      <Icon className={cn(b('icon'), iconClassName)} name="table-no-data" />
      {showText && <p className={cn(b('text'), textClassName)}>{text || lang.DEFAULT_TEXT}</p>}
    </div>
  );
};

export default NoData;
