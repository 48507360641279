import {Placement} from '@floating-ui/react';
import isNull from 'lodash/isNull';

export const setShift = (shiftLeft: number, shiftTop: number) => {
  return {
    name: 'setShift',
    fn({x, y}: {x: number; y: number}) {
      return {
        x: x + shiftLeft,
        y: y + shiftTop,
      };
    },
  };
};

export const getShiftPosition = (config: {
  shiftLeft: number;
  arrowOffset: number;
  position: Placement;
  shiftTop: number;
}) => {
  const {arrowOffset, shiftTop, shiftLeft, position} = config;

  if (arrowOffset !== 0 && shiftLeft === 0 && ['top', 'bottom'].includes(position)) {
    return {
      shiftLeft: 100 - arrowOffset,
      shiftTop: shiftTop,
    };
  }
  if (arrowOffset !== 0 && shiftTop === 0 && ['right', 'left'].includes(position)) {
    return {
      shiftLeft: shiftLeft,
      shiftTop: 100 - arrowOffset,
    };
  }
  return {
    shiftLeft,
    shiftTop,
  };
};

export const createPortalNode = () => {
  const modal = document.querySelector('[data-type="client-modal"]');
  const mainPortal = document.body.querySelector('#portal-root');
  const portal = document.createElement('div');
  portal.id = 'portal-root';
  if (isNull(mainPortal)) {
    document.body.appendChild(portal);
  }
  if (!isNull(modal)) {
    document.body.querySelectorAll('#portal-root').forEach((elem) => elem.remove());
    modal.appendChild(portal);
  }

  return {modal};
};
