export function transformQueryParamsForExport(queryParams) {
  const {q, ...rest} = queryParams;

  const params = {
    ...rest,
    ...q,
  };

  if (params.g) {
    params.g = [params.g];
  }

  if (params.participation_prizes_name_in) {
    params.participation_prizes_name_in = params.participation_prizes_name_in.map((i) => decodeURIComponent(i));
  }

  return params;
}
