import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {TranslationJsx} from 'client/models/language/types';

import './title-line.scss';

const b = bem('title-line');

const TitleLine = (props) => {
  const {text, className} = props;

  return (
    <div className={cn(b(), className)}>
      <span>{text}</span>
    </div>
  );
};

TitleLine.defaultProps = {
  className: '',
};

TitleLine.propTypes = {
  text: TranslationJsx.isRequired,
  className: PropTypes.string,
};

export default TitleLine;
