import React from 'react';

import cn from 'classnames';
import omit from 'lodash/omit';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';
import RequiredLabel from 'client/common/inputs/required-label';

import {CheckboxInputProps} from './types';

import cssModule from './checkbox-input.module.scss';

const b = bem('checkbox-input', {cssModule});

const CheckboxInput: React.FC<CheckboxInputProps> = (props) => {
  const {
    className,
    disabled,
    checked,
    onChange,
    onCheck,
    color = 'clients',
    label,
    name,
    inversionColor,
    visualClassName,
    readOnly,
    asButton,
    asLabel,
    testId,
    isPartiallyChecked,
    labelIconName,
    labelIconConfig,
    classNames,
    required = false,
    ...inputProps
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {checked: isChecked} = event.target;

    onCheck?.(isChecked);
    onChange?.(event);
  };

  return (
    <>
      <label
        className={cn(
          b({
            [color]: color,
            disabled,
            'is-checkbox-button': asButton,
            'is-checkbox-label': asLabel,
            checked,
            'partly-checked': isPartiallyChecked,
          }),
          className,
        )}
      >
        {readOnly && !(asButton || asLabel) ? (
          <Icon
            name={checked ? 'check-sign' : 'minus-sign'}
            width={22}
            height={22}
            className={b('state-icon', [color])}
          />
        ) : (
          <>
            <input
              name={name}
              disabled={disabled}
              type="checkbox"
              className={b('input')}
              checked={checked}
              onChange={handleChange}
              {...omit(inputProps, 'errorMessage')}
            />

            {!(asButton || asLabel) && (
              <div
                className={cn(
                  b('visual', {
                    [color]: color,
                    checked: checked || isPartiallyChecked,
                    disabled: disabled,
                    inversion: inversionColor,
                  }),
                  visualClassName,
                  classNames?.visual,
                )}
              >
                <Icon
                  name={isPartiallyChecked ? 'minus-sign' : 'check'}
                  width={21}
                  height={21}
                  className={cn(b('icon', {checked: checked || isPartiallyChecked}), classNames?.checkIcon)}
                  data-testid={testId}
                />
              </div>
            )}
          </>
        )}
        {(labelIconName || labelIconConfig?.name) && (
          <Icon
            name={labelIconName || ''}
            className={cn(b('label-icon'), classNames?.labelIconName)}
            {...labelIconConfig}
          />
        )}
        {label && <span className={cn(b('label'), classNames?.label)}>{label}</span>}
      </label>
      {required && <RequiredLabel />}
    </>
  );
};

export const CheckboxInputColorVariants = [
  'primary',
  'secondary',
  'visuals',
  'leads',
  'participants',
  'games',
  'devices',
  'clients',
  'visits',
  'users',
  'default',
] as const;

export default CheckboxInput;
