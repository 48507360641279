import {RootLeveElement, Column, ContentBlock, Section} from 'client/components/email-template-editor/types';
import {EmailTemplate} from 'client/models/email-templates/types';

interface GenericWithId {
  id?: string;
}

export const moveArrayEl = <T extends GenericWithId>(array: T[], id: string, afterId: string) => {
  const elIndex = array.findIndex((el) => el.id === id);
  const afterElIndex = array.findIndex((el) => el.id === afterId);
  const movedEl = array[elIndex];

  const result = [...array];
  result.splice(elIndex, 1);
  if (afterElIndex > -1) {
    result.splice(afterElIndex, 0, movedEl);
  } else {
    result.push(movedEl);
  }

  return result;
};

export const addArrayEl = <T extends GenericWithId>(array: T[], newEl: T, beforeId: string | null) => {
  const beforeElIndex = array.findIndex((el) => el.id === beforeId);
  const result = [...array];
  if (beforeElIndex > -1) {
    result.splice(beforeElIndex, 0, newEl);
  } else {
    result.push(newEl);
  }
  return result;
};

export const updateSection = (array: Section[], section: Section) => {
  const result = [...array];

  if (section) {
    const sectionIndex = result.findIndex((el) => el.id === section.id);
    if (sectionIndex > -1) {
      result.splice(sectionIndex, 1, section);
    }
  }

  return result;
};

export const removeArrayEl = <T extends GenericWithId>(array: T[], id: string) => {
  const result = [...array];

  const elIndex = result.findIndex((el) => el.id === id);

  if (elIndex > -1) {
    result.splice(elIndex, 1);
  }

  return result;
};

export const getColumnById = (sections: Section[], id: string) => {
  const columns = sections.reduce((acc: Column[], el) => [...acc, ...el.columns], []);
  return columns.find((el) => el.id === id);
};

export const getColumnByContentBlockId = (sections: Section[], contentBlockId: string) => {
  const columns = sections.reduce((acc: Column[], el) => [...acc, ...el.columns], []);
  return columns.find(({contentBlocks}) => contentBlocks.find(({id}) => id === contentBlockId));
};

export const addContentBlock = (
  docBody: RootLeveElement[],
  columnId: string,
  contentBlock: ContentBlock,
  afterContentBlockId: string | null = null,
) => {
  const sections = docBody.filter((el) => el.type === 'section');
  const targetSection = sections.find((section) => {
    return section.columns.find((column) => column.id === columnId);
  });
  if (targetSection) {
    const targetColumn = targetSection.columns.find((el) => el.id === columnId);
    if (targetColumn) {
      targetColumn.contentBlocks = addArrayEl(targetColumn.contentBlocks, contentBlock, afterContentBlockId);
    }
  }

  return docBody;
};

export const updateContentBlock = (docBody: RootLeveElement[], contentBlockData: ContentBlock) => {
  if (contentBlockData.id) {
    const sections = docBody.filter((el) => el.type === 'section');
    const column = getColumnByContentBlockId(sections, contentBlockData.id);

    if (column) {
      const contentBlockIndex = column.contentBlocks.findIndex((el) => el.id === contentBlockData.id);
      if (contentBlockIndex > -1) {
        const contentBlocks = [...column.contentBlocks];
        contentBlocks.splice(contentBlockIndex, 1, contentBlockData);
        column.contentBlocks = contentBlocks;
      }
    }
  }

  return docBody;
};

export const deleteContentBlock = (docBody: RootLeveElement[], contentBlockId: string) => {
  const sections = docBody.filter((el) => el.type === 'section');
  const column = getColumnByContentBlockId(sections, contentBlockId);
  if (column) {
    const contentBlockIndex = column.contentBlocks.findIndex((el) => el.id === contentBlockId);
    if (contentBlockIndex > -1) {
      const contentBlocks = [...column.contentBlocks];
      contentBlocks.splice(contentBlockIndex, 1);
      column.contentBlocks = contentBlocks;
    }
  }

  return docBody;
};

export const moveContentBlock = (
  docBody: RootLeveElement[],
  contentBlockId: string,
  targetColumnId: string,
  afterContentBlockId: string,
) => {
  const sections = docBody.filter((el) => el.type === 'section');
  const targetColumn = getColumnById(sections, targetColumnId);
  const currentColumn = getColumnByContentBlockId(sections, contentBlockId);

  if (currentColumn && targetColumn) {
    if (targetColumn === currentColumn) {
      targetColumn.contentBlocks = moveArrayEl(currentColumn.contentBlocks, contentBlockId, afterContentBlockId);
    } else {
      const contentBlock = currentColumn.contentBlocks.find((el) => el.id === contentBlockId);
      if (contentBlock) {
        currentColumn.contentBlocks = removeArrayEl(currentColumn.contentBlocks, contentBlockId);
        targetColumn.contentBlocks = addArrayEl(targetColumn.contentBlocks, contentBlock, afterContentBlockId);
      }
    }
  }

  return docBody;
};

export const updateEmailParams = (template: EmailTemplate, data: EmailTemplate) => {
  template.subject = data.subject;
  template.subject_preview = data.subject_preview;
  template.sender_name = data.sender_name;
  template.email_sender_id = data.email_sender_id;
  template.sender_email = data.sender_email;
  template.reply_to = data.reply_to;
  template.email_template_variables = data.email_template_variables;
  return template;
};
