import React from 'react';

import snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons';

import FilterLabel from 'client/components/common/filter-label';

import LeadsFiltersModal from 'client/components/leads/components/modals/leads-filters-modal/leads-filters-modal';
import {mapFilter} from 'client/components/leads/leads-list/helper';

import cssModule from './participants-list-toolbar-labels.module.scss';

const b = bem('participants-list-toolbar-labels', {cssModule});

export const checkOperationFilter = (data) => {
  delete data.created;
  delete data.participated;
  delete data.m;
  if (data.optInRadio !== LeadsFiltersModal.optInValues.noActive) {
    delete data.optInRadio;
  }
  return data;
};

const ParticipantsListToolbarLabels = ({onFilterFieldClear, onFilterClear, filter = {}}) => {
  const lang = useLanguage('PARTICIPANTS_LIST');

  return (
    <div className={b()}>
      {['phone', 'email', 'visuals']
        .filter((key) => filter[key] && filter[key] !== 'false')
        .map((key) => (
          <FilterLabel key={key} text={lang[`WITH_${key.toUpperCase()}`]} onDelete={() => onFilterFieldClear([key])} />
        ))}

      {['showHidden', 'showNotHidden']
        .filter((key) => filter[key] && filter[key] !== 'false')
        .map((key) => (
          <FilterLabel
            key={key}
            text={`${lang[snakeCase(key).toUpperCase()]}`}
            onDelete={() => onFilterFieldClear([key])}
          />
        ))}

      {['without_test', 'participations_only']
        .filter((key) => filter[key] && filter[key] !== 'false')
        .map((key) => (
          <FilterLabel key={key} text={lang[`${key.toUpperCase()}`]} onDelete={() => onFilterFieldClear([key])} />
        ))}

      {['participatedFrom', 'participatedTo']
        .filter((key) => filter[key])
        .map((key) => (
          <FilterLabel
            key={key}
            text={`${lang[snakeCase(key).toUpperCase()]}: ${filter[key]}`}
            onDelete={() => onFilterFieldClear([key])}
          />
        ))}

      {filter.optInRadio && filter.optInRadio === LeadsFiltersModal.optInValues.noActive && (
        <FilterLabel
          text={`${lang.OPT_IN_RADIO}: ${filter.optInRadio}`}
          onDelete={() => onFilterFieldClear(['optInRadio'])}
        />
      )}

      {['scenario', 'interface', 'opt_in']
        .filter((key) => filter[key])
        .map((key) =>
          filter[key].map(({label, value}) => (
            <FilterLabel
              key={`${key}-${value}`}
              text={`${lang[key.toUpperCase()]}: ${decodeURIComponent(label)}`}
              onDelete={() => onFilterFieldClear([key], value)}
            />
          )),
        )}

      {filter.levels?.map(({value}) => (
        <FilterLabel
          key={value}
          text={`${lang.LEVEL}: ${value}`}
          onDelete={() => onFilterFieldClear(['levels'], value)}
        />
      ))}

      {filter.prize && (
        <FilterLabel
          key={`prize-${filter.prize.label}`}
          text={`${lang.PRIZE_LABEL}: ${filter.prize.label}`}
          onDelete={() => onFilterFieldClear('prize')}
        />
      )}
      {filter.source && (
        <FilterLabel
          key={`source-${filter.source.label}`}
          text={`${decodeURI(filter.source.label)}`}
          onDelete={() => onFilterFieldClear('source')}
        />
      )}
      {Object.keys(checkOperationFilter(mapFilter(filter))).length > 0 && (
        <AppButton
          label={lang.CLEAR_FILTERS_BUTTON}
          nativeStyle
          onClick={onFilterClear}
          className="button button--bg-4"
        />
      )}
    </div>
  );
};

ParticipantsListToolbarLabels.propTypes = {
  filter: PropTypes.object,
  onFilterFieldClear: PropTypes.func.isRequired,
  onFilterClear: PropTypes.func.isRequired,
};

export default ParticipantsListToolbarLabels;
