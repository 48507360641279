import React, {useState} from 'react';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';

import {getDeviceAffectation} from 'client/ducks/device-affectation/actions';

import AwaitContainer from 'client/common/await-container';
import Modal from 'client/common/modals/modal';
import Offscreen from 'client/common/offscreen';
import TabSwitcher from 'client/common/tab-switcher';

import ClientDeviceModalGeneralTab from './client-device-modal-general-tab';
import ClientDeviceModalLogisticsTab from './client-device-modal-logistics-tab';
import ClientDeviceModalMediaTab from './client-device-modal-media-tab';

import cssModule from './client-device-modal.module.scss';

const b = bem('client-device-modal', {cssModule});

export type ClientDeviceModalTab = 'general' | 'logistics' | 'media';

type ClientDeviceModalProps = {
  affectationId: number;
  defaultTab?: ClientDeviceModalTab;
  onClose: () => void;
  fetchData: () => void;
};

const ClientDeviceModal: React.FC<ClientDeviceModalProps> = (props) => {
  const {defaultTab = 'general', onClose, affectationId, fetchData} = props;

  const lang = useLanguage('CLIENT_DEVICES.MODAL');
  const [activeTab, setActiveTab] = useState<ClientDeviceModalTab>(defaultTab);

  const {loading, data} = useReduxFetch({
    action: getDeviceAffectation,
    actionArgs: {
      id: affectationId,
      queryParams: {
        include_operation_prize_maps_modified: null,
        include_operation_with_real_participations: null,
        include_operation_available_membership: null,
        include_operation_show_configuration: null,
        include: {
          place: {region: null},
          device_affectation_infos: null,
          device: {
            device_family: null,
            current_interaction: {operation: null, automation_task: null},
            next_interaction: {operation: null, automation_task: null},
          },
        },
      },
    },
  });
  const affectation = data?.device_affectation;

  const description = [affectation?.place?.name, affectation?.place?.city_name, affectation?.place?.region?.name]
    .filter(Boolean)
    .join(' – ');

  return (
    <Modal
      title={affectation?.device?.name}
      show
      classNames={{body: b('body'), content: b('content')}}
      className={b()}
      onClose={onClose}
      description={description}
    >
      <AwaitContainer loading={loading}>
        <TabSwitcher
          className={b('header')}
          style="title"
          tabs={[
            {value: 'general', title: lang.GENERAL_TAB.TITLE},
            {value: 'logistics', title: lang.LOGISTICS_TAB.TITLE},
            {value: 'media', title: lang.MEDIA_PLAYER_TAB.TITLE},
          ]}
          activeTab={activeTab}
          onTabClick={setActiveTab}
        />
        <div className={b('tab-content')}>
          {activeTab === 'general' && <ClientDeviceModalGeneralTab affectation={affectation!} />}
          {activeTab === 'logistics' && <ClientDeviceModalLogisticsTab affectation={affectation!} />}
          <Offscreen hidden={activeTab !== 'media'}>
            {affectation?.device && (
              <ClientDeviceModalMediaTab
                clientId={affectation.client_id}
                device={affectation.device}
                affectationId={affectation.id}
                onClose={onClose}
                onSave={fetchData}
              />
            )}
          </Offscreen>
        </div>
      </AwaitContainer>
    </Modal>
  );
};

export default ClientDeviceModal;
