import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import bem from 'client/services/bem';

import Popover from 'client/common/popovers/popover';

import {TranslationJsx} from 'client/models/language/types';
import {ReactNode} from 'client/types';

import './auth-link.scss';

const b = bem('auth-link');

function AuthLink(props) {
  const {tooltip, to, additional, oversize, main, label, disabled, nativeLink, className} = props;
  const linkClassName = classNames(b('link', {additional, oversize, main, disabled}), className);

  const renderLink = nativeLink ? (
    <a href={to} className={linkClassName}>
      {label}
    </a>
  ) : (
    <Link className={linkClassName} to={to} key={to}>
      {label}
    </Link>
  );

  return (
    <div className={b({additional, oversize, main})}>
      <Popover
        position="top"
        trigger={tooltip ? 'both' : 'none'}
        overlay={<span id="popover-positioned-scrolling-right">{tooltip}</span>}
      >
        {disabled ? <span className={linkClassName}>{label}</span> : renderLink}
      </Popover>
    </div>
  );
}

AuthLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([TranslationJsx, PropTypes.element]).isRequired,
  tooltip: ReactNode,
  disabled: PropTypes.bool,
  additional: PropTypes.bool,
  oversize: PropTypes.bool,
  nativeLink: PropTypes.bool,
  main: PropTypes.bool,
  className: PropTypes.string,
};

AuthLink.defaultProps = {
  to: '',
  label: '',
  tooltip: '',
  disabled: false,
  additional: false,
  main: false,
  nativeLink: false,
  oversize: false,
  className: '',
};

export default AuthLink;
