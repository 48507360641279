import * as SessionStorage from 'client/services/sessionStorage';

export const initializeColorSwatches = (content: string, colorsAccessKey: string) => {
  const colorMatches = content?.matchAll(/color: #[0-9a-f]{6}/g);
  let commonColors = SessionStorage.getItem(colorsAccessKey) || [];

  for (const match of colorMatches) {
    const colorValue = match[0].split(': ')[1];

    if (!commonColors.includes(colorValue)) {
      commonColors.push(colorValue);
      commonColors = commonColors.slice(-5);
    }
  }

  if (commonColors.length > 0) {
    SessionStorage.setItem(colorsAccessKey, commonColors);
  }
};
