import {SegmentationParamsValues} from './types';

export default (values: SegmentationParamsValues) => {
  return values.fullGlobal || values.fullNone
    ? {
        default_initial_stock: values.commonQuantity,
        geo_level: values.deviceSegmentation,
        online_level: values.onlineSegmentation,
      }
    : {
        online_stock: values.onlineQuantity,
        online_level: values.onlineSegmentation,
        device_stock: values.deviceQuantity,
        geo_level: values.deviceSegmentation,
      };
};
