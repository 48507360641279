import {useCallback, useEffect, useMemo} from 'react';

import uniqBy from 'lodash/uniqBy';
import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import {getInstoreTaskKpis} from 'client/ducks/instore-tasks/actions';
import {selectInstoreTaskKpis} from 'client/ducks/instore-tasks/selectors';

export const useFetchTaskKpis = ({task}) => {
  const dispatch = useDispatch();

  const [loading, toggleLoading] = useToggle(false);

  const kpis = useSelector(selectInstoreTaskKpis);

  const fetchKpis = useCallback(async () => {
    const params = {
      q: {
        instore_task_id_eq: task.id,
        nature_in: ['report', 'kpi'],
      },
    };

    toggleLoading();
    const result = await dispatch(getInstoreTaskKpis(params));
    toggleLoading();

    return result;
  }, [dispatch, task.id, toggleLoading]);

  useEffect(() => {
    fetchKpis();
  }, [fetchKpis]);

  const uniqKpi = useMemo(
    () =>
      uniqBy(kpis, 'id').sort((a) => {
        return a.nature === 'report' ? -1 : 1;
      }),
    [kpis],
  );

  return {loading, kpis: uniqKpi};
};
