import React, {Component} from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {change, untouch, Field, reduxForm, SubmissionError} from 'redux-form';

import {patch} from 'client/services/fetch';
import {isEmail, isPhone} from 'client/services/helpers';

import {API_METHODS} from 'client/common/config';
import Icon from 'client/common/icon';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
// components
import fieldTemplate from 'client/components/common/field';

import './phones-emails-list.scss';

let LANGUAGE = {};
let items = [];
let EMAIL_ERRORS = {};

class PhonesEmailsList extends Component {
  static validate(vals) {
    const values = vals || {};
    const errors = {};
    const lang = LANGUAGE.PHONES_EMAILS_CARD || {};

    for (let i = 0; i < items.length; i++) {
      if (items[i].deleted) {
        continue;
      }
      let name = 'name' + i;
      let type = 'type' + i;
      let value = 'value' + i;

      errors[name] = PhonesEmailsList.getNameErrorMessage(values[name], values[type]?.value, lang);

      if (!values[type] || !values[type].value) {
        errors[type] = lang.TYPE_IS_REQUIRED_ERROR;
      } else if (values[type].value === 'EmailSender' && !isEmail(values[value])) {
        errors[value] = PhonesEmailsList.getEmailErrorMessage(values[value]) || lang.EMAIL_IS_INVALID;
      } else if (values[type].value === 'SmsSender' && !isPhone(values[value])) {
        errors[value] = lang.PHONE_IS_INVALID;
      }

      if (!values[value] || !values[value].trim()) {
        errors[value] = lang.VALUE_IS_REQUIRED_ERROR;
      }
    }

    return errors;
  }

  static updateSyncErrors(values) {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'PhonesEmailsForm',
      },
      payload: {
        syncErrors: PhonesEmailsList.validate(values),
      },
    };
  }

  static mapData(senders) {
    return {
      client: {
        message_senders: senders.map((sender) => ({
          id: sender.id,
          name: sender.name,
          type: sender.type,
          value: sender.type === 'SmsSender' ? isPhone(sender.value) : sender.value,
          _destroy: sender.deleted,
        })),
      },
    };
  }

  constructor(props) {
    super(props);

    LANGUAGE = props.languageState.payload.AGENCY;
    EMAIL_ERRORS = props.languageState.payload.EMAIL_ERRORS;
    this.types = Object.keys(props.languageState.payload.AGENCY.PHONES_EMAILS_CARD.TYPES).map((key) => ({
      value: key,
      label: props.languageState.payload.AGENCY.PHONES_EMAILS_CARD.TYPES[key],
    }));

    this.state = {
      items: [],
      deletingIndex: -1,
      showDeleteAccountModal: false,
    };
  }

  static getEmailErrorMessage(email) {
    if (!email) {
      return false;
    }
    if (email.indexOf('@') === -1) {
      return EMAIL_ERRORS.NO_AT;
    }
    if (/@\./.test(email) || /\.\./.test(email) || /\.$/.test(email) || /@[^.]*$/.test(email)) {
      return EMAIL_ERRORS.NO_DOMAIN;
    }
    return false;
  }

  static getNameErrorMessage(value, senderType, lang) {
    if (!value || !value.trim()) {
      return lang.NAME_IS_REQUIRED_ERROR;
    }

    if (senderType === 'SmsSender') {
      if (!/^[A-Za-z0-9]+$/.test(value)) {
        return lang.SPECIAL_CHARACTERS_ERROR;
      }

      if (/^\d+$/.test(value) && value.length > 15) {
        return lang.CHARACTER_LIMIT_ERROR;
      }

      if (!/^\d+$/.test(value) && value.length > 11) {
        return lang.CHARACTER_LIMIT_ERROR;
      }
    }

    return '';
  }

  componentDidMount() {
    this.applyNewData(this.props.data);
    this.intervalID = setInterval(() => {
      if (!isEmpty(LANGUAGE)) {
        clearInterval(this.intervalID);
        this.props.updateErrors((this.props.formValues && this.props.formValues.values) || {});
      }
    }, 200);
  }

  componentDidUpdate(prevProps) {
    const {data, editMode} = this.props;

    if (editMode && !prevProps.editMode) {
      return;
    }

    if (data !== prevProps.data) {
      this.applyNewData(data);
    }
  }

  applyNewData = (data) => {
    items = data.slice();
    this.setState({items});

    this.props.initialize(
      items.reduce((init, item, index) => {
        init['name' + index] = item.name;
        init['type' + index] = {value: item.type};
        init['value' + index] = item.value;
        return init;
      }, {}),
    );
  };

  onAddNewItemClick = () => {
    const newItems = this.state.items.slice();
    newItems.push({type: 'EmailSender'});
    items = newItems;
    this.props.onAddClick();
    this.setState({items: newItems}, () => {
      if (this.scrollbarRef) {
        this.scrollbarRef.scrollToBottom();
      }
      this.props.changeFieldValue('PhonesEmailsForm', 'type' + (newItems.length - 1), {value: 'EmailSender'});
      this.props.changeFieldValue('PhonesEmailsForm', 'value' + (newItems.length - 1), '');
      this.props.changeFieldValue('PhonesEmailsForm', 'name' + (newItems.length - 1), '');
      this.props.updateErrors(this.props.formValues.values);
      this.props.untouch('PhonesEmailsForm', 'value' + (newItems.length - 1), 'name' + (newItems.length - 1));
    });
  };

  handleChangeName =
    (index) =>
    ({target: {value}}) => {
      const item = this.state.items[index];
      item.name = value;
    };

  handleChangeType =
    (index) =>
    ({value}) => {
      const item = this.state.items[index];
      item.type = value;
      item.typeChanged = true;
    };

  handleChangeValue =
    (index) =>
    ({target: {value}}) => {
      const item = this.state.items[index];
      item.value = value;
    };

  onItemDelete = () => {
    const index = this.state.deletingIndex;
    const newItems = this.state.items.slice();
    const type = this.state.items[index];
    if (type.id) {
      type.deleted = true;
    } else {
      newItems.splice(index, 1);
    }
    this.setState({items: newItems, showDeleteAccountModal: false});
    items = newItems;
  };

  onCancelClick = () => {
    this.applyNewData(this.props.data);
    this.props.onCancelClick();
  };

  save = async () => {
    try {
      await patch(
        `${API_METHODS.CLIENTS}/${this.props.clientId}`,
        PhonesEmailsList.mapData(
          this.state.items.filter((i) => i.typeChanged && !i.deleted && i.id).map((i) => ({...i, deleted: true})),
        ),
      );

      const {errors} = await patch(
        `${API_METHODS.CLIENTS}/${this.props.clientId}`,
        PhonesEmailsList.mapData(this.state.items.map((i) => ({...i, id: i.typeChanged ? undefined : i.id}))), // eslint-disable-line no-undefined
      );

      if (errors) {
        const submitErrors = {};
        for (let i in errors.message_senders) {
          if (errors.message_senders.hasOwnProperty(i)) {
            // eslint-disable-next-line max-depth
            if (errors.message_senders[i].name) {
              submitErrors['name' + i] = LANGUAGE.PHONES_EMAILS_CARD.NAME_IS_TAKEN_ERROR;
            }
            // eslint-disable-next-line max-depth
            if (errors.message_senders[i].value) {
              submitErrors['value' + i] = LANGUAGE.PHONES_EMAILS_CARD.VALUE_IS_TAKEN_ERROR;
            }
          }
        }
        throw new SubmissionError(submitErrors);
      }
      this.props.onSaveClick();
    } catch (error) {
      // TODO: add toast notification
    }
  };

  render() {
    const {handleSubmit} = this.props;
    return (
      <div
        className={
          'phones-emails-list phones-emails-list--expand' +
          (!this.state.items.length ? ' phones-emails-list--empty' : '')
        }
      >
        <ConfirmationModal
          show={this.state.showDeleteAccountModal}
          onClose={() => this.setState({showDeleteAccountModal: false})}
          onCancel={() => this.setState({showDeleteAccountModal: false})}
          message={LANGUAGE.PHONES_EMAILS_CARD.DELETE_ACCOUNT_MODAL.BODY_TEXT}
          title={LANGUAGE.PHONES_EMAILS_CARD.DELETE_ACCOUNT_MODAL.TITLE}
          cancelText={LANGUAGE.CANCEL_BUTTON}
          confirmText={LANGUAGE.PHONES_EMAILS_CARD.DELETE_ACCOUNT_MODAL.CONFIRM}
          className="theme-color-8"
          buttonConfirmClass="button--bg-7"
          buttonCancelClass="button--bg-11"
          onConfirm={this.onItemDelete}
        />

        <form noValidate="noValidate" onSubmit={handleSubmit(this.save)}>
          <div className="phones-emails-list__list">
            {this.state.items.length === 0 ? (
              <span className="phones-emails-list__empty">{LANGUAGE.PHONES_EMAILS_CARD.NO_ACCOUNTS}</span>
            ) : (
              <CustomScrollbars
                scrollbarProps={{
                  autoHeightMax: 216,
                  autoHeightMin: 216,
                  ref: (scrollbar) => {
                    this.scrollbarRef = scrollbar;
                  },
                }}
              >
                <div className="phones-emails-list__list-content">
                  {this.state.items &&
                    this.state.items.map(
                      (account, i) =>
                        !account.deleted && (
                          <div className="phones-emails-list__item" key={i}>
                            <div>
                              <Field
                                name={'name' + i}
                                type="text"
                                cssModifier="input--no-min-height phones-emails-list__item__name"
                                component={fieldTemplate}
                                placeholder={LANGUAGE.PHONES_EMAILS_CARD.NAME_PLACEHOLDER}
                                onChange={this.handleChangeName(i)}
                              />
                              <Field
                                name={'type' + i}
                                type="select"
                                searchable={false}
                                placeholder={LANGUAGE.PHONES_EMAILS_CARD.TYPE_PLACEHOLDER}
                                cssModifier="phones-emails-list__item__type"
                                component={fieldTemplate}
                                options={this.types}
                                onChange={this.handleChangeType(i)}
                              />
                            </div>
                            <div>
                              <Field
                                name={'value' + i}
                                type="text"
                                cssModifier="input--no-min-height phones-emails-list__item__value"
                                component={fieldTemplate}
                                placeholder={LANGUAGE.PHONES_EMAILS_CARD.TYPES[account.type] || ''}
                                onChange={this.handleChangeValue(i)}
                              />
                              <button
                                type="button"
                                className="phones-emails-list__input-btn"
                                onClick={() => this.setState({showDeleteAccountModal: true, deletingIndex: i})}
                                disabled={account.sms_templates_present || account.email_templates_present}
                              >
                                <Icon name="trash" className="phones-emails-list__delete-icon" width={17} height={19} />
                              </button>
                            </div>
                          </div>
                        ),
                    )}
                </div>
              </CustomScrollbars>
            )}
          </div>
          <div className="phones-emails-list__bottom">
            <button
              className="button button--bg-7 button--full-width phones-emails-list__add-btn"
              onClick={this.onAddNewItemClick}
              type="button"
            >
              <Icon name="plus" className="button__icon" />
              <span>{LANGUAGE.PHONES_EMAILS_CARD.ADD_ACCOUNT_BUTTON}</span>
            </button>
            {this.props.editMode && (
              <div className="phones-emails-list__action-btns">
                <button
                  className="button button--bg-11 phones-emails-list__action-btn"
                  type="button"
                  onClick={this.onCancelClick}
                >
                  {LANGUAGE.CANCEL_BUTTON}
                </button>
                <button className="button button--bg-7 phones-emails-list__action-btn" type="submit">
                  {LANGUAGE.SAVE_BUTTON}
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const PhonesEmailsForm = reduxForm({
  form: 'PhonesEmailsForm',
  validate: PhonesEmailsList.validate,
})(PhonesEmailsList);

PhonesEmailsList.propTypes = {
  onAddClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  initialize: PropTypes.func,
  handleSubmit: PropTypes.func,
  changeFieldValue: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  data: PropTypes.array,
  clientId: PropTypes.number,
  editMode: PropTypes.bool,
  languageState: PropTypes.object.isRequired,
  formValues: PropTypes.object,
};

PhonesEmailsList.defaultProps = {
  onAddClick: () => {},
  onSaveClick: () => {},
  onCancelClick: () => {},
  data: [],
  editMode: false,
  clientId: 0,
};

export default connect(
  ({form}) => ({
    formValues: form.PhonesEmailsForm,
  }),
  {
    updateErrors: PhonesEmailsList.updateSyncErrors,
    changeFieldValue: change,
    untouch,
  },
)(PhonesEmailsForm);
