import React, {useMemo} from 'react';

import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import PaginationBar from 'client/common/paginations/pagination-bar';

import ClientTable from 'client/components/common/client-table';

import {prepareColumns} from 'client/components/participants/lists/participants-common-list/helpers';

import './participants-common-list.scss';

const b = bem('participants-common-list');

const DEFAULT_PER_PAGE = 5;

const ParticipantsCommonList = React.memo((props) => {
  const {data, displayColumns, getColumns, loading, isAdmin, onPaginationChange, meta, tableProps, pagination} = props;
  const lang = useLanguage('PARTICIPANTS_LIST');

  const columns = useMemo(() => {
    const defaultColumns = prepareColumns(displayColumns, {lang, isAdmin});

    return getColumns?.(defaultColumns) || defaultColumns;
  }, [getColumns, displayColumns, lang, isAdmin]);

  const handleChangeSort = ({sortField, sortOrder}) => {
    onPaginationChange({
      page: pagination?.page || 1,
      perPage: pagination?.perPage || DEFAULT_PER_PAGE,
      sortField: sortField,
      sortOrder: sortOrder,
    });
  };

  const handleChangePage = ({page}) => {
    onPaginationChange({
      page: page || 1,
      perPage: pagination?.perPage || DEFAULT_PER_PAGE,
      sortField: pagination?.sortField,
      sortOrder: pagination?.sortOrder,
    });
  };

  return (
    <div className={b()}>
      <ClientTable
        {...tableProps}
        className={b('table')}
        commonHeaderClassNames={{
          header: b('header'),
          label: b('header-label'),
        }}
        loading={loading}
        data={data}
        sortField={pagination?.sortField}
        sortOrder={pagination?.sortOrder}
        onSortChange={handleChangeSort}
        columns={columns}
        fakeEmptyValue={false}
      />
      <PaginationBar
        className={b('pagination')}
        data={data}
        onPageChange={handleChangePage}
        currentPage={meta.current_page}
        totalPages={meta.total_pages}
        totalItems={meta.total_count}
        perPage={pagination?.perPage || DEFAULT_PER_PAGE}
      />
    </div>
  );
}, isEqual);

ParticipantsCommonList.displayName = 'ParticipantsCommonList';

ParticipantsCommonList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      lead_id: PropTypes.number,
      test_mode: PropTypes.bool,
      visible_by_client: PropTypes.bool,
    }),
  ).isRequired,
  displayColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  meta: PropTypes.shape({
    current_page: PropTypes.number.isRequired,
    total_pages: PropTypes.number.isRequired,
    total_count: PropTypes.number.isRequired,
  }).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    perPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    sortField: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
  }).isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  getColumns: PropTypes.func,
  isAdmin: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,

  // For ClientTableProps:
  tableProps: PropTypes.object,
};
ParticipantsCommonList.displayName = 'ParticipantsCommonList';

ParticipantsCommonList.defaultProps = {
  loading: false,
  isAdmin: false,
  meta: {},
  getColumns: null,
  onPageChange: null,
  className: '',
};

export default ParticipantsCommonList;
