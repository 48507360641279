// type

export const getLanguages = (lang) => {
  const languages = {
    en: lang.ENGLISH,
    fr: lang.FRENCH,
    es: lang.SPANISH,
    it: lang.ITALIAN,
    nl: lang.DUTCH,
    pt: lang.PORTUGUESE,
    de: lang.GERMAN,
    pl: lang.POLISH,
  };

  return languages;
};
