import React, {useMemo} from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import LayoutPanel from 'client/common/panels/layout-panel';

import {getComments} from 'client/components/devices/modals/client-device-modal/helpers';
import {DeviceAffectation} from 'client/models/device-affectations';
import {AFFECTATION_INFO_MODES} from 'client/models/device-affectations';

import ClientDeviceModalActivityBlock from '../blocks/client-device-modal-activity-block';
import ClientDeviceModalCommentsBlock from '../blocks/client-device-modal-comments-block';
import ClientDeviceModalInfoBlock from '../blocks/client-device-modal-info-block';
import ClientDeviceModalStatusBlock from '../blocks/client-device-modal-status-block';

import cssModule from './client-device-modal-general-tab.module.scss';

const b = bem('client-device-modal-general-tab', {cssModule});

type ClientDeviceModalGeneralTabProps = {
  affectation: DeviceAffectation;
};

const ClientDeviceModalGeneralTab: React.FC<ClientDeviceModalGeneralTabProps> = ({affectation}) => {
  const lang = useLanguage('CLIENT_DEVICES.MODAL.GENERAL_TAB');
  const device = affectation.device!;

  const commentsData = useMemo(() => {
    return getComments(affectation, AFFECTATION_INFO_MODES.ACTIVITY);
  }, [affectation]);

  return (
    <div className={b()}>
      <figure className={b('image-container')}>
        {device.device_family?.image?.url && (
          <img className={b('image')} src={device.device_family?.image?.url} alt=" " />
        )}
      </figure>
      <div className={b('container')}>
        <LayoutPanel className={b('panel', ['grey', 'device'])}>
          <h4 className={b('title')}>{lang.DEVICE_INFO_TITLE}</h4>
          <ClientDeviceModalInfoBlock device={device} />
        </LayoutPanel>
      </div>
      <div className={b('container')}>
        <LayoutPanel className={b('panel', ['grey'])}>
          <h4 className={b('title')}>{lang.STATUS_TITLE}</h4>
          <ClientDeviceModalStatusBlock affectation={affectation} />
        </LayoutPanel>
        <LayoutPanel className={b('panel', ['grey'])}>
          <h4 className={b('title')}>
            {lang.ACTIVITY_TITLE}
            <span className={b('subtitle')}>{lang.ACTIVITY_FROM_TO}</span>
          </h4>
          <ClientDeviceModalActivityBlock affectation={affectation} />
        </LayoutPanel>
      </div>
      <div className={b('container')}>
        <LayoutPanel className={b('panel')}>
          <h4 className={b('title')}>{lang.COMMENTS_TITLE}</h4>
          <ClientDeviceModalCommentsBlock data={commentsData} />
        </LayoutPanel>
      </div>
    </div>
  );
};

export default ClientDeviceModalGeneralTab;
