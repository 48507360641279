import PropTypes from 'prop-types';

import {TranslationJsx} from 'client/models/language/types';

export const BaseFieldComponentProps = {
  label: TranslationJsx,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
  withWrap: PropTypes.bool,
};
