import React, {useMemo, useState} from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {IMaskMixin} from 'react-imask';
import {connect} from 'react-redux';
import {useToggle} from 'react-use';
import {reduxForm, SubmissionError} from 'redux-form';

import bem from 'client/services/bem';
import {patch, post} from 'client/services/fetch';
import {useReduxForm} from 'client/services/hooks';

import {OPT_IN_TYPES} from 'client/ducks/opt-in-columns/constants';
import {selectOptInsTakenMandatoryOrders} from 'client/ducks/opt-in-columns/selectors';

import RadioButtonGroup from 'client/common/button-group/radio-button-group';
import AppButton from 'client/common/buttons';
import {API_METHODS} from 'client/common/config';
import {SelectField} from 'client/common/fields';
import {TextField} from 'client/common/fields';
import {NumberField} from 'client/common/fields';
import {CheckboxField} from 'client/common/fields';
import Icon from 'client/common/icon';
import Modal from 'client/common/modals/modal';
import SelectDropdown from 'client/common/selects/select-dropdown';

import {TextAreaField} from 'client/components/common/fields';

import {LANGUAGE_LIST, LEVEL_OPTIONS} from './constants';
import {getInitialLanguageOptions, mapFormValues, initializeFormValues} from './helpers';
import {validate} from './validate';

import './add-new-opt-in-modal.scss';

const b = bem('add-new-opt-in-modal');

const MAX_LANGUAGES_COUNT = 7;
const FORM_NAME = 'AddNewOptInModalForm';

const MaskedTextField = IMaskMixin((props) => <TextField {...props} />);

const AddNewOptInModal = (props) => {
  const {handleSubmit, submitting, onDelete, onConfirm, onClose, reset, editingItem, clientId, lang} = props;

  const [languages, setLanguages] = useState(getInitialLanguageOptions(editingItem));
  const [activeLanguage, setActiveLanguage] = useState(languages.length === 1 ? languages[0].value : '');
  const [isAddingLanguage, toggleIsAddingLanguage] = useToggle(false);
  const [languageToAdd, setLanguageToAdd] = useState(null);
  const {change} = useReduxForm(FORM_NAME, {validate: (formValues) => validate(formValues, props)});

  const isEditing = !isEmpty(editingItem);
  const disabled = editingItem.blockToDelete;

  const handleSave = (values) => {
    let path = API_METHODS.OPT_IN_COLUMNS;
    let method = post;

    const body = mapFormValues(values, clientId);

    if (isEditing) {
      method = patch;
      path += '/' + props.editingItem.id;
    }

    return method(path, body).then(({errors}) => {
      if (errors) {
        const submitErrors = {};
        if (errors.name) {
          submitErrors.name = lang.TITLE_IS_TAKEN_ERROR;
        }
        if (errors.code) {
          submitErrors.code = lang.CODE_IS_TAKEN_ERROR;
        }
        throw new SubmissionError(submitErrors);
      }
      reset();
      onConfirm();
    });
  };

  const handleClose = () => {
    toggleIsAddingLanguage(false);
    reset();
    onClose();
  };

  const optInTypeOptions = Object.keys(OPT_IN_TYPES).map((key) => ({
    label: lang.OPT_IN_TYPES[key],
    value: OPT_IN_TYPES[key],
  }));

  const languagesRemaining = useMemo(
    () => LANGUAGE_LIST.filter((item) => !languages.find((language) => language.value === item.value)),
    [languages],
  );

  const addLanguage = () => {
    if (languages.length + 1 >= MAX_LANGUAGES_COUNT) {
      toggleIsAddingLanguage(false);
    }
    change('languages', [...languages, languageToAdd]);
    setLanguages((prevValue) => [...prevValue, languageToAdd]);
    setActiveLanguage(languageToAdd.value);
    setLanguageToAdd(null);
  };

  const deleteLanguage = () => {
    const updatedLanguages = languages.filter((item) => item.value !== activeLanguage);

    change(`opt_in_text_translations.${activeLanguage}`, '');
    change(`opt_out_text_translations.${activeLanguage}`, '');

    setActiveLanguage(updatedLanguages[0].value);
    setLanguages(updatedLanguages);
    change('languages', updatedLanguages);
  };

  const canLanguageBeDeleted = useMemo(() => {
    if (languages.length <= 1 || !activeLanguage) {
      return false;
    }

    // at least one of fr or en languages should remain
    return languages
      .filter((language) => language.value !== activeLanguage)
      .some((language) => language.value === 'fr' || language.value === 'en');
  }, [languages, activeLanguage]);

  return (
    <Modal onClose={handleClose} title={isEditing ? `${lang.TITLE_EDIT} ${editingItem.name}` : lang.TITLE_ADD}>
      <form noValidate="noValidate" onSubmit={handleSubmit(handleSave)}>
        <div className={b('form-fields')}>
          <SelectField label={lang.LEVEL_LABEL} name="level" options={LEVEL_OPTIONS} simpleValue withWrap />
          <TextField label={lang.TITLE_LABEL} name="name" placeholder={lang.TITLE_PLACEHOLDER} withWrap />
          <MaskedTextField
            label={lang.CODE_LABEL}
            name="code"
            placeholder={lang.CODE_PLACEHOLDER}
            normalize={(value) => value?.toUpperCase()} // automatically convert input chars to uppercase
            mask={/^[a-zA-Z0-9]{0,8}$/}
            withWrap
          />
          <NumberField label={lang.MANDATORY_ORDER_LABEL} name="mandatory_order" withWrap />
          <SelectField
            label={lang.OPT_IN_TYPE_LABEL}
            name="opt_in_type"
            options={optInTypeOptions}
            disabled={editingItem?.opt_in_type === OPT_IN_TYPES.EXTERNAL_CLIENT}
            simpleValue
          />

          <div className={b('multilang-fields')}>
            <div className={b('lang-control')}>
              <RadioButtonGroup
                radioButtons={languages}
                labelClassName={b('lang-label')}
                onChange={({target}) => setActiveLanguage(target.value)}
                value={activeLanguage}
              />
              {languages.length < MAX_LANGUAGES_COUNT && (
                <Icon name="add" width="30" height="30" onClick={() => toggleIsAddingLanguage(true)} />
              )}
              {canLanguageBeDeleted && <Icon name="minus" width="30" height="30" onClick={deleteLanguage} />}
            </div>
            {isAddingLanguage && (
              <div className={b('add-lang')}>
                <SelectDropdown
                  label={lang.ADD_LANGUAGE}
                  options={languagesRemaining}
                  onChange={setLanguageToAdd}
                  value={languageToAdd}
                  placeholder={lang.ADD_LANGUAGE}
                  getOptionLabel={(option) => option.labelLong}
                  selectClassName={b('select-lang')}
                />
                <AppButton
                  label={lang.CANCEL_BUTTON}
                  transparent
                  color="text-additional"
                  onClick={toggleIsAddingLanguage}
                />
                <AppButton
                  label={lang.ADD_BUTTON}
                  transparent
                  color="primary"
                  onClick={addLanguage}
                  disabled={!languageToAdd}
                />
              </div>
            )}
            <TextAreaField
              label={lang.OPT_IN_LABEL}
              name={`opt_in_text_translations.${activeLanguage}`}
              placeholder={lang.OPT_IN_PLACEHOLDER}
              disabled={!activeLanguage}
              withWrap
            />
            <TextAreaField
              label={lang.OPT_OUT_LABEL}
              name={`opt_out_text_translations.${activeLanguage}`}
              placeholder={lang.OPT_OUT_PLACEHOLDER}
              disabled={!activeLanguage}
            />
          </div>

          <TextAreaField label={lang.COMMENT_LABEL} name="comment" placeholder={lang.COMMENT_PLACEHOLDER} withWrap />
        </div>

        <div className={b('config-row')}>
          <CheckboxField className={b('editable-checkbox')} label={lang.EDITABLE_LABEL} name="editable" />
          {isEditing && (
            <AppButton
              onClick={onDelete}
              disabled={disabled}
              label={lang.DELETE_OPT_IN_BUTTON}
              iconName="trash"
              iconConfig={{
                name: 'trash',
                width: 17,
                height: 19,
              }}
              className={b('delete-button')}
            />
          )}
        </div>

        <div className={b('buttons')}>
          <AppButton label={lang.CANCEL_BUTTON} onClick={handleClose} transparent size="small" />
          <AppButton label={lang.SAVE_BUTTON} type="submit" disabled={submitting} size="small" />
        </div>
      </form>
    </Modal>
  );
};

AddNewOptInModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  editingItem: PropTypes.object,
  lang: PropTypes.object,

  // from redux-form
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
};

AddNewOptInModal.defaultProps = {
  editingItem: null,
};

const addNewOptInForm = reduxForm({
  form: FORM_NAME,
})(AddNewOptInModal);

export default connect((state, {editingItem}) => {
  const {languageState} = state;

  return {
    initialValues: initializeFormValues(editingItem),
    enableReinitialize: true,
    lang: {
      ...languageState.payload.AGENCY.ADD_OPT_IN_MODAL,
      OPT_IN_TYPES: languageState.payload.AGENCY.OPT_INS_CARD.OPT_IN_TYPES,
    },
    takenMandatoryOrders: selectOptInsTakenMandatoryOrders(state, editingItem?.mandatory_order),
  };
})(addNewOptInForm);
