import React from 'react';

import findIndex from 'lodash/findIndex';
import pull from 'lodash/pull';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {deleteMethod} from 'client/services/fetch';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {selectIsUserLoaded} from 'client/ducks/user/selectors';
import {getVisuals, getVisualDisplayItems} from 'client/ducks/visuals/actions';
import {selectVisualsTotalCount} from 'client/ducks/visuals/selectors';

import {CLIENT_PAGES, API_METHODS, POST_PREVIEW_TYPES, VISUAL_INTERACTION_TYPES} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import TitleBlock from 'client/components/common/title-block';

import VisFiltersModal from '../components/modals/vis-filters-modal';
import VisLeadInfoModal from '../components/modals/vis-lead-info-modal';
import VisPreviewModal from '../components/modals/vis-preview-modal';
import VisTableSettingsModal from '../components/modals/vis-table-settings-modal';
import VisualsBase from '../components/visuals-base';

import '../visuals.scss';

class VisualsGeneral extends ReactQueryParams {
  static propTypes = {
    languageState: PropTypes.object.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    visualsTotalCount: PropTypes.number.isRequired,
    getVisuals: PropTypes.func.isRequired,
    getVisualDisplayItems: PropTypes.func.isRequired,
    visuals: PropTypes.shape({
      visuals: PropTypes.array,
      meta: PropTypes.object,
    }),
    isUserLoaded: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    visuals: {},
  };

  // This number is defined in specification
  static MAX_COLUMNS_COUNT = 25;

  constructor(props) {
    super(props);

    this.state = {
      activeVisual: {},

      showFilterModal: false,
      showDeleteModal: false,
      showPreviewModal: false,
      showLeadInfoModal: false,
      showSettingsModal: false,
      showClear: false,

      selectedIds: [],
    };

    this.payload = props.languageState.payload;
    this.LANGUAGE = this.payload.VISUALS;
  }

  componentDidMount() {
    const {
      languageState: {
        payload: {BREADCRUMBS},
      },
    } = this.props;

    this.props.setBreadcrumbs([
      {
        name: BREADCRUMBS.CLIENTS,
        href: CLIENT_PAGES.CLIENTS_LIST,
      },
      {
        name: BREADCRUMBS.VISUALS,
      },
    ]);

    this.updateMe();
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.client && prevProps.client.id && this.props.client.id !== prevProps.client.id) {
      this.updateMe();
    }
  }

  updateMe = (params = this.queryParams) => {
    const sort = {
      name: (params.sort && params.sort.name) || 'id',
      order: (params.sort && params.sort.order) || 'ASC',
    };

    if (!params.page) {
      this.setQueryParams({page: 1});
    }
    if (!params.perPage) {
      this.setQueryParams({perPage: 5});
    }

    const {arrivingType, group, autotask_id, ...filters} = params.filters || {};

    filters.g = {};

    if (arrivingType && arrivingType.length) {
      filters.g = {
        0: {
          m: 'or',
        },
      };

      arrivingType.forEach((type) => {
        const key = type.split('__')[0];
        const value = type.split('__')[1];

        if (key === 'participation_interaction_type_in') {
          if (filters.g[0][key]) {
            filters.g[0][key].push(value);
          } else {
            filters.g[0][key] = [value];
          }
        } else {
          filters.g[0][key] = value;
        }
      });
    }

    if (group === 'has') {
      filters.participation_interaction_type_eq = VISUAL_INTERACTION_TYPES.OFFLINE;
    } else if (group === 'hasNo') {
      filters.g[1] = {
        m: 'or',
        participation_id_null: 't',
        participation_interaction_type_not_eq: VISUAL_INTERACTION_TYPES.OFFLINE,
      };
    }

    const visualsParams = {
      page: params.page || 1,
      per_page: +params.perPage || 5,
      q: {
        s: `${sort.name} ${sort.order}`,
        ...filters,
        title_cont: params.search || '',
        interface_id_eq: params.interfaceId || '',
        automation_task_id_eq: params.taskId || autotask_id || '',
      },
    };

    if (params.search) {
      this.setState({showClear: true});
    }

    window.scrollTo(0, 0);

    return this.props.getVisuals(visualsParams).then(this.updateColumns);
  };

  updateColumns = () => {
    this.props.getVisualDisplayItems({
      include: ['column_adapter'],
      q: {
        s: 'position asc',
      },
    });
  };

  handlePreviewClick = (activeVisual = {}) => {
    this.setState({
      showPreviewModal: !this.state.showPreviewModal,
      activeVisual,
    });
  };

  handlePreviewNextClick = () => {
    const {visuals} = this.props.visuals;
    const index = findIndex(visuals, (i) => i.id === this.state.activeVisual.id);

    if (index < visuals.length - 1) {
      return this.setState({
        activeVisual: visuals[(index + 1) % visuals.length],
      });
    }

    this.setQueryParams(
      {
        page: (+this.queryParams.page % this.props.visuals.meta.total_pages) + 1,
      },
      true,
    );

    return this.updateMe().then(() =>
      this.setState({
        activeVisual: this.props.visuals.visuals[0],
      }),
    );
  };

  handlePreviewPreviousClick = () => {
    const {visuals} = this.props.visuals;
    const index = findIndex(visuals, (i) => i.id === this.state.activeVisual.id);

    if (index > 0) {
      return this.setState({
        activeVisual: visuals[index - 1],
      });
    }

    const totalPages = this.props.visuals.meta.total_pages;
    this.setQueryParams(
      {
        page: ((this.queryParams.page - 2 + totalPages) % totalPages) + 1,
      },
      true,
    );

    return this.updateMe().then(() =>
      this.setState({
        activeVisual: this.props.visuals.visuals[this.props.visuals.visuals.length - 1],
      }),
    );
  };

  handleLeadClick = (activeVisual = {}) => {
    this.setState({
      showLeadInfoModal: !this.state.showLeadInfoModal,
      activeVisual,
    });
  };

  handleVisualSelect =
    (...ids) =>
    (checked) => {
      const selectedIds = this.state.selectedIds.slice();
      if (checked) {
        for (let id of ids) {
          if (!selectedIds.includes(id)) {
            selectedIds.push(id);
          }
        }
      } else {
        pull(selectedIds, ...ids);
      }

      this.setState({selectedIds});
    };

  handleDeleteVisuals = () => {
    const promises = this.state.selectedIds.map((id) => deleteMethod(`${API_METHODS.VISUALS}/${id}`));
    Promise.all(promises).then(() => {
      this.setState({selectedIds: [], showDeleteModal: false});
      this.updateMe();
    });
  };

  onSearchChange = (search = '') => {
    this.setQueryParams(
      {
        search,
        page: 1,
      },
      true,
    );
    this.setState({search}, this.updateMe);
  };

  onSearchClear = () => this.onSearchChange();

  getVisualsType = () => {
    return this.queryParams.trackerId ? POST_PREVIEW_TYPES.HASHTAG_TRACKER : POST_PREVIEW_TYPES.MAIN;
  };

  selectField = (key, values) => values[key];

  render() {
    const {showFilterModal, showPreviewModal, showLeadInfoModal, showSettingsModal, activeVisual, selectedIds} =
      this.state;

    const {visuals} = this.props;
    const data = visuals.visuals;

    return (
      <div>
        <VisFiltersModal
          show={showFilterModal}
          onClose={() => this.setState({showFilterModal: false})}
          totalItems={visuals.meta.total_count}
          updateMe={this.updateMe}
        />

        <VisPreviewModal
          show={showPreviewModal}
          visual={activeVisual}
          updateMe={this.updateMe}
          onClose={this.handlePreviewClick}
          onNext={this.handlePreviewNextClick}
          onPrevious={this.handlePreviewPreviousClick}
          selectedIds={selectedIds}
          onSelect={this.handleVisualSelect(activeVisual.id)}
          getVisualOrDiaporamaValue={this.selectField}
          showNavigation={data.length > 1}
          isAdmin
        />

        <VisTableSettingsModal
          show={showSettingsModal}
          updateMe={this.updateMe}
          onClose={() => this.setState({showSettingsModal: false})}
        />

        <VisLeadInfoModal show={showLeadInfoModal} visual={activeVisual} onClose={this.handleLeadClick} />

        <ConfirmationModal
          title={this.LANGUAGE.DELETE_VISUALS_MODAL.TITLE}
          message={this.LANGUAGE.DELETE_VISUALS_MODAL.MESSAGE}
          cancelText={this.LANGUAGE.DELETE_VISUALS_MODAL.CANCEL_BUTTON}
          confirmText={this.LANGUAGE.DELETE_VISUALS_MODAL.CONFIRM_BUTTON}
          className="theme-color-13"
          buttonConfirmClass="button--bg-8"
          onConfirm={this.handleDeleteVisuals}
          onCancel={() => this.setState({showDeleteModal: false})}
          onClose={() => this.setState({showDeleteModal: false})}
          show={this.state.showDeleteModal}
        />

        <VisualsBase
          data={data}
          meta={visuals.meta}
          selectedIds={selectedIds}
          onSelect={this.handleVisualSelect}
          visualsType={this.getVisualsType()}
          onSearchChange={this.onSearchChange}
          onSearchClear={this.onSearchClear}
          onLeadClick={this.handleLeadClick}
          onPreviewClick={this.handlePreviewClick}
          onSettingsClick={() => this.setState({showSettingsModal: true})}
          updateMe={this.updateMe}
          onShowFiltersClick={() => this.setState({showFilterModal: true})}
          getVisualOrDiaporamaValue={this.selectField}
          languageState={this.payload}
          isAdmin
          titleContent={
            <div className="visuals__page-title">
              <TitleBlock theme>
                <TitleBlock.Item>{this.LANGUAGE.TITLE}</TitleBlock.Item>
              </TitleBlock>
            </div>
          }
          actionButtons={
            <div className="vis-data-grid__action-btns">
              <div>
                <button className="button button--bg-8 " onClick={() => this.setState({showDeleteModal: true})}>
                  {`${this.LANGUAGE.TABLE.DELETE_FROM_DB_BUTTON} (${selectedIds.length})`}
                </button>
              </div>
              <div>
                <button className="button button--bg-8" onClick={() => this.setState({selectedIds: []})}>
                  {`${this.LANGUAGE.TABLE.DESELECT_ALL_BUTTON} (${selectedIds.length})`}
                </button>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    languageState: state.languageState,
    visuals: state.visuals,
    visualsTotalCount: selectVisualsTotalCount(state),
    isUserLoaded: selectIsUserLoaded(state),
    client: selectCurrentClient(state),
  }),
  {
    setBreadcrumbs,
    clearBreadcrumbs,
    getVisuals,
    getVisualDisplayItems,
  },
)(VisualsGeneral);
