import {mapClient} from './mappers';
import types from './types';

let init = {
  type: types.CLIENTS_LIST_NO_DATA,
  payload: {
    data: [],
    meta: {
      current_page: 0,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
  },
  metaMyClients: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
  client: {},
  loading: false,
};

export default function (_state_ = init, action) {
  let state = Object.assign({}, _state_);
  switch (action.type) {
    case types.CLIENTS_LIST_NO_DATA:
      state.type = types.CLIENTS_LIST_NO_DATA;
      state.payload = {data: action.payload.data, meta: action.payload.meta};
      return state;
    case types.CLIENTS_LIST_LOADED:
    case types.GET_CLIENTS_SUCCESS:
      const {
        payload: {clients, meta},
      } = action;
      state.type = types.CLIENTS_LIST_LOADED;
      state.payload = {
        data: clients.map(mapClient),
        meta: {
          ...state.payload.meta,
          ...meta,
        },
      };
      return state;
    case types.CLIENTS_LIST_CLIENT_ADDED:
      state.type = types.CLIENTS_LIST_CLIENT_ADDED;
      state.payload.data.push(action.payload);
      state.payload.meta.total_count += 1;
      return state;
    case types.CLIENTS_DELETE_SUCCESS:
      state.type = types.CLIENTS_DELETE_SUCCESS;
      const removedElIndex = state.payload.data.map((el) => el.id).indexOf(action.payload.id);
      if (removedElIndex) {
        state.payload.data.splice(removedElIndex, 1);
        state.payload.meta.total_count -= 1;
      }
      return state;

    case types.GET_CLIENT_SUCCESS:
      state.client = mapClient(action.payload.client);
      return state;

    case types.PATCH_CLIENT_WITH_FORM_DATA_REQUEST:
      state.loading = true;
      return state;

    case types.PATCH_CLIENT_WITH_FORM_DATA_ERROR:
    case types.PATCH_CLIENT_WITH_FORM_DATA_SUCCESS:
      state.loading = false;
      return state;
    case types.GET_MY_CLIENTS_SUCCESS:
      return {
        ...state,
        metaMyClients: {
          ...state.metaMyClients,
          ...action.payload.meta,
        },
      };
    default:
      return state;
  }
}
