import {mapNotWinnersData, mapWinningDevices} from './mappers';
import types from './types';

const initialState = {
  regions: [],
  stores: [],
  devices: [],
  campaigns: [],
  winnings: [],
  notWinnersData: {
    percent: 0,
    number: 0,
  },
  winner: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_WINNINGS_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action?.payload?.regions || initialState.regions,
      };

    case types.GET_WINNINGS_STORES_SUCCESS:
      return {
        ...state,
        stores: action?.payload?.places || initialState.stores,
      };

    case types.GET_WINNINGS_DEVICES_SUCCESS:
      return {
        ...state,
        devices: mapWinningDevices(action?.payload?.offline_interactions) || initialState.devices,
      };

    case types.GET_WINNINGS_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action?.payload?.campaigns || initialState.campaigns,
      };

    case types.GET_WINNINGS_SUCCESS:
      return {
        ...state,
        winnings: action?.payload?.prizes_data || initialState.winnings,
        notWinnersData: mapNotWinnersData(action?.payload?.leads_count, action?.payload?.wins_count),
      };

    case types.SEARCH_WINNER_SUCCESS:
      return {
        ...state,
        winner: action?.payload || initialState.winner,
      };

    default:
      return state;
  }
}
