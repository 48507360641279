import React, {useState} from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';
import SelectDropdown from 'client/common/selects/select-dropdown';

import usePrizeVariableOptions from 'client/components/email-template-editor/hooks/usePrizeVariableOptions';
import {getVariableOptions} from 'client/components/email-template-editor/inputs/parameters-variable-dropdown/helpers';
import {Translation} from 'client/models/language/types';
import {SmsTemplateKindVariable, SmsTemplateKind} from 'client/models/sms-templates/types';

import cssModule from './sms-variable-bar.module.scss';

const b = bem('sms-variable-bar', {cssModule});

type VariableFamily = 'lead' | 'participation' | 'device' | 'operation_datum' | 'instant_win' | 'lottery';

type FamilyOption = {
  value: VariableFamily;
  label: Translation;
};

type SmsVariableBarProps = {
  onConfirm: (value: string) => void;
  variables: SmsTemplateKindVariable[];
  templateKind: SmsTemplateKind;
};

const SmsVariableBar: React.FC<SmsVariableBarProps> = (props) => {
  const {onConfirm, variables, templateKind} = props;
  const lang = useLanguage('SMS_TEMPLATES.MODALS.SMS_TEMPLATE_MODAL.VARIABLE_BAR');

  const prizeOptions = usePrizeVariableOptions(templateKind?.category);

  const allOptions = {
    ...getVariableOptions(variables),
    instant_win: prizeOptions.instantWin?.options || [],
    lottery: prizeOptions.lottery?.options || [],
  };

  const allFamilyOptions = [
    {value: 'lead', label: lang.LEADS},
    {value: 'participation', label: lang.PARTICIPATIONS},
    {value: 'device', label: lang.DEVICE},
  ] as FamilyOption[];

  if (prizeOptions.instantWin) {
    allFamilyOptions.push({value: 'instant_win', label: prizeOptions.instantWin.label});
  }

  if (prizeOptions.lottery) {
    allFamilyOptions.push({value: 'lottery', label: prizeOptions.lottery.label});
  }

  const familyOptions = allFamilyOptions.filter(({value}) => allOptions[value]?.length);

  const [family, setFamily] = useState<VariableFamily>(familyOptions[0].value as VariableFamily);
  const [name, setName] = useState(allOptions[familyOptions[0].value][0].value);

  const handleFamilyChange = (value: VariableFamily) => {
    setFamily(value);
    setName(allOptions[value][0]?.value);
  };

  const handleConfirm = () => {
    onConfirm(name);
  };

  return (
    <div className={b()}>
      <SelectDropdown
        className={b('family-select')}
        label={lang.VARIABLE_FAMILY}
        value={family}
        onChange={handleFamilyChange}
        options={familyOptions}
        simpleValue
      />
      <SelectDropdown
        className={b('name-select')}
        label={lang.VARIABLE_NAME}
        value={name}
        onChange={setName}
        options={allOptions[family]}
        simpleValue
      />
      <AppButton
        className={b('button')}
        onClick={handleConfirm}
        title={lang.INSERT}
        iconName="check"
        color="light-clients"
      />
    </div>
  );
};

export default SmsVariableBar;
