import {callApi} from 'client/services/call-api';
import {getEmail, getToken} from 'client/services/cookie-data-source';

import {API_PATH, API_METHODS} from 'client/common/config';

import {ApiAction} from 'client/types';

import types from './types';

export const getSmsTemplateKinds: ApiAction = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SMS_TEMPLATE_KINDS}`,
      noCache: true,
      method: 'GET',
      types: [
        types.GET_SMS_TEMPLATE_KINDS_REQUEST,
        types.GET_SMS_TEMPLATE_KINDS_SUCCESS,
        types.GET_SMS_TEMPLATE_KINDS_ERROR,
      ],
      queryParams,
    });
};

export const updateSmsTemplatesBulk: ApiAction = (body = {}) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.OPERATION_SMS_TEMPLATES}/bulk_action`,
        noCache: true,
        method: 'PATCH',
        types: [
          types.UPDATE_SMS_TEMPLATES_BULK_REQUEST,
          types.UPDATE_SMS_TEMPLATES_BULK_SUCCESS,
          types.UPDATE_SMS_TEMPLATES_BULK_ERROR,
        ],
        body: {operation_sms_template: body},
      },
      true,
    );
};

export const sendTestSms = (id: number | string, body: any) => {
  return fetch(`${API_PATH}${API_METHODS.OPERATION_SMS_TEMPLATES}/${id}/send_test_sms`, {
    method: 'POST',
    headers: {
      'X-User-Email': getEmail() as string,
      'X-User-Token': getToken() as string,
    },
    body,
  });
};
