export default (values, task, user) => {
  const sharing = [];
  for (let [item, checked] of Object.entries(values.sharing)) {
    if (checked) {
      sharing.push(item);
    }
  }

  return {
    instore_internal_dashboard: {
      name: values.name,
      instore_task_id: task.id,
      user_id: user.id,
      sharing_level: sharing,
    },
  };
};
