import React, {useMemo} from 'react';

import loadingGif from 'assets/images/loading.gif';
import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxFetch from 'client/services/hooks/use-redux-fetch';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {webAppTesting} from 'client/ducks/interfaces/actions';

import AppButton from 'client/common/buttons';
import {useToast} from 'client/common/hooks/useToast';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Popover from 'client/common/popovers/popover';

import DiyOpPublishingPreviewCard from 'client/components/diy-operation/cards/diy-op-publishing-preview-card';
import {DIY_OPERATION_TYPES_WITH_URL, DIY_OPERATION_TYPES_DEVICE} from 'client/components/diy-operation/constants';
import {DiyOpStatusControlProps} from 'client/components/diy-operation/controls/diy-op-status-control/type';
import {Interfaces} from 'client/models/interfaces/types';

import cssModule from './diy-op-status-control.module.scss';

const b = bem('diy-op-status-control', {cssModule});

const DiyOpStatusControl: React.FC<DiyOpStatusControlProps> = (props) => {
  const {
    label,
    onClick,
    disabled,
    type,
    show = true,
    handleOpenModal,
    editUrlButtonDisabled,
    onUnPublish,
    url,
    isPublished,
    showEditUrlButton = !isPublished,
    isOperationArchived,
  } = props;

  const [confirmationModal, toggleConfirmationModal] = useToggle(false);

  const autotask = useSelector(selectAutotask);

  const currentInterface = useMemo(() => autotask.interfaces?.find(({online}) => online), [autotask]);
  const isWebApp = Object.values(DIY_OPERATION_TYPES_WITH_URL).includes(type);
  const isDevice = Object.values(DIY_OPERATION_TYPES_DEVICE).includes(type);

  const {appendToastNotification} = useToast();

  const {loading, fetch} = useReduxFetch<{interface: Interfaces}>({
    fetchOnMount: false,
    action: webAppTesting,
    actionArgs: currentInterface?.id,
  });

  const lang = useLanguage('DIY_OPERATION.CONTROLS.DIY_OP_STATUS_CONTROL');

  const onTesting = async () => {
    toggleConfirmationModal(true);
    try {
      const response = await fetch();
      let testUrl = response.payload.interface.web_app_test_url_full;
      if (testUrl) {
        testUrl = testUrl.startsWith('http') ? testUrl : `https://${testUrl}`;
        window.open(testUrl, '_blank');
      }
    } catch (e) {
      appendToastNotification({
        type: 'error',
        title: lang.ERROR_TITLE,
        description: lang.ERROR_MESSAGE,
      });
    }
    toggleConfirmationModal(false);
  };

  const renderUrl = useMemo(() => {
    if (!url) {
      return lang.URL_DEFAULT;
    }

    return !isPublished ? (
      <span className={b('link', ['not-published'])}>www.{url}</span>
    ) : (
      <a href={`https://${url}`} target="_blank" className={b('link', ['published'])}>
        www.{url}
      </a>
    );
  }, [isPublished, lang.URL_DEFAULT, url]);

  return (
    <div className={b()}>
      {show && (
        <div className={b('container')}>
          <div className={b('status-container')}>
            <AppButton
              label={label}
              iconName="publish"
              onClick={onClick}
              color="clients"
              size="medium"
              disabled={disabled || isOperationArchived}
            />
          </div>

          {isPublished && !!onUnPublish && (
            <Popover overlay={<span>{lang.UNPUBLISH_BUTTON}</span>}>
              <AppButton onClick={onUnPublish} iconName="unpublish" transparent={true} color="devices" outline={true} />
            </Popover>
          )}
        </div>
      )}
      {isWebApp && (
        <>
          <div className={b('url-container')}>
            {!url && <p className={b('url-title')}>{lang.URL}:</p>}
            <p className={b('url-value')}>{renderUrl}</p>
            {showEditUrlButton && !isOperationArchived && (
              <Popover overlay={<span>{lang.URL_EDIT}</span>}>
                <AppButton
                  disabled={isPublished && editUrlButtonDisabled}
                  onClick={handleOpenModal}
                  iconName="edit-alt"
                  className={b('button')}
                  color="light-clients"
                />
              </Popover>
            )}
          </div>
          <AppButton
            iconName="settings"
            color="light-clients"
            label={lang.TESTING_BUTTON}
            onClick={onTesting}
            loading={loading}
            className={b('testing-button')}
          />
        </>
      )}

      {isDevice && <DiyOpPublishingPreviewCard />}

      <ConfirmationModal
        title={lang.CONFIRM_TESTING_TITLE}
        show={!!confirmationModal}
        onClose={toggleConfirmationModal}
        message={
          <>
            <p className={b('modal-text')}>{lang.CONFIRM_TESTING_MESSAGE}</p>
            <p className={b('modal-loading-container')}>
              <img src={loadingGif} className={b('modal-loading')} />
            </p>
          </>
        }
        messageClassName={b('modal-message')}
        clientSide={true}
      />
    </div>
  );
};

export default DiyOpStatusControl;
