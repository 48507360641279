import React, {Component} from 'react';

import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Async} from 'react-select-plus';

import {TranslationJsx} from 'client/models/language/types';

import Icon from '../icon';

class AsyncDropdownAny extends Component {
  static propTypes = {
    className: PropTypes.string,
    error: TranslationJsx,
    label: TranslationJsx,
    placeholder: TranslationJsx,
    getOptions: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.any,
    scrollBarProps: PropTypes.object,
    inputProps: PropTypes.object,
    input: PropTypes.object,
    onChange: PropTypes.func,
    multi: PropTypes.bool,
    name: PropTypes.string,
    lang: PropTypes.object.isRequired,
    searchPromptText: TranslationJsx,
  };
  static defaultProps = {
    value: [],
    placeholder: '',
    multi: true,
    searchPromptText: '',
  };

  shouldComponentUpdate(nextProps) {
    if (!isEqual(this.props.value, nextProps.value)) {
      return true;
    }

    if (this.props.disabled !== nextProps.disabled) {
      return true;
    }

    if (!isEqual(this.props.input, nextProps.input)) {
      return true;
    }

    return false;
  }

  handleGetOptions = async (search, callback) => {
    const options = await this.props.getOptions(search);

    callback(null, {
      options,
      complete: true,
    });
  };

  arrowRenderer = () => {
    return <Icon name="dropdown" className="select__icon" />;
  };

  handleChange = (value) => {
    const {input, onChange} = this.props;

    if (input && input.onChange) {
      input.onChange(value);
    }

    if (onChange) {
      onChange(value);
    }
  };

  render() {
    const {
      label,
      error,
      placeholder,
      value,
      valueKey,
      labelKey,
      className,
      input,
      disabled,
      name,
      multi,
      inputProps,
      lang,
      searchPromptText,
    } = this.props;

    return (
      <div className={cn('select', {'select--error': error}, className)}>
        {label && <div className="select__label">{label}</div>}
        <Async
          multi={multi}
          name={name}
          cache={false}
          clearable={false}
          disabled={disabled}
          backspaceRemoves={true}
          onBlurResetsInput={false}
          className="select__field"
          optionClassName="ellipsis-text"
          loadOptions={this.handleGetOptions}
          arrowRenderer={this.arrowRenderer}
          placeholder={placeholder}
          value={(input && input.value) || value}
          valueKey={valueKey}
          labelKey={labelKey}
          searchPromptText={searchPromptText ? searchPromptText : lang.TYPE_TO_SEARCH}
          noResultsText={lang.DROPDOWN_NO_DATA}
          onChange={this.handleChange}
          inputProps={inputProps}
        />
        {error && <p className="select__message">{error}</p>}
      </div>
    );
  }
}

export default connect(({languageState}) => ({
  lang: languageState.payload.COMMON,
}))(AsyncDropdownAny);
