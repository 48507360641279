import {RSAA} from 'redux-api-middleware';

import {callApi} from 'client/services/call-api';
import {getQueryParams} from 'client/services/helpers';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function clearInterfaceTemplatesState() {
  return {
    type: types.CLEAR_TEMPLATES_STATE,
  };
}

export function getInterfaceTemplatesAction({page, perPage, sort = {}, fetchAll = false}) {
  return (dispatch) => {
    let queryParams = '';

    if (!fetchAll) {
      queryParams = getQueryParams({
        include: ['interface_template_items'],
        q: {
          s: `${sort.name} ${sort.order}`,
        },
        page,
        per_page: perPage,
      });
    }

    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.INTERFACE_TEMPLATES}?${queryParams}`,
        method: 'GET',
        types: [types.GET_TEMPLATES_REQUEST, types.GET_TEMPLATES_SUCCESS, types.GET_TEMPLATES_ERROR],
        isAuth: true,
        noCache: true,
      },
    });
  };
}

export function deleteInterfaceTemplateAction(id) {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.INTERFACE_TEMPLATES}/${id}`,
        method: 'DELETE',
        types: [types.DELETE_TEMPLATE_REQUEST, types.DELETE_TEMPLATE_SUCCESS, types.DELETE_TEMPLATE_ERROR],
        noCache: true,
      },
      true,
    );
}

export function getInterfaceTemplateItemsAction(id) {
  return (dispatch) => {
    const queryParams = getQueryParams({
      q: {
        interface_template_id_eq: id,
      },
    });

    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.INTERFACE_TEMPLATE_ITEMS}?${queryParams}`,
        method: 'GET',
        types: [types.GET_TEMPLATE_ITEMS_REQUEST, types.GET_TEMPLATE_ITEMS_SUCCESS, types.GET_TEMPLATE_ITEMS_ERROR],
        isAuth: true,
        noCache: true,
      },
    });
  };
}
