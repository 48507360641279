import React, {Component} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import BasePagination from 'client/components/common/base-pagination';

import {TranslationJsx} from 'client/models/language/types';

import './info-block.scss';

class InfoBlock extends Component {
  static Title(props) {
    const {className, children} = props;

    return <div className={`info-block__title ${className}`}>{children}</div>;
  }

  static Header(props) {
    const {className, children, alone} = props;

    const cssClass = classNames({
      'info-block__content-header': true,
      'info-block__content-header--alone': alone,
    });

    return <div className={`${cssClass} ${className}`}>{children}</div>;
  }

  static HeaderLink(props) {
    const {
      className,
      link: {url, text},
      description,
    } = props;
    const style = !description ? {marginTop: 3} : {marginTop: -4};

    return (
      <div className={`info-block__header-link pull-right ${className}`} style={style}>
        <Link to={url} className="link">
          {text}
        </Link>
        {description && <div className="info-block__header-link__description">{description}</div>}
      </div>
    );
  }

  static Body(props) {
    const {className, children, limitedHeight, emptyText, scrollable, expandRight, expand} = props;

    const cssClass = classNames({
      'info-block__content-body': true,
      'info-block__content-body--limited': limitedHeight,
      'info-block__content-body--scrollable': scrollable,
      'info-block__content-body--expand-right': expandRight,
      'info-block__content-body--expand': expand,
    });

    return (
      <div className={`${cssClass} ${className}`}>
        {children ? children : <div className="info-block__empty">{emptyText}</div>}
      </div>
    );
  }

  static Content(props) {
    const {className, children, headerOnly} = props;

    const cssClass = classNames({
      'info-block__content': true,
      'info-block__content--header-only': headerOnly,
    });

    return (
      <div className="">
        <div className={`${cssClass} ${className}`}>{children}</div>
      </div>
    );
  }

  render() {
    const {children, className, colorTheme, showPagination, paginationProps, paginationCssClass} = this.props;

    const cssClass = classNames({
      'info-block': true,
      [`info-block--color-${colorTheme}`]: !!colorTheme,
    });

    return (
      <div className={`${cssClass} ${className}`}>
        {children}
        {showPagination && (
          <div className={`info-block__pagination ${paginationCssClass}`}>
            <BasePagination
              totalPages={paginationProps?.totalPages}
              currentPage={paginationProps?.currentPage}
              onClick={paginationProps?.onClick}
            />
          </div>
        )}
      </div>
    );
  }
}

InfoBlock.defaultProps = {
  children: '',
  className: '',
  colorTheme: '',
  paginationCssClass: '',
  showPagination: false,
  paginationProps: {},
};

InfoBlock.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  colorTheme: PropTypes.string,
  paginationCssClass: PropTypes.string,
  showPagination: PropTypes.bool,
  paginationProps: PropTypes.object,
};

InfoBlock.Header.defaultProps = {
  children: '',
  className: '',
  alone: false,
};

InfoBlock.Header.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  alone: PropTypes.bool,
};

InfoBlock.Body.defaultProps = {
  children: '',
  className: '',
  scrollable: false,
  limitedHeight: false,
  expandRight: false,
  expand: false,
  emptyText: 'No content',
};

InfoBlock.Body.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  scrollable: PropTypes.bool,
  limitedHeight: PropTypes.bool,
  expandRight: PropTypes.bool,
  expand: PropTypes.bool,
  emptyText: TranslationJsx,
};

InfoBlock.Title.defaultProps = {
  children: '',
  className: '',
};

InfoBlock.Title.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

InfoBlock.Content.defaultProps = {
  children: '',
  className: '',
  headerOnly: false,
};

InfoBlock.Content.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  headerOnly: PropTypes.bool,
};

export default InfoBlock;
